import React, { useState, useEffect } from 'react';
import {
    Image,
    usePageLayout,
    PageLayout,
    Text,
    StackLayout,
    ProgressBar,
    Box,
    ColumnLayout,
    Icon,
    useResponsive,
} from '@embroker/ui-toolkit/v2';

export function YouAreAllDone() {
    const state = usePageLayout({
        header: null,
        isFullscreen: true,
    });
    const [display, setDisplay] = useState<0 | 1 | 2 | 3>(0);
    const isMobileView = useResponsive({ screenWidth: { smallerThan: 'tablet' } });
    const iconSectionStyles = isMobileView
        ? { width: '100%' }
        : { width: '33.33%', flexGrow: 0, maxWidth: '197px' };
    const containerSectionClassName = isMobileView ? 'u-1/1' : 'u-2/3';
    const minHeight = isMobileView ? '376px' : '224px';

    useEffect(() => {
        let intervalId: NodeJS.Timeout;
        if (display < 3) {
            intervalId = setInterval(() => {
                setDisplay((prev) => {
                    switch (prev) {
                        case 0:
                            return 1;
                        case 1:
                            return 2;
                        case 2:
                        case 3:
                            return 3;

                        default:
                            return 0;
                    }
                });
            }, 4000);
        }

        return () => clearInterval(intervalId);
    }, [display]);

    return (
        <PageLayout {...state}>
            <PageLayout.Section>
                <StackLayout center gap="64">
                    <Image name="illustration-woman-laptop" height={isMobileView ? 200 : 400} />
                    <StackLayout center gap="32" className={containerSectionClassName}>
                        <Text style="heading 3" textAlign="center">
                            You’re all done! Give us a second while we process your information.
                        </Text>
                        <div className="u-1/1">
                            <ProgressBar duration={10} />
                        </div>
                        <Box
                            backgroundColor="primary-100"
                            borderRadius="12"
                            gap="32 32"
                            style={{ minHeight: minHeight, width: '100%' }}
                            border={false}
                        >
                            <StackLayout gap="24">
                                <Text style="heading 3" textAlign="center">
                                    Why choose Embroker?
                                </Text>
                                <ColumnLayout
                                    responsive={{ screenWidth: { smallerThan: 'tablet' } }}
                                    top
                                    className="u-1/1"
                                >
                                    {display > 0 && (
                                        <StackLayout gap="12" center style={iconSectionStyles}>
                                            <Icon name="globe" size="regular" />
                                            <Text as="body" textAlign="center">
                                                Online policy management. Real people support.
                                            </Text>
                                        </StackLayout>
                                    )}
                                    {display > 1 && (
                                        <StackLayout gap="12" center style={iconSectionStyles}>
                                            <Icon name="gem" size="regular" />
                                            <Text as="body" textAlign="center">
                                                Best-in-class coverages from top-rated carriers.
                                            </Text>
                                        </StackLayout>
                                    )}
                                    {display > 2 && (
                                        <StackLayout gap="12" center style={iconSectionStyles}>
                                            <Icon name="simple-handshake" size="regular" />
                                            <Text as="body" textAlign="center">
                                                Trusted by thousands of small businesses like yours.
                                            </Text>
                                        </StackLayout>
                                    )}
                                </ColumnLayout>
                            </StackLayout>
                        </Box>
                    </StackLayout>
                </StackLayout>
            </PageLayout.Section>
        </PageLayout>
    );
}
