import React from 'react';

import { Vehicle } from '../../../types/Vehicle';
import { useEnumGroup } from '../../../../serverEnums/view/hooks/useEnumGroup';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { Table } from '@embroker/ui-toolkit/v2';

interface VehicleInfoProps {
    vehicle: Nullable<Immutable<Vehicle>>;
}

export const VehicleInfo = ({ vehicle }: VehicleInfoProps) => {
    const { result: vehicleUseCodeList } = useEnumGroup('VehicleUseCodeList');

    if (vehicleUseCodeList === undefined || !vehicle) {
        return null;
    }

    return (
        <Table>
            <Table.Header>
                <Table.Column>VIN</Table.Column>
                <Table.Column>LOCATION</Table.Column>
                <Table.Column>USAGE</Table.Column>
                <Table.Column>RADIUS</Table.Column>
                <Table.Column>LOSS PAYEE</Table.Column>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>{vehicle.vin}</Table.Cell>
                    <Table.Cell>{`${vehicle.garagingCity} ${vehicle.garagingState} ${vehicle.garagingZip}`}</Table.Cell>
                    <Table.Cell>{vehicleUseCodeList.get(vehicle.usage)}</Table.Cell>
                    <Table.Cell>{vehicle.radius}</Table.Cell>
                    <Table.Cell>{vehicle.lossPayee}</Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    );
};
