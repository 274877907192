import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject } from '@embroker/shotwell/core/di';
import { ShoppingGetActiveSignatureRequestResponse } from '@embroker/shotwell-api/app';
import { BrokerRepository } from '../../../brokerDashboard/repositories';
import { UUID } from '@embroker/shotwell/core/types/UUID';

interface GetLatestPendingSignatureParams {
    applicationId: UUID;
}

export interface GetLatestPendingSignature extends UseCase {
    execute(
        params: GetLatestPendingSignatureParams,
    ): AsyncResult<ShoppingGetActiveSignatureRequestResponse, InvalidArgument | OperationFailed>;
}

class GetLatestPendingSignatureRequestUseCase extends UseCase implements GetLatestPendingSignature {
    public static type = Symbol('Brokerage/GetLatestPendingSignatureRequest');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BrokerRepository) private brokerRepository: BrokerRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
    }: GetLatestPendingSignatureParams): AsyncResult<
        ShoppingGetActiveSignatureRequestResponse,
        InvalidArgument | OperationFailed
    > {
        const getSignatureRequestsResult = await this.brokerRepository.getActiveSignatureRequest({
            application_id: applicationId,
        });

        if (isErr(getSignatureRequestsResult)) {
            return getSignatureRequestsResult;
        }

        return Success<ShoppingGetActiveSignatureRequestResponse>(getSignatureRequestsResult.value);
    }
}

export const GetLatestPendingSignature: UseCaseClass<GetLatestPendingSignature> =
    GetLatestPendingSignatureRequestUseCase;
