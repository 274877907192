import { Grid, ColumnLayout, TextButton, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { ESPEndorsementPolicyAddressData } from '../../types/ESPEndorsementPolicy';
import { ESPEndorsementCardLayout } from './ESPEndorsementCardLayout.view';
import { ESPEndorsementValueLabel } from './ESPEndorsementValueLabel.view';

interface ESPEndorsementAddressSectionProp {
    addressData: ESPEndorsementPolicyAddressData;
    onEditClick: () => void;
}

export function ESPEndorsementAddressSection({
    addressData,
    onEditClick,
}: ESPEndorsementAddressSectionProp) {
    return (
        <ESPEndorsementCardLayout>
            <Grid>
                <Grid.Row>
                    <Grid.Cell>
                        <ColumnLayout center>
                            <Text style="heading 3">Address</Text>
                            <StackLayout gap="4">
                                <span></span>
                                <TextButton onClick={onEditClick} data-e2e="edit-address">
                                    Edit
                                </TextButton>
                            </StackLayout>
                        </ColumnLayout>
                    </Grid.Cell>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Cell width="1/4" data-e2e="cell-address-line-1">
                        <ESPEndorsementValueLabel
                            title="Address Line 1"
                            value={addressData.addressLine1}
                        />
                    </Grid.Cell>
                    <Grid.Cell width="1/4" data-e2e="cell-address-line-2">
                        <ESPEndorsementValueLabel
                            title="Address Line 2"
                            value={addressData.addressLine2}
                        />
                    </Grid.Cell>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Cell width="1/4" data-e2e="cell-city">
                        <ESPEndorsementValueLabel title="City" value={addressData.city} />
                    </Grid.Cell>
                    <Grid.Cell width="1/4" data-e2e="cell-state">
                        <ESPEndorsementValueLabel title="State" value={addressData.state} />
                    </Grid.Cell>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Cell width="1/4" data-e2e="cell-zip">
                        <ESPEndorsementValueLabel title="ZIP" value={addressData.zipCode} />
                    </Grid.Cell>
                    <Grid.Cell width="1/4" />
                </Grid.Row>
            </Grid>
        </ESPEndorsementCardLayout>
    );
}
