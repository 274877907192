import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface CyberConfig {
    readonly cyberDevModeEffectiveDate: string;
    readonly isEmbrokerCyberEnabled: boolean;
}

export const CyberConfig = {
    ...defineValidator<CyberConfig>({
        cyberDevModeEffectiveDate: Joi.string().allow(''),
        isEmbrokerCyberEnabled: Joi.bool().required(),
    }),
    create(cyberConfig: CyberConfig) {
        return CyberConfig.validate(cyberConfig);
    },
};
