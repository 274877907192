import { UUID } from '@embroker/shotwell/core/types/UUID';
import { map, route, withData } from 'navi';
import React from 'react';
import { redirectToWhoopsPage } from '../../../view/errors';
import { WCGAQuoteLandingPage } from '../../wcga/view/components/WCGAQuoteLandingPage';
import { WCGAEstimateLandingPage } from '../../wcga/view/components/WCGAEstimateLandingPage';
import { WCGAEstimateSubmitted } from '../../wcga/view/components/WCGAEstimateSubmitedPage';
import { isErr } from '@embroker/shotwell/core/types/Result';

const wcgaWizardRouteHandler = map(async (request) => {
    const { applicationId } = request.query;
    const validatedApplicationIdResult = UUID.validate(applicationId);

    if (isErr(validatedApplicationIdResult)) {
        return redirectToWhoopsPage(validatedApplicationIdResult.errors);
    }

    return route({
        title: 'WCGA Quote',
        view: <WCGAQuoteLandingPage applicationId={validatedApplicationIdResult.value} />,
    });
});

export const wcgaRoutes = {
    '/': map((request) =>
        withData(
            { url: request.mountpath, page: undefined, fullscreen: true },
            wcgaWizardRouteHandler,
        ),
    ),
    '/:page': map((request) =>
        withData(
            { url: request.mountpath, page: request.params.page, fullscreen: true },
            wcgaWizardRouteHandler,
        ),
    ),
    '/referred-estimate': map((request) =>
        withData(
            { url: request.mountpath, page: undefined, fullscreen: true },
            map(async (request) => {
                const { applicationId } = request.query;
                const validatedApplicationIdResult = UUID.validate(applicationId);

                if (isErr(validatedApplicationIdResult)) {
                    return redirectToWhoopsPage(validatedApplicationIdResult.errors);
                }

                return route({
                    title: 'WCGA Estimate',
                    view: (
                        <WCGAEstimateLandingPage
                            applicationId={validatedApplicationIdResult.value}
                        />
                    ),
                });
            }),
        ),
    ),
    '/referred-estimate-submitted': map((request) =>
        withData(
            { url: request.mountpath, page: undefined, fullscreen: true },
            map(async (request) => {
                const { applicationId } = request.query;
                const validatedApplicationIdResult = UUID.validate(applicationId);

                if (isErr(validatedApplicationIdResult)) {
                    return redirectToWhoopsPage(validatedApplicationIdResult.errors);
                }

                return route({
                    title: 'WCGA Estimate Submitted',
                    view: (
                        <WCGAEstimateSubmitted applicationId={validatedApplicationIdResult.value} />
                    ),
                });
            }),
        ),
    ),
};
