import React, { useCallback, useEffect } from 'react';
import { Spinner } from '@embroker/ui-toolkit/v2';

import { useNavigation } from '@app/view/hooks/useNavigation';
import {
    DEFAULT_NB_RV_REDIRECTION,
    isContentURI,
    useNBRVRedirectGateModalContent,
} from '../hooks/useNBRVRedirectGateModalContent';

import { NBRVRedirectGateModal } from './NBRVRedirectGateModal.view';
import { BundleCoverageType } from '@app/bundle/types/BundleQuoteCoverage';
import { AppTypeCode } from '@app/shopping/types/enums';
import { URI } from '@embroker/shotwell/core/types/URI';
import { GetNBRVFunnelStatus } from '@app/userOrg/useCases/GetNBRVFunnelStatus';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { execute } from '@embroker/shotwell/core/UseCase';
import { SetNBRVFunnelStatus } from '@app/userOrg/useCases/SetNBRVFunnelStatus';

export type NBRVRedirectGateUseCase =
    | 'noAppInProgressAndNoQuote'
    | 'singleValidQuote'
    | 'singleExpiredQuote'
    | 'singleAppInProgress'
    | 'expiredAppExpiredQuote';

interface NBRVRedirectGateProps {
    useCase: NBRVRedirectGateUseCase;
    redirectTo: string;
    coverageName?: string;
    appType?: BundleCoverageType | AppTypeCode;
}

export function NBRVRedirectGate({
    useCase,
    redirectTo,
    coverageName,
    appType,
}: NBRVRedirectGateProps) {
    const { isLoading: isLoadingFunnelStatus, result: funnelStatus } =
        useUseCase(GetNBRVFunnelStatus);

    const { navigate } = useNavigation();

    const onNavigate = useCallback(
        async (url: URI | string) => {
            await execute(SetNBRVFunnelStatus, {
                shown: true,
                forceShow: false,
            });
            navigate(url);
        },
        [navigate],
    );

    const modalContent = useNBRVRedirectGateModalContent({
        useCase,
        redirectTo,
        coverageName,
        appType,
        onNavigate,
    });

    const isURI = isContentURI(modalContent);

    useEffect(() => {
        if (isURI) {
            onNavigate(modalContent);
        } else if (!modalContent) {
            onNavigate(DEFAULT_NB_RV_REDIRECTION);
        }
    }, [modalContent, isURI, onNavigate]);

    useEffect(() => {
        if (!funnelStatus || isErr(funnelStatus)) {
            return;
        }
        // check if user already went through the funnel experience
        if (!funnelStatus.value.shouldShowPopup) {
            onNavigate(DEFAULT_NB_RV_REDIRECTION);
        }
    }, [funnelStatus, onNavigate]);

    if (!modalContent || isURI) {
        return null;
    }

    if (isLoadingFunnelStatus || modalContent === 'loading') {
        return <Spinner />;
    }

    return <NBRVRedirectGateModal {...modalContent} />;
}
