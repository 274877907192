import { ElementProps, useEventHandlerProxy } from '@embroker/ui-toolkit/v2';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useActive, useCurrentRoute, useLinkProps } from 'react-navi';

/**
 * Link component props.
 *
 * Extends all the built-in HTMLAnchorElement props and
 * requires the href prop to be set.
 */
export interface LinkProps extends ElementProps<'a'> {
    /**
     * URL to navigate to.
     */
    href: string;
    /**
     * Controls whether the "is-active" class name is set for this <Link> when
     * the current URL matches the href prop exactly or not.
     * If set to false (default) then the <Link> will be considered to be active
     * even if the current URL is descendant of the href URL.
     */
    exact?: boolean;
    /**
     * Whether to prefetch the linked page (and when) or not.
     * Possible values are 'mount' (prefetch as soon as the <Link> is mounted),
     * 'hover' (default, prefetch when the <Link> is hovered) or false (disables prefetch).
     */
    prefetch?: 'mount' | 'hover' | false;
    /**
     * If this is set to true pointer events will be ignored for this <Link>
     * and the "is-disabled" class name will be set.
     */
    disabled?: boolean;
    /**
     * The defaut display behavour is a combination of `inline-block` & 'inline-flex`. See class .c-link in Link.scss.
     */
    display?: 'inline';
}

/**
 *
 */
export const Link = React.forwardRef(function Link(
    props: LinkProps,
    ref: React.Ref<HTMLAnchorElement>,
) {
    const {
        exact = false,
        prefetch = false,
        disabled = false,
        href,
        display = 'inline-block',
        onClick,
        onMouseEnter,
        ...restProps
    } = props;

    const route = useCurrentRoute();
    const url = useMemo(() => {
        if (/^([^:]+:)?\/\//.test(href)) {
            return href;
        }
        return href
            .replace(/\/:([^/?]+)\b/g, (_, name) => '/' + (route.data[name] ?? ''))
            .replace(/\/{2,}/g, '/');
    }, [href, route.data]);

    const isActive = useActive(url, { exact, loading: true });
    const linkProps = useLinkProps({ href: url, disabled, onClick, onMouseEnter, prefetch });

    const hasTarget = typeof props.target === 'string' && props.target.length > 0;

    const eventHandlerProxy = useEventHandlerProxy(onClick, linkProps.onClick);

    const handleClick = hasTarget ? onClick : eventHandlerProxy;

    return (
        <a
            ref={ref}
            {...restProps}
            {...linkProps}
            aria-disabled={disabled}
            className={clsx('c-link', props.className, {
                'is-disabled': disabled,
                'is-active': isActive,
                'display-inline': display === 'inline',
            })}
            onClick={handleClick}
            onMouseEnter={hasTarget ? onMouseEnter : linkProps.onMouseEnter}
        />
    );
});
