import { inject } from '@embroker/shotwell/core/di';
import {
    Aborted,
    InvalidArgument,
    OperationFailed,
    Timeout,
    UnknownEntity,
} from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { cast } from '@embroker/shotwell/core/types/Nominal';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { DocumentRepository } from '../../../documents/repositories/DocumentRepository';
import { GetDocumentUrl } from '../../../documents/useCases/GetDocumentUrl';
import { TasksRepository } from '../../../tasks/repositories';
import { BrokerageQuoteRepository } from '../repositories/index';

export interface GetSignaturePacketFileUrlRequest {
    applicationId: UUID;
    quoteId: UUID;
    abortSignal: AbortSignal;
}

export interface GetSignaturePacketFileUrlResponse {
    readonly documentUrl: URI;
}

export interface GetSignaturePacketFileUrl extends UseCase {
    execute(
        request: GetSignaturePacketFileUrlRequest,
    ): AsyncResult<
        GetSignaturePacketFileUrlResponse,
        UnknownEntity | InvalidArgument | OperationFailed | Aborted | Timeout
    >;
}

class GetSignaturePacketFileUrlUseCase extends UseCase implements GetSignaturePacketFileUrl {
    public static type = Symbol('CrimeQuote/GetQuoteFileUrl');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BrokerageQuoteRepository) private repository: BrokerageQuoteRepository,
        @inject(DocumentRepository) private documentRepository: DocumentRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
        @inject(GetDocumentUrl.type) private getDocumentURL: GetDocumentUrl,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
        quoteId,
        abortSignal,
    }: GetSignaturePacketFileUrlRequest): AsyncResult<
        GetSignaturePacketFileUrlResponse,
        UnknownEntity | InvalidArgument | OperationFailed | Aborted | Timeout
    > {
        const createTaskResult = await this.repository.createSignaturePacketDocumentTask(
            applicationId,
            quoteId,
        );
        if (isErr(createTaskResult)) {
            return createTaskResult;
        }

        const pollForTaskStatusResult = await this.tasksRepository.pollForTaskStatus(
            createTaskResult.value.taskId,
            abortSignal,
        );
        if (isErr(pollForTaskStatusResult)) {
            return pollForTaskStatusResult;
        }

        const documentInfoResult = await this.documentRepository.getDocument(
            createTaskResult.value.documentId,
        );
        if (isErr(documentInfoResult)) {
            return documentInfoResult;
        }

        const getDocumentUrlResult = await this.getDocumentURL.execute({
            fileKey: documentInfoResult.value.fileKey ?? '',
        });
        if (isErr(getDocumentUrlResult)) {
            return getDocumentUrlResult;
        }

        return Success<GetSignaturePacketFileUrlResponse>({
            documentUrl: cast<URI>(getDocumentUrlResult.value.downloadUrl),
        });
    }
}

export const GetSignaturePacketFileUrl: UseCaseClass<GetSignaturePacketFileUrl> =
    GetSignaturePacketFileUrlUseCase;
