import { errorCodes } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { ErrorLike, ErrorObject } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Certificate } from './entities/Certificate';
import { CertificateDocument } from './types/CertificateDocument';
import { SelfServingCertificateCoverageList } from './types/SelfServingCertificateCoverage';

export const ErrorCode = errorCodes({
    GetSelfServingCoverageListFailed: 0xb601,
    GetCertificatesForActiveOrganizationFailed: 0xb602,
    GetShareableCertificateDataFailed: 0xb603,
    SelfServingCertificateRequestFailed: 0xb604,
    SaveCertificatePhoneNumberNull: 0xb605,
    CreateSelfServingCertificateFailed: 0xb606,
    PreviewSelfServingCertificateFailed: 0xb607,
    CertificateNotFound: 0xb609,
    SelfServingCertificateHasMultipleDocuments: 0xb609,
});

export type GetSelfServingCoverageListFailed = ErrorObject<
    typeof ErrorCode.GetSelfServingCoverageListFailed,
    {
        innerErrorList: Immutable<ErrorLike[]>;
    }
>;

export function GetSelfServingCoverageListFailed(
    innerErrorList: Immutable<ErrorLike[]>,
): Immutable<GetSelfServingCoverageListFailed> {
    return {
        name: 'GetSelfServingCoverageListFailed',
        code: ErrorCode.GetSelfServingCoverageListFailed,
        message: 'Get self serving coverages api request failed',
        details: { innerErrorList },
    };
}

export type GetCertificatesForActiveOrganizationFailed = ErrorObject<
    typeof ErrorCode.GetCertificatesForActiveOrganizationFailed,
    {
        innerErrorList: Immutable<ErrorLike[]>;
    }
>;

export function GetCertificatesForActiveOrganizationFailed(
    innerErrorList: Immutable<ErrorLike[]>,
): Immutable<GetCertificatesForActiveOrganizationFailed> {
    return {
        name: 'GetCertificatesForActiveOrganizationFailed',
        code: ErrorCode.GetCertificatesForActiveOrganizationFailed,
        message: 'Get certificates for an active organization api request failed',
        details: { innerErrorList },
    };
}

export type GetShareableCertificateDataFailed = ErrorObject<
    typeof ErrorCode.GetShareableCertificateDataFailed,
    {
        innerErrorList: Immutable<ErrorLike[]>;
        organizationID: UUID;
    }
>;

export function GetShareableCertificateDataFailed(
    innerErrorList: Immutable<ErrorLike[]>,
    organizationID: UUID,
): Immutable<GetShareableCertificateDataFailed> {
    return {
        name: 'GetShareableCertificateDataFailed',
        code: ErrorCode.GetShareableCertificateDataFailed,
        message: 'Get shareable certificate data api request failed',
        details: { innerErrorList, organizationID },
    };
}

export type SaveCertificatePhoneNumberNull = ErrorObject<
    typeof ErrorCode.SaveCertificatePhoneNumberNull,
    {}
>;

export function SaveCertificatePhoneNumberNull(): Immutable<SaveCertificatePhoneNumberNull> {
    return {
        name: 'SaveCertificatePhoneNumberNull',
        code: ErrorCode.SaveCertificatePhoneNumberNull,
        message: "Save certificate phone number is 'null' in save certificate repo call",
        details: {},
    };
}

export type CreateSelfServingCertificateFailed = ErrorObject<
    typeof ErrorCode.CreateSelfServingCertificateFailed,
    {
        innerErrorList: Immutable<ErrorLike[]>;
        selfServingCoverageList: SelfServingCertificateCoverageList;
    }
>;

export function CreateSelfServingCertificateFailed(
    innerErrorList: Immutable<ErrorLike[]>,
    selfServingCoverageList: SelfServingCertificateCoverageList,
): Immutable<CreateSelfServingCertificateFailed> {
    return {
        name: 'CreateSelfServingCertificateFailed',
        code: ErrorCode.CreateSelfServingCertificateFailed,
        message: 'Create self-serving certificate from api request failed',
        details: { innerErrorList, selfServingCoverageList },
    };
}

export type PreviewSelfServingCertificateFailed = ErrorObject<
    typeof ErrorCode.PreviewSelfServingCertificateFailed,
    {
        innerErrorList: Immutable<ErrorLike[]>;
        selfServingCoverageList: SelfServingCertificateCoverageList;
    }
>;

export function PreviewSelfServingCertificateFailed(
    innerErrorList: Immutable<ErrorLike[]>,
    selfServingCoverageList: SelfServingCertificateCoverageList,
): Immutable<PreviewSelfServingCertificateFailed> {
    return {
        name: 'PreviewSelfServingCertificateFailed',
        code: ErrorCode.PreviewSelfServingCertificateFailed,
        message: 'Preview self-serving certificate from api request failed',
        details: { innerErrorList, selfServingCoverageList },
    };
}

export type CertificateNotFound = ErrorObject<
    typeof ErrorCode.CertificateNotFound,
    {
        certificateID: UUID;
        innerErrorList: Immutable<ErrorLike[]>;
    }
>;

export function CertificateNotFound(
    certificateID: UUID,
    innerErrorList: Immutable<ErrorLike[]>,
): Immutable<CertificateNotFound> {
    return {
        name: 'CertificateNotFound',
        code: ErrorCode.CertificateNotFound,
        message: 'Certificate with the provided ID not found',
        details: { certificateID, innerErrorList },
    };
}

export type SelfServingCertificateHasMultipleDocuments = ErrorObject<
    typeof ErrorCode.SelfServingCertificateHasMultipleDocuments,
    {
        certificateDocumentList: Immutable<CertificateDocument[]>;
    }
>;

export function SelfServingCertificateHasMultipleDocuments(
    certificateDocumentList: Immutable<CertificateDocument[]>,
): Immutable<SelfServingCertificateHasMultipleDocuments> {
    return {
        name: 'SelfServingCertificateHasMultipleDocuments',
        code: ErrorCode.SelfServingCertificateHasMultipleDocuments,
        message: 'Self-serving certificate has more than one document',
        details: { certificateDocumentList },
    };
}

export type SelfServingCertificateRequestFailed = ErrorObject<
    typeof ErrorCode.SelfServingCertificateRequestFailed,
    {
        innerErrorList: Immutable<ErrorLike[]>;
        certificate: Certificate;
    }
>;

export function SelfServingCertificateRequestFailed(
    innerErrorList: Immutable<ErrorLike[]>,
    certificate: Certificate,
): Immutable<SelfServingCertificateRequestFailed> {
    return {
        name: 'SelfServingCertificateSpecialRequestFailed',
        code: ErrorCode.SelfServingCertificateRequestFailed,
        message: 'Send self serving certificate special request api request failed',
        details: { innerErrorList, certificate },
    };
}
