import { Money } from '@embroker/shotwell/core/types/Money';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { ColumnLayout, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';

export type BundleQuoteBindModalSummaryItems = {
    label: string;
    value: number | string;
}[];

export interface BundleQuoteBindModalSummaryProps {
    title: string;
    items: BundleQuoteBindModalSummaryItems;
}

export function BundleQuoteBindModalSummary({ title, items }: BundleQuoteBindModalSummaryProps) {
    return (
        <StackLayout gap="24">
            <Text style="heading 5" color="brand-400">
                {title}
            </Text>
            <StackLayout gap="8">
                {items.map((item) => (
                    <ColumnLayout key={item.label} center split="1">
                        <Text style="overline" color="brand-400" className="u-1/2">
                            {item.label}
                        </Text>
                        <Text className="u-1/2">
                            {typeof item.value === 'number' ? (
                                <MoneyDisplay
                                    value={Money.tryFromFloat(item.value)}
                                    fractionDigits={0}
                                />
                            ) : (
                                item.value
                            )}
                        </Text>
                    </ColumnLayout>
                ))}
            </StackLayout>
        </StackLayout>
    );
}
