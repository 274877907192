import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { useUseCaseTrigger } from '@embroker/shotwell/view/hooks/useUseCaseTrigger';
import {
    Button,
    Modal,
    ModalActions,
    ModalState,
    RadioGroup,
    StackLayout,
    ColumnLayout,
    Text,
    TextButton,
    TextAreaInput,
    CheckBox,
    StatusMessage,
    InputStatus,
} from '@embroker/ui-toolkit/v2';
import { RequestLossRuns } from '../../../useCases/RequestLossRuns';
import React, { useEffect, useState } from 'react';
import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';

interface EmailSelectModalProps {
    modal: ModalActions & ModalState;
    policyNumberList: string[];
    successCallback: () => void;
}

export function EmailSelectModal({
    modal,
    policyNumberList,
    successCallback,
}: EmailSelectModalProps) {
    const [useAlternateEmailAddress, setUseAlternateEmailAddress] = useState<boolean>(false);
    const [recipientListString, setRecipientListString] = useState<string>('');
    const [recipientListParsed, setRecipientListParsed] = useState<EmailAddress[]>([]);
    const [sendToSelf, setSendToSelf] = useState<boolean>(true);
    const [emailError, setEmailError] = useState<boolean>(false);
    const [useCaseError, setUseCaseError] = useState<boolean>(false);

    const {
        result,
        trigger: requestLossRunsTrigger,
        isLoading,
    } = useUseCaseTrigger(RequestLossRuns, {
        policyNumberList,
        recipientList: recipientListParsed,
        sendToSelf,
    });

    const parseEmails = () => {
        const rawEmailArray = recipientListString.split(',');
        const trimmedEmailArray = rawEmailArray
            .map((item) => item.trim())
            .filter((item) => item !== '');
        const validEmails: EmailAddress[] = [];
        for (const email of trimmedEmailArray) {
            const emailResult = EmailAddress.validate(email);
            if (isErr(emailResult)) {
                setEmailError(true);
                setRecipientListParsed([]);
                return;
            }

            validEmails.push(emailResult.value);
        }
        setRecipientListParsed(validEmails);
    };

    useEffect(() => {
        if (recipientListParsed.length > 0) {
            requestLossRunsTrigger();
        }
    }, [recipientListParsed, requestLossRunsTrigger]);

    useEffect(() => {
        if (result && modal.visible) {
            if (isErr(result)) {
                setUseCaseError(true);
                container.get<Logger>(Log).error(result.errors);
                return;
            }
            modal.hide();
            successCallback();
        }
    }, [modal, result, successCallback]);

    return (
        <Modal {...modal} size="small" dismissable={false}>
            <StackLayout gap="32">
                <StackLayout gap="24">
                    <Text style="heading 3">Which email should we use?</Text>
                    <Text>
                        Would you like your loss runs to be sent to your regular email address or to
                        a different one?
                    </Text>
                    <RadioGroup
                        disabled={isLoading}
                        value={useAlternateEmailAddress ? 'alternative' : 'regular'}
                        items={[
                            {
                                title: 'My regular email address',
                                value: 'regular',
                            },
                            {
                                title: 'A different email address',
                                value: 'alternative',
                            },
                        ]}
                        onChange={(event) => {
                            setRecipientListString('');
                            setEmailError(false);
                            setUseAlternateEmailAddress(event.target.value === 'alternative');
                        }}
                    />
                    {useAlternateEmailAddress && (
                        <React.Fragment>
                            <Text>Type in all of the recipients separated by a coma:</Text>
                            <StackLayout gap="12">
                                <TextAreaInput
                                    disabled={isLoading}
                                    value={recipientListString}
                                    onChange={(event) => {
                                        setEmailError(false);
                                        setRecipientListString(event.target.value);
                                    }}
                                    hasErrors={emailError}
                                />
                                {emailError && (
                                    <InputStatus
                                        messages={{ text: 'Invalid email list', status: 'error' }}
                                    />
                                )}
                            </StackLayout>
                            <CheckBox
                                disabled={isLoading}
                                checked={sendToSelf}
                                onChange={(event) => {
                                    setSendToSelf(event.target.checked);
                                }}
                            >
                                Send the loss run to me as well
                            </CheckBox>
                        </React.Fragment>
                    )}
                </StackLayout>
                {useCaseError ? (
                    <StatusMessage status="warning">
                        Sorry for the inconvenience, but we are unable to process your request at
                        this time. Please try later.
                    </StatusMessage>
                ) : null}
                <ColumnLayout gap="32">
                    <Button
                        disabled={
                            isLoading || (recipientListString === '' && useAlternateEmailAddress)
                        }
                        onClick={() => {
                            setUseCaseError(false);
                            if (useAlternateEmailAddress) {
                                parseEmails();
                            } else {
                                requestLossRunsTrigger();
                            }
                        }}
                    >
                        Confirm
                    </Button>
                    <TextButton disabled={isLoading} onClick={() => modal.hide()}>
                        Cancel
                    </TextButton>
                </ColumnLayout>
            </StackLayout>
        </Modal>
    );
}
