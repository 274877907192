import { PhoneNumber } from '@embroker/shotwell/core/types/PhoneNumber';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';

export enum TimeToReachPeriods {
    Morning = 'Morning',
    Afternoon = 'Afternoon',
    Evening = 'Evening',
}

export type TimeToReach = keyof typeof TimeToReachPeriods;

export interface ContactUsForm {
    readonly subject: string;
    readonly name: string;
    readonly email?: EmailAddress;
    readonly phoneNumber?: PhoneNumber;
    readonly timeToReach?: TimeToReach[];
    readonly message?: string;
}

export const ContactUsForm = {
    ...defineValidator<ContactUsForm>({
        subject: Joi.string().required(),
        name: Joi.string().required(),
        email: EmailAddress.schema.required(),
        phoneNumber: PhoneNumber.schema.optional(),
        message: Joi.string().optional(),
        timeToReach: Joi.array()
            .items(Joi.string().allow('Morning', 'Afternoon', 'Evening'))
            .optional(),
    }),
    create(contactUs: ContactUsForm) {
        return ContactUsForm.validate(contactUs);
    },
};
