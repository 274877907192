import {
    ModalActions,
    ModalState,
    StackLayout,
    Text,
    Modal,
    ScrollBox,
} from '@embroker/ui-toolkit/v2';
import React from 'react';

interface CowbellTermsConditionsModalProps {
    readonly modal: ModalActions & ModalState;
}

export function CowbellTermsConditionsModal({ modal }: CowbellTermsConditionsModalProps) {
    return (
        <Modal {...modal} size="medium" dismissable>
            <ScrollBox>
                <StackLayout gap="32">
                    <StackLayout gap="24">
                        <Text style="heading 3">
                            Cowbell Insurance Agency, LLC <br /> Terms and Conditions
                        </Text>
                        <Text style="body 1">
                            I understand that this Commercial Cyber Insurance Policy is produced by
                            Cowbell Insurance Agency and underwritten on behalf of National
                            Specialty Insurance Company and Spinnaker Insurance Company. This
                            Commercial Cyber Policy and any and all related policy documents will be
                            issued and administered by Cowbell Insurance Agency. Any payments made
                            in connection with the purchase of this Commercial Cyber Policy are made
                            to and maintained by Cowbell Insurance Agency.
                        </Text>
                        <Text style="body 1">
                            I affirm that I have read, and Cowbell Insurance Agency has advised me
                            to carefully read, the terms, conditions, limitations, exclusions and
                            any applicable endorsements of the commercial cyber insurance policy
                            that I am applying for, which have been made available to me. I affirm
                            that the information that I have provided throughout the application for
                            this insurance is, to the best of my knowledge, true, accurate, and
                            complete. I understand that any non-disclosure, misrepresentation or
                            non-payment of premium may result in cancellation of or non-renewal of
                            this policy.
                        </Text>
                        <Text style="body 1">
                            I understand and agree that my application for this Commercial Cyber
                            Policy will be submitted electronically and the policy documents related
                            to this insurance, including any notices and updates thereto
                            (collectively, the “Policy Documents”), will be made available to me
                            electronically. Cowbell Insurance Agency is authorized to send, and I
                            agree to accept delivery of, all Policy Documents electronically;
                            accordingly, I understand that I will not be receiving copies of the
                            Policy Documents by standard mail unless required by the department of
                            insurance of the state in which the Policy is issued. If I decide that I
                            no longer wish to receive the Policy Documents electronically, I shall
                            contact Cowbell Insurance Agency at support@cowbellcyber.ai to request
                            that the Policy Documents be sent to me by non-electronic delivery.
                        </Text>
                        <Text style="body 1">
                            It is my responsibility to provide Cowbell Insurance Agency with a valid
                            and current email address and to check that email address regularly for
                            important Policy Documents from Cowbell Insurance Agency.
                        </Text>
                    </StackLayout>
                </StackLayout>
            </ScrollBox>
        </Modal>
    );
}
