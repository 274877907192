import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { CreateProductDefinitionError, GetProductDefinitionError } from '../../errors';
import { Product } from '../../entities/Product';
import { OperationFailed } from '@embroker/shotwell/core/Error';
import { UUID } from '@embroker/shotwell/core/types/UUID';

/**
 * This is the repository used to work with ProductDefinition
 */

export interface ProductDefinitionRepository {
    /**
     * Fetches product definition with specified version
     * @returns Product definition
     * @returns GetProductDefinitionListError error if product definition request fails
     * @returns CreateProductDefinitionError error if `ProductDefinition` entity fails to be created from API response
     */
    get(
        version: UUID,
    ): AsyncResult<
        Product,
        GetProductDefinitionError | CreateProductDefinitionError | OperationFailed
    >;
}

export const ProductDefinitionRepository = Symbol('ProductDefinitionRepository');
