import {
    defineValidator,
    Joi,
    Schema,
    TypeChecker,
    Validator,
} from '@embroker/shotwell/core/validation/schema';

/**
 * Represents document object
 */
export interface Document {
    // id: UUID;
    /**
     * Is document certificate file
     */
    isCertificate: boolean;
    /**
     * Document name
     */
    name: string;
    /**
     * Relative file path
     */
    storageLocation: string;
    /**
     * Type of document
     */
    typeCode: string;
}

export interface DocumentValidator {
    /**
     * A Joi schema matching a valid Document object.
     */
    readonly schema: Schema.ObjectSchema<Document>;
    /**
     * Type predicate that checks if a given value can be used as Document object.
     *
     * Use this only to do early returns. It's recommended to use validate()
     * before using an unknown value as Document object as it normalizes the value
     * in addition to performing the same validation as check().
     */
    readonly check: TypeChecker<Document>;
    /**
     * Validates and normalizes the given value to a Document object.
     *
     * This should be used for all untrusted inputs to verify and, if required
     * (and possible), normalize the input.
     */
    readonly validate: Validator<Document>;
}

export const Document = {
    ...defineValidator<Document>({
        isCertificate: Joi.boolean(),
        name: Joi.string(),
        storageLocation: Joi.string(),
        typeCode: Joi.string(),
    }),
    create({ isCertificate, name, storageLocation, typeCode }: Document) {
        const document = { isCertificate, name, storageLocation, typeCode };
        return Document.validate(document);
    },
};
