import { CertificateCoverageInfo } from './CertificateCoverageInfo';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { Money } from '@embroker/shotwell/core/types/Money';

export interface CertificateCoverageUmbrellaExcess {
    coverageInfo: CertificateCoverageInfo;
    isUmbrella?: boolean;
    deductible?: Money;
    retention?: Money;
    claimsMade?: boolean;
    occur?: boolean;
}

export const CertificateCoverageUmbrellaExcess = {
    ...defineValidator<CertificateCoverageUmbrellaExcess>(
        Joi.object({
            coverageInfo: CertificateCoverageInfo.schema,
            isUmbrella: Joi.boolean().optional(),
            deductible: Money.schema.optional(),
            retention: Money.schema.optional(),
            claimsMade: Joi.boolean().optional(),
            occur: Joi.boolean().optional(),
        }).preferences({ presence: 'required' }),
    ),
    create(certificateCoverageUmbrellaExcess: CertificateCoverageUmbrellaExcess) {
        return CertificateCoverageUmbrellaExcess.validate(certificateCoverageUmbrellaExcess);
    },
};
