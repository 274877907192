import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Immutable } from '@embroker/shotwell/core/types';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { useAsyncTrigger } from '@embroker/shotwell/view/hooks/useAsyncTrigger';
import { execute } from '@embroker/shotwell/core/UseCase';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { ColumnLayout, StackLayout, Text, useModal, StatusMessage } from '@embroker/ui-toolkit/v2';

import { Quote as PremiumFinanceQuote } from '../../../payments/entities/Quote';
import { QuoteIndicationModal } from '../../../payments/view/components/modals/QuotesIndicationModal';
import { CoverageListInfo } from '../../../payments/types/PremiumFinanceIndicationInfo';
import { GetPremiumFinanceQuoteIndication } from '../../../payments/useCases/GetPremiumFinanceQuoteIndication';
import { BundleQuoteCoverageType } from '../../types/BundleQuoteCoverage';
import { Link } from '../../../view/components';
import { BundleQuoteCardLayout } from './BundleQuoteCardLayout.view';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { GetConfig } from '../../../payments/useCases/GetConfig';
import { AppContext } from '../../../view/AppContext';
import { hasRole } from '../../../userOrg/entities/Session';

function buildCoverageListInfo(selectedCoverages: Immutable<BundleQuoteCoverageType[]>) {
    return selectedCoverages.reduce<CoverageListInfo>((coverageListInfo, bundleQuoteCoverage) => {
        if (bundleQuoteCoverage.quote != undefined) {
            {
                coverageListInfo.push({
                    applicationId: bundleQuoteCoverage.quote.applicationId,
                    quoteId: bundleQuoteCoverage.quote.id,
                });
            }
        }
        return coverageListInfo;
    }, []);
}

export interface BundleQuotePaymentOptionsCardProps {
    selectedCoverages: Immutable<BundleQuoteCoverageType[]>;
    quoteEffectiveDate: Date;
}

export function BundleQuotePaymentOptionsCard({
    selectedCoverages,
    quoteEffectiveDate,
}: BundleQuotePaymentOptionsCardProps) {
    const quarterlyInstallments = 3;
    const monthlyInstallments = 10;
    const [
        hasUserRequestedPremiumFinanceIndicationQuote,
        setHasUserRequestedPremiumFinanceIndicationQuote,
    ] = useState(false);
    const [premiumFinanceIndicationQuarterlyQuote, setPremiumFinanceIndicationQuarterlyQuote] =
        useState<undefined | Immutable<EntityProps<PremiumFinanceQuote>>>();
    const [premiumFinanceIndicationMonthlyQuote, setPremiumFinanceIndicationMonthlyQuote] =
        useState<undefined | Immutable<EntityProps<PremiumFinanceQuote>>>();
    const [premiumFinanceIndicationError, setPremiumFinanceIndicationError] =
        useState<boolean>(false);

    const { result: config } = useUseCase(GetConfig);

    const { activeSession } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');

    const useAscend = !isBroker && config && isOK(config) ? config.value.useAscend : false;

    const quoteIndicationModal = useModal();

    const coverageListInfo: CoverageListInfo = useMemo(
        () => buildCoverageListInfo(selectedCoverages),
        [selectedCoverages],
    );

    const {
        trigger: monthlyTrigger,
        isLoading: monthlyIsLoading,
        result: monthlyResult,
    } = useAsyncTrigger(async (signal) => {
        return await execute(GetPremiumFinanceQuoteIndication, {
            coverageListInfo: coverageListInfo,
            numberOfInstallments: monthlyInstallments,
            startDate: quoteEffectiveDate,
            abortSignal: signal,
        });
    });

    const {
        trigger: quarterlyTrigger,
        isLoading: quarterlyIsLoading,
        result: quarterlyResult,
    } = useAsyncTrigger(async (signal) => {
        return await execute(GetPremiumFinanceQuoteIndication, {
            coverageListInfo: coverageListInfo,
            numberOfInstallments: quarterlyInstallments,
            startDate: quoteEffectiveDate,
            abortSignal: signal,
        });
    });

    const isNewRequestLoading = monthlyIsLoading || quarterlyIsLoading;

    const abortAndTriggerUseCases = useCallback(() => {
        monthlyTrigger();
        quarterlyTrigger();
    }, [monthlyTrigger, quarterlyTrigger]);

    useEffect(() => {
        abortAndTriggerUseCases();
        setPremiumFinanceIndicationError(false);
        setHasUserRequestedPremiumFinanceIndicationQuote(false);
    }, [coverageListInfo, abortAndTriggerUseCases]);

    useEffect(() => {
        if (monthlyResult == undefined || quarterlyResult == undefined) {
            return;
        }

        if (isErr(monthlyResult) || isErr(quarterlyResult)) {
            setPremiumFinanceIndicationError(true);
            return;
        }
        setPremiumFinanceIndicationQuarterlyQuote(quarterlyResult.value.quote);
        setPremiumFinanceIndicationMonthlyQuote(monthlyResult.value.quote);
    }, [monthlyResult, quarterlyResult]);

    const handlePreview: React.MouseEventHandler<HTMLAnchorElement> | undefined = (ev) => {
        ev.preventDefault();
        setHasUserRequestedPremiumFinanceIndicationQuote(true);
        setPremiumFinanceIndicationError(false);
        if (!premiumFinanceIndicationError) {
            quoteIndicationModal.show();
            return;
        }
        abortAndTriggerUseCases();
        quoteIndicationModal.show();
    };

    useEffect(() => {
        if (premiumFinanceIndicationError) {
            quoteIndicationModal.hide();
        }
    }, [premiumFinanceIndicationError, quoteIndicationModal]);

    return (
        <React.Fragment>
            <BundleQuoteCardLayout>
                <ColumnLayout gap="4">
                    <StackLayout gap="8">
                        <Text style="heading 4">Payment Options</Text>
                        <StackLayout gap="20">
                            <Text style="body 2" as="p" color="ui-500">
                                Did you know you can pay with your bank or credit card, or you can
                                apply for premium financing? Simply choose your method of payment
                                after binding your policy.
                            </Text>
                            {!useAscend && (
                                <Link href="" onClick={handlePreview}>
                                    Preview a financing quote
                                </Link>
                            )}
                        </StackLayout>
                    </StackLayout>

                    <span />
                </ColumnLayout>
            </BundleQuoteCardLayout>

            {premiumFinanceIndicationError && hasUserRequestedPremiumFinanceIndicationQuote ? (
                <StatusMessage status="error">
                    Sorry for inconvenience, Premium Finance Indication is not available at the
                    moment.
                </StatusMessage>
            ) : null}

            <QuoteIndicationModal
                minEffectiveDate={quoteEffectiveDate}
                modal={quoteIndicationModal}
                quotes={{
                    quote3Months: premiumFinanceIndicationQuarterlyQuote,
                    quote10Months: premiumFinanceIndicationMonthlyQuote,
                }}
                isNewRequestLoading={isNewRequestLoading}
            />
        </React.Fragment>
    );
}
