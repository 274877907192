import { map, mount, route } from 'navi';
import * as React from 'react';
import { AppContext } from '../../../view/AppContext';
import { RouteDefinitions } from '../../../view/routes/Route';
import { MyCertificatesPage } from '../components/MyCertificates';

import { SelfServingFlowWrapper } from '../components/selfServing/SelfServingFlowWrapper';

export const routes: RouteDefinitions<AppContext> = {
    '/certificates': mount<AppContext>({
        '/': route({ view: MyCertificatesPage, title: 'Embroker | Certificates' }),
        '/get-certificate': map(async (req, context: AppContext) => {
            return route({
                view: <SelfServingFlowWrapper />,
                title: 'Embroker | Certificates',
                data: { fullscreen: true },
            });
        }),
    }),
};
