import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { Applicant } from '../../entities/Applicant';

export interface ApplicantRepository {
    getApplicant(): AsyncResult<Applicant, InvalidArgument | OperationFailed | UnknownEntity>;
    update(
        applicant: Applicant,
    ): AsyncResult<Applicant, InvalidArgument | OperationFailed | UnknownEntity>;
}

export const ApplicantRepository = Symbol('ApplicantRepository');
