import React from 'react';

import { Immutable } from '@embroker/shotwell/core/types';
import { Lob } from '../../../types/Lob';
import { StackLayout, Text } from '@embroker/ui-toolkit/v2';
import { CoverageSectionTable } from './CoverageSectionTable';
import { DriversSection } from './DriversSection';
import { AutoLiabilitySection } from '../../../types/AutoLiabilitySection';
import { VehiclesSection } from './VehiclesSection';
import { CoverageSectionLimitAndRetention } from './CoverageSectionLimitAndRetention';
import { LineOfBusinessCodeListItem } from '@embroker/shotwell-api/enums';

export interface AutoCoverageSectionBodyProps {
    lob: Immutable<Lob>;
    lineOfBusiness: LineOfBusinessCodeListItem;
}

export function AutoCoverageSectionBody({ lob, lineOfBusiness }: AutoCoverageSectionBodyProps) {
    return (
        <StackLayout gap="16">
            <CoverageSectionLimitAndRetention lob={lob} />
            <StackLayout gap="none">
                <Text style="heading 5">What's covered</Text>
                <CoverageSectionTable
                    lob={lob}
                    customRetentionWording="DEDUCTIBLE"
                    lineOfBusiness={lineOfBusiness}
                />
            </StackLayout>
            <VehiclesSection
                vehicleList={(lob.liabilitySection as AutoLiabilitySection).vehicleList}
            />
            <DriversSection
                driverList={(lob.liabilitySection as AutoLiabilitySection).driverList}
            />
        </StackLayout>
    );
}
