import React, { useMemo } from 'react';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { FormViewLayout } from '../../../../view/components/FormViewLayout.view';
import { OnboardingPageProps } from './OnboardingFlowWrapper';
import { PageLayout, StackLayout, StatusMessage, Text, RadioGroup } from '@embroker/ui-toolkit/v2';
import { OnboardingPrefillQuestionnaireData } from '../../../types/OnboardingPrefillQuestionnaireData';
import { Success, isOK } from '@embroker/shotwell/core/types/Result';
import { GetApplicant } from '../../../../shopping/useCases/GetApplicant';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';

interface LawVerticalRefinementFormData {
    hasEmployees: 'yes' | 'no';
    locationType: 'home_office' | 'office_rented' | 'office_owned';
    usesEmployeeOrRentalVehicles: 'yes' | 'no';
}

const createLawVerticalRefinementForm = ({
    onCompleteOnboardingStep,
}: {
    onCompleteOnboardingStep: (questionnaireData: OnboardingPrefillQuestionnaireData) => void;
}) => {
    const LawVerticalRefinementForm = createForm<LawVerticalRefinementFormData>({
        fields: {
            hasEmployees: {
                type: 'text',
                validator: Joi.string().required(),
                formatValidationError(error) {
                    return 'You must select an option';
                },
            },
            locationType: {
                type: 'text',
                validator: Joi.string().required(),
                formatValidationError(error) {
                    return 'You must select an option';
                },
            },
            usesEmployeeOrRentalVehicles: {
                type: 'text',
                validator: Joi.string().required(),
                formatValidationError(error) {
                    return 'You must select an option';
                },
            },
        },
        submit: async ({ hasEmployees, locationType, usesEmployeeOrRentalVehicles }) => {
            onCompleteOnboardingStep({
                has_employees: hasEmployees === 'yes',
                location_type: locationType,
                uses_employee_or_rental_vehicles: usesEmployeeOrRentalVehicles === 'yes',
            });
            return Success();
        },
        formatSubmitErrors(errors) {
            if (errors.length === 0) {
                return [];
            }
            container
                .get<Logger>(Log)
                .warn(`User onboarding - NAICs code refinement page formatSubmitErrors: ${errors}`);
            return ['Sorry, something went wrong. Please try again later.'];
        },
    });
    return LawVerticalRefinementForm;
};

export function LawVerticalRefinmentQuestion({
    questionnaireData,
    onCompleteOnboardingStep,
    onBackClicked,
}: OnboardingPageProps) {
    const LawVerticalRefinementForm = useMemo(
        () => createLawVerticalRefinementForm({ onCompleteOnboardingStep }),
        [onCompleteOnboardingStep],
    );
    const hasEmployeesPrefilled =
        questionnaireData?.has_employees === undefined
            ? undefined
            : questionnaireData?.has_employees
            ? 'yes'
            : 'no';

    let locationType = questionnaireData?.location_type;
    if (locationType == 'coworking') {
        // onboarding flow doesn't use 'coworking' as answer
        locationType = undefined;
    }

    let usesEmployeeOrRentalVehicles: 'yes' | 'no' | undefined;
    if (questionnaireData?.uses_employee_or_rental_vehicles != undefined) {
        usesEmployeeOrRentalVehicles = questionnaireData?.uses_employee_or_rental_vehicles
            ? 'yes'
            : 'no';
    }

    const { fields, value, messages, setValue, submit } = useForm(LawVerticalRefinementForm, {
        hasEmployees: hasEmployeesPrefilled,
        locationType,
        usesEmployeeOrRentalVehicles,
    });

    const { result: getApplicantResult } = useUseCase(GetApplicant);
    const mainLocation =
        getApplicantResult &&
        isOK(getApplicantResult) &&
        getApplicantResult.value.applicant.headquarters.addressLine1;

    const YesOrNoOptions: { title: string; value: 'yes' | 'no' }[] = [
        {
            title: 'Yes',
            value: 'yes',
        },
        {
            title: 'No',
            value: 'no',
        },
    ];

    const LocationOptions: {
        title: string;
        value: 'home_office' | 'office_rented' | 'office_owned';
    }[] = [
        {
            title: 'This is my home office location',
            value: 'home_office',
        },
        {
            title: 'I rent an office at this location',
            value: 'office_rented',
        },
        {
            title: 'I own this location',
            value: 'office_owned',
        },
    ];

    const disableSubmit = !(
        fields.hasEmployees.props.value &&
        fields.locationType.props.value &&
        fields.usesEmployeeOrRentalVehicles.props.value
    );

    return (
        <PageLayout.Section>
            <FormViewLayout
                title="Tell us a few more details about your business so we can help you find the right protection."
                errorMessages={messages}
                disableSubmit={disableSubmit}
                onFormSubmit={submit}
                handleBack={onBackClicked}
            >
                <StackLayout gap="16">
                    <Text data-e2e="law-vertical-refinement-has-employee-question">
                        Does your firm have at least one employee?
                    </Text>
                    <RadioGroup
                        data-e2e="law-vertical-refinement-has-employee-radio-group"
                        onChange={(e) => {
                            setValue({
                                ...value,
                                hasEmployees: e?.target.value,
                            });
                        }}
                        items={YesOrNoOptions}
                        value={fields.hasEmployees.props.value}
                    />
                    <StatusMessage
                        status="helptext"
                        data-e2e="law-vertical-refinement-status-message"
                    >
                        &quot;Employee&quot; refers to any staff on payroll.
                    </StatusMessage>
                    <Text data-e2e="law-vertical-refinement-workspace-question">
                        Which best describes your workspace at {mainLocation}?
                    </Text>
                    <RadioGroup
                        data-e2e="law-vertical-refinement-workspace-radio-group"
                        onChange={(e) => {
                            setValue({
                                ...value,
                                locationType: e?.target.value,
                            });
                        }}
                        items={LocationOptions}
                        value={fields.locationType.props.value}
                    />
                    <Text data-e2e="law-vertical-refinement-vehicles-question">
                        Does anyone at the firm use employee-owned or rental vehicles for work
                        purposes (driving to court or to meet clients, etc.)?
                    </Text>
                    <RadioGroup
                        data-e2e="law-vertical-refinement-vehicles-radio-group"
                        onChange={(e) => {
                            setValue({
                                ...value,
                                usesEmployeeOrRentalVehicles: e?.target.value,
                            });
                        }}
                        items={YesOrNoOptions}
                        value={fields.usesEmployeeOrRentalVehicles.props.value}
                    />
                </StackLayout>
            </FormViewLayout>
        </PageLayout.Section>
    );
}
