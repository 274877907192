import { APIDocumentRepository } from './repositories/DocumentRepository/APIDocumentRepository';
import { ContainerModule } from '@embroker/shotwell/core/di';
import { DocumentRepository } from './repositories/DocumentRepository';
import { GetDocumentUrl } from './useCases/GetDocumentUrl';
import { GetFile } from './useCases/GetFile';
import { GetFileList } from './useCases/GetFileList';
import { Module } from '../Module';
import { UploadFiles } from './useCases/UploadFiles';
import { SaveFiles } from './useCases/SaveFiles';
import { GenerateAppFileUrl } from './useCases/GenerateAppFileUrl';
import { GetApplicationDocumentFileKey } from './useCases/GetApplicationDocumentFileKey';
import { DeleteFile } from './useCases/DeleteFile';

export const DocumentModule: Module = {
    container: new ContainerModule((bind) => {
        bind<UploadFiles>(UploadFiles.type).to(UploadFiles).inSingletonScope();
        bind<GetFile>(GetFile.type).to(GetFile).inSingletonScope();
        bind<GetFileList>(GetFileList.type).to(GetFileList).inSingletonScope();
        bind<DocumentRepository>(DocumentRepository).to(APIDocumentRepository).inSingletonScope();
        bind<GetDocumentUrl>(GetDocumentUrl.type).to(GetDocumentUrl).inSingletonScope();
        bind<SaveFiles>(SaveFiles.type).to(SaveFiles).inSingletonScope();
        bind<DeleteFile>(DeleteFile.type).to(DeleteFile).inSingletonScope();
        bind<GenerateAppFileUrl>(GenerateAppFileUrl.type).to(GenerateAppFileUrl).inSingletonScope();
        bind<GetApplicationDocumentFileKey>(GetApplicationDocumentFileKey.type)
            .to(GetApplicationDocumentFileKey)
            .inSingletonScope();
    }),
};
