import {
    defineEntityValidator,
    entity,
    Entity,
    UpdateEntityProps,
} from '@embroker/shotwell/core/entity/Entity';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { CertificateCoverage } from '../types/CertificateCoverage';
import { CertificateDocument } from '../types/CertificateDocument';
import { CertificateEntityRole } from '../types/CertificateEntityRole';
import { CertificateProducer } from '../types/CertificateProducer';
import { CertificateTemplateType, CertificateTemplateTypeList } from '../types/enums';

export interface CertificateTemplate extends Entity {
    /**
     * Indicates if additional insured are enabled in a certificate template
     * If they are, user will be able to input additional insured
     * when they want to issue a certificate using certificate template
     */
    readonly isAdditionalInsuredEnabled: boolean;
    /**
     * Indicates if certificate template is visible to the platform user (not admin)
     */
    readonly isCertificateTemplateActive: boolean;
    /**
     * Date when certificate template was created
     */
    readonly createdAt: Date;
    /**
     * Name of the certificate template
     */
    readonly name: string;
    readonly templateType: CertificateTemplateType;
    /**
     * Id of the certificate structure used to store main data of the certificate template like:
     * 1. Coverages
     * 2. Certificate owner
     */
    readonly certificateId: UUID;
    /**
     * Owner of the certificate template
     */
    readonly certificateOwner: CertificateEntityRole;
    /**
     * Certificate producer
     */
    readonly certificateProducer: CertificateProducer;
    /**
     * List of certificate coverages
     */
    readonly coverageList: CertificateCoverage[];
    /**
     * Name of the additional insured entity
     */
    readonly additionalInsuredName?: string;
    /**
     * Custom wording added by BST which will be printed on the certificate PDF
     */
    readonly customWording?: string;
    /**
     * Documents attached to the certificate template
     * They will be concatenated to the certificate PDF page when certificate is produced
     */
    readonly documentList: CertificateDocument[];

    /**
     * Updates certificate template entity
     * This will add CertificateTemplateUpdated event to the entity's event list
     * @param certificateTemplate
     */
    update(certificateTemplate: UpdateEntityProps<CertificateTemplate>): void;
}

interface CertificateTemplateUpdated extends DomainEvent {
    readonly origin: 'certificate';
    readonly name: 'Updated';
    readonly certificateTemplateId: UUID;
}

export const CertificateTemplate = entity<CertificateTemplate>({
    validator: defineEntityValidator<CertificateTemplate>({
        isAdditionalInsuredEnabled: Joi.boolean().required(),
        isCertificateTemplateActive: Joi.boolean().required(),
        createdAt: Joi.date().required(),
        name: Joi.string().required(),
        templateType: Joi.string().valid(...CertificateTemplateTypeList),
        certificateId: UUID.schema.required(),
        certificateOwner: CertificateEntityRole.schema.required(),
        certificateProducer: CertificateProducer.schema.required(),
        coverageList: Joi.array().items(CertificateCoverage.schema).required(),
        additionalInsuredName: Joi.string().optional().allow(''),
        customWording: Joi.string().optional().allow(''),
        documentList: Joi.array().items(CertificateDocument.schema).required(),
    }),
    update(newCertificateTemplate) {
        Object.assign(this.props, newCertificateTemplate);
        this.createEvent<CertificateTemplateUpdated>('Updated', {
            certificateTemplateId: this.id,
        });
    },
});
