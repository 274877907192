import React from 'react';
import {
    BoxLayout,
    CenterLayout,
    CoverLayout,
    Image,
    StackLayout,
    Text,
} from '@embroker/ui-toolkit/v2';

export function ReviewApplicationThankYou() {
    return (
        <CoverLayout>
            <StackLayout split="1">
                <BoxLayout gap="56">
                    <StackLayout gap="32">
                        <CenterLayout>
                            <Image inline={false} name="logo" />
                        </CenterLayout>
                        <CenterLayout centerText>
                            <StackLayout gap="32">
                                <Text as="h1" style="heading 1">
                                    Thank you!
                                </Text>
                                <Text as="p" style="body 1">
                                    We've sent an email to your broker notifying them of the changes
                                    you had made to this application.
                                    <br />
                                    After a review they will get back in touch with you, so you can
                                    proceed with the signing.
                                </Text>
                            </StackLayout>
                        </CenterLayout>
                    </StackLayout>
                </BoxLayout>
            </StackLayout>
        </CoverLayout>
    );
}
