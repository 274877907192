import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Immutable } from '@embroker/shotwell/core/types';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import {
    CardLayout,
    StackLayout,
    StatusLabel,
    Table,
    Text,
    useStaticTable,
} from '@embroker/ui-toolkit/v2';
import React, { useMemo } from 'react';
import { Application } from '../../../../shopping/entities/Application';
import {
    AwaitingBind,
    AwaitingQuotes,
    Bound,
    ClearanceFailed,
    ClientReservation,
    DeclinedByCarrier,
    InCreation,
    NotEligible,
    QuestionnaireInProgress,
    QuoteExpired,
    QuotesReady,
    Referred,
    AppTypeCode,
    AppTypeCodeListEmbrokerCrime,
    AppTypeCodeListEmbrokerCyber,
    AppTypeCodeListEmbrokerExcess,
    AppTypeCodeListESP,
    AppTypeCodeListEverestLawyersProfessionalLiability,
    AppTypeCodeListPCoML,
} from '@app/shopping/types/enums';
import { BrokerQuoteActionButton } from '../BrokerQuoteActionButton';
import {
    brokerageCodeToStatusLabel,
    BrokerStatusToColorMap,
} from '../quotes/BrokerQuotesTableData';
import { getCoverageLabel } from './BrokerClientPage';

export interface ClientPageQuotesProps {
    applications: Immutable<Array<EntityProps<Application>>>;
    organizationId: UUID;
    excessRenewalsEnabled: boolean;
}

export function ClientPageQuotes({
    applications,
    organizationId,
    excessRenewalsEnabled,
}: ClientPageQuotesProps) {
    const items = useMemo(
        () =>
            toBrokerOrganizationTableData({
                applications,
                organizationId,
                excessRenewalsEnabled,
            }),
        [applications, organizationId, excessRenewalsEnabled],
    );

    const { visibleItems } = useStaticTable<BrokerOrganizationTableData>({
        items,
        itemsPerPage: items.length,
    });

    const noItems = visibleItems.length === 0;

    return (
        <StackLayout gap="24">
            <Text style="heading 4">Previous Submissions</Text>
            <CardLayout>
                <CardLayout.Body>
                    {!noItems ? (
                        <Table>
                            <Table.Header>
                                <Table.Column>Coverage type</Table.Column>
                                <Table.Column>Status</Table.Column>
                                <Table.Column>Submit date</Table.Column>
                                <Table.Column>Premium</Table.Column>
                                <Table.Column>Action</Table.Column>
                            </Table.Header>
                            <Table.Body>
                                {visibleItems.map((row) => (
                                    <Table.Row key={row.id as UUID}>
                                        <Table.Cell>{row.coverageType}</Table.Cell>
                                        <Table.Cell>{row.status}</Table.Cell>
                                        <Table.Cell>{row.submitDate}</Table.Cell>
                                        <Table.Cell>{row.premium}</Table.Cell>
                                        <Table.Cell>{row.action}</Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    ) : (
                        <Text style="body 1">Client does not have quotes yet.</Text>
                    )}
                </CardLayout.Body>
            </CardLayout>
        </StackLayout>
    );
}

function toBrokerOrganizationTableData({
    applications,
    organizationId,
    excessRenewalsEnabled,
}: {
    applications: Immutable<Array<EntityProps<Application>>>;
    organizationId: UUID;
    excessRenewalsEnabled: boolean;
}): BrokerOrganizationTableData[] {
    return applications
        .filter(applicationToDisplayFilter)
        .sort((a, b) => {
            if (a.submittedDate === null) {
                return -1;
            } else if (b.submittedDate === null) {
                return 1;
            } else return a.submittedDate > b.submittedDate ? 1 : -1;
        })
        .map((row) => {
            return {
                id: row.id,
                coverageType: <Text style="label 1">{getCoverageLabel(row.appType)}</Text>,
                status: (
                    <StatusLabel fixed type={BrokerStatusToColorMap[row.status]}>
                        {brokerageCodeToStatusLabel(row.status)}
                    </StatusLabel>
                ),
                submitDate: row.submittedDate ? (
                    <DateDisplay format="MMM d, yyyy" value={row.submittedDate} />
                ) : (
                    <div>N/A</div>
                ),
                premium:
                    row.lastQuote?.totalPremium && row.status !== Referred ? (
                        <MoneyDisplay value={row.lastQuote?.totalPremium} />
                    ) : (
                        <div>N/A</div>
                    ),
                action:
                    row.creationType === ClientReservation ? null : (
                        <BrokerQuoteActionButton
                            applicationId={row.id}
                            hasQuotes={row.hasQuotes}
                            quotingEngine={row.quotingEngine ?? undefined}
                            ineligibilityReasons={row.ineligibilityReasons ?? undefined}
                            status={row.status}
                            organizationId={organizationId}
                            isPristine={row.isPristine}
                            showEditApplication={showEditAppButton(
                                row.appType,
                                excessRenewalsEnabled,
                            )}
                        />
                    ),
            };
        });
}

const BrokerAppTypes = [
    AppTypeCodeListESP,
    AppTypeCodeListPCoML,
    AppTypeCodeListEverestLawyersProfessionalLiability,
    AppTypeCodeListEmbrokerCrime,
    AppTypeCodeListEmbrokerCyber,
    AppTypeCodeListEmbrokerExcess,
];

const BrokerAppStatuses = [
    QuestionnaireInProgress,
    AwaitingQuotes,
    QuotesReady,
    NotEligible,
    DeclinedByCarrier,
    QuoteExpired,
    Referred,
    ClearanceFailed,
    InCreation,
    Bound,
    AwaitingBind,
];

function applicationToDisplayFilter(application: Immutable<EntityProps<Application>>): boolean {
    return (
        BrokerAppTypes.includes(application.appType) &&
        BrokerAppStatuses.includes(application.status)
    );
}

interface BrokerOrganizationTableData extends Record<string, unknown> {
    id: UUID;
    coverageType: React.ReactNode;
    status: React.ReactNode;
    submitDate: React.ReactNode;
    premium: React.ReactNode;
    action: React.ReactNode;
}

function showEditAppButton(appType: AppTypeCode, excessRenewalsEnabled: boolean) {
    if (appType === 'AppTypeCodeListEmbrokerExcess' && !excessRenewalsEnabled) {
        return false;
    }

    return true;
}
