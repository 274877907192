import { mount, redirect, route } from 'navi';
import { AppContext } from '../../../view/AppContext';
import { RouteDefinitions } from '../../../view/routes/Route';
import { ClaimsHistoryTab } from '../components/ClaimsHistoryTab';
import { FileClaimTab } from '../components/FileClaimTab';
import { ClaimRequestTab } from '../components/ClaimRequestTab';

export const routes: RouteDefinitions<AppContext> = {
    '/claims': mount<AppContext>({
        '/': redirect('claims-history'),
        '/claims-history': route({ view: ClaimsHistoryTab, title: 'Claims' }),
        '/filing-list': route({ view: FileClaimTab, title: 'Claims' }),
        '/claim-request': route({ view: ClaimRequestTab, title: 'Claims' }),
    }),
};
