import { FormData, OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { Button, ColumnLayout, TextButton } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { WizardForm } from '../../../view/hooks/useWizardForm';
import { Quote } from '../../entities/Quote';

interface DefaultPageNavigationProps<T extends FormData, Q extends Quote> {
    quote: Q;
    isSubmitting: boolean;
    hasNext: boolean;
    next: WizardForm<OpaqueForm<T>>['next'];
    hasPrevious: boolean;
    previous: WizardForm<OpaqueForm<T>>['previous'];
    nextButtonLabel: string;
    disableNextButton?: boolean;
    handlePurchase(): void;
    status: WizardForm<OpaqueForm<T>>['status'];
}

export function DefaultPageNavigation<T extends FormData, Q extends Quote>({
    quote,
    isSubmitting,
    hasNext,
    next,
    handlePurchase,
    hasPrevious,
    nextButtonLabel,
    previous,
    disableNextButton,
    status,
}: DefaultPageNavigationProps<T, Q>) {
    function isNextButtonDisabled(): boolean {
        const quoteNotSignedAndLastStep = quote.status != 'signed' && !hasNext;
        return isSubmitting || quoteNotSignedAndLastStep || (disableNextButton ?? false);
    }

    return (
        <ColumnLayout split="-1">
            <Button disabled={isNextButtonDisabled()} onClick={hasNext ? next : handlePurchase}>
                {nextButtonLabel}
            </Button>
            <TextButton
                size="small"
                as="button"
                className={hasPrevious ? '' : 'u-hidden'}
                icon="bold-caret-left"
                disabled={isSubmitting}
                onClick={previous}
            >
                Back to options
            </TextButton>
        </ColumnLayout>
    );
}
