import { UUID } from '@embroker/shotwell/core/types/UUID';
import {
    AppTypeCode,
    AppTypeCodeList,
    InsuranceApplicationCreationTypeCode,
    InsuranceApplicationCreationTypeCodeList,
    InsuranceApplicationStatusCode,
    InsuranceApplicationStatusCodeList,
} from './enums';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { valueObject } from '@embroker/shotwell/core/types/ValueObject';
import { Immutable } from '@embroker/shotwell/core/types';

export interface AppDetails {
    appId: UUID;
    appType: AppTypeCode;
    creationType: InsuranceApplicationCreationTypeCode;
    appStatus: InsuranceApplicationStatusCode;
}

export const AppDetails = valueObject({
    ...defineValidator<AppDetails>({
        appId: UUID.schema,
        appType: Joi.string().valid(...AppTypeCodeList),
        creationType: Joi.string().valid(...InsuranceApplicationCreationTypeCodeList),
        appStatus: Joi.string().valid(...InsuranceApplicationStatusCodeList),
    }),

    getAppTypes(
        appDetailsList: Immutable<AppDetails[]> | undefined,
        { creationType }: { creationType?: InsuranceApplicationCreationTypeCode } = {},
    ): AppTypeCode[] | undefined {
        const list = creationType
            ? appDetailsList?.filter(
                  (item) =>
                      item.creationType === creationType &&
                      item.appStatus !== 'InsuranceApplicationStatusCodeListDeleted',
              )
            : appDetailsList;
        return list?.map((item) => item.appType);
    },
    getBundleAppType(appDetailsList: Immutable<AppDetails[]> | undefined): AppTypeCode | undefined {
        return appDetailsList?.find(
            (item) => item.creationType === 'InsuranceApplicationCreationTypeCodeListBundle',
        )?.appType;
    },
});
