import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { useUseCaseTrigger } from '@embroker/shotwell/view/hooks/useUseCaseTrigger';
import React, { useContext, useEffect, useState } from 'react';
import { hasRole } from '../../../../../userOrg/entities/Session';
import { AppContext } from '../../../../../view/AppContext';
import { GetBoundPoliciesData } from '../../../../useCases/GetBoundPoliciesData';
import { BarChartReport } from './BarChartReport';
import { BarChartOptions } from './ChartModule';
import { BrokerReportProps, ChartReadyData, Report } from './ReportDefinitions';

const chartOptions = Report.defaults.getDefaultOptions();

export function PoliciesBoundPerWeekReport({
    title,
    width = 980,
    height = 400,
    className,
}: BrokerReportProps) {
    const { result, isLoading, trigger } = useUseCaseTrigger(
        GetBoundPoliciesData,
        Report.defaults.defaultIndexOptions,
    );

    const { activeSession } = useContext(AppContext);
    const isAdmin = hasRole(activeSession, 'admin');

    const [displayReport, setDisplayReport] = useState<boolean>(
        !isAdmin && process.env.NODE_ENV !== 'development',
    );

    const [data, setData] = useState<ChartReadyData | undefined>(undefined);
    const [dataEmpty, setDataEmpty] = useState<boolean>(true);

    useEffect(() => {
        if (!displayReport) {
            return;
        }
        trigger();
    }, [displayReport, trigger]);

    useEffect(() => {
        if (result === undefined) {
            return;
        }
        if (!isErr(result)) {
            setDataEmpty(result.value.dataPoints.length == 0);
            setData(
                Report.dataFormatting.formChartDataFromDataPoints(
                    result.value.dataPoints,
                    result.value.groupNames,
                    Report.defaults.defaultIndexOptions,
                ),
            );
        } else {
            container.get<Logger>(Log).error(result.errors);
        }
    }, [result, setDataEmpty, setData]);

    return (
        <BarChartReport
            chartOptions={chartOptions as BarChartOptions}
            chartReadyData={data}
            title={title}
            className={className}
            height={height}
            width={width}
            reportEmpty={dataEmpty}
            reportEnabled={displayReport}
            enableReportCallback={() => {
                setDisplayReport(true);
            }}
            reportLoading={isLoading}
        />
    );
}
