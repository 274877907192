import { inject } from '@embroker/shotwell/core/di';
import { OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import espSupplementalQuestionnaireFormEngine from './forms/esp_supplemental_questionnaire';
import { FormElement } from '@embroker/service-app-engine';
import { SupplementalQuestionnaireRepository } from '../esp/repositories/SupplementalQuestionnaireRepository';
import { toFormEngineInput } from '../../shopping/useCases/GetFormEngineContext';
import { addYears, isAfter } from 'date-fns';

export interface GetSupplementalQuestionnaireFormEngineRequest {
    applicationId: UUID;
    isBroker: boolean;
}

export interface GetSupplementalQuestionnaireFormEngineResponse {
    formEngine: FormElement;
}

export interface GetSupplementalQuestionnaireFormEngine extends UseCase {
    execute(
        request: GetSupplementalQuestionnaireFormEngineRequest,
    ): AsyncResult<GetSupplementalQuestionnaireFormEngineResponse, OperationFailed>;
}

class GetSupplementalQuestionnaireFormEngineUseCase
    extends UseCase
    implements GetSupplementalQuestionnaireFormEngine
{
    public static type = Symbol(
        'higherLimitsSupplementalQuestionnaire/GetSupplementalQuestionnaireFormEngine',
    );

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(SupplementalQuestionnaireRepository)
        private supplementalQuestionnaireRepository: SupplementalQuestionnaireRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
        isBroker,
    }: GetSupplementalQuestionnaireFormEngineRequest): AsyncResult<
        GetSupplementalQuestionnaireFormEngineResponse,
        OperationFailed
    > {
        const supplementalAppInfoResult =
            await this.supplementalQuestionnaireRepository.getSupplementalAppInfo(applicationId);

        if (isErr(supplementalAppInfoResult)) {
            return Failure(
                OperationFailed({
                    message: 'Failed to get supplementalAppInfo',
                }),
            );
        }

        const supplementalAppInfo = supplementalAppInfoResult.value;

        const context = {
            prefill: toFormEngineInput(supplementalAppInfo.supplementalQuestionnaire),
            isBroker: isBroker,
            applicationData: {
                dnoLimit: supplementalAppInfo.dnoLimit,
                eoLimit: supplementalAppInfo.eoLimit,
                currentDnoLimit: supplementalAppInfo.currentDnoLimit,
                currentEoLimit: supplementalAppInfo.currentEoLimit,
                isRenewal: supplementalAppInfo.isRenewal,
                isDNOBalanceSheetQuestionEnabled: getIsDNOBalanceSheetQuestionEnabled(
                    supplementalAppInfo.effectiveDate,
                    supplementalAppInfo.latestFundingDate,
                    supplementalAppInfo.totalFundingAmount,
                ),
            },
            isTechUnfundedApp: supplementalAppInfo.isTechUnfundedApp,
        };
        const factory = espSupplementalQuestionnaireFormEngine.factory;
        const formEngineInstance = factory.createInstance(context);

        formEngineInstance.machine.update({
            submitText: 'Submit Request',
        });

        return Success({ formEngine: formEngineInstance });
    }
}

export const GetSupplementalQuestionnaireFormEngine: UseCaseClass<GetSupplementalQuestionnaireFormEngine> =
    GetSupplementalQuestionnaireFormEngineUseCase;

const getIsDNOBalanceSheetQuestionEnabled = (
    effectiveDate?: Date,
    latestFundingDate?: Date,
    totalFundingAmount?: number,
) => {
    if (!effectiveDate || !latestFundingDate || !totalFundingAmount) {
        return false;
    }
    return isAfter(effectiveDate, addYears(latestFundingDate, 2)) && totalFundingAmount < 20000000;
};
