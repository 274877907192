import {
    CenterLayout,
    CoverLayout,
    Image,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React, { useContext } from 'react';
import { hasRole } from '../../../../userOrg/entities/Session';
import { AppContext } from '../../../../view/AppContext';
import { Link } from '../../../../view/components/Link/Link';

export function CreatingApplicationErrorPage() {
    const { activeSession } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');

    return (
        <CoverLayout>
            <StackLayout gap="32">
                <CenterLayout>
                    <Image inline={false} name="shotwell-construction" />
                </CenterLayout>
                <CenterLayout centerText>
                    <StackLayout gap="16">
                        <Text as="h1" style="heading 1">
                            We are sorry.
                        </Text>
                        <Text as="p" style="body 1">
                            At this time, we cannot process your insurance application.
                            <br />
                            {isBroker
                                ? 'Please reach out to Embroker support at 1 844 436 2765'
                                : 'Please reach out to your broker.'}
                        </Text>
                        {isBroker && (
                            <CenterLayout>
                                <TextButton as={Link} href="/broker/dashboard">
                                    Go back to Dashboard
                                </TextButton>
                            </CenterLayout>
                        )}
                    </StackLayout>
                </CenterLayout>
            </StackLayout>
        </CoverLayout>
    );
}
