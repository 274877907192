import { container } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Immutable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { cast } from '@embroker/shotwell/core/types/Nominal';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Spinner, StackLayout, StatusMessage, TextButton, useModal } from '@embroker/ui-toolkit/v2';
import React, { useContext, useState } from 'react';
import { hasRole } from '../../../userOrg/entities/Session';
import { AppContext } from '../../../view/AppContext';
import { Invoice } from '../../entities/Invoice';
import { isBundlePayment, BundlePayment } from '../../types/Payment';
import { GetInvoiceHistory } from '../../useCases/GetInvoiceHistory';
import { Charged } from './ChargedTable';
import { Credits } from './CreditTable';
import { BundleModalInvoiceDetail } from './modals/BundleInvoiceDetailsModal';
import { ModalInvoiceDetail } from './modals/InvoiceDetailsModal';
import { PaymentEmptyHistoryPage } from './NoPaymentHistory';
import { resolveLobByDescription } from './PaymentsDue';
import { PaymentsUpdateBillingInfo } from '@app/payments/view/components/PaymentsUpdateBilling';

interface BundlePolicyInfo {
    lineOfBusiness: string;
    invoiceNumber: string;
    policyNumber?: string;
    effectiveDate: Date;
    total: Money;
}

export interface PaymentData extends Record<string, unknown> {
    lineOfBusiness: string;
    invoiceNumber: string;
    policyNumber?: string;
    effectiveDate: Date;
    detailsLink: React.ReactNode;
    total: Money;
    bundlePolicyInfo?: BundlePolicyInfo[];
}

export function PaymentsHistory() {
    const context = useContext(AppContext);
    const modal = useModal();
    const bundleModal = useModal();
    const [currentInvoiceDetail, setCurrentInvoiceDetail] =
        useState<Immutable<EntityProps<Invoice>>>();
    const [currentBundleInvoiceDetail, setCurrentBundleInvoiceDetail] =
        useState<Immutable<BundlePayment>>();
    const isBroker = hasRole(context.activeSession, 'broker');

    function showModal(invoice: Immutable<EntityProps<Invoice>>) {
        modal.show();
        setCurrentInvoiceDetail(invoice);
    }

    function hideModal() {
        modal.hide();
    }

    function showBundleModal(invoiceList: Immutable<BundlePayment>) {
        bundleModal.show();
        setCurrentBundleInvoiceDetail(invoiceList);
    }

    function hideBundleModal() {
        bundleModal.hide();
    }

    const { result, isLoading } = useUseCase(GetInvoiceHistory, {
        organizationId: context.activeSession.organizationId ?? cast<UUID>('invalid'),
    });

    if (isLoading || result === undefined) {
        return <Spinner />;
    }
    if (isErr(result)) {
        container.get<Logger>(Log).error(result.errors);
        return null;
    }

    if (result.value.chargedInvoices.length === 0 && result.value.creditInvoices.length === 0) {
        return <PaymentEmptyHistoryPage isBroker={isBroker} />;
    }

    const creditData: PaymentData[] = result.value.creditInvoices.map((item) => {
        const detailsLink = <TextButton onClick={() => showModal(item)}>Details</TextButton>;
        return {
            lineOfBusiness: resolveLobByDescription(item.lineOfBusiness, item.description),
            invoiceNumber: item.invoiceNumber,
            policyNumber: item.policyNumber,
            effectiveDate: item.policyEffectiveDate,
            detailsLink,
            total: item.total,
        };
    });

    const chargedData: PaymentData[] = result.value.chargedInvoices.map((item) => {
        if (isBundlePayment(item)) {
            const detailsLink = (
                <TextButton onClick={() => showBundleModal(item)}>Details</TextButton>
            );
            const payment = item.invoiceList[0];
            const bundlePolicyInfo: BundlePolicyInfo[] = [];
            for (const invoice of item.invoiceList) {
                bundlePolicyInfo.push({
                    lineOfBusiness: invoice.lineOfBusiness,
                    invoiceNumber: invoice.invoiceNumber,
                    policyNumber: invoice.policyNumber,
                    effectiveDate: payment.policyEffectiveDate,
                    total: invoice.total,
                } as BundlePolicyInfo);
            }
            return {
                lineOfBusiness: item.lineOfBusiness,
                invoiceNumber: item.invoiceList.length + ' invoices',
                policyNumber: item.invoiceList.length + ' policies',
                effectiveDate: payment.policyEffectiveDate,
                detailsLink,
                total: item.total,
                bundlePolicyInfo: bundlePolicyInfo,
            } as PaymentData;
        }
        const detailsLink = <TextButton onClick={() => showModal(item)}>Details</TextButton>;
        return {
            lineOfBusiness: resolveLobByDescription(item.lineOfBusiness, item.description),
            invoiceNumber: item.invoiceNumber,
            policyNumber: item.policyNumber,
            effectiveDate: item.policyEffectiveDate,
            detailsLink,
            total: item.total,
        } as PaymentData;
    });

    return (
        <StackLayout>
            {!isBroker && (
                <StatusMessage status="info">
                    This section displays the payments submitted to Embroker. Please note that the
                    payments submitted directly to carriers will not be listed here.
                </StatusMessage>
            )}
            <Credits data={creditData} />
            <Charged data={chargedData} />
            <ModalInvoiceDetail
                hideModal={hideModal}
                invoice={currentInvoiceDetail}
                modal={modal}
                type="history"
            />
            <BundleModalInvoiceDetail
                hideModal={hideBundleModal}
                modal={bundleModal}
                bundlePayment={currentBundleInvoiceDetail}
            />
            <hr />
            <PaymentsUpdateBillingInfo />
        </StackLayout>
    );
}
