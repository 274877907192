import { LineOfBusinessCodeList, LineOfBusinessCodeListItem } from '@embroker/shotwell-api/enums';
import { PhoneNumber } from '@embroker/shotwell/core/types/PhoneNumber';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface DirectBillPolicy {
    displayName: string;
    policyNumber: string;
    insurerName: string;
    insurerBillingPhone?: PhoneNumber;
    insurerBillingURL?: string;
    lineOfBusiness: LineOfBusinessCodeListItem;
    subLineOfBusiness?: string;
    startDate: Date;
    endDate: Date;
}

export const DirectBillPolicy = {
    ...defineValidator<DirectBillPolicy>({
        displayName: Joi.string(),
        policyNumber: Joi.string().allow(''), // we allow empty string because purchased policies can have empty policy number while their processing is still ongoing
        insurerName: Joi.string(),
        insurerBillingPhone: Joi.string().optional(),
        insurerBillingURL: Joi.string().optional(),
        startDate: Joi.date(),
        endDate: Joi.date(),
        lineOfBusiness: Joi.string().valid(...LineOfBusinessCodeList),
        subLineOfBusiness: Joi.string().optional().allow(''),
    }),
    create(directBillPolicy: DirectBillPolicy) {
        return DirectBillPolicy.validate(directBillPolicy);
    },
};
