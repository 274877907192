import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { map, NaviRequest, route } from 'navi';
import React from 'react';
import { AppContext } from '../../../view/AppContext';
import { RouteDefinitions } from '../../../view/routes/Route';
import {
    PolicyStatusInSeries,
    PolicyStatusInSeriesExpired,
    PolicyStatusInSeriesList,
} from '../../types/PolicyStatusInSeries';
import { Policies } from '../components/Policies';
import { PolicyDetails } from '../components/PolicyDetails';

export const routes: RouteDefinitions<AppContext> = {
    '/policies': map(async (request: NaviRequest<AppContext>) => {
        const { paymentIdList } = request.query;
        return route({
            title: 'Embroker | Policies',
            view: <Policies paymentIdList={paymentIdList} />,
        });
    }),
    '/policies/detail': map(async (request: NaviRequest<AppContext>) => {
        const { policyId, tabToOpen, referrer } = request.query;
        if (!UUID.check(policyId)) {
            const errorLogId = container
                .get<Logger>(Log)
                .whoops(`Malformed url with invalid policy id: ${policyId}`);
            return route({ view: <ErrorPage errorLogId={errorLogId} /> });
        }
        let valueTabToOpen: PolicyStatusInSeries | undefined;
        if (Object.values<string>(PolicyStatusInSeriesList).includes(tabToOpen)) {
            valueTabToOpen = tabToOpen as PolicyStatusInSeries;
        }
        return route({
            title: 'Embroker | Policy',
            view: (
                <PolicyDetails
                    policyId={policyId}
                    initialTabToOpen={valueTabToOpen}
                    referrer={referrer}
                />
            ),
        });
    }),
    '/policies/expired-policy-detail': map(async (request: NaviRequest<AppContext>) => {
        const { policyId } = request.query;
        if (!UUID.check(policyId)) {
            const errorLogId = container
                .get<Logger>(Log)
                .whoops(`Malformed url with invalid policy id: ${policyId}`);
            return route({
                view: <ErrorPage errorLogId={errorLogId} />,
            });
        }
        return route({
            title: 'Embroker | Policies',
            view: (
                <PolicyDetails policyId={policyId} initialTabToOpen={PolicyStatusInSeriesExpired} />
            ),
        });
    }),
};
