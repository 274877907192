import { ContainerModule } from '@embroker/shotwell/core/di';
import { Module } from '../Module';
import { APIServerEnumsRepository } from './repositories/ServerEnumsRepository/APIServerEnumsRepository';
import { ServerEnumsRepository } from './repositories/ServerEnumsRepository/index';
import { GetServerEnums } from './useCases/GetServerEnums';

/**
 * The serverEnums module.
 */
export const ServerEnumsModule: Module = {
    container: new ContainerModule((bind) => {
        bind<ServerEnumsRepository>(ServerEnumsRepository)
            .to(APIServerEnumsRepository)
            .inSingletonScope();
        bind<GetServerEnums>(GetServerEnums.type).to(GetServerEnums).inSingletonScope();
    }),
};
