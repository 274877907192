const { initSentry } = require('@embroker/shotwell/core/logging/SentryTransport/init');

if (typeof window.EMBROKER_ENV?.SENTRY_DSN === 'string') {
    initSentry({
        dsn: EMBROKER_ENV.SENTRY_DSN,
        release: EMBROKER_ENV.RELEASE,
        beforeSend(event) {
            // NB: added as part of the triage of "phantom 400 responses" that result in Whoops errors
            // should be removed once that is complete, or once the Cookie header size is eliminated
            // as potential reason for these requests
            if (document.cookie.length >= 4000) {
                const cookieSize = {
                    total: document.cookie.length,
                    cookies: {},
                };
                for (const cookie of document.cookie.split(/\s*;\s*/g)) {
                    const size = cookie.length;
                    const [name] = cookie.split('=', 2);
                    if (cookieSize.cookies.hasOwnProperty(name)) {
                        if (Array.isArray(cookieSize.cookies[name])) {
                            cookieSize.cookies[name].push(size);
                        } else {
                            cookieSize.cookies[name] = [cookieSize.cookies[name], size];
                        }
                    } else {
                        cookieSize.cookies[name] = size;
                    }
                }
                event.extra = {
                    ...event.extra,
                    cookieSize,
                };
            }
            return event;
        },
    });
}
