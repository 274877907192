import {
    API,
    PaymentsBillingInfoGetRequest,
    PaymentsBillingInfoUpdateRequest,
} from '@embroker/shotwell-api/app';
import { injectable } from '@embroker/shotwell/core/di';
import {
    BillingInfoRepository,
    BillingInfoGetInput,
    BillingInfoGetResponse,
    BillingInfoUpdateInput,
    BillingInfoUpdateResponse,
} from '.';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';

@injectable()
export class APIBillingInfoRepository implements BillingInfoRepository {
    async getBillingInfo(
        getBillingInfoInput: BillingInfoGetInput,
    ): AsyncResult<BillingInfoGetResponse, InvalidArgument | OperationFailed> {
        const billingInfoGetRequest: PaymentsBillingInfoGetRequest = {
            billing_info_get: {
                organization_id: getBillingInfoInput.organizationId,
            },
        };
        const result = await API.request('payments/billing_info_get', billingInfoGetRequest);

        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return Success({
            id: result.value.id,
            organizationId: result.value.organization_id,
            billingEmailAddress: result.value.billing_email_address,
        } as BillingInfoGetResponse);
    }

    async updateBillingInfo(
        updateBillingInfoInput: BillingInfoUpdateInput,
    ): AsyncResult<BillingInfoUpdateResponse, InvalidArgument | OperationFailed> {
        const billingInfoUpdateRequest: PaymentsBillingInfoUpdateRequest = {
            billing_info_update: {
                organization_id: updateBillingInfoInput.organizationId,
                billing_email_address: updateBillingInfoInput.billingEmailAddress,
            },
        };
        const result = await API.request('payments/billing_info_update', billingInfoUpdateRequest);

        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return Success({
            id: result.value.id,
        } as BillingInfoUpdateResponse);
    }
}
