import { inject } from '@embroker/shotwell/core/di';
import { Aborted, InvalidArgument, OperationFailed, Timeout } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { cast } from '@embroker/shotwell/core/types/Nominal';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute, UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { GetDocumentUrl } from '../../../documents/useCases/GetDocumentUrl';
import { TasksRepository } from '../../../tasks/repositories';
import { DocGenFailed } from '../../errors';
import { ConfigFetchFailed } from '../errors';
import { QuoteRepository } from '../repositories/QuoteRepository';

export interface GenerateQuoteFileUrlRequest {
    applicationId: UUID;
    quoteId: UUID;
    abortSignal: AbortSignal;
}

export interface GenerateQuoteFileUrlResponse {
    readonly documentUrl: URI;
}

export interface GenerateQuoteFileUrl extends UseCase {
    execute(
        request: GenerateQuoteFileUrlRequest,
    ): AsyncResult<
        GenerateQuoteFileUrlResponse,
        InvalidArgument | OperationFailed | ConfigFetchFailed | Timeout | Aborted | DocGenFailed
    >;
}

class GenerateQuoteFileUrlUseCase extends UseCase implements GenerateQuoteFileUrl {
    public static type = Symbol('PCoMLQuote/GenerateQuoteFileUrl');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(QuoteRepository) private quoteRepository: QuoteRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
        quoteId,
        abortSignal,
    }: GenerateQuoteFileUrlRequest): AsyncResult<
        GenerateQuoteFileUrlResponse,
        InvalidArgument | OperationFailed | ConfigFetchFailed | Timeout | Aborted | DocGenFailed
    > {
        const createTaskResult = await this.quoteRepository.createQuoteSummaryAsyncTask(
            applicationId,
            quoteId,
        );
        if (isErr(createTaskResult)) {
            return createTaskResult;
        }

        const pollForTaskStatusResult = await this.tasksRepository.pollForTaskStatus(
            createTaskResult.value,
            abortSignal,
        );
        if (isErr(pollForTaskStatusResult)) {
            return Failure(DocGenFailed({ errors: pollForTaskStatusResult.errors }));
        }

        const lastQuoteResult = await this.quoteRepository.getQuoteByApplicationId(applicationId);
        if (isErr(lastQuoteResult)) {
            return lastQuoteResult;
        }
        if (lastQuoteResult.value.id !== quoteId) {
            return Failure(OperationFailed({ message: 'Not the latest pcoml quote' }));
        }
        const fileKey = lastQuoteResult.value.fileKey;
        if (fileKey == undefined) {
            return Failure(OperationFailed({ message: 'Pcoml quote summary fileKey not found' }));
        }
        const getDocumentUrlResult = await execute(GetDocumentUrl, {
            fileKey: fileKey,
        });

        if (isErr(getDocumentUrlResult)) {
            return getDocumentUrlResult;
        }

        return Success<GenerateQuoteFileUrlResponse>({
            documentUrl: cast<URI>(getDocumentUrlResult.value.downloadUrl),
        });
    }
}

export const GenerateQuoteFileUrl: UseCaseClass<GenerateQuoteFileUrl> = GenerateQuoteFileUrlUseCase;
