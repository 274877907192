import { ClaimStatusCodeList, ClaimStatusCodeListItem } from '@embroker/shotwell-api/enums';
import { Nullable, Immutable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { Claimant } from '../entities/Claim';
import { ClaimDocument } from './ClaimDocument';

export interface ClaimDetails {
    claimNumber: string;
    lossDate: Nullable<Date>;
    reportedDate: Date;
    closedDate: Nullable<Date>;
    valuationDate: Nullable<Date>;
    status: Nullable<ClaimStatusCodeListItem>;
    amountPaid: Nullable<Money>;
    adjusterName: Nullable<string>;
    adjusterPhoneNumber: Nullable<string>;
    reservedAmount: Nullable<Money>;
    causeOfLoss: Nullable<string>;
    claimantList: Claimant[];
    documents: Immutable<ClaimDocument[]>;
}

export const ClaimDetails = {
    ...defineValidator<ClaimDetails>({
        claimNumber: Joi.string(),
        lossDate: Joi.date().allow(null),
        reportedDate: Joi.date(),
        closedDate: Joi.date().allow(null),
        valuationDate: Joi.date().allow(null),
        status: Joi.string()
            .valid(...ClaimStatusCodeList)
            .allow('', null),
        amountPaid: Money.schema.allow(null),
        adjusterName: Joi.string().allow('', null),
        adjusterPhoneNumber: Joi.string().allow('', null),
        reservedAmount: Money.schema.allow(null),
        causeOfLoss: Joi.string().allow('', null),
        claimantList: Joi.array().items(Claimant.schema),
        documents: Joi.array().items(ClaimDocument.schema),
    }),
    create(claimDetails: ClaimDetails) {
        return ClaimDetails.validate(claimDetails);
    },
};
