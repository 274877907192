import { inject } from '@embroker/shotwell/core/di';
import { Aborted, InvalidArgument, OperationFailed, Timeout } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute, UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ApplicationRepository } from '../repositories/ApplicationRepository';
import { URI } from '@embroker/shotwell/core/types/URI';
import { GenerateAppFileUrlResponse } from '../../documents/useCases/GenerateAppFileUrl';
import { CheckApplicationPrintStatus } from './CheckApplicationPrintStatus';

/**
 * Request data for PrintStartedApplication use case
 */
export interface PrintStartedApplicationRequest {
    applicationId: UUID;
    abortSignal: AbortSignal;
}

/**
 * Response data for PrintStartedApplication use case
 */

export interface PrintStartedApplicationResponse {
    documentUrl: URI;
}

/**
 * PrintStartedApplication use case is used to create WCGA app document for given insurance app
 */
export interface PrintStartedApplication extends UseCase {
    execute(
        request: PrintStartedApplicationRequest,
    ): AsyncResult<
        PrintStartedApplicationResponse,
        OperationFailed | InvalidArgument | Aborted | Timeout
    >;
}

class PrintStartedApplicationUseCase extends UseCase implements PrintStartedApplication {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Shopping/PrintStartedApplication');

    /**
     * Constructor for PrintStartedApplication use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param applicationRepository used to store/fetch application info.
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes PrintStartedApplication use case
     * Input is of PrintStartedApplicationRequest type
     * @returns InvalidArgument if there is no such insurance application
     * @returns OperationFailed if error occured
     */
    public async execute(
        request: PrintStartedApplicationRequest,
    ): AsyncResult<
        PrintStartedApplicationResponse,
        OperationFailed | InvalidArgument | Aborted | Timeout
    > {
        const startPrintJobResponse = await this.applicationRepository.startPrinting(
            request.applicationId,
        );

        if (isErr(startPrintJobResponse)) {
            return handleOperationFailure(startPrintJobResponse);
        }
        const documentsGeneratedResult = await this.waitForDocumentToBeGenerated(
            startPrintJobResponse.value,
            request.abortSignal,
        );
        if (isErr(documentsGeneratedResult)) {
            return documentsGeneratedResult;
        }

        return Success<GenerateAppFileUrlResponse>({
            documentUrl: documentsGeneratedResult.value,
        });
    }

    private async waitForDocumentToBeGenerated(
        jobId: UUID,
        abortSignal: AbortSignal,
    ): AsyncResult<URI, InvalidArgument | OperationFailed | Aborted | Timeout> {
        const checkApplicationPrintStatusResult = await execute(CheckApplicationPrintStatus, {
            jobId: jobId,
            maxPollingRetries: 100,
            pollingRetryIntervalInMilliseconds: 5000,
            abortSignal,
        });

        if (isErr(checkApplicationPrintStatusResult)) {
            return checkApplicationPrintStatusResult;
        }

        return Success(checkApplicationPrintStatusResult.value.link as URI);
    }
}

export const PrintStartedApplication: UseCaseClass<PrintStartedApplication> =
    PrintStartedApplicationUseCase;
