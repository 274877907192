import { Money } from '@embroker/shotwell/core/types/Money';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface CyberQuoteDetails {
    readonly quoteId: UUID;
    readonly totalPremium: Money;
    readonly previousPremium?: Money;
    readonly totalBasePremium: Money;
    readonly taxes: Money;
    readonly fees: Money;
    readonly policyAdministrationFee: Money;
    readonly specimenPolicyFileKey?: string;
}

export const CyberQuoteDetails = {
    ...defineValidator<CyberQuoteDetails>({
        quoteId: UUID.schema.required(),
        totalPremium: Money.schema.required(),
        previousPremium: Money.schema.optional(),
        totalBasePremium: Money.schema.required(),
        taxes: Money.schema.required(),
        fees: Money.schema.required(),
        policyAdministrationFee: Money.schema.required(),
        specimenPolicyFileKey: Joi.string().optional(),
    }),
    create(cyberQuote: CyberQuoteDetails) {
        return CyberQuoteDetails.validate(cyberQuote);
    },
};
