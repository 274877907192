import { BoxLayoutProps, BoxLayout, CardLayoutProps, CardLayout } from '@embroker/ui-toolkit/v2';
import React from 'react';

interface BundleQuoteCardLayoutProps {
    gap?: BoxLayoutProps['gap'];
    boxShadow?: CardLayoutProps['boxShadow'];
    children: React.ReactNode;
    'data-e2e'?: string;
}

export function BundleQuoteCardLayout({
    gap = '16',
    children = null,
    boxShadow = true,
    'data-e2e': dataE2E,
}: BundleQuoteCardLayoutProps) {
    return (
        <CardLayout radius="16" boxShadow={boxShadow} data-e2e={dataE2E}>
            <BoxLayout gap={gap}>{children}</BoxLayout>
        </CardLayout>
    );
}
