import React from 'react';
import { UnderlyingCoverageProps } from '../../../view/components/BundleQuoteCoverageList';
import { CyberBundleCoverageView } from '../../../view/components/cyber/CyberBundleCoverageView';
import { CowbellCyberQuote } from '../entities/CowbellCyberQuote';
import { CowbellCyberLimit, CowbellCyberRetention } from '../types/CowbellCyberQuoteOptions';

export function CowbellCyberBundleCoverageOptions(
    underlyingCoverageProps: UnderlyingCoverageProps<CowbellCyberQuote>,
) {
    return (
        <CyberBundleCoverageView
            {...underlyingCoverageProps}
            cyberLimitOptions={CowbellCyberLimit}
            cyberRetentionOptions={CowbellCyberRetention}
        />
    );
}
