import React from 'react';
import { CheckBox, CheckBoxProps, Tooltip } from '@embroker/ui-toolkit/v2';

export interface CheckboxWithTooltipProps extends CheckBoxProps {
    tooltip?: string;
}

export const CheckboxWithTooltip = React.forwardRef(function CheckboxWithTooltip(
    { tooltip, ...other }: CheckboxWithTooltipProps,
    ref: React.Ref<HTMLInputElement>,
) {
    return (
        <React.Fragment>
            <CheckBox {...other} ref={ref} />
            {tooltip ? <Tooltip text={tooltip} /> : null}
        </React.Fragment>
    );
});
