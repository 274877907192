import React from 'react';
import {
    Form,
    FormFieldProps as FormFieldPropsToolkit,
    Immutable,
    InputStatusMessage,
    InputType,
    StackLayout,
    StatusMessage,
} from '@embroker/ui-toolkit/v2';
import { FormQuestionDefinition } from '../hooks/useDataDrivenForm';
import { INPUT_REQUIRE_MESSAGE } from '../types/validationObject';
import { MultipleFormFieldView } from './MultipleFormFieldView.view';
import { ComplexFormFieldDefinitionMap, isComplexQuestionType } from '../types/ComplexFieldTypes';

export interface InputProps {
    name: string;
    value?: unknown | unknown[];
    onChange: (e: { target: { value: any } }) => void;
    hasErrors: boolean;
    isRequired: boolean;
    placeholder?: FormQuestionDefinition['placeholder'];
    items?: FormQuestionDefinition['selectOptions'];
    disabled?: boolean;
}

export interface InputFieldProps {
    type: InputType;
    inputProps: InputProps;
    messages: FormFieldPropsToolkit['messages'];
    disabled?: boolean;
}

export type QuestionProps = Pick<
    FormQuestionDefinition,
    | 'key'
    | 'questionType'
    | 'title'
    | 'tooltip'
    | 'label'
    | 'selectOptions'
    | 'staticOptions'
    | 'isMultiple'
    | 'statusMessage'
>;

// It seems like placeholder from FormFieldProps does nothing when passed to Form.Field
// The placeholder on inputProps is what populates the placeholder
// Omitting from FieldProps for ease of understanding
export interface FormFieldProps extends Omit<FormFieldPropsToolkit, 'placeholder' | 'inputProps'> {
    type: InputType;
    inputProps: InputProps;
}

export interface FormFieldViewProps {
    questionProps: QuestionProps;
    inputFieldProps: InputFieldProps;
    onComplexFieldChange: (questionKey: string, value: unknown) => void;
}

export function parseMessages(
    messagesInput?: Immutable<InputStatusMessage[]>,
): Immutable<InputStatusMessage[]> {
    // This function ensures that if an input fails the 'required' validation, then we only message about 'required'.
    // Once the required status is fulfilled, we surface all validation messages to the user.
    const messages = messagesInput || [];
    const isRequiredInputMessage = messages.find((message) => message === INPUT_REQUIRE_MESSAGE);
    if (isRequiredInputMessage) {
        return [isRequiredInputMessage];
    }
    return messages;
}

export function FormFieldView(formFieldViewProps: FormFieldViewProps) {
    const { questionProps, inputFieldProps, onComplexFieldChange } = formFieldViewProps;
    const { questionType } = questionProps;

    if (questionProps.isMultiple) {
        return (
            <MultipleFormFieldView {...formFieldViewProps} onFieldChange={onComplexFieldChange} />
        );
    }

    if (isComplexQuestionType(questionType)) {
        const ComplexFormFieldView = ComplexFormFieldDefinitionMap[questionType].renderingComponent;
        return (
            <ComplexFormFieldView
                data-testid={`question-key-${questionProps.key}`}
                inputFieldProps={inputFieldProps}
                questionProps={{ ...questionProps, questionType }}
                onComplexFieldChange={onComplexFieldChange}
            />
        );
    }

    const formFieldProps: FormFieldProps = {
        title: questionProps.title,
        label: questionProps.label,
        tooltip: questionProps.tooltip,
        type: inputFieldProps.type,
        inputProps: {
            ...inputFieldProps.inputProps,
            placeholder: inputFieldProps.inputProps.placeholder,
            items: questionProps.selectOptions,
            disabled: inputFieldProps.disabled,
        },
        messages: parseMessages(inputFieldProps.messages),
    };

    // This is a hack to hide error messages for disabled fields
    // We should have a more robust implementation for this
    if (formFieldProps.inputProps.disabled) {
        formFieldProps.messages = [];
        formFieldProps.inputProps.hasErrors = false;
    }

    return (
        <StackLayout gap="12">
            <Form.Field data-testid={`question-key-${questionProps.key}`} {...formFieldProps} />
            {questionProps.statusMessage && (
                <StatusMessage status={questionProps.statusMessage.status}>
                    {questionProps.statusMessage.content}
                </StatusMessage>
            )}
        </StackLayout>
    );
}
