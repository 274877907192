import React from 'react';
import { TextButton } from '@embroker/ui-toolkit/v2';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { WizardForm } from '../../../../view/hooks/useWizardForm';

interface DownloadESPRenewalGTCButtonProps<T extends FormData> {
    isSubmitting: boolean;
    trigger: WizardForm<OpaqueForm<T>>['trigger'];
    isDisabled?: boolean;
}

export function DownloadESPRenewalGTCButton(isPlus: boolean) {
    return isPlus ? DownloadESPRenewalGTCPlusButton : DownloadESPRenewalGTCStandardButton;
}

function DownloadESPRenewalGTCStandardButton<T extends FormData>({
    isSubmitting,
    isDisabled,
    trigger,
}: DownloadESPRenewalGTCButtonProps<T>) {
    return (
        <TextButton
            as="button"
            disabled={isDisabled || isSubmitting}
            icon="download"
            onClick={() => trigger('downloadGTC')}
            data-e2e="general-terms-and-conditions"
        >
            General Terms &amp; Conditions
        </TextButton>
    );
}

function DownloadESPRenewalGTCPlusButton<T extends FormData>({
    isSubmitting,
    isDisabled,
    trigger,
}: DownloadESPRenewalGTCButtonProps<T>) {
    return (
        <TextButton
            as="button"
            disabled={isDisabled || isSubmitting}
            icon="download"
            onClick={() => trigger('downloadGTC')}
            data-e2e="general-terms-and-conditions-plus"
        >
            General Terms &amp; Conditions - Plus
        </TextButton>
    );
}
