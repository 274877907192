import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { Money } from '@embroker/shotwell/core/types/Money';
import { State } from '@embroker/shotwell/core/types/StateList';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { isAfter, isBefore, isEqual } from 'date-fns';

export enum DocumentPublicationType {
    Issue = 'issue',
    Preview = 'preview',
}

export interface DigitalEndorsementIssuedEvent extends DomainEvent {
    endorsementType: string;
    policyId: UUID;
}

export type EndorsementContext = {
    endorsementId?: UUID;
    userFullName: string;
    userTitle: string;
    company: string;
    policyId: UUID;
    policyEffectiveDate: Date;
    policyExpirationDate: Date;
    policyState?: State;
    endorsementType: string;
    origin: string;
};
type Location = {
    addressLine1: string;
    addressLine2?: string;
    city: string;
    stateCode: State;
    zip: string;
};

function isBeforeOrEqual(date1: Date, date2: Date): boolean {
    return isBefore(date1, date2) || isEqual(date1, date2);
}

const AMEND_POLICY_PERIOD_ENDORSEMENT_TYPE = 'amendPolicyPeriodEndorsement';
const AMEND_NAMED_INSURED_OR_ADDRESS_ENDORSEMENT_TYPE = 'amendNamedInsuredOrAddressEndorsement';
const EXTENDED_REPORTING_PERIOD_ELECTION_ENDORSEMENT_TYPE =
    'extendedReportingPeriodElectionEndorsement';
const POLICY_EXTENSION_ENDORSEMENT_TYPE = 'policyExtensionEndorsement';
const PCO_RUN_OFF_ENDORSEMENT_TYPE = 'pcoRunOffCoverageEndorsement';
const AMEND_ADDRESS_ENDORSEMENT_TYPE = 'changeAddressEndorsement';

const ADDRESS_AMENDMENT_ENDORSEMENTS = new Set<string>([
    AMEND_ADDRESS_ENDORSEMENT_TYPE,
    AMEND_NAMED_INSURED_OR_ADDRESS_ENDORSEMENT_TYPE,
]);

export const ESP_ADD_EPLI_COVERAGE = 'espAddEpliCoverageEndorsement';
export const ESP_ADD_DO_COVERAGE = 'espAddDoCoverage';
export const ESP_AMEND_DO_COVERAGE = 'espAmendDoCoverageLimitOfLiability';
export const ESP_AMEND_EPLI_COVERAGE = 'espAmendEpliLimitsRetentionEndorsement';
export const ESP_AMEND_TECH_EO_COVERAGE = 'espAmendTechEoCyberLiabilityCoverageLimitOfLiability';

function isValidAddressChange(formData: Record<string, unknown>, ctx: EndorsementContext) {
    const location = formData.address as Location;
    if (!location) {
        return false;
    }
    return !ctx.policyState || location.stateCode === ctx.policyState;
}

// isAddressChangeEndorsement Returns true for any endorsement which modifies/attempts to modify an address.
function isAddressChangeEndorsement(endorsement: string): boolean {
    return ADDRESS_AMENDMENT_ENDORSEMENTS.has(endorsement);
}

export function validateEndorsement(
    ctx: EndorsementContext,
    formData?: Record<string, unknown>,
): [boolean, string] {
    if (formData === undefined) {
        return [false, 'Form data is undefined.'];
    }

    const endorsementEffectiveDateStr = `${formData.endorsementEffectiveDate}T00:00:00`;
    const effectiveDate: Date = new Date(endorsementEffectiveDateStr);
    const dateFormatOptions: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };

    if (ctx.endorsementType === EXTENDED_REPORTING_PERIOD_ELECTION_ENDORSEMENT_TYPE) {
        const erpNumberOfYears = formData.erpNumberOfYears as string;
        const state = ctx.policyState;
        const enabledOptions: Partial<Record<State, string[]>> = {
            WY: ['1 year', '2 years', '3 years', 'unlimited'],
            CT: ['3 years', '3 year - reinstated'],
            AK: ['1 year', '2 years', '3 years', '5 years'],
        };
        const defaultOptions = ['1 year', '2 years', '3 years'];
        if (!state) {
            return [false, 'Policy state is not defined.'];
        }
        if (state in enabledOptions) {
            if (!(enabledOptions[state] as string[]).includes(erpNumberOfYears)) {
                return [false, `The selected ERP option is not available in the insured’s state.`];
            }
        }
        if (!defaultOptions.includes(erpNumberOfYears)) {
            return [false, `The selected ERP option is not available in the insured’s state.`];
        }
    }

    if (ctx.endorsementType === AMEND_POLICY_PERIOD_ENDORSEMENT_TYPE) {
        const endorsementPolicyEffectiveDate = new Date(formData.policyEffectiveDate as string);
        const endorsementPolicyExpirationDate = new Date(formData.policyExpirationDate as string);

        if (isBeforeOrEqual(endorsementPolicyExpirationDate, endorsementPolicyEffectiveDate)) {
            return [
                false,
                `Selected policy end date: ${endorsementPolicyExpirationDate.toLocaleDateString(
                    'en-US',
                    dateFormatOptions,
                )} must be after selected policy start date: ${endorsementPolicyEffectiveDate.toLocaleDateString(
                    'en-US',
                    dateFormatOptions,
                )}.`,
            ];
        }

        const isValid =
            isBeforeOrEqual(endorsementPolicyEffectiveDate, effectiveDate) &&
            isBeforeOrEqual(effectiveDate, endorsementPolicyExpirationDate) &&
            isBefore(endorsementPolicyEffectiveDate, endorsementPolicyExpirationDate);

        return [
            isValid,
            isValid
                ? ''
                : 'The selected endorsement effective date is not valid for the policy period.',
        ];
    }

    if (isAddressChangeEndorsement(ctx.endorsementType)) {
        const isValid = isValidAddressChange(formData, ctx);
        if (!isValid) {
            return [isValid, `Changing the insured’s state is not allowed via endorsements.`];
        }
    }

    if (ctx.endorsementType === POLICY_EXTENSION_ENDORSEMENT_TYPE) {
        const endorsementPolicyExpirationDate = new Date(formData.policyExpirationDate as string);

        if (isBeforeOrEqual(endorsementPolicyExpirationDate, ctx.policyExpirationDate)) {
            return [
                false,
                `New policy expiration date: ${endorsementPolicyExpirationDate.toLocaleDateString(
                    'en-US',
                    dateFormatOptions,
                )} must be after current policy expiration date: ${ctx.policyExpirationDate.toLocaleDateString(
                    'en-US',
                    dateFormatOptions,
                )}.`,
            ];
        }
    }

    if (ctx.endorsementType === PCO_RUN_OFF_ENDORSEMENT_TYPE) {
        const runOffPeriodFromDateStr = `${formData.endorsementEffectiveDate}T00:00:00`;
        const runOffPeriodFromDate = new Date(runOffPeriodFromDateStr);

        if (
            isBefore(runOffPeriodFromDate, ctx.policyEffectiveDate) ||
            isAfter(runOffPeriodFromDate, ctx.policyExpirationDate)
        ) {
            return [
                false,
                `The selected run-off period start date is not valid. The date must be between policy start date: ${ctx.policyEffectiveDate.toLocaleDateString(
                    'en-US',
                    dateFormatOptions,
                )} and end date: ${ctx.policyExpirationDate.toLocaleDateString(
                    'en-US',
                    dateFormatOptions,
                )}.`,
            ];
        }
    }

    const isValid =
        isBeforeOrEqual(ctx.policyEffectiveDate, effectiveDate) &&
        isBeforeOrEqual(effectiveDate, ctx.policyExpirationDate);

    return [
        isValid,
        isValid
            ? ''
            : `The selected endorsement effective date is not valid. The date must be between policy start date: ${ctx.policyEffectiveDate.toLocaleDateString(
                  'en-US',
                  dateFormatOptions,
              )} and end date: ${ctx.policyExpirationDate.toLocaleDateString(
                  'en-US',
                  dateFormatOptions,
              )}.`,
    ];
}

export interface LimitAndRetention {
    limit?: Money;
    retention?: Money;
    subLimit?: Money;
}
