import { Button, Card, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { useNavigation } from '../../../view/hooks/useNavigation';

export const EmbrokerPoliciesNoExisting = () => {
    const navigation = useNavigation();

    function goToStartShopping() {
        navigation.navigate('/shopping');
    }

    return (
        <Card center className="u-grid-size-12">
            <Text style="body 1">You have no policies available to display.</Text>
            <Button onClick={goToStartShopping}>Shop for coverage</Button>
        </Card>
    );
};
