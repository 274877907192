import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Immutable } from '@embroker/shotwell/core/types';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    Button,
    PageLayout,
    Spinner,
    StackLayout,
    Text,
    TextButton,
    useModal,
} from '@embroker/ui-toolkit/v2';
import React, { useEffect, useMemo, useState } from 'react';
import { Application } from '../../../shopping/entities/Application';
import { GetApplication } from '../../../shopping/useCases/GetApplication';
import { PrintStartedApplication } from '../../../shopping/useCases/PrintStartedApplication';
import { PrintApplicationModal } from '../../../shopping/view/components/PrintApplicationModal';
import { GetActiveOrganizationProfile } from '../../../userOrg/useCases/GetActiveOrganizationProfile';
import { SelectOrganization } from '../../../userOrg/useCases/SelectOrganization';
import { useNavigation } from '../../../view/hooks/useNavigation';

export interface BrokerRenewalKickoffProps {
    organizationId: UUID;
    applicationId: UUID;
}

export function BrokerRenewalKickoff({ applicationId, organizationId }: BrokerRenewalKickoffProps) {
    const printModal = useModal();
    const { navigate } = useNavigation();
    const { result } = useUseCase(GetActiveOrganizationProfile);
    const [organizationName, setOrganizationName] = useState('');
    const abortController = useMemo(() => {
        return new AbortController();
    }, []);

    const { result: applicationResult } = useUseCase(GetApplication, {
        applicationId,
    });

    useEffect(() => {
        if (result == undefined || isErr(result)) {
            return;
        }
        setOrganizationName(result.value.organization.companyLegalName);
    }, [result]);

    if (applicationResult == undefined) {
        return <Spinner />;
    }

    if (isErr(applicationResult)) {
        return <ErrorPage errors={applicationResult.errors} />;
    }

    const application: Immutable<EntityProps<Application>> = applicationResult?.value.application;
    const handleNextButton = async () => {
        await execute(SelectOrganization, { organizationId });
        navigate(
            URI.build('/shopping/application/basic-info', {
                applicationId: application.id,
            }),
        );
    };

    async function printStartedApplication(applicationId: UUID) {
        printModal.show();
        const printApplicationResult = await execute(PrintStartedApplication, {
            applicationId,
            abortSignal: abortController.signal,
        });
        printModal.hide();
        if (isOK(printApplicationResult)) {
            const pdfTab = window.open(
                printApplicationResult.value.documentUrl,
                '_blank',
                'noopener, noreferrer',
            );
            pdfTab?.focus();
        }
    }

    return (
        <PageLayout.Section>
            <StackLayout gap="32">
                <Text style="heading 3">Getting started on {organizationName} renewal</Text>
                <Text style="body 1">
                    Your client’s renewal can be completed digitally through a few simple steps.
                    Embroker’s renewal application will prefill last year’s data and prompt for a
                    few pieces of information to be updated prior to renewing. If you have any
                    questions or need help{' '}
                    <TextButton href="/support/contact">contact us</TextButton>.
                </Text>
                <ul>
                    <li>
                        <TextButton
                            onClick={() => {
                                printStartedApplication(applicationId);
                            }}
                        >
                            <Text style="body 1" color="primary-500">
                                Download application document
                            </Text>
                        </TextButton>
                    </li>
                    <li>
                        <Text style="body 1">Update client information and submit app</Text>
                    </li>
                    <li>
                        <Text style="body 1">Configure and review quote</Text>
                    </li>
                    <li>
                        <Text style="body 1">Bind the policy</Text>
                    </li>
                </ul>
                <Button appearance="primary" onClick={handleNextButton}>
                    Next
                </Button>
                <PrintApplicationModal modal={printModal} />
            </StackLayout>
        </PageLayout.Section>
    );
}
