import { UUID } from '@embroker/shotwell/core/types/UUID';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import {
    InsuranceApplicationStatusCode,
    InsuranceApplicationStatusCodeList,
} from '../../shopping/types/enums';
import { ProductType, ProductTypeList } from './ProductType';

export const ActivityItemRecordList = ['application', 'quote', 'renewal', 'payment'] as const;
export const ActivityAppStatusList = ['inProgress', 'inCreation'] as const;
export type ActivityItemRecord = (typeof ActivityItemRecordList)[number];
export type ActivityAppStatus = (typeof ActivityAppStatusList)[number];

export interface BrokerActivityItem {
    readonly record: ActivityItemRecord;
    readonly applicationId?: UUID;
    readonly applicationStatus?: InsuranceApplicationStatusCode;
    readonly organizationId: UUID;
    readonly organizationName: string;
    readonly productType: ProductType;
    readonly expiryDate?: Date;
    readonly hasQuotes?: boolean;
    readonly isSubmittedExternally?: boolean;
    readonly isStreamline?: boolean;
    readonly appStatus?: ActivityAppStatus;
}

export const BrokerActivityItem = {
    ...defineValidator<BrokerActivityItem>({
        record: Joi.string()
            .valid(...ActivityItemRecordList)
            .required(),
        organizationId: UUID.schema.required(),
        applicationId: UUID.schema.when('record', {
            is: 'payment',
            then: Joi.optional(),
            otherwise: Joi.required(),
        }),
        applicationStatus: Joi.string()
            .when('record', {
                is: 'payment',
                then: Joi.optional(),
                otherwise: Joi.required(),
            })
            .valid(...InsuranceApplicationStatusCodeList),
        organizationName: Joi.string().required(),
        productType: Joi.string()
            .valid(...ProductTypeList)
            .required(),
        expiryDate: Joi.date().when('record', {
            is: 'application',
            then: Joi.optional(),
            otherwise: Joi.required(),
        }),
        hasQuotes: Joi.boolean().when('record', {
            is: 'renewal',
            then: Joi.required(),
            otherwise: Joi.optional(),
        }),
        isSubmittedExternally: Joi.boolean().optional(),
        isStreamline: Joi.boolean().optional(),
        appStatus: Joi.string()
            .when('record', {
                is: 'application',
                then: Joi.required(),
                otherwise: Joi.optional(),
            })
            .valid(...ActivityAppStatusList),
    }),
    create(provider: BrokerActivityItem) {
        return BrokerActivityItem.validate(provider);
    },
};
