import { FormElement } from '@embroker/service-app-engine';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { JSONSerdes } from '@embroker/shotwell/core/encoding';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { AsyncResult, Failure, isErr, isOK, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { Immutable } from '@embroker/ui-toolkit/v2';
import { QuestionnaireParsingFailed } from '../../quote/errors';
import { ApplicantRepository } from '../../shopping/repositories/ApplicantRepository';
import { ApplicationRepository } from '../../shopping/repositories/ApplicationRepository';
import { EnrichmentData } from '../../shopping/types/EnrichmentData';
import { QuestionnaireData } from '../../shopping/types/QuestionnaireData';
import { ExpandedApplicationView } from '../../shopping/useCases/GetApplication';
import {
    GetFormEngineContext,
    toFormEngineInput,
} from '../../shopping/useCases/GetFormEngineContext';
import { GetFormEngineInstance } from '../../shopping/useCases/GetFormEngineInstance';
import { OverrideQuestionnaireData } from '../../shopping/useCases/OverrideQuestionnaireData';

export interface GetApplicationInCreationQuestionnaireRequest {
    applicationId: UUID;
}

export interface GetApplicationInCreationQuestionnaireResponse {
    formEngine: FormElement;
    application: Immutable<ExpandedApplicationView>;
}

export interface GetApplicationInCreationQuestionnaire extends UseCase {
    execute(
        request: GetApplicationInCreationQuestionnaireRequest,
    ): AsyncResult<
        GetApplicationInCreationQuestionnaireResponse,
        InvalidArgument | OperationFailed | QuestionnaireParsingFailed
    >;
}

@injectable()
class GetApplicationInCreationQuestionnaireUseCase
    extends UseCase
    implements GetApplicationInCreationQuestionnaire
{
    public static type = Symbol('Shopping/GetApplicationInCreationQuestionnaire');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(Log) private logger: Logger,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
        @inject(ApplicantRepository) private applicantRepository: ApplicantRepository,
        @inject(OverrideQuestionnaireData.type)
        private overrideQuestionnaireData: OverrideQuestionnaireData,
        @inject(GetFormEngineContext.type) private getFormEngineContext: GetFormEngineContext,
        @inject(GetFormEngineInstance.type) private getFormEngineInstance: GetFormEngineInstance,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
    }: GetApplicationInCreationQuestionnaireRequest): AsyncResult<
        GetApplicationInCreationQuestionnaireResponse,
        InvalidArgument | OperationFailed | QuestionnaireParsingFailed
    > {
        const getApplicationResponse = await this.applicationRepository.getApplication(
            applicationId,
        );
        if (isErr(getApplicationResponse)) {
            return getApplicationResponse;
        }
        const { value: application } = getApplicationResponse;

        const questionnaireDataResult = JSONSerdes.deserialize(
            application.questionnaireData ?? '{}',
        );
        if (isErr(questionnaireDataResult)) {
            return Failure(QuestionnaireParsingFailed(application.questionnaireData || '{}'));
        }

        const questionnaireData = questionnaireDataResult.value as QuestionnaireData;

        const enricherDataResult = await this.applicationRepository.getEnricherData(applicationId);
        let enrichmentData: EnrichmentData = {};
        if (isOK(enricherDataResult)) {
            enrichmentData = enricherDataResult.value;
        } else {
            // If the enricher data fetching fails, we do not stop a user from filling in the questionnaire
            this.logger.error(enricherDataResult.errors);
        }
        const prefillResponse = await this.applicationRepository.getPrefill();

        let prefill = {};
        if (isOK(prefillResponse)) {
            prefill = prefillResponse.value;
        } else {
            // If the prefill data fetching fails, we let the user fill in the questionnaire
            this.logger.error(prefillResponse.errors);
        }

        const overrideQuestionnaireDataResponse = await this.overrideQuestionnaireData.execute({
            questionnaireData,
            enrichmentData,
            prefill,
            overrideWebsite: true,
        });

        if (isErr(overrideQuestionnaireDataResponse)) {
            return overrideQuestionnaireDataResponse;
        }
        const overriddenQuestionnaireData =
            overrideQuestionnaireDataResponse.value.questionnaireData;

        let isAutofilled;
        const applicantResult = await this.applicantRepository.getApplicant();
        if (isOK(applicantResult)) {
            isAutofilled = applicantResult.value.isAutofilled;
        } else {
            // If the applicant data fetching fails, we do not stop a user from filling in the questionnaire
            this.logger.error(applicantResult.errors);
        }

        const parsedPrefill = toFormEngineInput(prefill);
        const parsedQuestionnaireData = toFormEngineInput(overriddenQuestionnaireData);

        const context = {
            appTypeList: [application.appType],
            shoppingCoverageList: application.shoppingCoverageList,
            data: parsedQuestionnaireData,
            isBroker: true,
            isAutofilled,
            newApplication: true,
            prefill: parsedPrefill,
            previousData: {},
        };

        const formEngineContextResponse = await this.getFormEngineContext.execute({
            context,
        });

        if (isErr(formEngineContextResponse)) {
            return formEngineContextResponse;
        }

        const { formEngineContext } = formEngineContextResponse.value;

        const getFormEngineResult = await this.getFormEngineInstance.execute({
            isNewApplication: true,
            formEngineContext,
        });
        if (isErr(getFormEngineResult)) {
            return getFormEngineResult;
        }
        const formEngine = getFormEngineResult.value.instance;

        formEngine.machine.update({
            submitText: 'Save and Continue',
        });

        const result: GetApplicationInCreationQuestionnaireResponse = {
            formEngine,
            application: {
                ...application.toDTO(),
                isRenewal: application.isRenewal(),
            },
        };

        return Success(result);
    }
}

export const GetApplicationInCreationQuestionnaire: UseCaseClass<GetApplicationInCreationQuestionnaire> =
    GetApplicationInCreationQuestionnaireUseCase;
