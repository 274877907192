import {
    defineValidator,
    Joi,
    Schema,
    TypeChecker,
    Validator,
} from '@embroker/shotwell/core/validation/schema';

/**
 * Represent CertificateDocument Object
 */

export interface CertificateDocument {
    /**
     * Date when document was uploaded
     */
    dateUploaded: Date;
    /**
     * S3 file key
     */
    fileKey: string;
    /**
     * Is it an approved certificate document
     */
    isActive: boolean;
    /**
     * Name of the document
     */
    name?: string;
    /**
     * Certificate document type
     */
    type: string;
}

export interface CertificateDocumentValidator {
    /**
     * A Joi schema matching a valid CertificateDocument object.
     */
    readonly schema: Schema.ObjectSchema<CertificateDocument>;
    /**
     * Type predicate that checks if a given value can be used as CertificateDocument object.
     *
     * Use this only to do early returns. It's recommended to use validate()
     * before using an unknown value as CertificateDocument object as it normalizes the value
     * in addition to performing the same validation as check().
     */
    readonly check: TypeChecker<CertificateDocument>;
    /**
     * Validates and normalizes the given value to a CertificateDocument object.
     *
     * This should be used for all untrusted inputs to verify and, if required
     * (and possible), normalize the input.
     */
    readonly validate: Validator<CertificateDocument>;
}

export const CertificateDocument = {
    ...defineValidator<CertificateDocument>(
        Joi.object({
            dateUploaded: Joi.date(),
            fileKey: Joi.string(),
            isActive: Joi.boolean(),
            name: Joi.string().optional(),
            type: Joi.string(),
        }).preferences({ presence: 'required' }),
    ),
    create(certificateDocument: CertificateDocument) {
        return CertificateDocument.validate(certificateDocument);
    },
};
