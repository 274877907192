import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success, isOK } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass, execute } from '@embroker/shotwell/core/UseCase';
import { BundleQuote } from '../entities/BundleQuote';
import { GetApplication } from '@app/shopping/useCases/GetApplication';
import { QuestionnaireData } from '../types/BundleQuoteQuestionnaireData';
import { CoveragePackageType, getRequoteForPackageType } from './GetRecommendedCoverageQuotes';

export interface ConfirmRecommendedCoverageSelectionRequest {
    abortSignal: AbortSignal;
    bundleQuote: BundleQuote;
    coveragePackageType: CoveragePackageType;
}

export interface ConfirmRecommendedCoverageSelection extends UseCase {
    execute(
        request: ConfirmRecommendedCoverageSelectionRequest,
    ): AsyncResult<
        ConfirmRecommendedCoverageSelectionResponse,
        InvalidArgument | OperationFailed | UnknownEntity
    >;
}

export interface ConfirmRecommendedCoverageSelectionResponse {
    requotedBundleQuote: BundleQuote;
}

class ConfirmRecommendedCoverageSelectionUseCase
    extends UseCase
    implements ConfirmRecommendedCoverageSelection
{
    public static type = Symbol('BundleQuote/ConfirmRecommendedCoverageSelection');

    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    public async execute({
        abortSignal,
        bundleQuote,
        coveragePackageType,
    }: ConfirmRecommendedCoverageSelectionRequest): AsyncResult<
        ConfirmRecommendedCoverageSelectionResponse,
        InvalidArgument | OperationFailed | UnknownEntity
    > {
        const applicationId = bundleQuote.applicationId;

        const getApplicationResult = await execute(GetApplication, {
            applicationId,
        });
        if (!isOK(getApplicationResult)) {
            return getApplicationResult;
        }

        const questionnaireDataResp = QuestionnaireData.deserializeQuestionnaireData(
            getApplicationResult.value.application.questionnaireData,
        );

        if (!isOK(questionnaireDataResp)) {
            return questionnaireDataResp;
        }

        const questionnaireData = questionnaireDataResp.value;

        const requotedBundleQuoteResult = await getRequoteForPackageType({
            abortSignal,
            bundleQuote,
            packageType: coveragePackageType,
            questionnaireData,
        });

        if (!isOK(requotedBundleQuoteResult)) {
            return requotedBundleQuoteResult;
        }

        return Success({ requotedBundleQuote: requotedBundleQuoteResult.value });
    }
}

export const ConfirmRecommendedCoverageSelection: UseCaseClass<ConfirmRecommendedCoverageSelection> =
    ConfirmRecommendedCoverageSelectionUseCase;
