import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';

const ClickEventNameList = [
    'New business Return visitor Continue Existing Application Pop up Displayed',
    'New business Return visitor Continue Existing Application Pop up _ continue clicked',
    'New business Return visitor Continue Existing Application Pop up closed',
] as const;
type SingleInProgressAppWelcomeBackPopupClickEventNameList = typeof ClickEventNameList;
export type SingleInProgressAppWelcomeBackPopupClickEventName =
    SingleInProgressAppWelcomeBackPopupClickEventNameList[number];

export enum SingleInProgressAppWelcomeBackPopupEventType {
    singleInProgressAppPopupDisplayed = 'singleInProgressAppPopupDisplayed',
    singleInProgressAppPopupContinueClicked = 'singleInProgressAppPopupContinueClicked',
    singleInProgressAppPopupClosed = 'singleInProgressAppPopupClosed',
}

export interface SingleInProgressAppWelcomeBackPopupEvent extends DomainEvent {
    name: 'SingleInProgressAppWelcomeBackPopupEvent';
    clickEventName: SingleInProgressAppWelcomeBackPopupClickEventName;
}

export interface PublishSingleInProgressAppWelcomeBackPopupEvent extends UseCase {
    execute(request: PublishSingleInProgressAppWelcomeBackPopupEventRequest): AsyncResult<void>;
}

export interface PublishSingleInProgressAppWelcomeBackPopupEventRequest {
    eventType: SingleInProgressAppWelcomeBackPopupEventType;
}

@injectable()
class PublishSingleInProgressAppWelcomeBackPopupEventUseCase
    extends UseCase
    implements PublishSingleInProgressAppWelcomeBackPopupEvent
{
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/PublishSingleInProgressAppWelcomeBackPopupEventUseCase');

    /**
     * Constructor for PublishSingleInProgressAppWelcomeBackPopupEvent class instance
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    /**
     * Executes the SingleInProgressAppWelcomeBackPopupEvent use case.
     */
    public async execute({
        eventType,
    }: PublishSingleInProgressAppWelcomeBackPopupEventRequest): AsyncResult<void> {
        let clickEventName: SingleInProgressAppWelcomeBackPopupClickEventName | null = null;

        switch (eventType) {
            case SingleInProgressAppWelcomeBackPopupEventType.singleInProgressAppPopupDisplayed: {
                clickEventName =
                    'New business Return visitor Continue Existing Application Pop up Displayed';
                break;
            }

            case SingleInProgressAppWelcomeBackPopupEventType.singleInProgressAppPopupContinueClicked: {
                clickEventName =
                    'New business Return visitor Continue Existing Application Pop up _ continue clicked';
                break;
            }

            case SingleInProgressAppWelcomeBackPopupEventType.singleInProgressAppPopupClosed: {
                clickEventName =
                    'New business Return visitor Continue Existing Application Pop up closed';
                break;
            }
        }

        if (clickEventName) {
            const event: SingleInProgressAppWelcomeBackPopupEvent = {
                origin: 'User',
                name: 'SingleInProgressAppWelcomeBackPopupEvent',
                clickEventName,
                createdAt: new Date(Date.now()),
                id: UUID.create(),
            };
            await this.eventBus.publish(event);
        }

        return Success();
    }
}

export const PublishSingleInProgressAppWelcomeBackPopupEvent: UseCaseClass<PublishSingleInProgressAppWelcomeBackPopupEvent> =
    PublishSingleInProgressAppWelcomeBackPopupEventUseCase;
