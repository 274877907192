import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { isDomainEventLocal } from '@embroker/shotwell/core/event/DomainEvent';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { execute } from '@embroker/shotwell/core/UseCase';
import { useDomainEvent } from '@embroker/shotwell/view/hooks/useDomainEvent';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Immutable, useModal, UserMenu } from '@embroker/ui-toolkit/v2';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-navi';
import {
    Organization,
    OrganizationCreated,
    OrganizationUpdated,
} from '../../userOrg/entities/Organization';
import { SessionOrganizationSelected } from '../../userOrg/entities/Session';
import { UserUpdated } from '../../userOrg/entities/User';
import { GetActiveOrganizationProfile } from '../../userOrg/useCases/GetActiveOrganizationProfile';
import {
    GetActiveUserProfile,
    GetActiveUserProfileResponse,
} from '../../userOrg/useCases/GetActiveUserProfile';
import { CompanySelectionModal } from '../../userOrg/view/components/CompanySelectionModal';

export function AuthenticatedUserMenu() {
    const { result: userProfile } = useUseCase(GetActiveUserProfile);
    const { result: companyProfile } = useUseCase(GetActiveOrganizationProfile);
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [organizationLegalName, setOrganizationLegalName] = useState('');
    const [organizationEmail, setOrganizationEmail] = useState('');
    const [hasMultipleOrganizations, setHasMultipleOrganizations] = useState(false);
    const companySelectionModal = useModal();

    useDomainEvent<UserUpdated>('User', 'Updated', async (event) => {
        if (!isDomainEventLocal(event)) {
            return;
        }
        const result = await execute(GetActiveUserProfile);
        if (isOK(result)) {
            setUserDetails(result.value as GetActiveUserProfileResponse);
        }
    });

    useDomainEvent<OrganizationUpdated>('Organization', 'Updated', async (event) => {
        if (!isDomainEventLocal(event)) {
            return;
        }
        const result = await execute(GetActiveOrganizationProfile);
        if (isOK(result)) {
            setOrganizationDetails(result.value.organization);
        }
    });

    useDomainEvent<SessionOrganizationSelected>(
        'Session',
        'OrganizationSelected',
        async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }
            const result = await execute(GetActiveOrganizationProfile);
            if (isOK(result)) {
                setOrganizationDetails(result.value.organization);
            }
        },
    );

    useDomainEvent<OrganizationCreated>('Organization', 'Created', async (event) => {
        if (!isDomainEventLocal(event)) {
            return;
        }
        const result = await execute(GetActiveUserProfile);
        if (isOK(result)) {
            setUserDetails(result.value as GetActiveUserProfileResponse);
        }
    });

    const setUserDetails = useCallback(
        (userProfile: GetActiveUserProfileResponse) => {
            setFullName(userProfile.firstName + ' ' + userProfile.lastName);
            setEmail(userProfile.email);
            setHasMultipleOrganizations(userProfile.organizations.length > 1);
        },
        [setFullName, setHasMultipleOrganizations],
    );

    const setOrganizationDetails = useCallback(
        (organization: Immutable<EntityProps<Organization>>) => {
            setOrganizationLegalName(organization.companyLegalName);
            setOrganizationEmail(organization.email?.toString() ?? '');
        },
        [setOrganizationLegalName],
    );

    useEffect(() => {
        if (companyProfile != null && isOK(companyProfile)) {
            setOrganizationDetails(companyProfile.value.organization);
        }
    }, [companyProfile, setOrganizationDetails]);

    useEffect(() => {
        if (userProfile != null && isOK(userProfile)) {
            setUserDetails(userProfile.value as GetActiveUserProfileResponse);
        }
    }, [userProfile, setUserDetails]);

    return (
        <UserMenu
            data-user-full-name={fullName}
            data-user-email={email}
            data-organization-name={organizationLegalName}
            data-organization-email={organizationEmail}
            title={fullName}
            subtitle={organizationLegalName}
        >
            <UserMenu.Item
                data-e2e="auth-user-menu-user-profile-link"
                as={Link}
                icon="user-profile"
                href="/user/profile"
            >
                Edit Profile
            </UserMenu.Item>
            <UserMenu.Item
                data-e2e="auth-user-menu-change-password-link"
                as={Link}
                href="/user/change-password"
            >
                Change Password
            </UserMenu.Item>
            <UserMenu.Item
                data-e2e="auth-user-menu-company-profile-link"
                as={Link}
                href="/company-profile"
            >
                Edit Company Profile
            </UserMenu.Item>
            <UserMenu.Item
                data-e2e="auth-user-menu-edit-team-settings-link"
                as={Link}
                href="/user/manage-users"
            >
                Edit Team Settings
            </UserMenu.Item>
            <UserMenu.Separator />
            <UserMenu.Item
                data-e2e="auth-user-menu-switch-add-company-link"
                href="#"
                icon="companies"
                onClick={companySelectionModal.show}
            >
                {hasMultipleOrganizations ? 'Switch Companies' : 'Add a New Company'}
            </UserMenu.Item>
            <UserMenu.Separator />
            <UserMenu.Item data-e2e="auth-user-menu-sign-out-link" icon="sign-out" href="/logout">
                Sign Out
            </UserMenu.Item>

            <CompanySelectionModal modal={companySelectionModal} />
        </UserMenu>
    );
}

export function AuthenticatedBrokerUserMenu() {
    const { result: userProfile } = useUseCase(GetActiveUserProfile);
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');

    const setUserDetails = useCallback(
        (userProfile: GetActiveUserProfileResponse) => {
            setFullName(userProfile.firstName + ' ' + userProfile.lastName);
            setEmail(userProfile.email);
        },
        [setFullName],
    );

    useDomainEvent<UserUpdated>('User', 'Updated', async (event) => {
        if (!isDomainEventLocal(event)) {
            return;
        }
        const result = await execute(GetActiveUserProfile);
        if (isOK(result)) {
            setUserDetails(result.value as GetActiveUserProfileResponse);
        }
    });

    useEffect(() => {
        if (userProfile != null && isOK(userProfile)) {
            setUserDetails(userProfile.value as GetActiveUserProfileResponse);
        }
    }, [userProfile, setUserDetails]);

    return (
        <UserMenu data-user-full-name={fullName} data-user-email={email} title={fullName}>
            <UserMenu.Item
                data-e2e="auth-menu-broker-profile"
                as={Link}
                icon="user-profile"
                href="/broker/profile"
            >
                Edit Profile
            </UserMenu.Item>
            <UserMenu.Item
                data-e2e="auth-menu-broker-change-pwd"
                as={Link}
                href="/broker/change-password"
            >
                Change Password
            </UserMenu.Item>
            <UserMenu.Separator />
            <UserMenu.Item data-e2e="auth-menu-broker-sign-out" icon="sign-out" href="/logout">
                Sign Out
            </UserMenu.Item>
        </UserMenu>
    );
}
