import { InsuranceApplicationStatusCodeListItem } from '@embroker/shotwell-api/enums';
import { Nullable } from '@embroker/shotwell/core/types';
import { State } from '@embroker/shotwell/core/types/StateList';
import { Joi, defineValidator } from '@embroker/shotwell/core/validation/schema';

export type WorkSpaceType = 'office_owned' | 'office_rented' | 'coworking' | 'home_office';

export interface CNAAppInfo {
    fullName: string;
    title: string;
    usaState: State;
    businessDescription: string;
    workSpaceType: WorkSpaceType;
    isBuildingInsuranceRequired: Nullable<boolean>;
    company: string;
    status: InsuranceApplicationStatusCodeListItem;
}

export const CNAAppInfo = {
    ...defineValidator<CNAAppInfo>({
        fullName: Joi.string().required(),
        title: Joi.string().required(),
        usaState: Joi.string().required(),
        businessDescription: Joi.string().allow('').required(),
        workSpaceType: Joi.string()
            .valid('office_owned', 'office_rented', 'coworking', 'home_office')
            .required(),
        isBuildingInsuranceRequired: Joi.bool().allow(null),
        company: Joi.string().required(),
        status: Joi.string().required(),
    }),
    create(cnaAppInfo: CNAAppInfo) {
        return CNAAppInfo.validate(cnaAppInfo);
    },
};
