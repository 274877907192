import { LPLQuoteOptions } from '../../../quote/lpl/types/LPLQuoteOptions';
import { BundleQuote } from '@app/bundle/entities/BundleQuote';
import { QuestionnaireData } from '@app/bundle/types/BundleQuoteQuestionnaireData';
import { CoveragePackageType } from '@app/bundle/useCases/GetRecommendedCoverageQuotes';

export const getRecommendedQuotingOptionsLpl = (
    bundleQuote: BundleQuote,
    packageType: CoveragePackageType,
    questionnaireData: QuestionnaireData,
): LPLQuoteOptions | undefined => {
    const revenueRange = QuestionnaireData.getRevenueRangeFromQuestionnaire(questionnaireData);
    const isDeselected = !bundleQuote.isCoverageSelected('LPLBundleCoverage');
    switch (packageType) {
        case 'starter': {
            const perClaimLimit = revenueRange === 'LOW' ? 500000 : 1000000;
            return {
                effectiveDate: bundleQuote.effectiveDate,
                perClaimLimit,
                aggregateLimit: 1000000,
                perClaimDeductible: 2500,
                deductibleType: 'DeductibleTypeLossAndClaimsExpense',
                claimsExpenseType: 'ClaimsExpenseTypeInsideOfLimits',
                separateClaimExpenseLimit: null,
                isDeselected,
            };
        }
        case 'enhanced': {
            const perClaimLimit = revenueRange === 'LOW' ? 1000000 : 2000000;
            const aggregateLimit = revenueRange === 'LOW' ? 1000000 : 4000000;
            return {
                effectiveDate: bundleQuote.effectiveDate,
                perClaimLimit,
                aggregateLimit,
                perClaimDeductible: 2500,
                deductibleType: 'DeductibleTypeLossAndClaimsExpense',
                claimsExpenseType: 'ClaimsExpenseTypeInsideOfLimits',
                separateClaimExpenseLimit: null,
                isDeselected,
            };
        }
        case 'deluxe': {
            const aggregateLimit = revenueRange === 'LOW' ? 1000000 : 4000000;
            const perClaimLimit = revenueRange === 'LOW' ? 1000000 : 4000000;
            const claimsExpenseType = ['LOW', 'MEDIUM'].includes(revenueRange)
                ? 'ClaimsExpenseTypeSeparateClaimsExpenses'
                : 'ClaimsExpenseTypeInAdditionToLimits';

            const separateClaimExpenseLimit =
                revenueRange === 'LOW' ? 250000 : revenueRange === 'MEDIUM' ? 500000 : null;

            return {
                effectiveDate: bundleQuote.effectiveDate,
                perClaimLimit,
                aggregateLimit,
                perClaimDeductible: 2500,
                deductibleType: 'DeductibleTypeLossAndClaimsExpense',
                claimsExpenseType,
                separateClaimExpenseLimit,
                isDeselected,
            };
        }
    }
};
