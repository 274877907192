import { Money } from '@embroker/shotwell/core/types/Money';
import { defineValidator } from '@embroker/shotwell/core/validation/schema';

export interface ESPTotalsAndDiscounts {
    readonly totalCoverages: Money;
    readonly subTotal: Money;
    readonly taxes: Money;
    readonly feesAndSurcharges: Money;
    readonly policyFee: Money;
    readonly mwuaFee?: Money;
}

export const ESPTotalsAndDiscounts = {
    ...defineValidator<ESPTotalsAndDiscounts>({
        totalCoverages: Money.schema.required(),
        subTotal: Money.schema.required(),
        taxes: Money.schema.required(),
        feesAndSurcharges: Money.schema.required(),
        policyFee: Money.schema.required(),
        mwuaFee: Money.schema.optional(),
    }),
    create(rates: ESPTotalsAndDiscounts) {
        return ESPTotalsAndDiscounts.validate(rates);
    },
};
