import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { hasOwnProp } from '@embroker/shotwell/core/object';
import { Data, Immutable, Nullable, Props } from '@embroker/shotwell/core/types';
import { Failure, Result, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Organization } from '../../../userOrg/entities/Organization';
import { User } from '../../../userOrg/entities/User';
import { GoogleAnalyticsRepository } from './index';
import { SKU } from '../../types/SKU';

interface AddressTrails {
    city: Nullable<string>;
    postalCode: Nullable<string>;
    state: Nullable<string>;
    street: string;
}

interface OrganizationTraits {
    name: string;
    id: UUID;
    employee_count: Nullable<number>;
    industry: Nullable<string>;
    isTestOrganization: Nullable<boolean>;
}

interface UserTraits {
    email: Nullable<string>;
    firstName: Nullable<string>;
    lastName: Nullable<string>;
    createdAt: Nullable<Date>;
    companyID: Nullable<UUID>;
    company?: OrganizationTraits;
    phone?: Nullable<string>;
    address?: AddressTrails;
    loggedViaAdmin?: boolean;
    isBroker?: boolean;
}

@injectable()
export class APIGoogleAnalyticsRepository implements GoogleAnalyticsRepository {
    constructor(@inject(Log) private logger: Logger) {
        if (!APIGoogleAnalyticsRepository.isGTMDataLayerReady()) {
            logger.warn('No data layer in window. Analytics may not work');
        }
    }

    public sendLoginEvent(
        user: Immutable<Props<User>>,
        organization: Nullable<Immutable<Props<Organization>>>,
        loggedViaAdmin?: boolean,
        isFirstLogin?: boolean,
    ): Result<void, InvalidArgument | OperationFailed> {
        if (!user) {
            this.logger.error('Invalid user object in sendLoginEvent analytics');
            return Failure(InvalidArgument({ argument: 'user', value: user }));
        }

        const buildUserTraitsResult: UserTraits = this.buildUserTraits(user, organization);
        const userTraits = {
            ...buildUserTraitsResult,
            loggedViaAdmin: loggedViaAdmin ?? false,
        };

        return APIGoogleAnalyticsRepository.sendEventToGTM('Login', {
            userId: user.id,
            userTraits: userTraits,
            isFirstLogin: isFirstLogin || false,
        });
    }

    public sendRegistrationEvent(
        user: Immutable<Props<User>>,
        organization: Nullable<Immutable<Props<Organization>>>,
        sku?: SKU,
    ): Result<void, InvalidArgument | OperationFailed> {
        if (!user) {
            this.logger.error('Invalid user object in sendRegistrationEvent analytics');
            return Failure(InvalidArgument({ argument: 'user', value: user }));
        }

        const buildUserTraitsResult: UserTraits = this.buildUserTraits(user, organization);
        const userTraits = {
            ...buildUserTraitsResult,
            loggedViaAdmin: false,
        };

        return APIGoogleAnalyticsRepository.sendEventToGTM('Signup Completed', {
            userId: user.id,
            company_id: userTraits.companyID,
            userTraits: userTraits,
            sku_string: sku?.sku_string,
            sku_string_plaintext: sku?.sku_string_plaintext,
        });
    }

    public updateUserTraits(
        user: Immutable<Props<User>>,
        organization: Nullable<Immutable<Props<Organization>>>,
    ): Result<void, InvalidArgument | OperationFailed> {
        if (!user) {
            this.logger.error('Invalid user object in updateUserData analytics');
            return Failure(InvalidArgument({ argument: 'user', value: user }));
        }

        const userTraits: UserTraits = this.buildUserTraits(user, organization);

        return APIGoogleAnalyticsRepository.sendEventToGTM('Update User Traits', {
            userId: user.id,
            userTraits: userTraits,
        });
    }

    public sendCustomEvent(
        eventName: string,
        payload?: Data,
    ): Result<void, InvalidArgument | OperationFailed> {
        const eventData = {
            em$eventProperties: payload ?? {},
        };
        return APIGoogleAnalyticsRepository.sendEventToGTM(eventName, eventData);
    }

    private buildUserTraits(
        user: Immutable<Props<User>>,
        organization: Nullable<Immutable<Props<Organization>>>,
    ): UserTraits {
        const userTraits: UserTraits = {
            email: user.email || '',
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            phone: user.phoneNumber,
            createdAt: user.createdAt,
            companyID: null,
            isBroker: user.isBroker,
        };

        if (organization !== null) {
            userTraits.companyID = organization.id;
            userTraits.company = {
                name: organization.companyLegalName,
                id: organization.id,
                employee_count: organization.totalNumberOfEmployees,
                isTestOrganization: organization.isTestOrganization,
                industry: organization.naics,
            };

            if (organization.headquarters !== null) {
                userTraits.address = {
                    city: organization.headquarters.city,
                    postalCode: organization.headquarters.zip,
                    state: organization.headquarters.state,
                    street: APIGoogleAnalyticsRepository.toCSVAddressLines(
                        organization.headquarters.addressLine1,
                        organization.headquarters.addressLine2,
                    ),
                };
            }
        }
        return userTraits;
    }

    /**
     * Parse address lines to csv
     * @param addressLine1
     * @param addressLine2
     */
    private static toCSVAddressLines(
        addressLine1: string | null | undefined,
        addressLine2: string | null | undefined,
    ): string {
        let addressLine;
        if (typeof addressLine1 === 'string' && addressLine1.trim().length !== 0) {
            addressLine = addressLine1;
        }
        if (typeof addressLine2 === 'string' && addressLine2.trim().length !== 0) {
            if (addressLine) {
                addressLine += `\n${addressLine2}`;
            } else {
                addressLine = addressLine2;
            }
        }
        if (addressLine === undefined) {
            return '';
        }
        return addressLine;
    }

    /**
     * Send event data to GTM platform
     * @param eventName Name of GTM event
     * @param eventData GTM event data for analytics statistics
     */
    private static sendEventToGTM(
        eventName: string,
        eventData: object,
    ): Result<void, OperationFailed> {
        const event = `em$${eventName}`;
        const gtmDataLayer = this.getGTMDataLayer();
        if (gtmDataLayer == null) {
            return Failure(OperationFailed({ message: 'No dataLayer in window object.' }));
        }
        const eventObject = { event, ...eventData };
        gtmDataLayer.push(eventObject);
        return Success();
    }

    /**
     * Check whether dataLayer array is imprinted inside window object.
     * This should naturally happen inside index page of web application.
     * If application is not supporting GTM and therefore does not have dataLayer,
     * this function will yield false, otherwise true is returned.
     */
    private static isGTMDataLayerReady() {
        return this.getGTMDataLayer() != null;
    }

    /**
     * Gets imprinted GTM dataLayer array from window object.
     * GTM naturally is being set up inside index page of the web application.
     * This set up consists of initializing dataLayer array, and injecting
     * GTM script which watches dataLayer, and handles asynchronously all jobs
     * (events, variable set requirements) that are being posted to dataLayer.
     * In order to schedule an event or set certain variable in GTM scope,
     * you just need to push an object to this array.
     * If application is not supporting GTM and therefore does not have dataLayer,
     * this function will yield null, otherwise GTM dataLayer array is returned.
     */
    private static getGTMDataLayer(): Nullable<Array<any>> {
        if (hasOwnProp(globalThis, 'dataLayer', Array.isArray)) {
            return (globalThis as { [key: string]: any })['dataLayer'] as Array<any>;
        }
        return null;
    }
}
