import { UUID } from '@embroker/shotwell/core/types/UUID';
import { valueObject } from '@embroker/shotwell/core/types/ValueObject';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { ESPCoverageType, ESPCoverageTypes } from './CoverageRestriction';

export interface EndorsementCustomInputItem {
    id: UUID;
    value: string;
}

export const EndorsementCustomInputItem = valueObject({
    ...defineValidator<EndorsementCustomInputItem>({
        id: UUID.schema.required(),
        value: Joi.string().required(),
    }),
});

export type EndorsementCustomInputList = Array<EndorsementCustomInputItem>;

export const EndorsementCustomInputList = valueObject({
    ...defineValidator<EndorsementCustomInputList>(
        Joi.array().items(EndorsementCustomInputItem.schema),
    ),
});

export interface Endorsement {
    id: UUID;
    name: string;
    displayName?: string;
    inputList: EndorsementCustomInputList;
    allowedCoverages?: Array<ESPCoverageType>;
}

export const Endorsement = {
    ...defineValidator<Endorsement>({
        id: UUID.schema.required(),
        name: Joi.string(),
        displayName: Joi.string().optional().allow(''),
        inputList: EndorsementCustomInputList.schema,
        allowedCoverages: Joi.array()
            .items(Joi.string().valid(...ESPCoverageTypes))
            .optional(),
    }),
    create(endorsement: Endorsement) {
        return Endorsement.validate(endorsement);
    },
};

export type EndorsementList = Array<Endorsement>;

export const EndorsementList = valueObject({
    ...defineValidator<EndorsementList>(Joi.array().items(Endorsement.schema)),
});
