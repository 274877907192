import { OperationFailed } from '@embroker/shotwell/core/Error';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Immutable } from '@embroker/shotwell/core/types';
import { ErrorLike, Failure, Success, isErr } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import {
    DefaultFormFieldRegistry,
    Renderer as Form,
    FormFieldRegistry,
    JSONSchema,
    useJSONSchemaForm,
} from '@embroker/shotwell/view/components/JSONSchemaForm';
import { useAsyncTrigger } from '@embroker/shotwell/view/hooks/useAsyncTrigger';
import { FormData } from '@embroker/shotwell/view/hooks/useForm';
import {
    Button,
    ButtonBar,
    Loader,
    RadioGroup,
    StackLayout,
    StatusMessage,
    Text,
    TextButton,
    useModal,
} from '@embroker/ui-toolkit/v2';
import React, { useCallback, useState } from 'react';
import { DocumentPublicationType, EndorsementContext, validateEndorsement } from '../types';
import { IssueEndorsement } from '../useCases/IssueEndorsementUseCase';
import { PreviewEndorsement } from '../useCases/PreviewEndorsementUseCase';
import { DocumentModal } from './DocumentModal';

export type NonPremiumBearingEndorsementJSONSchemaFormProps<
    T extends FormData,
    U extends string,
> = {
    endorsementTitle: string;
    jsonSchema: JSONSchema<T>;
    onDismiss(): void;
    onBack(): void;
    initialValue?: Partial<T>;
    endorsementContext: EndorsementContext;
    formFieldRegistry?: FormFieldRegistry<U>;
};

export function NonPremiumBearingEndorsementJSONSchemaForm<T extends FormData, U extends string>(
    props: NonPremiumBearingEndorsementJSONSchemaFormProps<T, U>,
) {
    const {
        endorsementTitle,
        jsonSchema,
        onDismiss,
        onBack,
        initialValue,
        endorsementContext,
        formFieldRegistry = new DefaultFormFieldRegistry<any>(),
    } = props;

    const documentModal = useModal();
    const [documentPublicationType, setDocumentPublicationType] = useState<DocumentPublicationType>(
        DocumentPublicationType.Preview,
    );
    const [endorsementDocumentUrl, setEndorsementDocumentUrl] = useState<URI | undefined>();

    // Extract the namespace and corresponding subschema
    const namespace = jsonSchema.$id ?? '';
    const subschema = jsonSchema.properties[namespace];

    const [errors, setErrors] = useState<Immutable<ErrorLike[]>>();

    const { form, resolvedJSONSchema } = useJSONSchemaForm(
        subschema as JSONSchema<T>,
        formFieldRegistry,
        initialValue ?? {},
    );

    const {
        trigger: handleIssueOrPreviewEndorsement,
        isLoading: isIssueOrPreviewEndorsementLoading,
    } = useAsyncTrigger(async () => {
        if (action && action == 'issue') {
            handleIssueEndorsement();
        } else if (action && action == 'preview') {
            handlePreviewEndorsement();
        }

        return Success();
    });

    const { trigger: handleIssueEndorsement, isLoading: isIssueEndorsementLoading } =
        useAsyncTrigger(async () => {
            if (endorsementContext.endorsementId === undefined) {
                const error: OperationFailed = OperationFailed({
                    message: 'Endorsement has not been precreated.',
                });
                setErrors([error]);
                return Failure(error);
            }

            const [valid, errorMsg] = validateEndorsement(
                endorsementContext,
                form.value as Record<string, any>,
            );
            if (!valid) {
                const error: OperationFailed = OperationFailed({
                    message: errorMsg,
                });
                setErrors([error]);
                return Failure(error);
            }

            const issueEndorsementResult = await execute(IssueEndorsement, {
                endorsementId: endorsementContext.endorsementId,
                endorsementData: { [namespace]: form.value },
            });

            if (isErr(issueEndorsementResult)) {
                setErrors(issueEndorsementResult.errors);
            } else {
                setErrors(undefined);
                setEndorsementDocumentUrl(issueEndorsementResult.value);
                setDocumentPublicationType(DocumentPublicationType.Issue);
                documentModal.show();
            }

            return issueEndorsementResult;
        });

    const { trigger: handlePreviewEndorsement, isLoading: isPreviewEndorsementLoading } =
        useAsyncTrigger(async () => {
            if (endorsementContext.endorsementId === undefined) {
                const error: OperationFailed = OperationFailed({
                    message: 'Endorsement has not been precreated.',
                });
                setErrors([error]);
                return Failure(error);
            }

            const [valid, errorMsg] = validateEndorsement(
                endorsementContext,
                form.value as Record<string, any>,
            );
            if (!valid) {
                const error: OperationFailed = OperationFailed({
                    message: errorMsg,
                });
                setErrors([error]);
                return Failure(error);
            }

            const previewEndorsementResult = await execute(PreviewEndorsement, {
                endorsementId: endorsementContext.endorsementId,
                endorsementData: { [namespace]: form.value },
            });

            if (isErr(previewEndorsementResult)) {
                setErrors(previewEndorsementResult.errors);
            } else {
                setErrors(undefined);
                setEndorsementDocumentUrl(previewEndorsementResult.value);
                setDocumentPublicationType(DocumentPublicationType.Preview);
                documentModal.show();
            }

            return previewEndorsementResult;
        });

    const [action, setAction] = useState<'preview' | 'issue'>();

    const handleActionChange = useCallback(
        ({ target: { value } }: { target: { value: string } }) => {
            setAction(value as 'preview' | 'issue');
        },
        [],
    );

    const handlePerformAction = useCallback(() => {
        if (!action) {
            setErrors([OperationFailed({ message: 'Please select an action' })]);
            return;
        }
        setErrors(undefined);
        form.submit();
    }, [action, setErrors, form]);

    return (
        <StackLayout>
            {isIssueOrPreviewEndorsementLoading ||
            isIssueEndorsementLoading ||
            isPreviewEndorsementLoading ? (
                <Loader />
            ) : null}
            <DocumentModal
                modal={documentModal}
                policyId={endorsementContext.policyId}
                endorsementType={endorsementContext.endorsementType}
                documentPublicationType={documentPublicationType}
                fileUrl={endorsementDocumentUrl}
                onDismiss={onDismiss}
            />

            <Text data-e2e="title-kind-of-endorsement" style="heading 3">
                {endorsementTitle}
            </Text>

            <Form
                id="endorsementForm"
                form={form}
                json={resolvedJSONSchema}
                formFieldRegistry={formFieldRegistry}
                onSubmit={handleIssueOrPreviewEndorsement}
            />

            <Text style="heading 4">Available actions</Text>

            <StackLayout gap="32">
                <RadioGroup
                    items={[
                        { title: 'Preview Endorsement', value: 'preview' },
                        { title: 'Issue Endorsement', value: 'issue' },
                    ]}
                    value={action}
                    onChange={handleActionChange}
                />

                <ButtonBar split="-1">
                    <Button appearance="primary" onClick={handlePerformAction}>
                        Perform action
                    </Button>
                    <TextButton data-e2e="back-button" onClick={() => onBack()}>
                        Back
                    </TextButton>
                </ButtonBar>
            </StackLayout>

            {errors ? <StatusMessage status="error">{errors[0].message}</StatusMessage> : null}
        </StackLayout>
    );
}
