import { isDateAfter } from '@embroker/service-app-engine';
import { Nullable } from '@embroker/shotwell/core/types';
import { State } from '@embroker/shotwell/core/types/StateList';
import { Money, USD } from '@embroker/shotwell/core/types/Money';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    BoxLayout,
    Button,
    Card,
    ColumnLayout,
    Form,
    Icon,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import { addDays } from 'date-fns';
import React from 'react';
import { WizardForm } from '../../../../../view/hooks/useWizardForm';
import { PCoMLQuoteOptions } from '../../../entities/PCoMLQuote';
import {
    getLimitRetentionOptions,
    pcomlQuotesCoverageDetailsMap,
    renewalQuoteDocumentsPublishDetails,
} from '../../config';
import { PCoMLQuoteOptionsFormData } from '../PCoMLQuoteLandingPage';

interface PCoMLRecommendedCoverageProps {
    isRenewal: boolean;
    type: string;
    quoteOptions: PCoMLQuoteOptions;
    fields: WizardForm<OpaqueForm<PCoMLQuoteOptionsFormData>>['fields'];
    isSubmitting: boolean;
    newInsurerDocumentsReleaseDate?: Date;
    submittedAt?: Date;
    state?: Nullable<State>;
    handleIsSelected: () => void;
}

export const PCoMLRecommendedCoverage = ({
    isRenewal,
    type,
    quoteOptions,
    fields,
    isSubmitting,
    newInsurerDocumentsReleaseDate,
    submittedAt,
    state,
    handleIsSelected,
}: PCoMLRecommendedCoverageProps) => {
    const limitField = type == 'dno' ? fields.dnoLimit : fields.eplLimit;
    const previousLimitField =
        type == 'dno' ? quoteOptions.dno.previousLimit : quoteOptions.epl.previousLimit;
    const retentionField = type == 'dno' ? fields.dnoRetention : fields.eplRetention;
    const previousRetentionField =
        type == 'dno' ? quoteOptions.dno.previousRetention : quoteOptions.epl.previousRetention;

    const getUrlBasedOnSubmittedDate = (
        isRenewal: boolean,
        newInsurerDocumentsReleaseDate?: Date,
        submittedAt?: Date,
        state?: Nullable<State>,
    ): string => {
        if (newInsurerDocumentsReleaseDate !== undefined && submittedAt !== undefined) {
            if (
                isSubmissionDateAfterPublishDate(
                    isRenewal,
                    submittedAt,
                    newInsurerDocumentsReleaseDate,
                    state,
                )
            ) {
                return pcomlQuotesCoverageDetailsMap[type].newDocumentUrl;
            }
        }
        return pcomlQuotesCoverageDetailsMap[type].documentUrl;
    };

    const isSubmissionDateAfterPublishDate = (
        isRenewal: boolean,
        submittedAt: Date,
        newInsurerDocumentsReleaseDate: Date,
        state?: Nullable<State>,
    ): boolean => {
        let daysToPublishByState = 0;

        if (state !== undefined && state !== null && isRenewal) {
            daysToPublishByState = renewalQuoteDocumentsPublishDetails[state].daysToPublish;
        }

        return isDateAfter(
            submittedAt,
            addDays(newInsurerDocumentsReleaseDate, daysToPublishByState),
        );
    };

    return (
        <Card
            isSelected={false}
            locked={false}
            menuItems={[
                <TextButton
                    target="_blank"
                    key={`download${type}Coverage`}
                    href={getUrlBasedOnSubmittedDate(
                        isRenewal,
                        newInsurerDocumentsReleaseDate,
                        submittedAt,
                        state,
                    )}
                >
                    Download coverage details
                </TextButton>,
            ]}
        >
            <Card.Header>
                <Icon name={pcomlQuotesCoverageDetailsMap[type].icon} />
                <Text style="heading 5">{pcomlQuotesCoverageDetailsMap[type].title}</Text>
            </Card.Header>
            <Card.Body>
                <StackLayout>
                    <Text>{pcomlQuotesCoverageDetailsMap[type].text}</Text>
                    <ColumnLayout gap="16" responsive={{ screenWidth: { smallerThan: 'tablet' } }}>
                        <Form.Field
                            type={limitField.type}
                            inputProps={{
                                ...limitField.props,
                                filterable: false,
                                items: getLimitRetentionOptions(type, previousLimitField, state)
                                    .limits,
                                label: 'Limit',
                                disabled:
                                    getLimitRetentionOptions(type, previousLimitField, state).limits
                                        .length == 1 || isSubmitting,
                            }}
                        />
                        <Form.Field
                            type={retentionField.type}
                            inputProps={{
                                ...retentionField.props,
                                filterable: false,
                                items: getLimitRetentionOptions(type, previousLimitField, state)
                                    .retentions,
                                label: 'Retention',
                                disabled: isSubmitting,
                            }}
                        />
                    </ColumnLayout>
                    {isRenewal && (
                        <ColumnLayout>
                            <BoxLayout gap="12" className="u-1/2">
                                <StackLayout gap="none">
                                    <Text style="microcopy">Previous Limit:</Text>
                                    <Text style="body 1">
                                        {previousLimitField &&
                                        !Money.isEqual(previousLimitField, USD(0)) ? (
                                            <MoneyDisplay
                                                value={previousLimitField}
                                                fractionDigits={0}
                                            />
                                        ) : (
                                            <Text style="microcopy">Not included</Text>
                                        )}
                                    </Text>
                                </StackLayout>
                            </BoxLayout>
                            <BoxLayout gap="12" className="u-1/2">
                                <StackLayout gap="none">
                                    <Text style="microcopy">Previous Retention</Text>
                                    <Text style="body 1">
                                        {previousRetentionField &&
                                        !Money.isEqual(previousRetentionField, USD(0)) ? (
                                            <MoneyDisplay
                                                value={previousRetentionField}
                                                fractionDigits={0}
                                            />
                                        ) : (
                                            <Text style="microcopy">Not included</Text>
                                        )}
                                    </Text>
                                </StackLayout>
                            </BoxLayout>
                        </ColumnLayout>
                    )}
                </StackLayout>
            </Card.Body>
            <Card.Footer>
                <Button appearance="primary" onClick={handleIsSelected} disabled={isSubmitting}>
                    Add Coverage
                </Button>
            </Card.Footer>
        </Card>
    );
};
