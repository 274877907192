import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { valueObject } from '@embroker/shotwell/core/types/ValueObject';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { IconName } from '@embroker/ui-toolkit/v2';
import { Quote } from '../../quote/entities/Quote';
import { LPLQuote } from '../../quote/lpl/entities/LPLQuote';
import { BOPChubbQuote } from '../coverageDefinition/bopChubb/entities/BOPChubbQuote';
import { BOPChubbQuestionnaireData } from '../coverageDefinition/bopChubb/types/BOPChubbQuestionnaireData';
import { CowbellCyberQuote } from '../coverageDefinition/cowbellCyber/entities/CowbellCyberQuote';
import { LawCyberQuote } from '../coverageDefinition/lawCyber/entities/LawCyberQuote';
import { LawCyberQuestionnaireData } from '../coverageDefinition/lawCyber/types/LawCyberQuestionnaireData';
import { LPLQuestionnaireData } from '../coverageDefinition/lplEverest/LPLQuestionnaireData';
import { MPLQuote } from '../coverageDefinition/mpl/entities/MPLQuote';
import { MPLQuestionnaireData } from '../coverageDefinition/mpl/types/MPLQuestionnaireData';
import { WCChubbQuote } from '../coverageDefinition/wcChubb/entities/WCChubbQuote';
import { QuestionnaireData } from './BundleQuoteQuestionnaireData';
import { ESPQuote } from '../coverageDefinition/esp/entities/ESPQuote';

export type BundleType = 'LawBundle';
export const BundleCoverageTypeEnum = {
    LPLBundleCoverage: 'LPLBundleCoverage',
    MPLCoverage: 'MPLCoverage',
    LawCyberBundleCoverage: 'LawCyberBundleCoverage',
    LawCyberBundleCoverageCowbell: 'LawCyberBundleCoverageCowbell',
    BOPChubbBundleCoverage: 'BOPChubbCoverage',
    WCChubbBundleCoverage: 'WCChubbCoverage',
    ESPCoverage: 'ESPCoverage',
} as const;
export type BundleCoverageType =
    (typeof BundleCoverageTypeEnum)[keyof typeof BundleCoverageTypeEnum];
export const BundleCoverageAppStatusEnum = {
    QuotesReady: 'QuotesReady',
    Referred: 'Referred',
    ReferredAwaitingReview: 'ReferredAwaitingReview',
    NotEligible: 'NotEligible',
    ClearanceFailed: 'ClearanceFailed',
    UserDeclined: 'UserDeclined',
    Purchased: 'Purchased',
    CanceledByAdmin: 'CanceledByAdmin',
};
export type BundleCoverageAppStatus =
    (typeof BundleCoverageAppStatusEnum)[keyof typeof BundleCoverageAppStatusEnum];

export const BundleWarningEnum = {
    CyberQuoteOptionsOverride: 'CyberQuoteOptionsOverride',
};
export type BundleWarningStatus = (typeof BundleWarningEnum)[keyof typeof BundleWarningEnum];

export type BundleQuoteType =
    | LPLQuote
    | LawCyberQuote
    | CowbellCyberQuote
    | MPLQuote
    | BOPChubbQuote
    | WCChubbQuote
    | ESPQuote;

type DistributedBundleQuoteType<T> = T extends BundleQuoteType ? BundleQuoteCoverage<T> : never;

export type BundleQuoteCoverageType = DistributedBundleQuoteType<BundleQuoteType>;

export interface BundleQuoteCoverageMetadata {
    title: string;
    description: string;
    name: string;
    icon: IconName;
}

export type BundleQuestionnaireDataType =
    | LPLQuestionnaireData
    | MPLQuestionnaireData
    | LawCyberQuestionnaireData
    | BOPChubbQuestionnaireData
    | QuestionnaireData;

export type QuestionnaireDataType<T extends EntityProps<Quote>> = T extends EntityProps<LPLQuote>
    ? LPLQuestionnaireData
    : T extends EntityProps<MPLQuote>
    ? MPLQuestionnaireData
    : T extends EntityProps<LawCyberQuote>
    ? LawCyberQuestionnaireData
    : T extends EntityProps<BOPChubbQuote>
    ? BOPChubbQuestionnaireData
    : QuestionnaireData;

export interface BundleQuoteCoverage<T extends EntityProps<Quote>> {
    readonly type: BundleCoverageType;
    readonly status: BundleCoverageAppStatus;
    readonly quote?: T;
    readonly questionnaireData: QuestionnaireDataType<T>;
    readonly appValidUntil: Date;
    readonly coverageMetadata: BundleQuoteCoverageMetadata;
}

export const BundleQuoteCoverage = valueObject({
    ...defineValidator<BundleQuoteCoverage<any>>({
        type: Joi.string()
            .allow(...Object.values(BundleCoverageTypeEnum))
            .required(),
        status: Joi.string()
            .valid(...Object.values(BundleCoverageAppStatusEnum))
            .required(),
        quote: Joi.alternatives()
            .try(
                LPLQuote.schema,
                LawCyberQuote.schema,
                CowbellCyberQuote.schema,
                MPLQuote.schema,
                BOPChubbQuote.schema,
                WCChubbQuote.schema,
                ESPQuote.schema,
            )
            .optional(),
        questionnaireData: Joi.object().required(),
        appValidUntil: Joi.date(),
        coverageMetadata: Joi.object().required(), // TODO: check which validation shoud go here
    }),
});
