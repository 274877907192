import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import {
    Button,
    Form,
    Modal,
    ModalActions,
    ModalState,
    Spinner,
    Stack,
    StackLayout,
    Text,
    TextAreaInput,
    UploadFile,
    UploadFileCard,
    UploadFileState,
} from '@embroker/ui-toolkit/v2';
import React, { useContext, useState } from 'react';
import { UploadFiles, UploadFilesRequest } from '../../../../documents/useCases/UploadFiles';
import { FileUpload } from '../../../../documents/view/FileUpload';
import { AppContext } from '../../../../view/AppContext';
import { UploadBORLetter } from '../../../useCases/UploadBORLetter';

export interface UploadBORLetterModalProps {
    modal: ModalState & ModalActions;
    organizationId?: UUID;
}

export function UploadBORLetterModal({ modal, organizationId }: UploadBORLetterModalProps) {
    const { visible } = modal;
    const errorMessageDisplayTime = 3000;
    const [activePageIndex, setActivePageIndex] = useState(0);
    const [uploadPercent, setUploadPercent] = useState(0);
    const [dropBoxContainerState, setDropBoxContainerState] = useState<UploadFileState>('default');
    const [additionalNotes, setAdditionalNotes] = useState<string | undefined>();
    const [uploadedFile, setUploadedFile] = useState<FileUpload | undefined>(undefined);
    const { activeSession } = useContext(AppContext);

    const handleUploadSubmit = () => {
        if (uploadedFile && uploadedFile.s3FileKey && activeSession.userId) {
            execute(UploadBORLetter, {
                brokerId: activeSession.userId,
                fileKey: uploadedFile?.s3FileKey,
                additionalNotes: additionalNotes,
                organizationId,
            }).then((response) => {
                if (isErr(response)) {
                    container.get<Logger>(Log).error(response.errors);
                    return;
                }
                setActivePageIndex(1);
            });
        }
    };

    const handleNewFilesAdded = (files: File[]) => {
        if (!isSingleFile(files)) {
            setDropBoxContainerState('numberOfFilesError');
            setTimeout(() => {
                setDropBoxContainerState('default');
            }, errorMessageDisplayTime);
            return;
        }

        const newFile = files[0];

        if (!isFilePDF(newFile)) {
            setDropBoxContainerState('fileTypeError');
            setTimeout(() => {
                setDropBoxContainerState('default');
            }, errorMessageDisplayTime);
            return;
        }

        const uploadId = UUID.create();
        const fileToUpload: FileUpload = {
            id: uploadId,
            file: newFile,
            s3FileKey: null,
        };

        uploadFile(fileToUpload);
    };

    const uploadFile = (file: FileUpload) => {
        const files = [file];
        let isLoaded = true;
        execute(UploadFiles, {
            files: files.map((item) => item.file),
            onFileUploadProgress: (uploaded, totalSize) => {
                setUploadPercent((uploaded / totalSize) * 100);
            },
        } as UploadFilesRequest).then((result) => {
            if (isLoaded && isOK(result)) {
                setUploadedFile({
                    id: result.value.uploadedFiles[0].id,
                    file: file.file,
                    s3FileKey: result.value.uploadedFiles[0].fileKey,
                });
            }
        });
        return () => {
            isLoaded = false;
        };
    };

    const handleFileRemove = () => {
        setUploadedFile(undefined);
        setUploadPercent(0);
    };

    const handleAdditionalNotesChange = (event: { target: { value: string } }) => {
        setAdditionalNotes(event.target.value);
    };

    return (
        <Modal {...modal} size="small">
            <Stack activeIndex={activePageIndex}>
                <Form>
                    {visible ? (
                        <StackLayout gap="32">
                            <StackLayout gap="24">
                                <Text style="heading 3">Upload BOR letter</Text>
                                <Text style="body 1">
                                    Please upload a BOR letter below and click 'Confirm'. We will
                                    let you know the next steps. Additionally add any notes and/or
                                    instructions in the box below.
                                </Text>
                            </StackLayout>
                            <StackLayout gap="12">
                                <UploadFile
                                    onFilesAdded={handleNewFilesAdded}
                                    uploadPercent={uploadPercent}
                                    onUploadAbort={() => {
                                        //empty
                                    }}
                                    state={dropBoxContainerState}
                                    isMultiple={false}
                                    fileTypeFilters={['application/pdf']}
                                    showButton
                                    buttonText="Choose file"
                                    placeholder="or drag file here."
                                />
                                {uploadedFile && (
                                    <UploadFileCard
                                        file={uploadedFile.file}
                                        onUploadAbort={handleFileRemove}
                                        state="success"
                                        uploadPercent={0}
                                        className="u-1/2"
                                    />
                                )}
                                <TextAreaInput
                                    label="Additional notes (Optional)"
                                    onChange={handleAdditionalNotesChange}
                                    value={additionalNotes}
                                />
                            </StackLayout>
                            <Button onClick={handleUploadSubmit} disabled={!uploadedFile}>
                                Confirm
                            </Button>
                        </StackLayout>
                    ) : (
                        <Spinner />
                    )}
                </Form>
                <StackLayout gap="24">
                    <Text style="heading 3">Success!</Text>
                    <Text>
                        Thank you for uploading BOR letter. Our team will review your request and
                        get back to you shortly with further updates on the BOR status!
                    </Text>
                </StackLayout>
            </Stack>
        </Modal>
    );
}

function isSingleFile(files: File[]) {
    return files.length === 1;
}

function isFilePDF(file: File) {
    return file.type === 'application/pdf';
}
