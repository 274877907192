import { State } from '@embroker/shotwell/core/types/StateList';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { Form, ReactProps, StackLayout, Text, TextButton, useModal } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { WarrantyAndFraudModal } from '../../../../../signature/components/WarrantyAndFraudModal';
import { WizardForm } from '../../../../../view/hooks/useWizardForm';
import { WCGAQuote } from '../../../entities/WCGAQuote';
import { WCGAQuoteOptionsFormData } from '../WCGAQuoteLandingPage';
import { SignInsuranceApplicationCheckbox } from '../../../../view/components/signature/SignInsuranceApplicationCheckbox';

interface WCGAUserInfo {
    fullName: string;
    title: string;
    state?: State;
    company: string;
}

interface QuoteSignatureProps extends ReactProps<'div'> {
    quote: WCGAQuote;
    fields: WizardForm<OpaqueForm<WCGAQuoteOptionsFormData>>['fields'];
    setValue: WizardForm<OpaqueForm<WCGAQuoteOptionsFormData>>['setValue'];
    value: WizardForm<OpaqueForm<WCGAQuoteOptionsFormData>>['value'];
    trigger: WizardForm<OpaqueForm<WCGAQuoteOptionsFormData>>['trigger'];
}

export const QuoteSignature = function QuoteSignature({
    quote,
    fields,
    setValue,
    value,
    trigger,
    className,
}: QuoteSignatureProps) {
    const warrantyAndFraudModal = useModal();

    const userInfo = quote.userInfo as WCGAUserInfo;

    return (
        <div className={className}>
            <StackLayout gap="32">
                <Text style="heading 4">Please agree to the statements below</Text>
                <Form.Field
                    type={fields.agreementToConductSignature.type}
                    messages={fields.agreementToConductSignature.messages}
                    inputProps={{
                        checked: fields.agreementToConductSignature.props.value,
                        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                            setValue({
                                ...value,
                                agreementToConductSignature: event.target.checked,
                            });
                            trigger('sign');
                        },
                        children: (
                            <SignInsuranceApplicationCheckbox
                                company={userInfo.company}
                                fullName={userInfo.fullName}
                                usaState={userInfo.state}
                                disableSurplusLinesDisclosure
                            />
                        ),
                    }}
                    data-e2e="agreement-to-conduct-signature"
                />

                <Form.Field
                    type={fields.warrantyAndFraudSignature.type}
                    messages={fields.warrantyAndFraudSignature.messages}
                    inputProps={{
                        checked: fields.warrantyAndFraudSignature.props.value,
                        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                            setValue({ ...value, warrantyAndFraudSignature: event.target.checked });
                            trigger('sign');
                        },
                        children: (
                            <Text as="span">
                                By checking this box, you certify all information provided in the
                                insurance application is true and correct and acknowledge that you
                                have read and agree to the{' '}
                                <TextButton onClick={warrantyAndFraudModal.show}>
                                    Warranty and Fraud Statement
                                </TextButton>{' '}
                            </Text>
                        ),
                    }}
                    data-e2e="warranty-and-fraud-signature"
                />

                <WarrantyAndFraudModal
                    stateAndActions={warrantyAndFraudModal}
                    fullName={userInfo.fullName}
                    title={userInfo.title}
                    state={userInfo.state}
                />
            </StackLayout>
        </div>
    );
};
