import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable, Props } from '@embroker/shotwell/core/types';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { NAICSRepository } from '../repositories/NAICSRepository/index';
import { NAICS } from '../types/NAICS';

/**
 * Request data for GetNAICSBySearchTerm use case
 */
export interface GetNAICSBySearchTermRequest {
    searchTerm: string;
}

/**
 * Response data for GetNAICSBySearchTerm use case
 */
export interface GetNAICSBySearchTermResponse {
    naicsList: Immutable<Props<NAICS>>[];
}

/**
 * GetNAICSBySearchTerm use case is used to ...
 */
export interface GetNAICSBySearchTerm extends UseCase {
    execute(
        request: GetNAICSBySearchTermRequest,
    ): AsyncResult<GetNAICSBySearchTermResponse, InvalidArgument | OperationFailed>;
}

@injectable()
class GetNAICSBySearchTermUseCase extends UseCase implements GetNAICSBySearchTerm {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/GetNAICSBySearchTerm');

    /**
     * Constructor for GetNAICSBySearchTerm use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param naicsRepository is a naics repository used to search naics
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(NAICSRepository) private naicsRepository: NAICSRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes GetNAICSBySearchTerm use case
     * Input is of GetNAICSBySearchTermRequest type
     * @returns data of GetNAICSBySearchTermResponse type if execution was successful
     */
    public async execute({
        searchTerm,
    }: GetNAICSBySearchTermRequest): AsyncResult<
        GetNAICSBySearchTermResponse,
        InvalidArgument | OperationFailed
    > {
        const repoResult = await this.naicsRepository.getListBySearchTerm(searchTerm);

        if (isErr(repoResult)) {
            return repoResult;
        }

        const result: Immutable<Props<NAICS>>[] = [];

        for (const item of repoResult.value) {
            result.push(item);
        }

        return Success<GetNAICSBySearchTermResponse>({ naicsList: result });
    }
}

export const GetNAICSBySearchTerm: UseCaseClass<GetNAICSBySearchTerm> = GetNAICSBySearchTermUseCase;
