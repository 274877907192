import {
    Button,
    Modal,
    ModalActions,
    ModalState,
    ScrollBox,
    StackLayout,
    Text,
} from '@embroker/ui-toolkit/v2';
import React from 'react';

interface ThankYouModalProps {
    modal: ModalState & ModalActions;
    hideModal(): void;
    children: string;
}

export function ThankYouModal({ children, ...props }: ThankYouModalProps) {
    const modal = props.modal;
    return (
        <Modal {...modal} hide={props.hideModal}>
            <ScrollBox>
                <StackLayout data-e2e="thank-you-modal" gap="24">
                    <Text style="heading 3">Thank you.</Text>
                    <Text>{children}</Text>
                    <Button onClick={props.hideModal}>Ok</Button>
                </StackLayout>
            </ScrollBox>
        </Modal>
    );
}
