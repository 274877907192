import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import {
    BoxLayout,
    ColumnLayout,
    Form,
    ReactProps,
    StackLayout,
    Text,
} from '@embroker/ui-toolkit/v2';
import { isBefore, isSameDay, isValid, startOfToday } from 'date-fns';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { WizardForm } from '../../../../../view/hooks/useWizardForm';
import { AppContext } from '../../../../../view/AppContext';
import { hasRole } from '../../../../../userOrg/entities/Session';
import { EffectiveDate } from '../../../../types/EffectiveDate';
import { ESPQuote } from '../../../entities/ESPQuote';
import { ESPQuoteOptionsFormData, MAX_FUTURE_DAYS_ALLOWED } from '../ESPQuoteLandingPage';
import { ESPRecommendedCoverageList } from './ESPRecommendedCoverageList';
import { ESPSelectedCoverageList } from './ESPSelectedCoverageList';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { GetESPQuestionnaireData } from '../../../useCases/GetESPQuestionnaireData';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { State } from '@embroker/shotwell/core/types/StateList';
import { InsuranceApplicationRestriction } from '../../../types/InsuranceApplicationRestriction';
import { CoverageRestriction } from '../../../types/CoverageRestriction';
import { Immutable } from '@embroker/shotwell/core/types';
import { ESPCoverageType } from '../ESPCoverage/limitRetentionOptions';
import { ESPEndorsementList } from './ESPEndorsementList';
import { Endorsement } from '../../../types/EndorsementList';

interface AnswersContextProps {
    answers?: QuestionnaireData;
}

export const AnswersContext = React.createContext<AnswersContextProps>({});

interface ESPCoveragesPageProps extends ReactProps<'div'> {
    applicationId: UUID;
    quote: ESPQuote;
    fields: WizardForm<OpaqueForm<ESPQuoteOptionsFormData>>['fields'];
    setValue: WizardForm<OpaqueForm<ESPQuoteOptionsFormData>>['setValue'];
    value: WizardForm<OpaqueForm<ESPQuoteOptionsFormData>>['value'];
    isSubmitting: boolean;
    restriction?: Immutable<InsuranceApplicationRestriction>;
    higherLimitRequests?: Immutable<Record<string, number>>;
    showEpliStandaloneInCalifornia?: () => void;
}

const DATE_FORMAT = 'MM/dd/yyyy';

export function coverageLimitField(
    coverageType: ESPCoverageType,
    fields: WizardForm<OpaqueForm<ESPQuoteOptionsFormData>>['fields'],
) {
    switch (coverageType) {
        case 'do':
            return fields.dnoLimit;
        case 'epli':
            return fields.eplLimit;
        case 'fiduciary':
            return fields.fiduciaryLimit;
        case 'eoCyber':
            return fields.eoLimit;
        case 'techCyber':
            return fields.techLimit;
        case 'techSplit':
            return fields.techLimit;
        case 'cyberSplit':
            return fields.techRetention;
    }
}

export const coverageRetentionField = function coverageRetentionField(
    coverageType: ESPCoverageType,
    fields: WizardForm<OpaqueForm<ESPQuoteOptionsFormData>>['fields'],
) {
    switch (coverageType) {
        case 'do':
            return fields.dnoRetention;
        case 'epli':
            return fields.eplRetention;
        case 'fiduciary':
            return fields.fiduciaryRetention;
        case 'eoCyber':
            return fields.eoRetention;
        case 'techCyber':
            return fields.techRetention;
        case 'techSplit':
            return fields.techRetention;
        case 'cyberSplit':
            return fields.cyberRetention;
    }
};

export function coverageLevelField(
    coverageType: ESPCoverageType,
    fields: WizardForm<OpaqueForm<ESPQuoteOptionsFormData>>['fields'],
) {
    switch (coverageType) {
        case 'do':
            return fields.dnoLevel;
        case 'epli':
            return fields.eplLevel;
        case 'fiduciary':
            return undefined;
        case 'eoCyber':
            return fields.eoLevel;
        case 'techCyber':
            return fields.techLevel;
        case 'techSplit':
            return undefined;
        case 'cyberSplit':
            return undefined;
    }
}

export function coverageIsSelectedField(
    coverageType: ESPCoverageType,
    fields: WizardForm<OpaqueForm<ESPQuoteOptionsFormData>>['fields'],
) {
    switch (coverageType) {
        case 'do':
            return fields.isDnoSelected;
        case 'epli':
            return fields.isEplSelected;
        case 'fiduciary':
            return fields.isFiduciarySelected;
        case 'eoCyber':
            return fields.isEoSelected;
        case 'techCyber':
            return fields.isTechSelected;
        case 'techSplit':
            return fields.isTechSelected;
        case 'cyberSplit':
            return fields.isCyberSelected;
    }
}

export function getCoverageRestriction(
    coverageType: ESPCoverageType,
    restriction: Immutable<InsuranceApplicationRestriction>,
): CoverageRestriction | undefined {
    const coverageRestriction = restriction.coverageRestrictions.find((cr) => {
        switch (coverageType) {
            case 'do':
                return cr.coverageType === 'ShoppingCoverageCodeListDirectorsAndOfficers';
            case 'epli':
                return cr.coverageType === 'ShoppingCoverageCodeListEmploymentPractices';
            case 'fiduciary':
                return cr.coverageType === 'ShoppingCoverageCodeListFiduciary';
            case 'eoCyber':
                return cr.coverageType === 'ShoppingCoverageCodeListCyber';
            case 'techCyber':
                return cr.coverageType === 'ShoppingCoverageCodeListTechSplit';
            case 'techSplit':
                return cr.coverageType === 'ShoppingCoverageCodeListTechSplit';
            case 'cyberSplit':
                return cr.coverageType === 'ShoppingCoverageCodeListCyberSplit';
            default:
                return false;
        }
    });

    return coverageRestriction;
}

function isCoverageRestricted(
    coverageType: ESPCoverageType,
    restriction?: Immutable<InsuranceApplicationRestriction>,
): boolean {
    if (!restriction) {
        return false;
    }

    const coverageRestriction = getCoverageRestriction(coverageType, restriction);

    if (!coverageRestriction) {
        return false;
    }

    return !coverageRestriction.allowCoverage;
}

function isSelectedEffectiveDateRestricted(
    effectiveDate: Date,
    isAdmin: boolean,
    restriction?: Immutable<InsuranceApplicationRestriction>,
): boolean {
    if (!restriction || !restriction.minimumStartDate || isAdmin) {
        return false;
    }

    return (
        isBefore(effectiveDate, restriction.minimumStartDate) &&
        !isSameDay(effectiveDate, restriction.minimumStartDate)
    );
}

export interface QuestionnaireData {
    state: State;
    numberOfEmployees: number;
    zip: string;
}

export function ESPCoveragesPage({
    applicationId,
    quote,
    setValue,
    fields,
    value,
    isSubmitting,
    className,
    restriction,
    higherLimitRequests,
    showEpliStandaloneInCalifornia,
}: ESPCoveragesPageProps) {
    const { activeSession } = useContext(AppContext);
    const isAdmin = hasRole(activeSession, 'admin');
    const { result: questionnaireData } = useUseCase(GetESPQuestionnaireData, {
        applicationId,
    });
    const [questionnaire, setQuestionnaire] = useState<QuestionnaireData>();
    const isQuoteReferred = quote?.status === 'referred';

    useEffect(() => {
        if (questionnaireData !== undefined && isOK(questionnaireData)) {
            setQuestionnaire(questionnaireData.value as QuestionnaireData);
        }
    }, [questionnaireData, setQuestionnaire]);

    const handleDateChange = (event: { target: { value: string; date: Date } }) => {
        if (isValid(event.target.date)) {
            setValue({
                ...value,
                startDate: event.target.date,
            });
        }
    };

    const isEffectiveDateSelectable = useCallback(
        (effectiveDate: Date) =>
            EffectiveDate.isSelectedEffectiveDateValid(
                effectiveDate,
                startOfToday(),
                MAX_FUTURE_DAYS_ALLOWED,
                isAdmin,
            ) && !isSelectedEffectiveDateRestricted(effectiveDate, isAdmin, restriction),
        [isAdmin, restriction],
    );

    const endDate = EffectiveDate.calculateCoverageEndDate(quote.details.effectiveDate);

    return (
        <StackLayout gap="32" className={className}>
            <Text style="heading 4">When do you want your coverage to begin?</Text>
            <ColumnLayout gap="none" center>
                <Form.Field
                    className="u-1/3@desktop u-1/2@tablet u-1/2"
                    type={fields.startDate.type}
                    inputProps={{
                        ...fields.startDate.props,
                        onChange: handleDateChange,
                        disabled: isSubmitting,
                        isSelectable: isEffectiveDateSelectable,
                    }}
                    messages={fields.startDate.messages}
                />
                <BoxLayout>
                    <Text style="heading 5" data-e2e="end-date">
                        &ndash;{' '}
                        {endDate ? <DateDisplay format={DATE_FORMAT} value={endDate} /> : null}
                    </Text>
                </BoxLayout>
            </ColumnLayout>
            <AnswersContext.Provider value={{ answers: questionnaire }}>
                <ESPSelectedCoverageList
                    selectedCoverages={quote.details.coverages.filter((coverage) => {
                        return (
                            coverageIsSelectedField(coverage.coverageType, fields).props.value &&
                            !isCoverageRestricted(coverage.coverageType, restriction)
                        );
                    })}
                    revenue={quote.quoteInfo?.revenue ?? 0}
                    state={quote.quoteInfo?.userInfo?.usaState}
                    isSubmitting={isSubmitting}
                    fields={fields}
                    restriction={restriction}
                    newInsurerDocumentsReleaseDate={quote.quoteInfo?.newInsurerDocumentsReleaseDate}
                    fiduciaryDocumentsReleaseDate={quote.quoteInfo?.fiduciaryDocumentsReleaseDate}
                    submittedAt={quote.quoteInfo?.submittedAt}
                    isQuoteReferred={isQuoteReferred}
                    higherLimitRequests={higherLimitRequests}
                    showEpliStandaloneInCalifornia={showEpliStandaloneInCalifornia}
                />
            </AnswersContext.Provider>
            <ESPRecommendedCoverageList
                recommendedCoverages={quote.details.coverages.filter((coverage) => {
                    return (
                        !coverageIsSelectedField(coverage.coverageType, fields).props.value &&
                        !isCoverageRestricted(coverage.coverageType, restriction)
                    );
                })}
                revenue={quote.quoteInfo?.revenue ?? 0}
                isSubmitting={isSubmitting}
                submittedAt={quote.quoteInfo?.submittedAt}
                newInsurerDocumentsReleaseDate={quote.quoteInfo?.newInsurerDocumentsReleaseDate}
                fiduciaryDocumentsReleaseDate={quote.quoteInfo?.fiduciaryDocumentsReleaseDate}
                fields={fields}
                isQuoteReferred={isQuoteReferred}
            />
            {restriction?.endorsementList && restriction.endorsementList.length > 0 && (
                <ESPEndorsementList
                    effectiveDate={quote.options.effectiveDate}
                    coverages={quote.details.coverages}
                    endorsements={restriction.endorsementList as Endorsement[]}
                />
            )}
        </StackLayout>
    );
}
