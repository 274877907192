import React, { useMemo } from 'react';

import { Input } from '@embroker/ui-toolkit/v2';

export interface DateFieldProps {
    value?: Date;
    format?: 'YYYY' | 'MM/YYYY';
    onChange: (event: { target: { value: Date } }) => void;
    readOnly?: boolean;
    placeholder?: string;
    label?: string;
    tooltip?: string;
}

// DOM input works with string of certain format, Input.x work with Date, but
// report back both Date and the string. With this component we make sure Date
// is reported outside, presumably to a field wrapper.
export const DateField = React.forwardRef(function DateField(
    { value, format, onChange, readOnly, placeholder, label, tooltip }: DateFieldProps,
    ref: React.Ref<HTMLInputElement>,
) {
    const handleOnChange = function ({ target: { date } }: { target: { date: Date } }) {
        onChange({ target: { value: date } });
    };
    const Component = useMemo(() => {
        switch (format) {
            case 'YYYY':
                return Input.Year;
            case 'MM/YYYY':
                return Input.Month;
            default:
                return Input.Date;
        }
    }, [format]);
    return (
        <Component
            ref={ref}
            value={value}
            onChange={handleOnChange}
            readOnly={readOnly}
            placeholder={placeholder}
            label={label}
            tooltip={tooltip}
        />
    );
});
