import { Module } from '../../Module';
import { ContainerModule } from '@embroker/shotwell/core/di';
import { CrimeQuoteRepository } from './repositories/CrimeQuoteRepository';
import { APICrimeQuoteRepository } from './repositories/CrimeQuoteRepository/APICrimeQuoteRepository';
import { GetLastCrimeQuote } from './useCases/GetLastCrimeQuote';
import { ReQuoteCrime } from './useCases/ReQuoteCrime';
import { PurchaseCrime } from './useCases/PurchaseCrime';
import { GetCrimeConfig } from './useCases/GetCrimeConfig';
import { CrimeConfigRepository } from './repositories/CrimeConfigRepository';
import { APICrimeConfigRepository } from './repositories/CrimeConfigRepository/APICrimeConfigRepository';
import { GenerateQuoteSummaryUrl } from './useCases/GenerateQuoteSummaryUrl';
import { GenerateCrimeSpecimenPolicyUrl } from './useCases/GenerateCrimeSpecimenPolicyUrl';

export const CrimeQuoteModule: Module = {
    container: new ContainerModule((bind) => {
        bind<CrimeQuoteRepository>(CrimeQuoteRepository)
            .to(APICrimeQuoteRepository)
            .inSingletonScope();
        bind<CrimeConfigRepository>(CrimeConfigRepository)
            .to(APICrimeConfigRepository)
            .inSingletonScope();
        bind<GetLastCrimeQuote>(GetLastCrimeQuote.type).to(GetLastCrimeQuote).inSingletonScope();
        bind<ReQuoteCrime>(ReQuoteCrime.type).to(ReQuoteCrime).inSingletonScope();
        bind<PurchaseCrime>(PurchaseCrime.type).to(PurchaseCrime).inSingletonScope();
        bind<GetCrimeConfig>(GetCrimeConfig.type).to(GetCrimeConfig).inSingletonScope();
        bind<GenerateQuoteSummaryUrl>(GenerateQuoteSummaryUrl.type)
            .to(GenerateQuoteSummaryUrl)
            .inSingletonScope();
        bind<GenerateCrimeSpecimenPolicyUrl>(GenerateCrimeSpecimenPolicyUrl.type)
            .to(GenerateCrimeSpecimenPolicyUrl)
            .inSingletonScope();
    }),
};
