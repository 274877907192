import { inject } from '@embroker/shotwell/core/di';
import { Aborted, InvalidArgument, OperationFailed, Timeout } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, isOK, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { TasksRepository } from '../../../tasks/repositories';
import { CyberQuoteOptions } from '../types/CyberQuoteOptions';
import { CyberQuote } from '../entities/CyberQuote';
import { CyberQuoteRepository } from '../repositories/CyberQuoteRepository';
import { CalculateSKU } from '../../../analytics/useCases/CalculateSKU';
import { ApplicationQuoteCreated } from '../../../shopping/entities/Application';

export interface ReQuoteCyberRequest {
    applicationId: UUID;
    cyberQuoteOptions: CyberQuoteOptions;
    abortSignal: AbortSignal;
}

export interface ReQuoteCyber extends UseCase {
    execute(
        request: ReQuoteCyberRequest,
    ): AsyncResult<CyberQuote, InvalidArgument | OperationFailed | Timeout | Aborted>;
}

class ReQuoteCyberUseCase extends UseCase implements ReQuoteCyber {
    public static type = Symbol('CyberQuote/ReQuoteCyber');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(CyberQuoteRepository) private cyberQuoteRepository: CyberQuoteRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
        @inject(CalculateSKU.type) private calculateSku: CalculateSKU,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
        cyberQuoteOptions,
        abortSignal,
    }: ReQuoteCyberRequest): AsyncResult<
        CyberQuote,
        InvalidArgument | OperationFailed | Timeout | Aborted
    > {
        const maxPollingRetries = 120;
        const pollingRetryIntervalInMilliseconds = 1000;
        const reQuoteResult = await this.cyberQuoteRepository.reQuoteCyber(
            applicationId,
            cyberQuoteOptions,
        );
        if (isErr(reQuoteResult)) {
            return reQuoteResult;
        }

        const pollForTaskStatusResult = await this.tasksRepository.pollForTaskStatus(
            reQuoteResult.value,
            abortSignal,
            maxPollingRetries,
            pollingRetryIntervalInMilliseconds,
        );
        if (isErr(pollForTaskStatusResult)) {
            return pollForTaskStatusResult;
        }

        const getCurrentQuoteResult = await this.cyberQuoteRepository.getLastCyberQuote(
            applicationId,
        );
        if (isErr(getCurrentQuoteResult)) {
            return getCurrentQuoteResult;
        }
        const quote = getCurrentQuoteResult.value;

        await this.calculateSku
            .execute({
                event: 'quote',
                applicationId: quote.applicationId,
            })
            .then((skuResult) => {
                const event: ApplicationQuoteCreated = {
                    origin: 'Application',
                    name: 'QuoteCreated',
                    createdAt: new Date(Date.now()),
                    applicationId,
                    totalPremium: quote.totalPayable,
                    id: UUID.create(),
                    sku: isOK(skuResult) ? skuResult.value : undefined,
                    isRenewal: quote.isRenewal,
                };
                this.eventBus.publish(event);
            });
        return Success(quote);
    }
}

export const ReQuoteCyber: UseCaseClass<ReQuoteCyber> = ReQuoteCyberUseCase;
