import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { isErr, Success, SuccessResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { AppTypeCode, ShoppingCoverage } from '../../shopping/types/enums';
import { SKURepository } from '../repositories/SKURepository';
import { SKU } from '../types/SKU';

export interface CalculateSKU extends UseCase {
    execute(request: CalculateSKURequest): Promise<SuccessResult<SKU | undefined>>;
}

export type CalculateSKURequest =
    | CalculateSKUQuestionnaireChangedRequest
    | CalculateSKUAppCreatedRequest
    | CalculateSKUQuoteRequest
    | CalculateSKUAppSubmitForReviewRequest
    | CalculateSKUPurchaseRequest;

export interface CalculateSKUQuestionnaireChangedRequest {
    readonly event: 'questionnaire_changed';
    readonly appType: AppTypeCode;
    readonly shoppingCoverageList: readonly ShoppingCoverage[];
    readonly questionnaireData?: string;
    readonly applicationId?: UUID;
}

export interface CalculateSKUAppCreatedRequest {
    readonly event: 'app_created';
    readonly appType: AppTypeCode;
    readonly shoppingCoverageList: readonly ShoppingCoverage[];
    readonly questionnaireData?: string;
    readonly applicationId?: UUID;
}

export interface CalculateSKUQuoteRequest {
    readonly event: 'quote';
    readonly applicationId: UUID;
}

export interface CalculateSKUPurchaseRequest {
    readonly event: 'purchase';
    readonly applicationId: UUID;
}

export interface CalculateSKUAppSubmitForReviewRequest {
    readonly event: 'submit_for_review';
    readonly applicationId: UUID;
}

@injectable()
class CalculateSKUUseCase extends UseCase implements CalculateSKU {
    public static type = Symbol('Analytics/CalculateSKU');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(SKURepository) private skuRepository: SKURepository,
        @inject(Log) private logger: Logger,
    ) {
        super(eventBus);
    }

    public async execute(request: CalculateSKURequest): Promise<SuccessResult<SKU | undefined>> {
        const calculateSKUResult = await this.skuRepository.calculateSKU(request);

        if (isErr(calculateSKUResult)) {
            this.logger.warn(`Failed to calculate sku`, calculateSKUResult);
            return Success(undefined);
        }

        return calculateSKUResult;
    }
}

export const CalculateSKU: UseCaseClass<CalculateSKU> = CalculateSKUUseCase;
