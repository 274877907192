import { inject } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Success, Failure, AsyncResult, isOK } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { APIQuestionerRepository } from '../repositories/APIQuestionerRepository';
import { SessionRepository } from '../../userOrg/repositories/SessionRepository';
import { isAuthenticated } from '../../userOrg/entities/Session';
import { Unauthenticated } from '../../userOrg/errors';
import { FailedToSaveQuestionerAnswers } from '../errors';
import { OracleAnswer } from '../types/OracleAnswerType';
import { TechCoverageTypes } from '@app/shopping/view/components/techVerticalProductSelection/TechVerticalProductSelectionPage';

// For types are only TechCoverageTypes, but this will be extended in the future
type CoverageTypes = TechCoverageTypes;

const CoverageSelectionOracleKeys: Record<CoverageTypes, string> = {
    techEO: 'coverage_selected_tech_eo_cyber',
    directorsAndOfficers: 'coverage_selected_do',
    employmentPractices: 'coverage_selected_epl',
    fiduciary: 'coverage_selected_fiduciary',
} as const;

export type SaveProductSelectionAnswersRequest = {
    coverages: { coverage: TechCoverageTypes; selected: boolean }[];
};

export type SaveProductSelectionAnswersResponse = {};

export type SaveProductSelectionAnswersUseCaseResult = AsyncResult<
    SaveProductSelectionAnswersResponse,
    Unauthenticated | FailedToSaveQuestionerAnswers
>;

export interface SaveProductSelectionAnswers extends UseCase {
    execute(request: SaveProductSelectionAnswersRequest): SaveProductSelectionAnswersUseCaseResult;
}

class SaveProductSelectionAnswersUseCase extends UseCase implements SaveProductSelectionAnswers {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Questioner/SaveProductSelectionAnswersUseCase');
    /**
     * Constructor for SaveProductSelectionAnswersUseCase use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param notificationRepository is notification repository used to store user Questioner
     */
    constructor(
        @inject(APIQuestionerRepository) private questionerRepository: APIQuestionerRepository,
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(SessionRepository) private sessionRepository: SessionRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        coverages,
    }: SaveProductSelectionAnswersRequest): SaveProductSelectionAnswersUseCaseResult {
        const activeSesion = await this.sessionRepository.getActiveSession();
        if (!isAuthenticated(activeSesion.value)) {
            return Failure(Unauthenticated());
        }

        const answers: OracleAnswer[] = coverages.map(({ coverage, selected }) => {
            return {
                key: CoverageSelectionOracleKeys[coverage],
                value: { boolean: [selected] },
                type: 'BOOLEAN',
                multiplicity: 1,
            };
        });

        const getQuestionerFormResp = await this.questionerRepository.saveQuestionerAnswers({
            answers,
        });

        if (!isOK(getQuestionerFormResp)) {
            return getQuestionerFormResp;
        }

        return Success<SaveProductSelectionAnswersResponse>({});
    }
}

export const SaveProductSelectionAnswers: UseCaseClass<SaveProductSelectionAnswers> =
    SaveProductSelectionAnswersUseCase;
