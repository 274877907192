import React from 'react';
import { ComplexFormFieldViewProps } from '../../types/ComplexFieldTypes';
import { FormFieldProps, parseMessages } from '../FormFieldView.view';
import { Form, StackLayout, StatusMessage } from '@embroker/ui-toolkit/v2';

export function YearFieldView(complexFormFieldViewProps: ComplexFormFieldViewProps) {
    const { questionProps, inputFieldProps } = complexFormFieldViewProps;
    const inputValue = inputFieldProps.inputProps.value
        ? String(inputFieldProps.inputProps.value)
        : undefined;

    const formFieldProps: FormFieldProps = {
        title: questionProps.title,
        label: questionProps.label,
        tooltip: questionProps.tooltip,
        type: inputFieldProps.type,
        inputProps: {
            ...inputFieldProps.inputProps,
            placeholder: inputFieldProps.inputProps.placeholder,
            items: questionProps.selectOptions,
            value: inputValue,
        },
        messages: parseMessages(inputFieldProps.messages),
    };

    return (
        <StackLayout gap="12">
            <Form.Field data-testid={`question-key-${questionProps.key}`} {...formFieldProps} />
            {questionProps.statusMessage && (
                <StatusMessage status={questionProps.statusMessage.status}>
                    {questionProps.statusMessage.content}
                </StatusMessage>
            )}
        </StackLayout>
    );
}
