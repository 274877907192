import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface MplBundleQuestionnaireData {
    naicsCode: string;
    riskProfileVisited?: boolean;
}

export const MplBundleQuestionnaireData = {
    ...defineValidator<MplBundleQuestionnaireData>({
        naicsCode: Joi.string().min(5),
        riskProfileVisited: Joi.boolean().optional(),
    }),
    create(mplBundleQuestionnaireData: MplBundleQuestionnaireData) {
        return MplBundleQuestionnaireData.validate(mplBundleQuestionnaireData);
    },
};
