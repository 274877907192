import { Immutable } from '@embroker/shotwell/core/types';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { StatusLabel, StatusType, Text, TextButton } from '@embroker/ui-toolkit/v2';
import { differenceInDays, startOfToday } from 'date-fns';
import React from 'react';
import { SelectOrganization } from '../../../../userOrg/useCases/SelectOrganization';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { BrokerRenewalRecord, BrokerRenewalStatus } from '../../../types/BrokerRenewalRecord';
import {
    GetBrokerRenewals,
    GetBrokerRenewalsRequest,
    RenewalTableColumn,
} from '../../../useCases/GetBrokerRenewals';
import { RenewalsTableActionButton } from './RenewalsTableActionButton';

export interface BrokerRenewalsTableData extends Record<string, unknown> {
    readonly id: UUID;
    readonly companyName: React.ReactNode;
    readonly managingBroker: React.ReactNode;
    readonly renewalStatus: React.ReactNode;
    readonly policyType: React.ReactNode;
    readonly daysToPreviousPolicyExpiry: React.ReactNode;
    readonly previousPolicyPremium: React.ReactNode;
    readonly action: React.ReactNode;
}

interface getRenewalsPageDataParams {
    itemsPerPage: number;
    pageIndex: number;
    column?: RenewalTableColumn;
    order?: 'ASC' | 'DESC';
    filters?: {
        target: RenewalTableColumn;
        value: string;
    }[];
}

export function buildGetBrokerRenewalsRequest({
    itemsPerPage,
    pageIndex,
    column,
    order,
    filters,
}: getRenewalsPageDataParams): GetBrokerRenewalsRequest {
    return {
        column,
        order: order ? (order === 'ASC' ? 'ascending' : 'descending') : undefined,
        index: pageIndex,
        size: itemsPerPage,
        filters,
    };
}

export async function getRenewalsPageData(params: getRenewalsPageDataParams) {
    const request = buildGetBrokerRenewalsRequest(params);

    const result = await execute(GetBrokerRenewals, request);
    if (isOK(result)) {
        return {
            items: toRenewalsTableData(result.value.renewalList),
            totalItems: result.value.pageInfo.totalItems,
        };
    }
    return {
        items: [],
        totalItems: 0,
    };
}

function toRenewalsTableData(
    renewalList: Immutable<BrokerRenewalRecord[]>,
): BrokerRenewalsTableData[] {
    return renewalList.map((renewal: Immutable<BrokerRenewalRecord>) => {
        const daysToExpiry = renewal.previousPolicyExpirationDate
            ? differenceInDays(renewal.previousPolicyExpirationDate, startOfToday())
            : undefined;
        const showActionButton = renewal.status !== 'expired';
        const { renewalStatus, statusLabelType } = getRenewalStatusData(renewal.status);

        return {
            id: renewal.applicationId,
            companyName: (
                <CompanyNameButton
                    organizationId={renewal.organizationId}
                    name={renewal.organizationName}
                />
            ),
            managingBroker: <Text style="default">{renewal.managingBroker}</Text>,
            renewalStatus: <StatusLabel type={statusLabelType}>{renewalStatus}</StatusLabel>,
            policyType: <div>{renewal.previousPolicyProductType ?? 'N/A'}</div>,
            daysToPreviousPolicyExpiry: <DaysToExpiration daysLeft={daysToExpiry} />,
            previousPolicyPremium: renewal.previousPolicyPremium ? (
                <MoneyDisplay value={renewal.previousPolicyPremium} />
            ) : (
                <div> 'N/A'</div>
            ),
            action: showActionButton ? (
                <RenewalsTableActionButton
                    applicationId={renewal.applicationId}
                    organizationId={renewal.organizationId}
                    status={renewal.status}
                    hasQuotes={renewal.hasQuotes}
                    quotingEngine={renewal.quotingEngine}
                    ineligibilityReasons={renewal.ineligibilityReasons}
                    isSubmittedExternally={renewal.isSubmittedExternally}
                    isStreamline={renewal.isStreamline}
                />
            ) : null,
        };
    });
}

interface CompanyNameButtonProps {
    organizationId: UUID;
    name: string;
}

function CompanyNameButton({ name, organizationId }: CompanyNameButtonProps) {
    const { navigate } = useNavigation();

    const handleCompanySelection = async () => {
        await execute(SelectOrganization, { organizationId });
        navigate(
            URI.build('/broker/organization-info', {
                organizationId,
            }),
        );
    };

    return <TextButton onClick={handleCompanySelection}>{name}</TextButton>;
}

function DaysToExpiration({ daysLeft }: { daysLeft?: number }) {
    if (daysLeft === undefined || isNaN(daysLeft)) {
        return <div>N/A</div>;
    }
    if (daysLeft < 0) {
        return (
            <StatusLabel fixed type="red">
                Expired
            </StatusLabel>
        );
    }

    return <div>{`${daysLeft} ${daysLeft === 1 ? 'day' : 'days'}`}</div>;
}

enum RenewalStatus {
    NotSubmitted = 'Not Submitted',
    QuotesReady = 'Quotes Ready',
    Referred = 'Referred',
    Expired = 'Expired',
    Declined = 'Declined',
}

interface GetRenewalStatusData {
    renewalStatus: RenewalStatus;
    statusLabelType: StatusType;
}

function getRenewalStatusData(status: BrokerRenewalStatus): GetRenewalStatusData {
    switch (status) {
        case 'quotes_ready':
            return { renewalStatus: RenewalStatus.QuotesReady, statusLabelType: 'green' };
        case 'not_submitted':
            return { renewalStatus: RenewalStatus.NotSubmitted, statusLabelType: 'gray' };
        case 'referred':
            return { renewalStatus: RenewalStatus.Referred, statusLabelType: 'yellow-outline' };
        case 'expired':
            return { renewalStatus: RenewalStatus.Expired, statusLabelType: 'red-outline' };
        default:
            return { renewalStatus: RenewalStatus.Declined, statusLabelType: 'red' };
    }
}
