import * as API from '@embroker/shotwell-api/app';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, NetworkFailure, OperationFailed } from '@embroker/shotwell/core/Error';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { Nullable } from '@embroker/shotwell/core/types/index';
import { Location, LocationType } from '@embroker/shotwell/core/types/Location';
import {
    AsyncResult,
    ErrorLike,
    Failure,
    handleOperationFailure,
    isErr,
    isOK,
    mergeErrors,
    Result,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { State } from '@embroker/shotwell/core/types/StateList';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import { CertificateTemplate } from '../../entities/CertificateTemplate';
import { CertificateCoverage } from '../../types/CertificateCoverage';
import { CertificateDocument } from '../../types/CertificateDocument';
import {
    CertificateEntityRole,
    CertificateEntityRoleType,
} from '../../types/CertificateEntityRole';
import { CertificateProducer } from '../../types/CertificateProducer';
import {
    CertificateTemplateType,
    CertificateTemplateTypeCustom,
    CertificateTemplateTypeList,
} from '../../types/enums';
import { CertificateTemplateRepository } from './index';

@injectable()
export class APICertificateTemplateRepository implements CertificateTemplateRepository {
    constructor(@inject(Log) private logger: Logger) {}

    public async getCertificateTemplatesForOrganization(
        organizationId: UUID,
    ): AsyncResult<CertificateTemplate[], OperationFailed | NetworkFailure | InvalidArgument> {
        const certificateResult = await API.API.request(
            'certificate/get_certificate_templates_for_organization',
            {
                organization_id: organizationId,
            },
        );

        if (isErr(certificateResult)) {
            return handleOperationFailure(certificateResult);
        }

        const result: CertificateTemplate[] = [];

        const results = await Promise.all(
            certificateResult.value.map((item) => {
                return this.toCertificateTemplate(item as API.CertificateTemplateWithId);
            }),
        );

        for (const item of results) {
            if (isErr(item)) {
                return mergeErrors(results);
            }
            result.push(item.value as CertificateTemplate);
        }

        return Success(result);
    }

    private static locationApiToEntity(
        data: API.Location,
    ): Result<Nullable<Location>, InvalidArgument> {
        if (!data) {
            return Success(null);
        }

        let stateCode: Nullable<State> | undefined = null;

        if (typeof data.state === 'string' && data.state.length > 0) {
            stateCode = State.getCodeByName(data.state);

            if (stateCode === undefined) {
                return Failure(InvalidArgument({ argument: 'state', value: data.state }));
            }
        }

        const result = Location.create({
            type: 'headquarters' as LocationType,
            addressLine1: data.address_lines,
            addressLine2: data.address_lines,
            city: data.city,
            state: stateCode,
            zip: data.zip_code as ZipCode,
            phoneNumber: null,
        });

        if (isErr(result)) {
            return mergeErrors([result]);
        }

        return Success(result.value);
    }

    private toCertificateProducer(
        data: API.CertificateProducer,
    ): Result<CertificateProducer, InvalidArgument> {
        const location = APICertificateTemplateRepository.locationApiToEntity({
            address_lines: data.address,
            city: data.city,
            zip_code: data.zip_code,
            state: data.state,
        } as API.Location);

        if (isErr(location)) {
            return mergeErrors([location]);
        }

        const result = CertificateProducer.create({
            certificateCreator: data.certificate_creator,
            name: data.name,
            email: data.email as EmailAddress,
            location: location.value as Location,
        });

        if (isErr(result)) {
            return mergeErrors([result]);
        }

        return Success(result.value);
    }

    private toCertificateDocument(
        documentList: API.CertificateDocumentWithIdList,
    ): Result<CertificateDocument[], ErrorLike> {
        const list: CertificateDocument[] = [];
        const results = documentList.map((element) => {
            return CertificateDocument.create({
                dateUploaded: element.date_uploaded as Date,
                fileKey: element.file_key,
                isActive: element.is_active,
                name: element.name ?? undefined,
                type: element.type,
            });
        });

        for (const result of results) {
            if (isErr(result)) {
                return mergeErrors(results);
            }
            list.push(result.value);
        }

        return Success(list);
    }

    private toCertificateCoverageList(
        coverageList: API.CertificateCoverageWithIdList,
    ): Result<CertificateCoverage[], ErrorLike> {
        const list: CertificateCoverage[] = [];
        const results = coverageList.map((element) => {
            return CertificateCoverage.create(element.structural_component_type_code);
        });

        for (const result of results) {
            if (isErr(result)) {
                return mergeErrors(results);
            }
            list.push(result.value);
        }

        return Success(list);
    }

    private toCertificateEntityRole(
        data: API.CertificateEntityRole,
        roleType: CertificateEntityRoleType,
    ): Result<CertificateEntityRole, InvalidArgument> {
        const location = APICertificateTemplateRepository.locationApiToEntity(
            data.location as API.Location,
        );

        if (isErr(location)) {
            return mergeErrors([location]);
        }

        const result = CertificateEntityRole.create({
            roleType: roleType,
            organizationId: data.org_id ?? undefined,
            organizationName: data.name,
            organizationEmail: data.email as EmailAddress,
            userId: data.user_id ?? undefined,
            userEmail: (data.user_email as EmailAddress) ?? undefined,
            location: (location.value as Location) ?? undefined,
            signedUp: data.signed_up ?? undefined,
            token: data.token ?? undefined,
            suppressEmailNotifications: data.suppress_email_notification ?? undefined,
        });

        if (isErr(result)) {
            return mergeErrors([result]);
        }

        return Success(result.value);
    }

    private async toCertificateTemplate(
        certificateTemplate: API.CertificateTemplateWithId,
    ): AsyncResult<CertificateTemplate, InvalidArgument | OperationFailed> {
        const coverageListResult = this.toCertificateCoverageList(
            certificateTemplate.certificate.coverage_list,
        );

        if (isErr(coverageListResult)) {
            return Failure(
                OperationFailed({
                    message: 'Failed to convert certificate coverages',
                    errors: coverageListResult.errors,
                }),
            );
        }

        const certificateProducer = this.toCertificateProducer(
            certificateTemplate.certificate.producer as API.CertificateProducer,
        );

        if (isErr(certificateProducer)) {
            return Failure(
                OperationFailed({
                    message: 'Failed to convert certificate producer',
                    errors: certificateProducer.errors,
                }),
            );
        }

        const owner = this.toCertificateEntityRole(
            certificateTemplate.certificate.owner as API.CertificateEntityRole,
            'Owner',
        );

        if (isErr(owner)) {
            return Failure(
                OperationFailed({
                    message: 'Failed to convert certificate owner',
                    errors: owner.errors,
                }),
            );
        }

        const documentList = this.toCertificateDocument(
            certificateTemplate.certificate.document_list,
        );

        if (isErr(documentList)) {
            return Failure(
                OperationFailed({
                    message: 'Failed to convert certificate documents',
                    errors: documentList.errors,
                }),
            );
        }

        let templateType = certificateTemplate.template_type as CertificateTemplateType;
        const isTemplateTypeIncluded = CertificateTemplateTypeList.includes(templateType);
        if (!isTemplateTypeIncluded) {
            templateType = CertificateTemplateTypeCustom;
            this.logger.warn(`
            Invalid template type on certificate template with id: ${certificateTemplate.id}. Falling back to "${CertificateTemplateTypeCustom}" type
            `);
        }

        const result = await CertificateTemplate.create({
            id: certificateTemplate.id,
            isAdditionalInsuredEnabled: certificateTemplate.additional_insured_enabled,
            isCertificateTemplateActive:
                certificateTemplate.certificate_template_active &&
                certificateTemplate.enabled_for_client,
            createdAt: certificateTemplate.created_at as Date,
            name: certificateTemplate.name,
            templateType: templateType,
            certificateId: certificateTemplate.certificate.id,
            certificateOwner: owner.value as CertificateEntityRole,
            certificateProducer: certificateProducer.value as CertificateProducer,
            coverageList: coverageListResult.value as CertificateCoverage[],
            additionalInsuredName:
                certificateTemplate.certificate.additional_insured_name ?? undefined,
            customWording: certificateTemplate.certificate.custom_wording ?? undefined,
            documentList: documentList.value as CertificateDocument[],
        });

        if (isOK(result)) {
            return Success(result.value);
        }
        return Failure(
            InvalidArgument({ argument: 'certificateTemplate', value: certificateTemplate }),
        );
    }
}
