import {
    InvalidArgument,
    NotImplemented,
    OperationFailed,
    UnknownEntity,
} from '@embroker/shotwell/core/Error';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Organization } from '../../entities/Organization';
import { OperationNotAllowedError, OrganizationNotFoundError, Unauthenticated } from '../../errors';
import { UserRoleType } from '../../types/UserRole';

/**
 * Interface for the organization repository
 */
export interface OrganizationRepository {
    /**
     * Fetches D&B NAICs code
     * @returns NAICs code or null
     * @returns OperationFailed error if organization request fails
     */
    getDnBNAICsCode(organizationId: UUID): AsyncResult<string | undefined, never>;

    /**
     * Fetches organization with provided id
     * @param organizationId identifier of the organization
     * @returns Organization entity
     * @returns OperationFailed error if organization request fails
     * @returns InvalidArgument error if organization entity creation fails
     */
    getOrganization(
        organizationId: UUID,
    ): AsyncResult<Organization, InvalidArgument | OperationFailed | UnknownEntity>;

    /**
     * Creates organization if it didn't already exist. If organization has already exists it will be updated
     * @param organization
     * @returns Success if create or update are successful
     * @returns InvalidArgument if a property of an organization has an invalid value
     * @returns EmailAlreadyInUse error if organization with the same email already exists, but their id's are different
     */
    save(
        organization: Organization,
    ): AsyncResult<Organization, InvalidArgument | OperationFailed | UnknownEntity>;

    /**
     * Fetches all organization entities for a user in which that user has provided role
     * @param userId identifier of the user
     * @param userRoles role which user has in organization(s). If null is sent, all user organizations are returned
     * @returns Array of organizations in which user has a given role
     * @returns UnknownEntity error if user with provided id does not exist
     */
    getOrganizationsForUser(
        userId: UUID,
        userRoles?: UserRoleType[],
    ): AsyncResult<Organization[], InvalidArgument | OperationFailed | Unauthenticated>;

    /**
     * Fetches organization invited by inviteToken
     * @param inviteToken is a token by which organization is identified
     * @returns Organization entity
     * @returns UnknownEntity error if invited organization does not exist for token
     */
    getOrganizationByInviteToken(
        inviteToken: UUID,
    ): AsyncResult<Organization, UnknownEntity | NotImplemented>;

    /**
     * Accept organization invite to the platform
     * @param inviteToken token which identifies invited organization
     * @returns Success if organization has successfully accepted the invite
     * @returns InvalidArgument if invited organization does not exist for the provided token
     */
    acceptInvite(inviteToken: UUID): AsyncResult<void, InvalidArgument | NotImplemented>;

    getClientOrganization(
        token: string,
    ): AsyncResult<
        Organization,
        OrganizationNotFoundError | OperationNotAllowedError | OperationFailed
    >;

    updateClientOrganization(
        token: string,
        organization: Organization,
    ): AsyncResult<
        void,
        InvalidArgument | OrganizationNotFoundError | OperationNotAllowedError | OperationFailed
    >;
}

export const OrganizationRepository = Symbol('OrganizationRepository');
