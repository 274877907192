import { Nullable } from '@embroker/shotwell/core/types';
import { SelectOptionItem } from '@embroker/ui-toolkit/v2';
import { LimitCombinationsMap } from '../../../types/Limits';
import { LPLHigherLimit } from '../../types/LPLHigherLimit';
import {
    AggregateLimit,
    ClaimsExpenseType,
    DeductibleType,
    PerClaimDeductible,
    PerClaimLimit,
    SeparateClaimExpenseLimit,
} from '../../types/LPLQuoteOptions';

interface LPLQuoteFormItems {
    perClaimLimitItems: SelectOptionItem<PerClaimLimit>[];
    aggregateLimitItems: SelectOptionItem<AggregateLimit>[];
    perClaimDeductibleItems: SelectOptionItem<PerClaimDeductible>[];
    separateClaimExpenseLimitItems: SelectOptionItem<SeparateClaimExpenseLimit>[];
    claimsExpenseTypeItems: SelectOptionItem<ClaimsExpenseType>[];
    deductibleTypeItems: SelectOptionItem<DeductibleType>[];
}

const ClaimsExpenseTypeSeparateClaimsExpenses = 'ClaimsExpenseTypeSeparateClaimsExpenses';
const DeductibleTypeLossAndClaimsExpense = 'DeductibleTypeLossAndClaimsExpense';
const DeductibleTypeLossOnly = 'DeductibleTypeLossOnly';

export function isSeparateClaimsExpensesOptionSelected(
    claimsExpenseType: ClaimsExpenseType,
): boolean {
    return claimsExpenseType === ClaimsExpenseTypeSeparateClaimsExpenses;
}

const SeparateClaimExpenseLimit100k = 100000;

export function getDefaultSeparateClaimExpenseLimit(
    claimsExpenseType: ClaimsExpenseType,
): Nullable<SeparateClaimExpenseLimit> {
    return isSeparateClaimsExpensesOptionSelected(claimsExpenseType)
        ? SeparateClaimExpenseLimit100k
        : null;
}

export function getDefaultAggregateLimit(
    perClaimLimit: PerClaimLimit,
    stateWithLargestNumber: string,
): AggregateLimit {
    const aggregateLimitItems = getAggregateLimitItems(perClaimLimit, stateWithLargestNumber);
    return aggregateLimitItems[0].value;
}

function getAggregateLimitItems(
    perClaimLimit: PerClaimLimit,
    stateWithLargestNumber: string,
    higherLimit?: LPLHigherLimit,
): SelectOptionItem<AggregateLimit>[] {
    const aggregateLimitItems = getAggregateBindableLimitItems(
        perClaimLimit === higherLimit?.per_claim_limit ? higherLimit.aggregate_limit : undefined,
    );
    // Allowed combinations of per claim limit and aggregate limit dropdown options
    // $100,000/$300,000
    // $200,000/$600,000
    // $250,000/$500,000 - not allowed in NY
    // $250,000/$750,000
    // $500,000/$500,000 - not allowed in NY
    // $500,000/$1,000,000 - not allowed in NY
    // $500,000/$1,500,000  - not allowed for NJ
    // $750,000/1,500,000 - allowed in NY only
    // $1,000,000/$1,000,000
    // $1,000,000/$2,000,000
    // $1,000,000/$3,000,000 - not allowed for NJ and NY
    // $2,000,000/$2,000,000
    // $2,000,000/$3,000,000 - allowed in NY only
    // $2,000,000/$4,000,000
    // $3,000,000/$3,000,000
    // $4,000,000/$4,000,000
    // $5,000,000/$5,000,000
    // perClaimLimitOption : [aggregateLimitOptionList]

    function getAllowedCombinations(stateWithLargestNumber: string): LimitCombinationsMap {
        const stateNJ = 'NJ';
        const stateNY = 'NY';
        const defaultAllowedCombinationsMap: LimitCombinationsMap = {
            100000: [300000],
            200000: [600000],
            250000: [500000, 750000],
            500000: [500000, 1000000, 1500000],
            1000000: [1000000, 2000000, 3000000],
            2000000: [2000000, 4000000],
            3000000: [3000000],
            4000000: [4000000],
            5000000: [5000000],
        };
        const nyAllowedCombinationsMap = {
            ...defaultAllowedCombinationsMap,
            250000: [750000],
            500000: [1500000],
            750000: [1500000],
            1000000: [1000000, 2000000],
            2000000: [2000000, 3000000, 4000000],
        };
        const njAllowedCombinationsMap = {
            ...defaultAllowedCombinationsMap,
            500000: [500000, 1000000],
            1000000: [1000000, 2000000],
        };
        if (stateWithLargestNumber === stateNY) {
            return nyAllowedCombinationsMap;
        }
        return stateWithLargestNumber === stateNJ
            ? njAllowedCombinationsMap
            : defaultAllowedCombinationsMap;
    }

    const allowedCombinations = getAllowedCombinations(stateWithLargestNumber)[perClaimLimit];
    return aggregateLimitItems.filter((item) => allowedCombinations.includes(item.value));
}

const getAggregateBindableLimitItems = (approvedAggregateLimit?: AggregateLimit) => {
    const aggregateLimitItems: SelectOptionItem<AggregateLimit>[] = [
        { title: '$300,000', value: 300000 },
        { title: '$500,000', value: 500000 },
        { title: '$600,000', value: 600000 },
        { title: '$750,000', value: 750000 },
        { title: '$1,000,000', value: 1000000 },
        { title: '$1,500,000', value: 1500000 },
        { title: '$2,000,000', value: 2000000 },
        { title: '$3,000,000', value: 3000000 },
        { title: '$4,000,000', value: 4000000 },
        { title: '$5,000,000', value: 5000000 },
    ];

    if (approvedAggregateLimit) {
        return aggregateLimitItems.filter((item) => {
            return item.value <= approvedAggregateLimit;
        });
    }
    return aggregateLimitItems;
};

function getSeparateClaimExpenseLimitItems(
    claimsExpenseType: ClaimsExpenseType,
    perClaimLimit: PerClaimLimit,
    stateWithLargestNumber: string,
): SelectOptionItem<SeparateClaimExpenseLimit>[] {
    const separateClaimExpenseLimitItems: SelectOptionItem<SeparateClaimExpenseLimit>[] = [
        {
            title: '$100,000',
            value: SeparateClaimExpenseLimit100k,
        },
        {
            title: '$200,000',
            value: 200000,
        },
        {
            title: '$250,000',
            value: 250000,
        },
        {
            title: '$500,000',
            value: 500000,
        },
        {
            title: '$1,000,000',
            value: 1000000,
        },
        {
            title: '$2,000,000',
            value: 2000000,
        },
        {
            title: '$3,000,000',
            value: 3000000,
        },
    ];
    // Allowed combinations of per claim limit and separate claim expenses coverage limit dropdown options
    // $100,000/[$100,000]
    // $200,000/[$100,000, $200,000]
    // $250,000/[$100,000, $200,000, $250,000]
    // $500,000/[$100,000, $200,000, $250,000, $500,000]
    // $1,000,000/[$100,000, $200,000, $250,000, $500,000, $1,000,000]
    // $2,000,000/[$100,000, $200,000, $250,000, $500,000, $1,000,000, $2,000,000]
    // $3,000,000/[$100,000, $200,000, $250,000, $500,000, $1,000,000, $2,000,000]
    // $4,000,000/[$100,000, $200,000, $250,000, $500,000, $1,000,000, $2,000,000]
    // $5,000,000/[$100,000, $200,000, $250,000, $500,000, $1,000,000, $2,000,000]
    // Higher limits for CA
    // $3,000,000/[$100,000, $200,000, $250,000, $500,000, $1,000,000, $2,000,000, $3,000,000]
    // $4,000,000/[$100,000, $200,000, $250,000, $500,000, $1,000,000, $2,000,000, $3,000,000]
    // $5,000,000/[$100,000, $200,000, $250,000, $500,000, $1,000,000, $2,000,000, $3,000,000]
    // perClaimLimitOption : [separateClaimExpenseLimitOptionList]
    const stateCA = 'CA';
    const stateWA = 'WA';
    const allowedCombinationsMap: LimitCombinationsMap =
        stateWithLargestNumber === stateCA
            ? {
                  100000: [100000],
                  200000: [100000, 200000],
                  250000: [100000, 200000, 250000],
                  500000: [100000, 200000, 250000, 500000],
                  1000000: [100000, 200000, 250000, 500000, 1000000],
                  2000000: [100000, 200000, 250000, 500000, 1000000, 2000000],
                  3000000: [100000, 200000, 250000, 500000, 1000000, 2000000, 3000000],
                  4000000: [100000, 200000, 250000, 500000, 1000000, 2000000, 3000000],
                  5000000: [100000, 200000, 250000, 500000, 1000000, 2000000, 3000000],
              }
            : stateWithLargestNumber === stateWA
            ? {
                  100000: [300000],
                  200000: [600000],
                  250000: [500000, 750000],
                  500000: [500000, 1000000, 1500000],
                  1000000: [1000000, 2000000, 3000000],
                  2000000: [2000000, 4000000],
                  3000000: [3000000],
                  4000000: [4000000],
                  5000000: [5000000],
              }
            : {
                  100000: [100000],
                  200000: [100000, 200000],
                  250000: [100000, 200000, 250000],
                  500000: [100000, 200000, 250000, 500000],
                  1000000: [100000, 200000, 250000, 500000, 1000000],
                  2000000: [100000, 200000, 250000, 500000, 1000000, 2000000],
                  3000000: [100000, 200000, 250000, 500000, 1000000, 2000000],
                  4000000: [100000, 200000, 250000, 500000, 1000000, 2000000],
                  5000000: [100000, 200000, 250000, 500000, 1000000, 2000000],
              };
    const allowedCombinations = allowedCombinationsMap[perClaimLimit];
    return isSeparateClaimsExpensesOptionSelected(claimsExpenseType)
        ? separateClaimExpenseLimitItems.filter((item) => allowedCombinations.includes(item.value))
        : [];
}

function getPerClaimDeductibleItems(
    numberOfAttorneys: number,
    stateWithLargestNumber: string,
): SelectOptionItem<PerClaimDeductible>[] {
    const stateNJ = 'NJ';
    const stateNY = 'NY';
    const stateWA = 'WA';

    const perClaimDefaultDeductibleItems: SelectOptionItem<PerClaimDeductible>[] = [
        {
            title: '$1,000',
            value: 1000,
        },
        {
            title: '$2,000',
            value: 2000,
        },
        {
            title: '$2,500',
            value: 2500,
        },
        {
            title: '$3,000',
            value: 3000,
        },
        {
            title: '$4,000',
            value: 4000,
        },
        {
            title: '$5,000',
            value: 5000,
        },
        {
            title: '$10,000',
            value: 10000,
        },
        {
            title: '$15,000',
            value: 15000,
        },
        {
            title: '$20,000',
            value: 20000,
        },
        {
            title: '$25,000',
            value: 25000,
        },
        {
            title: '$35,000',
            value: 35000,
        },
        {
            title: '$50,000',
            value: 50000,
        },
    ];

    const perClaimDefaultDeductibleItemsNJ: SelectOptionItem<PerClaimDeductible>[] = [
        {
            title: '$1,000',
            value: 1000,
        },
        {
            title: '$2,500',
            value: 2500,
        },
        {
            title: '$5,000',
            value: 5000,
        },
        {
            title: '$10,000',
            value: 10000,
        },
        {
            title: '$15,000',
            value: 15000,
        },
        {
            title: '$25,000',
            value: 25000,
        },
        {
            title: '$50,000',
            value: 50000,
        },
    ];

    const perClaimDefaultDeductibleItemsNY: SelectOptionItem<PerClaimDeductible>[] =
        perClaimDefaultDeductibleItems.filter((option) => option.value !== 35000);

    const perClaimDefaultDeductibleItemsWA: SelectOptionItem<PerClaimDeductible>[] =
        perClaimDefaultDeductibleItems.filter(
            (option) => option.value !== 20000 && option.value !== 35000 && option.value !== 50000,
        );

    const getDeductibleOptionsByNumOfAttorneys = (
        numberOfAttorneys: number,
        perClaimDeductibleItems: SelectOptionItem<PerClaimDeductible>[],
    ) => {
        // Firms with less than 2.5 attorneys have a max per claim deductible of $10,000
        // Firms with less than 11 attorneys have a max per claim deductible of $25,000
        if (numberOfAttorneys < 2.5) {
            return perClaimDeductibleItems.filter((option) => option.value <= 10000);
        }
        if (numberOfAttorneys < 11) {
            return perClaimDeductibleItems.filter((option) => option.value <= 25000);
        }
        return perClaimDeductibleItems;
    };

    // Firms in the NJ, NY, and WA state have different per claim deductible
    const perClaimDeductibleItems =
        stateWithLargestNumber === stateNJ
            ? perClaimDefaultDeductibleItemsNJ
            : stateWithLargestNumber === stateNY
            ? perClaimDefaultDeductibleItemsNY
            : stateWithLargestNumber === stateWA
            ? perClaimDefaultDeductibleItemsWA
            : perClaimDefaultDeductibleItems;
    return getDeductibleOptionsByNumOfAttorneys(numberOfAttorneys, perClaimDeductibleItems);
}

export function getDefaultClaimExpenseType(
    stateWithLargestNum: string,
    selectedPerClaimLimit: PerClaimLimit,
): ClaimsExpenseType {
    const claimExpenseTypeItems = getClaimExpenseTypeItems(
        stateWithLargestNum,
        selectedPerClaimLimit,
    );
    return claimExpenseTypeItems[0].value;
}

function getClaimExpenseTypeItems(
    stateWithLargestNum: string,
    selectedPerClaimLimit: PerClaimLimit,
): SelectOptionItem<ClaimsExpenseType>[] {
    const claimsExpenseTypeItems: SelectOptionItem<ClaimsExpenseType>[] = [
        {
            title: 'Claims Expense Inside of Limits',
            value: 'ClaimsExpenseTypeInsideOfLimits',
        },
        {
            title: 'Claims Expense in Addition to Limits',
            value: 'ClaimsExpenseTypeInAdditionToLimits',
        },
        {
            title: 'Separate Claims Expenses Coverage',
            value: ClaimsExpenseTypeSeparateClaimsExpenses,
        },
    ];

    // NY has limited options for claims expense types
    let claimsExpenseTypeItemsNY: SelectOptionItem<ClaimsExpenseType>[];
    if (selectedPerClaimLimit < 500 * 1000) {
        claimsExpenseTypeItemsNY = [
            {
                title: 'Claims Expense in Addition to Limits',
                value: 'ClaimsExpenseTypeInAdditionToLimits',
            },
        ];
    } else if (selectedPerClaimLimit >= 500 * 1000 && selectedPerClaimLimit < 5000 * 1000) {
        claimsExpenseTypeItemsNY = [
            {
                title: 'Claims Expense Inside of Limits With 50% Offset',
                value: 'ClaimsExpenseTypeInsideOfLimitsWithOffset',
            },
            {
                title: 'Claims Expense in Addition to Limits',
                value: 'ClaimsExpenseTypeInAdditionToLimits',
            },
        ];
    } else {
        claimsExpenseTypeItemsNY = [
            {
                title: 'Claims Expense Inside of Limits With 50% Offset',
                value: 'ClaimsExpenseTypeInsideOfLimitsWithOffset',
            },
            {
                title: 'Claims Expense Inside of Limits',
                value: 'ClaimsExpenseTypeInsideOfLimits',
            },
            {
                title: 'Claims Expense in Addition to Limits',
                value: 'ClaimsExpenseTypeInAdditionToLimits',
            },
        ];
    }
    // NJ has limited options for claims expense types
    const claimsExpenseTypeItemsNJ: SelectOptionItem<ClaimsExpenseType>[] = [];

    if (selectedPerClaimLimit < 1000000) {
        claimsExpenseTypeItemsNJ.push({
            title: 'Claims Expense in Addition to Limits',
            value: 'ClaimsExpenseTypeInAdditionToLimits',
        });
    } else if (selectedPerClaimLimit >= 1000000 && selectedPerClaimLimit < 5000000) {
        claimsExpenseTypeItemsNJ.push(
            {
                title: 'Claims Expense in Addition to Limits',
                value: 'ClaimsExpenseTypeInAdditionToLimits',
            },
            {
                title: 'Claims Expense Inside of Limits With 50% Offset',
                value: 'ClaimsExpenseTypeInsideOfLimitsWithOffset',
            },
        );
    } else {
        claimsExpenseTypeItemsNJ.push({
            title: 'Claims Expense Inside of Limits With 50% Offset',
            value: 'ClaimsExpenseTypeInsideOfLimitsWithOffset',
        });
    }

    const stateNJ = 'NJ';
    if (stateWithLargestNum === stateNJ) {
        return claimsExpenseTypeItemsNJ;
    }

    const stateNY = 'NY';
    if (stateWithLargestNum === stateNY) {
        return claimsExpenseTypeItemsNY;
    }

    const stateWA = 'WA';
    if (stateWithLargestNum === stateWA) {
        return [
            {
                title: 'Claims Expense Inside of Limits',
                value: 'ClaimsExpenseTypeInsideOfLimits',
            },
            {
                title: 'Claims Expense in Addition to Limits',
                value: 'ClaimsExpenseTypeInAdditionToLimits',
            },
        ];
    }

    return claimsExpenseTypeItems;
}

function getDeductibleTypeItems(
    stateWithLargestNum: string,
    perClaimLimit: number,
): SelectOptionItem<DeductibleType>[] {
    // For a New Jersey quote, and for per claim limit < 500_000 in NY
    // the deductible application option can only be Loss Only
    const isLossAndClaimsExpenseAllowed = (stateWithLargestNum: string, perClaimLimit: number) => {
        const stateNJ = 'NJ';
        const stateNY = 'NY';

        if (stateWithLargestNum !== stateNJ && stateWithLargestNum !== stateNY) {
            return true;
        }
        if (stateWithLargestNum === stateNJ) {
            return false;
        }
        return stateWithLargestNum === stateNY && perClaimLimit >= 500 * 1000;
    };

    const isLossOnlyAllowed = (stateWithLargestNum: string, perClaimLimit: number) => {
        const stateNY = 'NY';
        return !(stateWithLargestNum === stateNY && perClaimLimit >= 500 * 1000);
    };

    const deductibleTypeItems: SelectOptionItem<DeductibleType>[] = [];
    if (isLossAndClaimsExpenseAllowed(stateWithLargestNum, perClaimLimit)) {
        deductibleTypeItems.push({
            title: 'No',
            value: DeductibleTypeLossAndClaimsExpense,
        });
    }

    if (isLossOnlyAllowed(stateWithLargestNum, perClaimLimit)) {
        deductibleTypeItems.push({
            title: 'Yes',
            value: DeductibleTypeLossOnly,
        });
    }

    return deductibleTypeItems;
}

export function isClaimsExpenseTypeValid(
    claimsExpenseType: ClaimsExpenseType,
    stateWithLargestNumber: string,
    perClaimLimit: PerClaimLimit,
) {
    const claimsExpenseTypeItems: SelectOptionItem<ClaimsExpenseType>[] = getClaimExpenseTypeItems(
        stateWithLargestNumber,
        perClaimLimit,
    );
    return claimsExpenseTypeItems.some((option) => option.value === claimsExpenseType);
}

export function isPerClaimDeductibleValid(
    perClaimDeductible: PerClaimDeductible,
    numberOfAttorneys: number,
    stateWithLargestNumber: string,
) {
    const perClaimDeductibleItems: SelectOptionItem<PerClaimDeductible>[] =
        getPerClaimDeductibleItems(numberOfAttorneys, stateWithLargestNumber);
    return perClaimDeductibleItems.some((option) => option.value === perClaimDeductible);
}

export function isAggregateLimitValid(
    perClaimLimit: PerClaimLimit,
    stateWithLargestNumber: string,
    aggregateLimit: AggregateLimit,
) {
    const aggregateLimitItems: SelectOptionItem<AggregateLimit>[] = getAggregateLimitItems(
        perClaimLimit,
        stateWithLargestNumber,
    );
    return aggregateLimitItems.some((option) => option.value === aggregateLimit);
}

export function isSeparateClaimExpenseLimitValid(
    claimsExpenseType: ClaimsExpenseType,
    perClaimLimit: PerClaimLimit,
    separateClaimExpenseLimit: SeparateClaimExpenseLimit | null,
    stateWithLargestNumber: string,
) {
    const separateClaimExpenseLimitItems = getSeparateClaimExpenseLimitItems(
        claimsExpenseType,
        perClaimLimit,
        stateWithLargestNumber,
    );
    //SeparateClaimExpenseLimit is only available for California, for every other state the list
    //of options is an empty array and in that case the only valid value for separateClaimExpenseLimit
    //is null
    if (separateClaimExpenseLimitItems.length === 0) {
        return separateClaimExpenseLimit === null;
    }
    return separateClaimExpenseLimitItems.some(
        (option) => option.value === separateClaimExpenseLimit,
    );
}

export function getLPLQuoteFormItems(
    selectedPerClaimLimit: PerClaimLimit,
    selectedClaimsExpenseType: ClaimsExpenseType,
    totalNumberOfAttorneys: number,
    stateWithLargestNumber: string,
    higherLimit?: LPLHigherLimit,
): LPLQuoteFormItems {
    return {
        perClaimLimitItems: getPerClaimLimitItems(stateWithLargestNumber, higherLimit),
        aggregateLimitItems: getAggregateLimitItems(
            selectedPerClaimLimit,
            stateWithLargestNumber,
            higherLimit,
        ),
        perClaimDeductibleItems: getPerClaimDeductibleItems(
            totalNumberOfAttorneys,
            stateWithLargestNumber,
        ),
        separateClaimExpenseLimitItems: getSeparateClaimExpenseLimitItems(
            selectedClaimsExpenseType,
            selectedPerClaimLimit,
            stateWithLargestNumber,
        ),

        claimsExpenseTypeItems: getClaimExpenseTypeItems(
            stateWithLargestNumber,
            selectedPerClaimLimit,
        ),
        deductibleTypeItems: getDeductibleTypeItems(stateWithLargestNumber, selectedPerClaimLimit),
    };
}

const getPerClaimLimitItems = (
    stateWithLargestNum: string,
    higherLimit?: LPLHigherLimit,
): SelectOptionItem<PerClaimLimit>[] => {
    const perClaimRegularLimitListNY: SelectOptionItem<PerClaimLimit>[] = [
        { title: '$100,000', value: 100000 },
        { title: '$200,000', value: 200000 },
        { title: '$250,000', value: 250000 },
        { title: '$500,000', value: 500000 },
        { title: '$750,000', value: 750000 },
        { title: '$1,000,000', value: 1000000 },
        { title: '$2,000,000', value: 2000000 },
        { title: '$3,000,000', value: 3000000 },
        { title: '$4,000,000', value: 4000000 },
        { title: '$5,000,000', value: 5000000 },
    ];

    // Per claim limit of 750_000 is allowed in NY only
    const perClaimRegularLimitList =
        stateWithLargestNum === 'NY'
            ? perClaimRegularLimitListNY
            : perClaimRegularLimitListNY.filter((option) => option.value !== 750000);

    const approvedPerClaimLimit = higherLimit?.per_claim_limit;
    if (approvedPerClaimLimit) {
        return perClaimRegularLimitList.filter((item) => {
            return item.value <= approvedPerClaimLimit;
        });
    }
    return perClaimRegularLimitList;
};

export function isPerClaimLimitValid(
    perClaimLimit: PerClaimLimit,
    stateWithLargestNumber: string,
    higherLimit?: LPLHigherLimit,
) {
    const perClaimLimitItems: SelectOptionItem<PerClaimLimit>[] = getPerClaimLimitItems(
        stateWithLargestNumber,
        higherLimit,
    );
    return perClaimLimitItems.some((option) => option.value === perClaimLimit);
}
