import { Nullable } from '@embroker/shotwell/core/types';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { QuoteOptions } from '../../entities/Quote';

export type DeductibleType = 'DeductibleTypeLossAndClaimsExpense' | 'DeductibleTypeLossOnly';
export const DeductibleTypeSchema = {
    ...defineValidator<DeductibleType>(
        Joi.string().valid('DeductibleTypeLossAndClaimsExpense', 'DeductibleTypeLossOnly'),
    ),
};

export type ClaimsExpenseType =
    | 'ClaimsExpenseTypeInsideOfLimits'
    | 'ClaimsExpenseTypeInAdditionToLimits'
    | 'ClaimsExpenseTypeInsideOfLimitsWithOffset'
    | 'ClaimsExpenseTypeSeparateClaimsExpenses';
export const PerClaimLimitList = [
    100000, 200000, 250000, 500000, 750000, 1000000, 2000000, 3000000, 4000000, 5000000,
] as const;
export const AggregateLimitList = [
    300000, 500000, 600000, 750000, 1000000, 1500000, 2000000, 3000000, 4000000, 5000000,
] as const;
export const PerClaimDeductibleList = [
    1000, 2000, 2500, 3000, 4000, 5000, 10000, 15000, 20000, 25000, 35000, 50000,
] as const;
export const SeparateClaimExpenseLimitList = [
    100000, 200000, 250000, 500000, 1000000, 2000000, 3000000,
] as const;
export type PerClaimLimit = (typeof PerClaimLimitList)[number];
export type AggregateLimit = (typeof AggregateLimitList)[number];
export type PerClaimDeductible = (typeof PerClaimDeductibleList)[number];
export type SeparateClaimExpenseLimit = (typeof SeparateClaimExpenseLimitList)[number];

export interface LPLQuoteOptions extends QuoteOptions {
    readonly perClaimLimit: PerClaimLimit;
    readonly aggregateLimit: AggregateLimit;
    readonly perClaimDeductible: PerClaimDeductible;
    readonly deductibleType: DeductibleType;
    readonly claimsExpenseType: ClaimsExpenseType;
    readonly separateClaimExpenseLimit: Nullable<SeparateClaimExpenseLimit>;
    readonly isDeselected: boolean;
}

export const LPLQuoteOptions = {
    ...defineValidator<LPLQuoteOptions>({
        effectiveDate: Joi.date().required(),
        perClaimLimit: Joi.number().required(),
        aggregateLimit: Joi.number().required(),
        perClaimDeductible: Joi.number().required(),
        deductibleType: DeductibleTypeSchema.schema.required(),
        claimsExpenseType: Joi.string().required(),
        separateClaimExpenseLimit: Joi.number().allow(null),
        isDeselected: Joi.boolean().required(),
    }),
    create(lplQuoteOptions: LPLQuoteOptions) {
        return LPLQuoteOptions.validate(lplQuoteOptions);
    },
};
