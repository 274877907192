import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { execute, UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { DocGenFailed } from '../../errors';
import { DocumentType } from '../../types/Document';
import { LPLQuote } from '../entities/LPLQuote';
import { CreateLPLDocument } from './CreateLPLDocument';

interface DownloadLPLQuoteSummaryRequest {
    quote: LPLQuote;
    abortSignal: AbortSignal;
}

interface DownloadLPLQuoteSummaryResponse {
    quote: Immutable<LPLQuote>;
    fileUrl: URI;
}

export interface DownloadLPLQuoteSummary extends UseCase {
    execute(
        request: DownloadLPLQuoteSummaryRequest,
    ): AsyncResult<DownloadLPLQuoteSummaryResponse, DocGenFailed>;
}

@injectable()
class DownloadLPLQuoteSummaryUseCase extends UseCase implements DownloadLPLQuoteSummary {
    public static type = Symbol('LPLQuote/DownloadLPLQuoteSummary');

    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    public async execute({
        quote,
        abortSignal,
    }: DownloadLPLQuoteSummaryRequest): AsyncResult<DownloadLPLQuoteSummaryResponse, DocGenFailed> {
        const createDocumentResult = await execute(CreateLPLDocument, {
            applicationId: quote.applicationId,
            quoteId: quote.id,
            documentType: DocumentType.QuoteSummary,
            fileKey: quote.fileKey,
            abortSignal: abortSignal,
        });
        if (isErr(createDocumentResult)) {
            return Failure(DocGenFailed({ errors: createDocumentResult.errors }));
        }

        quote.assignFileKey(createDocumentResult.value.fileKey);

        return Success<DownloadLPLQuoteSummaryResponse>({
            quote: quote,
            fileUrl: createDocumentResult.value.fileUrl,
        });
    }
}

export const DownloadLPLQuoteSummary: UseCaseClass<DownloadLPLQuoteSummary> =
    DownloadLPLQuoteSummaryUseCase;
