import { OperationFailed } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { isErr, isOK, Success } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Nullable, Spinner, StackLayout, TextButton, useModal } from '@embroker/ui-toolkit/v2';
import React, { useEffect } from 'react';
import {
    AwaitingBind,
    AwaitingQuotes,
    Bound,
    DeclinedByCarrier,
    InCreation,
    InsuranceApplicationStatusCode,
    NotEligible,
    QuestionnaireInProgress,
    QuoteExpired,
    QuotesReady,
    QuotingEngine,
    QuotingEngineCrime,
    QuotingEngineCyber,
    QuotingEngineESP,
    QuotingEngineExcess,
    QuotingEngineLPLEverest,
    QuotingEnginePCoML,
    Referred,
    SupplementalInProgress,
} from '../../../shopping/types/enums';
import { IneligibilityReasons } from '../../../shopping/types/IneligibilityReasons';
import { ViewQuestionnaireModal } from '../../../shopping/view/components/ViewQuestionnaireModal';
import { SelectOrganization } from '../../../userOrg/useCases/SelectOrganization';
import { navigateToErrorPage } from '../../../view/errors';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { EditApplication } from '../../useCases/EditApplication';
import { useAsyncTrigger } from '@embroker/shotwell/view/hooks/useAsyncTrigger';
import { PrintBinder } from '../../../shopping/useCases/PrintBinder';

export interface BrokerQuoteActionButtonProps {
    applicationId: UUID;
    organizationId: UUID;
    status: InsuranceApplicationStatusCode;
    isPristine: boolean;
    hasQuotes: boolean;
    quotingEngine?: QuotingEngine;
    ineligibilityReasons?: Immutable<IneligibilityReasons>;
    startContinueLabel?: string;
    isRenewal?: boolean;
    showEditApplication: boolean;
}

export function BrokerQuoteActionButton({
    applicationId,
    organizationId,
    status,
    isPristine,
    hasQuotes,
    quotingEngine,
    ineligibilityReasons,
    startContinueLabel,
    isRenewal,
    showEditApplication,
}: BrokerQuoteActionButtonProps): Nullable<JSX.Element> {
    const viewQuestionnaireModal = useModal();
    const { navigate } = useNavigation();

    const handleStartContinue = async () => {
        await execute(SelectOrganization, { organizationId });
        navigate(
            URI.build('/shopping/application', {
                applicationId,
            }),
        );
    };
    const handleInCreationStartContinue = async () => {
        await execute(SelectOrganization, { organizationId });
        navigate(
            URI.build('/broker/application/', {
                applicationId,
            }),
        );
    };
    const handleViewQuotes = async () => {
        if (hasQuotes) {
            const link = resolveBrokerViewQuotesLink({ applicationId, quotingEngine, isRenewal });
            if (link) {
                await execute(SelectOrganization, { organizationId });
                return navigate(link);
            }
            return navigateToErrorPage(navigate, [
                OperationFailed({
                    message: 'Failed to resolve view quotes link',
                }),
            ]);
        }
    };
    const handleContinueSupplementalQuestionnaire = async () => {
        await execute(SelectOrganization, { organizationId });
        navigate(
            URI.build('/shopping/application/supplemental-questionnaire/esp', {
                applicationId: applicationId,
            }),
        );
    };
    const handleViewEstimate = async () => {
        await execute(SelectOrganization, { organizationId });
        navigate(
            URI.build('/shopping/application/quote/lpl/referred-estimate', {
                applicationId,
            }),
        );
    };
    const handleViewQuestionnaire = async () => {
        await execute(SelectOrganization, { organizationId });
        viewQuestionnaireModal.show();
    };
    const handleQuoteExpired = async () => {
        await execute(SelectOrganization, { organizationId });
        const editApplicationResult = await execute(EditApplication, {
            applicationId,
        });
        if (editApplicationResult && isOK(editApplicationResult)) {
            navigate(
                URI.build('/shopping/application/basic-info', {
                    applicationId,
                }),
            );
        }
    };

    const {
        trigger: onViewBinder,
        isLoading: isLoadingBinder,
        result: viewBinderResult,
    } = useAsyncTrigger(async () => {
        await execute(SelectOrganization, { organizationId });
        const printBinderResult = await execute(PrintBinder, {
            applicationId,
        });

        if (isErr(printBinderResult)) {
            return printBinderResult;
        }

        const pdfTab = window.open(printBinderResult.value, '_blank', 'noopener, noreferrer');
        pdfTab?.focus();

        return Success();
    });

    useEffect(() => {
        if (viewBinderResult && isErr(viewBinderResult)) {
            navigateToErrorPage(navigate, viewBinderResult.errors);
        }
    }, [viewBinderResult, navigate]);

    switch (status) {
        case AwaitingQuotes:
        case DeclinedByCarrier:
        case NotEligible:
            return (
                <React.Fragment>
                    <TextButton onClick={handleViewQuestionnaire}>View questionnaire</TextButton>
                    <ViewQuestionnaireModal
                        applicationId={applicationId}
                        modal={viewQuestionnaireModal}
                    />
                </React.Fragment>
            );
        case QuestionnaireInProgress:
            return (
                <TextButton onClick={handleStartContinue}>
                    {startContinueLabel || (isPristine ? 'Start' : 'Continue')}
                </TextButton>
            );
        case InCreation:
            return (
                <TextButton onClick={handleInCreationStartContinue}>
                    {startContinueLabel || (isPristine ? 'Start' : 'Continue')}
                </TextButton>
            );
        case QuotesReady:
            return (
                <StackLayout gap="none">
                    <TextButton onClick={handleViewQuotes}>View Quotes</TextButton>
                    {showEditApplication ? (
                        <TextButton onClick={handleQuoteExpired}>Edit Application</TextButton>
                    ) : null}
                </StackLayout>
            );
        case Referred:
            // there is an action button only if it is:
            // 1) ESP/PCoML loss runs review
            // 2) LPL indication
            // 3) Higher limits supplemental questionnaire is in progress
            if (isUnderLossRunsReview(quotingEngine, ineligibilityReasons)) {
                return <TextButton onClick={handleViewQuotes}>View Quotes</TextButton>;
            }
            if (isLPLIndication(quotingEngine, ineligibilityReasons)) {
                return <TextButton onClick={handleViewEstimate}>View Estimate</TextButton>;
            }
            return null;
        case QuoteExpired:
            return <TextButton onClick={handleQuoteExpired}>{'Resubmit'}</TextButton>;
        case Bound:
            return (
                <React.Fragment>
                    {isLoadingBinder && <Spinner />}
                    <TextButton onClick={onViewBinder}>{'View Binder'}</TextButton>
                </React.Fragment>
            );
        case SupplementalInProgress:
            return (
                <TextButton onClick={handleContinueSupplementalQuestionnaire}>Continue</TextButton>
            );
        case AwaitingBind:
            return showEditApplication ? (
                <TextButton onClick={handleQuoteExpired}>Edit Application</TextButton>
            ) : null;
    }
    return null;
}

interface ResolveBrokerViewQuotesLinkInput {
    applicationId: UUID;
    quotingEngine?: QuotingEngine;
    isRenewal?: boolean;
}

export function resolveBrokerViewQuotesLink({
    applicationId,
    quotingEngine,
    isRenewal,
}: ResolveBrokerViewQuotesLinkInput) {
    const baseURI = '/shopping/application/quote/';
    if (quotingEngine === QuotingEnginePCoML) {
        return URI.build(`${baseURI}pcoml`, { applicationId });
    }
    if (quotingEngine === QuotingEngineESP) {
        if (isRenewal) {
            return URI.build(`${baseURI}esp-renewals`, { applicationId });
        }
        return URI.build(`${baseURI}esp`, { applicationId });
    }
    if (quotingEngine === QuotingEngineLPLEverest) {
        return URI.build(`${baseURI}lpl`, { applicationId });
    }
    if (quotingEngine === QuotingEngineCrime) {
        return URI.build(`${baseURI}crime`, { applicationId });
    }
    if (quotingEngine === QuotingEngineCyber) {
        return URI.build(`${baseURI}cyber`, { applicationId });
    }
    if (quotingEngine === QuotingEngineExcess) {
        return URI.build(`${baseURI}excess`, { applicationId });
    }
}

export function isUnderLossRunsReview(
    quotingEngine?: QuotingEngine,
    ineligibilityReasons?: Immutable<IneligibilityReasons>,
): boolean {
    return (
        (quotingEngine === QuotingEngineESP || quotingEngine === QuotingEnginePCoML) &&
        ineligibilityReasons !== undefined &&
        ineligibilityReasons.referralReasons.length === 1 &&
        ineligibilityReasons.referralReasons[0] ===
            'Submission referred for underwrite review of loss runs'
    );
}

export function isLPLIndication(
    quotingEngine?: QuotingEngine,
    ineligibilityReasons?: Immutable<IneligibilityReasons>,
): boolean {
    const referralReasons = ['Firm has 11-50.5 lawyers', 'Firm has 11-20.5 lawyers'];
    return (
        quotingEngine === QuotingEngineLPLEverest &&
        ineligibilityReasons !== undefined &&
        ineligibilityReasons.referralReasons.length == 1 &&
        referralReasons.includes(ineligibilityReasons.referralReasons[0])
    );
}
