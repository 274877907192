import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ApplicationRepository } from '../repositories/ApplicationRepository';
import { AppTypeCode } from '../types/enums';
import { ShoppingCoverageCodeArray } from '../types/ShoppingCoverageCodeArray';

export interface CreateApplicationIntakeRequest {
    appType: AppTypeCode;
    shoppingCoverageList: ShoppingCoverageCodeArray;
    fileUrl: string;
    applicationId?: UUID;
}

export interface CreateApplicationIntakeResponse {
    taskId: UUID;
    applicationId: UUID;
}

export interface CreateApplicationIntake extends UseCase {
    execute(
        request: CreateApplicationIntakeRequest,
    ): AsyncResult<CreateApplicationIntakeResponse, InvalidArgument | OperationFailed>;
}

export class CreateApplicationIntakeUseCase extends UseCase implements CreateApplicationIntake {
    public static type = Symbol('Shopping/CreateApplicationIntake');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        appType,
        shoppingCoverageList,
        fileUrl,
        applicationId,
    }: CreateApplicationIntakeRequest): AsyncResult<
        CreateApplicationIntakeResponse,
        InvalidArgument | OperationFailed
    > {
        const result = await this.applicationRepository.createApplicationIntakeTask({
            appType,
            shoppingCoverageList,
            fileUrl,
            applicationId,
        });

        if (isErr(result)) {
            return result;
        }
        return Success({ taskId: result.value.taskId, applicationId: result.value.applicationId });
    }
}

export const CreateApplicationIntake: UseCaseClass<CreateApplicationIntake> =
    CreateApplicationIntakeUseCase;
