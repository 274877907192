import { Nominal } from '@embroker/shotwell/core/types/Nominal';
import { URI } from '@embroker/shotwell/core/types/URI';
import { Modal, ModalActions, ModalState } from '@embroker/ui-toolkit/v2';
import React, { useCallback } from 'react';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { ModalLayout } from '../../../view/components/ModalLayout.view';

interface RenewalWarningModalInterface {
    modal: ModalState & ModalActions;
    onCreateNewApp: () => void;
    renewalApplicationId: Nominal<string, 'UUID'> | undefined;
}

export function RenewalWarningModal({
    modal,
    onCreateNewApp,
    renewalApplicationId,
}: RenewalWarningModalInterface) {
    const { navigate } = useNavigation();

    const handleCompleteRenewal = useCallback(() => {
        const startRenewalURI = URI.build('/shopping/application/basic-info', {
            applicationId: renewalApplicationId,
        });
        navigate(startRenewalURI);
    }, [navigate, renewalApplicationId]);

    return (
        <Modal {...modal} size="small">
            <ModalLayout
                title="Existing Renewal Application"
                primaryAction={{
                    onClick: handleCompleteRenewal,
                    label: 'Complete Renewal',
                }}
                secondaryAction={{
                    onClick: onCreateNewApp,
                    label: 'Create New Application',
                }}
            >
                A renewal application for this policy type is available on your dashboard. If
                looking to renew your policy please complete the renewal instead of creating a new
                creating a new application.
            </ModalLayout>
        </Modal>
    );
}
