import { UUID } from '@embroker/shotwell/core/types/UUID';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UseCase, execute, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { Aborted, InvalidArgument, OperationFailed, Timeout } from '@embroker/shotwell/core/Error';
import { ConfigFetchFailed } from '@app/quote/pcoml/errors';
import { DocGenFailed } from '@app/quote/errors';
import { inject } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { BOPHartfordQuoteRepository } from '@app/quote/hartford-bop/repositories/BOPHartfordQuoteRepository';
import { TasksRepository } from '@app/tasks/repositories';
import { GetDocumentUrl } from '@app/documents/useCases/GetDocumentUrl';
import { cast } from '@embroker/shotwell/core/types/Nominal';

export interface GenerateQuoteFileUrlRequest {
    applicationId: UUID;
    quoteId: UUID;
    abortSignal: AbortSignal;
}

export interface GenerateQuoteFileUrlResponse {
    readonly documentUrl: URI;
}

export interface GenerateQuoteFileUrl extends UseCase {
    execute(
        request: GenerateQuoteFileUrlRequest,
    ): AsyncResult<
        GenerateQuoteFileUrlResponse,
        InvalidArgument | OperationFailed | ConfigFetchFailed | Timeout | Aborted | DocGenFailed
    >;
}

class GenerateQuoteFileUrlUseCase extends UseCase implements GenerateQuoteFileUrl {
    public static type = Symbol('Hartford/GenerateQuoteFileUrl');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BOPHartfordQuoteRepository)
        private hartfordRepository: BOPHartfordQuoteRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
        quoteId,
        abortSignal,
    }: GenerateQuoteFileUrlRequest): AsyncResult<
        GenerateQuoteFileUrlResponse,
        InvalidArgument | OperationFailed | ConfigFetchFailed | Timeout | Aborted | DocGenFailed
    > {
        const createTaskResult = await this.hartfordRepository.createQuoteSummaryAsyncTask(
            applicationId,
            quoteId,
        );

        if (isErr(createTaskResult)) {
            return createTaskResult;
        }

        const pollForTaskStatusResult = await this.tasksRepository.pollForTaskStatus(
            createTaskResult.value,
            abortSignal,
        );
        if (isErr(pollForTaskStatusResult)) {
            return Failure(DocGenFailed({ errors: pollForTaskStatusResult.errors }));
        }

        const latestQuoteResult = await this.hartfordRepository.getLatestQuote(applicationId);

        if (isErr(latestQuoteResult)) {
            return latestQuoteResult;
        }

        const getDocumentUrlResult = await execute(GetDocumentUrl, {
            fileKey: latestQuoteResult.value.fileKey as string,
        });

        if (isErr(getDocumentUrlResult)) {
            return getDocumentUrlResult;
        }

        return Success<GenerateQuoteFileUrlResponse>({
            documentUrl: cast<URI>(getDocumentUrlResult.value.downloadUrl),
        });
    }
}

export const GenerateQuoteFileUrl: UseCaseClass<GenerateQuoteFileUrl> = GenerateQuoteFileUrlUseCase;
