import { inject } from '@embroker/shotwell/core/di';
import {
    Aborted,
    InvalidArgument,
    OperationFailed,
    Timeout,
    UnknownEntity,
} from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { cast } from '@embroker/shotwell/core/types/Nominal';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute, UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { GetDocumentUrl } from '../../../documents/useCases/GetDocumentUrl';
import { TasksRepository } from '../../../tasks/repositories';
import { DocGenFailed } from '../../errors';
import { CyberQuoteRepository } from '../repositories/CyberQuoteRepository';

export interface GenerateQuoteSummaryUrlRequest {
    readonly applicationId: UUID;
    readonly quoteId: UUID;
    readonly abortSignal: AbortSignal;
}

export interface GenerateQuoteSummaryUrlResponse {
    readonly quoteSummaryUrl: URI;
}

export interface GenerateQuoteSummaryUrl extends UseCase {
    execute(
        request: GenerateQuoteSummaryUrlRequest,
    ): AsyncResult<
        GenerateQuoteSummaryUrlResponse,
        UnknownEntity | InvalidArgument | OperationFailed | Aborted | Timeout | DocGenFailed
    >;
}

class GenerateQuoteSummaryUrlUseCase extends UseCase implements GenerateQuoteSummaryUrl {
    public static type = Symbol('CyberQuote/GenerateQuoteSummaryUrl');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(CyberQuoteRepository) private cyberQuoteRepository: CyberQuoteRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
        quoteId,
        abortSignal,
    }: GenerateQuoteSummaryUrlRequest): AsyncResult<
        GenerateQuoteSummaryUrlResponse,
        UnknownEntity | InvalidArgument | OperationFailed | Aborted | Timeout | DocGenFailed
    > {
        const createAsyncTaskResult = await this.cyberQuoteRepository.createQuoteSummaryAsyncTask(
            applicationId,
            quoteId,
        );
        if (isErr(createAsyncTaskResult)) {
            return createAsyncTaskResult;
        }

        const createQuoteSummaryResult = await this.awaitCreateQuoteSummaryTask({
            taskId: createAsyncTaskResult.value,
            applicationId,
            quoteId,
            abortSignal,
        });
        if (isErr(createQuoteSummaryResult)) {
            return Failure(DocGenFailed({ errors: createQuoteSummaryResult.errors }));
        }

        const fileKey = createQuoteSummaryResult.value;
        if (fileKey === undefined || fileKey === '') {
            return Failure(OperationFailed({ message: 'No generated Quote Summary was found.' }));
        }

        const getDocumentUrlResult = await execute(GetDocumentUrl, { fileKey });
        if (isErr(getDocumentUrlResult)) {
            return getDocumentUrlResult;
        }

        return Success<GenerateQuoteSummaryUrlResponse>({
            quoteSummaryUrl: cast<URI>(getDocumentUrlResult.value.downloadUrl),
        });
    }

    private async awaitCreateQuoteSummaryTask({
        taskId,
        applicationId,
        quoteId,
        abortSignal,
    }: {
        taskId: UUID;
        applicationId: UUID;
        quoteId: UUID;
        abortSignal: AbortSignal;
    }): AsyncResult<string | undefined, InvalidArgument | OperationFailed | Aborted | Timeout> {
        const pollForTaskStatusResult = await this.tasksRepository.pollForTaskStatus(
            taskId,
            abortSignal,
        );
        if (isErr(pollForTaskStatusResult)) {
            return pollForTaskStatusResult;
        }

        const lastQuoteResult = await this.cyberQuoteRepository.getLastCyberQuote(applicationId);
        if (isErr(lastQuoteResult)) {
            return lastQuoteResult;
        }
        if (lastQuoteResult.value.id !== quoteId) {
            return Failure(OperationFailed({ message: 'Not the latest cyber quote' }));
        }

        return Success(lastQuoteResult.value.fileKey);
    }
}

export const GenerateQuoteSummaryUrl: UseCaseClass<GenerateQuoteSummaryUrl> =
    GenerateQuoteSummaryUrlUseCase;
