import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { Text, useModal } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { WizardForm } from '../../../../view/hooks/useWizardForm';
import { BundleCoverageTypeEnum } from '../../../types/BundleQuoteCoverage';
import { DocumentType } from '../../../types/BundleQuoteDocument';
import { BundleQuoteFormData } from '../../../types/BundleQuoteFormData';
import { Documents } from '../../../view/components/BundleQuoteLandingPage';
import { GenerateDocumentHyperlink } from '../../../view/components/GenerateDocumentHyperlink';
import { CowbellTermsConditionsModal } from './CowbellTermsConditionsModal';
import { Link } from '../../../../view/components/Link/Link';

interface SignInsuranceCowbellAttestationSignatureProps {
    documents: Documents;
    trigger: WizardForm<OpaqueForm<BundleQuoteFormData>>['trigger'];
}
export function SignInsuranceCowbellAttestationSignature({
    trigger,
    documents,
}: SignInsuranceCowbellAttestationSignatureProps) {
    const cowbellTermsConditionsModal = useModal();

    const cyberApplicationAttestationFileUrl = documents.find(
        (document) =>
            document.documentType === DocumentType.ApplicationAttestation &&
            document.coverageType === BundleCoverageTypeEnum.LawCyberBundleCoverageCowbell,
    )?.url;

    return (
        <React.Fragment>
            <Text style="body 1" data-e2e="cowbell-attestation">
                I have read Cowbell Insurance Agency{' '}
                <Link href="" display="inline" onClick={cowbellTermsConditionsModal.show}>
                    Terms of Use
                </Link>
                , Insurance fraud warnings and{' '}
                <GenerateDocumentHyperlink
                    fileUrl={cyberApplicationAttestationFileUrl}
                    handleTrigger={() => trigger('generateCowbellCyberApplicationAttestation')}
                >
                    State-Specific Signature-Bearing Endorsements
                </GenerateDocumentHyperlink>
                .
            </Text>
            <CowbellTermsConditionsModal modal={cowbellTermsConditionsModal} />
        </React.Fragment>
    );
}
