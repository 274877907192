import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Money, USD } from '@embroker/shotwell/core/types/Money';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { InvoiceRepository } from '../repositories/InvoiceRepository';

export interface SumInvoicesToPayRequest {
    invoiceIds: UUID[];
}

export interface SumInvoicesToPay extends UseCase {
    execute(
        request: SumInvoicesToPayRequest,
    ): AsyncResult<Money, InvalidArgument | OperationFailed>;
}

@injectable()
export class SumInvoicesToPayUseCase extends UseCase {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Payments/SumInvoicesToPay');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(InvoiceRepository) private invoiceRepo: InvoiceRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        input: SumInvoicesToPayRequest,
    ): AsyncResult<Money, InvalidArgument | OperationFailed> {
        if (input.invoiceIds.length === 0) {
            return Success(USD(0));
        }

        const result = await this.invoiceRepo.getByIds(input.invoiceIds);

        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        if (result.value.length == 0) {
            return Failure(OperationFailed({ message: 'Expected at least one selected invoice' }));
        }

        const sumResult = Money.sum(result.value.map((invoice) => invoice.balance));

        if (isErr(sumResult)) {
            return Failure(OperationFailed({ message: 'Cannot sum different currencies' }));
        }

        return Success(sumResult.value);
    }
}

export const SumInvoicesToPay: UseCaseClass<SumInvoicesToPay> = SumInvoicesToPayUseCase;
