import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { Modal, ModalActions, ModalState } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { WizardForm } from '../../../../view/hooks/useWizardForm';
import { ESPQuoteOptionsFormData } from './ESPQuoteLandingPage';
import { ModalLayout } from '../../../../view/components/ModalLayout.view';

interface HigherLimitModalProps<T extends ESPQuoteOptionsFormData> {
    readonly modal: ModalActions & ModalState;
    shouldHaveIptChanges?: boolean;
    trigger: WizardForm<OpaqueForm<T>>['trigger'];
}

export function HigherLimitModal<T extends ESPQuoteOptionsFormData>({
    modal,
    shouldHaveIptChanges,
    trigger,
}: HigherLimitModalProps<T>) {
    const handleCLick = async () => {
        trigger('requestHigherLimits');
        modal.hide();
    };
    return (
        <Modal {...modal} size="small" data-e2e="submit-higher-limit-modal" dismissable>
            <ModalLayout
                title="Are you sure you want to submit this request?"
                primaryAction={{
                    label: 'Submit Request',
                    onClick: handleCLick,
                    'data-e2e': 'submit-request',
                }}
                secondaryAction={{
                    label: 'Cancel',
                    onClick: modal.hide,
                    'data-e2e': 'cancel',
                }}
            >
                {shouldHaveIptChanges
                    ? 'Once you submit this request, our team can process and review your application.'
                    : "Once you submit this request, you'll be prompted to answer a few more questions so that our team can process your application."}
            </ModalLayout>
        </Modal>
    );
}
