import React, { SyntheticEvent } from 'react';
import {
    Button,
    ColumnLayout,
    Modal,
    ModalActions,
    ModalState,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import { Link } from '../../../view/components';

export interface NoShareableCoveragesProps {
    modal: ModalState & ModalActions;
    hideModal: Function;
}

export const NoShareableCoveragesModal = ({ modal, hideModal }: NoShareableCoveragesProps) => {
    return (
        <Modal {...modal}>
            <StackLayout gap="32">
                <StackLayout gap="24">
                    <Text style="heading 3">
                        Sorry you currently don’t have shareable coverage.
                    </Text>
                    <Text>
                        In order to issue a certificate you need a coverage with Embroker.
                        Currently, you have none active.
                    </Text>
                </StackLayout>
                <ColumnLayout gap="32">
                    <Button as={Link} href="/support/contact">
                        Contact Support
                    </Button>
                    <TextButton onClick={(event: SyntheticEvent) => hideModal()}>Cancel</TextButton>
                </ColumnLayout>
            </StackLayout>
        </Modal>
    );
};
