import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { execute } from '@embroker/shotwell/core/UseCase';
import { GetDocumentUrl } from '../../../documents/useCases/GetDocumentUrl';
import { QuotingEngineESP } from '../../../shopping/types/enums';
import { BundleCoverageType, BundleCoverageTypeEnum } from '../../types/BundleQuoteCoverage';
import { DocumentType } from '../../types/BundleQuoteDocument';
import { BuildBundleDocumentListProps } from '../../view/components/buildBundleDocumentList';
import { DocumentsItem } from '../../view/components/BundleQuoteLandingPage';
import {
    CoverageDefinition,
    ESPDocumentTitle,
    EspProductName,
    EspProductTitle,
    distributedPrefix,
    GetDocumentDownloadMetadataResponse,
    ESP,
    ProductFormData,
    generateDocumentDisplayName,
} from '../coverageDefinition';
import { ESPQuote } from './entities/ESPQuote';
import { buildESPCoverage } from './buildESPCoverage';
import { BundleQuote } from '@app/bundle/entities/BundleQuote';
import {
    ActionHandler,
    CreateFormParams,
    FormAction,
    OpaqueForm,
} from '@embroker/shotwell/view/hooks/useForm';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { keysOf } from '@embroker/shotwell/core/object';
import { getDocumentUrl } from '../bundleMappingFunctions';
import { WizardForm } from '@app/view/hooks/useWizardForm';
import { ESPBundleCoverageOptions } from './components/ESPBundleCoverageOptions';
import { ESPBundleSummary } from './components/ESPBundleSummary';
import { ESPModalCoverageSummary } from './components/ESPModalCoverageSummary';
import { ESPBundleFooter } from './components/ESPBundleFooter';
import {
    formDataToESPQuoteOptions,
    isESPFormData,
    isESPQuoteOptions,
    toApiESPQuoteOptions,
} from './mappingFunctions';
import { coverageOptionSchema, ESPQuoteOptions } from './types/ESPQuoteOptions';
import { GenerateDocumentButton } from '@app/bundle/view/components/GenerateDocumentButton';
import { espCoverageDetails } from '@app/quote/espUtils/quoteDocumentUtils';
import {
    ESPGeneralTermsAndConditionsPlusPostIPT2024URL,
    ESPGeneralTermsAndConditionsStandardPostIPT2024URL,
} from '@app/quote/espUtils/constants';

export const ESPCoverageDefinition: CoverageDefinition<ESPQuote> = {
    productName: EspProductName,
    productTitle: EspProductTitle,
    documentTitle: ESPDocumentTitle,
    type: BundleCoverageTypeEnum.ESPCoverage,
    quotingEngine: QuotingEngineESP,
    buildCoverage: buildESPCoverage,
    mapFormDataToQuoteOptions: formDataToESPQuoteOptions,
    apiProductDesignation: ESP,
    mapQuoteOptionsToAPI: toApiESPQuoteOptions,
    coverageOptionsComponent: ESPBundleCoverageOptions,
    summaryComponent: ESPBundleSummary,
    modalSummaryComponent: ESPModalCoverageSummary,
    footerComponent: ESPBundleFooter,
    isFieldsValidType(
        input: any,
    ): input is WizardForm<OpaqueForm<distributedPrefix<ESPQuoteOptions>>>['fields'] {
        return isESPFormData(input);
    },
    isOptionsValidType(input: any): input is ESPQuoteOptions {
        return isESPQuoteOptions(input);
    },
    getProductFields(
        bundleQuote: BundleQuote,
    ): CreateFormParams<ProductFormData<distributedPrefix<ESPQuoteOptions>>>['fields'] {
        // TODO: https://embroker.atlassian.net/browse/EM-43848
        // These fileds are set up to be implemented upom in a follow up ticket
        // Can be considered as a placeholder for now
        return {
            espDirectorsAndOfficers: {
                type: 'text',
                validator: coverageOptionSchema.required(),
            },
            espEmploymentPracticesLiability: {
                type: 'text',
                validator: coverageOptionSchema.required(),
            },
            espFiduciary: {
                type: 'text',
                validator: Joi.object({
                    selected: Joi.boolean().required(),
                }).required(),
            },
            espTechnology: {
                type: 'text',
                validator: coverageOptionSchema.optional(),
            },
            espCyber: {
                type: 'text',
                validator: coverageOptionSchema.optional(),
            },
            espPartnerCode: {
                type: 'text',
                validator: Joi.string().allow('').required(),
            },
            espPolicyFee: {
                type: 'currency',
                validator: Joi.number().allow(null).required(),
            },
            espSelected: {
                type: 'checkbox',
                validator: Joi.boolean(),
            },
            espIsPolicyFeeTaxable: {
                type: 'checkbox',
                validator: Joi.boolean(),
            },
        };
    },
    getProductActions(
        bundleQuote: BundleQuote,
        abortSignal: AbortSignal,
    ): Record<
        FormAction,
        | {
              action: ActionHandler<ESPQuoteOptions>;
              fields: (keyof distributedPrefix<ESPQuoteOptions>)[];
          }
        | ActionHandler<ProductFormData<ESPQuoteOptions>>
    > {
        const formFields = this.getProductFields(bundleQuote);
        return {
            generateESPQuoteDocument: {
                action: async () => {
                    return getDocumentUrl(
                        bundleQuote,
                        BundleCoverageTypeEnum.ESPCoverage,
                        DocumentType.QuoteDocument,
                        abortSignal,
                    );
                },
                fields: ['effectiveDate', ...keysOf(formFields)],
            },
        };
    },
    getCoverageDocuments({
        isDirty,
        documents,
        trigger,
        bundleQuote,
    }: BuildBundleDocumentListProps): DocumentsItem[] | undefined {
        const getFileUrl = ({
            coverageType,
            level,
        }: {
            coverageType: 'do' | 'epli' | 'fiduciary' | 'eoCyber' | 'techCyber';
            level: 'standard' | 'plus' | undefined;
        }) => {
            switch (coverageType) {
                case 'do':
                case 'epli':
                case 'techCyber':
                    return espCoverageDetails[coverageType].document[
                        level === 'standard' ? 'post_ipt_2024_standard' : 'post_ipt_2024_plus'
                    ] as URI;
                case 'eoCyber':
                    return espCoverageDetails.eoCyber.document[
                        level === 'standard' ? 'standard' : 'plus'
                    ] as URI;
                case 'fiduciary':
                    return espCoverageDetails.fiduciary.document.new_standard as URI;
                default:
                    break;
            }
        };

        const shouldIncludeESPDocs =
            !bundleQuote.isCoverageReferred(BundleCoverageTypeEnum.ESPCoverage) &&
            bundleQuote.isCoverageEnabled(BundleCoverageTypeEnum.ESPCoverage);

        if (!shouldIncludeESPDocs) {
            return undefined;
        }
        const ESPQuoteDocument: DocumentsItem = {
            component: GenerateDocumentButton,
            isDisabled: isDirty,
            fileUrl: documents.find(
                (document) =>
                    document.documentType === DocumentType.QuoteDocument &&
                    document.coverageType === BundleCoverageTypeEnum.ESPCoverage,
            )?.url,
            handleTrigger: () => trigger('generateESPQuoteDocument'),
            displayName: generateDocumentDisplayName({
                documentType: DocumentType.QuoteDocument,
                bundleCoverageType: BundleCoverageTypeEnum.ESPCoverage,
            }),
        };
        const coverages = (
            bundleQuote.coverageList.find((coverage) => coverage.type === 'ESPCoverage')?.quote as
                | ESPQuote
                | undefined
        )?.details.coverages;

        const ESPGeneralTerms: DocumentsItem = {
            component: GenerateDocumentButton,
            isDisabled: isDirty,
            fileUrl: (coverages
                ?.filter((coverage) => coverage.level)
                .every((coverage) => coverage.level === 'standard')
                ? ESPGeneralTermsAndConditionsStandardPostIPT2024URL
                : ESPGeneralTermsAndConditionsPlusPostIPT2024URL) as URI | undefined,
            handleTrigger: () => trigger('downloadESPGeneralTerms'),
            displayName: `General Terms ${DocumentType.toDisplayName(DocumentType.SpecimenPolicy)}`,
        };

        const coveragesDocuments: DocumentsItem[] = coverages
            ? coverages
                  .filter((coverage) => coverage.selected)
                  .map((coverage) => ({
                      component: GenerateDocumentButton,
                      isDisabled: isDirty,
                      fileUrl: getFileUrl({
                          coverageType: coverage.coverageType,
                          level: coverage.level,
                      }),
                      documentType: DocumentType.SpecimenPolicy,
                      handleTrigger: () => trigger(`generate${coverage.coverageType}Document`),
                      coverageType: BundleCoverageTypeEnum.ESPCoverage,
                      displayName: `${
                          espCoverageDetails[coverage.coverageType].title
                      } ${DocumentType.toDisplayName(DocumentType.SpecimenPolicy)}`,
                  }))
            : [];

        return [ESPQuoteDocument, ...coveragesDocuments, ESPGeneralTerms];
    },
    getInitialValues(bundleQuote): Record<string, unknown> {
        const epsCoverage = bundleQuote.coverageList.find(
            (coverage) => coverage.type === this.type,
        );

        if (
            epsCoverage === undefined ||
            epsCoverage.quote === undefined ||
            !this.isOptionsValidType(epsCoverage.quote.options)
        ) {
            return {};
        }

        const { options } = epsCoverage.quote;
        return {
            espSelected: true,
            espIsPolicyFeeTaxable: false,
            espDirectorsAndOfficers: options.directorsAndOfficers,
            espEmploymentPracticesLiability: options.employmentPracticesLiability,
            espFiduciary: options.fiduciary,
            espTechnology: options.technology,
            espCyber: options.cyber,
            espPartnerCode: options.partnerCode,
            espPolicyFee: options.policyFee?.amount ?? 0,
        };
    },
    toggleSelected(value: boolean): Record<string, unknown> {
        return { espSelected: value };
    },
    getMaxFutureDaysAllowed(): number {
        return 90;
    },
    async getDocumentDownloadMetadata(
        documentType: DocumentType,
        espQuote?: ESPQuote,
    ): AsyncResult<GetDocumentDownloadMetadataResponse, OperationFailed | InvalidArgument> {
        let downloadUrlResult;
        switch (documentType) {
            case DocumentType.QuoteDocument:
                if (espQuote?.fileKey === undefined) {
                    return Failure(InvalidArgument({ argument: 'fileKey', value: documentType }));
                }
                downloadUrlResult = await execute(GetDocumentUrl, {
                    fileKey: espQuote.fileKey,
                });
                if (isErr(downloadUrlResult)) {
                    return downloadUrlResult;
                }
                return Success({
                    fileKey: espQuote.fileKey,
                    downloadUrl: downloadUrlResult.value.downloadUrl,
                });
            default:
                return Failure(InvalidArgument({ argument: 'fileKey', value: documentType }));
        }
    },
    onSuccessHandler(
        value: any,
        action: string,
        setDocumentUrl: (
            url: URI,
            documentType: DocumentType,
            bundleCoverageType: BundleCoverageType,
        ) => void,
        onGenerateDocument: () => void,
    ) {
        switch (action) {
            case 'generateESPQuoteDocument':
                setDocumentUrl(
                    value.fileUrl,
                    DocumentType.QuoteDocument,
                    BundleCoverageTypeEnum.ESPCoverage,
                );
                onGenerateDocument();
        }
    },
    isLimitHigherThenAllowed(
        formValue: distributedPrefix<ESPQuoteOptions>,
        _coverageSelected,
        _questionnaire,
        _higherLimit,
        bundleQuote,
    ) {
        if (!bundleQuote?.coverageList) return false;

        const quote =
            bundleQuote?.coverageList?.length > 0 ? bundleQuote?.coverageList[0].quote : null;

        const options = quote?.options as ESPQuoteOptions;

        const isCyberLimitDirty = formValue.espCyber?.limit !== options?.cyber?.limit;
        const isCyberLimitHigherThenAllowed =
            formValue.espCyber?.selected &&
            formValue.espCyber?.limit >= 4_000_000 &&
            isCyberLimitDirty;

        const isTechLimitDirty = formValue.espTechnology?.limit !== options?.technology?.limit;
        const isTechLimitHigherThenAllowed =
            formValue.espTechnology?.selected &&
            formValue.espTechnology?.limit >= 4_000_000 &&
            isTechLimitDirty;

        const isDirectorsAndOfficersLimitDirty =
            formValue.espDirectorsAndOfficers?.limit !== options?.directorsAndOfficers?.limit;
        const isDirectorsAndOfficersLimitHigherThenAllowed =
            formValue.espDirectorsAndOfficers?.selected &&
            formValue.espDirectorsAndOfficers?.limit >= 4_000_000 &&
            isDirectorsAndOfficersLimitDirty;

        const isEPLILimitDirty =
            formValue.espEmploymentPracticesLiability?.limit !==
            options?.employmentPracticesLiability?.limit;
        const isEPLILimitHigherThenAllowed =
            formValue.espEmploymentPracticesLiability?.selected &&
            formValue.espEmploymentPracticesLiability?.limit >= 4_000_000 &&
            isEPLILimitDirty;

        return (
            isCyberLimitHigherThenAllowed ||
            isTechLimitHigherThenAllowed ||
            isDirectorsAndOfficersLimitHigherThenAllowed ||
            isEPLILimitHigherThenAllowed
        );
    },
    getPremiumRange() {
        return undefined;
    },
};
