import { defineEntityValidator, entity } from '@embroker/shotwell/core/entity/Entity';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { Data } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { SKU } from '../../../analytics/types/SKU';
import { commonQuoteProps, Quote, QuoteDetails, QuoteOptions } from '../../entities/Quote';
import { Coverage } from '../types/Coverage';
import { UserInfo } from '../types/UserInfo';

export interface ESPRenewalQuoteOptions extends QuoteOptions {
    readonly coverage: Data<Coverage>;
}

export const ESPRenewalQuoteOptions = {
    ...defineValidator<ESPRenewalQuoteOptions>({
        effectiveDate: Joi.date(),
        coverage: Joi.object().pattern(/\w/, Coverage.schema),
    }),
};

export interface ESPRenewalQuoteDetails extends QuoteDetails {
    readonly fees: Money;
    readonly taxes: Money;
    readonly policyFee: Money;
    readonly totalCoverages: Money;
    readonly subtotal: Money;
    readonly renewalDiscount: Money;
    readonly mwuaFee?: Money;
}

export const ESPRenewalQuoteDetails = {
    ...defineValidator<ESPRenewalQuoteDetails>({
        fees: Money.schema,
        taxes: Money.schema,
        policyFee: Money.schema,
        totalCoverages: Money.schema.required(),
        subtotal: Money.schema.required(),
        renewalDiscount: Money.schema.required(),
        mwuaFee: Money.schema.optional(),
    }),
};

export interface ESPRenewalQuote extends Quote {
    readonly options: ESPRenewalQuoteOptions;
    readonly details: ESPRenewalQuoteDetails;
    readonly userInfo?: UserInfo;
    readonly previousPolicyEndDate: Date;
    readonly submittedAt?: Date;
    readonly newInsurerDocumentsReleaseDate?: Date;
    readonly fiduciaryDocumentReleaseDate?: Date;
    readonly espDocumentsAfterEoCyberSplitReleaseDate?: Date;
    /**
     * Revenue info
     * represents the sum of gross_revenue_domestic and gross_revenue_foreign
     * extracted from questionnaireData
     */
    readonly revenue: Money;
    referralReasons?: readonly string[];
}

export const ESPRenewalQuote = entity<ESPRenewalQuote, Quote>({
    validator: defineEntityValidator<ESPRenewalQuote>({
        ...commonQuoteProps,
        options: ESPRenewalQuoteOptions.schema,
        details: ESPRenewalQuoteDetails.schema,
        userInfo: UserInfo.schema.optional(),
        previousPolicyEndDate: Joi.date(),
        revenue: Money.schema,
        submittedAt: Joi.date().optional(),
        newInsurerDocumentsReleaseDate: Joi.date().optional(),
        fiduciaryDocumentReleaseDate: Joi.date().optional(),
        espDocumentsAfterEoCyberSplitReleaseDate: Joi.date().optional(),
        referralReasons: Joi.array().items(Joi.string()).optional(),
    }),
    inherits: Quote,
});

export interface ESPRenewalQuotePurchased extends DomainEvent {
    readonly origin: 'ESPRenewalQuote';
    readonly name: 'Purchased';
    readonly totalPremium: Money;
    readonly applicationId: UUID;
    sku?: SKU;
}
