import { StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';

export function CyberFooter() {
    return (
        <StackLayout gap="none">
            <Text color="ui-400" style="body 2" data-e2e="cyber-carrier">
                Cyber Carrier: Clear Blue Specialty Insurance Company/Clear Blue Insurance Company
            </Text>
            <Text color="ui-500" style="body 2" data-e2e="cyber-carrier-description">
                Clear Blue Specialty Insurance Company and Clear Blue Insurance Company are part of
                Clear Blue Insurance Group, a leading property and casualty insurance company.
                Currently rated AM Best A- (Excellent), IX.
            </Text>
        </StackLayout>
    );
}
