import { inject } from '@embroker/shotwell/core/di';
import { JSONSerdes } from '@embroker/shotwell/core/encoding';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { AsyncResult, Failure, isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { CalculateSKU } from '../../analytics/useCases/CalculateSKU';
import { ApplicationCreated } from '../../shopping/entities/Application';
import {
    ApplicationNotFound,
    AppTypeNotAllowedForBroker,
    NameClearanceError,
    OperationNotAllowed,
} from '../../shopping/errors';
import { ApplicationRepository } from '../../shopping/repositories/ApplicationRepository';
import { FilledQuestionnaireData } from '../../shopping/types/QuestionnaireData';

export interface PromoteApplicationRequest {
    applicationId: UUID;
    questionnaireData: FilledQuestionnaireData;
}

export interface PromoteApplication extends UseCase {
    execute(
        request: PromoteApplicationRequest,
    ): AsyncResult<
        void,
        | InvalidArgument
        | OperationFailed
        | ApplicationNotFound
        | OperationNotAllowed
        | NameClearanceError
        | AppTypeNotAllowedForBroker
    >;
}

class PromoteApplicationUseCase extends UseCase implements PromoteApplication {
    public static type = Symbol('Shopping/PromoteApplication');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
        @inject(CalculateSKU.type) private calculateSKU: CalculateSKU,
        @inject(Log) private logger: Logger,
    ) {
        super(eventBus);
    }
    public async execute({
        applicationId,
        questionnaireData,
    }: PromoteApplicationRequest): AsyncResult<
        void,
        | InvalidArgument
        | OperationFailed
        | ApplicationNotFound
        | OperationNotAllowed
        | NameClearanceError
        | AppTypeNotAllowedForBroker
    > {
        const questionnaireDataResult = JSONSerdes.serialize(questionnaireData);
        if (isErr(questionnaireDataResult)) {
            return Failure(
                InvalidArgument({ argument: 'questionnaireData', value: questionnaireData }),
            );
        }

        const promoteApplicationResult = await this.applicationRepository.promoteApplication(
            applicationId,
            questionnaireDataResult.value,
        );

        if (isOK(promoteApplicationResult)) {
            await this.publishApplicationCreatedEvent(applicationId);
        }

        return promoteApplicationResult;
    }
    private async publishApplicationCreatedEvent(applicationId: UUID) {
        const applicationResult = await this.applicationRepository.getApplication(applicationId);
        if (isErr(applicationResult)) {
            this.logger.error(applicationResult);
            return;
        }

        const application = applicationResult.value;
        const skuResult = await this.calculateSKU.execute({
            appType: application.appType,
            event: 'app_created',
            questionnaireData: application.questionnaireData ?? undefined,
            shoppingCoverageList: application.shoppingCoverageList,
            applicationId: applicationId,
        });

        const eventData: ApplicationCreated = {
            origin: 'Application',
            name: 'ApplicationCreated',
            id: application.id,
            isRenewal: application.isRenewal(),
            sku: skuResult.value,
            createdAt: new Date(Date.now()),
        };
        await this.eventBus.publish(eventData);
    }
}

export const PromoteApplication: UseCaseClass<PromoteApplication> = PromoteApplicationUseCase;
