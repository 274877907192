import React, { useCallback } from 'react';
import {
    BoxLayout,
    Button,
    ButtonBar,
    ColumnLayout,
    Image,
    StackLayout,
    Text,
    WizardLayout,
} from '@embroker/ui-toolkit/v2';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { URI } from '@embroker/shotwell/core/types/URI';

interface StreamlineRenewalIneligibleInterface {
    applicationId: UUID;
}

export function StreamlineRenewalIneligiblePage({
    applicationId,
}: StreamlineRenewalIneligibleInterface) {
    const { navigate } = useNavigation();

    const handleStartApplication = useCallback(async () => {
        navigate(
            URI.build('/shopping/application', {
                applicationId,
            }),
        );
    }, [applicationId, navigate]);

    return (
        <WizardLayout>
            <WizardLayout.Cover>
                <ColumnLayout
                    gap="48"
                    split="-1"
                    responsive={{ containerWidth: { smallerThan: 'tablet' } }}
                >
                    <BoxLayout className="u-1/2@large-tablet u-1/1">
                        <StackLayout gap="32">
                            <Text style="heading 1">
                                Looks like some of your business details have changed.
                            </Text>
                            <StackLayout>
                                <Text style="body 1">
                                    Made some changes to your practice? No problem — we’ve
                                    streamlined the renewal process to ensure you get the most
                                    accurate quote, fast. Simply review your pre-filled application,
                                    update any information accordingly, and submit for our review.
                                </Text>
                            </StackLayout>
                            <ButtonBar responsive={{ containerWidth: { smallerThan: 'mobile' } }}>
                                <Button
                                    onClick={handleStartApplication}
                                    appearance="primary"
                                    type="submit"
                                >
                                    Start the Application
                                </Button>
                                <Button
                                    onClick={() => navigate('/summary')}
                                    appearance="ghost"
                                    type="submit"
                                >
                                    Back to Dashboard
                                </Button>
                            </ButtonBar>
                        </StackLayout>
                    </BoxLayout>
                    {/*@TODO change image*/}
                    <BoxLayout className="u-1/2@large-tablet u-1/1">
                        <Image width="100%" name="illustration-woman-laptop" />
                    </BoxLayout>
                </ColumnLayout>
            </WizardLayout.Cover>
        </WizardLayout>
    );
}
