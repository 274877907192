import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ExcessQuote } from '../../entities/ExcessQuote';
import { ExcessQuoteOptions } from '../../types/ExcessQuoteOptions';

export interface ExcessQuoteRepository {
    getLastExcessQuote(
        applicationId: UUID,
    ): AsyncResult<ExcessQuote, InvalidArgument | OperationFailed>;

    reQuoteExcess(
        applicationId: UUID,
        excessQuoteOptions: ExcessQuoteOptions,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed>;

    createQuoteSummaryAsyncTask(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed>;

    createSpecimenPolicyAsyncTask(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed>;

    submitForReview(
        applicationId: UUID,
        supplementalQuestionnaireData: string,
    ): AsyncResult<void, InvalidArgument | OperationFailed>;
}

export const ExcessQuoteRepository = Symbol('ExcessQuoteRepository');
