import { Immutable } from '@embroker/shotwell/core/types';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { GridLayout, Nullable, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { DisplayPolicy } from '../../types/DisplayPolicy';
import { PolicyTile } from './PolicyTile';
import { ProcessingPolicy } from './ProcessingPolicy';

interface PolicyListProps {
    title?: Nullable<string>;
    policyList: Immutable<DisplayPolicy[]>;
    unprocessedPolicyCount?: number;
    showActionButtons: boolean;
}

export const PolicyList = (props: PolicyListProps) => {
    let totalProcessingPolicyCount = 0;
    if (props.unprocessedPolicyCount) {
        totalProcessingPolicyCount += props.unprocessedPolicyCount;
    }

    const embrokerProcessingPolicies: string[] = [];
    const policies = props.policyList
        .map((item) => {
            if (item.viewMode === 'PolicyViewStatusCodeListDraft') {
                embrokerProcessingPolicies.push(item.displayName);
            } else {
                return (
                    <PolicyTile
                        key={item.id}
                        policyData={item}
                        showActionButtons={props.showActionButtons}
                    />
                );
            }
        })
        .filter((item) => {
            return item !== undefined;
        });

    for (let index = 0; index < totalProcessingPolicyCount; index++) {
        const key = UUID.create();
        policies.push(<ProcessingPolicy key={key} />);
    }
    for (let index = 0; index < embrokerProcessingPolicies.length; index++) {
        const key = UUID.create();
        policies.push(
            <ProcessingPolicy key={key} policyName={embrokerProcessingPolicies[index]} />,
        );
    }

    return (
        <React.Fragment>
            {props.title ? <Text style="heading 4">{props.title}</Text> : null}
            <GridLayout>{policies}</GridLayout>
        </React.Fragment>
    );
};
