import React from 'react';
import { Text } from '@embroker/ui-toolkit/v2';
import { Link } from '../../../view/components';

export function PrivacyMessage() {
    return (
        <Text style="microcopy">
            Your privacy is very important to us.&nbsp;
            <Link
                data-e2e="user-org-sign-in-page-privacy-link"
                href="https://www.embroker.com/privacy"
                target="_blank"
            >
                Learn more
            </Link>
        </Text>
    );
}
