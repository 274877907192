import { inject, injectable } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import {
    InvalidArgument,
    NotAllowed,
    OperationFailed,
    UnknownEntity,
} from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { UserInvite } from '../entities/UserInvite';
import { UserInviteRepository } from '../repositories/UserInviteRepository';

/**
 * AcceptUserInvite Use Case Request model.
 */
export interface AcceptUserInviteRequest {
    /**
     * Identifier of the UserInvite to accept.
     */
    readonly inviteToken: UUID;
}
/**
 * AcceptUserInvite Use Case Response model.
 */
export interface AcceptUserInviteResponse {
    /**
     * Identifier of the accepted UserInvite.
     */
    readonly userInviteId: UUID;
    /**
     * Identifier of the User this UserInvite is accepted by.
     */
    readonly inviteToken: UUID;
    /**
     * New status of this UserInvite (always 'accepted').
     */
    readonly status: 'accepted';
}

/**
 * AcceptUserInvite Use Case accepts a user invite to the platform.
 */

export interface AcceptUserInvite extends UseCase {
    execute(
        request: AcceptUserInviteRequest,
    ): AsyncResult<
        AcceptUserInviteResponse,
        UnknownEntity | InvalidArgument | NotAllowed | OperationFailed
    >;
}
@injectable()
class AcceptUserInviteUseCase extends UseCase implements AcceptUserInvite {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/AcceptUserInvite');
    /**
     * Constructor for UpdateOrganizationProfile use case class instance.
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param userInviteRepository UserInvite repository used to fetch/store UserInvite entities.
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(UserInviteRepository) private readonly userInviteRepository: UserInviteRepository,
    ) {
        super(eventBus);
    }
    /**
     * Executes AcceptUserInvite Use Case.
     *
     * @returns UnknownEntity error if user invite for inviteToken does not exist on the platform
     * @returns InvalidArgument error if updated user invite entity contains invalid property
     * @returns NotAllowed error if the user invite has already been rejected
     * @returns OperationFailed error if repo request failed
     */
    public async execute(
        request: AcceptUserInviteRequest,
    ): AsyncResult<
        AcceptUserInviteResponse,
        UnknownEntity | InvalidArgument | NotAllowed | OperationFailed
    > {
        const userInviteData: EntityProps<UserInvite> = {
            id: request.inviteToken,
            status: 'unset',
            email: null,
            organizationName: null,
            inviteEnabled: true,
            isOrganizationOwner: false,
        };

        const invite = await UserInvite.create(userInviteData);

        if (isErr(invite)) {
            return handleOperationFailure(invite);
        }

        const acceptResult = invite.value.accept();

        if (isErr(acceptResult)) {
            return handleOperationFailure(acceptResult);
        }

        const userInviteResult = await this.userInviteRepository.save(invite.value);

        if (isErr(userInviteResult)) {
            return handleOperationFailure(userInviteResult);
        }

        this.eventBus.publishEntityEvents(userInviteResult.value);

        return Success<AcceptUserInviteResponse>({
            userInviteId: userInviteResult.value.id,
            status: 'accepted',
            inviteToken: request.inviteToken,
        });
    }
}

export const AcceptUserInvite: UseCaseClass<AcceptUserInvite> = AcceptUserInviteUseCase;
