import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';

export interface InsuranceApplicationRepository {
    loadQuotableCoverages(
        insuranceApplicationId: UUID,
    ): AsyncResult<string[], InvalidArgument | OperationFailed>;
}

export const InsuranceApplicationRepository = Symbol('InsuranceApplicationRepository');
