import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ServerEnumsRepository } from '../repositories/ServerEnumsRepository';
import { ServerEnumGroup } from '../types/ServerEnumGroup';

/**
 * Response data for GetServerEnums use case
 */
export interface GetServerEnumsResponse {
    enums: ServerEnumGroup;
}

/**
 * GetServerEnums use case is used to ...
 */
export interface GetServerEnums extends UseCase {
    execute(): AsyncResult<GetServerEnumsResponse, InvalidArgument | OperationFailed>;
}

@injectable()
class GetServerEnumsUseCase extends UseCase implements GetServerEnums {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('serverEnums/GetServerEnums');

    /**
     * Constructor for GetServerEnums use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ServerEnumsRepository) private serverEnumsRepository: ServerEnumsRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes GetServerEnums use case
     * Input is of GetServerEnumsRequest type
     * @returns data of GetServerEnumsResponse type if execution was successful
     */
    public async execute(): AsyncResult<GetServerEnumsResponse, InvalidArgument | OperationFailed> {
        const result = await this.serverEnumsRepository.getEnums();

        if (isErr(result)) {
            return result;
        }

        return Success({
            enums: result.value,
        });
    }
}

export const GetServerEnums: UseCaseClass<GetServerEnums> = GetServerEnumsUseCase;
