import { isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Spinner } from '@embroker/ui-toolkit/v2';
import React, { useContext } from 'react';
import { AppContext } from '../../../../view/AppContext';
import { SelfServingCertificateCoverage } from '../../../types/SelfServingCertificateCoverage';
import {
    GetShareableCertificateData,
    GetShareableCertificateDataRequest,
    GetShareableCertificateDataResponse,
} from '../../../useCases/GetShareableCertificateData';
import { GetShareableSelfServingCoverageList } from '../../../useCases/GetShareableSelfServingCoverageList';
import {
    SendEnterSelfServingFlow,
    SendEnterSelfServingFlowRequest,
} from '../../../useCases/SendEnterSelfSevingFlowEvent';
import { SelfServingFlow } from './SelfServingFlow';

export function SelfServingFlowWrapper() {
    const { activeSession } = useContext(AppContext);

    const { result: sendEnterSelfServingFlowResult, isLoading: sendEnterSelfServingFlowIsLoading } =
        useUseCase(SendEnterSelfServingFlow, {
            organizationId: activeSession.organizationId,
        } as SendEnterSelfServingFlowRequest);

    const { result: shareableCertificateDataResult, isLoading: shareableDataIsLoading } =
        useUseCase(GetShareableCertificateData, {
            organizationId: activeSession.organizationId,
        } as GetShareableCertificateDataRequest);

    const { result: selfServingCoverageListResult, isLoading: selfServingCoverageListIsLoading } =
        useUseCase(GetShareableSelfServingCoverageList);

    if (
        shareableCertificateDataResult === undefined ||
        selfServingCoverageListResult === undefined ||
        sendEnterSelfServingFlowResult === undefined ||
        selfServingCoverageListIsLoading ||
        shareableDataIsLoading ||
        sendEnterSelfServingFlowIsLoading
    ) {
        return <Spinner />;
    }

    if (isErr(shareableCertificateDataResult)) {
        return <ErrorPage errors={shareableCertificateDataResult.errors} />;
    }

    if (isErr(selfServingCoverageListResult)) {
        return <ErrorPage errors={selfServingCoverageListResult.errors} />;
    }

    const shareableData =
        shareableCertificateDataResult.value as GetShareableCertificateDataResponse;

    const coverageMap = new Map<UUID, SelfServingCertificateCoverage>();

    for (const coverage of selfServingCoverageListResult.value.selfServingCoverageList) {
        coverageMap.set(UUID.create(), coverage);
    }

    return (
        <SelfServingFlow
            coverageMap={coverageMap}
            certificateOwner={shareableData.shareableCertificateData.certificateOwner}
            certificateProducer={shareableData.shareableCertificateData.certificateProducer}
        />
    );
}
