import { isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { map, route, withData, redirect } from 'navi';
import React from 'react';
import { GetInitialCNAQuote } from '../../bopCna/useCases/GetInitialCNAQuote';
import { CNAQuoteLandingPage } from '../../bopCna/view/components/CNAQuoteLandingPage';
import { URI } from '@embroker/shotwell/core/types/URI';

const cnaWizardRouteHandler = map(async (request) => {
    const { applicationId } = request.query;
    if (!UUID.check(applicationId)) {
        return route({ view: <ErrorPage /> });
    }

    const getQuoteResult = await execute(GetInitialCNAQuote, { applicationId });

    if (isErr(getQuoteResult)) {
        return route({ view: <ErrorPage errors={getQuoteResult.errors} /> });
    }

    if (getQuoteResult.value.cnaQuote.isQuoteReferred) {
        redirect(
            URI.build('/shopping/application/cna-kick-out/', {
                applicationId,
            }),
        );
    }

    return route({
        title: 'BOP quotes',
        view: (
            <CNAQuoteLandingPage
                cnaQuote={getQuoteResult.value.cnaQuote}
                applicationId={applicationId}
            />
        ),
    });
});

export const bopCnaRoutes = {
    '/': map((request) =>
        withData(
            { url: request.mountpath, page: undefined, fullscreen: true },
            cnaWizardRouteHandler,
        ),
    ),

    '/:page': map((request) =>
        withData(
            { url: request.mountpath, page: request.params.page, fullscreen: true },
            cnaWizardRouteHandler,
        ),
    ),
};
