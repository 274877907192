import { ContainerModule } from '@embroker/shotwell/core/di';
import { Module } from '../../Module';
import { EndorsementIntakeRepository } from './repositories/EndorsementIntakeRepository';
import { APIEndorsementIntakeRepository } from './repositories/EndorsementIntakeRepository/APIEndorsementIntakeRepository';
import { CreateOtherEndorsement } from './useCases/CreateOtherEndorsement';
import { RequestNameOrAddressChangeEndorsement } from './useCases/RequestNameOrAddressChangeEndorsement';

export const EndorsementIntakeModule: Module = {
    container: new ContainerModule((bind) => {
        bind<EndorsementIntakeRepository>(EndorsementIntakeRepository)
            .to(APIEndorsementIntakeRepository)
            .inSingletonScope();
        bind<RequestNameOrAddressChangeEndorsement>(RequestNameOrAddressChangeEndorsement.type)
            .to(RequestNameOrAddressChangeEndorsement)
            .inSingletonScope();
        bind<CreateOtherEndorsement>(CreateOtherEndorsement.type)
            .to(CreateOtherEndorsement)
            .inSingletonScope();
    }),
};
