import { Money } from '@embroker/shotwell/core/types/Money';
import { Text, Icon, StackLayout, Card, SelectInput, Immutable } from '@embroker/ui-toolkit/v2';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import React, { useState, useMemo } from 'react';
import { calculateTotalUnderlyingLimit, CarrierLayer } from '../../types/CarrierLayer';
import { EmbrokerTowerCard } from './EmbrokerTowerCard';
import { PrimaryTowerCard } from './PrimaryTowerCard';
import { TowerCard } from './TowerCard';
import { ExcessLimitOptions } from '../../types/ExcessQuoteOptions';

export function ordinal(number: number) {
    const englishOrdinalRules = new Intl.PluralRules('en', { type: 'ordinal' });
    const suffixes = new Map<Intl.LDMLPluralRule, string>();
    suffixes.set('one', 'st');
    suffixes.set('two', 'nd');
    suffixes.set('few', 'rd');
    suffixes.set('other', 'th');
    const category = englishOrdinalRules.select(number);
    const suffix = suffixes.get(category);
    if (suffix) {
        return number + suffix;
    }
    return '' + number;
}

export interface TowerStructureProps {
    limit: number;
    carrierLayers: CarrierLayer[];
    primaryCarrierLayer: CarrierLayer;
    premium: Money;
    className: string;
    handleChangeLimit: (newLimit: number) => void;
    handleExit: () => void;
    maxLimit: number;
}
export const TowerStructure = function TowerStructure({
    limit,
    carrierLayers,
    primaryCarrierLayer,
    premium,
    className,
    handleChangeLimit,
    handleExit,
    maxLimit,
}: TowerStructureProps) {
    const limitOptions: Immutable<{ value: number; title: string }>[] = useMemo(
        () =>
            ExcessLimitOptions.filter((limit) => {
                return limit <= maxLimit;
            })
                .map((x) => {
                    return Money.tryFromFloat(x);
                })
                .map(Money.toOption),
        [maxLimit],
    );

    const initiallySelectedLimit =
        limitOptions.find((x: Immutable<{ value: number; title: string }>) => x.value == limit) ??
        limitOptions[0];

    const totalUnderlyingLimit = calculateTotalUnderlyingLimit(carrierLayers, primaryCarrierLayer);

    const [selectedLimit, setSelectedLimit] = useState(initiallySelectedLimit);

    const handleLimitChange = (event: { target: { value: number } }) => {
        const limitValue = event.target.value;
        const newlySelectedLimit = limitOptions.find(
            (x: Immutable<{ value: number; title: string }>) => x.value == limitValue,
        );
        if (newlySelectedLimit) {
            setSelectedLimit(newlySelectedLimit);
            handleChangeLimit(limitValue);
        }
    };
    return (
        <Card size="small" onDismiss={handleExit} className={className} data-e2e="excess-card">
            <Card.Header data-e2e="excess-card-header">
                <Icon size="large" name="excess" />
                <Text style="heading 5" as="span">
                    Excess Tech E&O/Cyber
                </Text>
            </Card.Header>
            <Card.Body data-e2e="excess-card-body">
                <StackLayout gap="16">
                    <Text style="body 2">
                        Embroker will offer excess coverage up to the aggregate limit. Any sublimits
                        provided on the followed policy will be matched, subject to the aggregate
                        limit.
                    </Text>
                    <StackLayout gap="4">
                        <SelectInput
                            value={selectedLimit.value}
                            onChange={handleLimitChange}
                            items={limitOptions}
                            label="Limit"
                            data-e2e="excess-limit"
                        />
                        <Text style="microcopy">
                            Excess of{' '}
                            <MoneyDisplay
                                value={Money.tryFromFloat(totalUnderlyingLimit)}
                                fractionDigits={0}
                            />
                        </Text>
                    </StackLayout>
                    <Text style="heading 5" data-e2e="excess-tower-structure">
                        Excess Tower Structure
                    </Text>
                    <EmbrokerTowerCard limit={limit} layersCount={carrierLayers.length} />
                    {carrierLayers &&
                        carrierLayers
                            .slice(0)
                            .sort(
                                (c1: CarrierLayer, c2: CarrierLayer) =>
                                    -(c1.layer_number - c2.layer_number),
                            )
                            .map((item: CarrierLayer, index: number) => (
                                <TowerCard key={index} carrierLayer={item} />
                            ))}
                    <PrimaryTowerCard
                        key={carrierLayers.length}
                        carrierLayer={primaryCarrierLayer}
                    ></PrimaryTowerCard>
                    <Text style="body 1" data-e2e="premium">
                        Premium:{' '}
                        <b>
                            {' '}
                            <MoneyDisplay value={premium} fractionDigits={2} />
                        </b>
                    </Text>
                </StackLayout>
            </Card.Body>
        </Card>
    );
};
