import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { LPLQuoteRepository } from '../repositories/LPLQuoteRepository';
import { LPLQuoteExtended } from '../types/LPLQuoteExtended';

interface GetLastLPLQuoteRequest {
    applicationId: UUID;
}

export interface GetLastLPLQuote extends UseCase {
    execute(
        request: GetLastLPLQuoteRequest,
    ): AsyncResult<LPLQuoteExtended, InvalidArgument | OperationFailed>;
}

@injectable()
class GetLastLPLQuoteUseCase extends UseCase implements GetLastLPLQuote {
    public static type = Symbol('LPLQuote/GetLastLPLQuote');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(LPLQuoteRepository) private lplQuoteRepository: LPLQuoteRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
    }: GetLastLPLQuoteRequest): AsyncResult<LPLQuoteExtended, InvalidArgument | OperationFailed> {
        return await this.lplQuoteRepository.getLastLPLQuote(applicationId);
    }
}

export const GetLastLPLQuote: UseCaseClass<GetLastLPLQuote> = GetLastLPLQuoteUseCase;
