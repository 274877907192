import { container } from '@embroker/shotwell/core/di';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { ErrorLike, Failure, isOK, Success } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { createForm, OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { startOfToday } from 'date-fns/esm';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CalculateSKU } from '../../../../analytics/useCases/CalculateSKU';
import { QuotingEngineWCGA } from '../../../../shopping/types/enums';
import { PurchasedAppType } from '../../../../summary/types/PurchasedAppType';
import { hasRole } from '../../../../userOrg/entities/Session';
import { AppContext } from '../../../../view/AppContext';
import { NavigateFunction, useNavigation } from '../../../../view/hooks/useNavigation';
import { useWizardForm, WizardPages } from '../../../../view/hooks/useWizardForm';
import { QuoteBindCoverage } from '../../../entities/Quote';
import { SignQuote } from '../../../useCases/SignQuote';
import { UnsignQuote } from '../../../useCases/UnsignQuote';
import { validateEffectiveDate } from '../../../view/components/formValidators';
import {
    Page,
    PageNavigationComponent,
    QuoteLandingPage,
} from '../../../view/components/QuoteLandingPage';
import { ReferredQuoteOnDemandNavigation } from '../../../view/components/ReferredQuoteOnDemandNavigation';
import { WCGAQuote } from '../../entities/WCGAQuote';
import { GetLastWCGAQuote } from '../../useCases/GetLastWCGAQuote';
import { GetWCGAQuote } from '../../useCases/GetWCGAQuote';
import { PurchaseWCGAQuote } from '../../useCases/PurchaseWCGAQuote';
import { QuoteSignature } from './signature/QuoteSignature';
import { WCGACoverage } from './WCGACoverage';
import { WCGAQuoteBreakdown } from './WCGAQuoteBreakdown';

export interface WCGAQuoteOptionsFormData {
    applicationId: UUID;
    startDate: Date;
    includeBlanketWoS: boolean;
    recommendedWoS: boolean;
    agreementToConductSignature: boolean;
    warrantyAndFraudSignature: boolean;
}

const MAX_FUTURE_DAYS_ALLOWED = 90;

const makeTitle = (quote: WCGAQuote | undefined): string => {
    const defaultTitle = 'Workers Compensation';

    if (!quote) {
        return '';
    }

    return defaultTitle;
};

const createWCGAQuoteOptionsForm = (
    quote: WCGAQuote | undefined,
    setQuote: (quote: WCGAQuote) => void,
    navigate: NavigateFunction,
    isAdmin: boolean,
    abortSignal: AbortSignal,
) => {
    return createForm<WCGAQuoteOptionsFormData>({
        fields: {
            startDate: {
                type: 'date',
                validator: Joi.date()
                    .custom((value, helpers) => {
                        return validateEffectiveDate(
                            value,
                            startOfToday(),
                            MAX_FUTURE_DAYS_ALLOWED,
                            helpers,
                            isAdmin,
                        );
                    })
                    .required(),
                formatValidationError: (error) => {
                    switch (error.details.validator) {
                        case 'date.min':
                            return 'Effective date cannot be in the past.';
                        case 'date.max':
                            return 'Effective date cannot be more than ninety days in the future.';
                        default:
                            return error.message;
                    }
                },
                triggers: {
                    change: 'update',
                },
            },
            includeBlanketWoS: {
                type: 'select',
                validator: Joi.boolean().required(),
                formatValidationError: (error) => {
                    return error.message;
                },
                triggers: {
                    change: 'update',
                },
            },
            recommendedWoS: {
                type: 'hidden',
                validator: Joi.boolean().required(),
            },
            agreementToConductSignature: {
                type: 'checkbox',
                validator: Joi.boolean().equal(true),
            },
            warrantyAndFraudSignature: {
                type: 'checkbox',
                validator: Joi.boolean().equal(true),
            },
            applicationId: {
                type: 'hidden',
                validator: UUID.schema,
            },
        },
        actions: {
            update: {
                action: async (formData: WCGAQuoteOptionsFormData) => {
                    if (!quote) {
                        return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                    }

                    return await execute(GetWCGAQuote, {
                        applicationId: formData.applicationId,
                        startDate: formData.startDate,
                        includeBlanketWoS: formData.includeBlanketWoS,
                        getRecommended: formData.recommendedWoS,
                        abortSignal,
                    });
                },
                fields: ['startDate', 'includeBlanketWoS'],
            },
            sign: {
                action: async (formData: WCGAQuoteOptionsFormData) => {
                    if (!quote) {
                        return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                    }

                    if (
                        formData.agreementToConductSignature &&
                        formData.warrantyAndFraudSignature
                    ) {
                        return execute(SignQuote, { quote });
                    }

                    if (quote.status === 'signed') {
                        return await execute(UnsignQuote, { quote });
                    }

                    return Success({ quote });
                },
                fields: ['startDate', 'includeBlanketWoS'],
            },
            default: async () => {
                if (!quote) {
                    return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                }

                return await execute(PurchaseWCGAQuote, {
                    quote,
                });
            },
        },
        onSuccess: (value, action) => {
            switch (action) {
                case 'update':
                    setQuote(value.quote as WCGAQuote);
                    break;
                case 'sign':
                    setQuote(value.quote as WCGAQuote);
                    break;
                case 'default': {
                    const selectedCoverages: Array<string> = ['Workers Compensation'];

                    const purchasedAppType = PurchasedAppType.create({
                        appTypes: selectedCoverages,
                    });

                    let appTypes: string | undefined;

                    if (isOK(purchasedAppType)) {
                        const result = PurchasedAppType.encode(purchasedAppType.value);
                        if (isOK(result)) {
                            appTypes = result.value;
                        }
                    }

                    navigate(
                        URI.build('/summary', {
                            applicationId: quote?.applicationId,
                            appTypes,
                            quotingEngine: QuotingEngineWCGA,
                            ...value,
                        }),
                    );
                    break;
                }
                default:
                    break;
            }
        },
        onFailure: (errors: Immutable<ErrorLike[]>) => {
            console.error(errors);
        },
    });
};

export interface WCGAQuoteLandingPageProps {
    applicationId: UUID;
}

export const WCGAQuoteLandingPage = function ({ applicationId }: WCGAQuoteLandingPageProps) {
    const { result: lastQuote } = useUseCase(GetLastWCGAQuote, { applicationId });
    const { activeSession } = useContext(AppContext);
    const eventBus = useMemo(() => container.get<DomainEventBus>(DomainEventBus), []);
    const isAdmin = hasRole(activeSession, 'admin');
    const [quote, setQuote] = useState<WCGAQuote>();

    const abortController = useMemo(() => {
        return new AbortController();
    }, []);

    const handleBindCoverageEvent = useCallback(() => {
        if (!quote) {
            return;
        }
        execute(CalculateSKU, {
            event: 'quote',
            applicationId,
        }).then((skuResult) => {
            const event: QuoteBindCoverage = {
                origin: 'Quote',
                name: 'BindCoverage',
                totalPremium: quote.totalPayable,
                applicationId,
                createdAt: new Date(Date.now()),
                id: UUID.create(),
                sku: isOK(skuResult) ? skuResult.value : undefined,
                isRenewal: quote.isRenewal,
            };
            eventBus.publish(event);
        });
    }, [applicationId, eventBus, quote]);

    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, [abortController]);

    const { navigate } = useNavigation();
    const optionsForm = useMemo(
        () =>
            createWCGAQuoteOptionsForm(quote, setQuote, navigate, isAdmin, abortController.signal),
        [quote, navigate, isAdmin, abortController.signal],
    );

    const navigateToErrorPage = useCallback((errors: Immutable<never[]>) => {
        return <ErrorPage errors={errors} />;
    }, []);

    useEffect(() => {
        if (lastQuote != null) {
            if (isOK(lastQuote)) {
                setQuote(lastQuote.value.quote as WCGAQuote);
            } else {
                navigateToErrorPage(lastQuote.errors);
            }
        }
    }, [setQuote, lastQuote, navigateToErrorPage]);

    const quotePage = {
        name: 'coverage',
        fields: ['startDate', 'includeBlanketWoS'],
    };
    const signPage = {
        name: 'signature',
        fields: ['agreementToConductSignature', 'warrantyAndFraudSignature'],
    };
    const isQuoteExpired = quote?.daysToExpire === -1 && !isAdmin;
    const isQuoteBindable = !isQuoteExpired;
    const formPages = isQuoteBindable ? [quotePage, signPage] : [quotePage];

    const optionsWizardForm = useWizardForm(optionsForm, {
        pages: formPages as WizardPages<OpaqueForm<WCGAQuoteOptionsFormData>>,
        initialValue: {
            applicationId,
            startDate: quote?.options.effectiveDate,
            includeBlanketWoS: quote?.options.blanketWoS,
            recommendedWoS: quote?.options.isWosRecommended,
            agreementToConductSignature: false,
            warrantyAndFraudSignature: false,
        },
    });

    const quoteStepPage: Page<WCGAQuoteOptionsFormData, WCGAQuote> = {
        name: 'coverage',
        component: WCGACoverage,
    };
    const quoteSignPage: Page<WCGAQuoteOptionsFormData, WCGAQuote> = {
        name: 'signature',
        component: QuoteSignature,
    };
    const pages: Page<WCGAQuoteOptionsFormData, WCGAQuote>[] = isQuoteBindable
        ? [quoteStepPage, quoteSignPage]
        : [quoteStepPage];

    const referredNavigation: PageNavigationComponent<WCGAQuoteOptionsFormData, WCGAQuote> =
        ReferredQuoteOnDemandNavigation;

    return (
        <QuoteLandingPage
            title={makeTitle(quote)}
            icon="workers-comp"
            applicationId={applicationId}
            quote={quote}
            optionsWizardForm={optionsWizardForm}
            pages={pages}
            pageNavigation={!isQuoteBindable ? referredNavigation : undefined}
            quoteBreakdown={WCGAQuoteBreakdown}
            handleBindCoverage={handleBindCoverageEvent}
            daysToExpire={quote?.daysToExpire}
        />
    );
};
