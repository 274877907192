import { inject } from '@embroker/shotwell/core/di';
import { JSONSerdes } from '@embroker/shotwell/core/encoding';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Money } from '@embroker/shotwell/core/types/Money';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { State } from '@embroker/shotwell/core/types/StateList';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ApplicationRepository } from '../repositories/ApplicationRepository';
import { LPLAreaOfPractice } from '../types/LPLAreaOfPractice';
import { UpdateApplicantFromTier1 } from './UpdateApplicantFromTier1';

export interface UpdateApplicationQuestionnaireFromTier1Request {
    applicationId: UUID;
    numberOfAttorneys: number;
    revenue: Money;
    areasOfPractice: LPLAreaOfPractice[];
    stateWithMostAttorneys: State;
}

export interface UpdateApplicationQuestionnaireFromTier1 extends UseCase {
    execute(
        request: UpdateApplicationQuestionnaireFromTier1Request,
    ): AsyncResult<void, InvalidArgument | OperationFailed>;
}

class UpdateApplicationQuestionnaireFromTier1UseCase
    extends UseCase
    implements UpdateApplicationQuestionnaireFromTier1
{
    public static type = Symbol('Shopping/UpdateApplicationQuestionnaireFromTier1');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
        @inject(UpdateApplicantFromTier1.type)
        private updateApplicantFromTier1: UpdateApplicantFromTier1,
    ) {
        super(eventBus);
    }
    public async execute(
        data: UpdateApplicationQuestionnaireFromTier1Request,
    ): AsyncResult<void, InvalidArgument | OperationFailed> {
        const getApplicationResponse = await this.applicationRepository.getApplication(
            data.applicationId,
        );

        if (isErr(getApplicationResponse)) {
            return handleOperationFailure(getApplicationResponse);
        }
        const { value: application } = getApplicationResponse;

        const deserializeResult = JSONSerdes.deserialize(application.questionnaireData ?? '{}');
        if (isErr(deserializeResult)) {
            return deserializeResult;
        }

        const savedQuestionnaireData = deserializeResult.value as Record<string, unknown>;

        const inputQuestionnaireData = mapInputToQuestionnaireData(data);

        const newQuestionnaireData: Record<string, unknown> = {};
        Object.keys({ ...savedQuestionnaireData, ...inputQuestionnaireData }).forEach((key) => {
            newQuestionnaireData[key] = inputQuestionnaireData[key] ?? savedQuestionnaireData[key];
        });

        const serializeResult = JSONSerdes.serialize(newQuestionnaireData);
        if (isErr(serializeResult)) {
            return serializeResult;
        }

        const result = await this.applicationRepository.updateQuestionnaireData({
            isPrefill: false,
            applicationId: data.applicationId,
            questionnaireData: serializeResult.value,
        });
        if (isErr(result)) {
            return result;
        }
        application.updateQuestionnaireData(serializeResult.value);
        this.eventBus.publishEntityEvents(application);

        const updateApplicantResult = await this.updateApplicantFromTier1.execute({
            tier1QuestionnaireData: newQuestionnaireData,
        });
        if (isErr(updateApplicantResult)) {
            return Failure(OperationFailed({ errors: updateApplicantResult.errors }));
        }

        return Success();
    }
}

function mapInputToQuestionnaireData(inputData: UpdateApplicationQuestionnaireFromTier1Request) {
    const areas_of_practice_rows: { area: string; percentage: number }[] = [];
    inputData.areasOfPractice.forEach((area: LPLAreaOfPractice, index: number) => {
        areas_of_practice_rows[index] = {
            area: area.areaOfPracticeCode,
            percentage: area.percentage,
        };
    });
    const result: Record<string, unknown> = {
        totalAttorneysThisYear: inputData.numberOfAttorneys,
        state_with_most_attorneys: inputData.stateWithMostAttorneys,
        areas_of_practice: { areas_of_practice_rows },
        gross_revenue_total: Money.toFloat(inputData.revenue),
    };

    return result;
}

export const UpdateApplicationQuestionnaireFromTier1: UseCaseClass<UpdateApplicationQuestionnaireFromTier1> =
    UpdateApplicationQuestionnaireFromTier1UseCase;
