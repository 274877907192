import { Text } from '@embroker/ui-toolkit/v2';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import React, { useEffect, useState } from 'react';
import { GetOrganizationProfile } from '../../../userOrg/useCases/GetOrganizationProfile';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { execute } from '@embroker/shotwell/core/UseCase';
import { MPLValidNaicsCodes } from '../../../userOrg/types/MPLValidNaicsCodes';
import { LandingPageLayout } from '../../../view/components/LandingPageLayout.view';
import { PublishUserOrgUserClickEvent } from '../../../userOrg/useCases/PublishUserOrgUserClickEvent';

type UserGuidancePageMplProps = {
    isLoading: boolean;
    organizationId: UUID;
    onPrimaryButtonClick: () => void;
    onSecondaryButtonClick: () => void;
};

export function UserGuidancePageMpl({
    isLoading,
    organizationId,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
}: UserGuidancePageMplProps) {
    const [vertical, setVertical] = useState('');
    const [companyName, setCompanyName] = useState('');

    const { result: getOrganizationProfileResult } = useUseCase(GetOrganizationProfile, {
        organizationId,
    });

    useEffect(() => {
        if (getOrganizationProfileResult !== undefined && isOK(getOrganizationProfileResult)) {
            const naicsCode = getOrganizationProfileResult.value.organization.naics;
            if (naicsCode && MPLValidNaicsCodes.isNaicCodeValid(naicsCode)) {
                setVertical(MPLValidNaicsCodes.getVerticalText(naicsCode));
            } else {
                onSecondaryButtonClick();
            }
            setCompanyName(getOrganizationProfileResult.value.organization.companyLegalName);
        }
    }, [setCompanyName, setVertical, onSecondaryButtonClick, getOrganizationProfileResult]);

    const handlePrimaryButtonClick = async () => {
        await execute(PublishUserOrgUserClickEvent, {
            clickEventName: 'Start a Quote CTA Clicked',
        });
        onPrimaryButtonClick();
    };

    const handleSecondaryButtonClick = async () => {
        await execute(PublishUserOrgUserClickEvent, {
            clickEventName: 'Shop All Coverage CTA Clicked',
        });
        onSecondaryButtonClick();
    };

    return (
        <LandingPageLayout
            isLoading={isLoading}
            title={`Embroker takes the guesswork out of insurance for ${vertical} like you`}
            primaryAction={{
                label: 'Start a Quote',
                onClick: handlePrimaryButtonClick,
            }}
            secondaryAction={{
                label: 'Shop all Coverage',
                onClick: handleSecondaryButtonClick,
            }}
            imageProps={{
                width: '100%',
                name: 'illustration-woman-laptop-one-hand',
            }}
        >
            <Text style="body 1">
                Let us recommend the right coverages for {companyName}. Just answer a few questions
                to help us understand your business needs, and apply for custom policies in as
                little as 10 minutes.
            </Text>
        </LandingPageLayout>
    );
}
