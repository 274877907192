import { Immutable } from '@embroker/shotwell/core/types';
import {
    Button,
    ColumnLayout,
    SelectInput,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React, { MouseEvent } from 'react';
import { EndorsementDefinition } from '../repositories/digitalEndorsements';

interface EndorsementSelectorProps {
    onDismiss(): void;
    availableEndorsements: Immutable<EndorsementDefinition[]>;
    selectedEndorsement: EndorsementDefinition | undefined;
    setSelectedEndorsement(endorsement: EndorsementDefinition): void;
    onNext(value: MouseEvent<HTMLButtonElement>): void;
}

export function EndorsementSelector({
    onDismiss,
    availableEndorsements,
    selectedEndorsement,
    setSelectedEndorsement,
    onNext,
}: EndorsementSelectorProps) {
    const items = availableEndorsements.map((endorsement: EndorsementDefinition) => {
        return {
            title: endorsement.name,
            value: endorsement.type,
        };
    });

    return (
        <StackLayout gap="32">
            <Text data-e2e="title-kind-of-endorsement" style="heading 3">
                What kind of endorsement are you adding to this policy?
            </Text>
            <StackLayout>
                <Text color="brand-500">Select endorsement:</Text>
                <SelectInput
                    data-e2e="select-endorsement"
                    label="Endorsement"
                    items={[...items]}
                    onChange={(e) => {
                        const selectedType = e.target.value as string;
                        const selected = availableEndorsements.find(
                            (endorsement) => endorsement.type === selectedType,
                        );

                        if (selected) {
                            setSelectedEndorsement(selected);
                        }
                    }}
                    value={selectedEndorsement?.type}
                ></SelectInput>
            </StackLayout>
            <ColumnLayout gap="56">
                <Button
                    data-e2e="next-button"
                    onClick={onNext}
                    disabled={selectedEndorsement === undefined}
                    appearance="primary"
                >
                    Next
                </Button>
                <TextButton data-e2e="cancel-button" onClick={() => onDismiss()}>
                    Cancel
                </TextButton>
            </ColumnLayout>
        </StackLayout>
    );
}
