import { defineEntityValidator, entity } from '@embroker/shotwell/core/entity/Entity';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { SKU } from '../../../analytics/types/SKU';
import { commonQuoteProps, Quote } from '../../entities/Quote';
import { CyberQuoteDetails } from '../types/CyberQuoteDetails';
import { CyberQuoteOptions } from '../types/CyberQuoteOptions';
import { CyberUserInfo } from '../types/CyberUserInfo';

export interface CyberQuotePurchased extends DomainEvent {
    readonly origin: 'CyberQuote';
    readonly name: 'Purchased';
    readonly totalPremium: Nullable<Money>;
    readonly applicationId: UUID;
    sku?: SKU;
    readonly isRenewal: boolean;
}

export interface CyberQuote extends Quote {
    readonly options: CyberQuoteOptions;
    readonly details: CyberQuoteDetails;
    readonly isRenewal: boolean;
    readonly userInfo?: CyberUserInfo;
    daysToExpire?: number;
}

export const CyberQuote = entity<CyberQuote, Quote>({
    validator: defineEntityValidator<CyberQuote>({
        ...commonQuoteProps,
        options: CyberQuoteOptions.schema.required(),
        details: CyberQuoteDetails.schema.required(),
        isRenewal: Joi.boolean().required(),
        userInfo: CyberUserInfo.schema.optional(),
        daysToExpire: Joi.number().optional(),
    }),
    inherits: Quote,
});
