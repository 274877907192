import { Immutable } from '@embroker/shotwell/core/types';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { ErrorLike, isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    Accordion,
    BoxLayout,
    Button,
    ButtonBar,
    CenterLayout,
    ColumnLayout,
    Form,
    Header,
    PageLayout,
    StackLayout,
    StatusMessage,
    Text,
    TextButton,
    Tooltip,
    usePageLayout,
} from '@embroker/ui-toolkit/v2';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { DeleteFile } from '../../../../documents/useCases/DeleteFile';
import { SaveFiles } from '../../../../documents/useCases/SaveFiles';
import { FileUpload } from '../../../../documents/view/FileUpload';
import { UploadFile } from '../../../../documents/view/UploadFile/UploadFile';
import { SaveSupplementalQuestionnaire } from '../../../../higherLimitsSupplementalQuestionnaire/useCases/SaveSupplementalQuestionnaire';
import { SubmitExcessApplicationForReview } from '../../../../quote/embrokerExcess/useCases/SubmitExcessApplicationForReview';
import { hasRole } from '../../../../userOrg/entities/Session';
import { ActiveSession, AppContext } from '../../../../view/AppContext';
import { NavigationalLogo } from '../../../../view/components/NavigationalLogo';
import { navigateToErrorPage } from '../../../../view/errors';
import { NavigateFunction, useNavigation } from '../../../../view/hooks/useNavigation';
import { SubmitForReviewData } from './SubmitForReview';

export interface SubmitForReviewFormProps {
    applicationId: UUID;
    submitForReviewData: SubmitForReviewData;
    files: FileUpload[];
}

export function SubmitForReviewForm({
    applicationId,
    submitForReviewData,
    files,
}: SubmitForReviewFormProps) {
    const { navigate } = useNavigation();
    const { activeSession } = useContext(AppContext);

    const abortController = useMemo(() => {
        return new AbortController();
    }, []);

    const submitForReviewForm = useMemo(
        () =>
            createSubmitForReviewForm(
                abortController.signal,
                navigate,
                applicationId,
                activeSession,
            ),
        [abortController, navigate, applicationId, activeSession],
    );

    const { fields, submit, trigger } = useForm(submitForReviewForm, submitForReviewData);

    const state = usePageLayout({
        header: (
            <Header>
                <NavigationalLogo />
            </Header>
        ),
    });

    const [filesToDisplay, setFilesToDisplay] = useState<FileUpload[]>(files);
    const [filesToSave, setFilesToSave] = useState<FileUpload[]>();
    const [fileToDelete, setFileToDelete] = useState<FileUpload>();
    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
    const [showNoFilesUploaded, setShowNoFilesUploaded] = useState<boolean>(false);

    const onBackToQuoteClick = () => {
        const builtURI = URI.build('/shopping/application/quote/excess', {
            applicationId,
        });
        return navigate(builtURI);
    };

    const handleRemoved = (removedFile: FileUpload, newFiles: FileUpload[]) => {
        setFileToDelete(removedFile);
        setFilesToDisplay(newFiles);
    };

    const handleUploaded = (uploadedFile: FileUpload, newFiles: FileUpload[]) => {
        if (uploadedFile.s3FileKey === null) {
            setShowErrorMessage(true);
        }
        setFilesToSave(newFiles);
        setFilesToDisplay(newFiles);
    };

    useEffect(() => {
        let isMounted = true;
        if (fileToDelete !== undefined && fileToDelete.s3FileKey !== null) {
            execute(DeleteFile, {
                fileKey: fileToDelete.s3FileKey,
                applicationId,
            }).then((result) => {
                if (isMounted) {
                    if (isOK(result)) {
                        setFileToDelete(undefined);
                    }
                    if (isErr(result)) {
                        setShowErrorMessage(true);
                    }
                }
            });
        }

        return () => {
            isMounted = false;
        };
    }, [applicationId, fileToDelete]);

    useEffect(() => {
        let isMounted = true;
        if (filesToSave !== undefined) {
            const files = filesToSave
                .filter((file) => file.s3FileKey !== null)
                .map((file) => {
                    return {
                        name: file.file.name,
                        file_key: file.s3FileKey!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
                        mime_type: file.file.type,
                        size: file.file.size,
                    };
                });
            execute(SaveFiles, {
                files,
                applicationId,
            }).then((result) => {
                if (isMounted) {
                    if (isOK(result)) {
                        setShowNoFilesUploaded(false);
                    }
                    if (isErr(result)) {
                        setShowErrorMessage(true);
                    }
                }
            });
        }
        return () => {
            isMounted = false;
        };
    }, [applicationId, filesToSave]);

    const handleSelected = (selectedFiles: FileUpload[], newFiles: FileUpload[]) => {
        setFilesToDisplay(newFiles);
    };

    const handleSaveForLater = () => {
        trigger('saveForLater');
    };

    const handleSubmit = () => {
        if (filesToDisplay.length == 0) {
            setShowNoFilesUploaded(true);
            return;
        }
        setShowNoFilesUploaded(false);
        submit();
    };

    return (
        <PageLayout {...state}>
            <CenterLayout>
                <StackLayout className="u-2/3">
                    <BoxLayout gap="8">
                        <TextButton
                            as="button"
                            onClick={onBackToQuoteClick}
                            icon="bold-caret-left"
                            data-e2e="back-to-quote-page"
                        >
                            Back to Quote Page
                        </TextButton>
                    </BoxLayout>
                    <CenterLayout gap="32">
                        <StackLayout gap="32" className="u=3/4">
                            <Text style="heading 3" data-e2e="submit-for-review-heading">
                                Submit for Review
                            </Text>
                            <Text style="heading3">
                                Our underwriter will review your Application and underlying
                                documents, and will reach out soon via email. Based on additional
                                information provided, the quoted terms may change.
                            </Text>
                            <Form>
                                <StackLayout>
                                    <ColumnLayout>
                                        <Form.Field
                                            type={fields.fullName.type}
                                            label="Name"
                                            inputProps={{ ...fields.fullName.props }}
                                            messages={fields.fullName.messages}
                                            data-e2e="name"
                                        />
                                        <Form.Field
                                            type={fields.email.type}
                                            label="Work email"
                                            inputProps={{ ...fields.email.props }}
                                            messages={fields.email.messages}
                                            data-e2e="work-email"
                                        />
                                    </ColumnLayout>
                                    <Form.Field
                                        type={fields.additionalEmails.type}
                                        inputProps={{
                                            ...fields.additionalEmails.props,
                                            label: 'You can c.c. other people so that they receive our email response as well by adding their email addresses here (optional).',
                                            note: 'Use commas to separate multiple email recipients, if any.',
                                        }}
                                        messages={fields.additionalEmails.messages}
                                        data-e2e="add-other-people"
                                    />
                                    <Form.Field
                                        type={fields.additionalInformation.type}
                                        inputProps={{
                                            ...fields.additionalInformation.props,
                                        }}
                                        messages={fields.additionalInformation.messages}
                                        data-e2e="additional-information"
                                    ></Form.Field>
                                </StackLayout>
                            </Form>
                            <Accordion single data-e2e="accordion">
                                <Accordion.Item
                                    title="Upload Documents"
                                    state="open"
                                    disabled
                                    data-e2e="upload-documents-accordion"
                                >
                                    <StackLayout>
                                        <Text style="body 1">
                                            Please provide the Primary Application and Underlying
                                            Quotes.
                                            <Tooltip
                                                iconSize="medium"
                                                text={
                                                    <React.Fragment>
                                                        Please upload a PDF* copy of: <br />• The
                                                        most recently completed and signed Primary
                                                        Application (this should be the Application
                                                        that the Primary Carrier is accepting), and
                                                        <br />• Underlying Quote letters from all of
                                                        the carriers (including the Primary Carrier)
                                                        that are below Embroker's layer of coverage.
                                                        <br />
                                                        <br />
                                                        *If your files are not in PDF format (.pdf),
                                                        we'll follow up by email with instructions
                                                        on how and where to upload documents later.
                                                    </React.Fragment>
                                                }
                                                data-e2e="tooltip"
                                            />
                                        </Text>
                                        <UploadFile
                                            fileTypeFilters={['application/pdf']}
                                            onSelected={handleSelected}
                                            onUploaded={handleUploaded}
                                            onRemoved={handleRemoved}
                                            showButton
                                            isMultiple
                                            buttonText="Choose files"
                                            placeholder="or drag files here (PDF format only)"
                                            files={filesToDisplay}
                                            data-e2e="upload-file"
                                        />
                                        {showErrorMessage && (
                                            <StatusMessage
                                                status="error"
                                                onDismiss={() => {
                                                    setShowErrorMessage(false);
                                                }}
                                                data-e2e="upload-failed"
                                            >
                                                {'Document action failed.'}
                                            </StatusMessage>
                                        )}
                                        {showNoFilesUploaded && (
                                            <StatusMessage status="warning" data-e2e="warning">
                                                {
                                                    'Your Primary Application and Underlying Quote letter(s) are required. Please upload these documents to proceed with your submission request.'
                                                }
                                            </StatusMessage>
                                        )}
                                    </StackLayout>
                                </Accordion.Item>
                            </Accordion>
                            <ButtonBar
                                split={'-1'}
                                reverse
                                responsive={{ containerWidth: { smallerThan: 400 } }}
                            >
                                <ColumnLayout
                                    split={'-2'}
                                    responsive={{ containerWidth: { smallerThan: 400 } }}
                                >
                                    <TextButton
                                        onClick={handleSaveForLater}
                                        data-e2e="save-for-later"
                                    >
                                        Save for Later
                                    </TextButton>
                                    <Button onClick={handleSubmit} data-e2e="submit">
                                        Submit
                                    </Button>
                                </ColumnLayout>
                            </ButtonBar>
                        </StackLayout>
                    </CenterLayout>
                </StackLayout>
            </CenterLayout>
        </PageLayout>
    );
}

function createSubmitForReviewForm(
    abortSignal: AbortSignal,
    navigate: NavigateFunction,
    applicationId: UUID,
    activeSession: ActiveSession,
) {
    return createForm<SubmitForReviewData>({
        fields: {
            fullName: {
                type: 'text',
                validator: Joi.string().required(),
                formatValidationError() {
                    return 'Please enter name';
                },
            },
            email: {
                type: 'email',
                validator: EmailAddress.schema.required(),
                formatValidationError(error) {
                    switch (error.details.validator) {
                        case 'any.required':
                        case 'string.email':
                            return 'You must enter a valid email address';
                        default:
                            return error.message;
                    }
                },
            },
            additionalEmails: {
                type: 'csv',
                validator: Joi.array()
                    .items(EmailAddress.schema.trim().allow(''))
                    .min(1)
                    .optional(),
                formatValidationError(error) {
                    switch (error.details.validator) {
                        default:
                            return 'Please use valid email addresses';
                    }
                },
            },
            additionalInformation: {
                type: 'textarea',
                validator: Joi.string().optional(),
            },
        },
        formatSubmitErrors(errors) {
            if (errors.length > 0) {
                return ['Oops! Something went wrong. Please try again later.'];
            }
            return [];
        },
        actions: {
            saveForLater: {
                action: async (formData: SubmitForReviewData) => {
                    return await execute(SaveSupplementalQuestionnaire, {
                        applicationId,
                        questionnaireData: JSON.stringify(formData),
                    });
                },
                fields: ['fullName', 'email', 'additionalEmails', 'additionalInformation'],
            },
            default: {
                action: async (formData: SubmitForReviewData) => {
                    return await execute(SubmitExcessApplicationForReview, {
                        applicationId,
                        supplementalQuestionnaireData: JSON.stringify(formData),
                    });
                },
                fields: ['fullName', 'email', 'additionalEmails', 'additionalInformation'],
            },
        },
        onSuccess: (value, action) => {
            switch (action) {
                case 'saveForLater':
                    if (hasRole(activeSession, 'broker')) {
                        navigate('/broker/dashboard');
                        break;
                    }
                    navigate('/summary');
                    break;
                case 'default':
                    navigate(URI.build('/shopping/excess-thank-you'));
                    break;
            }
        },
        onFailure: (errors: Immutable<ErrorLike[]>) => {
            return navigateToErrorPage(navigate, errors);
        },
    });
}
