import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import {
    AsyncResult,
    isErr,
    Success,
    handleOperationFailure,
} from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';

import { ContactUsRepository } from '../repositories/ContactUs';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { ContactUsForm } from '../types/ContactForm';

export interface ContactUs extends UseCase {
    execute(contactUsData: ContactUsForm): AsyncResult<boolean, InvalidArgument | OperationFailed>;
}

class ContactUsUseCase extends UseCase implements ContactUs {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Support/ContactUs');
    /**
     * Constructor for ContactUs use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param ContactUsService
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ContactUsRepository) private embrokerRepository: ContactUsRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        formData: ContactUsForm,
    ): AsyncResult<boolean, InvalidArgument | OperationFailed> {
        const contactUsResult = await this.embrokerRepository.contactUs(formData);
        if (isErr(contactUsResult)) {
            return handleOperationFailure(contactUsResult);
        }

        return Success(true);
    }
}

export const ContactUs: UseCaseClass<ContactUs> = ContactUsUseCase;
