import { Joi } from '@embroker/shotwell/core/validation/schema';
import { Success } from '@embroker/shotwell/core/types/Result';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    Button,
    ButtonBar,
    Form,
    FormLayout,
    SidebarLayout,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React, { useMemo } from 'react';
import { IssueOptionsStepGuidelines } from './IssueOptionsStepGuidelines';

export interface IssueOptionsStepData {
    isCertificateForMyself: boolean;
}

function createIssueOptionsStepForm(onNext: (data: IssueOptionsStepData) => void) {
    return createForm<IssueOptionsStepData>({
        fields: {
            isCertificateForMyself: {
                type: 'radioGroup',
                validator: Joi.boolean().required().valid(true, false),
                formatValidationError() {
                    return 'Please select who is the certificate issued for.';
                },
            },
        },
        async submit(data) {
            onNext(data);
            return Success();
        },
    });
}

interface IssueOptionsStepProps {
    initialData?: Partial<IssueOptionsStepData>;
    onNext(data: IssueOptionsStepData): void;
    onBack(): void;
}

export function IssueOptionsStep({ initialData, onNext, onBack }: IssueOptionsStepProps) {
    const issueOptionsStepForm = useMemo(() => createIssueOptionsStepForm(onNext), [onNext]);
    const { fields, submit, value } = useForm(issueOptionsStepForm, initialData);

    function calcIssueButtonName(): string {
        if (value.isCertificateForMyself === true) {
            return 'Issue the Certificate';
        }
        return 'Next';
    }

    return (
        <SidebarLayout sidebarVariant="compact">
            <IssueOptionsStepGuidelines></IssueOptionsStepGuidelines>
            <StackLayout>
                <FormLayout appearance="wide">
                    <StackLayout gap="48">
                        <StackLayout gap="24">
                            <Text style={'heading 5'} data-e2e="issue-options-questionnaire">
                                Who do you need this certificate for?
                            </Text>
                            <Form>
                                <StackLayout>
                                    <Form.Field
                                        direction="horizontal"
                                        inputProps={{
                                            ...fields.isCertificateForMyself.props,
                                            items: [
                                                {
                                                    value: true,
                                                    title: 'I need a certificate for myself',
                                                },
                                                {
                                                    value: false,
                                                    title: 'I need a certificate for someone else',
                                                },
                                            ],
                                        }}
                                        messages={fields.isCertificateForMyself.messages}
                                        type={fields.isCertificateForMyself.type}
                                        data-e2e="cert-issue-options"
                                    />
                                </StackLayout>
                            </Form>
                        </StackLayout>
                        <ButtonBar
                            split={'-1'}
                            reverse
                            responsive={{ containerWidth: { smallerThan: 400 } }}
                        >
                            <TextButton onClick={onBack} data-e2e="issue-options-back">
                                Back
                            </TextButton>
                            <Button onClick={submit} data-e2e="issue-options-submit">
                                {calcIssueButtonName()}
                            </Button>
                        </ButtonBar>
                    </StackLayout>
                </FormLayout>
                <SidebarLayout.MobileFooter split="-1">
                    <Text>What does this mean?</Text>
                    <SidebarLayout.Link appearance="secondary" panelIndex={1}>
                        Let us explain
                    </SidebarLayout.Link>
                </SidebarLayout.MobileFooter>
            </StackLayout>
        </SidebarLayout>
    );
}
