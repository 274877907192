import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Nullable } from '@embroker/shotwell/core/types';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { SKU } from '../../analytics/types/SKU';
import { CalculateSKU } from '../../analytics/useCases/CalculateSKU';
import { CalculateSKUFromAppTypes } from '../../analytics/useCases/CalculateSKUFromAppTypes';
import { ApplicationForm, ApplicationSubmitted } from '../entities/Application';
import { AppTypeCode, ShoppingCoverage } from '../types/enums';

export enum FormEventName {
    SaveAndExitClicked = 'SaveAndExitClicked',
    SaveAndExitModalClicked = 'SaveAndExitModalClicked',
    Viewed = 'FormViewed',
    Saved = 'FormSaved',
    TierICompleted = 'TierICompleted',
    TierIICompleted = 'TierIICompleted',
    TierIIICompleted = 'TierIIICompleted',
    Submitted = 'FormSubmitted',
}

export interface PublishFormEventsRequest {
    eventName: FormEventName;
    formStage: string;
    formName: string;
    applicationId: Nullable<UUID>;
    appTypeList?: readonly AppTypeCode[];
    shoppingCoverageList?: readonly ShoppingCoverage[];
    isRenewal: boolean;
    questionnaireData?: string;
}

export interface PublishFormEvents extends UseCase {
    execute(request: PublishFormEventsRequest): AsyncResult<void>;
}

class PublishFormEventsUseCase extends UseCase implements PublishFormEvents {
    public static type = Symbol('Shopping/PublishFormEvents');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(CalculateSKU.type) private calculateSKU: CalculateSKU,
        @inject(CalculateSKUFromAppTypes.type)
        private calculateSKUFromAppTypes: CalculateSKUFromAppTypes,
    ) {
        super(eventBus);
    }

    public async execute(
        request: PublishFormEventsRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed> {
        let sku: SKU | undefined;
        if (request.shoppingCoverageList || request.questionnaireData) {
            const appType = request?.appTypeList?.[0] ?? 'AppTypeCodeListOther';

            const skuResult = await this.calculateSKU.execute({
                appType,
                event: 'questionnaire_changed',
                questionnaireData: request.questionnaireData,
                shoppingCoverageList: request.shoppingCoverageList ?? [],
                applicationId: request.applicationId ?? undefined,
            });

            sku = skuResult.value;
        } else {
            if (request.appTypeList) {
                const skuResult = await this.calculateSKUFromAppTypes.execute({
                    appTypeList: request.appTypeList,
                });
                sku = skuResult.value;
            }
        }

        if (request.eventName === FormEventName.Submitted) {
            const event: ApplicationSubmitted = {
                origin: 'Application',
                name: 'Submitted',
                createdAt: new Date(Date.now()),
                applicationId: request.applicationId ?? UUID.create(),
                id: UUID.create(),
                sku,
                isRenewal: request.isRenewal,
            };

            await this.eventBus.publish(event);
        } else {
            const applicationFormEvent: ApplicationForm = {
                origin: 'Application',
                name: request.eventName,
                formStage: request.formStage,
                formName: request.formName,
                createdAt: new Date(Date.now()),
                applicationId: request.applicationId,
                id: UUID.create(),
                sku,
                isRenewal: request.isRenewal,
            };

            await this.eventBus.publish(applicationFormEvent);
        }

        return Success();
    }
}

export const PublishFormEvents: UseCaseClass<PublishFormEvents> = PublishFormEventsUseCase;
