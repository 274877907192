import { Money, USD } from '@embroker/shotwell/core/types/Money';
import { State } from '@embroker/shotwell/core/types/StateList';
import { valueObject } from '@embroker/shotwell/core/types/ValueObject';
import { Joi, Schema, defineValidator } from '@embroker/shotwell/core/validation/schema';
import { SelectOptionItem } from '@embroker/ui-toolkit/v2';
import { QuoteOptions } from '../../../../quote/entities/Quote';
import { MPLVertical } from '../entities/MPLQuote';
import { MPLHigherLimit } from './MPLHigherLimit';

export const MPLPerClaimLimit = [
    10_000, 25_000, 50_000, 100_000, 250_000, 500_000, 1_000_000, 2_000_000, 3_000_000,
] as const;
export type MPLPerClaimLimit = (typeof MPLPerClaimLimit)[number];
export const MPLAggregateLimit = [
    10_000, 25_000, 50_000, 100_000, 250_000, 500_000, 1_000_000, 2_000_000, 3_000_000,
] as const;
export type MPLAggregateLimit = (typeof MPLAggregateLimit)[number];
export type MPLLimits = {
    perClaimLimit: MPLPerClaimLimit;
    aggregateLimit: MPLAggregateLimit;
};
export const MPLRetentionList = [
    0, 250, 500, 1_000, 2_500, 5_000, 10_000, 15_000, 25_000, 50_000,
] as const;
export type MPLRetention = (typeof MPLRetentionList)[number];

export interface MPLQuoteOptions extends QuoteOptions {
    readonly limits: MPLLimits;
    readonly retention: MPLRetention;
    readonly isDeselected: boolean;
}

export const isValidMPLLimitsCombination = (
    value: MPLLimits,
    helpers: Schema.CustomHelpers<number>,
) => {
    const validCombination = validPerClaimAggregateCombinationList.some(
        (limits) =>
            limits.perClaimLimit === value.perClaimLimit &&
            limits.aggregateLimit === value.aggregateLimit,
    );
    if (!validCombination) {
        return helpers.error('limits.invalidCombination');
    }
    return value;
};

export const MPLQuoteOptions = valueObject({
    ...defineValidator<MPLQuoteOptions>({
        effectiveDate: Joi.date().required(),
        limits: Joi.object({
            perClaimLimit: Joi.number()
                .allow(...MPLPerClaimLimit)
                .required(),
            aggregateLimit: Joi.number()
                .allow(...MPLAggregateLimit)
                .required(),
        })
            .custom(isValidMPLLimitsCombination)
            .required(),
        retention: Joi.number().required(),
        isDeselected: Joi.boolean().required(),
    }),
});

export const validPerClaimAggregateCombinationList: MPLLimits[] = [
    { perClaimLimit: 100_000, aggregateLimit: 100_000 },
    { perClaimLimit: 250_000, aggregateLimit: 250_000 },
    { perClaimLimit: 250_000, aggregateLimit: 500_000 },
    { perClaimLimit: 500_000, aggregateLimit: 500_000 },
    { perClaimLimit: 500_000, aggregateLimit: 1_000_000 },
    { perClaimLimit: 1_000_000, aggregateLimit: 1_000_000 },
    { perClaimLimit: 1_000_000, aggregateLimit: 2_000_000 },
    { perClaimLimit: 1_000_000, aggregateLimit: 3_000_000 },
    { perClaimLimit: 2_000_000, aggregateLimit: 2_000_000 },
    { perClaimLimit: 3_000_000, aggregateLimit: 3_000_000 },
];

export const getMPLLimitsOptions = (state: State, higherLimits?: MPLHigherLimit) => {
    const mplLimitsFormOption: SelectOptionItem<MPLLimits>[] = [];
    const perClaimAggregateCombinationList = getPerClaimAggregateLimitCombinationList(state);
    perClaimAggregateCombinationList.forEach((element) => {
        const perClaimMoney = Money.toString(USD(element.perClaimLimit * 100));
        const aggregateMoney = Money.toString(USD(element.aggregateLimit * 100));
        const perClaimAggregateCombinationTitle = `${perClaimMoney} / ${aggregateMoney}`;
        mplLimitsFormOption.push({ title: perClaimAggregateCombinationTitle, value: element });
    });
    if (higherLimits) {
        return mplLimitsFormOption.filter((item) => {
            return (
                item.value.perClaimLimit <= higherLimits.per_claim_limit &&
                item.value.aggregateLimit <= higherLimits.aggregate_limit
            );
        });
    }
    return mplLimitsFormOption;
};

const getPerClaimAggregateLimitCombinationList = (state: State): MPLLimits[] => {
    const specificRuleStates: Array<State> = ['SD', 'VT', 'NV', 'ND', 'AR', 'NM'];
    if (!specificRuleStates.includes(state)) {
        return validPerClaimAggregateCombinationList;
    }

    if (state === 'NM') {
        const newMexicoMinLimit = 500_000;
        return validPerClaimAggregateCombinationList.filter(
            (limits) =>
                limits.perClaimLimit >= newMexicoMinLimit &&
                limits.aggregateLimit >= newMexicoMinLimit,
        );
    }
    const specificRuleStatesMaxLimit = 1_000_000;
    return validPerClaimAggregateCombinationList.filter(
        (limits) =>
            limits.perClaimLimit <= specificRuleStatesMaxLimit &&
            limits.aggregateLimit <= specificRuleStatesMaxLimit,
    );
};

export const getMPLRetentionOptions = (mplVertical: MPLVertical) => {
    const minimumRetention = getMinimumRetention(mplVertical);
    return MPLRetentionList.filter((v) => v >= minimumRetention)
        .map((v) => USD(v * 100))
        .map(Money.toOption) as SelectOptionItem<MPLRetention>[];
};

const getMinimumRetention = (mplVertical: MPLVertical) => {
    switch (mplVertical) {
        case 'NonTechnologyBusinessAndManagementConsultants':
            return 0;
        case 'RealEstateAgent':
            return 2500;
        case 'HomeInspector':
            return 250;
        case 'TaxPreparersAndBookkeepers':
            return 1000;
        case 'Accountant':
            return 1000;

        default:
            return 0;
    }
};
