import {
    HartfordBopCoverage,
    HartfordBopCoverageLimit,
} from '@app/quote/hartford-bop/entities/BOPHartfordQuote';
import { ColumnLayout, SelectInput } from '@embroker/ui-toolkit/v2';
import { Money } from '@embroker/shotwell/core/types/Money';
import { GetCoverageLimitCode } from '@app/quote/hartford-bop/view/components/coverageMapper';
import React from 'react';

const NO_VALUE = 0;

interface Props {
    coverage: HartfordBopCoverage;
    aggregateLimit: HartfordBopCoverage;
    limit: HartfordBopCoverageLimit;
}

export function CoverageLimitValue({ coverage, limit, aggregateLimit }: Props) {
    const aggregateLimitValue = aggregateLimit.limits.find((l) => l.limit.amount !== NO_VALUE);

    if (!aggregateLimitValue) {
        return null;
    }

    return (
        <ColumnLayout>
            <SelectInput
                readOnly
                inputMode="decimal"
                items={[
                    {
                        value: limit.limit.amount,
                        title: Money.toString(limit.limit, {
                            fractionDigits: 0,
                            roundingMode: 'HALF_AWAY_FROM_ZERO',
                        }),
                    },
                ]}
                label={GetCoverageLimitCode(coverage.code)}
                value={limit.limit.amount}
            />

            <SelectInput
                readOnly
                inputMode="decimal"
                items={[
                    {
                        value: aggregateLimitValue.limit.amount,
                        title: Money.toString(aggregateLimitValue.limit, {
                            fractionDigits: 0,
                            roundingMode: 'HALF_AWAY_FROM_ZERO',
                        }),
                    },
                ]}
                label={GetCoverageLimitCode(aggregateLimit.code)}
                value={aggregateLimitValue.limit.amount}
            />
        </ColumnLayout>
    );
}
