import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { InvoiceRepository } from '../repositories/InvoiceRepository';
import { PaymentError } from '../types/errors';

export interface PayByAscendInput {
    invoiceIds: UUID[];
    userId?: UUID;
    organizationId: UUID;
}

export const PayByAscendInput = {
    ...defineValidator<PayByAscendInput>({
        invoiceIds: Joi.array().items(UUID.schema).min(1),
        userId: UUID.schema.optional(),
        organizationId: UUID.schema,
    }),
    create(inputData: PayByAscendInput) {
        return PayByAscendInput.validate(inputData);
    },
};

export interface PayByAscend extends UseCase {
    execute(
        request: PayByAscendInput,
    ): AsyncResult<string, InvalidArgument | OperationFailed | PaymentError>;
}

@injectable()
class PayByAscendUseCase extends UseCase {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Payments/PayByAscend');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(InvoiceRepository) private invoiceRepo: InvoiceRepository,
    ) {
        super(eventBus);
    }
    public async execute(
        request: PayByAscendInput,
    ): AsyncResult<string, InvalidArgument | OperationFailed | PaymentError> {
        const ascendInputData: PayByAscendInput = {
            invoiceIds: request.invoiceIds,
            userId: request.userId,
            organizationId: request.organizationId,
        };

        const ascendInput = PayByAscendInput.create(ascendInputData);

        if (isErr(ascendInput)) {
            return handleOperationFailure(ascendInput);
        }

        const createAscendProgramResult = await this.invoiceRepo.sendPaymentToAscend(
            ascendInput.value,
        );
        if (isErr(createAscendProgramResult)) {
            return handleOperationFailure(createAscendProgramResult);
        }

        return Success(createAscendProgramResult.value);
    }
}

export const PayByAscend: UseCaseClass<PayByAscend> = PayByAscendUseCase;
