import { FormElement, PageElement } from '@embroker/service-app-engine';
import { Nullable } from '@embroker/shotwell/core/types';
import { ErrorLike, isErr } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { execute } from '@embroker/shotwell/core/UseCase';
import {
    BoxLayout,
    FloatingLayout,
    StatusMessage,
    Text,
    useResponsive,
    useModal,
} from '@embroker/ui-toolkit/v2';
import React, { useMemo, useState } from 'react';
import { ErrorCode as ShoppingErrorCodes } from '../../../../../shopping/errors';
import { questionnaireToClientOrganizationData } from '../../../../../shopping/useCases/UpdateApplicantFromBusinessProfile';
import { MissingInformationModal } from '../../../../../shopping/view/components/MissingInformationModal';
import { ErrorCode as OrganizationErrorCodes } from '../../../../../userOrg/errors';
import { FormEngine } from '../../../../../view/components';
import { ProgressBarRangeType } from '../../../../../view/components/FormEngine/components/ProgressBar';
import { useFormEngineEvent } from '../../../../../view/components/FormEngine/hooks/useFormEngineEvent';
import { NavigateFunction, useNavigation } from '../../../../../view/hooks/useNavigation';
import { EditClientApplication } from '../../../../useCases/EditClientApplication';
import { UpdateClientOrganization } from '../../../../useCases/UpdateClientOrganization';
import { ExitConfirmationModal } from './ExitConfirmationModal';

interface EditApplicationProps {
    token: string;
    pageId?: string;
    formEngine: FormElement;
}

const PROGRESS_BAR_RANGE: ProgressBarRangeType = { min: 10 };
const guidanceTextBoxStyle = { maxWidth: '448px' };

export function EditApplication({ token, pageId, formEngine }: EditApplicationProps): JSX.Element {
    const { navigate } = useNavigation();
    const missingInformationModal = useModal();
    const exitConfirmationModal = useModal();

    const onConfirmExit = async () => {
        navigate(URI.build('/digital-signature', token));
    };

    const [firstInvalidPageId, setFirstInvalidPageId] = useState<Nullable<string>>(null);

    const defaultPage = useMemo(() => {
        if (pageId) {
            return null;
        }
        const {
            machine: {
                state: { pageList },
            },
        } = formEngine;

        return pageList.filter((page: PageElement) => page.canNavigateTo())[0] ?? null;
    }, [formEngine, pageId]);

    const navigateAfterModalConfirmation = () => {
        if (!firstInvalidPageId) {
            return;
        }

        formEngine.gotoPage(firstInvalidPageId);
    };

    useFormEngineEvent(formEngine, 'onbeforesubmit', () => {
        const formPagesInvalidIdList = getFormPagesInvalidIdList(formEngine);
        if (formPagesInvalidIdList.length === 0) {
            return;
        }

        setFirstInvalidPageId(formPagesInvalidIdList[0]);
        missingInformationModal.show();
    });

    useFormEngineEvent(formEngine, 'submit', () => {
        async function submitHandler() {
            const questionnaireData = formEngine.getSnapshot().value;

            // Update organization
            const updateClientOrganizationRequest = questionnaireToClientOrganizationData(
                token,
                questionnaireData as any,
            );
            const updateClientOrganizationResponse = await execute(
                UpdateClientOrganization,
                updateClientOrganizationRequest,
            );
            if (isErr(updateClientOrganizationResponse)) {
                navigateToMatchingErrorPage(
                    navigate,
                    updateClientOrganizationResponse.errors[0].code,
                );
                return;
            }

            // Edit application
            const editClientApplicationResponse = await execute(EditClientApplication, {
                token,
                questionnaireData: JSON.stringify(questionnaireData),
            });
            if (isErr(editClientApplicationResponse)) {
                navigateToMatchingErrorPage(navigate, editClientApplicationResponse.errors[0].code);
                return;
            }

            navigate('/review/application/thank-you');
        }

        submitHandler().catch((error: ErrorLike) => {
            navigateToMatchingErrorPage(navigate, error.code);
        });
    });

    // Temporary fix until small screen design for sharing renewals can be finalized
    const isMobile = useResponsive({ screenWidth: { smallerThan: 'large-tablet' } });

    return (
        <div>
            <ExitConfirmationModal modal={exitConfirmationModal} onConfirm={onConfirmExit} />
            <MissingInformationModal
                modal={missingInformationModal}
                navigateAfterModalConformation={navigateAfterModalConfirmation}
            />
            <FormEngine
                instance={formEngine}
                navigation
                page={pageId || defaultPage}
                onExitFullScreen={exitConfirmationModal.show}
                progressBarRange={PROGRESS_BAR_RANGE}
            />
            {!isMobile && (
                <FloatingLayout position="bottom">
                    <BoxLayout gap="56" style={guidanceTextBoxStyle}>
                        <StatusMessage
                            status="info"
                            onDismiss={() => {
                                // do nothing.
                            }}
                        >
                            <Text style="microcopy">
                                Please review the answers to questions asked in the application,
                                edit if anything needs to be changed and finally click on ‘Update
                                application’ on the last page of the questionnaire.
                            </Text>
                        </StatusMessage>
                    </BoxLayout>
                </FloatingLayout>
            )}
        </div>
    );
}

export function getFormPagesInvalidIdList(formEngine: FormElement) {
    const { pageList } = formEngine.machine.state;

    return pageList
        .filter((page: PageElement) => {
            const canNavigateTo = page.canNavigateTo();
            const isPageInvalid = !page.isValid();

            return canNavigateTo && isPageInvalid;
        })
        .map((page: PageElement) => page.id);
}

export function navigateToMatchingErrorPage(navigate: NavigateFunction, errorCode: number) {
    switch (errorCode) {
        case ShoppingErrorCodes.ApplicationNotFound:
        case OrganizationErrorCodes.OrganizationNotFound:
            navigate('/shareable/application/not-found');
            break;
        case ShoppingErrorCodes.OperationNotAllowed:
        case OrganizationErrorCodes.OperationNotAllowed:
            navigate('/shareable/application/not-allowed');
            break;
        default:
            navigate('/shareable/application/error');
            break;
    }
}
