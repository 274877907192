import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { DigitalEndorsementIssuedEvent } from '../types';
import { DomainEventSourceLocal } from '@embroker/shotwell/core/types/DomainEventSource';

export interface PublishDigitalEndorsementIssuedEventRequest {
    policyId: UUID;
    endorsementType: string;
}

export interface PublishDigitalEndorsementIssuedEvent extends UseCase {
    execute(request: PublishDigitalEndorsementIssuedEventRequest): AsyncResult<void>;
}

@injectable()
class PublishDigitalEndorsementIssuedEventUseCase
    extends UseCase
    implements PublishDigitalEndorsementIssuedEvent
{
    public static type = Symbol('DigitalEndorsements/PublishDigitalEndorsementIssuedEvent');

    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    public async execute(request: PublishDigitalEndorsementIssuedEventRequest): AsyncResult<void> {
        const event: DigitalEndorsementIssuedEvent = {
            origin: 'Endorsement',
            name: 'DigitalEndorsementIssued',
            createdAt: new Date(Date.now()),
            id: UUID.create(),
            source: DomainEventSourceLocal,
            policyId: request.policyId,
            endorsementType: request.endorsementType,
        };

        await this.eventBus.publish<DigitalEndorsementIssuedEvent>(event);

        return Success();
    }
}

export const PublishDigitalEndorsementIssuedEvent: UseCaseClass<PublishDigitalEndorsementIssuedEvent> =
    PublishDigitalEndorsementIssuedEventUseCase;
