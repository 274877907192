import { Aborted, InvalidArgument, OperationFailed, Timeout } from '@embroker/shotwell/core/Error';
import { Nullable } from '@embroker/shotwell/core/types';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import {
    SignatureDocumentNotAllowed,
    SignatureDocumentNotSigned,
} from '../../brokerDashboard/errors';

export type GetTaskStatusResponse = Nullable<string>;

export type GetPrintTaskStatusResponse = Nullable<string>;

export type PollForTaskStatusResponse = string;

export type PollForPrintTaskStatusResponse = string;

export type PollForSignatureDocumentTaskStatusResponse = string;

export interface TasksRepository {
    getTaskStatus(id: UUID): AsyncResult<GetTaskStatusResponse, InvalidArgument | OperationFailed>;
    getPrintTaskStatus(
        id: UUID,
    ): AsyncResult<GetPrintTaskStatusResponse, InvalidArgument | OperationFailed>;
    getSignatureDocumentTaskStatus(
        token: string,
    ): AsyncResult<
        GetTaskStatusResponse,
        InvalidArgument | OperationFailed | SignatureDocumentNotSigned | SignatureDocumentNotAllowed
    >;
    pollForTaskStatus(
        id: UUID,
        abortSignal?: AbortSignal,
        maxPollingRetries?: number,
        pollingRetryIntervalInMilliseconds?: number,
    ): AsyncResult<
        PollForTaskStatusResponse,
        InvalidArgument | OperationFailed | Aborted | Timeout
    >;
    pollForPrintTaskStatus(
        id: UUID,
        abortSignal?: AbortSignal,
        maxPollingRetries?: number,
        pollingRetryIntervalInMilliseconds?: number,
    ): AsyncResult<
        PollForPrintTaskStatusResponse,
        InvalidArgument | OperationFailed | Aborted | Timeout
    >;
    pollForSignatureDocumentTaskStatus(
        token: string,
        abortSignal?: AbortSignal,
        maxPollingRetries?: number,
        pollingRetryTimeoutInMilliseconds?: number,
    ): AsyncResult<
        PollForSignatureDocumentTaskStatusResponse,
        InvalidArgument | OperationFailed | Aborted | Timeout
    >;

    pollForSignatureDocumentSigningTaskStatus(
        signatureToken: UUID,
        abortSignal?: AbortSignal,
        maxPollingRetries?: number,
        pollingRetryTimeoutInMilliseconds?: number,
    ): AsyncResult<
        PollForSignatureDocumentTaskStatusResponse,
        | InvalidArgument
        | OperationFailed
        | Aborted
        | Timeout
        | SignatureDocumentNotSigned
        | SignatureDocumentNotAllowed
    >;
}

export const TasksRepository = Symbol('TasksRepository');
