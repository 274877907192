import { DetailsSidebar, List, Text } from '@embroker/ui-toolkit/v2';
import React, { useContext } from 'react';
import { AppContext } from '../../../view/AppContext';

export function TransferPolicySlideout() {
    const { closeSlideout } = useContext(AppContext);

    return (
        <DetailsSidebar>
            <DetailsSidebar.Header onDismiss={closeSlideout}>
                <Text style="heading 1">Upload Your Existing Policies to Embroker</Text>
                <Text>
                    Embroker makes it easier for you to view all of your business insurance policies
                    all in one place at the same time. If you need us to help you manage your
                    coverage, you can make us your broker by transferring the policy to Embroker
                    after uploading the policy documents - all with just a few clicks, and entirely
                    free of charge.
                </Text>
            </DetailsSidebar.Header>
            <DetailsSidebar.Body>
                <Text style="heading 4">All of Your Policies At A Glance</Text>
                <Text>
                    By uploading a PDF of your existing policies, you can have all of your business
                    insurance visible in one place for you to review. The original broker who bound
                    the policy will still manage that policy for you even if you upload it here, so
                    nothing will be changed - it's just a display of information.
                </Text>
                <Text style="heading 4">
                    How Does Transferring a Policy to Embroker Work, and Why Do It?
                </Text>
                <Text>
                    The broker who currently manages a specific insurance policy is referred to as
                    the Broker of Record. After uploading the policy PDF, click the &quot;Transfer
                    to Embroker&quot; button to digitally sign a Broker of Record letter for that
                    policy. If your insurer has a carrier appointment with Embroker, your policy is
                    eligible for a transfer. If we cannot accept a BOR transfer, our team will work
                    with you to develop a different strategy to address your risk management needs.
                    Benefits of transferring include:
                </Text>
                <List>
                    <List.Item key="1">
                        Convenience - no need to go back and forth between brokers for help managing
                        different policies
                    </List.Item>
                    <List.Item>Transfer without a disruption to your insurance package</List.Item>
                    <List.Item key="2">
                        You can renew your old policies and modify the coverage details all within
                        our platform
                    </List.Item>
                </List>
            </DetailsSidebar.Body>
        </DetailsSidebar>
    );
}
