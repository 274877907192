export const CertificateTemplateTypeMasterEOC = 'CertificateTemplateTypeMasterEOC';
export const CertificateTemplateTypeMasterAIPNC = 'CertificateTemplateTypeMasterAIPNC';
export const CertificateTemplateTypeMasterWOS = 'CertificateTemplateTypeMasterWOS';
export const CertificateTemplateTypeMasterFull = 'CertificateTemplateTypeMasterFull';
export const CertificateTemplateTypeCustom = 'CertificateTemplateTypeCustom';

export const CertificateTemplateTypeList = [
    CertificateTemplateTypeMasterEOC,
    CertificateTemplateTypeMasterAIPNC,
    CertificateTemplateTypeMasterWOS,
    CertificateTemplateTypeMasterFull,
    CertificateTemplateTypeCustom,
] as const;

type CertificateTemplateTypeList = typeof CertificateTemplateTypeList;

export type CertificateTemplateType = CertificateTemplateTypeList[number];
