import { AppTypeCodeListItem } from '@embroker/shotwell-api/enums';
import { container } from '@embroker/shotwell/core/di';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { ErrorLike, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { createForm } from '@embroker/shotwell/view/hooks/useForm';
import { Immutable, Nullable } from '@embroker/ui-toolkit/v2';
import { ShoppingCoverageCodeArray } from '../../../../shopping/types/ShoppingCoverageCodeArray';
import { CreateApplicationIntake } from '../../../../shopping/useCases/CreateApplicationIntake';
import {
    AppTypeCode,
    AppTypeCodeListEmbrokerCrime,
    AppTypeCodeListEmbrokerCyber,
    AppTypeCodeListEmbrokerExcess,
    AppTypeCodeListESP,
    AppTypeCodeListEverestLawyersProfessionalLiability,
    AppTypeCodeListPCoML,
    ShoppingCoverageCodeList,
    ShoppingCoverageCodeListCrime,
    ShoppingCoverageCodeListCyber,
    ShoppingCoverageCodeListDirectorsAndOfficers,
    ShoppingCoverageCodeListEmploymentPractices,
    ShoppingCoverageCodeListFiduciary,
    ShoppingCoverageCodeListProfessionalLiability,
} from '@app/shopping/types/enums';
import { GetApplicationShareToken } from '../../../../shopping/useCases/GetApplicationShareToken';
import { AddNewOrganization } from '../../../../userOrg/useCases/AddNewOrganization';
import { SelectOrganization } from '../../../../userOrg/useCases/SelectOrganization';
import { PrecreateApplication } from '../../../useCases/PrecreateApplication';
import { DO, DOAndEPL, EPL } from './BrokerSelectProductForm';

export enum BrokerCoverageType {
    ESP = 'Startup Package',
    PCOML = 'Private Company Management Liability',
    EmbrokerExcess = 'Excess Tech E&O/Cyber',
    LPL = 'Lawyers Professional Liability',
    Crime = 'Commercial Crime',
    Cyber = 'Cyber',
}

export const mapBrokerCoverageToAppTypeItem = new Map<BrokerCoverageType, AppTypeCodeListItem>([
    [BrokerCoverageType.ESP, AppTypeCodeListESP],
    [BrokerCoverageType.PCOML, AppTypeCodeListPCoML],
    [BrokerCoverageType.EmbrokerExcess, AppTypeCodeListEmbrokerExcess],
    [BrokerCoverageType.LPL, AppTypeCodeListEverestLawyersProfessionalLiability],
    [BrokerCoverageType.Crime, AppTypeCodeListEmbrokerCrime],
    [BrokerCoverageType.Cyber, AppTypeCodeListEmbrokerCyber],
]);

const lawFirmNAICSCode = '541110';

export interface BrokerOrganizationItem {
    id: Nullable<UUID>;
    name: string;
}

export interface BrokerSelectProductFormData {
    company: BrokerOrganizationItem;
    coverageType: BrokerCoverageType;
    cyberAndTechEO: boolean;
    pcomlCoverage: string;
    applicationIntake?: string;
    submissionFiles: boolean;
    additionalData: boolean;
    naics: string;
    shoppingCoverageList: Immutable<ShoppingCoverageCodeArray>;
    applicationId?: UUID;
}

export interface SelectProductResult {
    applicationId: UUID;
    applicationType: AppTypeCode;
    applicationToken: string;
    shoppingCoverageList: ShoppingCoverageCodeArray;
}

export interface CreateApplicationIntakeResult {
    applicationId: UUID;
    taskId: UUID;
}

export interface createBrokerSelectProductFormProps {
    abortController: AbortController;
}

export const createBrokerSelectProductForm = ({
    abortController,
}: createBrokerSelectProductFormProps) => {
    return createForm<BrokerSelectProductFormData>({
        fields: {
            company: {
                type: 'comboboxasync',
                validator: Joi.object()
                    .keys({
                        id: UUID.schema.allow(null),
                        name: Joi.string().required(),
                    })
                    .required(),
                formatValidationError() {
                    return 'You must provide an answer.';
                },
            },
            coverageType: {
                type: 'select',
                validator: Joi.string().required(),
                formatValidationError() {
                    return 'You must provide an answer.';
                },
            },
            cyberAndTechEO: {
                type: 'select',
                validator: Joi.boolean(),
                formatValidationError(error) {
                    if (error.details.validator === 'any.required') {
                        return 'You must provide an answer.';
                    } else {
                        return error.message;
                    }
                },
            },
            pcomlCoverage: {
                type: 'select',
                validator: Joi.string(),
                formatValidationError(error) {
                    if (error.details.validator === 'any.required') {
                        return 'You must provide an answer.';
                    } else {
                        return error.message;
                    }
                },
            },
            applicationIntake: {
                type: 'hidden',
                validator: Joi.string().optional(),
            },
            submissionFiles: {
                type: 'hidden',
                validator: Joi.boolean(),
            },
            additionalData: {
                type: 'hidden',
                validator: Joi.boolean(),
            },
            naics: {
                type: 'hidden',
                validator: Joi.string().optional(),
            },
            shoppingCoverageList: {
                type: 'hidden',
                validator: Joi.array().items(Joi.string().valid(...ShoppingCoverageCodeList)),
            },
            applicationId: {
                type: 'hidden',
                validator: UUID.schema.optional(),
            },
        },
        validator: Joi.object().keys({
            company: Joi.object()
                .keys({
                    id: UUID.schema.allow(null),
                    name: Joi.string().required(),
                })
                .required(),
            coverageType: Joi.string(),
            cyberAndTechEO: Joi.boolean().when('coverageType', {
                switch: [
                    {
                        is: Joi.valid(
                            BrokerCoverageType.LPL,
                            BrokerCoverageType.PCOML,
                            BrokerCoverageType.EmbrokerExcess,
                            BrokerCoverageType.Crime,
                            BrokerCoverageType.Cyber,
                        ).required(),
                        then: Joi.any(),
                    },
                    {
                        is: Joi.valid(BrokerCoverageType.ESP).required(),
                        then: Joi.required(),
                    },
                ],
            }),
            pcomlCoverage: Joi.string().when('coverageType', {
                switch: [
                    {
                        is: Joi.valid(
                            BrokerCoverageType.LPL,
                            BrokerCoverageType.ESP,
                            BrokerCoverageType.Crime,
                            BrokerCoverageType.Cyber,
                            BrokerCoverageType.EmbrokerExcess,
                        ).required(),
                        then: Joi.any(),
                    },
                    {
                        is: Joi.valid(BrokerCoverageType.PCOML).required(),
                        then: Joi.required(),
                    },
                ],
            }),
            applicationIntake: Joi.string().optional(),
            naics: Joi.string(),
            shoppingCoverageList: Joi.array().optional(),
        }),
        actions: {
            selectProduct: {
                action: async ({
                    company,
                    coverageType,
                    cyberAndTechEO,
                    pcomlCoverage,
                }: BrokerSelectProductFormData) => {
                    let organizationId: UUID;
                    let naicsCode: string | undefined;

                    const isEspApp = coverageType === BrokerCoverageType.ESP;

                    if (company.id === null) {
                        const addOrganizationResult = await execute(AddNewOrganization, {
                            companyLegalName: company.name,
                            raisedFunding: isEspApp ? true : undefined,
                        });
                        if (isErr(addOrganizationResult)) {
                            return addOrganizationResult;
                        }
                        organizationId = addOrganizationResult.value.organizationId;
                    } else {
                        organizationId = company.id;
                    }

                    const selectOrganizationResult = await execute(SelectOrganization, {
                        organizationId,
                    });
                    if (isErr(selectOrganizationResult)) {
                        return selectOrganizationResult;
                    }

                    let shoppingCoverageList: ShoppingCoverageCodeArray = [];

                    if (coverageType === BrokerCoverageType.LPL) {
                        shoppingCoverageList = [ShoppingCoverageCodeListProfessionalLiability];
                        naicsCode = lawFirmNAICSCode;
                    } else if (coverageType === BrokerCoverageType.ESP) {
                        shoppingCoverageList = [
                            ShoppingCoverageCodeListDirectorsAndOfficers,
                            ShoppingCoverageCodeListEmploymentPractices,
                            ShoppingCoverageCodeListFiduciary,
                        ];
                        if (cyberAndTechEO) {
                            shoppingCoverageList.push(ShoppingCoverageCodeListCyber);
                            shoppingCoverageList.push(
                                ShoppingCoverageCodeListProfessionalLiability,
                            );
                        }
                    } else if (coverageType === BrokerCoverageType.PCOML) {
                        if (pcomlCoverage === DO) {
                            shoppingCoverageList = [ShoppingCoverageCodeListDirectorsAndOfficers];
                        } else if (pcomlCoverage === EPL) {
                            shoppingCoverageList = [ShoppingCoverageCodeListEmploymentPractices];
                        } else if (pcomlCoverage === DOAndEPL) {
                            shoppingCoverageList = [
                                ShoppingCoverageCodeListDirectorsAndOfficers,
                                ShoppingCoverageCodeListEmploymentPractices,
                            ];
                        }
                    } else if (coverageType === BrokerCoverageType.Crime) {
                        shoppingCoverageList = [ShoppingCoverageCodeListCrime];
                    } else if (coverageType === BrokerCoverageType.Cyber) {
                        shoppingCoverageList = [ShoppingCoverageCodeListCyber];
                    } else if (coverageType == BrokerCoverageType.EmbrokerExcess) {
                        shoppingCoverageList = [
                            ShoppingCoverageCodeListProfessionalLiability,
                            ShoppingCoverageCodeListCyber,
                        ];
                    }

                    const appType = mapBrokerCoverageToAppTypeItem.get(coverageType);

                    if (appType === undefined) {
                        return Failure(
                            InvalidArgument({
                                argument: 'Unable to resolve coverage type to app type',
                                value: coverageType,
                            }),
                        );
                    }
                    const precreateApplicationsResponse = await execute(PrecreateApplication, {
                        appType: appType,
                        shoppingCoverageCodeList: shoppingCoverageList,
                        questionnaireData: {
                            naics_code: naicsCode,
                            company_name: company.name,
                        },
                    });

                    if (isErr(precreateApplicationsResponse)) {
                        container.get<Logger>(Log).error(precreateApplicationsResponse.errors);
                        return precreateApplicationsResponse;
                    }

                    const applicationId = precreateApplicationsResponse.value.applicationId;

                    const getApplicationShareTokenResponse = await execute(
                        GetApplicationShareToken,
                        {
                            applicationId,
                        },
                    );
                    if (isErr(getApplicationShareTokenResponse)) {
                        return getApplicationShareTokenResponse;
                    }
                    return Success<SelectProductResult>({
                        applicationId,
                        applicationType: appType,
                        applicationToken: getApplicationShareTokenResponse.value.shareToken,
                        shoppingCoverageList,
                    });
                },
                fields: ['company', 'coverageType', 'cyberAndTechEO', 'pcomlCoverage', 'naics'],
            },

            createApplicationIntake: {
                action: async ({
                    applicationIntake,
                    coverageType,
                    shoppingCoverageList,
                    applicationId,
                }: BrokerSelectProductFormData) => {
                    if (applicationIntake == undefined) {
                        return Failure(
                            InvalidArgument({
                                argument: 'application intake url',
                                value: applicationIntake,
                            }),
                        );
                    }
                    const intakeAppType = mapBrokerCoverageToAppTypeItem.get(coverageType);
                    if (intakeAppType === undefined) {
                        return Failure(
                            InvalidArgument({ argument: 'appType', value: intakeAppType }),
                        );
                    }

                    const CreateApplicationIntakeResult = await execute(CreateApplicationIntake, {
                        appType: intakeAppType,
                        shoppingCoverageList: [...shoppingCoverageList],
                        fileUrl: applicationIntake,
                        applicationId,
                    });

                    if (isErr(CreateApplicationIntakeResult)) {
                        return CreateApplicationIntakeResult;
                    }
                    return Success<CreateApplicationIntakeResult>(
                        CreateApplicationIntakeResult.value,
                    );
                },
                fields: ['applicationIntake', 'coverageType', 'shoppingCoverageList'],
            },
        },
        onFailure: (errors: Immutable<ErrorLike[]>) => {
            console.error(errors);
        },
    });
};
