import { injectable, inject } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { execute } from '@embroker/shotwell/core/UseCase';
import * as cookie from 'cookie';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { AppContextStore, AppContext } from '@app/view/AppContext';
import { GetActiveUserProfile } from '@app/userOrg/useCases/GetActiveUserProfile';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Nullable } from '@embroker/ui-toolkit/v2';
import { GetOrganizationProfile } from '@app/userOrg/useCases/GetOrganizationProfile';
import { NAICS_CODE_TO_VERTICAL, Vertical } from '@app/userOrg/types/enums';
import { GetApplicationList } from '@app/shopping/useCases/GetApplicationList';
import { ApplicationRepository } from '@app/shopping/repositories/ApplicationRepository';
import { State } from '@embroker/shotwell/core/types/StateList';
import {
    AppTypeCode,
    InsuranceApplicationStatusCode,
    ShoppingCoverage,
} from '@app/shopping/types/enums';
import { Money } from '@embroker/shotwell/core/types/Money';
import { URI } from '@embroker/shotwell/core/types/URI';

/**
 * Marketing data shapes below. Don't change the data shapes without coordinating with Marketing.
 * ▼▼▼▼▼▼▼▼▼▼▼▼▼
 */

type CookieSnapshotData = {
    user?: UserSnapshot;
    organization?: OrganizationSnapshot;
    session?: SessionSnapshot;
    applications?: ApplicationsSnapshot;
};

interface SessionSnapshot {
    lastVisit: number;
}

interface UserSnapshot {
    firstName: Nullable<string>;
    lastName: Nullable<string>;
    isBroker: Nullable<boolean>;
}

interface OrganizationSnapshot {
    name: Nullable<string>;
    naics: Nullable<string>;
    vertical: Nullable<Vertical>;
    website: Nullable<string>;
    stateCode: Nullable<State>;
}

interface ApplicationsSnapshot {
    [key: UUID]: ApplicationSnapshot;
}

interface ApplicationSnapshot {
    id: UUID;
    type: AppTypeCode;
    quote?: {
        daysToExpiration?: Nullable<number>;
        premium?: Nullable<Money>;
    };
    dateStarted: Nullable<number>;
    dateSubmitted: Nullable<number>;
    coverages?: ShoppingCoverage[];
    status: InsuranceApplicationStatusCode;
    url: Nullable<URI>;
}

/**
 * ▲▲▲▲▲▲▲▲▲▲▲▲▲
 * Marketing data shapes below. Don't change the data shapes without coordinating with Marketing.
 */

type AppState = {
    userId: Nullable<UUID>;
    organizationId: Nullable<UUID>;
};

@injectable()
export class CookieSnapshotService {
    private snapshotCookieName = '_embrokerAppSnapshot';
    private sessionState: AppState = {
        userId: null,
        organizationId: null,
    };

    constructor(
        @inject(DomainEventBus) private eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
    ) {
        // Subscribe to App Store
        AppContextStore.subscribe((context) => this.handleAppContextChange(context));

        // Subscribe to Event Bus
        this.subscribeToEventBus();
    }

    private async handleAppContextChange(newContext: AppContext) {
        const newContextUserId = newContext.activeSession.userId;
        const newContextOrganizationId = newContext.activeSession.organizationId;

        // If userId and organizationId references have changed, reset cookie
        if (
            newContextUserId !== this.sessionState?.userId ||
            newContextOrganizationId !== this.sessionState?.organizationId
        ) {
            this.resetCookie();
        }

        // Reinitialize session state with latest userId and organizationId
        this.sessionState = {
            userId: newContextUserId,
            organizationId: newContextOrganizationId,
        };

        // If userId and organizationId references are valid, fetch assoiated data
        if (newContextUserId && newContextOrganizationId) {
            this.setSnapshot('session', this.fetchSessionData());
            this.setSnapshot('user', await this.fetchUserData());
            this.setSnapshot('organization', await this.fetchOrganizationData());
            this.setSnapshot('applications', await this.fetchApplicationsData());
        }
    }

    private subscribeToEventBus() {
        this.eventBus.subscribe(async (event: any) => {
            switch (event.origin) {
                // Organization events
                case 'Organization': {
                    if (['Updated'].includes(event.name)) {
                        this.setSnapshot('organization', await this.fetchOrganizationData());
                    }
                    break;
                }

                case 'User': {
                    if (['Updated'].includes(event.name)) {
                        this.setSnapshot('user', await this.fetchUserData());
                    }

                    break;
                }

                case 'Application': {
                    if (
                        [
                            'Submitted',
                            'QuoteCreated',
                            'NotEligible',
                            'ApplicationCreated',
                            'SubmittedForReview',
                            'Deleted',
                            'Referred',
                        ].includes(event.name)
                    ) {
                        const applicationId = (event?.applicationId || event?.id) as UUID;
                        const application = await this.fetchApplicationData(applicationId);

                        if (application?.id) {
                            this.updateSnapshot('applications', { [application.id]: application });
                        }
                    }

                    break;
                }
            }
        });
    }

    private fetchSessionData(): SessionSnapshot {
        return {
            lastVisit: Date.now(),
        };
    }

    private async fetchUserData(): Promise<Nullable<UserSnapshot>> {
        const getActiveUserProfileResponse = await execute(GetActiveUserProfile);

        if (isOK(getActiveUserProfileResponse)) {
            const userData = getActiveUserProfileResponse.value;

            return {
                firstName: userData.firstName,
                lastName: userData.lastName,
                isBroker: userData.isBroker,
            };
        }

        return null;
    }

    private async fetchOrganizationData(): Promise<Nullable<OrganizationSnapshot>> {
        const organizationId = this.sessionState.organizationId;

        if (organizationId) {
            const getOrganizationsForUserResponse = await execute(GetOrganizationProfile, {
                organizationId,
            });

            if (isOK(getOrganizationsForUserResponse)) {
                const organizationData = getOrganizationsForUserResponse.value.organization;

                return {
                    name: organizationData.companyLegalName,
                    naics: organizationData.naics,
                    vertical: organizationData.naics
                        ? NAICS_CODE_TO_VERTICAL[organizationData.naics]
                        : null,
                    website: organizationData.website,
                    stateCode: organizationData?.headquarters?.state,
                };
            }
        }

        return null;
    }

    private async fetchApplicationsData(): Promise<Nullable<ApplicationsSnapshot>> {
        const getApplicationListResponse = await execute(GetApplicationList);

        if (isOK(getApplicationListResponse)) {
            const applicationListData = getApplicationListResponse.value.applicationList;

            const applicationListDataPromises = applicationListData.map(async (application) => {
                return await this.fetchApplicationData(application.id);
            });

            const applications = (await Promise.allSettled(applicationListDataPromises))
                .filter(
                    (
                        promise: PromiseSettledResult<Nullable<ApplicationSnapshot>>,
                    ): promise is PromiseFulfilledResult<Nullable<ApplicationSnapshot>> =>
                        promise.status === 'fulfilled',
                )
                .map((application) => application?.value)
                .filter((application): application is ApplicationSnapshot => application !== null)
                .reduce((acc: ApplicationsSnapshot, application) => {
                    acc[application.id] = application;

                    return acc;
                }, {});

            return applications;
        }

        return null;
    }

    private async fetchApplicationData(
        applicationId: UUID,
    ): Promise<Nullable<ApplicationSnapshot>> {
        const getApplicationResponse = await this.applicationRepository.getApplication(
            applicationId,
        );

        if (isOK(getApplicationResponse)) {
            const application = getApplicationResponse.value;

            return {
                id: application.id,
                type: application.appType,
                quote: {
                    daysToExpiration: application.daysToQuoteExpiration,
                    premium: application.lastQuote?.totalPremium,
                },
                dateStarted: application.startedDate?.getMilliseconds() ?? null,
                dateSubmitted: application.submittedDate?.getMilliseconds() ?? null,
                coverages: application.quotableShoppingCoverageList?.map((coverage) => coverage),
                status: application.status,
                url: application.getUrl(),
            };
        }

        return null;
    }

    /** Update snapshot value for a given key. This will not perform a full overwrite */
    private updateSnapshot<Key extends keyof CookieSnapshotData>(
        key: Key,
        newCookieData: Nullable<CookieSnapshotData[Key]>,
    ): boolean {
        const previousCookieData = this.readCookie();

        this.setSnapshot(key, {
            ...previousCookieData[key],
            ...newCookieData,
        });

        return true;
    }

    /** Replace full snapshot value for given key */
    private setSnapshot<Key extends keyof CookieSnapshotData>(
        key: Key,
        newCookieData: Nullable<CookieSnapshotData[Key]>,
    ): boolean {
        const previousCookieData = this.readCookie();

        const nextCookieData = {
            ...previousCookieData,
            [key]: newCookieData,
        };

        this.writeCookie(nextCookieData);

        return true;
    }

    private writeCookie(cookieData: CookieSnapshotData = {}): boolean {
        document.cookie = cookie.serialize(this.snapshotCookieName, JSON.stringify(cookieData), {
            path: '/',
        });

        return true;
    }

    private readCookie(): CookieSnapshotData {
        try {
            const parsedCookie = cookie.parse(document.cookie);

            return JSON.parse(parsedCookie[this.snapshotCookieName]);
        } catch (e) {
            return {};
        }
    }

    private resetCookie(): boolean {
        this.writeCookie({});

        return true;
    }
}
