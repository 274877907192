import { StructuralComponentTypeCodeListItem } from '@embroker/shotwell-api/enums';

export type ESPEndorsementLiabilityCoverageType =
    | 'LiabilityCoverageCodeListIndemnifiableDirectorsAndOfficersLiability'
    | 'LiabilityCoverageCodeListEmploymentPracticesLiability'
    | 'LiabilityCoverageCodeListFiduciaryLiability'
    | 'LiabilityCoverageCodeListTechnologyAndMediaErrorsAndOmissions'
    | 'LiabilityCoverageCodeListTechSplit'
    | 'LiabilityCoverageCodeListCyberSplit';

// Add Coverage not allowed for EO
export type AddESPEndorsementLiabilityCoverageType =
    | 'LiabilityCoverageCodeListIndemnifiableDirectorsAndOfficersLiability'
    | 'LiabilityCoverageCodeListEmploymentPracticesLiability'
    | 'LiabilityCoverageCodeListFiduciaryLiability';

// Edit Coverage not allowed for PL and Fiduciary
export type EditESPEndorsementLiabilityCoverageType = Exclude<
    ESPEndorsementLiabilityCoverageType,
    'LiabilityCoverageCodeListFiduciaryLiability'
>;

export const ESPEndorsementLiabilityCoverageTypesList: Array<ESPEndorsementLiabilityCoverageType> =
    [
        'LiabilityCoverageCodeListIndemnifiableDirectorsAndOfficersLiability',
        'LiabilityCoverageCodeListEmploymentPracticesLiability',
        'LiabilityCoverageCodeListFiduciaryLiability',
        'LiabilityCoverageCodeListTechnologyAndMediaErrorsAndOmissions',
    ];

export const ESPEndorsementCoverageSectionLiabilityMap: Map<
    StructuralComponentTypeCodeListItem,
    ESPEndorsementLiabilityCoverageType
> = new Map([
    [
        'StructuralComponentTypeCodeListDirectorsAndOfficersLiabilitySection',
        'LiabilityCoverageCodeListIndemnifiableDirectorsAndOfficersLiability',
    ],
    [
        'StructuralComponentTypeCodeListEmploymentPracticesLiabilitySection',
        'LiabilityCoverageCodeListEmploymentPracticesLiability',
    ],
    [
        'StructuralComponentTypeCodeListFiduciaryLiabilitySection',
        'LiabilityCoverageCodeListFiduciaryLiability',
    ],
    [
        'StructuralComponentTypeCodeListTechEOCyberLiabilitySection',
        'LiabilityCoverageCodeListTechnologyAndMediaErrorsAndOmissions',
    ],
]);

export const AddESPEndorsementLiabilityCoverageTypesList: Array<AddESPEndorsementLiabilityCoverageType> =
    [
        'LiabilityCoverageCodeListEmploymentPracticesLiability',
        'LiabilityCoverageCodeListIndemnifiableDirectorsAndOfficersLiability',
        'LiabilityCoverageCodeListFiduciaryLiability',
    ];

export const EditESPEndorsementLiabilityCoverageTypesList: Array<EditESPEndorsementLiabilityCoverageType> =
    [
        'LiabilityCoverageCodeListIndemnifiableDirectorsAndOfficersLiability',
        'LiabilityCoverageCodeListTechnologyAndMediaErrorsAndOmissions',
    ];

export const ESPEndorsementCoverageTypeMap: { [key: string]: string } = {
    LiabilityCoverageCodeListIndemnifiableDirectorsAndOfficersLiability: 'Directors & Officers',
    LiabilityCoverageCodeListEmploymentPracticesLiability: 'Employment Practices Liability',
    LiabilityCoverageCodeListFiduciaryLiability: 'Fiduciary',
    LiabilityCoverageCodeListTechnologyAndMediaErrorsAndOmissions:
        'Errors and Omissions/Cyber Liability',
};
