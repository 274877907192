import { Money } from '@embroker/shotwell/core/types/Money';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface WorkersCompensationLocationGroup {
    groupName: string;
    classificationCode: string;
    estimateAnnualPayroll: Money;
    ratingAmount: Money;
    ratingRate: number;
}

export const WorkersCompensationLocationGroup = {
    ...defineValidator<WorkersCompensationLocationGroup>({
        groupName: Joi.string(),
        classificationCode: Joi.string(),
        estimateAnnualPayroll: Money.schema,
        ratingAmount: Money.schema,
        ratingRate: Joi.number(),
    }),
    create(workersCompensationLocationGroup: WorkersCompensationLocationGroup) {
        return WorkersCompensationLocationGroup.validate(workersCompensationLocationGroup);
    },
};
