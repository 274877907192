import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { createForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    Button,
    ColumnLayout,
    Form,
    Immutable,
    StackLayout,
    Text,
    TextAreaInput,
    TextButton,
    WizardLayout,
} from '@embroker/ui-toolkit/v2';
import { isSameDay, isValid, startOfDay, startOfToday } from 'date-fns';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FileUpload } from '../../../../../documents/view/FileUpload';
import {
    getCrimeLimitOptions,
    getCrimeRetentionOptions,
} from '../../../../../quote/crime/view/components/limitRentetionOptions';
import {
    getCyberLimitOptions,
    getCyberRetentionOptions,
} from '../../../../../quote/cyber/view/components/limitRentetionOptions';
import { useWizardForm } from '../../../../../view/hooks/useWizardForm';
import { IntakeAdditionalInfo } from '../../../../useCases/IntakeAdditionalInfo';
import {
    BrokerCoverageType,
    mapBrokerCoverageToAppTypeItem,
} from '../createBrokerSelectProductForm';
import { AdditionalInfoProps } from './MlAdditionalInfo';

interface CyberCrimeAdditionalInfoFormData {
    applicationId: UUID;
    additionalFiles: Immutable<FileUpload[]>;
    cyberCrimeRetention: number;
    cyberCrimeLimit: number;
    effectiveDate: Date;
    additionalInfo: string;
}

interface AdditionalInfoFormParams {
    coverageType: BrokerCoverageType;
}

function createAdditionalInfoForm({ coverageType }: AdditionalInfoFormParams) {
    return createForm<CyberCrimeAdditionalInfoFormData>({
        fields: {
            applicationId: {
                type: 'hidden',
                validator: UUID.schema,
            },
            additionalFiles: {
                type: 'hidden',
                validator: Joi.array(),
            },
            cyberCrimeRetention: {
                type: 'select',
                validator: Joi.number().required(),
                formatValidationError: (error) => {
                    switch (error.details.validator) {
                        case 'any.required':
                            return 'You must select retention.';
                        default:
                            return error.message;
                    }
                },
            },
            cyberCrimeLimit: {
                type: 'select',
                validator: Joi.number().required(),
                formatValidationError: (error) => {
                    switch (error.details.validator) {
                        case 'any.required':
                            return 'You must select limit.';
                        default:
                            return error.message;
                    }
                },
            },
            effectiveDate: {
                type: 'date',
                validator: Joi.date().min(startOfToday()).required(),
                formatValidationError: (error) => {
                    switch (error.details.validator) {
                        case 'date.min':
                            return 'Date cannot be in the past.';
                        case 'any.required':
                            return 'You must enter a date.';
                        default:
                            return error.message;
                    }
                },
            },
            additionalInfo: {
                type: 'textarea',
                validator: Joi.string().optional(),
            },
        },
        formatSubmitErrors(errors) {
            if (errors.length > 0) {
                return ['Oops! Something went wrong. Please try again later.'];
            }

            return [];
        },
        submit: async (data: CyberCrimeAdditionalInfoFormData) => {
            const fileKeys: string[] = [];
            data.additionalFiles.forEach((file) => {
                if (file.s3FileKey !== null) {
                    fileKeys.push(file.s3FileKey);
                }
            });
            const appType = mapBrokerCoverageToAppTypeItem.get(coverageType);
            if (appType !== undefined) {
                const result = await execute(IntakeAdditionalInfo, {
                    appType: appType,
                    additionalData: {
                        isDnoSelected: false,
                        isEplSelected: false,
                        isFiduciarySelected: false,
                        isEnoSelected: false,
                        cyberCrimeLimit: data.cyberCrimeLimit,
                        cyberCrimeRetention: data.cyberCrimeRetention,
                        effectiveDate: data.effectiveDate,
                        additionalInfo: data.additionalInfo,
                    },
                    applicationId: data.applicationId,
                    additionalFiles: fileKeys,
                });

                if (isErr(result)) {
                    container.get<Logger>(Log).error(result.errors);
                }
            }

            return Success();
        },
    });
}

export function CyberCrimeAdditionalInfo({
    coverageType,
    filesUploaded,
    openSuccessModal,
    firstBackAction,
    appId,
    ...props
}: AdditionalInfoProps) {
    const additionalInfoForm = useMemo(() => {
        return createAdditionalInfoForm({
            coverageType: coverageType,
        });
    }, [coverageType]);

    const { value, fields, setValue, submit, status } = useWizardForm(additionalInfoForm, {
        pages: [
            {
                name: 'dateInfo',
                fields: [
                    'cyberCrimeRetention',
                    'cyberCrimeLimit',
                    'effectiveDate',
                    'additionalInfo',
                ],
            },
        ],
        keepUrl: true,
    });

    useEffect(() => {
        setValue({
            ...value,
            applicationId: appId,
            additionalFiles: filesUploaded,
        });
    }, [appId, setValue, value, filesUploaded]);

    useEffect(() => {
        if (status === 'submitted') {
            openSuccessModal();
        }
    }, [openSuccessModal, status]);

    const handleEffectiveDateChange = useCallback(
        (event: { target: { value: string; date: Date } }) => {
            const newDate = startOfDay(event.target.date);
            const isNotDateValid =
                !isValid(newDate) ||
                (value.effectiveDate !== null && isSameDay(value.effectiveDate, newDate));
            if (isNotDateValid) {
                return;
            }

            setValue({
                ...value,
                effectiveDate: newDate,
            });
        },
        [setValue, value],
    );

    return (
        <StackLayout gap="8" {...props}>
            <Text style="heading 4">Additional information required</Text>
            <StackLayout gap="8">
                <Text style="default">
                    Please answer following questions in order to provide you with the desired quote
                    option:
                </Text>
                <StackLayout gap="32">
                    <StackLayout gap="8">
                        <ColumnLayout>
                            <Form.Field
                                inputProps={{
                                    items:
                                        coverageType === BrokerCoverageType.Crime
                                            ? getCrimeLimitOptions()
                                            : getCyberLimitOptions(),
                                    value: fields.cyberCrimeLimit.props.value,
                                    onChange: (event: { target: { value: number } }) => {
                                        setValue({
                                            ...value,
                                            cyberCrimeLimit: event.target.value,
                                        });
                                    },
                                }}
                                type={fields.cyberCrimeLimit.type}
                                label="Limit"
                                messages={fields.cyberCrimeLimit.messages}
                            />
                            <Form.Field
                                inputProps={{
                                    items:
                                        coverageType === BrokerCoverageType.Crime
                                            ? getCrimeRetentionOptions()
                                            : getCyberRetentionOptions(),
                                    value: fields.cyberCrimeRetention.props.value,
                                    onChange: (event: { target: { value: number } }) => {
                                        setValue({
                                            ...value,
                                            cyberCrimeRetention: event.target.value,
                                        });
                                    },
                                }}
                                type={fields.cyberCrimeRetention.type}
                                label="Retention"
                                messages={fields.cyberCrimeRetention.messages}
                            />
                        </ColumnLayout>
                        <Form.Field
                            inputProps={{
                                value: fields.effectiveDate.props.value,
                                onChange: handleEffectiveDateChange,
                            }}
                            type={fields.effectiveDate.type}
                            label="Desired effective Date"
                            messages={fields.effectiveDate.messages}
                        />
                        <TextAreaInput
                            label="Additional information (optional)"
                            {...fields.additionalInfo.props}
                        />
                    </StackLayout>
                    <WizardLayout.Actions>
                        <Button onClick={submit}>{'Finish'}</Button>
                        <TextButton onClick={firstBackAction}>Back</TextButton>
                    </WizardLayout.Actions>
                </StackLayout>
            </StackLayout>
        </StackLayout>
    );
}
