import { API } from '@embroker/shotwell-api/app';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { InsuranceApplicationRepository } from '.';

@injectable()
export class APIInsuranceApplicationRepository implements InsuranceApplicationRepository {
    public async loadQuotableCoverages(
        insuranceApplicationId: UUID,
    ): AsyncResult<string[], InvalidArgument | OperationFailed> {
        const applicationResponse = await API.request('shopping/application', {
            id: insuranceApplicationId,
        });
        if (isErr(applicationResponse)) {
            return Failure(
                OperationFailed({
                    message: 'Failed to get application',
                    errors: applicationResponse.errors,
                }),
            );
        }

        const application = applicationResponse.value;
        const quotableShoppingCoverageList = application.quotable_shopping_coverage_list || [];

        return Success(quotableShoppingCoverageList);
    }
}
