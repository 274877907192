import {
    defineValidator,
    Joi,
    Schema,
    TypeChecker,
    Validator,
} from '@embroker/shotwell/core/validation/schema';

// This is a temporary implementation because we lack there enums on backend
// I am aware that you can edit individual characters in these strings but for now they will suffice
export const BorStatusNotBor = 'not_b_o_r';
export const BorStatusPendingBor = 'pending_b_o_r';
export const BorStatusIsBor = 'b_o_r';

const BorStatusList = [BorStatusNotBor, BorStatusPendingBor, BorStatusIsBor];

/**
 * Value Object representing an bot status
 */
export type BorStatus = (typeof BorStatusList)[number];

export interface BorStatusValidator {
    /**
     * A Joi schema matching a valid BorStatus object.
     */
    readonly schema: Schema.StringSchema;
    /**
     * Type predicate that checks if a given value can be used as BorStatus type.
     *
     * Use this only to do early returns. It's recommended to use validate()
     * before using an unknown value as BorStatus value as it normalizes the value
     * in addition to performing the same validation as check().
     */
    readonly check: TypeChecker<BorStatus>;
    /**
     * Validates and normalizes the given value to a BorStatus type.
     *
     * This should be used for all untrusted inputs to verify and, if required
     * (and possible), normalize the input.
     */
    readonly validate: Validator<BorStatus>;
}

export const BorStatus: BorStatusValidator = defineValidator<BorStatus>(
    Joi.string().valid(...BorStatusList),
);
