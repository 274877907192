import { State } from '@embroker/shotwell/core/types/StateList';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { CoverageType } from './CoverageType';

export interface LawBundleQuestionnaireData {
    totalAttorneysThisYear?: number;
    gross_revenue_total?: number;
    areas_of_practice?: { areas_of_practice_rows: { area: string; percentage: number }[] };
    state_with_most_attorneys?: State;
    coverage_toggles?: CoverageType[];
    naics_code?: string;
    current_pl?: boolean | null;
}

export const LawBundleQuestionnaireData = {
    ...defineValidator<LawBundleQuestionnaireData>({
        totalAttorneysThisYear: Joi.number().optional(),
        gross_revenue_total: Joi.number().optional(),
        areas_of_practice: Joi.object({
            areas_of_practice_rows: Joi.array().items(
                Joi.object({ area: Joi.string(), percentage: Joi.number() }),
            ),
        }).optional(),
        state_with_most_attorneys: State.schema.optional(),
        coverage_toggles: Joi.array()
            .items(
                Joi.valid(CoverageType.LPL, CoverageType.Cyber, CoverageType.BOP, CoverageType.WC),
            )
            .has(CoverageType.LPL)
            .unique()
            .optional(),
        naics_code: Joi.string().optional(),
        current_pl: Joi.boolean().optional().allow(null),
    }),
    create(lawBundleQuestionnaireData: LawBundleQuestionnaireData) {
        return LawBundleQuestionnaireData.validate(lawBundleQuestionnaireData);
    },
};
