import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { PCoMLEndorsementRepository } from '../repositories/PCoMLEndorsementRepository';
import { PCoMLEndorsementConfig } from '../types/PCoMLEndorsementConfig';

export interface GetPCoMLEndorsementConfig extends UseCase {
    execute(): AsyncResult<PCoMLEndorsementConfig, InvalidArgument | OperationFailed>;
}

@injectable()
class GetPCoMLEndorsementConfigUseCase extends UseCase implements GetPCoMLEndorsementConfig {
    public static type = Symbol('PCoMLEndorsement/GetPCoMLEndorsementConfig');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(PCoMLEndorsementRepository)
        private pcomlEndorsementRepository: PCoMLEndorsementRepository,
    ) {
        super(eventBus);
    }

    public async execute(): AsyncResult<PCoMLEndorsementConfig, InvalidArgument | OperationFailed> {
        return await this.pcomlEndorsementRepository.getPCoMLEndorsementConfig();
    }
}

export const GetPCoMLEndorsementConfig: UseCaseClass<GetPCoMLEndorsementConfig> =
    GetPCoMLEndorsementConfigUseCase;
