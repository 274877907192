import { Immutable } from '@embroker/shotwell/core/types';
import { USD } from '@embroker/shotwell/core/types/Money';
import { URI } from '@embroker/shotwell/core/types/URI';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import {
    Button,
    Card,
    CardLayout,
    ColumnLayout,
    GridLayout,
    StackLayout,
    StatusLabel,
    StatusType,
    Text,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { DisplayPolicy } from '../../../../policy/types/DisplayPolicy';
import { AppTypeCode, DisplayPolicyStatus } from '../../../../shopping/types/enums';
import { ProductIcon } from '../../../../shopping/view/components/ProductIcon';
import { useNavigation } from '../../../../view/hooks/useNavigation';

export interface ClientPagePoliciesProps {
    activePolicies: Immutable<DisplayPolicy[]>;
}

export function ClientPagePolicies({ activePolicies }: ClientPagePoliciesProps) {
    const { navigate } = useNavigation();

    const noItems = activePolicies.length === 0;

    return (
        <StackLayout gap="24">
            <Text style="heading 4">Policies</Text>
            {!noItems ? (
                <GridLayout>
                    {activePolicies.map((policy) => {
                        const handleManagePolicy = async () => {
                            navigate(
                                URI.build('/policies/detail', {
                                    policyId: policy.id,
                                    referrer: 'client',
                                }),
                            );
                        };

                        const appType = mapQuotingEngineToAppType(policy.quotingEngine);
                        const showIcon = appType !== undefined;

                        const isCrimeApp =
                            policy.subLineOfBusiness ===
                            'LineOfBusinessSubtypeCodeListCrimeDigital';
                        const isPcomlApp = policy.lineOfBusiness === 'LineOfBusinessCodeListPCoML';
                        const shouldDisplayBasePremiumWithEmbrokerAccessFee =
                            isCrimeApp || isPcomlApp;

                        const premiumToDisplay = shouldDisplayBasePremiumWithEmbrokerAccessFee
                            ? policy.basePremiumWithEmbrokerAccessFee
                            : policy.premiumPerYear;

                        return (
                            <Card key={policy.id} className="u-grid-size-6" appearance="loose">
                                <Card.Header>
                                    {showIcon ? (
                                        <ProductIcon
                                            type={appType ?? 'AppTypeCodeListESP'}
                                        ></ProductIcon>
                                    ) : null}
                                    <Text style="heading 5">{policy.displayName}</Text>
                                </Card.Header>
                                <Card.Body>
                                    <ColumnLayout split="-1">
                                        <StackLayout gap="12">
                                            <ColumnLayout gap="4">
                                                <Text color="ui-400">Covered By:</Text>
                                                <Text color="ui-500">{policy.insurerName}</Text>
                                            </ColumnLayout>
                                            <div>
                                                <ColumnLayout gap="4">
                                                    <Text color="ui-400">Number:</Text>
                                                    <Text color="ui-500">
                                                        {policy.policyNumber}
                                                    </Text>
                                                </ColumnLayout>
                                                <ColumnLayout gap="4">
                                                    <Text color="ui-400">Term:</Text>
                                                    <Text color="ui-500">
                                                        <DateDisplay
                                                            format="MMM dd, yyyy"
                                                            value={policy.startDate}
                                                        />
                                                    </Text>
                                                    <Text color="ui-400">-</Text>
                                                    <Text color="ui-500">
                                                        <DateDisplay
                                                            format="MMM dd, yyyy"
                                                            value={policy.endDate}
                                                        />
                                                    </Text>
                                                </ColumnLayout>
                                                <ColumnLayout gap="4">
                                                    <Text color="ui-400">Premium:</Text>
                                                    <Text color="ui-500">
                                                        <MoneyDisplay
                                                            value={premiumToDisplay || USD(0)}
                                                        />
                                                    </Text>
                                                </ColumnLayout>
                                            </div>
                                        </StackLayout>
                                        {mapPolicyStatusToStatusLabel(policy.policyStatus)}
                                    </ColumnLayout>
                                </Card.Body>
                                <Card.Footer>
                                    <StackLayout gap="16">
                                        <Button appearance="secondary" onClick={handleManagePolicy}>
                                            Manage policy
                                        </Button>
                                    </StackLayout>
                                </Card.Footer>
                            </Card>
                        );
                    })}
                </GridLayout>
            ) : (
                <CardLayout>
                    <CardLayout.Body>
                        <Text style="body 1">Client does not have policies yet.</Text>
                    </CardLayout.Body>
                </CardLayout>
            )}
        </StackLayout>
    );
}

type StatusToLabelMap = {
    [key in DisplayPolicyStatus]: {
        text: string;
        type: StatusType;
    };
};
const labelMap: StatusToLabelMap = {
    DisplayPolicyStatusCancelled: {
        type: 'red',
        text: 'Cancelled',
    },
    DisplayPolicyStatusCancelPending: {
        type: 'red-outline',
        text: 'Cancellation pending',
    },
    DisplayPolicyStatusExpired: {
        type: 'red',
        text: 'Expired',
    },
    DisplayPolicyStatusActive: {
        type: 'green',
        text: 'Active',
    },
    DisplayPolicyStatusNonRenewed: {
        type: 'yellow-outline',
        text: 'Nonrenewed',
    },
};

function mapPolicyStatusToStatusLabel(status: DisplayPolicyStatus) {
    return <StatusLabel type={labelMap[status].type}>{labelMap[status].text}</StatusLabel>;
}

export function mapQuotingEngineToAppType(quotingEngine?: string): AppTypeCode | undefined {
    if (quotingEngine === 'QuotingEngineESP') {
        return 'AppTypeCodeListESP';
    } else if (quotingEngine === 'QuotingEnginePCoML') {
        return 'AppTypeCodeListPCoML';
    } else if (quotingEngine === 'QuotingEngineLPLEverest') {
        return 'AppTypeCodeListEverestLawyersProfessionalLiability';
    } else if (quotingEngine === 'QuotingEngineCyber') {
        return 'AppTypeCodeListEmbrokerCyber';
    } else if (quotingEngine === 'QuotingEngineCrime') {
        return 'AppTypeCodeListEmbrokerCrime';
    }

    return undefined;
}
