import { UploadFiles, UploadFilesRequest } from '../../useCases/UploadFiles';
import { useEffect, useState } from 'react';

import { FileUpload } from '../FileUpload';
import { Nullable } from '@embroker/shotwell/core/types';
import { execute } from '@embroker/shotwell/core/UseCase';
import { isOK } from '@embroker/shotwell/core/types/Result';

export function useUpload(file: FileUpload) {
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadFile, setUploadFile] = useState<FileUpload | null>(null);
    const [abortController, setAbortController] = useState<Nullable<AbortController>>(null);
    const [fileKey, setFileKey] = useState<string | null>(null);

    useEffect(() => {
        if (fileKey !== null) {
            setAbortController(null);
            setUploadProgress(100);
        }
    }, [fileKey]);

    useEffect(() => {
        if (file.s3FileKey != null) {
            setFileKey(file.s3FileKey);
        }
    }, [file.s3FileKey]);

    useEffect(() => {
        let isLoaded = true;
        if (uploadFile !== null && abortController !== null) {
            file.uploadedSize = 0;
            execute(UploadFiles, {
                files: [uploadFile.file],
                onFileUploadProgress: (uploaded, totalSize) => {
                    if (isLoaded) {
                        file.uploadedSize = uploaded;
                        const progress = (uploaded / totalSize) * 100;
                        setUploadProgress(progress === 100 ? 99 : progress);
                    }
                },
                abortSignal: abortController.signal,
            } as UploadFilesRequest).then((result) => {
                if (isLoaded && isOK(result)) {
                    setFileKey(result.value.uploadedFiles[0].fileKey);
                }
            });
        }
        return () => {
            isLoaded = false;
        };
    }, [uploadFile, abortController, file]);

    useEffect(() => {
        if (uploadFile?.id !== file.id) {
            abortController?.abort();
            const alreadyUploaded = file.s3FileKey !== null;
            if (!alreadyUploaded) {
                const newAbortController = new AbortController();
                setAbortController(newAbortController);
                setUploadFile(file);
            }
        }
    }, [file, uploadFile, abortController]);

    return {
        uploadProgress,
        abortController,
        fileKey,
    };
}
