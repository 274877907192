import { Immutable } from '@embroker/shotwell/core/types';
import { StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { Lob } from '../../../types/Lob';
import { CoverageSectionLimitAndRetention } from './CoverageSectionLimitAndRetention';
import { CoverageSectionTable } from './CoverageSectionTable';

export interface ProfessionalLiabilityCoverageSectionBodyProps {
    lob: Immutable<Lob>;
}

export function ProfessionalLiabilityCoverageSectionBody({
    lob,
}: ProfessionalLiabilityCoverageSectionBodyProps) {
    return (
        <StackLayout gap="16">
            <CoverageSectionLimitAndRetention lob={lob} />
            <StackLayout gap="none">
                <Text style="heading 5">What's covered</Text>
                <CoverageSectionTable lob={lob} />
            </StackLayout>
        </StackLayout>
    );
}
