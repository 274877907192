import { ErrorCode, InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import {
    AsyncResult,
    ErrorLike,
    Failure,
    Success,
    isErr,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { InsuranceApplicationStatusCode } from '../../shopping/types/enums';
import { BundleQuoteRepository } from '../repositories';

export interface ApplicationStatus {
    applicationId: UUID;
    applicationStatus: InsuranceApplicationStatusCode;
}
export interface SetApplicationStatusRequest {
    applicationStatusList: ApplicationStatus[];
}

export interface SetApplicationStatus extends UseCase {
    execute(
        request: SetApplicationStatusRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed>;
}

@injectable()
class SetApplicationStatusUseCase extends UseCase implements SetApplicationStatus {
    public static type = Symbol('BundleQuote/SetApplicationStatus');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BundleQuoteRepository) private bundleQuoteRepository: BundleQuoteRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationStatusList,
    }: SetApplicationStatusRequest): AsyncResult<void, InvalidArgument | OperationFailed> {
        const resultList = await Promise.allSettled(
            applicationStatusList.map((application) => {
                return this.bundleQuoteRepository.setApplicationStatus(
                    application.applicationId,
                    application.applicationStatus,
                );
            }),
        );

        const errors = resultList.reduce<ErrorLike[]>((acc, curr) => {
            if (curr.status === 'rejected') {
                return [
                    ...acc,
                    {
                        name: 'OperationFailed',
                        code: ErrorCode.OperationFailed,
                        message: 'Set application status request failed',
                        details: curr.reason,
                    },
                ];
            }

            if (isErr(curr.value)) {
                return [...acc, ...curr.value.errors];
            }

            return acc;
        }, []);

        if (errors.length) {
            return Failure(
                OperationFailed({
                    message: 'Set application status request failed',
                    errors: errors,
                }),
            );
        }
        return Success();
    }
}

export const SetApplicationStatus: UseCaseClass<SetApplicationStatus> = SetApplicationStatusUseCase;
