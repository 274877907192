import { errorCodes } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { ErrorObject } from '@embroker/shotwell/core/types/Result';

export const ErrorCode = errorCodes({
    ConfigFetchFailed: 0x6000,
    UwReviewFetchFailed: 0x6001,
    ExportUWReviewFromAPIResponseFailed: 0x6002,
});

export type ConfigFetchFailed = ErrorObject<typeof ErrorCode.ConfigFetchFailed, {}>;

export function ConfigFetchFailed(): Immutable<ConfigFetchFailed> {
    return {
        name: 'ConfigFetchFailed',
        code: ErrorCode.ConfigFetchFailed,
        message: 'Failed to fetch config API.',
        details: {},
    };
}

export type UwReviewFetchFailed = ErrorObject<typeof ErrorCode.UwReviewFetchFailed, {}>;

export function UwReviewFetchFailed(): Immutable<UwReviewFetchFailed> {
    return {
        name: 'UwReviewFetchFailed',
        code: ErrorCode.UwReviewFetchFailed,
        message: 'Failed to fetch UW Review.',
        details: {},
    };
}

export type ExportUWReviewFromAPIResponseFailed = ErrorObject<
    typeof ErrorCode.ExportUWReviewFromAPIResponseFailed,
    {}
>;

export function ExportUWReviewFromAPIResponseFailed(): Immutable<ExportUWReviewFromAPIResponseFailed> {
    return {
        name: 'ExportUWReviewFromAPIResponseFailed',
        code: ErrorCode.ExportUWReviewFromAPIResponseFailed,
        message: 'Failed to export UW Review from API response.',
        details: {},
    };
}
