import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { EmbrokerExcessConfigRepository } from '../repositories/EmbrokerExcessConfigRepository';

export interface GetEmbrokerExcessEnabled extends UseCase {
    execute(): AsyncResult<boolean, InvalidArgument | OperationFailed>;
}

@injectable()
class GetEmbrokerExcessEnabledUseCase extends UseCase implements GetEmbrokerExcessEnabled {
    public static type = Symbol('EmbrokerExcess/GetEmbrokerExcessEnabled');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(EmbrokerExcessConfigRepository)
        private embrokerExcessConfigRepository: EmbrokerExcessConfigRepository,
    ) {
        super(eventBus);
    }

    public async execute(): AsyncResult<boolean, InvalidArgument | OperationFailed> {
        return await this.embrokerExcessConfigRepository.isEmbrokerExcessEnabled();
    }
}

export const GetEmbrokerExcessEnabled: UseCaseClass<GetEmbrokerExcessEnabled> =
    GetEmbrokerExcessEnabledUseCase;
