import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Immutable } from '@embroker/shotwell/core/types';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { List, Modal, ModalActions, ModalState, Text, StackLayout } from '@embroker/ui-toolkit/v2';
import React, { useMemo } from 'react';
import { Application } from '../../../../shopping/entities/Application';
import { AppTypeLabelMap } from '../../../../shopping/types/enums';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { ModalLayout } from '../../../../view/components/ModalLayout.view';

interface ConfirmationModalProps {
    modal: ModalState & ModalActions;
    organizationName: string;
    organizationId: UUID;
    application: Immutable<EntityProps<Application>>;
}

export function ConfirmationModal({
    modal,
    organizationName,
    organizationId,
    application,
}: ConfirmationModalProps) {
    const { navigate } = useNavigation();

    const programTitle = useMemo(() => {
        switch (application?.quotingEngine) {
            case 'QuotingEngineLPLEverest':
                return 'Lawyers Professional Liability';
            case 'QuotingEngineESP':
                return 'Startup Package';
            case 'QuotingEnginePCoML':
                return 'Management Liability';
            default:
                return 'policy';
        }
    }, [application]);

    const handleGoToClient = () =>
        navigate(URI.build('/broker/organization-info', { organizationId }));

    return (
        <Modal {...modal} dismissable={false}>
            <ModalLayout
                title={`${organizationName} is covered!`}
                primaryAction={{
                    label: 'Go To Client',
                    onClick: handleGoToClient,
                }}
                secondaryAction={{
                    label: 'Go To Dashboard',
                    onClick: () => navigate('/broker/dashboard'),
                }}
            >
                <Text>{'Your client now has the ' + programTitle + ' which includes:'}</Text>
                <AppTypeList application={application} />
                <Text as="p" style="label 1">
                    What's next? You can review or download a copy of the policy, or contact us.
                </Text>
                <StackLayout>
                    <a href="#chat-now">Open live chat</a>
                    <a href="tel:844.436.2765">Call 844.436.2765</a>
                </StackLayout>
            </ModalLayout>
        </Modal>
    );
}

function AppTypeList({ application }: { application: Immutable<EntityProps<Application>> }) {
    return (
        <List>
            {application && (
                <List.Item key={UUID.create()}>{AppTypeLabelMap[application.appType]}</List.Item>
            )}
        </List>
    );
}
