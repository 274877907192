import type { AppWebServerEnv } from './server/env';

export function getEnvVar<T extends keyof AppWebServerEnv, U = AppWebServerEnv[T]>(
    name: T,
    defaultValue?: U,
): U | undefined {
    if (typeof window.EMBROKER_ENV === 'object' && window.EMBROKER_ENV !== null) {
        const value = (window.EMBROKER_ENV as AppWebServerEnv)[name] ?? defaultValue;
        return value as U;
    }
    return defaultValue;
}
