import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { PolicyRepository } from '../repositories/PolicyRepository';
import { SignatureRequest } from '../types/SignatureRequest';

export interface CreateManagePolicyRequestRequest {
    insurerIdList: UUID[];
}

export const CreateManagePolicyRequestRequest = {
    ...defineValidator<CreateManagePolicyRequestRequest>({
        insurerIdList: Joi.array().items(UUID.schema.optional()),
    }),
    create(insurerIdList: UUID[]) {
        const createManagePolicyRequestRequest = {
            insurerIdList: insurerIdList,
        };
        return CreateManagePolicyRequestRequest.validate(createManagePolicyRequestRequest);
    },
};

export interface CreateManagePolicyRequestResponse {
    clientId: string;
    signatureRequestList: SignatureRequest[];
}

export const CreateManagePolicyRequestResponse = {
    ...defineValidator<CreateManagePolicyRequestResponse>({
        clientId: Joi.string(),
        signatureRequestList: Joi.array().items(SignatureRequest.schema),
    }),
    create(createManagePolicyRequestResponse: CreateManagePolicyRequestResponse) {
        return CreateManagePolicyRequestResponse.validate(createManagePolicyRequestResponse);
    },
};

export interface CreateManagePolicyRequest extends UseCase {
    execute(
        request: CreateManagePolicyRequestRequest,
    ): AsyncResult<CreateManagePolicyRequestResponse, InvalidArgument | OperationFailed>;
}

@injectable()
export class CreateManagePolicyRequestUseCase extends UseCase {
    public static type = Symbol('Policy/CreateManagePolicyRequest');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(PolicyRepository) private policyRepository: PolicyRepository,
    ) {
        super(eventBus);
    }

    async execute(
        request: CreateManagePolicyRequestRequest,
    ): AsyncResult<CreateManagePolicyRequestResponse, InvalidArgument | OperationFailed> {
        const createRequestResult = await this.policyRepository.borCreateTransferRequest(
            request.insurerIdList,
        );
        if (isErr(createRequestResult)) {
            return createRequestResult;
        }

        return CreateManagePolicyRequestResponse.create({
            clientId: createRequestResult.value.clientId,
            signatureRequestList: createRequestResult.value
                .signatureRequestList as SignatureRequest[],
        });
    }
}

export const CreateManagePolicyRequest: UseCaseClass<CreateManagePolicyRequest> =
    CreateManagePolicyRequestUseCase;
