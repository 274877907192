import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { PCoMLEndorsementRepository } from '../repositories/PCoMLEndorsementRepository';
import { Money } from '@embroker/shotwell/core/types/Money';
import { PCoMLEndorsementRate } from '../types/PCoMLEndorsementRate';
import { PCoMLEndorsementLiabilityCoverageType } from '../types/PCoMLEndorsementLiabilityCoverageType';
import { TasksRepository } from '../../../tasks/repositories';
import { Immutable } from '@embroker/shotwell/core/types';

export interface RatePCoMLEndorsementRequest {
    policyId: UUID;
    endorsementType: string;
    effectiveDate: Date;
    limitEndorsement: {
        coverage: PCoMLEndorsementLiabilityCoverageType;
        limit: Money;
        retention: Money;
    };
    abortSignal?: AbortSignal;
}

export interface RatePCoMLEndorsement extends UseCase {
    execute(
        request: RatePCoMLEndorsementRequest,
    ): AsyncResult<PCoMLEndorsementRate, InvalidArgument | OperationFailed>;
}

@injectable()
class RatePCoMLEndorsementUseCase extends UseCase implements RatePCoMLEndorsement {
    public static type = Symbol('PCoMLEndorsement/RatePCoMLEndorsement');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(PCoMLEndorsementRepository)
        private pcomlEndorsementRepository: PCoMLEndorsementRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        ratePCoMLEndorsementRequest: RatePCoMLEndorsementRequest,
    ): AsyncResult<PCoMLEndorsementRate, InvalidArgument | OperationFailed> {
        const rateResult = await this.pcomlEndorsementRepository.rateEndorsement(
            ratePCoMLEndorsementRequest,
        );
        if (isErr(rateResult)) {
            return rateResult;
        }

        const taskStatusResult = await this.tasksRepository.pollForTaskStatus(
            rateResult.value,
            ratePCoMLEndorsementRequest.abortSignal,
        );
        if (isErr(taskStatusResult)) {
            return Failure(
                OperationFailed({
                    message: `Get task status failed for task with id: ${rateResult.value}`,
                    errors: taskStatusResult.errors,
                }),
            );
        }

        const rateData = await RatePCoMLEndorsementUseCase.toPCoMLEndorsementRate(
            taskStatusResult.value,
        );
        if (isErr(rateData)) {
            return rateData;
        }

        return Success<PCoMLEndorsementRate>(rateData.value);
    }

    private static async toPCoMLEndorsementRate(
        rateJson: string,
    ): AsyncResult<Immutable<PCoMLEndorsementRate>, OperationFailed> {
        let rateObject;
        try {
            rateObject = JSON.parse(rateJson);
        } catch (e) {
            return Failure(OperationFailed({ message: 'Failed to parse Json data' }));
        }
        const rateCreateResult = PCoMLEndorsementRate.create({
            annualPremium: Money.tryFromFloat(rateObject.annual_premium),
            fees: Money.tryFromFloat(rateObject.fees),
            taxes: Money.tryFromFloat(rateObject.taxes),
            prorate: Money.tryFromFloat(rateObject.prorate),
            total: Money.tryFromFloat(rateObject.total),
        });

        if (isErr(rateCreateResult)) {
            return Failure(
                OperationFailed({
                    message: `Unable to create PCoMLEndorsementRate from ${rateObject}`,
                    errors: rateCreateResult.errors,
                }),
            );
        }

        return Success(rateCreateResult.value);
    }
}

export const RatePCoMLEndorsement: UseCaseClass<RatePCoMLEndorsement> = RatePCoMLEndorsementUseCase;
