import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { InvalidArgument, NotAllowed, OperationFailed } from '@embroker/shotwell/core/Error';
import { inject } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { BrokerRepository } from '../repositories';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { BrokerageOrganizationInfo } from '../types/BrokerageOrganizationInfo';

export interface GetBrokerageOrganizationInfoRequest {
    orgId: UUID;
}

export interface GetBrokerageOrganizationInfo extends UseCase {
    execute(
        request: GetBrokerageOrganizationInfoRequest,
    ): AsyncResult<BrokerageOrganizationInfo, InvalidArgument | OperationFailed | NotAllowed>;
}

class GetBrokerageOrganizationInfoUseCase extends UseCase implements GetBrokerageOrganizationInfo {
    public static type = Symbol('Broker/GetOrganizationInfo');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BrokerRepository) private brokerRepository: BrokerRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: GetBrokerageOrganizationInfoRequest,
    ): AsyncResult<BrokerageOrganizationInfo, InvalidArgument | OperationFailed | NotAllowed> {
        const result = await this.brokerRepository.getBrokerageOrganizationInfo(request.orgId);

        if (isErr(result)) {
            return result;
        }

        return Success(result.value);
    }
}

export const GetBrokerageOrganizationInfo: UseCaseClass<GetBrokerageOrganizationInfo> =
    GetBrokerageOrganizationInfoUseCase;
