import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ProgressSidebarItemStatus } from '@embroker/ui-toolkit/v2';
import { FormNavigationView, NavigationItem } from './FormNavigation.view';
import {
    QuestionerPageDefinition,
    extractFormPages,
} from '@app/shoppingQuestioner/types/QuestionerPageDefinition';

interface CompletedPages {
    [key: string]: boolean;
}

const getPageIdFromSubPages = (
    subPages: QuestionerPageDefinition[],
    completedPages: CompletedPages,
): string => {
    const firstIncompleteSubPage = subPages.find((page) => !completedPages[page.name]);
    return firstIncompleteSubPage ? firstIncompleteSubPage.name : subPages[0].name;
};

const allPagesCompleted = (
    currentPage: QuestionerPageDefinition,
    completedPages: CompletedPages,
): boolean => {
    if (currentPage.subPages?.length) {
        return currentPage.subPages.every((page) => allPagesCompleted(page, completedPages));
    } else {
        return completedPages[currentPage.name];
    }
};

const isActivePage = (page: QuestionerPageDefinition, activePage?: string): boolean => {
    if (page.name === activePage) {
        return true;
    }
    if (page.subPages) {
        return page.subPages.some((subPage) => isActivePage(subPage, activePage));
    }
    return false;
};

const getPageStatus = (
    currentPage: QuestionerPageDefinition,
    completedPages: CompletedPages,
    activePage?: string,
): ProgressSidebarItemStatus => {
    if (isActivePage(currentPage, activePage)) {
        return 'active';
    }

    if (allPagesCompleted(currentPage, completedPages)) {
        return 'success';
    }

    return 'default';
};

interface FormSideNavigationProps {
    activePage?: string;
    formPages: QuestionerPageDefinition[];
    onClickNavigationItem: (id: string) => void;
}

export function FormSideNavigation({
    activePage,
    formPages,
    onClickNavigationItem,
}: FormSideNavigationProps) {
    const pages = extractFormPages(formPages);
    const [completedPages, setCompletedPages] = useState<CompletedPages>({});

    const prevActivePageNameRef = useRef<string | undefined>(undefined);
    useEffect(() => {
        const activePageIndex = pages.findIndex((item) => item.name === activePage);
        const hasActivePage = activePageIndex !== -1;

        // Initialize completedPages state
        if (!prevActivePageNameRef.current && hasActivePage) {
            const updatedCompletedPages: CompletedPages = {};
            for (let i = 0; i < activePageIndex; i++) {
                updatedCompletedPages[pages[i].name] = true;
            }
            setCompletedPages({ ...updatedCompletedPages });
        }

        // Update completedPages as activePage changes
        if (prevActivePageNameRef.current !== activePage) {
            const prevActivePageName = prevActivePageNameRef.current;

            const prevActivePageIndex = pages.findIndex((item) => item.name === prevActivePageName);
            const isForwardProgress = prevActivePageIndex < activePageIndex;

            if (prevActivePageName && isForwardProgress) {
                setCompletedPages((prevState) => ({ ...prevState, [prevActivePageName]: true }));
            }
        }

        prevActivePageNameRef.current = activePage;
    }, [activePage, pages]);

    const navigationPages: NavigationItem[] = useMemo(() => {
        return formPages.map((page) => {
            const status = getPageStatus(page, completedPages, activePage);
            return {
                id: page.name,
                title: page.title || '',
                status,
                disabled: status === 'default',
            };
        });
    }, [activePage, formPages, completedPages]);

    const handleClickNavigationItem = useCallback(
        (id: string) => {
            const page = formPages.find((page) => page.name === id);
            if (!page) {
                return;
            }

            const pageId = page.subPages
                ? getPageIdFromSubPages(page.subPages, completedPages)
                : page.name;

            onClickNavigationItem(pageId);
        },
        [completedPages, formPages, onClickNavigationItem],
    );

    return (
        <FormNavigationView
            navigationPages={navigationPages}
            onClickNavigationItem={handleClickNavigationItem}
        />
    );
}
