import { UUID } from '@embroker/shotwell/core/types/UUID';
import { map, route, withData } from 'navi';
import React from 'react';
import { CrimeQuoteLandingPage } from '../../crime/view/components/CrimeQuoteLandingPage';
import { execute } from '@embroker/shotwell/core/UseCase';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { GetLastCrimeQuote } from '../../crime/useCases/GetLastCrimeQuote';
import { GetCrimeConfig } from '../../crime/useCases/GetCrimeConfig';
import { isValid, parseISO, startOfDay, startOfToday } from 'date-fns';
import { redirectToWhoopsPage } from '../../../view/errors';

function getToday(todayOverride?: string): () => Date {
    return () => {
        return todayOverride && isValid(parseISO(todayOverride))
            ? startOfDay(parseISO(todayOverride))
            : startOfToday();
    };
}

const crimeWizardRouteHandler = map(async (request) => {
    const { applicationId } = request.query;
    const validatedApplicationIdResult = UUID.validate(applicationId);

    if (isErr(validatedApplicationIdResult)) {
        return redirectToWhoopsPage(validatedApplicationIdResult.errors);
    }

    const getLastQuoteResult = await execute(GetLastCrimeQuote, {
        applicationId: validatedApplicationIdResult.value,
    });

    if (isErr(getLastQuoteResult)) {
        return redirectToWhoopsPage(getLastQuoteResult.errors);
    }

    const getCrimeConfigResult = await execute(GetCrimeConfig);
    const crimeDevModeEffectiveDate = isOK(getCrimeConfigResult)
        ? getCrimeConfigResult.value.crimeDevModeEffectiveDate
        : undefined;
    const isTestMode = crimeDevModeEffectiveDate !== '';

    return route({
        title: 'Crime Quote',
        view: (
            <CrimeQuoteLandingPage
                applicationId={validatedApplicationIdResult.value}
                initialCrimeQuote={getLastQuoteResult.value}
                getToday={getToday(crimeDevModeEffectiveDate)}
                isTestMode={isTestMode}
            />
        ),
    });
});

export const crimeRoutes = {
    '/': map((request) =>
        withData(
            { url: request.mountpath, page: undefined, fullscreen: true },
            crimeWizardRouteHandler,
        ),
    ),
    '/:page': map((request) =>
        withData(
            { url: request.mountpath, page: request.params.page, fullscreen: true },
            crimeWizardRouteHandler,
        ),
    ),
};
