import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ChannelType } from '../../types/ChannelType';
import {
    CreateServiceInfoError,
    GetManifestByVersionError,
    CreateManifestDefinitionError,
} from '../../errors';
import { ManifestDefinition } from '../../types/ManifestDefinition';

/**
 * This is the repository used to work with Manifest
 */

export interface GetManifestByVersionRequest {
    version: UUID;
}

export interface PublishManifestRequest {
    name: string;
    channel: ChannelType;
    publishedBy: UUID;
    manifestDefinition: ManifestDefinition;
}

export interface DisableRequest {
    supersedingManifestVersion: UUID;
    supersededManifestVersion: UUID;
    disabledBy: UUID;
}

export interface ManifestRepository {
    /**
     * Fetches manifest by version
     * @returns Manifest definition
     * @returns GetManifestByVersionError error if get manifest by version request fails
     * @returns CreateManifestDefinitionError error if `ManifestDefinition` entity fails to be created from API response
     * @returns CreateServiceInfoError error if `ModelVersion` entity or `ServiceDefinition` entity fails to be created from API response
     */
    getByVersion(
        request: GetManifestByVersionRequest,
    ): AsyncResult<
        ManifestDefinition,
        GetManifestByVersionError | CreateManifestDefinitionError | CreateServiceInfoError
    >;
}

export const ManifestRepository = Symbol('ManifestRepository');
