import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Immutable } from '@embroker/shotwell/core/types';
import { ErrorLike } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { redirect } from 'navi';
import { NavigateFunction } from './hooks/useNavigation';

/**
 * Log an error and navigate to Whoops Error page with error log id included.

 * USAGE: DO NOT use this as go-to solution since in general using navigation to handle
 * react IF scenarios is not desireable pattern. Since ATM we do use this patter in couple of places
 * this hook will accumulate all those behaviors in one place and wait for refactor

 * @param error List of error objects
 */
export function navigateToErrorPage<E extends ErrorLike>(
    navigate: NavigateFunction,
    error: Immutable<E[]>,
): void {
    const errorLogId = container.get<Logger>(Log).whoops(error);
    navigate(URI.build('/error', { errorLogId }));
}

/**
 * Log an error and redirect to Whoops Error page with error log id included.

 * USAGE: Intended for handling Whoops errors in route matchers where the Navigation object is not accessible

 * @param errors List of error objects
 */
export function redirectToWhoopsPage<E extends ErrorLike>(errors: Immutable<E[]>) {
    const errorLogId = container.get<Logger>(Log).whoops(errors);
    return redirect(URI.build('/error', { errorLogId }));
}
