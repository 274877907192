import { isOK } from '@embroker/shotwell/core/types/Result';
import { execute } from '@embroker/shotwell/core/UseCase';
import { TextButton } from '@embroker/ui-toolkit/v2';
import { useEnumGroup } from '../../../../serverEnums/view/hooks/useEnumGroup';
import React from 'react';
import { GetFile, GetFileRequest } from '../../../../documents/useCases/GetFile';
import { GetDocumentURL, GetDocumentURLRequest } from '../../../useCases/GetDocumentURL';

interface DownloadDocumentProps {
    currentDocumentFileKey: string;
    currentDocumentTypeCode: string;
    isDownload: boolean;
    buttonText?: string;
}

export const DownloadDocument = (props: DownloadDocumentProps) => {
    const { result: documentTypeCodeList } = useEnumGroup('DocumentTypeCodeList');

    if (documentTypeCodeList === undefined) {
        return null;
    }

    async function downloadDocument(documentTypeCodeList: Map<string, string>) {
        const result = await execute(GetDocumentURL, {
            fileKey: props.currentDocumentFileKey,
        } as GetDocumentURLRequest);
        if (result !== undefined && isOK(result)) {
            const result2 = await execute(GetFile, {
                fileName: `${documentTypeCodeList.get(props.currentDocumentTypeCode)}.pdf`,
                fileType: 'application/pdf',
                url: result.value.documentURL,
            } as GetFileRequest);
            if (result2 && result2.status === 'OK') {
                const fileURL = window.URL.createObjectURL(result2.value as File);
                const tempLink: HTMLElement = document.createElement('a');
                tempLink.setAttribute('href', fileURL);
                tempLink.setAttribute('download', result2.value.name);
                tempLink.click();
            }
        }
    }

    async function handleViewClicked() {
        const result = await execute(GetDocumentURL, {
            fileKey: props.currentDocumentFileKey,
        } as GetDocumentURLRequest);
        if (result !== undefined && isOK(result)) {
            window.open(result.value.documentURL);
        }
    }

    if (props.isDownload) {
        return (
            <TextButton
                data-e2e="download-pdf"
                onClick={() => {
                    downloadDocument(documentTypeCodeList);
                }}
            >
                {props.buttonText ?? 'Download PDF'}
            </TextButton>
        );
    } else {
        return (
            <TextButton
                data-e2e="view-document"
                onClick={() => {
                    handleViewClicked();
                }}
            >
                {props.buttonText ?? 'View (opens new tab)'}
            </TextButton>
        );
    }
};
