import { Nullable } from '@embroker/shotwell/core/types';
import { State } from '@embroker/shotwell/core/types/StateList';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { ScrollBox, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';

interface SignatureProps {
    fullName: string;
    title: string;
}

function Signature({ fullName, title }: SignatureProps) {
    const today = new Date(Date.now());
    return (
        <Text>
            Signed:{' '}
            <Text as="span" style="label 1">
                {fullName}
            </Text>
            Date:{' '}
            <Text as="span" style="label 1">
                <DateDisplay value={today} />
            </Text>
            <br />
            Title:{' '}
            <Text as="span" style="label 1">
                {title}
            </Text>
        </Text>
    );
}

const usaStatesWithWarrantyHeading = ['AK', 'FL', 'GA', 'KS', 'KY', 'ME', 'NE', 'NH', 'NC', 'OK'];

interface WarrantyAndFraudModalContentProps {
    fullName: string;
    title: string;
    usaState: Nullable<State>;
}

export const WarrantyAndFraudModalContent = function WarrantyAndFraudModalContent({
    fullName,
    title,
    usaState,
}: WarrantyAndFraudModalContentProps) {
    const headingPrefix = usaStatesWithWarrantyHeading.includes(usaState ?? '')
        ? 'Applicant Representation'
        : 'Warranty';
    return (
        <ScrollBox>
            <StackLayout>
                <Text as="h3" style="heading 3">
                    Fraud Prevention – General Warning
                </Text>
                <Text as="p" style="label 1">
                    The Applicant's submission of this Application does not obligate the Company to
                    issue, or the Applicant to purchase, a policy. The Applicant will be advised if
                    the Application for coverage is accepted.
                    <br />
                    The Applicant hereby authorizes the Company to make any inquiry in connection
                    with this Application.
                </Text>
                <Text as="p">
                    The undersigned authorized agents of the person(s) and entity(ies) proposed for
                    this insurance declare that to the best of their knowledge and belief, after
                    reasonable inquiry, the statements made in this Application and in any
                    attachments or other documents submitted with this Application are true and
                    complete. The undersigned agree that this Application and such attachments and
                    other documents shall be the basis of the insurance policy should a policy
                    providing the requested coverage be issued; that all such materials shall be
                    deemed to be attached to and shall form a part of any such policy; and that the
                    Company will have relied on all such materials in issuing any such policy.
                </Text>
                <Text as="p">
                    The information requested in this Application is for underwriting purposes only
                    and does not constitute notice to the Company under any policy of a Claim or
                    potential Claim.
                </Text>
                <Text as="p" style="label 1">
                    Notice to Louisiana, Maryland, Minnesota, New Mexico and Ohio Applicants:{' '}
                </Text>
                <Text as="p">
                    Any person who, with intent to defraud or knowing that he/she is facilitating a
                    fraud against an insurer, submits an application or files a claim containing a
                    false, fraudulent or deceptive statement is, or may be found to be, guilty of
                    insurance fraud, which is a crime, and may be subject to civil fines and
                    criminal penalties.
                </Text>
                <Text as="p" style="label 1">
                    Notice to Colorado Applicants:
                </Text>
                <Text as="p">
                    It is unlawful to knowingly provide false, incomplete or misleading facts or
                    information to an insurance company for the purpose of defrauding or attempting
                    to defraud the company. Penalties may include imprisonment, fines, denial of
                    insurance, and civil damages. Any insurance company or agent of an insurance
                    company who knowingly provides false, incomplete, or misleading facts or
                    information to a policy holder or claimant for the purpose of defrauding or
                    attempting to defraud the policy holder or claimant with regard to a settlement
                    or award payable from insurance proceeds shall be reported to the Colorado
                    Division of Insurance within the Department of Regulatory agencies.
                </Text>
                <Text as="p" style="label 1">
                    Notice to District of Columbia, Maine, Tennessee and Virginia Applicants:{' '}
                </Text>
                <Text as="p">
                    It is a crime to knowingly provide false, incomplete or misleading information
                    to an insurance company for the purpose of defrauding the company. Penalties may
                    include imprisonment, fines or a denial of insurance benefits.
                </Text>
                <Text as="p" style="label 1">
                    Notice to Florida and Oklahoma Applicants:
                </Text>
                <Text as="p">
                    Any person who, knowingly and with intent to injure, defraud or deceive any
                    employer or employee, insurance company, or self-insured program, files a
                    statement of claim containing any false or misleading information is guilty of:
                    a felony (in Oklahoma) or a felony of the third degree (in Florida).
                </Text>
                <Text as="p" style="label 1">
                    Notice to Kentucky Applicants:
                </Text>
                <Text as="p">
                    Any person who, knowingly and with intent to defraud any insurance company or
                    other person files an application for insurance containing any false
                    information, or conceals for the purpose of misleading, information concerning
                    any material fact thereto, commits a fraudulent insurance act which is a crime.
                </Text>
                <Text style="heading 3">
                    {headingPrefix}: Prior Knowledge of Facts, Circumstances, or Situations
                </Text>
                <Text as="p" style="label 1">
                    The undersigned authorized owner, partner, director, or officer represents and
                    warrants on behalf of the Named Insured and all persons/ entities for whom
                    insurance is being sought that to the best of his/ her knowledge and belief
                    after diligent inquiry, the statements set forth herein and attached hereto are
                    true. It is understood that the statements in this Application, including
                    material submitted to or obtained by the underwriter, are material to the
                    acceptance of the risk and relied upon by the underwriter. The Insureds further
                    agree that in the event of any material misrepresentation or omission in the
                    Application, including materials submitted to or obtained by the underwriter,
                    this Policy shall be voided.
                </Text>
                <Text as="p">
                    No person or entity proposed for coverage is aware of any fact, circumstance,
                    error or omission which he or she has reason to believe might give rise to any
                    Claim that would fall within the scope of the proposed coverage.
                </Text>
                <Text as="p">
                    If any person proposed for coverage is aware of any fact or circumstance or any
                    actual or alleged error or omission which he or she has reason to believe might
                    give rise to a claim that would fall within the scope of the proposed coverage.
                    Whether or not disclosed above, it is agreed that any claim arising therefrom is
                    excluded from the proposed coverage.
                </Text>
                <Text as="p">
                    The undersigned agrees that if the information supplied on this Application
                    changes between the date of this Application and the effective date or the
                    insurance; that he/she will immediately notify the Insurer of such changes, and
                    the Insurer may withdraw or modify any outstanding quotations or authorizations
                    or agreements to bind the insurance.
                </Text>
                <Text as="p">
                    Signing this Application does not bind the applicant or the Insurer to complete
                    the insurance, but it is agreed that this Application shall be the basis of the
                    contract should a policy be issued, and it will be attached to and become part
                    of the Policy.
                </Text>
                <Signature fullName={fullName} title={title} />
            </StackLayout>
        </ScrollBox>
    );
};
