import { useModal } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { BrokerClientReservationModal } from '../modals/ClientReservationModal';
import { GapBetweenPoliciesBrokerModal } from '../modals/GapBetweenPoliciesBrokerModal';
import { BrokerNotLicensedInStateModal } from '../modals/NotLicensedInStateModal';
import { BrokerQuoteDeclinedModal } from '../modals/QuoteDeclinedModal';
import { BrokerQuoteReferredModal } from '../modals/QuoteReferredModal';

export type BrokerKickoutModalTypes =
    | 'quoteReferred'
    | 'quoteDeclined'
    | 'stateUnlicensed'
    | 'clientReservation'
    | 'gapBetweenPolicies';

export function BrokerKickOutModals({ modalToShow }: { modalToShow: BrokerKickoutModalTypes }) {
    const modalState = useModal({ visible: true });

    switch (modalToShow) {
        case 'quoteReferred':
            return <BrokerQuoteReferredModal modal={modalState} />;

        case 'quoteDeclined':
            return <BrokerQuoteDeclinedModal modal={modalState} />;

        case 'stateUnlicensed':
            return <BrokerNotLicensedInStateModal modal={modalState} />;

        case 'clientReservation':
            return <BrokerClientReservationModal modal={modalState} />;

        case 'gapBetweenPolicies':
            return <GapBetweenPoliciesBrokerModal modal={modalState} />;

        default:
            return null;
    }
}
