import React from 'react';

import { StackLayout, Text, Table, Immutable } from '@embroker/ui-toolkit/v2';
import { SubjectOfInsurance } from '../../../types/SubjectOfInsurance';
import { PropertyCoverage } from '../../../types/PropertyCoverage';
import { USD } from '@embroker/shotwell/core/types/Money';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';

export interface PropertyCoverageSectionTableProps {
    subjectOfInsurance: Immutable<SubjectOfInsurance>;
}

export const PropertyCoverageSectionTable = ({
    subjectOfInsurance,
}: PropertyCoverageSectionTableProps) => {
    function getBuildingCoverage(subjectOfInsurance: Immutable<SubjectOfInsurance>): JSX.Element {
        let limit = USD(0);
        let founded = false;
        subjectOfInsurance.propertyCoverageList.forEach((item: PropertyCoverage) => {
            if (item.typeCode === 'PropertyCoverageCodeListBuilding') {
                limit = item.limit;
                founded = true;
                return;
            }
        });
        if (founded) {
            if (limit.amount > 0) {
                return <MoneyDisplay fractionDigits={0} value={limit} />;
            }
            return <Text style="label 1">included</Text>;
        }
        return <Text style="label 1">None</Text>;
    }

    function getBusinessPersonalPropertyCoverage(
        subjectOfInsurance: Immutable<SubjectOfInsurance>,
    ): JSX.Element {
        let limit = USD(0);
        let founded = false;
        subjectOfInsurance.propertyCoverageList.forEach((item: PropertyCoverage) => {
            if (item.typeCode === 'PropertyCoverageCodeListBusinessPersonalProperty') {
                limit = item.limit;
                founded = true;
                return;
            }
        });
        if (founded) {
            if (limit.amount > 0) {
                return <MoneyDisplay fractionDigits={0} value={limit} />;
            }
            return <Text style="label 1">included</Text>;
        }
        return <Text style="label 1">None</Text>;
    }

    function getPolicyDeductible(subjectOfInsurance: Immutable<SubjectOfInsurance>): JSX.Element {
        const policyDeductible = subjectOfInsurance.deductible;
        return <MoneyDisplay fractionDigits={0} value={policyDeductible ?? USD(0)} />;
    }

    return (
        <StackLayout gap="none">
            <Text style="heading 5">What's covered</Text>
            <Table>
                <Table.Header>
                    <Table.Column>BUILDING COVERAGE</Table.Column>
                    <Table.Column>BUSINESS PROPERTY PERSONAL COVERAGE</Table.Column>
                    <Table.Column>DEDUCTIBLE</Table.Column>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>{getBuildingCoverage(subjectOfInsurance)}</Table.Cell>
                        <Table.Cell>
                            {getBusinessPersonalPropertyCoverage(subjectOfInsurance)}
                        </Table.Cell>
                        <Table.Cell>{getPolicyDeductible(subjectOfInsurance)}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </StackLayout>
    );
};
