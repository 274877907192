import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ExcessQuote } from '../entities/ExcessQuote';
import { ExcessQuoteRepository } from '../repositories/EmbrokerExcessQuoteRepository';

export interface GetLastExcessQuoteRequest {
    applicationId: UUID;
}

export interface GetLastExcessQuote extends UseCase {
    execute(
        request: GetLastExcessQuoteRequest,
    ): AsyncResult<ExcessQuote, InvalidArgument | OperationFailed>;
}

@injectable()
class GetLastExcessQuoteUseCase extends UseCase implements GetLastExcessQuote {
    public static type = Symbol('ExcessQuote/GetLastExcessQuote');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ExcessQuoteRepository) private excessQuoteRepository: ExcessQuoteRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
    }: GetLastExcessQuoteRequest): AsyncResult<ExcessQuote, InvalidArgument | OperationFailed> {
        return await this.excessQuoteRepository.getLastExcessQuote(applicationId);
    }
}

export const GetLastExcessQuote: UseCaseClass<GetLastExcessQuote> = GetLastExcessQuoteUseCase;
