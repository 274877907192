import { ContainerModule } from '@embroker/shotwell/core/di';
import { Module } from '../../Module';
import { GetPCoMLEndorsementPolicy } from './useCases/GetPCoMLEndorsementPolicy';
import { PCoMLEndorsementRepository } from './repositories/PCoMLEndorsementRepository';
import { APIPCoMLEndorsementRepository } from './repositories/PCoMLEndorsementRepository/APIPCoMLEndorsementRepository';
import { RatePCoMLEndorsement } from './useCases/RatePCoMLEndorsement';
import { PurchasePCoMLEndorsement } from './useCases/PurchasePCoMLEndorsement';
import { GetPCoMLEndorsementUserData } from './useCases/GetPCoMLEndorsementUserData';
import { GetPCoMLEndorsementConfig } from './useCases/GetPCoMLEndorsementConfig';

export const PCoMLEndorsementModule: Module = {
    container: new ContainerModule((bind) => {
        bind<PCoMLEndorsementRepository>(PCoMLEndorsementRepository)
            .to(APIPCoMLEndorsementRepository)
            .inSingletonScope();
        bind<GetPCoMLEndorsementPolicy>(GetPCoMLEndorsementPolicy.type)
            .to(GetPCoMLEndorsementPolicy)
            .inSingletonScope();
        bind<GetPCoMLEndorsementUserData>(GetPCoMLEndorsementUserData.type)
            .to(GetPCoMLEndorsementUserData)
            .inSingletonScope();
        bind<RatePCoMLEndorsement>(RatePCoMLEndorsement.type)
            .to(RatePCoMLEndorsement)
            .inSingletonScope();
        bind<PurchasePCoMLEndorsement>(PurchasePCoMLEndorsement.type)
            .to(PurchasePCoMLEndorsement)
            .inSingletonScope();
        bind<GetPCoMLEndorsementConfig>(GetPCoMLEndorsementConfig.type)
            .to(GetPCoMLEndorsementConfig)
            .inSingletonScope();
    }),
};
