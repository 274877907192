import {
    defineValidator,
    Joi,
    Schema,
    TypeChecker,
    Validator,
} from '@embroker/shotwell/core/validation/schema';

export const PolicySortByExpirationDate = 'Expiration Date';
export const PolicySortByAlphabet = 'A to Z';
export const PolicySortByPremiumAmount = 'Premium amount';
export const PolicySortByStartDate = 'Start Date';

const PolicySortByList = [
    PolicySortByExpirationDate,
    PolicySortByAlphabet,
    PolicySortByPremiumAmount,
    PolicySortByStartDate,
];

export type PolicySortBy = (typeof PolicySortByList)[number];

export interface PolicySortByValidator {
    /**
     * A Joi schema matching a valid PolicySortBy object.
     */
    readonly schema: Schema.StringSchema;
    /**
     * Type predicate that checks if a given value can be used as PolicySortBy type.
     *
     * Use this only to do early returns. It's recommended to use validate()
     * before using an unknown value as PolicySortBy value as it normalizes the value
     * in addition to performing the same validation as check().
     */
    readonly check: TypeChecker<PolicySortBy>;
    /**
     * Validates and normalizes the given value to a PolicySortBy type.
     *
     * This should be used for all untrusted inputs to verify and, if required
     * (and possible), normalize the input.
     */
    readonly validate: Validator<PolicySortBy>;
}

export const PolicySortBy: PolicySortByValidator = defineValidator<PolicySortBy>(
    Joi.string().valid(...PolicySortByList),
);
