import { errorCodes } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { ErrorObject } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';

export const ErrorCode = errorCodes({
    InvalidClaimRequestSave: 0x7402,
});

export type InvalidClaimRequestSave = ErrorObject<
    typeof ErrorCode.InvalidClaimRequestSave,
    { policyId: Immutable<UUID> }
>;

export function InvalidClaimRequestSave(
    policyId: Immutable<UUID>,
): Immutable<InvalidClaimRequestSave> {
    return {
        name: 'InvalidClaimRequestSave',
        code: ErrorCode.InvalidClaimRequestSave,
        message: `Failed to save claim request for policy with id ${policyId}`,
        details: {
            policyId,
        },
    };
}
