import { Nullable } from '@embroker/shotwell/core/types';
import {
    defineValidator,
    Joi,
    Schema,
    TypeChecker,
    Validator,
} from '@embroker/shotwell/core/validation/schema';
import { Driver } from './Driver';
import { LiabilitySection } from './LiabilitySection';
import { Vehicle } from './Vehicle';

/**
 * Represents AUTO liability section object
 */
export interface AutoLiabilitySection extends LiabilitySection {
    /**
     * List of drivers
     */
    driverList: Nullable<Driver[]>;
    /**
     * Properties list
     */
    propertyCoverageList: Nullable<object[]>;
    /**
     * List of vehicles
     */
    vehicleList: Nullable<Vehicle[]>;
}

export interface AutoLiabilitySectionValidator {
    /**
     * A Joi schema matching a valid LobAuto object.
     */
    readonly schema: Schema.ObjectSchema<AutoLiabilitySection>;
    /**
     * Type predicate that checks if a given value can be used as AutoLiabilitySection object.
     *
     * Use this only to do early returns. It's recommended to use validate()
     * before using an unknown value as AutoLiabilitySection object as it normalizes the value
     * in addition to performing the same validation as check().
     */
    readonly check: TypeChecker<AutoLiabilitySection>;
    /**
     * Validates and normalizes the given value to a AutoLiabilitySection object.
     *
     * This should be used for all untrusted inputs to verify and, if required
     * (and possible), normalize the input.
     */
    readonly validate: Validator<AutoLiabilitySection>;
}

export const AutoLiabilitySection = {
    ...defineValidator<AutoLiabilitySection>(
        LiabilitySection.schema.keys({
            driverList: Joi.array().items(Driver.schema).allow(null),
            propertyCoverageList: Joi.array().items(Joi.object()).allow(null),
            vehicleList: Joi.array().items(Vehicle.schema).allow(null),
        }),
    ),
    create(lobAuto: AutoLiabilitySection) {
        return AutoLiabilitySection.validate(lobAuto);
    },
};
