import { Money, USD } from '@embroker/shotwell/core/types/Money';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { InvoiceTable, StatusMessage } from '@embroker/ui-toolkit/v2';
import React, { useContext } from 'react';
import { hasRole } from '../../../../userOrg/entities/Session';
import { AppContext } from '../../../../view/AppContext';
import { ESPRenewalQuote } from '../../entities/ESPRenewalQuote';
import { Coverage } from '../../types/Coverage';
import { espRenewalCoverageDetailsMap, minimumPremiumAmount } from '../config';

export interface ESPRenewalQuoteBreakdownProps {
    quote: ESPRenewalQuote;
}

const isMississippiState = (quote: ESPRenewalQuote): boolean => {
    return quote.userInfo?.usaState == 'MS';
};

const buildTitle = (coverage: Coverage) => {
    if (coverage.level === 'plus') {
        return espRenewalCoverageDetailsMap[coverage.type].title + ' Plus';
    }
    return espRenewalCoverageDetailsMap[coverage.type].title;
};

const displayReferredWarningMessage = () => {
    return (
        <StatusMessage status="warning">
            Your quote requires underwriting review. Please select your requested coverage, limits,
            and retentions. If applicable, you will be asked a few additional questions before being
            submitted to underwriting. We will contact you as soon as possible.
        </StatusMessage>
    );
};

export function ESPRenewalQuoteBreakdown({ quote }: ESPRenewalQuoteBreakdownProps) {
    const { activeSession } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');

    const selectedCoverages = Object.keys(quote.options.coverage)
        .filter((key) => quote.options.coverage[key].isSelected)
        .map((key) => quote.options.coverage[key]);
    const isMississippi = isMississippiState(quote);

    const showMinimumPremium =
        Money.isLessThan(quote.details.totalCoverages, minimumPremiumAmount) &&
        Money.isGreaterThan(quote.totalPayable, minimumPremiumAmount);
    const showAnnualTechFee = (quote.annualTechnologyFee?.amount ?? 0) > 0;
    const isQuoteReferred = quote?.status === 'referred';
    const isPremiumVisible = quote && !isQuoteReferred;

    if (isQuoteReferred) {
        return displayReferredWarningMessage();
    }
    return (
        <InvoiceTable>
            <InvoiceTable.Section>
                <InvoiceTable.Subtotal data-e2e="selected-coverages-list" title="Coverages" />
                {selectedCoverages.map((coverage) => {
                    return (
                        <InvoiceTable.Item key={coverage.type} title={buildTitle(coverage)}>
                            {isPremiumVisible && <MoneyDisplay value={coverage.premium} />}
                        </InvoiceTable.Item>
                    );
                })}
            </InvoiceTable.Section>
            <InvoiceTable.Section>
                <InvoiceTable.Subtotal title="Total coverages">
                    {isPremiumVisible && <MoneyDisplay value={quote.details.totalCoverages} />}
                </InvoiceTable.Subtotal>
            </InvoiceTable.Section>
            <InvoiceTable.Section>
                <InvoiceTable.Subtotal title="Subtotal">
                    {isPremiumVisible && <MoneyDisplay value={quote.details.subtotal} />}
                </InvoiceTable.Subtotal>
                {showMinimumPremium && (
                    <InvoiceTable.Item
                        title="Minimum Premium"
                        tooltip="The minimum we can charge for a policy premium for Embroker’s Startup Package is $3,500. Your policy does not meet the minimum premium, so we have to adjust the cost of your policy accordingly."
                    >
                        {isPremiumVisible && <MoneyDisplay value={minimumPremiumAmount} />}
                    </InvoiceTable.Item>
                )}
                {isMississippi && (
                    <InvoiceTable.Item
                        title="MWUA Fee"
                        tooltip="Mississippi Windstorm Underwriting Agency State Policy Fee - a 3% fee on gross premiums plus any policy fee charged for surplus
						lines policies written in Mississippi. "
                    >
                        {isPremiumVisible && (
                            <MoneyDisplay value={quote.details.mwuaFee ?? USD(0)} />
                        )}
                    </InvoiceTable.Item>
                )}
                <InvoiceTable.Item
                    title="Taxes"
                    tooltip="Surplus Line Tax is required on all surplus line insurance transactions and vary by state."
                >
                    {isPremiumVisible && <MoneyDisplay value={quote.details.taxes} />}
                </InvoiceTable.Item>
                <InvoiceTable.Item
                    title="Fees (excluding transaction fees based on your choice of payment)"
                    tooltip="Often referred to as “Stamping Fees”, Surplus Lines Fees are only required by certain states. In the applicable states Surplus Line Fees are charged on all insurance transactions. Embroker does not charge our clients any fees."
                >
                    {isPremiumVisible && <MoneyDisplay value={quote.details.fees} />}
                </InvoiceTable.Item>
                {isBroker && (
                    <InvoiceTable.Item title="Embroker Access Fee">
                        {isPremiumVisible && <MoneyDisplay value={quote.details.policyFee} />}
                    </InvoiceTable.Item>
                )}
                {showAnnualTechFee && (
                    <InvoiceTable.Item
                        title="Annual technology fee"
                        tooltip="The Annual technology fee is applied once a year with the first policy purchase of the year."
                    >
                        {isPremiumVisible && (
                            <MoneyDisplay value={quote.annualTechnologyFee ?? USD(0)} />
                        )}
                    </InvoiceTable.Item>
                )}
                <InvoiceTable.Total title="Total: ">
                    {isPremiumVisible && <MoneyDisplay value={quote.totalPayable} />}
                </InvoiceTable.Total>
            </InvoiceTable.Section>
        </InvoiceTable>
    );
}
