import { Filter, StackLayout, ColumnLayout } from '@embroker/ui-toolkit/v2';
import React, { useMemo, useState } from 'react';
import { PoliciesPerYear } from '../../types/PoliciesPerYear';
import { PolicyWithClaims } from '../../types/PolicyWithClaims';
import { PolicyWithClaimsCard } from './PolicyWithClaimsCard';

interface ClaimsListProps {
    borPolicies: PoliciesPerYear[];
    nonBORPolicies: PoliciesPerYear[];
}

export function ClaimsList({ borPolicies }: ClaimsListProps) {
    const { filterOptions, latestYear } = useFilterData(borPolicies);
    const [selectedYear, setSelectedYear] = useState(latestYear);

    const policiesByYear = useMemo(() => {
        const policiesMap = new Map<string, PolicyWithClaims[]>();
        for (const yearPolicies of borPolicies) {
            policiesMap.set(
                yearPolicies.year.toString(),
                sortPoliciesAndClaims(yearPolicies.policies),
            );
        }
        return policiesMap;
    }, [borPolicies]);

    return (
        <StackLayout gap="24">
            <StackLayout>
                <ColumnLayout>
                    <Filter
                        data-e2e="claims-list-filter"
                        title="View claims for coverage year"
                        value={selectedYear.toString()}
                        options={filterOptions}
                        onChange={(event: any) => {
                            setSelectedYear(event.target.value);
                        }}
                    />
                </ColumnLayout>
            </StackLayout>
            <StackLayout>
                {(policiesByYear.get(selectedYear) || []).map((policy: PolicyWithClaims) => {
                    return (
                        <ColumnLayout data-e2e="claims-list-claims-card" key={policy.id} split="-1">
                            <PolicyWithClaimsCard {...policy}></PolicyWithClaimsCard>
                        </ColumnLayout>
                    );
                })}
            </StackLayout>
        </StackLayout>
    );
}
interface FilterData {
    filterOptions: { title: string; value: string }[];
    latestYear: string;
}

function useFilterData(policies: PoliciesPerYear[]): FilterData {
    const filterOptions = useMemo(() => {
        return policies.map((yearlyData) => {
            return { title: yearlyData.year.toString(), value: yearlyData.year.toString() };
        });
    }, [policies]);

    const latestYear = useMemo(() => {
        return policies
            .reduce((maxYear, currentYear) => {
                return Number.parseInt(maxYear.year.toString()) >
                    Number.parseInt(currentYear.year.toString())
                    ? maxYear
                    : currentYear;
            })
            .year.toString();
    }, [policies]);

    return { filterOptions, latestYear };
}

function sortPoliciesByDate(policies: PolicyWithClaims[]): PolicyWithClaims[] {
    return policies.sort((a: PolicyWithClaims, b: PolicyWithClaims) => {
        return b.startDate.getTime() - a.startDate.getTime();
    });
}

function sortClaimsByDate(policy: PolicyWithClaims): PolicyWithClaims {
    policy.claimList =
        policy.claimList?.sort((a, b) => {
            return b.reportedDate.getTime() - a.reportedDate.getTime();
        }) || [];
    return policy;
}

function sortPoliciesAndClaims(policies: PolicyWithClaims[]): PolicyWithClaims[] {
    let claimFiledPolicies: PolicyWithClaims[] = [];
    let claimPolicies: PolicyWithClaims[] = [];

    for (const policy of policies) {
        policy.totalClaims > 0 ? claimFiledPolicies.push(policy) : claimPolicies.push(policy);
    }

    claimFiledPolicies = sortPoliciesByDate(claimFiledPolicies);
    claimPolicies = sortPoliciesByDate(claimPolicies);
    const sortedPolicies = claimFiledPolicies.concat(claimPolicies);

    for (let policy of sortedPolicies) {
        policy = sortClaimsByDate(policy);
    }

    return sortedPolicies;
}
