import { ContainerModule } from '@embroker/shotwell/core/di';
import { Module } from '../../Module';
import { RemoveSignaturePacket } from '../brokerage/useCases/RemoveSignaturePacket';
import { RequestHigherLimits } from '../brokerage/useCases/RequestHigherLimits';
import { UploadSignaturePacket } from '../brokerage/useCases/UploadSignaturePacket';
import { InsuranceApplicationRestrictionRepository } from './repositories/InsuranceApplicationRestrictionRepository';
import { APIInsuranceApplicationRestrictionRepository } from './repositories/InsuranceApplicationRestrictionRepository/APIInsuranceApplicationRestrictionRepository';
import { QuoteRepository } from './repositories/QuoteRepository';
import { APIQuoteRepository } from './repositories/QuoteRepository/APIQuoteRepository';
import { GenerateQuoteFileUrl } from './useCases/GenerateQuoteFileUrl';
import { GetQuote } from './useCases/GetQuote';
import { GetRestriction } from './useCases/GetRestriction';
import { PurchasePCoML } from './useCases/PurchasePCoML';
import { UpdateQuote } from './useCases/UpdateQuote';

export const PCoMLQuoteModule: Module = {
    container: new ContainerModule((bind) => {
        bind<QuoteRepository>(QuoteRepository).to(APIQuoteRepository).inSingletonScope();
        bind<GetQuote>(GetQuote.type).to(GetQuote).inSingletonScope();
        bind<PurchasePCoML>(PurchasePCoML.type).to(PurchasePCoML).inSingletonScope();
        bind<UpdateQuote>(UpdateQuote.type).to(UpdateQuote).inSingletonScope();
        bind<GenerateQuoteFileUrl>(GenerateQuoteFileUrl.type)
            .to(GenerateQuoteFileUrl)
            .inSingletonScope();
        bind<UploadSignaturePacket>(UploadSignaturePacket.type)
            .to(UploadSignaturePacket)
            .inSingletonScope();
        bind<RemoveSignaturePacket>(RemoveSignaturePacket.type)
            .to(RemoveSignaturePacket)
            .inSingletonScope();
        bind<RequestHigherLimits>(RequestHigherLimits.type)
            .to(RequestHigherLimits)
            .inSingletonScope();
        bind<GetRestriction>(GetRestriction.type).to(GetRestriction).inSingletonScope();
        bind<InsuranceApplicationRestrictionRepository>(
            APIInsuranceApplicationRestrictionRepository,
        )
            .to(APIInsuranceApplicationRestrictionRepository)
            .inSingletonScope();
    }),
};
