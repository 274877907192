import { Immutable } from '@embroker/shotwell/core/types';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { Card, CheckBox, ColumnLayout, StatusLabel, Text, useModal } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { DisplayPolicy } from '../../../../policy/types/DisplayPolicy';
import { ProductIcon } from '../../../../shopping/view/components/ProductIcon';
import { mapQuotingEngineToAppType } from '../brokerClientPage/ClientPagePolicies';
import { PoliciesSelected, SelectPolicySequenceModal } from './SelectPolicySequenceModal';

export function PolicyItem({
    policy,
    selected,
    hasMultiple,
    handleSelect,
}: {
    policy: Immutable<DisplayPolicy>;
    selected: PoliciesSelected;
    handleSelect: (value: PoliciesSelected) => void;
    hasMultiple: boolean;
}) {
    const selectPolicySequenceModal = useModal();

    const isPolicyProcessed = policy.policyNumber !== '';

    const handleCheck = (checked: boolean) => {
        if (checked === false) {
            return handleSelect('none');
        }
        if (hasMultiple) {
            selectPolicySequenceModal.show();
        } else {
            handleSelect('latest');
        }
    };
    const type = mapQuotingEngineToAppType(policy.quotingEngine);
    return (
        <React.Fragment>
            <Card
                input={
                    isPolicyProcessed ? (
                        <CheckBox
                            checked={selected !== 'none'}
                            onChange={(event) => {
                                handleCheck(event.target.checked);
                            }}
                        />
                    ) : undefined
                }
                className="u-grid-size-4"
                appearance="loose"
            >
                <Card.Header>
                    <ProductIcon type={type ?? 'AppTypeCodeListESP'}></ProductIcon>
                    <Text style="heading 5">{policy.displayName}</Text>
                </Card.Header>
                <Card.Body>
                    <ColumnLayout gap="4">
                        <Text color="ui-400">Number:</Text>
                        <Text color="ui-500">{policy.policyNumber}</Text>
                    </ColumnLayout>
                    <ColumnLayout gap="4">
                        <Text color="ui-400">Term:</Text>
                        <Text color="ui-500">
                            <DateDisplay format="MMM dd, yyyy" value={policy.startDate} />
                        </Text>
                        <Text color="ui-400">-</Text>
                        <Text color="ui-500">
                            <DateDisplay format="MMM dd, yyyy" value={policy.endDate} />
                        </Text>
                    </ColumnLayout>
                </Card.Body>
                <Card.Footer>
                    {!isPolicyProcessed ? (
                        <ColumnLayout split="-1">
                            <StatusLabel type="yellow-outline">Processing</StatusLabel>
                        </ColumnLayout>
                    ) : null}
                </Card.Footer>
            </Card>
            {hasMultiple && (
                <SelectPolicySequenceModal
                    setValue={handleSelect}
                    value={selected}
                    modal={selectPolicySequenceModal}
                />
            )}
        </React.Fragment>
    );
}
