import { Money } from '@embroker/shotwell/core/types/Money';
import { defineValidator } from '@embroker/shotwell/core/validation/schema';

export interface PCoMLEndorsementRate {
    annualPremium: Money;
    fees: Money;
    taxes: Money;
    prorate: Money;
    total: Money;
}

export const PCoMLEndorsementRate = {
    ...defineValidator<PCoMLEndorsementRate>({
        annualPremium: Money.schema.required(),
        fees: Money.schema.required(),
        taxes: Money.schema.required(),
        prorate: Money.schema.required(),
        total: Money.schema.required(),
    }),
    create(pcomlEndorsementRate: PCoMLEndorsementRate) {
        return PCoMLEndorsementRate.validate(pcomlEndorsementRate);
    },
};
