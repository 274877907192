import { StackLayout, Text, ColumnLayout } from '@embroker/ui-toolkit/v2';
import React, { useContext } from 'react';
import { PCoMLQuote } from '../../entities/PCoMLQuote';
import { pcomlQuotesCoverageDetailsMap } from '../config';
import { PCOMLQuoteModalSummary } from './PCoMLQuoteModalSummary';
import { getStateFromPCoMLQuote, sum } from './QuoteBreakdown';
import { AppContext } from '../../../../view/AppContext';
import { hasRole } from '../../../../userOrg/entities/Session';
import { USD } from '@embroker/shotwell/core/types/Money';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';

export function PCOMLQuoteSummary({ quote }: { quote: PCoMLQuote }) {
    const { activeSession } = useContext(AppContext);
    const coverages = [
        { coverageType: 'dno', ...quote.options['dno'] },
        { coverageType: 'epl', ...quote.options['epl'] },
    ];
    const excludedCoverages = coverages
        .filter((coverage) => coverage.isSelected === false)
        .map((coverage) => pcomlQuotesCoverageDetailsMap[coverage.coverageType].title);
    let message = null;
    if (excludedCoverages.length === 1) {
        message = (
            <Text>
                <b>{excludedCoverages[0]}</b> coverage was not included in this quote.
            </Text>
        );
    }

    const isBroker = hasRole(activeSession, 'broker');
    const dnoCoveragePart = quote.details.dno.premium;
    const eplCoveragePart = quote.details.epl.premium;
    const totalCoverages = sum([
        dnoCoveragePart,
        eplCoveragePart,
        quote.details.policyAdministrationFee ?? USD(0),
        quote.details.sociusEndorsementPremium ?? USD(0),
    ]);
    const state = getStateFromPCoMLQuote(quote);
    const isKentucky = state === 'KY';
    const totalPayable = isBroker ? totalCoverages : quote.totalPayable;

    return (
        <StackLayout gap="24">
            <Text>
                You are about to bind a policy
                {quote.applicationInfo?.userInfo?.company
                    ? ` for ${quote.applicationInfo.userInfo.company}`
                    : null}
                . Please confirm that the details listed below are correct:
            </Text>
            <PCOMLQuoteModalSummary quote={quote} />
            <ColumnLayout split="-1">
                <Text style="body 1">Total premium:</Text>
                <Text style="heading 5">
                    {isKentucky ? 'Forthcoming' : <MoneyDisplay value={totalPayable} />}
                </Text>
            </ColumnLayout>
            <Text style="microcopy">{message}</Text>
        </StackLayout>
    );
}
