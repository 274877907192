import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { FeatureRolloutRepository, GetEnrolledUserFeaturesResponse } from '../repositories';
import { APIFeatureRolloutRepository } from '../repositories/APIFeatureRolloutRepository';
import { GetUserEnrolledFeaturesError } from '../errors';

export interface GetEnrolledUserFeatures extends UseCase {
    execute(): AsyncResult<GetEnrolledUserFeaturesResponse, GetUserEnrolledFeaturesError>;
}

@injectable()
export class GetEnrolledUserFeaturesUseCase extends UseCase implements GetEnrolledUserFeatures {
    public static type = Symbol('FeatureRollout/GetEnrolledUserFeatures');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(FeatureRolloutRepository)
        private featureRolloutRepository: APIFeatureRolloutRepository,
    ) {
        super(eventBus);
    }

    async execute(): AsyncResult<GetEnrolledUserFeaturesResponse, GetUserEnrolledFeaturesError> {
        const apiResult = await this.featureRolloutRepository.getUserEnrolledFeatures();

        if (isErr(apiResult)) {
            return apiResult;
        }

        return Success({
            featuresEnrolled: apiResult.value.featuresEnrolled,
        });
    }
}

export const GetEnrolledUserFeatures: UseCaseClass<GetEnrolledUserFeatures> =
    GetEnrolledUserFeaturesUseCase;
