import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { compareDesc } from 'date-fns';
import { Invoice } from '../entities/Invoice';
import { InvoiceRepository } from '../repositories/InvoiceRepository';
import { Payment } from '../types/Payment';
import { isPaymentInBundleMap } from './GetPendingInvoices';
import { buildPaymentUI } from './GetSelectedInvoiceList';

export interface GetInvoicesRequest {
    organizationId: UUID;
}

export interface GetInvoicesResponse {
    creditInvoices: Immutable<Invoice[]>;
    chargedInvoices: Immutable<Payment[]>;
}

function compareByCreatedDate(item1: Payment, item2: Payment): number {
    if (item1.createdDate != null && item2.createdDate != null) {
        return compareDesc(item1.createdDate, item2.createdDate);
    }
    return -1;
}

export interface GetInvoiceHistory extends UseCase {
    execute(
        request: GetInvoicesRequest,
    ): AsyncResult<GetInvoicesResponse, InvalidArgument | OperationFailed>;
}

export function createBundleInvoiceMap(
    bundleInvoicesMap: Map<UUID, Immutable<Invoice>[]>,
    invoice: Immutable<Invoice>,
    bundleId?: UUID,
): Map<UUID, Immutable<Invoice>[]> {
    if (bundleId == undefined) {
        return bundleInvoicesMap;
    }
    if (isPaymentInBundleMap(bundleInvoicesMap, bundleId)) {
        const value = bundleInvoicesMap.get(bundleId);
        if (value) {
            return bundleInvoicesMap.set(bundleId, [...value, invoice]);
        }
    }
    return bundleInvoicesMap.set(bundleId, [invoice]);
}

@injectable()
class GetInvoiceHistoryUseCase extends UseCase implements GetInvoiceHistory {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Payments/GetInvoiceHistory');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(InvoiceRepository) private invoiceRepo: InvoiceRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        data: GetInvoicesRequest,
    ): AsyncResult<GetInvoicesResponse, InvalidArgument | OperationFailed> {
        const filteredInvoices = await this.invoiceRepo.getFiltered(data.organizationId);
        if (isErr(filteredInvoices)) {
            return filteredInvoices;
        }

        const { singleInvoiceUIList, bundleInvoiceUIList } = buildPaymentUI(
            filteredInvoices.value.chargedPayments,
        );

        const sortedPaymentHistoryUI = [...singleInvoiceUIList, ...bundleInvoiceUIList].sort(
            compareByCreatedDate,
        );

        const response: GetInvoicesResponse = {
            creditInvoices: filteredInvoices.value.creditPayments,
            chargedInvoices: sortedPaymentHistoryUI,
        };

        return Success(response);
    }
}

export const GetInvoiceHistory: UseCaseClass<GetInvoiceHistory> = GetInvoiceHistoryUseCase;
