import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { Organization } from '../entities/Organization';
import { User } from '../entities/User';
import { OrganizationRepository } from '../repositories/OrganizationRepository';

/**
 * Request data for RemoveUserFromOrganization use case
 * @param user is the user entity which we want to remove from the organization
 * @param organization is the organization from which we want to remove the user
 */
export interface RemoveUserFromOrganizationRequest {
    user: User;
    organization: Organization;
}

/**
 * RemoveUserFromOrganization use case is used to remove the member user from organization
 */
export interface RemoveUserFromOrganization extends UseCase {
    execute(
        request: RemoveUserFromOrganizationRequest,
    ): AsyncResult<void, InvalidArgument | UnknownEntity | OperationFailed>;
}

@injectable()
class RemoveUserFromOrganizationUseCase extends UseCase implements RemoveUserFromOrganization {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/RemoveUserFromOrganization');
    /**
     * Constructor for RemoveUserFromOrganization use case class instance
     * @param eventBus An event bus this Use Case will publish events to.
     * @param organizationRepository is the organization repository used to save the organization from which user was removed from
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(OrganizationRepository) private organizationRepository: OrganizationRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes RemoveUserFromOrganization use case
     * Input is of type RemoveUserFromOrganizationRequest
     * @returns Nothing if execution was successful
     * @returns InvalidArgument error if organization repository failed to save organization entity in which user was removed from
     * @returns UnknownEntity error if provided user or organization do not exist on the platform
     */
    public async execute({
        user,
        organization,
    }: RemoveUserFromOrganizationRequest): AsyncResult<
        void,
        InvalidArgument | UnknownEntity | OperationFailed
    > {
        const orgResult = await this.organizationRepository.getOrganization(organization.id);

        if (isErr(orgResult)) {
            return orgResult;
        }

        const userRoleResult = orgResult.value.getUserRole(user);

        if (isErr(userRoleResult)) {
            return userRoleResult;
        }

        orgResult.value.removeUser(userRoleResult.value);
        const result = await this.organizationRepository.save(orgResult.value as Organization);

        if (isErr(result)) {
            return result;
        }

        this.eventBus.publishEntityEvents(result.value);
        return Success();
    }
}

export const RemoveUserFromOrganization: UseCaseClass<RemoveUserFromOrganization> =
    RemoveUserFromOrganizationUseCase;
