import { inject } from '@embroker/shotwell/core/di';
import { OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ApplicationNotFound, OperationNotAllowed } from '../../shopping/errors';
import { ApplicationRepository } from '../../shopping/repositories/ApplicationRepository';

export interface CompleteShareableApplicationRequest {
    token: string;
}

export interface CompleteShareableApplication extends UseCase {
    execute(
        request: CompleteShareableApplicationRequest,
    ): AsyncResult<void, ApplicationNotFound | OperationNotAllowed | OperationFailed>;
}

class CompleteShareableApplicationUseCase extends UseCase implements CompleteShareableApplication {
    public static type = Symbol('Shopping/CompleteShareableApplication');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
    ) {
        super(eventBus);
    }
    public async execute({
        token,
    }: CompleteShareableApplicationRequest): AsyncResult<
        void,
        ApplicationNotFound | OperationNotAllowed | OperationFailed
    > {
        return await this.applicationRepository.completeShareableApplication(token);
    }
}

export const CompleteShareableApplication: UseCaseClass<CompleteShareableApplication> =
    CompleteShareableApplicationUseCase;
