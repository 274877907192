import { StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { FooterProps } from '../../coverageDefinition';
import { getMPLInsurer } from '../buildMPLCoverage';
import { MPLQuote } from '../entities/MPLQuote';

export function MPLFooter({ questionnaireData }: FooterProps<MPLQuote>) {
    const insurer = getMPLInsurer(
        questionnaireData?.locationWithLargestNumber?.state ?? questionnaireData.state,
    );
    return (
        <StackLayout gap="none">
            <Text color="ui-400" style="body 2" data-e2e="mpl-carrier">
                {`Professional Liability carrier: ${insurer}`}
            </Text>
            <Text color="ui-500" style="body 2" data-e2e="mpl-carrier-description">
                {`${insurer} is currently rated AM Best A- (Excellent).`}
            </Text>
        </StackLayout>
    );
}
