import { Immutable } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { UnderlyingCoverageProps } from '../../../view/components/BundleQuoteCoverageList';
import { CyberBundleCoverageView } from '../../../view/components/cyber/CyberBundleCoverageView';
import { LawCyberQuote } from '../entities/LawCyberQuote';
import { LawCyberLimit } from '../types/LawCyberQuoteOptions';
import { getRevenueFromQuestionnaire } from '../../../questionnaireData';

export function LawCyberBundleCoverageOptions(
    underlyingCoverageProps: UnderlyingCoverageProps<LawCyberQuote>,
) {
    const questionnaireData = underlyingCoverageProps.questionnaireData;
    const cyberRetentionOptions = getCyberRetentionOptions(
        getRevenueFromQuestionnaire(questionnaireData),
        questionnaireData.areas_of_practice.areas_of_practice_rows.map((aop) => aop.area),
    );
    return (
        <CyberBundleCoverageView
            {...underlyingCoverageProps}
            cyberLimitOptions={LawCyberLimit}
            cyberRetentionOptions={cyberRetentionOptions}
        />
    );
}

function getCyberRetentionOptions(
    revenue: number,
    areaOfPractice: readonly string[],
): Immutable<number[]> {
    const baseOptions: number[] = [10_000, 25_000, 50_000, 75_000, 100_000];
    const retentionRisingRevenue = 1_000_000;
    if (revenue > retentionRisingRevenue) {
        return baseOptions;
    }
    const retentionRisingAreasOfPractice = [
        'MergersAndAcquisitionsLess',
        'MergersAndAcquisitions',
        'FinancialInstitution',
        'IntellectualCopyright',
        'IntellectualTrademark',
        'IntellectualPatent',
        'MedicalMalpractice',
        'RealPropertyConveyanceResidential',
        'RealEstateCommercialLessThan5M',
        'RealEstateCommercialMoreThan5M',
        'RealEstateDevelopmentLandUse',
        'Securities',
        'SocialSecurity',
        'TaxOpinion',
        'TaxationOpinions',
    ];
    if (areaOfPractice.some((aop) => retentionRisingAreasOfPractice.includes(aop))) {
        return [5_000, ...baseOptions];
    }
    return [2_500, 5_000, ...baseOptions];
}
