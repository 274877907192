import { StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';

export function CowbellCyberFooter() {
    return (
        <StackLayout gap="none">
            <Text color="ui-400" style="body 2" data-e2e="cyber-carrier">
                Cyber Carrier: Cowbell Cyber Inc., underwritten by Spinnaker Insurance Company
            </Text>
            <Text color="ui-500" style="body 2" data-e2e="cyber-carrier-description">
                Spinnaker Insurance Company is currently rated AM Best A- (Excellent) with a
                Financial Size Category of VIII.
            </Text>
        </StackLayout>
    );
}
