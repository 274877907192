import { errorCodes } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { ErrorLike, ErrorObject } from '@embroker/shotwell/core/types/Result';

export const ErrorCode = errorCodes({
    CarrierNotFound: 0x2600,
    UwReviewFetchFailed: 0x6001,
    ExportUWReviewFromAPIResponseFailed: 0x6002,
});

export type CarrierNotFound = ErrorObject<typeof ErrorCode.CarrierNotFound>;

export function CarrierNotFound(): Immutable<CarrierNotFound> {
    return {
        name: 'CarrierNotFound',
        code: ErrorCode.CarrierNotFound,
        message: 'No matching carrier found',
    };
}

export type UwReviewFetchFailed = ErrorObject<typeof ErrorCode.UwReviewFetchFailed, {}>;

export function UwReviewFetchFailed(): Immutable<UwReviewFetchFailed> {
    return {
        name: 'UwReviewFetchFailed',
        code: ErrorCode.UwReviewFetchFailed,
        message: 'Failed to fetch UW Review.',
        details: {},
    };
}

export type ExportUWReviewFromAPIResponseFailed = ErrorObject<
    typeof ErrorCode.ExportUWReviewFromAPIResponseFailed,
    {}
>;

export function ExportUWReviewFromAPIResponseFailed(
    errors?: Immutable<ErrorLike[]>,
): Immutable<ExportUWReviewFromAPIResponseFailed> {
    return {
        name: 'ExportUWReviewFromAPIResponseFailed',
        code: ErrorCode.ExportUWReviewFromAPIResponseFailed,
        message: 'Failed to export UW Review from API response.',
        details: { errors },
    };
}
