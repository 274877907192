import { FormPageDefinition } from '@app/view/components/DataDrivenForm/hooks/useDataDrivenForm';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { MutuallyExclusiveProps } from '@embroker/ui-toolkit/v2';

type QuestionerPageDefinitionProps = MutuallyExclusiveProps<{
    fields: string[];
    subPages: QuestionerPageDefinition[];
}>;

export type QuestionerPageDefinition = QuestionerPageDefinitionProps & {
    name: string;
    title?: string;
    isInitial?: boolean;
};

export const extractFormPages = (
    formPageTree: QuestionerPageDefinition[],
): FormPageDefinition[] => {
    return formPageTree.reduce((accumulator, page) => {
        if (page.subPages) {
            return accumulator.concat(page.subPages as FormPageDefinition[]);
        }
        accumulator.push(page);
        return accumulator;
    }, [] as FormPageDefinition[]);
};

// The page logic wthin the useWizardForm assumes unique names for it to work
// As of now we cannot guarantee what will be returned fomr the BE so using getUniquePageNames to guarantee unique names
// TODO: This may not be the long therm solution, will be address if/when needed in https://embroker.atlassian.net/browse/EM-42345
export const _stubGetUniquePageNames = (
    formPageDefinitions: QuestionerPageDefinition[],
): QuestionerPageDefinition[] => {
    return formPageDefinitions.map((page) => {
        const uniqueName = `${page.name}.${UUID.create()}`;
        if ('subPages' in page && page.subPages) {
            const subPages = _stubGetUniquePageNames(page.subPages);
            return { ...page, name: uniqueName, subPages };
        } else {
            return { ...page, name: uniqueName };
        }
    });
};
