import { inject, injectable } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, Failure, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { isAuthenticated, Session } from '../entities/Session';
import { Unauthenticated } from '../errors';
import { SessionRepository } from '../repositories/SessionRepository';

/**
 * Response data for get active session use case
 */
export interface GetActiveSessionResponse {
    session: Immutable<EntityProps<Session>>;
}

/**
 * Get active session use case is used to obtain active session for the authenticated user
 */
export interface GetActiveSession extends UseCase {
    execute(): AsyncResult<
        GetActiveSessionResponse,
        InvalidArgument | OperationFailed | Unauthenticated
    >;
}

@injectable()
class GetActiveSessionUseCase extends UseCase implements GetActiveSession {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/GetActiveSession');
    /**
     * Constructor for get active session use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param sessionRepository
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(SessionRepository) private sessionRepository: SessionRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes get active session use case
     */
    public async execute(): AsyncResult<
        GetActiveSessionResponse,
        InvalidArgument | OperationFailed | Unauthenticated
    > {
        const result = await this.sessionRepository.getActiveSession();

        if (!isAuthenticated(result.value)) {
            return Failure(Unauthenticated());
        }

        return Success<GetActiveSessionResponse>({
            session: result.value.toDTO(),
        });
    }
}

export const GetActiveSession: UseCaseClass<GetActiveSession> = GetActiveSessionUseCase;
