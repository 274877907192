import { CoverLayout, Placeholder, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';

export function IssueCertificateStepGuidelines() {
    return (
        <CoverLayout>
            <StackLayout split="-1" gap="24">
                <StackLayout gap="4">
                    <Text style="overline">Step 5</Text>
                    <Text style="heading 3">Well done!</Text>
                </StackLayout>
                <Text style="body 1">
                    Something about making it all the way successfully. Yeah, buddy, you did it! You
                    can definitely do this again on your own.
                </Text>
                <Placeholder
                    backgroundColor="ui-50"
                    appearance="compact"
                    imageMaxWidth={270}
                    imageName="illustration-male-laptop"
                />
            </StackLayout>
        </CoverLayout>
    );
}
