import React, { SyntheticEvent, useMemo } from 'react';
import { container } from '@embroker/shotwell/core/di';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { URI } from '@embroker/shotwell/core/types/URI';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { Button } from '@embroker/ui-toolkit/v2';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { DisplayPolicy } from '../../../types/DisplayPolicy';
import { CardPayNowClicked } from '../../../entities/Policy';

interface PayPolicyButtonProps {
    policy: Immutable<DisplayPolicy>;
}

export const PayPolicyButton = ({ policy }: PayPolicyButtonProps) => {
    const navigation = useNavigation();
    const eventBus = useMemo(() => container.get<DomainEventBus>(DomainEventBus), []);

    async function handleOnClick(event: SyntheticEvent) {
        event.stopPropagation();

        const payNowClickedEvent: CardPayNowClicked = {
            origin: 'Policy',
            name: 'CardPayNowClicked',
            id: UUID.create(),
            createdAt: new Date(Date.now()),
            policyNumber: policy.policyNumber,
            isRenewal: policy.bookingType === 'PolicyBookingTypeRenewal',
        };

        await eventBus.publish(payNowClickedEvent);

        navigation.navigate(URI.build('/payments'));
    }

    return (
        <Button appearance="secondary" onClick={handleOnClick}>
            Pay Now
        </Button>
    );
};
