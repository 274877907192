import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isOK } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject } from '@embroker/shotwell/core/di';
import { BrokerRepository } from '../repositories';
import { QuickLinkTotals } from '../types/QuickLinkTotals';

export interface GetQuickLinkTotals extends UseCase {
    execute(): AsyncResult<QuickLinkTotals, InvalidArgument | OperationFailed>;
}

class GetQuickLinkTotalsUseCase extends UseCase implements GetQuickLinkTotals {
    public static type = Symbol('Broker/GetQuickLinkTotals');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BrokerRepository) private brokerRepository: BrokerRepository,
    ) {
        super(eventBus);
    }

    public async execute(): AsyncResult<QuickLinkTotals, InvalidArgument | OperationFailed> {
        const results = await Promise.allSettled([
            this.brokerRepository.getQuoteCount(),
            this.brokerRepository.getRenewalCount(),
            this.brokerRepository.getOrganizationCount(),
            this.brokerRepository.getPaymentCount(),
        ]);
        const allCounts: QuickLinkTotals = {};
        if (results[0].status === 'fulfilled' && isOK(results[0].value)) {
            allCounts.totalOpenQuotes = results[0].value.value;
        }
        if (results[1].status === 'fulfilled' && isOK(results[1].value)) {
            allCounts.totalRenewals = results[1].value.value;
        }
        if (results[2].status === 'fulfilled' && isOK(results[2].value)) {
            allCounts.totalAccounts = results[2].value.value;
        }
        if (results[3].status === 'fulfilled' && isOK(results[3].value)) {
            allCounts.totalPayments = results[3].value.value;
        }
        return QuickLinkTotals.create(allCounts);
    }
}

export const GetQuickLinkTotals: UseCaseClass<GetQuickLinkTotals> = GetQuickLinkTotalsUseCase;
