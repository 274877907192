import { StackLayout, Text, ColumnLayout } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { ESPQuote } from '../../entities/ESPQuote';
import { espCoverageDetails } from '../../../espUtils/quoteDocumentUtils';
import { ESPQuoteModalSummary } from './ESPQuoteModalSummary';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';

export function ESPQuoteSummary({ quote }: { quote: ESPQuote }) {
    let isTechCyberSplit = false;
    const selectedCoverages = quote.details.coverages.filter((coverage) => {
        if (coverage.coverageType === 'techCyber') {
            isTechCyberSplit = true;
        }
        return coverage.selected;
    });
    const excludedCoverages = (
        Object.keys(espCoverageDetails) as Array<keyof typeof espCoverageDetails>
    )
        .filter((type) => {
            if (isTechCyberSplit && type === 'eoCyber') {
                return false;
            }
            if (!isTechCyberSplit && type === 'techCyber') {
                return false;
            }
            return (
                selectedCoverages.find((coverage) => coverage.coverageType === type) === undefined
            );
        })
        .map((type) => espCoverageDetails[type].title);

    let message = null;
    if (excludedCoverages.length === 1) {
        message = (
            <Text>
                <b>{excludedCoverages[0]}</b> coverage was not included in this quote.
            </Text>
        );
    } else if (excludedCoverages.length === 2) {
        message = (
            <Text>
                <b>{excludedCoverages[0]}</b> and <b>{excludedCoverages[1]}</b> coverage were not
                included in this quote.
            </Text>
        );
    } else if (excludedCoverages.length === 3) {
        message = (
            <Text>
                <b>{excludedCoverages[0]}</b>, <b>{excludedCoverages[1]}</b> and{' '}
                <b>{excludedCoverages[2]}</b> coverage were not included in this quote.
            </Text>
        );
    }

    return (
        <StackLayout gap="24">
            <Text>
                You are about to bind a policy
                {quote.quoteInfo?.userInfo?.company
                    ? ` for ${quote.quoteInfo.userInfo.company}`
                    : null}
                . Please confirm that the details listed below are correct:
            </Text>
            <ESPQuoteModalSummary quote={quote} />
            <ColumnLayout split="-1">
                <Text style="body 1">Total premium:</Text>
                <Text style="heading 5" data-e2e="total-premium">
                    <MoneyDisplay value={quote.totalPayable} />
                </Text>
            </ColumnLayout>
            <Text style="microcopy">{message}</Text>
        </StackLayout>
    );
}
