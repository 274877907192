import { URI } from '@embroker/shotwell/core/types/URI';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface SignatureRequest {
    id: string;
    signingURL: URI;
}

export const SignatureRequest = {
    ...defineValidator<SignatureRequest>({
        id: Joi.string(),
        signingURL: Joi.string(),
    }),
    create(signatureRequest: SignatureRequest) {
        return SignatureRequest.validate(signatureRequest);
    },
};
