import { CertificateCoverageInfo } from './CertificateCoverageInfo';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface CertificateCoverageOther {
    coverageInfo: CertificateCoverageInfo;
}

export const CertificateCoverageOther = {
    ...defineValidator<CertificateCoverageOther>(
        Joi.object({
            coverageInfo: CertificateCoverageInfo.schema,
        }).preferences({ presence: 'required' }),
    ),
    create(certificateCoverageOther: CertificateCoverageOther) {
        return CertificateCoverageOther.validate(certificateCoverageOther);
    },
};
