import { Money } from '@embroker/shotwell/core/types/Money';
import { valueObject } from '@embroker/shotwell/core/types/ValueObject';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { QuoteDetails } from '../../../../quote/entities/Quote';

export interface WCChubbQuoteDetails extends QuoteDetails {
    readonly expirationDate: Date;
    readonly totalPremium: Money;
    readonly taxes: Money;
    readonly terrorismPremium: Money;
}

export const WCChubbQuoteDetails = valueObject({
    ...defineValidator<WCChubbQuoteDetails>({
        expirationDate: Joi.date().required(),
        totalPremium: Money.schema.required(),
        taxes: Money.schema.required(),
        terrorismPremium: Money.schema.required(),
    }),
});
