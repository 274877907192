import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { PolicyFilterRepository } from '../../policy/repositories/PolicyFilterRepository';
import { isAuthenticated, Session } from '../../userOrg/entities/Session';
import { InvitationExpired, InvitationNotFound, Unauthenticated } from '../errors';
import { BrokerRepository, BrokerSignUpRequest } from '../repositories';

/**
 * BrokerSignUp use case signs up invited broker.
 */
export interface BrokerSignUp extends UseCase {
    execute(
        request: BrokerSignUpRequest,
    ): AsyncResult<
        void,
        InvitationNotFound | InvitationExpired | OperationFailed | InvalidArgument | Unauthenticated
    >;
}

@injectable()
class BrokerSignUpUseCase extends UseCase implements BrokerSignUp {
    public static type = Symbol('Broker/BrokerSignUp');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BrokerRepository) private brokerRepository: BrokerRepository,
        @inject(PolicyFilterRepository) private policyFilterRepository: PolicyFilterRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: BrokerSignUpRequest,
    ): AsyncResult<
        void,
        InvitationNotFound | InvitationExpired | OperationFailed | InvalidArgument | Unauthenticated
    > {
        const signUpResult = await this.brokerRepository.signUpBroker(request);
        if (isErr(signUpResult)) {
            return signUpResult;
        }

        const sessionResult = await Session.create({
            authenticatedUserId: signUpResult.value.brokerId,
            userId: null,
            organizationId: null,
            expirationDateTime: null,
            roles: ['broker'],
        });

        if (isErr(sessionResult)) {
            return handleOperationFailure(sessionResult);
        }

        if (!isAuthenticated(sessionResult.value)) {
            return Failure(Unauthenticated());
        }

        sessionResult.value.onLoginByUser();

        await this.eventBus.publishEntityEvents(sessionResult.value);

        // reset policy filter to default
        const defaultFilter = this.policyFilterRepository.getDefault();
        this.policyFilterRepository.save(defaultFilter);

        return Success();
    }
}

export const BrokerSignUp: UseCaseClass<BrokerSignUp> = BrokerSignUpUseCase;
