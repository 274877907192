import { inject, injectable } from '@embroker/shotwell/core/di';
import { Aborted, InvalidArgument, OperationFailed, Timeout } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute, UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { GetDocumentUrl } from '../../../documents/useCases/GetDocumentUrl';
import { TasksRepository } from '../../../tasks/repositories';
import { DocumentType } from '../../types/Document';
import { LPLQuote } from '../entities/LPLQuote';
import { LPLQuoteRepository } from '../repositories/LPLQuoteRepository';

type LPLDocumentFileKey = string;
type LPLDocumentUrl = URI;

export interface CreateLPLDocumentRequest {
    applicationId: UUID;
    quoteId: UUID;
    documentType: DocumentType;
    fileKey?: string;
    abortSignal: AbortSignal;
}

interface CreateLPLDocumentResponse {
    fileKey: LPLDocumentFileKey;
    fileUrl: LPLDocumentUrl;
}

export interface CreateLPLDocument extends UseCase {
    execute(
        request: CreateLPLDocumentRequest,
    ): AsyncResult<
        CreateLPLDocumentResponse,
        InvalidArgument | OperationFailed | Aborted | Timeout
    >;
}

@injectable()
class CreateLPLDocumentUseCase extends UseCase implements CreateLPLDocument {
    public static type = Symbol('LPLQuote/CreateLPLDocument');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(LPLQuoteRepository) private lplQuoteRepository: LPLQuoteRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
        quoteId,
        documentType,
        fileKey,
        abortSignal,
    }: CreateLPLDocumentRequest): AsyncResult<
        CreateLPLDocumentResponse,
        InvalidArgument | OperationFailed | Aborted | Timeout
    > {
        const enqueueCreateDocumentTaskResult =
            await this.lplQuoteRepository.enqueueCreateLPLDocumentTask(
                applicationId,
                quoteId,
                documentType,
            );
        if (isErr(enqueueCreateDocumentTaskResult)) {
            return enqueueCreateDocumentTaskResult;
        }

        const pollForTaskStatusResult = await this.tasksRepository.pollForTaskStatus(
            enqueueCreateDocumentTaskResult.value.taskId,
            abortSignal,
        );
        if (isErr(pollForTaskStatusResult)) {
            return pollForTaskStatusResult;
        }
        if (pollForTaskStatusResult.value === '') {
            return Failure(
                OperationFailed({
                    message: `createLPLDocument polling invalid response data type. Expected non empty string'`,
                }),
            );
        }

        const getCurrentQuoteResult = await this.lplQuoteRepository.getLastLPLQuote(applicationId);
        if (isErr(getCurrentQuoteResult)) {
            return getCurrentQuoteResult;
        }
        if (quoteId !== getCurrentQuoteResult.value.lplQuote.id) {
            return Failure(
                OperationFailed({
                    message:
                        'createLPLDocument quoteId and lastQuoteId mismatch, quoteId: ' +
                        quoteId +
                        ', lastQuoteId: ' +
                        getCurrentQuoteResult.value.lplQuote.id,
                }),
            );
        }

        const fileKeyReloaded = getFileKey(getCurrentQuoteResult.value.lplQuote, documentType);
        if (!fileKeyReloaded) {
            return Failure(
                OperationFailed({
                    message: 'fileKey retrieved from lastQuote is null or undefined',
                }),
            );
        }
        const getDocumentUrlResult = await execute(GetDocumentUrl, { fileKey: fileKeyReloaded });
        if (isErr(getDocumentUrlResult)) {
            return getDocumentUrlResult;
        }
        return Success<CreateLPLDocumentResponse>({
            fileKey: fileKeyReloaded,
            fileUrl: URI.build(getDocumentUrlResult.value.downloadUrl),
        });
    }
}

function getFileKey(quote: LPLQuote, documentType: DocumentType): string | undefined {
    if (documentType === DocumentType.QuoteSummary) {
        return quote.fileKey;
    } else {
        return quote.details.specimenPolicyFileKey;
    }
}

export const CreateLPLDocument: UseCaseClass<CreateLPLDocument> = CreateLPLDocumentUseCase;
