import { VehicleUseCodeList, VehicleUseCodeListItem } from '@embroker/shotwell-api/enums';
import { Nullable } from '@embroker/shotwell/core/types/';
import { Money } from '@embroker/shotwell/core/types/Money';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import {
    defineValidator,
    Joi,
    Schema,
    TypeChecker,
    Validator,
} from '@embroker/shotwell/core/validation/schema';
import { LiabilityCoverage } from './LiabilityCoverage';

/**
 * Represents Vehicle description object
 */
export interface Vehicle {
    /**
     * Vehicle identifications number
     */
    vin: string;
    /**
     * Car make and model
     */
    name: string;
    /**
     * City where vehicle is being garaged
     */
    garagingCity: string;
    /**
     * State where vehicle is being garaged
     */
    garagingState: string;
    /**
     * Zip code where vehicle is being garaged
     */
    garagingZip: ZipCode;
    /**
     * In case of claim person that is reimbursed for a loss
     */
    lossPayee: Nullable<string>;
    /**
     *
     */
    liabilityList: LiabilityCoverage[];
    /**
     * Radius in which vehicle is driven where garage is centered
     */
    radius: string;
    /**
     * Type code defining car usage (for business, commercial purposes...)
     */
    usage: VehicleUseCodeListItem;
    /**
     * Deductible amount in case of non-collision clams
     */
    comprehensiveDeductible: Nullable<Money>;
    /**
     * Dollar amount paid for comprehensive auto coverage
     */
    comprehensivePremium: Nullable<Money>;
    /**
     * Deductible amount in case of collision clams
     */
    collisionDeductible: Nullable<Money>;
    /**
     * Dollar amount paid for collision auto coverage
     */
    collisionPremium: Nullable<Money>;
    /**
     * Rental Reimbursement Daily Limit
     */
    rentalReimbursementDailyLimit: Nullable<Money>;
    /**
     * Rental Reimbursement Max Limit
     */
    rentalReimbursementMaxLimit: Nullable<Money>;
    /**
     * Rental Reimbursement Premium
     */
    rentalReimbursementPremium: Nullable<Money>;
    /**
     * Towing And Labor Limit
     */
    towingAndLaborLimit: Nullable<Money>;
    /**
     * Towing And Labor Premium
     */
    towingAndLaborPremium: Nullable<Money>;
    /**
     * Collision Deductible Waiver Premium
     */
    collisionDeductibleWaiverPremium: Nullable<Money>;
    /**
     * Lease Loan Gap Premium
     */
    leaseLoanGapPremium: Nullable<Money>;
    /**
     * Audio Visual Data Equipment Premium
     */
    audioVisualDataEquipmentPremium: Nullable<Money>;
    /**
     * Audio Visual Data Equipment Limit
     */
    audioVisualDataEquipmentLimit: Nullable<Money>;
}

export interface VehicleValidator {
    /**
     * A Joi schema matching a valid Vehicle object.
     */
    readonly schema: Schema.ObjectSchema<Vehicle>;
    /**
     * Type predicate that checks if a given value can be used as Vehicle object.
     *
     * Use this only to do early returns. It's recommended to use validate()
     * before using an unknown value as Vehicle object as it normalizes the value
     * in addition to performing the same validation as check().
     */
    readonly check: TypeChecker<Vehicle>;
    /**
     * Validates and normalizes the given value to a Vehicle object.
     *
     * This should be used for all untrusted inputs to verify and, if required
     * (and possible), normalize the input.
     */
    readonly validate: Validator<Vehicle>;
}

export const Vehicle = {
    ...defineValidator<Vehicle>({
        vin: Joi.string(),
        name: Joi.string(),
        garagingCity: Joi.string(),
        garagingState: Joi.string(),
        garagingZip: ZipCode.schema,
        lossPayee: Joi.string().allow(null, ''),
        liabilityList: Joi.array().items(LiabilityCoverage.schema),
        radius: Joi.string(),
        usage: Joi.string().allow(...VehicleUseCodeList),
        comprehensiveDeductible: Money.schema.allow(null),
        comprehensivePremium: Money.schema.allow(null),
        collisionDeductible: Money.schema.allow(null),
        collisionPremium: Money.schema.allow(null),
        rentalReimbursementDailyLimit: Money.schema.allow(null),
        rentalReimbursementMaxLimit: Money.schema.allow(null),
        rentalReimbursementPremium: Money.schema.allow(null),
        towingAndLaborLimit: Money.schema.allow(null),
        towingAndLaborPremium: Money.schema.allow(null),
        collisionDeductibleWaiverPremium: Money.schema.allow(null),
        leaseLoanGapPremium: Money.schema.allow(null),
        audioVisualDataEquipmentPremium: Money.schema.allow(null),
        audioVisualDataEquipmentLimit: Money.schema.allow(null),
    }),
    create(vehicle: Vehicle) {
        return Vehicle.validate(vehicle);
    },
};
