import {
    Button,
    ButtonBar,
    CheckBox,
    ColumnLayout,
    Modal,
    ModalActions,
    ModalState,
    ScrollBox,
    StackLayout,
    StatusMessageList,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React, { useState } from 'react';
import { BundleQuote } from '../../entities/BundleQuote';
import { BundleQuoteModalSummary } from './BundleQuoteModalSummary.view';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';

export interface BundlePurchaseConfirmationModalProps {
    modal: ModalState & ModalActions;
    onConfirmPurchase: () => void;
    messages: Readonly<string[]>;
    trigger: (action: string) => void;
    bundleQuote: BundleQuote;
}

const disclaimer = `I understand that the Policy I'm purchasing is based on the information/Application I've submitted; and, to the extent that this differs from actual information, the Policy's terms, conditions, pricing, or other coverage features may change or the policy may be voided. I also understand that premium payment is a condition of coverage being effected on the date noted above.`;

export const BundlePurchaseConfirmationModal = function BundlePurchaseConfirmationModal({
    modal,
    onConfirmPurchase,
    messages,
    bundleQuote,
}: BundlePurchaseConfirmationModalProps) {
    const [isDisclaimerChecked, setIsDisclaimerChecked] = useState(false);

    const isPurchaseButtonDisabled = messages.length > 0 || !isDisclaimerChecked;

    return (
        <Modal {...modal} size="small tall" dismissable={false}>
            <ScrollBox>
                <StackLayout gap="32">
                    <StatusMessageList messages={messages} status="error" />
                    <StackLayout gap="24">
                        <React.Fragment>
                            <Text style="heading 3" data-e2e="are-details-correct">
                                Are these details correct?
                            </Text>
                            <QuoteSummary bundleQuote={bundleQuote} />
                            <CheckBox
                                onChange={(event) => setIsDisclaimerChecked(event.target.checked)}
                                checked={isDisclaimerChecked}
                            >
                                <Text style="microcopy" data-e2e="disclaimer-checkbox">
                                    {disclaimer}
                                </Text>
                            </CheckBox>
                        </React.Fragment>
                    </StackLayout>
                    <ButtonBar responsive={{ containerWidth: { smallerThan: 'mobile' } }}>
                        <Button
                            disabled={isPurchaseButtonDisabled}
                            onClick={onConfirmPurchase}
                            data-e2e="confirm-and-bind"
                        >
                            Confirm and Bind
                        </Button>
                        <TextButton onClick={modal.hide} data-e2e="go-back">
                            Go Back
                        </TextButton>
                    </ButtonBar>
                </StackLayout>
            </ScrollBox>
        </Modal>
    );
};

interface QuoteSummaryProps {
    bundleQuote: BundleQuote;
}

function QuoteSummary({ bundleQuote }: QuoteSummaryProps) {
    const policyWording = bundleQuote.coverageList.length > 1 ? 'policies' : 'policy';
    return (
        <StackLayout gap="24">
            <Text>
                You are about to bind the {policyWording} for{' '}
                {bundleQuote.organizationInfo.companyName}. Please confirm that the details listed
                below are correct.
            </Text>
            <BundleQuoteModalSummary bundleQuote={bundleQuote} />
            <ColumnLayout split="-1">
                <Text style="body 1">Total Premium:</Text>
                <Text data-e2e="total-premium" style="heading 5">
                    <ColumnLayout gap="16" bottom>
                        <MoneyDisplay value={bundleQuote.getTotalBindablePremium()} />
                        &nbsp;
                        <Text style="microcopy" as="span">
                            per year
                        </Text>
                    </ColumnLayout>
                </Text>
            </ColumnLayout>
        </StackLayout>
    );
}
