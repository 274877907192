import { Money } from '@embroker/shotwell/core/types/Money';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import {
    CenterLayout,
    ColumnLayout,
    Text,
    LayerCard,
    StackLayout,
    layerCardBackgrounds,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { CarrierLayer } from '../../types/CarrierLayer';

export interface PrimaryTowerCardProps {
    carrierLayer: CarrierLayer;
}
export const PrimaryTowerCard = function ({ carrierLayer }: PrimaryTowerCardProps) {
    const background = layerCardBackgrounds[2];
    return (
        <LayerCard background={background} key={carrierLayer.layer_number}>
            <CenterLayout as={ColumnLayout} gap="none">
                <StackLayout gap="none">
                    <Text style="heading 5">{`Primary - ${carrierLayer.carrier.name}`}</Text>

                    <MoneyDisplay
                        value={Money.tryFromFloat(carrierLayer.policy_aggregate)}
                        fractionDigits={0}
                    />

                    <Text>
                        Premium:{' '}
                        <MoneyDisplay
                            value={Money.tryFromFloat(carrierLayer.premium)}
                            fractionDigits={0}
                        />
                    </Text>
                </StackLayout>
            </CenterLayout>
        </LayerCard>
    );
};
