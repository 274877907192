import { inject } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { BrokerRepository } from '../repositories';
import { GetNaicsEligibleAppTypesError } from '../errors';

export interface GetNaicsEligibleAppTypesRequest {
    naics: string;
}

export interface GetNaicsEligibleAppTypes extends UseCase {
    execute(
        request: GetNaicsEligibleAppTypesRequest,
    ): AsyncResult<string[], GetNaicsEligibleAppTypesError>;
}

class GetNaicsEligibleAppTypesUseCase extends UseCase implements GetNaicsEligibleAppTypes {
    public static type = Symbol('Broker/GetNaicsEligibleAppTypes');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BrokerRepository) private brokerRepository: BrokerRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        naics,
    }: GetNaicsEligibleAppTypesRequest): AsyncResult<string[], GetNaicsEligibleAppTypesError> {
        return this.brokerRepository.getNaicsEligibleAppTypes(naics);
    }
}

export const GetNaicsEligibleAppTypes: UseCaseClass<GetNaicsEligibleAppTypes> =
    GetNaicsEligibleAppTypesUseCase;
