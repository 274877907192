import { cast } from '@embroker/shotwell/core/types/Nominal';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import {
    Button,
    ColumnLayout,
    Image,
    Modal,
    ScrollBox,
    StackLayout,
    Text,
    TextButton,
    useModal,
} from '@embroker/ui-toolkit/v2';
import React, { useContext } from 'react';
import { AppContext } from '../../../../../view/AppContext';
import { PayByPremiumFinance } from '../../../../useCases/PayByPremiumFinance';
import { BANK_VALUE } from '../../PaymentsDashboard';

export interface LeavingToFIFSiteProps {
    hide: () => void;
    openThankYou: () => void;
    invoiceIDs: UUID[];
    modal: ReturnType<typeof useModal>;
    option: string;
    quote: LeavingToFIFQuoteInfo;
    signedDateTimeTermsAndConditions?: Date;
}

export interface LeavingToFIFQuoteInfo {
    readonly quoteNumber: number;
    readonly downPayCreditCardUrl: URI;
    readonly downPayBankUrl: URI;
    readonly recurringCreditCardURL?: URI;
}

const BILLING_METHOD__ACH = 'ACH';
const BILLING_METHOD__CARD = 'Card';
type FifBillingMethod = 'ACH' | 'Card' | 'Invoice';

export function LeavingToFIFSite({
    quote,
    option,
    signedDateTimeTermsAndConditions,
    ...props
}: LeavingToFIFSiteProps) {
    const goToFIFButtonStyle = {
        textDecoration: 'none',
    };

    const { activeSession } = useContext(AppContext);
    const url = option === BANK_VALUE ? quote.downPayBankUrl : quote.downPayCreditCardUrl;
    const modalTitle =
        quote.recurringCreditCardURL != undefined
            ? 'Remitting the Down payment'
            : 'You are now leaving Embroker.com';

    const actionButtonText =
        quote.recurringCreditCardURL != undefined ? 'Pay Down payment' : 'Continue';

    const billingMethod: FifBillingMethod =
        option == BANK_VALUE ? BILLING_METHOD__ACH : BILLING_METHOD__CARD;

    const handleContinueToFIFSite = async () => {
        const result = await execute(PayByPremiumFinance, {
            invoiceIds: props.invoiceIDs,
            organizationID: cast<UUID>(activeSession.organizationId as UUID),
            quoteNumber: quote.quoteNumber,
            signedDateTimeTermsAndConditions: signedDateTimeTermsAndConditions,
            billingMethod: billingMethod,
        });

        if (result !== undefined && isOK(result)) {
            props.openThankYou();
        }
    };
    return (
        <Modal {...props.modal}>
            <ScrollBox>
                <StackLayout gap="24">
                    <Text style="heading 3">{modalTitle}</Text>
                    <Text>
                        Embroker is partnered with First Insurance Funding for our premium financing
                        plan. You will be redirected to their website to complete your transaction.
                    </Text>
                    <Image color="default" name="fif_logo" />
                    <ColumnLayout>
                        <Button
                            data-e2e="fif-leaving-embroker-modal-submit-btn"
                            as="a"
                            appearance="primary"
                            href={url}
                            onClick={handleContinueToFIFSite}
                            style={goToFIFButtonStyle}
                            target="_blank"
                        >
                            {actionButtonText}
                        </Button>
                        <TextButton
                            data-e2e="fif-leaving-embroker-modal-cancel-button"
                            onClick={props.hide}
                        >
                            Cancel
                        </TextButton>
                    </ColumnLayout>
                </StackLayout>
            </ScrollBox>
        </Modal>
    );
}
