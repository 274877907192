import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    Button,
    ColumnLayout,
    Modal,
    ModalActions,
    ModalState,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { WizardForm } from '../../../../view/hooks/useWizardForm';
import { LPLQuoteFormData } from './createLPLQuoteForm';

interface HigherLimitModalProps<T extends LPLQuoteFormData> {
    readonly modal: ModalActions & ModalState;
    trigger: WizardForm<OpaqueForm<T>>['trigger'];
}

export function HigherLimitModal<T extends LPLQuoteFormData>({
    modal,
    trigger,
}: HigherLimitModalProps<T>) {
    const handleCLick = async () => {
        trigger('requestHigherLimit');
        modal.hide();
    };
    return (
        <Modal {...modal} size="small" dismissable>
            <StackLayout gap="32">
                <Text style="heading 3">Higher Limits Request</Text>
                <Text>
                    Upon submission, our team will begin processing the request and will get back to
                    you.
                </Text>
                <ColumnLayout gap="56">
                    <Button onClick={handleCLick} data-e2e="submit-higher-limits-request">
                        Submit Request
                    </Button>
                    <TextButton onClick={modal.hide}>Cancel</TextButton>
                </ColumnLayout>
            </StackLayout>
        </Modal>
    );
}
