import { LineOfBusinessCodeList, LineOfBusinessCodeListItem } from '@embroker/shotwell-api/enums';
import { Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import {
    BorStatus,
    BorStatusIsBor,
    BorStatusNotBor,
    BorStatusPendingBor,
} from '../../policy/types/BorStatus';
import { ClaimDetails } from './ClaimDetails';

export interface PolicyWithClaims {
    id: UUID;
    policyNumber: string;
    displayName: string;
    insurerName: string;
    startDate: Date;
    endDate: Date;
    lineOfBusiness: LineOfBusinessCodeListItem;
    totalClaims: number;
    openClaims: number;
    claimList?: ClaimDetails[];
    premiumPerYear?: Money;
    totalIncurredLosses: Nullable<Money>;
    borStatus: BorStatus;
    subLineOfBusiness: Nullable<string>;
}

export const PolicyWithClaims = {
    ...defineValidator<PolicyWithClaims>({
        id: UUID.schema,
        policyNumber: Joi.string(),
        lineOfBusiness: Joi.string().valid(...LineOfBusinessCodeList),
        insurerName: Joi.string(),
        displayName: Joi.string(),
        startDate: Joi.date(),
        endDate: Joi.date(),
        totalClaims: Joi.number().integer().min(0),
        claimList: Joi.array().items(ClaimDetails.schema).optional(),
        premiumPerYear: Money.schema.optional(),
        openClaims: Joi.number().integer().min(0),
        totalIncurredLosses: Money.schema.allow(null),
        borStatus: Joi.string().valid(BorStatusNotBor, BorStatusPendingBor, BorStatusIsBor),
        subLineOfBusiness: Joi.string().allow(null),
    }),
    create(displayPolicy: PolicyWithClaims) {
        return PolicyWithClaims.validate(displayPolicy);
    },
};
