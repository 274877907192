import React from 'react';
import { Input, NumberInputProps } from '@embroker/ui-toolkit/v2';

const mask = [/[0-9]/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

export const FeinField = React.forwardRef(function FeinField(
    props: NumberInputProps,
    ref: React.Ref<HTMLInputElement>,
) {
    const { readOnly, placeholder, ...inputProps } = props;

    return (
        <Input.Number
            ref={ref}
            disabled={readOnly}
            placeholder={placeholder}
            guide={false}
            inputMode="numeric"
            keepCharPositions={false}
            mask={mask}
            placeholderChar=" "
            showMask={false}
            {...inputProps}
        />
    );
});
