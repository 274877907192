import { mount } from 'navi';
import { AppContext } from '../view/AppContext';
import { group, RouteDefinitions } from '../view/routes/Route';
import { espRoutes } from './esp/routes';

export const routes: RouteDefinitions<AppContext> = {
    '/shopping/application/supplemental-questionnaire': group({
        '/esp': mount(espRoutes),
    }),
};
