import { Button, Card, Text, BoxLayout } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { Link } from '../../../view/components/Link/Link';

export const EmptyClaimsList = () => {
    return (
        <BoxLayout>
            <Card center>
                <Text data-e2e="empty-claims-no-policies-txt" style="body 1">
                    You have no policies available to display.
                </Text>
                <Button data-e2e="empty-claims-shop-for-coverage-button" as={Link} href="/shopping">
                    Shop for coverage
                </Button>
            </Card>
        </BoxLayout>
    );
};
