import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { PageLayout, StackLayout, Text, TextButton } from '@embroker/ui-toolkit/v2';
import React, { useEffect, useState } from 'react';
import { PaymentsHistory } from '../../../../payments/view/components/PaymentsHistory';
import { GetActiveOrganizationProfile } from '../../../../userOrg/useCases/GetActiveOrganizationProfile';
import { useNavigation } from '../../../../view/hooks/useNavigation';

export function ClientPaymentHistory() {
    const { navigate } = useNavigation();
    const [organizationName, setOrganizationName] = useState<string | undefined>(undefined);

    const { result: orgInfoResult } = useUseCase(GetActiveOrganizationProfile);

    useEffect(() => {
        if (orgInfoResult && isOK(orgInfoResult)) {
            setOrganizationName(orgInfoResult.value.organization.companyLegalName);
        } else if (orgInfoResult && isErr(orgInfoResult)) {
            container.get<Logger>(Log).error(orgInfoResult.errors);
        }
    }, [orgInfoResult]);

    const handleReturnToClient = () => {
        navigate('/broker/organization-info');
    };

    return (
        <PageLayout.Section>
            <StackLayout gap="32">
                <TextButton
                    size="small"
                    icon="bold-caret-left"
                    iconPosition="left"
                    onClick={handleReturnToClient}
                >
                    Back to client
                </TextButton>
                <Text as="h1" style="heading 1">
                    {organizationName
                        ? `Payment history for ${organizationName}`
                        : 'Payment history'}
                </Text>
                <PaymentsHistory />
            </StackLayout>
        </PageLayout.Section>
    );
}
