import { Modal, ModalActions, ModalState } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { ModalLayout } from '../../../view/components/ModalLayout.view';

interface MissingInformationModalInterface {
    modal: ModalState & ModalActions;
    navigateAfterModalConformation(): void;
}
export function MissingInformationModal({
    modal,
    navigateAfterModalConformation,
}: MissingInformationModalInterface) {
    return (
        <Modal {...modal} dismissable={false}>
            <ModalLayout
                title="Some information is missing"
                primaryAction={{
                    label: 'Review Application',
                    onClick: async () => {
                        modal.hide();
                        navigateAfterModalConformation();
                    },
                    'data-e2e': 'review-application',
                }}
                secondaryAction={{
                    label: 'Cancel',
                    onClick: modal.hide,
                }}
            >
                There is some missing information we still need. Please review the information
                provided and resubmit.
            </ModalLayout>
        </Modal>
    );
}
