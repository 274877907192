import React from 'react';
import { mount, route, redirect, map } from 'navi';
import { RouteDefinitions } from '../../../view/routes/Route';
import { AppContext } from '../../../view/AppContext';
import { ContactUsPage } from '../components/ContactUs';
import { FAQEmbroker } from '../components/FAQ';

const SUPPORT_TITLE = 'Embroker | Support';

export const routes: RouteDefinitions<AppContext> = {
    '/support': {
        auth: 'any',
        isOrganizationSpecific: false,
        handler: map(async (request) => {
            const prefilledContactReason = request.params.prefilledContactReason;
            return mount<AppContext>({
                '/': redirect('./contact'),
                '/contact': route({
                    title: SUPPORT_TITLE,
                    view: <ContactUsPage prefilledContactReason={prefilledContactReason} />,
                }),
                '/faq': route({
                    title: SUPPORT_TITLE,
                    view: FAQEmbroker,
                }),
            });
        }),
    },
};
