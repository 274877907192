import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Avatar, BoxLayout, ColumnLayout, Spinner, Text } from '@embroker/ui-toolkit/v2';
import React, { useEffect, useState } from 'react';
import { GetBrokerageByBroker } from '../../useCases/GetBrokerageByBroker';

export interface BrokerProfileInfoProps {
    userId: UUID;
    userFirstName?: string;
    userLastName?: string;
}

export function BrokerProfileInfo({ userId, userFirstName, userLastName }: BrokerProfileInfoProps) {
    const { result: brokerageResult } = useUseCase(GetBrokerageByBroker, { userId });
    const [brokerageName, setBrokerageName] = useState('');
    const userName = userFirstName + ' ' + userLastName;

    useEffect(() => {
        if (brokerageResult !== undefined && isOK(brokerageResult)) {
            setBrokerageName(brokerageResult.value.brokerage.name);
        }
    }, [brokerageResult, setBrokerageName]);

    if (brokerageResult === undefined) {
        return <Spinner />;
    }

    if (isErr(brokerageResult)) {
        container.get<Logger>(Log).error(brokerageResult.errors);
        return <ErrorPage errors={brokerageResult.errors} />;
    }

    return (
        <ColumnLayout
            responsive={{ containerWidth: { smallerThan: 'large-tablet' } }}
            gap="16"
            split="-1"
        >
            <ColumnLayout gap="16">
                <Text>
                    <Avatar
                        size="large"
                        round
                        initials={getInitials(userName, brokerageName)}
                        as="span"
                    />
                </Text>

                <BoxLayout>
                    {userFirstName && (
                        <Text as="span" style="heading 2">
                            {userFirstName} {userLastName}
                        </Text>
                    )}
                    <Text>{brokerageName}</Text>
                </BoxLayout>
            </ColumnLayout>
        </ColumnLayout>
    );
}

function getInitials(userName: string, brokerageName: string): string {
    return userName.trim() ? userName : brokerageName;
}
