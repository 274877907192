import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { InvoiceRepository } from '../repositories/InvoiceRepository';

export interface SendIneligiblePaymentsRequest {
    paymentIds: Immutable<UUID>[];
    invoiceList: Immutable<string>[];
    organizationID: Immutable<UUID>;
}

export interface SendIneligiblePayments extends UseCase {
    execute(
        request: SendIneligiblePaymentsRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed>;
}

@injectable()
class SendIneligiblePaymentsUseCase extends UseCase {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Payments/SendIneligiblePayments');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(InvoiceRepository) private invoiceRepo: InvoiceRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        data: SendIneligiblePaymentsRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed> {
        if (data.paymentIds === null || data.paymentIds.length == 0) {
            return Failure(
                InvalidArgument({
                    argument: 'No ineligible payment IDs sent',
                    value: data.paymentIds,
                }),
            );
        }

        const inputData: SendIneligiblePaymentsRequest = {
            paymentIds: data.paymentIds,
            invoiceList: data.invoiceList,
            organizationID: data.organizationID,
        };

        const result = await this.invoiceRepo.sendIneligibleInvoices(inputData);

        if (isErr(result)) {
            return result;
        }

        return Success();
    }
}

export const SendIneligiblePayments: UseCaseClass<SendIneligiblePayments> =
    SendIneligiblePaymentsUseCase;
