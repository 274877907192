import * as APIType from '@embroker/shotwell-api/app';
import { Immutable } from '@embroker/shotwell/core/types';
import { WCChubbQuoteDetails } from './types/WCChubbQuoteDetails';
import {
    WCChubbPerAccidentLimit,
    WCChubbEachEmployeeLimit,
    WCChubbPolicyLimit,
    WCChubbQuoteOptions,
} from './types/WCChubbQuoteOptions';

export function toWCChubbQuoteDetails(
    details: Immutable<APIType.WcChubbQuoteDetails>,
): WCChubbQuoteDetails {
    return {
        expirationDate: details.expiration_date,
        terrorismPremium: details.terrorism_premium,
        totalPremium: details.total_premium,
        taxes: details.taxes,
    };
}

export function toWCChubbQuoteOptions(options: APIType.WcChubbQuoteOptions): WCChubbQuoteOptions {
    return {
        effectiveDate: options.effective_date,
        isDeselected: options.is_deselected,
        policyLimit: options.policy_limit as WCChubbPolicyLimit,
        perAccidentLimit: options.per_accident_limit as WCChubbPerAccidentLimit,
        eachEmployeeLimit: options.disease_each_employee as WCChubbEachEmployeeLimit,
    };
}
