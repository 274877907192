import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Button, Immutable } from '@embroker/ui-toolkit/v2';
import HelloSign from 'hellosign-embedded';
import React, { SyntheticEvent, useState } from 'react';
import {
    CreateManagePolicyRequest,
    CreateManagePolicyRequestRequest,
    CreateManagePolicyRequestResponse,
} from '../../../useCases/CreateManagePolicyRequest';
import {
    SendManagePolicyRequestRequest,
    SendManagePolicyRequestUseCase,
} from '../../../useCases/SendManagePolicyRequest';

export interface TransferToEmbrokerButtonProps {
    transferRequested?: boolean;
    insurerId: UUID;
    policyId: UUID;
    disabled?: boolean;
}

export const TransferToEmbrokerButton = (props: TransferToEmbrokerButtonProps) => {
    const [signingInProgress, setSigningInProgress] = useState(false);

    function helloSignFlow(
        helloSignData: Immutable<CreateManagePolicyRequestResponse>,
        policyId: UUID,
    ) {
        const helloSignClient = new HelloSign({
            clientId: helloSignData.clientId,
        });
        helloSignClient.open(helloSignData.signatureRequestList[0].signingURL.toString(), {
            allowCancel: true,
            skipDomainVerification: true,
        });
        helloSignClient.on('sign', (data: any) => {
            if (!(data && data.signatureId)) {
                return;
            }
            execute(SendManagePolicyRequestUseCase, {
                signatureIdList: [helloSignData.signatureRequestList[0].id],
                policyId: policyId,
            } as SendManagePolicyRequestRequest).then((sendManageRequestResult) => {
                if (sendManageRequestResult) {
                    if (isErr(sendManageRequestResult)) {
                        container.get<Logger>(Log).error(sendManageRequestResult.errors);
                    }
                }
            });
        });
    }

    function handleTransferToEmbroker(event: SyntheticEvent) {
        event.stopPropagation();
        if (signingInProgress) {
            return;
        }
        setSigningInProgress(true);
        execute(CreateManagePolicyRequest, {
            insurerIdList: [props.insurerId],
        } as CreateManagePolicyRequestRequest)
            .then((result) => {
                if (result === undefined) {
                    return;
                }
                if (isOK(result)) {
                    helloSignFlow(result.value, props.policyId);
                } else {
                    container.get<Logger>(Log).error(result.errors);
                }
            })
            .finally(() => {
                setSigningInProgress(false);
            });
    }

    const buttonText = props.transferRequested ? 'Transfer requested' : 'Transfer to Embroker';
    return (
        <Button
            appearance="secondary"
            disabled={props.transferRequested || props.disabled}
            onClick={(event: SyntheticEvent) => handleTransferToEmbroker(event)}
        >
            {buttonText}
        </Button>
    );
};
