import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import {
    Button,
    CenterLayout,
    FormLayout,
    Image,
    StackLayout,
    Text,
    WizardLayout,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { Link } from '../../../../view/components';
import { useNavigation } from '../../../../view/hooks/useNavigation';

interface FullRedirectContinueProps {
    redirectApplicationId: UUID;
}
export function FullRedirectContinue({ redirectApplicationId }: FullRedirectContinueProps) {
    const { navigate } = useNavigation();
    return (
        <WizardLayout
            title=""
            onDismiss={() => {
                navigate(URI`/summary`);
            }}
        >
            <FormLayout>
                <StackLayout gap="32" center>
                    <Image name="shotwell-business-2" />
                    <Text style="heading 2" data-e2e="header">
                        Let's dig deeper.
                    </Text>
                    <CenterLayout centerText>
                        <Text style="body 1" data-e2e="body">
                            Based on your responses, we need to ask you a few more questions to get
                            you covered.
                        </Text>
                    </CenterLayout>
                    <Button
                        as={Link}
                        href={URI.build(`/shopping/application`, {
                            applicationId: redirectApplicationId,
                        })}
                        data-e2e="continue"
                    >
                        Continue
                    </Button>
                </StackLayout>
            </FormLayout>
        </WizardLayout>
    );
}
