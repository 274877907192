import { API } from '@embroker/shotwell-api/app';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { USD } from '@embroker/shotwell/core/types/Money';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { startOfToday } from 'date-fns';
import { QuotingEngineCyber } from '../../../../shopping/types/enums';
import { QuoteExpiration } from '../../../types/QuoteExpiration';
import { QuoteIdMap, QuoteList } from '../../../types/QuoteList';
import { CyberQuote } from '../../entities/CyberQuote';
import {
    CyberLevel,
    CyberLimit,
    CyberQuoteOptions,
    CyberRetention,
} from '../../types/CyberQuoteOptions';
import { CyberUserInfo } from '../../types/CyberUserInfo';
import { CyberQuoteRepository, PurchaseResponse } from './index';

@injectable()
export class APICyberQuoteRepository implements CyberQuoteRepository {
    async getLastCyberQuote(
        applicationId: UUID,
    ): AsyncResult<CyberQuote, InvalidArgument | OperationFailed> {
        const applicationResponse = await API.request('shopping/application', {
            id: applicationId,
        });
        if (isErr(applicationResponse)) {
            return handleOperationFailure(applicationResponse);
        }

        const application = applicationResponse.value;

        const lastQuote = QuoteList.getLastQuote(application.quote_list);
        if (lastQuote == null) {
            return Failure(InvalidArgument({ argument: 'last_quote', value: lastQuote }));
        }

        const cyberQuoteDetails = lastQuote.details.cyber;
        const cyberQuoteOptions = lastQuote.options.cyber;

        if (cyberQuoteDetails === undefined) {
            return Failure(
                InvalidArgument({ argument: 'cyberQuoteDetails', value: cyberQuoteDetails }),
            );
        }

        if (cyberQuoteOptions === undefined) {
            return Failure(
                InvalidArgument({ argument: 'cyberQuoteOptions', value: cyberQuoteOptions }),
            );
        }

        let userInfo: CyberUserInfo;
        const questionnaireData = application.questionnaire_data;
        if (questionnaireData === null) {
            return Failure(
                InvalidArgument({ argument: 'questionnaire_data', value: questionnaireData }),
            );
        }
        try {
            const questionnaireDataParsed = JSON.parse(questionnaireData);
            userInfo = getUserInfoFromQuestionnaire(questionnaireDataParsed);
        } catch (e) {
            return Failure(OperationFailed({ message: 'Failed parsing questionnaire data' }));
        }

        const cyberQuoteEntityResult = await CyberQuote.create({
            isIndication: lastQuote.is_indication,
            id: lastQuote.id,
            totalPremium: lastQuote.total_premium,
            totalPayable: lastQuote.total_payable,
            applicationId: applicationId,
            status: lastQuote.accepted_at ? 'accepted' : 'draft',
            fileKey: lastQuote.file_key !== null ? lastQuote.file_key : undefined,
            options: {
                effectiveDate: cyberQuoteOptions.effective_date,
                level: cyberQuoteOptions.level as CyberLevel,
                limit: cyberQuoteOptions.limit as CyberLimit,
                retention: cyberQuoteOptions.retention as CyberRetention,
            },
            details: {
                quoteId: lastQuote.id,
                totalPremium: lastQuote.total_premium,
                totalBasePremium: cyberQuoteDetails.total_base_premium ?? USD(0),
                taxes: lastQuote.taxes ?? USD(0),
                fees: lastQuote.fees ?? USD(0),
                policyAdministrationFee: cyberQuoteDetails.policy_administration_fee ?? USD(0),
                specimenPolicyFileKey: cyberQuoteDetails.specimen_policy_file_key,
            },
            userInfo: userInfo,
            isRenewal: application.renewed_policy_id_list.length > 0,
            daysToExpire: QuoteExpiration.getDaysLeftUntilExpiration({
                quotingEngine: application.quoting_engine || undefined,
                applicationStatus: application.status,
                validUntil: application.valid_until,
                quoteEffectiveDate: cyberQuoteOptions.effective_date,
                today: startOfToday(),
                isBroker: application.brokerage_id !== null,
            }),
        });

        if (isErr(cyberQuoteEntityResult)) {
            return handleOperationFailure(cyberQuoteEntityResult);
        }

        return Success(cyberQuoteEntityResult.value);
    }

    async reQuoteCyber(
        applicationId: UUID,
        cyberQuoteOptions: CyberQuoteOptions,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed> {
        const reQuoteCyberResponse = await API.request('shopping/create_quote_task', {
            application_id: applicationId,
            quote_options: {
                cyber: {
                    effective_date: cyberQuoteOptions.effectiveDate,
                    limit: cyberQuoteOptions.limit,
                    retention: cyberQuoteOptions.retention,
                    level: cyberQuoteOptions.level,
                    partner_code: '',
                },
            },
        });
        if (isErr(reQuoteCyberResponse)) {
            return handleOperationFailure(reQuoteCyberResponse);
        }
        return Success(reQuoteCyberResponse.value.task_id);
    }

    public async purchaseCyber(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<PurchaseResponse, UnknownEntity | InvalidArgument | OperationFailed> {
        const quoteIdMap: QuoteIdMap = { [QuotingEngineCyber]: quoteId };
        const purchaseResponse = await API.request('shopping/purchase', {
            id: applicationId,
            quote_id_map: quoteIdMap,
        });

        if (isErr(purchaseResponse)) {
            return handleOperationFailure(purchaseResponse);
        }

        const { policy_issuance_task_id: policyIssuanceTaskId, policyId } = purchaseResponse.value;

        return Success({
            policyId: policyId,
            policyDoneTaskId: policyIssuanceTaskId,
        });
    }

    public async createQuoteSummaryAsyncTask(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed> {
        const createQuoteSummaryResult = await API.request('shopping/create_quote_summary_task', {
            application_id: applicationId,
            quote_id: quoteId,
        });
        if (isErr(createQuoteSummaryResult)) {
            return handleOperationFailure(createQuoteSummaryResult);
        }

        return Success(createQuoteSummaryResult.value.task_id);
    }

    public async createSpecimenPolicyAsyncTask(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed> {
        const createSpecimenPolicyResult = await API.request(
            'shopping/create_specimen_policy_task',
            {
                application_id: applicationId,
                quote_id: quoteId,
            },
        );
        if (isErr(createSpecimenPolicyResult)) {
            return handleOperationFailure(createSpecimenPolicyResult);
        }

        return Success(createSpecimenPolicyResult.value.task_id);
    }
}

function getUserInfoFromQuestionnaire(questionnaire: any): CyberUserInfo {
    return {
        company: questionnaire?.company_name,
        fullName: `${questionnaire?.first_name} ${questionnaire?.last_name}`,
        title: questionnaire?.title,
        usaState: questionnaire?.state,
    };
}
