import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { FormFamily } from './FormFamily';
import { Immutable } from '@embroker/ui-toolkit/v2';

export interface DocumentSpecification {
    docType: string;
    displayName?: string;
    tags: Immutable<string[]>;
    condition?: string;
    formFamilies: Immutable<FormFamily[]>;
    inputSchema?: string;
}

export const DocumentSpecification = {
    ...defineValidator<DocumentSpecification>(
        Joi.object({
            docType: Joi.string().required(),
            displayName: Joi.string().allow('').optional(),
            condition: Joi.string().allow('').optional(),
            formFamilies: Joi.array().items(FormFamily.schema).min(1).required(),
            tags: Joi.array()
                .items(Joi.string().not(''))
                .min(1)
                .unique((a: string, b: string): boolean => {
                    if (a !== '' && b !== '') {
                        return a === b;
                    }
                    return false;
                })
                .required(),
            inputSchema: Joi.string().optional(),
        }),
    ),
    create(documentSpecification: DocumentSpecification) {
        return DocumentSpecification.validate(documentSpecification);
    },
};
