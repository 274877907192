import { FormElement } from '@embroker/service-app-engine';
import { Nullable } from '@embroker/shotwell/core/types';
import { ErrorLike, isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Spinner } from '@embroker/ui-toolkit/v2';
import React, { useEffect, useState } from 'react';
import { useNavigation } from '../../../../../view/hooks/useNavigation';
import { GetClientApplicationFormEngine } from '../../../../useCases/GetClientApplicationFormEngine';
import { EditApplication, navigateToMatchingErrorPage } from './EditApplication';

interface ReviewApplicationProps {
    token: string;
    pageId?: string;
}

export function ReviewApplication({ token, pageId }: ReviewApplicationProps): JSX.Element {
    const { navigate } = useNavigation();
    const [formEngine, setFormEngine] = useState<Nullable<FormElement>>(null);

    const { result: formEngineResult } = useUseCase(GetClientApplicationFormEngine, {
        token,
    });

    useEffect(() => {
        setFormEngine(null);
    }, [token]);

    useEffect(() => {
        if (formEngineResult && isOK(formEngineResult)) {
            const formEngine = formEngineResult.value.formEngine;
            setFormEngine(formEngine);
        }
    }, [formEngineResult]);

    useEffect(() => {
        if (formEngineResult && isErr(formEngineResult)) {
            navigateToMatchingErrorPage(navigate, (formEngineResult.errors[0] as ErrorLike).code);
        }
    }, [navigate, formEngineResult]);

    useEffect(() => {
        if (formEngine) {
            return () => {
                formEngine.dispose();
            };
        }
    }, [formEngine]);

    if (!formEngine) {
        return <Spinner />;
    }

    return <EditApplication token={token} pageId={pageId} formEngine={formEngine} />;
}
