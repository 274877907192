import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { Claim } from '../../entities/Claim';
import { ClaimDocument } from '../../types/ClaimDocument';
import { LineOfBusinessCodeListItem, LineOfBusinessCodeList } from '@embroker/shotwell-api/enums';
import { InvalidClaimRequestSave } from '../../errors';
import { PhoneNumber } from '@embroker/shotwell/core/types/PhoneNumber';

export interface GetClaimsForPolicyResponse {
    claimsList: Claim[];
    insurerName: string;
    policyNumber: string;
    startDate: Date;
    endDate: Date;
    lineOfBusiness: LineOfBusinessCodeListItem;
}

export const GetClaimsForPolicyResponse = {
    ...defineValidator<GetClaimsForPolicyResponse>({
        claimsList: Joi.array().items(Claim.schema),
        insurerName: Joi.string(),
        policyNumber: Joi.string(),
        startDate: Joi.date(),
        endDate: Joi.date(),
        lineOfBusiness: Joi.string().valid(...LineOfBusinessCodeList),
    }),
    create(getClaimsForPolicyResponse: GetClaimsForPolicyResponse) {
        return GetClaimsForPolicyResponse.validate(getClaimsForPolicyResponse);
    },
};

export interface ClaimRequestDocument {
    name: string;
    file_key: string;
}

export const ClaimRequestDocument = {
    ...defineValidator<ClaimRequestDocument>({
        name: Joi.string().required(),
        file_key: Joi.string().required(),
    }),
    create(claimRequestDocument: ClaimRequestDocument) {
        return ClaimRequestDocument.validate(claimRequestDocument);
    },
};

export interface CreateClaimRequest {
    policyId: UUID;
    submitterName: string;
    contactPersonName: string;
    contactPersonEmail: string;
    contactPersonPhoneNumber: string;
    description: string;
    documentList: ClaimRequestDocument[];
}

export const CreateClaimRequest = {
    ...defineValidator<CreateClaimRequest>({
        policyId: UUID.schema.required(),
        submitterName: Joi.string().required(),
        contactPersonName: Joi.string().required(),
        contactPersonEmail: Joi.string().required(),
        contactPersonPhoneNumber: PhoneNumber.schema.required(),
        description: Joi.string().optional(),
        documentList: Joi.array().items(ClaimRequestDocument.schema),
    }),
    create(createClaimRequest: CreateClaimRequest) {
        return CreateClaimRequest.validate(createClaimRequest);
    },
};

export interface ClaimRepository {
    getClaims(): AsyncResult<Map<UUID, Claim[]>, InvalidArgument | OperationFailed>;
    getClaimsForPolicy(
        policyId: UUID,
    ): AsyncResult<GetClaimsForPolicyResponse, InvalidArgument | UnknownEntity | OperationFailed>;
    getClaimDocuments(
        claimId: UUID,
    ): AsyncResult<ClaimDocument[], InvalidArgument | UnknownEntity | OperationFailed>;
    createClaimRequest(
        claimRequest: CreateClaimRequest,
    ): AsyncResult<UUID, InvalidClaimRequestSave | InvalidArgument>;
}

export const ClaimRepository = Symbol('ClaimRepository');
