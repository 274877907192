import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import {
    AggregateLimit,
    AggregateLimitList,
    PerClaimLimit,
    PerClaimLimitList,
} from './LPLQuoteOptions';

export interface LPLHigherLimit {
    readonly per_claim_limit: PerClaimLimit;
    readonly aggregate_limit: AggregateLimit;
}

export const LPLHigherLimit = {
    ...defineValidator<LPLHigherLimit>({
        per_claim_limit: Joi.number()
            .valid(...PerClaimLimitList)
            .required(),
        aggregate_limit: Joi.number()
            .valid(...AggregateLimitList)
            .required(),
    }),
    create(lplHigherLimit?: { per_claim_limit: number; aggregate_limit: number }) {
        return LPLHigherLimit.validate(lplHigherLimit);
    },
};
