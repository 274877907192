import React from 'react';
import { ColumnLayout, StackLayout, Text } from '@embroker/ui-toolkit/v2';

export interface CoverageModalSummaryProps {
    /**
     * Title to be displayed above summary table
     */
    title: string;
    /**
     * Array of labels and values to be displayed as a table
     */
    summaryList: Array<{
        label: string;
        value: React.ReactNode;
    }>;
}

export function CoverageModalSummary({ title, summaryList }: CoverageModalSummaryProps) {
    return (
        <StackLayout gap="16">
            <Text style="heading 5">{title}</Text>

            <StackLayout gap="8">
                {summaryList.map(({ label, value }) => (
                    <ColumnLayout key={label} gap="8">
                        <Text className="u-1/2" style="overline">
                            {label}
                        </Text>
                        <Text>{value}</Text>
                    </ColumnLayout>
                ))}
            </StackLayout>
        </StackLayout>
    );
}
