import { Money } from '@embroker/shotwell/core/types/Money';
import { SelectOptionItem } from '@embroker/ui-toolkit/v2';
import { GeneralLiabilityLimit, StopGapLiabilityLimit } from '../../../types/CNAQuoteOptions';

const perPropertyDeductibleOptions: Array<SelectOptionItem<number>> = [
    {
        title: '$250',
        value: 250,
    },
    {
        title: '$500',
        value: 500,
    },
    {
        title: '$1,000',
        value: 1000,
    },
    {
        title: '$2,500',
        value: 2500,
    },
    {
        title: '$5,000',
        value: 5000,
    },
    {
        title: '$10,000',
        value: 10 * 1000,
    },
    {
        title: '$25,000',
        value: 25 * 1000,
    },
];

export function getPropertyDeductibleOptions(businessDescription: string, contentsLimit: number) {
    const filterDeductibleOptions = perPropertyDeductibleOptions.filter(
        (e) => e.value <= contentsLimit / 100,
    );
    if (!businessDescription) {
        return filterDeductibleOptions;
    }
    const classCodes = [
        '50813a',
        '56114',
        '56210a',
        '56210b',
        '56312',
        '56319a',
        '56319b',
        '56319c',
        '56319d',
        '56319e',
        '56413',
        '59999ae',
    ];
    const excludeLimit = 250;
    if (classCodes.includes(businessDescription.toLowerCase())) {
        return filterDeductibleOptions.filter((e) => e.value !== excludeLimit);
    }
    return filterDeductibleOptions;
}

export function isPropertyDeductibleTypeValid(
    value: Money,
    classCode: string,
    contentsLimit: number,
): boolean {
    return getPropertyDeductibleOptions(classCode, contentsLimit).some((option) =>
        Money.isEqual(Money.tryFromFloat(option.value), value),
    );
}

export const perWindHailDeductibleOptions: Array<SelectOptionItem<number>> = [
    {
        title: '$500',
        value: 500,
    },
    {
        title: '$1,000',
        value: 1000,
    },
    {
        title: '$2,500',
        value: 2500,
    },
    {
        title: '$5,000',
        value: 5000,
    },
    {
        title: '$10,000',
        value: 10 * 1000,
    },
    {
        title: '$15,000',
        value: 15 * 1000,
    },
    {
        title: '$20,000',
        value: 20 * 1000,
    },
    {
        title: '$25,000',
        value: 25 * 1000,
    },
];

const perGeneralLiabilityLimitOptions: Array<SelectOptionItem<string>> = [
    {
        title: '$500,000/$1,000,000',
        value: `${500 * 1000}/${1000 * 1000}`,
    },
    {
        title: '$1,000,000/$2,000,000',
        value: `${1000 * 1000}/${2000 * 1000}`,
    },
    {
        title: '$2,000,000/$4,000,000',
        value: `${2000 * 1000}/${4000 * 1000}`,
    },
];

export function getGeneralLiabilityLimitOptions(businessDescription: string) {
    if (!businessDescription) {
        return perGeneralLiabilityLimitOptions;
    }
    const classCodes = [
        '53127',
        '99600',
        '71311h',
        '54516z',
        '54606',
        '55313',
        '48130',
        '91315b',
        '59999c',
        '59997a',
        '71311c',
        '27410',
        '54116c',
        '59116b',
        '59997c',
        '56413',
        '65121yh',
        '52512c',
        '59626y',
        '65121yj',
        '59991a',
        '54516b',
        '53317b',
        '91315g',
        '65121yb',
        '65121yi',
        '59997h',
        '54446b',
        '54116f',
        '58128a',
        '71311b',
        '71311a',
    ];
    const excludeLimit = `${2000 * 1000}/${4000 * 1000}`;
    if (classCodes.includes(businessDescription.toLowerCase())) {
        return perGeneralLiabilityLimitOptions.filter((e) => e.value !== excludeLimit);
    }
    return perGeneralLiabilityLimitOptions;
}

export function isGeneralLiabilityLimitTypeValid(
    value: GeneralLiabilityLimit,
    classCode: string,
): boolean {
    return getGeneralLiabilityLimitOptions(classCode).some((option) => option.value === value);
}

const perHiredAndNonOwnedAutoLimitOptions: Array<SelectOptionItem<number>> = [
    {
        title: 'None',
        value: 0,
    },
    {
        title: '$300,000',
        value: 300 * 1000,
    },
    {
        title: '$500,000',
        value: 500 * 1000,
    },
    {
        title: '$1,000,000',
        value: 1000 * 1000,
    },
];

export function getHiredAndNonOwnedAutoLimitOptions(generalLiabilityLimit: GeneralLiabilityLimit) {
    const minGeneralLiabilityLimit = `${500 * 1000}/${1000 * 1000}`;
    const excludeLimit = 1000 * 1000;

    if (generalLiabilityLimit === minGeneralLiabilityLimit) {
        return perHiredAndNonOwnedAutoLimitOptions.filter((e) => e.value !== excludeLimit);
    }
    return perHiredAndNonOwnedAutoLimitOptions;
}

export const perStopGapLiabilityLimitOptions: Array<SelectOptionItem<string>> = [
    {
        title: 'None',
        value: `${0}/${0}/${0}`,
    },
    {
        title: '$300,000/$300,000/$600,000',
        value: `${300 * 1000}/${300 * 1000}/${600 * 1000}`,
    },
    {
        title: '$500,000/$500,000/$1,000,000',
        value: `${500 * 1000}/${500 * 1000}/${1000 * 1000}`,
    },
    {
        title: '$1,000,000/$1,000,000/$2,000,000',
        value: `${1000 * 1000}/${1000 * 1000}/${2000 * 1000}`,
    },
];

export function isStopGapLiabilityLimitTypeValid(value: StopGapLiabilityLimit): boolean {
    return perStopGapLiabilityLimitOptions.some((option) => option.value === value);
}
