import * as APIType from '@embroker/shotwell-api/app';
import { QuoteExtended } from '@embroker/shotwell-api/app';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import {
    ActionHandler,
    CreateFormParams,
    FormAction,
    OpaqueForm,
} from '@embroker/shotwell/view/hooks/useForm';
import React from 'react';
import { PremiumRange, Quote, QuoteOptions } from '../../quote/entities/Quote';
import { LPLQuoteOptions } from '../../quote/lpl/types/LPLQuoteOptions';
import { LimitType } from '../../quote/types/Limits';
import { QuotingEngine } from '../../shopping/types/enums';
import { WizardForm } from '../../view/hooks/useWizardForm';
import { BundleQuote } from '../entities/BundleQuote';
import {
    BundleCoverageType,
    BundleQuoteCoverage,
    QuestionnaireDataType,
} from '../types/BundleQuoteCoverage';
import { DocumentType } from '../types/BundleQuoteDocument';
import { BundleQuoteFormData } from '../types/BundleQuoteFormData';
import { BundleQuoteOptions } from '../types/BundleQuoteOptions';
import { BuildBundleDocumentListProps } from '../view/components/buildBundleDocumentList';
import {
    BundleQuoteCoverageListProps,
    UnderlyingCoverageProps,
} from '../view/components/BundleQuoteCoverageList';
import { DocumentsItem } from '../view/components/BundleQuoteLandingPage';
import { BOPChubbQuoteOptions } from './bopChubb/types/BOPChubbQuoteOptions';
import { ProductPrefix } from './coverageFormData';
import { CowbellCyberQuoteOptions } from './cowbellCyber/types/CowbellCyberQuoteOptions';
import { MPLQuoteOptions } from './mpl/types/MPLQuoteOptions';
import { WCChubbQuoteOptions } from './wcChubb/types/WCChubbQuoteOptions';
import { CoveragePackageType } from '../useCases/GetRecommendedCoverageQuotes';
import { QuestionnaireData } from '../types/BundleQuoteQuestionnaireData';
import { ESPQuoteOptions } from './esp/types/ESPQuoteOptions';
import { CoverageCatalog } from '../CoverageCatalog';

export const LPLEverest = 'lpl_everest';
export const MPL = 'mpl';
export const LawCyber = 'law_cyber';
export const CowbellCyber = 'cyber_cowbell';
export const BOPChubb = 'bop_chubb';
export const WCChubb = 'wc_chubb';
export const ESP = 'esp';

export const LplDocumentTitle = 'LPL';
export const MplDocumentTitle = 'PL';
export const CyberDocumentTitle = 'Cyber';
export const BOPChubbDocumentTitle = 'BOP';
export const WCChubbDocumentTitle = 'WC';
export const ESPDocumentTitle = 'ESP';
const DocumentTitleList = [
    LplDocumentTitle,
    CyberDocumentTitle,
    MplDocumentTitle,
    BOPChubbDocumentTitle,
    WCChubbDocumentTitle,
    ESPDocumentTitle,
] as const;

export type DocumentTitleType = (typeof DocumentTitleList)[number];

export const LplProductName = 'lpl';
export const MplProductName = 'mpl';
export const CyberProductName = 'cyber';
export const BOPChubbProductName = 'bopChubb';
export const WCChubbProductName = 'wcChubb';
export const EspProductName = 'esp';
const ProductNameList = [
    LplProductName,
    CyberProductName,
    MplProductName,
    BOPChubbProductName,
    WCChubbProductName,
    EspProductName,
] as const;

export type ProductNameType = (typeof ProductNameList)[number];
type distributedProductFields<T> = T extends ProductNameType
    ? Record<`${T}Selected`, boolean>
    : never;

export const LplProductTitle = 'Lawyers Professional Liability';
export const MplProductTitle = 'Miscellaneous Professional Liability';
export const CyberProductTitle = 'Cyber';
export const BOPChubbProductTitle = 'Business Owners Policy';
export const WCChubbProductTitle = 'Workers Compensation Policy'; //todo confirm value
export const BundleProductTitle = 'Law Firm';
export const EspProductTitle = 'Emprober Starter Package';
const ProductTitleList = [
    LplProductTitle,
    MplProductTitle,
    CyberProductTitle,
    BOPChubbProductTitle,
    WCChubbProductTitle,
    BundleProductTitle,
    EspProductTitle,
] as const;

export type ProductTitleType = (typeof ProductTitleList)[number];

export type ProductFormData<QO extends QuoteOptions> = Omit<QO, 'isDeselected' | 'effectiveDate'> &
    distributedProductFields<ProductNameType>;

const ApiProductDesignationList = [
    LPLEverest,
    LawCyber,
    CowbellCyber,
    MPL,
    BOPChubb,
    WCChubb,
    ESP,
] as const;
type ApiProductDesignationList = typeof ApiProductDesignationList;
type ApiProductDesignation = ApiProductDesignationList[number];
export type BundleSummaryComponentProps<T extends EntityProps<Quote>> = {
    questionnaireData: QuestionnaireDataType<T>;
    quote: T;
};

export interface ModalCoverageSummaryProp<Q extends EntityProps<Quote>> {
    quote: Q;
}

export interface FooterProps<Q extends EntityProps<Quote>> {
    questionnaireData: QuestionnaireDataType<Q>;
    quote: Quote | undefined;
}

type strippedQuoteOptions<T extends QuoteOptions> = keyof Omit<T, 'isDeselected' | 'effectiveDate'>;

type formQuoteFields<QO extends QuoteOptions, P extends ProductNameType> = ProductPrefix<
    QO,
    strippedQuoteOptions<QO>,
    P
>;

type distributedProductName<QO extends QuoteOptions> = QO extends BOPChubbQuoteOptions
    ? typeof BOPChubbProductName
    : QO extends WCChubbQuoteOptions
    ? typeof WCChubbProductName
    : QO extends MPLQuoteOptions
    ? typeof MplProductName
    : QO extends CowbellCyberQuoteOptions
    ? typeof CyberProductName
    : QO extends ESPQuoteOptions
    ? typeof EspProductName
    : QO extends LPLQuoteOptions
    ? typeof LplProductName
    : never;

export type distributedPrefix<T extends QuoteOptions> = T extends BundleQuoteOptions
    ? formQuoteFields<T, distributedProductName<T>>
    : ProductPrefix<T, never>;

export interface GetDocumentDownloadMetadataResponse {
    fileKey: string;
    downloadUrl: string;
}

export interface CoverageDefinition<T extends EntityProps<Quote>> {
    productName: ProductNameType;
    productTitle: ProductTitleType;
    documentTitle: DocumentTitleType;
    type: BundleCoverageType;
    quotingEngine: QuotingEngine;
    buildCoverage: (
        quoteExtended: Immutable<QuoteExtended>,
        isBroker: boolean,
        omitEffectiveDateValidation?: boolean,
    ) => AsyncResult<BundleQuoteCoverage<T>, OperationFailed | InvalidArgument>;
    getRecomendedQuoteOptions?: (
        bundleQuote: BundleQuote,
        coveragePackageType: CoveragePackageType,
        questionnaireData: QuestionnaireData,
    ) => T['options'] | undefined;
    mapFormDataToQuoteOptions: (formData: any) => T['options'] | undefined;
    apiProductDesignation: ApiProductDesignation;
    mapQuoteOptionsToAPI: (
        options: T['options'],
    ) =>
        | APIType.LplQuoteOptions
        | APIType.LawCyberQuoteOptions
        | APIType.CyberCowbellQuoteOptions
        | APIType.MplQuoteOptions
        | APIType.BopChubbQuoteOptions
        | APIType.WcChubbQuoteOptions
        | APIType.EspQuoteOptions;
    coverageOptionsComponent: React.FC<UnderlyingCoverageProps<T>>;
    coverageFooterComponent?: React.FC<FooterProps<T>>;
    summaryComponent: React.FC<BundleSummaryComponentProps<T>>;
    modalSummaryComponent: React.FC<ModalCoverageSummaryProp<T>>;
    footerComponent: React.FC<FooterProps<T>>;
    isFieldsValidType: (
        input: any,
    ) => input is WizardForm<OpaqueForm<distributedPrefix<T['options']>>>['fields'];
    isOptionsValidType: (input: any) => input is T['options'];
    getProductFields: (
        bundleQuote: BundleQuote,
    ) => CreateFormParams<ProductFormData<distributedPrefix<T['options']>>>['fields'];
    getProductActions: (
        bundleQuote: BundleQuote,
        abortSignal: AbortSignal,
    ) => Record<
        FormAction,
        | {
              action: ActionHandler<T['options']>;
              fields: (keyof distributedPrefix<T['options']>)[];
          }
        | ActionHandler<ProductFormData<T['options']>>
    >;
    getCoverageDocuments: ({
        bundleQuote,
        documents,
        trigger,
        isDirty,
        value,
    }: BuildBundleDocumentListProps) => DocumentsItem[] | undefined;
    getInitialValues: (bundleQuote: BundleQuote) => Record<string, unknown>;
    toggleSelected: (selected: boolean) => Record<string, unknown>;
    getMaxFutureDaysAllowed: () => number;

    getDocumentDownloadMetadata(
        documentType: DocumentType,
        quote?: any,
    ): AsyncResult<GetDocumentDownloadMetadataResponse, OperationFailed | InvalidArgument>;

    onSuccessHandler?: (
        value: any,
        action: string,
        setDocumentUrl: (
            url: URI,
            documentType: DocumentType,
            bundleCoverageType: BundleCoverageType,
        ) => void,
        onGenerateDocument: () => void,
    ) => void;

    requiresApplicationAttestation?: boolean;
    surplusLinesDiscDisabled?: boolean;
    isLimitHigherThenAllowed: (
        formValue: distributedPrefix<T['options']>,
        coverageSelected: boolean,
        questionnaire?: QuestionnaireDataType<T>,
        higherLimit?: Record<LimitType, number>,
        bundleQuote?: BundleQuote,
    ) => boolean;
    attestationComponentList?: React.FC<BundleQuoteCoverageListProps<BundleQuoteFormData>>[];

    getPremiumRange(quote?: Quote): PremiumRange | undefined;

    getHLCoverageSpecificText?(): string;

    getDownloadAppWithManifestDocAction?(trigger: WizardForm<OpaqueForm<any>>['trigger']): void;

    overrideBundleAppDocument?(): boolean;
}

export function generateDocumentDisplayName({
    documentType,
    bundleCoverageType,
}: {
    documentType: DocumentType;
    bundleCoverageType?: BundleCoverageType;
}) {
    const documentTitle = bundleCoverageType
        ? CoverageCatalog.getDocumentTitle(bundleCoverageType)
        : undefined;
    return documentType && documentTitle
        ? documentTitle + ' ' + DocumentType.toDisplayName(documentType)
        : DocumentType.toDisplayName(documentType);
}
