import {
    BoxLayout,
    CenterLayout,
    ColumnLayout,
    Image,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React from 'react';

export function PublicPaymentThankYou() {
    return (
        <BoxLayout gap="64">
            <BoxLayout gap="64">
                <ColumnLayout center>
                    <CenterLayout>
                        <StackLayout gap="48">
                            <CenterLayout>
                                <Image name="shotwell-1" />
                            </CenterLayout>
                            <StackLayout gap="24">
                                <CenterLayout>
                                    <Text style="heading 1">Thank you.</Text>
                                </CenterLayout>
                                <CenterLayout centerText>
                                    <Text>We are processing your payment.</Text>
                                    <Text>We will email you a receipt when we receive it.</Text>
                                </CenterLayout>
                                <CenterLayout>
                                    <Text style="microcopy">
                                        Need help?{' '}
                                        <TextButton size="small" href="mailto:support@embroker.com">
                                            Get in touch.
                                        </TextButton>
                                    </Text>
                                </CenterLayout>
                            </StackLayout>
                        </StackLayout>
                    </CenterLayout>
                </ColumnLayout>
            </BoxLayout>
        </BoxLayout>
    );
}
