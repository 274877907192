import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { BrokerRepository } from '../repositories';

export interface GetQuoteToBindRatio extends UseCase {
    execute(): AsyncResult<number, InvalidArgument | OperationFailed>;
}

class GetQuoteToBindRatioUseCase extends UseCase implements GetQuoteToBindRatio {
    public static type = Symbol('Broker/GetQuoteToBindRatio');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BrokerRepository) private brokerRepository: BrokerRepository,
    ) {
        super(eventBus);
    }

    public async execute(): AsyncResult<number, InvalidArgument | OperationFailed> {
        return await this.brokerRepository.getQuoteToBindRatio();
    }
}

export const GetQuoteToBindRatio: UseCaseClass<GetQuoteToBindRatio> = GetQuoteToBindRatioUseCase;
