import { CopyToClipboard, Box } from '@embroker/ui-toolkit/v2';
import React from 'react';

const shareServiceTitle = (
    isSubmittedExternally: boolean | undefined,
    isRenewal: boolean,
): string => {
    return isSubmittedExternally
        ? `Need to share the ${isRenewal ? 'renewal ' : ''}app again?`
        : `Share Embroker ${isRenewal ? 'renewal ' : ''}app`;
};
const shareServiceTooltip = (
    isSubmittedExternally: boolean | undefined,
    isRenewal: boolean,
): string => {
    return isSubmittedExternally
        ? `If your client has made an error and needs to edit the ${
              isRenewal ? 'renewal ' : ''
          }app again then copy the link below (by clicking the icon) and share it with your client. They will be able to access a pre-filled application by pasting the link in their browser. Once the client completes the application, we'll notify you via email. You will be able to review their completed application and submit it to get the quote (the client will not see the quote).`
        : `Copy the link below (by clicking the icon) and share it with your client. They will be able to access a pre-filled application by pasting the link in their browser. Once the client completes the application, we'll notify you via email. You will be able to review their completed application and submit it to get the quote (the client will not see the quote).`;
};

interface ShareableApplicationLinkProps {
    token: string;
    isRenewal: boolean;
    isSubmittedExternally?: boolean;
}
export function ShareableApplicationLink({
    token,
    isRenewal,
    isSubmittedExternally,
}: ShareableApplicationLinkProps): JSX.Element {
    const title = shareServiceTitle(isSubmittedExternally, isRenewal);
    const tooltip = shareServiceTooltip(isSubmittedExternally, isRenewal);

    return (
        <Box border={false} maxWidth={333}>
            <CopyToClipboard title={title} tooltip={tooltip} value={getShareUrl(token)} />
        </Box>
    );
}

export function getShareUrl(token: string) {
    return `${window.location.host}/shareable/application?token=${token}`;
}
