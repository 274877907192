import React from 'react';
import { Modal, ModalActions, ModalState } from '@embroker/ui-toolkit/v2';
import { ModalLayout } from '../../../view/components/ModalLayout.view';

export interface NoActivePoliciesProps {
    modal: ModalState & ModalActions;
    hideModal: Function;
}

export const NoActivePoliciesModal = ({ modal, hideModal }: NoActivePoliciesProps) => {
    return (
        <Modal {...modal}>
            <ModalLayout
                title="Sorry you currently don’t have any active policies managed by Embroker."
                primaryAction={{
                    label: 'Shop for coverage',
                    href: '/shopping',
                }}
            >
                In order to issue a certificate you need a coverage with Embroker. Currently you
                have none active.
            </ModalLayout>
        </Modal>
    );
};
