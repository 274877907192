import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed, Timeout, Aborted } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ESPEndorsementRepository } from '../repositories/ESPEndorsementRepository';
import { TasksRepository } from '../../../tasks/repositories';
import { ESPEndorsementPolicy } from '../types/ESPEndorsementPolicy';
import { DateNotInSequence, NoChanges, handleCreateEndorsementTaskFailure } from '../../errors';

export interface ChangeNamedInsuredESPEndorsementRequest {
    policyId: UUID;
    namedInsured: string;
    effectiveDate: Date;
    abortSignal: AbortSignal;
    maxPollingRetries?: number;
    pollingRetryIntervalInMilliseconds?: number;
}

export interface ChangeNamedInsuredESPEndorsement extends UseCase {
    execute(
        request: ChangeNamedInsuredESPEndorsementRequest,
    ): AsyncResult<
        ESPEndorsementPolicy,
        OperationFailed | InvalidArgument | DateNotInSequence | NoChanges | Timeout | Aborted
    >;
}

@injectable()
class ChangeNamedInsuredESPEndorsementUseCase
    extends UseCase
    implements ChangeNamedInsuredESPEndorsement
{
    public static type = Symbol('ESPEndorsement/ChangeNamedInsuredESPEndorsement');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ESPEndorsementRepository)
        private espEndorsementRepository: ESPEndorsementRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        policyId,
        namedInsured,
        effectiveDate,
        abortSignal,
        maxPollingRetries = 120,
        pollingRetryIntervalInMilliseconds = 1000,
    }: ChangeNamedInsuredESPEndorsementRequest): AsyncResult<
        ESPEndorsementPolicy,
        OperationFailed | InvalidArgument | DateNotInSequence | NoChanges | Timeout | Aborted
    > {
        const createEndorsementResult =
            await this.espEndorsementRepository.createNamedInsuredEndorsement({
                policyId,
                namedInsured,
                effectiveDate,
            });
        if (isErr(createEndorsementResult)) {
            return createEndorsementResult;
        }
        const pollForTaskStatusResult = await this.tasksRepository.pollForTaskStatus(
            createEndorsementResult.value,
            abortSignal,
            maxPollingRetries,
            pollingRetryIntervalInMilliseconds,
        );
        if (isErr(pollForTaskStatusResult)) {
            return handleCreateEndorsementTaskFailure(pollForTaskStatusResult);
        }
        return await this.espEndorsementRepository.loadPolicy(policyId);
    }
}

export const ChangeNamedInsuredESPEndorsement: UseCaseClass<ChangeNamedInsuredESPEndorsement> =
    ChangeNamedInsuredESPEndorsementUseCase;
