import {
    InvalidArgument,
    NotImplemented,
    OperationFailed,
    UnknownEntity,
} from '@embroker/shotwell/core/Error';
import { Immutable, Props } from '@embroker/shotwell/core/types';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { NumberRangeOfW2Employees } from '../../../shopping/types/enums';
import { User } from '../../entities/User';
import {
    CheckTokenValidityError,
    EmailAlreadyInUse,
    InvalidEmail,
    MaxNumberOfSignUpAttemptsExceeded,
    Unauthenticated,
} from '../../errors';
import { MailingAddress } from '../../types/MailingAddress';

export interface SignUpResponse {
    user: User;
    organizationId: UUID;
}

export interface SignUpRequest {
    user: Immutable<Props<User>>;
    organizationName: string;
    howDidYouHearAboutEmbroker?: string;
    website?: string;
    naicsCode?: string;
    hasReceivedVCFunding?: boolean;
    isTotalRevenueLargerThan20MillionDollars?: boolean;
    numberRangeOfW2Employees?: NumberRangeOfW2Employees;
    hasAutomobiles?: boolean;
    headquarters?: MailingAddress;
}

export interface UserRepository {
    /**
     * Fetches active user's details
     * @returns User entity which matches authenticated user Id in current active session
     * @return error if operation fails
     */
    getActiveUser(): AsyncResult<
        User,
        Unauthenticated | InvalidArgument | OperationFailed | UnknownEntity
    >;

    /**
     * Fetches a user with provided id
     * If null for id is provided then it fetches own user data
     * @param userId is the id the user
     * @returns User entity if provided id matches existing user on the platform
     * @returns UnknownEntity error if user with provided id does not exist on the platform
     */
    getUser(userId: UUID): AsyncResult<User, UnknownEntity | NotImplemented>;

    /**
     * Fetches user with provided email address
     * @param email is the email address of the user
     * @returns User entity if provided email address matches existing user on the platform
     * @returns OperationFailed error if operation failed for some unknown reason
     * @returns InvalidArgument error if an argument was invalid
     */
    getUserByEmail(email: EmailAddress): AsyncResult<User, OperationFailed | InvalidArgument>;

    /**
     * Saves user entity
     * @param user
     * @returns Nothing if save was successful
     * @returns InvalidArgument if user exists on platform but one of it's properties is not valid
     * @returns EmailAlreadyInUse if user entity with provided id does not exist on platform but
     * email address is already user by another user on the platform
     */
    save(
        user: User,
    ): AsyncResult<
        void,
        | InvalidArgument
        | OperationFailed
        | EmailAlreadyInUse
        | InvalidEmail
        | MaxNumberOfSignUpAttemptsExceeded
    >;

    signUp(
        input: SignUpRequest,
    ): AsyncResult<
        SignUpResponse,
        | InvalidArgument
        | OperationFailed
        | EmailAlreadyInUse
        | InvalidEmail
        | MaxNumberOfSignUpAttemptsExceeded
    >;

    /**
     * Fetches user by password reset token
     * @param password reset token
     * @returns InvalidArgument if reset token is invalid
     * @returns OperationFailed error if operation failed for some unknown reason
     */
    getUserByPasswordResetToken(
        token: string,
    ): AsyncResult<User, InvalidArgument | OperationFailed>;

    isUserBundleProspect(userId: UUID): AsyncResult<boolean, CheckTokenValidityError>;
}

export const UserRepository = Symbol('UserRepository');
