import { FormElement } from '@embroker/service-app-engine';
import { URI } from '@embroker/shotwell/core/types/URI';
import { Spinner, WizardLayoutAppearance } from '@embroker/ui-toolkit/v2';
import React, { useEffect, useState } from 'react';
import { useCurrentRoute } from 'react-navi';
import { PlainFormEngine } from '../PlainFormEngine';
import { ProgressBarRangeType, ProgressBarTitleRenderer } from './components/ProgressBar';
import { useFormEngineEvent } from './hooks/useFormEngineEvent';
import { registerFormComponents } from './registerFormComponents';
import { WithNavigation } from './WithNavigation';
import { WithProgressBar } from './WithProgressBar';

export interface FormEngineProps {
    instance: FormElement;
    navigation?: boolean;
    /**
     * id of the last completed page. Used only once when
     * initializing form engine instance to restore "completed"
     * state of the specified page and the pages preceding it.
     * (redundant if 'completed' prop is true).
     */
    lastCompletedPageId?: string;
    /**
     * If set to true, it will mark all pages as completed
     */
    readonly completed?: boolean;
    page?: string;
    readOnly?: boolean;

    /**
     * If true, wraps formEngine instance in wizardLayout,
     * true by default
     */
    inWizard?: boolean;
    /**
     * If inWizard true, this will change the dismiss appearance,
     * There are 2 options: Using the close Icon ("X") or "Save and Exit" wording
     * The close icon is default behavior
     */
    dismissAppearance?: 'close-icon' | 'save-and-exit';
    progressBarRange?: ProgressBarRangeType;
    progressBarTitleRenderer?: ProgressBarTitleRenderer;
    onExitFullScreen?: () => void;
    footer?: React.ReactNode;
    footerAppearance?: WizardLayoutAppearance;
}

export const FormEngine = React.memo(function FormEngine({
    instance,
    navigation,
    lastCompletedPageId,
    completed,
    page,
    readOnly,
    inWizard = true,
    dismissAppearance = 'close-icon',
    progressBarRange,
    progressBarTitleRenderer,
    onExitFullScreen,
    footer,
    footerAppearance,
}: FormEngineProps) {
    const {
        data: { url, page: urlPage },
        url: { search },
    } = useCurrentRoute();

    useFormEngineEvent(
        instance,
        'show',
        ({
            target: {
                machine: {
                    state: { visiblePage, pageList },
                },
            },
        }) => {
            const page = pageList[visiblePage];

            if (!page || !url) {
                return;
            }

            const pageExternal = page.id.replace(/_/g, '-');
            const urlParts = url.split('/');
            if (urlPage !== undefined && urlPage.length > 0) {
                urlParts.pop();
            }
            const nextPage = URI.join('/', ...urlParts, pageExternal) + search;
            window.history.pushState(null, '', nextPage);
        },
    );

    const [hideNavigation, setHideNavigation] = useState(false);

    useFormEngineEvent(
        instance,
        'show',
        ({
            target: {
                machine: {
                    state: { visiblePage, pageList },
                },
            },
        }) => {
            const page = pageList[visiblePage];

            if (!page) {
                return;
            }

            const {
                machine: {
                    state: { hideNavigation },
                },
            } = page;
            setHideNavigation(hideNavigation);
        },
    );

    const [inited, setInited] = useState(false);

    useEffect(() => {
        if (completed) {
            instance.markPagesAsCompleted();
        } else if (lastCompletedPageId) {
            instance.markPagesAsCompleted(lastCompletedPageId);
        }
        if (page) {
            instance.gotoPage(page);
        }
        setInited(true);
    }, [instance, page, lastCompletedPageId, completed]);

    useEffect(registerFormComponents, []);

    let Component = PlainFormEngine;

    if (navigation) {
        Component = WithNavigation({
            WrappedComponent: Component,
            instance,
            hideNavigation,
        });
    }

    if (inWizard) {
        Component = WithProgressBar({
            WrappedComponent: Component,
            instance,
            onExitFullScreen,
            progressBarRange,
            progressBarTitleRenderer,
            dismissAppearance,
            footer,
            footerAppearance,
        });
    }

    if (!inited) {
        return <Spinner />;
    }

    return <Component instance={instance} readOnly={readOnly} />;
});
