import { defineEntityValidator, entity } from '@embroker/shotwell/core/entity/Entity';
import { Money } from '@embroker/shotwell/core/types/Money';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { commonQuoteProps, Quote } from '../../entities/Quote';
import { ExcessQuestionnaire } from '../types/ExcessQuestionnaire';
import { CarrierLayer } from '../types/CarrierLayer';
import { ExcessQuoteDetails } from '../types/ExcessQuoteDetails';
import { ExcessQuoteOptions } from '../types/ExcessQuoteOptions';

export interface ExcessQuote extends Quote {
    readonly options: ExcessQuoteOptions;
    readonly details: ExcessQuoteDetails;
    readonly questionnaire: ExcessQuestionnaire;
    readonly taxes: Money;
    readonly fees: Money;
    readonly primaryCarrierLayer: CarrierLayer;
}

export const ExcessQuote = entity<ExcessQuote, Quote>({
    validator: defineEntityValidator<ExcessQuote>({
        ...commonQuoteProps,
        options: ExcessQuoteOptions.schema.required(),
        details: ExcessQuoteDetails.schema.required(),
        taxes: Money.schema.required(),
        fees: Money.schema.required(),
        questionnaire: Joi.object(),
        primaryCarrierLayer: Joi.object({
            layer_number: Joi.number().required(),
            carrier: Joi.object({
                id: UUID.schema.required().allow(null),
                name: Joi.string().required(),
            }),
            tech_eo_limit: Joi.number().required(),
            cyber_limit: Joi.number().optional(),
            policy_aggregate: Joi.number().required(),
            premium: Joi.number().required(),
        }),
    }),
    inherits: Quote,
});
