import {
    defineEntityValidator,
    entity,
    Entity,
    UpdateEntityProps,
} from '@embroker/shotwell/core/entity/Entity';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { Immutable, Nullable, Props } from '@embroker/shotwell/core/types';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { Organization } from './Organization';
import { PhoneNumber } from '@embroker/shotwell/core/types/PhoneNumber';
import { SKU } from '../../analytics/types/SKU';

export interface User extends Entity {
    /**
     * User's first name
     */
    readonly firstName: Nullable<string>;
    /**
     * User's last name
     */
    readonly lastName: Nullable<string>;
    /**
     * User's title
     */
    readonly title: Nullable<string>;
    /**
     * User's phone number
     */
    readonly phoneNumber: Nullable<PhoneNumber>;
    /**
     * User's email address
     */
    readonly email: Nullable<EmailAddress>;
    /**
     * User's old password
     */
    readonly oldPassword: Nullable<string>;
    /**
     * User's password
     */
    readonly password: Nullable<string>;
    /**
     * User's password reset token.
     * This value is set only when user wants to reset its password via passwordReset() method.
     */
    readonly passwordResetToken: Nullable<string>;
    /**
     * Token provided via invitation
     * This value is set only when user is signing up via invitation token
     */
    readonly signUpInviteToken: Nullable<string>;
    /**
     * Exact time when user was created
     */

    readonly isUserLoggedInBySignup: boolean;

    /**
     * Flag if user is broker or regular user
     */
    readonly isBroker?: boolean;

    createdAt: Nullable<Date>;
    /** Invitation Token provided via certificate share
     * This value is set only when user is signing up via certificate share invitation token
     */
    readonly certificateInviteToken: Nullable<string>;

    /**
     * Updates user's properties
     * @param input
     */
    update(input: UpdateEntityProps<User>): void;

    /**
     * Resets user's password to null.
     * When user is saved in the repo layer it will generate password reset token.
     * Token must be provided along with new password in order to set new password.
     */
    forgottenPassword(): void;

    /**
     * Updates user's password
     * This is used only for user who is authenticated on the platform
     * @param newPassword is the new password for the user
     */
    updatePassword(oldPassword: string, newPassword: string): void;

    /**
     * Creates user's password
     * This is used only for initial password setup for users that signed-up password-less
     * @param password is the new password for the user
     */
    setNewPassword(password: string): void;

    /**
     * Resets user's password
     * @param newPassword is the new password for the user
     */
    resetPassword(newPassword: string): void;

    /**
     * Create signUp event
     * When user is signing up, we must provide required data from organization.
     * @param organization Organization data
     * @param howDidYouHearAboutEmbroker Optional sign up user question
     * @param sku Selected product data
     */
    signUp(
        organization: Immutable<Props<Organization>>,
        howDidYouHearAboutEmbroker: Nullable<string>,
        sku?: SKU,
    ): void;
}

export interface UserUpdated extends DomainEvent {
    readonly origin: 'User';
    readonly name: 'Updated';
}

interface UserPasswordUpdate extends DomainEvent {
    readonly origin: 'User';
    readonly name: 'PasswordUpdated';
}

interface UserForgottenPassword extends DomainEvent {
    readonly origin: 'User';
    readonly name: 'ForgottenPassword';
}

interface UserPasswordReseted extends DomainEvent {
    readonly origin: 'User';
    readonly name: 'PasswordReset';
}

export interface UserPasswordSet extends DomainEvent {
    readonly origin: 'User';
    readonly name: 'PasswordSet';
}

export interface UserSignUpStarted extends DomainEvent {
    readonly origin: 'User';
    readonly name: 'SignUpStarted';
    readonly sku?: SKU;
}

export interface UserSignUpEmailProvided extends DomainEvent {
    readonly origin: 'User';
    readonly name: 'SignUpEmailProvided';
}

export interface SignedUp extends DomainEvent {
    readonly origin: 'User';
    readonly name: 'SignedUp';
    readonly organization: Immutable<Props<Organization>>;
    readonly howDidYouHearAboutEmbroker: Nullable<string>;
    readonly sku?: SKU;
}

export interface UserLoginByAdmin extends DomainEvent {
    readonly origin: 'User';
    readonly name: 'LoginByAdmin';
}

export interface UserOnboardingDnbNaicsRetrieved extends DomainEvent {
    readonly origin: 'User';
    readonly name: 'OnboardingDnbNaicsRetrieved';
    naicsCode: Nullable<string>;
}

export interface UserOnboardingNaicsChanged extends DomainEvent {
    readonly origin: 'User';
    readonly name: 'OnboardingNaicsChanged';
    naicsCode: Nullable<string>;
    previousNaicsCode: Nullable<string>;
}

export interface UserOnboardingStarted extends DomainEvent {
    readonly origin: 'User';
    readonly name: 'OnboardingStarted';
}

export interface UserOnboardingCompleted extends DomainEvent {
    readonly origin: 'User';
    readonly name: 'OnboardingCompleted';
}

export interface UserOnboardingStepVisited extends DomainEvent {
    readonly origin: 'User';
    readonly name: 'OnboardingStepVisited';
    readonly step: string;
}

export const User = entity<User>({
    validator: defineEntityValidator<User>({
        firstName: Joi.string().allow(null),
        lastName: Joi.string().allow(null),
        title: Joi.string().allow(null).allow(''),
        phoneNumber: PhoneNumber.schema.allow(null),
        email: EmailAddress.schema.allow(null),
        password: Joi.string().allow(null),
        passwordResetToken: Joi.string().allow(null),
        signUpInviteToken: Joi.string().allow(null),
        certificateInviteToken: Joi.string().allow(null),
        oldPassword: Joi.string().allow(null),
        createdAt: Joi.date().allow(null),
        isUserLoggedInBySignup: Joi.boolean().default(false),
        isBroker: Joi.boolean().optional(),
    }),
    update(newUser) {
        Object.assign(this.props, newUser);
        this.createEvent<UserUpdated>('Updated');
    },
    forgottenPassword() {
        this.props.password = null;
        this.createEvent<UserForgottenPassword>('ForgottenPassword');
    },
    updatePassword(oldPassword: string, newPassword: string) {
        this.props.password = newPassword;
        this.props.oldPassword = oldPassword;
        this.createEvent<UserPasswordUpdate>('PasswordUpdated');
    },
    setNewPassword(password: string) {
        this.props.password = password;
        this.createEvent<UserPasswordSet>('PasswordSet');
    },
    resetPassword(newPassword: string): void {
        this.props.password = newPassword;
        this.createEvent<UserPasswordReseted>('PasswordReset');
    },
    signUp(
        organization: Immutable<Props<Organization>>,
        howDidYouHearAboutEmbroker: Nullable<string>,
        sku?: SKU,
    ) {
        this.createEvent<SignedUp>('SignedUp', {
            organization,
            howDidYouHearAboutEmbroker,
            sku,
        });
    },
});
