import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, NotImplemented, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ErrorCode, InactiveAccount, NoAccount } from '../errors';
import { SessionRepository } from '../repositories/SessionRepository';
import { UserStatusCode } from '../types/enums';
import { UserSignUpEmailProvided } from '../entities/User';
import { UUID } from '@embroker/shotwell/core/types/UUID';

export interface CheckUserStatusRequest {
    email: EmailAddress;
}

export interface CheckUserStatusResponse {
    status: UserStatusCode;
}

export interface CheckUserStatus extends UseCase {
    execute(
        request: CheckUserStatusRequest,
    ): AsyncResult<
        CheckUserStatusResponse,
        InvalidArgument | NotImplemented | OperationFailed | InactiveAccount | NoAccount
    >;
}
class CheckUserStatusUseCase extends UseCase implements CheckUserStatus {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/CheckUserStatus');
    /**
     * Constructor for CheckUserStatusUseCase use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param sessionRepository is session repository used to check user status (password/no-password)
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(SessionRepository) private sessionRepository: SessionRepository,
    ) {
        super(eventBus);
    }
    public async execute({
        email,
    }: CheckUserStatusRequest): AsyncResult<
        CheckUserStatusResponse,
        InvalidArgument | NotImplemented | OperationFailed | InactiveAccount | NoAccount
    > {
        const userResult = await this.sessionRepository.getUserStatus(email);
        if (isErr(userResult)) {
            for (const error of userResult.errors) {
                if (error.code === ErrorCode.NoAccount) {
                    const event: UserSignUpEmailProvided = {
                        origin: 'User',
                        name: 'SignUpEmailProvided',
                        createdAt: new Date(Date.now()),
                        id: UUID.create(),
                    };
                    await this.eventBus.publish(event);
                }
            }
            return userResult;
        }

        return Success({ status: userResult.value });
    }
}

export const CheckUserStatus: UseCaseClass<CheckUserStatus> = CheckUserStatusUseCase;
