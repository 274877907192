import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { Quote } from '../entities/Quote';

export interface UnsignQuoteRequest {
    quote: Quote;
}

export interface UnsignQuoteResponse {
    quote: Immutable<Quote>;
}

export interface UnsignQuote extends UseCase {
    execute(
        request: UnsignQuoteRequest,
    ): AsyncResult<UnsignQuoteResponse, UnknownEntity | InvalidArgument | OperationFailed>;
}

class UnsignQuoteUseCase extends UseCase implements UnsignQuote {
    public static type = Symbol('Quote/UnsignQuote');

    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    public async execute({
        quote,
    }: UnsignQuoteRequest): AsyncResult<
        UnsignQuoteResponse,
        UnknownEntity | InvalidArgument | OperationFailed
    > {
        const unsignResult = quote.unsign();

        if (isErr(unsignResult)) {
            return handleOperationFailure(unsignResult);
        }

        return Success<UnsignQuoteResponse>({
            quote,
        });
    }
}

export const UnsignQuote: UseCaseClass<UnsignQuote> = UnsignQuoteUseCase;
