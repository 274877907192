import { ColumnLayout, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import { GetEndorsementQuoteResponse } from '../useCases/GetEndorsementQuoteUseCase';
import React from 'react';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';

const lineStyle = {
    backgroundColor: '#d9d8f5',
    height: 1,
};
export interface EndorsementQuoteProps {
    quote?: GetEndorsementQuoteResponse;
    isHigherLimitReviewRequired?: boolean;
}

export function EndorsementQuote({ quote, isHigherLimitReviewRequired }: EndorsementQuoteProps) {
    const hideAmounts = isHigherLimitReviewRequired ?? false;
    return quote ? (
        <StackLayout>
            <ColumnLayout gap="4" split="-1">
                <Text style="body 2">Current policy premium</Text>
                <Text style="label 1">
                    {hideAmounts ? '$ --.--' : <MoneyDisplay value={quote.currentPremium} />}
                </Text>
            </ColumnLayout>
            <ColumnLayout gap="4" split="-1">
                <Text style="body 2">New policy premium</Text>
                <Text style="label 1">
                    {hideAmounts ? '$ --.--' : <MoneyDisplay value={quote.returnPremium} />}
                </Text>
            </ColumnLayout>

            <hr style={lineStyle} />

            <ColumnLayout gap="4" split="-1">
                <Text style="body 2">Premium change</Text>
                <Text style="label 1">
                    {hideAmounts ? '$ --.--' : <MoneyDisplay value={quote.premiumChange} />}
                </Text>
            </ColumnLayout>
            <ColumnLayout gap="4" split="-1">
                <Text style="body 2">Taxes</Text>
                <Text style="label 1">
                    {hideAmounts ? '$ --.--' : <MoneyDisplay value={quote.taxes} />}
                </Text>
            </ColumnLayout>
            <ColumnLayout gap="4" split="-1">
                <Text style="body 2">Fees</Text>
                <Text style="label 1">
                    {hideAmounts ? '$ --.--' : <MoneyDisplay value={quote.fees} />}
                </Text>
            </ColumnLayout>

            <hr style={lineStyle} />

            <ColumnLayout gap="4" split="-1">
                <Text style="body 1">Total premium change</Text>
                <Text style="label 1">
                    {hideAmounts ? '$ --.--' : <MoneyDisplay value={quote.totalPremiumChange} />}
                </Text>
            </ColumnLayout>
        </StackLayout>
    ) : null;
}
