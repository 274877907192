import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { UserRepository } from '../repositories/UserRepository';

/**
 * Request data for ForgottenPassword use case
 * @param email is the valid email address of the existing user
 */
export interface ForgottenPasswordRequest {
    readonly email: EmailAddress;
}

/**
 * ForgottenPassword use case is used to reset password for the user who exists on the platform
 */

export interface ForgottenPassword extends UseCase {
    execute(
        request: ForgottenPasswordRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed>;
}

@injectable()
class ForgottenPasswordUseCase extends UseCase implements ForgottenPassword {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/ForgottenPassword');
    /**
     * Constructor for ForgottenPassword use case class instance
     * @param eventBus An event bus this Use Case will publish events to.
     * @param userRepository is used to save user entity who's password was reset
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(UserRepository) private userRepository: UserRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes ForgottenPassword use case
     * Input is of type ForgottenPasswordRequest
     * @returns Nothing if execution was successful
     * @returns InvalidArgument error if update of user entity with matching email address fails
     */
    public async execute({
        email,
    }: ForgottenPasswordRequest): AsyncResult<void, InvalidArgument | OperationFailed> {
        const userResult = await this.userRepository.getUserByEmail(email);

        if (isErr(userResult)) {
            return userResult;
        }

        userResult.value.forgottenPassword();

        const result = await this.userRepository.save(userResult.value);

        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        this.eventBus.publishEntityEvents(userResult.value);
        return Success();
    }
}

export const ForgottenPassword: UseCaseClass<ForgottenPassword> = ForgottenPasswordUseCase;
