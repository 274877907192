import { inject } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { PhoneNumber } from '@embroker/shotwell/core/types/PhoneNumber';
import { AsyncResult, isErr } from '@embroker/shotwell/core/types/Result';
import { Revenue } from '@embroker/shotwell/core/types/Revenue';
import { Year } from '@embroker/shotwell/core/types/Year';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { Location as LocationType } from '../../locations/entities/Location';
import { Organization } from '../../userOrg/entities/Organization';
import { OperationNotAllowedError, OrganizationNotFoundError } from '../../userOrg/errors';
import { OrganizationRepository } from '../../userOrg/repositories/OrganizationRepository';
import { MailingAddress as MailingAddressType } from '../../userOrg/types/MailingAddress';

export interface UpdateClientOrganizationRequest {
    token: string;
    naics: Nullable<string>;
    companyLegalName: string;
    website: string;
    totalNumberOfEmployees: Nullable<number>;
    totalAnnualPayroll: Nullable<Money>;
    yearStarted: Nullable<Year>;
    headquarters: MailingAddressType;
    otherLocations: Immutable<EntityProps<LocationType>>[];
    revenues: Revenue[];
    howDoesYourCompanyGenerateRevenue: Nullable<string>;
    raisedFunding: Nullable<boolean>;
    techAreaOfFocus: Nullable<string>;
    phoneNumber?: PhoneNumber;
}

export interface UpdateClientOrganization extends UseCase {
    execute(
        request: UpdateClientOrganizationRequest,
    ): AsyncResult<
        void,
        OrganizationNotFoundError | OperationNotAllowedError | InvalidArgument | OperationFailed
    >;
}

class UpdateClientOrganizationUseCase extends UseCase implements UpdateClientOrganization {
    public static type = Symbol('Shopping/UpdateClientOrganization');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(OrganizationRepository) private organizationRepository: OrganizationRepository,
    ) {
        super(eventBus);
    }
    public async execute(
        request: UpdateClientOrganizationRequest,
    ): AsyncResult<
        void,
        OrganizationNotFoundError | OperationNotAllowedError | InvalidArgument | OperationFailed
    > {
        const organizationResult = await this.organizationRepository.getClientOrganization(
            request.token,
        );
        if (isErr(organizationResult)) {
            return organizationResult;
        }

        const { value: organization } = organizationResult;

        organization.update(request);

        return await this.organizationRepository.updateClientOrganization(
            request.token,
            organization as Organization,
        );
    }
}

export const UpdateClientOrganization: UseCaseClass<UpdateClientOrganization> =
    UpdateClientOrganizationUseCase;
