import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { Button, ColumnLayout, TextButton } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { WizardForm } from '../../../../view/hooks/useWizardForm';

interface BrokerESPReferredNavigationProps<T extends FormData> {
    hasNext: boolean;
    next: WizardForm<OpaqueForm<T>>['next'];
    hasPrevious: boolean;
    previous: WizardForm<OpaqueForm<T>>['previous'];
    trigger: WizardForm<OpaqueForm<T>>['trigger'];
    isSubmitting: boolean;
    status: WizardForm<OpaqueForm<T>>['status'];
}

export function BrokerESPReferredNavigation<T extends FormData>({
    hasNext,
    next,
    hasPrevious,
    previous,
    trigger,
    isSubmitting,
    status,
}: BrokerESPReferredNavigationProps<T>) {
    const handleRecalculate = () => {
        trigger('update');
    };

    const handleReferred = () => {
        if (hasNext) {
            next();
        }
    };
    const resolveNextButtonLabel = () => {
        return hasNext ? 'Continue' : 'Bind coverage';
    };
    const dirty = status === 'dirty';
    const invalid = status === 'invalid';
    const isNextButtonDisabled = invalid;

    const renderNavigation = () => {
        if (dirty) {
            return (
                <Button disabled={isSubmitting} onClick={handleRecalculate}>
                    Recalculate
                </Button>
            );
        }
        return (
            <Button disabled={isNextButtonDisabled || !hasNext} onClick={handleReferred}>
                {resolveNextButtonLabel()}
            </Button>
        );
    };

    return (
        <ColumnLayout split="-1">
            {renderNavigation()}
            <TextButton
                as="button"
                className={hasPrevious ? '' : 'u-hidden'}
                icon="bold-caret-left"
                disabled={isSubmitting}
                onClick={previous}
            >
                Back to options
            </TextButton>
        </ColumnLayout>
    );
}
