import { isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    BoxLayout,
    Button,
    ButtonBar,
    ColumnLayout,
    Image,
    Spinner,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { GetDocumentUrl } from '../../../../documents/useCases/GetDocumentUrl';
import { GetFile, GetFileRequest } from '../../../../documents/useCases/GetFile';
import { downloadFileFromBrowser } from '../../../../documents/view/DownloadFile';
import { SelfServingCertificateHasMultipleDocuments } from '../../../errors';
import { GetSelfServingCertificate } from '../../../useCases/GetSelfServingCertficate';

interface FinalPageProps {
    emailAddress: string;
    onIssueAnotherCertificate(): void;
    certificateID: UUID;
}

export function FinalPage({
    emailAddress,
    onIssueAnotherCertificate,
    certificateID,
}: FinalPageProps) {
    const { result: certificate, isLoading } = useUseCase(GetSelfServingCertificate, {
        certificateID: certificateID,
    });

    if (isLoading || certificate == undefined) {
        return <Spinner />;
    }

    if (isErr(certificate)) {
        return <ErrorPage errors={certificate.errors} />;
    }

    if (certificate.value.certificate.documentList.length > 1) {
        return (
            <ErrorPage
                errors={[
                    SelfServingCertificateHasMultipleDocuments(
                        certificate.value.certificate.documentList,
                    ),
                ]}
            />
        );
    }

    const certificateDocument = certificate.value.certificate.documentList[0];

    const handleDownloadCertificate = async () => {
        const downloadURL = await execute(GetDocumentUrl, {
            fileKey: certificateDocument.fileKey,
        });

        if (isErr(downloadURL)) {
            return <ErrorPage errors={downloadURL.errors} />;
        }

        const file = await execute(GetFile, {
            url: downloadURL.value.downloadUrl,
            fileName: certificateDocument.name ?? 'certificate',
            fileType: 'application/pdf',
        } as GetFileRequest);

        if (isErr(file)) {
            return <ErrorPage errors={file.errors} />;
        }

        downloadFileFromBrowser(file.value);
    };

    return (
        <ColumnLayout responsive={{ containerWidth: { smallerThan: 'tablet' } }}>
            <StackLayout className="u-1/2@large-tablet u-1/1" gap="48">
                <StackLayout gap="24">
                    <Text style={'heading 1'} data-e2e="final-page-header">
                        That’s it!
                    </Text>
                    <Text style="body 1">
                        You can now download your certificate. For your convenience, we’ve also
                        emailed it to you.
                    </Text>
                </StackLayout>
                <ButtonBar responsive={{ containerWidth: { smallerThan: 400 } }} gap={'48'}>
                    <Button onClick={handleDownloadCertificate} data-e2e="final-page-download-cert">
                        Download Certificate
                    </Button>
                    <TextButton
                        onClick={onIssueAnotherCertificate}
                        data-e2e="final-page-issue-another-cert"
                    >
                        Issue Another Certificate
                    </TextButton>
                </ButtonBar>
            </StackLayout>
            <BoxLayout className="u-1/2@large-tablet u-1/1">
                <Image width="100%" name="illustration-laurel" />
            </BoxLayout>
        </ColumnLayout>
    );
}
