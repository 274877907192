import { defineEntityValidator, entity } from '@embroker/shotwell/core/entity/Entity';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { Quote, commonQuoteProps } from '../../../../quote/entities/Quote';
import { MPLQuoteDetails } from '../types/MPLQuoteDetails';
import { MPLQuoteOptions } from '../types/MPLQuoteOptions';

export const MPLVerticalList = [
    'NonTechnologyBusinessAndManagementConsultants',
    'RealEstateAgent',
    'HomeInspector',
    'TaxPreparersAndBookkeepers',
    'Accountant',
] as const;
export type MPLVertical = (typeof MPLVerticalList)[number];

export interface MPLQuote extends Quote {
    readonly options: MPLQuoteOptions;
    readonly details: MPLQuoteDetails;
    readonly mplVertical: MPLVertical;
}

export const MPLQuote = entity<MPLQuote, Quote>({
    validator: defineEntityValidator<MPLQuote>({
        ...commonQuoteProps,
        options: MPLQuoteOptions.schema.required(),
        details: MPLQuoteDetails.schema.required(),
        mplVertical: Joi.string()
            .valid(...MPLVerticalList)
            .required(),
    }),
    inherits: Quote,
});
