import React, { useState } from 'react';

import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { Lob } from '../../../types/Lob';
import { SubjectOfInsurance } from '../../../types/SubjectOfInsurance';
import { StackLayout, Filter, FilterOption } from '@embroker/ui-toolkit/v2';
import { PropertyCoverageSectionTable } from './PropertyCoverageSectionTable';
import { PropertyCoverageLocationCoverageTable } from './PropertyCoverageLocationCoverageTable';
import { CoverageSectionLimitAndRetention } from './CoverageSectionLimitAndRetention';

interface PropertyCoverageSectionBodyProps {
    lob: Immutable<Lob>;
}

function getLocationDisplay(location: Nullable<Immutable<SubjectOfInsurance>>) {
    if (location === null) {
        return '';
    }
    let key = '';

    if (location.buildingNumber && location.buildingNumber.toString().trim() !== '') {
        key += '#' + location.buildingNumber.toString().trim() + ' - ';
    }

    if (location.streetAddress && location.streetAddress.trim() !== '') {
        key += location.streetAddress.trim();
    }
    if (location.city && location.city.trim() !== '') {
        if (key !== '') {
            key += ', ';
        }
        key += location.city.trim();
    }
    if (location.state && location.state.trim() !== '') {
        if (key !== '') {
            key += ', ';
        }
        key += location.state.trim();
    }

    if (location.zipCode && location.zipCode.trim() !== '') {
        if (key !== '') {
            key += ' ';
        }
        key += location.zipCode.trim();
    }

    return key;
}

export const PropertyCoverageSectionBody = ({ lob }: PropertyCoverageSectionBodyProps) => {
    const locationList: FilterOption<Immutable<SubjectOfInsurance>>[] =
        lob.propertySection?.subjectOfInsuranceList.map((location) => {
            return {
                title: getLocationDisplay(location),
                value: location,
            };
        }) ?? [];

    const [selectedLocation, setSelectedLocation] = useState<
        Immutable<Nullable<SubjectOfInsurance>>
    >(locationList.length > 0 ? locationList[0].value : null);

    if (selectedLocation === null) {
        return null;
    }

    return (
        <StackLayout gap="24">
            <Filter
                options={locationList}
                value={selectedLocation}
                onChange={(event) => {
                    setSelectedLocation(event.target.value);
                }}
                title={`Choose a location`}
            />
            <CoverageSectionLimitAndRetention lob={lob} />
            <PropertyCoverageSectionTable subjectOfInsurance={selectedLocation} />
            <PropertyCoverageLocationCoverageTable subjectOfInsurance={selectedLocation} />
        </StackLayout>
    );
};
