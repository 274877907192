import { API } from '@embroker/shotwell-api/app';
import { isAPIError } from '@embroker/shotwell-api/errors';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { HigherLimitsApprovalNotNeeded } from '../../errors';
import {
    BrokerageQuoteRepository,
    CreateSignaturePacketDocumentTaskResponse,
    GetTaskStatusResponse,
} from './index';

@injectable()
export class APIBrokerageQuoteRepository implements BrokerageQuoteRepository {
    async createSignaturePacketDocumentTask(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<CreateSignaturePacketDocumentTaskResponse, InvalidArgument | OperationFailed> {
        const downloadSignaturePacketResponse = await API.request(
            'shopping/create_signature_packet',
            {
                application_id: applicationId,
                quote_id: quoteId,
            },
        );
        if (isErr(downloadSignaturePacketResponse)) {
            return handleOperationFailure(downloadSignaturePacketResponse);
        }
        return Success<CreateSignaturePacketDocumentTaskResponse>({
            taskId: downloadSignaturePacketResponse.value.task_id,
            documentId: downloadSignaturePacketResponse.value.document_id,
        });
    }

    async getTaskStatus(
        id: UUID,
    ): AsyncResult<GetTaskStatusResponse, InvalidArgument | OperationFailed> {
        const getTaskStatusResponse = await API.request('task/get_task_status', { id });
        if (isErr(getTaskStatusResponse)) {
            return handleOperationFailure(getTaskStatusResponse);
        }

        return Success({
            data: getTaskStatusResponse.value.data,
            error: getTaskStatusResponse.value.error,
        });
    }

    public async requestHigherLimits(
        applicationId: UUID,
        isSubmit: boolean,
    ): AsyncResult<void, HigherLimitsApprovalNotNeeded | InvalidArgument | OperationFailed> {
        const result = await API.request('shopping/request_higher_limits', {
            application_id: applicationId,
            is_submit: isSubmit,
        });

        if (isErr(result)) {
            const error = result.errors[0];
            if (isAPIError(error) && error.details.name === 'operation_not_needed') {
                return Failure(HigherLimitsApprovalNotNeeded());
            }

            return handleOperationFailure(result, 'Higher limit request failed.');
        }

        return Success();
    }
}
