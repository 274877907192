import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { Nullable } from '@embroker/shotwell/core/types';
import { UserOnboardingDnbNaicsRetrieved } from '../entities/User';

export interface PublishDnbNaicsRetrievedEvent extends UseCase {
    execute(request: PublishDnbNaicsRetrievedEventRequest): AsyncResult<void>;
}

export interface PublishDnbNaicsRetrievedEventRequest {
    naicsCode: Nullable<string>;
}

@injectable()
class PublishDnbNaicsRetrievedEventUseCase
    extends UseCase
    implements PublishDnbNaicsRetrievedEvent
{
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/PublishDnbNaicsRetrievedEventUseCase');

    /**
     * Constructor for PublishDnbNaicsRetrievedEvent class instance
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    /**
     * Executes the PublishDnbNaicsRetrievedEvent use case.
     */
    public async execute({ naicsCode }: PublishDnbNaicsRetrievedEventRequest): AsyncResult<void> {
        const userOnboardingDnbNaicsRetrievedEvent: UserOnboardingDnbNaicsRetrieved = {
            origin: 'User',
            name: 'OnboardingDnbNaicsRetrieved',
            createdAt: new Date(Date.now()),
            id: UUID.create(),
            naicsCode,
        };

        await this.eventBus.publish(userOnboardingDnbNaicsRetrievedEvent);

        return Success();
    }
}

export const PublishDnbNaicsRetrievedEvent: UseCaseClass<PublishDnbNaicsRetrievedEvent> =
    PublishDnbNaicsRetrievedEventUseCase;
