import { URLDescriptor } from 'navi';
import { NavigateOptionsWithoutURL } from 'navi/dist/types/Navigation';
import { useCallback, useEffect, useRef } from 'react';
import { useNavigation as useNavigationNavi } from 'react-navi';

export function useNavigation() {
    const isMounted = useRef(false);
    const { navigate: navigateNavi } = useNavigationNavi();

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    });

    const navigate = useCallback(
        (url: string | Partial<URLDescriptor>, options?: NavigateOptionsWithoutURL) => {
            if (isMounted.current) {
                return navigateNavi(url, options);
            }
            if (process.env.NODE_ENV !== 'production') {
                console.warn(`Can't perform navigation triggered from an unmounted component`);
            }
            return Promise.resolve();
        },
        [navigateNavi],
    );

    return { navigate };
}

export type NavigateFunction = ReturnType<typeof useNavigation>['navigate'];
