import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import {
    BoxLayout,
    Button,
    CardLayout,
    Form,
    StackLayout,
    StatusMessageList,
    Text,
    ButtonBar,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { PrivacyMessage } from './PrivacyMessage.view';

export interface PublicFormProps {
    readonly title: Nullable<React.ReactChild>;
    readonly subTitle?: Nullable<React.ReactChild>;
    readonly titleProps?: {};
    readonly children: JSX.Element | JSX.Element[];
    readonly submit: (event: React.FormEvent) => void;
    readonly submitText?: string;
    readonly submitDisabled?: boolean;
    readonly messages?: Immutable<string[]>;
    readonly otherOption?: JSX.Element | JSX.Element[];
    readonly showPrivacyMessage?: boolean;
}

export function PublicForm({
    title,
    subTitle,
    titleProps = {},
    children,
    submit,
    submitText = 'Log in',
    submitDisabled = false,
    messages = [],
    otherOption,
    showPrivacyMessage = true,
}: PublicFormProps) {
    return (
        <StackLayout gap="12">
            <CardLayout radius="24">
                <BoxLayout gap="32">
                    <Form noValidate onSubmit={submit}>
                        <StackLayout gap="24">
                            <StackLayout gap="12">
                                <span />
                                <StatusMessageList
                                    data-e2e="user-org-public-err-msg"
                                    messages={messages}
                                    status="error"
                                />
                                {title && (
                                    <Text style="heading 3" {...titleProps}>
                                        {title}
                                    </Text>
                                )}
                                {subTitle && <Text style="body 1">{subTitle}</Text>}
                            </StackLayout>
                            {children}
                            <ButtonBar
                                split={otherOption ? '-2' : '-1'}
                                responsive={{ containerWidth: { smallerThan: 'large-mobile' } }}
                                center
                            >
                                <Button
                                    data-e2e="user-org-public-submit-btn"
                                    type="submit"
                                    disabled={submitDisabled}
                                >
                                    {submitText}
                                </Button>
                                {otherOption}
                            </ButtonBar>
                        </StackLayout>
                    </Form>
                </BoxLayout>
            </CardLayout>
            {showPrivacyMessage && <PrivacyMessage />}
        </StackLayout>
    );
}
