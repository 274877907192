import { InsuranceApplicationSortColumn } from '@embroker/shotwell-api/app';
import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { BrokerQuoteRecordList, BrokerRepository } from '../repositories';
import { ListOptions } from '../types/ListOptions';

export type QuoteTableColumn = InsuranceApplicationSortColumn | 'product_type' | 'premium';
export interface QuoteTableFilter {
    target: QuoteTableColumn;
    value: string;
}
export interface GetBrokerQuotesRequest {
    readonly index: number;
    readonly size: number;
    readonly column?: QuoteTableColumn;
    readonly order?: 'ascending' | 'descending';
    readonly filters?: QuoteTableFilter[];
}

export interface GetBrokerQuotes extends UseCase {
    execute(
        request: GetBrokerQuotesRequest,
    ): AsyncResult<BrokerQuoteRecordList, InvalidArgument | OperationFailed>;
}

class GetBrokerQuotesUseCase extends UseCase implements GetBrokerQuotes {
    public static type = Symbol('Broker/GetBrokerQuotes');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BrokerRepository) private brokerRepository: BrokerRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: GetBrokerQuotesRequest,
    ): AsyncResult<BrokerQuoteRecordList, InvalidArgument | OperationFailed> {
        const listOptionsResult = ListOptions.create(request);
        if (isErr(listOptionsResult)) {
            return listOptionsResult;
        }
        return this.brokerRepository.getBrokerQuoteRecordList(listOptionsResult.value);
    }
}

export const GetBrokerQuotes: UseCaseClass<GetBrokerQuotes> = GetBrokerQuotesUseCase;
