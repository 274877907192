import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase } from '@embroker/shotwell/core/UseCase';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { Claimant } from '../entities/Claim';
import { ClaimRepository } from '../repositories/claims';
import { ClaimDetails } from '../types/ClaimDetails';
import { ClaimDocument } from '../types/ClaimDocument';

export interface GetPolicyClaimsDetailsRequest {
    policyId: UUID;
}

export const GetPolicyClaimsDetailsRequest = {
    ...defineValidator<GetPolicyClaimsDetailsRequest>({
        policyId: UUID.schema,
    }),
    create(getPolicyClaimsDetailsRequest: GetPolicyClaimsDetailsRequest) {
        return GetPolicyClaimsDetailsRequest.validate(getPolicyClaimsDetailsRequest);
    },
};
export interface GetPolicyClaimsDetailsResponse {
    claimList: ClaimDetails[];
    policyNumber: string;
    insurerName: string;
    startDate: Date;
    endDate: Date;
}

export const GetPolicyClaimsDetailsResponse = {
    ...defineValidator<GetPolicyClaimsDetailsResponse>({
        claimList: Joi.array().items(ClaimDetails.schema),
        policyNumber: Joi.string(),
        insurerName: Joi.string(),
        startDate: Joi.date(),
        endDate: Joi.date(),
    }),
    create(getPolicyClaimsDetailsResponse: GetPolicyClaimsDetailsResponse) {
        return GetPolicyClaimsDetailsResponse.validate(getPolicyClaimsDetailsResponse);
    },
};

@injectable()
export class GetPolicyClaimsDetails extends UseCase {
    public static type = Symbol('Claim/GetPolicyClaimsDetails');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ClaimRepository) private claimRepository: ClaimRepository,
    ) {
        super(eventBus);
    }
    async execute(
        request: GetPolicyClaimsDetailsRequest,
    ): AsyncResult<
        GetPolicyClaimsDetailsResponse,
        InvalidArgument | OperationFailed | UnknownEntity
    > {
        const getClaimsForPolicyResult = await this.claimRepository.getClaimsForPolicy(
            request.policyId,
        );
        if (isErr(getClaimsForPolicyResult)) {
            return getClaimsForPolicyResult;
        }

        const response: GetPolicyClaimsDetailsResponse = {
            claimList: [],
            endDate: getClaimsForPolicyResult.value.endDate,
            insurerName: getClaimsForPolicyResult.value.insurerName,
            policyNumber: getClaimsForPolicyResult.value.policyNumber,
            startDate: getClaimsForPolicyResult.value.startDate,
        };

        for (const claim of getClaimsForPolicyResult.value.claimsList) {
            const getDocumentsResult = await this.claimRepository.getClaimDocuments(claim.id);

            if (isErr(getDocumentsResult)) {
                return getDocumentsResult;
            }

            response.claimList.push({
                adjusterName: claim.adjusterName,
                adjusterPhoneNumber: claim.adjusterPhoneNumber,
                causeOfLoss: claim.causeOfLoss,
                claimNumber: claim.claimNumber,
                claimantList: claim.claimantList as Claimant[],
                closedDate: claim.closedDate,
                lossDate: claim.lossDate,
                reportedDate: claim.reportedDate,
                reservedAmount: claim.reservedAmount,
                amountPaid: claim.amountPaid,
                status: claim.status,
                valuationDate: claim.valuationDate,
                documents: getDocumentsResult.value as ClaimDocument[],
            } as ClaimDetails);
        }

        return Success(response);
    }
}
