import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success, isErr, Failure } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { NotificationRepository } from '../repositories/NotificationRepository';
import { NotificationViewModel } from '../entities/Notification';
import { NotificationStatus } from '@embroker/shotwell-api/v2/app.spec';
import { FailedToUpdateNotificationStatus, FailedToCreateNotificationEntity } from '../errors';

export interface UpdateNotificationStatusRequest {
    notification: NotificationViewModel;
    status: NotificationStatus;
}

export interface UpdateNotificationStatus extends UseCase {
    execute(
        request: UpdateNotificationStatusRequest,
    ): AsyncResult<
        NotificationViewModel,
        InvalidArgument | FailedToCreateNotificationEntity | FailedToUpdateNotificationStatus
    >;
}

class UpdateNotificationStatusUseCase extends UseCase implements UpdateNotificationStatus {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('NotificationCenter/UpdateNotificationStatusUseCase');
    /**
     * Constructor for UpdateNotificationStatusUseCase use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param notificationRepository is notification repository used to store user notifications
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(NotificationRepository) private notificationRepository: NotificationRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: UpdateNotificationStatusRequest,
    ): AsyncResult<
        NotificationViewModel,
        InvalidArgument | FailedToCreateNotificationEntity | FailedToUpdateNotificationStatus
    > {
        const notification = await this.notificationRepository.updateNotificationStatus(request);

        if (isErr(notification)) {
            return Failure(FailedToUpdateNotificationStatus());
        }

        return Success<NotificationViewModel>(notification.value.toDTO());
    }
}

export const UpdateNotificationStatus: UseCaseClass<UpdateNotificationStatus> =
    UpdateNotificationStatusUseCase;
