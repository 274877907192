import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { CNAQuote } from '../entities/CNAQuote';
import { CNAQuoteRepository } from '../repositories/CNAQuoteRepository';

export interface GetInitialCNAQuoteRequest {
    applicationId: UUID;
}

export interface GetInitialCNAQuoteResponse {
    cnaQuote: CNAQuote;
}

export interface GetInitialCNAQuote extends UseCase {
    execute(
        request: GetInitialCNAQuoteRequest,
    ): AsyncResult<GetInitialCNAQuoteResponse, InvalidArgument | OperationFailed>;
}

@injectable()
class GetInitialCNAQuoteUseCase extends UseCase implements GetInitialCNAQuote {
    public static type = Symbol('BOPCNA/GetInitialCNAQuote');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(CNAQuoteRepository) private cnaQuoteRepository: CNAQuoteRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
    }: GetInitialCNAQuoteRequest): AsyncResult<
        GetInitialCNAQuoteResponse,
        InvalidArgument | OperationFailed
    > {
        const result = await this.cnaQuoteRepository.getCurrentCNAQuote(applicationId);
        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        const { value: cnaQuote } = result;

        return Success<GetInitialCNAQuoteResponse>({ cnaQuote });
    }
}

export const GetInitialCNAQuote: UseCaseClass<GetInitialCNAQuote> = GetInitialCNAQuoteUseCase;
