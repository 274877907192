import { injectable } from '@embroker/shotwell/core/di';
import { Nullable } from '@embroker/shotwell/core/types';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import * as cookie from 'cookie';
import { AppContextStore, AppContext } from '../../view/AppContext';
import { ExperimentationServicePlatforms } from '../types/enums';
import { Location, LocationState } from '@embroker/shotwell/core/Location';

type AppContextChangeCallback = (context: AppContext) => void;
type LocationChangeCallback = (url: URL) => void;

interface AppContextChangeSubscription {
    callback: AppContextChangeCallback;
}

@injectable()
export class BaseExperimentationService {
    private appContextChangeSubcription: Nullable<AppContextChangeSubscription> = null;
    private prevContext: Nullable<AppContext> = null;
    protected platform: Nullable<ExperimentationServicePlatforms>;
    protected platformName: Nullable<string>;

    constructor() {
        this.platform = null;
        this.platformName = null;

        AppContextStore.subscribe((context) => {
            this.handleAppContextStoreChange(context);
        });
    }

    private handleAppContextStoreChange(nextContext: AppContext): void {
        const nextContextOrganizationId = nextContext.activeSession.organizationId ?? null;
        const prevContentOrganizationId = this.prevContext?.activeSession.organizationId ?? null;

        if (nextContextOrganizationId !== prevContentOrganizationId) {
            this.appContextChangeSubcription?.callback(nextContext);
        }

        this.prevContext = nextContext;
    }

    /**
     * Subscribe to AppContext change. Triggers callback when change to organizationId is detected
     *
     * @param callback Called when application context has been changed. Provided `appContext`.
     * @returns Unsubscription function
     */
    protected subscribeToAppContextChange(callback: AppContextChangeCallback): () => void {
        this.appContextChangeSubcription = { callback };

        return () => {
            this.appContextChangeSubcription = null;
        };
    }

    protected getCurrentLocation(): URL {
        return new URL(globalThis.location.href);
    }

    protected getDeviceId(): UUID {
        let deviceId = localStorage.getItem('deviceId') as UUID;

        if (!deviceId) {
            deviceId = UUID.create();
            localStorage.setItem('deviceId', deviceId);
        }

        return deviceId;
    }

    protected getGaCookieId(): string | undefined {
        try {
            return cookie.parse(document.cookie)._ga.substring(6);
        } catch (e) {
            return undefined;
        }
    }

    /**
     * Subscribe to window URL change events. Triggers callback when `history.pushState()` is called.
     * @param callback Called when `history.pushState()` is triggered
     * @returns Unsubscription function
     */
    protected subscribeToLocationChange(callback: LocationChangeCallback): () => void {
        return Location.subscribe((locationObserver: LocationState) => {
            callback(locationObserver.url);
        }, []);
    }

    private printPlatformName(): string {
        return this.platformName || this.platform || '';
    }

    protected printLog(message: string): void {
        console.log(this.printPlatformName(), message);
    }

    protected printInfo(message: string): void {
        console.info(this.printPlatformName(), message);
    }

    protected printError(message: string): void {
        console.error(this.printPlatformName(), message);
    }
}
