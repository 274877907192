export type PCoMLEndorsementLiabilityCoverageType =
    | 'LiabilityCoverageCodeListIndemnifiableDirectorsAndOfficersLiability'
    | 'LiabilityCoverageCodeListEmploymentPracticesLiability';

export const PCoMLEndorsementLiabilityCoverageTypesList: Array<PCoMLEndorsementLiabilityCoverageType> =
    [
        'LiabilityCoverageCodeListIndemnifiableDirectorsAndOfficersLiability',
        'LiabilityCoverageCodeListEmploymentPracticesLiability',
    ];

const mapCoverageTypeToDisplayName: Record<PCoMLEndorsementLiabilityCoverageType, string> = {
    LiabilityCoverageCodeListIndemnifiableDirectorsAndOfficersLiability: 'Directors and Officers',
    LiabilityCoverageCodeListEmploymentPracticesLiability: 'Employment Practices Liability',
};

export function getCoverageDisplayName(
    coverageType: PCoMLEndorsementLiabilityCoverageType,
): string {
    return mapCoverageTypeToDisplayName[coverageType];
}
