import { CenterLayout, CoverLayout, Image, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';

export function ShareableApplicationProgressSaved() {
    return (
        <CoverLayout>
            <StackLayout gap="32">
                <CenterLayout>
                    <Image inline={false} name="shotwell-1" />
                </CenterLayout>
                <CenterLayout centerText>
                    <StackLayout gap="32">
                        <Text as="h1" style="heading 1">
                            Progress saved
                        </Text>
                        <Text as="p" style="body 1">
                            You are good to return at a later point to continue your application.
                        </Text>
                    </StackLayout>
                </CenterLayout>
            </StackLayout>
        </CoverLayout>
    );
}
