import { StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';

export function BOPHartfordFooter() {
    return (
        <StackLayout gap="none">
            <Text style="body 2" data-e2e="bop-hartford-carrier">
                BOP Carrier: The Hartford
            </Text>
        </StackLayout>
    );
}
