import { UUID } from '@embroker/shotwell/core/types/UUID';
import React, { useEffect, useMemo, useState } from 'react';

import { useNavigation } from '../../../../view/hooks/useNavigation';

import { BOPHartfordQuote } from '@app/quote/hartford-bop/entities/BOPHartfordQuote';
import { QuoteBreakdown } from '@app/quote/hartford-bop/view/components/QuoteBreakdown';
import {
    BOPHartfordQuoteOptionsFormData,
    createBOPHartfordQuoteOptionsFormData,
} from '@app/quote/hartford-bop/view/components/createBOPHartfordOptionsForm';
import { QuoteCoverage } from '@app/quote/hartford-bop/view/components/Coverage';
import { QuoteSignature } from '@app/quote/hartford-bop/view/components/QuoteSignature';
import { useWizardForm, WizardForm, WizardPageDefinition } from '@app/view/hooks/useWizardForm';
import { Signature } from '@app/quote/types/Signature';
import { BOPHartfordHeroBanner } from '@app/quote/hartford-bop/view/components/BOPHartfordBanner';
import { LeavingConfirmationModal } from '@app/quote/view/components/LeavingConfirmationModal';
import {
    useModal,
    PageLayout,
    StackLayout,
    ColumnLayout,
    Stack,
    Loader,
    Text,
} from '@embroker/ui-toolkit/v2';
import { BOPHartfordFooter } from '@app/quote/hartford-bop/view/components/BOPHartfordFooter';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { DownloadDocumentButton } from '@app/quote/hartford-bop/view/components/quoteDocuments/QuoteDocumentButton';
import { DocumentsItem } from '@app/bundle/view/components/BundleQuoteLandingPage';
import { ThankYouErrorModal } from '@app/shopping/view/components/ThankYouErrorModal';

export interface BOPHartfordLandingPageProps {
    applicationId: UUID;
    quote: BOPHartfordQuote;
}

export interface BOPHartfordQuoteCoverageListProps<T extends BOPHartfordQuoteOptionsFormData> {
    fields: WizardForm<OpaqueForm<T>>['fields'];
    value: T;
    trigger: WizardForm<OpaqueForm<T>>['trigger'];
    setValue: (value: any) => void;
    submit: () => void;
    previous: () => void;
    messages: readonly string[];
    quoteHasExpired: boolean;
    appHasExpired: boolean;
    isDirty: boolean;
    quote: BOPHartfordQuote;
}

export interface QuoteLandingPageState {
    quote: BOPHartfordQuote;
}

export interface Page<T extends BOPHartfordQuoteOptionsFormData> {
    name: string;
    component: React.FC<BOPHartfordQuoteCoverageListProps<T>>;
}

export function BOPHartfordLandingPage({ quote: initialQuote }: BOPHartfordLandingPageProps) {
    const { navigate } = useNavigation();
    const [signature, setSignature] = useState<Signature>({
        transfers: false,
        warranty: false,
    });
    const [state, setState] = useState<QuoteLandingPageState>({
        quote: initialQuote,
    });

    const leavingConfirmationModal = useModal();
    const thankYouErrorModal = useModal();

    const { show: showThankYouErrorModal } = thankYouErrorModal;

    const abortController = useMemo(() => {
        return new AbortController();
    }, []);

    const handleConfirmLeaving = () => {
        leavingConfirmationModal.hide();
        navigate('/');
    };

    const quoteOptionsForm = useMemo(
        () =>
            createBOPHartfordQuoteOptionsFormData({
                quote: state.quote,
                abortSignal: abortController.signal,
                isBroker: false,
                isAdmin: false,
                onUpdateQuote: (quote) => {
                    setState({
                        quote,
                    });
                },
                setSignature,
                navigate,
            }),
        [state.quote, abortController.signal, navigate],
    );

    useEffect(() => {
        if (!state.quote.totalPremium.amount) {
            showThankYouErrorModal();
        }
    });

    const quotePage: WizardPageDefinition<typeof quoteOptionsForm> = {
        name: 'coverage',
        fields: ['effectiveDate'],
    };
    const signPage: WizardPageDefinition<typeof quoteOptionsForm> = {
        name: 'signature',
        fields: ['agreementToConductSignature', 'warrantyAndFraudSignature'],
    };

    const initialValue: BOPHartfordQuoteOptionsFormData = {
        effectiveDate: state.quote?.options.effectiveDate,
        agreementToConductSignature: signature.transfers,
        warrantyAndFraudSignature: signature.warranty,
        brokerSignature: false,
    };

    const formPages = [quotePage, signPage];
    const optionsWizardForm = useWizardForm(quoteOptionsForm, {
        pages: formPages,
        initialValue: initialValue,
    });

    const {
        reset,
        hasPrevious,
        setValue,
        value,
        next,
        previous,
        activePageIndex,
        trigger,
        fields,
        messages,
        submit,
        status,
    } = optionsWizardForm;

    const quoteStepPage: Page<typeof value> = {
        name: 'coverage',
        component: QuoteCoverage,
    };
    const quoteSignPage: Page<typeof value> = {
        name: 'signature',
        component: QuoteSignature,
    };

    const pages: Page<typeof value>[] = [quoteStepPage, quoteSignPage];

    const getDocumentList = (): DocumentsItem[] => {
        const applicationDocument: DocumentsItem = {
            component: DownloadDocumentButton,
            handleTrigger: () => {
                reset();
                trigger('downloadApp');
            },
            isDisabled: false,
            displayName: 'Application',
        };

        const quoteDocument: DocumentsItem = {
            component: DownloadDocumentButton,
            handleTrigger: () => {
                reset();
                trigger('downloadQuote');
            },
            isDisabled: false,
            displayName: 'Quote Document',
        };

        return [applicationDocument, quoteDocument];
    };

    const isSubmitting = status === 'submitting';

    return (
        <React.Fragment>
            <LeavingConfirmationModal
                modal={leavingConfirmationModal}
                onConfirmLeaving={handleConfirmLeaving}
            />

            <ThankYouErrorModal modal={thankYouErrorModal} />

            <BOPHartfordHeroBanner
                onDismiss={() => leavingConfirmationModal.show()}
                title="Your Customizable Quote"
            />

            <PageLayout.Section>
                <StackLayout>
                    <ColumnLayout
                        top
                        gap="24"
                        responsive={{ containerWidth: { smallerThan: 588 } }}
                    >
                        <StackLayout gap="24" className="u-1/1">
                            <Stack activeIndex={activePageIndex}>
                                {pages.map(({ component: Component }, index) => (
                                    <div key={index}>
                                        <Component
                                            quote={state.quote}
                                            value={value}
                                            setValue={setValue}
                                            fields={fields}
                                            trigger={trigger}
                                            previous={previous}
                                            isDirty={status === 'dirty'}
                                            submit={submit}
                                            messages={messages}
                                            quoteHasExpired={false}
                                            appHasExpired={false}
                                        />
                                    </div>
                                ))}
                            </Stack>
                            <BOPHartfordFooter />
                        </StackLayout>

                        {isSubmitting ? (
                            <Loader
                                heading={<Text style="heading 4">Finalizing your request</Text>}
                            />
                        ) : null}

                        <StackLayout gap="24" className="u-1/1 u-3/6@tablet">
                            <QuoteBreakdown
                                quote={state.quote}
                                next={next}
                                hasPrevious={hasPrevious}
                                effectiveDate={value.effectiveDate}
                                documentList={getDocumentList()}
                                fields={fields}
                                status={status}
                                trigger={trigger}
                                onDateChange={(event) => {
                                    setValue({
                                        ...value,
                                        effectiveDate: event.target.date,
                                    });
                                }}
                            />
                        </StackLayout>
                    </ColumnLayout>
                </StackLayout>
            </PageLayout.Section>
        </React.Fragment>
    );
}
