import React, { useCallback, useEffect, useState } from 'react';
import { Modal, StackLayout, useModal, Text } from '@embroker/ui-toolkit/v2';
import { execute } from '@embroker/shotwell/core/UseCase';
import { PublishShoppingUserClickEvent } from '@app/shopping/useCases/PublishShoppingUserClickEvent';
import { useStartApplication } from '@app/shopping/view/hooks/useStartApplication';
import { ModalLayout } from '@app/view/components/ModalLayout.view';
import { AppTypeCodeListManualEmploymentPractices } from '@app/shopping/types/enums';

type ESPEPLWarningModalProps = {
    isESPInCaliforniaWithOnlyEPLSelected: boolean;
};

export function ESPEPLWarningModal({
    isESPInCaliforniaWithOnlyEPLSelected,
}: ESPEPLWarningModalProps) {
    const [hasESPEPLModalBeenShown, setHasESPEPLModalBeenShown] = useState(false);

    const modal = useModal({ visible: false });
    const showModal = useCallback(() => {
        modal.show();
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'Quote page EPL CA Pop up Displayed',
        });
    }, [modal]);

    useEffect(() => {
        if (!hasESPEPLModalBeenShown && isESPInCaliforniaWithOnlyEPLSelected) {
            showModal();
            setHasESPEPLModalBeenShown(true);
        }
    }, [hasESPEPLModalBeenShown, isESPInCaliforniaWithOnlyEPLSelected, showModal]);

    const { startApplication } = useStartApplication();
    const onModalDismiss = () => {
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'Quote page EPL CA Pop up closed',
        });
    };
    const onModalClose = () => {
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'Quote page EPL CA Pop up closed',
        });
        setHasESPEPLModalBeenShown(true);
    };
    const handlePrimaryCTAClick = () => {
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'Quote page EPL CA Pop up _ add more coverage clicked',
        });
        setHasESPEPLModalBeenShown(true);
        modal.hide();
    };
    const handleSecondaryCTAClick = () => {
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'Quote page EPL CA Pop up _ get EPLI quote only clicked',
        });
        startApplication({ selectedAppTypes: [AppTypeCodeListManualEmploymentPractices] });
        modal.hide();
    };
    return (
        <Modal
            {...modal}
            onDismiss={onModalDismiss}
            onClose={onModalClose}
            size="small"
            dismissable
        >
            <ModalLayout
                title={
                    'Looks like you’re trying to get Employment Practices Liability (EPLI) on its own'
                }
                primaryAction={{
                    label: 'Add Another Coverage',
                    onClick: () => handlePrimaryCTAClick(),
                }}
                secondaryAction={{
                    label: 'Get EPLI Quote Only',
                    onClick: () => handleSecondaryCTAClick(),
                }}
            >
                <StackLayout gap="12">
                    <Text style="body 1">
                        Unfortunately, in California, you can only get an EPLI policy online if it’s
                        packaged with other products.
                    </Text>
                    <Text style="body 1">
                        To get EPLI digitally, simply select at least one other coverage.
                    </Text>
                    <Text style="body 1">
                        If you do want EPLI only, you'll need to provide some additional information
                        so our team can find the best fit for your business
                    </Text>
                </StackLayout>
            </ModalLayout>
        </Modal>
    );
}
