import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UpdateNotificationStatusRequest } from '../../useCases/UpdateNotificationStatus';
import {
    FailedToCreateNotificationEntity,
    FailedToGetNotifications,
    FailedToUpdateAllNotificationsStatus,
    FailedToUpdateNotificationStatus,
    FailedToGetNotificationsStats,
} from '../../errors';
import { Notification, NotificationStatus } from '../../entities/Notification';
import { NotificationStats } from '../../types/NotificationStats';

export interface NotificationRepository {
    /**
     * Fetches active user's notifications
     * @returns A list of notifications for current user
     * @return error if operation fails
     */
    getNotifications(): AsyncResult<
        Notification[],
        InvalidArgument | FailedToGetNotifications | UnknownEntity | OperationFailed
    >;
    updateNotificationStatus(
        request: UpdateNotificationStatusRequest,
    ): AsyncResult<
        Notification,
        | InvalidArgument
        | FailedToUpdateNotificationStatus
        | UnknownEntity
        | FailedToCreateNotificationEntity
    >;
    updateAllNotificationStatus(
        status: NotificationStatus,
    ): AsyncResult<void, FailedToUpdateAllNotificationsStatus>;
    getNotificationsStats(): AsyncResult<NotificationStats, FailedToGetNotificationsStats>;
}

export const NotificationRepository = Symbol('NotificationRepository');
