import {
    API,
    PaymentsAscendRequest,
    PaymentsPremiumFinanceSendIneligiblePaymentsRequest,
    PaymentsStripePayment,
    PaymentsStripePaymentGetByPublicKeyRequest,
    PaymentsStripePaymentGetListRequest,
    PaymentsStripePaymentGetRequest,
    PaymentsStripePaymentGetResponse,
    PaymentsStripePaymentListChargeRequest,
    PaymentsStripePaymentStatus,
    PaymentsStripeUserFilteredPaymentListsRequest,
    request,
    StripePaymentList,
} from '@embroker/shotwell-api/app';
import { PaymentsPurchaseTypeCodeListItem } from '@embroker/shotwell-api/enums';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { Money, USD } from '@embroker/shotwell/core/types/Money';
import { cast } from '@embroker/shotwell/core/types/Nominal';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { CreateTokenCardData, Stripe, StripeCardElement, Token } from '@stripe/stripe-js';
import { ACHTokenRequest, ChargeInvoiceInput, FilteredInvoices, InvoiceRepository } from '.';
import { Invoice } from '../../entities/Invoice';
import { isPaymentError, PaymentError } from '../../types/errors';
import { SendIneligiblePaymentsRequest } from '../../useCases/SendIneligiblePayments';
import { PaymentsBundleStripePaymentGetByPublicKeyRequest } from '@embroker/shotwell-api/app.spec';
import { PayByAscendInput } from '../../useCases/PayByAscend';

@injectable()
export class APIInvoiceRepository implements InvoiceRepository {
    async getFiltered(
        organizationId: UUID,
    ): AsyncResult<FilteredInvoices, InvalidArgument | OperationFailed> {
        const filteredPaymentRequest: PaymentsStripeUserFilteredPaymentListsRequest = {
            organization_id: organizationId,
        };

        const result = await API.request(
            'payments/stripe_user_filtered_payment_lists',
            filteredPaymentRequest,
        );

        if (isErr(result)) {
            return handleOperationFailure(result);
        }
        const response = result.value;

        const activePaymentsResult = await this.createInvoiceList(response.active_payments);
        if (isErr(activePaymentsResult)) {
            return activePaymentsResult;
        }

        const chargedPaymentsResult = await this.createInvoiceList(response.charged_payments);
        if (isErr(chargedPaymentsResult)) {
            return chargedPaymentsResult;
        }

        const creditPaymentsResult = await this.createInvoiceList(response.credit_payments);
        if (isErr(creditPaymentsResult)) {
            return creditPaymentsResult;
        }

        // Remove temporary invoices if there are generated ones
        const allPayments = [
            ...activePaymentsResult.value,
            ...chargedPaymentsResult.value,
            ...creditPaymentsResult.value,
        ];

        const filteredActivePaymentsResult = activePaymentsResult.value.filter((item) => {
            if (item.invoiceNumber === 'Pending') {
                return !allPayments.find(
                    (elem) => elem.policyId == item.policyId && elem.invoiceNumber !== 'Pending',
                );
            }
            return true;
        });

        return Success({
            duePayments: filteredActivePaymentsResult,
            creditPayments: creditPaymentsResult.value,
            chargedPayments: chargedPaymentsResult.value,
        } as FilteredInvoices);
    }
    async getByIds(ids: UUID[]): AsyncResult<Invoice[], InvalidArgument | OperationFailed> {
        const requestData: PaymentsStripePaymentGetListRequest = {
            payment_ids: ids,
        };

        const result = await API.request('payments/stripe_payment_get_list', requestData);
        if (isErr(result)) {
            return handleOperationFailure(result);
        }
        const response = result.value;

        const invoices: Invoice[] = [];
        for (const invoice of response.payments) {
            const invoiceResult = await this.createInvoice(invoice);
            if (isErr(invoiceResult)) {
                return invoiceResult;
            }
            invoices.push(invoiceResult.value as Invoice);
        }
        return Success(invoices);
    }

    async getById(id: UUID): AsyncResult<Invoice, InvalidArgument | OperationFailed> {
        const requestData: PaymentsStripePaymentGetRequest = {
            id: id,
        };

        const result = await API.request('payments/stripe_payment_get', requestData);
        if (isErr(result)) {
            return handleOperationFailure(result);
        }
        return await this.createInvoice(result.value as PaymentsStripePaymentGetResponse);
    }

    async update(
        entity: Invoice,
    ): AsyncResult<Invoice, UnknownEntity | InvalidArgument | OperationFailed> {
        //clear cache
        return Failure(UnknownEntity('Invoice', entity.id));
    }

    async chargePayment(
        chargeInvoiceInput: ChargeInvoiceInput,
    ): AsyncResult<string, InvalidArgument | OperationFailed | PaymentError> {
        const chargeRequest: PaymentsStripePaymentListChargeRequest = {
            payment_ids: chargeInvoiceInput.invoiceIds,
            token_id: chargeInvoiceInput.token,
            email: '',
            source_type: chargeInvoiceInput.paymentMethod,
        };

        return request('payments/stripe_payment_list_charge', chargeRequest).then((result) => {
            if (isErr(result)) {
                const paymentError = isPaymentError(result.errors);
                return paymentError ? Failure(paymentError) : handleOperationFailure(result);
            }
            const response = result.value;
            return Success(response);
        });
    }

    async sendPaymentToAscend(
        ascendInput: PayByAscendInput,
    ): AsyncResult<string, InvalidArgument | OperationFailed | PaymentError> {
        const ascendRequest: PaymentsAscendRequest = {
            payment_ids: ascendInput.invoiceIds,
            user_id: ascendInput.userId as string,
            organization_id: ascendInput.organizationId,
        };

        return request('payments/ascend_payment_list_create', ascendRequest).then((result) => {
            if (isErr(result)) {
                const paymentError = isPaymentError(result.errors);
                return paymentError ? Failure(paymentError) : handleOperationFailure(result);
            }
            const response = result.value;
            return Success(response);
        });
    }

    async getACHToken(
        input: ACHTokenRequest,
        stripeObj: Stripe,
    ): AsyncResult<Token | undefined, OperationFailed> {
        const token = {
            country: input.country,
            currency: input.currency,
            routing_number: input.routingNumber,
            account_number: input.accountNumber,
            account_holder_name: input.accountHolderName,
            account_holder_type: input.accountHolderType,
        };

        const result = await stripeObj.createToken('bank_account', token);

        if (result.error !== undefined) {
            return Failure(OperationFailed({ message: result.error?.message }));
        }

        return Success(result.token);
    }

    async getCCToken(
        stripe: Stripe,
        card: StripeCardElement,
        data: CreateTokenCardData,
    ): AsyncResult<Token | undefined, OperationFailed> {
        const result = await stripe.createToken(card, data);

        if (result.error !== undefined) {
            return Failure(OperationFailed({ message: result.error?.message }));
        }

        return Success(result.token);
    }

    async getStripeConfig(): AsyncResult<any, InvalidArgument | OperationFailed> {
        return request('payments/stripe_config', null).then((result) => {
            if (isErr(result)) {
                return handleOperationFailure(result);
            }
            const response = result.value;
            return Success(response);
        });
    }

    private async createInvoice(
        response: Immutable<PaymentsStripePayment>,
    ): AsyncResult<Invoice, InvalidArgument | OperationFailed> {
        const policyId = response.policy_id === '' ? undefined : response.policy_id;
        if (policyId !== undefined && !UUID.check(policyId)) {
            return Failure(OperationFailed({ message: 'PolicyId is not a valid UUID' }));
        }
        const invoiceResult = await Invoice.create({
            id: cast<UUID>(response.id as string),
            organizationId: cast<UUID>(response.organization_id),
            invoiceNumber: response.name.includes(':') ? 'Pending' : response.name,
            total: response.total_policy_cost as Money,
            balance: response.balance as Money,
            createdDate: response.created_at,
            lineOfBusiness: response.meta.policy_lob,
            policyNumber: response.meta.policy_number ? response.meta.policy_number : 'Pending',
            policyId: policyId,
            policyEffectiveDate: response.meta.policy_effective_date,
            status: response.status as PaymentsStripePaymentStatus,
            purchaseType: (response.purchase_type as PaymentsPurchaseTypeCodeListItem) ?? undefined,
            isEndorsement: response.is_endorsement as boolean,
            isNotEligibleForFinancing: response.not_eligible_for_financing as boolean,
            invoiceItemList: response.meta.invoice_item_list.map((element) => ({
                description: element.description,
                amount: USD(element.amount),
            })),
            billingName: response.billing_name,
            description: response.description,
            bundleId: response.bundle_id,
            carrierName: response.carrier_name,
        });
        if (isErr(invoiceResult)) {
            return handleOperationFailure(invoiceResult);
        }
        return invoiceResult;
    }
    private async createInvoiceList(
        response: Immutable<StripePaymentList>,
    ): AsyncResult<Invoice[], InvalidArgument | OperationFailed> {
        const invoices: Invoice[] = [];
        for (const invoice of response) {
            const invoiceResult = await this.createInvoice(invoice);
            if (isErr(invoiceResult)) {
                return handleOperationFailure(invoiceResult);
            }
            invoices.push(invoiceResult.value as Invoice);
        }
        return Success(invoices);
    }

    async getPublicInvoice(
        publicKey: string,
    ): AsyncResult<Immutable<Invoice>, InvalidArgument | OperationFailed | PaymentError> {
        const request: PaymentsStripePaymentGetByPublicKeyRequest = {
            public_key: publicKey,
        };
        const response = await API.request('payments/stripe_payment_get_by_public_key', request);

        if (isErr(response)) {
            const paymentError = isPaymentError(response.errors);
            if (paymentError) {
                return Failure(paymentError);
            }
            return handleOperationFailure(response);
        }

        const invoice = await this.createInvoice(response.value);

        if (isErr(invoice)) {
            return handleOperationFailure(invoice);
        }

        return Success(invoice.value);
    }

    async getBundlePublicInvoiceList(
        publicKey: UUID,
    ): AsyncResult<Invoice[], InvalidArgument | OperationFailed | PaymentError> {
        const request: PaymentsBundleStripePaymentGetByPublicKeyRequest = {
            public_key: publicKey,
        };
        const response = await API.request(
            'payments/bundle_stripe_payment_get_by_public_key',
            request,
        );

        if (isErr(response)) {
            const paymentError = isPaymentError(response.errors);
            if (paymentError) {
                return Failure(paymentError);
            }
            return Failure(OperationFailed(response));
        }

        return await this.createInvoiceList(response.value);
    }

    async sendIneligibleInvoices(
        data: SendIneligiblePaymentsRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed> {
        const request: PaymentsPremiumFinanceSendIneligiblePaymentsRequest = {
            payment_ids: data.paymentIds,
            organization_id: data.organizationID,
            invoice_number_list: data.invoiceList,
        };
        const response = await API.request(
            'payments/premium_finance_send_ineligible_payments',
            request,
        );

        if (isErr(response)) {
            return handleOperationFailure(response);
        }

        return Success();
    }
}
