import type * as APIType from '@embroker/shotwell-api/app';
import {
    API,
    CurrencyMarshaller,
    InsuranceApplication,
    TimeMarshaller,
} from '@embroker/shotwell-api/app';
import {
    InsuranceApplicationIneligibilityReasons,
    ShoppingGetHigherLimitRequestsResponse,
} from '@embroker/shotwell-api/app.spec';
import {
    InsuranceApplicationStatusCodeListItem,
    InsuranceApplicationStatusCodeListMap,
} from '@embroker/shotwell-api/enums';
import { isAPIError } from '@embroker/shotwell-api/errors';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { Money, USD } from '@embroker/shotwell/core/types/Money';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    isOK,
    Result,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { State } from '@embroker/shotwell/core/types/StateList';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import {
    addYears,
    isAfter,
    isSameDay,
    isValid,
    parseISO,
    startOfDay,
    startOfToday,
} from 'date-fns';
import { QuotingEngineESP, ShoppingCoverage } from '../../../../shopping/types/enums';
import { DocGenFailed, HigherLimitsApprovalNotNeeded } from '../../../errors';
import { QuoteExpiration } from '../../../types/QuoteExpiration';
import { QuoteIdMap, QuoteList } from '../../../types/QuoteList';
import { ESPQuote } from '../../entities/ESPQuote';
import { ConfigFetchFailed } from '../../errors';
import { ESPConfig } from '../../types/ESPConfig';
import {
    ESPCoverageQuoteOptionsItem,
    ESPDoEpliCoverageQuoteOptionsItem,
    ESPEoCyberCoverageQuoteOptionsItem,
    ESPFiduciaryCoverageQuoteOptionsItem,
    ESPQuoteOptions,
    ESPTechCyberCoverageQuoteOptionsItem,
} from '../../types/ESPQuoteOptions';
import {
    ESPCoverageRateItem,
    ESPDoEpliCoverageRateItem,
    ESPEoCyberCoverageRateItem,
    ESPFiduciaryCoverageRateItem,
    ESPRate,
    ESPTechCyberCoverageRateItem,
} from '../../types/ESPRate';
import { ESPUserInfo } from '../../types/ESPUserInfo';
import { QuoteOptionsExtensionRequestList } from '../../types/QuoteOptionsExtensionRequest';
import {
    ESPQuoteRepository,
    getESPQuestionnaireDataAndSubmissionDateResponse,
    PurchaseESPResponse,
} from './index';

interface toEspRateInput {
    apiEspRate: APIType.EspQuoteDetails;
    apiEspQuoteOptions: APIType.EspQuoteOptions;
    shoppingCoverageList: Immutable<string[]>;
    quotableShoppingCoverageList: Immutable<string[]>;
    existingLiabilities: Immutable<string[]>;
    taxes: Nullable<Money>;
    fees: Nullable<Money>;
}

export function toEspRate({
    apiEspRate,
    apiEspQuoteOptions,
    shoppingCoverageList,
    existingLiabilities,
    quotableShoppingCoverageList,
    taxes,
    fees,
}: toEspRateInput): ESPRate {
    const doPremium = CurrencyMarshaller.unmarshal(apiEspRate.directors_and_officers_premium);
    const epliPremium = CurrencyMarshaller.unmarshal(
        apiEspRate.employment_practices_liability_premium,
    );
    const fiduciaryPremium = CurrencyMarshaller.unmarshal(apiEspRate.fiduciary_premium);
    const eoPremium = CurrencyMarshaller.unmarshal(apiEspRate.errors_and_omissions_premium);
    const techPremium = CurrencyMarshaller.unmarshal(apiEspRate.technology_premium);
    const cyberPremium = CurrencyMarshaller.unmarshal(apiEspRate.cyber_premium);
    let totalCoverages = USD(0);
    const sumPremiums = Money.sum([
        doPremium,
        epliPremium,
        fiduciaryPremium,
        eoPremium,
        techPremium,
        cyberPremium,
    ]);
    if (isOK(sumPremiums)) {
        totalCoverages = sumPremiums.value;
    }
    return {
        effectiveDate: apiEspQuoteOptions.effective_period_start ?? new Date(Date.now()),
        coverages: buildEspCoverageRateItems({
            apiEspRate,
            apiEspQuoteOptions,
            shoppingCoverageList,
            existingLiabilities,
            quotableShoppingCoverageList,
        }),
        rates: {
            totalCoverages: totalCoverages,
            subTotal: CurrencyMarshaller.unmarshal(apiEspRate.subtotal),
            taxes: taxes ?? USD(0),
            feesAndSurcharges: fees ?? USD(0),
            policyFee: CurrencyMarshaller.unmarshal(apiEspRate.policy_fee),
            mwuaFee: apiEspRate.mwua_fee,
        },
    };
}

function enhancedFlagToCoverageLevel(enhancedFlag: Nullable<boolean>): 'plus' | 'standard' {
    switch (enhancedFlag) {
        case true:
            return 'plus';
        default:
            return 'standard';
    }
}

interface buildEspCoverageRateItemsInput {
    apiEspRate: APIType.EspQuoteDetails;
    apiEspQuoteOptions: APIType.EspQuoteOptions;
    shoppingCoverageList: Immutable<string[]>;
    quotableShoppingCoverageList: Immutable<string[]>;
    existingLiabilities: Immutable<string[]>;
}

function buildEspCoverageRateItems({
    apiEspRate,
    apiEspQuoteOptions,
    shoppingCoverageList,
    existingLiabilities,
    quotableShoppingCoverageList: quotableCoverageList,
}: buildEspCoverageRateItemsInput): Array<ESPCoverageRateItem> {
    const rateItems = makeRate(apiEspRate, apiEspQuoteOptions);
    const rateItemList = [
        {
            coverageRateItem: rateItems.do,
            isCoverageIncluded: isDirectorsAndOfficersIncluded,
        },
        {
            coverageRateItem: rateItems.epli,
            isCoverageIncluded: isEmploymentPracticesIncluded,
        },
        {
            coverageRateItem: rateItems.fiduciary,
            isCoverageIncluded: isFiduciaryIncluded,
        },
        {
            coverageRateItem: rateItems?.eoCyber,
            isCoverageIncluded: isEOCyberIncluded,
        },
        {
            coverageRateItem: rateItems?.techCyber,
            isCoverageIncluded: isEOCyberIncluded,
        },
    ];
    const selectedCoverages: ESPCoverageRateItem[] = [];
    const upSellCoverages: ESPCoverageRateItem[] = [];
    for (const item of rateItemList) {
        const { isCoverageIncluded: includesCoverage, coverageRateItem } = item;
        if (coverageRateItem !== undefined) {
            if (includesCoverage(quotableCoverageList)) {
                if (includesCoverage(shoppingCoverageList)) {
                    selectedCoverages.push(coverageRateItem);
                } else if (!includesCoverage(existingLiabilities)) {
                    upSellCoverages.push(coverageRateItem);
                }
            }
        }
    }

    return [...selectedCoverages, ...upSellCoverages];
}

interface quoteOptionItems {
    doOptionItem: ESPDoEpliCoverageQuoteOptionsItem;
    epliOptionItem: ESPDoEpliCoverageQuoteOptionsItem;
    fiduciaryOptionItem: ESPFiduciaryCoverageQuoteOptionsItem;
    eoOptionItem?: ESPEoCyberCoverageQuoteOptionsItem;
    techCyberOptionItem?: ESPTechCyberCoverageQuoteOptionsItem;
}

function makeQuoteOptions(apiEspQuoteOptions: APIType.EspQuoteOptions): quoteOptionItems {
    const doCoverageOptionsItem: ESPDoEpliCoverageQuoteOptionsItem = {
        coverageType: 'do',
        selected: apiEspQuoteOptions.directors_and_officers?.selected,
        limit: apiEspQuoteOptions.directors_and_officers?.limit,
        retention: apiEspQuoteOptions.directors_and_officers?.retention,
        level: enhancedFlagToCoverageLevel(apiEspQuoteOptions.directors_and_officers?.enhanced),
    };
    const epliCoverageOptionsItem: ESPDoEpliCoverageQuoteOptionsItem = {
        coverageType: 'epli',
        selected: apiEspQuoteOptions.employment_practices_liability?.selected,
        limit: apiEspQuoteOptions.employment_practices_liability?.limit,
        retention: apiEspQuoteOptions.employment_practices_liability?.retention,
        level: enhancedFlagToCoverageLevel(
            apiEspQuoteOptions.employment_practices_liability?.enhanced,
        ),
    };
    const fiduciaryCoverageOptionsItem: ESPFiduciaryCoverageQuoteOptionsItem = {
        coverageType: 'fiduciary',
        selected: apiEspQuoteOptions.fiduciary?.selected,
        limit: 1000000,
        retention: 0,
    };

    if (apiEspQuoteOptions.technology) {
        const techCyberCoverageOptionsItem: ESPTechCyberCoverageQuoteOptionsItem = {
            coverageType: 'techCyber',
            selected: apiEspQuoteOptions.technology?.selected,
            level: apiEspQuoteOptions.technology?.enhanced ? 'plus' : 'standard',
            techCoverageQuoteOptionsItem: {
                limit: apiEspQuoteOptions.technology?.limit,
                retention: apiEspQuoteOptions.technology?.retention,
            },
            cyberCoverageQuoteOptionsItem: {
                limit: apiEspQuoteOptions.cyber?.limit ?? 0,
                retention:
                    apiEspQuoteOptions.cyber?.retention ?? apiEspQuoteOptions.technology?.retention,
            },
        };

        return {
            doOptionItem: doCoverageOptionsItem,
            epliOptionItem: epliCoverageOptionsItem,
            fiduciaryOptionItem: fiduciaryCoverageOptionsItem,
            techCyberOptionItem: techCyberCoverageOptionsItem,
        };
    } else if (apiEspQuoteOptions.errors_and_omissions) {
        const eoCyberCoverageOptionsItem: ESPEoCyberCoverageQuoteOptionsItem = {
            coverageType: 'eoCyber',
            selected: apiEspQuoteOptions.errors_and_omissions?.selected ?? false,
            limit: apiEspQuoteOptions.errors_and_omissions?.limit ?? 0,
            retention: apiEspQuoteOptions.errors_and_omissions?.retention ?? 0,
            level: apiEspQuoteOptions.errors_and_omissions?.enhanced ? 'plus' : 'standard',
        };

        return {
            doOptionItem: doCoverageOptionsItem,
            epliOptionItem: epliCoverageOptionsItem,
            fiduciaryOptionItem: fiduciaryCoverageOptionsItem,
            eoOptionItem: eoCyberCoverageOptionsItem,
        };
    }

    return {
        doOptionItem: doCoverageOptionsItem,
        epliOptionItem: epliCoverageOptionsItem,
        fiduciaryOptionItem: fiduciaryCoverageOptionsItem,
    };
}

function makeRate(
    apiEspRate: APIType.EspQuoteDetails,
    apiEspQuoteOptions: APIType.EspQuoteOptions,
) {
    const optionsItems = makeQuoteOptions(apiEspQuoteOptions);

    const doRateItem: ESPDoEpliCoverageRateItem = {
        ...optionsItems.doOptionItem,
        premium: CurrencyMarshaller.unmarshal(apiEspRate.directors_and_officers_premium),
    };
    const epliRateItem: ESPDoEpliCoverageRateItem = {
        ...optionsItems.epliOptionItem,
        premium: CurrencyMarshaller.unmarshal(apiEspRate.employment_practices_liability_premium),
    };
    const fiduciaryRateItem: ESPFiduciaryCoverageRateItem = {
        ...optionsItems.fiduciaryOptionItem,
        premium: CurrencyMarshaller.unmarshal(apiEspRate.fiduciary_premium),
    };

    if (optionsItems.techCyberOptionItem !== undefined) {
        const techPremium = CurrencyMarshaller.unmarshal(apiEspRate.technology_premium);
        const cyberPremium = CurrencyMarshaller.unmarshal(apiEspRate.cyber_premium);
        const sumResult = Money.sum([techPremium, cyberPremium]);
        const isDiscounted =
            Number(apiEspRate.cyber_scorecard_adjustment) < 0 ||
            Number(apiEspRate.eo_scorecard_adjustment) < 0;
        const discountAmount =
            -1 *
            (Number(apiEspRate.cyber_scorecard_adjustment) +
                Number(apiEspRate.eo_scorecard_adjustment));
        let preDiscountPrice = 0;
        if (isOK(sumResult)) {
            preDiscountPrice = Money.toFloat(sumResult.value) + discountAmount;
        }

        const techCyberRateItem: ESPTechCyberCoverageRateItem = {
            ...optionsItems.techCyberOptionItem,
            premium: isOK(sumResult) ? sumResult.value : techPremium,
            preDiscountPrice: isDiscounted ? preDiscountPrice : undefined,
        };
        return {
            do: doRateItem,
            epli: epliRateItem,
            fiduciary: fiduciaryRateItem,
            techCyber: techCyberRateItem,
        };
    } else if (optionsItems.eoOptionItem !== undefined) {
        const eoCyberRateItem: ESPEoCyberCoverageRateItem = {
            ...optionsItems.eoOptionItem,
            premium: CurrencyMarshaller.unmarshal(apiEspRate.errors_and_omissions_premium),
        };
        return {
            do: doRateItem,
            epli: epliRateItem,
            fiduciary: fiduciaryRateItem,
            eoCyber: eoCyberRateItem,
        };
    }

    return {
        do: doRateItem,
        epli: epliRateItem,
        fiduciary: fiduciaryRateItem,
    };
}

function isDirectorsAndOfficersIncluded(coverageList: Immutable<Array<string>>) {
    return coverageList.includes('ShoppingCoverageCodeListDirectorsAndOfficers');
}

function isEmploymentPracticesIncluded(coverageList: Immutable<Array<string>>) {
    return coverageList.includes('ShoppingCoverageCodeListEmploymentPractices');
}

function isFiduciaryIncluded(coverageList: Immutable<Array<string>>) {
    return coverageList.includes('ShoppingCoverageCodeListFiduciary');
}

function isEOCyberIncluded(coverageList: Immutable<Array<string>>) {
    return (
        coverageList.includes('ShoppingCoverageCodeListProfessionalLiability') ||
        coverageList.includes('ShoppingCoverageCodeListCyber')
    );
}

function coverageLevelToEnhancedFlag(level: 'plus' | 'standard' | undefined): Nullable<boolean> {
    switch (level) {
        case 'plus':
            return true;
        case 'standard':
            return false;
        default:
            return null;
    }
}

function toApiEspRateOptions(espQuoteOptions: Immutable<ESPQuoteOptions>): APIType.EspQuoteOptions {
    const defaultDoCoverage: ESPDoEpliCoverageQuoteOptionsItem = {
        coverageType: 'do',
        selected: false,
        limit: 1000000, // $1,000,000
        retention: 10000, // $10,000
        level: 'plus',
    };
    const defaultEpliCoverage: ESPDoEpliCoverageQuoteOptionsItem = {
        coverageType: 'epli',
        selected: false,
        limit: 1000000, // $1,000,000
        retention: 10000, // $10,000
        level: 'plus',
    };
    const defaultFiduciaryCoverage: ESPFiduciaryCoverageQuoteOptionsItem = {
        coverageType: 'fiduciary',
        limit: 1000000, // $1,000,000
        retention: 0,
        selected: false,
    };
    const defaultTechCyberCoverage: ESPTechCyberCoverageQuoteOptionsItem = {
        coverageType: 'techCyber',
        selected: false,
        level: 'plus',
        techCoverageQuoteOptionsItem: {
            limit: 1000000,
            retention: 10000,
        },
        cyberCoverageQuoteOptionsItem: {
            limit: 1000000,
            retention: 10000,
        },
    };
    const doCoverage = (espQuoteOptions.coverages.find((item) => item.coverageType === 'do') ||
        defaultDoCoverage) as ESPDoEpliCoverageQuoteOptionsItem;
    const epliCoverage = (espQuoteOptions.coverages.find((item) => item.coverageType === 'epli') ||
        defaultEpliCoverage) as ESPDoEpliCoverageQuoteOptionsItem;
    const fiduciaryCoverage = (espQuoteOptions.coverages.find(
        (item) => item.coverageType === 'fiduciary',
    ) || defaultFiduciaryCoverage) as ESPFiduciaryCoverageQuoteOptionsItem;
    const techCyberCoverage = (espQuoteOptions.coverages.find(
        (item) => item.coverageType === 'techCyber',
    ) || defaultTechCyberCoverage) as ESPTechCyberCoverageQuoteOptionsItem;
    const isCyberSelected =
        techCyberCoverage.selected && techCyberCoverage.cyberCoverageQuoteOptionsItem.limit
            ? techCyberCoverage.cyberCoverageQuoteOptionsItem.limit > 0
            : false;
    return {
        directors_and_officers: {
            selected: doCoverage.selected,
            limit: doCoverage.limit,
            retention: doCoverage.retention,
            enhanced: coverageLevelToEnhancedFlag(doCoverage.level),
        },
        employment_practices_liability: {
            selected: epliCoverage.selected,
            limit: epliCoverage.limit,
            retention: epliCoverage.retention,
            enhanced: coverageLevelToEnhancedFlag(epliCoverage.level),
        },
        fiduciary: {
            selected: fiduciaryCoverage.selected,
        },
        technology: {
            selected: techCyberCoverage.selected,
            limit: techCyberCoverage.techCoverageQuoteOptionsItem.limit ?? 0,
            retention: techCyberCoverage.techCoverageQuoteOptionsItem.retention ?? 0,
            enhanced: coverageLevelToEnhancedFlag(techCyberCoverage.level),
        },
        cyber: {
            selected: isCyberSelected,
            limit: techCyberCoverage.cyberCoverageQuoteOptionsItem.limit ?? 0,
            retention: techCyberCoverage.cyberCoverageQuoteOptionsItem.retention ?? 0,
            enhanced: coverageLevelToEnhancedFlag(techCyberCoverage.level),
        },
        effective_period_start: espQuoteOptions.effectiveDate,
        effective_period_end: addYears(espQuoteOptions.effectiveDate, 1),
        partner_code: '',
        policy_fee: null,
        is_policy_fee_taxable: false,
    };
}

export function toESPQuoteOptions(
    espQuoteOptions: Immutable<APIType.EspQuoteOptions>,
): ESPQuoteOptions {
    const effectiveDate = espQuoteOptions.effective_period_start ?? new Date(Date.now());

    const optionItems = makeQuoteOptions(espQuoteOptions);

    const coverages: Immutable<Array<ESPCoverageQuoteOptionsItem>> = Object.values(optionItems);

    return {
        effectiveDate,
        coverages,
    };
}

function getUserInfoFromQuestionnaireData(questionnaire: QuestionnaireData): ESPUserInfo {
    return {
        fullName: `${questionnaire.first_name} ${questionnaire.last_name}`,
        title: questionnaire.title,
        usaState: questionnaire.state,
        company: questionnaire.company_name,
    };
}

function containsExistingCoverageReason(
    ineligibility_reasons: Immutable<InsuranceApplication['ineligibility_reasons']>,
): boolean {
    const existingCoverageReason = 'Submission referred for underwrite review of loss runs';
    return (
        ineligibility_reasons != null &&
        ineligibility_reasons.referral_reasons.includes(existingCoverageReason)
    );
}

// TODO: This exists in multiple places, move this to a common quote helper. (EM-36041)
function getRevenueFromQuestionnaireRevenueList(
    revenue_list: Array<RevenueListItem>,
): Result<number, InvalidArgument> {
    if (revenue_list === null || revenue_list === undefined) {
        return Failure(InvalidArgument({ argument: 'revenue_list', value: revenue_list }));
    }

    const DECEMBER = 11;
    const currentTime = new Date(Date.now());
    const currentYear = currentTime.getFullYear();
    const currentMonth = currentTime.getMonth();
    const referenceYear = (currentMonth === DECEMBER ? currentYear : currentYear - 1).toString();

    const revenueListItem = revenue_list.find((item) => item.fiscal_year === referenceYear);

    if (revenueListItem === null || revenueListItem === undefined) {
        return Failure(InvalidArgument({ argument: 'revenueListItem', value: revenueListItem }));
    }

    const revenueDefined = Number.isFinite(revenueListItem.gross_revenue_total);

    if (!revenueDefined) {
        return Failure(InvalidArgument({ argument: 'grossRevenue', value: revenueDefined }));
    }

    return Success(revenueListItem.gross_revenue_total);
}

export function mapFromGetHigherLimitRequestsApiResponse(
    higherLimitRequests: Immutable<ShoppingGetHigherLimitRequestsResponse>,
): Result<QuoteOptionsExtensionRequestList> {
    const higherLimitRequestsResult =
        higherLimitRequests.get_quote_options_extension_request_list.map((hlRequest) => {
            const hlCoverageMap = hlRequest.coverage_quote_options_extension_list.reduce(
                (acc: Record<string, number>, hlc) => {
                    acc[hlc.coverage_type] =
                        hlc.requested_limit.MultiValueLimitNameListSingleLimit ?? undefined;
                    return acc;
                },
                {},
            );

            return {
                requestedAt: hlRequest.requested_at,
                status: hlRequest.status,
                coverageQuoteOptionsExtensionMap: hlCoverageMap,
            };
        });
    return QuoteOptionsExtensionRequestList.create(higherLimitRequestsResult);
}

interface QuestionnaireData {
    revenue_list: Array<RevenueListItem>;
    first_name: string;
    last_name: string;
    title: string;
    state: State;
    company_name: string;
    raised_venture_funding: boolean;
}

interface RevenueListItem {
    gross_revenue_total: number;
    fiscal_year: string;
}

@injectable()
export class APIESPQuoteRepository implements ESPQuoteRepository {
    async getLastQuote(
        applicationId: UUID,
    ): AsyncResult<ESPQuote, InvalidArgument | OperationFailed | ConfigFetchFailed> {
        const applicationResponse = await API.request('shopping/application', {
            id: applicationId,
        });
        if (isErr(applicationResponse)) {
            return handleOperationFailure(applicationResponse);
        }
        const application = applicationResponse.value;
        const questionnaireData = application.questionnaire_data;
        const apiQuote = QuoteList.getLastQuote(application.quote_list);
        if (apiQuote == null) {
            return Failure(OperationFailed({ message: 'Empty quote list' }));
        }
        const apiEspRate = apiQuote.details.esp;
        const apiEspQuoteOptions = apiQuote.options.esp;

        if (questionnaireData == null) {
            return Failure(
                InvalidArgument({ argument: 'questionnaire_data', value: questionnaireData }),
            );
        }
        if (apiEspRate === undefined) {
            return Failure(InvalidArgument({ argument: 'esp_quote', value: apiEspRate }));
        }
        if (apiEspQuoteOptions === undefined) {
            return Failure(
                InvalidArgument({ argument: 'esp_quote_options', value: apiEspQuoteOptions }),
            );
        }
        let questionnaireDataParsed: QuestionnaireData;
        try {
            questionnaireDataParsed = JSON.parse(questionnaireData);
        } catch (e) {
            return Failure(OperationFailed({ message: 'Failed parsing questionnaire data' }));
        }
        const userInfo = getUserInfoFromQuestionnaireData(questionnaireDataParsed);
        const revenue = getRevenueFromQuestionnaireRevenueList(
            questionnaireDataParsed.revenue_list,
        );
        if (isErr(revenue)) {
            return revenue;
        }
        const shoppingCoverageList = application.shopping_coverage_list;
        if (!shoppingCoverageList || shoppingCoverageList.length === 0) {
            return Failure(
                InvalidArgument({
                    argument: 'Empty shopping coverage list',
                    value: shoppingCoverageList,
                }),
            );
        }
        const quotableShoppingCoverageList = application.quotable_shopping_coverage_list;
        if (!quotableShoppingCoverageList || quotableShoppingCoverageList.length === 0) {
            return Failure(
                InvalidArgument({
                    argument: 'Empty quotable coverage list',
                    value: shoppingCoverageList,
                }),
            );
        }
        const existingLiabilitiesResult = await this.getExistingLiabilitiesList();
        if (isErr(existingLiabilitiesResult)) {
            return handleOperationFailure(existingLiabilitiesResult);
        }
        const existingLiabilities = existingLiabilitiesResult.value;

        const appStatus = application.status as InsuranceApplicationStatusCodeListItem;
        const isReferredBecauseExistingCoverage =
            containsExistingCoverageReason(application.ineligibility_reasons) &&
            InsuranceApplicationStatusCodeListMap[appStatus] === 'Referred';

        const configResponse = await this.getESPConfig();
        if (isErr(configResponse)) {
            return Failure(ConfigFetchFailed());
        }

        const mapReferralReasonsToStatus = function (
            appStatus: string,
            referralReasons: Nullable<InsuranceApplicationIneligibilityReasons>,
        ) {
            switch (appStatus) {
                case 'Purchased':
                    return 'accepted';
                case 'Referred':
                    if (
                        referralReasons !== null &&
                        referralReasons.referral_reasons.length == 1 &&
                        referralReasons.referral_reasons[0] ==
                            'Submission referred for underwrite review of loss runs'
                    ) {
                        return 'draft';
                    } else {
                        return 'referred';
                    }
                default:
                    return 'draft';
            }
        };
        const resultQuote = await ESPQuote.create({
            isIndication: apiQuote.is_indication,
            id: apiQuote.id,
            quoteNumber: apiQuote.quote_number,
            applicationId: applicationId,
            initialShoppingCoverages: (shoppingCoverageList ?? []) as Array<ShoppingCoverage>,
            fileKey: apiQuote.file_key ?? undefined,
            totalPremium: CurrencyMarshaller.unmarshal(apiEspRate.total_premium),
            annualTechnologyFee: apiQuote.annual_technology_fee,
            totalPayable: apiQuote.total_payable,
            status: mapReferralReasonsToStatus(
                InsuranceApplicationStatusCodeListMap[appStatus],
                application.ineligibility_reasons as Nullable<InsuranceApplicationIneligibilityReasons>,
            ),
            options: {
                effectiveDate: apiEspQuoteOptions.effective_period_start ?? new Date(Date.now()),
            },
            details: toEspRate({
                apiEspRate,
                apiEspQuoteOptions,
                shoppingCoverageList,
                quotableShoppingCoverageList,
                existingLiabilities,
                taxes: apiQuote.taxes,
                fees: apiQuote.fees,
            }),
            quoteInfo: {
                userInfo: userInfo,
                revenue: revenue.value,
                isFunded: questionnaireDataParsed.raised_venture_funding || false,
                sociusEndorsementPremium: apiEspRate.socius_endorsement_premium,
                isReferredBecauseExistingCoverage: isReferredBecauseExistingCoverage,
                submittedAt:
                    application.submitted_at !== null
                        ? parseISO(application.submitted_at)
                        : undefined,
                newInsurerDocumentsReleaseDate: configResponse.value.newInsurerDocumentsReleaseDate,
                fiduciaryDocumentsReleaseDate: configResponse.value.fiduciaryDocumentsReleaseDate,
                espDocumentsAfterEoCyberSplitReleaseDate:
                    configResponse.value.espDocumentsAfterEoCyberSplitReleaseDate,
            },
            daysToExpire: QuoteExpiration.getDaysLeftUntilExpiration({
                quotingEngine: application.quoting_engine || undefined,
                applicationStatus: application.status,
                validUntil: application.valid_until,
                quoteEffectiveDate:
                    apiEspQuoteOptions.effective_period_start ?? new Date(Date.now()),
                today: startOfToday(),
                isBroker: application.brokerage_id !== null,
            }),
            referralReasons: application.ineligibility_reasons?.referral_reasons,
        });

        if (isErr(resultQuote)) {
            return handleOperationFailure(resultQuote);
        }
        return Success(resultQuote.value);
    }

    async getESPQuestionnaireDataAndSubmissionDate(
        applicationId: UUID,
    ): AsyncResult<
        getESPQuestionnaireDataAndSubmissionDateResponse,
        InvalidArgument | OperationFailed
    > {
        const applicationResponse = await API.request('shopping/application', {
            id: applicationId,
        });
        if (isErr(applicationResponse)) {
            return handleOperationFailure(applicationResponse);
        }
        const questionnaireData = applicationResponse.value.questionnaire_data;
        if (questionnaireData == null) {
            return Failure(
                InvalidArgument({ argument: 'questionnaire_data', value: questionnaireData }),
            );
        }

        const submittedAt = applicationResponse.value.submitted_at;
        if (submittedAt === null) {
            return Failure(InvalidArgument({ argument: 'submitted_at', value: submittedAt }));
        }

        const submissionDate = TimeMarshaller.unmarshal(submittedAt);
        if (isNaN(submissionDate.getTime())) {
            return Failure(InvalidArgument({ argument: 'submitted_at', value: submittedAt }));
        }
        return Success({ questionnaireData, submissionDate });
    }

    async getExistingLiabilitiesList(): AsyncResult<string[]> {
        const isEligibleEspPolicy = (policy: Immutable<APIType.Policy>, serverTime: Date) => {
            return (
                policy.bor_status === 'b_o_r' &&
                policy.solartis_policy_number !== '' &&
                policy.view_mode === 'PolicyViewStatusCodeListPublished' &&
                (isSameDay(policy.effective_period_end, serverTime) ||
                    isAfter(policy.effective_period_end, serverTime)) &&
                policy.cancellation_date == null
            );
        };
        const getPolicyLiabilityList = (policyData: Immutable<APIType.Policy>): string[] => {
            if (
                !policyData ||
                !policyData.lob_other_liability ||
                !policyData.lob_other_liability.liability_section ||
                !policyData.lob_other_liability.liability_section.liability_list
            ) {
                return [];
            }

            return policyData.lob_other_liability.liability_section.liability_list.map(
                (liabilityDto) => liabilityDto.type_code,
            );
        };
        const userPoliciesResponse = await API.request('policy/user_policies_full');
        if (isErr(userPoliciesResponse)) {
            return handleOperationFailure(userPoliciesResponse);
        }
        const serverTimeResponse = await API.request('global/get_server_time');
        if (isErr(serverTimeResponse)) {
            return handleOperationFailure(serverTimeResponse);
        }
        const serverDay = startOfDay(serverTimeResponse.value.time);
        if (!isValid(serverDay)) {
            return Failure(
                OperationFailed({
                    message: 'Invalid server time',
                }),
            );
        }
        const espPolicies = userPoliciesResponse.value.filter((policy) =>
            isEligibleEspPolicy(policy, serverDay),
        );
        const espLiabilitiesList = espPolicies
            .flatMap((espPolicy) => getPolicyLiabilityList(espPolicy)) // deduplicate
            .reduce((acc: string[], cur: string) => {
                if (!acc.includes(cur)) {
                    acc.push(cur);
                }
                return acc;
            }, []);
        return Success(espLiabilitiesList);
    }

    async requote(
        applicationId: UUID,
        espQuoteOptions: Immutable<ESPQuoteOptions>,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed> {
        const createQuoteResponse = await API.request('shopping/create_quote_task', {
            application_id: applicationId,
            quote_options: {
                esp: toApiEspRateOptions(espQuoteOptions),
            },
        });
        if (isErr(createQuoteResponse)) {
            return handleOperationFailure(createQuoteResponse);
        }
        return Success(createQuoteResponse.value.task_id);
    }

    async purchaseESP(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<PurchaseESPResponse, InvalidArgument | OperationFailed> {
        const quoteIdMap: QuoteIdMap = { [QuotingEngineESP]: quoteId };
        const purchaseResponse = await API.request('shopping/purchase', {
            id: applicationId,
            quote_id_map: quoteIdMap,
        });
        if (isErr(purchaseResponse)) {
            return handleOperationFailure(purchaseResponse);
        }

        const { policyId: policyId, policy_issuance_task_id: policyDoneTaskId } =
            purchaseResponse.value;

        return Success({
            policyId: policyId,
            policyDoneTaskId: policyDoneTaskId,
        });
    }

    async getESPPurchasedCoveragesList(
        applicationId: UUID,
    ): AsyncResult<string[], InvalidArgument | OperationFailed> {
        const applicationResponse = await API.request('shopping/application', {
            id: applicationId,
        });
        if (isErr(applicationResponse)) {
            return handleOperationFailure(applicationResponse);
        }

        const ESPShoppingCoverageCodeList = [
            'ShoppingCoverageCodeListCyber',
            'ShoppingCoverageCodeListDirectorsAndOfficers',
            'ShoppingCoverageCodeListEmploymentPractices',
            'ShoppingCoverageCodeListFiduciary',
        ] as const;

        const ESPShoppingCoveragePrettyPrint = {
            ShoppingCoverageCodeListCyber: 'Cyber Liability and Data Breach',
            ShoppingCoverageCodeListDirectorsAndOfficers: 'Directors and Officers',
            ShoppingCoverageCodeListEmploymentPractices: 'Employment Practices Liability',
            ShoppingCoverageCodeListFiduciary: 'Fiduciary',
        };

        type ESPShoppingCoverageCodeList = typeof ESPShoppingCoverageCodeList;
        type ESPShoppingCoverageCodeListItem = ESPShoppingCoverageCodeList[number];

        const shoppingCoverageList = applicationResponse.value.shopping_coverage_list;

        if (shoppingCoverageList == null) {
            return Failure(OperationFailed({ message: 'Coverage list is empty' }));
        }

        const purchasedCoverages = shoppingCoverageList.map((shoppingCoverage) => {
            return ESPShoppingCoveragePrettyPrint[
                shoppingCoverage as ESPShoppingCoverageCodeListItem
            ];
        });

        return Success<string[]>(purchasedCoverages);
    }

    async getESPConfig(): AsyncResult<ESPConfig, InvalidArgument | ConfigFetchFailed> {
        const getConfigResponse = await API.request('global/get_config');

        if (isErr(getConfigResponse)) {
            return Failure(ConfigFetchFailed());
        }

        return ESPConfig.create({
            dynamicESPRetentionEnabled: getConfigResponse.value.dynamic_esp_retention_enabled,
            dynamicESPRetentionStartDate:
                getConfigResponse.value.dynamic_esp_retention_start_date ?? undefined,
            dynamicESPRetentionUpdateDate:
                getConfigResponse.value.dynamic_esp_retention_update_date ?? undefined,
            newInsurerDocumentsReleaseDate:
                getConfigResponse.value.esp_new_insurer_documents_release_date ?? undefined,
            fiduciaryDocumentsReleaseDate:
                getConfigResponse.value.fiduciary_documents_release_date ?? undefined,
            pasRaterEnabled: getConfigResponse.value.esp_pas_rater_enabled,
            espDocumentsAfterEoCyberSplitReleaseDate:
                getConfigResponse.value.esp_documents_after_eo_cyber_split_release_date ??
                undefined,
        });
    }

    public async requestHigherLimits(
        applicationId: UUID,
        isSubmit: boolean,
    ): AsyncResult<void, HigherLimitsApprovalNotNeeded | OperationFailed> {
        const result = await API.request('shopping/request_higher_limits', {
            application_id: applicationId,
            is_submit: isSubmit,
        });

        if (isErr(result)) {
            const error = result.errors[0];
            if (isAPIError(error) && error.details.name === 'operation_not_needed') {
                return Failure(HigherLimitsApprovalNotNeeded());
            }

            return Failure(
                OperationFailed({ message: 'Higher limit request failed.', errors: result.errors }),
            );
        }

        return Success();
    }

    public async createQuoteSummaryAsyncTask(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed | DocGenFailed> {
        const createQuoteSummaryResult = await API.request('shopping/create_quote_summary_task', {
            application_id: applicationId,
            quote_id: quoteId,
        });
        if (isErr(createQuoteSummaryResult)) {
            return Failure(DocGenFailed(createQuoteSummaryResult));
        }

        return Success(createQuoteSummaryResult.value.task_id);
    }

    async getHigherLimitRequests(
        applicationId: UUID,
    ): AsyncResult<Immutable<QuoteOptionsExtensionRequestList>, OperationFailed | InvalidArgument> {
        const response = await API.request('shopping/get_higher_limit_requests', {
            application_id: applicationId,
        });

        if (isErr(response)) {
            return handleOperationFailure(response);
        }

        const requestList = mapFromGetHigherLimitRequestsApiResponse(response.value);

        if (isErr(requestList)) {
            return Failure(
                OperationFailed({
                    message: 'Failed to load from get higher limit request api',
                    errors: requestList.errors,
                }),
            );
        }

        return Success(requestList.value);
    }
}
