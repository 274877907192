import { InvoiceTable, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React, { Fragment } from 'react';
import { BundleQuote } from '@app/bundle/entities/BundleQuote';
import { BundleQuoteComparisonContentRenderer } from './BundleQuoteComparisonContentRenderer';

interface BundleQuoteComparisonComponentProps {
    bundleQuote: BundleQuote;
}

export function BundleQuoteComparisonView({ bundleQuote }: BundleQuoteComparisonComponentProps) {
    return (
        <StackLayout>
            <InvoiceTable>
                {bundleQuote.getEligibleCoverages().map((coverage) => {
                    const content = BundleQuoteComparisonContentRenderer(coverage);
                    return (
                        <Fragment key={coverage.type}>
                            <Text style="overline">{coverage.coverageMetadata.title}</Text>
                            {content}
                        </Fragment>
                    );
                })}
            </InvoiceTable>
        </StackLayout>
    );
}
