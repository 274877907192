import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { OperationFailed } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { Questionnaire } from '../../entities/Questionnaire';

export interface QuestionnaireRepository {
    get(questionnaire: Immutable<EntityProps<Questionnaire>>): AsyncResult<string, OperationFailed>;
}

export const QuestionnaireRepository = Symbol('QuestionnaireRepository');
