import { Money } from '@embroker/shotwell/core/types/Money';
import { defineValidator } from '@embroker/shotwell/core/validation/schema';
import { QuoteDetails } from '../../../../quote/entities/Quote';

export interface LawCyberQuoteDetails extends QuoteDetails {
    readonly taxes?: Money;
    readonly basePremium: Money;
    readonly specialSurcharge: Money;
}

export const LawCyberQuoteDetails = {
    ...defineValidator<LawCyberQuoteDetails>({
        taxes: Money.schema.optional(),
        basePremium: Money.schema.required(),
        specialSurcharge: Money.schema.required(),
    }),
    create(lawCyberQuoteDetails: LawCyberQuoteDetails) {
        return LawCyberQuoteDetails.validate(lawCyberQuoteDetails);
    },
};
