import { inject, injectable } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Aborted, InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { ProgressEvent } from '@embroker/shotwell/core/networking';
import { Immutable } from '@embroker/shotwell/core/types';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { Document } from '../entities/Document';
import { DocumentRepository } from '../repositories/DocumentRepository';

/**
 * Request data for UploadFiles use case
 */
export interface UploadFilesRequest {
    /**
     * A list of files to be uploaded
     */
    files: File[];
    /**
     * Callback used to provide information on the total progress of the upload of all files
     * @param uploaded is the total amount of data uploaded for all files in bytes
     * @param totalSize is the total amount of data to be uploaded for all files in bytes
     */
    onFileUploadProgress(uploaded: number, totalSize: number): void;
    /**
     * Signal used to abort file save operation
     */
    abortSignal?: AbortSignal;
}

/**
 * Response data for UploadFiles use case
 * @param uploadedFiles is a list of uploaded files containing their s3 file key
 */
export interface UploadFilesResponse {
    uploadedFiles: Immutable<EntityProps<Document>>[];
}

/**
 * UploadFiles use case uploads files to the file repository one by one
 */
export interface UploadFiles extends UseCase {
    execute(
        request: UploadFilesRequest,
    ): AsyncResult<UploadFilesResponse, InvalidArgument | OperationFailed | Aborted>;
}

@injectable()
export class UploadFilesUseCase extends UseCase implements UploadFiles {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Documents/UploadFiles');

    /**
     * Constructor for UploadFiles use case class instance.
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param documentRepository Document repository used to store files.
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(DocumentRepository) private documentRepository: DocumentRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes UploadFiles use case
     * @param files is the list of files to be saved to the document repository
     * @param handleFileUploadProgress is the callback used to track the progress of the upload of all files
     */
    async execute({
        files,
        onFileUploadProgress,
        abortSignal,
    }: UploadFilesRequest): AsyncResult<
        UploadFilesResponse,
        InvalidArgument | OperationFailed | Aborted
    > {
        const documents: Immutable<EntityProps<Document>>[] = [];

        let totalSize = 0;
        for (const file of files) {
            totalSize += file.size;
        }

        let uploadFromCompletedFiles = 0;

        for (const file of files) {
            const documentResult = await Document.create({
                name: file.name,
                size: file.size,
                type: file.type,
                fileKey: null,
            });

            if (isErr(documentResult)) {
                return handleOperationFailure(documentResult);
            }

            documentResult.value.setFile(file);

            const documentSaveResult = await this.documentRepository.save({
                file: documentResult.value,
                onFileSaveProgress: (event: ProgressEvent) => {
                    if (
                        onFileUploadProgress !== undefined &&
                        typeof onFileUploadProgress === 'function' &&
                        event.lengthComputable
                    ) {
                        onFileUploadProgress(uploadFromCompletedFiles + event.loaded, totalSize);
                    }
                },
                abortSignal,
            });

            if (isErr(documentSaveResult)) {
                return documentSaveResult;
            }

            uploadFromCompletedFiles += file.size;
            documents.push(documentSaveResult.value.toDTO());
        }

        return Success({
            uploadedFiles: documents,
        });
    }
}

export const UploadFiles: UseCaseClass<UploadFiles> = UploadFilesUseCase;
