import { Immutable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { QuotingEngine, QuotingEngineList } from '../../shopping/types/enums';
import { IneligibilityReasons } from '../../shopping/types/IneligibilityReasons';
import { ProductType, ProductTypeList } from './ProductType';

const BrokerRenewalStatusList = [
    'quotes_ready',
    'not_submitted',
    'declined',
    'referred',
    'expired',
] as const;

export type BrokerRenewalStatus = (typeof BrokerRenewalStatusList)[number];

export interface BrokerRenewalRecord {
    readonly applicationId: UUID;
    readonly managingBroker?: string;
    readonly organizationId: UUID;
    readonly organizationName: string;
    readonly premium?: Money;
    readonly dateSubmitted?: Date;
    readonly status: BrokerRenewalStatus;
    readonly isPristine: boolean;
    readonly hasQuotes: boolean;
    readonly quotingEngine?: QuotingEngine;
    readonly ineligibilityReasons?: Immutable<IneligibilityReasons>;
    readonly previousPolicyPremium?: Money;
    readonly previousPolicyExpirationDate?: Date;
    readonly previousPolicyProductType?: ProductType;
    readonly renewedPolicyIdList: Immutable<UUID[]>;
    readonly isSubmittedExternally?: boolean;
    readonly isStreamline?: boolean;
}

export const BrokerRenewalRecord = {
    ...defineValidator<BrokerRenewalRecord>({
        applicationId: UUID.schema,
        managingBroker: Joi.string().optional(),
        organizationId: UUID.schema,
        organizationName: Joi.string(),
        premium: Money.schema.optional(),
        dateSubmitted: Joi.date().optional(),
        status: Joi.string().valid(...BrokerRenewalStatusList),
        isPristine: Joi.boolean(),
        hasQuotes: Joi.boolean(),
        quotingEngine: Joi.string()
            .valid(...QuotingEngineList)
            .optional(),
        ineligibilityReasons: IneligibilityReasons.schema.optional(),
        previousPolicyPremium: Money.schema.optional(),
        previousPolicyExpirationDate: Joi.date().optional(),
        previousPolicyProductType: Joi.string()
            .valid(...ProductTypeList)
            .optional(),
        renewedPolicyIdList: Joi.array().required().items(UUID.schema.optional()),
        isSubmittedExternally: Joi.boolean().optional(),
        isStreamline: Joi.boolean().optional(),
    }),
    create(provider: BrokerRenewalRecord) {
        return BrokerRenewalRecord.validate(provider);
    },
};
