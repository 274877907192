import { UploadField } from '@app/view/components/FormEngine/components/UploadField';
import React from 'react';
import { ComplexFormFieldViewProps, getFormFieldProps } from '../../types/ComplexFieldTypes';
import { Form } from '@embroker/ui-toolkit/v2';

function assertFileUploadType(value: unknown): value is string[] {
    if (!Array.isArray(value)) {
        return false;
    }
    return value.every((item) => typeof item === 'string');
}

export function FileUploadField({
    questionProps,
    inputFieldProps,
    onComplexFieldChange,
}: ComplexFormFieldViewProps) {
    const { key } = questionProps;

    const formFieldProps = {
        ...getFormFieldProps(questionProps),
        messages: inputFieldProps.messages,
    };

    const { value } = inputFieldProps.inputProps;
    const inputValue = assertFileUploadType(value) ? value : undefined;

    const handleChange = (value: number) => {
        if (assertFileUploadType(value) && value.length > 0) {
            onComplexFieldChange(key, value);
        } else {
            onComplexFieldChange(key, undefined);
        }
    };

    return (
        <Form.Field {...formFieldProps}>
            <UploadField value={inputValue || []} onChange={handleChange} />
        </Form.Field>
    );
}
