import { State } from '@embroker/shotwell/core/types/StateList';
import { QuestionnaireData } from '../../../types/BundleQuoteQuestionnaireData';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface AreaOfPracticeItemValue {
    area: string;
}

interface LocationWithLargestNumber {
    state: State;
}

export interface LawCyberQuestionnaireData extends QuestionnaireData {
    areas_of_practice: { areas_of_practice_rows: AreaOfPracticeItemValue[] };
    location_with_largest_number: LocationWithLargestNumber;
}

export const LawCyberQuestionnaireData = {
    ...defineValidator<LawCyberQuestionnaireData>(
        QuestionnaireData.schema.keys({
            areas_of_practice: Joi.object(),
            location_with_largest_number: Joi.object(),
        }),
    ),
    create(questionnaireData: LawCyberQuestionnaireData) {
        return LawCyberQuestionnaireData.validate(questionnaireData);
    },
};
