import { URI } from '@embroker/shotwell/core/types/URI';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { WizardForm } from '../../../view/hooks/useWizardForm';
import { CoverageCatalog } from '../../CoverageCatalog';
import { BundleQuote } from '../../entities/BundleQuote';
import { BundleCoverageAppStatusEnum, BundleCoverageType } from '../../types/BundleQuoteCoverage';
import { DocumentType } from '../../types/BundleQuoteDocument';
import { DocumentsItem } from './BundleQuoteLandingPage';
import { DownloadDocumentButton } from './DownloadDocumentButton';
import { generateDocumentDisplayName } from '@app/bundle/coverageDefinition/coverageDefinition';

export interface BuildBundleDocumentListProps {
    bundleQuote: BundleQuote;
    documents: { url: URI; documentType: DocumentType; coverageType: BundleCoverageType }[];
    trigger: WizardForm<OpaqueForm<any>>['trigger'];
    isDirty: boolean;
    value: any;
    reset: () => void;
}

export function buildBundleDocumentList({
    bundleQuote,
    documents,
    trigger,
    isDirty,
    value,
    reset,
}: BuildBundleDocumentListProps): DocumentsItem[] {
    const coverages = bundleQuote.coverageList;

    const BundleAppDocument: DocumentsItem = {
        component: DownloadDocumentButton,
        displayName: generateDocumentDisplayName({
            documentType:
                coverages.length > 1
                    ? DocumentType.BundleApplication
                    : DocumentType.ApplicationAttestation,
        }),
        handleTrigger: () => {
            reset();
            trigger('downloadBundleApp');
        },
    };

    const documentList: DocumentsItem[] = [BundleAppDocument];

    if (
        coverages.length == 1 &&
        coverages.filter((coverage) => CoverageCatalog.overrideBundleAppDocument(coverage.type))
            .length != 0
    ) {
        documentList.pop();
    }

    const selectedCoverages = bundleQuote.getSelectedCoverages();
    return selectedCoverages.reduce((agg, curr) => {
        const coverageType = curr.type;
        if (curr.status !== BundleCoverageAppStatusEnum.QuotesReady) {
            return [...agg];
        }
        const coverageDocuments = CoverageCatalog.getProductDocuments(coverageType, {
            bundleQuote,
            documents,
            trigger,
            isDirty,
            value,
            reset,
        });
        if (coverageDocuments) {
            return [...agg, ...coverageDocuments];
        }
        return [...agg];
    }, documentList);
}
