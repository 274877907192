import { inject, injectable } from '@embroker/shotwell/core/di';
import { Aborted, InvalidArgument, OperationFailed, Timeout } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { GetDocumentUrl } from '../../../documents/useCases/GetDocumentUrl';
import { ESPQuoteOptions } from '../types/ESPQuoteOptions';
import { RequoteESP } from './RequoteESP';
import { DocGenFailed } from '../../errors';
import { TasksRepository } from '../../../tasks/repositories';
import { ESPQuoteRepository } from '../repositories/ESPQuoteRepository';
import { ConfigFetchFailed } from '../errors';

export interface DownloadESPQuoteDocumentRequest {
    applicationId: UUID;
    quoteId: UUID;
    espQuoteOptions: ESPQuoteOptions;
    abortSignal: AbortSignal;
    fileKey?: string;
}

interface createQuoteDocumentInput {
    applicationId: UUID;
    quoteId: UUID;
    abortSignal: AbortSignal;
}

export interface DownloadESPQuoteDocument extends UseCase {
    execute(
        request: DownloadESPQuoteDocumentRequest,
    ): AsyncResult<
        void,
        InvalidArgument | OperationFailed | Timeout | Aborted | DocGenFailed | ConfigFetchFailed
    >;
}

@injectable()
class DownloadESPQuoteDocumentUseCase extends UseCase implements DownloadESPQuoteDocument {
    public static type = Symbol('ESPQuote/DownloadESPQuoteDocument');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(GetDocumentUrl.type) private getDocumentURL: GetDocumentUrl,
        @inject(RequoteESP.type) private requoteESP: RequoteESP,
        @inject(ESPQuoteRepository) private espQuoteRepository: ESPQuoteRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
        quoteId,
        espQuoteOptions,
        abortSignal,
        fileKey,
    }: DownloadESPQuoteDocumentRequest): AsyncResult<
        void,
        InvalidArgument | OperationFailed | Timeout | Aborted | DocGenFailed | ConfigFetchFailed
    > {
        const espQuoteOptionsResult = ESPQuoteOptions.create(espQuoteOptions);
        if (isErr(espQuoteOptionsResult)) {
            return Failure(DocGenFailed({ errors: espQuoteOptionsResult.errors }));
        }

        const configResponse = await this.espQuoteRepository.getESPConfig();

        if (isErr(configResponse)) {
            return Failure(DocGenFailed({ errors: configResponse.errors }));
        }

        if (!fileKey) {
            if (configResponse.value.pasRaterEnabled) {
                const quoteDocument = await this.createQuoteDocument({
                    applicationId,
                    quoteId,
                    abortSignal,
                });
                if (isErr(quoteDocument)) {
                    return Failure(DocGenFailed({ errors: quoteDocument.errors }));
                }
                fileKey = quoteDocument.value;
            } else {
                const requoteResult = await this.requoteESP.execute({
                    applicationId: applicationId,
                    espQuoteOptions: espQuoteOptions,
                    abortSignal: abortSignal,
                });
                if (isErr(requoteResult)) {
                    return Failure(DocGenFailed({ errors: requoteResult.errors }));
                }
                fileKey = requoteResult.value.fileKey;
            }
        }

        const getDocumentUrlResult = await this.getDocumentURL.execute({
            fileKey: fileKey ?? '',
        });
        if (isErr(getDocumentUrlResult)) {
            return Failure(DocGenFailed({ errors: getDocumentUrlResult.errors }));
        }
        window.open(getDocumentUrlResult.value.downloadUrl);
        return Success();
    }

    private async createQuoteDocument({
        applicationId,
        quoteId,
        abortSignal,
    }: createQuoteDocumentInput): AsyncResult<
        string,
        InvalidArgument | OperationFailed | Timeout | Aborted | DocGenFailed | ConfigFetchFailed
    > {
        const createAsyncTaskResult = await this.espQuoteRepository.createQuoteSummaryAsyncTask(
            applicationId,
            quoteId,
        );
        if (isErr(createAsyncTaskResult)) {
            return createAsyncTaskResult;
        }

        const pollForTaskStatusResult = await this.tasksRepository.pollForTaskStatus(
            createAsyncTaskResult.value,
            abortSignal,
        );
        if (isErr(pollForTaskStatusResult)) {
            return Failure(DocGenFailed({ errors: pollForTaskStatusResult.errors }));
        }

        const lastQuoteResult = await this.espQuoteRepository.getLastQuote(applicationId);
        if (isErr(lastQuoteResult)) {
            return lastQuoteResult;
        }
        if (lastQuoteResult.value.id !== quoteId) {
            return Failure(OperationFailed({ message: 'Not the latest esp quote' }));
        }

        const fileKey = lastQuoteResult.value.fileKey;
        if (fileKey === undefined || fileKey === '') {
            return Failure(OperationFailed({ message: 'No generated Quote Summary was found.' }));
        }

        return Success(fileKey);
    }
}

export const DownloadESPQuoteDocument: UseCaseClass<DownloadESPQuoteDocument> =
    DownloadESPQuoteDocumentUseCase;
