import { API } from '@embroker/shotwell-api/app';
import type * as APITypes from '@embroker/shotwell-api/app';
import { OperationFailed } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Result,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { State } from '@embroker/shotwell/core/types/StateList';
import { injectable } from '@embroker/shotwell/core/di';
import { WCClassCode } from '../../types/WCClassCode';
import { entitiesMap } from './entitiesMap';
import { WCDataRepository } from './index';
import { DefaultClassCodes, NAICSCode, OfficerInclusion } from './types';

@injectable()
export class APIWCDataRepository implements WCDataRepository {
    private dataLoaded = false;
    private allowedClassCodes: Immutable<WCClassCode[]> = [];
    private defaultClassCodes?: Immutable<DefaultClassCodes>;
    private officerInclusion?: Immutable<OfficerInclusion>;

    getAllowedClassCodes(state: State): Immutable<WCClassCode[]> {
        return this.allowedClassCodes.filter((classCode) => {
            if (classCode.states.length === 0) {
                return true;
            }

            const isStateInStateList = classCode.states.includes(state);
            if (classCode.include) {
                return isStateInStateList;
            }

            return !isStateInStateList;
        });
    }

    getDefaultClassCodes(naicsCode: NAICSCode, state: State): Immutable<string[]> {
        if (!this.defaultClassCodes) {
            return [];
        }

        if (state && naicsCode) {
            return (
                (this.defaultClassCodes[state] && this.defaultClassCodes[state][naicsCode]) || []
            );
        }

        return [];
    }

    setEmployeeDataToSessionStorage(employeeData: Record<string, { $: {}[] }>): void {
        const classCodesId = window.sessionStorage.getItem('activeCodesId');
        try {
            const empDataStr = JSON.stringify(employeeData);
            // The following comment is copied in shopping 2020 rewrite
            //TODO: ~TECH DEBT~ PERFORM MERGE WITH POLICY UPLOAD DATA
            window.sessionStorage.setItem(`wcPayrollRows-${classCodesId}`, empDataStr);
        } catch (err) {
            window.sessionStorage.setItem(`wcPayrollRows-${classCodesId}`, '{}');
        }
    }

    setActiveCodesIdToSessionStorage(): void {
        //Tech debt - copy paste from ShoppingService,
        //consider rewrite whole logic regarding activeCodesId and storing it in SessionStorage
        const classCodesId = Math.random().toString(36).substring(2, 15);
        cleanUpSessionStorage();
        window.sessionStorage.setItem('activeCodesId', classCodesId);
    }

    getOfficerInclusion(state: State, entity: string): boolean {
        if (!this.officerInclusion) {
            return false;
        }

        const mappedEntity = entitiesMap[entity];

        return mappedEntity && this.officerInclusion[state]
            ? this.officerInclusion[state][mappedEntity] || false
            : false;
    }

    async loadData(): AsyncResult {
        if (this.dataLoaded) {
            return Success();
        }

        const classCodesResult = await API.request('shopping/wc_class_codes_list', {});
        if (isErr(classCodesResult)) {
            return handleOperationFailure(classCodesResult);
        }
        const defaultClassCodesResult = await API.request(
            'shopping/wc_default_class_codes_table',
            {},
        );
        if (isErr(defaultClassCodesResult)) {
            return handleOperationFailure(defaultClassCodesResult);
        }
        const officerInclusionResult = await API.request('shopping/wc_officer_inclusion', {});
        if (isErr(officerInclusionResult)) {
            return handleOperationFailure(officerInclusionResult);
        }
        let apiClassCodes, apiDefaultClassCodes, apiOfficerInclusion: any;

        try {
            apiClassCodes = JSON.parse(
                classCodesResult.value as APITypes.ShoppingWcClassCodesListResponse,
            );
        } catch (e) {
            return Failure(OperationFailed({ message: 'Failed parsing wc class codes list' }));
        }

        try {
            apiDefaultClassCodes = JSON.parse(
                defaultClassCodesResult.value as APITypes.ShoppingWcDefaultClassCodesTableResponse,
            );
        } catch (e) {
            return Failure(
                OperationFailed({ message: 'Failed parsing wc default class codes table' }),
            );
        }

        try {
            apiOfficerInclusion = JSON.parse(
                officerInclusionResult.value as APITypes.ShoppingWcOfficerInclusionResponse,
            );
        } catch (e) {
            return Failure(OperationFailed({ message: 'Failed parsing wc officer inclusion' }));
        }

        const allowedClassCodes = this.toAllowedClassCodes(apiClassCodes);
        if (isErr(allowedClassCodes)) {
            return Failure(OperationFailed({ message: 'loading allowed class codes failed' }));
        }
        this.allowedClassCodes = allowedClassCodes.value;

        const defaultClassCodes = this.toDefaultClassCodes(apiDefaultClassCodes);
        if (isErr(defaultClassCodes)) {
            return Failure(OperationFailed({ message: 'loading default class codes failed' }));
        }
        this.defaultClassCodes = defaultClassCodes.value;

        const officerInclusion = this.toOfficerInclusion(apiOfficerInclusion);
        if (isErr(officerInclusion)) {
            return Failure(OperationFailed({ message: 'loading officer inclusion failed' }));
        }
        this.officerInclusion = officerInclusion.value;

        this.dataLoaded = true;

        return Success();
    }

    toAllowedClassCodes(apiClassCodes: any): Result<Immutable<WCClassCode[]>> {
        const result: Immutable<WCClassCode>[] = [];
        for (const classCode of apiClassCodes) {
            const creationResult = WCClassCode.create({
                code: classCode.Code,
                description: classCode.Description,
                tooltip: classCode.Tooltip,
                states: classCode.States === null ? [] : classCode.States,
                include: classCode.Include,
            });
            if (isErr(creationResult)) {
                return handleOperationFailure(creationResult);
            }
            result.push(creationResult.value);
        }
        return Success(result);
    }

    toDefaultClassCodes(apiDefaultClassCodes: any): Result<Immutable<DefaultClassCodes>> {
        return DefaultClassCodes.create(apiDefaultClassCodes);
    }

    toOfficerInclusion(apiOfficerInclusion: any): Result<Immutable<OfficerInclusion>> {
        return OfficerInclusion.create(apiOfficerInclusion);
    }
}

//Tech debt - copy paste from ShoppingService,
//consider rewrite whole logic regarding activeCodesId and storing it in SessionStorage
function cleanUpSessionStorage() {
    const classCodesId = window.sessionStorage.getItem('activeCodesId');
    window.sessionStorage.removeItem('activeCodesId');
    window.sessionStorage.removeItem(`wcPayrollRows-${classCodesId}`);
}
