import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { IndicativePremium } from '../../entities/IndicativePremium';
import { BundleAppTypeNotSupportedError } from '../../errors';

export const supportedAppTypes = [
    'AppTypeCodeListLawBundleCyber',
    'AppTypeCodeListCyberCowbell',
    'AppTypeCodeListEverestLawyersProfessionalLiability',
    'AppTypeCodeListBOPChubb',
    'AppTypeCodeListWCChubb',
];

export interface IndicativePremiumRepository {
    getIndicativePremiumList(
        applicationId: UUID,
    ): AsyncResult<
        IndicativePremium[],
        InvalidArgument | OperationFailed | BundleAppTypeNotSupportedError
    >;
}

export const IndicativePremiumRepository = Symbol('IndicativePremiumRepository');
