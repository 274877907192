import React, { forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
    SidebarLayout,
    WizardLayout,
    CenterLayout,
    StickyLayout,
    BoxLayout,
} from '@embroker/ui-toolkit/v2';
import { FormSideNavigation } from './FormSideNavigation';
import {
    DataDrivenForm,
    DataDrivenFormRefrence,
} from '../../../view/components/DataDrivenForm/components/DataDrivenForm';
import { FormQuestionDefinition } from '../../../view/components/DataDrivenForm/hooks/useDataDrivenForm';
import {
    QuestionerPageDefinition,
    extractFormPages,
} from '@app/shoppingQuestioner/types/QuestionerPageDefinition';
import { useCurrentRoute } from 'react-navi';
import { URI } from '@embroker/shotwell/core/types/URI';

const calculateProgress = (
    formPages: QuestionerPageDefinition[],
    activePage: string | undefined,
): number => {
    const pageIndex = formPages.findIndex((page) => page.name === activePage);
    return ((pageIndex + 1) / formPages.length) * 100;
};
export interface QuestionerRendererRefrence {
    setUnansweredFormValues: (formValues: { [key: string]: unknown }) => void;
}
export interface QuestionerRendererProps {
    pages?: QuestionerPageDefinition[];
    questions: FormQuestionDefinition[];
    onSaveAndExit: () => void;
    onPageComplete: (formValue: { [key: string]: unknown }) => void;
    onFormSubmit: (formValue: { [key: string]: unknown }) => void;
    onFirstIncompletePageChange: (pageName: string) => void;
}

export const QuestionerRenderer = forwardRef(function MyComponen(
    {
        questions,
        pages = [],
        onSaveAndExit,
        onPageComplete,
        onFormSubmit,
        onFirstIncompletePageChange,
    }: QuestionerRendererProps,
    ref: Ref<QuestionerRendererRefrence>,
) {
    const currentRoute = useCurrentRoute();

    const [formPages] = useState(extractFormPages(pages));
    const [hiddenPages, setHidenPages] = useState<string[]>([]);

    const dataDrivenFormRef = useRef<DataDrivenFormRefrence>(null);
    const activePage = dataDrivenFormRef.current?.activePage;

    const previousPageRef = useRef(activePage);

    const [activePageIndex, setActivePageIndex] = useState<number>();
    useEffect(() => {
        if (!activePage) {
            return;
        }

        const {
            data: { url, page },
            url: { search },
        } = currentRoute;

        if (typeof url !== 'string') {
            return;
        }

        const urlParts = url.split('/');
        if (page !== undefined && page.length > 0) {
            urlParts.pop();
        }

        // TODO: https://embroker.atlassian.net/browse/EM-45526
        // This is not an ideal patern, but we do want to update the url to reflect the current step
        // The main reason for this logic is to keep the url in sync with the form state
        const currentUrl = URI.join('/', ...urlParts, activePage) + search;

        history.pushState(null, '', currentUrl);
    }, [activePageIndex, activePage, currentRoute]);

    useEffect(() => {
        if (activePage) {
            const activePageIndex = formPages.findIndex((page) => page.name === activePage);
            const prevPageIndex = formPages.findIndex(
                (page) => page.name === previousPageRef.current,
            );

            if (activePageIndex > prevPageIndex) {
                onFirstIncompletePageChange(activePage);
                previousPageRef.current = activePage;
            }
        }
    }, [activePage, formPages, onFirstIncompletePageChange]);

    useImperativeHandle(ref, () => ({
        setUnansweredFormValues: (formValues: { [key: string]: unknown }) =>
            dataDrivenFormRef.current?.setUnansweredFormValues(formValues),
    }));

    const handleClickNavigationItem = (pageName: string) => {
        dataDrivenFormRef.current?.setActiveStep(pageName);
    };

    const handleHiddenPagesChange = (hiddenPages: string[]) => {
        setHidenPages(hiddenPages);
    };

    const progress = calculateProgress(formPages, activePage);
    const formSideNavigationPages: QuestionerPageDefinition[] = pages
        .map((page) => {
            if (page.subPages) {
                const subPages = page.subPages?.filter(
                    (subPage) => !hiddenPages.includes(subPage.name),
                );
                return { ...page, subPages };
            }
            return page;
        })
        .filter((page) => Boolean(page.subPages?.length));

    return (
        <WizardLayout
            progress={progress}
            title="Application Questionnaire"
            onDismiss={onSaveAndExit}
            dismissAppearance="save-and-exit"
        >
            <SidebarLayout appearance="compact">
                <StickyLayout gap="64">
                    <FormSideNavigation
                        activePage={activePage}
                        formPages={formSideNavigationPages}
                        onClickNavigationItem={handleClickNavigationItem}
                    />
                </StickyLayout>
                <CenterLayout>
                    {/* BoxLayout is needed to align side navigation with the page header */}
                    <BoxLayout gap="8" />
                    <DataDrivenForm
                        formQuestions={questions}
                        formPages={formPages}
                        onPageComplete={onPageComplete}
                        onFormSubmit={onFormSubmit}
                        onPageIndexChange={setActivePageIndex}
                        onHiddenPagesChange={handleHiddenPagesChange}
                        ref={dataDrivenFormRef}
                    />
                </CenterLayout>
            </SidebarLayout>
        </WizardLayout>
    );
});
