import { ShoppingSignSignatureRequestRequest } from '@embroker/shotwell-api/app';
import { inject } from '@embroker/shotwell/core/di';
import { Aborted, InvalidArgument, OperationFailed, Timeout } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { TasksRepository } from '../../tasks/repositories';
import {
    SignatureDocumentAlreadySigned,
    SignatureDocumentNotAllowed,
    SignatureDocumentNotFound,
    SignatureDocumentNotSigned,
} from '../errors';
import { BrokerRepository } from '../repositories';

export interface SignSignatureRequestUseCaseResponse {
    documentUrl?: string;
}

export interface SignSignatureRequest extends UseCase {
    execute(
        request: ShoppingSignSignatureRequestRequest,
        abortSignal: AbortSignal,
    ): AsyncResult<
        SignSignatureRequestUseCaseResponse,
        | OperationFailed
        | InvalidArgument
        | SignatureDocumentNotFound
        | SignatureDocumentNotAllowed
        | SignatureDocumentNotSigned
        | SignatureDocumentAlreadySigned
        | Aborted
        | Timeout
    >;
}

class SignSignatureRequestUseCase extends UseCase implements SignSignatureRequest {
    public static type = Symbol('Broker/SignSignatureRequest');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BrokerRepository) private brokerRepository: BrokerRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: ShoppingSignSignatureRequestRequest,
        abortSignal: AbortSignal,
    ): AsyncResult<
        SignSignatureRequestUseCaseResponse,
        | OperationFailed
        | InvalidArgument
        | SignatureDocumentNotFound
        | SignatureDocumentNotAllowed
        | SignatureDocumentNotSigned
        | SignatureDocumentAlreadySigned
        | Aborted
        | Timeout
    > {
        const createTaskResult = await this.brokerRepository.signSignatureRequest(request);
        if (isErr(createTaskResult)) {
            return createTaskResult;
        }

        const documentTaskStatusResult =
            await this.tasksRepository.pollForSignatureDocumentSigningTaskStatus(
                createTaskResult.value.signatureToken,
                abortSignal,
            );
        if (isErr(documentTaskStatusResult)) {
            return documentTaskStatusResult;
        }

        return Success<SignSignatureRequestUseCaseResponse>({
            documentUrl: documentTaskStatusResult.value,
        });
    }
}

export const SignSignatureRequest: UseCaseClass<SignSignatureRequest> = SignSignatureRequestUseCase;
