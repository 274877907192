import { Aborted, InvalidArgument, OperationFailed, Timeout } from '@embroker/shotwell/core/Error';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, Success, isErr } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { DocumentRequested } from '../../documents/entities/Document';
import { Quote } from '../../quote/entities/Quote';
import { DocGenFailed } from '../../quote/errors';
import { TasksRepository } from '../../tasks/repositories';
import { CoverageCatalog } from '../CoverageCatalog';
import { GetDocumentDownloadMetadataResponse } from '../coverageDefinition/coverageDefinition';
import { DocumentCreationBundleTaskError } from '../errors';
import { BundleQuoteRepository } from '../repositories';
import { BundleCoverageType } from '../types/BundleQuoteCoverage';
import { DocumentType } from '../types/BundleQuoteDocument';
import { JSONSerdes } from '@embroker/shotwell/core/encoding';

export interface GetDocumentUrlRequest {
    bundleAppId: UUID;
    quote: Quote;
    documentType: DocumentType;
    abortSignal: AbortSignal;
    bundleCoverageType: BundleCoverageType;
}

export interface GetDocumentUrlResponse {
    fileKey: string;
    fileUrl: URI;
}
export interface GetDocumentUrl extends UseCase {
    execute(
        request: GetDocumentUrlRequest,
    ): AsyncResult<
        GetDocumentUrlResponse,
        | InvalidArgument
        | OperationFailed
        | Aborted
        | Timeout
        | DocGenFailed
        | DocumentCreationBundleTaskError
    >;
}

@injectable()
class GetDocumentUrlUseCase extends UseCase implements GetDocumentUrl {
    public static type = Symbol('BundleQuote/CreateBundleDocument');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BundleQuoteRepository) private bundleQuoteRepository: BundleQuoteRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        bundleAppId,
        quote,
        documentType,
        abortSignal,
        bundleCoverageType,
    }: GetDocumentUrlRequest): AsyncResult<
        GetDocumentUrlResponse,
        InvalidArgument | OperationFailed | Aborted | Timeout | DocGenFailed
    > {
        const createDocumentResult = await this.createDocument(
            quote.applicationId,
            quote.id,
            documentType,
            abortSignal,
        );
        if (isErr(createDocumentResult)) {
            return Failure(DocGenFailed(createDocumentResult));
        }

        if (documentType == DocumentType.ApplicationAttestationWithPapyrus) {
            const deserializeDocumentResult = JSONSerdes.deserialize(createDocumentResult.value);
            if (isErr(deserializeDocumentResult)) {
                return Failure(DocGenFailed(deserializeDocumentResult));
            }
            const documentResult = deserializeDocumentResult.value as CreateDocumentResult;
            return Success<GetDocumentUrlResponse>({
                fileKey: documentResult.fileKey,
                fileUrl: URI.build(documentResult.downloadURL),
            });
        }

        const getDocumentUrlResult = await this.getDocumentDownloadMetadata(
            bundleCoverageType,
            bundleAppId,
            documentType,
        );

        if (isErr(getDocumentUrlResult)) {
            return getDocumentUrlResult;
        }

        await publishEvents(documentType, bundleCoverageType, this.eventBus);

        return Success<GetDocumentUrlResponse>({
            fileKey: getDocumentUrlResult.value.fileKey,
            fileUrl: URI.build(getDocumentUrlResult.value.downloadUrl),
        });
    }

    private async createDocument(
        applicationId: UUID,
        quoteId: UUID,
        documentType: DocumentType,
        abortSignal: AbortSignal,
    ): AsyncResult<
        string,
        InvalidArgument | OperationFailed | Aborted | Timeout | DocumentCreationBundleTaskError
    > {
        const documentTaskResult = await this.bundleQuoteRepository.createDocumentAsyncTask(
            applicationId,
            quoteId,
            documentType,
        );
        if (isErr(documentTaskResult)) {
            return documentTaskResult;
        }
        const pollForTaskStatusResult = await this.tasksRepository.pollForTaskStatus(
            documentTaskResult.value,
            abortSignal,
        );
        if (isErr(pollForTaskStatusResult)) {
            return pollForTaskStatusResult;
        }
        if (pollForTaskStatusResult.value === '') {
            return Failure(
                OperationFailed({ message: 'Polling document creation returned empty file key' }),
            );
        }

        return Success(pollForTaskStatusResult.value);
    }

    private async getDocumentDownloadMetadata(
        bundleCoverageType: BundleCoverageType,
        bundleAppId: UUID,
        documentType: DocumentType,
    ): AsyncResult<GetDocumentDownloadMetadataResponse, OperationFailed | InvalidArgument> {
        const getLastBundleQuoteResult = await this.bundleQuoteRepository.getLastBundleQuote(
            bundleAppId,
        );
        if (isErr(getLastBundleQuoteResult)) {
            return getLastBundleQuoteResult;
        }
        const bundleQuote = getLastBundleQuoteResult.value;
        const coverage = bundleQuote.coverageList.find(
            (coverage) => coverage.type == bundleCoverageType,
        );
        if (!coverage || !coverage.quote) {
            return Failure(
                InvalidArgument({ argument: 'bundleCoverage', value: bundleCoverageType }),
            );
        }
        return CoverageCatalog.getDocumentDownloadMetadata(
            documentType,
            bundleCoverageType,
            coverage.quote,
        );
    }
}

export async function publishEvents(
    documentType: DocumentType,
    coverageType: BundleCoverageType,
    eventBus: DomainEventBus,
) {
    const documentRequestedType = coverageType + '_' + documentType;
    const documentRequestedEvent: DocumentRequested = {
        origin: 'Document',
        name: 'QuotePageDocumentRequested',
        documentType: documentRequestedType,
        id: UUID.create(),
        createdAt: new Date(Date.now()),
    };
    await eventBus.publish(documentRequestedEvent);
}

type CreateDocumentResult = {
    fileKey: string;
    downloadURL: string;
};

export const GetDocumentUrl: UseCaseClass<GetDocumentUrl> = GetDocumentUrlUseCase;
