import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { isOK, isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Spinner, TextButton, useModal } from '@embroker/ui-toolkit/v2';
import React, { useEffect, useMemo, useState } from 'react';
import { Application } from '../../../shopping/entities/Application';
import * as enums from '../../../shopping/types/enums';
import { PrintStartedApplication } from '../../../shopping/useCases/PrintStartedApplication';
import { getApplicationFlags } from '../../../shopping/view/components/applicationFlags';
import { DeleteApplicationModal } from '../../../shopping/view/components/DeleteApplicationModal';
import { PrintApplicationModal } from '../../../shopping/view/components/PrintApplicationModal';
import { PrintBinderModal } from '../../../shopping/view/components/PrintBinderModal';
import { PrintBinder } from '../../../shopping/useCases/PrintBinder';
import { GetApplicant } from '../../../shopping/useCases/GetApplicant';
import { ViewQuestionnaireModal } from '../../../shopping/view/components/ViewQuestionnaireModal';
import { hasRole, Session } from '../../../userOrg/entities/Session';
import { ApplicationAction } from './ApplicationAction';
import {
    AppTypeData,
    InsuranceApplicationCard,
    InsuranceApplicationCardProps,
} from './InsuranceApplicationCard';
import { navigateToErrorPage } from '../../../view/errors';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { getAppTypeCodeFromBundleDetails, getPolicyDetails } from './policyDetails';
import { CoverageCardIcon } from '../../../shopping/view/components/CoverageCardIcon.view';
import { Applicant } from '../../../shopping/entities/Applicant';
import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';

interface InsuranceApplicationCardListProps {
    applicationList: Immutable<Array<EntityProps<Application>>>;
    activeSession: Immutable<EntityProps<Session>>;
    canViewLplQuotes: boolean;
    canViewPCoMLQuotes: boolean;
    canViewCnaBopQuotes: boolean;
    canViewCrimeQuotes: boolean;
    canViewCyberQuotes: boolean;
    canViewExcessQuotes: boolean;
    maxApplications: number;
}

export function InsuranceApplicationCardList({
    applicationList,
    activeSession,
    canViewLplQuotes,
    canViewPCoMLQuotes,
    canViewCnaBopQuotes,
    canViewCrimeQuotes,
    canViewCyberQuotes,
    canViewExcessQuotes,
    maxApplications,
}: InsuranceApplicationCardListProps) {
    const [deleteId, setDeleteId] = useState<UUID>();
    const deleteModal = useModal();
    const [viewedApplicationId, setViewApplicationId] = useState<UUID>();
    const viewQuestionnaireModal = useModal();
    const printAppModal = useModal();
    const printBinderModal = useModal();
    const [isPrintingApp, setIsPrintingApp] = useState<boolean>(false);
    const [isPrintingBinder, setIsPrintingBinder] = useState<boolean>(false);
    const [applicant, setApplicant] = useState<Nullable<EntityProps<Applicant>>>(null);
    const abortController = useMemo(() => {
        return new AbortController();
    }, []);

    const { navigate } = useNavigation();
    const { result: getApplicantResult } = useUseCase(GetApplicant);

    useEffect(() => {
        if (getApplicantResult && isOK(getApplicantResult)) {
            setApplicant(getApplicantResult.value.applicant as Applicant);
        }
    }, [getApplicantResult]);

    useEffect(() => {
        if (isPrintingApp && abortController !== null) {
            printAppModal.show();
        } else {
            printAppModal.hide();
            abortController?.abort();
        }
    }, [isPrintingApp, printAppModal, abortController]);

    useEffect(() => {
        if (isPrintingBinder && abortController !== null) {
            printBinderModal.show();
        } else {
            printBinderModal.hide();
            abortController?.abort();
        }
    }, [isPrintingBinder, printBinderModal, abortController]);

    const dataList = toDataList({
        applicationList,
        applicant,
        canViewLplQuotes,
        canViewPCoMLQuotes,
        canViewCnaBopQuotes,
        canViewCrimeQuotes,
        canViewCyberQuotes,
        canViewExcessQuotes,
        activeSession,
        printStartedApplication,
        onPrintBinder,
        showDeleteApplicationModal,
        showViewQuestionnaireModal,
    });

    function showViewQuestionnaireModal(application: Application): void {
        setViewApplicationId(application.id);
        viewQuestionnaireModal.show();
    }

    function showDeleteApplicationModal(applicationId: UUID): void {
        setDeleteId(applicationId);
        deleteModal.show();
    }

    async function printStartedApplication(applicationId: UUID) {
        setIsPrintingApp(true);
        const printApplicationResult = await execute(PrintStartedApplication, {
            applicationId,
            abortSignal: abortController.signal,
        });
        setIsPrintingApp(false);
        if (isOK(printApplicationResult)) {
            const pdfTab = window.open(
                printApplicationResult.value.documentUrl,
                '_blank',
                'noopener, noreferrer',
            );
            pdfTab?.focus();
        }
    }

    async function onPrintBinder(applicationId: UUID) {
        setIsPrintingBinder(true);
        const printBinderResult = await execute(PrintBinder, {
            applicationId,
        });
        setIsPrintingBinder(false);

        if (isErr(printBinderResult)) {
            return navigateToErrorPage(navigate, printBinderResult.errors);
        }

        const pdfTab = window.open(printBinderResult.value, '_blank', 'noopener, noreferrer');
        pdfTab?.focus();
    }

    if (!getApplicantResult) {
        return <Spinner />;
    }

    return (
        <React.Fragment>
            {dataList.slice(0, maxApplications).map((data, index) => {
                return (
                    <InsuranceApplicationCard
                        key={index}
                        status={data.status}
                        appType={data.appType}
                        action={data.action}
                        menuItems={data.menuItems}
                        estimatedTimeToComplete={data.estimatedTimeToComplete}
                        policyExpirationDate={data.policyExpirationDate}
                        daysToQuoteExpiration={data.daysToQuoteExpiration}
                        renewedPolicyIdList={data.renewedPolicyIdList}
                    />
                );
            })}
            <ViewQuestionnaireModal
                applicationId={viewedApplicationId}
                modal={viewQuestionnaireModal}
            />
            <DeleteApplicationModal applicationId={deleteId} modal={deleteModal} />
            <PrintApplicationModal modal={printAppModal} />
            <PrintBinderModal modal={printBinderModal} />
        </React.Fragment>
    );
}

interface ToDataListInput {
    applicationList: Immutable<Array<EntityProps<Application>>>;
    applicant: Nullable<EntityProps<Applicant>>;
    canViewLplQuotes: boolean;
    canViewPCoMLQuotes: boolean;
    canViewCnaBopQuotes: boolean;
    canViewCrimeQuotes: boolean;
    canViewCyberQuotes: boolean;
    canViewExcessQuotes: boolean;
    activeSession: Immutable<EntityProps<Session>>;
    printStartedApplication(applicationId: UUID): void;
    onPrintBinder(applicationId: UUID): void;
    showDeleteApplicationModal(applicationId: UUID): void;
    showViewQuestionnaireModal(application: EntityProps<Application>): void;
}

function toDataList({
    applicationList,
    applicant,
    canViewLplQuotes,
    canViewPCoMLQuotes,
    canViewCnaBopQuotes,
    canViewCrimeQuotes,
    canViewCyberQuotes,
    canViewExcessQuotes,
    activeSession,
    printStartedApplication,
    onPrintBinder,
    showDeleteApplicationModal,
    showViewQuestionnaireModal,
}: ToDataListInput): InsuranceApplicationCardProps[] {
    const dataList: InsuranceApplicationCardProps[] = [];

    for (const item of applicationList) {
        const application = item as EntityProps<Application>;
        const flags = getApplicationFlags(application, {
            canViewLplQuotes,
            canViewPCoMLQuotes,
            canViewCnaBopQuotes,
            canViewCrimeQuotes,
            canViewCyberQuotes,
            canViewExcessQuotes,
        });

        let appQuestionnaireNaicsCode: string | undefined;
        try {
            const appQuestionnaireData = JSON.parse(application.questionnaireData as string) || {};
            appQuestionnaireNaicsCode = appQuestionnaireData.naics_code as string | undefined;
        } catch (e) {
            container.get<Logger>(Log).error(e);
        }
        if (application.appType) {
            const appTypeData = () => {
                const { bundleDetails } = application;

                const bundleAppTypeCode = getAppTypeCodeFromBundleDetails(bundleDetails);
                const appTypeCode = bundleAppTypeCode || application.appType;
                const { title, description, estimatedTime, icon } = getPolicyDetails(
                    appTypeCode,
                    appQuestionnaireNaicsCode,
                    applicant,
                );
                return {
                    name: title,
                    description,
                    icon: <CoverageCardIcon name={icon} />,
                    estimatedTime,
                    appType: appTypeCode,
                } as AppTypeData;
            };

            const menuItems: JSX.Element[] = [];
            if (
                application.status === enums.QuestionnaireInProgress &&
                activeSession.userId !== null &&
                hasRole(activeSession, 'admin')
            ) {
                menuItems.push(
                    <TextButton key="1" onClick={() => printStartedApplication(application.id)}>
                        Download
                    </TextButton>,
                );
            }
            if (application.isDeletable) {
                menuItems.push(
                    <TextButton key="2" onClick={() => showDeleteApplicationModal(application.id)}>
                        Delete
                    </TextButton>,
                );
            }

            const cardData: InsuranceApplicationCardProps = {
                status: application.status,
                appType: appTypeData(),
                action: (
                    <ApplicationAction
                        application={application}
                        flags={flags}
                        onViewQuestionnaire={showViewQuestionnaireModal}
                        onPrintBinder={onPrintBinder}
                    />
                ),
                menuItems: menuItems,
                estimatedTimeToComplete: appTypeData().estimatedTime,
                policyExpirationDate: application.policyExpirationDate,
                daysToQuoteExpiration: application.daysToQuoteExpiration,
                renewedPolicyIdList: application.renewedPolicyIdList,
            };

            dataList.push(cardData);
        }
    }
    return dataList;
}
