import * as APIType from '@embroker/shotwell-api/app';
import { API } from '@embroker/shotwell-api/app';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult, Failure, isErr, Result, Success } from '@embroker/shotwell/core/types/Result';
import { AppTypeCode } from '../../../shopping/types/enums';
import { SKU } from '../../types/SKU';
import {
    CalculateSKUFromShareablePoliciesRequest,
    CalculateSKURequest,
    ShareablePolicy,
    SKURepository,
} from './index';

@injectable()
export class APISKURepository implements SKURepository {
    async calculateSKU(
        input: CalculateSKURequest,
    ): AsyncResult<SKU, InvalidArgument | OperationFailed> {
        const requestData = mapToCalculateRequestData(input);
        if (isErr(requestData)) {
            return requestData;
        }

        const calculateSKUResponse = await API.request(
            'analytics/calculate_sku',
            requestData.value,
        );

        if (isErr(calculateSKUResponse)) {
            return Failure(OperationFailed(calculateSKUResponse));
        }

        const { sku }: any = calculateSKUResponse.value;

        const validateResult = SKU.validate(sku);
        if (isErr(validateResult)) {
            return validateResult;
        }

        return Success(validateResult.value);
    }

    async calculateSKUFromAppTypes(
        appTypeList: readonly AppTypeCode[],
    ): AsyncResult<SKU, InvalidArgument | OperationFailed> {
        const calculateResponse = await API.request('analytics/calculate_sku_from_app_types', {
            app_type_list: appTypeList,
        });

        if (isErr(calculateResponse)) {
            return Failure(OperationFailed(calculateResponse));
        }

        const { sku }: any = calculateResponse.value;

        const validateResult = SKU.validate(sku);
        if (isErr(validateResult)) {
            return validateResult;
        }

        return Success(validateResult.value);
    }

    async calculateSKUFromShareablePolicies(
        input: CalculateSKUFromShareablePoliciesRequest,
    ): AsyncResult<SKU, InvalidArgument | OperationFailed> {
        const calculateResponse = await API.request(
            'analytics/calculate_sku_from_shareable_policies',
            { shareable_policy_list: mapToApiShareablePolicyList(input.shareablePolicyList) },
        );

        if (isErr(calculateResponse)) {
            return Failure(OperationFailed(calculateResponse));
        }

        const { sku }: any = calculateResponse.value;

        const validateResult = SKU.validate(sku);
        if (isErr(validateResult)) {
            return validateResult;
        }

        return Success(validateResult.value);
    }
}

function mapToApiShareablePolicyList(
    shareablePolicyList: ShareablePolicy[],
): APIType.ShareablePolicy[] {
    return shareablePolicyList.map((policy) => {
        return {
            policy_id: policy.id,
            structural_component_list: policy.structuralComponentList,
        };
    });
}

function mapToCalculateRequestData(
    input: CalculateSKURequest,
): Result<APIType.AnalyticsCalculateSkuRequest, InvalidArgument> {
    switch (input.event) {
        case 'app_created':
        case 'questionnaire_changed':
            return Success({
                application_id: input.applicationId,
                app_type: input.appType,
                event: input.event,
                questionnaire_data: input.questionnaireData,
                shopping_coverage_list: input.shoppingCoverageList,
            });
        default:
            return Success({
                application_id: input.applicationId,
                event: input.event,
            });
    }
}
