import { URI } from '@embroker/shotwell/core/types/URI';
import {
    Button,
    ColumnLayout,
    Modal,
    ModalActions,
    ModalState,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { DocumentPublicationType } from '../types';
import { execute } from '@embroker/shotwell/core/UseCase';
import { PublishDigitalEndorsementIssuedEvent } from '../useCases/PublishDigitalEndorsementIssuedEventUseCase';
import { UUID } from '@embroker/shotwell/core/types/UUID';

interface DocumentModalProps {
    readonly modal: ModalActions & ModalState;
    readonly documentPublicationType: DocumentPublicationType;
    readonly policyId: UUID;
    readonly endorsementType: string;
    readonly fileUrl?: URI;
    onDismiss(): void;
}

export function DocumentModal({
    modal,
    documentPublicationType,
    policyId,
    endorsementType,
    fileUrl,
    onDismiss,
}: DocumentModalProps) {
    const closeOnIssue = async () => {
        if (documentPublicationType === DocumentPublicationType.Issue) {
            modal.hide();
            onDismiss();
            await execute(PublishDigitalEndorsementIssuedEvent, {
                policyId: policyId,
                endorsementType: endorsementType,
            });
        }
    };
    return (
        <Modal {...modal} size="small" dismissable={false}>
            <StackLayout gap="32">
                <StackLayout gap="24">
                    {documentPublicationType === DocumentPublicationType.Preview ? (
                        <Text style="heading 3" data-e2e="document-ready-to-download">
                            Your preview endorsement document is ready to download.
                        </Text>
                    ) : (
                        <Text style="heading 3" data-e2e="document-ready-to-download">
                            Success! The endorsement was issued and the policy is updated.
                        </Text>
                    )}

                    <Text data-e2e="download-document-by-clicking">
                        {' '}
                        Download the document by clicking on the Download button.{' '}
                    </Text>
                </StackLayout>
                <ColumnLayout gap="32">
                    <Button
                        as="a"
                        href={fileUrl}
                        target="_blank"
                        rel="noopener"
                        onClick={closeOnIssue}
                        data-e2e="download-document-modal-button"
                    >
                        Download
                    </Button>
                    {documentPublicationType === DocumentPublicationType.Issue && (
                        <TextButton onClick={closeOnIssue}>Back to Company</TextButton>
                    )}
                    {documentPublicationType === DocumentPublicationType.Preview && (
                        <TextButton onClick={() => modal.hide()} data-e2e="back-to-endorsement">
                            Back to Endorsement
                        </TextButton>
                    )}
                </ColumnLayout>
            </StackLayout>
        </Modal>
    );
}
