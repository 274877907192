import { Immutable } from '@embroker/shotwell/core/types';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { Card, Spinner, StackLayout, StatusMessage, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { WizardForm } from '../../../../../view/hooks/useWizardForm';
import { ESPCoverageRateItem } from '../../../types/ESPRate';
import { InsuranceApplicationRestriction } from '../../../types/InsuranceApplicationRestriction';
import {
    ESPQuoteOptionsFormData,
    mapToCoverageRestrictionCoverageType,
} from '../ESPQuoteLandingPage';
import { getCoverageRestriction } from './ESPCoveragesPage';
import { ESPSelectedCoverage } from './ESPSelectedCoverage';
import { GetGlobalConfig } from '../../../../../config/useCases/GetGlobalConfigUseCase';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { isErr } from '@embroker/shotwell/core/types/Result';

interface ESPSelectedCoverageListProps {
    selectedCoverages: Immutable<Array<ESPCoverageRateItem>>;
    isSubmitting: boolean;
    revenue: number;
    state?: string;
    fields: WizardForm<OpaqueForm<ESPQuoteOptionsFormData>>['fields'];
    restriction?: Immutable<InsuranceApplicationRestriction>;
    newInsurerDocumentsReleaseDate?: Date;
    fiduciaryDocumentsReleaseDate?: Date;
    submittedAt?: Date;
    isQuoteReferred: boolean;
    higherLimitRequests?: Immutable<Record<string, number>>;
    showEpliStandaloneInCalifornia?: () => void;
}

export const ESPSelectedCoverageList = ({
    selectedCoverages,
    revenue,
    state,
    isSubmitting,
    fields,
    restriction,
    newInsurerDocumentsReleaseDate,
    fiduciaryDocumentsReleaseDate,
    submittedAt,
    isQuoteReferred,
    higherLimitRequests,
    showEpliStandaloneInCalifornia,
}: ESPSelectedCoverageListProps) => {
    const { result: globalConfigResult, isLoading: isLoadingGlobalConfig } =
        useUseCase(GetGlobalConfig);
    if (isLoadingGlobalConfig || !globalConfigResult) {
        return <Spinner appearance="transparent" />;
    }
    if (isErr(globalConfigResult)) {
        return (
            <StatusMessage status="error">{`We failed to load the global configuration. Try again or report an error!`}</StatusMessage>
        );
    }

    const isIptChangesEnabled = globalConfigResult?.value?.config?.espIptChangesEnabled;
    const isEplOnly =
        selectedCoverages.length === 1 && selectedCoverages[0].coverageType === 'epli';
    const isCaState = state === 'CA';

    return (
        <StackLayout>
            <Text style="heading 4">Selected Coverages</Text>
            {selectedCoverages.length === 0 ? (
                <Card center>Add the coverages you're interested in to receive a quote</Card>
            ) : (
                selectedCoverages.map((coverage) => {
                    return (
                        <React.Fragment key={coverage.coverageType}>
                            {isIptChangesEnabled && isEplOnly && isCaState && (
                                <StackLayout>
                                    <StatusMessage status={'warning'}>
                                        EPLI coverage can only be purchased with another coverage.
                                    </StatusMessage>
                                </StackLayout>
                            )}
                            <ESPSelectedCoverage
                                key={coverage.coverageType}
                                coverage={coverage}
                                revenue={revenue}
                                isSubmitting={isSubmitting}
                                fields={fields}
                                state={state}
                                coverageRestriction={
                                    restriction
                                        ? getCoverageRestriction(coverage.coverageType, restriction)
                                        : undefined
                                }
                                newInsurerDocumentsReleaseDate={newInsurerDocumentsReleaseDate}
                                fiduciaryDocumentsReleaseDate={fiduciaryDocumentsReleaseDate}
                                submittedAt={submittedAt}
                                isQuoteReferred={isQuoteReferred}
                                restrictions={restriction}
                                higherLimitRequest={
                                    higherLimitRequests !== undefined
                                        ? higherLimitRequests[
                                              mapToCoverageRestrictionCoverageType(
                                                  coverage.coverageType,
                                              )
                                          ]
                                        : undefined
                                }
                                showEpliStandaloneInCalifornia={showEpliStandaloneInCalifornia}
                            />
                        </React.Fragment>
                    );
                })
            )}
        </StackLayout>
    );
};
