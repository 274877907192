import { BoxLayout, CardLayout } from '@embroker/ui-toolkit/v2';
import React from 'react';

interface ESPEndorsementCardLayoutProps {
    children: React.ReactNode;
    'data-e2e'?: string;
}

export function ESPEndorsementCardLayout({
    children = null,
    'data-e2e': dataE2E,
}: ESPEndorsementCardLayoutProps) {
    return (
        <CardLayout radius="8" data-e2e={dataE2E}>
            <BoxLayout>{children}</BoxLayout>
        </CardLayout>
    );
}
