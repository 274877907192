import { ESPEndorsementLiabilityCoverageType } from '../../types/ESPEndorsementLiabilityCoverageType';

// ESP Endorsement display name does not match LiabilityCoverageCodeListMap
const mapCoverageTypeToDisplayName: Record<ESPEndorsementLiabilityCoverageType, string> = {
    LiabilityCoverageCodeListIndemnifiableDirectorsAndOfficersLiability: 'Directors and Officers',
    LiabilityCoverageCodeListEmploymentPracticesLiability: 'Employment Practices Liability',
    LiabilityCoverageCodeListFiduciaryLiability: 'Fiduciary',
    LiabilityCoverageCodeListTechnologyAndMediaErrorsAndOmissions: 'Technology E&O/Cyber',
    LiabilityCoverageCodeListTechSplit: 'Technology E&O',
    LiabilityCoverageCodeListCyberSplit: 'Cyber',
};

export function getCoverageDisplayName(coverageType: ESPEndorsementLiabilityCoverageType): string {
    return mapCoverageTypeToDisplayName[coverageType];
}
