import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { map, route } from 'navi';
import React from 'react';
import { GetESPEndorsementPolicy } from '../../esp/useCases/GetESPEndorsementPolicy';
import { GetESPEndorsementUserData } from '../../esp/useCases/GetESPEndorsementUserData';
import { ESPEndorsementPage } from '../../esp/view/components/ESPEndorsementPage';
import { redirectToWhoopsPage } from '../../../view/errors';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { GetRestriction } from '../../../quote/esp/useCases/GetRestriction';
import { Immutable } from '@embroker/shotwell/core/types';
import { InsuranceApplicationRestriction } from '../../../quote/esp/types/InsuranceApplicationRestriction';

export const espEndorsementRoutes = {
    '/': map(async (req) => {
        const { policyId } = req.query;
        if (!UUID.check(policyId)) {
            return redirectToWhoopsPage([
                InvalidArgument({ argument: 'policyId', value: policyId, validator: 'UUID' }),
            ]);
        }

        const getESPEndorsementPolicyResult = await execute(GetESPEndorsementPolicy, {
            policyId,
        });
        if (isErr(getESPEndorsementPolicyResult)) {
            return redirectToWhoopsPage(getESPEndorsementPolicyResult.errors);
        }

        const getESPEndorsementUserDataResult = await execute(GetESPEndorsementUserData);
        if (isErr(getESPEndorsementUserDataResult)) {
            return redirectToWhoopsPage(getESPEndorsementUserDataResult.errors);
        }

        let restrictions: Immutable<InsuranceApplicationRestriction> | undefined;
        const getRestrictionsResult = await execute(GetRestriction, {
            insuranceApplicationId: getESPEndorsementPolicyResult.value.applicationId,
        });
        if (isOK(getRestrictionsResult)) {
            restrictions = getRestrictionsResult.value.restriction;
        }

        return route({
            view: (
                <ESPEndorsementPage
                    policyId={policyId}
                    userData={getESPEndorsementUserDataResult.value}
                    policy={getESPEndorsementPolicyResult.value}
                    restrictions={restrictions}
                />
            ),
        });
    }),
};
