import { Money } from '@embroker/shotwell/core/types/Money';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import React, { Fragment } from 'react';
import { PremiumRange } from '../../../../quote/entities/Quote';
import { ModalCoverageSummaryProp } from '../../coverageDefinition';
import { getMPLTitle } from '../buildMPLCoverage';
import { MPLQuote } from '../entities/MPLQuote';
import { CoverageModalSummary } from '../../CoverageSummary.view';

export function MPLModalCoverageSummary({ quote }: ModalCoverageSummaryProp<MPLQuote>) {
    const { premiumLabel, premiumDisplayComponent } =
        quote.status === 'referred'
            ? {
                  premiumLabel: 'ESTIMATED PREMIUM RANGE',
                  premiumDisplayComponent: getPremiumRangeComponent(quote.premiumRange),
              }
            : {
                  premiumLabel: 'PREMIUM',
                  premiumDisplayComponent: <MoneyDisplay value={quote.totalPremium} />,
              };

    return (
        <CoverageModalSummary
            title={getMPLTitle(quote.mplVertical)}
            summaryList={[
                {
                    label: 'LIMIT',
                    value: (
                        <Fragment>
                            <MoneyDisplay
                                value={Money.tryFromFloat(quote.options.limits.perClaimLimit)}
                                fractionDigits={0}
                            />
                            &nbsp;/&nbsp;
                            <MoneyDisplay
                                value={Money.tryFromFloat(quote.options.limits.aggregateLimit)}
                                fractionDigits={0}
                            />
                        </Fragment>
                    ),
                },
                {
                    label: 'RETENTION',
                    value: (
                        <MoneyDisplay
                            value={Money.tryFromFloat(quote.options.retention)}
                            fractionDigits={0}
                        />
                    ),
                },
                {
                    label: premiumLabel,
                    value: premiumDisplayComponent,
                },
            ]}
        />
    );
}

const getPremiumRangeComponent = (premiumRange?: PremiumRange) => {
    return premiumRange ? (
        <React.Fragment>
            <MoneyDisplay value={premiumRange.min} />
            &nbsp;-&nbsp;
            <MoneyDisplay value={premiumRange.max} />
        </React.Fragment>
    ) : null;
};
