import { injectable } from '@embroker/shotwell/core/di';
import { API } from '@embroker/shotwell-api/v2/app';
import * as APITypes from '@embroker/shotwell-api/v2/app.spec';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { GetManifestByVersionRequest, ManifestRepository } from './index';
import {
    CreateServiceInfoError,
    GetManifestByVersionError,
    CreateManifestDefinitionError,
} from '../../errors';
import { ManifestDefinition } from '../../types/ManifestDefinition';
import { ServiceDefinition } from '../../types/ServiceDefinition';
import { ModelVersion } from '../../types/ModelVersion';

@injectable()
export class APIManifestRepository implements ManifestRepository {
    async getByVersion(
        request: GetManifestByVersionRequest,
    ): AsyncResult<
        ManifestDefinition,
        GetManifestByVersionError | CreateManifestDefinitionError | CreateServiceInfoError
    > {
        const getManifestByVersionId = await API.get(`manifests/${request.version}?exact=false`);

        if (isErr(getManifestByVersionId)) {
            return Failure(GetManifestByVersionError(getManifestByVersionId.errors[0]));
        }

        const versions = await this.createServiceDefinitions(
            getManifestByVersionId.value.manifestVersion.definition.versions,
        );
        if (isErr(versions)) {
            return versions;
        }

        const manifestDefinitionResult = ManifestDefinition.create({
            effectiveDate: getManifestByVersionId.value.manifestVersion.definition.effectiveDate,
            versions: versions.value,
        });
        if (isErr(manifestDefinitionResult)) {
            return Failure(CreateManifestDefinitionError(manifestDefinitionResult.errors[0]));
        }

        return Success(manifestDefinitionResult.value);
    }

    private async createServiceDefinitions(
        definitions: Immutable<APITypes.VersionDefinition[]>,
    ): AsyncResult<ServiceDefinition[], CreateServiceInfoError> {
        const result: Immutable<ServiceDefinition>[] = [];
        for (const definition of definitions) {
            const definitionResult = await this.createServiceDefinition(definition);
            if (isErr(definitionResult)) {
                return definitionResult;
            }

            result.push(definitionResult.value);
        }

        return Success(result);
    }

    private async createServiceDefinition(
        definition: APITypes.VersionDefinition,
    ): AsyncResult<ServiceDefinition, CreateServiceInfoError> {
        const modelVersion = await this.createModelVersion(definition.modelVersion);
        if (isErr(modelVersion)) {
            return modelVersion;
        }

        const defResult = ServiceDefinition.create({
            service: definition.service,
            modelVersion: modelVersion.value,
        });
        if (isErr(defResult)) {
            return Failure(CreateServiceInfoError(defResult.errors[0]));
        }

        return defResult;
    }

    private async createModelVersion(
        modelVersionInfo: APITypes.ModelVersion,
    ): AsyncResult<ModelVersion, CreateServiceInfoError> {
        const modelVersionResult = ModelVersion.create({
            id: modelVersionInfo.id,
            modelType: modelVersionInfo.modelType,
            description: modelVersionInfo.description,
            effectiveDate: modelVersionInfo.effectiveDate,
        });

        if (isErr(modelVersionResult)) {
            return Failure(CreateServiceInfoError(modelVersionResult.errors[0]));
        }

        return Success(modelVersionResult.value);
    }
}
