import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { UseCase, UseCaseClass, execute } from '@embroker/shotwell/core/UseCase';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { cast } from '@embroker/shotwell/core/types/Nominal';
import { AsyncResult, Success, isErr } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { GetDocumentUrl } from '../../documents/useCases/GetDocumentUrl';
import { DigitalEndorsementsRepository } from '../repositories/digitalEndorsements';

export interface PreviewEndorsementRequest {
    endorsementId: UUID;
    endorsementData: unknown;
    prorateId?: UUID;
}

export interface PreviewEndorsement extends UseCase {
    execute(
        request: PreviewEndorsementRequest,
    ): AsyncResult<URI, InvalidArgument | OperationFailed>;
}

@injectable()
class PreviewEndorsementUseCase extends UseCase implements PreviewEndorsement {
    public static type = Symbol('DigitalEndorsements/PreviewEndorsement');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(DigitalEndorsementsRepository)
        private digitalEndorsementsRepository: DigitalEndorsementsRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: PreviewEndorsementRequest,
    ): AsyncResult<URI, InvalidArgument | OperationFailed> {
        const previewEndorsementResult =
            await this.digitalEndorsementsRepository.previewEndorsement({
                endorsementId: request.endorsementId,
                endorsementData: request.endorsementData,
                prorateId: request.prorateId,
            });

        if (isErr(previewEndorsementResult)) {
            return previewEndorsementResult;
        }
        const documentUrlResponse = await execute(GetDocumentUrl, {
            fileKey: previewEndorsementResult.value,
        });
        if (isErr(documentUrlResponse)) {
            return documentUrlResponse;
        }

        return Success(cast<URI>(documentUrlResponse.value.downloadUrl));
    }
}

export const PreviewEndorsement: UseCaseClass<PreviewEndorsement> = PreviewEndorsementUseCase;
