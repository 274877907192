import { isOK } from '@embroker/shotwell/core/types/Result';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Spinner } from '@embroker/ui-toolkit/v2';
import { isWithinInterval, parseISO, startOfToday } from 'date-fns';
import React from 'react';
import { GetEnrolledUserFeatures } from '../../../../featureRollout/useCases/GetEnrolledUserFeatures';
import { GetCrimeConfig } from '../../../../quote/crime/useCases/GetCrimeConfig';
import { GetCyberConfig } from '../../../../quote/cyber/useCases/GetCyberConfig';
import { GetEmbrokerExcessEnabled } from '../../../../quote/embrokerExcess/useCases/GetEmbrokerExcessEnabled';
import { GetConfig } from '../../../useCases/GetConfig';
import { BrokerSelectProduct, CoverageItem } from './BrokerSelectProductForm';
import { BrokerCoverageType } from './createBrokerSelectProductForm';

interface GetAQuoteWizardProps {
    naicsCode?: string;
    initialData?: any;
    hideCompanyPage?: boolean;
    isPreselected?: boolean;
}

const EXCESS_LABEL_START_DATE = '2023-02-19';
const EXCESS_LABEL_END_DATE = '2023-07-01';

function getExcessStatusLabel() {
    if (
        isWithinInterval(startOfToday(), {
            start: parseISO(EXCESS_LABEL_START_DATE),
            end: parseISO(EXCESS_LABEL_END_DATE),
        })
    ) {
        return 'new';
    }
}

export function GetAQuoteWizard(props: GetAQuoteWizardProps) {
    const { result: getCrimeConfigResult } = useUseCase(GetCrimeConfig);
    const { result: getCyberConfigResult } = useUseCase(GetCyberConfig);
    const { result: getEmbrokerExcessEnabledResult } = useUseCase(GetEmbrokerExcessEnabled);
    const { result: getBrokerConfigResult } = useUseCase(GetConfig);
    const { result: getEnrolledUserFeatures } = useUseCase(GetEnrolledUserFeatures);

    if (
        getEnrolledUserFeatures === undefined ||
        getBrokerConfigResult === undefined ||
        getCrimeConfigResult === undefined ||
        getCyberConfigResult === undefined ||
        getEmbrokerExcessEnabledResult === undefined
    ) {
        return <Spinner appearance="transparent" />;
    }

    const isEmbrokerCrimeEnabled =
        isOK(getCrimeConfigResult) && getCrimeConfigResult.value.isEmbrokerCrimeEnabled;
    const isEmbrokerExcessEnabled =
        isOK(getEmbrokerExcessEnabledResult) && getEmbrokerExcessEnabledResult.value;

    const coverages: CoverageItem[] = [
        {
            title: 'Startup Package',
            value: BrokerCoverageType.ESP,
        },
        {
            title: 'Private Company Management Liability',
            value: BrokerCoverageType.PCOML,
        },
        {
            title: 'Lawyers Professional Liability',
            value: BrokerCoverageType.LPL,
        },
    ];

    if (isEmbrokerCrimeEnabled) {
        coverages.push({
            title: 'Commercial Crime',
            value: BrokerCoverageType.Crime,
        });
    }

    if (isEmbrokerExcessEnabled) {
        coverages.splice(2, 0, {
            title: 'Excess Tech E&O/Cyber',
            value: BrokerCoverageType.EmbrokerExcess,
            statusLabel: getExcessStatusLabel(),
        });
    }

    return <BrokerSelectProduct {...props} coverages={coverages} />;
}
