import { ElementProps } from '@embroker/ui-toolkit/v2';
import React from 'react';

export const HiddenField = React.forwardRef(function HiddenField(
    props: ElementProps<'input'>,
    ref: React.Ref<HTMLInputElement>,
) {
    return (
        <input
            {...{
                ...props,
                type: 'hidden',
                ref,
            }}
        />
    );
});
