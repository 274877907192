import React from 'react';
import { Text, Tooltip } from '@embroker/ui-toolkit/v2';

interface BundleQuoteCoverageListTitleProps {
    title: React.ReactNode;
    toolTip?: string;
}

export function BundleQuoteCoverageListTitle({
    title,
    toolTip,
}: BundleQuoteCoverageListTitleProps) {
    return (
        <Text style="heading 4" data-e2e="coverageList-title">
            {title}

            {!!toolTip && (
                <Tooltip iconColor="ui-500" iconSize="small" placement="top" text={toolTip} />
            )}
        </Text>
    );
}
