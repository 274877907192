import {
    BrokerageGetBrokerageByBrokerResponse,
    BrokerageGetBrokersResponse,
    BrokerageGetDigitalQuotesDataResponse,
    BrokerageGetGrossPremiumDataResponse,
    BrokerageGetLookerEmbedUrlRequest,
    BrokerageGetPolicyBindsDataResponse,
    ListOptions,
    ShoppingCreateSignatureDocumentRequest,
    ShoppingCreateSignatureDocumentResponse,
    ShoppingGetActiveSignatureRequestRequest,
    ShoppingGetActiveSignatureRequestResponse,
    ShoppingGetSignatureDocumentRequest,
    ShoppingGetSignatureDocumentResponse,
    ShoppingSignSignatureRequestRequest,
} from '@embroker/shotwell-api/app';
import {
    InvalidArgument,
    NotAllowed,
    OperationFailed,
    UnknownEntity,
} from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { Money } from '@embroker/shotwell/core/types/Money';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { State } from '@embroker/shotwell/core/types/StateList';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { User } from '../../userOrg/entities/User';
import { Token } from '../../userOrg/types/Token';
import {
    BrokerageNotFoundError,
    BrokerEmailAlreadyInUse,
    BrokerInvalidEmail,
    EditApplicationError,
    GetNaicsEligibleAppTypesError,
    InvitationExpired,
    InvitationNotFound,
    ServiceNotAvailable,
    SignatureDocumentAlreadySigned,
    SignatureDocumentManagingBrokerNotFound,
    SignatureDocumentNotAllowed,
    SignatureDocumentNotFound,
    Unauthenticated,
} from '../errors';
import { BrokerActivityItem } from '../types/BrokerActivityItem';
import { BrokerageOrganizationInfo } from '../types/BrokerageOrganizationInfo';
import { BrokerQuoteRecord } from '../types/BrokerQuoteRecord';
import { BrokerRenewalRecord } from '../types/BrokerRenewalRecord';
import { OutstandingPayment } from '../types/OutstandingPayment';

export interface GetConfigResponse {
    readonly isAppIntakeEnabled: boolean;
    readonly isClientApplicationEditEnabled: boolean;
    readonly isMidTermBorEnabled: boolean;
}

export interface BrokerPolicy {
    policyId: UUID;
    companyId: UUID;
    companyName: string;
    policyNumber: string;
    effectiveDate: Date;
    expirationDate: Date;
    premium: Money;
    managingBrokerName: string;
    productType: string;
}

export interface BrokerPolicyList {
    policyList: BrokerPolicy[];
    pageInfo: PageInfo;
}

export interface BrokerOrganization {
    id: UUID;
    name: string;
    broker?: string;
    city?: string;
    state?: State;
}

export interface PageInfo {
    totalItems: number;
    size: number;
    index: number;
}
export interface BrokerRenewalRecordList {
    renewalList: BrokerRenewalRecord[];
    pageInfo: PageInfo;
}

export interface BrokerQuoteRecordList {
    quoteList: BrokerQuoteRecord[];
    pageInfo: PageInfo;
}

export interface BrokerActivityItemList {
    activityList: BrokerActivityItem[];
    pageInfo: PageInfo;
    partialResults: boolean;
}

export interface RawBarChartData {
    dataPoints: DataPoint[];
    groupNames: string[];
}

export interface DataPoint {
    x: string | number;
    y: string | number;
}

export interface IndexOptions {
    indexBy: 'x' | 'y';
}

export interface UploadBORLetterRequest {
    brokerId: UUID;
    fileKey: string;
    additionalNotes?: string;
    organizationId?: UUID;
}
export interface BrokerSignUpRequest {
    firstName: string;
    lastName: string;
    password: string;
    invitationToken: Token;
}

export interface BrokerSignUpResponse {
    brokerId: UUID;
}

export interface GetInvitationStatusRequest {
    invitationToken: Token;
}

type InvitationStatus = 'accepted' | 'expired' | 'pending';

export interface GetInvitationStatusResponse {
    status: InvitationStatus;
}

export interface SignSignatureRequestResponse {
    signatureToken: UUID;
}

export interface RequestLossRunsRequest {
    policyNumberList: string[];
    sendToCurrentBroker?: boolean;
    recipientList?: EmailAddress[];
}

export interface BrokerRepository {
    getConfig(): AsyncResult<GetConfigResponse, InvalidArgument | OperationFailed>;
    getActiveBroker(): AsyncResult<
        User,
        Unauthenticated | InvalidArgument | OperationFailed | UnknownEntity
    >;
    getBrokers(): AsyncResult<BrokerageGetBrokersResponse, InvalidArgument | OperationFailed>;
    getBrokerageByBroker(
        userId: UUID,
    ): AsyncResult<BrokerageGetBrokerageByBrokerResponse, BrokerageNotFoundError>;
    getBrokerageOrganizationInfo(
        orgId: UUID,
    ): AsyncResult<BrokerageOrganizationInfo, InvalidArgument | OperationFailed | NotAllowed>;
    getBrokerPolicyList(
        options: Immutable<ListOptions>,
    ): AsyncResult<BrokerPolicyList, InvalidArgument | OperationFailed>;
    getBrokerQuoteRecordList(
        options: Immutable<ListOptions>,
    ): AsyncResult<BrokerQuoteRecordList, InvalidArgument | OperationFailed>;
    getBrokerRenewalRecordList(
        options: Immutable<ListOptions>,
    ): AsyncResult<BrokerRenewalRecordList, InvalidArgument | OperationFailed>;
    getBrokerActivityItemsList(
        options: Immutable<ListOptions>,
    ): AsyncResult<BrokerActivityItemList, InvalidArgument | OperationFailed>;
    getBrokerageOutstandingPayments(): AsyncResult<
        OutstandingPayment[],
        InvalidArgument | OperationFailed | ServiceNotAvailable
    >;
    getBrokerOrganizations(
        broker?: UUID,
        filter?: string,
        count?: number,
    ): AsyncResult<BrokerOrganization[], InvalidArgument | OperationFailed>;
    editApplication(applicationId: UUID): AsyncResult<void, EditApplicationError>;
    getNaicsEligibleAppTypes(naics: string): AsyncResult<string[], GetNaicsEligibleAppTypesError>;
    getActiveSignatureRequest(
        request: ShoppingGetActiveSignatureRequestRequest,
    ): AsyncResult<ShoppingGetActiveSignatureRequestResponse, InvalidArgument | OperationFailed>;
    getSignatureDocument(
        request: ShoppingGetSignatureDocumentRequest,
    ): AsyncResult<
        ShoppingGetSignatureDocumentResponse,
        | InvalidArgument
        | OperationFailed
        | SignatureDocumentNotFound
        | SignatureDocumentNotAllowed
        | SignatureDocumentAlreadySigned
    >;
    save(
        user: User,
    ): AsyncResult<
        void,
        InvalidArgument | OperationFailed | BrokerEmailAlreadyInUse | BrokerInvalidEmail
    >;
    createSignatureDocument(
        request: ShoppingCreateSignatureDocumentRequest,
    ): AsyncResult<
        ShoppingCreateSignatureDocumentResponse,
        | OperationFailed
        | InvalidArgument
        | SignatureDocumentNotFound
        | SignatureDocumentNotAllowed
        | SignatureDocumentAlreadySigned
        | SignatureDocumentManagingBrokerNotFound
    >;
    signSignatureRequest(
        request: ShoppingSignSignatureRequestRequest,
    ): AsyncResult<
        SignSignatureRequestResponse,
        | InvalidArgument
        | OperationFailed
        | SignatureDocumentAlreadySigned
        | SignatureDocumentNotFound
    >;
    getRenewalCount(): AsyncResult<number, InvalidArgument | OperationFailed>;
    getQuoteCount(): AsyncResult<number, InvalidArgument | OperationFailed>;
    getOrganizationCount(): AsyncResult<number, InvalidArgument | OperationFailed>;
    getPaymentCount(): AsyncResult<number, InvalidArgument | OperationFailed>;
    getLookerReportUrl(
        request: BrokerageGetLookerEmbedUrlRequest,
    ): AsyncResult<string, InvalidArgument | OperationFailed>;
    getQuoteToBindRatio(): AsyncResult<number, InvalidArgument | OperationFailed>;
    getDigitalQuotesData(): AsyncResult<
        BrokerageGetDigitalQuotesDataResponse,
        InvalidArgument | OperationFailed
    >;
    getBoundPoliciesData(): AsyncResult<
        BrokerageGetPolicyBindsDataResponse,
        InvalidArgument | OperationFailed
    >;
    getGrossPremiumData(): AsyncResult<
        BrokerageGetGrossPremiumDataResponse,
        InvalidArgument | OperationFailed
    >;
    uploadBORLetter(
        request: UploadBORLetterRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed>;
    requestLossRuns(
        request: RequestLossRunsRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed | BrokerageNotFoundError | NotAllowed>;
    getInvitationStatus(
        request: GetInvitationStatusRequest,
    ): AsyncResult<
        GetInvitationStatusResponse,
        InvitationNotFound | InvalidArgument | OperationFailed
    >;
    signUpBroker(
        request: BrokerSignUpRequest,
    ): AsyncResult<
        BrokerSignUpResponse,
        InvitationNotFound | InvitationExpired | InvalidArgument | OperationFailed
    >;
}

export const BrokerRepository = Symbol('BrokerRepository');
