import { Immutable } from '@embroker/shotwell/core/types';
import {
    CheckBox,
    Grid,
    InputStatusMessage,
    Modal,
    useModal,
    Text,
    InputStatus,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import { SignInsuranceApplicationCheckbox } from '../../../../quote/view/components/signature/SignInsuranceApplicationCheckbox';
import React, { ChangeEventHandler, useCallback } from 'react';
import { ESPEndorsementUserData } from '../../types/ESPEndorsementUserData';
import { WarrantyAndFraudModalContent } from '../../../../quote/view/components/signature/WarrantyAndFraudModalContent';

interface ESPEndorsementSignatureProps {
    userData: ESPEndorsementUserData;
    agreementToConductSignature: boolean;
    onAgreementToConductSignatureChange: ChangeEventHandler<HTMLInputElement>;
    agreementToConductSignatureMessages: Immutable<InputStatusMessage[]>;
    warrantyAndFraudSignature: boolean;
    onWarrantyAndFraudSignatureChange: ChangeEventHandler<HTMLInputElement>;
    warrantyAndFraudSignatureMessages: Immutable<InputStatusMessage[]>;
    isFormInvalid: boolean;
}

export function ESPEndorsementSignature({
    userData,
    agreementToConductSignature,
    onAgreementToConductSignatureChange,
    agreementToConductSignatureMessages,
    warrantyAndFraudSignature,
    onWarrantyAndFraudSignatureChange,
    warrantyAndFraudSignatureMessages,
    isFormInvalid,
}: ESPEndorsementSignatureProps) {
    const warrantyAndFraudModal = useModal();

    const handleOpenWarrantyAndFraudModal = useCallback(() => {
        warrantyAndFraudModal.show();
    }, [warrantyAndFraudModal]);

    const agreementToConductContentAppearance =
        isFormInvalid && !agreementToConductSignature ? 'alert' : 'default';
    const warrantyAndFraudContentAppearance =
        isFormInvalid && !warrantyAndFraudSignature ? 'alert' : 'default';

    return (
        <React.Fragment>
            <Grid.Row>
                <Grid.Cell>
                    <div className="o-media o-media--gap-xsmall">
                        <div className="o-media__figure">
                            <CheckBox
                                checked={agreementToConductSignature}
                                onChange={onAgreementToConductSignatureChange}
                            />
                        </div>
                        <div className="o-media__body">
                            <SignInsuranceApplicationCheckbox
                                style={agreementToConductContentAppearance}
                                company={userData.company}
                                fullName={userData.fullName}
                                usaState={userData.usaState ?? null}
                            />
                            <InputStatus messages={agreementToConductSignatureMessages} />
                        </div>
                    </div>
                </Grid.Cell>
            </Grid.Row>
            <Grid.Row>
                <Grid.Cell>
                    <div className="o-media o-media--gap-xsmall">
                        <div className="o-media__figure">
                            <CheckBox
                                checked={warrantyAndFraudSignature}
                                onChange={onWarrantyAndFraudSignatureChange}
                            />
                        </div>
                        <div className="o-media__body">
                            <Modal {...warrantyAndFraudModal} size="medium">
                                <WarrantyAndFraudModalContent
                                    fullName={userData.fullName}
                                    title={userData.title}
                                    usaState={userData.usaState ?? null}
                                />
                            </Modal>
                            <Text style={warrantyAndFraudContentAppearance}>
                                By checking this box, you certify all information provided in the
                                insurance application is true and correct and acknowledge that you
                                have read and agree to the{' '}
                                <TextButton onClick={handleOpenWarrantyAndFraudModal}>
                                    Warranty and Fraud Statement
                                </TextButton>
                                .
                            </Text>
                            <InputStatus messages={warrantyAndFraudSignatureMessages} />
                        </div>
                    </div>
                </Grid.Cell>
            </Grid.Row>
        </React.Fragment>
    );
}
