import React from 'react';
import { useNavigation } from '@app/view/hooks/useNavigation';
import {
    onboardingStepUrlMap,
    USER_ONBOARDING_BASE_ROUTE,
} from '@app/userOrg/view/routes/onboardingRoutes';
import { TechVerticalProductSelectionPage } from './techVerticalProductSelection/TechVerticalProductSelectionPage';
import { StartGenericBundleRequest, useStartApplication } from '../hooks/useStartApplication';
import { Spinner } from '@embroker/ui-toolkit/v2';

export function ProductSelectionPage() {
    const { navigate } = useNavigation();
    const { startGenericBundleApplication, isLoading } = useStartApplication();
    const handleBackClicked = () => {
        const lastStep = Object.values(onboardingStepUrlMap).at(-1);
        navigate(`${USER_ONBOARDING_BASE_ROUTE}${lastStep}`);
    };
    const handleContinueClicked = (startGenericBundleRequest: StartGenericBundleRequest) => {
        startGenericBundleApplication(startGenericBundleRequest);
    };

    if (isLoading) {
        return <Spinner />;
    }

    // Currently we only suport TechVerticalProductSelection but this page will be built out to support other product selection pages
    return (
        <TechVerticalProductSelectionPage
            onBackClicked={handleBackClicked}
            onContinueClicked={handleContinueClicked}
        />
    );
}
