import { FormElement } from '@embroker/service-app-engine';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { hasRole } from '../../userOrg/entities/Session';
import { SessionRepository } from '../../userOrg/repositories/SessionRepository';
import { ApplicationRepository } from '../repositories/ApplicationRepository';
import { ApplicationStates, GetFormEngineContext, toFormEngineInput } from './GetFormEngineContext';
import { GetFormEngineInstance } from './GetFormEngineInstance';
import { Underlying } from '../types/enums';
import { AppDetails } from '../types/AppDetails';

export interface GetSubmittedApplicationRequest {
    id: UUID;
}

export interface GetSubmittedApplicationResponse {
    formEngine: FormElement;
}

export interface GetSubmittedApplication extends UseCase {
    execute(
        request: GetSubmittedApplicationRequest,
    ): AsyncResult<GetSubmittedApplicationResponse, InvalidArgument | OperationFailed>;
}

@injectable()
class GetSubmittedApplicationUseCase extends UseCase implements GetSubmittedApplication {
    public static type = Symbol('Shopping/GetSubmittedApplicationUseCase');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
        @inject(SessionRepository) private sessionRepository: SessionRepository,
        @inject(GetFormEngineContext.type) private getFormEngineContext: GetFormEngineContext,
        @inject(GetFormEngineInstance.type) private getFormEngineInstance: GetFormEngineInstance,
    ) {
        super(eventBus);
    }

    public async execute(
        request: GetSubmittedApplicationRequest,
    ): AsyncResult<GetSubmittedApplicationResponse, InvalidArgument | OperationFailed> {
        const getApplicationResponse = await this.applicationRepository.getApplication(request.id);
        if (isErr(getApplicationResponse)) {
            return handleOperationFailure(getApplicationResponse);
        }
        const { value: application } = getApplicationResponse;

        let qd;
        try {
            qd = JSON.parse(application.questionnaireData ?? '{}');
        } catch (error) {
            return Failure(OperationFailed({ message: 'Failed parse questionnaire data' }));
        }

        const activeSessionResult = await this.sessionRepository.getActiveSession();

        if (isErr(activeSessionResult)) {
            return handleOperationFailure(activeSessionResult);
        }

        if (activeSessionResult.value === null || activeSessionResult.value === undefined) {
            return Failure(
                OperationFailed({ message: 'Detected null for value in activeSessionResult' }),
            );
        }

        const questionnaireData = toFormEngineInput(qd);
        questionnaireData.buildings = questionnaireData.locations;

        const underlyingAppTypes = application.bundleDetails
            ? AppDetails.getAppTypes(application?.bundleDetails?.appDetails, {
                  creationType: Underlying,
              })
            : [];

        const raisedFunding = questionnaireData.raised_venture_funding ?? false;
        const context = {
            data: questionnaireData,
            raisedFunding,
            applicationState: ApplicationStates.SUBMITTED,
            isBroker: hasRole(activeSessionResult.value, 'broker'),
            shoppingCoverageList: application.shoppingCoverageList,
            appTypeList: [application.appType],
            underlyingAppTypes,
            isRenewal: application.isRenewal(),
        };

        const formEngineContextResponse = await this.getFormEngineContext.execute({
            context,
        });

        if (isErr(formEngineContextResponse)) {
            return handleOperationFailure(formEngineContextResponse);
        }

        const { formEngineContext } = formEngineContextResponse.value;
        const formEngineConfig = { alwaysNavigable: true };

        const getFormEngineResult = await this.getFormEngineInstance.execute({
            formEngineContext,
            formEngineConfig,
            appType: application.appType,
        });
        if (isErr(getFormEngineResult)) {
            return getFormEngineResult;
        }
        const formEngine = getFormEngineResult.value.instance;

        formEngine.machine.update({ continueText: 'Continue' });

        return Success<GetSubmittedApplicationResponse>({
            formEngine,
        });
    }
}

export const GetSubmittedApplication: UseCaseClass<GetSubmittedApplication> =
    GetSubmittedApplicationUseCase;
