import { inject, injectable } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { Certificate } from '../entities/Certificate';
import { CertificateNotFound } from '../errors';
import { CertificateRepository } from '../repositories/CertificateRepository';

/**
 * Request data for GetSelfServingCertificate use case
 */
export interface GetSelfServingCertificateRequest {
    certificateID: UUID;
}

/**
 * Response data for GetSelfServingCertificate use case
 */
export interface GetSelfServingCertificateResponse {
    certificate: Immutable<EntityProps<Certificate>>;
}

/**
 * GetSelfServingCertificate use case is used to fetch issued certificates for organization
 */
export interface GetSelfServingCertificate extends UseCase {
    execute({
        certificateID,
    }: GetSelfServingCertificateRequest): AsyncResult<
        GetSelfServingCertificateResponse,
        InvalidArgument | CertificateNotFound
    >;
}

@injectable()
class GetSelfServingCertificateUseCase extends UseCase implements GetSelfServingCertificate {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Certificates/GetSelfServingCertificateUseCase');

    /**
     * Constructor for GetSelfServingCertificate class instance
     * @param eventBus An event bus this Use Case will publish events to.
     * @param certificateRepo repo that will be used to fetch issued certificates for organization
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(CertificateRepository) private certificateRepo: CertificateRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes the GetSelfServingCertificate use case.
     * Input is of type GetSelfServingCertificateRequest
     * @returns InvalidArgument if one of properties in GetSelfServingCertificate was not valid
     */
    public async execute({
        certificateID,
    }: GetSelfServingCertificateRequest): AsyncResult<
        GetSelfServingCertificateResponse,
        InvalidArgument | CertificateNotFound
    > {
        const certificate = await this.certificateRepo.getCertificateForActiveOrganization({
            certificateID,
        });

        if (isErr(certificate)) {
            return certificate;
        }

        return Success({ certificate: certificate.value });
    }
}

export const GetSelfServingCertificate: UseCaseClass<GetSelfServingCertificate> =
    GetSelfServingCertificateUseCase;
