import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { map, route, withData } from 'navi';
import React from 'react';
import { redirectToWhoopsPage } from '../../view/errors';
import { SupplementalQuestionnairePage } from './views/SupplementalQuestionnairePage';
import { ThankYouPage } from './views/ThankYouPage';

function espWizardRouteHandler() {
    return map(async (request) => {
        const { applicationId } = request.query;

        if (!UUID.check(applicationId)) {
            return redirectToWhoopsPage([
                InvalidArgument({
                    argument: 'applicationId',
                    value: applicationId,
                    validator: 'UUID',
                }),
            ]);
        }

        return route({
            title: 'ESP Supplemental Questionnaire',
            view: <SupplementalQuestionnairePage applicationId={applicationId} />,
        });
    });
}

function thankYouPageHandler() {
    return map(async (request) => {
        const { applicationId } = request.query;

        if (!UUID.check(applicationId)) {
            return redirectToWhoopsPage([
                InvalidArgument({
                    argument: 'applicationId',
                    value: applicationId,
                    validator: 'UUID',
                }),
            ]);
        }

        return route({
            title: 'ESP Supplemental Questionnaire',
            view: <ThankYouPage applicationId={applicationId} />,
        });
    });
}

export const espRoutes = {
    '/': map((request) =>
        withData(
            { url: request.mountpath, page: undefined, fullscreen: true },
            espWizardRouteHandler(),
        ),
    ),
    '/thank-you': map((request) =>
        withData(
            { url: request.mountpath, page: undefined, fullscreen: true },
            thankYouPageHandler(),
        ),
    ),
    '/:page': map((request) =>
        withData(
            { url: request.mountpath, page: request.params.page, fullscreen: true },
            espWizardRouteHandler(),
        ),
    ),
};
