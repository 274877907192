import type * as APITypes from '@embroker/shotwell-api/app';
import { API } from '@embroker/shotwell-api/app';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Result,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UnknownNAICSCode } from '../../errors';
import { NAICS } from '../../types/NAICS';
import { getNaicsCodesInGroupResponse, NAICSRepository } from './index';

@injectable()
export class APINAICSRepository implements NAICSRepository {
    naicsList: NAICS[] = [];
    naicsGroups: Map<string, readonly string[]> = new Map<string, string[]>();

    constructor() {
        this.loadData();
    }

    public async getByCode(
        naicsCode: string,
    ): AsyncResult<NAICS, InvalidArgument | OperationFailed | UnknownNAICSCode> {
        await this.loadData();
        const naicsItem = this.naicsList.find((item) => item.code === naicsCode);
        if (naicsItem === undefined) {
            return Failure(UnknownNAICSCode(naicsCode));
        }

        return Success(naicsItem);
    }

    public async getListBySearchTerm(
        searchTerm: string,
        limit?: number,
    ): AsyncResult<NAICS[], InvalidArgument | OperationFailed> {
        const apiResult = await API.request('global/search_naics', {
            search_term: searchTerm,
            limit: limit === undefined ? null : limit,
        });

        if (isErr(apiResult)) {
            return handleOperationFailure(apiResult);
        }

        const result = [];

        for (const item of apiResult.value) {
            const newItemResult = NAICS.create({
                code: item.naics_code,
                matches: item.matches as string[],
                name: item.name,
            });

            if (isErr(newItemResult)) {
                return newItemResult;
            }

            result.push(newItemResult.value as NAICS);
        }

        return Success(result);
    }
    public isNaicsInGroup(naicsCode: string, naicsGroup: string): boolean {
        const selectedNaics = this.naicsList.find((naics) => naics.code === naicsCode);
        const selectedNaicsGroup = this.naicsGroups.get(naicsGroup);

        if (selectedNaics !== undefined && selectedNaicsGroup !== undefined) {
            return selectedNaicsGroup.includes(naicsCode);
        }

        return false;
    }

    public getNaicsGroupNamesByCode(naicsCode: string): string[] {
        const naicsGroupNames = [];
        const allNaicsGroupNames = this.naicsGroups.keys();
        for (const naicsGroupName of allNaicsGroupNames) {
            const naicsCodesForGroup = this.naicsGroups.get(naicsGroupName) ?? [];
            const naicsCodeExistsInGroup = naicsCodesForGroup.includes(naicsCode);
            if (naicsCodeExistsInGroup) {
                naicsGroupNames.push(naicsGroupName);
            }
        }
        return naicsGroupNames;
    }

    public getNaicsNameByCode(naicsCode: string): string | undefined {
        const naicsItem = this.naicsList.find((naics) => naics.code === naicsCode);
        if (naicsItem === undefined) {
            return undefined;
        }
        return naicsItem.name;
    }

    public async getNaicsCodesInGroup(
        naicsGroup: string,
    ): AsyncResult<getNaicsCodesInGroupResponse, OperationFailed | InvalidArgument> {
        const loadDataResult = await this.loadData();
        if (isErr(loadDataResult)) {
            return loadDataResult;
        }

        const naicsCodes = this.naicsGroups.get(naicsGroup);
        return Success({
            naicsCodes: naicsCodes ?? [],
        });
    }

    private async loadData(): AsyncResult<void, OperationFailed | InvalidArgument> {
        const naicsLoadResult = await this.loadNAICSCodes();
        const naicsGroupsResult = await this.loadNAICSGroups();

        if (isErr(naicsLoadResult)) {
            return handleOperationFailure(naicsLoadResult);
        }

        if (isErr(naicsGroupsResult)) {
            return handleOperationFailure(naicsGroupsResult);
        }

        return Success();
    }

    private async loadNAICSCodes(): AsyncResult<void, OperationFailed | InvalidArgument> {
        if (this.naicsList.length === 0) {
            const naicsAPIResult = await API.request('global/get_naics_data');

            if (isErr(naicsAPIResult)) {
                return handleOperationFailure(naicsAPIResult);
            }

            const fillResult = this.fillNaicsList(
                naicsAPIResult.value as APITypes.GlobalGetNaicsDataResponse,
            );

            if (isErr(fillResult)) {
                return handleOperationFailure(fillResult);
            }
        }

        return Success();
    }

    private fillNaicsList(
        data: APITypes.GlobalGetNaicsDataResponse,
    ): Result<void, OperationFailed | InvalidArgument> {
        if (this.naicsList.length === 0) {
            for (const item of data) {
                const naicsCode = NAICS.create({
                    code: item.naics_code,
                    name: item.name,
                    matches: [],
                });

                if (isErr(naicsCode)) {
                    return handleOperationFailure(naicsCode);
                }

                this.naicsList.push(naicsCode.value as NAICS);
            }
        }

        return Success();
    }

    private async loadNAICSGroups(): AsyncResult<void, OperationFailed | InvalidArgument> {
        if (this.naicsGroups.size === 0) {
            const groupsAPIResult = await API.request('global/get_naics_groups');

            if (isErr(groupsAPIResult)) {
                return handleOperationFailure(groupsAPIResult);
            }

            const naicsGroups = groupsAPIResult.value;
            for (const group in naicsGroups) {
                this.naicsGroups.set(group, naicsGroups[group]);
            }
        }
        return Success();
    }
}
