import { errorCodes } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { ErrorLike, ErrorObject } from '@embroker/shotwell/core/types/Result';

export const ErrorCode = errorCodes({
    ServiceNotAvailable: 0x1503,
    SignatureDocumentNotFound: 0x1517,
    SignatureDocumentNotReady: 0x1518,
    SignatureDocumentAlreadySigned: 0x1519,
    SignatureDocumentNotAllowed: 0x1520,
    SignatureDocumentNotSigned: 0x1511,
    SignatureDocumentManagingBrokerNotFound: 0x1512,
    EditApplicationErrorCode: 0x7505,
    GetNaicsEligibleAppTypesErrorCode: 0x7506,
    BrokerageNotFoundErrorCode: 0x7507,
    BrokerInvalidEmail: 0x7508,
    BrokerEmailAlreadyInUse: 0x7509,
    Unauthenticated: 0x7510,
    InvitationNotFound: 0x7511,
    InvitationExpired: 0x7512,
    InvalidQuestionnaire: 0x7513,
    ShareableApplicationNotSaved: 0x7514,
    OrganizationUpdateFailure: 0x7515,
    ApplicationUpdateFailure: 0x7516,
    ShareableApplicationNotCompleted: 0x7517,
    ShareableApplicationNotPromoted: 0x7518,
});

export type ServiceNotAvailable = ErrorObject<
    typeof ErrorCode.ServiceNotAvailable,
    {
        serviceName: string;
    }
>;
export function ServiceNotAvailable(serviceName: string): Immutable<ServiceNotAvailable> {
    return {
        name: 'ServiceNotAvailable',
        code: ErrorCode.ServiceNotAvailable,
        message: 'Service not available',
        details: {
            serviceName,
        },
    };
}

export type EditApplicationError = ErrorObject<typeof ErrorCode.EditApplicationErrorCode>;
export function EditApplicationError(): Immutable<EditApplicationError> {
    return {
        name: 'EditApplicationError',
        code: ErrorCode.EditApplicationErrorCode,
        message: 'Edit application error.',
    };
}

export type SignatureDocumentNotFound = ErrorObject<typeof ErrorCode.SignatureDocumentNotFound, {}>;
export function SignatureDocumentNotFound(): Immutable<SignatureDocumentNotFound> {
    return {
        name: 'SignatureDocumentNotFound',
        code: ErrorCode.SignatureDocumentNotFound,
        message: 'The signature document could not be found.',
        details: {},
    };
}

export type SignatureDocumentManagingBrokerNotFound = ErrorObject<
    typeof ErrorCode.SignatureDocumentManagingBrokerNotFound,
    {}
>;
export function SignatureDocumentManagingBrokerNotFound(): Immutable<SignatureDocumentManagingBrokerNotFound> {
    return {
        name: 'SignatureDocumentManagingBrokerNotFound',
        code: ErrorCode.SignatureDocumentManagingBrokerNotFound,
        message: 'The managing broker for the organization could not be found.',
        details: {},
    };
}

export type SignatureDocumentAlreadySigned = ErrorObject<
    typeof ErrorCode.SignatureDocumentAlreadySigned,
    {}
>;
export function SignatureDocumentAlreadySigned(): Immutable<SignatureDocumentAlreadySigned> {
    return {
        name: 'SignatureDocumentAlreadySigned',
        code: ErrorCode.SignatureDocumentAlreadySigned,
        message: 'The application has been already signed',
        details: {},
    };
}

export type SignatureDocumentNotSigned = ErrorObject<
    typeof ErrorCode.SignatureDocumentNotSigned,
    {}
>;
export function SignatureDocumentNotSigned(): Immutable<SignatureDocumentNotSigned> {
    return {
        name: 'SignatureDocumentNotSigned',
        code: ErrorCode.SignatureDocumentNotSigned,
        message: 'The application has not been signed',
        details: {},
    };
}

export type SignatureDocumentNotReady = ErrorObject<typeof ErrorCode.SignatureDocumentNotReady, {}>;
export function SignatureDocumentNotReady(): Immutable<SignatureDocumentNotReady> {
    return {
        name: 'SignatureDocumentNotReady',
        code: ErrorCode.SignatureDocumentNotReady,
        message: 'Signature document for the application is still being generated',
        details: {},
    };
}

export type SignatureDocumentNotAllowed = ErrorObject<
    typeof ErrorCode.SignatureDocumentNotAllowed,
    {}
>;
export function SignatureDocumentNotAllowed(): Immutable<SignatureDocumentNotAllowed> {
    return {
        name: 'SignatureDocumentNotAllowed',
        code: ErrorCode.SignatureDocumentNotAllowed,
        message: 'Not allowed to access the signature document for the time being',
        details: {},
    };
}

export type GetNaicsEligibleAppTypesError = ErrorObject<
    typeof ErrorCode.GetNaicsEligibleAppTypesErrorCode
>;
export function GetNaicsEligibleAppTypesError(): Immutable<GetNaicsEligibleAppTypesError> {
    return {
        name: 'GetNaicsEligibleAppTypesError',
        code: ErrorCode.GetNaicsEligibleAppTypesErrorCode,
        message: 'Get naics eligible app types error.',
    };
}

export type BrokerageNotFoundError = ErrorObject<typeof ErrorCode.BrokerageNotFoundErrorCode>;
export function BrokerageNotFoundError(): Immutable<BrokerageNotFoundError> {
    return {
        name: 'BrokerageNotFoundError',
        code: ErrorCode.BrokerageNotFoundErrorCode,
        message: 'Brokerage not found.',
    };
}

/**
 * Email already in use error object.
 */
export type BrokerEmailAlreadyInUse = ErrorObject<
    typeof ErrorCode.BrokerEmailAlreadyInUse,
    {
        /**
         * The email address that is already in use.
         */
        email: EmailAddress;
    }
>;

export function BrokerEmailAlreadyInUse(email: EmailAddress): Immutable<BrokerEmailAlreadyInUse> {
    return {
        name: 'BrokerEmailAlreadyInUse',
        code: ErrorCode.BrokerEmailAlreadyInUse,
        message: 'Email already in use.',
        details: {
            email,
        },
    };
}

/**
 * Invalid email error object.
 */
export type BrokerInvalidEmail = ErrorObject<
    typeof ErrorCode.BrokerInvalidEmail,
    {
        /**
         * The email address that is invalid.
         */
        email: EmailAddress;
    }
>;

export function BrokerInvalidEmail(email: EmailAddress): Immutable<BrokerInvalidEmail> {
    return {
        name: 'BrokerInvalidEmail',
        code: ErrorCode.BrokerInvalidEmail,
        message: 'Invalid email.',
        details: {
            email,
        },
    };
}

/**
 * Unauthenticated error object.
 */
export type Unauthenticated = ErrorObject<typeof ErrorCode.Unauthenticated>;

export function Unauthenticated(): Immutable<Unauthenticated> {
    return {
        name: 'Unauthenticated',
        code: ErrorCode.Unauthenticated,
        message: 'Unauthenticated.',
    };
}

export type InvitationNotFound = ErrorObject<typeof ErrorCode.InvitationNotFound>;

export function InvitationNotFound(): Immutable<InvitationNotFound> {
    return {
        name: 'InvitationNotFound',
        code: ErrorCode.InvitationNotFound,
        message: 'Invitation not found.',
    };
}

export type InvitationExpired = ErrorObject<typeof ErrorCode.InvitationExpired>;

export function InvitationExpired(): Immutable<InvitationExpired> {
    return {
        name: 'InvitationExpired',
        code: ErrorCode.InvitationExpired,
        message: 'Invitation expired.',
    };
}

export type InvalidQuestionnaire = ErrorObject<
    typeof ErrorCode.InvalidQuestionnaire,
    {
        questionnaire?: string;
        errors: Immutable<ErrorLike[]>;
    }
>;

export function InvalidQuestionnaire(
    errors: Immutable<ErrorLike[]>,
    questionnaire?: string,
): Immutable<InvalidQuestionnaire> {
    return {
        name: 'InvalidQuestionnaire',
        code: ErrorCode.InvalidQuestionnaire,
        message: 'Questionnaire data is not valid',
        details: {
            questionnaire,
            errors,
        },
    };
}

export type ShareableApplicationNotSaved = ErrorObject<
    typeof ErrorCode.ShareableApplicationNotSaved,
    {
        errors: Immutable<ErrorLike[]>;
    }
>;

export function ShareableApplicationNotSaved(
    errors: Immutable<ErrorLike[]>,
): Immutable<ShareableApplicationNotSaved> {
    return {
        name: 'ShareableApplicationNotSaved',
        code: ErrorCode.ShareableApplicationNotSaved,
        message: 'Shareable application is not saved',
        details: {
            errors,
        },
    };
}

export type ShareableApplicationNotCompleted = ErrorObject<
    typeof ErrorCode.ShareableApplicationNotCompleted,
    {
        errors: Immutable<ErrorLike[]>;
    }
>;

export function ShareableApplicationNotCompleted(
    errors: Immutable<ErrorLike[]>,
): Immutable<ShareableApplicationNotCompleted> {
    return {
        name: 'ShareableApplicationNotCompleted',
        code: ErrorCode.ShareableApplicationNotCompleted,
        message: 'Shareable application failed to complete',
        details: {
            errors,
        },
    };
}

export type ShareableApplicationNotPromoted = ErrorObject<
    typeof ErrorCode.ShareableApplicationNotPromoted,
    {
        errors: Immutable<ErrorLike[]>;
    }
>;

export function ShareableApplicationNotPromoted(
    errors: Immutable<ErrorLike[]>,
): Immutable<ShareableApplicationNotPromoted> {
    return {
        name: 'ShareableApplicationNotPromoted',
        code: ErrorCode.ShareableApplicationNotPromoted,
        message: 'Shareable application failed to promote',
        details: {
            errors,
        },
    };
}

export type OrganizationUpdateFailure = ErrorObject<
    typeof ErrorCode.OrganizationUpdateFailure,
    {
        errors: Immutable<ErrorLike[]>;
    }
>;

export function OrganizationUpdateFailure(
    errors: Immutable<ErrorLike[]>,
): Immutable<OrganizationUpdateFailure> {
    return {
        name: 'OrganizationUpdateFailure',
        code: ErrorCode.OrganizationUpdateFailure,
        message: 'Organization failed to update',
        details: {
            errors,
        },
    };
}

export type ApplicationUpdateFailure = ErrorObject<
    typeof ErrorCode.ApplicationUpdateFailure,
    {
        errors: Immutable<ErrorLike[]>;
    }
>;

export function ApplicationUpdateFailure(
    errors: Immutable<ErrorLike[]>,
): Immutable<ApplicationUpdateFailure> {
    return {
        name: 'ApplicationUpdateFailure',
        code: ErrorCode.ApplicationUpdateFailure,
        message: 'Client application update failed',
        details: {
            errors,
        },
    };
}
