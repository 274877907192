import { inject } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { AppTypeCodeListItem } from '@embroker/shotwell-api/enums';
import {
    ApplicationRepository,
    IntakeAdditionalInfoData,
} from '../../shopping/repositories/ApplicationRepository';
import { UUID } from '@embroker/shotwell/core/types/UUID';

export interface IntakeAdditionalInfoRequest {
    appType: AppTypeCodeListItem;
    additionalData: IntakeAdditionalInfoData;
    applicationId: UUID;
    additionalFiles: Array<string>;
}

export interface IntakeAdditionalInfo extends UseCase {
    execute(request: IntakeAdditionalInfoRequest): AsyncResult<void>;
}

class IntakeAdditionalInfoUseCase extends UseCase implements IntakeAdditionalInfo {
    public static type = Symbol('Broker/IntakeAdditionalInfo');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        appType,
        additionalData,
        additionalFiles,
        applicationId,
    }: IntakeAdditionalInfoRequest): AsyncResult<void> {
        const result = await this.applicationRepository.intakeAdditionalInfo({
            appType,
            additionalData: additionalData,
            additionalFiles: additionalFiles,
            applicationId: applicationId,
        });

        if (isErr(result)) {
            return result;
        }

        return Success();
    }
}

export const IntakeAdditionalInfo: UseCaseClass<IntakeAdditionalInfo> = IntakeAdditionalInfoUseCase;
