import {
    Box,
    Image,
    Modal,
    ModalActions,
    ModalState,
    ProgressBar,
    StackLayout,
    Text,
} from '@embroker/ui-toolkit/v2';
import React, { useEffect, useState } from 'react';

const texts = [
    'Analyzing uploaded document...',
    'Pulling relevant details...',
    'Pre-filling your data...',
    'Double-checking for any errors...',
    'Finalizing your details...',
];

export function AnalyzingUploadedDocumentModal({ modal }: { modal: ModalActions & ModalState }) {
    const [textIndex, setTextIndex] = useState(0);
    useEffect(() => {
        const intervalId = setInterval(() => {
            setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        }, 5000);

        return () => clearInterval(intervalId);
    }, []);
    return (
        <Modal {...modal} size="small" dismissable={false}>
            <StackLayout center gap="24">
                <Image maxWidth={'100px'} name="logo" />
                <Box style={{ width: '320px' }} border={false}>
                    <ProgressBar duration={60} />
                </Box>
                <Text style="heading 4">{texts[textIndex]}</Text>
                <Text style="body 1">This may take a minute or two.</Text>
            </StackLayout>
        </Modal>
    );
}
