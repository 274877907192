import { PolicyFilter } from '../../types/PolicyFilter';

/**
 * This is the repository used to work with ...
 */
export interface PolicyFilterRepository {
    save(filter: PolicyFilter): PolicyFilter;
    get(): PolicyFilter;
    getDefault(): PolicyFilter;
}

export const PolicyFilterRepository = Symbol('PolicyFilterRepository');
