import { container } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Immutable } from '@embroker/shotwell/core/types';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { PageLayout, Spinner, StatusMessage, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { Policy } from '../../../policy/entities/Policy';
import { GetPolicy, GetPolicyRequest } from '../../../policy/useCases/GetPolicy';
import { Application } from '../../../shopping/entities/Application';
import { GetApplication, GetApplicationRequest } from '../../../shopping/useCases/GetApplication';

interface PurchaseToastMessageProps {
    policyId: UUID;
    applicationId: UUID;
    renewalsLeft: number;
    applicationsLeft: number;
}

export const PurchaseToastMessage = ({
    policyId,
    applicationId,
    renewalsLeft,
    applicationsLeft,
}: PurchaseToastMessageProps) => {
    const { isLoading: isApplicationLoading, result: purchasedApplicationResult } = useUseCase(
        GetApplication,
        {
            applicationId: applicationId,
        } as GetApplicationRequest,
    );

    const { isLoading: isPolicyLoading, result: purchasedPolicyResult } = useUseCase(GetPolicy, {
        policyId: policyId,
    } as GetPolicyRequest);

    if (
        isApplicationLoading ||
        isPolicyLoading ||
        purchasedApplicationResult === undefined ||
        purchasedPolicyResult === undefined
    ) {
        return <Spinner />;
    }

    if (isErr(purchasedApplicationResult)) {
        container.get<Logger>(Log).error(purchasedApplicationResult.errors);
        return null;
    }

    if (isErr(purchasedPolicyResult)) {
        container.get<Logger>(Log).error(purchasedPolicyResult.errors);
        return null;
    }

    const purchasedApplication: Immutable<EntityProps<Application>> =
        purchasedApplicationResult.value.application;

    const purchasedPolicy: Immutable<EntityProps<Policy>> = purchasedPolicyResult.value.policy;

    const isRenewal: boolean = purchasedApplication.renewedPolicyIdList.length > 0;
    const numberOfRemaining: number = isRenewal ? renewalsLeft : applicationsLeft;

    const actionType = isRenewal ? 'renewals' : 'applications';

    const actionsToCompleteMessage = `You have ${numberOfRemaining} more ${actionType} to complete.`;

    return (
        <PageLayout.Section>
            <StatusMessage status="success">
                <Text as="span">
                    Nice work on completing the {purchasedPolicy.name}{' '}
                    {isRenewal ? 'renewal' : 'application'}!{' '}
                    {numberOfRemaining > 1 ? actionsToCompleteMessage : null}
                </Text>
            </StatusMessage>
        </PageLayout.Section>
    );
};
