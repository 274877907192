import { inject } from '@embroker/shotwell/core/di';
import { Aborted, InvalidArgument, OperationFailed, Timeout } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { CalculateSKU } from '../../../analytics/useCases/CalculateSKU';
import { ApplicationQuoteCreated } from '../../../shopping/entities/Application';
import { TasksRepository } from '../../../tasks/repositories';
import { CNAQuote } from '../entities/CNAQuote';
import { CNAQuoteRepository } from '../repositories/CNAQuoteRepository';
import { CNAQuoteOptions } from '../types/CNAQuoteOptions';

export interface QuoteCNARequest {
    applicationId: UUID;
    cnaQuoteOptions: CNAQuoteOptions;
    abortSignal: AbortSignal;
    maxPollingRetries?: number;
    pollingRetryIntervalInMilliseconds?: number;
}

export interface QuoteCNA extends UseCase {
    execute(
        request: QuoteCNARequest,
    ): AsyncResult<Immutable<CNAQuote>, InvalidArgument | OperationFailed | Timeout | Aborted>;
}

class QuoteCNAUseCase extends UseCase implements QuoteCNA {
    public static type = Symbol('BOPCNA/QuoteCNA');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(CNAQuoteRepository) private cnaQuoteRepository: CNAQuoteRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
        @inject(CalculateSKU.type) private calculateSku: CalculateSKU,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
        cnaQuoteOptions,
        abortSignal,
        maxPollingRetries = 120,
        pollingRetryIntervalInMilliseconds = 1000,
    }: QuoteCNARequest): AsyncResult<
        Immutable<CNAQuote>,
        InvalidArgument | OperationFailed | Timeout | Aborted
    > {
        const quoteResult = await this.cnaQuoteRepository.quoteCNA(applicationId, cnaQuoteOptions);
        if (isErr(quoteResult)) {
            return quoteResult;
        }

        const pollForTaskStatusResult = await this.tasksRepository.pollForTaskStatus(
            quoteResult.value,
            abortSignal,
            maxPollingRetries,
            pollingRetryIntervalInMilliseconds,
        );
        if (isErr(pollForTaskStatusResult)) {
            return pollForTaskStatusResult;
        }

        const getCurrentQuoteResult = await this.cnaQuoteRepository.getCurrentCNAQuote(
            applicationId,
        );
        if (isErr(getCurrentQuoteResult)) {
            return getCurrentQuoteResult;
        }

        const skuResult = await this.calculateSku.execute({
            event: 'quote',
            applicationId,
        });

        const event: ApplicationQuoteCreated = {
            origin: 'Application',
            name: 'QuoteCreated',
            createdAt: new Date(Date.now()),
            applicationId,
            totalPremium: getCurrentQuoteResult.value.totalPayable,
            id: UUID.create(),
            isRenewal: getCurrentQuoteResult.value.isRenewal,
            sku: skuResult.value,
        };
        await this.eventBus.publish(event);

        return Success(getCurrentQuoteResult.value);
    }
}

export const QuoteCNA: UseCaseClass<QuoteCNA> = QuoteCNAUseCase;
