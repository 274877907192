import { UploadFileCard as UploadFileCardView, UploadFileCardState } from '@embroker/ui-toolkit/v2';
import React, { useEffect, useState } from 'react';
import { FileUpload } from '../FileUpload';
import { useUpload } from '../hooks/useUpload';

interface UploadFileCardProps {
    fileUpload: FileUpload;
    onFileUploadCompleted(id: FileUpload): void;
    onFileUploadCancelled(id: FileUpload): void;
    onFileUploadProgress(id: FileUpload, uploadProgress: number): void;
    readOnly?: boolean;
}

export function UploadFileCard({
    fileUpload,
    onFileUploadCompleted,
    onFileUploadCancelled,
    onFileUploadProgress,
    readOnly,
}: UploadFileCardProps) {
    const [componentState, setComponentState] = useState<UploadFileCardState>('default');
    const { uploadProgress, abortController, fileKey } = useUpload(fileUpload);

    const handleFileUploadAbort = () => {
        abortController?.abort();
        onFileUploadCancelled(fileUpload);
    };

    useEffect(() => {
        if (componentState !== 'success' && uploadProgress === 100) {
            setComponentState('success');
        }
    }, [uploadProgress, componentState]);

    useEffect(() => {
        onFileUploadProgress(fileUpload, uploadProgress);
    }, [onFileUploadProgress, fileUpload, uploadProgress]);

    useEffect(() => {
        if (fileUpload.s3FileKey === null && fileKey !== null) {
            onFileUploadCompleted({
                ...fileUpload,
                s3FileKey: fileKey,
            });
        }
    }, [fileUpload, fileKey, onFileUploadCompleted]);

    const determineUploadProgress = () => {
        if (componentState === 'success') {
            return 0;
        }

        return uploadProgress;
    };

    return (
        <UploadFileCardView
            file={fileUpload.file}
            onUploadAbort={handleFileUploadAbort}
            readOnly={readOnly}
            state={componentState}
            uploadPercent={determineUploadProgress()}
        />
    );
}
