export const CoverageOptionsTermDefinitions = {
    limit: {
        title: 'Limit',
        definition:
            'A limit, or limit of liability, is the most money that the insurance company will pay for a covered claim. Once the limit is reached, coverage under that policy ends, and unless you have excess coverage or another responding policy, you may have to pay remaining losses and costs yourself.',
    },
    aggregateLimit: {
        title: 'Aggregate Limit',
        definition:
            'An aggregate limit is the maximum amount of money your insurance company will pay out for all covered claims during a policy period, which is typically 1 year. This limit is set for the entire policy, not for each individual claim.',
    },
    retention: {
        title: 'Deductible',
        definition:
            'Deductible is the amount of money that you are required to pay, per claim, before the insurance company will start paying.',
    },
    perClaimLimit: {
        title: 'Per Claim Limit',
        definition:
            'A Per Claim Limit is the maximum an insurer will pay for that claim and any related claims during the policy period.',
    },
    perClaimAggregateLimit: {
        title: 'Per Claim/Aggregate Limit',
        definition:
            'An aggregate limit is the maximum amount of money your insurance company will pay out for all covered claims during a policy period, which is typically 1 year. This limit is set for the entire policy, not for each individual claim.',
    },
    perOccurenceLimit: {
        title: 'Per Occurence Limit',
        definition: `A per occurrence limit is the maximum amount the insurer will pay for all claims resulting from a single occurrence. Subject to policy terms, that means that's the limit — no matter how many people are injured, how much property is damaged, or how many different claimants may make claims.`,
    },
    perClaimDeductible: {
        title: 'Per Claim Deductible',
        definition: undefined,
    },
    firstDollarDefenseDamageOnlyDeductible: {
        title: 'First Dollar Defense/Damages Only Deductible',
        definition: `First dollar defense relieves the firm of any burden to pay a deductible with respect to defense expenses (legal fees). The deductible is applied only to an actual payment for damages and/or settlements.`,
    },
    additionalClaimsExpenseOptions: {
        title: 'Additional Claims Expense Options',
        definition:
            'Claims Expense in Addition to Limit provides a separate additional limit specifically for defense costs on a claim and is uncapped. It provides additional coverage for defense costs while preserving standard limit for damages and settlement amounts. Separate Claims Expense Coverage provides a separate additional limit for defense costs on a claim and is capped at a certain amount. Once eroded on defense costs, the standard limit is used for defense costs, settlements and damages. This is an economical way to provide more protection for the firm.',
    },
    separateClaimsExpenseCoverageLimit: {
        title: 'Separate Claims Expense Coverage Limit',
        definition: undefined,
    },
    perAccidentLimit: {
        title: 'Employers Liability Each Accident',
        definition: undefined,
    },
    businessPersonalPropertyLimit: {
        title: 'Business Personal Property Limit',
        definition: `Business personal property coverage provides protection for loss or damage to the physical assets of the business such as furniture, computers, supplies, and fixtures. It doesn't cover the building, land or anything permanently affixed to the building (that's "Building" or "Real Property" coverage).`,
    },
    workerCompensationStatutory: {
        title: 'Workers Compensation',
        definition: `WC is governed by state laws and/or statutes ("statutory"). That means that benefits for medical care, lost wages, and other applicable amounts are determined by state law/statutes.`,
    },
    elPolicyLimit: {
        title: 'Employers Liability Policy Limit',
        definition: `This responds to claims of employer negligence resulting in the employee's illness or injury. To pursue an EL claim, employees must waive their rights under workers compensation.`,
    },
    elEachEmployeeLimit: {
        title: 'Employers Liability Disease Each Employee',
        definition: undefined,
    },
};
