import {
    defineEntityValidator,
    entity,
    Entity,
    UpdateEntityProps,
} from '@embroker/shotwell/core/entity/Entity';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { CertificateCoverage } from '../types/CertificateCoverage';
import { CertificateDocument } from '../types/CertificateDocument';
import { CertificateEntityRole } from '../types/CertificateEntityRole';
import { CertificateProducer } from '../types/CertificateProducer';

export interface Certificate extends Entity {
    readonly certificateHolder?: CertificateEntityRole;
    readonly certificateOwner: CertificateEntityRole;
    readonly certificateProducer: CertificateProducer;
    readonly coverageList: CertificateCoverage[];
    readonly additionalInsuredName?: string;
    readonly certificateType: string;
    readonly customWording?: string;
    readonly customerAdditionalRequirementsText?: string;
    readonly documentList: CertificateDocument[];
    readonly isIncludedForRenewal?: boolean;
    readonly issuedByCertificateTemplateId?: UUID;
    readonly referenceNumber?: string;
    readonly certificateSharingStatus?: string;
    readonly createdDateTime?: Date;
    readonly completedDateTime?: Date;
    update(certificate: UpdateEntityProps<this>): void;
    markAsShared(): void;
}

interface CertificateUpdated extends DomainEvent {
    readonly origin: 'Certificate';
    readonly name: 'Updated';
}

export interface CertificateShared extends DomainEvent {
    readonly origin: 'Certificate';
    readonly name: 'Shared';
}

export const Certificate = entity<Certificate>({
    validator: defineEntityValidator<Certificate>({
        certificateHolder: CertificateEntityRole.schema.optional(),
        certificateOwner: CertificateEntityRole.schema.required(),
        certificateProducer: CertificateProducer.schema.required(),
        coverageList: Joi.array().items(CertificateCoverage.schema).required(),
        additionalInsuredName: Joi.string().optional().allow(''),
        certificateType: Joi.string().required().allow(''),
        customWording: Joi.string().optional().allow(''),
        customerAdditionalRequirementsText: Joi.string().optional().allow(''),
        documentList: Joi.array().items(CertificateDocument.schema).required(),
        isIncludedForRenewal: Joi.boolean().optional(),
        issuedByCertificateTemplateId: UUID.schema.optional(),
        referenceNumber: Joi.string().optional().allow(''),
        certificateSharingStatus: Joi.string().optional().allow(''),
        createdDateTime: Joi.date().optional(),
        completedDateTime: Joi.date().optional(),
    }),
    update(newCertificate) {
        Object.assign(this.props, newCertificate);
        this.createEvent<CertificateUpdated>('Updated');
    },
    markAsShared() {
        this.createEvent<CertificateShared>('Shared');
    },
});
