import {
    Button,
    Modal,
    ModalActions,
    ModalState,
    RadioGroup,
    StackLayout,
    ColumnLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React, { useState } from 'react';

export type PoliciesSelected = 'all' | 'latest' | 'none';

interface SelectPolicySequenceModalProps {
    modal: ModalActions & ModalState;
    value: PoliciesSelected;
    setValue: (value: PoliciesSelected) => void;
}

export function SelectPolicySequenceModal({
    modal,
    value,
    setValue,
}: SelectPolicySequenceModalProps) {
    const [valueSelected, setValueSelected] = useState<PoliciesSelected>(value);
    return (
        <Modal {...modal} size="small" dismissable={false}>
            <StackLayout gap="32">
                <StackLayout gap="24">
                    <Text style="heading 3">Select policy sequence</Text>
                    <Text>
                        Would you like to receive loss runs for all the terms of this policy or just
                        the most recent one?
                    </Text>
                    <RadioGroup
                        value={valueSelected}
                        items={[
                            {
                                title: 'Most recent (policy term)',
                                value: 'latest',
                            },
                            {
                                title: 'All policy terms',
                                value: 'all',
                            },
                        ]}
                        onChange={(event) => {
                            setValueSelected(event.target.value === 'latest' ? 'latest' : 'all');
                        }}
                    ></RadioGroup>
                </StackLayout>
                <ColumnLayout gap="32">
                    <Button
                        onClick={() => {
                            setValue(valueSelected);
                            modal.hide();
                        }}
                    >
                        Confirm
                    </Button>
                    <TextButton onClick={() => modal.hide()}>Cancel</TextButton>
                </ColumnLayout>
            </StackLayout>
        </Modal>
    );
}
