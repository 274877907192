import { inject } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { Organization } from '../entities/Organization';
import { OrganizationRepository } from '../repositories/OrganizationRepository';

export interface GetOrganizationProfileRequest {
    organizationId: UUID;
}

export interface GetOrganizationProfileResponse {
    organization: Immutable<EntityProps<Organization>>;
}

export interface GetOrganizationProfile extends UseCase {
    execute(
        request: GetOrganizationProfileRequest,
    ): AsyncResult<
        GetOrganizationProfileResponse,
        UnknownEntity | InvalidArgument | OperationFailed
    >;
}

class GetOrganizationProfileUseCase extends UseCase implements GetOrganizationProfile {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/GetOrganizationProfile');
    /**
     * Constructor for GetOrganizationProfile use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param organizationRepository is user repository used to store user entity with updated properties
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(OrganizationRepository) private organizationRepository: OrganizationRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        organizationId,
    }: GetOrganizationProfileRequest): AsyncResult<
        GetOrganizationProfileResponse,
        UnknownEntity | InvalidArgument | OperationFailed
    > {
        const organizationResult = await this.organizationRepository.getOrganization(
            organizationId,
        );

        if (isErr(organizationResult)) {
            return organizationResult;
        }

        return Success({ organization: organizationResult.value.toDTO() });
    }
}

export const GetOrganizationProfile: UseCaseClass<GetOrganizationProfile> =
    GetOrganizationProfileUseCase;
