import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Nullable } from '@embroker/ui-toolkit/v2';

export interface BillingInfoUpdateInput {
    readonly billingEmailAddress: string;
    readonly organizationId: UUID;
}

export interface BillingInfoUpdateResponse {
    readonly id: UUID;
}

export interface BillingInfoGetInput {
    readonly organizationId: UUID;
}

export interface BillingInfoGetResponse {
    readonly id: Nullable<UUID>;
    readonly organizationId: UUID;
    readonly billingEmailAddress?: string;
}

export interface BillingInfoRepository {
    getBillingInfo(
        getBillingInfoInput: BillingInfoGetInput,
    ): AsyncResult<BillingInfoGetResponse, InvalidArgument | OperationFailed>;

    updateBillingInfo(
        updateBillingInfoInput: BillingInfoUpdateInput,
    ): AsyncResult<BillingInfoUpdateResponse, InvalidArgument | OperationFailed>;
}

export const BillingInfoRepository = Symbol('BillingInfoRepository');
