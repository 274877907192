export const ReportColors = {
    grid: '#EBEDFF',
    ESP: '#63B3ED',
    PCo: '#B4E7FC',
    LPL: '#90CDF4',
    Crime: '#2159A2',
    Cyber: '#4299E1',
    noDataIndicator: '#9290BE',
    reportAxis: '#D6D4F4',
    tickText: '#9290BE',
    axistitle: '#9290BE',
    legendLabelColor: '#9290BE',
};
