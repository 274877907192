import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, NotImplemented, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { AsyncResult, FailureResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ErrorCode } from '../errors';
import { SessionRepository } from '../repositories/SessionRepository';
import { UserSignUpEmailProvided } from '../entities/User';
import { UUID } from '@embroker/shotwell/core/types/UUID';

export interface CheckUsernameAvailabilityRequest {
    email: EmailAddress;
}

export interface CheckUsernameAvailabilityResponse {
    available: boolean;
}

export interface CheckUsernameAvailability extends UseCase {
    execute(
        request: CheckUsernameAvailabilityRequest,
    ): AsyncResult<
        CheckUsernameAvailabilityResponse,
        InvalidArgument | NotImplemented | OperationFailed
    >;
}
class CheckUsernameAvailabilityUseCase extends UseCase implements CheckUsernameAvailability {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/CheckUsernameAvailability');
    /**
     * Constructor for CheckUsernameAvailabilityUseCase use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param sessionRepository is session repository used to check user status (password/no-password)
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(SessionRepository) private sessionRepository: SessionRepository,
    ) {
        super(eventBus);
    }
    public async execute({
        email,
    }: CheckUsernameAvailabilityRequest): AsyncResult<
        CheckUsernameAvailabilityResponse,
        InvalidArgument | NotImplemented | OperationFailed
    > {
        const userResult = await this.sessionRepository.getUserStatus(email);
        if (isErr(userResult)) {
            for (const error of userResult.errors) {
                switch (error.code) {
                    case ErrorCode.NoAccount: {
                        const event: UserSignUpEmailProvided = {
                            origin: 'User',
                            name: 'SignUpEmailProvided',
                            createdAt: new Date(Date.now()),
                            id: UUID.create(),
                        };
                        await this.eventBus.publish(event);

                        return Success({ available: true });
                    }
                    case ErrorCode.InactiveAccount:
                        return Success({ available: false });
                    default:
                        break;
                }
            }
            return userResult as FailureResult<InvalidArgument | NotImplemented | OperationFailed>;
        }

        return Success({ available: false });
    }
}

export const CheckUsernameAvailability: UseCaseClass<CheckUsernameAvailability> =
    CheckUsernameAvailabilityUseCase;
