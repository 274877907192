import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Immutable } from '@embroker/shotwell/core/types';
import { Money, USD } from '@embroker/shotwell/core/types/Money';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { PercentageDisplay } from '@embroker/shotwell/view/components/PercentageDisplay';
import {
    BoxLayout,
    Button,
    CardLayout,
    CenterLayout,
    ColumnLayout,
    InvoiceTable,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import { addDays, addMonths, isAfter, isSameDay, subDays } from 'date-fns';
import React, { useContext } from 'react';
import { AppContext } from '../../../../view/AppContext';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { Quote } from '../../../entities/Quote';
import { QuoteOptions } from '../../../repositories/QuoteRepository';

interface QuoteWrapperProps {
    minEffectiveDate: Date;
    quotes: QuoteOptions;
    onQuoteSelected(quote: Quote, dueNow: Money): void;
}

function QuoteWrapper(props: QuoteWrapperProps) {
    const monthlyData = props.quotes.quote10Months;
    const quarterlyData = props.quotes.quote3Months;
    return (
        <ColumnLayout gap="24" responsive={{ containerWidth: { smallerThan: 'tablet' } }}>
            <QuoteDisplay
                data={monthlyData}
                discountPercent={15}
                minEffectiveDate={props.minEffectiveDate}
                onQuoteSelected={props.onQuoteSelected}
                paymentStep="Monthly"
            />
            <QuoteDisplay
                data={quarterlyData}
                discountPercent={30}
                minEffectiveDate={props.minEffectiveDate}
                onQuoteSelected={props.onQuoteSelected}
                paymentStep="Quarterly"
            />
        </ColumnLayout>
    );
}

interface QuoteInput {
    data: Quote;
    discountPercent: number;
    paymentStep: string;
    minEffectiveDate: Date;
    onQuoteSelected(quote: Quote, dueNow: Money): void;
}

export function sum(values: Money[]): Money {
    const sumResult = Money.sum(values);
    if (isOK(sumResult)) {
        return sumResult.value;
    }
    container
        .get<Logger>(Log)
        .error('Unexpected error while calculating the sum', sumResult.errors);

    return USD(Number.NaN);
}

function QuoteDisplay(props: QuoteInput) {
    const data = props.data;
    const monthlyStep = props.paymentStep === 'Monthly' ? 1 : 3;
    let virtualDate = props.minEffectiveDate;

    const dueNow = calculateDueNow(data);

    function calculateVirtualDate() {
        virtualDate = addMonths(virtualDate, monthlyStep);
        virtualDate = subDays(virtualDate, 5);

        return virtualDate;
    }

    function calculateDueNow(quote: Quote): Money {
        let numOfInstallments = 0;
        virtualDate = calculateVirtualDate();

        const today = new Date(Date.now());

        while (isAfter(today, virtualDate) || isSameDay(today, virtualDate)) {
            numOfInstallments++;
            virtualDate = addDays(virtualDate, 5);
            virtualDate = calculateVirtualDate();
        }

        return sum([
            quote.downPayment,
            // TODO: Once that Money.multiply() is implemented, replace this
            // with Money.multiply(quote.installmentAmount, numOfInstallments):
            Money.percentage(quote.installmentAmount, numOfInstallments * 100),
        ]);
    }
    return (
        <CardLayout>
            <BoxLayout data-e2e="fif-quotes-installment-cards-wrapper" gap="24">
                <StackLayout gap="32">
                    <StackLayout center gap="none">
                        <Text style="body 2" color="ui-500">
                            {String(data.numberOfInstallments)} payments of
                        </Text>
                        <Text style="heading 2">
                            <MoneyDisplay value={data.installmentAmount} />
                        </Text>
                        <Text style="body 2" color="ui-500">
                            + <PercentageDisplay value={props.discountPercent} /> down
                        </Text>
                    </StackLayout>
                    <InvoiceTable>
                        <InvoiceTable.Section>
                            <InvoiceTable.Item title="Total annual premiums, taxes and fees">
                                <MoneyDisplay value={data.totalPremium} />
                            </InvoiceTable.Item>
                            <InvoiceTable.Item title="Annual percentage rate">
                                <PercentageDisplay value={data.annualPercentageRate} decimal />
                            </InvoiceTable.Item>
                            <InvoiceTable.Item title="Total loan amount">
                                <MoneyDisplay value={data.totalPaymentAmount} />
                            </InvoiceTable.Item>
                            <InvoiceTable.Separator />
                            <InvoiceTable.Item
                                title={`${props.paymentStep} payment (${data.numberOfInstallments} installments)`}
                            >
                                <MoneyDisplay value={data.installmentAmount} />
                            </InvoiceTable.Item>
                            <InvoiceTable.Item
                                title={`Cash down payments (${props.discountPercent}% down)`}
                            >
                                <MoneyDisplay value={data.downPayment} />
                            </InvoiceTable.Item>
                            <InvoiceTable.Separator />
                            <InvoiceTable.Item title="Due now">
                                <MoneyDisplay value={dueNow} />
                            </InvoiceTable.Item>
                        </InvoiceTable.Section>
                    </InvoiceTable>
                    <CenterLayout>
                        <Button
                            data-e2e="fif-quotes-select-loan-wrapper"
                            appearance="primary"
                            onClick={() => props.onQuoteSelected(data, dueNow)}
                        >
                            Select Loan
                        </Button>
                    </CenterLayout>
                </StackLayout>
            </BoxLayout>
        </CardLayout>
    );
}

export interface QuotesPageDisplayInput {
    eligibilityPageShown: boolean;
    minEffectiveDate: Date;
    quotes: QuoteOptions;
    onQuoteSelected(quote: Quote, dueNow: Money): void;
    selectedInvoiceIdList: Immutable<UUID[]>;
}

export function QuotesPageDisplay(props: QuotesPageDisplayInput) {
    const navi = useNavigation();
    const {
        activeSession: { organizationId },
    } = useContext(AppContext);

    if (organizationId === null) {
        return null;
    }

    const handleBack = () => {
        if (!props.eligibilityPageShown) {
            navi.navigate('/payments');
        } else {
            navi.navigate(
                URI.build('/payments/finance/are-you-eligible', {
                    selectedInvoiceIdList: props.selectedInvoiceIdList.join(','),
                }),
            );
        }
    };

    return (
        <StackLayout gap="24">
            <TextButton size="small" icon="bold-caret-left" onClick={handleBack}>
                Back to Payments
            </TextButton>
            <StackLayout gap="32">
                <Text style="heading 2">Embroker Premium Financing</Text>
            </StackLayout>
            <QuoteWrapper
                minEffectiveDate={props.minEffectiveDate}
                onQuoteSelected={props.onQuoteSelected}
                quotes={props.quotes}
            />
        </StackLayout>
    );
}

export interface QuotesPageInput extends QuotesPageDisplayInput {
    activeStepIndex: number;
}

export function QuotesPage(props: QuotesPageInput) {
    if (props.activeStepIndex !== 0) {
        return null;
    }
    return <QuotesPageDisplay {...props} />;
}
