import { OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult, FailureResult, isErr } from '@embroker/shotwell/core/types/Result';
import { UseCase } from '@embroker/shotwell/core/UseCase';

export type executeReturnType<U extends UseCase> = AsyncResultWrapper<ReturnType<U['execute']>>;
export type errorType<R> = R extends FailureResult<infer E> ? E : never;
export type AsyncResultWrapper<A> = A extends AsyncResult<infer T, infer E>
    ? T extends void
        ? AsyncResult<T, E>
        : AsyncResult<void, E>
    : never;

export function aggregateErrors<U extends UseCase>(
    resultList: Array<PromiseSettledResult<Awaited<executeReturnType<U>>>>,
    message: string,
) {
    return resultList.reduce<(errorType<Awaited<executeReturnType<U>>> | OperationFailed)[]>(
        (acc, curr) => {
            if (curr.status === 'rejected') {
                return [
                    ...acc,
                    OperationFailed({
                        message: message,
                        errors: curr.reason,
                    }),
                ];
            }

            if (isErr(curr.value)) {
                return [
                    ...acc,
                    ...(curr.value.errors as errorType<Awaited<executeReturnType<U>>>[]),
                ];
            }

            return acc;
        },
        [],
    );
}
