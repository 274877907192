import React from 'react';
import { Text, TextButton } from '@embroker/ui-toolkit/v2';
import { ModalLayout } from '@app/view/components/ModalLayout.view';

interface ESPEndorsementErrorModalContentProps {
    onClose: () => void;
    onGoToContactUs: () => void;
    errorMessage?: string;
}
export function ESPEndorsementErrorModalContent({
    onClose,
    onGoToContactUs,
    errorMessage,
}: ESPEndorsementErrorModalContentProps) {
    const modalBody = errorMessage ? (
        errorMessage
    ) : (
        <React.Fragment>
            Pardon the malfunction. We're fixing it. Please try again later or{' '}
            <TextButton onClick={onGoToContactUs}>Contact Embroker</TextButton>.
        </React.Fragment>
    );
    return (
        <ModalLayout
            title="Oops."
            primaryAction={{
                label: 'Cancel',
                onClick: onClose,
            }}
        >
            <Text>{modalBody}</Text>
        </ModalLayout>
    );
}
