import { Joi, defineValidator } from '@embroker/shotwell/core/validation/schema';
import { valueObject } from '@embroker/shotwell/core/types/ValueObject';

export interface QuickLinkTotals {
    totalOpenQuotes?: number;
    totalRenewals?: number;
    totalAccounts?: number;
    totalPayments?: number;
}

export const QuickLinkTotals = valueObject({
    ...defineValidator<QuickLinkTotals>({
        totalOpenQuotes: Joi.number().integer().min(0).optional(),
        totalRenewals: Joi.number().integer().min(0).optional(),
        totalAccounts: Joi.number().integer().min(0).optional(),
        totalPayments: Joi.number().integer().min(0).optional(),
    }),
});
