import React from 'react';

import { Card, Text } from '@embroker/ui-toolkit/v2';

export const EmbrokerPoliciesEmptyList = () => {
    return (
        <Card center className="u-grid-size-12">
            <Text style="body 1">
                There are no policies that fit the chosen criteria available to display.
            </Text>
        </Card>
    );
};
