import React, { useContext, useEffect } from 'react';
import { useCurrentRoute } from 'react-navi';
import { AppContext } from '../AppContext';
import { HeaderView } from './Header.view';

export function Header() {
    const { activeSession, setHeader } = useContext(AppContext);
    const { data } = useCurrentRoute();

    useEffect(() => {
        if (data.hideHeader) {
            return setHeader(null);
        }
        if (activeSession.isAuthenticated) {
            return;
        }
        setHeader(<HeaderView />);
    }, [data.hideHeader, activeSession.isAuthenticated, setHeader]);

    return null;
}
