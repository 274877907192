import {
    BoxLayout,
    Button,
    CardLayout,
    CenterLayout,
    CheckBoxGroup,
    ColumnLayout,
    InvoiceTable,
    StackLayout,
    Text,
    TextButton,
    TextInput,
} from '@embroker/ui-toolkit/v2';
import React, { useEffect, useState } from 'react';
import { PremiumFinanceFlow } from './PremiumFinanceDashboard';

const PremiumFinanceSignaturePage = (props: PremiumFinanceFlow) => {
    const checkBoxValue = 1;
    const [selected, setSelected] = useState([] as number[]);
    const [values, setValues] = useState({
        name: '',
        position: '',
    });
    const [errors, setErrors] = useState(true);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        const id = target.id;
        const value = target.value;
        setValues({ ...values, [id]: value });
    };

    useEffect(() => {
        const { name, position } = values;
        const checkedTermsAndCondition = selected.length > 0 && selected[0] == checkBoxValue;
        if (!name.trim() || !position.trim() || !checkedTermsAndCondition) {
            setErrors(true);
        } else {
            setErrors(false);
        }
    }, [values, selected]);

    return (
        <StackLayout gap="24">
            <TextButton
                size="small"
                data-e2e="fif-signature-back-to-agreement-btn"
                icon="bold-caret-left"
                onClick={props.previous}
            >
                Back to agreement
            </TextButton>
            <StackLayout gap="32">
                <Text style="heading 2">Embroker Premium Financing</Text>
            </StackLayout>
            <ColumnLayout gap="24">
                <CardLayout>
                    <BoxLayout gap="24">
                        <Text style="heading 4">Signature</Text>
                    </BoxLayout>
                    <InvoiceTable.Separator />
                    <BoxLayout data-e2e="fif-signature-checkbox-wrapper" gap="24">
                        <CheckBoxGroup
                            id="group-2"
                            items={[
                                {
                                    title: 'By checking this box, and entering Name and Position constitutes my signature on the Commercial Premium Finance Agreement “PFA“ and certifies that I have read and understood all of the terms and conditions of the PFA relating to the financing of the policy premium(s) listed in this application and the rights of the premium finance company, and I agree to be bound by all of the terms and conditions of this PFA, a copy of which I have printed or saved for my records.',
                                    value: checkBoxValue,
                                },
                            ]}
                            onChange={(e: any) => {
                                setSelected(e.target.value);
                            }}
                            value={selected}
                        />
                    </BoxLayout>
                    <BoxLayout gap="24">
                        <StackLayout gap="32">
                            <ColumnLayout gap="24">
                                <TextInput
                                    data-e2e="fif-signature-full-name"
                                    label="Full Name"
                                    id="name"
                                    onChange={handleChange}
                                    value={values.name}
                                />
                                <TextInput
                                    data-e2e="fif-signature-position"
                                    label="Position"
                                    id="position"
                                    onChange={handleChange}
                                    value={values.position}
                                />
                            </ColumnLayout>
                            <CenterLayout>
                                <Button
                                    data-e2e="fif-signature-continue"
                                    disabled={errors}
                                    onClick={props.next}
                                    appearance="primary"
                                >
                                    Continue
                                </Button>
                            </CenterLayout>
                        </StackLayout>
                    </BoxLayout>
                </CardLayout>
            </ColumnLayout>
        </StackLayout>
    );
};

export const PremiumFinanceSignature = (props: PremiumFinanceFlow) => {
    if (props.activeStepIndex !== 2) {
        return null;
    }

    return <PremiumFinanceSignaturePage {...props} />;
};
