import { Year } from '@embroker/shotwell/core/types/Year';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { PolicyWithClaims } from './PolicyWithClaims';

export interface PoliciesPerYear {
    year: Year;
    policies: PolicyWithClaims[];
}

export const PoliciesPerYear = {
    ...defineValidator<PoliciesPerYear>({
        year: Year.schema,
        policies: Joi.array().items(PolicyWithClaims.schema),
    }),
    create(policiesPerYear: PoliciesPerYear) {
        return PoliciesPerYear.validate(policiesPerYear);
    },
};
