import { Money } from '@embroker/shotwell/core/types/Money';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface InvoiceItem {
    readonly description: string;
    readonly amount: Money;
}

export const InvoiceItem = {
    ...defineValidator<InvoiceItem>({
        description: Joi.string(),
        amount: Money.schema,
    }),
    create(item: InvoiceItem) {
        return InvoiceItem.validate(item);
    },
};
