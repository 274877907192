import * as APIType from '@embroker/shotwell-api/app';
import { Immutable } from '@embroker/shotwell/core/types';
import { BOPChubbQuoteDetails } from './types/BOPChubbQuoteDetails';
import {
    BOPChubbAggregateLimit,
    BOPChubbPerOccurrenceLimit,
    BOPChubbQuoteOptions,
} from './types/BOPChubbQuoteOptions';

export function toBOPChubbQuoteDetails(
    details: Immutable<APIType.BopChubbQuoteDetails>,
): BOPChubbQuoteDetails {
    return {
        coveragePremium: details.coverage_premium,
        expirationDate: details.expiration_date,
        liabilityPremium: details.liability_premium,
        propertyPremium: details.property_premium,
        totalPremium: details.total_premium,
        taxes: details.taxes,
    };
}

export function toBOPChubbQuoteOptions(
    options: APIType.BopChubbQuoteOptions,
): BOPChubbQuoteOptions {
    return {
        perOccurrenceLimit: options.per_occurrence_limit as BOPChubbPerOccurrenceLimit,
        aggregateLimit: options.aggregate_limit as BOPChubbAggregateLimit,
        productCode: options.product_code,
        omitHiredAndNonOwnedAutoCoverage: options.omit_hired_non_owned_auto_coverage,
        isDeselected: options.is_deselected,
        effectiveDate: options.effective_date,
    };
}
