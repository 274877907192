import { inject, injectable } from '@embroker/shotwell/core/di';
import { OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { QuoteRepository } from '../../pcoml/repositories/QuoteRepository';
import { SignaturePacketDocument } from '../../../shopping/types/SignaturePacketDocument';

export interface UploadSignaturePacketRequest {
    applicationId: UUID;
    signaturePacketDocument: SignaturePacketDocument;
}

export interface UploadSignaturePacket extends UseCase {
    execute(request: UploadSignaturePacketRequest): AsyncResult<void, OperationFailed>;
}

@injectable()
export class UploadSignaturePacketUseCase extends UseCase implements UploadSignaturePacket {
    public static type = Symbol('Broker/UploadSignaturePacket');
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(QuoteRepository) private quoteRepository: QuoteRepository,
    ) {
        super(eventBus);
    }

    async execute({
        applicationId,
        signaturePacketDocument,
    }: UploadSignaturePacketRequest): AsyncResult<void, OperationFailed> {
        const result = await this.quoteRepository.uploadSignaturePacket({
            applicationId,
            signaturePacketDocument,
        });

        if (isErr(result)) {
            return Failure(
                OperationFailed({
                    message: 'Failed signature packet document upload',
                }),
            );
        }

        return Success();
    }
}

export const UploadSignaturePacket: UseCaseClass<UploadSignaturePacket> =
    UploadSignaturePacketUseCase;
