import { Joi, defineValidator } from '@embroker/shotwell/core/validation/schema';
import { valueObject } from '@embroker/shotwell/core/types/ValueObject';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Money } from '@embroker/shotwell/core/types/Money';

export interface OutstandingPayment {
    readonly id: UUID;
    readonly companyId: UUID;
    readonly companyName: string;
    readonly policyId?: UUID;
    readonly policyNumber?: string;
    readonly effectiveDate: Date;
    readonly invoiceNumber?: string;
    readonly balance: Money;
}

export const OutstandingPayment = valueObject({
    ...defineValidator<OutstandingPayment>({
        id: UUID.schema,
        companyId: UUID.schema,
        companyName: Joi.string(),
        policyId: UUID.schema.optional(),
        policyNumber: Joi.string().allow('').optional(),
        effectiveDate: Joi.date(),
        invoiceNumber: Joi.string().optional(),
        balance: Money.schema,
    }),
});
