import { ColumnLayout, Form, Immutable, StackLayout } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { CowbellCyberQuote } from '../../../coverageDefinition/cowbellCyber/entities/CowbellCyberQuote';
import { LawCyberQuote } from '../../../coverageDefinition/lawCyber/entities/LawCyberQuote';
import { UnderlyingCoverageProps } from '../BundleQuoteCoverageList';
import { CoverageOptionsTermDefinitions } from '../../../types/enums';
import { toSelectCurrencyOption } from '../../../../quote/toSelectCurrencyOption';

interface CyberBundleCoverageViewProps
    extends UnderlyingCoverageProps<LawCyberQuote | CowbellCyberQuote> {
    readonly cyberLimitOptions: Immutable<number[]>;
    readonly cyberRetentionOptions: Immutable<number[]>;
}
export function CyberBundleCoverageView({
    fields,
    disabled = false,
    cyberLimitOptions,
    cyberRetentionOptions,
}: CyberBundleCoverageViewProps) {
    const isCyberLimitDisabled = cyberLimitOptions.length === 1 || disabled;
    const isCyberRetentionDisabled = cyberRetentionOptions.length === 1 || disabled;
    return (
        <StackLayout gap="12">
            <ColumnLayout gap="16" responsive={{ containerWidth: { smallerThan: 'large-mobile' } }}>
                <Form.Field
                    type={fields.cyberLimit.type}
                    inputProps={{
                        ...fields.cyberLimit.props,
                        filterable: false,
                        items: cyberLimitOptions,
                        label: CoverageOptionsTermDefinitions.limit.title,
                        tooltip: CoverageOptionsTermDefinitions.limit.definition,
                        disabled: isCyberLimitDisabled,
                        createNewItem: toSelectCurrencyOption,
                    }}
                    data-e2e="cyber-limit"
                />
                <Form.Field
                    type={fields.cyberRetention.type}
                    inputProps={{
                        ...fields.cyberRetention.props,
                        filterable: false,
                        items: cyberRetentionOptions,
                        label: CoverageOptionsTermDefinitions.retention.title,
                        tooltip: CoverageOptionsTermDefinitions.retention.definition,
                        disabled: isCyberRetentionDisabled,
                        createNewItem: toSelectCurrencyOption,
                    }}
                    data-e2e="cyber-retention"
                />
            </ColumnLayout>
        </StackLayout>
    );
}
