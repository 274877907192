import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { BrokerRepository } from '../repositories';

export interface UploadBORLetterRequest {
    brokerId: UUID;
    fileKey: string;
    additionalNotes?: string;
    organizationId?: UUID;
}

export interface UploadBORLetter extends UseCase {
    execute(request: UploadBORLetterRequest): AsyncResult<void, InvalidArgument | OperationFailed>;
}

class UploadBORLetterUseCase extends UseCase implements UploadBORLetter {
    public static type = Symbol('Broker/UploadBORLetter');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BrokerRepository) private brokerRepository: BrokerRepository,
    ) {
        super(eventBus);
    }
    public async execute({
        brokerId,
        fileKey,
        additionalNotes,
        organizationId,
    }: UploadBORLetterRequest): AsyncResult<void, InvalidArgument | OperationFailed> {
        return await this.brokerRepository.uploadBORLetter({
            brokerId,
            fileKey,
            additionalNotes,
            organizationId,
        });
    }
}

export const UploadBORLetter: UseCaseClass<UploadBORLetter> = UploadBORLetterUseCase;
