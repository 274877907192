import { getEnvVar } from '../../../env';
import { mount, route } from 'navi';
import * as React from 'react';
import { AppContext } from '../../../view/AppContext';
import { RouteDefinitions } from '../../../view/routes/Route';
import { NotificationCenterPage } from '../components/NotificationCenterPage';

export const routes: RouteDefinitions<AppContext> = getEnvVar(
    'NOTIFICATION_CENTER_FEATURE_FLAG',
    true,
)
    ? {
          '/notification-center': mount<AppContext>({
              '/': route({
                  view: <NotificationCenterPage />,
                  title: 'Embroker | Notification Center',
              }),
          }),
      }
    : {};
