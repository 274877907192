import { State } from '@embroker/shotwell/core/types/StateList';
import { StatusMessage } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { FooterProps } from '../../coverageDefinition';
import { MPLQuote } from '../entities/MPLQuote';

export function MPLCoverageFooter({ questionnaireData }: FooterProps<MPLQuote>) {
    const state = questionnaireData?.locationWithLargestNumber?.state ?? questionnaireData.state;
    const specificRuleStates: Array<State> = ['SD', 'VT', 'NV', 'ND', 'AR'];
    if (!specificRuleStates.includes(state)) {
        return null;
    }
    return (
        <StatusMessage status="helptext">
            A separate Limit for Defense Costs/Claims Expenses equal to the Aggregate Limit is
            required in certain states and is quoted accordingly.
        </StatusMessage>
    );
}
