import { USD } from '@embroker/shotwell/core/types/Money';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { Form as ShotwellForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    BoxLayout,
    Card,
    ColumnLayout,
    Form,
    Icon,
    ReactProps,
    SelectChangeEvent,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import { addDays, addYears, startOfDay, isWithinInterval, startOfToday } from 'date-fns';
import React from 'react';
import { WCGAQuote } from '../../entities/WCGAQuote';
import { WCGAEstimateOptionsFormData } from './WCGAEstimateLandingPage';
import { WOS_PDF_URL } from './wos/constants';

interface WCGAEstimateProps extends ReactProps<'div'> {
    quote: WCGAQuote;
    fields: ShotwellForm<WCGAEstimateOptionsFormData>['fields'];
    trigger: ShotwellForm<WCGAEstimateOptionsFormData>['trigger'];
    setValue: ShotwellForm<WCGAEstimateOptionsFormData>['setValue'];
    value: ShotwellForm<WCGAEstimateOptionsFormData>['value'];
    isSubmitting: boolean;
}

/**
 * Constant sum of money for display in the component
 */
const eachAccident = USD(100000000);
/**
 * Constant sum of money for display in the component
 */
const eachEmployee = USD(100000000);
/**
 * Constant sum of money for display in the component
 */
const totalLimit = USD(100000000);

const DATE_FORMAT = 'MM/dd/yyyy';

/**
 * Helper validation function for date picker
 * @param date Input date for validation
 */
function isDateInValidRange(date: Date) {
    const startRangeDate = startOfToday();
    const endRangeDate = addDays(startRangeDate, 90);
    const selectedDate = startOfDay(date);

    return isWithinInterval(selectedDate, {
        start: startRangeDate,
        end: endRangeDate,
    });
}

export function WCGAEstimateOptions({
    quote,
    trigger,
    setValue,
    fields,
    value,
    isSubmitting,
    className,
}: WCGAEstimateProps) {
    const handleStartDateChange = (event: { target: { value: string } }) => {
        const newDate = new Date(event.target.value);
        const isNewDateDifferentDay = newDate > value.startDate || newDate < value.startDate;
        if (isNewDateDifferentDay) {
            setValue({
                ...value,
                startDate: newDate,
            });
            trigger('update');
        }
    };

    const handleWoSChange = (event: SelectChangeEvent<boolean, false>) => {
        const newWoSValue = event.target.value;
        setValue({
            ...value,
            includeBlanketWoS: newWoSValue === true,
        });
        trigger('update');
    };

    return (
        <div className={className}>
            <StackLayout>
                <Text style="heading 4">When do you want your coverage to begin?</Text>
                <ColumnLayout gap="none">
                    <Form.Field
                        className="u-1/3@desktop u-1/2@tablet u-1/2"
                        type={fields.startDate.type}
                        inputProps={{
                            ...fields.startDate.props,
                            onChange: handleStartDateChange,
                            disabled: isSubmitting,
                            isSelectable: isDateInValidRange,
                        }}
                        messages={fields.startDate.messages}
                    />
                    <BoxLayout>
                        <Text style="heading 5">
                            &ndash;{' '}
                            <DateDisplay
                                format={DATE_FORMAT}
                                value={addYears(value.startDate, 1)}
                            />
                        </Text>
                    </BoxLayout>
                </ColumnLayout>
                <Card isSelected={false} locked={false}>
                    <Card.Header>
                        <Icon name="worker" />
                        <Text style="heading 5">Workers Compensation</Text>
                    </Card.Header>
                    <Card.Body>
                        <StackLayout>
                            <Text>
                                Workers Compensation insurance protects businesses and employees by
                                providing wage replacement and medical benefits to employees who
                                become injured or disabled on the job or as a result of their
                                employment.
                            </Text>
                            <ColumnLayout>
                                <BoxLayout gap="12" className="u-1/2">
                                    <Text style="body 1">Each Accident</Text>
                                    <Text>
                                        <MoneyDisplay value={eachAccident} />
                                    </Text>
                                </BoxLayout>
                                <BoxLayout gap="12" className="u-1/2">
                                    <Text style="body 1">Each Employee</Text>
                                    <Text>
                                        <MoneyDisplay value={eachEmployee} />
                                    </Text>
                                </BoxLayout>
                            </ColumnLayout>
                            <BoxLayout gap="12" className="u-1/2">
                                <Text style="body 1">Total Limit</Text>
                                <Text>
                                    <MoneyDisplay value={totalLimit} />
                                </Text>
                            </BoxLayout>
                            <Text as="span" style="body 1">
                                Estimated annual premium:{' '}
                                <Text as="span" style="heading 5">
                                    <MoneyDisplay value={quote.totalPremium} />
                                </Text>
                            </Text>
                            <br />
                            <ColumnLayout split="-1">
                                <Text as="span" style="label 1">
                                    Blanket Waiver of Subrogation
                                </Text>
                                <TextButton target="_blank" href={WOS_PDF_URL}>
                                    What is the difference?
                                </TextButton>
                            </ColumnLayout>
                            <Form.Field
                                type="select"
                                inputProps={{
                                    value: quote.options.blanketWoS,
                                    filterable: false,
                                    items: [
                                        {
                                            value: true,
                                            title: 'Include Blanket Waiver of Subrogation',
                                        },
                                        {
                                            value: false,
                                            title: 'Exclude Blanket Waiver of Subrogation',
                                        },
                                    ],
                                    label: 'Blanket Waiver of Subrogation',
                                    onChange: handleWoSChange,
                                    disabled: isSubmitting,
                                }}
                            />
                            <Text as="span" style="body 2">
                                (Based on your answers, we recommend you{' '}
                                {quote.options.isWosRecommended ? '' : 'do not'} include it.)
                            </Text>
                        </StackLayout>
                    </Card.Body>
                </Card>
                <Text>Carrier: {quote.details.insurerName}</Text>
                <Text>
                    This is an estimate. Before you can purchase this coverage, one of our
                    underwriters must review your application. They may approve it as-is, update the
                    estimated premium, or suggest alternative coverage.
                    <br />
                    <br />
                    Please click {'"'}Submit for review{'"'} to initiate the underwriter review, and
                    an an Embroker representative will contact you within the next business day.
                </Text>
            </StackLayout>
        </div>
    );
}
