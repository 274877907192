import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { ErrorLike, Failure, isOK } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { ModalActions, useModal } from '@embroker/ui-toolkit/v2';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { EstimateLandingPage } from '../../../view/components/EstimateLandingPage';
import { WCGAQuote } from '../../entities/WCGAQuote';
import { GetLastWCGAQuote } from '../../useCases/GetLastWCGAQuote';
import { GetWCGAQuote } from '../../useCases/GetWCGAQuote';
import { SubmitWCGAQuoteForReview } from '../../useCases/SubmitWCGAQuoteForReview';
import { EstimateThankYouModal } from './EstimateThankYouModal';
import { WCGAEstimateBreakdown } from './WCGAEstimateBreakdown';
import { WCGAEstimateOptions } from './WCGAEstimateOptions';
import { AppContext } from '../../../../view/AppContext';
import { hasRole } from '../../../../userOrg/entities/Session';
import { startOfToday } from 'date-fns/esm';
import { validateEffectiveDate } from '../../../view/components/formValidators';

export interface WCGAEstimateOptionsFormData {
    applicationId: UUID;
    startDate: Date;
    includeBlanketWoS: boolean;
    recommendedWoS: boolean;
}

const MAX_FUTURE_DAYS_ALLOWED = 90;

const makeTitle = (quote: WCGAQuote | undefined): string => {
    const defaultTitle = 'Workers Compensation';

    if (!quote) {
        return '';
    }

    return defaultTitle;
};

const createWCGAQuoteOptionsForm = (
    quote: WCGAQuote | undefined,
    setQuote: (quote: WCGAQuote) => void,
    showThankYouModal: ModalActions['show'],
    isAdmin: boolean,
    abortSignal: AbortSignal,
) => {
    return createForm<WCGAEstimateOptionsFormData>({
        fields: {
            startDate: {
                type: 'date',
                validator: Joi.date()
                    .custom((value, helpers) => {
                        return validateEffectiveDate(
                            value,
                            startOfToday(),
                            MAX_FUTURE_DAYS_ALLOWED,
                            helpers,
                            isAdmin,
                        );
                    })
                    .required(),
                formatValidationError: (error) => {
                    switch (error.details.validator) {
                        case 'date.min':
                            return 'Effective date cannot be in the past.';
                        case 'date.max':
                            return 'Effective date cannot be more than ninety days in the future.';
                        default:
                            return error.message;
                    }
                },
                triggers: {
                    change: 'update',
                },
            },
            includeBlanketWoS: {
                type: 'select',
                validator: Joi.boolean().required(),
                formatValidationError: (error) => {
                    return error.message;
                },
                triggers: {
                    change: 'update',
                },
            },
            recommendedWoS: {
                type: 'hidden',
                validator: Joi.boolean().required(),
            },
            applicationId: {
                type: 'hidden',
                validator: UUID.schema,
            },
        },
        actions: {
            update: async (formData: WCGAEstimateOptionsFormData) => {
                if (!quote) {
                    return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                }

                const updateQuoteResult = await execute(GetWCGAQuote, {
                    applicationId: formData.applicationId,
                    startDate: formData.startDate,
                    includeBlanketWoS: formData.includeBlanketWoS,
                    getRecommended: formData.recommendedWoS,
                    abortSignal: new AbortController().signal,
                });

                if (isOK(updateQuoteResult)) {
                    setQuote(updateQuoteResult.value.quote as WCGAQuote);
                }

                return updateQuoteResult;
            },
            default: async (formData: WCGAEstimateOptionsFormData) => {
                if (!quote) {
                    return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                }

                const submitResult = await execute(SubmitWCGAQuoteForReview, {
                    applicationId: quote.applicationId,
                });

                if (isOK(submitResult)) {
                    showThankYouModal();
                }

                return submitResult;
            },
        },
        onFailure: (errors: Immutable<ErrorLike[]>) => {
            console.error(errors);
        },
    });
};

export interface WCGAEstimateLandingPageProps {
    applicationId: UUID;
}

export const WCGAEstimateLandingPage = function ({ applicationId }: WCGAEstimateLandingPageProps) {
    const { result: lastQuote } = useUseCase(GetLastWCGAQuote, { applicationId });
    const [quote, setQuote] = useState<WCGAQuote>();
    const thankYouModal = useModal();

    const { activeSession } = useContext(AppContext);
    const isAdmin = hasRole(activeSession, 'admin');

    const abortController = useMemo(() => {
        return new AbortController();
    }, []);

    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, [abortController]);

    const optionsFormDefinition = useMemo(
        () =>
            createWCGAQuoteOptionsForm(
                quote,
                setQuote,
                thankYouModal.show,
                isAdmin,
                abortController.signal,
            ),
        [quote, thankYouModal.show, isAdmin, abortController.signal],
    );

    const navigateToErrorPage = useCallback((errors: Immutable<never[]>) => {
        return <ErrorPage errors={errors} />;
    }, []);

    useEffect(() => {
        if (lastQuote != null) {
            if (isOK(lastQuote)) {
                setQuote(lastQuote.value.quote as WCGAQuote);
            } else {
                navigateToErrorPage(lastQuote.errors);
            }
        }
    }, [setQuote, lastQuote, navigateToErrorPage]);

    const optionsForm = useForm(optionsFormDefinition, {
        applicationId: quote?.applicationId,
        includeBlanketWoS: quote?.options.blanketWoS,
        recommendedWoS: quote?.options.isWosRecommended,
        startDate: quote?.options.effectiveDate,
    });

    return (
        <React.Fragment>
            <EstimateLandingPage
                title={makeTitle(quote)}
                applicationId={applicationId}
                quote={quote}
                optionsForm={optionsForm}
                quoteOptions={WCGAEstimateOptions}
                quoteBreakdown={WCGAEstimateBreakdown}
            />
            <EstimateThankYouModal modal={thankYouModal} />
        </React.Fragment>
    );
};
