import { OperationFailed } from '@embroker/shotwell/core/Error';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Spinner } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { navigateToErrorPage } from '../../../../view/errors';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { PCoMLQuote, PCoMLQuoteOptions } from '../../entities/PCoMLQuote';
import { UpdateQuote } from '../../useCases/UpdateQuote';
import { PCoMLQuoteLandingPage } from './PCoMLQuoteLandingPage';

interface PCoMLQuotePageProps {
    applicationId: UUID;
    initialQuote: PCoMLQuote;
    quoteOptions: PCoMLQuoteOptions;
}

export function PCoMLQuoteReQuotePage({
    applicationId,
    initialQuote,
    quoteOptions,
}: PCoMLQuotePageProps) {
    let quote = initialQuote;

    const { navigate } = useNavigation();

    const { result, isLoading } = useUseCase(UpdateQuote, {
        quote: quote,
        quoteOptions: quoteOptions,
    });

    if (isLoading) {
        return <Spinner />;
    }

    if (result) {
        if (isOK(result)) {
            quote = result.value.quote;
            return <PCoMLQuoteLandingPage applicationId={applicationId} initialQuote={quote} />;
        }

        navigateToErrorPage(navigate, result.errors);
        return null;
    }

    navigateToErrorPage(navigate, [
        OperationFailed({ message: 'Update Quote response in undefined' }),
    ]);
    return null;
}
