import { Money } from '@embroker/shotwell/core/types/Money';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    BoxLayout,
    Card,
    ColumnLayout,
    Form,
    Icon,
    ReactProps,
    StackLayout,
    Text,
} from '@embroker/ui-toolkit/v2';
import React, { useCallback, useContext, useMemo } from 'react';
import { Link } from '../../../../view/components';
import { WizardForm } from '../../../../view/hooks/useWizardForm';
import { CNAQuote } from '../../entities/CNAQuote';
import { GeneralLiabilityLimit } from '../../types/CNAQuoteOptions';
import {
    getGeneralLiabilityLimitOptions,
    getHiredAndNonOwnedAutoLimitOptions,
    getPropertyDeductibleOptions,
    perStopGapLiabilityLimitOptions,
    perWindHailDeductibleOptions,
} from './CNAConfig/limitDeductibleOptions';
import { CNAQuoteOptionsFormData } from './CNAQuoteLandingPage';
import { EffectiveDate } from '../../../types/EffectiveDate';
import { startOfToday } from 'date-fns';
import { AppContext } from '../../../../view/AppContext';
import { hasRole } from '../../../../userOrg/entities/Session';

interface KeyPropertyLimits {
    label: string;
    value: Money;
}

type KeyPropertyLimitsList = KeyPropertyLimits[];

interface CNACoveragesPageProps extends ReactProps<'div'> {
    quote: CNAQuote;
    fields: WizardForm<OpaqueForm<CNAQuoteOptionsFormData>>['fields'];
    trigger: WizardForm<OpaqueForm<CNAQuoteOptionsFormData>>['trigger'];
    setValue: WizardForm<OpaqueForm<CNAQuoteOptionsFormData>>['setValue'];
    value: WizardForm<OpaqueForm<CNAQuoteOptionsFormData>>['value'];
    isSubmitting: boolean;
}

const DATE_FORMAT = 'MM/dd/yyyy';
const KEY_PROPERTY_LIMIT_BUILDING_TITLE = 'Building Value';
const KEY_PROPERTY_LIMIT_CONTENTS_TITLE = 'Contents Value';
const KEY_PROPERTY_LIMIT_TENANTS_TITLE = 'Tenants Improvements and Betterments Value';
const KEY_PROPERTY_LIMITS_COLUMNS = 2;
const MAX_FUTURE_DAYS_ALLOWED = 90;

export function CNACoveragePage({
    fields,
    quote,
    value,
    setValue,
    isSubmitting,
    className,
}: CNACoveragesPageProps) {
    const quoteAppInfo = quote.appInfo;
    const quoteContentsLimit = quote.options.contentsLimit.amount;
    const { activeSession } = useContext(AppContext);
    const isAdmin = hasRole(activeSession, 'admin');
    const endDate = useMemo(() => {
        return EffectiveDate.calculateCoverageEndDate(value.startDate);
    }, [value.startDate]);

    const isBuildingLimitEnabled = useMemo(
        () =>
            quoteAppInfo.workSpaceType === 'office_owned' ||
            (quoteAppInfo.workSpaceType === 'office_rented' &&
                quoteAppInfo.isBuildingInsuranceRequired),
        [quoteAppInfo.workSpaceType, quoteAppInfo.isBuildingInsuranceRequired],
    );

    const isTenantsAndBettermentsLimitEnabled = useMemo(
        () => quoteAppInfo.workSpaceType === 'office_rented',
        [quoteAppInfo.workSpaceType],
    );

    const perPropertyDeductibleOptions = useMemo(
        () => getPropertyDeductibleOptions(quoteAppInfo.businessDescription, quoteContentsLimit),
        [quoteContentsLimit, quoteAppInfo.businessDescription],
    );

    const isWindHailDeductibleEnabled = useMemo(
        () => ['FL', 'MS'].includes(quoteAppInfo.usaState) === false,
        [quoteAppInfo.usaState],
    );

    const perGeneralLiabilityLimitOptions = useMemo(
        () => getGeneralLiabilityLimitOptions(quoteAppInfo.businessDescription),
        [quoteAppInfo.businessDescription],
    );

    const isHNOAEnabled = useMemo(
        () =>
            !['65121bu', '65121o', '16471d'].includes(
                quoteAppInfo.businessDescription.toLowerCase(),
            ),
        [quoteAppInfo.businessDescription],
    );

    const perHiredAndNonOwnedAutoLimitOptions = useMemo(
        () =>
            getHiredAndNonOwnedAutoLimitOptions(
                value.generalLiabilityLimit as GeneralLiabilityLimit,
            ),
        [value.generalLiabilityLimit],
    );

    const isStopGapLiabilityEnabled = useMemo(
        () => ['OH', 'WA', 'ND', 'WY'].includes(quoteAppInfo.usaState),
        [quoteAppInfo.usaState],
    );

    const isEffectiveDateSelectable = useCallback(
        (effectiveDate: Date) =>
            EffectiveDate.isSelectedEffectiveDateValid(
                effectiveDate,
                startOfToday(),
                MAX_FUTURE_DAYS_ALLOWED,
                isAdmin,
            ),
        [isAdmin],
    );

    const handleDateChange = (event: { target: { value: string; date: Date } }) => {
        const newDate = new Date(event.target.value);
        const isNewDateDifferentDay = newDate > value.startDate || newDate < value.startDate;
        if (isNewDateDifferentDay) {
            setValue({
                ...value,
                startDate: newDate,
            });
        }
    };

    // Preserve order of limits in this array by request of EM-15321 task
    const keyPropertyLimits: KeyPropertyLimitsList = [];
    if (isBuildingLimitEnabled) {
        keyPropertyLimits.push({
            label: KEY_PROPERTY_LIMIT_BUILDING_TITLE,
            value: quote.options.buildingLimit,
        });
    }
    keyPropertyLimits.push({
        label: KEY_PROPERTY_LIMIT_CONTENTS_TITLE,
        value: quote.options.contentsLimit,
    });

    if (isTenantsAndBettermentsLimitEnabled) {
        keyPropertyLimits.push({
            label: KEY_PROPERTY_LIMIT_TENANTS_TITLE,
            value: quote.options.tenantsAndBettermentsLimit,
        });
    }

    return (
        <StackLayout className={className} gap="32">
            <Text style="heading 4">When do you want your coverage to begin?</Text>
            <ColumnLayout center>
                <Form.Field
                    className="u-1/3@desktop u-1/2@tablet u-1/2"
                    type={fields.startDate.type}
                    inputProps={{
                        ...fields.startDate.props,
                        onChange: handleDateChange,
                        disabled: isSubmitting,
                        isSelectable: isEffectiveDateSelectable,
                    }}
                    messages={fields.startDate.messages}
                />
                <BoxLayout>
                    <Text style="heading 5" data-e2e="end-date">
                        &ndash;{' '}
                        {endDate ? <DateDisplay format={DATE_FORMAT} value={endDate} /> : null}
                    </Text>
                </BoxLayout>
            </ColumnLayout>
            <Card data-e2e="bop-card">
                <Card.Header>
                    <Icon name="user-shield" />
                    <Text style="heading 5">Business Owners Policy</Text>
                </Card.Header>
                <Card.Body>
                    <StackLayout>
                        <Text>
                            A BOP (Business Owner’s Policy) combines liability and property
                            insurance into one policy. BOP insurance helps cover your business from
                            claims resulting from things like fire, theft or other covered
                            disasters. Business owners insurance also helps cover claims that could
                            arise from your business’s operation.
                        </Text>
                        <hr />
                        <Text style="overline">Key Property Limits and Deductibles</Text>
                        <StackLayout gap="24">
                            {renderKeyPropertyLimits(keyPropertyLimits)}
                        </StackLayout>
                        {quote.options.cnaWarnings.buildingLimitWarning && (
                            <Text>
                                <Icon name="status-incomplete" />
                                {quote.options.cnaWarnings.buildingLimitWarning}
                            </Text>
                        )}
                        <ColumnLayout
                            gap="16"
                            responsive={{ screenWidth: { smallerThan: 'tablet' } }}
                        >
                            <Form.Field
                                data-e2e="cna_bop-property_deductible"
                                type={fields.propertyDeductible.type}
                                inputProps={{
                                    ...fields.propertyDeductible.props,
                                    filterable: false,
                                    label: 'Property Deductible',
                                    items: perPropertyDeductibleOptions,
                                    disabled: isSubmitting,
                                }}
                            />
                            {isWindHailDeductibleEnabled ? (
                                <Form.Field
                                    data-e2e="cna_bop-wind_hail_deductible"
                                    type="select"
                                    inputProps={{
                                        ...fields.windHailDeductible.props,
                                        filterable: false,
                                        label: 'Wind/Hail Deductible',
                                        items: perWindHailDeductibleOptions,
                                        disabled: isSubmitting,
                                    }}
                                />
                            ) : null}
                        </ColumnLayout>
                        <hr />
                        <Text style="overline">Key Liability Limits</Text>
                        <ColumnLayout
                            gap="16"
                            responsive={{ screenWidth: { smallerThan: 'tablet' } }}
                        >
                            <Form.Field
                                data-e2e="cna_bop-general_liability_limit"
                                type={fields.generalLiabilityLimit.type}
                                inputProps={{
                                    ...fields.generalLiabilityLimit.props,
                                    filterable: false,
                                    label: 'General Liability Limit',
                                    items: perGeneralLiabilityLimitOptions,
                                    disabled: isSubmitting,
                                }}
                            />
                            {isHNOAEnabled ? (
                                <Form.Field
                                    data-e2e="cna_bop-hired_non-owned_auto_limit"
                                    type={fields.hiredAndNonOwnedAutoLimit.type}
                                    inputProps={{
                                        ...fields.hiredAndNonOwnedAutoLimit.props,
                                        filterable: false,
                                        label: 'Hired and Non-Owned Auto Limit',
                                        items: perHiredAndNonOwnedAutoLimitOptions,
                                        disabled: isSubmitting,
                                    }}
                                />
                            ) : null}
                        </ColumnLayout>
                        {isStopGapLiabilityEnabled ? (
                            <Form.Field
                                type={fields.stopGapLiabilityLimit.type}
                                className="u-1/2"
                                inputProps={{
                                    ...fields.stopGapLiabilityLimit.props,
                                    filterable: false,
                                    label: 'Stop Gap Liability Limit',
                                    items: perStopGapLiabilityLimitOptions,
                                    disabled: isSubmitting,
                                }}
                            />
                        ) : null}
                        {quote.options.cnaWarnings.hiredNonOwnedAutoLimitWarning && (
                            <Text>
                                <Icon name="status-incomplete" />
                                {quote.options.cnaWarnings.hiredNonOwnedAutoLimitWarning}
                            </Text>
                        )}
                        <ColumnLayout split="-1">
                            <Text style="heading 5">Choose Endorsement</Text>
                            <Link
                                href="https://embroker-public.s3.amazonaws.com/Policies/Standard+vs+Enhanced+BOP+coverage.pdf"
                                target="blank"
                            >
                                What's the difference?
                            </Link>
                        </ColumnLayout>
                        <Form.Field
                            type="radioGroup"
                            inputProps={{
                                ...fields.enhancedCoverage.props,
                                items: [
                                    {
                                        value: true,
                                        title: 'Enhanced',
                                        note: value.enhancedCoverage
                                            ? 'Included'
                                            : 'Additional cost',
                                    },
                                    {
                                        value: false,
                                        title: 'Standard',
                                        note: value.enhancedCoverage ? 'Reduced cost' : 'Included',
                                    },
                                ],
                                disabled: isSubmitting,
                                value: value.enhancedCoverage,
                            }}
                        />
                        {fields.enhancedCoverage.props.value}
                    </StackLayout>
                </Card.Body>
                <Card.Footer>
                    <Text as="span" style="body 1">
                        Premium:{' '}
                        <Text as="span" style="heading 5">
                            <MoneyDisplay value={quote.totalPremium} />
                        </Text>
                    </Text>
                </Card.Footer>
            </Card>
        </StackLayout>
    );
}

/**
 * This component will ensure rendering key property limits in KEY_PROPERTY_LIMITS_COLUMNS columns
 * Doing this is workaround since toolkit does not support grid for
 * these kind of components used on this page in this context of usage
 * @param limits list of key property limits
 */
function renderKeyPropertyLimits(limits: KeyPropertyLimitsList) {
    if (limits.length === 0) {
        return null;
    }

    const numOfRows = Math.ceil(limits.length / KEY_PROPERTY_LIMITS_COLUMNS);

    const sortedArray: KeyPropertyLimitsList[] = [];
    for (let i = 0; i < numOfRows; i++) {
        sortedArray.push(limits.splice(0, KEY_PROPERTY_LIMITS_COLUMNS));
    }

    return sortedArray.map((items, index) => {
        return (
            <ColumnLayout key={index}>
                {items.map((limit, limitIndex) => {
                    return (
                        <BoxLayout gap="12" className="u-1/2" key={limitIndex}>
                            <StackLayout gap="none">
                                <Text style="microcopy">{limit.label}</Text>
                                <Text style="body 1">
                                    <MoneyDisplay value={limit.value} />
                                </Text>
                            </StackLayout>
                        </BoxLayout>
                    );
                })}
            </ColumnLayout>
        );
    });
}
