import { Money } from '@embroker/shotwell/core/types/Money';
import { valueObject } from '@embroker/shotwell/core/types/ValueObject';
import { Joi, defineValidator } from '@embroker/shotwell/core/validation/schema';
import { QuoteDetails } from '../../../../quote/entities/Quote';

export interface TotalBasePremiumRange {
    min: number;
    max: number;
}

export interface MPLQuoteDetails extends QuoteDetails {
    readonly total_base_premium?: Money;
    readonly specimen_policy_file_key?: string;
}

export const MPLQuoteDetails = valueObject({
    ...defineValidator<MPLQuoteDetails>({
        total_base_premium: Money.schema.required(),
        specimen_policy_file_key: Joi.string().optional(),
    }),
});
