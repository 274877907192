import { defineEntityValidator, entity } from '@embroker/shotwell/core/entity/Entity';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { SKU } from '../../../analytics/types/SKU';
import { commonQuoteProps, Quote } from '../../entities/Quote';
import { CrimeQuoteDetails } from '../types/CrimeQuoteDetails';
import { CrimeQuoteOptions } from '../types/CrimeQuoteOptions';
import { CrimeUserInfo } from '../types/CrimeUserInfo';

export interface CrimeQuotePurchased extends DomainEvent {
    readonly origin: 'CrimeQuote';
    readonly name: 'Purchased';
    readonly totalPremium: Nullable<Money>;
    readonly applicationId: UUID;
    sku?: SKU;
    readonly isRenewal: boolean;
}

export interface CrimeQuote extends Quote {
    readonly options: CrimeQuoteOptions;
    readonly details: CrimeQuoteDetails;
    readonly isRenewal: boolean;
    readonly userInfo?: CrimeUserInfo;
}

export const CrimeQuote = entity<CrimeQuote, Quote>({
    validator: defineEntityValidator<CrimeQuote>({
        ...commonQuoteProps,
        options: CrimeQuoteOptions.schema.required(),
        isRenewal: Joi.boolean().required(),
        details: CrimeQuoteDetails.schema.required(),
        userInfo: CrimeUserInfo.schema.optional(),
    }),
    inherits: Quote,
});
