import { errorCodes } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { ErrorLike, ErrorObject } from '@embroker/shotwell/core/types/Result';

export const ErrorCode = errorCodes({
    GetGlobalConfigFailed: 0xb500,
});

export type GetGlobalConfigFailed = ErrorObject<typeof ErrorCode.GetGlobalConfigFailed, {}>;

export function GetGlobalConfigFailed(
    innerErrorList: Immutable<ErrorLike[]>,
): Immutable<GetGlobalConfigFailed> {
    return {
        name: 'GetGlobalConfigFailed',
        code: ErrorCode.GetGlobalConfigFailed,
        message: 'Failed to get global config.',
        details: {
            innerErrorList,
        },
    };
}
