import {
    API,
    Claim as APIClaim,
    Policy as APIPolicy,
    PolicyGetClaimDocumentsRequest,
    PolicyUserPolicyRequest,
} from '@embroker/shotwell-api/app';
import { ClaimStatusCodeListItem, LineOfBusinessCodeList } from '@embroker/shotwell-api/enums';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ClaimRepository, CreateClaimRequest, GetClaimsForPolicyResponse } from '.';
import { Claim, Claimant } from '../../entities/Claim';
import { ClaimDocument } from '../../types/ClaimDocument';
import { PhoneNumber } from '@embroker/shotwell/core/types/PhoneNumber';
import { InvalidClaimRequestSave } from '../../errors';

@injectable()
export class APIClaimRepository implements ClaimRepository {
    public async getClaims(): AsyncResult<Map<UUID, Claim[]>, InvalidArgument | OperationFailed> {
        const userPoliciesResult = await API.request('policy/user_policies_full');
        if (isErr(userPoliciesResult)) {
            return handleOperationFailure(userPoliciesResult);
        }
        return this.createClaimList(
            (userPoliciesResult.value as APIPolicy[])
                .filter((policy) => policy.claim_list && policy.claim_list.length > 0)
                .map((policy) => [policy.id, policy.claim_list] as [UUID, APIClaim[]]),
        );
    }

    public async getClaimsForPolicy(
        policyId: UUID,
    ): AsyncResult<GetClaimsForPolicyResponse, InvalidArgument | UnknownEntity | OperationFailed> {
        const request: PolicyUserPolicyRequest = {
            id: policyId,
        };
        const result = await API.request('policy/user_policy', request);
        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        const lineOfBusiness = LineOfBusinessCodeList.find(
            (item) => item === result.value.line_of_business,
        );

        if (lineOfBusiness === undefined) {
            return Failure(
                InvalidArgument({
                    argument: 'line_of_business',
                    value: result.value.line_of_business,
                }),
            );
        }

        const policy = result.value;

        const response: GetClaimsForPolicyResponse = {
            claimsList: [],
            insurerName: policy.insurer.name,
            policyNumber: policy.policy_num,
            startDate: policy.effective_period_start,
            endDate: policy.effective_period_end,
            lineOfBusiness,
        };

        for (const apiClaim of policy.claim_list) {
            const claimResult = await this.createClaim(apiClaim as APIClaim);

            if (isErr(claimResult)) {
                return handleOperationFailure(claimResult);
            }
            response.claimsList.push(claimResult.value as Claim);
        }

        return Success(response);
    }

    public async getClaimDocuments(
        claimId: UUID,
    ): AsyncResult<ClaimDocument[], InvalidArgument | UnknownEntity | OperationFailed> {
        const request: PolicyGetClaimDocumentsRequest = {
            claim_id: claimId,
        };
        const apiResult = await API.request('policy/get_claim_documents', request);
        if (isErr(apiResult)) {
            return handleOperationFailure(apiResult);
        }

        const documents = apiResult.value;
        const claimDocuments = [] as ClaimDocument[];
        for (const document of documents) {
            const claimDocumentData = {
                id: document.id,
                name: document.name ?? 'unnamed',
                fileKey: document.file_key,
                type: document.type,
                size: document.size ?? 0,
                mimeType: document.mime_type,
            };

            const claimDocumentResult = ClaimDocument.create(claimDocumentData);
            if (isErr(claimDocumentResult)) {
                return handleOperationFailure(claimDocumentResult);
            }

            claimDocuments.push(claimDocumentResult.value);
        }
        return Success(claimDocuments);
    }

    private async createClaimList(
        apiClaimsWithPolicyId: [UUID, APIClaim[]][],
    ): AsyncResult<Map<UUID, Claim[]>, InvalidArgument | OperationFailed> {
        const claimsResult = new Map<UUID, Claim[]>();
        for (const [policyId, apiClaims] of apiClaimsWithPolicyId) {
            const claims: Claim[] = [];
            for (const apiClaim of apiClaims) {
                const claimResult = await this.createClaim(apiClaim);
                if (isErr(claimResult)) {
                    return handleOperationFailure(claimResult);
                }
                claims.push(claimResult.value as Claim);
            }
            claimsResult.set(policyId, claims);
        }
        return Success(claimsResult);
    }

    private async createClaim(
        apiClaim: APIClaim,
    ): AsyncResult<Claim, InvalidArgument | OperationFailed> {
        const claimantList: Claimant[] = [];
        for (const apiClaimant of apiClaim.claimant_list) {
            const claimant = await Claimant.create({
                id: apiClaimant.id,
                name: apiClaimant.name,
            });
            if (isErr(claimant)) {
                return handleOperationFailure(claimant);
            }
            claimantList.push(claimant.value);
        }

        const claimData = {
            id: apiClaim.id,
            claimNumber: apiClaim.claim_number,
            lossDate: apiClaim.loss_date,
            reportedDate: apiClaim.reported_date,
            closedDate: apiClaim.closed_date,
            valuationDate: apiClaim.valuation_date,
            status: apiClaim.status as ClaimStatusCodeListItem,
            amountPaid: apiClaim.amount_paid,
            adjusterName: apiClaim.adjuster_name,
            adjusterPhoneNumber: apiClaim.adjuster_phone_number,
            reservedAmount: apiClaim.reserved_amount,
            causeOfLoss: apiClaim.cause_of_loss,
            claimantList: claimantList,
        };

        const claimResult = await Claim.create(claimData);
        if (isErr(claimResult)) {
            return handleOperationFailure(claimResult);
        }
        return claimResult;
    }

    public async createClaimRequest(
        claimRequest: CreateClaimRequest,
    ): AsyncResult<UUID, InvalidClaimRequestSave | InvalidArgument> {
        const phoneNumberResult = PhoneNumber.create(claimRequest.contactPersonPhoneNumber);
        if (isErr(phoneNumberResult)) {
            return phoneNumberResult;
        }

        const claimRequestResult = await API.request('policy/user_create_claim_request', {
            policy_id: claimRequest.policyId,
            submitter_name: claimRequest.submitterName,
            contact_person_name: claimRequest.contactPersonName,
            contact_person_email: claimRequest.contactPersonEmail,
            contact_person_phone: phoneNumberResult.value,
            description: claimRequest.description,
            document_list: claimRequest.documentList.map((doc) => {
                return {
                    name: doc.name,
                    file_key: doc.file_key,
                };
            }),
        });
        if (isErr(claimRequestResult)) {
            return Failure(InvalidClaimRequestSave(claimRequest.policyId));
        }
        return Success(claimRequestResult.value.claim_request_id);
    }
}
