import { ModalLayout } from '@app/view/components/ModalLayout.view';
import React from 'react';

interface ESPEndorsementInvoiceDoneModalContentProps {
    onClose: () => void;
}

export function ESPEndorsementInvoiceDoneModalContent({
    onClose,
}: ESPEndorsementInvoiceDoneModalContentProps) {
    return (
        <ModalLayout
            title="Your invoice is on the way!"
            primaryAction={{
                label: 'Got It',
                onClick: onClose,
            }}
        >
            You will receive an email with payment instructions shortly. Your digital policies will
            always be available in your Embroker account.
        </ModalLayout>
    );
}
