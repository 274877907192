import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { CyberConfig } from '../types/CyberConfig';
import { CyberConfigRepository } from '../repositories/CyberConfigRepository';

export interface GetCyberConfig extends UseCase {
    execute(): AsyncResult<CyberConfig, InvalidArgument | OperationFailed>;
}

@injectable()
class GetCyberConfigUseCase extends UseCase implements GetCyberConfig {
    public static type = Symbol('CyberQuote/GetCyberConfig');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(CyberConfigRepository) private cyberConfigRepository: CyberConfigRepository,
    ) {
        super(eventBus);
    }

    public async execute(): AsyncResult<CyberConfig, InvalidArgument | OperationFailed> {
        return await this.cyberConfigRepository.getCyberConfig();
    }
}

export const GetCyberConfig: UseCaseClass<GetCyberConfig> = GetCyberConfigUseCase;
