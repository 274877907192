import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Props } from '@embroker/shotwell/core/types';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { Location } from '@embroker/shotwell/core/types/Location';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Result,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { State } from '@embroker/shotwell/core/types/StateList';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { SKU } from '../../analytics/types/SKU';
import { CalculateSKUFromShareablePolicies } from '../../analytics/useCases/CalculateSKUFromShareablePolicies';
import { Certificate } from '../entities/Certificate';
import { CertificateRepository } from '../repositories/CertificateRepository';
import { CertificateDocument } from '../types/CertificateDocument';
import { CertificateEntityRole, CertificateEntityRoleInput } from '../types/CertificateEntityRole';
import { CertificateProducer } from '../types/CertificateProducer';
import { SelfServingCertificateCoverageList } from '../types/SelfServingCertificateCoverage';

/**
 * Request data for ShareCertificate use case
 * It contains certificate object to be shared
 */
export interface ShareCertificateRequest {
    /**
     * Certificate which will be shared
     * It has all data filled, coverages, holder, owner, producer etc.
     */
    certificateOwner: Props<CertificateEntityRole>;
    certificateProducer: Props<CertificateProducer>;
    companyName: string;
    email?: EmailAddress;
    mailingAddress: string;
    mailingAddressCont?: string;
    city: string;
    state: State;
    zipCode: ZipCode;
    referenceNumber?: string;
    renewal: boolean;
    certificateTemplateId?: UUID;
    additionalInsuredName?: string;
    additionalInstructions?: string;
    specialWording?: string;
    documentList: CertificateDocument[];
    isSelfServing?: boolean;
    selectedCoverageList: SelfServingCertificateCoverageList;
}

export interface SelfServingCertificateRequestSubmittedEvent extends DomainEvent {
    origin: 'Certificates';
    name: 'SelfServingCertificateRequestSubmittedEvent';
    additionalWordingText?: string;
    isPdfAttached: boolean;
    sku?: SKU;
}

/**
 * Certificate share use case is used to authenticate user to the platform
 */
export interface ShareCertificate extends UseCase {
    execute(request: ShareCertificateRequest): AsyncResult<void, InvalidArgument | OperationFailed>;
}

@injectable()
class ShareCertificateUseCase extends UseCase implements ShareCertificate {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Certificates/ShareCertificate');

    /**
     * Constructor for ShareCertificate use case class instance
     * @param eventBus An event bus this Use Case will publish events to.
     * @param certificateRepo repo that will be used to create a certificate share
     * @param calculateSKUFromShareablePolicies useCase that will return calculated skus data for
     * self serving certificate share event
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(CertificateRepository) private certificateRepo: CertificateRepository,
        @inject(CalculateSKUFromShareablePolicies.type)
        private calculateSKUFromShareablePolicies: CalculateSKUFromShareablePolicies,
    ) {
        super(eventBus);
    }

    /**
     * Executes the ShareCertificate use case.
     * Input is of type ShareCertificateRequest
     * @returns InvalidArgument if one of properties in ShareCertificate was not valid
     */
    public async execute(
        data: ShareCertificateRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed> {
        const certificateData = await createCertificateData(data);

        if (isErr(certificateData)) {
            return handleOperationFailure(certificateData);
        }

        const result = await this.certificateRepo.sendSelfServingCertificateRequest({
            certificate: certificateData.value as Certificate,
            selfServingCoverageList: data.selectedCoverageList,
        });

        if (isErr(result)) {
            return Failure(InvalidArgument({ argument: 'Certificate entity', value: data }));
        }

        const certificate: Certificate = certificateData.value as Certificate;
        certificate.markAsShared();

        const skuResult = await this.calculateSKUFromShareablePolicies.execute(
            data.selectedCoverageList,
        );

        this.eventBus.publishEntityEvents(certificate);

        if (data.isSelfServing) {
            const selfServingCertificateCreated: SelfServingCertificateRequestSubmittedEvent = {
                id: UUID.create(),
                origin: 'Certificates',
                name: 'SelfServingCertificateRequestSubmittedEvent',
                createdAt: new Date(Date.now()),
                additionalWordingText: data.specialWording,
                isPdfAttached: data.documentList.length > 0,
                sku: skuResult.value,
            };
            await this.eventBus.publish(selfServingCertificateCreated);
        }

        return Success();
    }
}

export const ShareCertificate: UseCaseClass<ShareCertificate> = ShareCertificateUseCase;

// Creates location object for certificate holder, owner, and producer
const createLocation = (data: Props<Location>): Result<Location | InvalidArgument> => {
    const result = Location.create({
        type: 'other',
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        city: data.city,
        state: data.state,
        zip: data.zip,
        phoneNumber: data.phoneNumber,
    } as Location);

    if (isErr(result)) {
        return handleOperationFailure(result);
    }

    return Success(result.value);
};

// Creates certificate holder object
const createCertificateHolder = (
    data: ShareCertificateRequest,
): Result<CertificateEntityRole | InvalidArgument> => {
    const locationData: Props<Location> = {
        type: 'other',
        addressLine1: data.mailingAddress,
        addressLine2: data.mailingAddressCont ?? null,
        city: data.city,
        state: data.state,
        zip: data.zipCode,
        phoneNumber: null,
    };
    const holderLocation = createLocation(locationData);

    if (isErr(holderLocation)) {
        return handleOperationFailure(holderLocation);
    }

    const result = CertificateEntityRole.create({
        roleType: 'Holder',
        organizationName: data.companyName,
        organizationEmail: data.email,
        userEmail: data.email,
        location: holderLocation.value,
    } as CertificateEntityRoleInput);

    if (isErr(result)) {
        return handleOperationFailure(result);
    }

    return Success(result.value);
};

// Creates certificate owner object
const createCertificateOwner = (
    data: Props<CertificateEntityRole>,
): Result<CertificateEntityRole | InvalidArgument> => {
    if (data.location === undefined) {
        return Failure(OperationFailed({ message: 'Owner location must not be null' }));
    }

    const locationData: Props<Location> = {
        type: data.location.type,
        addressLine1: data.location.addressLine1,
        addressLine2: data.location.addressLine2,
        city: data.location.city,
        state: data.location.state,
        zip: data.location.zip,
        phoneNumber: data.location.phoneNumber,
    };
    const ownerLocation = createLocation(locationData);

    if (isErr(ownerLocation)) {
        return handleOperationFailure(ownerLocation);
    }

    const result = CertificateEntityRole.create({
        roleType: 'Owner',
        organizationId: data.organizationId,
        organizationName: data.organizationName,
        organizationEmail: data.organizationEmail,
        userId: data.userId,
        userEmail: data.userEmail,
        location: ownerLocation.value,
        signedUp: data.signedUp,
        token: data.token,
        suppressEmailNotifications: data.suppressEmailNotifications,
    } as CertificateEntityRoleInput);

    if (isErr(result)) {
        return handleOperationFailure(result);
    }

    return Success(result.value);
};

// Creates certificate producer object
const createCertificateProducer = (
    data: Props<CertificateProducer>,
): Result<CertificateProducer | InvalidArgument> => {
    const locationData: Props<Location> = {
        type: data.location.type,
        addressLine1: data.location.addressLine1,
        addressLine2: data.location.addressLine2,
        city: data.location.city,
        state: data.location.state,
        zip: data.location.zip,
        phoneNumber: data.location.phoneNumber,
    };
    const producerLocation = createLocation(locationData);

    if (isErr(producerLocation)) {
        return handleOperationFailure(producerLocation);
    }

    const result = CertificateProducer.create({
        certificateCreator: data.certificateCreator,
        contactName: data.contactName,
        name: data.name,
        email: data.email,
        location: producerLocation.value,
    } as CertificateProducer);

    if (isErr(result)) {
        return handleOperationFailure(result);
    }

    return Success(result.value);
};

// Creates certificate entity to be shared
const createCertificateData = async (
    data: ShareCertificateRequest,
): AsyncResult<Certificate | InvalidArgument> => {
    const certificateHolder = createCertificateHolder(data);

    if (isErr(certificateHolder)) {
        return handleOperationFailure(certificateHolder);
    }

    const certificateOwner = createCertificateOwner(data.certificateOwner);

    if (isErr(certificateOwner)) {
        return handleOperationFailure(certificateOwner);
    }

    const certificateProducer = createCertificateProducer(data.certificateProducer);

    if (isErr(certificateProducer)) {
        return handleOperationFailure(certificateProducer);
    }

    const result = await Certificate.create({
        certificateHolder: certificateHolder.value as CertificateEntityRole,
        certificateOwner: certificateOwner.value as CertificateEntityRole,
        certificateProducer: certificateProducer.value as CertificateProducer,
        coverageList: [],
        certificateType: 'CertificateTypeCodeListSharedByOwner',
        customWording: data.specialWording,
        customerAdditionalRequirementsText: data.additionalInstructions,
        documentList: data.documentList,
        isIncludedForRenewal: data.renewal,
        referenceNumber: data.referenceNumber,
    });

    if (isErr(result)) {
        return handleOperationFailure(result);
    }

    return Success(result.value);
};
