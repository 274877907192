import { valueObject } from '@embroker/shotwell/core/types/ValueObject';
import { defineValidator } from '@embroker/shotwell/core/validation/schema';
import { ExcessTechEOCyber } from './ExcessRestriction';

export interface AdditionalQuestions {
    excessTechEOCyber?: ExcessTechEOCyber;
}

export const AdditionalQuestions = valueObject({
    ...defineValidator<AdditionalQuestions>({
        excessTechEOCyber: ExcessTechEOCyber.schema.optional(),
    }),
});
