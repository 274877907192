import { Money } from '@embroker/shotwell/core/types/Money';
import { State } from '@embroker/shotwell/core/types/StateList';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import {
    PCoMLEndorsementLiabilityCoverageType,
    PCoMLEndorsementLiabilityCoverageTypesList,
} from './PCoMLEndorsementLiabilityCoverageType';
import { UUID } from '@embroker/shotwell/core/types/UUID';

export interface PCoMLEndorsementPolicyAddressData {
    addressLine1: string;
    addressLine2?: string;
    city: string;
    state?: State;
    zipCode: ZipCode;
}

const PCoMLEndorsementPolicyAddressData = {
    ...defineValidator<PCoMLEndorsementPolicyAddressData>({
        addressLine1: Joi.string().required(),
        addressLine2: Joi.string().optional().allow(''),
        city: Joi.string().required(),
        state: State.schema.optional(),
        zipCode: ZipCode.schema.required(),
    }),
};

export interface PCoMLEndorsementLiabilityCoverage {
    limit?: Money;
    premium?: Money;
    retention?: Money;
    typeCode: PCoMLEndorsementLiabilityCoverageType;
}

const PCoMLEndorsementLiabilityCoverage = {
    ...defineValidator<PCoMLEndorsementLiabilityCoverage>({
        limit: Money.schema.optional(),
        premium: Money.schema.optional(),
        retention: Money.schema.optional(),
        typeCode: Joi.string()
            .valid(...PCoMLEndorsementLiabilityCoverageTypesList)
            .required(),
    }),
};

export interface PCoMLEndorsementPolicy {
    id: UUID;
    effectivePeriodStart: Date;
    effectivePeriodEnd: Date;
    namedInsured: string;
    addressData: PCoMLEndorsementPolicyAddressData;
    availableLiabilities: Array<PCoMLEndorsementLiabilityCoverage>;
    submittedAt?: Date;
    isRenewal: boolean;
    lastEndorsementDate?: Date;
}

export const PCoMLEndorsementPolicy = {
    ...defineValidator<PCoMLEndorsementPolicy>({
        id: UUID.schema.required(),
        effectivePeriodStart: Joi.date().required(),
        effectivePeriodEnd: Joi.date().required(),
        namedInsured: Joi.string().required(),
        addressData: PCoMLEndorsementPolicyAddressData.schema,
        availableLiabilities: Joi.array().items(PCoMLEndorsementLiabilityCoverage.schema),
        submittedAt: Joi.date().optional(),
        isRenewal: Joi.boolean().required(),
        lastEndorsementDate: Joi.date().optional(),
    }),
    create(pcomlEndorsementPolicy: PCoMLEndorsementPolicy) {
        return PCoMLEndorsementPolicy.validate(pcomlEndorsementPolicy);
    },
};
