import { container, ContainerModule } from '@embroker/shotwell/core/di';
import { Module } from '../../Module';
import { AppContext } from '../../view/AppContext';
import { GoogleAnalyticsRepository } from '../repositories/GoogleAnalyticsRepository';
import { APIGoogleAnalyticsRepository } from '../repositories/GoogleAnalyticsRepository/APIGoogleAnalyticsRepository';
import { SKURepository } from '../repositories/SKURepository';
import { APISKURepository } from '../repositories/SKURepository/APISKURepository';
import { CalculateSKU } from '../useCases/CalculateSKU';
import { CalculateSKUFromAppTypes } from '../useCases/CalculateSKUFromAppTypes';
import { CalculateSKUFromShareablePolicies } from '../useCases/CalculateSKUFromShareablePolicies';
import { GoogleAnalyticsService } from './GoogleAnalyticsService';
import { IndustrySearchAnalyticsService } from './IndustrySearchAnalyticsService';

/**
 * Interface for the analytics service
 */
export interface AnalyticsService {
    /**
     * Subscribes the service instance to domain events of interest
     * for analytics purposes.
     */
    subscribeToEvents(): void;
}

export const AnalyticsService = Symbol('AnalyticsService');

/**
 * Analytics module
 */
export const AnalyticsModule: Module = {
    container: new ContainerModule((bind) => {
        bind<AnalyticsService>(AnalyticsService).to(GoogleAnalyticsService).inSingletonScope();
        bind<GoogleAnalyticsRepository>(GoogleAnalyticsRepository)
            .to(APIGoogleAnalyticsRepository)
            .inSingletonScope();
        bind<IndustrySearchAnalyticsService>(IndustrySearchAnalyticsService)
            .to(IndustrySearchAnalyticsService)
            .inSingletonScope();
        bind<SKURepository>(SKURepository).to(APISKURepository).inSingletonScope();
        bind<CalculateSKU>(CalculateSKU.type).to(CalculateSKU).inSingletonScope();
        bind<CalculateSKUFromAppTypes>(CalculateSKUFromAppTypes.type)
            .to(CalculateSKUFromAppTypes)
            .inSingletonScope();
        bind<CalculateSKUFromShareablePolicies>(CalculateSKUFromShareablePolicies.type)
            .to(CalculateSKUFromShareablePolicies)
            .inSingletonScope();
    }),
    bootstrap: async () => {
        const analyticsService = container.get<AnalyticsService>(AnalyticsService);
        analyticsService.subscribeToEvents();
        const industrySearchService = container.get<IndustrySearchAnalyticsService>(
            IndustrySearchAnalyticsService,
        );
        industrySearchService.subscribeToEvents();
        return {} as AppContext;
    },
};
