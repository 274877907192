import { Money } from '@embroker/shotwell/core/types/Money';
import { Immutable } from '@embroker/ui-toolkit/v2';

export const toSelectCurrencyOption = (
    value: string | number,
): Immutable<{ value: string | number; title: string }> => {
    const moneyValue = Money.tryFromFloat(Number(value));

    return Money.isValid(moneyValue) ? Money.toOption(moneyValue) : { value, title: String(value) };
};
