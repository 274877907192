import { injectable } from '@embroker/shotwell/core/di';
import { FeatureRolloutRepository, GetEnrolledUserFeaturesResponse } from './index';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { API } from '@embroker/shotwell-api/app';
import { GetUserEnrolledFeaturesError } from '../errors';

@injectable()
export class APIFeatureRolloutRepository implements FeatureRolloutRepository {
    public async getUserEnrolledFeatures(): AsyncResult<
        GetEnrolledUserFeaturesResponse,
        GetUserEnrolledFeaturesError
    > {
        const apiResult = await API.request('global/get_enrolled_user_features');
        if (isErr(apiResult)) {
            return Failure(GetUserEnrolledFeaturesError());
        }

        return Success({ featuresEnrolled: apiResult.value.features_enrolled });
    }
}
