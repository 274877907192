import { FormElement } from '@embroker/service-app-engine';
import React, { useCallback, useMemo } from 'react';
import { useNavigation } from '../../hooks/useNavigation';
import {
    ProgressBar,
    ProgressBarRangeType,
    ProgressBarTitleRenderer,
} from './components/ProgressBar';
import { PlainFormEngineProps } from '../PlainFormEngine';
import { WizardLayoutAppearance } from '@embroker/ui-toolkit/v2';

interface WithProgressBarProps {
    WrappedComponent: React.FC<PlainFormEngineProps>;
    instance: FormElement;
    onExitFullScreen?: () => void;
    progressBarRange?: ProgressBarRangeType;
    progressBarTitleRenderer?: ProgressBarTitleRenderer;
    /**
     * If inWizard true, this will change the dismiss appearance,
     * There are 2 options: Using the close Icon ("X") or "Save and Exit" wording
     * The close icon is default behavior
     */
    dismissAppearance?: 'close-icon' | 'save-and-exit';
    footer?: React.ReactNode;
    footerAppearance?: WizardLayoutAppearance;
}

export const WithProgressBar = function WithProgressBar({
    WrappedComponent,
    instance,
    onExitFullScreen,
    progressBarRange,
    progressBarTitleRenderer,
    dismissAppearance = 'close-icon',
    footer,
    footerAppearance,
    ...props
}: WithProgressBarProps) {
    const ConnectedProgressBar = useMemo(() => ProgressBar.connect(instance.machine), [instance]);

    const { navigate } = useNavigation();

    const closeFullScreen = useCallback(() => {
        if (onExitFullScreen) {
            return onExitFullScreen();
        }
        navigate('/summary');
    }, [navigate, onExitFullScreen]);

    return function WithProgressBar(props: PlainFormEngineProps) {
        return (
            <ConnectedProgressBar
                onDismiss={closeFullScreen}
                progressBarRange={progressBarRange}
                progressBarTitleRenderer={progressBarTitleRenderer}
                dismissAppearance={dismissAppearance}
                footer={footer}
                footerAppearance={footerAppearance}
            >
                <WrappedComponent {...props} />
            </ConnectedProgressBar>
        );
    };
};
