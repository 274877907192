import { UUID } from '@embroker/shotwell/core/types/UUID';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

/**
 * Possible roles a user can have.
 */
export type UserRoleType = 'owner' | 'collaborator';

/**
 * A role of a user in an organization.
 */
export interface UserRole {
    /**
     * The user identifier.
     */
    readonly userId: UUID;
    /**
     * The role of the user in an organization.
     */
    readonly role: UserRoleType;
}

export const UserRole = defineValidator<UserRole>({
    userId: UUID.schema,
    role: Joi.string().valid('owner', 'collaborator'),
});
