import { Text, Tooltip } from '@embroker/ui-toolkit/v2';
import React, { useEffect, useState } from 'react';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { GetPolicyTransferRequestSignedDate } from '../../../useCases/GetPolicyTransferRequestSignedDate';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Nullable } from '@embroker/shotwell/core/types/';

export interface TransferRequestSignedDateProps {
    policyId: UUID;
}

export const TransferRequestSignedDate = ({ policyId }: TransferRequestSignedDateProps) => {
    const { result: transferRequestSignedDateResult } = useUseCase(
        GetPolicyTransferRequestSignedDate,
        policyId,
    );

    const [signedDate, setSignedDate] = useState<Nullable<Date>>();

    useEffect(() => {
        if (!transferRequestSignedDateResult) {
            return;
        }
        if (isErr(transferRequestSignedDateResult)) {
            container.get<Logger>(Log).error(transferRequestSignedDateResult.errors);
            return;
        }
        setSignedDate(transferRequestSignedDateResult.value as Nullable<Date>);
    }, [transferRequestSignedDateResult]);

    return signedDate ? (
        <Text nowrap style="microcopy" color="ui-400">
            Processing the transfer
            <Tooltip
                iconSize="small"
                text={`We received your transfer request on ${signedDate.toDateString()} and you will be notified about transfer status in up to 15 business days.`}
            />
        </Text>
    ) : null;
};
