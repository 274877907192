import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    Button,
    ButtonBar,
    ColumnLayout,
    Form,
    InvoiceTable,
    Modal,
    ReactProps,
    StackLayout,
    Text,
    TextButton,
    useModal,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { WizardForm } from '../../../../view/hooks/useWizardForm';
import { WarrantyAndFraudModalContent } from '../../../view/components/signature/WarrantyAndFraudModalContent';
import { SignInsuranceApplicationCheckbox } from '../../../view/components/signature/SignInsuranceApplicationCheckbox';
import { BOPHartfordQuote } from '@app/quote/hartford-bop/entities/BOPHartfordQuote';
import { BOPHartfordQuoteOptionsFormData } from '@app/quote/hartford-bop/view/components/createBOPHartfordOptionsForm';
import { BundleQuoteCardLayout } from '@app/bundle/view/components/BundleQuoteCardLayout.view';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { BOPHartfordQuoteSummary } from '@app/quote/hartford-bop/view/components/quoteSummary/BOPHartfordQuoteSummary';
import { URI } from '@embroker/shotwell/core/types/URI';
import { useNavigation } from '@app/view/hooks/useNavigation';

export interface QuoteSignatureProps extends ReactProps<'div'> {
    quote: BOPHartfordQuote;
    fields: WizardForm<OpaqueForm<BOPHartfordQuoteOptionsFormData>>['fields'];
    setValue: WizardForm<OpaqueForm<BOPHartfordQuoteOptionsFormData>>['setValue'];
    value: WizardForm<OpaqueForm<BOPHartfordQuoteOptionsFormData>>['value'];
    trigger: WizardForm<OpaqueForm<BOPHartfordQuoteOptionsFormData>>['trigger'];
    previous: () => void;
}

export function QuoteSignature(props: QuoteSignatureProps) {
    const { quote, fields, setValue, value, trigger, previous } = props;

    const warrantyAndFraudModal = useModal();
    const { navigate } = useNavigation();
    const signed = value.agreementToConductSignature && value.warrantyAndFraudSignature;

    if (!quote.userInfo) {
        return null;
    }

    const handleContinueButton = () => {
        const URL = URI.build('/shopping/application/quote/bop-hartford/payment', {
            applicationId: quote.applicationId,
        });
        navigate(URL);
    };

    return (
        <BundleQuoteCardLayout gap="48">
            <StackLayout gap="48">
                <StackLayout gap="16">
                    <Text style="heading 3">Quote Summary</Text>
                    <StackLayout>
                        <Text style="body 1" as="p" data-e2e="left-pannel-1">
                            Thanks for applying! Radically simple business insurance awaits — all
                            that's left to do is bind your policy and pay. We've summarized your
                            quotes below so you can see at a glance what you're getting.
                        </Text>
                        <Text style="body 1" as="p" data-e2e="left-pannel-2">
                            As Embroker's customer, you can manage your policies, issue certificates
                            of insurance and manage claims through your Embroker Dashboard.
                        </Text>
                    </StackLayout>
                </StackLayout>
                <StackLayout gap="32">
                    <InvoiceTable>
                        <StackLayout gap="24">
                            <InvoiceTable.Section>
                                <StackLayout gap="32">
                                    <BOPHartfordQuoteSummary quote={quote} />
                                    <div />
                                </StackLayout>
                            </InvoiceTable.Section>
                            <StackLayout>
                                <InvoiceTable.Section>
                                    <StackLayout gap="24">
                                        <InvoiceTable.Item
                                            title={
                                                <Text style="heading 3" as="span">
                                                    Total Premium
                                                </Text>
                                            }
                                        >
                                            <Text
                                                style="heading 3"
                                                as="span"
                                                data-e2e="total-payment-due-value"
                                            >
                                                <ColumnLayout gap="16" bottom>
                                                    <MoneyDisplay value={quote.totalPayable} />
                                                    &nbsp;
                                                    <Text
                                                        style="label 1"
                                                        as="span"
                                                        color="brand-400"
                                                    >
                                                        per year
                                                    </Text>
                                                </ColumnLayout>
                                            </Text>
                                        </InvoiceTable.Item>
                                    </StackLayout>
                                </InvoiceTable.Section>
                                <InvoiceTable.Section>
                                    <StackLayout gap="4">
                                        <Text style="heading 4">
                                            Please agree to the statements below
                                        </Text>

                                        <Form.Field
                                            type={fields.agreementToConductSignature.type}
                                            messages={fields.agreementToConductSignature.messages}
                                            inputProps={{
                                                checked:
                                                    fields.agreementToConductSignature.props.value,
                                                onChange: (
                                                    event: React.ChangeEvent<HTMLInputElement>,
                                                ) => {
                                                    setValue({
                                                        ...value,
                                                        agreementToConductSignature:
                                                            event.target.checked,
                                                    });
                                                    trigger('sign');
                                                },
                                                children: (
                                                    <SignInsuranceApplicationCheckbox
                                                        disableSurplusLinesDisclosure
                                                        company={quote.userInfo.company}
                                                        fullName={quote.userInfo.fullName}
                                                        usaState={quote.userInfo.usaState}
                                                    />
                                                ),
                                            }}
                                            data-e2e="agreement-to-conduct-signature"
                                        />
                                        <Form.Field
                                            type={fields.warrantyAndFraudSignature.type}
                                            messages={fields.warrantyAndFraudSignature.messages}
                                            inputProps={{
                                                checked:
                                                    fields.warrantyAndFraudSignature.props.value,
                                                onChange: (
                                                    event: React.ChangeEvent<HTMLInputElement>,
                                                ) => {
                                                    setValue({
                                                        ...value,
                                                        warrantyAndFraudSignature:
                                                            event.target.checked,
                                                    });
                                                    trigger('sign');
                                                },
                                                children: (
                                                    <Text as="span">
                                                        By checking this box, you certify all
                                                        information provided in the insurance
                                                        application is true and correct and
                                                        acknowledge that you have read and agree to
                                                        the{' '}
                                                        <TextButton
                                                            onClick={warrantyAndFraudModal.show}
                                                            className="c-link display-inline"
                                                        >
                                                            Warranty and Fraud Statement
                                                        </TextButton>
                                                    </Text>
                                                ),
                                            }}
                                            data-e2e="warranty-and-fraud-signature"
                                        />

                                        <Modal {...warrantyAndFraudModal} size="medium">
                                            <WarrantyAndFraudModalContent
                                                fullName={quote.userInfo.fullName}
                                                title={quote.userInfo.title}
                                                usaState={quote.userInfo.usaState}
                                            />
                                        </Modal>
                                    </StackLayout>
                                </InvoiceTable.Section>
                            </StackLayout>
                        </StackLayout>
                    </InvoiceTable>
                    <ButtonBar responsive={{ screenWidth: { smallerThan: 'tablet' } }} gap="32">
                        <Button
                            onClick={handleContinueButton}
                            disabled={!signed}
                            data-e2e="pay-now"
                        >
                            Continue to payment
                        </Button>
                        <TextButton as="button" onClick={previous}>
                            Back to Quote
                        </TextButton>
                    </ButtonBar>
                </StackLayout>
            </StackLayout>
        </BundleQuoteCardLayout>
    );
}
