import React, { useState } from 'react';

import { Nullable } from '@embroker/shotwell/core/types';
import { Money, USD } from '@embroker/shotwell/core/types/Money';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { Vehicle } from '../../../types/Vehicle';
import { ChooseVehicle } from './ChooseVehicle';
import { VehicleInfo } from './VehicleInfo';
import { StackLayout, Table, ColumnLayout, Text } from '@embroker/ui-toolkit/v2';

interface VehiclesSectionProps {
    vehicleList: Nullable<Vehicle[]>;
}

function displayMoney(money: Nullable<Money>) {
    if (money == null) {
        money = USD(0);
    }
    return <MoneyDisplay fractionDigits={0} value={money} />;
}

function displayPerDay(dailyLimit: Nullable<Money>, maxLimit: Nullable<Money>) {
    if (dailyLimit == null || dailyLimit.amount == 0 || maxLimit == null || maxLimit.amount == 0) {
        return displayMoney(null);
    }

    return (
        <ColumnLayout>
            <MoneyDisplay fractionDigits={0} value={dailyLimit} /> per day up to{' '}
            <MoneyDisplay fractionDigits={0} value={maxLimit} />
        </ColumnLayout>
    );
}

function displayWithCaption(money: Nullable<Money>, caption: string) {
    if (money == null || money.amount == 0) {
        return displayMoney(null);
    }
    return (
        <ColumnLayout>
            <MoneyDisplay fractionDigits={0} value={money} />
            {` ${caption}`}
        </ColumnLayout>
    );
}

export const VehiclesSection = ({ vehicleList }: VehiclesSectionProps) => {
    const [selectedVehicle, setSelectedVehicle] = useState<Nullable<Vehicle>>(
        vehicleList ? vehicleList[0] : null,
    );

    if (!vehicleList || !selectedVehicle) {
        return null;
    }

    const handleVehicleSelectionChange = (vehicleVin: string) => {
        const vehicle = vehicleList.find((vehicle) => vehicle.vin === vehicleVin);
        if (vehicle != null) {
            setSelectedVehicle(vehicle);
        }
    };

    const vehicleCoverageTable = (
        <Table>
            <Table.Header>
                <Table.Column>COVERAGE</Table.Column>
                <Table.Column>LIMITS</Table.Column>
                <Table.Column>DEDUCTIBLE</Table.Column>
                <Table.Column>PREMIUM</Table.Column>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>
                        <Text style="label 1">Comprehensive</Text>
                    </Table.Cell>
                    <Table.Cell>{displayMoney(null)}</Table.Cell>
                    <Table.Cell>{displayMoney(selectedVehicle.comprehensiveDeductible)}</Table.Cell>
                    <Table.Cell>{displayMoney(selectedVehicle.comprehensivePremium)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text style="label 1">Collision</Text>
                    </Table.Cell>
                    <Table.Cell>{displayMoney(null)}</Table.Cell>
                    <Table.Cell>{displayMoney(selectedVehicle.collisionDeductible)}</Table.Cell>
                    <Table.Cell>{displayMoney(selectedVehicle.collisionPremium)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text style="label 1">Rental</Text>
                    </Table.Cell>
                    <Table.Cell>
                        {displayPerDay(
                            selectedVehicle.rentalReimbursementDailyLimit,
                            selectedVehicle.rentalReimbursementMaxLimit,
                        )}
                    </Table.Cell>
                    <Table.Cell>{displayMoney(null)}</Table.Cell>
                    <Table.Cell>
                        {displayMoney(selectedVehicle.rentalReimbursementPremium)}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text style="label 1">Towing and labor</Text>
                    </Table.Cell>
                    <Table.Cell>
                        {displayWithCaption(
                            selectedVehicle.rentalReimbursementMaxLimit,
                            'per disablement',
                        )}
                    </Table.Cell>
                    <Table.Cell>{displayMoney(null)}</Table.Cell>
                    <Table.Cell>{displayMoney(selectedVehicle.towingAndLaborPremium)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text style="label 1">Collision deductable Waiver</Text>
                    </Table.Cell>
                    <Table.Cell>{displayMoney(null)}</Table.Cell>
                    <Table.Cell>{displayMoney(null)}</Table.Cell>
                    <Table.Cell>
                        {displayMoney(selectedVehicle.collisionDeductibleWaiverPremium)}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text style="label 1">Lease Loan Gap</Text>
                    </Table.Cell>
                    <Table.Cell>{displayMoney(null)}</Table.Cell>
                    <Table.Cell>{displayMoney(null)}</Table.Cell>
                    <Table.Cell>{displayMoney(selectedVehicle.leaseLoanGapPremium)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text style="label 1">Audio Visual Data Equipment</Text>
                    </Table.Cell>
                    <Table.Cell>
                        {' '}
                        {displayWithCaption(
                            selectedVehicle.audioVisualDataEquipmentLimit,
                            'per occurence',
                        )}
                    </Table.Cell>
                    <Table.Cell>{displayMoney(null)}</Table.Cell>
                    <Table.Cell>
                        {displayMoney(selectedVehicle.audioVisualDataEquipmentPremium)}
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    );

    return (
        <StackLayout gap="24">
            <StackLayout gap="none">
                <ChooseVehicle
                    onChange={handleVehicleSelectionChange}
                    selectedVehicle={selectedVehicle}
                    vehicleList={vehicleList}
                />
                <VehicleInfo vehicle={selectedVehicle} />
            </StackLayout>
            <StackLayout gap="none">
                <Text style="heading 5">{selectedVehicle.name} Coverage Details</Text>
                {vehicleCoverageTable}
            </StackLayout>
        </StackLayout>
    );
};
