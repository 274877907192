import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { UseCase, UseCaseClass, execute } from '@embroker/shotwell/core/UseCase';
import { container, inject } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, Failure, Success, isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { GetGlobalConfigFailed } from '../../config/errors';
import { MPLValidNaicsCodes } from '../../userOrg/types/MPLValidNaicsCodes';
import { Applicant } from '../entities/Applicant';
import { AppTypeNotSupported } from '../errors';
import { ApplicationRepository } from '../repositories/ApplicationRepository';
import { SelectedAppTypes } from '../types/SelectedAppTypes';
import { CreateApplications } from './CreateApplications';
import { GetApplicant } from './GetApplicant';
import { AppTypeCode, AppTypeCodeListEmbrokerExcess } from '@app/shopping/types/enums';

export interface StartApplicationRequest {
    selectedAppTypes: AppTypeCode[];
    abortSignal: AbortSignal;
    skipAppCreation?: boolean;
    page?: string;
}

export type StartApplicationResponse = {
    uri: URI;
};
export interface StartApplication extends UseCase {
    execute(
        request: StartApplicationRequest,
    ): AsyncResult<
        StartApplicationResponse,
        | OperationFailed
        | GetGlobalConfigFailed
        | InvalidArgument
        | UnknownEntity
        | AppTypeNotSupported
    >;
}

export class StartApplicationUseCase extends UseCase implements StartApplication {
    public static type = Symbol('Shopping/StartApplication');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(GetApplicant.type) private getApplicant: GetApplicant,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        selectedAppTypes,
        abortSignal,
        skipAppCreation,
        page,
    }: StartApplicationRequest): AsyncResult<
        StartApplicationResponse,
        | OperationFailed
        | GetGlobalConfigFailed
        | InvalidArgument
        | UnknownEntity
        | AppTypeNotSupported
    > {
        const applicantResp = await this.getApplicant.execute();
        if (isErr(applicantResp)) {
            return applicantResp;
        }

        const {
            value: { applicant },
        } = applicantResp;

        if (!selectedAppTypes) {
            return Failure(
                InvalidArgument({ argument: 'selectedAppTypes', value: selectedAppTypes }),
            );
        }

        const appTypes = selectedAppTypes;
        const isOnlyExcessSelected =
            appTypes?.length == 1 && appTypes?.includes(AppTypeCodeListEmbrokerExcess);

        let baseURL = '/shopping/questionnaire';
        if (isOnlyExcessSelected) {
            baseURL = '/shopping/excess-before-you-begin';
        }

        let redirectUri;

        const getConfigResponse = await this.applicationRepository.getConfig();
        if (isErr(getConfigResponse)) {
            return getConfigResponse;
        }

        const mplConfig = {
            isMPLEnabled: getConfigResponse.value.isMPLEnabled,
            isMPLWCChubbEnabled: getConfigResponse.value.isMPLWCEnabled,
            isCowbellCyberEnabled: getConfigResponse.value.isCowbellCyberEnabled,
            isBOPChubbEnabled: getConfigResponse.value.isBOPChubbEnabled,
        };

        const isMPLEligible =
            SelectedAppTypes.isMPLEligible(selectedAppTypes, mplConfig) &&
            MPLValidNaicsCodes.isMPLVerticalEnabled(applicant?.naicsIndustry || null);

        const isMPLVertical = MPLValidNaicsCodes.isNaicCodeValid(applicant?.naicsIndustry || null);

        if (skipAppCreation || (isMPLVertical && isMPLEligible)) {
            const createApplicationResp = await createApplication(
                selectedAppTypes,
                applicant,
                abortSignal,
            );
            const applicationId = isOK(createApplicationResp)
                ? createApplicationResp.value.id
                : null;

            if (applicationId) {
                const initialPage = page ? `/${page}` : '';
                redirectUri = URI.build(`/shopping/application${initialPage}`, {
                    applicationId: applicationId,
                });
            }
        } else {
            redirectUri = URI.build(baseURL, {
                qd: JSON.stringify({
                    app_type_list: appTypes,
                }),
            });
        }

        if (!redirectUri) {
            return Failure(InvalidArgument({ argument: 'redirectUri', value: redirectUri }));
        }

        return Success({ uri: redirectUri });
    }
}

const createApplication = async (
    selectedAppTypes: AppTypeCode[],
    applicant: Immutable<EntityProps<Applicant>>,
    abortSignal: AbortSignal,
): AsyncResult<{ id: UUID | null }, never> => {
    const createApplicationsResponse = await execute(CreateApplications, {
        appTypeList: selectedAppTypes,
        questionnaireData: {
            first_name: applicant?.firstName,
            last_name: applicant?.lastName,
            phone_number: parseInt(applicant?.phoneNumber as string),
            title: applicant?.title,
            naics_code: applicant?.naicsIndustry as string,
        },
        abortSignal: abortSignal,
        skipUserUpdate: true,
    });
    if (isOK(createApplicationsResponse)) {
        const createdApplicationIds = createApplicationsResponse.value.applicationIdList;
        if (!createdApplicationIds.length) {
            container.get<Logger>(Log).error('Application id list is empty', createdApplicationIds);
        }
        return Success({ id: createdApplicationIds[0] });
    }
    return Success({ id: null });
};

export const StartApplication: UseCaseClass<StartApplication> = StartApplicationUseCase;
