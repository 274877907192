import { getComponent, FieldElement } from '@embroker/service-app-engine';
import React from 'react';
import { ElementProps } from '@embroker/ui-toolkit/v2';

export interface GroupFieldProps extends ElementProps<'div'> {
    fields: FieldElement[];
    invertChildOrder?: boolean;
}

const sectionFieldsAreDisabled = (field: FieldElement) => {
    if (field.type === 'section') {
        return field.machine.state.fields.every((fieldElement) => {
            return !fieldElement.machine.state.enabled;
        });
    }
    return false;
};

export const GroupField = React.forwardRef(function GroupField(
    props: GroupFieldProps,
    ref: React.Ref<HTMLDivElement>,
) {
    // NOTE We read the onChange separately from fieldProps in order not to pass
    // it down to children, and effectively prevent unnecessary event handling
    const { fields, children, invertChildOrder = false, onChange, ...fieldProps } = props;

    const fieldComponents = fields.map((field: FieldElement) => {
        const Component = getComponent(field);
        if (field.type === 'section' && sectionFieldsAreDisabled(field)) {
            return null;
        }
        return <Component key={field.id} instance={field} />;
    });

    return (
        <div ref={ref} tabIndex={-1} {...fieldProps}>
            {invertChildOrder ? (
                <React.Fragment>
                    {fieldComponents}
                    {children}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {children}
                    {fieldComponents}
                </React.Fragment>
            )}
        </div>
    );
});
