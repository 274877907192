import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { UserOnboardingRepository } from '../repositories/UserOnboardingRepository';

export interface RemoveUserOnboardingDetails extends UseCase {
    execute(): AsyncResult<void, never>;
}

@injectable()
export class RemoveUserOnboardingDetailsUseCase
    extends UseCase
    implements RemoveUserOnboardingDetails
{
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/RemoveUserOnboardingDetails');
    /**
     * Constructor for RemoveUserOnboardingDetails use case class instance
     * @param eventBus An event bus this Use Case will publish events to.
     * @param sessionRepository is session repository used to update UserOnboardingDetails
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(UserOnboardingRepository)
        private userOnboardingRepository: UserOnboardingRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes RemoveUserOnboardingDetails use case
     * Input is of type RemoveUserOnboardingDetailsRequest
     * @returns Nothing if execution was successful
     */
    public async execute(): AsyncResult<void, never> {
        return await this.userOnboardingRepository.removeUserOnboardingDetails();
    }
}

export const RemoveUserOnboardingDetails: UseCaseClass<RemoveUserOnboardingDetails> =
    RemoveUserOnboardingDetailsUseCase;
