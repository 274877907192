import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import {
    AppTypeCode,
    AppTypeCodeList,
    CoverageGroupType,
    coverageGroupTypeMap,
    CoveragePriorityEnum,
} from './enums';

export interface Coverage {
    readonly appType: AppTypeCode;
    isSelected?: boolean;
}

export const Coverage = {
    ...defineValidator<Coverage>({
        appType: Joi.string()
            .valid(...AppTypeCodeList)
            .required(),
        isSelected: Joi.boolean().optional(),
    }),
    create(coverage: Coverage) {
        return Coverage.validate(coverage);
    },
};

export const getSelectedCoverages = (
    allCoverages: Coverage[],
    activeFilter?: CoverageGroupType,
): Coverage[] => {
    if (activeFilter) {
        const filteredCoverages = filterCoverages(activeFilter);

        const selectedCoverages: Coverage[] = [];
        filteredCoverages.forEach((appTypeCode: AppTypeCode) => {
            const coverage = allCoverages.find(
                (coverage: Coverage) => coverage.appType === appTypeCode,
            );
            if (coverage !== undefined) {
                selectedCoverages.push(coverage);
            }
        });
        return selectedCoverages;
    }

    return applyPriorityOrder(allCoverages);
};

export const applyPriorityOrder = (coverages: Coverage[]) => {
    const orderedAppTypes = coverages.sort(
        (a, b) => CoveragePriorityEnum[a.appType] - CoveragePriorityEnum[b.appType],
    );
    return orderedAppTypes;
};

export function filterCoverages(type?: CoverageGroupType): AppTypeCode[] {
    if (type === undefined) {
        return [...AppTypeCodeList];
    }

    const filteredAppTypes: AppTypeCode[] = [];

    const typeCoverages = coverageGroupTypeMap.get(type);
    if (typeCoverages && typeCoverages.length > 0) {
        filteredAppTypes.push(...typeCoverages);
    }
    return Array.from(new Set(filteredAppTypes));
}
