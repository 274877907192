import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { CoverageRepository } from '../repositories/CoverageRepository';
import { Coverage } from '../types/Coverage';

export interface GetAllCoveragesResponse {
    readonly allCoverages: Coverage[];
}

export interface GetAllCoverages extends UseCase {
    execute(): AsyncResult<GetAllCoveragesResponse, InvalidArgument | OperationFailed>;
}

@injectable()
class GetAllCoveragesUseCase extends UseCase implements GetAllCoverages {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Shopping/GetCoverages');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(CoverageRepository) private coverageRepository: CoverageRepository,
    ) {
        super(eventBus);
    }

    public async execute(): AsyncResult<
        GetAllCoveragesResponse,
        InvalidArgument | OperationFailed
    > {
        const allCoveragesResponse = await this.coverageRepository.getAllCoveragesForNoAuthUser();

        if (isErr(allCoveragesResponse)) {
            return allCoveragesResponse;
        }

        return Success<GetAllCoveragesResponse>({
            allCoverages: allCoveragesResponse.value as Array<Coverage>,
        });
    }
}

export const GetAllCoverages: UseCaseClass<GetAllCoverages> = GetAllCoveragesUseCase;
