import { Money } from '@embroker/shotwell/core/types/Money';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { Revenue } from '@embroker/shotwell/core/types/Revenue';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Year } from '@embroker/shotwell/core/types/Year';
import { execute } from '@embroker/shotwell/core/UseCase';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import getYear from 'date-fns/getYear';
import { GetActiveOrganizationProfileResponse } from '../useCases/GetActiveOrganizationProfile';
import { RemoveUserOnboardingQuestionnaireData } from '../useCases/RemoveUserOnboardingQuestionnaireData';
import { UpdateOrganizationProfileRequest } from '../useCases/UpdateOrganizationProfile';
import { UpdateUserOnboardingQuestionnaireData } from '../useCases/UpdateUserOnboardingQuestionnaireData';
import { locationFieldValidator } from '../view/components/LocationFieldSet.view';
import { WorkspaceOwnership } from './enums';
import { MailingAddress } from './MailingAddress';

function removeUndefinedAttributes<T extends object>(obj: T): Partial<T> {
    const result: Partial<T> = {};
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] !== undefined) {
            result[key] = obj[key];
        }
    }
    return result;
}

export interface OnboardingPrefillQuestionnaireData {
    tech_consulting_services?: boolean;
    headquarters?: MailingAddress;
    naics_code?: string;
    refined_naics_code?: string;
    dnb_naics_code?: string;
    location_type?: WorkspaceOwnership;
    has_employees?: boolean;
    uses_employee_or_rental_vehicles?: boolean;
    total_revenue?: Money;
    has_raised_funding?: boolean;
    number_of_employees?: number;
    tech_area_of_focus?: string;
    vetting?: boolean;
}
export type OnboardingPrefillQuestionnaireKeys = keyof OnboardingPrefillQuestionnaireData;

export const getCurrentYear = () => {
    const year = Year.create(getYear(Date.now()));
    if (isOK(year)) {
        return year.value;
    }
};

export const getOrganizationProfileFromQuestionnaireData = (
    questionnaireData: OnboardingPrefillQuestionnaireData,
    organizationId: UUID,
): UpdateOrganizationProfileRequest | undefined => {
    const revenue = Revenue.create({
        fiscalYear: getCurrentYear() as Year,
        grossTotal: questionnaireData.total_revenue as Money,
    });
    const organizationProfile = removeUndefinedAttributes({
        headquarters: questionnaireData.headquarters,
        naics: questionnaireData.naics_code,
        raisedFunding: questionnaireData.has_raised_funding,
        techAreaOfFocus: questionnaireData.tech_area_of_focus,
        totalNumberOfEmployees: questionnaireData.number_of_employees,
        revenues: isOK(revenue) ? [revenue.value] : undefined,
        workspaceOwnership: questionnaireData.location_type,
        hasAutomobiles: questionnaireData.uses_employee_or_rental_vehicles,
        hasEmployees: questionnaireData.has_employees,
    });

    if (Object.keys(organizationProfile).length === 0) {
        return;
    }

    return {
        id: organizationId,
        ...organizationProfile,
    };
};

export const getOnboardingQuestionnaireDataFromUserProfile = (
    organization: GetActiveOrganizationProfileResponse['organization'],
): OnboardingPrefillQuestionnaireData => {
    return {
        headquarters: organization.headquarters,
        naics_code: organization.naics || undefined,
        has_employees: organization.hasEmployees,
        has_raised_funding:
            organization.raisedFunding === null ? undefined : Boolean(organization.raisedFunding),
        tech_area_of_focus: organization.techAreaOfFocus || undefined,
        total_revenue: organization.revenues?.[1].grossTotal || undefined,
        number_of_employees: organization.totalNumberOfEmployees
            ? Number(organization.totalNumberOfEmployees)
            : undefined,
        location_type: organization.workspaceOwnership,
        uses_employee_or_rental_vehicles: organization.hasAutomobiles,
    };
};

export const OnboardingPrefillQuestionnaireData = {
    ...defineValidator<OnboardingPrefillQuestionnaireData>({
        tech_consulting_services: Joi.boolean().optional(),
        headquarters: locationFieldValidator,
    }),
    create(userOnboardingDetails: OnboardingPrefillQuestionnaireData) {
        return OnboardingPrefillQuestionnaireData.validate(userOnboardingDetails);
    },
    update(onboardingPrefillQuestionnaireData: OnboardingPrefillQuestionnaireData): void {
        execute(UpdateUserOnboardingQuestionnaireData, onboardingPrefillQuestionnaireData);
    },
    clear(): void {
        execute(RemoveUserOnboardingQuestionnaireData);
    },
};
