import {
    API,
    CurrencyMarshaller,
    InsuranceApplication,
    PcomlQuoteOptions,
    ShoppingPcomlQuoteInfoFromPolicyResponse,
} from '@embroker/shotwell-api/app';
import {
    InsuranceApplicationStatusCodeListItem,
    InsuranceApplicationStatusCodeListMap,
} from '@embroker/shotwell-api/enums';
import { isAPIError } from '@embroker/shotwell-api/errors';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { Immutable, Nullable } from '@embroker/shotwell/core/types/index';
import { USD } from '@embroker/shotwell/core/types/Money';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    isOK,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { State } from '@embroker/shotwell/core/types/StateList';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { parseISO, startOfToday } from 'date-fns';
import { QuotingEnginePCoML } from '../../../../shopping/types/enums';
import { RemoveSignaturePacketRequest } from '../../../brokerage/useCases/RemoveSignaturePacket';
import { UploadSignaturePacketRequest } from '../../../brokerage/useCases/UploadSignaturePacket';
import { InvalidAnnualTechFee } from '../../../errors';
import { QuoteExpiration } from '../../../types/QuoteExpiration';
import { QuoteIdMap, QuoteList } from '../../../types/QuoteList';
import { PCoMLQuote, PCoMLQuoteOptions } from '../../entities/PCoMLQuote';
import { ConfigFetchFailed } from '../../errors';
import { PCoMLConfig } from '../../types/PCoMLConfig';
import { UserInfo } from '../../types/UserInfo';
import { GetTaskStatusResponse, PurchaseResponse, QuoteRepository } from './index';

interface QuestionnaireData {
    first_name: string;
    last_name: string;
    title: string;
    state: string;
    company_name: string;
    naics_code?: string;
}

@injectable()
export class APIQuoteRepository implements QuoteRepository {
    public async getQuoteByApplicationId(
        applicationId: UUID,
    ): AsyncResult<PCoMLQuote, InvalidArgument | OperationFailed | ConfigFetchFailed> {
        const applicationResponse = await API.request('shopping/application', {
            id: applicationId,
        });
        if (isErr(applicationResponse)) {
            return handleOperationFailure(applicationResponse);
        }
        const application = applicationResponse.value;

        const lastQuote = QuoteList.getLastQuote(application.quote_list);
        if (lastQuote == null) {
            return Failure(InvalidArgument({ argument: 'quote_list', value: lastQuote }));
        }

        const pcomlQuoteDetails = lastQuote.details.pcoml;
        const pcomlQuoteOptions = lastQuote.options.pcoml;

        if (pcomlQuoteDetails === undefined) {
            return Failure(OperationFailed({ message: 'pcomlQuoteDetails is null' }));
        }

        if (pcomlQuoteOptions === undefined) {
            return Failure(OperationFailed({ message: 'pcomlQuoteOptions is null' }));
        }

        const previousPolicyId =
            application.renewed_policy_id_list.length > 0
                ? application.renewed_policy_id_list[0]
                : null;

        let previousPolicy: ShoppingPcomlQuoteInfoFromPolicyResponse | null = null;
        if (previousPolicyId !== null) {
            const previousPolicyResult = await API.request(
                'shopping/pcoml_quote_info_from_policy',
                {
                    policy_id: previousPolicyId,
                },
            );

            if (isErr(previousPolicyResult)) {
                return handleOperationFailure(previousPolicyResult);
            }
            previousPolicy = previousPolicyResult.value;
        }

        const questionnaireData = application.questionnaire_data;
        // This check is intentional to test both null and undefined
        if (questionnaireData == null) {
            return Failure(
                InvalidArgument({ argument: 'questionnaire_data', value: questionnaireData }),
            );
        }

        let questionnaireDataParsed: QuestionnaireData;
        try {
            questionnaireDataParsed = JSON.parse(questionnaireData);
        } catch (e) {
            return Failure(OperationFailed({ message: 'Failed parsing questionnaire data' }));
        }
        const userInfo = getUserInfoFromQuestionnaireData(questionnaireDataParsed);
        const appStatus = application.status as InsuranceApplicationStatusCodeListItem;
        const existingCoverageReferral =
            containsExistingCoverageReason(application.ineligibility_reasons) &&
            InsuranceApplicationStatusCodeListMap[appStatus] === 'Referred';

        const quotableCoverageList = application.quotable_shopping_coverage_list ?? [];

        const configResponse = await this.getPCoMLConfig();
        if (isErr(configResponse)) {
            return Failure(ConfigFetchFailed());
        }

        const resultQuote = await PCoMLQuote.create({
            isIndication: lastQuote.is_indication,
            id: lastQuote.id,
            applicationId,
            details: {
                dno: {
                    premium: pcomlQuoteDetails.directors_and_officers_premium ?? USD(0),
                    previousPremium:
                        previousPolicy !== null
                            ? CurrencyMarshaller.unmarshal(
                                  previousPolicy.directors_and_officers_premium,
                                  'directors_and_officers_premium',
                              )
                            : undefined,
                },
                epl: {
                    premium: pcomlQuoteDetails.employment_practices_liability_premium ?? USD(0),
                    previousPremium:
                        previousPolicy !== null
                            ? CurrencyMarshaller.unmarshal(
                                  previousPolicy.employment_practices_liability_premium,
                                  'employment_practices_liability_premium',
                              )
                            : undefined,
                },
                taxes: lastQuote.taxes ?? USD(0),
                fees: lastQuote.fees ?? USD(0),
                mwuaFee: lastQuote.details.pcoml?.mwua_fee,
                policyAdministrationFee:
                    lastQuote.details.pcoml?.policy_administration_fee ?? USD(0),
                sociusEndorsementPremium: pcomlQuoteDetails.socius_endorsement_premium ?? USD(0),
            },
            options: toPCoMLQuoteOptions(pcomlQuoteOptions, previousPolicy, quotableCoverageList),
            applicationInfo: {
                userInfo,
                isRenewal: previousPolicyId !== null,
                existingCoverageReferral,
                submittedAt:
                    application.submitted_at !== null
                        ? parseISO(application.submitted_at)
                        : undefined,
                newInsurerDocumentsReleaseDate: configResponse.value.newInsurerDocumentsReleaseDate,
                naics: questionnaireDataParsed.naics_code,
            },
            status: application.status === 'Purchased' ? 'accepted' : 'draft',
            totalPremium: lastQuote.total_premium,
            annualTechnologyFee: lastQuote.annual_technology_fee,
            totalPayable: lastQuote.total_payable,
            daysToExpire: QuoteExpiration.getDaysLeftUntilExpiration({
                quotingEngine: application.quoting_engine || undefined,
                applicationStatus: application.status,
                validUntil: application.valid_until,
                quoteEffectiveDate: pcomlQuoteOptions.effective_date,
                today: startOfToday(),
                isBroker: application.brokerage_id !== null,
            }),
            fileKey: lastQuote.file_key ?? undefined,
        });
        if (isErr(resultQuote)) {
            return handleOperationFailure(resultQuote);
        }

        return Success(resultQuote.value);
    }

    public async purchasePCoML(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<PurchaseResponse, InvalidArgument | OperationFailed | InvalidAnnualTechFee> {
        const quoteIdMap: QuoteIdMap = { [QuotingEnginePCoML]: quoteId };
        const purchaseResponse = await API.request('shopping/purchase', {
            id: applicationId,
            quote_id_map: quoteIdMap,
        });
        if (isErr(purchaseResponse)) {
            const error = purchaseResponse.errors[0];
            if (isAPIError(error) && error.details.name === 'invalid_annual_tech_fee') {
                return Failure(InvalidAnnualTechFee());
            }
            return handleOperationFailure(purchaseResponse);
        }

        const { policyId, policy_issuance_task_id: taskIdPolicyDone } = purchaseResponse.value;

        return Success({
            policyId: policyId,
            policyDoneTaskId: taskIdPolicyDone,
        } as PurchaseResponse);
    }

    async updateQuotePCoMLAsync(
        applicationId: UUID,
        pcomlQuoteOptions: PcomlQuoteOptions,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed> {
        const response = await API.request('shopping/create_quote_task', {
            application_id: applicationId,
            quote_options: {
                pcoml: pcomlQuoteOptions,
            },
        });
        if (isErr(response)) {
            return handleOperationFailure(response);
        }
        return Success(response.value.task_id);
    }

    public async createQuoteSummaryAsyncTask(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed> {
        const createQuoteSummaryTaskResult = await API.request(
            'shopping/create_quote_summary_task',
            {
                application_id: applicationId,
                quote_id: quoteId,
            },
        );
        if (isErr(createQuoteSummaryTaskResult)) {
            return handleOperationFailure(createQuoteSummaryTaskResult);
        }
        return Success(createQuoteSummaryTaskResult.value.task_id);
    }

    public async uploadSignaturePacket(
        request: UploadSignaturePacketRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed> {
        const result = await API.request('brokerage/process_signature_packet_upload', {
            document_list: [
                {
                    file_name: request.signaturePacketDocument.fileName,
                    storage_location: request.signaturePacketDocument.storageLocation,
                },
            ],
            application_id: request.applicationId,
        });
        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return Success();
    }

    public async removeSignaturePacket(
        request: RemoveSignaturePacketRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed> {
        const result = await API.request('brokerage/remove_uploaded_signature_packet', {
            storage_location: request.storageLocation,
            application_id: request.applicationId,
        });

        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return Success();
    }

    public async getTaskStatus(
        id: UUID,
    ): AsyncResult<GetTaskStatusResponse, InvalidArgument | OperationFailed> {
        const getTaskStatusResponse = await API.request('task/get_task_status', { id });
        if (isErr(getTaskStatusResponse)) {
            return handleOperationFailure(getTaskStatusResponse);
        }

        return Success({
            data: getTaskStatusResponse.value.data ?? null,
            error: getTaskStatusResponse.value.error ?? null,
        });
    }

    async getPCoMLConfig(): AsyncResult<PCoMLConfig, InvalidArgument | ConfigFetchFailed> {
        const getConfigResponse = await API.request('global/get_config');

        if (isErr(getConfigResponse)) {
            return Failure(ConfigFetchFailed());
        }

        return PCoMLConfig.create({
            newInsurerDocumentsReleaseDate:
                getConfigResponse.value.pcoml_new_insurer_documents_release_date ?? undefined,
        });
    }
}

function getUserInfoFromQuestionnaireData(questionnaire: QuestionnaireData): UserInfo {
    let stateCode: Nullable<State> = null;

    if (typeof questionnaire.state === 'string' && questionnaire.state.length > 0) {
        const result = State.validate(questionnaire.state);
        if (isOK(result)) {
            stateCode = result.value;
        }
    }

    return {
        fullName: `${questionnaire.first_name} ${questionnaire.last_name}`,
        title: questionnaire.title,
        usaState: stateCode,
        company: questionnaire.company_name,
    };
}

function containsExistingCoverageReason(
    ineligibility_reasons: Immutable<InsuranceApplication['ineligibility_reasons']>,
): boolean {
    const existingCoverageReason = 'Submission referred for underwrite review of loss runs';
    return (
        ineligibility_reasons != null &&
        ineligibility_reasons.referral_reasons.includes(existingCoverageReason)
    );
}

export function toPCoMLQuoteOptions(
    pcomlQuoteOptions: PcomlQuoteOptions,
    previousPolicy: ShoppingPcomlQuoteInfoFromPolicyResponse | null = null,
    quotableCoverageList: readonly string[] = [],
): PCoMLQuoteOptions {
    return {
        effectiveDate: pcomlQuoteOptions.effective_date,
        dno: {
            isSelected: pcomlQuoteOptions.dno_rating_info,
            limit: USD(pcomlQuoteOptions.dno_limit),
            previousLimit:
                previousPolicy !== null
                    ? CurrencyMarshaller.unmarshal(
                          previousPolicy.directors_and_officers_limit,
                          'directors_and_officers_limit',
                      )
                    : undefined,
            retention: USD(pcomlQuoteOptions.dno_retention),
            previousRetention:
                previousPolicy !== null
                    ? CurrencyMarshaller.unmarshal(
                          previousPolicy.directors_and_officers_retention,
                          'directors_and_officers_retention',
                      )
                    : undefined,
            inShoppingCoverageList: quotableCoverageList.includes(
                'ShoppingCoverageCodeListDirectorsAndOfficers',
            ),
        },
        epl: {
            isSelected: pcomlQuoteOptions.epli_rating_info,
            limit: USD(pcomlQuoteOptions.epli_limit),
            previousLimit:
                previousPolicy !== null
                    ? CurrencyMarshaller.unmarshal(
                          previousPolicy.employment_practices_liability_limit,
                          'employment_practices_liability_limit',
                      )
                    : undefined,
            retention: USD(pcomlQuoteOptions.epli_retention),
            previousRetention:
                previousPolicy !== null
                    ? CurrencyMarshaller.unmarshal(
                          previousPolicy.employment_practices_liability_retention,
                          'employment_practices_liability_retention',
                      )
                    : undefined,
            inShoppingCoverageList: quotableCoverageList.includes(
                'ShoppingCoverageCodeListEmploymentPractices',
            ),
        },
        policyAdministrationFeePreTax: pcomlQuoteOptions.policy_administration_fee_pre_tax,
        policyAdministrationFeePostTax: pcomlQuoteOptions.policy_administration_fee_post_tax,
    };
}
