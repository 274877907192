import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, NotImplemented, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { UserRepository } from '../repositories/UserRepository';

/**
 * Request data for ResetPassword use case
 * @param password is new password provided by user
 * @param token is used to identify the user that is requesting a password change
 */
export interface ResetPasswordRequest {
    readonly password: string;
    readonly token: string;
}

/**
 * ResetPassword use case is used to reset password for the user who exists on the platform
 */

export interface ResetPassword extends UseCase {
    execute(
        request: ResetPasswordRequest,
    ): AsyncResult<void, InvalidArgument | NotImplemented | OperationFailed>;
}

@injectable()
class ResetPasswordUseCase extends UseCase implements ResetPassword {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/ResetPassword');
    /**
     * Constructor for ResetPassword use case class instance
     * @param eventBus An event bus this Use Case will publish events to.
     * @param userRepository is used to save user entity who's password was reset
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(UserRepository) private userRepository: UserRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes ResetPassword use case
     * Input is of type ResetPasswordRequest
     * @returns void if successful
     * @returns InvalidArgument if provided token is not valid
     * @returns OperationFailed if some unknown error has occured
     */
    public async execute({
        password,
        token,
    }: ResetPasswordRequest): AsyncResult<void, InvalidArgument | OperationFailed> {
        const userResult = await this.userRepository.getUserByPasswordResetToken(token);

        if (isErr(userResult)) {
            return userResult;
        }

        userResult.value.resetPassword(password);

        const result = await this.userRepository.save(userResult.value);

        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return Success();
    }
}

export const ResetPassword: UseCaseClass<ResetPassword> = ResetPasswordUseCase;
