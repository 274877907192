import { Form } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { BundleQuoteCoverageListProps } from '../../../view/components/BundleQuoteCoverageList';
import { BundleQuoteFormData } from '../../../types/BundleQuoteFormData';
import {
    checkIsRequiredApplicationAttestation,
    checkIsRequiredNoKnownLossesAttestation,
} from '../../../view/components/createBundleQuoteForm';
import { SignInsuranceCowbellNoKnownLossesSignature } from './SignInsuranceCowbellNoKnownLossesSignature';

export function SignInsuranceCowbellNoKnownLossesSignatureWrapper({
    fields,
    setValue,
    value,
    trigger,
    bundleQuote,
}: BundleQuoteCoverageListProps<BundleQuoteFormData>) {
    const isRequiredApplicationAttestation = checkIsRequiredApplicationAttestation(bundleQuote);
    const isRequiredNoKnownLossesAttestation = checkIsRequiredNoKnownLossesAttestation(
        bundleQuote,
        isRequiredApplicationAttestation,
    );

    if (!isRequiredNoKnownLossesAttestation) {
        return null;
    }

    return (
        <Form.Field
            type={fields.applicationNoKnownLossesAttestation.type}
            messages={fields.applicationNoKnownLossesAttestation.messages}
            inputProps={{
                checked: fields.applicationNoKnownLossesAttestation.props.value,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    setValue({
                        ...value,
                        applicationNoKnownLossesAttestation: event.target.checked,
                    });
                    trigger('sign');
                },
                children: <SignInsuranceCowbellNoKnownLossesSignature bundleQuote={bundleQuote} />,
            }}
        />
    );
}
