//
// InterValidation definition
// Refine back-end data contract.
// https://embroker.atlassian.net/browse/EM-43445
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { QuestionerQuestion } from './QuestionerQuestionType';
import {
    ValidationAggregatorTypes,
    ValidationOperatorTypes,
    ValidationTypeProps,
} from '@app/view/components/DataDrivenForm/types/fieldValidationFactory';
import { AggregatorValueType } from './AggregatorTypes';
import { OperatorValueType } from './OperatorTypes';
import { InputValueTypes } from '@app/view/components/DataDrivenForm/types/ConditionalValidation';

export interface InterValidation {
    external_question_key: string;
    external_answer_field?: string;
    external_aggregator?: AggregatorValueType;
    internal_answer_field?: string;
    internal_aggregator?: AggregatorValueType;
    operator: OperatorValueType;
    error_message: string;
}
const InterValidationObjectSchema = Joi.object({
    external_question_key: Joi.string().required(),
    external_answer_field: Joi.string().optional().allow(''),
    external_aggregator: Joi.string().optional(),
    internal_answer_field: Joi.string().optional().allow(''),
    internal_aggregator: Joi.string().optional(),
    operator: Joi.string().required(),
    error_message: Joi.string().required(),
});

export const InterValidationSchema = Joi.array()
    .items(Joi.array().items(InterValidationObjectSchema))
    .optional();

export const VALIDATION_INNER_FIELD_MAP: { [key: string]: string } = {
    'amount_raised.unit_amount': 'moneyRaised',
};

export function getInterValidations(
    question: QuestionerQuestion,
): ValidationTypeProps[] | undefined {
    const { inter_validation } = question;
    if (!inter_validation.length) {
        return;
    }
    // inter_validation is a 2 dimensional array, currently the FE is only supporting a single group of inter validations
    const validations = inter_validation[0];
    if (!validations.length) {
        return;
    }

    const interValidationProps: ValidationTypeProps[] = validations.reduce(
        (acc: ValidationTypeProps[], validation: InterValidation) => {
            const externalAnswerType = getQuestionAnswerType(question, validation);
            const currentAnswerType = getQuestionAnswerType(question, validation);
            const { external_answer_field } = validation;
            const fieldKey = external_answer_field
                ? VALIDATION_INNER_FIELD_MAP[external_answer_field]
                : external_answer_field;
            if (externalAnswerType && currentAnswerType) {
                const validator: ValidationTypeProps = {
                    conditional: {
                        externalQuestionProps: {
                            questionKey: validation.external_question_key,
                            fieldKey,
                            valueType: externalAnswerType,
                            aggregator: getAggregator(validation.external_aggregator),
                        },
                        message: validation.error_message,
                        computeValidationProps: {
                            operator: getOperatorType(validation),
                            valueType: currentAnswerType,
                        },
                    },
                };
                acc.push(validator);
            }
            return acc;
        },
        [],
    );

    return interValidationProps;
}

function getAggregator(
    aggregatorValueType?: AggregatorValueType,
): ValidationAggregatorTypes | null {
    switch (aggregatorValueType) {
        case 'SUM':
            return 'sum';
        case 'ANY':
            return 'any';
    }
    return null;
}

function getQuestionAnswerType(
    question: QuestionerQuestion,
    validation: InterValidation,
): InputValueTypes | undefined {
    const answerFieldTypeMap: { [key: string]: InputValueTypes } = {
        amount_raised: 'number',
    };
    if (validation.internal_answer_field) {
        return answerFieldTypeMap[validation.internal_answer_field];
    }
    const { answer_type } = question;
    switch (answer_type) {
        case 'BOOLEAN':
            return 'boolean';
        case 'TEXT':
            return 'string';
        case 'INTEGER':
            return 'number';
        case 'CURRENCY':
            return 'currency';
        default:
    }
    return;
}

function getOperatorType(validation: InterValidation): ValidationOperatorTypes {
    switch (validation.operator) {
        case 'EQUAL':
            return 'equal';
        case 'LESS_THAN_OR_EQUAL':
            return 'lessThanEqualTo';
        case 'GREATER_THAN_OR_EQUAL':
            return 'greaterThanEqualTo';
        default:
            return 'equal';
    }
}
