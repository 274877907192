import { Money } from '@embroker/shotwell/core/types/Money';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { QuoteDetails } from '../../entities/Quote';

export interface TotalBasePremiumRange {
    min: number;
    max: number;
}

export interface LPLQuoteDetails extends QuoteDetails {
    readonly specimenPolicyFileKey?: string;
    readonly lplProcessingFee?: Money;
    readonly premium?: Money;
}

export const LPLQuoteDetails = {
    ...defineValidator<LPLQuoteDetails>({
        specimenPolicyFileKey: Joi.string().optional(),
        lplProcessingFee: Money.schema.optional(),
        premium: Money.schema.optional(),
    }),
    create(lplQuoteDetails: LPLQuoteDetails) {
        return LPLQuoteDetails.validate(lplQuoteDetails);
    },
};
