import { Joi, SchemaType } from '@embroker/shotwell/core/validation/schema';
import { FormQuestionDefinition } from '../hooks/useDataDrivenForm';
import {
    buildFieldValidator,
    isNumberOperator,
    isStringOperator,
    ValidationAggregatorTypes,
    ValidationOperatorTypes,
    ValidationTypeProps,
    ValidationTypes,
} from './fieldValidationFactory';
import { Money, USD } from '@embroker/shotwell/core/types/Money';

// validationProps & computeValidationProps are mutually exclusive
// validationProps is used to compare the value of the field with a static value
// computeValidationProps is used to compare the value of the field with the value of another field
export type ConditionalValidationDefinition = {
    externalQuestionProps: ExternalQuestionProps;
    message?: string | `${string}{{${string}}}${string}`;
} & (
    | { validationProps: Omit<ValidationTypeProps, 'conditional'>; computeValidationProps?: never }
    | {
          validationProps?: never;
          computeValidationProps: {
              operator: ValidationOperatorTypes;
              aggregator?: ValidationAggregatorTypes;
              valueType: InputValueTypes;
          };
      }
);

// validationProps & computeValidationProps are mutually exclusive
// validationProps is used to compare the value of the field with a static value
// computeValidationProps is used to compare the value of the field with the value of another field
export type ExternalQuestionProps = {
    questionKey: string;
    fieldKey?: string;
    valueType: InputValueTypes;
} & (
    | { validationProps: Omit<ValidationTypeProps, 'conditional'>; aggregator?: never }
    | {
          validationProps?: never;
          aggregator: ValidationAggregatorTypes | null;
      }
);
export type InputValueTypes = Exclude<keyof ValidationTypeProps, 'conditional'>;

type ComputeValidationProps = {
    operator: ValidationOperatorTypes;
    aggregator?: ValidationAggregatorTypes;
    valueType: InputValueTypes;
};

export interface BuildConditionalValidationProps {
    conditionalValidationDefinition: ConditionalValidationDefinition;
    questionDefinition: FormQuestionDefinition;
    externalQuestionDefinition: FormQuestionDefinition;
}

export function assertNonArrayObject(value: unknown): value is Record<string, unknown> {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
}
export const STRING_INSERT = '%s';
export const isTemplateString = (value?: string): value is string => {
    return Boolean(value && value.includes(STRING_INSERT));
};

export interface BuildValidationMessageProps {
    internalFieldValue: unknown;
    externalFieldValue: unknown;
    valueType: InputValueTypes;
    message?: string;
}

export const buildValidationMessage = (
    buildValidationMessageProps: BuildValidationMessageProps,
): string => {
    const { internalFieldValue, externalFieldValue, valueType, message } =
        buildValidationMessageProps;

    if (!isTemplateString(message)) {
        return message || 'Invalid input';
    }

    // As per ADR here https://github.com/embroker/quentin/blob/main/adr/20240610-inter-validation-error.md#conclusion
    // The message format assumes that the internal answer is mentioned before the external answer value
    const parts = message.split(STRING_INSERT);
    const result: string[] = [];

    const valueFormatters: { [key: string]: (value: unknown) => string } = {
        currency: (value: unknown) => Money.toString(USD((value as number) * 100)),
    };

    const defaultFormatter = (value: unknown) => String(value);
    const formatter = valueFormatters[valueType] || defaultFormatter;

    for (let i = 0; i < parts.length; i++) {
        result.push(parts[i]);
        if (i === 0) {
            result.push(formatter(internalFieldValue));
        } else if (i === 1 && parts.length > 2) {
            result.push(formatter(externalFieldValue));
        }
    }

    return result.join('');
};

export const convertComparisonValueType = (
    comparisonValue: unknown,
    type: Exclude<ValidationTypes, 'conditional'>,
    fieldKey?: string,
): number | string | boolean | undefined => {
    const value =
        assertNonArrayObject(comparisonValue) && fieldKey
            ? comparisonValue[fieldKey]
            : comparisonValue;

    if (value === undefined) {
        return;
    }

    const converters = {
        string: (value: unknown) => Joi.string().validate(String(value)), // We can be loose with the string conversion
        number: (value: unknown) => Joi.number().validate(value),
        boolean: (value: unknown) => Joi.boolean().validate(value),
        currency: (value: unknown) => Joi.number().validate(value),
    };

    const converter = converters[type];

    if (!converter) {
        return;
    }

    const { error, value: validatedValue } = converter(value);
    if (error) {
        return undefined;
    }

    switch (type) {
        case 'string':
            return String(validatedValue);
        case 'number':
        case 'currency':
            return Number(validatedValue);
        case 'boolean':
            return Boolean(validatedValue);
        default:
            return undefined;
    }
};

export const getValidationPropsFromComputeProps = (
    comparisonValue: string | number | boolean | undefined,
    computeValidationProps: ComputeValidationProps,
): ValidationTypeProps | undefined => {
    const { operator } = computeValidationProps;
    switch (computeValidationProps.valueType) {
        case 'string': {
            const stringOperator = isStringOperator(operator);
            if (stringOperator) {
                switch (operator) {
                    case 'equal':
                        return { string: { equal: comparisonValue as string } };
                }
            }
            return undefined;
        }
        case 'currency':
        case 'number': {
            const numberOperator = isNumberOperator(operator);
            if (numberOperator) {
                return { number: { [operator]: comparisonValue as number } };
            }
            return undefined;
        }
        case 'boolean': {
            const isValidOperator = ['equal'].includes(operator);
            return isValidOperator
                ? { boolean: { isTrue: comparisonValue as boolean } }
                : undefined;
        }
        default:
            return undefined;
    }
};

export function getAsArrayOfNumbers(
    values: unknown[] | readonly unknown[],
    fieldKey?: string,
): number[] {
    const convertedValues = values.map((value) =>
        convertComparisonValueType(value, 'number', fieldKey),
    );
    return convertedValues.filter(
        (item): item is number => typeof item === 'number' && !isNaN(item),
    );
}

export const getExternalFieldValue = (
    externalFieldValue: unknown,
    externalQuestionProps: ExternalQuestionProps,
): string | number | boolean | undefined => {
    const { valueType } = externalQuestionProps;
    const { fieldKey } = externalQuestionProps;

    // If there is no aggregator, then the value is the value
    if (!externalQuestionProps.aggregator) {
        return convertComparisonValueType(externalFieldValue, valueType, fieldKey);
    }

    const { aggregator } = externalQuestionProps;

    if (Array.isArray(externalFieldValue)) {
        switch (aggregator) {
            case 'sum': {
                const valueAsArrayOfNumbers = getAsArrayOfNumbers(externalFieldValue, fieldKey);
                const comparisonValue = valueAsArrayOfNumbers.reduce((acc, curr) => acc + curr, 0);
                return comparisonValue;
            }
        }
        return undefined;
    }

    return convertComparisonValueType(externalFieldValue, valueType, fieldKey);
};

export const buildDependentFieldValidator = (
    props: BuildConditionalValidationProps,
): SchemaType<any> => {
    return Joi.any().custom((internalFieldValue, helpers) => {
        const formValue = helpers.prefs.context;
        if (!assertNonArrayObject(formValue)) {
            return internalFieldValue;
        }

        const { conditionalValidationDefinition } = props;
        const { message, externalQuestionProps } = conditionalValidationDefinition;

        const externalFieldValue = getExternalFieldValue(
            formValue[externalQuestionProps.questionKey],
            externalQuestionProps,
        );
        const { valueType } = externalQuestionProps;

        const { validationProps, computeValidationProps } = conditionalValidationDefinition;
        // If we have validationProps, then use them, otherwise use computeValidationProps to generate validationProps
        const validationObject = validationProps
            ? validationProps
            : getValidationPropsFromComputeProps(externalFieldValue, computeValidationProps);

        if (!validationObject) {
            return internalFieldValue;
        }

        const validatorSchema = buildFieldValidator(validationObject);
        const validationResult = validatorSchema.validate(internalFieldValue);

        if (validationResult.error) {
            return helpers.error(
                `conditional.${buildValidationMessage({
                    internalFieldValue,
                    externalFieldValue,
                    valueType,
                    message,
                })}`,
            );
        }

        return internalFieldValue;
    });
};

// If we have externalQuestionProps.validationProps, then we use that to trigger conditional validation
// If we don't have externalQuestionProps.validationProps, then we always trigger conditional validation with Joi.any()
export const buildConditionalFieldValidator = (externalQuestionProps: ExternalQuestionProps) => {
    return externalQuestionProps.validationProps
        ? buildFieldValidator(externalQuestionProps.validationProps)
        : Joi.any();
};

export const buildConditionalValidator = (props: BuildConditionalValidationProps) => {
    const {
        conditionalValidationDefinition: { externalQuestionProps },
    } = props;

    return Joi.any().when(Joi.ref(`$${externalQuestionProps.questionKey}`), {
        is: buildConditionalFieldValidator(externalQuestionProps),
        then: buildDependentFieldValidator(props),
    });
};
