import React from 'react';
import { CardLayout, Text, Accordion } from '@embroker/ui-toolkit/v2';

export interface PolicyDetailsCardProps {
    title: React.ReactNode;
    children: React.ReactNode;
    isExpandable?: boolean;
    className?: string;
}

export const PolicyDetailsCard = ({
    title,
    children,
    isExpandable = false,
}: PolicyDetailsCardProps) => {
    const expandableDetailsCard = (
        <Accordion single>
            <Accordion.Item title={title}>{children}</Accordion.Item>
        </Accordion>
    );

    const nonExpandableDetailsCard = (
        <CardLayout>
            <CardLayout.Header>
                <Text style="heading 4">{title}</Text>
            </CardLayout.Header>
            <CardLayout.Body>{children}</CardLayout.Body>
        </CardLayout>
    );

    return isExpandable ? expandableDetailsCard : nonExpandableDetailsCard;
};
