import { API } from '@embroker/shotwell-api/app';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { hasOwnProp } from '@embroker/shotwell/core/object';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
    SuccessResult,
} from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { InvoiceRepository } from '../repositories/InvoiceRepository';

export interface LoadStripeResponse {
    stripeKey: string;
}

export interface LoadStripe extends UseCase {
    execute(request: any): AsyncResult<LoadStripeResponse, InvalidArgument | OperationFailed>;
}

@injectable()
class LoadStripeUseCase extends UseCase {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Payments/LoadStripe');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(InvoiceRepository) private invoiceRepo: InvoiceRepository,
    ) {
        super(eventBus);
    }

    async execute(
        request: any,
    ): AsyncResult<LoadStripeResponse, InvalidArgument | OperationFailed> {
        const stripeConfigResult = await this.getStripeConfig();

        if (isErr(stripeConfigResult)) {
            return handleOperationFailure(stripeConfigResult);
        }

        return Success<LoadStripeResponse>({ stripeKey: stripeConfigResult.value });
    }

    async getStripeConfig(): AsyncResult<string, InvalidArgument | OperationFailed> {
        return API.request('payments/stripe_config').then((result) => {
            if (isErr(result)) {
                return handleOperationFailure(result);
            }
            const { value } = result as SuccessResult<object>;
            if (!hasOwnProp(value, 'public_key')) {
                return Failure(InvalidArgument({ argument: 'public_key', value: undefined }));
            }
            return Success(String(value.public_key));
        });
    }
}

export const LoadStripe: UseCaseClass<LoadStripe> = LoadStripeUseCase;
