import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';

import { PaymentsConfig } from '../types/PaymentsConfig';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { QuoteRepository } from '../repositories/QuoteRepository';

export interface GetConfig extends UseCase {
    execute(): AsyncResult<PaymentsConfig, InvalidArgument | OperationFailed>;
}

@injectable()
export class GetConfigUseCase extends UseCase {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Payments/GetConfig');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(QuoteRepository) private quoteRepo: QuoteRepository,
    ) {
        super(eventBus);
    }

    public async execute(): AsyncResult<PaymentsConfig, InvalidArgument | OperationFailed> {
        const result = await this.quoteRepo.getPaymentConfig();

        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return Success(result.value);
    }
}

export const GetConfig: UseCaseClass<GetConfig> = GetConfigUseCase;
