import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject } from '@embroker/shotwell/core/di';
import { PCoMLQuote } from '../entities/PCoMLQuote';
import { QuoteRepository } from '../repositories/QuoteRepository';
import { ConfigFetchFailed } from '../errors';

export interface GetQuoteRequest {
    applicationId: UUID;
}

export interface GetQuoteResponse {
    quote: Immutable<PCoMLQuote>;
}

export interface GetQuote extends UseCase {
    execute(
        request: GetQuoteRequest,
    ): AsyncResult<
        GetQuoteResponse,
        UnknownEntity | InvalidArgument | OperationFailed | ConfigFetchFailed
    >;
}

class GetQuoteUseCase extends UseCase implements GetQuote {
    public static type = Symbol('PCoMLQuote/GetQuote');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(QuoteRepository) private quoteRepository: QuoteRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
    }: GetQuoteRequest): AsyncResult<
        GetQuoteResponse,
        UnknownEntity | InvalidArgument | OperationFailed | ConfigFetchFailed
    > {
        const quoteResult = await this.quoteRepository.getQuoteByApplicationId(applicationId);

        if (isErr(quoteResult)) {
            return quoteResult;
        }

        return Success<GetQuoteResponse>({
            quote: quoteResult.value,
        });
    }
}

export const GetQuote: UseCaseClass<GetQuote> = GetQuoteUseCase;
