import { Header } from '@embroker/ui-toolkit/v2';
import { NavigationalLogo } from './NavigationalLogo';
import React from 'react';

interface HeaderViewProps {
    children?: React.ReactNode;
}

export function HeaderView({ children }: HeaderViewProps) {
    return (
        <Header>
            <NavigationalLogo />
            {children}
        </Header>
    );
}
