import { inject, injectable, container } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { CalculateSKUFromAppTypes } from '../../analytics/useCases/CalculateSKUFromAppTypes';
import { AppTypeCode } from '../../shopping/types/enums';
import { UserSignUpStarted } from '../entities/User';
import { SKU } from '../../analytics/types/SKU';
import { Coverage } from '../../shopping/types/Coverage';

export interface PublishSignUpStartedEvent extends UseCase {
    execute(selectedCoverages?: Coverage[]): AsyncResult<void>;
}

@injectable()
class PublishSignUpStartedEventUseCase extends UseCase implements PublishSignUpStartedEvent {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/PublishSignUpStartedEventUseCase');

    /**
     * Constructor for PublishSignUpStartedEventUseCase class instance
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    /**
     * Executes the PublishSignUpStartedEvent use case.
     */
    public async execute(selectedCoverages: Coverage[] = []): AsyncResult<void> {
        let selectedAppTypes: AppTypeCode[] | undefined;
        if (selectedCoverages) {
            selectedAppTypes = selectedCoverages.map((coverage) => coverage.appType);
        }
        let sku: SKU | undefined;
        if (selectedAppTypes) {
            const calculateSKUFromAppTypesUseCase = container.get<CalculateSKUFromAppTypes>(
                CalculateSKUFromAppTypes.type,
            );
            const skuResult = await calculateSKUFromAppTypesUseCase.execute({
                appTypeList: selectedAppTypes,
            });
            sku = skuResult.value;
        }
        const signUpStartedEvent: UserSignUpStarted = {
            origin: 'User',
            name: 'SignUpStarted',
            createdAt: new Date(Date.now()),
            id: UUID.create(),
            sku,
        };

        await this.eventBus.publish(signUpStartedEvent);

        return Success();
    }
}

export const PublishSignUpStartedEvent: UseCaseClass<PublishSignUpStartedEvent> =
    PublishSignUpStartedEventUseCase;
