export interface LimitCombinationsMap {
    [perClaimLimit: number]: number[];
}

export const PerClaimLimitType = 'MultiValueLimitNameListPerClaimLimit';
export const AggregateLimitType = 'MultiValueLimitNameListAggregateLimit';
export const SingleLimit = 'MultiValueLimitNameListSingleLimit';
export const MultiLimitTypes = [PerClaimLimitType, AggregateLimitType];
export const LimitTypes = [...MultiLimitTypes, SingleLimit];
export type LimitType = (typeof LimitTypes)[number];

export function isLimitType(limit: Record<string, number>): limit is Record<LimitType, number> {
    const propListPresent = (inspectObject: Record<string, number>, ...props: string[]): boolean =>
        props.reduce((acc: boolean, curr: string) => acc && curr in inspectObject, true);
    return propListPresent(limit, ...MultiLimitTypes);
}
