import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { TextButton } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { WizardForm } from '../../../view/hooks/useWizardForm';

interface DownloadSignaturePacketButtonProps<T extends FormData> {
    isSubmitting: boolean;
    trigger: WizardForm<OpaqueForm<T>>['trigger'];
    isDisabled?: boolean;
}

export function DownloadSignaturePacketButton<T extends FormData>({
    isSubmitting,
    trigger,
    isDisabled,
}: DownloadSignaturePacketButtonProps<T>) {
    return (
        <TextButton
            as="button"
            disabled={isDisabled || isSubmitting}
            icon="download"
            onClick={() => trigger('downloadSignaturePacket')}
        >
            Download signature packet
        </TextButton>
    );
}
