import { StackLayout, Text, Button } from '@embroker/ui-toolkit/v2';
import React from 'react';

interface PCoMLEndorsementInvoiceModalProps {
    onConfirm: () => void;
}

export function PCoMLEndorsementInvoiceModal({ onConfirm }: PCoMLEndorsementInvoiceModalProps) {
    const handleOnClose = () => {
        onConfirm();
    };

    return (
        <StackLayout gap="32">
            <StackLayout gap="24">
                <Text style="heading 3">Your invoice is on the way!</Text>
                <Text>
                    You will receive an email with payment instructions shortly. Your digital
                    policies will always be available in your Embroker account.
                </Text>
            </StackLayout>
            <Button onClick={handleOnClose}>Got it</Button>
        </StackLayout>
    );
}
