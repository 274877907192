import { StackLayout, InsetBox, ComparisonTable, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';

export const ESPEplDifferenceModal = function ESPEplDifferenceModal() {
    return (
        <StackLayout>
            <Text style="heading 3">What is “EPLI Plus” Coverage?</Text>
            <Text>Cover an even broader range of risk with best-in-market coverage.</Text>
            <InsetBox>
                <ComparisonTable
                    comparisonData={[
                        {
                            topic: (
                                <React.Fragment>
                                    <Text style="heading 4">Wage &amp; Hour Sublimit:</Text>
                                    <Text style="body 2">
                                        We offer an increased amount of coverage in our EPLI Plus
                                        product.
                                    </Text>
                                </React.Fragment>
                            ),
                            plus: true,
                            standard: false,
                        },
                    ]}
                />
            </InsetBox>
        </StackLayout>
    );
};
