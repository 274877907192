import { ContainerModule } from '@embroker/shotwell/core/di';
import { Module } from '../Module';
import { RecommendRepository } from './repositories/RecommendRepository/index';
import { APIRecommendRepository } from './repositories/RecommendRepository/APIRecommendRepository';
import { GetRecommendedCoverages } from './useCases/GetRecommendedCoverages';
import { GetVerticalRecommendedCoverages } from '../shopping/useCases/GetVerticalRecommendedCoverages';

export const RecommendationsModule: Module = {
    container: new ContainerModule((bind) => {
        bind<RecommendRepository>(RecommendRepository)
            .to(APIRecommendRepository)
            .inSingletonScope();
        bind<GetRecommendedCoverages>(GetRecommendedCoverages.type)
            .to(GetRecommendedCoverages)
            .inSingletonScope();
        bind<GetVerticalRecommendedCoverages>(GetVerticalRecommendedCoverages.type)
            .to(GetVerticalRecommendedCoverages)
            .inSingletonScope();
    }),
};
