import * as API from '@embroker/shotwell-api/app';
import {
    LineOfBusinessCodeListItem,
    LineOfBusinessSubtypeCodeListItem,
    StructuralComponentTypeCodeListItem,
} from '@embroker/shotwell-api/enums';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, UnknownEntity } from '@embroker/shotwell/core/Error';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { Location as LocationCore, LocationType } from '@embroker/shotwell/core/types/Location';
import { PhoneNumber } from '@embroker/shotwell/core/types/PhoneNumber';
import {
    AsyncResult,
    Failure,
    isErr,
    mergeErrors,
    Result,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { State } from '@embroker/shotwell/core/types/StateList';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import {
    CertificateRepository,
    CreateSelfServingCertificateRequest,
    CreateSelfServingCertificateResponse,
    GetCertificateForActiveOrganizationRequest,
    GetSelfServingCertificateCoverageListResponse,
    PreviewSelfServingCertificateRequest,
    PreviewSelfServingCertificateResponse,
    SelfServingCertificateRequest,
} from '.';
import { Location } from '../../../locations/types/Location';
import { Certificate } from '../../entities/Certificate';
import {
    CertificateNotFound,
    CreateSelfServingCertificateFailed,
    GetCertificatesForActiveOrganizationFailed,
    GetSelfServingCoverageListFailed,
    GetShareableCertificateDataFailed,
    PreviewSelfServingCertificateFailed,
    SelfServingCertificateRequestFailed,
    SaveCertificatePhoneNumberNull,
} from '../../errors';
import { CertificateCoverage } from '../../types/CertificateCoverage';
import { CertificateCoverageAuto } from '../../types/CertificateCoverageAuto';
import { CertificateCoverageGeneralLiability } from '../../types/CertificateCoverageGeneralLiability';
import { CertificateCoverageInfo } from '../../types/CertificateCoverageInfo';
import { CertificateCoverageLimit } from '../../types/CertificateCoverageLimit';
import { CertificateCoverageOther } from '../../types/CertificateCoverageOther';
import { CertificateCoverageUmbrellaExcess } from '../../types/CertificateCoverageUmbrellaExcess';
import { CertificateCoverageWorkersCompensation } from '../../types/CertificateCoverageWorkersCompensation';
import { CertificateDocument } from '../../types/CertificateDocument';
import {
    CertificateEntityRole,
    CertificateEntityRoleType,
} from '../../types/CertificateEntityRole';
import { CertificateInsurer } from '../../types/CertificateInsurer';
import { CertificateProducer } from '../../types/CertificateProducer';
import {
    SelfServingCertificateCoverage,
    SelfServingCertificateCoverageList,
} from '../../types/SelfServingCertificateCoverage';

@injectable()
export class APICertificateRepository implements CertificateRepository {
    private certificateMap: Map<UUID, Certificate>;

    constructor() {
        this.certificateMap = new Map<UUID, Certificate>();
    }

    public async getCertificate(certificateId: UUID): AsyncResult<Certificate, UnknownEntity> {
        if (this.certificateMap.has(certificateId)) {
            const result = this.certificateMap.get(certificateId);
            return Success(result as Certificate);
        }

        return Failure(UnknownEntity('Certificate', certificateId));
    }

    public async getCertificateForActiveOrganization(
        request: GetCertificateForActiveOrganizationRequest,
    ): AsyncResult<Certificate, InvalidArgument | CertificateNotFound> {
        const certificateAPIResult = await API.API.request(
            'certificate/get_certificate_for_organization',
            {
                certificate_id: request.certificateID,
            } as API.CertificateGetCertificateForOrganizationRequest,
        );

        if (isErr(certificateAPIResult)) {
            return Failure(CertificateNotFound(request.certificateID, certificateAPIResult.errors));
        }

        const certificate = await APICertificateRepository.toCertificate(
            certificateAPIResult.value as API.CertificateWithId,
        );

        if (isErr(certificate)) {
            return certificate;
        }

        return Success(certificate.value);
    }

    public async getCertificatesForActiveOrganization(): AsyncResult<
        Certificate[],
        InvalidArgument | GetCertificatesForActiveOrganizationFailed
    > {
        const result: Certificate[] = [];

        const certificateResult = await API.API.request('certificate/issued_certificates', {
            count: null,
            desc_sort: null,
            paging_id: null,
            page_number: null,
            sort_by: null,
        } as API.CertificateIssuedCertificatesRequest);

        if (isErr(certificateResult)) {
            return Failure(GetCertificatesForActiveOrganizationFailed(certificateResult.errors));
        }

        const results = await Promise.all(
            certificateResult.value.map((item) =>
                APICertificateRepository.toCertificate(item as API.CertificateWithId),
            ),
        );

        for (const item of results) {
            if (isErr(item)) {
                return mergeErrors(results);
            }
            result.push(item.value as Certificate);
        }

        return Success(result);
    }

    private static async toCertificate(
        certificate: API.CertificateWithId,
    ): AsyncResult<Certificate, InvalidArgument> {
        const coverageListResult = APICertificateRepository.toCertificateCoverageList(
            certificate.coverage_list,
        );

        if (isErr(coverageListResult)) {
            return coverageListResult;
        }

        const certificateProducer = APICertificateRepository.toCertificateProducer(
            certificate.producer as API.CertificateProducer,
        );

        if (isErr(certificateProducer)) {
            return certificateProducer;
        }

        const holder = APICertificateRepository.toCertificateEntityRole(
            certificate.holder as API.CertificateEntityRole,
            'Holder',
        );

        if (isErr(holder)) {
            return holder;
        }

        const owner = APICertificateRepository.toCertificateEntityRole(
            certificate.owner as API.CertificateEntityRole,
            'Owner',
        );

        if (isErr(owner)) {
            return owner;
        }

        const documentList = APICertificateRepository.toCertificateDocument(
            certificate.document_list,
        );

        if (isErr(documentList)) {
            return documentList;
        }

        const result = await Certificate.create({
            certificateHolder: holder.value as CertificateEntityRole,
            certificateOwner: owner.value as CertificateEntityRole,
            certificateProducer: certificateProducer.value as CertificateProducer,
            coverageList: coverageListResult.value as CertificateCoverage[],
            additionalInsuredName: certificate.additional_insured_name ?? undefined,
            certificateType: certificate.certificate_type,
            customWording: certificate.custom_wording ?? undefined,
            customerAdditionalRequirementsText:
                certificate.customer_additional_requirements_text ?? undefined,
            documentList: documentList.value as CertificateDocument[],
            isIncludedForRenewal: certificate.included_for_renewal,
            issuedByCertificateTemplateId:
                certificate.issued_by_certificate_template_id ?? undefined,
            referenceNumber: certificate.reference_number ?? undefined,
            certificateSharingStatus: certificate.certificate_sharing_status,
            createdDateTime: certificate.creation_time,
            completedDateTime: certificate.completed_date_time ?? undefined,
        });

        if (isErr(result)) {
            return Failure(
                InvalidArgument({
                    argument: 'certificateCreate',
                    value: result,
                    responseBody: result.errors,
                }),
            );
        }

        return Success(result.value);
    }

    public async getShareableCertificateData(
        organizationId: UUID,
    ): AsyncResult<Certificate, InvalidArgument | GetShareableCertificateDataFailed> {
        const shareableData = await API.API.request(
            'certificate/get_owner_shareable_certificate_data',
            {
                organization_id: organizationId,
            },
        );

        if (isErr(shareableData)) {
            return Failure(GetShareableCertificateDataFailed(shareableData.errors, organizationId));
        }

        const result = await APICertificateRepository.toShareableCertificateData(
            shareableData.value as API.Certificate,
        );

        if (isErr(result)) {
            return result;
        }

        return Success(result.value);
    }

    public async sendSelfServingCertificateRequest(
        request: SelfServingCertificateRequest,
    ): AsyncResult<UUID, SelfServingCertificateRequestFailed | SaveCertificatePhoneNumberNull> {
        const apiCertificateResult = APICertificateRepository.toApiRequestSelfServingCertificate(
            request.certificate,
            request.selfServingCoverageList,
        );

        if (isErr(apiCertificateResult)) {
            return apiCertificateResult;
        }

        const response = await API.API.request(
            'certificate/send_certificate',
            apiCertificateResult.value,
        );

        if (isErr(response)) {
            return Failure(
                SelfServingCertificateRequestFailed(response.errors, request.certificate),
            );
        }

        this.certificateMap.set(request.certificate.id, request.certificate);
        return Success(request.certificate.id);
    }

    public async delete(id: UUID): AsyncResult<void, InvalidArgument> {
        this.certificateMap.delete(id);
        return Success();
    }

    private static toCertificateCoverageList(
        coverageList: API.CertificateCoverageList | API.CertificateCoverageWithIdList,
    ): Result<CertificateCoverage[], InvalidArgument> {
        const list: CertificateCoverage[] = [];
        const results = (
            coverageList as Array<CertificateCoverage | API.CertificateCoverageWithId>
        ).map((element: any) => {
            return CertificateCoverage.create(element.structural_component_type_code);
        });

        for (const result of results) {
            if (isErr(result)) {
                return mergeErrors(results);
            }
            list.push(result.value);
        }

        return Success(list);
    }

    private static toCertificateDocument(
        documentList: API.CertificateDocumentList | API.CertificateDocumentWithIdList,
    ): Result<CertificateDocument[], InvalidArgument> {
        const list: CertificateDocument[] = [];
        const results = (
            documentList as Array<API.CertificateDocument | API.CertificateDocumentWithId>
        ).map((element) => {
            return CertificateDocument.create({
                dateUploaded: element.date_uploaded as Date,
                fileKey: element.file_key,
                isActive: element.is_active,
                name: element.name ?? undefined,
                type: element.type,
            });
        });

        for (const result of results) {
            if (isErr(result)) {
                return mergeErrors(results);
            }
            list.push(result.value);
        }

        return Success(list);
    }

    private static toApiCertificateDocumentList(
        documentList: CertificateDocument[],
    ): API.CertificateDocumentList {
        const result: API.CertificateDocumentList = [];

        for (const element of documentList) {
            result.push({
                name: element.name,
                file_key: element.fileKey,
                type: element.type,
                is_active: element.isActive,
                date_uploaded: element.dateUploaded,
                turnstile_status: null,
            } as API.CertificateDocument);
        }

        return result;
    }

    private static toApiCertificateEntityRole(
        data?: CertificateEntityRole,
    ): Nullable<API.CertificateEntityRole> {
        if (data === undefined) {
            return null;
        }

        let location: Nullable<API.Location> = null;

        if (data.location !== undefined) {
            location = {
                id: null,
                address_lines:
                    data.location.addressLine1 +
                    (data.location.addressLine2 ? '\n' + data.location.addressLine2 : ''),
                city: data.location.city ?? null,
                zip_code: data.location.zip ?? null,
                state: data.location.state ?? null,
                square_footage_occupied: null,
                county: null,
                value_of_property: null,
            };
        }

        return {
            name: data.organizationName,
            email: data.organizationEmail ?? null,
            token: data.token ?? null,
            signed_up: data.signedUp ?? null,
            org_id: data.organizationId ?? null,
            location: location,
            user_id: data.userId ?? null,
            user_email: data.userEmail ?? null,
            suppress_email_notification: data.suppressEmailNotifications ?? null,
        };
    }

    private static parseAddressLines(addressLines: Nullable<string>): {
        addressLine1?: string;
        addressLine2?: string;
    } {
        if (typeof addressLines !== 'string') {
            return {};
        }
        const addressLineParts = addressLines.split('\n');

        return {
            addressLine1:
                addressLineParts[0] !== undefined && addressLineParts[0].trim() !== ''
                    ? addressLineParts[0].trim()
                    : undefined,
            addressLine2:
                addressLineParts[1] !== undefined && addressLineParts[1].trim() !== ''
                    ? addressLineParts[1].trim()
                    : undefined,
        };
    }

    private static toApiAddress(
        addressLine1: string | null | undefined,
        addressLine2: string | null | undefined,
    ): string | null {
        let addressLine;
        if (typeof addressLine1 === 'string' && addressLine1.trim().length !== 0) {
            addressLine = addressLine1;
        }
        if (typeof addressLine2 === 'string' && addressLine2.trim().length !== 0) {
            if (addressLine) {
                addressLine += `\n${addressLine2}`;
            } else {
                addressLine = addressLine2;
            }
        }
        if (addressLine === undefined) {
            return null;
        }
        return addressLine;
    }

    private static locationApiToEntity(
        data: API.Location,
    ): Result<Nullable<LocationCore>, InvalidArgument> {
        if (!data) {
            return Success(null);
        }

        const addressLines = APICertificateRepository.parseAddressLines(data.address_lines);
        const result = LocationCore.create({
            type: 'headquarters' as LocationType,
            addressLine1: addressLines.addressLine1 ?? null,
            addressLine2: addressLines.addressLine2 ?? null,
            city: data.city ?? null,
            state: APICertificateRepository.toState(data.state) ?? null,
            zip: data.zip_code as ZipCode,
            phoneNumber: null,
        });

        if (isErr(result)) {
            return mergeErrors([result]);
        }

        return Success(result.value);
    }

    private static toCertificateEntityRole(
        data: API.CertificateEntityRole,
        roleType: CertificateEntityRoleType,
    ): Result<Nullable<CertificateEntityRole>, InvalidArgument> {
        if (!data) {
            return Success(null);
        }

        const location = APICertificateRepository.locationApiToEntity(
            data.location as API.Location,
        );

        if (isErr(location)) {
            return mergeErrors([location]);
        }

        const result = CertificateEntityRole.create({
            roleType: roleType,
            organizationId: data.org_id ?? undefined,
            organizationName: data.name,
            organizationEmail: (data.email as EmailAddress) ?? undefined,
            userId: data.user_id ?? undefined,
            userEmail: (data.user_email as EmailAddress) ?? undefined,
            location: (location.value as LocationCore) ?? undefined,
            signedUp: data.signed_up ?? undefined,
            token: data.token ?? undefined,
            suppressEmailNotifications: data.suppress_email_notification ?? undefined,
        });

        if (isErr(result)) {
            return mergeErrors([result]);
        }

        return Success(result.value);
    }

    private static toState(state: Nullable<string>): State | null {
        const stateResult = State.getCodeByName(state || '');
        return stateResult || null;
    }

    private static toCertificateProducer(
        data: API.CertificateProducer,
    ): Result<CertificateProducer, InvalidArgument> {
        const re = /\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})/g;
        const subst = '$1$2$3';
        const phoneNumber = data.phone_number.replace(re, subst);

        const addressLines = APICertificateRepository.parseAddressLines(data.address);
        const location = LocationCore.create({
            type: 'other' as LocationType,
            addressLine1: addressLines.addressLine1 ?? null,
            addressLine2: addressLines.addressLine2 ?? null,
            city: data.city,
            state: APICertificateRepository.toState(data.state),
            zip: data.zip_code as ZipCode,
            phoneNumber: phoneNumber as PhoneNumber,
        });

        if (isErr(location)) {
            return mergeErrors([location]);
        }

        const result = CertificateProducer.create({
            certificateCreator: data.certificate_creator,
            name: data.name,
            contactName: data.contact_name || undefined,
            email: data.email as EmailAddress,
            location: location.value as LocationCore,
        });

        if (isErr(result)) {
            return mergeErrors([result]);
        }

        return Success(result.value);
    }

    private static toApiProducer(
        data: CertificateProducer,
    ): Result<API.CertificateProducer, SaveCertificatePhoneNumberNull> {
        if (data.location.phoneNumber === null) {
            return Failure(SaveCertificatePhoneNumberNull());
        }

        return Success({
            certificate_creator: data.certificateCreator,
            name: data.name,
            contact_name: data.contactName || null,
            address:
                APICertificateRepository.toApiAddress(
                    data.location.addressLine1,
                    data.location.addressLine2,
                ) || '',
            city: data.location.city as string,
            state: data.location.state as string,
            zip_code: data.location.zip as string,
            email: data.email,
            phone_number: data.location.phoneNumber,
        });
    }

    private static async toShareableCertificateData(
        certificate: API.Certificate,
    ): AsyncResult<Certificate, InvalidArgument> {
        const coverageListResult = APICertificateRepository.toCertificateCoverageList(
            certificate.coverage_list,
        );

        if (isErr(coverageListResult)) {
            return coverageListResult;
        }

        const certificateProducer = APICertificateRepository.toCertificateProducer(
            certificate.producer as API.CertificateProducer,
        );

        if (isErr(certificateProducer)) {
            return certificateProducer;
        }

        const holder = APICertificateRepository.toCertificateEntityRole(
            certificate.holder as API.CertificateEntityRole,
            'Holder',
        );

        if (isErr(holder)) {
            return holder;
        }

        const owner = APICertificateRepository.toCertificateEntityRole(
            certificate.owner as API.CertificateEntityRole,
            'Owner',
        );

        if (isErr(owner)) {
            return owner;
        }

        const documentList = APICertificateRepository.toCertificateDocument(
            certificate.document_list,
        );

        if (isErr(documentList)) {
            return documentList;
        }

        const result = await Certificate.create({
            certificateHolder: holder.value ?? undefined,
            certificateOwner: owner.value as CertificateEntityRole,
            certificateProducer: certificateProducer.value,
            coverageList: coverageListResult.value as CertificateCoverage[],
            additionalInsuredName: certificate.additional_insured_name ?? undefined,
            certificateType: certificate.certificate_type,
            customWording: certificate.custom_wording ?? undefined,
            customerAdditionalRequirementsText:
                certificate.customer_additional_requirements_text ?? undefined,
            documentList: documentList.value as CertificateDocument[],
            isIncludedForRenewal:
                certificate.included_for_renewal === null
                    ? false
                    : certificate.included_for_renewal,
            issuedByCertificateTemplateId:
                certificate.issued_by_certificate_template_id ?? undefined,
            referenceNumber: certificate.reference_number ?? undefined,
            completedDateTime: certificate.completed_date_time ?? undefined,
        });

        if (isErr(result)) {
            return Failure(
                InvalidArgument({
                    argument: 'certificateCreate',
                    value: result,
                    responseBody: result.errors,
                }),
            );
        }

        return Success(result.value);
    }

    private static toApiRequestSelfServingCertificate(
        certificate: Certificate,
        selfServingCoverageList: SelfServingCertificateCoverageList,
    ): Result<API.Certificate, SaveCertificatePhoneNumberNull> {
        const apiProducer = APICertificateRepository.toApiProducer(certificate.certificateProducer);

        if (isErr(apiProducer)) {
            return apiProducer;
        }

        const result: API.Certificate = {
            custom_wording: certificate.customWording ?? null,
            certificate_owner_email_entered_by_holder: null,
            reference_number: certificate.referenceNumber ?? null,
            additional_insured_text: null,
            additional_insured_name: certificate.additionalInsuredName ?? null,
            customer_additional_requirements_text:
                certificate.customerAdditionalRequirementsText ?? null,
            included_for_renewal: certificate.isIncludedForRenewal ?? null,
            description_of_operations: null,
            certificate_type: certificate.certificateType,
            completed_date_time: null,
            producer: apiProducer.value,
            holder: APICertificateRepository.toApiCertificateEntityRole(
                certificate.certificateHolder,
            ),
            owner: APICertificateRepository.toApiCertificateEntityRole(
                certificate.certificateOwner,
            ),
            issued_by_certificate_template_id: null,
            document_list: APICertificateRepository.toApiCertificateDocumentList(
                certificate.documentList,
            ),
            coverage_list:
                APICertificateRepository.toApiCertificateCoverageCreateList(
                    selfServingCoverageList,
                ),
            additional_email_input: null,
            additional_email_recipients: null,
        };

        return Success(result);
    }

    public async getSelfServingCoverageList(): AsyncResult<
        GetSelfServingCertificateCoverageListResponse,
        InvalidArgument | GetSelfServingCoverageListFailed
    > {
        const certificateCoverageList = await API.API.request(
            'certificate/get_self_serving_shareable_coverage_list',
        );

        if (isErr(certificateCoverageList)) {
            return Failure(GetSelfServingCoverageListFailed(certificateCoverageList.errors));
        }

        const result: GetSelfServingCertificateCoverageListResponse = {
            selfServingCoverageList: [],
        };

        for (const coverage of certificateCoverageList.value) {
            const selfServingCoverage = APICertificateRepository.toSelfServingCoverage(coverage);
            if (isErr(selfServingCoverage)) {
                return selfServingCoverage;
            }

            result.selfServingCoverageList.push(selfServingCoverage.value);
        }

        return Success(result);
    }

    private static toSelfServingCoverage(
        apiCertificateCoverage: Immutable<API.CertificateCoverage>,
    ): Result<SelfServingCertificateCoverage, InvalidArgument> {
        let selfServingCoverage: Result<SelfServingCertificateCoverage, InvalidArgument>;
        if (
            apiCertificateCoverage.acord_section_type ===
            'CertificateSectionCodeListAcord25GeneralLiability'
        ) {
            selfServingCoverage =
                APICertificateRepository.toCoverageGeneralLiability(apiCertificateCoverage);
        } else if (
            apiCertificateCoverage.acord_section_type ===
            'CertificateSectionCodeListAcord25AutoLiability'
        ) {
            selfServingCoverage = APICertificateRepository.toCoverageAuto(apiCertificateCoverage);
        } else if (
            apiCertificateCoverage.acord_section_type ===
            'CertificateSectionCodeListAcord25UmbrellaExcessLiability'
        ) {
            selfServingCoverage =
                APICertificateRepository.toCoverageUmbrellaExcess(apiCertificateCoverage);
        } else if (
            apiCertificateCoverage.acord_section_type ===
            'CertificateSectionCodeListAcord25WorkersCompensation'
        ) {
            selfServingCoverage =
                APICertificateRepository.toCoverageWorkersCompensation(apiCertificateCoverage);
        } else {
            selfServingCoverage = APICertificateRepository.toCoverageOther(apiCertificateCoverage);
        }

        return selfServingCoverage;
    }

    private static toCertificateInsurer(
        apiInsurer: Nullable<API.CertificateInsurer>,
    ): Result<CertificateInsurer, InvalidArgument> | undefined {
        if (apiInsurer === null) {
            return undefined;
        }

        return CertificateInsurer.create({
            name: apiInsurer.name,
            naic: apiInsurer.naic,
        });
    }

    private static toCertificateCoverageLimitList(
        apiCoverageLimitList: Immutable<API.CertificateCoverageLimit[]>,
    ): Result<CertificateCoverageLimit[], InvalidArgument> {
        const result: CertificateCoverageLimit[] = [];
        for (const coverageLimit of apiCoverageLimitList) {
            const coverageLimitResult = CertificateCoverageLimit.create({
                description: coverageLimit.description ?? undefined,
                amount: coverageLimit.amount ?? undefined,
                customName: coverageLimit.custom_name ?? undefined,
                limitTypeCode: coverageLimit.limit_type_code ?? undefined,
                liabilityTypeCode: coverageLimit.liability_type_code ?? undefined,
            });
            if (isErr(coverageLimitResult)) {
                return coverageLimitResult;
            }
            result.push(coverageLimitResult.value);
        }
        return Success(result);
    }

    private static toCoverageAuto(
        apiCoverageAuto: Immutable<API.CertificateCoverage>,
    ): Result<CertificateCoverageAuto, InvalidArgument> {
        const coverageInfo = APICertificateRepository.toCoverageInfo(apiCoverageAuto);
        if (isErr(coverageInfo)) {
            return coverageInfo;
        }
        return CertificateCoverageAuto.create({
            coverageInfo: coverageInfo.value,
            anyAuto: apiCoverageAuto.coverage_auto_any_auto ?? undefined,
            allOwnedAutos: apiCoverageAuto.coverage_auto_all_owned_autos ?? undefined,
            hiredAutos: apiCoverageAuto.coverage_auto_hired_autos ?? undefined,
            scheduledAutos: apiCoverageAuto.coverage_auto_scheduled_autos ?? undefined,
            nonOwnedAutos: apiCoverageAuto.coverage_auto_non_owned_autos ?? undefined,
            other1: apiCoverageAuto.coverage_auto_other_1 ?? undefined,
            other2: apiCoverageAuto.coverage_auto_other_2 ?? undefined,
        });
    }

    private static toCoverageOther(
        apiCoverageOther: Immutable<API.CertificateCoverage>,
    ): Result<CertificateCoverageOther, InvalidArgument> {
        const coverageInfo = APICertificateRepository.toCoverageInfo(apiCoverageOther);
        if (isErr(coverageInfo)) {
            return coverageInfo;
        }

        return CertificateCoverageOther.create({
            coverageInfo: coverageInfo.value,
        });
    }

    private static toCoverageGeneralLiability(
        apiCoverageGeneralLiability: Immutable<API.CertificateCoverage>,
    ): Result<CertificateCoverageGeneralLiability, InvalidArgument> {
        const coverageInfo = APICertificateRepository.toCoverageInfo(apiCoverageGeneralLiability);
        if (isErr(coverageInfo)) {
            return coverageInfo;
        }
        return CertificateCoverageGeneralLiability.create({
            coverageInfo: coverageInfo.value,
            claimsMade: apiCoverageGeneralLiability.coverage_gl_claims_made ?? undefined,
            occur: apiCoverageGeneralLiability.coverage_gl_occur ?? undefined,
            aggregateLimitAppliesPerPolicy:
                apiCoverageGeneralLiability.coverage_gl_aggregate_limit_applies_per_policy ??
                undefined,
            aggregateLimitAppliesPerProject:
                apiCoverageGeneralLiability.coverage_gl_aggregate_limit_applies_per_project ??
                undefined,
            aggregateLimitAppliesPerLoc:
                apiCoverageGeneralLiability.coverage_gl_aggregate_limit_applies_per_loc ??
                undefined,
            aggregateLimitAppliesPerOther:
                apiCoverageGeneralLiability.coverage_gl_aggregate_limit_applies_per_other ??
                undefined,
        });
    }

    private static toCoverageUmbrellaExcess(
        apiCoverageUmbrellaExcess: Immutable<API.CertificateCoverage>,
    ): Result<CertificateCoverageUmbrellaExcess, InvalidArgument> {
        const coverageInfo = APICertificateRepository.toCoverageInfo(apiCoverageUmbrellaExcess);
        if (isErr(coverageInfo)) {
            return coverageInfo;
        }
        return CertificateCoverageUmbrellaExcess.create({
            coverageInfo: coverageInfo.value,
            isUmbrella: apiCoverageUmbrellaExcess.coverage_um_ex_is_umbrella ?? undefined,
            deductible: apiCoverageUmbrellaExcess.coverage_um_ex_deductible ?? undefined,
            retention: apiCoverageUmbrellaExcess.coverage_um_ex_retention ?? undefined,
            claimsMade: apiCoverageUmbrellaExcess.coverage_um_ex_claims_made ?? undefined,
            occur: apiCoverageUmbrellaExcess.coverage_um_ex_occur ?? undefined,
        });
    }

    private static toCoverageWorkersCompensation(
        apiCoverageWorkersCompensation: Immutable<API.CertificateCoverage>,
    ): Result<CertificateCoverageWorkersCompensation, InvalidArgument> {
        const coverageInfo = APICertificateRepository.toCoverageInfo(
            apiCoverageWorkersCompensation,
        );
        if (isErr(coverageInfo)) {
            return coverageInfo;
        }
        return CertificateCoverageWorkersCompensation.create({
            coverageInfo: coverageInfo.value,
            isExecutiveOfficerExcluded:
                apiCoverageWorkersCompensation.coverage_workers_comp_executive_officer_excluded ??
                undefined,
            isPerStatute:
                apiCoverageWorkersCompensation.coverage_workers_comp_per_statute ?? undefined,
            other: apiCoverageWorkersCompensation.coverage_workers_comp_other ?? undefined,
        });
    }

    private static toLocation(apiLocation: API.Location): Result<Location, InvalidArgument> {
        const addressLines = APICertificateRepository.parseAddressLines(apiLocation.address_lines);
        return Location.create({
            city: apiLocation.city ?? undefined,
            zip: ZipCode.check(apiLocation.zip_code) ? apiLocation.zip_code : undefined,
            valueOfProperty: apiLocation.value_of_property ?? undefined,
            county: apiLocation.county === '' ? undefined : apiLocation.county ?? undefined,
            state: State.check(apiLocation.state)
                ? apiLocation.state
                : State.getCodeByName(apiLocation.state) ?? undefined,
            addressLine1: addressLines.addressLine1 ?? undefined,
            addressLine2: addressLines.addressLine2 ?? undefined,
            squareFootageOccupied: apiLocation.square_footage_occupied ?? undefined,
        });
    }

    private static toCoverageInfo(
        apiCertificateCoverage: Immutable<API.CertificateCoverage>,
    ): Result<CertificateCoverageInfo, InvalidArgument> {
        const locations: Location[] = [];
        for (const location of apiCertificateCoverage.location_list) {
            const locationResult = APICertificateRepository.toLocation(location);
            if (isErr(locationResult)) {
                return locationResult;
            }
            locations.push(locationResult.value);
        }

        const coverageLimits = APICertificateRepository.toCertificateCoverageLimitList(
            apiCertificateCoverage.limits,
        );
        if (isErr(coverageLimits)) {
            return coverageLimits;
        }

        const coverageInsurer = APICertificateRepository.toCertificateInsurer(
            apiCertificateCoverage.insurer,
        );
        if (coverageInsurer !== undefined && isErr(coverageInsurer)) {
            return coverageInsurer;
        }

        return CertificateCoverageInfo.create({
            createdAt: apiCertificateCoverage.created_at ?? undefined,
            accordSectionType: apiCertificateCoverage.acord_section_type ?? undefined,
            policyId: apiCertificateCoverage.policy_id ?? undefined,
            policyNumber: apiCertificateCoverage.policy_number ?? undefined,
            customName: apiCertificateCoverage.custom_name ?? undefined,
            policyLineOfBusiness:
                (apiCertificateCoverage.policy_line_of_business as LineOfBusinessCodeListItem) ??
                undefined,
            policyLineOfBusinessSubtype:
                (apiCertificateCoverage.policy_line_of_business_subtype as LineOfBusinessSubtypeCodeListItem) ??
                undefined,
            policyEffectiveDate: apiCertificateCoverage.policy_effective_date ?? undefined,
            policyEndDate: apiCertificateCoverage.policy_end_date ?? undefined,
            policyCancellationDate: apiCertificateCoverage.policy_cancellation_date ?? undefined,
            insurer: coverageInsurer !== undefined ? coverageInsurer.value : undefined,
            requestedAmount: apiCertificateCoverage.requested_amount ?? undefined,
            requestedLineOfBusiness: apiCertificateCoverage.requested_line_of_business ?? undefined,
            structuralComponentTypeCode:
                apiCertificateCoverage.structural_component_type_code as StructuralComponentTypeCodeListItem,
            waiverOfSubrogation: apiCertificateCoverage.waiver_of_subrogation ?? undefined,
            primaryNonContributory: apiCertificateCoverage.primary_non_contributory ?? undefined,
            blanketAdditionalInsured:
                apiCertificateCoverage.blanket_additional_insured ?? undefined,
            isInCertificateDocument: apiCertificateCoverage.is_in_certificate_document ?? undefined,
            lossPayee: apiCertificateCoverage.loss_payee ?? undefined,
            lenderLossPayee: apiCertificateCoverage.lender_loss_payee ?? undefined,
            mortgagee: apiCertificateCoverage.mortgagee ?? undefined,
            additionalInterest: apiCertificateCoverage.additional_interest ?? undefined,
            locationList: locations,
            limits: coverageLimits.value,
            bundleId: apiCertificateCoverage.bundle_id ?? undefined,
        });
    }

    public async previewSelfServingCertificate(
        request: PreviewSelfServingCertificateRequest,
    ): AsyncResult<
        PreviewSelfServingCertificateResponse,
        InvalidArgument | PreviewSelfServingCertificateFailed
    > {
        const previewSelfSeringCertificateRequest =
            APICertificateRepository.toApiCreateSelfSeringCertificateRequest(request);
        const result = await API.API.request(
            'certificate/preview_self_serving_certificate',
            previewSelfSeringCertificateRequest,
        );

        if (isErr(result)) {
            return Failure(
                PreviewSelfServingCertificateFailed(result.errors, request.selfServingCoverageList),
            );
        }

        return Success({
            certificateFileKey: result.value.certificate_file_key,
        });
    }

    public async createSelfServingCertificate(
        request: CreateSelfServingCertificateRequest,
    ): AsyncResult<
        CreateSelfServingCertificateResponse,
        InvalidArgument | CreateSelfServingCertificateFailed
    > {
        const createSelfSeringCertificateRequest =
            APICertificateRepository.toApiCreateSelfSeringCertificateRequest(request);
        const result = await API.API.request(
            'certificate/create_self_serving_certificate',
            createSelfSeringCertificateRequest,
        );

        if (isErr(result)) {
            return Failure(
                CreateSelfServingCertificateFailed(result.errors, request.selfServingCoverageList),
            );
        }
        const response = APICertificateRepository.toCreateSelfSeringCertificateResponse(
            result.value,
        );

        return Success(response);
    }

    private static toCreateSelfSeringCertificateResponse(
        data: API.CertificateCreateSelfServingCertificateResponse,
    ): CreateSelfServingCertificateResponse {
        return {
            certificateId: data.certificate_id,
        };
    }

    private static toApiCreateSelfSeringCertificateRequest(
        data: CreateSelfServingCertificateRequest,
    ): API.CertificateCreateSelfServingCertificateRequest {
        const holder = APICertificateRepository.toApiCertificateEntityRole(data.certificateHolder);
        return {
            coverages: APICertificateRepository.toApiCertificateCoverageCreateList(
                data.selfServingCoverageList,
            ),
            holder: holder ?? undefined,
            reference_number: data.referenceNumber,
        };
    }

    private static toApiCertificateCoverageCreateList(
        coverageList: SelfServingCertificateCoverageList,
    ): API.CertificateCoverageList {
        const result: API.CertificateCoverageList = [];

        for (const coverage of coverageList) {
            if (
                coverage.coverageInfo.accordSectionType ===
                'CertificateSectionCodeListAcord25GeneralLiability'
            ) {
                result.push(APICertificateRepository.glToApiCertificateCoverageCreate(coverage));
            } else if (
                coverage.coverageInfo.accordSectionType ===
                'CertificateSectionCodeListAcord25AutoLiability'
            ) {
                result.push(APICertificateRepository.autoToApiCertificateCoverage(coverage));
            } else if (
                coverage.coverageInfo.accordSectionType ===
                'CertificateSectionCodeListAcord25UmbrellaExcessLiability'
            ) {
                result.push(APICertificateRepository.ueToApiCertificateCoverage(coverage));
            } else if (
                coverage.coverageInfo.accordSectionType ===
                'CertificateSectionCodeListAcord25WorkersCompensation'
            ) {
                result.push(APICertificateRepository.wcToApiCertificateCoverage(coverage));
            } else {
                result.push(APICertificateRepository.otherToApiCertificateCoverageCreate(coverage));
            }
        }

        return result;
    }

    private static autoToApiCertificateCoverage(
        autoCoverage: Immutable<CertificateCoverageAuto>,
    ): API.CertificateCoverage {
        const coverageInfo = APICertificateRepository.mapInfoToApiCertificateCoverage(
            autoCoverage.coverageInfo,
        );

        return {
            ...coverageInfo,
            coverage_auto_any_auto: autoCoverage.anyAuto ?? null,
            coverage_auto_all_owned_autos: autoCoverage.allOwnedAutos ?? null,
            coverage_auto_hired_autos: autoCoverage.hiredAutos ?? null,
            coverage_auto_scheduled_autos: autoCoverage.scheduledAutos ?? null,
            coverage_auto_non_owned_autos: autoCoverage.nonOwnedAutos ?? null,
            coverage_auto_other_1: autoCoverage.other1 ?? null,
            coverage_auto_other_2: autoCoverage.other2 ?? null,
        };
    }

    private static ueToApiCertificateCoverage(
        ueCoverage: Immutable<CertificateCoverageUmbrellaExcess>,
    ): API.CertificateCoverage {
        const coverageInfo = APICertificateRepository.mapInfoToApiCertificateCoverage(
            ueCoverage.coverageInfo,
        );

        return {
            ...coverageInfo,
            coverage_um_ex_claims_made: ueCoverage.claimsMade ?? null,
            coverage_um_ex_deductible: ueCoverage.deductible ?? null,
            coverage_um_ex_is_umbrella: ueCoverage.isUmbrella ?? null,
            coverage_um_ex_occur: ueCoverage.occur ?? null,
            coverage_um_ex_retention: ueCoverage.retention ?? null,
        };
    }

    private static wcToApiCertificateCoverage(
        wcCoverage: Immutable<CertificateCoverageWorkersCompensation>,
    ): API.CertificateCoverage {
        const coverageInfo = APICertificateRepository.mapInfoToApiCertificateCoverage(
            wcCoverage.coverageInfo,
        );

        return {
            ...coverageInfo,
            coverage_workers_comp_executive_officer_excluded:
                wcCoverage.isExecutiveOfficerExcluded ?? null,
            coverage_workers_comp_other: wcCoverage.other ?? null,
            coverage_workers_comp_per_statute: wcCoverage.isPerStatute ?? null,
        };
    }

    private static glToApiCertificateCoverageCreate(
        glCoverage: Immutable<CertificateCoverageGeneralLiability>,
    ): API.CertificateCoverage {
        const coverageInfo = APICertificateRepository.mapInfoToApiCertificateCoverageCreate(
            glCoverage.coverageInfo,
        );

        return {
            ...coverageInfo,
            coverage_gl_aggregate_limit_applies_per_loc:
                glCoverage.aggregateLimitAppliesPerLoc ?? null,
            coverage_gl_aggregate_limit_applies_per_other:
                glCoverage.aggregateLimitAppliesPerOther ?? null,
            coverage_gl_aggregate_limit_applies_per_policy:
                glCoverage.aggregateLimitAppliesPerPolicy ?? null,
            coverage_gl_aggregate_limit_applies_per_project:
                glCoverage.aggregateLimitAppliesPerProject ?? null,
            coverage_gl_claims_made: glCoverage.claimsMade ?? null,
            coverage_gl_occur: glCoverage.occur ?? null,
        };
    }

    private static otherToApiCertificateCoverageCreate(
        otherCoverage: Immutable<CertificateCoverageOther>,
    ): API.CertificateCoverage {
        const coverageInfo = APICertificateRepository.mapInfoToApiCertificateCoverageCreate(
            otherCoverage.coverageInfo,
        );

        return {
            ...coverageInfo,
        };
    }

    private static mapInfoToApiCertificateCoverage(
        coverageInfo: Immutable<CertificateCoverageInfo>,
    ): API.CertificateCoverage {
        return {
            created_at: coverageInfo.createdAt ?? null,
            acord_section_type: coverageInfo.accordSectionType ?? null,
            policy_id: coverageInfo.policyId ?? null,
            policy_number: coverageInfo.policyNumber ?? null,
            custom_name: coverageInfo.customName ?? null,
            policy_line_of_business: coverageInfo.policyLineOfBusiness ?? null,
            policy_line_of_business_subtype: coverageInfo.policyLineOfBusinessSubtype ?? null,
            policy_effective_date: coverageInfo.policyEffectiveDate ?? null,
            policy_end_date: coverageInfo.policyEndDate ?? null,
            policy_cancellation_date: coverageInfo.policyCancellationDate ?? null,
            insurer: coverageInfo.insurer ?? null,
            requested_amount: coverageInfo.requestedAmount ?? null,
            requested_line_of_business: coverageInfo.requestedLineOfBusiness ?? null,
            structural_component_type_code: coverageInfo.structuralComponentTypeCode,
            waiver_of_subrogation: coverageInfo.waiverOfSubrogation ?? null,
            primary_non_contributory: coverageInfo.primaryNonContributory ?? null,
            blanket_additional_insured: coverageInfo.blanketAdditionalInsured ?? null,
            is_in_certificate_document: coverageInfo.isInCertificateDocument ?? null,
            loss_payee: coverageInfo.lossPayee ?? null,
            lender_loss_payee: coverageInfo.lenderLossPayee ?? null,
            mortgagee: coverageInfo.mortgagee ?? null,
            additional_interest: coverageInfo.additionalInterest ?? null,
            location_list: APICertificateRepository.toApiLocations(coverageInfo.locationList),
            limits: APICertificateRepository.toApiCertificateLimits(coverageInfo.limits),
            coverage_auto_all_owned_autos: null,
            coverage_auto_any_auto: null,
            coverage_auto_hired_autos: null,
            coverage_auto_non_owned_autos: null,
            coverage_auto_other_1: null,
            coverage_auto_other_2: null,
            coverage_auto_scheduled_autos: null,
            coverage_gl_aggregate_limit_applies_per_loc: null,
            coverage_gl_aggregate_limit_applies_per_other: null,
            coverage_gl_aggregate_limit_applies_per_policy: null,
            coverage_gl_aggregate_limit_applies_per_project: null,
            coverage_gl_claims_made: null,
            coverage_gl_occur: null,
            coverage_um_ex_claims_made: null,
            coverage_um_ex_deductible: null,
            coverage_um_ex_is_umbrella: null,
            coverage_um_ex_occur: null,
            coverage_um_ex_retention: null,
            coverage_workers_comp_executive_officer_excluded: null,
            coverage_workers_comp_other: null,
            coverage_workers_comp_per_statute: null,
            bundle_id: null,
        };
    }

    private static mapInfoToApiCertificateCoverageCreate(
        coverageInfo: Immutable<CertificateCoverageInfo>,
    ): API.CertificateCoverage {
        return {
            created_at: coverageInfo.createdAt ?? null,
            acord_section_type: coverageInfo.accordSectionType ?? null,
            policy_id: coverageInfo.policyId ?? null,
            policy_number: coverageInfo.policyNumber ?? null,
            custom_name: coverageInfo.customName ?? null,
            policy_line_of_business: coverageInfo.policyLineOfBusiness ?? null,
            policy_line_of_business_subtype: coverageInfo.policyLineOfBusinessSubtype ?? null,
            policy_effective_date: coverageInfo.policyEffectiveDate ?? null,
            policy_end_date: coverageInfo.policyEndDate ?? null,
            policy_cancellation_date: coverageInfo.policyCancellationDate ?? null,
            insurer: coverageInfo.insurer ?? null,
            requested_amount: coverageInfo.requestedAmount ?? null,
            requested_line_of_business: coverageInfo.requestedLineOfBusiness ?? null,
            structural_component_type_code: coverageInfo.structuralComponentTypeCode,
            waiver_of_subrogation: coverageInfo.waiverOfSubrogation ?? null,
            primary_non_contributory: coverageInfo.primaryNonContributory ?? null,
            blanket_additional_insured: coverageInfo.blanketAdditionalInsured ?? null,
            is_in_certificate_document: coverageInfo.isInCertificateDocument ?? null,
            loss_payee: coverageInfo.lossPayee ?? null,
            lender_loss_payee: coverageInfo.lenderLossPayee ?? null,
            mortgagee: coverageInfo.mortgagee ?? null,
            additional_interest: coverageInfo.additionalInterest ?? null,
            location_list: APICertificateRepository.toApiLocations(coverageInfo.locationList),
            limits: APICertificateRepository.toApiCertificateLimits(coverageInfo.limits),
            coverage_auto_all_owned_autos: null,
            coverage_auto_any_auto: null,
            coverage_auto_hired_autos: null,
            coverage_auto_non_owned_autos: null,
            coverage_auto_other_1: null,
            coverage_auto_other_2: null,
            coverage_auto_scheduled_autos: null,
            coverage_gl_aggregate_limit_applies_per_loc: null,
            coverage_gl_aggregate_limit_applies_per_other: null,
            coverage_gl_aggregate_limit_applies_per_policy: null,
            coverage_gl_aggregate_limit_applies_per_project: null,
            coverage_gl_claims_made: null,
            coverage_gl_occur: null,
            coverage_um_ex_claims_made: null,
            coverage_um_ex_deductible: null,
            coverage_um_ex_is_umbrella: null,
            coverage_um_ex_occur: null,
            coverage_um_ex_retention: null,
            coverage_workers_comp_executive_officer_excluded: null,
            coverage_workers_comp_other: null,
            coverage_workers_comp_per_statute: null,
            bundle_id: null,
        };
    }

    private static toApiCertificateLimits(
        limitList: Immutable<CertificateCoverageLimit[]>,
    ): API.CertificateCoverageLimit[] {
        const apiLimitList: API.CertificateCoverageLimit[] = [];

        for (const element of limitList) {
            const certificateCoverageLimit: API.CertificateCoverageLimit = {
                liability_type_code: element.liabilityTypeCode ?? null,
                limit_type_code: element.limitTypeCode ?? null,
                custom_name: element.customName ?? null,
                amount: element.amount ?? null,
                description: element.description ?? null,
            };
            apiLimitList.push(certificateCoverageLimit);
        }
        return apiLimitList;
    }

    private static toApiLocations(locations: Immutable<Location[]>): API.Location[] {
        const places: API.Location[] = [];
        for (const location of locations) {
            places.push({
                id: null,
                address_lines: APICertificateRepository.toApiAddress(
                    location.addressLine1,
                    location.addressLine2,
                ),
                city: location.city ?? null,
                zip_code: location.zip ?? null,
                state: location.state ?? null,
                square_footage_occupied: null,
                county: location.county ?? null,
                value_of_property: null,
            });
        }

        return places;
    }
}
