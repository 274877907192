import { valueObject } from '@embroker/shotwell/core/types/ValueObject';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { QuoteOptions } from '../../../../quote/entities/Quote';
import { Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';

// TODO: https://embroker.atlassian.net/browse/EM-43848
// CoverageOption is set up to be implemented upon in a follow up ticket
// Refinement may be needed
export interface CoverageOption {
    selected: boolean;
    limit: number;
    retention: number;
    enhanced: Nullable<boolean>;
}

export interface RequiredESPQuoteOptions extends Required<ESPQuoteOptions> {
    policyFee: Nullable<Money>;
    isPolicyFeeTaxable: boolean | null;
}

// TODO: https://embroker.atlassian.net/browse/EM-43848
// ESPQuoteOptions are set up to be implemented upon in a follow up ticket
// Refinement may be needed
export interface ESPQuoteOptions extends QuoteOptions {
    readonly directorsAndOfficers: CoverageOption;
    employmentPracticesLiability: CoverageOption;
    fiduciary: Pick<CoverageOption, 'selected'>;
    technology?: CoverageOption;
    cyber?: CoverageOption;
    partnerCode: string;
    policyFee: Nullable<Money>;
    isPolicyFeeTaxable: boolean | null;
}

export const coverageOptionSchema = Joi.object({
    selected: Joi.boolean().required(),
    limit: Joi.number().optional(),
    retention: Joi.number().optional(),
    enhanced: Joi.boolean().optional(),
});

export const ESPQuoteOptions = valueObject({
    ...defineValidator<ESPQuoteOptions>({
        effectiveDate: Joi.date().required(),
        isDeselected: Joi.boolean().required(),
        directorsAndOfficers: coverageOptionSchema.required(),
        employmentPracticesLiability: coverageOptionSchema.required(),
        fiduciary: Joi.object({
            selected: Joi.boolean().required(),
        }).required(),
        technology: coverageOptionSchema.optional(),
        cyber: coverageOptionSchema.optional(),
        partnerCode: Joi.string().allow('').required(),
        policyFee: Money.schema.allow(null).required(),
        isPolicyFeeTaxable: Joi.boolean().required(),
    }),
});
