import { defineEntityValidator, entity } from '@embroker/shotwell/core/entity/Entity';
import { Quote, commonQuoteProps } from '../../../../quote/entities/Quote';
import { WCChubbQuoteDetails } from '../types/WCChubbQuoteDetails';
import { WCChubbQuoteOptions } from '../types/WCChubbQuoteOptions';

export interface WCChubbQuote extends Quote {
    readonly options: WCChubbQuoteOptions;
    readonly details: WCChubbQuoteDetails;
}

export const WCChubbQuote = entity<WCChubbQuote, Quote>({
    validator: defineEntityValidator<WCChubbQuote>({
        ...commonQuoteProps,
        options: WCChubbQuoteOptions.schema.required(),
        details: WCChubbQuoteDetails.schema.required(),
    }),
    inherits: Quote,
});
