import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ExperimentationTestNames } from './enums';

export enum ExperimentationEventNames {
    Impression = 'Impression',
    FeatureUsed = 'FeatureUsed',
}

interface ExperimentationEvent extends DomainEvent {
    readonly origin: 'Experimentation';
    readonly name: ExperimentationEventNames;
    readonly deviceId: UUID;
}

export interface ExperimentationImpressionEvent extends ExperimentationEvent {
    readonly name: ExperimentationEventNames.Impression;
    readonly experimentationName: ExperimentationTestNames;
    readonly assignment: number;
}

export interface FeatureUsedEvent extends ExperimentationEvent {
    readonly name: ExperimentationEventNames.FeatureUsed;
    readonly featureName: ExperimentationTestNames;
    readonly value: any;
    readonly raw: object;
}
