import { inject } from '@embroker/shotwell/core/di';
import {
    Aborted,
    InvalidArgument,
    OperationFailed,
    Timeout,
    UnknownEntity,
} from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { cast } from '@embroker/shotwell/core/types/Nominal';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute, UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { GetDocumentUrl } from '../../../documents/useCases/GetDocumentUrl';
import { TasksRepository } from '../../../tasks/repositories';
import { DocGenFailed } from '../../errors';
import { CyberQuoteRepository } from '../repositories/CyberQuoteRepository';

export interface GenerateSpecimenPolicyUrlRequest {
    readonly applicationId: UUID;
    readonly quoteId: UUID;
    readonly abortSignal: AbortSignal;
}

export interface GenerateSpecimenPolicyUrlResponse {
    readonly specimenPolicyUrl: URI;
}

export interface GenerateSpecimenPolicyUrl extends UseCase {
    execute(
        request: GenerateSpecimenPolicyUrlRequest,
    ): AsyncResult<
        GenerateSpecimenPolicyUrlResponse,
        UnknownEntity | InvalidArgument | OperationFailed | Aborted | Timeout | DocGenFailed
    >;
}

class GenerateSpecimenPolicyUrlUseCase extends UseCase implements GenerateSpecimenPolicyUrl {
    public static type = Symbol('CyberQuote/GenerateSpecimenPolicyUrl');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(CyberQuoteRepository) private cyberQuoteRepository: CyberQuoteRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
        quoteId,
        abortSignal,
    }: GenerateSpecimenPolicyUrlRequest): AsyncResult<
        GenerateSpecimenPolicyUrlResponse,
        UnknownEntity | InvalidArgument | OperationFailed | Aborted | Timeout | DocGenFailed
    > {
        const createAsyncTaskResult = await this.cyberQuoteRepository.createSpecimenPolicyAsyncTask(
            applicationId,
            quoteId,
        );
        if (isErr(createAsyncTaskResult)) {
            return createAsyncTaskResult;
        }

        const createSpecimenPolicyResult = await this.awaitCreateSpecimenPolicyTask({
            taskId: createAsyncTaskResult.value,
            applicationId,
            quoteId,
            abortSignal,
        });
        if (isErr(createSpecimenPolicyResult)) {
            return Failure(DocGenFailed({ errors: createSpecimenPolicyResult.errors }));
        }

        const fileKey = createSpecimenPolicyResult.value;
        if (fileKey === undefined || fileKey === '') {
            return Failure(OperationFailed({ message: 'No generated Quote Summary was found.' }));
        }

        const getDocumentUrlResult = await execute(GetDocumentUrl, { fileKey });
        if (isErr(getDocumentUrlResult)) {
            return getDocumentUrlResult;
        }

        return Success<GenerateSpecimenPolicyUrlResponse>({
            specimenPolicyUrl: cast<URI>(getDocumentUrlResult.value.downloadUrl),
        });
    }

    private async awaitCreateSpecimenPolicyTask({
        taskId,
        applicationId,
        quoteId,
        abortSignal,
    }: {
        taskId: UUID;
        applicationId: UUID;
        quoteId: UUID;
        abortSignal: AbortSignal;
    }): AsyncResult<string | undefined, InvalidArgument | OperationFailed | Aborted | Timeout> {
        const pollForTaskStatusResult = await this.tasksRepository.pollForTaskStatus(
            taskId,
            abortSignal,
        );
        if (isErr(pollForTaskStatusResult)) {
            return pollForTaskStatusResult;
        }

        const lastQuoteResult = await this.cyberQuoteRepository.getLastCyberQuote(applicationId);
        if (isErr(lastQuoteResult)) {
            return lastQuoteResult;
        }
        if (lastQuoteResult.value.id !== quoteId) {
            return Failure(OperationFailed({ message: 'Not the latest cyber quote' }));
        }

        return Success(lastQuoteResult.value.details.specimenPolicyFileKey);
    }
}

export const GenerateSpecimenPolicyUrl: UseCaseClass<GenerateSpecimenPolicyUrl> =
    GenerateSpecimenPolicyUrlUseCase;
