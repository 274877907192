import { API, GlobalGetConfigResponse } from '@embroker/shotwell-api/app';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { injectable } from '@embroker/shotwell/core/di';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, Failure, isErr, isOK, Success } from '@embroker/shotwell/core/types/Result';
import { GetGlobalConfigFailed } from '../../errors';
import { GlobalConfig } from '../../types/GlobalConfig';
import { GlobalConfigRepository } from './index';

@injectable()
export class APIGlobalConfigRepository implements GlobalConfigRepository {
    private configCache?: GlobalConfig;

    public async getGlobalConfig(
        preferCache = true,
    ): AsyncResult<GlobalConfig, GetGlobalConfigFailed | InvalidArgument> {
        if (preferCache && this.configCache) {
            return Success(this.configCache);
        }

        const globalConfigApiResult = await API.request('global/get_config');
        if (isErr(globalConfigApiResult)) {
            return Failure(GetGlobalConfigFailed(globalConfigApiResult.errors));
        }

        const globalConfigResult = await APIGlobalConfigRepository.createGlobalConfig(
            globalConfigApiResult.value,
        );

        if (isOK(globalConfigResult)) {
            this.configCache = globalConfigResult.value;
        }
        return globalConfigResult;
    }

    static async createGlobalConfig(
        apiGlobalConfig: Immutable<GlobalGetConfigResponse>,
    ): AsyncResult<GlobalConfig, InvalidArgument> {
        return GlobalConfig.create({
            isEmbrokerCrimeEnabled: apiGlobalConfig.is_embroker_crime_enabled,
            isPcomlEnabled: apiGlobalConfig.is_pcoml_enabled,
            isWcgaEnabled: apiGlobalConfig.is_wcga_enabled,
            lplDevModeEffectiveDate: apiGlobalConfig.lpl_dev_mode_effective_date,
            pcomlEndorsementEnabled: apiGlobalConfig.pcoml_endorsement_enabled,
            salesforceBrowsingUrl: apiGlobalConfig.salesforce_browsing_url,
            isLplEnabled: apiGlobalConfig.is_lpl_enabled,
            crimeDevModeEffectiveDate: apiGlobalConfig.crime_dev_mode_effective_date,
            embrokerAppUrl: apiGlobalConfig.embroker_app_url,
            googleTagManagerToken: apiGlobalConfig.google_tag_manager_token,
            isBopEnabled: apiGlobalConfig.is_bop_enabled,
            isNewESPEndorsementEnabled: apiGlobalConfig.is_new_esp_endorsement_enabled,
            isMidTermBorEnabled: apiGlobalConfig.is_mid_term_bor_enabled,
            isCertificatesPhoenixAdminEnabled:
                apiGlobalConfig.is_certificates_phoenix_admin_enabled,
            isEoCyberSplitEnabled: apiGlobalConfig.is_eo_cyber_split_enabled,
            isPreBindEndorsementsEnabled: apiGlobalConfig.is_pre_bind_endorsements_enabled,
            isBundleRepresentationEnabled: apiGlobalConfig.is_bundle_representation_enabled,
            isPremiumFinanceBillingMethodUpdateEnabled:
                apiGlobalConfig.is_premium_finance_billing_method_update_enabled,
            isClaimRequestEnabled: apiGlobalConfig.is_claim_request_enabled,
            isMplEnabled: apiGlobalConfig.is_mpl_enabled,
            excessClearBlueEverspanSwitchDate:
                apiGlobalConfig.excess_clear_blue_everspan_switch_date,
            espClearBlueEverspanSwitchDate: apiGlobalConfig.esp_clear_blue_everspan_switch_date,
            pcoClearBlueEverspanSwitchDate: apiGlobalConfig.pco_clear_blue_everspan_switch_date,
            crimeClearBlueEverspanSwitchDate: apiGlobalConfig.crime_clear_blue_everspan_switch_date,
            cyberClearBlueEverspanSwitchDate: apiGlobalConfig.cyber_clear_blue_everspan_switch_date,
            newBundleFlowEnabled: apiGlobalConfig.new_bundle_flow_enabled,
            isHomeInspectorsMplVerticalEnabled:
                apiGlobalConfig.is_home_inspectors_mpl_vertical_enabled,
            isRealEstateAgentsMplVerticalEnabled:
                apiGlobalConfig.is_real_estate_agents_mpl_vertical_enabled,
            espIptChangesEnabled: apiGlobalConfig.esp_ipt_changes_enabled,
            espIptChangesReleaseDate: apiGlobalConfig.esp_ipt_changes_release_date,
            pcomlIptChangesEnabled: apiGlobalConfig.pcoml_ipt_changes_enabled,
            excessIptChangesEnabled: apiGlobalConfig.excess_ipt_changes_enabled,
            excessRenewalsEnabled: apiGlobalConfig.excess_renewals_enabled,
            omitEffectiveDateValidation: apiGlobalConfig.omit_effective_date_validation,
        });
    }
}
