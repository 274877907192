import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { DataEnricherRepository } from '../repositories/DataEnricher';
import { EnricherCompany } from '../types/EnricherCompany';

export interface GetEnricherCompanyRequest {
    readonly domain: string;
}

export interface GetEnricherCompanyResponse {
    readonly company: Immutable<EnricherCompany>;
}

export interface GetEnricherCompany extends UseCase {
    execute(
        requestData: GetEnricherCompanyRequest,
    ): AsyncResult<GetEnricherCompanyResponse, InvalidArgument | OperationFailed>;
}

@injectable()
class GetEnricherCompanyUseCase extends UseCase {
    public static type = Symbol('UserOrg/GetEnricherCompany');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(DataEnricherRepository) private dataEnricherRepository: DataEnricherRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        requestData: GetEnricherCompanyRequest,
    ): AsyncResult<GetEnricherCompanyResponse, InvalidArgument | OperationFailed> {
        const getCompanyResult = await this.dataEnricherRepository.getCompany(requestData.domain);
        if (isErr(getCompanyResult)) {
            return getCompanyResult;
        }

        const getEnricherCompanyResponse: GetEnricherCompanyResponse = {
            company: getCompanyResult.value,
        };

        return Success(getEnricherCompanyResponse);
    }
}

export const GetEnricherCompany: UseCaseClass<GetEnricherCompany> = GetEnricherCompanyUseCase;
