import { Async } from '@embroker/shotwell/core/async';
import { inject } from '@embroker/shotwell/core/di';
import { Aborted, InvalidArgument, OperationFailed, Timeout } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { TasksRepository } from '../../tasks/repositories';
import { ApplicationRepository } from '../repositories/ApplicationRepository';

interface GetApplicationIntakeTaskStatusRequest {
    id: UUID;
    maxPollingRetries?: number;
    pollingRetryIntervalInMilliseconds?: number;
    abortSignal: AbortSignal;
}

export interface GetApplicationIntakeTaskStatusResponse {
    applicationId: UUID;
}

export interface GetApplicationIntakeTaskStatus extends UseCase {
    execute(
        request: GetApplicationIntakeTaskStatusRequest,
    ): AsyncResult<
        GetApplicationIntakeTaskStatusResponse,
        InvalidArgument | OperationFailed | Timeout | Aborted
    >;
}

class GetApplicationIntakeTaskStatusUseCase
    extends UseCase
    implements GetApplicationIntakeTaskStatus
{
    public static type = Symbol('Shopping/GetApplicationIntakeTaskStatus');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        id,
        maxPollingRetries = 30,
        pollingRetryIntervalInMilliseconds = 5000,
        abortSignal,
    }: GetApplicationIntakeTaskStatusRequest): AsyncResult<
        GetApplicationIntakeTaskStatusResponse,
        InvalidArgument | OperationFailed | Timeout | Aborted
    > {
        let retryCount = 0;
        let isAborted = false;
        abortSignal.onabort = () => {
            isAborted = true;
        };
        while (retryCount < maxPollingRetries) {
            if (isAborted) {
                return Failure(Aborted('Polling aborted.'));
            }
            retryCount++;
            const GetApplicationIntakeTaskStatus = await this.tasksRepository.getTaskStatus(id);
            if (isErr(GetApplicationIntakeTaskStatus)) {
                return GetApplicationIntakeTaskStatus;
            }

            const data = GetApplicationIntakeTaskStatus.value;

            if (data) {
                if (!UUID.check(data)) {
                    return Failure(
                        OperationFailed({
                            message: `Invalid response type. Expected UUID, but got: '${data}'`,
                        }),
                    );
                }
                return Success({ applicationId: data });
            }

            await Async.sleep(pollingRetryIntervalInMilliseconds);
        }
        return Failure(Timeout('Submission GetApplicationIntakeTaskStatus max retries exceeded.'));
    }
}

export const GetApplicationIntakeTaskStatus: UseCaseClass<GetApplicationIntakeTaskStatus> =
    GetApplicationIntakeTaskStatusUseCase;
