import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { Quote } from '@embroker/shotwell-api/app';
import * as APIType from '@embroker/shotwell-api/app';
import { Money } from '@embroker/shotwell/core/types/Money';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { QuotingEngine } from '../../shopping/types/enums';

export type QuoteList = Quote[];

export type QuoteIdMap = Partial<Record<QuotingEngine, UUID>>;

export const QuoteList = {
    getLastQuote(quoteList: Immutable<Quote[]>): Nullable<Immutable<Quote>> {
        if (quoteList.length === 0) {
            return null;
        }
        return quoteList.reduce((acc: Immutable<Quote>, curr: Immutable<Quote>) => {
            const accCreatedAt = acc.created_at;
            const currCreatedAt = curr.created_at;
            return currCreatedAt.getTime() > accCreatedAt.getTime() ? curr : acc;
        });
    },
    getLastPremium(quoteList: Immutable<APIType.Quote[]>): Money | undefined {
        const lastQuote = this.getLastQuote(quoteList);
        return lastQuote?.total_premium ?? undefined;
    },
};
