import {
    API,
    CurrencyMarshaller,
    ShoppingCrimeQuoteInfoFromPolicyResponse,
} from '@embroker/shotwell-api/app';
import { isAPIError } from '@embroker/shotwell-api/errors';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { USD } from '@embroker/shotwell/core/types/Money';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { addYears, startOfToday } from 'date-fns';
import { QuotingEngineCrime } from '../../../../shopping/types/enums';
import { InvalidAnnualTechFee } from '../../../errors';
import { QuoteExpiration } from '../../../types/QuoteExpiration';
import { QuoteIdMap, QuoteList } from '../../../types/QuoteList';
import { CrimeQuote } from '../../entities/CrimeQuote';
import { CrimeLimit, CrimeQuoteOptions, CrimeRetention } from '../../types/CrimeQuoteOptions';
import { CrimeUserInfo } from '../../types/CrimeUserInfo';
import { CrimeQuoteRepository, PurchaseResponse } from './index';

@injectable()
export class APICrimeQuoteRepository implements CrimeQuoteRepository {
    async getLastCrimeQuote(
        applicationId: UUID,
    ): AsyncResult<CrimeQuote, InvalidArgument | OperationFailed> {
        const applicationResponse = await API.request('shopping/application', {
            id: applicationId,
        });
        if (isErr(applicationResponse)) {
            return handleOperationFailure(applicationResponse);
        }

        const application = applicationResponse.value;

        const lastQuote = QuoteList.getLastQuote(application.quote_list);
        if (lastQuote == null) {
            return Failure(InvalidArgument({ argument: 'last_quote', value: lastQuote }));
        }

        const crimeQuoteDetails = lastQuote.details.crime;
        const crimeQuoteOptions = lastQuote.options.crime;

        if (crimeQuoteDetails === undefined) {
            return Failure(
                InvalidArgument({ argument: 'crimeQuoteDetails', value: crimeQuoteDetails }),
            );
        }

        if (crimeQuoteOptions === undefined) {
            return Failure(
                InvalidArgument({ argument: 'crimeQuoteOptions', value: crimeQuoteOptions }),
            );
        }

        const previousPolicyId =
            application.renewed_policy_id_list.length > 0
                ? application.renewed_policy_id_list[0]
                : null;

        let previousPolicy: ShoppingCrimeQuoteInfoFromPolicyResponse | null = null;
        if (previousPolicyId !== null) {
            const previousPolicyResult = await API.request(
                'shopping/crime_quote_info_from_policy',
                {
                    policy_id: previousPolicyId,
                },
            );

            if (isErr(previousPolicyResult)) {
                return handleOperationFailure(previousPolicyResult);
            }
            previousPolicy = previousPolicyResult.value;
        }

        let userInfo: CrimeUserInfo;
        const questionnaireData = application.questionnaire_data;
        if (questionnaireData === null) {
            return Failure(
                InvalidArgument({ argument: 'questionnaire_data', value: questionnaireData }),
            );
        }
        try {
            const questionnaireDataParsed = JSON.parse(questionnaireData);
            userInfo = getUserInfoFromQuestionnaire(questionnaireDataParsed);
        } catch (e) {
            return Failure(OperationFailed({ message: 'Failed parsing questionnaire data' }));
        }

        const crimeQuoteEntityResult = await CrimeQuote.create({
            isIndication: lastQuote.is_indication,
            id: lastQuote.id,
            totalPremium: lastQuote.total_premium,
            totalPayable: lastQuote.total_payable,
            applicationId: applicationId,
            status: lastQuote.accepted_at ? 'accepted' : 'draft',
            fileKey: lastQuote.file_key !== null ? lastQuote.file_key : undefined,
            options: {
                effectiveDate: crimeQuoteOptions.effective_date,
                enhanced: crimeQuoteOptions.level === 'plus',
                limit: crimeQuoteOptions.limit as CrimeLimit,
                previousLimit:
                    previousPolicy !== null ? (previousPolicy.limit as CrimeLimit) : undefined,
                retention: crimeQuoteOptions.retention as CrimeRetention,
                previousRetention:
                    previousPolicy !== null
                        ? (previousPolicy.retention as CrimeRetention)
                        : undefined,
            },
            isRenewal: application.renewed_policy_id_list.length > 0,
            details: {
                quoteId: lastQuote.id,
                totalPremium: lastQuote.total_premium,
                subtotalAmount: crimeQuoteDetails.subtotal_amount,
                previousPremium:
                    previousPolicy !== null
                        ? CurrencyMarshaller.unmarshal(previousPolicy.premium, 'premium')
                        : undefined,
                totalBasePremium: crimeQuoteDetails.total_base_premium ?? USD(0),
                taxes: lastQuote.taxes ?? USD(0),
                fees: lastQuote.fees ?? USD(0),
                mwuaFee: lastQuote.details.crime?.mwua_fee,
                policyAdministrationFee: crimeQuoteDetails.policy_administration_fee ?? USD(0),
                specimenPolicyFileKey: crimeQuoteDetails.specimen_policy_file_key,
            },
            userInfo: userInfo,
            daysToExpire: QuoteExpiration.getDaysLeftUntilExpiration({
                quotingEngine: application.quoting_engine || undefined,
                applicationStatus: application.status,
                validUntil: application.valid_until,
                quoteEffectiveDate: crimeQuoteOptions.effective_date,
                today: startOfToday(),
                isBroker: application.brokerage_id !== null,
            }),
        });

        if (isErr(crimeQuoteEntityResult)) {
            return handleOperationFailure(crimeQuoteEntityResult);
        }

        return Success(crimeQuoteEntityResult.value);
    }

    async reQuoteCrime(
        applicationId: UUID,
        crimeQuoteOptions: CrimeQuoteOptions,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed> {
        const reQuoteCrimeResponse = await API.request('shopping/create_quote_task', {
            application_id: applicationId,
            quote_options: {
                crime: {
                    effective_date: crimeQuoteOptions.effectiveDate,
                    effective_period_end: addYears(crimeQuoteOptions.effectiveDate, 1),
                    limit: crimeQuoteOptions.limit,
                    retention: crimeQuoteOptions.retention,
                    level: crimeQuoteOptions.enhanced ? 'plus' : 'standard',
                    partner_code: '',
                },
            },
        });
        if (isErr(reQuoteCrimeResponse)) {
            return handleOperationFailure(reQuoteCrimeResponse);
        }
        return Success(reQuoteCrimeResponse.value.task_id);
    }

    public async purchaseCrime(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<
        PurchaseResponse,
        UnknownEntity | InvalidArgument | OperationFailed | InvalidAnnualTechFee
    > {
        const quoteIdMap: QuoteIdMap = { [QuotingEngineCrime]: quoteId };
        const purchaseResponse = await API.request('shopping/purchase', {
            id: applicationId,
            quote_id_map: quoteIdMap,
        });

        if (isErr(purchaseResponse)) {
            const error = purchaseResponse.errors[0];
            if (isAPIError(error) && error.details.name === 'invalid_annual_tech_fee') {
                return Failure(InvalidAnnualTechFee());
            }
            return handleOperationFailure(purchaseResponse);
        }

        const { policy_issuance_task_id: policyIssuanceTaskId, policyId } = purchaseResponse.value;

        return Success({
            policyId: policyId,
            policyDoneTaskId: policyIssuanceTaskId,
        });
    }

    public async createQuoteSummaryAsyncTask(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed> {
        const createQuoteSummaryResult = await API.request('shopping/create_quote_summary_task', {
            application_id: applicationId,
            quote_id: quoteId,
        });
        if (isErr(createQuoteSummaryResult)) {
            return handleOperationFailure(createQuoteSummaryResult);
        }

        return Success(createQuoteSummaryResult.value.task_id);
    }

    public async createSpecimenPolicyAsyncTask(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed> {
        const createSpecimenPolicyResult = await API.request(
            'shopping/create_specimen_policy_task',
            {
                application_id: applicationId,
                quote_id: quoteId,
            },
        );
        if (isErr(createSpecimenPolicyResult)) {
            return handleOperationFailure(createSpecimenPolicyResult);
        }

        return Success(createSpecimenPolicyResult.value.task_id);
    }
}

function getUserInfoFromQuestionnaire(questionnaire: any): CrimeUserInfo {
    return {
        company: questionnaire?.company_name,
        fullName: `${questionnaire?.first_name} ${questionnaire?.last_name}`,
        title: questionnaire?.title,
        usaState: questionnaire?.state,
    };
}
