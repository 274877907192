import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { Nullable } from '@embroker/shotwell/core/types';
import { ExperimentationTestName } from './ExperimentationTestName';

type ExperimentTestAssignment = number;
interface ExperimentationTestLike {
    name: ExperimentationTestName;
    assignment: ExperimentTestAssignment;
    organizationId: string;
}

export type ExperimentationTest = Nullable<ExperimentationTestLike>;

export const ExperimentationTest = {
    ...defineValidator<ExperimentationTest>({
        name: ExperimentationTestName.schema,
        assignment: Joi.number(),
        organizationId: Joi.string(),
    }),
    create(experimentationTest: ExperimentationTest) {
        return ExperimentationTest.validate(experimentationTest);
    },
};

export function isControlExperimentTest(experimentTest: ExperimentationTest): boolean {
    if (!experimentTest) return false;

    return experimentTest?.assignment === 0;
}

export function isVariantExperimentTest(
    experimentTest: ExperimentationTest,
    assignment?: ExperimentTestAssignment,
) {
    if (!experimentTest) return false;

    if (assignment && assignment > 0) {
        return experimentTest?.assignment === assignment;
    }

    return !isControlExperimentTest(experimentTest);
}
