import { Nullable } from '@embroker/shotwell/core/types';
import { State } from '@embroker/shotwell/core/types/StateList';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface Headquarters {
    addressLine1: Nullable<string>;
    addressLine2: Nullable<string>;
    city: Nullable<string>;
    state: Nullable<State>;
    county: Nullable<string>;
    zip: Nullable<ZipCode>;
}

export const Headquarters = {
    ...defineValidator<Headquarters>({
        addressLine1: Joi.string().allow(null),
        addressLine2: Joi.string().allow(null),
        city: Joi.string().allow(null),
        state: State.schema.allow(null),
        county: Joi.string().allow(null),
        zip: ZipCode.schema.allow(null),
    }),
    create(headquarters: Headquarters) {
        return Headquarters.validate(headquarters);
    },
};
