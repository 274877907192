import { QuoteExtended } from '@embroker/shotwell-api/app';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { JSONSerdes } from '@embroker/shotwell/core/encoding';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, Failure, isErr } from '@embroker/shotwell/core/types/Result';
import { State } from '@embroker/shotwell/core/types/StateList';
import { IconName } from '@embroker/ui-toolkit/v2';
import { startOfToday } from 'date-fns';
import { QuoteExpiration } from '../../../quote/types/QuoteExpiration';
import {
    QuotingEngineMPL,
    ShoppingCoverageCodeListProfessionalLiability,
} from '@app/shopping/types/enums';
import {
    BundleCoverageTypeEnum,
    BundleQuoteCoverage,
    BundleQuoteCoverageMetadata,
} from '../../types/BundleQuoteCoverage';
import { getAllowedHigherLimit, toBundleQuoteCoverage } from '../bundleMappingFunctions';
import { MPLQuote, MPLVertical } from './entities/MPLQuote';
import { MPLHigherLimit, mapFromMPLApiLimit } from './types/MPLHigherLimit';
import { MPLQuestionnaireData } from './types/MPLQuestionnaireData';
import { MPLQuoteDetails } from './types/MPLQuoteDetails';
import {
    MPLAggregateLimit,
    MPLPerClaimLimit,
    MPLQuoteOptions,
    MPLRetention,
    validPerClaimAggregateCombinationList,
} from './types/MPLQuoteOptions';

function calculateHigherLimit(
    mplQuoteExtended: Immutable<QuoteExtended>,
    questionnaireData: Immutable<MPLQuestionnaireData>,
) {
    if (mplQuoteExtended.uw_review?.are_manual_restrictions_applied) {
        return getAllowedHigherLimit(
            ShoppingCoverageCodeListProfessionalLiability,
            mplQuoteExtended.uw_review.allowed_app_quote_options.allowed_coverage_quote_options,
        );
    }

    return undefined;
}

export async function buildMPLCoverage(
    mplQuoteExtended: Immutable<QuoteExtended>,
    isBroker: boolean,
    omitEffectiveDateValidation = false,
): AsyncResult<BundleQuoteCoverage<MPLQuote>, OperationFailed | InvalidArgument> {
    if (!mplQuoteExtended) {
        return Failure(OperationFailed({ message: 'mplQuoteExtended is null or undefined' }));
    }

    const questionnaireDataResult = JSONSerdes.deserialize(mplQuoteExtended.questionnaire_data);
    if (isErr(questionnaireDataResult)) {
        return Failure(
            InvalidArgument({
                argument: 'questionnaire_data',
                value: mplQuoteExtended.questionnaire_data,
            }),
        );
    }
    const mplQuestionnaireData = MPLQuestionnaireData.create(questionnaireDataResult.value);
    if (isErr(mplQuestionnaireData)) {
        return Failure(
            InvalidArgument({
                argument: 'MPL questionnaire data',
                value: mplQuestionnaireData.errors,
            }),
        );
    }

    const questionnaireData = mplQuestionnaireData.value;
    const mplVertical = getMPLVertical(questionnaireData.naics_code);
    if (!mplVertical) {
        return Failure(OperationFailed({ message: 'mplVertical is null' }));
    }
    const mplCoverageMetadata = getMPLCoverageMetadata(mplVertical);

    if (!mplQuoteExtended.quote) {
        return toBundleQuoteCoverage<MPLQuote>(
            BundleCoverageTypeEnum.MPLCoverage,
            mplQuoteExtended.app_status,
            mplCoverageMetadata,
            questionnaireData,
            mplQuoteExtended.app_valid_until,
        );
    }
    if (!mplQuoteExtended.quote.options.mpl) {
        return Failure(OperationFailed({ message: 'mplQuoteOptions is null or undefined' }));
    }
    const mplQuoteOptions: MPLQuoteOptions = {
        effectiveDate: mplQuoteExtended.quote.options.mpl?.effective_date,
        limits: {
            perClaimLimit: mplQuoteExtended.quote.options.mpl?.per_claim_limit as MPLPerClaimLimit,
            aggregateLimit: mplQuoteExtended.quote.options.mpl
                ?.aggregate_limit as MPLAggregateLimit,
        },
        retention: mplQuoteExtended.quote.options.mpl?.retention as MPLRetention,
        isDeselected: mplQuoteExtended.quote.options.mpl?.is_deselected,
    };
    const mplQuoteDetails: MPLQuoteDetails = {
        total_base_premium: mplQuoteExtended.quote.details.mpl?.total_base_premium,
        specimen_policy_file_key: mplQuoteExtended.quote.details.mpl?.specimen_policy_file_key,
    };
    const higherLimit = calculateHigherLimit(mplQuoteExtended, questionnaireData);
    const mplHigherLimit = mapFromMPLApiLimit(higherLimit);
    const minMPLHigherLimit = getMinMPLHigherLimit(mplHigherLimit);

    const preBindEndorsements = mplQuoteExtended.uw_review?.endorsements?.reduce((acc, curr) => {
        return { ...acc, [curr.endorsement_id]: curr.endorsement_name };
    }, {});

    const mplQuote = await MPLQuote.create({
        isIndication: mplQuoteExtended.quote.is_indication,
        ...(questionnaireData.purchasedLimit &&
            mplQuoteExtended.quote.is_indication && {
                isCurrentLimitExceed:
                    questionnaireData.purchasedLimit < mplQuoteOptions.limits.perClaimLimit &&
                    mplQuoteOptions.limits.perClaimLimit < minMPLHigherLimit,
            }),
        id: mplQuoteExtended.quote.id,
        applicationId: mplQuoteExtended.quote.app_id,
        options: mplQuoteOptions,
        details: mplQuoteDetails,
        status: 'draft',
        higherLimit: higherLimit,
        preBindEndorsements: preBindEndorsements,
        totalPayable: mplQuoteExtended.quote.total_payable,
        totalPremium: mplQuoteExtended.quote.total_premium,
        premiumRange: mplQuoteExtended.quote.premium_range,
        annualTechnologyFee: mplQuoteExtended.quote.annual_technology_fee,
        daysToExpire: QuoteExpiration.getDaysLeftUntilExpiration({
            quotingEngine: QuotingEngineMPL,
            applicationStatus: mplQuoteExtended.app_status,
            isBroker: isBroker,
            quoteEffectiveDate: mplQuoteExtended.quote.options.mpl?.effective_date,
            today: startOfToday(),
            validUntil: mplQuoteExtended.app_valid_until || null,
            omitEffectiveDateValidation,
        }),
        quoteNumber: mplQuoteExtended.quote.quote_number,
        fees: mplQuoteExtended.quote.fees || undefined,
        fileKey: mplQuoteExtended.quote.file_key || undefined,
        mplVertical: mplVertical,
    });
    if (isErr(mplQuote)) {
        return Failure(InvalidArgument({ argument: 'MPL coverage', value: mplQuote.errors }));
    }
    return toBundleQuoteCoverage<MPLQuote>(
        BundleCoverageTypeEnum.MPLCoverage,
        mplQuoteExtended.app_status,
        mplCoverageMetadata,
        questionnaireData,
        mplQuoteExtended.app_valid_until,
        mplQuote.value,
    );
}

const getMinMPLHigherLimit = (higherLimits?: MPLHigherLimit): number => {
    if (higherLimits) {
        return validPerClaimAggregateCombinationList.reduce((prev, curr) => {
            return prev.perClaimLimit < curr.perClaimLimit ? prev : curr;
        }).perClaimLimit;
    }
    return 3_000_000;
};

export const getMPLTitle = (mplVertical: MPLVertical): string => {
    switch (mplVertical) {
        case 'Accountant':
            return 'Accountants Professional Liability';
        case 'TaxPreparersAndBookkeepers':
            return 'Tax Preparers & Bookkeepers Professional Liability';
        case 'RealEstateAgent':
            return 'Real Estate Agents Professional Liability';
        case 'HomeInspector':
            return 'Home Inspectors Professional Liability';
        case 'NonTechnologyBusinessAndManagementConsultants':
            return 'Non-Technology Consultants Professional Liability';
    }
};

export const getMPLInsurer = (state: State): string => {
    const everspanStates = ['FL', 'UT', 'WY'];
    if (everspanStates.includes(state)) {
        return 'Everspan Insurance Company';
    }
    return 'Providence Washington Insurance Company';
};

const getMPLDescription = (mplVertical: MPLVertical): string => {
    if (mplVertical === 'NonTechnologyBusinessAndManagementConsultants') {
        return `Protects Non-Technology Business and Management Consultants if you're sued for negligence, common mistakes, and more. Also known as Errors and Omissions (E&O).`;
    }
    return `Protects you and your business if you're sued for negligence, common mistakes, and more. Also known as Errors and Omissions (E&O).`;
};

const getMPLVertical = (naicsCode: string): MPLVertical | null => {
    switch (naicsCode) {
        case '541611':
        case '541612':
        case '541613':
        case '541614':
        case '541618':
        case '541620':
            return 'NonTechnologyBusinessAndManagementConsultants';
        case '531210':
            return 'RealEstateAgent';
        case '541350':
            return 'HomeInspector';
        case '541213':
            return 'TaxPreparersAndBookkeepers';
        case '541211':
            return 'Accountant';

        default:
            return null;
    }
};

const getMPLCoverageMetadata = (mplVertical: MPLVertical): BundleQuoteCoverageMetadata => {
    return {
        title: getMPLTitle(mplVertical),
        description: getMPLDescription(mplVertical),
        icon: coverageIconMap.get(mplVertical) || 'calculator', // using 'calculator' as a fall back to satify TS
        name: 'mpl',
    };
};

const coverageIconMap = new Map<MPLVertical, IconName>([
    ['Accountant', 'calculator'],
    ['TaxPreparersAndBookkeepers', 'tax-preparers'],
    ['RealEstateAgent', 'real-estate'],
    ['HomeInspector', 'home-inspectors'],
    ['NonTechnologyBusinessAndManagementConsultants', 'non-technology'],
]);
