import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import {
    PremiumFinanceUpdateBillingInfoACHInput,
    QuoteRepository,
} from '../repositories/QuoteRepository';
import { PremiumFinanceRoutingCodeInvalid } from '../types/errors';

export interface PremiumFinanceUpdateBillingInfoACHRequest {
    readonly routingNumber: string;
    readonly accountNumber: string;
    readonly accountType: string;
    readonly autoChargeACH: boolean;
    readonly quoteNumber: number;
}

export interface PremiumFinanceUpdateBillingInfoACHResponse {
    readonly achSignatureDateTime: Date;
}

const BILLING_METHOD__ACH = 'ACH';
const CUSTOMER_IP_ADDRESS__UNABLE_TO_OBTAIN = 'Unable to obtain';

export interface PremiumFinanceUpdateBillingInfoACH extends UseCase {
    execute(
        request: PremiumFinanceUpdateBillingInfoACHRequest,
    ): AsyncResult<
        PremiumFinanceUpdateBillingInfoACHResponse,
        PremiumFinanceRoutingCodeInvalid | InvalidArgument | OperationFailed
    >;
}

@injectable()
class PremiumFinanceUpdateBillingInfoACHUseCase extends UseCase {
    public static type = Symbol('Payments/PremiumFinanceUpdateBillingInfoACH');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(QuoteRepository) private quoteRepo: QuoteRepository,
    ) {
        super(eventBus);
    }
    public async execute(
        input: PremiumFinanceUpdateBillingInfoACHRequest,
    ): AsyncResult<
        PremiumFinanceUpdateBillingInfoACHResponse,
        PremiumFinanceRoutingCodeInvalid | InvalidArgument | OperationFailed
    > {
        const billingInfoInput: PremiumFinanceUpdateBillingInfoACHInput = {
            routingNumber: input.routingNumber,
            accountNumber: input.accountNumber,
            accountType: input.accountType,
            billingMethod: BILLING_METHOD__ACH,
            customerIpAddress: CUSTOMER_IP_ADDRESS__UNABLE_TO_OBTAIN,
            quoteNumber: input.quoteNumber,
        };
        const updateResult = await this.quoteRepo.updateBillingInfoACH(billingInfoInput);
        if (isErr(updateResult)) {
            return updateResult;
        }

        return Success({
            achSignatureDateTime: updateResult.value.achSignatureDateTime,
        } as PremiumFinanceUpdateBillingInfoACHResponse);
    }
}

export const PremiumFinanceUpdateBillingInfoACH: UseCaseClass<PremiumFinanceUpdateBillingInfoACH> =
    PremiumFinanceUpdateBillingInfoACHUseCase;
