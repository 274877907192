import { URI } from '@embroker/shotwell/core/types/URI';
import {
    Button,
    ColumnLayout,
    Modal,
    ModalActions,
    ModalState,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React, { useContext } from 'react';
import { AppContext } from '../../../../view/AppContext';
import { useNavigation } from '../../../../view/hooks/useNavigation';

export function SomethingWentWrongModal({
    modal,
    quoteNowUri,
    onHide,
}: {
    modal: ModalActions & ModalState;
    quoteNowUri?: URI;
    onHide: () => void;
}) {
    const { closeSlideout, setSlideout } = useContext(AppContext);
    const { navigate } = useNavigation();
    return (
        <Modal {...modal} size="small" dismissable onDismiss={onHide} onClose={onHide}>
            <StackLayout gap="32">
                <StackLayout gap="24">
                    <Text style="heading 3">Something went wrong</Text>
                    <Text style="body 1">
                        We could not process the application due to one or more of the below
                        reasons.
                    </Text>
                    <StackLayout gap="none">
                        <ColumnLayout gap="4">
                            <Text style="body 1">•</Text>
                            <Text style="body 1">
                                We're currently experiencing a service outage.
                            </Text>
                        </ColumnLayout>
                        <ColumnLayout gap="4">
                            <Text style="body 1">•</Text>
                            <Text style="body 1">
                                The document uploaded did not produce enough data, or is unreadable.
                            </Text>
                        </ColumnLayout>
                    </StackLayout>
                    <Text style="body 1">
                        Continue with manually filling out the application or try uploading a
                        different document.
                    </Text>
                </StackLayout>
                <ColumnLayout gap="32">
                    {quoteNowUri ? (
                        <Button
                            onClick={() => {
                                closeSlideout();
                                setSlideout(null);
                                navigate(quoteNowUri);
                                modal.hide();
                            }}
                            data-e2e="continue-button-intake-something-went-wrong"
                        >
                            Continue
                        </Button>
                    ) : null}
                    <TextButton onClick={onHide}>Try Again</TextButton>
                </ColumnLayout>
            </StackLayout>
        </Modal>
    );
}
