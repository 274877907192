import { ContainerModule } from '@embroker/shotwell/core/di';
import { Module } from '../../Module';
import { EmbrokerExcessConfigRepository } from './repositories/EmbrokerExcessConfigRepository';
import { APIEmbrokerExcessConfigRepository } from './repositories/EmbrokerExcessConfigRepository/APIEmbrokerExcessConfigRepository';
import { ExcessQuoteRepository } from './repositories/EmbrokerExcessQuoteRepository';
import { APIEmbrokerExcessQuoteRepository } from './repositories/EmbrokerExcessQuoteRepository/APIEmbrokerExcessQuoteRepository';
import { ExcessCarrierRepository } from './repositories/ExcessCarrierRepository';
import { APIExcessCarrierRepository } from './repositories/ExcessCarrierRepository/APIExcessCarrierRepository';
import { GetEmbrokerExcessEnabled } from './useCases/GetEmbrokerExcessEnabled';
import { GetExcessCarrier } from './useCases/GetExcessCarrier';
import { GetLastExcessQuote } from './useCases/GetLastExcessQuote';
import { ReQuoteExcess } from './useCases/ReQuoteExcess';
import { GenerateQuoteDocumentUrl } from './useCases/GenerateQuoteDocumentUrl';
import { SubmitExcessApplicationForReview } from './useCases/SubmitExcessApplicationForReview';
import { GetRestriction } from './useCases/GetRestriction';
import { ExcessRestrictionRepository } from './repositories/EmbrokerExcessRestrictionRepository';
import { APIEmbrokerExcessRestrictionRepository } from './repositories/EmbrokerExcessRestrictionRepository/APIEmbrokerExcessRestrictionRepository';

export const EmbrokerExcessModule: Module = {
    container: new ContainerModule((bind) => {
        bind<EmbrokerExcessConfigRepository>(EmbrokerExcessConfigRepository)
            .to(APIEmbrokerExcessConfigRepository)
            .inSingletonScope();
        bind<ExcessCarrierRepository>(ExcessCarrierRepository)
            .to(APIExcessCarrierRepository)
            .inSingletonScope();
        bind<GetEmbrokerExcessEnabled>(GetEmbrokerExcessEnabled.type)
            .to(GetEmbrokerExcessEnabled)
            .inSingletonScope();
        bind<GetExcessCarrier>(GetExcessCarrier.type).to(GetExcessCarrier).inSingletonScope();
        bind<GetLastExcessQuote>(GetLastExcessQuote.type).to(GetLastExcessQuote).inSingletonScope();
        bind<ReQuoteExcess>(ReQuoteExcess.type).to(ReQuoteExcess).inSingletonScope();
        bind<GetRestriction>(GetRestriction.type).to(GetRestriction).inSingletonScope();
        bind<GenerateQuoteDocumentUrl>(GenerateQuoteDocumentUrl.type)
            .to(GenerateQuoteDocumentUrl)
            .inSingletonScope();
        bind<SubmitExcessApplicationForReview>(SubmitExcessApplicationForReview.type)
            .to(SubmitExcessApplicationForReview)
            .inSingletonScope();
        bind<ExcessQuoteRepository>(ExcessQuoteRepository)
            .to(APIEmbrokerExcessQuoteRepository)
            .inSingletonScope();
        bind<ExcessRestrictionRepository>(ExcessRestrictionRepository)
            .to(APIEmbrokerExcessRestrictionRepository)
            .inSingletonScope();
    }),
};
