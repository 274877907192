import { isErr } from '@embroker/shotwell/core/types/Result';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Text } from '@embroker/ui-toolkit/v2';
import React, { useEffect, useState } from 'react';
import { GetActiveOrganizationProfile } from '../../../userOrg/useCases/GetActiveOrganizationProfile';

export function BrokerOrganizationName() {
    const [organizationName, setOrganizationName] = useState('');
    const { result } = useUseCase(GetActiveOrganizationProfile);

    useEffect(() => {
        if (result == undefined || isErr(result)) {
            return;
        }
        setOrganizationName(result.value.organization.companyLegalName);
    }, [setOrganizationName, result]);

    return (
        <React.Fragment>
            <br />
            <Text as="span" style="heading 3" color="ui-200">
                for {organizationName}
            </Text>
        </React.Fragment>
    );
}
