import { inject, injectable } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { deepClone } from '@embroker/shotwell/core/object';
import { Money } from '@embroker/shotwell/core/types/Money';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { Revenue } from '@embroker/shotwell/core/types/Revenue';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Year } from '@embroker/shotwell/core/types/Year';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { Immutable } from '@embroker/ui-toolkit/v2';
import {
    UpdateOrganizationProfile,
    UpdateOrganizationProfileRequest,
} from '../../userOrg/useCases/UpdateOrganizationProfile';
import { Applicant } from '../entities/Applicant';
import { ApplicantRepository } from '../repositories/ApplicantRepository';
import { LawBundleQuestionnaireData } from '../types/LawBundleQuestionnaireData';

export interface UpdateApplicantFromTier1Request {
    tier1QuestionnaireData: Immutable<LawBundleQuestionnaireData>;
}

export interface UpdateApplicantFromTier1 extends UseCase {
    execute(
        request: UpdateApplicantFromTier1Request,
    ): AsyncResult<void, UnknownEntity | InvalidArgument | OperationFailed>;
}

@injectable()
class UpdateApplicantFromTier1UseCase extends UseCase implements UpdateApplicantFromTier1 {
    public static type = Symbol('Shopping/UpdateApplicantFromTier1');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicantRepository) private applicantRepository: ApplicantRepository,
        @inject(UpdateOrganizationProfile.type)
        private updateOrganizationProfile: UpdateOrganizationProfile,
    ) {
        super(eventBus);
    }

    public async execute({
        tier1QuestionnaireData,
    }: UpdateApplicantFromTier1Request): AsyncResult<
        void,
        UnknownEntity | InvalidArgument | OperationFailed
    > {
        const applicantResult = await this.applicantRepository.getApplicant();
        if (isErr(applicantResult)) {
            return applicantResult;
        }
        const applicant = applicantResult.value;

        const organizationProfileData = questionnaireToOrganizationData(
            applicant,
            tier1QuestionnaireData,
        );
        const updateOrganizationProfileResponse = await this.updateOrganizationProfile.execute(
            organizationProfileData,
        );

        if (isErr(updateOrganizationProfileResponse)) {
            return handleOperationFailure(updateOrganizationProfileResponse);
        }

        return Success();
    }
}

function questionnaireToOrganizationData(
    applicantData: Immutable<EntityProps<Applicant>>,
    answers: Immutable<LawBundleQuestionnaireData>,
): UpdateOrganizationProfileRequest {
    const result: UpdateOrganizationProfileRequest = {
        id: applicantData.id as UUID,
    };

    if (answers.gross_revenue_total) {
        const DECEMBER = 11;
        const currentTime = new Date(Date.now());
        const currentYear = currentTime.getFullYear();
        const currentMonth = currentTime.getMonth();
        const referenceYear = (currentMonth === DECEMBER ? currentYear : currentYear - 1) as Year;
        let revenueList = deepClone(applicantData.revenueList) as Revenue[] | undefined;
        const referenceYearRevenue = Money.tryFromFloat(answers.gross_revenue_total);

        if (revenueList && revenueList.length !== 0) {
            const referenceYearIndex = revenueList.findIndex(
                (revenue) => revenue.fiscalYear === referenceYear,
            );
            if (referenceYearIndex !== -1) {
                revenueList[referenceYearIndex].grossTotal = referenceYearRevenue;
            } else {
                revenueList.push({
                    fiscalYear: referenceYear,
                    grossTotal: referenceYearRevenue,
                });
            }
        } else {
            revenueList = [
                {
                    fiscalYear: referenceYear,
                    grossTotal: referenceYearRevenue,
                },
            ];
        }
        result.revenues = revenueList;
    }

    return result;
}

export const UpdateApplicantFromTier1: UseCaseClass<UpdateApplicantFromTier1> =
    UpdateApplicantFromTier1UseCase;
