import { Immutable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import {
    AppTypeCode,
    AppTypeCodeList,
    InsuranceApplicationCreationTypeCode,
    InsuranceApplicationCreationTypeCodeList,
    InsuranceApplicationStatusCode,
    InsuranceApplicationStatusCodeList,
    QuotingEngine,
    QuotingEngineList,
} from '../../shopping/types/enums';
import { IneligibilityReasons } from '../../shopping/types/IneligibilityReasons';
import { ProductType, ProductTypeList } from './ProductType';

export interface BrokerQuoteRecord {
    readonly applicationId: UUID;
    readonly managingBroker?: string;
    readonly organizationId: UUID;
    readonly organizationName: string;
    readonly premium?: Money;
    readonly dateStarted?: Date;
    readonly dateSubmitted?: Date;
    readonly status: InsuranceApplicationStatusCode;
    readonly isPristine: boolean;
    readonly hasQuotes: boolean;
    readonly quotingEngine?: QuotingEngine;
    readonly ineligibilityReasons?: Immutable<IneligibilityReasons>;
    readonly daysToQuoteExpiration?: number;
    readonly appType: AppTypeCode;
    readonly coverageType?: ProductType;
    readonly creationType: InsuranceApplicationCreationTypeCode;
}

export const BrokerQuoteRecord = {
    ...defineValidator<BrokerQuoteRecord>({
        applicationId: UUID.schema,
        managingBroker: Joi.string().optional(),
        organizationId: UUID.schema,
        organizationName: Joi.string(),
        premium: Money.schema.optional(),
        dateStarted: Joi.date().optional(),
        dateSubmitted: Joi.date().optional(),
        status: Joi.string().valid(...InsuranceApplicationStatusCodeList),
        isPristine: Joi.boolean(),
        hasQuotes: Joi.boolean(),
        quotingEngine: Joi.string()
            .valid(...QuotingEngineList)
            .optional(),
        ineligibilityReasons: IneligibilityReasons.schema.optional(),
        daysToQuoteExpiration: Joi.number().optional(),
        appType: Joi.string().valid(...AppTypeCodeList),
        coverageType: Joi.string()
            .valid(...ProductTypeList)
            .optional(),
        creationType: Joi.string().valid(...InsuranceApplicationCreationTypeCodeList),
    }),
    create(provider: BrokerQuoteRecord) {
        return BrokerQuoteRecord.validate(provider);
    },
};
