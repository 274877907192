import { UUID } from '@embroker/shotwell/core/types/UUID';
import {
    BoxLayout,
    CardLayout,
    CenterLayout,
    ColumnLayout,
    Filter,
    FilterOption,
    PageLayout,
    Spinner,
    StackLayout,
    Table,
    Text,
    TextButton,
    useAsyncTable,
} from '@embroker/ui-toolkit/v2';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../../view/AppContext';
import { Link } from '../../../../view/components/Link/Link';
import { QuoteTableColumn } from '../../../useCases/GetBrokerQuotes';
import { BrokerFilter, Filters } from '../BrokerFilter';
import { BrokerTableHeader } from '../BrokerTableHeader';
import { BrokerQuoteTableRow, getQuotesPageDataFunction } from './BrokerQuotesTableData';

type BrokerQuoteStatus =
    | 'in_progress'
    | 'quotes_ready'
    | 'in_review'
    | 'quote_expired'
    | 'referred'
    | 'clearance'
    | 'declined';

interface BrokerQuotesProps {
    itemsPerPage?: number;
    showEditApplicationForExcess?: boolean;
}

export function BrokerQuotes({
    itemsPerPage = 10,
    showEditApplicationForExcess = false,
}: BrokerQuotesProps) {
    const { activeSession } = useContext(AppContext);

    const initialFilters = [];
    const [selectedBroker, setSelectedBroker] = useState<'all' | UUID>(
        activeSession.userId ?? 'all',
    );
    if (activeSession.userId !== null) {
        initialFilters.push({ target: 'managing_broker', value: activeSession.userId });
    }

    const [selectedStatusFilters, setSelectedStatusFilters] = useState<BrokerQuoteStatus[]>([]);

    const onStatusTypeFilterChange = (event: {
        readonly target: { readonly value: BrokerQuoteStatus[] };
    }) => {
        setSelectedStatusFilters(event.target.value);

        const statusFilterInfo = event.target.value.map(mapStatusFilterToSortInfo);
        const brokerFilter =
            selectedBroker === 'all' ? [] : [{ target: 'managing_broker', value: selectedBroker }];

        filterBy([...brokerFilter, ...statusFilterInfo]);
    };

    const filterByBroker = (filters: Filters) => {
        const statusFilterInfo = selectedStatusFilters.map(mapStatusFilterToSortInfo);
        const brokerFilters = filters ?? [];
        filterBy([...brokerFilters, ...statusFilterInfo]);
    };

    const { visibleItems, isLoading, sortedBy, sortBy, filterBy, pagination } =
        useAsyncTable<BrokerQuoteTableRow>({
            fetchPageData: getQuotesPageDataFunction(showEditApplicationForExcess),
            itemsPerPage,
            initialSortBy: { column: 'status', order: 'ASC' },
            initialFilters,
            prefetch: true,
        });
    const noItems = visibleItems.length === 0;
    const areFiltersApplied =
        selectedBroker !== activeSession.userId || selectedStatusFilters.length > 0;

    function handleSortBy(column: QuoteTableColumn, initialOrder?: 'ASC' | 'DESC') {
        return (event: React.MouseEvent) => {
            event.preventDefault();
            sortBy(
                column,
                sortedBy?.column !== column
                    ? initialOrder ?? 'ASC'
                    : sortedBy?.order === 'ASC'
                    ? 'DESC'
                    : 'ASC',
            );
        };
    }

    return (
        <PageLayout.Section>
            <StackLayout>
                <TextButton
                    size="small"
                    as={Link}
                    icon="bold-caret-left"
                    iconPosition="left"
                    href="/broker/dashboard"
                >
                    Back to dashboard
                </TextButton>
                {isLoading ? (
                    <Spinner />
                ) : (
                    <CardLayout>
                        <CardLayout.Header>
                            <BrokerTableHeader
                                noItems={noItems}
                                pagination={pagination}
                                title="Recent Quotes"
                            />
                        </CardLayout.Header>
                        <CardLayout.Body>
                            <StackLayout gap={noItems ? '24' : 'none'}>
                                <ColumnLayout
                                    responsive={{ containerWidth: { smallerThan: 'mobile' } }}
                                >
                                    <BrokerFilter
                                        selectedBroker={selectedBroker}
                                        onBrokerSelect={setSelectedBroker}
                                        filterBy={filterByBroker}
                                    />
                                    <Filter
                                        className="access_quotes_status_filter"
                                        title="Filter by status"
                                        options={StatusFilters}
                                        value={selectedStatusFilters}
                                        onChange={onStatusTypeFilterChange}
                                        multiple
                                    />
                                </ColumnLayout>
                                {!noItems ? (
                                    <Table>
                                        <Table.Header>
                                            <Table.Column
                                                isSortable
                                                isSortedBy={
                                                    sortedBy?.column === 'organization_name'
                                                }
                                                sortDirection={sortedBy?.order}
                                                onClick={handleSortBy('organization_name')}
                                            >
                                                Company
                                            </Table.Column>
                                            <Table.Column
                                                isSortable
                                                isSortedBy={sortedBy?.column === 'managing_broker'}
                                                sortDirection={sortedBy?.order}
                                                onClick={handleSortBy('managing_broker')}
                                                width="1/5"
                                            >
                                                Broker
                                            </Table.Column>
                                            <Table.Column
                                                isSortable
                                                isSortedBy={sortedBy?.column === 'status'}
                                                sortDirection={sortedBy?.order}
                                                onClick={handleSortBy('status')}
                                                width="1/8"
                                            >
                                                Status
                                            </Table.Column>
                                            <Table.Column
                                                isSortable
                                                isSortedBy={sortedBy?.column === 'product_type'}
                                                sortDirection={sortedBy?.order}
                                                onClick={handleSortBy('product_type')}
                                                width="1/10"
                                            >
                                                Coverage Type
                                            </Table.Column>
                                            <Table.Column
                                                isSortable
                                                isSortedBy={sortedBy?.column === 'date_submitted'}
                                                sortDirection={sortedBy?.order}
                                                onClick={handleSortBy('date_submitted')}
                                                width="1/8"
                                            >
                                                Date Submitted
                                            </Table.Column>
                                            <Table.Column
                                                isSortable
                                                isSortedBy={sortedBy?.column === 'premium'}
                                                sortDirection={sortedBy?.order}
                                                onClick={handleSortBy('premium', 'DESC')}
                                                width="1/10"
                                            >
                                                Premium
                                            </Table.Column>
                                            <Table.Column width="1/8">Action</Table.Column>
                                        </Table.Header>
                                        <Table.Body>
                                            {visibleItems.map((row) => (
                                                <Table.Row
                                                    key={row.id as UUID}
                                                    data-e2e="quote-table"
                                                >
                                                    <Table.Cell truncate data-e2e="company-name">
                                                        {row.companyName}
                                                    </Table.Cell>
                                                    <Table.Cell truncate data-e2e="managing-broker">
                                                        {row.managingBroker}
                                                    </Table.Cell>
                                                    <Table.Cell data-e2e="quote-status">
                                                        {row.status}
                                                    </Table.Cell>
                                                    <Table.Cell data-e2e="quote-coverage-type">
                                                        {row.coverageType}
                                                    </Table.Cell>
                                                    <Table.Cell data-e2e="quote-date-submitted">
                                                        {row.dateSubmitted}
                                                    </Table.Cell>
                                                    <Table.Cell data-e2e="quote-premium">
                                                        {row.premium}
                                                    </Table.Cell>
                                                    <Table.Cell data-e2e="quote-action">
                                                        {row.action}
                                                    </Table.Cell>
                                                </Table.Row>
                                            ))}
                                        </Table.Body>
                                    </Table>
                                ) : (
                                    <BoxLayout gap="24">
                                        <CenterLayout centerText>
                                            <Text style="label 1">
                                                {areFiltersApplied
                                                    ? 'No records match applied filter criteria.'
                                                    : 'You have no quotes yet. Once you get a quote the list will appear here.'}
                                            </Text>
                                        </CenterLayout>
                                    </BoxLayout>
                                )}
                            </StackLayout>
                        </CardLayout.Body>
                    </CardLayout>
                )}
            </StackLayout>
        </PageLayout.Section>
    );
}

const StatusFilters: FilterOption<BrokerQuoteStatus>[] = [
    {
        value: 'in_progress',
        title: 'In Progress',
    },
    {
        value: 'quotes_ready',
        title: 'Quotes Ready',
    },
    {
        value: 'in_review',
        title: 'In Review',
    },
    {
        value: 'quote_expired',
        title: 'Quote Expired',
    },
    {
        value: 'referred',
        title: 'Referred',
    },
    {
        value: 'clearance',
        title: 'Clearance',
    },
    {
        value: 'declined',
        title: 'Declined',
    },
];

function mapStatusFilterToSortInfo(filter: BrokerQuoteStatus) {
    return { target: 'status', value: filter };
}
