import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Money, USD } from '@embroker/shotwell/core/types/Money';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    isOK,
    Result,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { Immutable } from '@embroker/shotwell/core/types';
import { Year } from '@embroker/shotwell/core/types/Year';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { Policy } from '../../policy/entities/Policy';
import { PolicyRepository } from '../../policy/repositories/PolicyRepository';
import { ClaimRepository } from '../repositories/claims';
import { PoliciesPerYear } from '../types/PoliciesPerYear';
import { PolicyWithClaims } from '../types/PolicyWithClaims';
import { Claim } from '../entities/Claim';
import { ClaimDetails } from '../types/ClaimDetails';

export interface GetClaimsHistoryResponse {
    policiesBORPerYear: PoliciesPerYear[];
    policiesNonBORPerYear: PoliciesPerYear[];
}

export const GetClaimsHistoryResponse = {
    ...defineValidator<GetClaimsHistoryResponse>({
        policiesBORPerYear: Joi.array().items(PoliciesPerYear.schema),
        policiesNonBORPerYear: Joi.array().items(PoliciesPerYear.schema),
    }),
    create(getClaimsHistoryResponse: GetClaimsHistoryResponse) {
        return GetClaimsHistoryResponse.validate(getClaimsHistoryResponse);
    },
};

export interface GetClaimsHistory extends UseCase {
    execute(): AsyncResult<GetClaimsHistoryResponse, InvalidArgument | OperationFailed>;
}

@injectable()
export class GetClaimsHistoryUseCase extends UseCase {
    public static type = Symbol('Claim/GetClaimsHistory');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ClaimRepository) private claimRepository: ClaimRepository,
        @inject(PolicyRepository) private policyRepository: PolicyRepository,
    ) {
        super(eventBus);
    }

    async execute(): AsyncResult<GetClaimsHistoryResponse, InvalidArgument | OperationFailed> {
        const getClaimsResult = await this.claimRepository.getClaims();
        if (isErr(getClaimsResult)) {
            return getClaimsResult;
        }

        const getPoliciesResult = await this.policyRepository.getPolicies();
        if (isErr(getPoliciesResult)) {
            return getPoliciesResult;
        }

        const orderedPoliciesList = (getPoliciesResult.value as Policy[]).sort(
            (a: Policy, b: Policy) => {
                if (a.startDate.getFullYear() > b.startDate.getFullYear()) {
                    return -1;
                }
                if (a.startDate.getFullYear() < b.startDate.getFullYear()) {
                    return 1;
                }
                return 0;
            },
        );

        const borPoliciesPerYear: PoliciesPerYear[] = [];
        const nonBORPoliciesPerYear: PoliciesPerYear[] = [];

        for (const policy of orderedPoliciesList) {
            const claimsForPolicy = getClaimsResult.value.get(policy.id);
            const mappedClaimListResult = await this.mapClaimList(claimsForPolicy);
            if (isErr(mappedClaimListResult)) {
                return mappedClaimListResult;
            }
            const mappedClaimList = mappedClaimListResult.value as ClaimDetails[];
            const sumLosses = Money.sum(mappedClaimList.map((claim) => claim.amountPaid ?? USD(0)));
            let totalIncurredLosses = USD(0);

            if (isOK(sumLosses)) {
                totalIncurredLosses = sumLosses.value;
            }

            const policyWithClaims = PolicyWithClaims.create({
                id: policy.id,
                policyNumber: policy.policyNumber ? policy.policyNumber : '-',
                displayName: policy.name,
                startDate: policy.startDate,
                endDate: policy.endDate,
                lineOfBusiness: policy.lineOfBusiness,
                insurerName: policy.insurerName,
                claimList: mappedClaimList,
                openClaims:
                    mappedClaimList?.filter((claim) => claim.status === 'ClaimStatusCodeListOpen')
                        .length || 0,
                totalClaims: mappedClaimList?.length || 0,
                premiumPerYear: policy.premiumPerYear ?? undefined,
                totalIncurredLosses,
                borStatus: policy.borStatus,
                subLineOfBusiness: policy.subLineOfBusiness,
            });

            if (isErr(policyWithClaims)) {
                return policyWithClaims;
            }

            if (policy.borStatus == 'b_o_r') {
                this.addClaimToPoliciesPerYear(
                    borPoliciesPerYear,
                    policyWithClaims.value as PolicyWithClaims,
                );
            } else {
                this.addClaimToPoliciesPerYear(
                    nonBORPoliciesPerYear,
                    policyWithClaims.value as PolicyWithClaims,
                );
            }
        }

        return GetClaimsHistoryResponse.create({
            policiesBORPerYear: borPoliciesPerYear,
            policiesNonBORPerYear: nonBORPoliciesPerYear,
        });
    }

    private async mapClaimList(
        claimList?: Claim[],
    ): AsyncResult<ClaimDetails[], InvalidArgument | OperationFailed> {
        const claimDetails: Immutable<ClaimDetails>[] = [];
        if (!Array.isArray(claimList)) {
            return Success(claimDetails);
        }
        for (const claim of claimList) {
            const getDocumentsResult = await this.claimRepository.getClaimDocuments(claim.id);
            if (isErr(getDocumentsResult)) {
                return handleOperationFailure(getDocumentsResult);
            }
            const claimDetailsResult = ClaimDetails.create({
                adjusterName: claim.adjusterName,
                adjusterPhoneNumber: claim.adjusterPhoneNumber,
                causeOfLoss: claim.causeOfLoss,
                claimNumber: claim.claimNumber,
                claimantList: claim.claimantList,
                closedDate: claim.closedDate,
                lossDate: claim.lossDate,
                reportedDate: claim.reportedDate,
                reservedAmount: claim.reservedAmount,
                amountPaid: claim.amountPaid,
                status: claim.status,
                valuationDate: claim.valuationDate,
                documents: getDocumentsResult.value,
            });
            if (isErr(claimDetailsResult)) {
                return claimDetailsResult;
            }
            claimDetails.push(claimDetailsResult.value);
        }
        return Success(claimDetails);
    }

    private addClaimToPoliciesPerYear(
        policiesPerYear: PoliciesPerYear[],
        policyWithClaims: PolicyWithClaims,
    ): Result<void, InvalidArgument | OperationFailed> {
        let policyPerYear: PoliciesPerYear;
        if (
            policiesPerYear.length == 0 ||
            policiesPerYear[policiesPerYear.length - 1].year.valueOf() !=
                policyWithClaims.startDate.getFullYear()
        ) {
            const year = Year.create(policyWithClaims.startDate.getFullYear());
            if (isErr(year)) {
                return Failure(
                    InvalidArgument({
                        argument: 'Start year is not a valid year',
                        value: policyWithClaims.startDate,
                    }),
                );
            }
            policyPerYear = { year: year.value, policies: [] };
            policiesPerYear.push(policyPerYear);
        } else {
            policyPerYear = policiesPerYear[policiesPerYear.length - 1];
        }

        policyPerYear.policies.push(policyWithClaims);
        return Success();
    }
}

export const GetClaimsHistory: UseCaseClass<GetClaimsHistory> = GetClaimsHistoryUseCase;
