import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { PolicyFilterRepository } from '../repositories/PolicyFilterRepository';
import { PolicyFilter } from '../types/PolicyFilter';

/**
 * Request data for UpdatePolicyFilter use case
 */
export interface UpdatePolicyFilterRequest {
    filter: PolicyFilter;
}

/**
 * Response data for GetPolicyFilter use case
 */
export interface UpdatePolicyFilterResponse {
    filter: PolicyFilter;
}

/**
 * UpdatePolicyFilter use case is used to ...
 */
export interface UpdatePolicyFilter extends UseCase {
    execute(request: UpdatePolicyFilterRequest): AsyncResult<UpdatePolicyFilterResponse>;
}

@injectable()
class UpdatePolicyFilterUseCase extends UseCase implements UpdatePolicyFilter {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('policy/UpdatePolicyFilter');

    /**
     * Constructor for UpdatePolicyFilter use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param policyFilterRepository A repository which stores policyFilters
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(PolicyFilterRepository) private policyFilterRepository: PolicyFilterRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes UpdatePolicyFilter use case
     * Input is of SetPolicyFilterRequest type
     * @returns data of SetPolicyFilterResponse type if execution was successful
     */
    public async execute(
        request: UpdatePolicyFilterRequest,
    ): AsyncResult<UpdatePolicyFilterResponse> {
        const filter = this.policyFilterRepository.save(request.filter);
        const result: UpdatePolicyFilterResponse = {
            filter,
        };
        return Success(result);
    }
}

export const UpdatePolicyFilter: UseCaseClass<UpdatePolicyFilter> = UpdatePolicyFilterUseCase;
