import { Module } from '../../Module';
import { ContainerModule } from '@embroker/shotwell/core/di';
import { CyberQuoteRepository } from './repositories/CyberQuoteRepository';
import { APICyberQuoteRepository } from './repositories/CyberQuoteRepository/APICyberQuoteRepository';
import { GetLastCyberQuote } from './useCases/GetLastCyberQuote';
import { ReQuoteCyber } from './useCases/ReQuoteCyber';
import { PurchaseCyber } from './useCases/PurchaseCyber';
import { GetCyberConfig } from './useCases/GetCyberConfig';
import { CyberConfigRepository } from './repositories/CyberConfigRepository';
import { APICyberConfigRepository } from './repositories/CyberConfigRepository/APICyberConfigRepository';
import { GenerateQuoteSummaryUrl } from './useCases/GenerateQuoteSummaryUrl';
import { GenerateSpecimenPolicyUrl } from './useCases/GenerateSpecimenPolicyUrl';

export const CyberQuoteModule: Module = {
    container: new ContainerModule((bind) => {
        bind<CyberQuoteRepository>(CyberQuoteRepository)
            .to(APICyberQuoteRepository)
            .inSingletonScope();
        bind<CyberConfigRepository>(CyberConfigRepository)
            .to(APICyberConfigRepository)
            .inSingletonScope();
        bind<GetLastCyberQuote>(GetLastCyberQuote.type).to(GetLastCyberQuote).inSingletonScope();
        bind<ReQuoteCyber>(ReQuoteCyber.type).to(ReQuoteCyber).inSingletonScope();
        bind<PurchaseCyber>(PurchaseCyber.type).to(PurchaseCyber).inSingletonScope();
        bind<GetCyberConfig>(GetCyberConfig.type).to(GetCyberConfig).inSingletonScope();
        bind<GenerateQuoteSummaryUrl>(GenerateQuoteSummaryUrl.type)
            .to(GenerateQuoteSummaryUrl)
            .inSingletonScope();
        bind<GenerateSpecimenPolicyUrl>(GenerateSpecimenPolicyUrl.type)
            .to(GenerateSpecimenPolicyUrl)
            .inSingletonScope();
    }),
};
