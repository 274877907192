import { Modal, ModalActions, ModalState, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { ModalLayout } from '../../../../view/components/ModalLayout.view';

interface GapBetweenPoliciesBrokerModalInput {
    readonly modal: ModalActions & ModalState;
}

export function GapBetweenPoliciesBrokerModal({ modal }: GapBetweenPoliciesBrokerModalInput) {
    const { navigate } = useNavigation();
    return (
        <Modal {...modal} size="small" dismissable={false}>
            <ModalLayout
                title="Please Review Your Info"
                primaryAction={{
                    label: 'Back To Dashboard',
                    onClick: () => navigate('/broker/dashboard'),
                }}
                secondaryAction={{
                    label: 'Open Live Chat',
                    href: '#chat-now',
                }}
            >
                <Text>
                    Since there is a gap between current policy end (expiration) date and the
                    beginning of the new policy coverage, please contact Embroker.
                </Text>
                <Text>Or contact us via live chat or call us at 844.436.2765.</Text>
            </ModalLayout>
        </Modal>
    );
}
