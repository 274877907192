import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    BoxLayout,
    Button,
    CardLayout,
    CenterLayout,
    ColumnLayout,
    InvoiceTable,
    Loader,
    PageLayout,
    RadioGroup,
    StackLayout,
    Text,
    TextButton,
    useModal,
} from '@embroker/ui-toolkit/v2';
import React, { useState } from 'react';
import { GetGlobalConfig } from '../../../../config/useCases/GetGlobalConfigUseCase';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { Quote } from '../../../entities/Quote';
import { ThankYouModal } from '../modals/ThankYouModal';
import { BANK_VALUE, CREDIT_CARD_VALUE } from '../PaymentsDashboard';
import { BillingInfoACHModal } from './modals/BilingInfoACHModal';
import { BillingInfoCreditCardModal } from './modals/BillingInfoCreditCardModal';
import { LeavingToFIFQuoteInfo, LeavingToFIFSite } from './modals/LeavingEmbrokerSite';

export interface DownPaymentPageProps {
    next(): void;
    previous(): void;
    quote: Nullable<Quote>;
    dueNow?: Money;
    activeStepIndex: number;
    eligibleInvoicesIDs: UUID[];
}

interface DownPaymentProps extends DownPaymentPageProps {
    quote: Quote;
    dueNow: Money;
}

function DownPayment({ quote, ...props }: DownPaymentProps) {
    const [paymentOption, setPaymentOption] = useState(BANK_VALUE);
    const { navigate } = useNavigation();
    const [dueNow, setDueNow] = useState<Money>(props.dueNow);
    const [fifQuote, setFifQuote] = useState<LeavingToFIFQuoteInfo>({
        quoteNumber: quote.quoteNumber,
        downPayBankUrl: quote.downPayBankUrl,
        downPayCreditCardUrl: quote.downPayCreditCardUrl,
    });

    const leavingToFIFModal = useModal();
    const thankYouModal = useModal();
    const billingInfoACHModal = useModal();
    const billingInfoCreditCardModal = useModal();

    const { result: globalConfig, isLoading: isGlobalConfigLoading } = useUseCase(GetGlobalConfig);
    let isBillingMethodUpdateEnabled: boolean;

    const [signedDateTimeTermsAndConditions, setSignedDateTimeTermsAndConditions] =
        useState<Date>();

    if (isGlobalConfigLoading || globalConfig == undefined) {
        return <Loader />;
    }

    if (isOK(globalConfig)) {
        isBillingMethodUpdateEnabled =
            globalConfig.value.config.isPremiumFinanceBillingMethodUpdateEnabled;
    }

    const CREDIT_CARD_DOWN_PAYMENT_THRESHOLD = 83166; // The amount is represented in cents.
    const PREMIUM_FINANCE_CREDIT_CARD_FEE = 3.0;

    function handleOnPaymentChange(option: string) {
        setPaymentOption(option);
        if (option === BANK_VALUE) {
            setDueNow(props.dueNow);
        } else {
            const creditCardFee =
                dueNow.amount <= CREDIT_CARD_DOWN_PAYMENT_THRESHOLD
                    ? Money.tryFromFloat(24.95, { currency: props.dueNow.currency })
                    : Money.percentage(props.dueNow, PREMIUM_FINANCE_CREDIT_CARD_FEE);

            const sumResult = Money.sum([props.dueNow, creditCardFee]);
            if (isOK(sumResult)) {
                setDueNow(sumResult.value);
            } else {
                // this should never happen
                container
                    .get<Logger>(Log)
                    .error('Unexpected error while calculating due now amount', sumResult.errors);
            }
        }
    }

    function handleOnSignedDateTimeTermsAndConditionsChange(dateTime: Date) {
        setSignedDateTimeTermsAndConditions(dateTime);
    }

    function handleContinue() {
        if (isBillingMethodUpdateEnabled) {
            if (paymentOption == BANK_VALUE) {
                return showBillingInfoACHModal();
            }
            if (paymentOption == CREDIT_CARD_VALUE) {
                return showBillingInfoCreditCardModal();
            }
        }
        showLeavingToFIFModal();
    }

    function hideModal() {
        leavingToFIFModal.hide();
    }

    function showLeavingToFIFModal() {
        leavingToFIFModal.show();
    }

    function onContinueToFIFModal(recurringCreditCardURL?: URI) {
        setFifQuote({
            ...fifQuote,
            recurringCreditCardURL: recurringCreditCardURL,
        });
        leavingToFIFModal.show();
    }

    function hideBillingInfoCreditCardModal() {
        billingInfoCreditCardModal.hide();
    }

    function showBillingInfoCreditCardModal() {
        billingInfoCreditCardModal.show();
    }

    function hideBillingInfoACHModal() {
        billingInfoACHModal.hide();
    }

    function showBillingInfoACHModal() {
        billingInfoACHModal.show();
    }

    function openThankYouModal() {
        leavingToFIFModal.hide();
        thankYouModal.show();
    }

    function closeThankYouModal() {
        thankYouModal.hide();
        navigate('/payments');
    }

    function StatefulRadioGroup(props: {
        id: string;
        items: { title: string; value: string; note?: string }[];
    }) {
        return (
            <RadioGroup
                {...props}
                onChange={(e) => handleOnPaymentChange(e.target.value)}
                value={paymentOption}
            />
        );
    }

    return (
        <React.Fragment>
            <PageLayout.Section>
                <StackLayout gap="24">
                    <TextButton size="small" onClick={props.previous} icon="bold-caret-left">
                        Back to signature
                    </TextButton>
                    <StackLayout gap="32">
                        <Text style="heading 2">Embroker Premium Financing</Text>
                    </StackLayout>
                    <ColumnLayout gap="24">
                        <CardLayout>
                            <BoxLayout gap="24">
                                <Text style="heading 4">Down Payment</Text>
                            </BoxLayout>
                            <InvoiceTable.Separator />
                            <BoxLayout gap="24">
                                <StackLayout data-e2e="fif-down-payment-method-wrapper">
                                    <Text color="ui-500">
                                        Please select your preferred payment method for your down
                                        payment.
                                    </Text>
                                    <StatefulRadioGroup
                                        id="group-2"
                                        items={[
                                            {
                                                title: 'Pay via your bank',
                                                value: BANK_VALUE,
                                            },
                                            {
                                                title: 'Pay with your credit card',
                                                note: `Includes ${PREMIUM_FINANCE_CREDIT_CARD_FEE}% fee or $24.95 (whichever greater)`,
                                                value: CREDIT_CARD_VALUE,
                                            },
                                        ]}
                                    />
                                </StackLayout>
                            </BoxLayout>
                            <InvoiceTable.Separator />
                            <BoxLayout gap="24">
                                <ColumnLayout split="-1">
                                    <Text style="body 1">
                                        <b>Due now</b>
                                    </Text>
                                    <Text style="heading 5">
                                        <MoneyDisplay value={dueNow} />
                                    </Text>
                                </ColumnLayout>
                            </BoxLayout>
                            <InvoiceTable.Separator />
                            <BoxLayout gap="24">
                                <CenterLayout>
                                    <Button
                                        data-e2e="fif-down-payment-submit-button"
                                        appearance="primary"
                                        onClick={handleContinue}
                                    >
                                        Continue
                                    </Button>
                                </CenterLayout>
                            </BoxLayout>
                        </CardLayout>
                    </ColumnLayout>
                </StackLayout>
            </PageLayout.Section>
            <LeavingToFIFSite
                hide={hideModal}
                openThankYou={openThankYouModal}
                invoiceIDs={props.eligibleInvoicesIDs}
                modal={leavingToFIFModal}
                option={paymentOption}
                quote={fifQuote}
                signedDateTimeTermsAndConditions={signedDateTimeTermsAndConditions}
            />
            <BillingInfoACHModal
                modal={billingInfoACHModal}
                hideModal={hideBillingInfoACHModal}
                quoteNumber={quote.quoteNumber}
                showLeavingToFIFModal={showLeavingToFIFModal}
                onSignedDateTimeTermsAndConditionsChange={
                    handleOnSignedDateTimeTermsAndConditionsChange
                }
            />
            <BillingInfoCreditCardModal
                modal={billingInfoCreditCardModal}
                hideModal={hideBillingInfoCreditCardModal}
                quoteNumber={quote.quoteNumber}
                onContinue={onContinueToFIFModal}
                onSignedDateTimeTermsAndConditionsChange={
                    handleOnSignedDateTimeTermsAndConditionsChange
                }
            />
            <ThankYouModal modal={thankYouModal} hideModal={closeThankYouModal}>
                You have been redirected to the FIF site.
            </ThankYouModal>
        </React.Fragment>
    );
}

export function DownPaymentPage(props: DownPaymentPageProps) {
    if (props.activeStepIndex !== 3 || props.quote === null || props.dueNow === undefined) {
        return null;
    }
    return <DownPayment {...(props as DownPaymentProps)} />;
}
