import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { UUID } from '@embroker/shotwell/core/types/UUID';

export interface PolicyAttorney {
    id: UUID;
    averageWeeklyHours: number;
    barAdmittedDate: Date;
    fullName: string;
    hireDate: Date;
}

export const PolicyAttorney = {
    ...defineValidator<PolicyAttorney>({
        id: UUID.schema,
        averageWeeklyHours: Joi.number(),
        barAdmittedDate: Joi.date(),
        fullName: Joi.string(),
        hireDate: Joi.date(),
    }),
    create(policyAttorney: PolicyAttorney) {
        return PolicyAttorney.validate(policyAttorney);
    },
};
