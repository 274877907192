import { API } from '@embroker/shotwell-api/app';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import {
    AsyncResult,
    Success,
    isErr,
    handleOperationFailure,
} from '@embroker/shotwell/core/types/Result';
import { ContactUsForm } from '../../types/ContactForm';
import { ContactUsRepository } from './index';

@injectable()
export class APIContactUsRepository implements ContactUsRepository {
    public async contactUs({
        subject,
        name,
        email,
        phoneNumber,
        timeToReach,
        message,
    }: ContactUsForm): AsyncResult<void, InvalidArgument | OperationFailed> {
        const organizationsResult = await API.request('global/contact_us', {
            subject: subject,
            name: name,
            email: email,
            phone_number: phoneNumber ?? '',
            time_to_reach: timeToReach?.join(', ') ?? '',
            message: message ?? '',
        });

        if (isErr(organizationsResult)) {
            return handleOperationFailure(organizationsResult);
        }

        return Success();
    }
}
