import { FormData, OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { StatusMessage } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { WizardForm } from '../../../view/hooks/useWizardForm';

interface ErrorsProps<T extends FormData> {
    messages: Readonly<string[]>;
    action?: string;
    trigger: WizardForm<OpaqueForm<T>>['trigger'];
}

export function Errors<T extends FormData>({ messages, action, trigger }: ErrorsProps<T>) {
    return (
        <React.Fragment>
            {messages.length > 0 && action === 'update' ? (
                <StatusMessage status="warning">
                    Please verify that all the information is correct and click on the ‘Recalculate’
                    button to proceed. If issues persist, please contact Embroker.
                </StatusMessage>
            ) : null}
            {messages.length > 0 && action === 'requestHigherLimits' ? (
                <StatusMessage status="error">Higher limits request has failed</StatusMessage>
            ) : null}
        </React.Fragment>
    );
}
