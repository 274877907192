import { Nullable } from '@embroker/shotwell/core/types';
import { State } from '@embroker/shotwell/core/types/StateList';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import {
    defineValidator,
    Joi,
    Schema,
    TypeChecker,
    Validator,
} from '@embroker/shotwell/core/validation/schema';
import { physicalAddressValidator } from '@embroker/shotwell/core/types/POBox';
import { isOK } from '@embroker/shotwell/core/types/Result';

/**
 * Represents a mailing address object
 */
export interface MailingAddress {
    /**
     * The first address line of this business location (street and a number).
     */
    readonly addressLine1: Nullable<string>;
    /**
     * The second address line of this business location (floor, suite, etc.).
     */
    readonly addressLine2?: Nullable<string>;
    /**
     * The city of this business location.
     */
    readonly city: Nullable<string>;
    /**
     * The state of this business location.
     */
    readonly state: Nullable<State>;
    /**
     * The county of this business location, some US states have counties
     */
    readonly county?: Nullable<string>;
    /**
     * The zip code of this business location.
     */
    readonly zip: Nullable<ZipCode>;
}

export interface MailingAddressValidator {
    /**
     * A Joi schema matching a valid MailingAddress object.
     */
    readonly schema: Schema.ObjectSchema<MailingAddress>;
    /**
     * Type predicate that checks if a given value can be used as MailingAddress object.
     *
     * Use this only to do early returns. It's recommended to use validate()
     * before using an unknown value as MailingAddress object as it normalizes the value
     * in addition to performing the same validation as check().
     */
    readonly check: TypeChecker<MailingAddress>;
    /**
     * Validates and normalizes the given value to a MailingAddress object.
     *
     * This should be used for all untrusted inputs to verify and, if required
     * (and possible), normalize the input.
     */
    readonly validate: Validator<MailingAddress>;
}

export const MailingAddress = {
    ...defineValidator<MailingAddress>({
        addressLine1: Joi.string().pattern(new RegExp(physicalAddressValidator, 'i')).allow(null),
        addressLine2: Joi.string()
            .pattern(new RegExp(physicalAddressValidator, 'i'))
            .allow(null)
            .allow('')
            .optional(),
        city: Joi.string().allow(null),
        state: State.schema.allow(null),
        county: Joi.string().allow('').allow(null).optional(),
        zip: ZipCode.schema.allow(null),
    }),
    create(mailingAddress: MailingAddress) {
        return MailingAddress.validate(mailingAddress);
    },
    isValidHeadquarters(mailingAddress: MailingAddress) {
        const headquarters = this.create(mailingAddress);
        if (isOK(headquarters)) {
            const { addressLine1, city, state, zip } = headquarters.value;
            // if any of the requires attributes are falsy then headquarters is not valid
            return Boolean(addressLine1 && city && state && zip);
        }
        return false;
    },
};
