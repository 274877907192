import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { BrokerRenewalRecordList, BrokerRepository } from '../repositories';
import { ListOptions } from '../types/ListOptions';

export type RenewalTableColumn =
    | 'organization_name'
    | 'managing_broker'
    | 'status'
    | 'expiry_date'
    | 'previous_premium'
    | 'product_type';

export interface RenewalTableFilter {
    target: RenewalTableColumn;
    value: string;
}

export type RenewalTableFilters = RenewalTableFilter[];

export interface GetBrokerRenewalsRequest {
    readonly index: number;
    readonly size: number;
    readonly column?: RenewalTableColumn;
    readonly order?: 'ascending' | 'descending';
    readonly filters?: RenewalTableFilters;
}

export interface GetBrokerRenewals extends UseCase {
    execute(
        request: GetBrokerRenewalsRequest,
    ): AsyncResult<BrokerRenewalRecordList, InvalidArgument | OperationFailed>;
}

class GetBrokerRenewalsUseCase extends UseCase implements GetBrokerRenewals {
    public static type = Symbol('Broker/GetBrokerRenewals');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BrokerRepository) private brokerRepository: BrokerRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: GetBrokerRenewalsRequest,
    ): AsyncResult<BrokerRenewalRecordList, InvalidArgument | OperationFailed> {
        const listOptionsResult = ListOptions.create(request);
        if (isErr(listOptionsResult)) {
            return listOptionsResult;
        }
        return this.brokerRepository.getBrokerRenewalRecordList(listOptionsResult.value);
    }
}

export const GetBrokerRenewals: UseCaseClass<GetBrokerRenewals> = GetBrokerRenewalsUseCase;
