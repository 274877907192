import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject } from '@embroker/shotwell/core/di';
import { ApplicationRepository } from '../repositories/ApplicationRepository';

export interface UpdateApplicationQuestionnaireRequest {
    applicationId: UUID;
    questionnaireData: string;
}

export interface UpdateApplicationQuestionnaire extends UseCase {
    execute(
        request: UpdateApplicationQuestionnaireRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed>;
}

class UpdateApplicationQuestionnaireUseCase
    extends UseCase
    implements UpdateApplicationQuestionnaire
{
    public static type = Symbol('Shopping/UpdateApplicationQuestionnaire');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
    ) {
        super(eventBus);
    }
    public async execute({
        applicationId,
        questionnaireData,
    }: UpdateApplicationQuestionnaireRequest): AsyncResult<
        void,
        InvalidArgument | OperationFailed
    > {
        const getApplicationResponse = await this.applicationRepository.getApplication(
            applicationId,
        );

        if (isErr(getApplicationResponse)) {
            return handleOperationFailure(getApplicationResponse);
        }

        const { value: application } = getApplicationResponse;

        const result = await this.applicationRepository.updateQuestionnaireData({
            isPrefill: false,
            applicationId,
            questionnaireData,
        });
        if (isErr(result)) {
            return result;
        }
        application.updateQuestionnaireData(questionnaireData);
        this.eventBus.publishEntityEvents(application);
        return Success();
    }
}

export const UpdateApplicationQuestionnaire: UseCaseClass<UpdateApplicationQuestionnaire> =
    UpdateApplicationQuestionnaireUseCase;
