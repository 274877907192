import React, { SyntheticEvent } from 'react';
import { Button } from '@embroker/ui-toolkit/v2';

export interface ViewPolicyButtonProps {
    onClick: (event: SyntheticEvent) => void;
}

export const ViewPolicyButton = ({ onClick }: ViewPolicyButtonProps) => {
    const handleOnClick = (event: SyntheticEvent) => {
        event.stopPropagation();

        onClick(event);
    };

    return (
        <Button appearance="secondary" onClick={handleOnClick} data-e2e="view-policy-button">
            View Policy
        </Button>
    );
};
