import { API } from '@embroker/shotwell-api/app';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { injectable } from '@embroker/shotwell/core/di';
import { InvestorsRepository } from './index';

@injectable()
export class APIInvestorsRepository implements InvestorsRepository {
    async getInvestors(
        searchPattern: string,
    ): AsyncResult<string[], InvalidArgument | OperationFailed> {
        const getInvestorsResult = await API.request('shopping/get_investors', {
            search_pattern: searchPattern,
        });
        if (isErr(getInvestorsResult)) {
            return handleOperationFailure(getInvestorsResult);
        }

        return Success(getInvestorsResult.value);
    }
}
