import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Money } from '@embroker/shotwell/core/types/Money';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ESPEndorsementRepository } from '../repositories/ESPEndorsementRepository';
import { EditESPEndorsementLiabilityCoverageType } from '../types/ESPEndorsementLiabilityCoverageType';
import { ESPEndorsementRate } from '../types/ESPEndorsementRate';

export interface RateEditCoverageESPEndorsementRequest {
    policyId: UUID;
    coverageTypeCode: EditESPEndorsementLiabilityCoverageType;
    limit: Money;
    retention: Money;
    effectiveDate: Date;
}

export interface RateEditCoverageESPEndorsement extends UseCase {
    execute(
        request: RateEditCoverageESPEndorsementRequest,
    ): AsyncResult<ESPEndorsementRate, InvalidArgument | OperationFailed>;
}

@injectable()
class RateEditCoverageUseCase extends UseCase implements RateEditCoverageESPEndorsement {
    public static type = Symbol('ESPEndorsement/RateEditCoverageESPEndorsement');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ESPEndorsementRepository)
        private espEndorsementRepository: ESPEndorsementRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        policyId,
        coverageTypeCode,
        limit,
        retention,
        effectiveDate,
    }: RateEditCoverageESPEndorsementRequest): AsyncResult<
        ESPEndorsementRate,
        OperationFailed | InvalidArgument
    > {
        return await this.espEndorsementRepository.rateEditCoverageEndorsement({
            policyId: policyId,
            coverageTypeCode: coverageTypeCode,
            limit: limit,
            retention: retention,
            effectiveDate: effectiveDate,
        });
    }
}

export const RateEditCoverageESPEndorsement: UseCaseClass<RateEditCoverageESPEndorsement> =
    RateEditCoverageUseCase;
