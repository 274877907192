import { Joi, defineValidator } from '@embroker/shotwell/core/validation/schema';
import { AppTypeCode, AppTypeCodeList } from './enums';

export interface SelectedAppTypes {
    selectedAppTypes: AppTypeCode[];
}

export interface Config {
    isMPLEnabled: boolean;
    isCowbellCyberEnabled: boolean;
    isMPLWCChubbEnabled: boolean;
    isBOPChubbEnabled: boolean;
}

export const SelectedAppTypes = {
    ...defineValidator<SelectedAppTypes>({
        selectedAppTypes: Joi.array()
            .required()
            .items(Joi.string().valid(...AppTypeCodeList)),
    }),
    create(selectedAppTypes: AppTypeCode[]) {
        return SelectedAppTypes.validate(selectedAppTypes);
    },
    isMPLEligible(selectedAppTypes: AppTypeCode[], config: Config): boolean {
        const mplBundleEligibleAppTypeList: AppTypeCode[] = [];

        if (config.isCowbellCyberEnabled) {
            mplBundleEligibleAppTypeList.push(
                'AppTypeCodeListCyberCowbell',
                'AppTypeCodeListManualCyber',
            );
        }
        if (config.isMPLEnabled) {
            mplBundleEligibleAppTypeList.push('AppTypeCodeListMPL');
        }
        if (config.isBOPChubbEnabled) {
            mplBundleEligibleAppTypeList.push(
                'AppTypeCodeListBOPChubb',
                'AppTypeCodeListCNABOP',
                'AppTypeCodeListManualGL',
                'AppTypeCodeListManualProperty',
            );
        }
        if (config.isMPLWCChubbEnabled) {
            mplBundleEligibleAppTypeList.push(
                'AppTypeCodeListWCChubb',
                'AppTypeCodeListGAWorkersCompensation',
            );
        }
        return selectedAppTypes.some((appType) => mplBundleEligibleAppTypeList.includes(appType));
    },
};
