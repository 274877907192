import { isError } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { ErrorLike, isOK, Success } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { createForm } from '@embroker/shotwell/view/hooks/useForm';
import { isSameDay } from 'date-fns';
import { EditApplication } from '../../../../brokerDashboard/useCases/EditApplication';
import { GenerateAppFileUrl } from '../../../../documents/useCases/GenerateAppFileUrl';
import { QuotingEngineCrime } from '../../../../shopping/types/enums';
import { PurchasedAppType } from '../../../../summary/types/PurchasedAppType';
import { navigateToErrorPage } from '../../../../view/errors';
import { NavigateFunction } from '../../../../view/hooks/useNavigation';
import { GetSignaturePacketFileUrl } from '../../../brokerage/useCases/GetSignaturePacketFileUrl';
import { ErrorCode as QuoteErrorCode, isDocGenError } from '../../../errors';
import { SignQuote } from '../../../useCases/SignQuote';
import { UnsignQuote } from '../../../useCases/UnsignQuote';
import { validateEffectiveDate } from '../../../view/components/formValidators';
import { CrimeQuote } from '../../entities/CrimeQuote';
import { CrimeLimit, CrimeQuoteOptions, CrimeRetention } from '../../types/CrimeQuoteOptions';
import { GenerateCrimeSpecimenPolicyUrl } from '../../useCases/GenerateCrimeSpecimenPolicyUrl';
import { GenerateQuoteSummaryUrl } from '../../useCases/GenerateQuoteSummaryUrl';
import { PurchaseCrime } from '../../useCases/PurchaseCrime';
import { ReQuoteCrime } from '../../useCases/ReQuoteCrime';

export interface CrimeQuoteOptionsFormProps {
    quote: CrimeQuote;
    abortSignal: AbortSignal;
    isBroker: boolean;
    isAdmin: boolean;
    isRenewal: boolean;
    onUpdateQuote: (newCrimeQuote: CrimeQuote) => void;
    setQuoteSummaryUrl: (url: URI) => void;
    setSpecimenPolicyUrl: (url: URI) => void;
    navigate: NavigateFunction;
    getToday: () => Date;
    onGenerateDocument: () => void;
    onDocGenError: () => void;
}

const MAX_FUTURE_DAYS_ALLOWED = 90;

export interface CrimeQuoteOptionsFormData {
    level: boolean;
    limit: CrimeLimit;
    retention: CrimeRetention;

    effectiveDate: Date;

    agreementToConductSignature: boolean;
    warrantyAndFraudSignature: boolean;

    brokerSignature: boolean;
}

const formDataToQuoteOptions = (formData: CrimeQuoteOptionsFormData): CrimeQuoteOptions => ({
    effectiveDate: formData.effectiveDate,
    limit: formData.limit,
    retention: formData.retention,
    enhanced: formData.level,
});

export function createCrimeQuoteOptionsForm({
    quote,
    abortSignal,
    isBroker,
    isAdmin,
    isRenewal,
    onUpdateQuote,
    setQuoteSummaryUrl,
    setSpecimenPolicyUrl,
    navigate,
    getToday,
    onGenerateDocument,
    onDocGenError,
}: CrimeQuoteOptionsFormProps) {
    return createForm<CrimeQuoteOptionsFormData>({
        fields: {
            limit: {
                type: 'select',
                validator: Joi.number().required(),
            },
            retention: {
                type: 'select',
                validator: Joi.number().required(),
            },
            level: {
                type: 'checkbox',
                validator: Joi.boolean().required(),
            },
            effectiveDate: {
                type: 'date',
                validator: Joi.date()
                    .custom((value, helpers) => {
                        if (isRenewal && !isSameDay(quote.options.effectiveDate, value)) {
                            return helpers.error('date.fixed');
                        }
                        return validateEffectiveDate(
                            value,
                            getToday(),
                            MAX_FUTURE_DAYS_ALLOWED,
                            helpers,
                            isAdmin,
                        );
                    })
                    .required(),
                formatValidationError: (error) => {
                    switch (error.details.validator) {
                        case 'date.min':
                            return 'Effective date cannot be in the past.';
                        case 'date.max':
                            return 'Effective date cannot be more than ninety days in the future.';
                        case 'date.fixed':
                            return 'Effective date can not be changed for renewal application.';
                        default:
                            return error.message;
                    }
                },
            },
            agreementToConductSignature: {
                type: 'checkbox',
                validator: Joi.boolean().custom((value, helpers) => {
                    return (
                        isBroker || value || helpers.error('agreementToConductSignature.invalid')
                    );
                }),
            },
            warrantyAndFraudSignature: {
                type: 'checkbox',
                validator: Joi.boolean().custom((value, helpers) => {
                    return isBroker || value || helpers.error('warrantyAndFraudSignature.invalid');
                }),
            },
            brokerSignature: {
                type: 'hidden',
                validator: Joi.boolean().custom((value, helpers) => {
                    return !isBroker || value || helpers.error('brokerSignature.invalid');
                }),
            },
        },
        actions: {
            editApplication: {
                action: async () => {
                    return await execute(EditApplication, {
                        applicationId: quote.applicationId,
                        isRenewal,
                    });
                },
                fields: ['effectiveDate'],
            },
            update: {
                action: async (formData: CrimeQuoteOptionsFormData) => {
                    const quoteOptions = formDataToQuoteOptions(formData);

                    return await execute(ReQuoteCrime, {
                        applicationId: quote.applicationId,
                        crimeQuoteOptions: quoteOptions,
                        abortSignal: abortSignal,
                    });
                },
                fields: ['effectiveDate', 'limit', 'retention', 'level'],
            },
            sign: {
                action: async (formData: CrimeQuoteOptionsFormData) => {
                    if (
                        (isBroker && formData.brokerSignature) ||
                        (formData.agreementToConductSignature && formData.warrantyAndFraudSignature)
                    ) {
                        return execute(SignQuote, { quote });
                    }

                    if (quote.status === 'signed') {
                        return await execute(UnsignQuote, { quote });
                    }

                    return Success({ quote });
                },
                fields: ['effectiveDate', 'limit', 'retention', 'level'],
            },
            downloadQuote: {
                action: async () => {
                    return await execute(GenerateQuoteSummaryUrl, {
                        applicationId: quote.applicationId,
                        quoteId: quote.id,
                        abortSignal: abortSignal,
                    });
                },
                fields: ['effectiveDate', 'limit', 'retention', 'level'],
            },
            downloadApp: {
                action: async () => {
                    return await execute(GenerateAppFileUrl, {
                        applicationId: quote.applicationId,
                        abortSignal: abortSignal,
                    });
                },
                fields: ['effectiveDate', 'limit', 'retention', 'level'],
            },
            downloadSignaturePacket: {
                action: async () => {
                    return await execute(GetSignaturePacketFileUrl, {
                        applicationId: quote.applicationId,
                        quoteId: quote.id,
                        abortSignal,
                    });
                },
                fields: ['effectiveDate'],
            },
            downloadSpecimenPolicy: {
                action: async () => {
                    return await execute(GenerateCrimeSpecimenPolicyUrl, {
                        applicationId: quote.applicationId,
                        quoteId: quote.id,
                        abortSignal,
                    });
                },
                fields: ['effectiveDate', 'limit', 'retention', 'level'],
            },
            default: async () => {
                return await execute(PurchaseCrime, { quote });
            },
        },
        onSuccess: (value, action) => {
            switch (action) {
                case 'downloadQuote':
                    setQuoteSummaryUrl(value.quoteSummaryUrl);
                    onGenerateDocument();
                    break;
                case 'downloadSignaturePacket':
                    window.open(value.documentUrl as string, '_blank');
                    break;
                case 'downloadApp':
                    window.open(value.documentUrl as string, '_blank');
                    break;
                case 'downloadSpecimenPolicy':
                    setSpecimenPolicyUrl(value.specimenPolicyUrl);
                    onGenerateDocument();
                    break;
                case 'editApplication':
                    navigate(
                        URI.build('/shopping/application/basic-info', {
                            applicationId: quote?.applicationId,
                        }),
                    );
                    break;
                case 'update':
                    onUpdateQuote(value);
                    break;
                case 'sign':
                    onUpdateQuote(value.quote);
                    break;
                case 'default': {
                    if (isBroker) {
                        navigate(
                            URI.build('/broker/dashboard', {
                                applicationId: quote?.applicationId,
                            }),
                        );
                    } else {
                        const purchasedAppType = PurchasedAppType.create({
                            appTypes: ['Commercial Crime'],
                        });

                        let appTypes: string | undefined;

                        if (isOK(purchasedAppType)) {
                            const result = PurchasedAppType.encode(purchasedAppType.value);
                            if (isOK(result)) {
                                appTypes = result.value;
                            }
                        }

                        navigate(
                            URI.build('/summary', {
                                applicationId: quote.applicationId,
                                quotingEngine: QuotingEngineCrime,
                                appTypes,
                                ...value,
                            }),
                        );
                    }
                    break;
                }
                default:
                    break;
            }
        },
        onFailure: (errors: Immutable<ErrorLike[]>) => {
            if (!errors.every(isRecoverableError)) {
                navigateToErrorPage(navigate, errors);
            } else if (errors.some(isDocGenError)) {
                onDocGenError();
            }
            return;
        },
    });
}

function isRecoverableError(error: ErrorLike) {
    return (
        isError(QuoteErrorCode.InvalidAnnualTechFee, error) ||
        isError(QuoteErrorCode.DocGenFailed, error)
    );
}
