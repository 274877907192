import { assertNever } from '@embroker/shotwell/core/assert';

const DocumentTypeEnum = {
    QuoteSummary: 'QuoteSummary',
    SpecimenPolicy: 'SpecimenPolicy',
} as const;
export type DocumentType = (typeof DocumentTypeEnum)[keyof typeof DocumentTypeEnum];

export const DocumentType = Object.assign(DocumentTypeEnum, {
    toDisplayName(documentType: DocumentType): string {
        switch (documentType) {
            case DocumentType.QuoteSummary:
                return 'quote';
            case DocumentType.SpecimenPolicy:
                return 'specimen policy';
            default:
                assertNever(documentType);
        }
    },
});
