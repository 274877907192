import { Nullable } from '@embroker/shotwell/core/types/index';
import { State } from '@embroker/shotwell/core/types/StateList';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface UserInfo {
    readonly company: string;
    readonly fullName: string;
    readonly title: string;
    readonly usaState: Nullable<State>;
}

export const UserInfo = {
    ...defineValidator<UserInfo>({
        company: Joi.string().required(),
        fullName: Joi.string().required(),
        title: Joi.string().required(),
        usaState: State.schema.allow(null),
    }),
    create(userInfo: UserInfo) {
        return UserInfo.validate(userInfo);
    },
};
