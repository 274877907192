import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { createForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    Button,
    CheckBox,
    ColumnLayout,
    Form,
    Immutable,
    Stack,
    StackLayout,
    Text,
    TextAreaInput,
    TextButton,
    WizardLayout,
} from '@embroker/ui-toolkit/v2';
import { isSameDay, isValid, startOfDay, startOfToday } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FileUpload } from '../../../../../documents/view/FileUpload';
import { validateEffectiveDate } from '../../../../../quote/view/components/formValidators';
import { useWizardForm } from '../../../../../view/hooks/useWizardForm';
import { IntakeAdditionalInfo } from '../../../../useCases/IntakeAdditionalInfo';
import { DO, DOAndEPL, EPL } from '../BrokerSelectProductForm';
import {
    BrokerCoverageType,
    mapBrokerCoverageToAppTypeItem,
} from '../createBrokerSelectProductForm';
import { toSelectCurrencyOption } from '../../../../../quote/toSelectCurrencyOption';

const defaultFiduciaryLimitOptions = [1_000_000];

const defaultFiduciaryRetentionOptions = [0];

const defaultDoRetentionOptions = [10_000, 25_000, 50_000, 75_000, 100_000, 150_000];

const defaultEpliRetentionOptions = [10_000, 25_000, 50_000, 75_000, 100_000, 150_000];

const defaultEoCyberRetentionOptions = [2_500, 5_000, 10_000, 25_000, 50_000, 75_000, 100_000];
const defaultEpliLimitOptions = [1_000_000, 2_000_000];

const defaultDoLimitOptions = [1_000_000, 2_000_000, 3_000_000, 4_000_000, 5_000_000];

const defaultEoCyberLimitOptions = [1_000_000, 2_000_000, 3_000_000, 4_000_000, 5_000_000];

const MAX_FUTURE_DAYS_ALLOWED = 90;

export interface AdditionalInfoFormData {
    additionalFiles: Immutable<FileUpload[]>;
    applicationId: UUID;
    dnoCoverage: boolean;
    dnoRetention: number;
    dnoLimit: number;
    eplCoverage: boolean;
    eplRetention: number;
    eplLimit: number;
    fiduciaryCoverage: boolean;
    fiduciaryRetention: number;
    fiduciaryLimit: number;
    enoCoverage: boolean;
    enoRetention: number;
    enoLimit: number;
    effectiveDate: Date;
    additionalInfo: string;
}

interface AdditionalInfoFormParams {
    coverageType: BrokerCoverageType;
}

function createAdditionalInfoForm({ coverageType }: AdditionalInfoFormParams) {
    return createForm<AdditionalInfoFormData>({
        fields: {
            additionalFiles: {
                type: 'hidden',
                validator: Joi.array(),
            },
            applicationId: {
                type: 'hidden',
                validator: UUID.schema,
            },
            dnoCoverage: {
                type: 'checkbox',
                validator: Joi.boolean(),
            },
            dnoRetention: {
                type: 'select',
                validator: Joi.number().when(Joi.ref('$dnoCoverage'), {
                    is: true,
                    then: Joi.required(),
                    otherwise: Joi.optional(),
                }),
                formatValidationError: (error) => {
                    switch (error.details.validator) {
                        case 'any.required':
                            return 'You must select a value.';
                        default:
                            return error.message;
                    }
                },
            },
            dnoLimit: {
                type: 'select',
                validator: Joi.number().when(Joi.ref('$dnoCoverage'), {
                    is: true,
                    then: Joi.required(),
                    otherwise: Joi.optional(),
                }),
                formatValidationError: (error) => {
                    switch (error.details.validator) {
                        case 'any.required':
                            return 'You must select a value.';
                        default:
                            return error.message;
                    }
                },
            },
            eplCoverage: {
                type: 'checkbox',
                validator: Joi.boolean(),
            },
            eplRetention: {
                type: 'select',
                validator: Joi.number().when(Joi.ref('$eplCoverage'), {
                    is: true,
                    then: Joi.required(),
                    otherwise: Joi.optional(),
                }),
                formatValidationError: (error) => {
                    switch (error.details.validator) {
                        case 'any.required':
                            return 'You must select a value.';
                        default:
                            return error.message;
                    }
                },
            },
            eplLimit: {
                type: 'select',
                validator: Joi.number().when(Joi.ref('$eplCoverage'), {
                    is: true,
                    then: Joi.required(),
                    otherwise: Joi.optional(),
                }),
                formatValidationError: (error) => {
                    switch (error.details.validator) {
                        case 'any.required':
                            return 'You must select a value.';
                        default:
                            return error.message;
                    }
                },
            },
            fiduciaryCoverage: {
                type: 'checkbox',
                validator: Joi.boolean(),
            },
            fiduciaryRetention: {
                type: 'hidden',
                validator: Joi.number(),
            },
            fiduciaryLimit: {
                type: 'hidden',
                validator: Joi.number(),
            },
            enoCoverage: {
                type: 'checkbox',
                validator: Joi.boolean(),
            },
            enoRetention: {
                type: 'select',
                validator: Joi.number().when(Joi.ref('$enoCoverage'), {
                    is: true,
                    then: Joi.required(),
                    otherwise: Joi.optional(),
                }),
                formatValidationError: (error) => {
                    switch (error.details.validator) {
                        case 'any.required':
                            return 'You must select a value.';
                        default:
                            return error.message;
                    }
                },
            },
            enoLimit: {
                type: 'select',
                validator: Joi.number().when(Joi.ref('$enoCoverage'), {
                    is: true,
                    then: Joi.required(),
                    otherwise: Joi.optional(),
                }),
                formatValidationError: (error) => {
                    switch (error.details.validator) {
                        case 'any.required':
                            return 'You must select a value.';
                        default:
                            return error.message;
                    }
                },
            },
            effectiveDate: {
                type: 'date',
                validator: Joi.date()
                    .custom((value, helpers) => {
                        return validateEffectiveDate(
                            value,
                            startOfToday(),
                            MAX_FUTURE_DAYS_ALLOWED,
                            helpers,
                            false,
                        );
                    })
                    .required(),
                formatValidationError: (error) => {
                    switch (error.details.validator) {
                        case 'date.min':
                            return 'Date cannot be in the past.';
                        case 'date.max':
                            return 'Effective date cannot be more than ninety days in the future.';
                        case 'any.required':
                            return 'You must enter a date.';
                        default:
                            return error.message;
                    }
                },
            },
            additionalInfo: {
                type: 'textarea',
                validator: Joi.string().optional(),
            },
        },
        formatSubmitErrors(errors) {
            if (errors.length > 0) {
                return ['Oops! Something went wrong. Please try again later.'];
            }

            return [];
        },
        submit: async (data: AdditionalInfoFormData) => {
            const fileKeys: string[] = [];
            data.additionalFiles.forEach((file) => {
                if (file.s3FileKey !== null) {
                    fileKeys.push(file.s3FileKey);
                }
            });
            const appType = mapBrokerCoverageToAppTypeItem.get(coverageType);
            if (appType !== undefined) {
                const result = await execute(IntakeAdditionalInfo, {
                    appType: appType,
                    additionalData: {
                        isDnoSelected: data.dnoCoverage ?? false,
                        dnoLimit: data.dnoCoverage ? data.dnoLimit : 0,
                        dnoRetention: data.dnoCoverage ? data.dnoRetention : 0,
                        isEplSelected: data.eplCoverage ?? false,
                        eplLimit: data.eplCoverage ? data.eplLimit : 0,
                        eplRetention: data.eplCoverage ? data.eplRetention : 0,
                        isFiduciarySelected: data.fiduciaryCoverage ?? false,
                        fiduciaryLimit: data.fiduciaryCoverage
                            ? defaultFiduciaryLimitOptions[0]
                            : 0,
                        fiduciaryRetention: data.fiduciaryCoverage
                            ? defaultFiduciaryRetentionOptions[0]
                            : 0,
                        isEnoSelected: data.enoCoverage ?? false,
                        enoLimit: data.enoCoverage ? data.enoLimit : 0,
                        enoRetention: data.enoCoverage ? data.enoRetention : 0,
                        effectiveDate: data.effectiveDate,
                        additionalInfo: data.additionalInfo,
                    },
                    applicationId: data.applicationId,
                    additionalFiles: fileKeys,
                });

                if (isErr(result)) {
                    container.get<Logger>(Log).error(result.errors);
                }
            }

            return Success();
        },
    });
}

export interface AdditionalInfoProps {
    coverageType: BrokerCoverageType;
    appId: UUID;
    filesUploaded: FileUpload[];
    pcomlCoverage?: string;
    cyberAndTechEO?: boolean;
    firstBackAction: () => void;
    openSuccessModal: () => void;
}

export function MlAdditionalInfo({
    coverageType,
    pcomlCoverage,
    appId,
    filesUploaded,
    cyberAndTechEO,
    firstBackAction,
    openSuccessModal,
    ...props
}: AdditionalInfoProps) {
    const [shouldProceed, setShouldProceed] = useState(false);
    const additionalInfoForm = useMemo(() => {
        return createAdditionalInfoForm({
            coverageType: coverageType,
        });
    }, [coverageType]);

    const { value, fields, activePageIndex, validate, setValue, next, previous, submit, status } =
        useWizardForm(additionalInfoForm, {
            pages: [
                {
                    name: 'quoteInfo',
                    fields: [
                        'dnoCoverage',
                        'dnoLimit',
                        'dnoRetention',
                        'eplCoverage',
                        'eplLimit',
                        'eplRetention',
                        'fiduciaryCoverage',
                        'fiduciaryLimit',
                        'fiduciaryRetention',
                        'enoCoverage',
                        'enoRetention',
                        'enoLimit',
                    ],
                },
                {
                    name: 'dateInfo',
                    fields: ['effectiveDate', 'additionalInfo'],
                },
            ],
            keepUrl: true,
        });

    useEffect(() => {
        if (coverageType === BrokerCoverageType.ESP) {
            setValue({
                ...value,
                applicationId: appId,
                additionalFiles: filesUploaded,
                dnoCoverage: true,
                eplCoverage: true,
                fiduciaryCoverage: true,
                enoCoverage: cyberAndTechEO ?? false,
            });
        } else {
            setValue({
                ...value,
                applicationId: appId,
                additionalFiles: filesUploaded,
                dnoCoverage: pcomlCoverage === DOAndEPL || pcomlCoverage === DO,
                eplCoverage: pcomlCoverage === DOAndEPL || pcomlCoverage === EPL,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appId, coverageType, cyberAndTechEO, pcomlCoverage, filesUploaded]);

    useEffect(() => {
        if (status === 'submitted') {
            openSuccessModal();
        }
    }, [openSuccessModal, status]);

    useEffect(() => {
        if (shouldProceed) {
            next();
            setShouldProceed(false);
        }
    }, [validate, next, shouldProceed]);

    const handleEffectiveDateChange = useCallback(
        (event: { target: { value: string; date: Date } }) => {
            const newDate = startOfDay(event.target.date);
            const isNotDateValid =
                !isValid(newDate) ||
                (value.effectiveDate !== null && isSameDay(value.effectiveDate, newDate));
            if (isNotDateValid) {
                return;
            }

            setValue({
                ...value,
                effectiveDate: newDate,
            });
        },
        [setValue, value],
    );

    return (
        <StackLayout gap="8" {...props}>
            <Text style="heading 4">Additional information required</Text>
            <Stack activeIndex={activePageIndex}>
                <StackLayout gap="16">
                    <StackLayout gap="8">
                        <Text style="default">
                            Please select desired coverages below, as well as limits and retentions
                            for them.
                        </Text>
                        <CheckBox
                            appearance="border"
                            checked={value.dnoCoverage}
                            onChange={(event) => {
                                setValue({ ...value, dnoCoverage: event.target.checked });
                            }}
                            title="D&O"
                        >
                            D&O
                        </CheckBox>
                        {value.dnoCoverage && (
                            <ColumnLayout>
                                <Form.Field
                                    inputProps={{
                                        items: defaultDoLimitOptions,
                                        value: fields.dnoLimit.props.value,
                                        onChange: (event: { target: { value: number } }) => {
                                            setValue({
                                                ...value,
                                                dnoLimit: event.target.value,
                                            });
                                        },
                                        createNewItem: toSelectCurrencyOption,
                                    }}
                                    type={fields.dnoLimit.type}
                                    label="Limit"
                                    messages={fields.dnoLimit.messages}
                                />
                                <Form.Field
                                    inputProps={{
                                        items: defaultDoRetentionOptions,
                                        value: fields.dnoRetention.props.value,
                                        onChange: (event: { target: { value: number } }) => {
                                            setValue({
                                                ...value,
                                                dnoRetention: event.target.value,
                                            });
                                        },
                                        createNewItem: toSelectCurrencyOption,
                                    }}
                                    type={fields.dnoRetention.type}
                                    label="Retention"
                                    messages={fields.dnoRetention.messages}
                                />
                            </ColumnLayout>
                        )}
                    </StackLayout>
                    <StackLayout gap="8">
                        <CheckBox
                            appearance="border"
                            onChange={(event) => {
                                setValue({ ...value, eplCoverage: event.target.checked });
                            }}
                            checked={value.eplCoverage}
                            title="EPL"
                        >
                            EPL
                        </CheckBox>
                        {value.eplCoverage && (
                            <ColumnLayout>
                                <Form.Field
                                    inputProps={{
                                        items: defaultEpliLimitOptions,
                                        value: fields.eplLimit.props.value,
                                        onChange: (event: { target: { value: number } }) => {
                                            setValue({
                                                ...value,
                                                eplLimit: event.target.value,
                                            });
                                        },
                                        createNewItem: toSelectCurrencyOption,
                                    }}
                                    type={fields.eplLimit.type}
                                    label="Limit"
                                    messages={fields.eplLimit.messages}
                                />
                                <Form.Field
                                    inputProps={{
                                        items: defaultEpliRetentionOptions,
                                        value: fields.eplRetention.props.value,
                                        onChange: (event: { target: { value: number } }) => {
                                            setValue({
                                                ...value,
                                                eplRetention: event.target.value,
                                            });
                                        },
                                        createNewItem: toSelectCurrencyOption,
                                    }}
                                    type={fields.eplRetention.type}
                                    label="Retention"
                                    messages={fields.eplRetention.messages}
                                />
                            </ColumnLayout>
                        )}
                    </StackLayout>
                    {coverageType === BrokerCoverageType.ESP && (
                        <StackLayout gap="16">
                            <CheckBox
                                appearance="border"
                                onChange={(event) => {
                                    setValue({
                                        ...value,
                                        fiduciaryCoverage: event.target.checked,
                                    });
                                }}
                                checked={value.fiduciaryCoverage}
                                title="Fiduciary"
                            >
                                Fiduciary
                            </CheckBox>
                            <StackLayout gap="8">
                                <CheckBox
                                    appearance="border"
                                    onChange={(event) => {
                                        setValue({ ...value, enoCoverage: event.target.checked });
                                    }}
                                    checked={value.enoCoverage}
                                    title="Tech E&O"
                                >
                                    Tech E&O
                                </CheckBox>
                                {value.enoCoverage && (
                                    <ColumnLayout>
                                        <Form.Field
                                            inputProps={{
                                                items: defaultEoCyberLimitOptions,
                                                value: fields.enoLimit.props.value,
                                                onChange: (event: {
                                                    target: { value: number };
                                                }) => {
                                                    setValue({
                                                        ...value,
                                                        enoLimit: event.target.value,
                                                    });
                                                },
                                                createNewItem: toSelectCurrencyOption,
                                            }}
                                            type={fields.enoLimit.type}
                                            label="Limit"
                                            messages={fields.enoLimit.messages}
                                        />
                                        <Form.Field
                                            inputProps={{
                                                items: defaultEoCyberRetentionOptions,
                                                value: fields.enoRetention.props.value,
                                                onChange: (event: {
                                                    target: { value: number };
                                                }) => {
                                                    setValue({
                                                        ...value,
                                                        enoRetention: event.target.value,
                                                    });
                                                },
                                                createNewItem: toSelectCurrencyOption,
                                            }}
                                            type={fields.enoRetention.type}
                                            label="Retention"
                                            messages={fields.enoRetention.messages}
                                        />
                                    </ColumnLayout>
                                )}
                            </StackLayout>
                        </StackLayout>
                    )}
                    <WizardLayout.Actions>
                        <Button
                            disabled={
                                !value.dnoCoverage &&
                                !value.eplCoverage &&
                                !value.fiduciaryCoverage &&
                                !value.enoCoverage
                            }
                            onClick={() => {
                                validate();
                                setShouldProceed(true);
                            }}
                            data-e2e="continue-button-additional-info-ml"
                        >
                            {'Continue'}
                        </Button>
                        <TextButton onClick={firstBackAction}>Back</TextButton>
                    </WizardLayout.Actions>
                </StackLayout>
                <StackLayout gap="32">
                    <StackLayout gap="8">
                        <Text style="default">One final bit of information needed:</Text>
                        <Form.Field
                            inputProps={{
                                value: fields.effectiveDate.props.value,
                                onChange: handleEffectiveDateChange,
                            }}
                            type={fields.effectiveDate.type}
                            label="Desired effective Date"
                            messages={fields.effectiveDate.messages}
                        />
                        <TextAreaInput
                            label="Additional information (optional)"
                            {...fields.additionalInfo.props}
                        />
                    </StackLayout>
                    <WizardLayout.Actions>
                        <Button onClick={submit}>{'Finish'}</Button>
                        <TextButton onClick={previous}>Back</TextButton>
                    </WizardLayout.Actions>
                </StackLayout>
            </Stack>
        </StackLayout>
    );
}
