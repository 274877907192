import { inject, injectable } from '@embroker/shotwell/core/di';
import { JSONSerdes } from '@embroker/shotwell/core/encoding';
import { Aborted, InvalidArgument, OperationFailed, Timeout } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { CalculateSKU } from '../../../analytics/useCases/CalculateSKU';
import { ApplicationRepository } from '../../../shopping/repositories/ApplicationRepository';
import { TasksRepository } from '../../../tasks/repositories';
import { QuestionnaireParsingFailed } from '../../errors';
import { ESPQuote } from '../entities/ESPQuote';
import { ConfigFetchFailed } from '../errors';
import { ESPQuoteRepository } from '../repositories/ESPQuoteRepository';
import { ESPQuoteOptions } from '../types/ESPQuoteOptions';

export interface RequoteESPRequest {
    applicationId: UUID;
    espQuoteOptions: ESPQuoteOptions;
    abortSignal: AbortSignal;
    maxPollingRetries?: number;
    pollingRetryIntervalInMilliseconds?: number;
}

export interface RequoteESP extends UseCase {
    execute(
        request: RequoteESPRequest,
    ): AsyncResult<
        ESPQuote,
        | InvalidArgument
        | OperationFailed
        | ConfigFetchFailed
        | Timeout
        | Aborted
        | QuestionnaireParsingFailed
    >;
}

@injectable()
class RequoteESPUseCase extends UseCase implements RequoteESP {
    public static type = Symbol('ESPQuote/RateESP');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ESPQuoteRepository) private espQuoteRepository: ESPQuoteRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
        @inject(CalculateSKU.type) private calculateSKU: CalculateSKU,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
        espQuoteOptions,
        abortSignal,
        maxPollingRetries = 120,
        pollingRetryIntervalInMilliseconds = 1000,
    }: RequoteESPRequest): AsyncResult<
        ESPQuote,
        | InvalidArgument
        | OperationFailed
        | ConfigFetchFailed
        | Timeout
        | Aborted
        | QuestionnaireParsingFailed
    > {
        const espQuoteOptionsResult = ESPQuoteOptions.create(espQuoteOptions);
        if (isErr(espQuoteOptionsResult)) {
            return espQuoteOptionsResult;
        }

        const quoteResult = await this.espQuoteRepository.requote(
            applicationId,
            espQuoteOptionsResult.value,
        );
        if (isErr(quoteResult)) {
            return quoteResult;
        }

        const pollForTaskStatusResult = await this.tasksRepository.pollForTaskStatus(
            quoteResult.value,
            abortSignal,
            maxPollingRetries,
            pollingRetryIntervalInMilliseconds,
        );
        if (isErr(pollForTaskStatusResult)) {
            return pollForTaskStatusResult;
        }

        const getCurrentRateResult = await this.espQuoteRepository.getLastQuote(applicationId);
        if (isErr(getCurrentRateResult)) {
            return getCurrentRateResult;
        }

        const getApplicationResult = await this.applicationRepository.getApplication(applicationId);
        if (isErr(getApplicationResult)) {
            return getApplicationResult;
        }

        const quote = getCurrentRateResult.value;
        const application = getApplicationResult.value;
        const questionnaireDataResult = JSONSerdes.deserialize(
            application.questionnaireData ?? '{}',
        );
        if (isErr(questionnaireDataResult)) {
            return Failure(QuestionnaireParsingFailed(application.questionnaireData || '{}'));
        }

        const skuResult = await this.calculateSKU.execute({
            event: 'quote',
            applicationId,
        });
        if (isErr(skuResult)) {
            return skuResult;
        }

        application.onQuotePremium({
            totalPremium: quote.totalPayable,
            applicationId,
            isRenewal: application.isRenewal(),
            sku: skuResult.value,
        });
        await this.eventBus.publishEntityEvents(application);

        return Success<ESPQuote>(getCurrentRateResult.value);
    }
}

export const RequoteESP: UseCaseClass<RequoteESP> = RequoteESPUseCase;
