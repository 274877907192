import React from 'react';
import { ClaimsLandingPage } from './ClaimsLandingPage';
import { ClaimRequest } from './ClaimRequest';
import { useCurrentRoute } from 'react-navi';
import { UUID } from '@embroker/shotwell/core/types/UUID';

export const ClaimRequestTab = () => {
    const route = useCurrentRoute();

    return (
        <ClaimsLandingPage>
            <ClaimRequest policyId={route.url.query.policyId as UUID} />
        </ClaimsLandingPage>
    );
};
