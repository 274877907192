import React from 'react';
import { Image } from '@embroker/ui-toolkit/v2';
import { Link } from './Link/Link';

export function NavigationalLogo() {
    return (
        <Link href="/">
            <Image name="logotype" height={20} />
        </Link>
    );
}
