import { inject, injectable } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { Invoice } from '../entities/Invoice';
import { InvoiceRepository } from '../repositories/InvoiceRepository';
import { PaymentError } from '../types/errors';

export interface GetPublicInvoiceRequest {
    publicKey: string;
}

export interface PublicInvoiceResponse {
    Invoice: Immutable<EntityProps<Invoice>>;
}

export interface GetPublicInvoice extends UseCase {
    execute(
        request: GetPublicInvoiceRequest,
    ): AsyncResult<PublicInvoiceResponse, InvalidArgument | OperationFailed | PaymentError>;
}

@injectable()
class GetPublicInvoiceUseCase extends UseCase implements GetPublicInvoice {
    public static type = Symbol('Payments/GetPublicInvoice');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(InvoiceRepository) private invoiceRepo: InvoiceRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: GetPublicInvoiceRequest,
    ): AsyncResult<PublicInvoiceResponse, InvalidArgument | OperationFailed | PaymentError> {
        const publicInvoiceResult = await this.invoiceRepo.getPublicInvoice(request.publicKey);

        if (isErr(publicInvoiceResult)) {
            return publicInvoiceResult;
        }

        this.eventBus.publishEntityEvents(publicInvoiceResult.value);

        return Success({
            Invoice: publicInvoiceResult.value,
        });
    }
}

export const GetPublicInvoice: UseCaseClass<GetPublicInvoice> = GetPublicInvoiceUseCase;
