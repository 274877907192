import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { ApplicationRepository } from '../repositories/ApplicationRepository';

export interface DeleteApplicationRequest {
    id: UUID;
}

export interface DeleteApplication extends UseCase {
    execute(
        request: DeleteApplicationRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed | UnknownEntity>;
}

@injectable()
export class DeleteApplicationUseCase extends UseCase implements DeleteApplication {
    public static type = Symbol('Shopping/DeleteApplication');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        application: DeleteApplicationRequest,
    ): AsyncResult<void, UnknownEntity | InvalidArgument | OperationFailed> {
        const applicationResult = await this.applicationRepository.getApplication(application.id);

        if (isErr(applicationResult)) {
            return applicationResult;
        }

        applicationResult.value.onDeleted();

        const applicationDeleted = await this.applicationRepository.deleteApplication(
            application.id,
        );

        if (isErr(applicationDeleted)) {
            return applicationDeleted;
        }

        this.eventBus.publishEntityEvents(applicationResult.value);

        return Success();
    }
}

export const DeleteApplication: UseCaseClass<DeleteApplication> = DeleteApplicationUseCase;
