import { PageElement, PageStateMachine } from '@embroker/service-app-engine';
import { Nullable } from '@embroker/shotwell/core/types';
import {
    ProgressSidebar,
    ProgressSidebarItemStatus,
    WizardNavigation,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { FormEngineSubscriber, FormEngineSubscriberProps } from './FormEngineSubscriber';

export interface NavigationProps extends FormEngineSubscriberProps {
    goToPage(id: string | null | undefined): void;
}

function getNavigationItemStatus(
    isActive: boolean,
    isValid: boolean,
    isCompleted: boolean,
): ProgressSidebarItemStatus {
    if (isActive) {
        return 'active';
    }
    if (isCompleted) {
        return 'success';
    }
    if (!isValid) {
        return 'error';
    }
    return 'default';
}

interface getPageStateDetailsResponse {
    readonly id: string;
    readonly title: string;
    readonly pages?: PageElement[];
    readonly pageList?: PageElement[];
    readonly visible: boolean;
    readonly active: boolean;
    readonly isValid: () => boolean;
    readonly completed: boolean;
}

function getPageStateDetails(page: PageElement): getPageStateDetailsResponse {
    const {
        id,
        title,
        pages,
        visible,
        descendantVisible,
        enabled,
        parentEnabled,
        hiddenFromNavigation,
        pageList,
        completed,
    } = page.machine.state;
    return {
        id,
        title,
        pages,
        pageList,
        visible: enabled && parentEnabled && hiddenFromNavigation !== true,
        active: visible || descendantVisible || isAnyDescendantVisible(pages),
        isValid: () => page.machine.isValid(),
        completed,
    };
}

// for some reason `descendantVisible` field is flaky when eligibility rules are triggered for MPL.
// this function was needed in order to workaround that issue.
function isAnyDescendantVisible(pages: PageElement<PageStateMachine>[] | undefined): boolean {
    if (!pages) {
        return false;
    }

    for (const page of pages) {
        const descendants = page.machine.state.pages;
        if (!Array.isArray(descendants) || descendants.length == 0) {
            if (page.machine.state?.visible) {
                return true;
            }
        } else {
            if (isAnyDescendantVisible(descendants as PageElement<PageStateMachine>[])) {
                return true;
            }
        }
    }

    return false;
}

function getVisiblePages(pages?: PageElement[]): PageElement[] {
    const result =
        pages?.filter((page) => {
            const { visible } = getPageStateDetails(page);
            return visible;
        }) ?? [];
    return result;
}

function mapPageElementToPageLeaf(pageElement: PageElement): PageTreeLeaf {
    const { title, id, active, isValid, completed } = getPageStateDetails(pageElement);
    const isPageValid = isValid();
    const result: PageTreeLeaf = {
        type: 'leaf',
        id,
        title,
        status: getNavigationItemStatus(active, isPageValid, completed),
        completed,
        pageElement,
    };
    return result;
}

function mapPageElementToPageNode(pageElement: PageElement): PageTreeNode {
    const { title, pages: childPages, id } = getPageStateDetails(pageElement);
    const items: PageTreeItem[] = [];
    const visibleChildPages = getVisiblePages(childPages);
    for (const childPage of visibleChildPages) {
        let item: PageTreeItem;

        if (hasVisibleChildPages(childPage)) {
            // if childPage has its own children, we will recursively create a pageNode for it
            item = mapPageElementToPageNode(childPage);
        } else {
            // otherwise add it as a pageLeaf
            item = mapPageElementToPageLeaf(childPage);
        }
        items.push(item);
    }
    const result: PageTreeNode = {
        type: 'node',
        items: items,
        id,
        title,
        completed: items.every((item) => item.completed),
        pageElement,
    };
    return result;
}

function hasVisibleChildPages(pageElement: PageElement): boolean {
    const { pages: childPages } = getPageStateDetails(pageElement);
    return getVisiblePages(childPages).length > 0;
}

export interface PageTreeLeaf {
    readonly type: 'leaf';
    readonly title: string;
    readonly id: string;
    readonly status: ProgressSidebarItemStatus;
    readonly completed: boolean;
    readonly pageElement: PageElement<PageStateMachine>;
}

export interface PageTreeNode {
    readonly type: 'node';
    readonly title: string;
    readonly id: string;
    readonly items: PageTreeItem[];
    readonly completed: boolean;
    readonly pageElement: PageElement<PageStateMachine>;
}

export type PageTreeItem = PageTreeLeaf | PageTreeNode;
export type PageTree = PageTreeItem[];

export function constructPageTree(pages: PageElement[] = []): PageTree {
    const tree: PageTree = [];

    for (const page of pages) {
        const { visible, pages: childPages } = getPageStateDetails(page);
        const hasChildren = (childPages?.length ?? 0) > 0;
        if (visible) {
            if (hasChildren) {
                const item = mapPageElementToPageNode(page);
                tree.push(item);
            } else {
                const item = mapPageElementToPageLeaf(page);
                tree.push(item);
            }
        } else {
            //check if any of childPages is visible (use case for `about_your_company`)
            const visibleChildPages = getVisiblePages(childPages);
            for (const page of visibleChildPages) {
                const item = mapPageElementToPageLeaf(page);
                tree.push(item);
            }
        }
    }
    return tree;
}

interface createWizardNavigationProps {
    readonly pageTree: PageTree;
    readonly goToPage: (pageId: string) => void;
}

function createWizardNavigation({ pageTree, goToPage }: createWizardNavigationProps): JSX.Element {
    return (
        <WizardNavigation>
            {pageTree.map((item) => {
                return item.type === 'leaf' ? (
                    <WizardNavigation.Group
                        id={item.id}
                        key={item.id}
                        status={item.status}
                        title={
                            <a href="#" onClick={() => goToPage(item.id)}>
                                {item.title}
                            </a>
                        }
                    />
                ) : (
                    <WizardNavigation.Group
                        id={item.id}
                        key={item.id}
                        title={item.title}
                        status={
                            item.items.some((leaf) => getPageTreeItemStatus(leaf) === 'active')
                                ? 'active'
                                : 'default'
                        }
                    >
                        {item.items.map((leaf) => (
                            <WizardNavigation.Item
                                status={getPageTreeItemStatus(leaf)}
                                key={leaf.id}
                            >
                                <a href="#" onClick={() => goToPage(leaf.id)}>
                                    {leaf.title}
                                </a>
                            </WizardNavigation.Item>
                        ))}
                    </WizardNavigation.Group>
                );
            })}
        </WizardNavigation>
    );
}

const checkStatus = (pageItem: PageTreeItem, status: 'success' | 'active'): boolean => {
    if (pageItem.type === 'leaf') {
        return pageItem.status === status;
    }
    const check = (childItem: PageTreeItem) => {
        if (childItem.type == 'leaf') {
            return childItem.status === status;
        }
        return checkStatus(childItem, status);
    };
    if (status === 'active') {
        return pageItem.items.some(check);
    }
    return pageItem.items.every(check);
};

export function getPageTreeItemStatus(item: PageTreeItem): ProgressSidebarItemStatus {
    if (item.type === 'leaf') {
        return item.status;
    }

    if (checkStatus(item, 'active')) {
        return 'active';
    }

    if (checkStatus(item, 'success')) {
        return 'success';
    }

    return 'default';
}

function parsePageTreeForProgressNavigation(pageTree: PageTree): PageTreeLeaf[] {
    const pages: PageTreeLeaf[] = [];
    for (const page of pageTree) {
        if (page.type === 'leaf') {
            pages.push(page);
        } else {
            const pageItemsToDisplay = page.items
                .map((item: PageTreeItem): PageTreeLeaf => {
                    return {
                        id: item.id,
                        title: item.title,
                        type: 'leaf',
                        status: getPageTreeItemStatus(item),
                        completed: item.completed,
                        pageElement: item.pageElement,
                    };
                })
                .filter(({ pageElement }) => {
                    const hasEnabledPages = pageElement
                        .getPages()
                        .some((pageElement) => pageElement.isEnabled());
                    const hasEnabledFields = pageElement
                        .getFields()
                        .some((fieldElement) => !fieldElement.isDisabled());
                    return hasEnabledPages || hasEnabledFields;
                });
            pages.push(...pageItemsToDisplay);
        }
    }
    return pages;
}

const filterDisabledPages = (pages: PageElement<PageStateMachine>[]) =>
    pages.filter((pageEl) => pageEl.isEnabled());

const calculateTargetPage = (
    targetPage: PageElement<PageStateMachine>,
): PageElement<PageStateMachine> => {
    const childPages = filterDisabledPages(targetPage.machine.state.pages);

    // if targetPage has child pages:
    // it means it's a section, so we should choose
    // the first incomplete page (if any) in childPages
    // otherwise, choose the first page (in childPages)
    if (childPages.length > 0) {
        const firstIncompletePage = childPages.find((page) => !page.machine.state.completed);
        // if first incomplete page was found, return it
        if (firstIncompletePage) {
            return firstIncompletePage;
        }

        // otherwise, choose the first page in childPages
        return filterDisabledPages(childPages)[0];
    }

    return targetPage;
};

const isPreviousNavItemCompleted = (item: PageTreeLeaf, navItems: PageTreeLeaf[]): boolean => {
    const prevItemIndex = navItems.indexOf(item) - 1;
    if (prevItemIndex < 0) {
        return false;
    }
    const prevItem = navItems[prevItemIndex];
    return prevItem.completed;
};

// returns page id (string) if can navigate to the page, otherwise undefined
const getTargetPageId = (item: PageTreeLeaf, navItems: PageTreeLeaf[]): string | undefined => {
    const targetPageElement = item.pageElement;
    if (!targetPageElement) {
        return;
    }
    const targetPage = calculateTargetPage(targetPageElement);
    const isCurrentPage = targetPage.isVisible();
    const isPreviousPageCompleted = isPreviousNavItemCompleted(item, navItems);
    if (!isCurrentPage && (targetPage.machine.state.completed || isPreviousPageCompleted)) {
        return targetPage.id;
    }
};

interface createProgressNavigationProps {
    readonly pageTree: PageTree;
    readonly formEnginePages?: PageElement<PageStateMachine>[];
    readonly goToPage: (pageId: string) => void;
}

function createProgressNavigation({
    pageTree,
    formEnginePages,
    goToPage,
}: createProgressNavigationProps): JSX.Element {
    const pages = parsePageTreeForProgressNavigation(pageTree);

    const handleGoToPage = (item: PageTreeLeaf) => {
        const targetPageId = getTargetPageId(item, pages);
        if (targetPageId) {
            goToPage(targetPageId);
        }
    };

    const isDisabled = (item: PageTreeLeaf) => {
        const targetPageId = getTargetPageId(item, pages);
        return !targetPageId;
    };

    return (
        <ProgressSidebar>
            {pages.map((item) => {
                return (
                    <ProgressSidebar.Item
                        key={item.id}
                        status={item.status}
                        onClick={() => {
                            handleGoToPage(item);
                        }}
                        disabled={isDisabled(item)}
                    >
                        {item.title}
                    </ProgressSidebar.Item>
                );
            })}
        </ProgressSidebar>
    );
}

export class Navigation extends FormEngineSubscriber {
    private goToPage: (id?: Nullable<string>) => void;

    constructor(props: NavigationProps) {
        super(props);
        this.goToPage = props.goToPage;
    }

    render() {
        const maxNumberOfPageNodesForProgressNavigation = 1;

        const formEnginePages = this.props.pages;

        const pageTree = constructPageTree(formEnginePages);
        const goToPage = this.goToPage;

        const numberOfPageNodes = pageTree.filter((item) => item.type === 'node').length;
        if (numberOfPageNodes > maxNumberOfPageNodesForProgressNavigation) {
            return createWizardNavigation({ pageTree, goToPage });
        }
        return createProgressNavigation({ pageTree, formEnginePages, goToPage });
    }
}
