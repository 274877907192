import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { BrokerPolicyList, BrokerRepository } from '../repositories';
import { ListOptions } from '../types/ListOptions';

export type PolicyListSortableColumn =
    | 'expiration_date'
    | 'organization_name'
    | 'policy_number'
    | 'premium'
    | 'managing_broker';

export interface PolicyTableFilter {
    target: PolicyListSortableColumn;
    value: string;
}

export type PolicyTableFilters = PolicyTableFilter[];
export interface GetBrokerPoliciesParams {
    readonly index: number;
    readonly size: number;
    readonly column?: PolicyListSortableColumn;
    readonly order?: 'ascending' | 'descending';
    readonly filters?: PolicyTableFilters;
}

export interface GetBrokerPolicies extends UseCase {
    execute(
        request?: GetBrokerPoliciesParams,
    ): AsyncResult<BrokerPolicyList, InvalidArgument | OperationFailed>;
}

@injectable()
class GetBrokerPoliciesUseCase extends UseCase implements GetBrokerPolicies {
    public static type = Symbol('Broker/GetBrokerPolicies');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BrokerRepository) private brokerRepository: BrokerRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: GetBrokerPoliciesParams,
    ): AsyncResult<BrokerPolicyList, InvalidArgument | OperationFailed> {
        const listOptionsResult = ListOptions.create(request);
        if (isErr(listOptionsResult)) {
            return listOptionsResult;
        }

        const result = await this.brokerRepository.getBrokerPolicyList(listOptionsResult.value);
        if (isErr(result)) {
            return result;
        }

        return Success(result.value);
    }
}

export const GetBrokerPolicies: UseCaseClass<GetBrokerPolicies> = GetBrokerPoliciesUseCase;
