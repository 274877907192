import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success, Failure } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { UserLoginByAdmin } from '../entities/User';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { SessionRepository } from '../repositories/SessionRepository';
import { OperationFailed } from '@embroker/shotwell/core/Error';
import { hasRole } from '../entities/Session';

export interface LoginByAdmin extends UseCase {
    execute(request: void): AsyncResult<void, OperationFailed>;
}

@injectable()
class LoginByAdminUseCase extends UseCase implements LoginByAdmin {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/LoginByAdmin');
    /**
     * Constructor for LoginByAdmin use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to
     * @param sessionRepository Session repository used to authenticate the user
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(SessionRepository) private sessionRepository: SessionRepository,
    ) {
        super(eventBus);
    }

    public async execute(request: void): AsyncResult<void, OperationFailed> {
        const sessionResult = await this.sessionRepository.getActiveSession();
        if (sessionResult.value == null || !hasRole(sessionResult.value, 'admin')) {
            return Failure(OperationFailed({ message: 'active user is not an admin.' }));
        }

        const event: UserLoginByAdmin = {
            origin: 'User',
            name: 'LoginByAdmin',
            id: UUID.create(),
            createdAt: new Date(Date.now()),
        };
        await this.eventBus.publish(event);

        return Success();
    }
}

export const LoginByAdmin: UseCaseClass<LoginByAdmin> = LoginByAdminUseCase;
