import { ContainerModule } from '@embroker/shotwell/core/di';
import { Module } from '../../Module';
import { ESPQuoteRepository } from './repositories/ESPQuoteRepository';
import { APIESPQuoteRepository } from './repositories/ESPQuoteRepository/APIESPQuoteRepository';
import { InsuranceApplicationRestrictionRepository } from './repositories/InsuranceApplicationRestrictionRepository';
import { APIInsuranceApplicationRestrictionRepository } from './repositories/InsuranceApplicationRestrictionRepository/APIInsuranceApplicationRestrictionRepository';
import { DownloadESPQuoteDocument } from './useCases/DownloadESPQuoteDocument';
import { ESPRequestHigherLimits } from './useCases/ESPRequestHigherLimits';
import { GetESPPurchasedCoveragesList } from './useCases/GetESPPurchasedCoveragesList';
import { GetESPQuestionnaireData } from './useCases/GetESPQuestionnaireData';
import { GetHigherLimitRequestList } from './useCases/GetHigherLimitRequests';
import { GetInitialESPRate } from './useCases/GetInitialESPRate';
import { GetRestriction } from './useCases/GetRestriction';
import { PurchaseESP } from './useCases/PurchaseESP';
import { RequoteESP } from './useCases/RequoteESP';

export const ESPQuoteModule: Module = {
    container: new ContainerModule((bind) => {
        bind<ESPQuoteRepository>(ESPQuoteRepository).to(APIESPQuoteRepository).inSingletonScope();
        bind<RequoteESP>(RequoteESP.type).to(RequoteESP).inSingletonScope();
        bind<GetInitialESPRate>(GetInitialESPRate.type).to(GetInitialESPRate).inSingletonScope();
        bind<PurchaseESP>(PurchaseESP.type).to(PurchaseESP).inSingletonScope();
        bind<DownloadESPQuoteDocument>(DownloadESPQuoteDocument.type)
            .to(DownloadESPQuoteDocument)
            .inSingletonScope();
        bind<GetESPPurchasedCoveragesList>(GetESPPurchasedCoveragesList.type)
            .to(GetESPPurchasedCoveragesList)
            .inSingletonScope();
        bind<GetESPQuestionnaireData>(GetESPQuestionnaireData.type)
            .to(GetESPQuestionnaireData)
            .inSingletonScope();
        bind<GetRestriction>(GetRestriction.type).to(GetRestriction).inSingletonScope();
        bind<ESPRequestHigherLimits>(ESPRequestHigherLimits.type)
            .to(ESPRequestHigherLimits)
            .inSingletonScope();
        bind<InsuranceApplicationRestrictionRepository>(
            APIInsuranceApplicationRestrictionRepository,
        )
            .to(APIInsuranceApplicationRestrictionRepository)
            .inSingletonScope();
        bind<GetHigherLimitRequestList>(GetHigherLimitRequestList.type)
            .to(GetHigherLimitRequestList)
            .inSingletonScope();
    }),
};
