import { Immutable } from '@embroker/shotwell/core/types';
import { Revenue as RevenueType } from '@embroker/shotwell/core/types/Revenue';
import { CurrencyInput } from '@embroker/shotwell/view/components/CurrencyInput';
import { FieldMap } from '@embroker/shotwell/view/hooks/useForm';
import { Form } from '@embroker/ui-toolkit/v2';
import React from 'react';

interface RevenueData {
    isEstimated: boolean;
    fields: Immutable<FieldMap<RevenueType>>;
}

export function Revenue({ isEstimated, fields }: RevenueData) {
    const estimatedText = isEstimated ? ' (projected)' : '';
    const revenueFieldLabel =
        (fields.fiscalYear.props.value ?? '') + ' Annual Revenue' + estimatedText;

    return (
        <Form.Field messages={fields.grossTotal.messages}>
            <CurrencyInput
                data-e2e="user-org-revenue-field"
                label={revenueFieldLabel}
                onChange={({ target: { value } }) =>
                    fields.grossTotal.props.onChange({ target: { value: value ?? null } })
                }
                value={fields.grossTotal.props.value}
                hasErrors={fields.grossTotal.props.hasErrors}
                allowDecimal={false}
            />
        </Form.Field>
    );
}
