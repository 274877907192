import { Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import {
    defineValidator,
    Joi,
    Schema,
    TypeChecker,
    Validator,
} from '@embroker/shotwell/core/validation/schema';

/**
 * Represents liability coverage object
 * Each coverage can contain up to two limits, deductibles or retentions
 */
export interface LiabilityCoverage {
    /**
     * Type of coverage
     */
    typeCode: Nullable<string>;
    /**
     * List of codes describing types of auto insurance
     * NOTE: Specific for AUTO policy
     */
    coveredVehiclesCodeList: Nullable<string>;
    /**
     * Portion of the expenses paid by the insured in case of the claim event
     */
    deductible1Amount: Nullable<Money>;
    /**
     * Code describing when deductible is valid (per event, per claim...)
     */
    deductible1Code: Nullable<string>;
    /**
     * Portion of the expenses paid by the insured in case of the claim event
     */
    deductible2Amount: Nullable<Money>;
    /**
     * Code describing when deductible is valid (per event, per claim...)
     */
    deductible2Code: Nullable<string>;
    /**
     * The maximum amount of money an insurance company will pay for a covered claim
     */
    limit1Amount: Nullable<Money>;
    /**
     * Code describing domain of limit (per event, per claim...)
     */
    limit1Code: Nullable<string>;
    /**
     * Short sentence describing the limit
     * NOTE: Usually used by BST only for property coverage
     */
    limit1Description: Nullable<string>;
    /**
     * limit1SubType
     */
    limit1SubType: Nullable<string>;
    /**
     * limit1SubTypeOtherText
     */
    limit1SubTypeOtherText: Nullable<string>;
    /**
     * The maximum amount of money an insurance company will pay for a covered claim
     */
    limit2Amount: Nullable<Money>;
    /**
     * Code describing domain of limit (per event, per claim...)
     */
    limit2Code: Nullable<string>;
    /**
     * Short sentence describing the second limit
     * NOTE: Usually used by BST only for property coverage
     */
    limit2Description: Nullable<string>;
    /**
     * Coverage premium amount. Dollar price for coverage
     * NOTE: Not used by BST
     */
    premiumAmount: Nullable<Money>;
    /**
     * Unit by which risk exposure is multiplied in order to calculate the premium
     */
    premiumRate: Nullable<number>;
    /**
     * Amount specified in a insurance policy that must be paid by the insured before
     * the insurance policy will respond to a loss
     */
    sir1Amount: Nullable<Money>;
    /**
     * Domain of sir (per event, per claim...)
     */
    sir1Code: Nullable<string>;
    /**
     * Amount specified in a insurance policy that must be paid by the insured before
     * the insurance policy will respond to a loss
     */
    sir2Amount: Nullable<Money>;
    /**
     * Domain of sir (per event, per claim...)
     */
    sir2Code: Nullable<string>;
    /**
     * Percentage of participation in a insurance policy that must be paid by the insured before
     * the insurance policy will respond to a loss
     */
    sirPercent: Nullable<number>;
}

export interface LiabilityCoverageValidator {
    /**
     * A Joi schema matching a valid LiabilityCoverage object.
     */
    readonly schema: Schema.ObjectSchema<LiabilityCoverage>;
    /**
     * Type predicate that checks if a given value can be used as LiabilityCoverage object.
     *
     * Use this only to do early returns. It's recommended to use validate()
     * before using an unknown value as LiabilityCoverage object as it normalizes the value
     * in addition to performing the same validation as check().
     */
    readonly check: TypeChecker<LiabilityCoverage>;
    /**
     * Validates and normalizes the given value to a LiabilityCoverage object.
     *
     * This should be used for all untrusted inputs to verify and, if required
     * (and possible), normalize the input.
     */
    readonly validate: Validator<LiabilityCoverage>;
}

export const LiabilityCoverage = {
    ...defineValidator<LiabilityCoverage>({
        coveredVehiclesCodeList: Joi.string().allow(null, ''),
        deductible1Amount: Money.schema.allow(null),
        deductible1Code: Joi.string().allow(null, ''),
        deductible2Amount: Money.schema.allow(null),
        deductible2Code: Joi.string().allow(null, ''),
        limit1Amount: Money.schema.allow(null),
        limit1Code: Joi.string().allow(null, ''),
        limit1Description: Joi.string().allow(null, ''),
        limit1SubType: Joi.string().allow(null, ''),
        limit1SubTypeOtherText: Joi.string().allow(null, ''),
        limit2Amount: Money.schema.allow(null),
        limit2Code: Joi.string().allow(null, ''),
        limit2Description: Joi.string().allow(null, ''),
        premiumAmount: Money.schema.allow(null),
        premiumRate: Joi.number().allow(null),
        sir1Amount: Money.schema.allow(null),
        sir1Code: Joi.string().allow(null, ''),
        sir2Amount: Money.schema.allow(null),
        sir2Code: Joi.string().allow(null, ''),
        sirPercent: Joi.number().allow(null),
        typeCode: Joi.string().allow(null, ''),
    }),
    create(liabilityCoverage: LiabilityCoverage) {
        return LiabilityCoverage.validate(liabilityCoverage);
    },
};
