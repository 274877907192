import { API } from '@embroker/shotwell-api/app';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { QuoteCommonRepository } from './index';

@injectable()
export class APIQuoteCommonRepository implements QuoteCommonRepository {
    public async cancelApplication(
        applicationId: UUID,
    ): AsyncResult<void, InvalidArgument | OperationFailed> {
        const result = await API.request('shopping/set_application_status', {
            id: applicationId,
            app_status: 'InsuranceApplicationStatusCodeListCanceledByAdmin',
        });

        if (isErr(result)) {
            return Failure(
                OperationFailed({
                    message: 'Set application status request failed',
                    errors: result.errors,
                }),
            );
        }

        return Success();
    }
}
