import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { Data, Immutable, Nullable, Props } from '@embroker/shotwell/core/types';
import { Result } from '@embroker/shotwell/core/types/Result';
import { Organization } from '../../../userOrg/entities/Organization';
import { User } from '../../../userOrg/entities/User';
import { SKU } from '../../types/SKU';

/**
 * Interface for the Google Analytics repository
 */
export interface GoogleAnalyticsRepository {
    /**
     * Send analytics login event info about user and organization accessing platform
     * @param user User entity object
     * @param organization Organization entity object. Organization is not mandatory
     * @param loggedViaAdmin If admin is logged in as user flag
     * @param isFirstLogin if this is the first login of the user (at account creation)
     * @returns Success if create or update are successful
     * @returns InvalidArgument if a property of an arguments are invalid
     * @returns OperationFailed error if operation was unable to finish
     */
    sendLoginEvent(
        user: Immutable<Props<User>>,
        organization: Nullable<Immutable<Props<Organization>>>,
        loggedViaAdmin?: boolean,
        isFirstLogin?: boolean,
    ): Result<void, InvalidArgument | OperationFailed>;

    /**
     * Send analytics registration event info about user and organization registration on platform
     * @param user User entity object
     * @param organization Organization entity object. Organization is not mandatory
     * @param sku Data for identifying our products on the platform
     * @returns Success if create or update are successful
     * @returns InvalidArgument if a property of an arguments are invalid
     * @returns OperationFailed error if operation was unable to finish
     */
    sendRegistrationEvent(
        user: Immutable<Props<User>>,
        organization: Nullable<Immutable<Props<Organization>>>,
        sku?: SKU,
    ): Result<void, InvalidArgument | OperationFailed>;

    /**
     * Updates user and company data for all the others analytics events
     * @param user User entity object
     * @param organization Organization entity object. Organization is not mandatory
     */
    updateUserTraits(
        user: Immutable<Props<User>>,
        organization: Nullable<Immutable<Props<Organization>>>,
    ): Result<void, InvalidArgument | OperationFailed>;

    /**
     * Send custom analytics event
     * @param eventName Analytics event name
     * @param payload Data attached to analytics event
     */
    sendCustomEvent(
        eventName: string,
        payload?: Data,
    ): Result<void, InvalidArgument | OperationFailed>;
}

export const GoogleAnalyticsRepository = Symbol('GoogleAnalyticsRepository');
