import { inject, injectable } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { defineValidator } from '@embroker/shotwell/core/validation/schema';
import { Policy } from '../entities/Policy';
import { PolicyRepository } from '../repositories/PolicyRepository';

export interface GetPolicyRequest {
    policyId: UUID;
}

export const GetPolicyRequest = {
    ...defineValidator<GetPolicyRequest>({
        policyId: UUID.schema,
    }),
    create(getPolicyRequest: GetPolicyRequest) {
        return GetPolicyRequest.validate(getPolicyRequest);
    },
};

export interface GetPolicyResponse {
    policy: EntityProps<Policy>;
}

export interface GetPolicy extends UseCase {
    execute(
        request: GetPolicyRequest,
    ): AsyncResult<GetPolicyResponse, UnknownEntity | InvalidArgument | OperationFailed>;
}

@injectable()
export class GetPolicyUseCase extends UseCase {
    public static type = Symbol('Policy/GetPolicy');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(PolicyRepository) private policyRepository: PolicyRepository,
    ) {
        super(eventBus);
    }

    async execute(
        request: GetPolicyRequest,
    ): AsyncResult<GetPolicyResponse, UnknownEntity | InvalidArgument | OperationFailed> {
        const getPolicyResult = await this.policyRepository.getPolicy(request.policyId);
        if (isErr(getPolicyResult)) {
            return getPolicyResult;
        }

        const response: GetPolicyResponse = {
            policy: getPolicyResult.value.toDTO(),
        };
        return Success(response);
    }
}

export const GetPolicy: UseCaseClass<GetPolicy> = GetPolicyUseCase;
