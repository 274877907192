import { errorCodes } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { ErrorObject } from '@embroker/shotwell/core/types/Result';

export const ErrorCode = errorCodes({
    GetManifestByVersionError: 0xb000,
    GetProductDefinitionError: 0xb001,
    CreateServiceInfoError: 0xa105,
    CreateManifestDefinitionError: 0xa108,
    CreateProductDefinitionError: 0xa103,
    UwReviewFetchFailed: 0x6001,
    ExportUWReviewFromAPIResponseFailed: 0x6002,
});

export type GetManifestByVersionError = ErrorObject<typeof ErrorCode.GetManifestByVersionError, {}>;

export type GetProductDefinitionError = ErrorObject<typeof ErrorCode.GetProductDefinitionError, {}>;

export type CreateServiceInfoError = ErrorObject<typeof ErrorCode.CreateServiceInfoError, {}>;

export type CreateManifestDefinitionError = ErrorObject<
    typeof ErrorCode.CreateManifestDefinitionError,
    {}
>;

export type CreateProductDefinitionError = ErrorObject<
    typeof ErrorCode.CreateProductDefinitionError,
    {}
>;

export function GetManifestByVersionError(error: Error): Immutable<GetManifestByVersionError> {
    return {
        name: 'GetManifestByVersionError',
        code: ErrorCode.GetManifestByVersionError,
        message: error.message,
        details: {
            name: error.name,
            stack: error.stack,
        },
    };
}

export function GetProductDefinitionError(error: Error): Immutable<GetProductDefinitionError> {
    return {
        name: 'GetProductDefinitionError',
        code: ErrorCode.GetProductDefinitionError,
        message: error.message,
        details: {
            name: error.name,
            stack: error.stack,
        },
    };
}

export function CreateServiceInfoError(error: Error): Immutable<CreateServiceInfoError> {
    return {
        name: 'CreateServiceInfoError',
        code: ErrorCode.CreateServiceInfoError,
        message: error.message,
        details: {
            name: error.name,
            stack: error.stack,
        },
    };
}

export function CreateManifestDefinitionError(
    error: Error,
): Immutable<CreateManifestDefinitionError> {
    return {
        name: 'CreateManifestDefinitionError',
        code: ErrorCode.CreateManifestDefinitionError,
        message: error.message,
        details: {
            name: error.name,
            stack: error.stack,
        },
    };
}

export function CreateProductDefinitionError(
    error: Error,
): Immutable<CreateProductDefinitionError> {
    return {
        name: 'CreateProductDefinitionError',
        code: ErrorCode.CreateProductDefinitionError,
        message: error.message,
        details: {
            name: error.name,
            stack: error.stack,
        },
    };
}

export type UwReviewFetchFailed = ErrorObject<typeof ErrorCode.UwReviewFetchFailed, {}>;

export function UwReviewFetchFailed(): Immutable<UwReviewFetchFailed> {
    return {
        name: 'UwReviewFetchFailed',
        code: ErrorCode.UwReviewFetchFailed,
        message: 'Failed to fetch UW Review.',
        details: {},
    };
}

export type ExportUWReviewFromAPIResponseFailed = ErrorObject<
    typeof ErrorCode.ExportUWReviewFromAPIResponseFailed,
    {}
>;

export function ExportUWReviewFromAPIResponseFailed(): Immutable<ExportUWReviewFromAPIResponseFailed> {
    return {
        name: 'ExportUWReviewFromAPIResponseFailed',
        code: ErrorCode.ExportUWReviewFromAPIResponseFailed,
        message: 'Failed to export UW Review from API response.',
        details: {},
    };
}
