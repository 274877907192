import type * as APIType from '@embroker/shotwell-api/app';
import { addYears } from 'date-fns';
import { mplFormDataType } from './mplFormData';
import { MPLQuoteOptions } from './types/MPLQuoteOptions';

export function isMplQuoteOptions(options: any): options is MPLQuoteOptions {
    return 'limits' in options && 'retention' in options && 'effectiveDate' in options;
}

export function toApiMplQuoteOptions(options: MPLQuoteOptions): APIType.MplQuoteOptions {
    return {
        effective_date: options.effectiveDate,
        effective_period_end: addYears(options.effectiveDate, 1),
        per_claim_limit: options.limits.perClaimLimit,
        aggregate_limit: options.limits.aggregateLimit,
        retention: options.retention,
        is_deselected: options.isDeselected,
    };
}

export const formDataToMPLQuoteOptions = (formData: any): MPLQuoteOptions | undefined => {
    if (!isMplFormData(formData)) {
        return undefined;
    }
    return {
        effectiveDate: formData.effectiveDate,
        limits: formData.mplLimits,
        retention: formData.mplRetention,
        isDeselected: !formData.mplSelected,
    };
};

export function isMplFormData(input: any): input is mplFormDataType {
    return (
        'mplLimits' in input &&
        'mplRetention' in input &&
        'effectiveDate' in input &&
        'mplSelected' in input
    );
}
