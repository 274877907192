import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { GetGlobalConfigFailed } from '../../errors';
import { GlobalConfig } from '../../types/GlobalConfig';

export interface GlobalConfigRepository {
    /**
     * Collect configuration data which mostly contains feature flags and default configurations relevant to FE clients
     * @param preferCache True by default. Set to false if request should be passed to server
     */
    getGlobalConfig(
        preferCache?: boolean,
    ): AsyncResult<GlobalConfig, GetGlobalConfigFailed | InvalidArgument>;
}

export const GlobalConfigRepository = Symbol('GlobalConfigRepository');
