import { HartfordBopCoverage } from '@app/quote/hartford-bop/entities/BOPHartfordQuote';
import {
    BusinessLiabilityLimit,
    EmploymentPracticesLiability,
    GeneralAggregateLimit,
} from '@app/quote/hartford-bop/view/components/coverageMapper';
import { CoverageLimit } from '@app/quote/hartford-bop/view/components/quoteCoverage/CoverageLimit';
import React from 'react';

const NO_VALUE = 0;

interface Props {
    coverages: HartfordBopCoverage[];
}

export function CoverageList({ coverages }: Props) {
    const codes = [BusinessLiabilityLimit, GeneralAggregateLimit, EmploymentPracticesLiability];

    const filtered = coverages
        .filter((coverage) => !codes.includes(coverage.code))
        .filter((coverage) => coverage.limits.some((limit) => limit.limit.amount !== NO_VALUE));

    const generalAggregateCoverage = coverages.find(
        (coverage) => coverage.code === GeneralAggregateLimit,
    );

    const businessLiabilityCoverage = coverages.find(
        (coverage) => coverage.code === BusinessLiabilityLimit,
    );

    if (!generalAggregateCoverage || !businessLiabilityCoverage) {
        return null;
    }

    filtered.sort((a, b) => a.description.localeCompare(b.description));

    return (
        <React.Fragment>
            <CoverageLimit
                coverage={businessLiabilityCoverage}
                aggregateLimit={generalAggregateCoverage}
            />
            {filtered.map((coverage, index) => (
                <CoverageLimit
                    key={index}
                    coverage={coverage}
                    aggregateLimit={generalAggregateCoverage}
                />
            ))}
        </React.Fragment>
    );
}
