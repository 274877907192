import * as APIType from '@embroker/shotwell-api/app';
import { API } from '@embroker/shotwell-api/app';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { USD } from '@embroker/shotwell/core/types/Money';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { IndicativePremium } from '../../entities/IndicativePremium';
import { BundleAppTypeNotSupportedError } from '../../errors';
import { AppTypeCode } from '../../types/enums';
import { toQuote } from '../ApplicationRepository/APIApplicationRepository';
import { IndicativePremiumRepository, supportedAppTypes } from './index';

@injectable()
export class APIIndicativePremiumRepository implements IndicativePremiumRepository {
    async getIndicativePremiumList(
        applicationId: UUID,
    ): AsyncResult<
        IndicativePremium[],
        InvalidArgument | OperationFailed | BundleAppTypeNotSupportedError
    > {
        const indicativePremiumResponse = await API.request('shopping/calculate_indicative_quote', {
            app_id: applicationId,
        });
        if (isErr(indicativePremiumResponse)) {
            return Failure(
                OperationFailed({
                    message: 'Failed to fetch indicative premium',
                    errors: indicativePremiumResponse.errors,
                }),
            );
        }
        const { value: apiIndicativePremiumList } = indicativePremiumResponse;

        const indicativePremiumListEntitiesResult =
            await APIIndicativePremiumRepository.toIndicativePremiumList(apiIndicativePremiumList);

        if (isErr(indicativePremiumListEntitiesResult)) {
            return Failure(
                OperationFailed({
                    message: 'Failed to create IndicativePremium list',
                    errors: indicativePremiumListEntitiesResult.errors,
                }),
            );
        }

        const indicativePremiumList = indicativePremiumListEntitiesResult.value;

        const unsupportedAppType = indicativePremiumList.find(
            (indicativePremium) => !supportedAppTypes.includes(indicativePremium.appType),
        )?.appType;

        if (unsupportedAppType) {
            return Failure(BundleAppTypeNotSupportedError(unsupportedAppType));
        }

        return Success(indicativePremiumList);
    }

    private static async toIndicativePremiumList(
        indicativePremiumList: Immutable<APIType.ShoppingCalculateIndicativeQuoteResponse>,
    ): AsyncResult<Array<IndicativePremium>, InvalidArgument | OperationFailed> {
        const result: Array<Immutable<IndicativePremium>> = [];
        for (const indicativePremium of indicativePremiumList) {
            const indicativePremiumResult = await IndicativePremium.create({
                applicationId: indicativePremium.app_id,
                appType: indicativePremium.app_type as AppTypeCode,
                ineligible: indicativePremium.ineligible,
                basePremium: indicativePremium.base_premium ?? USD(0),
                quote: indicativePremium.quote ? toQuote(indicativePremium.quote) : undefined,
            });
            if (isErr(indicativePremiumResult)) {
                return handleOperationFailure(indicativePremiumResult);
            }
            result.push(indicativePremiumResult.value);
        }

        return Success(result);
    }
}
