import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { ValidationKeys, ValidationTypeProps, ValidationTypes } from './fieldValidationFactory';

export interface ValidationMessageBuilderProps {
    errorMsgObject?: ErrorMsgObject;
    validate?: ValidationTypeProps[];
}

export interface ErrorMsgObject {
    argument?: string;
    validator?: string;
    value?: any;
}

export const LIST_INPUT_DELIMITER = 'LIST_INPUT_DELIMITER';
export const MULTI_FIELD_INPUT_DELIMITER = 'MULTI_FIELD_INPUT_DELIMITER';
export const ANY_REQUIRED = 'any.required';
export const INVALID_OPTIONAL_ARRAY = 'INVALID_OPTIONAL_ARRAY';
export const EMPTY_REQUIRED_ARRAY = 'array.includesRequiredUnknowns';
// Joi uses '.' to delimit array validation messages. We are following the same pattern here.
// Joi example: '2.string.required' denotes that the 3nd element fails the required string validation.

export const INPUT_REQUIRE_MESSAGE = 'This input is required';

export const ErrorMsgObject = {
    ...defineValidator<ErrorMsgObject>(
        Joi.object({
            argument: Joi.string().optional(),
            validator: Joi.string().optional(),
            value: Joi.any().optional(),
        }),
    ),
    create(error: InvalidArgument) {
        return ErrorMsgObject.validate(error.details);
    },
    getErrorMsgObject(error: InvalidArgument): ErrorMsgObject {
        const errorMsgObjectResp = ErrorMsgObject.create(error);

        const errorMsgObject = isOK(errorMsgObjectResp)
            ? errorMsgObjectResp.value
            : { validator: 'unknown' };

        return errorMsgObject;
    },
    getValidationMessage(error: InvalidArgument, validate: ValidationTypeProps[] = []): string {
        const errorMsgObject = ErrorMsgObject.getErrorMsgObject(error);
        const { argument = '' } = errorMsgObject;

        const validationMessage = [];

        // Here argument is either a question key or an index from a list question.
        // If the argument is a number, we can assume that this validation message is for an item within a list question type.
        // Here we are using the argument to build the Joi pattern for array messaging.
        const isListInput = !isNaN(parseInt(argument, 10));
        if (isListInput) {
            validationMessage.push(argument);
        }

        validationMessage.push(ErrorMsgObject.buildValidationMessage(error, validate));

        return validationMessage.join(LIST_INPUT_DELIMITER);
    },
    buildValidationMessage(error: InvalidArgument, validate: ValidationTypeProps[] = []): string {
        const fallbackMessage = 'Input is not valid';
        const errorMsgObject = ErrorMsgObject.getErrorMsgObject(error);

        const [validatorDataType, validatorValueType] = errorMsgObject.validator?.split('.') || [];

        const requiredInputTypes = ['required', 'empty'];
        if (requiredInputTypes.includes(validatorValueType)) {
            return 'This input is required';
        }

        switch (validatorDataType) {
            case 'string': {
                return getStringValidatorMessage(validate, validatorValueType) || fallbackMessage;
            }
            case 'number': {
                return getNumberValidatorMessage(validate, validatorValueType) || fallbackMessage;
            }
            case 'boolean': {
                return getBooleanValidatorMessage(validatorValueType);
            }
            case `conditional`: {
                return validatorValueType;
            }
        }

        return fallbackMessage;
    },
};

const getBooleanValidatorMessage = (validatorValueType: string) => {
    return `Input must be ${validatorValueType}`;
};

const getStringValidatorMessage = (validate: ValidationTypeProps[], validatorValueType: string) => {
    const type: ValidationTypes = 'string';
    switch (validatorValueType) {
        case 'max': {
            const ruleValue = getValidatorRuleValue(validate, type, 'hasLengthLessThan');
            return ruleValue ? `Must be less than ${ruleValue} characters` : null;
        }
        case 'min': {
            const ruleValue = getValidatorRuleValue(validate, type, 'hasLengthGreaterThan');
            return ruleValue ? `Must be greater than ${ruleValue} characters` : null;
        }
        case 'pattern': {
            return `Not a valid text input`;
        }
        case 'regex': {
            const message = validate.find((validate) => validate?.string?.regex?.message);
            if (message) {
                return message.string?.regex?.message;
            }
            return `Not a valid text input`;
        }
    }
};

const getNumberValidatorMessage = (validate: ValidationTypeProps[], validatorValueType: string) => {
    const type = getValidationTypeFromValidationTypeProps(validate);
    switch (type) {
        case 'number': {
            switch (validatorValueType) {
                case 'max': {
                    const ruleValue = getValidatorRuleValue(validate, type, 'lessThan');
                    return ruleValue ? `Value must be less than ${ruleValue}` : null;
                }
                case 'min': {
                    const ruleValue = getValidatorRuleValue(validate, type, 'greaterThan');
                    return ruleValue ? `Value must be greater than ${ruleValue}` : null;
                }
            }
            break;
        }
        case 'currency': {
            switch (validatorValueType) {
                case 'max': {
                    const ruleValue = getValidatorRuleValue(validate, type, 'lessThan');
                    return ruleValue ? `Amount must be less than $${ruleValue}` : null;
                }
                case 'min': {
                    const ruleValue = getValidatorRuleValue(validate, type, 'greaterThan');
                    return ruleValue ? `Amount must be greater than $${ruleValue}` : null;
                }
            }
            break;
        }
    }
};

// This function is very loose by design, since the ValidationTypeProps objects can take many forms
// we use this to find the first element that meets our condition, if it exists.
const getValidatorRuleValue = (
    validate: ValidationTypeProps[],
    validationType: ValidationTypes,
    validationDefinition: ValidationKeys,
): unknown => {
    const validator: any = validate.find((validate: any) =>
        Boolean(validate?.[validationType]?.[validationDefinition]),
    );

    return validator ? validator[validationType]?.[validationDefinition] : null;
};

const getValidationTypeFromValidationTypeProps = (
    validate: ValidationTypeProps[],
): ValidationTypes | undefined => {
    if (Array.isArray(validate)) {
        if (typeof validate[0] === 'object') {
            // If this element is an object, we can assume it is a ValidationTypeProps object
            // An object of type ValidationTypeProps will have a single key, which is the type of ValidationTypes
            return Object.keys(validate[0])[0] as ValidationTypes;
        }
    }
    return;
};
