import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Immutable } from '@embroker/shotwell/core/types';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    Button,
    ColumnLayout,
    GridLayout,
    Loader,
    PageLayout,
    StackLayout,
    StatusMessage,
    Text,
    TextButton,
    useModal,
    useStableEventHandler,
} from '@embroker/ui-toolkit/v2';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-navi';
import { DisplayPolicy } from '../../../../policy/types/DisplayPolicy';
import { PolicySortByAlphabet } from '../../../../policy/types/PolicySortBy';
import { GetPolicies } from '../../../../policy/useCases/GetPolicies';
import { RequestLossRuns } from '../../../useCases/RequestLossRuns';
import { EmailSelectModal } from './EmailSelectModal';
import { PolicyItem } from './PolicyItem';
import { PoliciesSelected } from './SelectPolicySequenceModal';
import { SuccessModal } from './SuccessModal';

export function LossRunsRequest() {
    const successModal = useModal();
    const selectEmailModal = useModal();
    const [shouldDisplayErrorMessage, setShouldDisplayErrorMessage] = useState<boolean>(false);
    const [policies, setPolicies] = useState<Immutable<DisplayPolicy[]>>([]);
    const [policyNumberList, setPolicyNumberList] = useState<string[]>([]);
    const [selectedPoliciesMap, setSelectedPoliciesMap] = useState<
        Record<string, PoliciesSelected>
    >({});

    const { result: activePoliciesResult, isLoading } = useUseCase(GetPolicies, {
        filter: {
            showActive: true,
            showInactive: true,
            sortBy: PolicySortByAlphabet,
        },
    });

    const modalSuccessCallback = useStableEventHandler(successModal.show);

    useEffect(() => {
        if (activePoliciesResult) {
            if (isOK(activePoliciesResult)) {
                setPolicies(activePoliciesResult.value.policyList);
            } else {
                container.get<Logger>(Log).error(activePoliciesResult.errors);
            }
        }
    }, [activePoliciesResult]);

    const resetSelectedPolicies = useCallback(() => {
        setSelectedPoliciesMap(
            policies.reduce<Record<string, PoliciesSelected>>((object, policy) => {
                object[policy.id] = 'none';
                return object;
            }, {}),
        );
    }, [policies]);

    useEffect(() => {
        resetSelectedPolicies();
    }, [resetSelectedPolicies]);

    const createSelectHandler = (policyId: UUID) => {
        return (value: PoliciesSelected) => {
            setSelectedPoliciesMap({ ...selectedPoliciesMap, [`${policyId}`]: value });
        };
    };

    const handleSendRequest = async () => {
        const policyNumberList = ([] as string[]).concat.apply(
            [],
            policies.map((policy) =>
                selectedPoliciesMap[policy.id] !== 'none'
                    ? selectedPoliciesMap[policy.id] === 'all'
                        ? policy.policyNumbersInSeries
                        : [policy.policyNumber]
                    : [],
            ),
        );
        if (policyNumberList.length === 0) {
            return;
        }

        const containsNonLPLPolicy =
            policyNumberList.find((element) => !element.includes('EML')) !== undefined;

        if (containsNonLPLPolicy) {
            setPolicyNumberList(policyNumberList);
            selectEmailModal.show();
        } else {
            const result = await execute(RequestLossRuns, {
                policyNumberList,
            });

            if (isErr(result)) {
                container.get<Logger>(Log).error(result.errors);
                setShouldDisplayErrorMessage(true);
                return;
            }

            successModal.show();
            resetSelectedPolicies();
        }
    };

    const noPolicySelected = !Object.values(selectedPoliciesMap).find((value) => value !== 'none');

    return isLoading ? (
        <Loader />
    ) : (
        <PageLayout.Section>
            {selectEmailModal.visible && (
                <EmailSelectModal
                    modal={selectEmailModal}
                    policyNumberList={policyNumberList}
                    successCallback={modalSuccessCallback}
                />
            )}
            <StackLayout gap="24">
                <TextButton
                    size="small"
                    as={Link}
                    icon="bold-caret-left"
                    iconPosition="left"
                    href="/broker/organization-info"
                >
                    Back to client
                </TextButton>
                <Text style="heading 2">Request Loss Runs</Text>
                {policies.length > 0 ? (
                    <React.Fragment>
                        <Text>
                            Please select policies for which you would like to receive loss runs.
                        </Text>
                        <GridLayout>
                            {policies.map((policy) => (
                                <PolicyItem
                                    key={policy.id}
                                    policy={policy}
                                    handleSelect={createSelectHandler(policy.id)}
                                    hasMultiple={policy.hasMultiplePolicies}
                                    selected={selectedPoliciesMap[policy.id] || 'none'}
                                />
                            ))}
                        </GridLayout>
                        {shouldDisplayErrorMessage ? (
                            <StatusMessage status="warning">
                                Sorry for the inconvenience, but we are unable to process your
                                request at this time. Please try later.
                            </StatusMessage>
                        ) : null}
                        <ColumnLayout split="-1">
                            <Button disabled={noPolicySelected} onClick={handleSendRequest}>
                                Send request
                            </Button>
                        </ColumnLayout>
                        <SuccessModal modal={successModal} />
                    </React.Fragment>
                ) : (
                    <Text>
                        You have no policies yet. Once your client has coverage, the list will
                        appear here.
                    </Text>
                )}
            </StackLayout>
        </PageLayout.Section>
    );
}
