import { inject, injectable } from '@embroker/shotwell/core/di';
import { Aborted, InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isOK } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { SessionRepository } from '../../userOrg/repositories/SessionRepository';
import { DocumentRepository } from '../repositories/DocumentRepository';

/**
 * Request data for SaveFiles use case
 */
export interface SaveFilesRequest {
    /**
     * A list of uploaded files to be saved
     */
    files: {
        name: string;
        file_key: string;
        mime_type: string;
        size: number;
    }[];
    applicationId?: UUID;
}

/**
 * SaveFiles use case
 */
export interface SaveFiles extends UseCase {
    execute(
        request: SaveFilesRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed | Aborted>;
}

@injectable()
export class SaveFilesUseCase extends UseCase implements SaveFiles {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Documents/SaveFiles');

    /**
     * Constructor for SaveFiles use case class instance.
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param sessionRepository Session repository used to retrieve organization id.
     * @param documentRepository Document repository used to store files.
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(SessionRepository) private sessionRepository: SessionRepository,
        @inject(DocumentRepository) private documentRepository: DocumentRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes SaveFiles use case
     * @param files is the list of files to be saved to the document repository
     */
    async execute({
        files,
        applicationId,
    }: SaveFilesRequest): AsyncResult<void, InvalidArgument | OperationFailed | Aborted> {
        const sessionResult = await this.sessionRepository.getActiveSession();

        let organizationId = null;
        if (isOK(sessionResult) && sessionResult.value !== null) {
            organizationId = sessionResult.value.organizationId;
        }

        return await this.documentRepository.saveFiles({
            files,
            organizationId,
            applicationId,
        });
    }
}

export const SaveFiles: UseCaseClass<SaveFiles> = SaveFilesUseCase;
