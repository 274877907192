import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ApplicationRepository } from '../repositories/ApplicationRepository';
import { APIApplicationRepository } from '../repositories/ApplicationRepository/APIApplicationRepository';

export interface SaveApplicationLastPageRequest {
    userId: UUID;
    applicationId: UUID;
    lastPage: string;
}

export interface SaveApplicationLastPage extends UseCase {
    execute(
        request: SaveApplicationLastPageRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed>;
}

class SaveApplicationLastPageUseCase extends UseCase implements SaveApplicationLastPage {
    public static type = Symbol('Shopping/SaveApplicationLastPage');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: APIApplicationRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        userId,
        applicationId,
        lastPage,
    }: SaveApplicationLastPageRequest): AsyncResult<void, InvalidArgument | OperationFailed> {
        const saveLastPageResponse = await this.applicationRepository.saveApplicationLastPage({
            userId,
            applicationId,
            lastPage,
        });
        if (isErr(saveLastPageResponse)) {
            return saveLastPageResponse;
        }

        return Success();
    }
}

export const SaveApplicationLastPage: UseCaseClass<SaveApplicationLastPage> =
    SaveApplicationLastPageUseCase;
