import { injectable, inject } from '@embroker/shotwell/core/di';
import { AnalyticsService } from '.';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { GoogleAnalyticsRepository } from '../repositories/GoogleAnalyticsRepository';
import {
    NaicsSearchStarted,
    NaicsSearchSuccess,
    NaicsSearched,
    NaicsSearchStopped,
} from '../../industries/view/components/Naics';
import { Nullable, Immutable } from '@embroker/shotwell/core/types';
import { levenshtein } from './levenshtein';
import { isDomainEventLocal } from '@embroker/shotwell/core/event/DomainEvent';

interface SearchRecord {
    readonly query: string;
    readonly results: Immutable<string[]>;
}

@injectable()
export class IndustrySearchAnalyticsService implements AnalyticsService {
    private static readonly MinQueryLength: number = 5;
    private static readonly LevenshteinLengthRatioThreshold: number = 1 / 2;

    private reportedQueries: Set<string>;
    private lastSearchRecord: Nullable<SearchRecord>;
    private failedSearchCandidate: Nullable<SearchRecord>;

    constructor(
        @inject(DomainEventBus) private eventBus: DomainEventBus,
        @inject(GoogleAnalyticsRepository)
        private googleAnalyticsRepository: GoogleAnalyticsRepository,
    ) {
        this.reportedQueries = new Set<string>();
        this.lastSearchRecord = null;
        this.failedSearchCandidate = null;
    }

    public subscribeToEvents(): void {
        this.eventBus.subscribe<NaicsSearchStarted>('Naics', 'SearchStarted', async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }

            this.handleSearchStarted(event);
        });
        this.eventBus.subscribe<NaicsSearchStopped>('Naics', 'SearchStopped', async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }

            this.handleSearchStopped();
        });
        this.eventBus.subscribe<NaicsSearchSuccess>('Naics', 'SearchSuccess', async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }

            this.handleSuccessfulSearchEvent(event);
        });
        this.eventBus.subscribe<NaicsSearched>('Naics', 'Searched', async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }

            this.handleSearchEvent(event);
        });
    }

    private handleSearchStarted(event: Immutable<NaicsSearchStarted>) {
        this.googleAnalyticsRepository.sendCustomEvent('IndustrySearchStarted', {
            industrySearchContext: event.NAICSPagePosition,
        });
    }

    private handleSearchStopped() {
        if (this.failedSearchCandidate == null) {
            this.failedSearchCandidate = this.lastSearchRecord;
        }
        if (this.failedSearchCandidate != null) {
            this.processFailedSearchCandidate('clickAway');
        }
        this.lastSearchRecord = null;
        this.failedSearchCandidate = null;
    }

    private handleSuccessfulSearchEvent(event: Immutable<NaicsSearchSuccess>) {
        this.googleAnalyticsRepository.sendCustomEvent('IndustrySearch', {
            query: event.query.toLowerCase(),
            all_results: event.results,
            selection_occurred: true,
            selection_embroker_name: event.selectedResult,
            selection_rank: event.rank,
            trigger: 'selectIndustry',
            industrySearchContext: event.NAICSPagePosition,
        });
        this.reportedQueries.add(event.query);
    }

    private handleSearchEvent(event: Immutable<NaicsSearched>) {
        const currentQuery = event.query.toLowerCase();
        const lastQuery = this.lastSearchRecord?.query.toLowerCase() ?? '';
        const isExpandingQuery = currentQuery.startsWith(lastQuery);
        const isDeletingQuery = lastQuery.startsWith(currentQuery);
        if (isExpandingQuery) {
            this.handleQueryExpansion(currentQuery);
        } else if (isDeletingQuery) {
            this.handleQueryDeletion();
        } else {
            this.handleQueryCleared(currentQuery);
        }
        this.lastSearchRecord = {
            query: currentQuery,
            results: event.results,
        };
    }

    private handleQueryExpansion(query: string) {
        if (
            this.failedSearchCandidate != null &&
            this.isQuerySignificantlyAltered(this.failedSearchCandidate.query, query)
        ) {
            this.processFailedSearchCandidate('failedSearch');
        }
        this.failedSearchCandidate = null;
    }

    private handleQueryDeletion() {
        if (this.failedSearchCandidate == null) {
            this.failedSearchCandidate = this.lastSearchRecord;
        }
    }

    private handleQueryCleared(query: string) {
        if (this.failedSearchCandidate == null) {
            this.failedSearchCandidate = this.lastSearchRecord;
        }
        if (
            this.failedSearchCandidate != null &&
            this.isQuerySignificantlyAltered(this.failedSearchCandidate.query, query)
        ) {
            this.processFailedSearchCandidate('failedSearch');
        }
        this.failedSearchCandidate = null;
    }

    private isQuerySignificantlyAltered(oldQuery: string, newQuery: string) {
        const oldQueryLength = oldQuery.length;
        const oldQueryTooShort = oldQueryLength < IndustrySearchAnalyticsService.MinQueryLength;
        if (oldQueryTooShort) {
            return false;
        }
        const levenshteinValue = levenshtein(newQuery, oldQuery);
        const levenshteinLengthRatio = levenshteinValue / oldQueryLength;
        return (
            levenshteinLengthRatio > IndustrySearchAnalyticsService.LevenshteinLengthRatioThreshold
        );
    }

    private processFailedSearchCandidate(failCause: string) {
        if (
            this.failedSearchCandidate != null &&
            this.isQueryEligibleToReport(this.failedSearchCandidate.query)
        ) {
            this.googleAnalyticsRepository.sendCustomEvent('IndustrySearch', {
                query: this.failedSearchCandidate.query,
                all_results: this.failedSearchCandidate.results,
                selection_occurred: false,
                selection_embroker_name: '',
                selection_rank: 0,
                trigger: failCause,
            });
            this.reportedQueries.add(this.failedSearchCandidate.query);
        }
    }

    private isQueryEligibleToReport(query: string): boolean {
        const isLongEnough = query.length >= IndustrySearchAnalyticsService.MinQueryLength;
        const isAlreadyReported = this.reportedQueries.has(query);
        return isLongEnough && !isAlreadyReported;
    }
}
