import { Immutable } from '@embroker/shotwell/core/types';
import { URI } from '@embroker/shotwell/core/types/URI';
import {
    Field,
    FieldMap,
    Form,
    FormData,
    FormDataType,
    FormStatus,
    OpaqueForm,
    useForm,
} from '@embroker/shotwell/view/hooks/useForm';
import { usePrevious, useStepper } from '@embroker/ui-toolkit/v2';
import { useCallback, useEffect, useState } from 'react';
import { useCurrentRoute } from 'react-navi';
import { useNavigation } from './useNavigation';

export interface WizardPageDefinition<T extends OpaqueForm<FormData>> {
    readonly name: string;
    readonly title?: string;
    readonly fields: Readonly<(keyof FormDataType<T>)[]>;
    readonly isInitial?: boolean;
    readonly isEnabled?: boolean;
}

export type WizardPages<T extends OpaqueForm<FormData>> = ReadonlyArray<WizardPageDefinition<T>>;

/**
 * Result object returned from the useWizardForm hook.
 */
export interface WizardForm<T extends OpaqueForm<FormData>> {
    reset(): void;

    next(): void;

    previous(): void;

    hasNext: boolean;
    hasPrevious: boolean;
    activePageIndex: number;
    activePageOrder: number;
    activePageName: string;
    activePageTitle?: string;
    totalPages: number;
    fields: Immutable<FieldMap<FormDataType<T>>>;
    value: Immutable<FormDataType<T>>;

    setValue(value: FormDataType<T> | FormDataType<Immutable<T>>): void;

    status: FormStatus;
    setActiveStep: (page: string | number) => void;
    setFieldValue: (fieldName: string, value: unknown) => void;

    submit(): void;

    result: unknown;
    trigger: Form<Immutable<FormDataType<T>>>['trigger'];
    action: Form<Immutable<FormDataType<T>>>['action'];
    messages: Form<Immutable<FormDataType<T>>>['messages'];

    validate(...value: (keyof T)[]): void;
    triggerPageValidation: (pageName: string | number) => void;
}

/**
 * Merging useForm and useStepper into one useWizardForm hook
 * Manages validation of fields per page
 * Manages routing for every page name so we can use analytics
 *
 * @param form The form descriptor returned by createForm().
 * @param initialValue Optional initial value for this form (by default initializes all fields to undefined).
 * @param pages Representation of wizard pages, every page contains name,
 *              fields (name of fields that will be validate)
 *              and optional parameter isInitial for setting initial page in stepper
 */

export function useWizardForm<T extends OpaqueForm<FormData>>(
    form: T,
    {
        pages,
        initialValue = {},
        keepUrl = false,
        beforeNext = () => Promise.resolve(),
    }: {
        pages: WizardPages<T>;
        initialValue?: Partial<FormDataType<T>> | (() => Partial<FormDataType<T>>);
        keepUrl?: boolean;
        beforeNext?: (
            wizardForm: Pick<WizardForm<T>, 'activePageIndex' | 'value' | 'setValue'>,
        ) => Promise<void>;
    },
): WizardForm<T> {
    const [isValidating, setIsValidating] = useState<'next' | 'navigation' | ''>('');
    const [nextIndexOrNamePage, setNextIndexOrNamePage] = useState<string | number>('');
    const {
        reset,
        submit,
        validate,
        fields,
        value,
        setValue,
        status,
        result,
        trigger,
        action,
        messages,
        setFieldValue,
    } = useForm(form, initialValue);
    const initialPage = Math.max(
        0,
        pages.findIndex((page) => page.isInitial),
    );

    const {
        hasNext,
        activeStepIndex: activePageIndex,
        hasPrevious,
        activeStepOrder: activePageOrder,
        totalSteps: totalPages,
        next: nextPage,
        previous,
        setActiveStep,
    } = useStepper({
        steps: pages.map(({ name, isEnabled }) => ({ name, enabled: isEnabled !== false })),
        initialStep: initialPage,
    });

    const { navigate } = useNavigation();
    const {
        data: { url, page },
        url: { search },
    } = useCurrentRoute();

    const { name: activePageName, title: activePageTitle } = pages[activePageIndex];

    const popstateHandler = useCallback(() => {
        if (hasPrevious) {
            previous();
        }
    }, [hasPrevious, previous]);
    const prevPopstateHandler = usePrevious(popstateHandler);

    useEffect(() => {
        if (prevPopstateHandler !== undefined) {
            window.removeEventListener('popstate', prevPopstateHandler);
        }
        window.addEventListener('popstate', popstateHandler);
        return () => {
            window.removeEventListener('popstate', popstateHandler);
        };
    }, [popstateHandler, prevPopstateHandler]);

    useEffect(() => {
        if (!url || keepUrl) {
            return;
        }
        const urlParts = url.split('/');
        if (page !== undefined && page.length > 0) {
            urlParts.pop();
        }
        const nextPage = URI.join('/', ...urlParts, activePageName) + search;
        navigate(nextPage);
    }, [activePageName, url, page, search, navigate, keepUrl]);

    useEffect(() => {
        if (isValidating === '') {
            return;
        }
        if (isValidating === 'next') {
            setIsValidating('');
            const { fields: activePageFields } = pages[activePageIndex];
            const isPageInvalid = activePageFields.some(
                (name) => (fields[name] as Field<FormDataType<T>>).status == 'invalid',
            );
            if (isPageInvalid) {
                return;
            }
            beforeNext({
                activePageIndex,
                value,
                setValue,
            }).then(nextPage);
        } else if (isValidating === 'navigation') {
            setIsValidating('');
            const { fields: activePageFields } = pages[activePageIndex];
            const isPageInvalid = activePageFields.some(
                (name) => (fields[name] as Field<FormDataType<T>>).status == 'invalid',
            );
            if (isPageInvalid) {
                return;
            }
            setActiveStep(nextIndexOrNamePage);
        }
    }, [
        pages,
        fields,
        isValidating,
        activePageIndex,
        nextPage,
        beforeNext,
        value,
        setValue,
        setActiveStep,
        nextIndexOrNamePage,
    ]);

    const next = useCallback(() => {
        if (!hasNext) {
            return submit();
        }
        const { fields: activePageFields } = pages[activePageIndex];
        validate(...activePageFields);
        setIsValidating('next');
    }, [pages, validate, submit, hasNext, activePageIndex]);

    const triggerPageValidation = useCallback(
        (pageName: string | number) => {
            const { fields: activePageFields } = pages[activePageIndex];
            validate(...activePageFields);
            setIsValidating('navigation');
            setNextIndexOrNamePage(pageName);
        },
        [pages, validate, activePageIndex],
    );

    return {
        reset,
        next,
        previous,
        hasNext,
        hasPrevious,
        activePageIndex,
        activePageOrder,
        activePageName,
        activePageTitle,
        totalPages,
        fields,
        value,
        setValue,
        submit,
        status,
        setActiveStep,
        result,
        trigger,
        action,
        validate,
        messages,
        triggerPageValidation,
        setFieldValue,
    };
}
