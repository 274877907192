import { LineOfBusinessSubtypeCodeListItem } from '@embroker/shotwell-api/enums';
import { PhoneNumber } from '@embroker/shotwell/core/types/PhoneNumber';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { PhoneNumberDisplay } from '@embroker/shotwell/view/components/PhoneNumberDisplay';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    Card,
    ColumnLayout,
    GridLayout,
    Spinner,
    StackLayout,
    StatusMessage,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React, { useContext } from 'react';
import { PolicyIcon } from '../../../policy/view/components/PolicyIcon';
import { hasRole } from '../../../userOrg/entities/Session';
import { AppContext } from '../../../view/AppContext';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { GetDirectBillPolicies } from '../../useCases/GetDirectBillPolicies';

export function PaymentsCarriersDue() {
    const { navigate } = useNavigation();
    const context = useContext(AppContext);
    const { result: policiesResult, isLoading: isPoliciesLoading } =
        useUseCase(GetDirectBillPolicies);

    const isBroker = hasRole(context.activeSession, 'broker');
    if (isBroker) {
        navigate('/payments');
    }

    if (isPoliciesLoading || policiesResult === undefined) {
        return <Spinner />;
    }

    if (isErr(policiesResult)) {
        return <ErrorPage errors={policiesResult.errors} />;
    }

    const directBillPolicyList = policiesResult.value.policyList;

    if (directBillPolicyList.length == 0) {
        return (
            <StackLayout gap="24">
                <Card center>
                    <Card.Header singleColumn />
                    <Card.Body singleColumn>
                        <Text style="body 1">
                            You have no payments due to carriers at this time.
                        </Text>
                    </Card.Body>
                    <Card.Footer singleColumn />
                </Card>
            </StackLayout>
        );
    }

    const carriersCardList = directBillPolicyList.map((directBillPolicy) => {
        return (
            <Card size={'medium'} key={UUID.create() as string} appearance={'loose'}>
                <Card.Header>
                    <PolicyIcon
                        lineOfBusiness={directBillPolicy.lineOfBusiness}
                        subLineOfBusiness={
                            (directBillPolicy.subLineOfBusiness as LineOfBusinessSubtypeCodeListItem) ??
                            undefined
                        }
                    />
                    <Text style="heading 5">{directBillPolicy.displayName}</Text>
                </Card.Header>
                <Card.Body>
                    <StackLayout gap={'none'}>
                        <ColumnLayout gap={'8'}>
                            <Text style="body 2" color="ui-400">
                                Covered by:
                            </Text>
                            <Text>{directBillPolicy.insurerName}</Text>
                        </ColumnLayout>
                        <ColumnLayout gap={'8'}>
                            <Text style="body 2" color="ui-400">
                                Policy No.
                            </Text>
                            <Text>{directBillPolicy.policyNumber}</Text>
                        </ColumnLayout>
                        <ColumnLayout gap={'8'}>
                            <Text style="body 2" color="ui-400">
                                Policy Period:
                            </Text>
                            <Text>
                                <DateDisplay value={directBillPolicy.startDate} />
                                {'- '}
                                <DateDisplay value={directBillPolicy.endDate} />
                            </Text>
                        </ColumnLayout>
                        {directBillPolicy.insurerBillingPhone != undefined && (
                            <ColumnLayout gap={'8'}>
                                <Text style="body 2" color="ui-400">
                                    Billing Phone:
                                </Text>
                                <PhoneNumberDisplay
                                    value={directBillPolicy.insurerBillingPhone as PhoneNumber}
                                />
                            </ColumnLayout>
                        )}
                    </StackLayout>
                </Card.Body>
                <Card.Footer>
                    {directBillPolicy.insurerBillingURL == undefined ? (
                        <Text>Please reference the policy document for details.</Text>
                    ) : (
                        <TextButton
                            target="_blank"
                            href={URI.sanitize(directBillPolicy.insurerBillingURL)}
                        >
                            Visit carrier's billing page
                        </TextButton>
                    )}
                </Card.Footer>
            </Card>
        );
    });

    return (
        <StackLayout>
            <StatusMessage status="info">
                Payments for policies in this section are collected directly by the insurance
                carriers. Links to pay on the carrier's website are listed below for your
                convenience. Please note that Embroker will not update these tiles nor payments
                history section upon payment completion.
            </StatusMessage>
            <GridLayout>{carriersCardList}</GridLayout>
        </StackLayout>
    );
}
