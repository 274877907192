import React from 'react';
import {
    Button,
    ButtonBar,
    StackLayout,
    Text,
    TextButton,
    UseResponsiveScreenQuery,
    useResponsive,
} from '@embroker/ui-toolkit/v2';
import { Link } from '.';

interface ActionProps {
    label: string;
    onClick?: () => void;
    'data-e2e'?: string;
    href?: string;
}

export interface ModalLayoutProps {
    title?: React.ReactNode;
    children?: React.ReactNode;
    primaryAction: ActionProps;
    secondaryAction?: ActionProps;
    'data-e2e'?: string;
}

const mobileScreenQuery: UseResponsiveScreenQuery = {
    screenWidth: { smallerThan: 'large-mobile' },
};

export function ModalLayout({
    title,
    children,
    primaryAction,
    secondaryAction,
    'data-e2e': dataE2E,
}: ModalLayoutProps) {
    const isMobile = useResponsive(mobileScreenQuery);

    const primaryActionHref = primaryAction?.href;

    return (
        <StackLayout gap="32" data-e2e={dataE2E}>
            <StackLayout gap="24">
                {title ? (
                    <Text style="heading 3" data-e2e="modal-title">
                        {title}
                    </Text>
                ) : null}
                {children ? (
                    typeof children === 'string' ? (
                        <Text>{children}</Text>
                    ) : (
                        children
                    )
                ) : null}
            </StackLayout>
            {/*
             * Responsive `gap` value is to account for the fact that `TextButton` doesn't generate an inner padding as Button does.
             * 32px/24px gap is to account for the lack of horizontal padding. Gap should be reverted to 12px when `TextButton` padding is fixed.
             */}
            <ButtonBar gap={isMobile ? '24' : '32'} responsive={mobileScreenQuery}>
                {primaryAction && (
                    <Button
                        onClick={primaryAction.onClick}
                        as={primaryActionHref ? Link : undefined}
                        href={primaryActionHref ?? ''}
                        data-e2e={primaryAction['data-e2e'] ?? 'modal-primary-button'}
                    >
                        {primaryAction.label}
                    </Button>
                )}
                {secondaryAction && (
                    <TextButton
                        onClick={secondaryAction.onClick}
                        data-e2e={secondaryAction['data-e2e'] ?? 'modal-secondary-button'}
                        href={secondaryAction.href}
                    >
                        {secondaryAction.label}
                    </TextButton>
                )}
            </ButtonBar>
        </StackLayout>
    );
}
