import React, { useEffect, useContext, useMemo } from 'react';
import { StackLayout, Form, Text } from '@embroker/ui-toolkit/v2';
import { container } from '@embroker/shotwell/core/di';
import { BrokerBanner } from '../../../../view/components/BrokerBanner.view';
import { execute } from '@embroker/shotwell/core/UseCase';
import { URI } from '@embroker/shotwell/core/types/URI';
import { useCurrentRoute } from 'react-navi';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import { Nullable } from '@embroker/shotwell/core/types';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { EmailAlreadyInUse, ErrorCode } from '../../../errors';
import { SignUp as SignUpUseCase } from '../../../useCases/SignUp';
import { CheckUsernameAvailability } from '../../../useCases/CheckUsernameAvailability';
import { Failure, isErr } from '@embroker/shotwell/core/types/Result';
import { PublicPageLayout } from '../PublicPageLayout.view';
import { AppContext } from '../../../../view/AppContext';
import { HeaderView, Link } from '../../../../view/components';
import { PublishSignUpStartedEvent } from '../../../useCases/PublishSignUpStartedEvent';
import { PublicForm } from '../PublicForm';
import { UserOnboardingDetails } from '../../../types/UserOnboardingDetails';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { UnauthenticatedHeaderCtaView } from '../UnauthenticatedHeaderCta.view';
import { PublishUserOrgUserClickEvent } from '../../../useCases/PublishUserOrgUserClickEvent';

interface SignUpFormData {
    emailAddress: EmailAddress;
    organizationName: string;
    website: string;
}

const emailSchemaValidationMessages: { [key: string]: string } = {
    'any.required': 'You must enter your email address',
    'any.empty': 'You must enter your email address',
    'string.email': 'You must enter a valid email address',
};

const emailAlreadyInUseMessage = 'This email is already connected to an Embroker account.';
const invalidEmailMessage = 'Please enter a valid email';

const createSignUpForm = (redirectUrl?: string) => {
    return createForm<SignUpFormData>({
        fields: {
            emailAddress: {
                type: 'email',
                validator: Joi.string(),
                formatValidationError(error) {
                    const reason = error.details.validator || '';
                    return emailSchemaValidationMessages[reason] || error.message;
                },
            },
            organizationName: {
                type: 'text',
                validator: Joi.string().required().trim().min(1),
                formatValidationError(error) {
                    return 'You must enter your organization name';
                },
            },
            website: {
                type: 'text',
                validator: Joi.string().optional().allow('').allow(null),
            },
        },
        formatSubmitErrors(errors) {
            for (const error of errors) {
                const errorCode = error.code;
                switch (errorCode) {
                    case ErrorCode.EmailAlreadyInUse:
                        return [emailAlreadyInUseMessage];
                    case ErrorCode.InvalidEmail:
                        return ['Please enter a valid email'];
                    case ErrorCode.MaxNumberOfSignUpAttemptsExceeded:
                        return [
                            'You have tried to use invalid email address 5 times. You cannot create an account for the next 1 hour.',
                        ];
                }
            }

            container
                .get<Logger>(Log)
                .warn(`User onboarding - Signup page formatSubmitErrors: ${errors}`);

            return ['Sorry, something went wrong. Please try again later.'];
        },
        submit: async ({ emailAddress, organizationName, website }) => {
            const emailAvailabilityResponse = await execute(CheckUsernameAvailability, {
                email: emailAddress,
            });
            if (isErr(emailAvailabilityResponse)) {
                return emailAvailabilityResponse;
            }
            if (!emailAvailabilityResponse.value.available) {
                return Failure(EmailAlreadyInUse(emailAddress));
            }

            const signUpUseCaseResponse = await execute(SignUpUseCase, {
                emailAddress,
                organizationName,
                website,
                certificateInviteToken: null,
                signUpInviteToken: null,
            });

            if (isErr(signUpUseCaseResponse)) {
                return signUpUseCaseResponse;
            }

            UserOnboardingDetails.initializeUserOnboardingContext(redirectUrl);

            return signUpUseCaseResponse;
        },
    });
};

export const hasEmptyRequiredField = (
    formValues: { [key: string]: any },
    optionalFileds: string[] = [],
) => {
    for (const key in formValues) {
        if (formValues.hasOwnProperty(key)) {
            const isOptional = optionalFileds.includes(key);
            if (!isOptional && !formValues[key]) {
                return true;
            }
            if (
                typeof formValues[key] === 'object' &&
                hasEmptyRequiredField(formValues[key], optionalFileds)
            ) {
                return true;
            }
        }
    }
    return false;
};

interface SignUpPageProps {
    readonly userEmail?: EmailAddress;
    readonly organizationName?: string;
    readonly redirectUrl?: string;
}

export function SignUpPage({ userEmail, organizationName, redirectUrl }: SignUpPageProps) {
    const { selectedCoverages } = useContext(AppContext);
    const { url: currentRoute } = useCurrentRoute();
    const { setHeader } = useContext(AppContext);

    const signUpForm = useMemo(() => {
        return createSignUpForm(redirectUrl);
    }, [redirectUrl]);

    const { fields, value, messages, submit } = useForm(signUpForm, {
        emailAddress: userEmail,
        organizationName: organizationName,
    });

    const signInUrl = URI.build('/login', {
        ...currentRoute.query,
        emailAddress: value.emailAddress,
    });

    useEffect(() => {
        execute(PublishSignUpStartedEvent, selectedCoverages);
    }, [selectedCoverages]);

    const [emailAlreadyRegisteredMessage, formMessages] = useMemo(() => {
        const emailFormSubmitErrorMessages: { [key: string]: React.ReactChild } = {
            [emailAlreadyInUseMessage]: (
                <React.Fragment>
                    This email is already connected to an Embroker account.{' '}
                    <Link href={signInUrl}>Sign in</Link>.
                </React.Fragment>
            ),
            [invalidEmailMessage]: invalidEmailMessage,
        };

        return messages.reduce<[Nullable<React.ReactChild[]>, string[]]>(
            (accumulator, message) => {
                const emailError = emailFormSubmitErrorMessages[message];
                if (emailError) {
                    accumulator[0] =
                        accumulator[0] === null ? [emailError] : [...accumulator[0], emailError];
                } else {
                    accumulator[1].push(message);
                }

                return accumulator;
            },
            [null, []],
        );
    }, [signInUrl, messages]);

    const handleBannerClick = async () => {
        await execute(PublishUserOrgUserClickEvent, {
            clickEventName: 'Signup Broker Banner Clicked',
        });
    };

    const emailAddressFormMessages = [
        ...fields.emailAddress.messages,
        emailAlreadyRegisteredMessage,
    ].filter(Boolean) as React.ReactChild[];

    const disableButton = hasEmptyRequiredField(value, ['website']);

    const emailInputProps = {
        ...fields.emailAddress.props,
        autoComplete: 'email',
        hasErrors: fields.emailAddress.props.hasErrors || !!emailAlreadyRegisteredMessage,
    };

    useEffect(() => {
        setHeader(
            <HeaderView>
                <UnauthenticatedHeaderCtaView
                    href="/login"
                    content="Sign in now"
                    dataE2e="customer-public-header-login-link"
                    question="Already have an account?"
                />
            </HeaderView>,
        );
    }, [setHeader]);

    return (
        <PublicPageLayout>
            <StackLayout gap="16">
                <PublicForm
                    title="Let's get your business covered!"
                    subTitle="Welcome! Let's start with the basics so you can get the right coverage for your business."
                    messages={formMessages}
                    submit={submit}
                    submitText="Continue"
                    submitDisabled={disableButton}
                    showPrivacyMessage={false}
                >
                    <StackLayout gap="12">
                        <Form.Field
                            data-e2e="sign-up-company-name"
                            label="Company name"
                            type={fields.organizationName.type}
                            inputProps={{
                                ...fields.organizationName.props,
                            }}
                            messages={fields.organizationName.messages}
                        />
                        <Form.Field
                            data-e2e="sign-up-username"
                            label="Email"
                            type={fields.emailAddress.type}
                            inputProps={{ ...emailInputProps }}
                            messages={emailAddressFormMessages}
                        />
                        <Form.Field
                            data-e2e="sign-up-company-website"
                            label="Company website"
                            type={fields.website.type}
                            inputProps={{
                                ...fields.website.props,
                            }}
                            messages={fields.website.messages}
                        />
                        <Text style="microcopy">
                            By clicking &quot;Continue,&quot; you acknowledge that you understand
                            and agree to Embroker's&nbsp;
                            <Link href="https://www.embroker.com/terms/" target="_blank">
                                Terms
                            </Link>
                            ,&nbsp;
                            <Link href="https://www.embroker.com/privacy/" target="_blank">
                                Privacy Policy
                            </Link>
                            ,&nbsp;and&nbsp;
                            <Link href="https://www.embroker.com/disclosure/" target="_blank">
                                Compensation Disclosure
                            </Link>
                        </Text>
                    </StackLayout>
                </PublicForm>
                <BrokerBanner onClick={handleBannerClick} />
            </StackLayout>
        </PublicPageLayout>
    );
}
