import { StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';

export function BOPChubbFooter() {
    return (
        <StackLayout gap="none">
            <Text color="ui-400" style="body 2" data-e2e="bop-chubb-carrier">
                BOP Carrier: Chubb - insurance is underwritten by ACE Property and Casualty
                Insurance Company
            </Text>
            <Text color="ui-500" style="body 2" data-e2e="bop-chubb-carrier-description">
                ACE Property And Casualty Insurance Company currently has an AM Best A++ (Superior)
                rating with a Financial Size Category of XV.
            </Text>
        </StackLayout>
    );
}
