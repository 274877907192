import { isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { NotificationCta } from '@embroker/shotwell-api/v2/app.spec';

export type NotificationCtaType = NotificationCta['type'];
export const NotificationCtaType = defineValidator<NotificationCtaType>(
    Joi.string().valid('view-policy', 'make-payment', 'download'),
);

const notificatoionRedirectMap: {
    [key in NotificationCtaType]: { endpoint: string; label: string; paramKey?: string };
} = {
    ['download']: { label: 'Download', endpoint: 'certificates' },
    ['view-policy']: { label: 'View Policy', endpoint: 'policies/detail', paramKey: 'policyId' },
    ['make-payment']: { label: 'Make Payment', endpoint: '' },
};

export const NotificationAction = {
    ...defineValidator<NotificationCta>(
        Joi.object({
            type: NotificationCtaType.schema,
            value: Joi.string(),
        }),
    ),
    create(cta: NotificationCta) {
        return NotificationAction.validate(cta);
    },
    getRedirect(cta?: NotificationCta): { label: string; url: string } | undefined {
        if (!cta) {
            return undefined;
        }

        const action = this.create(cta);

        if (!isOK(action)) {
            return undefined;
        }

        const { label, endpoint, paramKey } = notificatoionRedirectMap[action.value.type];

        const url = URI.build('/', endpoint, {
            ...(paramKey !== undefined && { [paramKey]: cta.value }),
        });

        return { label, url };
    },
};
