import { State } from '@embroker/shotwell/core/types/StateList';
import { Modal, ModalActions, ModalState } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { WarrantyAndFraudModalContent } from './modals/WarrantyAndFraudModalContent';

export interface WarrantyAndFraudModalProps {
    stateAndActions: ModalState & ModalActions;
    fullName?: string;
    title?: string;
    state?: State;
}

export function WarrantyAndFraudModal({
    stateAndActions,
    fullName,
    title,
    state,
}: WarrantyAndFraudModalProps) {
    return (
        <Modal {...stateAndActions} size="large">
            <WarrantyAndFraudModalContent fullName={fullName} title={title} state={state} />
        </Modal>
    );
}
