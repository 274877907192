import { ClaimStatusCodeListItem } from '@embroker/shotwell-api/enums';
import { defineEntityValidator, entity, Entity } from '@embroker/shotwell/core/entity/Entity';
import { Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { Joi } from '@embroker/shotwell/core/validation/schema';

export interface Claimant extends Entity {
    /**
     * Name of the claimant
     */
    name: string;
}

export const Claimant = entity<Claimant>({
    validator: defineEntityValidator<Claimant>({
        name: Joi.string(),
    }),
});

export interface Claim extends Entity {
    /**
     * Claim number
     */
    claimNumber: string;

    /**
     * Loss date
     */
    lossDate: Nullable<Date>;

    /**
     * Reported date
     */
    reportedDate: Date;

    /**
     * Closed date
     */
    closedDate: Nullable<Date>;

    /**
     * Valuation date
     */
    valuationDate: Nullable<Date>;

    /**
     * Status
     */
    status: Nullable<ClaimStatusCodeListItem>;

    /**
     * Amount paid
     */
    amountPaid: Nullable<Money>;

    /**
     * Adjuster name
     */
    adjusterName: Nullable<string>;

    /**
     * Adjuster phone number
     */
    adjusterPhoneNumber: Nullable<string>;

    /**
     * Reserved amount
     */
    reservedAmount: Nullable<Money>;

    /**
     * Cause of loss
     */
    causeOfLoss: Nullable<string>;

    /**
     * List of claimants
     */
    claimantList: Claimant[];
}

export const Claim = entity<Claim>({
    validator: defineEntityValidator<Claim>({
        claimNumber: Joi.string(),
        lossDate: Joi.date().allow(null),
        reportedDate: Joi.date(),
        closedDate: Joi.date().allow(null),
        valuationDate: Joi.date().allow(null),
        status: Joi.string().allow('', null),
        amountPaid: Money.schema.allow(null),
        adjusterName: Joi.string().allow('', null),
        adjusterPhoneNumber: Joi.string().allow('', null),
        reservedAmount: Money.schema.allow(null),
        causeOfLoss: Joi.string().allow('', null),
        claimantList: Joi.array().items(Claimant.schema),
    }),
});
