import { Loader, ModalActions, ModalState, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';

export interface AnalyzingDataProps {
    modal: ModalState & ModalActions;
    hideModal: Function;
}

export const AnalyzingDataModal = ({ modal }: AnalyzingDataProps) => {
    return (
        <Loader
            dismissible
            {...modal}
            heading={<Text style="heading 4">Analyzing uploaded policy...</Text>}
            subheading="This could take a few minutes. If you don't want to wait, feel free to close this window and finish your other tasks in the meantime."
        />
    );
};
