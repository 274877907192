import { Immutable } from '@embroker/shotwell/core/types';
import { StackLayout, Text } from '@embroker/ui-toolkit/v2';
import { format } from 'date-fns';
import React from 'react';
import { CoverageType } from '../../../types/CoverageRestriction';
import { ESPCoverageType } from '../ESPCoverage/limitRetentionOptions';
import { ESPCoverageRateItem } from '../../../types/ESPRate';
import { Endorsement } from '../../../types/EndorsementList';

interface ESPEndorsementListProps {
    effectiveDate: Date;
    coverages: Immutable<Array<ESPCoverageRateItem>>;
    endorsements: Endorsement[];
}

export function ESPEndorsementList({
    effectiveDate,
    coverages,
    endorsements,
}: ESPEndorsementListProps) {
    const selectedCoverageTypes = coverages
        .filter(({ selected }) => selected)
        .map(({ coverageType }) => mapESPCoverageTypeToCoverageType(coverageType));

    const endorsementsToDisplay = endorsements.filter((endorsement) => {
        if (!endorsement.allowedCoverages) {
            return false;
        }

        // check if there is intersection between allowed and selected coverages
        return (
            endorsement.allowedCoverages.filter((allowedCoverage) =>
                selectedCoverageTypes.includes(allowedCoverage),
            ).length > 0
        );
    });

    return (
        <StackLayout>
            {endorsementsToDisplay.length > 0 && <Text style="heading 4">Endorsements</Text>}
            <ul>
                {endorsementsToDisplay
                    .map((endorsement) => ({
                        id: endorsement.id,
                        name: endorsement.name,
                        displayName: endorsement.displayName,
                        coverages: endorsement.allowedCoverages,
                        inputListSerialized:
                            endorsement.inputList.length > 0
                                ? `( ${endorsement.inputList
                                      .map((input) =>
                                          input.value === 'inception'
                                              ? format(effectiveDate, 'MM/dd/yyyy')
                                              : input.value,
                                      )
                                      .join(' , ')} )`
                                : '',
                    }))
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((endorsement) => (
                        <li key={endorsement.id}>
                            <Text style="body 2">
                                {endorsement.displayName
                                    ? endorsement.displayName
                                    : endorsement.name}{' '}
                                {endorsement.inputListSerialized}
                            </Text>
                        </li>
                    ))}
            </ul>
        </StackLayout>
    );
}

function mapESPCoverageTypeToCoverageType(coverageType: ESPCoverageType): CoverageType {
    switch (coverageType) {
        case 'do':
            return 'ShoppingCoverageCodeListDirectorsAndOfficers';
        case 'fiduciary':
            return 'ShoppingCoverageCodeListFiduciary';
        case 'epli':
            return 'ShoppingCoverageCodeListEmploymentPractices';
        case 'eoCyber':
            return 'ShoppingCoverageCodeListCyber';
        default:
            return 'ShoppingCoverageCodeListTechSplit';
    }
}
