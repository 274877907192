import { inject, injectable } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { TasksRepository } from '../../tasks/repositories';
import { Quote } from '../entities/Quote';
import { QuoteRepository } from '../repositories/QuoteRepository';
import { CoverageListInfo } from '../types/PremiumFinanceIndicationInfo';

export interface GetPremiumFinanceIndicationQuoteRequest {
    coverageListInfo: CoverageListInfo;
    numberOfInstallments: number;
    startDate: Date;
    abortSignal: AbortSignal;
}

export interface GetPremiumFinanceIndicationQuoteResponse {
    quote: Immutable<EntityProps<Quote>>;
}

export interface GetPremiumFinanceQuoteIndication extends UseCase {
    execute(
        request: GetPremiumFinanceIndicationQuoteRequest,
    ): AsyncResult<GetPremiumFinanceIndicationQuoteResponse, InvalidArgument | OperationFailed>;
}

@injectable()
class GetPremiumFinanceQuoteIndicationUseCase extends UseCase {
    public static type = Symbol('Payments/GetPremiumFinanceQuoteIndication');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(QuoteRepository) private quoteRepository: QuoteRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        data: GetPremiumFinanceIndicationQuoteRequest,
    ): AsyncResult<GetPremiumFinanceIndicationQuoteResponse, OperationFailed | InvalidArgument> {
        const result = await this.quoteRepository.getQuoteIndication({
            coverageListInfo: data.coverageListInfo,
            startDate: data.startDate,
            numberOfInstallments: data.numberOfInstallments,
        });

        if (isErr(result)) {
            return result;
        }

        const maxPollingRetries = 20;
        const pollingRetryIntervalInMilliseconds = 1000;

        const taskStatusResult = await this.tasksRepository.pollForTaskStatus(
            result.value,
            data.abortSignal,
            maxPollingRetries,
            pollingRetryIntervalInMilliseconds,
        );

        if (isErr(taskStatusResult)) {
            return Failure(
                OperationFailed({
                    message: `Get task status failed for task with id: ${result.value}`,
                    errors: taskStatusResult.errors,
                }),
            );
        }

        const quoteOptionResult = await GetPremiumFinanceQuoteIndicationUseCase.toQuoteOptions(
            taskStatusResult.value,
        );
        if (isErr(quoteOptionResult)) {
            return quoteOptionResult;
        }

        const response: GetPremiumFinanceIndicationQuoteResponse = {
            quote: quoteOptionResult.value,
        };

        return Success(response);
    }

    private static async toQuoteOptions(
        quoteJson: string,
    ): AsyncResult<Immutable<EntityProps<Quote>>, OperationFailed> {
        let quoteObject;
        try {
            quoteObject = JSON.parse(quoteJson);
        } catch (e) {
            return Failure(
                OperationFailed({
                    message: `Unable to parse ${quoteJson}`,
                }),
            );
        }
        const quoteCreateResult = await Quote.create({
            quoteNumber: quoteObject.QuoteNumber,
            installmentAmount: Money.tryFromFloat(quoteObject.InstallmentAmount),
            totalPremium: Money.tryFromFloat(quoteObject.TotalPremium),
            totalPaymentAmount: Money.tryFromFloat(quoteObject.TotalPaymentAmount),
            downPayment: Money.tryFromFloat(quoteObject.DownPayment),
            downPayCreditCardUrl: quoteObject.DownPayCcUrl,
            downPayBankUrl: quoteObject.DownPayBankUrl,
            pdfFileKey: quoteObject.PdfFileKey,
            numberOfInstallments: quoteObject.NumberOfInstallments,
            annualPercentageRate: Math.round(((quoteObject.APR + Number.EPSILON) * 100) / 100),
        });

        if (isErr(quoteCreateResult)) {
            return Failure(
                OperationFailed({
                    message: `Unable to create Quote entity from ${quoteObject}`,
                    errors: quoteCreateResult.errors,
                }),
            );
        }

        return Success(quoteCreateResult.value as Immutable<EntityProps<Quote>>);
    }
}

export const GetPremiumFinanceQuoteIndication: UseCaseClass<GetPremiumFinanceQuoteIndication> =
    GetPremiumFinanceQuoteIndicationUseCase;
