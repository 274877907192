import React from 'react';
import { TextButton } from '@embroker/ui-toolkit/v2';
import { PrintElement, HideInPrint } from '../../../../view/components/PrintElement';

interface TransactionsAgreement {
    agreement: React.Ref<HTMLDivElement>;
}

function TransactionsAgreementPrintLink({ agreement }: TransactionsAgreement) {
    return (
        <HideInPrint>
            Please{' '}
            <PrintElement elementToPrint={agreement}>
                <TextButton as="a">print or download a copy of this disclosure</TextButton>
            </PrintElement>{' '}
            for your records after reading it.
        </HideInPrint>
    );
}

export { TransactionsAgreementPrintLink };
