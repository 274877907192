import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { CarrierNotFound } from '../../errors';
import { ExcessCarrier } from '../../types/ExcessCarrier';

export interface ExcessCarrierRepository {
    getCarrier(id: UUID): AsyncResult<ExcessCarrier, CarrierNotFound | InvalidArgument>;
    getCarriers(
        searchTerm: string,
    ): AsyncResult<ExcessCarrier[], InvalidArgument | OperationFailed>;
}

export const ExcessCarrierRepository = Symbol('ExcessCarrierRepository');
