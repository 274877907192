import { errorCodes, isError } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { ErrorLike, ErrorObject } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';

export const ErrorCode = errorCodes({
    /**
     * Annual tech fee not applicable
     */
    InvalidAnnualTechFee: 0x2400,
    DocGenFailed: 0x2401,
    RequoteFailed: 0x2402,
    HigherLimitsApprovalNotNeeded: 0x2403,
    QuoteOptionsApprovalNeeded: 0x2404,
    QuestionnaireParsingFailed: 0x2405,
    ApplicationFetchFailed: 0x2406,
});

export type InvalidAnnualTechFee = ErrorObject<typeof ErrorCode.InvalidAnnualTechFee, {}>;

export function InvalidAnnualTechFee(): Immutable<InvalidAnnualTechFee> {
    return {
        name: 'InvalidAnnualTechFee',
        code: ErrorCode.InvalidAnnualTechFee,
        message:
            'Annual technology fee on the current quote is no longer valid. ' +
            'Please requote and try again.',
        details: {},
    };
}

export type DocGenFailed = ErrorObject<
    typeof ErrorCode.DocGenFailed,
    {
        /**
         * Optional list of unhandled errors.
         */
        errors: Immutable<ErrorLike[]>;
    }
>;

export function DocGenFailed({
    errors = [],
}: {
    errors?: Immutable<ErrorLike[]>;
}): Immutable<DocGenFailed> {
    return {
        name: 'DocGenFailed',
        code: ErrorCode.DocGenFailed,
        message: 'Document generation failed.',
        details: { errors },
    };
}

export function isDocGenError(error: ErrorLike) {
    return isError(ErrorCode.DocGenFailed, error);
}

export type RequoteFailed = ErrorObject<
    typeof ErrorCode.RequoteFailed,
    {
        /**
         * Optional list of unhandled errors.
         */
        errors: Immutable<ErrorLike[]>;
    }
>;

export function RequoteFailed({
    errors = [],
}: {
    errors?: Immutable<ErrorLike[]>;
}): Immutable<RequoteFailed> {
    return {
        name: 'RequoteFailed',
        code: ErrorCode.RequoteFailed,
        message: 'Requote failed.',
        details: { errors },
    };
}

export type HigherLimitsApprovalNotNeeded = ErrorObject<
    typeof ErrorCode.HigherLimitsApprovalNotNeeded,
    {}
>;

export function HigherLimitsApprovalNotNeeded(): Immutable<HigherLimitsApprovalNotNeeded> {
    return {
        name: 'HigherLimitsApprovalNotNeeded',
        code: ErrorCode.HigherLimitsApprovalNotNeeded,
        message: 'Higher limits approval is not needed for the application.',
        details: {},
    };
}

export type QuoteOptionsApprovalNeeded = ErrorObject<
    typeof ErrorCode.QuoteOptionsApprovalNeeded,
    {}
>;

export function QuoteOptionsApprovalNeeded(): Immutable<QuoteOptionsApprovalNeeded> {
    return {
        name: 'QuoteOptionsApprovalNeeded',
        code: ErrorCode.QuoteOptionsApprovalNeeded,
        message: 'Quote options approval is needed for the application.',
        details: {},
    };
}

export type QuestionnaireParsingFailed = ErrorObject<
    typeof ErrorCode.QuestionnaireParsingFailed,
    {}
>;

export function QuestionnaireParsingFailed(qData: string): Immutable<QuestionnaireParsingFailed> {
    return {
        name: 'QuestionnaireParsingFailed',
        code: ErrorCode.QuestionnaireParsingFailed,
        message: 'Failed parsing questionnaire data.',
        details: { qData },
    };
}

export type ApplicationFetchFailed = ErrorObject<typeof ErrorCode.ApplicationFetchFailed, {}>;

export function ApplicationFetchFailed(applicationId: UUID): Immutable<ApplicationFetchFailed> {
    return {
        name: 'ApplicationFetchFailed',
        code: ErrorCode.ApplicationFetchFailed,
        message: 'Failed to fetch application for renewal.',
        details: { applicationId },
    };
}
