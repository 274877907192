import { Money } from '@embroker/shotwell/core/types/Money';
import { QuoteOptions } from './QuoteOptions';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface Quote {
    totalPremium: Money;
    options: QuoteOptions;
    accepted: boolean;
}

export const Quote = {
    ...defineValidator<Quote>({
        totalPremium: Money.schema,
        options: QuoteOptions.schema,
        accepted: Joi.boolean(),
    }),
    create(quote: Quote) {
        return Quote.validate(quote);
    },
};
