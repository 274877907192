import { ContainerModule } from '@embroker/shotwell/core/di';
import { Module } from '../Module';
import { ReferQuote } from './useCases/ReferQuote';
import { SignQuote } from './useCases/SignQuote';
import { UnsignQuote } from './useCases/UnsignQuote';
import { routes } from './view/routes';
import { CancelApplication } from './useCases/CancelApplication';
import { QuoteCommonRepository } from './repositories';
import { APIQuoteCommonRepository } from './repositories/QuoteCommonRepository';
import { PublishQuoteUserClickEvent } from './useCases/PublishQuoteUserClickEvent';

export const QuoteModule: Module = {
    container: new ContainerModule((bind) => {
        bind<SignQuote>(SignQuote.type).to(SignQuote).inSingletonScope();
        bind<UnsignQuote>(UnsignQuote.type).to(UnsignQuote).inSingletonScope();
        bind<ReferQuote>(ReferQuote.type).to(ReferQuote).inSingletonScope();
        bind<PublishQuoteUserClickEvent>(PublishQuoteUserClickEvent.type)
            .to(PublishQuoteUserClickEvent)
            .inSingletonScope();
        bind<QuoteCommonRepository>(QuoteCommonRepository)
            .to(APIQuoteCommonRepository)
            .inSingletonScope();
        bind<CancelApplication>(CancelApplication.type).to(CancelApplication).inSingletonScope();
    }),
    routes,
};
