import { UUID } from '@embroker/shotwell/core/types/UUID';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { CoverageRestriction } from './CoverageRestriction';

export interface InsuranceApplicationRestriction {
    insuranceApplicationId: UUID;
    coverageRestrictions: CoverageRestriction[];
    areManualRestrictionsApplied?: boolean;
}

export const InsuranceApplicationRestriction = {
    ...defineValidator<InsuranceApplicationRestriction>({
        insuranceApplicationId: UUID.schema,
        coverageRestrictions: Joi.array().items(CoverageRestriction.schema),
        areManualRestrictionsApplied: Joi.boolean().optional(),
    }),
    create(restriction: InsuranceApplicationRestriction) {
        return InsuranceApplicationRestriction.validate(restriction);
    },
};
