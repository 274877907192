import { inject } from '@embroker/shotwell/core/di';
import { OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ApplicationRepository } from '../repositories/ApplicationRepository';
import { URI } from '@embroker/shotwell/core/types/URI';

/**
 * Request data for PrintBinder use case
 */
export interface PrintBinderRequest {
    applicationId: UUID;
}

export interface PrintBinder extends UseCase {
    execute(request: PrintBinderRequest): AsyncResult<URI, OperationFailed>;
}

class PrintBinderUseCase extends UseCase implements PrintBinder {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Shopping/PrintBinder');

    /**
     * Constructor for PrintBinder use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param applicationRepository used to store/fetch application info.
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes PrintBinder use case
     * Input is of PrintBinderRequest type
     * @returns InvalidArgument if there is no such insurance application
     * @returns OperationFailed if error occured
     */
    public async execute(request: PrintBinderRequest): AsyncResult<URI, OperationFailed> {
        const response = await this.applicationRepository.printBinder(request.applicationId);

        if (isErr(response)) {
            return response;
        }

        return Success(response.value);
    }
}

export const PrintBinder: UseCaseClass<PrintBinder> = PrintBinderUseCase;
