import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export type CyberLimit = 500_000 | 1_000_000 | 2_000_000;
export type CyberRetention = 2_500 | 5_000 | 7_500 | 10_000 | 25_000 | 50_000 | 75_000;
export type CyberLevel = 'plus' | 'standard';

export interface CyberQuoteOptions {
    readonly effectiveDate: Date;
    readonly limit: CyberLimit;
    readonly retention: CyberRetention;
    readonly level: CyberLevel;
    readonly previousLimit?: CyberLimit;
    readonly previousRetention?: CyberRetention;
}

export const CyberQuoteOptions = {
    ...defineValidator<CyberQuoteOptions>({
        effectiveDate: Joi.date().required(),
        limit: Joi.number().required(),
        retention: Joi.number().required(),
        level: Joi.string().required(),
        previousLimit: Joi.number().optional(),
        previousRetention: Joi.number().optional(),
    }),
    create(cyberQuoteOptions: CyberQuoteOptions) {
        return CyberQuoteOptions.validate(cyberQuoteOptions);
    },
};
