import type * as APITypes from '@embroker/shotwell-api/app';
import { API } from '@embroker/shotwell-api/app';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, Failure, isErr, Result, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ExcessRestrictionRepository } from '.';
import { ExportUWReviewFromAPIResponseFailed, UwReviewFetchFailed } from '../../errors';
import { InsuranceApplicationRestriction } from '../../types/InsuranceApplicationRestriction';
import { makeExcessAdditionalQuestions } from './excessMappers';
import { EndorsementList } from '../../types/ExcessEndorsement';
import { getLogger } from '../../view/logger';
import { AdditionalQuestions } from '../../types/AdditionalQuestions';

@injectable()
export class APIEmbrokerExcessRestrictionRepository implements ExcessRestrictionRepository {
    public async get(
        insuranceApplicationId: UUID,
    ): AsyncResult<
        InsuranceApplicationRestriction,
        InvalidArgument | UwReviewFetchFailed | ExportUWReviewFromAPIResponseFailed
    > {
        const getUWReviewResult = await API.request('shopping/get_uw_review', {
            insurance_application_id: insuranceApplicationId,
        });

        if (
            isErr(getUWReviewResult) ||
            !getUWReviewResult.value ||
            !getUWReviewResult.value.uw_review
        ) {
            return Failure(UwReviewFetchFailed());
        }

        const restriction = fromGetUWReviewApiResponse(getUWReviewResult.value.uw_review);

        if (isErr(restriction)) {
            return Failure(ExportUWReviewFromAPIResponseFailed(restriction.errors));
        }

        return Success(restriction.value);
    }
}

function fromGetUWReviewApiResponse(
    apiRestriction?: Immutable<APITypes.UwReview>,
): Result<InsuranceApplicationRestriction> {
    if (!apiRestriction || !apiRestriction?.data?.additional_questions?.excess_tech_e_o_cyber) {
        return Failure(OperationFailed({ message: 'Excess restriction is undefined' }));
    }

    const additionalQuestions = makeExcessAdditionalQuestions(
        apiRestriction.data,
    ) as AdditionalQuestions;
    if (!additionalQuestions) {
        return Failure(OperationFailed({ message: 'Excess restriction is undefined' }));
    }

    const cyberCoverage =
        apiRestriction.allowed_app_quote_options.allowed_coverage_quote_options.find(
            ({ coverage_type }) => coverage_type == 'ShoppingCoverageCodeListCyber',
        );

    const defaultMaxLimit = 5 * 1000 * 1000;
    const maxLimit = cyberCoverage
        ? cyberCoverage.limit.max.MultiValueLimitNameListSingleLimit
        : defaultMaxLimit;

    const endorsements = mapToEndorsementList(apiRestriction.endorsements);

    return InsuranceApplicationRestriction.create({
        insuranceApplicationId: apiRestriction.insurance_application_id,
        minimumStartDate: apiRestriction.allowed_app_quote_options.minimum_start_date
            ? new Date(apiRestriction.allowed_app_quote_options.minimum_start_date)
            : new Date(Date.now()),
        areManualRestrictionsApplied: apiRestriction.are_manual_restrictions_applied ?? false,
        additionalQuestions: additionalQuestions,
        maxLimit: maxLimit,
        endorsementList: endorsements,
    });
}

function mapToEndorsementList(
    apiEndorsementList?: Immutable<APITypes.UwEndorsement[]>,
): Immutable<EndorsementList> {
    if (apiEndorsementList === undefined) {
        return [];
    }

    const endorsements = apiEndorsementList.map(
        (endorsement: Immutable<APITypes.UwEndorsement>) => {
            return {
                id: endorsement.endorsement_id,
                name: endorsement.endorsement_name ?? '',
                formNumber: endorsement.endorsement_form_number ?? '',
            };
        },
    );

    const endorsementListResult = EndorsementList.create(endorsements);

    if (isErr(endorsementListResult)) {
        getLogger().error(endorsementListResult.errors);
        return [];
    }

    return endorsementListResult.value;
}
