import { QuoteExtended } from '@embroker/shotwell-api/app';
import { JSONSerdes } from '@embroker/shotwell/core/encoding';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, Failure, isErr } from '@embroker/shotwell/core/types/Result';
import { startOfToday } from 'date-fns';
import { LPLQuote } from '../../../quote/lpl/entities/LPLQuote';
import { toLplQuoteOptions } from '../../../quote/lpl/repositories/LPLQuoteRepository/APILPLQuoteRepository';
import { QuoteExpiration } from '../../../quote/types/QuoteExpiration';
import {
    QuotingEngineLPLEverest,
    ShoppingCoverageCodeListProfessionalLiability,
} from '@app/shopping/types/enums';
import {
    BundleCoverageTypeEnum,
    BundleQuoteCoverage,
    BundleQuoteCoverageMetadata,
} from '../../types/BundleQuoteCoverage';
import { getAllowedHigherLimit, toBundleQuoteCoverage } from '../bundleMappingFunctions';
import { LPLQuestionnaireData } from './LPLQuestionnaireData';
import { toLplQuoteDetails } from './mappingFunctions';

export async function buildLPLCoverage(
    lplQuoteExtended: Immutable<QuoteExtended>,
    isBroker: boolean,
    omitEffectiveDateValidation = false,
): AsyncResult<BundleQuoteCoverage<LPLQuote>, OperationFailed | InvalidArgument> {
    if (!lplQuoteExtended) {
        return Failure(OperationFailed({ message: 'lplQuoteExtended is null or undefined' }));
    }
    const lplCoverageMetadata = getLPLCoverageMetadata();

    const questionnaireDataResult = JSONSerdes.deserialize(lplQuoteExtended.questionnaire_data);
    if (isErr(questionnaireDataResult)) {
        return Failure(
            InvalidArgument({
                argument: 'questionnaire_data',
                value: lplQuoteExtended.questionnaire_data,
            }),
        );
    }

    const lplQuestionnaireData = LPLQuestionnaireData.create(
        questionnaireDataResult.value as LPLQuestionnaireData,
    );
    if (isErr(lplQuestionnaireData)) {
        return Failure(
            InvalidArgument({
                argument: 'LPL questionnaire data',
                value: lplQuestionnaireData.errors,
            }),
        );
    }

    if (!lplQuoteExtended.quote) {
        return toBundleQuoteCoverage<LPLQuote>(
            BundleCoverageTypeEnum.LPLBundleCoverage,
            lplQuoteExtended.app_status,
            lplCoverageMetadata,
            lplQuestionnaireData.value,
            lplQuoteExtended.app_valid_until,
        );
    }
    if (!lplQuoteExtended.quote.details.lpl_everest) {
        return Failure(OperationFailed({ message: 'lplQuoteDetails is null or undefined' }));
    }
    if (!lplQuoteExtended.quote.options.lpl_everest) {
        return Failure(OperationFailed({ message: 'lplQuoteOptions is null or undefined' }));
    }
    const lplQuoteOptions = toLplQuoteOptions(lplQuoteExtended.quote.options.lpl_everest);
    const higherLimit = getAllowedHigherLimit(
        ShoppingCoverageCodeListProfessionalLiability,
        lplQuoteExtended.uw_review?.allowed_app_quote_options.allowed_coverage_quote_options,
    );
    const lplQuote = await LPLQuote.create({
        isIndication: lplQuoteExtended.quote.is_indication,
        id: lplQuoteExtended.quote.id,
        applicationId: lplQuoteExtended.quote.app_id,
        details: toLplQuoteDetails(lplQuoteExtended.quote.details.lpl_everest),
        options: lplQuoteOptions,
        status: 'draft',
        totalPayable: lplQuoteExtended.quote.total_payable,
        totalPremium: lplQuoteExtended.quote.total_premium,
        premiumRange: lplQuoteExtended.quote.premium_range,
        higherLimit: higherLimit,
        annualTechnologyFee: lplQuoteExtended.quote.annual_technology_fee,
        daysToExpire: QuoteExpiration.getDaysLeftUntilExpiration({
            quotingEngine: QuotingEngineLPLEverest,
            applicationStatus: lplQuoteExtended.app_status,
            isBroker: isBroker,
            quoteEffectiveDate: lplQuoteExtended.quote.options.lpl_everest.effective_date,
            today: startOfToday(),
            validUntil: lplQuoteExtended.app_valid_until || null,
            omitEffectiveDateValidation,
        }),
        quoteNumber: lplQuoteExtended.quote.quote_number,
        fees: lplQuoteExtended.quote.fees || undefined,
        fileKey: lplQuoteExtended.quote.file_key || undefined,
    });
    if (isErr(lplQuote)) {
        return Failure(InvalidArgument({ argument: 'LPL coverage', value: lplQuote.errors }));
    }

    return toBundleQuoteCoverage<LPLQuote>(
        BundleCoverageTypeEnum.LPLBundleCoverage,
        lplQuoteExtended.app_status,
        lplCoverageMetadata,
        lplQuestionnaireData.value,
        lplQuoteExtended.app_valid_until,
        lplQuote.value,
    );
}

export const getLPLCoverageMetadata = (): BundleQuoteCoverageMetadata => {
    return {
        title: 'Lawyers Professional Liability',
        description:
            'Protects your practice from malpractice claims and other risks, for yourself and your partners, as well as your employees, contractors and Of Counsel attorneys.',
        icon: 'law',
        name: 'lpl',
    };
};
