import React from 'react';
import { CheckBox, Form } from '@embroker/ui-toolkit/v2';
import {
    ComplexFormFieldViewProps,
    getFormFieldProps,
    getInputProps,
} from '../../types/ComplexFieldTypes';

export function assertInputValueAsBoolean(input: unknown): input is boolean {
    return typeof input === 'boolean';
}

export function BooleanCheckboxFieldView(complexFormFieldViewProps: ComplexFormFieldViewProps) {
    const { questionProps, inputFieldProps, onComplexFieldChange } = complexFormFieldViewProps;
    const { key } = questionProps;
    const formFieldProps = getFormFieldProps(questionProps);
    const { value, ...inputProps } = getInputProps(questionProps, inputFieldProps);
    const inputValue = assertInputValueAsBoolean(value) ? value : undefined;

    return (
        <Form.Field {...formFieldProps}>
            <CheckBox
                {...inputProps}
                checked={Boolean(inputValue)}
                onChange={(event: any) => {
                    onComplexFieldChange(key, event.target.checked);
                }}
            >
                {formFieldProps.label}
            </CheckBox>
        </Form.Field>
    );
}
