import React from 'react';

import { Vehicle } from '../../../types/Vehicle';
import { Filter, FilterOption, StackLayout, Text } from '@embroker/ui-toolkit/v2';

interface ChooseVehicleProps {
    vehicleList: Vehicle[];
    selectedVehicle: Vehicle;
    onChange(vehicleVin: string): void;
}

export function ChooseVehicle({ vehicleList, selectedVehicle, onChange }: ChooseVehicleProps) {
    if (vehicleList == null || vehicleList.length == 0) {
        return <div />;
    }

    const vehicleDropDownItems: FilterOption<string>[] = vehicleList.map((item) => {
        return {
            value: item.vin,
            title: item.name,
        } as FilterOption<string>;
    });

    return (
        <StackLayout gap="none">
            <Text style="heading 5">Vehicles</Text>
            <Filter
                options={vehicleDropDownItems}
                value={selectedVehicle.vin}
                title="Choose a vehicle"
                onChange={(event) => {
                    onChange(event.target.value);
                }}
            />
        </StackLayout>
    );
}
