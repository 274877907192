import { UUID } from '@embroker/shotwell/core/types/UUID';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { Form, FormData } from '@embroker/shotwell/view/hooks/useForm';
import {
    Button,
    ColumnLayout,
    HeroBanner,
    PageLayout,
    SidebarLayout,
    Spinner,
    StackLayout,
    StickyLayout,
    Text,
    useModal,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { Quote } from '../../entities/Quote';
import { LeavingConfirmationModal } from './LeavingConfirmationModal';

export interface QuoteOptionsComponentProps<T extends FormData, Q extends Quote> {
    applicationId: UUID;
    isSubmitting: boolean;
    quote: Q;
    fields: Form<T>['fields'];
    value: Form<T>['value'];
    setValue: Form<T>['setValue'];
    trigger: Form<T>['trigger'];
}

export type QuoteOptionsComponent<T extends FormData, Q extends Quote> = React.FC<
    QuoteOptionsComponentProps<T, Q>
>;

export interface QuoteBreakdownComponentProps<Q extends Quote> {
    quote: Q;
}

export type QuoteBreakdownComponent<Q extends Quote> = React.FC<QuoteBreakdownComponentProps<Q>>;

export interface EstimateLandingPageProps<T extends FormData, Q extends Quote> {
    title: string;
    applicationId: UUID;
    quote?: Q;
    optionsForm: Form<T>;
    quoteOptions: QuoteOptionsComponent<T, Q>;
    quoteBreakdown: QuoteBreakdownComponent<Q>;
}

export function EstimateLandingPage<T extends FormData, Q extends Quote>({
    title,
    applicationId,
    quote,
    optionsForm,
    quoteOptions: QuoteOptions,
    quoteBreakdown: QuoteBreakdown,
}: EstimateLandingPageProps<T, Q>) {
    const { fields, value, setValue, submit, trigger, status } = optionsForm;

    const handleSubmitForReview = () => {
        submit();
    };

    const leavingConfirmationModal = useModal();

    const handleConfirmLeaving = () => {
        leavingConfirmationModal.hide();
        navigate('/summary');
    };

    const handleLeaving = () => {
        leavingConfirmationModal.show();
    };

    const { navigate } = useNavigation();

    const isSubmitting = status === 'submitting';

    if (!quote) {
        return <Spinner />;
    }

    return (
        <React.Fragment>
            <HeroBanner icon="rocket" onDismiss={handleLeaving}>
                <h1>
                    <Text as="span" style="heading 1">
                        Your {title} Estimate
                    </Text>
                </h1>
            </HeroBanner>
            <PageLayout.Section>
                <SidebarLayout sidebar="right" gap="32">
                    <React.Fragment>
                        <QuoteOptions
                            applicationId={applicationId}
                            isSubmitting={isSubmitting}
                            quote={quote}
                            fields={fields}
                            value={value}
                            setValue={setValue}
                            trigger={trigger}
                        />
                        <SidebarLayout.MobileFooter>
                            <ColumnLayout>
                                <StackLayout gap="8">
                                    <Text>Total amount: </Text>
                                    <Text style="heading 5">
                                        <MoneyDisplay value={quote.totalPremium} />
                                    </Text>
                                </StackLayout>

                                <SidebarLayout.Link panelIndex={1}>
                                    Show Coverages
                                </SidebarLayout.Link>
                            </ColumnLayout>
                        </SidebarLayout.MobileFooter>
                    </React.Fragment>
                    <StickyLayout gap="32">
                        <StackLayout gap="32">
                            <QuoteBreakdown quote={quote} />
                            <Button disabled={isSubmitting} onClick={handleSubmitForReview}>
                                Submit for review
                            </Button>
                        </StackLayout>
                    </StickyLayout>
                </SidebarLayout>
            </PageLayout.Section>
            {isSubmitting ? <Spinner /> : null}
            <LeavingConfirmationModal
                modal={leavingConfirmationModal}
                onConfirmLeaving={handleConfirmLeaving}
            />
        </React.Fragment>
    );
}
