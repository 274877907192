import { URI } from '@embroker/shotwell/core/types/URI';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { Avatar, StackLayout, Summary, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { Link } from '../../../../view/components';
import { BrokerageOrganizationInfo } from '../../../types/BrokerageOrganizationInfo';

export interface ClientInfoTableProps {
    organizationInfo: BrokerageOrganizationInfo;
}

export function ClientInfoTable({ organizationInfo }: ClientInfoTableProps) {
    return (
        <Summary
            media={<Avatar round size="large" initials={organizationInfo.name ?? 'N/A'} />}
            title={
                <StackLayout gap="none">
                    <Text style="heading 5">{organizationInfo.name}</Text>
                    <Text style="microcopy">
                        Broker: {organizationInfo.managingBrokerName ?? 'N/A'}
                    </Text>
                </StackLayout>
            }
        >
            <Summary.Section>
                <Text style="microcopy">{organizationInfo.address}</Text>
                <Text style="microcopy">
                    {organizationInfo.city}, {organizationInfo.state} {organizationInfo.zip}
                </Text>
            </Summary.Section>
            <Summary.Section>
                <Text style="microcopy">
                    {organizationInfo.email ? (
                        <Link href={`mailto:${organizationInfo.email}`} target="_blank">
                            {organizationInfo.email}
                        </Link>
                    ) : (
                        'N/A'
                    )}
                </Text>
            </Summary.Section>
            <Summary.Section>
                <Text style="microcopy">
                    {organizationInfo.website ? (
                        <Link href={URI.sanitize(organizationInfo.website)} target="_blank">
                            {organizationInfo.website}
                        </Link>
                    ) : (
                        'N/A'
                    )}
                </Text>
                <Text style="microcopy">{organizationInfo.phoneNumber}</Text>
            </Summary.Section>
            <Summary.Section>
                <Text style="microcopy">{organizationInfo.industry}</Text>
                <Text style="microcopy">
                    {organizationInfo.employeeCount !== undefined
                        ? `${organizationInfo.employeeCount} employee${
                              organizationInfo.employeeCount === 1 ? '' : 's'
                          }`
                        : 'N/A'}
                </Text>
            </Summary.Section>
            <Summary.Section>
                <Text style="microcopy">Annual Revenue:</Text>
                <Text style="microcopy">
                    <MoneyDisplay value={organizationInfo.revenue} />
                </Text>
            </Summary.Section>
        </Summary>
    );
}
