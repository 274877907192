import { State } from '@embroker/shotwell/core/types/StateList';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { QuestionnaireData } from '../../types/BundleQuoteQuestionnaireData';

interface LocationWithLargestNumber {
    state: State;
}

export interface LPLQuestionnaireData extends QuestionnaireData {
    totalAttorneysThisYear: number;
    location_with_largest_number?: LocationWithLargestNumber;
}

export const LPLQuestionnaireData = {
    ...defineValidator<LPLQuestionnaireData>(
        QuestionnaireData.schema.keys({
            totalAttorneysThisYear: Joi.number(),
            location_with_largest_number: Joi.object().optional(),
        }),
    ),
    create(questionnaireData: unknown) {
        return LPLQuestionnaireData.validate(questionnaireData);
    },
};
