import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { DocumentRepository } from '../repositories/DocumentRepository';

/**
 * Request data for GetFile use case
 */
export interface GetFileRequest {
    /**
     * A url file should be downloaded from
     */
    url: URI;
    /**
     * Name of returned file, since object S3 is returning doesn't have that value
     */
    fileName: string;
    /**
     * Type of returned file, since object S3 is returning doesn't have that value
     */
    fileType: string;
}

export const GetFileRequest = {
    ...defineValidator<GetFileRequest>({
        url: Joi.string(),
        fileName: Joi.string(),
        fileType: Joi.string(),
    }),
    create(getFileRequest: GetFileRequest) {
        return GetFileRequest.validate(getFileRequest);
    },
};

/**
 * GetFile use case
 */
export interface GetFile extends UseCase {
    execute(request: GetFileRequest): AsyncResult<File, InvalidArgument | OperationFailed>;
}

@injectable()
export class GetFileUseCase extends UseCase implements GetFile {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Documents/GetFile');

    /**
     * Constructor for GetDownloadFile use case class instance.
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param documentRepository Document repository used to store files.
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(DocumentRepository) private documentRepository: DocumentRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes GetFile use case
     * @param url is the url from which file should be downloaded
     */
    async execute(request: GetFileRequest): AsyncResult<File, InvalidArgument | OperationFailed> {
        const result = await this.documentRepository.getFile(request);
        if (isErr(result)) {
            return Failure(OperationFailed(result));
        }
        return Success(result.value as File);
    }
}
export const GetFile: UseCaseClass<GetFile> = GetFileUseCase;
