import {
    API,
    CurrencyMarshaller,
    GlobalDownloadUrlRequest,
    PaymentsGetPremiumFinanceQuoteRequest,
    PaymentsPremiumFinanceQuotePaymentRequest,
} from '@embroker/shotwell-api/app';
import {
    PaymentsGetPremiumFinanceIndicationQuoteRequest,
    PaymentsPremiumFinanceUpdateBillingAchRequest,
    PaymentsPremiumFinanceUpdateRecurringCreditCardRequest,
    PremiumFinanceIndicationCoverageInfo,
    PremiumFinanceIndicationCoverageList,
} from '@embroker/shotwell-api/app.spec';
import { Async } from '@embroker/shotwell/core/async';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { Immutable, Mutable, Nullable } from '@embroker/shotwell/core/types';
import { cast } from '@embroker/shotwell/core/types/Nominal';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    isOK,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import {
    PremiumFinanceGetQuoteInput,
    PremiumFinanceGetTasksInput,
    PremiumFinanceQuotePaymentInput,
    PremiumFinanceUpdateBillingInfoACHInput,
    PremiumFinanceUpdateBillingInfoACHResponse,
    PremiumFinanceUpdateBillingInfoCreditCardInput,
    PremiumFinanceUpdateBillingInfoCreditCardResponse,
    QuoteOptions,
    QuoteRepository,
} from '.';
import { Quote } from '../../entities/Quote';
import {
    ConfigFetchFailed,
    ErrorCode,
    isPaymentError,
    PremiumFinanceRoutingCodeInvalid,
} from '../../types/errors';
import { PremiumFinanceGetQuoteIndicationInput } from '../../types/PremiumFinanceIndicationInfo';
import { DownloadPDFAgreementRequestData } from '../../useCases/DownloadPDFAgreement';
import { GenerateDocumentLinkRequestData } from '../../useCases/GenerateDocumentLink';
import { PaymentsConfig } from '../../types/PaymentsConfig';

interface TaskStatus {
    isDone: boolean;
    result: Nullable<string>;
    error: Nullable<string>;
}

async function getTaskStatus(
    taskId: UUID,
): AsyncResult<TaskStatus, InvalidArgument | OperationFailed> {
    const taskResult = await API.request('task/get_task_status', { id: taskId });

    if (isErr(taskResult)) {
        return handleOperationFailure(taskResult);
    }

    if (taskResult.value.data) {
        return Success({ isDone: true, result: taskResult.value.data, error: null });
    }
    if (taskResult.value.error) {
        return Failure(OperationFailed({ message: taskResult.value.error }));
    }
    return Success({ isDone: false, result: null, error: null });
}

async function toQuoteOptions(quoteJson: string): AsyncResult<Quote, InvalidArgument> {
    try {
        const quoteObj = JSON.parse(quoteJson);
        const quote = await Quote.create({
            quoteNumber: quoteObj.QuoteNumber,
            installmentAmount: CurrencyMarshaller.unmarshal(quoteObj.InstallmentAmount),
            totalPremium: CurrencyMarshaller.unmarshal(quoteObj.TotalPremium),
            totalPaymentAmount: CurrencyMarshaller.unmarshal(quoteObj.TotalPaymentAmount),
            downPayment: CurrencyMarshaller.unmarshal(quoteObj.DownPayment),
            downPayCreditCardUrl: cast<URI>(quoteObj.DownPayCcUrl),
            downPayBankUrl: cast<URI>(quoteObj.DownPayBankUrl),
            pdfFileKey: quoteObj.PdfFileKey,
            numberOfInstallments: quoteObj.NumberOfInstallments,
            annualPercentageRate: Math.round(((quoteObj.APR + Number.EPSILON) * 100) / 100),
        });
        if (isErr(quote)) {
            return Failure(InvalidArgument({ argument: 'quoteJson', value: quote }));
        }

        return quote;
    } catch (e) {
        return Failure(InvalidArgument({ argument: 'quoteJson', value: quoteJson }));
    }
}

function mapToApiPremiumFinanceIndicationCoverageList(
    getQuoteIndicationInput: PremiumFinanceGetQuoteIndicationInput,
): PremiumFinanceIndicationCoverageList {
    return getQuoteIndicationInput.coverageListInfo.map((coverage) => {
        return {
            application_id: coverage.applicationId,
            quote_id: coverage.quoteId,
        } as PremiumFinanceIndicationCoverageInfo;
    });
}

@injectable()
export class APIQuoteRepository implements QuoteRepository {
    async updateBillingInfoACH(
        updateBillingInfoACHInput: PremiumFinanceUpdateBillingInfoACHInput,
    ): AsyncResult<
        PremiumFinanceUpdateBillingInfoACHResponse,
        PremiumFinanceRoutingCodeInvalid | InvalidArgument | OperationFailed
    > {
        const updateBillingRequest: PaymentsPremiumFinanceUpdateBillingAchRequest = {
            quote_number: updateBillingInfoACHInput.quoteNumber,
            billing_method: updateBillingInfoACHInput.billingMethod,
            bank_account_number: updateBillingInfoACHInput.accountNumber,
            account_type: updateBillingInfoACHInput.accountType,
            routing_code: updateBillingInfoACHInput.routingNumber,
            customer_ip_address: updateBillingInfoACHInput.customerIpAddress,
        };
        const result = await API.request(
            'payments/premium_finance_update_billing_ach',
            updateBillingRequest,
        );

        if (isErr(result)) {
            const paymentError = isPaymentError(result.errors);
            if (paymentError != undefined) {
                if (paymentError.code == ErrorCode.PremiumFinanceRoutingCodeInvalid) {
                    return Failure(PremiumFinanceRoutingCodeInvalid());
                }
            }
            return handleOperationFailure(result);
        }

        return Success({
            achSignatureDateTime: result.value.ach_signature_date_time,
        } as PremiumFinanceUpdateBillingInfoACHResponse);
    }

    async updateBillingInfoCreditCard(
        updateBillingInfoCreditCardInput: PremiumFinanceUpdateBillingInfoCreditCardInput,
    ): AsyncResult<
        PremiumFinanceUpdateBillingInfoCreditCardResponse,
        InvalidArgument | OperationFailed
    > {
        const updateBillingRequest: PaymentsPremiumFinanceUpdateRecurringCreditCardRequest = {
            quote_number: updateBillingInfoCreditCardInput.quoteNumber,
        };
        const result = await API.request(
            'payments/premium_finance_update_recurring_credit_card',
            updateBillingRequest,
        );

        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        const fifURI = URI.decodeURIComponent(result.value.fif_credit_card_url);

        if (isErr(fifURI)) {
            return handleOperationFailure(fifURI);
        }

        return Success({
            fifRecurringCreditCardURL: fifURI.value,
            creditCardSignatureDateTime: result.value.credit_card_signature_date_time,
        } as PremiumFinanceUpdateBillingInfoCreditCardResponse);
    }

    async getTaskIds(
        input: Immutable<PremiumFinanceGetTasksInput>,
    ): AsyncResult<PremiumFinanceGetQuoteInput, InvalidArgument | OperationFailed> {
        const getQuote3Request: PaymentsGetPremiumFinanceQuoteRequest = {
            payment_ids: input.invoiceIds as Mutable<UUID[]>,
            number_of_installments: 3,
        };

        const result3 = await API.request('payments/get_premium_finance_quote', getQuote3Request);
        if (isErr(result3)) {
            return handleOperationFailure(result3);
        }

        const getQuote10Request: PaymentsGetPremiumFinanceQuoteRequest = {
            payment_ids: input.invoiceIds as Mutable<UUID[]>,
            number_of_installments: 10,
        };
        const result10 = await API.request('payments/get_premium_finance_quote', getQuote10Request);
        if (isErr(result10)) {
            return handleOperationFailure(result10);
        }

        const result: PremiumFinanceGetQuoteInput = {
            taskMonthly: result10.value.task_id,
            taskQuarterly: result3.value.task_id,
        };

        return Success(result);
    }

    async getQuote(
        taskIds: PremiumFinanceGetQuoteInput,
    ): AsyncResult<QuoteOptions, OperationFailed | InvalidArgument> {
        let task3Done = false;
        let task3Result = '';
        let task10Done = false;
        let task10Result = '';

        while (!task3Done || !task10Done) {
            if (!task3Done) {
                const task3Status = await getTaskStatus(taskIds.taskQuarterly);
                if (isOK(task3Status)) {
                    if (task3Status.value.isDone) {
                        task3Done = true;
                        if (task3Status.value.result) {
                            task3Result = task3Status.value.result;
                        }
                    }
                } else {
                    return handleOperationFailure(task3Status);
                }
            }
            if (!task10Done) {
                const task10Status = await getTaskStatus(taskIds.taskMonthly);
                if (isOK(task10Status)) {
                    if (task10Status.value.isDone) {
                        task10Done = true;
                        if (task10Status.value.result) {
                            task10Result = task10Status.value.result;
                        }
                    }
                } else {
                    return handleOperationFailure(task10Status);
                }
            }
            await Async.sleep(5000);
        }

        const quote3 = await toQuoteOptions(task3Result);
        if (isErr(quote3)) {
            return quote3;
        }
        if (quote3.value.numberOfInstallments != 3) {
            return Failure(
                InvalidArgument({
                    argument: 'numberOfInstallments',
                    value: quote3.value.numberOfInstallments,
                }),
            );
        }

        const quote10 = await toQuoteOptions(task10Result);
        if (isErr(quote10)) {
            return quote10;
        }
        if (quote10.value.numberOfInstallments != 10) {
            return Failure(
                InvalidArgument({
                    argument: 'numberOfInstallments',
                    value: quote10.value.numberOfInstallments,
                }),
            );
        }

        const result: QuoteOptions = {
            quote3Months: quote3.value,
            quote10Months: quote10.value,
        };

        return Success(result);
    }

    async sendQuotePayment({
        invoiceIds,
        organizationId,
        quoteNumber,
        signedDateTimeTermsAndConditions,
        billingMethod,
    }: PremiumFinanceQuotePaymentInput): AsyncResult<boolean, OperationFailed | InvalidArgument> {
        const chargeRequest: PaymentsPremiumFinanceQuotePaymentRequest = {
            payment_ids: invoiceIds,
            organization_id: organizationId,
            quote_number: quoteNumber,
            signed_date_time_terms_and_conditions: signedDateTimeTermsAndConditions,
            billing_method: billingMethod,
        };

        const result = await API.request('payments/premium_finance_quote_payment', chargeRequest);
        if (isErr(result)) {
            return handleOperationFailure(result);
        }
        return Success(true);
    }

    async generateDocumentLink(
        request: GenerateDocumentLinkRequestData,
    ): AsyncResult<string, InvalidArgument | OperationFailed> {
        const data: GlobalDownloadUrlRequest = {
            file_key: request.pdfFileKey,
            content_type: 'application/pdf',
            dest_file_name: null,
        };

        const result = await API.request('global/download_url', data);

        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return Success(result.value);
    }

    async downloadPDFAgreement(
        request: DownloadPDFAgreementRequestData,
    ): AsyncResult<string, InvalidArgument | OperationFailed> {
        const data: GlobalDownloadUrlRequest = {
            file_key: request.pdfFileKey,
            content_type: null,
            dest_file_name: 'document.pdf',
        };

        const result = await API.request('global/download_url', data);

        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return Success(result.value);
    }

    async getQuoteIndication(
        getQuoteIndicationInput: PremiumFinanceGetQuoteIndicationInput,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed> {
        const coverageListInfo =
            mapToApiPremiumFinanceIndicationCoverageList(getQuoteIndicationInput);

        const requestData: PaymentsGetPremiumFinanceIndicationQuoteRequest = {
            number_of_installments: getQuoteIndicationInput.numberOfInstallments,
            coverage_list_info: coverageListInfo,
            start_date: getQuoteIndicationInput.startDate,
        };
        const result = await API.request(
            'payments/get_premium_finance_indication_quote',
            requestData,
        );

        if (isErr(result)) {
            return Failure(OperationFailed({ message: result.errors.toString() }));
        }

        return Success(result.value.task_id);
    }

    async getPaymentConfig(): AsyncResult<PaymentsConfig, InvalidArgument | ConfigFetchFailed> {
        const getConfigResponse = await API.request('global/get_config');

        if (isErr(getConfigResponse)) {
            return Failure(ConfigFetchFailed());
        }

        return PaymentsConfig.create({
            useAscend: getConfigResponse.value.use_ascend,
        });
    }
}
