import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { Nullable } from '@embroker/shotwell/core/types';
import { PcomlQuoteOptions } from '@embroker/shotwell-api/app';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { RemoveSignaturePacketRequest } from '../../../brokerage/useCases/RemoveSignaturePacket';
import { UploadSignaturePacketRequest } from '../../../brokerage/useCases/UploadSignaturePacket';
import { InvalidAnnualTechFee } from '../../../errors';
import { PCoMLQuote } from '../../entities/PCoMLQuote';
import { PCoMLConfig } from '../../types/PCoMLConfig';
import { ConfigFetchFailed } from '../../errors';

export interface PurchaseResponse {
    isOFACRejected: boolean;
    policyId: UUID;
    policyDoneTaskId: UUID;
}

export interface GetTaskStatusResponse {
    data: Nullable<string>;
    error: Nullable<string>;
}

export interface QuoteRepository {
    getQuoteByApplicationId(
        applicationId: UUID,
    ): AsyncResult<PCoMLQuote, InvalidArgument | OperationFailed | ConfigFetchFailed>;

    purchasePCoML(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<PurchaseResponse, InvalidArgument | OperationFailed | InvalidAnnualTechFee>;

    updateQuotePCoMLAsync(
        applicationId: UUID,
        quoteOptions: PcomlQuoteOptions,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed>;

    createQuoteSummaryAsyncTask(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed>;

    uploadSignaturePacket(
        request: UploadSignaturePacketRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed>;

    removeSignaturePacket(
        request: RemoveSignaturePacketRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed>;

    getTaskStatus(id: UUID): AsyncResult<GetTaskStatusResponse, InvalidArgument | OperationFailed>;

    getPCoMLConfig(): AsyncResult<
        PCoMLConfig,
        InvalidArgument | OperationFailed | ConfigFetchFailed
    >;
}

export const QuoteRepository = Symbol('QuoteRepository');
