import { Aborted, InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import {
    DeleteFileParams,
    GetFileListParams,
    GetFileParams,
    SaveFilesParams,
} from './APIDocumentRepository';

import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { Document } from '../../entities/Document';
import { ProgressEvent } from '@embroker/shotwell/core/networking';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { GeneratedDocument } from '../../entities/GeneratedDocument';

/**
 * Represents the data used by DocumentRepository.save function
 */
export interface SaveDocument {
    /**
     * File to be uploaded
     */
    file: Document;
    /**
     * Callback which is executed when file upload progress changes
     * @param event is of type ProgressEvent which contains relevant data about the progress
     */
    onFileSaveProgress(event: ProgressEvent): void;
    /**
     * Signal used to abort file save operation
     */
    abortSignal?: AbortSignal;
}

/**
 * Interface of the document repository
 * This repository is used to store and save files so that they can be obtained later
 */
export interface DocumentRepository {
    /**
     * Gets the Amazon S3 download URL
     * @param fileKey file key to get the download URL for
     * browser will know how to show the file
     */
    getDocumentUrl(fileKey: string): AsyncResult<string, InvalidArgument | OperationFailed>;
    /**
     * Saves the file in the repository
     * File save progress can be monitored through a callback handleFileSaveProgress, as described in @interface SaveDocument
     */
    save(params: SaveDocument): AsyncResult<Document, InvalidArgument | OperationFailed | Aborted>;
    /**
     * Downloads document from given url
     * @param params url : url from which document should be downloaded
     */
    getFile(params: GetFileParams): AsyncResult<File | OperationFailed>;
    /**
     * Obtains file list from file key list
     * @param params GetFileListRequest
     */
    getFileList(params: GetFileListParams): AsyncResult<Document[], OperationFailed>;
    /**
     * Gets Document information for a given document id
     * @param id document id
     */
    getDocument(id: UUID): AsyncResult<Document, InvalidArgument | OperationFailed>;
    /**
     * Saves uploaded files information to the database
     * @param files files to be saved
     * @param organizationId organization id
     */
    saveFiles(params: SaveFilesParams): AsyncResult<void, OperationFailed>;
    /**
     * Deletes file from the database
     * @param fileKey file to be deleted
     * @param applicationId id of the application that the document is linked to
     */
    deleteFile(params: DeleteFileParams): AsyncResult<void, OperationFailed>;
    /**
     * Checks if application has generating document task in progress
     * @param applicationId application id
     */
    hasDocGenTaskInProgress(
        applicationId: UUID,
    ): AsyncResult<boolean, InvalidArgument | OperationFailed>;
    /**
     * Gets Application Document file key
     * @param applicationId application id
     */
    getApplicationDocumentFileKey(
        applicationId: UUID,
    ): AsyncResult<string | void, InvalidArgument | OperationFailed>;
    /**
     * Gets Application generated document list
     * @param applicationId application id
     */
    getApplicationDocumentGeneratedDocuments(
        applicationId: UUID,
    ): AsyncResult<GeneratedDocument[], InvalidArgument | OperationFailed>;
}

export const DocumentRepository = Symbol('DocumentRepository');
