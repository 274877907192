import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { InvoiceTable } from '@embroker/ui-toolkit/v2';
import React, { useContext } from 'react';
import { hasRole } from '../../../../userOrg/entities/Session';
import { AppContext } from '../../../../view/AppContext';
import { QuoteBreakdownComponentProps } from '../../../view/components/QuoteLandingPage';
import { CyberQuote } from '../../entities/CyberQuote';
import { CyberQuoteOptionsFormData } from './createCyberQuoteOptionsForm';

export function CyberQuoteBreakdown({
    quote,
}: QuoteBreakdownComponentProps<CyberQuoteOptionsFormData, CyberQuote>) {
    const { activeSession } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');

    return (
        <InvoiceTable>
            <InvoiceTable.Section>
                <InvoiceTable.Subtotal data-e2e="selected-coverages-list" title="Coverages" />
                <InvoiceTable.Item data-e2e="coverage-item" title="Cyber">
                    <MoneyDisplay value={quote.details.totalBasePremium} />
                </InvoiceTable.Item>
            </InvoiceTable.Section>
            <InvoiceTable.Section>
                <InvoiceTable.Subtotal title="Subtotal" />
                <InvoiceTable.Item
                    title="Taxes"
                    tooltip="Surplus Line Tax is required on all surplus line insurance transactions and vary by state."
                >
                    <MoneyDisplay value={quote.details.taxes} />
                </InvoiceTable.Item>
                <InvoiceTable.Item
                    title="Fees (excluding transaction fees based on your choice of payment)"
                    tooltip="Often referred to as “Stamping Fees”, Surplus Lines Fees are only required by certain states. In the applicable states Surplus Line Fees are charged on all insurance transactions. Embroker does not charge our clients any fees."
                >
                    <MoneyDisplay value={quote.details.fees} />
                </InvoiceTable.Item>
            </InvoiceTable.Section>
            <InvoiceTable.Section>
                {isBroker && (
                    <InvoiceTable.Item title="Embroker Access Fee" data-e2e="access-fee">
                        <MoneyDisplay value={quote.details.policyAdministrationFee} />
                    </InvoiceTable.Item>
                )}
                <InvoiceTable.Total data-e2e="total-amount" title="Total: ">
                    <MoneyDisplay value={quote.details.totalPremium} />
                </InvoiceTable.Total>
            </InvoiceTable.Section>
        </InvoiceTable>
    );
}
