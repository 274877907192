import { ContainerModule } from '@embroker/shotwell/core/di';
import { routes } from './view/routes';
import { Module } from '../Module';
import { PublishCyberCrossSellEvent } from './useCases/PublishCyberCrossSellEvent';

/**
 * Lpl Renewal module declarations container.
 */
export const LplRenewalModule: Module = {
    routes,
    container: new ContainerModule((bind) => {
        bind<PublishCyberCrossSellEvent>(PublishCyberCrossSellEvent.type)
            .to(PublishCyberCrossSellEvent)
            .inSingletonScope();
    }),
};
