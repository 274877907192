import React, { useContext } from 'react';
import { DownloadDocument } from './DownloadDocument';
import { Document } from '../../../types/Document';
import { useEnumGroup } from '../../../../serverEnums/view/hooks/useEnumGroup';
import { ColumnLayout, Table, Text } from '@embroker/ui-toolkit/v2';
import { Immutable } from '@embroker/shotwell/core/types';
import { AppContext } from '../../../../view/AppContext';
import { hasRole } from '../../../../userOrg/entities/Session';
import { PolicyDetailsCard } from './PolicyDetailsCard.view';

export interface DocumentSectionProps {
    documentList: Immutable<Document[]>;
}

export const DocumentSection = (props: DocumentSectionProps) => {
    const { activeSession } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');
    const { result: documentTypeCodeList } = useEnumGroup('DocumentTypeCodeList');

    if (documentTypeCodeList === undefined) {
        return null;
    }

    function isBrokerQuoteDocument(document: Immutable<Document>) {
        return isBroker && document.typeCode === 'DocumentTypeCodeListQuote';
    }

    return (
        <PolicyDetailsCard title="Documents">
            <Table>
                <Table.Header>
                    <Table.Column>Name</Table.Column>
                    <Table.Column>Type</Table.Column>
                    <Table.Column>Action</Table.Column>
                </Table.Header>
                <Table.Body>
                    {props.documentList.map((document) => {
                        if (isBrokerQuoteDocument(document)) {
                            return null;
                        }
                        return (
                            <Table.Row key={document.storageLocation}>
                                <Table.Cell>
                                    <Text style="label 1" data-e2e="document-name">
                                        {document.name}
                                    </Text>
                                </Table.Cell>
                                <Table.Cell>
                                    {documentTypeCodeList.get(document.typeCode)}
                                </Table.Cell>
                                <Table.Cell>
                                    <ColumnLayout split="-1">
                                        <DownloadDocument
                                            currentDocumentFileKey={document.storageLocation}
                                            currentDocumentTypeCode={document.typeCode}
                                            isDownload={false}
                                        />
                                        <DownloadDocument
                                            currentDocumentFileKey={document.storageLocation}
                                            currentDocumentTypeCode={document.typeCode}
                                            isDownload
                                        />
                                    </ColumnLayout>
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        </PolicyDetailsCard>
    );
};
