import React, { SyntheticEvent } from 'react';

import {
    StackLayout,
    ColumnLayout,
    Modal,
    Text,
    Button,
    ModalState,
    ModalActions,
} from '@embroker/ui-toolkit/v2';

export interface PolicyInTheWorksProps {
    modal: ModalState & ModalActions;
    hideModal: Function;
}

export const PolicyInTheWorksModal = ({ modal, hideModal }: PolicyInTheWorksProps) => {
    return (
        <Modal {...modal} dismissable={false}>
            <StackLayout gap="32">
                <StackLayout gap="24">
                    <Text style="heading 3">Your policy is in the works!</Text>
                    <Text>
                        Our Customer Success team will review this policy and add the details to
                        your account in a jiffy. What's next? Once we've processed your policy
                        you'll be able to transfer it to Embroker.
                    </Text>
                    <Text>
                        Transferring your policies to Embroker allows us to audit your insurance
                        program to identify gaps in coverage and look for potential cost savings.
                    </Text>
                </StackLayout>
                <ColumnLayout gap="32">
                    <Button appearance="primary" onClick={(event: SyntheticEvent) => hideModal()}>
                        Got it
                    </Button>
                </ColumnLayout>
            </StackLayout>
        </Modal>
    );
};
