import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ApplicationRepository } from '../repositories/ApplicationRepository';

export interface GetApplicationShareTokenResponse {
    shareToken: Immutable<string>;
}

export interface GetApplicationShareTokenRequest {
    applicationId: UUID;
}

export interface GetApplicationShareToken extends UseCase {
    execute({
        applicationId,
    }: GetApplicationShareTokenRequest): AsyncResult<
        GetApplicationShareTokenResponse,
        InvalidArgument | OperationFailed
    >;
}

class GetApplicationShareTokenUseCase extends UseCase implements GetApplicationShareToken {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Shopping/GetApplicationShareToken');
    /**
     * Constructor for GetApplicationShareToken use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param applicantRepository is applicant repository used to store applicant entity with updated properties
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
    }: GetApplicationShareTokenRequest): AsyncResult<
        GetApplicationShareTokenResponse,
        InvalidArgument | OperationFailed
    > {
        const result = await this.applicationRepository.getApplicationShareToken(applicationId);

        if (isErr(result)) {
            return result;
        }

        return Success<GetApplicationShareTokenResponse>({
            shareToken: result.value,
        });
    }
}

export const GetApplicationShareToken: UseCaseClass<GetApplicationShareToken> =
    GetApplicationShareTokenUseCase;
