import React from 'react';
import { ProgressSidebar, ProgressSidebarItemStatus } from '@embroker/ui-toolkit/v2';

export interface NavigationItem {
    id: string;
    status: ProgressSidebarItemStatus;
    title: string;
    disabled?: boolean;
}

interface FormNavigationViewProps {
    navigationPages: NavigationItem[];
    onClickNavigationItem: (id: string) => void;
}

export function FormNavigationView(props: FormNavigationViewProps) {
    const { navigationPages, onClickNavigationItem } = props;

    return (
        <ProgressSidebar>
            {navigationPages.map((item: NavigationItem) => {
                return (
                    <ProgressSidebar.Item
                        key={item.id}
                        status={item.status}
                        onClick={() => onClickNavigationItem(item.id)}
                        disabled={item.disabled}
                    >
                        {item.title}
                    </ProgressSidebar.Item>
                );
            })}
        </ProgressSidebar>
    );
}
