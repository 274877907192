import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { isValid, parseISO, startOfDay, startOfToday } from 'date-fns';
import { map, route, withData } from 'navi';
import React from 'react';
import { redirectToWhoopsPage } from '../../../view/errors';
import { GetLastLPLQuote } from '../../lpl/useCases/GetLastLPLQuote';
import { GetLPLConfig } from '../../lpl/useCases/GetLPLConfig';
import { LPLQuoteLandingPage } from '../../lpl/view/components/LPLQuoteLandingPage';

function getToday(todayOverride?: string): () => Date {
    return () => {
        return todayOverride && isValid(parseISO(todayOverride))
            ? startOfDay(parseISO(todayOverride))
            : startOfToday();
    };
}

function lplWizzardRouteHandler(referredQuote = false) {
    return map(async (req) => {
        const { applicationId } = req.query;

        if (!UUID.check(applicationId)) {
            return redirectToWhoopsPage([
                InvalidArgument({
                    argument: 'applicationId',
                    value: applicationId,
                    validator: 'UUID',
                }),
            ]);
        }

        const getLastQuoteResult = await execute(GetLastLPLQuote, {
            applicationId: applicationId,
        });

        if (isErr(getLastQuoteResult)) {
            return redirectToWhoopsPage(getLastQuoteResult.errors);
        }

        const getConfigResult = await execute(GetLPLConfig);

        const lplDevModeEffectiveDate = isOK(getConfigResult)
            ? getConfigResult.value.lplDevModeEffectiveDate
            : undefined;
        const isTestMode = lplDevModeEffectiveDate !== '';

        return route({
            title: 'Lawyers PL Quote',
            view: (
                <LPLQuoteLandingPage
                    applicationId={applicationId}
                    initialLplQuote={getLastQuoteResult.value.lplQuote}
                    lplQuoteInfo={getLastQuoteResult.value.lplQuoteInfo}
                    getToday={getToday(lplDevModeEffectiveDate)}
                    referredQuote={referredQuote}
                    isTestMode={isTestMode}
                />
            ),
        });
    });
}

export const lplRoutes = {
    '/': map((request) =>
        withData(
            { url: request.mountpath, page: undefined, fullscreen: true },
            lplWizzardRouteHandler(),
        ),
    ),
    '/referred-estimate': withData(
        { page: undefined, fullscreen: true },
        lplWizzardRouteHandler(true),
    ),
    '/:page': map((request) =>
        withData(
            { url: request.mountpath, page: request.params.page, fullscreen: true },
            lplWizzardRouteHandler(),
        ),
    ),
};
