import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { DocumentRepository } from '../repositories/DocumentRepository';

/**
 * Request data for GetDocumentUrl use case
 */
export interface GetDocumentUrlRequest {
    fileKey: string;
}

/**
 * Response data for GetDocumentUrl use case
 * @param downloadUrl s3 download url
 */
export interface GetDocumentUrlResponse {
    downloadUrl: string;
}

/**
 * GetDocumentUrl use case uploads files to the file repository one by one
 */
export interface GetDocumentUrl extends UseCase {
    execute(
        request: GetDocumentUrlRequest,
    ): AsyncResult<GetDocumentUrlResponse, InvalidArgument | OperationFailed>;
}

@injectable()
export class GetDocumentUrlUseCase extends UseCase implements GetDocumentUrl {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Documents/GetDocumentUrl');

    /**
     * Constructor for GetDocumentUrl use case class instance.
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param documentRepository Document repository used to store files.
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(DocumentRepository) private documentRepository: DocumentRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes GetDocumentUrl use case
     * @param fileKey file key used to retrieve s3 download url
     */
    async execute({
        fileKey,
    }: GetDocumentUrlRequest): AsyncResult<
        GetDocumentUrlResponse,
        InvalidArgument | OperationFailed
    > {
        const apiResult = await this.documentRepository.getDocumentUrl(fileKey);

        if (isErr(apiResult)) {
            return apiResult;
        }

        return Success({
            downloadUrl: apiResult.value,
        } as GetDocumentUrlResponse);
    }
}

export const GetDocumentUrl: UseCaseClass<GetDocumentUrl> = GetDocumentUrlUseCase;
