import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { CalculateSKU } from '../../../analytics/useCases/CalculateSKU';
import { ApplicationRepository } from '../../../shopping/repositories/ApplicationRepository';
import { ExcessQuoteRepository } from '../repositories/EmbrokerExcessQuoteRepository';

export interface SubmitExcessApplicationForReviewRequest {
    applicationId: UUID;
    supplementalQuestionnaireData: string;
}

export interface SubmitExcessApplicationForReview extends UseCase {
    execute(
        request: SubmitExcessApplicationForReviewRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed>;
}

@injectable()
export class SubmitExcessApplicationForReviewUseCase
    extends UseCase
    implements SubmitExcessApplicationForReview
{
    public static type = Symbol('ExcessQuote/SubmitExcessApplicationForReview');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ExcessQuoteRepository) private excessQuoteRepository: ExcessQuoteRepository,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
        @inject(CalculateSKU.type) private calculateSKU: CalculateSKU,
    ) {
        super(eventBus);
    }

    async execute({
        applicationId,
        supplementalQuestionnaireData,
    }: SubmitExcessApplicationForReviewRequest): AsyncResult<
        void,
        InvalidArgument | OperationFailed
    > {
        const getApplicationResult = await this.applicationRepository.getApplication(applicationId);
        if (isErr(getApplicationResult)) {
            return getApplicationResult;
        }

        const skuResult = await this.calculateSKU.execute({
            event: 'submit_for_review',
            applicationId,
        });
        if (isErr(skuResult)) {
            return skuResult;
        }

        const submitForReviewResult = await this.excessQuoteRepository.submitForReview(
            applicationId,
            supplementalQuestionnaireData,
        );
        if (isErr(submitForReviewResult)) {
            return submitForReviewResult;
        }

        const application = getApplicationResult.value;

        application.onSubmittedForReview({
            applicationId: application.id,
            sku: skuResult.value,
            isRenewal: application.isRenewal(),
        });

        await this.eventBus.publishEntityEvents(application);

        return Success();
    }
}

export const SubmitExcessApplicationForReview: UseCaseClass<SubmitExcessApplicationForReview> =
    SubmitExcessApplicationForReviewUseCase;
