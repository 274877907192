import { inject, injectable } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import {
    InvalidArgument,
    NotAllowed,
    OperationFailed,
    UnknownEntity,
} from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { UserInvite } from '../entities/UserInvite';
import { UserInviteRepository } from '../repositories/UserInviteRepository';
import { Token } from '../types/Token';

/**
 * GetInvitedOrganizationData Use Case Request model.
 */
export interface GetInvitedOrganizationDataRequest {
    /**
     * Invitation token sent to user's email.
     */
    readonly inviteToken: Token;
}
/**
 * GetInvitedOrganizationData Use Case Response model.
 */
export interface GetInvitedOrganizationDataResponse {
    /**
     * User invitation data.
     */
    readonly userInvite: Immutable<EntityProps<UserInvite>>;
}

/**
 * GetInvitedOrganizationData Use Case fetches invite data for the invited user.
 */

export interface GetInvitedOrganizationData extends UseCase {
    execute(
        request: GetInvitedOrganizationDataRequest,
    ): AsyncResult<
        GetInvitedOrganizationDataResponse,
        UnknownEntity | InvalidArgument | NotAllowed | OperationFailed
    >;
}
@injectable()
class GetInvitedOrganizationDataUseCase extends UseCase implements GetInvitedOrganizationData {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/GetInvitedOrganizationData');
    /**
     * Constructor for GetInvitedOrganizationData use case class instance.
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param userInviteRepository UserInvite repository used to fetch/store UserInvite entities.
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(UserInviteRepository) private readonly userInviteRepository: UserInviteRepository,
    ) {
        super(eventBus);
    }
    /**
     * Executes GetInvitedOrganizationData Use Case.
     *
     * @returns UnknownEntity error if user invite for inviteToken does not exist on the platform
     * @returns InvalidArgument error if updated user invite entity contains invalid property
     * @returns NotAllowed error if the user invite has already been rejected
     * @returns OperationFailed error if repo request failed
     */
    public async execute(
        request: GetInvitedOrganizationDataRequest,
    ): AsyncResult<
        GetInvitedOrganizationDataResponse,
        UnknownEntity | InvalidArgument | NotAllowed | OperationFailed
    > {
        if (!request.inviteToken) {
            return Failure(
                InvalidArgument({ argument: 'inviteToken', value: request.inviteToken }),
            );
        }

        const inviteResult = await this.userInviteRepository.getUserInviteByToken(
            request.inviteToken,
        );

        if (isErr(inviteResult)) {
            return handleOperationFailure(inviteResult);
        }

        return Success({ userInvite: inviteResult.value.toDTO() });
    }
}

export const GetInvitedOrganizationData: UseCaseClass<GetInvitedOrganizationData> =
    GetInvitedOrganizationDataUseCase;
