import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { keysOf } from '@embroker/shotwell/core/object';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import {
    ActionHandler,
    CreateFormParams,
    FormAction,
    OpaqueForm,
} from '@embroker/shotwell/view/hooks/useForm';
import { GetDocumentUrl } from '../../../documents/useCases/GetDocumentUrl';
import { CyberQuote } from '../../../quote/cyber/entities/CyberQuote';
import { QuotingEngineLawCyber } from '../../../shopping/types/enums';
import { WizardForm } from '../../../view/hooks/useWizardForm';
import { BundleQuote } from '../../entities/BundleQuote';
import { BundleCoverageType, BundleCoverageTypeEnum } from '../../types/BundleQuoteCoverage';
import { DocumentType } from '../../types/BundleQuoteDocument';
import { BuildBundleDocumentListProps } from '../../view/components/buildBundleDocumentList';
import { DocumentsItem } from '../../view/components/BundleQuoteLandingPage';
import { DownloadDocumentButton } from '../../view/components/DownloadDocumentButton';
import { GenerateDocumentButton } from '../../view/components/GenerateDocumentButton';
import { getDocumentUrl, getStaticDocumentUrl } from '../bundleMappingFunctions';
import {
    CoverageDefinition,
    CyberDocumentTitle,
    CyberProductName,
    CyberProductTitle,
    distributedPrefix,
    generateDocumentDisplayName,
    GetDocumentDownloadMetadataResponse,
    LawCyber,
    ProductFormData,
} from '../coverageDefinition';
import { buildLawCyberCoverage } from './buildLawCyberCoverage';
import { CyberFooter } from './components/CyberBundleFooter';
import { CyberQuoteSummary } from './components/CyberBundleSummary';
import { CyberModalCoverageSummary } from './components/CyberModalCoverageSummary';
import { LawCyberBundleCoverageOptions } from './components/LawCyberBundleCoverageOptions';
import { LawCyberQuote } from './entities/LawCyberQuote';
import {
    formDataToLawCyberQuoteOptions,
    isLawCyberFormData,
    isLawCyberQuoteOptions,
    toApiLawCyberQuoteOptions,
} from './mappingFunctions';
import { LawCyberQuoteOptions } from './types/LawCyberQuoteOptions';

export const LawCyberCoverageDefinition: CoverageDefinition<LawCyberQuote> = {
    productName: CyberProductName,
    productTitle: CyberProductTitle,
    documentTitle: CyberDocumentTitle,
    type: BundleCoverageTypeEnum.LawCyberBundleCoverage,
    quotingEngine: QuotingEngineLawCyber,
    buildCoverage: buildLawCyberCoverage,
    mapFormDataToQuoteOptions: formDataToLawCyberQuoteOptions,
    apiProductDesignation: LawCyber,
    mapQuoteOptionsToAPI: toApiLawCyberQuoteOptions,
    coverageOptionsComponent: LawCyberBundleCoverageOptions,
    summaryComponent: CyberQuoteSummary,
    modalSummaryComponent: CyberModalCoverageSummary,
    footerComponent: CyberFooter,
    isFieldsValidType(
        input: any,
    ): input is WizardForm<OpaqueForm<distributedPrefix<LawCyberQuoteOptions>>>['fields'] {
        return isLawCyberFormData(input);
    },
    isOptionsValidType(input: any): input is LawCyberQuoteOptions {
        return isLawCyberQuoteOptions(input);
    },
    getProductFields(
        bundleQuote: BundleQuote,
    ): CreateFormParams<ProductFormData<distributedPrefix<LawCyberQuoteOptions>>>['fields'] {
        return {
            cyberLimit: {
                type: 'select',
                validator: Joi.number(),
            },
            cyberRetention: {
                type: 'select',
                validator: Joi.number(),
            },
            cyberSelected: {
                type: 'checkbox',
                validator: Joi.boolean(),
            },
        };
    },
    getProductActions(
        bundleQuote: BundleQuote,
        abortSignal: AbortSignal,
    ): Record<
        FormAction,
        | {
              action: ActionHandler<LawCyberQuoteOptions>;
              fields: (keyof distributedPrefix<LawCyberQuoteOptions>)[];
          }
        | ActionHandler<ProductFormData<LawCyberQuoteOptions>>
    > {
        const formFields = this.getProductFields(bundleQuote);
        return {
            generateCyberQuoteDocument: {
                action: async () => {
                    return getDocumentUrl(
                        bundleQuote,
                        BundleCoverageTypeEnum.LawCyberBundleCoverage,
                        DocumentType.QuoteDocument,
                        abortSignal,
                    );
                },
                fields: ['effectiveDate', ...keysOf(formFields)],
            },
            downloadCyberGeneralTerms: {
                action: async () => {
                    return getStaticDocumentUrl(
                        DocumentType.GeneralTerms,
                        BundleCoverageTypeEnum.LawCyberBundleCoverage,
                        bundleQuote,
                    );
                },
                fields: ['effectiveDate', ...keysOf(formFields)],
            },
        };
    },
    getCoverageDocuments({
        bundleQuote,
        documents,
        trigger,
        isDirty,
        value,
    }: BuildBundleDocumentListProps): DocumentsItem[] | undefined {
        const shouldIncludeLawCyberDocs =
            !bundleQuote.isCoverageReferred(BundleCoverageTypeEnum.LawCyberBundleCoverage) &&
            bundleQuote.isCoverageEnabled(BundleCoverageTypeEnum.LawCyberBundleCoverage) &&
            value.cyberSelected;

        if (!shouldIncludeLawCyberDocs) {
            return undefined;
        }

        const CyberQuoteDocument: DocumentsItem = {
            component: GenerateDocumentButton,
            isDisabled: isDirty,
            fileUrl: documents.find(
                (document) =>
                    document.documentType === DocumentType.QuoteDocument &&
                    document.coverageType === BundleCoverageTypeEnum.LawCyberBundleCoverage,
            )?.url,
            handleTrigger: () => trigger('generateCyberQuoteDocument'),
            displayName: generateDocumentDisplayName({
                documentType: DocumentType.QuoteDocument,
                bundleCoverageType: BundleCoverageTypeEnum.LawCyberBundleCoverage,
            }),
        };
        const CyberGeneralTerms: DocumentsItem = {
            component: DownloadDocumentButton,
            isDisabled: isDirty,
            fileUrl: documents.find(
                (document) =>
                    document.documentType === DocumentType.GeneralTerms &&
                    document.coverageType === BundleCoverageTypeEnum.LawCyberBundleCoverage,
            )?.url,
            handleTrigger: () => trigger('downloadCyberGeneralTerms'),
            displayName: generateDocumentDisplayName({
                documentType: DocumentType.GeneralTerms,
                bundleCoverageType: BundleCoverageTypeEnum.LawCyberBundleCoverage,
            }),
        };
        return [CyberQuoteDocument, CyberGeneralTerms];
    },
    getInitialValues(bundleQuote): Record<string, unknown> {
        const lawCyberCoverage = bundleQuote.coverageList.find(
            (coverage) => coverage.type === this.type,
        );

        // TODO: improve error handling
        if (
            lawCyberCoverage === undefined ||
            lawCyberCoverage.quote === undefined ||
            !this.isOptionsValidType(lawCyberCoverage.quote.options)
        ) {
            return {};
        }

        const lawCyberOptions = lawCyberCoverage.quote.options;
        return {
            cyberLimit: lawCyberOptions.limit,
            cyberRetention: lawCyberOptions.retention,
            cyberSelected: !lawCyberOptions.isDeselected,
        };
    },
    toggleSelected(value: boolean): Record<string, unknown> {
        return { cyberSelected: value };
    },
    getMaxFutureDaysAllowed(): number {
        return 90;
    },
    async getDocumentDownloadMetadata(
        documentType: DocumentType,
        cyberQuote?: CyberQuote,
    ): AsyncResult<GetDocumentDownloadMetadataResponse, OperationFailed | InvalidArgument> {
        const GTC_CYBER_URL =
            'https://embroker.s3.us-west-2.amazonaws.com/Cyber+Standard+Specimen.pdf';
        let downloadUrlResult;
        switch (documentType) {
            case DocumentType.GeneralTerms:
                return Success({ fileKey: '', downloadUrl: GTC_CYBER_URL });
            case DocumentType.QuoteDocument:
                if (cyberQuote?.fileKey === undefined) {
                    return Failure(InvalidArgument({ argument: 'fileKey', value: documentType }));
                }
                downloadUrlResult = await execute(GetDocumentUrl, {
                    fileKey: cyberQuote.fileKey,
                });
                if (isErr(downloadUrlResult)) {
                    return downloadUrlResult;
                }
                return Success({
                    fileKey: cyberQuote.fileKey,
                    downloadUrl: downloadUrlResult.value.downloadUrl,
                });
            default:
                return Failure(InvalidArgument({ argument: 'fileKey', value: documentType }));
        }
    },
    onSuccessHandler(
        value: any,
        action: string,
        setDocumentUrl: (
            url: URI,
            documentType: DocumentType,
            bundleCoverageType: BundleCoverageType,
        ) => void,
        onGenerateDocument: () => void,
    ) {
        switch (action) {
            case 'generateCyberQuoteDocument':
                setDocumentUrl(
                    value.fileUrl,
                    DocumentType.QuoteDocument,
                    BundleCoverageTypeEnum.LawCyberBundleCoverage,
                );
                onGenerateDocument();
                break;
            case 'downloadCyberGeneralTerms':
                window.open(value.fileUrl as string, '_blank');
                break;
        }
    },
    isLimitHigherThenAllowed(
        formValue: distributedPrefix<LawCyberQuoteOptions>,
        coverageSelected: boolean,
    ): boolean {
        return false;
    },

    getPremiumRange() {
        return undefined;
    },
};
