import { InvalidArgument, UnknownEntity, OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { ContactUsForm } from '../../types/ContactForm';

export interface ContactUsPublicRepository {
    contactUs(
        formData: ContactUsForm,
    ): AsyncResult<void, InvalidArgument | OperationFailed | UnknownEntity>;
}

export const ContactUsPublicRepository = Symbol('ContactUsPublicRepository');
