import { container } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Immutable } from '@embroker/shotwell/core/types';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { execute } from '@embroker/shotwell/core/UseCase';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    Button,
    ColumnLayout,
    PageLayout,
    Spinner,
    StackLayout,
    StatusMessage,
    Text,
    useModal,
} from '@embroker/ui-toolkit/v2';
import React, { useContext, useEffect, useState } from 'react';
import { GetGlobalConfig } from '../../../config/useCases/GetGlobalConfigUseCase';
import { PolicySortByAlphabet } from '../../../policy/types/PolicySortBy';
import { GetPolicies } from '../../../policy/useCases/GetPolicies';
import { AppContext } from '../../../view/AppContext';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { Certificate } from '../../entities/Certificate';
import {
    GetIssuedCertificates,
    GetIssuedCertificatesRequest,
} from '../../useCases/GetIssuedCertificates';
import { GetShareableSelfServingCoverageList } from '../../useCases/GetShareableSelfServingCoverageList';
import { CertificatesHistoryPage } from './CertificatesHistory';
import { NoActivePoliciesModal } from './NoActivePoliciesModal';
import { NoCertificatesPage } from './NoCertificates';
import { NoShareableCoveragesModal } from './NoSheableCoveragesModal';

export function MyCertificatesPage() {
    const { activeSession } = useContext(AppContext);
    const [successUserMessage, setSuccessUserMessage] = useState<string>();
    const [errorUserMessage, setErrorUserMessage] = useState<string>();

    const {
        result: issuedCertificatesResult,
        isLoading: certificateListIsLoading,
        reload: reloadIssuedCertificatesTable,
    } = useUseCase(GetIssuedCertificates, {
        organizationId: activeSession.organizationId,
    } as GetIssuedCertificatesRequest);

    const { result: policiesResult, isLoading: policiesIsLoading } = useUseCase(GetPolicies, {
        filter: {
            showActive: true,
            showInactive: false,
            sortBy: PolicySortByAlphabet,
        },
    });

    const { result: globalConfigResult, isLoading: globalConfigIsLoading } =
        useUseCase(GetGlobalConfig);

    const [hasAnyShareableCoverage, setHasAnySharableCoverage] = useState(false);

    useEffect(() => {
        if (globalConfigResult !== undefined) {
            if (isErr(globalConfigResult)) {
                container.get<Logger>(Log).error(globalConfigResult.errors);
                return;
            }
        }
    }, [globalConfigResult]);

    useEffect(() => {
        async function getShareableSelfServingCoverageList() {
            const result = await execute(GetShareableSelfServingCoverageList);
            if (isOK(result)) {
                setHasAnySharableCoverage(result.value.selfServingCoverageList.length > 0);
                return;
            }
            setErrorUserMessage('Failed to fetch your coverages');
        }
        getShareableSelfServingCoverageList();
    }, []);

    if (
        issuedCertificatesResult === undefined ||
        globalConfigResult === undefined ||
        policiesResult === undefined ||
        certificateListIsLoading ||
        policiesIsLoading ||
        globalConfigIsLoading
    ) {
        return <Spinner />;
    }

    let hasAnyPolicy = false;

    /**
     * Declare complete failure only if we assess that every part of feature will fail to load
     * Otherwise just log errors
     */

    let certificateList: Immutable<EntityProps<Certificate>[]> | null = null;

    if (isErr(issuedCertificatesResult)) {
        container.get<Logger>(Log).error(issuedCertificatesResult.errors);
    } else {
        certificateList = issuedCertificatesResult.value.certificateList;
    }

    if (isErr(policiesResult)) {
        container.get<Logger>(Log).error(policiesResult.errors);
    } else {
        hasAnyPolicy = policiesResult.value.hasAnyPolicy;
    }

    const onCertificateShare = (messageForUser?: string) => {
        setSuccessUserMessage(messageForUser);
        reloadIssuedCertificatesTable();
    };

    const onCertificateError = (messageForUser?: string) => {
        setErrorUserMessage(messageForUser);
    };

    const clearUserMessages = () => {
        setSuccessUserMessage(undefined);
        setErrorUserMessage(undefined);
    };

    return (
        <MyCertificatesView
            onCertificateShare={onCertificateShare}
            onCertificateError={onCertificateError}
            clearUserMessages={clearUserMessages}
            certificateList={certificateList}
            hasAnyPolicy={hasAnyPolicy}
            hasAnyShareableCoverage={hasAnyShareableCoverage}
            successUserMessage={successUserMessage}
            errorUserMessage={errorUserMessage}
        />
    );
}

interface MyCertificatesViewProps {
    onCertificateShare: (messageForUser?: string) => void;
    onCertificateError: (messageForUser?: string) => void;
    certificateList: Immutable<EntityProps<Certificate>[]> | null;
    hasAnyPolicy: boolean;
    hasAnyShareableCoverage: boolean;
    clearUserMessages: () => void;
    successUserMessage?: string;
    errorUserMessage?: string;
}

function MyCertificatesView({
    certificateList,
    hasAnyPolicy,
    hasAnyShareableCoverage,
    clearUserMessages,
    successUserMessage,
    errorUserMessage,
}: MyCertificatesViewProps) {
    const navigation = useNavigation();
    const modalNoActivePolices = useModal();
    const modalNoShareableCoverages = useModal();

    if (certificateList === null) {
        // To do: Implement reload
        return null;
    }

    const openSelfServingCertificatesFlow = () => {
        navigation.navigate('/certificates/get-certificate');
    };

    const handleIssueCertificate = () => {
        if (!hasAnyPolicy) {
            modalNoActivePolices.show();
            return;
        }
        if (!hasAnyShareableCoverage) {
            modalNoShareableCoverages.show();
            return;
        }
        openSelfServingCertificatesFlow();
    };

    const displayCertificateHistory = certificateList.length > 0;

    return (
        <PageLayout.Section>
            <NoActivePoliciesModal
                hideModal={modalNoActivePolices.hide}
                modal={modalNoActivePolices}
            />
            <NoShareableCoveragesModal
                hideModal={modalNoShareableCoverages.hide}
                modal={modalNoShareableCoverages}
            />
            <StackLayout gap="64">
                <StackLayout>
                    <ColumnLayout split="1" responsive={{ screenWidth: { smallerThan: 'tablet' } }}>
                        <Text style="heading 2">Get your certificate of insurance instantly!</Text>
                        {displayCertificateHistory ? (
                            <Button onClick={handleIssueCertificate} data-e2e="issue-certificate">
                                Issue Certificate
                            </Button>
                        ) : null}
                    </ColumnLayout>
                </StackLayout>
                {successUserMessage === undefined ? null : (
                    <StatusMessage status="success" onClick={clearUserMessages}>
                        <StackLayout>
                            <ColumnLayout>
                                <Text as="span">{successUserMessage}</Text>
                            </ColumnLayout>
                        </StackLayout>
                    </StatusMessage>
                )}
                {errorUserMessage === undefined ? null : (
                    <StatusMessage status="error" onClick={clearUserMessages}>
                        <StackLayout>
                            <ColumnLayout>
                                <Text as="span">{errorUserMessage}</Text>
                            </ColumnLayout>
                        </StackLayout>
                    </StatusMessage>
                )}
                {displayCertificateHistory ? (
                    <CertificatesHistoryPage certificates={certificateList} />
                ) : (
                    <NoCertificatesPage openCertificateSlideOut={handleIssueCertificate} />
                )}
            </StackLayout>
        </PageLayout.Section>
    );
}
