import { API, TaskGetSignatureDocumentSigningTaskStatusRequest } from '@embroker/shotwell-api/app';
import { Async } from '@embroker/shotwell/core/async';
import { injectable } from '@embroker/shotwell/core/di';
import { Aborted, InvalidArgument, OperationFailed, Timeout } from '@embroker/shotwell/core/Error';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import {
    TasksRepository,
    GetTaskStatusResponse,
    PollForTaskStatusResponse,
    GetPrintTaskStatusResponse,
    PollForPrintTaskStatusResponse,
    PollForSignatureDocumentTaskStatusResponse,
} from './index';
import { isAPIError } from '@embroker/shotwell-api/errors';
import {
    SignatureDocumentNotAllowed,
    SignatureDocumentNotSigned,
} from '../../brokerDashboard/errors';

@injectable()
export class APITasksRepository implements TasksRepository {
    async getTaskStatus(
        id: UUID,
    ): AsyncResult<GetTaskStatusResponse, InvalidArgument | OperationFailed> {
        const getTaskStatusResponse = await API.request('task/get_task_status', { id });
        if (isErr(getTaskStatusResponse)) {
            return handleOperationFailure(getTaskStatusResponse);
        }

        if (getTaskStatusResponse.value.error) {
            return Failure(OperationFailed({ message: getTaskStatusResponse.value.error }));
        }

        return Success(getTaskStatusResponse.value.data);
    }

    async getPrintTaskStatus(
        id: UUID,
    ): AsyncResult<GetPrintTaskStatusResponse, InvalidArgument | OperationFailed> {
        const response = await API.request('task/get_print_task_status', { id });
        if (isErr(response)) {
            return handleOperationFailure(response);
        }

        if (response.value.error) {
            return Failure(OperationFailed({ message: response.value.error }));
        }

        return Success(response.value.document_url);
    }

    async getSignatureDocumentTaskStatus(
        token: string,
    ): AsyncResult<GetTaskStatusResponse, InvalidArgument | OperationFailed> {
        const getTaskStatusResponse = await API.request('task/get_signature_document_task_status', {
            token,
        });
        if (isErr(getTaskStatusResponse)) {
            return handleOperationFailure(getTaskStatusResponse);
        }

        return Success(getTaskStatusResponse.value.data);
    }

    async getSignatureDocumentSigningTaskStatus(
        signatureToken: UUID,
    ): AsyncResult<
        GetTaskStatusResponse,
        InvalidArgument | OperationFailed | SignatureDocumentNotSigned | SignatureDocumentNotAllowed
    > {
        const request: TaskGetSignatureDocumentSigningTaskStatusRequest = {
            signature_token: signatureToken,
        };
        const getTaskStatusResponse = await API.request(
            'task/get_signature_document_signing_task_status',
            request,
        );
        if (isErr(getTaskStatusResponse)) {
            const error = getTaskStatusResponse.errors[0];
            if (isAPIError(error)) {
                if (error.details.name === 'application_not_signed') {
                    return Failure(SignatureDocumentNotSigned());
                }
                if (error.details.name === 'application_signing_not_allowed') {
                    return Failure(SignatureDocumentNotAllowed());
                }
            }
            return handleOperationFailure(getTaskStatusResponse);
        }

        return Success(getTaskStatusResponse.value.data);
    }

    async pollForTaskStatus(
        id: UUID,
        abortSignal?: AbortSignal,
        maxPollingRetries = 120,
        pollingRetryIntervalInMilliseconds = 1000,
    ): AsyncResult<
        PollForTaskStatusResponse,
        InvalidArgument | OperationFailed | Aborted | Timeout
    > {
        let retryCount = 0;
        let isAborted = false;

        if (abortSignal) {
            abortSignal.onabort = () => {
                isAborted = true;
            };
        }

        while (retryCount < maxPollingRetries) {
            if (isAborted) {
                return Failure(Aborted('Polling aborted.'));
            }

            retryCount++;

            const getTaskStatusResult = await this.getTaskStatus(id);

            if (isErr(getTaskStatusResult)) {
                return getTaskStatusResult;
            }

            if (getTaskStatusResult.value) {
                return Success(getTaskStatusResult.value);
            }

            await Async.sleep(pollingRetryIntervalInMilliseconds);
        }

        return Failure(Timeout('Polling max retries exceeded.'));
    }

    async pollForPrintTaskStatus(
        id: UUID,
        abortSignal?: AbortSignal,
        maxPollingRetries = 120,
        pollingRetryIntervalInMilliseconds = 1000,
    ): AsyncResult<
        PollForPrintTaskStatusResponse,
        InvalidArgument | OperationFailed | Aborted | Timeout
    > {
        let retryCount = 0;
        let isAborted = false;

        if (abortSignal) {
            abortSignal.onabort = () => {
                isAborted = true;
            };
        }

        while (retryCount < maxPollingRetries) {
            if (isAborted) {
                return Failure(Aborted('Polling aborted.'));
            }

            retryCount++;

            const getTaskStatusResult = await this.getPrintTaskStatus(id);

            if (isErr(getTaskStatusResult)) {
                return getTaskStatusResult;
            }

            const fileKey = getTaskStatusResult.value;

            if (fileKey) {
                return Success(fileKey);
            }

            await Async.sleep(pollingRetryIntervalInMilliseconds);
        }

        return Failure(Timeout('Polling max retries exceeded.'));
    }

    async pollForSignatureDocumentTaskStatus(
        token: string,
        abortSignal?: AbortSignal,
        maxPollingRetries = 120,
        pollingRetryTimeoutInMilliseconds = 1000,
    ): AsyncResult<
        PollForSignatureDocumentTaskStatusResponse,
        InvalidArgument | OperationFailed | Aborted | Timeout
    > {
        let retryCount = 0;
        let isAborted = false;

        if (abortSignal) {
            abortSignal.onabort = () => {
                isAborted = true;
            };
        }

        while (retryCount < maxPollingRetries) {
            if (isAborted) {
                return Failure(Aborted('Polling aborted.'));
            }

            retryCount++;

            const documentTaskStatusResult = await this.getSignatureDocumentTaskStatus(token);
            if (isErr(documentTaskStatusResult)) {
                return documentTaskStatusResult;
            }

            const taskStatus = documentTaskStatusResult.value;
            if (taskStatus) {
                return Success<PollForSignatureDocumentTaskStatusResponse>(taskStatus);
            }

            await Async.sleep(pollingRetryTimeoutInMilliseconds);
        }

        return Failure(Timeout('Polling max retries exceeded.'));
    }

    async pollForSignatureDocumentSigningTaskStatus(
        signatureToken: UUID,
        abortSignal?: AbortSignal,
        maxPollingRetries = 120,
        pollingRetryTimeoutInMilliseconds = 1000,
    ): AsyncResult<
        PollForSignatureDocumentTaskStatusResponse,
        | InvalidArgument
        | OperationFailed
        | Aborted
        | Timeout
        | SignatureDocumentNotSigned
        | SignatureDocumentNotAllowed
    > {
        let retryCount = 0;
        let isAborted = false;

        if (abortSignal) {
            abortSignal.onabort = () => {
                isAborted = true;
            };
        }

        while (retryCount < maxPollingRetries) {
            if (isAborted) {
                return Failure(Aborted('Polling aborted.'));
            }

            retryCount++;

            const documentTaskStatusResult = await this.getSignatureDocumentSigningTaskStatus(
                signatureToken,
            );
            if (isErr(documentTaskStatusResult)) {
                return documentTaskStatusResult;
            }

            if (documentTaskStatusResult.value) {
                return Success<PollForSignatureDocumentTaskStatusResponse>(
                    documentTaskStatusResult.value,
                );
            }

            await Async.sleep(pollingRetryTimeoutInMilliseconds);
        }

        return Failure(Timeout('Polling max retries exceeded.'));
    }
}
