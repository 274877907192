import { OracleAnswerTypeDefinition } from '../OracleAnswerType';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { deserializeNumberAnswers, serializeNumber } from './number';

export const IntegerDefinition: OracleAnswerTypeDefinition = {
    answerKeyType: 'integer',
    schemaFunctions: {
        validator: Joi.number(),
        serializeAnswer: serializeNumber,
        deserializeAnswer: deserializeNumberAnswers,
    },
};
