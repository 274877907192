import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { Location } from '@embroker/shotwell/core/types/Location';
import {
    defineValidator,
    Joi,
    Schema,
    TypeChecker,
    Validator,
} from '@embroker/shotwell/core/validation/schema';

/**
 * Represent CertificateProducer Object
 */

export interface CertificateProducer {
    /**
     * Short name of the certificate producer
     */
    certificateCreator: string;
    /**
     * Full name of the certificate producer
     */
    name: string;
    /**
     * Contact name of the certificate producer
     */
    contactName?: string;
    /**
     * Email of the certificate producer
     */
    email: EmailAddress;
    /**
     * Location of the certificate producer
     */
    location: Location;
}

export interface CertificateProducerValidator {
    /**
     * A Joi schema matching a valid CertificateProducer object.
     */
    readonly schema: Schema.ObjectSchema<CertificateProducer>;
    /**
     * Type predicate that checks if a given value can be used as CertificateProducer object.
     *
     * Use this only to do early returns. It's recommended to use validate()
     * before using an unknown value as CertificateProducer object as it normalizes the value
     * in addition to performing the same validation as check().
     */
    readonly check: TypeChecker<CertificateProducer>;
    /**
     * Validates and normalizes the given value to a CertificateProducer object.
     *
     * This should be used for all untrusted inputs to verify and, if required
     * (and possible), normalize the input.
     */
    readonly validate: Validator<CertificateProducer>;
}

export const CertificateProducer = {
    ...defineValidator<CertificateProducer>(
        Joi.object({
            certificateCreator: Joi.string(),
            name: Joi.string(),
            contactName: Joi.string().optional(),
            email: EmailAddress.schema,
            location: Location.schema,
        }).preferences({ presence: 'required' }),
    ),
    create(certificateProducer: CertificateProducer) {
        return CertificateProducer.validate(certificateProducer);
    },
};
