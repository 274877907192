import { QuestionnaireData } from '../../../types/BundleQuoteQuestionnaireData';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface BOPChubbQuestionnaireData extends QuestionnaireData {
    ownership_of_vehicles: boolean;
    delivery_with_rental_or_owned_vehicles: boolean;
}

export const BOPChubbQuestionnaireData = {
    ...defineValidator<BOPChubbQuestionnaireData>(
        QuestionnaireData.schema.keys({
            ownership_of_vehicles: Joi.boolean(),
            delivery_with_rental_or_owned_vehicles: Joi.boolean(),
        }),
    ),
    create(questionnaireData: BOPChubbQuestionnaireData) {
        return BOPChubbQuestionnaireData.validate(questionnaireData);
    },
};
