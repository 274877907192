import { ShoppingCoverageCodeListMap } from '@embroker/shotwell-api/enums';
import { Immutable } from '@embroker/shotwell/core/types';
import { URI } from '@embroker/shotwell/core/types/URI';
import {
    Button,
    CenterLayout,
    FormLayout,
    Image,
    StackLayout,
    Text,
    TextButton,
    WizardLayout,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { Link } from '../../../../view/components';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { AppTypeCode, AppTypeLabelMap, ShoppingCoverage } from '../../../types/enums';
import { RedirectApplicationData } from '../../../types/RedirectApplicationDataArray';

export interface PartialQuoteRedirectProps {
    readonly eligibleShoppingCoverages: Immutable<Array<ShoppingCoverage>>;
    readonly redirectApplicationListData: Immutable<Array<RedirectApplicationData>>;
    readonly quoteURI: URI;
}

export function PartialQuoteRedirect({
    eligibleShoppingCoverages,
    redirectApplicationListData,
    quoteURI,
}: PartialQuoteRedirectProps) {
    const hasMultipleRedirectApplications = redirectApplicationListData.length > 1;
    const { navigate } = useNavigation();
    return (
        <WizardLayout
            title=""
            onDismiss={() => {
                navigate(URI`/summary`);
            }}
        >
            <FormLayout>
                <StackLayout center>
                    <Image name="shotwell-business-2" />
                    <Text style="heading 2" data-e2e="header">
                        Your quote is ready.
                    </Text>
                    <CenterLayout centerText>
                        <Text style="body 1" data-e2e="body">
                            Based on your responses, a quote is ready for
                            {` ${renderShoppingCoverages(eligibleShoppingCoverages)} `}
                            {eligibleShoppingCoverages.length > 1 ? 'coverages' : 'coverage'}.
                        </Text>
                    </CenterLayout>
                    <Button as={Link} href={quoteURI} data-e2e="see-quote">
                        See quote
                    </Button>
                    <CenterLayout centerText>
                        <Text style="body 1">
                            Some additional questions are required for your{' '}
                            {hasMultipleRedirectApplications
                                ? 'other coverages'
                                : `${renderAppTypes(
                                      redirectApplicationListData[0].appTypeList,
                                  )} coverage:`}
                        </Text>
                    </CenterLayout>
                    {redirectApplicationListData.map(({ id, appTypeList }) => {
                        return (
                            <TextButton
                                as={Link}
                                key={id}
                                href={URI.build(`/shopping/application`, { applicationId: id })}
                                data-e2e="link"
                            >
                                {renderAppTypes(appTypeList)}
                            </TextButton>
                        );
                    })}
                </StackLayout>
            </FormLayout>
        </WizardLayout>
    );
}

export function renderAppTypes(appTypes: Immutable<Array<AppTypeCode>>): string {
    const output: Array<string> = [];

    appTypes.forEach(function (appType, index) {
        // if list is more than one item and this is the last item, prefix with 'and '
        if (appTypes.length > 1 && index === appTypes.length - 1) {
            output.push('and ');
        }
        // output the item
        output.push(AppTypeLabelMap[appType]);
        // if list is more than 2 items, append a comma to all but the last item
        if (appTypes.length > 2 && index < appTypes.length - 1) {
            output.push(',');
        }
        // if list is more than 1 item, append a space to all but the last item
        if (appTypes.length > 1 && index < appTypes.length - 1) {
            output.push(' ');
        }
    });

    return output.join('');
}

export function renderShoppingCoverages(
    shoppingCoverages: Immutable<Array<ShoppingCoverage>>,
): string {
    const output: Array<string> = [];

    if (shoppingCoverages) {
        shoppingCoverages.forEach(function (shoppingCoverage, index) {
            // if list is more than one item and this is the last item, prefix with 'and '
            if (shoppingCoverages.length > 1 && index === shoppingCoverages.length - 1) {
                output.push('and ');
            }
            // output the item
            output.push(ShoppingCoverageCodeListMap[shoppingCoverage]);
            // if list is more than 2 items, append a comma to all but the last item
            if (shoppingCoverages.length > 2 && index < shoppingCoverages.length - 1) {
                output.push(',');
            }
            // if list is more than 1 item, append a space to all but the last item
            if (shoppingCoverages.length > 1 && index < shoppingCoverages.length - 1) {
                output.push(' ');
            }
        });
    }

    return output.join('');
}
