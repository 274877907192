import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject } from '@embroker/shotwell/core/di';
import { BrokerRepository, DataPoint, IndexOptions, RawBarChartData } from '../repositories';

export interface GetDigitalQuotesData extends UseCase {
    execute(
        indexOptions: IndexOptions,
    ): AsyncResult<RawBarChartData, InvalidArgument | OperationFailed>;
}

class GetDigitalQuotesDataUseCase extends UseCase implements GetDigitalQuotesData {
    public static type = Symbol('Broker/GetDigitalQuotesData');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BrokerRepository) private brokerRepository: BrokerRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        indexOptions: IndexOptions,
    ): AsyncResult<RawBarChartData, InvalidArgument | OperationFailed> {
        const result = await this.brokerRepository.getDigitalQuotesData();
        if (isErr(result)) {
            return result;
        }

        const dataPoints: DataPoint[] = [];
        const groupNames: string[] = [];

        result.value.forEach((data) => {
            dataPoints.push({
                x: indexOptions.indexBy == 'x' ? data.quote_date : data.product_count,
                y: indexOptions.indexBy != 'x' ? data.quote_date : data.product_count,
            });
            groupNames.push(data.product_type);
        });

        return Success({ dataPoints, groupNames });
    }
}

export const GetDigitalQuotesData: UseCaseClass<GetDigitalQuotesData> = GetDigitalQuotesDataUseCase;
