import { container } from '@embroker/shotwell/core/di';
import { JSONSerdes } from '@embroker/shotwell/core/encoding';
import { OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { equal } from '@embroker/shotwell/core/object';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { Failure, isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { FloatingLayout, Spinner, useModal } from '@embroker/ui-toolkit/v2';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ApplicationEarlyClearanceFailed } from '../../../../shopping/entities/Application';
import { ErrorCode } from '../../../../shopping/errors';
import { AppTypeCode, InCreation, ShoppingCoverage } from '../../../../shopping/types/enums';
import { ExpandedApplicationView } from '../../../../shopping/useCases/GetApplication';
import { GetApplicationLastPage } from '../../../../shopping/useCases/GetApplicationLastPage';
import { GetApplicationStatus } from '../../../../shopping/useCases/GetApplicationStatus';
import { FormEventName, PublishFormEvents } from '../../../../shopping/useCases/PublishFormEvents';
import { SaveApplicationLastPage } from '../../../../shopping/useCases/SaveApplicationLastPage';
import { UpdateApplicationQuestionnaire } from '../../../../shopping/useCases/UpdateApplicationQuestionnaire';
import { ExitConfirmationModal } from '../../../../shopping/view/components/ExitConfirmationModal';
import { MissingInformationModal } from '../../../../shopping/view/components/MissingInformationModal';
import { AppContext } from '../../../../view/AppContext';
import { FormEngine } from '../../../../view/components';
import { ApplicationQuestionnaireTitle } from '../../../../view/components/ApplicationQuestionnaireTitle.view';
import { useFormEngineEvent } from '../../../../view/components/FormEngine/hooks/useFormEngineEvent';
import { navigateToErrorPage } from '../../../../view/errors';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { GetApplicationInCreationQuestionnaire } from '../../../useCases/GetApplicationInCreationQuestionnaire';
import { PromoteApplication } from '../../../useCases/PromoteApplication';
import { getFormPagesInvalidIdList } from '../shareableApplications/clientApplicationEdit/EditApplication';
import { BrokerApplicationInfoBar } from './BrokerApplicationInfoBar';

interface ApplicationCreationQuestionnaireProps {
    applicationId: UUID;
    page?: string;
}

const progressBarRange = { max: 20 };

export function BrokerApplication(props: ApplicationCreationQuestionnaireProps): JSX.Element {
    const { navigate } = useNavigation();
    const { activeSession } = useContext(AppContext);
    const { result: applicationStatusResult } = useUseCase(GetApplicationStatus, {
        applicationId: props.applicationId,
    });
    const [isLoadingStatus, setIsLoadingStatus] = useState(true);

    useEffect(() => {
        if (applicationStatusResult !== undefined && isOK(applicationStatusResult)) {
            if (applicationStatusResult.value.status === InCreation) {
                setIsLoadingStatus(false);
            } else {
                navigate('/broker/dashboard');
            }
        }
    }, [applicationStatusResult, activeSession, navigate]);

    if (isLoadingStatus || applicationStatusResult === undefined) {
        return <Spinner />;
    }

    if (isErr(applicationStatusResult)) {
        return <ErrorPage errors={applicationStatusResult.errors} />;
    }

    return <ApplicationInCreationQuestionnaire {...props} />;
}

export function ApplicationInCreationQuestionnaire({
    applicationId,
    page,
}: ApplicationCreationQuestionnaireProps) {
    const { navigate } = useNavigation();
    const { activeSession } = useContext(AppContext);
    const [formEngine, setFormEngine] = useState<any>(null);
    const [currentApplication, setCurrentApplication] =
        useState<Immutable<ExpandedApplicationView>>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [savedQuestionnaireData, setSavedQuestionnaireData] = useState(null);
    const [firstInvalidPageId, setFirstInvalidPageId] = useState<Nullable<string>>(null);
    const [lastViewedPage, setLastViewedPage] = useState<string>('');
    const missingInformationModal = useModal();
    const exitConfirmationModal = useModal();

    const defaultPage = 'additional_company_details';

    const { result } = useUseCase(GetApplicationInCreationQuestionnaire, {
        applicationId,
    });
    const eventBus = useMemo(() => container.get<DomainEventBus>(DomainEventBus), []);

    useEffect(() => {
        if (result && isOK(result)) {
            const { application, formEngine } = result.value;
            setCurrentApplication(application as Immutable<ExpandedApplicationView>);
            setFormEngine(formEngine);
        }
    }, [result]);

    useEffect(() => {
        if (formEngine) {
            publishFormEvent(
                FormEventName.Viewed,
                formEngine,
                applicationId,
                currentApplication?.appType,
                currentApplication?.shoppingCoverageList,
                currentApplication?.isRenewal,
            );
            return () => {
                formEngine.dispose();
            };
        }
    }, [currentApplication, formEngine, applicationId]);

    useEffect(() => {
        let isMounted = true;
        execute(GetApplicationLastPage, {
            userId: activeSession.userId as UUID,
            applicationId,
        }).then((result) => {
            if (isMounted && isOK(result)) {
                setLastViewedPage(result.value.lastPage);
            }
        });
        return () => {
            isMounted = false;
        };
    }, [activeSession.userId, applicationId]);

    useEffect(() => {
        setFormEngine(null);
    }, [applicationId]);

    const navigateAfterModalConfirmation = () => {
        if (!firstInvalidPageId) {
            return;
        }
        formEngine.gotoPage(firstInvalidPageId);
    };

    const onConfirmExitApplication = async () => {
        const questionnaireData = formEngine.getSnapshot().value;
        await execute(UpdateApplicationQuestionnaire, {
            applicationId,
            questionnaireData: JSON.stringify(questionnaireData),
        });

        navigate('/broker/dashboard');
        return;
    };

    useFormEngineEvent(formEngine, 'show', () => {
        async function showHandler() {
            const {
                machine: {
                    state: { visiblePage, pageList },
                },
            } = formEngine;

            execute(SaveApplicationLastPage, {
                applicationId,
                userId: activeSession.userId as UUID,
                lastPage: pageList[visiblePage].id,
            });

            const questionnaireData = formEngine.getSnapshot().value;
            setSavedQuestionnaireData(questionnaireData);
            if (equal(savedQuestionnaireData, questionnaireData)) {
                return;
            }

            let questionnaireDataString;
            try {
                questionnaireDataString = JSON.stringify(questionnaireData);
            } catch (error) {
                return Failure(
                    OperationFailed({ message: 'Failed to stringify questionnaire data' }),
                );
            }

            const updateApplicationResponse = await execute(UpdateApplicationQuestionnaire, {
                applicationId,
                questionnaireData: questionnaireDataString,
            });
            if (isErr(updateApplicationResponse)) {
                return;
            }
        }
        showHandler().catch((error) => {
            navigateToErrorPage(navigate, error);
        });
    });

    useFormEngineEvent(formEngine, 'hide', () => {
        publishFormEvent(
            FormEventName.Saved,
            formEngine,
            applicationId,
            currentApplication?.appType,
            currentApplication?.shoppingCoverageList,
            currentApplication?.isRenewal,
        );
    });

    useFormEngineEvent(formEngine, 'onbeforesubmit', () => {
        const formPagesInvalidIdList = getFormPagesInvalidIdList(formEngine);
        if (formPagesInvalidIdList.length === 0) {
            return;
        }
        const invalidPageId = formPagesInvalidIdList[0];
        setFirstInvalidPageId(invalidPageId);
        missingInformationModal.show();
    });

    useFormEngineEvent(formEngine, 'submit', () => {
        async function handleSubmit() {
            publishFormEvent(
                FormEventName.Saved,
                formEngine,
                applicationId,
                currentApplication?.appType,
                currentApplication?.shoppingCoverageList,
                currentApplication?.isRenewal,
            );
            setIsSubmitting(true);
            const questionnaireData = formEngine.getSnapshot().value;
            const promoteApplicationsResponse = await execute(PromoteApplication, {
                applicationId,
                questionnaireData,
            });

            if (isErr(promoteApplicationsResponse)) {
                if (promoteApplicationsResponse.errors[0].code === ErrorCode.NameClearanceError) {
                    const event: ApplicationEarlyClearanceFailed = {
                        id: UUID.create(),
                        origin: 'Application',
                        name: 'EarlyClearanceFailed',
                        createdAt: new Date(Date.now()),
                    };
                    await eventBus.publish(event);
                    navigate('/shopping/early-quote-reservation');
                    return;
                }
                container.get<Logger>(Log).error(promoteApplicationsResponse.errors);
                if (
                    promoteApplicationsResponse.errors[0].code ===
                    ErrorCode.AppTypeNotAllowedForBroker
                ) {
                    navigate('/broker-declined');
                    return;
                }
                navigate('/broker/application/error');
                return;
            }

            const uri = URI.build('/shopping/application/additional-company-details', {
                applicationId,
            });
            navigate(uri);
        }
        handleSubmit().catch((error) => {
            navigateToErrorPage(navigate, error);
        });
    });

    if (result && isErr(result)) {
        return <ErrorPage errors={result.errors} />;
    }

    if (!formEngine || isSubmitting) {
        return <Spinner />;
    }

    return (
        <React.Fragment>
            <ExitConfirmationModal
                modal={exitConfirmationModal}
                onExitApplication={() => {
                    publishFormEvent(
                        FormEventName.SaveAndExitModalClicked,
                        formEngine,
                        applicationId,
                        currentApplication?.appType,
                        currentApplication?.shoppingCoverageList,
                        currentApplication?.isRenewal,
                    );
                    onConfirmExitApplication();
                }}
            />
            <MissingInformationModal
                modal={missingInformationModal}
                navigateAfterModalConformation={navigateAfterModalConfirmation}
            />
            <FormEngine
                instance={formEngine}
                navigation
                onExitFullScreen={() => {
                    publishFormEvent(
                        FormEventName.SaveAndExitClicked,
                        formEngine,
                        applicationId,
                        currentApplication?.appType,
                        currentApplication?.shoppingCoverageList,
                        currentApplication?.isRenewal,
                    );
                    exitConfirmationModal.show();
                }}
                progressBarRange={progressBarRange}
                page={page || lastViewedPage || defaultPage}
                progressBarTitleRenderer={ApplicationQuestionnaireTitle}
                dismissAppearance="save-and-exit"
            />
            <FloatingLayout position="bottom">
                <BrokerApplicationInfoBar application={currentApplication} />
            </FloatingLayout>
        </React.Fragment>
    );
}

function publishFormEvent(
    eventName: FormEventName,
    formEngine: any,
    applicationId: UUID,
    appType?: AppTypeCode,
    shoppingCoverageList?: readonly ShoppingCoverage[],
    isRenewal?: boolean,
) {
    const visiblePage = formEngine.machine.state.visiblePage;
    const pageList = formEngine.machine.state.pageList;
    let formName = pageList[visiblePage]?.machine.state.title;
    if (formName === undefined) {
        formName = 'Basic Info';
    }

    const appTypeList = appType ? [appType] : [];

    const snapshot = formEngine.getSnapshot();
    const questionnaireData = snapshot.value;

    const serializedQuestionnaireData = JSONSerdes.serialize(questionnaireData);

    execute(PublishFormEvents, {
        eventName,
        formStage: 'Company Profile',
        formName,
        applicationId,
        appTypeList,
        questionnaireData: isOK(serializedQuestionnaireData)
            ? serializedQuestionnaireData.value
            : undefined,
        shoppingCoverageList,
        isRenewal: isRenewal ?? false,
    });
}
