import { ContainerModule } from '@embroker/shotwell/core/di';
import { Module } from '../Module';
import { InvoiceRepository } from './repositories/InvoiceRepository';
import { APIInvoiceRepository } from './repositories/InvoiceRepository/APIInvoiceRepository';
import { QuoteRepository } from './repositories/QuoteRepository';
import { APIQuoteRepository } from './repositories/QuoteRepository/APIQuoteRepository';
import { DownloadPDFAgreement } from './useCases/DownloadPDFAgreement';
import { GenerateDocumentLink } from './useCases/GenerateDocumentLink';
import { GetDirectBillPolicies } from './useCases/GetDirectBillPolicies';
import { GetInvoiceHistory } from './useCases/GetInvoiceHistory';
import { GetPendingInvoices } from './useCases/GetPendingInvoices';
import { GetPremiumFinanceQuote } from './useCases/GetPremiumFinanceQuote';
import { GetPremiumFinanceQuoteIndication } from './useCases/GetPremiumFinanceQuoteIndication';
import { GetPremiumFinanceTaskIds } from './useCases/GetPremiumFinanceTaskIds';
import { GetPublicInvoice } from './useCases/GetPublicInvoice';
import { GetSelectedInvoiceList } from './useCases/GetSelectedInvoiceList';
import { LoadStripe } from './useCases/LoadStripe';
import { PayByACH } from './useCases/PayByACH';
import { PayByCC, PayByCreditCard } from './useCases/PayByCreditCard';
import { PayByPremiumFinance } from './useCases/PayByPremiumFinance';
import { PremiumFinanceUpdateBillingInfoACH } from './useCases/PremiumFinanceUpdateBillingInfoACH';
import { PremiumFinanceUpdateBillingInfoCreditCard } from './useCases/PremiumFinanceUpdateBillingInfoCreditCard';
import { SendIneligiblePayments } from './useCases/SendIneligiblePayments';
import { SumInvoicesToPay } from './useCases/SumInvoicesToPay';
import { routes } from './view/routes';
import { GetBundlePublicInvoice } from './useCases/GetBundlePublicInvoice';
import { PublishPaymentCTAClickEvent } from './useCases/PaymentCTAClickEvent';
import { PayByAscend } from './useCases/PayByAscend';
import { GetConfig } from './useCases/GetConfig';
import { UpdateBillingInfo } from './useCases/UpdateBillingInfo';
import { GetBillingInfo } from './useCases/GetBillingInfo';
import { BillingInfoRepository } from './repositories/BillingInfoRepository';
import { APIBillingInfoRepository } from './repositories/BillingInfoRepository/APIBillingInfoRepository';

/**
 * Payments module declarations container.
 */
export const PaymentsModule: Module = {
    routes,
    container: new ContainerModule((bind) => {
        bind<InvoiceRepository>(InvoiceRepository).to(APIInvoiceRepository).inSingletonScope();
        bind<QuoteRepository>(QuoteRepository).to(APIQuoteRepository).inSingletonScope();
        bind<BillingInfoRepository>(BillingInfoRepository)
            .to(APIBillingInfoRepository)
            .inSingletonScope();
        bind<LoadStripe>(LoadStripe.type).to(LoadStripe).inSingletonScope();
        bind<GetPendingInvoices>(GetPendingInvoices.type).to(GetPendingInvoices).inSingletonScope();
        bind<SendIneligiblePayments>(SendIneligiblePayments.type)
            .to(SendIneligiblePayments)
            .inSingletonScope();
        bind<GetInvoiceHistory>(GetInvoiceHistory.type).to(GetInvoiceHistory).inSingletonScope();
        bind<SumInvoicesToPay>(SumInvoicesToPay.type).to(SumInvoicesToPay).inSingletonScope();
        bind<PayByACH>(PayByACH.type).to(PayByACH).inSingletonScope();
        bind<PayByCreditCard>(PayByCC.type).to(PayByCC).inSingletonScope();
        bind<PayByAscend>(PayByAscend.type).to(PayByAscend).inSingletonScope();
        bind<PayByPremiumFinance>(PayByPremiumFinance.type)
            .to(PayByPremiumFinance)
            .inSingletonScope();
        bind<GetPremiumFinanceTaskIds>(GetPremiumFinanceTaskIds.type)
            .to(GetPremiumFinanceTaskIds)
            .inSingletonScope();
        bind<GetPremiumFinanceQuote>(GetPremiumFinanceQuote.type)
            .to(GetPremiumFinanceQuote)
            .inSingletonScope();
        bind<GenerateDocumentLink>(GenerateDocumentLink.type)
            .to(GenerateDocumentLink)
            .inSingletonScope();
        bind<DownloadPDFAgreement>(DownloadPDFAgreement.type)
            .to(DownloadPDFAgreement)
            .inSingletonScope();
        bind<GetPublicInvoice>(GetPublicInvoice.type).to(GetPublicInvoice).inSingletonScope();
        bind<GetSelectedInvoiceList>(GetSelectedInvoiceList.type)
            .to(GetSelectedInvoiceList)
            .inSingletonScope();
        bind<GetBundlePublicInvoice>(GetBundlePublicInvoice.type)
            .to(GetBundlePublicInvoice)
            .inSingletonScope();
        bind<GetDirectBillPolicies>(GetDirectBillPolicies.type)
            .to(GetDirectBillPolicies)
            .inSingletonScope();
        bind<GetPremiumFinanceQuoteIndication>(GetPremiumFinanceQuoteIndication.type)
            .to(GetPremiumFinanceQuoteIndication)
            .inSingletonScope();
        bind<PremiumFinanceUpdateBillingInfoACH>(PremiumFinanceUpdateBillingInfoACH.type)
            .to(PremiumFinanceUpdateBillingInfoACH)
            .inSingletonScope();
        bind<PremiumFinanceUpdateBillingInfoCreditCard>(
            PremiumFinanceUpdateBillingInfoCreditCard.type,
        )
            .to(PremiumFinanceUpdateBillingInfoCreditCard)
            .inSingletonScope();
        bind<PublishPaymentCTAClickEvent>(PublishPaymentCTAClickEvent.type)
            .to(PublishPaymentCTAClickEvent)
            .inSingletonScope();
        bind<GetConfig>(GetConfig.type).to(GetConfig).inSingletonScope();
        bind<UpdateBillingInfo>(UpdateBillingInfo.type).to(UpdateBillingInfo).inSingletonScope();
        bind<GetBillingInfo>(GetBillingInfo.type).to(GetBillingInfo).inSingletonScope();
    }),
};
