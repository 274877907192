import { Immutable } from '@embroker/shotwell/core/types';
import { URI } from '@embroker/shotwell/core/types/URI';
import {
    CenterLayout,
    FormLayout,
    Image,
    StackLayout,
    Text,
    TextButton,
    WizardLayout,
} from '@embroker/ui-toolkit/v2';
import { Link } from '../../../../view/components';
import React from 'react';
import { RedirectApplicationDataArray } from '../../../types/RedirectApplicationDataArray';
import { renderAppTypes } from './PartialQuoteRedirect';
import { useNavigation } from '../../../../view/hooks/useNavigation';

export interface FullRedirectChooseProps {
    redirectApplicationDataList: Immutable<RedirectApplicationDataArray>;
}

export function FullRedirectChoose({ redirectApplicationDataList }: FullRedirectChooseProps) {
    const { navigate } = useNavigation();
    return (
        <WizardLayout
            title=""
            onDismiss={() => {
                navigate(URI`/summary`);
            }}
        >
            <FormLayout>
                <StackLayout gap="32" center>
                    <Image name="shotwell-business-2" />
                    <Text style="heading 2" data-e2e="header">
                        Let's dig deeper.
                    </Text>
                    <CenterLayout centerText>
                        <Text style="body 1" data-e2e="body">
                            Based on your responses, we need to ask you a few more questions to get
                            you covered.
                        </Text>
                    </CenterLayout>
                    <Text style="heading 4">Which coverages do you want to pursue first?</Text>
                    {redirectApplicationDataList.map((item) => (
                        <TextButton
                            as={Link}
                            key={item.id}
                            href={URI.build('/shopping/application', { applicationId: item.id })}
                            data-e2e="link"
                        >
                            {renderAppTypes(item.appTypeList)}
                        </TextButton>
                    ))}
                </StackLayout>
            </FormLayout>
        </WizardLayout>
    );
}
