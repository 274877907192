import { Joi } from '@embroker/shotwell/core/validation/schema';
import { defineEntityValidator, entity, Entity } from '@embroker/shotwell/core/entity/Entity';
import { ProductDefinition } from '../types/ProductDefinition';

export interface Product extends Entity {
    /**
     * Version of product definition
     */
    version: string;
    /**
     * JSON representation of product definition
     */
    definition: ProductDefinition;
    /**
     * Effective date of product definition
     */
    effectiveDate: Date;
    /**
     * Description of product definition
     */
    description: string;
}

export const Product = entity<Product>({
    validator: defineEntityValidator<Product>({
        version: Joi.string().required(),
        definition: ProductDefinition.schema.required(),
        effectiveDate: Joi.date().required(),
        description: Joi.string().required(),
    }),
});
