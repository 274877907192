import { injectable } from '@embroker/shotwell/core/di';
import { deepClone } from '@embroker/shotwell/core/object';
import { PolicyFilter } from '../../types/PolicyFilter';
import { PolicySortByAlphabet } from '../../types/PolicySortBy';
import { PolicyFilterRepository } from './index';

const defaultValue: PolicyFilter = {
    showActive: true,
    showInactive: false,
    sortBy: PolicySortByAlphabet,
};

@injectable()
export class PolicyFilterRepositoryImpl implements PolicyFilterRepository {
    private filter: PolicyFilter = deepClone(defaultValue);

    public get(): PolicyFilter {
        return deepClone(this.filter);
    }

    public getDefault(): PolicyFilter {
        return deepClone(defaultValue);
    }

    public save(filter: PolicyFilter): PolicyFilter {
        Object.assign(this.filter, filter);
        return deepClone(filter);
    }
}
