import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Immutable } from '@embroker/shotwell/core/types';
import { FailureResult, isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { PageLayout, Spinner, StackLayout, usePrevious } from '@embroker/ui-toolkit/v2';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { DisplayPolicy } from '../../../../policy/types/DisplayPolicy';
import { PolicySortByAlphabet } from '../../../../policy/types/PolicySortBy';
import { GetPolicies } from '../../../../policy/useCases/GetPolicies';
import { Application } from '../../../../shopping/entities/Application';
import * as enums from '../../../../shopping/types/enums';
import {
    AppTypeCode,
    QuestionnaireInProgress,
    QuotesReady,
    Referred,
} from '../../../../shopping/types/enums';
import { GetApplicationList } from '../../../../shopping/useCases/GetApplicationList';
import { AppContext } from '../../../../view/AppContext';
import { BrokerageOrganizationInfo } from '../../../types/BrokerageOrganizationInfo';
import { GetBrokerageOrganizationInfo } from '../../../useCases/GetBrokerageOrganizationInfo';
import { GetAQuoteWizard } from '../getAQuoteWizard/GetAQuoteWizard';
import { ClientActionButtons } from './ClientActionButtons';
import { ClientInfoTable } from './ClientInfoTable';
import { ClientPageHeader } from './ClientPageHeader';
import { ClientPagePolicies } from './ClientPagePolicies';
import { ClientPageQuotes } from './ClientPageQuotes';
import { ClientPageRenewals } from './ClientPageRenewals';
import {
    GetGlobalConfig,
    GetGlobalConfigUseCaseResponse,
} from '@app/config/useCases/GetGlobalConfigUseCase';

function hasRenewalActionStatus(applicationStatus: enums.InsuranceApplicationStatusCode): boolean {
    return (
        applicationStatus === QuotesReady ||
        applicationStatus === QuestionnaireInProgress ||
        applicationStatus === Referred
    );
}

function brokerRenewalApplicationFilter(
    application: Immutable<EntityProps<Application>>,
    isRenewalDisplay: boolean,
) {
    return isRenewalDisplay
        ? application.renewedPolicyIdList.length > 0 && hasRenewalActionStatus(application.status)
        : application.renewedPolicyIdList.length == 0 ||
        !hasRenewalActionStatus(application.status);
}

interface BrokerClientV2PageProps {
    organizationId: UUID;
}

export function BrokerClientPage({ organizationId }: BrokerClientV2PageProps) {
    const previousOrganizationId = usePrevious(organizationId);
    const { setSlideout } = useContext(AppContext);
    const [errors, setErrors] = useState<FailureResult<never>['errors']>([]);
    const [policies, setPolicies] = useState<Immutable<DisplayPolicy[]> | undefined>(undefined);
    const [applications, setApplications] = useState<Immutable<Array<EntityProps<Application>>>>(
        [],
    );
    const [renewals, setRenewals] = useState<Immutable<Array<EntityProps<Application>>>>([]);
    const [organizationInfo, setOrganizationInfo] = useState<BrokerageOrganizationInfo | undefined>(
        undefined,
    );
    const [globalConfig, setGlobalConfig] = useState<GetGlobalConfigUseCaseResponse | undefined>(
        undefined,
    );
    const { result: orgInfoResult } = useUseCase(GetBrokerageOrganizationInfo, {
        orgId: organizationId,
    });
    const { result: applicationsResult, reload: reloadApplications } =
        useUseCase(GetApplicationList);

    const { result: activePoliciesResult, reload: reloadPolicies } = useUseCase(GetPolicies, {
        filter: {
            showActive: true,
            showInactive: true,
            sortBy: PolicySortByAlphabet,
        },
    });

    const { result: globalConfigResult } = useUseCase(GetGlobalConfig);

    const initialData = useMemo(
        () => ({ company: { name: organizationInfo?.name, id: organizationId } }),
        [organizationInfo, organizationId],
    );

    const handleGetQuote = useCallback(
        () => setSlideout(<GetAQuoteWizard initialData={initialData} hideCompanyPage />),
        [setSlideout, initialData],
    );

    useEffect(() => {
        if (previousOrganizationId !== undefined && previousOrganizationId !== organizationId) {
            reloadApplications();
            reloadPolicies();
        }
    }, [organizationId, previousOrganizationId, reloadApplications, reloadPolicies]);

    useEffect(() => {
        if (applicationsResult && isOK(applicationsResult)) {
            setApplications(
                applicationsResult.value.applicationList.filter((app) =>
                    brokerRenewalApplicationFilter(app, false),
                ),
            );
            setRenewals(
                applicationsResult.value.applicationList.filter((app) =>
                    brokerRenewalApplicationFilter(app, true),
                ),
            );
        } else if (applicationsResult && isErr(applicationsResult)) {
            setApplications([]);
            setRenewals([]);
        }
    }, [applicationsResult]);

    useEffect(() => {
        if (activePoliciesResult && isOK(activePoliciesResult)) {
            setPolicies(activePoliciesResult.value.policyList);
        } else if (activePoliciesResult && isErr(activePoliciesResult)) {
            setPolicies([]);
        }
    }, [activePoliciesResult]);

    useEffect(() => {
        if (orgInfoResult && isOK(orgInfoResult)) {
            setOrganizationInfo(orgInfoResult.value);
        } else if (orgInfoResult && isErr(orgInfoResult)) {
            setErrors(orgInfoResult.errors);
        }
    }, [orgInfoResult]);

    useEffect(() => {
        setSlideout(null);
        return () => {
            setSlideout(null);
        };
    }, [organizationId, setSlideout]);

    useEffect(() => {
        if (globalConfigResult && isOK(globalConfigResult)) {
            setGlobalConfig(globalConfigResult.value);
        } else if (globalConfigResult && isErr(globalConfigResult)) {
            setErrors(globalConfigResult.errors);
        }
    }, [globalConfigResult]);

    const OrgDetailsErrorPage = useMemo(() => {
        return <ErrorPage errors={errors} />;
    }, [errors]);

    if (errors.length > 0) {
        return OrgDetailsErrorPage;
    }

    if (
        organizationInfo == undefined ||
        policies == undefined ||
        applications == undefined ||
        renewals == undefined ||
        globalConfig === undefined
    ) {
        return <Spinner />;
    }

    return (
        <PageLayout.Section>
            <StackLayout gap="48">
                <ClientPageHeader onQuoteStart={handleGetQuote} />
                <StackLayout gap="32">
                    <ClientInfoTable organizationInfo={organizationInfo} />
                    <ClientActionButtons organizationId={organizationId} />
                    <ClientPageRenewals
                        renewals={renewals}
                        activePolicies={policies}
                        orgId={organizationId}
                    />
                </StackLayout>
                <ClientPagePolicies activePolicies={policies} />
                <ClientPageQuotes
                    applications={applications}
                    organizationId={organizationId}
                    excessRenewalsEnabled={globalConfig.config.excessRenewalsEnabled}
                />
            </StackLayout>
        </PageLayout.Section>
    );
}

export function getCoverageLabel(code: AppTypeCode): string {
    switch (code) {
        case 'AppTypeCodeListESP':
            return 'Startup Package';
        case 'AppTypeCodeListEverestLawyersProfessionalLiability':
            return 'Lawyers Professional Liability';
        case 'AppTypeCodeListPCoML':
            return 'Private Company Management Liability';
        case 'AppTypeCodeListEmbrokerCrime':
            return 'Commercial Crime';
        case 'AppTypeCodeListEmbrokerCyber':
            return 'Cyber';
        case 'AppTypeCodeListEmbrokerExcess':
            return 'Excess';
        default:
            return 'N/A';
    }
}
