import { Money } from '@embroker/shotwell/core/types/Money';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import {
    CenterLayout,
    ColumnLayout,
    Text,
    LayerCard,
    Icon,
    StackLayout,
    layerCardBackgrounds,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { ordinal } from './TowerStructure';

export interface EmbrokerTowerCardProps {
    limit: number;
    layersCount: number;
}

export const EmbrokerTowerCard = function ({ limit, layersCount }: EmbrokerTowerCardProps) {
    const background = layerCardBackgrounds[0];
    return (
        <LayerCard background={background} key={-1}>
            <CenterLayout as={ColumnLayout} gap="none">
                <StackLayout gap="none">
                    <Text style="heading 5" color="ui-50">{`${ordinal(
                        layersCount + 1,
                    )} Excess - Embroker`}</Text>

                    <Text color="ui-50">
                        <MoneyDisplay value={Money.tryFromFloat(limit)} fractionDigits={0} />
                    </Text>
                </StackLayout>
                <Icon name="shotwell-right" size="large" color="ui-50" />
            </CenterLayout>
        </LayerCard>
    );
};
