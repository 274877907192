import { Nullable } from '@embroker/shotwell/core/types';
import {
    defineValidator,
    Joi,
    Schema,
    TypeChecker,
    Validator,
} from '@embroker/shotwell/core/validation/schema';
import { BlanketLimit } from './BlanketLimit';
import { LiabilityCoverage } from './LiabilityCoverage';
import { SubjectOfInsurance } from './SubjectOfInsurance';
import { Limit } from './Limit';
import { PolicyAttorney } from './PolicyAttorney';

/**
 * Represents "Liability" object
 */
export interface LiabilitySection {
    /**
     * List of policy attorneys
     */
    attorneyRoster?: PolicyAttorney[];
    /**
     * Is policy having blanked insurance
     */
    blanketAdditionalInsured: boolean;
    /**
     * List of blanket limits
     */
    blanketLimitsList: Nullable<BlanketLimit[]>;
    /**
     * Are any claims made
     */
    claimsMade: boolean;
    /**
     * List of policy liabilities
     */
    liabilityList: Nullable<LiabilityCoverage[]>;
    /**
     * Limit amount for section
     */
    limit: Limit;
    /**
     * Occurrence
     */
    occur: boolean;
    /**
     * Can multiple policies get triggered by the same loss
     */
    primaryNonContributory: boolean;
    /**
     * Is insurance carriers eligible to legally pursue
     * a third party that caused an insurance loss to the insured
     */
    waiverOfSubrogation: boolean;
    /**
     * One or more units of exposure potentially involved in a single-loss event
     */
    subjectOfInsuranceList: Nullable<SubjectOfInsurance[]>;
}

export interface LiabilitySectionValidator {
    /**
     * A Joi schema matching a valid Liability object.
     */
    readonly schema: Schema.ObjectSchema<LiabilitySection>;
    /**
     * Type predicate that checks if a given value can be used as Liability object.
     *
     * Use this only to do early returns. It's recommended to use validate()
     * before using an unknown value as Liability object as it normalizes the value
     * in addition to performing the same validation as check().
     */
    readonly check: TypeChecker<LiabilitySection>;
    /**
     * Validates and normalizes the given value to a Liability object.
     *
     * This should be used for all untrusted inputs to verify and, if required
     * (and possible), normalize the input.
     */
    readonly validate: Validator<LiabilitySection>;
}

/**
 * Constructs a Liability object representing USD cents.
 */
export const LiabilitySection = {
    ...defineValidator<LiabilitySection>(
        Joi.object({
            attorneyRoster: Joi.array().items(PolicyAttorney.schema).optional(),
            blanketAdditionalInsured: Joi.boolean(),
            blanketLimitsList: Joi.array().items(BlanketLimit.schema).allow(null),
            claimsMade: Joi.boolean(),
            liabilityList: Joi.array().items(LiabilityCoverage.schema).allow(null),
            limit: Limit.schema,
            occur: Joi.boolean(),
            primaryNonContributory: Joi.boolean(),
            waiverOfSubrogation: Joi.boolean(),
            subjectOfInsuranceList: Joi.array().items(Joi.object()).allow(null),
        }).preferences({ presence: 'required' }),
    ),
    create(liabilitySection: LiabilitySection) {
        return LiabilitySection.validate(liabilitySection);
    },
};
