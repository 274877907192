import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { Button, ColumnLayout, TextButton } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { WizardForm } from '../../../../view/hooks/useWizardForm';
import { withConfirmationModalShowProps } from '../../../view/components/WithExtraProps';
import { LPLQuote } from '../../entities/LPLQuote';
import { LPLQuoteFormData } from './createLPLQuoteForm';

interface LPLHigherLimitNavigationProps<T extends LPLQuoteFormData, Q extends LPLQuote>
    extends withConfirmationModalShowProps {
    quote: Q;
    isSubmitting: boolean;
    trigger: WizardForm<OpaqueForm<T>>['trigger'];
    status: WizardForm<OpaqueForm<T>>['status'];
}

export function LPLHigherLimitNavigation<T extends LPLQuoteFormData, Q extends LPLQuote>({
    isSubmitting,
    trigger,
    status,
    showConfirmationModal,
}: LPLHigherLimitNavigationProps<T, Q>) {
    const isDirty = status === 'dirty';

    const handleClick = () => {
        if (isDirty) {
            trigger('update');
        } else {
            showConfirmationModal();
        }
    };

    return (
        <ColumnLayout split="-1">
            <Button disabled={isSubmitting} onClick={handleClick}>
                {isDirty ? 'Recalculate' : 'Continue'}
            </Button>
            <TextButton as="button" className={'u-hidden'} />
        </ColumnLayout>
    );
}
