import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UserInvite } from '../../entities/UserInvite';
import { Token } from '../../types/Token';

/**
 * Repository used to create and update user invites to the platform
 */
export interface UserInviteRepository {
    /**
     * Retrieves list of users invited to organization and their privileges
     * @returns List of UserInvites
     * @returns InvalidArgument error if provided user invite entity is not valid invite
     */
    get(): AsyncResult<UserInvite[], InvalidArgument | OperationFailed>;
    /**
     * Fetches user invite for the provided token
     * @param token
     * @returns UserInvite entity if one exists on the platform for the provided token
     * @returns UnknownEntity error if provided token was invalid
     * @returns OperationFailed | InvalidArgument error if request failed
     */
    getUserInviteByToken(
        token: Token,
    ): AsyncResult<UserInvite, OperationFailed | InvalidArgument | UnknownEntity>;
    /**
     * Saves the UserInvite entity
     * @param userInvite is the entity which we want to save
     * @returns UserInvite if user invite entity was saved successfully
     * @returns InvalidArgument error if provided user invite entity is not valid invite
     */
    save(userInvite: UserInvite): AsyncResult<UserInvite, InvalidArgument | OperationFailed>;
    /**
     *
     * @param userInvites List of UserInvite entities
     * @returns void in case of success
     * @returns InvalidArgument or OperationFailed in case that request failed
     */
    sendInvites(userInvites: UserInvite[]): AsyncResult<void, InvalidArgument | OperationFailed>;
}

export const UserInviteRepository = Symbol('UserInviteRepository');
