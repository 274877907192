import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject } from '@embroker/shotwell/core/di';
import { BrokerRepository } from '../repositories';
import { OutstandingPayment } from '../types/OutstandingPayment';
import { ServiceNotAvailable } from '../errors';

export interface GetBrokerageOutstandingPayments extends UseCase {
    execute(): AsyncResult<
        OutstandingPayment[],
        InvalidArgument | OperationFailed | ServiceNotAvailable
    >;
}

class GetBrokerageOutstandingPaymentsUseCase
    extends UseCase
    implements GetBrokerageOutstandingPayments
{
    public static type = Symbol('Broker/GetDashboardPayments');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BrokerRepository) private brokerRepository: BrokerRepository,
    ) {
        super(eventBus);
    }

    public async execute(): AsyncResult<
        OutstandingPayment[],
        InvalidArgument | OperationFailed | ServiceNotAvailable
    > {
        return this.brokerRepository.getBrokerageOutstandingPayments();
    }
}

export const GetBrokerageOutstandingPayments: UseCaseClass<GetBrokerageOutstandingPayments> =
    GetBrokerageOutstandingPaymentsUseCase;
