import { isErr } from '@embroker/shotwell/core/types/Result';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Spinner } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { PoliciesPerYear } from '../../types/PoliciesPerYear';
import { GetClaimsHistory } from '../../useCases/GetClaimsHistory';
import { ClaimsLandingPage } from './ClaimsLandingPage';
import { ClaimsList } from './ClaimsList';
import { EmptyClaimsList } from './EmptyClaimsList';
import { TransferToEmbroker } from './TransferToEmbroker';

export const ClaimsHistoryTab = () => {
    const { result, isLoading } = useUseCase(GetClaimsHistory);
    if (result === undefined || isLoading) {
        return <Spinner />;
    }

    if (isErr(result)) {
        return <ErrorPage errors={result.errors} />;
    }

    const hasBorPolicies = result.value.policiesBORPerYear.length > 0;
    const hasNonBorPolicies = result.value.policiesNonBORPerYear.length > 0;

    return (
        <ClaimsLandingPage withSubNavigation={hasBorPolicies}>
            {hasBorPolicies ? (
                <ClaimsList
                    borPolicies={result.value.policiesBORPerYear as PoliciesPerYear[]}
                    nonBORPolicies={result.value.policiesNonBORPerYear as PoliciesPerYear[]}
                />
            ) : hasNonBorPolicies ? (
                <TransferToEmbroker />
            ) : (
                <EmptyClaimsList />
            )}
        </ClaimsLandingPage>
    );
};
