import { inject, injectable } from '@embroker/shotwell/core/di';
import { OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Nullable } from '@embroker/shotwell/core/types';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { PolicyRepository } from '../repositories/PolicyRepository';
import { LobType } from '../types/Lob';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';

export interface PolicyDocumentUploaded extends DomainEvent {
    readonly origin: 'Policy';
    readonly name: 'DocumentUploaded';
}

export interface UploadPolicyRequest {
    fileKey: string;
    agreementId: Nullable<UUID>;
    lineOfBusiness: Nullable<LobType>;
    unprocessedPolicyName: string;
    isFromOnboarding: Nullable<boolean>;
}

export const UploadPolicyRequest = {
    ...defineValidator<UploadPolicyRequest>({
        fileKey: Joi.string(),
        agreementId: UUID.schema.allow(null),
        lineOfBusiness: Joi.string().allow(null, ''),
        unprocessedPolicyName: Joi.string(),
        isFromOnboarding: Joi.boolean().allow(null),
    }),
    create(uploadPolicyRequest: UploadPolicyRequest) {
        return UploadPolicyRequest.validate(uploadPolicyRequest);
    },
};

export interface UploadPolicy extends UseCase {
    execute(request: UploadPolicyRequest): AsyncResult<Nullable<UUID>, OperationFailed>;
}

@injectable()
export class UploadPolicyUseCase extends UseCase {
    public static type = Symbol('Policy/UploadPolicy');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(PolicyRepository) private policyRepository: PolicyRepository,
    ) {
        super(eventBus);
    }

    async execute(request: UploadPolicyRequest): AsyncResult<Nullable<UUID>, OperationFailed> {
        const policyDocumentUploaded: PolicyDocumentUploaded = {
            origin: 'Policy',
            name: 'DocumentUploaded',
            id: UUID.create(),
            createdAt: new Date(Date.now()),
        };

        await this.eventBus.publish(policyDocumentUploaded);

        const result = await this.policyRepository.policyUpload(request);
        if (isErr(result)) {
            return Failure(OperationFailed({}));
        }

        const result2 = await this.policyRepository.createPolicyFromPDF({
            activity_id: result.value as string,
            file_key: request.fileKey,
        });
        if (isErr(result2)) {
            return Failure(OperationFailed({}));
        }

        let result3 = await this.policyRepository.getTaskStatus(result2.value.task_id);
        if (isErr(result3) || result3.value.error !== null) {
            return Failure(OperationFailed({}));
        }

        while (result3.value.data === null) {
            await new Promise((r) => setTimeout(r, 5000));
            result3 = await this.policyRepository.getTaskStatus(result2.value.task_id);
            if (isErr(result3) || result3.value.error !== null) {
                return Failure(OperationFailed({}));
            }
        }

        return Success(result.value);
    }
}

export const UploadPolicy: UseCaseClass<UploadPolicy> = UploadPolicyUseCase;
