import React from 'react';
import { LearnMoreSlideout } from '../LearnMoreSlideout';
import { lawProducts } from '../coverageDetails';
import { AppTypeCode } from '../../../types/enums';

interface CoverageSelectionSlideoutProps {
    appTypeCode: AppTypeCode;
    naicsCode?: string;
    onClose: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const voidFunction = () => {};

export function CoverageInformationSlideout({
    appTypeCode,
    naicsCode,
}: CoverageSelectionSlideoutProps) {
    const getSlideoutContent = (appTypeCode: AppTypeCode): JSX.Element => {
        return (
            <LearnMoreSlideout
                appTypeCode={appTypeCode}
                naicsCode={naicsCode}
                isSelected={false}
                onCoverageSelect={voidFunction}
                variant={lawProducts.includes(appTypeCode) ? 'new' : 'standard'}
            />
        );
    };
    const sildeoutContent = getSlideoutContent(appTypeCode);
    return sildeoutContent;
}
