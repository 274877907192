import {
    Button,
    CenterLayout,
    Icon,
    InsetBox,
    Modal,
    ModalActions,
    ModalState,
    StackLayout,
    Table,
    Text,
} from '@embroker/ui-toolkit/v2';
import React from 'react';

type ESPCoverageType = 'do' | 'epli' | 'eoCyber' | 'fiduciary' | 'techCyber';

interface HowWeCompareModalContentProps {
    modal: ModalState & ModalActions;
    coverageType: ESPCoverageType;
}

function DirectorsAndOfficers() {
    return (
        <Table>
            <Table.Header>
                <Table.Column>GENERAL TERMS & CONDITIONS</Table.Column>
                <Table.Column>Arch</Table.Column>
                <Table.Column>Chubb</Table.Column>
                <Table.Column>Erisk</Table.Column>
                <Table.Column>Berkeley</Table.Column>
                <Table.Column>Embroker</Table.Column>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>
                        <Text>Coverage Territory Worldwide</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text>Automatic coverage for all acquisitions</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
            <Table.Header>
                <Table.Column>DIRECTORS & OFFICERS LIABILITY</Table.Column>
                <Table.Column />
                <Table.Column />
                <Table.Column />
                <Table.Column />
                <Table.Column />
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>
                        <Text>Prior Acts</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text>No Hammer Clause</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text>Knowlege of Claim Restricted to CEO only</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text>&quot;Insured&quot; includes independent contractors</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text>Includes Employed Lawyers Extension</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text>100% Defense Allocation to Covered Loss</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text>Antitrust exclusion removed</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text>Professional Services Exclusion Derivative Carveback</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text>Derivative Costs Sublimit</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text>Extended cover for your VC Investor</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text>Additional Side A $1M Limit of Liability</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text>Breach of Contract Exclusion Side A & B Carveback</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    );
}

function EmploymentPracticesLiability() {
    return (
        <Table>
            <Table.Header>
                <Table.Column>GENERAL TERMS & CONDITIONS</Table.Column>
                <Table.Column>Arch</Table.Column>
                <Table.Column>Chubb</Table.Column>
                <Table.Column>Erisk</Table.Column>
                <Table.Column>Berkeley</Table.Column>
                <Table.Column>Embroker</Table.Column>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>
                        <Text>Coverage Territory Worldwide</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text>Automatic coverage for all acquisitions</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
            <Table.Header>
                <Table.Column>Employment Practices Liability</Table.Column>
                <Table.Column />
                <Table.Column />
                <Table.Column />
                <Table.Column />
                <Table.Column />
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>
                        <Text>Wage & Hour Defense Costs All States</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text>
                            Violation of Uniformed Services Employment and Reemployment Rights Act
                        </Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text>
                            &quot;Claim&quot; includes actions by prospective employee or
                            prospective applicant
                        </Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    );
}

function Fiduciary() {
    return (
        <Table>
            <Table.Header>
                <Table.Column>GENERAL TERMS & CONDITIONS</Table.Column>
                <Table.Column>Arch</Table.Column>
                <Table.Column>Chubb</Table.Column>
                <Table.Column>Erisk</Table.Column>
                <Table.Column>Berkeley</Table.Column>
                <Table.Column>Embroker</Table.Column>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>
                        <Text>Coverage Territory Worldwide</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text>Automatic coverage for all acquisitions</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
            <Table.Header>
                <Table.Column>Fiduciary</Table.Column>
                <Table.Column />
                <Table.Column />
                <Table.Column />
                <Table.Column />
                <Table.Column />
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>
                        <Text>
                            &quot;Claim&quot; includes actions by prospective employee or
                            prospective applicant
                        </Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text>Penalties for Violations of HIPAA Privacy Provisions</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    );
}

function EoCyber() {
    return (
        <Table>
            <Table.Header>
                <Table.Column>GENERAL TERMS & CONDITIONS</Table.Column>
                <Table.Column>Axis</Table.Column>
                <Table.Column>Beazley</Table.Column>
                <Table.Column>Chubb Digitech</Table.Column>
                <Table.Column>CFC</Table.Column>
                <Table.Column>Hiscox</Table.Column>
                <Table.Column>Embroker Standard</Table.Column>
                <Table.Column>Embroker Plus</Table.Column>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>
                        <Text>Coverage Territory Worldwide</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text>Automatic coverage for all acquisitions</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
            <Table.Header>
                <Table.Column>Technology E&O/Cyber</Table.Column>
                <Table.Column />
                <Table.Column />
                <Table.Column />
                <Table.Column />
                <Table.Column />
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>
                        <Text>Express Breach of Contract</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text>Liability of Others Assumed Contractually (Indemnity)</Text>
                    </Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text>Software Code Infringement</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text>Return of Fees as Damages</Text>
                    </Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text>Expense Fund for Privacy Remediation of Others</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text>Payment of Withheld Fees</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text>Dependent Business Interruption Full Policy Limit</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text>Social Engineering</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Text> By Endorsement</Text>
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Text> By Endorsement</Text>
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Text> By Endorsement</Text>
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Text> By Endorsement</Text>
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Text>Contingent Bodily Injury & Property Damage</Text>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Text>By Endorsement</Text>
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="positive-500" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="close" color="ui-400" size="medium" />
                        </CenterLayout>
                    </Table.Cell>
                    <Table.Cell>
                        <CenterLayout centerText>
                            <Icon name="status-success" color="primary-500" />
                        </CenterLayout>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    );
}

function getTableByCoverageType(coverageType: ESPCoverageType) {
    if (coverageType == 'do') {
        return <DirectorsAndOfficers />;
    }
    if (coverageType == 'epli') {
        return <EmploymentPracticesLiability />;
    }
    if (coverageType == 'fiduciary') {
        return <Fiduciary />;
    }
    if (coverageType == 'eoCyber' || coverageType == 'techCyber') {
        return <EoCyber />;
    }
}

export const HowWeCompareModalContent = React.memo(function HowWeCompareModalContent({
    modal,
    coverageType,
}: HowWeCompareModalContentProps) {
    return (
        <Modal {...modal} size="large">
            <StackLayout gap="24">
                <Text style="heading 3">How we compare</Text>
                <InsetBox gap="16">{getTableByCoverageType(coverageType)}</InsetBox>
                <Button onClick={modal.hide}>Got it</Button>
            </StackLayout>
        </Modal>
    );
});
