import { State } from '@embroker/shotwell/core/types/StateList';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { InsetBox, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React, { ReactNode } from 'react';
import { UsaRepresentationStates } from './SignatureField';

interface LabelProps {
    children: ReactNode;
}
function Label({ children }: LabelProps) {
    return (
        <Text as="span" style="label 1">
            {children}
        </Text>
    );
}

interface SignatureProps {
    fullName: string;
    title: string;
}
function Signature({ fullName, title }: SignatureProps) {
    const today = new Date(Date.now());
    return (
        <Text>
            Signed: <Label>{fullName}</Label>
            <br />
            Date:{' '}
            <Label>
                <DateDisplay value={today} />
            </Label>
            <br />
            Title: <Label>{title}</Label>
        </Text>
    );
}

interface WarrantyAndFraudModalProps {
    fullName: string;
    title: string;
    state: State;
}

export const WarrantyAndFraudModal = React.memo(function WarrantyAndFraudModal({
    fullName,
    title,
    state,
}: WarrantyAndFraudModalProps) {
    const warrantyHeading = UsaRepresentationStates.includes(state)
        ? 'Representations'
        : 'Statement of Facts';
    return (
        <InsetBox>
            <StackLayout gap="24">
                <Text style="heading 3">Fraud Prevention – General Warning</Text>
                <StackLayout gap="12">
                    <Label>
                        The Applicant's submission of this Application does not obligate the Company
                        to issue, or the Applicant to purchase, a policy. The Applicant will be
                        advised if the Application for coverage is accepted.
                    </Label>
                    <Label>
                        The Applicant hereby authorizes the Company to make any inquiry in
                        connection with this Application.
                    </Label>
                </StackLayout>
                <Text as="p">
                    The undersigned authorized agents of the person(s) and entity(ies) proposed for
                    this insurance declare that to the best of their knowledge and belief, after
                    reasonable inquiry, the statements made in this Application and in any
                    attachments or other documents submitted with this Application are true and
                    complete. The Applicant and/or the undersigned agree that this Application and
                    such attachments and other documents shall be the basis of the insurance policy
                    should a policy providing the requested coverage be issued; that all such
                    materials shall be deemed to be attached to and shall form a part of any such
                    policy; and that the Company will have relied on all such materials in issuing
                    any such policy.
                </Text>
                <Text as="p">
                    The information requested in this Application is for underwriting purposes only
                    and does not constitute notice to the Company under any policy of a Claim or
                    potential Claim.
                </Text>
                <StackLayout gap="4">
                    <Label>
                        General Fraud Warning: Applicable in all States unless specifically stated
                        otherwise below:
                    </Label>
                    <Text as="p">
                        Any person who knowingly and with intent to defraud any insurance company or
                        another person files an application for insurance or statement of claim
                        containing any materially false information, or conceals for the purpose of
                        misleading, information concerning any fact material thereto, commits a
                        fraudulent insurance act, which is a crime and subjects the person to
                        criminal and civil penalties.
                    </Text>
                </StackLayout>

                <StackLayout gap="4">
                    <Label>
                        Applicable in Alabama, Arkansas, Louisiana, Maryland, New Mexico, Rhode
                        Island and West Virginia:
                    </Label>
                    <Text as="p">
                        Any person who knowingly (or willfully)* presents a false or fraudulent
                        claim for payment of a loss or benefit or knowingly presents false
                        information in an application for insurance is guilty of a crime and may be
                        subject to (civil)** fines and (criminal penalties)** confinement in prison.
                        *Applies in MD only. **Applies in NM only.
                    </Text>
                </StackLayout>
                <StackLayout gap="4">
                    <Label>Applicable in California:</Label>
                    <Text as="p">
                        For your protection California law requires the following to appear on this
                        form. Any person who knowingly presents false or fraudulent information to
                        obtain or amend insurance coverage or to make a claim for the payment of a
                        loss is guilty of a crime and may be subject to fines and confinement in
                        state prison.
                    </Text>
                </StackLayout>
                <StackLayout gap="4">
                    <Label>Applicable in Colorado:</Label>
                    <Text as="p">
                        It is unlawful to knowingly provide false, incomplete, or misleading facts
                        or information to an insurance company for the purpose of defrauding or
                        attempting to defraud the company. Penalties may include imprisonment,
                        fines, denial of insurance and civil damages. Any insurance company or agent
                        of an insurance company who knowingly provides false, incomplete, or
                        misleading facts or information to a policyholder or claimant for the
                        purpose of defrauding or attempting to defraud the policyholder or claimant
                        with regard to a settlement or award payable from insurance proceeds shall
                        be reported to the Colorado Division of Insurance within the Department of
                        Regulatory Agencies.
                    </Text>
                </StackLayout>
                <StackLayout gap="4">
                    <Label>Applicable in the District of Columbia:</Label>
                    <Text as="p">
                        WARNING: It is a crime to provide false or misleading information to an
                        insurer for the purpose of defrauding the insurer or any other person.
                        Penalties include imprisonment and/or fines. In addition, an insurer may
                        deny insurance benefits if false information materially related to a claim
                        was provided by the applicant.
                    </Text>
                </StackLayout>
                <StackLayout gap="4">
                    <Label>Applicable in Florida:</Label>
                    <Text as="p">
                        Any person who knowingly and with intent to injure, defraud, or deceive any
                        insurer files a statement of claim containing any false, incomplete, or
                        misleading information is guilty of a felony of the third degree.
                    </Text>
                </StackLayout>
                <StackLayout gap="4">
                    <Label>Applicable in Hawaii:</Label>
                    <Text as="p">
                        For your protection, Hawaii law requires you to be informed that presenting
                        a fraudulent claim for payment of a loss or benefit is a crime punishable by
                        fines or imprisonment, or both.
                    </Text>
                </StackLayout>
                <StackLayout gap="4">
                    <Label>Applicable in Kansas:</Label>
                    <Text as="p">
                        Any person who, knowingly and with intent to defraud, presents, causes to be
                        presented or prepares with knowledge or belief that it will be presented to
                        or by an insurer, purported insurer, broker or any agent thereof, any
                        written, electronic, electronic impulse, facsimile, magnetic, oral, or
                        telephonic communication or statement as part of, or in support of, an
                        application for the issuance of, or the rating of an insurance policy for
                        personal or commercial insurance, or a claim for payment or other benefit
                        pursuant to an insurance policy for commercial or personal insurance which
                        such person knows to contain materially false information concerning any
                        fact material thereto; or conceals, for the purpose of misleading,
                        information concerning any fact material thereto commits a fraudulent
                        insurance act.
                    </Text>
                </StackLayout>
                <StackLayout gap="4">
                    <Label>Applicable in Kentucky, New York and Pennsylvania:</Label>
                    <Text as="p">
                        Any person who knowingly and with intent to defraud any insurance company or
                        other person files an application for insurance or statement of claim
                        containing any materially false information or conceals for the purpose of
                        misleading, information concerning any fact material thereto commits a
                        fraudulent insurance act, which is a crime (and subjects such person to
                        criminal and civil penalties)* (not to exceed five thousand dollars and the
                        stated value of the claim for each such violation)**. *Applies in NY and PA
                        only. **Applies in NY Only.
                    </Text>
                </StackLayout>
                <StackLayout gap="4">
                    <Label>Applicable in Maine, Tennessee, Virginia and Washington:</Label>
                    <Text as="p">
                        It is a crime to knowingly provide false, incomplete, or misleading
                        information to an insurance company for the purpose of defrauding the
                        company. Penalties (may)* include imprisonment, fines, and denial of
                        insurance benefits. * Applies in ME Only.
                    </Text>
                </StackLayout>
                <StackLayout gap="4">
                    <Label>Applicable in Massachusetts and Nebraska:</Label>
                    <Text as="p">
                        Any person who knowingly and with intent to defraud any insurance company or
                        another person files an application of insurance or statement of claim
                        containing any materially false information, or conceals for the purpose of
                        misleading information concerning any material fact there to, may be
                        committing a fraudulent insurance act, which may be a crime and may subject
                        the person to criminal and civil penalties.
                    </Text>
                </StackLayout>
                <StackLayout gap="4">
                    <Label>Applicable in Minnesota:</Label>
                    <Text as="p">
                        Any person who, with intent to defraud or knowing that he/she is
                        facilitating a fraud against an insurer, submits an application or files a
                        claim of loss containing a false, fraudulent or deceptive statement is, or
                        may be found to be, guilty of insurance fraud, which is a crime, and may be
                        subject to civil fines and criminal penalties.
                    </Text>
                </StackLayout>
                <StackLayout gap="4">
                    <Label>Applicable in New Hampshire:</Label>
                    <Text as="p">
                        Any person who knowingly presents a false or fraudulent claim for payment of
                        a loss or benefit or knowingly presents false information in an application
                        for insurance is guilty of a crime and may be subject to fines and
                        confinement in prison.
                    </Text>
                </StackLayout>
                <StackLayout gap="4">
                    <Label>Applicable in New Jersey:</Label>
                    <Text as="p">
                        Any person who includes any false or misleading information on an
                        application for an insurance policy is subject to criminal and civil
                        penalties.
                    </Text>
                </StackLayout>
                <StackLayout gap="4">
                    <Label>Applicable in Ohio:</Label>
                    <Text as="p">
                        Any person who, with intent to defraud or knowing that he is facilitating a
                        fraud against an insurer, submits an application or files a claim containing
                        a false or deceptive statement is guilty of insurance fraud.
                    </Text>
                </StackLayout>
                <StackLayout gap="4">
                    <Label>Applicable in Oklahoma:</Label>
                    <Text as="p">
                        WARNING: Any person who knowingly, and with intent to injure, defraud or
                        deceive any insurer, makes any claim for the proceeds of an insurance policy
                        containing any false, incomplete or misleading information is guilty of a
                        felony.
                    </Text>
                </StackLayout>
                <StackLayout gap="4">
                    <Label>Applicable in Oregon:</Label>
                    <Text as="p">
                        Any person who knowingly and with intent to defraud or solicit another to
                        defraud the insurer by submitting an application containing a false
                        statement as to any material fact may be violating state law.
                    </Text>
                </StackLayout>
                <StackLayout gap="4">
                    <Label>Applicable in Vermont:</Label>
                    <Text as="p">
                        Any person who knowingly presents a false statement in an application for
                        insurance may be guilty of a criminal offense and may be subject to
                        penalties under state law.
                    </Text>
                </StackLayout>

                <Text style="heading 3">{warrantyHeading}</Text>
                <Label>
                    The undersigned authorized owner, partner, director, or officer represents and
                    warrants on behalf of the Named Insured and all persons/ entities for whom
                    insurance is being sought that to the best of his/ her knowledge and belief
                    after diligent inquiry, the statements set forth herein and attached hereto are
                    true. It is understood that the statements in this Application, including
                    material submitted to or obtained by the underwriter, are material to the
                    acceptance of the risk and relied upon by the underwriter. The Insureds further
                    agree that in the event of any material misrepresentation or omission in the
                    Application, including materials submitted to or obtained by the underwriter,
                    this Policy shall be voided.
                </Label>
                <Text as="p">
                    The undersigned agrees that if the information supplied on this Application
                    changes between the date of this Application and the effective date or the
                    insurance; that he/she will immediately notify the Insurer of such changes, and
                    the Insurer may withdraw or modify any outstanding quotations or authorizations
                    or agreements to bind the insurance.
                </Text>
                <Text as="p">
                    Signing this Application does not bind the applicant or the Insurer to complete
                    the insurance, but it is agreed that this Application shall be the basis of the
                    contract should a policy be issued, and it will be attached to and become part
                    of the Policy.
                </Text>
                <Signature fullName={fullName} title={title} />
            </StackLayout>
        </InsetBox>
    );
});
