import {
    LineOfBusinessCodeListItem,
    LineOfBusinessSubtypeCodeListItem,
} from '@embroker/shotwell-api/enums';
import { Icon, IconName } from '@embroker/ui-toolkit/v2';
import React from 'react';

function getPolicyIcon(
    lineOfBusiness: LineOfBusinessCodeListItem,
    subLineOfBusiness?: LineOfBusinessSubtypeCodeListItem,
): IconName {
    const lineOfBusinessIconMap = new Map<LineOfBusinessCodeListItem, IconName>([
        ['LineOfBusinessCodeListAuto', 'auto'],
        ['LineOfBusinessCodeListAviation', 'plane'],
        ['LineOfBusinessCodeListBOP', 'user-shield'],
        ['LineOfBusinessCodeListBoilerAndMachinery', 'product-default'],
        ['LineOfBusinessCodeListCannabis', 'product-default'],
        ['LineOfBusinessCodeListCrime', 'cage'],
        ['LineOfBusinessCodeListCriticalIllness', 'product-default'],
        ['LineOfBusinessCodeListCyber', 'thief'],
        ['LineOfBusinessCodeListDental', 'meeting'],
        ['LineOfBusinessCodeListDirectorsAndOfficers', 'meeting'],
        ['LineOfBusinessCodeListEarthquake', 'user-shield'],
        ['LineOfBusinessCodeListEmploymentPractices', 'suitcase'],
        ['LineOfBusinessCodeListExcess', 'product-default'],
        ['LineOfBusinessCodeListExcessTechEOCyber', 'excess'],
        ['LineOfBusinessCodeListExcessInterestLife', 'handshake'],
        ['LineOfBusinessCodeListFiduciary', 'handshake'],
        ['LineOfBusinessCodeListFixedAnnuity', 'handshake'],
        ['LineOfBusinessCodeListFlood', 'product-default'],
        ['LineOfBusinessCodeListGarageAndDealers', 'auto'],
        ['LineOfBusinessCodeListGeneralLiability', 'shield-in-circle'],
        ['LineOfBusinessCodeListGroupSuperannuation', 'product-default'],
        ['LineOfBusinessCodeListHospitalIndemnity', 'product-default'],
        ['LineOfBusinessCodeListIncomeReplacement', 'product-default'],
        ['LineOfBusinessCodeListIndexedAnnuity', 'handshake'],
        ['LineOfBusinessCodeListIndividualSuperannuation', 'product-default'],
        ['LineOfBusinessCodeListInlandMarine', 'product-default'],
        ['LineOfBusinessCodeListInterestSensitiveWholeLife', 'product-default'],
        ['LineOfBusinessCodeListLiability', 'shield-in-circle'],
        ['LineOfBusinessCodeListLongTermCare', 'product-default'],
        ['LineOfBusinessCodeListLongTermDisability', 'product-default'],
        ['LineOfBusinessCodeListMajorMedical', 'product-default'],
        ['LineOfBusinessCodeListMedicareSupplement', 'product-default'],
        ['LineOfBusinessCodeListOther', 'product-default'],
        ['LineOfBusinessCodeListOwnersAndContractors', 'houses'],
        ['LineOfBusinessCodeListPackage', 'multicoverage'],
        ['LineOfBusinessCodeListProductLiability', 'announcement'],
        ['LineOfBusinessCodeListProfessionalLiability', 'pen'],
        ['LineOfBusinessCodeListProperty', 'building'],
        ['LineOfBusinessCodeListShortTermDisability', 'product-default'],
        ['LineOfBusinessCodeListSpecialtyLines', 'product-default'],
        ['LineOfBusinessCodeListTermLife', 'handshake'],
        ['LineOfBusinessCodeListTravel', 'plane'],
        ['LineOfBusinessCodeListTruckers', 'car'],
        ['LineOfBusinessCodeListUmbrella', 'umbrella'],
        ['LineOfBusinessCodeListUniversalLife', 'handshake'],
        ['LineOfBusinessCodeListVariableAnnuity', 'handshake'],
        ['LineOfBusinessCodeListVariableUniversalLife', 'handshake'],
        ['LineOfBusinessCodeListVariableWholeLife', 'handshake'],
        ['LineOfBusinessCodeListVentureCapitalAssetProtection', 'chart'],
        ['LineOfBusinessCodeListVision', 'product-default'],
        ['LineOfBusinessCodeListWatercraft', 'product-default'],
        ['LineOfBusinessCodeListWholeLife', 'handshake'],
        ['LineOfBusinessCodeListWind', 'product-default'],
        ['LineOfBusinessCodeListWorkersCompensation', 'worker'],
        ['LineOfBusinessCodeListAccountantsProfessionalLiability', 'calculator'],
        ['LineOfBusinessCodeListRealEstateAgentsProfessionalLiability', 'real-estate'],
        ['LineOfBusinessCodeListTaxPreparersAndBookkeepersProfessionalLiability', 'tax-preparers'],
        ['LineOfBusinessCodeListHomeInspectorsProfessionalLiability', 'home-inspectors'],
        [
            'LineOfBusinessCodeListNonTechnologyBusinessAndManagementConsultantProfessionalLiability',
            'non-technology',
        ],
    ]);

    const lineOfBusinessSubtypeCodeListIconMap: Map<LineOfBusinessSubtypeCodeListItem, IconName> =
        new Map([
            ['LineOfBusinessSubtypeCodeListLawyersProfessional', 'law'],
            ['LineOfBusinessSubtypeCodeListManagementLiability', 'rocket'],
            ['LineOfBusinessSubtypeCodeListCrime', 'crime'],
            ['LineOfBusinessSubtypeCodeListCrimeDigital', 'crime'],
            ['LineOfBusinessSubtypeCodeListHiredNonOwnedAutoOnly', 'auto'],
            ['LineOfBusinessSubtypeCodeListRecreationalVehicles', 'auto'],
            ['LineOfBusinessSubtypeCodeListGarageAndDealers', 'auto'],
            ['LineOfBusinessSubtypeCodeListMotorTruckCargo', 'auto'],
            ['LineOfBusinessSubtypeCodeListPersonalAuto', 'auto'],
            ['LineOfBusinessSubtypeCodeListTruckers', 'auto'],
            ['LineOfBusinessSubtypeCodeListAviation', 'plane'],
            ['LineOfBusinessSubtypeCodeListProductsLiability', 'product'],
            ['LineOfBusinessSubtypeCodeListCyberEO', 'cyber'],
            ['LineOfBusinessSubtypeCodeListTechProfessionalCyberPackage', 'cyber'],
            ['LineOfBusinessSubtypeCodeListCyberPrivacyLiability', 'cyber'],
            ['LineOfBusinessSubtypeCodeListExcessCyberLiability', 'cyber'],
            ['LineOfBusinessSubtypeCodeListTechEOCyberDigital', 'cyber'],
            ['LineOfBusinessSubtypeCodeListEmploymentPracticesLiability', 'epli'],
            ['LineOfBusinessSubtypeCodeListEPLIDigital', 'epli'],
            ['LineOfBusinessSubtypeCodeListFiduciary', 'handshake'],
            ['LineOfBusinessSubtypeCodeListFiduciaryDigital', 'handshake'],
            ['LineOfBusinessSubtypeCodeListGeneralProfessionalLiability', 'pen'],
            ['LineOfBusinessSubtypeCodeListHomeowners', 'houses'],
            ['LineOfBusinessSubtypeCodeListErrorsAndOmissions', 'eo'],
            ['LineOfBusinessSubtypeCodeListDODigital', 'meeting'],
            ['LineOfBusinessSubtypeCodeListWorkersCompensation', 'worker'],
        ]);

    if (
        lineOfBusiness !== 'LineOfBusinessCodeListOther' &&
        lineOfBusiness !== 'LineOfBusinessCodeListESP' &&
        lineOfBusiness !== 'LineOfBusinessCodeListPCoML'
    ) {
        return lineOfBusinessIconMap.get(lineOfBusiness) || 'product-default';
    }
    if (subLineOfBusiness == null) {
        return 'product-default';
    }
    return lineOfBusinessSubtypeCodeListIconMap.get(subLineOfBusiness) || 'product-default';
}

interface PolicyIconProps {
    lineOfBusiness: LineOfBusinessCodeListItem;
    subLineOfBusiness?: LineOfBusinessSubtypeCodeListItem;
}

export function PolicyIcon({ lineOfBusiness, subLineOfBusiness }: PolicyIconProps) {
    const policyIcon = getPolicyIcon(lineOfBusiness, subLineOfBusiness);

    return <Icon size="large" name={policyIcon} />;
}
