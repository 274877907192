import { Money } from '@embroker/shotwell/core/types/Money';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { ColumnLayout, InsetBox, ScrollBox, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { QuoteSummaryComponentProps } from '../../../view/components/QuoteLandingPage';
import { LPLQuote } from '../../entities/LPLQuote';
import { getRetroactiveDate } from './LPLQuoteStep';
import { withQuoteInfoProps } from '../../../view/components/WithExtraProps';

interface LPLQuoteSummaryProps extends QuoteSummaryComponentProps<LPLQuote>, withQuoteInfoProps {}

export function LPLQuoteSummary({ quote, lplQuoteInfo }: LPLQuoteSummaryProps) {
    const retroactiveDate = getRetroactiveDate(
        lplQuoteInfo.hasCurrentPolicy,
        quote.options.effectiveDate,
        lplQuoteInfo.currentRetroactiveDate,
    );
    return (
        <StackLayout gap="24">
            <Text>
                You are about to bind a policy for {lplQuoteInfo.userInfo.companyName}. Please
                confirm that the details listed below are correct:
            </Text>
            <ColumnLayout gap="8">
                <Text>Effective date: </Text>{' '}
                <Text style="heading 5" data-e2e="effective-date">
                    <DateDisplay value={quote.options.effectiveDate} />
                </Text>
            </ColumnLayout>
            <InsetBox>
                <ScrollBox>
                    <StackLayout gap="24">
                        <Text style="heading 5" data-e2e="coverage-type-title">
                            Lawyers Professional Liability
                        </Text>
                        <StackLayout gap="16">
                            <Text>
                                <Text style="overline">PER CLAIM LIMIT</Text>{' '}
                                <MoneyDisplay
                                    value={Money.tryFromFloat(quote.options.perClaimLimit)}
                                    fractionDigits={0}
                                />
                            </Text>
                            <Text>
                                <Text style="overline">AGGREGATE LIMIT</Text>{' '}
                                <MoneyDisplay
                                    value={Money.tryFromFloat(quote.options.aggregateLimit)}
                                    fractionDigits={0}
                                />
                            </Text>
                            <Text>
                                <Text style="overline">PER CLAIM DEDUCTIBLE</Text>{' '}
                                <MoneyDisplay
                                    value={Money.tryFromFloat(quote.options.perClaimDeductible)}
                                    fractionDigits={0}
                                />
                            </Text>
                            <Text>
                                <Text style="overline">POLICY RETROACTIVE DATE</Text>{' '}
                                {retroactiveDate}
                            </Text>
                        </StackLayout>
                    </StackLayout>
                </ScrollBox>
            </InsetBox>
            <ColumnLayout split="-1">
                <Text style="body 1">Total premium:</Text>
                <Text data-e2e="total-premium" style="heading 5">
                    {<MoneyDisplay value={quote.totalPayable} />}
                </Text>
            </ColumnLayout>
        </StackLayout>
    );
}
