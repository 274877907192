import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Modal, ModalActions, ModalState } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { DeleteApplication } from '../../useCases/DeleteApplication';
import { ModalLayout } from '../../../view/components/ModalLayout.view';

interface DeleteApplicationInterface {
    applicationId: UUID | undefined;
    modal: ModalState & ModalActions;
}
export function DeleteApplicationModal({ applicationId, modal }: DeleteApplicationInterface) {
    if (!applicationId) {
        return null;
    }

    return (
        <Modal {...modal} size="small">
            <ModalLayout
                title="Delete Questionnaire"
                primaryAction={{
                    onClick: async () => {
                        await execute(DeleteApplication, {
                            id: applicationId,
                        });
                        modal.hide();
                    },
                    label: 'Delete',
                }}
                secondaryAction={{
                    onClick: () => {
                        modal.hide();
                    },
                    label: 'Cancel',
                }}
            >
                Warning: Deleting this questionnaire will cause you to lose all the information
                you've added. Are you sure you want to proceed?
            </ModalLayout>
        </Modal>
    );
}
