import type * as APIType from '@embroker/shotwell-api/app';
import { USD } from '@embroker/shotwell/core/types/Money';
import { addYears } from 'date-fns';
import { espFormDataType } from './espFormData';
import { ESPQuoteOptions, RequiredESPQuoteOptions } from './types/ESPQuoteOptions';

// TODO: https://embroker.atlassian.net/browse/EM-43848
// Mapping functions in this file are set up to be implemented upon in a follow up ticket
// Refinement may be needed

export function isESPQuoteOptions(options: any): options is RequiredESPQuoteOptions {
    return (
        'directorsAndOfficers' in options &&
        'employmentPracticesLiability' in options &&
        'fiduciary' in options &&
        'partnerCode' in options &&
        'policyFee' in options &&
        'isPolicyFeeTaxable' in options
    );
}

export function toApiESPQuoteOptions(options: ESPQuoteOptions): APIType.EspQuoteOptions {
    return {
        directors_and_officers: options.directorsAndOfficers,
        employment_practices_liability: options.employmentPracticesLiability,
        fiduciary: options.fiduciary,
        technology: options.technology,
        cyber: options.cyber,
        effective_period_start: options.effectiveDate,
        effective_period_end: addYears(options.effectiveDate, 1),
        partner_code: options.partnerCode,
        policy_fee: options.policyFee,
        is_policy_fee_taxable: options.isPolicyFeeTaxable,
    };
}

export const formDataToESPQuoteOptions = (formData: any): ESPQuoteOptions | undefined => {
    if (!isESPFormData(formData)) {
        return undefined;
    }
    return {
        effectiveDate: formData.effectiveDate,
        directorsAndOfficers: formData.espDirectorsAndOfficers,
        employmentPracticesLiability: formData.espEmploymentPracticesLiability,
        fiduciary: formData.espFiduciary,
        technology: formData.espTechnology,
        cyber: formData.espCyber,
        partnerCode: formData.espPartnerCode,
        policyFee: USD(formData.espPolicyFee),
        isPolicyFeeTaxable: formData.espIsPolicyFeeTaxable,
    };
};

export function isESPFormData(input: any): input is espFormDataType {
    return (
        'espIsPolicyFeeTaxable' in input &&
        'espDirectorsAndOfficers' in input &&
        'espEmploymentPracticesLiability' in input &&
        'espFiduciary' in input &&
        'espTechnology' in input &&
        'espCyber' in input &&
        'espPolicyFee' in input
    );
}
