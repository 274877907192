import { inject } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Success, isErr, Failure, AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { NotificationRepository } from '../repositories/NotificationRepository';
import { SessionRepository } from '../../userOrg/repositories/SessionRepository';
import { isAuthenticated } from '../../userOrg/entities/Session';
import { Unauthenticated } from '../../userOrg/errors';
import { FailedToGetNotificationsStats } from '../errors';
import { NotificationStats } from '../types/NotificationStats';

export interface GetNotificationsStats extends UseCase {
    execute(): AsyncResult<NotificationStats, Unauthenticated | FailedToGetNotificationsStats>;
}

class GetNotificationsStatsUseCase extends UseCase implements GetNotificationsStats {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('NotificationCenter/GetNotificationsStatsUseCase');
    /**
     * Constructor for GetNotificationsStatsUseCase use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param notificationRepository is notification repository used to store user notifications
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(SessionRepository) private sessionRepository: SessionRepository,
        @inject(NotificationRepository) private notificationRepository: NotificationRepository,
    ) {
        super(eventBus);
    }

    public async execute(): AsyncResult<
        NotificationStats,
        Unauthenticated | FailedToGetNotificationsStats
    > {
        const activeSesion = await this.sessionRepository.getActiveSession();
        if (!isAuthenticated(activeSesion.value)) {
            return Failure(Unauthenticated());
        }

        const notificationsStats = await this.notificationRepository.getNotificationsStats();

        if (isErr(notificationsStats)) {
            return Failure<FailedToGetNotificationsStats>(FailedToGetNotificationsStats());
        }
        const statsObject = NotificationStats.create(notificationsStats.value);

        if (isErr(statsObject)) {
            return Failure<FailedToGetNotificationsStats>(FailedToGetNotificationsStats());
        }

        return Success<NotificationStats>(statsObject.value);
    }
}

export const GetNotificationsStats: UseCaseClass<GetNotificationsStats> =
    GetNotificationsStatsUseCase;
