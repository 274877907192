import React from 'react';
import {
    BoxLayout,
    CenterLayout,
    CoverLayout,
    Image,
    StackLayout,
    Text,
} from '@embroker/ui-toolkit/v2';

interface ShareableApplicationThankYouProps {
    isRenewal: boolean;
}

export function ShareableApplicationThankYou({ isRenewal }: ShareableApplicationThankYouProps) {
    return (
        <CoverLayout>
            <StackLayout split="1">
                <BoxLayout gap="56">
                    <StackLayout gap="32">
                        <CenterLayout>
                            <Image inline={false} name="logo" />
                        </CenterLayout>
                        <CenterLayout centerText>
                            <StackLayout gap="32">
                                <Text as="h1" style="heading 1">
                                    Thank you!
                                </Text>
                                <Text as="p" style="body 1">
                                    We've sent an email to your broker notifying them of your
                                    application.
                                    <br />
                                    After they review it, they'll contact you with a quote for your
                                    {isRenewal && ` renewal`} coverage.
                                </Text>
                            </StackLayout>
                        </CenterLayout>
                    </StackLayout>
                </BoxLayout>
            </StackLayout>
        </CoverLayout>
    );
}
