import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Nullable, useModal } from '@embroker/ui-toolkit/v2';
import React, { useCallback, useContext, useEffect } from 'react';
import { GetApplicationIntakeTaskStatus } from '../../../../shopping/useCases/GetApplicationIntakeTaskStatus';
import { AppContext } from '../../../../view/AppContext';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { AnalyzingUploadedDocumentModal } from '../modals/AnalyzingUploadedDocumentModal';
import { SomethingWentWrongModal } from '../modals/SomethingWentWrongModal';
import { ApplicationProcessingSuccessModal } from './ApplicationProcessingSuccessModal';

export function ApplicationProcessing({
    taskId,
    abortController,
    quoteNowUri,
    isExcess,
    setIntakeTaskId,
}: {
    taskId: UUID;
    abortController: AbortController;
    quoteNowUri?: URI;
    isExcess: boolean;
    setIntakeTaskId: (value: Nullable<UUID>) => void;
}) {
    const { closeSlideout, setSlideout } = useContext(AppContext);
    const { navigate } = useNavigation();
    const analyzingUploadedDocumentModal = useModal({ visible: true });
    const somethingWentWrongModal = useModal();
    const applicationProcessingSuccessModal = useModal();

    const { hide: analyzingUploadedDocumentModalHide } = analyzingUploadedDocumentModal;
    const { show: somethingWentWrongModalShow } = somethingWentWrongModal;

    const handleApplicationProcessingSuccess = useCallback(
        (applicationId: UUID) => {
            const baseURL = isExcess
                ? '/shopping/excess-before-you-begin'
                : '/shopping/application/basic-info';
            closeSlideout();
            setSlideout(null);
            navigate(
                URI.build(baseURL, {
                    applicationId,
                }),
            );
        },
        [closeSlideout, navigate, setSlideout, isExcess],
    );

    const { result: getTaskStatusResult } = useUseCase(GetApplicationIntakeTaskStatus, {
        id: taskId,
        maxPollingRetries: 100,
        pollingRetryIntervalInMilliseconds: 5000,
        abortSignal: abortController.signal,
    });

    useEffect(() => {
        if (getTaskStatusResult && isErr(getTaskStatusResult)) {
            analyzingUploadedDocumentModalHide();
            somethingWentWrongModalShow();
            return;
        }
        if (getTaskStatusResult && isOK(getTaskStatusResult)) {
            analyzingUploadedDocumentModalHide();
            applicationProcessingSuccessModal.show();
        }
    }, [
        getTaskStatusResult,
        handleApplicationProcessingSuccess,
        analyzingUploadedDocumentModalHide,
        somethingWentWrongModalShow,
        applicationProcessingSuccessModal,
    ]);

    const triggerHandleApplicationProcessingSuccess = () => {
        if (getTaskStatusResult && isOK(getTaskStatusResult)) {
            applicationProcessingSuccessModal.hide();
            handleApplicationProcessingSuccess(getTaskStatusResult.value.applicationId);
        }
    };

    return (
        <React.Fragment>
            <AnalyzingUploadedDocumentModal modal={analyzingUploadedDocumentModal} />
            <SomethingWentWrongModal
                modal={somethingWentWrongModal}
                quoteNowUri={quoteNowUri}
                onHide={() => {
                    setIntakeTaskId(null);
                    somethingWentWrongModal.hide();
                }}
            />
            <ApplicationProcessingSuccessModal
                modal={applicationProcessingSuccessModal}
                triggerHandleApplicationProcessingSuccess={
                    triggerHandleApplicationProcessingSuccess
                }
            />
        </React.Fragment>
    );
}
