import { Immutable } from '@embroker/shotwell/core/types';
import { ColumnLayout, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import { format } from 'date-fns';
import React from 'react';
import { LPLAttorneyInfo } from '../../types/LPLAttorneyInfo';

interface IndividualInsuredListProps {
    individualInsuredList: Immutable<LPLAttorneyInfo[]>;
    effectiveDate: Date;
}

export function LPLIndividualInsuredList({
    individualInsuredList,
    effectiveDate,
}: IndividualInsuredListProps) {
    const insuredNameList = individualInsuredList.map((insured) => (
        <Text key={insured.fullName}>{insured.fullName}</Text>
    ));
    const insuredRetroDateList = individualInsuredList.map((insured) => (
        <Text key={insured.fullName + insured.retroactiveDate}>
            {insured.retroactiveDate
                ? format(insured.retroactiveDate, 'MM/dd/yyyy')
                : format(effectiveDate, 'MM/dd/yyyy')}
        </Text>
    ));
    return (
        <ColumnLayout gap="none">
            <StackLayout gap="8">
                <Text style="heading 4">Individual Insured(s)</Text>
                {insuredNameList}
            </StackLayout>
            <StackLayout gap="8">
                <Text style="heading 4">Retroactive date</Text>
                {insuredRetroDateList}
            </StackLayout>
        </ColumnLayout>
    );
}
