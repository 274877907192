import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { BundleQuoteRepository } from '../repositories';
import { aggregateErrors } from './useCaseTypes';

export interface RequestReferredQuoteReviewRequest {
    applicationIdList: UUID[];
}

export interface RequestReferredQuoteReview extends UseCase {
    execute(
        request: RequestReferredQuoteReviewRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed>;
}

@injectable()
class RequestReferredQuoteReviewUseCase extends UseCase implements RequestReferredQuoteReview {
    public static type = Symbol('BundleQuote/RequestReferredQuoteReview');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BundleQuoteRepository) private bundleQuoteRepository: BundleQuoteRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationIdList,
    }: RequestReferredQuoteReviewRequest): AsyncResult<void, InvalidArgument | OperationFailed> {
        const submitForReviewResultList = await Promise.allSettled(
            applicationIdList.map((applicationId) => {
                return this.bundleQuoteRepository.submitForReview(applicationId);
            }),
        );

        const errors = aggregateErrors<RequestReferredQuoteReview>(
            submitForReviewResultList,
            'Failed request review request',
        );

        if (errors.length) {
            return Failure(errors);
        }
        return Success();
    }
}

export const RequestReferredQuoteReview: UseCaseClass<RequestReferredQuoteReview> =
    RequestReferredQuoteReviewUseCase;
