import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { Location } from '@embroker/shotwell/core/types/Location';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import {
    defineValidator,
    Joi,
    Schema,
    TypeChecker,
    Validator,
} from '@embroker/shotwell/core/validation/schema';

/**
 * Represent CertificateEntityRole Object
 */

export type CertificateEntityRoleType = 'Holder' | 'Owner';

export type CertificateEntityRoleList = CertificateEntityRole[];

export interface CertificateEntityRole {
    roleType: CertificateEntityRoleType;
    organizationId?: UUID;
    organizationName: string;
    organizationEmail?: EmailAddress;
    userId?: UUID;
    userEmail?: EmailAddress;
    location?: Location;
    signedUp?: boolean;
    token?: string;
    suppressEmailNotifications?: boolean;
}

export interface CertificateRoleValidator {
    /**
     * A Joi schema matching a valid CertificateEntityRole object.
     */
    readonly schema: Schema.ObjectSchema<CertificateEntityRole>;
    /**
     * Type predicate that checks if a given value can be used as CertificateEntityRole object.
     *
     * Use this only to do early returns. It's recommended to use validate()
     * before using an unknown value as CertificateEntityRole object as it normalizes the value
     * in addition to performing the same validation as check().
     */
    readonly check: TypeChecker<CertificateEntityRole>;
    /**
     * Validates and normalizes the given value to a CertificateEntityRole object.
     *
     * This should be used for all untrusted inputs to verify and, if required
     * (and possible), normalize the input.
     */
    readonly validate: Validator<CertificateEntityRole>;
}

export interface CertificateEntityRoleInput {
    /**
     * Role type for certificate entity
     * Valid roles are 'Owner' and 'Holder'
     */
    roleType: CertificateEntityRoleType;
    /**
     * Id of the organization associated with the certificate
     */
    organizationId?: UUID;
    /**
     * Name of the organization associated with the certificate
     */
    organizationName: string;
    /**
     * Email of the organization associated with the certificate
     */
    organizationEmail?: EmailAddress;
    /**
     * Id of the user (owner) to whom certificate was issued to
     */
    userId?: UUID;
    /**
     * Email of the user (owner) to whom certificate was issued to
     */
    userEmail?: EmailAddress;
    /**
     * Location of the organization associated with the certificate
     */
    location?: Location;
    /**
     * If the organization (user) was invited to the platform this flag indicates if they have signed up
     */
    signedUp?: boolean;
    /**
     * Invitation token for the invited organization
     */
    token?: string;
    /**
     * Indicates if notification email should be sent to organization associated with the certificate
     */
    suppressEmailNotifications?: boolean;
}

export const CertificateEntityRole = {
    ...defineValidator<CertificateEntityRole>(
        Joi.object({
            roleType: Joi.valid('Holder').valid('Owner'),
            organizationId: UUID.schema.optional(),
            organizationName: Joi.string().allow(''),
            organizationEmail: EmailAddress.schema.optional().allow(''),
            userId: UUID.schema.optional(),
            userEmail: EmailAddress.schema.optional().allow(''),
            location: Location.schema.optional(),
            signedUp: Joi.boolean().optional(),
            token: Joi.string().optional().allow(''),
            suppressEmailNotifications: Joi.boolean().optional(),
        }).preferences({ presence: 'required' }),
    ),
    create(certificateEntityRole: CertificateEntityRoleInput) {
        return CertificateEntityRole.validate(certificateEntityRole);
    },
};
