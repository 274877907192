import { API } from '@embroker/shotwell-api/app';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult, handleOperationFailure, isErr } from '@embroker/shotwell/core/types/Result';
import { CrimeConfig } from '../../types/CrimeConfig';
import { CrimeConfigRepository } from './index';

@injectable()
export class APICrimeConfigRepository implements CrimeConfigRepository {
    private config?: { crimeDevModeEffectiveDate: string; isEmbrokerCrimeEnabled: boolean };

    async getCrimeConfig(): AsyncResult<CrimeConfig, InvalidArgument | OperationFailed> {
        if (this.config !== undefined) {
            return CrimeConfig.create({
                crimeDevModeEffectiveDate: this.config.crimeDevModeEffectiveDate,
                isEmbrokerCrimeEnabled: this.config.isEmbrokerCrimeEnabled,
            });
        }
        const getConfigResponse = await API.request('global/get_config');

        if (isErr(getConfigResponse)) {
            return handleOperationFailure(getConfigResponse);
        }

        this.config = {
            crimeDevModeEffectiveDate: getConfigResponse.value.crime_dev_mode_effective_date,
            isEmbrokerCrimeEnabled: getConfigResponse.value.is_embroker_crime_enabled,
        };

        return CrimeConfig.create({
            crimeDevModeEffectiveDate: this.config.crimeDevModeEffectiveDate,
            isEmbrokerCrimeEnabled: this.config.isEmbrokerCrimeEnabled,
        });
    }
}
