import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { PolicyRepository } from '../repositories/PolicyRepository';

export interface GetUnprocessedPolicies extends UseCase {
    execute(): AsyncResult<string[], UnknownEntity | InvalidArgument | OperationFailed>;
}

@injectable()
export class GetUnprocessedPoliciesUseCase extends UseCase {
    public static type = Symbol('Policy/GetUnprocessedPolicies');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(PolicyRepository) private policyRepository: PolicyRepository,
    ) {
        super(eventBus);
    }

    async execute(): AsyncResult<string[], UnknownEntity | InvalidArgument | OperationFailed> {
        const result = await this.policyRepository.getUnprocessedPolicies();
        if (isErr(result)) {
            return result;
        }
        return Success(result.value as string[]);
    }
}

export const GetUnprocessedPolicies: UseCaseClass<GetUnprocessedPolicies> =
    GetUnprocessedPoliciesUseCase;
