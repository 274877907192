import { valueObject } from '@embroker/shotwell/core/types/ValueObject';
import { defineValidator, Joi, Schema } from '@embroker/shotwell/core/validation/schema';
import { QuoteOptions } from '../../../../quote/entities/Quote';

export const BOPChubbPerOccurrenceLimit = [1_000_000, 2_000_000] as const;
export type BOPChubbPerOccurrenceLimit = (typeof BOPChubbPerOccurrenceLimit)[number];
export const BOPChubbAggregateLimit = [2_000_000, 4_000_000] as const;
export type BOPChubbAggregateLimit = (typeof BOPChubbAggregateLimit)[number];
export const BOPChubbProductCode = [
    'GeneralLiability',
    'GeneralLiabilityBusinessPersonalProperty',
] as const;
export type BOPChubbProductCode = (typeof BOPChubbProductCode)[number];

export interface BOPChubbQuoteOptions extends QuoteOptions {
    readonly perOccurrenceLimit: BOPChubbPerOccurrenceLimit;
    readonly aggregateLimit: BOPChubbAggregateLimit;
    readonly productCode: BOPChubbProductCode;
    readonly omitHiredAndNonOwnedAutoCoverage: boolean;
    readonly isDeselected: boolean;
}

export const BOPChubbQuoteOptions = valueObject({
    ...defineValidator<BOPChubbQuoteOptions>({
        effectiveDate: Joi.date().required(),
        perOccurrenceLimit: Joi.number()
            .valid(...BOPChubbPerOccurrenceLimit)
            .required(),
        aggregateLimit: Joi.number()
            .valid(...BOPChubbAggregateLimit)
            .custom((value, helpers) => {
                return validateAggregateLimit(
                    value,
                    helpers.state.ancestors[0].perOccurrenceLimit,
                    helpers,
                );
            })
            .required(),
        productCode: Joi.string()
            .valid(...BOPChubbProductCode)
            .required(),
        omitHiredAndNonOwnedAutoCoverage: Joi.boolean().required(),
        isDeselected: Joi.boolean().required(),
    }),
});

function validateAggregateLimit(
    aggregateLimit: BOPChubbAggregateLimit,
    perOccurrenceLimit: BOPChubbPerOccurrenceLimit,
    helpers: Schema.CustomHelpers<number>,
): BOPChubbAggregateLimit | Schema.ErrorReport {
    if (aggregateLimit == 2 * perOccurrenceLimit) {
        return aggregateLimit;
    }
    return helpers.error('aggregateLimit.invalid');
}
