import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { LPLQuoteRepository } from '../repositories/LPLQuoteRepository';
import { LPLConfig } from '../types/LPLConfig';

export interface GetLPLConfig extends UseCase {
    execute(): AsyncResult<LPLConfig, InvalidArgument | OperationFailed>;
}

@injectable()
class GetLPLConfigUseCase extends UseCase implements GetLPLConfig {
    public static type = Symbol('LPLQuote/GetLPLConfig');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(LPLQuoteRepository) private lplQuoteRepository: LPLQuoteRepository,
    ) {
        super(eventBus);
    }

    public async execute(): AsyncResult<LPLConfig, InvalidArgument | OperationFailed> {
        return await this.lplQuoteRepository.getLPLConfig();
    }
}

export const GetLPLConfig: UseCaseClass<GetLPLConfig> = GetLPLConfigUseCase;
