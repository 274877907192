import { container, inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { UserOnboardingStepType, UserOnboardingSubStepType } from '../types/UserOnboardingDetails';
import { Nullable } from '@embroker/ui-toolkit/v2';
import { NAICS_CODE_TO_VERTICAL } from '../types/enums';
import { OnboardingPrefillQuestionnaireData } from '../types/OnboardingPrefillQuestionnaireData';
import { GrowthBookExperimentationService } from '@app/experimentation/services/GrowthBookExperimentationService';

export const NaicsRefinementTypes = ['nonTechConsultant', 'accounting'] as const;
type NaicsRefinementTypesListType = typeof NaicsRefinementTypes;
export type RefinementQuestionType = NaicsRefinementTypesListType[number];

interface OnboardingStepDefinition {
    stepType: UserOnboardingStepType;
    isNotRequired?: (data: OnboardingPrefillQuestionnaireData) => boolean;
}

export interface GetUserOnboardingStepRequest {
    currentStep: Nullable<UserOnboardingStepType>;
    questionnaireData?: OnboardingPrefillQuestionnaireData;
    progessType: 'forward' | 'backward';
}

export type GetUserOnboardingStepResponse = Nullable<{
    stepType: NonNullable<UserOnboardingStepType>;
}>;
export interface GetUserOnboardingStep extends UseCase {
    execute(
        request: GetUserOnboardingStepRequest,
    ): AsyncResult<GetUserOnboardingStepResponse, never>;
}

const NON_TECH_NAICS_CODES_REQUIRE_REFINEMENT = [
    '541611',
    '541612',
    '541613',
    '541614',
    '541618',
    '541620',
];

const ACCOUNTING_NAICS_CODES_REQUIRE_REFINEMENT = ['541219', '541214'];

const ACCOUNTING_DNB_CODES_REQUIRE_REFINEMENT = [
    ...ACCOUNTING_NAICS_CODES_REQUIRE_REFINEMENT,
    '541211',
    '541213',
];

@injectable()
class GetUserOnboardingStepUseCase extends UseCase implements GetUserOnboardingStep {
    public static type = Symbol('Global/GetUserOnboardingStep');

    /**
     * constructor for the GetUserOnboardingStep use case
     * @param eventBus An event bus this Use Case will publish events to.
     * @param entityTypeRepository is the repository used to fetch globaly available data
     */
    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    public async execute(
        request?: GetUserOnboardingStepRequest,
    ): AsyncResult<GetUserOnboardingStepResponse, never> {
        const { currentStep, progessType = 'forward', questionnaireData } = request || {};

        const userOnboardingQuestionnaireData = questionnaireData || {};
        const nextStep = this.findNextEligibleOnboardingStep(
            userOnboardingQuestionnaireData,
            progessType,
            currentStep,
        );

        if (nextStep) {
            return Success({ stepType: nextStep });
        }

        return Success(null);
    }
    private findNextEligibleOnboardingStep(
        data: OnboardingPrefillQuestionnaireData,
        progessType: 'forward' | 'backward' = 'forward',
        currentStep?: UserOnboardingStepType,
    ): UserOnboardingStepType | null {
        const orderedOnboardingStepDefinition =
            progessType === 'forward'
                ? OnboardingStepDefinition
                : OnboardingStepDefinition.slice().reverse();

        if (!currentStep) {
            return orderedOnboardingStepDefinition[0].stepType;
        }

        const currentIndex = orderedOnboardingStepDefinition.findIndex(
            (step) => step.stepType === currentStep,
        );

        if (currentIndex === -1) return null;

        for (let i = currentIndex + 1; i < orderedOnboardingStepDefinition.length; i++) {
            const { isNotRequired } = orderedOnboardingStepDefinition[i];
            if (isNotRequired && isNotRequired(data)) {
                continue;
            }
            return orderedOnboardingStepDefinition[i].stepType;
        }
        return null;
    }
}

export const GetUserOnboardingStep: UseCaseClass<GetUserOnboardingStepUseCase> =
    GetUserOnboardingStepUseCase;

function getVerticalRefinementType(
    questionnaireData: OnboardingPrefillQuestionnaireData,
): UserOnboardingSubStepType | undefined {
    const { naics_code, vetting } = questionnaireData;
    const vertical = naics_code && NAICS_CODE_TO_VERTICAL[naics_code];
    if (vertical === 'LawFirm') {
        return 'lawVerticalRefinement';
    }
    const isTechVerticalOneByEmbroker = container
        .get<GrowthBookExperimentationService>(GrowthBookExperimentationService)
        .getFeatureValue('tech-vertical-one-by-embroker', false);
    const vettingRequired = isVettingRequired(naics_code);
    if (
        isTechVerticalOneByEmbroker &&
        vertical === 'TechCompanies' &&
        (!vettingRequired || (vettingRequired && vetting))
    ) {
        return 'staffDetailsPage';
    }
}

export function getNaicsRefinementType(
    questionnaireData: OnboardingPrefillQuestionnaireData,
): RefinementQuestionType | undefined {
    const { naics_code = null } = questionnaireData;

    if (isNonTechRefinementRequired(naics_code)) {
        return 'nonTechConsultant';
    }

    if (isAccountingRefinementRequired(questionnaireData)) {
        return 'accounting';
    }
    return undefined;
}

export const NAICS_CODE_VETTING_REQUIRED = [
    '425110',
    '517911',
    '522320',
    '541330',
    '541430',
    '611430',
    '611610',
    '611620',
    '611691',
    '611699',
    '611710',
];

export function isVettingRequired(naicsCode?: string) {
    return typeof naicsCode === 'string' && NAICS_CODE_VETTING_REQUIRED.includes(naicsCode);
}

export function isNonTechRefinementRequired(naics: Nullable<string>): boolean {
    const nonTechRefinementRequired = Boolean(
        NON_TECH_NAICS_CODES_REQUIRE_REFINEMENT.find((naicsCode) => naicsCode === naics),
    );
    return nonTechRefinementRequired;
}

export function isAccountingRefinementRequired(
    questionnaireData: OnboardingPrefillQuestionnaireData,
): boolean {
    const { naics_code, dnb_naics_code } = questionnaireData;

    // If the submitted naics value is one of the codes in the NAICS_CODES_REQUIRE_REFINEMENT
    // We ask the user a refinement question
    const naicsRequiresRefinement = Boolean(
        ACCOUNTING_NAICS_CODES_REQUIRE_REFINEMENT.find((naicsCode) => naicsCode === naics_code),
    );

    // If the submitted naics value is one of the codes in the DNB_CODES_REQUIRE_REFINEMENT
    // ie if the naics input is prepopulated by DnB and the user submits the same code
    // We ask the user a refinement question
    const dnbNaicsRequiresRefinement =
        naics_code === dnb_naics_code &&
        ACCOUNTING_DNB_CODES_REQUIRE_REFINEMENT.some((naicsCode) => naicsCode === naics_code);

    return naicsRequiresRefinement || dnbNaicsRequiresRefinement;
}

export const OnboardingStepDefinition: OnboardingStepDefinition[] = [
    { stepType: 'businessLocation' },
    { stepType: 'naicsConfirmation' },
    {
        stepType: 'naicsRefinement',
        isNotRequired: (data) => getNaicsRefinementType(data) === undefined,
    },
    {
        stepType: 'vettingRefinement',
        isNotRequired: (data) => !isVettingRequired(data.naics_code),
    },
    {
        stepType: 'industryRefinement',
        isNotRequired: (data) => getVerticalRefinementType(data) === undefined,
    },
];

// TEST SCENARIOS
//
//
// LawFirm vertical flow
// Accounting refinement flow
// MPL flow
// Non tech refinement question
// Default flow
// Deep linking flow
// Deeplinking override flow
