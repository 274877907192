import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { PhoneNumber } from '@embroker/shotwell/core/types/PhoneNumber';
import { isErr, Success } from '@embroker/shotwell/core/types/Result';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    Button,
    Form,
    FormLayout,
    Modal,
    ModalActions,
    ModalState,
    StackLayout,
    StatusMessage,
    StatusMessageList,
    Text,
    useModal,
} from '@embroker/ui-toolkit/v2';
import React, { useEffect, useState } from 'react';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { ErrorCode } from '../../errors';
import {
    GetActiveUserProfile,
    GetActiveUserProfileResponse,
} from '../../useCases/GetActiveUserProfile';
import { UpdateUserProfile } from '../../useCases/UpdateUserProfile';
import { UserOrgPage } from './UserOrgPage';
import { ModalLayout } from '../../../view/components/ModalLayout.view';

interface UpdateProfileFormData {
    firstName: string;
    lastName: string;
    title: string;
    phoneNumber: PhoneNumber;
    email: string;
    password: Nullable<string>;
}

const UpdateProfileForm = createForm<UpdateProfileFormData>({
    fields: {
        firstName: {
            type: 'text',
            validator: Joi.string().required(),
            formatValidationError(error) {
                return 'Please enter first name';
            },
        },
        lastName: {
            type: 'text',
            validator: Joi.string().required(),
            formatValidationError(error) {
                return 'Please enter last name';
            },
        },
        title: {
            type: 'text',
            validator: Joi.string().required(),
            formatValidationError() {
                return 'Please enter job title';
            },
        },
        phoneNumber: {
            type: 'tel',
            validator: PhoneNumber.schema.required(),
            formatValidationError(error) {
                return 'You must enter valid phone number';
            },
        },
        email: {
            type: 'email',
            validator: Joi.string().email({ tlds: false }).required(),
            formatValidationError(error) {
                switch (error.details.validator) {
                    case 'any.required':
                        return 'Please enter your email';
                    case 'string.email':
                        return 'You must enter a valid email address';
                    default:
                        return error.message;
                }
            },
        },
        password: {
            type: 'password',
            validator: Joi.string().allow(null),
            formatValidationError(error) {
                return 'Please enter valid password';
            },
        },
    },
    useCase: UpdateUserProfile,
    formatSubmitErrors(errors) {
        for (const error of errors) {
            if (error.code == ErrorCode.InvalidEmail) {
                return [`Please enter a valid email`];
            }
        }
        return ['Sorry, update failed'];
    },
});

export function UpdateProfile() {
    const { result } = useUseCase(GetActiveUserProfile);

    if (!result) {
        return null;
    }

    if (isErr(result)) {
        return <ErrorPage errors={result.errors} />;
    }

    return <UpdateProfileFormComponent user={result.value} />;
}

interface UpdateProfileData {
    user: Immutable<GetActiveUserProfileResponse>;
}

function UpdateProfileFormComponent(props: UpdateProfileData) {
    const navigation = useNavigation();
    const [success, setSuccess] = useState(false);
    const [emailChanged, setPasswordChanged] = useState(false);
    const [phoneNumberErrors, setPhoneNumberErrors] = useState<string[]>([]);
    const { submit, status, fields, messages, result, setValue, value } = useForm(
        UpdateProfileForm,
        {
            firstName: props.user.firstName,
            lastName: props.user.lastName,
            title: props.user.title,
            phoneNumber: props.user.phoneNumber,
            email: props.user.email,
        },
    );
    const modal = useModal();

    useEffect(() => {
        if (status === 'submitted' && result == undefined) {
            // If email was changed, logout user
            if (emailChanged) {
                navigation.navigate('/logout');
            } else {
                setSuccess(true);
            }
            setPasswordChanged(false);
        }
        if (status === 'invalid') {
            setSuccess(false);
        }
    }, [status, result, emailChanged, navigation, setSuccess]);

    const onPasswordModalSubmit = (enteredPassword: string) => {
        const values = { ...value, password: enteredPassword };
        setValue(values);
        setPasswordChanged(true);
        modal.hide();
        submit();
    };

    const checkPhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        const phoneNumber = event.target.value === '' ? null : event.target.value;
        if (phoneNumber === null) {
            setPhoneNumberErrors(['Please enter your phone number']);
        } else if (!PhoneNumber.check(phoneNumber)) {
            setPhoneNumberErrors(['Phone number must have 10 digits']);
        } else {
            setPhoneNumberErrors([]);
        }
    };

    return (
        <UserOrgPage>
            <FormLayout>
                <Form>
                    <StackLayout data-e2e="update-usr-profile-status-msg-wrapper" gap="32">
                        {!success && <StatusMessageList messages={messages} status="error" />}
                        {success && (
                            <StatusMessage status="success">
                                Profile successfully updated
                            </StatusMessage>
                        )}
                        <Text style="heading 3">Update your profile</Text>
                        <StackLayout>
                            <Form.Field
                                data-e2e="update-usr-profile-first-name"
                                inputProps={{
                                    ...fields.firstName.props,
                                }}
                                label="First name"
                                messages={fields.firstName.messages}
                                type={fields.firstName.type}
                            />
                            <Form.Field
                                data-e2e="update-usr-profile-last-name"
                                inputProps={{
                                    ...fields.lastName.props,
                                }}
                                label="Last name"
                                messages={fields.lastName.messages}
                                type={fields.lastName.type}
                            />
                            <Form.Field
                                data-e2e="update-usr-profile-title"
                                inputProps={{
                                    ...fields.title.props,
                                }}
                                label="Title"
                                messages={fields.title.messages}
                                type={fields.title.type}
                            />
                            <Form.Field
                                data-e2e="update-usr-profile-phone-number"
                                inputProps={{
                                    ...fields.phoneNumber.props,
                                    onBlur: checkPhoneNumber,
                                }}
                                label="Phone number"
                                messages={phoneNumberErrors}
                                type="tel"
                            />
                            <Form.Field
                                data-e2e="update-usr-profile-email"
                                inputProps={{
                                    ...fields.email.props,
                                }}
                                label="Email"
                                messages={fields.email.messages}
                                type={fields.email.type}
                            />
                        </StackLayout>
                        <Button
                            data-e2e="update-usr-submit-button"
                            // if user updated email, prompt password modal
                            onClick={() => {
                                if (props.user.email !== fields.email.props.value) {
                                    modal.show();
                                } else {
                                    submit();
                                }
                            }}
                        >
                            Update profile
                        </Button>
                    </StackLayout>
                </Form>
                <PasswordModal modal={modal} onPasswordModalSubmit={onPasswordModalSubmit} />
            </FormLayout>
        </UserOrgPage>
    );
}

interface PasswordModalInputData {
    modal: ModalState & ModalActions;
    onPasswordModalSubmit(password: any): void;
}

interface PasswordFormData {
    readonly password: string;
}

const PasswordForm = createForm<PasswordFormData>({
    fields: {
        password: {
            type: 'password',
            validator: Joi.string().min(8).required(),
            formatValidationError(error) {
                switch (error.details.validator) {
                    case 'any.required':
                        return 'You must enter a password';
                    case 'string.min':
                        return 'Password must be at least 8 characters long';
                    default:
                        return error.message;
                }
            },
        },
    },
    async submit(data) {
        return Success();
    },
});

function PasswordModal({ modal, onPasswordModalSubmit }: PasswordModalInputData) {
    const { submit, fields, status, setValue } = useForm(PasswordForm);

    useEffect(() => {
        if (status === 'submitted') {
            onPasswordModalSubmit(fields.password.props.value);
            setValue({ password: '' });
        }
    }, [status, fields, onPasswordModalSubmit, setValue]);

    return (
        <Modal {...modal}>
            <Form data-e2e="confirm-pwd-form-wrapper" onSubmit={submit}>
                <ModalLayout
                    title="Confirm Password"
                    primaryAction={{
                        label: 'Proceed',
                        onClick: () => submit(),
                        'data-e2e': 'confirm-pwd-submit-button',
                    }}
                >
                    <Text>
                        Please enter your password again. You will be logged out. Just in case, a
                        security email will be sent to your old email address.
                    </Text>
                    <Form.Field
                        data-e2e="confirm-pwd-input-field"
                        inputProps={{
                            ...fields.password.props,
                        }}
                        label="Password"
                        messages={fields.password.messages}
                        type={fields.password.type}
                    />
                </ModalLayout>
            </Form>
        </Modal>
    );
}
