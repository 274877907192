import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface PCoMLEndorsementConfig {
    readonly pcomlEndorsementEnabled: boolean;
}

export const PCoMLEndorsementConfig = {
    ...defineValidator<PCoMLEndorsementConfig>({
        pcomlEndorsementEnabled: Joi.boolean(),
    }),
    create(pcomlEndorsementConfig: PCoMLEndorsementConfig) {
        return PCoMLEndorsementConfig.validate(pcomlEndorsementConfig);
    },
};
