import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { WCDataRepository } from '../repositories/WCDataRepository';
import { Place } from '../types/Place';

export interface CreateWCEmployeeDataTableRequest {
    locations: Place[];
    naicsCode: string;
}

export interface CreateWCEmployeeDataTable extends UseCase {
    execute(
        request: CreateWCEmployeeDataTableRequest,
    ): AsyncResult<void | InvalidArgument | OperationFailed>;
}

@injectable()
class CreateWCEmployeeDataTableUseCase extends UseCase implements CreateWCEmployeeDataTable {
    public static type = Symbol('Shopping/CreateWCEmployeeDataTableUseCase');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(WCDataRepository) private wcDataRepository: WCDataRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        locations,
        naicsCode,
    }: CreateWCEmployeeDataTableRequest): AsyncResult<void | InvalidArgument | OperationFailed> {
        const employeeData: Record<string, { $: {}[] }> = {};
        const loadDataResult = await this.wcDataRepository.loadData();

        if (isErr(loadDataResult)) {
            return handleOperationFailure(loadDataResult);
        }

        for (const location of locations) {
            if (!location.state) {
                continue;
            }

            const wcCodes = this.wcDataRepository.getDefaultClassCodes(naicsCode, location.state);

            if (!employeeData[location.state]) {
                employeeData[location.state] = { $: [] };
            }

            const workerTypes = wcCodes.map((code) => {
                return {
                    class_code: code,
                    payroll: 0,
                    number_of_employees: 0,
                };
            });
            employeeData[location.state].$.push({
                location_id: location.id,
                worker_types: { $: workerTypes },
            });
        }

        this.wcDataRepository.setEmployeeDataToSessionStorage(employeeData);

        return Success();
    }
}

export const CreateWCEmployeeDataTable: UseCaseClass<CreateWCEmployeeDataTable> =
    CreateWCEmployeeDataTableUseCase;
