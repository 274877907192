import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Nullable } from '@embroker/shotwell/core/types';
import { Success, SuccessResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { isAuthenticated, Role } from '../entities/Session';
import { SessionRepository } from '../repositories/SessionRepository';

/**
 * AutoLogin Use Case response data model
 */
export type AutoLoginResponse =
    | {
          isAuthenticated: true;
          userId: UUID;
          organizationId: Nullable<UUID>;
          userOrganizationIdList?: UUID[];
          roles: Role[];
      }
    | {
          isAuthenticated: false;
      };

/**
 * AutoLogin Use Case is used to automatically authenticate the user on the platform without asking for username and password
 */

export interface AutoLogin extends UseCase {
    execute(): Promise<SuccessResult<AutoLoginResponse>>;
}

@injectable()
class AutoLoginUseCase extends UseCase implements AutoLogin {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/AutoLogin');
    /**
     * Constructor for AutoLogin use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param sessionRepository is session repository used authenticate the user on the platform using the provided sessionToken
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(SessionRepository) private sessionRepository: SessionRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes AutoLogin use case
     * @returns Session entity after successful execution
     */
    public async execute(): Promise<SuccessResult<AutoLoginResponse>> {
        const session = (await this.sessionRepository.getActiveSession()).value;

        if (session !== null) {
            this.eventBus.publishEntityEvents(session);

            if (isAuthenticated(session)) {
                return Success<AutoLoginResponse>({
                    isAuthenticated: true,
                    userId: session.userId,
                    organizationId: session.organizationId,
                    userOrganizationIdList: session.userOrganizationIdList,
                    roles: session.roles,
                });
            }
        }

        return Success<AutoLoginResponse>({ isAuthenticated: false });
    }
}

export const AutoLogin: UseCaseClass<AutoLogin> = AutoLoginUseCase;
