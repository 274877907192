import { QuoteExtended } from '@embroker/shotwell-api/app';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, Failure, isErr } from '@embroker/shotwell/core/types/Result';
import { startOfToday } from 'date-fns';
import { QuoteExpiration } from '../../../quote/types/QuoteExpiration';
import { QuotingEngineCyberCowbell } from '../../../shopping/types/enums';
import { BundleCoverageTypeEnum, BundleQuoteCoverage } from '../../types/BundleQuoteCoverage';
import { toBundleQuoteCoverage } from '../bundleMappingFunctions';
import { getCyberCoverageMetadata } from '../lawCyber/buildLawCyberCoverage';
import { CowbellCyberQuote } from './entities/CowbellCyberQuote';
import { toCowbellCyberQuoteDetails, toCowbellCyberQuoteOptions } from './mappingFunctions';
import { JSONSerdes } from '@embroker/shotwell/core/encoding';
import { QuestionnaireData } from '../../types/BundleQuoteQuestionnaireData';

export async function buildCowbellCyberCoverage(
    cowbellCyberQuoteExtended: Immutable<QuoteExtended>,
    isBroker: boolean,
    omitEffectiveDateValidation = false,
): AsyncResult<BundleQuoteCoverage<CowbellCyberQuote>, OperationFailed | InvalidArgument> {
    if (!cowbellCyberQuoteExtended) {
        return Failure(
            OperationFailed({ message: 'cowbellCyberQuoteExtended is null or undefined' }),
        );
    }
    const cowbellCyberCoverageMetadata = getCyberCoverageMetadata();

    const questionnaireDataResult = JSONSerdes.deserialize(
        cowbellCyberQuoteExtended.questionnaire_data,
    );
    if (isErr(questionnaireDataResult)) {
        return Failure(
            InvalidArgument({
                argument: 'questionnaire_data',
                value: cowbellCyberQuoteExtended.questionnaire_data,
            }),
        );
    }

    const questionnaireData = QuestionnaireData.create(
        questionnaireDataResult.value as QuestionnaireData,
    );
    if (isErr(questionnaireData)) {
        return Failure(
            InvalidArgument({
                argument: 'Questionnaire data',
                value: questionnaireData.errors,
            }),
        );
    }
    if (!cowbellCyberQuoteExtended.quote) {
        return toBundleQuoteCoverage<CowbellCyberQuote>(
            BundleCoverageTypeEnum.LawCyberBundleCoverageCowbell,
            cowbellCyberQuoteExtended.app_status,
            cowbellCyberCoverageMetadata,
            questionnaireData.value,
            cowbellCyberQuoteExtended.app_valid_until,
        );
    }
    if (!cowbellCyberQuoteExtended.quote.details.cyber_cowbell) {
        return Failure(
            OperationFailed({ message: 'cowbellCyberQuoteDetails is null or undefined' }),
        );
    }
    if (!cowbellCyberQuoteExtended.quote.options.cyber_cowbell) {
        return Failure(
            OperationFailed({ message: 'cowbellCyberQuoteOptions is null or undefined' }),
        );
    }

    const cowbellCoverage = await CowbellCyberQuote.create({
        isIndication: cowbellCyberQuoteExtended.quote.is_indication,
        id: cowbellCyberQuoteExtended.quote.id,
        applicationId: cowbellCyberQuoteExtended.quote.app_id,
        details: toCowbellCyberQuoteDetails(
            cowbellCyberQuoteExtended.quote.details.cyber_cowbell,
            cowbellCyberQuoteExtended.quote.taxes,
        ),
        options: toCowbellCyberQuoteOptions(cowbellCyberQuoteExtended.quote.options.cyber_cowbell),
        status: 'draft',
        totalPayable: cowbellCyberQuoteExtended.quote.total_payable,
        totalPremium: cowbellCyberQuoteExtended.quote.total_premium,
        annualTechnologyFee: cowbellCyberQuoteExtended.quote.annual_technology_fee,
        daysToExpire: QuoteExpiration.getDaysLeftUntilExpiration({
            quotingEngine: QuotingEngineCyberCowbell,
            applicationStatus: cowbellCyberQuoteExtended.app_status,
            isBroker: isBroker,
            quoteEffectiveDate:
                cowbellCyberQuoteExtended.quote.options.cyber_cowbell.effective_date,
            today: startOfToday(),
            validUntil: cowbellCyberQuoteExtended.app_valid_until || null,
            omitEffectiveDateValidation,
        }),
        quoteNumber: cowbellCyberQuoteExtended.quote.quote_number,
        fees: cowbellCyberQuoteExtended.quote.fees || undefined,
        fileKey: cowbellCyberQuoteExtended.quote.file_key || undefined,
    });

    if (isErr(cowbellCoverage)) {
        return Failure(
            InvalidArgument({ argument: 'Cowbell cyber coverage', value: cowbellCoverage.errors }),
        );
    }

    return toBundleQuoteCoverage<CowbellCyberQuote>(
        BundleCoverageTypeEnum.LawCyberBundleCoverageCowbell,
        cowbellCyberQuoteExtended.app_status,
        cowbellCyberCoverageMetadata,
        questionnaireData.value,
        cowbellCyberQuoteExtended.app_valid_until,
        cowbellCoverage.value,
    );
}
