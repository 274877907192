import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import {
    AsyncResult,
    isErr,
    Success,
    handleOperationFailure,
} from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { URI } from '@embroker/shotwell/core/types/URI';
import { Applicant } from '../../shopping/entities/Applicant';
import { ApplicantRepository } from '../../shopping/repositories/ApplicantRepository';
import { Coverage } from '../../shopping/types/Coverage';
import { GetRecommendedRequest, RecommendRepository } from '../repositories/RecommendRepository';
import { MPLValidNaicsCodes } from '../../userOrg/types/MPLValidNaicsCodes';
import {
    NumberRangeOfW2Employees,
    AppTypeCodeListMPL,
    AppTypeCodeListMPLBundle,
} from '@app/shopping/types/enums';
import { GetActiveOrganizationProfile } from '../../userOrg/useCases/GetActiveOrganizationProfile';
import { CoverageEligibility } from '../../shopping/types/CoverageEligibility';
import { AppTypeCodeListManualDefaultPL } from '@app/shopping/types/spec_enums';

export interface GetRecommendedCoveragesRequest {
    website?: URI;
    companyName?: string;
    naicsCode: string;
    hasRaisedFunding?: boolean;
    isTotalRevenueLargerThan20MillionDollars?: boolean;
    numberRangeOfW2Employees?: NumberRangeOfW2Employees;
    hasAutomobiles?: boolean;
    companyEmail?: EmailAddress;
    employeeCount?: number;
}

export interface GetRecommendedCoveragesResponse {
    readonly recommendedCoverages: Coverage[];
}

export interface GetRecommendedCoverages extends UseCase {
    execute(
        input?: GetRecommendedCoveragesRequest,
    ): AsyncResult<GetRecommendedCoveragesResponse, InvalidArgument | OperationFailed>;
}

@injectable()
class GetRecommendedCoveragesUseCase extends UseCase implements GetRecommendedCoverages {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Recommend/GetRecommendedCoverages');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(RecommendRepository) private recommendRepository: RecommendRepository,
        @inject(ApplicantRepository) private applicantRepository: ApplicantRepository,
        @inject(GetActiveOrganizationProfile.type)
        private getActiveOrganizationProfile: GetActiveOrganizationProfile,
    ) {
        super(eventBus);
    }

    public async execute(
        input?: GetRecommendedCoveragesRequest,
    ): AsyncResult<GetRecommendedCoveragesResponse, InvalidArgument | OperationFailed> {
        let repoRequestData: GetRecommendedRequest;
        if (input) {
            repoRequestData = getRecommendedRepoRequestBasedOnInput(input);
        } else {
            const getRecommendedRepoRequestResult = await this.getRecommendedRepoRequest();
            if (isErr(getRecommendedRepoRequestResult)) {
                return handleOperationFailure(getRecommendedRepoRequestResult);
            }
            repoRequestData = getRecommendedRepoRequestResult.value;
        }

        const getRecommendedCoveragesResult = await this.getRecommendedCoverages(repoRequestData);
        if (isErr(getRecommendedCoveragesResult)) {
            return handleOperationFailure(getRecommendedCoveragesResult);
        }
        const getActiveOrganizationProfileResp = await this.getActiveOrganizationProfile.execute();
        if (isErr(getActiveOrganizationProfileResp)) {
            return handleOperationFailure(getActiveOrganizationProfileResp);
        }
        const naicsCode = getActiveOrganizationProfileResp?.value?.organization?.naics || undefined;
        const headquartersState =
            getActiveOrganizationProfileResp?.value?.organization.headquarters.state;

        const isMPLVerticalEnabled = MPLValidNaicsCodes.isMPLVerticalEnabled(
            repoRequestData.naicsCode,
        );

        const recommendedCoveragesValue = getRecommendedCoveragesResult.value as Array<Coverage>;

        let recommendedCoverages = isMPLVerticalEnabled
            ? recommendedCoveragesValue
            : recommendedCoveragesValue.filter(
                  ({ appType }: Coverage) =>
                      ![AppTypeCodeListMPL, AppTypeCodeListMPLBundle].includes(appType),
              );

        if (CoverageEligibility.isManualProductLiabilityEligibile(headquartersState, naicsCode)) {
            recommendedCoverages = recommendedCoverages.filter(
                (appType) => appType.appType !== AppTypeCodeListManualDefaultPL,
            );
        }

        return Success<GetRecommendedCoveragesResponse>({ recommendedCoverages });
    }

    private async getRecommendedRepoRequest(): AsyncResult<
        GetRecommendedRequest,
        InvalidArgument | OperationFailed
    > {
        const applicantResult = await this.applicantRepository.getApplicant();

        if (isErr(applicantResult)) {
            return handleOperationFailure(applicantResult);
        }

        const applicant = applicantResult.value as Applicant;

        const repoRequestData: GetRecommendedRequest = {
            naicsCode: applicant.naicsIndustry || '',
            hasRaisedFunding: applicant.hasReceivedVCFunding,
            isTotalRevenueLargerThan20MillionDollars:
                applicant.isTotalRevenueLargerThan20MillionDollars,
            numberRangeOfW2Employees: applicant.numberRangeOfW2Employees,
            hasAutomobiles: applicant.hasAutomobiles,
            employeeCount: applicant.numberOfEmployees,
        };
        return Success(repoRequestData);
    }

    private async getRecommendedCoverages(
        repoRequestData: GetRecommendedRequest,
    ): AsyncResult<Array<Coverage>, InvalidArgument | OperationFailed> {
        const recommendedCoveragesResponse = await this.recommendRepository.getRecommended(
            repoRequestData,
        );

        if (isErr(recommendedCoveragesResponse)) {
            return recommendedCoveragesResponse;
        }

        const getActiveOrganizationProfileResp = await this.getActiveOrganizationProfile.execute();
        if (isErr(getActiveOrganizationProfileResp)) {
            return handleOperationFailure(getActiveOrganizationProfileResp);
        }

        let recommendedCoverages = recommendedCoveragesResponse.value as Array<Coverage>;

        const headquartersState =
            getActiveOrganizationProfileResp?.value?.organization.headquarters.state;

        const naicsCode = getActiveOrganizationProfileResp?.value?.organization.naics;

        recommendedCoverages = recommendedCoverages.filter((coverage) => {
            return (
                CoverageEligibility.getIneligibilityReasons(
                    coverage.appType,
                    headquartersState,
                    naicsCode,
                ).length === 0
            );
        });

        recommendedCoverages = recommendedCoverages.filter(
            (coverage) => coverage.appType !== 'AppTypeCodeListGAWorkersCompensation',
        );

        return Success(recommendedCoverages);
    }
}

function getRecommendedRepoRequestBasedOnInput(
    input: GetRecommendedCoveragesRequest,
): GetRecommendedRequest {
    return {
        naicsCode: input.naicsCode,
        hasRaisedFunding: input.hasRaisedFunding,
        isTotalRevenueLargerThan20MillionDollars: input.isTotalRevenueLargerThan20MillionDollars,
        numberRangeOfW2Employees: input.numberRangeOfW2Employees,
        hasAutomobiles: input.hasAutomobiles,
        employeeCount: input.employeeCount,
    };
}

export const GetRecommendedCoverages: UseCaseClass<GetRecommendedCoveragesUseCase> =
    GetRecommendedCoveragesUseCase;
