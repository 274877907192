import React from 'react';
import { ModalLayout } from '@app/view/components/ModalLayout.view';

interface ESPEndorsementPolicyUpdatedModalContentProps {
    onClose: () => void;
}

export function ESPEndorsementPolicyUpdatedModalContent({
    onClose,
}: ESPEndorsementPolicyUpdatedModalContentProps) {
    return (
        <ModalLayout
            title="Your management liability policy has been updated"
            primaryAction={{ label: 'Got It', onClick: onClose }}
        >
            If the change affects your other policies, one of our agents will contact you.
        </ModalLayout>
    );
}
