import React from 'react';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { StackLayout, Text } from '@embroker/ui-toolkit/v2';
import { convertLobTypeToDisplayName, Lob, LobType } from '../../../types/Lob';
import { AutoCoverageSectionBody } from './AutoCoverageSectionBody';
import { CoverageSectionTable } from './CoverageSectionTable';
import { UmbrellaExcessCoverageSectionBody } from './UmbrellaExcessCoverageSectionBody';
import { ProfessionalLiabilityCoverageSectionBody } from './ProfessionalLiabilityCoverageSectionBody';
import { LineOfBusinessCodeListItem } from '@embroker/shotwell-api/enums';
import { WCCoverageSectionBody } from './WCCoverageSectionBody';
import { PropertyCoverageSectionBody } from './PropertyCoverageSectionBody';
import { CoverageSectionLimitAndRetention } from './CoverageSectionLimitAndRetention';
import { PolicyDetailsCard } from './PolicyDetailsCard.view';

interface CoverageSectionCardProps {
    lob: Nullable<Immutable<Lob>>;
    isExpandable?: boolean;
    lineOfBusiness: LineOfBusinessCodeListItem;
}

export const CoverageSectionCard = ({
    lob,
    isExpandable,
    lineOfBusiness,
}: CoverageSectionCardProps) => {
    if (lob === null) {
        return null;
    }

    function getCoverageSectionBody(
        lob: Immutable<Lob>,
        lineOfBusiness: LineOfBusinessCodeListItem,
    ): JSX.Element {
        if (lob.lobType === 'umbrellaExcessLiabilityLob') {
            return <UmbrellaExcessCoverageSectionBody lob={lob} lineOfBusiness={lineOfBusiness} />;
        }

        if (lob.lobType === 'autoLiabilityLob') {
            return <AutoCoverageSectionBody lob={lob} lineOfBusiness={lineOfBusiness} />;
        }

        if (lob.lobType === 'workersCompensationLiabilityLob') {
            return <WCCoverageSectionBody lob={lob} />;
        }

        if (lob.lobType === 'propertyLob') {
            return <PropertyCoverageSectionBody lob={lob} />;
        }

        if (lob.lobType === 'professionalLiabilityLob') {
            return <ProfessionalLiabilityCoverageSectionBody lob={lob} />;
        }

        return (
            <StackLayout gap="16">
                <CoverageSectionLimitAndRetention lob={lob} />
                <Text style="heading 5">What's covered</Text>
                <CoverageSectionTable lob={lob} lineOfBusiness={lineOfBusiness} />
            </StackLayout>
        );
    }

    const coverageSectionBody = getCoverageSectionBody(lob, lineOfBusiness);

    return (
        <PolicyDetailsCard title={getCoverageTitle(lob.lobType)} isExpandable={isExpandable}>
            {coverageSectionBody}
        </PolicyDetailsCard>
    );
};

export function getCoverageTitle(lobType: LobType): string {
    if (lobType === 'cyberLiabilityLob') {
        return 'Technology E&O/Cyber';
    } else if (lobType === 'nonTechnologyBusinessAndManagementConsultantProfessionalLiabilityLob') {
        return 'Non-Technology Consultants Professional Liability';
    }

    return convertLobTypeToDisplayName(lobType);
}
