if (!('name' in function () {})) {
    // Function.prototype.name is not supported in Internet Explorer
    Object.defineProperty(Function.prototype, 'name', {
        get: function () {
            const value = (this.toString().match(/^function\s*([^\s(]+)/) || [])[1];
            Object.defineProperty(this, 'name', { value });
            return value;
        },
    });
}
