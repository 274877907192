import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { InvoiceTable } from '@embroker/ui-toolkit/v2';
import React, { useContext } from 'react';
import { hasRole } from '../../../../userOrg/entities/Session';
import { AppContext } from '../../../../view/AppContext';
import { QuoteBreakdownComponentProps } from '../../../view/components/QuoteLandingPage';
import { CrimeQuote } from '../../entities/CrimeQuote';
import { CrimeQuoteOptionsFormData } from './createCrimeQuoteOptionsForm';
import { getFees } from '../../../pcoml/view/components/QuoteBreakdown';

function getState(quote: CrimeQuote) {
    return quote.userInfo?.usaState;
}

export function CrimeQuoteBreakdown({
    quote,
    status,
}: QuoteBreakdownComponentProps<CrimeQuoteOptionsFormData, CrimeQuote>) {
    const { activeSession } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');
    const shouldHidePremium = status === 'dirty';
    const state = getState(quote);
    const isMississippi = state === 'MS';
    const { fees, mwuaFee } = getFees(quote.details);
    return (
        <InvoiceTable>
            <InvoiceTable.Section>
                <InvoiceTable.Subtotal data-e2e="selected-coverages-list" title="Coverages" />
                <InvoiceTable.Item title="Commercial Crime" data-e2e="comercial-crime-premium">
                    {!shouldHidePremium ? (
                        <MoneyDisplay value={quote.details.totalBasePremium} />
                    ) : null}
                </InvoiceTable.Item>
            </InvoiceTable.Section>
            {!isBroker && (
                <InvoiceTable.Section>
                    <InvoiceTable.Subtotal title="Subtotal">
                        {!shouldHidePremium ? (
                            <MoneyDisplay value={quote.details.subtotalAmount} />
                        ) : null}
                    </InvoiceTable.Subtotal>
                    <InvoiceTable.Item
                        title="Taxes"
                        tooltip="Surplus Line Tax is required on all surplus line insurance transactions and vary by state."
                        data-e2e="crime-taxes"
                    >
                        {!shouldHidePremium ? <MoneyDisplay value={quote.details.taxes} /> : null}
                    </InvoiceTable.Item>
                    {isMississippi ? (
                        <InvoiceTable.Item title="MWUA Fee" data-e2e="crime-mwua-fee">
                            {!shouldHidePremium ? <MoneyDisplay value={mwuaFee} /> : null}
                        </InvoiceTable.Item>
                    ) : null}
                    <InvoiceTable.Item
                        title="Fees (excluding transaction fees based on your choice of payment)"
                        tooltip="Often referred to as “Stamping Fees”, Surplus Lines Fees are only required by certain states. In the applicable states Surplus Line Fees are charged on all insurance transactions. Embroker does not charge our clients any fees."
                        data-e2e="crime-fees"
                    >
                        {!shouldHidePremium ? <MoneyDisplay value={fees} /> : null}
                    </InvoiceTable.Item>
                </InvoiceTable.Section>
            )}
            <InvoiceTable.Section>
                {isBroker && (
                    <InvoiceTable.Item title="Embroker Access Fee" data-e2e="access-fee">
                        {!shouldHidePremium ? (
                            <MoneyDisplay value={quote.details.policyAdministrationFee} />
                        ) : null}
                    </InvoiceTable.Item>
                )}
                {!isBroker && (
                    <InvoiceTable.Total data-e2e="total-amount" title="Total: ">
                        {!shouldHidePremium ? (
                            <MoneyDisplay value={quote.details.totalPremium} />
                        ) : null}
                    </InvoiceTable.Total>
                )}
            </InvoiceTable.Section>
        </InvoiceTable>
    );
}
