import { API } from '@embroker/shotwell-api/app';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { NotificationRepository } from './index';

@injectable()
export class APINotificationRepository implements NotificationRepository {
    public async pendingNotificationsCount(): AsyncResult<
        number,
        OperationFailed | InvalidArgument
    > {
        const notificationsResult = await API.request('notifications/num_unread');
        if (isErr(notificationsResult)) {
            return handleOperationFailure(notificationsResult);
        }

        return Success(notificationsResult.value);
    }
}
