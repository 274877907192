import { TextButton } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { DocumentType } from '../../types/Document';
import { MenuItemComponentProp } from './QuoteLandingPage';

type DownloadDocumentButtonProps<T extends FormData> = MenuItemComponentProp<T>;

export function DownloadDocumentButton<T extends FormData>({
    documentType,
    onGenerateFileClick,
    fileUrl,
    isSubmitting,
    isDisabled,
}: DownloadDocumentButtonProps<T>) {
    if (fileUrl) {
        return (
            <TextButton
                as="a"
                disabled={isDisabled || isSubmitting}
                icon="download"
                href={fileUrl}
                target="_blank"
                rel="noopener"
                data-e2e="download-document"
            >
                Download {DocumentType.toDisplayName(documentType)}
            </TextButton>
        );
    }
    return (
        <TextButton
            as="button"
            disabled={isDisabled || isSubmitting}
            icon="download"
            onClick={onGenerateFileClick}
            data-e2e="generate-document"
        >
            Generate {DocumentType.toDisplayName(documentType)}
        </TextButton>
    );
}
