import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { PhoneNumber } from '@embroker/shotwell/core/types/PhoneNumber';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import { Button, Form, FormLayout, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React, { useEffect, useState } from 'react';
import { ContactUsForm, TimeToReach, TimeToReachPeriods } from '../../types/ContactForm';
import { ContactUs } from '../../useCases/ContactUs';
import { ContactUsPublic } from '../../useCases/ContactUsPublic';
import { SupportPageLayout } from './SupportPageLayout.view';

export const ContactReasonEmbrokerPlatform = 'The Embroker platform';
export const ContactReasonPayments = 'Payments';
export const ContactReasonRenewal = 'Renewal';
export const ContactReasonInsurance = 'Insurance';
export const ContactReasonForgotEmail = 'Forgot email';
export const ContactReasonSomethingElse = 'Something else';
export const ContactReasonClaims = 'Claims';

export const contactReasonList = [
    ContactReasonEmbrokerPlatform,
    ContactReasonPayments,
    ContactReasonInsurance,
    ContactReasonRenewal,
    ContactReasonForgotEmail,
    ContactReasonSomethingElse,
    ContactReasonClaims,
];
export type ContactReason = (typeof contactReasonList)[number];
const getReasonTitle = (reason: ContactReason) => {
    if (reason !== ContactReasonForgotEmail) {
        return reason;
    }
    return 'I forgot my email address';
};

const BusinessProfileForm = createForm<ContactUsForm>({
    fields: {
        subject: {
            type: 'select',
            validator: Joi.string().required(),
            formatValidationError() {
                return 'You must choose a question';
            },
        },
        name: {
            type: 'text',
            validator: Joi.string().required(),
            formatValidationError() {
                return 'You must enter your company name';
            },
        },
        email: {
            type: 'email',
            validator: EmailAddress.schema.required(),
            formatValidationError() {
                return 'You must enter a valid e-mail';
            },
        },
        phoneNumber: {
            type: 'tel',
            validator: PhoneNumber.schema.optional(),
            formatValidationError() {
                return 'You must enter valid phone number';
            },
        },
        timeToReach: {
            type: 'csv',
            validator: Joi.array().items(Joi.string()).optional(),
            formatValidationError() {
                return 'You must select suitable time';
            },
        },
        message: {
            type: 'textarea',
            validator: Joi.string().required(),
            formatValidationError() {
                return 'Enter the message which you want to send to Embroker';
            },
        },
    },
    submit: async (contactData) => {
        return await execute(
            contactData.subject === 'Forgot email' ? ContactUsPublic : ContactUs,
            contactData,
        );
    },
    formatSubmitErrors(errors) {
        return ['Sorry, data you entered is invalid.'];
    },
});

interface ContactUsPageProp {
    prefilledContactReason: ContactReason;
}

export const ContactUsPage = (props: ContactUsPageProp) => {
    const [statusMessage, setStatusMessage] = useState(false);
    const { submit, status, fields, value, setValue } = useForm(BusinessProfileForm, {
        subject: props.prefilledContactReason || '',
        name: '',
        email: undefined,
        phoneNumber: undefined,
        message: '',
        timeToReach: [],
    });

    useEffect(() => {
        if (status === 'submitted') {
            setStatusMessage(true);
            setValue({
                subject: '',
                name: '',
                email: undefined,
                phoneNumber: undefined,
                message: '',
                timeToReach: [],
            });
        }
    }, [status, setValue, statusMessage]);

    return (
        <SupportPageLayout>
            <Form onSubmit={submit}>
                <FormLayout>
                    <StackLayout gap="24">
                        <Text style="heading 3">
                            Send us a message and we'll get back to you shortly.
                        </Text>
                        <Form.Field
                            inputProps={{
                                items: contactReasonList.map((reason) => ({
                                    value: reason,
                                    title: getReasonTitle(reason),
                                })),
                                placeholder: '',
                                ...fields.subject.props,
                            }}
                            label="I have a question regarding"
                            messages={fields.subject.messages}
                            type="select"
                        />
                        <Form.Field
                            inputProps={{
                                ...fields.name.props,
                            }}
                            label="Name"
                            messages={fields.name.messages}
                            type="text"
                        />
                        <Form.Field
                            inputProps={{
                                autoComplete: 'email',
                                ...fields.email?.props,
                            }}
                            label="Work email address"
                            messages={fields.email?.messages}
                            type="email"
                        />
                        <Form.Field
                            inputProps={{
                                autoComplete: 'tel-national',
                                ...fields.phoneNumber?.props,
                            }}
                            label="Phone number (optional)"
                            messages={fields.phoneNumber?.messages}
                            type="tel"
                        />
                        {value?.phoneNumber ? (
                            <Form.Field
                                type="checkBoxGroup"
                                label="What's the best time to reach you?"
                                messages={fields.timeToReach?.messages}
                                inputProps={{
                                    ...fields.timeToReach.props,
                                    items: [
                                        {
                                            title: TimeToReachPeriods.Morning,
                                            value: TimeToReachPeriods.Morning,
                                        },
                                        {
                                            title: TimeToReachPeriods.Afternoon,
                                            value: TimeToReachPeriods.Afternoon,
                                        },
                                        {
                                            title: TimeToReachPeriods.Evening,
                                            value: TimeToReachPeriods.Evening,
                                        },
                                    ],
                                    onChange: (event) => {
                                        setValue({
                                            ...value,
                                            timeToReach: event.target.value as TimeToReach[],
                                        });
                                    },
                                }}
                            />
                        ) : null}
                        <Form.Field
                            inputProps={{ ...fields.message?.props }}
                            label="Send us a message"
                            type="textarea"
                            messages={fields.message?.messages}
                        />
                        {statusMessage && (
                            <Text color="primary-500">The message was sent successfully</Text>
                        )}
                        <Button type="submit">Send</Button>
                    </StackLayout>
                </FormLayout>
            </Form>
        </SupportPageLayout>
    );
};
