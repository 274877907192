import { StackLayout, Text, ColumnLayout } from '@embroker/ui-toolkit/v2';
import React, { useContext } from 'react';
import { CrimeQuote } from '../../entities/CrimeQuote';
import { CrimeQuoteModalSummary } from './CrimeQuoteModalSummary';
import { USD } from '@embroker/shotwell/core/types/Money';
import { AppContext } from '../../../../view/AppContext';
import { hasRole } from '../../../../userOrg/entities/Session';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { sum } from '../../../../payments/view/components/premiumFinance/QuotesPage';

export function CrimeQuoteSummary({ quote }: { quote: CrimeQuote }) {
    const { activeSession } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');
    const totalPayable = isBroker
        ? sum([quote.details.totalBasePremium, quote.details.policyAdministrationFee ?? USD(0)])
        : quote.details.totalPremium;

    return (
        <StackLayout gap="24">
            <Text>
                You are about to bind a policy
                {quote.userInfo?.company ? ` for ${quote.userInfo.company}` : null}. Please confirm
                that the details listed below are correct:
            </Text>
            <CrimeQuoteModalSummary quote={quote} />
            <ColumnLayout split="-1">
                <Text style="body 1">Total premium:</Text>
                <Text data-e2e="total-premium" style="heading 5">
                    {<MoneyDisplay value={totalPayable} />}
                </Text>
            </ColumnLayout>
        </StackLayout>
    );
}
