import { Data, Immutable } from '@embroker/shotwell/core/types';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { State } from '@embroker/shotwell/core/types/StateList';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { ReactProps, StackLayout } from '@embroker/ui-toolkit/v2';
import React, { useEffect, useState } from 'react';
import { WizardForm } from '../../../../../view/hooks/useWizardForm';
import { GetESPQuestionnaireData } from '../../../../esp/useCases/GetESPQuestionnaireData';
import { ESPRenewalQuote } from '../../../entities/ESPRenewalQuote';
import { Coverage } from '../../../types/Coverage';
import { CoverageRestriction } from '../../../types/CoverageRestriction';
import { InsuranceApplicationRestriction } from '../../../types/InsuranceApplicationRestriction';
import { CoverageType } from '../../config';
import { ESPRenewalQuoteOptionsFormData } from '../ESPRenewalQuoteLandingPage';
import { ESPRenewalRecommendedCoverageList } from './ESPRenewalRecommendedCoverageList';
import { ESPRenewalSelectedCoverageList } from './ESPRenewalSelectedCoverageList';
import { ESPRenewalEndorsementList } from '../ESPRenewalEndorsementList';
import { Endorsement } from '../../../types/EndorsementList';

interface ESPRenewalCoveragePageProps extends ReactProps<'div'> {
    applicationId: UUID;
    quote: ESPRenewalQuote;
    fields: WizardForm<OpaqueForm<ESPRenewalQuoteOptionsFormData>>['fields'];
    isSubmitting: boolean;
    restriction?: Immutable<InsuranceApplicationRestriction>;
    higherLimitRequests?: Immutable<Record<string, number>>;
    showEpliStandaloneInCalifornia?: () => void;
}

export function coverageLimitField(
    coverageType: CoverageType,
    fields: WizardForm<OpaqueForm<ESPRenewalQuoteOptionsFormData>>['fields'],
) {
    switch (coverageType) {
        case 'dno':
            return fields.dnoLimit;
        case 'epli':
            return fields.eplLimit;
        case 'fiduciary':
            return fields.fiduciaryLimit;
        case 'eo':
            return fields.eoLimit;
        case 'techCyber':
            return fields.techLimit;
    }
}

export function coverageRetentionField(
    coverageType: CoverageType,
    fields: WizardForm<OpaqueForm<ESPRenewalQuoteOptionsFormData>>['fields'],
) {
    switch (coverageType) {
        case 'dno':
            return fields.dnoRetention;
        case 'epli':
            return fields.eplRetention;
        case 'fiduciary':
            return fields.fiduciaryRetention;
        case 'eo':
            return fields.eoRetention;
        case 'techCyber':
            return fields.techRetention;
    }
}

export function coverageLevelField(
    coverageType: CoverageType,
    fields: WizardForm<OpaqueForm<ESPRenewalQuoteOptionsFormData>>['fields'],
) {
    switch (coverageType) {
        case 'dno':
            return fields.dnoLevel;
        case 'epli':
            return fields.eplLevel;
        case 'fiduciary':
            return undefined;
        case 'eo':
            return fields.eoLevel;
        case 'techCyber':
            return fields.techLevel;
    }
}

export function coverageIsSelectedField(
    coverageType: CoverageType,
    fields: WizardForm<OpaqueForm<ESPRenewalQuoteOptionsFormData>>['fields'],
) {
    switch (coverageType) {
        case 'dno':
            return fields.isDnoSelected;
        case 'epli':
            return fields.isEplSelected;
        case 'fiduciary':
            return fields.isFiduciarySelected;
        case 'eo':
            return fields.isEOSelected;
        case 'techCyber':
            return fields.isTechSelected;
    }
}

function hasRecommendationSection(
    fields: WizardForm<OpaqueForm<ESPRenewalQuoteOptionsFormData>>['fields'],
    coverageDictionary: Data<Coverage>,
) {
    const isDoRecommended =
        (coverageDictionary['dno'] && fields.isDnoSelected.props.value == false) ?? false;
    const isEpliRecommended =
        (coverageDictionary['epli'] && fields.isEplSelected.props.value == false) ?? false;
    const isFiduciaryRecommended =
        (coverageDictionary['fiduciary'] && fields.isFiduciarySelected.props.value == false) ??
        false;
    const isEoRecommended =
        (coverageDictionary['eo'] && fields.isEOSelected.props.value == false) ?? false;
    const isTechCyberRecommended =
        (coverageDictionary['techCyber'] && fields.isTechSelected.props.value == false) ?? false;

    const hasRecommendedCoverages =
        isDoRecommended ||
        isEpliRecommended ||
        isFiduciaryRecommended ||
        isEoRecommended ||
        isTechCyberRecommended;

    return hasRecommendedCoverages;
}

export function getCoverageRestriction(
    coverageType: CoverageType,
    restriction: Immutable<InsuranceApplicationRestriction>,
): CoverageRestriction | undefined {
    const coverageRestriction = restriction.coverageRestrictions.find((cr) => {
        switch (coverageType) {
            case 'dno':
                return cr.coverageType === 'ShoppingCoverageCodeListDirectorsAndOfficers';
            case 'epli':
                return cr.coverageType === 'ShoppingCoverageCodeListEmploymentPractices';
            case 'fiduciary':
                return cr.coverageType === 'ShoppingCoverageCodeListFiduciary';
            case 'eo':
                return cr.coverageType === 'ShoppingCoverageCodeListCyber';
            case 'techCyber':
                return cr.coverageType === 'ShoppingCoverageCodeListTechSplit';
            default:
                return false;
        }
    });

    return coverageRestriction;
}

export function isCoverageRestricted(
    coverageType: CoverageType,
    restriction?: Immutable<InsuranceApplicationRestriction>,
): boolean {
    if (!restriction) {
        return false;
    }

    const coverageRestriction = getCoverageRestriction(coverageType, restriction);

    if (!coverageRestriction) {
        return false;
    }

    return !coverageRestriction.allowCoverage;
}

export interface QuestionnaireData {
    state: State;
    numberOfEmployees: number;
    zip: string;
    coveragesWithClaims?: string[];
}

export function ESPRenewalCoveragePage({
    applicationId,
    quote,
    fields,
    isSubmitting,
    className,
    restriction,
    higherLimitRequests,
    showEpliStandaloneInCalifornia,
}: ESPRenewalCoveragePageProps) {
    const { result: questionnaireData } = useUseCase(GetESPQuestionnaireData, {
        applicationId,
    });
    const [questionnaire, setQuestionnaire] = useState<QuestionnaireData>();
    const isQuoteReferred = quote?.status === 'referred';

    useEffect(() => {
        if (questionnaireData !== undefined && isOK(questionnaireData)) {
            setQuestionnaire(questionnaireData.value as QuestionnaireData);
        }
    }, [questionnaireData, setQuestionnaire]);

    return (
        <StackLayout gap="32" className={className}>
            <ESPRenewalSelectedCoverageList
                coverageDictionary={quote.options.coverage}
                revenue={quote.revenue ?? 0}
                isSubmitting={isSubmitting}
                fields={fields}
                answers={questionnaire}
                restriction={restriction}
                newInsurerDocumentsReleaseDate={quote.newInsurerDocumentsReleaseDate}
                fiduciaryDocumentsReleaseDate={quote.fiduciaryDocumentReleaseDate}
                submittedAt={quote.submittedAt}
                state={quote.userInfo?.usaState}
                isQuoteReferred={isQuoteReferred}
                higherLimitRequests={higherLimitRequests}
                effectiveDate={quote.options.effectiveDate}
                showEpliStandaloneInCalifornia={showEpliStandaloneInCalifornia}
            />
            {hasRecommendationSection(fields, quote.options.coverage) && (
                <ESPRenewalRecommendedCoverageList
                    coverageDictionary={quote.options.coverage}
                    revenue={quote.revenue ?? 0}
                    isSubmitting={isSubmitting}
                    fields={fields}
                    restriction={restriction}
                    newInsurerDocumentsReleaseDate={quote.newInsurerDocumentsReleaseDate}
                    fiduciaryDocumentsReleaseDate={quote.fiduciaryDocumentReleaseDate}
                    submittedAt={quote.submittedAt}
                    state={quote.userInfo?.usaState}
                    isQuoteReferred={isQuoteReferred}
                    effectiveDate={quote.options.effectiveDate}
                />
            )}
            {restriction?.endorsementList && restriction.endorsementList.length > 0 && (
                <ESPRenewalEndorsementList
                    quoteOptions={quote.options}
                    endorsements={restriction.endorsementList as Endorsement[]}
                />
            )}
        </StackLayout>
    );
}
