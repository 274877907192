import { UUID } from '@embroker/shotwell/core/types/UUID';
import {
    BoxLayout,
    CardLayout,
    CenterLayout,
    ColumnLayout,
    Filter,
    FilterOption,
    PageLayout,
    Spinner,
    StackLayout,
    Table,
    Text,
    TextButton,
    useAsyncTable,
} from '@embroker/ui-toolkit/v2';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../../view/AppContext';
import { Link } from '../../../../view/components/Link/Link';
import { RenewalTableColumn } from '../../../useCases/GetBrokerRenewals';
import { BrokerFilter, Filters } from '../BrokerFilter';
import { BrokerTableHeader } from '../BrokerTableHeader';
import { BrokerRenewalsTableData, getRenewalsPageData } from './BrokerRenewalsTableData';

type BrokerRenewalStatus = 'not_submitted' | 'quotes_ready' | 'referred' | 'declined' | 'expired';

interface BrokerRenewalsProps {
    itemsPerPage?: number;
}

export function BrokerRenewals({ itemsPerPage = 10 }: BrokerRenewalsProps) {
    const { activeSession } = useContext(AppContext);

    const initialFilters = [];
    const [selectedBroker, setSelectedBroker] = useState<UUID | 'all'>(
        activeSession.userId ?? 'all',
    );
    if (activeSession.userId !== null) {
        initialFilters.push({ target: 'managing_broker', value: activeSession.userId });
    }

    const [selectedStatusFilters, setSelectedStatusFilters] = useState<BrokerRenewalStatus[]>([]);

    const onStatusFilterChange = (event: {
        readonly target: { readonly value: BrokerRenewalStatus[] };
    }) => {
        setSelectedStatusFilters(event.target.value);

        const statusFilterInfo = event.target.value.map(mapStatusFilterToSortInfo);
        const brokerFilter =
            selectedBroker === 'all' ? [] : [{ target: 'managing_broker', value: selectedBroker }];

        filterBy([...brokerFilter, ...statusFilterInfo]);
    };

    const filterByBroker = (filters: Filters) => {
        const statusFilterInfo = selectedStatusFilters.map(mapStatusFilterToSortInfo);
        const brokerFilters = filters ?? [];
        filterBy([...brokerFilters, ...statusFilterInfo]);
    };

    const { visibleItems, isLoading, sortedBy, sortBy, filterBy, pagination } =
        useAsyncTable<BrokerRenewalsTableData>({
            fetchPageData: getRenewalsPageData,
            itemsPerPage,
            initialSortBy: { column: 'date_started', order: 'ASC' },
            initialFilters,
            prefetch: true,
        });
    const noItems = visibleItems.length === 0;
    const areFiltersApplied =
        selectedBroker !== activeSession.userId || selectedStatusFilters.length > 0;

    function handleSortBy(column: RenewalTableColumn, initialOrder?: 'ASC' | 'DESC') {
        return (event: React.MouseEvent) => {
            event.preventDefault();
            sortBy(
                column,
                sortedBy?.column !== column
                    ? initialOrder ?? 'ASC'
                    : sortedBy?.order === 'ASC'
                    ? 'DESC'
                    : 'ASC',
            );
        };
    }

    return (
        <PageLayout.Section>
            <StackLayout>
                <TextButton
                    size="small"
                    as={Link}
                    icon="bold-caret-left"
                    iconPosition="left"
                    href="/broker/dashboard"
                >
                    Back to dashboard
                </TextButton>
                {isLoading ? (
                    <Spinner />
                ) : (
                    <CardLayout>
                        <CardLayout.Header>
                            <BrokerTableHeader
                                noItems={noItems}
                                pagination={pagination}
                                title="Upcoming Renewals"
                            />
                        </CardLayout.Header>
                        <CardLayout.Body>
                            <StackLayout gap={noItems ? '24' : 'none'}>
                                <ColumnLayout>
                                    <BrokerFilter
                                        selectedBroker={selectedBroker}
                                        onBrokerSelect={setSelectedBroker}
                                        filterBy={filterByBroker}
                                    />
                                    <Filter
                                        className="access_renewals_status_filter"
                                        title="Filter by status"
                                        options={StatusFilters}
                                        value={selectedStatusFilters}
                                        onChange={onStatusFilterChange}
                                        multiple
                                    />
                                </ColumnLayout>
                                {!noItems ? (
                                    <Table>
                                        <Table.Header>
                                            <Table.Column
                                                isSortable
                                                isSortedBy={
                                                    sortedBy?.column === 'organization_name'
                                                }
                                                sortDirection={sortedBy?.order}
                                                onClick={handleSortBy('organization_name')}
                                            >
                                                Company
                                            </Table.Column>
                                            <Table.Column
                                                isSortable
                                                isSortedBy={sortedBy?.column === 'managing_broker'}
                                                sortDirection={sortedBy?.order}
                                                onClick={handleSortBy('managing_broker')}
                                                width="1/5"
                                            >
                                                Broker
                                            </Table.Column>
                                            <Table.Column
                                                isSortable
                                                isSortedBy={sortedBy?.column === 'status'}
                                                sortDirection={sortedBy?.order}
                                                onClick={handleSortBy('status')}
                                                width="1/8"
                                            >
                                                Status
                                            </Table.Column>
                                            <Table.Column
                                                isSortable
                                                isSortedBy={sortedBy?.column === 'product_type'}
                                                sortDirection={sortedBy?.order}
                                                onClick={handleSortBy('product_type')}
                                                width="1/8"
                                            >
                                                Policy
                                            </Table.Column>
                                            <Table.Column
                                                isSortable
                                                isSortedBy={sortedBy?.column === 'expiry_date'}
                                                sortDirection={sortedBy?.order}
                                                onClick={handleSortBy('expiry_date')}
                                                width="1/8"
                                            >
                                                Expires in
                                            </Table.Column>
                                            <Table.Column
                                                isSortable
                                                isSortedBy={sortedBy?.column === 'previous_premium'}
                                                sortDirection={sortedBy?.order}
                                                onClick={handleSortBy('previous_premium', 'DESC')}
                                                width="1/10"
                                            >
                                                Prev. Premium
                                            </Table.Column>
                                            <Table.Column width="1/8">Action</Table.Column>
                                        </Table.Header>
                                        <Table.Body>
                                            {visibleItems.map((row) => (
                                                <Table.Row
                                                    key={row.id as UUID}
                                                    data-e2e="renewal-table"
                                                >
                                                    <Table.Cell
                                                        truncate
                                                        data-e2e="renewal-company-name"
                                                    >
                                                        {row.companyName}
                                                    </Table.Cell>
                                                    <Table.Cell
                                                        truncate
                                                        data-e2e="renewal-managing-broker"
                                                    >
                                                        {row.managingBroker}
                                                    </Table.Cell>
                                                    <Table.Cell data-e2e="renewal-status">
                                                        {row.renewalStatus}
                                                    </Table.Cell>
                                                    <Table.Cell data-e2e="renewal-policy-type">
                                                        {row.policyType}
                                                    </Table.Cell>
                                                    <Table.Cell data-e2e="renewal-exp-date">
                                                        {row.daysToPreviousPolicyExpiry}
                                                    </Table.Cell>
                                                    <Table.Cell data-e2e="renewal-previous-premium">
                                                        {row.previousPolicyPremium}
                                                    </Table.Cell>
                                                    <Table.Cell data-e2e="renewal-action">
                                                        {row.action}
                                                    </Table.Cell>
                                                </Table.Row>
                                            ))}
                                        </Table.Body>
                                    </Table>
                                ) : (
                                    <BoxLayout gap="24">
                                        <CenterLayout centerText>
                                            <Text style="label 1">
                                                {areFiltersApplied
                                                    ? 'No records match applied filter criteria.'
                                                    : 'You have no upcoming renewals.'}
                                            </Text>
                                        </CenterLayout>
                                    </BoxLayout>
                                )}
                            </StackLayout>
                        </CardLayout.Body>
                    </CardLayout>
                )}
            </StackLayout>
        </PageLayout.Section>
    );
}

const StatusFilters: FilterOption<BrokerRenewalStatus>[] = [
    {
        value: 'not_submitted',
        title: 'Not Submitted',
    },
    {
        value: 'quotes_ready',
        title: 'Quotes Ready',
    },
    {
        value: 'referred',
        title: 'Referred',
    },
    {
        value: 'declined',
        title: 'Declined',
    },
    {
        value: 'expired',
        title: 'Expired',
    },
];

function mapStatusFilterToSortInfo(filter: BrokerRenewalStatus) {
    return { target: 'status', value: filter };
}
