import { Money } from '@embroker/shotwell/core/types/Money';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { ColumnLayout, InsetBox, ScrollBox, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { CyberQuote } from '../../entities/CyberQuote';

export function CyberQuoteSummary({ quote }: { quote: CyberQuote }) {
    return (
        <StackLayout gap="24">
            <Text>
                You are about to bind a policy
                {quote.userInfo?.company ? ` for ${quote.userInfo.company}` : null}. Please confirm
                that the details listed below are correct:
            </Text>
            <ColumnLayout gap="8">
                <Text>Effective date: </Text>{' '}
                <Text style="heading 5" data-e2e="effective-date">
                    <DateDisplay value={quote.options.effectiveDate} />
                </Text>
            </ColumnLayout>
            <InsetBox>
                <ScrollBox>
                    <StackLayout gap="24">
                        <Text style="heading 5" data-e2e="coverage-type-title">
                            Cyber Liability
                            {quote.options.level === 'plus' ? ' (Plus)' : ' (Standard)'}
                        </Text>
                        <ColumnLayout gap="24">
                            <Text>
                                <Text style="overline">LIMIT</Text>{' '}
                                <MoneyDisplay
                                    value={Money.tryFromFloat(quote.options.limit)}
                                    fractionDigits={0}
                                />
                            </Text>
                            <Text>
                                <Text style="overline">RETENTION</Text>{' '}
                                <MoneyDisplay
                                    value={Money.tryFromFloat(quote.options.retention)}
                                    fractionDigits={0}
                                />
                            </Text>
                        </ColumnLayout>
                    </StackLayout>
                </ScrollBox>
            </InsetBox>
            <ColumnLayout split="-1">
                <Text style="body 1">Total premium:</Text>
                <Text style="heading 5">{<MoneyDisplay value={quote.details.totalPremium} />}</Text>
            </ColumnLayout>
        </StackLayout>
    );
}
