import { inject } from '@embroker/shotwell/core/di';
import { Aborted, InvalidArgument, OperationFailed, Timeout } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { CalculateSKU } from '../../../analytics/useCases/CalculateSKU';
import { ApplicationRepository } from '../../../shopping/repositories/ApplicationRepository';
import { TasksRepository } from '../../../tasks/repositories';
import { ExcessQuote } from '../entities/ExcessQuote';
import { ExcessQuoteRepository } from '../repositories/EmbrokerExcessQuoteRepository';
import { ExcessQuoteOptions } from '../types/ExcessQuoteOptions';

export interface ReQuoteExcessRequest {
    applicationId: UUID;
    excessQuoteOptions: ExcessQuoteOptions;
    abortSignal: AbortSignal;
}

export interface ReQuoteExcess extends UseCase {
    execute(
        request: ReQuoteExcessRequest,
    ): AsyncResult<ExcessQuote, InvalidArgument | OperationFailed | Timeout | Aborted>;
}

class ReQuoteExcessUseCase extends UseCase implements ReQuoteExcess {
    public static type = Symbol('ExcessQuote/ReQuoteExcess');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ExcessQuoteRepository) private excessQuoteRepository: ExcessQuoteRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
        @inject(CalculateSKU.type) private calculateSKU: CalculateSKU,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
        excessQuoteOptions,
        abortSignal,
    }: ReQuoteExcessRequest): AsyncResult<
        ExcessQuote,
        InvalidArgument | OperationFailed | Timeout | Aborted
    > {
        const reQuoteResult = await this.excessQuoteRepository.reQuoteExcess(
            applicationId,
            excessQuoteOptions,
        );
        if (isErr(reQuoteResult)) {
            return reQuoteResult;
        }

        const pollForTaskStatusResult = await this.tasksRepository.pollForTaskStatus(
            reQuoteResult.value,
            abortSignal,
        );
        if (isErr(pollForTaskStatusResult)) {
            return pollForTaskStatusResult;
        }

        const getCurrentQuoteResult = await this.excessQuoteRepository.getLastExcessQuote(
            applicationId,
        );
        if (isErr(getCurrentQuoteResult)) {
            return getCurrentQuoteResult;
        }

        const getApplicationResult = await this.applicationRepository.getApplication(applicationId);
        if (isErr(getApplicationResult)) {
            return getApplicationResult;
        }

        const skuResult = await this.calculateSKU.execute({
            event: 'quote',
            applicationId,
        });
        if (isErr(skuResult)) {
            return skuResult;
        }

        const application = getApplicationResult.value;
        const quote = getCurrentQuoteResult.value;

        application.onQuotePremium({
            totalPremium: quote.totalPayable,
            applicationId: application.id,
            isRenewal: application.isRenewal(),
            sku: skuResult.value,
        });

        await this.eventBus.publishEntityEvents(application);

        return Success(quote);
    }
}

export const ReQuoteExcess: UseCaseClass<ReQuoteExcess> = ReQuoteExcessUseCase;
