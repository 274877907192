import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface PaymentsConfig {
    readonly useAscend: boolean;
}

export const PaymentsConfig = {
    ...defineValidator<PaymentsConfig>({
        useAscend: Joi.boolean(),
    }),

    create(config: PaymentsConfig) {
        return PaymentsConfig.validate(config);
    },
};
