import { hasOwnProp, isObject } from '@embroker/shotwell/core/object';
import { URI } from '@embroker/shotwell/core/types/URI';

export const redirectToLegacy = (uri: string) => {
    if (
        hasOwnProp(window, 'EMBROKER_ENV', isObject) &&
        hasOwnProp(window.EMBROKER_ENV, 'EMBROKER_APP_URL') &&
        typeof window.EMBROKER_ENV.EMBROKER_APP_URL === 'string'
    ) {
        if (!uri.includes('/admin')) {
            const legacyURI = uri.includes('/app/') ? uri.replace('/app/', '') : uri;
            location.href =
                URI.join(window.EMBROKER_ENV.EMBROKER_APP_URL, legacyURI) + location.search;
        } else {
            if (typeof window.EMBROKER_ENV.EMBROKER_ADMIN_URL === 'string') {
                const legacyURI = uri.includes('/admin') ? uri.replace('/admin', '') : uri;
                location.href =
                    URI.join(window.EMBROKER_ENV.EMBROKER_ADMIN_URL, legacyURI) + location.search;
            }
        }
    }
};
