import { StackLayout, InsetBox, ComparisonTable, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';

export const CrimeDifferenceModalContent = React.memo(function CrimeDifferenceModalContent() {
    return (
        <StackLayout>
            <Text style="heading 3">What is “Commercial Crime Plus” Coverage?</Text>
            <Text>Cover an even broader range of risk with best-in-market coverage.</Text>
            <InsetBox>
                <ComparisonTable
                    comparisonData={[
                        {
                            topic: (
                                <React.Fragment>
                                    <Text style="heading 4">
                                        Broader Coverage for Employee Theft of Insured Property:
                                        Unidentified Employee
                                    </Text>
                                    <Text style="body 2">
                                        Our Standard policy requires the specific employee that
                                        committed the theft to be identified. Our Plus policy
                                        provides coverage even if an unidentified employee committed
                                        the act.
                                    </Text>
                                </React.Fragment>
                            ),
                            plus: true,
                            standard: false,
                        },
                        {
                            topic: (
                                <React.Fragment>
                                    <Text style="heading 4">
                                        Broader Coverage for Employee Theft of Client Property:
                                        Collusion with Others
                                    </Text>
                                    <Text style="body 2">
                                        Both our Standard and Plus policies provide coverage for
                                        employee theft of client’s money; securities and other
                                        properties (certain tangible property, as described in the
                                        policy). However, our Standard policy does not provide
                                        coverage if your employee colludes with your client’s
                                        employees whereas our Plus policy does provide coverage if
                                        your employee colludes with others.
                                    </Text>
                                </React.Fragment>
                            ),
                            plus: true,
                            standard: false,
                        },
                        {
                            topic: (
                                <React.Fragment>
                                    <Text style="heading 4">
                                        Broader Coverage for Property Outside of Premises: Not
                                        Limited to Property In-Transit & Additional Authorized
                                        Custodians
                                    </Text>
                                    <Text style="body 2">
                                        Our Plus policy is not limited to covered property
                                        in-transit from point A to B. Additionally, the Plus policy
                                        provides coverage when such property is in the temporary
                                        care and custody of a relative of an executive if such
                                        relative is duly authorized.
                                    </Text>
                                </React.Fragment>
                            ),
                            plus: true,
                            standard: false,
                        },
                        {
                            topic: (
                                <React.Fragment>
                                    <Text style="heading 4">
                                        Higher Limit for Investigation Costs: $250,000 vs. $50,000
                                    </Text>
                                    <Text style="body 2">
                                        Our Plus policy provides $250,000 in investigation costs
                                        whereas the Standard policy provides $50,000. Investigation
                                        costs are defined as the reasonable and necessary costs
                                        incurred by the Insured in excess of the Insured
                                        Organization’s normal operating costs (with the prior
                                        written approval) to establish the existence, amount, and
                                        preparation of the Insured’s proof of loss in support of a
                                        covered loss.
                                    </Text>
                                </React.Fragment>
                            ),
                            plus: true,
                            standard: false,
                        },
                        {
                            topic: (
                                <React.Fragment>
                                    <Text style="heading 4">
                                        Forensics Costs of Investigating the Theft of Source Code:
                                        $25,000 with $5,000 Retention
                                    </Text>
                                    <Text style="body 2">
                                        Crime policies do not provide coverage for the loss of
                                        intellectual property. However, our Plus policy is one of
                                        the rare policies—if not the only crime policy—that
                                        reimburses you for some of the forensics costs of
                                        investigating the theft of source code by an employee.
                                    </Text>
                                </React.Fragment>
                            ),
                            plus: true,
                            standard: false,
                        },
                        {
                            topic: (
                                <React.Fragment>
                                    <Text style="heading 4">
                                        Cost of Employee Counseling After a Robbery or Attempted
                                        Robbery: $5,000 with $1,000 Retention
                                    </Text>
                                    <Text style="body 2">
                                        Our Plus policy provides added coverage for the cost of
                                        employee counseling after a robbery or attempted robbery.
                                    </Text>
                                </React.Fragment>
                            ),
                            plus: true,
                            standard: false,
                        },
                        {
                            topic: (
                                <React.Fragment>
                                    <Text style="heading 4">
                                        Employee Theft of Your Property: Coverage for Up to 120 Days
                                        After the Employee’s Termination
                                    </Text>
                                    <Text style="body 2">
                                        When it comes to employee theft, our Standard policy
                                        excludes losses sustained more than sixty (60) days after
                                        such employee’s termination. Our Plus policy provides an
                                        additional sixty (60)-day buffer so that it only excludes
                                        loss sustained, one hundred twenty (120) days after such
                                        employee’s termination.
                                    </Text>
                                </React.Fragment>
                            ),
                            plus: true,
                            standard: false,
                        },
                        {
                            topic: (
                                <React.Fragment>
                                    <Text style="heading 4">Relaxed Post-Loss Requirements</Text>
                                    <Text style="body 2">
                                        When a loss is discovered, our Standard policy requires
                                        notice as soon as practicable, but no later than sixty (60)
                                        days after discovery. Additionally, a detailed, sworn proof
                                        of loss, with full particulars must be submitted to the
                                        Insurer no later than one hundred twenty (120) days after
                                        discovery of loss.
                                    </Text>
                                    <Text style="body 2">
                                        In contrast, our Plus policy requires notice as soon as
                                        practicable, but no later than ninety (90) days after
                                        discovery. Additionally, the Plus policy relaxes the time
                                        limit for submitting the detailed, sworn proof of loss,
                                        requiring it to be submitted to the Insurer no later than
                                        one hundred fifty (150) days after discovery of loss.
                                    </Text>
                                </React.Fragment>
                            ),
                            plus: true,
                            standard: false,
                        },
                    ]}
                />
            </InsetBox>
        </StackLayout>
    );
});
