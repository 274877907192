import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

/**
 * SKU interface params have snake_case because of the Segment requirements
 **/
export interface SKU {
    readonly sku_string: string;
    readonly sku_string_plaintext: string;
    readonly product_skus?: readonly string[];
    readonly product_skus_plaintext?: readonly string[];
}

/**
 * SKU is a Stock Keeping Unit short name which is used strictly for analytic purposes
 * in identifying our products on the platform
 **/
export const SKU = {
    ...defineValidator<SKU>({
        sku_string: Joi.string(),
        sku_string_plaintext: Joi.string(),
        product_skus: Joi.array().optional().items(Joi.string().required()),
        product_skus_plaintext: Joi.array().optional().items(Joi.string().required()),
    }),
};
