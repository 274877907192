import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { InvoiceTable } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { AppTypeLabelMap } from '../../../../shopping/types/enums';
import { WCGAQuote } from '../../entities/WCGAQuote';

export interface WCGAQuoteBreakdownProps {
    quote: WCGAQuote;
}

export function WCGAEstimateBreakdown({ quote }: WCGAQuoteBreakdownProps) {
    return (
        <InvoiceTable>
            <InvoiceTable.Section>
                <InvoiceTable.Subtotal data-e2e="selected-coverages-list" title="Coverages" />
                <InvoiceTable.Item title={AppTypeLabelMap['AppTypeCodeListGAWorkersCompensation']}>
                    <MoneyDisplay value={quote.totalPremium} />
                </InvoiceTable.Item>
            </InvoiceTable.Section>
            <InvoiceTable.Section>
                <InvoiceTable.Total title="Estimated annual premium">
                    <MoneyDisplay value={quote.totalPremium} />
                </InvoiceTable.Total>
            </InvoiceTable.Section>
        </InvoiceTable>
    );
}
