import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export type CrimeLimit = 500000 | 1000000 | 2000000 | 3000000 | 4000000 | 5000000;
export type CrimeRetention = 2500 | 5000 | 10000 | 25000 | 50000 | 100000;

export interface CrimeQuoteOptions {
    readonly effectiveDate: Date;
    readonly limit: CrimeLimit;
    readonly previousLimit?: CrimeLimit;
    readonly retention: CrimeRetention;
    readonly previousRetention?: CrimeRetention;
    readonly enhanced: boolean;
}

export const CrimeQuoteOptions = {
    ...defineValidator<CrimeQuoteOptions>({
        effectiveDate: Joi.date().required(),
        limit: Joi.number().required(),
        previousLimit: Joi.number().optional(),
        retention: Joi.number().required(),
        previousRetention: Joi.number().optional(),
        enhanced: Joi.boolean().required(),
    }),
    create(crimeQuoteOptions: CrimeQuoteOptions) {
        return CrimeQuoteOptions.validate(crimeQuoteOptions);
    },
};
