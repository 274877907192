import { ContainerModule } from '@embroker/shotwell/core/di';
import { Module } from '../Module';
import { TasksRepository } from './repositories';
import { APITasksRepository } from './repositories/APITasksRepository';

export const TasksModule: Module = {
    container: new ContainerModule((bind) => {
        bind<TasksRepository>(TasksRepository).to(APITasksRepository).inSingletonScope();
    }),
};
