import { API, ReflectEnumsResponse } from '@embroker/shotwell-api/app';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { ServerEnum } from '../../types/ServerEnum';
import { ServerEnumGroup } from '../../types/ServerEnumGroup';
import { ServerEnumsRepository } from './index';

@injectable()
export class APIServerEnumsRepository implements ServerEnumsRepository {
    private cachedEnums: Immutable<ServerEnumGroup> = new Map<
        string,
        ServerEnum[]
    >() as ServerEnumGroup;

    public async getEnums(): AsyncResult<ServerEnumGroup, InvalidArgument | OperationFailed> {
        if (this.cachedEnums.size === 0) {
            const enumGroupsResultAPI = await API.request('reflect/enums', {});

            if (isErr(enumGroupsResultAPI)) {
                return handleOperationFailure(enumGroupsResultAPI);
            }

            const enumGroupsAPI = enumGroupsResultAPI.value as ReflectEnumsResponse;

            for (const enumsGroupAPI of enumGroupsAPI.enum_list) {
                const serverEnums: Immutable<ServerEnum>[] = [];

                for (const enumsAPI of enumsGroupAPI.values) {
                    serverEnums.push({
                        name: enumsAPI,
                        value: enumsGroupAPI.name + enumsAPI.replace(/\W/g, ''),
                    });
                }

                this.cachedEnums.set(enumsGroupAPI.name, serverEnums);
            }
        }

        return Success(this.cachedEnums);
    }
}
