import React from 'react';
import { StackLayout, Text, InsetBox } from '@embroker/ui-toolkit/v2';

export const AgreementToConductModalContent = () => {
    return (
        <StackLayout gap="24">
            <InsetBox>
                <Text style="heading 3">AGREEMENT TO CONDUCT ELECTRONIC TRANSACTIONS</Text>
                <Text as="p" style="label 1">
                    DISCLOSURES AND CONSENT TO CONDUCT BUSINESS ELECTRONICALLY
                </Text>

                <Text as="p">
                    We are required by law to provide certain disclosures to you before you enter
                    into this transaction electronically. In addition, we need your consent to enter
                    into this transaction before we can deliver certain documents, including your
                    current and future insurance related documents, to you electronically. Please
                    read this notice carefully before giving consent.
                </Text>
                <Text as="p">
                    By accepting these terms and conditions you agree that Embroker may discontinue
                    sending paper documents and provide insurance related documents to you
                    electronically. By accepting these terms and conditions, you also agree to
                    accept the Embroker Services (as defined in the Embroker Terms and Conditions)
                    on an &quot;AS-IS&quot; basis. Embroker does not guarantee that Services will be
                    without defect or flaw and Embroker will not be held responsible for any loss
                    associated with the use of this service.
                </Text>
                <Text as="p">
                    This Disclosure and Consent to Conduct Business Electronically
                    (&quot;Disclosure&quot;) applies to all communications for those policies,
                    contracts, Services and notices offered or accessible through the Embroker
                    Platform. &quot;Embroker Platform&quot; means the website and its associated
                    links, Services, and Communications available at www.embroker.com.
                </Text>
                <Text as="p">
                    The term “Embroker” refers to Embroker, Inc. and Embroker Insurance Services
                    LLC.
                </Text>
                <Text as="p">
                    &quot;Communications&quot; means all the information that Embroker is required
                    to provide to you by law, or as reasonably necessary to administer your Policy
                    (as defined below), which includes, but is not limited to, your online
                    enrollment or application, warranty statement, surplus lines disclosure
                    statement, declarations page, policy, certificate, endorsements, terms and
                    conditions, transaction history, privacy policies, billing statements,
                    amendments, Services, notices and disclosures about changes in the terms of your
                    Policy.
                </Text>
                <Text as="p">
                    &quot;Policy&quot; means a written contract of insurance, or written agreement
                    effecting insurance, or the certificate thereof, and includes all clauses,
                    riders or endorsements, disclosures and declarations page.
                </Text>
                <Text as="p">
                    <strong>1. Scope of Communications to Be Provided in Electronic Form.</strong>
                </Text>
                <Text as="p">
                    You agree that Embroker may provide you with any Communication in electronic
                    format (the &quot;Paperless Transaction&quot;), and that we may discontinue
                    sending paper Communications to you, unless and until you withdraw your consent
                    as described below or at our option upon notification by Embroker to you. You
                    further acknowledge that we may authorize an agent or broker to deliver certain
                    Communications to you on our behalf, and perform other Services to help
                    facilitate the delivery of Communications to you.
                </Text>
                <Text as="p">
                    <strong>
                        2. Method of Providing Communications to You in Electronic Form.
                    </strong>
                </Text>
                <Text as="p">
                    All Communications that Embroker provides to you in electronic form will be
                    provided by one or more of the following methods: (1) via e-mail; (2) by access
                    to Embroker’s secure Web site at www.embroker.com; or (3) to the extent
                    permissible by law.
                </Text>
                <Text as="p">
                    <strong>3. How to Withdraw Consent.</strong>
                </Text>
                <Text as="p">
                    You may withdraw your consent to receive Communications that we provide to you
                    in electronic form by calling us at 1-844.436.2765, emailing us at
                    legal@embroker.com, or writing to us at Embroker, 5214F Diamond Heights Blvd.
                    Unit #1261, San Francisco, California 94131. At our option, we may treat your
                    provision of an invalid e-mail address, or the subsequent malfunction of a
                    previously valid e-mail address, as a withdrawal of your consent to receive
                    electronic Communications. We will not impose any fee to process the withdrawal
                    of your consent to receive electronic Communications; however, your access and
                    use of the Embroker Platform and Services may be terminated. Any withdrawal of
                    your consent to receive electronic Communications will be effective only after
                    we have a reasonable period of time to process your withdrawal. Termination of
                    your consent to conduct business electronically shall not affect legal
                    enforceability of any Policy provided to you.
                </Text>
                <Text as="p">
                    <strong>4. How to Update Your Records.</strong>
                </Text>
                <Text as="p">
                    It is your responsibility to provide Embroker with a true, accurate and complete
                    e-mail address, contact, and other information related to this Disclosure and
                    your Policy, and to maintain and update promptly any changes in this
                    information. You can update your information emailing us at support@embroker.com
                    or writing to us at Embroker, 5214F Diamond Heights Blvd. Unit #1261, San
                    Francisco, California 94131. Please do not send confidential information to us
                    via traditional e-mail, as we cannot guarantee that the transmission will be
                    secure.
                </Text>
                <Text as="p">
                    <strong>5. Hardware and Software Requirements.</strong>
                </Text>
                <Text as="p">
                    In order to access, view and retain electronic Communications from us, you must
                    have:
                </Text>
                <ul>
                    <li>An up-to-date Internet browser to access your Communications;</li>
                    <li>
                        Local, electronic storage capacity to retain our Communications and/or a
                        printer to print them;
                    </li>
                    <li>A valid e-mail account and software to access it;</li>
                    <li>
                        An up-to-date device or devices (e.g., computer, Smartphone, tablet, etc)
                        suitable for connecting to the Internet;
                    </li>
                    <li>
                        Added the domain @embroker.com to your e-mail account’s list of &quot;safe
                        senders.&quot;
                    </li>
                    <li>
                        Software that enables you to view files in Portable Document Format
                        (&quot;PDF&quot;). If you cannot download the most recent version of Adobe
                        Reader, please call your manufacturer to find out how to download software
                        that is functionally equivalent.
                    </li>
                </ul>
                <Text as="p">
                    <strong>6. Requesting Paper Copies.</strong>
                </Text>
                <Text as="p">
                    When you consent to receive Communications electronically, you should not expect
                    to receive a paper copy of any Communication, unless you request it or we
                    otherwise deem it appropriate to do so. You can obtain a paper copy of an
                    electronic Communication by printing it yourself or by requesting that we mail
                    you a paper copy, provided that such request is made within a reasonable time
                    after we first provided the electronic Communication to you. To request a paper
                    copy, you may do so by emailing us at support@embroker.com, or writing to us at
                    Embroker, 5214F Diamond Heights Blvd. Unit #1261, San Francisco, California
                    94131. We may charge you a reasonable service fee for a paper copy, of which we
                    will provide you prior notice, for the delivery of paper copies of any
                    Communication provided to you electronically pursuant to this authorization.
                </Text>
                <Text as="p">
                    <strong>7. Communications in Writing.</strong>
                </Text>
                <Text as="p">
                    All Communications in either electronic or paper format from us to you will be
                    considered &quot;in writing.&quot; You should print or download for your records
                    a copy of this Disclosure and any other Communication that is important to you
                    or which we instruct you to download and retain.
                </Text>
                <Text as="p">
                    <strong>8. Federal and State Laws.</strong>
                </Text>
                <Text as="p">
                    You acknowledge and agree that your consent to electronic Communications is
                    being provided in connection with a transaction affecting interstate commerce
                    that is subject to the federal Electronic Signatures in Global and National
                    Commerce Act (the “Act”) and your state’s UETA law. You further agree that you
                    intend that the Act and your state’s UETA law apply to the fullest extent
                    possible to validate our ability to conduct business with you by electronic
                    means.
                </Text>
                <Text as="p">
                    <strong>9. Termination/Changes.</strong>
                </Text>
                <Text as="p">
                    We reserve the right, in our sole discretion, to discontinue the provision of
                    your electronic Communications, or to terminate or change the terms and
                    conditions on which we provide electronic Communication. We will provide you
                    with notice of any such termination or change as required by law.
                </Text>
                <Text as="p">
                    <strong>10. Jurisdiction and Enforceability.</strong>
                </Text>
                <Text as="p">
                    The Disclosure and your relationship with us under this Disclosure shall be
                    governed by the laws of the State of California without regard to its conflict
                    of laws provision. You and Embroker agree to submit to the exclusive
                    jurisdiction of the California to resolve any legal matter arising from the
                    Disclosure. Notwithstanding this, you agree that we shall be allowed to apply
                    for injunctive remedies (or an equivalent type of urgent legal relief) in any
                    jurisdiction.
                </Text>
                <Text as="p">
                    <strong>11. Severability.</strong>
                </Text>
                <Text as="p">
                    If any court of law, having the jurisdiction to decide on this matter, rules
                    that any provision of this Disclosure is invalid, then that provision will be
                    removed from the Disclosure without affecting the rest of the Disclosure. The
                    remaining provisions of the Disclosure will continue to be valid and
                    enforceable.
                </Text>
                <Text as="p">
                    <strong>12. Acceptance and Consent.</strong>
                </Text>
                <Text as="p">
                    By consenting to this Disclosure, you agree to the following statements:
                </Text>
                <Text as="p">
                    I have read, understand and agree to be bound by the terms and conditions
                    described above and consent to receive electronic documents according to the
                    process described above.
                </Text>
            </InsetBox>
        </StackLayout>
    );
};
