import React from 'react';
import { PageNavigationComponentProps } from '../../../view/components/QuoteLandingPage';
import { QuoteOnDemandNavigation } from '../../../view/components/QuoteOnDemandNavigation';
import { ESPQuote } from '../../entities/ESPQuote';
import { ESPQuoteOptionsFormData } from './ESPQuoteLandingPage';
import { withReferredNavigationProps } from '../../../view/components/WithExtraProps';

interface EspReferredNavigationProps
    extends PageNavigationComponentProps<ESPQuoteOptionsFormData, ESPQuote>,
        withReferredNavigationProps {}

export function EspReferredNavigation({
    trigger,
    quote,
    hasNext,
    hasPrevious,
    previous,
    isSubmitting,
    status,
    onReferredCTAButtonClick,
    referredCTAButtonLabel,
    action,
}: EspReferredNavigationProps) {
    return (
        <QuoteOnDemandNavigation
            quote={quote}
            isSubmitting={isSubmitting}
            hasNext={hasNext}
            next={onReferredCTAButtonClick}
            hasPrevious={hasPrevious}
            previous={previous}
            nextButtonLabel={referredCTAButtonLabel}
            disableNextButton={false}
            handlePurchase={() => {
                // do nothing
            }}
            trigger={trigger}
            status={status}
            action={action}
        />
    );
}
