import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { Quote } from '../../entities/Quote';
import { PremiumFinanceGetQuoteIndicationInput } from '../../types/PremiumFinanceIndicationInfo';
import { ConfigFetchFailed, PremiumFinanceRoutingCodeInvalid } from '../../types/errors';
import { GenerateDocumentLinkRequestData } from '../../useCases/GenerateDocumentLink';
import { PaymentsConfig } from '../../types/PaymentsConfig';

export interface QuoteOptions {
    quote3Months: Quote;
    quote10Months: Quote;
}

export interface PremiumFinanceGetTasksInput {
    readonly invoiceIds: UUID[];
}

export const PremiumFinanceGetTasksInput = {
    ...defineValidator<PremiumFinanceGetTasksInput>({
        invoiceIds: Joi.array().items(UUID.schema).min(1),
    }),
    create(invoiceIds: UUID[]) {
        return PremiumFinanceGetTasksInput.validate({ invoiceIds });
    },
};

export interface PremiumFinanceGetQuoteInput {
    taskMonthly: UUID;
    taskQuarterly: UUID;
}

type FifBillingMethod = 'ACH' | 'Card' | 'Invoice';

export interface PremiumFinanceQuotePaymentInput {
    readonly invoiceIds: UUID[];
    readonly organizationId: UUID;
    readonly quoteNumber: number;
    readonly signedDateTimeTermsAndConditions?: Date;
    readonly billingMethod: FifBillingMethod;
}

export const PremiumFinanceQuotePaymentInput = {
    ...defineValidator<PremiumFinanceQuotePaymentInput>({
        invoiceIds: Joi.array().items(UUID.schema).min(1),
        organizationId: UUID.schema,
        quoteNumber: Joi.number(),
        signedDateTimeTermsAndConditions: Joi.date().optional(),
        billingMethod: Joi.string(),
    }),
    create(premiumFinanceQuotePaymentInput: PremiumFinanceQuotePaymentInput) {
        return PremiumFinanceQuotePaymentInput.validate(premiumFinanceQuotePaymentInput);
    },
};

export interface PremiumFinanceUpdateBillingInfoACHInput {
    readonly routingNumber: string;
    readonly accountNumber: string;
    readonly accountType: string;
    readonly billingMethod: string;
    readonly customerIpAddress: string;
    readonly quoteNumber: number;
}

export interface PremiumFinanceUpdateBillingInfoACHResponse {
    readonly achSignatureDateTime: Date;
}

export interface PremiumFinanceUpdateBillingInfoCreditCardInput {
    readonly quoteNumber: number;
}

export interface PremiumFinanceUpdateBillingInfoCreditCardResponse {
    readonly fifRecurringCreditCardURL: URI;
    readonly creditCardSignatureDateTime: Date;
}

export interface QuoteRepository {
    getTaskIds(
        taskIds: Immutable<PremiumFinanceGetTasksInput>,
    ): AsyncResult<PremiumFinanceGetQuoteInput, InvalidArgument | OperationFailed>;
    getQuote(
        getQuoteInput: PremiumFinanceGetQuoteInput,
    ): AsyncResult<QuoteOptions, InvalidArgument | OperationFailed>;
    sendQuotePayment(
        quotePaymentInput: Immutable<PremiumFinanceQuotePaymentInput>,
    ): AsyncResult<boolean, InvalidArgument | OperationFailed>;
    generateDocumentLink(
        request: GenerateDocumentLinkRequestData,
    ): AsyncResult<string, InvalidArgument | OperationFailed>;
    downloadPDFAgreement(
        request: GenerateDocumentLinkRequestData,
    ): AsyncResult<string, InvalidArgument | OperationFailed>;
    getQuoteIndication(
        getQuoteIndicationInput: PremiumFinanceGetQuoteIndicationInput,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed>;
    updateBillingInfoACH(
        updateBillingInfoACHInput: PremiumFinanceUpdateBillingInfoACHInput,
    ): AsyncResult<
        PremiumFinanceUpdateBillingInfoACHResponse,
        PremiumFinanceRoutingCodeInvalid | InvalidArgument | OperationFailed
    >;
    updateBillingInfoCreditCard(
        updateBillingInfoACHInput: PremiumFinanceUpdateBillingInfoCreditCardInput,
    ): AsyncResult<
        PremiumFinanceUpdateBillingInfoCreditCardResponse,
        InvalidArgument | OperationFailed
    >;
    getPaymentConfig(): AsyncResult<PaymentsConfig, InvalidArgument | ConfigFetchFailed>;
}

export const QuoteRepository = Symbol('QuoteRepository');
