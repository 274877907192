import { Immutable } from '@embroker/shotwell/core/types';
import { Revenue as RevenueType } from '@embroker/shotwell/core/types/Revenue';
import React from 'react';
import { Revenue } from './Revenue';
import { FieldMap } from '@embroker/shotwell/view/hooks/useForm';

interface RevenueListData {
    items: Immutable<FieldMap<RevenueType>[]>;
}

export function RevenueList({ items }: RevenueListData) {
    return (
        <React.Fragment key="revenue-list">
            {items.map((item, index) => {
                return (
                    <Revenue
                        data-e2e="user-org-revenue-list-fiscal-year-estimated"
                        key={item.fiscalYear.props.value || index}
                        isEstimated={index === items.length - 1}
                        fields={items[index]}
                    />
                );
            })}
        </React.Fragment>
    );
}
