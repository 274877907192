import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import {
    BoxLayout,
    Button,
    CardLayout,
    Immutable,
    Pagination,
    Table,
    Text,
    useStaticTable,
} from '@embroker/ui-toolkit/v2';
import React, { useMemo } from 'react';
import { SelectOrganization } from '../../../../userOrg/useCases/SelectOrganization';
import { NavigateFunction, useNavigation } from '../../../../view/hooks/useNavigation';
import { OutstandingPayment } from '../../../types/OutstandingPayment';

export interface BrokerageOutstandingPaymentsTableData extends Record<string, unknown> {
    readonly id: UUID;
    readonly companyName: React.ReactNode;
    readonly companyId: UUID;
    readonly policyId?: UUID;
    readonly policyNumber: React.ReactNode;
    readonly effectiveDate: React.ReactNode;
    readonly invoiceNumber: React.ReactNode;
    readonly balance: React.ReactNode;
    readonly action: React.ReactNode;
}

export interface BrokerageOutstandingPaymentsTableProps {
    data: BrokerageOutstandingPaymentsTableData[];
}

export interface ToBrokerageOutstandingPaymentsTableDataRequest {
    tableData: Immutable<OutstandingPayment[]>;
    navigate: NavigateFunction;
}

export function PaymentsTable({ tableData }: { tableData: Immutable<OutstandingPayment[]> }) {
    const { navigate } = useNavigation();

    const items = useMemo(
        () =>
            toBrokerageOutstandingPaymentsTableData({
                tableData,
                navigate,
            }),
        [navigate, tableData],
    );

    const { visibleItems, pagination } = useStaticTable<BrokerageOutstandingPaymentsTableData>({
        items,
        itemsPerPage: 10,
    });

    const noItems = visibleItems.length === 0;

    return (
        <CardLayout>
            <CardLayout.Header>
                <Text style="heading 4">Unpaid balances</Text>
                {pagination.totalPages > 1 ? <Pagination {...pagination} /> : null}
            </CardLayout.Header>
            <CardLayout.Body>
                {!noItems ? (
                    <BoxLayout gap="8">
                        <Table>
                            <Table.Header>
                                <Table.Column>Company</Table.Column>
                                <Table.Column width="1/5">Policy number</Table.Column>
                                <Table.Column width="1/8">Effective date</Table.Column>
                                <Table.Column width="1/8">Invoice number</Table.Column>
                                <Table.Column width="1/10">Balance</Table.Column>
                                <Table.Column width="1/8">Action</Table.Column>
                            </Table.Header>
                            <Table.Body>
                                {visibleItems.map((row) => (
                                    <Table.Row key={row.policyId as UUID}>
                                        <Table.Cell truncate>{row.companyName}</Table.Cell>
                                        <Table.Cell>{row.policyNumber}</Table.Cell>
                                        <Table.Cell>{row.effectiveDate}</Table.Cell>
                                        <Table.Cell>{row.invoiceNumber}</Table.Cell>
                                        <Table.Cell>{row.balance}</Table.Cell>
                                        <Table.Cell>{row.action}</Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </BoxLayout>
                ) : (
                    <div className="u-grid-size-12">
                        <Text style="body 1">You have no unpaid balances right now.</Text>
                    </div>
                )}
            </CardLayout.Body>
        </CardLayout>
    );
}

function toBrokerageOutstandingPaymentsTableData({
    tableData,
    navigate,
}: ToBrokerageOutstandingPaymentsTableDataRequest) {
    return tableData.map((row) => {
        return {
            id: row.id,
            companyName: <Text style="label 1">{row.companyName}</Text>,
            companyId: row.companyId,
            policyId: row.policyId,
            policyNumber: row.policyNumber,
            effectiveDate: row.effectiveDate ? (
                <DateDisplay format="MMM d, yyyy" value={row.effectiveDate} />
            ) : (
                <div>N/A</div>
            ),
            invoiceNumber: row.invoiceNumber,
            balance: <MoneyDisplay value={row.balance} />,
            action: (
                <Button
                    onClick={async () => {
                        await execute(SelectOrganization, {
                            organizationId: row.companyId as UUID,
                        });
                        navigate('/payments');
                    }}
                >
                    Pay now
                </Button>
            ),
        };
    });
}
