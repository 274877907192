import { Module } from '../Module';
import { ContainerModule } from '@embroker/shotwell/core/di';
import { ContactUsRepository } from './repositories/ContactUs';
import { ContactUsPublicRepository } from './repositories/ContactUsPublic';
import { APIContactUsRepository } from './repositories/ContactUs/APIContactUsRepository';
import { APIContactUsPublicRepository } from './repositories/ContactUsPublic/APIContactUsPublicRepository';
import { ContactUs } from './useCases/ContactUs';
import { ContactUsPublic } from './useCases/ContactUsPublic';
import { routes } from './view/routes';

export const SupportModule: Module = {
    routes,
    container: new ContainerModule((bind) => {
        bind<ContactUsRepository>(ContactUsRepository)
            .to(APIContactUsRepository)
            .inSingletonScope();
        bind<ContactUs>(ContactUs.type).to(ContactUs).inSingletonScope();
        bind<ContactUsPublicRepository>(ContactUsPublicRepository)
            .to(APIContactUsPublicRepository)
            .inSingletonScope();
        bind<ContactUsPublic>(ContactUsPublic.type).to(ContactUsPublic).inSingletonScope();
    }),
};
