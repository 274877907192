import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { AsyncResult, isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { HigherLimitsApprovalNotNeeded } from '../../errors';
import { ESPQuoteRepository } from '../repositories/ESPQuoteRepository';

export interface ESPRequestHigherLimitsRequest {
    applicationId: UUID;
    isSubmit: boolean;
}

export interface ESPRequestHigherLimits extends UseCase {
    execute(
        request: ESPRequestHigherLimitsRequest,
    ): AsyncResult<void, HigherLimitsApprovalNotNeeded | InvalidArgument | OperationFailed>;
}

@injectable()
export class ESPRequestHigherLimitsUseCase extends UseCase implements ESPRequestHigherLimits {
    public static type = Symbol('ESP/RequestHigherLimits');
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ESPQuoteRepository) private quoteRepository: ESPQuoteRepository,
        @inject(Log) private logger: Logger,
    ) {
        super(eventBus);
    }

    async execute({
        applicationId,
        isSubmit,
    }: ESPRequestHigherLimitsRequest): AsyncResult<
        void,
        HigherLimitsApprovalNotNeeded | InvalidArgument | OperationFailed
    > {
        const result = await this.quoteRepository.requestHigherLimits(applicationId, isSubmit);
        if (isErr(result)) {
            this.logger.error(result.errors);
        }

        return result;
    }
}

export const ESPRequestHigherLimits: UseCaseClass<ESPRequestHigherLimits> =
    ESPRequestHigherLimitsUseCase;
