import { Modal, ModalActions, ModalState, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { ModalLayout } from '../../../view/components/ModalLayout.view';

interface ManualQuotingConfirmationModalInput {
    readonly modal: ModalActions & ModalState;
}

export function ManualQuotingConfirmationModal({ modal }: ManualQuotingConfirmationModalInput) {
    const { navigate } = useNavigation();

    return (
        <Modal {...modal} size="small" dismissable={false}>
            <ModalLayout
                title="Thank you!"
                primaryAction={{
                    label: 'Go to Dashboard',
                    onClick: () => navigate('/summary'),
                }}
            >
                <Text>
                    We've received your Application. Once we complete our review, we’ll be in touch
                    with you via email in 1-2 business days.
                </Text>
                <Text>
                    Need more info? Connect with us via the{' '}
                    <a href="#chat-now">messaging&nbsp;app</a>.
                </Text>
            </ModalLayout>
        </Modal>
    );
}
