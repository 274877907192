import {
    defineEntityValidator,
    Entity,
    entity,
    EntityProps,
} from '@embroker/shotwell/core/entity/Entity';
import { Joi } from '@embroker/shotwell/core/validation/schema';

export const NotificationStatus = ['read', 'unread', 'dismissed'] as const;
export type NotificationStatus = (typeof NotificationStatus)[number];

export const NotificationActionTypes = ['view-policy', 'make-payment', 'download'] as const;
export type NotificationActionTypes = (typeof NotificationActionTypes)[number];

export interface NotificationAction {
    type: NotificationActionTypes;
    value: string;
}

export interface Notification extends Entity {
    /*
     * Notification's content
     */
    content: string;
    /*
     * The status of the notification, can be 'read' 'unread' or 'dismissed'
     */
    status: NotificationStatus;
    /*
     * The effective date of the notification
     */
    created: Date;
    /*
     * The object defining a notification's action
     */
    action?: NotificationAction;
}

export const Notification = entity<Notification>({
    validator: defineEntityValidator<Notification>({
        content: Joi.string(),
        status: Joi.string().valid(...NotificationStatus),
        created: Joi.date(),
        action: Joi.object({
            type: Joi.string().valid(...NotificationActionTypes),
            value: Joi.string(),
        }).optional(),
    }),
});

export type NotificationViewModel = EntityProps<Notification>;
