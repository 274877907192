import {
    LineOfBusinessCodeList,
    LineOfBusinessCodeListItem,
    LineOfBusinessSubtypeCodeList,
    LineOfBusinessSubtypeCodeListItem,
    StructuralComponentTypeCodeList,
    StructuralComponentTypeCodeListItem,
} from '@embroker/shotwell-api/enums';
import { Immutable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { Location } from '../../locations/types/Location';
import { CertificateCoverageLimit } from './CertificateCoverageLimit';
import { CertificateInsurer } from './CertificateInsurer';

export interface CertificateCoverageInfo {
    createdAt?: Date;
    accordSectionType?: string;
    policyId?: UUID;
    policyNumber?: string;
    customName?: string;
    policyLineOfBusiness?: LineOfBusinessCodeListItem;
    policyLineOfBusinessSubtype?: LineOfBusinessSubtypeCodeListItem;
    policyEffectiveDate?: Date;
    policyEndDate?: Date;
    policyCancellationDate?: Date;
    insurer?: CertificateInsurer;
    requestedAmount?: Money;
    requestedLineOfBusiness?: string;
    structuralComponentTypeCode: StructuralComponentTypeCodeListItem;
    waiverOfSubrogation?: boolean;
    primaryNonContributory?: boolean;
    blanketAdditionalInsured?: boolean;
    isInCertificateDocument?: boolean;
    lossPayee?: boolean;
    lenderLossPayee?: boolean;
    mortgagee?: boolean;
    additionalInterest?: boolean;
    locationList: Immutable<Location[]>;
    limits: Immutable<CertificateCoverageLimit[]>;
    bundleId?: UUID;
}

export const CertificateCoverageInfo = {
    ...defineValidator<CertificateCoverageInfo>(
        Joi.object({
            createdAt: Joi.date().optional(),
            accordSectionType: Joi.string().optional(),
            policyId: UUID.schema.optional(),
            policyNumber: Joi.string().optional().allow(''),
            customName: Joi.string().optional().allow(''),
            policyLineOfBusiness: Joi.string()
                .allow(...LineOfBusinessCodeList)
                .optional(),
            policyLineOfBusinessSubtype: Joi.string()
                .allow(...LineOfBusinessSubtypeCodeList)
                .optional(),
            policyEffectiveDate: Joi.date().optional(),
            policyEndDate: Joi.date().optional(),
            policyCancellationDate: Joi.date().optional(),
            insurer: CertificateInsurer.schema.optional(),
            requestedAmount: Money.schema.optional(),
            requestedLineOfBusiness: Joi.string().optional(),
            structuralComponentTypeCode: Joi.string()
                .allow(...StructuralComponentTypeCodeList)
                .required(),
            waiverOfSubrogation: Joi.boolean().optional(),
            primaryNonContributory: Joi.boolean().optional(),
            blanketAdditionalInsured: Joi.boolean().optional(),
            isInCertificateDocument: Joi.boolean().optional(),
            lossPayee: Joi.boolean().optional(),
            lenderLossPayee: Joi.boolean().optional(),
            mortgagee: Joi.boolean().optional(),
            additionalInterest: Joi.boolean().optional(),
            locationList: Joi.array().items(Location.schema),
            limits: Joi.array().items(CertificateCoverageLimit.schema),
            bundleId: UUID.schema.optional(),
        }).preferences({ presence: 'required' }),
    ),
    create(certificateCoverageInfo: CertificateCoverageInfo) {
        return CertificateCoverageInfo.validate(certificateCoverageInfo);
    },
};

export function coverageHasLimits(certificateCoverageInfo: CertificateCoverageInfo): boolean {
    return (
        certificateCoverageInfo.limits !== undefined && certificateCoverageInfo.limits.length > 0
    );
}
