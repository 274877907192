import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Button, Text } from '@embroker/ui-toolkit/v2';
import React, { useContext } from 'react';
import { Application } from '../../../shopping/entities/Application';
import * as enums from '../../../shopping/types/enums';
import { ApplicationFlags } from '../../../shopping/view/components/applicationFlags';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { AppTypeCodeListEmbrokerExcess } from '@app/shopping/types/enums';
import { AppContext } from '../../../view/AppContext';
import { hasRole } from '../../../userOrg/entities/Session';

interface ActionElementProps {
    application: EntityProps<Application>;
    onViewQuestionnaire: (application: EntityProps<Application>) => void;
    flags: ApplicationFlags;
    onPrintBinder: (applicationId: UUID) => void;
}

export function resolveQuotesReadyLink(applicationId: UUID, flags: ApplicationFlags): URI {
    if (flags.showNewBusinessESPQuoteLink) {
        return URI.build('/shopping/application/quote/esp/', { applicationId });
    }
    if (flags.showRenewalESPQuoteLink) {
        return URI.build('/shopping/application/quote/esp-renewals/', { applicationId });
    }
    if (flags.showWCGAQuoteLink) {
        return URI.build('/shopping/application/quote/wcga/', { applicationId });
    }
    if (flags.showLplQuoteLink) {
        return URI.build('/shopping/application/quote/lpl/', { applicationId });
    }
    if (flags.showCnaBopQuoteLink) {
        return URI.build('/shopping/application/quote/bopcna/', { applicationId });
    }
    if (flags.showPCoMLQuoteLink) {
        return URI.build('/shopping/application/quote/pcoml/', { applicationId });
    }
    if (flags.showCrimeQuoteLink) {
        return URI.build('/shopping/application/quote/crime/', { applicationId });
    }
    if (flags.showCyberQuoteLink) {
        return URI.build('/shopping/application/quote/cyber/', { applicationId });
    }
    if (flags.showExcessQuoteLink) {
        return URI.build('/shopping/application/quote/excess/', { applicationId });
    }
    if (flags.showBundleQuoteLink) {
        return URI.build('/shopping/bundle', { applicationId });
    }
    if (flags.showCyberCowbellQuoteLink) {
        return URI.build('/shopping/cyber-cowbell', { applicationId });
    }
    if (flags.showBopHartfordQuoteLink) {
        return URI.build('/shopping/application/quote/bop-hartford', { applicationId });
    }

    return URI.build('');
}

export function ApplicationAction({
    application,
    onViewQuestionnaire,
    flags,
    onPrintBinder,
}: ActionElementProps) {
    const { navigate } = useNavigation();
    const onActionClick = (uri: URI) => {
        navigate(uri);
    };
    const { activeSession } = useContext(AppContext);
    const isAdmin = hasRole(activeSession, 'admin');

    switch (application.status) {
        case enums.AwaitingQuotes:
            return (
                <Button
                    appearance="secondary"
                    onClick={() => onViewQuestionnaire(application)}
                    data-e2e="view-questionnaire"
                >
                    View Questionnaire
                </Button>
            );
        case enums.QuestionnaireInProgress:
            return (
                <Button
                    appearance="secondary"
                    onClick={() => {
                        let baseURL = '/shopping/application';
                        if (
                            application.appType === AppTypeCodeListEmbrokerExcess &&
                            application.isPristine
                        ) {
                            baseURL = '/shopping/excess-before-you-begin';
                        }
                        onActionClick(
                            URI.build(baseURL, {
                                applicationId: application.id,
                            }),
                        );
                    }}
                >
                    {application.renewedPolicyIdList.length > 0
                        ? 'Renew Policy'
                        : 'Continue Application'}
                </Button>
            );
        case enums.Purchased:
            return (
                <Button
                    appearance="secondary"
                    onClick={() => {
                        onActionClick(URI.build('/policies'));
                    }}
                >
                    View Policies
                </Button>
            );
        case enums.QuotesReady:
            return (
                <Button
                    appearance="secondary"
                    data-e2e="view-quote"
                    onClick={() => {
                        onActionClick(resolveQuotesReadyLink(application.id, flags));
                    }}
                >
                    View Quotes
                </Button>
            );
        case enums.AwaitingBind: {
            if (isAdmin && flags.showCNABOPQuoteLink) {
                return (
                    <Button
                        appearance="secondary"
                        onClick={() => {
                            onActionClick(
                                URI.build('/shopping/application/quote/bopcna/signature', {
                                    applicationId: application.id,
                                }),
                            );
                        }}
                    >
                        View Quotes
                    </Button>
                );
            }
            return null;
        }
        case enums.Referred: {
            if (flags.showWCGAQuoteLink) {
                return (
                    <Button
                        appearance="secondary"
                        onClick={() => {
                            onActionClick(
                                URI.build('/shopping/application/quote/wcga/referred-estimate/', {
                                    applicationId: application.id,
                                }),
                            );
                        }}
                    >
                        View Estimate
                    </Button>
                );
            }
            if ((flags.showWCGAQuoteLink && application.hasClientReviewRequest) ?? false) {
                return (
                    <Button
                        appearance="secondary"
                        onClick={() => {
                            onActionClick(
                                URI.build(
                                    '/shopping/application/quote/wcga/referred-estimate-submitted/',
                                    {
                                        applicationId: application.id,
                                    },
                                ),
                            );
                        }}
                    >
                        View Estimate
                    </Button>
                );
            }
            if (flags.showLplReferredEstimateLink) {
                return (
                    <Button
                        appearance="secondary"
                        onClick={() => {
                            onActionClick(
                                URI.build('/shopping/application/quote/lpl/referred-estimate/', {
                                    applicationId: application.id,
                                }),
                            );
                        }}
                    >
                        View Estimate
                    </Button>
                );
            }
            if (flags.showLplViewQuestionnaireLink) {
                return (
                    <Button appearance="secondary" onClick={() => onViewQuestionnaire(application)}>
                        View Questionnaire
                    </Button>
                );
            }
            return null;
        }
        case enums.BundleSubmitted:
            return (
                <Button
                    appearance="secondary"
                    data-e2e="view-quote"
                    onClick={() => {
                        onActionClick(resolveQuotesReadyLink(application.id, flags));
                    }}
                >
                    View Quotes
                </Button>
            );
        case enums.EarlyClearanceFailed:
        case enums.ClearanceFailed:
            return <Text>Clearance review</Text>;
        case enums.QuoteExpired:
            return <Text style="microcopy">Quote expired</Text>;
        case enums.SubmissionInProgress:
            return <Text style="microcopy">Submission in progress</Text>;
        case enums.Bound:
            return (
                <Button
                    appearance="secondary"
                    data-e2e="view-binder"
                    onClick={() => onPrintBinder(application.id)}
                >
                    View Binder
                </Button>
            );
        case enums.SupplementalInProgress:
            return (
                <Button
                    appearance="secondary"
                    onClick={() =>
                        navigate(
                            URI.build('/shopping/application/supplemental-questionnaire/esp', {
                                applicationId: application.id,
                            }),
                        )
                    }
                >
                    Continue
                </Button>
            );

        default:
            return null;
    }
}
