import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import {
    PremiumFinanceGetQuoteInput,
    PremiumFinanceGetTasksInput,
    QuoteRepository,
} from '../repositories/QuoteRepository';

export interface GetPremiumFinanceTaskIds extends UseCase {
    execute(
        request: PremiumFinanceGetTasksInput,
    ): AsyncResult<PremiumFinanceGetQuoteInput, InvalidArgument | OperationFailed>;
}

/**
 * GetPremiumFinanceQuote use case is used to add a new organization on the platform
 */
@injectable()
class GetPremiumFinanceTaskIdsUseCase extends UseCase {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Payments/GetPremiumFinanceTaskIds');

    /**
     * Constructor for GetPremiumFinanceQuote class instance
     * @param quoteRepository is the quote repo which will be used to obtain premium finance quotes
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(QuoteRepository) private quoteRepository: QuoteRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes the GetPremiumFinanceQuote use case.
     * @param request is representation of the new organization
     * @returns GetPremiumFinanceQuoteResponse if execution was successful
     * @returns InvalidArgument if one of properties in GetPremiumFinanceQuoteRequest was not valid
     */
    public async execute(
        request: PremiumFinanceGetTasksInput,
    ): AsyncResult<PremiumFinanceGetQuoteInput, InvalidArgument | OperationFailed> {
        const validationResult = PremiumFinanceGetTasksInput.validate(request);

        if (isErr(validationResult)) {
            return validationResult;
        }

        const result = await this.quoteRepository.getTaskIds(validationResult.value);

        if (isErr(result)) {
            return result;
        }

        return Success<PremiumFinanceGetQuoteInput>({
            taskMonthly: result.value.taskMonthly,
            taskQuarterly: result.value.taskQuarterly,
        });
    }
}

export const GetPremiumFinanceTaskIds: UseCaseClass<GetPremiumFinanceTaskIds> =
    GetPremiumFinanceTaskIdsUseCase;
