import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, isErr } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { PolicyRepository } from '../repositories/PolicyRepository';

/**
 * Request data for GetDocumentURL use case
 */
export interface GetDocumentURLRequest {
    fileKey: string;
}

export const GetDocumentURLRequest = {
    ...defineValidator<GetDocumentURLRequest>({
        fileKey: Joi.string(),
    }),
    create(getDocumentURLRequest: GetDocumentURLRequest) {
        return GetDocumentURLRequest.validate(getDocumentURLRequest);
    },
};

/**
 * Response data for GetDocumentURL use case
 */
export interface GetDocumentURLResponse {
    documentURL: URI;
}

export const GetDocumentURLResponse = {
    ...defineValidator<GetDocumentURLResponse>({
        documentURL: Joi.string(),
    }),
    create(getDocumentURLResponse: GetDocumentURLResponse) {
        return GetDocumentURLResponse.validate(getDocumentURLResponse);
    },
};

/**
 * GetDocumentURL use case is used to ...
 */
export interface GetDocumentURL extends UseCase {
    execute(
        request: GetDocumentURLRequest,
    ): AsyncResult<GetDocumentURLResponse, InvalidArgument | OperationFailed>;
}

@injectable()
class GetDocumentURLUseCase extends UseCase implements GetDocumentURL {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('policy/GetDocumentURL');

    /**
     * Constructor for GetDocumentURL use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(PolicyRepository) private policyRepository: PolicyRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes GetDocumentURL use case
     * Input is of GetDocumentURLRequest type
     * @returns data of GetDocumentURLResponse type if execution was successful
     * @returns InvalidArgument if ...
     * @returns OperationFailed if ...
     */
    public async execute(
        request: GetDocumentURLRequest,
    ): AsyncResult<GetDocumentURLResponse, InvalidArgument | OperationFailed> {
        if (request.fileKey === '') {
            return Failure(InvalidArgument({ argument: 'request', value: request }));
        }

        const result = await this.policyRepository.downloadUrl(request.fileKey);
        if (isErr(result)) {
            return result;
        }

        const documentURLData: GetDocumentURLResponse = {
            documentURL: result.value,
        };

        return GetDocumentURLResponse.create(documentURLData);
    }
}

export const GetDocumentURL: UseCaseClass<GetDocumentURL> = GetDocumentURLUseCase;
