import type * as APIType from '@embroker/shotwell-api/app';
import { API, CurrencyMarshaller, InsuranceApplication } from '@embroker/shotwell-api/app';
import { InsuranceApplicationStatusCodeListItem } from '@embroker/shotwell-api/enums';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { injectable } from '@embroker/shotwell/core/di';
import { Money } from '@embroker/shotwell/core/types/Money';
import {
    AsyncResult,
    Failure,
    Result,
    Success,
    handleOperationFailure,
    isErr,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { startOfToday } from 'date-fns';
import { AwaitingBind, QuotingEngineCNABOP } from '../../../../shopping/types/enums';
import { QuoteExpiration } from '../../../types/QuoteExpiration';
import { QuoteIdMap, QuoteList } from '../../../types/QuoteList';
import { CNAQuote } from '../../entities/CNAQuote';
import { CNAAppInfo } from '../../types/CNAAppInfo';
import {
    CNAQuoteOptions,
    GeneralLiabilityLimit,
    StopGapLiabilityLimit,
} from '../../types/CNAQuoteOptions';
import { CNAWarnings } from '../../types/CNAWarnings';
import { CNAQuoteRepository, PurchaseCNAResponse } from './index';

const BUILDING_LIMIT_MIN_WARNING_MESSAGE = `You entered a building value on your application that is lower than our property appraisal system allows. The minimum value we can insure for your building has been updated below. If you would like additional assistance, please click the 'Support' link above to contact an Embroker advisor.`;
const BUILDING_LIMIT_MAX_WARNING_MESSAGE = `You entered a building value on your application that is higher than our property appraisal system allows. The maximum value we can insure for your building has been updated below. If you would like additional assistance, please click the 'Support' link above to contact an Embroker advisor.`;
const HIRED_NON_OWNED_AUTO_LIMIT_WARNING_MESSAGE =
    'Automatically updated to conform with General Liability limit selection.';

interface GetCNAWarningsRequest {
    hiredNonOwnedAutoLimit: Money;
    generalLiabilityLimit: GeneralLiabilityLimit;
    adjustedBuildingLimit: Money;
    buildingLimit: Money;
}

@injectable()
export class APICNAQuoteRepository implements CNAQuoteRepository {
    async getCurrentCNAQuote(
        applicationId: UUID,
    ): AsyncResult<CNAQuote, InvalidArgument | OperationFailed> {
        const applicationResponse = await API.request('shopping/application', {
            id: applicationId,
        });

        if (isErr(applicationResponse)) {
            return handleOperationFailure(applicationResponse);
        }

        return await toCNAQuote(applicationResponse.value as InsuranceApplication, applicationId);
    }

    async quoteCNA(
        applicationId: UUID,
        cnaQuoteOptions: CNAQuoteOptions,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed> {
        const createCnaQuoteResponse = await API.request('shopping/create_quote_task', {
            application_id: applicationId,
            quote_options: {
                cna_bop: toApiCnaQuoteOptions(cnaQuoteOptions),
            },
        });

        if (isErr(createCnaQuoteResponse)) {
            return handleOperationFailure(createCnaQuoteResponse);
        }

        return Success(createCnaQuoteResponse.value.task_id);
    }

    public async submitForReview(
        applicationId: UUID,
    ): AsyncResult<void, InvalidArgument | OperationFailed> {
        const response = await API.request('shopping/submit_for_review', {
            application_id: applicationId,
        });

        if (isErr(response)) {
            return handleOperationFailure(response);
        }

        return Success();
    }

    async purchaseCNA(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<PurchaseCNAResponse, InvalidArgument | OperationFailed> {
        const quoteIdMap: QuoteIdMap = { [QuotingEngineCNABOP]: quoteId };
        const purchaseResponse = await API.request('shopping/purchase', {
            id: applicationId,
            quote_id_map: quoteIdMap,
        });

        if (isErr(purchaseResponse)) {
            return handleOperationFailure(purchaseResponse);
        }

        const { policyId, policy_issuance_task_id: taskIdPolicyDone } = purchaseResponse.value;

        const result: PurchaseCNAResponse = {
            policyId: policyId,
            policyDoneTaskId: taskIdPolicyDone,
        };

        return Success(result);
    }
}

async function toCNAQuote(
    apiInsuranceApplication: APIType.InsuranceApplication,
    applicationId: UUID,
): AsyncResult<CNAQuote, InvalidArgument | OperationFailed> {
    const quote = QuoteList.getLastQuote(apiInsuranceApplication.quote_list);
    if (quote === null) {
        return Failure(OperationFailed({ message: 'Quote not found in the application object' }));
    }

    const quoteOptions = quote.options.cna_bop ?? ({} as APIType.CnaBopQuoteOptions);
    const quoteDetails = quote.details.cna_bop ?? ({} as APIType.CnaBopQuoteDetails);
    const adjustedBuildingLimit = quoteDetails.adjusted_building_limit;
    const cnaQuoteOptions = toCNAQuoteOptions(quoteOptions, adjustedBuildingLimit);

    const cnaAppInfoResult = toCnaAppInfo(apiInsuranceApplication);

    if (isErr(cnaAppInfoResult)) {
        return handleOperationFailure(cnaAppInfoResult);
    }

    const result = await CNAQuote.create({
        isIndication: quote.is_indication,
        id: quote.id,
        totalPremium: quote.total_premium,
        annualTechnologyFee: quote.annual_technology_fee,
        totalPayable: quote.total_payable,
        options: cnaQuoteOptions,
        isQuoteReferred: quoteDetails.referred,
        applicationId: applicationId,
        appInfo: cnaAppInfoResult.value,
        status: 'draft',
        isRenewal: apiInsuranceApplication.renewed_policy_id_list.length > 0,
        daysToExpire: QuoteExpiration.getDaysLeftUntilExpiration({
            quotingEngine: apiInsuranceApplication.quoting_engine || undefined,
            applicationStatus: apiInsuranceApplication.status,
            validUntil: apiInsuranceApplication.valid_until,
            quoteEffectiveDate: cnaQuoteOptions.effectiveDate,
            today: startOfToday(),
            isBroker: apiInsuranceApplication.brokerage_id !== null,
        }),
    });

    if (isErr(result)) {
        return handleOperationFailure(result);
    }

    if (apiInsuranceApplication.status === AwaitingBind) {
        const signResult = result.value.sign();
        if (isErr(signResult)) {
            return handleOperationFailure(signResult);
        }
    }

    return result;
}

function toCNAQuoteOptions(
    apiCnaQuoteOptions: APIType.CnaBopQuoteOptions,
    adjustedBuildingLimit: Money,
): CNAQuoteOptions {
    const generalLiabilityLimit = `${Money.toFloat(
        apiCnaQuoteOptions.gl_per_occ_limit,
    )}/${Money.toFloat(apiCnaQuoteOptions.gl_aggregate_limit)}` as GeneralLiabilityLimit;

    const warnings = getCnaWarnings({
        adjustedBuildingLimit,
        buildingLimit: apiCnaQuoteOptions.building_limit,
        generalLiabilityLimit,
        hiredNonOwnedAutoLimit: apiCnaQuoteOptions.hired_non_owned_limit,
    });

    const buildingLimit = buildBuildingLimit(
        apiCnaQuoteOptions.building_limit,
        adjustedBuildingLimit,
    );

    const hiredNonOwnedAutoLimit = buildHiredNonOwnedAutoLimit(
        apiCnaQuoteOptions.hired_non_owned_limit,
        generalLiabilityLimit,
    );

    const {
        effective_date,
        contents_limit,
        property_deductible,
        tenants_and_betterments_limit,
        wind_hail_deductible,
        enhanced,
        stop_gap_liability_per_acc_limit,
        stop_gap_liability_dis_each_empl_limit,
        stop_gap_liability_dis_pol_limit,
    } = apiCnaQuoteOptions;

    return {
        effectiveDate: effective_date,
        generalLiabilityLimit,
        hiredNonOwnedAutoLimit: hiredNonOwnedAutoLimit,
        buildingLimit: buildingLimit,
        contentsLimit: contents_limit,
        propertyDeductible: property_deductible,
        tenantsAndBettermentsLimit: tenants_and_betterments_limit,
        windHailDeductible: wind_hail_deductible,
        enhanced,
        stopGapLiabilityLimit: `${CurrencyMarshaller.marshal(
            stop_gap_liability_per_acc_limit,
        )}/${CurrencyMarshaller.marshal(
            stop_gap_liability_dis_each_empl_limit,
        )}/${CurrencyMarshaller.marshal(
            stop_gap_liability_dis_pol_limit,
        )}` as StopGapLiabilityLimit,
        cnaWarnings: warnings,
    };
}

function getCnaWarnings({
    hiredNonOwnedAutoLimit,
    generalLiabilityLimit,
    adjustedBuildingLimit,
    buildingLimit,
}: GetCNAWarningsRequest): CNAWarnings {
    let buildingLimitWarning = undefined;
    let hiredNonOwnedAutoLimitWarning = undefined;

    if (adjustedBuildingLimit.amount !== 0) {
        buildingLimitWarning =
            adjustedBuildingLimit.amount > buildingLimit.amount
                ? BUILDING_LIMIT_MIN_WARNING_MESSAGE
                : BUILDING_LIMIT_MAX_WARNING_MESSAGE;
    }

    const hnoaLimit = getHNOABasedOnGL(hiredNonOwnedAutoLimit, generalLiabilityLimit);
    if (hnoaLimit !== hiredNonOwnedAutoLimit) {
        hiredNonOwnedAutoLimitWarning = HIRED_NON_OWNED_AUTO_LIMIT_WARNING_MESSAGE;
    }

    return {
        buildingLimitWarning,
        hiredNonOwnedAutoLimitWarning,
    };
}

function buildBuildingLimit(apiBuildingLimit: Money, adjustedBuildingLimit: Money): Money {
    let buildingLimit = apiBuildingLimit;

    if (adjustedBuildingLimit.amount !== 0) {
        buildingLimit = adjustedBuildingLimit;
    }

    return buildingLimit;
}

function buildHiredNonOwnedAutoLimit(
    apiHiredNonOwnedAutoLimit: Money,
    apiGeneralLiabilityLimit: GeneralLiabilityLimit,
): Money {
    let hiredNonOwnedAutoLimit = apiHiredNonOwnedAutoLimit;
    const hnoaLimit = getHNOABasedOnGL(apiHiredNonOwnedAutoLimit, apiGeneralLiabilityLimit);

    if (hnoaLimit !== apiHiredNonOwnedAutoLimit) {
        hiredNonOwnedAutoLimit = hnoaLimit;
    }

    return hiredNonOwnedAutoLimit;
}

function getHNOABasedOnGL(
    hiredNonOwnedAutoLimit: Money,
    generalLiabilityLimit: GeneralLiabilityLimit,
): Money {
    const hnoaMaxLimit = Money.tryFromFloat(1000 * 1000);
    const hnoaUpdatedLimit = Money.tryFromFloat(500 * 1000);
    const glMinLimit = `${Money.toFloat(hnoaUpdatedLimit)}/${Money.toFloat(hnoaMaxLimit)}`;

    if (
        generalLiabilityLimit === glMinLimit &&
        Money.isEqual(hiredNonOwnedAutoLimit, hnoaMaxLimit)
    ) {
        return hnoaUpdatedLimit;
    }

    return hiredNonOwnedAutoLimit;
}

function toApiCnaQuoteOptions(cnaQuoteOptions: CNAQuoteOptions): APIType.CnaBopQuoteOptions {
    const {
        effectiveDate,
        generalLiabilityLimit,
        hiredNonOwnedAutoLimit,
        buildingLimit,
        contentsLimit,
        propertyDeductible,
        tenantsAndBettermentsLimit,
        windHailDeductible,
        stopGapLiabilityLimit,
        enhanced,
    } = cnaQuoteOptions;
    const tmpGLLimit = generalLiabilityLimit.split('/');
    const generalLiabilityPerOccLimit = parseInt(tmpGLLimit[0], 10);
    const generalLiabilityAggLimit = parseInt(tmpGLLimit[1], 10);
    const tmpSGLLimit = stopGapLiabilityLimit.split('/');
    const stopGapLiabilityPerAcc = parseInt(tmpSGLLimit[0], 10);
    const stopGapLiabilityDisEachEmpl = parseInt(tmpSGLLimit[1], 10);
    const stopGapLiabilityDisPol = parseInt(tmpSGLLimit[2], 10);

    return {
        enhanced,
        effective_date: effectiveDate,
        gl_aggregate_limit: CurrencyMarshaller.unmarshal(
            !isNaN(generalLiabilityAggLimit) ? generalLiabilityAggLimit : 0,
        ),
        gl_per_occ_limit: CurrencyMarshaller.unmarshal(
            !isNaN(generalLiabilityPerOccLimit) ? generalLiabilityPerOccLimit : 0,
        ),
        hired_non_owned_limit: hiredNonOwnedAutoLimit,
        building_limit: buildingLimit,
        contents_limit: contentsLimit,
        tenants_and_betterments_limit: tenantsAndBettermentsLimit,
        property_deductible: propertyDeductible,
        wind_hail_deductible: windHailDeductible,
        stop_gap_liability_per_acc_limit: CurrencyMarshaller.unmarshal(
            !isNaN(stopGapLiabilityPerAcc) ? stopGapLiabilityPerAcc : 0,
        ),
        stop_gap_liability_dis_each_empl_limit: CurrencyMarshaller.unmarshal(
            !isNaN(stopGapLiabilityDisEachEmpl) ? stopGapLiabilityDisEachEmpl : 0,
        ),
        stop_gap_liability_dis_pol_limit: CurrencyMarshaller.unmarshal(
            !isNaN(stopGapLiabilityDisPol) ? stopGapLiabilityDisPol : 0,
        ),
    };
}

function toCnaAppInfo(apiInsuranceApplication: APIType.InsuranceApplication): Result<CNAAppInfo> {
    const questionnaireData = apiInsuranceApplication.questionnaire_data;

    if (questionnaireData === null || questionnaireData === undefined) {
        return Failure(
            InvalidArgument({ argument: 'questionnaire_data', value: questionnaireData }),
        );
    }

    let questionnaireDataParsed;
    try {
        questionnaireDataParsed = JSON.parse(questionnaireData);
    } catch (_e) {
        return Failure(OperationFailed({ message: 'Failed to parse questionnaire_data' }));
    }

    const fullName =
        questionnaireDataParsed.full_name ||
        `${questionnaireDataParsed.first_name} ${questionnaireDataParsed.last_name}`;
    const locations = questionnaireDataParsed?.locations?.location;
    const firstLocation = Array.isArray(locations) && (locations[0] as any);
    const isBuildingInsuranceRequired = questionnaireDataParsed.is_building_insurance_required
        ? questionnaireDataParsed.is_building_insurance_required
        : null;

    return Success({
        fullName: fullName,
        title: questionnaireDataParsed.title,
        usaState: firstLocation ? firstLocation.state : '',
        businessDescription: questionnaireDataParsed.business_description,
        workSpaceType: questionnaireDataParsed.working_space_type,
        isBuildingInsuranceRequired: isBuildingInsuranceRequired,
        company: questionnaireDataParsed.company_name,
        status: apiInsuranceApplication.status as InsuranceApplicationStatusCodeListItem,
    });
}
