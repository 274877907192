import { API } from '@embroker/shotwell-api/app';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { Settings } from '../../types/Settings';
import { LocationRepository } from './index';

@injectable()
export class APILocationRepository implements LocationRepository {
    public async getSettings(): AsyncResult<Settings, OperationFailed | InvalidArgument> {
        const configResult = await API.request('global/get_config');

        if (isErr(configResult)) {
            return handleOperationFailure(configResult);
        }

        const result = Settings.create({
            googleTagManagerToken: configResult.value.google_tag_manager_token,
        });

        if (isErr(result)) {
            return result;
        }

        return Success(result.value);
    }
}
