import { Password } from '@embroker/shotwell/core/types/Password';
import { MINIMUM_PASSWORD_LENGTH } from '@embroker/shotwell/core/validation/password';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    Button,
    Form,
    FormLayout,
    StackLayout,
    StatusMessage,
    StatusMessageList,
    Text,
} from '@embroker/ui-toolkit/v2';
import React, { useEffect, useState } from 'react';
import { ChangePassword } from '../../useCases/ChangePassword';

interface ChangePasswordFormData {
    oldPassword: string;
    newPassword: string;
}

const ChangePasswordForm = createForm<ChangePasswordFormData>({
    fields: {
        oldPassword: {
            type: 'password',
            validator: Joi.string().required(),
            formatValidationError(error) {
                switch (error.details.validator) {
                    case 'any.required':
                    case 'string.empty':
                        return 'You need to enter you current password';
                    default:
                        return `Unhandled validation error: ${error.message}`;
                }
            },
        },
        newPassword: {
            type: 'password',
            validator: Password.schema,
            formatValidationError(error) {
                switch (error.details.validator) {
                    case 'any.required':
                    case 'string.empty':
                        return 'You need to enter new password';
                    case 'string.min':
                    case 'password.length':
                        return `Password should be at least ${MINIMUM_PASSWORD_LENGTH} characters long`;
                    case 'password.strength':
                        return 'Please provide a stronger password';
                    default:
                        return `Unhandled validation error: ${error.message}`;
                }
            },
        },
    },
    useCase: ChangePassword,
    formatSubmitErrors(errors) {
        return ['Sorry, but it seems that the current password you entered is invalid'];
    },
});

export function UpdateExistingPassword() {
    const [passwordIsChangedSuccessfully, setPasswordIsChangedSuccessfully] = useState(false);
    const { submit, messages, fields, status, setValue } = useForm(ChangePasswordForm);

    useEffect(() => {
        if (status === 'submitted') {
            setPasswordIsChangedSuccessfully(true);
            setValue({
                newPassword: '',
                oldPassword: '',
            });
        }
    }, [status, setValue]);

    return (
        <FormLayout>
            <Form noValidate onSubmit={submit}>
                <StackLayout data-e2e="update-pwd-status-msg-wrapper" gap="32">
                    {status === 'invalid' && (
                        <StatusMessageList messages={messages} status="error" />
                    )}
                    {passwordIsChangedSuccessfully && (
                        <StatusMessage status="success">
                            Password updated successfully
                        </StatusMessage>
                    )}
                    <Text data-e2e="update-pwd-header" style="heading 3">
                        Update your password
                    </Text>
                    <Form.Field
                        inputProps={{
                            autoComplete: 'old-password',
                            ...fields.oldPassword.props,
                        }}
                        data-e2e="update-pwd-old-password"
                        label="Enter current password"
                        messages={fields.oldPassword.messages}
                        type={fields.oldPassword.type}
                    ></Form.Field>
                    <Form.Field
                        inputProps={{
                            autoComplete: 'new-password',
                            ...fields.newPassword.props,
                            strengthMeter: true,
                        }}
                        data-e2e="update-pwd-new-password"
                        label="Enter new password"
                        messages={fields.newPassword.messages}
                        type={fields.newPassword.type}
                    ></Form.Field>

                    <Button data-e2e="update-pwd-submit-button" type="submit">
                        Update password
                    </Button>
                </StackLayout>
            </Form>
        </FormLayout>
    );
}
