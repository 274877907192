import { Modal, ModalActions, ModalState, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { ModalLayout } from '../../../../view/components/ModalLayout.view';

export function BrokerQuoteReferredModal({ modal }: { modal: ModalActions & ModalState }) {
    const { navigate } = useNavigation();

    return (
        <Modal {...modal} size="small" dismissable={false}>
            <ModalLayout
                title="Thanks"
                primaryAction={{
                    label: 'Back To Dashboard',
                    onClick: () => navigate('/broker/dashboard'),
                    'data-e2e': 'back-to-dashboard',
                }}
                secondaryAction={{
                    label: 'Open Live Chat',
                    href: '#chat-now',
                    'data-e2e': 'open-live-chat',
                }}
                data-e2e="referred-modal"
            >
                <Text>
                    We've received your Application. An Embroker team member will need to review it
                    due to some of your responses. We'll be in touch with you via email in 1-2
                    business days, and can update you at that time.
                </Text>
                <Text>You can also get more information from your Access Channel Manager.</Text>
            </ModalLayout>
        </Modal>
    );
}
