import { Password } from '@embroker/shotwell/core/types/Password';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    BoxLayout,
    Button,
    Form,
    FormLayout,
    StackLayout,
    StatusMessageList,
    Text,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { Token } from '../../../../userOrg/types/Token';
import { BrokerSignUp as BrokerSignUpUseCase } from '../../../useCases/BrokerSignUp';
import { PrivacyMessage } from '../../../../userOrg/view/components/PrivacyMessage.view';

interface BrokerSignUpFormData {
    firstName: string;
    lastName: string;
    password: Password;
    invitationToken: Token;
}

const brokerSignUpForm = createForm<BrokerSignUpFormData>({
    fields: {
        firstName: {
            type: 'text',
            validator: Joi.string().required().trim().min(1),
            formatValidationError(error) {
                return 'You must enter your first name';
            },
        },
        lastName: {
            type: 'text',
            validator: Joi.string().required().trim().min(1),
            formatValidationError(error) {
                return 'You must enter your last name';
            },
        },
        password: {
            type: 'text',
            validator: Password.schema.required(),
            formatValidationError(error) {
                switch (error.details.validator) {
                    case 'any.required':
                    case 'string.empty':
                        return 'You must enter a password';
                    case 'string.min':
                    case 'password.length':
                        return 'Entered password is too short';
                    case 'password.strength':
                        return 'Please provide a stronger password';
                    default:
                        return error.message;
                }
            },
        },
        invitationToken: 'hidden',
    },
    formatSubmitErrors(errors) {
        return ['Sorry, something went wrong. Please try again later.'];
    },
    submit: async ({ firstName, lastName, password, invitationToken }) => {
        return await execute(BrokerSignUpUseCase, {
            firstName,
            lastName,
            password,
            invitationToken,
        });
    },
});

interface BrokerSignUpProps {
    readonly invitationToken?: Token;
}

export function BrokerSignUp({ invitationToken }: BrokerSignUpProps) {
    const { fields, submit, messages } = useForm(brokerSignUpForm, {
        invitationToken,
    });

    return (
        <FormLayout>
            <BoxLayout gap="48">
                <StackLayout gap="24">
                    <StatusMessageList messages={messages} status="warning" />
                    <Text style="heading 3">Create your account</Text>
                    <Form noValidate onSubmit={submit}>
                        <StackLayout gap="32">
                            <StackLayout gap="12">
                                <Form.Field
                                    label="First name"
                                    type="text"
                                    inputProps={{ ...fields.firstName.props }}
                                    messages={fields.firstName.messages}
                                />
                                <Form.Field
                                    label="Last name"
                                    type="text"
                                    inputProps={{ ...fields.lastName.props }}
                                    messages={fields.lastName.messages}
                                />
                                <Form.Field
                                    label="Password"
                                    type="password"
                                    inputProps={{
                                        ...fields.password.props,
                                        autoComplete: 'new-password',
                                        strengthMeter: true,
                                        note: 'Use a few words, avoid common phrases. No need for symbols, digits or uppercase letters',
                                    }}
                                    messages={fields.password.messages}
                                />
                            </StackLayout>
                            <Button size="regular" type="submit" onClick={submit}>
                                Create Account
                            </Button>
                        </StackLayout>
                    </Form>
                    <PrivacyMessage />
                </StackLayout>
            </BoxLayout>
        </FormLayout>
    );
}
