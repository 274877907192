import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { Settings } from '../../types/Settings';

/**
 * This repository is used to work with locations
 */
export interface LocationRepository {
    getSettings(): AsyncResult<Settings, OperationFailed | InvalidArgument>;
}

export const LocationRepository = Symbol('LocationRepository');
