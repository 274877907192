import { State } from '@embroker/shotwell/core/types/StateList';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import {
    Collapsible,
    Icon,
    InvoiceTable,
    StackLayout,
    Text,
    TextButton,
    Tooltip,
} from '@embroker/ui-toolkit/v2';
import React, { Fragment, useContext, useState } from 'react';
import { LPLQuote } from '../../../../quote/lpl/entities/LPLQuote';
import { hasRole } from '../../../../userOrg/entities/Session';
import { AppContext } from '../../../../view/AppContext';
import { CoverageBreakdownList } from '../../../view/components/CoverageBreakdownList.view';
import { BundleSummaryComponentProps } from '../../coverageDefinition';
import { USD } from '@embroker/shotwell/core/types/Money';

export function LPLQuoteSummary({
    questionnaireData,
    quote,
}: BundleSummaryComponentProps<LPLQuote>) {
    const [showCoverageItems, setShowCoverageItems] = useState<boolean>(false);
    const { activeSession } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');
    const caretName = !showCoverageItems ? 'caret-down' : 'caret-up';

    const coverageItems: { label: string; value: number }[] = [
        { label: 'Per Claim Limit', value: quote.options.perClaimLimit },
        { label: 'Aggregate Limit', value: quote.options.aggregateLimit },
        { label: 'Retention', value: quote.options.perClaimDeductible },
    ];

    const state = questionnaireData?.location_with_largest_number?.state ?? questionnaireData.state;

    return (
        <StackLayout gap="16">
            <InvoiceTable.Section>
                {quote.details.premium ? (
                    <React.Fragment>
                        <InvoiceTable.Item
                            style={{ alignItems: 'center' }}
                            title={
                                <React.Fragment>
                                    <Text
                                        style="body 1"
                                        as="span"
                                        data-e2e="coverage-list-lpl-title"
                                    >
                                        Lawyers Professional Liability
                                        <TextButton
                                            as={Icon}
                                            name={caretName}
                                            onClick={() => setShowCoverageItems(!showCoverageItems)}
                                        />
                                    </Text>
                                    <br />
                                    <Text style="body 2" as="span" data-e2e="lpl-insurer">
                                        Insurer: Everest National Insurance Company
                                    </Text>
                                </React.Fragment>
                            }
                        >
                            <Text style="body 1" as="span" data-e2e="lpl-premium">
                                <MoneyDisplay value={quote.details.premium} />
                            </Text>
                        </InvoiceTable.Item>
                        <InvoiceTable.Item
                            title={
                                <Text style="body 1" as="span">
                                    Taxes
                                </Text>
                            }
                        >
                            <Text style="body 1" as="span">
                                <MoneyDisplay value={USD(0)} />
                            </Text>
                        </InvoiceTable.Item>
                    </React.Fragment>
                ) : null}
                <Collapsible state={showCoverageItems ? 'open' : 'closed'}>
                    <CoverageBreakdownList coverageItems={coverageItems} />
                </Collapsible>
                {lplStateWithSurchargeTitleMap.has(state) && quote.fees ? (
                    <Fragment>
                        <InvoiceTable.Item />
                        <InvoiceTable.Item
                            title={
                                <Text style="body 1" as="span" data-e2e="lpl-surcharge-title">
                                    {lplStateWithSurchargeTitleMap.get(state)}
                                </Text>
                            }
                        >
                            <Text style="body 1" as="span" data-e2e="lpl-fees-value">
                                <MoneyDisplay value={quote.fees} />
                            </Text>
                        </InvoiceTable.Item>
                    </Fragment>
                ) : null}
                {isBroker ? (
                    quote.details.lplProcessingFee ? (
                        <InvoiceTable.Item
                            title={<React.Fragment>Embroker Access Fee</React.Fragment>}
                            data-e2e="access-fee"
                        >
                            <MoneyDisplay value={quote.details.lplProcessingFee} />
                        </InvoiceTable.Item>
                    ) : null
                ) : quote.annualTechnologyFee?.amount ? (
                    <InvoiceTable.Item
                        title={
                            <React.Fragment>
                                Annual technology fee{' '}
                                <Tooltip text="The Annual technology fee is applied once a year with the first policy purchase of the year." />
                            </React.Fragment>
                        }
                    >
                        <MoneyDisplay value={quote.annualTechnologyFee} />
                    </InvoiceTable.Item>
                ) : null}
            </InvoiceTable.Section>
            <InvoiceTable.Item
                title={
                    <Text style="heading 5" as="span" data-e2e="lpl-total">
                        Total Lawyers Professional Liability
                    </Text>
                }
            >
                <Text style="heading 5" as="span" data-e2e="lpl-total-payable-value">
                    <MoneyDisplay value={quote.totalPayable} />
                </Text>
            </InvoiceTable.Item>
        </StackLayout>
    );
}

const lplStateWithSurchargeTitleMap = new Map<State, string>([
    ['FL', 'Florida FIGA Surcharge'],
    ['NJ', 'New Jersey PLIGA Surcharge'],
    ['WV', 'West Virginia Fire & Casualty Surcharge'],
]);
