import { Immutable } from '@embroker/shotwell/core/types';
import { Accordion, Text, StackLayout } from '@embroker/ui-toolkit/v2';
import React, { useContext } from 'react';
import { hasRole } from '../../../userOrg/entities/Session';
import { AppContext } from '../../../view/AppContext';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { SupportPageLayout } from './SupportPageLayout.view';

interface FAQs {
    section: string;
    questions: QuestionItem[];
}

interface QuestionItem {
    title: string;
    content: React.ReactNode;
}

interface FAQSection {
    items: FAQs[];
}

export function FAQEmbroker() {
    const { navigate } = useNavigation();
    const { activeSession } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');

    const aboutEmbrokerSection: FAQs = {
        section: 'About Embroker',
        questions: [
            {
                title: 'What is Embroker?',
                content: (
                    <React.Fragment>
                        Embroker is an insurance brokerage for businesses. Our brokers are licensed
                        to sell insurance coverage to you, our customer. What sets us apart from
                        other insurance brokerages is our free online platform, which makes dealing
                        with insurance easier, faster and more cost-effective than ever before.
                    </React.Fragment>
                ),
            },
            {
                title: 'What kind of businesses do you provide coverage for?',
                content: (
                    <React.Fragment>
                        We provide insurance coverage solutions and expert risk-management
                        consultation to a wide range of business types and sizes. We are{' '}
                        <a
                            href="https://www.embroker.com/licenses/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            licensed
                        </a>{' '}
                        to sell insurance in all 50 U.S. states and the District of Columbia.
                    </React.Fragment>
                ),
            },
            {
                title: 'Who are the people behind Embroker?',
                content: (
                    <React.Fragment>
                        Embroker is powered by an experienced and stellar team of insurance brokers
                        and insurance industry executives, software engineers, product designers,
                        and marketers. We were founded in 2015 by our CEO, Matt Miller. Read more
                        about us{' '}
                        <a href="https://www.embroker.com/about/" target="_blank" rel="noreferrer">
                            here
                        </a>
                        .
                    </React.Fragment>
                ),
            },
        ],
    };

    const joinEmbrokerSection = {
        section: 'Joining Embroker',
        questions: [
            {
                title: 'How much does Embroker cost?',
                content: (
                    <React.Fragment>
                        The Embroker platform is free to use. If you purchase insurance through us,
                        we get paid a commission by the insurance carrier.
                        <br />
                        To learn more, read our full{' '}
                        <a
                            href="https://www.embroker.com/disclosure/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            compensation disclosure
                        </a>
                        .
                    </React.Fragment>
                ),
            },
            {
                title: 'I have policies with other insurance brokers. Can I still use Embroker?',
                content: (
                    <React.Fragment>
                        Yes, you are welcome to start an account, upload your policies, and take
                        advantage of basic coverage comparison data. To get the most out of
                        Embroker, we recommend you make us your broker by transferring your policies
                        to us and/or purchasing coverage.
                    </React.Fragment>
                ),
            },
            {
                title: 'Why should I make Embroker my broker?',
                content: (
                    <React.Fragment>
                        When you make Embroker your broker, you can easily share certificates,
                        schedule renewals, file claims, get a licensed expert to review potential
                        gaps in your coverage, and more — with the convenience of a secure online
                        platform.
                    </React.Fragment>
                ),
            },
            {
                title: 'How do I make Embroker my broker?',
                content: (
                    <React.Fragment>
                        We become your broker when you transfer your policies to us and/or purchase
                        coverage. Please note: Each insurance carrier processes broker of record
                        transfers differently. It may take only a few days, or possibly up to the
                        time of renewal, before Embroker can act on your behalf with the current
                        insurance carrier. We can provide more details on what to expect as well as
                        discuss with you details about your policies, specific coverages, a strategy
                        for your renewal, and any potential gaps in coverage.
                    </React.Fragment>
                ),
            },
        ],
    };

    const safetyAndSecuritySection = {
        section: 'Safety and Security',
        questions: [
            {
                title: 'How does Embroker keep my data and personal information safe?',
                content: (
                    <React.Fragment>
                        We care deeply about your privacy and use industry-standard security
                        measures, which includes Amazon S3 encryption, to keep your data safe and
                        secure. Your data is never shared with a third-party, and is only used to
                        help us make smarter and more cost-effective decision about your coverage.
                    </React.Fragment>
                ),
            },
        ],
    };

    const policiesAndCertificatesSection = {
        section: 'Policies and certificates',
        questions: [
            {
                title: 'Why would I upload my insurance policy?',
                content: (
                    <React.Fragment>
                        When you upload your policy to your Embroker account, we turn it into a
                        clean digital version which you can access at any time from your Policies
                        page. Having access to these paperless policies also lets you easily see
                        what you’re covered for, without the hassle of having to sift through policy
                        documents.
                    </React.Fragment>
                ),
            },
            {
                title: 'How do I transfer my policies to Embroker?',
                content: (
                    <React.Fragment>
                        It’s easy: You can start by uploading PDF versions of your policies on your
                        policies page. If you’re having issues, you can click the Support link to
                        have a broker help you.
                    </React.Fragment>
                ),
            },
            {
                title: 'How do I get a PDF version of my current insurance policy?',
                content: (
                    <React.Fragment>
                        You may already have access to a PDF version of your policy from your
                        current broker. If not, you can scan your paper policy and upload it from
                        your Policies page. Alternatively, you can upload a PDF version of your
                        coverage certificate or binder. If you purchase coverage directly from us,
                        your PDF policy will be automatically added to your Policies page.
                    </React.Fragment>
                ),
            },
            {
                title: 'What happens when I upload a policy?',
                content: (
                    <React.Fragment>
                        We extract data from the PDF and create a new digital version that’s much
                        easier to view. Within 24 hours of uploading, we’ll create a snapshot
                        summary of your policy. A full-text version of your policy is created within
                        48 hours of uploading.
                    </React.Fragment>
                ),
            },
            {
                title: 'How do I send you my policy?',
                content: (
                    <React.Fragment>
                        It’s easy. Here are your options:
                        <br />
                        <div className="emB-additionalPadding-left">
                            {' '}
                            - <b>Text a photo</b> of the first page to 800-867-5309. Data and
                            messaging rates may apply.
                        </div>
                        <div className="emB-additionalPadding-left">
                            {' '}
                            - <b>Email us</b> the policy as an image or PDF attachment to{' '}
                            <a href="mailto:policies@embroker.com">policies@embroker</a>{' '}
                        </div>
                        <div className="emB-additionalPadding-left">
                            {' '}
                            - <b>Upload your policy PDF</b> to your{' '}
                            <a
                                onClick={() => {
                                    navigate('/policies');
                                }}
                            >
                                Policies
                            </a>{' '}
                        </div>
                        The digital version of your policy will be available on your{' '}
                        <a
                            onClick={() => {
                                navigate('/policies');
                            }}
                        >
                            Policies
                        </a>{' '}
                        page within 48 hours.
                    </React.Fragment>
                ),
            },
            {
                title: 'How do I send a certificate for an expired policy?',
                content: (
                    <React.Fragment>
                        Please contact us for help with expired policies.
                    </React.Fragment>
                ),
            },
            {
                title: 'How can I make changes to my policy?',
                content: (
                    <React.Fragment>
                        You can{' '}
                        <a
                            onClick={() => {
                                navigate('/policies');
                            }}
                        >
                            request a change
                        </a>{' '}
                        to your business by filling out the request a change form.
                    </React.Fragment>
                ),
            },
        ],
    };

    const claimsSection = {
        section: 'Claims',
        questions: [
            {
                title: 'What information do I need to report my claim?',
                content: (
                    <React.Fragment>
                        <li>Name and contact information of everyone involved in the loss</li>
                        <li>Your policy information</li>
                        <li>
                            The type of loss you are reporting (auto, property, workers compensation
                            and etc.)
                        </li>
                        <li>When and how the loss occurred</li>
                        <li>Description of any injuries or damages</li>
                    </React.Fragment>
                ),
            },
            {
                title: "I don't have all the information needed to report my claim. What should I do?",
                content: (
                    <React.Fragment>
                        You should file your claim as soon as possible. You will be able to add and
                        update your information later.
                    </React.Fragment>
                ),
            },
            {
                title: 'What happens after I report my claim?',
                content: (
                    <React.Fragment>
                        If you file online, you should receive confirmation that you've filed and an
                        update when the filing is done or if further information is needed. If you
                        filed on the phone you may be called with updates or a request for follow up
                        information. Auto claims or property claims usually require a representative
                        to examine the damage in person.
                    </React.Fragment>
                ),
            },
            {
                title: 'How long will it take to settle my claim?',
                content: (
                    <React.Fragment>
                        Time varies depending on the type of the loss, and how long it takes to
                        investigate your claim.
                    </React.Fragment>
                ),
            },
            {
                title: 'When can I expect payment?',
                content: (
                    <React.Fragment>
                        After all the facts about the loss are reviewed, and it is determined that
                        your loss is covered by your insurance policy, a check can be issued to
                        cover damages as estimated by your claims professional. Damaged property and
                        vehicles usually require an in-person inspection. A check can be issued
                        immediately or soon after the inspection.
                    </React.Fragment>
                ),
            },
        ],
    };

    const multipleCompaniesSection = {
        section: 'Managing Multiple Companies',
        questions: [
            {
                title: 'I manage insurance for more than one company. Can I add multiple companies to my account?',
                content: (
                    <React.Fragment>
                        Yes, here’s how:
                        <div className="emB-additionalPadding-left">
                            {' '}
                            - go to your &lt;Your company profile&gt; menu
                        </div>
                        <div className="emB-additionalPadding-left">
                            {' '}
                            - click{' '}
                            <a
                                onClick={() => {
                                    navigate('/user/switch-companies');
                                }}
                            >
                                Settings
                            </a>{' '}
                            then Add a new company
                        </div>
                        <div className="emB-additionalPadding-left">
                            {' '}
                            - follow prompts to add the new company.
                        </div>
                        To switch to a different company profile within your account, click the
                        Switch company button. <br />
                        Please note: your Embroker account is limited to one login email address,
                        regardless of how many companies you manage.
                    </React.Fragment>
                ),
            },
            {
                title: 'How do I remove a company from my account?',
                content: (
                    <React.Fragment>
                        At the moment, you will need to{' '}
                        <a
                            onClick={() => {
                                navigate('/support/contact');
                            }}
                        >
                            contact us
                        </a>{' '}
                        to remove a company from your account.
                    </React.Fragment>
                ),
            },
        ],
    };

    const userManagementSection = {
        section: 'User Management',
        questions: [
            {
                title: 'How do I add team members to my company?',
                content: (
                    <React.Fragment>
                        Adding team members is the most secure and convenient way to share access to
                        your company’s insurance coverages. To add a team member, log into your
                        Embroker account and navigate to the{' '}
                        <a
                            onClick={() => {
                                navigate('/user/manage-users');
                            }}
                        >
                            Team settings
                        </a>{' '}
                        page from the upper right navigation near your company name. Invite new team
                        members using their email address, separating each one with a comma. <br />{' '}
                        Please note: Invitations expire after 14 days. Also, invited team members
                        cannot invite other team members. You can{' '}
                        <a
                            onClick={() => {
                                navigate('/support/contact');
                            }}
                        >
                            contact us
                        </a>{' '}
                        to have this changed.
                    </React.Fragment>
                ),
            },
            {
                title: 'How do I control email notifications?',
                content: (
                    <React.Fragment>
                        Notifications help you stay on top of activity within your account. To
                        control notifications, please{' '}
                        <a
                            onClick={() => {
                                navigate('/support/contact');
                            }}
                        >
                            contact us
                        </a>{' '}
                        and we’ll help you turn them on or off.
                    </React.Fragment>
                ),
            },
        ],
    };

    const purchaseCoverageSection = {
        section: 'Purchasing Coverage',
        questions: [
            {
                title: 'Can I purchase insurance coverage for my business through Embroker?',
                content: (
                    <React.Fragment>
                        Yes. You can{' '}
                        <a
                            onClick={() => {
                                navigate('/shopping');
                                console.log('This page need to be implemented');
                            }}
                        >
                            purchase coverage here
                        </a>
                        .
                    </React.Fragment>
                ),
            },
            {
                title: 'How long does it take to get coverage?',
                content: (
                    <React.Fragment>
                        You can have coverage for your business as early as the same day of
                        purchase, up to 1-2 weeks. Coverage timing depends on complexity of your
                        business and coverage needs.
                    </React.Fragment>
                ),
            },
            {
                title: "How do I know I have the right coverage for my business? / How do I know I'm not over or underpaying for coverage?",
                content: (
                    <React.Fragment>
                        From your account dashboard, you can view coverage and premium comparison
                        data for companies similar to yours. You can also connect with a broker at
                        any time to get help identifying your coverage gaps.
                    </React.Fragment>
                ),
            },
            {
                title: 'What are my payment options, in terms of method and timing?',
                content: (
                    <React.Fragment>
                        Presently, we accept checks as the method of payment. We are currently
                        working on offering ACH and credit card payment options. Our brokers will
                        work with you on a customized payment schedule.
                    </React.Fragment>
                ),
            },
            {
                title: 'What happens if I leave Embroker in the future?',
                content: (
                    <React.Fragment>
                        Nothing, except we’d be sad to see you go! Your data and account settings
                        will stay the way you left them should you decide to come back.
                    </React.Fragment>
                ),
            },
            {
                title: 'Where can I leave suggestions for your product?',
                content: (
                    <React.Fragment>
                        We’re breaking new ground in an exciting time for the insurance and
                        financial industries and would love to hear your suggestions. Please send an
                        email to <a href="mailto:hello@embroker.com">hello@embroker.com</a>.
                    </React.Fragment>
                ),
            },
        ],
    };

    const FAQs: Array<FAQs> = [aboutEmbrokerSection, joinEmbrokerSection, safetyAndSecuritySection];

    if (!isBroker) {
        FAQs.push(
            policiesAndCertificatesSection,
            claimsSection,
            multipleCompaniesSection,
            userManagementSection,
        );
    }

    FAQs.push(purchaseCoverageSection);

    return (
        <SupportPageLayout>
            <StackLayout gap="48">
                <div />
                <FAQSection items={FAQs} />
            </StackLayout>
        </SupportPageLayout>
    );
}

const FAQSection = ({ items }: FAQSection) => {
    const renderItems = items.map((item: Immutable<FAQs>, itemIndex: number) => {
        const accords = item.questions.map(
            (question: Immutable<QuestionItem>, questionIndex: number) => {
                return (
                    <Accordion key={itemIndex + questionIndex}>
                        <Accordion.Item title={question.title}>{question.content}</Accordion.Item>
                    </Accordion>
                );
            },
        );
        return (
            <StackLayout gap="32" key={itemIndex}>
                <Text style="heading 3">{item.section}</Text>
                <StackLayout gap="12">{accords}</StackLayout>
            </StackLayout>
        );
    });
    return <StackLayout gap="48">{renderItems}</StackLayout>;
};
