import { FormElement } from '@embroker/service-app-engine';
import { Nullable } from '@embroker/shotwell/core/types';
import { ErrorLike, isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Spinner } from '@embroker/ui-toolkit/v2';
import React, { useEffect, useState } from 'react';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { GetClientApplicationFormEngine } from '../../../useCases/GetClientApplicationFormEngine';
import { navigateToMatchingErrorPage } from './clientApplicationEdit/EditApplication';
import { ShareableApplicationForm } from './ShareableApplicationForm';

interface ShareableApplicationProps {
    token: string;
}

export function ShareableApplication({ token }: ShareableApplicationProps): JSX.Element {
    const { navigate } = useNavigation();
    const [formEngine, setFormEngine] = useState<Nullable<FormElement>>(null);
    const [isRenewal, setIsRenewal] = useState<boolean>();
    const [isInCreation, setIsInCreation] = useState<boolean>();
    const [initialPage, setInitialPage] = useState<string>('basic_info');

    const { reload: reloadApplication, result: formEngineResult } = useUseCase(
        GetClientApplicationFormEngine,
        {
            token,
            submitText: 'Finish',
        },
    );

    useEffect(() => {
        setFormEngine(null);
    }, [token]);

    useEffect(() => {
        if (formEngineResult && isOK(formEngineResult)) {
            setFormEngine(formEngineResult.value.formEngine);
            setIsRenewal(formEngineResult.value.isRenewal);
            setIsInCreation(formEngineResult.value.isInCreation);
        }
    }, [formEngineResult]);

    useEffect(() => {
        if (formEngineResult && isErr(formEngineResult)) {
            navigateToMatchingErrorPage(navigate, (formEngineResult.errors[0] as ErrorLike).code);
        }
    }, [navigate, formEngineResult]);

    useEffect(() => {
        if (formEngine && isInCreation) {
            formEngine.machine.update({
                submitText: 'Save and Continue',
            });
        }
    }, [formEngine, isInCreation]);

    useEffect(() => {
        if (isInCreation === false) {
            setInitialPage('additional_company_details');
        }
    }, [isInCreation]);

    useEffect(() => {
        if (formEngine) {
            return () => {
                formEngine.dispose();
            };
        }
    }, [formEngine]);

    if (!formEngine || isInCreation === undefined) {
        return <Spinner />;
    }

    return (
        <ShareableApplicationForm
            token={token}
            pageId={initialPage}
            formEngine={formEngine}
            isRenewal={isRenewal}
            isInCreation={isInCreation}
            reloadApplication={reloadApplication}
        />
    );
}
