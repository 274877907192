import { inject } from '@embroker/shotwell/core/di';
import { JSONSerdes } from '@embroker/shotwell/core/encoding';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, Failure, isErr } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import {
    ApplicationNotFound,
    NameClearanceError,
    OperationNotAllowed,
} from '../../shopping/errors';
import { ApplicationRepository } from '../../shopping/repositories/ApplicationRepository';
import { FilledQuestionnaireData } from '../../shopping/types/QuestionnaireData';

export interface PromoteShareableApplicationRequest {
    token: string;
    questionnaireData: Immutable<FilledQuestionnaireData>;
}

export interface PromoteShareableApplication extends UseCase {
    execute(
        request: PromoteShareableApplicationRequest,
    ): AsyncResult<
        void,
        | OperationFailed
        | InvalidArgument
        | ApplicationNotFound
        | OperationNotAllowed
        | NameClearanceError
    >;
}

class PromoteShareableApplicationUseCase extends UseCase implements PromoteShareableApplication {
    public static type = Symbol('Shopping/PromoteShareableApplication');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
    ) {
        super(eventBus);
    }
    public async execute({
        token,
        questionnaireData,
    }: PromoteShareableApplicationRequest): AsyncResult<
        void,
        | OperationNotAllowed
        | OperationFailed
        | InvalidArgument
        | ApplicationNotFound
        | NameClearanceError
    > {
        const questionnaireDataResult = JSONSerdes.serialize(questionnaireData);
        if (isErr(questionnaireDataResult)) {
            return Failure(
                InvalidArgument({ argument: 'questionnaireData', value: questionnaireData }),
            );
        }

        return await this.applicationRepository.promoteShareableApplication(
            token,
            questionnaireDataResult.value,
        );
    }
}

export const PromoteShareableApplication: UseCaseClass<PromoteShareableApplication> =
    PromoteShareableApplicationUseCase;
