import { Joi } from '@embroker/shotwell/core/validation/schema';
import { Success } from '@embroker/shotwell/core/types/Result';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    Button,
    ButtonBar,
    Form,
    FormLayout,
    SidebarLayout,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React, { useMemo } from 'react';
import { BusinessInfoStepGuidelines } from './BusinessInfoStepGuidelines';

export interface EmailToThemStepData {
    directlyToThem: boolean;
}

function createEmailToThemStepForm(onNext: (data: EmailToThemStepData) => void) {
    return createForm<EmailToThemStepData>({
        fields: {
            directlyToThem: {
                type: 'radioGroup',
                validator: Joi.boolean().required().valid(true, false),
                formatValidationError() {
                    return 'Please specify should we email the certificate to the certificate holder.';
                },
            },
        },
        async submit(data) {
            onNext(data);
            return Success();
        },
    });
}

interface EmailToThemStepProps {
    initialData?: Partial<EmailToThemStepData>;
    businessName: string;
    onNext(data: EmailToThemStepData): void;
    onBack(): void;
}

export function EmailToThemStep({
    initialData,
    businessName,
    onNext,
    onBack,
}: EmailToThemStepProps) {
    const emailToThemStepForm = useMemo(() => createEmailToThemStepForm(onNext), [onNext]);
    const { fields, submit } = useForm(emailToThemStepForm, initialData);

    return (
        <SidebarLayout appearance="default" sidebarVariant="compact">
            <BusinessInfoStepGuidelines></BusinessInfoStepGuidelines>
            <StackLayout>
                <FormLayout appearance="wide">
                    <StackLayout gap="48">
                        <StackLayout gap="24">
                            <Text style={'heading 5'} data-e2e="email-to-them-questionnaire">
                                Would you like us to email the certificate directly to{' '}
                                {businessName}?
                            </Text>
                            <Form>
                                <StackLayout>
                                    <Form.Field
                                        direction="horizontal"
                                        inputProps={{
                                            ...fields.directlyToThem.props,
                                            items: [
                                                {
                                                    value: true,
                                                    title: 'Yes',
                                                },
                                                {
                                                    value: false,
                                                    title: 'No',
                                                },
                                            ],
                                        }}
                                        messages={fields.directlyToThem.messages}
                                        type={fields.directlyToThem.type}
                                        data-e2e="email-to-them-wrapper"
                                    />
                                </StackLayout>
                            </Form>
                        </StackLayout>
                        <ButtonBar
                            split={'-1'}
                            reverse
                            responsive={{ containerWidth: { smallerThan: 400 } }}
                        >
                            <TextButton onClick={onBack} data-e2e="email-to-them-back">
                                Back
                            </TextButton>
                            <Button onClick={submit} data-e2e="email-to-them-submit">
                                Next
                            </Button>
                        </ButtonBar>
                    </StackLayout>
                </FormLayout>
                <SidebarLayout.MobileFooter split="-1">
                    <Text>What does this mean?</Text>
                    <SidebarLayout.Link appearance="secondary" panelIndex={1}>
                        Let us explain
                    </SidebarLayout.Link>
                </SidebarLayout.MobileFooter>
            </StackLayout>
        </SidebarLayout>
    );
}
