import React, { useState, useEffect } from 'react';
import { Box, StackLayout, StatusMessage, Text } from '@embroker/ui-toolkit/v2';
import { execute } from '@embroker/shotwell/core/UseCase';
import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { ExpandedApplicationView } from '../../../../shopping/useCases/GetApplication';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { ShareableApplicationLink } from '../shareableApplications/ShareableAppLink';
import { GetApplicationShareToken } from '../../../../shopping/useCases/GetApplicationShareToken';
import { Intake } from '../../../../shopping/types/enums';

interface BrokerApplicationInfoBarProps {
    application?: Immutable<ExpandedApplicationView>;
}

export const BrokerApplicationInfoBar = ({ application }: BrokerApplicationInfoBarProps) => {
    const [shareToken, setShareToken] = useState<Nullable<string>>(null);
    const [isInfoBarDismissed, setInfoBarDismissed] = useState(false);

    const showDisplayInfoBar =
        application?.creationType == Intake &&
        application?.intakeTaskId != null &&
        !isInfoBarDismissed;

    const shareTokenRequired =
        application && application?.appType !== 'AppTypeCodeListEmbrokerExcess';

    useEffect(() => {
        if (shareTokenRequired) {
            let isCanceled = false;
            execute(GetApplicationShareToken, { applicationId: application.id }).then((result) => {
                if (isCanceled) {
                    return;
                }
                if (result && !isErr(result)) {
                    setShareToken(result.value.shareToken);
                }
                if (isErr(result)) {
                    container.get<Logger>(Log).error(result);
                }
            });
            return () => {
                isCanceled = true;
            };
        }
    }, [application, shareTokenRequired]);

    const dismissInfoBar = () => {
        setInfoBarDismissed(true);
    };

    return (
        <Box gap="24 56" maxWidth={424} border={false}>
            <StackLayout gap="32">
                {showDisplayInfoBar && (
                    <StatusMessage status="info" onDismiss={dismissInfoBar}>
                        <Text style="microcopy">
                            Please review all information pulled from the PDF for accuracy.
                        </Text>
                    </StatusMessage>
                )}
                {shareTokenRequired && shareToken && (
                    <ShareableApplicationLink
                        token={shareToken}
                        isSubmittedExternally={application?.isSubmittedExternally}
                        isRenewal={application?.isRenewal ?? false}
                    />
                )}
            </StackLayout>
        </Box>
    );
};
