import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { BrokerOrganization, BrokerRepository } from '../repositories';

export interface GetBrokerOrganizationsParams {
    readonly broker?: UUID;
    readonly filter?: string;
    readonly count?: number;
}

export interface GetBrokerOrganizations extends UseCase {
    execute(
        request?: GetBrokerOrganizationsParams,
    ): AsyncResult<BrokerOrganization[], InvalidArgument | OperationFailed>;
}

@injectable()
class GetBrokerOrganizationsUseCase extends UseCase implements GetBrokerOrganizations {
    public static type = Symbol('Broker/GetBrokerOrganizations');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BrokerRepository) private brokerRepository: BrokerRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        broker,
        filter,
        count,
    }: GetBrokerOrganizationsParams): AsyncResult<
        BrokerOrganization[],
        InvalidArgument | OperationFailed
    > {
        return this.brokerRepository.getBrokerOrganizations(broker, filter, count);
    }
}

export const GetBrokerOrganizations: UseCaseClass<GetBrokerOrganizations> =
    GetBrokerOrganizationsUseCase;
