import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Immutable } from '@embroker/shotwell/core/types';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Nullable, useModal } from '@embroker/ui-toolkit/v2';
import React, { useEffect } from 'react';
import { Application } from '../../../../shopping/entities/Application';
import { GetApplication } from '../../../../shopping/useCases/GetApplication';
import { GetOrganizationProfile } from '../../../../userOrg/useCases/GetOrganizationProfile';
import { ConfirmationModal } from './ConfirmationModal';

interface BrokerConfirmationModalProps {
    organizationId: UUID;
    applicationId: UUID;
}

export function BrokerConfirmationModal({
    organizationId,
    applicationId,
}: BrokerConfirmationModalProps) {
    const coverageConfirmationModal = useModal();
    const { show: showCoverageConfirmation } = coverageConfirmationModal;

    const { result: getApplicationResult } = useUseCase(GetApplication, { applicationId });
    const { result: getOrganizationProfileResult } = useUseCase(GetOrganizationProfile, {
        organizationId,
    });

    let application: Nullable<Immutable<EntityProps<Application>>> = null;
    let organizationName;
    if (getApplicationResult !== undefined && isOK(getApplicationResult)) {
        application = getApplicationResult.value.application;
    }
    if (getOrganizationProfileResult !== undefined && isOK(getOrganizationProfileResult)) {
        organizationName = getOrganizationProfileResult.value.organization.companyLegalName;
    }

    useEffect(() => {
        if (application != null) {
            showCoverageConfirmation();
        }
    }, [application, showCoverageConfirmation]);

    return organizationName && application ? (
        <ConfirmationModal
            modal={coverageConfirmationModal}
            organizationName={organizationName}
            organizationId={organizationId}
            application={application}
        />
    ) : null;
}
