import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface CNAWarnings {
    buildingLimitWarning?: string;
    hiredNonOwnedAutoLimitWarning?: string;
}

export const CNAWarnings = {
    ...defineValidator<CNAWarnings>({
        buildingLimitWarning: Joi.string().optional(),
        hiredNonOwnedAutoLimitWarning: Joi.string().optional(),
    }),
    create(cnaWarnings: CNAWarnings) {
        return CNAWarnings.validate(cnaWarnings);
    },
};
