import { Money } from '@embroker/shotwell/core/types/Money';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface Coverage {
    /**
     * Coverage Limit
     */
    readonly limit: Money;
    /**
     * Coverage Second Limit
     */
    readonly secondLimit?: Money;
    /**
     * Coverage Previous Limit
     */
    readonly previousLimit: Money;
    /**
     * Coverage Previous Second Limit
     */
    readonly previousSecondLimit?: Money;
    /**
     * Coverage Retention
     */
    readonly retention: Money;
    /**
     * Coverage Second Retention
     */
    readonly secondRetention?: Money;
    /**
     * Coverage Previous Retention
     */
    readonly previousRetention: Money;
    /**
     * Coverage Previous Second Retention
     */
    readonly previousSecondRetention?: Money;
    /**
     * Is Coverage Selected
     */
    readonly isSelected: boolean;
    /**
     * Coverage level
     */
    readonly level?: 'plus' | 'standard';
    /**
     * Coverage Premium
     */
    readonly premium: Money;
    /**
     * Coverage Previous Premium
     */
    readonly previousPremium: Money;
    /**
     * Coverage Type
     */
    readonly type: 'dno' | 'epli' | 'eo' | 'fiduciary' | 'techCyber';
}

export const Coverage = {
    ...defineValidator<Coverage>({
        limit: Money.schema.required(),
        secondLimit: Money.schema.optional(),
        previousLimit: Money.schema.required(),
        previousSecondLimit: Money.schema.optional(),
        retention: Money.schema.required(),
        secondRetention: Money.schema.optional(),
        previousRetention: Money.schema.required(),
        previousSecondRetention: Money.schema.optional(),
        isSelected: Joi.bool().required(),
        level: Joi.string().valid('plus', 'standard').optional(),
        premium: Money.schema.required(),
        previousPremium: Money.schema.required(),
        type: Joi.string(),
    }),
    create(coverage: Coverage) {
        return Coverage.validate(coverage);
    },
};
