import { Modal, ModalActions, ModalState, Text } from '@embroker/ui-toolkit/v2';
import { hasRole } from '../../../userOrg/entities/Session';
import { AppContext } from '../../../view/AppContext';
import React, { useContext } from 'react';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { ModalLayout } from '../../../view/components/ModalLayout.view';
import { Link } from 'react-navi';

interface CanceledParentPolicyErrorModalInput {
    modal: ModalState & ModalActions;
}

export function CanceledParentPolicyErrorModal({ modal }: CanceledParentPolicyErrorModalInput) {
    const { navigate } = useNavigation();
    const { activeSession } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');

    const goToDashboard = isBroker
        ? () => navigate('/broker/dashboard')
        : () => navigate('/summary');

    return (
        <Modal {...modal} size="small" dismissable={false}>
            <ModalLayout
                primaryAction={{
                    label: 'Back to Dashboard',
                    onClick: goToDashboard,
                }}
            >
                <Text>
                    Looks like your current policy has been canceled and we cannot offer the renewal
                    quote. Please contact our <Link href="/support/contact">support team</Link>.
                </Text>
            </ModalLayout>
        </Modal>
    );
}
