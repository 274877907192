import { ErrorLike, isErr } from '@embroker/shotwell/core/types/Result';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { PageLayout, Spinner, StackLayout, TextButton } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { Link } from 'react-navi';
import { ErrorCode } from '../../../errors';
import { GetBrokerageOutstandingPayments } from '../../../useCases/GetBrokerageOutstandingPayments';
import { PaymentsTable } from './PaymentsTable';
import { UnavailablePage } from './UnavailablePage.view';

export function BrokerUnpaidBalances() {
    const { result } = useUseCase(GetBrokerageOutstandingPayments);

    if (result === undefined) {
        return <Spinner />;
    }

    if (isErr(result)) {
        if ((result.errors[0] as ErrorLike).code === ErrorCode.ServiceNotAvailable) {
            return <UnavailablePage />;
        } else {
            return <ErrorPage errors={result.errors} />;
        }
    }

    return (
        <PageLayout.Section>
            <StackLayout>
                <TextButton
                    size="small"
                    as={Link}
                    icon="bold-caret-left"
                    iconPosition="left"
                    href="/broker/dashboard"
                >
                    Back to dashboard
                </TextButton>
                <PaymentsTable tableData={result.value} />
            </StackLayout>
        </PageLayout.Section>
    );
}
