import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';

export interface RequestNameOrAddressChangeEndorsementRequest {
    readonly policyId: UUID;
    readonly effectiveDate: Date;
    readonly insuredName: string;
    readonly reasonForChange: string;
    readonly reasonForChangeAdditionalDetails?: string;
    readonly address: string;
    readonly city: string;
    readonly state: string;
    readonly zip: string;
}

export interface EndorsementIntakeRepository {
    requestNameOrAddressChangeEndorsement(
        request: RequestNameOrAddressChangeEndorsementRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed>;

    createOtherEndorsement(
        effectiveDate: Date,
        requestedChangeMessage: string,
        policyId: UUID,
    ): AsyncResult<void, InvalidArgument | OperationFailed>;
}

export const EndorsementIntakeRepository = Symbol('EndorsementIntakeRepository');
