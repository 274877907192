import React, { FC } from 'react';
import { LPLQuoteInfo } from '../../lpl/types/LPLQuoteInfo';

function getDisplayName(Component: FC<any>): string {
    return Component.displayName || Component.name || 'Component';
}

export interface withQuoteInfoProps {
    lplQuoteInfo: LPLQuoteInfo;
    today: Date;
    referredQuote: boolean;
}

export interface withConfirmationModalShowProps {
    showConfirmationModal: () => void;
}

export interface withReferredNavigationProps {
    onReferredCTAButtonClick: () => void;
    referredCTAButtonLabel: string;
}

export function withExtraProps<I, P extends I>(Component: FC<P>, extraInfo: I) {
    const WithQuoteInfo = (props: Omit<P, keyof I>) => {
        return <Component {...({ ...props, ...extraInfo } as P & JSX.IntrinsicAttributes)} />;
    };
    WithQuoteInfo.displayName = `WithExtraProps(${getDisplayName(Component)})`;
    return WithQuoteInfo;
}
