import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { BundleQuote } from '../entities/BundleQuote';
import { BundleQuoteRepository } from '../repositories';

export interface GetLastBundleQuoteRequest {
    applicationId: UUID;
}

export interface GetLastBundleQuote extends UseCase {
    execute(
        request: GetLastBundleQuoteRequest,
    ): AsyncResult<BundleQuote, InvalidArgument | OperationFailed>;
}

@injectable()
class GetLastBundleQuoteUseCase extends UseCase implements GetLastBundleQuote {
    public static type = Symbol('BundleQuote/GetLastBundleQuote');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BundleQuoteRepository) private bundleQuoteRepository: BundleQuoteRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
    }: GetLastBundleQuoteRequest): AsyncResult<BundleQuote, InvalidArgument | OperationFailed> {
        const getLastQuoteResult = await this.bundleQuoteRepository.getLastBundleQuote(
            applicationId,
        );

        if (isErr(getLastQuoteResult)) {
            return getLastQuoteResult;
        }

        return getLastQuoteResult;
    }
}

export const GetLastBundleQuote: UseCaseClass<GetLastBundleQuote> = GetLastBundleQuoteUseCase;
