import type * as APIType from '@embroker/shotwell-api/app';
import { API } from '@embroker/shotwell-api/app';
import { isAPIError } from '@embroker/shotwell-api/errors';
import { inject, injectable } from '@embroker/shotwell/core/di';
import {
    InvalidArgument,
    NotImplemented,
    OperationFailed,
    UnknownEntity,
} from '@embroker/shotwell/core/Error';
import { findDomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { Nullable } from '@embroker/shotwell/core/types';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Result,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { SignUpRequest, SignUpResponse, UserRepository } from '.';
import { isAuthenticated } from '../../entities/Session';
import { User } from '../../entities/User';
import {
    CheckTokenValidityError,
    EmailAlreadyInUse,
    InvalidEmail,
    MaxNumberOfSignUpAttemptsExceeded,
    Unauthenticated,
} from '../../errors';
import { SessionRepository } from '../SessionRepository';
import { APIOrganizationRepository } from '../OrganizationRepository/APIOrganizationRepository';

interface Invitation {
    token: UUID;
    accepted: boolean;
}

interface UserData {
    user: Nullable<User>;
    userResponseData: Nullable<APIType.UserAccount>;
    invitation: Nullable<Invitation>;
}

const INVALID_EMAIL_ERROR_CODE = 'invalid_email';
const EMAIL_EXISTS_ERROR_CODE = 'email_exists';
const MAXIMUM_NUMBER_OF_ATTEMPTS_ERROR_CODE = 'max_number_of_attempts';

@injectable()
export class APIUserRepository implements UserRepository {
    private readonly userData: UserData;
    private isUserLoggedInBySignup: boolean;

    constructor(@inject(SessionRepository) private sessionRepo: SessionRepository) {
        this.userData = {
            user: null,
            userResponseData: null,
            invitation: null,
        };
        this.isUserLoggedInBySignup = false;
    }

    public async getActiveUser(): AsyncResult<
        User,
        Unauthenticated | InvalidArgument | OperationFailed | UnknownEntity
    > {
        const session = await this.sessionRepo.getActiveSession();
        if (!isAuthenticated(session.value)) {
            return Failure(Unauthenticated());
        }

        const result = await API.request('user/get');

        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        const userAccount = result.value.user;
        const userObject = {
            id: userAccount.id,
            firstName: userAccount.first_name || null,
            lastName: userAccount.last_name || null,
            title: userAccount.prefix_title,
            phoneNumber: userAccount.phone,
            email: userAccount.email as EmailAddress,
            signUpInviteToken: null,
            password: null,
            passwordResetToken: null,
            certificateInviteToken: null,
            oldPassword: null,
            createdAt: userAccount.created_at,
            isUserLoggedInBySignup: this.isUserLoggedInBySignup,
            isBroker: userAccount.is_broker,
        };
        const resultUser = await User.create(userObject);

        if (isErr(resultUser)) {
            return handleOperationFailure(resultUser);
        }
        this.userData.user = resultUser.value;
        this.userData.userResponseData = userAccount;

        return Success(resultUser.value);
    }

    public async getUser(userId: UUID): AsyncResult<User, UnknownEntity | NotImplemented> {
        // Until our API starts supporting user fetch by id
        return Failure(NotImplemented());
    }

    public async save(
        user: User,
    ): AsyncResult<
        void,
        | InvalidArgument
        | OperationFailed
        | EmailAlreadyInUse
        | InvalidEmail
        | MaxNumberOfSignUpAttemptsExceeded
    > {
        const updatedEvent = findDomainEvent(user.events, 'User', 'Updated');
        const passwordUpdateEvent = findDomainEvent(user.events, 'User', 'PasswordUpdated');
        const forgottenPasswordEvent = findDomainEvent(user.events, 'User', 'ForgottenPassword');
        const setPasswordEvent = findDomainEvent(user.events, 'User', 'PasswordSet');
        const resetPasswordEvent = findDomainEvent(user.events, 'User', 'PasswordReset');

        // update user
        if (updatedEvent !== undefined) {
            const updateExistingResult = await this.updateExisting(user);

            if (isErr(updateExistingResult)) {
                return updateExistingResult;
            }

            return Success();
        }

        // update password
        if (passwordUpdateEvent !== undefined) {
            const updatePasswordResult = await this.updatePassword(user);

            if (isErr(updatePasswordResult)) {
                return updatePasswordResult;
            }

            return Success();
        }

        // set password
        if (setPasswordEvent !== undefined) {
            const setPasswordResult = await this.setPassword(user);

            if (isErr(setPasswordResult)) {
                return setPasswordResult;
            }

            return Success();
        }

        // send forgot email
        if (forgottenPasswordEvent !== undefined) {
            const forgottenPasswordEvent = await this.forgottenPassword(user);

            if (isErr(forgottenPasswordEvent)) {
                return forgottenPasswordEvent;
            }

            return Success();
        }

        // new password
        if (resetPasswordEvent !== undefined) {
            const resetPasswordResult = await this.resetPassword(user);

            if (isErr(resetPasswordResult)) {
                return resetPasswordResult;
            }

            return Success();
        }

        return Failure(OperationFailed({ message: 'Invalid save request' }));
    }

    public async signUp(
        input: SignUpRequest,
    ): AsyncResult<
        SignUpResponse,
        | InvalidArgument
        | OperationFailed
        | EmailAlreadyInUse
        | InvalidEmail
        | MaxNumberOfSignUpAttemptsExceeded
    > {
        this.isUserLoggedInBySignup = true;

        const headquartersMailingAddress = input.headquarters
            ? APIOrganizationRepository.marshalHeadquarterLocationForSignup(input.headquarters)
            : {};

        const signUpResponseResult = await API.request('user/sign_up', {
            first_name: input.user.firstName ?? undefined,
            last_name: input.user.lastName ?? undefined,
            company_name: input.organizationName,
            email: input.user.email ?? undefined,
            pwd: input.user.password ?? undefined,
            third_party_sign_up_token: input.user.certificateInviteToken ?? undefined,
            user_invite_sign_up_token: input.user.signUpInviteToken ?? undefined,
            how_did_you_hear_about_embroker: input.howDidYouHearAboutEmbroker,
            company_website: input.website,
            company_naics_code: input.naicsCode,
            company_has_raised_vc_funding: input.hasReceivedVCFunding,
            company_has_automobiles: input.hasAutomobiles,
            company_number_range_of_w2_employees: input.numberRangeOfW2Employees,
            company_has_revenue_larger_than_20_million:
                input.isTotalRevenueLargerThan20MillionDollars,
            ...headquartersMailingAddress,
        });
        if (isErr(signUpResponseResult)) {
            for (const error of signUpResponseResult.errors) {
                if (
                    isAPIError(error) &&
                    error.details.name === EMAIL_EXISTS_ERROR_CODE &&
                    input.user.email !== null
                ) {
                    return Failure(EmailAlreadyInUse(input.user.email));
                }
                if (
                    isAPIError(error) &&
                    error.details.name === MAXIMUM_NUMBER_OF_ATTEMPTS_ERROR_CODE
                ) {
                    return Failure(MaxNumberOfSignUpAttemptsExceeded());
                }
            }
            return handleOperationFailure(signUpResponseResult);
        }
        if (
            (signUpResponseResult.value as APIType.UserSignUpResponse).invalid_email &&
            input.user.email
        ) {
            return Failure(InvalidEmail(input.user.email));
        }

        API.setAuthorization({
            accessToken: signUpResponseResult.value.access_token,
            refreshToken: signUpResponseResult.value.refresh_token,
        });

        const userData = (signUpResponseResult.value as APIType.UserSignUpResponse).user;
        const organizationId = (signUpResponseResult.value as APIType.UserSignUpResponse).org_id;
        const userResult = await User.create({
            id: userData.id,
            firstName: userData.first_name || null,
            lastName: userData.last_name || null,
            title: userData.prefix_title,
            phoneNumber: input.user.phoneNumber,
            email: userData.email as EmailAddress,
            password: input.user.password,
            signUpInviteToken: null,
            passwordResetToken: input.user.passwordResetToken,
            certificateInviteToken: null,
            oldPassword: input.user.password,
            createdAt: userData.created_at,
            isUserLoggedInBySignup: false,
            isBroker: userData.is_broker,
        });

        if (isErr(userResult)) {
            return handleOperationFailure(userResult);
        }
        this.userData.user = userResult.value;

        return Success({ user: userResult.value, organizationId });
    }

    public async getUserByEmail(
        email: EmailAddress,
    ): AsyncResult<User, OperationFailed | InvalidArgument> {
        const userResult = await User.create({
            firstName: null,
            lastName: null,
            email,
            phoneNumber: null,
            title: null,
            password: null,
            passwordResetToken: null,
            signUpInviteToken: null,
            certificateInviteToken: null,
            oldPassword: null,
            createdAt: null,
            isUserLoggedInBySignup: false,
        });

        if (isErr(userResult)) {
            return handleOperationFailure(userResult);
        }

        userResult.value.clearEvents();

        return Success(userResult.value);
    }

    private async forgottenPassword(
        user: User,
    ): AsyncResult<void, InvalidArgument | OperationFailed> {
        const result = await API.request('user/forgotten_password', user.email);
        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return Success();
    }

    public async getUserByPasswordResetToken(
        token: string,
    ): AsyncResult<User, InvalidArgument | OperationFailed> {
        const userResult = await User.create({
            firstName: null,
            lastName: null,
            email: null,
            phoneNumber: null,
            title: null,
            password: null,
            signUpInviteToken: null,
            passwordResetToken: token,
            certificateInviteToken: null,
            oldPassword: null,
            createdAt: null,
            isUserLoggedInBySignup: false,
        });

        if (isErr(userResult)) {
            return handleOperationFailure(userResult);
        }

        userResult.value.clearEvents();

        return Success(userResult.value);
    }

    public async resetPassword(user: User): AsyncResult<void, OperationFailed | InvalidArgument> {
        const result = await API.request('user/reset_password', {
            pwd: user.password,
            token: user.passwordResetToken,
        } as APIType.UserResetPasswordRequest);

        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return Success();
    }

    private async updatePassword(user: User): AsyncResult<void, OperationFailed | InvalidArgument> {
        if (!this.userData.userResponseData || !this.userData.user) {
            return Failure(
                OperationFailed({
                    message: 'User must be fetched with getUser before it can be saved',
                }),
            );
        }
        const result = await API.request('user/change_password', {
            old_pwd: user.oldPassword,
            new_pwd: user.password,
        });
        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        this.userData.user = user;
        return Success();
    }

    private async setPassword(user: User): AsyncResult<void, OperationFailed | InvalidArgument> {
        if (!this.userData.userResponseData || !this.userData.user) {
            return Failure(
                OperationFailed({
                    message: 'User must be fetched with getUser before it can be saved',
                }),
            );
        }
        const result = await API.request('user/set_password', {
            pwd: user.password,
        });
        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        this.userData.user = user;
        return Success();
    }

    private async updateExisting(
        user: User,
    ): AsyncResult<void, OperationFailed | InvalidArgument | InvalidEmail> {
        if (!this.userData.userResponseData) {
            return Failure(
                OperationFailed({
                    message: 'User must be fetched with getUser before it can be saved',
                }),
            );
        }
        const requestDataResult = APIUserRepository.buildUserUpdateRequest(user, this.userData);
        if (isErr(requestDataResult)) {
            return requestDataResult;
        }

        const result = await API.request('user/update', requestDataResult.value);
        if (isErr(result)) {
            for (const error of result.errors) {
                if (
                    isAPIError(error) &&
                    error.details.name === INVALID_EMAIL_ERROR_CODE &&
                    user.email !== null
                ) {
                    return Failure(InvalidEmail(user.email));
                }
            }
            return handleOperationFailure(result);
        }
        this.userData.user = user;
        return Success();
    }

    /**
     * Create request object from raw response user data and user entity data
     *
     * @param newUserData User entity used to create request object
     * @param currentUserData Last cached raw user data
     * @returns User request object
     */
    private static buildUserUpdateRequest(
        newUserData: User,
        currentUserData: UserData,
    ): Result<APIType.UserUpdateRequest, InvalidArgument> {
        if (newUserData.email === null) {
            return Failure(
                InvalidArgument({ argument: 'newUserData.email', value: newUserData.email }),
            );
        }

        let hasMultipleOrganizations = false;
        let archived: Nullable<boolean> = false;
        let howDidYouHearAboutEmbroker: Nullable<string> = null;
        let createdAt: Nullable<Date> = null;
        let loggedAsAdmin: Nullable<boolean> = null;
        if (
            currentUserData !== null &&
            currentUserData.userResponseData !== undefined &&
            currentUserData.userResponseData !== null
        ) {
            const serverUserData = currentUserData.userResponseData;
            hasMultipleOrganizations = serverUserData.has_multiple_orgs;
            archived = serverUserData.archived;
            howDidYouHearAboutEmbroker =
                serverUserData.how_did_you_hear_about_embroker === undefined
                    ? null
                    : serverUserData.how_did_you_hear_about_embroker;
            createdAt = serverUserData.created_at === undefined ? null : serverUserData.created_at;
            loggedAsAdmin =
                serverUserData.logged_as_admin === undefined
                    ? null
                    : serverUserData.logged_as_admin;
        }

        if (newUserData.firstName === null) {
            return Failure(
                InvalidArgument({ argument: 'firstName', value: newUserData.firstName }),
            );
        }

        if (newUserData.lastName === null) {
            return Failure(InvalidArgument({ argument: 'lastName', value: newUserData.lastName }));
        }

        return Success({
            org: null,
            pwd: newUserData.password,
            user: {
                id: newUserData.id,
                email: newUserData.email,
                prefix_title: newUserData.title,
                first_name: newUserData.firstName,
                last_name: newUserData.lastName,
                phone: newUserData.phoneNumber,
                phone_ext: null,
                has_multiple_orgs: hasMultipleOrganizations,
                archived: archived,
                created_at: createdAt,
                logged_as_admin: loggedAsAdmin,
                how_did_you_hear_about_embroker: howDidYouHearAboutEmbroker,
                is_broker: false,
                brokerage_id: null, // not actually used in user update
            },
        });
    }
    public async isUserBundleProspect(userId: UUID): AsyncResult<boolean, CheckTokenValidityError> {
        const result = await API.request('bundle_prospect/token_validity', {
            user_account_id: userId,
        });

        if (isErr(result)) {
            const error = result.errors[0];
            if (isAPIError(error)) {
                if (error.details.name === 'prospect_not_found') {
                    return Success(false);
                }
            }
            return Failure(CheckTokenValidityError(userId));
        }

        return Success(true);
    }
}
