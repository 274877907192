import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { QuoteRepository } from '../repositories/QuoteRepository';

export interface GenerateDocumentLinkRequestData {
    pdfFileKey: string;
}

export interface GenerateDocumentLink extends UseCase {
    execute(
        request: GenerateDocumentLinkRequestData,
    ): AsyncResult<string, InvalidArgument | OperationFailed>;
}

@injectable()
class GenerateDocumentLinkUseCase extends UseCase implements GenerateDocumentLink {
    public static type = Symbol('Payments/GenerateDocumentLink');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(QuoteRepository) private quoteRepo: QuoteRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: GenerateDocumentLinkRequestData,
    ): AsyncResult<string, InvalidArgument | OperationFailed> {
        const result = await this.quoteRepo.generateDocumentLink(request);

        if (isErr(result)) {
            return result;
        }

        return Success(result.value);
    }
}

export const GenerateDocumentLink: UseCaseClass<GenerateDocumentLink> = GenerateDocumentLinkUseCase;
