import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { InsuranceApplicationStatusCode } from '../../shopping/types/enums';
import { BundleQuote } from '../entities/BundleQuote';
import {
    ApplicationNotFound,
    DocumentCreationBundleTaskError,
    EnqueueReQuoteBundleTaskError,
    InvalidAnnualTechFee,
    OperationNotAllowed,
    PurchaseBundleQuoteError,
    QuoteIdMissing,
    QuoteNotFound,
    QuoteOptionsNotAllowed,
    RenewalBeforeLastEndorsementDateNotAllowed,
} from '../errors';
import { BundleCoverageType } from '../types/BundleQuoteCoverage';
import { DocumentType } from '../types/BundleQuoteDocument';
import { BundleQuoteOptions } from '../types/BundleQuoteOptions';

export interface BundleQuoteRepository {
    getLastBundleQuote(
        applicationId: UUID,
    ): AsyncResult<BundleQuote, InvalidArgument | OperationFailed>;

    enqueueReQuoteBundleTask(
        applicationId: UUID,
        quoteOptionsMap: Immutable<Map<BundleCoverageType, BundleQuoteOptions>>,
    ): AsyncResult<
        UUID,
        ApplicationNotFound | OperationNotAllowed | EnqueueReQuoteBundleTaskError | InvalidArgument
    >;

    purchaseBundleQuote(
        applicationId: UUID,
        coverageTypeQuoteIdMap: Map<BundleCoverageType, UUID>,
    ): AsyncResult<
        void,
        | ApplicationNotFound
        | OperationNotAllowed
        | QuoteOptionsNotAllowed
        | InvalidAnnualTechFee
        | QuoteIdMissing
        | QuoteNotFound
        | RenewalBeforeLastEndorsementDateNotAllowed
        | PurchaseBundleQuoteError
        | InvalidArgument
    >;

    submitForReview(applicationId: UUID): AsyncResult<void, InvalidArgument | OperationFailed>;

    createDocumentAsyncTask(
        applicationId: UUID,
        quoteId: UUID,
        documentType: DocumentType,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed | DocumentCreationBundleTaskError>;

    requestHigherLimit(applicationId: UUID): AsyncResult<void, InvalidArgument | OperationFailed>;

    setApplicationStatus(
        applicationId: UUID,
        applicationStatus: InsuranceApplicationStatusCode,
    ): AsyncResult<void, InvalidArgument | OperationFailed>;

    getBundleApplicationFromBundleId(bundleId: UUID): AsyncResult<UUID, OperationFailed>;
}

export const BundleQuoteRepository = Symbol('BundleQuoteRepository');
