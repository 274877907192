import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { UserInfo } from './UserInfo';

export interface PCoMLApplicationInfo {
    readonly userInfo: UserInfo;
    readonly isRenewal: boolean;
    readonly existingCoverageReferral: boolean;
    readonly submittedAt?: Date;
    readonly newInsurerDocumentsReleaseDate?: Date;
    readonly naics?: string;
}

export const PCoMLApplicationInfo = {
    ...defineValidator<PCoMLApplicationInfo>({
        userInfo: UserInfo.schema.required(),
        existingCoverageReferral: Joi.bool().required(),
        isRenewal: Joi.boolean().required(),
        submittedAt: Joi.date().optional(),
        newInsurerDocumentsReleaseDate: Joi.date().optional(),
        naics: Joi.string().optional(),
    }),
    create(pcomlQuoteInfo: PCoMLApplicationInfo) {
        return PCoMLApplicationInfo.validate(pcomlQuoteInfo);
    },
};
