import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success, isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { PolicyRepository } from '../../../policy/repositories/PolicyRepository';
import { EndorsementIntakeRepository } from '../repositories/EndorsementIntakeRepository';

export interface CreateOtherEndorsementRequest {
    readonly policyId: UUID;
    readonly effectiveDate: Date;
    readonly requestedChangeMessage: string;
}

export interface CreateOtherEndorsement extends UseCase {
    execute(
        request: CreateOtherEndorsementRequest,
    ): AsyncResult<void, UnknownEntity | InvalidArgument | OperationFailed>;
}

@injectable()
class CreateOtherEndorsementUseCase extends UseCase implements CreateOtherEndorsement {
    public static type = Symbol('OtherEndorsement/CreateOtherEndorsement');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(EndorsementIntakeRepository)
        private endorsementIntakeRepository: EndorsementIntakeRepository,
        @inject(PolicyRepository) private policyRepository: PolicyRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        effectiveDate,
        requestedChangeMessage,
        policyId,
    }: CreateOtherEndorsementRequest): AsyncResult<
        void,
        UnknownEntity | OperationFailed | InvalidArgument
    > {
        const policyResult = await this.policyRepository.getPolicy(policyId);

        if (isErr(policyResult)) {
            return policyResult;
        }

        policyResult.value.endorse();

        const endorsementResult = await this.endorsementIntakeRepository.createOtherEndorsement(
            effectiveDate,
            requestedChangeMessage,
            policyId,
        );

        if (isErr(endorsementResult)) {
            return endorsementResult;
        }

        this.eventBus.publishEntityEvents(policyResult.value);

        return Success();
    }
}

export const CreateOtherEndorsement: UseCaseClass<CreateOtherEndorsement> =
    CreateOtherEndorsementUseCase;
