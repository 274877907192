import { add, addDays, isAfter, isBefore, isSameDay, subDays } from 'date-fns';
import { Nominal } from '@embroker/shotwell/core/types/Nominal';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export const ADMIN_MAX_BACKDATE_DAYS = 30;

export type EffectiveDate = Nominal<Date, 'EffectiveDate'>;
export const EffectiveDate = {
    ...defineValidator<Date>(Joi.date()),
    isSelectedEffectiveDateValid(
        effectiveDate: Date,
        today: Date,
        maxFutureDaysAllowed: number,
        isAdmin: boolean,
    ): boolean {
        // Start date can't be in the past or more than 60/90 days in the future (depending of coverage)
        // Admin user can backdate start date up to 30 days
        return (
            this.isAfterMinValidDate(effectiveDate, today, isAdmin) &&
            this.isBeforeMaxValidDate(effectiveDate, today, maxFutureDaysAllowed)
        );
    },
    isAfterMinValidDate(effectiveDate: Date, today: Date, isAdmin: boolean): boolean {
        const minEffectiveDate = this.calculateMinEffectiveDate(today, isAdmin);

        return (
            isSameDay(effectiveDate, minEffectiveDate) || isAfter(effectiveDate, minEffectiveDate)
        );
    },
    isBeforeMaxValidDate(effectiveDate: Date, today: Date, maxFutureDaysAllowed: number): boolean {
        const maxEffectiveDate = this.calculateMaxEffectiveDate(today, maxFutureDaysAllowed);

        return (
            isSameDay(maxEffectiveDate, effectiveDate) || isBefore(effectiveDate, maxEffectiveDate)
        );
    },
    calculateMinEffectiveDate(today: Date, isAdmin: boolean) {
        // Admin user can backdate start date up to 30 days
        const adminMinDate = subDays(today, ADMIN_MAX_BACKDATE_DAYS);

        return isAdmin ? adminMinDate : today;
    },
    calculateMaxEffectiveDate(today: Date, maxFutureDaysAllowed: number) {
        return addDays(today, maxFutureDaysAllowed);
    },
    calculateCoverageEndDate(effectiveDate: Date | null) {
        return effectiveDate ? add(effectiveDate, { years: 1 }) : null;
    },
};
