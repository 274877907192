import { Nullable } from '@embroker/shotwell/core/types';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface SignaturePacketDocument {
    readonly fileName: string;
    readonly storageLocation: Nullable<string>;
    isDigitallySigned: boolean;
}

export const SignaturePacketDocument = {
    ...defineValidator<SignaturePacketDocument>({
        fileName: Joi.string(),
        storageLocation: Joi.string().allow(null),
        isDigitallySigned: Joi.boolean(),
    }),
    create(signaturePacketDocument: SignaturePacketDocument) {
        return SignaturePacketDocument.validate(signaturePacketDocument);
    },
};
