import { BundleCreationType, BundleCreationTypeItem } from '@embroker/shotwell-api/enums';
import { valueObject } from '@embroker/shotwell/core/types/ValueObject';
import { Joi, defineValidator } from '@embroker/shotwell/core/validation/schema';
import { AppDetails } from './AppDetails';

export interface BundleDetails {
    creationType: BundleCreationTypeItem;
    appDetails: AppDetails[];
}

export const BundleDetails = valueObject({
    ...defineValidator<BundleDetails>({
        creationType: Joi.string().valid(...BundleCreationType),
        appDetails: Joi.array().items(AppDetails.schema),
    }),
});
