import { Money } from '@embroker/shotwell/core/types/Money';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import React from 'react';
import { ModalCoverageSummaryProp } from '../../coverageDefinition';
import { WCChubbQuote } from '../entities/WCChubbQuote';
import { CoverageModalSummary } from '../../CoverageSummary.view';

export function WCChubbCoverageModalSummary({ quote }: ModalCoverageSummaryProp<WCChubbQuote>) {
    return (
        <CoverageModalSummary
            title="Workers Compensation"
            summaryList={[
                { label: 'WORKERS COMPENSATION', value: 'Statutory' },
                {
                    label: 'EMPLOYERS LIABILITY - EACH ACCIDENT',
                    value: (
                        <MoneyDisplay
                            value={Money.tryFromFloat(quote.options.perAccidentLimit)}
                            fractionDigits={0}
                        />
                    ),
                },
                {
                    label: 'EMPLOYERS LIABILITY - EACH EMPLOYEE',
                    value: (
                        <MoneyDisplay
                            value={Money.tryFromFloat(quote.options.eachEmployeeLimit)}
                            fractionDigits={0}
                        />
                    ),
                },
                {
                    label: 'EMPLOYERS LIABILITY - POLICY LIMIT',
                    value: (
                        <MoneyDisplay
                            value={Money.tryFromFloat(quote.options.policyLimit)}
                            fractionDigits={0}
                        />
                    ),
                },
                {
                    label: 'PREMIUM',
                    value: <MoneyDisplay value={quote.totalPayable} />,
                },
            ]}
        />
    );
}
