import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Nullable } from '@embroker/shotwell/core/types';
import { AsyncResult, Failure, Success, isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { GetPolicy } from '../../policy/useCases/GetPolicy';
import {
    ApplicationNotFound,
    StreamlineRenewalAlreadyPurchased,
    StreamlineRenewalAlreadyQuoted,
    StreamlineRenewalNotEligible,
} from '../errors';
import { ApplicationRepository } from '../repositories/ApplicationRepository';
import { getSignatureData } from './CreateApplications';
import { GetApplication } from './GetApplication';

interface SubmitStreamlineRenewalRequest {
    applicationId: UUID;
}

interface SubmitStreamlineRenewalResponse {
    taskId: Nullable<UUID>;
}

export interface SubmitStreamlineRenewal extends UseCase {
    execute(
        request: SubmitStreamlineRenewalRequest,
    ): AsyncResult<
        SubmitStreamlineRenewalResponse,
        | ApplicationNotFound
        | StreamlineRenewalAlreadyPurchased
        | StreamlineRenewalAlreadyQuoted
        | StreamlineRenewalNotEligible
        | InvalidArgument
        | OperationFailed
    >;
}

class SubmitStreamlineRenewalUseCase extends UseCase implements SubmitStreamlineRenewal {
    public static type = Symbol('Shopping/SubmitStreamlineRenewal');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
        @inject(GetApplication.type) private getApplication: GetApplication,
        @inject(GetPolicy.type) private getPolicy: GetPolicy,
    ) {
        super(eventBus);
    }

    public async execute(
        request: SubmitStreamlineRenewalRequest,
    ): AsyncResult<
        SubmitStreamlineRenewalResponse,
        | ApplicationNotFound
        | StreamlineRenewalAlreadyPurchased
        | StreamlineRenewalAlreadyQuoted
        | StreamlineRenewalNotEligible
        | InvalidArgument
        | OperationFailed
    > {
        const renewalApplicationResult = await this.getApplication.execute({
            applicationId: request.applicationId,
        });

        if (isErr(renewalApplicationResult)) {
            return Failure(ApplicationNotFound());
        } else {
            const appStatus = renewalApplicationResult.value.application.status;
            if (appStatus !== 'InsuranceApplicationStatusCodeListQuestionnaireInProgress') {
                if (appStatus === 'InsuranceApplicationStatusCodeListPurchased') {
                    return Failure(StreamlineRenewalAlreadyPurchased());
                } else if (appStatus === 'InsuranceApplicationStatusCodeListQuotesReady') {
                    return Failure(StreamlineRenewalAlreadyQuoted());
                } else {
                    return Failure(
                        OperationFailed({ message: 'Invalid streamline renewal app status' }),
                    );
                }
            }
        }

        const expiringPolicyResult = await this.getPolicy.execute({
            policyId: renewalApplicationResult.value.application.renewedPolicyIdList[0],
        });
        if (isErr(expiringPolicyResult)) {
            return Failure(OperationFailed(expiringPolicyResult));
        } else {
            if (expiringPolicyResult.value.policy.endDate < new Date(Date.now())) {
                return Failure(OperationFailed({ message: 'Policy already expired' }));
            }
        }

        const userTzOffsetMinutes = new Date(Date.now()).getTimezoneOffset();
        const signatureData = getSignatureData();
        const response = await this.applicationRepository.submitStreamlineRenewal(
            request.applicationId,
            -userTzOffsetMinutes,
            signatureData,
        );
        if (isErr(response)) {
            return response;
        }

        return Success({
            taskId: response.value,
        });
    }
}

export const SubmitStreamlineRenewal: UseCaseClass<SubmitStreamlineRenewal> =
    SubmitStreamlineRenewalUseCase;
