import {
    Aborted,
    InvalidArgument,
    OperationFailed,
    Timeout,
    UnknownEntity,
} from '@embroker/shotwell/core/Error';
import { UseCase, UseCaseClass, execute } from '@embroker/shotwell/core/UseCase';
import { inject } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import {
    AsyncResult,
    Failure,
    Success,
    handleOperationFailure,
    isErr,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { CalculateSKU } from '../../analytics/useCases/CalculateSKU';
import { TasksRepository } from '../../tasks/repositories';
import { GetActiveOrganizationProfile } from '../../userOrg/useCases/GetActiveOrganizationProfile';
import { ApplicationCreated } from '../entities/Application';
import { AppTypeNotAllowedForBroker, NoEligibleAppTypeFound } from '../errors';
import { ApplicationRepository } from '../repositories/ApplicationRepository';
import { MplBundleQuestionnaireData } from '../types/MplBundleQuestionnaireData';
import { AppTypeCodeListMPL, AppTypeCodeListMPLBundle } from '@app/shopping/types/enums';

export interface CreateMplBundleApplicationRequest {
    abortSignal: AbortSignal;
    maxPollingRetries?: number;
    pollingRetryIntervalInMilliseconds?: number;
}

export interface CreateMplBundleApplicationResponse {
    bundleApplicationId: UUID;
}

export interface CreateMplBundleApplication extends UseCase {
    execute(
        request: CreateMplBundleApplicationRequest,
    ): AsyncResult<
        CreateMplBundleApplicationResponse,
        | InvalidArgument
        | OperationFailed
        | UnknownEntity
        | Aborted
        | Timeout
        | AppTypeNotAllowedForBroker
        | NoEligibleAppTypeFound
    >;
}

export class CreateMplBundleApplicationUseCase
    extends UseCase
    implements CreateMplBundleApplication
{
    public static type = Symbol('Shopping/CreateMplBundleApplication');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
        @inject(CalculateSKU.type)
        private calculateSKU: CalculateSKU,
        @inject(Log) private logger: Logger,
    ) {
        super(eventBus);
    }

    public async execute({
        abortSignal,
        maxPollingRetries = 5,
        pollingRetryIntervalInMilliseconds = 120,
    }: CreateMplBundleApplicationRequest): AsyncResult<
        CreateMplBundleApplicationResponse,
        | InvalidArgument
        | OperationFailed
        | UnknownEntity
        | Aborted
        | Timeout
        | AppTypeNotAllowedForBroker
        | NoEligibleAppTypeFound
    > {
        const configResponse = await this.applicationRepository.getConfig();
        if (isErr(configResponse)) {
            return configResponse;
        }

        const getActiveOrganizationProfileResp = await execute(GetActiveOrganizationProfile);
        if (isErr(getActiveOrganizationProfileResp)) {
            return handleOperationFailure(getActiveOrganizationProfileResp);
        }

        const naics = getActiveOrganizationProfileResp.value.organization.naics || '';
        const createQuestionnaireDataResult = MplBundleQuestionnaireData.create({
            naicsCode: naics,
        });

        if (isErr(createQuestionnaireDataResult)) {
            return handleOperationFailure(createQuestionnaireDataResult);
        }

        const questionnaireDataWithCoverages = createQuestionnaireDataResult.value;

        let questionnaireDataStringified;
        try {
            questionnaireDataStringified = JSON.stringify(questionnaireDataWithCoverages);
        } catch (error) {
            return Failure(OperationFailed({ message: 'Failed to stringify questionnaire data' }));
        }

        const createApplicationsResult = await this.applicationRepository.createApplications({
            appTypeList: [AppTypeCodeListMPL, AppTypeCodeListMPLBundle],
            questionnaireData: questionnaireDataStringified,
        });

        if (isErr(createApplicationsResult)) {
            return createApplicationsResult;
        }

        const { taskId, applicationList } = createApplicationsResult.value;
        const applicationIdList: UUID[] = applicationList.map((item) => item.id);

        const bundleApplicationId = applicationIdList[0]; // Bundle application is the 0th element
        const bundleShoppingCoverageList = applicationList[0]; // Bundle application is the 0th element

        const skuResult = await this.calculateSKU.execute({
            event: 'app_created',
            appType: AppTypeCodeListMPL,
            shoppingCoverageList: bundleShoppingCoverageList.shoppingCoverageList,
            applicationId: bundleApplicationId,
        });
        const eventData: ApplicationCreated = {
            origin: 'Application',
            name: 'ApplicationCreated',
            id: bundleApplicationId,
            isRenewal: bundleShoppingCoverageList.isRenewal(),
            sku: skuResult.value,
            createdAt: new Date(Date.now()),
        };
        this.eventBus.publish(eventData);

        const pollForTaskStatusResult = await this.tasksRepository.pollForTaskStatus(
            taskId,
            abortSignal,
            maxPollingRetries,
            pollingRetryIntervalInMilliseconds,
        );
        if (isErr(pollForTaskStatusResult)) {
            this.logger.warn(
                `Create Applications UseCase - Got error for task with id: ${taskId}`,
                pollForTaskStatusResult.errors,
            );
        } else if (!UUID.check(pollForTaskStatusResult.value)) {
            this.logger.warn(
                `Create Applications UseCase - Invalid response type for task with id: ${taskId}`,
                pollForTaskStatusResult,
            );
        }

        return Success<CreateMplBundleApplicationResponse>({
            bundleApplicationId,
        });
    }
}

export const CreateMplBundleApplication: UseCaseClass<CreateMplBundleApplication> =
    CreateMplBundleApplicationUseCase;
