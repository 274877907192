import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { UUID } from '@embroker/shotwell/core/types/UUID';

export interface FormFamily {
    id?: UUID;
    docType?: string;
    optional: boolean;
}

export const FormFamily = {
    ...defineValidator<FormFamily>(
        Joi.object({
            id: UUID.schema.optional(),
            docType: Joi.string().optional(),
            optional: Joi.boolean().required(),
        }),
    ),
    create(formFamily: FormFamily) {
        return FormFamily.validate(formFamily);
    },
};
