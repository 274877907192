import { ContainerModule } from '@embroker/shotwell/core/di';
import { Module } from '../Module';
import { DigitalEndorsementsRepository } from './repositories/digitalEndorsements';
import { APIDigitalEndorsementsRepository } from './repositories/digitalEndorsements/APIDigitalEndorsementsRepository';
import { GetEndorsementQuote } from './useCases/GetEndorsementQuoteUseCase';
import { GetEndorsementRecords } from './useCases/GetEndorsementRecordsUseCase';
import { GetEndorsements } from './useCases/GetEndorsementsUseCase';
import { IssueEndorsement } from './useCases/IssueEndorsementUseCase';
import { PrecreateEndorsement } from './useCases/PrecreateEndorsementUseCase';
import { PreviewEndorsement } from './useCases/PreviewEndorsementUseCase';
import { PublishDigitalEndorsementIssuedEvent } from './useCases/PublishDigitalEndorsementIssuedEventUseCase';
import { InsuranceApplicationRepository } from './repositories/insuranceApplication';
import { APIInsuranceApplicationRepository } from './repositories/insuranceApplication/APIInsuranceApplicationRepository';
import { ManifestRepository } from './repositories/manifest';
import { APIManifestRepository } from './repositories/manifest/APIManifestRepository';
import { ProductDefinitionRepository } from './repositories/productDefinition';
import { APIProductDefinitionRepository } from './repositories/productDefinition/APIProductDefinitionRepository';
import { InsuranceApplicationRestrictionRepository } from './repositories/insuranceApplicationRestriction';
import { APIInsuranceApplicationRestrictionRepository } from './repositories/insuranceApplicationRestriction/APIInsuranceApplicationRestrictionRepository';

export const DigitalEndorsementsModule: Module = {
    container: new ContainerModule((bind) => {
        bind<DigitalEndorsementsRepository>(DigitalEndorsementsRepository)
            .to(APIDigitalEndorsementsRepository)
            .inSingletonScope();
        bind<InsuranceApplicationRepository>(InsuranceApplicationRepository)
            .to(APIInsuranceApplicationRepository)
            .inSingletonScope();
        bind<InsuranceApplicationRestrictionRepository>(InsuranceApplicationRestrictionRepository)
            .to(APIInsuranceApplicationRestrictionRepository)
            .inSingletonScope();
        bind<ManifestRepository>(ManifestRepository).to(APIManifestRepository).inSingletonScope();
        bind<ProductDefinitionRepository>(ProductDefinitionRepository)
            .to(APIProductDefinitionRepository)
            .inSingletonScope();
        bind<GetEndorsements>(GetEndorsements.type).to(GetEndorsements).inSingletonScope();
        bind<PrecreateEndorsement>(PrecreateEndorsement.type)
            .to(PrecreateEndorsement)
            .inSingletonScope();
        bind<GetEndorsementQuote>(GetEndorsementQuote.type)
            .to(GetEndorsementQuote)
            .inSingletonScope();
        bind<IssueEndorsement>(IssueEndorsement.type).to(IssueEndorsement).inSingletonScope();
        bind<PreviewEndorsement>(PreviewEndorsement.type).to(PreviewEndorsement).inSingletonScope();
        bind<GetEndorsementRecords>(GetEndorsementRecords.type)
            .to(GetEndorsementRecords)
            .inSingletonScope();
        bind<PublishDigitalEndorsementIssuedEvent>(PublishDigitalEndorsementIssuedEvent.type)
            .to(PublishDigitalEndorsementIssuedEvent)
            .inSingletonScope();
    }),
};
