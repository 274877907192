import { defineEntityValidator, entity } from '@embroker/shotwell/core/entity/Entity';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { SKU } from '../../../analytics/types/SKU';
import { commonQuoteProps, Quote, QuoteDetails, QuoteOptions } from '../../entities/Quote';
import { PCoMLApplicationInfo } from '../types/PCoMLApplicationInfo';

export interface PCoMLQuotePurchased extends DomainEvent {
    readonly origin: 'PCoMLQuote';
    readonly name: 'Purchased';
    readonly isOFACRejected: boolean;
    readonly totalPremium: Nullable<Money>;
    readonly applicationId: UUID;
    sku?: SKU;
    readonly isRenewal: boolean;
}

export interface LineItemOptions {
    readonly limit: Money;
    readonly previousLimit?: Money;
    readonly retention: Money;
    readonly previousRetention?: Money;
    readonly isSelected: boolean;
    readonly inShoppingCoverageList: boolean;
}

export const LineItemOptions = {
    ...defineValidator<LineItemOptions>({
        limit: Money.schema,
        previousLimit: Money.schema.optional(),
        retention: Money.schema,
        previousRetention: Money.schema.optional(),
        isSelected: Joi.bool(),
        inShoppingCoverageList: Joi.bool(),
    }),
};

export interface PCoMLQuoteOptions extends QuoteOptions {
    readonly dno: LineItemOptions;
    readonly epl: LineItemOptions;
    readonly policyAdministrationFeePreTax?: Money;
    readonly policyAdministrationFeePostTax?: Money;
}

export const PCoMLQuoteOptions = {
    ...defineValidator<PCoMLQuoteOptions>({
        effectiveDate: Joi.date(),
        dno: LineItemOptions.schema,
        epl: LineItemOptions.schema,
        policyAdministrationFeePreTax: Money.schema.optional(),
        policyAdministrationFeePostTax: Money.schema.optional(),
    }),
};

export interface LineItemDetails {
    readonly premium: Money;
    readonly previousPremium?: Money;
}

export const LineItemDetails = {
    ...defineValidator<LineItemDetails>({
        premium: Money.schema,
        previousPremium: Money.schema.optional(),
    }),
};

export interface PCoMLQuoteDetails extends QuoteDetails {
    readonly dno: LineItemDetails;
    readonly epl: LineItemDetails;
    readonly fees: Money;
    readonly mwuaFee?: Money;
    readonly taxes: Money;
    readonly policyAdministrationFee?: Money;
    readonly sociusEndorsementPremium?: Money;
}

export const PCoMLQuoteDetails = {
    ...defineValidator<PCoMLQuoteDetails>({
        dno: LineItemDetails.schema,
        epl: LineItemDetails.schema,
        fees: Money.schema,
        mwuaFee: Money.schema.optional(),
        taxes: Money.schema,
        policyAdministrationFee: Money.schema.optional(),
        sociusEndorsementPremium: Money.schema.optional(),
    }),
};

export interface PCoMLQuote extends Quote {
    readonly options: PCoMLQuoteOptions;
    readonly details: PCoMLQuoteDetails;
    readonly applicationInfo?: PCoMLApplicationInfo;
}

export const PCoMLQuote = entity<PCoMLQuote, Quote>({
    validator: defineEntityValidator<PCoMLQuote>({
        ...commonQuoteProps,
        options: PCoMLQuoteOptions.schema,
        details: PCoMLQuoteDetails.schema,
        applicationInfo: PCoMLApplicationInfo.schema.optional(),
    }),
    inherits: Quote,
});
