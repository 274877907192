import { Money } from '@embroker/shotwell/core/types/Money';
import { defineValidator } from '@embroker/shotwell/core/validation/schema';
import { QuoteDetails } from '../../../../quote/entities/Quote';

export interface CowbellCyberQuoteDetails extends QuoteDetails {
    readonly taxes?: Money;
    readonly basePremium: Money;
    readonly specialSurcharge: Money;
}

export const CowbellCyberQuoteDetails = {
    ...defineValidator<CowbellCyberQuoteDetails>({
        taxes: Money.schema.optional(),
        basePremium: Money.schema.required(),
        specialSurcharge: Money.schema.required(),
    }),
    create(cowbellCyberQuoteDetails: CowbellCyberQuoteDetails) {
        return CowbellCyberQuoteDetails.validate(cowbellCyberQuoteDetails);
    },
};
