import { API } from '@embroker/shotwell-api/app';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult, handleOperationFailure, isErr } from '@embroker/shotwell/core/types/Result';
import { CyberConfig } from '../../types/CyberConfig';
import { CyberConfigRepository } from './index';

@injectable()
export class APICyberConfigRepository implements CyberConfigRepository {
    private config?: { cyberDevModeEffectiveDate: string; isEmbrokerCyberEnabled: boolean };

    async getCyberConfig(): AsyncResult<CyberConfig, InvalidArgument | OperationFailed> {
        if (this.config !== undefined) {
            return CyberConfig.create({
                cyberDevModeEffectiveDate: this.config.cyberDevModeEffectiveDate,
                isEmbrokerCyberEnabled: this.config.isEmbrokerCyberEnabled,
            });
        }
        const getConfigResponse = await API.request('global/get_config');

        if (isErr(getConfigResponse)) {
            return handleOperationFailure(getConfigResponse);
        }

        this.config = {
            cyberDevModeEffectiveDate: getConfigResponse.value.cyber_dev_mode_effective_date,
            isEmbrokerCyberEnabled: getConfigResponse.value.is_embroker_cyber_enabled,
        };

        return CyberConfig.create({
            cyberDevModeEffectiveDate: this.config.cyberDevModeEffectiveDate,
            isEmbrokerCyberEnabled: this.config.isEmbrokerCyberEnabled,
        });
    }
}
