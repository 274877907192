import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Username } from './UsernameForm';
import { UsernamePassword } from './UsernamePasswordForm';
import { PublicPageLayout } from '../PublicPageLayout.view';
import { AppContext } from '../../../../view/AppContext';
import { HeaderView } from '../../../../view/components/Header.view';
import { UnauthenticatedHeaderCtaView } from '../UnauthenticatedHeaderCta.view';

interface SignInPageProps {
    userEmail?: EmailAddress;
}

export function SignInPage({ userEmail }: SignInPageProps) {
    const [isPasswordFieldVisible, setIsPasswordFieldVisible] = useState(false);
    const [username, setUsername] = useState<EmailAddress | undefined>(userEmail);
    const { setHeader } = useContext(AppContext);

    const onUserExists = useCallback((email: EmailAddress) => {
        setUsername(email);
        setIsPasswordFieldVisible(true);
    }, []);

    useEffect(() => {
        setHeader(
            <HeaderView>
                <UnauthenticatedHeaderCtaView
                    href="/signup"
                    content="Sign up now"
                    dataE2e="customer-public-header-signup-link"
                    question="New to Embroker?"
                />
            </HeaderView>,
        );
    }, [setHeader]);

    return (
        <PublicPageLayout>
            {isPasswordFieldVisible ? (
                <UsernamePassword username={username} />
            ) : (
                <Username onUserExists={onUserExists} username={username} />
            )}
        </PublicPageLayout>
    );
}
