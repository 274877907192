import { Nullable } from '@embroker/shotwell/core/types';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { LoadStripe } from '../../useCases/LoadStripe';

export function useStripe(): Nullable<Promise<Nullable<Stripe>>> {
    const [stripe, setStripe] = useState<Nullable<Promise<Nullable<Stripe>>>>(null);
    const { result: stripeConfig } = useUseCase(LoadStripe);

    useEffect(() => {
        if (stripeConfig !== undefined && isOK(stripeConfig)) {
            setStripe(loadStripe(stripeConfig.value.stripeKey));
        }
    }, [stripeConfig]);

    return stripe;
}
