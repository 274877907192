import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { Applicant } from '../entities/Applicant';
import { ApplicantRepository } from '../repositories/ApplicantRepository';
import { NumberRangeOfW2Employees } from '../types/enums';

export interface UpdateApplicantInfoRequest {
    id: UUID;
    website?: string;
    naicsIndustry?: string;
    hasReceivedVCFunding?: boolean;
    isTotalRevenueLargerThan20MillionDollars?: boolean;
    numberRangeOfW2Employees?: NumberRangeOfW2Employees;
    hasAutomobiles?: boolean;
}

export interface UpdateApplicantInfo extends UseCase {
    execute(
        request: UpdateApplicantInfoRequest,
    ): AsyncResult<void, UnknownEntity | InvalidArgument | OperationFailed>;
}

@injectable()
export class UpdateApplicantInfoUseCase extends UseCase implements UpdateApplicantInfo {
    public static type = Symbol('Shopping/UpdateApplicantInfo');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicantRepository) private applicantRepo: ApplicantRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        applicant: UpdateApplicantInfoRequest,
    ): AsyncResult<void, UnknownEntity | InvalidArgument | OperationFailed> {
        const applicantResult = await this.applicantRepo.getApplicant();

        if (isErr(applicantResult)) {
            return applicantResult;
        }

        applicantResult.value.update(applicant);

        const result = await this.applicantRepo.update(applicantResult.value as Applicant);

        if (isErr(result)) {
            return result;
        }

        this.eventBus.publishEntityEvents(applicantResult.value);
        return Success();
    }
}

export const UpdateApplicantInfo: UseCaseClass<UpdateApplicantInfo> = UpdateApplicantInfoUseCase;
