import { Modal, ModalActions, ModalState } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { ModalLayout } from '../../../../../view/components/ModalLayout.view';

interface ExitConfirmationModalProps {
    onConfirm: () => void;
    modal: ModalState & ModalActions;
}

export function ExitConfirmationModal(props: ExitConfirmationModalProps) {
    const handleOnConfirm = () => props.onConfirm();

    return (
        <Modal dismissable={false} {...props.modal}>
            <ModalLayout
                title="Are you sure you want to leave?"
                primaryAction={{
                    onClick: handleOnConfirm,
                    label: 'Exit Without Saving',
                }}
                secondaryAction={{
                    onClick: props.modal.hide,
                    label: 'Cancel',
                }}
            >
                Please note that the changes you have made so far will not be saved.
            </ModalLayout>
        </Modal>
    );
}
