import { inject, injectable } from '@embroker/shotwell/core/di';
import {
    Aborted,
    InvalidArgument,
    OperationFailed,
    Timeout,
    UnknownEntity,
} from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { GetDocumentUrl } from '../../../documents/useCases/GetDocumentUrl';
import { TasksRepository } from '../../../tasks/repositories';
import { DocGenFailed } from '../../errors';
import { ExcessQuoteRepository } from '../repositories/EmbrokerExcessQuoteRepository';

export interface GenerateQuoteDocumentUrlRequest {
    readonly applicationId: UUID;
    readonly quoteId: UUID;
    readonly abortSignal: AbortSignal;
}

export interface GenerateQuoteDocumentUrlResponse {
    readonly quoteSummaryUrl: URI;
}

export interface GenerateQuoteDocumentUrl extends UseCase {
    execute(
        request: GenerateQuoteDocumentUrlRequest,
    ): AsyncResult<
        GenerateQuoteDocumentUrlResponse,
        UnknownEntity | InvalidArgument | OperationFailed | Aborted | Timeout | DocGenFailed
    >;
}

@injectable()
class GenerateQuoteDocumentUrlUseCase extends UseCase implements GenerateQuoteDocumentUrl {
    public static type = Symbol('ExcessQuote/GenerateQuoteDocumentUrl');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ExcessQuoteRepository) private excessQuoteRepository: ExcessQuoteRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
        @inject(GetDocumentUrl.type) private getDocumentUrl: GetDocumentUrl,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
        quoteId,
        abortSignal,
    }: GenerateQuoteDocumentUrlRequest): AsyncResult<
        GenerateQuoteDocumentUrlResponse,
        InvalidArgument | OperationFailed | DocGenFailed
    > {
        const createAsyncTaskResult = await this.excessQuoteRepository.createQuoteSummaryAsyncTask(
            applicationId,
            quoteId,
        );
        if (isErr(createAsyncTaskResult)) {
            return createAsyncTaskResult;
        }

        const pollForTaskStatusResult = await this.tasksRepository.pollForTaskStatus(
            createAsyncTaskResult.value,
            abortSignal,
        );
        if (isErr(pollForTaskStatusResult)) {
            return Failure(DocGenFailed({ errors: pollForTaskStatusResult.errors }));
        }

        const lastQuoteResult = await this.excessQuoteRepository.getLastExcessQuote(applicationId);
        if (isErr(lastQuoteResult)) {
            return lastQuoteResult;
        }
        if (lastQuoteResult.value.id !== quoteId) {
            return Failure(OperationFailed({ message: 'Not the latest excess quote' }));
        }

        const fileKey: string = lastQuoteResult.value.fileKey ?? '';
        if (fileKey === '') {
            return Failure(OperationFailed({ message: 'No generated quote document was found.' }));
        }

        const getDocumentUrlResult = await this.getDocumentUrl.execute({ fileKey });
        if (isErr(getDocumentUrlResult)) {
            return getDocumentUrlResult;
        }

        return Success<GenerateQuoteDocumentUrlResponse>({
            quoteSummaryUrl: URI.build(getDocumentUrlResult.value.downloadUrl),
        });
    }
}

export const GenerateQuoteDocumentUrl: UseCaseClass<GenerateQuoteDocumentUrl> =
    GenerateQuoteDocumentUrlUseCase;
