import { Module } from '../../Module';
import { ContainerModule } from '@embroker/shotwell/core/di';
import { BOPHartfordQuoteRepository } from '@app/quote/hartford-bop/repositories/BOPHartfordQuoteRepository';
import { APIBOPHartfordQuoteRepository } from '@app/quote/hartford-bop/repositories/BOPHartfordQuoteRepository/APIBOPHartfordQuoteRepository';
import { GetLatestBOPHartfordQuote } from '@app/quote/hartford-bop/useCases/GetLatestQuote';
import { PurchaseHartfordBOP } from '@app/quote/hartford-bop/useCases/PurchaseHartfordBOP';
import { ReQuote } from '@app/quote/hartford-bop/useCases/ReQuote';
import { GetPaymentUrl } from '@app/quote/hartford-bop/useCases/GetPaymentUrl';
import { GenerateQuoteFileUrl } from '@app/quote/hartford-bop/useCases/GetQuoteDocument';

export const HartfordBopModule: Module = {
    container: new ContainerModule((bind) => {
        bind<BOPHartfordQuoteRepository>(BOPHartfordQuoteRepository)
            .to(APIBOPHartfordQuoteRepository)
            .inSingletonScope();
        bind<GetLatestBOPHartfordQuote>(GetLatestBOPHartfordQuote.type)
            .to(GetLatestBOPHartfordQuote)
            .inSingletonScope();
        bind<PurchaseHartfordBOP>(PurchaseHartfordBOP.type)
            .to(PurchaseHartfordBOP)
            .inSingletonScope();
        bind<ReQuote>(ReQuote.type).to(ReQuote).inSingletonScope();
        bind<GetPaymentUrl>(GetPaymentUrl.type).to(GetPaymentUrl).inSingletonScope();
        bind<GenerateQuoteFileUrl>(GenerateQuoteFileUrl.type)
            .to(GenerateQuoteFileUrl)
            .inSingletonScope();
    }),
};
