import React from 'react';
import { PageLayout } from '@embroker/ui-toolkit/v2';
import {
    ErrorPage as ErrorPageBody,
    ErrorPageProps,
} from '@embroker/shotwell/view/components/ErrorPage';

export function ErrorPage(props: ErrorPageProps) {
    return (
        <PageLayout.Section>
            <ErrorPageBody {...props} />
        </PageLayout.Section>
    );
}
