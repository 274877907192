import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Immutable } from '@embroker/shotwell/core/types';
import { StackLayout } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { Policy } from '../../../entities/Policy';
import { Lob } from '../../../types/Lob';
import { CoverageSectionCard } from './CoverageSectionCard';

interface CoverageSectionProps {
    policy: Immutable<EntityProps<Policy>>;
}

export const CoverageSection = ({ policy }: CoverageSectionProps) => {
    function policyVisible(section: Immutable<Lob>): boolean {
        return (
            (section.liabilitySection !== null &&
                section.liabilitySection.liabilityList !== null &&
                section.liabilitySection.liabilityList.length > 0) ||
            (section.propertySection !== null &&
                section.propertySection.subjectOfInsuranceList.length > 0)
        );
    }

    const availableLobList = policy.lobList.filter((item) => {
        return item !== undefined && policyVisible(item);
    });

    const coverageSectionList = availableLobList.map((coverage: Immutable<Lob>) => {
        return (
            <CoverageSectionCard
                key={coverage.lobType}
                lob={coverage}
                isExpandable={availableLobList.length > 1}
                lineOfBusiness={policy.lineOfBusiness}
            />
        );
    });
    return <StackLayout>{coverageSectionList}</StackLayout>;
};
