import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { Nullable } from '@embroker/shotwell/core/types';
import { UserOnboardingNaicsChanged } from '../entities/User';

export interface PublishOnboardingNaicsChangedEvent extends UseCase {
    execute(request: PublishOnboardingNaicsChangedEventRequest): AsyncResult<void>;
}

export interface PublishOnboardingNaicsChangedEventRequest {
    naicsCode: Nullable<string>;
    previousNaicsCode: Nullable<string>;
}

@injectable()
class PublishOnboardingNaicsChangedEventUseCase
    extends UseCase
    implements PublishOnboardingNaicsChangedEvent
{
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/PublishOnboardingNaicsChangedEventUseCase');

    /**
     * Constructor for PublishOnboardingNaicsChangedEvent class instance
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    /**
     * Executes the PublishOnboardingNaicsChangedEvent use case.
     */
    public async execute({
        naicsCode,
        previousNaicsCode,
    }: PublishOnboardingNaicsChangedEventRequest): AsyncResult<void> {
        const userOnboardingNaicsChangedEvent: UserOnboardingNaicsChanged = {
            origin: 'User',
            name: 'OnboardingNaicsChanged',
            createdAt: new Date(Date.now()),
            id: UUID.create(),
            naicsCode,
            previousNaicsCode,
        };

        await this.eventBus.publish(userOnboardingNaicsChangedEvent);

        return Success();
    }
}

export const PublishOnboardingNaicsChangedEvent: UseCaseClass<PublishOnboardingNaicsChangedEvent> =
    PublishOnboardingNaicsChangedEventUseCase;
