import { Success } from '@embroker/shotwell/core/types/Result';
import { State } from '@embroker/shotwell/core/types/StateList';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    Button,
    ButtonBar,
    Form,
    FormLayout,
    SidebarLayout,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React, { useMemo } from 'react';
import { LocationStepGuidelines } from './LocationStepGuidelines';
import {
    LocationFieldDefinition,
    LocationFieldSet,
} from '@app/userOrg/view/components/LocationFieldSet.view';
import { MailingAddress as MailingAddressType } from '@app/userOrg/types/MailingAddress';

export interface LocationStepData {
    headquarters: MailingAddressType;
    referenceNumber?: string;
}

export interface FormattedLocationStepData {
    address1: string;
    address2?: string;
    city: string;
    state?: State;
    zip: ZipCode;
    referenceNumber?: string;
}

function createLocationStepForm(onNext: (data: FormattedLocationStepData) => void) {
    return createForm<LocationStepData>({
        fields: {
            headquarters: LocationFieldDefinition,
            referenceNumber: {
                type: 'text',
                validator: Joi.string().optional().trim().min(1).max(20),
                formatValidationError(error) {
                    switch (error.details.validator) {
                        case 'string.max':
                            return "Sorry, you can't use more than 20 characters.";
                        default:
                            return error.message;
                    }
                },
            },
        },
        async submit(data) {
            const formattedLocationStepData: FormattedLocationStepData = {
                address1: data.headquarters.addressLine1 ?? '',
                address2: data.headquarters.addressLine2 ?? '',
                city: data.headquarters.city ?? '',
                state: data.headquarters.state as State,
                zip: data.headquarters.zip as ZipCode,
                referenceNumber: data.referenceNumber,
            };
            onNext(formattedLocationStepData);
            return Success();
        },
    });
}

interface LocationStepProps {
    initialData?: FormattedLocationStepData;
    onNext(data: FormattedLocationStepData): void;
    onBack(): void;
}

export function LocationStep({ initialData, onNext, onBack }: LocationStepProps) {
    const locationStepForm = useMemo(() => createLocationStepForm(onNext), [onNext]);
    const { fields, value, submit, setValue } = useForm(locationStepForm, {
        headquarters: {
            addressLine1: initialData?.address1 ?? '',
            addressLine2: initialData?.address2 ?? '',
            city: initialData?.city ?? null,
            zip: initialData?.zip ?? null,
            state: initialData?.state ?? null,
        },
        referenceNumber: initialData?.referenceNumber,
    });

    const handleChange = (headquarters: Partial<MailingAddressType>) => {
        setValue({
            ...value,
            headquarters: headquarters as MailingAddressType,
        });
    };

    return (
        <SidebarLayout appearance="default" sidebarVariant="compact">
            <LocationStepGuidelines />
            <StackLayout>
                <FormLayout appearance="wide">
                    <StackLayout gap="48">
                        <StackLayout gap="24">
                            <Text style={'heading 5'} data-e2e="location-step-questionnaire">
                                Please provide details for the business entity requiring the
                                certificate:
                            </Text>
                            <LocationFieldSet
                                fieldValue={value.headquarters}
                                onChange={handleChange}
                                messages={fields.headquarters.messages}
                            />
                            <Form.Field
                                inputProps={{
                                    ...fields.referenceNumber.props,
                                    value: fields.referenceNumber.props.value ?? '',
                                }}
                                label="Reference Number (optional)"
                                messages={fields.referenceNumber.messages}
                                tooltip="This can be used later to find similar requests"
                                type={fields.referenceNumber.type}
                                data-e2e="location-step-reference-num"
                            />
                        </StackLayout>
                    </StackLayout>
                    <ButtonBar
                        split={'-1'}
                        reverse
                        responsive={{ containerWidth: { smallerThan: 400 } }}
                    >
                        <TextButton onClick={onBack} data-e2e="location-step-back">
                            Back
                        </TextButton>
                        <Button onClick={submit} data-e2e="location-step-submit">
                            Next
                        </Button>
                    </ButtonBar>
                </FormLayout>
                <SidebarLayout.MobileFooter split="-1">
                    <Text>What does this mean?</Text>
                    <SidebarLayout.Link appearance="secondary" panelIndex={1}>
                        Let us explain
                    </SidebarLayout.Link>
                </SidebarLayout.MobileFooter>
            </StackLayout>
        </SidebarLayout>
    );
}
