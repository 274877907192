import React from 'react';
import {
    TechVerticalProductSelectionCard,
    TechVerticalRecommendationType,
} from './TechVerticalProductSelectionCard.view';
import { TechVerticalCoverageType } from './TechVerticalProductSelectionPage';
import { Text } from '@embroker/ui-toolkit/v2';

export interface TechVerticalProductSelectionCardProps {
    isSelected: boolean;
    onSelectionChange: (appType: TechVerticalCoverageType) => void;
    className?: string;
    handleTooltipClick: () => void;
    canSelect?: boolean;
    naicsCode?: string | null;
}

export function TechEOCard({
    isSelected,
    onSelectionChange,
    className,
    handleTooltipClick,
    canSelect,
    naicsCode,
}: TechVerticalProductSelectionCardProps) {
    const getRecommendationType = (naics?: string | null) => {
        switch (naics) {
            case '541512':
            case '541690': {
                return TechVerticalRecommendationType.mustHaveForTechConsultants;
            }
            default: {
                return TechVerticalRecommendationType.mustHaveForTechCompanies;
            }
        }
    };

    return (
        <TechVerticalProductSelectionCard
            canSelect={canSelect}
            className={className}
            recommendationType={getRecommendationType(naicsCode)}
            iconName="chip"
            title="Technology Errors and Omissions / Cyber"
            columnLists={{
                leftColumn: {
                    title: 'Common coverages:',
                    list: [
                        'Errors, omissions, or misstatements',
                        'Negligence',
                        'Computer fraud',
                        'Privacy breaches',
                    ],
                },
                rightColumn: {
                    title: 'Why you need this:',
                    list: [
                        'It’s comprehensive — two full policies in one',
                        'Contractual compliance',
                        'It covers independent contractors and any non-U.S. employees',
                    ],
                },
            }}
            isSelected={isSelected}
            onTitleTooltipClick={handleTooltipClick}
            onCoverageSelectionChange={() => onSelectionChange(TechVerticalCoverageType.techEO)}
        >
            <Text style="body 1">
                Covers liability claims that allege financial losses arising from your tech products
                or services, as well as costs arising from a security breach (ransomware, system
                damage, etc.).
            </Text>
        </TechVerticalProductSelectionCard>
    );
}

export function DirectorsAndOfficersCard({
    className,
    isSelected,
    canSelect,
    onSelectionChange,
    handleTooltipClick,
}: TechVerticalProductSelectionCardProps) {
    return (
        <TechVerticalProductSelectionCard
            canSelect={canSelect}
            className={className}
            recommendationType={TechVerticalRecommendationType.none}
            iconName="meeting"
            title="Directors and Officers"
            columnLists={{
                leftColumn: {
                    title: 'Common coverages:',
                    list: [
                        'Shareholder claims',
                        'Contract disputes',
                        'Regulatory gaps',
                        'Allegations of financial loss',
                    ],
                },
                rightColumn: {
                    title: 'Why you need this:',
                    list: [
                        'Typically required by executives/investors to protect personal assets',
                        'It shows investors you’re serious and prepared',
                    ],
                },
            }}
            isSelected={isSelected}
            onTitleTooltipClick={handleTooltipClick}
            onCoverageSelectionChange={() =>
                onSelectionChange(TechVerticalCoverageType.directorsAndOfficers)
            }
        >
            <Text style="body 1">
                Protects your company’s decision makers against claims and lawsuits alleging certain
                wrongful acts in managing the business.
            </Text>
        </TechVerticalProductSelectionCard>
    );
}

export function EPLCard({
    className,
    isSelected,
    canSelect,
    onSelectionChange,
    handleTooltipClick,
}: TechVerticalProductSelectionCardProps) {
    return (
        <TechVerticalProductSelectionCard
            canSelect={canSelect}
            className={className}
            recommendationType={TechVerticalRecommendationType.none}
            iconName="suitcase"
            title="Employment Practices Liability"
            columnLists={{
                leftColumn: {
                    title: 'Common coverages:',
                    list: [
                        'Wrongful termination',
                        'Failure to promote',
                        'Harassment and retaliation',
                        'Breach of employment contract',
                    ],
                },
                rightColumn: {
                    title: 'Why you need this:',
                    list: [
                        'Employee claims are on the rise: EEOC received 81,055 discrimination charges in 2023',
                        'Covers costs associated with reputation repair',
                    ],
                },
            }}
            isSelected={isSelected}
            onTitleTooltipClick={handleTooltipClick}
            onCoverageSelectionChange={() =>
                onSelectionChange(TechVerticalCoverageType.employmentPractices)
            }
        >
            <Text style="body 1">
                Provides financial protection from employee lawsuits alleging either unfair or
                inappropriate acts were committed against them by someone who represents your
                company.
            </Text>
        </TechVerticalProductSelectionCard>
    );
}

export function FiduciaryCard({
    className,
    isSelected,
    canSelect,
    onSelectionChange,
    handleTooltipClick,
}: TechVerticalProductSelectionCardProps) {
    return (
        <TechVerticalProductSelectionCard
            canSelect={canSelect}
            className={className}
            recommendationType={TechVerticalRecommendationType.none}
            iconName="handshake"
            title="Fiduciary Liability"
            columnLists={{
                leftColumn: {
                    title: 'Common coverages:',
                    list: [
                        'Errors or omissions in the administration of ERISA-regulated benefit plans',
                        'Imprudent investment decisions',
                    ],
                },
                rightColumn: {
                    title: 'Why you need this:',
                    list: [
                        'Your company grants stock options',
                        'You offer an employee benefit plan',
                    ],
                },
            }}
            isSelected={isSelected}
            onTitleTooltipClick={handleTooltipClick}
            onCoverageSelectionChange={() => onSelectionChange(TechVerticalCoverageType.fiduciary)}
        >
            <Text style="body 1">
                Protects your company and the people administering your benefits plans against
                financial losses in lawsuits alleging mismanagement of funds and benefit programs.
            </Text>
        </TechVerticalProductSelectionCard>
    );
}
