import { ContainerModule } from '@embroker/shotwell/core/di';
import { Module } from '../../Module';
import { CNAQuoteRepository } from './repositories/CNAQuoteRepository';
import { APICNAQuoteRepository } from './repositories/CNAQuoteRepository/APICNAQuoteRepository';
import { GetInitialCNAQuote } from './useCases/GetInitialCNAQuote';
import { PurchaseCNA } from './useCases/PurchaseCNA';
import { QuoteCNA } from './useCases/QuoteCNA';
import { SubmitCNAQuoteForReview } from './useCases/SubmitCNAQuoteForReview';

export const BOPCNAQuoteModule: Module = {
    container: new ContainerModule((bind) => {
        bind<CNAQuoteRepository>(CNAQuoteRepository).to(APICNAQuoteRepository).inSingletonScope();
        bind<GetInitialCNAQuote>(GetInitialCNAQuote.type).to(GetInitialCNAQuote).inSingletonScope();
        bind<QuoteCNA>(QuoteCNA.type).to(QuoteCNA).inSingletonScope();
        bind<PurchaseCNA>(PurchaseCNA.type).to(PurchaseCNA).inSingletonScope();
        bind<SubmitCNAQuoteForReview>(SubmitCNAQuoteForReview.type)
            .to(SubmitCNAQuoteForReview)
            .inSingletonScope();
    }),
};
