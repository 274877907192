import { container } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import {
    Card,
    CardProps,
    ColumnLayout,
    StatusLabel,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React, { useCallback, useContext, useMemo } from 'react';
import { ApplicationAccessStartQuote } from '../../../../../shopping/entities/Application';
import { AppTypeCode } from '../../../../../shopping/types/enums';
import { ProductIcon } from '../../../../../shopping/view/components/ProductIcon';
import { AppContext } from '../../../../../view/AppContext';
import { BrokerCoverageType } from '../../getAQuoteWizard/createBrokerSelectProductForm';
import { GetAQuoteWizard } from '../../getAQuoteWizard/GetAQuoteWizard';

export interface NaicsAppetiteAppCardProps extends CardProps {
    naicsCode: string;
    appType: AppTypeCode;
    isInAppetite: boolean;
    isPartiallyInAppetite: boolean;
    cardTextToDisplay: string;
    isDefault: boolean;
}

export function NaicsAppetiteAppCard({
    naicsCode,
    appType,
    isInAppetite,
    isPartiallyInAppetite,
    cardTextToDisplay,
    isDefault,
    ...props
}: NaicsAppetiteAppCardProps) {
    const { setSlideout } = useContext(AppContext);
    const coverageType = mapAppTypeToCoverageType.get(appType) || '';
    const eventBus = useMemo(() => container.get<DomainEventBus>(DomainEventBus), []);
    const shouldShowCard = isInAppetite || isPartiallyInAppetite;

    const handleGetQuote = useCallback(() => {
        setSlideout(
            <GetAQuoteWizard isPreselected initialData={{ coverageType, naics: naicsCode }} />,
            {
                isDismissable: false,
            },
        );
        const event: ApplicationAccessStartQuote = {
            id: UUID.create(),
            origin: 'Application',
            name: 'AccessStartQuote',
            createdAt: new Date(Date.now()),
        };
        eventBus.publish(event);
    }, [setSlideout, coverageType, eventBus, naicsCode]);
    return (
        <Card {...props} disabled={isDefault ? false : !shouldShowCard} appearance="compact">
            <Card.Header>
                <ProductIcon type={appType} />
                <Text style="heading 5" as="span">
                    {coverageType}
                </Text>
            </Card.Header>
            <Card.Body>
                <Text>{mapAppTypeToNaicsAppetiteDescription.get(appType)}</Text>
            </Card.Body>
            <Card.Footer>
                {shouldShowCard && (
                    <ColumnLayout split="-1">
                        <StatusLabel color="primary-100">{cardTextToDisplay}</StatusLabel>
                        <TextButton
                            onClick={() => {
                                handleGetQuote();
                            }}
                        >
                            Start application
                        </TextButton>
                    </ColumnLayout>
                )}
            </Card.Footer>
        </Card>
    );
}

const mapAppTypeToCoverageType: Map<AppTypeCode, string> = new Map([
    ['AppTypeCodeListESP', BrokerCoverageType.ESP],
    ['AppTypeCodeListPCoML', BrokerCoverageType.PCOML],
    ['AppTypeCodeListEverestLawyersProfessionalLiability', BrokerCoverageType.LPL],
    ['AppTypeCodeListEmbrokerCrime', BrokerCoverageType.Crime],
    ['AppTypeCodeListEmbrokerCyber', BrokerCoverageType.Cyber],
]);

const mapAppTypeToNaicsAppetiteDescription: Map<AppTypeCode, string> = new Map([
    [
        'AppTypeCodeListESP',
        'D&O, EPL, Cyber, E&O, and Fiduciary coverage for venture funded startups. ',
    ],
    ['AppTypeCodeListPCoML', 'D&O and EPL coverage for privately-held companies.'],
    [
        'AppTypeCodeListEverestLawyersProfessionalLiability',
        'Professional liability / E&O insurance for Law Firms, underwritten by Everest.',
    ],
    ['AppTypeCodeListEmbrokerCrime', 'Coverage for employee theft, fraud, forgery, etc.'],
    ['AppTypeCodeListEmbrokerCyber', 'Coverage for cyber-related security breaches.'],
]);
