import { valueObject } from '@embroker/shotwell/core/types/ValueObject';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { QuoteOptions } from '../../../../quote/entities/Quote';

export const LawCyberLimit = [100_000] as const;
export type LawCyberLimit = (typeof LawCyberLimit)[number];
export const LawCyberRetention = [2_500, 5_000, 10_000, 25_000, 50_000, 75_000, 100_000] as const;
export type LawCyberRetention = (typeof LawCyberRetention)[number];

export interface LawCyberQuoteOptions extends QuoteOptions {
    readonly limit: number;
    readonly retention: number;
    readonly isDeselected: boolean;
}

export const LawCyberQuoteOptions = valueObject({
    ...defineValidator<LawCyberQuoteOptions>({
        effectiveDate: Joi.date().required(),
        limit: Joi.number()
            .valid(...LawCyberLimit)
            .required(),
        retention: Joi.number()
            .allow(...LawCyberRetention)
            .required(),
        isDeselected: Joi.boolean().required(),
    }),
});
