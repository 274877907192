import { UUID } from '@embroker/shotwell/core/types/UUID';
import { map, route, withData } from 'navi';
import React from 'react';
import { CyberQuoteLandingPage } from '../../cyber/view/components/CyberQuoteLandingPage';
import { execute } from '@embroker/shotwell/core/UseCase';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { GetLastCyberQuote } from '../../cyber/useCases/GetLastCyberQuote';
import { GetCyberConfig } from '../../cyber/useCases/GetCyberConfig';
import { isValid, parseISO, startOfDay, startOfToday } from 'date-fns';
import { redirectToWhoopsPage } from '../../../view/errors';

function getToday(todayOverride?: string): () => Date {
    return () => {
        return todayOverride && isValid(parseISO(todayOverride))
            ? startOfDay(parseISO(todayOverride))
            : startOfToday();
    };
}

const cyberWizardRouteHandler = map(async (request) => {
    const { applicationId } = request.query;
    const validatedApplicationIdResult = UUID.validate(applicationId);

    if (isErr(validatedApplicationIdResult)) {
        return redirectToWhoopsPage(validatedApplicationIdResult.errors);
    }

    const getLastQuoteResult = await execute(GetLastCyberQuote, {
        applicationId: validatedApplicationIdResult.value,
    });

    if (isErr(getLastQuoteResult)) {
        return redirectToWhoopsPage(getLastQuoteResult.errors);
    }

    const getCyberConfigResult = await execute(GetCyberConfig);
    const cyberDevModeEffectiveDate = isOK(getCyberConfigResult)
        ? getCyberConfigResult.value.cyberDevModeEffectiveDate
        : undefined;
    const isTestMode = cyberDevModeEffectiveDate !== '';

    return route({
        title: 'Cyber Quote',
        view: (
            <CyberQuoteLandingPage
                applicationId={validatedApplicationIdResult.value}
                initialCyberQuote={getLastQuoteResult.value}
                getToday={getToday(cyberDevModeEffectiveDate)}
                isTestMode={isTestMode}
            />
        ),
    });
});

export const cyberRoutes = {
    '/': map((request) =>
        withData(
            { url: request.mountpath, page: undefined, fullscreen: true },
            cyberWizardRouteHandler,
        ),
    ),
    '/:page': map((request) =>
        withData(
            { url: request.mountpath, page: request.params.page, fullscreen: true },
            cyberWizardRouteHandler,
        ),
    ),
};
