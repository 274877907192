import { RouteDefinitions } from '../../../view/routes/Route';
import { map, redirect, route } from 'navi';
import { AppContext } from '../../../view/AppContext';
import { DashboardSummary } from '../components/DashboardSummary';
import React from 'react';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { QuotingEngine } from '../../../shopping/types/enums';
import { hasRole } from '../../../userOrg/entities/Session';
import { URI } from '@embroker/shotwell/core/types/URI';
import { execute } from '@embroker/shotwell/core/UseCase';
import { GetReturnUserLoginRedirect } from '@app/userOrg/useCases/GetReturnUserLoginRedirect';
import { isOK } from '@embroker/shotwell/core/types/Result';

export const routes: RouteDefinitions<AppContext> = {
    '/summary': map(async (req, context: AppContext) => {
        const { activeSession } = context;

        const isBroker = hasRole(activeSession, 'broker');

        if (isBroker) {
            return redirect(URI.build('/broker/dashboard/'));
        }

        const getReturnUserLoginRedirectResp = await execute(GetReturnUserLoginRedirect);
        const returnUserLoginRedirect = isOK(getReturnUserLoginRedirectResp)
            ? getReturnUserLoginRedirectResp.value
            : null;

        if (returnUserLoginRedirect) {
            return redirect(
                URI.build('/user/nbrv-redirect-gate', {
                    redirectTo: returnUserLoginRedirect.redirectTo || '/',
                    useCase: returnUserLoginRedirect.useCase,
                    coverageName: returnUserLoginRedirect.coverageName,
                    appType: returnUserLoginRedirect.appType,
                }),
                { exact: true },
            );
        }

        const { applicationId, modal, quotingEngine, policyId } = req.query;
        return route({
            title: 'Embroker | Dashboard',
            view: (
                <DashboardSummary
                    quotedApplicationId={applicationId as UUID}
                    modalType={modal}
                    quotedApplicationQuotingEngine={quotingEngine as QuotingEngine}
                    policyId={policyId as UUID}
                />
            ),
            data: { hideHeader: true },
        });
    }),
};
