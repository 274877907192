import { API } from '@embroker/shotwell-api/app';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { CarrierNotFound } from '../../errors';
import { ExcessCarrier } from '../../types/ExcessCarrier';
import { ExcessCarrierRepository } from './index';

@injectable()
export class APIExcessCarrierRepository implements ExcessCarrierRepository {
    public async getCarrier(
        id: UUID,
    ): AsyncResult<ExcessCarrier, CarrierNotFound | InvalidArgument> {
        const result = await API.request('excess/get_carrier', { carrier_id: id });

        if (isErr(result)) {
            return Failure(CarrierNotFound());
        }

        return ExcessCarrier.create({
            id,
            carrierName: result.value.carrier_name,
            carrierPaper: result.value.paper,
            policyFormName: result.value.cyber_policy_form_name,
            cyberCoverageName: result.value.cyber_coverage_name,
        });
    }

    public async getCarriers(
        searchTerm: string,
    ): AsyncResult<ExcessCarrier[], InvalidArgument | OperationFailed> {
        const response = await API.request('excess/get_carriers', { searchTerm });

        if (isErr(response)) {
            return handleOperationFailure(response);
        }

        const carrierList: ExcessCarrier[] = [];

        for (const carrier of response.value) {
            const result = ExcessCarrier.create({
                // a temporary assertion until we sync with our api/backend types
                id: carrier.id as UUID,
                carrierName: carrier.carrier_name,
                carrierPaper: carrier.paper,
                policyFormName: carrier.cyber_policy_form_name,
                cyberCoverageName: carrier.cyber_coverage_name,
            });

            if (isErr(result)) {
                return Failure(
                    OperationFailed({
                        message: 'Failed to convert excess carrier',
                        errors: result.errors,
                    }),
                );
            }
            carrierList.push(result.value);
        }

        return Success(carrierList);
    }
}
