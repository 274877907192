import { Immutable } from '@embroker/shotwell/core/types';
import { ErrorLike } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import {
    Grid,
    ColumnLayout,
    Modal,
    ScrollBox,
    StackLayout,
    useModal,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React, { useCallback, useContext } from 'react';
import { hasRole } from '../../../../userOrg/entities/Session';
import { AppContext } from '../../../../view/AppContext';
import { ESPEndorsementPolicy } from '../../types/ESPEndorsementPolicy';
import { ESPEndorsementNamedInsured } from './ESPEndorsementNamedInsured';
import { ESPEndorsementCardLayout } from '@app/endorsement/esp/view/components/ESPEndorsementCardLayout.view';
import { ESPEndorsementValueLabel } from '@app/endorsement/esp/view/components/ESPEndorsementValueLabel.view';
import { EndorsementSlideout } from '@app/endorsement/intake/view/components/EndorsementSlideout';

interface ESPEndorsementNamedInsuredSectionProps {
    policyId: UUID;
    namedInsured: string;
    effectivePeriodStart: Date;
    effectivePeriodEnd: Date;
    isEndorsementDisabled: boolean;
    hasRenewalApplication?: boolean;
    onSaveEndorsementSuccess: (updatedPolicy: ESPEndorsementPolicy) => void;
    onSaveEndorsementFailure: (errors: Immutable<ErrorLike[]>) => void;
}

export function ESPEndorsementNamedInsuredSection({
    policyId,
    namedInsured,
    effectivePeriodStart,
    effectivePeriodEnd,
    isEndorsementDisabled,
    onSaveEndorsementSuccess,
    onSaveEndorsementFailure,
    hasRenewalApplication,
}: ESPEndorsementNamedInsuredSectionProps) {
    const namedInsuredModal = useModal();
    const { setSlideout, activeSession } = useContext(AppContext);
    const isAdmin = hasRole(activeSession, 'admin');
    function handleEndorsementsDismissed() {
        setSlideout(null);
    }
    function handleOpenManualEndorsementModal() {
        setSlideout(<EndorsementSlideout onDismiss={handleEndorsementsDismissed} />, {
            isDismissable: true,
        });
    }

    const handleOpenNamedInsuredModal = useCallback(() => {
        namedInsuredModal.show();
    }, [namedInsuredModal]);

    const handleCloseNamedInsuredModal = useCallback(() => {
        namedInsuredModal.hide();
    }, [namedInsuredModal]);

    const handleOpenChangeName = isAdmin
        ? handleOpenNamedInsuredModal
        : handleOpenManualEndorsementModal;

    const handleSaveNamedInsuredSuccess = useCallback(
        (updatedPolicy: ESPEndorsementPolicy) => {
            namedInsuredModal.hide();
            return onSaveEndorsementSuccess(updatedPolicy);
        },
        [namedInsuredModal, onSaveEndorsementSuccess],
    );

    return (
        <ESPEndorsementCardLayout>
            <Grid>
                <Grid.Row>
                    <Grid.Cell>
                        <ColumnLayout>
                            <Text style="heading 3">Named insured</Text>
                            <StackLayout gap="none">
                                <span></span>
                                <TextButton
                                    disabled={isEndorsementDisabled}
                                    onClick={handleOpenChangeName}
                                    data-e2e="edit-name"
                                >
                                    Edit
                                </TextButton>
                            </StackLayout>
                        </ColumnLayout>
                    </Grid.Cell>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Cell data-e2e="named-insured">
                        <ESPEndorsementValueLabel title="Name Insured" value={namedInsured} />
                    </Grid.Cell>
                </Grid.Row>
                <Modal {...namedInsuredModal} size="medium">
                    <ScrollBox>
                        {namedInsuredModal.visible ? (
                            <ESPEndorsementNamedInsured
                                policyId={policyId}
                                effectivePeriodStart={effectivePeriodStart}
                                effectivePeriodEnd={effectivePeriodEnd}
                                namedInsured={namedInsured}
                                onSaveEndorsementSuccess={handleSaveNamedInsuredSuccess}
                                onSaveEndorsementFailure={onSaveEndorsementFailure}
                                onClose={handleCloseNamedInsuredModal}
                                hasRenewalApplication={hasRenewalApplication}
                            />
                        ) : (
                            ''
                        )}
                    </ScrollBox>
                </Modal>
            </Grid>
        </ESPEndorsementCardLayout>
    );
}
