import { DigitalEndorsementStatusCodeListItem } from '@embroker/shotwell-api/enums';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, Success, isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { DigitalEndorsementsRepository } from '../repositories/digitalEndorsements';

export interface EndorsementRecord {
    id: UUID;
    serialNumber: number;
    policyId: UUID;
    endorsementType: string;
    origin: string;
    isPremiumBearing: boolean;
    data: unknown;
    createdAt: Date;
    status: DigitalEndorsementStatusCodeListItem;
    documentFileKey?: string;
}

export interface GetEndorsementRecordsRequest {
    policyId: UUID;
}

export interface GetEndorsementRecordsResponse {
    endorsements: Immutable<EndorsementRecord[]>;
}

export interface GetEndorsementRecords extends UseCase {
    execute(
        request: GetEndorsementRecordsRequest,
    ): AsyncResult<GetEndorsementRecordsResponse, InvalidArgument | OperationFailed>;
}

const POSTBIND_ENDORSEMENT_ORIGIN = 'postbind-endorsement';

@injectable()
class GetEndorsementRecordsUseCase extends UseCase implements GetEndorsementRecords {
    public static type = Symbol('DigitalEndorsements/GetEndorsementRecords');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(DigitalEndorsementsRepository)
        private digitalEndorsementsRepository: DigitalEndorsementsRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: GetEndorsementRecordsRequest,
    ): AsyncResult<GetEndorsementRecordsResponse, InvalidArgument | OperationFailed> {
        const listEndorsementsResult = await this.digitalEndorsementsRepository.listEndorsements({
            policyId: request.policyId,
        });

        if (isErr(listEndorsementsResult)) {
            return listEndorsementsResult;
        }

        const endorsements = listEndorsementsResult.value.filter((endorsement) => {
            return endorsement.origin === POSTBIND_ENDORSEMENT_ORIGIN;
        });

        return Success({
            endorsements,
        });
    }
}

export const GetEndorsementRecords: UseCaseClass<GetEndorsementRecords> =
    GetEndorsementRecordsUseCase;
