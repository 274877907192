import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface Settings {
    readonly googleTagManagerToken: string;
}

export const Settings = {
    ...defineValidator<Settings>({
        googleTagManagerToken: Joi.string(),
    }),
    create(settings: Settings) {
        return Settings.validate(settings);
    },
};
