import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Money } from '@embroker/shotwell/core/types/Money';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ESPEndorsementRepository } from '../repositories/ESPEndorsementRepository';
import { EditESPEndorsementLiabilityCoverageType } from '../types/ESPEndorsementLiabilityCoverageType';
import { ESPEndorsementRate } from '../types/ESPEndorsementRate';

export interface RateEditEndorsementCoverage {
    coverageTypeCode: EditESPEndorsementLiabilityCoverageType;
    limit: Money;
    retention: Money;
}
export interface RateEditMultipleCoverageESPEndorsementRequest {
    agreementId: UUID;
    coverages: RateEditEndorsementCoverage[];
    effectiveDate: Date;
}

export interface RateEditMultipleCoverageESPEndorsement extends UseCase {
    execute(
        request: RateEditMultipleCoverageESPEndorsementRequest,
    ): AsyncResult<ESPEndorsementRate, InvalidArgument | OperationFailed>;
}

@injectable()
class RateEditMultipleCoverageUseCase
    extends UseCase
    implements RateEditMultipleCoverageESPEndorsement
{
    public static type = Symbol('ESPEndorsement/RateEditMultipleCoverageESPEndorsement');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ESPEndorsementRepository)
        private espEndorsementRepository: ESPEndorsementRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        agreementId,
        coverages,
        effectiveDate,
    }: RateEditMultipleCoverageESPEndorsementRequest): AsyncResult<
        ESPEndorsementRate,
        OperationFailed | InvalidArgument
    > {
        return await this.espEndorsementRepository.rateEditMultipleCoverageEndorsement({
            agreementId: agreementId,
            coverages: coverages,
            effectiveDate: effectiveDate,
        });
    }
}

export const RateEditMultipleCoverageESPEndorsement: UseCaseClass<RateEditMultipleCoverageESPEndorsement> =
    RateEditMultipleCoverageUseCase;
