import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { GetApplicant } from '../../../shopping/useCases/GetApplicant';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { GetActiveUserProfile } from '../../../userOrg/useCases/GetActiveUserProfile';

export function useDisablePayments() {
    const { result: getApplicantResult } = useUseCase(GetApplicant);
    const { result: userProfileResult } = useUseCase(GetActiveUserProfile);

    if (
        getApplicantResult &&
        isOK(getApplicantResult) &&
        userProfileResult &&
        isOK(userProfileResult)
    ) {
        const user = userProfileResult?.value;
        const applicant = getApplicantResult?.value.applicant;
        const doesApplicantHaveHeadquartersInKentucky =
            applicant.headquarters.state === 'KY' ||
            applicant.locations?.some((location) => location.state === 'KY');
        if (doesApplicantHaveHeadquartersInKentucky) {
            return {
                isPaymentDisabled: true,
                paymentDisabledMessage: `"We are currently working to calculate your state taxes. You can expect to receive an invoice and instructions on how to pay at ${user.email} within 1-2 business days."`,
            };
        }
    }

    return {
        isPaymentDisabled: false,
        paymentDisabledMessage: '',
    };
}
