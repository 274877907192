import { ContainerModule } from '@embroker/shotwell/core/di';
import { Module } from '../Module';
import { BrokerRepository } from './repositories';
import { APIBrokerRepository } from './repositories/APIBrokerRepository';
import { BrokerSignUp } from './useCases/BrokerSignUp';
import { CompleteShareableApplication } from './useCases/CompleteShareableApplication';
import { EditApplication } from './useCases/EditApplication';
import { EditClientApplication } from './useCases/EditClientApplication';
import { GetApplicationInCreationQuestionnaire } from './useCases/GetApplicationInCreationQuestionnaire';
import { GetBoundPoliciesData } from './useCases/GetBoundPoliciesData';
import { GetBrokerActivity } from './useCases/GetBrokerActivity';
import { GetBrokerageByBroker } from './useCases/GetBrokerageByBroker';
import { GetBrokerageOrganizationInfo } from './useCases/GetBrokerageOrganizationInfo';
import { GetBrokerageOutstandingPayments } from './useCases/GetBrokerageOutstandingPayments';
import { GetBrokerOrganizations } from './useCases/GetBrokerOrganizations';
import { GetBrokerPolicies } from './useCases/GetBrokerPolicies';
import { GetBrokerQuotes } from './useCases/GetBrokerQuotes';
import { GetBrokerRenewals } from './useCases/GetBrokerRenewals';
import { GetBrokers } from './useCases/GetBrokers';
import { GetClientApplicationFormEngine } from './useCases/GetClientApplicationFormEngine';
import { GetConfig } from './useCases/GetConfig';
import { GetDigitalQuotesData } from './useCases/GetDigitalQuotesData';
import { GetGrossPremiumData } from './useCases/GetGrossPremiumData';
import { GetInvitationStatus } from './useCases/GetInvitationStatus';
import { GetLookerReportUrl } from './useCases/GetLookerReportUrl';
import { GetNaicsEligibleAppTypes } from './useCases/GetNaicsEligibleAppTypes';
import { GetQuickLinkTotals } from './useCases/GetQuickLinkTotals';
import { GetQuoteToBindRatio } from './useCases/GetQuoteToBindRatio';
import { GetSignatureDocument } from './useCases/GetSignatureDocument';
import { GetSignatureDocumentUrl } from './useCases/GetSignatureDocumentUrl';
import { IntakeAdditionalInfo } from './useCases/IntakeAdditionalInfo';
import { PromoteApplication } from './useCases/PromoteApplication';
import { PromoteShareableApplication } from './useCases/PromoteShareableApplication';
import { RequestLossRuns } from './useCases/RequestLossRuns';
import { SignSignatureRequest } from './useCases/SignSignatureRequest';
import { UpdateBrokerProfile } from './useCases/UpdateBrokerProfile';
import { UpdateClientApplication } from './useCases/UpdateClientApplication';
import { UpdateClientOrganization } from './useCases/UpdateClientOrganization';
import { UploadBORLetter } from './useCases/UploadBORLetter';
import { routes } from './view/routes';
import { PrecreateApplication } from './useCases/PrecreateApplication';

export const BrokerDashboardModule: Module = {
    routes,
    container: new ContainerModule((bind) => {
        bind<BrokerRepository>(BrokerRepository).to(APIBrokerRepository).inSingletonScope();
        bind<GetBrokers>(GetBrokers.type).to(GetBrokers).inSingletonScope();
        bind<UpdateBrokerProfile>(UpdateBrokerProfile.type)
            .to(UpdateBrokerProfile)
            .inSingletonScope();
        bind<GetBrokerageByBroker>(GetBrokerageByBroker.type)
            .to(GetBrokerageByBroker)
            .inSingletonScope();
        bind<GetBrokerQuotes>(GetBrokerQuotes.type).to(GetBrokerQuotes).inSingletonScope();
        bind<GetNaicsEligibleAppTypes>(GetNaicsEligibleAppTypes.type)
            .to(GetNaicsEligibleAppTypes)
            .inSingletonScope();
        bind<EditApplication>(EditApplication.type).to(EditApplication).inSingletonScope();
        bind<GetBrokerPolicies>(GetBrokerPolicies.type).to(GetBrokerPolicies).inSingletonScope();
        bind<GetBrokerageOutstandingPayments>(GetBrokerageOutstandingPayments.type)
            .to(GetBrokerageOutstandingPayments)
            .inSingletonScope();
        bind<GetBrokerOrganizations>(GetBrokerOrganizations.type)
            .to(GetBrokerOrganizations)
            .inSingletonScope();
        bind<GetBrokerageOrganizationInfo>(GetBrokerageOrganizationInfo.type)
            .to(GetBrokerageOrganizationInfo)
            .inSingletonScope();
        bind<GetConfig>(GetConfig.type).to(GetConfig).inSingletonScope();
        bind<SignSignatureRequest>(SignSignatureRequest.type)
            .to(SignSignatureRequest)
            .inSingletonScope();
        bind<GetSignatureDocument>(GetSignatureDocument.type)
            .to(GetSignatureDocument)
            .inSingletonScope();
        bind<GetSignatureDocumentUrl>(GetSignatureDocumentUrl.type)
            .to(GetSignatureDocumentUrl)
            .inSingletonScope();
        bind<GetBrokerRenewals>(GetBrokerRenewals.type).to(GetBrokerRenewals).inSingletonScope();
        bind<GetBrokerActivity>(GetBrokerActivity.type).to(GetBrokerActivity).inSingletonScope();
        bind<GetQuickLinkTotals>(GetQuickLinkTotals.type).to(GetQuickLinkTotals).inSingletonScope();
        bind<GetLookerReportUrl>(GetLookerReportUrl.type).to(GetLookerReportUrl).inSingletonScope();
        bind<IntakeAdditionalInfo>(IntakeAdditionalInfo.type)
            .to(IntakeAdditionalInfo)
            .inSingletonScope();
        bind<GetQuoteToBindRatio>(GetQuoteToBindRatio.type)
            .to(GetQuoteToBindRatio)
            .inSingletonScope();
        bind<GetDigitalQuotesData>(GetDigitalQuotesData.type)
            .to(GetDigitalQuotesData)
            .inSingletonScope();
        bind<GetBoundPoliciesData>(GetBoundPoliciesData.type)
            .to(GetBoundPoliciesData)
            .inSingletonScope();
        bind<GetGrossPremiumData>(GetGrossPremiumData.type)
            .to(GetGrossPremiumData)
            .inSingletonScope();
        bind<GetClientApplicationFormEngine>(GetClientApplicationFormEngine.type)
            .to(GetClientApplicationFormEngine)
            .inSingletonScope();
        bind<UpdateClientApplication>(UpdateClientApplication.type)
            .to(UpdateClientApplication)
            .inSingletonScope();
        bind<EditClientApplication>(EditClientApplication.type)
            .to(EditClientApplication)
            .inSingletonScope();
        bind<UpdateClientOrganization>(UpdateClientOrganization.type)
            .to(UpdateClientOrganization)
            .inSingletonScope();
        bind<CompleteShareableApplication>(CompleteShareableApplication.type)
            .to(CompleteShareableApplication)
            .inSingletonScope();
        bind<PromoteShareableApplication>(PromoteShareableApplication.type)
            .to(PromoteShareableApplication)
            .inSingletonScope();
        bind<GetApplicationInCreationQuestionnaire>(GetApplicationInCreationQuestionnaire.type)
            .to(GetApplicationInCreationQuestionnaire)
            .inSingletonScope();
        bind<PromoteApplication>(PromoteApplication.type).to(PromoteApplication).inSingletonScope();
        bind<UploadBORLetter>(UploadBORLetter.type).to(UploadBORLetter).inSingletonScope();
        bind<RequestLossRuns>(RequestLossRuns.type).to(RequestLossRuns).inSingletonScope();
        bind<GetInvitationStatus>(GetInvitationStatus.type)
            .to(GetInvitationStatus)
            .inSingletonScope();
        bind<BrokerSignUp>(BrokerSignUp.type).to(BrokerSignUp).inSingletonScope();
        bind<PrecreateApplication>(PrecreateApplication.type)
            .to(PrecreateApplication)
            .inSingletonScope();
    }),
};
