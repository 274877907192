import { inject, injectable } from '@embroker/shotwell/core/di';
import {
    InvalidArgument,
    NotAllowed,
    OperationFailed,
    UnknownEntity,
} from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { execute, UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { EmailAlreadyInUse, InvalidEmail, MaxNumberOfSignUpAttemptsExceeded } from '../errors';
import { Token } from '../types/Token';
import { GetInvitedOrganizationData } from './GetInvitedOrganizationData';
import { SignUp } from './SignUp';
import { UserSignUpEmailProvided } from '../entities/User';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { PublishSignUpStartedEvent } from './PublishSignUpStartedEvent';

/**
 * SignUpInvitedUser Use Case Request model.
 */
export interface SignUpInvitedUserRequest {
    userInvitationToken?: Token;
    certificateInviteToken?: Token;
}

export interface SignUpInvitedUser extends UseCase {
    execute(
        request: SignUpInvitedUserRequest,
    ): AsyncResult<
        | void
        | UnknownEntity
        | InvalidArgument
        | NotAllowed
        | OperationFailed
        | EmailAlreadyInUse
        | InvalidEmail
        | MaxNumberOfSignUpAttemptsExceeded
    >;
}

@injectable()
class SignUpInvitedUserUseCase extends UseCase implements SignUpInvitedUser {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/SignUpInvitedUser');
    /**
     * Constructor for SignUpInvitedUserUseCase use case class instance.
     *
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    public async execute(
        request: SignUpInvitedUserRequest,
    ): AsyncResult<
        | void
        | UnknownEntity
        | InvalidArgument
        | NotAllowed
        | OperationFailed
        | EmailAlreadyInUse
        | InvalidEmail
        | MaxNumberOfSignUpAttemptsExceeded
    > {
        if (request.userInvitationToken === null && request.certificateInviteToken === null) {
            const message = 'Both userInvitationToken and certificateInviteToken are null';
            return Failure(OperationFailed({ message }));
        }
        const getInvitedOrganizationDataResult = await execute(GetInvitedOrganizationData, {
            inviteToken: request.userInvitationToken || (request.certificateInviteToken as Token),
        });
        if (isErr(getInvitedOrganizationDataResult)) {
            return getInvitedOrganizationDataResult;
        }

        const userInvite = getInvitedOrganizationDataResult.value.userInvite;

        if (userInvite.email === null) {
            return Failure(OperationFailed({ message: 'userInvite.email is null' }));
        }
        if (userInvite.organizationName === null) {
            return Failure(OperationFailed({ message: 'userInvite.organizationName is null' }));
        }

        this.publishPreSignUpEvents();

        const signUpResult = await execute(SignUp, {
            emailAddress: userInvite.email,
            organizationName: userInvite.organizationName,
            signUpInviteToken: request.userInvitationToken ?? null,
            certificateInviteToken: request.certificateInviteToken ?? null,
        });
        if (isErr(signUpResult)) {
            return signUpResult;
        }

        return Success();
    }

    private publishPreSignUpEvents() {
        execute(PublishSignUpStartedEvent);
        const signUpEmailProvidedEvent: UserSignUpEmailProvided = {
            origin: 'User',
            name: 'SignUpEmailProvided',
            createdAt: new Date(Date.now()),
            id: UUID.create(),
        };
        this.eventBus.publish(signUpEmailProvidedEvent);
    }
}

export const SignUpInvitedUser: UseCaseClass<SignUpInvitedUser> = SignUpInvitedUserUseCase;
