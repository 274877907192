import { Money } from '@embroker/shotwell/core/types/Money';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface CrimeQuoteDetails {
    readonly quoteId: UUID;
    readonly totalPremium: Money;
    readonly subtotalAmount: Money;
    readonly previousPremium?: Money;
    readonly totalBasePremium: Money;
    readonly taxes: Money;
    readonly fees: Money;
    readonly mwuaFee?: Money;
    readonly policyAdministrationFee: Money;
    readonly specimenPolicyFileKey?: string;
}

export const CrimeQuoteDetails = {
    ...defineValidator<CrimeQuoteDetails>({
        quoteId: UUID.schema.required(),
        totalPremium: Money.schema.required(),
        subtotalAmount: Money.schema.required(),
        previousPremium: Money.schema.optional(),
        totalBasePremium: Money.schema.required(),
        taxes: Money.schema.required(),
        fees: Money.schema.required(),
        mwuaFee: Money.schema.optional(),
        policyAdministrationFee: Money.schema.required(),
        specimenPolicyFileKey: Joi.string().optional(),
    }),
    create(crimeQuote: CrimeQuoteDetails) {
        return CrimeQuoteDetails.validate(crimeQuote);
    },
};
