import { State } from '@embroker/shotwell/core/types/StateList';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

/**
 * An user info needed for Sign modals
 */
export interface WCGAUserInfo {
    /**
     * The name of the user.
     */
    readonly fullName: string;

    /**
     * The title of the user
     */
    readonly title: string;

    /**
     * The USA state of the user's company HQ
     */
    readonly state?: State;
}

export const WCGAUserInfo = {
    ...defineValidator<WCGAUserInfo>({
        fullName: Joi.string(),
        title: Joi.string(),
        state: State.schema.optional(),
    }),
    create(settings: WCGAUserInfo) {
        return WCGAUserInfo.validate(settings);
    },
};
