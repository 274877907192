import { isOK } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import {
    Nullable,
    StackLayout,
    UploadFile,
    UploadFileCard,
    UploadFileState,
} from '@embroker/ui-toolkit/v2';
import React, { useState } from 'react';
import { UploadFiles, UploadFilesRequest } from '../../../../documents/useCases/UploadFiles';
import { FileUpload } from '../../../../documents/view/FileUpload';

interface ApplicationUploadProps {
    onFileUpload: (fileKey: Nullable<string>) => void;
    onFileRemove: () => void;
}

function isSingleFile(files: File[]) {
    return files.length === 1;
}

function isFilePDF(file: File) {
    return file.type === 'application/pdf';
}

const isFileLargerThan25MB = (file: File) => {
    const maxSizeInBytes = 25 * 1024 * 1024;
    return file.size > maxSizeInBytes;
};

const errorMessageDisplayTime = 3000;

export function BrokerApplicationUpload({ onFileUpload, onFileRemove }: ApplicationUploadProps) {
    const [uploadPercent, setUploadPercent] = useState(0);
    const [dropBoxContainerState, setDropBoxContainerState] = useState<UploadFileState>('default');
    const [file, setFile] = useState<File>();

    const uploadFile = (file: FileUpload) => {
        const files = [file];
        let isLoaded = true;
        execute(UploadFiles, {
            files: files.map((item) => item.file),
            onFileUploadProgress: (uploaded, totalSize) => {
                setUploadPercent((uploaded / totalSize) * 100);
            },
        } as UploadFilesRequest).then((result) => {
            if (isLoaded && isOK(result)) {
                setFile(file.file);
                onFileUpload(result.value.uploadedFiles[0].fileKey);
            }
        });
        return () => {
            isLoaded = false;
        };
    };
    const handleNewFilesAdded = (files: File[]) => {
        if (!isSingleFile(files)) {
            setDropBoxContainerState('numberOfFilesError');
            setTimeout(() => {
                setDropBoxContainerState('default');
            }, errorMessageDisplayTime);
            return;
        }

        const newFile = files[0];

        if (!isFilePDF(newFile)) {
            setDropBoxContainerState('fileTypeError');
            setTimeout(() => {
                setDropBoxContainerState('default');
            }, errorMessageDisplayTime);
            return;
        }

        if (isFileLargerThan25MB(newFile)) {
            setDropBoxContainerState('fileSizeError');
            setTimeout(() => {
                setDropBoxContainerState('default');
            }, errorMessageDisplayTime);
            return;
        }

        const uploadId = UUID.create();
        const fileToUpload: FileUpload = {
            id: uploadId,
            file: newFile,
            s3FileKey: null,
        };

        uploadFile(fileToUpload);
    };

    const handleFileRemove = () => {
        setFile(undefined);
        setUploadPercent(0);
        onFileRemove();
    };
    return (
        <StackLayout gap="8">
            <UploadFile
                onFilesAdded={handleNewFilesAdded}
                uploadPercent={uploadPercent}
                onUploadAbort={() => {
                    //empty
                }}
                state={dropBoxContainerState}
                isMultiple={false}
                fileTypeFilters={['application/pdf']}
                showButton
                buttonText="Upload PDF file"
                placeholder="or drag file here."
            />

            {file && (
                <UploadFileCard
                    file={file}
                    onUploadAbort={handleFileRemove}
                    state="success"
                    uploadPercent={0}
                    className="u-1/2"
                />
            )}
        </StackLayout>
    );
}
