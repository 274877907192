import { URI } from '@embroker/shotwell/core/types/URI';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    BoxLayout,
    Button,
    ColumnLayout,
    Form,
    Modal,
    ModalActions,
    ModalState,
    ScrollBox,
    Spinner,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-navi';
import {
    PremiumFinanceUpdateBillingInfoCreditCard,
    PremiumFinanceUpdateBillingInfoCreditCardResponse,
} from '../../../../useCases/PremiumFinanceUpdateBillingInfoCreditCard';
import { errorsStyle } from '../../modals/PayWithCCModal';

interface BillingInfoCreditCardModalInput {
    quoteNumber: number;
    modal: ModalState & ModalActions;
    hideModal(): void;
    onContinue: (recurringCreditCardURL?: URI) => void;
    onSignedDateTimeTermsAndConditionsChange(dateTime: Date): void;
}

export interface BillingInfoCreditCardInput {
    readonly autoChargeCreditCard: boolean;
    readonly quoteNumber: number;
}

const BillingInfoCreditCardForm = createForm<BillingInfoCreditCardInput>({
    fields: {
        quoteNumber: {
            type: 'hidden',
            validator: Joi.number().required(),
        },
        autoChargeCreditCard: {
            type: 'checkbox',
            validator: Joi.boolean().optional(),
        },
    },
    useCase: PremiumFinanceUpdateBillingInfoCreditCard,
});

const CommercialRecurringCreditCardAuthorizationTermsAndConditions =
    'https://embroker-public.s3.amazonaws.com/PremiumFinance/Commercial+Recurring+Credit+Card+Authorization+Terms+And+Conditions.pdf';

export function BillingInfoCreditCardModal({
    modal,
    hideModal,
    quoteNumber,
    onContinue,
    onSignedDateTimeTermsAndConditionsChange,
}: BillingInfoCreditCardModalInput) {
    const { submit, fields, status, messages, result, setValue, value, reset } = useForm(
        BillingInfoCreditCardForm,
        { quoteNumber: quoteNumber, autoChargeCreditCard: false },
    );
    const [displayError, setDisplayError] = useState(false);
    const [modalClosed, setModalClosed] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (status == 'pristine' || status == 'submitting') {
            setModalClosed(false);
        }
        if (status == 'submitting') {
            setIsSubmitting(true);
            return;
        }
        setIsSubmitting(false);
        if (status == 'invalid') {
            if (messages.length > 0) {
                return setDisplayError(true);
            }
        }
        setDisplayError(false);
        if (result != undefined && status == 'submitted' && !modalClosed) {
            const { fifRecurringCreditCardURL, creditCardSignatureDateTime } =
                result as PremiumFinanceUpdateBillingInfoCreditCardResponse;
            hideModal();
            setModalClosed(true);
            onSignedDateTimeTermsAndConditionsChange(creditCardSignatureDateTime);
            window.open(fifRecurringCreditCardURL, '_blank', 'noopener,noreferrer');
            onContinue(fifRecurringCreditCardURL);
            return;
        }
    }, [
        status,
        result,
        hideModal,
        reset,
        messages,
        fields,
        modalClosed,
        onContinue,
        onSignedDateTimeTermsAndConditionsChange,
    ]);

    function handleBillingMethodChange(event: React.ChangeEvent<HTMLInputElement>) {
        setValue({
            ...value,
            autoChargeCreditCard: event.target.checked,
        });
    }

    function handleOnPerInstallment() {
        if (value.autoChargeCreditCard == false) {
            hideModal();
            setModalClosed(true);
            onContinue();
            return;
        }
    }

    return (
        <Modal {...modal} hide={hideModal}>
            {isSubmitting && <Spinner appearance="transparent" />}
            <Form onSubmit={submit}>
                <ScrollBox>
                    <StackLayout gap="16">
                        <Text data-e2e="pay-with-CreditCard-modal-header" style="heading 3">
                            Credit Card Info
                        </Text>
                        <Text data-e2e="pay-CreditCard-routing-number-label">
                            Please select whether you want to be auto-charged for your installments.
                            If you don't select Recurring Credit Card option, you will be invoiced
                            per each installment. If you select to auto-charge, you will be
                            redirected to setup your credit card for recurring payments.
                        </Text>
                        <Text>
                            <b>
                                Please note, there is a separate link for Remitting the Down Payment
                                in the next step
                            </b>
                            .
                        </Text>
                        <hr />
                        <Form.Field
                            type={fields.autoChargeCreditCard.type}
                            messages={fields.autoChargeCreditCard.messages}
                            inputProps={{
                                ...fields.autoChargeCreditCard.props,
                                onChange: handleBillingMethodChange,
                                checked: fields.autoChargeCreditCard.props.value || false,
                                children: (
                                    <Text style="Label 1">
                                        I agree to the Terms and Conditions of the Recurring Credit
                                        Card Authorization
                                    </Text>
                                ),
                            }}
                        />
                        <TextButton
                            as={Link}
                            href={CommercialRecurringCreditCardAuthorizationTermsAndConditions}
                            target="_blank"
                            data-e2e="pay-with-CreditCard-terms-and-conditions"
                        >
                            Terms and Conditions
                        </TextButton>
                        <hr />
                        <Text>
                            {displayError && (
                                <span style={errorsStyle}>
                                    {messages.map((message) => message)}
                                </span>
                            )}
                        </Text>
                        <ColumnLayout>
                            {value.autoChargeCreditCard && (
                                <Button
                                    data-e2e="pay-with-CreditCard-submit-btn"
                                    appearance="primary"
                                    type="submit"
                                >
                                    Setup Credit Card
                                </Button>
                            )}
                            {!value.autoChargeCreditCard && (
                                <Button
                                    data-e2e="pay-with-CreditCard-submit-btn"
                                    appearance="primary"
                                    onClick={handleOnPerInstallment}
                                >
                                    Invoice per installment
                                </Button>
                            )}
                            <BoxLayout>
                                <TextButton
                                    data-e2e="pay-with-CreditCard-cancel-btn"
                                    onClick={hideModal}
                                >
                                    Cancel
                                </TextButton>
                            </BoxLayout>
                        </ColumnLayout>
                    </StackLayout>
                </ScrollBox>
            </Form>
        </Modal>
    );
}
