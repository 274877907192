import {
    PaginationState,
    PaginationActions,
    ColumnLayout,
    Pagination,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { Link } from 'react-navi';

export interface BrokerTableHeaderProps {
    noItems: boolean;
    pagination?: PaginationState & PaginationActions;
    isDashboardPage?: boolean;
    title: string;
    viewAllUrl?: string;
}

export function BrokerTableHeader({
    noItems,
    pagination,
    title,
    viewAllUrl,
    isDashboardPage = false,
}: BrokerTableHeaderProps) {
    return (
        <ColumnLayout split="1">
            {!isDashboardPage ? (
                <React.Fragment>
                    <Text style="heading 4">{title}</Text>
                    {pagination && pagination.totalPages > 1 ? (
                        <Pagination {...pagination} />
                    ) : null}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Text style="heading 4">{title}</Text>
                    {!noItems ? (
                        <TextButton
                            size="small"
                            as={Link}
                            icon="bold-caret-right"
                            iconPosition="right"
                            href={viewAllUrl || ''}
                            disabled={viewAllUrl == undefined}
                        >
                            View all
                        </TextButton>
                    ) : null}
                </React.Fragment>
            )}
        </ColumnLayout>
    );
}
