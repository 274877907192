import type * as APIType from '@embroker/shotwell-api/app';
import { Aborted, InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { HTTPRequestError, ProgressEvent, sendRequest } from '@embroker/shotwell/core/networking';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
    mergeErrors,
} from '@embroker/shotwell/core/types/Result';
import { Document, DocumentAdded } from '../../entities/Document';
import { DocumentRepository, SaveDocument } from './index';

import { API } from '@embroker/shotwell-api/app';
import { injectable } from '@embroker/shotwell/core/di';
import { findDomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { Nullable } from '@embroker/shotwell/core/types';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { GeneratedDocument } from '../../entities/GeneratedDocument';

export interface S3Upload {
    /**
     * Url to which file should be uploaded
     */
    readonly uploadUrl: string;
    /**
     * Unique key which can be used to obtain the file stored on Amazon S3
     */
    readonly fileKey: string;
}

/**
 * Used as a type for uploadFileWithProgress function
 */
interface UploadFileParams {
    /**
     * File to be uploaded
     */
    file: File;
    /**
     * Callback which is executed when file upload progress changes
     * @param uploaded is the total amount of data uploaded for all files in bytes
     * @param totalSize is the total amount of data to be uploaded in bytes
     */
    onProgress(event: ProgressEvent): void;
    /**
     * Signal used to abort the upload operation
     */
    abortSignal?: AbortSignal;
}

export interface GetFileParams {
    /**
     * A url file should be downloaded from
     */
    url: URI;
    /**
     * Name of returned file
     */
    fileName: string;
    /**
     * Type of returned file
     */
    fileType: string;
}

export interface GetFileListParams {
    fileKeyList: string[];
    applicationId?: UUID;
}

export interface SaveFilesParams {
    files: {
        name: string;
        file_key: string;
        mime_type: string;
        size: number;
    }[];
    organizationId: Nullable<UUID>;
    applicationId?: UUID;
}

export interface DeleteFileParams {
    fileKey: string;
    applicationId?: UUID;
}

@injectable()
export class APIDocumentRepository implements DocumentRepository {
    public async save({
        file,
        onFileSaveProgress,
        abortSignal,
    }: SaveDocument): AsyncResult<Document, InvalidArgument | OperationFailed | Aborted> {
        const documentAddedEvent = findDomainEvent<DocumentAdded>(file.events, 'Document', 'Added');

        if (documentAddedEvent === undefined) {
            return Failure(InvalidArgument({ argument: 'file', value: file }));
        }

        const uploadFileResult = await APIDocumentRepository.uploadFile({
            file: documentAddedEvent.file,
            onProgress: onFileSaveProgress,
            abortSignal,
        });

        if (isErr(uploadFileResult)) {
            return uploadFileResult;
        }

        return Success(uploadFileResult.value);
    }

    public async getDocumentUrl(
        fileKey: string,
    ): AsyncResult<string, InvalidArgument | OperationFailed> {
        const apiResult = await API.request('global/download_url', {
            file_key: fileKey,
            content_type: 'application/pdf',
            dest_file_name: null,
        } as APIType.GlobalDownloadUrlRequest);

        if (isErr(apiResult)) {
            return Failure(OperationFailed({ message: 'Failed to obtain S3 download url' }));
        }

        const result = apiResult.value;

        return Success(result);
    }

    private static async getS3UploadUrl(): AsyncResult<S3Upload, OperationFailed> {
        const apiResult = await API.request('global/upload_url', {});

        if (isErr(apiResult)) {
            return Failure(
                OperationFailed({
                    message: 'Failed to obtain S3 upload url and designated file key',
                }),
            );
        }

        const result = apiResult.value as APIType.GlobalUploadUrlResponse;

        return Success({
            fileKey: result.file_key,
            uploadUrl: result.url,
        });
    }

    private static async uploadFile({
        file,
        onProgress,
        abortSignal,
    }: UploadFileParams): AsyncResult<Document, InvalidArgument | OperationFailed | Aborted> {
        const s3UploadResult = await APIDocumentRepository.getS3UploadUrl();

        if (isErr(s3UploadResult)) {
            return s3UploadResult;
        }

        const result = await sendRequest(s3UploadResult.value.uploadUrl, file)
            .withMethod('PUT')
            .withHeaders({ 'Content-Type': file.type })
            .withHeaders({ 'x-amz-server-side-encryption': 'AES256' })
            .withProgress(onProgress)
            .withAbortSignal(abortSignal);

        if (isErr(result)) {
            return Failure(
                OperationFailed({
                    message: 'File upload failed.',
                    errors: result.errors,
                }),
            );
        }

        const uploadResult = await Document.create({
            fileKey: s3UploadResult.value.fileKey,
            name: file.name,
            size: file.size,
            type: file.type,
        });

        if (isErr(uploadResult)) {
            return Failure(
                OperationFailed({
                    message: `Failed to create Document entity for file: ${file.name}.`,
                }),
            );
        }

        return Success(uploadResult.value);
    }

    public async getFileList(params: GetFileListParams): AsyncResult<Document[], OperationFailed> {
        const result: Document[] = [];

        if (params.fileKeyList.length === 0 && params.applicationId === undefined) {
            return Success(result);
        }

        const apiResult = await API.request('global/get_file_list', {
            file_key_list: params.fileKeyList,
            applicationId: params.applicationId,
        } as APIType.GlobalGetFileListRequest);

        if (isErr(apiResult)) {
            return Failure(OperationFailed({ message: 'Failed to obtain file list' }));
        }

        for (const document of apiResult.value) {
            const documentEntity = await Document.create({
                id: document.id,
                fileKey: document.file_key,
                name: document.name as string,
                size: document.size as number,
                type: document.type,
            });

            if (isErr(documentEntity)) {
                return Failure(
                    OperationFailed({
                        message: `Failed to create Document entity for document: ${document.id}.`,
                    }),
                );
            }

            result.push(documentEntity.value);
        }

        return Success(result);
    }

    public async saveFiles(params: SaveFilesParams): AsyncResult<void, OperationFailed> {
        if (params.files.length === 0) {
            // The endpoint only adds new files, i.e. it does not remove old ones,
            // so it makes no sense to send an empty list
            return Success();
        }

        const apiResult = await API.request('global/save_files', {
            document_list: params.files,
            organizationId: params.organizationId,
            applicationId: params.applicationId,
        } as APIType.GlobalSaveFilesRequest);

        if (isErr(apiResult)) {
            return Failure(OperationFailed({ message: 'Failed to save files' }));
        }

        return Success(apiResult.value);
    }

    public async deleteFile(params: DeleteFileParams): AsyncResult<void, OperationFailed> {
        if (params.fileKey.length === 0) {
            return Failure(OperationFailed({ message: 'No file key has been sent' }));
        }

        const apiResult = await API.request('global/delete_file', {
            file_key: params.fileKey,
            applicationId: params.applicationId,
        });

        if (isErr(apiResult)) {
            return Failure(OperationFailed({ message: 'Failed to delete file' }));
        }

        return Success(apiResult.value);
    }

    public async getFile(params: GetFileParams): AsyncResult<File | OperationFailed> {
        const result = await this.getBlob(params.url);
        if (isErr(result)) {
            return Failure(
                OperationFailed({
                    message: 'File download failed.',
                    errors: result.errors,
                }),
            );
        }
        return Success(
            new File([result.value as Blob], params.fileName, {
                type: params.fileType,
            }),
        );
    }

    public async getDocument(id: UUID): AsyncResult<Document, InvalidArgument | OperationFailed> {
        const apiResult = await API.request('global/get_document', {
            id,
        } as APIType.GlobalGetDocumentRequest);

        if (isErr(apiResult)) {
            return Failure(OperationFailed({ message: 'Failed to obtain document' }));
        }
        const documentEntity = await Document.create({
            id: apiResult.value.id,
            fileKey: apiResult.value.file_key,
            name: apiResult.value.name ?? '',
            size: apiResult.value.size ?? 0,
            type: apiResult.value.type,
        });
        if (isErr(documentEntity)) {
            return handleOperationFailure(documentEntity);
        }

        return documentEntity;
    }

    private async getBlob(url: URI): AsyncResult<Blob, HTTPRequestError> {
        return await sendRequest(url).withMethod('GET');
    }

    public async hasDocGenTaskInProgress(
        applicationId: UUID,
    ): AsyncResult<boolean, InvalidArgument | OperationFailed> {
        const hasDocGenTaskResponse = await API.request('task/has_doc_gen_task_in_process', {
            application_id: applicationId,
        });
        if (isErr(hasDocGenTaskResponse)) {
            return handleOperationFailure(hasDocGenTaskResponse);
        }

        return hasDocGenTaskResponse;
    }

    public async getApplicationDocumentFileKey(
        applicationId: UUID,
    ): AsyncResult<string | void, InvalidArgument | OperationFailed> {
        const applicationResponse = await API.request('shopping/application', {
            id: applicationId,
        });
        if (isErr(applicationResponse)) {
            return handleOperationFailure(applicationResponse);
        }
        if (applicationResponse.value.generated_documents.length === 0) {
            return Success();
        }

        return Success(applicationResponse.value.generated_documents[0].file_key);
    }

    public async getApplicationDocumentGeneratedDocuments(
        applicationId: UUID,
    ): AsyncResult<GeneratedDocument[], InvalidArgument | OperationFailed> {
        const applicationResponse = await API.request('shopping/application', {
            id: applicationId,
        });
        if (isErr(applicationResponse)) {
            return handleOperationFailure(applicationResponse);
        }
        if (applicationResponse.value.generated_documents.length === 0) {
            return Success([]);
        }

        const generatedDocuments: GeneratedDocument[] = [];
        if (applicationResponse.value.generated_documents !== undefined) {
            const generatedDocs = applicationResponse.value.generated_documents;

            const generatedDocumentsResults = await Promise.all(
                generatedDocs.map((document) => createDocumentEntity(document)),
            );
            for (const result of generatedDocumentsResults) {
                if (isErr(result)) {
                    return mergeErrors(generatedDocumentsResults);
                }
                generatedDocuments.push(result.value);
            }
        }

        return Success(generatedDocuments);
    }
}

export const createDocumentEntity = async (
    document: APIType.InsuranceApplicationGeneratedDocument,
) => {
    const entityInput = {
        id: document.id,
        fileName: document.file_name,
        fileKey: document.file_key,
    };

    const validateProps = GeneratedDocument.validate(entityInput);

    if (isErr(validateProps)) {
        return handleOperationFailure(validateProps);
    }

    const generatedDocument = await GeneratedDocument.create(entityInput);

    if (isErr(generatedDocument)) {
        return handleOperationFailure(generatedDocument);
    }

    return Success(generatedDocument.value);
};
