import React from 'react';
import { URI } from '@embroker/shotwell/core/types/URI';
import { Link } from '../../../view/components/Link/Link';

export interface GenerateDocumentHyperlinkProps {
    fileUrl?: URI;
    handleTrigger: () => void;
    children: React.ReactNode;
}

export function GenerateDocumentHyperlink({
    fileUrl,
    children,
    handleTrigger,
}: GenerateDocumentHyperlinkProps) {
    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        handleTrigger();
    };

    if (fileUrl) {
        return (
            <Link display="inline" href={fileUrl} target="_blank" rel="noreferrer">
                {children}
            </Link>
        );
    }
    return (
        <Link display="inline" href="" onClick={handleClick}>
            {children}
        </Link>
    );
}
