import { StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';

export function SupportedApplicationsListESP(): React.ReactNode {
    return (
        <StackLayout>
            <Text style="label">Here are the applications we’re currently accepting:</Text>
            <Text style="label">• E-Risk - BAM</Text>
            <Text style="label">• Embroker Startup Program</Text>
            <Text style="label">• Travelers</Text>
            <Text style="label">• Scale </Text>
            <Text style="label">
                Contact your Access channel managers for other applications you’d like supported.
            </Text>
        </StackLayout>
    );
}

export function SupportedApplicationsListPco(): React.ReactNode {
    return (
        <StackLayout>
            <Text style="label">Here are the applications we’re currently accepting:</Text>
            <Text style="label">• E-Risk - BAM</Text>
            <Text style="label">• Embroker PCoML</Text>
            <Text style="label">• Travelers</Text>
            <Text style="label">• Scale </Text>
            <Text style="label">
                Contact your Access channel managers for other applications you’d like supported.
            </Text>
        </StackLayout>
    );
}

export function SupportedApplicationsListLPL(): React.ReactNode {
    return (
        <StackLayout>
            <Text style="label">
                • Prior Application <strong>(Recommended)</strong>
            </Text>
            <Text style="label">• Prior Quote</Text>
            <Text style="label">• Certificate of Insurance</Text>
            <Text style="label">• Policy Document</Text>
        </StackLayout>
    );
}

export function SupportedApplicationsListCrime(): React.ReactNode {
    return (
        <StackLayout>
            <Text style="label">Here are the applications we’re currently accepting:</Text>
            <Text style="label">• Erisk - BAM</Text>
            <Text style="label">• Embroker Crime</Text>
            <Text style="label">
                Contact your Access channel managers for other applications you’d like supported.
            </Text>
        </StackLayout>
    );
}

export function SupportedApplicationsListExcess(): React.ReactNode {
    return (
        <StackLayout>
            <Text style="label">Here are the applications we’re currently accepting:</Text>
            <Text style="label">• Embroker Excess</Text>
            <Text style="label">
                Contact your Access channel managers for other applications you’d like supported.
            </Text>
        </StackLayout>
    );
}

export function SupportedApplicationsListDefault(): React.ReactNode {
    return (
        <StackLayout>
            <Text style="label">No supported applications for this product.</Text>
        </StackLayout>
    );
}
