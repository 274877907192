import { defineCodec } from '@embroker/shotwell/core/encoding';
import { valueObject } from '@embroker/shotwell/core/types/ValueObject';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

/**
 * Purchased app types
 */
export interface PurchasedAppType {
    /**
     * App types
     */
    readonly appTypes: ReadonlyArray<string>;
}

export const PurchasedAppType = valueObject({
    ...defineCodec<PurchasedAppType>(),
    ...defineValidator<PurchasedAppType>({
        appTypes: Joi.array().items(Joi.string()),
    }),
});
