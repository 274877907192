import type * as APIType from '@embroker/shotwell-api/app';
import { API } from '@embroker/shotwell-api/app';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { findDomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { InviteStatus, UserInvite } from '../../entities/UserInvite';
import { Token } from '../../types/Token';
import { UserInviteRepository } from './index';

type responseInviteStatusType = 'InvitationAccepted' | 'InvitationExpired' | 'InvitationPending';
@injectable()
export class APIUserInviteRepository implements UserInviteRepository {
    // map "invite token" to UserInvite Entity
    private userInvites: Map<UUID, UserInvite>;

    private static inviteStatusMapper: Map<responseInviteStatusType, InviteStatus> = new Map([
        ['InvitationAccepted', 'accepted'],
        ['InvitationExpired', 'rejected'],
        ['InvitationPending', 'unset'],
    ]);

    constructor() {
        this.userInvites = new Map();
    }

    public async get(): AsyncResult<UserInvite[], InvalidArgument | OperationFailed> {
        const result = await API.request('user/invitations', {
            paging_id: null,
            count: null,
        });

        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        const userInvitesResult = await this.toUserInvite(
            result.value as APIType.UserInvitationsResponse,
        );

        if (isErr(userInvitesResult)) {
            return userInvitesResult;
        }

        return Success(userInvitesResult.value as UserInvite[]);
    }

    public async getUserInviteByToken(
        token: Token,
    ): AsyncResult<UserInvite, OperationFailed | InvalidArgument | UnknownEntity> {
        const invitedDataResult = await API.request('user/get_invited_data', {
            sign_up_token: token,
        });

        if (isErr(invitedDataResult)) {
            return Failure(InvalidArgument({ argument: 'token', value: token }));
        }

        const emailResult = EmailAddress.validate(invitedDataResult.value.user.email);
        if (isErr(emailResult)) {
            return emailResult;
        }

        const userInviteResult = await UserInvite.create({
            inviteEnabled: true, // by default everyone has invitation option enabled
            status: 'unset',
            email: emailResult.value,
            organizationName: invitedDataResult.value.company.name,
            isOrganizationOwner: false,
        });

        if (isErr(userInviteResult)) {
            return handleOperationFailure(userInviteResult);
        }

        this.save(userInviteResult.value);
        return Success(userInviteResult.value);
    }

    public async save(
        userInvite: UserInvite,
    ): AsyncResult<UserInvite, InvalidArgument | OperationFailed> {
        const createdEvent = findDomainEvent(userInvite.events, 'UserInvite', 'Created');
        const acceptedEvent = findDomainEvent(userInvite.events, 'UserInvite', 'Accepted');
        const rejectedEvent = findDomainEvent(userInvite.events, 'UserInvite', 'Rejected');

        // cache invite entity
        if (createdEvent !== undefined || rejectedEvent !== undefined) {
            this.userInvites.set(userInvite.id, userInvite);
            return Success(userInvite);
        }

        // send accept. this is usually called in case that user is already embroker user
        if (acceptedEvent !== undefined) {
            const acceptResponse = await API.request('user/invitation_accept', {
                invitation_id: userInvite.id,
            });

            if (isErr(acceptResponse)) {
                return handleOperationFailure(acceptResponse);
            }

            this.userInvites.set(userInvite.id, userInvite);
            return Success(userInvite);
        }
        return Failure(OperationFailed({ message: 'Invalid Invite operation' }));
    }

    public async sendInvites(
        userInvites: UserInvite[],
    ): AsyncResult<void, InvalidArgument | OperationFailed> {
        // return false success in case of no data provided and avoid sending request to server
        if (userInvites.length === 0) {
            return Success();
        }

        const parsedInvites = this.toRequestInvites(userInvites);
        const invitationsResult = await API.request('user/invitation_create', parsedInvites);
        if (isErr(invitationsResult)) {
            return handleOperationFailure(invitationsResult);
        }

        return Success();
    }

    /**
     * Parse list of UserInvite entities to server request list for user/invitation_create endpoint
     *
     * @param data is list of UserInvite entities
     *
     * @return invites is list of user invite server request objects
     */
    private toRequestInvites(data: UserInvite[]): APIType.UserInvitationCreateRequest {
        const invites: APIType.UserInvitationCreateRequest = [];
        for (const invite of data) {
            if (invite.email !== null) {
                invites.push({
                    invitee_email: invite.email.toString(),
                });
            }
        }
        return invites;
    }

    /**
     * Parse invite date from server response to list of UserInvite entities
     *
     * @param data User invites obtained by calling user/get_invited_data
     *
     * @return list of UserInvite entities
     * @return InvalidArgument error in case of unknown server data
     * @return OperationFailed error in case of entity creation errors
     */
    private async toUserInvite(
        data: APIType.UserInvitationsResponse,
    ): AsyncResult<UserInvite[], InvalidArgument | OperationFailed> {
        const userInvites: UserInvite[] = [];

        for (const item of data) {
            const status = APIUserInviteRepository.inviteStatusMapper.get(
                item.status as responseInviteStatusType,
            );
            if (status === undefined) {
                return Failure(InvalidArgument({ argument: 'item.status', value: item.status }));
            }
            const email = EmailAddress.validate(item.invitee_email);
            if (isErr(email)) {
                return Failure(
                    InvalidArgument({ argument: 'item.invitee_email', value: item.invitee_email }),
                );
            }

            const userInviteResult = await UserInvite.create({
                // This a temporary hack until we change our API
                id: item.id !== null ? item.id : UUID.create(),
                inviteEnabled: item.accepted_invitee_org_invite_enabled,
                email: email.value,
                status: status,
                organizationName: null,
                isOrganizationOwner: item.is_org_owner,
            });
            if (isErr(userInviteResult)) {
                return handleOperationFailure(userInviteResult);
            }
            userInvites.push(userInviteResult.value);
        }
        return Success(userInvites);
    }
}
