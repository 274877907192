import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Spinner } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { CheckUserStatus } from '../../useCases/CheckUserStatus';
import { GetActiveUserProfile } from '../../useCases/GetActiveUserProfile';
import { SetupNewPassword } from './SetupNewPassword';
import { UpdateExistingPassword } from './UpdateExistingPassword';
import { UserOrgPage } from './UserOrgPage';

export function ChangePasswordEditor() {
    const { result: userProfileResult, isLoading } = useUseCase(GetActiveUserProfile);

    if (userProfileResult === undefined || isLoading) {
        return <Spinner />;
    }

    if (isErr(userProfileResult)) {
        return <ErrorPage errors={userProfileResult.errors} />;
    }

    return <ChangePasswordView email={userProfileResult.value.email} />;
}

export function ChangePasswordView({ email }: { email: EmailAddress }) {
    const { result: userStatusResult, isLoading } = useUseCase(CheckUserStatus, {
        email: email,
    });
    if (userStatusResult === undefined || isLoading) {
        return null;
    }

    if (isErr(userStatusResult)) {
        return <ErrorPage errors={userStatusResult.errors} />;
    }

    return (
        <UserOrgPage>
            {userStatusResult.value.status == 'UserStatusCodeListUserWithoutPassword' ? (
                <SetupNewPassword />
            ) : (
                <UpdateExistingPassword />
            )}
        </UserOrgPage>
    );
}
export default ChangePasswordEditor;
