import { defineEntityValidator, entity } from '@embroker/shotwell/core/entity/Entity';
import { commonQuoteProps, Quote } from '../../../../quote/entities/Quote';
import { LawCyberQuoteDetails } from '../types/LawCyberQuoteDetails';
import { LawCyberQuoteOptions } from '../types/LawCyberQuoteOptions';

export interface LawCyberQuote extends Quote {
    readonly options: LawCyberQuoteOptions;
    readonly details: LawCyberQuoteDetails;
}

export const LawCyberQuote = entity<LawCyberQuote, Quote>({
    validator: defineEntityValidator<LawCyberQuote>({
        ...commonQuoteProps,
        options: LawCyberQuoteOptions.schema.required(),
        details: LawCyberQuoteDetails.schema.required(),
    }),
    inherits: Quote,
});
