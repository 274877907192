import { URI } from '@embroker/shotwell/core/types/URI';
import {
    Button,
    CenterLayout,
    FormLayout,
    Image,
    StackLayout,
    Text,
    WizardLayout,
} from '@embroker/ui-toolkit/v2';
import React, { useContext } from 'react';
import { hasRole } from '../../../userOrg/entities/Session';
import { AppContext } from '../../../view/AppContext';
import { useNavigation } from '../../../view/hooks/useNavigation';

export function EarlyQuoteReservation() {
    const { navigate } = useNavigation();
    const { activeSession } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');

    let dashboardURL = '/summary';
    if (isBroker) {
        dashboardURL = '/broker/dashboard';
    }
    return (
        <WizardLayout
            title=""
            onDismiss={() => {
                navigate(URI`/summary`);
            }}
        >
            <FormLayout>
                <StackLayout gap="32" center>
                    <Image name="shotwell-service" />
                    <Text style="heading 2">Oops.</Text>
                    <CenterLayout centerText>
                        <StackLayout gap="16" center>
                            {!isBroker ? (
                                <React.Fragment>
                                    <Text>
                                        It looks like we've already received a submission for your
                                        company from another brokerage or client. Please contact
                                        them to finalize information.
                                    </Text>
                                    <Text>If you think this is an error, please contact us.</Text>
                                </React.Fragment>
                            ) : (
                                <Text>
                                    It looks like we've already received this submission. To proceed
                                    we'll need a signed Broker of Record letter. Please contact us
                                    if you have any questions or need more information.
                                </Text>
                            )}
                            <StackLayout gap="none">
                                <a href="#chat-now">Open live chat</a>
                                <a href="tel:844.436.2765">Call us at 844.436.2765</a>
                            </StackLayout>
                            <Button onClick={() => navigate(dashboardURL)}>
                                Back to Dashboard
                            </Button>
                        </StackLayout>
                    </CenterLayout>
                </StackLayout>
            </FormLayout>
        </WizardLayout>
    );
}
