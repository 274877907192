import { FormData, OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { Button, ColumnLayout } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { WizardForm } from '../../../view/hooks/useWizardForm';

interface ReferredQuoteOnDemandNavigationProps<T extends FormData> {
    isSubmitting: boolean;
    trigger: WizardForm<OpaqueForm<T>>['trigger'];
    status: WizardForm<OpaqueForm<T>>['status'];
}

export function ReferredQuoteOnDemandNavigation<T extends FormData>({
    isSubmitting,
    trigger,
    status,
}: ReferredQuoteOnDemandNavigationProps<T>) {
    const handleRecalculate = () => {
        trigger('update');
    };

    const dirty = status === 'dirty';

    if (dirty) {
        return (
            <ColumnLayout split="-1">
                <Button disabled={isSubmitting} onClick={handleRecalculate}>
                    Recalculate
                </Button>
            </ColumnLayout>
        );
    }

    return null;
}
