import { map, route, withData } from 'navi';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import React from 'react';
import { execute } from '@embroker/shotwell/core/UseCase';
import { GetLatestBOPHartfordQuote } from '@app/quote/hartford-bop/useCases/GetLatestQuote';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { BOPHartfordLandingPage } from '@app/quote/hartford-bop/view/components/BOPHartfordLandingPage';
import { BOPHartfordQuote } from '@app/quote/hartford-bop/entities/BOPHartfordQuote';
import { BOPHartfordCheckoutPage } from '@app/quote/hartford-bop/view/components/BOPHartfordCheckoutPage';

const hartfordWizardRouteHandler = map(async (request) => {
    const { applicationId } = request.query;
    if (!UUID.check(applicationId)) {
        return route({ view: <ErrorPage /> });
    }

    const getQuoteResult = await execute(GetLatestBOPHartfordQuote, {
        applicationId,
    });

    if (isErr(getQuoteResult)) {
        return route({ view: <ErrorPage errors={getQuoteResult.errors} /> });
    }

    return route({
        title: 'Hartford Quote',
        view: (
            <BOPHartfordLandingPage
                quote={getQuoteResult.value.quote as BOPHartfordQuote}
                applicationId={applicationId}
            />
        ),
    });
});

const paymentPageHandler = map(async (request) => {
    const { applicationId } = request.query;
    if (!UUID.check(applicationId)) {
        return route({ view: <ErrorPage /> });
    }

    const getQuoteResult = await execute(GetLatestBOPHartfordQuote, {
        applicationId,
    });

    if (isErr(getQuoteResult)) {
        return route({ view: <ErrorPage errors={getQuoteResult.errors} /> });
    }

    return route({
        title: 'Hartford Quote',
        view: (
            <BOPHartfordCheckoutPage
                quote={getQuoteResult.value.quote as BOPHartfordQuote}
                applicationId={applicationId}
            />
        ),
    });
});

export const bopHartfordRoutes = {
    '/': map((request) =>
        withData(
            { url: request.mountpath, page: undefined, fullscreen: true },
            hartfordWizardRouteHandler,
        ),
    ),
    '/:page': map((request) =>
        withData(
            { url: request.mountpath, page: request.params.page, fullscreen: true },
            hartfordWizardRouteHandler,
        ),
    ),

    '/payment': map(async (request) =>
        withData(
            { url: request.mountpath, page: request.params.page, fullscreen: true },
            paymentPageHandler,
        ),
    ),
};
