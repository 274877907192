import { defineEntityValidator, entity, Entity } from '@embroker/shotwell/core/entity/Entity';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { Joi } from '@embroker/shotwell/core/validation/schema';

export interface DocumentAdded extends DomainEvent {
    readonly origin: 'Document';
    readonly name: 'Added';
    readonly file: Immutable<File>;
}

export interface Document extends Entity {
    readonly name: string;
    readonly type: string;
    readonly size: number;
    readonly fileKey: Nullable<string>;
    setFile(file: Immutable<File>): void;
}

export interface DocumentRequested extends DomainEvent {
    origin: string;
    name: string;
    documentType: string;
}

export const Document = entity<Document>({
    validator: defineEntityValidator<Document>({
        name: Joi.string(),
        type: Joi.string(),
        size: Joi.number().allow(null),
        fileKey: Joi.string().allow(null),
    }),
    setFile(file: Immutable<File>) {
        this.createEvent<DocumentAdded>('Added', {
            file,
        });
    },
});
