import React from 'react';
import { USD } from '@embroker/shotwell/core/types/Money';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { ColumnLayout, StackLayout, Text } from '@embroker/ui-toolkit/v2';

interface CoverageBreakdownListProps {
    coverageItems: { label: string; value: number }[];
}
export function CoverageBreakdownList({ coverageItems }: CoverageBreakdownListProps) {
    return (
        <StackLayout gap="8">
            {coverageItems.map(({ value, label }) => (
                <ColumnLayout key={value} center>
                    <Text className="u-1/4" style="overline">
                        {label}
                    </Text>
                    <Text style="microcpoy" color="ui-500">
                        <MoneyDisplay fractionDigits={0} value={USD(value * 100)} />
                    </Text>
                </ColumnLayout>
            ))}
        </StackLayout>
    );
}
