import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { Stripe } from '@stripe/stripe-js';
import {
    ACHTokenRequest,
    ChargeInvoiceInput,
    InvoiceRepository,
} from '../repositories/InvoiceRepository';
import { isPaymentErrorCode, PaymentError } from '../types/errors';
import { PaymentEvent } from '../entities/Invoice';
import { Money } from '@embroker/shotwell/core/types/Money';

export interface PayByACHInput {
    readonly invoiceIds: UUID[];
    readonly accountNumber: string;
    readonly routingNumber: string;
    readonly accountHolderName: string;
    readonly stripe: Stripe;
    readonly total: Money;
}

export const PayByACHInput = {
    ...defineValidator<PayByACHInput>({
        invoiceIds: Joi.array().items(UUID.schema).min(1),
        accountNumber: Joi.string(),
        routingNumber: Joi.string(),
        accountHolderName: Joi.string(),
        stripe: Joi.object(),
        total: Money.schema.required(),
    }),
    create(
        invoiceIds: UUID[],
        accountNumber: string,
        routingNumber: string,
        accountHolderName: string,
        stripe: Stripe,
        total: Money,
    ) {
        return PayByACHInput.validate({
            invoiceIds,
            accountNumber,
            routingNumber,
            accountHolderName,
            stripe,
            total,
        });
    },
};

export interface PayByACH extends UseCase {
    execute(
        request: PayByACHInput,
    ): AsyncResult<string, InvalidArgument | OperationFailed | PaymentError>;
}

@injectable()
class PayByACHUseCase extends UseCase {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Payments/PayByACH');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(InvoiceRepository) private invoiceRepo: InvoiceRepository,
    ) {
        super(eventBus);
    }
    public async execute(
        input: PayByACHInput,
    ): AsyncResult<string, InvalidArgument | OperationFailed | PaymentError> {
        const ACHInputData: ACHTokenRequest = {
            country: 'us',
            currency: 'USD',
            accountNumber: input.accountNumber,
            routingNumber: input.routingNumber,
            accountHolderName: input.accountHolderName,
            accountHolderType: 'individual',
        };

        const ACHInput = ACHTokenRequest.create(ACHInputData);

        if (isErr(ACHInput)) {
            return handleOperationFailure(ACHInput);
        }

        const tokenResult = await this.invoiceRepo.getACHToken(ACHInput.value, input.stripe);

        if (isErr(tokenResult)) {
            return tokenResult;
        }

        if (tokenResult.value === undefined) {
            return Failure(InvalidArgument({ argument: 'token', value: undefined }));
        }

        const invoiceInputData: ChargeInvoiceInput = {
            invoiceIds: input.invoiceIds,
            token: tokenResult.value.id,
            paymentMethod: 'ach',
        };

        const chargeInvoiceInput = ChargeInvoiceInput.create(invoiceInputData);

        if (isErr(chargeInvoiceInput)) {
            return chargeInvoiceInput;
        }

        const chargeResult = await this.invoiceRepo.chargePayment(chargeInvoiceInput.value);
        if (isErr(chargeResult)) {
            const paymentFailed: PaymentEvent = {
                origin: 'Invoice',
                name: 'OnPayment',
                paymentAmount: input.total,
                paymentType: 'ach',
                id: UUID.create(),
                createdAt: new Date(Date.now()),
            };

            await this.eventBus.publish(paymentFailed);

            const paymentError = isPaymentErrorCode(chargeResult.errors);
            if (paymentError != undefined) {
                return Failure(paymentError);
            }
            return handleOperationFailure(chargeResult);
        }

        const paymentSuccess: PaymentEvent = {
            origin: 'Invoice',
            name: 'OnPayment',
            paymentAmount: input.total,
            paymentType: 'ach',
            id: UUID.create(),
            createdAt: new Date(Date.now()),
        };

        await this.eventBus.publish(paymentSuccess);
        return Success(chargeResult.value);
    }
}

export const PayByACH: UseCaseClass<PayByACH> = PayByACHUseCase;
