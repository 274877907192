import { CurrencyMarshaller } from '@embroker/shotwell-api/app';
import { Immutable } from '@embroker/shotwell/core/types';
import { Money, USD } from '@embroker/shotwell/core/types/Money';
import { ErrorLike, isOK } from '@embroker/shotwell/core/types/Result';
import { State } from '@embroker/shotwell/core/types/StateList';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { createForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    Button,
    ColumnLayout,
    Form,
    InvoiceTable,
    Modal,
    ModalActions,
    Nullable,
    Question,
    RadioGroup,
    Spinner,
    Stack,
    StackLayout,
    Table,
    Text,
    TextButton,
    useModal,
    WizardLayout,
} from '@embroker/ui-toolkit/v2';
import { format, isAfter, isBefore, isSameDay, isValid, startOfDay } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { Link } from '../../../../view/components';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { useWizardForm } from '../../../../view/hooks/useWizardForm';
import {
    getCoverageDisplayName,
    PCoMLEndorsementLiabilityCoverageType,
    PCoMLEndorsementLiabilityCoverageTypesList,
} from '../../types/PCoMLEndorsementLiabilityCoverageType';
import {
    PCoMLEndorsementLiabilityCoverage,
    PCoMLEndorsementPolicy,
} from '../../types/PCoMLEndorsementPolicy';
import { PCoMLEndorsementRate } from '../../types/PCoMLEndorsementRate';
import { PCoMLEndorsementUserData } from '../../types/PCoMLEndorsementUserData';
import {
    PurchasePCoMLEndorsement,
    PurchasePCoMLEndorsementRequest,
} from '../../useCases/PurchasePCoMLEndorsement';
import {
    RatePCoMLEndorsement,
    RatePCoMLEndorsementRequest,
} from '../../useCases/RatePCoMLEndorsement';
import {
    getAddCoverageRetentionOptions,
    getEditCoverageLimitOptions,
} from './getPCoMLLimitAndRetentionOptions';
import { PCoMLEndorsementErrorModal } from './PCoMLEndorsementErrorModal';
import { PCoMLEndorsementInvoiceModal } from './PCoMLEndorsementInvoiceModal';
import { PCoMLEndorsementSignature } from './signature/PCoMLEndorsementSignature';
import { toSelectCurrencyOption } from '../../../../quote/toSelectCurrencyOption';
import {
    LocationFieldDefinition,
    LocationFieldSet,
} from '@app/userOrg/view/components/LocationFieldSet.view';
import { MailingAddress } from '@app/userOrg/types/MailingAddress';

const COVERAGE = 'Coverages';
const NAMED_INSURED = 'Named insured';
const ADDRESS = 'Address';
const DATE_FORMAT = 'MM/dd/yyyy';

type CoverageEndorsementType = 'Edit' | undefined;
interface PCoMLEndorsementFormData {
    endorsementType: string;
    coverageEndorsementType: CoverageEndorsementType;
    coverageLiabilityTypeCode?: PCoMLEndorsementLiabilityCoverageType;
    limit: number;
    retention: number;
    headquarters: MailingAddress;
    namedInsured?: string;
    effectiveDate: Nullable<Date>;
    agreementToConductSignature: boolean;
    warrantyAndFraudSignature: boolean;
}

const formDataToRatePCoMLRequest = (
    formData: PCoMLEndorsementFormData,
    policyId: UUID,
    endorsementType: string,
) => {
    return {
        policyId: policyId,
        endorsementType: endorsementType,
        effectiveDate: formData.effectiveDate,
        limitEndorsement: {
            coverage: formData.coverageLiabilityTypeCode,
            limit: CurrencyMarshaller.unmarshal(formData.limit),
            retention: CurrencyMarshaller.unmarshal(formData.retention),
        },
    } as RatePCoMLEndorsementRequest;
};

const formDataToPurchasePCoMLRequest = (
    formData: PCoMLEndorsementFormData,
    policyId: UUID,
    endorsementType: string,
) => {
    return {
        policyId: policyId,
        endorsementType: endorsementType,
        effectiveDate: formData.effectiveDate,
        limitEndorsement: {
            coverage: formData.coverageLiabilityTypeCode,
            limit: Money.tryFromFloat(formData.limit),
            retention: Money.tryFromFloat(formData.retention),
        },
        addressEndorsement: {
            addressLine1: formData.headquarters.addressLine1,
            addressLine2: formData.headquarters.addressLine2 || '',
            city: formData.headquarters.city,
            state: formData.headquarters.state,
            zipCode: formData.headquarters.zip,
        },
        namedInsuredEndorsement: {
            namedInsured: formData.namedInsured,
        },
    } as PurchasePCoMLEndorsementRequest;
};

const createPCoMLEndorsementForm = (
    policyId: UUID,
    initialPolicy: PCoMLEndorsementPolicy,
    endorsementType: string,
    setEndorsementRate: (rate: PCoMLEndorsementRate) => void,
    setPolicy: (policy: PCoMLEndorsementPolicy) => void,
    showInvoiceModal: ModalActions['show'],
    showErrorModal: ModalActions['show'],
) => {
    return createForm<PCoMLEndorsementFormData>({
        fields: {
            endorsementType: {
                type: 'select',
                validator: Joi.string().required(),
                formatValidationError() {
                    return 'You must provide an answer.';
                },
            },
            coverageEndorsementType: {
                type: 'select',
                validator: Joi.string().optional(),
            },
            coverageLiabilityTypeCode: {
                type: 'select',
                validator: Joi.string().optional(),
            },
            limit: {
                type: 'select',
                validator: Joi.number().required(),
                formatValidationError: (error) => {
                    if (error.details.validator === 'any.required') {
                        return 'Limit is empty.';
                    } else {
                        return error.message;
                    }
                },
            },
            retention: {
                type: 'select',
                validator: Joi.number().required(),
                formatValidationError: (error) => {
                    if (error.details.validator === 'any.required') {
                        return 'Retention is empty.';
                    } else {
                        return error.message;
                    }
                },
            },
            headquarters: LocationFieldDefinition,
            namedInsured: {
                type: 'text',
                validator: Joi.string().required(),
                formatValidationError() {
                    return 'You must enter name insured value.';
                },
            },
            effectiveDate: {
                type: 'date',
                validator: Joi.date()
                    .min(initialPolicy.lastEndorsementDate ?? initialPolicy.effectivePeriodStart)
                    .max(initialPolicy.effectivePeriodEnd)
                    .required(),
                formatValidationError: (error) => {
                    if (
                        error.details.validator == 'date.min' &&
                        initialPolicy.lastEndorsementDate
                    ) {
                        return `Due to the pending endorsement, new endorsement effective date has to be on or after ${format(
                            initialPolicy.lastEndorsementDate,
                            DATE_FORMAT,
                        )}`;
                    }
                    if (
                        error.details.validator == 'date.min' ||
                        error.details.validator == 'date.max'
                    ) {
                        return 'The effective date does not coincide with the policy period.';
                    }
                    if (error.details.validator == 'any.required') {
                        return 'You must set an effective date.';
                    }
                    return error.message;
                },
            },
            agreementToConductSignature: {
                type: 'hidden',
                validator: Joi.boolean().valid(true),
                formatValidationError: (error) => {
                    if (error.details.validator == 'any.only') {
                        return 'You must agree to this condition';
                    }
                    return error.message;
                },
            },
            warrantyAndFraudSignature: {
                type: 'hidden',
                validator: Joi.boolean().valid(true),
                formatValidationError: (error) => {
                    if (error.details.validator == 'any.only') {
                        return 'You must agree to this condition';
                    }
                    return error.message;
                },
            },
        },
        actions: {
            rate: {
                action: async (formData: PCoMLEndorsementFormData) => {
                    return await execute(
                        RatePCoMLEndorsement,
                        formDataToRatePCoMLRequest(formData, policyId, endorsementType),
                    );
                },
                fields: ['effectiveDate', 'limit'],
            },
            changeNamedInsured: {
                action: async (formData: PCoMLEndorsementFormData) => {
                    const purchaseEndorsementResult = await execute(
                        PurchasePCoMLEndorsement,
                        formDataToPurchasePCoMLRequest(formData, policyId, endorsementType),
                    );

                    if (isOK(purchaseEndorsementResult)) {
                        showInvoiceModal();
                    } else {
                        showErrorModal();
                    }

                    return purchaseEndorsementResult;
                },
                fields: ['effectiveDate', 'namedInsured'],
            },
            changeAddress: {
                action: async (formData: PCoMLEndorsementFormData) => {
                    const purchaseEndorsementResult = await execute(
                        PurchasePCoMLEndorsement,
                        formDataToPurchasePCoMLRequest(formData, policyId, endorsementType),
                    );

                    if (isOK(purchaseEndorsementResult)) {
                        showInvoiceModal();
                    } else {
                        showErrorModal();
                    }

                    return purchaseEndorsementResult;
                },
                fields: ['effectiveDate', 'headquarters'],
            },
            changeLimit: {
                action: async (formData: PCoMLEndorsementFormData) => {
                    const purchaseEndorsementResult = await execute(
                        PurchasePCoMLEndorsement,
                        formDataToPurchasePCoMLRequest(formData, policyId, endorsementType),
                    );

                    if (isOK(purchaseEndorsementResult)) {
                        showInvoiceModal();
                    } else {
                        showErrorModal();
                    }

                    return purchaseEndorsementResult;
                },
                fields: [
                    'effectiveDate',
                    'limit',
                    'retention',
                    'warrantyAndFraudSignature',
                    'agreementToConductSignature',
                ],
            },
        },
        onSuccess: async (value, action) => {
            switch (action) {
                case 'rate':
                    setEndorsementRate(value);
                    break;
                case 'changeNamedInsured':
                case 'changeAddress':
                case 'changeLimits':
                    setPolicy(value);
                    break;
                default:
                    break;
            }
        },
        onFailure: (errors: Immutable<ErrorLike[]>) => {
            console.error(errors);
        },
    });
};

const fieldsPerPage: { [key: number]: (keyof PCoMLEndorsementFormData)[] } = {
    0: ['endorsementType', 'limit', 'retention', 'effectiveDate'],
    1: ['endorsementType', 'limit', 'retention', 'effectiveDate'],
    2: ['endorsementType', 'limit', 'retention', 'effectiveDate'],
    3: [],
    4: [],
};

const pages = Object.entries(fieldsPerPage).map(([name, fields]) => ({
    name,
    fields,
}));

function isEditableCoverage(coverage: PCoMLEndorsementLiabilityCoverage, isRenewal: boolean) {
    const { typeCode, limit } = coverage;

    if (!limit) {
        return false;
    }

    const limitOptions = getEditCoverageLimitOptions(typeCode, limit, isRenewal);
    return limitOptions.length > 0;
}

const defaultEditCoverageRate: PCoMLEndorsementRate = {
    annualPremium: USD(0),
    prorate: USD(0),
    taxes: USD(0),
    fees: USD(0),
    total: USD(0),
};

interface PCoMLEndorsementPageProps {
    policyId: UUID;
    initialPolicy: PCoMLEndorsementPolicy;
    userData: PCoMLEndorsementUserData;
    onDismiss: () => void;
}

export function PCoMLEndorsementPage({
    policyId,
    initialPolicy,
    userData,
    onDismiss,
}: PCoMLEndorsementPageProps) {
    const [policy, setPolicy] = useState(initialPolicy);
    const [endorsementRate, setEndorsementRate] = useState(defaultEditCoverageRate);
    const [endorsementType, setEndorsementType] = useState(COVERAGE);

    const invoiceModal = useModal();
    const errorModal = useModal();

    const initialValue: PCoMLEndorsementFormData = {
        endorsementType: COVERAGE,
        coverageEndorsementType: undefined,
        coverageLiabilityTypeCode: undefined,
        limit: 0,
        retention: 0,
        headquarters: {
            addressLine1: policy.addressData.addressLine1,
            addressLine2: policy.addressData.addressLine2 ?? undefined,
            city: policy.addressData.city,
            state: policy.addressData.state as State,
            zip: policy.addressData.zipCode,
        },
        namedInsured: policy.namedInsured,
        agreementToConductSignature: false,
        warrantyAndFraudSignature: false,
        effectiveDate: startOfDay(Date.now()),
    };

    const endorsementForm = useMemo(
        () =>
            createPCoMLEndorsementForm(
                policyId,
                initialPolicy,
                endorsementType,
                setEndorsementRate,
                setPolicy,
                invoiceModal.show,
                errorModal.show,
            ),
        [
            policyId,
            endorsementType,
            setPolicy,
            setEndorsementRate,
            initialPolicy,
            invoiceModal.show,
            errorModal.show,
        ],
    );

    const { value, setValue, activePageIndex, status, setActiveStep, fields, trigger } =
        useWizardForm(endorsementForm, {
            pages,
            initialValue: initialValue,
            keepUrl: true,
        });

    const nextHandler = () => {
        switch (value.endorsementType) {
            case COVERAGE:
                setActiveStep(1);
                setEndorsementType('change_limit');
                break;
            case NAMED_INSURED:
                setActiveStep(3);
                setEndorsementType('change_name');
                break;
            case ADDRESS:
                setActiveStep(4);
                setEndorsementType('change_address');
                break;
        }
    };

    const { navigate } = useNavigation();
    const handleGoToContactUs = () => {
        navigate('/support/contact');
    };

    const handleCoverageEndorsement = (
        type: CoverageEndorsementType,
        coverage: PCoMLEndorsementLiabilityCoverage,
    ) => {
        setValue({
            ...value,
            coverageEndorsementType: type,
            coverageLiabilityTypeCode: coverage.typeCode,
            limit: coverage.limit ? Money.toFloat(coverage.limit) : 0,
            retention: coverage.retention ? Money.toFloat(coverage.retention) : 0,
        });
        setActiveStep(2);
    };

    const goToFirstStep = () => {
        setActiveStep(0);
    };

    const { coverageLiabilityTypeCode } = value;

    const limitOptions = useMemo(() => {
        if (!policy) {
            return [];
        }
        const { availableLiabilities, isRenewal } = policy;
        const limit = availableLiabilities.find(
            ({ typeCode }) =>
                typeCode === (coverageLiabilityTypeCode as PCoMLEndorsementLiabilityCoverageType),
        )?.limit;

        if (!limit) {
            return [];
        }

        return getEditCoverageLimitOptions(
            coverageLiabilityTypeCode as PCoMLEndorsementLiabilityCoverageType,
            limit,
            isRenewal,
        );
    }, [policy, coverageLiabilityTypeCode]);

    const retentionOptions = useMemo(() => {
        return getAddCoverageRetentionOptions(
            coverageLiabilityTypeCode as PCoMLEndorsementLiabilityCoverageType,
        );
    }, [coverageLiabilityTypeCode]);

    if (!policy) {
        return null;
    }

    const isAddressPurchasable = () => {
        if (!isAddressChanged() || !isDateSelected()) {
            return false;
        }
        return true;
    };

    const isAddressChanged = () => {
        return (
            fields.headquarters.props.value?.addressLine1 != policy.addressData.addressLine1 ||
            fields.headquarters.props.value?.addressLine2 != policy.addressData.addressLine2 ||
            fields.headquarters.props.value?.city != policy.addressData.city ||
            fields.headquarters.props.value?.state != policy.addressData.state ||
            fields.headquarters.props.value?.zip != policy.addressData.zipCode
        );
    };

    const isNamedInsuredPurchasable = () => {
        if (!isNamedInsuredChanged() || !isDateSelected()) {
            return false;
        }
        return true;
    };

    const isNamedInsuredChanged = () => {
        return fields.namedInsured.props.value != policy.namedInsured;
    };

    const isLimitChanged = () => {
        const limit = policy.availableLiabilities.find(
            ({ typeCode }) =>
                typeCode === (coverageLiabilityTypeCode as PCoMLEndorsementLiabilityCoverageType),
        )?.limit;
        return Money.tryFromFloat(value.limit) != limit;
    };

    const isDateSelected = () => {
        return value.effectiveDate != null;
    };

    const handleAgreementToConductSignatureChange = () => {
        setValue({
            ...value,
            agreementToConductSignature: !value.agreementToConductSignature,
        });
    };

    const handleWarrantyAndFraudSignatureChange = () => {
        setValue({
            ...value,
            warrantyAndFraudSignature: !value.warrantyAndFraudSignature,
        });
    };

    const handleEffectiveDateChange = (event: { target: { value: string; date: Date } }) => {
        if (isValid(event.target.date)) {
            setValue({
                ...value,
                effectiveDate: event.target.date,
            });
        }
    };

    const handleCloseInvoiceModal = () => {
        invoiceModal.hide();
    };

    const handleCloseErrorModal = () => {
        errorModal.hide();
    };

    const handleSubmit = () => {
        switch (endorsementType) {
            case 'change_name':
                trigger('changeNamedInsured');
                break;
            case 'change_limit':
                trigger('changeLimit');
                break;
            case 'change_address':
                trigger('changeAddress');
                break;
            default:
                break;
        }
    };

    const showCalculateButton = () => {
        const isSelected = isDateSelected() && isLimitChanged();
        const isDirty = fields.limit.status == 'dirty' || fields.effectiveDate.status == 'dirty';
        return isDirty && isSelected;
    };

    const isPurchaseDisabled = () => {
        return !isDateSelected() || !isLimitChanged() || !isPurchaseAvailable;
    };

    const handleCalculate = () => {
        trigger('rate');
    };

    function isEffectiveDateValid(effectiveDate: Date) {
        return (
            isAfterMinValidDate(effectiveDate, initialPolicy.effectivePeriodStart) &&
            isBeforeMaxValidDate(effectiveDate, initialPolicy.effectivePeriodEnd)
        );
    }

    function isAfterMinValidDate(effectiveDate: Date, effectivePeriodStart: Date) {
        return (
            isSameDay(effectiveDate, effectivePeriodStart) ||
            isAfter(effectiveDate, effectivePeriodStart)
        );
    }

    function isBeforeMaxValidDate(effectiveDate: Date, effectivePeriodEnd: Date) {
        return (
            isSameDay(effectiveDate, effectivePeriodEnd) ||
            isBefore(effectiveDate, effectivePeriodEnd)
        );
    }

    function onLocationChange(headquarters: Partial<MailingAddress>) {
        setValue({
            ...value,
            headquarters: headquarters as MailingAddress,
        });
    }

    const isLoading = status === 'submitting';
    const isFormInvalid = status === 'invalid';
    const isPurchaseAvailable =
        value.agreementToConductSignature && value.warrantyAndFraudSignature;

    return (
        <WizardLayout title="Modify Policy" onDismiss={onDismiss}>
            {isLoading && <Spinner />}
            <StackLayout gap="24">
                <Stack activeIndex={activePageIndex}>
                    {/* General endorsement page */}
                    <StackLayout>
                        <Text style="heading 3">Make changes to your Management Liability</Text>
                        <Text style="body 1">
                            So that we can process the request, please answer a few questions for
                            us.
                        </Text>
                        <Question title="What part of your policy do you need to change?">
                            <RadioGroup
                                id="endorsement-type"
                                items={[
                                    {
                                        title: 'Coverages',
                                        value: COVERAGE,
                                    },
                                    {
                                        title: 'Named insured',
                                        value: NAMED_INSURED,
                                    },
                                    {
                                        title: 'Address',
                                        value: ADDRESS,
                                    },
                                ]}
                                {...fields.endorsementType.props}
                            />
                        </Question>
                    </StackLayout>
                    {/* Coverage endorsement */}
                    <StackLayout>
                        <Table>
                            <Table.Header>
                                <Table.Column>Coverages</Table.Column>
                                <Table.Column>LIMIT</Table.Column>
                                <Table.Column>RETENTION</Table.Column>
                                <Table.Column colSpan={2}>PREMIUM</Table.Column>
                            </Table.Header>
                            <Table.Body>
                                {PCoMLEndorsementLiabilityCoverageTypesList.map(
                                    (coverageTypeCode) => {
                                        const coverage:
                                            | PCoMLEndorsementLiabilityCoverage
                                            | undefined = policy.availableLiabilities.find(
                                            (al) => al.typeCode === coverageTypeCode,
                                        );
                                        if (coverage) {
                                            return (
                                                <Table.Row>
                                                    <Table.Cell>
                                                        <Text>
                                                            {getCoverageDisplayName(
                                                                coverageTypeCode,
                                                            )}
                                                        </Text>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <MoneyDisplay
                                                            value={coverage.limit ?? USD(0)}
                                                        />
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <MoneyDisplay
                                                            value={coverage.retention ?? USD(0)}
                                                        />
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <MoneyDisplay
                                                            value={coverage.premium ?? USD(0)}
                                                        />
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {isEditableCoverage(
                                                            coverage,
                                                            policy.isRenewal,
                                                        ) && (
                                                            <TextButton
                                                                onClick={() =>
                                                                    handleCoverageEndorsement(
                                                                        'Edit',
                                                                        coverage,
                                                                    )
                                                                }
                                                            >
                                                                Edit
                                                            </TextButton>
                                                        )}
                                                    </Table.Cell>
                                                </Table.Row>
                                            );
                                        }
                                    },
                                )}
                            </Table.Body>
                        </Table>
                    </StackLayout>
                    {/* Edit/Add coverage endorsement */}
                    <StackLayout>
                        <Text style="heading 3">
                            {value.coverageEndorsementType}{' '}
                            {getCoverageDisplayName(
                                value.coverageLiabilityTypeCode as PCoMLEndorsementLiabilityCoverageType,
                            )}{' '}
                            Coverage
                        </Text>
                        <ColumnLayout>
                            <Form.Field
                                type={fields.limit.type}
                                className="u-1/2"
                                inputProps={{
                                    ...fields.limit.props,
                                    filterable: false,
                                    disabled: !isDateSelected(),
                                    items: limitOptions,
                                    label: 'Limit',
                                    createNewItem: toSelectCurrencyOption,
                                }}
                                messages={fields.limit.messages}
                            />
                            <Form.Field
                                type={fields.retention.type}
                                className="u-1/2"
                                inputProps={{
                                    ...fields.retention.props,
                                    filterable: false,
                                    disabled: true,
                                    items: retentionOptions,
                                    label: 'Retention',
                                    createNewItem: toSelectCurrencyOption,
                                }}
                                messages={fields.limit.messages}
                            />
                        </ColumnLayout>
                        <InvoiceTable>
                            <InvoiceTable.Section>
                                <InvoiceTable.Item title="Additional premium">
                                    <MoneyDisplay value={endorsementRate.annualPremium} />
                                </InvoiceTable.Item>
                                <InvoiceTable.Item title="Prorated (mid year)">
                                    <MoneyDisplay value={endorsementRate.prorate} />
                                </InvoiceTable.Item>
                                <InvoiceTable.Item title="Taxes">
                                    <MoneyDisplay value={endorsementRate.taxes} />
                                </InvoiceTable.Item>
                                <InvoiceTable.Item title="Fees">
                                    <MoneyDisplay value={endorsementRate.fees} />
                                </InvoiceTable.Item>
                                <InvoiceTable.Item title="Total">
                                    <MoneyDisplay value={endorsementRate.total} />
                                </InvoiceTable.Item>
                            </InvoiceTable.Section>
                        </InvoiceTable>
                        <Question title="Effective date of change">
                            <Form.Field
                                type={fields.effectiveDate.type}
                                messages={fields.effectiveDate.messages}
                                inputProps={{
                                    ...fields.effectiveDate.props,
                                    onChange: handleEffectiveDateChange,
                                    disabled: isLoading,
                                    isSelectable: isEffectiveDateValid,
                                    label: 'Select your date',
                                }}
                            />
                        </Question>
                        <Text>
                            Current policy period:{' '}
                            <DateDisplay value={policy.effectivePeriodStart} />
                            {' - '}
                            <DateDisplay value={policy.effectivePeriodEnd} />
                        </Text>
                        <StackLayout>
                            <PCoMLEndorsementSignature
                                agreementToConductSignature={value.agreementToConductSignature}
                                onAgreementToConductSignatureChange={
                                    handleAgreementToConductSignatureChange
                                }
                                agreementToConductSignatureMessages={
                                    fields.agreementToConductSignature.messages
                                }
                                userData={userData}
                                isFormInvalid={isFormInvalid}
                                warrantyAndFraudSignature={value.warrantyAndFraudSignature}
                                onWarrantyAndFraudSignatureChange={
                                    handleWarrantyAndFraudSignatureChange
                                }
                                warrantyAndFraudSignatureMessages={
                                    fields.warrantyAndFraudSignature.messages
                                }
                            />
                            <WizardLayout.Actions>
                                {showCalculateButton() ? (
                                    <Button onClick={handleCalculate}>Calculate</Button>
                                ) : (
                                    <Button onClick={handleSubmit} disabled={isPurchaseDisabled()}>
                                        Purchase
                                    </Button>
                                )}
                                <TextButton onClick={goToFirstStep}>Cancel</TextButton>
                            </WizardLayout.Actions>
                        </StackLayout>
                    </StackLayout>
                    {/* Named insured endorsement */}
                    <StackLayout>
                        <Text style="heading 3">Update Name</Text>
                        <StackLayout>
                            <Form.Field
                                type={fields.namedInsured.type}
                                messages={fields.namedInsured.messages}
                                className="u-1/1"
                                inputProps={{
                                    ...fields.namedInsured.props,
                                    filterable: false,
                                    disabled: false,
                                    label: 'Named Insured',
                                }}
                            />
                            <Question title="Effective date of change">
                                <Form.Field
                                    type={fields.effectiveDate.type}
                                    messages={fields.effectiveDate.messages}
                                    inputProps={{
                                        ...fields.effectiveDate.props,
                                        onChange: handleEffectiveDateChange,
                                        disabled: isLoading,
                                        isSelectable: isEffectiveDateValid,
                                        label: 'Select your date',
                                    }}
                                />
                            </Question>
                            <Text>
                                Current policy period:{' '}
                                <DateDisplay value={policy.effectivePeriodStart} />
                                {' - '}
                                <DateDisplay value={policy.effectivePeriodEnd} />
                            </Text>
                            <WizardLayout.Actions>
                                <Button
                                    onClick={handleSubmit}
                                    disabled={!isNamedInsuredPurchasable()}
                                >
                                    Purchase
                                </Button>
                                <TextButton onClick={goToFirstStep}>Cancel</TextButton>
                            </WizardLayout.Actions>
                        </StackLayout>
                    </StackLayout>
                    {/* Address endorsement */}
                    <StackLayout>
                        <Text style="heading 3">Update Address</Text>
                        <Text style="body 1">
                            So that we can process the request, please answer a few questions for
                            us.
                        </Text>
                        <Question title="Address">
                            <LocationFieldSet
                                fieldValue={fields.headquarters.props.value}
                                onChange={onLocationChange}
                                messages={fields.headquarters.messages}
                                disableState
                                autoSuggest={false}
                            />
                        </Question>
                        <Text>
                            <TextButton as={Link} href="/support">
                                Contact Embroker{' '}
                            </TextButton>{' '}
                            to make a change to your state.
                        </Text>
                        <Question title="Effective date of change">
                            <Form.Field
                                type={fields.effectiveDate.type}
                                messages={fields.effectiveDate.messages}
                                inputProps={{
                                    ...fields.effectiveDate.props,
                                    onChange: handleEffectiveDateChange,
                                    disabled: isLoading,
                                    isSelectable: isEffectiveDateValid,
                                    label: 'Select your date',
                                }}
                            />
                        </Question>
                        <Text>
                            Current policy period:{' '}
                            <DateDisplay value={policy.effectivePeriodStart} />
                            {' - '}
                            <DateDisplay value={policy.effectivePeriodEnd} />
                        </Text>
                        <WizardLayout.Actions>
                            <Button onClick={handleSubmit} disabled={!isAddressPurchasable()}>
                                Purchase
                            </Button>
                            <TextButton onClick={goToFirstStep}>Cancel</TextButton>
                        </WizardLayout.Actions>
                    </StackLayout>
                </Stack>
            </StackLayout>
            {activePageIndex == 0 && (
                <WizardLayout.Actions>
                    <Button onClick={nextHandler}>Next</Button>
                </WizardLayout.Actions>
            )}
            <Modal {...invoiceModal} appearance="default" dismissable={false}>
                <PCoMLEndorsementInvoiceModal
                    onConfirm={() => {
                        onDismiss();
                        handleCloseInvoiceModal();
                    }}
                />
            </Modal>
            <Modal {...errorModal} appearance="default">
                <PCoMLEndorsementErrorModal
                    onClose={handleCloseErrorModal}
                    onGoToContactUs={handleGoToContactUs}
                />
            </Modal>
        </WizardLayout>
    );
}
