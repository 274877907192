import { OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { SupplementalAppInfo } from '../../types/SupplementalAppInfo';

export interface SaveSupplementalQuestionnaireRequest {
    applicationId: UUID;
    questionnaireData: string;
}

export interface SubmitSupplementalQuestionnaireRequest {
    applicationId: UUID;
    questionnaireData: string;
}

export interface SupplementalQuestionnaireRepository {
    getSupplementalAppInfo(applicationId: UUID): AsyncResult<SupplementalAppInfo, OperationFailed>;
    saveSupplementalQuestionnaire(
        request: SaveSupplementalQuestionnaireRequest,
    ): AsyncResult<void, OperationFailed>;
    submitSupplementalQuestionnaire(
        request: SubmitSupplementalQuestionnaireRequest,
    ): AsyncResult<void, OperationFailed>;
    requestHigherLimits(applicationId: UUID): AsyncResult<void, OperationFailed>;
}

export const SupplementalQuestionnaireRepository = Symbol('SupplementalQuestionnaireRepository');
