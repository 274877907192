import { Money } from '@embroker/shotwell/core/types/Money';
import { State } from '@embroker/shotwell/core/types/StateList';
import { Nullable, SelectOptionItem } from '@embroker/ui-toolkit/v2';
import {
    CoverageRestriction,
    ShoppingCoverageCodeListCyberSplit,
    ShoppingCoverageCodeListTechSplit,
} from '../../../types/CoverageRestriction';
import { QuestionnaireData } from '../ESPCoveragesPage/ESPCoveragesPage';

export type ESPCoverageType =
    | 'do'
    | 'epli'
    | 'eoCyber'
    | 'fiduciary'
    | 'techCyber'
    | 'techSplit'
    | 'cyberSplit';

interface ESPCoverageLimitOptionRecord {
    retentions: Array<SelectOptionItem<number>>;
    limits: Array<SelectOptionItem<number>>;
}

export interface Option {
    readonly title: string;
    readonly value: number;
}

const defaultDoEplRetentionOptions: Array<SelectOptionItem<number>> = [
    { title: '$10,000', value: 10000 },
    { title: '$25,000', value: 25000 },
    { title: '$50,000', value: 50000 },
    { title: '$75,000', value: 75000 },
    { title: '$100,000', value: 100000 },
    { title: '$150,000', value: 150000 },
];

const defaultLimitOptions: Array<SelectOptionItem<number>> = [
    { title: '$1,000,000', value: 1000000 },
    { title: '$2,000,000', value: 2000000 },
    { title: '$3,000,000', value: 3000000 },
];

const defaultRestrictionLimitOptions: Array<SelectOptionItem<number>> = [
    { title: '$1,000,000', value: 1000000 },
    { title: '$2,000,000', value: 2000000 },
    { title: '$3,000,000', value: 3000000 },
    { title: '$4,000,000', value: 4000000 },
    { title: '$5,000,000', value: 5000000 },
];

const defaultEpliLimitOptions: Array<SelectOptionItem<number>> = [
    { title: '$1,000,000', value: 1000000 },
    { title: '$2,000,000', value: 2000000 },
    { title: '$3,000,000', value: 3000000 },
];

const maxDefaultEpliLimitCA = 2 * 1000 * 1000; // $2M in California

const defaultDoLimitRetentionOptions: ESPCoverageLimitOptionRecord = {
    retentions: defaultDoEplRetentionOptions,
    limits: defaultLimitOptions,
};

const defaultDoRestrictionLimitRetentionOptions: ESPCoverageLimitOptionRecord = {
    retentions: defaultDoEplRetentionOptions,
    limits: defaultRestrictionLimitOptions,
};

const defaultEpliLimitRetentionOptions: ESPCoverageLimitOptionRecord = {
    retentions: defaultDoEplRetentionOptions,
    limits: defaultEpliLimitOptions,
};

const defaultEpliRestrictionLimitRetentionOptions: ESPCoverageLimitOptionRecord = {
    retentions: defaultDoEplRetentionOptions,
    limits: defaultRestrictionLimitOptions,
};

const fiduciaryLimitRetentionOptions: ESPCoverageLimitOptionRecord = {
    retentions: [{ title: '$0', value: 0 }],
    limits: [{ title: '$1,000,000', value: 1000000 }],
};

const defaultEoCyberRetentionOptions: Array<SelectOptionItem<number>> = [
    { title: '$2,500', value: 2500 },
    { title: '$5,000', value: 5000 },
    { title: '$10,000', value: 10000 },
    { title: '$25,000', value: 25000 },
    { title: '$50,000', value: 50000 },
    { title: '$75,000', value: 75000 },
    { title: '$100,000', value: 100000 },
];

const defaultEoCyberLimitRetentionOptions: ESPCoverageLimitOptionRecord = {
    retentions: defaultEoCyberRetentionOptions,
    limits: defaultLimitOptions,
};

const defaultEoCyberRestrictionLimitRetentionOptions: ESPCoverageLimitOptionRecord = {
    retentions: defaultEoCyberRetentionOptions,
    limits: defaultRestrictionLimitOptions,
};

const defaultTechSplitRetentionOptions: Array<SelectOptionItem<number>> = [
    { title: '$2,500', value: 2500 },
    { title: '$5,000', value: 5000 },
    { title: '$10,000', value: 10000 },
    { title: '$25,000', value: 25000 },
    { title: '$50,000', value: 50000 },
    { title: '$75,000', value: 75000 },
    { title: '$100,000', value: 100000 },
];

const defaultTechSplitLimitRetentionOptions: ESPCoverageLimitOptionRecord = {
    retentions: defaultTechSplitRetentionOptions,
    limits: defaultLimitOptions,
};

const defaultTechSplitRestrictionLimitRetentionOptions: ESPCoverageLimitOptionRecord = {
    retentions: defaultTechSplitRetentionOptions,
    limits: defaultRestrictionLimitOptions,
};

const defaultCyberSplitRetentionOptions: Array<SelectOptionItem<number>> =
    defaultTechSplitRetentionOptions;

const defaultCyberSplitLimitRetentionOptions: ESPCoverageLimitOptionRecord = {
    retentions: defaultCyberSplitRetentionOptions,
    limits: [{ title: '$0', value: 0 }, ...defaultLimitOptions],
};

const defaultCyberSplitRestrictionLimitRetentionOptions: ESPCoverageLimitOptionRecord = {
    retentions: defaultCyberSplitRetentionOptions,
    limits: [{ title: '$0', value: 0 }, ...defaultRestrictionLimitOptions],
};

export function getLimitRetentionOptions(
    coverageType: ESPCoverageType,
    answers?: QuestionnaireData,
    hasRestriction?: boolean,
    coverageRestriction?: CoverageRestriction,
): ESPCoverageLimitOptionRecord {
    // DO and EPLI have the same defaults options (if no coverageType specified)
    switch (coverageType) {
        case 'eoCyber':
            return getEoCyberLimitRetentionOptions(hasRestriction);

        case 'techCyber':
            return getTechCyberLimitRetentionOptions(hasRestriction, coverageRestriction);

        case 'fiduciary':
            return fiduciaryLimitRetentionOptions;

        case 'epli':
            return getEpliLimitRetentionOptions(
                getMinimumEpliRetention(answers, coverageRestriction),
                coverageRestriction,
                answers?.state,
            );

        default:
            return getDnOLimitRetentionOptions(hasRestriction);
    }
}

export function getRestrictedLimitRetentionOptions(
    coverageType: ESPCoverageType,
    coverageRestriction?: CoverageRestriction,
    answers?: QuestionnaireData,
): ESPCoverageLimitOptionRecord {
    if (!coverageRestriction) {
        return getLimitRetentionOptions(coverageType, answers, false);
    }

    const limits = getLimitRetentionOptions(
        coverageType,
        answers,
        true,
        coverageRestriction,
    ).limits.filter((limit) =>
        Money.isLessThanOrEqual(Money.tryFromFloat(limit.value), coverageRestriction.maxLimit),
    );
    const retentions = getLimitRetentionOptions(
        coverageType,
        answers,
        false,
        coverageRestriction,
    ).retentions.filter((retention) =>
        Money.isGreaterThanOrEqual(
            Money.tryFromFloat(retention.value),
            coverageRestriction.minRetention,
        ),
    );

    return {
        limits,
        retentions,
    };
}

export function isRetentionOptionValid(value: number, coverageType: ESPCoverageType) {
    return getLimitRetentionOptions(coverageType).retentions.some(
        (option) => option.value === value,
    );
}

export function isLimitOptionValid(value: number, coverageType: ESPCoverageType) {
    return getLimitRetentionOptions(coverageType).limits.some((option) => option.value === value);
}

export function getMinimumEpliRetention(
    answers?: QuestionnaireData,
    coverageRestriction?: CoverageRestriction,
): number {
    if (!answers) {
        return 0;
    }
    if (coverageRestriction) {
        return Money.toFloat(coverageRestriction.minRetention);
    }
    const state = answers.state;
    const zip = answers.zip;
    const numberOfEmployees = answers.numberOfEmployees;

    let minimumEPLIRetention = 10000;

    const californiaRiskyZipCodeList = ['900', '901', '917', '940', '941'];

    if (state == 'CA') {
        if (zipStartsWith(zip, californiaRiskyZipCodeList)) {
            minimumEPLIRetention =
                calculateMinimumRetentionForCaliforniaRiskyZips(numberOfEmployees);
        } else {
            minimumEPLIRetention =
                calculateMinimumRetentionForCaliforniaNonRiskyZips(numberOfEmployees);
        }
    } else {
        minimumEPLIRetention = calculateMinimumRetentionForNonCalifornia(numberOfEmployees);
    }

    return minimumEPLIRetention;
}

function zipStartsWith(zipCode: string, prefixes: string[]): boolean {
    for (const prefix of prefixes) {
        if (zipCode.startsWith(prefix)) {
            return true;
        }
    }
    return false;
}

function calculateMinimumRetentionForCaliforniaRiskyZips(numberOfEmployees: number): number {
    let minimumEPLIRetention: number;
    if (numberOfEmployees <= 50) {
        minimumEPLIRetention = 75000;
    } else if (numberOfEmployees <= 100) {
        minimumEPLIRetention = 100000;
    } else if (numberOfEmployees <= 250) {
        minimumEPLIRetention = 150000;
    } else if (numberOfEmployees <= 500) {
        minimumEPLIRetention = 150000;
    } else {
        minimumEPLIRetention = 150000;
    }
    return minimumEPLIRetention;
}

function calculateMinimumRetentionForCaliforniaNonRiskyZips(numberOfEmployees: number): number {
    let minimumEPLIRetention: number;
    if (numberOfEmployees <= 50) {
        minimumEPLIRetention = 50000;
    } else if (numberOfEmployees <= 100) {
        minimumEPLIRetention = 50000;
    } else if (numberOfEmployees <= 250) {
        minimumEPLIRetention = 75000;
    } else if (numberOfEmployees <= 500) {
        minimumEPLIRetention = 150000;
    } else {
        minimumEPLIRetention = 150000;
    }
    return minimumEPLIRetention;
}

function calculateMinimumRetentionForNonCalifornia(numberOfEmployees: number): number {
    let minimumEPLIRetention: number;
    if (numberOfEmployees <= 50) {
        minimumEPLIRetention = 10000;
    } else if (numberOfEmployees <= 100) {
        minimumEPLIRetention = 10000;
    } else if (numberOfEmployees <= 250) {
        minimumEPLIRetention = 25000;
    } else if (numberOfEmployees <= 500) {
        minimumEPLIRetention = 50000;
    } else {
        minimumEPLIRetention = 50000;
    }
    return minimumEPLIRetention;
}

function getEpliLimitRetentionOptions(
    minimumEpliRetention: number,
    coverageRestriction?: CoverageRestriction,
    state?: Nullable<State>,
): ESPCoverageLimitOptionRecord {
    const retentions = defaultEpliLimitRetentionOptions.retentions.filter(
        (option) => option.value >= minimumEpliRetention,
    );
    let limits = defaultEpliLimitRetentionOptions.limits;

    if (coverageRestriction !== undefined && coverageRestriction) {
        limits = defaultEpliRestrictionLimitRetentionOptions.limits.filter((limit) =>
            Money.isLessThanOrEqual(Money.tryFromFloat(limit.value), coverageRestriction.maxLimit),
        );
    } else if (state && state === 'CA') {
        limits = defaultEpliLimitRetentionOptions.limits.filter(
            (limit) => limit.value <= maxDefaultEpliLimitCA,
        );
    }

    return { limits, retentions };
}

function getEoCyberLimitRetentionOptions(hasRestriction?: boolean): ESPCoverageLimitOptionRecord {
    if (hasRestriction) {
        return defaultEoCyberRestrictionLimitRetentionOptions;
    }
    return defaultEoCyberLimitRetentionOptions;
}

function getTechSplitLimitRetentionOptions(hasRestriction?: boolean): ESPCoverageLimitOptionRecord {
    if (hasRestriction) {
        return defaultTechSplitRestrictionLimitRetentionOptions;
    }
    return defaultTechSplitLimitRetentionOptions;
}

export function getCyberSplitLimitRetentionOptions(
    hasRestriction?: boolean,
): ESPCoverageLimitOptionRecord {
    if (hasRestriction) {
        return defaultCyberSplitRestrictionLimitRetentionOptions;
    }
    return defaultCyberSplitLimitRetentionOptions;
}

function getDnOLimitRetentionOptions(hasRestriction?: boolean): ESPCoverageLimitOptionRecord {
    if (hasRestriction) {
        return defaultDoRestrictionLimitRetentionOptions;
    }
    return defaultDoLimitRetentionOptions;
}

function getTechCyberLimitRetentionOptions(
    hasRestriction?: boolean,
    coverageRestriction?: CoverageRestriction,
): ESPCoverageLimitOptionRecord {
    let options = getEoCyberLimitRetentionOptions(hasRestriction);

    if (!coverageRestriction) {
        return options;
    }
    // TODO: Implement higher limits request for split limits for tech and cyber parts
    switch (coverageRestriction?.coverageType) {
        case ShoppingCoverageCodeListTechSplit:
            options = getTechSplitLimitRetentionOptions(hasRestriction);
            break;

        case ShoppingCoverageCodeListCyberSplit:
            options = getCyberSplitLimitRetentionOptions(hasRestriction);
            if (!coverageRestriction.allowCoverage) {
                options = {
                    ...options,
                    limits: [{ title: '$0', value: 0 }],
                };
            }
            break;
    }

    return {
        limits: options.limits.filter((limit) =>
            Money.isGreaterThanOrEqual(
                coverageRestriction.maxLimit,
                Money.tryFromFloat(limit.value),
            ),
        ),
        retentions: options.retentions.filter((retention) =>
            Money.isLessThanOrEqual(
                coverageRestriction.minRetention,
                Money.tryFromFloat(retention.value),
            ),
        ),
    };
}
