import React from 'react';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Text } from '@embroker/ui-toolkit/v2';
import { GetActiveOrganizationProfile } from '../../../userOrg/useCases/GetActiveOrganizationProfile';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { execute } from '@embroker/shotwell/core/UseCase';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { MPLVertical, NAICS_CODE_TO_VERTICAL } from '../../../userOrg/types/enums';
import { LandingPageLayout } from '../../../view/components/LandingPageLayout.view';
import { PublishShoppingUserClickEvent } from '../../useCases/PublishShoppingUserClickEvent';

export const MPLValidNaicsCodeVerticalLabel: Record<MPLVertical, string> = {
    Accounting: 'Accountants',
    HomeInspectorAndBuildingInspectionServices: 'Home Inspectors',
    NonTechnologyConsultants: 'Non-Technology Consultants',
    RealEstateAgent: 'Real Estate Agents',
    TaxPreparation: 'Tax Preparers',
};

export function GuidanceFlowFullyIneligible() {
    const { navigate } = useNavigation();

    const { result: getActiveOrganizationProfile, isLoading } = useUseCase(
        GetActiveOrganizationProfile,
    );

    const naics =
        getActiveOrganizationProfile && isOK(getActiveOrganizationProfile)
            ? getActiveOrganizationProfile.value.organization.naics || ''
            : '';

    let vertical: MPLVertical | undefined = undefined;
    const naicsMap = NAICS_CODE_TO_VERTICAL[naics];
    if (naicsMap !== 'LawFirm' && naicsMap !== 'TechCompanies') {
        vertical = naicsMap;
    }
    const verticalText = vertical ? MPLValidNaicsCodeVerticalLabel[vertical] : '';

    const coverages = [
        `${verticalText} Professional Liability`.trim(),
        'Cyber Insurance',
        'Business Owners Policy',
        'Workers Compensation',
    ];

    const handleOnClick = () => {
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'I need guidance CTA clicked',
        });
        navigate('/shopping');
    };

    return (
        <LandingPageLayout
            isLoading={isLoading}
            title="We're sorry!"
            primaryAction={{
                label: 'Shop All Coverages',
                onClick: handleOnClick,
            }}
            imageProps={{
                width: '100%',
                name: 'full-ineligibility',
            }}
        >
            <Text style="body 1">
                Unfortunately, based on some of your responses, your company isn't eligible for the
                following coverages in our recommended package:
            </Text>
            <Text>
                {coverages.map((coverage) => (
                    <React.Fragment key={coverage}>
                        <Text as="span" style="body 1">
                            {coverage}
                        </Text>
                        <br />
                    </React.Fragment>
                ))}
            </Text>
            <Text style="body 1">We'll be in touch if our guidelines change.</Text>
            <Text style="body 1">
                In the meantime, feel free to shop our full suite of policies for other coverages
                you may need.
            </Text>
        </LandingPageLayout>
    );
}
