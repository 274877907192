import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Filter } from '@embroker/ui-toolkit/v2';
import React, { useEffect, useMemo, useState } from 'react';
import { PolicyFilter as PolicyFilterType } from '../../types/PolicyFilter';
import {
    PolicySortByAlphabet,
    PolicySortByExpirationDate,
    PolicySortByPremiumAmount,
} from '../../types/PolicySortBy';
import { UpdatePolicyFilter } from '../../useCases/UpdatePolicyFilter';

const ActivePolicyFilter = 'Active';
const InactivePolicyFilter = 'Inactive';

export interface PolicyFilterProps {
    filter: PolicyFilterType;
    isLoading: boolean;
    onChange(filter: PolicyFilterType): void;
}

export function PolicyFilter({ filter, onChange, isLoading }: PolicyFilterProps) {
    const [activeFilters, setActiveFilters] = useState(filter);

    const filterValue = useMemo(() => {
        const result: string[] = [];
        if (activeFilters.showActive) {
            result.push(ActivePolicyFilter);
        }
        if (activeFilters.showInactive) {
            result.push(InactivePolicyFilter);
        }
        return result;
    }, [activeFilters]);

    useEffect(() => {
        let isMounted = true;
        execute(UpdatePolicyFilter, {
            filter: activeFilters,
        }).then((result) => {
            if (isMounted) {
                if (isOK(result)) {
                    onChange(result.value.filter);
                } else {
                    container.get<Logger>(Log).error(result.errors);
                }
            }
        });
        return () => {
            isMounted = false;
        };
    }, [activeFilters, onChange]);

    function setFilters(filterValues: string[]) {
        if (!isLoading) {
            const showActive = filterValues.includes(ActivePolicyFilter);
            const showInactive = filterValues.includes(InactivePolicyFilter);
            setActiveFilters({ ...activeFilters, showActive, showInactive });
        }
    }

    function setSortbyParameter(newSortByParameter: string) {
        if (!isLoading) {
            setActiveFilters({ ...activeFilters, sortBy: newSortByParameter });
        }
    }

    return (
        <React.Fragment>
            <Filter
                data-e2e="filter-policies"
                title="View"
                multiple
                value={filterValue}
                onChange={(event) => {
                    setFilters(event.target.value);
                }}
                options={[
                    { title: 'Active', value: ActivePolicyFilter },
                    { title: 'Inactive', value: InactivePolicyFilter },
                ]}
            />
            <Filter
                data-e2e="sort-by-filter"
                value={activeFilters.sortBy}
                onChange={(event) => {
                    setSortbyParameter(event.target.value);
                }}
                title="Sort by"
                options={[
                    {
                        title: 'A to Z',
                        value: PolicySortByAlphabet,
                    },
                    {
                        title: 'Expiration date',
                        value: PolicySortByExpirationDate,
                    },
                    {
                        title: 'Premium amount',
                        value: PolicySortByPremiumAmount,
                    },
                ]}
            />
        </React.Fragment>
    );
}
