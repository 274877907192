import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { URI } from '@embroker/shotwell/core/types/URI';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import { Form, TextButton } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { ErrorCode } from '../../../errors';
import { Login } from '../../../useCases/Login';
import { PublicForm } from '../PublicForm';
import { Joi } from '@embroker/shotwell/core/validation/schema';

interface UsernamePasswordFormData {
    username: EmailAddress;
    password: string;
}
const UsernamePasswordForm = createForm<UsernamePasswordFormData>({
    useCase: Login,
    formatSubmitErrors(errors) {
        if (errors.length === 0) {
            return [];
        }
        for (const error of errors) {
            switch (error.code) {
                case ErrorCode.WrongUsernamePasswordPair:
                    return [
                        'Sorry, the email and password you entered do not match. Please try again.',
                    ];
                case ErrorCode.InactiveAccount:
                    return ['This user has been deactivated'];
                default:
                    break;
            }
        }
        return ['Oops! Something went wrong. Please try again later.'];
    },
    fields: {
        username: {
            type: 'email',
            validator: EmailAddress.schema.required(),
            formatValidationError(error) {
                switch (error.details.validator) {
                    case 'any.required':
                        return 'You must enter your email';
                    case 'string.email':
                        return 'You must enter a valid email address';
                    default:
                        return error.message;
                }
            },
        },
        password: {
            type: 'password',
            validator: Joi.string().required(),
            formatValidationError(error) {
                switch (error.details.validator) {
                    case 'any.required':
                        return 'You must enter your password';
                    default:
                        return 'You must enter a valid password';
                }
            },
        },
    },
});
interface UsernamePasswordProps {
    username?: EmailAddress;
}

export function UsernamePassword({ username }: UsernamePasswordProps) {
    const { navigate } = useNavigation();

    const { fields, submit, messages, value } = useForm(UsernamePasswordForm, {
        username,
    });

    const submitDisabled = !value.username || !value.password;

    return (
        <PublicForm
            data-e2e="user-org-username-pwd-submit-button"
            otherOption={
                <TextButton onClick={() => navigate(URI.build('/recover-password'))}>
                    Forgot your password?
                </TextButton>
            }
            messages={messages}
            submit={submit}
            title="Sign in"
            submitDisabled={submitDisabled}
        >
            <Form.Field
                data-e2e="user-org-username-pwd-form-email"
                inputProps={{
                    autoComplete: 'email',
                    ...fields.username.props,
                }}
                label="Email Address"
                messages={fields.username.messages}
                type="email"
            />
            <Form.Field
                data-e2e="user-org-username-pwd-current"
                inputProps={{
                    autoComplete: 'current-password',
                    ...fields.password.props,
                }}
                label="Password"
                messages={fields.password.messages}
                type="password"
            />
        </PublicForm>
    );
}
