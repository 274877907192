// **Register active experiment tests here.**
// Note: Be sure to activate test on the abtests service prior to FE deployment,
// 		 or else all accounts will be be auto-assigned with a default test assignment of 0.
export const ExperimentationTestNames = [
    'law-bundle-indicative-premium-test',
    'document-ingestion',
    'nb-rv-no-app-in-progress-or-quote',
    'nb-rv-single-valid-quote',
    'nb-rv-single-expired-quote',
    'nb-rv-single-app-in-progress',
    'nb-rv-expired-app-expired-quote',
    'quote-comparison',
    'lpl-deselected-popup',
    'tech-vertical-one-by-embroker',
] as const;

export type ExperimentationTestNames = typeof ExperimentationTestNames;

export enum ExperimentationServicePlatforms {
    GrowthBook = 'growthbook',
}
