import { WorkspaceOwnership } from '@app/userOrg/types/enums';
import type * as APITypes from '@embroker/shotwell-api/app';
import { API, UserOrganizationUpdateRequest } from '@embroker/shotwell-api/app';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { PhoneNumber } from '@embroker/shotwell/core/types/PhoneNumber';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    isOK,
    mergeErrors,
    Result,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { Revenue } from '@embroker/shotwell/core/types/Revenue';
import { State } from '@embroker/shotwell/core/types/StateList';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Year } from '@embroker/shotwell/core/types/Year';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import { EnricherCompany } from '../../../userOrg/types/EnricherCompany';
import { EnricherPerson } from '../../../userOrg/types/EnricherPerson';
import { Applicant } from '../../entities/Applicant';
import { NumberRangeOfW2Employees } from '../../types/enums';
import { Headquarters } from '../../types/Headquarters';
import { Place } from '../../types/Place';
import { ApplicantRepository } from './index';

@injectable()
export class APIApplicantRepository implements ApplicantRepository {
    private serverOrganization: Nullable<Immutable<APITypes.Organization>>;

    constructor() {
        this.serverOrganization = null;
    }

    public async getApplicant(): AsyncResult<
        Applicant,
        InvalidArgument | OperationFailed | UnknownEntity
    > {
        const detailsResult = await API.request('user/details', {
            user_email: null,
        });
        if (isErr(detailsResult)) {
            return handleOperationFailure(detailsResult);
        }
        const { user, org } = detailsResult.value as APITypes.UserDetailsResponse;

        this.serverOrganization = org;

        const applicantEntityResult = await APIApplicantRepository.toApplicant({
            organization: org,
            user,
        });

        if (isErr(applicantEntityResult)) {
            return applicantEntityResult;
        }

        return Success(applicantEntityResult.value as Applicant);
    }

    public async update(
        applicant: Applicant,
    ): AsyncResult<Applicant, InvalidArgument | OperationFailed | UnknownEntity> {
        if (!this.serverOrganization) {
            const response = await this.getApplicant();
            if (isErr(response)) {
                return response;
            }
            if (!this.serverOrganization) {
                return Failure(OperationFailed({ message: 'GetApplicant failed' }));
            }
        }
        const requestData: UserOrganizationUpdateRequest = {
            ...(this.serverOrganization as APITypes.Organization),
            website: applicant?.website ?? null,
            name: applicant?.organizationName ?? '',
            naics_code: applicant.naicsIndustry ?? null,
            raised_funding: applicant.hasReceivedVCFunding ?? null,
            tech_area_of_focus: applicant.techAreaOfFocus ?? null,
            email: applicant.userEmail,
            is_revenue_larger_than_20m: applicant?.isTotalRevenueLargerThan20MillionDollars ?? null,
            number_range_of_w2_employees: applicant?.numberRangeOfW2Employees ?? null,
            has_automobiles: applicant?.hasAutomobiles ?? null,
            higher_limits_approved: applicant?.higherLimitsApproved ?? false,
        };

        const applicantUpdateResult = await API.request('user/organization_update', requestData);

        if (isErr(applicantUpdateResult)) {
            return handleOperationFailure(applicantUpdateResult);
        }

        return Success(applicant);
    }

    private static async toApplicant({
        organization,
        user,
        companyEnrichedData,
        personEnrichedData,
    }: {
        organization: APITypes.Organization;
        user: APITypes.UserAccount;
        companyEnrichedData?: EnricherCompany;
        personEnrichedData?: EnricherPerson;
    }): AsyncResult<Applicant, InvalidArgument | OperationFailed> {
        const headquartersResponse = APIApplicantRepository.toHeadquarters(
            organization.headquarters,
        );

        if (isErr(headquartersResponse)) {
            return handleOperationFailure(headquartersResponse);
        }

        let salvagedPhone = null;
        // if user is a broker, his phone number is not relevant, so we use organization's phone number
        const applicablePhoneNumber = user.is_broker ? organization.phone_number : user.phone;

        const phoneNumberResult = PhoneNumber.create(
            applicablePhoneNumber ?? companyEnrichedData?.phoneNumber ?? '',
        );
        if (isOK(phoneNumberResult)) {
            salvagedPhone = phoneNumberResult.value;
        }

        const firstName = personEnrichedData?.fullName?.split(' ')[0];
        const lastName = personEnrichedData?.fullName?.split(' ').slice(1).join(' ');

        const isAutofilled =
            Boolean(firstName) ||
            Boolean(lastName) ||
            Boolean(personEnrichedData?.title) ||
            (!applicablePhoneNumber && Boolean(salvagedPhone)) ||
            Boolean(companyEnrichedData?.numEmployees) ||
            Boolean(companyEnrichedData?.yearFounded) ||
            Boolean(companyEnrichedData?.fundingRound) ||
            Boolean(companyEnrichedData?.naic);

        const result = await Applicant.create({
            id: organization.id as UUID,
            organizationName: organization.name,
            numberOfEmployees:
                organization.employee_number !== null
                    ? organization.employee_number
                    : companyEnrichedData?.numEmployees ?? undefined,
            naicsIndustry: organization.naics_code
                ? organization.naics_code
                : companyEnrichedData?.naic ?? undefined,
            doEmployeesTravelInternationally:
                organization.international_travel !== null
                    ? organization.international_travel
                    : undefined,
            hasReceivedVCFunding:
                organization.raised_funding ??
                (companyEnrichedData?.fundingRound != null ? true : undefined),
            techAreaOfFocus: organization.tech_area_of_focus || undefined,
            revenueList: this.apiRevenueListToRevenueList(organization.revenue_list) || undefined,
            userId: user.id,
            firstName: user.first_name || firstName,
            lastName: user.last_name || lastName,
            title: (user.prefix_title || personEnrichedData?.title) ?? undefined,
            userEmail: user.email as EmailAddress,
            website: organization.website || undefined,
            yearStarted: (organization.year_started ??
                companyEnrichedData?.yearFounded ??
                undefined) as Year | undefined,
            headquarters: headquartersResponse.value as Headquarters,
            entityTypeId: organization.entity_type_id || undefined,
            productOrService: organization.provided_service || undefined,
            totalPayroll: organization.total_payroll_of_employees || undefined,
            locations: APIApplicantRepository.toLocation(organization.location_list),
            phoneNumber: salvagedPhone || undefined,
            isTotalRevenueLargerThan20MillionDollars:
                organization.is_revenue_larger_than_20m || undefined,
            numberRangeOfW2Employees:
                (organization.number_range_of_w2_employees as NumberRangeOfW2Employees) ||
                undefined,
            hasAutomobiles: organization.has_automobiles || undefined,
            higherLimitsApproved: organization.higher_limits_approved ?? undefined,
            dateRecommendationQuestionsAnswered:
                organization.date_recommendation_questions_answered ?? undefined,
            isAutofilled: isAutofilled,
            workspaceType: this.toWorkspaceOwnership(organization.workspace_ownership),
        });
        if (isErr(result)) {
            return handleOperationFailure(result);
        }
        return Success(result.value as Applicant);
    }

    private static toLocation(apiLocation: APITypes.Location[]): Place[] {
        const result = apiLocation.map((item) => {
            const lines = item.address_lines?.split('\n');

            const state = this.toState(item.state);
            const zip = this.toZipCode(item.zip_code);

            return {
                id: item.id || null,
                addressLine1: lines?.[0] ? lines[0] : null,
                addressLine2: lines?.[1] ? lines[1] : null,
                city: item.city || null,
                zip,
                state,
                squareFootageOccupied: item.square_footage_occupied,
                county: item.county || null,
                valueOfProperty: item.value_of_property,
            };
        });
        return result;
    }

    private static toHeadquarters(
        apiHQ: APITypes.MailingAddress,
    ): Result<Headquarters | InvalidArgument> {
        const lines = apiHQ.address_lines?.split('\n');

        const state = this.toState(apiHQ.state);
        const zip = this.toZipCode(apiHQ.zip_code);

        const response = Headquarters.create({
            addressLine1: lines?.[0] ? lines[0] : null,
            addressLine2: lines?.[1] ? lines[1] : null,
            city: apiHQ.city || null,
            state,
            county: apiHQ.county || null,
            zip,
        });
        if (isErr(response)) {
            return mergeErrors([response]);
        }
        return Success(response.value);
    }

    private static toWorkspaceOwnership(input: string | undefined): WorkspaceOwnership | undefined {
        if (!input) {
            return undefined;
        }

        if (['office_owned', 'office_rented', 'coworking', 'home_office'].includes(input)) {
            return input as WorkspaceOwnership;
        }

        return undefined;
    }

    private static toZipCode(input: Nullable<string>): Nullable<ZipCode> {
        if (input == null) {
            return null;
        }

        const validateZipCodeResult = ZipCode.validate(input);
        if (isErr(validateZipCodeResult)) {
            return null;
        }

        return validateZipCodeResult.value;
    }

    private static toState(input: Nullable<string>): Nullable<State> {
        if (input == null) {
            return null;
        }

        const validateStateResult = State.validate(input);
        if (isErr(validateStateResult)) {
            return null;
        }

        return validateStateResult.value;
    }

    private static apiRevenueListToRevenueList(
        revenueList: Nullable<APITypes.RevenueList>,
    ): Nullable<Revenue[]> {
        return (
            revenueList?.map((item) => {
                return {
                    fiscalYear: Number.parseInt(item.fiscal_year) as Year,
                    grossTotal: item.gross_revenue_total,
                };
            }) ?? null
        );
    }
}
