import { isOK } from '@embroker/shotwell/core/types/Result';
import { OracleAnswerTypeDefinition } from '../OracleAnswerType';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { startOfDay } from 'date-fns';

export type OracleDate = {
    year: number;
    month: number;
    day?: number;
};

export const OracleDate = {
    ...defineValidator<OracleDate>({
        year: Joi.number().required(),
        month: Joi.number().required(),
        day: Joi.number().optional(),
    }),
    create(oracleLocation: OracleDate) {
        return OracleDate.validate(oracleLocation);
    },
    getOracleDate(date: Date): OracleDate {
        return {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate(),
        };
    },
};

export const serializeDateAnswer = (formData: unknown): OracleDate | undefined => {
    const dateObj = startOfDay(new Date(formData as string));
    const date = OracleDate.create({
        year: dateObj.getFullYear(),
        month: dateObj.getMonth() + 1,
        day: dateObj.getDate(),
    });
    if (isOK(date)) {
        return date.value;
    }
};

export function assertOracleDateType(input: any): input is OracleDate {
    return 'year' in input && 'month' in input;
}
export const deserializeDateAnswers = (currentValue: any): unknown[] | undefined => {
    if (!Array.isArray(currentValue)) {
        return undefined;
    }

    return (currentValue as OracleDate[]).reduce((accumulator, current) => {
        if (assertOracleDateType(current)) {
            const dateString = `${current.month}/${current.day ? current.day : '00'}/${
                current.year
            }`;
            accumulator.push(startOfDay(new Date(dateString)));
        }
        return accumulator;
    }, [] as Date[]);
};

export function createDateFromOracleDate(oracleDate: OracleDate): Date {
    const { year, month, day } = oracleDate;
    const adjustedMonth = month - 1;

    // If day is not provided, default to the 1st of the month
    const dateDay = day ?? 1;

    return new Date(year, adjustedMonth, dateDay);
}
export function createOracleDateFromDate(input: Date | string): OracleDate {
    if (typeof input === 'string') {
        const [monthStr, yearStr] = input.split('/');
        return { year: parseInt(yearStr, 10), month: parseInt(monthStr, 10) };
    }
    if (input instanceof Date) {
        return { year: input.getFullYear(), month: input.getMonth() + 1 };
    }
    throw new Error('Invalid input type. Expected Date object or string in "MM/YYYY" format');
}

export const DateDefinition: OracleAnswerTypeDefinition = {
    answerKeyType: 'date',
    schemaFunctions: {
        validator: OracleDate.schema,
        serializeAnswer: serializeDateAnswer,
        deserializeAnswer: deserializeDateAnswers,
    },
};
