import { Accordion, StackLayout, TextButton, Icon } from '@embroker/ui-toolkit/v2';
import React, { useContext } from 'react';
import { GetConfig } from '../../useCases/GetConfig';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { AppContext } from '../../../view/AppContext';
import { hasRole } from '../../../userOrg/entities/Session';

export function FAQ() {
    const { result: config } = useUseCase(GetConfig);

    const { activeSession } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');

    let useAscend = false;
    if (!isBroker && config !== undefined && isOK(config)) {
        useAscend = config.value?.useAscend;
    }

    function getDefaultFAQ(): JSX.Element {
        return (
            <Accordion>
                <Accordion.Item title="Why is there a charge associated with credit cards?">
                    Embroker endeavors to make insurance easier and has chosen to offer card payment
                    options for all invoices. The cost associated with credit card transactions is
                    passed on from our vendor.
                </Accordion.Item>
                <Accordion.Item title="I have a question about one of my invoices, how to reach out?">
                    <StackLayout gap="8">
                        <p>
                            Any way you want to! Embroker has multiple channels available to get
                            support when and how you want it.
                        </p>
                        <p>Contact us:</p>
                        <p>
                            <Icon name="chat" color="primary-500" size="small" /> Email to{' '}
                            <TextButton href="mailto:support@embroker.com">
                                support@embroker.com
                            </TextButton>
                        </p>
                        <p>
                            <Icon name="phone" color="primary-500" size="small" /> Call{' '}
                            <TextButton href="tel:844.436.2765">844.436.2765</TextButton>
                        </p>
                    </StackLayout>
                </Accordion.Item>
                <Accordion.Item title="When are my invoices due?">
                    Embroker invoices policies after purchase and therefore all invoices are due
                    immediately.
                </Accordion.Item>
            </Accordion>
        );
    }

    function getAscendFAQ(): JSX.Element {
        return (
            <Accordion>
                <Accordion.Item title="Why is there a charge associated with electronic payments (credit card, ACH, wire transfer)?">
                    Embroker seeks to make insurance easier and offers electronic payment options
                    for all invoices. The cost associated with these transactions is passed on from
                    our electronic payment vendor. Before you choose your electronic payment method,
                    you’ll see the applicable “transaction fees” added to the final amount to be
                    paid.
                </Accordion.Item>
                <Accordion.Item title="Who can I contact if I have a question about my invoice(s) or payment(s)?">
                    <StackLayout gap="8">
                        <p>
                            Our Accounting Team will be happy to help. Email us at{' '}
                            <TextButton href="mailto:billing@embroker.com">
                                billing@embroker.com
                            </TextButton>{' '}
                            or call us at{' '}
                            <TextButton href="tel:844.436.2765">844.436.2765</TextButton>, option
                            #4. Check additional questions below for how to/where to send payments
                            to.
                        </p>
                    </StackLayout>
                </Accordion.Item>
                <Accordion.Item title="When are my invoices due?">
                    All invoices are due immediately upon receipt. Failure to remit payment promptly
                    may result in cancellation, refusal to issue a policy, or other action by the
                    insurer. Thank you in advance for your prompt payment.
                </Accordion.Item>
                <Accordion.Item title="I’d like to pay electronically. How do I do that, and are there fees associated with credit card, ACH, or wire payments?">
                    <StackLayout gap="8">
                        <p>
                            To electronically pay your invoice(s), click on the Payments tab to the
                            left of this FAQ tab. You’ll see your open invoices there. Select the
                            invoices you’d like to pay and your preferred payment method. Click the
                            button to the right which will take you to Embroker’s electronic payment
                            and premium financing vendor, Ascend. Ascend will guide you through your
                            payment, and before anything is charged, you’ll see their fees as
                            “transaction fees” before the total payment recap.
                        </p>
                        <p>
                            To help you plan out your costs, transaction fees charged are:
                            <br />
                            Credit Card: 2.9% plus $0.30 per transaction
                            <br />
                            ACH: 0.8% up to $5 maximum fee per transaction
                            <br />
                            Wire Transfer: $8 flat fee per transaction
                        </p>
                        <p>
                            If you choose to finance your premium, terms of that financing agreement
                            will be provided and any finance (APR) or other fees will be disclosed
                            before you sign the Premium Finance Agreement. Please read the Agreement
                            and its terms carefully. Note: Only authorized representatives of your
                            Company are permitted to sign Premium Finance Agreements.
                        </p>
                    </StackLayout>
                </Accordion.Item>
                <Accordion.Item title="Can I mail a check instead of paying electronically?">
                    Yes! Please mail your check to the address on your invoice. If you need another
                    copy of your invoice, please click on Pay Now and you'll have the opportunity to
                    print your invoice in a few screens. Checks can take about (10) business days to
                    clear, and receipt of funds by Embroker does not constitute payment to the
                    insurer. Do not send any payments to an Embroker office as this further delays
                    processing. If you choose to pay by check, be advised that the time lag may
                    cause the insurer to issue a Notice of Cancellation for Nonpayment of Premium.
                    If a policy is canceled and payment is received after that cancellation date,
                    Embroker cannot guarantee that the policy will be reinstated — only the insurer
                    can decide whether to reinstate coverage and under what conditions.
                </Accordion.Item>
                <Accordion.Item title="Are there fees for paying by check?">
                    No, but please note that more time is involved in processing check payments as
                    described in the above question.
                </Accordion.Item>
            </Accordion>
        );
    }

    return useAscend ? getAscendFAQ() : getDefaultFAQ();
}
