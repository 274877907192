import { Immutable } from '@embroker/shotwell/core/types';
import { Money, USD } from '@embroker/shotwell/core/types/Money';
import { cast } from '@embroker/shotwell/core/types/Nominal';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Spinner, useModal } from '@embroker/ui-toolkit/v2';
import { Elements } from '@stripe/react-stripe-js';
import React, { useContext, useEffect, useState } from 'react';
import { useLoadingRoute } from 'react-navi';
import { AppContext } from '../../../view/AppContext';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { Payment, BundlePayment, SinglePayment, isBundlePayment } from '../../types/Payment';
import { GetInvoicesRequest, GetPendingInvoices } from '../../useCases/GetPendingInvoices';
import { useStripe } from '../hooks/useStripe';
import { BundleModalInvoiceDetail } from './modals/BundleInvoiceDetailsModal';
import { ModalInvoiceDetail } from './modals/InvoiceDetailsModal';
import { PayWithACHModal } from './modals/PayWithACHModal';
import { PayWithCCModal } from './modals/PayWithCCModal';
import { ThankYouModal } from './modals/ThankYouModal';
import { NoPaymentsDuePage } from './NoPaymentsDue';
import { PaymentsDue } from './PaymentsDue';
import { execute } from '@embroker/shotwell/core/UseCase';
import { PublishPaymentCTAClickEvent } from '../../useCases/PaymentCTAClickEvent';

export const BANK_VALUE = 'bank';
export const CREDIT_CARD_VALUE = 'card';
export const PREMIUM_FINANCE_VALUE = 'premium_finance';

export function buildInvoiceIdList(idList: UUID[], invoice: Immutable<Payment>): UUID[] {
    if (isBundlePayment(invoice)) {
        return [...idList, ...invoice.invoiceList.map((invoice) => invoice.id)];
    }
    return [...idList, invoice.id];
}

export function PaymentsDashboard() {
    const stripe = useStripe();

    const loading = useLoadingRoute();
    const { activeSession } = useContext(AppContext);
    const orgId: GetInvoicesRequest = {
        organizationId: cast<UUID>(activeSession.organizationId as UUID),
    };
    const userId = cast<UUID>(activeSession.userId as UUID);

    const { result, isLoading, reload } = useUseCase(GetPendingInvoices, orgId);
    const [currentInvoiceDetail, setCurrentInvoiceDetail] = useState<Immutable<SinglePayment>>();
    const [currentBundleInvoiceDetail, setCurrentBundleInvoiceDetail] =
        useState<Immutable<BundlePayment>>();
    const [total, setTotal] = useState(USD(0));
    const [option, setOption] = useState('bank');
    const navigation = useNavigation();

    const [activeInvoices, setActiveInvoices] = useState<UUID[]>([]);
    const [UIActiveInvoices, setUIActiveInvoices] = useState<UUID[]>([]);

    useEffect(() => {
        if (result !== undefined && isOK(result)) {
            const idList = result.value.invoiceDueList.reduce(
                (idList, invoice) => buildInvoiceIdList(idList, invoice),
                new Array<UUID>(),
            );
            const UIIdList: UUID[] = result.value.invoiceDueList.map((invoice) => invoice.id);
            setActiveInvoices(idList);
            setUIActiveInvoices(UIIdList);
        }
    }, [result]);

    const toggleActive = (UIPaymentId: UUID, invoiceIdList: UUID[]) => {
        const UIIndex = UIActiveInvoices.indexOf(UIPaymentId);
        if (UIIndex >= 0) {
            setUIActiveInvoices([
                ...UIActiveInvoices.slice(0, UIIndex),
                ...UIActiveInvoices.slice(UIIndex + 1),
            ]);
            let newArray = [...activeInvoices];
            for (const id of invoiceIdList) {
                const index = newArray.indexOf(id);
                if (index >= 0) {
                    newArray = [...newArray.slice(0, index), ...newArray.slice(index + 1)];
                }
            }
            setActiveInvoices(newArray);
        } else {
            setUIActiveInvoices(UIActiveInvoices.concat(UIPaymentId));
            setActiveInvoices(activeInvoices.concat(invoiceIdList));
        }
    };

    const modal = useModal();
    const bundleModal = useModal();
    const ACHModal = useModal();
    const CCModal = useModal();
    const ThankYouForPaymentModal = useModal();

    if (isLoading || result === undefined) {
        return <Spinner />;
    }

    function showModal(invoice: Immutable<SinglePayment>) {
        modal.show();
        setCurrentInvoiceDetail(invoice);
    }

    function hideModal() {
        modal.hide();
    }

    function showBundleModal(invoiceList: Immutable<BundlePayment>) {
        bundleModal.show();
        setCurrentBundleInvoiceDetail(invoiceList);
    }

    function hideBundleModal() {
        bundleModal.hide();
    }

    function showACHModal(total: Money) {
        execute(PublishPaymentCTAClickEvent, { type: 'Bank' });
        ACHModal.show();
        setTotal(total);
    }

    function hideACHModal() {
        ACHModal.hide();
    }

    function showCCModal(total: Money) {
        execute(PublishPaymentCTAClickEvent, { type: 'Credit Card' });
        CCModal.show();
        setTotal(total);
    }

    function hideCCModal() {
        CCModal.hide();
    }

    function handleOptionChange(option: string) {
        setOption(option);
    }

    function showThankYouPaymentModal() {
        ThankYouForPaymentModal.show();
    }

    async function hideThankYouForPaymentModal() {
        ThankYouForPaymentModal.hide();
        reload();
    }

    function handlePayWithPremiumFinance() {
        execute(PublishPaymentCTAClickEvent, { type: 'Financing' });
        navigation.navigate(
            URI.build('/payments/finance/are-you-eligible', {
                selectedInvoiceIdList: activeInvoices.join(','),
            }),
        );
    }

    if (loading) {
        return <Spinner />;
    }

    if (isOK(result)) {
        if (result.value.invoiceDueList.length > 0) {
            return (
                <React.Fragment>
                    <PaymentsDue
                        activeInvoices={activeInvoices}
                        UIActiveInvoices={UIActiveInvoices}
                        invoiceDueList={result.value.invoiceDueList}
                        onOptionChange={handleOptionChange}
                        onPayWithPremiumFinance={handlePayWithPremiumFinance}
                        option={option}
                        showACHModal={showACHModal}
                        showCCModal={showCCModal}
                        showModal={showModal}
                        showBundleModal={showBundleModal}
                        toggleActive={toggleActive}
                        userId={userId}
                        organizationId={orgId.organizationId}
                    />
                    <ModalInvoiceDetail
                        hideModal={hideModal}
                        invoice={currentInvoiceDetail}
                        modal={modal}
                    />
                    <BundleModalInvoiceDetail
                        hideModal={hideBundleModal}
                        bundlePayment={currentBundleInvoiceDetail}
                        modal={bundleModal}
                    />
                    <Elements stripe={stripe}>
                        <PayWithACHModal
                            activeInvoices={activeInvoices}
                            hideModal={hideACHModal}
                            showThankYouModal={showThankYouPaymentModal}
                            modal={ACHModal}
                            total={total}
                        />
                        <PayWithCCModal
                            activeInvoices={activeInvoices}
                            hideModal={hideCCModal}
                            showThankYouModal={showThankYouPaymentModal}
                            modal={CCModal}
                            total={total}
                        />
                        <ThankYouModal
                            modal={ThankYouForPaymentModal}
                            hideModal={hideThankYouForPaymentModal}
                        >
                            Your payment has been received
                        </ThankYouModal>
                    </Elements>
                </React.Fragment>
            );
        } else {
            return <NoPaymentsDuePage />;
        }
    }
    return null;
}
