import {
    ColumnLayout,
    Modal,
    StackLayout,
    Text,
    ModalActions,
    ModalState,
    Button,
} from '@embroker/ui-toolkit/v2';
import React from 'react';

interface HigherLimitSuccessModalProps {
    readonly modal: ModalActions & ModalState;
    onDismiss(): void;
}

export function HigherLimitSuccessModal({ modal, onDismiss }: HigherLimitSuccessModalProps) {
    const closeOnIssue = async () => {
        modal.hide();
        onDismiss();
    };
    return (
        <Modal {...modal} size="small" dismissable={false}>
            <StackLayout gap="32">
                <Text style="heading 3">Success! </Text>
                <Text>The request was sent. We will get back to you soon.</Text>
                <ColumnLayout gap="32">
                    <Button onClick={closeOnIssue}>Back to Policy</Button>
                </ColumnLayout>
            </StackLayout>
        </Modal>
    );
}
