import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface CrimeConfig {
    readonly crimeDevModeEffectiveDate: string;
    readonly isEmbrokerCrimeEnabled: boolean;
}

export const CrimeConfig = {
    ...defineValidator<CrimeConfig>({
        crimeDevModeEffectiveDate: Joi.string().allow(''),
        isEmbrokerCrimeEnabled: Joi.bool().required(),
    }),
    create(crimeConfig: CrimeConfig) {
        return CrimeConfig.validate(crimeConfig);
    },
};
