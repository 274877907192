import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';

const UserOrgClickEventNameList = [
    'Guided Flow CTA clicked',
    'I know what i need CTA clicked',
    'Signup Broker Banner Clicked',
    'Start a Quote CTA Clicked',
    'Shop All Coverage CTA Clicked',
] as const;
type UserOrgClickEventNameList = typeof UserOrgClickEventNameList;
export type UserOrgClickEventName = UserOrgClickEventNameList[number];

export interface UserOrgUserClickEvent extends DomainEvent {
    name: 'UserOrgUserClickEvent';
    clickEventName: UserOrgClickEventName;
}

interface ArgsProps {
    clickEventName: UserOrgClickEventName;
}

export interface PublishUserOrgUserClickEvent extends UseCase {
    execute(request: ArgsProps): AsyncResult<void>;
}

@injectable()
class PublishUserOrgUserClickEventUseCase extends UseCase implements PublishUserOrgUserClickEvent {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/PublishUserOrgUserClickEventUseCase');

    /**
     * Constructor for PublishUserOrgUserClickEvent class instance
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    /**
     * Executes the PublishUserOrgUserClickEvent use case.
     */
    public async execute({ clickEventName }: ArgsProps): AsyncResult<void> {
        const event = {
            id: UUID.create(),
            createdAt: new Date(Date.now()),
            origin: 'UserOrg',
            name: `UserOrgUserClickEvent`,
            clickEventName: clickEventName,
        };

        await this.eventBus.publish(event);

        return Success();
    }
}

export const PublishUserOrgUserClickEvent: UseCaseClass<PublishUserOrgUserClickEvent> =
    PublishUserOrgUserClickEventUseCase;
