import { Money } from '@embroker/shotwell/core/types/Money';
import { defineValidator } from '@embroker/shotwell/core/validation/schema';

export interface ESPEndorsementRate {
    annualPremium: Money;
    prorate: Money;
    taxes: Money;
    fees: Money;
    total: Money;
}

export const ESPEndorsementRate = {
    ...defineValidator<ESPEndorsementRate>({
        annualPremium: Money.schema,
        prorate: Money.schema,
        taxes: Money.schema,
        fees: Money.schema,
        total: Money.schema,
    }),
    create(espEndorsementRate: ESPEndorsementRate) {
        return ESPEndorsementRate.validate(espEndorsementRate);
    },
};
