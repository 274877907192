import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UnknownNAICSCode } from '../../errors';
import { NAICS } from '../../types/NAICS';

/**
 * This is the repository used to handle NAICS requests
 */
export interface NAICSRepository {
    /**
     * Retrieves NAICS object for provided NAICS code
     * @param naicsCode NAICS code for which we seek more information
     * @returns NAICS object in case that exists for provided NAICS code
     * @return UnknownNAICSCode if NAICS is not found for provided NAICS code
     * @returns InvalidArgument or OperationFailed in case that request fails or data is corrupted
     */
    getByCode(
        naicsCode: string,
    ): AsyncResult<NAICS, InvalidArgument | OperationFailed | UnknownNAICSCode>;
    /**
     * Returns list of NAICS objects that match provided search term
     * @param searchTerm input string that should narrow down result streak
     * @returns InvalidArgument or OperationFailed in case that request failed or data is corrupted
     */
    getListBySearchTerm(
        searchTerm: string,
    ): AsyncResult<NAICS[], InvalidArgument | OperationFailed>;
    /**
     * Returns boolean indicating whether given NAICS belongs to the given NAICS group
     * @param naicsCode NAICS code checked
     * @param naicsGroup NAICS group which is being checked
     */
    isNaicsInGroup(naicsCode: string, naicsGroup: string): boolean;

    /**
     * Returns the name(s) of the NAICS group(s) given its code
     * @param naicsCode NAICS code checked
     */
    getNaicsGroupNamesByCode(naicsCode: string): string[];

    /**
     * Returns industry name w/o loading data
     * @param naicsCode NAICS code
     */
    getNaicsNameByCode(naicsCode: string): string | undefined;

    getNaicsCodesInGroup(
        naicsGroup: string,
    ): AsyncResult<getNaicsCodesInGroupResponse, OperationFailed | InvalidArgument>;
}

export type getNaicsCodesInGroupResponse = {
    readonly naicsCodes: Immutable<string[]>;
};

export const NAICSRepository = Symbol('NAICSRepository');
