import {
    LineOfBusinessCodeListItem,
    LineOfBusinessCodeListMap,
    StructuralComponentTypeCodeListItem,
} from '@embroker/shotwell-api/enums';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import {
    Button,
    ButtonBar,
    GridLayout,
    SidebarLayout,
    StackLayout,
    StatusMessage,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React, { useEffect, useState } from 'react';
import { CertificateCoverageInfo } from '../../../types/CertificateCoverageInfo';
import {
    SelfServingCertificateCoverage,
    SelfServingCertificateCoverageList,
} from '../../../types/SelfServingCertificateCoverage';
import { SelectCoveragesStepGuidelines } from './SelectCoveragesStepGuidelines';
import {
    CoverageIconDataProps,
    SelfServingCoveragePolicyCard,
} from './SelfServingCoveragePolicyCard';

function buildPolicyDisplayName(
    coverageInfo: CertificateCoverageInfo,
    lineOfBusiness: LineOfBusinessCodeListItem,
): string {
    const policiesNameByLineOfBusiness = new Map<LineOfBusinessCodeListItem, string>([
        ['LineOfBusinessCodeListWorkersCompensation', 'Workers Compensation'],
        ['LineOfBusinessCodeListAuto', 'Commercial Auto'],
        ['LineOfBusinessCodeListBOP', 'Business Owner’s'],
        ['LineOfBusinessCodeListGeneralLiability', 'General Liability'],
        ['LineOfBusinessCodeListUmbrella', 'Umbrella'],
        ['LineOfBusinessCodeListDirectorsAndOfficers', 'Directors and Officers'],
        ['LineOfBusinessCodeListProperty', 'Property'],
        ['LineOfBusinessCodeListEmploymentPractices', 'Employment Practices Liability'],
        [
            'LineOfBusinessCodeListProfessionalLiability',
            'Professional Liability / Errors and Omissions',
        ],
        ['LineOfBusinessCodeListPackage', 'Package'],
        ['LineOfBusinessCodeListExcess', 'Excess'],
        ['LineOfBusinessCodeListOther', 'Other Liability'],
        ['LineOfBusinessCodeListCyber', 'Cyber / Data Breach'],
        [
            'LineOfBusinessCodeListOwnersAndContractors',
            'Owners and Contractors Protective Liability',
        ],
        ['LineOfBusinessCodeListCrime', 'Crime'],
        ['LineOfBusinessCodeListFiduciary', 'Fiduciary'],
        ['LineOfBusinessCodeListProductLiability', 'Product Liability'],
        ['LineOfBusinessCodeListTravel', 'International/Foreign Package'],
        ['LineOfBusinessCodeListCannabis', 'Cannabis'],
        ['LineOfBusinessCodeListESP', 'Embroker Startup Package'],
        ['LineOfBusinessCodeListPCoML', 'Business Management Insurance'],
    ]);

    function suffixWithPolicy(text: string) {
        return text + ' Policy';
    }

    if (
        coverageInfo.customName &&
        lineOfBusiness !== 'LineOfBusinessCodeListESP' &&
        lineOfBusiness !== 'LineOfBusinessCodeListPCoML'
    ) {
        return suffixWithPolicy(coverageInfo.customName);
    }

    if (lineOfBusiness === undefined) {
        return suffixWithPolicy('Other');
    }

    const policyName = policiesNameByLineOfBusiness.get(lineOfBusiness);
    if (policyName === undefined) {
        return suffixWithPolicy('Other');
    }

    return suffixWithPolicy(policyName);
}

function buildCoverageDisplayNameByLoB(
    coverageInfo: CertificateCoverageInfo,
    lineOfBusiness: LineOfBusinessCodeListItem,
    policyName: string,
): string {
    const structuralComponentToDisplayTitleMap: Map<StructuralComponentTypeCodeListItem, string> =
        new Map([
            ['StructuralComponentTypeCodeListAutoLiabilitySection', 'Auto Liability'],
            ['StructuralComponentTypeCodeListCyberLiabilitySection', 'Cyber Liability'],
            [
                'StructuralComponentTypeCodeListDirectorsAndOfficersLiabilitySection',
                'Directors And Officers Liability',
            ],
            [
                'StructuralComponentTypeCodeListEmploymentPracticesLiabilitySection',
                'Employment Practices Liability',
            ],
            ['StructuralComponentTypeCodeListGeneralLiabilitySection', 'General Liability'],
            ['StructuralComponentTypeCodeListOtherLiabilitySection', 'Other Liability'],
            [
                'StructuralComponentTypeCodeListOwnersAndContractorsSection',
                'Owners And Contractors Liability',
            ],
            [
                'StructuralComponentTypeCodeListProfessionalLiabilitySection',
                'Professional Liability',
            ],
            [
                'StructuralComponentTypeCodeListUmbrellaExcessLiabilitySection',
                'Umbrella Excess Liability',
            ],
            ['StructuralComponentTypeCodeListPropertySection', 'Property Liability'],
            ['StructuralComponentTypeCodeListEmployersLiabilitySection', 'Employers Liability'],
            ['StructuralComponentTypeCodeListFiduciaryLiabilitySection', 'Fiduciary Liability'],
            [
                'StructuralComponentTypeCodeListTechEOCyberLiabilitySection',
                'Errors & Omissions / Cyber Liability',
            ],
        ]);

    function suffixWithLiability(coverageName: string) {
        return coverageName + (coverageName.includes('Liability') ? '' : ' Liability');
    }

    if (lineOfBusiness === 'LineOfBusinessCodeListOther') {
        return suffixWithLiability(policyName);
    }

    if (
        lineOfBusiness === 'LineOfBusinessCodeListBOP' ||
        lineOfBusiness === 'LineOfBusinessCodeListPackage' ||
        lineOfBusiness === 'LineOfBusinessCodeListESP' ||
        lineOfBusiness === 'LineOfBusinessCodeListPCoML'
    ) {
        const coverageName = structuralComponentToDisplayTitleMap.get(
            coverageInfo.structuralComponentTypeCode,
        );

        if (coverageName !== undefined) {
            return coverageName;
        }
    }

    const defaultCoverageName = LineOfBusinessCodeListMap[lineOfBusiness];
    if (defaultCoverageName === undefined) {
        return 'Other Liability';
    }
    return suffixWithLiability(defaultCoverageName);
}

export interface SelectCoveragesStepData {
    selectedIdList: UUID[];
    selectedCoverageList: SelfServingCertificateCoverageList;
}

interface SelectCoveragesStepProps {
    coverageMap: Map<UUID, SelfServingCertificateCoverage>;
    selectCoveragesData: SelectCoveragesStepData;
    onSelectedIdListChange(selectedId: UUID, addToList: boolean): void;
    onNext(): void;
    onBack(): void;
}

export function SelectCoveragesStep({
    coverageMap,
    onNext,
    onBack,
    onSelectedIdListChange,
    selectCoveragesData,
}: SelectCoveragesStepProps) {
    const coverageList = Array.from(coverageMap);
    const policyCoverageList = coverageList.filter(
        ([key, coverage]) => !coverage.coverageInfo.bundleId,
    );

    const bundleCoverageList = coverageList.filter(
        ([key, coverage]) => coverage.coverageInfo.bundleId,
    );

    const createCoverageCard = (key: UUID, coverage: SelfServingCertificateCoverage) => {
        const coverageInfo = coverage.coverageInfo;
        const policyLineOfBusiness = coverageInfo.policyLineOfBusiness;

        if (policyLineOfBusiness == undefined) {
            return null;
        }

        const policyName = buildPolicyDisplayName(coverageInfo, policyLineOfBusiness);

        const coverageName = buildCoverageDisplayNameByLoB(
            coverageInfo,
            policyLineOfBusiness,
            policyName,
        );

        const coverageIconData: CoverageIconDataProps = {
            policyLineOfBusiness: coverageInfo.policyLineOfBusiness,
            policyLineOfBusinessSubtype: coverageInfo.policyLineOfBusinessSubtype,
            structuralComponentTypeCode: coverageInfo.structuralComponentTypeCode,
        };

        return (
            <SelfServingCoveragePolicyCard
                coverageName={coverageName}
                policyName={policyName}
                carrierName={coverageInfo.insurer?.name}
                policyNumber={coverageInfo.policyNumber}
                policyEffectiveDate={coverageInfo.policyEffectiveDate}
                policyEndDate={coverageInfo.policyEndDate}
                limits={coverageInfo.limits}
                coverageIconData={coverageIconData}
                id={key}
                selectedIdList={selectCoveragesData.selectedIdList}
                onSelectedIdListChange={onSelectedIdListChange}
                key={key}
            />
        );
    };

    const policyCoverageCardList = policyCoverageList.map(([key, coverage]) => {
        return createCoverageCard(key, coverage);
    });

    const bundleCoverageCardList = bundleCoverageList.map(([key, coverage]) => {
        return createCoverageCard(key, coverage);
    });

    const [onNextNoCoveragesSelected, setOnNextNoCoveragesSelected] = useState(false);

    const onNextClicked = () => {
        if (selectCoveragesData.selectedIdList.length == 0) {
            setOnNextNoCoveragesSelected(true);
            return;
        }
        setOnNextNoCoveragesSelected(false);
        onNext();
    };

    useEffect(() => {
        if (onNextNoCoveragesSelected && selectCoveragesData.selectedIdList.length > 0) {
            setOnNextNoCoveragesSelected(false);
        }
    }, [selectCoveragesData.selectedIdList, onNextNoCoveragesSelected]);

    return (
        <SidebarLayout appearance="default" sidebarVariant="compact">
            <SelectCoveragesStepGuidelines />
            <StackLayout>
                <GridLayout>
                    {onNextNoCoveragesSelected && (
                        <StatusMessage
                            status={'error'}
                            className="u-grid-size-12"
                            data-e2e="no-selected-coverages-questionnaire"
                        >
                            {' '}
                            Please select at least one coverage{' '}
                        </StatusMessage>
                    )}
                </GridLayout>
                <GridLayout>
                    {policyCoverageCardList.length > 0 && (
                        <Text
                            style="heading 5"
                            className="u-grid-size-12"
                            data-e2e="select-coverages-questionnaire"
                        >
                            Please select coverage(s) you would like to add:
                        </Text>
                    )}
                </GridLayout>
                <StackLayout gap="48">
                    <GridLayout>{policyCoverageCardList}</GridLayout>
                    <GridLayout>
                        {bundleCoverageList.length > 0 && (
                            <Text
                                style="heading 5"
                                className="u-grid-size-12"
                                data-e2e="select-coverages-questionnaire"
                            >
                                Please select individual coverage(s) from your bundle(s) you would
                                like to add:
                            </Text>
                        )}
                    </GridLayout>
                </StackLayout>
                <GridLayout>{bundleCoverageCardList}</GridLayout>
                <GridLayout>
                    <div className="u-grid-size-12">
                        <ButtonBar
                            split={'-1'}
                            reverse
                            responsive={{ containerWidth: { smallerThan: 400 } }}
                        >
                            <TextButton onClick={onBack} data-e2e="select-coverages-back">
                                Back
                            </TextButton>
                            <Button onClick={onNextClicked} data-e2e="select-coverages-submit">
                                Next
                            </Button>
                        </ButtonBar>
                    </div>
                </GridLayout>
                <SidebarLayout.MobileFooter split="-1">
                    <Text>What does this mean?</Text>
                    <SidebarLayout.Link appearance="secondary" panelIndex={1}>
                        Let us explain
                    </SidebarLayout.Link>
                </SidebarLayout.MobileFooter>
            </StackLayout>
        </SidebarLayout>
    );
}
