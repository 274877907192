import React, { useEffect, useMemo } from 'react';
import { PublishOnboardingStepVisitedEvent } from '../../../useCases/PublishOnboardingStepVisitedEvent';
import { OnboardingPageProps } from './OnboardingFlowWrapper';
import { Form, Nullable, PageLayout, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import { execute } from '@embroker/shotwell/core/UseCase';
import { OnboardingPrefillQuestionnaireData } from '@app/userOrg/types/OnboardingPrefillQuestionnaireData';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { container } from '@embroker/shotwell/core/di';
import { Success } from '@embroker/shotwell/core/types/Result';
import { FormViewLayout } from '@app/view/components/FormViewLayout.view';

interface VettingFormData {
    vetting: Nullable<boolean>;
}

const createVettingForm = (
    onCompleteOnboardingStep: (questionnaireData?: OnboardingPrefillQuestionnaireData) => void,
) => {
    return createForm<VettingFormData>({
        fields: {
            vetting: {
                type: 'radioGroup',
                validator: Joi.boolean().allow(null),
                formatValidationError(error) {
                    return 'You must select naics code';
                },
            },
        },
        submit: async (formData) => {
            onCompleteOnboardingStep({
                vetting: formData.vetting as boolean,
            });
            return Success();
        },
        formatSubmitErrors(errors) {
            if (errors.length === 0) {
                return [];
            }

            container
                .get<Logger>(Log)
                .warn(`User onboarding - Vetting page formatSubmitErrors: ${errors}`);
            return ['Sorry, something went wrong. Please try again later.'];
        },
    });
};

export function VettingRefinement({
    onCompleteOnboardingStep,
    onBackClicked,
    questionnaireData,
}: OnboardingPageProps) {
    const VettingForm = useMemo(
        () => createVettingForm(onCompleteOnboardingStep),
        [onCompleteOnboardingStep],
    );

    const { fields, messages, submit } = useForm(VettingForm, {
        vetting: questionnaireData?.vetting || null,
    });

    useEffect(() => {
        execute(PublishOnboardingStepVisitedEvent, {
            step: 'vettingRefinement',
        });
    }, []);

    const disableButton =
        fields.vetting.props.value === null || fields.vetting.props.value === undefined;

    return (
        <PageLayout.Section>
            <FormViewLayout
                title="Tell us more about your services?"
                errorMessages={[]}
                disableSubmit={disableButton}
                onFormSubmit={submit}
                handleBack={onBackClicked}
                data-e2e-submit="user-org-public-submit-btn"
            >
                <StackLayout gap="24">
                    <Text>
                        Does your company provide technology services or products, such as software
                        development, IT consulting, or technology-related support services to others
                        for a fee?
                    </Text>
                </StackLayout>
                <Form.Field
                    messages={messages}
                    inputProps={{
                        ...fields.vetting.props,
                        value: fields.vetting.props.value ?? undefined,
                        items: [
                            {
                                value: true,
                                title: 'Yes',
                            },
                            {
                                value: false,
                                title: 'No',
                            },
                        ],
                    }}
                    type="radioGroup"
                />
            </FormViewLayout>
        </PageLayout.Section>
    );
}
