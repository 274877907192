import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import {
    createForm,
    Field,
    Form as ShotwellForm,
    useForm,
} from '@embroker/shotwell/view/hooks/useForm';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    Button,
    Form,
    FormLayout,
    StackLayout,
    StatusLabel,
    StatusMessage,
    StatusMessageList,
    Table,
    Text,
} from '@embroker/ui-toolkit/v2';
import React, { useEffect, useState } from 'react';
import { UserInvite } from '../../entities/UserInvite';
import { GetActiveOrganizationProfile } from '../../useCases/GetActiveOrganizationProfile';
import { GetActiveUserProfile } from '../../useCases/GetActiveUserProfile';
import { GetUserInvites } from '../../useCases/GetUserInvites';
import { SendUserInvites } from '../../useCases/SendUserInvites';
import { UserOrgPage } from './UserOrgPage';

interface InviteMembersFormData {
    inviteMembers: string[];
}

export const inviteMembersForm = createForm<InviteMembersFormData>({
    useCase: SendUserInvites,
    fields: {
        inviteMembers: {
            type: 'csv',
            validator: Joi.array()
                .items(Joi.string().email({ tlds: false }).trim().allow(''))
                .min(1)
                .required(),
            formatValidationError(error) {
                switch (error.details.validator) {
                    case 'any.required':
                    case 'array.min':
                        return 'You must enter at least one email';
                    case 'string.email':
                        return 'Make sure that your emails are valid and try again';
                    default:
                        return error.message;
                }
            },
        },
    },
    formatSubmitErrors(errors) {
        if (errors.length !== 0) {
            return ['Make sure that your emails are valid and try again'];
        }
        return [];
    },
});

export function InviteMembers() {
    const { submit, status, fields, messages, value, setValue } = useForm(inviteMembersForm);
    const { result: activeOrganizationResult } = useUseCase(GetActiveOrganizationProfile);
    const { result: activeUserResult } = useUseCase(GetActiveUserProfile);
    const { result: userInvitesResult, reload: reFetchUserInvites } = useUseCase(GetUserInvites);

    if (!userInvitesResult || !activeOrganizationResult || !activeUserResult) {
        return null;
    }

    if (isErr(userInvitesResult)) {
        return <ErrorPage errors={userInvitesResult.errors} />;
    }

    if (isErr(activeOrganizationResult)) {
        return <ErrorPage errors={activeOrganizationResult.errors} />;
    }

    if (isErr(activeUserResult)) {
        return <ErrorPage errors={activeUserResult.errors} />;
    }

    const onInviteFinished = () => {
        reFetchUserInvites();
    };

    const userInvites = userInvitesResult.value.invites as Immutable<EntityProps<UserInvite>>[];
    const email = activeUserResult.value.email;
    const userCanInvite = getUserInvitePrivileges(userInvites, email);

    return (
        <UserOrgPage>
            <FormLayout>
                <Form noValidate onSubmit={submit}>
                    <StackLayout gap="32">
                        <Text data-e2e="user-org-inv-members-header" style="heading 3">
                            Edit Team Settings
                        </Text>
                        {userCanInvite ? (
                            <MemberInviteArea
                                data-e2e="user-org-inv-members-invite-area"
                                formData={{ status, fields, messages, value, setValue }}
                                onInviteFinished={onInviteFinished}
                            />
                        ) : null}
                        <MemberTable userInvites={userInvites} currentUserEmail={email} />
                    </StackLayout>
                </Form>
            </FormLayout>
        </UserOrgPage>
    );
}

interface MemberInviteAreaProps {
    formData: Pick<
        ShotwellForm<InviteMembersFormData>,
        'status' | 'fields' | 'messages' | 'value' | 'setValue'
    >;
    onInviteFinished(): void;
}

function MemberInviteArea({ formData, onInviteFinished }: MemberInviteAreaProps) {
    const { status, fields, messages, value, setValue } = formData;
    const [isInviteSentSuccessfully, setIsInviteSentSuccessfully] = useState(false);

    useEffect(() => {
        if (status === 'invalid') {
            setIsInviteSentSuccessfully(false);
        }
        if (status === 'submitted') {
            setIsInviteSentSuccessfully(true);
            setValue({ ...value, inviteMembers: [] });
            onInviteFinished();
        }
    }, [status, value, setValue, onInviteFinished]);

    return (
        <React.Fragment>
            {isInviteSentSuccessfully && (
                <StatusMessage data-e2e="user-org-inv-members-success-msg" status="success">
                    Your invitation(s) have been sent successfully
                </StatusMessage>
            )}
            <StatusMessageList
                messages={messages}
                status="error"
                data-e2e="user-org-inv-members-error-msg"
            />
            <Form.Field
                data-e2e="user-org-inv-members-text-area"
                inputProps={{ ...fields.inviteMembers.props }}
                label="Invite member(s) with their emails (separate with commas)*"
                messages={filterDuplicatedErrorMessages(fields.inviteMembers.messages)}
                type={fields.inviteMembers.type}
            />

            <Button data-e2e="user-org-inv-submit-button" disabled={false} type="submit">
                Invite
            </Button>
        </React.Fragment>
    );
}

interface MemberTableProps {
    userInvites: Immutable<EntityProps<UserInvite>>[];
    currentUserEmail: EmailAddress;
}

function MemberTable({ userInvites, currentUserEmail }: MemberTableProps) {
    const invites = parseInvitationsForUI(userInvites, currentUserEmail);

    return (
        <Table style="subtle">
            <Table.Header>
                <Table.Column>Email</Table.Column>
                <Table.Column>Role</Table.Column>
                <Table.Column>Status</Table.Column>
            </Table.Header>
            <Table.Body data-e2e="user-org-inv-table">
                {invites.map((row) => (
                    <Table.Row key={row.id as UUID}>
                        <Table.Cell>{row.email}</Table.Cell>
                        <Table.Cell>{row.role}</Table.Cell>
                        <Table.Cell>
                            <StatusLabel type={row.status == 'Active' ? 'green' : 'yellow-outline'}>
                                {row.status}
                            </StatusLabel>
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    );
}

/**
 * Check whether user with provided useEmail has enabled inviting feature
 * @param invitations List of users/email in organization
 * @param userEmail Email of user currently viewing the list
 */
function getUserInvitePrivileges(
    invitations: Immutable<EntityProps<UserInvite>>[],
    userEmail: Nullable<EmailAddress>,
): boolean {
    if (userEmail === null) {
        return false;
    }
    const callerInvitation = invitations.find(
        (invitation) => invitation.email === userEmail && invitation.status === 'accepted',
    );

    if (!callerInvitation) {
        return false;
    }
    return callerInvitation.inviteEnabled;
}

function parseInvitationsForUI(
    userInvites: Immutable<EntityProps<UserInvite>>[],
    currentUserEmail: EmailAddress,
): any[] {
    const currentUserAcceptedInvite = userInvites.find(
        (item) => item.email === currentUserEmail && item.status === 'accepted',
    );

    const currentUserInvite =
        currentUserAcceptedInvite ?? userInvites.find((item) => item.email === currentUserEmail);

    const otherUsers = userInvites.filter((item) => item.email !== currentUserEmail);

    const sortedInvites = currentUserInvite ? [currentUserInvite] : [];
    sortedInvites.push(...otherUsers);

    const invites = [];
    for (const invite of sortedInvites) {
        invites.push({
            key: invite.id,
            email: invite.email === currentUserEmail ? invite.email + ' (YOU)' : invite.email,
            role: invite.inviteEnabled ? 'Owner' : 'Team Member',
            status:
                invite.status === 'accepted'
                    ? 'Active'
                    : invite.status === 'rejected'
                    ? 'Invitation expired'
                    : 'Pending invitation',
        });
    }

    return invites;
}

function filterDuplicatedErrorMessages(
    messages: Immutable<Field<InviteMembersFormData['inviteMembers']>['messages']>,
) {
    if (messages.length > 0) {
        const areAllMessagesTheSame = messages.every((element) => {
            if (element === messages[0]) {
                return true;
            }
        });
        return areAllMessagesTheSame ? [messages[0]] : messages;
    }
    return messages;
}

export default InviteMembers;
