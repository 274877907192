import { WorkspaceOwnership, WorkspaceOwnershipList } from '@app/userOrg/types/enums';
import { defineEntityValidator, entity, Entity } from '@embroker/shotwell/core/entity/Entity';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { Money } from '@embroker/shotwell/core/types/Money';
import { PhoneNumber } from '@embroker/shotwell/core/types/PhoneNumber';
import { Revenue } from '@embroker/shotwell/core/types/Revenue';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Year } from '@embroker/shotwell/core/types/Year';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { NumberRangeOfW2Employees, NumberRangeOfW2EmployeesCodeList } from '../types/enums';
import { Headquarters } from '../types/Headquarters';
import { Place } from '../types/Place';

interface ApplicantUpdated extends DomainEvent {
    readonly origin: 'Applicant';
    readonly name: 'Updated';
}

export interface UpdateApplicantProps {
    readonly website?: string;
    readonly naicsIndustry?: string;
    readonly hasReceivedVCFunding?: boolean;
    readonly numberOfEmployees?: number;
    readonly isTotalRevenueLargerThan20MillionDollars?: boolean;
    readonly numberRangeOfW2Employees?: NumberRangeOfW2Employees;
    readonly hasAutomobiles?: boolean;
}

export interface Applicant extends Entity {
    readonly organizationName?: string;
    readonly numberOfEmployees?: number;
    readonly naicsIndustry?: string;
    readonly doEmployeesTravelInternationally?: boolean;
    readonly hasReceivedVCFunding?: boolean;
    readonly techAreaOfFocus?: string;
    readonly revenueList?: Revenue[];
    update(newApplicantInfo: UpdateApplicantProps): void;
    readonly userId: UUID;
    readonly firstName?: string;
    readonly lastName?: string;
    readonly title?: string;
    readonly userEmail: EmailAddress;
    readonly website?: string;
    readonly yearStarted?: Year;
    readonly headquarters: Headquarters;
    readonly totalPayroll?: Money;
    readonly productOrService?: string;
    readonly entityTypeId?: UUID;
    readonly locations?: Place[];
    readonly phoneNumber?: PhoneNumber;
    readonly isTotalRevenueLargerThan20MillionDollars?: boolean;
    readonly numberRangeOfW2Employees?: NumberRangeOfW2Employees;
    readonly hasAutomobiles?: boolean;
    readonly higherLimitsApproved?: boolean;
    readonly dateRecommendationQuestionsAnswered?: Date;
    readonly isAutofilled?: boolean;
    readonly workspaceType?: WorkspaceOwnership;
}

export const Applicant = entity<Applicant>({
    // Most of the fields can be unset by admin
    validator: defineEntityValidator<Applicant>({
        organizationName: Joi.string().optional(),
        numberOfEmployees: Joi.number().optional(),
        naicsIndustry: Joi.string().optional(),
        doEmployeesTravelInternationally: Joi.boolean().optional(),
        hasReceivedVCFunding: Joi.boolean().optional(),
        techAreaOfFocus: Joi.string().optional(),
        revenueList: Joi.array().optional().items(Revenue.schema),
        userId: UUID.schema.required(),
        firstName: Joi.string().optional(),
        lastName: Joi.string().optional(),
        title: Joi.string().optional(),
        userEmail: EmailAddress.schema.required(),
        website: Joi.string().optional(),
        yearStarted: Year.schema.optional(),
        headquarters: Headquarters.schema,
        entityTypeId: UUID.schema.optional(),
        productOrService: Joi.string().optional(),
        totalPayroll: Money.schema.optional(),
        locations: Joi.array().optional().items(Place.schema),
        phoneNumber: PhoneNumber.schema.optional(),
        isTotalRevenueLargerThan20MillionDollars: Joi.boolean().optional(),
        numberRangeOfW2Employees: Joi.string()
            .valid(...NumberRangeOfW2EmployeesCodeList)
            .optional(),
        hasAutomobiles: Joi.boolean().optional(),
        higherLimitsApproved: Joi.boolean().optional(),
        dateRecommendationQuestionsAnswered: Joi.date().optional(),
        isAutofilled: Joi.boolean().optional(),
        workspaceType: Joi.string()
            .valid(...WorkspaceOwnershipList)
            .optional(),
    }),
    update(newApplicantInfo: UpdateApplicantProps) {
        Object.assign(this.props, newApplicantInfo);
        this.createEvent<ApplicantUpdated>('Updated');
    },
});
