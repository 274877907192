import { isDateAfter, isDateBefore } from '@embroker/service-app-engine';
import { Data } from '@embroker/shotwell/core/types';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    Button,
    Card,
    ColumnLayout,
    Form,
    Icon,
    Modal,
    ModalActions,
    ModalState,
    StackLayout,
    Text,
    TextButton,
    Tooltip,
    useModal,
} from '@embroker/ui-toolkit/v2';
import React, { useContext } from 'react';
import { WizardForm } from '../../../../../view/hooks/useWizardForm';
import { espCoverageDetails } from '../../../../espUtils/quoteDocumentUtils';
import { ESPCoverageRateItem } from '../../../types/ESPRate';
import { getLimitRetentionOptions } from '../ESPCoverage/limitRetentionOptions';
import { ESPQuoteOptionsFormData } from '../ESPQuoteLandingPage';
import { FAQModalContent } from '../Modals/FAQModal';
import { HowMuchCoverageModalContent } from '../Modals/HowMuchCoverageModal';
import { HowWeCompareModalContent } from '../Modals/HowWeCompareModal';
import {
    coverageIsSelectedField,
    coverageLevelField,
    coverageLimitField,
    coverageRetentionField,
} from './ESPCoveragesPage';
import { ESPDoDifferenceModal } from './ESPDoDifferenceModal';
import { ESPEoCyberDifferenceModal } from './ESPEoCyberDifferenceModal';
import { ESPEplDifferenceModal } from './ESPEplDifferenceModal';
import { AppContext } from '../../../../../view/AppContext';

interface ESPRecommendedCoverageProps {
    coverage: ESPCoverageRateItem;
    isSubmitting: boolean;
    revenue: number;
    newInsurerDocumentsReleaseDate?: Date;
    fiduciaryDocumentsReleaseDate?: Date;
    submittedAt?: Date;
    fields: WizardForm<OpaqueForm<ESPQuoteOptionsFormData>>['fields'];
    isQuoteReferred: boolean;
}

export const ESPRecommendedCoverage = ({
    coverage,
    fields,
    revenue,
    isSubmitting,
    newInsurerDocumentsReleaseDate,
    fiduciaryDocumentsReleaseDate,
    submittedAt,
    isQuoteReferred,
}: ESPRecommendedCoverageProps) => {
    const limitField = coverageLimitField(coverage.coverageType, fields);
    const retentionField = coverageRetentionField(coverage.coverageType, fields);
    const isSelectedField = coverageIsSelectedField(coverage.coverageType, fields);
    const levelField = coverage.level && coverageLevelField(coverage.coverageType, fields);

    const faqModal = useModal();
    const howWeCompareModal = useModal();
    const howMuchCoverageModal = useModal();
    const doModal = useModal();
    const epliModal = useModal();
    const eoCyberModal = useModal();
    const modals: Data<ModalState & ModalActions> = {
        do: doModal,
        epli: epliModal,
        eoCyber: eoCyberModal,
        techCyber: eoCyberModal,
    };

    const { globalConfig } = useContext(AppContext);

    if (!limitField || !retentionField) {
        return null;
    }

    const getCoverageUrl = (
        coverageType: 'do' | 'epli' | 'fiduciary' | 'eoCyber' | 'techCyber',
        newInsurerDocumentsReleaseDate?: Date,
        fiduciaryDocumentsReleaseDate?: Date,
        submittedAt?: Date,
        effectiveDate?: Date,
    ): string => {
        const espIptChangesEnabled = globalConfig?.espIptChangesEnabled;
        const releaseDate = globalConfig?.espIptChangesReleaseDate;
        if (
            espIptChangesEnabled &&
            releaseDate &&
            effectiveDate &&
            !isDateBefore(effectiveDate, releaseDate)
        ) {
            switch (coverageType) {
                case 'do':
                case 'epli':
                case 'techCyber':
                    return espCoverageDetails[coverageType].document[
                        coverage.level == 'standard'
                            ? 'post_ipt_2024_standard'
                            : 'post_ipt_2024_plus'
                    ];
            }
        }

        if (
            fiduciaryDocumentsReleaseDate !== undefined &&
            submittedAt !== undefined &&
            coverageType == 'fiduciary'
        ) {
            if (isDateAfter(submittedAt, fiduciaryDocumentsReleaseDate)) {
                return espCoverageDetails['fiduciary'].document.new_standard;
            }
        }

        if (newInsurerDocumentsReleaseDate !== undefined && submittedAt !== undefined) {
            if (isDateAfter(submittedAt, newInsurerDocumentsReleaseDate)) {
                if (coverageType == 'fiduciary') {
                    return espCoverageDetails['fiduciary'].document.standard;
                }
                if (coverageType == 'do' || coverageType == 'epli') {
                    return espCoverageDetails[coverageType].document[
                        coverage.level == 'standard' ? 'new_standard' : 'new_plus'
                    ];
                }
                return espCoverageDetails[coverageType].document[coverage.level ?? 'old'];
            }
        }
        return coverageType != 'fiduciary'
            ? espCoverageDetails[coverageType].document[coverage.level ?? 'old']
            : espCoverageDetails['fiduciary'].document.standard;
    };

    if (globalConfig === undefined) {
        return null;
    }

    const effectiveDate = fields.startDate.props.value;
    const isClearBlueToEverspanSwitchActive =
        effectiveDate !== undefined &&
        isDateAfter(effectiveDate, globalConfig.espClearBlueEverspanSwitchDate);

    if (coverage.coverageType == 'techCyber') {
        return (
            <Card
                menuItems={[
                    <TextButton
                        target="_blank"
                        key={'download' + coverage.coverageType + 'Coverage'}
                        href={getCoverageUrl(
                            coverage.coverageType,
                            newInsurerDocumentsReleaseDate,
                            fiduciaryDocumentsReleaseDate,
                            submittedAt,
                            effectiveDate,
                        )}
                    >
                        Download coverage details
                    </TextButton>,
                    <TextButton onClick={faqModal.show} key="1">
                        FAQs
                    </TextButton>,
                    <TextButton onClick={howWeCompareModal.show} key="2">
                        How We Compare
                    </TextButton>,
                ]}
            >
                <Card.Header>
                    <Icon name={espCoverageDetails[coverage.coverageType].icon} />
                    <Text style="heading 5">{espCoverageDetails[coverage.coverageType].title}</Text>
                </Card.Header>
                <Card.Body>
                    <StackLayout>
                        <Text>{espCoverageDetails[coverage.coverageType].text}</Text>
                        <Text style="heading 5">
                            Tech E&O
                            <Tooltip
                                trigger="click"
                                iconSize="small"
                                text={
                                    levelField?.props.value === 'plus'
                                        ? 'The Tech E&O limits are for the Liability coverages, including Insuring Agreements 1, 2, 3, and 4 on the "PLUS" form.'
                                        : 'The Tech E&O limits are for the Liability coverages, including Insuring Agreements 1a, 1b, and 2a on the "Standard" form.'
                                }
                            />
                        </Text>
                        <ColumnLayout
                            gap="16"
                            responsive={{ screenWidth: { smallerThan: 'tablet' } }}
                        >
                            <Form.Field
                                type={fields.techLimit.type}
                                inputProps={{
                                    ...fields.techLimit.props,
                                    filterable: false,
                                    items: getLimitRetentionOptions(coverage.coverageType).limits,
                                    label: 'Limit',
                                    disabled: true,
                                    value: getLimitRetentionOptions(
                                        coverage.coverageType,
                                    ).limits.reduce(
                                        (op, item) => (op = op > item.value ? op : item.value),
                                        0,
                                    ),
                                }}
                            />
                            <Form.Field
                                type={fields.techRetention.type}
                                inputProps={{
                                    ...fields.techRetention.props,
                                    filterable: false,
                                    items: getLimitRetentionOptions(coverage.coverageType)
                                        .retentions,
                                    label: 'Retention',
                                    disabled: true,
                                }}
                            />
                        </ColumnLayout>
                        <Text style="heading 5">
                            1st Party Expenses
                            <Tooltip
                                trigger="click"
                                iconSize="small"
                                text="These cover 1st party expenses after a network security event. These limits are rarely contractually required, so you’re unlikely to need higher coverage limits — plus, lowering the limit is a good way to reduce your premium!"
                            />
                        </Text>
                        <ColumnLayout
                            gap="16"
                            responsive={{ screenWidth: { smallerThan: 'tablet' } }}
                        >
                            <Form.Field
                                type={fields.cyberLimit.type}
                                inputProps={{
                                    ...fields.cyberLimit.props,
                                    filterable: false,
                                    items: getLimitRetentionOptions(coverage.coverageType).limits,
                                    label: 'Limit',
                                    disabled: true,
                                    value: getLimitRetentionOptions(
                                        coverage.coverageType,
                                    ).limits.reduce(
                                        (op, item) => (op = op > item.value ? op : item.value),
                                        0,
                                    ),
                                }}
                            />
                            <Form.Field
                                type={fields.cyberRetention.type}
                                inputProps={{
                                    ...fields.cyberRetention.props,
                                    filterable: false,
                                    items: getLimitRetentionOptions(coverage.coverageType)
                                        .retentions,
                                    label: 'Retention',
                                    disabled: true,
                                }}
                            />
                        </ColumnLayout>
                        {levelField && !isQuoteReferred ? (
                            <React.Fragment>
                                <ColumnLayout split="-1">
                                    <Text style="heading 5">Select your coverage </Text>
                                    <TextButton onClick={modals[coverage.coverageType].show}>
                                        What's the difference?
                                    </TextButton>
                                </ColumnLayout>
                                <Form.Field
                                    type={levelField.type}
                                    inputProps={{
                                        ...levelField.props,
                                        items: [
                                            {
                                                value: 'plus',
                                                title: 'Plus',
                                            },
                                            {
                                                value: 'standard',
                                                title: 'Standard',
                                            },
                                        ],
                                        disabled: true,
                                    }}
                                />
                            </React.Fragment>
                        ) : null}
                    </StackLayout>
                </Card.Body>
                <Card.Footer>
                    <Button
                        appearance="primary"
                        onClick={() => {
                            fields.isCyberSelected.props.onChange({
                                target: {
                                    value: true,
                                },
                            });

                            fields.isTechSelected.props.onChange({
                                target: {
                                    value: true,
                                },
                            });
                        }}
                        disabled={isSubmitting}
                    >
                        Add Coverage
                    </Button>
                </Card.Footer>
                <Modal size="large" {...eoCyberModal}>
                    <ESPEoCyberDifferenceModal />
                </Modal>
                <FAQModalContent
                    modal={faqModal}
                    isClearBlueToEverspanSwitchActive={isClearBlueToEverspanSwitchActive}
                />
                <HowWeCompareModalContent
                    modal={howWeCompareModal}
                    coverageType={coverage.coverageType}
                />
                <HowMuchCoverageModalContent
                    modal={howMuchCoverageModal}
                    coverageType={coverage.coverageType}
                />
                <Modal size="large" {...doModal}>
                    <ESPDoDifferenceModal />
                </Modal>
                <Modal size="large" {...epliModal}>
                    <ESPEplDifferenceModal />
                </Modal>
            </Card>
        );
    }

    return (
        <Card
            menuItems={[
                <TextButton
                    target="_blank"
                    key={`download${coverage.coverageType}Coverage`}
                    href={getCoverageUrl(
                        coverage.coverageType,
                        newInsurerDocumentsReleaseDate,
                        fiduciaryDocumentsReleaseDate,
                        submittedAt,
                        effectiveDate,
                    )}
                >
                    Download coverage details
                </TextButton>,
                <TextButton onClick={faqModal.show} key="1">
                    FAQs
                </TextButton>,
                <TextButton onClick={howWeCompareModal.show} key="2">
                    How We Compare
                </TextButton>,
                coverage.coverageType != 'fiduciary' && (
                    <TextButton onClick={howMuchCoverageModal.show}>
                        How Much Coverage Do I Need
                    </TextButton>
                ),
            ]}
        >
            <Card.Header>
                <Icon name={espCoverageDetails[coverage.coverageType].icon} />
                <Text style="heading 5">{espCoverageDetails[coverage.coverageType].title}</Text>
            </Card.Header>
            <Card.Body>
                <StackLayout>
                    <Text>{espCoverageDetails[coverage.coverageType].text}</Text>
                    <ColumnLayout gap="16" responsive={{ screenWidth: { smallerThan: 'tablet' } }}>
                        <Form.Field
                            type={limitField.type}
                            inputProps={{
                                ...limitField.props,
                                filterable: false,
                                items: getLimitRetentionOptions(coverage.coverageType).limits,
                                label: 'Limit',
                                disabled: true,
                                value: getLimitRetentionOptions(
                                    coverage.coverageType,
                                ).limits.reduce(
                                    (op, item) => (op = op > item.value ? op : item.value),
                                    0,
                                ),
                            }}
                        />
                        <Form.Field
                            type={retentionField.type}
                            inputProps={{
                                ...retentionField.props,
                                filterable: false,
                                items: getLimitRetentionOptions(coverage.coverageType).retentions,
                                label: 'Retention',
                                disabled: true,
                            }}
                        />
                    </ColumnLayout>
                    {levelField && !isQuoteReferred ? (
                        <React.Fragment>
                            <ColumnLayout split="-1">
                                <Text style="heading 5">Select your coverage </Text>
                                <TextButton onClick={modals[coverage.coverageType].show}>
                                    What's the difference?
                                </TextButton>
                            </ColumnLayout>
                            <Form.Field
                                type={levelField.type}
                                inputProps={{
                                    ...levelField.props,
                                    items: [
                                        {
                                            value: 'plus',
                                            title: 'Plus',
                                        },
                                        {
                                            value: 'standard',
                                            title: 'Standard',
                                        },
                                    ],
                                    disabled: true,
                                }}
                            />
                        </React.Fragment>
                    ) : null}
                </StackLayout>
            </Card.Body>
            <Card.Footer>
                <Button
                    appearance="primary"
                    onClick={() => {
                        isSelectedField?.props.onChange({
                            target: {
                                value: true,
                            },
                        });
                    }}
                    disabled={isSubmitting}
                >
                    Add Coverage
                </Button>
            </Card.Footer>
            <Modal size="large" {...eoCyberModal}>
                <ESPEoCyberDifferenceModal />
            </Modal>
            <FAQModalContent
                modal={faqModal}
                isClearBlueToEverspanSwitchActive={isClearBlueToEverspanSwitchActive}
            />
            <HowWeCompareModalContent
                modal={howWeCompareModal}
                coverageType={coverage.coverageType}
            />
            <HowMuchCoverageModalContent
                modal={howMuchCoverageModal}
                coverageType={coverage.coverageType}
            />
            <Modal size="large" {...doModal}>
                <ESPDoDifferenceModal />
            </Modal>
            <Modal size="large" {...epliModal}>
                <ESPEplDifferenceModal />
            </Modal>
        </Card>
    );
};
