import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { UserRepository } from '../repositories/UserRepository';
import { Unauthenticated } from '../errors';

/**
 * Request data for ChangePassword use case
 * @property userId is the id of the user which password we want to change
 * @property oldPassword is the user's old password
 * @property newPassword is the user's new password
 */
export interface ChangePasswordRequest {
    oldPassword: string;
    newPassword: string;
}

/**
 * Change password use case is used to change password for authenticated user
 */

export interface ChangePassword extends UseCase {
    execute(
        request: ChangePasswordRequest,
    ): AsyncResult<void, Unauthenticated | UnknownEntity | OperationFailed | InvalidArgument>;
}

@injectable()
class ChangePasswordUseCase extends UseCase implements ChangePassword {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/ChangePassword');
    /**
     * Constructor for ChangePassword class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param userRepository is the user repository used to save user entity with new password
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(UserRepository) private userRepository: UserRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes the ChangePassword use case
     * Input is of type ChangePasswordRequest
     * @returns Nothing if execution was successful
     * @returns UnknownEntity error if user with userId does not exist on platform
     * @returns InvalidArgument if oldPassword does not match user's old password.
     * @returns InvalidArgument if newPassword has an invalid format
     * @returns OperationFailed if repo layer fails to deliver
     */
    public async execute({
        oldPassword,
        newPassword,
    }: ChangePasswordRequest): AsyncResult<
        void,
        Unauthenticated | UnknownEntity | OperationFailed | InvalidArgument
    > {
        const userResult = await this.userRepository.getActiveUser();
        if (isErr(userResult)) {
            return userResult;
        }

        const user = userResult.value;
        user.updatePassword(oldPassword, newPassword);

        const updateUserResult = await this.userRepository.save(user);
        if (isErr(updateUserResult)) {
            return handleOperationFailure(updateUserResult);
        }

        this.eventBus.publishEntityEvents(user);
        return Success();
    }
}

export const ChangePassword: UseCaseClass<ChangePassword> = ChangePasswordUseCase;
