import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject } from '@embroker/shotwell/core/di';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { BrokerRepository } from '../repositories';
import { BrokerageGetBrokerageByBrokerResponse } from '@embroker/shotwell-api/app.spec';
import { BrokerageNotFoundError } from '../errors';

export interface GetBrokerageByBrokerRequest {
    userId: UUID;
}
export interface GetBrokerageByBroker extends UseCase {
    execute(
        request: GetBrokerageByBrokerRequest,
    ): AsyncResult<BrokerageGetBrokerageByBrokerResponse, BrokerageNotFoundError>;
}

class GetBrokerageByBrokerUseCase extends UseCase implements GetBrokerageByBroker {
    public static type = Symbol('Broker/GetBrokerageByBroker');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BrokerRepository) private brokerRepository: BrokerRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: GetBrokerageByBrokerRequest,
    ): AsyncResult<BrokerageGetBrokerageByBrokerResponse, BrokerageNotFoundError> {
        const result = await this.brokerRepository.getBrokerageByBroker(request.userId);

        if (isErr(result)) {
            return result;
        }

        return Success(result.value);
    }
}

export const GetBrokerageByBroker: UseCaseClass<GetBrokerageByBroker> = GetBrokerageByBrokerUseCase;
