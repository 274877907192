import { USD } from '@embroker/shotwell/core/types/Money';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { InvoiceTable, StatusMessage } from '@embroker/ui-toolkit/v2';
import React, { useContext } from 'react';
import { hasRole } from '../../../../userOrg/entities/Session';
import { AppContext } from '../../../../view/AppContext';
import { WizardForm } from '../../../../view/hooks/useWizardForm';
import { indicationMessage } from '../../../types/QuoteHigherLimitsState';
import { withQuoteInfoProps } from '../../../view/components/WithExtraProps';
import { LPLQuote } from '../../entities/LPLQuote';
import { LPLHigherLimit } from '../../types/LPLHigherLimit';
import { PerClaimLimit } from '../../types/LPLQuoteOptions';
import { LPLQuoteFormData } from './createLPLQuoteForm';

interface LPLQuoteBreakdownProps<T extends LPLQuoteFormData, Q extends LPLQuote>
    extends withQuoteInfoProps {
    quote: Q;
    value: WizardForm<OpaqueForm<T>>['value'];
    status: WizardForm<OpaqueForm<T>>['status'];
    hasPrevious: boolean;
}

export function LPLQuoteBreakdown<T extends LPLQuoteFormData, Q extends LPLQuote>({
    quote,
    lplQuoteInfo,
    value,
    status,
    hasPrevious,
}: LPLQuoteBreakdownProps<T, Q>) {
    const { activeSession } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');
    const isDirty = status === 'dirty';
    const lplPolicyFee = quote.details.lplProcessingFee ?? USD(0);
    const showAnnualTechFee = !isBroker && (quote.annualTechnologyFee?.amount ?? 0) > 0;
    const limitHigherThenAllowed =
        value.perClaimLimit > (lplQuoteInfo.higherLimit?.per_claim_limit ?? 2000000);
    const hidePremium = (isDirty || quote.isIndication || limitHigherThenAllowed) && !isBroker;

    const indicationMessage = getIndicationMessages(
        value.perClaimLimit,
        quote.isIndication,
        isBroker,
        isDirty,
        lplQuoteInfo.higherLimit,
    );
    return (
        <InvoiceTable>
            <InvoiceTable.Subtotal data-e2e="selected-coverages-list" title="Coverages" />
            {quote.details.premium ? (
                <InvoiceTable.Item title="Policy Premium" data-e2e="policy-premium">
                    {!hidePremium && <MoneyDisplay value={quote.details.premium} />}
                </InvoiceTable.Item>
            ) : null}
            {isLPLStateWithFees(lplQuoteInfo.stateWithLargestNumber) &&
            quote.details.premium &&
            quote.fees ? (
                <InvoiceTable.Item
                    title={
                        getLPLStateFeeTitle(lplQuoteInfo.stateWithLargestNumber) +
                        ' (excluding transaction fees based on your choice of payment)'
                    }
                    data-e2e="lpl-fees"
                >
                    {!hidePremium && <MoneyDisplay value={quote.fees} />}
                </InvoiceTable.Item>
            ) : null}
            <InvoiceTable.Section>
                <InvoiceTable.Item
                    title="Total Premium with Taxes & Assessments"
                    data-e2e="total-premium-with-taxes"
                >
                    {!hidePremium && <MoneyDisplay value={quote.totalPremium} />}
                </InvoiceTable.Item>
            </InvoiceTable.Section>
            <InvoiceTable.Section>
                {isBroker ? (
                    <InvoiceTable.Item title="Embroker Access Fee" data-e2e="access-fee">
                        {!hidePremium && <MoneyDisplay value={lplPolicyFee} />}
                    </InvoiceTable.Item>
                ) : null}
                {showAnnualTechFee ? (
                    <InvoiceTable.Item
                        title="Annual technology fee"
                        tooltip="The Annual technology fee is applied once a year with the first policy purchase of the year."
                    >
                        <MoneyDisplay value={quote.annualTechnologyFee ?? USD(0)} />
                    </InvoiceTable.Item>
                ) : null}
                <InvoiceTable.Total title="Total: " data-e2e="total-amount">
                    {!hidePremium && <MoneyDisplay value={quote.totalPayable} />}
                </InvoiceTable.Total>
            </InvoiceTable.Section>
            {indicationMessage && !hasPrevious ? (
                <StatusMessage status={indicationMessage.status}>
                    {indicationMessage.message}
                </StatusMessage>
            ) : null}
        </InvoiceTable>
    );
}

const lplStateWithFeeTitleMap: { [key: string]: string } = {
    FL: 'Florida FIGA Surcharge',
    NJ: 'New Jersey PLIG Fund',
    WV: 'WV Fire & Casualty Surcharge',
};

function getIndicationMessages(
    perClaimLimit: PerClaimLimit,
    isIndication: boolean,
    isBroker: boolean,
    isDirty: boolean,
    higherLimit?: LPLHigherLimit,
): indicationMessage | null {
    const higherLimitHasBeenReviewedMessage =
        'Your submission has been reviewed and you can now select and quote any of the available limit and deductible options. Once you’ve made the selection, hit Recalculate to update the quote.';
    if (higherLimit && !isIndication) {
        return {
            message: higherLimitHasBeenReviewedMessage,
            status: 'info',
        };
    }
    if (isIndication && isBroker && !isDirty) {
        return {
            message:
                'Please note this amount is an indication only and is subject to further underwriting review.',
            status: 'warning',
        };
    }
    const maxRegularPerClaimLimit: PerClaimLimit = 2000000;
    if (perClaimLimit > maxRegularPerClaimLimit) {
        return {
            message:
                'You have selected a higher limit that needs to be approved by our staff prior to binding.',
            status: 'info',
        };
    }
    return null;
}

function isLPLStateWithFees(stateWithLargestNumber: string) {
    return stateWithLargestNumber in lplStateWithFeeTitleMap;
}

function getLPLStateFeeTitle(stateWithLargestNumber: string) {
    return lplStateWithFeeTitleMap[stateWithLargestNumber];
}
