import { container } from '@embroker/shotwell/core/di';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import {
    InsetBox,
    Modal,
    ModalActions,
    ModalState,
    Spinner,
    usePrevious,
} from '@embroker/ui-toolkit/v2';
import React, { useEffect, useState } from 'react';
import { FormEngine } from '../../../view/components';
import { navigateToErrorPage } from '../../../view/errors';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { GetSubmittedApplication } from '../../useCases/GetSubmittedApplication';

interface ViewQuestionnaireProps {
    applicationId?: UUID;
    modal: ModalState & ModalActions;
}

export function ViewQuestionnaireModal({ applicationId, modal }: ViewQuestionnaireProps) {
    const { visible } = modal;

    const previousVisible = usePrevious(visible);
    const [formEngine, setFormEngine] = useState<any>();
    const modalSize = visible && formEngine ? 'large' : 'small';
    const isModalDismissable = modalSize == 'small' ? false : true;
    const { navigate } = useNavigation();

    useEffect(() => {
        let didCancel = false;
        if (visible && !previousVisible && applicationId) {
            const loadApplication = async () => {
                const useCase = container.get<GetSubmittedApplication>(
                    GetSubmittedApplication.type,
                );
                const result = await useCase.execute({ id: applicationId });

                if (isErr(result)) {
                    navigateToErrorPage(navigate, result.errors);
                    return;
                }

                if (!didCancel) {
                    const { formEngine } = result.value;
                    setFormEngine(formEngine);
                }
            };

            loadApplication();
        }
        return () => {
            didCancel = true;
        };
    }, [applicationId, previousVisible, visible, navigate]);

    useEffect(() => {
        if (!visible && previousVisible) {
            setFormEngine(null);
        }
    }, [visible, previousVisible]);

    return (
        <Modal {...modal} size={modalSize} dismissable={isModalDismissable}>
            {visible && formEngine ? (
                <InsetBox>
                    <FormEngine
                        instance={formEngine}
                        navigation
                        readOnly
                        inWizard={false}
                        completed
                    />
                </InsetBox>
            ) : (
                <Spinner />
            )}
        </Modal>
    );
}
