import { IconName } from '@embroker/ui-toolkit/v2';
import { MPLVertical } from '../../userOrg/types/enums';
import { MPLBundleUnderlyingAppType } from './enums';

interface RiskAssessmentCardData {
    title: string;
    coverageName: string;
    iconName: IconName;
    description: string;
    riskExposures: string[];
    footnote: React.ReactNode;
    isTrainingIncluded: boolean;
}

const MPL_DEFAULT_CARD_DATA: RiskAssessmentCardData = {
    title: '',
    coverageName: '',
    iconName: 'calculator',
    description: '',
    riskExposures: [],
    footnote: null,
    isTrainingIncluded: false,
};

const CYBER_DEFAULT_CARD_DATA: RiskAssessmentCardData = {
    title: 'CYBER RISK PROFILE',
    coverageName: 'Cyber Insurance',
    iconName: 'cyber',
    description: 'Protects companies from malicious cyber attacks and their unwanted effects.',
    riskExposures: [
        "Traditional general liability insurance doesn't offer protection for losses from hacks, data breaches, or phishing scams.",
        "Your employees access sensitive client data, exposing you to legal action from them, your clients and other end users if there's a data breach.",
    ],
    footnote: null,
    isTrainingIncluded: true,
};
const WC_DEFAULT_CARD_DATA: RiskAssessmentCardData = {
    title: 'WORKPLACE LIABILITIES',
    coverageName: 'Workers Compensation',
    iconName: 'workers-comp',
    description:
        'Required by most states, it pays medical bills, lost wages, and other amounts under law when employees are injured at work.',
    riskExposures: [
        'Office workers aren’t immune to risk. Your employees are susceptible to slips and falls, and developing repetitive stress injuries from extensive time sitting and using a computer.',
    ],
    footnote: null,
    isTrainingIncluded: false,
};

const BOP_DEFAULT_CARD_DATA: RiskAssessmentCardData = {
    title: 'PROPERTY AND PREMISES EXPOSURES',
    coverageName: 'Business Owners Policy',
    iconName: 'user-shield',
    description:
        'An insurance policy for small businesses that provides core property and liability coverages.',
    riskExposures: [],
    footnote: null,
    isTrainingIncluded: false,
};

export const DEFAULT_RISK_ASSESSMENT_COVERAGE_DATA: Record<
    MPLBundleUnderlyingAppType,
    RiskAssessmentCardData
> = {
    AppTypeCodeListMPL: MPL_DEFAULT_CARD_DATA,
    AppTypeCodeListCyberCowbell: CYBER_DEFAULT_CARD_DATA,
    AppTypeCodeListBOPChubb: BOP_DEFAULT_CARD_DATA,
    AppTypeCodeListWCChubb: WC_DEFAULT_CARD_DATA,
};

const MPLVerticalCoverageNames: Record<MPLVertical, string> = {
    Accounting: 'Accountants Professional Liability',
    TaxPreparation: 'Tax Preparers & Bookkeepers Professional Liability',
    RealEstateAgent: 'Real Estate Agents Professional Liability',
    NonTechnologyConsultants: 'Non-Technology Consultants Professional Liability',
    HomeInspectorAndBuildingInspectionServices: 'Home Inspectors Professional Liability',
};

const MPLVerticalRiskTitles: Record<MPLVertical, string> = {
    Accounting: 'RISKS FOR ACCOUNTANTS',
    TaxPreparation: 'RISKS FOR TAX PREPARERS AND BOOKKEEPERS',
    RealEstateAgent: 'RISKS FOR REAL ESTATE AGENTS',
    NonTechnologyConsultants: 'RISKS FOR NON-TECHNOLOGY CONSULTANTS',
    HomeInspectorAndBuildingInspectionServices: 'RISKS FOR HOME INSPECTORS',
};

const MPLVerticalRiskExposures: Record<MPLVertical, string[]> = {
    Accounting: [
        "Your CPAs drive your firm's risk profile because they are responsible for reviewing and ensuring the accuracy of clients' financial records. Improper reporting or allegations of errors can lead to claims.",
    ],
    TaxPreparation: [
        'Even if the claim is unfounded, your firm can be sued by clients alleging errors, negligence, or breach of contract.',
        'In 2021, 73% of lawsuits against firms like yours were related to tax services.*',
    ],
    RealEstateAgent: [
        'Providing inaccurate information, incomplete disclosures, breach of fiduciary duty, and ethics complaints are among the most common claims against realtors.',
    ],
    NonTechnologyConsultants: [
        'Allegations of breach of contract, financial losses or damages, or errors/omissions in work products are common claims against consultants.',
    ],
    HomeInspectorAndBuildingInspectionServices: [
        'Home inspectors risk being sued for the costs of damages and repairs if clients allege that significant property defects were misidentified or not reported.',
    ],
};

const MPLVerticalDescriptions: Record<MPLVertical, string> = {
    Accounting:
        "Protects you and your business if you're sued for negligence, common mistakes, and more.",
    TaxPreparation:
        "Protects you and your business if you're sued for negligence, common mistakes, and more.",
    RealEstateAgent:
        "Protects you and your business if you're sued for negligence, common mistakes, and more.",
    NonTechnologyConsultants:
        "Protects Non-Technology Business and Management Consultants if you're sued for negligence, common mistakes, and more.",
    HomeInspectorAndBuildingInspectionServices:
        "Protects you and your business if you're sued for negligence, common mistakes, and more.",
};

const WCVerticalRiskExposure: Record<MPLVertical, string[]> = {
    Accounting: [
        'Office workers aren’t immune to risk. Your employees are susceptible to slips and falls, and developing repetitive stress injuries from extensive time sitting and using a computer.',
    ],
    TaxPreparation: [
        'Office workers aren’t immune to risk. Your employees are susceptible to slips and falls, and developing repetitive stress injuries from extensive time sitting and using a computer.',
    ],
    RealEstateAgent: [
        'Coordinating schedules, meeting clients, and visiting properties can put Real Estate Agents at risk of slips and falls, animal injuries, and other accidents.',
    ],
    NonTechnologyConsultants: [
        'Office workers aren’t immune to risk. Your employees are susceptible to slips and falls, and developing repetitive stress injuries from extensive time sitting and using a computer.',
    ],
    HomeInspectorAndBuildingInspectionServices: [
        'Inspecting homes can be physically demanding, and may put your employees at risk for falls and accidents, exposure to hazardous materials, electrocution, or other dangers.',
    ],
};

const MPLVerticalFootnotes: Record<MPLVertical, string | undefined> = {
    TaxPreparation: '*source: CNA Professional Liability Claims Database, copyright 2022',
};

export function enrichMPLCardData(vertical: MPLVertical): RiskAssessmentCardData {
    const title = MPLVerticalRiskTitles[vertical];
    const coverageName = MPLVerticalCoverageNames[vertical];
    const riskExposures = MPLVerticalRiskExposures[vertical];
    const description = MPLVerticalDescriptions[vertical];
    const footnote = MPLVerticalFootnotes[vertical];
    return {
        ...MPL_DEFAULT_CARD_DATA,
        title,
        coverageName,
        riskExposures,
        description,
        footnote,
    };
}

export function enrichWCCardData(vertical: MPLVertical): RiskAssessmentCardData {
    const riskExposures = WCVerticalRiskExposure[vertical];
    return {
        ...WC_DEFAULT_CARD_DATA,
        riskExposures,
    };
}

export function enrichBOPCardData(workplaceType: string): RiskAssessmentCardData {
    const riskExposures = [];
    if (workplaceType === 'office_rented') {
        riskExposures.push(
            "Renters bear the risks of property loss or damage, as well as for injuries to others at their location. Check your lease to understand what types of coverage you're required to have.",
        );
    } else {
        riskExposures.push(
            'Even if your company is 100% remote or based in a home office, protecting all equipment and technology owned by your business can make all the difference when facing natural disasters, theft, vandalism, or accidents!',
        );
    }
    return { ...BOP_DEFAULT_CARD_DATA, riskExposures };
}
