import { EspQuoteOptions } from '@embroker/shotwell-api/app';
import { Nullable } from '@embroker/shotwell/core/types';

export interface ESPRenewalQuoteOptions {
    isDNOSelected?: boolean;
    DNOLimit: number;
    DNORetention: number;
    DNOLevel?: 'plus' | 'standard';
    isEPLSelected?: boolean;
    EPLLimit: number;
    EPLRetention: number;
    EPLLevel?: 'plus' | 'standard';
    isFiduciarySelected?: boolean;
    isEOSelected?: boolean;
    EOLimit: number;
    EORetention: number;
    EOLevel: 'plus' | 'standard';
    isTechSelected?: boolean;
    TechLimit?: number;
    TechRetention?: number;
    TechLevel?: 'plus' | 'standard';
    isCyberSelected?: boolean;
    CyberLimit?: number;
    CyberRetention?: number;
    CyberLevel?: 'plus' | 'standard';
    effectivePeriodStart: Date;
}

function coverageLevelToEnhancedFlag(level: 'plus' | 'standard' | undefined): Nullable<boolean> {
    switch (level) {
        case 'plus':
            return true;
        case 'standard':
            return false;
        default:
            return null;
    }
}

export const ESPRenewalQuoteOptionsToESPRateOptions = (
    quoteOptions: ESPRenewalQuoteOptions,
): EspQuoteOptions => {
    const dnoQuoteOptions = {
        selected: !!quoteOptions.isDNOSelected,
        limit: quoteOptions.DNOLimit,
        retention: quoteOptions.DNORetention,
        enhanced: coverageLevelToEnhancedFlag(quoteOptions.DNOLevel),
    };

    const epliQuoteOptions = {
        selected: !!quoteOptions.isEPLSelected,
        limit: quoteOptions.EPLLimit,
        retention: quoteOptions.EPLRetention,
        enhanced: coverageLevelToEnhancedFlag(quoteOptions.EPLLevel),
    };

    const fiduciaryQuoteOptions = {
        selected: !!quoteOptions.isFiduciarySelected,
    };

    const technologyQuoteOptions = {
        selected: quoteOptions.isTechSelected ?? false,
        limit: quoteOptions.TechLimit ?? 1000000,
        retention: quoteOptions.TechRetention ?? 2500,
        enhanced: coverageLevelToEnhancedFlag(quoteOptions.TechLevel),
    };

    const cyberQuoteOptions = {
        selected:
            (technologyQuoteOptions.selected &&
                quoteOptions.CyberLimit !== undefined &&
                quoteOptions.CyberLimit > 0) ??
            false,
        limit: quoteOptions.CyberLimit ?? 0,
        retention: technologyQuoteOptions.retention,
        enhanced: technologyQuoteOptions.enhanced,
    };

    return {
        directors_and_officers: dnoQuoteOptions,
        employment_practices_liability: epliQuoteOptions,
        fiduciary: fiduciaryQuoteOptions,
        technology: technologyQuoteOptions,
        cyber: cyberQuoteOptions,
        partner_code: '',
        effective_period_start: quoteOptions.effectivePeriodStart,
        policy_fee: null,
        is_policy_fee_taxable: false,
    };
};
