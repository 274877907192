import * as APITypes from '@embroker/shotwell-api/app';
import { API } from '@embroker/shotwell-api/app';
import { DigitalEndorsementStatusCodeListItem } from '@embroker/shotwell-api/enums';
import { APIError, isAPIError } from '@embroker/shotwell-api/errors';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { RequestResult } from '@embroker/shotwell/core/networking';
import {
    AsyncResult,
    Success,
    handleOperationFailure,
    isErr,
} from '@embroker/shotwell/core/types/Result';
import {
    AvailableEndorsementsSpecs,
    DigitalEndorsementsRepository,
    EndorsementRecord,
    EndorsementSpec,
    ProrateRequest as GetEndorsementQuoteRequest,
    ProrateResponse as GetEndorsementQuoteResponse,
    IssueEndorsementRequest,
    ListRequest,
    PrecreateRequest,
    PreviewRequest,
} from './index';
import { UUID } from '@embroker/shotwell/core/types/UUID';

@injectable()
export class APIDigitalEndorsementsRepository implements DigitalEndorsementsRepository {
    constructor(@inject(Log) private logger: Logger) {}

    public async precreateEndorsement(
        request: PrecreateRequest,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed> {
        const precreateEndorsementResult = await API.request(
            'digital_endorsements/precreate_endorsement_direct',
            {
                policy_id: request.policyId,
                origin: request.origin,
                endorsement_type: request.endorsementType,
            },
        );

        if (isErr(precreateEndorsementResult)) {
            return handleOperationFailure(precreateEndorsementResult);
        }

        const endorsementId = precreateEndorsementResult.value.endorsement_id;

        return Success(endorsementId);
    }

    public async getEndorsementQuote(
        request: GetEndorsementQuoteRequest,
    ): AsyncResult<GetEndorsementQuoteResponse, InvalidArgument | OperationFailed> {
        const endorsementQuoteResult = await API.request(
            'digital_endorsements/get_endorsement_quote_direct',
            {
                endorsement_id: request.endorsementId,
                endorsement_data: request.endorsementData,
            },
        );

        if (isErr(endorsementQuoteResult)) {
            const error = endorsementQuoteResult.errors[0];
            if (isAPIError(error)) {
                // Handle specific errors here...
            }

            return handleOperationFailure(endorsementQuoteResult);
        }

        const prorate = endorsementQuoteResult.value.endorsement_quote;

        return Success({
            rateId: prorate.rate_id,
            prorateId: prorate.prorate_id,
            currentPremium: prorate.current_premium,
            returnPremium: prorate.cumulative_premium,
            premiumChange: prorate.premium_change,
            taxes: prorate.taxes,
            fees: prorate.fees,
            totalPremiumChange: prorate.total_premium_change,
        });
    }

    public async previewEndorsement(
        request: PreviewRequest,
    ): AsyncResult<string, InvalidArgument | OperationFailed> {
        const result = await API.request('digital_endorsements/preview_endorsement_direct', {
            endorsement_id: request.endorsementId,
            endorsement_data: request.endorsementData,
            prorate_id: request.prorateId,
        });
        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return Success(result.value.file_key);
    }

    public async issueEndorsement(
        request: IssueEndorsementRequest,
    ): AsyncResult<string, InvalidArgument | OperationFailed> {
        const result: RequestResult<
            APITypes.DigitalEndorsementsIssueEndorsementDirectResponse,
            APIError
        > = await API.request('digital_endorsements/issue_endorsement_direct', {
            endorsement_id: request.endorsementId,
            endorsement_data: request.endorsementData,
            rate_id: request.rateId,
            prorate_id: request.prorateId,
        });
        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return Success(result.value.file_key);
    }

    public async listEndorsements(
        request: ListRequest,
    ): AsyncResult<EndorsementRecord[], InvalidArgument | OperationFailed> {
        const result = await API.request('digital_endorsements/list_endorsements_direct', {
            policy_id: request.policyId,
        });
        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return Success(
            result.value.endorsements.map((endorsement: APITypes.DigitalEndorsement) => ({
                id: endorsement.id,
                serialNumber: endorsement.serial_number,
                policyId: endorsement.policy_id,
                endorsementType: endorsement.endorsement_type,
                origin: endorsement.origin,
                isPremiumBearing: endorsement.is_premium_bearing,
                data: endorsement.data,
                createdAt: new Date(endorsement.created_at),
                status: endorsement.status as DigitalEndorsementStatusCodeListItem,
                documentFileKey: endorsement.document_file_key,
            })),
        );
    }

    public async getAvailableEndorsements(
        manifestId: UUID,
    ): AsyncResult<AvailableEndorsementsSpecs, InvalidArgument | OperationFailed> {
        const result = await API.request('digital_endorsements/get_available_endorsements', {
            manifest_id: manifestId,
        });
        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return Success(
            result.value.endorsements.map(
                (endorsement) =>
                    ({
                        displayName: endorsement.displayName,
                        tags: endorsement.tags,
                        inputSchema: endorsement.inputSchema,
                        docType: endorsement.docType,
                    } as EndorsementSpec),
            ),
        );
    }
}
