import { inject } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { Organization } from '../entities/Organization';
import { OrganizationRepository } from '../repositories/OrganizationRepository';
import { SessionRepository } from '../repositories/SessionRepository';

export interface GetActiveOrganizationProfileResponse {
    organization: Immutable<EntityProps<Organization>>;
}

export interface GetActiveOrganizationProfile extends UseCase {
    execute(): AsyncResult<
        GetActiveOrganizationProfileResponse,
        UnknownEntity | InvalidArgument | OperationFailed
    >;
}

class GetActiveOrganizationProfileUseCase extends UseCase implements GetActiveOrganizationProfile {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/GetActiveOrganizationProfile');
    /**
     * Constructor for GetActiveOrganizationProfile use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param organizationRepository is user repository used to store user entity with updated properties
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(SessionRepository) private sessionRepository: SessionRepository,
        @inject(OrganizationRepository) private organizationRepository: OrganizationRepository,
    ) {
        super(eventBus);
    }

    public async execute(): AsyncResult<
        GetActiveOrganizationProfileResponse,
        UnknownEntity | InvalidArgument | OperationFailed
    > {
        const activeSessionResult = await this.sessionRepository.getActiveSession();

        if (isErr(activeSessionResult)) {
            return handleOperationFailure(activeSessionResult);
        }

        if (activeSessionResult.value === null || activeSessionResult.value === undefined) {
            return Failure(
                OperationFailed({ message: 'Detected null for value in activeSessionResult' }),
            );
        }

        if (activeSessionResult.value.organizationId === null) {
            return Failure(
                OperationFailed({
                    message: 'Detected null for organizationId in activeSessionResult',
                }),
            );
        }

        const organizationResult = await this.organizationRepository.getOrganization(
            activeSessionResult.value.organizationId,
        );

        if (isErr(organizationResult)) {
            return organizationResult;
        }

        return Success({ organization: organizationResult.value.toDTO() });
    }
}

export const GetActiveOrganizationProfile: UseCaseClass<GetActiveOrganizationProfile> =
    GetActiveOrganizationProfileUseCase;
