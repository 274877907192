import { ContainerModule } from '@embroker/shotwell/core/di';
import type { Module } from '../Module';
import { CertificateRepository } from './repositories/CertificateRepository';
import { APICertificateRepository } from './repositories/CertificateRepository/APICertificateRepository';
import { CertificateTemplateRepository } from './repositories/CertificateTemplateRepository';
import { APICertificateTemplateRepository } from './repositories/CertificateTemplateRepository/APICertificateTemplateRepository';
import { CreateSelfServingCertificate } from './useCases/CreateSelfServingCertificate';
import { GetIssuedCertificates } from './useCases/GetIssuedCertificates';
import { GetSelfServingCertificate } from './useCases/GetSelfServingCertficate';
import { GetShareableCertificateData } from './useCases/GetShareableCertificateData';
import { GetShareableSelfServingCoverageList } from './useCases/GetShareableSelfServingCoverageList';
import { PreviewSelfServingCertificate } from './useCases/PreviewSelfServingCertificate';
import { SendEnterSelfServingFlow } from './useCases/SendEnterSelfSevingFlowEvent';
import { ShareCertificate } from './useCases/ShareCertificate';
import { routes } from './view/routes';

/**
 * The Certificates module.
 */
export const CertificatesModule: Module = {
    routes,
    container: new ContainerModule((bind) => {
        bind<ShareCertificate>(ShareCertificate.type).to(ShareCertificate).inSingletonScope();
        bind<CertificateRepository>(CertificateRepository)
            .to(APICertificateRepository)
            .inSingletonScope();
        bind<CertificateTemplateRepository>(CertificateTemplateRepository)
            .to(APICertificateTemplateRepository)
            .inSingletonScope();
        bind<GetShareableCertificateData>(GetShareableCertificateData.type)
            .to(GetShareableCertificateData)
            .inSingletonScope();
        bind<GetIssuedCertificates>(GetIssuedCertificates.type)
            .to(GetIssuedCertificates)
            .inSingletonScope();
        bind<GetShareableSelfServingCoverageList>(GetShareableSelfServingCoverageList.type)
            .to(GetShareableSelfServingCoverageList)
            .inSingletonScope();
        bind<CreateSelfServingCertificate>(CreateSelfServingCertificate.type)
            .to(CreateSelfServingCertificate)
            .inSingletonScope();
        bind<PreviewSelfServingCertificate>(PreviewSelfServingCertificate.type)
            .to(PreviewSelfServingCertificate)
            .inSingletonScope();
        bind<GetSelfServingCertificate>(GetSelfServingCertificate.type)
            .to(GetSelfServingCertificate)
            .inSingletonScope();
        bind<SendEnterSelfServingFlow>(SendEnterSelfServingFlow.type)
            .to(SendEnterSelfServingFlow)
            .inSingletonScope();
    }),
};
