import { LineOfBusinessCodeListItem } from '@embroker/shotwell-api/enums';
import { container } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Immutable } from '@embroker/shotwell/core/types';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { ColumnLayout, StatusMessage, Text, TextButton } from '@embroker/ui-toolkit/v2';
import { differenceInDays, isAfter, isPast, startOfDay, startOfToday } from 'date-fns';
import React, { ReactNode, useState } from 'react';
import { useEnumGroup } from '../../../../serverEnums/view/hooks/useEnumGroup';
import { Link } from '../../../../view/components';
import { Policy } from '../../../entities/Policy';

interface StatusBannerProps {
    policy: Immutable<EntityProps<Policy>>;
    renewalStartYear?: number;
    onSeeRenewalClicked?: Function;
    isPolicyForRenew: boolean;
}

export const StatusBanner = ({
    policy,
    renewalStartYear,
    onSeeRenewalClicked,
    isPolicyForRenew,
}: StatusBannerProps) => {
    const logger = container.get<Logger>(Log);
    const MAXIMUM_EXPIRATION_DAYS_VISIBLE = 120;
    const policyEndDate = policy.endDate;
    const policyCancellationDate = policy.cancellationDate ? policy.cancellationDate : undefined;

    const { isLoading: isLoadingLOBEnums, result: lineOfBusinessCodeList } =
        useEnumGroup('LineOfBusinessCodeList');
    const [notificationClosed, setNotificationClosed] = useState(false);

    const currentDate = new Date(startOfToday());
    const daysBeforeExpiration = differenceInDays(policyEndDate, currentDate);

    const contactLinkProps = {
        children: getNavigationText({
            renewalStartYear,
            isPolicyCanceled: !!policyCancellationDate,
            policyEndDate: policyEndDate,
            daysBeforeExpiration,
            maximumDaysBeforeExpirationToShow: MAXIMUM_EXPIRATION_DAYS_VISIBLE,
            isPolicyForRenew: isPolicyForRenew,
        }),
    };

    const contactLink =
        renewalStartYear && onSeeRenewalClicked ? (
            <TextButton onClick={() => onSeeRenewalClicked()} {...contactLinkProps} />
        ) : (
            <Link href="/support/contact" {...contactLinkProps} />
        );

    if (isLoadingLOBEnums) {
        return null;
    } else if (!lineOfBusinessCodeList) {
        logger.error('Unable to display status banner because "lineOfBusinessCodeList" is missing');
        return null;
    }

    if (notificationClosed) {
        return null;
    }

    const statusComponent = getStatusTextComponent({
        lineOfBusinessCodeList,
        renewalStartYear,
        policyCancellationDate: policyCancellationDate,
        contactLink,
        lineOfBusiness: policy.lineOfBusiness,
        policyEndDate: policyEndDate,
        daysBeforeExpiration,
        maximumDaysBeforeExpirationToShow: MAXIMUM_EXPIRATION_DAYS_VISIBLE,
        policyInsurerName: policy.insurerName,
    });

    if (statusComponent === null) {
        return null;
    }

    return (
        <StatusMessage
            status={renewalStartYear ? 'warning' : 'error'}
            onDismiss={renewalStartYear ? () => setNotificationClosed(true) : undefined}
        >
            <ColumnLayout gap="8" responsive={{ containerWidth: { smallerThan: 'tablet' } }}>
                {statusComponent}
            </ColumnLayout>
        </StatusMessage>
    );
};

interface getNavigationTextProps {
    renewalStartYear?: number;
    isPolicyCanceled: boolean;
    policyEndDate: Date;
    daysBeforeExpiration: number;
    maximumDaysBeforeExpirationToShow: number;
    isPolicyForRenew: boolean;
}

function getNavigationText({
    renewalStartYear,
    isPolicyCanceled,
    policyEndDate,
    daysBeforeExpiration,
    maximumDaysBeforeExpirationToShow,
    isPolicyForRenew,
}: getNavigationTextProps): string | undefined {
    if (renewalStartYear) {
        return `View ${renewalStartYear} coverage renewal terms`;
    }
    if (isPolicyCanceled) {
        return 'Please contact us to reinstate.';
    }
    if (isPast(policyEndDate) && isPolicyForRenew) {
        return 'Please contact us to renew.';
    }
    if (daysBeforeExpiration > 0 && daysBeforeExpiration <= maximumDaysBeforeExpirationToShow) {
        return 'Please contact us to renew.';
    }
}

interface getStatusTextComponentProps {
    lineOfBusinessCodeList: Map<string, string>;
    renewalStartYear?: number;
    policyCancellationDate?: Date;
    policyEndDate: Date;
    policyInsurerName: string;
    contactLink: ReactNode;
    lineOfBusiness: LineOfBusinessCodeListItem;
    maximumDaysBeforeExpirationToShow: number;
    daysBeforeExpiration: number;
}

function getStatusTextComponent({
    lineOfBusinessCodeList,
    renewalStartYear,
    policyCancellationDate,
    contactLink,
    lineOfBusiness,
    policyEndDate,
    daysBeforeExpiration,
    maximumDaysBeforeExpirationToShow,
    policyInsurerName,
}: getStatusTextComponentProps): JSX.Element | null {
    if (renewalStartYear) {
        return (
            <Text>
                This {lineOfBusinessCodeList.get(lineOfBusiness)} policy expires soon. We have your
                renewal policy ready. {contactLink}
            </Text>
        );
    }
    if (policyCancellationDate) {
        const currentDate = new Date(startOfToday());
        let policyCancelledText = 'This policy has been cancelled on';
        if (isAfter(startOfDay(policyCancellationDate), currentDate)) {
            policyCancelledText = 'This policy will be cancelled on';
        }
        return (
            <Text>
                {`${policyCancelledText} `}
                {<DateDisplay value={policyCancellationDate} />}. {contactLink}
            </Text>
        );
    }
    if (isPast(policyEndDate)) {
        return (
            <Text>
                This policy has expired and your business is no longer covered by{' '}
                {policyInsurerName}. {contactLink}
            </Text>
        );
    }
    if (daysBeforeExpiration > 0 && daysBeforeExpiration <= maximumDaysBeforeExpirationToShow) {
        return (
            <Text>
                This policy is expiring in {daysBeforeExpiration} days.{' '}
                {lineOfBusinessCodeList.get(lineOfBusiness) === 'ESP' ? (
                    <TextButton as={Link} href="/summary">
                        Please click here to renew.
                    </TextButton>
                ) : (
                    contactLink
                )}
            </Text>
        );
    }

    return null;
}
