import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { PolicyRepository } from '../repositories/PolicyRepository';
import { AppTypeCode, AppTypeCodeList } from '@app/shopping/types/enums';

export interface GetPolicyRenewalStatusRequest {
    appType: AppTypeCode;
    renewedPolicyIdList: UUID[];
}

export const GetPolicyRenewalStatusRequest = {
    ...defineValidator<GetPolicyRenewalStatusRequest>({
        appType: Joi.string().valid(...AppTypeCodeList),
        renewedPolicyIdList: Joi.array(),
    }),
    create(getPolicyRequest: GetPolicyRenewalStatusRequest) {
        return GetPolicyRenewalStatusRequest.validate(getPolicyRequest);
    },
};

export interface getPolicyRenewalStatusResponse {
    status: string;
}

export interface GetPolicyRenewalStatus extends UseCase {
    execute(
        request: GetPolicyRenewalStatusRequest,
    ): AsyncResult<
        getPolicyRenewalStatusResponse,
        UnknownEntity | InvalidArgument | OperationFailed
    >;
}

@injectable()
export class GetPolicyRenewalStatusUseCase extends UseCase {
    public static type = Symbol('Policy/GetPolicyRenewalStatus');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(PolicyRepository) private policyRepository: PolicyRepository,
    ) {
        super(eventBus);
    }

    async execute(
        request: GetPolicyRenewalStatusRequest,
    ): AsyncResult<
        getPolicyRenewalStatusResponse,
        UnknownEntity | InvalidArgument | OperationFailed
    > {
        if (
            (request.appType === 'AppTypeCodeListBOPChubb' ||
                request.appType === 'AppTypeCodeListWCChubb') &&
            request.renewedPolicyIdList.length > 0
        ) {
            const policyId = request.renewedPolicyIdList[0];
            const getPolicyResult = await this.policyRepository.getPolicyRenewalStatus(policyId);

            if (isErr(getPolicyResult)) {
                return getPolicyResult;
            }

            const response: getPolicyRenewalStatusResponse = {
                status: getPolicyResult.value,
            };
            return Success(response);
        }

        return Success({
            status: '',
        });
    }
}

export const GetPolicyRenewalStatus: UseCaseClass<GetPolicyRenewalStatus> =
    GetPolicyRenewalStatusUseCase;
