import {
    StackLayout,
    Form,
    ColumnLayout,
    TextButton,
    Text,
    SelectInputAsync,
    SelectOptionAsyncType,
} from '@embroker/ui-toolkit/v2';
import { CurrencyInput } from '@embroker/shotwell/view/components/CurrencyInput';
import React, { useState, useEffect } from 'react';

import { Money, USD } from '@embroker/shotwell/core/types/Money';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { API as APIv2 } from '@embroker/shotwell-api/v2/app';
import { State } from '@embroker/shotwell/core/types/StateList';
import { isErr } from '@embroker/shotwell/core/types/Result';

interface WorkerTypeProps {
    /**
     * US state for which this list of workers applies to.
     */
    state?: State;

    /**
     * Location ID for which this list of workers applies to.
     */
    readonly locationId?: UUID;

    /**
     * Form Engine errors to forward to the components.
     * This is used to show the 'This field is required' error message
     * when a user tries to continue without filling out all the fields.
     *
     * Note: This is an array of 'Records'. This is because the underlying form
     * can have multiple instances of 'Worker Types' when the user clicks '+ Add Another Type'.
     */
    errors?: Record<string, string[]>[];

    /**
     * onChange method provided by the form engine.
     * Calling this method will save this form's data to the questionnaire data
     * for the current application.
     * @param newData
     * @returns
     */
    onChange: (newData: WorkerTypeFormData[]) => void;

    /**
     * Worker types to initialize the form with.
     * This is sometimes driven directly from spec files to pre-populate the form
     * with data from the cached questionnaire.
     */
    worker_types: WorkerTypeFormData[];
}

export const WorkerTypes = React.forwardRef(function WorkerTypes(
    { state, worker_types, errors, ...props }: WorkerTypeProps,
    ref?: React.Ref<HTMLDivElement>,
) {
    const onFormUpdate = (idxToUpdate: number) => (newData: WorkerTypeFormData) => {
        const nextForms: WorkerTypeFormData[] = [...formData];
        nextForms[idxToUpdate] = { ...newData };

        setTotalPayroll(calculateTotalPayroll(nextForms));
        updateForms(nextForms);
        props.onChange(nextForms);
    };

    const onAddWorkerType = () => {
        const nextForms: WorkerTypeFormData[] = [...formData];

        nextForms.push({});

        updateForms(nextForms);
        props.onChange(nextForms);
    };

    const onFormRemove = (idxToRemove: number) => () => {
        const nextForms: WorkerTypeFormData[] = formData.filter((_, idx) => idx !== idxToRemove);

        setTotalPayroll(calculateTotalPayroll(nextForms));
        updateForms(nextForms);
        props.onChange(nextForms);
    };

    const calculateTotalPayroll = (nextForms: WorkerTypeFormData[]): Money => {
        let totalPayroll = 0;

        nextForms.forEach((form) => {
            totalPayroll += form.payroll?.amount || 0;
        });

        return USD(totalPayroll);
    };

    const [formData, updateForms] = useState<WorkerTypeFormData[]>(worker_types);
    const [totalPayroll, setTotalPayroll] = useState<Money>(calculateTotalPayroll(worker_types));

    useEffect(() => {
        // Add this validation to prevent a user to have 0 worker_types
        if (worker_types.length > 0) {
            updateForms([...worker_types]);
            setTotalPayroll(calculateTotalPayroll([...worker_types]));
        }
    }, [worker_types]);

    return (
        <StackLayout ref={ref}>
            <div className="o-stack-layout--gap-12">
                {formData.map((form, idx) => (
                    <WorkerTypeForm
                        state={state}
                        key={idx}
                        showRemove={formData.length > 1}
                        data={form}
                        errors={errors ? errors[idx] : {}}
                        onFormRemove={onFormRemove(idx)}
                        onFormUpdate={onFormUpdate(idx)}
                    ></WorkerTypeForm>
                ))}
            </div>
            <div className="em-table-button-row">
                <ColumnLayout>
                    <Text>{`Total payroll: `}</Text>&nbsp;
                    {totalPayroll?.amount === 0 ? (
                        '-'
                    ) : (
                        <MoneyDisplay value={totalPayroll || USD(0)}></MoneyDisplay>
                    )}
                </ColumnLayout>
                <div className="em-table-button-row">
                    <TextButton data-e2e="add-another-worker-type" onClick={onAddWorkerType}>
                        + Add another type
                    </TextButton>
                </div>
            </div>
        </StackLayout>
    );
});

interface WorkerClass {
    class_code?: string;
    description_id?: string;
    text?: string;
}

export interface WorkerTypeFormData {
    payroll?: Money;
    full_time_count?: number;
    part_time_count?: number;
    worker_class?: WorkerClass;
}

interface WorkerTypeFormProps {
    data: WorkerTypeFormData;
    state?: State;
    showRemove: boolean;

    errors?: Record<string, string[]>;

    onFormUpdate: (newData: WorkerTypeFormData) => void;
    onFormRemove: () => void;
}

const fullPartTimeCountError = 'At least one full- or part- time worker is required';

const WorkerTypeForm = ({
    data,
    state,
    showRemove,
    errors,
    onFormUpdate,
    onFormRemove,
}: WorkerTypeFormProps) => {
    const [workerClassErrors, setWorkerClassErrors] = useState<string[]>([]);
    const [fullTimeCountErrors, setFullTimeCountErrors] = useState<string[]>([]);
    const [partTimeCountErrors, setPartTimeCountErrors] = useState<string[]>([]);
    const [payrollErrors, setPayrollErrors] = useState<string[]>([]);
    const [currentWorkerClass, setCurrentWorkerClass] = useState<string | undefined>(
        data.worker_class
            ? `${data.worker_class.class_code}-${data.worker_class.description_id}`
            : undefined,
    );
    const [classCodeOptions, setClassCodeOptions] = useState<SelectOptionAsyncType[] | undefined>(
        data.worker_class
            ? [
                  {
                      label: data.worker_class.text ?? '',
                      value: `${data.worker_class.class_code}-${data.worker_class.description_id}`,
                  },
              ]
            : undefined,
    );

    useEffect(() => {
        if (errors?.worker_class?.length && errors?.worker_class?.length > 0) {
            setWorkerClassErrors(errors.worker_class);
        } else {
            setWorkerClassErrors([]);
        }

        if (errors?.full_time_count?.length && errors?.full_time_count?.length > 0) {
            setFullTimeCountErrors([fullPartTimeCountError]);
        } else {
            setFullTimeCountErrors([]);
        }

        if (errors?.part_time_count?.length && errors?.part_time_count?.length > 0) {
            setPartTimeCountErrors([fullPartTimeCountError]);
        } else {
            setPartTimeCountErrors([]);
        }

        if (errors?.payroll?.length && errors?.payroll?.length > 0) {
            setPayrollErrors(errors.payroll);
        } else {
            setPayrollErrors([]);
        }
    }, [errors]);

    const searchNcciCodes = async (selectInputText: string) => {
        if (state == undefined) {
            // TODO
            return Promise.resolve([]);
        }

        const response = await APIv2.get(`ncci?state=${state}&query=${selectInputText}`);
        if (isErr(response)) {
            return Promise.resolve([]);
        }

        const options: SelectOptionAsyncType[] = [];
        for (const ncciItem of response.value.classList ?? []) {
            options.push({
                label: ncciItem.classCodeDescription,
                value: ncciItem.classCode + '-' + ncciItem.descriptionId,
            });
        }

        return Promise.resolve(options);
    };

    const onClassCodeSelection = (newValue: SelectOptionAsyncType) => {
        let result: WorkerClass | undefined = undefined;
        if (newValue != null) {
            const [classCode, descriptionId] = newValue.value.split('-');
            result = {
                class_code: classCode,
                description_id: descriptionId,
                text: newValue.label,
            };
            setClassCodeOptions([newValue]);
        }

        setWorkerClassErrors([]);

        onFormUpdate({
            ...data,
            worker_class: result,
        });
    };

    useEffect(() => {
        setCurrentWorkerClass(
            data.worker_class
                ? `${data.worker_class.class_code}-${data.worker_class.description_id}`
                : '',
        );
        setClassCodeOptions(
            data.worker_class
                ? [
                      {
                          label: data.worker_class.text ?? '',
                          value: `${data.worker_class.class_code}-${data.worker_class.description_id}`,
                      },
                  ]
                : [{ label: '', value: '' }],
        );
    }, [data.worker_class]);

    return (
        <div className="o-stack-layout--gap-12">
            <StackLayout className="em-table-row">
                <Form.Field messages={workerClassErrors}>
                    <SelectInputAsync
                        data-e2e="worker-class"
                        value={currentWorkerClass}
                        request={searchNcciCodes}
                        maxMenuHeight={100}
                        label={'Employment type/category'}
                        onChange={onClassCodeSelection}
                        hasErrors={workerClassErrors.length > 0}
                        options={classCodeOptions}
                    ></SelectInputAsync>
                </Form.Field>
                <ColumnLayout>
                    <Form.Field
                        messages={fullTimeCountErrors}
                        data-e2e="full_time_count"
                        label="Full time"
                        type="number"
                        inputProps={{
                            onChange: (event) => {
                                let result: number | undefined = undefined;
                                if (event.target.value) {
                                    result = Number.parseInt(event.target.value);
                                }

                                setFullTimeCountErrors([]);

                                onFormUpdate({
                                    ...data,
                                    full_time_count: result,
                                });
                            },
                            value: data.full_time_count,
                        }}
                    ></Form.Field>
                    <Form.Field
                        messages={partTimeCountErrors}
                        data-e2e="part_time_count"
                        label="Part time"
                        type="number"
                        inputProps={{
                            onChange: (event) => {
                                let result: number | undefined = undefined;
                                if (event.target.value) {
                                    result = Number.parseInt(event.target.value);
                                }

                                setPartTimeCountErrors([]);

                                onFormUpdate({
                                    ...data,
                                    part_time_count: result,
                                });
                            },
                            value: data.part_time_count,
                        }}
                    ></Form.Field>
                    <Form.Field messages={payrollErrors}>
                        <CurrencyInput
                            hasErrors={payrollErrors.length > 0}
                            onSubmit={(event) => {
                                console.log(event);
                            }}
                            data-e2e="payroll"
                            label="Payroll"
                            value={data.payroll}
                            onChange={(newValue) => {
                                let result: Money | undefined = undefined;

                                if (
                                    newValue.target.value?.amount !== undefined &&
                                    !isNaN(newValue.target.value.amount)
                                ) {
                                    result = newValue.target.value;
                                }

                                setPayrollErrors([]);

                                onFormUpdate({
                                    ...data,
                                    payroll: result,
                                });
                            }}
                        ></CurrencyInput>
                    </Form.Field>
                </ColumnLayout>
                {showRemove && (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                        <TextButton onClick={onFormRemove}>Remove</TextButton>
                    </div>
                )}
            </StackLayout>
        </div>
    );
};
