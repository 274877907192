import { StackLayout, InsetBox, ComparisonTable, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';

export const ESPDoDifferenceModal = function ESPDoDifferenceModal() {
    return (
        <StackLayout>
            <Text style="heading 3">What is “D&amp;O Plus” Coverage?</Text>
            <Text>Cover an even broader range of risk with best-in-market coverage.</Text>
            <InsetBox>
                <ComparisonTable
                    comparisonData={[
                        {
                            topic: (
                                <React.Fragment>
                                    <Text style="heading 4">Exclusions</Text>
                                    <Text style="body 2">
                                        Every D&amp;O insurance policy has several very specific
                                        exclusions that outline instances in which coverage is not
                                        available. Some of these exclusions can be extremely broad,
                                        significantly limiting your coverage when a claim occurs.
                                        With our D&O Plus product, we limit the breadth of most
                                        common exclusions, including but not limited to the
                                        Intellectual Property, Pollution, Contractual Liability, and
                                        Insured vs. Insured exclusions.
                                    </Text>
                                </React.Fragment>
                            ),
                            plus: true,
                            standard: false,
                        },
                        {
                            topic: (
                                <React.Fragment>
                                    <Text style="heading 4">Expanded Outside Capacity </Text>
                                    <Text style="body 2">
                                        Most D&amp;O policies provide some coverage for team members
                                        when they are instructed by the Company to join boards of
                                        Non-Profit Organizations. This is called “outside entity” or
                                        “outside capacity” coverage. Our Plus policy takes it a step
                                        further giving coverage for individual team members, not
                                        only for Non-Profit Organizations, but also for other
                                        For-Profit entities.
                                    </Text>
                                </React.Fragment>
                            ),
                            plus: true,
                            standard: false,
                        },
                        {
                            topic: (
                                <React.Fragment>
                                    <Text style="heading 4">Pre-Claim Costs:</Text>
                                    <Text style="body 2">
                                        Pre-claim coverage in D&amp;O insurance will provide
                                        protection for the costs associated with “informal
                                        inquiries” that are often conducted by administrative or
                                        regulatory agencies before a claim is filed. Also called
                                        “lookback coverage,” pre-claims coverage will reimburse
                                        legal expenses and other costs incurred prior to a formal
                                        investigation or claim. Our D&O Plus coverage offers
                                        full-limit pre-claim costs coverage subject to the terms and
                                        conditions of the policy.
                                    </Text>
                                </React.Fragment>
                            ),
                            plus: true,
                            standard: false,
                        },
                    ]}
                />
            </InsetBox>
        </StackLayout>
    );
};
