import { ContainerModule } from '@embroker/shotwell/core/di';
import { Module } from '@embroker/shotwell/core/Module';
import { AppContext } from '../../view/AppContext';
import { QuoteRepository } from './repositories/QuoteRepository';
import { APIQuoteRepository } from './repositories/QuoteRepository/APIQuoteRepository';
import { GetQuote } from './useCases/GetQuote';
import { DownloadESPRenewalQuoteDocument } from './useCases/DownloadESPRenewalQuoteDocument';
import { PurchaseESPRenewal } from './useCases/PurchaseESPRenewal';
import { UpdateQuote } from './useCases/UpdateQuote';
import { GetRestriction } from './useCases/GetRestriction';
import { APIInsuranceApplicationRestrictionRepository } from './repositories/InsuranceApplicationRestrictionRepository/APIInsuranceApplicationRestrictionRepository';
import { InsuranceApplicationRestrictionRepository } from './repositories/InsuranceApplicationRestrictionRepository';

export const ESPRenewalQuoteModule: Module<AppContext> = {
    container: new ContainerModule((bind) => {
        bind<QuoteRepository>(QuoteRepository).to(APIQuoteRepository).inSingletonScope();
        bind<GetQuote>(GetQuote.type).to(GetQuote).inSingletonScope();
        bind<PurchaseESPRenewal>(PurchaseESPRenewal.type).to(PurchaseESPRenewal).inSingletonScope();
        bind<UpdateQuote>(UpdateQuote.type).to(UpdateQuote).inSingletonScope();
        bind<DownloadESPRenewalQuoteDocument>(DownloadESPRenewalQuoteDocument.type)
            .to(DownloadESPRenewalQuoteDocument)
            .inSingletonScope();
        bind<GetRestriction>(GetRestriction.type).to(GetRestriction).inSingletonScope();
        bind<InsuranceApplicationRestrictionRepository>(
            APIInsuranceApplicationRestrictionRepository,
        )
            .to(APIInsuranceApplicationRestrictionRepository)
            .inSingletonScope();
    }),
};
