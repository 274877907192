import { Success } from '@embroker/shotwell/core/types/Result';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    Button,
    ButtonBar,
    Form,
    FormLayout,
    SidebarLayout,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React, { useMemo } from 'react';
import { CustomizeCertificateStepGuidelines } from './CustomizeCertificateStepGuidelines';

export interface CustomizeCertificateStepData {
    isCustomized?: boolean;
}

function createCustomizeCertificateStepForm(
    onCustomizeCertificate: (data: CustomizeCertificateStepData) => void,
    onIssueCertificate: (data: CustomizeCertificateStepData) => void,
) {
    return createForm<CustomizeCertificateStepData>({
        fields: {
            isCustomized: {
                type: 'radioGroup',
                validator: Joi.boolean().required().valid(true, false),
                formatValidationError() {
                    return 'Please specify if you want to customise the certificate.';
                },
            },
        },
        async submit(data) {
            data.isCustomized ? onCustomizeCertificate(data) : onIssueCertificate(data);
            return Success();
        },
    });
}

interface CustomizeCertificateStepProps {
    initialData?: Partial<CustomizeCertificateStepData>;
    onCustomizeCertificate(data: CustomizeCertificateStepData): void;
    onIssueCertificate(data: CustomizeCertificateStepData): void;
    onPreviewCertificate(data: CustomizeCertificateStepData): void;
    onBack(): void;
}

export function CustomizeCertificateStep({
    initialData,
    onCustomizeCertificate,
    onIssueCertificate,
    onPreviewCertificate,
    onBack,
}: CustomizeCertificateStepProps) {
    const customizeCertificateStepForm = useMemo(
        () => createCustomizeCertificateStepForm(onCustomizeCertificate, onIssueCertificate),
        [onCustomizeCertificate, onIssueCertificate],
    );
    const { fields, submit, value } = useForm(customizeCertificateStepForm, initialData);

    function calcIssueButtonName(): string {
        if (value.isCustomized === true) {
            return 'Customize Your Certificate';
        }
        if (value.isCustomized === false) {
            return 'Issue the Certificate';
        }
        return 'Next';
    }

    const split = value.isCustomized === false ? '-2' : '-1';

    return (
        <SidebarLayout appearance="default" sidebarVariant="compact">
            <CustomizeCertificateStepGuidelines />
            <StackLayout>
                <FormLayout appearance="wide">
                    <StackLayout gap="48">
                        <StackLayout gap="24">
                            <Text style={'heading 5'} data-e2e="customize-cert-questionnaire">
                                We have defaulted to include all your Embroker polices on the
                                certificate. Do you want to select specific policies to appear on
                                your certificate?
                            </Text>
                            <Form>
                                <StackLayout>
                                    <Form.Field
                                        direction="horizontal"
                                        inputProps={{
                                            ...fields.isCustomized.props,
                                            items: [
                                                {
                                                    value: true,
                                                    title: 'Yes, I’d like to pick and choose policies',
                                                },
                                                {
                                                    value: false,
                                                    title: 'No, I want all my policies on the certificate',
                                                },
                                            ],
                                        }}
                                        messages={fields.isCustomized.messages}
                                        type={fields.isCustomized.type}
                                        data-e2e="customize-certificate-wrapper"
                                    />
                                </StackLayout>
                            </Form>
                        </StackLayout>
                        <ButtonBar
                            split={split}
                            reverse
                            responsive={{ containerWidth: { smallerThan: 400 } }}
                        >
                            <TextButton onClick={onBack} data-e2e="customize-cert-back">
                                Back
                            </TextButton>
                            {value.isCustomized === false ? (
                                <Button
                                    data-e2e="customize-cert-preview"
                                    appearance="secondary"
                                    onClick={() => {
                                        onPreviewCertificate(value);
                                    }}
                                >
                                    Preview the Certificate
                                </Button>
                            ) : null}
                            <Button onClick={submit} data-e2e="customize-cert-submit">
                                {calcIssueButtonName()}
                            </Button>
                        </ButtonBar>
                    </StackLayout>
                </FormLayout>
                <SidebarLayout.MobileFooter split="-1">
                    <Text>What does this mean?</Text>
                    <SidebarLayout.Link appearance="secondary" panelIndex={1}>
                        Let us explain
                    </SidebarLayout.Link>
                </SidebarLayout.MobileFooter>
            </StackLayout>
        </SidebarLayout>
    );
}
