import React, { useEffect, useState } from 'react';
import {
    ColumnLayout,
    StackLayout,
    Text,
    Form,
    TextButton,
    Button,
    Spinner,
    TextInput,
    CheckBox,
    useModal,
    UploadFile,
    UploadFileState,
    StatusMessage,
    UploadFileCard,
    usePrevious,
} from '@embroker/ui-toolkit/v2';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import { CreateClaimRequest, ClaimRequestDocument } from '../../useCases/CreateClaimRequest';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { execute } from '@embroker/shotwell/core/UseCase';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { GetPolicy } from '../../../policy/useCases/GetPolicy';
import { isErr, isOK, Success } from '@embroker/shotwell/core/types/Result';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { ClaimRequestThankYouModal } from './ClaimRequestThankYouModal';
import { ClaimIntakeModal } from './ClaimIntakeModal';
import { FileUpload } from '../../../documents/view/FileUpload';
import { UploadFiles, UploadFilesRequest } from '../../../documents/useCases/UploadFiles';
import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { DeleteFile, DeleteFileRequest } from '../../../documents/useCases/DeleteFile';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Policy } from '../../../policy/entities/Policy';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { PhoneNumber } from '@embroker/shotwell/core/types/PhoneNumber';

export interface ClaimRequestFormData {
    submitterName: string;
    contactPersonName: string;
    contactPersonEmail: string;
    contactPersonPhone: string;
    description: string;
    acceptedTerms: boolean;
}

const ClaimRequestForm = createForm<ClaimRequestFormData>({
    formatSubmitErrors(errors) {
        if (errors.length > 0) {
            return ['Oops! Something went wrong. Please try again later.'];
        }

        return [];
    },
    fields: {
        submitterName: {
            type: 'text',
            validator: Joi.string().required(),
            formatValidationError() {
                return 'This field is required.';
            },
        },
        contactPersonName: {
            type: 'text',
            validator: Joi.string().required(),
            formatValidationError() {
                return 'This field is required.';
            },
        },
        contactPersonEmail: {
            type: 'email',
            validator: Joi.string().email({ tlds: false }).required(),
            formatValidationError(error) {
                switch (error.details.validator) {
                    case 'any.required':
                    case 'string.email':
                        return 'You must enter a valid email address';
                    default:
                        return error.message;
                }
            },
        },
        contactPersonPhone: {
            type: 'tel',
            validator: PhoneNumber.schema.required(),
            formatValidationError() {
                return 'This field is required.';
            },
        },
        description: {
            type: 'textarea',
            validator: Joi.string().required(),
            formatValidationError() {
                return 'This field is required.';
            },
        },
        acceptedTerms: {
            type: 'checkbox',
            validator: Joi.boolean().valid(true),
            formatValidationError() {
                return 'Please accept terms and conditions.';
            },
        },
    },
    submit: async () => {
        return Success();
    },
});

export interface ClaimRequestProps {
    policyId: UUID;
}

export const ClaimRequest = (props: ClaimRequestProps) => {
    const { navigate } = useNavigation();
    const [dropBoxContainerState, setDropBoxContainerState] = useState<UploadFileState>('default');
    const timeErrorMessageShouldBeDisplayed = 3000;
    const [uploadPercent, setUploadPercent] = useState(0);
    const [uploadFiles, setUploadFiles] = useState<FileUpload[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>();
    const { fields, value, setValue, submit, status } = useForm(ClaimRequestForm, {
        acceptedTerms: false,
    } as Partial<ClaimRequestFormData>);

    const previousStatus = usePrevious(status);

    const thankYouModal = useModal();
    const claimIntakeModal = useModal();

    const policyId = props.policyId;

    const { result: policyResult } = useUseCase(GetPolicy, {
        policyId: policyId,
    });

    const { show: showThankYouModal } = thankYouModal;
    const { show: showClaimIntakeModal } = claimIntakeModal;

    useEffect(() => {
        if (previousStatus != status && status === 'submitted') {
            execute(CreateClaimRequest, {
                policyId: policyId,
                submitterName: value.submitterName,
                contactPersonName: value.contactPersonName,
                contactPersonEmail: value.contactPersonEmail,
                contactPersonPhoneNumber: value.contactPersonPhone,
                description: value.description,
                documentList: uploadFiles.map((fileUpload) => {
                    return {
                        name: fileUpload.file.name,
                        file_key: fileUpload.s3FileKey,
                    } as ClaimRequestDocument;
                }),
            }).then((submitResult) => {
                if (isOK(submitResult)) {
                    showThankYouModal();
                }
                if (isErr(submitResult)) {
                    setErrorMessage(submitResult.errors[0].message);
                }
            });
        }
    }, [status, previousStatus, value, uploadFiles, policyId, showThankYouModal]);

    if (policyResult === undefined) {
        return <Spinner />;
    }

    if (isErr(policyResult)) {
        return <ErrorPage errors={policyResult.errors} />;
    }

    const policyDetails = policyResult.value.policy;

    const allowedClaimLinesOfBusiness = [
        'LineOfBusinessCodeListESP',
        'LineOfBusinessCodeListPCoML',
        'LineOfBusinessCodeListCyber',
    ];

    const checkClaimAllowed = (policy: Immutable<EntityProps<Policy>>) => {
        return (
            policy.insuranceApplicationId &&
            (allowedClaimLinesOfBusiness.includes(policy.lineOfBusiness) ||
                policy.subLineOfBusiness === 'LineOfBusinessSubtypeCodeListCrimeDigital')
        );
    };

    function onUploaded(newFiles: FileUpload[]) {
        newFiles.forEach((fileUpload) => {
            uploadFiles.push(fileUpload);
        });
        setUploadFiles(uploadFiles);
    }

    const errorMessageDisplayTime = 1800;

    const uploadFile = (file: FileUpload) => {
        const files = [file];
        execute(UploadFiles, {
            files: files.map((item) => item.file),
            onFileUploadProgress: (uploaded, totalSize) => {
                setUploadPercent((uploaded / totalSize) * 100);
            },
        } as UploadFilesRequest).then((result) => {
            setUploadPercent(100);

            if (isOK(result)) {
                for (let i = 0; i < files.length; i++) {
                    files[i].s3FileKey = result.value.uploadedFiles[i].fileKey;
                }
            } else {
                container.get<Logger>(Log).error(result.errors);
            }
            onUploaded(files);

            setTimeout(() => {
                setUploadPercent(0);
            }, errorMessageDisplayTime);
        });
    };

    const handleAddFile = (files: File[]) => {
        const addedFile = files[0];

        if (!isFilePDF(addedFile)) {
            setDropBoxContainerState('fileTypeError');
            setTimeout(() => {
                setDropBoxContainerState('default');
            }, timeErrorMessageShouldBeDisplayed);
            return;
        }

        const fileToUpload: FileUpload = {
            id: UUID.create(),
            file: addedFile,
            s3FileKey: null,
        };

        uploadFile(fileToUpload);
    };

    const isFilePDF = (file: File) => {
        return file.type === 'application/pdf';
    };

    const handleFileRemove = (s3FileKey: Nullable<string>) => {
        execute(DeleteFile, {
            fileKey: s3FileKey,
        } as DeleteFileRequest).then((result) => {
            if (isOK(result)) {
                const filteredList = uploadFiles.filter((fileUpload) => {
                    return fileUpload.s3FileKey !== s3FileKey;
                });
                setUploadFiles(filteredList);
            }
        });
    };

    return (
        <React.Fragment>
            {errorMessage ? <StatusMessage status="error">{errorMessage}</StatusMessage> : null}
            {checkClaimAllowed(policyDetails) ? (
                <React.Fragment>
                    <ColumnLayout>
                        <StackLayout className="u-1/4">
                            <TextButton
                                onClick={() => {
                                    navigate('/claims/filing-list');
                                }}
                            >
                                Back to Claims
                            </TextButton>
                        </StackLayout>
                        <StackLayout className="u-1/2">
                            <Text>
                                <Text style="heading 3">File a Claim</Text>
                            </Text>
                            <Text>POLICY: {policyDetails.name}</Text>
                            <Text>CARRIER: {policyDetails.insurerName} </Text>
                            <TextInput
                                label="Insured name"
                                id="name"
                                value={policyDetails.insuredPartiesList[0].name}
                                readOnly
                                tooltip={
                                    "We've pre-filled this based on our current records. If updates are needed, please contact us at endorsements@embroker.com."
                                }
                            />
                            <TextInput
                                label="Policy number"
                                id="name"
                                value={policyDetails.policyNumber}
                                readOnly
                                tooltip={
                                    "We've pre-filled the policy you selected in the previous screen. If you think that more than one policy could be applicable, please let us know in the Brief Description section below."
                                }
                            />
                            <Form.Field
                                className="u-grid-size-6"
                                inputProps={{
                                    ...fields.submitterName.props,
                                }}
                                label="Claim Submitter (Full Name)"
                                messages={fields.submitterName.messages}
                                type={fields.submitterName.type}
                                tooltip={'Your first and last names.'}
                            />
                            <Form.Field
                                className="u-grid-size-6"
                                inputProps={{
                                    ...fields.contactPersonName.props,
                                }}
                                label="Claim Contact Person at Your Company (Full Name)"
                                messages={fields.contactPersonName.messages}
                                type={fields.contactPersonName.type}
                                tooltip={
                                    'First and last names of the person who the Claim Professional should contact.'
                                }
                            />
                            <Form.Field
                                className="u-grid-size-6"
                                inputProps={{
                                    ...fields.contactPersonEmail.props,
                                }}
                                label="Claim Contact Person's Email"
                                messages={fields.contactPersonEmail.messages}
                                type={fields.contactPersonEmail.type}
                            />
                            <Form.Field
                                className="u-grid-size-6"
                                inputProps={{
                                    ...fields.contactPersonPhone.props,
                                }}
                                label="Claim Contact Person's Phone Number"
                                messages={fields.contactPersonPhone.messages}
                                type={fields.contactPersonPhone.type}
                            />
                            <Form.Field
                                className="u-grid-size-6"
                                inputProps={{
                                    ...fields.description.props,
                                }}
                                label="Brief Description"
                                messages={fields.description.messages}
                                type={fields.description.type}
                                tooltip={
                                    "Please describe the matter you're submitting, including the name of the claimant, key dates, related entities, circumstances, other parties involved, etc. If you have a copy of correspondence, a lawsuit, or other documentation, please upload it below and refer to it here."
                                }
                            />
                            <StackLayout gap={'16'}>
                                <UploadFile
                                    buttonText="Choose file"
                                    placeholder="or drag file here."
                                    fileTypeFilters={['application/pdf']}
                                    isMultiple={false}
                                    onFilesAdded={handleAddFile}
                                    uploadPercent={uploadPercent}
                                    showButton
                                    onUploadAbort={() => {
                                        //empty
                                    }}
                                    state={dropBoxContainerState}
                                />
                                <ColumnLayout className="u-1/2">
                                    {uploadFiles.length > 0 &&
                                        uploadFiles.map((fileUpload) => {
                                            return (
                                                <UploadFileCard
                                                    key={fileUpload.id}
                                                    file={fileUpload.file}
                                                    onUploadAbort={() =>
                                                        handleFileRemove(fileUpload.s3FileKey)
                                                    }
                                                    state="success"
                                                    uploadPercent={0}
                                                />
                                            );
                                        })}
                                </ColumnLayout>
                                <Text style="microcopy">
                                    Attach relevant documentation in PDF format.
                                </Text>
                            </StackLayout>
                            <Form.Field messages={fields.acceptedTerms.messages}>
                                <CheckBox
                                    onChange={() =>
                                        setValue({
                                            ...value,
                                            acceptedTerms: !value.acceptedTerms,
                                        })
                                    }
                                    checked={value.acceptedTerms}
                                >
                                    <Text>
                                        By submitting this form, you affirm that you have read and
                                        agree to our{' '}
                                        <TextButton onClick={showClaimIntakeModal}>
                                            Fraud Statement(s).
                                        </TextButton>
                                    </Text>
                                </CheckBox>
                            </Form.Field>
                            <Button onClick={submit} type="submit">
                                Send
                            </Button>
                        </StackLayout>
                    </ColumnLayout>
                    <ClaimRequestThankYouModal modal={thankYouModal} />
                    <ClaimIntakeModal modal={claimIntakeModal} />
                </React.Fragment>
            ) : null}
        </React.Fragment>
    );
};
