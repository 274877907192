import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { Money } from '@embroker/shotwell/core/types/Money';
import { PhoneNumber } from '@embroker/shotwell/core/types/PhoneNumber';
import { State } from '@embroker/shotwell/core/types/StateList';
import { URI } from '@embroker/shotwell/core/types/URI';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface BrokerageOrganizationInfo {
    name?: string;
    address?: string;
    state?: State;
    city?: string;
    zip?: ZipCode;
    revenue: Money;
    county?: string;
    industry?: string;
    website?: URI;
    managingBrokerName?: string;
    employeeCount?: number;
    email?: EmailAddress;
    phoneNumber?: PhoneNumber;
}

export const BrokerageOrganizationInfo = {
    ...defineValidator<BrokerageOrganizationInfo>({
        name: Joi.string().optional(),
        address: Joi.string().optional(),
        state: State.schema.optional(),
        city: Joi.string().optional(),
        zip: ZipCode.schema.optional(),
        revenue: Money.schema,
        county: Joi.string().optional(),
        industry: Joi.string().optional(),
        website: Joi.string().optional(),
        managingBrokerName: Joi.string().optional(),
        employeeCount: Joi.number().optional(),
        email: EmailAddress.schema.optional(),
        phoneNumber: PhoneNumber.schema.optional(),
    }),
    create(orgInfo: BrokerageOrganizationInfo) {
        return BrokerageOrganizationInfo.validate(orgInfo);
    },
};
