import { container } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Immutable } from '@embroker/shotwell/core/types';
import { Money, USD } from '@embroker/shotwell/core/types/Money';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { PercentageDisplay } from '@embroker/shotwell/view/components/PercentageDisplay';
import {
    BoxLayout,
    Button,
    CardLayout,
    CenterLayout,
    ColumnLayout,
    InvoiceTable,
    Modal,
    ModalActions,
    ModalState,
    ScrollBox,
    Spinner,
    StackLayout,
    Text,
} from '@embroker/ui-toolkit/v2';
import { addDays, addMonths, isAfter, isSameDay, subDays } from 'date-fns';
import React from 'react';
import { Quote } from '../../../entities/Quote';

export interface QuoteIndicationOptions {
    quote3Months?: Immutable<EntityProps<Quote>>;
    quote10Months?: Immutable<EntityProps<Quote>>;
}

export interface QuoteIndicationModalProps {
    minEffectiveDate: Date;
    quotes?: QuoteIndicationOptions;
    modal: ModalState & ModalActions;
    isNewRequestLoading?: boolean;
}

export function QuoteIndicationModal(props: QuoteIndicationModalProps) {
    const monthlyData = props.quotes?.quote10Months;
    const quarterlyData = props.quotes?.quote3Months;
    const monthlyInstallmentsDiscountPercent = 15;
    const quarterlyInstallmentsDiscountPercent = 30;

    if (
        props.modal.visible &&
        (!props.quotes || !monthlyData || !quarterlyData || props.isNewRequestLoading)
    ) {
        return <Spinner />;
    }

    return (
        <Modal
            appearance="seamless"
            {...props.modal}
            size={!props.quotes || !monthlyData || !quarterlyData ? 'small' : 'large'}
        >
            {props.quotes && monthlyData && quarterlyData ? (
                <ScrollBox>
                    <CardLayout>
                        <CardLayout.Header>
                            <Text style="heading 4">Premium Financing Options Preview</Text>
                        </CardLayout.Header>
                        <CardLayout.Body>
                            <StackLayout>
                                <ColumnLayout
                                    gap="8"
                                    responsive={{ containerWidth: { smallerThan: 500 } }}
                                >
                                    <QuoteIndicationDisplay
                                        data={monthlyData}
                                        discountPercent={monthlyInstallmentsDiscountPercent}
                                        minEffectiveDate={props.minEffectiveDate}
                                        paymentStep="Monthly"
                                    />
                                    <QuoteIndicationDisplay
                                        data={quarterlyData}
                                        discountPercent={quarterlyInstallmentsDiscountPercent}
                                        minEffectiveDate={props.minEffectiveDate}
                                        paymentStep="Quarterly"
                                    />
                                </ColumnLayout>
                                <CenterLayout>
                                    <Button onClick={props.modal.hide}>Close</Button>
                                </CenterLayout>
                            </StackLayout>
                        </CardLayout.Body>
                    </CardLayout>
                </ScrollBox>
            ) : null}
        </Modal>
    );
}

interface QuoteIndicationInput {
    data: Immutable<EntityProps<Quote>>;
    discountPercent: number;
    paymentStep: string;
    minEffectiveDate: Date;
}

function sum(values: Money[]): Money {
    const sumResult = Money.sum(values);
    if (isOK(sumResult)) {
        return sumResult.value;
    }
    container
        .get<Logger>(Log)
        .error('Unexpected error while calculating the sum', sumResult.errors);

    return USD(Number.NaN);
}

export function QuoteIndicationDisplay(props: QuoteIndicationInput) {
    const data = props.data;
    const monthlyStep = props.paymentStep === 'Monthly' ? 1 : 3;
    let virtualDate = props.minEffectiveDate;

    const dueNow = calculateDueNow(data);

    function calculateVirtualDate() {
        virtualDate = addMonths(virtualDate, monthlyStep);
        virtualDate = subDays(virtualDate, 5);

        return virtualDate;
    }

    function calculateDueNow(quote: Immutable<EntityProps<Quote>>): Money {
        let numOfInstallments = 0;
        virtualDate = calculateVirtualDate();

        const today = new Date(Date.now());

        while (isAfter(today, virtualDate) || isSameDay(today, virtualDate)) {
            numOfInstallments++;
            virtualDate = addDays(virtualDate, 5);
            virtualDate = calculateVirtualDate();
        }

        return sum([
            quote.downPayment,
            // TODO: Once that Money.multiply() is implemented, replace this
            // with Money.multiply(quote.installmentAmount, numOfInstallments):
            Money.percentage(quote.installmentAmount, numOfInstallments * 100),
        ]);
    }
    return (
        <CardLayout>
            <BoxLayout gap="24">
                <StackLayout gap="32">
                    <StackLayout center gap="none">
                        <Text style="body 2" color="ui-500">
                            {String(data.numberOfInstallments)} payments of
                        </Text>
                        <Text style="heading 2">
                            <MoneyDisplay value={data.installmentAmount} />
                        </Text>
                        <Text style="body 2" color="ui-500">
                            + <PercentageDisplay value={props.discountPercent} /> down
                        </Text>
                    </StackLayout>
                    <InvoiceTable>
                        <InvoiceTable.Section>
                            <InvoiceTable.Item title="Total annual premiums, taxes and fees">
                                <MoneyDisplay value={data.totalPremium} />
                            </InvoiceTable.Item>
                            <InvoiceTable.Item title="Annual percentage rate">
                                <PercentageDisplay value={data.annualPercentageRate} decimal />
                            </InvoiceTable.Item>
                            <InvoiceTable.Item title="Total loan amount">
                                <MoneyDisplay value={data.totalPaymentAmount} />
                            </InvoiceTable.Item>
                            <InvoiceTable.Item
                                title={`${props.paymentStep} payment (${data.numberOfInstallments} installments)`}
                            >
                                <MoneyDisplay value={data.installmentAmount} />
                            </InvoiceTable.Item>
                            <InvoiceTable.Item
                                title={`Cash down payments (${props.discountPercent}% down)`}
                            >
                                <MoneyDisplay value={data.downPayment} />
                            </InvoiceTable.Item>
                            <InvoiceTable.Item title="Due now">
                                <MoneyDisplay value={dueNow} />
                            </InvoiceTable.Item>
                        </InvoiceTable.Section>
                    </InvoiceTable>
                </StackLayout>
            </BoxLayout>
        </CardLayout>
    );
}
