import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject } from '@embroker/shotwell/core/di';
import { BrokerRepository } from '../repositories';
import { BrokerageGetBrokersResponse } from '@embroker/shotwell-api/app.spec';

export interface GetBrokers extends UseCase {
    execute(): AsyncResult<BrokerageGetBrokersResponse, InvalidArgument | OperationFailed>;
}

class GetBrokersUseCase extends UseCase implements GetBrokers {
    public static type = Symbol('Broker/GetBrokers');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BrokerRepository) private brokerRepository: BrokerRepository,
    ) {
        super(eventBus);
    }

    public async execute(): AsyncResult<
        BrokerageGetBrokersResponse,
        InvalidArgument | OperationFailed
    > {
        const result = await this.brokerRepository.getBrokers();

        if (isErr(result)) {
            return result;
        }

        return Success(result.value);
    }
}

export const GetBrokers: UseCaseClass<GetBrokers> = GetBrokersUseCase;
