import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { Text, useModal } from '@embroker/ui-toolkit/v2';
import { startOfToday } from 'date-fns';
import React from 'react';
import { BundleQuote } from '../../../entities/BundleQuote';
import { BundleCoverageTypeEnum } from '../../../types/BundleQuoteCoverage';
import { CowbellNoKnownLossesModal } from './CowbellNoKnownLossesModal';
import { Link } from '../../../../view/components/Link/Link';

export const DATE_FORMAT = 'MM/dd/yyyy';

interface SignInsuranceCowbellNoKnownLossesSignatureProps {
    bundleQuote: BundleQuote;
}
export function SignInsuranceCowbellNoKnownLossesSignature({
    bundleQuote,
}: SignInsuranceCowbellNoKnownLossesSignatureProps) {
    const cowbellNoKnownLossesModal = useModal();

    const cowbellCyberCoverage = bundleQuote.getCoverageByCoverageType(
        BundleCoverageTypeEnum.LawCyberBundleCoverageCowbell,
    );
    const quoteNumber = cowbellCyberCoverage?.quote?.quoteNumber;
    const effectiveDate = bundleQuote.effectiveDate;
    const companyName = bundleQuote.organizationInfo.companyName;

    return (
        <React.Fragment>
            <Text style="body 1" data-e2e="cowbell-no-known-losses-attestation">
                I represent that between <DateDisplay format={DATE_FORMAT} value={effectiveDate} />{' '}
                {'(12:01 AM)'} and <DateDisplay format={DATE_FORMAT} value={startOfToday()} />, I am
                not currently aware of any accidents, facts, or circumstances occuring that time
                that may result in future claims covered under Cowbell Cyber Prime 100.{' '}
                <Link href="" display="inline" onClick={cowbellNoKnownLossesModal.show}>
                    View Full Disclosure
                </Link>
                .
            </Text>
            <CowbellNoKnownLossesModal
                modal={cowbellNoKnownLossesModal}
                quoteNumber={quoteNumber}
                effectiveDate={effectiveDate}
                companyName={companyName}
            />
        </React.Fragment>
    );
}
