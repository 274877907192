import React, { Fragment, useMemo } from 'react';
import { ModalCoverageSummaryProp } from '../../coverageDefinition';
import { ESPQuote } from '../entities/ESPQuote';
import { InvoiceTable, ScrollBox, StackLayout } from '@embroker/ui-toolkit/v2';
import {
    BundleQuoteBindModalSummary,
    BundleQuoteBindModalSummaryItems,
} from '@app/bundle/view/components/BundleQuoteBindModalSummary.view';
import { ESPCoverageRateItem } from '@app/quote/esp/types/ESPRate';

export function ESPModalCoverageSummary({ quote }: ModalCoverageSummaryProp<ESPQuote>) {
    const selectedCoverages = quote.details.coverages.filter((coverage) => {
        return coverage.selected;
    });

    const titles: Record<ESPCoverageRateItem['coverageType'], string> = {
        fiduciary: 'Fiduciary Liability',
        epli: 'Employment Practices Liability',
        do: 'Directors and Officers',
        techCyber: 'Technology Error and Omissions / Cyber',
        eoCyber: 'Technology Error and Omissions / Cyber',
    };

    const displayItems: Record<
        ESPCoverageRateItem['coverageType'],
        BundleQuoteBindModalSummaryItems
    > = useMemo(() => {
        const items: Record<ESPCoverageRateItem['coverageType'], BundleQuoteBindModalSummaryItems> =
            {
                fiduciary: [],
                epli: [],
                do: [],
                techCyber: [],
                eoCyber: [],
            };
        selectedCoverages.forEach((coverage) => {
            const { coverageType } = coverage;
            const limit = {
                label: 'Limit',
                value:
                    coverage.coverageType !== 'techCyber'
                        ? coverage.limit
                        : coverage.techCoverageQuoteOptionsItem.limit || 0,
            };
            const retention = {
                label: 'Retention',
                value:
                    coverage.coverageType !== 'techCyber'
                        ? coverage.retention
                        : coverage.techCoverageQuoteOptionsItem.retention || 0,
            };
            const coverageLevel = {
                label: 'Coverage Level',
                value: coverage.level === 'standard' ? ' Standard' : ' Plus',
            };
            switch (coverageType) {
                case 'fiduciary':
                    if (items[coverageType].length === 0) {
                        items[coverageType].push(limit);
                        items[coverageType].push(retention);
                    }
                    break;
                case 'do':
                case 'epli':
                case 'eoCyber':
                    if (items[coverageType].length === 0) {
                        items[coverageType].push(limit);
                        items[coverageType].push(retention);
                        items[coverageType].push(coverageLevel);
                    }
                    break;
                case 'techCyber':
                    if (items[coverageType].length === 0) {
                        items[coverageType].push(limit);
                        items[coverageType].push(retention);
                        items[coverageType].push({
                            label: '1st party expenses limit',
                            value: coverage.cyberCoverageQuoteOptionsItem.limit || 0,
                        });
                        items[coverageType].push({
                            label: '1st party expenses retention',
                            value: coverage.cyberCoverageQuoteOptionsItem.retention || 0,
                        });
                        items[coverageType].push(coverageLevel);
                    }
                    break;

                default:
                    break;
            }
        });
        return items;
    }, [selectedCoverages]);

    return (
        <ScrollBox>
            <StackLayout gap="24">
                {selectedCoverages.map((coverage, index) => (
                    <Fragment key={coverage.coverageType}>
                        <BundleQuoteBindModalSummary
                            title={titles[coverage.coverageType]}
                            items={displayItems[coverage.coverageType]}
                        />
                        {index < selectedCoverages.length - 1 && <InvoiceTable.Separator />}
                    </Fragment>
                ))}
            </StackLayout>
        </ScrollBox>
    );
}
