import { QUEL } from '@embroker/shotwell-api/quel';
import { injectable } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { OperationFailed } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { Questionnaire } from '../../entities/Questionnaire';
import { QuestionnaireRepository } from './index';

interface GetQuestionnaireApiResponse {
    questionnaire: string;
}
@injectable()
export class APIQuestionnaireRepository implements QuestionnaireRepository {
    async get(
        questionnaire: Immutable<EntityProps<Questionnaire>>,
    ): AsyncResult<string, OperationFailed> {
        const response = await QUEL.request('POST', '/global/get_questionnaire', {
            name: questionnaire.type,
            version: questionnaire.id,
        });
        if (isErr(response)) {
            return Failure(
                OperationFailed({
                    message: 'Failed to fetch the questionnaire',
                    errors: response.errors,
                }),
            );
        }

        return Success((response.value as GetQuestionnaireApiResponse).questionnaire);
    }
}
