import type { Element, FormEngineEvent } from '@embroker/service-app-engine';
import { useStableEventHandler } from '@embroker/ui-toolkit/v2';
import { useEffect } from 'react';

/**
 * Subscribe to a Form Engine event.
 *
 * @param formEngine The Form Engine Form instance.
 * @param eventType Event type (e.g. 'submit').
 * @param callback The callback invoked when the event is invoked.
 *
 * @example
 *  useFormEngineEvent(formEngine, 'submit', () => {
 *      setFormData(formEngine.getSnapshot().value);
 *  });
 */
export function useFormEngineEvent<T extends string>(
    formEngine: Element,
    eventType: T,
    callback: (event: FormEngineEvent<T, Element>) => void,
) {
    const handler = useStableEventHandler(callback);
    useEffect(() => {
        if (formEngine) {
            formEngine.on(eventType, handler);
            return () => {
                formEngine.off(eventType, handler);
            };
        }
    }, [formEngine, eventType, handler]);
}
