import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { CalculateSKU } from '../../analytics/useCases/CalculateSKU';
import { BundleQuote, BundleQuotePurchased } from '../entities/BundleQuote';
import {
    ApplicationNotFound,
    InvalidAnnualTechFee,
    OperationNotAllowed,
    PurchaseBundleQuoteCoverageError,
    PurchaseBundleQuoteError,
    QuoteIdMissing,
    QuoteNotFound,
    QuoteOptionsNotAllowed,
    RenewalBeforeLastEndorsementDateNotAllowed,
} from '../errors';
import { BundleQuoteRepository } from '../repositories';
import { BundleCoverageAppStatusEnum, BundleCoverageType } from '../types/BundleQuoteCoverage';
import { aggregateErrors, executeReturnType } from './useCaseTypes';

export interface PurchaseBundleQuoteRequest {
    bundleQuote: BundleQuote;
}

export interface PurchaseBundleQuote extends UseCase {
    execute(
        request: PurchaseBundleQuoteRequest,
    ): AsyncResult<
        void,
        | ApplicationNotFound
        | OperationNotAllowed
        | QuoteOptionsNotAllowed
        | InvalidAnnualTechFee
        | QuoteIdMissing
        | QuoteNotFound
        | RenewalBeforeLastEndorsementDateNotAllowed
        | PurchaseBundleQuoteError
        | PurchaseBundleQuoteCoverageError
        | InvalidArgument
        | OperationFailed
    >;
}

@injectable()
class PurchaseBundleQuoteUseCase extends UseCase implements PurchaseBundleQuote {
    public static type = Symbol('BundleQuote/PurchaseBundleQuote');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BundleQuoteRepository) private bundleQuoteRepository: BundleQuoteRepository,
        @inject(CalculateSKU.type) private CalculateSku: CalculateSKU,
    ) {
        super(eventBus);
    }

    public async execute({
        bundleQuote,
    }: PurchaseBundleQuoteRequest): AsyncResult<
        void,
        | ApplicationNotFound
        | OperationNotAllowed
        | QuoteOptionsNotAllowed
        | InvalidAnnualTechFee
        | QuoteIdMissing
        | QuoteNotFound
        | RenewalBeforeLastEndorsementDateNotAllowed
        | PurchaseBundleQuoteError
        | PurchaseBundleQuoteCoverageError
        | InvalidArgument
        | OperationFailed
    > {
        const purchaseResultList = await Promise.allSettled(
            bundleQuote.coverageList.reduce<Array<executeReturnType<PurchaseBundleQuote>>>(
                (acc, coverage) => {
                    if (
                        coverage.status === BundleCoverageAppStatusEnum.QuotesReady &&
                        coverage.quote &&
                        !coverage.quote.isIndication &&
                        !coverage.quote.options.isDeselected
                    ) {
                        return [
                            ...acc,
                            this.bundleQuoteRepository.purchaseBundleQuote(
                                coverage.quote.applicationId,
                                new Map<BundleCoverageType, UUID>([
                                    [coverage.type, coverage.quote.id],
                                ]),
                            ),
                        ];
                    }
                    return acc;
                },
                [],
            ),
        );

        const errors = aggregateErrors<PurchaseBundleQuote>(
            purchaseResultList,
            'Failed purchase request',
        );

        if (errors.length) {
            return Failure(errors);
        }

        const skuResult = await this.CalculateSku.execute({
            event: 'purchase',
            applicationId: bundleQuote.applicationId,
        });

        const purchaseEvent: BundleQuotePurchased = {
            origin: 'BundleQuote',
            name: 'Purchased',
            id: bundleQuote.id,
            applicationId: bundleQuote.applicationId,
            isOFACRejected: false,
            createdAt: new Date(Date.now()),
            totalPremium: bundleQuote.getTotalBundlePremium(),
            sku: skuResult.value,
            isRenewal: false, // defaulting this until bundle renewals are impl
        };
        await this.eventBus.publish(purchaseEvent);

        return Success();
    }
}

export const PurchaseBundleQuote: UseCaseClass<PurchaseBundleQuote> = PurchaseBundleQuoteUseCase;
