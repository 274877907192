import { hasError } from '@embroker/shotwell/core/Error';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Spinner, useModal } from '@embroker/ui-toolkit/v2';
import { Elements } from '@stripe/react-stripe-js/dist/react-stripe.umd';
import React from 'react';
import { ErrorCode, PaymentsNotAllCharged } from '../../../types/errors';
import {
    GetBundlePublicInvoice,
    GetBundlePublicInvoiceRequest,
} from '../../../useCases/GetBundlePublicInvoice';
import { useStripe } from '../../hooks/useStripe';
import { PayWithACHModal } from '../modals/PayWithACHModal';
import { PayWithCCModal } from '../modals/PayWithCCModal';
import { BundleInvoiceInfo } from './BundlePublicInvoiceInfo';
import { PublicPaymentNotFound } from './PublicPaymentNotFound';
import { PublicPaymentSubmitted } from './PublicPaymentSubmitted';

interface BundlePublicPaymentData {
    publicKey: UUID;
}

export function BundlePublicPayment(props: BundlePublicPaymentData) {
    const stripe = useStripe();

    const ACHModal = useModal();
    const CCModal = useModal();

    const request: GetBundlePublicInvoiceRequest = {
        publicKey: props.publicKey,
    };

    const { result, isLoading } = useUseCase(GetBundlePublicInvoice, request);

    if (isLoading || result === undefined) {
        return <Spinner />;
    }

    if (isErr(result)) {
        if (hasError(result.errors, [ErrorCode.PaymentNotFound])) {
            return <PublicPaymentNotFound />;
        }
        return <ErrorPage errors={result.errors} />;
    }

    const bundlePayment = result.value.bundlePayment;

    const chargedInvoices = bundlePayment.invoiceList.filter(
        (invoice) => invoice.status === 'charge',
    );

    const areAllInvoicesCharged = chargedInvoices.length === bundlePayment.invoiceList.length;
    const isAnyInvoiceUncharged = chargedInvoices.length > 0;

    if (areAllInvoicesCharged) {
        return <PublicPaymentSubmitted />;
    } else if (isAnyInvoiceUncharged) {
        return <ErrorPage errors={[PaymentsNotAllCharged()]} />;
    }

    const selectedInvoiceIdList = bundlePayment.invoiceList.map((invoice) => invoice.id);

    const total = bundlePayment.total;

    function showACHModal() {
        ACHModal.show();
    }

    function hideACHModal() {
        ACHModal.hide();
    }

    function showCCModal() {
        CCModal.show();
    }

    function hideCCModal() {
        CCModal.hide();
    }

    return (
        <React.Fragment>
            <BundleInvoiceInfo
                bundlePayment={bundlePayment}
                showACHModal={showACHModal}
                showCCModal={showCCModal}
            />
            <Elements stripe={stripe}>
                <PayWithACHModal
                    activeInvoices={selectedInvoiceIdList}
                    hideModal={hideACHModal}
                    modal={ACHModal}
                    publicKey={props.publicKey}
                    total={total}
                />
                <PayWithCCModal
                    activeInvoices={selectedInvoiceIdList}
                    hideModal={hideCCModal}
                    modal={CCModal}
                    publicKey={props.publicKey}
                    total={total}
                />
            </Elements>
        </React.Fragment>
    );
}
