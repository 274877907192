import {
    StackLayout,
    Text,
    Box,
    ColumnLayout,
    ButtonBar,
    Button,
    BoxLayout,
} from '@embroker/ui-toolkit/v2';
import React from 'react';

type Props = {
    onHide: () => void;
};

export const ESPEoBundleCyberDifferenceModal = ({ onHide }: Props) => {
    return (
        <BoxLayout style={{ overflowY: 'scroll' }}>
            <StackLayout>
                <Text style="heading 3">What is Technology E&O / Cyber “Plus” Coverage?</Text>
                <Text>
                    Our Plus form covers a broader range of Tech E&O / Cyber exposures with market
                    leading-coverage enhancements not included in our Standard form.
                </Text>
                <Box backgroundColor="ui-100" borderRadius="8" gap="32">
                    <StackLayout gap="12">
                        <Text style="heading 4">
                            Financial protection against contract-related claims:
                        </Text>
                        <ColumnLayout gap="4">
                            <Text>•</Text>{' '}
                            <Text>
                                Express coverage for liability of others (indemnity) that you assume
                                in a contract or agreement.
                            </Text>
                        </ColumnLayout>
                        <ColumnLayout gap="4">
                            <Text>•</Text>{' '}
                            <Text>
                                If you’ve agreed to it contractually, the policy will pay breach
                                response costs directly to your client in the event of a breach
                                related to your tech products or services — with no claim against
                                you needed to trigger payment.
                            </Text>
                        </ColumnLayout>
                        <ColumnLayout gap="4">
                            <Text>•</Text>{' '}
                            <Text>
                                Covers court-ordered fees awarded back to clients (most policies
                                don't offer this).
                            </Text>
                        </ColumnLayout>
                    </StackLayout>
                    <Box border={false}>
                        <hr />
                    </Box>
                    <StackLayout gap="12">
                        <Text style="heading 4">
                            Added coverages for a stronger defense against online threats:
                        </Text>
                        <ColumnLayout gap="4">
                            <Text>•</Text>{' '}
                            <Text>
                                <b>“Pay on Behalf” Ransomware:</b> In response to cyber extortion,
                                your carrier will make a ransom payment for you directly, instead of
                                you making the payment then getting reimbursed by the insurer.
                            </Text>
                        </ColumnLayout>
                        <ColumnLayout gap="4">
                            <Text>•</Text>{' '}
                            <Text>
                                <b>Bricking and Computer System Betterment:</b> Covers the cost of
                                replacing your computers or any associated devices/equipment that
                                are corrupted or destroyed in a cyber incident.
                            </Text>
                        </ColumnLayout>
                        <ColumnLayout gap="4">
                            <Text>•</Text>{' '}
                            <Text>
                                <b>Reputational Harm:</b> Pays costs associated with restoring your
                                reputation following a cyber incident or security breach, including
                                fees for hiring a public relations firm to handle negative
                                publicity.
                            </Text>
                        </ColumnLayout>
                        <ColumnLayout gap="4">
                            <Text>•</Text>{' '}
                            <Text>
                                <b>Utility Fraud:</b> Covers the cost of any unauthorized use of
                                your telephone and/or computer systems. This may include
                                telecommunications fraud and cryptojacking.
                            </Text>
                        </ColumnLayout>
                        <ColumnLayout gap="4">
                            <Text>•</Text>{' '}
                            <Text>
                                <b>Social Engineering:</b> Covers losses caused by fraudulent acts
                                in which an employee is tricked into sending money to a scammer
                                alleging to be a vendor, coworker, or executive. Subject to a
                                minimum retention of $25,000.
                            </Text>
                        </ColumnLayout>
                        <ColumnLayout gap="4">
                            <Text>•</Text>{' '}
                            <Text>
                                <b>Funds Transfer Fraud:</b> Covers losses that can arise if someone
                                fraudulently instructs a financial institution to debit money from
                                your transfer account.
                            </Text>
                        </ColumnLayout>
                        <ColumnLayout gap="4">
                            <Text>•</Text>{' '}
                            <Text>
                                <b>Invoice and Delivery Fraud:</b> Covers losses in cases where
                                unauthorized access to your network has allowed a third party to
                                create and send fraudulent invoices and payment instructions to your
                                customers.
                            </Text>
                        </ColumnLayout>
                        <ColumnLayout gap="4">
                            <Text>•</Text>{' '}
                            <Text>
                                <b>Voluntary Shutdown:</b> Covers loss of income and extra expenses
                                if you intentionally shut down your computer system to try to limit
                                losses as a result of a network security breach or as required by a
                                government entity.
                            </Text>
                        </ColumnLayout>
                    </StackLayout>
                </Box>
                <ButtonBar>
                    <div />
                    <Button onClick={onHide}>Got it</Button>
                </ButtonBar>
            </StackLayout>
        </BoxLayout>
    );
};
