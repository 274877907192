import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { CyberQuote } from '../entities/CyberQuote';
import { CyberQuoteRepository } from '../repositories/CyberQuoteRepository';

export interface GetLastCyberQuoteRequest {
    applicationId: UUID;
}

export interface GetLastCyberQuote extends UseCase {
    execute(
        request: GetLastCyberQuoteRequest,
    ): AsyncResult<CyberQuote, InvalidArgument | OperationFailed>;
}

@injectable()
class GetLastCyberQuoteUseCase extends UseCase implements GetLastCyberQuote {
    public static type = Symbol('CyberQuote/GetLastCyberQuote');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(CyberQuoteRepository) private cyberQuoteRepository: CyberQuoteRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
    }: GetLastCyberQuoteRequest): AsyncResult<CyberQuote, InvalidArgument | OperationFailed> {
        return await this.cyberQuoteRepository.getLastCyberQuote(applicationId);
    }
}

export const GetLastCyberQuote: UseCaseClass<GetLastCyberQuote> = GetLastCyberQuoteUseCase;
