import React from 'react';
import { BundleSummaryComponentProps } from '../../coverageDefinition';
import { LawCyberQuote } from '../entities/LawCyberQuote';
import { CyberInvoiceSummaryTable } from '../../../view/components/cyber/CyberInvoiceSummaryTable';

export function CyberQuoteSummary({
    questionnaireData,
    quote,
}: BundleSummaryComponentProps<LawCyberQuote>) {
    const state = questionnaireData?.location_with_largest_number?.state ?? questionnaireData.state;
    return (
        <CyberInvoiceSummaryTable
            insurerName={getLawCyberInsurer(state)}
            quote={quote}
            questionnaireData={questionnaireData}
            feeTitleText={getLawCyberFeeTitle(state)}
        />
    );
}

function getLawCyberInsurer(state: string): string {
    if (state === 'NC') {
        return 'Insurer: Clear Blue Insurance Company';
    }

    return 'Insurer: Clear Blue Specialty Insurance Company';
}

function getLawCyberFeeTitle(state: string): string {
    if (state === 'OR') {
        return 'Surplus Lines Service Charge';
    }

    return 'Surplus Lines Fees';
}
