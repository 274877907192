import { Modal, ModalActions, ModalState, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { ModalLayout } from '../../../../view/components/ModalLayout.view';

export interface SuccessModalProps {
    modal: ModalState & ModalActions;
}

export function SuccessModal({ modal }: SuccessModalProps) {
    const { navigate } = useNavigation();
    const handleClose = () => navigate('/summary');

    return (
        <Modal {...modal} dismissable={false}>
            <ModalLayout
                title="Thanks"
                primaryAction={{
                    label: 'Continue',
                    onClick: handleClose,
                }}
            >
                <Text>
                    Thank you for requesting the policy. An Embroker team member will review it
                    shortly. If approved, your request confirms your intent to purchase coverage. We
                    will then bind and issue the policy accordingly, and issue an invoice.
                </Text>
                <Text>We'll be in touch if we require any additional information.</Text>
            </ModalLayout>
        </Modal>
    );
}
