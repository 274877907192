import { defineCodec } from '@embroker/shotwell/core/encoding';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { ShoppingCoverage, ShoppingCoverageCodeList } from './enums';

export type ShoppingCoverageCodeArray = Array<ShoppingCoverage>;

export const ShoppingCoverageCodeArray = {
    ...defineCodec<ShoppingCoverageCodeArray>(),
    ...defineValidator<ShoppingCoverageCodeArray>(
        Joi.array()
            .required()
            .items(Joi.string().valid(...ShoppingCoverageCodeList)),
    ),
};
