import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { ServerEnumGroup } from '../../types/ServerEnumGroup';

/**
 * This is the repository used to work with ...
 */
export interface ServerEnumsRepository {
    getEnums(): AsyncResult<ServerEnumGroup, InvalidArgument | OperationFailed>;
}

export const ServerEnumsRepository = Symbol('ServerEnumsRepository');
