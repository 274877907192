import { AppTypeCodeListItem } from '@embroker/shotwell-api/enums';
import { inject } from '@embroker/shotwell/core/di';
import { JSONSerdes } from '@embroker/shotwell/core/encoding';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, Failure, isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import {
    AppTypeNotSupported,
    OrganizationNotFound,
    PrecreateApplicationError,
    ShoppingCoverageNotProvided,
    ShoppingCoverageNotSupportedForAppType,
} from '../../shopping/errors';
import { ApplicationRepository } from '../../shopping/repositories/ApplicationRepository';
import { QuestionnaireData } from '../../shopping/types/QuestionnaireData';
import { ShoppingCoverageCodeArray } from '../../shopping/types/ShoppingCoverageCodeArray';
import { InvalidQuestionnaire } from '../errors';

export interface PrecreateApplicationRequest {
    appType: Immutable<AppTypeCodeListItem>;
    shoppingCoverageCodeList: Immutable<ShoppingCoverageCodeArray>;
    questionnaireData: QuestionnaireData;
}

export interface PrecreateApplicationResponse {
    applicationId: UUID;
}

export interface PrecreateApplication extends UseCase {
    execute(
        request: PrecreateApplicationRequest,
    ): AsyncResult<
        PrecreateApplicationResponse,
        | PrecreateApplicationError
        | ShoppingCoverageNotSupportedForAppType
        | OrganizationNotFound
        | AppTypeNotSupported
        | ShoppingCoverageNotProvided
        | InvalidQuestionnaire
    >;
}

export class PrecreateApplicationUseCase extends UseCase implements PrecreateApplication {
    public static type = Symbol('Broker/PrecreateApplication');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        appType,
        shoppingCoverageCodeList,
        questionnaireData,
    }: PrecreateApplicationRequest): AsyncResult<
        PrecreateApplicationResponse,
        | PrecreateApplicationError
        | ShoppingCoverageNotSupportedForAppType
        | OrganizationNotFound
        | AppTypeNotSupported
        | ShoppingCoverageNotProvided
        | InvalidQuestionnaire
    > {
        const questionnaireDataResult = JSONSerdes.serialize(questionnaireData);
        if (isErr(questionnaireDataResult)) {
            return Failure(InvalidQuestionnaire(questionnaireDataResult.errors));
        }

        return await this.applicationRepository.precreateApplication({
            appType,
            shoppingCoverageCodeList,
            questionnaireData: questionnaireDataResult.value,
        });
    }
}

export const PrecreateApplication: UseCaseClass<PrecreateApplication> = PrecreateApplicationUseCase;
