import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface ModelVersion {
    id: string;
    modelType: string;
    description: string;
    effectiveDate: Date;
}

export const ModelVersion = {
    ...defineValidator<ModelVersion>(
        Joi.object({
            id: Joi.string(),
            modelType: Joi.string(),
            description: Joi.string(),
            effectiveDate: Joi.date().required(),
        }),
    ),
    create(modelVersion: ModelVersion) {
        return ModelVersion.validate(modelVersion);
    },
};
