import { errorCodes } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { ErrorObject } from '@embroker/shotwell/core/types/Result';

export const ErrorCode = errorCodes({
    /**
     * NAICS not found error
     */
    UnknownNAICSCode: 0x3501,
});

export type UnknownNAICSCode = ErrorObject<
    typeof ErrorCode.UnknownNAICSCode,
    {
        naicsCode: string;
    }
>;

export function UnknownNAICSCode(naicsCode: string): Immutable<UnknownNAICSCode> {
    return {
        name: 'UnknownNAICSCode',
        code: ErrorCode.UnknownNAICSCode,
        message: 'Unknown NAICS code',
        details: {
            naicsCode,
        },
    };
}
