import { API, CurrencyMarshaller } from '@embroker/shotwell-api/app';
import { UwReview } from '@embroker/shotwell-api/app.spec';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    isOK,
    Result,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import {
    CoverageRestriction,
    ESPCoverageType,
    ESPCoverageTypes,
} from '../../types/CoverageRestriction';
import { InsuranceApplicationRestriction } from '../../types/InsuranceApplicationRestriction';
import { InsuranceApplicationRestrictionRepository } from './index';
import { format, parseISO } from 'date-fns';
import { Money } from '@embroker/shotwell/core/types/Money';

@injectable()
export class APIInsuranceApplicationRestrictionRepository
    implements InsuranceApplicationRestrictionRepository
{
    public async get(
        insuranceApplicationId: UUID,
    ): AsyncResult<
        InsuranceApplicationRestriction | undefined,
        InvalidArgument | UnknownEntity | OperationFailed
    > {
        const result = await API.request('shopping/get_uw_review', {
            insurance_application_id: insuranceApplicationId,
        });

        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        const restriction = fromApiResponse(result.value?.uw_review);

        if (isErr(restriction)) {
            return handleOperationFailure(restriction);
        }

        return Success(restriction.value);
    }
}

function getFormattedValue(dataType: string, value: string): string {
    switch (dataType) {
        case 'UWEndorsementCustomInputDataTypeCurrency':
            return Money.toString(CurrencyMarshaller.unmarshal(value));
        case 'UWEndorsementCustomInputDataTypeDate':
            return value === 'inception' ? value : format(parseISO(value), 'MM/dd/yyyy');
        case 'UWEndorsementCustomInputDataTypeText':
            return value;
        case 'UWEndorsementCustomInputDataTypeTextarea':
            return value;
        case 'UWEndorsementCustomInputDataTypePercentage':
            return value + '%';
        default:
            return value;
    }
}

function fromApiResponse(
    apiRestriction?: Immutable<UwReview>,
): Result<InsuranceApplicationRestriction | undefined> {
    if (!apiRestriction) {
        return Success(undefined);
    }
    const coverageRestrictions =
        apiRestriction.allowed_app_quote_options.allowed_coverage_quote_options.reduce(
            (acc: CoverageRestriction[], apiCoverageRestriction) => {
                const coverageType = [...ESPCoverageTypes].find(
                    (x) => x === apiCoverageRestriction.coverage_type,
                );
                if (!coverageType) {
                    return acc;
                }
                const coverageRestriction = CoverageRestriction.create({
                    coverageType: coverageType,
                    maxLimit: CurrencyMarshaller.unmarshal(
                        apiCoverageRestriction.limit.max['MultiValueLimitNameListSingleLimit'],
                    ),
                    minRetention: CurrencyMarshaller.unmarshal(
                        apiCoverageRestriction.retention?.min,
                    ),
                    allowPlus: apiCoverageRestriction.allow_plus ?? true,
                    allowCoverage: apiCoverageRestriction.allow_renewal ?? true,
                    increasePremium: apiCoverageRestriction.increase_premium ?? 0,
                });

                if (isOK(coverageRestriction)) {
                    acc.push(coverageRestriction.value);
                }

                return acc;
            },
            [],
        );

    const endorsements = (apiRestriction.endorsements ?? []).map((endorsement) => {
        return {
            id: endorsement.endorsement_id,
            name: endorsement.endorsement_name ?? '',
            displayName: endorsement.endorsement_display_name,
            inputList:
                endorsement.custom_inputs?.map((ci) => ({
                    id: ci.id,
                    value: getFormattedValue(ci.data_type, ci.value),
                })) ?? [],
            allowedCoverages: (endorsement.allowed_coverages ?? []) as ESPCoverageType[],
        };
    });

    return InsuranceApplicationRestriction.create({
        insuranceApplicationId: apiRestriction.insurance_application_id,
        minimumStartDate: apiRestriction.allowed_app_quote_options.minimum_start_date
            ? new Date(apiRestriction.allowed_app_quote_options.minimum_start_date)
            : new Date(Date.now()),
        coverageRestrictions: coverageRestrictions,
        endorsementList: endorsements,
        areManualRestrictionsApplied: apiRestriction.are_manual_restrictions_applied ?? false,
    });
}
