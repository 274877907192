import { Money } from '@embroker/shotwell/core/types/Money';
import { SelectOptionItem } from '@embroker/ui-toolkit/v2';
import { CrimeLimit, CrimeRetention } from '../../types/CrimeQuoteOptions';

export function getCrimeLimitOptions(): SelectOptionItem<CrimeLimit>[] {
    return [
        { title: '$500,000', value: 500000 },
        { title: '$1,000,000', value: 1000000 },
        { title: '$2,000,000', value: 2000000 },
        { title: '$3,000,000', value: 3000000 },
        { title: '$4,000,000', value: 4000000 },
        { title: '$5,000,000', value: 5000000 },
    ];
}

export function getCrimeRetentionOptions(): SelectOptionItem<CrimeRetention>[] {
    return [
        { title: '$2,500', value: 2500 },
        { title: '$5,000', value: 5000 },
        { title: '$10,000', value: 10000 },
        { title: '$25,000', value: 25000 },
        { title: '$50,000', value: 50000 },
        { title: '$100,000', value: 100000 },
    ];
}

const limitMax = 3000 * 1000; // $3M

export const getLimitRetentionOptions = (previousLimit?: CrimeLimit) => {
    const limitOptions = getCrimeLimitOptions();

    const limits =
        previousLimit &&
        Money.isGreaterThan(Money.tryFromFloat(previousLimit), Money.tryFromFloat(limitMax))
            ? limitOptions.filter((limit) =>
                  Money.isLessThanOrEqual(
                      Money.tryFromFloat(limit.value),
                      Money.tryFromFloat(previousLimit),
                  ),
              )
            : limitOptions.filter((limit) =>
                  Money.isLessThanOrEqual(
                      Money.tryFromFloat(limit.value),
                      Money.tryFromFloat(limitMax),
                  ),
              );
    const retentions = getCrimeRetentionOptions();

    return {
        limits,
        retentions,
    };
};
