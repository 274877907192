import { errorCodes } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { ErrorObject } from '@embroker/shotwell/core/types/Result';

export const ErrorCode = errorCodes({
    FailedToUpdateNotificationStatus: 0xb700,
    FailedToGetNotifications: 0xb701,
    FailedToCreateNotificationEntity: 0xb702,
    FailedToUpdateAllNotificationsStatus: 0xb703,
    FailedToGetNotificationsStats: 0xb704,
});

export type FailedToCreateNotificationEntity = ErrorObject<
    typeof ErrorCode.FailedToCreateNotificationEntity
>;

export function FailedToCreateNotificationEntity(): Immutable<FailedToCreateNotificationEntity> {
    return {
        name: 'FailedToCreateNotificationEntity',
        code: ErrorCode.FailedToCreateNotificationEntity,
        message: 'Failed to create notification entity.',
    };
}

export type FailedToUpdateNotificationStatus = ErrorObject<
    typeof ErrorCode.FailedToUpdateNotificationStatus
>;

export function FailedToUpdateNotificationStatus(): Immutable<FailedToUpdateNotificationStatus> {
    return {
        name: 'FailedToUpdateNotificationStatus',
        code: ErrorCode.FailedToUpdateNotificationStatus,
        message: 'Failed to update user notification.',
    };
}

export type FailedToUpdateAllNotificationsStatus = ErrorObject<
    typeof ErrorCode.FailedToUpdateAllNotificationsStatus
>;

export function FailedToUpdateAllNotificationsStatus(): Immutable<FailedToUpdateAllNotificationsStatus> {
    return {
        name: 'FailedToUpdateAllNotificationsStatus',
        code: ErrorCode.FailedToUpdateAllNotificationsStatus,
        message: 'Failed to update all user notifications status.',
    };
}

export type FailedToGetNotifications = ErrorObject<typeof ErrorCode.FailedToGetNotifications>;

export function FailedToGetNotifications(): Immutable<FailedToGetNotifications> {
    return {
        name: 'FailedToGetNotifications',
        code: ErrorCode.FailedToGetNotifications,
        message: 'Failed to update user notification.',
    };
}

export type FailedToGetNotificationsStats = ErrorObject<
    typeof ErrorCode.FailedToGetNotificationsStats
>;

export function FailedToGetNotificationsStats(): Immutable<FailedToGetNotificationsStats> {
    return {
        name: 'FailedToGetNotificationsStats',
        code: ErrorCode.FailedToGetNotificationsStats,
        message: 'Failed to update user notification.',
    };
}
