import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { deepClone, IndexableObject, isObject } from '@embroker/shotwell/core/object';
import { Data, Immutable } from '@embroker/shotwell/core/types';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { URI } from '@embroker/shotwell/core/types/URI';
import { physicalAddressValidator } from '@embroker/shotwell/core/types/POBox';
import * as specDefinitions from '@app/shopping/types/spec_enums';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { countyMap, State, StateList } from '@embroker/shotwell/core/types/StateList';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import {
    AppTypeCode,
    AppTypeCodeList,
    ShopppingCoverageCode,
    ShopppingCoverageCodeList,
} from '@app/shopping/types/enums';
import { NAICSRepository } from '../../industries/repositories/NAICSRepository';
import { ExcessCarrierRepository } from '../../quote/embrokerExcess/repositories/ExcessCarrierRepository';
import { hasRole } from '../../userOrg/entities/Session';
import { GetActiveSession } from '../../userOrg/useCases/GetActiveSession';
import { InvestorsRepository } from '../repositories/InvestorsRepository';
import { WCDataRepository } from '../repositories/WCDataRepository';
import type {
    WCGASpecLocationTable,
    WCGASpecStateTable,
    WCGAWorkerType,
} from '../types/WCPayrollTable';
import { bopClassCodes } from '@embroker/formspecs/context/bopClassCodes';
import { MLDisclosureByState } from '@embroker/formspecs/context/disclosures';
import { Money } from '@embroker/shotwell/core/types/Money';
import { GlobalConfigRepository } from '@app/config/repositories/GlobalConfigRepository';

export interface GetFormEngineContextRequest {
    context: Data;
    requireSession?: boolean;
}

export interface GetFormEngineContextResponse {
    formEngineContext: Data;
}

export const ApplicationStates = Object.freeze({
    UNSAVED: 'unsaved',
    SAVED: 'saved',
    SUBMITTED: 'submitted',
});

const AppTypes = AppTypeCodeList.reduce((acc: IndexableObject<AppTypeCode>, value) => {
    acc[value] = value;
    return acc;
}, {});

const ShoppingCoverageCodes = ShopppingCoverageCodeList.reduce(
    (acc: IndexableObject<ShopppingCoverageCode>, value) => {
        acc[value] = value;
        return acc;
    },
    {},
);

const defaultContext = {
    ...specDefinitions,
    ...AppTypes,
    ...ShoppingCoverageCodes,
    applicationState: ApplicationStates.UNSAVED,
    bopClassCodes,
    showSignatureValue: true,
    fromNumToCurrency: fromNumToUsdCurrency,
    isEmailValid,
    MLDisclosureByState,
    newApplication: false,
    prefillWCEmployeeData,
    USCounties: countyMap,
    USStatesList: StateList,
    physicalAddressValidator: physicalAddressValidator,
};

export interface GetFormEngineContext extends UseCase {
    execute(
        request: GetFormEngineContextRequest,
    ): AsyncResult<GetFormEngineContextResponse, InvalidArgument | OperationFailed>;
}

@injectable()
class GetFormEngineContextUseCase extends UseCase implements GetFormEngineContext {
    public static type = Symbol('Shopping/GetFormEngineContextUseCase');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(GetActiveSession.type) private readonly getActiveSession: GetActiveSession,
        @inject(WCDataRepository) private wcDataRepository: WCDataRepository,
        @inject(NAICSRepository) private naicsRepository: NAICSRepository,
        @inject(InvestorsRepository) private investorRepository: InvestorsRepository,
        @inject(ExcessCarrierRepository) private excessCarrierRepository: ExcessCarrierRepository,
        @inject(GlobalConfigRepository) private globalConfigRepository: GlobalConfigRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: GetFormEngineContextRequest,
    ): AsyncResult<GetFormEngineContextResponse, InvalidArgument | OperationFailed> {
        const configResponse = await this.globalConfigRepository.getGlobalConfig(true);

        if (isErr(configResponse)) {
            return handleOperationFailure(configResponse);
        }

        if (configResponse.value.isWcgaEnabled) {
            const loadWCDataResult = await this.wcDataRepository.loadData();

            if (isErr(loadWCDataResult)) {
                return handleOperationFailure(loadWCDataResult);
            }
        }

        const wcClassCodeList = this.wcClassCodeList.bind(this);
        const wcIncludesOfficers = this.wcIncludesOfficers.bind(this);
        const fetchLeadInvestors = this.fetchLeadInvestors.bind(this);
        const fetchCarriers = this.fetchCarriers.bind(this);
        const getCarrier = this.getCarrier.bind(this);
        const isNaicsInGroup = this.isNaicsInGroup.bind(this);
        const getNaicsNameByCode = this.getNaicsNameByCode.bind(this);

        let isAdmin = false;
        let mandatoryRuleDisabled = false;
        const requireSession = request.requireSession ?? true;
        if (requireSession) {
            const sessionResult = await this.getActiveSession.execute();
            if (isErr(sessionResult)) {
                return handleOperationFailure(sessionResult);
            }
            const activeSession = sessionResult.value.session;
            mandatoryRuleDisabled =
                hasRole(activeSession, 'admin') && request.context.newApplication === true;

            isAdmin = hasRole(activeSession, 'admin');
        }

        const formEngineContext: Data = {
            ...defaultContext,
            mandatoryRuleDisabled,
            isAdmin,
            fetchLeadInvestors,
            fetchCarriers,
            getCarrier,
            WCClassCodeList: wcClassCodeList,
            WCIncludesOfficers: wcIncludesOfficers,
            isNaicsInGroup,
            getNaicsNameByCode,
            moneyTryFromFloat: moneyTryFromFloat,
            moneySum: moneySum,
            moneyIsEqual: moneyIsEqual,
            isValidURL: (url: string) => URI.check(url),
            ...request.context,
        };

        return Success<GetFormEngineContextResponse>({
            formEngineContext,
        });
    }

    wcClassCodeList(state: State) {
        return this.wcDataRepository.getAllowedClassCodes(state).map((classCode) => ({
            value: classCode.code,
            title: classCode.description + ` (${classCode.code})`,
            tooltip: classCode.tooltip,
        }));
    }

    wcIncludesOfficers(state: State, entity: string) {
        return this.wcDataRepository.getOfficerInclusion(state, entity);
    }

    isNaicsInGroup(naicsCode: string, naicsGroup: string) {
        return this.naicsRepository.isNaicsInGroup(naicsCode, naicsGroup);
    }

    getNaicsNameByCode(naicsCode: string): string | undefined {
        return this.naicsRepository.getNaicsNameByCode(naicsCode);
    }

    async fetchLeadInvestors(searchPattern: string | undefined) {
        if (!searchPattern) {
            return [];
        }
        const investorsResult = await this.investorRepository.getInvestors(searchPattern);
        if (isErr(investorsResult)) {
            return [];
        }

        const { value: investors } = investorsResult;
        return (investors || []).map((investor) => ({
            label: investor,
            value: investor,
        }));
    }

    async fetchCarriers(searchPattern: string | undefined) {
        if (!searchPattern) {
            return [];
        }

        const carriersResult = await this.excessCarrierRepository.getCarriers(searchPattern);
        if (isErr(carriersResult)) {
            return [];
        }

        const { value: carriers } = carriersResult;
        return carriers.map((carrier) => ({
            label: `${carrier.carrierName} (${carrier.carrierPaper})`,
            value: carrier.id,
        }));
    }

    async getCarrier(id: string | undefined) {
        if (!UUID.check(id)) {
            return {
                cyber_name: 'Cyber',
            };
        }
        const carrierResult = await this.excessCarrierRepository.getCarrier(id);
        if (isErr(carrierResult)) {
            return;
        }

        return {
            policy_form_name: carrierResult.value.policyFormName,
            cyber_name: carrierResult.value.cyberCoverageName,
        };
    }
}

export const GetFormEngineContext: UseCaseClass<GetFormEngineContext> = GetFormEngineContextUseCase;

type setInObject = IndexableObject | unknown[];

function setIn(path: string[], object: setInObject, value: unknown): setInObject {
    const [key, ...keys] = path;
    if (isObject(object)) {
        if (keys.length === 0) {
            object[key] = value;
        } else {
            const nextKey = keys[0];
            if (object[key] === undefined) {
                object[key] = isNaN(Number(nextKey)) ? {} : [];
            }
            setIn(keys, object[key] as setInObject, value);
        }
    } else {
        const index = Number(key);
        if (keys.length === 0) {
            if (!isNaN(index)) {
                object[index] = value;
            }
        } else {
            const nextKey = keys[0];
            if (object[index] === undefined) {
                object[index] = isNaN(Number(nextKey)) ? {} : [];
            }
            setIn(keys, object[index] as setInObject, value);
        }
    }
    return object;
}

export function toFormEngineInput(formEngineSnapshot: IndexableObject): any {
    const result = Object.create(null);
    for (const key of Object.keys(formEngineSnapshot)) {
        const path = key.split('.');
        setIn(path, result, formEngineSnapshot[key]);
    }
    return result;
}

export function isEmailValid(email: string): boolean {
    return EmailAddress.check(email);
}

export function fromNumToUsdCurrency(num: any): string {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
    });

    return formatter.format(num);
}
// Tech debt, copy paste from ShoppingService.js cause we did not have time to properly write this,
// consider rewrite this function after release of em2020 shopping
export function prefillWCEmployeeData(
    value: WCGASpecLocationTable[],
    state: string,
    applicationState: string,
): WCGASpecStateTable {
    const classCodesId = window.sessionStorage.getItem('activeCodesId');
    let updatedRows = null;
    const newRows = { $: [...value] } as WCGASpecStateTable;

    if (applicationState === ApplicationStates.SUBMITTED) {
        return newRows;
    }

    const wcPayrollRows = window.sessionStorage.getItem(`wcPayrollRows-${classCodesId}`);
    try {
        const oldRows = wcPayrollRows ? JSON.parse(wcPayrollRows) : {};
        updatedRows = mergePrefillPerState(oldRows[state], newRows);
    } catch (err) {
        updatedRows = newRows;
    }
    return updatedRows;
}

/**
 * @description Merges generated class with values from prefill, taking prefill as basis and generated data as extension.
 *
 * @param {Object} generatedData
 * @param {Object} prefillData
 */
function mergePrefillPerState(
    generatedData: WCGASpecStateTable,
    prefillData: WCGASpecStateTable,
): WCGASpecStateTable {
    if (!generatedData || !Array.isArray(generatedData.$) || generatedData.$.length === 0) {
        return prefillData && Array.isArray(prefillData.$) ? deepClone(prefillData) : { $: [] };
    }
    if (!prefillData || !Array.isArray(prefillData.$) || prefillData.$.length === 0) {
        return deepClone(generatedData);
    }

    const resultData = deepClone(prefillData);
    for (const generatedRow of generatedData.$) {
        const { location_id: generatedLocationId, worker_types: generatedWorkerTypes } =
            generatedRow;
        const matchingPrefillLocation = resultData.$?.find(
            (e: WCGASpecLocationTable) => e.location_id === generatedLocationId,
        );

        if (!matchingPrefillLocation) {
            continue;
        }

        const prefillClassCodes = matchingPrefillLocation.worker_types?.$?.map(
            (type: WCGAWorkerType) => type.class_code,
        );
        const notPrefilledWorkerType = generatedWorkerTypes?.$?.filter(
            (wType: WCGAWorkerType) => !((prefillClassCodes?.indexOf(wType.class_code) ?? -1) >= 0),
        );

        matchingPrefillLocation.worker_types?.$?.push(...(notPrefilledWorkerType || []));
    }
    return resultData;
}

function moneyTryFromFloat(value: number): Immutable<Money> {
    return Money.tryFromFloat(value);
}

function moneySum(a: Immutable<Money>, b: Immutable<Money>): Immutable<Money | undefined> {
    const sum = Money.sum([a, b]);
    if (isErr(sum)) {
        return;
    }
    return sum.value;
}

function moneyIsEqual(a: Immutable<Money>, b: Immutable<Money>): boolean {
    return Money.isEqual(a, b);
}
