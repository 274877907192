import { Quote } from '@app/quote/entities/Quote';
import { StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { ESPQuote } from '../entities/ESPQuote';

type ESPBundleFooterProps = {
    quote: Quote | undefined;
};

export function ESPBundleFooter(props: ESPBundleFooterProps) {
    const { quote } = props;
    const options = (quote as ESPQuote)?.options;
    return (
        <StackLayout gap="32">
            {options?.cyber?.selected && options?.technology?.selected && (
                <StackLayout gap="none">
                    <Text color="ui-400" style="body 2" data-e2e="cyber-carrier">
                        Tech E&O / Cyber: Underwritten by Everspan Indemnity Insurance Company
                    </Text>
                    <Text color="ui-500" style="body 2" data-e2e="cyber-carrier-description">
                        Everspan Indemnity Insurance Company is currently ritated AM Best A-
                        (Excellent) with a Financial Size Category of VIII.
                    </Text>
                </StackLayout>
            )}
            {options?.directorsAndOfficers?.selected && (
                <StackLayout gap="none">
                    <Text color="ui-400" style="body 2" data-e2e="cyber-carrier">
                        D&O carrier: Underwritten by Everspan Indemnity Insurance Company
                    </Text>
                    <Text color="ui-500" style="body 2" data-e2e="cyber-carrier-description">
                        Everspan Indemnity Insurance Company is currently rated AM Best A-
                        (Excellent) with a Financial Size Category of VIII.
                    </Text>
                </StackLayout>
            )}
            {options?.employmentPracticesLiability?.selected && (
                <StackLayout gap="none">
                    <Text color="ui-400" style="body 2" data-e2e="cyber-carrier">
                        EPL carrier: Underwritten by Everspan Indemnity Insurance Company
                    </Text>
                    <Text color="ui-500" style="body 2" data-e2e="cyber-carrier-description">
                        Everspan Indemnity Insurance Company is currently rated AM Best A-
                        (Excellent) with a Financial Size Category of VIII.
                    </Text>
                </StackLayout>
            )}
            {options?.fiduciary?.selected && (
                <StackLayout gap="none">
                    <Text color="ui-400" style="body 2" data-e2e="cyber-carrier">
                        Fiduciary carrier: Underwritten by Everspan Indemnity Insurance Company
                    </Text>
                    <Text color="ui-500" style="body 2" data-e2e="cyber-carrier-description">
                        Everspan Indemnity Insurance Company is currently rated AM Best A-
                        (Excellent) with a Financial Size Category of VIII.
                    </Text>
                </StackLayout>
            )}
        </StackLayout>
    );
}
