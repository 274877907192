import { ScrollBox, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';

export function LPLStreamlineConditions() {
    return (
        <ScrollBox>
            <StackLayout>
                {conditionList.map((condition, index) => (
                    <Text as="p" key={index}>
                        {condition}
                    </Text>
                ))}
            </StackLayout>
        </ScrollBox>
    );
}

const conditionList = [
    'There is no change to your attorney roster;',
    'Your Areas of Practice are the same as the ones noted in the application on file;',
    'The amount of work within the Areas of Practice you selected on the application submitted for the prior year’s policy have not changed by more than +/- 5%; ',
    'In the past year, no attorney has been the subject of a bar complaint, bar grievance, denied the right to practice, suspended from practice, disbarred, reprimanded, or had other disciplinary action by any court or administrative agency;',
    'In the past year no claims or incidents have been alleged or otherwise active against attorneys in the firm (past or present);',
    'No member of the firm is aware of any incident, act, error or omission that may result in a claim or disciplinary action being brought against the firm;',
    'Neither the firm nor any attorney employed or hired by the law firm has been indicted or convicted of a criminal charge;',
    'In the past 5 years no attorney in the firm handled any class action/mass tort litigation; and',
    'No attorney in the firm provides services or legal counsel to clients, individuals or businesses involved in the growing, storage, testing, handling, manufacturing, packaging, dispensing, distribution, transportation or sale of cannabis and/or products containing cannabis.',
];
