import {
    Aborted,
    InvalidArgument,
    OperationFailed,
    Timeout,
    UnknownEntity,
} from '@embroker/shotwell/core/Error';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, Failure, Success, isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { addMinutes, format } from 'date-fns';
import { CalculateSKU } from '../../analytics/useCases/CalculateSKU';
import { GlobalConfigRepository } from '../../config/repositories/GlobalConfigRepository';
import { TasksRepository } from '../../tasks/repositories';
import { Application, ApplicationCreated } from '../entities/Application';
import { AnswerMissing, AppTypeNotAllowedForBroker, NoEligibleAppTypeFound } from '../errors';
import { ApplicationRepository } from '../repositories/ApplicationRepository';
import { FilledQuestionnaireData } from '../types/QuestionnaireData';
import { AppTypeCode } from '../types/enums';
import { UpdateApplicantFromBusinessProfile } from './UpdateApplicantFromBusinessProfile';

export interface CreateApplicationsRequest {
    questionnaireData: FilledQuestionnaireData;
    appTypeList: Immutable<AppTypeCode[]>;
    abortSignal: AbortSignal;
    maxPollingRetries?: number;
    pollingRetryIntervalInMilliseconds?: number;
    skipUserUpdate?: boolean;
}

export interface CreateApplicationsResponse {
    applicationIdList: UUID[];
    nameCleared: boolean;
}

export interface CreateApplications extends UseCase {
    execute(
        request: CreateApplicationsRequest,
    ): AsyncResult<
        CreateApplicationsResponse,
        | InvalidArgument
        | OperationFailed
        | UnknownEntity
        | Aborted
        | Timeout
        | AppTypeNotAllowedForBroker
        | NoEligibleAppTypeFound
        | AnswerMissing
    >;
}

export class CreateApplicationsUseCase extends UseCase implements CreateApplications {
    public static type = Symbol('Shopping/CreateApplications');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
        @inject(UpdateApplicantFromBusinessProfile.type)
        private updateApplicantFromBusinessProfile: UpdateApplicantFromBusinessProfile,
        @inject(CalculateSKU.type) private calculateSKU: CalculateSKU,
        @inject(Log) private logger: Logger,
        @inject(GlobalConfigRepository) private globalConfigRepository: GlobalConfigRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        questionnaireData,
        appTypeList,
        abortSignal,
        maxPollingRetries = 120,
        pollingRetryIntervalInMilliseconds = 1000,
        skipUserUpdate = false,
    }: CreateApplicationsRequest): AsyncResult<
        CreateApplicationsResponse,
        | InvalidArgument
        | OperationFailed
        | UnknownEntity
        | Aborted
        | Timeout
        | AppTypeNotAllowedForBroker
        | NoEligibleAppTypeFound
        | AnswerMissing
    > {
        //  This should never happen. The only reason that naics_code is possibly undefined
        // is because LawBundle application is missing that field from FilledQuestionnaireData
        if (!questionnaireData.naics_code) {
            return Failure(OperationFailed({ message: 'Missing naics code' }));
        }
        appendSignatureData(questionnaireData);

        if (!skipUserUpdate) {
            const updateApplicantFromBusinessProfileResponse =
                await this.updateApplicantFromBusinessProfile.execute({ questionnaireData });

            if (isErr(updateApplicantFromBusinessProfileResponse)) {
                return updateApplicantFromBusinessProfileResponse;
            }
        }

        let questionnaireDataStringify;
        try {
            questionnaireDataStringify = JSON.stringify(questionnaireData);
        } catch (error) {
            return Failure(OperationFailed({ message: 'Failed stringify questionnaire data' }));
        }

        let processedAppList = [...appTypeList];
        if (isTechEOInsteadOfCyber(questionnaireData, processedAppList)) {
            processedAppList = convertCyberToTechEO(processedAppList);
        }

        const globalConfigResult = await this.globalConfigRepository.getGlobalConfig();

        if (isErr(globalConfigResult)) {
            return Failure(OperationFailed({ message: 'Failed get global config' }));
        }

        const createApplicationsResult = await this.applicationRepository.createApplications({
            appTypeList: processedAppList,
            questionnaireData: questionnaireDataStringify,
        });

        if (isErr(createApplicationsResult)) {
            return createApplicationsResult;
        }

        const { taskId, applicationList, nameCleared } = createApplicationsResult.value;
        for (const application of applicationList) {
            if (application.creationType === 'InsuranceApplicationCreationTypeCodeListUnderlying') {
                continue;
            }
            await this.publishApplicationCreatedEvent(application);
        }

        const applicationIdList: UUID[] = applicationList.map((item) => item.id);

        const pollForTaskStatusResult = await this.tasksRepository.pollForTaskStatus(
            taskId,
            abortSignal,
            maxPollingRetries,
            pollingRetryIntervalInMilliseconds,
        );
        if (isErr(pollForTaskStatusResult)) {
            this.logger.warn(
                `Create Applications UseCase - Got error for task with id: ${taskId}`,
                pollForTaskStatusResult.errors,
            );
        } else if (!UUID.check(pollForTaskStatusResult.value)) {
            this.logger.warn(
                `Create Applications UseCase - Invalid response type for task with id: ${taskId}`,
                pollForTaskStatusResult,
            );
        }

        return Success<CreateApplicationsResponse>({
            applicationIdList: applicationIdList,
            nameCleared: nameCleared,
        });
    }

    private async publishApplicationCreatedEvent(application: Immutable<Application>) {
        const skuResult = await this.calculateSKU.execute({
            appType: application.appType,
            event: 'app_created',
            questionnaireData: application.questionnaireData ?? undefined,
            shoppingCoverageList: application.shoppingCoverageList,
            applicationId: application.id,
        });

        const eventData: ApplicationCreated = {
            origin: 'Application',
            name: 'ApplicationCreated',
            id: application.id,
            isRenewal: application.isRenewal(),
            sku: skuResult.value,
            createdAt: new Date(Date.now()),
        };
        await this.eventBus.publish(eventData);
    }
}

function isTechEOInsteadOfCyber(
    questionnaireData: FilledQuestionnaireData,
    appTypeList: AppTypeCode[],
) {
    return (
        appTypeList.includes('AppTypeCodeListEmbrokerCyber') &&
        questionnaireData.is_tech_eo_additional_question
    );
}

function convertCyberToTechEO(appTypeList: AppTypeCode[]) {
    const convertedAppTypeList = appTypeList.filter(
        (item) => item != 'AppTypeCodeListEmbrokerCyber',
    );
    convertedAppTypeList.push('AppTypeCodeListManualCyber', 'AppTypeCodeListManualDefaultPL');

    return convertedAppTypeList;
}

export function appendSignatureData(questionnaireData: FilledQuestionnaireData) {
    const { signatureLocalUtcOffset, signatureDate, signatureDateNow } = getSignatureData();

    questionnaireData.signature_local_utc_offset = signatureLocalUtcOffset;
    questionnaireData.signature_date_now = signatureDateNow;
    questionnaireData.signature_date = signatureDate;
}

export interface signatureData {
    signatureLocalUtcOffset: string;
    signatureDateNow: string;
    signatureDate: string;
}

export function getSignatureData(): signatureData {
    const now = new Date(Date.now());

    const signatureLocalUtcOffset = formatDateTime(now, {
        dateFormat: 'Z',
    });
    const signatureDateNow = formatDateTime(now, { utcOffset: -6 });
    const signatureDate = formatDateTime(now, {
        utcOffset: -6,
        dateFormat: 'yyyy-MM-dd',
    });
    return {
        signatureDate,
        signatureLocalUtcOffset,
        signatureDateNow,
    };
}

/**
 * Format date using provided format string (defaulting to ISO8601).
 * Timezone offset can be specified via utcOffset option and input date
 * will be converted from the local utc offset to the specified one.
 *
 * @param {Date|string|number} date
 * @param {object} options
 *    - format    Format string, defaults to 'yyyy-MM-dd'T'HH:mm:ss.SSSZ'
 *    - utcOffset UTC offset in hours (could be partial, like 1.5 for 01:30) defaults to local time offset
 */
function formatDateTime(
    date: Date,
    { dateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSSZ", utcOffset = -date.getTimezoneOffset() / 60 } = {},
) {
    const local = new Date(date);
    const dest = addMinutes(local, utcOffset * 60 + date.getTimezoneOffset());
    // N.B. we hijack Z and ZZ format specs by replacing them with destination timezone
    // before we pass the format string to the date-fns' format() function as it would
    // always use local timezone offset when formatting Z and ZZ
    return format(
        dest,
        dateFormat.replace(/Z{1,2}/, (formatSpec) => {
            const offsetHours = Math.floor(Math.abs(utcOffset));
            const offsetMinutes = (Math.abs(utcOffset) - offsetHours) * 60;
            return [
                `${utcOffset >= 0 ? '+' : '-'}${('0' + offsetHours).slice(-2)}`,
                ('0' + offsetMinutes).slice(-2),
            ].join(formatSpec === 'ZZ' ? '' : ':');
        }),
    );
}

export const CreateApplications: UseCaseClass<CreateApplications> = CreateApplicationsUseCase;
