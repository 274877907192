import { inject, injectable } from '@embroker/shotwell/core/di';
import { Aborted, InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { DocumentRepository } from '../repositories/DocumentRepository';

/**
 * Request data for DeleteFile use case
 */
export interface DeleteFileRequest {
    /**
     * Amazon S3 file key of the file that will be deleted
     */
    fileKey: string;
    applicationId?: UUID;
}

/**
 * DeleteFile use case
 */
export interface DeleteFile extends UseCase {
    execute(
        request: DeleteFileRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed | Aborted>;
}

@injectable()
export class DeleteFileUseCase extends UseCase implements DeleteFile {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Documents/DeleteFile');

    /**
     * Constructor for DeleteFile use case class instance.
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param sessionRepository Session repository used to retrieve organization id.
     * @param documentRepository Document repository used to store files.
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(DocumentRepository) private documentRepository: DocumentRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes DeleteFile use case
     * @param fileKey The file key of the document that will be deleted from the document repository
     * @param applicationId The id of the application that the document is linked to
     */
    async execute({
        fileKey,
        applicationId,
    }: DeleteFileRequest): AsyncResult<void, InvalidArgument | OperationFailed | Aborted> {
        return await this.documentRepository.deleteFile({
            fileKey,
            applicationId,
        });
    }
}

export const DeleteFile: UseCaseClass<DeleteFile> = DeleteFileUseCase;
