import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { CertificateCoverageInfo } from './CertificateCoverageInfo';

export interface CertificateCoverageGeneralLiability {
    coverageInfo: CertificateCoverageInfo;

    claimsMade?: boolean;
    occur?: boolean;
    aggregateLimitAppliesPerPolicy?: boolean;
    aggregateLimitAppliesPerProject?: boolean;
    aggregateLimitAppliesPerLoc?: boolean;
    aggregateLimitAppliesPerOther?: string;
}

export const CertificateCoverageGeneralLiability = {
    ...defineValidator<CertificateCoverageGeneralLiability>(
        Joi.object({
            coverageInfo: CertificateCoverageInfo.schema,
            claimsMade: Joi.boolean().optional(),
            occur: Joi.boolean().optional(),
            aggregateLimitAppliesPerPolicy: Joi.boolean().optional(),
            aggregateLimitAppliesPerProject: Joi.boolean().optional(),
            aggregateLimitAppliesPerLoc: Joi.boolean().optional(),
            aggregateLimitAppliesPerOther: Joi.string().optional().allow(''),
        }).preferences({ presence: 'required' }),
    ),
    create(certificateCoverageGeneralLiability: CertificateCoverageGeneralLiability) {
        return CertificateCoverageGeneralLiability.validate(certificateCoverageGeneralLiability);
    },
};
