import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Nullable } from '@embroker/shotwell/core/types';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { PhoneNumber } from '@embroker/shotwell/core/types/PhoneNumber';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ErrorCode, BrokerInvalidEmail, Unauthenticated } from '../errors';
import { BrokerRepository } from '../repositories/index';

/**
 * Request data for UpdateBrokerProfile use case
 * @param firstName is the new first name for the broker
 * @param lastName is the new last name for the broker
 */
export interface UpdateBrokerProfileRequest {
    firstName?: string;
    lastName?: string;
    title?: Nullable<string>;
    phoneNumber?: Nullable<PhoneNumber>;
    password?: Nullable<string>;
}

/**
 * UpdateBrokerProfile use case is used to update basic profile data for the broker
 */

export interface UpdateBrokerProfile extends UseCase {
    execute(
        request: UpdateBrokerProfileRequest,
    ): AsyncResult<
        void,
        Unauthenticated | UnknownEntity | OperationFailed | InvalidArgument | BrokerInvalidEmail
    >;
}

@injectable()
export class UpdateBrokerProfileUseCase extends UseCase {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/UpdateBrokerProfile');
    /**
     * Constructor for UpdateBrokerProfile use case class instance
     * @param eventBus An event bus this Use Case will publish events to.
     * @param brokerRepository is user repository used to store user entity with updated properties
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BrokerRepository) private brokerRepository: BrokerRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes UpdateBrokerProfile use case
     * Input is of type UpdateBrokerProfileRequest
     * @returns Nothing if execution was successful
     * @returns InvalidArgument error if updated broker entity contains invalid property
     * @returns OperationFailed error if repo failed to execute request
     */
    public async execute(
        data: UpdateBrokerProfileRequest,
    ): AsyncResult<
        void,
        Unauthenticated | UnknownEntity | OperationFailed | InvalidArgument | BrokerInvalidEmail
    > {
        const userResult = await this.brokerRepository.getActiveBroker();

        if (isErr(userResult)) {
            return userResult;
        }

        data.title = data.title === undefined ? null : data.title;
        data.phoneNumber = data.phoneNumber === undefined ? null : data.phoneNumber;
        data.password = data.password === undefined ? null : data.password;
        userResult.value.update(data);

        const result = await this.brokerRepository.save(userResult.value);
        if (isErr(result)) {
            for (const error of result.errors) {
                if (error.code === ErrorCode.BrokerInvalidEmail) {
                    return Failure(error);
                }
            }
            return handleOperationFailure(result);
        }

        this.eventBus.publishEntityEvents(userResult.value);
        return Success();
    }
}

export const UpdateBrokerProfile: UseCaseClass<UpdateBrokerProfile> = UpdateBrokerProfileUseCase;
