import { State } from '@embroker/shotwell/core/types/StateList';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

type NAICSDefaults = string[];

type StateDefaults = Record<string, NAICSDefaults>;
const StateDefaults = {
    ...defineValidator<StateDefaults>(
        Joi.object().pattern(Joi.string(), Joi.array().items(Joi.string())),
    ),
};

export type DefaultClassCodes = Record<State, StateDefaults>;
export const DefaultClassCodes = {
    ...defineValidator<DefaultClassCodes>(Joi.object().pattern(State.schema, StateDefaults.schema)),
    create(classCode: DefaultClassCodes) {
        return DefaultClassCodes.validate(classCode);
    },
};

type EntityOfficerInclusion = Record<string, boolean>;
const EntityOfficerInclusion = {
    ...defineValidator<EntityOfficerInclusion>(Joi.object().pattern(Joi.string(), Joi.bool())),
};

export type OfficerInclusion = Record<State, EntityOfficerInclusion>;
export const OfficerInclusion = {
    ...defineValidator<OfficerInclusion>(
        Joi.object().pattern(State.schema, EntityOfficerInclusion.schema),
    ),
    create(inclusion: OfficerInclusion) {
        return OfficerInclusion.validate(inclusion);
    },
};

export type NAICSCode = string;
