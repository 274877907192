import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { AsyncResult, Failure, Success, isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { CalculateSKU } from '../../analytics/useCases/CalculateSKU';
import { TasksRepository } from '../../tasks/repositories';
import { ApplicationRepository } from '../repositories/ApplicationRepository';
import {
    AppTypeNotAllowedForBroker,
    NoAppTypeForGenericBundle,
    NoEligibleAppTypeFound,
} from '../errors';
import { TechCoverageTypes } from '../view/components/techVerticalProductSelection/TechVerticalProductSelectionPage';
import { AppTypeCode, AppTypeCodeListESP } from '../types/enums';
import { ApplicationCreated } from '../entities/Application';

export interface CreateGenericBundleApplicationRequest {
    abortSignal: AbortSignal;
    maxPollingRetries?: number;
    pollingRetryIntervalInMilliseconds?: number;
    selectedCoverages: TechCoverageTypes[];
}

export interface CreateGenericBundleApplicationResponse {
    bundleApplicationId: UUID;
}

type CreateGenericBundleApplicationResult = AsyncResult<
    CreateGenericBundleApplicationResponse,
    | InvalidArgument
    | OperationFailed
    | UnknownEntity
    | AppTypeNotAllowedForBroker
    | NoEligibleAppTypeFound
    | NoAppTypeForGenericBundle
>;

export interface CreateGenericBundleApplication extends UseCase {
    execute(request: CreateGenericBundleApplicationRequest): CreateGenericBundleApplicationResult;
}

export class CreateGenericBundleApplicationUseCase
    extends UseCase
    implements CreateGenericBundleApplication
{
    public static type = Symbol('Shopping/CreateGenericBundleApplication');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
        @inject(CalculateSKU.type)
        private calculateSKU: CalculateSKU, // TODO calculate sku
        @inject(Log) private logger: Logger,
    ) {
        super(eventBus);
    }

    public async execute({
        selectedCoverages,
        abortSignal,
        maxPollingRetries,
        pollingRetryIntervalInMilliseconds,
    }: CreateGenericBundleApplicationRequest): CreateGenericBundleApplicationResult {
        const configResponse = await this.applicationRepository.getConfig();
        if (isErr(configResponse)) {
            return configResponse;
        }

        const appTypeList = getAppTypesFromSelectedCoverages(selectedCoverages);

        if (!appTypeList.length) {
            return Failure(NoAppTypeForGenericBundle());
        }

        const createApplicationsResult = await this.applicationRepository.createApplications({
            appTypeList: getAppTypesFromSelectedCoverages(selectedCoverages),
            questionnaireData: JSON.stringify({}),
            createGenericBundle: true,
        });

        if (isErr(createApplicationsResult)) {
            return createApplicationsResult;
        }

        const { taskId } = createApplicationsResult.value;

        const pollForTaskStatusResult = await this.tasksRepository.pollForTaskStatus(
            taskId,
            abortSignal,
            maxPollingRetries,
            pollingRetryIntervalInMilliseconds,
        );
        if (isErr(pollForTaskStatusResult)) {
            this.logger.warn(
                `Create Applications UseCase - Got error for task with id: ${taskId}`,
                pollForTaskStatusResult.errors,
            );
        } else if (!UUID.check(pollForTaskStatusResult.value)) {
            this.logger.warn(
                `Create Applications UseCase - Invalid response type for task with id: ${taskId}`,
                pollForTaskStatusResult,
            );
        }

        const { applicationList } = createApplicationsResult.value;

        const skuResult = await this.calculateSKU.execute({
            event: 'app_created',
            appType: 'AppTypeCodeListGenericBundle',
            shoppingCoverageList: applicationList[0].shoppingCoverageList,
            applicationId: applicationList[0].id,
        });
        const eventData: ApplicationCreated = {
            origin: 'Application',
            name: 'ApplicationCreated',
            id: applicationList[0].id,
            isRenewal: applicationList[0].isRenewal(),
            sku: skuResult.value,
            createdAt: new Date(Date.now()),
        };
        this.eventBus.publish(eventData);

        const bundleApplicationId = applicationList[0].id; // Bundle application is the 0th element

        return Success<CreateGenericBundleApplicationResponse>({
            bundleApplicationId,
        });
    }
}

export const CreateGenericBundleApplication: UseCaseClass<CreateGenericBundleApplication> =
    CreateGenericBundleApplicationUseCase;

export function getAppTypesFromSelectedCoverages(
    selectedCoverages: TechCoverageTypes[],
): AppTypeCode[] {
    const techCoverages: TechCoverageTypes[] = [
        'techEO',
        'directorsAndOfficers',
        'employmentPractices',
        'fiduciary',
    ];
    if (techCoverages.some((coverage: TechCoverageTypes) => selectedCoverages.includes(coverage))) {
        return [AppTypeCodeListESP];
    }
    return [];
}
