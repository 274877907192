import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { execute, UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { DocGenFailed } from '../../errors';
import { DocumentType } from '../../types/Document';
import { LPLQuote } from '../entities/LPLQuote';
import { CreateLPLDocument } from './CreateLPLDocument';

interface DownloadLPLSpecimenPolicyRequest {
    quote: LPLQuote;
    abortSignal: AbortSignal;
}

interface DownloadLPLSpecimenPolicyResponse {
    quote: Immutable<LPLQuote>;
    fileUrl: URI;
}

export interface DownloadLPLSpecimenPolicy extends UseCase {
    execute(
        request: DownloadLPLSpecimenPolicyRequest,
    ): AsyncResult<DownloadLPLSpecimenPolicyResponse, DocGenFailed>;
}

@injectable()
class DownloadLPLSpecimenPolicyUseCase extends UseCase implements DownloadLPLSpecimenPolicy {
    public static type = Symbol('LPLQuote/DownloadLPLSpecimenPolicy');

    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    public async execute({
        quote,
        abortSignal,
    }: DownloadLPLSpecimenPolicyRequest): AsyncResult<
        DownloadLPLSpecimenPolicyResponse,
        DocGenFailed
    > {
        const createDocumentResult = await execute(CreateLPLDocument, {
            applicationId: quote.applicationId,
            quoteId: quote.id,
            documentType: DocumentType.SpecimenPolicy,
            fileKey: quote.details.specimenPolicyFileKey,
            abortSignal: abortSignal,
        });
        if (isErr(createDocumentResult)) {
            return Failure(DocGenFailed({ errors: createDocumentResult.errors }));
        }

        quote.assignSpecimenPolicyFileKey(createDocumentResult.value.fileKey);

        return Success<DownloadLPLSpecimenPolicyResponse>({
            quote: quote,
            fileUrl: createDocumentResult.value.fileUrl,
        });
    }
}

export const DownloadLPLSpecimenPolicy: UseCaseClass<DownloadLPLSpecimenPolicy> =
    DownloadLPLSpecimenPolicyUseCase;
