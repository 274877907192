import {
    LineOfBusinessCodeListItem,
    PolicyViewStatusCodeListItem,
} from '@embroker/shotwell-api/enums';
import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Nullable } from '@embroker/shotwell/core/types';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { ColumnLayout, StackLayout, Text, TextButton, useModal } from '@embroker/ui-toolkit/v2';
import React, { useState } from 'react';
import { useEnumGroup } from '../../../../serverEnums/view/hooks/useEnumGroup';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { PolicyIds } from '../../../types/UmbrellaExcessLiabilitySection';
import { GetPolicy, GetPolicyRequest } from '../../../useCases/GetPolicy';
import { PolicyInTheWorksModal } from '../../modals/PolicyInTheWorks';

interface UnderlyingAgreementsProps {
    underlyingAgreementsList: Nullable<PolicyIds[]>;
    lineOfBusiness: LineOfBusinessCodeListItem;
}

interface UnderlyingAgreementData {
    policyName: string;
    insurerName: string;
    policyId: UUID;
    viewMode: PolicyViewStatusCodeListItem;
}

export const UnderlyingAgreements = (props: UnderlyingAgreementsProps) => {
    const navigation = useNavigation();

    const [underlyingAgreementList, setUnderlyingAgreementList] = useState(
        [] as UnderlyingAgreementData[],
    );
    const { result: lineOfBusinessCodeList } = useEnumGroup('LineOfBusinessCodeList');
    const modalPolicyInTheWorks = useModal();

    if (
        lineOfBusinessCodeList === undefined ||
        props.underlyingAgreementsList === null ||
        props.underlyingAgreementsList.length < 1
    ) {
        return null;
    }

    if (underlyingAgreementList.length === 0) {
        const promises = props.underlyingAgreementsList.map(async (item) => {
            const getPolicyResult = await execute(GetPolicy, {
                policyId: item.id,
            } as GetPolicyRequest);
            return getPolicyResult;
        });
        Promise.all(promises)
            .then((result) => {
                const tempArray: UnderlyingAgreementData[] = [] as UnderlyingAgreementData[];
                result.forEach((item) => {
                    if (item !== undefined && isOK(item)) {
                        const underlyingAgreement: UnderlyingAgreementData = {
                            insurerName: item.value.policy.insurerName,
                            policyId: item.value.policy.id,
                            policyName: item.value.policy.name,
                            viewMode: item.value.policy.viewMode,
                        };
                        tempArray.push(underlyingAgreement);
                    }
                });
                setUnderlyingAgreementList(tempArray);
            })
            .catch((error) => {
                container
                    .get<Logger>(Log)
                    .error(`Failed to load underlying policy/policies ${error}`);
                return null;
            });
    }

    function handleViewClicked(underlyingAgreement: UnderlyingAgreementData) {
        if (underlyingAgreement.viewMode === 'PolicyViewStatusCodeListDraft') {
            modalPolicyInTheWorks.show();
        } else {
            navigation.navigate(
                URI.build('/policies/detail', {
                    policyId: underlyingAgreement.policyId,
                }),
            );
        }
    }

    const underlyingAgreements = (
        <StackLayout gap="8">
            {underlyingAgreementList.map((underlyingAgreement) => {
                return (
                    <ColumnLayout key={underlyingAgreement.policyId}>
                        <Text style="label 1">
                            {underlyingAgreement.policyName} covered by{' '}
                            {underlyingAgreement.insurerName}
                        </Text>
                        <TextButton onClick={() => handleViewClicked(underlyingAgreement)}>
                            View Policy
                        </TextButton>
                    </ColumnLayout>
                );
            })}
        </StackLayout>
    );

    function hidePolicyInTheWorksModal() {
        modalPolicyInTheWorks.hide();
    }

    return (
        <StackLayout gap="12">
            <PolicyInTheWorksModal
                hideModal={hidePolicyInTheWorksModal}
                modal={modalPolicyInTheWorks}
            />
            {underlyingAgreementList.length > 0 ? (
                <React.Fragment>
                    <Text style="body 2">
                        The {lineOfBusinessCodeList.get(props.lineOfBusiness)} Policy supplements
                        coverages provided by the following policies:
                    </Text>
                    {underlyingAgreements}
                </React.Fragment>
            ) : null}
        </StackLayout>
    );
};

export default UnderlyingAgreements;
