import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';

const ClickEventNameList = [
    'New business Return visitor Start Onboarding Pop up Displayed',
    'New business Return visitor Start Onboarding Pop up _ continue clicked',
    'New business Return visitor Start Onboarding Pop up closed',
    'New business Return visitor Continue Onboarding Pop up Displayed',
    'New business Return visitor Continue Onboarding Pop up _ continue clicked',
    'New business Return visitor Continue Onboarding Pop up closed',
    'New business Return visitor Product Recommendation Pop up Displayed',
    'New business Return visitor Product Recommendation Pop up _ continue clicked',
    'New business Return visitor Product Recommendation Pop up closed',
] as const;
type OnboardingWelcomeBackPopupClickEventNameList = typeof ClickEventNameList;
export type OnboardingWelcomeBackPopupClickEventName =
    OnboardingWelcomeBackPopupClickEventNameList[number];

export enum OnboardingWelcomeBackPopupEventType {
    startOnboardingPopupDisplayed = 'startOnboardingPopupDisplayed',
    startOnboardingPopupContinueClicked = 'startOnboardingPopupContinueClicked',
    startOnboardingPopupClosed = 'startOnboardingPopupClosed',
    continueOnboardingPopupDisplayed = 'continueOnboardingPopupDisplayed',
    continueOnboardingPopupContinueClicked = 'continueOnboardingPopupContinueClicked',
    continueOnboardingPopupClosed = 'continueOnboardingPopupClosed',
    productRecommendationPopupDisplayed = 'productRecommendationPopupDisplayed',
    productRecommendationPopupContinueClicked = 'productRecommendationPopupContinueClicked',
    productRecommendationPopupClosed = 'productRecommendationPopupClosed',
}

export interface OnboardingWelcomeBackPopupEvent extends DomainEvent {
    name: 'OnboardingWelcomeBackPopupEvent';
    clickEventName: OnboardingWelcomeBackPopupClickEventName;
}

export interface PublishOnboardingWelcomeBackPopupEvent extends UseCase {
    execute(request: PublishOnboardingWelcomeBackPopupEventRequest): AsyncResult<void>;
}

export interface PublishOnboardingWelcomeBackPopupEventRequest {
    eventType: OnboardingWelcomeBackPopupEventType;
}

@injectable()
class PublishOnboardingWelcomeBackPopupEventUseCase
    extends UseCase
    implements PublishOnboardingWelcomeBackPopupEvent
{
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/PublishOnboardingWelcomeBackPopupEventUseCase');

    /**
     * Constructor for PublishOnboardingWelcomeBackPopupEvent class instance
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    /**
     * Executes the PublishOnboardingWelcomeBackPopupEvent use case.
     */
    public async execute({
        eventType,
    }: PublishOnboardingWelcomeBackPopupEventRequest): AsyncResult<void> {
        let clickEventName: OnboardingWelcomeBackPopupClickEventName | null = null;

        switch (eventType) {
            case OnboardingWelcomeBackPopupEventType.startOnboardingPopupDisplayed: {
                clickEventName = 'New business Return visitor Start Onboarding Pop up Displayed';
                break;
            }

            case OnboardingWelcomeBackPopupEventType.startOnboardingPopupContinueClicked: {
                clickEventName =
                    'New business Return visitor Start Onboarding Pop up _ continue clicked';
                break;
            }

            case OnboardingWelcomeBackPopupEventType.startOnboardingPopupClosed: {
                clickEventName = 'New business Return visitor Start Onboarding Pop up closed';
                break;
            }

            case OnboardingWelcomeBackPopupEventType.continueOnboardingPopupDisplayed: {
                clickEventName = 'New business Return visitor Continue Onboarding Pop up Displayed';
                break;
            }

            case OnboardingWelcomeBackPopupEventType.continueOnboardingPopupContinueClicked: {
                clickEventName =
                    'New business Return visitor Continue Onboarding Pop up _ continue clicked';
                break;
            }

            case OnboardingWelcomeBackPopupEventType.continueOnboardingPopupClosed: {
                clickEventName = 'New business Return visitor Continue Onboarding Pop up closed';
                break;
            }

            case OnboardingWelcomeBackPopupEventType.productRecommendationPopupDisplayed: {
                clickEventName =
                    'New business Return visitor Product Recommendation Pop up Displayed';
                break;
            }

            case OnboardingWelcomeBackPopupEventType.productRecommendationPopupContinueClicked: {
                clickEventName =
                    'New business Return visitor Product Recommendation Pop up _ continue clicked';
                break;
            }

            case OnboardingWelcomeBackPopupEventType.productRecommendationPopupClosed: {
                clickEventName = 'New business Return visitor Product Recommendation Pop up closed';
                break;
            }
        }

        if (clickEventName) {
            const event: OnboardingWelcomeBackPopupEvent = {
                origin: 'User',
                name: 'OnboardingWelcomeBackPopupEvent',
                clickEventName,
                createdAt: new Date(Date.now()),
                id: UUID.create(),
            };
            await this.eventBus.publish(event);
        }

        return Success();
    }
}

export const PublishOnboardingWelcomeBackPopupEvent: UseCaseClass<PublishOnboardingWelcomeBackPopupEvent> =
    PublishOnboardingWelcomeBackPopupEventUseCase;
