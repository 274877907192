import { BundleQuote } from '@app/bundle/entities/BundleQuote';
import { QuestionnaireData } from '@app/bundle/types/BundleQuoteQuestionnaireData';
import { CoveragePackageType } from '@app/bundle/useCases/GetRecommendedCoverageQuotes';
import { BOPChubbQuoteOptions } from './types/BOPChubbQuoteOptions';

export const getRecommendedQuotingOptionsBopChubb = (
    bundleQuote: BundleQuote,
    packageType: CoveragePackageType,
    questionnaireData: QuestionnaireData,
): BOPChubbQuoteOptions | undefined => {
    const isDeselected = !bundleQuote.isCoverageSelected('BOPChubbCoverage');
    switch (packageType) {
        case 'starter': {
            return {
                effectiveDate: bundleQuote.effectiveDate,
                perOccurrenceLimit: 1000000,
                aggregateLimit: 2000000,
                productCode: 'GeneralLiability',
                omitHiredAndNonOwnedAutoCoverage: false,
                isDeselected,
            };
        }
        case 'enhanced': {
            return {
                effectiveDate: bundleQuote.effectiveDate,
                perOccurrenceLimit: 1000000,
                aggregateLimit: 2000000,
                productCode: 'GeneralLiability',
                omitHiredAndNonOwnedAutoCoverage: false,
                isDeselected,
            };
        }
        case 'deluxe': {
            const perOccurrenceLimit = questionnaireData.other_company_locations
                ? 2000000
                : 1000000;
            const aggregateLimit = questionnaireData.other_company_locations ? 4000000 : 2000000;
            return {
                effectiveDate: bundleQuote.effectiveDate,
                perOccurrenceLimit,
                aggregateLimit,
                productCode: 'GeneralLiability',
                omitHiredAndNonOwnedAutoCoverage: false,
                isDeselected,
            };
        }
    }
};
