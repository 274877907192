import { isOK } from '@embroker/shotwell/core/types/Result';
import { OracleAnswerTypeDefinition } from '../OracleAnswerType';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { FundraisingRound } from '@app/view/components/DataDrivenForm/components/complexFormFieldViews/FundraisingRoundFormFieldView.view';
import { OracleCurrency, oracleCurrencySchema } from './currency';
import { createDateFromOracleDate, createOracleDateFromDate, OracleDate } from './date';

export type OracleFundraisingRound = {
    date: OracleDate;
    amount_raised: OracleCurrency;
    lead_investor: string;
};

export const OracleFundraisingRound = {
    ...defineValidator<OracleFundraisingRound>({
        date: OracleDate.schema,
        amount_raised: oracleCurrencySchema,
        lead_investor: Joi.string().required(),
    }),
    create(fundraisingRound: OracleFundraisingRound) {
        return OracleFundraisingRound.validate(fundraisingRound);
    },
};

export const serializeFundraisingRoundAnswer = (
    formData: unknown,
): OracleFundraisingRound | undefined => {
    if (!assertFundraisingRoundType(formData)) {
        return undefined;
    }
    const fundraisingRound = OracleFundraisingRound.create({
        date: createOracleDateFromDate(formData.fundraiseDate as Date),
        amount_raised: {
            currency_code: 'USD',
            unit_amount: parseFloat(formData.moneyRaised),
        },
        lead_investor: formData.leadInvestor,
    });
    if (isOK(fundraisingRound)) {
        return fundraisingRound.value;
    }
};

export function assertFundraisingRoundType(input: any): input is FundraisingRound {
    return 'fundraiseDate' in input && 'moneyRaised' in input && 'leadInvestor' in input;
}
export function assertOracleFundraisingRoundType(input: any): input is OracleFundraisingRound {
    return 'date' in input && 'amount_raised' in input && 'lead_investor' in input;
}
export const deserializeFundraisingRoundAnswers = (currentValue: any): unknown[] | undefined => {
    if (!Array.isArray(currentValue)) {
        return undefined;
    }

    return (currentValue as FundraisingRound[]).reduce((accumulator, current) => {
        if (assertOracleFundraisingRoundType(current)) {
            accumulator.push({
                fundraiseDate: createDateFromOracleDate(current.date),
                moneyRaised: current.amount_raised.unit_amount.toString(),
                leadInvestor: current.lead_investor,
            });
        }
        return accumulator;
    }, [] as FundraisingRound[]);
};

export const FundraisingRoundDefinition: OracleAnswerTypeDefinition = {
    answerKeyType: 'funding_round',
    schemaFunctions: {
        validator: OracleFundraisingRound.schema,
        serializeAnswer: serializeFundraisingRoundAnswer,
        deserializeAnswer: deserializeFundraisingRoundAnswers,
    },
};
