import { Joi, defineValidator } from '@embroker/shotwell/core/validation/schema';
import { AppContextStore } from '../../view/AppContext';

export type MPLValidNaicsCodeKeys =
    | '541211'
    | '541213'
    | '531210'
    | '541350'
    | '541611'
    | '541612'
    | '541613'
    | '541614'
    | '541618'
    | '541620';

export const MPLValidNaicsCodeVerticalText: Record<MPLValidNaicsCodeKeys, string> = {
    '541211': 'accountants',
    '541213': 'tax preparers/bookkeepers',
    '531210': 'real estate agents/brokers',
    '541350': 'home inspectors',
    '541611': 'consultants',
    '541612': 'consultants',
    '541613': 'consultants',
    '541614': 'consultants',
    '541618': 'consultants',
    '541620': 'consultants',
};

export const MPLValidNaicsCodes = {
    ...defineValidator<MPLValidNaicsCodeKeys[]>(Joi.array()),
    create(MPLValidNaicsCodeKeys: MPLValidNaicsCodeKeys[]) {
        return MPLValidNaicsCodes.validate(MPLValidNaicsCodeKeys);
    },
    isNaicCodeValid(naicsCode: string | null): boolean {
        return Boolean(naicsCode && Object.keys(MPLValidNaicsCodeVerticalText).includes(naicsCode));
    },
    isMPLVerticalEnabled(naicsCode: string | null): boolean {
        if (!AppContextStore.context.globalConfig) {
            return false;
        }
        const {
            isHomeInspectorsMplVerticalEnabled,
            isMplEnabled,
            isRealEstateAgentsMplVerticalEnabled,
        } = AppContextStore.context.globalConfig;

        const vertical = MPLValidNaicsCodeVerticalText[naicsCode as MPLValidNaicsCodeKeys];
        // using 'as' here is not ideal, however, if naicsCode is not actually of type MPLValidNaicsCodeKeys, the logic below will still hold
        if (vertical === 'home inspectors' && !isHomeInspectorsMplVerticalEnabled) {
            return false;
        }

        if (vertical === 'real estate agents/brokers' && !isRealEstateAgentsMplVerticalEnabled) {
            return false;
        }

        return Boolean(isMplEnabled);
    },
    getVerticalText(naics: string): string {
        return MPLValidNaicsCodeVerticalText[naics as MPLValidNaicsCodeKeys];
    },
};
