import { Immutable } from '@embroker/shotwell/core/types';
import { useState, useCallback } from 'react';

export interface getTablePageDataParams<T> {
    itemsPerPage: number;
    pageIndex: number;
    column?: T;
    order?: 'ASC' | 'DESC';
    filters?: {
        target: T;
        value: string;
    }[];
}

export interface getTablePageDataResponse<T, U> {
    items: Immutable<T>[];
    totalItems: number;
    additionalData: U;
}

export function useTableDataSource<
    T extends string,
    U extends Record<string, unknown>,
    V extends Record<string, unknown>,
>(
    getTablePageData: (
        params: getTablePageDataParams<T>,
    ) => Promise<getTablePageDataResponse<U, V>>,
) {
    const [additionalData, setAdditionalData] = useState<V>();

    const fetchPageData = useCallback(
        async (params) => {
            const { additionalData, items, totalItems } = await getTablePageData(params);
            setAdditionalData(additionalData);
            return {
                items,
                totalItems,
            };
        },
        [getTablePageData, setAdditionalData],
    );

    return {
        additionalData,
        fetchPageData,
    };
}
