import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { PCoMLEndorsementPolicy } from '../../types/PCoMLEndorsementPolicy';
import { Money } from '@embroker/shotwell/core/types/Money';
import { PCoMLEndorsementLiabilityCoverageType } from '../../types/PCoMLEndorsementLiabilityCoverageType';
import { State } from '@embroker/shotwell/core/types/StateList';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import { PCoMLEndorsementUserData } from '../../types/PCoMLEndorsementUserData';
import { PCoMLEndorsementConfig } from '../../types/PCoMLEndorsementConfig';

export interface RateEndorsementRequest {
    policyId: UUID;
    endorsementType: string;
    effectiveDate: Date;
    limitEndorsement: {
        coverage: PCoMLEndorsementLiabilityCoverageType;
        limit: Money;
        retention: Money;
    };
}

export interface CreateEndorsementRequest {
    policyId: UUID;
    endorsementType: string;
    effectiveDate: Date;
    limitEndorsement: {
        coverage?: PCoMLEndorsementLiabilityCoverageType;
        limit: Money;
        retention: Money;
    };
    addressEndorsement: {
        addressLine1: string;
        addressLine2?: string;
        city: string;
        state?: State;
        zipCode: ZipCode;
    };
    namedInsuredEndorsement: {
        namedInsured?: string;
    };
}

export interface PCoMLEndorsementRepository {
    getPolicy(
        policyId: UUID,
    ): AsyncResult<PCoMLEndorsementPolicy, InvalidArgument | OperationFailed>;
    rateEndorsement(
        rateEndorsementRequest: RateEndorsementRequest,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed>;
    createEndorsement(
        createEndorsementRequest: CreateEndorsementRequest,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed>;
    loadUserData(): AsyncResult<PCoMLEndorsementUserData, InvalidArgument | OperationFailed>;
    getPCoMLEndorsementConfig(): AsyncResult<
        PCoMLEndorsementConfig,
        InvalidArgument | OperationFailed
    >;
}

export const PCoMLEndorsementRepository = Symbol('PCoMLEndorsementRepository');
