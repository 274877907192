import { isOK } from '@embroker/shotwell/core/types/Result';
import { execute } from '@embroker/shotwell/core/UseCase';
import { useReducer, useState, useCallback, useEffect } from 'react';
import { NotificationViewModel } from '../../entities/Notification';
import { NotificationStatus } from '@embroker/shotwell-api/v2/app.spec';
import { UpdateNotificationStatus as UpdateNotificationStatusUseCase } from '../../useCases/UpdateNotificationStatus';
import { NotificationList } from '../../types/NotificationList';
import { PaginationState, PaginationActions, usePagination } from '@embroker/ui-toolkit/v2';
import { UpdateAllUserNotificationsStatus } from '../../useCases/UpdateAllUserNotificationsStatus';

interface NotificationCenterState {
    allNotifications: NotificationViewModel[] | undefined;
    pagination: PaginationState;
}

const initialState: NotificationCenterState = {
    allNotifications: undefined,
    pagination: { totalPages: 0, currentPageIndex: 0 },
};

const ActionTypesEnum = {
    setPaginationCurrentPage: 'setPaginationCurrentPage',
    updateNotificationStatus: 'updateNotificationStatus',
    updateAllNotificationStatus: 'updateAllNotificationStatus',
    setAllNotifications: 'setAllNotifications',
} as const;

type UpdateNotificationStatus = {
    type: typeof ActionTypesEnum.updateNotificationStatus;
    payload: { notification: NotificationViewModel; statusUpdate: NotificationStatus };
};
type UpdateAllNotificationStatus = {
    type: typeof ActionTypesEnum.updateAllNotificationStatus;
    payload: { statusUpdate: NotificationStatus };
};

type SetAllNotifications = {
    type: typeof ActionTypesEnum.setAllNotifications;
    payload: { notifications: NotificationViewModel[] };
};

type ActionTypes = UpdateNotificationStatus | UpdateAllNotificationStatus | SetAllNotifications;

function reducer(state: NotificationCenterState, action: ActionTypes) {
    // if state.allNotifications is undefined then set as empty array
    const { allNotifications = [] } = state;
    switch (action.type) {
        case ActionTypesEnum.setAllNotifications: {
            const { notifications } = action.payload;
            return { ...state, allNotifications: notifications };
        }
        case ActionTypesEnum.updateNotificationStatus: {
            const { notification, statusUpdate } = action.payload;
            const { id } = notification;
            const itemIndex = allNotifications.findIndex(
                (item: NotificationViewModel) => item.id === id,
            );
            const updatedNotifications = allNotifications
                .slice(0, itemIndex)
                .concat(
                    [{ ...allNotifications[itemIndex], status: statusUpdate }],
                    allNotifications.slice(itemIndex + 1),
                );
            return {
                ...state,
                allNotifications: updatedNotifications,
            };
        }
        case ActionTypesEnum.updateAllNotificationStatus: {
            const { statusUpdate } = action.payload;

            const updatedNotifications = allNotifications.map(
                (notification: NotificationViewModel) => ({
                    ...notification,
                    status: statusUpdate,
                }),
            );
            return {
                ...state,
                allNotifications: updatedNotifications,
            };
        }
        default:
            return initialState;
    }
}

export interface NotificationsContext {
    pageNotifications: NotificationViewModel[];
    isNotificationLoadingMap: { [key: string]: boolean };
    isAllNotificationsLoading: boolean;
    pagination: PaginationState & PaginationActions;
    unreadNotificationCount: number;
    updateAllNotificationsStatus: (statusUpdate: NotificationStatus) => void;
    updateNotificationStatus: (
        notification: NotificationViewModel,
        statusUpdate: NotificationStatus,
    ) => void;
}

export function useNotificationCenterState(
    notifications: NotificationViewModel[],
): NotificationsContext {
    const [isAllNotificationsLoading, setIsAllNotificationsLoading] = useState<boolean>(false);
    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
    });

    const setAllNotifications = useCallback((notifications: NotificationViewModel[]) => {
        dispatch({
            type: ActionTypesEnum.setAllNotifications,
            payload: { notifications },
        });
    }, []);

    useEffect(() => {
        const setInitialNotifications =
            notifications.length > 0 && state.allNotifications === undefined;

        if (setInitialNotifications) {
            setAllNotifications(notifications);
        }
    }, [notifications, state.allNotifications, setAllNotifications]);

    const [isNotificationLoadingMap, setIsNotificationLoadingMap] = useState<{
        [key: string]: boolean;
    }>({});

    const { allNotifications = [] } = state;
    const activeNotifications = allNotifications.filter(
        (notification: NotificationViewModel) => notification.status !== 'dismissed',
    );

    const pagination = usePagination({
        totalPages: NotificationList.getPageCount(activeNotifications),
    });

    const pageNotifications = NotificationList.getPage(
        activeNotifications,
        pagination.currentPageIndex,
    );

    const unreadNotificationCount = NotificationList.getUnreadCount(allNotifications);

    const toggleNotificationIsLoading = useCallback((notification: NotificationViewModel) => {
        setIsNotificationLoadingMap((prevState) => {
            return {
                ...prevState,
                [notification.id]: !prevState[notification.id],
            };
        });
    }, []);

    const updateNotificationStatus = useCallback(
        async (notification: NotificationViewModel, statusUpdate: NotificationStatus) => {
            toggleNotificationIsLoading(notification);

            const updatedNotification = await execute(UpdateNotificationStatusUseCase, {
                notification,
                status: statusUpdate,
            });

            if (isOK(updatedNotification)) {
                const { value: respNotification } = updatedNotification;

                dispatch({
                    type: ActionTypesEnum.updateNotificationStatus,
                    payload: { notification: respNotification, statusUpdate },
                });
            }

            toggleNotificationIsLoading(notification);
        },
        [toggleNotificationIsLoading],
    );

    const updateAllNotificationsStatus = useCallback(
        async (statusUpdate: NotificationStatus) => {
            if (unreadNotificationCount === 0) return;
            setIsAllNotificationsLoading(true);
            const resp = await execute(UpdateAllUserNotificationsStatus, { status: 'read' });

            if (isOK(resp)) {
                dispatch({
                    type: ActionTypesEnum.updateAllNotificationStatus,
                    payload: { statusUpdate },
                });
            }

            setIsAllNotificationsLoading(false);
        },
        [unreadNotificationCount],
    );

    return {
        isNotificationLoadingMap,
        isAllNotificationsLoading,
        pageNotifications,
        pagination,
        unreadNotificationCount,
        updateNotificationStatus,
        updateAllNotificationsStatus,
    };
}
