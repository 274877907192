import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { AsyncResult, SuccessResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Session } from '../../entities/Session';
import {
    ExpiredTokenError,
    FailedToRetrieveSessionError,
    InactiveAccount,
    InvalidTokenError,
    NoAccount,
    WrongUsernamePasswordPair,
} from '../../errors';
import { UserStatusCode } from '../../types/enums';

export interface SessionCreateParams {
    username: string;
    password: string;
}

export interface LoginByBundleTokenResponse {
    session: Immutable<Session>;
    bundleApplicationId?: UUID;
}

/**
 * This is the repository used to create, update and delete sessions on the platform
 */
export interface SessionRepository {
    /**
     * Creates a session entity for provided username and password
     * @returns Session entity if provided username and password match existing user
     * @returns InvalidArgument if provided username does not exist on platform
     * @returns InvalidArgument if user exists but password is does not match
     * @returns OperationFailed if session could not be created for some other reason
     */
    create(
        params: SessionCreateParams,
    ): AsyncResult<
        Session,
        InvalidArgument | OperationFailed | WrongUsernamePasswordPair | InactiveAccount
    >;

    loginByBundleToken(
        token: string,
    ): AsyncResult<
        LoginByBundleTokenResponse,
        InvalidArgument | InvalidTokenError | ExpiredTokenError | FailedToRetrieveSessionError
    >;

    loginByStreamlineRenewalProspectToken(
        token: string,
    ): AsyncResult<
        Immutable<Session>,
        InvalidTokenError | ExpiredTokenError | FailedToRetrieveSessionError
    >;

    /**
     * Returns the currently active session.
     *
     * Can return an unauthenticated Session (if the user is not authenticated).
     */
    getActiveSession(): Promise<SuccessResult<Nullable<Immutable<Session>>>>;

    /**
     * Saves the provided session entity. This method can be used to:
     * 1. Extend current active session
     * 2. Change impersonated user
     * 3. Change current organization for the user
     * @param session is the active session for authenticated user
     */
    save(session: Immutable<Session>): Promise<SuccessResult<Immutable<Session>>>;

    /**
     * Destroys the session for the authenticated user.
     * @param session is the active session for the authenticated user
     * @returns Nothing if session is saved successfully.
     */
    delete(): Promise<SuccessResult<void>>;

    getUserStatus(
        email: EmailAddress,
    ): AsyncResult<UserStatusCode, InvalidArgument | OperationFailed | InactiveAccount | NoAccount>;
}

export const SessionRepository = Symbol('SessionRepository');
