import React from 'react';
import { Money } from '@embroker/shotwell/core/types/Money';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { ColumnLayout, InsetBox, ScrollBox, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import { PCoMLQuote } from '../../entities/PCoMLQuote';
import { pcomlQuotesCoverageDetailsMap } from '../config';

const lineStyle = { borderTop: '1px #d3dce7' };

export function PCOMLQuoteModalSummary({ quote }: { quote: PCoMLQuote }) {
    const coverages = [
        { coverageType: 'dno', ...quote.options['dno'] },
        { coverageType: 'epl', ...quote.options['epl'] },
    ];
    const selectedCoverages = coverages.filter((coverage) => coverage.isSelected);

    return (
        <StackLayout>
            <ColumnLayout gap="8">
                <Text>Effective date: </Text>{' '}
                <Text style="heading 5" data-e2e="effective-date">
                    <DateDisplay value={quote.options.effectiveDate} />
                </Text>
            </ColumnLayout>
            <InsetBox withBorder background="ui-100">
                <ScrollBox>
                    <StackLayout gap="24">
                        {selectedCoverages.map((coverage, index) => (
                            <React.Fragment key={coverage.coverageType}>
                                <Text style="heading 5" data-e2e="coverage-type-title">
                                    {pcomlQuotesCoverageDetailsMap[coverage.coverageType].title}
                                </Text>
                                <ColumnLayout gap="24">
                                    <Text>
                                        <Text style="overline">LIMIT</Text>{' '}
                                        <MoneyDisplay
                                            value={Money.tryFromFloat(coverage.limit.amount)}
                                            fractionDigits={0}
                                        />
                                    </Text>
                                    <Text>
                                        <Text style="overline">RETENTION</Text>{' '}
                                        <MoneyDisplay
                                            value={Money.tryFromFloat(coverage.retention.amount)}
                                            fractionDigits={0}
                                        />
                                    </Text>
                                </ColumnLayout>
                                {index === selectedCoverages.length - 1 ? null : (
                                    <hr style={lineStyle} />
                                )}
                            </React.Fragment>
                        ))}
                    </StackLayout>
                </ScrollBox>
            </InsetBox>
        </StackLayout>
    );
}
