import {
    defineValidator,
    Joi,
    Schema,
    TypeChecker,
    Validator,
} from '@embroker/shotwell/core/validation/schema';

/**
 * Represents a NAICS value.
 */
export interface NAICS {
    /**
     * String NAICS code
     */
    readonly code: string;
    /**
     * String NAICS descriptive name
     */
    readonly name: string;
    /**
     * This list is returned in case of that this value is search result
     * Otherwise matches will be null
     *
     * List of strings by which search matched NAICS result
     */
    readonly matches: string[];
}

export interface NAICSValidator {
    /**
     * A Joi schema matching a valid NAICS object.
     */
    readonly schema: Schema.ObjectSchema<NAICS>;
    /**
     * Type predicate that checks if a given value can be used as NAICS object.
     *
     * Use this only to do early returns. It's recommended to use validate()
     * before using an unknown value as NAICS object as it normalizes the value
     * in addition to performing the same validation as check().
     */
    readonly check: TypeChecker<NAICS>;
    /**
     * Validates and normalizes the given value to a NAICS object.
     *
     * This should be used for all untrusted inputs to verify and, if required
     * (and possible), normalize the input.
     */
    readonly validate: Validator<NAICS>;
}

export const NAICS = {
    ...defineValidator<NAICS>({
        code: Joi.string(),
        name: Joi.string(),
        matches: Joi.array().items(Joi.string()).allow(null),
    }),
    create(naics: NAICS) {
        return NAICS.validate(naics);
    },
};
