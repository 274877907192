import React, { useState } from 'react';
import { ColumnLayout, Filter } from '@embroker/ui-toolkit/v2';

interface InsuranceApplicationFilterProps {
    onChange: (months: FilterPeriod) => void;
}

export enum FilterPeriod {
    All = 0,
    Months6 = 6,
    Year1 = 12,
    Years3 = 36,
}

export function InsuranceApplicationFilter({ onChange }: InsuranceApplicationFilterProps) {
    const filterOptions = [
        { title: 'All time', value: FilterPeriod.All },
        { title: '6 months', value: FilterPeriod.Months6 },
        { title: '1 year', value: FilterPeriod.Year1 },
        { title: '3 years', value: FilterPeriod.Years3 },
    ];
    const [filterValue, setFilterValue] = useState<FilterPeriod>(FilterPeriod.Months6);

    const handleFilterChange = (months: FilterPeriod) => {
        setFilterValue(months);
        onChange(months);
    };

    return (
        <ColumnLayout split={'-1'}>
            <Filter
                title="Show"
                value={filterValue}
                options={filterOptions}
                onChange={(event: any) => handleFilterChange(event.target.value)}
            />
        </ColumnLayout>
    );
}
