import { Immutable } from '@embroker/shotwell/core/types';

export const entitiesMap: Immutable<Record<string, string>> = {
    Association: 'Association',
    Corporation: 'Corporation',
    'Individual/Sole Proprietor': 'IndividualSoleProprietor',
    'Joint Venture': 'JointVenture',
    'Limited Liability Company (LLC)': 'LLC',
    'Limited Liability Partnership (LLP)': 'LLP',
    'Non-Profit': 'NonProfit',
    Partnership: 'Partnership',
    'Professional Corporation': 'ProfessionalCorporation',
    'S-Corporation': 'SCorporation',
    Trust: 'Trust',
};
