import { Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    BoxLayout,
    Button,
    ColumnLayout,
    Form,
    InvoiceTable,
    Modal,
    ScrollBox,
    StackLayout,
    Text,
    TextButton,
    TextInput,
} from '@embroker/ui-toolkit/v2';
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import {
    CreateTokenCardData,
    StripeCardCvcElementChangeEvent,
    StripeCardExpiryElementChangeEvent,
    StripeCardNumberElement,
    StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { PayByCC } from '../../../useCases/PayByCreditCard';

interface CCModalInput {
    hideModal(): void;
    showThankYouModal?(): void;
    total: Money;
    activeInvoices: UUID[];
    modal: any;
    publicKey?: UUID;
}

export interface PayByCCInput {
    readonly invoiceIds: UUID[];
    readonly routingNumber: string;
    readonly accountNumber: string;
    readonly accountHolderName: string;
}

interface UICCInput {
    card: Nullable<StripeCardNumberElement>;
    readonly invoiceIds: UUID[];
    stripe: object;
    data: CreateTokenCardData;
    total: Money;
}

const elementsStyle = {
    color: '#333',
    border: '0.5px solid #e6e8eb',
    height: '50px',
    width: '100%',
    padding: '16px 10px',
} as const;

export const errorsStyle = {
    color: 'red',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
} as const;

const CCForm = createForm<UICCInput>({
    fields: {
        data: {
            type: 'hidden',
            validator: Joi.object(),
        },
        card: {
            type: 'hidden',
            validator: Joi.object(),
        },
        stripe: {
            type: 'hidden',
            validator: Joi.object(),
        },
        invoiceIds: {
            type: 'hidden',
            validator: Joi.array().items(UUID.schema).min(1),
        },
        total: {
            type: 'hidden',
            validator: Money.schema.required(),
        },
    },
    useCase: PayByCC,
});

export const CREDIT_CARD_FEE = 2.9;

export function PayWithCCModal(props: CCModalInput) {
    const stripe = useStripe();
    const elements = useElements();

    const { hideModal, showThankYouModal } = props;

    const navigation = useNavigation();
    const [errors, setErrors] = useState('');
    const [cardHolderName, setCardHolderName] = useState('');
    const [cardComplete, setCardComplete] = useState({
        number: false,
        cvc: false,
        expiry: false,
    });
    const { submit, value, setValue, status, result, messages } = useForm(CCForm);
    const [disablePayButton, setDisablePayButton] = useState(false);

    useEffect(() => {
        if (status == 'submitting') {
            setDisablePayButton(true);
            return;
        }
        setDisablePayButton(false);
        if (status === 'invalid' && result === undefined && messages) {
            setErrors(messages[0]);
        }
    }, [messages, result, status]);

    useEffect(() => {
        if (result !== undefined && props.publicKey == undefined) {
            hideModal();
            if (showThankYouModal !== undefined) {
                showThankYouModal();
            }
        }
        if (result !== undefined && props.publicKey !== undefined && status === 'submitted') {
            navigation.navigate(
                URI.build('/payments/stripe/payment/thank-you', {
                    publicKey: props.publicKey,
                }),
            );
        }
    }, [result, props.publicKey, hideModal, showThankYouModal, navigation, status]);

    useEffect(() => {
        setValue({ ...value, invoiceIds: props.activeInvoices });
        if (!Object.values(cardComplete).includes(false) && cardHolderName) {
            setErrors('');
        }
    }, [cardComplete, value, setValue, props.activeInvoices, cardHolderName]);

    function handleCCPay(input: UICCInput) {
        if (Object.values(cardComplete).includes(false) || !cardHolderName) {
            setErrors('You have incorrect or incomplete data, please revise');
            return;
        }

        setValue({
            ...value,
            card: input.card,
            stripe: input.stripe,
            data: input.data,
            total: input.total,
        });
        submit();
    }

    const creditCardFee = Money.percentage(props.total, CREDIT_CARD_FEE);
    const creditCardValue = Money.percentage(props.total, 100 + CREDIT_CARD_FEE);

    if (stripe === null || elements === null) {
        return null;
    }

    function handleNumberChange(event: StripeCardNumberElementChangeEvent) {
        setCardComplete({ ...cardComplete, number: event.complete });
    }
    function handleExpiryChange(event: StripeCardExpiryElementChangeEvent) {
        setCardComplete({ ...cardComplete, expiry: event.complete });
    }
    function handleCVCChange(event: StripeCardCvcElementChangeEvent) {
        setCardComplete({ ...cardComplete, cvc: event.complete });
    }
    const handleCardHolderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCardHolderName(event.target.value);
    };

    const onHideModal = () => {
        props.hideModal();
        setErrors('');
    };

    return (
        <Modal {...props.modal} hide={onHideModal}>
            <Form>
                <ScrollBox>
                    <StackLayout gap="16">
                        <Text style="heading 3">Pay With a Credit Card</Text>
                        <Text>Card holder's name</Text>
                        <TextInput
                            value={cardHolderName}
                            onChange={handleCardHolderChange}
                            data-e2e="holder-name"
                        />
                        <Text>Card number</Text>
                        <Form.Field>
                            <div className="c-input--text" id="cardDiv" style={elementsStyle}>
                                <CardNumberElement onChange={handleNumberChange} />
                            </div>
                        </Form.Field>
                        <ColumnLayout>
                            <StackLayout>
                                <Text>Expiration date</Text>
                                <Form.Field>
                                    <div id="cardExpiry" style={elementsStyle}>
                                        <CardExpiryElement onChange={handleExpiryChange} />
                                    </div>
                                </Form.Field>
                            </StackLayout>
                            <StackLayout>
                                <Text>CVC</Text>
                                <Form.Field>
                                    <div id="cardCVC" style={elementsStyle}>
                                        <CardCvcElement onChange={handleCVCChange} />
                                    </div>
                                </Form.Field>
                            </StackLayout>
                        </ColumnLayout>
                        <hr />
                        <InvoiceTable>
                            <InvoiceTable.Section>
                                <InvoiceTable.Subtotal title="Subtotal">
                                    <MoneyDisplay value={props.total} />
                                </InvoiceTable.Subtotal>
                                <InvoiceTable.Subtotal title="+ Credit card fees">
                                    <MoneyDisplay value={creditCardFee} />
                                </InvoiceTable.Subtotal>
                                <hr />

                                <InvoiceTable.Total title="Total Amount">
                                    <MoneyDisplay value={creditCardValue} />
                                </InvoiceTable.Total>

                                <hr />
                            </InvoiceTable.Section>
                        </InvoiceTable>
                        <Text>
                            <div data-e2e="payment-error-message" style={errorsStyle}>
                                {errors}
                            </div>
                        </Text>
                        <ColumnLayout>
                            <Button
                                disabled={disablePayButton}
                                appearance="primary"
                                data-e2e="pay-cc-button"
                                onClick={() =>
                                    handleCCPay({
                                        card: elements.getElement(CardNumberElement),
                                        invoiceIds: props.activeInvoices,
                                        stripe: stripe,
                                        data: { name: cardHolderName },
                                        total: props.total,
                                    })
                                }
                            >
                                Pay
                            </Button>
                            <BoxLayout>
                                <TextButton onClick={onHideModal}>Cancel</TextButton>
                            </BoxLayout>
                        </ColumnLayout>
                    </StackLayout>
                </ScrollBox>
            </Form>
        </Modal>
    );
}
