import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Nullable } from '@embroker/shotwell/core/types';
import { AsyncResult, Failure, isOK, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass, execute } from '@embroker/shotwell/core/UseCase';
import { GetActiveSession } from './GetActiveSession';
import { UpdateOrganizationProfile } from './UpdateOrganizationProfile';
import { NaicsConfirmationError } from '../errors';

/**
 * Request data for UpdateOrganizationNaicsCode use case
 * @param confirmedNaicsCode is confirmed Naics code for the organization entity
 */
export interface UpdateOrganizationNaicsCodeRequest {
    confirmedNaicsCode: Nullable<string>;
}

export interface UpdateOrganizationNaicsCode extends UseCase {
    execute(request: UpdateOrganizationNaicsCodeRequest): AsyncResult<void, NaicsConfirmationError>;
}

@injectable()
export class UpdateOrganizationNaicsCodeUseCase
    extends UseCase
    implements UpdateOrganizationNaicsCode
{
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/UpdateOrganizationNaicsCode');
    /**
     * Constructor for UpdateOrganizationNaicsCode use case class instance
     * @param eventBus An event bus this Use Case will publish events to.
     * @param organizationRepo is organization repository used to save organization entity with updated properties
     */
    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    /**
     * Executes UpdateOrganizationNaicsCode use case
     * Input is of type UpdateOrganizationNaicsCodeRequest
     * @returns Nothing if execution was successful
     * @returns NaicsConfirmationError error if organization could not be updated.
     */
    public async execute({
        confirmedNaicsCode,
    }: UpdateOrganizationNaicsCodeRequest): AsyncResult<void, NaicsConfirmationError> {
        const getActiveSessionResult = await execute(GetActiveSession);
        if (isOK(getActiveSessionResult)) {
            const activeSessionResult = getActiveSessionResult.value;
            const updateOrgResult = await execute(UpdateOrganizationProfile, {
                naics: confirmedNaicsCode,
                id: activeSessionResult.session.organizationId as UUID,
            });
            if (isOK(updateOrgResult)) {
                return Success();
            }
        }

        return Failure(NaicsConfirmationError());
    }
}

export const UpdateOrganizationNaicsCode: UseCaseClass<UpdateOrganizationNaicsCode> =
    UpdateOrganizationNaicsCodeUseCase;
