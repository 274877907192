import { CoverLayout, Placeholder, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';

export function StepHelpGuidelines() {
    return (
        <CoverLayout>
            <StackLayout split="-1" gap="24">
                <Text style={'heading 3'}>We’re here to help</Text>
                <Text style="body 1">
                    Our Customer Success Team is happy to assist you with any special requests you
                    might have. Just provide us with the necessary details and they will get back to
                    you at their earliest convenience.
                </Text>
                <Placeholder
                    backgroundColor="ui-50"
                    appearance="compact"
                    imageMaxWidth={270}
                    imageName="illustration-male-laptop"
                />
            </StackLayout>
        </CoverLayout>
    );
}
