import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import {
    PremiumFinanceUpdateBillingInfoCreditCardInput,
    QuoteRepository,
} from '../repositories/QuoteRepository';

export interface PremiumFinanceUpdateBillingInfoCreditCardRequest {
    readonly quoteNumber: number;
    readonly autoChargeCreditCard: boolean;
}

export interface PremiumFinanceUpdateBillingInfoCreditCardResponse {
    readonly fifRecurringCreditCardURL: URI;
    readonly creditCardSignatureDateTime: Date;
}

export interface PremiumFinanceUpdateBillingInfoCreditCard extends UseCase {
    execute(
        request: PremiumFinanceUpdateBillingInfoCreditCardRequest,
    ): AsyncResult<
        PremiumFinanceUpdateBillingInfoCreditCardResponse,
        InvalidArgument | OperationFailed
    >;
}

@injectable()
class PremiumFinanceUpdateBillingInfoCreditCardUseCase extends UseCase {
    public static type = Symbol('Payments/PremiumFinanceUpdateBillingInfoCreditCard');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(QuoteRepository) private quoteRepo: QuoteRepository,
    ) {
        super(eventBus);
    }
    public async execute(
        input: PremiumFinanceUpdateBillingInfoCreditCardRequest,
    ): AsyncResult<
        PremiumFinanceUpdateBillingInfoCreditCardResponse,
        InvalidArgument | OperationFailed
    > {
        const billingInfoInput: PremiumFinanceUpdateBillingInfoCreditCardInput = {
            quoteNumber: input.quoteNumber,
        };
        const updateResult = await this.quoteRepo.updateBillingInfoCreditCard(billingInfoInput);
        if (isErr(updateResult)) {
            return handleOperationFailure(updateResult);
        }

        return Success({
            fifRecurringCreditCardURL: updateResult.value.fifRecurringCreditCardURL,
            creditCardSignatureDateTime: updateResult.value.creditCardSignatureDateTime,
        });
    }
}

export const PremiumFinanceUpdateBillingInfoCreditCard: UseCaseClass<PremiumFinanceUpdateBillingInfoCreditCard> =
    PremiumFinanceUpdateBillingInfoCreditCardUseCase;
