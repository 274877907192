import { Modal, ModalActions, ModalState, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { ModalLayout } from '../../../view/components/ModalLayout.view';

interface OfacRejectedModalInput {
    modal: ModalState & ModalActions;
}

export function OfacRejectedModal({ modal }: OfacRejectedModalInput) {
    const { navigate } = useNavigation();
    return (
        <Modal {...modal} size="small" dismissable={false}>
            <ModalLayout
                title="Thank you for your application"
                primaryAction={{
                    label: 'Back to Dashboard',
                    onClick: () => navigate('/summary'),
                    'data-e2e': 'back-to-dashboard',
                }}
                secondaryAction={{
                    label: 'Open Live Chat',
                    href: '#chat-now',
                }}
            >
                <Text>
                    One of your responses requires clarification. No problem! One of our expert
                    advisors will call you shortly and complete the process.
                </Text>
                <Text>Or you can contact us directly via live chat or call us at 844.436.2765</Text>
            </ModalLayout>
        </Modal>
    );
}
