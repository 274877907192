import {
    defineEntityValidator,
    Entity,
    entity,
    UpdateEntityProps,
} from '@embroker/shotwell/core/entity/Entity';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { Nullable } from '@embroker/shotwell/core/types';
import { State } from '@embroker/shotwell/core/types/StateList';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { physicalAddressValidator } from '@embroker/shotwell/core/types/POBox';

interface LocationUpdated extends DomainEvent {
    readonly origin: 'Location';
    readonly name: 'Updated';
}

export interface Location extends Entity {
    readonly addressLine1: Nullable<string>;
    readonly addressLine2: Nullable<string>;
    readonly city: Nullable<string>;
    readonly state: Nullable<State>;
    readonly county: Nullable<string>;
    readonly valueOfProperty: Nullable<number>;
    readonly squareFootageOccupied: Nullable<number>;
    readonly zip: Nullable<ZipCode>;
    update<T extends Location>(newLocation: UpdateEntityProps<T>): void;
}
export const Location = entity<Location>({
    validator: defineEntityValidator<Location>({
        addressLine1: Joi.string().pattern(new RegExp(physicalAddressValidator, 'i')).allow(null),
        addressLine2: Joi.string().pattern(new RegExp(physicalAddressValidator, 'i')).allow(null),
        city: Joi.string().allow(null),
        state: State.schema.allow(null),
        county: Joi.string().allow(null),
        valueOfProperty: Joi.number().allow(null),
        squareFootageOccupied: Joi.number().allow(null),
        zip: ZipCode.schema.allow(null),
    }),
    update(newLocation) {
        Object.assign(this.props, newLocation);
        this.createEvent<LocationUpdated>('Updated');
    },
});
