import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { BrokerActivityItemList, BrokerRepository } from '../repositories';
import { ListOptions } from '../types/ListOptions';

export type ActivityTableColumn = 'record' | 'expiry_date' | 'client_name' | 'product_name';

export interface ActivityTableFilter {
    target: ActivityTableColumn;
    value: string;
}
export interface GetBrokerActivityRequest {
    readonly index: number;
    readonly size: number;
    readonly column?: ActivityTableColumn;
    readonly order?: 'ascending' | 'descending';
    readonly filters?: ActivityTableFilter[];
}

export interface GetBrokerActivity extends UseCase {
    execute(
        request: GetBrokerActivityRequest,
    ): AsyncResult<BrokerActivityItemList, InvalidArgument | OperationFailed>;
}

class GetBrokerActivityUseCase extends UseCase implements GetBrokerActivity {
    public static type = Symbol('Broker/GetBrokerActivity');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BrokerRepository) private brokerRepository: BrokerRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: GetBrokerActivityRequest,
    ): AsyncResult<BrokerActivityItemList, InvalidArgument | OperationFailed> {
        const listOptionsResult = ListOptions.create(request);
        if (isErr(listOptionsResult)) {
            return listOptionsResult;
        }
        return this.brokerRepository.getBrokerActivityItemsList(listOptionsResult.value);
    }
}

export const GetBrokerActivity: UseCaseClass<GetBrokerActivity> = GetBrokerActivityUseCase;
