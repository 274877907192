import { OnboardingPrefillQuestionnaireData } from '@app/userOrg/types/OnboardingPrefillQuestionnaireData';
import { WorkspaceOwnership } from '@app/userOrg/types/enums';
import { Success } from '@embroker/shotwell/core/types/Result';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { createForm } from '@embroker/shotwell/view/hooks/useForm';
import React, { useMemo } from 'react';
import { OnboardingPageProps } from './OnboardingFlowWrapper';
import { StaffDetails } from './StaffDetails.view';
import { Money } from '@embroker/shotwell/core/types/Money';

export interface StaffDetailsInput {
    readonly hasRaisedFounding: boolean;
    readonly totalRevenue: Money;
    readonly workspace: WorkspaceOwnership;
    readonly employeesCount: number;
}

export const staffDetailsForm = ({
    onCompleteOnboardingStep,
}: {
    onCompleteOnboardingStep: (questionnaireData: OnboardingPrefillQuestionnaireData) => void;
}) => {
    return createForm<StaffDetailsInput>({
        fields: {
            hasRaisedFounding: {
                type: 'radioGroup',
                validator: Joi.boolean().required(),
            },
            totalRevenue: {
                type: 'currency',
                validator: Money.schema.required(),
                formatValidationError(error) {
                    if (error.details.validator === 'number.min') {
                        return 'The revenue must be more than 0.';
                    }
                    if (error.details.validator === 'any.required') {
                        return 'Please enter a revenue.';
                    }
                    return error.message;
                },
            },
            workspace: {
                type: 'radioGroup',
                validator: Joi.string()
                    .valid('home_office', 'office_rented', 'office_owned')
                    .required(),
            },
            employeesCount: {
                type: 'number',
                validator: Joi.number().min(1).required(),
                formatValidationError(error) {
                    if (error.details.validator === 'number.min') {
                        return 'Please enter a minimum of 1 employees.';
                    }
                    if (error.details.validator === 'any.required') {
                        return 'Please enter number of employees.';
                    }
                    return error.message;
                },
            },
        },
        submit: async (input) => {
            onCompleteOnboardingStep({
                has_raised_funding: input.hasRaisedFounding,
                total_revenue: input.totalRevenue,
                location_type: input.workspace,
                number_of_employees: input.employeesCount,
            });
            return Success();
        },
    });
};

export const StaffDetailsPage = ({
    questionnaireData,
    onCompleteOnboardingStep,
    onBackClicked,
}: OnboardingPageProps) => {
    const staffDetailsFormMemo = useMemo(
        () => staffDetailsForm({ onCompleteOnboardingStep }),
        [onCompleteOnboardingStep],
    );

    return (
        <StaffDetails
            form={staffDetailsFormMemo}
            handleBack={onBackClicked}
            questionnaireData={questionnaireData}
        />
    );
};
