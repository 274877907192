import { defineEntityValidator, entity } from '@embroker/shotwell/core/entity/Entity';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { ShoppingCoverage, ShoppingCoverageCodeList } from '../../../shopping/types/enums';
import { commonQuoteProps, Quote } from '../../entities/Quote';
import { ESPQuoteInfo } from '../types/ESPQuoteInfo';
import { ESPRate } from '../types/ESPRate';

export interface ESPQuote extends Quote {
    readonly details: ESPRate;
    readonly quoteInfo?: ESPQuoteInfo;
    initialShoppingCoverages: readonly ShoppingCoverage[];
    referralReasons?: readonly string[];
}

export const ESPQuote = entity<ESPQuote, Quote>({
    validator: defineEntityValidator<ESPQuote>({
        ...commonQuoteProps,
        details: ESPRate.schema,
        quoteInfo: ESPQuoteInfo.schema.optional(),
        initialShoppingCoverages: Joi.array().items(
            Joi.string().valid(...ShoppingCoverageCodeList),
        ),
        referralReasons: Joi.array().items(Joi.string()).optional(),
    }),
    inherits: Quote,
});
