import React, { useEffect } from 'react';
import {
    Modal,
    ModalActions,
    ModalState,
    Text,
    TextButton,
    ScrollBox,
    Immutable,
    StackLayout,
} from '@embroker/ui-toolkit/v2';
import { ModalLayout } from '../../../view/components/ModalLayout.view';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { URI } from '@embroker/shotwell/core/types/URI';
import { PCoMLQuote } from '@app/quote/pcoml/entities/PCoMLQuote';
import { QuoteInfo } from '@app/shopping/useCases/GetQuoteSummaryByPaymentIds';
import { PCOMLQuoteModalSummary } from '../../../quote/pcoml/view/components/PCoMLQuoteModalSummary';
import { BundleQuoteModalSummary } from '@app/bundle/view/components/BundleQuoteModalSummary.view';
import { BundleQuote } from '@app/bundle/entities/BundleQuote';
import { CrimeQuote } from '@app/quote/crime/entities/CrimeQuote';
import { CrimeQuoteModalSummary } from '@app/quote/crime/view/components/CrimeQuoteModalSummary';
import { ESPQuote } from '@app/quote/esp/entities/ESPQuote';
import { ESPQuoteModalSummary } from '@app/quote/esp/view/components/ESPQuoteModalSummary';
import {
    ManualQuote,
    ManualQuoteModalSummary,
} from '@app/quote/manual/view/components/ManualQuoteModalSummary';
import { execute } from '@embroker/shotwell/core/UseCase';
import {
    PublishReturnFromAscendEvent,
    ReturnFromAscendEventType,
} from '@app/policy/useCases/PublishReturnFromAscendEvent';

interface AscendPaymentsModalInput {
    modal: ModalState & ModalActions;
    isRedirectedToPolicy?: boolean;
    email?: string;
    quoteInfo?: Immutable<QuoteInfo>;
}

export function AscendPaymentsModal({
    modal,
    isRedirectedToPolicy,
    email,
    quoteInfo,
}: AscendPaymentsModalInput) {
    const { navigate } = useNavigation();
    const primaryAction = () => {
        if (isRedirectedToPolicy) {
            return {
                label: 'Go To Dashboard',
                onClick: async () => {
                    modal.hide();
                    if (isRedirectedToPolicy) {
                        navigate('/summary');
                    }
                },
            };
        }
        return {
            label: 'Got It',
            onClick: () => {
                modal.hide();
            },
        };
    };

    const handleUpdateBillingAddress = async () => {
        navigate(URI.build('/payments/history', {}));

        execute(PublishReturnFromAscendEvent, {
            eventType: ReturnFromAscendEventType.updateYourBillingEmailClicked,
        });
    };

    useEffect(() => {
        if (modal.visible) {
            execute(PublishReturnFromAscendEvent, {
                eventType: ReturnFromAscendEventType.pageLoaded,
            });
        }
    }, [modal.visible]);

    return (
        <Modal {...modal} dismissable={!isRedirectedToPolicy}>
            <ScrollBox>
                <ModalLayout title="Thank You!" primaryAction={primaryAction()}>
                    <Text>
                        You've been redirected to your Embroker dashboard — welcome back! Your
                        coverage listed below is now in place.
                    </Text>
                    <Text>
                        Ascend will be sending you a confirmation and receipt for your payment to{' '}
                        {email}.
                    </Text>

                    <Text>
                        Need to update your billing email address? You can{' '}
                        <TextButton onClick={handleUpdateBillingAddress}>
                            update your billing email address{' '}
                        </TextButton>{' '}
                        on the Payments tab.
                    </Text>

                    {/* PCoML quote */}
                    {quoteInfo && quoteInfo.pCoMLQuote && (
                        <PCOMLQuoteModalSummary quote={quoteInfo?.pCoMLQuote as PCoMLQuote} />
                    )}

                    {/* Crime quote */}
                    {quoteInfo && quoteInfo.crimeQuote && (
                        <CrimeQuoteModalSummary quote={quoteInfo?.crimeQuote as CrimeQuote} />
                    )}

                    {/* ESP quote */}
                    {quoteInfo && quoteInfo.espQuote && (
                        <ESPQuoteModalSummary quote={quoteInfo?.espQuote as ESPQuote} />
                    )}

                    {/* Bundle quote */}
                    {quoteInfo && quoteInfo.bundleQuote && (
                        <BundleQuoteModalSummary
                            bundleQuote={quoteInfo.bundleQuote as BundleQuote}
                        />
                    )}

                    {/* Cyber Monoline quote */}
                    {quoteInfo && quoteInfo.cyberQuote && (
                        <BundleQuoteModalSummary
                            bundleQuote={quoteInfo.cyberQuote as BundleQuote}
                        />
                    )}

                    {/* Non-digital quote */}
                    {quoteInfo && quoteInfo.manualQuotes && (
                        <StackLayout>
                            {quoteInfo.manualQuotes.map((quote) => (
                                <ManualQuoteModalSummary
                                    key={quote.name}
                                    quote={quote as ManualQuote}
                                />
                            ))}
                        </StackLayout>
                    )}
                </ModalLayout>
            </ScrollBox>
        </Modal>
    );
}
