import { inject, injectable } from '@embroker/shotwell/core/di';
import { Aborted, InvalidArgument, OperationFailed, Timeout } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import {
    AsyncResult,
    isErr,
    Success,
    Failure,
    handleOperationFailure,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ESPQuoteRepository } from '../repositories/ESPQuoteRepository';
import { State } from '@embroker/shotwell/core/types/StateList';

export interface GetESPQuestionnaireRequest {
    applicationId: UUID;
}

export interface GetESPQuestionnaireResponse {
    state: State;
    numberOfEmployees: number;
    coveragesWithClaims?: string[];
    raisedFunding: boolean;
    version: number;
    zip: string;
}

interface QuestionnaireData {
    state: State;
    number_of_employees: number;
    claims_in_past_three_years_coverages?: string[];
    raised_venture_funding: boolean;
    zip: string;
}

function mapToGetESPQuestionnaireResponse(
    data: QuestionnaireData,
    version: number,
): GetESPQuestionnaireResponse {
    return {
        state: data.state,
        numberOfEmployees: data.number_of_employees,
        raisedFunding: data.raised_venture_funding,
        coveragesWithClaims: data.claims_in_past_three_years_coverages,
        zip: data.zip,
        version,
    };
}

export interface GetESPQuestionnaireData extends UseCase {
    execute(
        request: GetESPQuestionnaireRequest,
    ): AsyncResult<
        GetESPQuestionnaireResponse | void,
        InvalidArgument | OperationFailed | Aborted | Timeout
    >;
}

@injectable()
class GetESPQuestionnaireDataUseCase extends UseCase implements GetESPQuestionnaireData {
    public static type = Symbol('ESPQuote/GetESPQuestionnaireData');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ESPQuoteRepository) private espQuoteRepository: ESPQuoteRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
    }: GetESPQuestionnaireRequest): AsyncResult<
        GetESPQuestionnaireResponse | void,
        InvalidArgument | OperationFailed | Aborted | Timeout
    > {
        const applicationDataResult =
            await this.espQuoteRepository.getESPQuestionnaireDataAndSubmissionDate(applicationId);
        if (isErr(applicationDataResult)) {
            return handleOperationFailure(applicationDataResult);
        }

        const configResponse = await this.espQuoteRepository.getESPConfig();

        if (isErr(configResponse)) {
            return handleOperationFailure(configResponse);
        }

        const dynamicESPRetention = configResponse.value.dynamicESPRetentionEnabled;
        const dynamicESPRetentionStartDate = configResponse.value.dynamicESPRetentionStartDate;
        const dynamicESPRetentionUpdateDate = configResponse.value.dynamicESPRetentionUpdateDate;
        const applicationSubmissionDate = applicationDataResult.value.submissionDate;
        if (
            !dynamicESPRetention ||
            dynamicESPRetentionStartDate == undefined ||
            applicationSubmissionDate < dynamicESPRetentionStartDate
        ) {
            return Success();
        }

        let dynamicRetentionVersion = 1;
        if (
            dynamicESPRetentionUpdateDate &&
            dynamicESPRetentionUpdateDate < applicationSubmissionDate
        ) {
            dynamicRetentionVersion = 2;
        }

        let questionnaireDataParsed: QuestionnaireData;
        try {
            questionnaireDataParsed = JSON.parse(applicationDataResult.value.questionnaireData);
        } catch (e) {
            return Failure(OperationFailed({ message: 'Failed parsing questionnaire data' }));
        }

        return Success(
            mapToGetESPQuestionnaireResponse(questionnaireDataParsed, dynamicRetentionVersion),
        );
    }
}

export const GetESPQuestionnaireData: UseCaseClass<GetESPQuestionnaireData> =
    GetESPQuestionnaireDataUseCase;
