import { Nullable } from '@embroker/shotwell/core/types';
import { PhoneNumber } from '@embroker/shotwell/core/types/PhoneNumber';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface PolicyClaimContactInfo {
    policyId: UUID;
    policyDisplayName: string;
    policyNumber: string;
    policyStartDate: Date;
    insurerName: string;
    lineOfBusiness: string;
    lineOfBusinessSubtype: Nullable<string>;
    applicationId?: UUID;
    claimWebsite: Nullable<string>;
    claimEmail: Nullable<string>;
    claimPhoneNumber: Nullable<PhoneNumber>;
    claimPhoneExtension: Nullable<number>;
}

export const PolicyClaimContactInfo = {
    ...defineValidator<PolicyClaimContactInfo>({
        policyId: UUID.schema,
        policyDisplayName: Joi.string(),
        policyNumber: Joi.string(),
        policyStartDate: Joi.date(),
        insurerName: Joi.string(),
        lineOfBusiness: Joi.string(),
        lineOfBusinessSubtype: Joi.string().allow(null),
        applicationId: UUID.schema.optional(),
        claimWebsite: Joi.string().allow('', null),
        claimEmail: Joi.string().allow('', null),
        claimPhoneNumber: Joi.string().allow(null),
        claimPhoneExtension: Joi.number().allow(null),
    }),
    create(policyClaimContactInfo: PolicyClaimContactInfo) {
        return PolicyClaimContactInfo.validate(policyClaimContactInfo);
    },
};
