import { Modal, ModalActions, ModalState, Spinner, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { ModalLayout } from '../../../view/components/ModalLayout.view';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { useAsyncTrigger } from '@embroker/shotwell/view/hooks/useAsyncTrigger';
import { CancelApplication } from '../../useCases/CancelApplication';
import { Success, isErr } from '@embroker/shotwell/core/types/Result';
import { execute } from '@embroker/shotwell/core/UseCase';
import { AppTypeCodeListManualEmploymentPractices } from '@app/shopping/types/enums';
import { useStartApplication } from '../../../shopping/view/hooks/useStartApplication';

interface EPLIStandaloneInCaliforniaModalInput {
    readonly modal: ModalActions & ModalState;
    readonly appId: UUID;
}

export function EPLIStandaloneInCaliforniaModal({
    modal,
    appId,
}: EPLIStandaloneInCaliforniaModalInput) {
    const { trigger: onCancelApplication, isLoading: isLoadingCancelApplication } = useAsyncTrigger(
        async () => {
            const result = await execute(CancelApplication, { applicationId: appId });

            if (isErr(result)) {
                return result;
            }

            return Success();
        },
    );

    const { startApplication, isLoading: isLoadingStartApplication } = useStartApplication();

    if (isLoadingCancelApplication || isLoadingStartApplication) {
        return <Spinner />;
    }

    const startLongFormEPLI = () => {
        onCancelApplication();
        startApplication({ selectedAppTypes: [AppTypeCodeListManualEmploymentPractices] });
    };

    return (
        <Modal {...modal} size="small" dismissable>
            <ModalLayout
                title="Looks like you're trying to buy Employment Practices Liability (EPLI) on its own"
                primaryAction={{
                    label: 'Add Another Coverage',
                    onClick: modal.hide,
                }}
                secondaryAction={{
                    label: 'Get an EPLI Quote',
                    onClick: startLongFormEPLI,
                }}
            >
                <Text>
                    To purchase EPLI digitally, simply add at least one other coverage to your
                    quote.
                </Text>
                <Text>
                    For an EPLI only quote, we'll need some additional information from you so we
                    can shop around and find the right fit for your business.
                </Text>
            </ModalLayout>
        </Modal>
    );
}
