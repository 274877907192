import { UUID } from '@embroker/shotwell/core/types/UUID';
import { HeroBanner, PageLayout, Text, useModal } from '@embroker/ui-toolkit/v2';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PurchaseHartfordBOP } from '../../useCases/PurchaseHartfordBOP';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import {
    BOPHartfordPaymentEvent,
    BOPHartfordQuote,
} from '@app/quote/hartford-bop/entities/BOPHartfordQuote';
import { QuoteExpiration } from '@app/quote/embrokerExcess/view/components/QuoteExpiration';
import { LeavingConfirmationModal } from '@app/quote/view/components/LeavingConfirmationModal';
import { PaymentPage } from '@app/quote/hartford-bop/view/components/PaymentPage';
import { execute } from '@embroker/shotwell/core/UseCase';
import { navigateToErrorPage } from '../../../../view/errors';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { GetPaymentUrl } from '@app/quote/hartford-bop/useCases/GetPaymentUrl';
import { ThankYouModal } from '@app/payments/view/components/modals/ThankYouModal';

interface BOPHartfordCheckoutPageProps {
    applicationId: UUID;
    quote: BOPHartfordQuote;
}

const policyPendingErr = 'Policy status is issue pending';

export function BOPHartfordCheckoutPage({ quote }: BOPHartfordCheckoutPageProps) {
    const { navigate } = useNavigation();
    const leavingConfirmationModal = useModal();
    const thankYouForPaymentModal = useModal();
    const [hartfordPaymentUrl, setHartfordPaymentUrl] = useState<string>('');
    const [appAlreadySubmitted, setAppAlreadySubmitted] = useState<boolean>(false);

    let eventReceived = false;

    const controller = useMemo(() => {
        return new AbortController();
    }, []);

    const handleConfirmLeaving = useCallback(() => {
        leavingConfirmationModal.hide();
        controller.abort();
        navigate('/');
    }, [controller, leavingConfirmationModal, navigate]);

    const handleLeaving = useCallback(() => {
        leavingConfirmationModal.show();
    }, [leavingConfirmationModal]);

    useEffect(() => {
        if (appAlreadySubmitted) {
            thankYouForPaymentModal.show();
            return;
        }

        if (hartfordPaymentUrl) {
            return;
        }

        execute(GetPaymentUrl, {
            path: quote.details.paymentUrl,
        }).then((result) => {
            if (isErr(result)) {
                const issuePending = result.errors.some(
                    (error) => error.message === policyPendingErr,
                );
                if (issuePending) {
                    setAppAlreadySubmitted(true);
                    return;
                }
                navigateToErrorPage(navigate, result.errors);
                return;
            }
            setHartfordPaymentUrl(result.value);
        });
    }, [
        appAlreadySubmitted,
        navigate,
        quote.details.paymentUrl,
        thankYouForPaymentModal,
        hartfordPaymentUrl,
    ]);

    async function hideThankYouForPaymentModal() {
        thankYouForPaymentModal.hide();
        return navigate('/policies');
    }

    const onPaymentCallback = async (data: BOPHartfordPaymentEvent) => {
        const isPaid =
            data &&
            data.type === 'Error' &&
            data.messages.bopErrorMessage === 'Payment Details Are Already Saved';
        const isSuccess = data && data.type === 'Success';

        if (isSuccess || isPaid) {
            if (!eventReceived) {
                eventReceived = true;

                const result = await execute(PurchaseHartfordBOP, {
                    quote,
                });

                controller.abort();

                if (isErr(result)) {
                    navigateToErrorPage(navigate, result.errors);
                    return;
                }

                thankYouForPaymentModal.show();
            }
        }
    };

    return (
        <React.Fragment>
            <HeroBanner
                onDismiss={handleLeaving}
                message={<QuoteExpiration daysToExpire={quote.daysToExpire}></QuoteExpiration>}
            >
                <Text style="heading 1">Payment</Text>
            </HeroBanner>
            <LeavingConfirmationModal
                modal={leavingConfirmationModal}
                onConfirmLeaving={handleConfirmLeaving}
            />

            <ThankYouModal modal={thankYouForPaymentModal} hideModal={hideThankYouForPaymentModal}>
                Your payment has been received and your policy is being processed.
            </ThankYouModal>

            {hartfordPaymentUrl !== '' && (
                <PageLayout.Section>
                    <PaymentPage
                        paymentUrl={hartfordPaymentUrl}
                        onPaymentCallback={onPaymentCallback}
                        controller={controller}
                    />
                </PageLayout.Section>
            )}
        </React.Fragment>
    );
}
