import { Modal, useModal, Text, TextButton, TextStyle } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { Nullable } from '@embroker/shotwell/core/types';
import { State } from '@embroker/shotwell/core/types/StateList';
import { AgreementToConductModalContent } from './AgreementToConductModalContent';
import { StateDisclosureModalContent } from './StateDisclosureModalContent';

const CompensationDisclosureURL = 'https://www.embroker.com/disclosure/';

export interface SignInsuranceApplicationCheckboxProps {
    company: string;
    fullName: string;
    usaState?: Nullable<State>;
    style?: TextStyle;
    disableSurplusLinesDisclosure?: boolean;
}

export function SignInsuranceApplicationCheckbox({
    company,
    fullName,
    usaState,
    style,
    disableSurplusLinesDisclosure,
}: SignInsuranceApplicationCheckboxProps) {
    const agreementModal = useModal();
    const stateDisclosureModal = useModal();

    return (
        <React.Fragment>
            <Text as="span" style={style}>
                By checking this box, you are electronically signing an insurance application and
                confirm that you have read and consent to our{' '}
                <TextButton onClick={agreementModal.show} className="c-link display-inline">
                    Agreement to Conduct Electronic Transactions
                </TextButton>
                {!disableSurplusLinesDisclosure ? ', ' : ' and '}
                <TextButton
                    href={CompensationDisclosureURL}
                    target="_blank"
                    className="c-link display-inline"
                >
                    Compensation Disclosure
                </TextButton>
                {!disableSurplusLinesDisclosure && (
                    <React.Fragment>
                        {' and '}
                        <TextButton
                            onClick={stateDisclosureModal.show}
                            className="c-link display-inline"
                        >
                            Surplus Lines Disclosure
                        </TextButton>
                    </React.Fragment>
                )}
                .
            </Text>
            <Modal {...agreementModal} size="medium">
                <AgreementToConductModalContent />
            </Modal>
            <Modal {...stateDisclosureModal} size="medium">
                <StateDisclosureModalContent
                    companyName={company}
                    fullName={fullName}
                    usaState={usaState ?? ''}
                />
            </Modal>
        </React.Fragment>
    );
}
