import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';

export const QuoteUserEventEnum = {
    noThanksClicked: 'SE Exclusion modal - No, thanks clicked',
    addCyberAfterCheckoutClicked: 'SE Exclusion modal - Add Cyber After Checkout clicked',
    exitCtaClicked: 'SE Exclusion modal - Exit CTA Clicked',
    outsideModalClicked: 'SE Exclusion modal - Outside Modal Area clicked',
    pageLoaded: 'SE Exclusion modal - Page loaded',
} as const;

export const QuoteUserEventEnumTypeList = [
    QuoteUserEventEnum.noThanksClicked,
    QuoteUserEventEnum.addCyberAfterCheckoutClicked,
    QuoteUserEventEnum.exitCtaClicked,
    QuoteUserEventEnum.outsideModalClicked,
    QuoteUserEventEnum.pageLoaded,
] as const;
export type QuoteUserEventEnumType = (typeof QuoteUserEventEnumTypeList)[number];

export interface QuoteUserClickEvent extends DomainEvent {
    name: 'QuoteUserClickEvent';
    clickEventName: QuoteUserEventEnumType;
}

interface ArgsProps {
    clickEventName: QuoteUserEventEnumType;
}

export interface PublishQuoteUserClickEvent extends UseCase {
    execute(request: ArgsProps): AsyncResult<void>;
}

@injectable()
class PublishQuoteUserClickEventUseCase extends UseCase implements PublishQuoteUserClickEvent {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('shopping/PublishQuoteUserClickEventUseCase');

    /**
     * Constructor for PublishQuoteUserClickEvent class instance
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    /**
     * Executes the PublishQuoteUserClickEvent use case.
     */
    public async execute({ clickEventName }: ArgsProps): AsyncResult<void> {
        const event = {
            id: UUID.create(),
            createdAt: new Date(Date.now()),
            origin: 'Quote',
            name: `QuoteUserClickEvent`,
            clickEventName: clickEventName,
        };

        await this.eventBus.publish(event);

        return Success();
    }
}

export const PublishQuoteUserClickEvent: UseCaseClass<PublishQuoteUserClickEvent> =
    PublishQuoteUserClickEventUseCase;
