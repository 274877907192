import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ApplicationRepository } from '../repositories/ApplicationRepository';
import { InsuranceApplicationStatusCode } from '../types/enums';

export interface GetApplicationStatuRequest {
    applicationId: UUID;
}

export interface GetApplicationStatusResponse {
    status: InsuranceApplicationStatusCode;
}

export interface GetApplicationStatus extends UseCase {
    execute(
        request: GetApplicationStatuRequest,
    ): AsyncResult<GetApplicationStatusResponse, InvalidArgument | OperationFailed>;
}

@injectable()
class GetAplicationStatusUseCase extends UseCase implements GetApplicationStatus {
    public static type = Symbol('Shopping/GetApplicationStatus');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: GetApplicationStatuRequest,
    ): AsyncResult<GetApplicationStatusResponse, InvalidArgument | OperationFailed> {
        const getApplicationResponse = await this.applicationRepository.getApplication(
            request.applicationId,
        );
        if (isErr(getApplicationResponse)) {
            return handleOperationFailure(getApplicationResponse);
        }
        const {
            value: { status },
        } = getApplicationResponse;

        return Success<GetApplicationStatusResponse>({
            status,
        });
    }
}

export const GetApplicationStatus: UseCaseClass<GetApplicationStatus> = GetAplicationStatusUseCase;
