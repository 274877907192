import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { GetGlobalConfigFailed } from '../errors';
import { GlobalConfigRepository } from '../repositories/GlobalConfigRepository';
import { GlobalConfig } from '../types/GlobalConfig';

export interface GetGlobalConfigUseCaseResponse {
    config: Immutable<GlobalConfig>;
}

export interface GetGlobalConfig extends UseCase {
    execute(): AsyncResult<GetGlobalConfigUseCaseResponse, GetGlobalConfigFailed | InvalidArgument>;
}

@injectable()
class GetGlobalConfigUseCase extends UseCase implements GetGlobalConfig {
    public static type = Symbol('global/GetGlobalConfig');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(GlobalConfigRepository) private globalConfigRepository: GlobalConfigRepository,
    ) {
        super(eventBus);
    }

    public async execute(): AsyncResult<
        GetGlobalConfigUseCaseResponse,
        GetGlobalConfigFailed | InvalidArgument
    > {
        const globalConfigResult = await this.globalConfigRepository.getGlobalConfig();

        if (isErr(globalConfigResult)) {
            return globalConfigResult;
        }

        const response: GetGlobalConfigUseCaseResponse = {
            config: globalConfigResult.value,
        };

        return Success(response);
    }
}

export const GetGlobalConfig: UseCaseClass<GetGlobalConfig> = GetGlobalConfigUseCase;
