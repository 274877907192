import { container } from '@embroker/shotwell/core/di';
import { Sanitizer } from '@embroker/shotwell/core/sanitization/Sanitizer';
import { Text, TextStyle, Tooltip } from '@embroker/ui-toolkit/v2';
import React from 'react';

export interface TextWithTooltipProps {
    tooltip?: string;
    title?: string;
    style: TextStyle;
}

export const TextWithTooltip = React.forwardRef(function TextWithTooltip(
    props: TextWithTooltipProps,
    ref: React.Ref<HTMLDivElement>,
) {
    return (
        <Text ref={ref} style={props.style}>
            {props.title ? (
                <span
                    dangerouslySetInnerHTML={{
                        __html: container.get<Sanitizer>(Sanitizer).innerHTMLString(props.title),
                    }}
                />
            ) : null}
            {props.tooltip ? <Tooltip iconSize="small" text={props.tooltip} /> : null}
        </Text>
    );
});
