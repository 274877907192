import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, NotAllowed, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { AsyncResult, isErr, isOK, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { CalculateSKU } from '../../../analytics/useCases/CalculateSKU';
import { InvalidAnnualTechFee } from '../../errors';
import { PCoMLQuote, PCoMLQuotePurchased } from '../entities/PCoMLQuote';
import { ConfigFetchFailed } from '../errors';
import { QuoteRepository } from '../repositories/QuoteRepository';

export interface PurchasePCoMLRequest {
    quote: PCoMLQuote;
}

export interface PurchasePCoMLResponse {
    policyId: UUID;
    policyDoneTaskId: UUID;
}

export interface PurchasePCoML extends UseCase {
    execute(
        request: PurchasePCoMLRequest,
    ): AsyncResult<
        PurchasePCoMLResponse,
        NotAllowed | InvalidArgument | OperationFailed | InvalidAnnualTechFee | ConfigFetchFailed
    >;
}

class PurchasePCoMLUseCase extends UseCase implements PurchasePCoML {
    public static type = Symbol('shopping/purchase');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(QuoteRepository) private quoteRepository: QuoteRepository,
        @inject(CalculateSKU.type) private calculateSku: CalculateSKU,
    ) {
        super(eventBus);
    }

    public async execute({
        quote,
    }: PurchasePCoMLRequest): AsyncResult<
        PurchasePCoMLResponse,
        NotAllowed | InvalidArgument | OperationFailed | InvalidAnnualTechFee | ConfigFetchFailed
    > {
        const acceptResult = quote.accept();
        if (isErr(acceptResult)) {
            return acceptResult;
        }

        const result = await this.quoteRepository.purchasePCoML(quote.applicationId, quote.id);
        if (isErr(result)) {
            return result;
        }

        const PCoMLQuoteResult = await this.quoteRepository.getQuoteByApplicationId(
            quote.applicationId,
        );
        let totalPremium: Nullable<Money> = null;
        if (isOK(PCoMLQuoteResult)) {
            totalPremium = PCoMLQuoteResult.value.totalPremium;
        }

        const skuResult = await this.calculateSku.execute({
            event: 'purchase',
            applicationId: quote.applicationId,
        });

        const purchaseEvent: PCoMLQuotePurchased = {
            origin: 'PCoMLQuote',
            name: 'Purchased',
            id: quote.id,
            applicationId: quote.applicationId,
            isOFACRejected: result.value.isOFACRejected,
            createdAt: new Date(Date.now()),
            totalPremium,
            sku: skuResult.value,
            isRenewal: quote.applicationInfo?.isRenewal ?? false,
        };
        await this.eventBus.publish(purchaseEvent);

        return Success({
            policyId: result.value.policyId,
            policyDoneTaskId: result.value.policyDoneTaskId,
        });
    }
}

export const PurchasePCoML: UseCaseClass<PurchasePCoML> = PurchasePCoMLUseCase;
