import { APIError } from '@embroker/shotwell-api/errors';
import { errorCodes } from '@embroker/shotwell/core/Error';
import { HTTPRequestError } from '@embroker/shotwell/core/networking';
import { Immutable } from '@embroker/shotwell/core/types';
import { ErrorLike, ErrorObject } from '@embroker/shotwell/core/types/Result';

interface StripeError {
    readonly name: string;
}

const GeneralPaymentsErrorCode = 0x4400;
const PaymentNotFoundCode = 0x4401;
const PaymentsNotAllChargedCode = 0x4402;
const StripeGenericDeclineCode = 0x4441;
const StripeCreditCardIncorrectCVCCode = 0x4442;
const StripeCreditCardInvalidCVCCode = 0x4443;
const StripeCreditCardInsufficientFundsCode = 0x4444;
const StripeCreditCardExpiredCode = 0x4445;
const StripeBankAccountUnusableCode = 0x4446;
const PremiumFinanceRoutingCodeInvalidCode = 0x4447;
const ConfigFetchFailedCode = 0x4448;

const PaymentsErrorCodeList = [
    GeneralPaymentsErrorCode,
    PaymentNotFoundCode,
    PaymentsNotAllChargedCode,
    StripeGenericDeclineCode,
    StripeCreditCardExpiredCode,
    StripeCreditCardIncorrectCVCCode,
    StripeCreditCardInvalidCVCCode,
    StripeCreditCardInsufficientFundsCode,
    StripeBankAccountUnusableCode,
    PremiumFinanceRoutingCodeInvalidCode,
    ConfigFetchFailedCode,
];
type PaymentsErrorCode = (typeof PaymentsErrorCodeList)[number];

export const ErrorCode = errorCodes({
    /*
        General Payments error code
     */
    GeneralPaymentsError: GeneralPaymentsErrorCode,
    /**
     * Payment not found
     */
    PaymentNotFound: PaymentNotFoundCode,
    /**
     * Payments not all charged
     */
    PaymentsNotAllCharged: PaymentsNotAllChargedCode,
    /*
     * Stripe generic decline code
     */
    StripeGenericDecline: StripeGenericDeclineCode,
    /*
     *eStripe credit card expired code
     */
    StripeCreditCardExpired: StripeCreditCardExpiredCode,
    /**
     * Incorrect CVC number provided on credit card
     */
    StripeCreditCardIncorrectCVC: StripeCreditCardIncorrectCVCCode,
    /**
     * Invalid CVC number provided on credit card
     */
    StripeCreditCardInvalidCVC: StripeCreditCardInvalidCVCCode,
    /*
     * Insufficient funds on credit card
     */
    StripeCreditCardInsufficientFunds: StripeCreditCardInsufficientFundsCode,
    /*
     * Stripe bank account unusable
     */
    StripeBankAccountUnusable: StripeBankAccountUnusableCode,
    /*
     * Premium Finance routing code invalid
     */
    PremiumFinanceRoutingCodeInvalid: PremiumFinanceRoutingCodeInvalidCode,
    /*
     * Get config
     */
    ConfigFetchFailed: ConfigFetchFailedCode,
});

/*
 *   Payment not found error
 */

export type PaymentNotFound = ErrorObject<PaymentsErrorCode>;

export function PaymentNotFound(): Immutable<PaymentNotFound> {
    return {
        name: 'PaymentNotFound',
        code: ErrorCode.PaymentNotFound,
        message: 'Payment not found.',
    };
}

export type PremiumFinanceRoutingCodeInvalid = ErrorObject<PaymentsErrorCode>;

export function PremiumFinanceRoutingCodeInvalid(): Immutable<PremiumFinanceRoutingCodeInvalid> {
    return {
        name: 'PremiumFinanceRoutingCodeInvalid',
        code: ErrorCode.PremiumFinanceRoutingCodeInvalid,
        message: 'Invalid bank routing number.',
    };
}

export type PaymentsNotAllCharged = ErrorObject<PaymentsErrorCode>;

export function PaymentsNotAllCharged(): Immutable<PaymentsNotAllCharged> {
    return {
        name: 'PaymentsNotAllCharged',
        code: ErrorCode.PaymentsNotAllCharged,
        message: 'Payments not all charged.',
    };
}

export type PaymentError = ErrorObject<PaymentsErrorCode>;

export type StripeGenericDecline = PaymentError;

export function StripeGenericDecline(): Immutable<StripeGenericDecline> {
    return {
        name: 'StripeGenericDecline',
        code: ErrorCode.StripeGenericDecline,
        message:
            "This card is declined by the card issuer. Please contact them directly to discuss the issue. For now, let's try another payment method.",
    };
}

export type StripeCreditCardExpired = PaymentError;

export function StripeCreditCardExpired(): Immutable<StripeCreditCardExpired> {
    return {
        name: 'StripeCreditCardExpired',
        code: ErrorCode.StripeCreditCardExpired,
        message: 'The provided credit card has expired. Try using a different credit card.',
    };
}

export type StripeCreditCardIncorrectCVC = PaymentError;

export function StripeCreditCardIncorrectCVC(): Immutable<StripeCreditCardIncorrectCVC> {
    return {
        name: 'StripeCreditCardIncorrectCVC',
        code: ErrorCode.StripeCreditCardIncorrectCVC,
        message:
            "The security code you entered doesn't seem quite right. Mind retyping the CVC and resubmitting for payment?",
    };
}

export type StripeCreditCardInvalidCVC = PaymentError;

export function StripeCreditCardInvalidCVC(): Immutable<StripeCreditCardInvalidCVC> {
    return {
        name: 'StripeCreditCardInvalidCVC',
        code: ErrorCode.StripeCreditCardInvalidCVC,
        message:
            'Hmm. Looks like the security code for this card is invalid. Please verify the code and try again, or use another payment method.',
    };
}

export type StripeCreditCardInsufficientFunds = PaymentError;

export function StripeCreditCardInsufficientFunds(): Immutable<StripeCreditCardInsufficientFunds> {
    return {
        name: 'StripeCreditCardInsufficientFunds',
        code: ErrorCode.StripeCreditCardInsufficientFunds,
        message:
            "We can't process your payment using this card due to insufficient funds. Please try a different payment method.",
    };
}

export type StripeBankAccountUnusable = PaymentError;

export function StripeBankAccountUnusable(): Immutable<StripeBankAccountUnusable> {
    return {
        name: 'StripeBankAccountUnusable',
        code: ErrorCode.StripeBankAccountUnusable,
        message:
            '"This bank account is not authorized for debit payments. Please try another payment method.',
    };
}

const PaymentNotFoundCodeString = 'cant_find';
const GenericDeclineCodeString = 'generic_decline';
const CreditCardExpiredString = 'card_expired';
const IncorrectCVCCodeString = 'incorrect_cvc';
const InvalidCVCCodeString = 'invalid_cvc';
const InsufficientFundsCodeString = 'insufficient_funds';
const BankAccountUnusableString = 'bank_account_unusable';
const PremiumFinanceRoutingCodeInvalidString = 'invalid_routing_code';

const PaymentErrorCodeStrings = [
    PaymentNotFoundCodeString,
    GenericDeclineCodeString,
    CreditCardExpiredString,
    IncorrectCVCCodeString,
    InvalidCVCCodeString,
    InsufficientFundsCodeString,
    BankAccountUnusableString,
    PremiumFinanceRoutingCodeInvalidString,
];

export function isPaymentError(
    errors: Immutable<(HTTPRequestError | APIError)[]>,
): PaymentError | undefined {
    const errorCode = PaymentErrorCodeStrings.find((string) =>
        errors.find((error) => (error.details as StripeError).name === string),
    );

    const error = errorCode ? errorMap.get(errorCode) : undefined;

    return error ? error() : undefined;
}

export function isPaymentErrorCode(errors: Immutable<ErrorLike[]>) {
    return errors.find((error) => PaymentsErrorCodeList.find((code) => code === error.code));
}

const errorMap = new Map([
    [PaymentNotFoundCodeString, PaymentNotFound],
    [GenericDeclineCodeString, StripeGenericDecline],
    [CreditCardExpiredString, StripeCreditCardExpired],
    [IncorrectCVCCodeString, StripeCreditCardIncorrectCVC],
    [InvalidCVCCodeString, StripeCreditCardInvalidCVC],
    [InsufficientFundsCodeString, StripeCreditCardInsufficientFunds],
    [BankAccountUnusableString, StripeBankAccountUnusable],
    [PremiumFinanceRoutingCodeInvalidString, PremiumFinanceRoutingCodeInvalid],
]);

export type ConfigFetchFailed = ErrorObject<typeof ErrorCode.ConfigFetchFailed, {}>;

export function ConfigFetchFailed(): Immutable<ConfigFetchFailed> {
    return {
        name: 'ConfigFetchFailed',
        code: ErrorCode.ConfigFetchFailed,
        message: 'Failed to fetch config API.',
        details: {},
    };
}
