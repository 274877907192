import { inject, injectable } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { EntityType } from '../entities/EntityType';
import { EntityTypeRepository } from '../repositories/EntityTypeRepository';

/**
 * Result from successful execution of GetEntityTypes use case
 */
export interface GetEntityTypesResponse {
    /**
     * This is the array of entity type objects
     */
    entityTypes: Immutable<EntityProps<EntityType>>[];
}

/**
 * Fetches entity types from repository
 */
export interface GetEntityTypes extends UseCase {
    execute(): AsyncResult<GetEntityTypesResponse, OperationFailed | InvalidArgument>;
}

@injectable()
class GetEntityTypesUseCase extends UseCase implements GetEntityTypes {
    public static type = Symbol('Global/GetEntityTypes');

    /**
     * constructor for the GetEntityTypes use case
     * @param eventBus An event bus this Use Case will publish events to.
     * @param entityTypeRepository is the repository used to fetch globaly available data
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(EntityTypeRepository) private readonly entityTypeRepository: EntityTypeRepository,
    ) {
        super(eventBus);
    }

    public async execute(): AsyncResult<GetEntityTypesResponse, OperationFailed | InvalidArgument> {
        const entityTypesResult = await this.entityTypeRepository.getEntityTypes();

        if (isErr(entityTypesResult)) {
            return entityTypesResult;
        }

        const result = entityTypesResult.value.map((item: EntityType) => {
            return item.toDTO();
        });

        return Success<GetEntityTypesResponse>({
            entityTypes: result,
        });
    }
}

export const GetEntityTypes: UseCaseClass<GetEntityTypes> = GetEntityTypesUseCase;
