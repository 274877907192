import { isDateBefore } from '@embroker/service-app-engine';
import { Nullable } from '@embroker/shotwell/core/types';
import { differenceInDays, isValid } from 'date-fns';
import {
    QuotingEngineBOPChubb,
    QuotingEngineCrime,
    QuotingEngineCyber,
    QuotingEngineCyberCowbell,
    QuotingEngineESP,
    QuotingEngineExcess,
    QuotingEngineLPLEverest,
    QuotingEngineLawCyber,
    QuotingEngineMPL,
    QuotingEnginePCoML,
    QuotingEngineWCChubb,
} from '../../shopping/types/enums';

interface getDaysLeftUntilExpirationInput {
    quotingEngine?: string;
    applicationStatus: string;
    validUntil: Nullable<Date>;
    quoteEffectiveDate: Date;
    today: Date;
    isBroker: boolean;
    omitEffectiveDateValidation?: boolean;
}
export const QuoteExpiration = {
    shouldDisplayQuoteExpirationLabel(appStatus: string, quotingEngine?: string): boolean {
        return (
            [
                QuotingEngineESP,
                QuotingEnginePCoML,
                QuotingEngineCrime,
                QuotingEngineCyber,
                QuotingEngineLPLEverest,
                QuotingEngineLawCyber,
                QuotingEngineExcess,
                QuotingEngineCyberCowbell,
                QuotingEngineMPL,
                QuotingEngineBOPChubb,
                QuotingEngineWCChubb,
            ].includes(quotingEngine ?? '') &&
            appStatus != 'InsuranceApplicationStatusCodeListQuoteExpired'
        );
    },

    toDaysLeftParam(daysLeft: number): number {
        // 0 days means that expires today, -1 day means expired
        return Math.max(-1, daysLeft);
    },

    daysLeftUsingValidUntilDate(validUntil: Date, today: Date): number {
        return this.toDaysLeftParam(differenceInDays(validUntil, today));
    },

    daysLeftUsingCloserDate(quoteEffectiveDate: Date, validUntil: Date, today: Date): number {
        const closerDate = isDateBefore(quoteEffectiveDate, validUntil)
            ? quoteEffectiveDate
            : validUntil;
        const daysLeft = differenceInDays(closerDate, today);
        return this.toDaysLeftParam(daysLeft);
    },

    getDaysLeftUntilExpiration(input: getDaysLeftUntilExpirationInput): number | undefined {
        if (!this.shouldDisplayQuoteExpirationLabel(input.applicationStatus, input.quotingEngine)) {
            return;
        }
        if (!input.validUntil || !isValid(input.validUntil)) {
            return;
        }

        // if test mode flag is enabled we return a 30 days period
        // this is used in cases when we need to bind quotes that have effective dates in the past
        if (input.omitEffectiveDateValidation) {
            const testModePeriodInDays = 30;
            return testModePeriodInDays;
        }

        // A temporary solution to prevent discrepancies on the Access side where the new
        // expiry date logic (that calculates in the effective date) is not yet implemented
        // Using the same temporary solution to differentiate between LPL and other coverages
        // For LPL we are counting difference from closer date between quote effective date and valid until date
        // which is application submission date + 45 days(+60 days for the LPL)

        // And now Cowbell coverage joins the mix; Quotes from cowbell always expire in a maximum of 21 days
        // from when the quote occured or the effective date chosen by the user, whichever comes first.
        const useApplicationValidUntilDate =
            input.isBroker ||
            (input.quotingEngine !== QuotingEngineLPLEverest &&
                input.quotingEngine !== QuotingEngineCyberCowbell &&
                input.quotingEngine !== QuotingEngineMPL);

        if (useApplicationValidUntilDate) {
            return this.daysLeftUsingValidUntilDate(input.validUntil, input.today);
        }

        if (!input.quoteEffectiveDate || !isValid(input.quoteEffectiveDate)) {
            return;
        }
        const closestDaysLeft = this.daysLeftUsingCloserDate(
            input.quoteEffectiveDate,
            input.validUntil,
            input.today,
        );
        const mplQuoteValidity = 31;
        if (input.quotingEngine === QuotingEngineMPL) {
            return closestDaysLeft > mplQuoteValidity ? mplQuoteValidity : closestDaysLeft;
        }
        return closestDaysLeft;
    },
};
