import { defineEntityValidator, entity, Entity } from '@embroker/shotwell/core/entity/Entity';
import { Nullable } from '@embroker/shotwell/core/types';
import { Joi } from '@embroker/shotwell/core/validation/schema';

export interface GeneratedDocument extends Entity {
    readonly fileName: string;
    readonly fileKey: Nullable<string>;
}

export const GeneratedDocument = entity<GeneratedDocument>({
    validator: defineEntityValidator<GeneratedDocument>({
        fileName: Joi.string(),
        fileKey: Joi.string().allow(null),
    }),
});
