import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable, Props } from '@embroker/shotwell/core/types';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { Organization } from '../entities/Organization';
import { OrganizationRepository } from '../repositories/OrganizationRepository';
import { Unauthenticated } from '../errors';

export interface GetOrganizationsForUserRequest {
    userId: UUID;
}

export interface GetOrganizationsForUserResponse {
    organizations: Immutable<Props<Organization>>[];
}

export interface GetOrganizationsForUser extends UseCase {
    execute(
        request: GetOrganizationsForUserRequest,
    ): AsyncResult<
        GetOrganizationsForUserResponse,
        InvalidArgument | OperationFailed | Unauthenticated
    >;
}

class GetOrganizationsForUserUseCase extends UseCase implements GetOrganizationsForUser {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/GetOrganizationsForUser');
    /**
     * Constructor for GetOrganizationsForUser use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param organizationRepository is user repository used to get organization list
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(OrganizationRepository) private organizationRepository: OrganizationRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        userId,
    }: GetOrganizationsForUserRequest): AsyncResult<
        GetOrganizationsForUserResponse,
        InvalidArgument | OperationFailed | Unauthenticated
    > {
        const organizationsResult = await this.organizationRepository.getOrganizationsForUser(
            userId,
        );
        if (isErr(organizationsResult)) {
            return organizationsResult;
        }

        return Success({
            organizations: organizationsResult.value,
        });
    }
}

export const GetOrganizationsForUser: UseCaseClass<GetOrganizationsForUser> =
    GetOrganizationsForUserUseCase;
