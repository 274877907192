import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Immutable } from '@embroker/shotwell/core/types';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    BlockButton,
    Button,
    Card,
    GridLayout,
    Icon,
    StackLayout,
    Text,
} from '@embroker/ui-toolkit/v2';
import React, { useEffect, useState } from 'react';
import { BorStatusIsBor, BorStatusNotBor } from '../../../policy/types/BorStatus';
import { PolicySortByAlphabet } from '../../../policy/types/PolicySortBy';
import { GetPolicies, GetPoliciesResponse } from '../../../policy/useCases/GetPolicies';
import { Link } from '../../../view/components';

interface NoCertificatesPageProps {
    openCertificateSlideOut: () => void;
}

export function NoCertificatesPage({ openCertificateSlideOut }: NoCertificatesPageProps) {
    const [showTransferPolicies, setShowTransferPolicies] = useState(false);
    const [hasNoPolicies, setHasNoPolicies] = useState(true);
    const { result: getPoliciesResult } = useUseCase(GetPolicies, {
        filter: {
            showActive: true,
            showInactive: false,
            sortBy: PolicySortByAlphabet,
        },
    });

    useEffect(() => {
        if (!getPoliciesResult) {
            return;
        }
        if (isOK(getPoliciesResult)) {
            setHasNoPolicies(getPoliciesResult.value.policyList.length === 0);
            if (arePoliciesOnlyNonBor(getPoliciesResult.value)) {
                setShowTransferPolicies(true);
            }
        } else {
            container.get<Logger>(Log).error(getPoliciesResult.errors);
        }
    }, [getPoliciesResult]);

    return (
        <React.Fragment>
            <StackLayout>
                {hasNoPolicies ? (
                    <ShopForCoverage />
                ) : showTransferPolicies ? (
                    <TransferPolicies />
                ) : (
                    <GetCertificates openCertificateSlideOut={openCertificateSlideOut} />
                )}
            </StackLayout>
            <GridLayout>
                <Text style="heading 4" className="u-grid-size-12">
                    Looking For Something?
                </Text>
                <BlockButton
                    as={Link}
                    className="u-grid-size-6"
                    href="/shopping"
                    data-e2e="certs-add-new-coverage"
                >
                    <Icon name="shield" color="primary-500" />
                    <Text style="label 1">Add new coverage</Text>
                </BlockButton>
                <BlockButton
                    className="u-grid-size-6"
                    as="a"
                    href="#live-agent"
                    data-e2e="certs-live-chat"
                >
                    <Icon name="chat" color="primary-500" />
                    <Text style="label 1">Chat with an Embroker Advisor</Text>
                </BlockButton>
            </GridLayout>
        </React.Fragment>
    );
}

function arePoliciesOnlyNonBor(data: Immutable<GetPoliciesResponse>): boolean {
    let anyBor = false;
    let anyNonBor = false;
    anyBor = data.policyList.some((policy) => {
        return policy.borStatus === BorStatusIsBor;
    });
    anyNonBor = data.policyList.some((policy) => {
        return policy.borStatus === BorStatusNotBor;
    });

    return !anyBor && anyNonBor;
}

function ShopForCoverage() {
    return (
        <Card center className="u-grid-size-12">
            <Text style="body 1">
                You have no policies yet to request certificates of coverage.
            </Text>
            <Button as={Link} href="/summary" data-e2e="certs-shop-for-coverage">
                Shop for coverage
            </Button>
        </Card>
    );
}

function TransferPolicies() {
    return (
        <Card center className="u-grid-size-12">
            <Text style="body 1">
                If you’d like us to issue your certificates, please transfer management of your
                active policies to Embroker.
            </Text>
            <Button as={Link} href="/policies">
                Transfer to Embroker
            </Button>
        </Card>
    );
}

interface GetCertificatesProps {
    openCertificateSlideOut: () => void;
}

function GetCertificates({
    openCertificateSlideOut: openCertificateSlideOut,
}: GetCertificatesProps) {
    return (
        <Card center className="u-grid-size-12">
            <Text style="body 1">You currently don’t have any certificates.</Text>
            <Button onClick={openCertificateSlideOut} data-e2e="no-certs-get-button">
                Get Certificate
            </Button>
        </Card>
    );
}
