import {
    BoxLayout,
    Button,
    CenterLayout,
    ColumnLayout,
    Image,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { useNavigation } from '../../../view/hooks/useNavigation';

export function BrokerDeclinedPage() {
    const { navigate } = useNavigation();

    return (
        <BoxLayout gap="56">
            <StackLayout gap="32">
                <CenterLayout>
                    <Image name="shotwell-service" />
                </CenterLayout>
                <CenterLayout centerText>
                    <StackLayout gap="32">
                        <Text as="h1" style="heading 1">
                            It's not you, it's us!
                        </Text>
                        <Text as="p" style="body 1">
                            Sorry, this company does not qualify for a quote. Please contact us if
                            you have any questions or need more information.
                        </Text>
                        <Text as="h4" style="heading 4">
                            You can contact us directly:
                        </Text>
                        <CenterLayout centerText>
                            <ColumnLayout>
                                <TextButton href="#chat-now" icon="live-chat">
                                    Live chat
                                </TextButton>
                                <TextButton
                                    icon="phone"
                                    color="primary-500"
                                    href="tel:844.436.2765"
                                >
                                    Call 844.436.2765
                                </TextButton>
                            </ColumnLayout>
                        </CenterLayout>
                        <CenterLayout>
                            <Button onClick={() => navigate('/broker/dashboard')}>
                                Back to Dashboard
                            </Button>
                        </CenterLayout>
                    </StackLayout>
                </CenterLayout>
            </StackLayout>
        </BoxLayout>
    );
}
