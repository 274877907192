import { FormElement } from '@embroker/service-app-engine';
import { AppTypeCodeListItem } from '@embroker/shotwell-api/enums';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Data, Immutable } from '@embroker/shotwell/core/types';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { SessionRepository } from '../../userOrg/repositories/SessionRepository';
import { Applicant } from '../entities/Applicant';
import { Application } from '../entities/Application';
import { ApplicantRepository } from '../repositories/ApplicantRepository';
import { ApplicationRepository } from '../repositories/ApplicationRepository';
import { WCDataRepository } from '../repositories/WCDataRepository';
import { DeepLinkData } from '../types/DeepLinkData';
import { AppTypeCode, ShoppingCoverageCodeToAppTypeCodeMap, Underlying } from '../types/enums';
import { QuestionnaireData } from '../types/QuestionnaireData';
import { GetFormEngineContext, toFormEngineInput } from './GetFormEngineContext';
import { GetFormEngineInstance } from './GetFormEngineInstance';
import { OverrideQuestionnaireData } from './OverrideQuestionnaireData';
import { AppDetails } from '../types/AppDetails';
import { GlobalConfig } from '../../config/types/GlobalConfig';

export interface GetApplicationCreationQuestionnaireRequest {
    deepLinkData?: DeepLinkData;
    globalConfig?: GlobalConfig;
}

export interface GetApplicationCreationQuestionnaireResponse {
    appTypeList: AppTypeCode[];
    formEngine: FormElement;
}

export interface GetApplicationCreationQuestionnaire extends UseCase {
    execute(
        request: GetApplicationCreationQuestionnaireRequest,
    ): AsyncResult<GetApplicationCreationQuestionnaireResponse, InvalidArgument | OperationFailed>;
}

@injectable()
class GetApplicationCreationQuestionnaireUseCase
    extends UseCase
    implements GetApplicationCreationQuestionnaire
{
    public static type = Symbol('Shopping/GetApplicationCreationQuestionnaire');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
        @inject(ApplicantRepository) private applicantRepository: ApplicantRepository,
        @inject(SessionRepository) private sessionRepository: SessionRepository,
        @inject(WCDataRepository) private wcDataRepository: WCDataRepository,
        @inject(OverrideQuestionnaireData.type)
        private overrideQuestionnaireData: OverrideQuestionnaireData,
        @inject(GetFormEngineContext.type) private getFormEngineContext: GetFormEngineContext,
        @inject(GetFormEngineInstance.type) private getFormEngineInstance: GetFormEngineInstance,
    ) {
        super(eventBus);
    }

    public async execute({
        deepLinkData,
        globalConfig,
    }: GetApplicationCreationQuestionnaireRequest): AsyncResult<
        GetApplicationCreationQuestionnaireResponse,
        InvalidArgument | OperationFailed
    > {
        const isIptChangesEnabled = globalConfig?.espIptChangesEnabled || false;

        const prefillResponse = await this.applicationRepository.getPrefill();

        let applicationToBeCopied: Immutable<EntityProps<Application>> | undefined;
        const appTypeList: AppTypeCode[] = applicationToBeCopied
            ? getAppTypesFromApplications(applicationToBeCopied)
            : deepLinkData?.appTypes ?? [];

        const underlyingAppTypes = applicationToBeCopied?.bundleDetails
            ? AppDetails.getAppTypes(applicationToBeCopied?.bundleDetails?.appDetails, {
                  creationType: Underlying,
              })
            : [];

        if (isErr(prefillResponse)) {
            return handleOperationFailure(prefillResponse);
        }

        const prefill = prefillResponse.value;

        const overrideQuestionnaireDataResponse = await this.overrideQuestionnaireData.execute({
            questionnaireData: {},
            prefill,
            overrideWebsite: true,
        });

        if (isErr(overrideQuestionnaireDataResponse)) {
            return handleOperationFailure(overrideQuestionnaireDataResponse);
        }

        const questionnaireData = overrideQuestionnaireDataResponse.value.questionnaireData;

        const activeSessionResult = await this.sessionRepository.getActiveSession();

        if (isErr(activeSessionResult)) {
            return handleOperationFailure(activeSessionResult);
        }

        if (activeSessionResult.value === null || activeSessionResult.value === undefined) {
            return Failure(
                OperationFailed({ message: 'Detected null for value in activeSessionResult' }),
            );
        }
        const applicantResult = await this.applicantRepository.getApplicant();
        if (isErr(applicantResult)) {
            return handleOperationFailure(applicantResult);
        }

        const parsedPrefill = toFormEngineInput(prefill);
        const parsedQuestionnaireData = toFormEngineInput(questionnaireData);
        const context = {
            data: parsedQuestionnaireData,
            prefill: parsedPrefill,
            newApplication: true,
            previousData: {},
            isAutofilled: applicantResult.value.isAutofilled,
            isBroker: false,
            appTypeList: appTypeList,
            isIptChangesEnabled: isIptChangesEnabled,
            underlyingAppTypes,
        };

        this.wcDataRepository.setActiveCodesIdToSessionStorage();

        const formEngineContextResponse = await this.getFormEngineContext.execute({
            context,
        });

        if (isErr(formEngineContextResponse)) {
            return handleOperationFailure(formEngineContextResponse);
        }

        const { formEngineContext } = formEngineContextResponse.value;

        if (deepLinkData) {
            overrideContextDataWithDeepLinkData(formEngineContext, deepLinkData);
            const applicant = applicantResult.value;
            const deepLinkDataForApplicant = {
                numberOfEmployees: applicant.numberOfEmployees,
                doEmployeesTravelInternationally: applicant.doEmployeesTravelInternationally,
                naicsIndustry:
                    deepLinkData.naicsCode !== undefined
                        ? deepLinkData.naicsCode
                        : applicant.naicsIndustry,
                hasReceivedVCFunding:
                    deepLinkData.raisedFunding !== undefined
                        ? deepLinkData.raisedFunding
                        : applicant.hasReceivedVCFunding,
            };
            applicant.update(deepLinkDataForApplicant);
            const result = await this.applicantRepository.update(applicant as Applicant);
            if (isErr(result)) {
                return handleOperationFailure(result);
            }
        }
        const getFormEngineResult = await this.getFormEngineInstance.execute({
            isNewApplication: true,
            formEngineContext,
        });
        if (isErr(getFormEngineResult)) {
            return getFormEngineResult;
        }
        const formEngine = getFormEngineResult.value.instance;

        formEngine.machine.update({
            submitText: 'Save and Continue',
        });

        if (deepLinkData?.firstPage) {
            formEngine.gotoPage(deepLinkData.firstPage);
        }

        const result: GetApplicationCreationQuestionnaireResponse = {
            formEngine,
            appTypeList,
        };

        return Success(result);
    }
}

function getAppTypesFromApplications(application: Immutable<EntityProps<Application>>) {
    if (application.shoppingCoverageList.length === 0) {
        return [application.appType];
    }
    return application.shoppingCoverageList.map((code) => {
        return ShoppingCoverageCodeToAppTypeCodeMap[code];
    });
}

function overrideContextDataWithDeepLinkData(feContext: Data, deepLinkData: DeepLinkData) {
    const { naicsCode, raisedFunding, includeTechEO } = deepLinkData;
    const data = { ...(feContext.data as QuestionnaireData) };
    if (raisedFunding !== undefined) {
        data.raised_venture_funding = raisedFunding;
    }
    if (isAppTypeOnlyTechEO(deepLinkData.appTypes)) {
        data.raised_venture_funding = false;
        data.is_tech_eo_additional_question = true;
    }
    if (naicsCode) {
        data.naics_code = naicsCode;
    }
    if (includeTechEO !== undefined) {
        data.is_tech_eo_additional_question = includeTechEO;
    }
    feContext.data = data;
}

function isAppTypeOnlyTechEO(appTypes?: AppTypeCodeListItem[]): boolean | undefined {
    return appTypes?.includes('AppTypeCodeListTechEO') && appTypes.length == 1;
}

export const GetApplicationCreationQuestionnaire: UseCaseClass<GetApplicationCreationQuestionnaire> =
    GetApplicationCreationQuestionnaireUseCase;
