import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { State } from '@embroker/shotwell/core/types/StateList';

export interface CyberUserInfo {
    readonly company: string;
    readonly fullName: string;
    readonly title: string;
    readonly usaState: State;
}

export const CyberUserInfo = {
    ...defineValidator<CyberUserInfo>({
        company: Joi.string().required(),
        fullName: Joi.string().required(),
        title: Joi.string().required(),
        usaState: State.schema.allow(null),
    }),
    create(userInfo: CyberUserInfo) {
        return CyberUserInfo.validate(userInfo);
    },
};
