import React, { useContext, useEffect } from 'react';
import { Modal, useModal } from '@embroker/ui-toolkit/v2';
import { AppContext } from '../../../../../view/AppContext';
import { ModalLayout } from '../../../../../view/components/ModalLayout.view';

export function AdditionalInfoSuccessModal() {
    const { closeSlideout, setSlideout } = useContext(AppContext);
    const modal = useModal({ visible: true });

    useEffect(() => {
        if (!modal.visible) {
            closeSlideout();
            setSlideout(null);
        }
    }, [closeSlideout, modal.visible, setSlideout]);

    return (
        <Modal {...modal} size="small" dismissable>
            <ModalLayout
                title="Success!"
                primaryAction={{
                    label: 'Got It',
                    onClick: () => {
                        modal.hide();
                    },
                }}
            >
                We have received the information you submitted. We will review it and get back to
                you as soon as possible.
            </ModalLayout>
        </Modal>
    );
}
