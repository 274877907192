import { Money } from '@embroker/shotwell/core/types/Money';
import {
    defineValidator,
    Joi,
    Schema,
    TypeChecker,
    Validator,
} from '@embroker/shotwell/core/validation/schema';
import { LiabilitySection } from './LiabilitySection';
import { WorkersCompensationLocation } from './WorkersCompensationLocation';

/**
 * Represents a commonly used "line of business" object
 */
export interface WorkersCompensationLiabilitySection extends LiabilitySection {
    /**
     * Gross premium
     */
    grossPremium: Money;
    /**
     * List of locations
     */
    locationList: WorkersCompensationLocation[];
    /**
     * Officers included
     */
    officersIncluded: boolean;
}

export interface WorkersCompensationLiabilitySectionValidator {
    /**
     * A Joi schema matching a valid LobWC object.
     */
    readonly schema: Schema.ObjectSchema<WorkersCompensationLiabilitySection>;
    /**
     * Type predicate that checks if a given value can be used as WorkersCompensationLiabilitySection object.
     *
     * Use this only to do early returns. It's recommended to use validate()
     * before using an unknown value as WorkersCompensationLiabilitySection object as it normalizes the value
     * in addition to performing the same validation as check().
     */
    readonly check: TypeChecker<WorkersCompensationLiabilitySection>;
    /**
     * Validates and normalizes the given value to a WorkersCompensationLiabilitySection object.
     *
     * This should be used for all untrusted inputs to verify and, if required
     * (and possible), normalize the input.
     */
    readonly validate: Validator<WorkersCompensationLiabilitySection>;
}

export const WorkersCompensationLiabilitySection = {
    ...defineValidator<WorkersCompensationLiabilitySection>(
        LiabilitySection.schema.keys({
            grossPremium: Money.schema,
            locationList: Joi.array().items(WorkersCompensationLocation.schema),
            officersIncluded: Joi.boolean(),
        }),
    ),
    create(workersCompensation: WorkersCompensationLiabilitySection) {
        return WorkersCompensationLiabilitySection.validate(workersCompensation);
    },
};
