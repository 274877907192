import { Money } from '@embroker/shotwell/core/types/Money';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface CertificateCoverageLimit {
    liabilityTypeCode?: string;
    limitTypeCode?: string;
    customName?: string;
    amount?: Money;
    description?: string;
}

export const CertificateCoverageLimit = {
    ...defineValidator<CertificateCoverageLimit>(
        Joi.object({
            liabilityTypeCode: Joi.string().optional(),
            limitTypeCode: Joi.string().optional(),
            customName: Joi.string().optional().allow(''),
            amount: Money.schema.optional(),
            description: Joi.string().optional().allow(''),
        }).preferences({ presence: 'required' }),
    ),
    create(certificateCoverageLimit: CertificateCoverageLimit) {
        return CertificateCoverageLimit.validate(certificateCoverageLimit);
    },
};
