import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success, isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { PolicyRepository } from '../../../policy/repositories/PolicyRepository';
import { EndorsementIntakeRepository } from '../repositories/EndorsementIntakeRepository';

export interface RequestNameOrAddressChangeEndorsementRequest {
    readonly policyId: UUID;
    readonly effectiveDate: Date;
    readonly insuredName: string;
    readonly reasonForChange: string;
    readonly reasonForChangeAdditionalDetails?: string;
    readonly address: string;
    readonly city: string;
    readonly state: string;
    readonly zip: string;
}

export interface RequestNameOrAddressChangeEndorsement extends UseCase {
    execute(
        request: RequestNameOrAddressChangeEndorsementRequest,
    ): AsyncResult<void, UnknownEntity | InvalidArgument | OperationFailed>;
}

@injectable()
class RequestNameOrAddressChangeEndorsementUseCase
    extends UseCase
    implements RequestNameOrAddressChangeEndorsement
{
    public static type = Symbol('OtherEndorsement/RequestNameOrAddressChangeEndorsement');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(EndorsementIntakeRepository)
        private endorsementIntakeRepository: EndorsementIntakeRepository,
        @inject(PolicyRepository) private policyRepository: PolicyRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: RequestNameOrAddressChangeEndorsementRequest,
    ): AsyncResult<void, UnknownEntity | OperationFailed | InvalidArgument> {
        const policyResult = await this.policyRepository.getPolicy(request.policyId);
        if (isErr(policyResult)) {
            return policyResult;
        }

        policyResult.value.endorse();

        const endorsementResult =
            await this.endorsementIntakeRepository.requestNameOrAddressChangeEndorsement(request);

        if (isErr(endorsementResult)) {
            return endorsementResult;
        }

        this.eventBus.publishEntityEvents(policyResult.value);

        return Success();
    }
}

export const RequestNameOrAddressChangeEndorsement: UseCaseClass<RequestNameOrAddressChangeEndorsement> =
    RequestNameOrAddressChangeEndorsementUseCase;
