import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { PhoneNumber } from '@embroker/shotwell/core/types/PhoneNumber';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    isOK,
    Result,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { Revenue } from '@embroker/shotwell/core/types/Revenue';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Year } from '@embroker/shotwell/core/types/Year';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { UpdateClientOrganizationRequest } from '../../brokerDashboard/useCases/UpdateClientOrganization';
import { Location as LocationType } from '../../locations/entities/Location';
import { hasRole } from '../../userOrg/entities/Session';
import { SessionRepository } from '../../userOrg/repositories/SessionRepository';
import { GetEntityTypes } from '../../userOrg/useCases/GetEntityTypes';
import {
    UpdateOrganizationProfile,
    UpdateOrganizationProfileRequest,
} from '../../userOrg/useCases/UpdateOrganizationProfile';
import {
    UpdateUserProfile,
    UpdateUserProfileRequest,
} from '../../userOrg/useCases/UpdateUserProfile';
import { AnswerMissing } from '../errors';
import { ApplicantRepository } from '../repositories/ApplicantRepository';
import { Location } from '../types/Location';
import { FilledQuestionnaireData } from '../types/QuestionnaireData';

export interface UpdateApplicantFromBusinessProfileRequest {
    questionnaireData: Immutable<FilledQuestionnaireData>;
}

export interface UpdateApplicantFromBusinessProfile extends UseCase {
    execute(
        request: UpdateApplicantFromBusinessProfileRequest,
    ): AsyncResult<void, UnknownEntity | InvalidArgument | OperationFailed | AnswerMissing>;
}

@injectable()
class UpdateApplicantFromBusinessProfileUseCase
    extends UseCase
    implements UpdateApplicantFromBusinessProfile
{
    public static type = Symbol('Shopping/UpdateApplicantFromBusinessProfile');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicantRepository) private applicantRepository: ApplicantRepository,
        @inject(SessionRepository) private sessionRepository: SessionRepository,
        @inject(GetEntityTypes.type) private getEntityTypes: GetEntityTypes,
        @inject(UpdateOrganizationProfile.type)
        private updateOrganizationProfile: UpdateOrganizationProfile,
        @inject(UpdateUserProfile.type) private updateUserProfile: UpdateUserProfile,
    ) {
        super(eventBus);
    }

    public async execute({
        questionnaireData,
    }: UpdateApplicantFromBusinessProfileRequest): AsyncResult<
        void,
        UnknownEntity | InvalidArgument | OperationFailed | AnswerMissing
    > {
        const applicantResult = await this.applicantRepository.getApplicant();
        if (isErr(applicantResult)) {
            return applicantResult;
        }
        const applicant = applicantResult.value;

        const getEntityTypesResponse = await this.getEntityTypes.execute();

        if (isErr(getEntityTypesResponse)) {
            return handleOperationFailure(getEntityTypesResponse);
        }

        const activeSessionResult = await this.sessionRepository.getActiveSession();

        if (activeSessionResult.value === null || activeSessionResult.value === undefined) {
            return Failure(
                OperationFailed({ message: 'Detected null for value in activeSessionResult' }),
            );
        }

        const entityName = questionnaireData.entity;

        const entityType = getEntityTypesResponse.value.entityTypes.find(
            (entity) => entity.name === entityName,
        );

        if (entityName && !entityType) {
            return Failure(
                InvalidArgument({
                    argument: 'questionnaireData.entity',
                    value: questionnaireData.entity,
                }),
            );
        }

        const organizationProfileData = questionnaireToOrganizationData(
            applicant.id,
            questionnaireData,
            entityType?.id,
        );
        const updateOrganizationProfileResponse = await this.updateOrganizationProfile.execute(
            organizationProfileData,
        );

        if (isErr(updateOrganizationProfileResponse)) {
            return handleOperationFailure(updateOrganizationProfileResponse);
        }

        const isBroker = hasRole(activeSessionResult.value, 'broker');

        // is user is a broker, we do not override his data with the client data
        if (!isBroker) {
            const userDataResult = questionnaireToUserData(questionnaireData);
            if (isErr(userDataResult)) {
                return userDataResult;
            }
            const updateUserProfileResponse = await this.updateUserProfile.execute(
                userDataResult.value,
            );
            if (isErr(updateUserProfileResponse)) {
                return handleOperationFailure(updateUserProfileResponse);
            }
        }

        return Success();
    }
}

export function questionnaireToClientOrganizationData(
    token: string,
    answers: Immutable<FilledQuestionnaireData>,
): UpdateClientOrganizationRequest {
    let phoneNumber;
    const phoneNumberResult = PhoneNumber.create(String(answers.phone_number ?? ''));
    if (isOK(phoneNumberResult)) {
        phoneNumber = phoneNumberResult.value;
    }
    return {
        token,
        totalNumberOfEmployees: answers.number_of_employees ?? null,
        naics: answers.naics_code || null,
        headquarters: {
            addressLine1: answers.mailing_address || null,
            addressLine2: answers.suite || null,
            city: answers.city ?? null,
            state: answers.state ?? null,
            county: answers.county ?? null,
            zip: answers.zip ?? null,
        },
        companyLegalName: answers.company_name ?? '',
        website: answers.website ?? '',
        yearStarted: answers.year_started ?? null,
        totalAnnualPayroll: answers.total_payroll
            ? Money.tryFromFloat(answers.total_payroll)
            : null,
        howDoesYourCompanyGenerateRevenue: answers.product_or_service ?? null,
        revenues: answers.revenue_list?.map(toRevenue) ?? [],
        otherLocations: answers.locations?.location.map(toOtherLocation) ?? [],
        raisedFunding:
            answers.raised_venture_funding ?? answers.broker_raised_venture_funding ?? null,
        techAreaOfFocus: answers.tech_area_of_focus ?? null,
        phoneNumber,
    } as UpdateClientOrganizationRequest;
}

function questionnaireToOrganizationData(
    applicantId: UUID,
    answers: Immutable<FilledQuestionnaireData>,
    entityTypeId?: UUID,
): UpdateOrganizationProfileRequest {
    let phoneNumber;
    const phoneNumberResult = PhoneNumber.create(String(answers.phone_number ?? ''));
    if (isOK(phoneNumberResult)) {
        phoneNumber = phoneNumberResult.value;
    }
    const organizationProfileData: UpdateOrganizationProfileRequest = {
        id: applicantId,
        totalNumberOfEmployees: answers.number_of_employees ?? null,
        naics: answers.naics_code || null,
        headquarters: {
            addressLine1: answers.mailing_address || null,
            addressLine2: answers.suite || null,
            city: answers.city ?? null,
            state: answers.state ?? null,
            county: answers.county ?? null,
            zip: answers.zip ?? null,
        },
        companyLegalName: answers.company_name ?? '',
        entityType: entityTypeId ?? null,
        website: answers.website ?? '',
        yearStarted: answers.year_started ?? null,
        totalAnnualPayroll: answers.total_payroll
            ? Money.tryFromFloat(answers.total_payroll)
            : null,
        howDoesYourCompanyGenerateRevenue: answers.product_or_service ?? null,
        revenues: answers.revenue_list?.map(toRevenue) ?? [],
        otherLocations: answers.locations?.location.map(toOtherLocation) ?? [],
        raisedFunding:
            answers.raised_venture_funding ?? answers.broker_raised_venture_funding ?? null,
        techAreaOfFocus: answers.tech_area_of_focus ?? null,
        email: answers.email ?? null,
        phoneNumber,
    };
    return organizationProfileData;
}

function questionnaireToUserData(
    answers: Immutable<FilledQuestionnaireData>,
): Result<UpdateUserProfileRequest, AnswerMissing> {
    if (answers.first_name && answers.last_name && answers.phone_number && answers.title) {
        const userData: UpdateUserProfileRequest = {
            firstName: answers.first_name,
            lastName: answers.last_name,
            title: answers.title,
            phoneNumber: answers.phone_number.toString() as PhoneNumber,
        };

        return Success(userData);
    }
    return Failure(AnswerMissing());
}

function numberToCurrency(value: any): Nullable<Money> {
    if (typeof value === 'number') {
        return Money.tryFromFloat(value);
    }
    return null;
}

function toRevenue(item: any): Revenue {
    return {
        fiscalYear: item.fiscal_year as Year,
        grossTotal: numberToCurrency(item.gross_revenue_total),
    };
}

function toOtherLocation(qdPlace: Location): LocationType {
    const locationId = qdPlace.id ? qdPlace.id : UUID.create();
    return {
        id: locationId,
        addressLine1: qdPlace.mailing_address,
        addressLine2: qdPlace.suite,
        city: qdPlace.city,
        state: qdPlace.state,
        county: qdPlace.county,
        zip: qdPlace.zip,
        squareFootageOccupied: qdPlace.square_footage ?? null,
        valueOfProperty: null,
    } as LocationType;
}

export const UpdateApplicantFromBusinessProfile: UseCaseClass<UpdateApplicantFromBusinessProfile> =
    UpdateApplicantFromBusinessProfileUseCase;
