import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import {
    Modal,
    ModalActions,
    ModalState,
    ScrollBox,
    StackLayout,
    Text,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { DATE_FORMAT } from './SignInsuranceCowbellNoKnownLossesSignature';

interface CowbellNoKnownLossesModalProps {
    modal: ModalActions & ModalState;
    quoteNumber?: string;
    companyName: string;
    effectiveDate: Date;
}

export function CowbellNoKnownLossesModal({
    modal,
    quoteNumber,
    companyName,
    effectiveDate,
}: CowbellNoKnownLossesModalProps) {
    const wrongfulActElement = (
        <React.Fragment>
            <strong>Wrongful Act, Cyber Incident, Extortion Threat, Security Breach, </strong>
            or<strong> Interrelated Wrongful Act</strong>
        </React.Fragment>
    );
    const claims = <strong>Claims</strong>;
    const claim = <strong>Claim</strong>;
    const loss = <strong>Loss</strong>;

    return (
        <Modal {...modal} size="medium" dismissable>
            <ScrollBox>
                <StackLayout gap="32">
                    <StackLayout gap="24">
                        <Text style="heading 3">Representation of No Known Losses</Text>
                        <StackLayout gap="12">
                            {quoteNumber && (
                                <Text style="overline">Quote Number: {quoteNumber}</Text>
                            )}
                            <Text style="overline">
                                Policy Effective Date:{' '}
                                <DateDisplay format={DATE_FORMAT} value={effectiveDate} />
                            </Text>
                            <Text style="overline">First Named Insured: {companyName}</Text>
                        </StackLayout>
                        <Text style="body 1">
                            I, as the undersigned, affirm that between the Policy Effective Date and
                            the date this Representation of No Known Losses is executed, (1) no{' '}
                            {claims} have been made, and that the undersigned knows of no losses or
                            threats of any {claims} to be made against any person or entity who, or
                            which could be, covered under the Policy referenced above, unless such
                            has already been reported to the Insurer; and (2) there have been no
                            allegations of any actual or alleged {wrongfulActElement} that the
                            undersigned has reason to believe might give rise to a {claim} under the
                            Policy, unless such has already been reported to the Insurer.
                        </Text>
                        <Text style="body 1">
                            I further affirm that as the date this Representation of No Known Losses
                            is executed, no Insured proposed for coverage has knowledge of any fact,
                            circumstance, situation or actual or alleged {wrongfulActElement} which
                            they have reason to believe might result in a future {claim} under the
                            Policy or could be considered a {loss} covered under the Policy, unless
                            such has already been reported to the Insurer. The undersigned
                            understands that any {claim} or {loss} resulting from any such fact,
                            circumstance, situation or actual or alleged {wrongfulActElement} is not
                            covered under the Policy.
                        </Text>
                        <Text style="body 1">
                            I further affirm and understand that if it is established that I was
                            aware of any {wrongfulActElement} that was not already reported to the
                            Insurer prior to the execution date of this Affirmation, the submission
                            of this Affirmation constitutes a material misrepresentation and will
                            result in the immediate cancellation of this Policy and the denial of
                            any claim for coverage or defense. I further understand that if the
                            Insurer becomes obligated to make any payment under this Policy for a
                            loss, occurrence, accident, or other circumstance or event occurring
                            between the inception date and the date this Affirmation is executed by
                            me for which I was aware and which was not already reported to the
                            Insurer, the first Named Insured shall be solely responsible for
                            reimbursing the Insurer for such payment and any expenses incurred from
                            such payment to the fullest extent permitted. I affirm that I have the
                            authority to attest to this information on behalf of the first Named
                            Insured.
                        </Text>
                    </StackLayout>
                </StackLayout>
            </ScrollBox>
        </Modal>
    );
}
