import { AppTypeCodeList } from '@embroker/shotwell-api/enums';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success, isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ApplicationRepository } from '../repositories/ApplicationRepository';

export interface GetPartialEligibilityRequest {
    applicationId: UUID;
}

export interface GetPartialEligibilityResponse {
    appTypeList: AppTypeCodeList;
}

export interface GetPartialEligibility extends UseCase {
    execute(
        request: GetPartialEligibilityRequest,
    ): AsyncResult<GetPartialEligibilityResponse, InvalidArgument | OperationFailed>;
}

@injectable()
class GetPartialEligibilityUseCase extends UseCase implements GetPartialEligibility {
    public static type = Symbol('Shopping/GetPartialEligibility');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
    }: GetPartialEligibilityRequest): AsyncResult<GetPartialEligibilityResponse, OperationFailed> {
        const applicationResult = await this.applicationRepository.getPartialEligibility(
            applicationId,
        );
        if (isErr(applicationResult)) {
            return applicationResult;
        }

        return Success({ appTypeList: applicationResult.value });
    }
}

export const GetPartialEligibility: UseCaseClass<GetPartialEligibility> =
    GetPartialEligibilityUseCase;
