import { inject } from '@embroker/shotwell/core/di';
import {
    Aborted,
    InvalidArgument,
    OperationFailed,
    Timeout,
    UnknownEntity,
} from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { CalculateSKU } from '../../../analytics/useCases/CalculateSKU';
import { ApplicationFetchFailed, QuestionnaireParsingFailed } from '../../errors';
import { ESPRenewalQuote, ESPRenewalQuotePurchased } from '../entities/ESPRenewalQuote';
import { ESPRenewalBeforeLastEndorsement, ESPRenewalConfigFetchFailed } from '../errors';
import { QuoteRepository } from '../repositories/QuoteRepository';
import { ESPRenewalQuoteOptions } from '../types/ESPRenewalQuoteOptions';
import { UpdateQuote } from './UpdateQuote';

export interface PurchaseESPRenewalRequest {
    quote: ESPRenewalQuote;
    quoteOptions: ESPRenewalQuoteOptions;
    abortSignal: AbortSignal;
}

export interface PurchaseESPRenewalResponse {
    policyId: UUID;
    policyDoneTaskId: UUID;
}

export interface PurchaseESPRenewal extends UseCase {
    execute(
        request: PurchaseESPRenewalRequest,
    ): AsyncResult<
        PurchaseESPRenewalResponse,
        | InvalidArgument
        | OperationFailed
        | ESPRenewalConfigFetchFailed
        | ESPRenewalBeforeLastEndorsement
        | ApplicationFetchFailed
        | QuestionnaireParsingFailed
        | Timeout
        | Aborted
        | UnknownEntity
    >;
}

class PurchaseESPRenewalUseCase extends UseCase implements PurchaseESPRenewal {
    public static type = Symbol('ESPRenewalQuote/PurchaseESPRenewal');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(QuoteRepository) private quoteRepository: QuoteRepository,
        @inject(UpdateQuote.type) private updateQuote: UpdateQuote,
        @inject(CalculateSKU.type) private calculateSKU: CalculateSKU,
    ) {
        super(eventBus);
    }

    public async execute({
        quote,
        quoteOptions,
        abortSignal,
    }: PurchaseESPRenewalRequest): AsyncResult<
        PurchaseESPRenewalResponse,
        | InvalidArgument
        | OperationFailed
        | ESPRenewalConfigFetchFailed
        | ESPRenewalBeforeLastEndorsement
        | ApplicationFetchFailed
        | QuestionnaireParsingFailed
        | Timeout
        | Aborted
        | UnknownEntity
    > {
        if (!quote.fileKey) {
            const updateQuoteResult = await this.updateQuote.execute({
                quote: quote,
                quoteOptions: quoteOptions,
                abortSignal,
            });
            if (isErr(updateQuoteResult)) {
                return updateQuoteResult;
            }
            quote = updateQuoteResult.value.quote;
        }
        const purchaseResult = await this.quoteRepository.purchaseESPRenewal(
            quote.applicationId,
            quote.id,
        );

        if (isErr(purchaseResult)) {
            for (const error of purchaseResult?.errors) {
                if (error?.name === ESPRenewalBeforeLastEndorsement.name) {
                    return purchaseResult;
                }
            }
            return handleOperationFailure(purchaseResult);
        }

        const quoteResult = await this.quoteRepository.getQuoteByApplicationId(quote.applicationId);
        if (isErr(quoteResult)) {
            return handleOperationFailure(quoteResult);
        }

        const { value: ESPRenewalQuote } = quoteResult;

        const skuResult = await this.calculateSKU.execute({
            event: 'purchase',
            applicationId: quote.applicationId,
        });

        const purchaseRenewalQuoteEvent: ESPRenewalQuotePurchased = {
            origin: 'ESPRenewalQuote',
            name: 'Purchased',
            id: UUID.create(),
            createdAt: new Date(Date.now()),
            totalPremium: ESPRenewalQuote.totalPayable,
            applicationId: ESPRenewalQuote.applicationId,
            sku: skuResult.value,
        };

        this.eventBus.publish(purchaseRenewalQuoteEvent);

        return Success({
            policyId: purchaseResult.value.policyId,
            policyDoneTaskId: purchaseResult.value.policyDoneTaskId,
        });
    }
}

export const PurchaseESPRenewal: UseCaseClass<PurchaseESPRenewal> = PurchaseESPRenewalUseCase;
