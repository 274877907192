import { Immutable } from '@embroker/shotwell/core/types';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { StatusLabel, Text, TextButton } from '@embroker/ui-toolkit/v2';
import React from 'react';
import {
    ClientReservation,
    InsuranceApplicationStatusCode,
    InsuranceApplicationStatusCodeListMap,
    QuotesReady,
    Referred,
} from '../../../../shopping/types/enums';
import { SelectOrganization } from '../../../../userOrg/useCases/SelectOrganization';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { BrokerQuoteRecord } from '../../../types/BrokerQuoteRecord';
import {
    GetBrokerQuotes,
    GetBrokerQuotesRequest,
    QuoteTableColumn,
} from '../../../useCases/GetBrokerQuotes';
import { BrokerQuoteActionButton } from '../BrokerQuoteActionButton';

// Constants for quotes statuses
const AWAITING_QUOTES = 'Awaiting Quotes';
const QUESTIONNAIRE_IN_PROGRESS = 'Questionnaire In Progress';
const IN_CREATION = 'In Creation';
const EARLY_CLEARANCE_FAILED = 'Early Clearance Failed';
const NOT_ELIGIBLE = 'Not Eligible';

export interface BrokerQuoteTableRow extends Record<string, unknown> {
    readonly id: UUID;
    readonly companyName: React.ReactNode;
    readonly managingBroker: React.ReactNode;
    readonly dateStarted: React.ReactNode;
    readonly dateSubmitted: React.ReactNode;
    readonly status: React.ReactNode;
    readonly coverageType: React.ReactNode;
    readonly premium: React.ReactNode;
    readonly action: React.ReactNode;
}
interface getQuotesPageDataParams {
    itemsPerPage: number;
    pageIndex: number;
    column?: QuoteTableColumn;
    order?: 'ASC' | 'DESC';
    filters?: {
        target: QuoteTableColumn;
        value: string;
    }[];
}

function buildGetBrokerQuotesRequest({
    itemsPerPage,
    pageIndex,
    column,
    order,
    filters,
}: getQuotesPageDataParams): GetBrokerQuotesRequest {
    return {
        column,
        order: order ? (order === 'ASC' ? 'ascending' : 'descending') : undefined,
        index: pageIndex,
        size: itemsPerPage,
        filters,
    };
}

export function getQuotesPageDataFunction(isExcessEditAppEnabled: boolean) {
    return async function getQuotesPageData(params: getQuotesPageDataParams) {
        const request = buildGetBrokerQuotesRequest(params);

        const result = await execute(GetBrokerQuotes, request);
        if (isOK(result)) {
            return {
                items: toQuotesTableRows(result.value.quoteList, isExcessEditAppEnabled),
                totalItems: result.value.pageInfo.totalItems,
            };
        }
        return {
            items: [],
            totalItems: 0,
        };
    };
}

function toQuotesTableRows(
    quoteTableData: Immutable<BrokerQuoteRecord[]>,
    excessRenewalsEnabled: boolean,
): BrokerQuoteTableRow[] {
    return quoteTableData.map((quote: Immutable<BrokerQuoteRecord>) => {
        return {
            id: quote.applicationId,
            companyName: (
                <CompanyNameButton
                    id={quote.organizationId}
                    name={quote.organizationName}
                    status={quote.status}
                    daysToExpiry={quote.daysToQuoteExpiration}
                />
            ),
            managingBroker: <Text style="default">{quote.managingBroker}</Text>,
            status: (
                <StatusLabel fixed type={BrokerStatusToColorMap[quote.status]}>
                    {brokerageCodeToStatusLabel(quote.status)}
                </StatusLabel>
            ),
            coverageType: <Text style="default"> {quote.coverageType ?? 'N/A'}</Text>,
            dateStarted: quote.dateStarted ? (
                <DateDisplay format="MMM d, yyyy" value={quote.dateStarted} />
            ) : (
                <div>N/A</div>
            ),
            dateSubmitted: quote.dateSubmitted ? (
                <DateDisplay format="MMM d, yyyy" value={quote.dateSubmitted} />
            ) : (
                <div>N/A</div>
            ),
            premium:
                quote.premium && quote.status !== Referred ? (
                    <MoneyDisplay value={quote.premium} />
                ) : (
                    <div>N/A</div>
                ),
            action:
                quote.creationType === ClientReservation ? null : (
                    <BrokerQuoteActionButton
                        applicationId={quote.applicationId}
                        organizationId={quote.organizationId}
                        isPristine={quote.isPristine}
                        status={quote.status}
                        hasQuotes={quote.hasQuotes}
                        quotingEngine={quote.quotingEngine}
                        ineligibilityReasons={quote.ineligibilityReasons}
                        showEditApplication={
                            !(
                                quote.appType === 'AppTypeCodeListEmbrokerExcess' &&
                                !excessRenewalsEnabled
                            )
                        }
                    />
                ),
        };
    });
}

interface CompanyNameButtonProps {
    id: UUID;
    status: InsuranceApplicationStatusCode;
    name: string;
    daysToExpiry?: number;
}

function CompanyNameButton({ name, id, daysToExpiry, status }: CompanyNameButtonProps) {
    const { navigate } = useNavigation();

    const daysToExpiryComponent = daysToExpiry !== undefined && status === QuotesReady && (
        <Text as="div" style="microcopy" color="ui-500">
            {daysToExpiry === 0
                ? 'Quote expires today'
                : `Quote expires in ${daysToExpiry} ${daysToExpiry === 1 ? 'day' : 'days'}`}
        </Text>
    );

    const handleCompanySelection = async () => {
        await execute(SelectOrganization, { organizationId: id });
        navigate(
            URI.build('/broker/organization-info', {
                organizationId: id,
            }),
        );
    };

    return (
        <React.Fragment>
            <TextButton onClick={handleCompanySelection}>{name}</TextButton>
            {daysToExpiryComponent}
        </React.Fragment>
    );
}

export function brokerageCodeToStatusLabel(code: InsuranceApplicationStatusCode) {
    const status = InsuranceApplicationStatusCodeListMap[code];
    switch (status) {
        // There is no manual quoting for brokers, so awaiting quotes status is used to inform them about ineligibility for digital quoting
        case AWAITING_QUOTES:
        case NOT_ELIGIBLE:
            return 'Declined';
        case IN_CREATION:
        case QUESTIONNAIRE_IN_PROGRESS:
            return 'In Progress';
        case EARLY_CLEARANCE_FAILED:
            return 'Clearance';
        default:
            return status;
    }
}

export const BrokerStatusToColorMap = {
    InsuranceApplicationStatusCodeListQuestionnaireInProgress: 'gray',
    InsuranceApplicationStatusCodeListAwaitingQuotes: 'red',
    InsuranceApplicationStatusCodeListQuotesReady: 'green',
    InsuranceApplicationStatusCodeListPurchased: 'green',
    InsuranceApplicationStatusCodeListBound: 'green',
    InsuranceApplicationStatusCodeListCanceledByAdmin: 'red',
    InsuranceApplicationStatusCodeListAutoCanceled: 'red',
    InsuranceApplicationStatusCodeListDeleted: 'red',
    InsuranceApplicationStatusCodeListInProgressByAdmin: 'gray',
    InsuranceApplicationStatusCodeListQuoteExpired: 'red-outline',
    InsuranceApplicationStatusCodeListSubmissionInProgress: 'gray',
    InsuranceApplicationStatusCodeListReferred: 'yellow-outline',
    InsuranceApplicationStatusCodeListClearanceFailed: 'yellow-outline',
    InsuranceApplicationStatusCodeListEarlyClearanceFailed: 'yellow-outline',
    InsuranceApplicationStatusCodeListReferredAwaitingReview: 'yellow-outline',
    InsuranceApplicationStatusCodeListOFACFailed: 'yellow-outline',
    InsuranceApplicationStatusCodeListAwaitingBind: 'yellow-outline',
    InsuranceApplicationStatusCodeListNotEligible: 'red',
    InsuranceApplicationStatusCodeListDeclinedByCarrier: 'red',
    InsuranceApplicationStatusCodeListInCreation: 'gray',
    InsuranceApplicationStatusCodeListBundleSubmitted: 'gray',
    InsuranceApplicationStatusCodeListSupplementalInProgress: 'yellow-outline',
} as const;
