import React, { useEffect, useContext } from 'react';
import {
    PageLayout,
    BoxLayout,
    BoxLayoutProps,
    CoverLayout,
    useResponsive,
    FormLayout,
    Box,
} from '@embroker/ui-toolkit/v2';
import { AppContext } from '../../../view/AppContext';
import { MarketingSideBanner } from '../../../view/components/SideBanner/MarketingSideBanner.view';

export interface PublicPageLayoutProps {
    children: React.ReactNode;
}

export interface ResponsiveCoverLayoutProps extends BoxLayoutProps {
    children: React.ReactNode;
}

function ResponsiveCoverLayout({ children, ...props }: ResponsiveCoverLayoutProps) {
    const isLargeDesktop = useResponsive({ screenWidth: { greaterThan: 'large-tablet' } });

    return isLargeDesktop ? (
        <CoverLayout {...props}>{children}</CoverLayout>
    ) : (
        <div>{children}</div>
    );
}

export function PublicPageLayout({ children }: PublicPageLayoutProps) {
    const { setSidebar } = useContext(AppContext);

    useEffect(() => {
        setSidebar(<MarketingSideBanner />);

        return () => {
            setSidebar(null);
        };
    }, [setSidebar]);

    return (
        <PageLayout.Section>
            <ResponsiveCoverLayout as={BoxLayout} gap="32">
                <FormLayout as={Box} maxWidth={544} border={false}>
                    {children}
                </FormLayout>
            </ResponsiveCoverLayout>
        </PageLayout.Section>
    );
}
