import { inject } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { Applicant } from '../entities/Applicant';
import { ApplicantRepository } from '../repositories/ApplicantRepository';

export interface GetApplicantResponse {
    applicant: Immutable<EntityProps<Applicant>>;
}

export interface GetApplicant extends UseCase {
    execute(): AsyncResult<GetApplicantResponse, UnknownEntity | InvalidArgument | OperationFailed>;
}

class GetApplicantUseCase extends UseCase implements GetApplicant {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Shopping/GetApplicant');
    /**
     * Constructor for GetApplicant use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param applicantRepository is applicant repository used to store applicant entity with updated properties
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicantRepository) private applicantRepository: ApplicantRepository,
    ) {
        super(eventBus);
    }

    public async execute(): AsyncResult<
        GetApplicantResponse,
        UnknownEntity | InvalidArgument | OperationFailed
    > {
        const applicantResult = await this.applicantRepository.getApplicant();

        if (isErr(applicantResult)) {
            return applicantResult;
        }

        return Success<GetApplicantResponse>({
            applicant: applicantResult.value as Applicant,
        });
    }
}

export const GetApplicant: UseCaseClass<GetApplicant> = GetApplicantUseCase;
