import { inject } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { Application } from '../entities/Application';
import { ApplicationRepository } from '../repositories/ApplicationRepository';

export interface ExpandedApplicationView extends EntityProps<Application> {
    isRenewal: boolean;
}

export interface GetApplicationResponse {
    application: Immutable<ExpandedApplicationView>;
}

export interface GetApplicationRequest {
    applicationId: UUID;
}

export interface GetApplication extends UseCase {
    execute({
        applicationId,
    }: GetApplicationRequest): AsyncResult<
        GetApplicationResponse,
        UnknownEntity | InvalidArgument | OperationFailed
    >;
}

class GetApplicationUseCase extends UseCase implements GetApplication {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Shopping/GetApplication');
    /**
     * Constructor for GetApplication use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param applicantRepository is applicant repository used to store applicant entity with updated properties
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
    }: GetApplicationRequest): AsyncResult<
        GetApplicationResponse,
        UnknownEntity | InvalidArgument | OperationFailed
    > {
        const applicationResult = await this.applicationRepository.getApplication(applicationId);

        if (isErr(applicationResult)) {
            return applicationResult;
        }

        const application = applicationResult.value as Application;

        return Success<GetApplicationResponse>({
            application: {
                ...application.toDTO(),
                isRenewal: application.isRenewal(),
            },
        });
    }
}

export const GetApplication: UseCaseClass<GetApplication> = GetApplicationUseCase;
