import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { PremiumFinanceQuotePaymentInput, QuoteRepository } from '../repositories/QuoteRepository';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { Money, USD } from '@embroker/shotwell/core/types/Money';

export interface PremiumFinanceEvent extends DomainEvent {
    origin: string;
    name: string;
    paymentAmount: Money;
}

type FifBillingMethod = 'ACH' | 'Card' | 'Invoice';

export interface PayByPremiumFinanceRequest {
    readonly invoiceIds: UUID[];
    readonly organizationID: UUID;
    readonly quoteNumber: number;
    readonly signedDateTimeTermsAndConditions?: Date;
    readonly billingMethod: FifBillingMethod;
}

export interface PayByPremiumFinance extends UseCase {
    execute(
        request: PayByPremiumFinanceRequest,
    ): AsyncResult<boolean, InvalidArgument | OperationFailed>;
}

@injectable()
class PayByPremiumFinanceUseCase extends UseCase {
    public static type = Symbol('Payments/PayByPremiumFinance');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(QuoteRepository) private quoteRepo: QuoteRepository,
    ) {
        super(eventBus);
    }
    public async execute(
        input: PayByPremiumFinanceRequest,
    ): AsyncResult<boolean, InvalidArgument | OperationFailed> {
        const premiumFinanceQuotePaymentInput = PremiumFinanceQuotePaymentInput.create({
            signedDateTimeTermsAndConditions: input.signedDateTimeTermsAndConditions,
            billingMethod: input.billingMethod,
            invoiceIds: input.invoiceIds,
            organizationId: input.organizationID,
            quoteNumber: input.quoteNumber,
        });
        if (isErr(premiumFinanceQuotePaymentInput)) {
            return handleOperationFailure(premiumFinanceQuotePaymentInput);
        }

        const chargeResult = await this.quoteRepo.sendQuotePayment(
            premiumFinanceQuotePaymentInput.value,
        );
        if (isErr(chargeResult)) {
            const paymentFailed: PremiumFinanceEvent = {
                origin: 'Invoice',
                name: 'OnPremiumFinance',
                paymentAmount: USD(0),
                id: UUID.create(),
                createdAt: new Date(Date.now()),
            };

            await this.eventBus.publish(paymentFailed);
            return handleOperationFailure(chargeResult);
        }

        const paymentSuccess: PremiumFinanceEvent = {
            origin: 'Invoice',
            name: 'OnPremiumFinance',
            paymentAmount: USD(0),
            id: UUID.create(),
            createdAt: new Date(Date.now()),
        };

        await this.eventBus.publish(paymentSuccess);

        return Success(chargeResult.value);
    }
}

export const PayByPremiumFinance: UseCaseClass<PayByPremiumFinance> = PayByPremiumFinanceUseCase;
