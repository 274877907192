import { AppTypeCode } from './enums';

export const AppTypeCodeListManualAuto = 'AppTypeCodeListManualAuto';
export const AppTypeCodeListEmbrokerCrime = 'AppTypeCodeListEmbrokerCrime';
export const AppTypeCodeListManualCrime = 'AppTypeCodeListManualCrime';
export const AppTypeCodeListEmbrokerCyber = 'AppTypeCodeListEmbrokerCyber';
export const AppTypeCodeListManualCyber = 'AppTypeCodeListManualCyber';
export const AppTypeCodeListManualDirectorsAndOfficers =
    'AppTypeCodeListManualDirectorsAndOfficers';
export const AppTypeCodeListManualEmploymentPractices = 'AppTypeCodeListManualEmploymentPractices';
export const AppTypeCodeListManualFiduciary = 'AppTypeCodeListManualFiduciary';
export const AppTypeCodeListGeneralLiability = 'AppTypeCodeListGeneralLiability';
export const AppTypeCodeListManualConstructionGL = 'AppTypeCodeListManualConstructionGL';
export const AppTypeCodeListManualCannabis = 'AppTypeCodeListManualCannabis';
export const AppTypeCodeListManualGL = 'AppTypeCodeListManualGL';
export const AppTypeCodeListManualProperty = 'AppTypeCodeListManualProperty';
export const AppTypeCodeListManualAccountantsPL = 'AppTypeCodeListManualAccountantsPL';
export const AppTypeCodeListManualTechEO = 'AppTypeCodeListManualTechEO';
export const AppTypeCodeListManualDefaultPL = 'AppTypeCodeListManualDefaultPL';
export const AppTypeCodeListManualConstructionPL = 'AppTypeCodeListManualConstructionPL';
export const AppTypeCodeListManualLawyersProfessionalLiability =
    'AppTypeCodeListManualLawyersProfessionalLiability';
export const AppTypeCodeListEverestLawyersProfessionalLiability =
    'AppTypeCodeListEverestLawyersProfessionalLiability';
export const AppTypeCodeListProductLiability = 'AppTypeCodeListProductLiability';
export const AppTypeCodeListManualProductLiability = 'AppTypeCodeListManualProductLiability';
export const AppTypeCodeListProperty = 'AppTypeCodeListProperty';
export const AppTypeCodeListManualUmbrella = 'AppTypeCodeListManualUmbrella';
export const AppTypeCodeListUmbrella = 'AppTypeCodeListUmbrella';
export const AppTypeCodeListGAWorkersCompensation = 'AppTypeCodeListGAWorkersCompensation';
export const AppTypeCodeListManualWorkersCompensation = 'AppTypeCodeListManualWorkersCompensation';
export const AppTypeCodeListOther = 'AppTypeCodeListOther';
export const AppTypeCodeListTravel = 'AppTypeCodeListTravel';
export const AppTypeCodeListManualTravel = 'AppTypeCodeListManualTravel';
export const AppTypeCodeListCannabis = 'AppTypeCodeListCannabis';
export const AppTypeCodeListManualVentureCapitalAssetProtection =
    'AppTypeCodeListManualVentureCapitalAssetProtection';
export const AppTypeCodeListManualHomeownersAssociation =
    'AppTypeCodeListManualHomeownersAssociation';
export const AppTypeCodeListHomeownersAssociation = 'AppTypeCodeListHomeownersAssociation';
export const AppTypeCodeListCNABOP = 'AppTypeCodeListCNABOP';
export const AppTypeCodeListESP = 'AppTypeCodeListESP';
export const AppTypeCodeListPCoML = 'AppTypeCodeListPCoML';
export const AppTypeCodeListLawBundle = 'AppTypeCodeListLawBundle';
export const AppTypeCodeListLawBundleCyber = 'AppTypeCodeListLawBundleCyber';
export const AppTypeCodeListCyberCowbell = 'AppTypeCodeListCyberCowbell';
export const AppTypeCodeListEmbrokerExcess = 'AppTypeCodeListEmbrokerExcess';
export const AppTypeCodeListBOPChubb = 'AppTypeCodeListBOPChubb';
export const AppTypeCodeListMPL = 'AppTypeCodeListMPL';
export const AppTypeCodeListMPLBundle = 'AppTypeCodeListMPLBundle';
export const AppTypeCodeListWCChubb = 'AppTypeCodeListWCChubb';
export const AppTypeCodeListHartfordBOP = 'AppTypeCodeListHartfordBOP';

export const ShoppingCoverageCodeListWorkersCompensation =
    'ShoppingCoverageCodeListWorkersCompensation';
export const ShoppingCoverageCodeListDirectorsAndOfficers =
    'ShoppingCoverageCodeListDirectorsAndOfficers';
export const ShoppingCoverageCodeListEmploymentPractices =
    'ShoppingCoverageCodeListEmploymentPractices';
export const ShoppingCoverageCodeListFiduciary = 'ShoppingCoverageCodeListFiduciary';
export const ShoppingCoverageCodeListProfessionalLiability =
    'ShoppingCoverageCodeListProfessionalLiability';
export const ShoppingCoverageCodeListCyber = 'ShoppingCoverageCodeListCyber';
export const ShoppingCoverageCodeListCrime = 'ShoppingCoverageCodeListCrime';
export const ShoppingCoverageCodeListGeneralLiability = 'ShoppingCoverageCodeListGeneralLiability';
export const ShoppingCoverageCodeListProperty = 'ShoppingCoverageCodeListProperty';
export const ShoppingCoverageCodeListProductLiability = 'ShoppingCoverageCodeListProductLiability';

export const AppTypesCodes: AppTypeCode[] = [
    AppTypeCodeListESP,
    AppTypeCodeListPCoML,
    AppTypeCodeListManualAuto,
    AppTypeCodeListEmbrokerCrime,
    AppTypeCodeListManualCrime,
    AppTypeCodeListEmbrokerCyber,
    AppTypeCodeListManualCyber,
    AppTypeCodeListManualDirectorsAndOfficers,
    AppTypeCodeListManualEmploymentPractices,
    AppTypeCodeListManualFiduciary,
    AppTypeCodeListManualConstructionGL,
    AppTypeCodeListManualCannabis,
    AppTypeCodeListManualGL,
    AppTypeCodeListManualProperty,
    AppTypeCodeListManualConstructionPL,
    AppTypeCodeListManualAccountantsPL,
    AppTypeCodeListManualTechEO,
    AppTypeCodeListManualDefaultPL,
    AppTypeCodeListManualLawyersProfessionalLiability,
    AppTypeCodeListEverestLawyersProfessionalLiability,
    AppTypeCodeListManualProductLiability,
    AppTypeCodeListManualUmbrella,
    AppTypeCodeListGAWorkersCompensation,
    AppTypeCodeListManualWorkersCompensation,
    AppTypeCodeListOther,
    AppTypeCodeListManualTravel,
    AppTypeCodeListManualVentureCapitalAssetProtection,
    AppTypeCodeListManualHomeownersAssociation,
    AppTypeCodeListCNABOP,
    AppTypeCodeListLawBundle,
    AppTypeCodeListLawBundleCyber,
    AppTypeCodeListCyberCowbell,
    AppTypeCodeListEmbrokerExcess,
    AppTypeCodeListBOPChubb,
    AppTypeCodeListMPLBundle,
    AppTypeCodeListMPL,
    AppTypeCodeListMPLBundle,
    AppTypeCodeListWCChubb,
    AppTypeCodeListHartfordBOP,
];

export const AppTypePrettyPrint = {
    AppTypeCodeListESP: 'Embroker Startup Program',
    AppTypeCodeListPCoML: 'Business Management Insurance',
    AppTypeCodeListAuto: 'Commercial Auto',
    AppTypeCodeListManualAuto: 'Commercial Auto',
    AppTypeCodeListEmbrokerCrime: 'Crime',
    AppTypeCodeListManualCrime: 'Crime',
    AppTypeCodeListEmbrokerCyber: 'Cyber',
    AppTypeCodeListManualCyber: 'Cyber Liability and Data Breach',
    AppTypeCodeListManualDirectorsAndOfficers: 'Directors and Officers',
    AppTypeCodeListManualEmploymentPractices: 'Employment Practices Liability',
    AppTypeCodeListManualFiduciary: 'Fiduciary',
    AppTypeCodeListGeneralLiability: 'General Liability',
    AppTypeCodeListManualConstructionGL: 'General Liability',
    AppTypeCodeListManualGL: 'General Liability',
    AppTypeCodeListManualCannabis: 'Cannabis',
    AppTypeCodeListManualAccountantsPL: 'Accountants Professional Liability / Errors and Omissions',
    AppTypeCodeListManualDefaultPL: 'Professional Liability / Errors and Omissions',
    AppTypeCodeListManualTechEO: 'Technology Errors and Omissions / Cyber',
    AppTypeCodeListTechEO: 'Technology Errors and Omissions / Cyber',
    AppTypeCodeListManualConstructionPL:
        'Architects and Engineers Professional Liability / Errors and Omissions',
    AppTypeCodeListManualLawyersProfessionalLiability:
        'Lawyers Professional Liability / Errors and Omissions',
    AppTypeCodeListEverestLawyersProfessionalLiability:
        'Lawyers Professional Liability / Errors and Omissions',
    AppTypeCodeListProperty: 'Property',
    AppTypeCodeListManualProperty: 'Property',
    AppTypeCodeListProductLiability: 'Product Liability',
    AppTypeCodeListManualProductLiability: 'Product Liability',
    AppTypeCodeListManualUmbrella: 'Umbrella',
    AppTypeCodeListUmbrella: 'Umbrella',
    AppTypeCodeListGAWorkersCompensation: 'Workers Compensation',
    AppTypeCodeListManualWorkersCompensation: 'Workers Compensation',
    AppTypeCodeListOther: 'Other Liability',
    AppTypeCodeListTravel: 'International Package',
    AppTypeCodeListManualTravel: 'International Package',
    AppTypeCodeListManualVentureCapitalAssetProtection: 'Venture Capital Asset Protection',
    AppTypeCodeListHomeownersAssociation: 'Homeowners Association',
    AppTypeCodeListManualHomeownersAssociation: 'Homeowners Association',
    AppTypeCodeListCNABOP: 'Business Owners Policy',
    AppTypeCodeListBOPChubb: 'Business Owners Policy',
    AppTypeCodeListLawBundle: 'Law Bundle',
    AppTypeCodeListLawBundleCyber: 'Cyber',
    AppTypeCodeListCyberCowbell: 'Cyber',
    AppTypeCodeListEmbrokerExcess: 'Embroker Excess',
    AppTypeCodeListMPLBundle: 'Combined',
    AppTypeCodeListMPL: 'Miscellaneous Professional Liability',
    AppTypeCodeListWCChubb: 'Workers Compensation',
    AppTypeCodeListHartfordBOP: 'Business Owners Policy',
    AppTypeCodeListGenericBundle: 'TBD',
};

// Currently used within specs for form engine select
export const AppTypeSelectItems = AppTypesCodes.map((code) => ({
    value: code,
    title: AppTypePrettyPrint[code],
}));

// Currently passed back in getAppTypes in shopping service, compatible with API for application creation
export const AppTypeDefs = AppTypesCodes.map((code) => ({
    appType: code,
    name: AppTypePrettyPrint[code],
}));
