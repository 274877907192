import {
    TextButton,
    Modal,
    ModalActions,
    ModalState,
    StackLayout,
    Text,
    ColumnLayout,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { useNavigation } from '../../../../view/hooks/useNavigation';

interface ThankYouModalProps {
    modal: ModalState & ModalActions;
}

export const EstimateThankYouModal = function ThankYouModal({ ...props }: ThankYouModalProps) {
    const { navigate } = useNavigation();
    const handleCompleteAnotherApp = () => navigate('/summary');

    return (
        <Modal {...props.modal} dismissable={false}>
            <StackLayout gap="32">
                <StackLayout gap="24">
                    <Text style="heading 3">We’ve received your application</Text>
                    <Text>
                        One of our brokers is reviewing your request and will contact you soon. Stay
                        tuned for the next steps!
                    </Text>
                </StackLayout>
                <ColumnLayout gap="32">
                    <TextButton onClick={handleCompleteAnotherApp}>
                        Start another application
                    </TextButton>
                </ColumnLayout>
            </StackLayout>
        </Modal>
    );
};
