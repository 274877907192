import { Nominal } from '@embroker/shotwell/core/types/Nominal';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { LPLAreaOfPracticeCode, LPLAreaOfPracticeCodeList } from './enums';

export interface LPLAreaOfPractice {
    areaOfPracticeCode: LPLAreaOfPracticeCode;
    percentage: number;
}

export const LPLAreaOfPractice = {
    ...defineValidator<LPLAreaOfPractice>({
        areaOfPracticeCode: Joi.string()
            .valid(...LPLAreaOfPracticeCodeList)
            .required(),
        percentage: Joi.number().min(0).max(100).required(),
    }),
    create(areaOfPractice: LPLAreaOfPractice) {
        return LPLAreaOfPractice.validate(areaOfPractice);
    },
};

export type LPLAreaOfPracticeList = Nominal<Array<LPLAreaOfPractice>, 'LPLAreaOfPracticeList'>;

export const LPLAreaOfPracticeList = {
    ...defineValidator<LPLAreaOfPracticeList>(
        Joi.array()
            .items(LPLAreaOfPractice.schema)
            .custom((value, helpers) => {
                const total = value.reduce(
                    (acc: number, obj: LPLAreaOfPractice) => acc + (obj?.percentage ?? 0),
                    0,
                );

                if (total !== 100) {
                    return helpers.message({
                        custom: 'The total percentage of cases in your chosen areas of practice must add up to 100%.',
                    });
                }

                return value;
            })
            .unique((a, b) => a.areaOfPracticeCode === b.areaOfPracticeCode),
    ),
    create(areaOfPracticeList: LPLAreaOfPracticeList) {
        return LPLAreaOfPracticeList.validate(areaOfPracticeList);
    },
};
