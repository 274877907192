import { Nominal } from '@embroker/shotwell/core/types/Nominal';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export type Token = Nominal<string, 'Token'>;

export const Token = {
    ...defineValidator<Token>(
        Joi.string()
            .required()
            .length(32)
            .pattern(/^[a-z0-9_-]+$/i),
    ),
    create(): Token {
        let result = '';
        const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_';
        for (let i = 0; i < 32; i++) {
            result += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return result as Token;
    },
};
