import { inject, injectable } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { UserInvite } from '../entities/UserInvite';
import { UserInviteRepository } from '../repositories/UserInviteRepository';

/**
 * Response data for GetUserInvites use case
 * @param invites is list of user invites
 */
export interface GetTeamInvitesResponse {
    invites: Immutable<EntityProps<UserInvite>>[];
}

/**
 * GetUserInvites use case returns list of invited users and their privileges including caller himself
 */
export interface GetUserInvites extends UseCase {
    execute(): AsyncResult<GetTeamInvitesResponse, InvalidArgument | OperationFailed>;
}

@injectable()
class GetTeamInvitesUseCase extends UseCase {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/GetUserInvites');
    /**
     * Constructor for GetUserInvites class instance
     * @param eventBus An event bus this Use Case will publish events to.
     * @param userInviteRepository is the user invite repository used to fetch invited user's id
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(UserInviteRepository) private userInviteRepository: UserInviteRepository,
    ) {
        super(eventBus);
    }

    /**
     * Execute GetTeamInvitesUseCase use case
     * @returns data as GetTeamInvitesResponse type if execution was successful
     * @returns InvalidArgument error if provided id is invalid
     * @returns OperationFailed error if operation fails to execute
     */
    public async execute(): AsyncResult<GetTeamInvitesResponse, OperationFailed | InvalidArgument> {
        const invitationsResult = await this.userInviteRepository.get();
        if (isErr(invitationsResult)) {
            return invitationsResult;
        }

        const listOfJsonInvites = invitationsResult.value.map((item) => item.toDTO());

        return Success({
            invites: listOfJsonInvites,
        });
    }
}

export const GetUserInvites: UseCaseClass<GetUserInvites> = GetTeamInvitesUseCase;
