import { ComboBoxAsyncOption, ComboBoxInputAsync, Nullable } from '@embroker/ui-toolkit/v2';

import { GetBrokerOrganizations } from '../../../useCases/GetBrokerOrganizations';
import React from 'react';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { isErr } from '@embroker/shotwell/core/types/Result';

const MINIMAL_TEXT_INPUT_LENGTH = 2;
const MAXIMAL_OPTIONS_COUNT = 10;

interface OrganizationSearchProps {
    /**
     * Maximum number of suggested results
     * Defaults to MAXIMAL_OPTIONS_COUNT
     */
    count?: number;
    /**
     * Optional minimal length of text input before search is initiated.
     * Defaults to MINIMAL_TEXT_INPUT_LENGTH
     */
    minimumTextLength?: number;
    /**
     * Triggered on user click action on one of drop-down items
     * @param item Selected item from a drop-down list
     */
    onSelect(item: unknown): void;
    /**
     * If provided, this will be called with the input value when search option is being selected, and onSelect will not be called
     * @param input Value entered in the drop-down input field
     */
    onSearch?: (input: string) => void;
    /**
     * Callback triggered on component input change
     * @param input Value entered in the drop-down input field
     */
    onInputChange?(input: string): void;
    /**
     * Controlled input value
     */
    inputValue?: string;
    /**
     * Determines whether the new option should be displayed based on the current input value
     * Defaults to checking if a label equal to the current input exists in the option list
     * @param input Value entered in the drop-down input field
     */
    isValidSearch?(input: string): boolean;
    /**
     * Creates a label based on the current input
     * @param input Value entered in the drop-down input field
     */
    searchLabel?(input: string): string;
    /**
     * Text to display when there is no input
     */
    placeholder?: string;
    /**
     * Readonly state for input field
     */
    readOnly?: boolean;
    /**
     * Class name that will be passed on to the input component
     */
    className?: string;
}

export const OrganizationSearch = React.forwardRef(function OrganizationSelect(
    {
        count = MAXIMAL_OPTIONS_COUNT,
        inputValue,
        onSelect,
        onSearch,
        searchLabel,
        onInputChange,
        isValidSearch,
        minimumTextLength = MINIMAL_TEXT_INPUT_LENGTH,
        placeholder,
        readOnly,
        className,
    }: OrganizationSearchProps,
    ref?: React.Ref<HTMLInputElement>,
) {
    const searchForOrganizations = async (input: string) => {
        const result = await execute(GetBrokerOrganizations, { filter: input, count });
        if (isErr(result)) {
            return Promise.resolve([]);
        }
        const options: ComboBoxAsyncOption<Nullable<UUID>>[] = result.value.map((organization) => ({
            value: organization.id,
            label: organization.name,
        }));
        return Promise.resolve(options);
    };

    const handleOnSelect = (event: { target: { value: unknown } }) => {
        if (onSelect) {
            onSelect(event.target.value);
        }
    };

    const getNewOptionData = (input: string) => {
        const option: ComboBoxAsyncOption<Nullable<UUID>> = {
            value: null,
            label: searchLabel ? searchLabel(input) : input,
        };
        return option;
    };

    let resultingClassName = className;
    if (className == undefined) {
        resultingClassName = onSearch ? 'u-1/4' : undefined;
    }

    return (
        <ComboBoxInputAsync
            ref={ref}
            className={resultingClassName}
            minimumInputLength={minimumTextLength}
            disabled={readOnly}
            onChange={handleOnSelect}
            onInputChange={onInputChange}
            inputValue={inputValue}
            request={searchForOrganizations}
            placeholder={placeholder}
            createNewItem={getNewOptionData}
            onCreateNewItem={onSearch}
            isValidNewItem={isValidSearch}
            newItemPosition="first"
            hideDropdownIndicator
            debounce
        />
    );
});
