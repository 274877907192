import { Money } from '@embroker/shotwell/core/types/Money';
import { valueObject } from '@embroker/shotwell/core/types/ValueObject';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface ExcessTowerLayer {
    layerNumber: number;
    carrier: string;
    policyNumber: string;
    writingCompany: string;
    premium: Money;
    policyAggregate: Money;
}

export const ExcessTowerLayer = valueObject({
    ...defineValidator<ExcessTowerLayer>({
        layerNumber: Joi.number().integer().required(),
        carrier: Joi.string().required(),
        policyNumber: Joi.string().required().allow(''),
        writingCompany: Joi.string().required().allow(''),
        premium: Money.schema.required(),
        policyAggregate: Money.schema.required(),
    }),
});

export interface ExcessTechEOCyber {
    policyStartDate: Date;
    policyEndDate: Date;
    techEOLimit: Money;
    cyberLimit: Money;
    technologySelected: boolean;
    cyberSelected: boolean;
    primaryCarrier: string;
    primaryPolicyNumber: string;
    primaryWritingCompany: string;
    primaryPremium: Money;
    policyAggregate: Money;
    excessTower: ExcessTowerLayer[];
}

export const ExcessTechEOCyber = valueObject({
    ...defineValidator<ExcessTechEOCyber>({
        policyStartDate: Joi.date().required(),
        policyEndDate: Joi.date().required(),
        techEOLimit: Money.schema.required(),
        cyberLimit: Money.schema.required(),
        technologySelected: Joi.boolean().required(),
        cyberSelected: Joi.boolean().required(),
        primaryCarrier: Joi.string().required(),
        primaryPolicyNumber: Joi.string().required().allow(''),
        primaryWritingCompany: Joi.string().required().allow(''),
        primaryPremium: Money.schema.required(),
        policyAggregate: Money.schema.required(),
        excessTower: Joi.array().items(ExcessTowerLayer.schema).unique(),
    }),
});
