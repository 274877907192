import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ESPEndorsementRepository } from '../repositories/ESPEndorsementRepository';
import { ESPEndorsementPolicy } from '../types/ESPEndorsementPolicy';

export interface GetESPEndorsementPolicyRequest {
    policyId: UUID;
}

export interface GetESPEndorsementPolicy extends UseCase {
    execute(
        request: GetESPEndorsementPolicyRequest,
    ): AsyncResult<ESPEndorsementPolicy, InvalidArgument | OperationFailed>;
}

@injectable()
class GetESPEndorsementPolicyUseCase extends UseCase implements GetESPEndorsementPolicy {
    public static type = Symbol('ESPEndorsement/GetESPEndorsementPolicy');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ESPEndorsementRepository)
        private espEndorsementRepository: ESPEndorsementRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        policyId,
    }: GetESPEndorsementPolicyRequest): AsyncResult<
        ESPEndorsementPolicy,
        InvalidArgument | OperationFailed
    > {
        return await this.espEndorsementRepository.loadPolicy(policyId);
    }
}

export const GetESPEndorsementPolicy: UseCaseClass<GetESPEndorsementPolicy> =
    GetESPEndorsementPolicyUseCase;
