import React from 'react';
import { State } from '@embroker/shotwell/core/types/StateList';
import { LPLWarrantyAndFraudModalContent } from '../../../../quote/lpl/view/components/LPLWarrantyAndFraudModalContent';
import { Text, Modal, useModal } from '@embroker/ui-toolkit/v2';
import { Link } from '../../../../view/components';

interface SignInsuranceApplicationWarrantySignatureProps {
    title: string;
    fullName: string;
    usaState: State;
    handleAppDownloadClick?: () => void;
}

export function SignInsuranceApplicationWarrantySignature({
    title,
    fullName,
    usaState,
    handleAppDownloadClick,
}: SignInsuranceApplicationWarrantySignatureProps) {
    const warrantyAndFraudModal = useModal();

    return (
        <React.Fragment>
            <Text style="body 1" data-e2e="warranty-fraud">
                By checking this box, you certify all information provided in the&nbsp;
                {handleAppDownloadClick ? (
                    <Link
                        href="#"
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleAppDownloadClick();
                        }}
                    >
                        insurance application
                    </Link>
                ) : (
                    'insurance application'
                )}
                &nbsp;is true and correct and acknowledge that you have read and agree to the&nbsp;
                <Link href="" display="inline" onClick={warrantyAndFraudModal.show}>
                    Warranty and Fraud Statement
                </Link>
                .
            </Text>
            <Modal {...warrantyAndFraudModal} size="medium">
                <LPLWarrantyAndFraudModalContent
                    fullName={fullName}
                    title={title}
                    usaState={usaState}
                />
            </Modal>
        </React.Fragment>
    );
}
