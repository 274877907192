import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { DocGenFailed, HigherLimitsApprovalNotNeeded } from '../../../errors';
import { ESPQuote } from '../../entities/ESPQuote';
import { ConfigFetchFailed } from '../../errors';
import { ESPConfig } from '../../types/ESPConfig';
import { ESPQuoteOptions } from '../../types/ESPQuoteOptions';
import { QuoteOptionsExtensionRequestList } from '../../types/QuoteOptionsExtensionRequest';

export interface PurchaseESPResponse {
    policyId: UUID;
    policyDoneTaskId: UUID;
}

export interface getESPQuestionnaireDataAndSubmissionDateResponse {
    questionnaireData: string;
    submissionDate: Date;
}
export interface ESPQuoteRepository {
    getLastQuote(
        applicationId: UUID,
    ): AsyncResult<ESPQuote, InvalidArgument | OperationFailed | ConfigFetchFailed>;

    requote(
        applicationId: UUID,
        espQuoteOptions: Immutable<ESPQuoteOptions>,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed>;

    purchaseESP(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<PurchaseESPResponse, InvalidArgument | OperationFailed>;

    getESPPurchasedCoveragesList(
        applicationId: UUID,
    ): AsyncResult<string[], InvalidArgument | OperationFailed>;

    getESPQuestionnaireDataAndSubmissionDate(
        applicationId: UUID,
    ): AsyncResult<
        getESPQuestionnaireDataAndSubmissionDateResponse,
        InvalidArgument | OperationFailed
    >;

    getESPConfig(): AsyncResult<ESPConfig, InvalidArgument | OperationFailed | ConfigFetchFailed>;

    requestHigherLimits(
        applicationId: UUID,
        isSubmit: boolean,
    ): AsyncResult<void, HigherLimitsApprovalNotNeeded | OperationFailed>;

    createQuoteSummaryAsyncTask(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed | DocGenFailed>;
    getHigherLimitRequests(
        applicationId: UUID,
    ): AsyncResult<QuoteOptionsExtensionRequestList, OperationFailed | InvalidArgument>;
}

export const ESPQuoteRepository = Symbol('ESPQuoteRepository');
