import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { PolicySortBy } from './PolicySortBy';

export interface PolicyFilter {
    sortBy: PolicySortBy;
    showActive: boolean;
    showInactive: boolean;
}

export const PolicyFilter = {
    ...defineValidator<PolicyFilter>({
        sortBy: PolicySortBy.schema,
        showActive: Joi.boolean(),
        showInactive: Joi.boolean(),
    }),
    create(filter: PolicyFilter) {
        return PolicyFilter.validate(filter);
    },
};
