import {
    Aborted,
    InvalidArgument,
    OperationFailed,
    Timeout,
    UnknownEntity,
} from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { cast } from '@embroker/shotwell/core/types/Nominal';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { inject } from '@embroker/shotwell/core/di';
import { GetDocumentUrl } from '../../../documents/useCases/GetDocumentUrl';
import { UpdateQuote } from './UpdateQuote';
import { ESPRenewalQuote } from '../entities/ESPRenewalQuote';
import { ESPRenewalQuoteOptions } from '../types/ESPRenewalQuoteOptions';
import { UseCaseClass, UseCase } from '@embroker/shotwell/core/UseCase';
import { ESPRenewalConfigFetchFailed } from '../errors';
import { ApplicationFetchFailed, DocGenFailed, QuestionnaireParsingFailed } from '../../errors';
import { QuoteRepository } from '../repositories/QuoteRepository';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { TasksRepository } from '@app/tasks/repositories';
import { ConfigFetchFailed } from '@app/quote/esp/errors';

export interface DownloadESPRenewalQuoteDocumentRequest {
    quote: ESPRenewalQuote;
    quoteOptions: ESPRenewalQuoteOptions;
    abortSignal: AbortSignal;
}

export interface DownloadESPRenewalQuoteDocumentResponse {
    documentUrl: URI;
}

interface createQuoteDocumentInput {
    applicationId: UUID;
    quoteId: UUID;
    abortSignal: AbortSignal;
}

export interface DownloadESPRenewalQuoteDocument extends UseCase {
    execute(
        request: DownloadESPRenewalQuoteDocumentRequest,
    ): AsyncResult<
        DownloadESPRenewalQuoteDocumentResponse,
        | UnknownEntity
        | InvalidArgument
        | OperationFailed
        | ESPRenewalConfigFetchFailed
        | QuestionnaireParsingFailed
        | ApplicationFetchFailed
        | DocGenFailed
        | Timeout
        | Aborted
    >;
}

class DownloadESPRenewalQuoteDocumentUseCase
    extends UseCase
    implements DownloadESPRenewalQuoteDocument
{
    public static type = Symbol('ESPRenewalQuote/GetQuoteFileUrl');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(UpdateQuote.type) private updateQuote: UpdateQuote,
        @inject(GetDocumentUrl.type) private getDocumentUrl: GetDocumentUrl,
        @inject(QuoteRepository) private quoteRepository: QuoteRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        quote,
        quoteOptions,
        abortSignal,
    }: DownloadESPRenewalQuoteDocumentRequest): AsyncResult<
        DownloadESPRenewalQuoteDocumentResponse,
        | UnknownEntity
        | InvalidArgument
        | OperationFailed
        | ESPRenewalConfigFetchFailed
        | QuestionnaireParsingFailed
        | ApplicationFetchFailed
        | DocGenFailed
        | Timeout
        | Aborted
    > {
        const configResponse = await this.quoteRepository.getESPRenewalConfig();

        if (isErr(configResponse)) {
            return configResponse;
        }

        let fileKey = quote.fileKey;
        if (!fileKey) {
            if (configResponse.value.pasRaterEnabled) {
                const quoteDocument = await this.createQuoteDocument({
                    applicationId: quote.applicationId,
                    quoteId: quote.id,
                    abortSignal,
                });
                if (isErr(quoteDocument)) {
                    return quoteDocument;
                }
                fileKey = quoteDocument.value;
            } else {
                const updateQuoteResult = await this.updateQuote.execute({
                    quote: quote,
                    quoteOptions: quoteOptions,
                    abortSignal,
                });
                if (isErr(updateQuoteResult)) {
                    return updateQuoteResult;
                }
                fileKey = updateQuoteResult.value.quote.fileKey;
            }
        }

        const getDocumentUrlResult = await this.getDocumentUrl.execute({
            fileKey: fileKey ?? '',
        });

        if (isErr(getDocumentUrlResult)) {
            return getDocumentUrlResult;
        }

        return Success<DownloadESPRenewalQuoteDocumentResponse>({
            documentUrl: cast<URI>(getDocumentUrlResult.value.downloadUrl),
        });
    }

    private async createQuoteDocument({
        applicationId,
        quoteId,
        abortSignal,
    }: createQuoteDocumentInput): AsyncResult<
        string,
        InvalidArgument | OperationFailed | Timeout | Aborted | DocGenFailed | ConfigFetchFailed
    > {
        const createAsyncTaskResult = await this.quoteRepository.createQuoteSummaryAsyncTask(
            applicationId,
            quoteId,
        );
        if (isErr(createAsyncTaskResult)) {
            return createAsyncTaskResult;
        }

        const pollForTaskStatusResult = await this.tasksRepository.pollForTaskStatus(
            createAsyncTaskResult.value,
            abortSignal,
        );
        if (isErr(pollForTaskStatusResult)) {
            return Failure(DocGenFailed({ errors: pollForTaskStatusResult.errors }));
        }

        const lastQuoteResult = await this.quoteRepository.getQuoteByApplicationId(applicationId);
        if (isErr(lastQuoteResult)) {
            return lastQuoteResult;
        }
        if (lastQuoteResult.value.id !== quoteId) {
            return Failure(OperationFailed({ message: 'Not the latest esp quote' }));
        }

        const fileKey = lastQuoteResult.value.fileKey;
        if (fileKey === undefined || fileKey === '') {
            return Failure(OperationFailed({ message: 'No generated Quote Summary was found.' }));
        }

        return Success(fileKey);
    }
}

export const DownloadESPRenewalQuoteDocument: UseCaseClass<DownloadESPRenewalQuoteDocument> =
    DownloadESPRenewalQuoteDocumentUseCase;
