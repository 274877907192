import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, Success, isErr } from '@embroker/shotwell/core/types/Result';
import { isAuthenticated } from '../entities/Session';
import {
    ExpiredTokenError,
    FailedToRetrieveSessionError,
    InvalidTokenError,
    Unauthenticated,
} from '../errors';
import { SessionRepository } from '../repositories/SessionRepository';
import { Token } from '../types/Token';

export interface StreamlineRenewalProspectLogin extends UseCase {
    execute(
        token: string,
    ): AsyncResult<
        void,
        Unauthenticated | InvalidTokenError | ExpiredTokenError | FailedToRetrieveSessionError
    >;
}

@injectable()
class StreamlineRenewalProspectLoginUseCase
    extends UseCase
    implements StreamlineRenewalProspectLogin
{
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/StreamlineRenewalProspectLogin');
    /**
     * Constructor for StreamlineRenewalProspectLogin use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to
     * @param sessionRepository Session repository used to authenticate the user
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(SessionRepository) private sessionRepository: SessionRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        token: string,
    ): AsyncResult<
        void,
        Unauthenticated | InvalidTokenError | ExpiredTokenError | FailedToRetrieveSessionError
    > {
        if (!Token.check(token)) {
            return Failure(InvalidTokenError());
        }

        const result = await this.sessionRepository.loginByStreamlineRenewalProspectToken(token);

        if (isErr(result)) {
            return result;
        }

        const session = result.value;

        if (!isAuthenticated(session)) {
            return Failure(Unauthenticated());
        }

        session.onLoginByUser();

        await this.eventBus.publishEntityEvents(session);

        return Success();
    }
}

export const StreamlineRenewalProspectLogin: UseCaseClass<StreamlineRenewalProspectLogin> =
    StreamlineRenewalProspectLoginUseCase;
