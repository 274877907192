import { inject, injectable } from '@embroker/shotwell/core/di';
import { Aborted, InvalidArgument, OperationFailed, Timeout } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ESPQuote } from '../entities/ESPQuote';
import { ConfigFetchFailed } from '../errors';
import { ESPQuoteRepository } from '../repositories/ESPQuoteRepository';

export interface GetInitialESPRateRequest {
    applicationId: UUID;
}

export interface GetInitialESPRate extends UseCase {
    execute(
        request: GetInitialESPRateRequest,
    ): AsyncResult<
        ESPQuote,
        InvalidArgument | OperationFailed | ConfigFetchFailed | Aborted | Timeout
    >;
}

@injectable()
class GetInitialESPRateUseCase extends UseCase implements GetInitialESPRate {
    public static type = Symbol('ESPQuote/GetInitialESPRate');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ESPQuoteRepository) private espQuoteRepository: ESPQuoteRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
    }: GetInitialESPRateRequest): AsyncResult<
        ESPQuote,
        InvalidArgument | OperationFailed | ConfigFetchFailed | Aborted | Timeout
    > {
        const getCurrentRateResult = await this.espQuoteRepository.getLastQuote(applicationId);

        if (isErr(getCurrentRateResult)) {
            return getCurrentRateResult;
        }

        return Success<ESPQuote>(getCurrentRateResult.value);
    }
}

export const GetInitialESPRate: UseCaseClass<GetInitialESPRate> = GetInitialESPRateUseCase;
