import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Button, ButtonBar, useModal } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { UploadBORLetterModal } from '../modals/UploadBORLetterModal';

export function ClientActionButtons({ organizationId }: { organizationId: UUID }) {
    const uploadBORLetterModal = useModal();
    const { navigate } = useNavigation();

    const handleUploadBORLetter = async () => {
        uploadBORLetterModal.show();
    };
    const handleClientPaymentHistory = () => navigate('/broker/client-payment-history');

    const handleRequestLossRuns = async () => {
        navigate(URI.build('/broker/request-loss-runs', { organizationId }));
    };

    return (
        <React.Fragment>
            <UploadBORLetterModal modal={uploadBORLetterModal} organizationId={organizationId} />
            <ButtonBar split="-3" gap="16" responsive={{ screenWidth: { smallerThan: 'tablet' } }}>
                <Button size="small" icon="file" onClick={handleRequestLossRuns}>
                    Request loss runs
                </Button>
                <Button size="small" icon="history" onClick={handleClientPaymentHistory}>
                    Payment history
                </Button>
                <Button size="small" icon="upload" onClick={handleUploadBORLetter}>
                    Upload BOR letter
                </Button>
            </ButtonBar>
        </React.Fragment>
    );
}
