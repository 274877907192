import { Password } from '@embroker/shotwell/core/types/Password';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import { Form } from '@embroker/ui-toolkit/v2';
import React, { useEffect } from 'react';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { ResetPassword } from '../../useCases/ResetPassword';
import { PublicForm } from './PublicForm';
import { PublicPageLayout } from './PublicPageLayout.view';

interface ResetPasswordFormData {
    password: Password;
    token: string;
}

const ResetPasswordForm = createForm<ResetPasswordFormData>({
    useCase: ResetPassword,
    formatSubmitErrors(errors) {
        // TODO: improve the copy of this message?
        return ['Password reset token has expired'];
    },
    fields: {
        password: {
            type: 'password',
            validator: Password.schema,
            formatValidationError(error) {
                switch (error.details.validator) {
                    case 'any.required':
                    case 'string.empty':
                        return 'You must enter a new password';
                    case 'string.min':
                    case 'password.length':
                        return 'Entered password is too short';
                    case 'password.strength':
                        return 'Please provide a stronger password';
                    default:
                        return error.message;
                }
            },
        },
        token: {
            type: 'hidden',
            validator: Joi.string().required(),
        },
    },
});

interface ResetPasswordPageProps {
    token: string;
}

export function ResetPasswordPage({ token }: ResetPasswordPageProps) {
    const navigation = useNavigation();
    const { submit, messages, fields, status } = useForm(ResetPasswordForm, {
        token,
    });

    useEffect(() => {
        if (status === 'submitted') {
            navigation.navigate('/login');
        }
    }, [status, navigation]);

    return (
        <PublicPageLayout>
            <PublicForm
                messages={messages}
                submit={submit}
                submitText="Create a new password"
                title="Create a new password"
                submitDisabled={!fields.password.props.value}
            >
                <Form.Field
                    data-e2e="user-org-reset-pwd-enter-pwd-field"
                    inputProps={{
                        ...fields.password.props,
                        note: (
                            <span>
                                Use a few words, avoid common phrases. <br /> No need for symbols,
                                digits or uppercase letters
                            </span>
                        ),
                        autoComplete: 'new-password',
                        strengthMeter: true,
                    }}
                    label="Enter new password"
                    messages={fields.password.messages}
                    type="password"
                ></Form.Field>
            </PublicForm>
        </PublicPageLayout>
    );
}
