import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    isOK,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { CalculateSKU } from '../../../analytics/useCases/CalculateSKU';
import { CNAQuotePurchased } from '../entities/CNAQuote';
import { CNAQuoteRepository } from '../repositories/CNAQuoteRepository';

export interface PurchaseCNARequest {
    applicationId: UUID;
    quoteId: UUID;
}

export interface PurchaseCNAResponse {
    policyId: UUID;
    policyDoneTaskId: UUID;
}

export interface PurchaseCNA extends UseCase {
    execute(
        request: PurchaseCNARequest,
    ): AsyncResult<PurchaseCNAResponse, InvalidArgument | OperationFailed>;
}

@injectable()
class PurchaseCNAUseCase extends UseCase implements PurchaseCNA {
    public static type = Symbol('BOPCNA/PurchaseCNA');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(CNAQuoteRepository) private cnaQuoteRepository: CNAQuoteRepository,
        @inject(CalculateSKU.type) private calculateSku: CalculateSKU,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
        quoteId,
    }: PurchaseCNARequest): AsyncResult<PurchaseCNAResponse, OperationFailed | InvalidArgument> {
        const purchaseResult = await this.cnaQuoteRepository.purchaseCNA(applicationId, quoteId);
        if (isErr(purchaseResult)) {
            return handleOperationFailure(purchaseResult);
        }

        const CNAQuoteResult = await this.cnaQuoteRepository.getCurrentCNAQuote(applicationId);
        let totalPremium: Nullable<Money> = null;
        let isRenewal = false;

        if (isOK(CNAQuoteResult)) {
            totalPremium = CNAQuoteResult.value.totalPremium;
            isRenewal = CNAQuoteResult.value.isRenewal;
        }

        const skuResult = await this.calculateSku.execute({
            event: 'purchase',
            applicationId,
        });

        await this.eventBus.publish({
            origin: 'CNAQuote',
            name: 'Purchased',
            id: quoteId,
            applicationId: applicationId,
            totalPremium,
            createdAt: new Date(Date.now()),
            isRenewal,
            sku: skuResult.value,
        } as CNAQuotePurchased);

        return Success({
            policyId: purchaseResult.value.policyId,
            policyDoneTaskId: purchaseResult.value.policyDoneTaskId,
        });
    }
}

export const PurchaseCNA: UseCaseClass<PurchaseCNA> = PurchaseCNAUseCase;
