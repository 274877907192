import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import {
    PremiumFinanceGetQuoteInput,
    QuoteOptions,
    QuoteRepository,
} from '../repositories/QuoteRepository';

/**
 * Request data for GetPremiumFinanceQuote use case
 * It contains all relevant data for new organization
 */
export interface GetPremiumFinanceQuoteRequest {
    invoiceIds: UUID[];
}

/**
 * Response data for GetPremiumFinanceQuote use case
 * @property OrganizationId is the id of the new organization
 */
export type GetPremiumFinanceQuoteResponse = QuoteOptions;

export interface GetPremiumFinanceQuote extends UseCase {
    execute(
        request: PremiumFinanceGetQuoteInput,
    ): AsyncResult<GetPremiumFinanceQuoteResponse, InvalidArgument | OperationFailed>;
}

/**
 * GetPremiumFinanceQuote use case is used to add a new organization on the platform
 */
@injectable()
class GetPremiumFinanceQuoteUseCase extends UseCase {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Payments/GetPremiumFinanceQuote');

    /**
     * Constructor for GetPremiumFinanceQuote class instance
     * @param quoteRepository is the quote repo which will be used to obtain premium finance quotes
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(QuoteRepository) private quoteRepository: QuoteRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes the GetPremiumFinanceQuote use case.
     * @param data is representation of the new organization
     * @returns GetPremiumFinanceQuoteResponse if execution was successful
     * @returns InvalidArgument if one of properties in GetPremiumFinanceQuoteRequest was not valid
     */
    public async execute(
        data: PremiumFinanceGetQuoteInput,
    ): AsyncResult<GetPremiumFinanceQuoteResponse, OperationFailed | InvalidArgument> {
        return this.quoteRepository.getQuote({
            taskMonthly: data.taskMonthly,
            taskQuarterly: data.taskQuarterly,
        });
    }
}

export const GetPremiumFinanceQuote: UseCaseClass<GetPremiumFinanceQuote> =
    GetPremiumFinanceQuoteUseCase;
