import { API } from '@embroker/shotwell-api/app';
import type * as APITypes from '@embroker/shotwell-api/app';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { EntityType } from '../../entities/EntityType';
import { EntityTypeRepository } from './index';

@injectable()
export class APIEntityTypeRepository implements EntityTypeRepository {
    public async getEntityTypes(): AsyncResult<EntityType[], OperationFailed | InvalidArgument> {
        const entityTypesResult = await API.request('global/entity_type');

        if (isErr(entityTypesResult)) {
            return handleOperationFailure(entityTypesResult);
        }

        const result = await APIEntityTypeRepository.toEntityTypes(
            entityTypesResult.value as APITypes.GlobalEntityTypeResponse,
        );

        if (isErr(result)) {
            return result;
        }

        return Success(result.value);
    }

    private static async toEntityTypes(
        entityList: APITypes.GlobalEntityTypeResponse,
    ): AsyncResult<EntityType[], OperationFailed | InvalidArgument> {
        const result: EntityType[] = [];
        for (const entity of entityList) {
            const entityResult = await EntityType.create(entity);

            if (isErr(entityResult)) {
                return handleOperationFailure(entityResult);
            }

            result.push(entityResult.value);
        }
        return Success(result);
    }
}
