import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { UserRepository } from '../repositories/UserRepository';
import { Unauthenticated } from '../errors';

/**
 * Request data for SetPassword use case
 * @property userId is the id of the user which password we want to set password
 * @property password is the user's newly submitted password
 */
export interface SetPasswordRequest {
    password: string;
}

/**
 * Set password use case is used to set password for unauthenticated user that is setting his password
 */

export interface SetPassword extends UseCase {
    execute(
        request: SetPasswordRequest,
    ): AsyncResult<void, Unauthenticated | UnknownEntity | OperationFailed | InvalidArgument>;
}

@injectable()
class SetPasswordUseCase extends UseCase implements SetPassword {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/SetPassword');
    /**
     * Constructor for SetPassword class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param userRepository is the user repository used to save user entity with newly set password
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(UserRepository) private userRepository: UserRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes the SetPassword use case
     * Input is of type SetPasswordRequest
     * @returns Nothing if execution was successful
     * @returns UnknownEntity error if user with userId does not exist on platform
     * @returns InvalidArgument if password is already set
     * @returns InvalidArgument if password is too short
     * @returns OperationFailed if repo layer fails to deliver
     */
    public async execute({
        password,
    }: SetPasswordRequest): AsyncResult<
        void,
        Unauthenticated | UnknownEntity | OperationFailed | InvalidArgument
    > {
        const userResult = await this.userRepository.getActiveUser();
        if (isErr(userResult)) {
            return userResult;
        }

        const user = userResult.value;
        user.setNewPassword(password);

        const updateUserResult = await this.userRepository.save(user);
        if (isErr(updateUserResult)) {
            return handleOperationFailure(updateUserResult);
        }

        this.eventBus.publishEntityEvents(user);
        return Success();
    }
}

export const SetPassword: UseCaseClass<SetPassword> = SetPasswordUseCase;
