import { inject } from '@embroker/shotwell/core/di';
import {
    Aborted,
    InvalidArgument,
    OperationFailed,
    Timeout,
    UnknownEntity,
} from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { cast } from '@embroker/shotwell/core/types/Nominal';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute, UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { GetDocumentUrl } from '../../../documents/useCases/GetDocumentUrl';
import { TasksRepository } from '../../../tasks/repositories';
import { DocGenFailed } from '../../errors';
import { CrimeQuoteRepository } from '../repositories/CrimeQuoteRepository';

export interface GenerateCrimeSpecimenPolicyUrlRequest {
    readonly applicationId: UUID;
    readonly quoteId: UUID;
    readonly abortSignal: AbortSignal;
}

export interface GenerateCrimeSpecimenPolicyUrlResponse {
    readonly specimenPolicyUrl: URI;
}

export interface GenerateCrimeSpecimenPolicyUrl extends UseCase {
    execute(
        request: GenerateCrimeSpecimenPolicyUrlRequest,
    ): AsyncResult<
        GenerateCrimeSpecimenPolicyUrlResponse,
        UnknownEntity | InvalidArgument | OperationFailed | Aborted | Timeout | DocGenFailed
    >;
}

class GenerateCrimeSpecimenPolicyUrlUseCase
    extends UseCase
    implements GenerateCrimeSpecimenPolicyUrl
{
    public static type = Symbol('CrimeQuote/GenerateSpecimenPolicyUrl');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(CrimeQuoteRepository) private crimeQuoteRepository: CrimeQuoteRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
        quoteId,
        abortSignal,
    }: GenerateCrimeSpecimenPolicyUrlRequest): AsyncResult<
        GenerateCrimeSpecimenPolicyUrlResponse,
        UnknownEntity | InvalidArgument | OperationFailed | Aborted | Timeout | DocGenFailed
    > {
        const createAsyncTaskResult = await this.crimeQuoteRepository.createSpecimenPolicyAsyncTask(
            applicationId,
            quoteId,
        );
        if (isErr(createAsyncTaskResult)) {
            return createAsyncTaskResult;
        }

        const createCrimeSpecimenPolicyResult = await this.awaitCreateCrimeSpecimenPolicyTask({
            taskId: createAsyncTaskResult.value,
            applicationId,
            quoteId,
            abortSignal,
        });
        if (isErr(createCrimeSpecimenPolicyResult)) {
            return Failure(DocGenFailed({ errors: createCrimeSpecimenPolicyResult.errors }));
        }

        const fileKey = createCrimeSpecimenPolicyResult.value;
        if (fileKey === undefined || fileKey === '') {
            return Failure(OperationFailed({ message: 'No generated Specimen Policy was found.' }));
        }

        const getDocumentUrlResult = await execute(GetDocumentUrl, { fileKey });
        if (isErr(getDocumentUrlResult)) {
            return getDocumentUrlResult;
        }

        return Success<GenerateCrimeSpecimenPolicyUrlResponse>({
            specimenPolicyUrl: cast<URI>(getDocumentUrlResult.value.downloadUrl),
        });
    }

    private async awaitCreateCrimeSpecimenPolicyTask({
        taskId,
        applicationId,
        quoteId,
        abortSignal,
    }: {
        taskId: UUID;
        applicationId: UUID;
        quoteId: UUID;
        abortSignal: AbortSignal;
    }): AsyncResult<string | undefined, InvalidArgument | OperationFailed | Aborted | Timeout> {
        const pollForTaskStatusResult = await this.tasksRepository.pollForTaskStatus(
            taskId,
            abortSignal,
        );
        if (isErr(pollForTaskStatusResult)) {
            return pollForTaskStatusResult;
        }

        const lastQuoteResult = await this.crimeQuoteRepository.getLastCrimeQuote(applicationId);
        if (isErr(lastQuoteResult)) {
            return lastQuoteResult;
        }
        if (lastQuoteResult.value.id !== quoteId) {
            return Failure(OperationFailed({ message: 'Not the latest crime quote' }));
        }

        return Success(lastQuoteResult.value.details.specimenPolicyFileKey);
    }
}

export const GenerateCrimeSpecimenPolicyUrl: UseCaseClass<GenerateCrimeSpecimenPolicyUrl> =
    GenerateCrimeSpecimenPolicyUrlUseCase;
