import { isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { execute } from '@embroker/shotwell/core/UseCase';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Loader } from '@embroker/ui-toolkit/v2';
import React, { useEffect } from 'react';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { Token } from '../../../types/Token';
import { GetInvitedOrganizationData } from '../../../useCases/GetInvitedOrganizationData';
import { SignUpInvitedUser } from '../../../useCases/SignUpInvitedUser';

interface SignUpForInvitedUsersProps {
    readonly userInvitationToken?: Token;
    readonly certificateInviteToken?: Token;
}

export function SignUpForInvitedUsers(props: SignUpForInvitedUsersProps) {
    const { navigate } = useNavigation();
    const { isLoading, result: signUpResult } = useUseCase(SignUpInvitedUser, {
        userInvitationToken: props.userInvitationToken,
        certificateInviteToken: props.certificateInviteToken,
    });

    useEffect(() => {
        let isCancelled = false;
        if (!isLoading && signUpResult) {
            if (isOK(signUpResult)) {
                navigate('/');
            } else {
                const inviteToken =
                    props.userInvitationToken || (props.certificateInviteToken as Token);
                execute(GetInvitedOrganizationData, { inviteToken })
                    .then((result) => {
                        if (isCancelled) {
                            return;
                        }
                        if (isOK(result)) {
                            const userInvite = result.value.userInvite;
                            const userData = {
                                emailAddress:
                                    userInvite.email !== null ? userInvite.email : undefined,
                                organizationName:
                                    userInvite.organizationName !== null
                                        ? userInvite.organizationName
                                        : undefined,
                            };
                            navigate(URI.build('/signup', userData));
                        } else {
                            navigate('/signup');
                        }
                    })
                    .catch(() => {
                        if (isCancelled) {
                            return;
                        }
                        navigate('/signup');
                    });
            }
        }
        return () => {
            isCancelled = true;
        };
    }, [isLoading, signUpResult, navigate, props]);

    return <Loader />;
}
