import React from 'react';
import { ColumnLayout, StackLayout, Table, Text } from '@embroker/ui-toolkit/v2';
import { getLOBName, Lob } from '../../../types/Lob';
import { Immutable } from '@embroker/shotwell/core/types';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { Money } from '@embroker/shotwell/core/types/Money';
import { Limit } from '../../../types/Limit';

interface CoverageSectionLimitAndRetentionProps {
    lob: Immutable<Lob>;
}

export const CoverageSectionLimitAndRetention = ({
    lob,
}: CoverageSectionLimitAndRetentionProps) => {
    const policyHasDeductible =
        lob.lobType == 'professionalLiabilityLob' || lob.lobType == 'propertyLob';

    const amountColumn = (amountMoney: Money) => {
        return (
            <StackLayout>
                {amountMoney.amount >= 0 ? (
                    <ColumnLayout>
                        <Text style="default" as="span">
                            <MoneyDisplay fractionDigits={0} value={amountMoney} />
                        </Text>
                    </ColumnLayout>
                ) : null}
            </StackLayout>
        );
    };

    const coverageLimitAndRetention = getCoverageLimitAndRetention(lob);

    // Introduce a policyIsLimitOnly for use in WC policy rendering
    const policyIsLimitOnly = lob.lobType === 'workersCompensationLiabilityLob';

    return coverageLimitAndRetention ? (
        <StackLayout gap="none">
            <Text style="heading 5">
                Coverage Limit {!policyIsLimitOnly ? 'and Retention' : ''}
            </Text>
            <Table>
                <Table.Header>
                    <Table.Column>COVERAGE</Table.Column>
                    <Table.Column>
                        {policyIsLimitOnly
                            ? null
                            : `COVERAGE ${policyHasDeductible ? 'DEDUCTIBLE' : 'RETENTION'}`}
                    </Table.Column>
                    <Table.Column>COVERAGE LIMIT</Table.Column>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            <ColumnLayout split="0">
                                <Text style="label 1">{getLOBName(lob.lobType)}</Text>
                            </ColumnLayout>
                        </Table.Cell>
                        <Table.Cell>
                            {lob.lobType == 'excessLiabilityLob' ? (
                                <ColumnLayout>
                                    <Text style="label 1">N/A</Text>
                                </ColumnLayout>
                            ) : policyHasDeductible ? (
                                coverageLimitAndRetention.deductibleAmount ? (
                                    amountColumn(coverageLimitAndRetention.deductibleAmount)
                                ) : null
                            ) : coverageLimitAndRetention.sirAmount ? (
                                amountColumn(coverageLimitAndRetention.sirAmount)
                            ) : null}
                        </Table.Cell>
                        <Table.Cell>
                            {coverageLimitAndRetention.amount ? (
                                amountColumn(coverageLimitAndRetention.amount)
                            ) : (
                                <Text style="label 1">{coverageLimitAndRetention.description}</Text>
                            )}
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </StackLayout>
    ) : null;
};

function getCoverageLimitAndRetention(lob: Immutable<Lob>): Limit | undefined {
    if (lob.propertySection && checkLimitAmounts(lob.propertySection.limit)) {
        return lob.propertySection.limit;
    } else if (lob.liabilitySection && checkLimitAmounts(lob.liabilitySection.limit)) {
        return lob.liabilitySection.limit;
    } else {
        return undefined;
    }
}

function checkLimitAmounts(limit: Limit): boolean {
    return (
        limit.amount != undefined ||
        limit.deductibleAmount != undefined ||
        limit.sirAmount != undefined ||
        limit.description != undefined
    );
}
