import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { State } from '@embroker/shotwell/core/types/StateList';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { InputStatus, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { WizardForm } from '../../../../../view/hooks/useWizardForm';
import { PCoMLQuoteOptions } from '../../../entities/PCoMLQuote';
import { InsuranceApplicationRestriction } from '../../../types/InsuranceApplicationRestriction';
import { PCoMLQuoteOptionsFormData } from '../PCoMLQuoteLandingPage';
import { PCoMLRecommendedCoverage } from './PCoMLRecommendedCoverage';
import { getCoverageRestriction } from './PCoMLSelectedCoverageList';

interface PCoMLRecommendedCoveragesProps {
    isRenewal: boolean;
    quoteOptions: PCoMLQuoteOptions;
    isSubmitting: boolean;
    newInsurerDocumentsReleaseDate?: Date;
    submittedAt?: Date;
    state?: Nullable<State>;
    fields: WizardForm<OpaqueForm<PCoMLQuoteOptionsFormData>>['fields'];
    handleIsDNOSelected: () => void;
    handleIsEPLSelected: () => void;
    restriction?: Immutable<InsuranceApplicationRestriction>;
}

export function PCoMLRecommendedCoverageList({
    isRenewal,
    quoteOptions,
    fields,
    isSubmitting,
    newInsurerDocumentsReleaseDate,
    submittedAt,
    state,
    handleIsDNOSelected,
    handleIsEPLSelected,
    restriction,
}: PCoMLRecommendedCoveragesProps) {
    const dnoRestriction = restriction && getCoverageRestriction('do', restriction);
    const eplRestriction = restriction && getCoverageRestriction('epl', restriction);

    const dnoAllowed = !dnoRestriction || dnoRestriction.allowCoverage;
    const eplAllowed = !eplRestriction || eplRestriction.allowCoverage;

    if (
        (fields.isEplSelected.props.value === quoteOptions.epl.inShoppingCoverageList ||
            !eplAllowed) &&
        (fields.isDnoSelected.props.value === quoteOptions.dno.inShoppingCoverageList ||
            !dnoAllowed)
    ) {
        return null;
    }
    return (
        <StackLayout>
            <Text style="heading 4" data-e2e="recommended-coverages">
                Recommended Coverages
            </Text>
            <InputStatus messages={fields.coverage.messages} role="alert" />
            {!fields.isDnoSelected.props.value &&
                quoteOptions.dno.inShoppingCoverageList &&
                dnoAllowed && (
                    <PCoMLRecommendedCoverage
                        isRenewal={isRenewal}
                        type={'dno'}
                        quoteOptions={quoteOptions}
                        fields={fields}
                        isSubmitting={isSubmitting}
                        handleIsSelected={handleIsDNOSelected}
                        newInsurerDocumentsReleaseDate={newInsurerDocumentsReleaseDate}
                        submittedAt={submittedAt}
                        state={state}
                    />
                )}
            {!fields.isEplSelected.props.value &&
                quoteOptions.epl.inShoppingCoverageList &&
                eplAllowed && (
                    <PCoMLRecommendedCoverage
                        isRenewal={isRenewal}
                        type={'epl'}
                        quoteOptions={quoteOptions}
                        handleIsSelected={handleIsEPLSelected}
                        isSubmitting={isSubmitting}
                        fields={fields}
                        newInsurerDocumentsReleaseDate={newInsurerDocumentsReleaseDate}
                        submittedAt={submittedAt}
                        state={state}
                    />
                )}
        </StackLayout>
    );
}
