import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { defineValidator } from '@embroker/shotwell/core/validation/schema';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { APIInsuranceApplicationRestrictionRepository } from '../repositories/InsuranceApplicationRestrictionRepository/APIInsuranceApplicationRestrictionRepository';
import { InsuranceApplicationRestriction } from '../types/InsuranceApplicationRestriction';
import { GetRestrictionFailed } from '../errors';

export interface GetRestrictionRequest {
    readonly insuranceApplicationId: UUID;
}

export interface GetRestrictionResponse {
    readonly restriction?: InsuranceApplicationRestriction;
}

export const GetRestrictionResponse = {
    ...defineValidator<GetRestrictionResponse>({
        restriction: InsuranceApplicationRestriction.schema,
    }),
    create(getRestrictionResponse: GetRestrictionResponse) {
        return GetRestrictionResponse.validate(getRestrictionResponse);
    },
};

export interface GetRestriction extends UseCase {
    execute(
        request: GetRestrictionRequest,
    ): AsyncResult<GetRestrictionResponse, InvalidArgument | GetRestrictionFailed | UnknownEntity>;
}

@injectable()
export class GetRestrictionUseCase extends UseCase implements GetRestriction {
    public static type = Symbol('InsuranceApplicationRestriction/GetRestriction');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(APIInsuranceApplicationRestrictionRepository)
        private insuranceApplicationRestrictionRepository: APIInsuranceApplicationRestrictionRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: GetRestrictionRequest,
    ): AsyncResult<GetRestrictionResponse, InvalidArgument | GetRestrictionFailed | UnknownEntity> {
        const result = await this.insuranceApplicationRestrictionRepository.get(
            request.insuranceApplicationId,
        );

        if (isErr(result)) {
            return Failure(GetRestrictionFailed());
        }

        return Success({
            restriction: result.value,
        });
    }
}

export const GetRestriction: UseCaseClass<GetRestriction> = GetRestrictionUseCase;
