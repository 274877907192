import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { BundleQuote } from '../entities/BundleQuote';
import { BundleQuoteRepository } from '../repositories';
import { aggregateErrors } from './useCaseTypes';

export interface RequestHigherLimitsRequest {
    bundleAppId: UUID;
    applicationIdList: UUID[];
}

export interface RequestHigherLimit extends UseCase {
    execute(
        request: RequestHigherLimitsRequest,
    ): AsyncResult<BundleQuote, InvalidArgument | OperationFailed>;
}

@injectable()
export class RequestHigherLimitUseCase extends UseCase implements RequestHigherLimit {
    public static type = Symbol('BundleQuote/RequestHigherLimit');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BundleQuoteRepository) private bundleQuoteRepository: BundleQuoteRepository,
    ) {
        super(eventBus);
    }

    async execute({
        bundleAppId,
        applicationIdList,
    }: RequestHigherLimitsRequest): AsyncResult<BundleQuote, InvalidArgument | OperationFailed> {
        const requestHigherLimitsResultList = await Promise.allSettled(
            applicationIdList.map((applicationId) => {
                return this.bundleQuoteRepository.requestHigherLimit(applicationId);
            }),
        );

        const errors = aggregateErrors<RequestHigherLimit>(
            requestHigherLimitsResultList,
            'Failed higher limit request',
        );

        if (errors.length) {
            return Failure(errors);
        }

        const getCurrentQuoteResult = await this.bundleQuoteRepository.getLastBundleQuote(
            bundleAppId,
        );
        if (isErr(getCurrentQuoteResult)) {
            return getCurrentQuoteResult;
        }

        return Success(getCurrentQuoteResult.value);
    }
}

export const RequestHigherLimit: UseCaseClass<RequestHigherLimit> = RequestHigherLimitUseCase;
