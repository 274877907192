import { inject } from '@embroker/shotwell/core/di';
import { Aborted, InvalidArgument, OperationFailed, Timeout } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { cast } from '@embroker/shotwell/core/types/Nominal';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { TasksRepository } from '../../tasks/repositories';
import {
    SignatureDocumentAlreadySigned,
    SignatureDocumentManagingBrokerNotFound,
    SignatureDocumentNotAllowed,
    SignatureDocumentNotFound,
} from '../errors';
import { BrokerRepository } from '../repositories';

export interface GetSignatureDocumentUrlRequest {
    token: string;
    abortSignal: AbortSignal;
    existingDocumentUrl?: string;
}

export interface GetSignatureDocumentUrlResponse {
    readonly documentUrl: URI;
}

export interface GetSignatureDocumentUrl extends UseCase {
    execute(
        request: GetSignatureDocumentUrlRequest,
    ): AsyncResult<
        GetSignatureDocumentUrlResponse,
        | OperationFailed
        | InvalidArgument
        | SignatureDocumentNotFound
        | SignatureDocumentNotAllowed
        | SignatureDocumentAlreadySigned
        | SignatureDocumentManagingBrokerNotFound
        | Aborted
        | Timeout
    >;
}

class GetSignatureDocumentUrlUseCase extends UseCase implements GetSignatureDocumentUrl {
    public static type = Symbol('DigitalSigning/GetSignatureRequestUrl');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BrokerRepository) private brokerRepository: BrokerRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        token,
        abortSignal,
        existingDocumentUrl,
    }: GetSignatureDocumentUrlRequest): AsyncResult<
        GetSignatureDocumentUrlResponse,
        | OperationFailed
        | InvalidArgument
        | SignatureDocumentNotFound
        | SignatureDocumentNotAllowed
        | SignatureDocumentAlreadySigned
        | SignatureDocumentManagingBrokerNotFound
        | Aborted
        | Timeout
    > {
        if (existingDocumentUrl) {
            return Success<GetSignatureDocumentUrlResponse>({
                documentUrl: cast<URI>(existingDocumentUrl),
            });
        }

        const taskResult = await this.brokerRepository.createSignatureDocument({
            token,
        });
        if (isErr(taskResult)) {
            return taskResult;
        }

        const documentTaskStatusResult =
            await this.tasksRepository.pollForSignatureDocumentTaskStatus(token, abortSignal);
        if (isErr(documentTaskStatusResult)) {
            return documentTaskStatusResult;
        }

        const signatureDocumentResult = await this.brokerRepository.getSignatureDocument({ token });
        if (isErr(signatureDocumentResult)) {
            return signatureDocumentResult;
        }

        return Success<GetSignatureDocumentUrlResponse>({
            documentUrl: cast<URI>(signatureDocumentResult.value.document_url ?? ''),
        });
    }
}

export const GetSignatureDocumentUrl: UseCaseClass<GetSignatureDocumentUrl> =
    GetSignatureDocumentUrlUseCase;
