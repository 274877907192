import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';

export interface SessionCreateParams {
    username: string;
    password: string;
}

/**
 * This is the repository used to work with user notifications
 */
export interface NotificationRepository {
    /**
     * Get number of unread notifications
     * @return OperationFailed | InvalidArgument if request failed
     * @return number of unread notifications
     */
    pendingNotificationsCount(): AsyncResult<number, InvalidArgument | OperationFailed>;
}

export const NotificationRepository = Symbol('NotificationRepository');
