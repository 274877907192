import { ReactProps, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import { format } from 'date-fns';
import React from 'react';
import { ESPRenewalQuoteOptions } from '../../entities/ESPRenewalQuote';
import { ESPCoverageType } from '../../types/CoverageRestriction';
import { Endorsement } from '../../types/EndorsementList';

interface ESPRenewalEndorsementListProps extends ReactProps<'div'> {
    quoteOptions: ESPRenewalQuoteOptions;
    endorsements: Endorsement[];
}

export function ESPRenewalEndorsementList({
    quoteOptions,
    endorsements,
}: ESPRenewalEndorsementListProps) {
    const selectedCoverageTypes = getSelectedCoverageTypes(quoteOptions);

    const endorsementsToDisplay = endorsements.filter((endorsement) => {
        if (!endorsement.allowedCoverages) {
            return false;
        }

        // check if there is intersection between allowed and selected coverages
        return (
            endorsement.allowedCoverages.filter((allowedCoverage) =>
                selectedCoverageTypes.includes(allowedCoverage),
            ).length > 0
        );
    });

    return (
        <StackLayout>
            {endorsementsToDisplay?.length > 0 && <Text style="heading 4">Endorsements</Text>}
            <ul>
                {endorsementsToDisplay
                    .map((endorsement) => ({
                        id: endorsement.id,
                        name: endorsement.name,
                        displayName: endorsement.displayName,
                        inputListSerialized:
                            endorsement.inputList.length > 0
                                ? `( ${endorsement.inputList
                                      .map((input) =>
                                          input.value === 'inception'
                                              ? format(quoteOptions.effectiveDate, 'MM/dd/yyyy')
                                              : input.value,
                                      )
                                      .join(' , ')} )`
                                : '',
                    }))
                    .sort((endt1, endt2) => (endt1.name > endt2.name ? 1 : -1))
                    .map((endorsement) => (
                        <li key={endorsement.id}>
                            <Text style="body 2">
                                {endorsement.displayName
                                    ? endorsement.displayName
                                    : endorsement.name}{' '}
                                {endorsement.inputListSerialized}
                            </Text>
                        </li>
                    ))}
            </ul>
        </StackLayout>
    );
}

function getSelectedCoverageTypes(quoteOptions: ESPRenewalQuoteOptions): ESPCoverageType[] {
    const typesArray: ESPCoverageType[] = [];

    if (quoteOptions.coverage.dno?.isSelected) {
        typesArray.push('ShoppingCoverageCodeListDirectorsAndOfficers');
    }

    if (quoteOptions.coverage.fiduciary?.isSelected) {
        typesArray.push('ShoppingCoverageCodeListFiduciary');
    }

    if (quoteOptions.coverage.epli?.isSelected) {
        typesArray.push('ShoppingCoverageCodeListEmploymentPractices');
    }

    if (quoteOptions.coverage.eo?.isSelected) {
        typesArray.push('ShoppingCoverageCodeListCyber');
    }

    if (quoteOptions.coverage.techCyber?.isSelected) {
        typesArray.push('ShoppingCoverageCodeListTechSplit');
    }

    return typesArray;
}
