import React from 'react';
import { WelcomeHeroBanner } from '../../../view/components/WelcomeHeroBanner.view';
import { Nullable, Immutable } from '@embroker/shotwell/core/types';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Application } from '../../../shopping/entities/Application';
import { DisplayPolicy } from '../../../policy/types/DisplayPolicy';

enum WelcomeMessage {
    HowCanWeHelp = 'How can we help you today?',
    CompleteYourApplication = 'Complete your application',
    CompleteYourApplications = 'Complete your applications',
    NotCovered = `Let's get you covered`,
    Renew = `It's time to renew your policies`,
}

type DashboardWelcomeBannerProps = {
    userFirstName: Nullable<string>;
    renewalsList: Immutable<Array<EntityProps<Application>>>;
    applicationList: Immutable<Array<EntityProps<Application>>>;
    quotesList: Immutable<Array<EntityProps<Application>>>;
    policyList: Immutable<DisplayPolicy[]>;
};

export function DashboardWelcomeBanner({
    userFirstName,
    renewalsList,
    applicationList,
    quotesList,
    policyList,
}: DashboardWelcomeBannerProps) {
    const greeting: string = userFirstName ? `Welcome ${userFirstName},` : 'Welcome,';
    const hasAnyApplicationOrPolicy: boolean =
        applicationList.length > 0 ||
        renewalsList.length > 0 ||
        quotesList.length > 0 ||
        policyList.length > 0;

    let welcomeMessage: WelcomeMessage;

    if (!hasAnyApplicationOrPolicy) {
        welcomeMessage = WelcomeMessage.NotCovered;
    } else {
        if (renewalsList.length > 0) {
            welcomeMessage = WelcomeMessage.Renew;
        } else if (applicationList.length + quotesList.length == 1) {
            welcomeMessage = WelcomeMessage.CompleteYourApplication;
        } else if (applicationList.length + quotesList.length > 1) {
            welcomeMessage = WelcomeMessage.CompleteYourApplications;
        } else {
            welcomeMessage = WelcomeMessage.HowCanWeHelp;
        }
    }
    return <WelcomeHeroBanner title={greeting} subtitle={welcomeMessage} />;
}
