import { Nullable } from '@embroker/shotwell/core/types';
import { State } from '@embroker/shotwell/core/types/StateList';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    Form,
    Modal,
    ReactProps,
    StackLayout,
    Text,
    TextButton,
    useModal,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { WizardForm } from '../../../../../view/hooks/useWizardForm';
import { PCoMLQuote } from '../../../entities/PCoMLQuote';
import { PCoMLQuoteOptionsFormData } from '../PCoMLQuoteLandingPage';
import { WarrantyAndFraudModalContent } from './WarrantyAndFraudModalContent';
import { SignInsuranceApplicationCheckbox } from '../../../../view/components/signature/SignInsuranceApplicationCheckbox';

interface PCoMLUserInfo {
    company: string;
    fullName: string;
    title: string;
    usaState: Nullable<State>;
}

interface QuoteSignatureProps extends ReactProps<'div'> {
    quote: PCoMLQuote;
    fields: WizardForm<OpaqueForm<PCoMLQuoteOptionsFormData>>['fields'];
    setValue: WizardForm<OpaqueForm<PCoMLQuoteOptionsFormData>>['setValue'];
    value: WizardForm<OpaqueForm<PCoMLQuoteOptionsFormData>>['value'];
    trigger: WizardForm<OpaqueForm<PCoMLQuoteOptionsFormData>>['trigger'];
}

export function QuoteSignature({
    quote,
    fields,
    setValue,
    value,
    trigger,
    className,
}: QuoteSignatureProps) {
    const warrantyAndFraudModal = useModal();

    const userInfo = quote.applicationInfo?.userInfo as PCoMLUserInfo;

    const handleStatementAgreementCheckChanged = (
        { target: { checked } }: React.ChangeEvent<HTMLInputElement>,
        property: string,
    ) => {
        setValue({
            ...value,
            [property]: checked,
        });
        trigger('sign');
    };

    return (
        <StackLayout className={className} gap="32">
            <Text style="heading 4">Please agree to the statements below</Text>
            <Form.Field
                type={fields.agreementToConductSignature.type}
                messages={fields.agreementToConductSignature.messages}
                inputProps={{
                    checked: fields.agreementToConductSignature.props.value,
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                        handleStatementAgreementCheckChanged(event, 'agreementToConductSignature');
                    },
                    children: (
                        <SignInsuranceApplicationCheckbox
                            company={userInfo.company}
                            fullName={userInfo.fullName}
                            usaState={userInfo.usaState}
                        />
                    ),
                }}
                data-e2e="agreement-to-conduct-signature"
            />
            <Form.Field
                type={fields.warrantyAndFraudSignature.type}
                messages={fields.warrantyAndFraudSignature.messages}
                inputProps={{
                    checked: fields.warrantyAndFraudSignature.props.value,
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                        handleStatementAgreementCheckChanged(event, 'warrantyAndFraudSignature');
                    },
                    children: (
                        <Text as="span">
                            By checking this box, you certify all information provided in the
                            insurance application is true and correct and acknowledge that you have
                            read and agree to the{' '}
                            <TextButton onClick={warrantyAndFraudModal.show}>
                                Warranty and Fraud Statement
                            </TextButton>
                        </Text>
                    ),
                }}
                data-e2e="warranty-and-fraud-signature"
            />
            <Modal {...warrantyAndFraudModal} size="medium">
                <WarrantyAndFraudModalContent
                    fullName={userInfo.fullName}
                    title={userInfo.title}
                    usaState={userInfo.usaState}
                />
            </Modal>
        </StackLayout>
    );
}
