import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { UseCase, UseCaseClass, execute } from '@embroker/shotwell/core/UseCase';
import { inject } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, Success, isErr } from '@embroker/shotwell/core/types/Result';
import {
    AppTypeCode,
    AppTypeCodeListEverestLawyersProfessionalLiability,
} from '@app/shopping/types/enums';
import { GetApplicant } from './GetApplicant';
import { NAICS_CODE_TO_VERTICAL } from '../../userOrg/types/enums';
import { GetGlobalConfigFailed } from '../../config/errors';
import { AppTypeNotSupported } from '../errors';
import { GetApplicationList } from './GetApplicationList';
import { UUID } from '@embroker/shotwell/core/types/UUID';

export interface StartApplicationIsRenewalRequest {
    selectedAppTypes: AppTypeCode[];
}

export type StartApplicationIsRenewalResponse = {
    [key: string]: UUID | undefined;
};
export interface StartApplicationIsRenewal extends UseCase {
    execute(
        request: StartApplicationIsRenewalRequest,
    ): AsyncResult<
        StartApplicationIsRenewalResponse,
        | OperationFailed
        | GetGlobalConfigFailed
        | InvalidArgument
        | UnknownEntity
        | AppTypeNotSupported
    >;
}

export class StartApplicationIsRenewalUseCase extends UseCase implements StartApplicationIsRenewal {
    public static type = Symbol('Shopping/StartApplicationIsRenewal');

    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    public async execute({
        selectedAppTypes,
    }: StartApplicationIsRenewalRequest): AsyncResult<
        StartApplicationIsRenewalResponse,
        | OperationFailed
        | GetGlobalConfigFailed
        | InvalidArgument
        | UnknownEntity
        | AppTypeNotSupported
    > {
        const applicationListResp = await execute(GetApplicationList);
        if (isErr(applicationListResp)) {
            return Failure(OperationFailed({ message: 'GetApplicationList failed' }));
        }

        const {
            value: { applicationList },
        } = applicationListResp;

        const applicantResp = await execute(GetApplicant);
        if (isErr(applicantResp)) {
            return applicantResp;
        }

        const lplRenewalApplications = applicationList.find((app) => {
            return (
                app.appType === AppTypeCodeListEverestLawyersProfessionalLiability &&
                app.renewedPolicyIdList.length > 0 &&
                app.status === 'InsuranceApplicationStatusCodeListQuestionnaireInProgress'
            );
        });

        const {
            value: { applicant },
        } = applicantResp;

        if (!selectedAppTypes) {
            return Failure(OperationFailed({ message: 'selectedAppTypes is empty' }));
        }

        const applicantNaics = applicant?.naicsIndustry || '';
        const isLawFirmVertical = NAICS_CODE_TO_VERTICAL[applicantNaics] === 'LawFirm';

        const isLPLApp =
            selectedAppTypes.includes(AppTypeCodeListEverestLawyersProfessionalLiability) &&
            isLawFirmVertical;

        const lplRenewalApplicationId = isLPLApp ? lplRenewalApplications?.id : undefined;

        return Success({ lplRenewalApplicationId: lplRenewalApplicationId });
    }
}

export const StartApplicationIsRenewal: UseCaseClass<StartApplicationIsRenewal> =
    StartApplicationIsRenewalUseCase;
