import { Money } from '@embroker/shotwell/core/types/Money';
import { OracleAnswerTypeDefinition } from '../OracleAnswerType';
import { Joi } from '@embroker/shotwell/core/validation/schema';

export type OracleCurrency = {
    currency_code: string;
    unit_amount: number;
};

export const serializeCurrencyAnswer = (formData: unknown): OracleCurrency => {
    const currency = Money.tryFromFloat(formData as number, { precision: 2 });
    return {
        currency_code: currency.currency,
        unit_amount: currency.amount,
    };
};
export function assertOracleCurrencyType(input: any): input is OracleCurrency {
    return 'currency_code' in input && 'unit_amount' in input;
}
export const deserializeCurrencyAnswers = (currentValue: any): number[] | undefined => {
    if (!Array.isArray(currentValue)) {
        return undefined;
    }

    return (currentValue as OracleCurrency[]).reduce((accumulator, current) => {
        if (assertOracleCurrencyType(current)) {
            const currency = Money.tryFromFloat(current.unit_amount, { precision: -2 });
            accumulator.push(currency.amount);
        }
        return accumulator;
    }, [] as number[]);
};

export const oracleCurrencySchema = Joi.object({
    currency_code: Joi.string().required(),
    unit_amount: Joi.number().integer().required(),
});

export const CurrencyDefinition: OracleAnswerTypeDefinition = {
    answerKeyType: 'currency',
    schemaFunctions: {
        validator: oracleCurrencySchema,
        serializeAnswer: serializeCurrencyAnswer,
        deserializeAnswer: deserializeCurrencyAnswers,
    },
};
