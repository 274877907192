import { inject, injectable } from '@embroker/shotwell/core/di';
import { OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { SupplementalQuestionnaireRepository } from '../esp/repositories/SupplementalQuestionnaireRepository';

export interface SubmitSupplementalQuestionnaireRequest {
    applicationId: UUID;
    questionnaireData: string;
}

export interface SubmitSupplementalQuestionnaire extends UseCase {
    execute(request: SubmitSupplementalQuestionnaireRequest): AsyncResult<void, OperationFailed>;
}

@injectable()
export class SubmitSupplementalQuestionnaireUseCase
    extends UseCase
    implements SubmitSupplementalQuestionnaire
{
    public static type = Symbol(
        'higherLimitsSupplementalQuestionnaire/SubmitSupplementalQuestionnaire',
    );

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(SupplementalQuestionnaireRepository)
        private supplementalQuestionnaireRepository: SupplementalQuestionnaireRepository,
    ) {
        super(eventBus);
    }

    async execute({
        applicationId,
        questionnaireData,
    }: SubmitSupplementalQuestionnaireRequest): AsyncResult<void, OperationFailed> {
        const result =
            await this.supplementalQuestionnaireRepository.submitSupplementalQuestionnaire({
                applicationId,
                questionnaireData,
            });
        if (isErr(result)) {
            return result;
        }

        return await this.supplementalQuestionnaireRepository.requestHigherLimits(applicationId);
    }
}

export const SubmitSupplementalQuestionnaire: UseCaseClass<SubmitSupplementalQuestionnaire> =
    SubmitSupplementalQuestionnaireUseCase;
