import { FilterOption } from '@embroker/ui-toolkit/v2';

export const ProductTypeList = ['Crime', 'Cyber', 'ESP', 'Excess', 'LPL', 'PCoML'] as const;

export type ProductType = (typeof ProductTypeList)[number];

export class ProductTypeFilter {
    public static getFilters(includeExcess = true): FilterOption<ProductType>[] {
        if (!includeExcess) {
            return ProductTypeFilters.filter((e) => e.value !== 'Excess');
        }

        return ProductTypeFilters;
    }
}

const ProductTypeFilters: FilterOption<ProductType>[] = [
    {
        value: 'ESP',
        title: 'ESP',
    },
    {
        value: 'PCoML',
        title: 'PCoML',
    },
    {
        value: 'Excess',
        title: 'Excess',
    },
    {
        value: 'LPL',
        title: 'LPL',
    },
    {
        value: 'Crime',
        title: 'Crime',
    },
    {
        value: 'Cyber',
        title: 'Cyber',
    },
];
