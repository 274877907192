import { FormFieldViewDefinition } from '../components/DataDrivenForm';

export interface DdFormFieldSetProps {
    title: React.ReactNode;
    questions: string[];
}

export const isFieldSet = (question: FormFieldViewDefinition) => {
    return Boolean(question.questionProps.staticOptions?.fieldSetProps !== undefined);
};

export const getFieldSets = (questions: FormFieldViewDefinition[]): FormFieldViewDefinition[] => {
    return questions.filter((question) => isFieldSet(question));
};

export const getQuestionsForFieldSet = (
    filedSet: FormFieldViewDefinition,
    questions: FormFieldViewDefinition[],
): FormFieldViewDefinition[] => {
    const keys = filedSet.questionProps.staticOptions?.fieldSetProps?.questions || [];
    return questions.filter((question) => keys.includes(question.questionProps.key));
};

export const isStandAloneQuestion = (
    key: string,
    questions: FormFieldViewDefinition[],
): boolean => {
    return !getFieldSets(questions).some((fieldSet) =>
        fieldSet.questionProps.staticOptions?.fieldSetProps?.questions.includes(key),
    );
};
