import { Money } from '@embroker/shotwell/core/types/Money';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    BoxLayout,
    Button,
    ColumnLayout,
    Form,
    InvoiceTable,
    Modal,
    ScrollBox,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import { useStripe } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { PayByACH } from '../../../useCases/PayByACH';
import { errorsStyle } from './PayWithCCModal';

interface ACHModalProps {
    hideModal(): void;
    showThankYouModal?(): void;
    total: Money;
    activeInvoices: UUID[];
    modal: any;
    publicKey?: UUID;
}

export interface PayByACHInput {
    readonly invoiceIds: UUID[];
    readonly routingNumber: string;
    readonly accountNumber: string;
    readonly accountHolderName: string;
    readonly stripe: object;
    readonly total: Money;
}

const ACHForm = createForm<PayByACHInput>({
    fields: {
        routingNumber: {
            type: 'text',
            validator: Joi.string().required().length(9).replace(' ', ''),
            formatValidationError: (error) => {
                switch (error.details.validator) {
                    case 'string.length':
                        return 'Routing number must have 9 digits.';
                    case 'any.required':
                        return 'Routing number is required.';
                    case 'string.empty':
                        return 'Routing number is empty.';
                    default:
                        return error.message;
                }
            },
        },
        accountNumber: {
            type: 'text',
            validator: Joi.string().required(),
            formatValidationError: (error) => {
                switch (error.details.validator) {
                    case 'any.required':
                        return 'Account number is required.';
                    case 'string.empty':
                        return 'Account number is empty.';
                    default:
                        return error.message;
                }
            },
        },
        accountHolderName: {
            type: 'text',
            validator: Joi.string().required(),
            formatValidationError: (error) => {
                switch (error.details.validator) {
                    case 'any.required':
                        return 'Account holder name is required.';
                    case 'string.empty':
                        return 'Account holder name is empty.';
                    default:
                        return error.message;
                }
            },
        },
        invoiceIds: {
            type: 'hidden',
            validator: Joi.array().items(UUID.schema).min(1),
        },
        stripe: {
            type: 'hidden',
            validator: Joi.object(),
        },
        total: {
            type: 'hidden',
            validator: Money.schema.required(),
        },
    },
    useCase: PayByACH,
});

function PayWithACH(props: ACHModalProps & { stripe: Stripe }) {
    const navigation = useNavigation();
    const { submit, fields, status, messages, result, setValue, value, reset } = useForm(ACHForm, {
        stripe: props.stripe,
        total: props.total,
    });
    const { hideModal, showThankYouModal } = props;
    const [displayError, setDisplayError] = useState(false);
    const [disablePayButton, setDisablePayButton] = useState(false);

    useEffect(() => {
        if (messages.length) {
            setDisplayError(true);
        }
    }, [messages]);

    useEffect(() => {
        if (status == 'submitting') {
            setDisablePayButton(true);
            return;
        }
        setDisablePayButton(false);
        if (status === 'dirty') {
            setValue({ ...value, invoiceIds: props.activeInvoices });
            setDisplayError(false);
        }
    }, [status, setValue, value, props.activeInvoices]);

    useEffect(() => {
        if (result !== undefined && props.publicKey == undefined) {
            hideModal();
            reset();
            if (showThankYouModal !== undefined) {
                showThankYouModal();
            }
        }
        if (result !== undefined && props.publicKey !== undefined && status === 'submitted') {
            navigation.navigate(
                URI.build('/payments/stripe/payment/thank-you', { publicKey: props.publicKey }),
            );
        }
    }, [result, props.publicKey, hideModal, showThankYouModal, reset, navigation, status]);

    function onCancelModal() {
        setDisplayError(false);
        reset();
        props.hideModal();
    }

    return (
        <Modal {...props.modal} hide={onCancelModal}>
            <Form onSubmit={submit}>
                <ScrollBox>
                    <StackLayout gap="16">
                        <Text data-e2e="pay-with-ach-modal-header" style="heading 3">
                            Pay Via Your Bank
                        </Text>
                        <Text data-e2e="pay-ach-routing-number-label">Bank routing number</Text>
                        <Form.Field
                            data-e2e="pay-ach-routing-number-input"
                            inputProps={{
                                ...fields.routingNumber.props,
                            }}
                            type="text"
                            messages={fields.routingNumber.messages}
                        />
                        <Text data-e2e="pay-ach-account-number-label">Bank account number</Text>
                        <Form.Field
                            data-e2e="pay-ach-account-number-input"
                            inputProps={{
                                ...fields.accountNumber.props,
                            }}
                            type="text"
                            messages={fields.accountNumber.messages}
                        />
                        <Text data-e2e="pay-ach-holder-name-label">Account holder name</Text>
                        <Form.Field
                            data-e2e="pay-ach-holder-name-input"
                            inputProps={{
                                ...fields.accountHolderName.props,
                            }}
                            label=""
                            type="text"
                            messages={fields.accountHolderName.messages}
                        />
                        <Text data-e2e="pay-with-ach-disclaimer-txt">
                            By clicking ‘Pay’ below, I authorize Embroker Insurance Services LLC to
                            electronically debit my account.
                        </Text>
                        <hr />
                        <InvoiceTable>
                            <InvoiceTable.Section>
                                <InvoiceTable.Total
                                    data-e2e="pay-with-ach-total-amount-wrapper"
                                    title="Total Amount"
                                >
                                    <MoneyDisplay value={props.total} />
                                </InvoiceTable.Total>
                            </InvoiceTable.Section>
                        </InvoiceTable>
                        <hr />
                        <Text>
                            {displayError && (
                                <span style={errorsStyle}>
                                    {messages.map((message) => message)}
                                </span>
                            )}
                        </Text>
                        <ColumnLayout>
                            <Button
                                data-e2e="pay-with-ach-submit-btn"
                                appearance="primary"
                                type="submit"
                                disabled={disablePayButton}
                            >
                                Pay
                            </Button>
                            <BoxLayout>
                                <TextButton
                                    data-e2e="pay-with-ach-cancel-btn"
                                    onClick={onCancelModal}
                                >
                                    Cancel
                                </TextButton>
                            </BoxLayout>
                        </ColumnLayout>
                    </StackLayout>
                </ScrollBox>
            </Form>
        </Modal>
    );
}

export function PayWithACHModal(props: ACHModalProps) {
    const stripe = useStripe();
    if (stripe === null) {
        return null;
    }
    return <PayWithACH {...props} stripe={stripe} />;
}
