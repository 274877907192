import { StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';

export function LPLFooter() {
    return (
        <StackLayout gap="none">
            <Text color="ui-400" style="body 2" data-e2e="lpl-carrier">
                LPL Carrier: Everest National Insurance Company
            </Text>
            <Text color="ui-500" style="body 2" data-e2e="lpl-carrier-description">
                Everest National Insurance Company is currently rated AM Best A+ (Superior) with a
                Financial Size Category of XV ($2 Billion or greater) and is part of the S&P 500
                Index. The Lawyers Leadership Team at Everest has deep experience protecting law
                firms for legal malpractice over an average of 20 years.
            </Text>
        </StackLayout>
    );
}
