import { QuoteExtended } from '@embroker/shotwell-api/app';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, Failure, isErr } from '@embroker/shotwell/core/types/Result';
import { startOfToday } from 'date-fns';
import { QuoteExpiration } from '../../../quote/types/QuoteExpiration';
import { QuotingEngineLawCyber } from '../../../shopping/types/enums';
import {
    BundleCoverageTypeEnum,
    BundleQuoteCoverage,
    BundleQuoteCoverageMetadata,
} from '../../types/BundleQuoteCoverage';
import { toBundleQuoteCoverage } from '../bundleMappingFunctions';
import { LawCyberQuote } from './entities/LawCyberQuote';
import { toLawCyberQuoteDetails, toLawCyberQuoteOptions } from './mappingFunctions';
import { JSONSerdes } from '@embroker/shotwell/core/encoding';
import { LawCyberQuestionnaireData } from './types/LawCyberQuestionnaireData';

export async function buildLawCyberCoverage(
    lawCyberQuoteExtended: Immutable<QuoteExtended>,
    isBroker: boolean,
    omitEffectiveDateValidation = false,
): AsyncResult<BundleQuoteCoverage<LawCyberQuote>, OperationFailed | InvalidArgument> {
    if (!lawCyberQuoteExtended) {
        return Failure(OperationFailed({ message: 'lawCyberQuoteExtended is null or undefined' }));
    }
    const cyberCoverageMetadata = getCyberCoverageMetadata();

    const questionnaireDataResult = JSONSerdes.deserialize(
        lawCyberQuoteExtended.questionnaire_data,
    );
    if (isErr(questionnaireDataResult)) {
        return Failure(
            InvalidArgument({
                argument: 'questionnaire_data',
                value: lawCyberQuoteExtended.questionnaire_data,
            }),
        );
    }

    const lawCyberQuestionnaireData = LawCyberQuestionnaireData.create(
        questionnaireDataResult.value as LawCyberQuestionnaireData,
    );
    if (isErr(lawCyberQuestionnaireData)) {
        return Failure(
            InvalidArgument({
                argument: 'Law Cyber questionnaire data',
                value: lawCyberQuestionnaireData.errors,
            }),
        );
    }

    if (!lawCyberQuoteExtended.quote) {
        return toBundleQuoteCoverage<LawCyberQuote>(
            BundleCoverageTypeEnum.LawCyberBundleCoverage,
            lawCyberQuoteExtended.app_status,
            cyberCoverageMetadata,
            lawCyberQuestionnaireData.value,
            lawCyberQuoteExtended.app_valid_until,
        );
    }
    if (!lawCyberQuoteExtended.quote.details.law_cyber) {
        return Failure(OperationFailed({ message: 'lawCyberQuoteDetails is null or undefined' }));
    }
    if (!lawCyberQuoteExtended.quote.options.law_cyber) {
        return Failure(OperationFailed({ message: 'lawCyberQuoteOptions is null or undefined' }));
    }
    const lawCyberCoverage = await LawCyberQuote.create({
        isIndication: lawCyberQuoteExtended.quote.is_indication,
        id: lawCyberQuoteExtended.quote.id,
        applicationId: lawCyberQuoteExtended.quote.app_id,
        details: toLawCyberQuoteDetails(
            lawCyberQuoteExtended.quote.details.law_cyber,
            lawCyberQuoteExtended.quote.taxes,
        ),
        options: toLawCyberQuoteOptions(lawCyberQuoteExtended.quote.options.law_cyber),
        status: 'draft',
        totalPayable: lawCyberQuoteExtended.quote.total_payable,
        totalPremium: lawCyberQuoteExtended.quote.total_premium,
        annualTechnologyFee: lawCyberQuoteExtended.quote.annual_technology_fee,
        daysToExpire: QuoteExpiration.getDaysLeftUntilExpiration({
            quotingEngine: QuotingEngineLawCyber,
            applicationStatus: lawCyberQuoteExtended.app_status,
            isBroker: isBroker,
            quoteEffectiveDate: lawCyberQuoteExtended.quote.options.law_cyber.effective_date,
            today: startOfToday(),
            validUntil: lawCyberQuoteExtended.app_valid_until || null,
            omitEffectiveDateValidation,
        }),
        quoteNumber: lawCyberQuoteExtended.quote.quote_number,
        fees: lawCyberQuoteExtended.quote.fees || undefined,
        fileKey: lawCyberQuoteExtended.quote.file_key || undefined,
    });
    if (isErr(lawCyberCoverage)) {
        return Failure(
            InvalidArgument({ argument: 'Law cyber coverage', value: lawCyberCoverage.errors }),
        );
    }

    return toBundleQuoteCoverage<LawCyberQuote>(
        BundleCoverageTypeEnum.LawCyberBundleCoverage,
        lawCyberQuoteExtended.app_status,
        cyberCoverageMetadata,
        lawCyberQuestionnaireData.value,
        lawCyberQuoteExtended.app_valid_until,
        lawCyberCoverage.value,
    );
}

export const getCyberCoverageMetadata = (): BundleQuoteCoverageMetadata => {
    return {
        title: 'Cyber Insurance',
        description:
            'A modern business must-have, Cyber covers first and third party claims resulting from security/data breaches and cybercrimes like ransomware, phishing, and more.',
        icon: 'cyber',
        name: 'cyber',
    };
};
