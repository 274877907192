import {
    BoxLayout,
    Button,
    ButtonBar,
    ColumnLayout,
    Image,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { Link } from '../../../../view/components';

interface SubmitRequestFinalPageProps {
    isSuccessful: boolean;
    onIssueAnotherCertificate(): void;
}

export function SubmitRequestFinalPage({
    isSuccessful,
    onIssueAnotherCertificate,
}: SubmitRequestFinalPageProps) {
    return (
        <ColumnLayout responsive={{ containerWidth: { smallerThan: 'tablet' } }}>
            {isSuccessful === true ? (
                <StackLayout className="u-1/2@large-tablet u-1/1" gap="48">
                    <StackLayout gap="24">
                        <Text style={'heading 1'}>That’s it!</Text>
                        <Text style="body 1">
                            Your certificate request has been submitted to our customer success
                            team. They’ll be in touch with you shortly!
                        </Text>
                    </StackLayout>
                    <ButtonBar responsive={{ containerWidth: { smallerThan: 400 } }}>
                        <Button size="large" onClick={onIssueAnotherCertificate}>
                            Issue Another
                        </Button>
                    </ButtonBar>
                </StackLayout>
            ) : (
                <StackLayout className="u-1/2@large-tablet u-1/1" gap="48">
                    <StackLayout gap="24">
                        <Text style={'heading 1'}>Failed to request the certificate!</Text>
                        <Text style="body 1">We’ve encountered an error. Please try again.</Text>
                        <ButtonBar responsive={{ containerWidth: { smallerThan: 400 } }} gap={'48'}>
                            <Button onClick={onIssueAnotherCertificate}>Try Again</Button>
                            <TextButton as={Link} href="/support/contact">
                                Contact Support
                            </TextButton>
                        </ButtonBar>
                    </StackLayout>
                </StackLayout>
            )}
            {isSuccessful === true ? (
                <BoxLayout className="u-1/2@large-tablet u-1/1">
                    <Image width="100%" name="illustration-laurel" />
                </BoxLayout>
            ) : null}
        </ColumnLayout>
    );
}
