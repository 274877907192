import {
    ColumnLayout,
    DetailsSidebar,
    Hyperlink,
    Icon,
    IconName,
    List,
    StackLayout,
    Text,
} from '@embroker/ui-toolkit/v2';
import React from 'react';

export interface TechVerticalProductSelectionCardSlideoutProps {
    handleDismiss(): void;
    headerIcon: IconName;
    headerTitle: string;
    coverageFullTitle: string;
    coverage: string;
    description: string[];
    checkList: string[];
}

export const TechVerticalProductSelectionCardSlideout = ({
    handleDismiss,
    headerIcon,
    headerTitle,
    coverageFullTitle,
    coverage,
    description,
    checkList,
}: TechVerticalProductSelectionCardSlideoutProps) => {
    return (
        <DetailsSidebar>
            <DetailsSidebar.Header
                onDismiss={handleDismiss}
                icon={headerIcon}
                iconColor="primary-300"
            >
                <Text style="heading 2">{headerTitle}</Text>
            </DetailsSidebar.Header>
            <DetailsSidebar.Body>
                <StackLayout gap="32">
                    <StackLayout gap="12">
                        <Text style="heading 4" color="brand-400">
                            Why do you need {coverageFullTitle}?
                        </Text>
                        <StackLayout gap="24">
                            {description.map((desc) => (
                                <Text key={desc} style="body 1" color="ui-500">
                                    {desc}
                                </Text>
                            ))}
                        </StackLayout>
                    </StackLayout>
                    <StackLayout gap="12">
                        <Text style="overline" color="ui-500">
                            common {coverage} coverages INCLUDE:
                        </Text>
                        <List>
                            {checkList.map((item) => (
                                <List.Item key={item} icon="status-success">
                                    <Text style="body 1" as="div">
                                        {item}
                                    </Text>
                                </List.Item>
                            ))}
                        </List>
                    </StackLayout>
                    <Text style="heading 4" color="brand-400">
                        Why get it with Embroker?
                    </Text>
                    <StackLayout gap="24">
                        <ColumnLayout key="content" top>
                            <StackLayout center className="u-1/3">
                                <Icon name="star" color="primary-500" />
                                <Text textAlign="center">
                                    Excellent or Better Rating by AM Best
                                </Text>
                            </StackLayout>
                            <StackLayout center className="u-1/3">
                                <Icon name="chat" color="primary-500" />
                                <Text textAlign="center">Expert Insurance Support</Text>
                            </StackLayout>
                            <StackLayout center className="u-1/3">
                                <Icon name="certificate" color="primary-500" />
                                <Text textAlign="center">Online Certificate of Insurance</Text>
                            </StackLayout>
                        </ColumnLayout>
                        <Text style="body 1">
                            A.M. Best ratings are believed to be current, however they are subject
                            to change and subject to{' '}
                            <Hyperlink href="http://www.embroker.com/terms" target="_blank">
                                Embroker's terms
                            </Hyperlink>
                            <br />
                            The above summaries are not guarantees of coverage or a quote. Coverage
                            is subject to the terms, conditions and exclusions of issued policies
                            only.
                        </Text>
                    </StackLayout>
                </StackLayout>
            </DetailsSidebar.Body>
        </DetailsSidebar>
    );
};
