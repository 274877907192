import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Nullable } from '@embroker/shotwell/core/types';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ErrorCode, InvalidEmail, Unauthenticated } from '../errors';
import { UserRepository } from '../repositories/UserRepository';
import { PhoneNumber } from '@embroker/shotwell/core/types/PhoneNumber';

/**
 * Request data for UpdateUserProfile use case
 * @param firstName is the new first name for the user
 * @param lastName is the new last name for the user
 * @param title is the new title for the user
 */
export interface UpdateUserProfileRequest {
    firstName: string;
    lastName: string;
    title: string;
    phoneNumber: PhoneNumber;
    password?: Nullable<string>;
}

/**
 * UpdateUserProfile use case is used to update basic profile data for the user
 */

export interface UpdateUserProfile extends UseCase {
    execute(
        request: UpdateUserProfileRequest,
    ): AsyncResult<
        void,
        Unauthenticated | UnknownEntity | OperationFailed | InvalidArgument | InvalidEmail
    >;
}

@injectable()
export class UpdateUserProfileUseCase extends UseCase {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/UpdateUserProfile');
    /**
     * Constructor for UpdateOrganizationProfile use case class instance
     * @param eventBus An event bus this Use Case will publish events to.
     * @param userRepository is user repository used to store user entity with updated properties
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(UserRepository) private userRepository: UserRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes UpdateUserProfile use case
     * Input is of type UpdateUserProfileRequest
     * @returns Nothing if execution was successful
     * @returns InvalidArgument error if updated user entity contains invalid property
     * @returns OperationFailed error if repo failed to execute request
     */
    public async execute(
        data: UpdateUserProfileRequest,
    ): AsyncResult<
        void,
        Unauthenticated | UnknownEntity | OperationFailed | InvalidArgument | InvalidEmail
    > {
        const userResult = await this.userRepository.getActiveUser();

        if (isErr(userResult)) {
            return userResult;
        }
        data.password = data.password === undefined ? null : data.password;
        userResult.value.update(data);

        const result = await this.userRepository.save(userResult.value);
        if (isErr(result)) {
            for (const error of result.errors) {
                if (error.code === ErrorCode.InvalidEmail) {
                    return Failure(error);
                }
            }
            return handleOperationFailure(result);
        }

        this.eventBus.publishEntityEvents(userResult.value);
        return Success();
    }
}

export const UpdateUserProfile: UseCaseClass<UpdateUserProfile> = UpdateUserProfileUseCase;
