import { inject } from '@embroker/shotwell/core/di';
import { OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ApplicationRepository } from '../repositories/ApplicationRepository';
import { ApplicationNotFound } from '../errors';

interface RecreateRenewalRequest {
    applicationId: UUID;
}

interface RecreateRenewalResponse {
    applicationId: UUID;
}

export interface RecreateRenewal extends UseCase {
    execute(
        request: RecreateRenewalRequest,
    ): AsyncResult<RecreateRenewalResponse, ApplicationNotFound | OperationFailed>;
}

class RecreateRenewalUseCase extends UseCase implements RecreateRenewal {
    public static type = Symbol('Shopping/RecreateRenewal');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: RecreateRenewalRequest,
    ): AsyncResult<RecreateRenewalResponse, ApplicationNotFound | OperationFailed> {
        const response = await this.applicationRepository.recreateRenewal(request.applicationId);

        if (isErr(response)) {
            return response;
        }

        return Success({
            applicationId: response.value,
        });
    }
}

export const RecreateRenewal: UseCaseClass<RecreateRenewal> = RecreateRenewalUseCase;
