import {
    defineValidator,
    Joi,
    Schema,
    TypeChecker,
    Validator,
} from '@embroker/shotwell/core/validation/schema';

export interface CertificateCoverage {
    /**
     * Certificate coverage type
     */
    structuralComponentTypeCode: string;
}

export interface CertificateCoverageValidator {
    /**
     * A Joi schema matching a valid CertificateCoverage object.
     */
    readonly schema: Schema.ObjectSchema<CertificateCoverage>;
    /**
     * Type predicate that checks if a given value can be used as CertificateCoverage object.
     *
     * Use this only to do early returns. It's recommended to use validate()
     * before using an unknown value as CertificateCoverage object as it normalizes the value
     * in addition to performing the same validation as check().
     */
    readonly check: TypeChecker<CertificateCoverage>;
    /**
     * Validates and normalizes the given value to a CertificateCoverage object.
     *
     * This should be used for all untrusted inputs to verify and, if required
     * (and possible), normalize the input.
     */
    readonly validate: Validator<CertificateCoverage>;
}

export const CertificateCoverage = {
    ...defineValidator<CertificateCoverage>(
        Joi.object({
            structuralComponentTypeCode: Joi.string().required(),
        }),
    ),
    create(structuralComponentTypeCode: string) {
        return CertificateCoverage.validate({ structuralComponentTypeCode });
    },
};
