import {
    Modal,
    ModalActions,
    ModalState,
    Spinner,
    StackLayout,
    Text,
} from '@embroker/ui-toolkit/v2';
import React from 'react';

interface PrintApplicationInterface {
    modal: ModalState & ModalActions;
}
export function PrintApplicationModal({ modal }: PrintApplicationInterface) {
    return (
        <Modal {...modal} dismissable={false}>
            <StackLayout>
                <Text style="heading 4">Printing application</Text>
                <Text>
                    Do not close this window. The document will open in a new tab once it is
                    generated...
                </Text>

                <Spinner appearance="transparent" />
            </StackLayout>
        </Modal>
    );
}
