import {
    BoxLayout,
    CenterLayout,
    ColumnLayout,
    Image,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React from 'react';

export function PublicPaymentSubmitted() {
    return (
        <BoxLayout gap="64">
            <BoxLayout gap="64">
                <ColumnLayout center>
                    <CenterLayout>
                        <StackLayout gap="48">
                            <CenterLayout>
                                <Image name="shotwell-1" />
                            </CenterLayout>
                            <StackLayout gap="24">
                                <CenterLayout centerText>
                                    <Text style="heading 3" className="u-2/3">
                                        {' '}
                                        You've already submitted a payment for this invoice.
                                    </Text>
                                </CenterLayout>
                                <CenterLayout>
                                    <Text>
                                        Need help?{' '}
                                        <TextButton size="small" href="mailto:support@embroker.com">
                                            Get in touch.
                                        </TextButton>
                                    </Text>
                                </CenterLayout>
                            </StackLayout>
                        </StackLayout>
                    </CenterLayout>
                </ColumnLayout>
            </BoxLayout>
        </BoxLayout>
    );
}
