import {
    StackLayout,
    Text,
    ElementProps,
    TabNavigation,
    PageLayout,
} from '@embroker/ui-toolkit/v2';
import React, { PropsWithChildren } from 'react';
import { Link } from '../../../view/components';
import { ContactNavigation } from './ContactNavigation';

export function SupportPageLayout({ children }: PropsWithChildren<ElementProps<'div'>>) {
    return (
        <PageLayout.Section>
            <StackLayout gap="32">
                <Text style="heading 2">Support</Text>
                <TabNavigation>
                    <TabNavigation.Item as={Link} exact href="/support/contact">
                        Contact Us
                    </TabNavigation.Item>
                    <TabNavigation.Item as={Link} exact href="/support/faq">
                        FAQ
                    </TabNavigation.Item>
                </TabNavigation>
                <ContactNavigation />
            </StackLayout>
            {children}
        </PageLayout.Section>
    );
}
