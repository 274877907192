import {
    BoxLayout,
    CenterLayout,
    Image,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React from 'react';

export function PublicPaymentNotFound() {
    return (
        <StackLayout split="1">
            <BoxLayout gap="56">
                <StackLayout gap="32">
                    <CenterLayout>
                        <Image inline={false} name="shotwell-construction" />
                    </CenterLayout>
                    <CenterLayout centerText>
                        <StackLayout gap="32">
                            <Text as="h1" style="heading 1">
                                Payment not found
                            </Text>
                            <Text as="p" style="body 1">
                                Sorry, we are unable to locate the payment associated with this{' '}
                                <br />
                                link. Please reach out to our{' '}
                                <TextButton href="mailto:support@embroker.com">
                                    support team
                                </TextButton>
                                . We’re eager to sort this <br />
                                out for you.
                            </Text>
                        </StackLayout>
                    </CenterLayout>
                </StackLayout>
            </BoxLayout>
        </StackLayout>
    );
}
