import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ClaimRepository } from '../repositories/claims';
import { OperationFailed } from '@embroker/shotwell/core/Error';

export interface ClaimRequestDocument {
    name: string;
    file_key: string;
}

export interface CreateClaimRequestDetails {
    policyId: UUID;
    submitterName: string;
    contactPersonName: string;
    contactPersonEmail: string;
    contactPersonPhoneNumber: string;
    description: string;
    documentList: ClaimRequestDocument[];
}

export interface CreateClaimRequest extends UseCase {
    execute(request: CreateClaimRequestDetails): AsyncResult<UUID | OperationFailed>;
}

@injectable()
class ClaimRequestUseCase extends UseCase implements CreateClaimRequest {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Claims/CreateClaimRequest');

    /**
     * Constructor for CreateClaimRequest UseCase class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param claimRepository is repository used to store policy info
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ClaimRepository) private claimRepository: ClaimRepository,
    ) {
        super(eventBus);
    }

    public async execute(request: CreateClaimRequestDetails): AsyncResult<UUID | OperationFailed> {
        const result = await this.claimRepository.createClaimRequest(request);
        if (isErr(result)) {
            return result;
        }
        return Success(result.value);
    }
}

export const CreateClaimRequest: UseCaseClass<CreateClaimRequest> = ClaimRequestUseCase;
