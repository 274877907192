import { errorCodes } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { ErrorObject } from '@embroker/shotwell/core/types/Result';

export const ErrorCode = errorCodes({
    FailedToGetQuestionerForm: 0xb800,
    FailedToSaveQuestionerAnswers: 0xb8001,
    FailedToGetAnswerFromFormValues: 0xb8002,
    InvalidAnswerFromFormValue: 0xb8003,
});

export type FailedToGetQuestionerForm = ErrorObject<typeof ErrorCode.FailedToGetQuestionerForm>;
export type FailedToSaveQuestionerAnswers = ErrorObject<
    typeof ErrorCode.FailedToSaveQuestionerAnswers
>;
export type FailedToGetAnswerFromFormValues = ErrorObject<
    typeof ErrorCode.FailedToGetAnswerFromFormValues,
    {
        questionKey: string;
        value: unknown;
    }
>;
export type InvalidAnswerFromFormValue = ErrorObject<typeof ErrorCode.InvalidAnswerFromFormValue>;

export function FailedToGetQuestionerForm(): Immutable<FailedToGetQuestionerForm> {
    return {
        name: 'FailedToGetQuestionerForm',
        code: ErrorCode.FailedToGetQuestionerForm,
        message: 'Failed to get Questioner form.',
    };
}

export function FailedToSaveQuestionerAnswers(): Immutable<FailedToSaveQuestionerAnswers> {
    return {
        name: 'FailedToSaveQuestionerAnswers',
        code: ErrorCode.FailedToSaveQuestionerAnswers,
        message: 'Failed to save Questioner answers.',
    };
}

export function FailedToGetAnswerFromFormValues(props: {
    questionKey: string;
    value: unknown;
}): Immutable<FailedToGetAnswerFromFormValues> {
    return {
        name: 'FailedToGetAnswerFromQuestioner',
        code: ErrorCode.FailedToGetAnswerFromFormValues,
        message: 'Failed to get answer from form values.',
        details: props,
    };
}

export function InvalidAnswerFromFormValue(): Immutable<InvalidAnswerFromFormValue> {
    return {
        name: 'InvalidAnswerFromFormValue',
        code: ErrorCode.InvalidAnswerFromFormValue,
        message: 'Invalid answer from form value.',
    };
}
