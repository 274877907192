import { container } from '@embroker/shotwell/core/di';
import { OperationFailed } from '@embroker/shotwell/core/Error';
import { isDomainEventLocal } from '@embroker/shotwell/core/event/DomainEvent';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { useDomainEvent } from '@embroker/shotwell/view/hooks/useDomainEvent';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    BoxLayout,
    Button,
    ColumnLayout,
    Form,
    Image,
    Input,
    InputStatus,
    Modal,
    ModalActions,
    ModalState,
    ScrollBox,
    SelectChangeEvent,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useCurrentRoute } from 'react-navi';
import { AppContext } from '../../../view/AppContext';
import { navigateToErrorPage } from '../../../view/errors';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { OrganizationAdded, OrganizationSwitched } from '../../entities/Organization';
import { SessionOrganizationSelected } from '../../entities/Session';
import { AddNewOrganization } from '../../useCases/AddNewOrganization';
import {
    GetActiveUserProfile,
    GetActiveUserProfileResponse,
} from '../../useCases/GetActiveUserProfile';
import { SelectOrganization } from '../../useCases/SelectOrganization';

interface CompanySelectionModalProps {
    modal: ModalState & ModalActions;
}

export function CompanySelectionModal({ modal }: CompanySelectionModalProps) {
    const { activeSession } = useContext(AppContext);
    const [userProfile, setUserProfile] =
        useState<Nullable<Immutable<GetActiveUserProfileResponse>>>(null);
    const { organizationId } = activeSession;
    const { result: userProfileResult } = useUseCase(GetActiveUserProfile);

    useEffect(() => {
        if (userProfileResult != null && isOK(userProfileResult)) {
            setUserProfile(userProfileResult.value);
        }
    }, [userProfileResult]);

    useDomainEvent<SessionOrganizationSelected>(
        'Session',
        'OrganizationSelected',
        async (event) => {
            if (!isDomainEventLocal(event)) {
                return;
            }
            if (activeSession) {
                const result = await execute(GetActiveUserProfile);
                if (isOK(result)) {
                    setUserProfile(result.value);
                }
            }
        },
    );

    useDomainEvent('Organization', 'Created', async () => {
        if (activeSession) {
            const result = await execute(GetActiveUserProfile);
            if (isOK(result)) {
                setUserProfile(result.value);
            }
        }
    });

    if (userProfile === null) {
        return null;
    }

    return (
        <Modal {...modal} dismissable={organizationId !== null} size="medium">
            <ScrollBox>
                {modal.visible ? (
                    <UserCompanies
                        organizationId={organizationId}
                        organizations={userProfile.organizations.map(
                            ({ companyLegalName, id }) => ({
                                title: companyLegalName,
                                value: id,
                            }),
                        )}
                        modal={modal}
                    />
                ) : null}
            </ScrollBox>
        </Modal>
    );
}

interface OrganizationSelectionFormData {
    organizationId: UUID;
}

const organizationSelectionForm = createForm<OrganizationSelectionFormData>({
    fields: {
        organizationId: {
            type: 'text',
            validator: UUID.schema.required(),
            formatValidationError: (error) => {
                if (error.details.validator === 'any.required') {
                    return 'Please select a company';
                }
                return error.message;
            },
        },
    },
    submit: async (form: OrganizationSelectionFormData) => {
        return await execute(SelectOrganization, {
            organizationId: form.organizationId,
        });
    },
});

interface OrganizationItem {
    title: string;
    value: UUID;
}

interface UserCompaniesProps {
    readonly organizationId: Nullable<UUID>;
    readonly organizations: OrganizationItem[];
    readonly modal: ModalState & ModalActions;
}

function UserCompanies({ organizationId, organizations, modal }: UserCompaniesProps) {
    const navigation = useNavigation();
    const eventBus = useMemo(() => container.get<DomainEventBus>(DomainEventBus), []);
    const { activeSession } = useContext(AppContext);
    const [organizationList, setOrganizationList] = useState(organizations);
    const [showAddNewCompanyInput, setShowAddNewCompanyInput] = useState(false);
    const [addedCompanyMessage, setAddedCompanyMessage] = useState('');
    const route = useCurrentRoute();
    const numberOfCompaniesWithoutDropDownList = 5;
    const { status, fields, value, setValue, submit, messages } = useForm(
        organizationSelectionForm,
        {
            organizationId: organizationId === null ? undefined : organizationId,
        },
    );
    const handleAddCompany = useCallback(
        (organization: OrganizationItem) => {
            setOrganizationList(organizationList.concat(organization));
            setValue({ organizationId: organization.value });
            if (organizationList.length >= numberOfCompaniesWithoutDropDownList) {
                setAddedCompanyMessage(`"${organization.title}" added!`);
            } else {
                setAddedCompanyMessage('Company added!');
            }
        },
        [organizationList, setValue],
    );

    const publishOrganizationSwitchedEvent = useCallback(
        (organizationId: UUID) => {
            const event: OrganizationSwitched = {
                origin: 'Organization',
                name: 'Switched',
                createdAt: new Date(Date.now()),
                organizationId,
                id: UUID.create(),
            };
            eventBus.publish(event);
        },
        [eventBus],
    );

    const updateShowAddNewCompanyInput = (showAddNewCompanyInput: boolean) => {
        setShowAddNewCompanyInput(showAddNewCompanyInput);
    };
    const updateAddedCompanyMessage = (addedCompanyMessage: string) => {
        setAddedCompanyMessage(addedCompanyMessage);
    };
    const decodedRedirectTo = URI.decodeURIComponent(route.url.query.redirectTo);

    useEffect(() => {
        if (status === 'submitted' && modal.visible) {
            publishOrganizationSwitchedEvent(value.organizationId);
            modal.hide();
            if (typeof route.url.query.redirectTo === 'string' && !isErr(decodedRedirectTo)) {
                if (route.url.query.isDeepLink) {
                    // TODO rethink about this logic?
                    const parts = decodedRedirectTo.value.split('/').slice(1);
                    const uri = URI.build('/', ...parts);
                    navigation.navigate(uri);
                } else {
                    navigation.navigate(decodedRedirectTo.value);
                }
            } else {
                navigation.navigate('/summary');
            }
        }
    }, [
        navigation,
        status,
        value.organizationId,
        modal,
        route.url,
        publishOrganizationSwitchedEvent,
        decodedRedirectTo,
    ]);

    if (isErr(decodedRedirectTo)) {
        navigateToErrorPage(navigation.navigate, [
            OperationFailed({
                message: 'Failed to decode route url query',
            }),
        ]);
        return null;
    }

    const handleOrganizationSelect = (event: SelectChangeEvent<UUID, false>) => {
        if (event.target.value) {
            setValue({ organizationId: event.target.value });
        }
    };

    return (
        <React.Fragment>
            <BoxLayout>
                <StackLayout gap="24">
                    <Image name="logotype" width={150} />
                    <Text data-e2e="user-org-select-header" style="heading 3">
                        Choose a company
                    </Text>
                    {organizationList.length > numberOfCompaniesWithoutDropDownList ? (
                        <StackLayout gap="12">
                            <Input.Select
                                data-e2e="user-org-selection-org-list-dropdown-select"
                                items={organizationList}
                                onChange={handleOrganizationSelect}
                                value={value.organizationId || ''}
                            />
                            <Text color="positive-500"> {addedCompanyMessage}</Text>
                        </StackLayout>
                    ) : (
                        <StackLayout gap="12">
                            <Input.RadioGroup
                                data-e2e="user-org-selection-org-list-radio-select"
                                appearance="selector"
                                items={organizationList}
                                {...fields.organizationId.props}
                            />
                            <Text color="positive-500"> {addedCompanyMessage}</Text>
                        </StackLayout>
                    )}
                </StackLayout>
                <InputStatus
                    data-e2e="user-org-selection-alert-msg"
                    messages={fields.organizationId.messages}
                    role="alert"
                />
            </BoxLayout>
            <BoxLayout>
                <StackLayout gap="24">
                    <CompanyAddingForm
                        onAddCompany={handleAddCompany}
                        updateShowAddNewCompanyInput={updateShowAddNewCompanyInput}
                        showAddNewCompanyInput={showAddNewCompanyInput}
                        updateAddedCompanyMessage={updateAddedCompanyMessage}
                    />
                    {!showAddNewCompanyInput ? (
                        <StackLayout>
                            <Button
                                data-e2e="user-org-selection-goto-switch-company-btn"
                                onClick={submit}
                            >
                                {activeSession.organizationId === null
                                    ? 'Go to this company'
                                    : 'Switch company'}
                            </Button>
                            {messages.map((message) => (
                                <Text color="negative-500" key={message}>
                                    {message}
                                </Text>
                            ))}
                        </StackLayout>
                    ) : null}
                </StackLayout>
            </BoxLayout>
        </React.Fragment>
    );
}

interface AddCompanyFormData {
    companyLegalName: string;
}

const addCompanyForm = createForm<AddCompanyFormData>({
    useCase: AddNewOrganization,
    formatSubmitErrors(errors) {
        // TODO: inspect errors and return a more informational message.
        return ['Sorry, something went wrong. Please try again.'];
    },
    fields: {
        companyLegalName: {
            type: 'text',
            validator: Joi.string().required().trim().min(1),
            formatValidationError(error) {
                if (
                    error.details.validator === 'any.required' ||
                    error.details.validator === 'string.empty'
                ) {
                    return 'You must provide company name';
                }
                return error.message;
            },
        },
    },
});

interface CompanyAddingFormProps {
    onAddCompany: (data: { value: UUID; title: string }) => void;
    updateShowAddNewCompanyInput: (showAddNewCompanyInput: boolean) => void;
    showAddNewCompanyInput: boolean;
    updateAddedCompanyMessage: (addedCompanyMessage: string) => void;
}

function CompanyAddingForm({
    onAddCompany,
    updateShowAddNewCompanyInput,
    showAddNewCompanyInput,
    updateAddedCompanyMessage,
}: CompanyAddingFormProps) {
    const { submit, fields, status, result, setValue } = useForm(addCompanyForm);
    const eventBus = useMemo(() => container.get<DomainEventBus>(DomainEventBus), []);
    const publishOrganizationAddedEvent = useCallback(
        (organizationId: UUID) => {
            const event: OrganizationAdded = {
                origin: 'Organization',
                name: 'Added',
                createdAt: new Date(Date.now()),
                organizationId,
                id: UUID.create(),
            };
            eventBus.publish(event);
        },
        [eventBus],
    );

    useEffect(() => {
        if (status === 'submitted' && typeof result !== 'undefined') {
            setValue({ companyLegalName: '' });
            updateShowAddNewCompanyInput(false);
            onAddCompany({
                value: result.organizationId,
                title: result.companyLegalName,
            });
            publishOrganizationAddedEvent(result.organizationId);
        }
    }, [
        status,
        result,
        onAddCompany,
        setValue,
        updateShowAddNewCompanyInput,
        publishOrganizationAddedEvent,
    ]);

    const displayInputField = (event: React.MouseEvent) => {
        event.preventDefault();
        updateShowAddNewCompanyInput(true);
        updateAddedCompanyMessage('');
    };

    const hideInputField = (event: React.MouseEvent) => {
        event.preventDefault();
        updateShowAddNewCompanyInput(false);
    };

    return (
        <Form noValidate onSubmit={submit}>
            {showAddNewCompanyInput ? (
                <StackLayout gap="24">
                    <Form.Field
                        data-e2e="user-org-selection-new-company-name-input"
                        inputProps={{
                            ...fields.companyLegalName.props,
                        }}
                        label="New company name"
                        messages={fields.companyLegalName.messages}
                        type={fields.companyLegalName.type}
                    />
                    <ColumnLayout>
                        <Button data-e2e="user-org-selection-add-company-btn" type="submit">
                            Add Company
                        </Button>
                        <TextButton
                            data-e2e="user-org-selection-cancel-btn"
                            onClick={hideInputField}
                        >
                            Cancel
                        </TextButton>
                    </ColumnLayout>
                </StackLayout>
            ) : (
                <TextButton
                    data-e2e="user-org-selection-add-another-btn"
                    onClick={displayInputField}
                >
                    + Add another company
                </TextButton>
            )}
        </Form>
    );
}
