import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable, Props } from '@embroker/shotwell/core/types';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase } from '@embroker/shotwell/core/UseCase';
import { PolicyClaimContactInfo } from '../types/PolicyClaimContactInfo';
import { PolicyRepository } from '../../policy/repositories/PolicyRepository';

export interface FileClaimResponse {
    claimContactInfoList: Immutable<Props<PolicyClaimContactInfo>[]>;
}

@injectable()
export class FileClaim extends UseCase {
    public static type = Symbol('Claim/FileClaim');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(PolicyRepository) private policyRepository: PolicyRepository,
    ) {
        super(eventBus);
    }
    async execute(): AsyncResult<FileClaimResponse, InvalidArgument | OperationFailed> {
        const getPoliciesResult = await this.policyRepository.getPolicies();
        if (isErr(getPoliciesResult)) {
            return getPoliciesResult;
        }

        const activePolicies = getPoliciesResult.value.filter(
            (policy) =>
                policy.borStatus == 'b_o_r' &&
                policy.status === 'Active' &&
                policy.viewMode === 'PolicyViewStatusCodeListPublished',
        );

        const policyClaimContactInfoList: PolicyClaimContactInfo[] = [];
        for (const policy of activePolicies) {
            const policyClaimContactInfo = PolicyClaimContactInfo.create({
                policyId: policy.id,
                policyDisplayName: policy.name,
                policyNumber: policy.policyNumber,
                policyStartDate: policy.startDate,
                insurerName: policy.insurerName,
                applicationId: policy.insuranceApplicationId || undefined,
                lineOfBusiness: policy.lineOfBusiness,
                lineOfBusinessSubtype: policy.subLineOfBusiness,
                claimWebsite: policy.claimWebsite,
                claimEmail: policy.claimEmail,
                claimPhoneNumber: policy.claimPhoneNumber,
                claimPhoneExtension: policy.claimPhoneExtension,
            });
            if (isErr(policyClaimContactInfo)) {
                return policyClaimContactInfo;
            }
            policyClaimContactInfoList.push(policyClaimContactInfo.value);
        }

        const response: FileClaimResponse = {
            claimContactInfoList: policyClaimContactInfoList,
        };

        return Success(response);
    }
}
