import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ExcessRestrictionRepository } from '../repositories/EmbrokerExcessRestrictionRepository';
import { InsuranceApplicationRestriction } from '../types/InsuranceApplicationRestriction';

export interface GetRestrictionRequest {
    readonly insuranceApplicationId: UUID;
}

export interface GetRestriction extends UseCase {
    execute(
        request: GetRestrictionRequest,
    ): AsyncResult<
        InsuranceApplicationRestriction,
        InvalidArgument | OperationFailed | UnknownEntity
    >;
}

@injectable()
export class GetRestrictionUseCase extends UseCase implements GetRestriction {
    public static type = Symbol('ExcessRestriction/GetRestriction');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ExcessRestrictionRepository)
        private embrokerExcessRestrictionRepository: ExcessRestrictionRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: GetRestrictionRequest,
    ): AsyncResult<
        InsuranceApplicationRestriction,
        InvalidArgument | OperationFailed | UnknownEntity
    > {
        const result = await this.embrokerExcessRestrictionRepository.get(
            request.insuranceApplicationId,
        );

        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        if (!result.value) {
            return Failure(OperationFailed({ message: 'Invalid data, no excess review' }));
        }

        return Success(result.value);
    }
}

export const GetRestriction: UseCaseClass<GetRestriction> = GetRestrictionUseCase;
