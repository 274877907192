import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { GetSelfServingCoverageListFailed } from '../errors';
import { CertificateRepository } from '../repositories/CertificateRepository';
import { SelfServingCertificateCoverageList } from '../types/SelfServingCertificateCoverage';

/**
 * Response data for GetShareableCertificateData use case
 *
 * @property selfServingCoverageList is the list of available certificate templates for the organization
 */
export interface GetShareableSelfServingCoverageListResponse {
    selfServingCoverageList: Immutable<SelfServingCertificateCoverageList>;
}

/**
 * GetShareableSelfServingCoverageListData use case is used to fetch all potentially shareable certificate data for the organization
 */
export interface GetShareableSelfServingCoverageList extends UseCase {
    execute(): AsyncResult<
        GetShareableSelfServingCoverageListResponse,
        InvalidArgument | GetSelfServingCoverageListFailed
    >;
}

@injectable()
class GetShareableSelfServingCoverageListUseCase
    extends UseCase
    implements GetShareableSelfServingCoverageList
{
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Certificates/GetShareableSelfServingCoverageListData');

    /**
     * Constructor for GetShareableSelfServingCoverageList class instance
     * @param eventBus An event bus this Use Case will publish events to.
     * @param certificateRepo repo that will be used to check for shareable certificate data
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(CertificateRepository) private certificateRepo: CertificateRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes the GetShareableSelfServingCoverageList use case.
     * @returns GetShareableSelfServingCoverageListResponse if execution was successful
     * @returns InvalidArgument or OperationFailed if callout to repo fails for any reason.
     */
    public async execute(): AsyncResult<
        GetShareableSelfServingCoverageListResponse,
        InvalidArgument | GetSelfServingCoverageListFailed
    > {
        const selfServingCoverageList = await this.certificateRepo.getSelfServingCoverageList();

        if (isErr(selfServingCoverageList)) {
            return selfServingCoverageList;
        }

        const result: GetShareableSelfServingCoverageListResponse = {
            selfServingCoverageList: selfServingCoverageList.value.selfServingCoverageList,
        };

        return Success(result);
    }
}

export const GetShareableSelfServingCoverageList: UseCaseClass<GetShareableSelfServingCoverageList> =
    GetShareableSelfServingCoverageListUseCase;
