import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface IneligibilityReasons {
    readonly referralReasons: string[];
    readonly declinedReasons: string[];
    readonly investigationNeededReasons: string[];
}

export const IneligibilityReasons = {
    ...defineValidator<IneligibilityReasons>({
        referralReasons: Joi.array().items(Joi.string()).required(),
        declinedReasons: Joi.array().items(Joi.string()).required(),
        investigationNeededReasons: Joi.array().items(Joi.string()).required(),
    }),
    create(ineligibilityReasons: IneligibilityReasons) {
        return IneligibilityReasons.validate(ineligibilityReasons);
    },
};
