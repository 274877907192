import React from 'react';
import {
    Modal,
    ModalActions,
    ModalState,
    StackLayout,
    Text,
    ScrollBox,
} from '@embroker/ui-toolkit/v2';

interface ClaimIntakeModalInput {
    modal: ModalState & ModalActions;
}

export function ClaimIntakeModal({ modal }: ClaimIntakeModalInput) {
    return (
        <Modal {...modal} size="medium">
            <ScrollBox>
                <StackLayout gap="32">
                    <Text style="heading 4">Fraud Warnings - Claims</Text>
                    <Text>
                        <b>
                            General Fraud Warning applicable in all states except those specifically
                            noted below, and applicable in Alabama, Alaska, Maryland, Minnesota, New
                            Jersey and Texas:
                        </b>{' '}
                        Any person who knowingly presents a false or fraudulent a loss or benefit or
                        knowingly presents false information in an application for insurance is
                        guilty of a crime and may be subject to prosecution under state law,
                        restitution, fines, civil and/or criminal penalties, confinement in prison
                        or any combination thereof.
                    </Text>
                    <Text>
                        <b>Applicable in Arizona:</b> For your protection Arizona law requires the
                        following statement to appear on this form. Any person who knowingly
                        presents a false or fraudulent claim for payment of a loss is subject to
                        criminal and civil penalties.
                    </Text>
                    <Text>
                        <b>
                            Applicable in Arkansas, Louisiana, Michigan, Rhode Island and West
                            Virginia:{' '}
                        </b>
                        Any person who knowingly presents a false or fraudulent claim for payment of
                        a loss or benefit or knowingly presents false information in an application
                        for insurance is guilty of a crime and may be subject to fines and
                        confinement in prison.
                    </Text>
                    <Text>
                        <b>Applicable in California:</b> For your protection California law requires
                        the following to appear on this form. Any person who knowingly presents a
                        false or fraudulent claim for the payment of a loss is guilty of a crime and
                        may be subject to fines and confinement in state prison.
                    </Text>
                    <Text>
                        <b>Applicable in Colorado:</b> It is unlawful to knowingly provide false,
                        incomplete, or misleading facts or information to an insurance company for
                        the purpose of defrauding or attempting to defraud the company. Penalties
                        may include imprisonment, fines, denial of insurance and civil damages. Any
                        insurance company or agent of an insurance company who knowingly provides
                        false, incomplete, or misleading facts or information to a policyholder or
                        claimant for the purpose of defrauding or attempting to defraud the
                        policyholder or claimant with regard to a settlement or award payable from
                        insurance proceeds shall be reported to the Colorado Division of Insurance
                        within the Department of Regulatory Agencies.
                    </Text>
                    <Text>
                        <b>Applicable in Delaware, Indiana and Idaho:</b> Any person who knowingly,
                        and with intent to injure, defraud or deceive any insurer, files a statement
                        of claim containing any false, incomplete or misleading information is
                        guilty of a felony.
                    </Text>
                    <Text>
                        <b>Applicable in the District of Columbia:</b> WARNING: It is a crime to
                        provide false or misleading information to an insurer for the purpose of
                        defrauding the insurer or any other person. Penalties include imprisonment
                        and/or fines. In addition, an insurer may deny insurance benefits if false
                        information materially related to a claim was provided by the applicant.
                    </Text>
                    <Text>
                        <b>Applicable in Florida:</b> Any person who knowingly and with intent to
                        injure, defraud, or deceive any insurer files a statement of claim
                        containing any false, incomplete, or misleading information is guilty of a
                        felony of the third degree.
                    </Text>
                    <Text>
                        <b>Applicable in Kansas:</b> Any person who, knowingly and with intent to
                        defraud, presents, causes to be presented or prepares with knowledge or
                        belief that it will be presented to or by an insurer, purported insurer,
                        broker or any agent thereof, any written, electronic, electronic impulse,
                        facsimile, magnetic, oral, or telephonic communication or statement as part
                        of, or in support of, an application for the issuance of, or the rating of
                        an insurance policy for personal or commercial insurance, or a claim for
                        payment or other benefit pursuant to an insurance policy for commercial or
                        personal insurance which such person knows to contain materially false
                        information concerning any fact material thereto; or conceals, for the
                        purpose of misleading, information concerning any fact material thereto
                        commits a fraudulent insurance act. Workers Compensation: Warning:
                        Acceptance of employment with a different employer that requires the
                        performance of activities you have stated you cannot perform because of the
                        injury for which you are receiving temporary disability benefits could
                        constitute fraud and could result in loss of future benefits and restitution
                        of prior workers compensation awards and benefits paid.
                    </Text>
                    <Text>
                        <b>Applicable in Kentucky:</b> Any person who knowingly and with intent to
                        defraud any insurance company or other person files a statement of claim
                        containing any materially false information or conceals, for the purpose of
                        misleading, information concerning any fact material thereto commits a
                        fraudulent insurance act, which is a crime.
                    </Text>
                    <Text>
                        <b>Applicable in Maine, Tennessee, Virginia and Washington:</b> It is a
                        crime to knowingly provide false, incomplete or misleading information to an
                        insurance company for the purpose of defrauding the company. Penalties may
                        include imprisonment, fines or denial of insurance benefits.
                    </Text>
                    <Text>
                        <b>Applicable in Massachusetts and Nebraska:</b> Any person who knowingly
                        and with intent to defraud any insurance company or another person files an
                        application of insurance or statement of claim containing any materially
                        false information, or conceals for the purpose of misleading information
                        concerning any material fact there to, may be committing a fraudulent
                        insurance act, which may be a crime and may subject the person to criminal
                        and civil penalties.
                    </Text>
                    <Text>
                        <b>Applicable in Nevada:</b> Pursuant to NRS 686A.291, any person who
                        knowingly and willfully files a statement of claim that contains any false,
                        incomplete or misleading information concerning a material fact is guilty of
                        a category D felony.
                    </Text>
                    <Text>
                        <b>Applicable in New Hampshire:</b> Any person who, with a purpose to
                        injure, defraud or deceive any insurance company, files a statement of claim
                        containing any false, incomplete or misleading information is subject to
                        prosecution and punishment for insurance fraud as provided in RSA 638:20.
                    </Text>
                    <Text>
                        <b>Applicable in New Mexico:</b> Any person who knowingly presents a false
                        or fraudulent claim for payment of a loss or benefit or knowingly presents
                        false information in an application for insurance is guilty of a crime and
                        may be subject to civil fines and criminal penalties.
                    </Text>
                    <Text>
                        <b>Applicable in New York:</b> Any person who knowingly and with intent to
                        defraud any insurance company or other person files an application for
                        insurance or statement of claim containing any materially false information,
                        or conceals for the purpose of misleading, information concerning any fact
                        material thereto, commits a fraudulent insurance act, which is a crime, and
                        shall also be subject to a civil penalty not to exceed five thousand dollars
                        and the stated value of the claim for each such violation.
                    </Text>
                    <Text>
                        <b>Applicable in Ohio:</b> Any person who, with intent to defraud or knowing
                        that he is facilitating a fraud against an insurer, submits an application
                        or files a claim containing a false or deceptive statement is guilty of
                        insurance fraud.
                    </Text>
                    <Text>
                        <b>Applicable in Oklahoma:</b> WARNING: Any person who knowingly, and with
                        intent to injure, defraud or deceive any insurer, makes any claim for the
                        proceeds of an insurance policy containing any false, incomplete or
                        misleading information is guilty of a felony.
                    </Text>
                    <Text>
                        <b>Applicable in Oregon:</b> Any person who knowingly and with intent to
                        defraud or solicit another to defraud the insurer by submitting an
                        application containing a false statement as to any material fact may be
                        violating state violating state law.
                    </Text>
                    <Text>
                        <b>Applicable in Pennsylvania:</b> Any person who knowingly and with intent
                        to defraud any insurance company or other person files an application for
                        insurance or statement of claim containing any materially false information
                        or conceals for the purpose of misleading, information concerning any fact
                        material thereto commits a fraudulent insurance act, which is a crime and
                        subjects such person to criminal and civil penalties.
                    </Text>
                </StackLayout>
            </ScrollBox>
        </Modal>
    );
}
