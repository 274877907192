import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface NotificationStats {
    countUnread: number;
}

export const NotificationStats = {
    ...defineValidator<NotificationStats>(
        Joi.object({
            countUnread: Joi.number(),
        }),
    ),
    create(cta: NotificationStats) {
        return NotificationStats.validate(cta);
    },
};
