import { Immutable } from '@embroker/shotwell/core/types';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { InputStatus, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { WizardForm } from '../../../../../view/hooks/useWizardForm';
import { ESPCoverageRateItem } from '../../../types/ESPRate';
import { ESPQuoteOptionsFormData } from '../ESPQuoteLandingPage';
import { ESPRecommendedCoverage } from './ESPRecommendedCoverage';

interface ESPRecommendedCoverageListProps {
    recommendedCoverages: Immutable<Array<ESPCoverageRateItem>>;
    isSubmitting: boolean;
    revenue: number;
    newInsurerDocumentsReleaseDate?: Date;
    fiduciaryDocumentsReleaseDate?: Date;
    submittedAt?: Date;
    fields: WizardForm<OpaqueForm<ESPQuoteOptionsFormData>>['fields'];
    isQuoteReferred: boolean;
}

export const ESPRecommendedCoverageList = ({
    recommendedCoverages,
    isSubmitting,
    revenue,
    fields,
    newInsurerDocumentsReleaseDate,
    fiduciaryDocumentsReleaseDate,
    submittedAt,
    isQuoteReferred,
}: ESPRecommendedCoverageListProps) => {
    if (recommendedCoverages.length == 0) {
        return null;
    }
    return (
        <StackLayout>
            <Text style="heading 4" data-e2e="recommended-coverages">
                Recommended Coverages
            </Text>
            <InputStatus messages={fields.coverage.messages} role="alert" />
            {recommendedCoverages.map((coverage) => {
                return (
                    <ESPRecommendedCoverage
                        key={coverage.coverageType}
                        revenue={revenue}
                        coverage={coverage}
                        isSubmitting={isSubmitting}
                        fields={fields}
                        newInsurerDocumentsReleaseDate={newInsurerDocumentsReleaseDate}
                        fiduciaryDocumentsReleaseDate={fiduciaryDocumentsReleaseDate}
                        submittedAt={submittedAt}
                        isQuoteReferred={isQuoteReferred}
                    />
                );
            })}
        </StackLayout>
    );
};
