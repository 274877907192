import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import {
    BillingInfoGetInput,
    BillingInfoRepository,
} from '@app/payments/repositories/BillingInfoRepository';
import { BillingInfo } from '@app/payments/entities/BillingInfo';

/**
 * Response data for GetPremiumFinanceQuote use case
 * @property OrganizationId is the id of the new organization
 */
export type GetBillingInfoResponse = BillingInfo;

export interface GetBillingInfo extends UseCase {
    execute(
        request: BillingInfoGetInput,
    ): AsyncResult<GetBillingInfoResponse, InvalidArgument | OperationFailed>;
}

/**
 * GetBillingInfoUseCase use case is used to get billing info on the platform
 */
@injectable()
class GetBillingInfoUseCase extends UseCase implements GetBillingInfo {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Payments/GetBillingInfo');

    /**
     * Constructor for GetBillingInfo class instance
     * @param billingInfoRepository is the billing info repo which will be used to obtain billing info
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BillingInfoRepository) private billingInfoRepository: BillingInfoRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes the GetPremiumFinanceQuote use case.
     * @param request is representation of the new organization
     * @returns GetPremiumFinanceQuoteResponse if execution was successful
     * @returns InvalidArgument if one of properties in GetPremiumFinanceQuoteRequest was not valid
     */
    public async execute(
        request: BillingInfoGetInput,
    ): AsyncResult<GetBillingInfoResponse, OperationFailed | InvalidArgument> {
        const result = await this.billingInfoRepository.getBillingInfo({
            organizationId: request.organizationId,
        });
        if (isErr(result)) {
            return result;
        }
        return Success<GetBillingInfoResponse>({
            id: result.value.id,
            organizationId: result.value.organizationId,
            billingEmailAddress: result.value.billingEmailAddress,
        });
    }
}

export const GetBillingInfo: UseCaseClass<GetBillingInfo> = GetBillingInfoUseCase;
