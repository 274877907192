import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { deepClone } from '@embroker/shotwell/core/object';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { Location } from '@embroker/shotwell/core/types/Location';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { State } from '@embroker/shotwell/core/types/StateList';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { SKU } from '../../analytics/types/SKU';
import { CalculateSKUFromShareablePolicies } from '../../analytics/useCases/CalculateSKUFromShareablePolicies';
import { CreateSelfServingCertificateFailed } from '../errors';
import { CertificateRepository } from '../repositories/CertificateRepository';
import { CertificateEntityRole } from '../types/CertificateEntityRole';
import { SelfServingCertificateCoverageList } from '../types/SelfServingCertificateCoverage';

export interface OwnSelfServingCertificateCreated extends DomainEvent {
    origin: 'Certificates';
    name: 'OwnSelfServingCertificateCreated';
    certificateId: UUID;
}

export interface ThirdPartySelfServingCertificateCreated extends DomainEvent {
    origin: 'Certificates';
    name: 'ThirdPartySelfServingCertificateCreated';
    certificateId: UUID;
    holderEmailProvided?: EmailAddress;
    includesProvisionCustomization?: boolean;
    isAdditionalInsuredDisabled?: boolean;
    isWaiverOfSubrogationDisabled?: boolean;
    isPrimaryNonContributoryDisabled?: boolean;
    includesPolicyCustomization?: boolean;
    sku?: SKU;
}

export type CertificateIssueSource =
    | 'Own'
    | '3rd Party Complete'
    | '3rd Party Custom'
    | '3rd Party Custom With Flags';

/**
 * Response data for CreateSelfServingCertificate use case
 *
 * @property certificateId identifier of the self-serving certificate
 */
export interface CreateSelfServingCertificateResponse {
    certificateId: UUID;
}

/**
 * Request data for CreateSelfServingCertificate use case
 *
 * @param selfServingCoverageList list of shareable coverages
 * @param certificateHolder certificate holder to be displayed in the certificate document
 * @param referenceNumber is a reference number value to be printed on certificate pdf
 */
export interface CreateSelfServingCertificateRequest {
    selfServingCoverageList: SelfServingCertificateCoverageList;
    companyName?: string;
    email?: EmailAddress;
    mailingAddress?: string;
    mailingAddressCont?: string;
    city?: string;
    state?: State;
    zipCode?: ZipCode;
    referenceNumber?: string;
    isAdditionalInsuredDisabled?: boolean;
    isWaiverOfSubrogationDisabled?: boolean;
    isPrimaryNonContributoryDisabled?: boolean;
    certificateIssueSource: CertificateIssueSource;
}

/**
 * CreateSelfServingCertificate use case is used to create self-serving certificate
 */
export interface CreateSelfServingCertificate extends UseCase {
    execute(
        request: CreateSelfServingCertificateRequest,
    ): AsyncResult<
        CreateSelfServingCertificateResponse,
        InvalidArgument | CreateSelfServingCertificateFailed
    >;
}

@injectable()
class CreateSelfServingCertificateUseCase extends UseCase implements CreateSelfServingCertificate {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Certificates/CreateSelfServingCertificate');

    /**
     * Constructor for CreateSelfServingCertificate class instance
     * @param eventBus An event bus this Use Case will publish events to.
     * @param certificateRepo repo that will be used to check for shareable certificate data
     * @param calculateSKUFromShareablePolicies useCase that will return calculated skus data for
     * published certificate share events
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(CertificateRepository) private certificateRepo: CertificateRepository,
        @inject(CalculateSKUFromShareablePolicies.type)
        private calculateSKUFromShareablePolicies: CalculateSKUFromShareablePolicies,
    ) {
        super(eventBus);
    }

    /**
     * Executes the CreateSelfServingCertificate use case.
     * @returns GCreateSelfServingCertificateResponse if execution was successful
     * @returns InvalidArgument or OperationFailed if callout to repo fails for any reason.
     */
    public async execute(
        request: CreateSelfServingCertificateRequest,
    ): AsyncResult<
        CreateSelfServingCertificateResponse,
        InvalidArgument | CreateSelfServingCertificateFailed
    > {
        const certificateHolder =
            request.companyName &&
            request.city &&
            request.mailingAddress &&
            request.state &&
            request.zipCode
                ? ({
                      roleType: 'Holder',
                      organizationName: request.companyName,
                      organizationEmail: request.email,
                      userEmail: request.email,
                      location: {
                          type: 'other',
                          addressLine1: request.mailingAddress,
                          addressLine2: request.mailingAddressCont ?? null,
                          city: request.city,
                          state: request.state,
                          zip: request.zipCode,
                          phoneNumber: null,
                      } as Location,
                  } as CertificateEntityRole)
                : undefined;

        const selfServingCoverageList = deepClone(request.selfServingCoverageList);

        const skuResult = await this.calculateSKUFromShareablePolicies.execute(
            selfServingCoverageList,
        );

        for (const coverage of selfServingCoverageList) {
            if (request.isWaiverOfSubrogationDisabled) {
                coverage.coverageInfo.waiverOfSubrogation = false;
            }
            if (request.isAdditionalInsuredDisabled) {
                coverage.coverageInfo.blanketAdditionalInsured = false;
            }
            if (request.isPrimaryNonContributoryDisabled) {
                coverage.coverageInfo.primaryNonContributory = false;
            }
        }

        const certificateId = await this.certificateRepo.createSelfServingCertificate({
            certificateHolder: certificateHolder,
            referenceNumber: request.referenceNumber,
            selfServingCoverageList: selfServingCoverageList,
        });

        if (isErr(certificateId)) {
            return certificateId;
        }

        const result: CreateSelfServingCertificateResponse = {
            certificateId: certificateId.value.certificateId,
        };

        if (request.certificateIssueSource === 'Own') {
            const selfServingCertificateCreated: OwnSelfServingCertificateCreated = {
                id: certificateId.value.certificateId,
                origin: 'Certificates',
                name: 'OwnSelfServingCertificateCreated',
                createdAt: new Date(Date.now()),
                certificateId: certificateId.value.certificateId,
            };
            await this.eventBus.publish(selfServingCertificateCreated);
        } else if (request.certificateIssueSource === '3rd Party Complete') {
            const selfServingCertificateCreated: ThirdPartySelfServingCertificateCreated = {
                id: certificateId.value.certificateId,
                origin: 'Certificates',
                name: 'ThirdPartySelfServingCertificateCreated',
                createdAt: new Date(Date.now()),
                certificateId: certificateId.value.certificateId,
                holderEmailProvided: request.email,
                includesProvisionCustomization: false,
                includesPolicyCustomization: false,
                sku: skuResult.value,
            };
            await this.eventBus.publish(selfServingCertificateCreated);
        } else if (request.certificateIssueSource === '3rd Party Custom') {
            const selfServingCertificateCreated: ThirdPartySelfServingCertificateCreated = {
                id: certificateId.value.certificateId,
                origin: 'Certificates',
                name: 'ThirdPartySelfServingCertificateCreated',
                createdAt: new Date(Date.now()),
                certificateId: certificateId.value.certificateId,
                holderEmailProvided: request.email,
                includesProvisionCustomization: false,
                includesPolicyCustomization: true,
                sku: skuResult.value,
            };
            await this.eventBus.publish(selfServingCertificateCreated);
        } else {
            const selfServingCertificateCreated: ThirdPartySelfServingCertificateCreated = {
                id: certificateId.value.certificateId,
                origin: 'Certificates',
                name: 'ThirdPartySelfServingCertificateCreated',
                createdAt: new Date(Date.now()),
                certificateId: certificateId.value.certificateId,
                holderEmailProvided: request.email,
                includesProvisionCustomization: true,
                includesPolicyCustomization: true,
                isAdditionalInsuredDisabled: request.isAdditionalInsuredDisabled,
                isWaiverOfSubrogationDisabled: request.isWaiverOfSubrogationDisabled,
                isPrimaryNonContributoryDisabled: request.isPrimaryNonContributoryDisabled,
                sku: skuResult.value,
            };
            await this.eventBus.publish(selfServingCertificateCreated);
        }

        return Success(result);
    }
}

export const CreateSelfServingCertificate: UseCaseClass<CreateSelfServingCertificate> =
    CreateSelfServingCertificateUseCase;
