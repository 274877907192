import { Success } from '@embroker/shotwell/core/types/Result';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    Button,
    ButtonBar,
    Form,
    FormLayout,
    SidebarLayout,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React, { useMemo } from 'react';
import { BusinessInfoStepGuidelines } from './BusinessInfoStepGuidelines';

export interface BusinessNameStepData {
    businessName: string;
}

function createBusinessNameStepForm(onNext: (data: BusinessNameStepData) => void) {
    return createForm<BusinessNameStepData>({
        fields: {
            businessName: {
                type: 'text',
                validator: Joi.string().max(180).required().trim().min(1),
                formatValidationError(error) {
                    switch (error.details.validator) {
                        case 'string.max':
                            return 'Certificate holder name must be under 180 characters.';
                        default:
                            return 'Please provide the business name.';
                    }
                },
            },
        },
        async submit(data) {
            onNext(data);
            return Success();
        },
    });
}

interface BusinessNameStepProps {
    initialData?: Partial<BusinessNameStepData>;
    onNext(data: BusinessNameStepData): void;
    onBack(): void;
}

export function BusinessNameStep({ initialData, onNext, onBack }: BusinessNameStepProps) {
    const businessNameStepForm = useMemo(() => createBusinessNameStepForm(onNext), [onNext]);
    const { fields, submit } = useForm(businessNameStepForm, initialData);

    return (
        <SidebarLayout appearance="default" sidebarVariant="compact">
            <BusinessInfoStepGuidelines></BusinessInfoStepGuidelines>
            <StackLayout>
                <FormLayout appearance="wide">
                    <StackLayout gap="48">
                        <StackLayout gap="24">
                            <Text style={'heading 5'} data-e2e="business-name-questionnaire">
                                Please provide details for the business entity requiring the
                                certificate:
                            </Text>
                            <StackLayout>
                                <Form.Field
                                    inputProps={{
                                        ...fields.businessName.props,
                                    }}
                                    label="Business Name"
                                    messages={fields.businessName.messages}
                                    type={fields.businessName.type}
                                    data-e2e="business-name-input"
                                />
                            </StackLayout>
                        </StackLayout>
                        <ButtonBar
                            split={'-1'}
                            reverse
                            responsive={{ containerWidth: { smallerThan: 400 } }}
                        >
                            <TextButton onClick={onBack} data-e2e="business-name-back">
                                Back
                            </TextButton>
                            <Button onClick={submit} data-e2e="business-name-submit">
                                Next
                            </Button>
                        </ButtonBar>
                    </StackLayout>
                </FormLayout>
                <SidebarLayout.MobileFooter split="-1">
                    <Text>What does this mean?</Text>
                    <SidebarLayout.Link appearance="secondary" panelIndex={1}>
                        Let us explain
                    </SidebarLayout.Link>
                </SidebarLayout.MobileFooter>
            </StackLayout>
        </SidebarLayout>
    );
}
