import React from 'react';
import { ModalLayout } from '@app/view/components/ModalLayout.view';

interface ESPEndorsementHigherLimitRequestModalContentProps {
    onClose: () => void;
}

export function ESPEndorsementHigherLimitRequestModalContent({
    onClose,
}: ESPEndorsementHigherLimitRequestModalContentProps) {
    return (
        <ModalLayout
            title="Your higher limits request has been received"
            primaryAction={{
                label: 'Got It',
                onClick: onClose,
            }}
        >
            Our team will review your request promptly and will contact you via email soon.
        </ModalLayout>
    );
}
