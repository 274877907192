import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { CardLayout, StackLayout, Table, Text, TextButton } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { PaymentData } from './PaymentsHistory';

export interface CreditsTableProps {
    data: PaymentData[];
}

export function Credits(props: CreditsTableProps) {
    if (props.data.length === 0) {
        return null;
    }
    return (
        <StackLayout gap="24">
            <Text style="heading 4">Credits</Text>
            <StackLayout gap="none">
                <Text style="body 1">You have credits pending payment from your carrier(s).</Text>
                <Text style="body 1">
                    We will apply these to future invoices or you can request a refund once the
                    funds become
                </Text>
                <Text style="body 1">
                    available. Refunds may take up to 90 days to process.{' '}
                    <TextButton href="/support/contact">Contact us </TextButton> for more
                    information.
                </Text>
            </StackLayout>
            <CardLayout>
                <CardLayout.Body>
                    <Table>
                        <Table.Header>
                            <Table.Column>POLICY</Table.Column>
                            <Table.Column>INVOICE NO.</Table.Column>
                            <Table.Column>POLICY NO.</Table.Column>
                            <Table.Column>ISSUE DATE</Table.Column>
                            <Table.Column>AMOUNT</Table.Column>
                            <Table.Column />
                        </Table.Header>
                        <Table.Body>
                            {props.data.map((item) => (
                                <Table.Row key={item.policyNumber}>
                                    <Table.Cell>
                                        <Text style="label 1">{item.lineOfBusiness}</Text>
                                    </Table.Cell>
                                    <Table.Cell>{item.invoiceNumber}</Table.Cell>
                                    <Table.Cell>{item.policyNumber}</Table.Cell>
                                    <Table.Cell>
                                        <DateDisplay value={item.effectiveDate} />
                                    </Table.Cell>
                                    <Table.Cell>
                                        <MoneyDisplay
                                            value={item.total}
                                            negativeFormat="parenthesis"
                                        />
                                    </Table.Cell>
                                    <Table.Cell>{item.detailsLink}</Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </CardLayout.Body>
            </CardLayout>
        </StackLayout>
    );
}
