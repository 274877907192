import React from 'react';

import { DisplayPolicy } from '../../types/DisplayPolicy';
import { EmbrokerPoliciesEmptyList } from './EmbrokerPoliciesEmptyList';
import { EmbrokerPoliciesNoExisting } from './EmbrokerPoliciesNoExisting';
import { PolicyList } from './PolicyList';

interface EmbrokerPolicyListProps {
    policyList: DisplayPolicy[];
    hasAnyPolicy: boolean;
    showActionButtons: boolean;
}

export const EmbrokerPolicyList = (props: EmbrokerPolicyListProps) => {
    if (!props.hasAnyPolicy) {
        return <EmbrokerPoliciesNoExisting />;
    }

    if (props.policyList.length === 0) {
        return <EmbrokerPoliciesEmptyList />;
    }

    return <PolicyList policyList={props.policyList} showActionButtons={props.showActionButtons} />;
};
