import { getComponent } from '@embroker/service-app-engine';
import { Text } from '@embroker/ui-toolkit/v2';
import React, { useCallback, useEffect, useMemo } from 'react';

interface TableFieldProps {
    fields: any[];
    addAllowed: boolean;
    addLabel?: string;
    addRow(): void;
    removeAllowed: boolean;
    removeLabel?: string;
    removeRow(id: any): void;
    beforeRemovingRow?: () => Promise<void>;
    minimum: number;
    maximum: number;
    dynamicRows: number;
    sortDynamicRowsBy?(value: any): number;
    sortDynamicRowsOrder: 'ascending' | 'descending';
    styleHints: string[];
    readOnly: boolean;
    //Note to self: (Lazar) props bellow are not used.
    //This is probably so that we don't pass those as fieldProps
    onChange?: any;
    onFocus?: any;
    value?: any;
}

export const TableField = React.forwardRef(function TableField(
    props: TableFieldProps,
    ref: React.Ref<HTMLDivElement>,
) {
    const {
        fields,
        addAllowed,
        addLabel,
        addRow,
        removeAllowed,
        removeLabel,
        removeRow,
        beforeRemovingRow,
        minimum,
        maximum,
        dynamicRows,
        sortDynamicRowsBy,
        sortDynamicRowsOrder,
        styleHints,
        onChange,
        onFocus,
        value,
        readOnly,
        ...fieldProps
    } = props;

    const staticRows = fields.length - dynamicRows;
    const rowElements = useMemo(() => {
        if (typeof sortDynamicRowsBy !== 'function') {
            return fields;
        }

        const valueOf = sortDynamicRowsBy;
        const desc = sortDynamicRowsOrder === 'descending';

        const rows = fields.filter((field) => field.isDynamic);

        rows.sort((a, b) => {
            let aValue = valueOf(a.machine.state.value);
            let bValue = valueOf(b.machine.state.value);
            if (desc) {
                [aValue, bValue] = [bValue, aValue];
            }
            if (aValue === bValue) {
                return 0;
            }
            return aValue > bValue ? 1 : -1;
        });

        return fields.filter((field) => !field.isDynamic).concat(rows);
    }, [fields, sortDynamicRowsBy, sortDynamicRowsOrder]);

    const handleAddNewClick = useCallback(
        (e) => {
            e.preventDefault();
            addRow();
        },
        [addRow],
    );

    const rows = useMemo(() => {
        const handleClick = (fieldId: any) => (e: React.SyntheticEvent) => {
            e.preventDefault();

            if (beforeRemovingRow) {
                beforeRemovingRow()
                    .then(() => {
                        removeRow(fieldId);
                    })
                    .catch(() => {
                        //TODO log error
                    });
                return;
            }

            removeRow(fieldId);
        };

        if (readOnly && rowElements.length === 0) {
            return <Text style="microcopy">No items were listed.</Text>;
        }

        return rowElements.map((field, index) => {
            const Component = getComponent(field);
            const childProps: any = {
                instance: field,
                key: field.id,
                className: '',
            };

            if (!readOnly && removeAllowed) {
                childProps.className = 'em-table-row-removable';
                childProps.children = (
                    <div
                        className="em-table-row-remove"
                        style={
                            index >= staticRows && dynamicRows > minimum
                                ? undefined
                                : { visibility: 'hidden', height: 0, minHeight: 0 }
                        }
                    >
                        <a
                            className="em-button em-button-text em-button-color-default em-button-size-normal"
                            onClick={handleClick(field.id)}
                        >
                            {removeLabel}
                        </a>
                    </div>
                );
                childProps.invertChildOrder = styleHints.includes('em-remove-button-right');
            }

            return <Component key={index} {...childProps} />;
        });
    }, [
        rowElements,
        removeAllowed,
        staticRows,
        dynamicRows,
        minimum,
        removeLabel,
        styleHints,
        beforeRemovingRow,
        removeRow,
        readOnly,
    ]);

    useEffect(() => {
        let rows = dynamicRows;
        while (rows++ < minimum) {
            addRow();
        }
    });

    return (
        <div ref={ref} tabIndex={-1} {...fieldProps}>
            {rows}
            {readOnly || !addAllowed || dynamicRows >= maximum ? null : (
                <div className="em-table-button-row">
                    <a
                        className="em-button em-button-text em-button-color-default em-button-size-normal"
                        onClick={handleAddNewClick}
                    >
                        + {addLabel}
                    </a>
                </div>
            )}
        </div>
    );
});
