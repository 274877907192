import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, NotAllowed, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { CalculateSKU } from '../../../analytics/useCases/CalculateSKU';
import { InvalidAnnualTechFee } from '../../errors';
import { WCGAQuote, WCGAQuotePurchased } from '../entities/WCGAQuote';
import { WCGAQuoteRepository } from '../repositories/WCGAQuoteRepository';

/**
 * Request data for PurchaseWCGAQuote use case
 */
export interface PurchaseWCGAQuoteRequest {
    quote: WCGAQuote;
}

/**
 * Response data for PurchaseWCGAQuote use case
 */
export interface PurchaseWCGAQuoteResponse {
    policyId: UUID;
    policyDoneTaskId: UUID;
}

/**
 * PurchaseWCGAQuote use case is used to purchase selected WCGA digital quote
 */
export interface PurchaseWCGAQuote extends UseCase {
    execute(
        request: PurchaseWCGAQuoteRequest,
    ): AsyncResult<
        PurchaseWCGAQuoteResponse,
        NotAllowed | OperationFailed | InvalidArgument | InvalidAnnualTechFee
    >;
}

class PurchaseWCGAQuoteUseCase extends UseCase implements PurchaseWCGAQuote {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('WCGAQuote/PurchaseWCGAQuote');

    /**
     * Constructor for PurchaseWCGAQuote use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(WCGAQuoteRepository) private wcgaQuoteRepository: WCGAQuoteRepository,
        @inject(CalculateSKU.type) private calculateSku: CalculateSKU,
    ) {
        super(eventBus);
    }

    /**
     * Executes PurchaseWCGAQuote use case
     * Input is of PurchaseWCGAQuoteRequest type
     * @returns InvalidArgument if quote is not valid
     * @returns OperationFailed if repository could not perform purchase
     */
    public async execute({
        quote,
    }: PurchaseWCGAQuoteRequest): AsyncResult<
        PurchaseWCGAQuoteResponse,
        NotAllowed | OperationFailed | InvalidArgument | InvalidAnnualTechFee
    > {
        const result = await this.wcgaQuoteRepository.purchase(quote.applicationId, quote.id);

        if (isErr(result)) {
            const error = result.errors[0];
            if (error.name === 'InvalidAnnualTechFee') {
                return Failure(InvalidAnnualTechFee());
            }

            return handleOperationFailure(result);
        }

        const acceptResult = quote.accept();
        if (isErr(acceptResult)) {
            return acceptResult;
        }

        const skuResult = await this.calculateSku.execute({
            event: 'purchase',
            applicationId: quote.applicationId,
        });

        const purchaseEvent: WCGAQuotePurchased = {
            id: quote.id,
            origin: 'WCGAQuote',
            name: 'Purchased',
            applicationId: quote.applicationId,
            createdAt: new Date(Date.now()),
            totalPremium: quote.totalPremium,
            sku: skuResult.value,
            isRenewal: quote.isRenewal,
        };
        this.eventBus.publish(purchaseEvent);

        return Success({
            policyId: result.value.policyId,
            policyDoneTaskId: result.value.policyDoneTaskId,
        });
    }
}

export const PurchaseWCGAQuote: UseCaseClass<PurchaseWCGAQuote> = PurchaseWCGAQuoteUseCase;
