import { Money } from '@embroker/shotwell/core/types/Money';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface PropertyCoverage {
    typeCode: string;
    deductible?: Money;
    limit: Money;
    description: string;
}

export const PropertyCoverage = {
    ...defineValidator<PropertyCoverage>({
        typeCode: Joi.string(),
        deductible: Money.schema.optional(),
        limit: Money.schema,
        description: Joi.string().allow(null, ''),
    }),
    create(propertyCoverage: PropertyCoverage) {
        return PropertyCoverage.validate(propertyCoverage);
    },
};
