import { defineEntityValidator, entity, Entity } from '@embroker/shotwell/core/entity/Entity';
import { PhoneNumber } from '@embroker/shotwell/core/types/PhoneNumber';
import { Joi } from '@embroker/shotwell/core/validation/schema';

export interface WritingCompany extends Entity {
    /**
     * Writing company legal name
     */
    readonly name: string;
    /**
     * A NAIC insurance company code is a five-digit code that is assigned to
     * an insurance company by the National Association of Insurance Commissioners.
     * For writing companies outside of the U.S., this field may be in a different format.
     */
    readonly naicCode?: string;
    /**
     * Writing company billing phone
     */
    readonly billingPhone?: PhoneNumber;
    /**
     * Writing company billing url
     */
    readonly billingUrl?: string;
}

export const WritingCompany = entity<WritingCompany>({
    validator: defineEntityValidator<WritingCompany>({
        name: Joi.string().required(),
        naicCode: Joi.string().optional().allow(''),
        billingPhone: PhoneNumber.schema.optional(),
        billingUrl: Joi.string().optional(),
    }),
});
