import { Money } from '@embroker/shotwell/core/types/Money';
import { ColumnLayout, StackLayout, Table, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { useEnumGroup } from '../../../../serverEnums/view/hooks/useEnumGroup';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { Lob, LobType } from '../../../types/Lob';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { LiabilityCoverage } from '../../../types/LiabilityCoverage';
import { LineOfBusinessCodeListItem } from '@embroker/shotwell-api/enums';

interface CoverageColumnData {
    coverageCode: string;
    vehicleCode: string;
}

interface DeductibleColumnData {
    deductible1Amount: Money;
    deductible1Code: Nullable<string>;
    deductible2Amount: Money;
    deductible2Code: Nullable<string>;
}

interface RetentionColumnData {
    sir1Amount: Money;
    sir1Code: Nullable<string>;
    sir2Amount: Money;
    sir2Code: Nullable<string>;
    sirPercent: Nullable<number>;
}

interface LimitColumnData {
    limit1Code: string;
    limit1Amount: Money;
    limit1Description: string;
    limit2Code: string;
    limit2Amount: Money;
    limit2Description: string;
    typeCode: string;
}

interface GeneralCoverageSectionCardProps {
    lob: Immutable<Lob>;
    customRetentionWording?: string;
    lineOfBusiness?: LineOfBusinessCodeListItem;
}

export const CoverageSectionTable = ({
    lob,
    customRetentionWording,
    lineOfBusiness,
}: GeneralCoverageSectionCardProps) => {
    let liabilityList = lob.liabilitySection?.liabilityList;
    if (lob.lobType === 'cyberLiabilityLob' && liabilityList) {
        liabilityList = getSortedTechCyberLiabilityCoverageCodeListArray(
            lob.lobType,
            liabilityList,
        );
    }

    const { result: limitAppliesToCodeList } = useEnumGroup('LimitAppliesToCodeList');
    const { result: coveredVehiclesCodeList } = useEnumGroup('CoveredVehiclesCodeList');
    const { result: liabilityCoverageCodeList } = useEnumGroup('LiabilityCoverageCodeList');

    if (
        limitAppliesToCodeList === undefined ||
        coveredVehiclesCodeList === undefined ||
        liabilityCoverageCodeList === undefined
    ) {
        return null;
    }

    const getNameFromCoveredVehiclesTypeCode = (typeCodes: string) => {
        const JoinedTypeCodes = typeCodes
            .split(',')
            .map((item) => {
                return coveredVehiclesCodeList.get(item);
            })
            .join(', ');
        if (JoinedTypeCodes === ', ') {
            return '';
        }
        return '(' + JoinedTypeCodes + ')';
    };

    const getCoverageNameByCode = (coverageCode: string, lobType: string) => {
        const coverageNameByCodeMap: { [key: string]: string } = {
            LiabilityCoverageCodeListNonTechnologyBusinessAndManagementConsultantProfessionalLiability:
                'Non-Technology Business and Management Consultants Professional Liability',
        };
        const name =
            coverageNameByCodeMap[coverageCode] || liabilityCoverageCodeList.get(coverageCode);

        const lobPrefixMap: { [key: string]: string } = {
            workersCompensationLiabilityLob: 'Employers Liability - ',
        };
        const prefix = lobPrefixMap[lobType] || '';

        return `${prefix}${name}`;
    };

    const coverageColumn = (coverageTableColumnData: CoverageColumnData) => {
        return (
            <ColumnLayout key="coverageTableColumnData" split="0">
                <Text style="label 1">
                    {coverageTableColumnData.coverageCode
                        ? getCoverageNameByCode(coverageTableColumnData.coverageCode, lob.lobType)
                        : null}
                </Text>
                {coverageTableColumnData.vehicleCode ? (
                    <Text style="label 1">
                        {getNameFromCoveredVehiclesTypeCode(coverageTableColumnData.vehicleCode)}
                    </Text>
                ) : null}
            </ColumnLayout>
        );
    };

    function checkIfPolicyHasDeductible(): boolean {
        if (!(lob.liabilitySection && lob.liabilitySection.liabilityList)) {
            return false;
        }
        return lob.liabilitySection.liabilityList.some((item) => {
            return (
                (item.deductible1Amount && item.deductible1Amount.amount > 0) ||
                (item.deductible2Amount && item.deductible2Amount.amount > 0)
            );
        });
    }

    function checkIfPolicyHasNonZeroRetention(): boolean {
        if (!(lob.liabilitySection && lob.liabilitySection.liabilityList)) {
            return false;
        }

        return lob.liabilitySection.liabilityList.some((item) => {
            return (
                (item.sir1Amount && item.sir1Amount.amount > 0) ||
                (item.sir2Amount && item.sir2Amount.amount > 0) ||
                (item.sirPercent && item.sirPercent > 0)
            );
        });
    }

    const policyHasDeductible = checkIfPolicyHasDeductible();
    const policyHasNonZeroRetention = checkIfPolicyHasNonZeroRetention();

    const getLimitLabel = (limitCode: string, lobType: string): string | undefined => {
        const lobLabelMap: { [key: string]: { [key: string]: string } } = {
            workersCompensationLiabilityLob: {
                LimitAppliesToCodeListAggregate: 'Policy Limit',
                LimitAppliesToCodeListPerEmployee: 'Each Employee',
                LimitAppliesToCodeListPerAccident: 'Each Accident',
            },
        };
        const lobLabel = lobLabelMap[lobType] ? lobLabelMap[lobType][limitCode] : undefined;
        return lobLabel || limitAppliesToCodeList.get(limitCode);
    };

    const deductibleColumn = (deductibleColumnData: DeductibleColumnData) => {
        return (
            <StackLayout gap="12">
                {deductibleColumnData.deductible1Amount &&
                deductibleColumnData.deductible1Amount.amount > 0 ? (
                    <ColumnLayout>
                        <Text style="default" as="span">
                            <MoneyDisplay
                                fractionDigits={0}
                                value={deductibleColumnData.deductible1Amount}
                            />
                        </Text>
                        <Text style="default" as="span">
                            {deductibleColumnData.deductible1Code
                                ? limitAppliesToCodeList.get(deductibleColumnData.deductible1Code)
                                : null}
                        </Text>
                    </ColumnLayout>
                ) : null}
                {deductibleColumnData.deductible2Amount &&
                deductibleColumnData.deductible2Amount.amount > 0 ? (
                    <ColumnLayout>
                        <Text style="default" as="span">
                            <MoneyDisplay
                                fractionDigits={0}
                                value={deductibleColumnData.deductible2Amount}
                            />
                        </Text>
                        <Text style="default" as="span">
                            {deductibleColumnData.deductible2Code
                                ? limitAppliesToCodeList.get(deductibleColumnData.deductible2Code)
                                : null}
                        </Text>
                    </ColumnLayout>
                ) : null}
            </StackLayout>
        );
    };

    const retentionColumn = (retentionColumnData: RetentionColumnData) => {
        return (
            <StackLayout gap="12">
                {retentionColumnData.sir1Amount && retentionColumnData.sir1Amount.amount >= 0 ? (
                    <ColumnLayout>
                        <Text style="default" as="span">
                            <MoneyDisplay
                                fractionDigits={0}
                                value={retentionColumnData.sir1Amount}
                            />
                        </Text>
                        <Text style="default" as="span">
                            {retentionColumnData.sir1Code
                                ? limitAppliesToCodeList.get(retentionColumnData.sir1Code)
                                : null}
                        </Text>
                    </ColumnLayout>
                ) : null}
                {retentionColumnData.sir2Amount && retentionColumnData.sir2Amount.amount > 0 ? (
                    <ColumnLayout>
                        <Text style="default" as="span">
                            <MoneyDisplay
                                fractionDigits={0}
                                value={retentionColumnData.sir2Amount}
                            />
                        </Text>
                        <Text style="default" as="span">
                            {retentionColumnData.sir2Code
                                ? limitAppliesToCodeList.get(retentionColumnData.sir2Code)
                                : null}
                        </Text>
                    </ColumnLayout>
                ) : null}
                {retentionColumnData.sirPercent && retentionColumnData.sirPercent > 0 ? (
                    <Text style="default" as="span">
                        {retentionColumnData.sirPercent}
                    </Text>
                ) : null}
            </StackLayout>
        );
    };

    const limitsColumn = (limitColumnData: LimitColumnData) => {
        return (
            <StackLayout gap="12">
                {limitColumnData.limit1Amount && limitColumnData.limit1Amount.amount >= 0 ? (
                    <ColumnLayout gap="4">
                        <Text style="default" as="span">
                            <MoneyDisplay fractionDigits={0} value={limitColumnData.limit1Amount} />
                        </Text>
                        <Text style="default" as="span">
                            {limitColumnData.limit1Code
                                ? getLimitLabel(limitColumnData.limit1Code, lob.lobType)
                                : null}
                        </Text>
                    </ColumnLayout>
                ) : null}

                {limitColumnData.limit1Description &&
                limitColumnData.limit1Description.length != 0 ? (
                    <Text style="default" as="span">
                        {limitColumnData.limit1Description}
                    </Text>
                ) : null}

                {lineOfBusiness &&
                    lineOfBusiness === 'LineOfBusinessCodeListBOP' &&
                    generateBopLiabilityText(
                        lob.lobType,
                        limitColumnData.typeCode,
                        limitColumnData.limit1Code,
                    )}

                {limitColumnData.limit2Amount && limitColumnData.limit2Amount.amount > 0 ? (
                    <ColumnLayout gap="4">
                        <Text style="default" as="span">
                            <MoneyDisplay fractionDigits={0} value={limitColumnData.limit2Amount} />
                        </Text>
                        <Text style="default" as="span">
                            {limitColumnData.limit2Code
                                ? getLimitLabel(limitColumnData.limit2Code, lob.lobType)
                                : null}
                        </Text>
                    </ColumnLayout>
                ) : null}

                {limitColumnData.limit2Description &&
                limitColumnData.limit2Description.length != 0 ? (
                    <Text style="default" as="span">
                        {limitColumnData.limit2Description}
                    </Text>
                ) : null}

                {lineOfBusiness &&
                    lineOfBusiness === 'LineOfBusinessCodeListBOP' &&
                    generateBopLiabilityText(
                        lob.lobType,
                        limitColumnData.typeCode,
                        limitColumnData.limit2Code,
                    )}
            </StackLayout>
        );
    };

    // EM-36266 concession logic for BOP CHUBB release.
    // Only ever consider the custom retention wording if the policy
    // does not have any non-nil and non-zero deductibles.
    const determineRetentionWording = (): string => {
        if (policyHasDeductible) {
            return 'DEDUCTIBLE';
        }

        if (policyHasNonZeroRetention) {
            return 'RETENTION';
        }

        if (customRetentionWording && customRetentionWording !== '') {
            return customRetentionWording;
        }

        return 'RETENTION';
    };

    return liabilityList ? (
        <Table>
            <Table.Header>
                <Table.Column>COVERAGE</Table.Column>
                <Table.Column>{determineRetentionWording()}</Table.Column>
                <Table.Column>LIMITS</Table.Column>
            </Table.Header>
            <Table.Body>
                {liabilityList.map((item, index) => {
                    return (
                        <Table.Row key={index}>
                            <Table.Cell>
                                {coverageColumn({
                                    coverageCode: item.typeCode,
                                    vehicleCode: item.coveredVehiclesCodeList,
                                } as CoverageColumnData)}
                            </Table.Cell>
                            <Table.Cell>
                                {lob.lobType == 'excessLiabilityLob' ? (
                                    <ColumnLayout>
                                        <Text style="label 1">N/A</Text>
                                    </ColumnLayout>
                                ) : policyHasDeductible ? (
                                    deductibleColumn({
                                        deductible1Amount: item.deductible1Amount,
                                        deductible1Code: item.deductible1Code,
                                        deductible2Amount: item.deductible2Amount,
                                        deductible2Code: item.deductible2Code,
                                    } as DeductibleColumnData)
                                ) : (
                                    retentionColumn({
                                        sir1Amount: item.sir1Amount,
                                        sir1Code: item.sir1Code,
                                        sir2Amount: item.sir2Amount,
                                        sir2Code: item.sir2Code,
                                        sirPercent: item.sirPercent,
                                    } as RetentionColumnData)
                                )}
                            </Table.Cell>
                            <Table.Cell>
                                {limitsColumn({
                                    limit1Amount: item.limit1Amount,
                                    limit1Code: item.limit1Code,
                                    limit1Description: item.limit1Description,
                                    limit2Amount: item.limit2Amount,
                                    limit2Code: item.limit2Code,
                                    limit2Description: item.limit2Description,
                                    typeCode: item.typeCode,
                                } as LimitColumnData)}
                            </Table.Cell>
                        </Table.Row>
                    );
                })}
            </Table.Body>
        </Table>
    ) : null;
};

type LiabilityCoverageCodeListSortedMap = Record<string, LiabilityCoverage | undefined>;

function getSortedTechCyberLiabilityCoverageCodeListArray(
    lobType: LobType,
    liabilityList: Immutable<Nullable<LiabilityCoverage[]>>,
): Immutable<Nullable<LiabilityCoverage[]>> {
    if (lobType !== 'cyberLiabilityLob') {
        return liabilityList;
    }

    if (!liabilityList) {
        return null;
    }

    const sortedMap: LiabilityCoverageCodeListSortedMap = {
        // Tech part
        LiabilityCoverageCodeListTechnologyAndMediaErrorsAndOmissions: undefined,
        LiabilityCoverageCodeListContractualPrivacyBreachAndForensicExpenses: undefined,
        LiabilityCoverageCodeListWithheldFees: undefined,
        LiabilityCoverageCodeListNetworkSecurityAndPrivacyLiability: undefined,
        // Cyber part
        LiabilityCoverageCodeListPrivacyBreachForensicExpenseAndReputationalHarm: undefined,
        LiabilityCoverageCodeListPrivacyBreachAndForensicExpense: undefined,
        LiabilityCoverageCodeListPaymentCardIndustryAndCardBrandFinesAssessmentsOrCharges:
            undefined,
        LiabilityCoverageCodeListSystemDamageBrickingAndBetterment: undefined,
        LiabilityCoverageCodeListTelecommunicationsAndUtilityFraudIncludingCryptojacking: undefined,
        LiabilityCoverageCodeListSocialEngineeringAndFundsTransferFraud: undefined,
        LiabilityCoverageCodeListSystemDamage: undefined,
        LiabilityCoverageCodeListTelecommunicationsFraud: undefined,
        LiabilityCoverageCodeListCyberExtortion: undefined,
        LiabilityCoverageCodeListContingentBodilyInjuryAndPropertyDamage: undefined,
        LiabilityCoverageCodeListBusinessInterruptionNetworkSecurityEvent: undefined,
        LiabilityCoverageCodeListBusinessInterruptionSystemFailure: undefined,
        LiabilityCoverageCodeListDependentBusinessInterruptionDependentBusinessDisruption:
            undefined,
        LiabilityCoverageCodeListDependentBusinessInterruptionDependentSystemFailure: undefined,
        LiabilityCoverageCodeListInvoiceAndDeliveryFraud: undefined,
        LiabilityCoverageCodeListBusinessInterruptionCostsNetworkSecurityEvent: undefined,
        LiabilityCoverageCodeListBusinessInterruptionCostsSystemFailure: undefined,
    };

    for (const coverage of liabilityList) {
        if (!coverage.typeCode) {
            continue;
        }

        sortedMap[coverage.typeCode] = coverage;
    }

    for (const key in sortedMap) {
        if (!sortedMap[key]) {
            delete sortedMap[key];
        }
    }

    const sortedArray = Object.values(sortedMap);

    const acceptableTechCyberSplitLiabilityCoverageListLength = [
        10, // Standard
        16, // Plus
    ];
    if (!acceptableTechCyberSplitLiabilityCoverageListLength.includes(sortedArray.length)) {
        // Return original liability coverage list as it's for old E&O / Cyber
        return liabilityList;
    }

    return Object.values(sortedMap) as LiabilityCoverage[];
}

// We need an extra texts in the policy section for BOP Chubb
// This was previously done in the backend (see [EM-36297])
// However, that change can break the certificate service, so we need to move the change into the front end instead (see [EM-36460])
function generateBopLiabilityText(lobType: LobType, typeCode: string, limitCode: string): string {
    // For the General Liability coverage section, we want the "Policy Aggregate Limit" text underneath the Aggregate Limit
    if (isGLAggregateLimit(lobType, typeCode, limitCode)) {
        return 'Policy Aggregate Limit';
    }

    // For the Auto Liability coverage section, we want the "Included in the Per Occurrence Limit" text underneath the Per Accident Limit
    if (isAutoPerOccurenceLimit(lobType, typeCode, limitCode)) {
        return 'Included in the Per Occurrence Limit';
    }

    return '';
}

function isGLAggregateLimit(lobType: LobType, typeCode: string, limitCode: string): boolean {
    return (
        lobType === 'generalLiabilityLob' &&
        typeCode === 'LiabilityCoverageCodeListGeneralLiability' &&
        limitCode === 'LimitAppliesToCodeListAggregate'
    );
}

function isAutoPerOccurenceLimit(lobType: LobType, typeCode: string, limitCode: string): boolean {
    return (
        lobType === 'autoLiabilityLob' &&
        typeCode === 'LiabilityCoverageCodeListHiredNonOwnedAutoLiability' &&
        limitCode === 'LimitAppliesToCodeListPerAccident'
    );
}
