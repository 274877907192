import { inject } from '@embroker/shotwell/core/di';
import { Aborted, InvalidArgument, OperationFailed, Timeout } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { BOPHartfordQuoteRepository } from '@app/quote/hartford-bop/repositories/BOPHartfordQuoteRepository';

export interface GetPaymentUrlRequest {
    path: string;
}

export interface GetPaymentUrl extends UseCase {
    execute(
        request: GetPaymentUrlRequest,
    ): AsyncResult<string, InvalidArgument | OperationFailed | Timeout | Aborted>;
}

class GetPaymentUrlUseCase extends UseCase implements GetPaymentUrl {
    public static type = Symbol('BopHartford/GetPaymentUrl');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BOPHartfordQuoteRepository) private repository: BOPHartfordQuoteRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        path,
    }: GetPaymentUrlRequest): AsyncResult<
        string,
        InvalidArgument | OperationFailed | Timeout | Aborted
    > {
        const result = await this.repository.getPaymentUrl(path);
        if (isErr(result)) {
            return result;
        }
        return Success(result.value);
    }
}

export const GetPaymentUrl: UseCaseClass<GetPaymentUrl> = GetPaymentUrlUseCase;
