import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { NAICSRepository } from '../repositories/NAICSRepository';
//Use naicsinudstry repo
/**
 * Request data for GetNAICSGroupNameByCode use case
 */
export interface GetNAICSGroupNameByCodeRequest {
    naicsCode: Nullable<string>;
}

/**
 * Response data for GetNAICSGroupNameByCode use case
 */
export interface GetNAICSGroupNameByCodeResponse {
    naicsGroupName: Immutable<string>[];
}

/**
 * GetNAICSGroupNameByCode use case is used to get all NAICS group names for given NAICS code
 */
export interface GetNAICSGroupNameByCode extends UseCase {
    execute(request: GetNAICSGroupNameByCodeRequest): AsyncResult<GetNAICSGroupNameByCodeResponse>;
}

@injectable()
class GetNAICSGroupNameByCodeUseCase extends UseCase implements GetNAICSGroupNameByCode {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/GetNAICSGroupNameByCode');

    /**
     * Constructor for GetNAICSGroupNameByCode use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(NAICSRepository) private naicsRepository: NAICSRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes GetNAICSGroupNameByCode use case
     * Input is of GetNAICSGroupNameByCodeRequest type
     * @returns data of GetNAICSGroupNameByCodeResponse type if execution was successful
     */
    public async execute({
        naicsCode,
    }: GetNAICSGroupNameByCodeRequest): AsyncResult<GetNAICSGroupNameByCodeResponse> {
        if (!naicsCode) {
            return Success<GetNAICSGroupNameByCodeResponse>({
                naicsGroupName: [],
            });
        }
        const repoResult = this.naicsRepository.getNaicsGroupNamesByCode(naicsCode);

        return Success({ naicsGroupName: repoResult });
    }
}

export const GetNAICSGroupNameByCode: UseCaseClass<GetNAICSGroupNameByCode> =
    GetNAICSGroupNameByCodeUseCase;
