import { OperationFailed } from '@embroker/shotwell/core/Error';
import { Nullable } from '@embroker/shotwell/core/types';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { execute } from '@embroker/shotwell/core/UseCase';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import { Form, TextButton } from '@embroker/ui-toolkit/v2';
import React, { useEffect } from 'react';
import { useCurrentRoute } from 'react-navi';
import { ContactReason } from '../../../../support/view/components/ContactUs';
import { Link } from '../../../../view/components';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { ErrorCode } from '../../../errors';
import { UserStatusCode } from '../../../types/enums';
import { CheckUserStatus } from '../../../useCases/CheckUserStatus';
import { PublicForm } from '../PublicForm';

interface UsernameFormData {
    username: EmailAddress;
}

interface SubmitResult {
    readonly emailAddress: EmailAddress;
    readonly userStatus: UserStatusCode;
}

const emailNotRegistered = "This email isn't registered with Embroker.";

const UsernameForm = createForm<UsernameFormData>({
    fields: {
        username: {
            type: 'email',
            validator: EmailAddress.schema.required(),
            formatValidationError(error) {
                switch (error.details.validator) {
                    case 'any.required':
                        return 'You must enter your email';
                    case 'string.email':
                        return 'You must enter a valid email address';
                    default:
                        return error.message;
                }
            },
        },
    },
    submit: async (formResult) => {
        const emailAddress = formResult.username;
        const response = await execute(CheckUserStatus, { email: emailAddress });
        if (isErr(response)) {
            for (const error of response.errors) {
                switch (error.code) {
                    case ErrorCode.InactiveAccount:
                    case ErrorCode.NoAccount:
                        return Failure(error);
                    default:
                        break;
                }
            }
            return Failure(
                OperationFailed({
                    message: 'CheckUserStatus failed',
                    errors: response.errors,
                }),
            );
        }
        const userStatus = response.value.status;
        const result: SubmitResult = {
            userStatus,
            emailAddress,
        };
        return Success(result);
    },
    formatSubmitErrors(errors) {
        if (errors.length === 0) {
            return [];
        }

        for (const error of errors) {
            switch (error.code) {
                case ErrorCode.InactiveAccount:
                    return ['This user has been deactivated'];
                case ErrorCode.NoAccount:
                    return [emailNotRegistered];
                default:
                    break;
            }
        }

        return ['Sorry, something went wrong. Please try again later.'];
    },
});
interface UsernameProps {
    username?: EmailAddress;
    onUserExists: (username: EmailAddress) => void;
}
export function Username({ username, onUserExists }: UsernameProps) {
    const { navigate } = useNavigation();
    const { url: currentRoute } = useCurrentRoute();
    const { fields, status, result, submit, messages, value } = useForm(UsernameForm, {
        username,
    });

    const signUpUrl = URI.build('/signup', {
        ...currentRoute.query,
        emailAddress: value.username,
    });

    const [emailNotRegisteredMessage, formMessages] = messages.reduce<
        [Nullable<React.ReactChild>, string[]]
    >(
        (accumulator, message) => {
            if (message === emailNotRegistered) {
                accumulator[0] = (
                    <React.Fragment>
                        This email isn't registered with Embroker.{' '}
                        <Link href={signUpUrl}>Sign up</Link>.
                    </React.Fragment>
                );
            } else {
                accumulator[1].push(message);
            }

            return accumulator;
        },
        [null, []],
    );
    const emailFieldMessages = [emailNotRegisteredMessage, ...fields.username.messages].filter(
        Boolean,
    ) as React.ReactChild[];

    useEffect(() => {
        if (status === 'submitted') {
            const { emailAddress } = result as SubmitResult;
            onUserExists(emailAddress);
        }
    }, [result, status, onUserExists]);

    const prefilledContactReason: ContactReason = 'Forgot email';

    const usernameInputProps = {
        autoComplete: 'email',
        ...fields.username.props,
        hasErrors: fields.username.props.hasErrors || !!emailNotRegisteredMessage,
    };

    return (
        <PublicForm
            otherOption={
                <TextButton
                    data-e2e="user-org-username-form-forgot-email-link"
                    onClick={() =>
                        navigate(
                            URI.build('/support/contact', currentRoute.search, {
                                prefilledContactReason,
                            }),
                        )
                    }
                >
                    Forgot email?
                </TextButton>
            }
            messages={formMessages}
            submit={submit}
            title="Sign in"
            submitText="Continue"
            submitDisabled={!usernameInputProps.value}
        >
            <Form.Field
                data-e2e="user-org-username-form-email-field"
                inputProps={usernameInputProps}
                label="Email Address"
                messages={emailFieldMessages}
                type="email"
            />
        </PublicForm>
    );
}
