import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { LPLQuoteRepository } from '../repositories/LPLQuoteRepository';
import { ApplicationNotFound, Unknown } from '@app/quote/lpl/errors';
import { OperationFailed } from '@embroker/shotwell/core/Error';

export interface UpdateApplicationAddCyberAfterCheckoutRequest {
    applicationId: UUID;
    addCyberAfterCheckout: boolean;
}

export interface UpdateApplicationAddCyberAfterCheckout extends UseCase {
    execute(
        request: UpdateApplicationAddCyberAfterCheckoutRequest,
    ): AsyncResult<void, OperationFailed | ApplicationNotFound | Unknown>;
}

@injectable()
class UpdateApplicationAddCyberAfterCheckoutUseCase
    extends UseCase
    implements UpdateApplicationAddCyberAfterCheckout
{
    public static type = Symbol('LPLQuote/UpdateApplicationAddCyber');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(LPLQuoteRepository) private lplQuoteRepository: LPLQuoteRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
        addCyberAfterCheckout,
    }: UpdateApplicationAddCyberAfterCheckoutRequest): AsyncResult<
        void,
        OperationFailed | ApplicationNotFound | Unknown
    > {
        const result = await this.lplQuoteRepository.updateApplicationAddCyberAfterCheckout(
            applicationId,
            addCyberAfterCheckout,
        );
        if (isErr(result)) {
            return result;
        }
        return Success();
    }
}

export const UpdateApplicationAddCyberAfterCheckout: UseCaseClass<UpdateApplicationAddCyberAfterCheckout> =
    UpdateApplicationAddCyberAfterCheckoutUseCase;
