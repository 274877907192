import { Nullable } from '@embroker/shotwell/core/types';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { valueObject } from '@embroker/shotwell/core/types/ValueObject';

export interface Application {
    questionnaireData: Nullable<string>;
}

export const Application = valueObject({
    ...defineValidator<Application>({
        questionnaireData: Joi.string().allow(null),
    }),
});
