import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { Nominal } from '@embroker/shotwell/core/types/Nominal';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    Button,
    PageLayout,
    ColumnLayout,
    FloatingLayout,
    GridLayout,
    Spinner,
    useModal,
    Text,
    StackLayout,
    useResponsive,
} from '@embroker/ui-toolkit/v2';
import { AppTypeCode } from '../../../types/enums';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { LearnMoreSlideout } from '../LearnMoreSlideout';
import { AppContext, AppContextStore } from '../../../../view/AppContext';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { Applicant } from '../../../entities/Applicant';
import { Application } from '../../../entities/Application';
import { Coverage } from '../../../types/Coverage';
import { GetAllCoverages } from '../../../useCases/GetAllCoverages';
import { GetApplicant } from '../../../useCases/GetApplicant';
import { GetApplicationList } from '../../../useCases/GetApplicationList';
import { ShoppingCoverageList } from './ShoppingCoverageList';
import { GetVerticalRecommendedCoverages } from '../../../useCases/GetVerticalRecommendedCoverages';
import { RenewalWarningModal } from '../RenewalWarningModal';
import { GetPolicies } from '../../../../policy/useCases/GetPolicies';
import { PolicySortByAlphabet } from '../../../../policy/types/PolicySortBy';
import { MPLValidNaicsCodes } from '../../../../userOrg/types/MPLValidNaicsCodes';
import { GetOrganizationProfile } from '../../../../userOrg/useCases/GetOrganizationProfile';
import { getAppTypeCodeFromBundleDetails } from '../../../../summary/view/components/policyDetails';
import { useStartApplication } from '../../hooks/useStartApplication';
import { PublishShoppingUserClickEvent } from '../../../useCases/PublishShoppingUserClickEvent';
import { ShoppingBanner } from './ShoppingBanner.view';
import { NAICS_CODE_TO_VERTICAL, Vertical } from '../../../../userOrg/types/enums';
import { lawProducts } from '../coverageDetails';

export function getShoppableCoverages(
    coverageList: Immutable<Coverage[]>,
    applicationList: EntityProps<Application>[] | Immutable<Array<EntityProps<Application>>>,
    quoteList: Immutable<Array<EntityProps<Application>>> = [],
): Coverage[] {
    const activeCoverageApplications = applicationList.filter(
        (application) =>
            application.status === 'InsuranceApplicationStatusCodeListQuestionnaireInProgress',
    );

    const removeDuplicateCoverages = [
        ...activeCoverageApplications,
        ...quoteList,
    ] as EntityProps<Application>[];

    return coverageList.filter(
        (coverage) =>
            !removeDuplicateCoverages.some((application) => {
                const bundleAppType = getAppTypeCodeFromBundleDetails(application.bundleDetails);
                return (
                    coverage.appType === application.appType || coverage.appType === bundleAppType
                );
            }),
    );
}

type ShoppingDashboardProps = {
    organizationId: UUID;
};

export function ShoppingDashboard({ organizationId }: ShoppingDashboardProps) {
    const [applicant, setApplicant] = useState<Nullable<EntityProps<Applicant>>>(null);
    const [allCoverages, setAllCoverages] = useState<Nullable<Coverage[]>>(null);
    const [applicationList, setApplicationList] = useState<Nullable<Application[]>>(null);
    const { result: getApplicantResult } = useUseCase(GetApplicant);
    const { result: getAllCoverageResult } = useUseCase(GetAllCoverages);
    const { result: policiesResult } = useUseCase(GetPolicies, {
        filter: {
            showActive: true,
            showInactive: false,
            sortBy: PolicySortByAlphabet,
        },
    });
    const { selectedCoverages } = useContext(AppContext);
    const { navigate } = useNavigation();
    const { result: getApplicationListResult } = useUseCase(GetApplicationList);
    const [lplRenewalApplicationId, setLPLRenewalApplicationId] =
        useState<Nominal<string, 'UUID'>>();

    const isMobile = useResponsive({ screenWidth: { smallerThan: 'tablet' } });

    const { setSlideout } = useContext(AppContext);

    const renewalWarningModal = useModal();

    const { result: getOrganizationProfileResult } = useUseCase(GetOrganizationProfile, {
        organizationId,
    });

    const organizationNaics =
        getOrganizationProfileResult && isOK(getOrganizationProfileResult)
            ? getOrganizationProfileResult.value.organization.naics
            : null;

    const hasAnyPolicy =
        policiesResult && isOK(policiesResult) && policiesResult.value.hasAnyPolicy;

    const isValidMPLNaicsCode = MPLValidNaicsCodes.isNaicCodeValid(organizationNaics);
    const isMPLEnabled = MPLValidNaicsCodes.isMPLVerticalEnabled(organizationNaics);
    const displayMPLBanner = !hasAnyPolicy && isValidMPLNaicsCode && isMPLEnabled;

    const vertical = NAICS_CODE_TO_VERTICAL[organizationNaics || ''];
    const isLawVertical = vertical === 'LawFirm';
    const displayLPLBanner = !hasAnyPolicy && isLawVertical;

    useEffect(() => {
        if (getApplicantResult && isOK(getApplicantResult)) {
            setApplicant(getApplicantResult.value.applicant as Applicant);
        }
    }, [getApplicantResult]);

    useEffect(() => {
        if (getAllCoverageResult && isOK(getAllCoverageResult)) {
            const allCoveragesResult = getAllCoverageResult.value.allCoverages as Coverage[];
            setAllCoverages(allCoveragesResult);
        }
    }, [getAllCoverageResult]);

    useEffect(() => {
        if (getApplicationListResult && isOK(getApplicationListResult)) {
            setApplicationList(getApplicationListResult.value.applicationList as Application[]);
        }
    }, [getApplicationListResult, applicationList]);

    const { result: verticalRecommendedCoveragesResponse } = useUseCase(
        GetVerticalRecommendedCoverages,
    );

    const dashboardCoverages = useMemo(() => {
        const verticalCoverages: Coverage[] =
            verticalRecommendedCoveragesResponse && isOK(verticalRecommendedCoveragesResponse)
                ? [...verticalRecommendedCoveragesResponse.value.verticalCoverages]
                : [];
        return getShoppableCoverages(verticalCoverages, applicationList || []);
    }, [applicationList, verticalRecommendedCoveragesResponse]);

    const getSelectedCoverageTypes = () => {
        if (!selectedCoverages) {
            return [];
        }
        return selectedCoverages.map((coverage) => coverage.appType);
    };

    const { getLPLRenewalApplicationId, startApplication, isLoading } = useStartApplication();

    const handleStartApplication = async () => {
        const selectedAppTypes = getSelectedCoverageTypes();

        const lplRenewalApplicationId = await getLPLRenewalApplicationId(selectedAppTypes, false);
        if (lplRenewalApplicationId) {
            setLPLRenewalApplicationId(lplRenewalApplicationId);
            renewalWarningModal.show();
            return;
        }

        startApplication({ selectedAppTypes });
    };

    const handleCreateNewApp = () =>
        startApplication({ selectedAppTypes: getSelectedCoverageTypes() });

    if (getApplicantResult && isErr(getApplicantResult)) {
        return <ErrorPage errors={getApplicantResult.errors} />;
    }

    if (getAllCoverageResult && isErr(getAllCoverageResult)) {
        return <ErrorPage errors={getAllCoverageResult.errors} />;
    }

    if (!applicant || !allCoverages || !verticalRecommendedCoveragesResponse) {
        return <Spinner />;
    }

    const setupLearnMoreComponent = (code: AppTypeCode, isSelected: boolean) => {
        setSlideout(
            <LearnMoreSlideout
                appTypeCode={code}
                naicsCode={applicant.naicsIndustry}
                isSelected={isSelected}
                onCoverageSelect={handleLearnMoreCoverageSelected}
                variant={lawProducts.includes(code) ? 'new' : 'standard'}
            />,
        );
    };

    const handleLearnMoreCoverageSelected = (code: AppTypeCode, isSelected: boolean) => {
        if (isSelected) {
            execute(PublishShoppingUserClickEvent, {
                clickEventName: 'Shop All Coverage Get a Quote slideout clicked',
                clickEventPayload: { appType: code },
            });
        }
        handleAddAndRemoveCoverage(code, isSelected);
        setupLearnMoreComponent(code, isSelected);
    };

    const handleShowDetails = (appType: AppTypeCode, isSelected = false) => {
        setupLearnMoreComponent(appType, isSelected);
    };

    const handleAddAndRemoveCoverage = (appType: AppTypeCode, isSelected: boolean) => {
        const updatedAllCoverages = allCoverages.map((coverage) => {
            if (coverage.appType === appType) {
                return {
                    ...coverage,
                    isSelected,
                };
            }
            return { ...coverage };
        });
        AppContextStore.update({
            selectedCoverages: updatedAllCoverages.filter((coverage) => coverage.isSelected),
        });
        setAllCoverages(updatedAllCoverages);
    };

    const handleINeedGuidenceCTAClick = async (vertical: Vertical) => {
        await execute(PublishShoppingUserClickEvent, {
            clickEventName: 'I need guidance CTA clicked',
        });
        const url = URI.build(
            `shopping/${vertical === 'LawFirm' ? 'law-bundle' : 'guidance-page'}`,
            vertical === 'LawFirm'
                ? {}
                : {
                      mplGuidanceRequested: true,
                  },
        );
        navigate(url);
    };

    return (
        <PageLayout.Section>
            {isLoading && <Spinner />}
            {selectedCoverages && selectedCoverages.length > 0 ? (
                <FloatingLayout position="top">
                    <ColumnLayout split="-1">
                        <Button
                            onClick={handleStartApplication}
                            notificationNumber={selectedCoverages.length}
                        >
                            Start application{selectedCoverages.length > 1 ? 's' : ''}
                        </Button>
                    </ColumnLayout>
                </FloatingLayout>
            ) : null}

            <StackLayout gap={isMobile ? '24' : '48'}>
                <StackLayout gap="12">
                    <Text className="u-grid-size-12" style={isMobile ? 'heading 1' : 'heading 2'}>
                        What kind of insurance are you looking for?
                    </Text>
                    <Text className="u-grid-size-12" style="body 1">
                        To get the best rates for new and existing coverage, simply select the
                        coverages you need and start our easy application process.
                    </Text>
                </StackLayout>
                {displayMPLBanner && (
                    <ShoppingBanner
                        className="u-grid-size-12"
                        action={{
                            onClick: () => handleINeedGuidenceCTAClick('Accounting'),
                            label: "Let's Go!",
                        }}
                        title="Not sure what you need?"
                        subtitle="Let us build a custom insurance package just for your business."
                    />
                )}
                {displayLPLBanner && (
                    <ShoppingBanner
                        className="u-grid-size-12"
                        action={{
                            onClick: () => handleINeedGuidenceCTAClick('LawFirm'),
                            label: "Let's Go!",
                        }}
                        title="Not sure what you need?"
                        subtitle="Let us build a custom insurance package just for your business."
                    />
                )}
                <GridLayout>
                    <ShoppingCoverageList
                        allCoverages={dashboardCoverages}
                        applicant={applicant}
                        selectedCoverages={selectedCoverages}
                        handleAddAndRemoveCoverage={(appType, isSelected) => {
                            if (isSelected) {
                                execute(PublishShoppingUserClickEvent, {
                                    clickEventName: 'Shop All Coverage Get a Quote clicked',
                                    clickEventPayload: { appType },
                                });
                            }
                            handleAddAndRemoveCoverage(appType, isSelected);
                        }}
                        handleShowDetails={handleShowDetails}
                    />
                </GridLayout>
            </StackLayout>

            <RenewalWarningModal
                modal={renewalWarningModal}
                onCreateNewApp={handleCreateNewApp}
                renewalApplicationId={lplRenewalApplicationId}
            />
        </PageLayout.Section>
    );
}
