import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { SupplementalQuestionnaireRepository } from '../esp/repositories/SupplementalQuestionnaireRepository';

export interface SaveSupplementalQuestionnaireRequest {
    applicationId: UUID;
    questionnaireData: string;
}

export interface SaveSupplementalQuestionnaire extends UseCase {
    execute(
        request: SaveSupplementalQuestionnaireRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed>;
}

class SaveSupplementalQuestionnaireUseCase
    extends UseCase
    implements SaveSupplementalQuestionnaire
{
    public static type = Symbol(
        'higherLimitsSupplementalQuestionnaire/SaveSupplementalQuestionnaire',
    );

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(SupplementalQuestionnaireRepository)
        private supplementalQuestionnaireRepository: SupplementalQuestionnaireRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
        questionnaireData,
    }: SaveSupplementalQuestionnaireRequest): AsyncResult<void, InvalidArgument | OperationFailed> {
        return await this.supplementalQuestionnaireRepository.saveSupplementalQuestionnaire({
            applicationId,
            questionnaireData,
        });
    }
}

export const SaveSupplementalQuestionnaire: UseCaseClass<SaveSupplementalQuestionnaire> =
    SaveSupplementalQuestionnaireUseCase;
