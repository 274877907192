import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';

const ClickEventNameList = [
    'Update your billing email address clicked',
    'Go to Dashboard clicked',
    'Page Load',
    'Get Quote clicked',
] as const;
type CyberCrossSellClickEventNameList = typeof ClickEventNameList;
export type CyberCrossSellClickEventName = CyberCrossSellClickEventNameList[number];

export enum CyberCrossSellEventType {
    updateYourBillingEmailAddressClicked = 'updateYourBillingEmailAddressClicked',
    goToDashboardClicked = 'goToDashboardClicked',
    pageLoad = 'pageLoad',
    startQuote = 'startQuote',
}

export interface CyberCrossSellEvent extends DomainEvent {
    name: 'CyberCrossSellEvent';
    clickEventName: CyberCrossSellClickEventName;
}

export interface PublishCyberCrossSellEvent extends UseCase {
    execute(request: PublishCyberCrossSellEventRequest): AsyncResult<void>;
}

export interface PublishCyberCrossSellEventRequest {
    eventType: CyberCrossSellEventType;
}

@injectable()
class PublishCyberCrossSellEventUseCase extends UseCase implements PublishCyberCrossSellEvent {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('LPLRenewal/PublishCyberCrossSellEventUseCase');

    /**
     * Constructor for PublishCyberCrossSellEvent class instance
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    /**
     * Executes the PublishCyberCrossSellEvent use case.
     */
    public async execute({ eventType }: PublishCyberCrossSellEventRequest): AsyncResult<void> {
        let clickEventName: CyberCrossSellClickEventName | null = null;

        switch (eventType) {
            case CyberCrossSellEventType.updateYourBillingEmailAddressClicked: {
                clickEventName = 'Update your billing email address clicked';
                break;
            }

            case CyberCrossSellEventType.goToDashboardClicked: {
                clickEventName = 'Go to Dashboard clicked';
                break;
            }

            case CyberCrossSellEventType.pageLoad: {
                clickEventName = 'Page Load';
                break;
            }
            case CyberCrossSellEventType.startQuote: {
                clickEventName = 'Get Quote clicked';
                break;
            }
        }

        if (clickEventName) {
            const event: CyberCrossSellEvent = {
                origin: 'LPLRenewal',
                name: 'CyberCrossSellEvent',
                clickEventName,
                createdAt: new Date(Date.now()),
                id: UUID.create(),
            };
            await this.eventBus.publish(event);
        }

        return Success();
    }
}

export const PublishCyberCrossSellEvent: UseCaseClass<PublishCyberCrossSellEvent> =
    PublishCyberCrossSellEventUseCase;
