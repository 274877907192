import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { PCoMLEndorsementRepository } from '../repositories/PCoMLEndorsementRepository';
import { PCoMLEndorsementUserData } from '../types/PCoMLEndorsementUserData';

export interface GetPCoMLEndorsementUserData extends UseCase {
    execute(
        request: void,
    ): AsyncResult<PCoMLEndorsementUserData, InvalidArgument | OperationFailed>;
}

@injectable()
class GetPCoMLEndorsementUserDataUseCase extends UseCase implements GetPCoMLEndorsementUserData {
    public static type = Symbol('PCoMLEndorsement/GetPCoMLEndorsementUserData');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(PCoMLEndorsementRepository)
        private pcomlEndorsementRepository: PCoMLEndorsementRepository,
    ) {
        super(eventBus);
    }

    public async execute(): AsyncResult<
        PCoMLEndorsementUserData,
        InvalidArgument | OperationFailed
    > {
        return await this.pcomlEndorsementRepository.loadUserData();
    }
}

export const GetPCoMLEndorsementUserData: UseCaseClass<GetPCoMLEndorsementUserData> =
    GetPCoMLEndorsementUserDataUseCase;
