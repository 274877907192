import { FormData, OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { Button, ColumnLayout, TextButton } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { WizardForm } from '../../../view/hooks/useWizardForm';
import { Quote } from '../../entities/Quote';

interface QuoteOnDemandNavigationProps<T extends FormData, Q extends Quote> {
    quote: Q;
    isSubmitting: boolean;
    hasNext: boolean;
    nextButtonLabel: string;
    next: WizardForm<OpaqueForm<T>>['next'];
    hasPrevious: boolean;
    previous: WizardForm<OpaqueForm<T>>['previous'];
    handlePurchase(): void;
    trigger: WizardForm<OpaqueForm<T>>['trigger'];
    status: WizardForm<OpaqueForm<T>>['status'];
    disableNextButton?: boolean;
    action: WizardForm<OpaqueForm<T>>['action'];
}

export function QuoteOnDemandNavigation<T extends FormData, Q extends Quote>({
    quote,
    isSubmitting,
    hasNext,
    nextButtonLabel,
    next,
    handlePurchase,
    hasPrevious,
    previous,
    trigger,
    status,
    disableNextButton,
    action,
}: QuoteOnDemandNavigationProps<T, Q>) {
    const handleRecalculate = () => {
        trigger('update');
    };

    const dirty = status === 'dirty';
    const invalid = status === 'invalid';

    const renderNavigation = () => {
        if (dirty || (invalid && action === 'update')) {
            return (
                <Button disabled={isSubmitting} onClick={handleRecalculate}>
                    {quote?.status === 'referred' ? 'Refresh' : 'Recalculate'}
                </Button>
            );
        }

        return (
            <Button
                disabled={
                    disableNextButton || isSubmitting || (quote.status != 'signed' && !hasNext)
                }
                onClick={hasNext ? next : handlePurchase}
            >
                {nextButtonLabel}
            </Button>
        );
    };

    return (
        <ColumnLayout split="-1">
            {renderNavigation()}
            <TextButton
                size="small"
                as="button"
                className={hasPrevious ? '' : 'u-hidden'}
                icon="bold-caret-left"
                disabled={isSubmitting}
                onClick={previous}
            >
                Back to options
            </TextButton>
        </ColumnLayout>
    );
}
