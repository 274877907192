import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { CNAQuote } from '../../entities/CNAQuote';
import { CNAQuoteOptions } from '../../types/CNAQuoteOptions';

export interface PurchaseCNAResponse {
    policyId: UUID;
    policyDoneTaskId: UUID;
}

export interface PurchaseDetails {
    policyId: UUID;
    taskIdPolicyDone: UUID;
}

export interface CNAQuoteRepository {
    getCurrentCNAQuote(
        applicationId: UUID,
    ): AsyncResult<CNAQuote, InvalidArgument | OperationFailed>;

    quoteCNA(
        applicationId: UUID,
        cnaQuoteOptions: CNAQuoteOptions,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed>;

    submitForReview(applicationId: UUID): AsyncResult<void, InvalidArgument | OperationFailed>;

    purchaseCNA(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<PurchaseCNAResponse, InvalidArgument | OperationFailed>;
}

export const CNAQuoteRepository = Symbol('CNAQuoteRepository');
