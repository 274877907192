import React from 'react';

import { AppContext } from '@app/view/AppContext';
import { RouteDefinitions } from '@app/view/routes/Route';
import { map, route } from 'navi';
import { CrossSellLandingPage } from '../CrossSellLandingPage';

export const routes: RouteDefinitions<AppContext> = {
    '/lpl-renewal/cyber-insurance-offer': map(async (request) => {
        const coverageName = request.query.coverageName;
        const effectiveDate = request.query.effectiveDate;
        return route({
            title: 'Cyber Offer Landing Page',
            view: (
                <CrossSellLandingPage coverageName={coverageName} effectiveDate={effectiveDate} />
            ),
            data: { hideHeader: true, fullscreen: true },
        });
    }),
};
