import { isDomainEventLocal } from '@embroker/shotwell/core/event/DomainEvent';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { execute } from '@embroker/shotwell/core/UseCase';
import { useDomainEvent } from '@embroker/shotwell/view/hooks/useDomainEvent';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    Navigation as UiNavigation,
    StatusMessage,
    TextButton,
    usePrevious,
} from '@embroker/ui-toolkit/v2';
import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { hasRole } from '../../userOrg/entities/Session';
import { UserPasswordSet } from '../../userOrg/entities/User';
import { CheckUserStatus } from '../../userOrg/useCases/CheckUserStatus';
import { GetActiveUserProfile } from '../../userOrg/useCases/GetActiveUserProfile';
import { AppContext } from '../AppContext';
import { AuthenticatedBrokerUserMenu, AuthenticatedUserMenu } from './AuthenticatedUserMenu';
import { Link } from './Link/Link';
import { NotificationsContext } from '../../notifications/NotificationsContext';
import { getEnvVar } from '../../env';
import { USER_ONBOARDING_BASE_ROUTE } from '@app/userOrg/view/routes/onboardingRoutes';
import { useCurrentRoute } from 'react-navi';

interface NavigationProps {
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

function AuthenticatedNavigation({ onClick }: NavigationProps) {
    const { toggleNavigationState, navigationState = 'open' } = useContext(AppContext);

    const { unreadNotificationCount } = useContext(NotificationsContext);
    const [isUserWithPassword, setUserWithPassword] = useState<boolean | undefined>(undefined);

    const { result: activeUserResp } = useUseCase(GetActiveUserProfile);
    const email = activeUserResp && isOK(activeUserResp) ? activeUserResp.value.email : null;

    useDomainEvent<UserPasswordSet>('User', 'PasswordSet', async (event) => {
        if (!isDomainEventLocal(event)) {
            return;
        }
        setUserWithPassword(true);
    });

    useEffect(() => {
        if (email) {
            (async () => {
                const userStatusResp = await execute(CheckUserStatus, { email });
                if (isOK(userStatusResp)) {
                    const userHasPassword =
                        userStatusResp.value.status === 'UserStatusCodeListUserWithPassword';
                    setUserWithPassword(userHasPassword);
                }
            })();
        }
    }, [email]);

    const secondaryNavigationItems = [
        isUserWithPassword === false && (
            <StatusMessage key="warning-profile" status="warning">
                Finish setting up{' '}
                <TextButton
                    data-e2e="user-navigation-set-pwd-link"
                    as={Link}
                    href="/user/change-password"
                >
                    your profile
                </TextButton>
                .
            </StatusMessage>
        ),
        getEnvVar('NOTIFICATION_CENTER_FEATURE_FLAG') && (
            <UiNavigation.Item
                key="nav-notification-center"
                data-e2e="broker-navigation-notification-link"
                as={Link}
                icon="notifications"
                href="/notification-center"
                indicationCount={unreadNotificationCount}
                appearance="button"
            >
                Notifications
            </UiNavigation.Item>
        ),
    ].filter(Boolean);

    return (
        <UiNavigation
            data-e2e="user-sidebar-navigation-wrapper"
            onClick={onClick}
            state={navigationState}
            onToggleState={toggleNavigationState}
        >
            <UiNavigation.Header href="/" as={Link} state={navigationState} />
            <UiNavigation.Body
                split={secondaryNavigationItems.length ? `-${secondaryNavigationItems.length}` : ''}
            >
                <UiNavigation.Item
                    key="nav-home"
                    data-e2e="user-navigation-home-link"
                    as={Link}
                    icon="home"
                    href="/summary"
                >
                    Home
                </UiNavigation.Item>
                <UiNavigation.Item
                    key="nav-shopping"
                    data-e2e="user-navigation-shopping-link"
                    as={Link}
                    icon="shop"
                    href="/shopping"
                >
                    Shop for coverage
                </UiNavigation.Item>
                <UiNavigation.Item
                    key="nav-policies"
                    data-e2e="user-navigation-policies-link"
                    as={Link}
                    icon="policies"
                    href="/policies"
                >
                    Policies
                </UiNavigation.Item>
                <UiNavigation.Item
                    key="nav-payments"
                    data-e2e="user-navigation-payments-link"
                    as={Link}
                    icon="payments"
                    href="/payments"
                >
                    Payments
                </UiNavigation.Item>
                <UiNavigation.Item
                    key="nav-certificates"
                    data-e2e="user-navigation-certs-link"
                    as={Link}
                    icon="certificates"
                    href="/certificates"
                >
                    Certificates
                </UiNavigation.Item>
                <UiNavigation.Item
                    key="nav-claims"
                    data-e2e="user-navigation-claims-link"
                    as={Link}
                    icon="claims"
                    href="/claims"
                >
                    Claims
                </UiNavigation.Item>
                <UiNavigation.Item
                    key="nav-support"
                    data-e2e="user-navigation-support-link"
                    as={Link}
                    icon="support"
                    href="/support"
                >
                    Support
                </UiNavigation.Item>
                {secondaryNavigationItems}
            </UiNavigation.Body>
            <AuthenticatedUserMenu />
        </UiNavigation>
    );
}

function BrokerNavigation({ onClick }: Omit<NavigationProps, 'email'>) {
    const { toggleNavigationState, navigationState = 'open' } = useContext(AppContext);

    return (
        <UiNavigation
            data-e2e="brokerage-sidebar-navigation-wrapper"
            onClick={onClick}
            state={navigationState}
            onToggleState={toggleNavigationState}
        >
            <UiNavigation.Header href="/" as={Link} state={navigationState} subtitle="ACCESS" />
            <UiNavigation.Body>
                <UiNavigation.Item
                    data-e2e="broker-navigation-dashboard-link"
                    as={Link}
                    icon="home"
                    href="/broker/dashboard"
                >
                    Dashboard
                </UiNavigation.Item>
                <UiNavigation.Item
                    data-e2e="broker-navigation-quotes-link"
                    as={Link}
                    icon="shop"
                    href="/broker/quotes"
                >
                    Quotes
                </UiNavigation.Item>
                <UiNavigation.Item
                    data-e2e="broker-navigation-renewals-link"
                    as={Link}
                    icon="renewals"
                    href="/broker/renewals"
                >
                    Renewals
                </UiNavigation.Item>
                <UiNavigation.Item
                    data-e2e="broker-navigation-policies-link"
                    as={Link}
                    icon="policies"
                    href="/broker/policies"
                >
                    Policies
                </UiNavigation.Item>
                <UiNavigation.Item
                    data-e2e="broker-navigation-clients-link"
                    as={Link}
                    icon="clients"
                    href="/broker/companies"
                >
                    Clients
                </UiNavigation.Item>
                <UiNavigation.Item
                    data-e2e="broker-navigation-payments-link"
                    as={Link}
                    icon="payments"
                    href="/broker/unpaid-balances"
                >
                    Payments
                </UiNavigation.Item>
                <UiNavigation.Item
                    data-e2e="broker-navigation-support-link"
                    as={Link}
                    icon="support"
                    href="/support"
                >
                    Support
                </UiNavigation.Item>
            </UiNavigation.Body>
            <AuthenticatedBrokerUserMenu />
        </UiNavigation>
    );
}

export function Navigation() {
    const { activeSession, navigationState, toggleNavigationState, setNavigation } =
        useContext(AppContext);

    const {
        url: { pathname: currentRoutePathname },
    } = useCurrentRoute();

    const isBroker = hasRole(activeSession, 'broker');
    const { isAuthenticated } = activeSession;

    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            const hasHref = e.nativeEvent
                .composedPath()
                .some(
                    (el) =>
                        el instanceof HTMLAnchorElement &&
                        el.hasAttribute('href') &&
                        el.getAttribute('href') !== '#',
                );

            if (navigationState === 'open' && hasHref) {
                toggleNavigationState();
            }
        },
        [navigationState, toggleNavigationState],
    );

    const prevCurrentRoutePathname = useRef<string | null>(null);
    const prevSetNavigation = usePrevious(setNavigation);
    const prevNavigationState = usePrevious(navigationState);

    useEffect(() => {
        // This logic is to ensure that the navigation is updated only on route changes
        // and when we have the setNavigation methond initialized
        if (
            prevCurrentRoutePathname.current !== currentRoutePathname ||
            prevSetNavigation !== setNavigation ||
            prevNavigationState !== navigationState
        ) {
            const isOnboardingFlow = currentRoutePathname.includes(USER_ONBOARDING_BASE_ROUTE);
            const isAuthenticatedNavigation = isAuthenticated && !isOnboardingFlow;

            if (isBroker) {
                setNavigation(<BrokerNavigation onClick={handleClick} />);
            } else if (isAuthenticatedNavigation) {
                setNavigation(<AuthenticatedNavigation onClick={handleClick} />);
            } else {
                setNavigation(null);
            }
        }

        prevCurrentRoutePathname.current = currentRoutePathname;
    }, [
        isBroker,
        isAuthenticated,
        currentRoutePathname,
        prevNavigationState,
        navigationState,
        setNavigation,
        prevSetNavigation,
        handleClick,
    ]);

    return null;
}
