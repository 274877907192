import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ApplicationRepository } from '../repositories/ApplicationRepository';

interface SubmitApplicationRequest {
    applicationId: UUID;
}

interface SubmitApplicationResponse {
    taskId: UUID;
}

export interface SubmitApplication extends UseCase {
    execute(
        request: SubmitApplicationRequest,
    ): AsyncResult<SubmitApplicationResponse, InvalidArgument | OperationFailed>;
}

class SubmitApplicationUseCase extends UseCase implements SubmitApplication {
    public static type = Symbol('Shopping/SubmitApplication');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: SubmitApplicationRequest,
    ): AsyncResult<SubmitApplicationResponse, InvalidArgument | OperationFailed> {
        const userTzOffsetMinutes = new Date(Date.now()).getTimezoneOffset();

        const taskId = await this.applicationRepository.submitApplication(
            request.applicationId,
            -userTzOffsetMinutes,
        );

        if (isErr(taskId)) {
            return taskId;
        }
        return Success({
            taskId: taskId.value,
        });
    }
}

export const SubmitApplication: UseCaseClass<SubmitApplication> = SubmitApplicationUseCase;
