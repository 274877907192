import { inject, injectable } from '@embroker/shotwell/core/di';
import { JSONSerdes } from '@embroker/shotwell/core/encoding';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { AsyncResult, Failure, isErr, isOK, Success } from '@embroker/shotwell/core/types/Result';
import { RevenueList } from '@embroker/shotwell/core/types/RevenueList';
import { State } from '@embroker/shotwell/core/types/StateList';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ApplicantRepository } from '../repositories/ApplicantRepository';
import { ApplicationRepository } from '../repositories/ApplicationRepository';
import { LPLAreaOfPracticeCode } from '../types/enums';
import { LawBundleQuestionnaireData } from '../types/LawBundleQuestionnaireData';
import { LPLAreaOfPractice } from '../types/LPLAreaOfPractice';

export interface GetLawBundlePrefillRequest {
    applicationId?: UUID;
}

export interface GetLawBundlePrefillResponse {
    numberOfAttorneys?: number;
    revenue?: Money;
    areasOfPractice?: LPLAreaOfPractice[];
    stateWithMostAttorneys?: State;
}

export interface GetLawBundlePrefill extends UseCase {
    execute(
        request: GetLawBundlePrefillRequest,
    ): AsyncResult<GetLawBundlePrefillResponse, InvalidArgument | OperationFailed>;
}

@injectable()
class GetLawBundlePrefillUseCase extends UseCase implements GetLawBundlePrefill {
    public static type = Symbol('Shopping/GetLawBundlePrefill');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
        @inject(ApplicantRepository) private applicantRepository: ApplicantRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
    }: GetLawBundlePrefillRequest): AsyncResult<
        GetLawBundlePrefillResponse,
        InvalidArgument | OperationFailed
    > {
        let result: GetLawBundlePrefillResponse = {};
        if (applicationId) {
            const applicationResult = await this.applicationRepository.getApplication(
                applicationId,
            );
            if (isErr(applicationResult)) {
                return applicationResult;
            }
            const deserializeResult = JSONSerdes.deserialize(
                applicationResult.value.questionnaireData ?? '{}',
            );
            if (isErr(deserializeResult)) {
                return Failure(OperationFailed({ errors: deserializeResult.errors }));
            }
            const validationResult = LawBundleQuestionnaireData.validate(deserializeResult.value);
            if (isErr(validationResult)) {
                return Failure(OperationFailed({ errors: validationResult.errors }));
            }
            const questionnaireData = validationResult.value;

            result = mapQuestionnaireDataToPrefill(questionnaireData);
        }

        const applicantResult = await this.applicantRepository.getApplicant();
        if (isErr(applicantResult)) {
            return Failure(
                OperationFailed({
                    errors: applicantResult.errors,
                    message: 'Failed to fetch applicant details',
                }),
            );
        }
        const { revenueList } = applicantResult.value;
        if (revenueList && revenueList.length > 0) {
            const revenueListResult = RevenueList.validate(revenueList);
            if (isOK(revenueListResult)) {
                const currentRevenue = RevenueList.getLastFinancialYearRevenue(
                    revenueListResult.value,
                );
                result.revenue = currentRevenue?.grossTotal ?? undefined;
            }
        }

        if (!applicationId) {
            result.stateWithMostAttorneys = applicantResult.value.headquarters.state ?? undefined;
        }

        return Success(result);
    }
}

function mapQuestionnaireDataToPrefill(
    questionnaireData: Immutable<LawBundleQuestionnaireData>,
): GetLawBundlePrefillResponse {
    const prefill: GetLawBundlePrefillResponse = {};

    prefill.revenue = questionnaireData.gross_revenue_total
        ? Money.tryFromFloat(questionnaireData.gross_revenue_total)
        : undefined;
    prefill.areasOfPractice = toLPLAreaOfPracticeList(questionnaireData.areas_of_practice);
    prefill.stateWithMostAttorneys = questionnaireData.state_with_most_attorneys;
    prefill.numberOfAttorneys = questionnaireData.totalAttorneysThisYear;

    return prefill;
}

function toLPLAreaOfPracticeList(
    areasOfPracticeData?: Immutable<{
        areas_of_practice_rows: { area: string; percentage: number }[];
    }>,
): LPLAreaOfPractice[] {
    const areaOfPracticeList: LPLAreaOfPractice[] = [];
    if (areasOfPracticeData && areasOfPracticeData.areas_of_practice_rows.length > 0) {
        areasOfPracticeData.areas_of_practice_rows.forEach((areaOfPractice) => {
            const newItem = {
                areaOfPracticeCode: areaOfPractice.area as LPLAreaOfPracticeCode,
                percentage: areaOfPractice.percentage,
            };
            const validationResult = LPLAreaOfPractice.create(newItem);
            if (isOK(validationResult)) {
                areaOfPracticeList.push(newItem);
            }
        });
    }

    return areaOfPracticeList;
}

export const GetLawBundlePrefill: UseCaseClass<GetLawBundlePrefill> = GetLawBundlePrefillUseCase;
