import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { CyberQuote } from '../../entities/CyberQuote';
import { CyberQuoteOptions } from '../../types/CyberQuoteOptions';

export interface PurchaseResponse {
    policyId: UUID;
    policyDoneTaskId: UUID;
}

export interface CyberQuoteRepository {
    getLastCyberQuote(
        applicationId: UUID,
    ): AsyncResult<CyberQuote, InvalidArgument | OperationFailed>;

    reQuoteCyber(
        applicationId: UUID,
        lplQuoteOptions: CyberQuoteOptions,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed>;

    purchaseCyber(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<PurchaseResponse, UnknownEntity | InvalidArgument | OperationFailed>;

    createQuoteSummaryAsyncTask(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed>;

    createSpecimenPolicyAsyncTask(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed>;
}

export const CyberQuoteRepository = Symbol('CyberQuoteRepository');
