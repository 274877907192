import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ESPQuoteRepository } from '../repositories/ESPQuoteRepository';

export interface GetESPPurchasedCoveragesListRequest {
    applicationId: UUID;
}

export interface GetESPPurchasedCoveragesList extends UseCase {
    execute(
        request: GetESPPurchasedCoveragesListRequest,
    ): AsyncResult<string[], InvalidArgument | OperationFailed>;
}

@injectable()
class GetESPPurchasedCoveragesListUseCase extends UseCase implements GetESPPurchasedCoveragesList {
    public static type = Symbol('ESPQuote/GetESPPurchasedCoveragesList');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ESPQuoteRepository) private espQuoteRepository: ESPQuoteRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
    }: GetESPPurchasedCoveragesListRequest): AsyncResult<
        string[],
        InvalidArgument | OperationFailed
    > {
        return await this.espQuoteRepository.getESPPurchasedCoveragesList(applicationId);
    }
}

export const GetESPPurchasedCoveragesList: UseCaseClass<GetESPPurchasedCoveragesList> =
    GetESPPurchasedCoveragesListUseCase;
