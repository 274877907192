import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { cast } from '@embroker/shotwell/core/types/Nominal';
import { AsyncResult, isErr, isOK, Success } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { ErrorPage } from '../../../view/components/ErrorPage.view';
import { map, mount, NaviRequest, redirect, route, withView } from 'navi';
import * as React from 'react';
import { AppContext } from '../../../view/AppContext';
import { RouteDefinitions } from '../../../view/routes/Route';
import { isBundlePayment, Payment } from '../../types/Payment';
import { GetPremiumFinanceQuote } from '../../useCases/GetPremiumFinanceQuote';
import { GetPremiumFinanceTaskIds } from '../../useCases/GetPremiumFinanceTaskIds';
import { GetSelectedInvoiceList } from '../../useCases/GetSelectedInvoiceList';
import {
    SendIneligiblePayments,
    SendIneligiblePaymentsRequest,
} from '../../useCases/SendIneligiblePayments';
import { FAQ } from '../components/FAQ';
import { PaymentsCarriersDue } from '../components/PaymentsCarriersDue';
import { buildInvoiceIdList, PaymentsDashboard } from '../components/PaymentsDashboard';
import { PaymentsHistory } from '../components/PaymentsHistory';
import { PaymentsPage } from '../components/PaymentsPage';
import { EligibilityPage } from '../components/premiumFinance/EligibilityPage';
import { PremiumFinanceDashboard } from '../components/premiumFinance/PremiumFinanceDashboard';
import { BundlePublicPayment } from '../components/publicPayment/BundlePublicPayment';
import { PublicPayment } from '../components/publicPayment/PublicPayment';
import { PublicPaymentThankYou } from '../components/publicPayment/PublicPaymentThankYou';
import { PaymentsErrorPage } from '../components/PaymentsErrorPage';

interface PremiumFinanceData {
    selectedInvoiceIdList: Immutable<UUID[]>;
    eligibleInvoices: Immutable<Payment[]>;
    notEligibleInvoices: Immutable<Payment[]>;
}

const PAYMENTS_TITLE = 'Embroker | Payments';

/*
    Sometimes public key parameter in URL is displayed in different ways.
    This function covers all known variations and returns the one that is used.
*/
function resolvePublicKey(query: Record<string, string | undefined>): string | undefined {
    const { publicKey, publickey, public_key } = query;
    return publicKey || publickey || public_key;
}

function buildInvoiceNumberList(idList: string[], invoice: Immutable<Payment>): string[] {
    if (isBundlePayment(invoice)) {
        return [...idList, ...invoice.invoiceList.map((invoice) => invoice.invoiceNumber)];
    }
    return [...idList, invoice.invoiceNumber];
}

async function getPremiumFinanceData(
    request: NaviRequest,
): AsyncResult<PremiumFinanceData, InvalidArgument | OperationFailed> {
    const selectedInvoiceIdList = (request.query['selectedInvoiceIdList'] ?? '')
        .split(',')
        .map((id) => {
            const validationResult = UUID.validate(id);
            if (isOK(validationResult)) {
                return validationResult.value;
            }
            return false;
        })
        .filter(Boolean) as UUID[];

    const result = await execute(GetSelectedInvoiceList, {
        invoiceIdList: selectedInvoiceIdList,
    });

    if (isErr(result)) {
        return result;
    }

    const selectedUIInvoiceList = result.value.UIInvoiceList;

    const eligibleInvoices = selectedUIInvoiceList.filter((item) => {
        return !item.isNotEligibleForFinancing && !item.isEndorsement;
    });

    const notEligibleInvoices = selectedUIInvoiceList.filter((item) => {
        return item.isNotEligibleForFinancing || item.isEndorsement;
    });

    return Success<PremiumFinanceData>({
        selectedInvoiceIdList: selectedInvoiceIdList,
        eligibleInvoices: eligibleInvoices,
        notEligibleInvoices: notEligibleInvoices,
    });
}

export const routes: RouteDefinitions<AppContext> = {
    '/payments': withView(
        PaymentsPage,
        mount<AppContext>({
            '/': route({
                title: PAYMENTS_TITLE,
                view: PaymentsDashboard,
                data: { hideHeader: true },
            }),
            '/carriers': route({
                title: PAYMENTS_TITLE,
                view: PaymentsCarriersDue,
                data: { hideHeader: true },
            }),
            '/history': route({
                title: PAYMENTS_TITLE,
                view: PaymentsHistory,
                data: { hideHeader: true },
            }),
            '/faqs': route({
                title: PAYMENTS_TITLE,
                view: FAQ,
                data: { hideHeader: true },
            }),
        }),
    ),
    '/payments/finance/are-you-eligible': map(async (request) => {
        const { selectedInvoiceIdList } = request.query;
        const result = await getPremiumFinanceData(request);

        if (result === undefined || isErr(result)) {
            return route({
                view: <ErrorPage errors={result.errors} />,
            });
        }

        if (!result.value.notEligibleInvoices.length) {
            return redirect(
                URI.build('/payments/finance/', { selectedInvoiceIdList: selectedInvoiceIdList }),
            );
        }

        const ineligibleInvoiceList = result.value.notEligibleInvoices.reduce(
            (idList, invoice) => buildInvoiceNumberList(idList, invoice),
            new Array<string>(),
        );

        const ineligibleData: SendIneligiblePaymentsRequest = {
            paymentIds: result.value.notEligibleInvoices.map((item) => item.id),
            invoiceList: ineligibleInvoiceList,
            organizationID: cast<UUID>(request.context.activeSession.organizationId as UUID),
        };

        const ineligibleEventResult = await execute(SendIneligiblePayments, ineligibleData);

        if (ineligibleEventResult === undefined || isErr(ineligibleEventResult)) {
            return route({
                view: <ErrorPage errors={ineligibleEventResult.errors} />,
            });
        }

        return route<AppContext>(async () => {
            return {
                title: 'Embroker | Payments',
                view: (
                    <EligibilityPage
                        selectedInvoiceIdList={selectedInvoiceIdList}
                        eligibleInvoices={result.value.eligibleInvoices}
                        notEligibleInvoices={result.value.notEligibleInvoices}
                    />
                ),
                data: { hideHeader: true },
            };
        });
    }),
    '/payments/finance': map(async (request) => {
        const result = await getPremiumFinanceData(request);

        if (result === undefined || isErr(result)) {
            return route({
                view: <ErrorPage errors={result.errors} />,
            });
        }

        const isEligibilityPageShown = result.value.notEligibleInvoices.length !== 0;

        const eligibleInvoiceList = result.value.eligibleInvoices.reduce(
            (idList, invoice) => buildInvoiceIdList(idList, invoice),
            new Array<UUID>(),
        );

        const taskIds = await execute(GetPremiumFinanceTaskIds, {
            invoiceIds: eligibleInvoiceList,
        });

        if (isErr(taskIds)) {
            return route({
                view: <ErrorPage errors={taskIds.errors} />,
            });
        }

        const quotes = await execute(GetPremiumFinanceQuote, {
            taskMonthly: taskIds.value.taskMonthly,
            taskQuarterly: taskIds.value.taskQuarterly,
        });

        if (isErr(quotes)) {
            return route({
                view: <ErrorPage errors={quotes.errors} />,
            });
        }

        return route({
            title: 'Embroker | Payments',
            view: (
                <PremiumFinanceDashboard
                    selectedInvoiceIdList={result.value.selectedInvoiceIdList}
                    backPage={isEligibilityPageShown}
                    eligibleInvoices={result.value.eligibleInvoices}
                    quotes={quotes.value}
                />
            ),
            data: { hideHeader: true },
        });
    }),
    /*
     * Public payment url route
     */
    '/payments/stripe/payment': {
        auth: 'none',
        isOrganizationSpecific: false,
        handler: map((request: NaviRequest<AppContext>) => {
            const result = resolvePublicKey(request.query);
            return route({
                title: 'Public payment',
                view: <PublicPayment publicKey={result as UUID} />,
            });
        }),
    },
    '/payments/bundle/payment': {
        auth: 'none',
        isOrganizationSpecific: false,
        handler: map((request: NaviRequest<AppContext>) => {
            const result = resolvePublicKey(request.query);
            return route({
                title: 'Bundle public payment',
                view: <BundlePublicPayment publicKey={result as UUID} />,
            });
        }),
    },
    '/payments/stripe/payment/thank-you': {
        auth: 'none',
        isOrganizationSpecific: false,
        handler: route({
            title: 'Public payment',
            view: <PublicPaymentThankYou />,
        }),
    },
    //TODO remove route - backwards compatibility old route
    '/payments/stripe/payment/:publicKey': {
        auth: 'none',
        isOrganizationSpecific: false,
        handler: map(async (request) => {
            const publicKey = request.params.publicKey;
            return redirect(URI.build('/payments/stripe/payment', { publicKey }));
        }),
    },
    '/payments/bundle/payment/:publicKey': {
        auth: 'none',
        isOrganizationSpecific: false,
        handler: map(async (request) => {
            const publicKey = request.params.publicKey;
            return redirect(URI.build('/payments/bundle/payment', { publicKey }));
        }),
    },
    '/payments/error-page': route({
        title: PAYMENTS_TITLE,
        view: PaymentsErrorPage,
    }),
    '/payments/invoice-error-page': route({
        title: PAYMENTS_TITLE,
        view: <PaymentsErrorPage isInvoiceError />,
    }),
};
