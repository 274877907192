import { Immutable } from '@embroker/shotwell/core/types';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { LPLAttorneyInfo } from './LPLAttorneyInfo';
import { LPLHigherLimit } from './LPLHigherLimit';
import { LPLUserInfo } from './LPLUserInfo';

export interface LPLQuoteInfo {
    readonly currentRetroactiveDate?: Date;
    readonly hasCurrentPolicy: boolean;
    readonly currentPolicyEndDate?: Date;
    readonly totalAttorneys: number;
    readonly stateWithLargestNumber: string;
    readonly userInfo: LPLUserInfo;
    readonly isRenewal: boolean;
    readonly isStreamlineRenewal: boolean;
    readonly attorneyList: Immutable<LPLAttorneyInfo[]>;
    readonly higherLimit?: Immutable<LPLHigherLimit>;
    readonly disableEditApp?: boolean;
}

export const LPLQuoteInfo = {
    ...defineValidator<LPLQuoteInfo>({
        currentRetroactiveDate: Joi.date().optional(),
        hasCurrentPolicy: Joi.boolean().required(),
        currentPolicyEndDate: Joi.date().optional(),
        totalAttorneys: Joi.number().required(),
        stateWithLargestNumber: Joi.string().required(),
        userInfo: LPLUserInfo.schema.required(),
        isRenewal: Joi.boolean().required(),
        isStreamlineRenewal: Joi.boolean().required(),
        attorneyList: Joi.array().items(LPLAttorneyInfo.schema).required(),
        higherLimit: LPLHigherLimit.schema.optional(),
        disableEditApp: Joi.boolean().optional(),
    }),
    create(lplQuoteInfo: LPLQuoteInfo) {
        return LPLQuoteInfo.validate(lplQuoteInfo);
    },
};
