export const PolicyStatusInSeriesExpired = 'expired';
export const PolicyStatusInSeriesRenewal = 'renewal';
export const PolicyStatusInSeriesCurrent = 'current';

export const PolicyStatusInSeriesList = [
    PolicyStatusInSeriesExpired,
    PolicyStatusInSeriesRenewal,
    PolicyStatusInSeriesCurrent,
] as const;

type PolicyStatusInSeriesList = typeof PolicyStatusInSeriesList;

export type PolicyStatusInSeries = PolicyStatusInSeriesList[number];
