import { Joi, SchemaType } from '@embroker/shotwell/core/validation/schema';
import { MutuallyExclusiveProps } from '@embroker/ui-toolkit/v2';
import { FormQuestionDefinition } from '../hooks/useDataDrivenForm';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { ErrorMsgObject, INVALID_OPTIONAL_ARRAY } from './validationObject';
import { ComplexFormFieldDefinitionMap, isComplexQuestionType } from './ComplexFieldTypes';
import {
    buildConditionalValidator,
    ConditionalValidationDefinition,
} from './ConditionalValidation';
import { DEFAULT_EMPTY_VALUE } from '../components/MultipleFormFieldView.view';

export type ValidationAggregatorTypes = 'any' | 'sum';

export type ValidationOperatorTypes =
    | keyof CurrencyValidationDefinition
    | keyof NumberValidationDefinition
    | keyof StringValidationDefinition
    | keyof BooleanValidationDefinition;

export type ValidationTypes = keyof ValidationTypeProps;

export type BooleanValidationDefinition = { isTrue: boolean };

export type CurrencyValidationDefinition = {
    lessThan?: number;
    greaterThan?: number;
    equal?: number;
};

export type NumberValidationDefinition = {
    lessThan?: number;
    greaterThan?: number;
    lessThanEqualTo?: number;
    greaterThanEqualTo?: number;
    equal?: number;
};
export function isNumberOperator(
    definition: string,
): definition is keyof NumberValidationDefinition {
    return ['lessThan', 'greaterThan', 'lessThanEqualTo', 'greaterThanEqualTo', 'equal'].some(
        (key) => key === definition,
    );
}

export type StringValidationDefinition = {
    hasLengthGreaterThan?: number;
    hasLengthLessThan?: number;
    regex?: { pattern: RegExp | string; message?: string };
    equal?: string;
    includes?: string[];
};
export function isStringOperator(
    definition: string,
): definition is keyof StringValidationDefinition {
    return ['hasLengthGreaterThan', 'hasLengthLessThan', 'pattern', 'equal', 'includes'].some(
        (key) => key === definition,
    );
}

export type ValidationKeys =
    | keyof StringValidationDefinition
    | keyof NumberValidationDefinition
    | keyof BooleanValidationDefinition;

export type ValidationDefinitionTypes =
    | BooleanValidationDefinition
    | StringValidationDefinition
    | NumberValidationDefinition;

export type ValidationTypeProps = MutuallyExclusiveProps<{
    boolean: BooleanValidationDefinition;
    string: StringValidationDefinition;
    number: NumberValidationDefinition;
    currency: CurrencyValidationDefinition;
    conditional: ConditionalValidationDefinition;
}>;

export const buildBooleanValidator = (
    validationProps: BooleanValidationDefinition,
): SchemaType<any> => {
    let booleanValidator = Joi.boolean();

    const { isTrue } = validationProps;

    booleanValidator = isTrue ? booleanValidator.valid(true) : booleanValidator.valid(false);
    booleanValidator = booleanValidator.custom((value, { prefs: { context }, error }) =>
        error(`boolean.${isTrue}`),
    );

    return booleanValidator;
};

export const buildStringValidator = (
    validationProps: StringValidationDefinition,
): SchemaType<any> => {
    let stringValidator = Joi.string();

    const { hasLengthGreaterThan, hasLengthLessThan, regex, equal, includes } = validationProps;
    if (hasLengthGreaterThan) {
        const minLength = hasLengthGreaterThan + 1;
        stringValidator = stringValidator.min(minLength);
    }

    if (hasLengthLessThan) {
        const maxLength = hasLengthLessThan - 1;
        stringValidator = stringValidator.max(maxLength);
    }

    if (regex) {
        const { pattern, message } = regex;
        const validator =
            typeof pattern === 'string'
                ? Joi.string().pattern(RegExp(pattern))
                : Joi.string().pattern(pattern);

        stringValidator = stringValidator.custom((value, helpers) =>
            !validator.validate(value).error
                ? value
                : helpers.error(message ? `string.regex.${message}` : 'string.pattern'),
        );
    }

    if (equal) {
        stringValidator = stringValidator.valid(equal);
    }

    if (includes) {
        return Joi.custom((value, helpers) =>
            includes.includes(value) ? value : helpers.error('array.includes'),
        );
    }

    return stringValidator;
};

export const buildNumberValidator = (
    validationProps: NumberValidationDefinition,
): SchemaType<any> => {
    let numberValidator = Joi.number();

    const { lessThan, greaterThan, equal, lessThanEqualTo, greaterThanEqualTo } = validationProps;

    if (lessThan) {
        numberValidator = numberValidator.max(lessThan - 1);
    }

    if (lessThanEqualTo) {
        numberValidator = numberValidator.max(lessThanEqualTo);
    }

    if (greaterThan) {
        numberValidator = numberValidator.min(greaterThan + 1);
    }

    if (greaterThanEqualTo) {
        numberValidator = numberValidator.min(greaterThanEqualTo);
    }

    if (equal) {
        numberValidator = numberValidator.valid(equal);
    }

    return numberValidator;
};

export const buildFieldValidator = (props: Omit<ValidationTypeProps, 'conditional'>) => {
    let validator = Joi.any();
    const { string, number, boolean, currency } = props;
    if (string) {
        validator = validator.concat(buildStringValidator(string));
    }
    if (number) {
        validator = validator.concat(buildNumberValidator(number));
    }
    if (boolean) {
        validator = validator.concat(buildBooleanValidator(boolean));
    }
    if (currency) {
        // We treat currency as a number within the form itslef, therefore we can build validations using buildNumberValidator
        validator = validator.concat(buildNumberValidator(currency));
    }

    return validator;
};

export const buildValidator = (
    questionKey: string,
    formQuestionDefinitions: FormQuestionDefinition[],
): {
    validator: SchemaType<any>;
    formatValidationError: (error: InvalidArgument) => string;
} => {
    const defaultValidator = Joi.any();
    const defaultFormatValidationError = (error: InvalidArgument) =>
        ErrorMsgObject.getValidationMessage(error, validate);

    const question = formQuestionDefinitions.find(
        (questionDefinition) => questionDefinition.key === questionKey,
    );

    // Since the .find method above will return FormQuestionDefinition | undefined we need a fallback here, in reality this should never be undefined
    if (!question) {
        return {
            validator: Joi.any().optional(),
            formatValidationError: defaultFormatValidationError,
        };
    }

    const { validate, isRequired, isMultiple, questionType } = question;

    let validator = defaultValidator;
    let formatValidationError = defaultFormatValidationError;

    if (isComplexQuestionType(questionType)) {
        validator = ComplexFormFieldDefinitionMap[questionType].validator;
        formatValidationError = ComplexFormFieldDefinitionMap[questionType].formatValidationError;
    }

    if (validate && Array.isArray(validate)) {
        for (const definition of validate) {
            const { conditional } = definition;
            const externalQuestionDefinition = formQuestionDefinitions.find(
                ({ key }) => key === conditional?.externalQuestionProps.questionKey,
            );
            if (conditional && externalQuestionDefinition) {
                const conditionalValidator = buildConditionalValidator({
                    conditionalValidationDefinition: conditional,
                    questionDefinition: question,
                    externalQuestionDefinition,
                });
                validator = validator.concat(conditionalValidator);
            } else {
                const fieldValidator = buildFieldValidator(definition);
                validator = validator.concat(fieldValidator);
            }
        }
    }

    if (isMultiple) {
        return {
            validator: getValidatorAsArrayValidation(validator, isRequired),
            formatValidationError,
        };
    }

    if (isRequired) {
        validator = getValidatorAsRequired(validator);
    } else {
        validator = validator.optional();
    }

    return { validator, formatValidationError };
};

export const getValidatorAsArrayValidation = (
    validator: SchemaType<unknown>,
    isRequired?: boolean,
): SchemaType<unknown> => {
    if (isRequired) {
        return Joi.array().items(getValidatorAsRequired(validator)).required();
    }
    const arrayValidator = Joi.array().items(getValidatorAsRequired(validator));
    return Joi.array().custom((value, { error }) => {
        const requiresValidation =
            isRequired || value.some((v: any) => ![DEFAULT_EMPTY_VALUE, ''].includes(v));

        if (requiresValidation && arrayValidator.validate(value).error) {
            return error(INVALID_OPTIONAL_ARRAY);
        }
        return value;
    });
};

export const getValidatorAsRequired = (validator: SchemaType<unknown>): SchemaType<unknown> => {
    return validator.invalid(null, '').required();
};

export const getFieldValidation = (
    questionKey: string,
    formQuestionDefinitions: FormQuestionDefinition[],
): {
    validator: SchemaType<any>;
    formatValidationError: (error: InvalidArgument) => string;
} => {
    const { validator: baseValidator, formatValidationError } = buildValidator(
        questionKey,
        formQuestionDefinitions,
    );

    return {
        validator: baseValidator,
        formatValidationError,
    };
};
