import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { Coverage } from '../../../shopping/types/Coverage';
import { NumberRangeOfW2Employees } from '../../../shopping/types/enums';

export interface GetRecommendedRequest {
    naicsCode: string;
    hasRaisedFunding?: boolean;
    isTotalRevenueLargerThan20MillionDollars?: boolean;
    numberRangeOfW2Employees?: NumberRangeOfW2Employees;
    hasAutomobiles?: boolean;
    employeeCount?: number;
}

export interface RecommendRepository {
    getRecommended(
        input: GetRecommendedRequest,
    ): AsyncResult<Array<Coverage>, InvalidArgument | OperationFailed>;
}

export const RecommendRepository = Symbol('RecommendRepository');
