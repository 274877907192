import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { ErrorLike, isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import {
    TextButton,
    Modal,
    PageLayout,
    StackLayout,
    StatusMessage,
    Text,
    useModal,
    ScrollBox,
} from '@embroker/ui-toolkit/v2';
import React, { useEffect, useMemo, useCallback, useContext, useState } from 'react';
import { InsuranceApplicationRestriction } from '../../../../quote/esp/types/InsuranceApplicationRestriction';
import { hasRole } from '../../../../userOrg/entities/Session';
import { AppContext } from '../../../../view/AppContext';
import { Link } from '../../../../view/components';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { ErrorCode } from '../../../errors';
import {
    ESPEndorsementLiabilityCoverage,
    ESPEndorsementPolicy,
    ESPEndorsementPolicyAddressData,
} from '../../types/ESPEndorsementPolicy';
import { ESPEndorsementUserData } from '../../types/ESPEndorsementUserData';
import { ESPEndorsementAddressSection } from './ESPEndorsementAddressSection.view';
import { ESPEndorsementCoverageListSection } from './ESPEndorsementCoverageListSection';
import { ESPEndorsementErrorModalContent } from './ESPEndorsementErrorModalContent.view';
import { ESPEndorsementInvoiceDoneModalContent } from './ESPEndorsementInvoiceDoneModalContent.view';
import { ESPEndorsementNamedInsuredSection } from './ESPEndorsementNamedInsuredSection';
import { ChangeAddressESPEndorsement } from '../../useCases/ChangeAddressESPEndorsement';
import { ESPEndorsementPolicyUpdatedModalContent } from './ESPEndorsementPolicyUpdatedModalContent.view';
import {
    ESPEndorsementAddress,
    ESPEndorsementChangeAddressFormData,
} from './ESPEndorsementAddress.view';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';

interface ESPEndorsementPageProps {
    policyId: UUID;
    policy: Immutable<ESPEndorsementPolicy>;
    userData: ESPEndorsementUserData;
    restrictions?: Immutable<InsuranceApplicationRestriction>;
}

interface EspEndorsementPageState {
    effectivePeriodStart: Date;
    effectivePeriodEnd: Date;
    namedInsured: string;
    addressData: ESPEndorsementPolicyAddressData;
    availableLiabilities: Immutable<Array<ESPEndorsementLiabilityCoverage>>;
    submittedAt: Nullable<string>;
    isEPLIEligible: boolean;
    isDNOEligible: boolean;
    isFiduciaryEligible: boolean;
    inRunoff: boolean;
}

export function ESPEndorsementPage({
    policyId,
    policy,
    userData,
    restrictions,
}: ESPEndorsementPageProps) {
    const { activeSession } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');
    const isAdmin = hasRole(activeSession, 'admin');
    const [errorModalMessage, setErrorModalMessage] = useState<string | undefined>();
    const [state, setState] = useState<EspEndorsementPageState>({
        ...policy,
    });

    const isPolicyInReferredState = policy.isReferred != undefined && policy.isReferred;
    const isPolicyInRunoff = policy.inRunoff;
    const isEndorsementDisabled =
        (!isAdmin && !userData.title) || isPolicyInReferredState || isPolicyInRunoff;

    const { navigate } = useNavigation();
    const handleGoToContactUs = useCallback(() => {
        navigate('/support/contact');
    }, [navigate]);

    const handleGoToUserProfile = useCallback(() => {
        navigate('/user/profile');
    }, [navigate]);

    const addressModal = useModal();
    // non premium endorsement
    const policyUpdatedModal = useModal();
    // premium endorsement success
    const invoiceDoneModal = useModal();
    const errorModal = useModal();

    const abortController = useMemo(() => {
        return new AbortController();
    }, []);

    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, [abortController]);

    const handleUpdatePolicy = useCallback(
        (updatedPolicy: ESPEndorsementPolicy) => {
            setState((prevState: EspEndorsementPageState) => ({
                ...prevState,
                ...updatedPolicy,
            }));

            policyUpdatedModal.show();
        },
        [policyUpdatedModal],
    );

    const handleAddressSave = async (
        changeAddressFormData: ESPEndorsementChangeAddressFormData,
    ) => {
        addressModal.hide();

        const result = await execute(ChangeAddressESPEndorsement, {
            policyId,
            addressData: {
                addressLine1: changeAddressFormData.address.addressLine1 as string,
                addressLine2: changeAddressFormData.address.addressLine2 as string,
                city: changeAddressFormData.address.city as string,
                zipCode: changeAddressFormData.address.zip as ZipCode,
            },
            effectiveDate: changeAddressFormData.effectiveDate as Date,
            abortSignal: abortController.signal,
        });

        if (isErr(result)) {
            handleFailure(result.errors);
        } else if (isOK(result)) {
            handleUpdatePolicy(result.value as ESPEndorsementPolicy);
        }

        return result;
    };

    const handlePurchaseEndorsementSuccess = useCallback(
        (updatedPolicy: ESPEndorsementPolicy) => {
            setState((prevState: EspEndorsementPageState) => ({
                ...prevState,
                ...updatedPolicy,
            }));
            invoiceDoneModal.show();
        },
        [invoiceDoneModal],
    );

    const handleFailure = useCallback(
        (errors: Immutable<ErrorLike[]>) => {
            for (const error of errors) {
                const errorCode = error.code;
                switch (errorCode) {
                    case ErrorCode.DateNotInSequence:
                        setErrorModalMessage(
                            'Endorsement effective date should be greater than the prior endorsement effective date.',
                        );
                        break;
                    case ErrorCode.NoChanges:
                        setErrorModalMessage('No changes were made.');
                        break;
                }
            }
            errorModal.show();
        },
        [errorModal],
    );

    const handleClosePolicyUpdatedModal = useCallback(() => {
        policyUpdatedModal.hide();
    }, [policyUpdatedModal]);

    const handleCloseInvoiceDoneModal = useCallback(() => {
        invoiceDoneModal.hide();
    }, [invoiceDoneModal]);

    const handleCloseErrorModal = useCallback(() => {
        errorModal.hide();
    }, [errorModal]);

    return (
        <PageLayout.Section>
            <StackLayout>
                <StackLayout gap="16">
                    <StackLayout gap="32">
                        <span />
                        <Text style="heading 2">Make changes to your Startup Package</Text>
                        <span />
                    </StackLayout>
                    {!userData.title && !isBroker ? (
                        <StatusMessage status="warning">
                            To make endorsements available please enter your title in the{' '}
                            <TextButton onClick={handleGoToUserProfile}>user profile.</TextButton>
                        </StatusMessage>
                    ) : null}
                    {isPolicyInReferredState && (
                        <StatusMessage status="warning">
                            Your policy is now in a state of referral. This means that you won't be
                            able to automatically add an endorsement (except to change your
                            address), renew your policy, or purchase a new one. Please contact{' '}
                            <Link href="/support/contact">our customer success</Link> team if you
                            have any questions.
                        </StatusMessage>
                    )}
                    {isPolicyInRunoff ? (
                        <StatusMessage status="info">
                            Tail coverage (also known as an 'extended reporting period') is a type
                            of policy endorsement. Once tail coverage is purchased, it means you are
                            in a runoff period, and cannot use other types of endorsements.
                        </StatusMessage>
                    ) : null}
                    {!isBroker || isAdmin ? (
                        <ESPEndorsementCoverageListSection
                            policyId={policyId}
                            restrictions={isAdmin ? undefined : restrictions}
                            effectivePeriodStart={state.effectivePeriodStart}
                            effectivePeriodEnd={state.effectivePeriodEnd}
                            availableLiabilities={state.availableLiabilities}
                            submittedAt={state.submittedAt}
                            isEPLIEligible={state.isEPLIEligible}
                            isDNOEligible={state.isDNOEligible}
                            isFiduciaryEligible={state.isFiduciaryEligible}
                            onPurchaseEndorsementSuccess={handlePurchaseEndorsementSuccess}
                            onPurchaseEndorsementFailure={handleFailure}
                            userData={userData}
                            isEndorsementDisabled={isEndorsementDisabled}
                            hasRenewalApplication={policy.hasRenewalApplication}
                        />
                    ) : null}
                    <ESPEndorsementNamedInsuredSection
                        policyId={policyId}
                        namedInsured={state.namedInsured}
                        effectivePeriodStart={state.effectivePeriodStart}
                        effectivePeriodEnd={state.effectivePeriodEnd}
                        onSaveEndorsementSuccess={handleUpdatePolicy}
                        onSaveEndorsementFailure={handleFailure}
                        isEndorsementDisabled={isPolicyInReferredState || isPolicyInRunoff}
                        hasRenewalApplication={policy.hasRenewalApplication}
                    />
                    <ESPEndorsementAddressSection
                        addressData={state.addressData}
                        onEditClick={addressModal.show}
                    />
                </StackLayout>
            </StackLayout>
            <Modal {...addressModal} size="medium">
                <ScrollBox>
                    {addressModal.visible ? (
                        <ESPEndorsementAddress
                            effectivePeriodStart={state.effectivePeriodStart}
                            effectivePeriodEnd={state.effectivePeriodEnd}
                            address={state.addressData}
                            onSave={handleAddressSave}
                            onClose={addressModal.hide}
                            onGoToContactUs={handleGoToContactUs}
                            hasRenewalApplication={policy.hasRenewalApplication}
                        />
                    ) : (
                        ''
                    )}
                </ScrollBox>
            </Modal>
            <Modal {...policyUpdatedModal} size="small" dismissable={false}>
                <ESPEndorsementPolicyUpdatedModalContent onClose={handleClosePolicyUpdatedModal} />
            </Modal>
            <Modal {...invoiceDoneModal} size="small" dismissable={false}>
                <ESPEndorsementInvoiceDoneModalContent onClose={handleCloseInvoiceDoneModal} />
            </Modal>
            <Modal {...errorModal} size="small">
                <ESPEndorsementErrorModalContent
                    onClose={handleCloseErrorModal}
                    onGoToContactUs={handleGoToContactUs}
                    errorMessage={errorModalMessage}
                />
            </Modal>
        </PageLayout.Section>
    );
}
