import { Immutable } from '@embroker/shotwell/core/types';
import {
    InsetBox,
    InvoiceTable,
    ScrollBox,
    StackLayout,
    ColumnLayout,
    Text,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { CoverageCatalog } from '../../CoverageCatalog';
import { BundleQuote } from '../../entities/BundleQuote';
import {
    BundleCoverageAppStatusEnum,
    BundleQuoteCoverageType,
} from '../../types/BundleQuoteCoverage';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';

export interface BundleQuoteModalSummaryProps {
    bundleQuote: BundleQuote;
}

export const BundleQuoteModalSummary = function BundleQuoteModalSummary({
    bundleQuote,
}: BundleQuoteModalSummaryProps) {
    const coverageList = bundleQuote.getSelectedCoverages();
    const bindable = coverageList.filter(
        (coverage) =>
            (coverage.status === BundleCoverageAppStatusEnum.QuotesReady ||
                coverage.status === BundleCoverageAppStatusEnum.Purchased) &&
            !coverage.quote?.isIndication,
    );

    return (
        <StackLayout>
            <ColumnLayout gap="8" center>
                <Text>Effective date: </Text>{' '}
                <Text style="heading 4">
                    <DateDisplay value={bundleQuote.effectiveDate} />
                </Text>
            </ColumnLayout>
            {bindable && bindable.length > 0 ? (
                <InsetBox gap="16" withBorder background="ui-100">
                    <ScrollBox>
                        <StackLayout gap="24">
                            {bindable.map((coverage, i) => renderCoverage(coverage, i))}
                        </StackLayout>
                    </ScrollBox>
                </InsetBox>
            ) : null}
        </StackLayout>
    );
};

function renderCoverage(coverage: Immutable<BundleQuoteCoverageType>, index: number) {
    const Component = CoverageCatalog.getModalSummaryComponent(coverage.type);

    const quote = coverage.quote;

    if (!Component || !quote || !CoverageCatalog.isQuoteValidType(quote, coverage.type)) {
        return null;
    }

    return (
        <StackLayout key={coverage.type}>
            {index !== 0 ? <InvoiceTable.Separator /> : null}
            <Component quote={quote} />
        </StackLayout>
    );
}
