import { CertificateCoverageInfo } from './CertificateCoverageInfo';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface CertificateCoverageAuto {
    coverageInfo: CertificateCoverageInfo;
    anyAuto?: boolean;
    allOwnedAutos?: boolean;
    hiredAutos?: boolean;
    scheduledAutos?: boolean;
    nonOwnedAutos?: boolean;
    other1?: string;
    other2?: string;
}

export const CertificateCoverageAuto = {
    ...defineValidator<CertificateCoverageAuto>(
        Joi.object({
            coverageInfo: CertificateCoverageInfo.schema,
            anyAuto: Joi.boolean().optional(),
            allOwnedAutos: Joi.boolean().optional(),
            hiredAutos: Joi.boolean().optional(),
            scheduledAutos: Joi.boolean().optional(),
            nonOwnedAutos: Joi.boolean().optional(),
            other1: Joi.string().optional().allow(''),
            other2: Joi.string().optional().allow(''),
        }).preferences({ presence: 'required' }),
    ),
    create(certificateCoverageAuto: CertificateCoverageAuto) {
        return CertificateCoverageAuto.validate(certificateCoverageAuto);
    },
};
