import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject } from '@embroker/shotwell/core/di';
import { ApplicationRepository } from '../../../shopping/repositories/ApplicationRepository';
import { SignaturePacketDocument } from '../../../shopping/types/SignaturePacketDocument';

export interface GetSignaturePacketDocumentsRequest {
    applicationId: UUID;
}

export interface GetSignaturePacketDocumentsResponse {
    readonly signaturePacketDocuments: Immutable<Array<SignaturePacketDocument>>;
}

export interface GetSignaturePacketDocuments extends UseCase {
    execute(
        request: GetSignaturePacketDocumentsRequest,
    ): AsyncResult<
        GetSignaturePacketDocumentsResponse,
        UnknownEntity | InvalidArgument | OperationFailed
    >;
}

class GetSignaturePacketDocumentsUseCase extends UseCase implements GetSignaturePacketDocuments {
    public static type = Symbol('Brokerage/GetSignaturePacketDocuments');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
    }: GetSignaturePacketDocumentsRequest): AsyncResult<
        GetSignaturePacketDocumentsResponse,
        InvalidArgument | OperationFailed | UnknownEntity
    > {
        const applicationResult = await this.applicationRepository.getApplication(applicationId);
        if (isErr(applicationResult)) {
            return applicationResult;
        }

        return Success<GetSignaturePacketDocumentsResponse>({
            signaturePacketDocuments: applicationResult.value.signaturePacketDocuments,
        });
    }
}

export const GetSignaturePacketDocuments: UseCaseClass<GetSignaturePacketDocuments> =
    GetSignaturePacketDocumentsUseCase;
