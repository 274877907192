import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { Money } from '@embroker/shotwell/core/types/Money';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import {
    AddESPEndorsementLiabilityCoverageType,
    EditESPEndorsementLiabilityCoverageType,
} from '../../types/ESPEndorsementLiabilityCoverageType';
import { ESPEndorsementPolicy } from '../../types/ESPEndorsementPolicy';
import { ESPEndorsementRate } from '../../types/ESPEndorsementRate';
import { ESPEndorsementUserData } from '../../types/ESPEndorsementUserData';
import { DateNotInSequence, NoChanges } from '../../../errors';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import { State } from '@embroker/shotwell/core/types/StateList';

export interface CreateNamedInsuredEndorsement {
    policyId: UUID;
    namedInsured: string;
    effectiveDate: Date;
}

export interface CreateAddressEndorsementParam {
    policyId: UUID;
    addressLine1: string;
    addressLine2: string;
    city: string;
    zipCode: ZipCode;
    state?: State;
    effectiveDate: Date;
}

export interface CreateAddCoverageEndorsementParam {
    policyId: UUID;
    coverageTypeCode: AddESPEndorsementLiabilityCoverageType;
    limit: Money;
    retention: Money;
    effectiveDate: Date;
    enhanced: boolean;
}

export interface CreateEditCoverageEndorsementParam {
    policyId: UUID;
    coverageTypeCode: EditESPEndorsementLiabilityCoverageType;
    limit: Money;
    retention: Money;
    effectiveDate: Date;
}

export interface EndorsementCoverage {
    coverageTypeCode: EditESPEndorsementLiabilityCoverageType;
    limit: Money;
    retention: Money;
}
export interface CreateEditMultipleCoverageEndorsementParam {
    agreementId: UUID;
    coverages: EndorsementCoverage[];
    effectiveDate: Date;
}

export interface RateAddCoverageEndorsementParam {
    policyId: UUID;
    coverageTypeCode: AddESPEndorsementLiabilityCoverageType;
    limit: Money;
    retention: Money;
    effectiveDate: Date;
    enhanced: boolean;
}

export interface RateEditCoverageEndorsementParam {
    policyId: UUID;
    coverageTypeCode: EditESPEndorsementLiabilityCoverageType;
    limit: Money;
    retention: Money;
    effectiveDate: Date;
}

interface EditEndorsementMultipleCoverage {
    coverageTypeCode: EditESPEndorsementLiabilityCoverageType;
    limit: Money;
    retention: Money;
}

export interface RateEditMultipleCoverageEndorsementParam {
    agreementId: UUID;
    coverages: EditEndorsementMultipleCoverage[];
    effectiveDate: Date;
}

export interface ESPEndorsementRepository {
    loadPolicy(
        policyId: UUID,
    ): AsyncResult<ESPEndorsementPolicy, InvalidArgument | OperationFailed>;

    loadUserData(): AsyncResult<ESPEndorsementUserData, InvalidArgument | OperationFailed>;

    createNamedInsuredEndorsement(
        createNamedInsuredEndorsement: CreateNamedInsuredEndorsement,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed | DateNotInSequence | NoChanges>;

    createAddressEndorsement(
        createAddressEndorsementParam: CreateAddressEndorsementParam,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed | DateNotInSequence | NoChanges>;

    createAddCoverageEndorsement(
        createAddCoverageEndorsementParam: CreateAddCoverageEndorsementParam,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed | DateNotInSequence | NoChanges>;

    createEditCoverageEndorsement(
        createEditCoverageEndorsementParam: CreateEditCoverageEndorsementParam,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed | DateNotInSequence | NoChanges>;

    createEditMultipleCoverageEndorsement(
        createEditMultipleCoverageEndorsementParam: CreateEditMultipleCoverageEndorsementParam,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed | DateNotInSequence | NoChanges>;

    rateAddCoverageEndorsement(
        rateAddCoverageEndorsementParam: RateAddCoverageEndorsementParam,
    ): AsyncResult<ESPEndorsementRate, InvalidArgument | OperationFailed>;

    rateEditCoverageEndorsement(
        rateEditCoverageEndorsementParam: RateEditCoverageEndorsementParam,
    ): AsyncResult<ESPEndorsementRate, InvalidArgument | OperationFailed>;

    rateEditMultipleCoverageEndorsement(
        rateEditMultipleCoverageEndorsementParam: RateEditMultipleCoverageEndorsementParam,
    ): AsyncResult<ESPEndorsementRate, InvalidArgument | OperationFailed>;
}

export const ESPEndorsementRepository = Symbol('ESPEndorsementRepository');
