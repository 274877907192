import { inject } from '@embroker/shotwell/core/di';
import { OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ApplicationNotFound, OperationNotAllowed } from '../../shopping/errors';
import { ApplicationRepository } from '../../shopping/repositories/ApplicationRepository';

export interface EditClientApplicationRequest {
    token: string;
    questionnaireData: string;
}

export interface EditClientApplication extends UseCase {
    execute(
        request: EditClientApplicationRequest,
    ): AsyncResult<void, ApplicationNotFound | OperationNotAllowed | OperationFailed>;
}

class EditClientApplicationUseCase extends UseCase implements EditClientApplication {
    public static type = Symbol('Shopping/EditClientApplication');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
    ) {
        super(eventBus);
    }
    public async execute({
        token,
        questionnaireData,
    }: EditClientApplicationRequest): AsyncResult<
        void,
        ApplicationNotFound | OperationNotAllowed | OperationFailed
    > {
        return await this.applicationRepository.editClientApplication(token, questionnaireData);
    }
}

export const EditClientApplication: UseCaseClass<EditClientApplication> =
    EditClientApplicationUseCase;
