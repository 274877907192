import { allEnumValuesMaps } from '@embroker/shotwell-api/enums';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { execute } from '@embroker/shotwell/core/UseCase';
import { useCallback, useEffect, useState } from 'react';
import { GetServerEnums } from '../../useCases/GetServerEnums';

export function useEnumGroup(enumGroup: string) {
    const [result, setResult] = useState<Map<string, string>>();
    const [isLoading, setIsLoading] = useState(true);

    const reload = useCallback(() => {
        execute(GetServerEnums).then((response) => {
            if (isOK(response)) {
                const newResult = new Map<string, string>();

                const staticEnums = allEnumValuesMaps.get(enumGroup);

                for (const [key, value] of Object.entries(staticEnums)) {
                    newResult.set(key, value as string);
                }

                const serverEnumsMap = response.value.enums;
                if (serverEnumsMap.has(enumGroup)) {
                    const serverEnums = serverEnumsMap.get(enumGroup);

                    if (serverEnums !== undefined) {
                        for (const serverEnum of serverEnums) {
                            newResult.set(serverEnum.value, serverEnum.name);
                        }

                        setResult(newResult);
                    }
                }
            }

            setIsLoading(false);
        });
    }, [enumGroup]);

    useEffect(() => {
        if (result === undefined) {
            reload();
        }
    }, [result, reload]);

    return { result, isLoading, reload };
}
