import { Nullable } from '@embroker/shotwell/core/types';
import { BaseContext, BaseContextStore } from '@embroker/shotwell/view/BaseContext';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { PageLayoutActions, PageLayoutState } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { Coverage } from '../shopping/types/Coverage';
import { Role } from '../userOrg/entities/Session';
import { GlobalConfig } from '../config/types/GlobalConfig';

export interface UserOnboardingContext {
    redirectUrl?: string;
}

export interface ActiveSession {
    isAuthenticated: boolean;
    authenticatedUserId: Nullable<UUID>;
    userId: Nullable<UUID>;
    organizationId: Nullable<UUID>;
    userOrganizationIdList?: UUID[];
    roles: Nullable<Role[]>;
}

type PageLayoutContext = PageLayoutActions & Pick<PageLayoutState, 'navigationState'>;

/**
 * Application context object.
 */
export interface AppContext extends PageLayoutContext, BaseContext {
    /**
     * Current user's session information.
     */
    readonly activeSession: ActiveSession;
    /**
     * Returns true if AppContext.activeSession has been initialized.
     *
     * AppContext.activeSession is non-nullable in order to make it easier
     * to use, but withAuthentication() route matcher needs to differentiate
     * between initial activeSession and *validated* unauthenticated session.
     */
    readonly hasActiveSession: boolean;
    selectedCoverages?: Coverage[];
    globalConfig?: GlobalConfig;
    /**
     * Resets the active session so that it gets refreshed on the next navigation.
     */
    resetActiveSession(): void;
}

export const AppContextStore = new (class extends BaseContextStore<AppContext> {
    public constructor() {
        super();

        // initial unauthenticated session
        const activeSession: ActiveSession = {
            isAuthenticated: false,
            authenticatedUserId: null,
            userId: null,
            organizationId: null,
            roles: null,
        };

        this.data = {
            ...this.data,
            hasActiveSession: false,
            activeSession,
            resetActiveSession: () => this.update({ hasActiveSession: false, activeSession }),
            navigationState: 'open',
            setHeader() {
                // do nothing.
            },
            setSidebar() {
                // do nothing.
            },
            setNavigation() {
                // do nothing.
            },
            toggleNavigationState() {
                // do nothing.
            },
            setSlideout() {
                // do nothing.
            },
            closeSlideout() {
                // do nothing.
            },
            setToast() {
                // do nothing.
            },
            closeToast() {
                // do nothing.
            },
            setFullscreen() {
                // do nothing.
            },
        };
    }
})();

export const AppContext = React.createContext<AppContext>({ ...AppContextStore.context });
