import { inject } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Success, Failure, AsyncResult, isOK } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { APIQuestionerRepository } from '../repositories/APIQuestionerRepository';
import { SessionRepository } from '../../userOrg/repositories/SessionRepository';
import { isAuthenticated } from '../../userOrg/entities/Session';
import { Unauthenticated } from '../../userOrg/errors';
import { FailedToSaveQuestionerAnswers } from '../errors';
import { QuestionerQuestion } from '../types/QuestionerQuestionType';
import { OracleAnswer } from '../types/OracleAnswerType';

export type SaveQuestionerAnswersRequest = {
    formData: { [key: string]: unknown };
    questionerQuestions: QuestionerQuestion[];
};

export type SaveQuestionerAnswersResponse = {};

export type SaveQuestionerAnswersUseCaseResult = AsyncResult<
    SaveQuestionerAnswersResponse,
    Unauthenticated | FailedToSaveQuestionerAnswers
>;

export interface SaveQuestionerAnswers extends UseCase {
    execute(request: SaveQuestionerAnswersRequest): SaveQuestionerAnswersUseCaseResult;
}

class SaveQuestionerAnswersUseCase extends UseCase implements SaveQuestionerAnswers {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Questioner/SaveQuestionerAnswersUseCase');
    /**
     * Constructor for SaveQuestionerAnswersUseCase use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param notificationRepository is notification repository used to store user Questioner
     */
    constructor(
        @inject(APIQuestionerRepository) private questionerRepository: APIQuestionerRepository,
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(SessionRepository) private sessionRepository: SessionRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: SaveQuestionerAnswersRequest,
    ): SaveQuestionerAnswersUseCaseResult {
        const activeSesion = await this.sessionRepository.getActiveSession();
        if (!isAuthenticated(activeSesion.value)) {
            return Failure(Unauthenticated());
        }

        const { formData, questionerQuestions } = request;

        const { answers, errors } = OracleAnswer.getAnswersFromQuestionnaire(
            formData,
            questionerQuestions,
        );

        if (errors.length) {
            return Failure(FailedToSaveQuestionerAnswers());
        }

        const getQuestionerFormResp = await this.questionerRepository.saveQuestionerAnswers({
            answers,
        });

        if (!isOK(getQuestionerFormResp)) {
            return getQuestionerFormResp;
        }

        return Success<SaveQuestionerAnswersResponse>({});
    }
}

export const SaveQuestionerAnswers: UseCaseClass<SaveQuestionerAnswers> =
    SaveQuestionerAnswersUseCase;
