import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { Nullable } from '@embroker/shotwell/core/types';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { HigherLimitsApprovalNotNeeded } from '../../errors';

export interface GetTaskStatusResponse {
    data: Nullable<string>;
    error: Nullable<string>;
}

export interface CreateSignaturePacketDocumentTaskResponse {
    taskId: UUID;
    documentId: UUID;
}

export interface BrokerageQuoteRepository {
    createSignaturePacketDocumentTask(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<CreateSignaturePacketDocumentTaskResponse, InvalidArgument | OperationFailed>;

    getTaskStatus(id: UUID): AsyncResult<GetTaskStatusResponse, InvalidArgument | OperationFailed>;

    requestHigherLimits(
        applicationId: UUID,
        isSubmit: boolean,
    ): AsyncResult<void, HigherLimitsApprovalNotNeeded | InvalidArgument | OperationFailed>;
}

export const BrokerageQuoteRepository = Symbol('BrokerageQuoteRepository');
