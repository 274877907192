import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject } from '@embroker/shotwell/core/di';
import { BrokerRepository, GetConfigResponse } from '../repositories';

export interface GetConfig extends UseCase {
    execute(): AsyncResult<GetConfigResponse, InvalidArgument | OperationFailed>;
}

class GetConfigUseCase extends UseCase implements GetConfig {
    public static type = Symbol('Broker/GetConfig');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BrokerRepository) private brokerRepository: BrokerRepository,
    ) {
        super(eventBus);
    }

    public async execute(): AsyncResult<GetConfigResponse, InvalidArgument | OperationFailed> {
        return await this.brokerRepository.getConfig();
    }
}

export const GetConfig: UseCaseClass<GetConfig> = GetConfigUseCase;
