import {
    ColumnLayout,
    PageLayout,
    StackLayout,
    TabNavigation,
    Text,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { useCurrentRoute } from 'react-navi';
import { Link } from '../../../view/components/Link/Link';

export interface ClaimsLandingPageProps {
    children: React.ReactNode;
    withSubNavigation?: boolean;
}

export function ClaimsLandingPage({ children, withSubNavigation = true }: ClaimsLandingPageProps) {
    const route = useCurrentRoute();
    return (
        <PageLayout.Section>
            <StackLayout gap="32">
                <ColumnLayout gap="16">
                    <StackLayout>
                        <Text data-e2e="claims-page-header" as="span" style="heading 2">
                            {route.title}
                        </Text>
                        <Text>
                            View open or closed claims that are filed under your various policies
                            with us, and get contact details for filing a new claim.
                        </Text>
                    </StackLayout>
                </ColumnLayout>
                {withSubNavigation && (
                    <TabNavigation>
                        <TabNavigation.Item
                            data-e2e="claims-sub-nav-history-link"
                            as={Link}
                            href="/claims/claims-history"
                            exact
                        >
                            Claims History
                        </TabNavigation.Item>
                        <TabNavigation.Item
                            data-e2e="claims-sub-nav-filling-list-link"
                            as={Link}
                            href="/claims/filing-list"
                            exact
                        >
                            Filing a claim
                        </TabNavigation.Item>
                    </TabNavigation>
                )}
                {children}
            </StackLayout>
        </PageLayout.Section>
    );
}
