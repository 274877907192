import { OpaqueForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    PageLayout,
    Text,
    StackLayout,
    Form,
    RadioGroup,
    StatusMessage,
} from '@embroker/ui-toolkit/v2';

import React, { useMemo } from 'react';
import { StaffDetailsInput } from './StaffDetailsPage';
import { FormViewLayout } from '@app/view/components/FormViewLayout.view';
import { OnboardingPrefillQuestionnaireData } from '@app/userOrg/types/OnboardingPrefillQuestionnaireData';
import { CurrencyInput } from '@embroker/shotwell/view/components/CurrencyInput';
import { Money } from '@embroker/shotwell/core/types/Money';

export interface StaffDetailsProps {
    form: OpaqueForm<StaffDetailsInput>;
    questionnaireData?: OnboardingPrefillQuestionnaireData;
    handleBack?: () => void;
}

export const StaffDetails = ({ form, questionnaireData, handleBack }: StaffDetailsProps) => {
    const { fields, submit } = useForm(form, {
        hasRaisedFounding: questionnaireData?.has_raised_funding,
        totalRevenue: questionnaireData?.total_revenue,
        workspace: questionnaireData?.location_type,
        employeesCount: questionnaireData?.number_of_employees,
    });
    const disableSubmit = useMemo(
        () =>
            !(
                fields.employeesCount.props.value &&
                fields.hasRaisedFounding.props.value !== undefined &&
                fields.workspace.props.value &&
                fields.totalRevenue.props.value
            ),
        [fields],
    );

    const address = useMemo(
        () => questionnaireData?.headquarters?.addressLine1 || '',
        [questionnaireData],
    );

    return (
        <PageLayout.Section>
            <FormViewLayout
                submitText="Next"
                onFormSubmit={submit}
                title="Tell us a few more details about your business so we can help you find the
                        right protection."
                handleBack={handleBack}
                disableSubmit={disableSubmit}
            >
                <StackLayout>
                    <Text color="ui-500">Has the company raised funding?</Text>
                    <RadioGroup
                        id={fields.hasRaisedFounding.props.name}
                        items={[
                            {
                                title: 'Yes',
                                value: true,
                            },
                            {
                                title: 'No',
                                value: false,
                            },
                        ]}
                        status={fields.hasRaisedFounding.errors.length ? 'error' : 'default'}
                        {...fields.hasRaisedFounding.props}
                    />
                </StackLayout>
                <StackLayout gap="12">
                    <Text color="ui-500">
                        What’s the total revenue your business expects to generate in the current
                        fiscal year?
                    </Text>
                    <Form.Field messages={fields.totalRevenue.messages}>
                        <CurrencyInput
                            label="Current year annual revenue (projected)"
                            allowDecimal={false}
                            {...fields.totalRevenue.props}
                            value={fields.totalRevenue.props.value}
                            onChange={({ target: { value } }) =>
                                fields.totalRevenue.props.onChange({
                                    target: { value: value as Money },
                                })
                            }
                        />
                    </Form.Field>
                    <StatusMessage status="helptext">
                        This refers to the revenue before any deductions, such as commission splits,
                        revenue sharing, expenses, etc.
                    </StatusMessage>
                </StackLayout>
                <StackLayout>
                    <Text color="ui-500">Which best describes your workspace at {address}?</Text>
                    <RadioGroup
                        id={fields.workspace.props.name}
                        items={[
                            {
                                title: 'This is my home office location',
                                value: 'home_office',
                            },
                            {
                                title: 'I rent an office at this location',
                                value: 'office_rented',
                            },
                            {
                                title: 'I own this location',
                                value: 'office_owned',
                            },
                        ]}
                        status={fields.workspace.errors.length ? 'error' : 'default'}
                        {...fields.workspace.props}
                    />
                </StackLayout>
                <StackLayout gap="12">
                    <Text color="ui-500">How many total employees does your company have?</Text>
                    <Form.Field
                        inputProps={{
                            ...fields.employeesCount.props,
                        }}
                        label="Total employee number"
                        messages={fields.employeesCount.messages}
                        type={fields.employeesCount.type}
                    />
                    <StatusMessage status="helptext">
                        {`"Employee" `} refers to any staff on payroll, any independent contractors
                        you hire, and the company’s owners.
                    </StatusMessage>
                </StackLayout>
            </FormViewLayout>
        </PageLayout.Section>
    );
};
