import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ESPRenewalQuote } from '../../entities/ESPRenewalQuote';
import { ESPRenewalConfigFetchFailed, ESPRenewalBeforeLastEndorsement } from '../../errors';
import { ESPRenewalConfig } from '../../types/ESPRenewalConfig';
import { ApplicationFetchFailed, DocGenFailed } from '../../../errors';

export interface PurchaseESPRenewalResponse {
    policyId: UUID;
    policyDoneTaskId: UUID;
}

export interface QuoteRepository {
    getQuoteByApplicationId(
        applicationId: UUID,
    ): AsyncResult<
        ESPRenewalQuote,
        InvalidArgument | OperationFailed | ESPRenewalConfigFetchFailed
    >;

    purchaseESPRenewal(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<
        PurchaseESPRenewalResponse,
        InvalidArgument | OperationFailed | ESPRenewalBeforeLastEndorsement
    >;

    updateQuote(
        applicationId: UUID,
        quoteOptions: any,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed>;

    getESPRenewalConfig(): AsyncResult<ESPRenewalConfig, InvalidArgument | OperationFailed>;

    getESPQuestionnaireData(
        applicationId: UUID,
    ): AsyncResult<string, InvalidArgument | OperationFailed | ApplicationFetchFailed>;

    createQuoteSummaryAsyncTask(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed | DocGenFailed>;
}

export const QuoteRepository = Symbol('QuoteRepository');
