import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Immutable } from '@embroker/shotwell/core/types';
import { Nominal } from '@embroker/shotwell/core/types/Nominal';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { ColumnLayout, TextButton } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { GetFile } from '../../../../documents/useCases/GetFile';
import { GetDocumentURL } from '../../../../policy/useCases/GetDocumentURL';
import { GetPolicy, GetPolicyRequest } from '../../../../policy/useCases/GetPolicy';
import { SelectOrganization } from '../../../../userOrg/useCases/SelectOrganization';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { BrokerPolicy } from '../../../repositories';
import {
    GetBrokerPolicies,
    GetBrokerPoliciesParams,
    PolicyListSortableColumn,
    PolicyTableFilters,
} from '../../../useCases/GetBrokerPolicies';

export interface BrokerPolicyTableData extends Record<string, unknown> {
    readonly policyId: UUID;
    readonly companyId: UUID;
    readonly companyName?: React.ReactNode;
    readonly policyNumber?: string;
    readonly effectiveDate?: React.ReactNode;
    readonly expirationDate?: React.ReactNode;
    readonly premium?: React.ReactNode;
    readonly action?: React.ReactNode;
    readonly managingBrokerName?: string;
    readonly productType?: string;
}

export interface GetPolicyPageDataRequest {
    pageIndex: number;
    itemsPerPage: number;
    column?: PolicyListSortableColumn;
    order?: 'ASC' | 'DESC';
    filters?: PolicyTableFilters;
}

export async function getPolicyPageData({
    pageIndex,
    itemsPerPage,
    column,
    order,
    filters,
}: GetPolicyPageDataRequest) {
    const request: GetBrokerPoliciesParams = {
        column,
        order: order ? (order === 'ASC' ? 'ascending' : 'descending') : undefined,
        index: pageIndex,
        size: itemsPerPage,
        filters,
    };

    const result = await execute(GetBrokerPolicies, request);
    if (isOK(result)) {
        return {
            items: toPolicyTableData({
                policyTableData: result.value.policyList,
            }),
            totalItems: result.value.pageInfo.totalItems,
        };
    }
    return {
        items: [],
        totalItems: 0,
    };
}

export interface ToPolicyTableDataRequest {
    policyTableData: Immutable<BrokerPolicy[]>;
}

export function toPolicyTableData({
    policyTableData,
}: ToPolicyTableDataRequest): BrokerPolicyTableData[] {
    return policyTableData.map((policyEntry: BrokerPolicy) => {
        return {
            policyId: policyEntry.policyId,
            companyId: policyEntry.companyId,
            companyName: (
                <CompanyNameLink name={policyEntry.companyName} id={policyEntry.companyId} />
            ),
            policyNumber: policyEntry.policyNumber,
            effectiveDate: policyEntry.effectiveDate ? (
                <DateDisplay format="MMM d, yyyy" value={policyEntry.effectiveDate} />
            ) : (
                <div>N/A</div>
            ),
            expirationDate: policyEntry.expirationDate ? (
                <DateDisplay format="MMM d, yyyy" value={policyEntry.expirationDate} />
            ) : (
                <div>N/A</div>
            ),
            premium: <MoneyDisplay value={policyEntry.premium} />,
            action: (
                <BrokerPoliciesAction
                    organizationId={policyEntry.companyId}
                    policyId={policyEntry.policyId}
                    policyNumber={policyEntry.policyNumber}
                />
            ),
            managingBrokerName: policyEntry.managingBrokerName,
            productType: mapPolicyProductType(policyEntry.productType),
        };
    });
}

interface BrokerPolicyActionProps {
    organizationId: Nominal<string, 'UUID'>;
    policyId: Nominal<string, 'UUID'>;
    policyNumber: string;
}

export function BrokerPoliciesAction({
    organizationId,
    policyId,
    policyNumber,
}: BrokerPolicyActionProps): JSX.Element {
    const { navigate } = useNavigation();

    return (
        <ColumnLayout gap="16">
            <TextButton
                onClick={async () => {
                    await execute(SelectOrganization, {
                        organizationId,
                    });
                    navigate(URI.build('/policies/detail', { policyId }));
                }}
            >
                View
            </TextButton>
            {policyNumber && (
                <TextButton
                    onClick={async () => {
                        await execute(SelectOrganization, {
                            organizationId,
                        });
                        downloadPolicyDocument(policyId);
                    }}
                >
                    Download
                </TextButton>
            )}
        </ColumnLayout>
    );
}

async function downloadPolicyDocument(policyId: UUID) {
    const policyDataResponse = await getPolicyResponse(policyId);
    const fileKey = policyDataResponse?.value.policy.documents.find(
        (document) => document.typeCode === 'DocumentTypeCodeListFullPolicyDocument',
    )?.storageLocation;

    if (fileKey === undefined) {
        return;
    }
    const getDocumentURLResult = await execute(GetDocumentURL, {
        fileKey,
    });
    if (getDocumentURLResult !== undefined && isOK(getDocumentURLResult)) {
        const getFileResult = await execute(GetFile, {
            fileName: 'Policy.pdf',
            fileType: 'application/pdf',
            url: getDocumentURLResult.value.documentURL,
        });
        if (getFileResult && isOK(getFileResult)) {
            const fileURL = window.URL.createObjectURL(getFileResult.value);
            const tempLink: HTMLElement = document.createElement('a');
            tempLink.setAttribute('href', fileURL);
            tempLink.setAttribute('download', getFileResult.value.name);
            tempLink.click();
        }
    }
}

async function getPolicyResponse(policyId: UUID) {
    const request: GetPolicyRequest = {
        policyId,
    };
    const getPolicyResult = await execute(GetPolicy, request);
    if (isOK(getPolicyResult)) {
        return getPolicyResult;
    } else {
        container.get<Logger>(Log).error(getPolicyResult.errors);
        return null;
    }
}

export function CompanyNameLink({ name, id }: { name: string; id: UUID }) {
    const { navigate } = useNavigation();

    const handleCompanySelection = async () => {
        await execute(SelectOrganization, { organizationId: id });
        navigate(
            URI.build('/broker/organization-info', {
                organizationId: id,
            }),
        );
    };

    return (
        <TextButton className="access_todo_company_link" onClick={handleCompanySelection}>
            {name}
        </TextButton>
    );
}

function mapPolicyProductType(productType: string) {
    if (productType === 'LPLEverest') {
        return 'LPL';
    }
    return productType;
}
