import { inject, injectable } from '@embroker/shotwell/core/di';
import {
    InvalidArgument,
    NotAllowed,
    OperationFailed,
    UnknownEntity,
} from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { CalculateSKU } from '../../../analytics/useCases/CalculateSKU';
import { InvalidAnnualTechFee } from '../../errors';
import { CrimeQuote, CrimeQuotePurchased } from '../entities/CrimeQuote';
import { CrimeQuoteRepository } from '../repositories/CrimeQuoteRepository';

export interface PurchaseCrimeRequest {
    quote: CrimeQuote;
}

export interface PurchaseCrimeResponse {
    policyId: UUID;
    policyDoneTaskId: UUID;
}

export interface PurchaseCrime extends UseCase {
    execute(
        request: PurchaseCrimeRequest,
    ): AsyncResult<
        PurchaseCrimeResponse,
        NotAllowed | OperationFailed | InvalidArgument | UnknownEntity | InvalidAnnualTechFee
    >;
}

@injectable()
class PurchaseCrimeUseCase extends UseCase implements PurchaseCrime {
    public static type = Symbol('CrimeQuote/PurchaseCrime');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(CrimeQuoteRepository) private crimeQuoteRepository: CrimeQuoteRepository,
        @inject(CalculateSKU.type) private calculateSku: CalculateSKU,
    ) {
        super(eventBus);
    }

    public async execute({
        quote,
    }: PurchaseCrimeRequest): AsyncResult<
        PurchaseCrimeResponse,
        NotAllowed | OperationFailed | InvalidArgument | UnknownEntity | InvalidAnnualTechFee
    > {
        const acceptResult = quote.accept();
        if (isErr(acceptResult)) {
            return acceptResult;
        }

        const result = await this.crimeQuoteRepository.purchaseCrime(quote.applicationId, quote.id);
        if (isErr(result)) {
            return result;
        }

        const skuResult = await this.calculateSku.execute({
            event: 'purchase',
            applicationId: quote.applicationId,
        });

        const purchaseEvent: CrimeQuotePurchased = {
            id: quote.id,
            origin: 'CrimeQuote',
            name: 'Purchased',
            applicationId: quote.applicationId,
            createdAt: new Date(Date.now()),
            totalPremium: quote.totalPremium,
            sku: skuResult.value,
            isRenewal: quote.isRenewal,
        };
        await this.eventBus.publish(purchaseEvent);

        return Success({
            policyId: result.value.policyId,
            policyDoneTaskId: result.value.policyDoneTaskId,
        });
    }
}

export const PurchaseCrime: UseCaseClass<PurchaseCrime> = PurchaseCrimeUseCase;
