import { isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Spinner } from '@embroker/ui-toolkit/v2';
import React, { useEffect } from 'react';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { AcceptUserInvite, AcceptUserInviteRequest } from '../../useCases/AcceptUserInvite';

interface AcceptInviteProps {
    invitationId: UUID;
}

export function AcceptInvite({ invitationId }: AcceptInviteProps) {
    const { navigate } = useNavigation();
    const { result } = useUseCase(AcceptUserInvite, {
        inviteToken: invitationId,
    } as AcceptUserInviteRequest);

    useEffect(() => {
        if (result) {
            if (isErr(result)) {
                navigate('/summary');
            } else {
                navigate('/user/switch-companies');
            }
        }
    }, [result, navigate]);

    return <Spinner />;
}
