import { Schema } from '@embroker/shotwell/core/validation/schema';
import { EffectiveDate } from '../../types/EffectiveDate';

export function validateEffectiveDate(
    effectiveDate: Date,
    today: Date,
    maxFutureDaysAllowed: number,
    helpers: Schema.CustomHelpers,
    isAdmin: boolean,
): Date | Schema.ErrorReport {
    if (!EffectiveDate.isAfterMinValidDate(effectiveDate, today, isAdmin)) {
        return helpers.error('date.min');
    }
    if (!EffectiveDate.isBeforeMaxValidDate(effectiveDate, today, maxFutureDaysAllowed)) {
        return helpers.error('date.max');
    }

    return effectiveDate;
}
