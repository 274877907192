import { Immutable } from '@embroker/shotwell/core/types';
import { USD } from '@embroker/shotwell/core/types/Money';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    BoxLayout,
    Card,
    ColumnLayout,
    HeroBanner,
    Icon,
    InvoiceTable,
    PageLayout,
    SidebarLayout,
    StackLayout,
    StickyLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import { addYears } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { AppTypeLabelMap } from '../../../../shopping/types/enums';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { GetLastWCGAQuote } from '../../useCases/GetLastWCGAQuote';
import { WOS_PDF_URL } from './wos/constants';

interface WCGAEstmateSubmittedProps {
    applicationId: UUID;
}

/**
 * Constant sum of money for display in the component
 */
const eachAccident = USD(100000000);
/**
 * Constant sum of money for display in the component
 */
const eachEmployee = USD(100000000);
/**
 * Constant sum of money for display in the component
 */
const totalLimit = USD(100000000);

const DATE_FORMAT = 'MM/dd/yyyy';

export const WCGAEstimateSubmitted = ({ applicationId }: WCGAEstmateSubmittedProps) => {
    const { navigate } = useNavigation();
    const lastQuote = useUseCase(GetLastWCGAQuote, { applicationId });
    const [startDate, setStartDate] = useState(new Date(Date.now()));
    const [premium, setPremium] = useState(USD(0));
    const [wos, setWos] = useState(true);
    const [isWosRecommended, setIsWosRecommended] = useState(true);
    const [insurerName, setInsurerName] = useState('');

    const navigateToSummary = useCallback(() => {
        return navigate('/summary');
    }, [navigate]);

    const navigateToErrorPage = useCallback((errors: Immutable<never[]>) => {
        return <ErrorPage errors={errors} />;
    }, []);

    useEffect(() => {
        if (lastQuote.result != null) {
            if (isOK(lastQuote.result)) {
                setStartDate(new Date(lastQuote.result.value.quote.options.effectiveDate));
                setPremium(lastQuote.result.value.quote.totalPremium);
                setInsurerName(lastQuote.result.value.quote.details.insurerName);
                setWos(lastQuote.result.value.quote.options.blanketWoS);
                setIsWosRecommended(lastQuote.result.value.quote.options.isWosRecommended);
            } else {
                navigateToErrorPage(lastQuote.result.errors);
            }
        }
    }, [lastQuote.result, navigateToErrorPage]);

    return (
        <React.Fragment>
            <HeroBanner icon="rocket" onDismiss={navigateToSummary}>
                <h1>
                    <Text as="span" style="heading 1">
                        Your {AppTypeLabelMap['AppTypeCodeListGAWorkersCompensation']} Estimate
                    </Text>
                </h1>
            </HeroBanner>
            <PageLayout.Section>
                <SidebarLayout sidebar="right" gap="32">
                    <React.Fragment>
                        <StackLayout>
                            <Text style="heading 4">Estimate for effective date</Text>
                            <Text style="heading 5">
                                <DateDisplay format={DATE_FORMAT} value={startDate} />
                                {' - '}
                                <DateDisplay format={DATE_FORMAT} value={addYears(startDate, 1)} />
                            </Text>
                            <br />
                            <Card isSelected={false} locked={false}>
                                <Card.Header>
                                    <Icon name="worker" />
                                    <Text style="heading 5">
                                        {AppTypeLabelMap['AppTypeCodeListGAWorkersCompensation']}
                                    </Text>
                                </Card.Header>
                                <Card.Body>
                                    <StackLayout>
                                        <Text>
                                            Workers Compensation insurance protects businesses and
                                            employees by providing wage replacement and medical
                                            benefits to employees who become injured or disabled on
                                            the job or as a result of their employment.
                                        </Text>
                                        <ColumnLayout>
                                            <BoxLayout gap="12" className="u-1/2">
                                                <Text style="body 1">Each Accident</Text>
                                                <Text>
                                                    <MoneyDisplay value={eachAccident} />
                                                </Text>
                                            </BoxLayout>
                                            <BoxLayout gap="12" className="u-1/2">
                                                <Text style="body 1">Each Employee</Text>
                                                <Text>
                                                    <MoneyDisplay value={eachEmployee} />
                                                </Text>
                                            </BoxLayout>
                                        </ColumnLayout>
                                        <BoxLayout gap="12" className="u-1/2">
                                            <Text style="body 1">Total Limit</Text>
                                            <Text>
                                                <MoneyDisplay value={totalLimit} />
                                            </Text>
                                        </BoxLayout>
                                        <Text as="span" style="body 1">
                                            Estimated annual premium:{' '}
                                            <Text as="span" style="heading 5">
                                                <MoneyDisplay value={premium} />
                                            </Text>
                                        </Text>
                                        <br />
                                        <ColumnLayout split="-1">
                                            <Text as="span" style="label 1">
                                                Blanket Waiver of Subrogation
                                            </Text>
                                            <TextButton target="_blank" href={WOS_PDF_URL}>
                                                What is the difference?
                                            </TextButton>
                                        </ColumnLayout>
                                        {wos ? (
                                            <Text style="label 1">
                                                Include Blanket Waiver of Subrogation
                                            </Text>
                                        ) : (
                                            <Text style="label 1">
                                                Exclude Blanket Waiver of Subrogation
                                            </Text>
                                        )}
                                        <Text as="span" style="body 2">
                                            (Based on your answers, we recommend you{' '}
                                            {isWosRecommended ? '' : 'do not'} include it.)
                                        </Text>
                                    </StackLayout>
                                </Card.Body>
                            </Card>
                            <Text>Carrier: {insurerName}</Text>
                            <Text>
                                A broker is reviewing your application. Embroker will contact you
                                with next steps.
                            </Text>
                        </StackLayout>
                        <SidebarLayout.MobileFooter>
                            <ColumnLayout>
                                <StackLayout gap="8">
                                    <Text>Total amount: </Text>
                                    <Text style="heading 5">
                                        <MoneyDisplay value={premium} />
                                    </Text>
                                </StackLayout>

                                <SidebarLayout.Link panelIndex={1}>
                                    Show Coverages
                                </SidebarLayout.Link>
                            </ColumnLayout>
                        </SidebarLayout.MobileFooter>
                    </React.Fragment>
                    <StickyLayout gap="32">
                        <StackLayout gap="32">
                            <InvoiceTable>
                                <InvoiceTable.Section>
                                    <InvoiceTable.Subtotal
                                        data-e2e="selected-coverages-list"
                                        title="Coverages"
                                    />
                                    <InvoiceTable.Item
                                        title={
                                            AppTypeLabelMap['AppTypeCodeListGAWorkersCompensation']
                                        }
                                    >
                                        <MoneyDisplay value={premium} />
                                    </InvoiceTable.Item>
                                </InvoiceTable.Section>
                                <InvoiceTable.Section>
                                    <InvoiceTable.Total title="Estimated annual premium">
                                        <MoneyDisplay value={premium} />
                                    </InvoiceTable.Total>
                                </InvoiceTable.Section>
                            </InvoiceTable>
                            <TextButton
                                size="small"
                                icon="bold-caret-left"
                                onClick={navigateToSummary}
                            >
                                Back to summary
                            </TextButton>
                        </StackLayout>
                    </StickyLayout>
                </SidebarLayout>
            </PageLayout.Section>
        </React.Fragment>
    );
};
