import { Props } from '@embroker/shotwell/core/types';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { State, State as LocationState } from '@embroker/shotwell/core/types/StateList';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    Button,
    ButtonBar,
    Form,
    FormLayout,
    SidebarLayout,
    StackLayout,
    StatusMessage,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React, { useMemo, useState } from 'react';
import { FileUpload } from '../../../../documents/view/FileUpload';
import { UploadFile } from '../../../../documents/view/UploadFile/UploadFile';
import { CertificateEntityRole } from '../../../types/CertificateEntityRole';
import { CertificateProducer } from '../../../types/CertificateProducer';
import { SelfServingCertificateCoverageList } from '../../../types/SelfServingCertificateCoverage';
import { ShareCertificate, ShareCertificateRequest } from '../../../useCases/ShareCertificate';
import { StepHelpGuidelines } from './UploadFileStepGuidelines';

export interface UploadFileStepItemData {
    id: UUID;
    name: string;
    type: string;
    dateUploaded: Date;
    fileKey: string;
    isActive: boolean;
}

export interface UploadFileStepFormData {
    uploadSubmitRequest: UploadSubmitRequestData;
    documentList: UploadFileStepItemData[];
    specialWording: string;
}

export interface UploadSubmitRequestData {
    certificateOwner: Props<CertificateEntityRole>;
    certificateProducer: Props<CertificateProducer>;
    businessName: string;
    emailAddress?: EmailAddress;
    address1: string;
    address2?: string;
    city: string;
    state: LocationState;
    zip: ZipCode;
    referenceNumber?: string;
    selectedCoverageList: SelfServingCertificateCoverageList;
}

export interface InitialData {
    uploadSubmitRequestData: UploadSubmitRequestData;
    documentList: UploadFileStepItemData[];
    submissionFiles: FileUpload[];
    specialWording: string;
}

const UploadFileStepItemSchema = Joi.object({
    id: UUID.schema.optional(),
    name: Joi.string().required(),
    fileKey: Joi.string().required(),
    dateUploaded: Joi.date().required(),
    isActive: Joi.boolean().required(),
    type: Joi.string().required(),
});

function createUploadFileStepForm(onSubmitRequest: (isSuccess: boolean) => void) {
    return createForm<UploadFileStepFormData>({
        fields: {
            uploadSubmitRequest: {
                type: 'hidden',
                validator: Joi.object({
                    certificateOwner: CertificateEntityRole.schema.required(),
                    certificateProducer: CertificateProducer.schema.required(),
                    businessName: Joi.string().max(69).required(),
                    emailAddress: EmailAddress.schema.optional(),
                    address1: Joi.string().required(),
                    address2: Joi.string().optional().allow(''),
                    city: Joi.string().required(),
                    state: State.schema.required(),
                    zip: ZipCode.schema.required(),
                    referenceNumber: Joi.string().optional(),
                    selectedCoverageList: Joi.array().optional(),
                }).preferences({ presence: 'required' }),
                formatValidationError(error) {
                    return error.message;
                },
            },
            documentList: {
                type: 'text',
                validator: Joi.array().items(UploadFileStepItemSchema).optional(),
            },
            specialWording: {
                type: 'textarea',
                validator: Joi.string().required().trim().min(1),
                formatValidationError(error) {
                    switch (error.details.validator) {
                        case 'any.required':
                        case 'string.empty':
                            return 'Please specify the wording that you would like on the certificate.';
                        default:
                            return error.message;
                    }
                },
            },
        },
        submit: async (data) => {
            return await execute(ShareCertificate, buildShareCertificateRequestData(data));
        },
        onSuccess: () => {
            onSubmitRequest(true);
        },
        onFailure: () => {
            onSubmitRequest(false);
        },
    });
}

interface UploadFileStepProps {
    initialData: InitialData;
    onUploaded(data: UploadFileStepItemData[]): void;
    onRemoved(data: UploadFileStepItemData[]): void;
    onSelected(data: FileUpload[], specialWording: string): void;
    onSubmitRequest(isSuccess: boolean): void;
    onBack(): void;
}

export function UploadFileStep({
    initialData,
    onSubmitRequest,
    onBack,
    onUploaded,
    onRemoved,
    onSelected,
}: UploadFileStepProps) {
    const [submissionFiles, setSubmissionFiles] = useState<FileUpload[]>(
        initialData.submissionFiles,
    );
    const [errorMessage, setErrorMessage] = useState(false);
    const uploadFileStepForm = useMemo(
        () => createUploadFileStepForm(onSubmitRequest),
        [onSubmitRequest],
    );
    const { fields, value, submit, setValue } = useForm(uploadFileStepForm, {
        documentList: initialData.documentList,
        specialWording: initialData.specialWording,
        uploadSubmitRequest: {
            certificateOwner: initialData.uploadSubmitRequestData.certificateOwner,
            certificateProducer: initialData.uploadSubmitRequestData.certificateProducer,
            businessName: initialData.uploadSubmitRequestData.businessName,
            emailAddress: initialData.uploadSubmitRequestData.emailAddress,
            address1: initialData.uploadSubmitRequestData.address1,
            address2: initialData.uploadSubmitRequestData.address2,
            city: initialData.uploadSubmitRequestData.city,
            state: initialData.uploadSubmitRequestData.state,
            zip: initialData.uploadSubmitRequestData.zip,
            referenceNumber: initialData.uploadSubmitRequestData.referenceNumber,
            selectedCoverageList: initialData.uploadSubmitRequestData.selectedCoverageList,
        },
    });
    const isUploading = () => {
        return submissionFiles.find((item) => item.s3FileKey === null) !== undefined;
    };

    const handleSelected = (newFiles: FileUpload[]) => {
        newFiles = submissionFiles.concat(newFiles);
        setSubmissionFiles(newFiles);
        onSelected(newFiles, value.specialWording);
    };

    const handleRemoved = (removedFile: FileUpload, newFiles: FileUpload[]) => {
        setSubmissionFiles(newFiles);
        const documentListWithoutRemovedFile = value.documentList.filter(
            (item) => item.id != removedFile.id,
        );
        setValue({
            ...value,
            documentList: documentListWithoutRemovedFile,
        });
        onRemoved(documentListWithoutRemovedFile);
        onSelected(newFiles, value.specialWording);
    };

    const handleUploaded = (uploadedFile: FileUpload, newFiles: FileUpload[]) => {
        if (uploadedFile.s3FileKey == null) {
            setErrorMessage(true);
            return;
        }
        setErrorMessage(false);
        setSubmissionFiles(newFiles);
        const uploadedDocument: UploadFileStepItemData = {
            type: 'CertificateDocumentTypeCodeListAdditonalInstructions',
            id: uploadedFile.id,
            name: uploadedFile.file.name,
            fileKey: uploadedFile.s3FileKey,
            isActive: false,
            dateUploaded: new Date(Date.now()),
        };
        setValue({
            ...value,
            documentList: [...value.documentList, uploadedDocument],
        });
        const documentList = [...value.documentList, uploadedDocument];
        onUploaded(documentList);
        onSelected(newFiles, value.specialWording);
    };

    return (
        <SidebarLayout appearance="default" sidebarVariant="compact">
            <StepHelpGuidelines />
            <StackLayout>
                <FormLayout appearance="wide">
                    <StackLayout gap="64">
                        <Form>
                            <StackLayout gap={'48'}>
                                <StackLayout gap={'24'}>
                                    <Text style={'heading 5'}>
                                        Do you need to add special wording to the certificate?
                                    </Text>
                                    <Form.Field
                                        inputProps={{
                                            ...fields.specialWording.props,
                                        }}
                                        label="Special Wording"
                                        messages={fields.specialWording.messages}
                                        type={fields.specialWording.type}
                                    />
                                </StackLayout>
                                <StackLayout gap={'16'}>
                                    <Text style={'heading 5'}>
                                        Please upload any certificate or special wording documents
                                        here
                                    </Text>
                                    <Form.Field messages={fields.documentList.messages}>
                                        <UploadFile
                                            fileTypeFilters={['application/pdf']}
                                            onSelected={handleSelected}
                                            onUploaded={handleUploaded}
                                            onRemoved={handleRemoved}
                                            showButton
                                            isMultiple
                                            buttonText={'Choose files'}
                                            files={submissionFiles}
                                            placeholder={'or drag file here.'}
                                        />
                                        {errorMessage ? (
                                            <StatusMessage status="error">{`Error while uploading document`}</StatusMessage>
                                        ) : null}
                                    </Form.Field>
                                    <StackLayout gap={'none'}>
                                        <Form.Field
                                            label="Submit request data"
                                            messages={fields.uploadSubmitRequest.messages}
                                        />
                                    </StackLayout>
                                </StackLayout>
                            </StackLayout>
                        </Form>
                        <ButtonBar
                            split={'-1'}
                            reverse
                            responsive={{ containerWidth: { smallerThan: 400 } }}
                        >
                            <TextButton onClick={onBack}>Back</TextButton>
                            <Button disabled={isUploading()} onClick={submit}>
                                Submit Request
                            </Button>
                        </ButtonBar>
                    </StackLayout>
                </FormLayout>
                <SidebarLayout.MobileFooter split="-1">
                    <Text>What does this mean?</Text>
                    <SidebarLayout.Link appearance="secondary" panelIndex={1}>
                        Let us explain
                    </SidebarLayout.Link>
                </SidebarLayout.MobileFooter>
            </StackLayout>
        </SidebarLayout>
    );
}

function buildShareCertificateRequestData(
    uploadFileStepFormData: UploadFileStepFormData,
): ShareCertificateRequest {
    const documentList: Array<UploadFileStepItemData> = (
        uploadFileStepFormData?.documentList ?? []
    ).map((document) => document);

    return {
        certificateOwner: uploadFileStepFormData.uploadSubmitRequest.certificateOwner,
        certificateProducer: uploadFileStepFormData.uploadSubmitRequest.certificateProducer,
        companyName: uploadFileStepFormData.uploadSubmitRequest.businessName,
        email: uploadFileStepFormData.uploadSubmitRequest.emailAddress,
        mailingAddress: uploadFileStepFormData.uploadSubmitRequest.address1,
        mailingAddressCont: uploadFileStepFormData.uploadSubmitRequest.address2,
        city: uploadFileStepFormData.uploadSubmitRequest.city,
        state: uploadFileStepFormData.uploadSubmitRequest.state,
        zipCode: uploadFileStepFormData.uploadSubmitRequest.zip,
        referenceNumber: uploadFileStepFormData.uploadSubmitRequest.referenceNumber,
        renewal: true,
        additionalInsuredName: undefined,
        additionalInstructions: undefined,
        specialWording: uploadFileStepFormData.specialWording,
        documentList: documentList,
        isSelfServing: true,
        selectedCoverageList: uploadFileStepFormData.uploadSubmitRequest.selectedCoverageList,
    } as ShareCertificateRequest;
}
