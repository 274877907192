import { Modal, ModalActions, ModalState, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { ModalLayout } from '../../../../view/components/ModalLayout.view';

export function BrokerNotLicensedInStateModal({ modal }: { modal: ModalActions & ModalState }) {
    const { navigate } = useNavigation();

    return (
        <Modal {...modal} size="small" dismissable={false}>
            <ModalLayout
                title="Something's Missing Here"
                primaryAction={{
                    label: 'Back To Dashboard',
                    onClick: () => navigate('/broker/dashboard'),
                    'data-e2e': 'back-to-dashboard',
                }}
                secondaryAction={{
                    label: 'Open Live Chat',
                    href: '#chat-now',
                    'data-e2e': 'open-live-chat',
                }}
                data-e2e="decline-modal"
            >
                <Text data-e2e="decline-modal-text">
                    Embroker does not have a surplus line license on file for the state you are
                    attempting to quote. Contact us with the license information to release the
                    quote.
                </Text>
                <Text>
                    Or you can contact us directly via live chat or call us at 844.436.2765.
                </Text>
            </ModalLayout>
        </Modal>
    );
}
