import React, { useContext, useMemo, useState } from 'react';
import { GetActiveOrganizationProfile } from '@app/userOrg/useCases/GetActiveOrganizationProfile';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    Button,
    ButtonBar,
    GridLayout,
    Modal,
    PageLayout,
    Spinner,
    StackLayout,
    StatusMessage,
    Text,
    TextButton,
    useModal,
    usePageLayout,
    useResponsive,
} from '@embroker/ui-toolkit/v2';
import {
    DirectorsAndOfficersCard,
    EPLCard,
    FiduciaryCard,
    TechEOCard,
    TechVerticalProductSelectionCardProps,
} from './TechVerticalProductSelectionCardTemplates';
import { TechVerticalProductSelectionPageBanner } from './TechVerticalProductSelectionPageBanner.view';
import { AppContext } from '@app/view/AppContext';
import { TechVerticalProductSelectionCardSlideout } from './TechVerticalProductSelectionCardSlideout.view';
import { ModalLayout } from '@app/view/components/ModalLayout.view';
import { PublishShoppingUserClickEvent } from '@app/shopping/useCases/PublishShoppingUserClickEvent';
import { execute } from '@embroker/shotwell/core/UseCase';
import { StartGenericBundleRequest, useStartApplication } from '../../hooks/useStartApplication';
import { AppTypeCodeListManualEmploymentPractices } from '@app/shopping/types/enums';
import { HeaderView } from '@app/view/components/Header.view';

export enum TechVerticalCoverageType {
    techEO = 'techEO',
    directorsAndOfficers = 'directorsAndOfficers',
    employmentPractices = 'employmentPractices',
    fiduciary = 'fiduciary',
}

export type TechCoverageTypes = keyof typeof TechVerticalCoverageType;

const TECH_VERTICAL_COVERAGES: TechVerticalCoverageType[] = [
    TechVerticalCoverageType.techEO,
    TechVerticalCoverageType.directorsAndOfficers,
    TechVerticalCoverageType.employmentPractices,
    TechVerticalCoverageType.fiduciary,
];

export const coverageToCardMap: {
    [key in TechVerticalCoverageType]: React.FC<TechVerticalProductSelectionCardProps>;
} = {
    techEO: TechEOCard,
    directorsAndOfficers: DirectorsAndOfficersCard,
    employmentPractices: EPLCard,
    fiduciary: FiduciaryCard,
} as const;
export interface TechVerticalProductSelectionPageProps {
    onBackClicked: () => void;
    onContinueClicked: (startGenericBundleRequest: StartGenericBundleRequest) => void;
}

export function TechVerticalProductSelectionPage({
    onBackClicked,
    onContinueClicked,
}: TechVerticalProductSelectionPageProps) {
    const { setSlideout, closeSlideout } = useContext(AppContext);
    const [showWarningMessage, setShowWarningMessage] = useState(false);
    const modal = useModal({ visible: false });
    const { startApplication } = useStartApplication();

    const pageLayout = usePageLayout({
        header: <HeaderView />,
    });

    const [selectedCoverages, setSelectedCoverages] = useState<TechCoverageTypes[]>([
        ...TECH_VERTICAL_COVERAGES,
    ]);

    const { result: getActiveOrganizationProfileResp, isLoading } = useUseCase(
        GetActiveOrganizationProfile,
    );

    const organization =
        getActiveOrganizationProfileResp && isOK(getActiveOrganizationProfileResp)
            ? getActiveOrganizationProfileResp.value.organization
            : undefined;

    const organizationName = organization?.companyLegalName;
    const organizationState = organization?.headquarters.state;
    const organizationNaics = organization?.naics;
    const organizationIsFunded = Boolean(organization?.raisedFunding);

    const availableCoverages = !organizationIsFunded
        ? TECH_VERTICAL_COVERAGES.filter((coverage) => coverage == TechVerticalCoverageType.techEO)
        : TECH_VERTICAL_COVERAGES;

    const isMobile = useResponsive({ screenWidth: { smallerThan: 'large-tablet' } });
    const isDesktop = useResponsive({ screenWidth: { smallerThan: 'large-desktop' } });

    const gridCardClassName = isDesktop ? 'u-grid-column-span-4' : 'u-grid-column-span-6';

    const toggleCard = (appType: TechVerticalCoverageType) => {
        setSelectedCoverages((currentArr) => {
            if (currentArr.includes(appType)) {
                return currentArr.filter((item) => item !== appType);
            }
            return currentArr.concat(appType);
        });
        setShowWarningMessage(false);
    };

    const handleTooltipClick = (type: TechCoverageTypes) => {
        switch (type) {
            case TechVerticalCoverageType.techEO:
                setSlideout(
                    <TechVerticalProductSelectionCardSlideout
                        headerIcon="chip"
                        headerTitle="Comprehensive protection for tech and data-related risks"
                        coverageFullTitle="Technology Errors and Omissions / Cyber"
                        coverage="Tech E&O / Cyber"
                        description={[
                            'Data breaches, cyberattacks, or claims of a software or coding error — each of these can be devastating for tech pros like you.',
                            'Tech E&O / Cyber insurance is specially designed to address such risks. The E&O part covers liabilities related to products or services — like your software failing to perform as intended — while Cyber can cover financial losses following a cyber event.',
                        ]}
                        checkList={[
                            'Lawsuits alleging mistakes, omissions or negligence in providing your professional services',
                            'Claims you missed a deadline or went over budget',
                            'Costs to restore your company’s damaged security system',
                            'Software copyright code infringement',
                        ]}
                        handleDismiss={closeSlideout}
                    />,
                );
                break;
            case TechVerticalCoverageType.directorsAndOfficers:
                setSlideout(
                    <TechVerticalProductSelectionCardSlideout
                        headerIcon="meeting"
                        headerTitle="Protection for your leadership team against costly lawsuits"
                        coverageFullTitle="Directors and Officers"
                        coverage="D&O"
                        description={[
                            'Directors and officers of tech companies face risks — from shareholder disputes and claims alleging mismanagement to regulatory investigations. The stakes can be high.',
                            'Directors and officers (D&O) liability insurance protects covered executives and company assets if they’re sued for actions taken on behalf of the company. It pays legal fees, settlements and judgments, safeguarding them from financial loss. ',
                        ]}
                        checkList={[
                            'Claims alleging misleading financial statements ',
                            'Claims arising from mergers, acquisitions, or divestitures',
                            'Defense costs/settlements for covered regulatory investigations ',
                            'Lawsuits brought on by shareholders on behalf of the company',
                        ]}
                        handleDismiss={closeSlideout}
                    />,
                );
                break;
            case TechVerticalCoverageType.employmentPractices:
                setSlideout(
                    <TechVerticalProductSelectionCardSlideout
                        headerIcon="suitcase"
                        headerTitle="Comprehensive protection against employment-related risks"
                        coverageFullTitle="Employment Practices Liability Insurance"
                        coverage="EPLI"
                        description={[
                            'Wrongful termination, discrimination, failure to promote, wage and hour disputes — these are just some of the employment-related risks tech companies like yours can face. A single claim or lawsuit can cause irreparable financial or reputational damage.',
                            'Employment Practices Liability Insurance (EPLI) protects your business by covering legal defense costs, and settlements or judgments required to be paid under a covered claim.',
                        ]}
                        checkList={[
                            'Claims alleging unfair promotion practices or unjust dismissal',
                            'Financial loss due to covered settlements or court awards',
                            'Claims of sexual, racial, or other forms of harassment',
                            'Covered attorney fees and other legal defense expenses',
                        ]}
                        handleDismiss={closeSlideout}
                    />,
                );
                break;
            case TechVerticalCoverageType.fiduciary:
                setSlideout(
                    <TechVerticalProductSelectionCardSlideout
                        headerIcon="handshake"
                        headerTitle="Coverage against claims of benefit plan mismanagement"
                        coverageFullTitle="Fiduciary Liability Insurance"
                        coverage="FIDUCIARY LIABILITY"
                        description={[
                            'Managing employee benefits can come with significant risks. Participants could claim the fiduciaries administering the plan underfunded the plan, failed to protect their private data, or aren’t compliant with regulations — any one of these could result in a costly lawsuit',
                            'Fiduciary Liability insurance covers legal defense costs and financial losses from an alleged breach of fiduciary duty, including failure to administer the benefits plant according to plan documents.',
                        ]}
                        checkList={[
                            'Mistakes in enrollment, eligibility, or benefit calculations',
                            'Legal defense costs, settlements, and damages awarded by the court in findings of wrongdoing',
                            'Imprudent choice of investments or third-party vendors',
                            'Costs associated with investigating a claim',
                        ]}
                        handleDismiss={closeSlideout}
                    />,
                );
                break;
            default:
                break;
        }
    };

    const hasOnlyEPLCoverageSelected = useMemo(
        () =>
            selectedCoverages.length === 1 &&
            selectedCoverages.includes(TechVerticalCoverageType.employmentPractices),
        [selectedCoverages],
    );

    const handleSubmit = () => {
        if (hasOnlyEPLCoverageSelected && organizationState === 'CA') {
            showModal();
        } else {
            const startGenericBundleRequest: StartGenericBundleRequest =
                TECH_VERTICAL_COVERAGES.map((coverage) => ({
                    coverage,
                    selected:
                        availableCoverages.includes(coverage) &&
                        selectedCoverages.includes(coverage),
                }));
            onContinueClicked(startGenericBundleRequest);
        }
    };

    const showModal = () => {
        modal.show();
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'Product recommendation EPL CA Pop up Displayed',
        });
    };
    const onModalDismiss = () => {
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'Product recommendation EPL CA Pop up closed',
        });
    };
    const onModalClose = () => {
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'Product recommendation EPL CA Pop up closed',
        });
        setShowWarningMessage(true);
    };
    const handlePrimaryCTAClick = () => {
        execute(PublishShoppingUserClickEvent, {
            clickEventName: 'Product recommendation EPL CA Pop up _ add more coverage clicked',
        });
        setShowWarningMessage(true);
        modal.hide();
    };
    const handleSecondaryCTAClick = () => {
        startApplication({ selectedAppTypes: [AppTypeCodeListManualEmploymentPractices] });
        modal.hide();
    };

    if (isLoading) {
        return <Spinner />;
    }

    const canSelectMultipleCoverages = availableCoverages.length > 1;

    const getHeadeTitle = () => {
        switch (organization?.naics) {
            case '541512':
            case '541690': {
                return `${organizationName}, start covering your bases with our tailored Technology/IT Consultant Package.`;
            }
            default: {
                return `${organizationName}, start covering your bases with our tailored Startup package.`;
            }
        }
    };

    return (
        <PageLayout {...pageLayout}>
            <PageLayout.Section>
                <StackLayout gap="32">
                    <GridLayout>
                        <Text className="u-grid-size-12" style="heading 2">
                            {getHeadeTitle()}
                        </Text>
                    </GridLayout>

                    <GridLayout>
                        <TechVerticalProductSelectionPageBanner className="u-grid-size-12" />
                    </GridLayout>

                    {showWarningMessage && hasOnlyEPLCoverageSelected && (
                        <GridLayout>
                            <StatusMessage status="warning" className="u-grid-size-12">
                                EPLI coverage can only be purchased with another coverage. Please
                                select one more coverage, or continue with a non-digital quote.
                            </StatusMessage>
                        </GridLayout>
                    )}

                    <GridLayout>
                        {availableCoverages.map((coverage) => {
                            const Card = coverageToCardMap[coverage];
                            return (
                                <Card
                                    key={coverage}
                                    canSelect={canSelectMultipleCoverages}
                                    className={gridCardClassName}
                                    isSelected={selectedCoverages.includes(coverage)}
                                    naicsCode={organizationNaics}
                                    onSelectionChange={toggleCard}
                                    handleTooltipClick={() => handleTooltipClick(coverage)}
                                />
                            );
                        })}
                    </GridLayout>

                    <GridLayout>
                        <StackLayout className="u-grid-size-12" gap={isMobile ? '32' : '48'}>
                            <StackLayout gap="none">
                                <Text style="microcopy">
                                    *Discount subject to receiving a SecurityScorecard rating of
                                    A-B.
                                </Text>
                                <Text style="microcopy">
                                    <strong>Please note</strong>: Changes to your cybersecurity
                                    rating won't lower your current premium, but will be credited at
                                    policy renewal.
                                </Text>
                            </StackLayout>

                            <ButtonBar split={isMobile ? '1' : '-2'}>
                                <TextButton
                                    icon="thin-arrow-left"
                                    iconPosition="left"
                                    iconSize="medium"
                                    onClick={onBackClicked}
                                >
                                    Back
                                </TextButton>
                                <Button
                                    disabled={
                                        canSelectMultipleCoverages && !selectedCoverages.length
                                    }
                                    onClick={handleSubmit}
                                >
                                    Continue Application
                                </Button>
                            </ButtonBar>
                        </StackLayout>
                    </GridLayout>
                </StackLayout>
                <Modal
                    {...modal}
                    onDismiss={onModalDismiss}
                    onClose={onModalClose}
                    size="small"
                    dismissable
                >
                    <ModalLayout
                        title="Looks like you’re trying to get EPLI on its own"
                        primaryAction={{
                            label: 'Add Another Coverage',
                            onClick: () => handlePrimaryCTAClick(),
                        }}
                        secondaryAction={{
                            label: 'Get EPLI Only',
                            onClick: () => handleSecondaryCTAClick(),
                        }}
                    >
                        <StackLayout gap="12">
                            <Text style="body 1">
                                Unfortunately, in California, you can only get an EPLI policy online
                                if it’s packaged with other products.
                            </Text>
                            <Text style="body 1">
                                To get EPLI digitally, simply select at least one other coverage.
                            </Text>
                            <Text style="body 1">
                                If you do want EPLI only, you'll need to provide some additional
                                information so our team can find the best fit for your business.
                            </Text>
                        </StackLayout>
                    </ModalLayout>
                </Modal>
            </PageLayout.Section>
        </PageLayout>
    );
}
