import { Nullable } from '@embroker/shotwell/core/types';
import {
    defineValidator,
    Joi,
    Schema,
    TypeChecker,
    Validator,
} from '@embroker/shotwell/core/validation/schema';
import { AutoLiabilitySection } from './AutoLiabilitySection';
import { LiabilitySection } from './LiabilitySection';
import { PropertySection } from './PropertySection';
import { UmbrellaExcessLiabilitySection } from './UmbrellaExcessLiabilitySection';
import { WorkersCompensationLiabilitySection } from './WorkersCompensationLiabilitySection';

const LobTypeList = [
    'autoLiabilityLob',
    'cyberLiabilityLob',
    'directorsAndOfficersLiabilityLob',
    'employmentPracticesLiabilityLob',
    'generalLiabilityLob',
    'otherLiabilityLob',
    'ownersAndContractorsLiabilityLob',
    'professionalLiabilityLob',
    'umbrellaExcessLiabilityLob',
    'propertyLob',
    'workersCompensationLiabilityLob',
    'fiduciaryLiabilityLob',
    'excessLiabilityLob',
    'cyberInsuranceLob',
    'accountantsProfessionalLiabilityLob',
    'nonTechnologyBusinessAndManagementConsultantProfessionalLiabilityLob',
    'taxPreparersAndBookkeepersProfessionalLiabilityLob',
    'realEstateAgentsProfessionalLiabilityLob',
    'homeInspectorsProfessionalLiabilityLob',
];

export type LobType = (typeof LobTypeList)[number];

export interface LobTypeValidator {
    /**
     * A Joi schema matching a valid LobType object.
     */
    readonly schema: Schema.StringSchema;
    /**
     * Type predicate that checks if a given value can be used as LobType type.
     *
     * Use this only to do early returns. It's recommended to use validate()
     * before using an unknown value as LobType value as it normalizes the value
     * in addition to performing the same validation as check().
     */
    readonly check: TypeChecker<LobType>;
    /**
     * Validates and normalizes the given value to a LobType type.
     *
     * This should be used for all untrusted inputs to verify and, if required
     * (and possible), normalize the input.
     */
    readonly validate: Validator<LobType>;
}

export const LobType: LobTypeValidator = defineValidator<LobType>(
    Joi.string().valid(...LobTypeList),
);

export interface Lob {
    /**
     * Lob type
     */
    readonly lobType: LobType;
    /**
     * Liability section
     */
    readonly liabilitySection: Nullable<LiabilitySection>;
    /**
     * Property section
     */
    readonly propertySection: Nullable<PropertySection>;
}

export interface LobValidator {
    /**
     * A Joi schema matching a valid LobAuto object.
     */
    readonly schema: Schema.ObjectSchema<Lob>;
    /**
     * Type predicate that checks if a given value can be used as AutoLiabilitySection object.
     *
     * Use this only to do early returns. It's recommended to use validate()
     * before using an unknown value as AutoLiabilitySection object as it normalizes the value
     * in addition to performing the same validation as check().
     */
    readonly check: TypeChecker<Lob>;
    /**
     * Validates and normalizes the given value to a AutoLiabilitySection object.
     *
     * This should be used for all untrusted inputs to verify and, if required
     * (and possible), normalize the input.
     */
    readonly validate: Validator<Lob>;
}

export const Lob = {
    ...defineValidator<Lob>({
        lobType: Joi.string().valid(...LobTypeList),
        liabilitySection: LiabilitySection.schema,
        propertySection: PropertySection.schema.allow(null),
    }),
    create(lob: Lob) {
        return Lob.validate(lob);
    },
};

export const LobAuto = {
    ...defineValidator<Lob>({
        lobType: Joi.string().valid('autoLiabilityLob'),
        liabilitySection: AutoLiabilitySection.schema,
        propertySection: PropertySection.schema.allow(null).required(),
    }),
    create(liabilitySection: AutoLiabilitySection) {
        const lob = {
            lobType: 'autoLiabilityLob',
            liabilitySection,
            propertySection: null,
        };
        return LobAuto.validate(lob);
    },
};

export const LobUmbrellaExcess = {
    ...defineValidator<Lob>({
        lobType: Joi.string().valid('umbrellaExcessLiabilityLob'),
        liabilitySection: UmbrellaExcessLiabilitySection.schema,
        propertySection: PropertySection.schema.allow(null).required(),
    }),
    create(liabilitySection: UmbrellaExcessLiabilitySection) {
        const lob = {
            lobType: 'umbrellaExcessLiabilityLob',
            liabilitySection,
            propertySection: null,
        };
        return LobUmbrellaExcess.validate(lob);
    },
};

export const LobExcessTechEOCyber = {
    ...defineValidator<Lob>({
        lobType: Joi.string().valid('excessLiabilityLob'),
        liabilitySection: UmbrellaExcessLiabilitySection.schema,
        propertySection: PropertySection.schema.allow(null).required(),
    }),
    create(liabilitySection: UmbrellaExcessLiabilitySection) {
        const lob = {
            lobType: 'excessLiabilityLob',
            liabilitySection,
            propertySection: null,
        };
        return LobExcessTechEOCyber.validate(lob);
    },
};

export const LobWorkersCompensation = {
    ...defineValidator<Lob>({
        lobType: Joi.string().valid('workersCompensationLiabilityLob'),
        liabilitySection: WorkersCompensationLiabilitySection.schema,
        propertySection: PropertySection.schema.allow(null).required(),
    }),
    create(liabilitySection: WorkersCompensationLiabilitySection) {
        const lob = {
            lobType: 'workersCompensationLiabilityLob',
            liabilitySection,
            propertySection: null,
        };
        return LobWorkersCompensation.validate(lob);
    },
};

export const LobProperty = {
    ...defineValidator<Lob>({
        lobType: Joi.string().valid('propertyLob'),
        liabilitySection: LiabilitySection.schema.allow(null).required(),
        propertySection: PropertySection.schema,
    }),
    create(propertySection: PropertySection) {
        const lob = {
            lobType: 'propertyLob',
            liabilitySection: null,
            propertySection,
        };
        return LobProperty.validate(lob);
    },
};

export function getLOBName(lobType: LobType): string {
    if (lobType === 'cyberLiabilityLob') {
        return 'Technology E&O/Cyber';
    } else if (lobType === 'nonTechnologyBusinessAndManagementConsultantProfessionalLiabilityLob') {
        return 'Non-Technology Business and Management Consultants Professional Liability';
    } else if (lobType === 'workersCompensationLiabilityLob') {
        return 'Workers Compensation';
    }

    return convertLobTypeToDisplayName(lobType);
}

export function convertLobTypeToDisplayName(lobType: LobType): string {
    const lobEndLength = 4;
    let sentenceCase = lobType.replace(/([a-z])([A-Z])/g, '$1 $2');
    sentenceCase = sentenceCase.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
    return (
        sentenceCase.charAt(0).toUpperCase() +
        sentenceCase.slice(1, sentenceCase.length - lobEndLength)
    );
}
