import { FormData, OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { Button, ColumnLayout, TextButton } from '@embroker/ui-toolkit/v2';
import React, { useState } from 'react';
import { WizardForm } from '../../../../view/hooks/useWizardForm';
import { PCoMLQuote } from '../../entities/PCoMLQuote';

interface BrokerPcomlReferredNavigationProps<T extends FormData> {
    trigger: WizardForm<OpaqueForm<T>>['trigger'];
    quote: PCoMLQuote;
    hasNext: boolean;
    next: WizardForm<OpaqueForm<T>>['next'];
    hasPrevious: boolean;
    previous: WizardForm<OpaqueForm<T>>['previous'];
    isSubmitting: boolean;
    status: WizardForm<OpaqueForm<T>>['status'];
}

export function BrokerPcomlReferredNavigation<T extends FormData>({
    trigger,
    quote,
    hasNext,
    next,
    hasPrevious,
    previous,
    isSubmitting,
    status,
}: BrokerPcomlReferredNavigationProps<T>) {
    const [higherLimitsRequested, setHigherLimitsRequested] = useState(false);

    const existingCoverageReferral = quote?.applicationInfo?.existingCoverageReferral;
    const higherLimitsReferral = quote?.status === 'referred';

    const handleReferred = () => {
        if (higherLimitsReferral) {
            setHigherLimitsRequested(true);
            trigger('requestHigherLimits');
        } else if (hasNext) {
            next();
        }
    };

    const resolveNextButtonLabel = () => {
        if (higherLimitsReferral) {
            return higherLimitsRequested ? 'Higher Limit Requested' : 'Request Higher Limit';
        }
        return hasNext ? 'Continue' : 'Bind coverage';
    };

    const invalid = status === 'invalid';
    const shouldDisableBindButton = existingCoverageReferral && !hasNext;
    const shouldDisableContinueButton = higherLimitsReferral && higherLimitsRequested;

    const isNextButtonDisabled = invalid || shouldDisableContinueButton || shouldDisableBindButton;

    return (
        <ColumnLayout split="-1">
            <Button disabled={isNextButtonDisabled} onClick={handleReferred}>
                {resolveNextButtonLabel()}
            </Button>
            <TextButton
                as="button"
                className={hasPrevious ? '' : 'u-hidden'}
                icon="bold-caret-left"
                disabled={isSubmitting}
                onClick={previous}
            >
                Back to options
            </TextButton>
        </ColumnLayout>
    );
}
