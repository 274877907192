import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';

export interface SendEnterSelfServingFlowRequest {
    organizationId: UUID;
}

export interface SelfServingFlowEnteredEvent extends DomainEvent {
    origin: 'Certificates';
    companyId: UUID;
}

export interface SendEnterSelfServingFlow extends UseCase {
    execute({ organizationId }: SendEnterSelfServingFlowRequest): AsyncResult<void>;
}

@injectable()
class SendEnterSelfServingFlowEventUseCase extends UseCase implements SendEnterSelfServingFlow {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Certificates/SendEnterSelfServingFlowEventUseCase');

    /**
     * Constructor for SendEnterSelfServingFlow class instance
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    /**
     * Executes the GetIssuedCertificates use case.
     * Input is of type GetIssuedCertificatesRequest
     */
    public async execute({ organizationId }: SendEnterSelfServingFlowRequest): AsyncResult<void> {
        const enteredSelfServingFlow: SelfServingFlowEnteredEvent = {
            id: UUID.create(),
            origin: 'Certificates',
            name: 'SelfServingFlowEntered',
            createdAt: new Date(Date.now()),
            companyId: organizationId,
        };
        await this.eventBus.publish(enteredSelfServingFlow);

        return Success();
    }
}

export const SendEnterSelfServingFlow: UseCaseClass<SendEnterSelfServingFlow> =
    SendEnterSelfServingFlowEventUseCase;
