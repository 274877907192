import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { InvoiceTable, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { BOPHartfordQuote } from '@app/quote/hartford-bop/entities/BOPHartfordQuote';

interface BOPHartfordQuoteSummaryProps {
    quote: BOPHartfordQuote;
}

export function BOPHartfordQuoteSummary({ quote }: BOPHartfordQuoteSummaryProps) {
    return quote ? (
        <StackLayout gap="16">
            <InvoiceTable.Section>
                <InvoiceTable.Item
                    title={
                        <React.Fragment>
                            <Text style="body 1" as="span" data-e2e="coverage-list-bop-title">
                                General Liability Policy
                            </Text>
                            <br />
                            <Text style="body 2" as="span">
                                Insurer: Hartford
                            </Text>
                        </React.Fragment>
                    }
                >
                    <Text style="body 1" as="span" data-e2e="bop-base-premium">
                        <MoneyDisplay value={quote.totalPremium} />
                    </Text>
                </InvoiceTable.Item>
                <InvoiceTable.Item
                    title={
                        <Text style="body 1" as="span" data-e2e="bop-taxes">
                            Taxes
                        </Text>
                    }
                >
                    <Text style="body 1" as="span" data-e2e="bop-taxes-value">
                        Included
                    </Text>
                </InvoiceTable.Item>
            </InvoiceTable.Section>
            <InvoiceTable.Item
                title={
                    <Text style="heading 5" as="span" data-e2e="bop-total">
                        Total Business Owners Policy
                    </Text>
                }
            >
                <Text style="heading 5" as="span" data-e2e="bop-total-value">
                    <MoneyDisplay value={quote.totalPayable} />
                </Text>
            </InvoiceTable.Item>
        </StackLayout>
    ) : null;
}
