import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { isErr, Success, SuccessResult } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { AppTypeCode } from '../../shopping/types/enums';
import { SKURepository } from '../repositories/SKURepository';
import { SKU } from '../types/SKU';

export interface CalculateSKUFromAppTypes extends UseCase {
    execute(request: CalculateSKUFromAppTypesRequest): Promise<SuccessResult<SKU | undefined>>;
}

export interface CalculateSKUFromAppTypesRequest {
    appTypeList: readonly AppTypeCode[];
}

@injectable()
class CalculateSKUFromAppTypesUseCase extends UseCase implements CalculateSKUFromAppTypes {
    public static type = Symbol('Analytics/CalculateSKUFromAppTypes');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(SKURepository) private skuRepository: SKURepository,
        @inject(Log) private logger: Logger,
    ) {
        super(eventBus);
    }

    public async execute(
        request: CalculateSKUFromAppTypesRequest,
    ): Promise<SuccessResult<SKU | undefined>> {
        const calculateSKUResult = await this.skuRepository.calculateSKUFromAppTypes(
            request.appTypeList,
        );

        if (isErr(calculateSKUResult)) {
            this.logger.warn(`Failed to calculate sku for selected app types`, calculateSKUResult);
            return Success(undefined);
        }

        return calculateSKUResult;
    }
}

export const CalculateSKUFromAppTypes: UseCaseClass<CalculateSKUFromAppTypes> =
    CalculateSKUFromAppTypesUseCase;
