import { execute } from '@embroker/shotwell/core/UseCase';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { GetOnboardingRedirect } from '../../useCases/GetOnboardingRedirect';
import { useCallback, useState } from 'react';
import { useNavigation } from 'react-navi';
import {
    getOrganizationProfileFromQuestionnaireData,
    OnboardingPrefillQuestionnaireData,
} from '../../types/OnboardingPrefillQuestionnaireData';
import { RemoveUserOnboardingDetails } from '@app/userOrg/useCases/RemoveUserOnboardingDetails';
import {
    OnboardingFlowWrapperProps,
    onboardingStepComponentMap,
} from '../components/userOnboardingFlow/OnboardingFlowWrapper';
import { GetUserOnboardingStep } from '@app/userOrg/useCases/GetUserOnboardingStep';
import { UserOnboardingStepType } from '@app/userOrg/types/UserOnboardingDetails';
import { UpdateUserOnboardingQuestionnaireData } from '@app/userOrg/useCases/UpdateUserOnboardingQuestionnaireData';
import { UpdateOrganizationProfile } from '@app/userOrg/useCases/UpdateOrganizationProfile';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { SaveOnboardingAnswers } from '@app/shoppingQuestioner/useCases/SaveOnboardingAnswers';

export interface OnboardingStepPayload {
    stepType: NonNullable<UserOnboardingStepType>;
    questionnaireData: OnboardingPrefillQuestionnaireData;
}

/**
 * A hook for exiting the user onboarding flow.
 * Will return a function called 'exitOnboardingFlow' that can be called to exit the onboarding flow.
 *
 * This hook determines where a user will enter the app using the GetOnboardingRedirect use case
 * This hook performs the clearUserOnboardingContext() action
 */
export function useUserOnboardingNavigation({
    questionnaireData: initialQuestionnaireData,
    organization,
    userOnboardingStepType,
}: OnboardingFlowWrapperProps) {
    const { navigate } = useNavigation();
    const [currentOnboardingStep, setUserOnboardingStepType] = useState<OnboardingStepPayload>({
        stepType: userOnboardingStepType,
        questionnaireData: initialQuestionnaireData || {},
    });

    const onExitOnboardingFlow = useCallback(
        async (questionnaireData: OnboardingPrefillQuestionnaireData) => {
            const redirectEndpoint = await execute(GetOnboardingRedirect, { questionnaireData });
            await execute(UpdateUserOnboardingQuestionnaireData, questionnaireData);
            if (isOK(redirectEndpoint)) {
                await execute(RemoveUserOnboardingDetails);
                navigate(redirectEndpoint.value);
            }
        },
        [navigate],
    );

    const handleGoToPreviousStep = useCallback(async () => {
        const questionnaireData = { ...currentOnboardingStep.questionnaireData };

        const userOnboardingStep = await execute(GetUserOnboardingStep, {
            currentStep: currentOnboardingStep.stepType,
            progessType: 'backward',
            questionnaireData,
        });
        const prevOnboardingStep = isOK(userOnboardingStep) ? userOnboardingStep.value : null;

        const previousStepType = prevOnboardingStep?.stepType;

        if (previousStepType) {
            setUserOnboardingStepType({
                stepType: previousStepType,
                questionnaireData,
            });
        }
    }, [currentOnboardingStep]);

    const onCompleteOnboardingStep = useCallback(
        async (currentStepQuestionnaireData?: OnboardingPrefillQuestionnaireData) => {
            if (currentStepQuestionnaireData) {
                if (organization) {
                    const organizationProfile = getOrganizationProfileFromQuestionnaireData(
                        currentStepQuestionnaireData,
                        organization.id as UUID,
                    );
                    if (organizationProfile) {
                        await execute(SaveOnboardingAnswers, { currentStepQuestionnaireData });
                        await execute(UpdateOrganizationProfile, organizationProfile);
                    }
                }
            }

            const questionnaireData = { ...currentOnboardingStep.questionnaireData };
            const updatedQuestionnaireData = {
                ...questionnaireData,
                ...currentStepQuestionnaireData,
            };

            const userOnboardingStep = await execute(GetUserOnboardingStep, {
                currentStep: currentOnboardingStep.stepType,
                progessType: 'forward',
                questionnaireData: updatedQuestionnaireData,
            });

            const nextOnboardingStep = isOK(userOnboardingStep) ? userOnboardingStep.value : null;

            if (nextOnboardingStep) {
                setUserOnboardingStepType({
                    stepType: nextOnboardingStep.stepType,
                    questionnaireData: updatedQuestionnaireData,
                });
            } else {
                onExitOnboardingFlow(updatedQuestionnaireData);
            }
        },
        [currentOnboardingStep, organization, onExitOnboardingFlow],
    );

    const firstOnboardingStep = Object.keys(onboardingStepComponentMap)[0];
    const canGoToPreviousStep = currentOnboardingStep.stepType !== firstOnboardingStep;

    return {
        onCompleteOnboardingStep,
        onGoToPreviousStep: canGoToPreviousStep ? handleGoToPreviousStep : undefined,
        currentOnboardingStep,
    };
}

export type nextOnboardingStepFunction = ReturnType<
    typeof useUserOnboardingNavigation
>['onCompleteOnboardingStep'];

export type goToPreviousStepFunction = ReturnType<
    typeof useUserOnboardingNavigation
>['onGoToPreviousStep'];
