import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { QuoteRepository } from '../repositories/QuoteRepository';

export interface DownloadPDFAgreementRequestData {
    pdfFileKey: string;
}

export interface DownloadPDFAgreement extends UseCase {
    execute(
        request: DownloadPDFAgreementRequestData,
    ): AsyncResult<string, InvalidArgument | OperationFailed>;
}

@injectable()
class DownloadPDFAgreementUseCase extends UseCase implements DownloadPDFAgreement {
    public static type = Symbol('Payments/DownloadPDFAgreement');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(QuoteRepository) private quoteRepo: QuoteRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: DownloadPDFAgreementRequestData,
    ): AsyncResult<string, InvalidArgument | OperationFailed> {
        const result = await this.quoteRepo.downloadPDFAgreement(request);

        if (isErr(result)) {
            return result;
        }

        return Success(result.value);
    }
}

export const DownloadPDFAgreement: UseCaseClass<DownloadPDFAgreement> = DownloadPDFAgreementUseCase;
