import { commonQuoteProps, Quote } from '../../../../quote/entities/Quote';
import { ESPRate } from '@app/quote/esp/types/ESPRate';
import { defineEntityValidator, entity } from '@embroker/shotwell/core/entity/Entity';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { ESPQuoteOptions } from '../types/ESPQuoteOptions';

export interface ESPQuote extends Quote {
    readonly options: ESPQuoteOptions;
    readonly details: ESPRate;
    readonly referralReasons?: readonly string[];
}

export const ESPQuote = entity<ESPQuote, Quote>({
    validator: defineEntityValidator<ESPQuote>({
        ...commonQuoteProps,
        details: ESPRate.schema,
        options: ESPQuoteOptions.schema.required(),
        referralReasons: Joi.array().items(Joi.string()).optional(),
    }),
    inherits: Quote,
});
