import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import { Form, Text, TextButton, StackLayout } from '@embroker/ui-toolkit/v2';
import React, { useEffect, useState } from 'react';
import { ForgottenPassword as ForgottenPasswordUseCase } from '../../useCases/ForgottenPassword';
import { PublicForm } from './PublicForm';
import { PublicPageLayout } from './PublicPageLayout.view';

interface RecoverPasswordFormData {
    email: EmailAddress;
}

const RecoverPasswordForm = createForm<RecoverPasswordFormData>({
    useCase: ForgottenPasswordUseCase,
    formatSubmitErrors(errors) {
        if (errors.length === 0) {
            return [];
        }
        return ["Email address doesn't exist"];
    },
    fields: {
        email: {
            type: 'email',
            validator: EmailAddress.schema.required(),
            formatValidationError(error) {
                if (error.details.validator === 'any.required') {
                    return 'You must provide an answer.';
                } else {
                    return 'You must provide valid email address.';
                }
            },
        },
    },
});

interface RecoverPasswordPageData {
    emailAddress?: string;
}

export function RecoverPasswordPage({ emailAddress }: RecoverPasswordPageData) {
    const { submit, messages, fields, status } = useForm(RecoverPasswordForm, {
        email: emailAddress as EmailAddress,
    });
    const [isSubmittedOnce, setIsSubmittedOnce] = useState(false);

    useEffect(() => {
        if (status === 'submitted' && !isSubmittedOnce) {
            setIsSubmittedOnce(true);
        }
    }, [status, isSubmittedOnce]);

    return (
        <PublicPageLayout>
            <PublicForm
                data-e2e="user-org-recover-pwd-submit"
                otherOption={
                    emailAddress === undefined ? (
                        <TextButton data-e2e="user-org-recover-pwd-login-btn" href="/login">
                            Sign In
                        </TextButton>
                    ) : undefined
                }
                messages={messages}
                submit={submit}
                submitText={isSubmittedOnce ? 'Resend email' : 'Reset password'}
                title="Recover password"
                titleProps={{ 'data-e2e': 'user-org-recover-pwd-header' }}
            >
                <div>
                    {isSubmittedOnce ? (
                        <div>
                            <Text style="body 1" data-e2e="user-org-recover-pwd-email-sent-title">
                                Email sent!
                            </Text>
                            <Text style="body 1" data-e2e="user-org-recover-pwd-email-sent-text">
                                We have sent an email to your inbox to reset your password. Please
                                note it expires in 48 hours.
                            </Text>
                            <Text style="body 1" data-e2e="user-org-recover-pwd-check-spam-txt">
                                If you don't see the email please check your spam folder.
                            </Text>
                        </div>
                    ) : (
                        <StackLayout gap="24">
                            <Text style="body 1">Enter your email to reset password</Text>
                            <Form.Field
                                data-e2e="user-org-recover-pwd-email"
                                inputProps={{
                                    ...fields.email.props,
                                    autoComplete: 'email',
                                }}
                                label="Your email"
                                messages={fields.email.messages}
                                type={fields.email.type}
                            />
                        </StackLayout>
                    )}
                </div>
            </PublicForm>
        </PublicPageLayout>
    );
}
