import { inject, injectable } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable, Props } from '@embroker/shotwell/core/types';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { defineValidator } from '@embroker/shotwell/core/validation/schema';
import { isFuture, isPast } from 'date-fns';
import { Policy } from '../entities/Policy';
import { PolicyRepository } from '../repositories/PolicyRepository';
import {
    PolicyStatusInSeries,
    PolicyStatusInSeriesCurrent,
    PolicyStatusInSeriesExpired,
    PolicyStatusInSeriesRenewal,
} from '../types/PolicyStatusInSeries';

export interface GetPolicySeriesRequest {
    policyId: UUID;
}

export const GetPolicySeriesRequest = {
    ...defineValidator<GetPolicySeriesRequest>({
        policyId: UUID.schema,
    }),
    create(getPolicySeriesRequest: GetPolicySeriesRequest) {
        return GetPolicySeriesRequest.validate(getPolicySeriesRequest);
    },
};

export interface GetPolicySeriesResponse {
    policyMap: Map<PolicyStatusInSeries, Immutable<EntityProps<Policy>[]>>;
}

const policyFilterFunctionTabNameMap = new Map<PolicyStatusInSeries, Function>([
    [
        PolicyStatusInSeriesCurrent,
        (policyList: Immutable<Props<Policy>[]>): Immutable<Props<Policy>[]> => {
            return policyList.filter(
                (policy) =>
                    isPast(policy.startDate) &&
                    isFuture(policy.endDate) &&
                    policy.viewMode !== 'PolicyViewStatusCodeListDraft',
            );
        },
    ],
    [
        PolicyStatusInSeriesRenewal,
        (policyList: Immutable<Props<Policy>[]>): Immutable<Props<Policy>[]> => {
            return policyList.filter(
                (policy) =>
                    isFuture(policy.startDate) &&
                    policy.viewMode !== 'PolicyViewStatusCodeListDraft',
            );
        },
    ],
    [
        PolicyStatusInSeriesExpired,
        (policyList: Immutable<Props<Policy>[]>): Immutable<Props<Policy>[]> => {
            return policyList.filter(
                (policy) =>
                    isPast(policy.endDate) && policy.viewMode !== 'PolicyViewStatusCodeListDraft',
            );
        },
    ],
]);

function getPolicyListMap(
    policyList: Immutable<EntityProps<Policy>[]>,
): Map<PolicyStatusInSeries, Immutable<Props<Policy>[]>> {
    const result = new Map<PolicyStatusInSeries, Immutable<Props<Policy>[]>>();
    policyFilterFunctionTabNameMap.forEach((filterFunction, policyStatusInSeries) => {
        result.set(policyStatusInSeries, filterFunction(policyList));
    });
    return result;
}

export interface GetPolicySeries extends UseCase {
    execute(
        request: GetPolicySeriesRequest,
    ): AsyncResult<GetPolicySeriesResponse, UnknownEntity | InvalidArgument | OperationFailed>;
}

@injectable()
export class GetPolicySeriesUseCase extends UseCase {
    public static type = Symbol('Policy/GetPolicySeries');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(PolicyRepository) private policyRepository: PolicyRepository,
    ) {
        super(eventBus);
    }

    async execute(
        request: GetPolicySeriesRequest,
    ): AsyncResult<GetPolicySeriesResponse, UnknownEntity | InvalidArgument | OperationFailed> {
        const getPolicyResult = await this.policyRepository.getPolicy(request.policyId);
        if (isErr(getPolicyResult)) {
            return getPolicyResult;
        }

        const policySeriesResult = await this.policyRepository.getPolicySeries(
            getPolicyResult.value.renewalSeriesId,
        );

        if (isErr(policySeriesResult)) {
            return policySeriesResult;
        }

        const policyList: EntityProps<Policy>[] = policySeriesResult.value.map((policy) => {
            return policy.toDTO();
        });

        const getPolicySeriesResponse: GetPolicySeriesResponse = {
            policyMap: getPolicyListMap(policyList),
        };

        return Success(getPolicySeriesResponse);
    }
}

export const GetPolicySeries: UseCaseClass<GetPolicySeries> = GetPolicySeriesUseCase;
