import { API } from '@embroker/shotwell-api/v2/app';
import * as APITypes from '@embroker/shotwell-api/v2/app.spec';
import { injectable } from '@embroker/shotwell/core/di';
import { OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { CreateProductDefinitionError, GetProductDefinitionError } from '../../errors';
import { ProductDefinitionRepository } from './index';
import { RequestResult } from '@embroker/shotwell/core/networking';
import { APIError } from '@embroker/shotwell-api/errors';
import { Product } from '../../entities/Product';
import { DocumentSpecification } from '../../types/DocumentSpecification';
import { FormFamily } from '../../types/FormFamily';
import { ProductDefinition } from '../../types/ProductDefinition';

@injectable()
export class APIProductDefinitionRepository implements ProductDefinitionRepository {
    async get(
        version: UUID,
    ): AsyncResult<
        Product,
        GetProductDefinitionError | CreateProductDefinitionError | OperationFailed
    > {
        const result: RequestResult<APITypes.GETProductDefinitionsVersion200Response, APIError> =
            await API.get(`product-definitions/${version}`);

        if (isErr(result)) {
            return Failure(GetProductDefinitionError(result.errors[0]));
        }

        const product = result.value.product;
        if (!product) {
            return Failure(
                OperationFailed({
                    message: 'Product definition not defined',
                    errors: [],
                }),
            );
        }
        if (
            !product.version ||
            !product.definition ||
            !product.effectiveDate ||
            !product.description
        ) {
            return Failure(
                OperationFailed({
                    message: 'Product definition not defined properly',
                    errors: [],
                }),
            );
        }
        const productDefinitionResult = await this.createProductDefinition(
            <APITypes.ProductDefinition>product.definition,
        );
        if (isErr(productDefinitionResult)) {
            return productDefinitionResult;
        }

        const productDefinitionEntity = await Product.create({
            version: product.version,
            definition: productDefinitionResult.value,
            effectiveDate: product.effectiveDate,
            description: product.description,
        });

        if (isErr(productDefinitionEntity)) {
            return Failure(CreateProductDefinitionError(productDefinitionEntity.errors[0]));
        }

        return Success(productDefinitionEntity.value);
    }

    private async createProductDefinition(
        apiProductDefinition: APITypes.ProductDefinition,
    ): AsyncResult<ProductDefinition, CreateProductDefinitionError> {
        const documentSpecs = await this.createDocumentSpecificationList(
            apiProductDefinition.documentSpecs,
        );
        if (isErr(documentSpecs)) {
            return documentSpecs;
        }

        const definitionResult = ProductDefinition.create({
            shortName: apiProductDefinition.shortName,
            fullName: apiProductDefinition.fullName,
            icon: apiProductDefinition.icon ?? '',
            documentSpecs: documentSpecs.value,
        });
        if (isErr(definitionResult)) {
            return Failure(CreateProductDefinitionError(definitionResult.errors[0]));
        }

        return Success(definitionResult.value);
    }

    private async createDocumentSpecificationList(
        apiDocumentSpecificationList: APITypes.DocumentSpecification[],
    ): AsyncResult<DocumentSpecification[], CreateProductDefinitionError> {
        const documentSpecificationList: DocumentSpecification[] = [];
        if (apiDocumentSpecificationList !== undefined && apiDocumentSpecificationList.length > 0) {
            for (const apiDocumentSpecification of apiDocumentSpecificationList) {
                const documentSpecification = await this.createDocumentSpecification(
                    apiDocumentSpecification,
                );
                if (isErr(documentSpecification)) {
                    return documentSpecification;
                }
                documentSpecificationList.push(documentSpecification.value);
            }
        }

        return Success(documentSpecificationList);
    }

    private async createDocumentSpecification(
        apiDocumentSpecification: APITypes.DocumentSpecification,
    ): AsyncResult<DocumentSpecification, CreateProductDefinitionError> {
        const formFamilyList = await this.createFormFamilyList(
            apiDocumentSpecification.formFamilies,
        );
        if (isErr(formFamilyList)) {
            return formFamilyList;
        }

        const documentSpecificationResult = DocumentSpecification.create({
            docType: apiDocumentSpecification.docType,
            displayName: apiDocumentSpecification.displayName,
            tags: apiDocumentSpecification.tags,
            condition: apiDocumentSpecification.condition,
            formFamilies: formFamilyList.value,
            inputSchema: apiDocumentSpecification.inputSchema,
        });

        if (isErr(documentSpecificationResult)) {
            return Failure(CreateProductDefinitionError(documentSpecificationResult.errors[0]));
        }

        return Success(documentSpecificationResult.value);
    }

    private async createFormFamilyList(
        apiFormFamilyList: APITypes.FormFamily[],
    ): AsyncResult<FormFamily[], CreateProductDefinitionError> {
        const result: FormFamily[] = [];
        for (const apiFormFamily of apiFormFamilyList) {
            const versionResult = await this.createFormFamily(apiFormFamily);
            if (isErr(versionResult)) {
                return versionResult;
            }
            result.push(versionResult.value);
        }

        return Success(result);
    }

    private async createFormFamily(
        apiFormFamily: APITypes.FormFamily,
    ): AsyncResult<FormFamily, CreateProductDefinitionError> {
        const formFamilyResult = FormFamily.create({
            id: apiFormFamily.id,
            docType: apiFormFamily.docType,
            optional: apiFormFamily.optional,
        });

        if (isErr(formFamilyResult)) {
            return Failure(CreateProductDefinitionError(formFamilyResult.errors[0]));
        }

        return Success(formFamilyResult.value);
    }
}
