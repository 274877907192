import { inject } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isOK, Success, Failure } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { NotificationRepository } from '../repositories/NotificationRepository';
import { FailedToUpdateAllNotificationsStatus } from '../errors';
import { NotificationStatus } from '../entities/Notification';

export type NotificationStatusRequest = { status: NotificationStatus };

export interface UpdateAllUserNotificationsStatus extends UseCase {
    execute(
        request: NotificationStatusRequest,
    ): AsyncResult<void, FailedToUpdateAllNotificationsStatus>;
}

class UpdateAllUserNotificationsStatusUseCase
    extends UseCase
    implements UpdateAllUserNotificationsStatus
{
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('NotificationCenter/UpdateAllUserNotificationsUseCase');
    /**
     * Constructor for UpdateAllUserNotificationsUseCase use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param notificationRepository is notification repository used to store user notifications
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(NotificationRepository) private notificationRepository: NotificationRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: NotificationStatusRequest,
    ): AsyncResult<void, FailedToUpdateAllNotificationsStatus> {
        const { status } = request;
        const updateResult = await this.notificationRepository.updateAllNotificationStatus(status);

        if (isOK(updateResult)) {
            return Success();
        }

        return Failure(FailedToUpdateAllNotificationsStatus());
    }
}

export const UpdateAllUserNotificationsStatus: UseCaseClass<UpdateAllUserNotificationsStatus> =
    UpdateAllUserNotificationsStatusUseCase;
