import { valueObject } from '@embroker/shotwell/core/types/ValueObject';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { MailingAddress } from '../../userOrg/types/MailingAddress';
import { BundleQuoteUserInfo } from './BundleQuoteUserInfo';

export interface BundleQuoteOrganization {
    readonly companyName: string;
    readonly address: MailingAddress;
    readonly userInfo: BundleQuoteUserInfo;
    readonly currentPolicyEndDate?: Date;
}

export const BundleQuoteOrganization = valueObject({
    ...defineValidator<BundleQuoteOrganization>({
        companyName: Joi.string(),
        address: MailingAddress.schema,
        userInfo: BundleQuoteUserInfo.schema,
        currentPolicyEndDate: Joi.date().optional(),
    }),
});
