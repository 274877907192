import { inject, injectable } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { Certificate } from '../entities/Certificate';
import { CertificateRepository } from '../repositories/CertificateRepository';

/**
 * Request data for GetIssuedCertificates use case
 */
export interface GetIssuedCertificatesRequest {
    organizationId: UUID;
}

/**
 * Response data for GetIssuedCertificates use case
 */
export interface GetIssuedCertificatesResponse {
    certificateList: Immutable<EntityProps<Certificate>[]>;
}

/**
 * GetIssuedCertificates use case is used to fetch issued certificates for organization
 */
export interface GetIssuedCertificates extends UseCase {
    execute({
        organizationId,
    }: GetIssuedCertificatesRequest): AsyncResult<
        GetIssuedCertificatesResponse,
        InvalidArgument | OperationFailed
    >;
}

@injectable()
class GetIssuedCertificatesUseCase extends UseCase implements GetIssuedCertificates {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Certificates/GetIssuedCertificatesUseCase');

    /**
     * Constructor for GetIssuedCertificates class instance
     * @param eventBus An event bus this Use Case will publish events to.
     * @param certificateRepo repo that will be used to fetch issued certificates for organization
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(CertificateRepository) private certificateRepo: CertificateRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes the GetIssuedCertificates use case.
     * Input is of type GetIssuedCertificatesRequest
     * @returns InvalidArgument if one of properties in GetIssuedCertificates was not valid
     */
    public async execute({
        organizationId,
    }: GetIssuedCertificatesRequest): AsyncResult<
        GetIssuedCertificatesResponse,
        InvalidArgument | OperationFailed
    > {
        const certificates = await this.certificateRepo.getCertificatesForActiveOrganization();

        if (isErr(certificates)) {
            return handleOperationFailure(certificates);
        }

        const response: GetIssuedCertificatesResponse = {
            certificateList: (certificates.value as Certificate[]).map((certificate: Certificate) =>
                certificate.toDTO(),
            ),
        };

        return Success(response);
    }
}

export const GetIssuedCertificates: UseCaseClass<GetIssuedCertificates> =
    GetIssuedCertificatesUseCase;
