import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { PageLayout, Spinner, Stack, useStepper } from '@embroker/ui-toolkit/v2';
import React, { useState } from 'react';
import { useLoadingRoute } from 'react-navi';
import { Quote } from '../../../entities/Quote';
import { QuoteOptions } from '../../../repositories/QuoteRepository';
import { Payment } from '../../../types/Payment';
import { buildInvoiceIdList } from '../PaymentsDashboard';
import { DownPaymentPage } from './DownPayment';
import { PDFAgreement } from './PDFAgreement';
import { QuotesPage } from './QuotesPage';
import { PremiumFinanceSignature } from './Signature';

export interface PremiumFinanceFlow {
    next(): void;
    previous(): void;
    activeStepIndex: number;
}

interface PremiumFinanceDashboardInput {
    backPage: boolean;
    quotes: QuoteOptions;
    eligibleInvoices: Immutable<Payment[]>;
    selectedInvoiceIdList: Immutable<UUID[]>;
}

function calcMinEffectiveDate(payments: Immutable<Payment[]>) {
    let dates = payments.map((payment) => {
        return payment.policyEffectiveDate;
    });

    if (!Array.isArray(dates)) {
        dates = [dates];
    }
    return dates.reduce(function (a, b) {
        return a < b ? a : b;
    });
}

export const PremiumFinanceDashboard = (props: PremiumFinanceDashboardInput) => {
    const loading = useLoadingRoute();
    const { activeStepIndex, next, previous } = useStepper({
        steps: ['select-loan', 'download-agreement', 'signature', 'down-payment'],
        initialStep: 'select-loan',
    });

    const [selectedQuote, setSelectedQuote] = useState<Nullable<Quote>>(null);
    const [dueNow, setDueNow] = useState<Money>();

    const minEffectiveDate = new Date(calcMinEffectiveDate(props.eligibleInvoices));
    function handleSelectedQuote(quote: Quote, dueNow: Money) {
        setSelectedQuote(quote);
        setDueNow(dueNow);
        next();
    }

    const eligibleInvoicesIDs = props.eligibleInvoices.reduce(
        (idList, invoice) => buildInvoiceIdList(idList, invoice),
        new Array<UUID>(),
    );

    if (loading) {
        return <Spinner />;
    }

    return (
        <PageLayout.Section>
            <Stack activeIndex={activeStepIndex}>
                <QuotesPage
                    selectedInvoiceIdList={props.selectedInvoiceIdList}
                    eligibilityPageShown={props.backPage}
                    minEffectiveDate={minEffectiveDate}
                    onQuoteSelected={handleSelectedQuote}
                    quotes={props.quotes}
                    activeStepIndex={activeStepIndex}
                />
                <PDFAgreement
                    activeStepIndex={activeStepIndex}
                    next={next}
                    previous={previous}
                    quote={selectedQuote}
                />
                <PremiumFinanceSignature
                    next={next}
                    previous={previous}
                    activeStepIndex={activeStepIndex}
                />
                <DownPaymentPage
                    activeStepIndex={activeStepIndex}
                    dueNow={dueNow}
                    eligibleInvoicesIDs={eligibleInvoicesIDs}
                    next={next}
                    previous={previous}
                    quote={selectedQuote}
                />
            </Stack>
        </PageLayout.Section>
    );
};
