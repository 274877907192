import React, { ReactNode } from 'react';
import { Button, StackLayout, Text, WizardLayout } from '@embroker/ui-toolkit/v2';

interface EndorsementSlideoutContentProps {
    title: string;
    onDismiss: () => void;
    handleCTA: () => void;
    buttonLabel: string;
    bodyHeader: string;
    body: Array<string | ReactNode>;
}

export const EndorsementSlideoutContent = ({
    title,
    onDismiss,
    buttonLabel,
    handleCTA,
    body,
    bodyHeader,
}: EndorsementSlideoutContentProps) => {
    return (
        <WizardLayout title={title} onDismiss={onDismiss}>
            <StackLayout gap="48">
                <StackLayout>
                    <Text style="heading 3" data-e2e="slideout-header">
                        {bodyHeader}
                    </Text>
                    {body.map((item, index) => (
                        <Text
                            style="body 1"
                            data-e2e={`slideout-body-${index + 1}`}
                            key={typeof item === 'string' ? item : index}
                        >
                            {item}
                        </Text>
                    ))}
                </StackLayout>
                <Button onClick={handleCTA} data-e2e="slideout-cta">
                    {buttonLabel}
                </Button>
            </StackLayout>
        </WizardLayout>
    );
};
