import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Immutable } from '@embroker/shotwell/core/types';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import {
    Button,
    ColumnLayout,
    InvoiceTable,
    Modal,
    ScrollBox,
    StackLayout,
    Text,
    useModal,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { Invoice } from '../../../entities/Invoice';

interface InvoiceDetailsModal {
    hideModal(): void;
    invoice?: Immutable<EntityProps<Invoice>>;
    modal: ReturnType<typeof useModal>;
    type?: string;
}

export function ModalInvoiceDetail(props: InvoiceDetailsModal) {
    if (props.invoice === undefined) {
        return null;
    }

    const invoice = props.invoice;
    const invoiceItemList =
        invoice.invoiceItemList.length > 0 ? (
            <InvoiceTable.Section>
                {invoice.invoiceItemList.map((item) => {
                    return (
                        <InvoiceTable.Item key={item.description} title={item.description}>
                            <MoneyDisplay value={item.amount} negativeFormat="parenthesis" />
                        </InvoiceTable.Item>
                    );
                })}
            </InvoiceTable.Section>
        ) : null;

    const totalAmount = props.type === 'history' ? invoice.total : invoice.balance;

    return (
        <Modal {...props.modal}>
            <ScrollBox>
                <StackLayout gap="16">
                    <Text style="heading 3">Invoice details</Text>
                    <Text style="heading 5">{invoice.lineOfBusiness}</Text>
                    <ColumnLayout>
                        <StackLayout className="u-1/3">
                            <Text data-e2e="invoice-details-policy-number" style="overline">
                                Policy no.
                            </Text>
                            <Text>{invoice.policyNumber}</Text>
                        </StackLayout>
                        <StackLayout className="u-1/3">
                            <Text data-e2e="invoice-details-invoice-number" style="overline">
                                Invoice no.
                            </Text>
                            <Text>{invoice.invoiceNumber}</Text>
                        </StackLayout>
                        <StackLayout className="u-1/3">
                            <Text style="overline">Effective</Text>
                            <Text>
                                <DateDisplay value={invoice.policyEffectiveDate} />
                            </Text>
                        </StackLayout>
                    </ColumnLayout>
                    <hr />
                    <InvoiceTable>
                        <InvoiceTable.Section>{invoiceItemList}</InvoiceTable.Section>
                        <InvoiceTable.Total
                            data-e2e="invoice-details-total-amount"
                            title="Total amount"
                        >
                            <MoneyDisplay value={totalAmount} negativeFormat="parenthesis" />
                        </InvoiceTable.Total>
                    </InvoiceTable>
                    <Text data-e2e="invoice-details-total-amount-disclaimer">
                        Total may be different if paid by credit card.
                    </Text>
                    <Button
                        data-e2e="invoice-details-got-it-btn"
                        appearance="primary"
                        onClick={props.hideModal}
                    >
                        Got It
                    </Button>
                </StackLayout>
            </ScrollBox>
        </Modal>
    );
}
