import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { WCGAQuote } from '../entities/WCGAQuote';
import { WCGAQuoteRepository } from '../repositories/WCGAQuoteRepository';

/**
 * Request data for GetLastWCGAQuote use case
 */
export interface GetLastWCGAQuoteRequest {
    applicationId: UUID;
}

/**
 * Response data for GetLastWCGAQuote use case
 */
export interface GetWCGAQuoteResponse {
    quote: Immutable<WCGAQuote>;
}

/**
 * GetLastWCGAQuote use case is used to get the last Referred quote currently in review
 */
export interface GetLastWCGAQuote extends UseCase {
    execute(
        request: GetLastWCGAQuoteRequest,
    ): AsyncResult<GetWCGAQuoteResponse, OperationFailed | InvalidArgument>;
}

class GetLastWCGAQuoteUseCase extends UseCase implements GetLastWCGAQuote {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('WCGAQuote/GetLastWCGAQuote');

    /**
     * Constructor for GetLastWCGAQuote use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(WCGAQuoteRepository) private wcgaQuoteRepository: WCGAQuoteRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes GetLastWCGAQuote use case
     * Input is of GetLastWCGAQuoteRequest type
     * @returns InvalidArgument if there is no such insurance application
     * @returns OperationFailed if repository failed to get a quote
     */
    public async execute(
        request: GetLastWCGAQuoteRequest,
    ): AsyncResult<GetWCGAQuoteResponse, OperationFailed | InvalidArgument> {
        const result = await this.wcgaQuoteRepository.getLast(request.applicationId);

        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return Success({ quote: result.value });
    }
}

export const GetLastWCGAQuote: UseCaseClass<GetLastWCGAQuote> = GetLastWCGAQuoteUseCase;
