import {
    defineEntityValidator,
    entity,
    Entity,
    EntityProps,
} from '@embroker/shotwell/core/entity/Entity';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { Immutable, Nullable, Props } from '@embroker/shotwell/core/types';
import { Failure, Result, Success, SuccessResult } from '@embroker/shotwell/core/types/Result';
import { equalUUID, UUID } from '@embroker/shotwell/core/types/UUID';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { addSeconds } from 'date-fns';
import { Unauthenticated } from '../errors';
import { ActiveSession } from '../../view/AppContext';

/**
 * Default duration used for extending the session expiration date/time.
 */
const DEFAULT_SESSION_DURATION_SECONDS = 300;
/**
 * A domain event created when an authenticated Session is deauthenticated.
 */
export interface SessionDeauthenticated extends DomainEvent {
    readonly origin: 'Session';
    readonly name: 'Deauthenticated';

    readonly userId?: UUID;
    readonly authenticatedUserId?: UUID;
    readonly organizationId?: UUID;
}
/**
 * A domain event created when a new user is impersonated in the active Session.
 */
export interface SessionUserImpersonated extends DomainEvent {
    readonly origin: 'Session';
    readonly name: 'UserImpersonated';
    /**
     * Identifier of the user that is now impersonated in this Session.
     */
    readonly userId: UUID;
}
/**
 * A domain event created when a new organization is selected in the active Session.
 */
export interface SessionOrganizationSelected extends DomainEvent {
    readonly origin: 'Session';
    readonly name: 'OrganizationSelected';
    /**
     * Identifier of the organization that is now active in this Session.
     */
    readonly organizationId: UUID;
}
/**
 * A domain event created when an authenticated Session is extended.
 */
export interface SessionExtended extends DomainEvent {
    readonly origin: 'Session';
    readonly name: 'Extended';
    /**
     * The new expiration date/time for this Session.
     */
    readonly expirationDateTime: Date;
}

export interface SessionUserLogin extends DomainEvent {
    readonly origin: 'Session';
    readonly name: 'UserLogin';
    /**
     * Identifier of the user that is now active in this Session.
     */
    readonly userId: UUID;
    /**
     * Identifier of the organization that is now active in this Session.
     */
    readonly organizationId: Nullable<UUID>;
}

export const Role = ['user', 'admin', 'broker', 'guest'] as const;
export type Role = (typeof Role)[number];
/**
 * User session representation.
 *
 * An active session exists even for unauthenticated users.
 */
export interface Session extends Entity {
    /**
     * Identifier of the authenticated User.
     */
    readonly authenticatedUserId: Nullable<UUID>;
    /**
     * Identifier of the active User.
     *
     * This is usually the same as authenticatedUserId but having it separate allows impersonate user feature.
     */
    readonly userId: Nullable<UUID>;
    /**
     * Identifier of the active Organization.
     */
    readonly organizationId: Nullable<UUID>;
    /**
     * List of all Organizations for the active User.
     */
    readonly userOrganizationIdList?: UUID[];
    /**
     * Expiration date & time of this Session.
     */
    readonly expirationDateTime: Nullable<Date>;
    /**
     * Current session roles.
     */
    readonly roles: Role[];
    /**
     * Returns true if current session has specified role.
     */
    hasRole(role: Role): boolean;
    /**
     * Returns true if this Session currently represents an authenticated user.
     *
     * I.e. checks that authenticatedUserId is not null.
     */
    isAuthenticated(): boolean;
    /**
     * Deauthenticates this Session.
     *
     * If the Session is already not authenticated this function does nothing.
     */
    deauthenticate(): SuccessResult<void>;
    /**
     * Impersonate a specific user.
     *
     * If the Session is not authenticated a Failure result with Unauthenticated error is returned.
     */
    impersonateUser(userId: UUID): Result<void, Unauthenticated>;
    /**
     * Changes the currently active Organization for this Session.
     *
     * If the session is not authenticated a Failure result with Unauthenticated error is returned.
     */
    selectOrganization(organizationId: UUID): Result<void, Unauthenticated>;
    /**
     * Extends the current Session's expirationDateTime by the provided duration in seconds.
     *
     * If omitted, durationSeconds defaults to DEFAULT_SESSION_DURATION_SECONDS.
     */
    extend(durationSeconds?: number): Result<void, Unauthenticated>;

    /**
     * Adds Login by User event to its event list.
     */
    onLoginByUser(): void;
}

/**
 * Represents an authenticated session (i.e. a Session whose isAuthenticated() returns true)
 */
export interface AuthenticatedSession extends Session {
    authenticatedUserId: UUID;
    userId: UUID;
    expirationDateTime: Date;
}

/**
 * Checks if the given Session is an instance of AuthenticatedSession.
 *
 * @param session The (possibly null) Session to check.
 */
export function isAuthenticated(
    session: Nullable<Props<Immutable<Session>>>,
): session is Props<AuthenticatedSession>;
export function isAuthenticated(
    session: Nullable<Immutable<Session>>,
): session is AuthenticatedSession;
export function isAuthenticated(
    session: Nullable<Immutable<Session> | Props<Immutable<Session>>>,
): boolean {
    return session !== null && session.authenticatedUserId !== null;
}

/**
 * Returns true if given session has specified role.
 */
export function hasRole(
    session: Immutable<EntityProps<Session>> | ActiveSession,
    role: Role,
): boolean {
    return session.roles !== null && session.roles.includes(role);
}

export const Session = entity<Session>({
    validator: defineEntityValidator<Session>({
        authenticatedUserId: UUID.schema.allow(null).optional().default(null),
        userId: UUID.schema.allow(null).optional().default(null),
        organizationId: UUID.schema.allow(null).optional().default(null),
        userOrganizationIdList: Joi.array().items(UUID.schema.optional()).optional(),
        expirationDateTime: Joi.date().allow(null).optional().default(null),
        roles: Joi.array().items(Joi.string().allow(...Role)),
    }),
    async init() {
        if (this.props.authenticatedUserId !== null) {
            if (this.props.userId === null) {
                this.props.userId = this.props.authenticatedUserId;
            } else if (!equalUUID(this.props.authenticatedUserId, this.props.userId)) {
                this.createEvent<SessionUserImpersonated>('UserImpersonated', {
                    userId: this.props.userId,
                });
            }
            if (this.props.expirationDateTime === null) {
                this.props.expirationDateTime = addSeconds(
                    new Date(Date.now()),
                    DEFAULT_SESSION_DURATION_SECONDS,
                );
            }
            if (this.props.organizationId !== null) {
                this.createEvent<SessionOrganizationSelected>('OrganizationSelected', {
                    organizationId: this.props.organizationId,
                });
            }
        } else {
            this.props.userId = null;
            this.props.expirationDateTime = null;
            this.props.organizationId = null;
        }
        return Success();
    },
    hasRole(role: Role): boolean {
        return this.roles.includes(role);
    },
    isAuthenticated() {
        return this.props.authenticatedUserId !== null;
    },
    deauthenticate() {
        if (!this.isAuthenticated()) {
            return Success();
        }

        const authenticatedUserId = this.props.authenticatedUserId;
        this.props.authenticatedUserId = null;
        this.props.expirationDateTime = null;
        this.props.userId = null;
        this.props.organizationId = null;
        this.props.roles = [];

        if (authenticatedUserId !== null) {
            this.createEvent<SessionDeauthenticated>('Deauthenticated', {
                userId: this.props.userId ?? undefined,
                organizationId: this.props.organizationId ?? undefined,
                authenticatedUserId: this.props.authenticatedUserId ?? undefined,
            });
        }

        return Success();
    },
    impersonateUser(userId: UUID) {
        if (!this.isAuthenticated()) {
            return Failure(Unauthenticated());
        }

        if (this.props.userId === null || !equalUUID(this.props.userId, userId)) {
            this.props.userId = userId;
            this.createEvent<SessionUserImpersonated>('UserImpersonated', {
                userId,
            });
        }

        return Success();
    },
    selectOrganization(organizationId: UUID) {
        if (!this.isAuthenticated()) {
            return Failure(Unauthenticated());
        }

        if (
            this.props.organizationId === null ||
            !equalUUID(this.props.organizationId, organizationId)
        ) {
            this.props.organizationId = organizationId;

            this.createEvent<SessionOrganizationSelected>('OrganizationSelected', {
                organizationId,
            });
        }

        return Success();
    },
    extend(durationSeconds = DEFAULT_SESSION_DURATION_SECONDS) {
        if (!this.isAuthenticated()) {
            return Failure(Unauthenticated());
        }

        if (this.props.expirationDateTime != null) {
            this.props.expirationDateTime = addSeconds(
                this.props.expirationDateTime,
                durationSeconds,
            );

            this.createEvent<SessionExtended>('Extended', {
                expirationDateTime: this.props.expirationDateTime,
            });
        }

        return Success();
    },
    onLoginByUser() {
        if (this.userId) {
            this.createEvent<SessionUserLogin>('UserLogin', {
                userId: this.userId,
                organizationId: this.organizationId,
            });
        }
    },
});
