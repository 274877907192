import { valueObject } from '@embroker/shotwell/core/types/ValueObject';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { QuoteOptions } from '../../../../quote/entities/Quote';

export const CowbellCyberLimit = [
    100_000, 250_000, 500_000, 750_000, 1_000_000, 2_000_000, 3_000_000,
] as const;
export type CowbellCyberLimit = (typeof CowbellCyberLimit)[number];
export const CowbellCyberRetention = [2_500, 5_000, 10_000] as const;
export type CowbellCyberRetention = (typeof CowbellCyberRetention)[number];

export interface CowbellCyberQuoteOptions extends QuoteOptions {
    readonly limit: number;
    readonly retention: number;
    readonly isDeselected: boolean;
}

export const CowbellCyberQuoteOptions = valueObject({
    ...defineValidator<CowbellCyberQuoteOptions>({
        effectiveDate: Joi.date().required(),
        limit: Joi.number()
            .valid(...CowbellCyberLimit)
            .required(),
        retention: Joi.number().required(),
        isDeselected: Joi.boolean().required(),
    }),
});
