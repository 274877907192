import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { Money, USD } from '@embroker/shotwell/core/types/Money';
import { ErrorLike, isErr, SuccessResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    Text,
    Button,
    ButtonBar,
    TextButton,
    Form,
    Grid,
    Spinner,
    VBox,
    StackLayout,
    StatusMessage,
    SelectInput,
    BoxLayout,
    ColumnLayout,
    UseResponsiveScreenQuery,
    useResponsive,
} from '@embroker/ui-toolkit/v2';
import { format, isSameDay, isValid, startOfDay } from 'date-fns';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CoverageRestriction } from '../../../../quote/esp/types/CoverageRestriction';
import {
    CreateOtherEndorsement,
    CreateOtherEndorsementRequest,
} from '../../../intake/useCases/CreateOtherEndorsement';
import {
    EditESPEndorsementLiabilityCoverageType,
    ESPEndorsementCoverageTypeMap,
} from '../../types/ESPEndorsementLiabilityCoverageType';
import {
    ESPEndorsementLiabilityCoverage,
    ESPEndorsementPolicy,
} from '../../types/ESPEndorsementPolicy';
import { ESPEndorsementRate } from '../../types/ESPEndorsementRate';
import { ESPEndorsementUserData } from '../../types/ESPEndorsementUserData';
import { EditMultipleCoverageESPEndorsement } from '../../useCases/EditMultipleCoverageEndorsement';
import { RateEditMultipleCoverageESPEndorsement } from '../../useCases/RateEditMultipleCoverageEndorsement';
import { ESPEndorsementSignature } from './ESPEndorsementSignature';
import { getCoverageDisplayName } from './getCoverageDisplayName';
import {
    getEditCoverageCyberLimitOptions,
    getEditCoverageLimitOptions,
} from './getESPLimitAndRetentionOptions';
import { hasRole } from '../../../../userOrg/entities/Session';
import { AppContext } from '../../../../view/AppContext';
import { toSelectCurrencyOption } from '../../../../quote/toSelectCurrencyOption';
import { ESPEndorsementPremiumCostBreakdown } from '@app/endorsement/esp/view/components/ESPEndorsementPremiumCostBreakdown.view';

function formatRequestHigherLimitMessage(
    coverageTypeCode: string,
    oldLimit: Money,
    newLimit: number,
): string {
    const coverage = ESPEndorsementCoverageTypeMap[coverageTypeCode];
    const limitBefore: string = Money.toString(oldLimit);
    // newLimit is an integer in dollars, so we multiply it by 100 to convert to cents
    const requestedLimit = USD(newLimit * 100);
    const limitAfter: string = Money.toString(requestedLimit);

    return `Increase ${coverage} limit from ${limitBefore} to ${limitAfter}`;
}

const DATE_FORMAT = 'MM/dd/yyyy';

interface ESPEndorsementEditCoverageCombinedFormData {
    techLimit: number;
    cyberLimit: number;
    effectiveDate: Nullable<Date>;
    agreementToConductSignature: boolean;
    warrantyAndFraudSignature: boolean;
}

interface CreateEspEndorsementEditCoverageFormParams {
    policyId: UUID;
    effectivePeriodStart: Date;
    effectivePeriodEnd: Date;
    coverageTypeCode: EditESPEndorsementLiabilityCoverageType;
    retention: Money;
    oldTechLimit: Money;
    oldCyberLimit: Money;
    onPurchaseEndorsementSuccess: (updatedPolicy: ESPEndorsementPolicy) => void;
    onPurchaseEndorsementFailure: (errors: Immutable<ErrorLike[]>) => void;
    abortSignal: AbortSignal;
    onRequestHigherLimitSuccess: () => void;
}

function createEspEndorsementEditCoverageCombinedForm({
    policyId,
    effectivePeriodStart,
    effectivePeriodEnd,
    coverageTypeCode,
    retention,
    oldTechLimit,
    oldCyberLimit,
    onPurchaseEndorsementSuccess,
    onPurchaseEndorsementFailure,
    abortSignal,
    onRequestHigherLimitSuccess,
}: CreateEspEndorsementEditCoverageFormParams) {
    return createForm<ESPEndorsementEditCoverageCombinedFormData>({
        fields: {
            techLimit: {
                type: 'text',
                validator: Joi.number().required(),
                formatValidationError: (error) => {
                    if (error.details.validator === 'any.required') {
                        return 'Limit is empty.';
                    } else {
                        return error.message;
                    }
                },
            },
            cyberLimit: {
                type: 'text',
                validator: Joi.number().required(),
                formatValidationError: (error) => {
                    if (error.details.validator === 'any.required') {
                        return 'Limit is empty.';
                    } else {
                        return error.message;
                    }
                },
            },
            effectiveDate: {
                type: 'date',
                validator: Joi.date().min(effectivePeriodStart).max(effectivePeriodEnd).required(),
                formatValidationError: (error) => {
                    if (
                        error.details.validator == 'date.min' ||
                        error.details.validator == 'date.max'
                    ) {
                        return 'The effective date does not coincide with the policy period.';
                    }
                    if (error.details.validator == 'any.required') {
                        return 'You must set an effective date.';
                    }
                    return error.message;
                },
            },
            agreementToConductSignature: {
                // FIXME bool as hidden ?
                type: 'hidden',
                validator: Joi.boolean().valid(true),
                formatValidationError: (error) => {
                    if (error.details.validator == 'any.only') {
                        return 'You must agree to this condition';
                    }
                    return error.message;
                },
            },
            warrantyAndFraudSignature: {
                // FIXME bool as hidden ?
                type: 'hidden',
                validator: Joi.boolean().valid(true),
                formatValidationError: (error) => {
                    if (error.details.validator == 'any.only') {
                        return 'You must agree to this condition';
                    }
                    return error.message;
                },
            },
        },
        actions: {
            requestHigherLimit: async (
                editCoverageFormData: ESPEndorsementEditCoverageCombinedFormData,
            ) => {
                const effectiveDate = editCoverageFormData.effectiveDate as Date;
                const requestedChangeMessage = formatRequestHigherLimitMessage(
                    coverageTypeCode,
                    oldTechLimit,
                    editCoverageFormData.techLimit,
                );

                const request: CreateOtherEndorsementRequest = {
                    effectiveDate,
                    requestedChangeMessage,
                    policyId,
                };
                return await execute(CreateOtherEndorsement, request);
            },
            purchase: async (editCoverageFormData: ESPEndorsementEditCoverageCombinedFormData) => {
                const effectiveDate = editCoverageFormData.effectiveDate as Date;
                const coverages = [
                    {
                        coverageTypeCode:
                            'LiabilityCoverageCodeListTechSplit' as EditESPEndorsementLiabilityCoverageType,
                        limit: Money.tryFromFloat(editCoverageFormData.techLimit),
                        retention: retention,
                    },
                    {
                        coverageTypeCode:
                            'LiabilityCoverageCodeListCyberSplit' as EditESPEndorsementLiabilityCoverageType,
                        limit: Money.tryFromFloat(editCoverageFormData.cyberLimit),
                        retention: retention,
                    },
                ];
                return await execute(EditMultipleCoverageESPEndorsement, {
                    agreementId: policyId,
                    coverages: coverages,
                    effectiveDate: effectiveDate,
                    abortSignal,
                });
            },
        },
        onSuccess: (value, action) => {
            switch (action) {
                case 'purchase':
                    onPurchaseEndorsementSuccess(value);
                    break;
                case 'requestHigherLimit':
                    onRequestHigherLimitSuccess();
                    break;
                default:
                    break;
            }
        },
        onFailure: (errors: Immutable<ErrorLike[]>) => onPurchaseEndorsementFailure(errors),
    });
}

const defaultEditCoverageRate: ESPEndorsementRate = {
    annualPremium: USD(0),
    prorate: USD(0),
    taxes: USD(0),
    fees: USD(0),
    total: USD(0),
};

interface ESPEndorsementEditESPTechSplitCoverageProps {
    policyId: UUID;
    effectivePeriodStart: Date;
    effectivePeriodEnd: Date;
    techCoverage: ESPEndorsementLiabilityCoverage;
    cyberCoverage: ESPEndorsementLiabilityCoverage;
    userData: ESPEndorsementUserData;
    restriction?: Immutable<CoverageRestriction>;
    cyberRestriction?: Immutable<CoverageRestriction>;
    hasRenewalApplication?: boolean;
    onPurchaseEndorsementFailure: (errors: Immutable<ErrorLike[]>) => void;
    onPurchaseEndorsementSuccess: (updatedPolicy: ESPEndorsementPolicy) => void;
    onClose: () => void;
    onRequestHigherLimitSuccess: () => void;
}

const responsiveTablet: UseResponsiveScreenQuery = { screenWidth: { smallerThan: 'tablet' } };

export function ESPEndorsementEditESPTechSplitCoverage({
    policyId,
    effectivePeriodStart,
    effectivePeriodEnd,
    techCoverage,
    cyberCoverage,
    userData,
    onPurchaseEndorsementFailure,
    onPurchaseEndorsementSuccess,
    onClose,
    restriction,
    cyberRestriction,
    onRequestHigherLimitSuccess,
    hasRenewalApplication,
}: ESPEndorsementEditESPTechSplitCoverageProps) {
    const [editCoverageRate, setEditCoverageRate] = useState(defaultEditCoverageRate);
    const [isQuoteLoading, setIsQuoteLoading] = useState(false);
    const isMobile = useResponsive(responsiveTablet);

    const { activeSession } = useContext(AppContext);
    const isAdmin = hasRole(activeSession, 'admin');
    const isCyberAllowed = isAdmin ? true : cyberRestriction?.allowCoverage;

    const abortController = useMemo(() => {
        return new AbortController();
    }, []);

    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, [abortController]);

    const espEditCoverageForm = useMemo(
        () =>
            createEspEndorsementEditCoverageCombinedForm({
                policyId: policyId,
                coverageTypeCode: techCoverage.typeCode as EditESPEndorsementLiabilityCoverageType,
                effectivePeriodStart: effectivePeriodStart,
                effectivePeriodEnd: effectivePeriodEnd,
                retention: techCoverage.retention,
                oldTechLimit: techCoverage.limit,
                oldCyberLimit: cyberCoverage.limit,
                onPurchaseEndorsementSuccess: onPurchaseEndorsementSuccess,
                onPurchaseEndorsementFailure: onPurchaseEndorsementFailure,
                abortSignal: abortController.signal,
                onRequestHigherLimitSuccess: onRequestHigherLimitSuccess,
            }),
        [
            policyId,
            effectivePeriodStart,
            effectivePeriodEnd,
            techCoverage.typeCode,
            techCoverage.retention,
            techCoverage.limit,
            cyberCoverage.limit,
            onPurchaseEndorsementSuccess,
            onPurchaseEndorsementFailure,
            abortController.signal,
            onRequestHigherLimitSuccess,
        ],
    );

    const { value, setValue, status, fields, trigger } = useForm(espEditCoverageForm, {
        effectiveDate: null,
        techLimit: undefined,
        cyberLimit: isCyberAllowed ? undefined : 0,
        agreementToConductSignature: false,
        warrantyAndFraudSignature: false,
    });

    useEffect(() => {
        const endorsementEditCoverageSchema = Joi.object({
            techLimit: Joi.number().min(Money.toFloat(techCoverage.limit)).required(),
            cyberLimit: value.techLimit
                ? Joi.number().max(value.techLimit).required()
                : Joi.number().max(Money.toFloat(techCoverage.limit)).required(),
            effectiveDate: Joi.date().min(effectivePeriodStart).max(effectivePeriodEnd).required(),
        });
        const validationResult = endorsementEditCoverageSchema.validate({
            techLimit: value.techLimit,
            cyberLimit: value.cyberLimit,
            effectiveDate: value.effectiveDate,
        });
        if (validationResult.error) {
            setEditCoverageRate(defaultEditCoverageRate);
            return;
        }
        if (
            value.techLimit === Money.toFloat(techCoverage.limit) &&
            value.cyberLimit === Money.toFloat(cyberCoverage.limit)
        ) {
            setEditCoverageRate(defaultEditCoverageRate);
            return;
        }
        setIsQuoteLoading(true);
        execute(RateEditMultipleCoverageESPEndorsement, {
            agreementId: policyId,
            coverages: [
                {
                    coverageTypeCode:
                        'LiabilityCoverageCodeListTechSplit' as EditESPEndorsementLiabilityCoverageType,
                    limit: Money.tryFromFloat(value.techLimit),
                    retention: techCoverage.retention,
                },
                {
                    coverageTypeCode:
                        'LiabilityCoverageCodeListCyberSplit' as EditESPEndorsementLiabilityCoverageType,
                    limit: Money.tryFromFloat(value.cyberLimit),
                    retention: techCoverage.retention,
                },
            ],
            effectiveDate: value.effectiveDate as Date,
        })
            .then((rateResult) => {
                if (isErr(rateResult)) {
                    onPurchaseEndorsementFailure(rateResult.errors);
                }
                setEditCoverageRate((rateResult as SuccessResult<ESPEndorsementRate>).value);
            })
            .finally(() => {
                setIsQuoteLoading(false);
            });
    }, [
        effectivePeriodEnd,
        effectivePeriodStart,
        onPurchaseEndorsementFailure,
        value.effectiveDate,
        value.techLimit,
        value.cyberLimit,
        techCoverage.limit,
        techCoverage.retention,
        techCoverage.typeCode,
        cyberCoverage.limit,
        policyId,
    ]);

    const handleTechLimitChange = (event: { target: { value: string } }) => {
        if (value.techLimit === Number(event.target.value)) {
            return;
        }
        setValue({
            ...value,
            techLimit: Number(event.target.value),
        });
    };

    const handleCyberLimitChange = (event: { target: { value: string } }) => {
        if (value.cyberLimit === Number(event.target.value)) {
            return;
        }
        setValue({
            ...value,
            cyberLimit: Number(event.target.value),
        });
    };

    const handleEffectiveDateChange = useCallback(
        (event: { target: { value: string; date: Date } }) => {
            // const newDate = parse(event.target.value, DATE_FORMAT, new Date(Date.now()));
            const newDate = startOfDay(event.target.date);
            const isNotDateValid =
                !isValid(newDate) ||
                (value.effectiveDate !== null && isSameDay(value.effectiveDate, newDate));
            if (isNotDateValid) {
                return;
            }

            setValue({
                ...value,
                effectiveDate: newDate,
            });
        },
        [setValue, value],
    );

    const handleAgreementToConductSignatureChange = () => {
        setValue({
            ...value,
            agreementToConductSignature: !value.agreementToConductSignature,
        });
    };

    const handleWarrantyAndFraudSignatureChange = () => {
        setValue({
            ...value,
            warrantyAndFraudSignature: !value.warrantyAndFraudSignature,
        });
    };

    const handleRequestHigherLimit = async () => {
        trigger('requestHigherLimit');
    };

    const handlePurchase = async () => {
        trigger('purchase');
    };

    const isPurchaseEnabled = () => {
        return (
            (value.techLimit === Money.toFloat(techCoverage.limit) &&
                value.cyberLimit === Money.toFloat(cyberCoverage.limit)) ||
            value.techLimit < value.cyberLimit
        );
    };

    const isFormSubmitting = status === 'submitting';
    const isLoading = isQuoteLoading || isFormSubmitting;

    const isFormInvalid = status === 'invalid';

    const coverageDisplayName = useMemo(
        () => getCoverageDisplayName(techCoverage.typeCode),
        [techCoverage.typeCode],
    );

    const limitOptions = useMemo(
        () =>
            getEditCoverageLimitOptions(
                techCoverage.typeCode as EditESPEndorsementLiabilityCoverageType,
                techCoverage.limit,
            ),
        [techCoverage.typeCode, techCoverage.limit],
    );

    const secondLimitOptions = useMemo(
        () =>
            getEditCoverageCyberLimitOptions(
                techCoverage.typeCode as EditESPEndorsementLiabilityCoverageType,
                value.techLimit,
                cyberCoverage.limit,
            ),
        [techCoverage.typeCode, value.techLimit, cyberCoverage.limit],
    );

    const maxAllowedLimit = restriction?.maxLimit || Money.tryFromFloat(3000000);
    const maxCyberAllowedLimit = cyberRestriction?.maxLimit || maxAllowedLimit;
    const isHigherLimitEndorsement =
        Money.isGreaterThan(Money.tryFromFloat(value.techLimit), maxAllowedLimit) ||
        Money.isGreaterThan(Money.tryFromFloat(value.cyberLimit), maxCyberAllowedLimit);

    const hideAmounts = isHigherLimitEndorsement || isLoading;

    return (
        <BoxLayout gap="24">
            <Form>
                <StackLayout gap="24">
                    {isLoading && <Spinner appearance="transparent" />}
                    <Text style="heading 3">Edit {coverageDisplayName} Coverage</Text>
                    <StackLayout gap="16">
                        <Text style="heading 5">Errors And Omissions:</Text>
                        <ColumnLayout grow="fixed" responsive={responsiveTablet}>
                            <Form.Field title="Limit" messages={fields.techLimit.messages}>
                                <SelectInput
                                    items={limitOptions.map(toSelectCurrencyOption)}
                                    readOnly={isLoading}
                                    value={value.techLimit}
                                    onChange={handleTechLimitChange}
                                />
                            </Form.Field>
                            <Form.Field
                                inputProps={{
                                    value: techCoverage.retention
                                        ? Money.toFloat(techCoverage.retention)
                                        : undefined,
                                    readOnly: true,
                                }}
                                title="Retention"
                                type="currency"
                            />
                            <div>
                                {hideAmounts ? null : (
                                    <VBox align={isMobile ? 'top spread' : 'bottom spread'}>
                                        <Text style="body 1">Additional premium</Text>
                                        <Text style="heading 5">
                                            <MoneyDisplay value={editCoverageRate.annualPremium} />
                                        </Text>
                                    </VBox>
                                )}
                            </div>
                        </ColumnLayout>
                    </StackLayout>
                    <StackLayout gap="16">
                        <Text style="heading 5">Cyber:</Text>
                        <ColumnLayout grow="fixed" responsive={responsiveTablet}>
                            <Form.Field title="Limit" messages={fields.cyberLimit.messages}>
                                <SelectInput
                                    items={secondLimitOptions.map(toSelectCurrencyOption)}
                                    readOnly={isLoading || !isCyberAllowed}
                                    value={isCyberAllowed ? value.cyberLimit : 0}
                                    onChange={handleCyberLimitChange}
                                />
                            </Form.Field>
                            <Form.Field
                                inputProps={{
                                    value: techCoverage.retention
                                        ? Money.toFloat(techCoverage.retention)
                                        : undefined,
                                    readOnly: true,
                                }}
                                title="Retention"
                                type="currency"
                            />
                            <div />
                        </ColumnLayout>
                    </StackLayout>
                    <ESPEndorsementPremiumCostBreakdown
                        prorate={hideAmounts ? null : editCoverageRate.prorate}
                        taxes={hideAmounts ? null : editCoverageRate.taxes}
                        fees={hideAmounts ? null : editCoverageRate.fees}
                        total={hideAmounts ? null : editCoverageRate.total}
                    />
                    <Grid>
                        <Grid.Row>
                            <Grid.Cell md="1/2" width="1/1">
                                <Form.Field
                                    inputProps={{
                                        value: value.effectiveDate
                                            ? format(value.effectiveDate, DATE_FORMAT)
                                            : undefined,
                                        onChange: handleEffectiveDateChange,
                                        disabled: isLoading,
                                        note: (
                                            <Fragment>
                                                Current policy period:&nbsp;
                                                <DateDisplay value={effectivePeriodStart} />
                                                &nbsp;-&nbsp;
                                                <DateDisplay value={effectivePeriodEnd} />
                                            </Fragment>
                                        ),
                                    }}
                                    title="Effective date"
                                    messages={fields.effectiveDate.messages}
                                    type={fields.effectiveDate.type}
                                    data-e2e="effective-date"
                                />
                            </Grid.Cell>
                        </Grid.Row>
                        {hasRenewalApplication && (
                            <Grid.Row>
                                <Grid.Cell width="1/1">
                                    <StatusMessage status="warning">
                                        The renewal application started prior to this change.
                                        Processing the request will result in the renewal
                                        application being reset.
                                    </StatusMessage>
                                </Grid.Cell>
                            </Grid.Row>
                        )}
                        <ESPEndorsementSignature
                            agreementToConductSignature={value.agreementToConductSignature}
                            onAgreementToConductSignatureChange={
                                handleAgreementToConductSignatureChange
                            }
                            agreementToConductSignatureMessages={
                                fields.agreementToConductSignature.messages
                            }
                            userData={userData}
                            isFormInvalid={isFormInvalid}
                            warrantyAndFraudSignature={value.warrantyAndFraudSignature}
                            onWarrantyAndFraudSignatureChange={
                                handleWarrantyAndFraudSignatureChange
                            }
                            warrantyAndFraudSignatureMessages={
                                fields.warrantyAndFraudSignature.messages
                            }
                        />
                        <Grid.Row>
                            <Grid.Cell>
                                <ButtonBar>
                                    {isHigherLimitEndorsement ? (
                                        <Button
                                            onClick={handleRequestHigherLimit}
                                            disabled={isLoading}
                                            data-e2e="request-higher-limit"
                                        >
                                            Request Higher Limit
                                        </Button>
                                    ) : (
                                        <Button
                                            onClick={handlePurchase}
                                            disabled={isLoading || isPurchaseEnabled()}
                                            data-e2e="purchase"
                                        >
                                            Purchase
                                        </Button>
                                    )}
                                    <TextButton
                                        disabled={isLoading}
                                        onClick={onClose}
                                        data-e2e="cancel"
                                    >
                                        Cancel
                                    </TextButton>
                                </ButtonBar>
                            </Grid.Cell>
                        </Grid.Row>
                    </Grid>
                </StackLayout>
            </Form>
        </BoxLayout>
    );
}
