import { FormElement, FormFactory, FormStateMachine } from '@embroker/service-app-engine';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Data } from '@embroker/shotwell/core/types';
import { AsyncResult, Failure, isErr, Result, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { AppTypeCode } from '../types/enums';
import cnaBopFormEngine from './forms/cna_bop';
import embrokerCrimeFormEngine from './forms/embroker_crime';
import embrokerCyberFormEngine from './forms/embroker_cyber';
import espFormEngine from './forms/esp';
import everestLplFormEngine from './forms/everest_lpl';
import excessFormEngine from './forms/excess';
import gaWcFormEngine from './forms/ga_workers_compensation';
import lawBundleFormEngine from './forms/law_bundle';
import cyberCowbellFormEngine from './forms/cyber_cowbell';
import manualAccountantsPLFormEngine from './forms/manual_accountants_pl';
import manualAutoFormEngine from './forms/manual_auto';
import manualCannabisFormEngine from './forms/manual_cannabis';
import manualConstructionGLFormEngine from './forms/manual_construction_gl';
import manualConstructionPLFormEngine from './forms/manual_construction_pl';
import manualCrimeFormEngine from './forms/manual_crime';
import manualCyberFormEngine from './forms/manual_cyber';
import manualDefaultPLFormEngine from './forms/manual_default_pl';
import manualDirectorsAndOfficersFormEngine from './forms/manual_do';
import manualEplFormEngine from './forms/manual_epl';
import manualFiduciaryFormEngine from './forms/manual_fiduciary';
import manualGLFormEngine from './forms/manual_gl';
import manualHoaFormEngine from './forms/manual_home_owners';
import manualLplFormEngine from './forms/manual_lpl';
import manualProductLiabilityFormEngine from './forms/manual_product_liability';
import manualPropertyFormEngine from './forms/manual_property';
import manualTechEoFormEngine from './forms/manual_tech_eo';
import manualTravelFormEngine from './forms/manual_travel';
import manualUmbrellaFormEngine from './forms/manual_umbrella';
import manualVentureCapitalFormEngine from './forms/manual_vcap';
import manualWcFormEngine from './forms/manual_workers_compensation';
import mplBundleFormEngine from './forms/mpl_bundle';
import newApplicationFormEngine from './forms/new_application';
import pcomlFormEngine from './forms/pcoml';
import hartfordBopFormEngine from './forms/bop_hartford';

export interface GetFormEngineInstanceRequest {
    readonly formEngineContext: Data;
    readonly formEngineConfig?: Data;
    readonly isNewApplication?: boolean;
    readonly appType?: AppTypeCode;
}
export interface GetFormEngineInstanceResponse {
    instance: FormElement<FormStateMachine>;
}

export interface GetFormEngineInstance extends UseCase {
    execute(
        request: GetFormEngineInstanceRequest,
    ): AsyncResult<GetFormEngineInstanceResponse, OperationFailed>;
}

interface ValidateInputProps {
    readonly isNewApplication: boolean;
    readonly appType?: AppTypeCode;
}

interface GetFormEngineFactoryProps {
    readonly isNewApplication: boolean;
    readonly appType?: AppTypeCode;
}
@injectable()
class GetFormEngineInstanceUseCase extends UseCase implements GetFormEngineInstance {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Shopping/GetFormEngineInstanceUseCase');

    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }
    public async execute({
        isNewApplication = false,
        appType,
        formEngineContext,
        formEngineConfig = {},
    }: GetFormEngineInstanceRequest): AsyncResult<GetFormEngineInstanceResponse, OperationFailed> {
        const validationResult = this.validateInput({ isNewApplication, appType });
        if (isErr(validationResult)) {
            return validationResult;
        }

        const factory = this.getFormEngineFactory({ isNewApplication, appType });
        const instance = factory.createInstance(formEngineContext, formEngineConfig);

        return Success<GetFormEngineInstanceResponse>({ instance });
    }

    private readonly appTypeToFormEngineFactoryMap = {
        AppTypeCodeListESP: () => espFormEngine.factory,
        AppTypeCodeListTechEO: () => espFormEngine.factory,
        AppTypeCodeListPCoML: () => pcomlFormEngine.factory,
        AppTypeCodeListManualWorkersCompensation: () => manualWcFormEngine.factory,
        AppTypeCodeListGAWorkersCompensation: () => gaWcFormEngine.factory,
        AppTypeCodeListCNABOP: () => cnaBopFormEngine.factory,
        AppTypeCodeListManualLawyersProfessionalLiability: () => manualLplFormEngine.factory,
        AppTypeCodeListEverestLawyersProfessionalLiability: () => everestLplFormEngine.factory,
        AppTypeCodeListEmbrokerCrime: () => embrokerCrimeFormEngine.factory,
        AppTypeCodeListManualCrime: () => manualCrimeFormEngine.factory,
        AppTypeCodeListManualCyber: () => manualCyberFormEngine.factory,
        AppTypeCodeListEmbrokerCyber: () => embrokerCyberFormEngine.factory,
        AppTypeCodeListManualCannabis: () => manualCannabisFormEngine.factory,
        AppTypeCodeListManualGL: () => manualGLFormEngine.factory,
        AppTypeCodeListManualProperty: () => manualPropertyFormEngine.factory,
        AppTypeCodeListManualProductLiability: () => manualProductLiabilityFormEngine.factory,
        AppTypeCodeListManualConstructionGL: () => manualConstructionGLFormEngine.factory,
        AppTypeCodeListManualTravel: () => manualTravelFormEngine.factory,
        AppTypeCodeListManualUmbrella: () => manualUmbrellaFormEngine.factory,
        AppTypeCodeListManualHomeownersAssociation: () => manualHoaFormEngine.factory,
        AppTypeCodeListManualAuto: () => manualAutoFormEngine.factory,
        AppTypeCodeListOther: () => newApplicationFormEngine.factory,
        AppTypeCodeListManualAccountantsPL: () => manualAccountantsPLFormEngine.factory,
        AppTypeCodeListManualConstructionPL: () => manualConstructionPLFormEngine.factory,
        AppTypeCodeListManualTechEO: () => manualTechEoFormEngine.factory,
        AppTypeCodeListManualDefaultPL: () => manualDefaultPLFormEngine.factory,
        AppTypeCodeListManualDirectorsAndOfficers: () =>
            manualDirectorsAndOfficersFormEngine.factory,
        AppTypeCodeListManualVentureCapitalAssetProtection: () =>
            manualVentureCapitalFormEngine.factory,
        AppTypeCodeListManualEmploymentPractices: () => manualEplFormEngine.factory,
        AppTypeCodeListManualFiduciary: () => manualFiduciaryFormEngine.factory,
        AppTypeCodeListLawBundle: () => lawBundleFormEngine.factory,
        AppTypeCodeListLawBundleCyber: () => lawBundleFormEngine.factory,
        AppTypeCodeListEmbrokerExcess: () => excessFormEngine.factory,
        AppTypeCodeListCyberCowbell: () => cyberCowbellFormEngine.factory,
        AppTypeCodeListBOPChubb: () => lawBundleFormEngine.factory, //TODO - placeholder for future monoline factory/forms
        AppTypeCodeListMPL: () => lawBundleFormEngine.factory, // TODO - placeholder for future monoline factory/forms
        AppTypeCodeListMPLBundle: () => mplBundleFormEngine.factory,
        AppTypeCodeListWCChubb: () => lawBundleFormEngine.factory,
        AppTypeCodeListHartfordBOP: () => hartfordBopFormEngine.factory,
        AppTypeCodeListGenericBundle: () => newApplicationFormEngine.factory,
    };

    private getFormEngineFactory({
        isNewApplication,
        appType,
    }: GetFormEngineFactoryProps): FormFactory {
        const resolveFormEngineFactory =
            isNewApplication || !appType
                ? this.appTypeToFormEngineFactoryMap['AppTypeCodeListOther']
                : this.appTypeToFormEngineFactoryMap[appType];

        return resolveFormEngineFactory();
    }

    private validateInput({
        isNewApplication,
        appType,
    }: ValidateInputProps): Result<void, OperationFailed> {
        if (isNewApplication) {
            return Success();
        }

        if (appType == null) {
            return Failure(OperationFailed({ message: 'Empty AppType' }));
        }

        return Success();
    }
}
export const GetFormEngineInstance: UseCaseClass<GetFormEngineInstance> =
    GetFormEngineInstanceUseCase;
