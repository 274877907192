import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface LPLConfig {
    readonly lplDevModeEffectiveDate: string;
}

export const LPLConfig = {
    ...defineValidator<LPLConfig>({
        lplDevModeEffectiveDate: Joi.string().allow(''),
    }),
    create(lplConfig: LPLConfig) {
        return LPLConfig.validate(lplConfig);
    },
};
