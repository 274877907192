import React from 'react';
import { Modal, useModal } from '@embroker/ui-toolkit/v2';

import { ModalLayout, ModalLayoutProps } from '../../../view/components/ModalLayout.view';

export interface NBRVRedirectGateModalProps extends ModalLayoutProps {
    onClose?: () => void;
    onDismiss?: () => void;
}

export const NBRVRedirectGateModal: React.FC<NBRVRedirectGateModalProps> = ({
    title,
    children,
    primaryAction,
    secondaryAction,
    onClose,
    onDismiss,
}) => {
    const modal = useModal({ visible: true });

    const handlePrimaryCTAClick = () => {
        modal.hide();
        if (primaryAction?.onClick) {
            primaryAction.onClick();
        }
    };

    const handleSecondaryCTAClick = () => {
        modal.hide();
        if (secondaryAction?.onClick) {
            secondaryAction.onClick();
        }
    };

    return (
        <Modal {...modal} onDismiss={onDismiss} onClose={onClose} size="small" dismissable>
            <ModalLayout
                title={title}
                primaryAction={{
                    ...primaryAction,
                    onClick: () => handlePrimaryCTAClick(),
                }}
                secondaryAction={
                    secondaryAction && {
                        ...secondaryAction,
                        onClick: () => handleSecondaryCTAClick(),
                    }
                }
            >
                {children}
            </ModalLayout>
        </Modal>
    );
};
