import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { isDomainEventLocal } from '@embroker/shotwell/core/event/DomainEvent';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { execute } from '@embroker/shotwell/core/UseCase';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { useDomainEvent } from '@embroker/shotwell/view/hooks/useDomainEvent';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    Avatar,
    BoxLayout,
    ColumnLayout,
    Immutable,
    PageLayout,
    Spinner,
    StackLayout,
    TabNavigation,
    Text,
    TextButton,
    useModal,
} from '@embroker/ui-toolkit/v2';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { BrokerProfileInfo } from '../../../brokerDashboard/view/components/BrokerProfileInfo';
import { GetApplicant } from '../../../shopping/useCases/GetApplicant';
import { AppContext } from '../../../view/AppContext';
import { Link } from '../../../view/components';
import { Organization, OrganizationUpdated } from '../../entities/Organization';
import { hasRole } from '../../entities/Session';
import { UserUpdated } from '../../entities/User';
import { GetActiveOrganizationProfile } from '../../useCases/GetActiveOrganizationProfile';
import { GetActiveUserProfile } from '../../useCases/GetActiveUserProfile';
import { CompanySelectionModal } from './CompanySelectionModal';

export interface UserOrgPageProps {
    children: React.ReactNode;
}

export function UserOrgPage({ children }: UserOrgPageProps) {
    const { activeSession } = useContext(AppContext);
    const { result: userProfileResult } = useUseCase(GetActiveUserProfile);
    const { result: companyProfileResult } = useUseCase(GetActiveOrganizationProfile);
    const isBroker = hasRole(activeSession, 'broker');
    const [hasMultipleOrganizations, setHasMultipleOrganizations] = useState(false);
    const [organizationName, setOrganizationName] = useState('');
    const [userFirstName, setUserFirstName] = useState('');
    const [userLastName, setUserLastName] = useState('');
    const userName = userFirstName + ' ' + userLastName;
    const selectCompanyModal = useModal();

    useDomainEvent<UserUpdated>('User', 'Updated', async (event) => {
        if (!isDomainEventLocal(event)) {
            return;
        }
        const result = await execute(GetApplicant);
        if (isOK(result)) {
            setUserFirstName(result.value.applicant.firstName as string);
        }
    });

    useDomainEvent<UserUpdated>('User', 'Updated', async (event) => {
        if (!isDomainEventLocal(event)) {
            return;
        }
        const result = await execute(GetActiveUserProfile);
        if (isOK(result)) {
            setUserLastName(result.value.lastName);
        }
    });

    useDomainEvent<OrganizationUpdated>('Organization', 'Updated', async (event) => {
        if (!isDomainEventLocal(event)) {
            return;
        }
        const result = await execute(GetActiveOrganizationProfile);
        if (isOK(result)) {
            setOrganizationDetails(result.value.organization);
        }
    });

    const setOrganizationDetails = useCallback(
        (organization: Immutable<EntityProps<Organization>>) => {
            if (!isBroker) {
                setOrganizationName(organization.companyLegalName);
            }
        },
        [setOrganizationName, isBroker],
    );

    const [activeTabNavigationIndex, setActiveTabNavigationIndex] = useState<number | undefined>();
    function handleTabNavigationClick(index: number) {
        return (e: React.MouseEvent) => {
            setActiveTabNavigationIndex(index);
        };
    }

    useEffect(() => {
        if (userProfileResult !== undefined && isOK(userProfileResult)) {
            setUserFirstName(userProfileResult.value.firstName);
            setUserLastName(userProfileResult.value.lastName);
            setHasMultipleOrganizations(userProfileResult.value.organizations.length > 1);
        }
    }, [userProfileResult]);

    useEffect(() => {
        if (companyProfileResult !== undefined && isOK(companyProfileResult)) {
            setOrganizationDetails(companyProfileResult.value.organization);
        }
    }, [companyProfileResult, setOrganizationDetails]);

    if (userProfileResult === undefined) {
        return <Spinner />;
    }
    if (isErr(userProfileResult)) {
        return <ErrorPage errors={userProfileResult.errors} />;
    }

    return (
        <PageLayout.Section>
            <StackLayout>
                {isBroker && activeSession.userId !== null ? (
                    <BrokerProfileInfo
                        userId={activeSession.userId}
                        userFirstName={userFirstName}
                        userLastName={userLastName}
                    />
                ) : (
                    <ColumnLayout
                        responsive={{ containerWidth: { smallerThan: 400 } }}
                        gap="16"
                        split="1"
                        top
                    >
                        <ColumnLayout gap="16">
                            <Text>
                                <Avatar
                                    data-e2e="user-org-company-avatar"
                                    size="large"
                                    round
                                    initials={getInitials(userName, organizationName)}
                                    as="span"
                                />
                            </Text>

                            {userFirstName ? (
                                <div>
                                    <Text
                                        data-e2e="user-org-first-last-name"
                                        as="span"
                                        style="heading 2"
                                    >
                                        {userFirstName} {userLastName}
                                    </Text>
                                    <Text data-e2e="user-org-name">{organizationName}</Text>
                                </div>
                            ) : (
                                <BoxLayout>
                                    <Text data-e2e="user-org-name-no-first-last">
                                        {organizationName}
                                    </Text>
                                </BoxLayout>
                            )}
                        </ColumnLayout>
                        {!isBroker && (
                            <TextButton
                                data-e2e="user-org-switch-add-button"
                                onClick={selectCompanyModal.show}
                            >
                                {hasMultipleOrganizations
                                    ? 'Switch Companies'
                                    : 'Add a New Company'}
                            </TextButton>
                        )}
                    </ColumnLayout>
                )}

                <BoxLayout>
                    {isBroker ? (
                        <TabNavigation>
                            <TabNavigation.Item
                                data-e2e="user-org-broker-profile-nav-link"
                                active={activeTabNavigationIndex === 0}
                                onClick={handleTabNavigationClick(0)}
                                as={Link}
                                href="/broker/profile"
                                exact
                            >
                                Edit Profile
                            </TabNavigation.Item>
                            <TabNavigation.Item
                                data-e2e="user-org-broker-change-pwd-nav-link"
                                active={activeTabNavigationIndex === 1}
                                onClick={handleTabNavigationClick(1)}
                                as={Link}
                                href="/broker/change-password"
                                exact
                            >
                                Change Password
                            </TabNavigation.Item>
                        </TabNavigation>
                    ) : (
                        <TabNavigation>
                            <TabNavigation.Item
                                data-e2e="user-org-user-profile-nav-link"
                                active={activeTabNavigationIndex === 0}
                                onClick={handleTabNavigationClick(0)}
                                as={Link}
                                href="/user/profile"
                                exact
                            >
                                Edit Profile
                            </TabNavigation.Item>
                            <TabNavigation.Item
                                data-e2e="user-org-change-pwd-link"
                                active={activeTabNavigationIndex === 1}
                                onClick={handleTabNavigationClick(1)}
                                as={Link}
                                href="/user/change-password"
                                exact
                            >
                                Change Password
                            </TabNavigation.Item>
                            <TabNavigation.Item
                                data-e2e="user-org-company-profile-nav-link"
                                active={activeTabNavigationIndex === 2}
                                onClick={handleTabNavigationClick(2)}
                                as={Link}
                                href="/company-profile"
                                exact
                            >
                                Edit Company
                            </TabNavigation.Item>
                            <TabNavigation.Item
                                data-e2e="user-org-manage-users-nav-link"
                                active={activeTabNavigationIndex === 3}
                                onClick={handleTabNavigationClick(3)}
                                as={Link}
                                href="/user/manage-users"
                                exact
                            >
                                Edit Team Settings
                            </TabNavigation.Item>
                        </TabNavigation>
                    )}
                </BoxLayout>
            </StackLayout>
            {children}
            <CompanySelectionModal modal={selectCompanyModal} />
        </PageLayout.Section>
    );
}

function getInitials(userName: string, organizationName: string): string {
    return userName.trim() ? userName : organizationName;
}
