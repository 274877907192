import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { NotificationRepository } from '../repositories/NotificationRepository';

export interface GetNotificationsCountResponse {
    notificationsCount: number;
}

export interface GetNotificationsCount extends UseCase {
    execute(): AsyncResult<GetNotificationsCountResponse, InvalidArgument | OperationFailed>;
}

class GetOrganizationProfileUseCase extends UseCase implements GetNotificationsCount {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/GetNotificationsCount');
    /**
     * Constructor for GetNotificationsCount use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param notificationRepository is user repository used to store user entity with updated properties
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(NotificationRepository) private notificationRepository: NotificationRepository,
    ) {
        super(eventBus);
    }

    public async execute(): AsyncResult<
        GetNotificationsCountResponse,
        InvalidArgument | OperationFailed
    > {
        const notificationsResult = await this.notificationRepository.pendingNotificationsCount();

        if (isErr(notificationsResult)) {
            return notificationsResult;
        }

        return Success({
            notificationsCount: notificationsResult.value,
        });
    }
}

export const GetNotificationsCount: UseCaseClass<GetNotificationsCount> =
    GetOrganizationProfileUseCase;
