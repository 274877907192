import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { ColumnLayout, InsetBox, ScrollBox, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { Money } from '@embroker/shotwell/core/types/Money';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';

export interface ManualQuote {
    name: string;
    effectiveDate: Date;
    totalPremium: Money;
}

export function ManualQuoteModalSummary({ quote }: { quote: ManualQuote }) {
    return (
        <StackLayout>
            <ColumnLayout gap="8">
                <Text>Effective date: </Text>{' '}
                <Text style="heading 5">
                    <DateDisplay value={quote.effectiveDate} />
                </Text>
            </ColumnLayout>
            <InsetBox withBorder background="ui-100">
                <ScrollBox>
                    <StackLayout gap="24">
                        <Text style="heading 5">{quote.name}</Text>
                        <ColumnLayout gap="8">
                            <Text className="u-1/2" style="overline">
                                Premium
                            </Text>
                            <MoneyDisplay value={quote.totalPremium} fractionDigits={0} />
                        </ColumnLayout>
                    </StackLayout>
                </ScrollBox>
            </InsetBox>
        </StackLayout>
    );
}
