import { inject } from '@embroker/shotwell/core/di';
import { OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ApplicationNotFound, OperationNotAllowed } from '../../shopping/errors';
import { ApplicationRepository } from '../../shopping/repositories/ApplicationRepository';

export interface UpdateClientApplicationRequest {
    token: string;
    questionnaireData: string;
}

export interface UpdateClientApplication extends UseCase {
    execute(
        request: UpdateClientApplicationRequest,
    ): AsyncResult<void, ApplicationNotFound | OperationNotAllowed | OperationFailed>;
}

class UpdateClientApplicationUseCase extends UseCase implements UpdateClientApplication {
    public static type = Symbol('Shopping/UpdateClientApplication');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
    ) {
        super(eventBus);
    }
    public async execute({
        token,
        questionnaireData,
    }: UpdateClientApplicationRequest): AsyncResult<
        void,
        ApplicationNotFound | OperationNotAllowed | OperationFailed
    > {
        return await this.applicationRepository.updateClientApplication(token, questionnaireData);
    }
}

export const UpdateClientApplication: UseCaseClass<UpdateClientApplication> =
    UpdateClientApplicationUseCase;
