import { StateChangeEvent, StateSelect } from '@embroker/shotwell/view/components/StateSelect';
import {
    GoogleAddressProps,
    useGoogleLocations,
} from '@embroker/shotwell/view/hooks/useGooglePlaces';
import React, { useRef } from 'react';
import innerText from 'react-innertext';
import { MailingAddress as MailingAddressType } from '../../types/MailingAddress';
import { FieldDefinition, FormFieldMessage } from '@embroker/shotwell/view/hooks/useForm';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import { ColumnLayout, Form, StackLayout } from '@embroker/ui-toolkit/v2';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { MESSAGES as ZIPCODE_VALIDATION_MESSAGES } from '@embroker/shotwell/core/validation/zipCode';
import {
    ANY_REQUIRED,
    MULTI_FIELD_INPUT_DELIMITER,
} from '@app/view/components/DataDrivenForm/types/validationObject';

export const locationFieldValidator = MailingAddressType.schema.concat(
    Joi.object({
        zip: ZipCode.schema.fromState(Joi.ref('state')),
    }),
);
export const locationFieldFormatValidationError = (error: InvalidArgument) => {
    return `${error.details.argument}${MULTI_FIELD_INPUT_DELIMITER}${error.details.validator}`;
};

export const LocationFieldDefinition: FieldDefinition<MailingAddressType> = {
    type: 'text',
    validator: locationFieldValidator,
    formatValidationError: locationFieldFormatValidationError,
};

const MAILING_ADDRESS_DEFAULT_VALUE: Partial<MailingAddressType> = {
    addressLine1: undefined,
    addressLine2: undefined,
    state: undefined,
    zip: undefined,
    city: undefined,
    county: undefined,
};

interface LocationFieldSetProps {
    fieldValue?: MailingAddressType;
    messages: Immutable<FormFieldMessage[]>;
    onChange: (newValue: Partial<MailingAddressType>) => void;
    disableState?: boolean;
    autoSuggest?: boolean;
    readOnly?: boolean;
    name?: string;
}

function hasEmptyObjectRequiredMessage(messages: Immutable<FormFieldMessage[]>): boolean {
    const messageTexts = messages.map(innerText);
    const hasFieldSpecificMessage = messageTexts.some((message) => {
        return Object.keys(MAILING_ADDRESS_DEFAULT_VALUE).some((key) => message.includes(key));
    });
    const hasAnyRequiredMessage = messageTexts.some((message) => message.includes(ANY_REQUIRED));
    // If there is no field specific message and there is an any required message, then we have an empty object required message
    return !hasFieldSpecificMessage && hasAnyRequiredMessage;
}

const parseMessageForInput = (
    messages: Immutable<FormFieldMessage[]>,
    inputName: keyof MailingAddressType,
    inputLabel: string,
) => {
    const defaultMessage = `Please enter an address that's a physical location (no P.O. boxes, etc.`;

    const inputMessage = messages
        .map(innerText)
        .map((message) => {
            const [fieldName, validator] = message.split(MULTI_FIELD_INPUT_DELIMITER);

            return {
                fieldName,
                validator,
            };
        })
        .filter(({ fieldName }) => fieldName === inputName)
        .map(({ validator }) => validator)[0];

    if (inputMessage || hasEmptyObjectRequiredMessage(messages)) {
        let message = 'Please enter a valid ' + inputLabel;

        switch (inputMessage) {
            case 'string.empty': {
                message = 'You must enter your ' + inputLabel;
                break;
            }

            case 'string.pattern.base':
            case 'string.pattern.name': {
                message = defaultMessage;
                break;
            }
        }

        if (inputName === 'zip') {
            message = ZIPCODE_VALIDATION_MESSAGES[inputMessage] ?? message;
        }

        return [message];
    }

    return [];
};

export function LocationFieldSet({
    fieldValue,
    messages = [],
    onChange,
    disableState = false,
    autoSuggest = true,
    readOnly = false,
    name,
}: LocationFieldSetProps) {
    const addressSearch = useRef<HTMLInputElement>(null);
    const data = fieldValue || { ...MAILING_ADDRESS_DEFAULT_VALUE };

    const fillInAddress = (prop: GoogleAddressProps) => {
        const { mailingAddress, county, city, state, zip } = prop;
        onChange({
            ...data,
            addressLine1: mailingAddress,
            city: city,
            county: county,
            state: state,
            zip: zip,
        });
    };

    useGoogleLocations(addressSearch, fillInAddress, !autoSuggest, {
        mailingAddress: data.addressLine1 ?? undefined,
        city: data.city ?? undefined,
        state: data.state ?? undefined,
        county: data.county ?? undefined,
        zip: data.zip ?? undefined,
    });

    const handleOnChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | StateChangeEvent
            | { target: { value: string | ZipCode } },
        attr: keyof MailingAddressType,
    ) => {
        if (attr !== undefined) {
            onChange({
                ...data,
                [attr]: event.target.value,
            });
        }
    };

    const stateHasInputErrors = parseMessageForInput(messages, 'state', 'state').length > 0;

    return (
        <StackLayout gap="12">
            <Form.Field
                data-e2e="user-org-mailing-address-line-1"
                label="Address line 1"
                inputProps={{
                    value: data.addressLine1 ?? '',
                    onChange: (e) => handleOnChange(e, 'addressLine1'),
                    ref: addressSearch,
                    placeholder: '',
                    name: name ? `${name}-address-line-1` : undefined,
                }}
                messages={parseMessageForInput(messages, 'addressLine1', 'street address')}
                type="text"
                readOnly={readOnly}
            />
            <Form.Field
                data-e2e="user-org-mailing-address-line-2"
                inputProps={{
                    value: data.addressLine2 ?? '',
                    onChange: (e) => handleOnChange(e, 'addressLine2'),
                    name: name ? `${name}-address-line-2` : undefined,
                }}
                label="Address line 2"
                type="text"
                readOnly={readOnly}
            />
            <ColumnLayout responsive={{ screenWidth: { smallerThan: 'tablet' } }} grow="fixed">
                <Form.Field
                    data-e2e="user-org-mailing-address-city"
                    inputProps={{
                        value: data.city ?? '',
                        onChange: (e) => handleOnChange(e, 'city'),
                        name: name ? `${name}-city` : undefined,
                    }}
                    messages={parseMessageForInput(messages, 'city', 'city')}
                    label="City"
                    type="text"
                    readOnly={readOnly}
                />
                <Form.Field
                    label="State"
                    messages={parseMessageForInput(messages, 'state', 'state')}
                >
                    <StateSelect
                        data-e2e="user-org-mailing-address-state"
                        placeholder="State"
                        onChange={(e: StateChangeEvent) => handleOnChange(e, 'state')}
                        hasErrors={stateHasInputErrors}
                        value={data.state ?? undefined}
                        disabled={disableState}
                        readOnly={readOnly}
                        name={name ? `${name}-state` : undefined}
                    />
                </Form.Field>
                <Form.Field
                    data-e2e="user-org-mailing-address-zip"
                    inputProps={{
                        value: data.zip ?? '',
                        onChange: (e) => handleOnChange(e, 'zip'),
                        name: name ? `${name}-zip` : undefined,
                    }}
                    label="Zip"
                    messages={parseMessageForInput(messages, 'zip', 'zip')}
                    type="zip"
                    readOnly={readOnly}
                />
            </ColumnLayout>
        </StackLayout>
    );
}
