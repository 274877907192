import { container } from '@embroker/shotwell/core/di';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { deepClone } from '@embroker/shotwell/core/object';
import { Immutable, Nullable, Props } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { cast } from '@embroker/shotwell/core/types/Nominal';
import { Failure, isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { Revenue } from '@embroker/shotwell/core/types/Revenue';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Year } from '@embroker/shotwell/core/types/Year';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import {
    CurrencyInput,
    CurrencyInputEvent,
} from '@embroker/shotwell/view/components/CurrencyInput';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { FieldMap, OpaqueForm, createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    Button,
    Form,
    FormLayout,
    RadioGroup,
    SelectChangeEvent,
    SelectInput,
    SelectOptionAsyncType,
    StackLayout,
    StatusMessage,
    StatusMessageList,
    Text,
    TextAreaInput,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { GetNAICSCodesByGroup } from '../../../industries/useCases/GetNAICSCodesByGroup';
import { Naics } from '../../../industries/view/components/Naics';
import { Location as LocationType } from '../../../locations/entities/Location';
import { LocationData } from '../../../locations/view/components/Location';
import { LocationList } from '../../../locations/view/components/LocationList';
import { MainAreaOfFocusList } from '../../types/enums';
import { MailingAddress as MailingAddressType } from '../../types/MailingAddress';
import { GetActiveOrganizationProfile } from '../../useCases/GetActiveOrganizationProfile';
import { GetEntityTypes } from '../../useCases/GetEntityTypes';
import { GetOrganizationProfile } from '../../useCases/GetOrganizationProfile';
import { UpdateOrganizationProfile } from '../../useCases/UpdateOrganizationProfile';
import { RevenueList } from './RevenueList';
import { UserOrgPage } from './UserOrgPage';
import { LocationFieldDefinition, LocationFieldSet } from './LocationFieldSet.view';

interface BusinessProfileFormData {
    id: UUID;
    companyLegalName: string;
    website: string;
    naics: Nullable<string>;
    entityType: Nullable<UUID>;
    totalNumberOfEmployees: Nullable<number>;
    totalAnnualPayroll: Nullable<Money>;
    yearStarted: Nullable<Year>;
    headquarters: MailingAddressType;
    otherLocations: Immutable<Props<Omit<LocationType, 'events'>>>[];
    revenues: Revenue[];
    howDoesYourCompanyGenerateRevenue: Nullable<string>;
    raisedFunding: Nullable<boolean>;
    techAreaOfFocus: Nullable<string>;
}

const BusinessProfileForm = createForm<BusinessProfileFormData>({
    fields: {
        id: {
            type: 'text',
            validator: UUID.schema,
        },
        companyLegalName: {
            type: 'text',
            validator: Joi.string().required(),
            formatValidationError() {
                return 'You must enter your company name';
            },
        },
        website: {
            type: 'text',
            validator: Joi.string().allow(null),
        },
        naics: {
            type: 'selectasync',
            validator: Joi.string().allow(null),
            formatValidationError(error) {
                return 'You must select naics code';
            },
        },
        entityType: {
            type: 'select',
            validator: UUID.schema.allow(null),
            formatValidationError() {
                return 'You must select entity type';
            },
        },
        totalNumberOfEmployees: {
            type: 'number',
            validator: Joi.number().integer().allow(null),
            formatValidationError(error) {
                if (error.details.validator === 'number.integer') {
                    return 'You must enter integer value for total number of employees';
                }
                return 'You must enter number of employees';
            },
        },
        totalAnnualPayroll: {
            type: 'number',
            validator: Money.schema.allow(null),
            formatValidationError() {
                return 'You must enter total annual payroll';
            },
        },
        yearStarted: {
            type: 'number',
            validator: Year.schema.optional().allow(null),
            formatValidationError(error) {
                switch (error.details.validator) {
                    case 'number.min':
                    case 'number.max':
                        return 'Year not in valid range';
                    case 'number.base':
                        return 'Enter valid year format using only digits';
                    default:
                        return 'Please check is your year in valid format';
                }
            },
        },
        headquarters: LocationFieldDefinition,
        otherLocations: {
            type: 'text', // temporary until groups are introduced
            validator: Joi.array().items(LocationType.schema),
            formatValidationError(error) {
                switch (error.details.validator) {
                    case 'string.pattern.base':
                        return "Please enter an address that's a physical location (no P.O. boxes, etc.)";
                    case 'string.pattern.name':
                        return "Please enter an address that's a physical location (no P.O. boxes, etc.)";
                    case 'string.empty':
                        return 'Location fields must be filled';
                    default:
                        return error.message;
                }
            },
        },
        revenues: {
            type: 'text', // Not really important, since we'll pick the component to render, not ui toolkit
            items: {
                fiscalYear: {
                    type: 'number',
                    validator: Year.schema.required(),
                },
                grossTotal: {
                    type: 'number',
                    validator: Money.schema.required(),
                    formatValidationError(error) {
                        switch (error.details.validator) {
                            case 'number.base':
                            case 'any.required':
                                return 'This field is required';
                            default:
                                return error.message;
                        }
                    },
                },
            },
            validator: Joi.array().items(Revenue.schema),
        },
        howDoesYourCompanyGenerateRevenue: {
            type: 'textarea',
            validator: Joi.string().allow(null, ''),
        },
        raisedFunding: {
            type: 'radioGroup',
            validator: Joi.boolean().allow(null),
        },
        techAreaOfFocus: {
            type: 'selectasync',
            validator: Joi.string().allow(null),
        },
    },
    submit: async (businessData) => {
        if (
            businessData.headquarters.zip !== null &&
            !ZipCode.check(businessData.headquarters.zip)
        ) {
            return Failure(
                InvalidArgument({ argument: 'ZipCode', value: businessData.headquarters.zip }),
            );
        }

        return await execute(UpdateOrganizationProfile, businessData);
    },
    formatSubmitErrors(errors) {
        if (errors.length === 0) {
            return [];
        }
        container.get<Logger>(Log).warn(`Company Profile formatSubmitErrors: ${errors}`);
        return ['Sorry, data you entered is invalid.'];
    },
});

interface EntityTypeOption {
    value: UUID;
    title: string;
}

export function UpdateBusinessProfile() {
    const { result: organizationProfile } = useUseCase(GetActiveOrganizationProfile);
    const { result: entityTypes } = useUseCase(GetEntityTypes);

    if (organizationProfile === undefined || entityTypes === undefined) {
        return null;
    }

    if (isErr(organizationProfile)) {
        return <ErrorPage errors={organizationProfile.errors} />;
    }

    if (isErr(entityTypes)) {
        return <ErrorPage errors={entityTypes.errors} />;
    }

    const businessProfile = organizationProfile.value.organization;
    const entityTypeList = entityTypes.value.entityTypes;

    const createEntityTypeOptions = (): EntityTypeOption[] => {
        if (entityTypeList === null) {
            return [];
        }
        return entityTypeList.map((item) => {
            return {
                value: item.id,
                title: item.name,
            };
        });
    };

    const entityTypeOptions = createEntityTypeOptions();

    return <BusinessProfile data={businessProfile} entityTypes={entityTypeOptions} />;
}

interface BusinessProfileData {
    data: any;
    entityTypes: EntityTypeOption[];
}

export function BusinessProfile({ data, entityTypes }: BusinessProfileData) {
    const [formStatus, setFormStatus] = useState<'draft' | 'submitted' | 'invalid'>('draft');
    const { submit, fields, setValue, value, status, messages } = useForm<
        OpaqueForm<BusinessProfileFormData>
    >(BusinessProfileForm, {
        ...data,
    });

    const { result: technologyNaicsCodesResult } = useUseCase(GetNAICSCodesByGroup, {
        naicsGroup: 'Technology',
    });

    const { result: lawFirmNaicsCodesResult } = useUseCase(GetNAICSCodesByGroup, {
        naicsGroup: 'LawFirm',
    });

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const updateOtherLocations = useCallback(() => {
        execute(GetOrganizationProfile, { organizationId: value.id }).then((result) => {
            if (isOK(result) && result.value?.organization?.otherLocations != null) {
                setValue({
                    ...value,
                    otherLocations: result.value.organization.otherLocations,
                });
            }
        });
    }, [value, setValue]);

    useEffect(() => {
        if (status === 'submitted') {
            updateOtherLocations();
            setFormStatus('submitted');
            scrollToTop();
        }
        if (status === 'invalid') {
            setFormStatus('invalid');
            scrollToTop();
        }
    }, [status, updateOtherLocations]);

    if (!technologyNaicsCodesResult || !lawFirmNaicsCodesResult) {
        return null;
    }

    const showTechAreaOfFocus = (function (): boolean {
        const hasRaisedFunding = fields.raisedFunding.props.value;

        if (isErr(technologyNaicsCodesResult)) {
            container.get<Logger>(Log).error(technologyNaicsCodesResult.errors);
            return true;
        }

        if (hasRaisedFunding) {
            return true;
        }

        const naicsCode = fields.naics.props.value;
        return technologyNaicsCodesResult.value.naicsList.includes(naicsCode ?? '');
    })();

    const showRaisedFunding = (function (): boolean {
        if (isErr(lawFirmNaicsCodesResult)) {
            container.get<Logger>(Log).error(lawFirmNaicsCodesResult.errors);
            return true;
        }

        const naicsCode = fields.naics.props.value;
        return !lawFirmNaicsCodesResult.value.naicsList.includes(naicsCode ?? '');
    })();

    const handleAddNewLocation = async () => {
        setValue({
            ...value,
            otherLocations: value.otherLocations.concat({
                id: UUID.create(),
                addressLine1: null,
                addressLine2: null,
                city: null,
                state: null,
                zip: null,
                county: null,
                valueOfProperty: null,
                squareFootageOccupied: null,
            }),
        });
    };

    const handleHeadquartersChange = (newValue: Partial<MailingAddressType>) => {
        setValue({
            ...value,
            headquarters: newValue as MailingAddressType,
        });
    };

    const handleOtherLocationsChange = (newValue: Immutable<LocationData[]>) => {
        setValue(
            deepClone({
                ...value,
                otherLocations: newValue,
            }),
        );
    };

    const handleEntityTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            entityType: cast<UUID>(event.target.value ?? null),
        });
    };

    const handleNaicsCodeTypeChange = (newValue?: SelectOptionAsyncType) => {
        setValue({
            ...value,
            naics: newValue?.value ?? null,
        });
    };

    const handleTotalAnnualPayrollChange = (event: CurrencyInputEvent) => {
        setValue({
            ...value,
            totalAnnualPayroll: event.target.value ?? null,
        });
    };

    const handleTechAreaOfFocusChange = (event: SelectChangeEvent<string, false>) => {
        setValue({
            ...value,
            techAreaOfFocus: event.target.value ?? null,
        });
    };

    const handleHowDoesYourCompanyGenerateRevenue = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setValue({
            ...value,
            howDoesYourCompanyGenerateRevenue: event.target.value,
        });
    };

    return (
        <UserOrgPage>
            <FormLayout>
                <Form noValidate onSubmit={submit}>
                    <StackLayout data-e2e="user-org-company-status-msg-wrapper" gap="32">
                        <StatusMessageList messages={messages} status="error" />
                        {formStatus === 'submitted' ? (
                            <StatusMessage status="success">Company profile updated</StatusMessage>
                        ) : formStatus === 'invalid' ? (
                            <StatusMessage status="error">
                                Sorry, data you entered is invalid.
                            </StatusMessage>
                        ) : null}
                        <Text data-e2e="company-profile-header" style="heading 3">
                            Company profile
                        </Text>
                        <StackLayout>
                            <Form.Field
                                data-e2e="user-org-company-profile-company-name"
                                inputProps={{
                                    ...fields.companyLegalName.props,
                                }}
                                label="Company legal name"
                                messages={fields.companyLegalName.messages}
                                type={fields.companyLegalName.type}
                            />
                            <Form.Field
                                data-e2e="user-org-company-profile-website"
                                inputProps={{
                                    ...fields.website.props,
                                }}
                                label="Website"
                                messages={fields.website.messages}
                                type={fields.website.type}
                            />
                            <Form.Field
                                data-e2e="user-org-company-profile-naics-form"
                                label="What does your company do?"
                                messages={fields.naics.messages}
                                e2e-data-company-profile="naics-code"
                                inputProps={{
                                    ...fields.naics.props,
                                    value: String(fields.naics.props.value),
                                }}
                            >
                                <Naics
                                    data-e2e="user-org-company-profile-naics"
                                    {...fields.naics.props}
                                    initialValue={fields.naics.props.value ?? undefined}
                                    onChange={handleNaicsCodeTypeChange}
                                />
                            </Form.Field>
                            <Form.Field
                                data-e2e="user-org-company-profile-entity-type"
                                inputProps={{
                                    ...fields.entityType.props,
                                    value: String(fields.entityType.props.value),
                                    items: entityTypes,
                                    onChange: handleEntityTypeChange,
                                    placeholder: '',
                                }}
                                label="Entity type"
                                messages={fields.entityType.messages}
                                type={fields.entityType.type}
                            />
                            <Form.Field
                                data-e2e="user-org-company-profile-num-of-employees"
                                inputProps={{
                                    ...fields.totalNumberOfEmployees.props,
                                    value: String(fields.totalNumberOfEmployees.props.value ?? ''),
                                }}
                                label="Total number of employees"
                                messages={fields.totalNumberOfEmployees.messages}
                                type={fields.totalNumberOfEmployees.type}
                            />
                            <Form.Field label="Total annual payroll">
                                <CurrencyInput
                                    data-e2e="user-org-company-profile-total-annual-payroll"
                                    label="Total annual payroll"
                                    onChange={handleTotalAnnualPayrollChange}
                                    value={fields.totalAnnualPayroll.props.value}
                                />
                            </Form.Field>
                            <Form.Field
                                data-e2e="user-org-company-profile-total-year-started"
                                inputProps={{
                                    ...fields.yearStarted.props,
                                    value: String(fields.yearStarted.props.value ?? ''),
                                    includeThousandsSeparator: false,
                                    allowNegative: false,
                                }}
                                label="Year started"
                                messages={fields.yearStarted.messages}
                                type={fields.yearStarted.type}
                            />
                            {showRaisedFunding ? (
                                <div>
                                    <Text data-e2e="company-has-raised-funds-header" style="body 1">
                                        Has your company raised funding?
                                    </Text>
                                    <RadioGroup
                                        data-e2e="user-org-company-profile-raised-funds-yes-no"
                                        items={[
                                            {
                                                title: 'Yes',
                                                value: true,
                                            },
                                            {
                                                title: 'No',
                                                value: false,
                                            },
                                        ]}
                                        {...fields.raisedFunding.props}
                                    ></RadioGroup>{' '}
                                </div>
                            ) : null}
                            {showTechAreaOfFocus ? (
                                <SelectInput
                                    data-e2e="user-org-company-profile-main-area-of-focus"
                                    label="Main area of focus"
                                    value={String(fields.techAreaOfFocus.props.value ?? '')}
                                    items={MainAreaOfFocusList}
                                    onChange={handleTechAreaOfFocusChange}
                                />
                            ) : null}
                        </StackLayout>
                        <Text data-e2e="company-profile-locations-header" style="heading 3">
                            Company headquarters location
                        </Text>
                        <Text style="body 1" color="ui-500">
                            Please provide a physical address and not a P.O. box, mailing address,
                            or your registered agent’s address. If the company is fully remote,
                            enter the CEO’s or Founder’s address.
                        </Text>
                        <LocationFieldSet
                            fieldValue={value.headquarters}
                            messages={fields.headquarters.messages}
                            onChange={handleHeadquartersChange}
                        />
                        <Text data-e2e="company-profile-locations-header" style="heading 3">
                            Company locations
                        </Text>
                        {fields.otherLocations.props.value &&
                        fields.otherLocations.props.value.length > 0 ? (
                            <React.Fragment>
                                <LocationList
                                    data={fields.otherLocations.props.value}
                                    onChange={handleOtherLocationsChange}
                                    messages={fields.otherLocations.messages}
                                />
                                <TextButton
                                    data-e2e="user-org-company-add-another-location"
                                    onClick={handleAddNewLocation}
                                >
                                    + Add another company location
                                </TextButton>
                            </React.Fragment>
                        ) : (
                            <Button
                                data-e2e="user-org-company-add-a-location-btn"
                                appearance="secondary"
                                onClick={handleAddNewLocation}
                            >
                                Add a location
                            </Button>
                        )}
                        <Text data-e2e="user-org-company-revenue-header" style="heading 3">
                            Revenue
                        </Text>
                        <StackLayout>
                            <RevenueList
                                items={
                                    (fields.revenues.items as Immutable<FieldMap<Revenue>[]>) ?? []
                                }
                            />
                        </StackLayout>
                        <StackLayout>
                            <Text>How does your company generate revenue?</Text>
                            <TextAreaInput
                                data-e2e="user-org-company-how-generate-revenue-input"
                                onChange={handleHowDoesYourCompanyGenerateRevenue}
                                value={value.howDoesYourCompanyGenerateRevenue ?? ''}
                            />
                        </StackLayout>
                        <Button data-e2e="user-org-company-submit-button" type="submit">
                            Save
                        </Button>
                    </StackLayout>
                </Form>
            </FormLayout>
        </UserOrgPage>
    );
}
