import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable, Props } from '@embroker/shotwell/core/types/index';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { LocationRepository } from '../../locations/repositories/LocationsRepository/index';
import { Settings } from '../../locations/types/Settings';
/**
 * Response data for GetSettings use case
 */
export interface GetSettingsResponse {
    settings: Immutable<Props<Settings>>;
}

/**
 * GetSettings use case is used to fetch settings about locations
 */
export interface GetSettings extends UseCase {
    execute(): AsyncResult<GetSettingsResponse, InvalidArgument | OperationFailed>;
}

@injectable()
class GetSettingsUseCase extends UseCase implements GetSettings {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Locations/GetSettings');

    /**
     * Constructor for GetSettings use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(LocationRepository) private readonly locationsRepository: LocationRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes GetSettings use case
     * Input is of GetSettingsRequest type
     * @returns data of GetSettingsResponse type if execution was successful
     * @returns InvalidArgument if repository could not read settings properly
     * @returns OperationFailed if repository could not fetch settings
     */
    public async execute(): AsyncResult<GetSettingsResponse, InvalidArgument | OperationFailed> {
        const result = await this.locationsRepository.getSettings();

        if (isErr(result)) {
            return result;
        }

        return Success<GetSettingsResponse>({
            settings: result.value,
        });
    }
}

export const GetSettings: UseCaseClass<GetSettings> = GetSettingsUseCase;
