import { CertificateCoverageInfo } from './CertificateCoverageInfo';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { Money } from '@embroker/shotwell/core/types/Money';

export interface CertificateCoverageWorkersCompensation {
    coverageInfo: CertificateCoverageInfo;
    isExecutiveOfficerExcluded?: boolean;
    isPerStatute?: boolean;
    other?: Money;
}

export const CertificateCoverageWorkersCompensation = {
    ...defineValidator<CertificateCoverageWorkersCompensation>(
        Joi.object({
            coverageInfo: CertificateCoverageInfo.schema,
            isExecutiveOfficerExcluded: Joi.boolean().optional(),
            isPerStatute: Joi.boolean().optional(),
            other: Money.schema.optional(),
        }).preferences({ presence: 'required' }),
    ),
    create(certificateCoverageWorkersCompensation: CertificateCoverageWorkersCompensation) {
        return CertificateCoverageWorkersCompensation.validate(
            certificateCoverageWorkersCompensation,
        );
    },
};
