import { inject } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Success, isErr, Failure, AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { NotificationRepository } from '../repositories/NotificationRepository';
import { Notification, NotificationViewModel } from '../entities/Notification';
import { SessionRepository } from '../../userOrg/repositories/SessionRepository';
import { isAuthenticated } from '../../userOrg/entities/Session';
import { Unauthenticated } from '../../userOrg/errors';

export interface GetActiveNotifications extends UseCase {
    execute(): AsyncResult<NotificationViewModel[], Unauthenticated>;
}

class GetNotificationsUseCase extends UseCase implements GetActiveNotifications {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('NotificationCenter/GetNotificationsUseCase');
    /**
     * Constructor for GetNotificationsUseCase use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param notificationRepository is notification repository used to store user notifications
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(SessionRepository) private sessionRepository: SessionRepository,
        @inject(NotificationRepository) private notificationRepository: NotificationRepository,
    ) {
        super(eventBus);
    }

    public async execute(): AsyncResult<NotificationViewModel[], Unauthenticated> {
        const activeSesion = await this.sessionRepository.getActiveSession();
        if (!isAuthenticated(activeSesion.value)) {
            return Failure(Unauthenticated());
        }

        const notifications = await this.notificationRepository.getNotifications();

        if (isErr(notifications)) {
            // Because we want to get user notifications on page load
            // We are executing GetNotificationsUseCase in the notification module bootstrap,
            // Since the gateway is a separate service we want our FE app to function even when gateway is down.
            // If gateway is unreachable and we cannot get notifications, fail silently and return a empty array.
            return Success<NotificationViewModel[]>([]);
        }

        return Success<NotificationViewModel[]>(
            notifications.value.map((n: Notification) => n.toDTO()),
        );
    }
}

export const GetActiveNotifications: UseCaseClass<GetActiveNotifications> = GetNotificationsUseCase;
