import { Modal, Text, TextButton, useModal } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { LPLUserInfo } from '../../types/LPLUserInfo';
import { LPLStreamlineConditions } from './LPLStreamlineConditions';
import { LPLWarrantyAndFraudModalContent } from './LPLWarrantyAndFraudModalContent';

export function WarrantyAndFraudStatement(lplUserInfo: LPLUserInfo, isStreamlineRenewal: boolean) {
    const warrantyAndFraudModal = useModal();
    const streamlineConditionsModal = useModal();

    return (
        <Text as="span">
            By checking this box, you{' '}
            {isStreamlineRenewal ? (
                <React.Fragment>
                    confirm that the{' '}
                    <TextButton onClick={streamlineConditionsModal.show}>
                        renewal conditions
                    </TextButton>{' '}
                    are true
                </React.Fragment>
            ) : (
                <React.Fragment>
                    certify all information provided in the insurance application is true
                </React.Fragment>
            )}{' '}
            and correct and acknowledge that you have read and agree to the{' '}
            <TextButton onClick={warrantyAndFraudModal.show}>
                Warranty and Fraud Statement
            </TextButton>
            <Modal {...warrantyAndFraudModal} size="medium">
                <LPLWarrantyAndFraudModalContent {...lplUserInfo} />
            </Modal>
            <Modal {...streamlineConditionsModal} size="medium">
                <LPLStreamlineConditions />
            </Modal>
        </Text>
    );
}
