import { container } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Nullable } from '@embroker/shotwell/core/types';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Button, ColumnLayout } from '@embroker/ui-toolkit/v2';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { ApplicationAccessStartQuote } from '../../../../shopping/entities/Application';
import { AppContext } from '../../../../view/AppContext';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { OrganizationSearch } from '../organizations/OrganizationSearch';
import { GetAQuoteWizard } from '../getAQuoteWizard/GetAQuoteWizard';

function isValidSearch(input: string) {
    return input.length > 0;
}

function createLabel(input: string) {
    return `Search for "${input}"`;
}

export function DashboardHeader() {
    const [input, setInput] = useState('');
    const { navigate } = useNavigation();
    const { setSlideout } = useContext(AppContext);
    const eventBus = useMemo(() => container.get<DomainEventBus>(DomainEventBus), []);

    const handleGetQuote = useCallback(() => {
        setSlideout(<GetAQuoteWizard />, {
            isDismissable: false,
        });
        const event: ApplicationAccessStartQuote = {
            id: UUID.create(),
            origin: 'Application',
            name: 'AccessStartQuote',
            createdAt: new Date(Date.now()),
        };
        eventBus.publish(event);
    }, [setSlideout, eventBus]);

    const handleSelectOrganization = (item: { value: Nullable<UUID>; label: string }) => {
        if (item.value != null) {
            navigate(
                URI.build('/broker/organization-info', {
                    organizationId: item.value,
                }),
            );
        } else {
            navigate(
                URI.build('/broker/companies', {
                    filter: input,
                }),
            );
        }
    };

    const handleOnSearch = (input: string) => {
        navigate(
            URI.build('/broker/companies', {
                filter: input,
            }),
        );
    };

    return (
        <header>
            <ColumnLayout gap="48">
                <OrganizationSearch
                    placeholder="Search by client name"
                    className="u-1/1"
                    onSelect={handleSelectOrganization}
                    onSearch={handleOnSearch}
                    isValidSearch={isValidSearch}
                    searchLabel={createLabel}
                    inputValue={input}
                    onInputChange={(value) => setInput(value)}
                    minimumTextLength={3}
                    count={5}
                />

                <Button appearance="primary" onClick={handleGetQuote}>
                    Get a Quote
                </Button>
            </ColumnLayout>
        </header>
    );
}
