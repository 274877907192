import {
    BoxLayout,
    CenterLayout,
    ColumnLayout,
    Image,
    StackLayout,
    Text,
} from '@embroker/ui-toolkit/v2';
import React from 'react';

export function InvitationExpired() {
    return (
        <BoxLayout gap="64">
            <BoxLayout gap="64">
                <ColumnLayout center>
                    <CenterLayout>
                        <StackLayout gap="48">
                            <CenterLayout>
                                <Image name="shotwell-1" />
                            </CenterLayout>
                            <StackLayout gap="24">
                                <CenterLayout>
                                    <Text style="heading 2">Your invitation has expired.</Text>
                                </CenterLayout>
                                <CenterLayout centerText>
                                    <Text>
                                        Please contact your Access Channel Manager to resend you the
                                        invite.
                                    </Text>
                                </CenterLayout>
                            </StackLayout>
                        </StackLayout>
                    </CenterLayout>
                </ColumnLayout>
            </BoxLayout>
        </BoxLayout>
    );
}
