import {
    BoxLayout,
    Button,
    Card,
    ColumnLayout,
    Grid,
    Icon,
    Media,
    StackLayout,
    Text,
    TextButton,
    UseResponsiveScreenQuery,
    useResponsive,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { useNavigation } from '../../../../view/hooks/useNavigation';

interface ESPRenewalThankYouPageProps {
    isDNOSelected: boolean;
    isEPLISelected: boolean;
    isFiduciarySelected: boolean;
    isEOSelected: boolean;
    isTechCyberSelected: boolean;
}

const responsiveQuery: UseResponsiveScreenQuery = { screenWidth: { smallerThan: 'tablet' } };

export function ESPRenewalThankYouPage(props: ESPRenewalThankYouPageProps) {
    const { navigate } = useNavigation();
    const isResponsive = useResponsive(responsiveQuery);

    const handleGoToPayments = () => navigate('/payments');

    const handleReviewYourPolicy = () => navigate('/policies');

    const handleCompleteAnotherApp = () => navigate('/dashboard');

    return (
        <BoxLayout gap="8">
            <Card>
                <Card.Body>
                    <Media>
                        <Media.Figure>
                            <Icon name="shotwell" size="large" />
                        </Media.Figure>
                        <Media.Body>
                            <StackLayout gap="64">
                                <Text style="heading light 1">You're covered</Text>
                                <Grid gap="medium">
                                    <Grid.Row>
                                        <Grid.Cell>
                                            <ColumnLayout split="2" responsive={responsiveQuery}>
                                                <div>
                                                    <Text>Your company is now protected by</Text>
                                                    <StackLayout gap="8">
                                                        {props.isDNOSelected && (
                                                            <Text as="p" style="label 1">
                                                                Directors and Officers
                                                            </Text>
                                                        )}
                                                        {props.isEPLISelected && (
                                                            <Text as="p" style="label 1">
                                                                Employment Practices Liability
                                                            </Text>
                                                        )}
                                                        {props.isFiduciarySelected && (
                                                            <Text as="p" style="label 1">
                                                                Fiduciary
                                                            </Text>
                                                        )}
                                                        {props.isEOSelected && (
                                                            <Text as="p" style="label 1">
                                                                Technology E&O/Cyber
                                                            </Text>
                                                        )}
                                                        {props.isTechCyberSelected && (
                                                            <Text as="p" style="label 1">
                                                                Technology E&O/Cyber
                                                            </Text>
                                                        )}
                                                    </StackLayout>
                                                </div>
                                                <div>
                                                    <StackLayout gap="4">
                                                        <Text>Other things you can do</Text>
                                                        <TextButton
                                                            onClick={handleReviewYourPolicy}
                                                        >
                                                            Review your policy
                                                        </TextButton>
                                                        <TextButton
                                                            onClick={handleCompleteAnotherApp}
                                                        >
                                                            Complete another application
                                                        </TextButton>
                                                    </StackLayout>
                                                </div>
                                            </ColumnLayout>
                                        </Grid.Cell>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Cell width={isResponsive ? '1/1' : '1/2'}>
                                            <Text>
                                                You can pay now by adding your ACH information. If
                                                you aren't ready, you can return later after you've
                                                received your invoice.
                                            </Text>
                                        </Grid.Cell>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Cell>
                                            <Button onClick={handleGoToPayments}>
                                                Go to Payments
                                            </Button>
                                        </Grid.Cell>
                                    </Grid.Row>
                                </Grid>
                            </StackLayout>
                        </Media.Body>
                    </Media>
                </Card.Body>
            </Card>
        </BoxLayout>
    );
}
