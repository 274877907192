import React, { useContext } from 'react';
import { EndorsementSlideoutContent } from './EndorsementSlideoutContent.view';
import { AppContext } from '@app/view/AppContext';

interface EndorsementSlideoutProps {
    onDismiss: () => void;
}

export const EndorsementSlideout = ({ onDismiss }: EndorsementSlideoutProps) => {
    const { closeSlideout } = useContext(AppContext);

    const handleClick = () => {
        window.open(
            'https://embroker.atlassian.net/servicedesk/customer/portal/31/create/11014',
            '_blank',
            'noopener,noreferrer',
        );
        closeSlideout();
    };

    return (
        <EndorsementSlideoutContent
            title="Modify Policy"
            onDismiss={onDismiss}
            buttonLabel="Open Request Form"
            handleCTA={handleClick}
            bodyHeader="Make changes to your policy"
            body={[
                'To modify your original policy, submit your requested changes (and when these changes should take effect) via the form linked below. Once your request is processed, we’ll update your policy and notify you.',
                <span key="with-bold-content">
                    <strong>Please note: </strong>Making changes to your policy may increase the
                    cost of your insurance.
                </span>,
            ]}
        />
    );
};
