import { defineCodec } from '@embroker/shotwell/core/encoding';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import * as enums from './enums';

export interface RedirectApplicationData {
    id: UUID;
    appTypeList: Array<enums.AppTypeCode>;
}

export type RedirectApplicationDataArray = Array<RedirectApplicationData>;

export const RedirectApplicationDataArray = {
    ...defineValidator<RedirectApplicationDataArray>(
        Joi.array()
            .required()
            .items({
                id: UUID.schema.required(),
                appTypeList: Joi.array()
                    .required()
                    .items(Joi.string().valid(...enums.AppTypeCodeList)),
            }),
    ),
    ...defineCodec<RedirectApplicationDataArray>(),
};
