import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { UseCase, UseCaseClass, execute } from '@embroker/shotwell/core/UseCase';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { cast } from '@embroker/shotwell/core/types/Nominal';
import { AsyncResult, Success, isErr } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { GetDocumentUrl } from '../../documents/useCases/GetDocumentUrl';
import { DigitalEndorsementsRepository } from '../repositories/digitalEndorsements';

export interface IssueEndorsementRequest {
    endorsementId: UUID;
    endorsementData: unknown;
    rateId?: UUID;
    prorateId?: UUID;
}

export interface IssueEndorsement extends UseCase {
    execute(request: IssueEndorsementRequest): AsyncResult<URI, InvalidArgument | OperationFailed>;
}

@injectable()
class IssueEndorsementUseCase extends UseCase implements IssueEndorsement {
    public static type = Symbol('DigitalEndorsements/IssueEndorsement');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(DigitalEndorsementsRepository)
        private digitalEndorsementsRepository: DigitalEndorsementsRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: IssueEndorsementRequest,
    ): AsyncResult<URI, InvalidArgument | OperationFailed> {
        const issueEndorsementResult = await this.digitalEndorsementsRepository.issueEndorsement({
            endorsementId: request.endorsementId,
            endorsementData: request.endorsementData,
            prorateId: request.prorateId,
            rateId: request.rateId,
        });

        if (isErr(issueEndorsementResult)) {
            return issueEndorsementResult;
        }
        const documentUrlResponse = await execute(GetDocumentUrl, {
            fileKey: issueEndorsementResult.value,
        });
        if (isErr(documentUrlResponse)) {
            return documentUrlResponse;
        }

        return Success(cast<URI>(documentUrlResponse.value.downloadUrl));
    }
}

export const IssueEndorsement: UseCaseClass<IssueEndorsement> = IssueEndorsementUseCase;
