import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success, isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { CNAQuoteRepository } from '../repositories/CNAQuoteRepository';

/**
 * Request data for SubmitCNAQuoteForReview use case
 */
export interface SubmitCNAQuoteReviewRequest {
    applicationId: UUID;
}

/**
 * SubmitCNAQuoteForReview use case is used to submit referred CNA quote to review
 */
export interface SubmitCNAQuoteForReview extends UseCase {
    execute(
        request: SubmitCNAQuoteReviewRequest,
    ): AsyncResult<void, OperationFailed | InvalidArgument>;
}

class SubmitCNAQuoteRevieweUseCase extends UseCase implements SubmitCNAQuoteForReview {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('CNAQuote/SubmitCNAQuoteForReview');

    /**
     * Constructor for SubmitCNAQuoteForReview use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(CNAQuoteRepository) private cnaQuoteRepository: CNAQuoteRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes SubmitCNAQuoteForReview use case
     * Input is of SubmitCNAQuoteReviewRequest type
     * @returns InvalidArgument if insurance application does not exist
     * @returns OperationFailed if repository could not submit the quote for review
     */
    public async execute(
        request: SubmitCNAQuoteReviewRequest,
    ): AsyncResult<void, OperationFailed | InvalidArgument> {
        const submitResponse = await this.cnaQuoteRepository.submitForReview(request.applicationId);

        if (isErr(submitResponse)) {
            return submitResponse;
        }

        return Success();
    }
}

export const SubmitCNAQuoteForReview: UseCaseClass<SubmitCNAQuoteForReview> =
    SubmitCNAQuoteRevieweUseCase;
