import { PageLayout, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { View } from 'react-navi';
import { PaymentsSubNavigation } from './Subnavigation';

export function PaymentsPage() {
    return (
        <PageLayout.Section as={StackLayout} gap="32">
            <Text data-e2e="payments-page-header" style="heading 2">
                Payments
            </Text>
            <PaymentsSubNavigation />
            <View />
        </PageLayout.Section>
    );
}
