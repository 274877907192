import { ContainerModule } from '@embroker/shotwell/core/di';
import { Module } from '../../Module';
import { ESPEndorsementRepository } from './repositories/ESPEndorsementRepository';
import { APIESPEndorsementRepository } from './repositories/ESPEndorsementRepository/APIESPEndorsementRepository';
import { AddCoverageESPEndorsement } from './useCases/AddCoverageESPEndorsement';
import { ChangeAddressESPEndorsement } from './useCases/ChangeAddressESPEndorsement';
import { ChangeNamedInsuredESPEndorsement } from './useCases/ChangeNamedInsuredESPEndorsement';
import { EditCoverageESPEndorsement } from './useCases/EditCoverageESPEndorsement';
import { GetESPEndorsementPolicy } from './useCases/GetESPEndorsementPolicy';
import { GetESPEndorsementUserData } from './useCases/GetESPEndorsementUserData';
import { RateAddCoverageESPEndorsement } from './useCases/RateAddCoverageESPEndorsement';
import { RateEditCoverageESPEndorsement } from './useCases/RateEditCoverageESPEndorsement';
import { RateEditMultipleCoverageESPEndorsement } from './useCases/RateEditMultipleCoverageEndorsement';
import { EditMultipleCoverageESPEndorsement } from './useCases/EditMultipleCoverageEndorsement';

export const ESPEndorsementModule: Module = {
    container: new ContainerModule((bind) => {
        bind<ESPEndorsementRepository>(ESPEndorsementRepository)
            .to(APIESPEndorsementRepository)
            .inSingletonScope();
        bind<GetESPEndorsementPolicy>(GetESPEndorsementPolicy.type)
            .to(GetESPEndorsementPolicy)
            .inSingletonScope();
        bind<GetESPEndorsementUserData>(GetESPEndorsementUserData.type)
            .to(GetESPEndorsementUserData)
            .inSingletonScope();
        bind<ChangeNamedInsuredESPEndorsement>(ChangeNamedInsuredESPEndorsement.type)
            .to(ChangeNamedInsuredESPEndorsement)
            .inSingletonScope();
        bind<ChangeAddressESPEndorsement>(ChangeAddressESPEndorsement.type)
            .to(ChangeAddressESPEndorsement)
            .inSingletonScope();
        bind<AddCoverageESPEndorsement>(AddCoverageESPEndorsement.type)
            .to(AddCoverageESPEndorsement)
            .inSingletonScope();
        bind<EditCoverageESPEndorsement>(EditCoverageESPEndorsement.type)
            .to(EditCoverageESPEndorsement)
            .inSingletonScope();
        bind<EditMultipleCoverageESPEndorsement>(EditMultipleCoverageESPEndorsement.type)
            .to(EditMultipleCoverageESPEndorsement)
            .inSingletonScope();
        bind<RateAddCoverageESPEndorsement>(RateAddCoverageESPEndorsement.type)
            .to(RateAddCoverageESPEndorsement)
            .inSingletonScope();
        bind<RateEditCoverageESPEndorsement>(RateEditCoverageESPEndorsement.type)
            .to(RateEditCoverageESPEndorsement)
            .inSingletonScope();
        bind<RateEditMultipleCoverageESPEndorsement>(RateEditMultipleCoverageESPEndorsement.type)
            .to(RateEditMultipleCoverageESPEndorsement)
            .inSingletonScope();
    }),
};
