import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Money } from '@embroker/shotwell/core/types/Money';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { AsyncResult, Failure, isErr } from '@embroker/shotwell/core/types/Result';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { PCoMLEndorsementRepository } from '../repositories/PCoMLEndorsementRepository';
import { PCoMLEndorsementPolicy } from '../types/PCoMLEndorsementPolicy';
import { PCoMLEndorsementLiabilityCoverageType } from '../types/PCoMLEndorsementLiabilityCoverageType';
import { State } from '@embroker/shotwell/core/types/StateList';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import { TasksRepository } from '../../../tasks/repositories';

export interface PurchasePCoMLEndorsementRequest {
    policyId: UUID;
    endorsementType: string;
    effectiveDate: Date;
    limitEndorsement: {
        coverage?: PCoMLEndorsementLiabilityCoverageType;
        limit: Money;
        retention: Money;
    };
    addressEndorsement: {
        addressLine1: string;
        addressLine2: string;
        city: string;
        state?: State;
        zipCode: ZipCode;
    };
    namedInsuredEndorsement: {
        namedInsured?: string;
    };
    abortSignal?: AbortSignal;
}

export interface PurchasePCoMLEndorsement extends UseCase {
    execute(
        request: PurchasePCoMLEndorsementRequest,
    ): AsyncResult<PCoMLEndorsementPolicy, InvalidArgument | OperationFailed>;
}

@injectable()
class PurchasePCoMLEndorsementUseCase extends UseCase implements PurchasePCoMLEndorsement {
    public static type = Symbol('PCoMLEndorsement/RatePCoMLEndorsement');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(PCoMLEndorsementRepository)
        private pcomlEndorsementRepository: PCoMLEndorsementRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: PurchasePCoMLEndorsementRequest,
    ): AsyncResult<PCoMLEndorsementPolicy, InvalidArgument | OperationFailed> {
        const result = await this.pcomlEndorsementRepository.createEndorsement(request);
        if (isErr(result)) {
            return result;
        }

        const taskStatusResult = await this.tasksRepository.pollForTaskStatus(
            result.value,
            request.abortSignal,
        );

        if (isErr(taskStatusResult)) {
            return Failure(
                OperationFailed({
                    message: `Get task status failed for task with id: ${result.value}`,
                    errors: taskStatusResult.errors,
                }),
            );
        }

        return await this.pcomlEndorsementRepository.getPolicy(request.policyId);
    }
}

export const PurchasePCoMLEndorsement: UseCaseClass<PurchasePCoMLEndorsement> =
    PurchasePCoMLEndorsementUseCase;
