import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable, Props } from '@embroker/shotwell/core/types';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { UnknownNAICSCode } from '../errors';
import { NAICSRepository } from '../repositories/NAICSRepository';
import { NAICS } from '../types/NAICS';

/**
 * Request data for GetNAICSByCode use case
 */
export interface GetNAICSByCodeRequest {
    naicsCode?: string;
}

/**
 * Response data for GetNAICSByCode use case
 */
export interface GetNAICSByCodeResponse {
    naics: Immutable<Props<NAICS>>;
}

/**
 * GetNAICSByCode use case is used to ...
 */
export interface GetNAICSByCode extends UseCase {
    execute(
        request: GetNAICSByCodeRequest,
    ): AsyncResult<GetNAICSByCodeResponse, InvalidArgument | OperationFailed | UnknownNAICSCode>;
}

@injectable()
class GetNAICSByCodeUseCase extends UseCase implements GetNAICSByCode {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/GetNAICSByCode');

    /**
     * Constructor for GetNAICSByCode use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(NAICSRepository) private naicsRepository: NAICSRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes GetNAICSByCode use case
     * Input is of GetNAICSByCodeRequest type
     * @returns data of GetNAICSByCodeResponse type if execution was successful
     */
    public async execute({
        naicsCode,
    }: GetNAICSByCodeRequest): AsyncResult<
        GetNAICSByCodeResponse,
        InvalidArgument | OperationFailed | UnknownNAICSCode
    > {
        if (!naicsCode) {
            return Success<GetNAICSByCodeResponse>({
                naics: {
                    code: '',
                    name: '',
                    matches: [],
                },
            });
        }
        const repoResult = await this.naicsRepository.getByCode(naicsCode);

        if (isErr(repoResult)) {
            return repoResult;
        }

        return Success({ naics: repoResult.value });
    }
}

export const GetNAICSByCode: UseCaseClass<GetNAICSByCode> = GetNAICSByCodeUseCase;
