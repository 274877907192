import { ContainerModule } from '@embroker/shotwell/core/di';
import { Module } from '../Module';
import { GetActiveNotifications } from './useCases/GetActiveNotifications';
import { NotificationRepository } from './repositories/NotificationRepository';
import { APINotificationRepository } from './repositories/NotificationRepository/APINotificationRepository';
import { routes } from './view/routes';
import { execute } from '@embroker/shotwell/core/UseCase';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { UpdateNotificationStatus } from './useCases/UpdateNotificationStatus';
import { NotificationsContextStore } from './NotificationsContext';
import { getEnvVar } from '../env';
import { UpdateAllUserNotificationsStatus } from './useCases/UpdateAllUserNotificationsStatus';
import { GetNotificationsStats } from './useCases/GetNotificationsStats';

/**
 * The Notifications module.
 */
export const NotificationsModule: Module = {
    routes,
    container: new ContainerModule((bind) => {
        bind<NotificationRepository>(NotificationRepository)
            .to(APINotificationRepository)
            .inSingletonScope();
        bind<GetActiveNotifications>(GetActiveNotifications.type)
            .to(GetActiveNotifications)
            .inSingletonScope();
        bind<GetNotificationsStats>(GetNotificationsStats.type)
            .to(GetNotificationsStats)
            .inSingletonScope();
        bind<UpdateNotificationStatus>(UpdateNotificationStatus.type)
            .to(UpdateNotificationStatus)
            .inSingletonScope();
        bind<UpdateAllUserNotificationsStatus>(UpdateAllUserNotificationsStatus.type)
            .to(UpdateAllUserNotificationsStatus)
            .inSingletonScope();
    }),
    bootstrap: async () => {
        if (!getEnvVar('NOTIFICATION_CENTER_FEATURE_FLAG')) {
            return undefined;
        }

        const statsResult = await execute(GetNotificationsStats);
        if (isOK(statsResult)) {
            NotificationsContextStore.update({
                unreadNotificationCount: statsResult.value.countUnread,
            });
        }

        return undefined;
    },
};
