import { errorCodes } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { ErrorObject } from '@embroker/shotwell/core/types/Result';

export const ErrorCode = errorCodes({
    ESPRenewalConfigFetchFailed: 0x6000,
    ESPRenewalBeforeLastEndorsement: 0x6003,
});

export type ESPRenewalConfigFetchFailed = ErrorObject<
    typeof ErrorCode.ESPRenewalConfigFetchFailed,
    {}
>;

export function ESPRenewalConfigFetchFailed(): Immutable<ESPRenewalConfigFetchFailed> {
    return {
        name: 'ESPRenewalConfigFetchFailed',
        code: ErrorCode.ESPRenewalConfigFetchFailed,
        message: 'Failed to fetch config API for ESP renewal.',
        details: {},
    };
}

export type ESPRenewalBeforeLastEndorsement = ErrorObject<
    typeof ErrorCode.ESPRenewalBeforeLastEndorsement
>;

export function ESPRenewalBeforeLastEndorsement(): Immutable<ESPRenewalBeforeLastEndorsement> {
    return {
        name: ESPRenewalBeforeLastEndorsement.name,
        code: ErrorCode.ESPRenewalBeforeLastEndorsement,
        message:
            'Policy cannot be renewed because there is an endorsement on the parent policy processed after the renewal application was submitted. Please contact support for assistance.',
    };
}
