import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { Coverage } from '../../types/Coverage';

export interface GetAllCoveragesRequest {
    readonly naicsCode: string;
}

export interface CoverageRepository {
    getAllCoverages(
        input: GetAllCoveragesRequest,
    ): AsyncResult<Array<Coverage>, InvalidArgument | OperationFailed>;
    getAllCoveragesForNoAuthUser(): AsyncResult<Array<Coverage>, InvalidArgument | OperationFailed>;
}

export const CoverageRepository = Symbol('CoverageRepository');
