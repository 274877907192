import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { NBRVFunnelDetails } from './APINBRVFunnelRepository';

export interface NBRVFunnelRepository {
    setNBRVFunnelDetails(funnelDetails: NBRVFunnelDetails | null): AsyncResult<void, never>;

    getNBRVFunnelDetails(): AsyncResult<NBRVFunnelDetails | null, never>;
}

export const NBRVFunnelRepository = Symbol('NBRVFunnelRepository');
