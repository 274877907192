import { Money } from '@embroker/shotwell/core/types/Money';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import {
    Nullable,
    Grid,
    StackLayout,
    Text,
    InsetBox,
    useResponsive,
} from '@embroker/ui-toolkit/v2';
import React from 'react';

interface ESPEndorsementPremiumCostBreakdownProps {
    prorate: Nullable<Money>;
    taxes: Nullable<Money>;
    fees: Nullable<Money>;
    total: Nullable<Money>;
}

export function ESPEndorsementPremiumCostBreakdown({
    prorate,
    taxes,
    fees,
    total,
}: ESPEndorsementPremiumCostBreakdownProps) {
    const isMobile = useResponsive({ screenWidth: { smallerThan: 'large-mobile' } });
    const negativeMargin = -1 * (isMobile ? 16 : 24);

    return (
        <StackLayout>
            <Grid>
                <Grid.Row>
                    <Grid.Cell width="1/2">
                        <Text style="body 2">Prorated (mid year)</Text>
                    </Grid.Cell>
                    <Grid.Cell>
                        {prorate ? (
                            <Text style="heading 5">
                                <MoneyDisplay value={prorate} />
                            </Text>
                        ) : null}
                    </Grid.Cell>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Cell width="1/2">
                        <Text style="body 2">Taxes</Text>
                    </Grid.Cell>
                    <Grid.Cell>
                        {taxes ? (
                            <Text style="heading 5">
                                <MoneyDisplay value={taxes} />
                            </Text>
                        ) : null}
                    </Grid.Cell>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Cell width="1/2">
                        <Text style="body 2">Fees</Text>
                    </Grid.Cell>
                    <Grid.Cell>
                        {fees ? (
                            <Text style="heading 5">
                                <MoneyDisplay value={fees} />
                            </Text>
                        ) : null}
                    </Grid.Cell>
                </Grid.Row>
            </Grid>
            <InsetBox gap="24" style={{ marginLeft: negativeMargin, marginRight: negativeMargin }}>
                <Grid>
                    <Grid.Row>
                        <Grid.Cell width="1/2">
                            <Text style="heading 5">Total additional premium</Text>
                        </Grid.Cell>
                        <Grid.Cell>
                            {total ? (
                                <Text style="heading 5">
                                    <MoneyDisplay value={total} />
                                </Text>
                            ) : null}
                        </Grid.Cell>
                    </Grid.Row>
                </Grid>
            </InsetBox>
        </StackLayout>
    );
}
