import { Data } from '@embroker/shotwell/core/types';
import { Money, USD } from '@embroker/shotwell/core/types/Money';
import { IconName, Nullable } from '@embroker/ui-toolkit/v2';
import {
    ESPDNOPlusURL,
    ESPDNOPlusNewInsurerURL,
    ESPDNOStandardURL,
    ESPDNOStandardNewInsurerURL,
    ESPEoCyberPlusURL,
    ESPEoCyberStandardURL,
    ESPEPLIPlusURL,
    ESPEPLIStandardURL,
    ESPEPLIStandardNewInsurerURL,
    ESPEPLIPlusNewInsurerURL,
    ESPFiduciaryStandardURL,
    ESPFiduciaryNewStandardURL,
    ESPTechEOCyberSplitPlusURL,
    ESPDNOPlusPostIPT2024URL,
    ESPDNOStandardPostIPT2024URL,
    ESPEPLIPlusPostIPT2024URL,
    ESPEPLIStandardPostIPT2024URL,
    ESPTechCyberPlusPostIPT2024URL,
    ESPTechCyberStandardPostIPT2024URL,
} from '../../espUtils/constants';
import {
    CoverageRestriction,
    ShoppingCoverageCodeListCyberSplit,
} from '../types/CoverageRestriction';
import { State } from '@embroker/shotwell/core/types/StateList';

export type CoverageType = 'dno' | 'epli' | 'eo' | 'fiduciary' | 'techCyber';

interface ESPRenewalCoverageDetails {
    readonly title: string;
    readonly icon: IconName;
    readonly text: string;
    readonly document: Data<string>;
}

export const espRenewalCoverageDetailsMap: Record<string, ESPRenewalCoverageDetails> = {
    dno: {
        title: 'Directors and Officers',
        icon: 'meeting',
        text: 'Shield your company and its board members from litigation by disgruntled shareholders, customers, investors and regulatory bodies.',
        document: {
            standard: ESPDNOStandardURL,
            new_standard: ESPDNOStandardNewInsurerURL,
            plus: ESPDNOPlusURL,
            new_plus: ESPDNOPlusNewInsurerURL,
            post_ipt_2024_standard: ESPDNOStandardPostIPT2024URL,
            post_ipt_2024_plus: ESPDNOPlusPostIPT2024URL,
        },
    },
    epli: {
        title: 'Employment Practices Liability',
        icon: 'suitcase',
        text: 'EPLI provides coverage for claims alleging discrimination, wrongful termination, harassment and other employment related issues.',
        document: {
            standard: ESPEPLIStandardURL,
            new_standard: ESPEPLIStandardNewInsurerURL,
            plus: ESPEPLIPlusURL,
            new_plus: ESPEPLIPlusNewInsurerURL,
            post_ipt_2024_standard: ESPEPLIStandardPostIPT2024URL,
            post_ipt_2024_plus: ESPEPLIPlusPostIPT2024URL,
        },
    },
    fiduciary: {
        title: 'Fiduciary Liability',
        icon: 'handshake',
        text: 'Protects against claims of errors or negligence on your company’s benefits plans under guidelines set by the Employee Retirement Income Security Act (ERISA).',
        document: {
            standard: ESPFiduciaryStandardURL,
            new_standard: ESPFiduciaryNewStandardURL,
        },
    },
    eo: {
        title: 'Technology E&O/Cyber',
        icon: 'thief',
        text: 'E&O provides coverage for a failure of a service or software and Cyber Liability provides coverage for a data breach and loss of private third-party sensitive information.',
        document: {
            standard: ESPEoCyberStandardURL,
            plus: ESPEoCyberPlusURL,
        },
    },
    techCyber: {
        title: 'Technology E&O/Cyber',
        icon: 'thief',
        text: 'Tech E&O provides coverage for a failure of technology services and 3rd party liabilities. 1st Party Expenses refers to coverage for expenses that arise from a cyber event, including ransomware, notification expenses, and system damage.',
        document: {
            standard: ESPEoCyberStandardURL,
            plus: ESPTechEOCyberSplitPlusURL,
            post_ipt_2024_standard: ESPTechCyberStandardPostIPT2024URL,
            post_ipt_2024_plus: ESPTechCyberPlusPostIPT2024URL,
        },
    },
};

export interface Option {
    readonly title: string;
    readonly value: number;
}

const limitRetentionOptionsMap: Record<string, Record<string, Option[]>> = {
    dno: {
        retentions: [
            { title: '$10,000', value: 10000 },
            { title: '$25,000', value: 25000 },
            { title: '$50,000', value: 50000 },
            { title: '$75,000', value: 75000 },
            { title: '$100,000', value: 100000 },
            { title: '$150,000', value: 150000 },
        ],
        limits: [
            { title: '$1,000,000', value: 1000000 },
            { title: '$2,000,000', value: 2000000 },
            { title: '$3,000,000', value: 3000000 },
            { title: '$4,000,000', value: 4000000 },
            { title: '$5,000,000', value: 5000000 },
        ],
    },
    fiduciary: {
        retentions: [{ title: '$0', value: 0 }],
        limits: [{ title: '$1,000,000', value: 1000000 }],
    },
    epli: {
        retentions: [
            { title: '$10,000', value: 10000 },
            { title: '$25,000', value: 25000 },
            { title: '$50,000', value: 50000 },
            { title: '$75,000', value: 75000 },
            { title: '$100,000', value: 100000 },
            { title: '$150,000', value: 150000 },
        ],
        limits: [
            { title: '$1,000,000', value: 1000000 },
            { title: '$2,000,000', value: 2000000 },
            { title: '$3,000,000', value: 3000000 },
            { title: '$4,000,000', value: 4000000 },
            { title: '$5,000,000', value: 5000000 },
        ],
    },
    eo: {
        retentions: [
            { title: '$2,500', value: 2500 },
            { title: '$5,000', value: 5000 },
            { title: '$10,000', value: 10000 },
            { title: '$25,000', value: 25000 },
            { title: '$50,000', value: 50000 },
            { title: '$75,000', value: 75000 },
            { title: '$100,000', value: 100000 },
        ],
        limits: [
            { title: '$1,000,000', value: 1000000 },
            { title: '$2,000,000', value: 2000000 },
            { title: '$3,000,000', value: 3000000 },
            { title: '$4,000,000', value: 4000000 },
            { title: '$5,000,000', value: 5000000 },
        ],
    },
    techCyber: {
        retentions: [
            { title: '$2,500', value: 2500 },
            { title: '$5,000', value: 5000 },
            { title: '$10,000', value: 10000 },
            { title: '$25,000', value: 25000 },
            { title: '$50,000', value: 50000 },
            { title: '$75,000', value: 75000 },
            { title: '$100,000', value: 100000 },
        ],
        limits: [
            { title: '$1,000,000', value: 1000000 },
            { title: '$2,000,000', value: 2000000 },
            { title: '$3,000,000', value: 3000000 },
            { title: '$4,000,000', value: 4000000 },
            { title: '$5,000,000', value: 5000000 },
        ],
    },
    eo5M: {
        retentions: [
            { title: '$2,500', value: 2500 },
            { title: '$5,000', value: 5000 },
            { title: '$10,000', value: 10000 },
            { title: '$25,000', value: 25000 },
        ],
        limits: [
            { title: '$1,000,000', value: 1000000 },
            { title: '$2,000,000', value: 2000000 },
            { title: '$3,000,000', value: 3000000 },
            { title: '$4,000,000', value: 4000000 },
            { title: '$5,000,000', value: 5000000 },
        ],
    },
    eo10M: {
        retentions: [
            { title: '$2,500', value: 2500 },
            { title: '$5,000', value: 5000 },
            { title: '$10,000', value: 10000 },
            { title: '$25,000', value: 25000 },
            { title: '$50,000', value: 50000 },
        ],
        limits: [
            { title: '$1,000,000', value: 1000000 },
            { title: '$2,000,000', value: 2000000 },
            { title: '$3,000,000', value: 3000000 },
            { title: '$4,000,000', value: 4000000 },
            { title: '$5,000,000', value: 5000000 },
        ],
    },
};

const revenueMax = Object.freeze({
    val5M: USD(5000 * 1000), // $5M
    val10M: USD(10000 * 1000), // $10M
});

export const getCyberLimitRetentionOptions = (revenueInfo?: Money) => {
    let limitRetentionOptions = limitRetentionOptionsMap['techCyber'];

    if (revenueInfo) {
        if (revenueInfo < revenueMax.val5M) {
            limitRetentionOptions = limitRetentionOptionsMap['eo5M'];
        }
        if (revenueInfo < revenueMax.val10M) {
            limitRetentionOptions = limitRetentionOptionsMap['eo10M'];
        }
    }
    limitRetentionOptions = {
        ...limitRetentionOptions,
        limits: [{ title: '$0', value: 0 }, ...limitRetentionOptions.limits],
    };
    return limitRetentionOptions;
};

export const getLimitRetentionOptions = (
    type: CoverageType,
    previousLimit: Money,
    state?: Nullable<State>,
    revenueInfo?: Money,
    coverageRestriction?: CoverageRestriction,
    higherLimitRequested?: boolean,
) => {
    let limits;
    const limitMax = calculateMaxLimit(type, state);
    let limitRetentionOptions = limitRetentionOptionsMap[type];

    if ((type === 'eo' || type === 'techCyber') && revenueInfo) {
        if (revenueInfo < revenueMax.val5M) {
            limitRetentionOptions = limitRetentionOptionsMap['eo5M'];
        }
        if (revenueInfo < revenueMax.val10M) {
            limitRetentionOptions = limitRetentionOptionsMap['eo10M'];
        }
    }

    if (type === 'techCyber') {
        limitRetentionOptions = getTechCyberLimitRetentionOptions(
            limitRetentionOptions,
            previousLimit,
            coverageRestriction,
            higherLimitRequested,
        );
    }

    if (coverageRestriction && Money.isGreaterThan(coverageRestriction.maxLimit, previousLimit)) {
        previousLimit = coverageRestriction.maxLimit;
    }

    if (Money.isGreaterThan(previousLimit, Money.tryFromFloat(limitMax))) {
        limits = limitRetentionOptions.limits.filter((limit) =>
            Money.isLessThanOrEqual(Money.tryFromFloat(limit.value), previousLimit),
        );
    } else {
        limits = limitRetentionOptions.limits.filter((limit) =>
            Money.isLessThanOrEqual(Money.tryFromFloat(limit.value), Money.tryFromFloat(limitMax)),
        );
    }

    const retentions = limitRetentionOptions.retentions;

    return {
        limits,
        retentions,
    };
};

export const minimumPremiumAmount = USD(3500 * 100);

export function getRestrictedLimitRetentionOptions(
    coverageType: CoverageType,
    previousLimit: Money,
    state?: Nullable<State>,
    coverageRestriction?: CoverageRestriction,
    revenueInfo?: Money,
    higherLimitRequested?: boolean,
): Record<string, Option[]> {
    const limitRetentionOptions = getLimitRetentionOptions(
        coverageType,
        previousLimit,
        state,
        revenueInfo,
        coverageRestriction,
        higherLimitRequested,
    );

    if (!coverageRestriction) {
        return limitRetentionOptions;
    }

    const limits = limitRetentionOptions.limits.filter((limit) =>
        Money.isLessThanOrEqual(Money.tryFromFloat(limit.value), coverageRestriction.maxLimit),
    );

    const retentions = limitRetentionOptions.retentions.filter((retention) =>
        Money.isGreaterThanOrEqual(
            Money.tryFromFloat(retention.value),
            coverageRestriction.minRetention,
        ),
    );

    return {
        limits,
        retentions,
    };
}

function getTechCyberLimitRetentionOptions(
    options: Record<string, Option[]>,
    previousLimit: Money,
    coverageRestriction?: CoverageRestriction,
    higherLimitRequested?: boolean,
) {
    if (!coverageRestriction || !options || !options.limits || !options.retentions) {
        return options;
    }

    if (coverageRestriction.coverageType === ShoppingCoverageCodeListCyberSplit) {
        if (coverageRestriction.allowCoverage) {
            options = {
                ...options,
                limits: [{ title: '$0', value: 0 }, ...options.limits],
            };
        } else {
            options = {
                ...options,
                limits: [{ title: '$0', value: 0 }],
            };
        }
    }

    return {
        limits: options.limits.filter(
            (limit) =>
                Money.isGreaterThanOrEqual(
                    coverageRestriction.maxLimit,
                    Money.tryFromFloat(limit.value),
                ) ||
                (Money.isGreaterThanOrEqual(previousLimit, coverageRestriction.maxLimit) &&
                    !higherLimitRequested),
        ),
        retentions: options.retentions.filter((retention) =>
            Money.isLessThanOrEqual(
                coverageRestriction.minRetention,
                Money.tryFromFloat(retention.value),
            ),
        ),
    };
}

function calculateMaxLimit(coverageType: CoverageType, state?: Nullable<State>): number {
    const isCA = state === 'CA';
    return coverageType === 'epli' ? (isCA ? 2000 * 1000 : 3000 * 1000) : 5000 * 1000;
}
