import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { InvestorsRepository } from '../repositories/InvestorsRepository';

export interface GetInvestorsPayload {
    searchPattern: string;
}

export interface GetInvestors extends UseCase {
    execute({
        searchPattern,
    }: GetInvestorsPayload): AsyncResult<string[], InvalidArgument | OperationFailed>;
}

@injectable()
class GetInvestorsUseCase extends UseCase implements GetInvestors {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Shopping/GetInvestors');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(InvestorsRepository) private investorsRepository: InvestorsRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        searchPattern,
    }: GetInvestorsPayload): AsyncResult<string[], InvalidArgument | OperationFailed> {
        const getInvestorsResponse = await this.investorsRepository.getInvestors(searchPattern);

        if (isErr(getInvestorsResponse)) {
            return getInvestorsResponse;
        }

        return Success(getInvestorsResponse.value as string[]);
    }
}

export const GetInvestors: UseCaseClass<GetInvestors> = GetInvestorsUseCase;
