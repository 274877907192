import React from 'react';

import { Immutable } from '@embroker/shotwell/core/types';
import { Lob } from '../../../types/Lob';
import { StackLayout, Text } from '@embroker/ui-toolkit/v2';
import { CoverageSectionTable } from './CoverageSectionTable';
import { UnderlyingAgreements } from './UnderlyingAgreements';
import { LineOfBusinessCodeListItem } from '@embroker/shotwell-api/enums';
import { UmbrellaExcessLiabilitySection } from '../../../types/UmbrellaExcessLiabilitySection';
import { CoverageSectionLimitAndRetention } from './CoverageSectionLimitAndRetention';

export interface UmbrellaExcessCoverageSectionBodyProps {
    lob: Immutable<Lob>;
    lineOfBusiness: LineOfBusinessCodeListItem;
}

export const UmbrellaExcessCoverageSectionBody = ({
    lob,
    lineOfBusiness,
}: UmbrellaExcessCoverageSectionBodyProps) => {
    if (lob === null) {
        return null;
    }
    return (
        <StackLayout gap="16">
            <CoverageSectionLimitAndRetention lob={lob} />
            <StackLayout gap="none">
                <Text style="heading 5">What's covered</Text>
                <CoverageSectionTable lob={lob} />
            </StackLayout>
            <UnderlyingAgreements
                lineOfBusiness={lineOfBusiness}
                underlyingAgreementsList={
                    lob.lobType === 'umbrellaExcessLiabilityLob'
                        ? (lob.liabilitySection as UmbrellaExcessLiabilitySection)
                              .underlyingAgreementList
                        : null
                }
            />
        </StackLayout>
    );
};
