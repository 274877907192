import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { BrokerRepository } from '../repositories';

export interface GetLookerReportUrl extends UseCase {
    execute(resource: LookerReportType): AsyncResult<string, InvalidArgument | OperationFailed>;
}

export type LookerReportType =
    | 'total_digital_premium'
    | 'gross_written_premium'
    | 'new_digital_customers'
    | 'number_of_quotes';

class GetLookerReportUrlUseCase extends UseCase implements GetLookerReportUrl {
    public static type = Symbol('Broker/GetLookerReportUrl');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BrokerRepository) private brokerRepository: BrokerRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        resource: LookerReportType,
    ): AsyncResult<string, InvalidArgument | OperationFailed> {
        return await this.brokerRepository.getLookerReportUrl({
            resource,
        });
    }
}

export const GetLookerReportUrl: UseCaseClass<GetLookerReportUrl> = GetLookerReportUrlUseCase;
