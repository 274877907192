import { Button, Card, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { useNavigation } from '../../../view/hooks/useNavigation';

export function EmptyInsuranceApplicationList() {
    const navigation = useNavigation();
    function goToStartShopping() {
        navigation.navigate('/shopping');
    }

    return (
        <Card center className="u-grid-size-12">
            <Text style="body 1">You haven't selected any coverages</Text>
            <Button onClick={goToStartShopping}>Start Shopping</Button>
        </Card>
    );
}
