import { ContainerModule } from '@embroker/shotwell/core/di';
import { Module } from '../Module';
import { SupplementalQuestionnaireRepository } from './esp/repositories/SupplementalQuestionnaireRepository';
import { APISupplementalQuestionnaireRepository } from './esp/repositories/SupplementalQuestionnaireRepository/SupplementalQuestionnaireRepository';
import { routes } from './routes';
import { SaveSupplementalQuestionnaire } from './useCases/SaveSupplementalQuestionnaire';
import { GetSupplementalQuestionnaireFormEngine } from './useCases/GetSupplementalQuestionnaireFormEngine';
import { SubmitSupplementalQuestionnaire } from './useCases/SubmitSupplementalQuestionnaire';

export const HigherLimitsSupplementalQuestionnaireModule: Module = {
    container: new ContainerModule((bind) => {
        bind<SupplementalQuestionnaireRepository>(SupplementalQuestionnaireRepository)
            .to(APISupplementalQuestionnaireRepository)
            .inSingletonScope();
        bind<GetSupplementalQuestionnaireFormEngine>(GetSupplementalQuestionnaireFormEngine.type)
            .to(GetSupplementalQuestionnaireFormEngine)
            .inSingletonScope();
        bind<SaveSupplementalQuestionnaire>(SaveSupplementalQuestionnaire.type)
            .to(SaveSupplementalQuestionnaire)
            .inSingletonScope();
        bind<SubmitSupplementalQuestionnaire>(SubmitSupplementalQuestionnaire.type)
            .to(SubmitSupplementalQuestionnaire)
            .inSingletonScope();
    }),
    routes,
};
