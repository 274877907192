import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Nullable } from '@embroker/shotwell/core/types';
import { AsyncResult, isErr, isOK, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass, execute } from '@embroker/shotwell/core/UseCase';
import { OrganizationRepository } from '../repositories/OrganizationRepository';
import { GetNAICSByCode } from '../../industries/useCases/GetNAICSByCode';
import { GetActiveSession } from './GetActiveSession';

export interface GetDnbNaicsCodeResponse {
    naicsCode: Nullable<string>;
}
/**
 * GetDnbNaicsCode use case is used to get the Naics code for a given organization
 */

export interface GetDnbNaicsCode extends UseCase {
    execute(): AsyncResult<GetDnbNaicsCodeResponse, never>;
}

@injectable()
export class GetDnbNaicsCodeUseCase extends UseCase implements GetDnbNaicsCode {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/GetDnbNaicsCode');
    /**
     * Constructor for GetDnbNaicsCode use case class instance
     * @param eventBus An event bus this Use Case will publish events to.
     * @param organizationRepo is organization repository used to save organization entity with updated properties
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(OrganizationRepository) private organizationRepo: OrganizationRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes GetDnbNaicsCode use case
     * Input is of type GetDnbNaicsCodeRequest
     * @returns Successful with a valid NAICs code if a valid NAICs code was found.
     * @returns Successful with a null if a valid NAICs code was not found,
     */
    public async execute(): AsyncResult<GetDnbNaicsCodeResponse, never> {
        const getActiveSessionResult = await execute(GetActiveSession);

        if (isErr(getActiveSessionResult)) {
            return Success({ naicsCode: null });
        }

        const activeSessionResult = getActiveSessionResult.value;
        const organizationId = activeSessionResult.session.organizationId;

        if (organizationId === null) {
            return Success({ naicsCode: null });
        }

        const naicsCodeResult = await this.organizationRepo.getDnBNAICsCode(organizationId);

        if (isErr(naicsCodeResult)) {
            return Success({ naicsCode: null });
        }

        const naicsCode = naicsCodeResult.value;

        const result = await execute(GetNAICSByCode, { naicsCode });

        if (isOK(result)) {
            return Success({ naicsCode: result.value.naics.code });
        }

        return Success({ naicsCode: null });
    }
}

export const GetDnbNaicsCode: UseCaseClass<GetDnbNaicsCode> = GetDnbNaicsCodeUseCase;
