import { container } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { cast } from '@embroker/shotwell/core/types/Nominal';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { useModal } from '@embroker/ui-toolkit/v2';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CalculateSKU } from '../../../../analytics/useCases/CalculateSKU';
import { AppTypeLabelMap } from '../../../../shopping/types/enums';
import { hasRole } from '../../../../userOrg/entities/Session';
import { AppContext } from '../../../../view/AppContext';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { useWizardForm, WizardPages } from '../../../../view/hooks/useWizardForm';
import { BrokerSignaturePage } from '../../../brokerage/view/components/BrokerSignaturePage';
import { QuoteBindCoverage } from '../../../entities/Quote';
import { DocumentType } from '../../../types/Document';
import { DocGenErrorModal } from '../../../view/components/DocGenErrorModal';
import { DocumentModal } from '../../../view/components/DocumentModal';
import { DownloadAppButton } from '../../../view/components/DownloadAppButton';
import { DownloadDocumentButton } from '../../../view/components/DownloadDocumentButton';
import { DownloadSignaturePacketButton } from '../../../view/components/DownloadSignaturePacketButton';
import { EditApplicationButton } from '../../../view/components/EditApplicationButton';
import {
    MenuItem,
    Page,
    PageNavigationComponent,
    QuoteLandingPage,
} from '../../../view/components/QuoteLandingPage';
import { QuoteOnDemandNavigation } from '../../../view/components/QuoteOnDemandNavigation';
import { ReferredQuoteOnDemandNavigation } from '../../../view/components/ReferredQuoteOnDemandNavigation';
import { CrimeQuote } from '../../entities/CrimeQuote';
import {
    createCrimeQuoteOptionsForm,
    CrimeQuoteOptionsFormData,
} from './createCrimeQuoteOptionsForm';
import { CrimeCoverage } from './CrimeCoverage';
import { CrimeQuoteBreakdown } from './CrimeQuoteBreakdown';
import { CrimeQuoteSummary } from './CrimeQuoteSummary';
import { CrimeQuoteSignature } from './signature/CrimeQuoteSignature';

export interface CrimeQuoteLandingPageProps {
    applicationId: UUID;
    initialCrimeQuote: CrimeQuote;
    getToday: () => Date;
    isTestMode: boolean;
}

interface CrimeQuoteLandingPageState {
    quote: CrimeQuote;
    quoteFileUrl?: URI;
    specimenPolicyFileUrl?: URI;
    lastGeneratedDocumentType?: DocumentType;
}

export function CrimeQuoteLandingPage({
    applicationId,
    initialCrimeQuote,
    getToday,
    isTestMode,
}: CrimeQuoteLandingPageProps) {
    const [state, setState] = useState<CrimeQuoteLandingPageState>({
        quote: initialCrimeQuote,
        quoteFileUrl: undefined,
        specimenPolicyFileUrl: undefined,
        lastGeneratedDocumentType: undefined,
    });
    const eventBus = useMemo(() => container.get<DomainEventBus>(DomainEventBus), []);
    const { activeSession } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');
    const isAdmin = hasRole(activeSession, 'admin');
    const isRenewal = initialCrimeQuote.isRenewal;
    const documentModal = useModal();
    const docGenErrorModal = useModal();

    const abortController = useMemo(() => {
        return new AbortController();
    }, []);

    const handleUpdateQuote = useCallback((newCrimeQuote: CrimeQuote) => {
        setState((prevState: CrimeQuoteLandingPageState) => ({
            ...prevState,
            quote: newCrimeQuote,
            quoteFileUrl: undefined,
            specimenPolicyFileUrl: undefined,
            lastGeneratedDocumentType: undefined,
        }));
    }, []);

    const handleSetQuoteSummaryUrl = useCallback((url: URI) => {
        setState((prevState: CrimeQuoteLandingPageState) => ({
            ...prevState,
            quoteFileUrl: url,
            lastGeneratedDocumentType: DocumentType.QuoteSummary,
        }));
    }, []);

    const handleSetSpecimenPolicyUrl = useCallback((url: URI) => {
        setState((prevState: CrimeQuoteLandingPageState) => ({
            ...prevState,
            specimenPolicyFileUrl: url,
            lastGeneratedDocumentType: DocumentType.SpecimenPolicy,
        }));
    }, []);

    const handleBindCoverageEvent = useCallback(() => {
        execute(CalculateSKU, {
            event: 'quote',
            applicationId: applicationId,
        }).then((skuResult) => {
            const event: QuoteBindCoverage = {
                origin: 'Quote',
                name: 'BindCoverage',
                totalPremium: state.quote.totalPayable,
                applicationId,
                createdAt: new Date(Date.now()),
                id: UUID.create(),
                sku: isOK(skuResult) ? skuResult.value : undefined,
                isRenewal: state.quote.isRenewal,
            };
            eventBus.publish(event);
        });
    }, [applicationId, eventBus, state.quote]);

    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, [abortController]);

    const { navigate } = useNavigation();
    const crimeQuoteOptionsForm = useMemo(
        () =>
            createCrimeQuoteOptionsForm({
                quote: state.quote,
                abortSignal: abortController.signal,
                isBroker,
                isAdmin,
                isRenewal,
                onUpdateQuote: handleUpdateQuote,
                setQuoteSummaryUrl: handleSetQuoteSummaryUrl,
                setSpecimenPolicyUrl: handleSetSpecimenPolicyUrl,
                navigate,
                getToday,
                onGenerateDocument: documentModal.show,
                onDocGenError: docGenErrorModal.show,
            }),
        [
            state.quote,
            navigate,
            isBroker,
            isAdmin,
            isRenewal,
            abortController.signal,
            handleUpdateQuote,
            handleSetQuoteSummaryUrl,
            handleSetSpecimenPolicyUrl,
            getToday,
            documentModal.show,
            docGenErrorModal.show,
        ],
    );
    const initialValue: CrimeQuoteOptionsFormData = {
        limit: state.quote?.options.limit ?? 1000000,
        retention: state.quote?.options.retention ?? 10000,
        level: state.quote?.options.enhanced,
        effectiveDate: state.quote?.options.effectiveDate,
        agreementToConductSignature: state.quote.status == 'signed',
        warrantyAndFraudSignature: state.quote.status == 'signed',
        brokerSignature: false,
    };

    const quotePage = {
        name: 'coverage',
        fields: ['effectiveDate', 'limit', 'retention', 'level'],
    };
    const signPage = {
        name: 'signature',
        fields: isBroker
            ? ['brokerSignature']
            : ['agreementToConductSignature', 'warrantyAndFraudSignature'],
    };

    // To enable renewals testing
    const daysToExpiration = isTestMode ? undefined : state?.quote?.daysToExpire;
    const isQuoteExpired = daysToExpiration === -1 && !isAdmin;
    const isQuoteBindable = !isQuoteExpired;
    const formPages = isQuoteBindable ? [quotePage, signPage] : [quotePage];
    const optionsWizardForm = useWizardForm(crimeQuoteOptionsForm, {
        pages: formPages as WizardPages<OpaqueForm<CrimeQuoteOptionsFormData>>,
        initialValue: initialValue,
    });

    const quoteStepPage: Page<CrimeQuoteOptionsFormData, CrimeQuote> = {
        name: 'coverage',
        component: CrimeCoverage,
    };
    const quoteSignPage: Page<CrimeQuoteOptionsFormData, CrimeQuote> = {
        name: 'signature',
        component: isBroker ? BrokerSignaturePage : CrimeQuoteSignature,
    };
    const pages: Page<CrimeQuoteOptionsFormData, CrimeQuote>[] = isQuoteBindable
        ? [quoteStepPage, quoteSignPage]
        : [quoteStepPage];

    const navigation: PageNavigationComponent<CrimeQuoteOptionsFormData, CrimeQuote> =
        QuoteOnDemandNavigation;
    const referredNavigation: PageNavigationComponent<CrimeQuoteOptionsFormData, CrimeQuote> =
        ReferredQuoteOnDemandNavigation;

    const DownloadAppItem: MenuItem<CrimeQuoteOptionsFormData> = {
        component: DownloadAppButton,
        isDisabled: state.quote?.status === 'referred' || optionsWizardForm.status === 'dirty',
    };

    const DownloadQuoteItem: MenuItem<CrimeQuoteOptionsFormData> = {
        component: DownloadDocumentButton,
        isDisabled: state.quote?.status === 'referred' || optionsWizardForm.status === 'dirty',
        fileUrl: state.quoteFileUrl,
        documentType: DocumentType.QuoteSummary,
        onGenerateFileClick: () => optionsWizardForm.trigger('downloadQuote'),
    };

    const DownloadSignaturePacket: MenuItem<CrimeQuoteOptionsFormData> = {
        component: DownloadSignaturePacketButton,
        isDisabled: state.quote?.status === 'referred' || optionsWizardForm.status === 'dirty',
    };

    const DownloadSpecimenPolicyItem: MenuItem<CrimeQuoteOptionsFormData> = {
        component: DownloadDocumentButton,
        isDisabled: state.quote?.status === 'referred' || optionsWizardForm.status === 'dirty',
        fileUrl: state.specimenPolicyFileUrl,
        documentType: DocumentType.SpecimenPolicy,
        onGenerateFileClick: () => optionsWizardForm.trigger('downloadSpecimenPolicy'),
    };

    const EditApplicationItem: MenuItem<CrimeQuoteOptionsFormData> = {
        component: EditApplicationButton,
    };

    const menuButtons = isBroker
        ? [
              DownloadSignaturePacket,
              DownloadQuoteItem,
              DownloadSpecimenPolicyItem,
              DownloadAppItem,
              EditApplicationItem,
          ]
        : [DownloadQuoteItem, DownloadSpecimenPolicyItem, DownloadAppItem];

    return (
        <React.Fragment>
            <DocGenErrorModal modal={docGenErrorModal} />
            <DocumentModal
                modal={documentModal}
                fileUrl={
                    state.lastGeneratedDocumentType
                        ? state.lastGeneratedDocumentType === DocumentType.QuoteSummary
                            ? state.quoteFileUrl
                            : state.specimenPolicyFileUrl
                        : undefined
                }
                documentType={state.lastGeneratedDocumentType}
            />
            <QuoteLandingPage
                title={AppTypeLabelMap['AppTypeCodeListEmbrokerCrime']}
                icon="cage"
                applicationId={applicationId}
                quote={state.quote}
                optionsWizardForm={optionsWizardForm}
                pages={pages}
                quoteBreakdown={CrimeQuoteBreakdown}
                quoteSummary={CrimeQuoteSummary}
                menuItems={menuButtons}
                pageNavigation={isQuoteBindable ? navigation : referredNavigation}
                exitUrl={cast<URI>(isBroker ? '/broker/dashboard' : '/summary')}
                daysToExpire={daysToExpiration}
                handleBindCoverage={handleBindCoverageEvent}
            />
        </React.Fragment>
    );
}
