import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success, isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { DigitalEndorsementsRepository } from '../repositories/digitalEndorsements';

export interface PrecreateEndorsementRequest {
    policyId: UUID;
    origin: string;
    endorsementType: string;
}

export interface PrecreateEndorsement extends UseCase {
    execute(
        request: PrecreateEndorsementRequest,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed>;
}

@injectable()
class PrecreateEndorsementUseCase extends UseCase implements PrecreateEndorsement {
    public static type = Symbol('DigitalEndorsements/PrecreateEndorsement');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(DigitalEndorsementsRepository)
        private digitalEndorsementsRepository: DigitalEndorsementsRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: PrecreateEndorsementRequest,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed> {
        const precreateEndorsementResult =
            await this.digitalEndorsementsRepository.precreateEndorsement({
                policyId: request.policyId,
                origin: request.origin,
                endorsementType: request.endorsementType,
            });

        if (isErr(precreateEndorsementResult)) {
            return precreateEndorsementResult;
        }

        return Success(precreateEndorsementResult.value);
    }
}

export const PrecreateEndorsement: UseCaseClass<PrecreateEndorsement> = PrecreateEndorsementUseCase;
