import { DigitalEndorsementStatusCodeListItem } from '@embroker/shotwell-api/enums';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { Money } from '@embroker/shotwell/core/types/Money';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { CoverageRestriction } from '../../types/CoverageRestriction';
import { LimitAndRetention } from '../../types';

export interface EndorsementDefinition {
    type: string;
    name?: string;
    schema?: string;
    isPremiumBearing: boolean;
    currentOptions?: LimitAndRetention;
    restriction?: CoverageRestriction;
}

export interface EndorsementRecord {
    id: UUID;
    serialNumber: number;
    policyId: UUID;
    endorsementType: string;
    origin: string;
    isPremiumBearing: boolean;
    data: unknown;
    createdAt: Date;
    status: DigitalEndorsementStatusCodeListItem;
    documentFileKey?: string;
}

export interface PrecreateRequest {
    policyId: UUID;
    endorsementType: string;
    origin: string;
}

export interface ProrateRequest {
    endorsementId: UUID;
    endorsementData: unknown;
}

export interface ProrateResponse {
    rateId: UUID;
    prorateId: UUID;
    currentPremium: Money;
    returnPremium: Money;
    premiumChange: Money;
    taxes: Money;
    fees: Money;
    totalPremiumChange: Money;
}

export interface IssueEndorsementRequest {
    endorsementId: UUID;
    endorsementData: unknown;
    rateId?: UUID;
    prorateId?: UUID;
}

export interface PreviewRequest {
    endorsementId: UUID;
    endorsementData: unknown;
    prorateId?: UUID;
}

export interface ListRequest {
    policyId: UUID;
}

export type AvailableEndorsementsSpecs = EndorsementSpec[];
export interface EndorsementSpec {
    displayName?: string;
    tags: string[];
    inputSchema?: string;
    docType: string;
}

export interface DigitalEndorsementsRepository {
    precreateEndorsement(
        request: PrecreateRequest,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed>;

    getEndorsementQuote(
        request: ProrateRequest,
    ): AsyncResult<ProrateResponse, InvalidArgument | OperationFailed>;

    previewEndorsement(
        request: PreviewRequest,
    ): AsyncResult<string, InvalidArgument | OperationFailed>;

    issueEndorsement(
        request: IssueEndorsementRequest,
    ): AsyncResult<string, InvalidArgument | OperationFailed>;

    listEndorsements(
        request: ListRequest,
    ): AsyncResult<EndorsementRecord[], InvalidArgument | OperationFailed>;

    getAvailableEndorsements(
        manifestId: UUID,
    ): AsyncResult<AvailableEndorsementsSpecs, InvalidArgument | OperationFailed>;
}

export const DigitalEndorsementsRepository = Symbol('DigitalEndorsementsRepository');
