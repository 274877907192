import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';

type PaymentType = 'Credit Card' | 'Bank' | 'Financing' | 'Electronically' | 'PaperCheck';

const paymentTypeToMessageMap = {
    'Credit Card': 'Pay with a credit card clicked',
    Bank: 'Pay via your bank clicked',
    Financing: 'Pay monthly clicked',
    Electronically: 'Pay Electronically clicked',
    PaperCheck: 'Pay via paper check clicked',
};

export interface PaymentCTAClickEvent extends DomainEvent {
    name: 'PaymentCTAClickEvent';
    message: string;
}

export interface PublishPaymentCTAClickEvent extends UseCase {
    execute(request: { type: PaymentType }): AsyncResult<void>;
}

@injectable()
class PublishPaymentCTAClickEventtUseCase extends UseCase implements PublishPaymentCTAClickEvent {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Payments/PublishPaymentCTAClickEventUseCase');

    /**
     * Constructor for PublishPaymentCTAClickEventUseCase class instance
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    /**
     * Executes the PublishPaymentCTAClickEventUseCase use case.
     */
    public async execute({ type }: { type: PaymentType }): AsyncResult<void> {
        const paymentCTAClickEvent: PaymentCTAClickEvent = {
            id: UUID.create(),
            createdAt: new Date(Date.now()),
            origin: 'Payments',
            name: 'PaymentCTAClickEvent',
            message: paymentTypeToMessageMap[type],
        };

        await this.eventBus.publish(paymentCTAClickEvent);

        return Success();
    }
}

export const PublishPaymentCTAClickEvent: UseCaseClass<PublishPaymentCTAClickEvent> =
    PublishPaymentCTAClickEventtUseCase;
