import { FormElement } from '@embroker/service-app-engine';
import {
    CenterLayout,
    ColumnLayout,
    SidebarLayout,
    StackLayout,
    StickyLayout,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React, { useCallback, useMemo } from 'react';
import { Navigation } from './components/Navigation';
import { PlainFormEngineProps } from '../PlainFormEngine';

interface WithNavigationProps {
    WrappedComponent: React.FC<PlainFormEngineProps>;
    instance: FormElement;
    hideNavigation: boolean;
}

export const WithNavigation = function WithNavigation({
    WrappedComponent,
    instance,
    hideNavigation,
    ...props
}: WithNavigationProps) {
    const ConnectedNavigation = useMemo(() => Navigation.connect(instance.machine), [instance]);
    const closeButtonRef = React.useRef<HTMLDivElement>(null);

    const goToPage = useCallback(
        (pageId) => {
            instance.gotoPage(pageId);
            if (closeButtonRef.current) {
                closeButtonRef.current.click();
            }
        },
        [instance, closeButtonRef],
    );

    return function WithNavigation(props: PlainFormEngineProps) {
        return (
            <SidebarLayout appearance="compact">
                {ConnectedNavigation !== null && (
                    <React.Fragment>
                        <StickyLayout gap="64" className={hideNavigation ? 'u-hidden' : undefined}>
                            <StackLayout gap="64">
                                <ConnectedNavigation goToPage={goToPage} />
                            </StackLayout>
                        </StickyLayout>
                        <SidebarLayout.MobileFooter>
                            <ColumnLayout split="-1">
                                <SidebarLayout.Link
                                    panelIndex={2}
                                    as={TextButton}
                                    ref={closeButtonRef}
                                >
                                    Close Menu
                                </SidebarLayout.Link>
                            </ColumnLayout>
                        </SidebarLayout.MobileFooter>
                    </React.Fragment>
                )}
                <CenterLayout>
                    <WrappedComponent {...props} />
                    <SidebarLayout.MobileFooter>
                        <ColumnLayout>
                            <SidebarLayout.Link panelIndex={1} as={TextButton}>
                                Open Menu
                            </SidebarLayout.Link>
                        </ColumnLayout>
                    </SidebarLayout.MobileFooter>
                </CenterLayout>
            </SidebarLayout>
        );
    };
};
