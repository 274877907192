import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { CrimeQuote } from '../entities/CrimeQuote';
import { CrimeQuoteRepository } from '../repositories/CrimeQuoteRepository';

export interface GetLastCrimeQuoteRequest {
    applicationId: UUID;
}

export interface GetLastCrimeQuote extends UseCase {
    execute(
        request: GetLastCrimeQuoteRequest,
    ): AsyncResult<CrimeQuote, InvalidArgument | OperationFailed>;
}

@injectable()
class GetLastCrimeQuoteUseCase extends UseCase implements GetLastCrimeQuote {
    public static type = Symbol('CrimeQuote/GetLastCrimeQuote');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(CrimeQuoteRepository) private crimeQuoteRepository: CrimeQuoteRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
    }: GetLastCrimeQuoteRequest): AsyncResult<CrimeQuote, InvalidArgument | OperationFailed> {
        return await this.crimeQuoteRepository.getLastCrimeQuote(applicationId);
    }
}

export const GetLastCrimeQuote: UseCaseClass<GetLastCrimeQuote> = GetLastCrimeQuoteUseCase;
