import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { AppTypeCode } from '../types/enums';

const ShoppingUserClickEventNameList = [
    'I need guidance CTA clicked',
    'Shopping Broker Banner Clicked',
    'Ineligibility Shop all Coverages CTA clicked',
    'Full Ineligibility Shop all Coverages clicked',
    'Shop All Coverage Get a Quote clicked',
    'Shop All Coverage Get a Quote slideout clicked',
    'Starter Package Continue CTA clicked',
    'Starter Package Customized CTA clicked',
    'Enhaced Package Continue CTA clicked',
    'Enhaced Package Customized CTA clicked',
    'Deluxe Package Continue CTA clicked',
    'Deluxe Package Customized CTA clicked',
    'LPL Deselected Pop up Displayed',
    'LPL Deselected Pop up _ get a quote clicked',
    'LPL Deselected Pop up closed',
    'LPL Deselected Pop up _ I have this coverage clicked',
    'Quote Comparison Page Slideout Cancel CTA clicked',
    'Quote Comparison Page Slideout Exit CTA clicked',
    'Quote Comparison Page Slideout Proceed with HL CTA clicked',
    'Quote Comparison page loaded',
    'Package Save and Exit clicked',
    'Modal Save and Exit CTA clicked',
    'Product recommendation EPL CA Pop up Displayed',
    'Product recommendation EPL CA Pop up _ add more coverage clicked',
    'Product recommendation EPL CA Pop up closed',
    'Quote page EPL CA Pop up Displayed',
    'Quote page EPL CA Pop up _ add more coverage clicked',
    'Quote page EPL CA Pop up _ get EPLI quote only clicked',
    'Quote page EPL CA Pop up closed',
] as const;
type ShoppingUserClickEventNameList = typeof ShoppingUserClickEventNameList;
export type ShoppingUserClickEventName = ShoppingUserClickEventNameList[number];

export interface ShoppingUserClickEvent extends DomainEvent {
    name: 'ShoppingUserClickEvent';
    clickEventName: ShoppingUserClickEventName;
    clickEventPayload?: {
        appType?: AppTypeCode;
    };
}

interface ArgsProps {
    clickEventName: ShoppingUserClickEventName;
    clickEventPayload?: {
        appType?: AppTypeCode;
    };
}

export interface PublishShoppingUserClickEvent extends UseCase {
    execute(request: ArgsProps): AsyncResult<void>;
}

@injectable()
class PublishShoppingUserClickEventUseCase
    extends UseCase
    implements PublishShoppingUserClickEvent
{
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('shopping/PublishShoppingUserClickEventUseCase');

    /**
     * Constructor for PublishShoppingUserClickEvent class instance
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    /**
     * Executes the PublishShoppingUserClickEvent use case.
     */
    public async execute({ clickEventName, clickEventPayload }: ArgsProps): AsyncResult<void> {
        const event = {
            id: UUID.create(),
            createdAt: new Date(Date.now()),
            origin: 'Shopping',
            name: `ShoppingUserClickEvent`,
            clickEventName: clickEventName,
            clickEventPayload,
        };

        await this.eventBus.publish(event);

        return Success();
    }
}

export const PublishShoppingUserClickEvent: UseCaseClass<PublishShoppingUserClickEvent> =
    PublishShoppingUserClickEventUseCase;
