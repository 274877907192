import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, NotAllowed, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { BrokerageNotFoundError } from '../errors';
import { BrokerRepository } from '../repositories';

export interface RequestLossRunsInput {
    policyNumberList: string[];
    sendToSelf?: boolean;
    recipientList?: EmailAddress[];
}

export interface RequestLossRuns extends UseCase {
    execute(
        request: RequestLossRunsInput,
    ): AsyncResult<void, InvalidArgument | OperationFailed | NotAllowed | BrokerageNotFoundError>;
}

class RequestLossRunsUseCase extends UseCase implements RequestLossRuns {
    public static type = Symbol('Broker/RequestLossRuns');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BrokerRepository) private brokerRepository: BrokerRepository,
    ) {
        super(eventBus);
    }
    public async execute({
        policyNumberList,
        sendToSelf,
        recipientList,
    }: RequestLossRunsInput): AsyncResult<
        void,
        InvalidArgument | OperationFailed | NotAllowed | BrokerageNotFoundError
    > {
        return await this.brokerRepository.requestLossRuns({
            policyNumberList,
            sendToCurrentBroker: sendToSelf,
            recipientList,
        });
    }
}

export const RequestLossRuns: UseCaseClass<RequestLossRuns> = RequestLossRunsUseCase;
