import { errorCodes } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { ErrorObject } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { BundleQuoteCoverageType } from './types/BundleQuoteCoverage';

export const ErrorCode = errorCodes({
    EnqueueReQuoteBundleTaskError: 0x3100,
    ApplicationNotFound: 0x3101,
    OperationNotAllowed: 0x3102,
    QuoteOptionsNotAllowed: 0x3103,
    InvalidAnnualTechFee: 0x3104,
    QuoteIdMissing: 0x3105,
    QuoteNotFound: 0x3106,
    RenewalBeforeLastEndorsementDateNotAllowed: 0x3107,
    PurchaseBundleQuoteError: 0x3108,
    PurchaseBundleQuoteCoverageError: 0x3109,
    DocumentCreationBundleTaskError: 0x310a,
});

export type EnqueueReQuoteBundleTaskError = ErrorObject<
    typeof ErrorCode.EnqueueReQuoteBundleTaskError,
    {}
>;

export type DocumentCreationBundleTaskError = ErrorObject<
    typeof ErrorCode.DocumentCreationBundleTaskError,
    {}
>;

export type ApplicationNotFound = ErrorObject<typeof ErrorCode.ApplicationNotFound, {}>;

export type OperationNotAllowed = ErrorObject<typeof ErrorCode.OperationNotAllowed, {}>;

export type QuoteOptionsNotAllowed = ErrorObject<typeof ErrorCode.QuoteOptionsNotAllowed, {}>;

export type InvalidAnnualTechFee = ErrorObject<typeof ErrorCode.InvalidAnnualTechFee, {}>;

export type QuoteIdMissing = ErrorObject<typeof ErrorCode.QuoteIdMissing, {}>;

export type QuoteNotFound = ErrorObject<typeof ErrorCode.QuoteNotFound, {}>;

export type RenewalBeforeLastEndorsementDateNotAllowed = ErrorObject<
    typeof ErrorCode.RenewalBeforeLastEndorsementDateNotAllowed,
    {}
>;

export type PurchaseBundleQuoteError = ErrorObject<typeof ErrorCode.PurchaseBundleQuoteError, {}>;

export type PurchaseBundleQuoteCoverageError = ErrorObject<
    typeof ErrorCode.PurchaseBundleQuoteCoverageError,
    {}
>;

export function EnqueueReQuoteBundleTaskError(
    error: Error,
): Immutable<EnqueueReQuoteBundleTaskError> {
    return {
        name: 'EnqueueReQuoteBundleTaskError',
        code: ErrorCode.EnqueueReQuoteBundleTaskError,
        message: error.message,
        details: {
            name: error.name,
            stack: error.stack,
        },
    };
}

export function DocumentCreationBundleTaskError(
    error: Error,
): Immutable<DocumentCreationBundleTaskError> {
    return {
        name: 'DocumentCreationBundleTaskError',
        code: ErrorCode.DocumentCreationBundleTaskError,
        message: error.message,
        details: {
            name: error.name,
            stack: error.stack,
        },
    };
}

export function ApplicationNotFound(
    applicationId: UUID,
    error: Error,
): Immutable<ApplicationNotFound> {
    return {
        name: 'ApplicationNotFound',
        code: ErrorCode.ApplicationNotFound,
        message: `"Application with id ${applicationId} not found"`,
        details: {
            name: error.name,
            stack: error.stack,
        },
    };
}

export function OperationNotAllowed(error: Error): Immutable<OperationNotAllowed> {
    return {
        name: 'OperationNotAllowed',
        code: ErrorCode.OperationNotAllowed,
        message: error.message,
        details: {
            name: error.name,
            stack: error.stack,
        },
    };
}

export function QuoteOptionsNotAllowed(error: Error): Immutable<QuoteOptionsNotAllowed> {
    return {
        name: 'QuoteOptionsNotAllowed',
        code: ErrorCode.QuoteOptionsNotAllowed,
        message: error.message,
        details: {
            name: error.name,
            stack: error.stack,
        },
    };
}

export function InvalidAnnualTechFee(error: Error): Immutable<InvalidAnnualTechFee> {
    return {
        name: 'InvalidAnnualTechFee',
        code: ErrorCode.InvalidAnnualTechFee,
        message: error.message,
        details: {
            name: error.name,
            stack: error.stack,
        },
    };
}

export function QuoteIdMissing(error: Error): Immutable<QuoteIdMissing> {
    return {
        name: 'QuoteIdMissing',
        code: ErrorCode.QuoteIdMissing,
        message: error.message,
        details: {
            name: error.name,
            stack: error.stack,
        },
    };
}

export function QuoteNotFound(error: Error): Immutable<QuoteNotFound> {
    return {
        name: 'QuoteNotFound',
        code: ErrorCode.QuoteNotFound,
        message: error.message,
        details: {
            name: error.name,
            stack: error.stack,
        },
    };
}

export function RenewalBeforeLastEndorsementDateNotAllowed(
    error: Error,
): Immutable<RenewalBeforeLastEndorsementDateNotAllowed> {
    return {
        name: 'RenewalBeforeLastEndorsementDateNotAllowed',
        code: ErrorCode.RenewalBeforeLastEndorsementDateNotAllowed,
        message: error.message,
        details: {
            name: error.name,
            stack: error.stack,
        },
    };
}

export function PurchaseBundleQuoteError(error: Error): Immutable<PurchaseBundleQuoteError> {
    return {
        name: 'PurchaseBundleQuoteError',
        code: ErrorCode.PurchaseBundleQuoteError,
        message: error.message,
        details: {
            name: error.name,
            stack: error.stack,
        },
    };
}

export function PurchaseBundleQuoteCoverageError(
    message: string,
    coverages?: Immutable<BundleQuoteCoverageType[]>,
): Immutable<PurchaseBundleQuoteCoverageError> {
    return {
        name: 'PurchaseBundleQuoteCoverageError',
        code: ErrorCode.PurchaseBundleQuoteCoverageError,
        message: message,
        details: {
            coverages: coverages,
        },
    };
}
