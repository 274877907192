import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ApplicationRepository } from '../repositories/ApplicationRepository';
import { APIApplicationRepository } from '../repositories/ApplicationRepository/APIApplicationRepository';

export interface GetApplicationLastPageRequest {
    userId: UUID;
    applicationId: UUID;
}

export interface GetApplicationLastPageResponse {
    lastPage: string;
}

export interface GetApplicationLastPage extends UseCase {
    execute(
        request: GetApplicationLastPageRequest,
    ): AsyncResult<GetApplicationLastPageResponse, InvalidArgument | OperationFailed>;
}

class GetApplicationLastPageUseCase extends UseCase implements GetApplicationLastPage {
    public static type = Symbol('Shopping/GetApplicationLastPage');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: APIApplicationRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        userId,
        applicationId,
    }: GetApplicationLastPageRequest): AsyncResult<
        GetApplicationLastPageResponse,
        InvalidArgument | OperationFailed
    > {
        const getLastPageResponse = await this.applicationRepository.getApplicationLastPage({
            userId,
            applicationId,
        });
        if (isErr(getLastPageResponse)) {
            return getLastPageResponse;
        }

        return Success({
            lastPage: getLastPageResponse.value.last_page,
        });
    }
}

export const GetApplicationLastPage: UseCaseClass<GetApplicationLastPage> =
    GetApplicationLastPageUseCase;
