import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { PCoMLEndorsementRepository } from '../repositories/PCoMLEndorsementRepository';
import { PCoMLEndorsementPolicy } from '../types/PCoMLEndorsementPolicy';

export interface GetPCoMLEndorsementPolicyRequest {
    policyId: UUID;
}

export interface GetPCoMLEndorsementPolicy extends UseCase {
    execute(
        request: GetPCoMLEndorsementPolicyRequest,
    ): AsyncResult<PCoMLEndorsementPolicy, InvalidArgument | OperationFailed>;
}

@injectable()
class GetPCoMLEndorsementPolicyUseCase extends UseCase implements GetPCoMLEndorsementPolicy {
    public static type = Symbol('PCoMLEndorsement/GetPCoMLEndorsementPolicy');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(PCoMLEndorsementRepository)
        private pcomlEndorsementRepository: PCoMLEndorsementRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        policyId,
    }: GetPCoMLEndorsementPolicyRequest): AsyncResult<
        PCoMLEndorsementPolicy,
        InvalidArgument | OperationFailed
    > {
        return await this.pcomlEndorsementRepository.getPolicy(policyId);
    }
}

export const GetPCoMLEndorsementPolicy: UseCaseClass<GetPCoMLEndorsementPolicy> =
    GetPCoMLEndorsementPolicyUseCase;
