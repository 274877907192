import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { BOPHartfordQuote } from '@app/quote/hartford-bop/entities/BOPHartfordQuote';
import { BOPHartfordQuoteRepository } from '@app/quote/hartford-bop/repositories/BOPHartfordQuoteRepository';

export interface GetLatestQuoteRequest {
    applicationId: UUID;
}

export interface GetLatestQuoteResponse {
    quote: BOPHartfordQuote;
}

export interface GetLatestBOPHartfordQuote extends UseCase {
    execute(
        request: GetLatestQuoteRequest,
    ): AsyncResult<GetLatestQuoteResponse, InvalidArgument | OperationFailed>;
}

@injectable()
class GetLatestBOPHartfordQuoteQuoteUseCase extends UseCase implements GetLatestBOPHartfordQuote {
    public static type = Symbol('HartfordBop/GetLatestBOPHartfordQuote');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BOPHartfordQuoteRepository) private repository: BOPHartfordQuoteRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
    }: GetLatestQuoteRequest): AsyncResult<
        GetLatestQuoteResponse,
        InvalidArgument | OperationFailed
    > {
        const result = await this.repository.getLatestQuote(applicationId);

        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        const { value: quote } = result;

        return Success<GetLatestQuoteResponse>({
            quote: quote as BOPHartfordQuote,
        });
    }
}

export const GetLatestBOPHartfordQuote: UseCaseClass<GetLatestBOPHartfordQuote> =
    GetLatestBOPHartfordQuoteQuoteUseCase;
