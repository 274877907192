import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Dot, DotColor, Immutable, Text } from '@embroker/ui-toolkit/v2';
import { differenceInDays, startOfToday } from 'date-fns';
import React from 'react';
import { SupplementalInProgress } from '../../../../shopping/types/enums';
import { ActivityItemRecord, BrokerActivityItem } from '../../../types/BrokerActivityItem';
import { ProductType } from '../../../types/ProductType';
import {
    ActivityTableColumn,
    GetBrokerActivity,
    GetBrokerActivityRequest,
} from '../../../useCases/GetBrokerActivity';
import { getTablePageDataParams, getTablePageDataResponse } from '../../hooks/useTableDataSource';
import { CompanyNameLink } from '../policies/BrokerPoliciesTableData';
import { BrokerActivityActionButton } from './BrokerActivityActionButton';

export interface BrokerActivityTableRow extends Record<string, unknown> {
    readonly id: UUID;
    readonly message: React.ReactNode;
    readonly action: React.ReactNode;
}

function buildGetBrokerActivityRequest({
    itemsPerPage,
    pageIndex,
    column,
    order,
    filters,
}: getTablePageDataParams<ActivityTableColumn>): GetBrokerActivityRequest {
    return {
        column,
        order: order ? (order === 'ASC' ? 'ascending' : 'descending') : undefined,
        index: pageIndex,
        size: itemsPerPage,
        filters,
    };
}

export async function getActivityTablePageData(
    params: getTablePageDataParams<ActivityTableColumn>,
): Promise<getTablePageDataResponse<BrokerActivityTableRow, { partialResults?: boolean }>> {
    const request = buildGetBrokerActivityRequest(params);
    const result = await execute(GetBrokerActivity, request);

    if (isErr(result)) {
        container.get<Logger>(Log).error(result);
        return {
            items: [],
            totalItems: 0,
            additionalData: {
                partialResults: true,
            },
        };
    }

    return {
        items: toActivityTableRows(result.value.activityList),
        totalItems: result.value.pageInfo.totalItems,
        additionalData: {
            partialResults: result.value.partialResults,
        },
    };
}

function toActivityTableRows(
    ActivityTableData: Immutable<BrokerActivityItem[]>,
): BrokerActivityTableRow[] {
    return ActivityTableData.map((activity) => {
        return {
            id: activity.applicationId as UUID,
            message: (
                <ToDoMessage
                    record={activity.record}
                    name={activity.organizationName}
                    productType={activity.productType}
                    expiryDate={activity.expiryDate}
                    organizationId={activity.organizationId as UUID}
                />
            ),
            action: (
                <BrokerActivityActionButton
                    record={activity.record}
                    productType={activity.productType}
                    organizationId={activity.organizationId as UUID}
                    applicationId={activity.applicationId as UUID}
                    hasQuotes={activity.hasQuotes}
                    isSubmittedExternally={activity.isSubmittedExternally}
                    activityAppStatus={activity.appStatus}
                    hasSupplementalQuestionnaireInProgress={
                        activity.applicationStatus === SupplementalInProgress
                    }
                    isStreamline={activity.isStreamline}
                />
            ),
        };
    });
}
interface ExpirationMessageProps {
    daysLeft: number;
    companyLink: JSX.Element;
    productName: string;
}
function QuoteExpirationMessage({ daysLeft, companyLink, productName }: ExpirationMessageProps) {
    const remainingTime = `${daysLeft} day${daysLeft === 1 ? '' : 's'}`;
    const remainingTimeMessage = daysLeft === 0 ? ' today' : ` in ${remainingTime}`;
    return (
        <React.Fragment>
            <b>Quote</b> for {companyLink} for {productName} will expire
            {remainingTimeMessage}
        </React.Fragment>
    );
}

function RenewalExpirationMessage({ daysLeft, companyLink, productName }: ExpirationMessageProps) {
    const remainingTime = `${daysLeft} day${daysLeft === 1 ? '' : 's'}`;
    const remainingTimeMessage = daysLeft === 0 ? ' today' : ` in ${remainingTime}`;
    return (
        <React.Fragment>
            <b>Renewal</b> for {companyLink} for {productName} is coming up
            {remainingTimeMessage}
        </React.Fragment>
    );
}

function ToDoMessage({
    record,
    name,
    expiryDate,
    productType,
    organizationId,
}: {
    record: ActivityItemRecord;
    name: string;
    productType: ProductType;
    organizationId: UUID;
    expiryDate?: Date;
}) {
    const companyLink = <CompanyNameLink name={name} id={organizationId} />;
    const productName = buildProductName(productType);
    const daysLeft = expiryDate ? differenceInDays(expiryDate, startOfToday()) : 0;
    const dotColor = getDotColor(daysLeft);

    switch (record) {
        case 'application':
            return (
                <React.Fragment>
                    <Dot data-e2e="application-dot-status" />
                    <Text style="body 2" data-e2e="application-todo-message">
                        <b>Application</b> for {companyLink} for {productName} is incomplete
                    </Text>
                </React.Fragment>
            );
        case 'quote':
            return (
                <React.Fragment>
                    <Dot color={dotColor} data-e2e="quote-dot-status" />
                    <Text style="body 2" data-e2e="quote-todo-message">
                        {QuoteExpirationMessage({ daysLeft, companyLink, productName })}
                    </Text>
                </React.Fragment>
            );
        case 'renewal':
            return (
                <React.Fragment>
                    <Dot color={dotColor} data-e2e="renewal-dot-status" />
                    <Text style="body 2" data-e2e="renewal-todo-message">
                        {RenewalExpirationMessage({ daysLeft, companyLink, productName })}
                    </Text>
                </React.Fragment>
            );
        case 'payment':
            return (
                <React.Fragment>
                    <Dot data-e2e="payment-dot-status" />
                    <Text style="body 2" data-e2e="payment-todo-message">
                        <b>Payment</b> for {companyLink} for {productName} is due
                    </Text>
                </React.Fragment>
            );
    }
}

function buildProductName(productType: ProductType): string {
    if (productType === 'ESP' || productType === 'LPL' || productType === 'Excess') {
        return `an ${productType} policy`;
    }
    return `a ${productType} policy`;
}

function getDotColor(daysRemaining: number): DotColor {
    if (daysRemaining < 15) {
        return 'negative-500';
    } else if (daysRemaining > 30) {
        return 'positive-500';
    }
    return 'warning-500';
}
