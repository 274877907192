import { Money } from '@embroker/shotwell/core/types/Money';
import { SelectOptionItem } from '@embroker/ui-toolkit/v2';
import { CyberLimit, CyberRetention } from '../../types/CyberQuoteOptions';

export function getCyberLimitOptions(): SelectOptionItem<CyberLimit>[] {
    return [
        { title: '$500,000', value: 500000 },
        { title: '$1,000,000', value: 1000000 },
        { title: '$2,000,000', value: 2000000 },
    ];
}

export function getCyberRetentionOptions(): SelectOptionItem<CyberRetention>[] {
    return [
        { title: '$2,500', value: 2500 },
        { title: '$5,000', value: 5000 },
        { title: '$7,500', value: 7500 },
        { title: '$10,000', value: 10000 },
        { title: '$25,000', value: 25000 },
        { title: '$50,000', value: 50000 },
        { title: '$75,000', value: 75000 },
    ];
}

const limitMax = 2000 * 1000;

export const getLimitRetentionOptions = (previousLimit?: CyberLimit) => {
    const limitOptions = getCyberLimitOptions();

    const limits =
        previousLimit &&
        Money.isGreaterThan(Money.tryFromFloat(previousLimit), Money.tryFromFloat(limitMax))
            ? limitOptions.filter((limit) =>
                  Money.isLessThanOrEqual(
                      Money.tryFromFloat(limit.value),
                      Money.tryFromFloat(previousLimit),
                  ),
              )
            : limitOptions.filter((limit) =>
                  Money.isLessThanOrEqual(
                      Money.tryFromFloat(limit.value),
                      Money.tryFromFloat(limitMax),
                  ),
              );
    const retentions = getCyberRetentionOptions();

    return {
        limits,
        retentions,
    };
};
