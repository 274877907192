import { QuestionnaireData } from './types/BundleQuoteQuestionnaireData';

export function getRevenueFromQuestionnaire(questionnaireData: QuestionnaireData): number {
    if (
        questionnaireData.gross_revenue_total !== null &&
        questionnaireData.gross_revenue_total !== undefined
    ) {
        questionnaireData.gross_revenue_total;
    }

    if (questionnaireData.revenue_list === null || questionnaireData.revenue_list === undefined) {
        return 0;
    }

    const DECEMBER = 11;
    const currentTime = new Date(Date.now());
    const currentYear = currentTime.getFullYear();
    const currentMonth = currentTime.getMonth();
    const referenceYear = (currentMonth === DECEMBER ? currentYear : currentYear - 1).toString();

    const revenueListItem = questionnaireData.revenue_list.find(
        (item) => item.fiscal_year === referenceYear,
    );

    return revenueListItem?.gross_revenue_total ?? 0;
}
