import { Button, Card, Text, BoxLayout } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { Link } from '../../../view/components/Link/Link';

export const TransferToEmbroker = () => {
    return (
        <BoxLayout>
            <Card center>
                <Text data-e2e="claims-please-transfer-management-text" style="body 1">
                    If you'd like us to manage claims, please transfer management of your active
                    policies to Embroker.
                </Text>
                <Button data-e2e="claims-transfer-to-embroker-button" as={Link} href="/policies">
                    Transfer to Embroker
                </Button>
            </Card>
        </BoxLayout>
    );
};
