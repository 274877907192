import { Nullable } from '@embroker/shotwell/core/types';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Spinner, useModal } from '@embroker/ui-toolkit/v2';
import React, { useContext, useEffect, useState } from 'react';
import { getFormPagesInvalidIdList } from '../../../brokerDashboard/view/components/shareableApplications/clientApplicationEdit/EditApplication';
import { ExitConfirmationModal } from '../../../shopping/view/components/ExitConfirmationModal';
import { MissingInformationModal } from '../../../shopping/view/components/MissingInformationModal';
import { hasRole } from '../../../userOrg/entities/Session';
import { AppContext } from '../../../view/AppContext';
import { FormEngine } from '../../../view/components';
import { useFormEngineEvent } from '../../../view/components/FormEngine/hooks/useFormEngineEvent';
import { navigateToErrorPage } from '../../../view/errors';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { GetSupplementalQuestionnaireFormEngine } from '../../useCases/GetSupplementalQuestionnaireFormEngine';
import { SaveSupplementalQuestionnaire } from '../../useCases/SaveSupplementalQuestionnaire';
import { SubmitSupplementalQuestionnaire } from '../../useCases/SubmitSupplementalQuestionnaire';

interface SupplementalQuestionnairePageProps {
    applicationId: UUID;
}

export function SupplementalQuestionnairePage({
    applicationId,
}: SupplementalQuestionnairePageProps) {
    const { navigate } = useNavigation();
    const [formEngine, setFormEngine] = useState<any>(null);
    const [firstInvalidPageId, setFirstInvalidPageId] = useState<Nullable<string>>(null);
    const { activeSession } = useContext(AppContext);
    const exitConfirmationModal = useModal();
    const missingInformationModal = useModal();
    const isBroker = hasRole(activeSession, 'broker');
    const { result: getSuppQuestionnaireFormEngineResult } = useUseCase(
        GetSupplementalQuestionnaireFormEngine,
        {
            applicationId,
            isBroker,
        },
    );

    useEffect(() => {
        if (!getSuppQuestionnaireFormEngineResult) {
            return;
        }
        if (isOK(getSuppQuestionnaireFormEngineResult)) {
            setFormEngine(getSuppQuestionnaireFormEngineResult.value.formEngine);
        } else {
            navigateToErrorPage(navigate, getSuppQuestionnaireFormEngineResult.errors);
        }
    }, [getSuppQuestionnaireFormEngineResult, navigate]);

    useEffect(() => {
        setFormEngine(null);
    }, [applicationId]);

    const navigateToDashboard = () => {
        if (isBroker) {
            navigate(URI.build('/broker/dashboard'));
            return;
        }
        navigate(URI.build('/summary'));
    };

    const onConfirmExitApplication = async () => {
        const supplementalQuestionnaireData = formEngine.getSnapshot().value;
        const saveSupplementalQuestionnaireResult = await execute(SaveSupplementalQuestionnaire, {
            applicationId: applicationId,
            questionnaireData: JSON.stringify(supplementalQuestionnaireData),
        });

        if (isErr(saveSupplementalQuestionnaireResult)) {
            navigateToErrorPage(navigate, saveSupplementalQuestionnaireResult.errors);
            return;
        }
        navigateToDashboard();
    };

    const navigateAfterModalConfirmation = () => {
        if (!firstInvalidPageId) {
            return;
        }
        formEngine.gotoPage(firstInvalidPageId);
    };

    useFormEngineEvent(formEngine, 'onbeforesubmit', () => {
        const formPagesInvalidIdList = getFormPagesInvalidIdList(formEngine);
        if (formPagesInvalidIdList.length === 0) {
            return;
        }
        const invalidPageId = formPagesInvalidIdList[0];
        setFirstInvalidPageId(invalidPageId);
        missingInformationModal.show();
    });

    useFormEngineEvent(formEngine, 'submit', () => {
        const navigateToThankYouPage = () => {
            navigate(
                URI.build('/shopping/application/supplemental-questionnaire/esp/thank-you', {
                    applicationId: applicationId,
                }),
            );
        };

        async function submitHandler() {
            const supplementalQuestionnaireData = formEngine.getSnapshot().value;
            const submitSupplementalQuestionnaireResult = await execute(
                SubmitSupplementalQuestionnaire,
                {
                    applicationId: applicationId,
                    questionnaireData: JSON.stringify(supplementalQuestionnaireData),
                },
            );

            if (isErr(submitSupplementalQuestionnaireResult)) {
                throw submitSupplementalQuestionnaireResult.errors;
            }

            navigateToThankYouPage();
        }

        submitHandler().catch((error) => {
            navigateToErrorPage(navigate, error);
        });
    });

    if (!formEngine) {
        return <Spinner />;
    }

    return (
        <React.Fragment>
            <ExitConfirmationModal
                modal={exitConfirmationModal}
                onExitApplication={onConfirmExitApplication}
            />
            <MissingInformationModal
                modal={missingInformationModal}
                navigateAfterModalConformation={navigateAfterModalConfirmation}
            />
            <FormEngine
                navigation={false}
                instance={formEngine}
                onExitFullScreen={exitConfirmationModal.show}
                page={'esp_dno_coverage'}
            />
        </React.Fragment>
    );
}
