import { Data, Immutable, Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { State } from '@embroker/shotwell/core/types/StateList';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { Card, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { WizardForm } from '../../../../../view/hooks/useWizardForm';
import { CoverageType } from '../../../../esp/types/CoverageRestriction';
import { Coverage } from '../../../types/Coverage';
import { InsuranceApplicationRestriction } from '../../../types/InsuranceApplicationRestriction';
import { ESPRenewalQuoteOptionsFormData } from '../ESPRenewalQuoteLandingPage';
import {
    getCoverageRestriction,
    isCoverageRestricted,
    QuestionnaireData,
} from './ESPRenewalCoveragePage';
import { ESPRenewalSelectedCoverage } from './ESPRenewalSelectedCoverage';

interface ESPRenewalSelectedCoverageListProps {
    coverageDictionary: Data<Coverage>;
    isSubmitting: boolean;
    revenue: Money;
    fields: WizardForm<OpaqueForm<ESPRenewalQuoteOptionsFormData>>['fields'];
    answers?: QuestionnaireData;
    restriction?: Immutable<InsuranceApplicationRestriction>;
    newInsurerDocumentsReleaseDate?: Date;
    fiduciaryDocumentsReleaseDate?: Date;
    submittedAt?: Date;
    state?: Nullable<State>;
    isQuoteReferred: boolean;
    higherLimitRequests?: Immutable<Record<string, number>>;
    effectiveDate?: Date;
    showEpliStandaloneInCalifornia?: () => void;
}

export const mapToCoverageRestrictionCoverageType = (coverageType: string): CoverageType => {
    switch (coverageType) {
        case 'dno':
            return 'ShoppingCoverageCodeListDirectorsAndOfficers';
        case 'epli':
            return 'ShoppingCoverageCodeListEmploymentPractices';
        case 'fiduciary':
            return 'ShoppingCoverageCodeListFiduciary';
        case 'techCyber':
            return 'ShoppingCoverageCodeListTechSplit';
        default:
            return 'ShoppingCoverageCodeListCyber';
    }
};

export const ESPRenewalSelectedCoverageList = ({
    coverageDictionary,
    revenue,
    isSubmitting,
    fields,
    answers,
    restriction,
    newInsurerDocumentsReleaseDate,
    fiduciaryDocumentsReleaseDate,
    submittedAt,
    state,
    isQuoteReferred,
    higherLimitRequests,
    effectiveDate,
    showEpliStandaloneInCalifornia,
}: ESPRenewalSelectedCoverageListProps) => {
    return (
        <StackLayout>
            <Text style="heading 4">Selected Coverages</Text>
            {((coverageDictionary['dno'] && fields.isDnoSelected.props.value) ||
                (coverageDictionary['epli'] && fields.isEplSelected.props.value) ||
                (coverageDictionary['fiduciary'] && fields.isFiduciarySelected.props.value) ||
                (coverageDictionary['eo'] && fields.isEOSelected.props.value) ||
                (coverageDictionary['techCyber'] && fields.isTechSelected.props.value)) ===
            false ? (
                <Card center>Add the coverages you're interested in to receive a quote</Card>
            ) : null}
            {coverageDictionary['dno'] &&
                fields.isDnoSelected.props.value &&
                !isCoverageRestricted(coverageDictionary['dno'].type, restriction) && (
                    <ESPRenewalSelectedCoverage
                        key={coverageDictionary['dno'].type}
                        coverage={coverageDictionary['dno']}
                        revenue={revenue}
                        isSubmitting={isSubmitting}
                        fields={fields}
                        coverageRestriction={
                            restriction
                                ? getCoverageRestriction(
                                      coverageDictionary['dno'].type,
                                      restriction,
                                  )
                                : undefined
                        }
                        newInsurerDocumentsReleaseDate={newInsurerDocumentsReleaseDate}
                        fiduciaryDocumentsReleaseDate={fiduciaryDocumentsReleaseDate}
                        submittedAt={submittedAt}
                        state={state}
                        isQuoteReferred={isQuoteReferred}
                        restrictions={restriction}
                        higherLimitRequest={
                            higherLimitRequests !== undefined
                                ? higherLimitRequests[
                                      mapToCoverageRestrictionCoverageType(
                                          coverageDictionary['dno'].type,
                                      )
                                  ]
                                : undefined
                        }
                        effectiveDate={effectiveDate}
                        showEpliStandaloneInCalifornia={showEpliStandaloneInCalifornia}
                    />
                )}
            {coverageDictionary['epli'] &&
                fields.isEplSelected.props.value &&
                !isCoverageRestricted(coverageDictionary['epli'].type, restriction) && (
                    <ESPRenewalSelectedCoverage
                        key={coverageDictionary['epli'].type}
                        coverage={coverageDictionary['epli']}
                        revenue={revenue}
                        isSubmitting={isSubmitting}
                        fields={fields}
                        answers={answers}
                        coverageRestriction={
                            restriction
                                ? getCoverageRestriction(
                                      coverageDictionary['epli'].type,
                                      restriction,
                                  )
                                : undefined
                        }
                        newInsurerDocumentsReleaseDate={newInsurerDocumentsReleaseDate}
                        fiduciaryDocumentsReleaseDate={fiduciaryDocumentsReleaseDate}
                        submittedAt={submittedAt}
                        state={state}
                        isQuoteReferred={isQuoteReferred}
                        restrictions={restriction}
                        higherLimitRequest={
                            higherLimitRequests !== undefined
                                ? higherLimitRequests[
                                      mapToCoverageRestrictionCoverageType(
                                          coverageDictionary['epli'].type,
                                      )
                                  ]
                                : undefined
                        }
                        effectiveDate={effectiveDate}
                        showEpliStandaloneInCalifornia={showEpliStandaloneInCalifornia}
                    />
                )}
            {coverageDictionary['fiduciary'] &&
                fields.isFiduciarySelected.props.value &&
                !isCoverageRestricted(coverageDictionary['fiduciary'].type, restriction) && (
                    <ESPRenewalSelectedCoverage
                        key={coverageDictionary['fiduciary'].type}
                        coverage={coverageDictionary['fiduciary']}
                        revenue={revenue}
                        isSubmitting={isSubmitting}
                        fields={fields}
                        coverageRestriction={
                            restriction
                                ? getCoverageRestriction(
                                      coverageDictionary['fiduciary'].type,
                                      restriction,
                                  )
                                : undefined
                        }
                        newInsurerDocumentsReleaseDate={newInsurerDocumentsReleaseDate}
                        fiduciaryDocumentsReleaseDate={fiduciaryDocumentsReleaseDate}
                        submittedAt={submittedAt}
                        state={state}
                        isQuoteReferred={isQuoteReferred}
                        restrictions={restriction}
                        higherLimitRequest={
                            higherLimitRequests !== undefined
                                ? higherLimitRequests[
                                      mapToCoverageRestrictionCoverageType(
                                          coverageDictionary['fiduciary'].type,
                                      )
                                  ]
                                : undefined
                        }
                        effectiveDate={effectiveDate}
                        showEpliStandaloneInCalifornia={showEpliStandaloneInCalifornia}
                    />
                )}
            {coverageDictionary['eo'] &&
                fields.isEOSelected.props.value &&
                !isCoverageRestricted(coverageDictionary['eo'].type, restriction) && (
                    <ESPRenewalSelectedCoverage
                        key={coverageDictionary['eo'].type}
                        coverage={coverageDictionary['eo']}
                        revenue={revenue}
                        isSubmitting={isSubmitting}
                        fields={fields}
                        coverageRestriction={
                            restriction
                                ? getCoverageRestriction(coverageDictionary['eo'].type, restriction)
                                : undefined
                        }
                        newInsurerDocumentsReleaseDate={newInsurerDocumentsReleaseDate}
                        fiduciaryDocumentsReleaseDate={fiduciaryDocumentsReleaseDate}
                        submittedAt={submittedAt}
                        state={state}
                        isQuoteReferred={isQuoteReferred}
                        restrictions={restriction}
                        higherLimitRequest={
                            higherLimitRequests !== undefined
                                ? higherLimitRequests[
                                      mapToCoverageRestrictionCoverageType(
                                          coverageDictionary['eo'].type,
                                      )
                                  ]
                                : undefined
                        }
                        effectiveDate={effectiveDate}
                        showEpliStandaloneInCalifornia={showEpliStandaloneInCalifornia}
                    />
                )}
            {coverageDictionary['techCyber'] &&
                fields.isTechSelected.props.value &&
                !isCoverageRestricted(coverageDictionary['techCyber'].type, restriction) && (
                    <ESPRenewalSelectedCoverage
                        key={coverageDictionary['techCyber'].type}
                        coverage={coverageDictionary['techCyber']}
                        revenue={revenue}
                        isSubmitting={isSubmitting}
                        fields={fields}
                        coverageRestriction={
                            restriction
                                ? getCoverageRestriction(
                                      coverageDictionary['techCyber'].type,
                                      restriction,
                                  )
                                : undefined
                        }
                        newInsurerDocumentsReleaseDate={newInsurerDocumentsReleaseDate}
                        fiduciaryDocumentsReleaseDate={fiduciaryDocumentsReleaseDate}
                        submittedAt={submittedAt}
                        state={state}
                        isQuoteReferred={isQuoteReferred}
                        restrictions={restriction}
                        higherLimitRequest={
                            higherLimitRequests !== undefined
                                ? higherLimitRequests[
                                      mapToCoverageRestrictionCoverageType(
                                          coverageDictionary['techCyber'].type,
                                      )
                                  ]
                                : undefined
                        }
                        effectiveDate={effectiveDate}
                        showEpliStandaloneInCalifornia={showEpliStandaloneInCalifornia}
                    />
                )}
        </StackLayout>
    );
};
