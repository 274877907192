import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface LPLAttorneyInfo {
    readonly fullName: string;
    readonly retroactiveDate?: Date;
}

export const LPLAttorneyInfo = {
    ...defineValidator<LPLAttorneyInfo>({
        fullName: Joi.string().required(),
        retroactiveDate: Joi.date().optional(),
    }),
    create(lplAttorneyInfo: LPLAttorneyInfo) {
        return LPLAttorneyInfo.validate(lplAttorneyInfo);
    },
};
