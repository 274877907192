import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { isErr, Result, Success } from '@embroker/shotwell/core/types/Result';
import { State } from '@embroker/shotwell/core/types/StateList';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { QuestionnaireData } from '../../../types/BundleQuoteQuestionnaireData';

interface LocationWithLargestNumber {
    state: State;
}

interface rawMPLQuestionnaireData extends QuestionnaireData {
    location_with_largest_number?: LocationWithLargestNumber;
    purchased_limit?: number;
}

export interface MPLQuestionnaireData extends QuestionnaireData {
    locationWithLargestNumber?: LocationWithLargestNumber;
    purchasedLimit?: number;
}

export const MPLQuestionnaireData = {
    ...defineValidator<rawMPLQuestionnaireData>(
        QuestionnaireData.schema.keys({
            location_with_largest_number: Joi.object({
                state: State.schema,
            }).optional(),
            purchased_limit: Joi.number().optional(),
        }),
    ),
    create(questionnaireData: unknown): Result<MPLQuestionnaireData, InvalidArgument> {
        const result = MPLQuestionnaireData.validate(questionnaireData);
        if (isErr(result)) {
            return result;
        }

        return Success({
            ...result.value,
            locationWithLargestNumber: result.value.location_with_largest_number,
            purchasedLimit: result.value.purchased_limit
                ? Number(result.value.purchased_limit)
                : undefined,
        });
    },
};
