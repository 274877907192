import { defineEntityValidator, entity, Entity } from '@embroker/shotwell/core/entity/Entity';
import { NotAllowed } from '@embroker/shotwell/core/Error';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { Nullable } from '@embroker/shotwell/core/types';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { Failure, Result, Success } from '@embroker/shotwell/core/types/Result';
import { Joi } from '@embroker/shotwell/core/validation/schema';

/**
 * Possible statuses a UserInvite can be in.
 */
export type InviteStatus = 'accepted' | 'rejected' | 'unset';
/**
 * UserInvite class represents user's invite to platform or organization.
 */
export interface UserInvite extends Entity {
    /**
     * Status of the user invite.
     *
     * It can be 'accepted', 'rejected' or 'unset'
     */
    readonly status: InviteStatus;
    /**
     * Email address of invited user
     */
    readonly email: Nullable<EmailAddress>;
    /**
     * Name of the invitee organization
     */
    readonly organizationName: Nullable<string>;
    /**
     * Is the invitee owner of the organization
     */
    readonly isOrganizationOwner: boolean;
    /**
     * NOTE: Please tend towards removing this as soon as API of "user/invitations" is upgraded
     * True if able to invite others to organization
     */
    readonly inviteEnabled: boolean;
    /**
     * Marks the invite as accepted.
     *
     * If invite has already been rejected a NotAllowed error is returned.
     */
    accept(): Result<void, NotAllowed>;
    /**
     * Marks the invite as rejected.
     *
     * If invite has already been accepted a NotAllowed error is returned.
     */
    reject(): Result<void, NotAllowed>;
}

/**
 * A domain event created when user sends an invitation.
 */
export interface UserInvited extends DomainEvent {
    readonly origin: 'UserInvite';
    readonly name: 'Invited';
}

/**
 * A domain event created when user accepts the invite.
 */
export interface UserInviteAccepted extends DomainEvent {
    readonly origin: 'UserInvite';
    readonly name: 'Accepted';
    /**
     * Email address of user which accepted invitation
     */
    readonly email: Nullable<EmailAddress>;
}
/**
 * A domain event created when user rejects the invite.
 */
export interface UserInviteRejected extends DomainEvent {
    readonly origin: 'UserInvite';
    readonly name: 'Rejected';
    /**
     * Email address of user which declined invitation
     */
    readonly email: Nullable<EmailAddress>;
}

export const UserInvite = entity<UserInvite>({
    validator: defineEntityValidator<UserInvite>({
        status: Joi.string().valid('accepted', 'rejected', 'unset').optional().default('unset'),
        email: EmailAddress.schema.allow(null, ''),
        organizationName: Joi.string().allow(null, ''),
        isOrganizationOwner: Joi.boolean().default(false),
        inviteEnabled: Joi.boolean(),
    }),
    accept() {
        if (this.props.status === 'rejected') {
            return Failure(
                NotAllowed({ operation: 'accept()', reason: 'Invite has already been rejected.' }),
            );
        }
        if (this.props.status !== 'accepted') {
            this.props.status = 'accepted';
            this.createEvent<UserInviteAccepted>('Accepted', {
                email: this.email,
            });
        }
        return Success();
    },
    reject() {
        if (this.props.status === 'accepted') {
            return Failure(
                NotAllowed({ operation: 'reject()', reason: 'Invite has already been accepted.' }),
            );
        }
        if (this.props.status !== 'rejected') {
            this.props.status = 'rejected';
            this.createEvent<UserInviteRejected>('Rejected', {
                email: this.email,
            });
        }
        return Success();
    },
});
