import {
    Modal,
    ModalActions,
    ModalState,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { useNavigation } from '../../../view/hooks/useNavigation';

interface ClaimRequestThankYouModalInput {
    modal: ModalState & ModalActions;
}

export function ClaimRequestThankYouModal({ modal }: ClaimRequestThankYouModalInput) {
    const { navigate } = useNavigation();

    return (
        <Modal {...modal} size="small" dismissable={false}>
            <StackLayout gap="32">
                <Text style="heading 3">Thank you!</Text>
                <Text>
                    This confirms receipt of your claim submission. You'll receive a formal
                    acknowledgement letter shortly that will include an assigned Claim Number and
                    Claim Professional.
                </Text>
                <TextButton
                    onClick={() => {
                        navigate('/claims/filing-list');
                    }}
                >
                    Close
                </TextButton>
            </StackLayout>
        </Modal>
    );
}
