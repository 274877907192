import { ShoppingGetActiveSignatureRequestResponse } from '@embroker/shotwell-api/app';
import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Immutable } from '@embroker/shotwell/core/types';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { FieldMap, OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    Box,
    CenterLayout,
    ColumnLayout,
    Icon,
    Label,
    ReactProps,
    StackLayout,
    StatusLabel,
    StatusMessage,
    Text,
    TextButton,
    useStableEventHandler,
} from '@embroker/ui-toolkit/v2';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { FileUpload } from '../../../../documents/view/FileUpload';
import { UploadFile } from '../../../../documents/view/UploadFile/UploadFile';
import { SignaturePacketDocument } from '../../../../shopping/types/SignaturePacketDocument';
import { WizardForm } from '../../../../view/hooks/useWizardForm';
import { Quote } from '../../../entities/Quote';
import { GetLatestPendingSignature } from '../../useCases/GetLatestPendingSignature';
import { GetSignaturePacketDocuments } from '../../useCases/GetSignaturePacketDocuments';
import { RemoveSignaturePacket } from '../../useCases/RemoveSignaturePacket';
import { UploadSignaturePacket } from '../../useCases/UploadSignaturePacket';

interface FormDataWithSignatureFilesUpload {
    readonly brokerSignature: boolean;
}

export interface BrokerSignaturePageProps<T extends FormDataWithSignatureFilesUpload>
    extends ReactProps<'div'> {
    quote: Quote;
    isSubmitting: boolean;
    fields: Immutable<FieldMap<FormDataWithSignatureFilesUpload>>;
    setValue: WizardForm<OpaqueForm<Immutable<T>>>['setValue'];
    value: WizardForm<OpaqueForm<T>>['value'];
    trigger: WizardForm<OpaqueForm<T>>['trigger'];
    activePageName?: string;
}

export function BrokerSignaturePage<T extends FormDataWithSignatureFilesUpload>({
    quote,
    fields,
    setValue,
    value,
    isSubmitting,
    trigger,
    className,
    activePageName,
}: BrokerSignaturePageProps<T>) {
    const [signatureRequest, setSignatureRequest] =
        useState<ShoppingGetActiveSignatureRequestResponse>();

    // Uploaded files provided to upload component (state is controlled from outside)
    const [files, setFiles] = useState<FileUpload[]>([]);

    // Uploaded files which are saved as signature packet documents (subset of files)
    const [uploadedDocuments, setUploadedDocuments] = useState<SignaturePacketDocument[]>([]);

    // Fetch previously uploaded documents
    const { result: SignaturePacketDocumentsResult } = useUseCase(GetSignaturePacketDocuments, {
        applicationId: quote.applicationId,
    });

    // Used when above fetching is done for mapping those documents to fileUpload type to be provided to upload component (as files)
    const setInitialSignatureDocuments = useStableEventHandler(
        (signaturePacketDocuments: Immutable<Array<SignaturePacketDocument>>) => {
            const signatureDocuments: SignaturePacketDocument[] = [...signaturePacketDocuments];
            setFiles(
                signatureDocuments.map((doc) => {
                    return {
                        id: UUID.create(),
                        file: { name: doc.fileName } as File,
                        s3FileKey: doc.storageLocation,
                    };
                }),
            );
            setUploadedDocuments(signatureDocuments);
        },
    );

    useEffect(() => {
        if (SignaturePacketDocumentsResult && isOK(SignaturePacketDocumentsResult)) {
            setInitialSignatureDocuments(
                SignaturePacketDocumentsResult.value.signaturePacketDocuments.filter(
                    (signaturePacket) => !signaturePacket.isDigitallySigned,
                ),
            );
        }
    }, [SignaturePacketDocumentsResult, setInitialSignatureDocuments]);

    // Get the latest signature request when landing on the signature page
    // The reason why this is not done while on coverage page is that we shouldn't change form value and make it dirty for quotes which are recalculated on demand
    // While on coverage page with unsigned quote, reset signatureRequest to ensure that its old value is not used in any of the useEffects when landing on signature page
    useEffect(() => {
        let isMounted = true;
        if (activePageName === 'signature' && quote.status === 'draft') {
            execute(GetLatestPendingSignature, {
                applicationId: quote.applicationId,
            }).then((result) => {
                if (isMounted) {
                    if (isOK(result)) {
                        setSignatureRequest(result.value);
                    } else {
                        container.get<Logger>(Log).error(result.errors);
                    }
                }
            });
        }
        if (activePageName === 'coverage' && quote.status !== 'signed') {
            setSignatureRequest(undefined);
        }
        return () => {
            isMounted = false;
        };
    }, [activePageName, setSignatureRequest, quote.status, quote.applicationId]);

    // When landing on the signature page check if application is digitally signed and if it is trigger quote signing (signing can only happen while on signature page)
    // Signing is not triggered if there are only uploaded documents because we can't guarantee if it's the latest signaturePacket document
    // That can be "verified" when document upload happens and quote signing is triggered in that case
    // The reason to set brokerSignature to true when there are uploaded documents is to know if broker should be warned to reupload documents
    useEffect(() => {
        if (activePageName === 'signature') {
            setValue({
                ...value,
                brokerSignature:
                    uploadedDocuments.length > 0 || signatureRequest?.is_signed === true,
            });

            if (signatureRequest?.is_signed === true) {
                trigger('sign');
            }
        }
    }, [activePageName, value, setValue, trigger, signatureRequest?.is_signed, uploadedDocuments]);

    const handleUploadFile = (uploadedFile: FileUpload, newFiles: FileUpload[]) => {
        if (uploadedFile.s3FileKey !== null) {
            execute(UploadSignaturePacket, {
                applicationId: quote.applicationId,
                signaturePacketDocument: {
                    fileName: uploadedFile.file.name,
                    storageLocation: uploadedFile.s3FileKey,
                    isDigitallySigned: false,
                },
            }).then((response) => {
                if (isOK(response)) {
                    setUploadedDocuments([
                        ...uploadedDocuments,
                        {
                            fileName: uploadedFile.file.name,
                            storageLocation: uploadedFile.s3FileKey,
                            isDigitallySigned: false,
                        },
                    ]);
                    setValue({
                        ...value,
                        brokerSignature: true,
                    });
                    trigger('sign');
                }
            });
        }

        setFiles(newFiles);
    };

    const handleSelectedFiles = (_selectedFiles: FileUpload[], newFiles: FileUpload[]) => {
        setFiles(newFiles);
    };

    const handleRemovedFile = (removedFile: FileUpload, newFiles: FileUpload[]) => {
        if (removedFile.s3FileKey !== null) {
            execute(RemoveSignaturePacket, {
                applicationId: quote.applicationId,
                storageLocation: removedFile.s3FileKey,
            }).then((response) => {
                if (isOK(response)) {
                    onSuccessfullyRemovedFile(removedFile, newFiles);
                }
            });
        } else {
            onSuccessfullyRemovedFile(removedFile, newFiles);
        }
    };

    const onSuccessfullyRemovedFile = (removedFile: FileUpload, newFiles: FileUpload[]) => {
        const documents = uploadedDocuments.filter((document: SignaturePacketDocument) => {
            return (
                removedFile.s3FileKey === null || document.storageLocation !== removedFile.s3FileKey
            );
        });
        setFiles(newFiles);
        setUploadedDocuments(documents);
        setValue({
            ...value,
            brokerSignature: documents.length > 0 || signatureRequest?.is_signed === true,
        });
        if (quote.status === 'signed') {
            trigger('sign');
        }
    };

    const getSignatureUrl = (token: string) => `${window.location.host}/digital-signature/${token}`;

    const [isCopied, setIsCopied] = useState(false);

    const handleCopyToClipboardClick = (token: string) => {
        const copyInput = document.createElement('input');
        try {
            document.body.appendChild(copyInput);
            copyInput.setAttribute('value', getSignatureUrl(token));
            copyInput.select();
            const executionResult = document.execCommand('copy');
            setIsCopied(executionResult);
            setTimeout(() => setIsCopied(false), 5000);
        } catch (err) {
            setIsCopied(false);
        } finally {
            document.body.removeChild(copyInput);
        }
    };

    const getCopyToClipboardResult = useCallback(() => {
        if (isCopied) {
            return (
                <CenterLayout gap="none">
                    <StatusLabel type="green-outline">Copied to your clipboard</StatusLabel>
                </CenterLayout>
            );
        }
        return null;
    }, [isCopied]);

    const showReuploadError = value.brokerSignature && quote.status !== 'signed';

    return (
        <StackLayout className={className}>
            <Text style="heading 4">We need some signed documents to bind coverage.</Text>
            {signatureRequest ? (
                signatureRequest.is_signed ? (
                    <StatusMessage status="success">
                        Application was successfully signed on{' '}
                        {new Date(signatureRequest?.date_signed || 0).toDateString()}
                    </StatusMessage>
                ) : (
                    <React.Fragment>
                        <Text style="body 1">
                            Share the signature packet digitally with your client by sending this
                            link. We’ll notify you when the client signs.
                        </Text>
                        <Box>
                            <ColumnLayout split="-1" data-e2e="digital-signature-link">
                                <Label style="body 2">
                                    {getSignatureUrl(signatureRequest.application_token)}
                                </Label>
                                <TextButton
                                    onClick={() => {
                                        handleCopyToClipboardClick(
                                            signatureRequest.application_token,
                                        );
                                    }}
                                >
                                    <Icon name="copy" size="medium" />
                                </TextButton>
                            </ColumnLayout>
                        </Box>
                        {getCopyToClipboardResult()}
                        <Text style="body 1">
                            Alternatively{' '}
                            <TextButton
                                color="primary-500"
                                disabled={isSubmitting}
                                onClick={() => {
                                    trigger('downloadSignaturePacket');
                                }}
                            >
                                download the PDF
                            </TextButton>
                            , get it signed by the client and upload here to proceed to binding.
                        </Text>
                    </React.Fragment>
                )
            ) : (
                <Text style="body 1">
                    <TextButton
                        color="primary-500"
                        disabled={isSubmitting}
                        onClick={() => {
                            trigger('downloadSignaturePacket');
                        }}
                    >
                        Download the PDF
                    </TextButton>
                    , get it signed by the client and upload here to proceed to binding.
                </Text>
            )}

            <hr />
            <Text style="default">
                When your client has signed the signature packet agreeing to purchase of coverage,
                please upload the scanned documents and click &quot;Bind coverage&quot; on the
                right.
            </Text>
            <UploadFile
                files={files}
                readOnly={isSubmitting}
                buttonText={'Choose file'}
                placeholder="or drag file here."
                fileTypeFilters={['application/pdf']}
                onUploaded={handleUploadFile}
                onRemoved={handleRemovedFile}
                onSelected={handleSelectedFiles}
                showButton
                isMultiple
            />
            {fields.brokerSignature.props.hasErrors ? (
                <StatusMessage status="error">
                    Please upload the agreements signed by the client or wait for the client to sign
                    the application digitally to purchase coverage.{' '}
                    {fields.brokerSignature.messages}
                </StatusMessage>
            ) : null}
            {showReuploadError ? (
                <StatusMessage status="error">
                    Please download new signature packet PDF and upload the agreements signed by the
                    client or sign the application digitally to purchase coverage.
                </StatusMessage>
            ) : null}
        </StackLayout>
    );
}
