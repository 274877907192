import { Modal, ModalActions, ModalState } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { ModalLayout } from '../../../view/components/ModalLayout.view';

export interface LeavingConfirmationModalProps {
    modal: ModalState & ModalActions;
    onConfirmLeaving: () => void;
}

export const LeavingConfirmationModal = function LeavingConfirmationModal({
    modal,
    onConfirmLeaving,
}: LeavingConfirmationModalProps) {
    return (
        <Modal {...modal}>
            <ModalLayout
                title="Are you sure you want to leave?"
                primaryAction={{
                    label: 'Save and Exit',
                    onClick: onConfirmLeaving,
                }}
                secondaryAction={{
                    label: 'Cancel',
                    onClick: modal.hide,
                }}
            >
                We know that sometimes you just don't have the time. Exiting will save your progress
                so you can easily pick up right where you left off.
            </ModalLayout>
        </Modal>
    );
};
