import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { CarrierNotFound } from '../errors';
import { ExcessCarrierRepository } from '../repositories/ExcessCarrierRepository';
import { ExcessCarrier } from '../types/ExcessCarrier';

export interface GetExcessCarrier extends UseCase {
    execute(request: UUID): AsyncResult<ExcessCarrier, CarrierNotFound | InvalidArgument>;
}

@injectable()
export class GetExcessCarrierUseCase extends UseCase implements GetExcessCarrier {
    public static type = Symbol('Excess/GetExcessCarrier');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ExcessCarrierRepository)
        private excessCarrierRepository: ExcessCarrierRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: UUID,
    ): AsyncResult<ExcessCarrier, CarrierNotFound | InvalidArgument> {
        return await this.excessCarrierRepository.getCarrier(request);
    }
}

export const GetExcessCarrier: UseCaseClass<GetExcessCarrier> = GetExcessCarrierUseCase;
