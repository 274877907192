import { inject, injectable } from '@embroker/shotwell/core/di';
import { OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { Document } from '../entities/Document';
import { DocumentRepository } from '../repositories/DocumentRepository';

/**
 * Request data for GetFileList use case
 */
export interface GetFileListRequest {
    /**
     * File keys
     */
    fileKeyList: string[];
    applicationId?: UUID;
}

export const GetFileListRequest = {
    ...defineValidator<GetFileListRequest>({
        fileKeyList: Joi.array().items(Joi.string()),
        applicationId: UUID.schema.optional(),
    }),
    create(getFileListRequest: GetFileListRequest) {
        return GetFileListRequest.validate(getFileListRequest);
    },
};

/**
 * GetFileList use case
 */
export interface GetFileList extends UseCase {
    execute(request: GetFileListRequest): AsyncResult<Document[], OperationFailed>;
}

@injectable()
export class GetFileListUseCase extends UseCase implements GetFileList {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Documents/GetFileList');

    /**
     * Constructor for GetFileList use case class instance.
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param documentRepository Document repository used to get file lists from.
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(DocumentRepository) private documentRepository: DocumentRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes GetFileList use case
     * @param fileKeyList list of file keys of the documents in the resulting file list
     */
    async execute(request: GetFileListRequest): AsyncResult<Document[], OperationFailed> {
        const result = await this.documentRepository.getFileList(request);
        return result;
    }
}
export const GetFileList: UseCaseClass<GetFileList> = GetFileListUseCase;
