import { isOK, Result } from '@embroker/shotwell/core/types/Result';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { Notification, NotificationViewModel } from '../entities/Notification';
import { Immutable } from '@embroker/shotwell/core/types';
import { shallowClone } from '@embroker/shotwell/core/object';

const DISPLAY_NOTIFICATION_COUNT = 6;

export const NotificationList = {
    ...defineValidator<Notification[]>(Joi.array().items(Notification.schema)),
    create(notifications: NotificationViewModel[]) {
        return NotificationList.validate(notifications);
    },
    getUnreadCount(notifications: Immutable<NotificationViewModel[]>): number {
        return notifications.filter(
            (notification: NotificationViewModel) => notification.status === 'unread',
        ).length;
    },
    getPageCount(notifications: NotificationViewModel[]): number {
        return Math.ceil(notifications.length / DISPLAY_NOTIFICATION_COUNT);
    },
    getAllNotifications(notifications?: Result<NotificationViewModel[]>): NotificationViewModel[] {
        if (notifications !== undefined && isOK(notifications)) {
            return shallowClone(notifications.value);
        }
        return [];
    },
    getPage(notifications: NotificationViewModel[], pageNumber: number): NotificationViewModel[] {
        const validate = NotificationList.validate(notifications);

        if (isOK(validate)) {
            const sortedNotifications = notifications.sort(
                (b: NotificationViewModel, a: NotificationViewModel) =>
                    a.created.getTime() - b.created.getTime(),
            );

            const startOffset = DISPLAY_NOTIFICATION_COUNT * pageNumber;
            const endOffset = startOffset + DISPLAY_NOTIFICATION_COUNT;

            return sortedNotifications.slice(startOffset, endOffset);
        }
        return [];
    },
};
