import { NotificationStatus } from '@embroker/shotwell-api/v2/app.spec';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import {
    ColumnLayout,
    Text,
    StackLayout,
    TextButton,
    Dot,
    CheckBox,
    Tooltip,
    useResponsive,
    UseResponsiveScreenQuery,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { NotificationViewModel } from '../../entities/Notification';
import { NotificationAction } from '../../types/NotificationAction';
import { Link } from '../../../view/components/Link/Link';

interface NotificationProps {
    notificationDto: NotificationViewModel;
    isLoading?: boolean;
    onUpdateNotificationStatus: (
        notification: NotificationViewModel,
        updatedStatus: NotificationStatus,
    ) => void;
}

export function Notification({
    isLoading = false,
    onUpdateNotificationStatus,
    notificationDto,
}: NotificationProps) {
    const { content, status, action, created } = notificationDto;
    const isRead = status === 'read';

    const handleStatusChange = (status: NotificationStatus) =>
        onUpdateNotificationStatus(notificationDto, status);

    const notificationCta = NotificationAction.getRedirect(action);
    const breakpointQuery: UseResponsiveScreenQuery = {
        screenWidth: { smallerThan: 'large-tablet' },
    };
    const isBreakPoint = useResponsive(breakpointQuery);

    return (
        <ColumnLayout split="1" responsive={breakpointQuery} gap={isBreakPoint ? '12' : undefined}>
            <ColumnLayout gap="16" top className={isBreakPoint ? 'u-1/1' : 'u-5/7'}>
                <Dot
                    color="positive-500"
                    className={`${isRead ? 'u-invisible' : ''} c-icon`}
                    data-e2e="notification-isread-dot"
                />
                <StackLayout gap="12">
                    <Text color={isRead ? 'ui-400' : undefined} data-e2e="notification-content">
                        {content}
                    </Text>
                    <Text color="ui-400" style="microcopy">
                        <DateDisplay value={created} distance from={new Date(Date.now())} /> ago
                    </Text>
                </StackLayout>
            </ColumnLayout>
            <ColumnLayout split="-3" gap="24" center className={isBreakPoint ? 'u-1/1' : 'u-2/7'}>
                {notificationCta && (
                    <TextButton
                        as={Link}
                        href={notificationCta.url}
                        data-e2e="notification-call-to-action"
                    >
                        {notificationCta.label}
                    </TextButton>
                )}
                <Tooltip placement="bottom" text={`Mark as ${isRead ? 'un' : ''}read.`}>
                    <CheckBox
                        disabled={isLoading}
                        appearance="circle-check"
                        inline
                        checked={isRead}
                        onChange={() => handleStatusChange(isRead ? 'unread' : 'read')}
                        data-e2e="notification-isread-toggle"
                    />
                </Tooltip>
                <TextButton
                    disabled={isLoading}
                    icon="close"
                    iconSize="small"
                    color="ui-500"
                    onClick={() => handleStatusChange('dismissed')}
                    data-e2e="notification-dismiss-button"
                />
            </ColumnLayout>
        </ColumnLayout>
    );
}
