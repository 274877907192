import { ContainerModule } from '@embroker/shotwell/core/di';
import { Module } from '../../Module';
import { WCGAQuoteRepository } from './repositories/WCGAQuoteRepository';
import { APIWCGAQuoteRepository } from './repositories/WCGAQuoteRepository/APIWCGAQuoteRepository';
import { GetLastWCGAQuote } from './useCases/GetLastWCGAQuote';
import { GetWCGAQuote } from './useCases/GetWCGAQuote';
import { PurchaseWCGAQuote } from './useCases/PurchaseWCGAQuote';
import { SubmitWCGAQuoteForReview } from './useCases/SubmitWCGAQuoteForReview';

export const WCGAQuoteModule: Module = {
    container: new ContainerModule((bind) => {
        bind<WCGAQuoteRepository>(WCGAQuoteRepository)
            .to(APIWCGAQuoteRepository)
            .inSingletonScope();
        bind<GetWCGAQuote>(GetWCGAQuote.type).to(GetWCGAQuote).inSingletonScope();
        bind<PurchaseWCGAQuote>(PurchaseWCGAQuote.type).to(PurchaseWCGAQuote).inSingletonScope();
        bind<SubmitWCGAQuoteForReview>(SubmitWCGAQuoteForReview.type)
            .to(SubmitWCGAQuoteForReview)
            .inSingletonScope();
        bind<GetLastWCGAQuote>(GetLastWCGAQuote.type).to(GetLastWCGAQuote).inSingletonScope();
    }),
};
