import { defineEntityValidator, entity, Entity } from '@embroker/shotwell/core/entity/Entity';
import { Money } from '@embroker/shotwell/core/types/Money';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { AppTypeCode, AppTypeCodeList } from '../types/enums';
import { Quote } from '../types/Quote';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { Nullable } from '@embroker/shotwell/core/types';
import { SKU } from '../../analytics/types/SKU';

export interface IndicativePremium extends Entity {
    applicationId: UUID;
    appType: AppTypeCode;
    ineligible: boolean;
    basePremium: Money;
    quote?: Quote;
}

export interface IndicativePremiumCreated extends DomainEvent {
    readonly origin: 'IndicativePremium';
    readonly name: 'Created';
    indicativePremiumAmount: Nullable<Money>;
    applicationId: UUID;
    isRenewal: boolean;
    sku?: SKU;
}

export const IndicativePremium = entity<IndicativePremium>({
    validator: defineEntityValidator<IndicativePremium>({
        appType: Joi.string()
            .valid(...AppTypeCodeList)
            .required(),
        applicationId: UUID.schema.required(),
        ineligible: Joi.boolean(),
        basePremium: Money.schema.required(),
        quote: Quote.schema.optional(),
    }),
});
