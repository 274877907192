import { ScrollBox, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';

const usaStatesWithWarrantyHeading = ['AK', 'FL', 'GA', 'KS', 'KY', 'ME', 'NE', 'NH', 'NC', 'OK'];

interface WarrantyAndFraudModalInput {
    fullName: string;
    title: string;
    usaState: string;
}

export function LPLWarrantyAndFraudModalContent({
    fullName,
    title,
    usaState,
}: WarrantyAndFraudModalInput) {
    const headingPrefix = usaStatesWithWarrantyHeading.includes(usaState)
        ? 'Applicant Representation'
        : 'Warranty';
    return (
        <ScrollBox>
            <StackLayout>
                <Text as="h3" style="heading 3">
                    {headingPrefix}: Prior Knowledge of Facts, Circumstances, or Situations
                </Text>
                <Text as="p">
                    The undersigned declares that to the best of his/her knowledge, after reasonable
                    inquiry, the statements herein are true. It is agreed that this Application
                    shall be the basis of the contract should a Policy be issued, and this
                    Application will be attached to and become a part of such Policy, if issued.
                    Insurer hereby is authorized to make any investigation and inquiry in connection
                    with this Application as they may deem necessary. The Company will have relied
                    upon such Applicant, attachments, and such other information submitted therewith
                    in issuing such policy. The undersigned further certifies that he/she has read
                    the applicable fraud notices referenced below in this Application and that none
                    of the information provided herein has been provided in violation of any
                    applicable insurance fraud laws or regulations.
                </Text>
                <br />
                <Text as="h3" style="heading 3">
                    Fraud Statements
                </Text>
                <br />
                <strong>General Statements</strong>
                <Text as="p">
                    Any person who knowingly and with intent to defraud any insurance company or
                    another person files an application for insurance or statement of claim
                    containing any materially false information, or conceals for the purpose of
                    misleading, information concerning any fact material thereto, commits a
                    fraudulent insurance act, which is a crime and subjects the person to criminal
                    and civil penalties. (Applicable in all states except those specifically
                    identified below).
                </Text>
                <strong>
                    Applicable in Alabama, Arkansas, Louisiana, Maryland, New Mexico, Rhode Island
                    and West Virginia
                </strong>
                <Text as="p">
                    Any person who knowingly (or willfully)* presents a false or fraudulent claim
                    for payment of a loss or benefit or knowingly presents false information in an
                    application for insurance is guilty of a crime and may be subject to (civil)**
                    fines and (criminal penalties)** confinement in prison. *Applies in MD only. **
                    Applies in NM only.
                </Text>
                <strong>Applicable in California</strong>
                <Text as="p">
                    Any person who knowingly presents false or fraudulent information to obtain or
                    amend insurance coverage or to make a claim for the payment of a loss is guilty
                    of a crime and may be subject to fines and confinement in state prison. This
                    warning shall apply to this Policy, including any endorsements herein, any
                    mid-term modification, or any mid-term amendment by endorsement.
                </Text>
                <strong>Applicable in Colorado</strong>
                <Text as="p">
                    It is unlawful to knowingly provide false, incomplete, or misleading facts or
                    information to an insurance company for the purpose of defrauding or attempting
                    to defraud the company. Penalties may include imprisonment, fines, denial of
                    insurance and civil damages. Any insurance company or agent of an insurance
                    company who knowingly provides false, incomplete, or misleading facts or
                    information to a policyholder or claimant for the purpose of defrauding or
                    attempting to defraud the policyholder or claimant with regard to a settlement
                    or award payable from insurance proceeds shall be reported to the Colorado
                    Division of Insurance within the Department of Regulatory Agencies.
                </Text>
                <strong>Applicable in the District of Columbia</strong>
                <Text as="p">
                    Warning: it is a crime to provide false or misleading information to an insurer
                    for the purpose of defrauding the insurer or any other person. Penalties include
                    imprisonment and/or fines. In addition, an insurer may deny insurance benefits
                    if false information materially related to a claim was provided by the
                    applicant.
                </Text>
                <strong>Applicable in Florida</strong>
                <Text as="p">
                    Any person who knowingly, and with intent to injure, defraud, or deceive any
                    insurer files a statement of claim or an application containing any false,
                    incomplete or misleading information is guilty of a felony of the third degree.
                </Text>
                <strong>Applicable in Hawaii</strong>
                <Text as="p">
                    For you protection, Hawaii law requires you to be informed that presenting a
                    fraudulent claim for payment of a loss or benefit is a crime punishable by fines
                    or imprisonment, or both.
                </Text>
                <strong>Applicable in Kansas</strong>
                <Text as="p">
                    Any person who, knowingly and with intent to defraud, presents, causes to be
                    presented or prepares with knowledge or belief that it will be presented to or
                    by an insurer, purported insurer, broker or any agent thereof, any written
                    statement as part of, or in support of, an application for the issuance of, or
                    the rating of an insurance policy for personal or commercial insurance, or a
                    claim for payment or other benefit pursuant to an insurance policy for
                    commercial or personal insurance which such person knows to contain materially
                    false information concerning any fact material thereto; or conceals, for the
                    purpose of misleading, information concerning any fact material thereto commits
                    a fraudulent insurance act.
                </Text>
                <strong>Applicable in Kentucky, New York and Pennsylvania</strong>
                <Text as="p">
                    Any person who knowingly and with intent to defraud any insurance company or
                    other person files an application for insurance or statement of claim containing
                    any materially false information or conceals for the purpose of misleading,
                    information concerning any fact material thereto commits a fraudulent insurance
                    act, which is a crime (and subjects such person to criminal and civil
                    penalties)* (not to exceed five thousand dollars and the stated value of the
                    claim for each such violation)**.*Applies in NY and PA only. **Applies in NY
                    Only.
                </Text>
                <strong>Applicable in Massachusetts and Nebraska</strong>
                <Text as="p">
                    Any person who knowingly and with intent to defraud any insurance company or
                    another person files an application for insurance or statement of claim
                    containing any materially false information, or conceals for the purpose of
                    misleading information concerning any fact material thereto, may be committing a
                    fraudulent insurance act, which may be a crime and may subject the person to
                    criminal and civil penalties.
                </Text>
                <strong>Applicable in Maine, Tennessee, Virginia and Washington</strong>
                <Text as="p">
                    It is a crime to knowingly provide false, incomplete, or misleading information
                    to an insurance company for the purpose of defrauding the company. Penalties
                    (may)* include imprisonment, fines, and denial of insurance benefits. * Applies
                    in ME Only.
                </Text>
                <strong>Applicable in Minnesota</strong>
                <Text as="p">
                    A person who files a claim with intent to defraud or helps commit a fraud
                    against an insurer is guilty of a crime.
                </Text>
                <strong>Applicable in New Hampshire</strong>
                <Text as="p">
                    Any person who knowingly presents a false or fraudulent claim for payment of a
                    loss or benefit or knowingly presents false information in an application for
                    insurance is guilty of a crime and may be subject to fines and confinement in
                    prison.
                </Text>
                <strong>Applicable in New Jersey</strong>
                <Text as="p">
                    Any person who includes any false or misleading information on an application
                    for an insurance policy is subject to criminal and civil penalties.
                </Text>
                <strong>Applicable in Ohio</strong>
                <Text as="p">
                    Any person who, with intent to defraud or knowing that he/she is facilitating a
                    fraud against an insurer, submits an application or files a claim containing a
                    false or deceptive statement is guilty of insurance fraud.
                </Text>
                <strong>Applicable in Oklahoma</strong>
                <Text as="p">
                    Warning: Any person who knowingly, and with intent to injure, defraud, or
                    deceive any insurer, makes any claim for the proceeds of an insurance policy
                    containing any false, incomplete or misleading information is guilty of a
                    felony.
                </Text>
                <strong>Applicable in Oregon</strong>
                <Text as="p">
                    Any person who knowingly and with intent to defraud or solicit another to
                    defraud the insurer by submitting an application containing a false statement as
                    to any material fact may be violating state law.
                </Text>
                <strong>Applicable in Vermont</strong>
                <Text as="p">
                    Any person who knowingly presents a false statement in an application for
                    insurance may be guilty of a criminal offense and may be subject to penalties
                    under state law.
                </Text>
                <Text as="p">
                    Signed: <strong>{fullName}</strong>
                    <br />
                    Date:{' '}
                    <strong>
                        <DateDisplay value={new Date(Date.now())} format="MM/dd/yyyy" />
                    </strong>
                    <br />
                    Title: <strong>{title}</strong>
                </Text>
            </StackLayout>
        </ScrollBox>
    );
}
