import { DocumentSpecification } from './DocumentSpecification';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { Immutable } from '@embroker/ui-toolkit/v2';

export interface ProductDefinition {
    shortName: string;
    fullName: string;
    icon: string;
    documentSpecs: Immutable<DocumentSpecification[]>;
}

export const ProductDefinition = {
    ...defineValidator<ProductDefinition>(
        Joi.object({
            shortName: Joi.string().required(),
            fullName: Joi.string().required(),
            icon: Joi.string().allow('').required(),
            documentSpecs: Joi.array().items(DocumentSpecification.schema).required(),
        }),
    ),
    create(productDefinition: ProductDefinition) {
        return ProductDefinition.validate(productDefinition);
    },
};
