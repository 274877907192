import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { PolicyFilterRepository } from '../repositories/PolicyFilterRepository';
import { PolicyFilter } from '../types/PolicyFilter';

/**
 * Response data for GetPolicyFilter use case
 */
export interface GetPolicyFilterResponse {
    filter: PolicyFilter;
}

/**
 * GetPolicyFilter use case is used to ...
 */
export interface GetPolicyFilter extends UseCase {
    execute(): AsyncResult<GetPolicyFilterResponse>;
}

@injectable()
class GetPolicyFilterUseCase extends UseCase implements GetPolicyFilter {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('policy/GetPolicyFilter');

    /**
     * Constructor for GetPolicyFilter use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param policyFilterRepository A repository which stores policyFilters
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(PolicyFilterRepository) private policyFilterRepository: PolicyFilterRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes GetPolicyFilter use case
     * Input is of GetPolicyFilterRequest type
     * @returns data of GetPolicyFilterResponse type if execution was successful
     * @returns InvalidArgument if ...
     * @returns OperationFailed if ...
     */
    public async execute(): AsyncResult<GetPolicyFilterResponse> {
        const filter = this.policyFilterRepository.get();
        const result: GetPolicyFilterResponse = {
            filter,
        };
        return Success(result);
    }
}

export const GetPolicyFilter: UseCaseClass<GetPolicyFilter> = GetPolicyFilterUseCase;
