import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import {
    BrokerRepository,
    GetInvitationStatusRequest,
    GetInvitationStatusResponse,
} from '../repositories';

export interface GetInvitationStatus extends UseCase {
    execute(
        request: GetInvitationStatusRequest,
    ): AsyncResult<GetInvitationStatusResponse, InvalidArgument | OperationFailed>;
}
@injectable()
class GetInvitationStatusUseCase extends UseCase implements GetInvitationStatus {
    public static type = Symbol('UserOrg/GetInvitationStatus');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BrokerRepository) private brokerRepository: BrokerRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: GetInvitationStatusRequest,
    ): AsyncResult<GetInvitationStatusResponse, InvalidArgument | OperationFailed> {
        if (!request.invitationToken) {
            return Failure(
                InvalidArgument({ argument: 'invitationToken', value: request.invitationToken }),
            );
        }

        const result = await this.brokerRepository.getInvitationStatus(request);

        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return Success(result.value);
    }
}

export const GetInvitationStatus: UseCaseClass<GetInvitationStatus> = GetInvitationStatusUseCase;
