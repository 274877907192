import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';

const ClickEventNameList = [
    'New business Return visitor Quote Ready Pop up Displayed',
    'New business Return visitor Quote Ready Pop up _ continue clicked',
    'New business Return visitor Quote Ready Pop up _ start over',
    'New business Return visitor Quote Ready Pop up closed',
    'New business Return visitor Expired Quote Pop up Displayed',
    'New business Return visitor Expired Quote Pop up _ continue clicked',
    'New business Return visitor Expired Quote Pop up _ start over',
    'New business Return visitor Expired Quote Pop up closed',
    'New business Return visitor Quote and App Expired New App Pop up Displayed',
    'New business Return visitor Quote and App Expired New App Pop up _ continue clicked',
    'New business Return visitor Quote and App Expired New App Pop up closed',
] as const;

type SingleValidAndExpiredQuotePopupEventNameList = typeof ClickEventNameList;
export type SingleValidAndExpiredQuotePopupEventName =
    SingleValidAndExpiredQuotePopupEventNameList[number];

export enum SingleValidAndExpiredQuotePopupEventType {
    singleValidQuotePopupDisplayed = 'singleValidQuotePopupDisplayed',
    singleValidQuotePopupContinueClicked = 'singleValidQuotePopupContinueClicked',
    singleValidQuotePopupClosed = 'singleValidQuotePopupClosed',
    singleValidQuotePopupStartOver = 'singleValidQuotePopupStartOver',
    singleExpiredQuotePopupDisplayed = 'expiredQuotePopupDisplayed',
    singleExpiredQuotePopupContinueClicked = 'expiredQuotePopupContinueClicked',
    singleExpiredQuotePopupClosed = 'expiredQuotePopupClosed',
    singleExpiredQuotePopupStartOver = 'expiredQuotePopupStartOver',
    appExpiredSingleExpiredQuoteDisplayed = 'appExpiredSingleExpiredQuoteDisplayed',
    appExpiredSingleExpiredQuoteContinueClicked = 'appExpiredSingleExpiredQuoteContinueClicked',
    appExpiredSingleExpiredQuoteClosed = 'appExpiredSingleExpiredQuoteClosed',
}

export interface SingleValidAndExpiredQuotePopupEvent extends DomainEvent {
    name: 'SingleValidAndExpiredQuotePopupEvent';
    clickEventName: SingleValidAndExpiredQuotePopupEventName;
}

export interface PublishSingleValidAndExpiredQuotePopupEvent extends UseCase {
    execute(request: PublishSingleValidAndExpiredQuotePopupEventRequest): AsyncResult<void>;
}

export interface PublishSingleValidAndExpiredQuotePopupEventRequest {
    eventType: SingleValidAndExpiredQuotePopupEventType;
}

@injectable()
class PublishSingleValidAndExpiredQuotePopupEventUseCase
    extends UseCase
    implements PublishSingleValidAndExpiredQuotePopupEvent
{
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/PublishSingleValidAndExpiredQuotePopupEventUseCase');

    /**
     * Constructor for PublishSingleValidAndExpiredQuotePopupEvent class instance
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    /**
     * Executes the PublishSingleValidAndExpiredQuotePopupEvent use case.
     */
    public async execute({
        eventType,
    }: PublishSingleValidAndExpiredQuotePopupEventRequest): AsyncResult<void> {
        let clickEventName: SingleValidAndExpiredQuotePopupEventName | null = null;

        switch (eventType) {
            case SingleValidAndExpiredQuotePopupEventType.singleValidQuotePopupDisplayed: {
                clickEventName = 'New business Return visitor Quote Ready Pop up Displayed';
                break;
            }
            case SingleValidAndExpiredQuotePopupEventType.singleValidQuotePopupContinueClicked: {
                clickEventName =
                    'New business Return visitor Quote Ready Pop up _ continue clicked';
                break;
            }
            case SingleValidAndExpiredQuotePopupEventType.singleValidQuotePopupStartOver: {
                clickEventName = 'New business Return visitor Quote Ready Pop up _ start over';
                break;
            }
            case SingleValidAndExpiredQuotePopupEventType.singleValidQuotePopupClosed: {
                clickEventName = 'New business Return visitor Quote Ready Pop up closed';
                break;
            }
            case SingleValidAndExpiredQuotePopupEventType.singleExpiredQuotePopupDisplayed: {
                clickEventName = 'New business Return visitor Expired Quote Pop up Displayed';
                break;
            }
            case SingleValidAndExpiredQuotePopupEventType.singleExpiredQuotePopupContinueClicked: {
                clickEventName =
                    'New business Return visitor Expired Quote Pop up _ continue clicked';
                break;
            }
            case SingleValidAndExpiredQuotePopupEventType.singleExpiredQuotePopupStartOver: {
                clickEventName = 'New business Return visitor Expired Quote Pop up _ start over';
                break;
            }
            case SingleValidAndExpiredQuotePopupEventType.singleExpiredQuotePopupClosed: {
                clickEventName = 'New business Return visitor Expired Quote Pop up closed';
                break;
            }
            case SingleValidAndExpiredQuotePopupEventType.appExpiredSingleExpiredQuoteDisplayed: {
                clickEventName =
                    'New business Return visitor Quote and App Expired New App Pop up Displayed';
                break;
            }
            case SingleValidAndExpiredQuotePopupEventType.appExpiredSingleExpiredQuoteContinueClicked: {
                clickEventName =
                    'New business Return visitor Quote and App Expired New App Pop up _ continue clicked';
                break;
            }
            case SingleValidAndExpiredQuotePopupEventType.appExpiredSingleExpiredQuoteClosed: {
                clickEventName =
                    'New business Return visitor Quote and App Expired New App Pop up closed';
                break;
            }
        }

        if (clickEventName) {
            const event: SingleValidAndExpiredQuotePopupEvent = {
                origin: 'User',
                name: 'SingleValidAndExpiredQuotePopupEvent',
                clickEventName,
                createdAt: new Date(Date.now()),
                id: UUID.create(),
            };
            await this.eventBus.publish(event);
        }

        return Success();
    }
}

export const PublishSingleValidAndExpiredQuotePopupEvent: UseCaseClass<PublishSingleValidAndExpiredQuotePopupEvent> =
    PublishSingleValidAndExpiredQuotePopupEventUseCase;
