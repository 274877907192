import { TextButton } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { DocumentsItemComponentProp } from './BundleQuoteLandingPage';

export function DownloadDocumentButton({
    isSubmitting,
    handleTrigger,
    isDisabled,
    displayName,
}: DocumentsItemComponentProp) {
    return (
        <TextButton
            as="button"
            disabled={isDisabled || isSubmitting}
            icon="download"
            color="primary-500"
            onClick={handleTrigger}
            data-e2e="document-button"
        >
            {displayName}
        </TextButton>
    );
}
