import { USD } from '@embroker/shotwell/core/types/Money';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { InvoiceTable } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { AppTypeLabelMap } from '../../../../shopping/types/enums';
import { CNAQuote } from '../../entities/CNAQuote';

export interface QuoteDetailsProps {
    quote: CNAQuote;
}

export const QuoteDetails = function QuoteDetails({ quote }: QuoteDetailsProps) {
    const showAnnualTechFee = (quote.annualTechnologyFee?.amount ?? 0) > 0;

    return (
        <InvoiceTable>
            <InvoiceTable.Section>
                <InvoiceTable.Subtotal data-e2e="selected-coverages-list" title="Coverages" />
                <InvoiceTable.Item title={AppTypeLabelMap['AppTypeCodeListCNABOP']}>
                    <MoneyDisplay value={quote.totalPremium} />
                </InvoiceTable.Item>
                {showAnnualTechFee && (
                    <InvoiceTable.Item
                        title="Annual technology fee (excluding transaction fees based on your choice of payment)"
                        tooltip="The Annual technology fee is applied once a year with the first policy purchase of the year."
                    >
                        <MoneyDisplay value={quote.annualTechnologyFee ?? USD(0)} />
                    </InvoiceTable.Item>
                )}
            </InvoiceTable.Section>
            <InvoiceTable.Section>
                <InvoiceTable.Total title="Total: ">
                    <MoneyDisplay value={quote.totalPayable} />
                </InvoiceTable.Total>
            </InvoiceTable.Section>
        </InvoiceTable>
    );
};
