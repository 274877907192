import { InsuredTypeCodeList, InsuredTypeCodeListItem } from '@embroker/shotwell-api/enums';
import {
    defineValidator,
    Joi,
    Schema,
    TypeChecker,
    Validator,
} from '@embroker/shotwell/core/validation/schema';

/**
 * Represents Insured Party object
 */
export interface InsuredParty {
    // id: UUID; FIXME: this is under big question mark
    /**
     * Name of insured party
     */
    name: string;
    /**
     * Code explaining relations between
     * policy and insured party
     */
    typeCode: InsuredTypeCodeListItem;
}

export interface InsuredPartyValidator {
    /**
     * A Joi schema matching a valid InsuredParty object.
     */
    readonly schema: Schema.ObjectSchema<InsuredParty>;
    /**
     * Type predicate that checks if a given value can be used as InsuredParty object.
     *
     * Use this only to do early returns. It's recommended to use validate()
     * before using an unknown value as InsuredParty object as it normalizes the value
     * in addition to performing the same validation as check().
     */
    readonly check: TypeChecker<InsuredParty>;
    /**
     * Validates and normalizes the given value to a InsuredParty object.
     *
     * This should be used for all untrusted inputs to verify and, if required
     * (and possible), normalize the input.
     */
    readonly validate: Validator<InsuredParty>;
}

export const InsuredParty = {
    ...defineValidator<InsuredParty>({
        name: Joi.string(),
        typeCode: Joi.string().valid(...InsuredTypeCodeList),
    }),
    create(name: string, typeCode: InsuredTypeCodeListItem) {
        const insuredParty: InsuredParty = { name, typeCode };
        return InsuredParty.validate(insuredParty);
    },
};
