import React from 'react';
import {
    Banner,
    ColumnLayout,
    Text,
    Image,
    StackLayout,
    useResponsive,
} from '@embroker/ui-toolkit/v2';

interface TechVerticalProductSelectionPageBannerProps {
    className?: string;
}

export function TechVerticalProductSelectionPageBanner({
    className,
}: TechVerticalProductSelectionPageBannerProps) {
    const isMobile = useResponsive({ screenWidth: { smallerThan: 'large-tablet' } });

    const content = (
        <React.Fragment>
            <Text style="body 1">
                Unlock <strong>up to 10% off Tech E&O</strong> through our partnership with
                SecurityScorecard. Submit your application to get your cybersecurity score — plus a{' '}
                <strong>free cyber risk report</strong> with tips to reduce your business risks.*
            </Text>
            <div>
                <Image width={134} height={35} name="security-scoreboard" />
            </div>
        </React.Fragment>
    );

    return (
        <Banner className={className}>
            {isMobile ? (
                <StackLayout>{content}</StackLayout>
            ) : (
                <ColumnLayout>{content}</ColumnLayout>
            )}
        </Banner>
    );
}
