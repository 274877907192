import React from 'react';
import { UnderlyingCoverageProps } from '../../../view/components/BundleQuoteCoverageList';
import { WCChubbQuote } from '../entities/WCChubbQuote';
import {
    WCChubbEachEmployeeLimit,
    WCChubbPerAccidentLimit,
    WCChubbPolicyLimit,
} from '../types/WCChubbQuoteOptions';
import { WCChubbBundleCoverageView } from './WCChubbBundleCoverageView';

export function WCChubbBundleCoverageOptions(
    underlyingCoverageProps: UnderlyingCoverageProps<WCChubbQuote>,
) {
    return (
        <WCChubbBundleCoverageView
            {...underlyingCoverageProps}
            policyLimitOptions={WCChubbPolicyLimit}
            perAccidentLimitOptions={WCChubbPerAccidentLimit}
            eachEmployeeLimitOptions={WCChubbEachEmployeeLimit}
        />
    );
}
