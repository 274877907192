import type * as APITypes from '@embroker/shotwell-api/app';
import { API, CurrencyMarshaller } from '@embroker/shotwell-api/app';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import {
    AsyncResult,
    Failure,
    FailureResult,
    isErr,
    isOK,
    Result,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { InsuranceApplicationRestrictionRepository } from '.';
import { ExportUWReviewFromAPIResponseFailed, UwReviewFetchFailed } from '../../errors';
import { CoverageRestriction, CoverageTypes } from '../../types/CoverageRestriction';
import { InsuranceApplicationRestriction } from '../../types/InsuranceApplicationRestriction';

@injectable()
export class APIInsuranceApplicationRestrictionRepository
    implements InsuranceApplicationRestrictionRepository
{
    public async get(
        insuranceApplicationId: UUID,
    ): AsyncResult<
        InsuranceApplicationRestriction | undefined,
        InvalidArgument | UwReviewFetchFailed | ExportUWReviewFromAPIResponseFailed
    > {
        const getUWReviewResult = await API.request('shopping/get_uw_review', {
            insurance_application_id: insuranceApplicationId,
        });

        if (isErr(getUWReviewResult)) {
            return Failure(UwReviewFetchFailed());
        }

        const restriction = fromGetUWReviewApiResponse(getUWReviewResult.value?.uw_review);

        if (isErr(restriction)) {
            return Failure(ExportUWReviewFromAPIResponseFailed());
        }

        return Success(restriction.value);
    }
}

function fromGetUWReviewApiResponse(
    apiRestriction?: Immutable<APITypes.UwReview>,
): Result<InsuranceApplicationRestriction | undefined> {
    if (!apiRestriction) {
        return Success(undefined);
    }
    let failedCoverageRestriction: FailureResult<InvalidArgument> | undefined = undefined;
    const coverageRestrictions =
        apiRestriction.allowed_app_quote_options.allowed_coverage_quote_options.reduce(
            (acc: CoverageRestriction[], apiCoverageRestriction) => {
                const coverageType = CoverageTypes.find(
                    (coverageType) => coverageType === apiCoverageRestriction.coverage_type,
                );
                if (!coverageType) {
                    return acc;
                }
                const coverageRestriction = CoverageRestriction.create({
                    coverageType: coverageType,
                    maxLimit: CurrencyMarshaller.unmarshal(
                        apiCoverageRestriction.limit.max['MultiValueLimitNameListSingleLimit'],
                    ),
                    minRetention: CurrencyMarshaller.unmarshal(
                        apiCoverageRestriction.retention?.min,
                    ),
                    allowPlus: apiCoverageRestriction.allow_plus ?? true,
                    allowCoverage: apiCoverageRestriction.allow_renewal ?? true,
                });

                if (isOK(coverageRestriction)) {
                    acc.push(coverageRestriction.value);
                } else {
                    failedCoverageRestriction = coverageRestriction;
                }

                return acc;
            },
            [],
        );

    if (failedCoverageRestriction !== undefined) {
        return failedCoverageRestriction;
    }

    return InsuranceApplicationRestriction.create({
        insuranceApplicationId: apiRestriction.insurance_application_id,
        coverageRestrictions: coverageRestrictions,
        areManualRestrictionsApplied: apiRestriction.are_manual_restrictions_applied ?? false,
    });
}
