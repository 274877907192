import { Nullable } from '@embroker/shotwell/core/types';
import { State } from '@embroker/shotwell/core/types/StateList';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    Form,
    Modal,
    ReactProps,
    StackLayout,
    Text,
    TextButton,
    useModal,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { WizardForm } from '../../../../../view/hooks/useWizardForm';
import { ESPRenewalQuote } from '../../../entities/ESPRenewalQuote';
import { ESPRenewalQuoteOptionsFormData } from '../ESPRenewalQuoteLandingPage';
import { WarrantyAndFraudModalContent } from './WarrantyAndFraudModalContent';
import { SignInsuranceApplicationCheckbox } from '../../../../view/components/signature/SignInsuranceApplicationCheckbox';

interface ESPRenewalUserInfo {
    company: string;
    fullName: string;
    title: string;
    usaState: Nullable<State>;
}

interface QuoteSignatureProps extends ReactProps<'div'> {
    quote: ESPRenewalQuote;
    fields: WizardForm<OpaqueForm<ESPRenewalQuoteOptionsFormData>>['fields'];
    setValue: WizardForm<OpaqueForm<ESPRenewalQuoteOptionsFormData>>['setValue'];
    value: WizardForm<OpaqueForm<ESPRenewalQuoteOptionsFormData>>['value'];
    trigger: WizardForm<OpaqueForm<ESPRenewalQuoteOptionsFormData>>['trigger'];
}

export const QuoteSignature = function QuoteSignature({
    quote,
    fields,
    setValue,
    value,
    trigger,
    className,
}: QuoteSignatureProps) {
    const warrantyAndFraudModal = useModal();

    const userInfo = quote.userInfo as ESPRenewalUserInfo;

    return (
        <div className={className}>
            <StackLayout gap="32">
                <Text style="heading 4">Please agree to the statements below</Text>
                <Form.Field
                    type={fields.agreementToConductSignature.type}
                    messages={fields.agreementToConductSignature.messages}
                    inputProps={{
                        checked: fields.agreementToConductSignature.props.value,
                        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                            setValue({
                                ...value,
                                agreementToConductSignature: event.target.checked,
                            });
                            trigger('sign');
                        },
                        children: (
                            <SignInsuranceApplicationCheckbox
                                company={userInfo.company}
                                fullName={userInfo.fullName}
                                usaState={userInfo.usaState}
                            />
                        ),
                    }}
                    data-e2e="agreement-to-conduct-signature"
                />
                <Form.Field
                    type={fields.warrantyAndFraudSignature.type}
                    messages={fields.warrantyAndFraudSignature.messages}
                    inputProps={{
                        checked: fields.warrantyAndFraudSignature.props.value,
                        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                            setValue({ ...value, warrantyAndFraudSignature: event.target.checked });
                            trigger('sign');
                        },
                        children: (
                            <Text as="span">
                                By checking this box, you certify all information provided in the
                                insurance application is true and correct and acknowledge that you
                                have read and agree to the{' '}
                                <TextButton onClick={warrantyAndFraudModal.show}>
                                    Warranty and Fraud Statement
                                </TextButton>
                            </Text>
                        ),
                    }}
                    data-e2e="warranty-and-fraud-signature"
                />
                <Modal {...warrantyAndFraudModal} size="medium">
                    <WarrantyAndFraudModalContent
                        fullName={userInfo.fullName}
                        title={userInfo.title}
                        usaState={userInfo.usaState}
                    />
                </Modal>
            </StackLayout>
        </div>
    );
};
