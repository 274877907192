import React, { useEffect } from 'react';
import {
    Text,
    PageLayout,
    StackLayout,
    Image,
    BoxLayout,
    CoverLayout,
} from '@embroker/ui-toolkit/v2';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { useBackgroundColor } from '../../../view/hooks/useBackgroundColor';
import { URI } from '@embroker/shotwell/core/types/URI';

export const BundleQuoteThankYou = () => {
    useBackgroundColor('ui-200');
    const { navigate } = useNavigation();

    useEffect(() => {
        const interval = setInterval(() => {
            navigate(URI.build('/payments'));
        }, 3000);

        return () => clearInterval(interval);
    }, [navigate]);

    return (
        <PageLayout.Section>
            <CoverLayout>
                <StackLayout gap="64" center>
                    <BoxLayout className="u-2/3@large-tablet u-1/1">
                        <Image name="illustration-rocket" width="100%" />
                    </BoxLayout>
                    <Text
                        as="span"
                        style="heading 1"
                        data-e2e="redirect-to-payments"
                        className="c-heading--center"
                    >
                        Thank you! We’ll direct you to payments now.
                    </Text>
                </StackLayout>
            </CoverLayout>
        </PageLayout.Section>
    );
};
