import { inject, injectable } from '@embroker/shotwell/core/di';
import { OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { QuoteRepository } from '../../pcoml/repositories/QuoteRepository';

export interface RemoveSignaturePacketRequest {
    applicationId: UUID;
    storageLocation: string;
}

export interface RemoveSignaturePacket extends UseCase {
    execute(request: RemoveSignaturePacketRequest): AsyncResult<void, OperationFailed>;
}

@injectable()
export class RemoveSignaturePacketUseCase extends UseCase implements RemoveSignaturePacket {
    public static type = Symbol('Broker/RemoveSignaturePacket');
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(QuoteRepository) private quoteRepository: QuoteRepository,
    ) {
        super(eventBus);
    }

    async execute({
        applicationId,
        storageLocation,
    }: RemoveSignaturePacketRequest): AsyncResult<void, OperationFailed> {
        const result = await this.quoteRepository.removeSignaturePacket({
            applicationId,
            storageLocation,
        });

        if (isErr(result)) {
            return Failure(
                OperationFailed({
                    message: 'Failed to remove signature packet document',
                }),
            );
        }

        return Success();
    }
}

export const RemoveSignaturePacket: UseCaseClass<RemoveSignaturePacket> =
    RemoveSignaturePacketUseCase;
