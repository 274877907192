import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed, Timeout, Aborted } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Money } from '@embroker/shotwell/core/types/Money';
import { AsyncResult, isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ESPEndorsementRepository } from '../repositories/ESPEndorsementRepository';
import { TasksRepository } from '../../../tasks/repositories';
import { EditESPEndorsementLiabilityCoverageType } from '../types/ESPEndorsementLiabilityCoverageType';
import { ESPEndorsementPolicy } from '../types/ESPEndorsementPolicy';
import { DateNotInSequence, NoChanges, handleCreateEndorsementTaskFailure } from '../../errors';

export interface EditEndorsementCoverage {
    coverageTypeCode: EditESPEndorsementLiabilityCoverageType;
    limit: Money;
    retention: Money;
}

export interface EditMultipleCoverageESPEndorsementRequest {
    agreementId: UUID;
    coverages: EditEndorsementCoverage[];
    effectiveDate: Date;
    abortSignal: AbortSignal;
    maxPollingRetries?: number;
    pollingRetryIntervalInMilliseconds?: number;
}

export interface EditMultipleCoverageESPEndorsement extends UseCase {
    execute(
        request: EditMultipleCoverageESPEndorsementRequest,
    ): AsyncResult<
        ESPEndorsementPolicy,
        OperationFailed | InvalidArgument | DateNotInSequence | NoChanges | Timeout | Aborted
    >;
}

@injectable()
class EditMultipleCoverageESPEndorsementUseCase
    extends UseCase
    implements EditMultipleCoverageESPEndorsement
{
    public static type = Symbol('ESPEndorsement/EditCoverageESPEndorsement');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ESPEndorsementRepository)
        private espEndorsementRepository: ESPEndorsementRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        agreementId,
        coverages,
        effectiveDate,
        abortSignal,
        maxPollingRetries = 120,
        pollingRetryIntervalInMilliseconds = 1000,
    }: EditMultipleCoverageESPEndorsementRequest): AsyncResult<
        ESPEndorsementPolicy,
        OperationFailed | InvalidArgument | DateNotInSequence | NoChanges | Timeout | Aborted
    > {
        const createEndorsementResult =
            await this.espEndorsementRepository.createEditMultipleCoverageEndorsement({
                agreementId: agreementId,
                effectiveDate: effectiveDate,
                coverages: coverages,
            });
        if (isErr(createEndorsementResult)) {
            return createEndorsementResult;
        }
        const pollForTaskStatusResult = await this.tasksRepository.pollForTaskStatus(
            createEndorsementResult.value,
            abortSignal,
            maxPollingRetries,
            pollingRetryIntervalInMilliseconds,
        );
        if (isErr(pollForTaskStatusResult)) {
            return handleCreateEndorsementTaskFailure(pollForTaskStatusResult);
        }
        return await this.espEndorsementRepository.loadPolicy(agreementId);
    }
}

export const EditMultipleCoverageESPEndorsement: UseCaseClass<EditMultipleCoverageESPEndorsement> =
    EditMultipleCoverageESPEndorsementUseCase;
