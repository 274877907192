import { inject, injectable } from '@embroker/shotwell/core/di';
import {
    InvalidArgument,
    NotAllowed,
    OperationFailed,
    UnknownEntity,
} from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { CalculateSKU } from '../../../analytics/useCases/CalculateSKU';
import { CyberQuote, CyberQuotePurchased } from '../entities/CyberQuote';
import { CyberQuoteRepository } from '../repositories/CyberQuoteRepository';

export interface PurchaseCyberRequest {
    quote: CyberQuote;
}

export interface PurchaseCyberResponse {
    policyId: UUID;
    policyDoneTaskId: UUID;
}

export interface PurchaseCyber extends UseCase {
    execute(
        request: PurchaseCyberRequest,
    ): AsyncResult<
        PurchaseCyberResponse,
        NotAllowed | OperationFailed | InvalidArgument | UnknownEntity
    >;
}

@injectable()
class PurchaseCyberUseCase extends UseCase implements PurchaseCyber {
    public static type = Symbol('CyberQuote/PurchaseCyber');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(CyberQuoteRepository) private cyberQuoteRepository: CyberQuoteRepository,
        @inject(CalculateSKU.type) private calculateSku: CalculateSKU,
    ) {
        super(eventBus);
    }

    public async execute({
        quote,
    }: PurchaseCyberRequest): AsyncResult<
        PurchaseCyberResponse,
        NotAllowed | OperationFailed | InvalidArgument | UnknownEntity
    > {
        const acceptResult = quote.accept();
        if (isErr(acceptResult)) {
            return acceptResult;
        }

        const result = await this.cyberQuoteRepository.purchaseCyber(quote.applicationId, quote.id);
        if (isErr(result)) {
            return result;
        }

        const skuResult = await this.calculateSku.execute({
            event: 'purchase',
            applicationId: quote.applicationId,
        });

        const purchaseEvent: CyberQuotePurchased = {
            id: quote.id,
            origin: 'CyberQuote',
            name: 'Purchased',
            createdAt: new Date(Date.now()),
            totalPremium: quote.totalPremium,
            applicationId: quote.applicationId,
            sku: skuResult.value,
            isRenewal: quote.isRenewal,
        };
        await this.eventBus.publish(purchaseEvent);

        return Success({
            policyId: result.value.policyId,
            policyDoneTaskId: result.value.policyDoneTaskId,
        });
    }
}

export const PurchaseCyber: UseCaseClass<PurchaseCyber> = PurchaseCyberUseCase;
