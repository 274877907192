import { API } from '@embroker/shotwell-api/app';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { EmbrokerExcessConfigRepository } from './index';

@injectable()
export class APIEmbrokerExcessConfigRepository implements EmbrokerExcessConfigRepository {
    private config?: { isEmbrokerExcessEnabled: boolean };

    async isEmbrokerExcessEnabled(): AsyncResult<boolean, InvalidArgument | OperationFailed> {
        if (this.config !== undefined) {
            return Success(this.config.isEmbrokerExcessEnabled);
        }
        const getConfigResponse = await API.request('global/get_config');

        if (isErr(getConfigResponse)) {
            return handleOperationFailure(getConfigResponse);
        }

        this.config = {
            isEmbrokerExcessEnabled: getConfigResponse.value.is_embroker_excess_enabled,
        };

        return Success(getConfigResponse.value.is_embroker_excess_enabled);
    }
}
