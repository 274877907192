import { State } from '@embroker/shotwell/core/types/StateList';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface ESPEndorsementUserData {
    company: string;
    fullName: string;
    title: string;
    usaState?: State;
}

export const ESPEndorsementUserData = {
    ...defineValidator<ESPEndorsementUserData>({
        company: Joi.string().required(),
        fullName: Joi.string().required(),
        title: Joi.string().allow('').required(),
        usaState: State.schema.optional(),
    }),
    create(userData: ESPEndorsementUserData) {
        return ESPEndorsementUserData.validate(userData);
    },
};
