import { errorCodes, isError } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { ErrorLike, ErrorObject } from '@embroker/shotwell/core/types/Result';

export const ErrorCode = errorCodes({
    /**
     * State agent license not found
     */
    StateAgentLicenseNotFound: 0x1504,
    /**
     * Failed to fetch config
     */
    ConfigFetchFailed: 0x6000,
    /**
     * Failed to fetch config
     */
    GetRestrictionFailed: 0x6001,
});

export type StateAgentLicenseNotFound = ErrorObject<typeof ErrorCode.StateAgentLicenseNotFound, {}>;

export function StateAgentLicenseNotFound(): Immutable<StateAgentLicenseNotFound> {
    return {
        name: 'StateAgentLicenseNotFound',
        code: ErrorCode.StateAgentLicenseNotFound,
        message: "No agent license found for the client's state.",
        details: {},
    };
}

export function isStateUnlicensedError(error: ErrorLike) {
    return isError(ErrorCode.StateAgentLicenseNotFound, error);
}

export type ConfigFetchFailed = ErrorObject<typeof ErrorCode.ConfigFetchFailed, {}>;

export function ConfigFetchFailed(): Immutable<ConfigFetchFailed> {
    return {
        name: 'ConfigFetchFailed',
        code: ErrorCode.ConfigFetchFailed,
        message: 'Failed to fetch config API.',
        details: {},
    };
}

export type GetRestrictionFailed = ErrorObject<typeof ErrorCode.GetRestrictionFailed, {}>;

export function GetRestrictionFailed(): Immutable<GetRestrictionFailed> {
    return {
        name: 'GetRestrictionFailed',
        code: ErrorCode.GetRestrictionFailed,
        message: 'Failed to fetch restriction.',
        details: {},
    };
}
