import { mount } from 'navi';
import { AppContext } from '../../../view/AppContext';
import { RouteDefinitions, group } from '../../../view/routes/Route';
import { bopCnaRoutes } from './bopCnaRoutes';
import { espRenewalRoutes } from './espRenewalRoutes';
import { espRoutes } from './espRoutes';
import { lplRoutes } from './lplRoutes';
import { pcomlRoutes } from './pcomlRoutes';
import { wcgaRoutes } from './wcgaRoutes';
import { crimeRoutes } from './crimeRoutes';
import { cyberRoutes } from './cyberRoutes';
import { embrokerExcessRoutes } from './embrokerExcessRoutes';
import { bopHartfordRoutes } from '@app/quote/view/routes/bopHartfordRoutes';

export const routes: RouteDefinitions<AppContext> = {
    '/shopping/application/quote': group({
        '/lpl': mount(lplRoutes),
        '/wcga': mount(wcgaRoutes),
        '/esp': mount(espRoutes),
        '/pcoml': mount(pcomlRoutes),
        '/bopcna': mount(bopCnaRoutes),
        '/esp-renewals': mount(espRenewalRoutes),
        '/crime': mount(crimeRoutes),
        '/cyber': mount(cyberRoutes),
        '/excess': mount(embrokerExcessRoutes),
        '/bop-hartford': mount(bopHartfordRoutes),
    }),
};
