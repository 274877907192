import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { GetUserEnrolledFeaturesError } from '../errors';
import { UUID } from '@embroker/shotwell/core/types/UUID';

export interface GetEnrolledUserFeaturesRequest {
    tenantId?: UUID;
}

export interface GetEnrolledUserFeaturesResponse {
    featuresEnrolled: string[];
}

export interface FeatureRolloutRepository {
    getUserEnrolledFeatures(): AsyncResult<
        GetEnrolledUserFeaturesResponse,
        GetUserEnrolledFeaturesError
    >;
}

export const FeatureRolloutRepository = Symbol('FeatureRolloutRepository');
