import { Modal, ModalActions, ModalState } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { AgreementToConductModalContent } from './modals/AgreementToConductModalContent';

export interface AgreementToConductModalProps {
    stateAndActions: ModalState & ModalActions;
}

export function AgreementToConductModal({ stateAndActions }: AgreementToConductModalProps) {
    return (
        <Modal {...stateAndActions} size="large">
            <AgreementToConductModalContent />
        </Modal>
    );
}
