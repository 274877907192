import { StackLayout } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { CoverageCatalog } from '../../CoverageCatalog';
import { BundleCoverageAppStatusEnum } from '../../types/BundleQuoteCoverage';
import { BundleQuoteFormData } from '../../types/BundleQuoteFormData';
import { BundleQuoteCoverageProps } from './BundleQuoteCoverage';

export function BundleQuoteCoverageMultiCoverage<T extends BundleQuoteFormData>({
    coverage,
    organizationInfo,
    fields,
    value,
    isDirty = false,
    quoteHasExpired,
    appHasExpired,
    trigger,
    setValue,
    handleCoverageSelectToggle,
    isSelected,
    isReferred,
    isNotEligible,
}: BundleQuoteCoverageProps<T>) {
    const { quote, type, status, questionnaireData } = coverage;
    const isReferredAwaitingReview = status === BundleCoverageAppStatusEnum.ReferredAwaitingReview;

    const isCoverageDisabled =
        !isSelected ||
        quoteHasExpired ||
        isReferredAwaitingReview ||
        appHasExpired ||
        isNotEligible;

    const CoverageOptionsComponent = CoverageCatalog.getCoverageOptionsComponent(type);
    const CoverageFooterComponent = CoverageCatalog.getCoverageFooterComponent(type);
    const isCoverageOptionsComponentHidden =
        !CoverageOptionsComponent ||
        !quote ||
        !questionnaireData ||
        !CoverageCatalog.isValueValidType(value, coverage.type) ||
        !CoverageCatalog.isQuoteValidType(quote, coverage.type) ||
        !CoverageCatalog.isQuestionnaireDataValidType(questionnaireData, coverage.type) ||
        !CoverageCatalog.isFieldsValidType(fields, coverage.type);

    return !isCoverageOptionsComponentHidden ? (
        <StackLayout gap="12">
            <CoverageOptionsComponent
                quote={quote}
                questionnaireData={questionnaireData}
                fields={fields}
                setValue={setValue}
                value={value}
                organizationInfo={organizationInfo}
                trigger={trigger}
                disabled={isCoverageDisabled}
            />
            {CoverageFooterComponent ? (
                <CoverageFooterComponent questionnaireData={questionnaireData} quote={quote} />
            ) : null}
        </StackLayout>
    ) : null;
}
