import { Modal, ModalActions, ModalState, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { ModalLayout } from '../../../../view/components/ModalLayout.view';

export function BrokerClientReservationModal({ modal }: { modal: ModalActions & ModalState }) {
    const { navigate } = useNavigation();

    return (
        <Modal {...modal} size="small" dismissable={false}>
            <ModalLayout
                title="Something’s missing here."
                primaryAction={{
                    label: 'Back to Dashboard',
                    onClick: () => navigate('/broker/dashboard'),
                }}
                secondaryAction={{ label: 'Open Live Chat', href: '#chat-now' }}
            >
                <StackLayout gap="24">
                    <Text>
                        It looks like we've already received this submission. To proceed we'll need
                        a signed Broker of Record letter. Please contact us if you have any
                        questions or need more information.
                    </Text>
                    <Text>
                        Or you can contact us directly via live chat or call us at 844.436.2765
                    </Text>
                </StackLayout>
            </ModalLayout>
        </Modal>
    );
}
