import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { PhoneNumber } from '@embroker/shotwell/core/types/PhoneNumber';
import { AsyncResult, Failure, isErr, Result, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { Immutable } from '@embroker/ui-toolkit/v2';
import { Policy } from '../../policy/entities/Policy';
import { WritingCompany } from '../../policy/entities/WritingCompany';
import { GetWritingCompanyListError, OneOrMoreWritingCompanyNotFound } from '../../policy/errors';
import { PolicyRepository } from '../../policy/repositories/PolicyRepository';
import { PolicySortByStartDate } from '../../policy/types/PolicySortBy';
import { DirectBillPolicy } from '../types/DirectBillPolicy';

export interface GetDirectBillPoliciesResponse {
    policyList: Immutable<DirectBillPolicy[]>;
}

export const GetDirectBillPoliciesResponse = {
    ...defineValidator<GetDirectBillPoliciesResponse>({
        policyList: Joi.array().items(DirectBillPolicy.schema),
    }),
    create(getDirectBillPoliciesResponse: GetDirectBillPoliciesResponse) {
        return GetDirectBillPoliciesResponse.validate(getDirectBillPoliciesResponse);
    },
};

export interface GetDirectBillPolicies extends UseCase {
    execute(): AsyncResult<
        GetDirectBillPoliciesResponse,
        | UnknownEntity
        | InvalidArgument
        | OperationFailed
        | GetWritingCompanyListError
        | OneOrMoreWritingCompanyNotFound
    >;
}

function listToDirectBillPolicy(
    policies: Immutable<Policy>[],
    writingCompanyList: Immutable<WritingCompany[]>,
): Result<DirectBillPolicy[], UnknownEntity> {
    const result: DirectBillPolicy[] = [];
    for (const policy of policies) {
        const writingCompany = writingCompanyList.find((writingCompany) => {
            if (policy.insurerId === writingCompany.id) {
                return writingCompany;
            }
        });

        if (writingCompany === undefined) {
            return Failure(UnknownEntity('insurer ID', policy.insurerId as UUID));
        }

        result.push(toDirectBillPolicy(policy, writingCompany));
    }
    return Success(result);
}

function toDirectBillPolicy(
    policy: Immutable<Policy>,
    writingCompany: Immutable<WritingCompany>,
): DirectBillPolicy {
    return {
        displayName: policy.name,
        policyNumber: policy.policyNumber,
        insurerName: policy.insurerName,
        lineOfBusiness: policy.lineOfBusiness,
        subLineOfBusiness: policy.subLineOfBusiness ?? undefined,
        startDate: policy.startDate,
        endDate: policy.endDate,
        insurerBillingPhone: writingCompany.billingPhone as PhoneNumber,
        insurerBillingURL: writingCompany.billingUrl,
    };
}

@injectable()
export class GetDirectBillPoliciesUseCase extends UseCase {
    public static type = Symbol('Policy/GetDirectBillPolicies');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(PolicyRepository) private policyRepository: PolicyRepository,
    ) {
        super(eventBus);
    }

    async execute(): AsyncResult<
        GetDirectBillPoliciesResponse,
        | UnknownEntity
        | InvalidArgument
        | OperationFailed
        | GetWritingCompanyListError
        | OneOrMoreWritingCompanyNotFound
    > {
        const getPoliciesResult = await this.policyRepository.getPolicies();
        if (isErr(getPoliciesResult)) {
            return getPoliciesResult;
        }

        const filteredPolicyList = getPoliciesResult.value.filter((policy) => {
            return (
                policy.matchFilter({
                    sortBy: PolicySortByStartDate,
                    showActive: true,
                    showInactive: false,
                }) && policy.isDirectBill
            );
        });

        if (filteredPolicyList.length === 0) {
            return Success({ policyList: [] });
        }

        const uniqueWritingCompanyIdList: UUID[] = [];
        filteredPolicyList.forEach((policy) => {
            if (!uniqueWritingCompanyIdList.includes(policy.insurerId)) {
                uniqueWritingCompanyIdList.push(policy.insurerId);
            }
        });

        const directBillWritingCompanyList = await this.policyRepository.getWritingCompanyList(
            uniqueWritingCompanyIdList,
        );

        if (isErr(directBillWritingCompanyList)) {
            return directBillWritingCompanyList;
        }

        const directBillPolicyList = listToDirectBillPolicy(
            filteredPolicyList,
            directBillWritingCompanyList.value,
        );

        if (isErr(directBillPolicyList)) {
            return directBillPolicyList;
        }

        const directBillPoliciesResponseResult = GetDirectBillPoliciesResponse.create({
            policyList: directBillPolicyList.value,
        });

        if (isErr(directBillPoliciesResponseResult)) {
            return directBillPoliciesResponseResult;
        }

        return Success({
            policyList: directBillPoliciesResponseResult.value.policyList,
        });
    }
}

export const GetDirectBillPolicies: UseCaseClass<GetDirectBillPolicies> =
    GetDirectBillPoliciesUseCase;
