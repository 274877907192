import { Money } from '@embroker/shotwell/core/types/Money';
import {
    defineValidator,
    Joi,
    Schema,
    TypeChecker,
    Validator,
} from '@embroker/shotwell/core/validation/schema';

export const ShoppingCoverageCodeListCyber = 'ShoppingCoverageCodeListCyber';
export const ShoppingCoverageCodeListTechSplit = 'ShoppingCoverageCodeListTechSplit';
export const ShoppingCoverageCodeListCyberSplit = 'ShoppingCoverageCodeListCyberSplit';

export const ESPCoverageTypes = [
    'ShoppingCoverageCodeListDirectorsAndOfficers',
    'ShoppingCoverageCodeListEmploymentPractices',
    ShoppingCoverageCodeListCyber,
    ShoppingCoverageCodeListTechSplit,
    ShoppingCoverageCodeListCyberSplit,
    'ShoppingCoverageCodeListFiduciary',
] as const;

export type CoverageType = (typeof ESPCoverageTypes)[number];

export interface CoverageRestriction {
    coverageType: CoverageType;
    maxLimit: Money;
    minRetention: Money;
    increasePremium: number;
    allowCoverage: boolean;
    allowPlus: boolean;
}

export interface CoverageRestrictionValidator {
    /**
     * A Joi schema matching a valid CoverageRestriction object.
     */
    readonly schema: Schema.ObjectSchema<CoverageRestriction>;
    /**
     * Type predicate that checks if a given value can be used as CoverageRestriction object.
     *
     * Use this only to do early returns. It's recommended to use validate()
     * before using an unknown value as CoverageRestriction object as it normalizes the value
     * in addition to performing the same validation as check().
     */
    readonly check: TypeChecker<CoverageRestriction>;
    /**
     * Validates and normalizes the given value to a CoverageRestriction object.
     *
     * This should be used for all untrusted inputs to verify and, if required
     * (and possible), normalize the input.
     */
    readonly validate: Validator<CoverageRestriction>;
}

export const CoverageRestriction = {
    ...defineValidator<CoverageRestriction>({
        coverageType: Joi.string().valid(...ESPCoverageTypes),
        maxLimit: Money.schema,
        minRetention: Money.schema,
        increasePremium: Joi.number().min(-99),
        allowCoverage: Joi.boolean(),
        allowPlus: Joi.boolean(),
    }),
    create(coverageRestriction: CoverageRestriction) {
        return CoverageRestriction.validate(coverageRestriction);
    },
};
