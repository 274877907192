import { Nullable } from '@embroker/shotwell/core/types';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface ClaimDocument {
    id: UUID;
    fileKey: string;
    mimeType: Nullable<string>;
    name: string;
    size: number;
    type: string;
}

export const ClaimDocument = {
    ...defineValidator<ClaimDocument>({
        id: UUID.schema,
        fileKey: Joi.string(),
        mimeType: Joi.string().allow(null),
        name: Joi.string(),
        size: Joi.number(),
        type: Joi.string(),
    }),
    create(claimDocument: ClaimDocument) {
        return ClaimDocument.validate(claimDocument);
    },
};
