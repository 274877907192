import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { UserInvite, UserInvited } from '../entities/UserInvite';
import { UserInviteRepository } from '../repositories/UserInviteRepository';
import { UUID } from '@embroker/shotwell/core/types/UUID';

/**
 * Request data for SendUserInvites use case
 * @param inviteMembers is the list of emails that are being invited to the active organization
 */
interface SendUserInvitesRequest {
    inviteMembers: string[];
}

/**
 * SendUserInvites use case is used to send invites to other users/non-users to join active organization
 */

export interface SendUserInvites extends UseCase {
    execute(request: SendUserInvitesRequest): AsyncResult<void, InvalidArgument | OperationFailed>;
}

@injectable()
class SendUserInvitesUseCase extends UseCase implements SendUserInvites {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/SendUserInvites');
    /**
     * Constructor for SendUserInvites use case class instance
     * @param eventBus An event bus this Use Case will publish events to.
     * @param UserInviteRepository is repository which is to send user invites
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(UserInviteRepository) private userInviteRepository: UserInviteRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes SendUserInvites use case
     * Input is of type SendUserInvitesRequest
     * @returns Nothing if execution was successful
     * @returns OperationFailed is error returned in case that request failed
     * @returns InvalidArgument is error returned in case of bad arguments
     */
    public async execute({
        inviteMembers,
    }: SendUserInvitesRequest): AsyncResult<void, InvalidArgument | OperationFailed> {
        const inviteEmails = inviteMembers.map((item) => item.trim()).filter((item) => item !== '');
        const userInvites: UserInvite[] = [];
        for (const email of inviteEmails) {
            const emailResult = EmailAddress.validate(email);
            if (isErr(emailResult)) {
                return Failure(InvalidArgument({ argument: 'email', value: email }));
            }
            const userInviteResult = await UserInvite.create({
                email: emailResult.value,
                status: 'unset',
                organizationName: null,
                inviteEnabled: true,
                isOrganizationOwner: false,
            });

            if (isErr(userInviteResult)) {
                return handleOperationFailure(userInviteResult);
            }
            userInvites.push(userInviteResult.value);
        }
        if (userInvites.length === 0) {
            return Failure(InvalidArgument({ argument: 'inviteMembers', value: inviteMembers }));
        }
        const sendInvitesResult = await this.userInviteRepository.sendInvites(userInvites);
        if (isErr(sendInvitesResult)) {
            return sendInvitesResult;
        }

        const userInvitedEvent: UserInvited = {
            origin: 'UserInvite',
            name: 'Invited',
            id: UUID.create(),
            createdAt: new Date(Date.now()),
        };

        await this.eventBus.publish(userInvitedEvent);

        return Success();
    }
}

export const SendUserInvites: UseCaseClass<SendUserInvites> = SendUserInvitesUseCase;
