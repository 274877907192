import { UUID } from '@embroker/shotwell/core/types/UUID';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface AdditionalInterest {
    partyID: UUID;
    partyName: string;
    typeCode: string;
}

export const AdditionalInterest = {
    ...defineValidator<AdditionalInterest>({
        partyID: UUID.schema,
        partyName: Joi.string(),
        typeCode: Joi.string(),
    }),
    create(additionalInterest: AdditionalInterest) {
        return AdditionalInterest.validate(additionalInterest);
    },
};
