import { ModalActions, ModalState, Modal, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { ModalLayout } from '../../../view/components/ModalLayout.view';

interface DocGenErrorModalInput {
    readonly modal: ModalActions & ModalState;
}

export function DocGenErrorModal({ modal }: DocGenErrorModalInput) {
    return (
        <Modal {...modal} size="small" dismissable>
            <ModalLayout
                title="Something went wrong here"
                primaryAction={{
                    label: 'Review Quotes',
                    onClick: modal.hide,
                }}
                secondaryAction={{
                    label: 'Open Live Chat',
                    href: '#chat-now',
                }}
            >
                <Text>
                    Please try downloading the document again. If the issues persist, please contact
                    Embroker.
                </Text>
                <Text>Or contact us via live chat or call us at 844.436.2765</Text>
            </ModalLayout>
        </Modal>
    );
}
