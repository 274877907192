import { OracleAnswerTypeDefinition } from '../OracleAnswerType';
import { Joi } from '@embroker/shotwell/core/validation/schema';

export const serializeNumber = (formData: unknown): number | undefined => {
    const number = Number(formData);
    if (!isNaN(number)) {
        return number;
    }
};

export const deserializeNumberAnswers = (currentValue: any): number[] | undefined => {
    if (!Array.isArray(currentValue)) {
        return undefined;
    }
    return (currentValue as number[]).reduce((accumulator, current) => {
        const number = Number(currentValue);
        if (!isNaN(number)) {
            accumulator.push(number);
        }
        return accumulator;
    }, [] as number[]);
};

export const NumberDefinition: OracleAnswerTypeDefinition = {
    answerKeyType: 'number',
    schemaFunctions: {
        validator: Joi.number(),
        serializeAnswer: serializeNumber,
        deserializeAnswer: deserializeNumberAnswers,
    },
};
