import { StackLayout } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { Location, LocationProps } from './Location';

export interface LocationWithMenuProps extends LocationProps {
    /**
     * Menu commands, passed from the controlling component, e.g. a collection
     * of locations with menu
     */
    children?: React.ReactNode;
}

export const LocationWithMenu = ({ children, data, ...other }: LocationWithMenuProps) => {
    return (
        <StackLayout>
            <div>{children}</div>
            <Location {...other} data={data} />
        </StackLayout>
    );
};
