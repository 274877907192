import { OracleAnswerTypeDefinition } from '../OracleAnswerType';
import { Joi } from '@embroker/shotwell/core/validation/schema';

export const serializeText = (formData: unknown): string | undefined => {
    return String(formData);
};

export const deserializeTextAnswers = (currentValue: any): string[] | undefined => {
    if (!Array.isArray(currentValue)) {
        return undefined;
    }
    return currentValue.map((current: any) => String(current));
};

export const TextDefinition: OracleAnswerTypeDefinition = {
    answerKeyType: 'text',
    schemaFunctions: {
        validator: Joi.string().allow(''),
        serializeAnswer: serializeText,
        deserializeAnswer: deserializeTextAnswers,
    },
};
