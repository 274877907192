import { State } from '@embroker/shotwell/core/types/StateList';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface WCClassCode {
    code: string;
    description: string;
    tooltip?: string;
    states: State[];
    include: boolean;
}

export const WCClassCode = {
    ...defineValidator<WCClassCode>({
        code: Joi.string().required(),
        description: Joi.string().required(),
        tooltip: Joi.string().required().allow(''),
        states: Joi.array().items(State.schema),
        include: Joi.bool(),
    }),
    create(classCode: WCClassCode) {
        return WCClassCode.validate(classCode);
    },
};
