import { inject } from '@embroker/shotwell/core/di';
import { OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { Quote } from '../entities/Quote';

export interface DraftQuoteRequest {
    quote: Quote;
}

export interface DraftQuoteResponse {
    quote: Immutable<Quote>;
}

export interface DraftQuote extends UseCase {
    execute(
        request: DraftQuoteRequest,
    ): AsyncResult<DraftQuoteResponse, UnknownEntity | OperationFailed>;
}

class DraftQuoteUseCase extends UseCase implements DraftQuote {
    public static type = Symbol('Quote/DraftQuote');

    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    public async execute({
        quote,
    }: DraftQuoteRequest): AsyncResult<DraftQuoteResponse, UnknownEntity | OperationFailed> {
        const draftResult = quote.draft();

        if (isErr(draftResult)) {
            return Failure(
                OperationFailed({ message: 'Draft quote failed', errors: draftResult.errors }),
            );
        }

        return Success<DraftQuoteResponse>({
            quote,
        });
    }
}

export const DraftQuote: UseCaseClass<DraftQuote> = DraftQuoteUseCase;
