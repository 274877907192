import { useEffect } from 'react';
import { AppContextStore } from '../AppContext';
import { BackgroundColor } from '@embroker/ui-toolkit/v2';

export function useBackgroundColor(backgroundColor: BackgroundColor) {
    useEffect(() => {
        AppContextStore.context.setBackgroundColor(backgroundColor);

        return () => {
            AppContextStore.context.resetBackgroundColor();
        };
    }, [backgroundColor]);
}
