import { Immutable } from '@embroker/shotwell/core/types';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { Card, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { WizardForm } from '../../../../../view/hooks/useWizardForm';
import { PCoMLQuote } from '../../../entities/PCoMLQuote';
import { CoverageRestriction } from '../../../types/CoverageRestriction';
import { InsuranceApplicationRestriction } from '../../../types/InsuranceApplicationRestriction';
import { PCoMLQuoteOptionsFormData } from '../PCoMLQuoteLandingPage';
import { PCoMLSelectedCoverage } from './PCoMLSelectedCoverage';

interface PCoMLSelectedCoverageListProps {
    quote: PCoMLQuote;
    isSubmitting: boolean;
    handleIsDNOSelected: () => void;
    handleIsEPLSelected: () => void;
    fields: WizardForm<OpaqueForm<PCoMLQuoteOptionsFormData>>['fields'];
    restriction?: Immutable<InsuranceApplicationRestriction>;
    showEpliStandaloneInCalifornia?: () => void;
}

export const PCoMLSelectedCoverageList = ({
    quote,
    isSubmitting,
    fields,
    handleIsDNOSelected,
    handleIsEPLSelected,
    restriction,
    showEpliStandaloneInCalifornia,
}: PCoMLSelectedCoverageListProps) => {
    return (
        <StackLayout>
            <Text style="heading 4">Selected Coverages</Text>
            {(fields.isDnoSelected.props.value || fields.isEplSelected.props.value) === false ? (
                <Card center>Add the coverages you're interested in to receive a quote</Card>
            ) : null}
            {fields.isDnoSelected.props.value && quote.options.dno.inShoppingCoverageList && (
                <PCoMLSelectedCoverage
                    type={'dno'}
                    isRenewal={quote.applicationInfo?.isRenewal ?? false}
                    details={quote.details.dno}
                    options={quote.options.dno}
                    isSubmitting={isSubmitting}
                    fields={fields}
                    handleIsSelected={handleIsDNOSelected}
                    newInsurerDocumentsReleaseDate={
                        quote.applicationInfo?.newInsurerDocumentsReleaseDate
                    }
                    submittedAt={quote.applicationInfo?.submittedAt}
                    state={quote.applicationInfo?.userInfo.usaState}
                    coverageRestriction={
                        restriction ? getCoverageRestriction('do', restriction) : undefined
                    }
                    showEpliStandaloneInCalifornia={showEpliStandaloneInCalifornia}
                    naics={quote?.applicationInfo?.naics}
                />
            )}
            {fields.isEplSelected.props.value && quote.options.epl.inShoppingCoverageList && (
                <PCoMLSelectedCoverage
                    type={'epl'}
                    isRenewal={quote.applicationInfo?.isRenewal ?? false}
                    details={quote.details.epl}
                    options={quote.options.epl}
                    isSubmitting={isSubmitting}
                    fields={fields}
                    handleIsSelected={handleIsEPLSelected}
                    newInsurerDocumentsReleaseDate={
                        quote.applicationInfo?.newInsurerDocumentsReleaseDate
                    }
                    submittedAt={quote.applicationInfo?.submittedAt}
                    state={quote.applicationInfo?.userInfo.usaState}
                    coverageRestriction={
                        restriction ? getCoverageRestriction('epl', restriction) : undefined
                    }
                    showEpliStandaloneInCalifornia={showEpliStandaloneInCalifornia}
                    naics={quote?.applicationInfo?.naics}
                />
            )}
        </StackLayout>
    );
};

type coverageType = 'do' | 'epl';

export function getCoverageRestriction(
    coverageType: coverageType,
    restriction: Immutable<InsuranceApplicationRestriction>,
): CoverageRestriction | undefined {
    const coverageRestriction = restriction.coverageRestrictions.find((cr) => {
        switch (coverageType) {
            case 'do':
                return cr.coverageType === 'ShoppingCoverageCodeListDirectorsAndOfficers';
            case 'epl':
                return cr.coverageType === 'ShoppingCoverageCodeListEmploymentPractices';
            default:
                return false;
        }
    });

    return coverageRestriction;
}
