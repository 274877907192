import { USD } from '@embroker/shotwell/core/types/Money';
import { IconName } from '@embroker/ui-toolkit/v2';
import { Data } from '@embroker/shotwell/core/types';
import {
    ESPDNOOldURL,
    ESPDNOPlusURL,
    ESPDNOPlusNewInsurerURL,
    ESPDNOStandardURL,
    ESPDNOStandardNewInsurerURL,
    ESPEoCyberPlusURL,
    ESPEoCyberStandardURL,
    ESPEPLIOldURL,
    ESPEPLIPlusURL,
    ESPEPLIStandardURL,
    ESPEPLIStandardNewInsurerURL,
    ESPEPLIPlusNewInsurerURL,
    ESPFiduciaryStandardURL,
    ESPFiduciaryNewStandardURL,
    ESPTechEOCyberSplitPlusURL,
    ESPDNOPlusPostIPT2024URL,
    ESPDNOStandardPostIPT2024URL,
    ESPEPLIPlusPostIPT2024URL,
    ESPEPLIStandardPostIPT2024URL,
    ESPTechCyberStandardPostIPT2024URL,
    ESPTechCyberPlusPostIPT2024URL,
} from './constants';
import { State } from '@embroker/shotwell/core/types/StateList';

interface ESPQuoteCoverageDetailItem {
    readonly title: string;
    readonly icon: IconName;
    readonly text: string;
    readonly document: Data<string>;
}

interface ESPDocumentsOffsetByState {
    readonly daysToPublish: number;
}

type UsaState = State;

type ESPCoverageType = 'do' | 'epli' | 'eoCyber' | 'fiduciary' | 'techCyber';
type ESPQuoteCoverageDetail = { [k in ESPCoverageType]: ESPQuoteCoverageDetailItem };
type ESPQuoteDocumentsDetails = { [k in UsaState]: ESPDocumentsOffsetByState };

export const renewalQuoteDocumentsPublishDetails: ESPQuoteDocumentsDetails = {
    AR: {
        daysToPublish: 14,
    },
    SC: {
        daysToPublish: 14,
    },
    TX: {
        daysToPublish: 14,
    },
    ME: {
        daysToPublish: 14,
    },
    MS: {
        daysToPublish: 14,
    },
    FL: {
        daysToPublish: 29,
    },
    IN: {
        daysToPublish: 29,
    },
    IA: {
        daysToPublish: 29,
    },
    MA: {
        daysToPublish: 29,
    },
    MT: {
        daysToPublish: 29,
    },
    OK: {
        daysToPublish: 29,
    },
    VT: {
        daysToPublish: 29,
    },
    PA: {
        daysToPublish: 45,
    },
    CT: {
        daysToPublish: 75,
    },
    AL: {
        daysToPublish: 5,
    },
    AK: {
        daysToPublish: 5,
    },
    AZ: {
        daysToPublish: 5,
    },
    CA: {
        daysToPublish: 5,
    },
    CO: {
        daysToPublish: 5,
    },
    DC: {
        daysToPublish: 5,
    },
    DE: {
        daysToPublish: 5,
    },
    GA: {
        daysToPublish: 5,
    },
    HI: {
        daysToPublish: 5,
    },
    ID: {
        daysToPublish: 5,
    },
    IL: {
        daysToPublish: 5,
    },
    KS: {
        daysToPublish: 5,
    },
    KY: {
        daysToPublish: 5,
    },
    LA: {
        daysToPublish: 5,
    },
    MD: {
        daysToPublish: 5,
    },
    MI: {
        daysToPublish: 5,
    },
    MN: {
        daysToPublish: 5,
    },
    MO: {
        daysToPublish: 5,
    },
    NE: {
        daysToPublish: 5,
    },
    NV: {
        daysToPublish: 5,
    },
    NH: {
        daysToPublish: 5,
    },
    NJ: {
        daysToPublish: 5,
    },
    NM: {
        daysToPublish: 5,
    },
    NY: {
        daysToPublish: 5,
    },
    NC: {
        daysToPublish: 5,
    },
    ND: {
        daysToPublish: 5,
    },
    OH: {
        daysToPublish: 5,
    },
    OR: {
        daysToPublish: 5,
    },
    RI: {
        daysToPublish: 5,
    },
    SD: {
        daysToPublish: 5,
    },
    TN: {
        daysToPublish: 5,
    },
    UT: {
        daysToPublish: 5,
    },
    VA: {
        daysToPublish: 5,
    },
    WA: {
        daysToPublish: 5,
    },
    WV: {
        daysToPublish: 5,
    },
    WI: {
        daysToPublish: 5,
    },
    WY: {
        daysToPublish: 5,
    },
};

export const fiduciaryQuoteDocumentsPublishDetails: ESPQuoteDocumentsDetails = {
    AR: {
        daysToPublish: 0,
    },
    SC: {
        daysToPublish: 0,
    },
    TX: {
        daysToPublish: 0,
    },
    ME: {
        daysToPublish: 0,
    },
    MS: {
        daysToPublish: 0,
    },
    FL: {
        daysToPublish: 14,
    },
    IN: {
        daysToPublish: 14,
    },
    IA: {
        daysToPublish: 14,
    },
    MA: {
        daysToPublish: 14,
    },
    MT: {
        daysToPublish: 14,
    },
    OK: {
        daysToPublish: 14,
    },
    VT: {
        daysToPublish: 14,
    },
    PA: {
        daysToPublish: 30,
    },
    CT: {
        daysToPublish: 60,
    },
    AL: {
        daysToPublish: 0,
    },
    AK: {
        daysToPublish: 0,
    },
    AZ: {
        daysToPublish: 0,
    },
    CA: {
        daysToPublish: 0,
    },
    CO: {
        daysToPublish: 0,
    },
    DC: {
        daysToPublish: 0,
    },
    DE: {
        daysToPublish: 0,
    },
    GA: {
        daysToPublish: 0,
    },
    HI: {
        daysToPublish: 0,
    },
    ID: {
        daysToPublish: 0,
    },
    IL: {
        daysToPublish: 0,
    },
    KS: {
        daysToPublish: 0,
    },
    KY: {
        daysToPublish: 0,
    },
    LA: {
        daysToPublish: 0,
    },
    MD: {
        daysToPublish: 0,
    },
    MI: {
        daysToPublish: 0,
    },
    MN: {
        daysToPublish: 0,
    },
    MO: {
        daysToPublish: 0,
    },
    NE: {
        daysToPublish: 0,
    },
    NV: {
        daysToPublish: 0,
    },
    NH: {
        daysToPublish: 0,
    },
    NJ: {
        daysToPublish: 0,
    },
    NM: {
        daysToPublish: 0,
    },
    NY: {
        daysToPublish: 0,
    },
    NC: {
        daysToPublish: 0,
    },
    ND: {
        daysToPublish: 0,
    },
    OH: {
        daysToPublish: 0,
    },
    OR: {
        daysToPublish: 0,
    },
    RI: {
        daysToPublish: 0,
    },
    SD: {
        daysToPublish: 0,
    },
    TN: {
        daysToPublish: 0,
    },
    UT: {
        daysToPublish: 0,
    },
    VA: {
        daysToPublish: 0,
    },
    WA: {
        daysToPublish: 0,
    },
    WV: {
        daysToPublish: 0,
    },
    WI: {
        daysToPublish: 0,
    },
    WY: {
        daysToPublish: 0,
    },
};

export const espCoverageDetails: ESPQuoteCoverageDetail = {
    do: {
        title: 'Directors and Officers',
        icon: 'meeting',
        text: 'Shield your company and its board members from litigation by disgruntled shareholders, customers, investors and regulatory bodies.',
        document: {
            old: ESPDNOOldURL,
            standard: ESPDNOStandardURL,
            new_standard: ESPDNOStandardNewInsurerURL,
            plus: ESPDNOPlusURL,
            new_plus: ESPDNOPlusNewInsurerURL,
            post_ipt_2024_standard: ESPDNOStandardPostIPT2024URL,
            post_ipt_2024_plus: ESPDNOPlusPostIPT2024URL,
        },
    },

    epli: {
        title: 'Employment Practices Liability',
        icon: 'suitcase',
        text: 'EPLI provides coverage for claims alleging discrimination, wrongful termination, harassment and other employment related issues.',
        document: {
            old: ESPEPLIOldURL,
            standard: ESPEPLIStandardURL,
            new_standard: ESPEPLIStandardNewInsurerURL,
            plus: ESPEPLIPlusURL,
            new_plus: ESPEPLIPlusNewInsurerURL,
            post_ipt_2024_standard: ESPEPLIStandardPostIPT2024URL,
            post_ipt_2024_plus: ESPEPLIPlusPostIPT2024URL,
        },
    },

    fiduciary: {
        title: 'Fiduciary Liability',
        icon: 'handshake',
        text: 'Protects against claims of errors or negligence on your company’s benefits plans under guidelines set by the Employee Retirement Income Security Act (ERISA).',
        document: {
            standard: ESPFiduciaryStandardURL,
            new_standard: ESPFiduciaryNewStandardURL,
        },
    },

    eoCyber: {
        title: 'Technology E&O/Cyber',
        icon: 'thief',
        text: 'E&O provides coverage for a failure of a service or software and Cyber Liability provides coverage for a data breach and loss of private third-party sensitive information.',
        document: {
            standard: ESPEoCyberStandardURL,
            plus: ESPEoCyberPlusURL,
        },
    },

    techCyber: {
        title: 'Technology E&O/Cyber',
        icon: 'thief',
        text: 'Tech E&O provides coverage for a failure of technology services and 3rd party liabilities. 1st Party Expenses refers to coverage for expenses that arise from a cyber event, including ransomware, notification expenses, and system damage.',
        document: {
            standard: ESPEoCyberStandardURL,
            plus: ESPTechEOCyberSplitPlusURL,
            post_ipt_2024_standard: ESPTechCyberStandardPostIPT2024URL,
            post_ipt_2024_plus: ESPTechCyberPlusPostIPT2024URL,
        },
    },
};

export const minimumPremiumAmount = USD(3500 * 100);
