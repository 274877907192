import { Nullable } from '@embroker/shotwell/core/types/';
import { Money } from '@embroker/shotwell/core/types/Money';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { WorkersCompensationLocationGroup } from './WorkersCompensationLocationGroup';

export interface WorkersCompensationLocation {
    id: UUID;
    grossPremium: Money;
    numLocation: number;
    name: string;
    state: Nullable<string>;
    streetAddress: Nullable<string>;
    city: Nullable<string>;
    zip: Nullable<ZipCode>;
    locationGroupList: WorkersCompensationLocationGroup[];
}

export const WorkersCompensationLocation = {
    ...defineValidator<WorkersCompensationLocation>({
        id: UUID.schema,
        grossPremium: Money.schema,
        numLocation: Joi.number(),
        name: Joi.string(),
        state: Joi.string().allow(null, ''),
        streetAddress: Joi.string().allow(null, ''),
        city: Joi.string().allow(null, ''),
        zip: ZipCode.schema.allow(null),
        locationGroupList: Joi.array().items(WorkersCompensationLocationGroup.schema),
    }),
    create(workersCompensationLocation: WorkersCompensationLocation) {
        return WorkersCompensationLocation.validate(workersCompensationLocation);
    },
};
