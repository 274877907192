import { defineEntityValidator, entity } from '@embroker/shotwell/core/entity/Entity';
import { Quote, commonQuoteProps } from '../../../../quote/entities/Quote';
import { BOPChubbQuoteDetails } from '../types/BOPChubbQuoteDetails';
import { BOPChubbQuoteOptions } from '../types/BOPChubbQuoteOptions';

export interface BOPChubbQuote extends Quote {
    readonly options: BOPChubbQuoteOptions;
    readonly details: BOPChubbQuoteDetails;
}

export const BOPChubbQuote = entity<BOPChubbQuote, Quote>({
    validator: defineEntityValidator<BOPChubbQuote>({
        ...commonQuoteProps,
        options: BOPChubbQuoteOptions.schema.required(),
        details: BOPChubbQuoteDetails.schema.required(),
    }),
    inherits: Quote,
});
