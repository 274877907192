import { UUID } from '@embroker/shotwell/core/types/UUID';
import {
    BoxLayout,
    CardLayout,
    CenterLayout,
    PageLayout,
    Spinner,
    StackLayout,
    Table,
    Text,
    TextButton,
    useAsyncTable,
} from '@embroker/ui-toolkit/v2';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../../view/AppContext';
import { Link } from '../../../../view/components/Link/Link';
import { PolicyListSortableColumn } from '../../../useCases/GetBrokerPolicies';
import { BrokerFilter } from '../BrokerFilter';
import { BrokerTableHeader } from '../BrokerTableHeader';
import { BrokerPolicyTableData, getPolicyPageData } from './BrokerPoliciesTableData';

interface BrokerPoliciesProps {
    itemsPerPage?: number;
}

export function BrokerPolicies({ itemsPerPage = 10 }: BrokerPoliciesProps) {
    const { activeSession } = useContext(AppContext);
    const initialFilters = [];
    const [selectedBroker, setSelectedBroker] = useState<UUID | 'all'>(
        activeSession.userId ?? 'all',
    );
    if (activeSession.userId !== null) {
        initialFilters.push({ target: 'managing_broker', value: activeSession.userId });
    }
    const { visibleItems, sortBy, sortedBy, isLoading, pagination, filterBy } =
        useAsyncTable<BrokerPolicyTableData>({
            fetchPageData: getPolicyPageData,
            itemsPerPage,
            initialSortBy: { column: 'expiration_date', order: 'ASC' },
            initialFilters,
            prefetch: true,
        });

    function handleSortBy(column: PolicyListSortableColumn) {
        return (event: React.MouseEvent) => {
            event.preventDefault();
            sortBy(
                column,
                sortedBy?.column === column && sortedBy?.order === 'ASC' ? 'DESC' : 'ASC',
            );
        };
    }

    const noItems = visibleItems.length === 0;
    const areFiltersApplied = selectedBroker !== activeSession.userId;

    return (
        <PageLayout.Section>
            <StackLayout>
                <TextButton
                    size="small"
                    as={Link}
                    icon="bold-caret-left"
                    iconPosition="left"
                    href="/broker/dashboard"
                >
                    Back to dashboard
                </TextButton>
                {isLoading ? (
                    <Spinner />
                ) : (
                    <CardLayout>
                        <CardLayout.Header>
                            <BrokerTableHeader
                                noItems={noItems}
                                pagination={pagination}
                                title="Recent Policies"
                            />
                        </CardLayout.Header>
                        <CardLayout.Body>
                            <StackLayout gap={noItems ? '24' : 'none'}>
                                <BrokerFilter
                                    selectedBroker={selectedBroker}
                                    onBrokerSelect={setSelectedBroker}
                                    filterBy={filterBy}
                                />
                                {!noItems ? (
                                    <Table>
                                        <Table.Header>
                                            <Table.Column
                                                isSortable
                                                isSortedBy={
                                                    sortedBy?.column === 'organization_name'
                                                }
                                                sortDirection={sortedBy?.order}
                                                onClick={handleSortBy('organization_name')}
                                            >
                                                Client
                                            </Table.Column>
                                            <Table.Column
                                                isSortable
                                                isSortedBy={sortedBy?.column === 'managing_broker'}
                                                sortDirection={sortedBy?.order}
                                                onClick={handleSortBy('managing_broker')}
                                            >
                                                Broker
                                            </Table.Column>
                                            <Table.Column>Type</Table.Column>
                                            <Table.Column width="1/10">Policy No.</Table.Column>
                                            <Table.Column
                                                isSortable
                                                isSortedBy={sortedBy?.column === 'expiration_date'}
                                                sortDirection={sortedBy?.order}
                                                onClick={handleSortBy('expiration_date')}
                                                width="1/10"
                                            >
                                                Exp. date
                                            </Table.Column>

                                            <Table.Column
                                                isSortable
                                                isSortedBy={sortedBy?.column === 'premium'}
                                                sortDirection={sortedBy?.order}
                                                onClick={handleSortBy('premium')}
                                                width="1/9"
                                            >
                                                Premium
                                            </Table.Column>
                                            <Table.Column width="1/8">Action</Table.Column>
                                        </Table.Header>
                                        <Table.Body>
                                            {visibleItems.map((row) => (
                                                <Table.Row
                                                    key={row.policyId as UUID}
                                                    data-e2e="policy-table"
                                                >
                                                    <Table.Cell truncate data-e2e="company-name">
                                                        {row.companyName}
                                                    </Table.Cell>
                                                    <Table.Cell truncate data-e2e="managing-broker">
                                                        {row.managingBrokerName}
                                                    </Table.Cell>
                                                    <Table.Cell data-e2e="policy-type">
                                                        {row.productType}
                                                    </Table.Cell>
                                                    <Table.Cell data-e2e="policy-number">
                                                        {row.policyNumber}
                                                    </Table.Cell>
                                                    <Table.Cell data-e2e="policy-exp-date">
                                                        {row.expirationDate}
                                                    </Table.Cell>
                                                    <Table.Cell data-e2e="policy-premium">
                                                        {row.premium}
                                                    </Table.Cell>
                                                    <Table.Cell data-e2e="policy-action">
                                                        {row.action}
                                                    </Table.Cell>
                                                </Table.Row>
                                            ))}
                                        </Table.Body>
                                    </Table>
                                ) : (
                                    <BoxLayout gap="24">
                                        <CenterLayout centerText>
                                            <Text style="label 1">
                                                {areFiltersApplied
                                                    ? 'No records match applied filter criteria.'
                                                    : 'You have no policies yet. Once your clients have coverage, the list will appear here.'}
                                            </Text>
                                        </CenterLayout>
                                    </BoxLayout>
                                )}
                            </StackLayout>
                        </CardLayout.Body>
                    </CardLayout>
                )}
            </StackLayout>
        </PageLayout.Section>
    );
}
