import { API } from '@embroker/shotwell-api/app';
import { isAPIError } from '@embroker/shotwell-api/errors';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { container, injectable } from '@embroker/shotwell/core/di';
import { findDomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import {
    AsyncResult,
    Failure,
    Success,
    SuccessResult,
    handleOperationFailure,
    isErr,
    isOK,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import {
    Role,
    Session,
    SessionOrganizationSelected,
    isAuthenticated,
} from '../../entities/Session';
import {
    ExpiredTokenError,
    FailedToRetrieveSessionError,
    InactiveAccount,
    InvalidTokenError,
    NoAccount,
    WrongUsernamePasswordPair,
} from '../../errors';
import { UserStatusCode } from '../../types/enums';
import { LoginByBundleTokenResponse, SessionCreateParams, SessionRepository } from './index';

@injectable()
export class APISessionRepository implements SessionRepository {
    private activeSession: Nullable<Immutable<Session>> = null;

    public async create(
        params: SessionCreateParams,
    ): AsyncResult<
        Session,
        InvalidArgument | OperationFailed | WrongUsernamePasswordPair | InactiveAccount
    > {
        const email = EmailAddress.validate(params.username, 'username');

        if (isErr(email)) {
            return email;
        }

        const result = await API.request('user/login', {
            email: email.value,
            pwd: params.password,
        });

        if (isErr(result)) {
            if (process.env.NODE_ENV === 'development') {
                container.get<Logger>(Log).error('Login failed', result);
            }

            const userPasswordPairWrongError = 'user_pwd_pair_wrong';
            const inactiveAccountError = 'inactive_account';
            for (const error of result.errors) {
                if (isAPIError(error)) {
                    const errorDetails = error.details.name;
                    switch (errorDetails) {
                        case userPasswordPairWrongError:
                            return Failure(WrongUsernamePasswordPair());
                        case inactiveAccountError:
                            return Failure(InactiveAccount());
                        default:
                            break;
                    }
                }
            }
            return handleOperationFailure(result);
        }
        const role = result.value.user.is_broker ? 'broker' : 'user';

        API.setAuthorization({
            accessToken: result.value.access_token,
            refreshToken: result.value.refresh_token,
        });

        const userOrganizationIdList = result.value.organizations
            .filter((organizationId) => organizationId !== null)
            .map((organization) => organization.id as UUID);

        const sessionResult = await Session.create({
            authenticatedUserId: result.value.user.id,
            userId: null,
            organizationId: userOrganizationIdList.length === 1 ? userOrganizationIdList[0] : null,
            userOrganizationIdList,
            expirationDateTime: null,
            roles: [role],
        });

        if (isErr(sessionResult)) {
            if (process.env.NODE_ENV === 'development') {
                container.get<Logger>(Log).error('Failed to create Session', sessionResult);
            }
            return handleOperationFailure(sessionResult);
        }

        return this.save(sessionResult.value);
    }

    public async loginByBundleToken(
        token: string,
    ): AsyncResult<
        LoginByBundleTokenResponse,
        InvalidArgument | InvalidTokenError | ExpiredTokenError | FailedToRetrieveSessionError
    > {
        const result = await API.request('law_bundle/open', {
            token,
        });

        if (isErr(result)) {
            if (process.env.NODE_ENV === 'development') {
                container.get<Logger>(Log).error('Login failed', result);
            }
            const invalidTokenError = 'bundle_prospect_not_found';
            const expiredTokenError = 'token_expired';
            for (const error of result.errors) {
                if (isAPIError(error)) {
                    const errorDetails = error.details.name;
                    switch (errorDetails) {
                        case invalidTokenError:
                            return Failure(InvalidTokenError());
                        case expiredTokenError:
                            return Failure(ExpiredTokenError());
                        default:
                            break;
                    }
                }
            }
            return Failure(InvalidTokenError());
        }

        const activeSession = await this.getActiveSession();

        if (activeSession.value === null) {
            return Failure(FailedToRetrieveSessionError());
        }

        API.setAuthorization({
            accessToken: result.value.access_token,
            refreshToken: result.value.refresh_token,
        });

        return Success({
            session: activeSession.value,
            bundleApplicationId: result.value.bundle_application_id,
        });
    }

    public async loginByStreamlineRenewalProspectToken(
        token: string,
    ): AsyncResult<
        Immutable<Session>,
        InvalidTokenError | ExpiredTokenError | FailedToRetrieveSessionError
    > {
        const result = await API.request('user/streamline_renewal_prospect_login', {
            token,
        });

        if (isErr(result)) {
            if (process.env.NODE_ENV === 'development') {
                container.get<Logger>(Log).error('Login failed', result);
            }
            const invalidTokenError = 'prospect_not_found';
            const expiredTokenError = 'expired_token';
            for (const error of result.errors) {
                if (isAPIError(error)) {
                    const errorDetails = error.details.name;
                    switch (errorDetails) {
                        case invalidTokenError:
                            return Failure(InvalidTokenError());
                        case expiredTokenError:
                            return Failure(ExpiredTokenError());
                        default:
                            break;
                    }
                }
            }
            return Failure(InvalidTokenError());
        }

        const activeSession = await this.getActiveSession();

        if (activeSession.value === null) {
            return Failure(FailedToRetrieveSessionError());
        }

        API.setAuthorization({
            accessToken: result.value.access_token,
            refreshToken: result.value.refresh_token,
        });

        return Success(activeSession.value);
    }

    public async getActiveSession(): Promise<SuccessResult<Nullable<Immutable<Session>>>> {
        const roles: Role[] = [];
        if (this.activeSession !== null && isAuthenticated(this.activeSession)) {
            return Success(this.activeSession);
        }

        const sessionUserGetResult = await API.request('session/get');

        let impersonatedUserId: Nullable<UUID> | null = null;
        let userOrganizationIdList: UUID[] | undefined = undefined;
        let authUserId: Nullable<UUID> | null = null;

        if (sessionUserGetResult && isOK(sessionUserGetResult)) {
            if (sessionUserGetResult.value.user) {
                roles.push(sessionUserGetResult.value.user.is_broker ? 'broker' : 'user');
                authUserId = sessionUserGetResult.value.user.id;
            }
            if (sessionUserGetResult.value.organizations) {
                userOrganizationIdList = sessionUserGetResult.value.organizations
                    .filter((organizationId) => organizationId !== null)
                    .map((organization) => organization.id as UUID);
            }
            if (sessionUserGetResult.value.impersonated_user) {
                impersonatedUserId = sessionUserGetResult.value.impersonated_user.id;
                API.updateContext({ is_user_impersonated: true });
                roles.push('admin');
                if (sessionUserGetResult.value.impersonated_user.is_broker) {
                    roles.push('broker');
                }
            }
        }

        const sessionResult = await Session.create({
            authenticatedUserId: authUserId,
            organizationId: userOrganizationIdList?.length === 1 ? userOrganizationIdList[0] : null,
            userOrganizationIdList,
            userId: impersonatedUserId,
            expirationDateTime: null,
            roles: roles,
        });

        if (isErr(sessionResult)) {
            if (process.env.NODE_ENV === 'development') {
                container.get<Logger>(Log).error('Failed to create Session', sessionResult);
            }
            return Success(null);
        }

        return this.save(sessionResult.value);
    }

    public async save(session: Immutable<Session>): Promise<SuccessResult<Immutable<Session>>> {
        this.activeSession = session;

        const organizationSelectedEvent = findDomainEvent<SessionOrganizationSelected>(
            session.events,
            'Session',
            'OrganizationSelected',
        );

        if (organizationSelectedEvent !== undefined) {
            API.updateContext({
                organization_id: organizationSelectedEvent.organizationId,
            });
        }

        return Success(session);
    }

    public async delete(): Promise<SuccessResult> {
        if (isAuthenticated(this.activeSession)) {
            await API.request('user/logout');
            API.updateContext({
                organization_id: null,
            });
            API.clearAuthorization();
        }
        this.activeSession = null;
        return Success();
    }

    public async getUserStatus(
        email: EmailAddress,
    ): AsyncResult<
        UserStatusCode,
        InvalidArgument | OperationFailed | InactiveAccount | NoAccount
    > {
        const userStatusResult = await API.request('user/get_status', { email_address: email });
        if (isErr(userStatusResult)) {
            return handleOperationFailure(userStatusResult);
        }

        const userArchivedStatus = 'UserStatusCodeListArchivedUser';
        if (userStatusResult.value.status === userArchivedStatus) {
            return Failure(InactiveAccount());
        }

        const notAUser = 'UserStatusCodeListNotAUser';
        if (userStatusResult.value.status === notAUser) {
            return Failure(NoAccount());
        }

        const result = userStatusResult.value.status as UserStatusCode;
        return Success(result);
    }
}
