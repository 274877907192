import { container } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { execute } from '@embroker/shotwell/core/UseCase';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import {
    CardLayout,
    ColumnLayout,
    Pagination,
    StackLayout,
    StatusLabel,
    StatusType,
    Table,
    Text,
    TextButton,
    useStaticTable,
} from '@embroker/ui-toolkit/v2';
import React, { useMemo } from 'react';
import { GetDocumentUrl, GetDocumentUrlRequest } from '../../../documents/useCases/GetDocumentUrl';
import { Link } from '../../../view/components/Link/Link';
import { Certificate } from '../../entities/Certificate';

const CERTIFICATE_TYPE_EVIDENCE_OF_COVERAGE = 'CertificateTypeCodeListEvidenceOfCoverage';
const TABLE_ITEMS_LIMIT_PER_PAGE = 10;

type SortableColumn = 'companyName' | 'email' | 'lastUpdatedTime';

interface CertificateHistoryPageData {
    certificates: Immutable<EntityProps<Certificate>[]>;
}

export function CertificatesHistoryPage({ certificates }: CertificateHistoryPageData) {
    const certificateTableList = useMemo(() => {
        return [...parseRawCertificateListForTableView(certificates)];
    }, [certificates]);

    const { visibleItems, pagination, sortedBy, sortBy } = useStaticTable<CertificateTableItem>({
        items: certificateTableList,
        itemsPerPage: TABLE_ITEMS_LIMIT_PER_PAGE,
    });

    const showPagination = pagination.totalPages > 1;

    const sortTableHandler = (column: SortableColumn) => {
        return (event: React.MouseEvent) => {
            event.preventDefault();
            sortBy(
                column,
                sortedBy?.column === column && sortedBy?.order === 'ASC' ? 'DESC' : 'ASC',
            );
        };
    };

    return (
        <StackLayout>
            <ColumnLayout split={showPagination ? '-1' : '0'}>
                <Text style="heading 4" data-e2e="certs-issued-online-header">
                    Certificates you have issued online
                </Text>
                {showPagination ? <Pagination {...pagination} maximumVisibleButtons={4} /> : null}
            </ColumnLayout>
            <CardLayout>
                <CardLayout.Body>
                    <Table>
                        <Table.Header>
                            <Table.Column
                                isSortable
                                isSortedBy={sortedBy?.column === 'companyName'}
                                onClick={sortTableHandler('companyName')}
                                data-e2e="certs-table-company-header"
                            >
                                COMPANY
                            </Table.Column>
                            <Table.Column
                                isSortable
                                isSortedBy={sortedBy?.column === 'email'}
                                onClick={sortTableHandler('email')}
                                data-e2e="certs-table-email-header"
                            >
                                EMAIL
                            </Table.Column>
                            <Table.Column data-e2e="certs-table-reference-header">
                                REFERENCE/PROJECT
                            </Table.Column>
                            <Table.Column data-e2e="certs-table-status-header">STATUS</Table.Column>
                            <Table.Column
                                isSortable
                                isSortedBy={sortedBy?.column === 'lastUpdatedTime'}
                                onClick={sortTableHandler('lastUpdatedTime')}
                                data-e2e="certs-table-lst-updated-header"
                            >
                                DATE
                            </Table.Column>
                            <Table.Column data-e2e="certs-table-issued-header">
                                CERTIFICATE ISSUED
                            </Table.Column>
                        </Table.Header>
                        <Table.Body>
                            {visibleItems.map((row) => {
                                return (
                                    <Table.Row key={row.id}>
                                        <Table.Cell>
                                            <Text style="label 1" data-e2e="cert-company-name-row">
                                                {row.companyName}
                                            </Text>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Text style="label 1" data-e2e="cert-email-row">
                                                {row.email}
                                            </Text>
                                        </Table.Cell>
                                        <Table.Cell truncate>{row.reference}</Table.Cell>
                                        <Table.Cell>
                                            <StatusLabel
                                                fixed
                                                data-e2e="cert-status-row"
                                                type={row.status.type}
                                            >
                                                {row.status.text}
                                            </StatusLabel>
                                        </Table.Cell>
                                        <Table.Cell data-e2e="cert-lst-updated-row">
                                            {row.lastUpdatedTime ? (
                                                <DateDisplay
                                                    format="MMM d, yyyy"
                                                    value={row.lastUpdatedTime}
                                                />
                                            ) : (
                                                'N/A'
                                            )}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <StackLayout gap="none" data-e2e="cert-download-row">
                                                {row.downloadButton}
                                            </StackLayout>
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </CardLayout.Body>
            </CardLayout>
        </StackLayout>
    );
}

interface CertificateTableItem extends Record<string, unknown> {
    id: string;
    companyName: string;
    email: Nullable<EmailAddress>;
    reference: string;
    status: { type: StatusType; text: string };
    downloadButton: React.ReactNode;
    lastUpdatedTime?: Date;
}

function parseRawCertificateListForTableView(
    inputCertificateList: Immutable<EntityProps<Certificate>[]>,
) {
    const certificateList: CertificateTableItem[] =
        inputCertificateList.map((certificate) => {
            return {
                id: certificate.id,
                companyName: getCertificateCompany(certificate),
                email: certificate.certificateHolder?.organizationEmail || null,
                reference: certificate.referenceNumber || 'N/A',
                status: getCertificateStatus(certificate),
                downloadButton: renderDocumentLinks(certificate),
                lastUpdatedTime: certificate.completedDateTime || certificate.createdDateTime,
            };
        }) || [];

    return certificateList.sort(sortCertificates);
}

function DocumentLink({ fileKey }: { fileKey: string }) {
    const downloadHandler = () => {
        execute(GetDocumentUrl, { fileKey: fileKey } as GetDocumentUrlRequest).then((result) => {
            if (isOK(result)) {
                window.open(result.value.downloadUrl, '_blank');
            } else {
                container.get<Logger>(Log).error(result.errors);
            }
        });
    };

    return <TextButton onClick={downloadHandler}>Download</TextButton>;
}

function getCertificateStatus(certificate: Immutable<EntityProps<Certificate>>): {
    type: StatusType;
    text: string;
} {
    let text = 'REQUESTED';
    let type: StatusType = 'gray';

    switch (certificate.certificateSharingStatus) {
        case 'CertificateSharingRelationshipStatusCodeListCompleted': {
            text = 'SENT';
            type = 'green';
            break;
        }
        case 'CertificateSharingRelationshipStatusCodeListCancelled': {
            text = 'FAILED';
            type = 'red';
            break;
        }
        case 'CertificateSharingRelationshipStatusCodeListRequested': {
            text = 'REQUESTED';
            type = 'gray';
            break;
        }
        default: {
            text = 'REQUESTED';
            type = 'gray';
            break;
        }
    }
    return { type, text };
}

function getCertificateCompany(certificate: Immutable<EntityProps<Certificate>>): string {
    if (certificate.certificateType === CERTIFICATE_TYPE_EVIDENCE_OF_COVERAGE) {
        return '(Evidence of Coverage Only)';
    }
    return certificate.certificateHolder ? certificate.certificateHolder.organizationName : '';
}

function renderDocumentLinks(certificate: Immutable<EntityProps<Certificate>>): React.ReactNode {
    if (
        certificate.certificateSharingStatus ===
        'CertificateSharingRelationshipStatusCodeListCancelled'
    ) {
        return (
            <TextButton href="/support/contact" as={Link}>
                Contact Support
            </TextButton>
        );
    }
    const documentList = certificate.documentList;
    return documentList.map((item) => {
        return <DocumentLink key={item.fileKey} fileKey={item.fileKey} />;
    });
}

function sortCertificates(a: CertificateTableItem, b: CertificateTableItem): number {
    if (a.lastUpdatedTime && b.lastUpdatedTime) {
        if (a.lastUpdatedTime > b.lastUpdatedTime) {
            return -1;
        }
        if (b.lastUpdatedTime > a.lastUpdatedTime) {
            return 1;
        }
    }
    if (!a.lastUpdatedTime && !b.lastUpdatedTime) {
        return 0;
    }
    if (!b.lastUpdatedTime && a.lastUpdatedTime) {
        return -1;
    }
    if (!a.lastUpdatedTime && b.lastUpdatedTime) {
        return 1;
    }

    return 0;
}
