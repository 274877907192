import { Aborted, InvalidArgument, OperationFailed, Timeout } from '@embroker/shotwell/core/Error';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, Success, isErr } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { DocGenFailed } from '../../quote/errors';
import { CoverageCatalog } from '../CoverageCatalog';
import { BundleQuote } from '../entities/BundleQuote';
import { BundleCoverageType } from '../types/BundleQuoteCoverage';
import { DocumentType } from '../types/BundleQuoteDocument';
import { GetDocumentUrlResponse, publishEvents } from './GetDocumentUrl';

export interface GetStaticDocumentUrlRequest {
    documentType: DocumentType;
    bundleCoverageType: BundleCoverageType;
    bundleQuote: BundleQuote;
}

export interface GetStaticDocumentUrl extends UseCase {
    execute(
        request: GetStaticDocumentUrlRequest,
    ): AsyncResult<
        GetDocumentUrlResponse,
        InvalidArgument | OperationFailed | Aborted | Timeout | DocGenFailed
    >;
}

@injectable()
class GetStaticDocumentUrlUseCase extends UseCase implements GetStaticDocumentUrl {
    public static type = Symbol('BundleQuote/CreateBundleStaticDocument');

    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    public async execute({
        documentType,
        bundleCoverageType,
        bundleQuote,
    }: GetStaticDocumentUrlRequest): AsyncResult<
        GetDocumentUrlResponse,
        InvalidArgument | OperationFailed | Aborted | Timeout | DocGenFailed
    > {
        const coverage = bundleQuote.coverageList.find(
            (coverage) => coverage.type == bundleCoverageType,
        );
        if (!coverage || !coverage.quote) {
            return Failure(
                InvalidArgument({ argument: 'bundleCoverage', value: bundleCoverageType }),
            );
        }
        const documentUrlResult = await CoverageCatalog.getDocumentDownloadMetadata(
            documentType,
            bundleCoverageType,
            coverage.quote,
        );
        if (isErr(documentUrlResult)) {
            return documentUrlResult;
        }
        await publishEvents(documentType, bundleCoverageType, this.eventBus);
        return Success<GetDocumentUrlResponse>({
            fileKey: '',
            fileUrl: URI.build(documentUrlResult.value.downloadUrl),
        });
    }
}
export const GetStaticDocumentUrl: UseCaseClass<GetStaticDocumentUrl> = GetStaticDocumentUrlUseCase;
