import { Immutable } from '@embroker/shotwell/core/types';
import { Table, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { InsuredParty } from '../../../types/InsuredParty';

interface NamedInsuredSectionProps {
    insuredPartiesList: Immutable<InsuredParty[]>;
}

export const NamedInsuredSection = ({ insuredPartiesList }: NamedInsuredSectionProps) => {
    return (
        <Table>
            <Table.Header>
                <Table.Column>Named Insured</Table.Column>
            </Table.Header>
            <Table.Body>
                {insuredPartiesList.map((insuredParty) => (
                    <Table.Row key={insuredParty.name}>
                        <Table.Cell>
                            <Text style="label 1" data-e2e="named-insured">
                                {insuredParty.name}
                            </Text>
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    );
};
