import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    Form,
    Modal,
    ReactProps,
    StackLayout,
    Text,
    TextButton,
    useModal,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { WizardForm } from '../../../../../view/hooks/useWizardForm';
import { ESPQuote } from '../../../entities/ESPQuote';
import { ESPQuoteOptionsFormData } from '../ESPQuoteLandingPage';
import { WarrantyAndFraudModalContent } from './WarrantyAndFraudModalContent';
import { SignInsuranceApplicationCheckbox } from '../../../../view/components/signature/SignInsuranceApplicationCheckbox';

interface QuoteSignatureProps extends ReactProps<'div'> {
    quote: ESPQuote;
    fields: WizardForm<OpaqueForm<ESPQuoteOptionsFormData>>['fields'];
    setValue: WizardForm<OpaqueForm<ESPQuoteOptionsFormData>>['setValue'];
    value: WizardForm<OpaqueForm<ESPQuoteOptionsFormData>>['value'];
    trigger: WizardForm<OpaqueForm<ESPQuoteOptionsFormData>>['trigger'];
}

export function QuoteSignature({
    quote,
    fields,
    setValue,
    value,
    trigger,
    className,
}: QuoteSignatureProps) {
    const warrantyAndFraudModal = useModal();

    const userInfo = quote.quoteInfo?.userInfo;
    if (!userInfo) {
        return null;
    }

    return (
        <StackLayout gap="32" className={className}>
            <Text style="heading 4">Please agree to the statements below</Text>
            <Form.Field
                type={fields.agreementToConductSignature.type}
                messages={fields.agreementToConductSignature.messages}
                inputProps={{
                    checked: fields.agreementToConductSignature.props.value,
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                        setValue({
                            ...value,
                            agreementToConductSignature: event.target.checked,
                        });
                        trigger('sign');
                    },
                    children: (
                        <SignInsuranceApplicationCheckbox
                            company={userInfo.company}
                            fullName={userInfo.fullName}
                            usaState={userInfo.usaState}
                        />
                    ),
                }}
                data-e2e="agreement-to-conduct-signature"
            />
            <Form.Field
                type={fields.warrantyAndFraudSignature.type}
                messages={fields.warrantyAndFraudSignature.messages}
                inputProps={{
                    checked: fields.warrantyAndFraudSignature.props.value,
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                        setValue({ ...value, warrantyAndFraudSignature: event.target.checked });
                        trigger('sign');
                    },
                    children: (
                        <Text as="span">
                            By checking this box, you certify all information provided in the
                            insurance application is true and correct and acknowledge that you have
                            read and agree to the{' '}
                            <TextButton onClick={warrantyAndFraudModal.show}>
                                Warranty and Fraud Statement
                            </TextButton>
                        </Text>
                    ),
                }}
                data-e2e="warranty-and-fraud-signature"
            />
            <Modal {...warrantyAndFraudModal} size="medium">
                <WarrantyAndFraudModalContent
                    fullName={userInfo.fullName}
                    title={userInfo.title}
                    usaState={userInfo.usaState}
                />
            </Modal>
        </StackLayout>
    );
}
