import { errorCodes } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { ErrorObject } from '@embroker/shotwell/core/types/Result';

export const ErrorCode = errorCodes({
    GetUserEnrolledFeaturesError: 0x2300,
});

export type GetUserEnrolledFeaturesError = ErrorObject<
    typeof ErrorCode.GetUserEnrolledFeaturesError
>;

export function GetUserEnrolledFeaturesError(): Immutable<GetUserEnrolledFeaturesError> {
    return {
        name: 'GetUserEnrolledFeaturesError',
        code: ErrorCode.GetUserEnrolledFeaturesError,
        message: 'GetUserEnrolledFeatures error',
    };
}
