import { Password } from '@embroker/shotwell/core/types/Password';
import { MINIMUM_PASSWORD_LENGTH } from '@embroker/shotwell/core/validation/password';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    Button,
    CenterLayout,
    ColumnLayout,
    Form,
    FormLayout,
    Icon,
    StackLayout,
    StatusMessage,
    StatusMessageList,
    Text,
} from '@embroker/ui-toolkit/v2';
import React, { useEffect, useState } from 'react';
import { SetPassword } from '../../useCases/SetPassword';
import { PrivacyMessage } from './PrivacyMessage.view';

interface SetNewPasswordFormData {
    password: string;
}

const SetNewPasswordForm = createForm<SetNewPasswordFormData>({
    fields: {
        password: {
            type: 'password',
            validator: Password.schema,
            formatValidationError(error) {
                switch (error.details.validator) {
                    case 'any.required':
                    case 'string.empty':
                        return 'You need to enter new password';
                    case 'string.min':
                    case 'password.length':
                        return `Password should be at least ${MINIMUM_PASSWORD_LENGTH} characters long`;
                    case 'password.strength':
                        return 'Please provide a stronger password';
                    default:
                        return `Unhandled validation error: ${error.message}`;
                }
            },
        },
    },
    useCase: SetPassword,
    formatSubmitErrors(errors) {
        return ['Sorry, but it seems that the current password you entered is invalid'];
    },
});

export function SetupNewPassword() {
    const [newPasswordIsSetSuccessfully, setNewPasswordIsSetSuccessfully] = useState(false);
    const { submit, messages, fields, status, setValue } = useForm(SetNewPasswordForm);

    useEffect(() => {
        if (status === 'submitted') {
            setNewPasswordIsSetSuccessfully(true);
            setValue({
                password: '',
            });
        }
    }, [status, setValue]);

    return (
        <FormLayout>
            <Form noValidate onSubmit={submit}>
                {newPasswordIsSetSuccessfully ? (
                    <StackLayout data-e2e="set-pwd-success-msg-wrapper" gap="32">
                        <StatusMessage status="success">
                            Password created successfully
                        </StatusMessage>
                    </StackLayout>
                ) : (
                    <StackLayout data-e2e="set-pwd-error-msg-wrapper" gap="32">
                        <StatusMessageList messages={messages} status="error" />
                        <Text data-e2e="set-pwd-header" style="heading 3">
                            Setup your password
                        </Text>
                        <Form.Field
                            inputProps={{
                                note: 'Use a few words, avoid common phrases. No need for symbols, digits or uppercase letters',
                                autoComplete: 'set-password',
                                ...fields.password.props,
                                strengthMeter: true,
                            }}
                            data-e2e="set-pwd-password-field"
                            label="Enter your password"
                            messages={fields.password.messages}
                            type={fields.password.type}
                        ></Form.Field>
                        <StackLayout center gap="32">
                            <Button data-e2e="set-pwd-submit-button" type="submit">
                                Create new password
                            </Button>
                            <CenterLayout>
                                <ColumnLayout gap="8">
                                    <Icon data-e2e="set-pwd-lock-icon" name="lock" size="small" />
                                    <PrivacyMessage />
                                </ColumnLayout>
                            </CenterLayout>
                        </StackLayout>
                    </StackLayout>
                )}
            </Form>
        </FormLayout>
    );
}
