import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success, isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { WCGAQuoteRepository } from '../repositories/WCGAQuoteRepository';

/**
 * Request data for SubmitWCGAQuoteForReview use case
 */
export interface SubmitWCGAQuoteReviewRequest {
    applicationId: UUID;
}

/**
 * SubmitWCGAQuoteForReview use case is used to submit referred WCGA quote to review
 */
export interface SubmitWCGAQuoteForReview extends UseCase {
    execute(
        request: SubmitWCGAQuoteReviewRequest,
    ): AsyncResult<void, OperationFailed | InvalidArgument>;
}

class SubmitWCGAQuoteRevieweUseCase extends UseCase implements SubmitWCGAQuoteForReview {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('WCGAQuote/SubmitWCGAQuoteForReview');

    /**
     * Constructor for SubmitWCGAQuoteForReview use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(WCGAQuoteRepository) private wcgaQuoteRepository: WCGAQuoteRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes SubmitWCGAQuoteForReview use case
     * Input is of SubmitWCGAQuoteReviewRequest type
     * @returns InvalidArgument if insurance application does not exist
     * @returns OperationFailed if repository could not submit the quote for review
     */
    public async execute(
        request: SubmitWCGAQuoteReviewRequest,
    ): AsyncResult<void, OperationFailed | InvalidArgument> {
        const submitResponse = await this.wcgaQuoteRepository.submitForReview(
            request.applicationId,
        );

        if (isErr(submitResponse)) {
            return submitResponse;
        }

        return Success();
    }
}

export const SubmitWCGAQuoteForReview: UseCaseClass<SubmitWCGAQuoteForReview> =
    SubmitWCGAQuoteRevieweUseCase;
