import { ErrorCode, InvalidArgument } from '@embroker/shotwell/core/Error';
import { container } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Nullable } from '@embroker/shotwell/core/types';
import {
    ErrorLike,
    Failure,
    FailureResult,
    Result,
    Success,
    isErr,
    isOK,
} from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { useModal } from '@embroker/ui-toolkit/v2';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { hasRole } from '../../../userOrg/entities/Session';
import { ActiveSession, AppContext } from '../../../view/AppContext';
import { navigateToErrorPage } from '../../../view/errors';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { Application, ApplicationEarlyClearanceFailed } from '../../entities/Application';
import { RedirectApplicationDataArray } from '../../types/RedirectApplicationDataArray';
import { ShoppingCoverageCodeArray } from '../../types/ShoppingCoverageCodeArray';
import {
    BundleSubmitted,
    ClearanceFailed,
    DeclinedByCarrier,
    NotEligible,
    QuotesReady,
    QuotingEngine,
    QuotingEngineBOPChubb,
    QuotingEngineCNABOP,
    QuotingEngineCrime,
    QuotingEngineCyber,
    QuotingEngineCyberCowbell,
    QuotingEngineESP,
    QuotingEngineExcess,
    QuotingEngineLPLEverest,
    QuotingEngineLawCyber,
    QuotingEngineMPL,
    QuotingEngineManual,
    QuotingEnginePCoML,
    QuotingEngineWCChubb,
    QuotingEngineWCGA,
    Referred,
    AppTypeCodeListCyberCowbell,
    QuotingEngineHartfordBOP,
} from '@app/shopping/types/enums';
import { GetTaskStatus, GetTaskStatusResponse } from '../../useCases/GetTaskStatus';
import { CanceledParentPolicyErrorModal } from './CanceledParentPolicyErrorModal';
import { DeclinedModal } from './DeclinedModal';
import { ManualQuotingConfirmationModal } from './ManualQuotingConfirmationModal';
import { OfacRejectedModal } from './OfacRejectedModal';
import { SubmissionTimeoutErrorModal } from './SubmissionTimeoutErrorModal';
import { ThankYouErrorModal } from './ThankYouErrorModal';
import { hasExistingCoverage, isLPLIndication } from './applicationFlags';
import { YouAreAllDone } from './YouAreAllDone.view';

export interface ApplicationSubmissionProps {
    taskId: UUID;
}

export function ApplicationSubmission({ taskId }: ApplicationSubmissionProps) {
    const { navigate } = useNavigation();
    const { activeSession } = useContext(AppContext);
    const ofacRejectedModal = useModal();
    const manualModal = useModal();
    const submissionTimeoutErrorModal = useModal();
    const canceledParentPolicyErrorModal = useModal();
    const thankYouErrorModal = useModal();
    const declinedModal = useModal();
    const abortController = useMemo(() => new AbortController(), []);
    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, [abortController]);
    const { result: getTaskStatusResult } = useUseCase(GetTaskStatus, {
        id: taskId,
        pollingRetryIntervalInMilliseconds: 1000,
        abortSignal: abortController.signal,
    });
    const eventBus = useMemo(() => container.get<DomainEventBus>(DomainEventBus), []);

    const { show: showOFACRejected } = ofacRejectedModal;
    const { show: showManualModal } = manualModal;
    const { show: showSubmissionTimeoutErrorModal } = submissionTimeoutErrorModal;
    const { show: showCanceledParentPolicyErrorModal } = canceledParentPolicyErrorModal;
    const { show: showThankYouErrorModal } = thankYouErrorModal;
    const { show: showDeclinedModal } = declinedModal;

    const handleGetTaskStatusSuccess = useCallback(
        (response: GetTaskStatusResponse) => {
            const {
                application,
                redirectApplicationList,
                isLPLEnabled,
                isPCOMLEnabled,
                isEmbrokerCrimeEnabled,
                isEmbrokerCyberEnabled,
                isEmbrokerExcessEnabled,
            } = response;

            if (application.isOFACRejected) {
                if (hasRole(activeSession, 'broker')) {
                    navigate(URI.build('/broker/dashboard', { modal: 'quoteReferred' }));
                } else {
                    showOFACRejected();
                }
                return;
            }
            if (redirectApplicationList && redirectApplicationList.length > 0) {
                const getRedirectApplicationDirectionsResult = getRedirectApplicationDirections(
                    application,
                    redirectApplicationList,
                );
                if (isErr(getRedirectApplicationDirectionsResult)) {
                    navigateToErrorPage(navigate, getRedirectApplicationDirectionsResult.errors);
                    return;
                }
                const path = getRedirectApplicationDirectionsResult.value;
                navigate(path);
                return;
            }
            if (application.ineligibilityReasons != null) {
                if (
                    application.ineligibilityReasons.investigationNeededReasons.length != 0 &&
                    application.quotingEngine == 'QuotingEngineWCGA'
                ) {
                    showThankYouErrorModal();
                    return;
                }
            }
            const path = getPathBasedOnQuotingEngine({
                application: application,
                isPCOMLEnabled,
                isLPLEnabled,
                isEmbrokerCrimeEnabled,
                isEmbrokerCyberEnabled,
                isEmbrokerExcessEnabled,
                activeSession,
                eventBus,
            });
            if (path) {
                navigate(path);
            } else {
                if (
                    application.ineligibilityReasons &&
                    application.ineligibilityReasons.declinedReasons.length > 0
                ) {
                    showDeclinedModal();
                }
                showManualModal();
            }
        },
        [
            activeSession,
            showOFACRejected,
            navigate,
            showManualModal,
            showThankYouErrorModal,
            eventBus,
            showDeclinedModal,
        ],
    );

    useEffect(() => {
        if (getTaskStatusResult && isErr(getTaskStatusResult)) {
            if (isSubmissionTimeoutError(getTaskStatusResult)) {
                showSubmissionTimeoutErrorModal();
                return;
            }
            if (isParentPolicyCanceledError(getTaskStatusResult)) {
                showCanceledParentPolicyErrorModal();
                return;
            }
            showThankYouErrorModal();
            return;
        }
        if (getTaskStatusResult && isOK(getTaskStatusResult)) {
            handleGetTaskStatusSuccess(getTaskStatusResult.value as GetTaskStatusResponse);
        }
    }, [
        getTaskStatusResult,
        showThankYouErrorModal,
        handleGetTaskStatusSuccess,
        showSubmissionTimeoutErrorModal,
        showCanceledParentPolicyErrorModal,
    ]);

    return (
        <React.Fragment>
            <YouAreAllDone />
            <ThankYouErrorModal modal={thankYouErrorModal} />
            <OfacRejectedModal modal={ofacRejectedModal} />
            <ManualQuotingConfirmationModal modal={manualModal} />
            <SubmissionTimeoutErrorModal modal={submissionTimeoutErrorModal} />
            <CanceledParentPolicyErrorModal modal={canceledParentPolicyErrorModal} />
            <DeclinedModal modal={declinedModal} />
        </React.Fragment>
    );
}

function isSubmissionTimeoutError(failureResult: FailureResult<ErrorLike>): boolean {
    if (failureResult.errors.length === 0) {
        return false;
    }
    return failureResult.errors[0].code === ErrorCode.Timeout;
}

function isParentPolicyCanceledError(failureResult: FailureResult<ErrorLike>): boolean {
    if (failureResult.errors.length === 0) {
        return false;
    }
    return failureResult.errors[0].message === 'parent_policy_canceled';
}

function getRedirectApplicationDirections(
    application: EntityProps<Application>,
    redirectApplicationList: Array<EntityProps<Application>>,
): Result<URI, InvalidArgument> {
    const hasQuotesReady = application.status === 'InsuranceApplicationStatusCodeListQuotesReady';
    if (hasQuotesReady && application.quotingEngine != null) {
        const redirectApplicationDataList = redirectApplicationList.map(mapToRedirectionData);

        const selectedAndQuotableCoverages = application.shoppingCoverageList.filter(
            (coverage) => application.quotableShoppingCoverageList?.includes(coverage) ?? false,
        );

        const encodeShoppingCoverageArrayResult = ShoppingCoverageCodeArray.encode(
            selectedAndQuotableCoverages,
        );
        if (isErr(encodeShoppingCoverageArrayResult)) {
            return encodeShoppingCoverageArrayResult;
        }
        const encodeRedirectionDataResult = RedirectApplicationDataArray.encode(
            redirectApplicationDataList,
        );
        if (isErr(encodeRedirectionDataResult)) {
            return encodeRedirectionDataResult;
        }
        const isRenewal = application.renewedPolicyIdList.length > 0;
        const quoteURIResult = getQuotePageURI(
            application.id,
            application.quotingEngine,
            isRenewal,
        );
        if (isErr(quoteURIResult)) {
            return quoteURIResult;
        }
        const quoteURI = quoteURIResult.value;
        const baseURI = '/shopping/application/partial-quote-redirect';

        return Success(
            URI.build(baseURI, {
                shoppingCoverageList: encodeShoppingCoverageArrayResult.value,
                redirectionData: encodeRedirectionDataResult.value,
                quoteURI,
            }),
        );
    }
    if (redirectApplicationList.length === 1) {
        const redirectApplicationId = redirectApplicationList[0].id;
        return Success(
            URI.build('/shopping/application/full-redirect-continue', {
                redirectApplicationId,
            }),
        );
    }
    const redirectApplicationDataList = redirectApplicationList.map(mapToRedirectionData);
    const encodeRedirectionDataResult = RedirectApplicationDataArray.encode(
        redirectApplicationDataList,
    );
    if (isErr(encodeRedirectionDataResult)) {
        return encodeRedirectionDataResult;
    }
    return Success(
        URI.build('/shopping/application/full-redirect-choose', {
            redirectionData: encodeRedirectionDataResult.value,
        }),
    );
}

function mapToRedirectionData(item: EntityProps<Application>) {
    return {
        id: item.id,
        appTypeList: [item.appType],
    };
}

function getQuotePageURI(
    applicationId: UUID,
    quotingEngine: QuotingEngine,
    isRenewal: boolean,
): Result<URI, InvalidArgument> {
    const baseURI = '/shopping/application/quote';
    const quotingEngineToQuoteURIMap = {
        QuotingEngineESP: isRenewal ? URI`${baseURI}/esp-renewals` : URI`${baseURI}/esp`,
        QuotingEnginePCoML: URI`${baseURI}/pcoml`,
        QuotingEngineWCGA: URI`${baseURI}/wcga`,
        QuotingEngineLPLEverest: URI`${baseURI}/lpl`,
        QuotingEngineCNABOP: URI`${baseURI}/bopcna`,
        QuotingEngineCrime: URI`${baseURI}/crime`,
        QuotingEngineCyber: URI`${baseURI}/cyber`,
        QuotingEngineExcess: URI`${baseURI}/excess`,
        QuotingEngineHartfordBOP: URI`${baseURI}/bop-hartford`,
    };
    if (
        quotingEngine === QuotingEngineManual ||
        quotingEngine === QuotingEngineLawCyber ||
        quotingEngine === QuotingEngineCyberCowbell ||
        quotingEngine === QuotingEngineBOPChubb ||
        quotingEngine === QuotingEngineMPL ||
        quotingEngine === QuotingEngineWCChubb ||
        quotingEngine === QuotingEngineHartfordBOP
    ) {
        return Failure(
            InvalidArgument({
                argument: 'quotingEngine',
                value: `No quote page uri for ${quotingEngine}`,
            }),
        );
    }

    const quotePageURI = quotingEngineToQuoteURIMap[quotingEngine];
    return Success(URI.build(quotePageURI, { applicationId }));
}

interface GetPathBasedOnQuotingEngineRequest {
    readonly application: EntityProps<Application>;
    readonly isLPLEnabled: boolean;
    readonly isPCOMLEnabled: boolean;
    readonly isEmbrokerCrimeEnabled: boolean;
    readonly isEmbrokerCyberEnabled: boolean;
    readonly isEmbrokerExcessEnabled: boolean;
    readonly activeSession: ActiveSession;
    readonly eventBus: DomainEventBus;
}

function getPathBasedOnQuotingEngine({
    application,
    isLPLEnabled,
    isPCOMLEnabled,
    isEmbrokerCrimeEnabled,
    isEmbrokerCyberEnabled,
    isEmbrokerExcessEnabled,
    activeSession,
    eventBus,
}: GetPathBasedOnQuotingEngineRequest): Nullable<URI> {
    const {
        id: applicationId,
        quotingEngine,
        status,
        ineligibilityReasons,
        previousApplicationsQuotingEngineList,
    } = application;

    const isBroker = hasRole(activeSession, 'broker');

    if ([NotEligible, DeclinedByCarrier].includes(status)) {
        if (isBroker) {
            return URI.build('/broker/dashboard', { modal: 'quoteDeclined' });
        }
        return null;
    }

    if (quotingEngine === QuotingEngineESP) {
        if (
            previousApplicationsQuotingEngineList.length === 1 &&
            previousApplicationsQuotingEngineList[0] === QuotingEngineESP
        ) {
            return URI.build('/shopping/application/quote/esp-renewals/', {
                applicationId,
            });
        } else {
            const route = URI.build('/shopping/application/quote/esp/', {
                applicationId,
            });
            return route;
        }
    }

    if (quotingEngine === QuotingEngineWCGA) {
        if (status === Referred) {
            return URI.build('/shopping/application/quote/wcga/referred-estimate/', {
                applicationId,
            });
        }
        return URI.build('/shopping/application/quote/wcga/', { applicationId });
    }

    if (quotingEngine === QuotingEngineHartfordBOP) {
        return URI.build('/shopping/application/quote/bop-hartford/', { applicationId });
    }

    if (quotingEngine === QuotingEngineCNABOP && !isNotEligibleOrCNAQuoteReferred(application)) {
        return URI.build('/shopping/application/quote/bopcna/', { applicationId });
    }

    if (isLPLEnabled && quotingEngine === QuotingEngineLPLEverest) {
        if (status === QuotesReady) {
            return URI.build('/shopping/application/quote/lpl/', { applicationId });
        }
        if (isLPLIndication(application)) {
            return URI.build('/shopping/application/quote/lpl/referred-estimate', {
                applicationId,
            });
        }
        if (status === Referred || status === ClearanceFailed) {
            if (status === ClearanceFailed) {
                const event: ApplicationEarlyClearanceFailed = {
                    id: UUID.create(),
                    origin: 'Application',
                    name: 'EarlyClearanceFailed',
                    createdAt: new Date(Date.now()),
                };
                eventBus.publish(event);
                return URI.build('/shopping/early-quote-reservation');
            }
            const isGapBetweenPolicies = ineligibilityReasons
                ? ineligibilityReasons.referralReasons.some(
                      (reason) => reason == 'Gap Between Policies',
                  )
                : false;
            if (isGapBetweenPolicies) {
                return isBroker
                    ? URI.build('/broker/dashboard', { modal: 'gapBetweenPolicies' })
                    : URI.build('/summary', { modal: 'gapBetweenPolicies' });
            }
            if (isBroker) {
                return resolveBrokerReferral(ineligibilityReasons?.referralReasons);
            }
            return resolveClientReferral(ineligibilityReasons?.referralReasons);
        }
    }

    if (isPCOMLEnabled && quotingEngine === QuotingEnginePCoML) {
        if (status === Referred && !hasExistingCoverage(ineligibilityReasons?.referralReasons)) {
            if (isBroker) {
                return resolveBrokerReferral(ineligibilityReasons?.referralReasons);
            }
            return resolveClientReferral(ineligibilityReasons?.referralReasons);
        }

        return URI.build('/shopping/application/quote/pcoml/', {
            applicationId,
        });
    }

    if (isEmbrokerCrimeEnabled && quotingEngine === QuotingEngineCrime) {
        if (status === Referred) {
            if (isBroker) {
                return resolveBrokerReferral(ineligibilityReasons?.referralReasons);
            }
            return resolveClientReferral(ineligibilityReasons?.referralReasons);
        }
        return URI.build('/shopping/application/quote/crime/', { applicationId });
    }

    if (isEmbrokerCyberEnabled && quotingEngine === QuotingEngineCyber) {
        if (status === Referred) {
            if (isBroker) {
                return resolveBrokerReferral(ineligibilityReasons?.referralReasons);
            }
            return resolveClientReferral(ineligibilityReasons?.referralReasons);
        }
        return URI.build('/shopping/application/quote/cyber/', { applicationId });
    }

    if (isEmbrokerExcessEnabled && quotingEngine === QuotingEngineExcess) {
        if (status === Referred) {
            if (isBroker) {
                return resolveBrokerReferral(ineligibilityReasons?.referralReasons);
            }
            return resolveClientReferral(ineligibilityReasons?.referralReasons);
        }
        return URI.build('/shopping/application/quote/excess', {
            applicationId,
        });
    }

    if (
        application.appType == AppTypeCodeListCyberCowbell &&
        quotingEngine === QuotingEngineCyberCowbell
    ) {
        return URI.build('/shopping/cyber-cowbell', { applicationId });
    }

    if (application.appType == 'AppTypeCodeListLawBundle' && status === BundleSubmitted) {
        return URI.build('/shopping/bundle', { applicationId });
    }

    if (application.appType == 'AppTypeCodeListMPLBundle' && status === BundleSubmitted) {
        if (application.bundleDetails?.creationType == 'BundleCreationTypeExplicit') {
            const eligibleUnderlyingApps = application.bundleDetails?.appDetails?.filter(
                (appDetail) =>
                    appDetail.appType !== 'AppTypeCodeListMPLBundle' &&
                    ![
                        'InsuranceApplicationStatusCodeListNotEligible',
                        'InsuranceApplicationStatusCodeListDeclinedByCarrier',
                    ].includes(appDetail.appStatus),
            );
            if (eligibleUnderlyingApps?.length === 0) {
                return URI.build('/shopping/application/mono-line-ineligible');
            }
        }
        return URI.build('/shopping/bundle', { applicationId });
    }

    if (application.appType === 'AppTypeCodeListGenericBundle' && status === BundleSubmitted) {
        return URI.build('/shopping/bundle', { applicationId });
    }

    if (isBroker) {
        return URI.build('/broker/dashboard', { modal: 'quoteDeclined' });
    }

    return null;
}

function isNotEligibleOrCNAQuoteReferred(application: EntityProps<Application>): boolean {
    return application.isCNAQuoteReferred || application.status === NotEligible;
}

export function resolveBrokerReferral(referralReasons?: readonly string[]): URI {
    if (referralReasons !== undefined) {
        if (referralReasons.some((reason) => reason == 'Broker not licensed in state')) {
            return URI.build('/broker/dashboard', { modal: 'stateUnlicensed' });
        } else if (referralReasons.some((reason) => reason.includes('Client reservation'))) {
            return URI.build('/broker/dashboard', { modal: 'clientReservation' });
        }
    }

    return URI.build('/broker/dashboard', { modal: 'quoteReferred' });
}

function resolveClientReferral(referralReasons?: Array<string>): Nullable<URI> {
    if (referralReasons !== undefined) {
        if (referralReasons.some((reason) => reason == 'Client reservation')) {
            return URI.build('/summary', { modal: 'clientReservation' });
        }
    }

    return null;
}
