import React from 'react';
import { URI } from '@embroker/shotwell/core/types/URI';
import {
    ColumnLayout,
    StackLayout,
    Text,
    TextButton,
    useResponsive,
} from '@embroker/ui-toolkit/v2';
import { Link, useCurrentRoute } from 'react-navi';

interface UnauthenticatedHeaderCtaViewProps {
    content: string;
    href: string;
    dataE2e: string;
    question: string;
}

export const UnauthenticatedHeaderCtaView = ({
    content,
    href,
    dataE2e,
    question,
}: UnauthenticatedHeaderCtaViewProps) => {
    const isSmallScreen = useResponsive({ screenWidth: { smallerThan: 'desktop' } });
    const { url } = useCurrentRoute();
    const queryFilter = { emailAddress: undefined };

    return (
        <StackLayout as={Text}>
            <ColumnLayout as={Text} gap="4">
                {!isSmallScreen && <Text>{question}</Text>}
                <TextButton
                    data-e2e={dataE2e}
                    as={Link}
                    href={URI.build(href, url.search, queryFilter)}
                >
                    {content}
                    {!isSmallScreen && '.'}
                </TextButton>
            </ColumnLayout>
        </StackLayout>
    );
};
