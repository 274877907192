import { FormElement } from '@embroker/service-app-engine';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    isOK,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { format } from 'date-fns';
import { PolicyRepository } from '../../policy/repositories/PolicyRepository';
import { Lob } from '../../policy/types/Lob';
import { hasRole } from '../../userOrg/entities/Session';
import { SessionRepository } from '../../userOrg/repositories/SessionRepository';
import { ApplicantRepository } from '../repositories/ApplicantRepository';
import { ApplicationRepository } from '../repositories/ApplicationRepository';
import { EnrichmentData } from '../types/EnrichmentData';
import { Intake, Underlying } from '../types/enums';
import { QuestionnaireData } from '../types/QuestionnaireData';
import { Quote } from '../types/Quote';
import { ApplicationStates, GetFormEngineContext, toFormEngineInput } from './GetFormEngineContext';
import { GetFormEngineInstance } from './GetFormEngineInstance';
import { OverrideQuestionnaireData } from './OverrideQuestionnaireData';
import { AppDetails } from '../types/AppDetails';
import { OnboardingPrefillQuestionnaireData } from '../../userOrg/types/OnboardingPrefillQuestionnaireData';
import { GetUserOnboardingQuestionnaireData } from '../../userOrg/useCases/GetUserOnboardingQuestionnaireData';
import { GlobalConfig } from '../../config/types/GlobalConfig';

export interface GetApplicationQuestionnaireRequest {
    applicationId: UUID;
    globalConfig?: GlobalConfig;
}

export interface GetApplicationQuestionnaireResponse {
    formEngine: FormElement;
}

export interface GetApplicationQuestionnaire extends UseCase {
    execute(
        request: GetApplicationQuestionnaireRequest,
    ): AsyncResult<GetApplicationQuestionnaireResponse, InvalidArgument | OperationFailed>;
}

@injectable()
class GetApplicationQuestionnaireUseCase extends UseCase implements GetApplicationQuestionnaire {
    public static type = Symbol('Shopping/GetApplicationQuestionnaire');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(Log) private logger: Logger,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
        @inject(SessionRepository) private sessionRepository: SessionRepository,
        @inject(ApplicantRepository) private applicantRepository: ApplicantRepository,
        @inject(PolicyRepository) private policyRepository: PolicyRepository,
        @inject(OverrideQuestionnaireData.type)
        private overrideQuestionnaireData: OverrideQuestionnaireData,
        @inject(GetFormEngineContext.type) private getFormEngineContext: GetFormEngineContext,
        @inject(GetFormEngineInstance.type) private getFormEngineInstance: GetFormEngineInstance,
        @inject(GetUserOnboardingQuestionnaireData.type)
        private getUserOnboardingQuestionnaireData: GetUserOnboardingQuestionnaireData,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
        globalConfig,
    }: GetApplicationQuestionnaireRequest): AsyncResult<
        GetApplicationQuestionnaireResponse,
        InvalidArgument | OperationFailed
    > {
        const isIptChangesEnabled = globalConfig?.espIptChangesEnabled || false;
        const prefillResponse = await this.applicationRepository.getPrefill();
        if (isErr(prefillResponse)) {
            return handleOperationFailure(prefillResponse);
        }

        const onboardingPrefillResponse = await this.getUserOnboardingQuestionnaireData.execute();
        // The .execute() method here makes all attributes of the response object _readonly_
        // This disrupts the typing of downstream data (string[] in particular), so using 'as OnboardingPrefillQuestionnaireData' below
        const onboardingPrefill =
            onboardingPrefillResponse && isOK(onboardingPrefillResponse)
                ? (onboardingPrefillResponse.value as OnboardingPrefillQuestionnaireData)
                : {};

        const prefill = prefillResponse.value;
        const getApplicationResponse = await this.applicationRepository.getApplication(
            applicationId,
        );
        if (isErr(getApplicationResponse)) {
            return handleOperationFailure(getApplicationResponse);
        }
        const { value: application } = getApplicationResponse;

        let questionnaireData: QuestionnaireData;
        try {
            questionnaireData = JSON.parse(application.questionnaireData ?? '{}');
        } catch (error) {
            return Failure(OperationFailed({ message: 'Failed parse questionnaire data' }));
        }

        const enricherDataResult = await this.applicationRepository.getEnricherData(applicationId);
        let enricherData: EnrichmentData = {};
        // if the enricher data fetch fails, we don't want to stop user from starting an application
        if (isOK(enricherDataResult)) {
            enricherData = enricherDataResult.value;
        } else {
            this.logger.error(enricherDataResult.errors);
        }

        const overrideQuestionnaireDataResponse = await this.overrideQuestionnaireData.execute({
            questionnaireData,
            enrichmentData: enricherData,
            prefill,
            onboardingPrefill,
            // Embroker Cyber makes two calls to Data Enricher, the second time
            // with overridden questionnaire data. And we don't want website to
            // change in between, because we retrieve Data Enricher results by
            // website as search key.
            overrideWebsite: !(application.appType == 'AppTypeCodeListEmbrokerCyber'),
            isIntake: application.creationType === Intake,
        });
        if (isErr(overrideQuestionnaireDataResponse)) {
            return handleOperationFailure(overrideQuestionnaireDataResponse);
        }

        const overrideQuestionnaireData = overrideQuestionnaireDataResponse.value.questionnaireData;
        let previousData = {};
        if (application.isRenewal()) {
            const previousDataResult = await this.getPreviousData(
                application.renewedPolicyIdList[0],
            );
            if (isErr(previousDataResult)) {
                return handleOperationFailure(previousDataResult);
            }

            previousData = previousDataResult.value;
        }
        const activeSessionResult = await this.sessionRepository.getActiveSession();
        if (isErr(activeSessionResult)) {
            return handleOperationFailure(activeSessionResult);
        }

        if (activeSessionResult.value === null || activeSessionResult.value === undefined) {
            return Failure(
                OperationFailed({ message: 'Detected null for value in activeSessionResult' }),
            );
        }

        const isBroker = hasRole(activeSessionResult.value, 'broker');
        const applicantResult = await this.applicantRepository.getApplicant();
        if (isErr(applicantResult)) {
            return handleOperationFailure(applicantResult);
        }
        const parsedPrefill = toFormEngineInput(prefill);
        const parsedQuestionnaireData = toFormEngineInput(overrideQuestionnaireData);
        const parsedPreviousData = toFormEngineInput(previousData);

        const shoppingCoverageList = application.shoppingCoverageList;

        let totalFundingDisclaimer = undefined;
        const isMissingTotalFunding =
            parsedPrefill.raised_venture_funding && !parsedPrefill.total_funding_amount;
        const shouldEnrichTotalFunding = !application.isRenewal() && isMissingTotalFunding;

        if (shouldEnrichTotalFunding && enricherData.totalFunding) {
            parsedPrefill.total_funding_amount = Money.toFloat(enricherData.totalFunding);
            totalFundingDisclaimer = getTotalFundingDisclaimer(enricherData);
        }

        const underlyingAppTypes = application.bundleDetails
            ? AppDetails.getAppTypes(application?.bundleDetails?.appDetails, {
                  creationType: Underlying,
              })
            : [];

        const context = {
            data: parsedQuestionnaireData,
            prefill: parsedPrefill,
            applicationState: ApplicationStates.SAVED,
            raisedFunding: applicantResult.value.hasReceivedVCFunding,
            previousData: parsedPreviousData,
            isRenewal: application.isRenewal(),
            isIntake: application.creationType === Intake,
            totalFundingDisclaimer,
            isBroker,
            shoppingCoverageList,
            underlyingAppTypes,
            isIptChangesEnabled: isIptChangesEnabled,
            appTypeList: [application.appType],
            bundleCreationType: application.bundleDetails?.creationType,
            onboardingPrefilledFields: Object.keys(onboardingPrefill),
        };

        const formEngineContextResponse = await this.getFormEngineContext.execute({
            context,
        });

        if (isErr(formEngineContextResponse)) {
            return handleOperationFailure(formEngineContextResponse);
        }
        const { formEngineContext } = formEngineContextResponse.value;

        const getFormEngineResult = await this.getFormEngineInstance.execute({
            appType: application.appType,
            formEngineContext,
        });
        if (isErr(getFormEngineResult)) {
            return getFormEngineResult;
        }
        const formEngine = getFormEngineResult.value.instance;

        return Success({
            formEngine,
        });
    }

    private async getPreviousData(
        renewedPolicyId: UUID,
    ): AsyncResult<any, UnknownEntity | InvalidArgument | OperationFailed> {
        const getPolicyResponse = await this.policyRepository.getPolicy(renewedPolicyId);
        if (isErr(getPolicyResponse)) {
            return getPolicyResponse;
        }

        const policy = getPolicyResponse.value;
        if (!policy.insuranceApplicationId) {
            return Success({});
        }

        const getPreviousApplicationResponse = await this.applicationRepository.getApplication(
            policy.insuranceApplicationId,
        );
        if (isErr(getPreviousApplicationResponse)) {
            return getPreviousApplicationResponse;
        }

        const previousApplication = getPreviousApplicationResponse.value;

        let previousQuestionnaireData: QuestionnaireData;
        try {
            previousQuestionnaireData = JSON.parse(previousApplication.questionnaireData ?? '{}');
        } catch (error) {
            return Failure(
                OperationFailed({ message: 'Failed parse previous questionnaire data' }),
            );
        }

        const policyStartDate = format(policy.startDate, 'yyyy-MM-dd');
        const policyEndDate = format(policy.endDate, 'yyyy-MM-dd');
        const previousLplPolicy = mapToPreviousLPLPolicy(previousApplication.lastQuote);
        const previousEspPolicy = mapToPreviousESPPolicy(policy.lobList);
        const previousPcomlPolicy = mapToPreviousPCoMLPolicy(policy.lobList);
        const policyInsurer = policy.insurerName;
        const policyNumber = policy.policyNumber;
        const policyPremium = moneyToDollarAmount(policy.premiumPerYear);

        return Success({
            ...previousQuestionnaireData,
            policyStartDate,
            policyEndDate,
            policyNumber,
            policyInsurer,
            policyPremium,
            previousLplPolicy,
            previousEspPolicy,
            previousPcomlPolicy,
            quotingEngine: previousApplication.quotingEngine,
        });
    }
}

function mapToPreviousLPLPolicy(quote: Nullable<Quote>) {
    const lplPolicyOptions = quote?.options?.lplEverest;

    if (!quote || !lplPolicyOptions || !quote.accepted) {
        return undefined;
    }

    const claimsExpenseType =
        lplPolicyOptions.claimsExpenseType === 'ClaimsExpenseTypeInAdditionToLimits';
    const deductibleType = lplPolicyOptions.deductibleType === 'DeductibleTypeLossOnly';
    const totalPremium = moneyToDollarAmount(quote.totalPremium);

    return {
        perClaimLimit: lplPolicyOptions.perClaimLimit,
        aggregateLimitLiability: lplPolicyOptions.aggregateLimit,
        perClaimDeductible: lplPolicyOptions.perClaimDeductible,
        claimsExpenseOutside: claimsExpenseType,
        firstDollar: deductibleType,
        currentPremium: totalPremium,
    };
}

function mapToPreviousESPPolicy(lobList: Immutable<Lob[]>) {
    const dnoCoverage = lobList
        .find((lob) => lob.lobType === 'directorsAndOfficersLiabilityLob')
        ?.liabilitySection?.liabilityList?.find(
            (liability) =>
                liability.typeCode ===
                'LiabilityCoverageCodeListIndemnifiableDirectorsAndOfficersLiability',
        );
    const dnoLimit = moneyToDollarAmount(dnoCoverage?.limit1Amount);
    const dnoPremium = moneyToDollarAmount(dnoCoverage?.premiumAmount);

    const epliCoverage = lobList
        .find((lob) => lob.lobType === 'employmentPracticesLiabilityLob')
        ?.liabilitySection?.liabilityList?.find(
            (liability) =>
                liability.typeCode === 'LiabilityCoverageCodeListEmploymentPracticesLiability',
        );
    const epliLimit = moneyToDollarAmount(epliCoverage?.limit1Amount);
    const epliPremium = moneyToDollarAmount(epliCoverage?.premiumAmount);

    const fiduciaryCoverage = lobList
        .find((lob) => lob.lobType === 'fiduciaryLiabilityLob')
        ?.liabilitySection?.liabilityList?.find(
            (liability) => liability.typeCode === 'LiabilityCoverageCodeListFiduciaryLiability',
        );
    const fiduciaryLimit = moneyToDollarAmount(fiduciaryCoverage?.limit1Amount);
    const fiduciaryPremium = moneyToDollarAmount(fiduciaryCoverage?.premiumAmount);

    const eoCyberCoverage = lobList
        .find((lob) => lob.lobType === 'cyberLiabilityLob')
        ?.liabilitySection?.liabilityList?.find(
            (liability) =>
                liability.typeCode ===
                'LiabilityCoverageCodeListTechnologyAndMediaErrorsAndOmissions',
        );
    const eoCyberLimit = moneyToDollarAmount(eoCyberCoverage?.limit1Amount);
    const eoCyberPremium = moneyToDollarAmount(eoCyberCoverage?.premiumAmount);

    return {
        dnoLimit,
        dnoPremium,
        epliLimit,
        epliPremium,
        fiduciaryLimit,
        fiduciaryPremium,
        eoCyberLimit,
        eoCyberPremium,
    };
}

function mapToPreviousPCoMLPolicy(lobList: Immutable<Lob[]>) {
    const dnoCoverage = lobList
        .find((lob) => lob.lobType === 'directorsAndOfficersLiabilityLob')
        ?.liabilitySection?.liabilityList?.find(
            (liability) =>
                liability.typeCode ===
                'LiabilityCoverageCodeListIndemnifiableDirectorsAndOfficersLiability',
        );
    const dnoLimit = moneyToDollarAmount(dnoCoverage?.limit1Amount);
    const dnoPremium = moneyToDollarAmount(dnoCoverage?.premiumAmount);

    const epliCoverage = lobList
        .find((lob) => lob.lobType === 'employmentPracticesLiabilityLob')
        ?.liabilitySection?.liabilityList?.find(
            (liability) =>
                liability.typeCode === 'LiabilityCoverageCodeListEmploymentPracticesLiability',
        );
    const epliLimit = moneyToDollarAmount(epliCoverage?.limit1Amount);
    const epliPremium = moneyToDollarAmount(epliCoverage?.premiumAmount);

    return {
        dnoLimit,
        dnoPremium,
        epliLimit,
        epliPremium,
    };
}

function moneyToDollarAmount(amount?: Nullable<Money>) {
    return amount ? Money.toFloat(amount) : undefined;
}

function getTotalFundingDisclaimer(enrichmentData: EnrichmentData) {
    return {
        title: enrichmentData.totalFundingCrunchbaseHandle
            ? `Powered by <a href="https://www.crunchbase.com/${enrichmentData.totalFundingCrunchbaseHandle}" target="_blank" rel="noopener">Crunchbase</a>. Please review the information and make any relevant changes.`
            : "We've pre-filled some information for you from our data providers. Please review the information and make any relevant changes.",
        tooltip: enrichmentData.totalFundingCrunchbaseHandle
            ? 'Embroker partners with Crunchbase data provider to pre-fill publicly available information about your business to streamline your application process. Some data may not be the most up to date. Please make any changes you like.'
            : 'Embroker partners with various 3rd party data providers to pre-fill publicly available information about your business to streamline your application process. Some data may not be the most up to date. Please make any changes you like.',
    };
}

export const GetApplicationQuestionnaire: UseCaseClass<GetApplicationQuestionnaire> =
    GetApplicationQuestionnaireUseCase;
