import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { CreateTokenCardData, Stripe, StripeCardElement } from '@stripe/stripe-js';
import { ChargeInvoiceInput, InvoiceRepository } from '../repositories/InvoiceRepository';
import { isPaymentErrorCode, PaymentError } from '../types/errors';
import { PaymentEvent } from '../entities/Invoice';
import { Money } from '@embroker/shotwell/core/types/Money';

export interface PayByCreditCardRequest {
    readonly invoiceIds: UUID[];
    readonly stripe: Stripe;
    readonly data: CreateTokenCardData;
    readonly card: StripeCardElement;
    readonly total: Money;
}

export const PayByCreditCardInput = {
    ...defineValidator<PayByCreditCardRequest>({
        invoiceIds: Joi.array().items(UUID.schema).min(1),
        stripe: Joi.object(),
        data: Joi.object(),
        card: Joi.object(),
        total: Money.schema.required(),
    }),
};

export interface PayByCreditCard extends UseCase {
    execute(
        request: PayByCreditCardRequest,
    ): AsyncResult<string, InvalidArgument | OperationFailed | PaymentError>;
}

@injectable()
class PayByCreditCardUseCase extends UseCase {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Payments/PayByCreditCard');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(InvoiceRepository) private invoiceRepo: InvoiceRepository,
    ) {
        super(eventBus);
    }
    public async execute(
        request: PayByCreditCardRequest,
    ): AsyncResult<string, InvalidArgument | OperationFailed | PaymentError> {
        const tokenResult = await this.invoiceRepo.getCCToken(
            request.stripe,
            request.card,
            request.data,
        );

        if (isErr(tokenResult)) {
            return tokenResult;
        }

        if (tokenResult.value === undefined) {
            return Failure(InvalidArgument({ argument: 'token', value: undefined }));
        }

        if (tokenResult.value.card?.funding !== 'credit') {
            return Failure(
                OperationFailed({
                    message:
                        'Debit cards are not supported at this time. \n Please enter a credit card or pay with ACH.',
                    errors: [],
                }),
            );
        }

        const invoiceInputData: ChargeInvoiceInput = {
            invoiceIds: request.invoiceIds,
            token: tokenResult.value.id,
            paymentMethod: 'card',
        };

        const chargeInvoiceInput = ChargeInvoiceInput.create(invoiceInputData);

        if (isErr(chargeInvoiceInput)) {
            return handleOperationFailure(chargeInvoiceInput);
        }

        const chargeResult = await this.invoiceRepo.chargePayment(
            chargeInvoiceInput.value as ChargeInvoiceInput,
        );
        if (isErr(chargeResult)) {
            const paymentSuccess: PaymentEvent = {
                origin: 'Invoice',
                name: 'OnPayment',
                paymentAmount: request.total,
                paymentType: 'credit_card',
                id: UUID.create(),
                createdAt: new Date(Date.now()),
            };

            await this.eventBus.publish(paymentSuccess);
            const paymentError = isPaymentErrorCode(chargeResult.errors);
            if (paymentError) {
                return Failure(paymentError);
            }
            return handleOperationFailure(chargeResult);
        }
        const paymentSuccess: PaymentEvent = {
            origin: 'Invoice',
            name: 'OnPayment',
            paymentAmount: request.total,
            paymentType: 'credit_card',
            id: UUID.create(),
            createdAt: new Date(Date.now()),
        };

        await this.eventBus.publish(paymentSuccess);

        return Success(chargeResult.value);
    }
}

export const PayByCC: UseCaseClass<PayByCreditCard> = PayByCreditCardUseCase;
