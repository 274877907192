import { OperationFailed } from '@embroker/shotwell/core/Error';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ApplicationNotFound, StreamlineRenewalAlreadyPurchased } from '../errors';
import { ApplicationRepository } from '../repositories/ApplicationRepository';
import { GetApplication } from './GetApplication';

interface OptOutFromStreamlineRenewalRequest {
    applicationId: UUID;
}

export interface OptOutFromStreamlineRenewal extends UseCase {
    execute(
        request: OptOutFromStreamlineRenewalRequest,
    ): AsyncResult<void, StreamlineRenewalAlreadyPurchased | ApplicationNotFound | OperationFailed>;
}

class OptOutFromStreamlineRenewalUseCase extends UseCase implements OptOutFromStreamlineRenewal {
    public static type = Symbol('Shopping/OptOutFromStreamlineRenewal');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
        @inject(GetApplication.type) private getApplication: GetApplication,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
    }: OptOutFromStreamlineRenewalRequest): AsyncResult<
        void,
        StreamlineRenewalAlreadyPurchased | ApplicationNotFound | OperationFailed
    > {
        const renewalApplicationResult = await this.getApplication.execute({
            applicationId,
        });
        if (isErr(renewalApplicationResult)) {
            return Failure(ApplicationNotFound());
        } else {
            const appStatus = renewalApplicationResult.value.application.status;
            if (appStatus === 'InsuranceApplicationStatusCodeListPurchased') {
                return Failure(StreamlineRenewalAlreadyPurchased());
            }
        }

        const optOutResult = await this.applicationRepository.optOutFromStreamlineRenewal(
            applicationId,
        );
        return optOutResult;
    }
}

export const OptOutFromStreamlineRenewal: UseCaseClass<OptOutFromStreamlineRenewal> =
    OptOutFromStreamlineRenewalUseCase;
