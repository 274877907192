import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { NBRVFunnelRepository } from '../repositories/NBRVFunnelRepository';
import { NBRVFunnelDetails } from '../repositories/NBRVFunnelRepository/APINBRVFunnelRepository';

export interface SetNBRVFunnelStatus extends UseCase {
    execute(funnelDetails: NBRVFunnelDetails | null): AsyncResult<void>;
}

@injectable()
export class SetNBRVFunnelStatusUseCase extends UseCase implements SetNBRVFunnelStatus {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/SetNBRVFunnelStatus');
    /**
     * Constructor for SetNBRVFunnelStatus use case class instance
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(NBRVFunnelRepository) private nbrvFunnelRepository: NBRVFunnelRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes SetNBRVFunnelStatus use case
     * @returns Nothing if execution was successful
     */
    public async execute(funnelDetails: NBRVFunnelDetails | null): AsyncResult<void> {
        const funnelDetailsResponse = await this.nbrvFunnelRepository.setNBRVFunnelDetails(
            funnelDetails,
        );

        if (isErr(funnelDetailsResponse)) {
            return handleOperationFailure(funnelDetailsResponse);
        }

        return Success();
    }
}

export const SetNBRVFunnelStatus: UseCaseClass<SetNBRVFunnelStatus> = SetNBRVFunnelStatusUseCase;
