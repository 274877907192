import { injectable } from '@embroker/shotwell/core/di';
import { isErr, Success } from '@embroker/shotwell/core/types/Result';
import { NBRVFunnelRepository } from '.';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { JSONSerdes } from '@embroker/shotwell/core/encoding';

const NBRV_FUNNEL_DETAILS = 'NBRVFunnelStatus';

export interface NBRVFunnelDetails {
    shown?: boolean;
    forceShow?: boolean;
    initialSessionId?: UUID;
}

@injectable()
export class APINBRVFunnelRepository implements NBRVFunnelRepository {
    constructor() {
        //
    }

    public async setNBRVFunnelDetails(funnelDetails: NBRVFunnelDetails | null) {
        if (!funnelDetails) {
            window.localStorage.removeItem(NBRV_FUNNEL_DETAILS);
            return Success();
        }
        const currentValue = await this.getNBRVFunnelDetails();
        const value = Object.assign({}, currentValue ? currentValue.value : {}, funnelDetails);
        const funnelDetailsResult = JSONSerdes.serialize(value);
        if (isErr(funnelDetailsResult)) {
            return Success();
        }
        window.localStorage.setItem(NBRV_FUNNEL_DETAILS, funnelDetailsResult.value);
        return Success();
    }

    public async getNBRVFunnelDetails() {
        const obj = window.localStorage.getItem(NBRV_FUNNEL_DETAILS);
        if (!obj) {
            return Success(null);
        }
        const funnelDetailsResult = JSONSerdes.deserialize(obj);
        if (isErr(funnelDetailsResult)) {
            return Success(null);
        }
        return Success(funnelDetailsResult.value as NBRVFunnelDetails);
    }
}
