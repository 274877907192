import { container } from '@embroker/shotwell/core/di';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable, Nullable, Props } from '@embroker/shotwell/core/types';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    ColumnLayout,
    SelectInputAsync,
    SelectOptionAsyncType,
    SelectOptionProps,
    StackLayout,
    Text,
} from '@embroker/ui-toolkit/v2';
import React, { useEffect, useMemo, useState } from 'react';
import { NAICS as NAICSType } from '../../types/NAICS';
import { GetNAICSByCode } from '../../useCases/GetNAICSByCode';
import { GetNAICSBySearchTerm } from '../../useCases/GetNAICSBySearchTerm';

export interface NaicsSearchStarted extends DomainEvent {
    readonly origin: 'Naics';
    readonly name: 'SearchStarted';
    readonly NAICSPagePosition?: string;
}

export interface NaicsSearchStopped extends DomainEvent {
    readonly origin: 'Naics';
    readonly name: 'SearchStopped';
}

export interface NaicsSearched extends DomainEvent {
    readonly origin: 'Naics';
    readonly name: 'Searched';
    readonly query: string;
    readonly results: string[];
    readonly NAICSPagePosition?: string;
}

export interface NaicsSearchSuccess extends DomainEvent {
    readonly origin: 'Naics';
    readonly name: 'SearchSuccess';
    readonly query: string;
    readonly results: string[];
    readonly selectedResult: string;
    readonly rank: number;
    readonly NAICSPagePosition?: string;
}

const MINIMAL_TEXT_INPUT_LENGTH = 2;

interface NAICSSelectProps {
    /**
     * Initial component value. If omitted, component will be empty
     */
    initialValue?: string;
    /**
     * Optional minimal length of text input before search is initiated.
     * Default is set to MINIMAL_TEXT_INPUT_LENGTH, which means that before at least
     * MINIMAL_TEXT_INPUT_LENGTH characters are typed in into input, search will halt.
     * This value will be passed to SelectInputAsync component
     */
    minimumTextLength?: number;
    /**
     * Callback triggered on component input change
     * @param newValue Newly set data
     */
    onChange(newValue: any): void;
    /**
     * Readonly state for input field
     */
    readOnly?: boolean;
}

function getNAICSPagePositionFromURL(): string {
    const locationPath = location.pathname;
    switch (locationPath) {
        case '/company-profile':
            return 'userCompanyProfile';
        case '/shopping/questionnaire/company-details':
            return 'shoppingCompanyProfile';
        case '/broker/dashboard':
            return 'accessAppetiteTool';
        default:
            return '';
    }
}

export const Naics = React.forwardRef(function Naics(
    {
        initialValue,
        onChange,
        minimumTextLength = MINIMAL_TEXT_INPUT_LENGTH,
        readOnly,
        ...props
    }: NAICSSelectProps,
    ref?: React.Ref<HTMLInputElement>,
) {
    const [naicsData, setNAICSData] = useState<Nullable<Immutable<Props<NAICSType>>>>(null);
    const { result } = useUseCase(GetNAICSByCode, {
        naicsCode: initialValue,
    });
    const eventBus = useMemo(() => container.get<DomainEventBus>(DomainEventBus), []);
    const [query, setQuery] = useState(initialValue);
    const [options, setOptions] = useState<SelectOptionAsyncType[]>([]);
    const [isInputFirstTimeInFocus, setInputFirstTimeInFocus] = useState(true);
    const NAICSPagePosition = getNAICSPagePositionFromURL();

    useEffect(() => {
        if (result !== undefined) {
            if (!isErr(result)) {
                setNAICSData(result.value.naics);
            } else {
                setNAICSData(null);
            }
        }
    }, [initialValue, result, naicsData]);

    // dependencies still being fetched - loading component
    if (naicsData === undefined || result == undefined || naicsData === null) {
        return null;
    }

    const renderOptionLabel = (
        data: SelectOptionAsyncType,
        _inputValue: string,
        optionsProps: SelectOptionProps,
    ) => {
        const label = data.label;
        const naicsCode = data.value;

        const textColor = optionsProps.isSelected ? 'primary-500' : undefined;

        return (
            <StackLayout gap="4">
                <ColumnLayout split="-1" gap="16">
                    <Text as="span" style="body 1" color={textColor}>
                        {label}
                    </Text>
                    <Text as="span" style="body 1" color={textColor}>
                        {naicsCode}
                    </Text>
                </ColumnLayout>
                <Text style="body 2" color={textColor}>
                    Matches:{' '}
                    {data.matches !== undefined && data.matches.length > 0
                        ? data?.matches?.join(', ')
                        : naicsCode}
                </Text>
            </StackLayout>
        );
    };

    const searchForNAICS = async (selectInputText: string) => {
        const result = await execute(GetNAICSBySearchTerm, { searchTerm: selectInputText });
        if (isErr(result)) {
            return Promise.resolve([]);
        }
        const options: SelectOptionAsyncType[] = [];
        for (const naicsItem of result.value.naicsList) {
            options.push({
                label: naicsItem.name,
                value: naicsItem.code,
                matches: (naicsItem.matches ?? []) as string[],
            });
        }
        setQuery(selectInputText);
        setOptions(options);
        publishSearchEvent(selectInputText, options);
        return Promise.resolve(options);
    };

    const publishSearchEvent = (query: string, options: SelectOptionAsyncType[]) => {
        const results = options.map((option) => option.label);
        const event: NaicsSearched = {
            id: UUID.create(),
            origin: 'Naics',
            name: 'Searched',
            query,
            NAICSPagePosition,
            results,
            createdAt: new Date(Date.now()),
        };
        eventBus.publish(event);
    };

    const handleOnChange = (newValue: SelectOptionAsyncType) => {
        if (newValue != null) {
            publishSuccessfulSearchEvent(newValue);
        }
        if (onChange) {
            onChange(newValue);
        }
    };

    const publishSuccessfulSearchEvent = (selectedValue: SelectOptionAsyncType) => {
        const results = options.map((option) => option.label);
        const rank = 1 + options.findIndex((option) => option.value === selectedValue.value);
        const event: NaicsSearchSuccess = {
            id: UUID.create(),
            origin: 'Naics',
            name: 'SearchSuccess',
            query: query !== undefined ? query : '',
            results,
            selectedResult: selectedValue.label,
            rank,
            NAICSPagePosition,
            createdAt: new Date(Date.now()),
        };
        eventBus.publish(event);
    };

    const handleOnFocus = () => {
        if (!readOnly && isInputFirstTimeInFocus) {
            setInputFirstTimeInFocus(false);
            publishSearchStartedEvent();
        }
    };

    const publishSearchStartedEvent = () => {
        const event: NaicsSearchStarted = {
            id: UUID.create(),
            origin: 'Naics',
            name: 'SearchStarted',
            createdAt: new Date(Date.now()),
            NAICSPagePosition,
        };
        eventBus.publish(event);
    };

    const handleOnBlur = () => {
        if (!readOnly) {
            publishSearchStoppedEvent();
        }
    };

    const publishSearchStoppedEvent = () => {
        const event: NaicsSearchStopped = {
            id: UUID.create(),
            origin: 'Naics',
            name: 'SearchStopped',
            createdAt: new Date(Date.now()),
        };
        eventBus.publish(event);
    };

    return (
        <SelectInputAsync
            {...props}
            ref={ref}
            renderOptionLabel={renderOptionLabel}
            maxMenuHeight={420}
            minimumInputLength={minimumTextLength}
            isDisabled={readOnly}
            onChange={handleOnChange}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            options={[
                {
                    label: naicsData.name,
                    value: naicsData.code,
                    matches: (naicsData.matches ?? []) as string[],
                },
            ]}
            label={'Industry name, description, NAICS code, etc.'}
            request={searchForNAICS}
            noOptionsText="Start typing..."
        />
    );
});
