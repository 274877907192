import { StripePaymentsChargeSourceType } from '@embroker/shotwell-api/app';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { Currency } from '@embroker/shotwell/core/types/Money';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { CreateTokenCardData, Stripe, StripeCardElement, Token } from '@stripe/stripe-js';
import { Invoice } from '../../entities/Invoice';
import { PaymentError } from '../../types/errors';
import { SendIneligiblePaymentsRequest } from '../../useCases/SendIneligiblePayments';
import { PayByAscendInput } from '../../useCases/PayByAscend';

export interface ACHTokenRequest {
    readonly country: string;
    readonly currency: Currency;
    readonly accountNumber: string;
    readonly routingNumber: string;
    readonly accountHolderName: string;
    readonly accountHolderType: string;
}

export interface FilteredInvoices {
    duePayments: Immutable<Invoice[]>;
    creditPayments: Immutable<Invoice[]>;
    chargedPayments: Immutable<Invoice[]>;
}

export const ACHTokenRequest = {
    ...defineValidator<ACHTokenRequest>({
        country: Joi.string(),
        currency: Joi.string(),
        accountNumber: Joi.string(),
        routingNumber: Joi.string(),
        accountHolderName: Joi.string(),
        accountHolderType: Joi.string(),
    }),
    create(token: ACHTokenRequest) {
        return ACHTokenRequest.validate(token);
    },
};

export interface ChargeInvoiceInput {
    readonly invoiceIds: UUID[];
    readonly token: string;
    readonly paymentMethod: StripePaymentsChargeSourceType;
}

export const ChargeInvoiceInput = {
    ...defineValidator<ChargeInvoiceInput>({
        invoiceIds: Joi.array().items(UUID.schema).min(1),
        token: Joi.string(),
        paymentMethod: Joi.string().valid('ach', 'card'),
    }),
    create(inputData: ChargeInvoiceInput) {
        return ChargeInvoiceInput.validate(inputData);
    },
};

export interface InvoiceRepository {
    getFiltered(
        organizationId: UUID,
    ): AsyncResult<FilteredInvoices, InvalidArgument | OperationFailed>;
    getByIds(
        ids: UUID[],
    ): AsyncResult<Invoice[], UnknownEntity | InvalidArgument | OperationFailed>;
    getById(id: UUID): AsyncResult<Invoice, UnknownEntity | InvalidArgument | OperationFailed>;
    update(e: Invoice): AsyncResult<Invoice, UnknownEntity | InvalidArgument | OperationFailed>;
    chargePayment(
        chargeInvoiceInput: Immutable<ChargeInvoiceInput>,
    ): AsyncResult<string, InvalidArgument | OperationFailed | PaymentError>;
    sendPaymentToAscend(
        ascendInput: Immutable<PayByAscendInput>,
    ): AsyncResult<string, InvalidArgument | OperationFailed | PaymentError>;
    getStripeConfig(): AsyncResult<any, InvalidArgument | OperationFailed>;
    getACHToken(
        input: ACHTokenRequest,
        stripe: Stripe,
    ): AsyncResult<Token | undefined, OperationFailed>;
    getCCToken(
        stripe: Stripe,
        card: StripeCardElement,
        data: CreateTokenCardData,
    ): AsyncResult<Token | undefined, OperationFailed>;
    getPublicInvoice(
        publicKey: string,
    ): AsyncResult<Immutable<Invoice>, InvalidArgument | OperationFailed | PaymentError>;
    getBundlePublicInvoiceList(
        publicKey: UUID,
    ): AsyncResult<Invoice[], InvalidArgument | OperationFailed | PaymentError>;
    sendIneligibleInvoices(
        data: SendIneligiblePaymentsRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed>;
}

export const InvoiceRepository = Symbol('InvoiceRepository');
