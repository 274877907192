import { isOK } from '@embroker/shotwell/core/types/Result';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { isValid, parseISO, startOfDay, startOfToday } from 'date-fns';
import { useEffect, useState } from 'react';
import { GetCrimeConfig } from '../../useCases/GetCrimeConfig';

export interface UseDevModeTodayResult {
    readonly result: Date;
    readonly isLoading: boolean;
}

export function useDevModeToday(): UseDevModeTodayResult {
    const [result, setResult] = useState<Date>(startOfToday());
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { isLoading: isLoadingCrimeConfig, result: crimeConfigResult } =
        useUseCase(GetCrimeConfig);

    useEffect(() => {
        if (!isLoadingCrimeConfig && crimeConfigResult !== undefined) {
            if (isOK(crimeConfigResult)) {
                const devModeDate = crimeConfigResult.value.crimeDevModeEffectiveDate;
                const date = parseDate(devModeDate) ?? startOfToday();
                setResult(date);
            }
            setIsLoading(false);
        }
    }, [isLoadingCrimeConfig, crimeConfigResult]);

    return { result, isLoading };
}

function parseDate(date: string): Date | undefined {
    return isValid(parseISO(date)) ? startOfDay(parseISO(date)) : undefined;
}
