import { inject, injectable } from '@embroker/shotwell/core/di';
import { Aborted, OperationFailed, Timeout } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { defineValidator } from '@embroker/shotwell/core/validation/schema';
import { QuoteOptionsExtensionRequestList } from '../types/QuoteOptionsExtensionRequest';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ESPQuoteRepository } from '../repositories/ESPQuoteRepository';

export interface GetHigherLimitRequestListRequest {
    applicationId: UUID;
}

export interface GetHigherLimitRequestListResponse {
    readonly higherLimitRequestList: QuoteOptionsExtensionRequestList;
}

export const GetHigherLimitRequestListResponse = {
    ...defineValidator<GetHigherLimitRequestListResponse>({
        higherLimitRequestList: QuoteOptionsExtensionRequestList.schema,
    }),
    create(gethigherLimitRequestListResponse: GetHigherLimitRequestListResponse) {
        return GetHigherLimitRequestListResponse.validate(gethigherLimitRequestListResponse);
    },
};

export interface GetHigherLimitRequestList extends UseCase {
    execute(
        request: GetHigherLimitRequestListRequest,
    ): AsyncResult<QuoteOptionsExtensionRequestList, OperationFailed | Aborted | Timeout>;
}

@injectable()
export class GetGetHigherLimitRequestListUseCase extends UseCase {
    public static type = Symbol('InsuranceApplicationRestriction/GetHigherLimitRequestList');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ESPQuoteRepository) private espQuoteRepository: ESPQuoteRepository,
    ) {
        super(eventBus);
    }

    async execute({
        applicationId,
    }: GetHigherLimitRequestListRequest): AsyncResult<
        QuoteOptionsExtensionRequestList,
        OperationFailed | Aborted | Timeout
    > {
        const result = await this.espQuoteRepository.getHigherLimitRequests(applicationId);

        if (isErr(result)) {
            return Failure(
                OperationFailed({
                    message: 'Failed to get higher limit request list',
                    errors: result.errors,
                }),
            );
        }

        return Success(result.value);
    }
}

export const GetHigherLimitRequestList: UseCaseClass<GetHigherLimitRequestList> =
    GetGetHigherLimitRequestListUseCase;
