import { StructuralComponentTypeCodeListItem } from '@embroker/shotwell-api/enums';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { AppTypeCode, ShoppingCoverage } from '../../../shopping/types/enums';
import { SKU } from '../../types/SKU';

export interface SKURepository {
    calculateSKU(input: CalculateSKURequest): AsyncResult<SKU, InvalidArgument | OperationFailed>;
    calculateSKUFromAppTypes(
        appTypeList: readonly AppTypeCode[],
    ): AsyncResult<SKU, InvalidArgument | OperationFailed>;
    calculateSKUFromShareablePolicies(
        input: CalculateSKUFromShareablePoliciesRequest,
    ): AsyncResult<SKU, InvalidArgument | OperationFailed>;
}

export type CalculateSKURequest =
    | CalculateSKUQuestionnaireChangedRequest
    | CalculateSKUAppCreatedRequest
    | CalculateSKUQuoteRequest
    | CalculateSKUAppSubmitForReviewRequest
    | CalculateSKUPurchaseRequest;

export interface CalculateSKUQuestionnaireChangedRequest {
    readonly event: 'questionnaire_changed';
    readonly appType: AppTypeCode;
    readonly shoppingCoverageList: readonly ShoppingCoverage[];
    readonly questionnaireData?: string;
    readonly applicationId?: UUID;
}

export interface CalculateSKUAppCreatedRequest {
    readonly event: 'app_created';
    readonly appType: AppTypeCode;
    readonly shoppingCoverageList: readonly ShoppingCoverage[];
    readonly questionnaireData?: string;
    readonly applicationId?: UUID;
}

export interface CalculateSKUQuoteRequest {
    readonly event: 'quote';
    readonly applicationId: UUID;
}

export interface CalculateSKUPurchaseRequest {
    readonly event: 'purchase';
    readonly applicationId: UUID;
}

export interface CalculateSKUAppSubmitForReviewRequest {
    readonly event: 'submit_for_review';
    readonly applicationId: UUID;
}

export interface CalculateSKUFromShareablePoliciesRequest {
    readonly shareablePolicyList: ShareablePolicy[];
}

export interface ShareablePolicy {
    id: UUID;
    structuralComponentList: StructuralComponentTypeCodeListItem[];
}

export const SKURepository = Symbol('SKURepository');
