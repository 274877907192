import React from 'react';
import { HeroBanner, Text } from '@embroker/ui-toolkit/v2';

interface BOPHartfordHeroBannerProps {
    onDismiss: () => void;
    title: React.ReactNode;
    subtitle?: React.ReactNode;
}

export const BOPHartfordHeroBanner = ({
    onDismiss,
    title = 'Your Customizable Quote',
    subtitle,
}: BOPHartfordHeroBannerProps) => {
    return (
        <HeroBanner
            onDismiss={onDismiss}
            dismissAppearance="save-and-exit"
            data-e2e="bundle-hero-banner"
        >
            <Text as="span" style="heading 1">
                {title}
            </Text>
            {subtitle && (
                <Text as="span" style="heading 5">
                    {subtitle}
                </Text>
            )}
        </HeroBanner>
    );
};
