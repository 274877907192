import React, { SyntheticEvent } from 'react';

import {
    Button,
    ColumnLayout,
    Modal,
    ModalActions,
    ModalState,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import { Link } from '../../../../view/components';

export interface IssueCertificateFailProps {
    onClose(): void;
    modal: ModalState & ModalActions;
    hideModal: Function;
}

export const IssueCertificateFailModal = ({
    onClose,
    modal,
    hideModal,
}: IssueCertificateFailProps) => {
    return (
        <Modal {...modal}>
            <StackLayout gap="32">
                <StackLayout gap="24">
                    <Text style="heading 3">Something went wrong.</Text>
                    <Text>We've failed to issue a certificate. Please try again.</Text>
                </StackLayout>
                <ColumnLayout gap="32">
                    <Button
                        onClick={(event: SyntheticEvent) => {
                            hideModal();
                            onClose();
                        }}
                    >
                        Try again
                    </Button>
                    <TextButton as={Link} href="/support/contact">
                        Contact Support
                    </TextButton>
                </ColumnLayout>
            </StackLayout>
        </Modal>
    );
};
