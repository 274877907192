import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { State } from '@embroker/shotwell/core/types/StateList';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    BoxLayout,
    CardLayout,
    CenterLayout,
    ColumnLayout,
    Immutable,
    Nullable,
    PageLayout,
    Spinner,
    StackLayout,
    StatusMessage,
    Table,
    Text,
    TextButton,
    useStaticTable,
} from '@embroker/ui-toolkit/v2';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from '../../../../view/AppContext';
import { Link } from '../../../../view/components/Link/Link';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { BrokerOrganization } from '../../../repositories';
import { GetBrokerOrganizations } from '../../../useCases/GetBrokerOrganizations';
import { BrokerFilter } from '../BrokerFilter';
import { BrokerTableHeader } from '../BrokerTableHeader';
import { CompanyNameLink } from '../policies/BrokerPoliciesTableData';
import { OrganizationSearch } from './OrganizationSearch';

type Organizations = Immutable<Array<BrokerOrganization>>;

export interface SearchResultsTableData extends Record<string, unknown> {
    readonly id: UUID;
    readonly companyName: React.ReactNode;
    readonly managingBroker: React.ReactNode;
    readonly location: React.ReactNode;
}

function isValidSearch(input: string) {
    return input.length > 0;
}

function createLabel(input: string) {
    return `Search for "${input}"`;
}

export interface BrokerOrganizationsProps {
    filter?: string;
}

export function BrokerOrganizations({ filter: defaultFilter }: BrokerOrganizationsProps) {
    const maxSearchResultsCount = 50;
    const itemsPerPage = 10;
    const { navigate } = useNavigation();
    const { activeSession } = useContext(AppContext);
    const [searchFilter, setSearchFilter] = useState(defaultFilter);
    const [brokerFilter, setBrokerFilter] = useState<'all' | UUID>(
        defaultFilter || activeSession.userId == null ? 'all' : activeSession.userId,
    );
    const [organizations, setOrganizations] = useState<Organizations>([]);
    const [inputText, setInputText] = useState<string | undefined>(defaultFilter);
    const { result, isLoading } = useUseCase(GetBrokerOrganizations, {
        broker: brokerFilter == 'all' ? undefined : brokerFilter,
        filter: searchFilter,
        count: maxSearchResultsCount + 1,
    });

    const items = useMemo(
        () =>
            toBrokerOrganizationsTableData({
                organizations,
            }).slice(0, maxSearchResultsCount),
        [organizations],
    );

    useEffect(() => {
        if (result && isOK(result)) {
            setOrganizations(result.value);
        } else if (result && isErr(result)) {
            setOrganizations([]);
        }
    }, [result]);

    const handleSelectOrganization = (item: { value: Nullable<UUID>; label: string }) => {
        if (item.value != null) {
            navigate(
                URI.build('/broker/organization-info', {
                    organizationId: item.value,
                }),
            );
        } else {
            setSearchFilter(inputText);
        }
    };

    const handleOnSearch = (input: string) => {
        setSearchFilter(input);
    };

    const handleClearSearch = () => {
        setInputText(undefined);
        setSearchFilter(undefined);
    };

    const handleBrokerFilterChange = (value: 'all' | UUID) => {
        setBrokerFilter(value);
    };

    const showTruncatedResultsWarning = organizations.length > maxSearchResultsCount;

    const { visibleItems, pagination } = useStaticTable<SearchResultsTableData>({
        items,
        itemsPerPage,
    });

    const hasItems = visibleItems.length !== 0;

    return (
        <PageLayout.Section>
            <StackLayout>
                <ColumnLayout split="-1">
                    <TextButton
                        size="small"
                        as={Link}
                        icon="bold-caret-left"
                        iconPosition="left"
                        href="/broker/dashboard"
                    >
                        Back to dashboard
                    </TextButton>
                    <OrganizationSearch
                        placeholder="Search by client name"
                        onSelect={handleSelectOrganization}
                        onSearch={handleOnSearch}
                        searchLabel={createLabel}
                        isValidSearch={isValidSearch}
                        inputValue={inputText}
                        onInputChange={(value) => setInputText(value)}
                        minimumTextLength={3}
                        count={5}
                    />
                </ColumnLayout>
                {showTruncatedResultsWarning ? (
                    <StatusMessage status="info">
                        Showing the first <b>{maxSearchResultsCount}</b> clients only. Please use
                        the search to get more specific results.
                    </StatusMessage>
                ) : null}
                {searchFilter && hasItems && (
                    <Text>
                        {`Your search for "${searchFilter}" brought the following matches (10 results per page).`}
                    </Text>
                )}
                <CardLayout>
                    <CardLayout.Header>
                        <BrokerTableHeader
                            noItems={!hasItems}
                            pagination={pagination}
                            title={searchFilter ? 'Search Results' : 'Your Clients'}
                        />
                    </CardLayout.Header>
                    <CardLayout.Body>
                        <StackLayout gap={hasItems ? 'none' : '24'}>
                            <BrokerFilter
                                onBrokerSelect={handleBrokerFilterChange}
                                selectedBroker={brokerFilter}
                            />
                            {hasItems && !isLoading && (
                                <Table>
                                    <Table.Header>
                                        <Table.Column>Client</Table.Column>
                                        <Table.Column>Broker</Table.Column>
                                        <Table.Column>Location</Table.Column>
                                        <Table.Column />
                                    </Table.Header>
                                    <Table.Body>
                                        {visibleItems.map((row) => (
                                            <Table.Row key={row.id as UUID}>
                                                <Table.Cell truncate>{row.companyName}</Table.Cell>
                                                <Table.Cell truncate>
                                                    {row.managingBroker}
                                                </Table.Cell>
                                                <Table.Cell colSpan={2}>{row.location}</Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                            )}
                            {!hasItems && !isLoading && (
                                <BoxLayout gap="24">
                                    <CenterLayout centerText>
                                        <Text style="label 1">
                                            {searchFilter
                                                ? `We couldn't find a match for "${searchFilter}". Please try a different search term.`
                                                : 'You haven’t added any clients yet. Start adding clients by clicking the ‘Start Quote’ button on your dashboard.'}
                                        </Text>
                                    </CenterLayout>
                                </BoxLayout>
                            )}
                        </StackLayout>
                    </CardLayout.Body>
                </CardLayout>
                {isLoading && <Spinner />}
                {searchFilter ? (
                    <TextButton icon="trash" iconPosition="left" onClick={handleClearSearch}>
                        Clear search
                    </TextButton>
                ) : null}
            </StackLayout>
        </PageLayout.Section>
    );
}

function toBrokerOrganizationsTableData({
    organizations,
}: {
    organizations: Organizations;
}): SearchResultsTableData[] {
    return organizations.map((row) => {
        return {
            id: row.id as UUID,
            companyName: <CompanyNameLink name={row.name} id={row.id as UUID} />,
            managingBroker: <Text>{row.broker ?? 'N/A'}</Text>,
            location: <Text>{createLocationInfo(row.state, row.city)}</Text>,
        };
    });
}

function createLocationInfo(state?: State, city?: string): string {
    if (state == undefined && city == undefined) {
        return 'N/A';
    }
    return `${city ?? 'N/A'}, ${state ?? 'N/A'}`;
}
