import { Module } from '../Module';
import { ContainerModule } from '@embroker/shotwell/core/di';
import { FeatureRolloutRepository } from './repositories';
import { APIFeatureRolloutRepository } from './repositories/APIFeatureRolloutRepository';
import { GetEnrolledUserFeatures } from './useCases/GetEnrolledUserFeatures';

export const FeatureRolloutModule: Module = {
    container: new ContainerModule((bind) => {
        bind<FeatureRolloutRepository>(FeatureRolloutRepository)
            .to(APIFeatureRolloutRepository)
            .inSingletonScope();
        bind<GetEnrolledUserFeatures>(GetEnrolledUserFeatures.type)
            .to(GetEnrolledUserFeatures)
            .inSingletonScope();
    }),
};
