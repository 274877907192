import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { Quote } from '../entities/Quote';

export interface SignQuoteRequest {
    quote: Quote;
}

export interface SignQuoteResponse {
    quote: Immutable<Quote>;
}

export interface SignQuote extends UseCase {
    execute(
        request: SignQuoteRequest,
    ): AsyncResult<SignQuoteResponse, UnknownEntity | InvalidArgument | OperationFailed>;
}

class SignQuoteUseCase extends UseCase implements SignQuote {
    public static type = Symbol('Quote/SignQuote');

    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    public async execute({
        quote,
    }: SignQuoteRequest): AsyncResult<
        SignQuoteResponse,
        UnknownEntity | InvalidArgument | OperationFailed
    > {
        const signResult = quote.sign();

        if (isErr(signResult)) {
            return handleOperationFailure(signResult);
        }

        return Success<SignQuoteResponse>({
            quote,
        });
    }
}

export const SignQuote: UseCaseClass<SignQuote> = SignQuoteUseCase;
