import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Table, Text, TextButton, ColumnLayout } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { GetDocumentUrl, GetDocumentUrlRequest } from '../../../documents/useCases/GetDocumentUrl';
import { ClaimDetails } from '../../types/ClaimDetails';
import { Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { ClaimStatusCodeListItem } from '@embroker/shotwell-api/enums';
import { Claimant } from '../../entities/Claim';
import { ClaimDocument } from '../../types/ClaimDocument';

interface ClaimList {
    claimNumber: string;
    lossDate: Nullable<Date>;
    reportedDate: Date;
    closedDate: Nullable<Date>;
    valuationDate: Nullable<Date>;
    status: Nullable<ClaimStatusCodeListItem>;
    amountPaid: Nullable<Money>;
    reservedAmount: Nullable<Money>;
    adjusterName: Nullable<string>;
    causeOfLoss: Nullable<string>;
    claimList: ClaimDetails[];
    documents: ClaimDocument[];
    claimantList: Claimant[];
}

interface ClaimDetailsProps {
    claimDetails: ClaimList;
}
export const ClaimDocumentList = ({ claimDetails }: ClaimDetailsProps) => {
    return (
        <Table style="subtle">
            <Table.Header>
                <Table.Column data-e2e="claims-doc-list-cause-of-loss-header">
                    Cause of loss
                </Table.Column>
                <Table.Column data-e2e="claims-doc-list-claimant-header">Claimants</Table.Column>
                <Table.Column data-e2e="claims-doc-list-adjuster-header">Adjuster</Table.Column>
                <Table.Column data-e2e="claims-doc-list-docs-header">Documents</Table.Column>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell data-e2e="claims-doc-list-cause-of-loss">
                        {claimDetails.causeOfLoss}
                    </Table.Cell>
                    <Table.Cell data-e2e="claims-doc-list-claimant-name-wrapper">
                        {claimDetails.claimantList?.map((claimant) => {
                            return <Text key={claimant.id}>{claimant.name}</Text>;
                        })}
                    </Table.Cell>
                    <Table.Cell data-e2e="claims-doc-list-adjuster-name-wrapper" truncate>
                        {claimDetails.adjusterName}
                    </Table.Cell>
                    <Table.Cell>
                        {claimDetails.documents.map((document, index) => (
                            <ColumnLayout data-e2e="claims-doc-list-documents-wrapper" key={index}>
                                <DocumentLink
                                    key={document.id}
                                    fileKey={document.fileKey}
                                    fileName={document.name}
                                />
                            </ColumnLayout>
                        ))}
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    );
};

function DocumentLink({ fileKey, fileName }: { fileKey: string; fileName: string }) {
    const downloadHandler = () => {
        execute(GetDocumentUrl, { fileKey: fileKey } as GetDocumentUrlRequest).then((result) => {
            if (isOK(result)) {
                window.open(result.value.downloadUrl, '_blank');
            } else {
                container.get<Logger>(Log).error(result.errors);
            }
        });
    };

    return <TextButton onClick={downloadHandler}>{fileName}</TextButton>;
}
