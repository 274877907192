import React from 'react';
import {
    BorderStyle,
    Card,
    CheckBox,
    ColumnLayout,
    Icon,
    IconName,
    List,
    StackLayout,
    StatusType,
    Text,
    TextButton,
    useResponsive,
} from '@embroker/ui-toolkit/v2';

export enum TechVerticalRecommendationType {
    none = 'none',
    mustHaveForTechCompanies = 'mustHaveForTechCompanies',
    mustHaveForTechConsultants = 'mustHaveForTechConsultants',
}

interface TechVerticalRecommendationTypeStyle {
    borderStyle?: BorderStyle;
    statusLabelText?: string;
    statusLabelType?: StatusType;
}

const RECOMMENDATION_TYPE_STYLES: Record<
    TechVerticalRecommendationType,
    TechVerticalRecommendationTypeStyle | null
> = {
    none: {
        borderStyle: 'ui-300',
    },
    mustHaveForTechCompanies: {
        borderStyle: 'ui-500',
        statusLabelText: 'MUST-HAVE FOR TECH COMPANIES',
        statusLabelType: 'gray',
    },
    mustHaveForTechConsultants: {
        borderStyle: 'ui-500',
        statusLabelText: 'MUST-HAVE FOR TECH CONSULTANTS',
        statusLabelType: 'gray',
    },
};

interface TechVerticalProductSelectionCardColumn {
    title: string;
    list: string[];
}

interface TechVerticalProductSelectionCardProps {
    recommendationType: TechVerticalRecommendationType;
    iconName: IconName;
    title: string;
    children: React.ReactNode;
    isSelected?: boolean;
    onTitleTooltipClick?: () => void;
    onCoverageSelectionChange?: () => void;
    className?: string;
    canSelect?: boolean;
    columnLists: {
        leftColumn: TechVerticalProductSelectionCardColumn;
        rightColumn: TechVerticalProductSelectionCardColumn;
    };
}

export function TechVerticalProductSelectionCard({
    recommendationType,
    iconName,
    title,
    children,
    isSelected,
    onTitleTooltipClick,
    onCoverageSelectionChange,
    className,
    canSelect = true,
    columnLists,
}: TechVerticalProductSelectionCardProps) {
    const recommendationTypeStyle = RECOMMENDATION_TYPE_STYLES[recommendationType];

    const isMobile = useResponsive({ screenWidth: { smallerThan: 'large-tablet' } });

    const ColumnLists = (
        <React.Fragment>
            {columnLists.leftColumn ? (
                <StackLayout gap="16">
                    <Text style="body 1">{columnLists.leftColumn.title}</Text>
                    <List>
                        {columnLists.leftColumn.list.map((item) => (
                            <List.Item key={item} icon="check">
                                <Text style="body 1" as="div">
                                    {item}
                                </Text>
                            </List.Item>
                        ))}
                    </List>
                </StackLayout>
            ) : null}
            {columnLists.rightColumn ? (
                <StackLayout gap="16">
                    <Text style="body 1">{columnLists.rightColumn.title}</Text>
                    <List>
                        {columnLists.rightColumn.list.map((item) => (
                            <List.Item key={item} icon="shield-check" iconColor="primary-500">
                                <Text style="body 1" as="div">
                                    {item}
                                </Text>
                            </List.Item>
                        ))}
                    </List>
                </StackLayout>
            ) : null}
        </React.Fragment>
    );

    return (
        <Card
            appearance="box"
            borderRadius="16"
            borderStyle={recommendationTypeStyle?.borderStyle ?? 'none'}
            statusLabel={recommendationTypeStyle?.statusLabelText}
            statusLabelPosition="center"
            statusLabelType={recommendationTypeStyle?.statusLabelType}
            className={className}
        >
            <StackLayout gap="24">
                <StackLayout gap="4">
                    <StackLayout gap="8">
                        <ColumnLayout top split="-1">
                            <Icon size="large" name={iconName} />
                            {canSelect ? (
                                <CheckBox
                                    checked={isSelected}
                                    appearance="toggle"
                                    onChange={onCoverageSelectionChange}
                                    data-e2e="coverage-toggle"
                                    inline
                                />
                            ) : (
                                <span />
                            )}
                        </ColumnLayout>
                        <ColumnLayout center gap="8">
                            <Text style="heading 5">{title}</Text>
                            {!!onTitleTooltipClick && (
                                <React.Fragment>
                                    &nbsp;&nbsp;
                                    <TextButton
                                        icon="info-outline"
                                        verticalAlign="middle"
                                        onClick={onTitleTooltipClick}
                                    />
                                </React.Fragment>
                            )}
                        </ColumnLayout>
                    </StackLayout>
                    <StackLayout gap="24">
                        {children}
                        {isMobile ? (
                            <StackLayout gap="24">{ColumnLists}</StackLayout>
                        ) : (
                            <ColumnLayout gap="24" grow="fixed">
                                {ColumnLists}
                            </ColumnLayout>
                        )}
                    </StackLayout>
                </StackLayout>
            </StackLayout>
        </Card>
    );
}
