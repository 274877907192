import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { Nullable } from '@embroker/shotwell/core/types';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Certificate } from '../../entities/Certificate';
import {
    CertificateNotFound,
    CreateSelfServingCertificateFailed,
    GetCertificatesForActiveOrganizationFailed,
    GetSelfServingCoverageListFailed,
    GetShareableCertificateDataFailed,
    PreviewSelfServingCertificateFailed,
    SelfServingCertificateRequestFailed,
    SaveCertificatePhoneNumberNull,
} from '../../errors';
import { CertificateEntityRole } from '../../types/CertificateEntityRole';
import { SelfServingCertificateCoverageList } from '../../types/SelfServingCertificateCoverage';

export interface GetTaskStatusResponse {
    data: Nullable<string>;
    error: Nullable<string>;
}

export interface GetSelfServingCertificateCoverageListResponse {
    selfServingCoverageList: SelfServingCertificateCoverageList;
}

export interface CreateSelfServingCertificateResponse {
    certificateId: UUID;
}

export interface CreateSelfServingCertificateRequest {
    selfServingCoverageList: SelfServingCertificateCoverageList;
    certificateHolder?: CertificateEntityRole;
    referenceNumber?: string;
}

export interface SelfServingCertificateRequest {
    certificate: Certificate;
    selfServingCoverageList: SelfServingCertificateCoverageList;
}

export interface PreviewSelfServingCertificateRequest {
    selfServingCoverageList: SelfServingCertificateCoverageList;
    certificateHolder?: CertificateEntityRole;
    referenceNumber?: string;
}

export interface PreviewSelfServingCertificateResponse {
    certificateFileKey: string;
}

export interface GetCertificateForActiveOrganizationRequest {
    certificateID: UUID;
}

/**
 * Interface for the certificate repository
 */
export interface CertificateRepository {
    /**
     * Fetches certificate for organization
     * @returns Array of certificates associated with organization
     * @returns InvalidArgument error if certificate with provided id fails to retrieve data fetched form server
     * @returns GetCertificatesForActiveOrganizationFailed error if api call fails
     */
    getCertificateForActiveOrganization(
        request: GetCertificateForActiveOrganizationRequest,
    ): AsyncResult<Certificate, InvalidArgument | CertificateNotFound>;
    /**
     * Fetches certificate for organization
     * @returns Array of certificates associated with organization
     * @returns InvalidArgument error if certificate with provided id fails to retrieve data fetched form server
     * @returns GetCertificatesForActiveOrganizationFailed error if api call fails
     */
    getCertificatesForActiveOrganization(): AsyncResult<
        Certificate[],
        InvalidArgument | GetCertificatesForActiveOrganizationFailed
    >;

    /**
     * Checks if there is shareable certificate data for the organization
     * @param organizationId identifier of the organization
     * @returns Boolean value showing if there is shareable data for the organization
     * @returns InvalidArgument error if certificate with provided id fails to retrieve data fetched form server
     * @returns GetShareableCertificateDataFailed error if api call fails
     */
    getShareableCertificateData(
        organizationId: UUID,
    ): AsyncResult<Certificate, InvalidArgument | GetShareableCertificateDataFailed>;

    /**
     * Gets shareable coverage list for self-serving certificate flow
     * @returns SelfServingCertificateCoverageList list of shareable coverages
     * @returns InvalidArgument error if a creation of a coverage fails
     * @returns GetSelfServingCoverageListFailed error if the api call fails
     */
    getSelfServingCoverageList(): AsyncResult<
        GetSelfServingCertificateCoverageListResponse,
        InvalidArgument | GetSelfServingCoverageListFailed
    >;

    /**
     * Previews certificate document from self-serving certificate flow
     * @param selfServingCoverageList list of shareable coverages
     * @param certificateHolder certificate holder to be displayed in the certificate document
     * @param referenceNumber is a reference number value to be printed on certificate pdf
     * @returns certificateFileKey File key of the self-serving certificate document
     * @returns InvalidArgument error if creation of a coverage fails
     * @returns CreateSelfServingCertificateFailed if request failed for some unexpected reason
     */
    previewSelfServingCertificate(
        request: PreviewSelfServingCertificateRequest,
    ): AsyncResult<
        PreviewSelfServingCertificateResponse,
        InvalidArgument | PreviewSelfServingCertificateFailed
    >;

    /**
     * Creates certificate from self-serving certificate flow
     * @param selfServingCoverageList list of shareable coverages
     * @param certificate
     * @returns certificateId identifier of the self-serving certificate
     * @returns InvalidArgument error if creation of a coverage fails
     * @returns CreateSelfServingCertificateFailed if request failed for some unexpected reason
     */
    createSelfServingCertificate(
        request: CreateSelfServingCertificateRequest,
    ): AsyncResult<
        CreateSelfServingCertificateResponse,
        InvalidArgument | CreateSelfServingCertificateFailed
    >;

    /**
     * Send certificate from self-serving certificate flow
     * @param certificate
     * @param selfServingCoverageList list of shareable coverages
     * @returns identifier of the self-serving certificate
     * @returns SelfServingCertificateRequestFailed error if the api call fails
     * @returns SaveCertificatePhoneNumberNull error if provided location phone number is 'null'
     */
    sendSelfServingCertificateRequest(
        request: SelfServingCertificateRequest,
    ): AsyncResult<UUID, SelfServingCertificateRequestFailed | SaveCertificatePhoneNumberNull>;
}

export const CertificateRepository = Symbol('CertificateRepository');
