import { Money } from '@embroker/shotwell/core/types/Money';
import {
    defineValidator,
    Joi,
    Schema,
    TypeChecker,
    Validator,
} from '@embroker/shotwell/core/validation/schema';

/**
 * Represents a Blanked limit object
 */
export interface BlanketLimit {
    /**
     * Limit amount
     */
    limit: Money;
    /**
     * Limit type
     */
    typeCode: string;
}

export interface BlanketValidator {
    /**
     * A Joi schema matching a valid BlanketLimit object.
     */
    readonly schema: Schema.ObjectSchema<BlanketLimit>;
    /**
     * Type predicate that checks if a given value can be used as BlanketLimit object.
     *
     * Use this only to do early returns. It's recommended to use validate()
     * before using an unknown value as BlanketLimit object as it normalizes the value
     * in addition to performing the same validation as check().
     */
    readonly check: TypeChecker<BlanketLimit>;
    /**
     * Validates and normalizes the given value to a BlanketLimit object.
     *
     * This should be used for all untrusted inputs to verify and, if required
     * (and possible), normalize the input.
     */
    readonly validate: Validator<BlanketLimit>;
}

export const BlanketLimit = {
    ...defineValidator<BlanketLimit>({
        limit: Money.schema,
        typeCode: Joi.string(),
    }),
    create(blanketLimit: BlanketLimit) {
        return BlanketLimit.validate(blanketLimit);
    },
};
