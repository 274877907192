import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { UserOnboardingRepository } from '../repositories/UserOnboardingRepository';

/**
 * Request data for UpdateUserOnboardingDetails use case
 * @param action is the update action: 'set-item' | 'remove-item'
 * @param redirectUrl is the redirectUrl to be sotred in the UserOnboardingDetails
 */
export interface UpdateUserOnboardingDetailsRequest {
    redirectUrl?: string;
}

export interface UpdateUserOnboardingDetails extends UseCase {
    execute(request: UpdateUserOnboardingDetailsRequest): AsyncResult<void, never>;
}

@injectable()
export class UpdateUserOnboardingDetailsUseCase
    extends UseCase
    implements UpdateUserOnboardingDetails
{
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/UpdateUserOnboardingDetails');
    /**
     * Constructor for UpdateUserOnboardingQuestionnaireData use case class instance
     * @param eventBus An event bus this Use Case will publish events to.
     * @param userOnboardingRepository is repository used to update UserOnboardingDetails
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(UserOnboardingRepository)
        private userOnboardingRepository: UserOnboardingRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes UpdateUserOnboardingDetails use case
     * Input is of type UpdateUserOnboardingDetailsRequest
     * @returns Nothing if execution was successful
     */
    public async execute({
        redirectUrl,
    }: UpdateUserOnboardingDetailsRequest): AsyncResult<void, never> {
        return await this.userOnboardingRepository.setUserOnboardingDetails({
            redirectUrl,
        });
    }
}

export const UpdateUserOnboardingDetails: UseCaseClass<UpdateUserOnboardingDetails> =
    UpdateUserOnboardingDetailsUseCase;
