import {
    BoxLayout,
    Button,
    CardLayout,
    ColumnLayout,
    StackLayout,
    StatusMessage,
    Text,
    Form,
} from '@embroker/ui-toolkit/v2';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import React, { useContext } from 'react';
import { UpdateBillingInfo } from '@app/payments/useCases/UpdateBillingInfo';
import { execute } from '@embroker/shotwell/core/UseCase';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { createForm, OpaqueForm, useForm } from '@embroker/shotwell/view/hooks/useForm';

import { AppContext } from '@app/view/AppContext';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { GetActiveUserProfile } from '@app/userOrg/useCases/GetActiveUserProfile';
import { GetBillingInfo } from '@app/payments/useCases/GetBillingInfo';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';

interface PaymentsUpdateBillingFormData {
    billingEmailAddress: string;
    organizationId: UUID;
}

export const PaymentsUpdateBillingForm = createForm<PaymentsUpdateBillingFormData>({
    fields: {
        billingEmailAddress: {
            type: 'email',
            validator: EmailAddress.schema.required(),
        },
        organizationId: {
            type: 'text',
            validator: UUID.schema,
        },
    },
    submit: async (data) => {
        return await execute(UpdateBillingInfo, data);
    },
});

export function PaymentsUpdateBillingInfo() {
    const { result: userProfile } = useUseCase(GetActiveUserProfile);
    const { activeSession } = useContext(AppContext);
    const organizationId: UUID = activeSession.organizationId as UUID;
    const { result: existingBillingInfo } = useUseCase(GetBillingInfo, {
        organizationId: organizationId,
    });

    if (userProfile === undefined) {
        return null;
    }
    if (existingBillingInfo === undefined) {
        return null;
    }

    if (isErr(userProfile)) {
        return <ErrorPage errors={userProfile.errors} />;
    }
    if (isErr(existingBillingInfo)) {
        return <ErrorPage errors={existingBillingInfo.errors} />;
    }

    const billingEmail = existingBillingInfo.value.billingEmailAddress ?? userProfile.value.email;

    return (
        <PaymentsUpdateBilling
            organizationId={organizationId}
            billingEmailAddress={billingEmail}
        ></PaymentsUpdateBilling>
    );
}

export function PaymentsUpdateBilling(data: PaymentsUpdateBillingFormData) {
    const { submit, fields, status } = useForm<OpaqueForm<PaymentsUpdateBillingFormData>>(
        PaymentsUpdateBillingForm,
        {
            ...data,
        },
    );

    return (
        <StackLayout>
            <Text data-e2e="payments-update-billing-header" style="heading 3">
                Billing Email Address
            </Text>
            <CardLayout>
                <BoxLayout gap="24">
                    <StackLayout>
                        {status === 'submitted' ? (
                            <StatusMessage status="success">
                                Your billing address has been updated successfully!
                            </StatusMessage>
                        ) : status === 'invalid' ? (
                            <StatusMessage status="error">
                                Sorry for the inconvenience, but we were unable to update your
                                billing email address. Please try again.
                            </StatusMessage>
                        ) : null}
                        <Text style="body 2">
                            Please review and update your billing email address if necessary. Don't
                            worry, we'll only use this email for billing-related emails.
                        </Text>
                        <Form noValidate onSubmit={submit}>
                            <ColumnLayout
                                gap="24"
                                responsive={{ screenWidth: { smallerThan: 'large-tablet' } }}
                            >
                                <Form.Field
                                    data-e2e="payments-update-billing-email-address"
                                    inputProps={{
                                        ...fields.billingEmailAddress.props,
                                    }}
                                    label="Billing email address"
                                    messages={fields.billingEmailAddress.messages}
                                    type={fields.billingEmailAddress.type}
                                />
                                <Button
                                    appearance="secondary"
                                    data-e2e="payments-update-billing-submit-button"
                                    type="submit"
                                >
                                    Update Email Address
                                </Button>
                            </ColumnLayout>
                        </Form>
                    </StackLayout>
                </BoxLayout>
            </CardLayout>
        </StackLayout>
    );
}
