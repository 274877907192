import {
    StructuralComponentTypeCodeList,
    StructuralComponentTypeCodeListItem,
} from '@embroker/shotwell-api/enums';
import { Money } from '@embroker/shotwell/core/types/Money';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface PolicySection {
    type: StructuralComponentTypeCodeListItem;
    limit?: Money;
    retention?: Money;
    subLimit?: Money;
}

export const PolicySection = {
    ...defineValidator<PolicySection>(
        Joi.object({
            type: Joi.string().valid(...StructuralComponentTypeCodeList),
            limit: Money.schema.optional(),
            subLimit: Money.schema.optional(),
            retention: Money.schema.optional(),
        }).preferences({ presence: 'required' }),
    ),
    create(policySection: PolicySection) {
        return PolicySection.validate(policySection);
    },
};

export const StructuralComponentTypeCodeListDirectorsAndOfficersLiabilitySection =
    'StructuralComponentTypeCodeListDirectorsAndOfficersLiabilitySection';
export const StructuralComponentTypeCodeListEmploymentPracticesLiabilitySection =
    'StructuralComponentTypeCodeListEmploymentPracticesLiabilitySection';
export const StructuralComponentTypeCodeListFiduciaryLiabilitySection =
    'StructuralComponentTypeCodeListFiduciaryLiabilitySection';
export const StructuralComponentTypeCodeListTechEOCyberLiabilitySection =
    'StructuralComponentTypeCodeListTechEOCyberLiabilitySection';
