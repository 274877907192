import { GetFileList, GetFileListRequest } from '../../../../documents/useCases/GetFileList';
import React, { useEffect, useState } from 'react';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';

import { Document } from '../../../../documents/entities/Document';
import { FileUpload } from '../../../../documents/view/FileUpload';
import { SaveFiles } from '../../../../documents/useCases/SaveFiles';
import { UploadFile } from '../../../../documents/view/UploadFile/UploadFile';
import { execute } from '@embroker/shotwell/core/UseCase';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';

export interface UploadFieldProps {
    value: string[];
    readOnly?: boolean;
    onChange: (value: any) => void;
}

export const UploadField = React.forwardRef(function UploadField(
    { value = [], readOnly, onChange }: UploadFieldProps,
    ref: React.Ref<HTMLDivElement>,
) {
    const [initialValue] = useState(value);
    const [files, setFiles] = useState<FileUpload[]>([]);

    const request: GetFileListRequest = {
        fileKeyList: initialValue,
    };

    const { result } = useUseCase(GetFileList, request);

    useEffect(() => {
        if (result == undefined || isErr(result)) {
            return;
        }

        const files = result.value.map((document: Document) => ({
            id: document.id,
            file: new File([''], document.name),
            s3FileKey: document.fileKey,
        }));

        setFiles(files);
    }, [result]);

    useEffect(() => {
        let isCanceled = false;
        const filesToSave = files
            .filter((file) => file.s3FileKey !== null)
            .map((file) => {
                return {
                    name: file.file.name,
                    file_key: file.s3FileKey!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
                    mime_type: file.file.type,
                    size: file.file.size,
                };
            });
        execute(SaveFiles, {
            files: filesToSave,
        }).then((result) => {
            if (isCanceled) {
                return;
            }
            if (isOK(result)) {
                onChange(filesToSave.map((file) => file.file_key));
            }
        });
        return () => {
            isCanceled = true;
        };
    }, [files, onChange]);

    const handleSelected = (selectedFiles: FileUpload[], newFiles: FileUpload[]) => {
        setFiles(newFiles);
    };

    const handleRemoved = (removedFile: FileUpload, newFiles: FileUpload[]) => {
        setFiles(newFiles);
    };

    const handleUploaded = (uploadedFile: FileUpload, newFiles: FileUpload[]) => {
        setFiles(newFiles);
    };

    return (
        <UploadFile
            ref={ref}
            buttonText="Upload a file"
            placeholder="or drag file here."
            files={files}
            sizeLimit={25}
            onRemoved={handleRemoved}
            onSelected={handleSelected}
            onUploaded={handleUploaded}
            readOnly={readOnly}
            showButton
        />
    );
});
