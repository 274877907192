import { defineEntityValidator, entity, Entity } from '@embroker/shotwell/core/entity/Entity';
import { Money } from '@embroker/shotwell/core/types/Money';
import { URI } from '@embroker/shotwell/core/types/URI';
import { Joi } from '@embroker/shotwell/core/validation/schema';

export interface Quote extends Entity {
    readonly quoteNumber: number;
    readonly installmentAmount: Money;
    readonly totalPremium: Money;
    readonly totalPaymentAmount: Money;
    readonly downPayment: Money;
    readonly downPayCreditCardUrl: URI;
    readonly downPayBankUrl: URI;
    readonly pdfFileKey: string;
    readonly numberOfInstallments: number;
    readonly annualPercentageRate: number;
}

export const Quote = entity<Quote>({
    validator: defineEntityValidator<Quote>({
        quoteNumber: Joi.number(),
        installmentAmount: Money.schema,
        totalPremium: Money.schema,
        totalPaymentAmount: Money.schema,
        downPayment: Money.schema,
        downPayCreditCardUrl: Joi.string().allow(''),
        downPayBankUrl: Joi.string().allow(''),
        pdfFileKey: Joi.string().allow(''),
        numberOfInstallments: Joi.number().positive(),
        annualPercentageRate: Joi.number().positive(),
    }),
});
