import { Money } from '@embroker/shotwell/core/types/Money';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { ColumnLayout, InsetBox, ScrollBox, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { ESPQuote } from '../../entities/ESPQuote';
import { espCoverageDetails } from '../../../espUtils/quoteDocumentUtils';

const lineStyle = { borderTop: '1px #d3dce7' };

export function ESPQuoteModalSummary({ quote }: { quote: ESPQuote }) {
    const selectedCoverages = quote.details.coverages.filter((coverage) => {
        return coverage.selected;
    });

    return (
        <StackLayout>
            <ColumnLayout gap="8">
                <Text>Effective date: </Text>{' '}
                <Text style="heading 5" data-e2e="effective-date">
                    <DateDisplay value={quote.options.effectiveDate} />
                </Text>
            </ColumnLayout>
            <InsetBox withBorder background="ui-100">
                <ScrollBox>
                    <StackLayout gap="24">
                        {selectedCoverages.map((coverage, index) => (
                            <React.Fragment key={coverage.coverageType}>
                                <Text style="heading 5" data-e2e="coverage-type-title">
                                    {espCoverageDetails[coverage.coverageType].title}
                                    {coverage.level
                                        ? coverage.level === 'standard'
                                            ? ' (Standard)'
                                            : ' (Plus)'
                                        : null}
                                </Text>
                                <ColumnLayout gap="24">
                                    {coverage.coverageType != 'techCyber' && (
                                        <React.Fragment>
                                            <Text>
                                                <Text style="overline" data-e2e="limit">
                                                    LIMIT
                                                </Text>{' '}
                                                <MoneyDisplay
                                                    value={Money.tryFromFloat(coverage.limit)}
                                                    fractionDigits={0}
                                                />
                                            </Text>
                                            <Text>
                                                <Text style="overline" data-e2e="retention">
                                                    RETENTION
                                                </Text>{' '}
                                                <MoneyDisplay
                                                    value={Money.tryFromFloat(coverage.retention)}
                                                    fractionDigits={0}
                                                />
                                            </Text>
                                        </React.Fragment>
                                    )}
                                    {coverage.coverageType == 'techCyber' && (
                                        <StackLayout>
                                            <Text style="overline">TECHNOLOGY E&O</Text>{' '}
                                            <ColumnLayout gap="24">
                                                <Text>
                                                    <Text style="overline">LIMIT</Text>{' '}
                                                    <MoneyDisplay
                                                        value={Money.tryFromFloat(
                                                            coverage.techCoverageQuoteOptionsItem
                                                                .limit ?? 0,
                                                        )}
                                                        fractionDigits={0}
                                                    />
                                                </Text>
                                                <Text>
                                                    <Text style="overline">RETENTION</Text>{' '}
                                                    <MoneyDisplay
                                                        value={Money.tryFromFloat(
                                                            coverage.techCoverageQuoteOptionsItem
                                                                .retention ?? 0,
                                                        )}
                                                        fractionDigits={0}
                                                    />
                                                </Text>
                                            </ColumnLayout>
                                            <Text style="overline">CYBER</Text>{' '}
                                            <ColumnLayout gap="24">
                                                <Text>
                                                    <Text style="overline">LIMIT</Text>{' '}
                                                    <MoneyDisplay
                                                        value={Money.tryFromFloat(
                                                            coverage.cyberCoverageQuoteOptionsItem
                                                                .limit ?? 0,
                                                        )}
                                                        fractionDigits={0}
                                                    />
                                                </Text>
                                                <Text>
                                                    <Text style="overline">RETENTION</Text>{' '}
                                                    <MoneyDisplay
                                                        value={Money.tryFromFloat(
                                                            coverage.cyberCoverageQuoteOptionsItem
                                                                .retention ?? 0,
                                                        )}
                                                        fractionDigits={0}
                                                    />
                                                </Text>
                                            </ColumnLayout>
                                        </StackLayout>
                                    )}
                                </ColumnLayout>
                                {index === selectedCoverages.length - 1 ? null : (
                                    <hr style={lineStyle} />
                                )}
                            </React.Fragment>
                        ))}
                    </StackLayout>
                </ScrollBox>
            </InsetBox>
        </StackLayout>
    );
}
