import { Module } from '../Module';
import { ContainerModule } from '@embroker/shotwell/core/di';
import { GlobalConfigRepository } from './repositories/GlobalConfigRepository';
import { APIGlobalConfigRepository } from './repositories/GlobalConfigRepository/APIGlobalConfigRepository';
import { GetGlobalConfig } from './useCases/GetGlobalConfigUseCase';

export const ConfigModule: Module = {
    container: new ContainerModule((bind) => {
        bind<GlobalConfigRepository>(GlobalConfigRepository)
            .to(APIGlobalConfigRepository)
            .inSingletonScope();
        bind<GetGlobalConfig>(GetGlobalConfig.type).to(GetGlobalConfig).inSingletonScope();
    }),
};
