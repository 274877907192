import { defineValidator } from '@embroker/shotwell/core/validation/schema';
import { LawCyberQuoteOptions } from '../../bundle/coverageDefinition/lawCyber/types/LawCyberQuoteOptions';
import { CowbellCyberQuoteOptions } from '../../bundle/coverageDefinition/cowbellCyber/types/CowbellCyberQuoteOptions';
import { BOPChubbQuoteOptions } from '../../bundle/coverageDefinition/bopChubb/types/BOPChubbQuoteOptions';
import { ESPQuoteOptions } from '../../quote/esp/types/ESPQuoteOptions';
import { LPLQuoteOptions } from '../../quote/lpl/types/LPLQuoteOptions';
import { PCoMLQuoteOptions } from '../../quote/pcoml/entities/PCoMLQuote';
import { WCChubbQuoteOptions } from '../../bundle/coverageDefinition/wcChubb/types/WCChubbQuoteOptions';

export interface QuoteOptions {
    lplEverest?: LPLQuoteOptions;
    esp?: ESPQuoteOptions;
    pcoml?: PCoMLQuoteOptions;
    lawCyber?: LawCyberQuoteOptions;
    cowbellCyber?: CowbellCyberQuoteOptions;
    bopChubb?: BOPChubbQuoteOptions;
    wcChubb?: WCChubbQuoteOptions;
}

export const QuoteOptions = {
    ...defineValidator<QuoteOptions>({
        lplEverest: LPLQuoteOptions.schema.optional(),
        esp: ESPQuoteOptions.schema.optional(),
        pcoml: PCoMLQuoteOptions.schema.optional(),
        lawCyber: LawCyberQuoteOptions.schema.optional(),
        cowbellCyber: CowbellCyberQuoteOptions.schema.optional(),
        bopChubb: BOPChubbQuoteOptions.schema.optional(),
        wcChubb: WCChubbQuoteOptions.schema.optional(),
    }),
    create(quoteOptions: QuoteOptions) {
        return QuoteOptions.validate(quoteOptions);
    },
};
