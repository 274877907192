import { ContainerModule } from '@embroker/shotwell/core/di';
import { Module } from '../Module';
import { APIQuestionerRepository } from './repositories/APIQuestionerRepository';
import { GetQuestionerForm } from './useCases/GetQuestionerForm';
import { SaveQuestionerAnswers } from './useCases/SaveQuestionerAnswers';
import { SaveProductSelectionAnswers } from './useCases/SaveProductSelectionAnswers';
import { SaveOnboardingAnswers } from './useCases/SaveOnboardingAnswers';
import { GetStaticConditionalActions } from './useCases/GetStaticConditionalActions';

/**
 * The ShoppingQuestionerModule module.
 */
export const ShoppingQuestionerModule: Module = {
    container: new ContainerModule((bind) => {
        bind<APIQuestionerRepository>(APIQuestionerRepository)
            .to(APIQuestionerRepository)
            .inSingletonScope();
        bind<GetQuestionerForm>(GetQuestionerForm.type).to(GetQuestionerForm).inSingletonScope();
        bind<SaveProductSelectionAnswers>(SaveProductSelectionAnswers.type)
            .to(SaveProductSelectionAnswers)
            .inSingletonScope();
        bind<SaveOnboardingAnswers>(SaveOnboardingAnswers.type)
            .to(SaveOnboardingAnswers)
            .inSingletonScope();
        bind<SaveQuestionerAnswers>(SaveQuestionerAnswers.type)
            .to(SaveQuestionerAnswers)
            .inSingletonScope();
        bind<GetStaticConditionalActions>(GetStaticConditionalActions.type)
            .to(GetStaticConditionalActions)
            .inSingletonScope();
    }),
};
