import { inject } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Success, AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ConditionalAction } from '../types/ConditionalAction';
import { OperationFailed } from '@embroker/shotwell/core/Error';

export interface GetStaticConditionalActionsResponse {
    staticConditionalActions: { [key: string]: ConditionalAction[] };
}
export interface GetStaticConditionalActions extends UseCase {
    execute(): AsyncResult<GetStaticConditionalActionsResponse, OperationFailed>;
}

class GetStaticConditionalActionsUseCase extends UseCase implements GetStaticConditionalActions {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Questioner/GetStaticConditionalActionsUseCase');
    /**
     * Constructor for GetStaticConditionalActionsUseCase use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    public async execute(): AsyncResult<GetStaticConditionalActionsResponse, OperationFailed> {
        // Static condition actions were created based on this Jira ticket: https://embroker.atlassian.net/browse/EM-46057
        // The reason was that we didn't have a way to use a dynamic year value to compare with the year_started value.
        // This is a temporary solution until we have a better way to handle this.
        const STATIC_CONDITIONAL_ACTIONS: { [key: string]: ConditionalAction[] } = {
            revenue: [
                {
                    action: 'VISIBLE',
                    rules: [
                        [
                            {
                                comparison_value: {
                                    number: [new Date(Date.now()).getFullYear()],
                                    type: 'NUMBER',
                                },
                                external_ref: {
                                    field: '',
                                    key: 'year_started',
                                },
                                operator: 'LESS_THAN',
                            },
                        ],
                    ],
                },
            ],
        };

        return Success<GetStaticConditionalActionsResponse>({
            staticConditionalActions: STATIC_CONDITIONAL_ACTIONS,
        });
    }
}

export const GetStaticConditionalActions: UseCaseClass<GetStaticConditionalActions> =
    GetStaticConditionalActionsUseCase;
