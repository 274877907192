import { defineEntityValidator, entity, Entity } from '@embroker/shotwell/core/entity/Entity';
import { Joi } from '@embroker/shotwell/core/validation/schema';

export interface EntityType extends Entity {
    /**
     * Entity type's name
     */
    readonly name: string;
}

export const EntityType = entity<EntityType>({
    validator: defineEntityValidator<EntityType>({
        name: Joi.string(),
    }),
});
