import type * as APIType from '@embroker/shotwell-api/app';
import { Immutable } from '@embroker/shotwell/core/types';
import { USD } from '@embroker/shotwell/core/types/Money';
import { LPLQuoteDetails } from '../../../quote/lpl/types/LPLQuoteDetails';
import { LPLQuoteOptions } from '../../../quote/lpl/types/LPLQuoteOptions';
import { lplFormDataType } from './lplFormData';

export function isLplQuoteOptions(options: any): options is LPLQuoteOptions {
    return (
        'claimsExpenseType' in options &&
        'deductibleType' in options &&
        'separateClaimExpenseLimit' in options &&
        'aggregateLimit' in options &&
        'perClaimLimit' in options &&
        'perClaimDeductible' in options &&
        'effectiveDate' in options
    );
}

export function toLplQuoteDetails(details: Immutable<APIType.LplQuoteDetails>): LPLQuoteDetails {
    return {
        specimenPolicyFileKey: details.specimen_policy_file_key || undefined,
        lplProcessingFee: details.policy_administration_fee ?? USD(0),
        premium: details.premium ?? undefined,
    };
}

export function toApiLplQuoteOptions(options: LPLQuoteOptions): APIType.LplQuoteOptions {
    return {
        effective_date: options.effectiveDate,
        per_claim_limit: options.perClaimLimit,
        aggregate_limit: options.aggregateLimit,
        per_claim_deductible: options.perClaimDeductible,
        aggregate_deductible: 'AggregateDeductibleNA',
        deductible_type: options.deductibleType,
        claims_expense_type: options.claimsExpenseType,
        separate_claim_expense_limit: options.separateClaimExpenseLimit ?? null,
        is_deselected: options.isDeselected,
    };
}

export const formDataToLPLQuoteOptions = (formData: any): LPLQuoteOptions | undefined => {
    if (!isLplFormData(formData)) {
        return undefined;
    }
    return {
        effectiveDate: formData.effectiveDate,
        perClaimLimit: formData.lplPerClaimLimit,
        aggregateLimit: formData.lplAggregateLimit,
        perClaimDeductible: formData.lplPerClaimDeductible,
        deductibleType: formData.lplDeductibleType,
        claimsExpenseType: formData.lplClaimsExpenseType,
        separateClaimExpenseLimit: formData.lplSeparateClaimExpenseLimit,
        isDeselected: !formData.lplSelected,
    };
};

export function isLplFormData(input: any): input is lplFormDataType {
    return (
        'lplPerClaimLimit' in input &&
        'lplAggregateLimit' in input &&
        'lplPerClaimDeductible' in input &&
        'lplDeductibleType' in input &&
        'lplClaimsExpenseType' in input &&
        'lplSeparateClaimExpenseLimit' in input &&
        'effectiveDate' in input &&
        'lplSelected' in input
    );
}
