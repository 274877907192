import type * as APIType from '@embroker/shotwell-api/app';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { lawCyberFormDataType } from './lawCyberFormData';
import { LawCyberQuoteDetails } from './types/LawCyberQuoteDetails';
import {
    LawCyberLimit,
    LawCyberQuoteOptions,
    LawCyberRetention,
} from './types/LawCyberQuoteOptions';

export function toLawCyberQuoteOptions(
    options: APIType.LawCyberQuoteOptions,
): LawCyberQuoteOptions {
    return {
        effectiveDate: options.effective_date,
        limit: options.limit as LawCyberLimit,
        retention: options.retention as LawCyberRetention,
        isDeselected: options.is_deselected,
    };
}

export function toLawCyberQuoteDetails(
    details: Immutable<APIType.LawCyberQuoteDetails>,
    taxes: Nullable<Money>,
): LawCyberQuoteDetails {
    return {
        taxes: taxes || undefined,
        basePremium: details.total_base_premium,
        specialSurcharge: details.special_surcharge,
    };
}

export function isLawCyberQuoteOptions(options: any): options is LawCyberQuoteOptions {
    return 'limit' in options && 'retention' in options && 'effectiveDate' in options;
}

export function toApiLawCyberQuoteOptions(
    options: LawCyberQuoteOptions,
): APIType.LawCyberQuoteOptions {
    return {
        effective_date: options.effectiveDate,
        limit: options.limit,
        retention: options.retention,
        level: 'standard',
        is_deselected: options.isDeselected,
    };
}

export const formDataToLawCyberQuoteOptions = (formData: any): LawCyberQuoteOptions | undefined => {
    if (!isLawCyberFormData(formData)) {
        return undefined;
    }
    return {
        effectiveDate: formData.effectiveDate,
        limit: formData.cyberLimit,
        retention: formData.cyberRetention,
        isDeselected: !formData.cyberSelected,
    };
};

export function isLawCyberFormData(input: any): input is lawCyberFormDataType {
    return 'cyberLimit' in input && 'cyberRetention' in input && 'cyberSelected' in input;
}
