import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, Failure, isErr, Result, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { InvoiceRepository } from '../repositories/InvoiceRepository';
import { PaymentError } from '../types/errors';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { BundlePayment } from '../types/Payment';
import { buildBundlePaymentUI } from './GetPendingInvoices';
import { Invoice } from '../entities/Invoice';

export interface GetBundlePublicInvoiceRequest {
    publicKey: UUID;
}

export interface BundlePublicInvoiceResponse {
    bundlePayment: Immutable<BundlePayment>;
}

export interface GetBundlePublicInvoice extends UseCase {
    execute(
        request: GetBundlePublicInvoiceRequest,
    ): AsyncResult<BundlePublicInvoiceResponse, InvalidArgument | OperationFailed | PaymentError>;
}

@injectable()
class GetBundlePublicInvoiceUseCase extends UseCase implements GetBundlePublicInvoice {
    public static type = Symbol('Payments/GetPublicInvoice');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(InvoiceRepository) private invoiceRepo: InvoiceRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: GetBundlePublicInvoiceRequest,
    ): AsyncResult<BundlePublicInvoiceResponse, InvalidArgument | OperationFailed | PaymentError> {
        const publicInvoiceResult = await this.invoiceRepo.getBundlePublicInvoiceList(
            request.publicKey,
        );

        if (isErr(publicInvoiceResult)) {
            return publicInvoiceResult;
        }

        const bundleId = getBundleIdFromInvoiceList(publicInvoiceResult.value);

        if (isErr(bundleId)) {
            return bundleId;
        }

        const bundleUIInvoice = buildBundlePaymentUI(bundleId.value, publicInvoiceResult.value);

        if (isErr(bundleUIInvoice)) {
            return bundleUIInvoice;
        }

        const response: BundlePublicInvoiceResponse = {
            bundlePayment: bundleUIInvoice.value,
        };

        return Success(response);
    }
}

export function getBundleIdFromInvoiceList(
    invoiceList: Immutable<Invoice[]>,
): Result<UUID, InvalidArgument | OperationFailed> {
    if (invoiceList.length === 0) {
        return Failure(
            OperationFailed({
                message: `InvoiceList is empty`,
            }),
        );
    }

    const bundleId = invoiceList[0].bundleId;

    if (bundleId == undefined) {
        return Failure(
            OperationFailed({
                message: `BundleId is undefined for invoice: ${invoiceList[0].invoiceNumber}`,
            }),
        );
    }

    if (invoiceList.some((invoice) => invoice.bundleId != bundleId)) {
        return Failure(
            OperationFailed({
                message: 'BundleId is not the same for all invoices',
            }),
        );
    }

    return Success(bundleId);
}

export const GetBundlePublicInvoice: UseCaseClass<GetBundlePublicInvoice> =
    GetBundlePublicInvoiceUseCase;
