import { Data, Immutable, Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { State } from '@embroker/shotwell/core/types/StateList';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { InputStatus, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { WizardForm } from '../../../../../view/hooks/useWizardForm';
import { Coverage } from '../../../types/Coverage';
import { InsuranceApplicationRestriction } from '../../../types/InsuranceApplicationRestriction';
import { ESPRenewalQuoteOptionsFormData } from '../ESPRenewalQuoteLandingPage';
import { isCoverageRestricted } from './ESPRenewalCoveragePage';
import { ESPRenewalRecommendedCoverage } from './ESPRenewalRecommendedCoverage';

interface ESPRenewalRecommendedCoverageListProps {
    coverageDictionary: Data<Coverage>;
    isSubmitting: boolean;
    revenue: Money;
    fields: WizardForm<OpaqueForm<ESPRenewalQuoteOptionsFormData>>['fields'];
    restriction?: Immutable<InsuranceApplicationRestriction>;
    newInsurerDocumentsReleaseDate?: Date;
    fiduciaryDocumentsReleaseDate?: Date;
    submittedAt?: Date;
    state?: Nullable<State>;
    isQuoteReferred: boolean;
    effectiveDate?: Date;
}

export const ESPRenewalRecommendedCoverageList = ({
    coverageDictionary,
    isSubmitting,
    revenue,
    fields,
    restriction,
    newInsurerDocumentsReleaseDate,
    fiduciaryDocumentsReleaseDate,
    submittedAt,
    state,
    isQuoteReferred,
    effectiveDate,
}: ESPRenewalRecommendedCoverageListProps) => {
    return (
        <StackLayout>
            <Text style="heading 4" data-e2e="recommended-coverages">
                Recommended Coverages
            </Text>
            <InputStatus messages={fields.coverage.messages} role="alert" />
            {coverageDictionary['dno'] &&
                !isCoverageRestricted(coverageDictionary['dno'].type, restriction) &&
                !fields.isDnoSelected.props.value && (
                    <ESPRenewalRecommendedCoverage
                        key={coverageDictionary['dno'].type}
                        coverage={coverageDictionary['dno']}
                        revenue={revenue}
                        isSubmitting={isSubmitting}
                        fields={fields}
                        newInsurerDocumentsReleaseDate={newInsurerDocumentsReleaseDate}
                        fiduciaryDocumentsReleaseDate={fiduciaryDocumentsReleaseDate}
                        submittedAt={submittedAt}
                        state={state}
                        isQuoteReferred={isQuoteReferred}
                        effectiveDate={effectiveDate}
                    />
                )}
            {coverageDictionary['epli'] &&
                !isCoverageRestricted(coverageDictionary['epli'].type, restriction) &&
                !fields.isEplSelected.props.value && (
                    <ESPRenewalRecommendedCoverage
                        key={coverageDictionary['epli'].type}
                        coverage={coverageDictionary['epli']}
                        revenue={revenue}
                        isSubmitting={isSubmitting}
                        fields={fields}
                        newInsurerDocumentsReleaseDate={newInsurerDocumentsReleaseDate}
                        fiduciaryDocumentsReleaseDate={fiduciaryDocumentsReleaseDate}
                        submittedAt={submittedAt}
                        state={state}
                        isQuoteReferred={isQuoteReferred}
                        effectiveDate={effectiveDate}
                    />
                )}
            {coverageDictionary['fiduciary'] &&
                !isCoverageRestricted(coverageDictionary['fiduciary'].type, restriction) &&
                !fields.isFiduciarySelected.props.value && (
                    <ESPRenewalRecommendedCoverage
                        key={coverageDictionary['fiduciary'].type}
                        coverage={coverageDictionary['fiduciary']}
                        revenue={revenue}
                        isSubmitting={isSubmitting}
                        fields={fields}
                        newInsurerDocumentsReleaseDate={newInsurerDocumentsReleaseDate}
                        fiduciaryDocumentsReleaseDate={fiduciaryDocumentsReleaseDate}
                        submittedAt={submittedAt}
                        state={state}
                        isQuoteReferred={isQuoteReferred}
                        effectiveDate={effectiveDate}
                    />
                )}
            {coverageDictionary['eo'] &&
                !isCoverageRestricted(coverageDictionary['eo'].type, restriction) &&
                !fields.isEOSelected.props.value && (
                    <ESPRenewalRecommendedCoverage
                        key={coverageDictionary['eo'].type}
                        coverage={coverageDictionary['eo']}
                        revenue={revenue}
                        isSubmitting={isSubmitting}
                        fields={fields}
                        newInsurerDocumentsReleaseDate={newInsurerDocumentsReleaseDate}
                        fiduciaryDocumentsReleaseDate={fiduciaryDocumentsReleaseDate}
                        submittedAt={submittedAt}
                        state={state}
                        isQuoteReferred={isQuoteReferred}
                        effectiveDate={effectiveDate}
                    />
                )}
            {coverageDictionary['techCyber'] &&
                !isCoverageRestricted(coverageDictionary['techCyber'].type, restriction) &&
                !fields.isTechSelected.props.value && (
                    <ESPRenewalRecommendedCoverage
                        key={coverageDictionary['techCyber'].type}
                        coverage={coverageDictionary['techCyber']}
                        revenue={revenue}
                        isSubmitting={isSubmitting}
                        fields={fields}
                        newInsurerDocumentsReleaseDate={newInsurerDocumentsReleaseDate}
                        fiduciaryDocumentsReleaseDate={fiduciaryDocumentsReleaseDate}
                        submittedAt={submittedAt}
                        state={state}
                        isQuoteReferred={isQuoteReferred}
                        effectiveDate={effectiveDate}
                    />
                )}
        </StackLayout>
    );
};
