import { API } from '@embroker/shotwell-api/app';
import type * as APIType from '@embroker/shotwell-api/app';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    isOK,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { isValid, startOfDay } from 'date-fns';
import {
    PCoMLEndorsementLiabilityCoverageType,
    PCoMLEndorsementLiabilityCoverageTypesList,
} from '../../types/PCoMLEndorsementLiabilityCoverageType';
import {
    PCoMLEndorsementLiabilityCoverage,
    PCoMLEndorsementPolicy,
    PCoMLEndorsementPolicyAddressData,
} from '../../types/PCoMLEndorsementPolicy';
import {
    PCoMLEndorsementRepository,
    RateEndorsementRequest,
    CreateEndorsementRequest,
} from './index';
import { State } from '@embroker/shotwell/core/types/StateList';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import { PCoMLEndorsementUserData } from '../../types/PCoMLEndorsementUserData';
import { PCoMLEndorsementConfig } from '../../types/PCoMLEndorsementConfig';

function mapToPCoMLEndorsementLiabilityCoverage(
    liability: APIType.LiabilitySectionItem,
): PCoMLEndorsementLiabilityCoverage {
    return {
        typeCode: liability.type_code as PCoMLEndorsementLiabilityCoverageType,
        limit: liability.limit1_amount ? liability.limit1_amount : undefined,
        retention: liability.s_i_r_1_amount ? liability.s_i_r_1_amount : undefined,
        premium: liability.premium_amount ? liability.premium_amount : undefined,
    };
}

function getPCoMLEndorsementAvailableLiabilities(
    policy: Immutable<APIType.Policy>,
): Array<PCoMLEndorsementLiabilityCoverage> {
    const liabilityList = policy?.lob_other_liability?.liability_section?.liability_list;
    if (!liabilityList) {
        return [];
    }
    return liabilityList
        .filter((liability) =>
            PCoMLEndorsementLiabilityCoverageTypesList.includes(
                liability.type_code as PCoMLEndorsementLiabilityCoverageType,
            ),
        )
        .map(mapToPCoMLEndorsementLiabilityCoverage);
}

function isNamedInsuredPredicate(insured: { id: UUID; type_code: string; name: string }) {
    return insured.type_code === 'InsuredTypeCodeListFirstNamed';
}

function getPCoMLEndorsementNamedInsured(policy: Immutable<APIType.Policy>): string {
    return policy.insured_list.find(isNamedInsuredPredicate)?.name ?? '';
}

function getPCoMLEndorsementAddressData(
    policy: Immutable<APIType.Policy>,
): PCoMLEndorsementPolicyAddressData {
    let stateCode: State | undefined;
    const result = State.validate(policy.state);
    if (isOK(result)) {
        stateCode = result.value;
    }
    return {
        addressLine1: policy.address ?? '',
        addressLine2: policy.suite_number ?? '',
        city: policy.city ?? '',
        state: stateCode,
        zipCode: policy.zip_code as ZipCode,
    };
}

@injectable()
export class APIPCoMLEndorsementRepository implements PCoMLEndorsementRepository {
    async getPolicy(
        policyId: UUID,
    ): AsyncResult<PCoMLEndorsementPolicy, InvalidArgument | OperationFailed> {
        const userPolicyResponse = await API.request('policy/user_policy', {
            id: policyId,
        });
        if (isErr(userPolicyResponse)) {
            return handleOperationFailure(userPolicyResponse);
        }

        const applicationResponse = await API.request('shopping/application', {
            id: userPolicyResponse.value.insurance_application_id as UUID,
        });
        if (isErr(applicationResponse)) {
            return handleOperationFailure(applicationResponse);
        }

        const serverDateResult = await this.getServerDate();
        if (isErr(serverDateResult)) {
            return serverDateResult;
        }

        const availableLiabilitiesResult = getPCoMLEndorsementAvailableLiabilities(
            userPolicyResponse.value,
        );
        const namedInsured = getPCoMLEndorsementNamedInsured(userPolicyResponse.value);
        const addressData = getPCoMLEndorsementAddressData(userPolicyResponse.value);
        const lastEndorsementDate = userPolicyResponse.value.last_endorsement_date ?? undefined;
        const submittedAt = applicationResponse.value.submitted_at;
        const isRenewal = applicationResponse.value.renewed_policy_id_list.length > 0;
        return PCoMLEndorsementPolicy.create({
            id: userPolicyResponse.value.id,
            addressData: addressData,
            availableLiabilities: availableLiabilitiesResult,
            submittedAt: submittedAt ? new Date(submittedAt) : undefined,
            effectivePeriodEnd: userPolicyResponse.value.effective_period_end,
            effectivePeriodStart: userPolicyResponse.value.effective_period_start,
            namedInsured: namedInsured,
            isRenewal,
            lastEndorsementDate: lastEndorsementDate,
        });
    }

    async createEndorsement(
        createEndorsementRequest: CreateEndorsementRequest,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed> {
        const createEndorsementResponse = await API.request('endorsement/create_endorsement', {
            policy_id: createEndorsementRequest.policyId,
            endorsement_type: createEndorsementRequest.endorsementType,
            effective_date: createEndorsementRequest.effectiveDate,
            limit_endorsement: createEndorsementRequest.limitEndorsement.coverage
                ? createEndorsementRequest.limitEndorsement
                : null,
            named_insured_endorsement: {
                named_insured: createEndorsementRequest.namedInsuredEndorsement.namedInsured,
            },
            address_endorsement: {
                address_line1: createEndorsementRequest.addressEndorsement.addressLine1,
                address_line2: createEndorsementRequest.addressEndorsement.addressLine2,
                city: createEndorsementRequest.addressEndorsement.city,
                zip_code: createEndorsementRequest.addressEndorsement.zipCode,
                state: createEndorsementRequest.addressEndorsement.state,
            },
            coverage_endorsement: null,
            extend_policy_endorsement: null,
            delete_coverage: null,
            runoff_length: null,
            reinstate_reason: null,
        } as unknown as APIType.EndorsementCreateEndorsementRequest);
        if (isErr(createEndorsementResponse)) {
            return handleOperationFailure(createEndorsementResponse);
        }
        return Success(createEndorsementResponse.value.task_id);
    }

    async rateEndorsement(
        rateEndorsementRequest: RateEndorsementRequest,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed> {
        const rateEndorsementResponse = await API.request('endorsement/rate_pcoml', {
            policy_id: rateEndorsementRequest.policyId,
            endorsement_type: rateEndorsementRequest.endorsementType,
            effective_date: rateEndorsementRequest.effectiveDate,
            limit_endorsement: rateEndorsementRequest.limitEndorsement,
            named_insured_endorsement: null,
            address_endorsement: null,
            coverage_endorsement: null,
            extend_policy_endorsement: null,
            delete_coverage: null,
            runoff_length: null,
            reinstate_reason: null,
        } as APIType.EndorsementRateRequest);

        if (isErr(rateEndorsementResponse)) {
            return handleOperationFailure(rateEndorsementResponse);
        }

        return Success(rateEndorsementResponse.value.task_id);
    }

    async loadUserData(): AsyncResult<PCoMLEndorsementUserData, InvalidArgument | OperationFailed> {
        const userDetailsResponse = await API.request('user/details');
        if (isErr(userDetailsResponse)) {
            return handleOperationFailure(userDetailsResponse);
        }
        // api workaround: ESP UserAccount must contain prefix_title; use empty "" as sentinel value for validation
        const title = userDetailsResponse.value.user.prefix_title
            ? userDetailsResponse.value.user.prefix_title
            : '';

        const state = userDetailsResponse.value.org.headquarters.state;

        let stateCode: State | undefined;

        const result = State.validate(state);
        if (isOK(result)) {
            stateCode = result.value;
        }

        return PCoMLEndorsementUserData.create({
            company: userDetailsResponse.value.org.name,
            fullName: `${userDetailsResponse.value.user.first_name} ${userDetailsResponse.value.user.last_name}`,
            title: title,
            usaState: stateCode,
        });
    }

    async getServerDate(): AsyncResult<Date, InvalidArgument | OperationFailed> {
        const serverTimeResponse = await API.request('global/get_server_time');
        if (isErr(serverTimeResponse)) {
            return handleOperationFailure(serverTimeResponse);
        }
        const serverDate = startOfDay(serverTimeResponse.value.time);
        if (!isValid(serverDate)) {
            return Failure(
                OperationFailed({
                    message: 'Invalid server time',
                }),
            );
        }
        return Success(serverDate);
    }

    async getPCoMLEndorsementConfig(): AsyncResult<
        PCoMLEndorsementConfig,
        InvalidArgument | OperationFailed
    > {
        const getConfigResponse = await API.request('global/get_config');

        if (isErr(getConfigResponse)) {
            return handleOperationFailure(getConfigResponse);
        }

        return PCoMLEndorsementConfig.create({
            pcomlEndorsementEnabled: getConfigResponse.value.pcoml_endorsement_enabled,
        });
    }
}
