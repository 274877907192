import { Nullable } from '@embroker/shotwell/core/types';
import { State } from '@embroker/shotwell/core/types/StateList';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface Place {
    id: Nullable<UUID>;
    addressLine1: Nullable<string>;
    addressLine2: Nullable<string>;
    city: Nullable<string>;
    zip: Nullable<ZipCode>;
    state: Nullable<State>;
    squareFootageOccupied: Nullable<number>;
    county: Nullable<string>;
    valueOfProperty: Nullable<number>;
}

export const Place = {
    ...defineValidator<Place>({
        id: UUID.schema.allow(null),
        addressLine1: Joi.string().allow(null),
        addressLine2: Joi.string().allow(null),
        city: Joi.string().allow(null),
        zip: ZipCode.schema.allow(null),
        state: State.schema.allow(null),
        squareFootageOccupied: Joi.number().allow(null),
        county: Joi.string().allow(null),
        valueOfProperty: Joi.number().allow(null),
    }),
    create(place: Place) {
        return Place.validate(place);
    },
};
