import {
    ColumnLayout,
    Form,
    Immutable,
    SelectChangeEvent,
    StackLayout,
    StatusMessage,
    Text,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { UnderlyingCoverageProps } from '../../../view/components/BundleQuoteCoverageList';
import { BOPChubbQuote } from '../entities/BOPChubbQuote';
import { BOPChubbAggregateLimit, BOPChubbPerOccurrenceLimit } from '../types/BOPChubbQuoteOptions';
import { ProductCodeRadioItem } from './BOPChubbBundleCoverageOptions';
import { CoverageOptionsTermDefinitions } from '../../../types/enums';
import { toSelectCurrencyOption } from '../../../../quote/toSelectCurrencyOption';

interface BOPChubbBundleCoverageViewProps extends UnderlyingCoverageProps<BOPChubbQuote> {
    perOccurrenceLimitOptions: Immutable<number[]>;
    aggregateLimitOptions: Immutable<number[]>;
    productCodeOptions: ProductCodeRadioItem[];
}

export function BOPChubbBundleCoverageView({
    fields,
    value,
    setValue,
    quote,
    organizationInfo,
    perOccurrenceLimitOptions,
    aggregateLimitOptions,
    productCodeOptions,
    disabled = false,
    questionnaireData,
}: BOPChubbBundleCoverageViewProps) {
    //CheckBox should be displayed if all vehicle questions are answered with no and if premium is higher than $600
    const premiumLimitInCents = 60000;
    const isAutoCoverageDisplayed =
        questionnaireData.delivery_with_rental_or_owned_vehicles !== undefined &&
        !questionnaireData.delivery_with_rental_or_owned_vehicles &&
        questionnaireData.ownership_of_vehicles != undefined &&
        !questionnaireData.ownership_of_vehicles &&
        quote.totalPremium.amount > premiumLimitInCents;

    const handlePerOccurrenceLimitChange = (
        e: SelectChangeEvent<BOPChubbPerOccurrenceLimit, false>,
    ) => {
        if (!e.target.value) {
            return;
        }
        const newValue = {
            ...value,
            bopChubbPerOccurrenceLimit: e.target.value,
            bopChubbAggregateLimit: (e.target.value * 2) as BOPChubbAggregateLimit,
        };
        setValue(newValue);
    };

    const handleAutoCoverageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = {
            ...value,
            bopChubbOmitHiredAndNonOwnedAutoCoverage: !e.target.checked,
        };
        setValue(newValue);
    };

    return (
        <StackLayout gap="12">
            <ColumnLayout gap="16" responsive={{ containerWidth: { smallerThan: 'large-mobile' } }}>
                <Form.Field
                    type={fields.bopChubbPerOccurrenceLimit.type}
                    inputProps={{
                        ...fields.bopChubbPerOccurrenceLimit.props,
                        filterable: false,
                        items: perOccurrenceLimitOptions,
                        label: CoverageOptionsTermDefinitions.perOccurenceLimit.title,
                        tooltip: CoverageOptionsTermDefinitions.perOccurenceLimit.definition,
                        onChange: handlePerOccurrenceLimitChange,
                        disabled,
                        createNewItem: toSelectCurrencyOption,
                    }}
                    data-e2e="bop-per-claim"
                />
                <Form.Field
                    type={fields.bopChubbAggregateLimit.type}
                    inputProps={{
                        ...fields.bopChubbAggregateLimit.props,
                        filterable: false,
                        items: aggregateLimitOptions,
                        readOnly: true,
                        disabled,
                        label: CoverageOptionsTermDefinitions.aggregateLimit.title,
                        tooltip: CoverageOptionsTermDefinitions.aggregateLimit.definition,
                        createNewItem: toSelectCurrencyOption,
                    }}
                    data-e2e="bop-aggregate"
                />
            </ColumnLayout>
            {organizationInfo.address.state !== 'FL' &&
                value.bopChubbProductCode === 'GeneralLiabilityBusinessPersonalProperty' && (
                    <Form.Field
                        type="text"
                        inputProps={{
                            label: CoverageOptionsTermDefinitions.businessPersonalPropertyLimit
                                .title,
                            value: '$100,000',
                            readOnly: true,
                            tooltip:
                                CoverageOptionsTermDefinitions.businessPersonalPropertyLimit
                                    .definition,
                            disabled,
                        }}
                        data-e2e="bop-business-personal-property"
                    />
                )}
            <Form.Field
                type="radioGroup"
                inputProps={{
                    ...fields.bopChubbProductCode.props,
                    items: productCodeOptions,
                    disabled,
                }}
                data-e2e="bop-product-code"
            />
            {isAutoCoverageDisplayed ? (
                <Form.Field
                    className="u-1/1"
                    type={fields.bopChubbOmitHiredAndNonOwnedAutoCoverage.type}
                    inputProps={{
                        ...fields.bopChubbOmitHiredAndNonOwnedAutoCoverage.props,
                        onChange: handleAutoCoverageChange,
                        checked: !fields.bopChubbOmitHiredAndNonOwnedAutoCoverage.props.value,
                        children: <span>Include Hired/Non-Owned Auto Coverage</span>,
                        disabled,
                        tooltipText: `Hired/Non-Owned Auto Coverage protects you and your business in the event of an auto accident while traveling for work purposes in a vehicle that is either rented or not owned by your company.`,
                    }}
                    data-e2e="bop-auto-coverage"
                />
            ) : null}
            {organizationInfo.address.state === 'FL' && (
                <StatusMessage status="info">
                    <Text style="microcopy">
                        Business Personal Property coverage will be included at a fixed limit of $1
                        for all Florida state policies, as required by Chubb.
                    </Text>
                </StatusMessage>
            )}
        </StackLayout>
    );
}
