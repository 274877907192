import { TooltipItem, Chart, LegendItem, ChartOptions as ChartJsOptions } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { defaults, Bar as ChartJsBar } from 'react-chartjs-2';
import { ReportColors } from './ReportColors';
import 'chartjs-adapter-date-fns';
import { format, parse, startOfToday } from 'date-fns';

export const displayDateFormat = "MMM ''yy";

function configureChartJsDefaults() {
    defaults.plugins.tooltip.callbacks.title = function (tooltipItems: TooltipItem<'bar'>[]) {
        if (!tooltipItems[0]) {
            return '';
        }
        const datapointDate = parse(tooltipItems[0].label, 'MMM d, yyyy, pp', startOfToday());
        return format(datapointDate, displayDateFormat);
    };
    defaults.plugins.legend.labels.filter = function (item: LegendItem) {
        return !item.text.includes('Dummy');
    };
    defaults.plugins.legend.labels.color = ReportColors.legendLabelColor;

    defaults.scales.time.ticks.color = ReportColors.tickText;
    defaults.scales.linear.ticks.color = ReportColors.tickText;

    Chart.register(ChartDataLabels);
    if (defaults.plugins.datalabels) {
        defaults.plugins.datalabels.offset = -2;
        defaults.plugins.datalabels.font = { size: 20 };
        defaults.plugins.datalabels.color = ReportColors.noDataIndicator;
        defaults.plugins.datalabels.align = 'end';
        defaults.plugins.datalabels.anchor = 'end';
    }
}

configureChartJsDefaults();

export const Bar = ChartJsBar;
export type BarChartOptions = ChartJsOptions<'bar'>;
