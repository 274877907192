import {
    defineValidator,
    Joi,
    Schema,
    TypeChecker,
    Validator,
} from '@embroker/shotwell/core/validation/schema';
import { SubjectOfInsurance } from './SubjectOfInsurance';
import { Limit } from './Limit';

export interface PropertySection {
    /**
     * Is policy having blanked insurance
     */
    blanketAdditionalInsured: boolean;
    /**
     * Are any claims made
     */
    claimsMade: boolean;
    /**
     * Occurrence
     */
    occur: boolean;
    /**
     * Can multiple policies get triggered by the same loss
     */
    primaryNonContributory: boolean;
    /**
     * Is insurance carriers eligible to legally pursue
     * a third party that caused an insurance loss to the insured
     */
    waiverOfSubrogation: boolean;
    /**
     * One or more units of exposure potentially involved in a single-loss event
     */
    subjectOfInsuranceList: SubjectOfInsurance[];
    /**
     * Limit for section
     */
    limit: Limit;
}

export interface PropertySectionValidator {
    /**
     * A Joi schema matching a valid PropertySection object.
     */
    readonly schema: Schema.ObjectSchema<PropertySection>;
    /**
     * Type predicate that checks if a given value can be used as PropertySection object.
     *
     * Use this only to do early returns. It's recommended to use validate()
     * before using an unknown value as PropertySection object as it normalizes the value
     * in addition to performing the same validation as check().
     */
    readonly check: TypeChecker<PropertySection>;
    /**
     * Validates and normalizes the given value to a PropertySection object.
     *
     * This should be used for all untrusted inputs to verify and, if required
     * (and possible), normalize the input.
     */
    readonly validate: Validator<PropertySection>;
}

/**
 * Constructs a Liability object representing USD cents.
 */
export const PropertySection = {
    ...defineValidator<PropertySection>(
        Joi.object({
            blanketAdditionalInsured: Joi.boolean(),
            claimsMade: Joi.boolean(),
            occur: Joi.boolean(),
            primaryNonContributory: Joi.boolean(),
            waiverOfSubrogation: Joi.boolean(),
            subjectOfInsuranceList: Joi.array().items(SubjectOfInsurance.schema),
            limit: Limit.schema,
        }).preferences({ presence: 'required' }),
    ),
    create(property: PropertySection) {
        return PropertySection.validate(property);
    },
};
