import { InvalidArgument, NetworkFailure, OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { CertificateTemplate } from '../../entities/CertificateTemplate';

/**
 * Interface for the certificateTemplate repository
 */
export interface CertificateTemplateRepository {
    /**
     * Fetches certificateTemplate with provided id
     * @param organizationId identifier of the organization
     * @returns Array of certificateTemplates associated with organization
     */
    getCertificateTemplatesForOrganization(
        organizationId: UUID,
    ): AsyncResult<CertificateTemplate[], OperationFailed | NetworkFailure | InvalidArgument>;
}

export const CertificateTemplateRepository = Symbol('CertificateTemplateRepository');
