import { isOK } from '@embroker/shotwell/core/types/Result';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { isValid, parseISO, startOfDay, startOfToday } from 'date-fns';
import { useEffect, useState } from 'react';
import { GetCyberConfig } from '../../useCases/GetCyberConfig';

export interface UseDevModeTodayResult {
    readonly result: Date;
    readonly isLoading: boolean;
}

export function useDevModeToday(): UseDevModeTodayResult {
    const [result, setResult] = useState<Date>(startOfToday());
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { isLoading: isLoadingCyberConfig, result: cyberConfigResult } =
        useUseCase(GetCyberConfig);

    useEffect(() => {
        if (!isLoadingCyberConfig && cyberConfigResult !== undefined) {
            if (isOK(cyberConfigResult)) {
                const devModeDate = cyberConfigResult.value.cyberDevModeEffectiveDate;
                const date = parseDate(devModeDate) ?? startOfToday();
                setResult(date);
            }
            setIsLoading(false);
        }
    }, [isLoadingCyberConfig, cyberConfigResult]);

    return { result, isLoading };
}

function parseDate(date: string): Date | undefined {
    return isValid(parseISO(date)) ? startOfDay(parseISO(date)) : undefined;
}
