import { FormElement } from '@embroker/service-app-engine';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { JSONSerdes } from '@embroker/shotwell/core/encoding';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { IndexableObject } from '@embroker/shotwell/core/object';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { format } from 'date-fns';
import { PolicyRepository } from '../../policy/repositories/PolicyRepository';
import { Lob } from '../../policy/types/Lob';
import { QuestionnaireParsingFailed } from '../../quote/errors';
import { Application } from '../../shopping/entities/Application';
import { ApplicationNotFound, OperationNotAllowed } from '../../shopping/errors';
import { ApplicationRepository } from '../../shopping/repositories/ApplicationRepository';
import { AppTypeCode, InCreation, Underlying } from '../../shopping/types/enums';
import { QuestionnaireData } from '../../shopping/types/QuestionnaireData';
import { Quote } from '../../shopping/types/Quote';
import {
    ApplicationStates,
    GetFormEngineContext,
    toFormEngineInput,
} from '../../shopping/useCases/GetFormEngineContext';
import { GetFormEngineInstance } from '../../shopping/useCases/GetFormEngineInstance';

export interface GetClientApplicationFormEngineRequest {
    token: string;
    submitText?: string;
}

export interface GetClientApplicationFormEngineResult {
    formEngine: FormElement;
    isRenewal: boolean;
    isInCreation: boolean;
}

export interface GetClientApplicationFormEngine extends UseCase {
    execute(
        request: GetClientApplicationFormEngineRequest,
    ): AsyncResult<
        GetClientApplicationFormEngineResult,
        | ApplicationNotFound
        | OperationNotAllowed
        | OperationFailed
        | InvalidArgument
        | QuestionnaireParsingFailed
    >;
}

@injectable()
class GetClientApplicationFormEngineUseCase
    extends UseCase
    implements GetClientApplicationFormEngine
{
    public static type = Symbol('Broker/GetClientApplicationFormEngineUseCase');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
        @inject(PolicyRepository) private policyRepository: PolicyRepository,
        @inject(GetFormEngineContext.type) private getFormEngineContext: GetFormEngineContext,
        @inject(GetFormEngineInstance.type) private getFormEngineInstance: GetFormEngineInstance,
    ) {
        super(eventBus);
    }

    public async execute({
        token,
        submitText = 'Update application',
    }: GetClientApplicationFormEngineRequest): AsyncResult<
        GetClientApplicationFormEngineResult,
        | ApplicationNotFound
        | OperationNotAllowed
        | OperationFailed
        | InvalidArgument
        | QuestionnaireParsingFailed
    > {
        const getClientApplicationResponse = await this.applicationRepository.getClientApplication(
            token,
        );
        if (isErr(getClientApplicationResponse)) {
            return getClientApplicationResponse;
        }

        const currentApplication = getClientApplicationResponse.value.currentApplication;
        const isInCreation = currentApplication.status === InCreation;
        const isRenewal = currentApplication.isRenewal();

        const questionnaireDataResult = JSONSerdes.deserialize(
            currentApplication.questionnaireData ?? '{}',
        );
        if (isErr(questionnaireDataResult)) {
            return Failure(
                QuestionnaireParsingFailed(currentApplication.questionnaireData || '{}'),
            );
        }
        const questionnaireData = questionnaireDataResult.value as IndexableObject;

        const previousDataResult = await this.getPreviousData(
            token,
            getClientApplicationResponse.value.previousApplication,
        );
        if (previousDataResult && isErr(previousDataResult)) {
            return previousDataResult;
        }
        const previousData = (previousDataResult && previousDataResult.value) || {};

        const prefillResponse = await this.applicationRepository.getClientQuestionnairePrefill(
            token,
        );
        if (isErr(prefillResponse)) {
            return prefillResponse;
        }

        const data = toFormEngineInput(questionnaireData);
        const parsedPreviousData = toFormEngineInput(previousData);
        const prefill = toFormEngineInput(prefillResponse.value);
        const underlyingAppTypes: AppTypeCode[] | undefined =
            currentApplication.bundleDetails?.appDetails
                .filter((item) => item.creationType === Underlying)
                .map((item) => item.appType);
        const context = {
            data,
            previousData: parsedPreviousData,
            prefill,
            isRenewal,
            isBroker: true,
            raisedFunding: data.raised_venture_funding ?? false,
            shoppingCoverageList: currentApplication.shoppingCoverageList,
            appTypeList: [currentApplication.appType],
            applicationState: ApplicationStates.SAVED,
            isClientApplication: true,
            newApplication: isInCreation,
            underlyingAppTypes,
        };

        const formEngineContextResponse = await this.getFormEngineContext.execute({
            context,
            requireSession: false,
        });
        if (isErr(formEngineContextResponse)) {
            return Failure(
                OperationFailed({
                    message: 'Failed to instantiate form engine context.',
                    errors: formEngineContextResponse.errors,
                }),
            );
        }

        const { formEngineContext } = formEngineContextResponse.value;
        const formEngineConfig = { alwaysNavigable: true };

        const formEngineInstanceResult = await this.getFormEngineInstance.execute({
            isNewApplication: isInCreation,
            formEngineContext,
            formEngineConfig,
            appType: currentApplication.appType,
        });
        if (isErr(formEngineInstanceResult)) {
            return formEngineInstanceResult;
        }
        const formEngine = formEngineInstanceResult.value.instance;

        formEngine.machine.update({
            continueText: 'Continue',
            submitText,
        });

        return Success<GetClientApplicationFormEngineResult>({
            formEngine,
            isRenewal,
            isInCreation,
        });
    }

    private async getPreviousData(
        token: string,
        previousApplication: Immutable<Application> | undefined,
    ) {
        if (!previousApplication) {
            return undefined;
        }
        const previousPolicyResponse = await this.policyRepository.getClientPreviousPolicy(token);
        if (isErr(previousPolicyResponse)) {
            return previousPolicyResponse;
        }
        const previousPolicy = previousPolicyResponse.value;

        let previousQuestionnaireData: QuestionnaireData;
        try {
            previousQuestionnaireData = JSON.parse(previousApplication.questionnaireData ?? '{}');
        } catch (error) {
            return Failure(
                OperationFailed({ message: 'Failed parse previous questionnaire data' }),
            );
        }

        const policyStartDate = format(previousPolicy.startDate, 'yyyy-MM-dd');
        const policyEndDate = format(previousPolicy.endDate, 'yyyy-MM-dd');
        const previousLplPolicy = mapToPreviousLPLPolicy(previousApplication.lastQuote);
        const previousEspPolicy = mapToPreviousESPPolicy(previousPolicy.lobList);
        const previousPcomlPolicy = mapToPreviousPCoMLPolicy(previousPolicy.lobList);
        const policyInsurer = previousPolicy.insurerName;
        const policyNumber = previousPolicy.policyNumber;
        const policyPremium = moneyToDollarAmount(previousPolicy.premiumPerYear);

        return Success({
            ...previousQuestionnaireData,
            policyStartDate,
            policyEndDate,
            policyNumber,
            policyInsurer,
            policyPremium,
            previousLplPolicy,
            previousEspPolicy,
            previousPcomlPolicy,
            quotingEngine: previousApplication.quotingEngine,
        });
    }
}

function mapToPreviousLPLPolicy(quote: Nullable<Quote>) {
    const lplPolicyOptions = quote?.options?.lplEverest;

    if (!quote || !lplPolicyOptions || !quote.accepted) {
        return undefined;
    }

    const claimsExpenseType =
        lplPolicyOptions.claimsExpenseType === 'ClaimsExpenseTypeInAdditionToLimits';
    const deductibleType = lplPolicyOptions.deductibleType === 'DeductibleTypeLossOnly';
    const totalPremium = moneyToDollarAmount(quote.totalPremium);

    return {
        perClaimLimit: lplPolicyOptions.perClaimLimit,
        aggregateLimitLiability: lplPolicyOptions.aggregateLimit,
        perClaimDeductible: lplPolicyOptions.perClaimDeductible,
        claimsExpenseOutside: claimsExpenseType,
        firstDollar: deductibleType,
        currentPremium: totalPremium,
    };
}

function mapToPreviousESPPolicy(lobList: Immutable<Lob[]>) {
    const dnoCoverage = lobList
        .find((lob) => lob.lobType === 'directorsAndOfficersLiabilityLob')
        ?.liabilitySection?.liabilityList?.find(
            (liability) =>
                liability.typeCode ===
                'LiabilityCoverageCodeListIndemnifiableDirectorsAndOfficersLiability',
        );
    const dnoLimit = moneyToDollarAmount(dnoCoverage?.limit1Amount);
    const dnoPremium = moneyToDollarAmount(dnoCoverage?.premiumAmount);

    const epliCoverage = lobList
        .find((lob) => lob.lobType === 'employmentPracticesLiabilityLob')
        ?.liabilitySection?.liabilityList?.find(
            (liability) =>
                liability.typeCode === 'LiabilityCoverageCodeListEmploymentPracticesLiability',
        );
    const epliLimit = moneyToDollarAmount(epliCoverage?.limit1Amount);
    const epliPremium = moneyToDollarAmount(epliCoverage?.premiumAmount);

    const fiduciaryCoverage = lobList
        .find((lob) => lob.lobType === 'fiduciaryLiabilityLob')
        ?.liabilitySection?.liabilityList?.find(
            (liability) => liability.typeCode === 'LiabilityCoverageCodeListFiduciaryLiability',
        );
    const fiduciaryLimit = moneyToDollarAmount(fiduciaryCoverage?.limit1Amount);
    const fiduciaryPremium = moneyToDollarAmount(fiduciaryCoverage?.premiumAmount);

    const eoCyberCoverage = lobList
        .find((lob) => lob.lobType === 'cyberLiabilityLob')
        ?.liabilitySection?.liabilityList?.find(
            (liability) =>
                liability.typeCode ===
                'LiabilityCoverageCodeListTechnologyAndMediaErrorsAndOmissions',
        );
    const eoCyberLimit = moneyToDollarAmount(eoCyberCoverage?.limit1Amount);
    const eoCyberPremium = moneyToDollarAmount(eoCyberCoverage?.premiumAmount);

    return {
        dnoLimit,
        dnoPremium,
        epliLimit,
        epliPremium,
        fiduciaryLimit,
        fiduciaryPremium,
        eoCyberLimit,
        eoCyberPremium,
    };
}

function mapToPreviousPCoMLPolicy(lobList: Immutable<Lob[]>) {
    const dnoCoverage = lobList
        .find((lob) => lob.lobType === 'directorsAndOfficersLiabilityLob')
        ?.liabilitySection?.liabilityList?.find(
            (liability) =>
                liability.typeCode ===
                'LiabilityCoverageCodeListIndemnifiableDirectorsAndOfficersLiability',
        );
    const dnoLimit = moneyToDollarAmount(dnoCoverage?.limit1Amount);
    const dnoPremium = moneyToDollarAmount(dnoCoverage?.premiumAmount);

    const epliCoverage = lobList
        .find((lob) => lob.lobType === 'employmentPracticesLiabilityLob')
        ?.liabilitySection?.liabilityList?.find(
            (liability) =>
                liability.typeCode === 'LiabilityCoverageCodeListEmploymentPracticesLiability',
        );
    const epliLimit = moneyToDollarAmount(epliCoverage?.limit1Amount);
    const epliPremium = moneyToDollarAmount(epliCoverage?.premiumAmount);

    return {
        dnoLimit,
        dnoPremium,
        epliLimit,
        epliPremium,
    };
}

function moneyToDollarAmount(amount?: Nullable<Money>) {
    return amount ? Money.toFloat(amount) : undefined;
}

export const GetClientApplicationFormEngine: UseCaseClass<GetClientApplicationFormEngine> =
    GetClientApplicationFormEngineUseCase;
