import { Money } from '@embroker/shotwell/core/types/Money';
import { PCoMLEndorsementLiabilityCoverageType } from '../../types/PCoMLEndorsementLiabilityCoverageType';

const defaultDoLimitOptions = [1000000, 2000000, 3000000, 4000000, 5000000];

const defaultDoRetentionOptions = [2500, 5000, 10000, 15000];

// fixed because of COVID-19
const defaultEpliLimitOptions = [1000000, 2000000];

const renewalEpliLimitOptions = [1000000, 2000000, 3000000, 4000000, 5000000];

const defaultEpliRetentionOptions = [10000, 25000, 50000, 75000, 100000, 150000];

export function getEditCoverageLimitOptions(
    coverageType: PCoMLEndorsementLiabilityCoverageType,
    currentLimit: Money,
    isRenewal: boolean,
): number[] {
    const currentLimitInt = Money.toFloat(currentLimit);
    switch (coverageType) {
        case 'LiabilityCoverageCodeListIndemnifiableDirectorsAndOfficersLiability':
            return defaultDoLimitOptions.filter((limitOption) => limitOption > currentLimitInt);
        case 'LiabilityCoverageCodeListEmploymentPracticesLiability':
            if (isRenewal) {
                return renewalEpliLimitOptions.filter(
                    (limitOption) => limitOption > currentLimitInt,
                );
            }
            return defaultEpliLimitOptions.filter((limitOption) => limitOption > currentLimitInt);
        default:
            return [];
    }
}

export function getAddCoverageLimitOptions(
    coverageType: PCoMLEndorsementLiabilityCoverageType,
): number[] {
    switch (coverageType) {
        case 'LiabilityCoverageCodeListEmploymentPracticesLiability':
            return defaultEpliLimitOptions;
        case 'LiabilityCoverageCodeListIndemnifiableDirectorsAndOfficersLiability':
            return defaultDoLimitOptions;
        default:
            return [];
    }
}

export function getAddCoverageRetentionOptions(
    coverageType: PCoMLEndorsementLiabilityCoverageType,
): number[] {
    switch (coverageType) {
        case 'LiabilityCoverageCodeListIndemnifiableDirectorsAndOfficersLiability':
            return defaultDoRetentionOptions;
        case 'LiabilityCoverageCodeListEmploymentPracticesLiability':
            return defaultEpliRetentionOptions;
        default:
            return [];
    }
}
