import {
    errorCodes,
    InvalidArgument,
    OperationFailed,
    Timeout,
    Aborted,
} from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { Failure, FailureResult, ErrorObject } from '@embroker/shotwell/core/types/Result';

export const ErrorCode = errorCodes({
    /**
     * Selected endorsement date is not in sequence error.
     */
    DateNotInSequence: 0xa400,
    /**
     * Selected endorsement date is not in sequence error.
     */
    NoChanges: 0xa401,
});

/**
 * Date not in sequence error object.
 */
export type DateNotInSequence = ErrorObject<typeof ErrorCode.DateNotInSequence, {}>;

/**
 * No changes made error object.
 */
export type NoChanges = ErrorObject<typeof ErrorCode.NoChanges, {}>;

export function DateNotInSequence(): Immutable<DateNotInSequence> {
    return {
        name: 'DateNotInSequence',
        code: ErrorCode.DateNotInSequence,
        message: 'Selected date is not in sequence.',
        details: {},
    };
}

export function NoChanges(): Immutable<NoChanges> {
    return {
        name: 'NoChanges',
        code: ErrorCode.NoChanges,
        message: 'No changes were made.',
        details: {},
    };
}

export const DATE_NOT_IN_SEQUENCE_ERROR_CODE = 'effective_date_not_in_sequence';
export const NO_CHANGES_ERROR_CODE = 'no_changes_made';

export function handleCreateEndorsementTaskFailure(
    pollForTaskStatusResult: FailureResult<InvalidArgument | OperationFailed | Aborted | Timeout>,
): FailureResult<
    OperationFailed | InvalidArgument | DateNotInSequence | NoChanges | Timeout | Aborted
> {
    if (pollForTaskStatusResult.errors && pollForTaskStatusResult.errors.length > 0) {
        const error = pollForTaskStatusResult.errors[0];
        if (error.message === DATE_NOT_IN_SEQUENCE_ERROR_CODE) {
            return Failure(DateNotInSequence());
        } else if (error.message === NO_CHANGES_ERROR_CODE) {
            return Failure(NoChanges());
        }
    }
    return pollForTaskStatusResult;
}
