import React, { SyntheticEvent } from 'react';
import { FormFieldView } from './FormFieldView.view';
import { FormFieldViewDefinition } from './DataDrivenForm';
import { FieldSetView } from './FieldSetView.view';
import { getQuestionsForFieldSet, isFieldSet, isStandAloneQuestion } from '../types/FieldSetType';
import { FormViewLayout } from '../../FormViewLayout.view';

export interface FormViewProps {
    submitText: string;
    currentPageTitle?: string;
    currentPageQuestions: FormFieldViewDefinition[];
    hasPrevious: boolean;
    handleSubmit: (e: SyntheticEvent) => void;
    handleBack: (e: SyntheticEvent) => void;
    handleComplexFieldChange: (questionKey: string, value: unknown) => void;
}

export function FormView(props: FormViewProps) {
    const {
        currentPageTitle,
        currentPageQuestions: questions,
        hasPrevious,
        submitText,
        handleComplexFieldChange,
        handleBack,
        handleSubmit,
    } = props;
    return (
        <FormViewLayout
            onFormSubmit={handleSubmit}
            disableSubmit={false}
            title={currentPageTitle || ''}
            handleBack={hasPrevious ? () => handleBack({} as any) : undefined}
            submitText={submitText}
            backText="Back"
        >
            {questions.map((formFieldView: FormFieldViewDefinition) => {
                const { key, staticOptions } = formFieldView.questionProps;
                const fieldSetProps = staticOptions?.fieldSetProps;

                if (
                    isFieldSet(formFieldView) &&
                    fieldSetProps &&
                    getQuestionsForFieldSet(formFieldView, questions).length
                ) {
                    return (
                        <FieldSetView
                            key={key}
                            {...fieldSetProps}
                            questions={getQuestionsForFieldSet(formFieldView, questions)}
                            handleComplexFieldChange={handleComplexFieldChange}
                        />
                    );
                }

                if (!isStandAloneQuestion(key, questions)) {
                    // If the question is part of a field set, it will be rendered within FieldSetView, not as a stand-alone question
                    return null;
                }

                return (
                    <FormFieldView
                        key={key}
                        {...formFieldView}
                        onComplexFieldChange={handleComplexFieldChange}
                    />
                );
            })}
        </FormViewLayout>
    );
}
