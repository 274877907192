import { inject } from '@embroker/shotwell/core/di';
import {
    Aborted,
    InvalidArgument,
    OperationFailed,
    Timeout,
    UnknownEntity,
} from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { CalculateSKU } from '../../../analytics/useCases/CalculateSKU';
import { ApplicationRepository } from '../../../shopping/repositories/ApplicationRepository';
import { TasksRepository } from '../../../tasks/repositories';
import { ApplicationFetchFailed, QuestionnaireParsingFailed } from '../../errors';
import { ESPRenewalQuote } from '../entities/ESPRenewalQuote';
import { ESPRenewalConfigFetchFailed } from '../errors';
import { QuoteRepository } from '../repositories/QuoteRepository';
import {
    ESPRenewalQuoteOptions,
    ESPRenewalQuoteOptionsToESPRateOptions,
} from '../types/ESPRenewalQuoteOptions';

export interface UpdateQuoteRequest {
    quote: ESPRenewalQuote;
    quoteOptions: ESPRenewalQuoteOptions;
    abortSignal: AbortSignal;
    maxPollingRetries?: number;
    pollingRetryIntervalInMilliseconds?: number;
}

export interface UpdateQuoteResponse {
    quote: Immutable<ESPRenewalQuote>;
}

export interface UpdateQuote extends UseCase {
    execute(
        request: UpdateQuoteRequest,
    ): AsyncResult<
        UpdateQuoteResponse,
        | UnknownEntity
        | InvalidArgument
        | OperationFailed
        | ESPRenewalConfigFetchFailed
        | ApplicationFetchFailed
        | QuestionnaireParsingFailed
        | Aborted
        | Timeout
    >;
}

class UpdateQuoteUseCase extends UseCase implements UpdateQuote {
    public static type = Symbol('ESPRenewalQuote/UpdateQuote');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(QuoteRepository) private quoteRepository: QuoteRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
        @inject(CalculateSKU.type) private calculateSKU: CalculateSKU,
    ) {
        super(eventBus);
    }

    public async execute({
        quote,
        quoteOptions,
        abortSignal,
        maxPollingRetries = 120,
        pollingRetryIntervalInMilliseconds = 1000,
    }: UpdateQuoteRequest): AsyncResult<
        UpdateQuoteResponse,
        | UnknownEntity
        | InvalidArgument
        | OperationFailed
        | ESPRenewalConfigFetchFailed
        | ApplicationFetchFailed
        | QuestionnaireParsingFailed
        | Aborted
        | Timeout
    > {
        const quoteRepoResult = await this.quoteRepository.updateQuote(
            quote.applicationId,
            ESPRenewalQuoteOptionsToESPRateOptions(quoteOptions),
        );

        if (isErr(quoteRepoResult)) {
            return quoteRepoResult;
        }

        const pollForTaskStatusResult = await this.tasksRepository.pollForTaskStatus(
            quoteRepoResult.value,
            abortSignal,
            maxPollingRetries,
            pollingRetryIntervalInMilliseconds,
        );
        if (isErr(pollForTaskStatusResult)) {
            return pollForTaskStatusResult;
        }

        const quoteResult = await this.quoteRepository.getQuoteByApplicationId(quote.applicationId);
        if (isErr(quoteResult)) {
            return quoteResult;
        }

        const { value: ESPRenewalQuote } = quoteResult;

        const getApplicationResult = await this.applicationRepository.getApplication(
            quote.applicationId,
        );
        if (isErr(getApplicationResult)) {
            return getApplicationResult;
        }

        const application = getApplicationResult.value;

        const skuResult = await this.calculateSKU.execute({
            event: 'quote',
            applicationId: quote.applicationId,
        });
        application.onQuotePremium({
            totalPremium: ESPRenewalQuote.totalPayable,
            applicationId: ESPRenewalQuote.applicationId,
            isRenewal: true,
            sku: skuResult.value,
        });
        await this.eventBus.publishEntityEvents(application);

        return Success<UpdateQuoteResponse>({
            quote: ESPRenewalQuote,
        });
    }
}

export const UpdateQuote: UseCaseClass<UpdateQuote> = UpdateQuoteUseCase;
