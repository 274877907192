import React from 'react';
import { StackLayout, Table, Text } from '@embroker/ui-toolkit/v2';
import { Immutable } from '@embroker/shotwell/core/types';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { PolicyDetailsCard } from './PolicyDetailsCard.view';
import { PolicyAttorney } from '../../../types/PolicyAttorney';

interface AttorneyRosterCardProps {
    attorneyRoster: Immutable<PolicyAttorney[]>;
}

export const AttorneyRosterCard = ({ attorneyRoster }: AttorneyRosterCardProps) => {
    function getAverageWeeklyHours(avgAverageWeeklyHours: number): string {
        switch (avgAverageWeeklyHours) {
            case 0:
                return '<9';
            case 10:
                return '10-20';
            case 21:
                return '>21';
            default:
                return '';
        }
    }

    return (
        <PolicyDetailsCard title="Attorney Roster">
            <StackLayout gap="none">
                <Text style="heading 5">List of All Attorneys Covered by Your Policy</Text>
                <Table>
                    <Table.Header>
                        <Table.Column>ATTORNEY NAME</Table.Column>
                        <Table.Column>YEAR ADMITTED TO BAR</Table.Column>
                        <Table.Column>JOINED FIRM</Table.Column>
                        <Table.Column>HOURS PER WEEK</Table.Column>
                    </Table.Header>
                    <Table.Body>
                        {attorneyRoster.map((attorney) => {
                            return (
                                <Table.Row key={attorney.id}>
                                    <Table.Cell>{attorney.fullName}</Table.Cell>
                                    <Table.Cell>
                                        <Text>{attorney.barAdmittedDate.getFullYear()}</Text>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <DateDisplay format="M/d/yyyy" value={attorney.hireDate} />
                                    </Table.Cell>
                                    <Table.Cell>
                                        {getAverageWeeklyHours(attorney.averageWeeklyHours)}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            </StackLayout>
        </PolicyDetailsCard>
    );
};
