import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Data } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { Revenue } from '@embroker/shotwell/core/types/Revenue';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { NAICSRepository } from '../../industries/repositories/NAICSRepository';
import { hasRole } from '../../userOrg/entities/Session';
import { EntityTypeRepository } from '../../userOrg/repositories/EntityTypeRepository';
import { SessionRepository } from '../../userOrg/repositories/SessionRepository';
import { Applicant } from '../entities/Applicant';
import { ApplicantRepository } from '../repositories/ApplicantRepository';
import { EnrichmentData } from '../types/EnrichmentData';
import { Headquarters } from '../types/Headquarters';
import { Location, Locations } from '../types/Location';
import { Place } from '../types/Place';
import { QuestionnaireData } from '../types/QuestionnaireData';
import { OnboardingPrefillQuestionnaireData } from '../../userOrg/types/OnboardingPrefillQuestionnaireData';

export interface OverrideQuestionnaireDataRequest {
    questionnaireData: QuestionnaireData;
    onboardingPrefill?: OnboardingPrefillQuestionnaireData;
    enrichmentData?: EnrichmentData;
    prefill: Data;
    overrideWebsite: boolean;
    isIntake?: boolean;
}

export interface OverrideQuestionnaireDataResponse {
    questionnaireData: QuestionnaireData;
}

export interface OverrideQuestionnaireData extends UseCase {
    execute(
        request: OverrideQuestionnaireDataRequest,
    ): AsyncResult<OverrideQuestionnaireDataResponse, InvalidArgument | OperationFailed>;
}

@injectable()
export class OverrideQuestionnaireDataUseCase extends UseCase implements OverrideQuestionnaireData {
    public static type = Symbol('Shopping/OverrideQuestionnaireData');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicantRepository) private applicantRepository: ApplicantRepository,
        @inject(EntityTypeRepository) private entityTypeRepository: EntityTypeRepository,
        @inject(NAICSRepository) private naicsRepository: NAICSRepository,
        @inject(SessionRepository) private sessionRepository: SessionRepository,
    ) {
        super(eventBus);
    }
    public async execute({
        questionnaireData,
        enrichmentData,
        prefill,
        overrideWebsite: shouldOverrideWebsite,
        onboardingPrefill,
        isIntake = false,
    }: OverrideQuestionnaireDataRequest): AsyncResult<
        OverrideQuestionnaireDataResponse,
        InvalidArgument | OperationFailed
    > {
        const activeSessionResult = await this.sessionRepository.getActiveSession();
        if (isErr(activeSessionResult)) {
            return handleOperationFailure(activeSessionResult);
        }

        if (activeSessionResult.value === null || activeSessionResult.value === undefined) {
            return Failure(
                OperationFailed({ message: 'Detected null for value in activeSessionResult' }),
            );
        }

        const isBroker = hasRole(activeSessionResult.value, 'broker');

        const getApplicantResponse = await this.applicantRepository.getApplicant();

        if (isErr(getApplicantResponse)) {
            return handleOperationFailure(getApplicantResponse);
        }

        const applicant = getApplicantResponse.value as Applicant;

        const entityTypesResponse = await this.entityTypeRepository.getEntityTypes();

        if (isErr(entityTypesResponse)) {
            return handleOperationFailure(entityTypesResponse);
        }

        const entityTypes = entityTypesResponse.value;

        const entity = entityTypes.find((entity) => {
            return entity.id === applicant.entityTypeId;
        })?.name;

        const locationsPrefill = prefill.locations || {};

        const overrides: Overrides = {
            applicant,
            entity,
            locationsPrefill,
            shouldOverrideWebsite,
            isIntake,
            enrichmentData,
            onboardingPrefill,
        };

        const override = isBroker ? overrideAccess : overrideDirect;

        const overriddenQuestionnaireData = override(questionnaireData, overrides);

        return Success({ questionnaireData: overriddenQuestionnaireData });
    }
}

export const OverrideQuestionnaireData: UseCaseClass<OverrideQuestionnaireData> =
    OverrideQuestionnaireDataUseCase;

interface Overrides {
    readonly applicant: Applicant;
    readonly locationsPrefill: any;
    readonly entity: string | undefined;
    readonly shouldOverrideWebsite: boolean;
    readonly isIntake: boolean;
    readonly enrichmentData?: EnrichmentData;
    readonly onboardingPrefill?: OnboardingPrefillQuestionnaireData;
}

function overrideDirect(result: QuestionnaireData, input: Overrides): QuestionnaireData {
    const { applicant, entity, locationsPrefill, shouldOverrideWebsite, onboardingPrefill } = input;

    result = overrideUserData(result, applicant);
    result = overrideHeadquartersInfo(result, applicant.headquarters);

    result = {
        ...result,
        locations: getQuestionnaireLocations(applicant.locations ?? [], locationsPrefill),
        revenue_list: mapRevenueList(applicant.revenueList),
    };

    if (shouldOverrideWebsite) {
        result = {
            ...result,
            website: applicant.website ?? undefined,
        };
    }

    return {
        ...result,
        entity,
        company_name: applicant.organizationName || undefined,
        phone_number: applicant.phoneNumber ? parseInt(applicant.phoneNumber, 10) : undefined,
        number_of_employees: applicant.numberOfEmployees,
        year_started: applicant.yearStarted,
        total_payroll: moneyToFormEngineCurrency(applicant.totalPayroll || null),
        product_or_service: applicant.productOrService || undefined,
        raised_venture_funding: applicant.hasReceivedVCFunding,
        tech_area_of_focus: applicant.techAreaOfFocus || undefined,
        naics_code: applicant.naicsIndustry || undefined,
        working_space_type: applicant.workspaceType,
        ...onboardingPrefill,
    };
}

function overrideAccess(result: QuestionnaireData, input: Overrides): QuestionnaireData {
    const { applicant, enrichmentData, entity, locationsPrefill, shouldOverrideWebsite, isIntake } =
        input;

    if (isIntake) {
        if (enrichmentData?.totalFunding?.amount) {
            const hasQuestionnaireTotalFunding = result.total_funding_amount !== undefined;
            if (!hasQuestionnaireTotalFunding) {
                result = {
                    ...result,
                    total_funding_amount: moneyToFormEngineCurrency(enrichmentData.totalFunding),
                };
            }
        }
    } else {
        // We do not override headquarters info in intake flow since, initially,
        // it is not populated in organization prefill.
        result = overrideHeadquartersInfo(result, applicant.headquarters);
        // Preventing the scenario where revenue and/or additional locations data that client filled in for a new organization gets deleted in case the broker opens an incomplete shareable application
        const shouldOverrideRevenue = applicant.revenueList && applicant.revenueList.length > 0;
        const shouldOverrideLocations = applicant.locations && applicant.locations.length > 0;
        result = {
            ...result,
            locations: shouldOverrideLocations
                ? getQuestionnaireLocations(applicant.locations ?? [], locationsPrefill)
                : undefined,
            revenue_list: shouldOverrideRevenue ? mapRevenueList(applicant.revenueList) : undefined,
        };
    }

    if (shouldOverrideWebsite) {
        result = {
            ...result,
            website: applicant.website ?? undefined,
        };
    }

    result = overrideBrokerData(result, applicant);

    // Important note: this logic relays on the prefill containing the same data as the questionnaireData or newer (updated through another application, for example),
    // so setting the values to undefined does not delete any data unless prefilling is explicitly set to false, which is not the case for any of these fields.

    return {
        ...result,
        entity,
        company_name: applicant.organizationName || undefined,
        phone_number: applicant.phoneNumber ? parseInt(applicant.phoneNumber, 10) : undefined,
        number_of_employees: applicant.numberOfEmployees,
        year_started: applicant.yearStarted,
        total_payroll: moneyToFormEngineCurrency(applicant.totalPayroll || null),
        product_or_service: applicant.productOrService || undefined,
        raised_venture_funding: applicant.hasReceivedVCFunding,
        tech_area_of_focus: applicant.techAreaOfFocus || undefined,
        naics_code: applicant.naicsIndustry || undefined,
        working_space_type: applicant.workspaceType,
    };
}

function overrideUserData(result: QuestionnaireData, applicant: Applicant): QuestionnaireData {
    return {
        ...result,
        first_name: applicant.firstName ?? undefined,
        last_name: applicant.lastName ?? undefined,
        title: applicant.title ?? undefined,
        email: applicant.userEmail ?? undefined,
    };
}

function overrideBrokerData(result: QuestionnaireData, applicant: Applicant): QuestionnaireData {
    return {
        ...result,
        broker_first_name: result.broker_first_name ?? applicant.firstName ?? undefined,
        broker_last_name: result.broker_last_name ?? applicant.lastName ?? undefined,
        broker_email: result.broker_email ?? applicant.userEmail ?? undefined,
    };
}

function overrideHeadquartersInfo(result: QuestionnaireData, hq: Headquarters): QuestionnaireData {
    return {
        ...result,
        city: hq?.city ?? undefined,
        state: hq?.state ?? undefined,
        county: hq?.county ?? undefined,
        zip: hq?.zip ?? undefined,
        mailing_address: hq?.addressLine1 ?? undefined,
        suite: hq?.addressLine2 ?? undefined,
    };
}

function mapRevenueList(revenueList: Revenue[] | undefined): any[] | undefined {
    return (
        revenueList?.map(function toQuestionnaireRevenue(revenue) {
            return {
                fiscal_year: revenue.fiscalYear.toString(),
                gross_revenue_total: moneyToFormEngineCurrency(revenue.grossTotal),
            };
        }) ?? undefined
    );
}

function getQuestionnaireLocations(locations: Place[], locationsPrefill: any): Locations {
    const prefillLocationMap = new Map<UUID, any>();
    if (locationsPrefill?.location) {
        locationsPrefill.location.forEach(function (elem: any) {
            const { id, ...other } = elem;
            if (id) {
                prefillLocationMap.set(id, { ...other });
            }
        });
    }
    return {
        location: locations.map((location: Place) =>
            toQuestionnaireDataLocation(location, prefillLocationMap),
        ),
    };
}

function toQuestionnaireDataLocation(place: Place, prefillLocationMap: Map<UUID, any>): Location {
    const extraLocationData = place.id ? prefillLocationMap.get(place.id) : {};
    return {
        ...extraLocationData,
        id: place.id,
        city: place.city,
        state: place.state,
        place: place.county,
        county: place.county,
        mailing_address: place.addressLine1 ?? '',
        suite: place.addressLine2 ?? '',
        zip: place.zip,
        square_footage: place.squareFootageOccupied,
    };
}

function moneyToFormEngineCurrency(value: Money | null) {
    if (value) {
        return Money.toFloat(value);
    }
    return undefined;
}
