import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { ModelVersion } from './ModelVersion';
import { Immutable } from '@embroker/shotwell/core/types';

export interface ServiceDefinition {
    service: string;
    modelVersion: Immutable<ModelVersion>;
}

export const ServiceDefinition = {
    ...defineValidator<ServiceDefinition>(
        Joi.object({
            service: Joi.string(),
            modelVersion: ModelVersion.schema,
        }),
    ),
    create(serviceDefinition: ServiceDefinition) {
        return ServiceDefinition.validate(serviceDefinition);
    },
};
