import { inject } from '@embroker/shotwell/core/di';
import { Aborted, InvalidArgument, OperationFailed, Timeout } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, isErr, Success, SuccessResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { CalculateSKU } from '../../analytics/useCases/CalculateSKU';
import { ApplicationQuoteCreated, ApplicationReferred } from '../../shopping/entities/Application';
import { TasksRepository } from '../../tasks/repositories';
import { BundleQuote } from '../entities/BundleQuote';
import { ApplicationNotFound, EnqueueReQuoteBundleTaskError, OperationNotAllowed } from '../errors';
import { BundleQuoteRepository } from '../repositories';
import {
    BundleCoverageType,
    BundleCoverageTypeEnum,
    BundleWarningEnum,
    BundleWarningStatus,
} from '../types/BundleQuoteCoverage';
import { BundleQuoteOptions } from '../types/BundleQuoteOptions';
import { CowbellCyberQuoteOptions } from '../coverageDefinition/cowbellCyber/types/CowbellCyberQuoteOptions';

export interface ReQuoteBundleRequest {
    applicationId: UUID;
    quoteOptionsMap: Immutable<Map<BundleCoverageType, BundleQuoteOptions>>;
    abortSignal: AbortSignal;
}

export interface ReQuoteBundle extends UseCase {
    execute(
        request: ReQuoteBundleRequest,
    ): AsyncResult<
        ReQuoteBundleResponse,
        | ApplicationNotFound
        | EnqueueReQuoteBundleTaskError
        | OperationNotAllowed
        | InvalidArgument
        | OperationFailed
        | Aborted
        | Timeout
    >;
}

export interface ReQuoteBundleResponse {
    bundleQuote: SuccessResult<BundleQuote>;
    warnings: BundleWarningStatus[];
}

class ReQuoteBundleUseCase extends UseCase implements ReQuoteBundle {
    public static type = Symbol('BundleQuote/ReQuoteBundle');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BundleQuoteRepository) private bundleQuoteRepository: BundleQuoteRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
        @inject(CalculateSKU.type) private calculateSku: CalculateSKU,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
        quoteOptionsMap,
        abortSignal,
    }: ReQuoteBundleRequest): AsyncResult<
        ReQuoteBundleResponse,
        | ApplicationNotFound
        | EnqueueReQuoteBundleTaskError
        | OperationNotAllowed
        | InvalidArgument
        | OperationFailed
        | Aborted
        | Timeout
    > {
        const enqueueReQuoteTaskResult = await this.bundleQuoteRepository.enqueueReQuoteBundleTask(
            applicationId,
            quoteOptionsMap,
        );

        if (isErr(enqueueReQuoteTaskResult)) {
            return enqueueReQuoteTaskResult;
        }

        const pollForTaskStatusResult = await this.tasksRepository.pollForTaskStatus(
            enqueueReQuoteTaskResult.value,
            abortSignal,
        );

        if (isErr(pollForTaskStatusResult)) {
            return pollForTaskStatusResult;
        }

        const getLastQuoteResult = await this.bundleQuoteRepository.getLastBundleQuote(
            applicationId,
        );

        if (isErr(getLastQuoteResult)) {
            return getLastQuoteResult;
        }

        const bundleQuote = getLastQuoteResult.value;
        const skuResult = await this.calculateSku.execute({
            event: 'quote',
            applicationId,
        });

        if (bundleQuote.isAnyCoverageReferred()) {
            const event: ApplicationReferred = {
                origin: 'Application',
                name: 'Referred',
                createdAt: new Date(Date.now()),
                applicationId: applicationId,
                totalPremium: bundleQuote.getTotalBundlePremium(),
                id: bundleQuote.id,
                sku: skuResult.value,
                isRenewal: false, // no renewals for now
            };
            this.eventBus.publish(event);
        } else if (
            !bundleQuote.areAllCoveragesNotEligible() &&
            !bundleQuote.areAllCoveragesDisabled()
        ) {
            const event: ApplicationQuoteCreated = {
                origin: 'Application',
                name: 'QuoteCreated',
                createdAt: new Date(Date.now()),
                applicationId,
                totalPremium: bundleQuote.getTotalBundlePremium(),
                id: bundleQuote.id,
                sku: skuResult.value,
                isRenewal: false, // no renewals for now
            };
            this.eventBus.publish(event);
        }

        // There is a case where cowbell sends back a limit or retention that doesn't match what we request at requote.
        // We need to add a warning to show users that their request has been override.
        const warnings = [] as BundleWarningStatus[];
        const cyberRequestOptions = quoteOptionsMap.get(
            BundleCoverageTypeEnum.LawCyberBundleCoverageCowbell,
        );
        if (cyberRequestOptions !== undefined) {
            const requestOptions = cyberRequestOptions as CowbellCyberQuoteOptions;
            const responseOptions = getLastQuoteResult.value.getCoverageQuoteOptions(
                BundleCoverageTypeEnum.LawCyberBundleCoverageCowbell,
            ) as CowbellCyberQuoteOptions;
            if (
                requestOptions.limit != responseOptions.limit ||
                requestOptions.retention != responseOptions.retention
            ) {
                warnings.push(BundleWarningEnum.CyberQuoteOptionsOverride);
            }
        }

        return Success({
            bundleQuote: getLastQuoteResult,
            warnings: warnings,
        });
    }
}

export const ReQuoteBundle: UseCaseClass<ReQuoteBundle> = ReQuoteBundleUseCase;
