import { API } from '@embroker/shotwell-api/v2/app';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Failure,
    Success,
    mergeErrors,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import {
    Notification,
    NotificationViewModel,
    NotificationStatus,
    NotificationActionTypes,
} from '../../entities/Notification';
import { NotificationRepository } from '.';
import * as APITypes from '@embroker/shotwell-api/v2/app.spec';
import { UpdateNotificationStatusRequest } from '../../useCases/UpdateNotificationStatus';
import {
    FailedToCreateNotificationEntity,
    FailedToGetNotifications,
    FailedToUpdateAllNotificationsStatus,
    FailedToGetNotificationsStats,
    FailedToUpdateNotificationStatus,
} from '../../errors';
import { NotificationStats } from '../../types/NotificationStats';

@injectable()
export class APINotificationRepository implements NotificationRepository {
    public async getNotificationsStats(): AsyncResult<
        NotificationStats,
        FailedToGetNotificationsStats
    > {
        const result = await API.get('notifications/stats');

        if (isErr(result)) {
            return Failure(FailedToGetNotificationsStats());
        }

        const countUnread = result.value.count_unread;

        return Success({ countUnread });
    }

    public async getNotifications(): AsyncResult<
        Notification[],
        FailedToGetNotifications | InvalidArgument | OperationFailed
    > {
        const result = await API.get('notifications');

        if (isErr(result)) {
            return Failure(FailedToGetNotifications());
        }

        const notifications: Notification[] = [];
        const notificationsResults = await Promise.all(
            result.value.notifications.map((notification) =>
                createNotificationEntity(notification),
            ),
        );

        for (const result of notificationsResults) {
            if (isErr(result)) {
                return mergeErrors(notificationsResults);
            }
            notifications.push(result.value);
        }

        return Success(notifications);
    }

    public async updateNotificationStatus(
        request: UpdateNotificationStatusRequest,
    ): AsyncResult<
        Notification,
        | InvalidArgument
        | FailedToUpdateNotificationStatus
        | UnknownEntity
        | FailedToCreateNotificationEntity
    > {
        const {
            notification: { id },
            status,
        } = request;
        const result = await API.patch(`notifications/${id}`, { status });

        if (isErr(result)) {
            return Failure(FailedToUpdateNotificationStatus());
        }

        const notification = await createNotificationEntity(result.value);
        if (isErr(notification)) {
            return Failure(FailedToCreateNotificationEntity());
        }

        return Success(notification.value);
    }

    public async updateAllNotificationStatus(
        status: NotificationStatus,
    ): AsyncResult<void, FailedToUpdateAllNotificationsStatus> {
        const result = await API.patch('notifications', { status });

        if (isErr(result)) {
            return Failure(FailedToUpdateAllNotificationsStatus());
        }

        return Success();
    }
}

export const createNotificationEntity = async (notification: APITypes.Notification) => {
    const entityInput: NotificationViewModel = {
        id: notification.id as UUID,
        content: notification.content,
        // TODO: https://embroker.atlassian.net/browse/EM-35269
        // Move date unmarshalling over to Notification service BFF layer
        created: new Date(notification.created),
        status: notification.status,
    };

    if (notification.cta) {
        entityInput.action = {
            type: notification.cta.type as NotificationActionTypes,
            value: notification.cta.value,
        };
    }

    const validateProps = Notification.validate(entityInput, undefined, { convert: false });

    if (isErr(validateProps)) {
        return handleOperationFailure(validateProps);
    }

    const userNotification = await Notification.create(entityInput);

    if (isErr(userNotification)) {
        return handleOperationFailure(userNotification);
    }

    return Success(userNotification.value);
};
