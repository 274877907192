import { Money } from '@embroker/shotwell/core/types/Money';
import { State } from '@embroker/shotwell/core/types/StateList';
import { IconName } from '@embroker/ui-toolkit/v2';
import { CoverageRestriction } from '../types/CoverageRestriction';
import { Nullable } from '@embroker/shotwell/core/types';

interface PCoMLCoverageDetails {
    readonly title: string;
    readonly icon: IconName;
    readonly text: string;
    readonly documentUrl: string;
    readonly newDocumentUrl: string;
}

interface PCoMLDocumentsOffsetByState {
    readonly daysToPublish: number;
}

type UsaState = State;

type PCoMLQuoteDocumentsDetails = { [k in UsaState]: PCoMLDocumentsOffsetByState };

export const renewalQuoteDocumentsPublishDetails: PCoMLQuoteDocumentsDetails = {
    AR: {
        daysToPublish: 0,
    },
    SC: {
        daysToPublish: 0,
    },
    TX: {
        daysToPublish: 0,
    },
    ME: {
        daysToPublish: 0,
    },
    MS: {
        daysToPublish: 0,
    },
    FL: {
        daysToPublish: 45,
    },
    IN: {
        daysToPublish: 0,
    },
    IA: {
        daysToPublish: 0,
    },
    MA: {
        daysToPublish: 45,
    },
    MT: {
        daysToPublish: 0,
    },
    OK: {
        daysToPublish: 45,
    },
    VT: {
        daysToPublish: 0,
    },
    PA: {
        daysToPublish: 45,
    },
    CT: {
        daysToPublish: 0,
    },
    AL: {
        daysToPublish: 0,
    },
    AK: {
        daysToPublish: 0,
    },
    AZ: {
        daysToPublish: 0,
    },
    CA: {
        daysToPublish: 0,
    },
    CO: {
        daysToPublish: 0,
    },
    DC: {
        daysToPublish: 0,
    },
    DE: {
        daysToPublish: 0,
    },
    GA: {
        daysToPublish: 0,
    },
    HI: {
        daysToPublish: 0,
    },
    ID: {
        daysToPublish: 0,
    },
    IL: {
        daysToPublish: 0,
    },
    KS: {
        daysToPublish: 0,
    },
    KY: {
        daysToPublish: 0,
    },
    LA: {
        daysToPublish: 0,
    },
    MD: {
        daysToPublish: 0,
    },
    MI: {
        daysToPublish: 0,
    },
    MN: {
        daysToPublish: 0,
    },
    MO: {
        daysToPublish: 0,
    },
    NE: {
        daysToPublish: 0,
    },
    NV: {
        daysToPublish: 0,
    },
    NH: {
        daysToPublish: 0,
    },
    NJ: {
        daysToPublish: 0,
    },
    NM: {
        daysToPublish: 0,
    },
    NY: {
        daysToPublish: 0,
    },
    NC: {
        daysToPublish: 0,
    },
    ND: {
        daysToPublish: 0,
    },
    OH: {
        daysToPublish: 0,
    },
    OR: {
        daysToPublish: 0,
    },
    RI: {
        daysToPublish: 0,
    },
    SD: {
        daysToPublish: 0,
    },
    TN: {
        daysToPublish: 0,
    },
    UT: {
        daysToPublish: 0,
    },
    VA: {
        daysToPublish: 0,
    },
    WA: {
        daysToPublish: 0,
    },
    WV: {
        daysToPublish: 0,
    },
    WI: {
        daysToPublish: 0,
    },
    WY: {
        daysToPublish: 0,
    },
};

export const pcomlQuotesCoverageDetailsMap: Record<string, PCoMLCoverageDetails> = {
    dno: {
        title: 'Directors and Officers',
        icon: 'meeting',
        text: 'Shield your company and its board members from litigation by disgruntled shareholders, customers, investors and regulatory bodies.',
        documentUrl:
            'https://s3.amazonaws.com/embroker-public/Policies/DNO_Coverage_Terms_and_Conditions_pcoml.pdf',
        newDocumentUrl:
            'https://embroker-public.s3.amazonaws.com/Policies/Policies+DNO+Coverage+Terms+and+Conditions+pcoml+V.2.pdf',
    },
    epl: {
        title: 'Employment Practices Liability',
        icon: 'suitcase',
        text: 'EPLI provides coverage for claims alleging discrimination, wrongful termination, harassment and other employment related issues.',
        documentUrl:
            'https://s3.amazonaws.com/embroker-public/Policies/EPLI_Terms_and_Conditions_pcoml.pdf',
        newDocumentUrl:
            'https://embroker-public.s3.amazonaws.com/Policies/Policies+EPLI+Terms+and+Conditions+pcoml.pdf+V.2.pdf',
    },
};

interface Option {
    readonly title: string;
    readonly value: number;
}

export const limitRetentionOptionsMap: Record<string, Record<string, Option[]>> = {
    dno: {
        retentions: [
            { title: '$2,500', value: 2500 },
            { title: '$5,000', value: 5000 },
            { title: '$10,000', value: 10000 },
            { title: '$15,000', value: 15000 },
        ],
        limits: [
            { title: '$1,000,000', value: 1000000 },
            { title: '$2,000,000', value: 2000000 },
            { title: '$3,000,000', value: 3000000 },
            { title: '$4,000,000', value: 4000000 },
            { title: '$5,000,000', value: 5000000 },
        ],
    },
    epl: {
        retentions: [
            { title: '$10,000', value: 10000 },
            { title: '$25,000', value: 25000 },
            { title: '$50,000', value: 50000 },
            { title: '$75,000', value: 75000 },
            { title: '$100,000', value: 100000 },
            { title: '$150,000', value: 150000 },
            { title: '$250,000', value: 250000 },
        ],
        limits: [
            { title: '$1,000,000', value: 1000000 },
            { title: '$2,000,000', value: 2000000 },
            { title: '$3,000,000', value: 3000000 },
        ],
    },
};

const limitMax = 3000 * 1000; // $3M
const limitMaxCA = 2000 * 1000; // $2M in California

type LimitRetentionOptions = {
    ignoreStateSpecificRestrictions: boolean;
};
export const getLimitRetentionOptions = (
    type: string,
    previousLimit?: Money,
    state?: Nullable<State>,
    options: LimitRetentionOptions = {
        ignoreStateSpecificRestrictions: false,
    },
) => {
    let limits;

    const maxLimit =
        state && state === 'CA' && !options.ignoreStateSpecificRestrictions && type === 'epl'
            ? limitMaxCA
            : limitMax;
    const limitRetentionOptions = limitRetentionOptionsMap[type];

    if (previousLimit && Money.isGreaterThan(previousLimit, Money.tryFromFloat(maxLimit))) {
        limits = limitRetentionOptions.limits.filter((limit) =>
            Money.isLessThanOrEqual(Money.tryFromFloat(limit.value), previousLimit),
        );
    } else {
        limits = limitRetentionOptions.limits.filter((limit) =>
            Money.isLessThanOrEqual(Money.tryFromFloat(limit.value), Money.tryFromFloat(maxLimit)),
        );
    }

    const retentions = limitRetentionOptions.retentions;

    return {
        limits,
        retentions,
    };
};

export function getRestrictedLimitRetentionOptions(
    type: string,
    coverageRestriction?: CoverageRestriction,
    previousLimit?: Money,
    state?: Nullable<State>,
) {
    if (!coverageRestriction) {
        return getLimitRetentionOptions(type, previousLimit, state);
    }

    const options: LimitRetentionOptions = {
        ignoreStateSpecificRestrictions: false,
    };

    const isRestrictionSetByUW = coverageRestriction.areManualRestrictionsApplied;
    const isCalifornia = state === 'CA';
    const isLimitGreaterThanDefault = Money.isGreaterThan(
        coverageRestriction.maxLimit,
        Money.tryFromFloat(limitMaxCA),
    );
    if (isRestrictionSetByUW && isCalifornia && isLimitGreaterThanDefault) {
        options.ignoreStateSpecificRestrictions = true;
    }

    const limits = getLimitRetentionOptions(type, previousLimit, state, options).limits.filter(
        (limit) =>
            Money.isLessThanOrEqual(Money.tryFromFloat(limit.value), coverageRestriction.maxLimit),
    );
    const retentions = getLimitRetentionOptions(
        type,
        previousLimit,
        state,
        options,
    ).retentions.filter((retention) =>
        Money.isGreaterThanOrEqual(
            Money.tryFromFloat(retention.value),
            coverageRestriction.minRetention,
        ),
    );

    return {
        limits,
        retentions,
    };
}
