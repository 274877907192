import { Module } from '@embroker/shotwell/core/Module';
import { ContainerModule } from '@embroker/shotwell/core/di';
import { BundleQuoteRepository } from './repositories';
import { APIBundleQuoteRepository } from './repositories/APIBundleQuoteRepository';
import { FinalizeBundleQuote } from './useCases/FinalizeBundleQuote';
import { GetDocumentUrl } from './useCases/GetDocumentUrl';
import { GetLastBundleQuote } from './useCases/GetLastBundleQuote';
import { GetStaticDocumentUrl } from './useCases/GetStaticDocumentUrl';
import { PurchaseBundleQuote } from './useCases/PurchaseBundleQuote';
import { ReQuoteBundle } from './useCases/ReQuoteBundle';
import { RequestHigherLimit } from './useCases/RequestHigherLimit';
import { RequestReferredQuoteReview } from './useCases/RequestReferredQuoteReview';
import { SetApplicationStatus } from './useCases/SetApplicationStatus';
import { PublishBundleUserClickEvent } from './useCases/PublishBundleUserClickEvent';
import { GetRecommendedCoverageQuotes } from './useCases/GetRecommendedCoverageQuotes';
import { ConfirmRecommendedCoverageSelection } from './useCases/ConfirmRecommendedCoverageSelection';

export const BundleQuoteModule: Module = {
    container: new ContainerModule((bind) => {
        bind<BundleQuoteRepository>(BundleQuoteRepository)
            .to(APIBundleQuoteRepository)
            .inSingletonScope();
        bind<GetLastBundleQuote>(GetLastBundleQuote.type).to(GetLastBundleQuote).inSingletonScope();
        bind<RequestReferredQuoteReview>(RequestReferredQuoteReview.type)
            .to(RequestReferredQuoteReview)
            .inSingletonScope();
        bind<ReQuoteBundle>(ReQuoteBundle.type).to(ReQuoteBundle).inSingletonScope();
        bind<GetRecommendedCoverageQuotes>(GetRecommendedCoverageQuotes.type)
            .to(GetRecommendedCoverageQuotes)
            .inSingletonScope();
        bind<ConfirmRecommendedCoverageSelection>(ConfirmRecommendedCoverageSelection.type)
            .to(ConfirmRecommendedCoverageSelection)
            .inSingletonScope();
        bind<PurchaseBundleQuote>(PurchaseBundleQuote.type).to(PurchaseBundleQuote);
        bind<GetDocumentUrl>(GetDocumentUrl.type).to(GetDocumentUrl).inSingletonScope();
        bind<GetStaticDocumentUrl>(GetStaticDocumentUrl.type)
            .to(GetStaticDocumentUrl)
            .inSingletonScope();
        bind<RequestHigherLimit>(RequestHigherLimit.type).to(RequestHigherLimit).inSingletonScope();
        bind<FinalizeBundleQuote>(FinalizeBundleQuote.type)
            .to(FinalizeBundleQuote)
            .inSingletonScope();
        bind<SetApplicationStatus>(SetApplicationStatus.type)
            .to(SetApplicationStatus)
            .inSingletonScope();
        bind<PublishBundleUserClickEvent>(PublishBundleUserClickEvent.type)
            .to(PublishBundleUserClickEvent)
            .inSingletonScope();
    }),
};
