import { Money, USD } from '@embroker/shotwell/core/types/Money';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { CNAWarnings } from './CNAWarnings';

export type GeneralLiabilityLimit = '500000/1000000' | '1000000/2000000' | '2000000/4000000';
export type StopGapLiabilityLimit =
    | '0/0/0'
    | '300000/300000/600000'
    | '500000/500000/1000000'
    | '1000000/1000000/2000000';

export interface CNAQuoteOptions {
    readonly effectiveDate: Date;
    readonly buildingLimit: Money;
    readonly contentsLimit: Money;
    readonly tenantsAndBettermentsLimit: Money;
    readonly propertyDeductible: Money;
    readonly windHailDeductible: Money;
    readonly generalLiabilityLimit: GeneralLiabilityLimit;
    readonly hiredNonOwnedAutoLimit: Money;
    readonly stopGapLiabilityLimit: StopGapLiabilityLimit;
    readonly enhanced: boolean;
    readonly cnaWarnings: CNAWarnings;
}

export const CNAQuoteOptions = {
    ...defineValidator<CNAQuoteOptions>({
        effectiveDate: Joi.date().required(),
        buildingLimit: Money.schema.required(),
        contentsLimit: Money.schema.required(),
        tenantsAndBettermentsLimit: Money.schema.required(),
        propertyDeductible: Money.schema
            .valid(
                USD(25000),
                USD(50000),
                USD(100000),
                USD(250000),
                USD(500000),
                USD(1000000),
                USD(2500000),
            )
            .required(),
        windHailDeductible: Money.schema
            .valid(
                USD(50000),
                USD(100000),
                USD(250000),
                USD(500000),
                USD(1000000),
                USD(1500000),
                USD(2000000),
                USD(2500000),
            )
            .required(),
        generalLiabilityLimit: Joi.string()
            .valid('500000/1000000', '1000000/2000000', '2000000/4000000')
            .required(),
        hiredNonOwnedAutoLimit: Money.schema
            .valid(USD(0), USD(30000000), USD(50000000), USD(100000000))
            .required(),
        stopGapLiabilityLimit: Joi.string()
            .valid(
                '0/0/0',
                '300000/300000/600000',
                '500000/500000/1000000',
                '1000000/1000000/2000000',
            )
            .required(),
        enhanced: Joi.boolean().required(),
        cnaWarnings: CNAWarnings.schema.required(),
    }),
    create(cnaQuoteOptions: CNAQuoteOptions) {
        return CNAQuoteOptions.validate(cnaQuoteOptions);
    },
};
