import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import {
    BillingInfoRepository,
    BillingInfoUpdateInput,
} from '../repositories/BillingInfoRepository';
import { UUID } from '@embroker/shotwell/core/types/UUID';

export interface UpdateBillingInfoInput {
    readonly billingEmailAddress: string;
}

export interface UpdateBillingInfoRequest {
    readonly billingEmailAddress: string;
    readonly organizationId: UUID;
}

export type UpdateBillingInfoResponse = {
    id: UUID;
};

export interface UpdateBillingInfo extends UseCase {
    execute(
        request: UpdateBillingInfoRequest,
    ): AsyncResult<UpdateBillingInfoResponse, InvalidArgument | OperationFailed>;
}

@injectable()
class UpdateBillingInfoUseCase extends UseCase {
    public static type = Symbol('Payments/UpdateBillingInfo');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BillingInfoRepository) private billingInfoRepository: BillingInfoRepository,
    ) {
        super(eventBus);
    }
    public async execute(
        input: BillingInfoUpdateInput,
    ): AsyncResult<UpdateBillingInfoResponse, InvalidArgument | OperationFailed> {
        return this.billingInfoRepository.updateBillingInfo({
            billingEmailAddress: input.billingEmailAddress,
            organizationId: input.organizationId,
        });
    }
}

export const UpdateBillingInfo: UseCaseClass<UpdateBillingInfo> = UpdateBillingInfoUseCase;
