import { defineEntityValidator, entity, Entity } from '@embroker/shotwell/core/entity/Entity';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { SKU } from '../../analytics/types/SKU';
import { BundleDetails } from '../types/BundleDetails';
import {
    AppTypeCode,
    AppTypeCodeList,
    AppTypeCodeListEmbrokerExcess,
    AppTypeCodeListESP,
    InsuranceApplicationCreationTypeCode,
    InsuranceApplicationCreationTypeCodeList,
    InsuranceApplicationStatusCode,
    InsuranceApplicationStatusCodeList,
    QuotesReady,
    BundleSubmitted,
    QuotingEngine,
    QuotingEngineList,
    Referred,
    Purchased,
    SupplementalInProgress,
    QuestionnaireInProgress,
    ShoppingCoverage,
    ShoppingCoverageCodeList,
} from '../types/enums';
import { IneligibilityReasons } from '../types/IneligibilityReasons';
import { Quote } from '../types/Quote';
import { SignaturePacketDocument } from '../types/SignaturePacketDocument';
import { FormEventName } from '../useCases/PublishFormEvents';
import { URI } from '@embroker/shotwell/core/types/URI';
import { getApplicationFlags } from '@app/shopping/view/components/applicationFlags';
import { resolveQuotesReadyLink } from '@app/summary/view/components/ApplicationAction';

/**
 * We don't really ever create an Application entity in the app.
 * Application Entity is being created on the Back-end side, which is why we are introducing
 * this custom event here in order to publish application creation event.
 */

export interface ApplicationAccessStartQuote extends DomainEvent {
    readonly origin: 'Application';
    readonly name: 'AccessStartQuote';
}

export interface ApplicationPurchaseIntent extends DomainEvent {
    readonly origin: 'Application';
    readonly name: 'PurchaseIntent';
    sku?: SKU;
}

export interface ApplicationSubmitClearanceFailed extends DomainEvent {
    readonly origin: 'Application';
    readonly name: 'SubmitClearanceFailed';
}

export interface ApplicationEarlyClearanceFailed extends DomainEvent {
    readonly origin: 'Application';
    readonly name: 'EarlyClearanceFailed';
}
export interface ApplicationNotEligible extends DomainEvent {
    readonly origin: 'Application';
    readonly name: 'NotEligible';
    applicationId: UUID;
    isRenewal: boolean;
    sku?: SKU;
}
export interface ApplicationForm extends DomainEvent {
    readonly origin: 'Application';
    readonly name: FormEventName;
    readonly formStage: string;
    readonly formName: string;
    readonly applicationId: Nullable<UUID>;
    readonly isRenewal: boolean;
    readonly sku?: SKU;
}

export interface ApplicationCreated extends DomainEvent {
    readonly origin: 'Application';
    readonly name: 'ApplicationCreated';
    id: UUID;
    readonly isRenewal: boolean;
    readonly sku?: SKU;
}

export interface ApplicationQuestionnaireUpdated extends DomainEvent {
    readonly origin: 'Application';
    readonly name: 'QuestionnaireUpdated';
}

export interface ApplicationOFACRejected extends DomainEvent {
    readonly origin: 'Application';
    readonly name: 'OFACRejected';
}

export interface ApplicationSubmitted extends DomainEvent {
    readonly origin: 'Application';
    readonly name: 'Submitted';
    applicationId: UUID;
    isRenewal: boolean;
    readonly sku?: SKU;
}

export interface ApplicationSubmittedForReview extends DomainEvent {
    readonly origin: 'Application';
    readonly name: 'SubmittedForReview';
    applicationId: UUID;
    readonly sku?: SKU;
    readonly isRenewal: boolean;
}

export interface ApplicationDeleted extends DomainEvent {
    readonly origin: 'Application';
    readonly name: 'Deleted';
}

export interface ApplicationQuoteCreated extends DomainEvent {
    readonly origin: 'Application';
    readonly name: 'QuoteCreated';
    totalPremium: Nullable<Money>;
    applicationId: UUID;
    isRenewal: boolean;
    isStreamline?: boolean;
    sku?: SKU;
    isPartiallyQuoted?: boolean;
}

export interface ApplicationReferred extends DomainEvent {
    readonly origin: 'Application';
    readonly name: 'Referred';
    totalPremium: Nullable<Money>;
    applicationId: UUID;
    isRenewal: boolean;
    isStreamline?: boolean;
    sku?: SKU;
}

export interface OnQuotePremiumInput {
    totalPremium: Nullable<Money>;
    applicationId: UUID;
    isRenewal: boolean;
    sku?: SKU;
    isPartiallyQuoted?: boolean;
}

export interface OnSubmitApplicationInput {
    applicationId: UUID;
    sku?: SKU;
    isRenewal: boolean;
}

export interface OnSubmitApplicationForReviewInput {
    applicationId: UUID;
    sku?: SKU;
    isRenewal: boolean;
}

export interface Application extends Entity {
    readonly appType: AppTypeCode;
    readonly shoppingCoverageList: Array<ShoppingCoverage>;
    readonly quotableShoppingCoverageList?: Array<ShoppingCoverage>;
    readonly startedDate: Nullable<Date>;
    readonly submittedDate: Nullable<Date>;
    readonly status: InsuranceApplicationStatusCode;
    readonly creationType: InsuranceApplicationCreationTypeCode;
    readonly isPristine: boolean;
    readonly hasQuotes: boolean;
    readonly hasSocius?: boolean;
    readonly quotingEngine: Nullable<QuotingEngine>;
    readonly previousApplicationsQuotingEngineList: Array<QuotingEngine>;
    readonly renewedPolicyIdList: Array<UUID>;
    readonly questionnaireData: Nullable<string>;
    readonly intakeTaskId?: Nullable<UUID>;
    readonly submissionTaskId: Nullable<UUID>;
    readonly redirectToApplicationList: Nullable<Array<UUID>>;
    readonly isOFACRejected: Nullable<boolean>;
    readonly isCNAQuoteReferred: Nullable<boolean>;
    readonly signaturePacketDocuments: Array<SignaturePacketDocument>;
    readonly ineligibilityReasons: Nullable<IneligibilityReasons>;
    readonly isDeletable: Nullable<boolean>;
    readonly policyExpirationDate: Nullable<Date>;
    readonly lastQuote: Nullable<Quote>;
    readonly daysToQuoteExpiration: Nullable<number>;
    readonly isSubmittedExternally?: boolean;
    readonly supplementalQuestionnaireData?: string;
    readonly bundleDetails?: BundleDetails;
    readonly hasClientReviewRequest?: boolean;
    readonly isStreamline: boolean;

    /**
     * Set expiration date for underlying policy of renewal application.
     * @param expirationDate Represents expiration date of policy that is renewable.
     */
    setPolicyExpirationDate(expirationDate: Date): void;

    /**
     * Update application questionnaire data and creates ApplicationQuestionnaireUpdated event.
     * @param newData Represents new questionnaire data.
     */
    updateQuestionnaireData(newData: string): void;

    /**
     * Test if the application is a renewal
     */
    isRenewal(): boolean;

    /**
     * Create OFAC rejected event
     */
    onOFACRejected(): void;

    /**
     * Create SubmitClearanceFailed event
     */
    onSubmitClearanceFailed(): void;

    /**
     * Create application submitted event
     */
    onSubmitted(onSubmitApplicationInput: OnSubmitApplicationInput): void;

    /**
     * Create application submitted for review event
     */
    onSubmittedForReview(input: OnSubmitApplicationForReviewInput): void;

    /**
     * Create application deleted event
     */
    onDeleted(): void;

    /**
     * Create quote premium event when quote is ready
     * @param onQuotePremiumInput premium amount
     * Product name is usually calculated based on quoting engine
     */
    onQuotePremium(onQuotePremiumInput: OnQuotePremiumInput): void;
    /**
     * Get next user-navigatable URL for application given its status
     */
    getUrl(): Nullable<URI>;
}

export const Application = entity<Application>({
    validator: defineEntityValidator<Application>({
        appType: Joi.string().valid(...AppTypeCodeList),
        shoppingCoverageList: Joi.array()
            .required()
            .allow(null)
            .items(...ShoppingCoverageCodeList),
        quotableShoppingCoverageList: Joi.array()
            .optional()
            .items(...ShoppingCoverageCodeList),
        startedDate: Joi.date().allow(null),
        submittedDate: Joi.date().allow(null),
        status: Joi.string().valid(...InsuranceApplicationStatusCodeList),
        creationType: Joi.string().valid(...InsuranceApplicationCreationTypeCodeList),
        isPristine: Joi.boolean(),
        previousApplicationsQuotingEngineList: Joi.array().items(
            Joi.string().valid(...QuotingEngineList),
        ),
        quotingEngine: Joi.string().valid(...QuotingEngineList, null),
        questionnaireData: Joi.string().allow(null),
        renewedPolicyIdList: Joi.array().required().items(UUID.schema.optional()),
        hasQuotes: Joi.boolean().required(),
        hasSocius: Joi.boolean().optional(),
        intakeTaskId: UUID.schema.optional().allow(null),
        submissionTaskId: UUID.schema.allow(null),
        redirectToApplicationList: Joi.array().required().allow(null).items(UUID.schema.required()),
        isOFACRejected: Joi.boolean().required().allow(null),
        isCNAQuoteReferred: Joi.boolean().required().allow(null),
        signaturePacketDocuments: Joi.array().items(SignaturePacketDocument.schema),
        ineligibilityReasons: IneligibilityReasons.schema.allow(null),
        isDeletable: Joi.boolean().allow(null),
        policyExpirationDate: Joi.date().allow(null),
        lastQuote: Quote.schema.allow(null),
        daysToQuoteExpiration: Joi.number().optional().allow(null),
        isSubmittedExternally: Joi.boolean().optional().allow(null),
        supplementalQuestionnaireData: Joi.string().optional().allow(null),
        bundleDetails: BundleDetails.schema.optional(),
        hasClientReviewRequest: Joi.boolean().optional().allow(null),
        isStreamline: Joi.boolean().required(),
    }),
    setPolicyExpirationDate(expirationDate: Date): void {
        this.props.policyExpirationDate = expirationDate;
    },
    updateQuestionnaireData(newData: string): void {
        this.props.questionnaireData = newData;
        this.createEvent<ApplicationQuestionnaireUpdated>('QuestionnaireUpdated');
    },
    isRenewal() {
        return this.renewedPolicyIdList.length > 0;
    },
    onOFACRejected() {
        this.createEvent<ApplicationOFACRejected>('OFACRejected');
    },
    onSubmitClearanceFailed() {
        this.createEvent<ApplicationSubmitClearanceFailed>('SubmitClearanceFailed');
    },
    onSubmitted(onSubmitApplicationInput: OnSubmitApplicationInput) {
        this.createEvent<ApplicationSubmitted>('Submitted', {
            applicationId: onSubmitApplicationInput.applicationId,
            sku: onSubmitApplicationInput.sku,
            isRenewal: onSubmitApplicationInput.isRenewal,
        });
    },
    onQuotePremium(onQuotePremiumInput: OnQuotePremiumInput) {
        if (this.quotingEngine === null) {
            return;
        } else if (this.status === QuotesReady) {
            this.createEvent<ApplicationQuoteCreated>('QuoteCreated', {
                applicationId: onQuotePremiumInput.applicationId,
                totalPremium: onQuotePremiumInput.totalPremium,
                sku: onQuotePremiumInput.sku,
                isRenewal: onQuotePremiumInput.isRenewal,
                isPartiallyQuoted: onQuotePremiumInput.isPartiallyQuoted,
                isStreamline: this.isStreamline,
            });
        } else if (this.status === Referred) {
            this.createEvent<ApplicationReferred>('Referred', {
                applicationId: onQuotePremiumInput.applicationId,
                totalPremium: onQuotePremiumInput.totalPremium,
                sku: onQuotePremiumInput.sku,
                isRenewal: onQuotePremiumInput.isRenewal,
                isStreamline: this.isStreamline,
            });
        }
    },
    onSubmittedForReview(input: OnSubmitApplicationForReviewInput) {
        this.createEvent<ApplicationSubmittedForReview>('SubmittedForReview', {
            applicationId: input.applicationId,
            sku: input.sku,
            isRenewal: input.isRenewal,
        });
    },
    onDeleted() {
        this.createEvent<ApplicationDeleted>('Deleted');
    },
    getUrl() {
        // Stubbing all flags to true since we're simply generating URLs; we're not making display/access decisions here.
        const applicationFlags = getApplicationFlags(this, {
            canViewCnaBopQuotes: true,
            canViewLplQuotes: true,
            canViewPCoMLQuotes: true,
            canViewCrimeQuotes: true,
            canViewCyberQuotes: true,
            canViewExcessQuotes: true,
        });

        switch (this.status) {
            case QuestionnaireInProgress: {
                let baseURL = '/shopping/application';

                if (this.appType === AppTypeCodeListEmbrokerExcess && this.isPristine) {
                    baseURL = '/shopping/excess-before-you-begin';
                }

                return URI.build(baseURL, {
                    applicationId: this.id,
                });
            }
            case Purchased: {
                return URI.build('/policies');
            }
            case BundleSubmitted:
            case QuotesReady: {
                return resolveQuotesReadyLink(this.id, applicationFlags) || null;
            }
            case SupplementalInProgress: {
                if (this.appType === AppTypeCodeListESP) {
                    return URI.build('/shopping/application/supplemental-questionnaire/esp', {
                        applicationId: this.id,
                    });
                }

                return null;
            }
            case Referred: {
                if (applicationFlags.showWCGAQuoteLink) {
                    return URI.build('/shopping/application/quote/wcga/referred-estimate/', {
                        applicationId: this.id,
                    });
                } else if (
                    (applicationFlags.showWCGAQuoteLink && this.hasClientReviewRequest) ??
                    false
                ) {
                    return URI.build(
                        '/shopping/application/quote/wcga/referred-estimate-submitted/',
                        {
                            applicationId: this.id,
                        },
                    );
                } else if (applicationFlags.showLplReferredEstimateLink) {
                    return URI.build('/shopping/application/quote/lpl/referred-estimate/', {
                        applicationId: this.id,
                    });
                }

                return null;
            }
        }

        return null;
    },
});
