import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { isErr, Success, SuccessResult } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { SelfServingCertificateCoverageList } from '../../certificates/types/SelfServingCertificateCoverage';
import {
    CalculateSKUFromShareablePoliciesRequest,
    ShareablePolicy,
    SKURepository,
} from '../repositories/SKURepository';
import { SKU } from '../types/SKU';

export interface CalculateSKUFromShareablePolicies extends UseCase {
    execute(
        selectedCoverages: SelfServingCertificateCoverageList,
    ): Promise<SuccessResult<SKU | undefined>>;
}

@injectable()
class CalculateSKUFromShareablePoliciesUseCase
    extends UseCase
    implements CalculateSKUFromShareablePolicies
{
    public static type = Symbol('Analytics/CalculateSKUFromShareablePolicies');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(SKURepository) private skuRepository: SKURepository,
        @inject(Log) private logger: Logger,
    ) {
        super(eventBus);
    }

    public async execute(
        selectedCoverages: SelfServingCertificateCoverageList,
    ): Promise<SuccessResult<SKU | undefined>> {
        const repoInput = createRepoInput(selectedCoverages);
        const calculateSKUResult = await this.skuRepository.calculateSKUFromShareablePolicies(
            repoInput,
        );

        if (isErr(calculateSKUResult)) {
            this.logger.warn(
                `Failed to calculate sku for selected shareable policies`,
                calculateSKUResult,
            );
            return Success(undefined);
        }

        return calculateSKUResult;
    }
}

function createRepoInput(
    selectedCoverages: SelfServingCertificateCoverageList,
): CalculateSKUFromShareablePoliciesRequest {
    const shareablePolicyList: ShareablePolicy[] = [];

    selectedCoverages.forEach((coverage) => {
        const existingPolicy = shareablePolicyList.find(
            (policy) => policy.id === coverage.coverageInfo.policyId,
        );
        if (existingPolicy) {
            existingPolicy.structuralComponentList.push(
                coverage.coverageInfo.structuralComponentTypeCode,
            );
        } else {
            if (coverage.coverageInfo.policyId) {
                const newPolicy = {
                    id: coverage.coverageInfo.policyId,
                    structuralComponentList: [coverage.coverageInfo.structuralComponentTypeCode],
                };
                shareablePolicyList.push(newPolicy);
            }
        }
    });

    return { shareablePolicyList };
}

export const CalculateSKUFromShareablePolicies: UseCaseClass<CalculateSKUFromShareablePolicies> =
    CalculateSKUFromShareablePoliciesUseCase;
