import { inject } from '@embroker/shotwell/core/di';
import { Aborted, InvalidArgument, OperationFailed, Timeout } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { DocumentRepository } from '../../documents/repositories/DocumentRepository';
import { TasksRepository } from '../../tasks/repositories';
import { ApplicationRepository } from '../repositories/ApplicationRepository';

/**
 * Request data for CheckApplicationPrintStatus use case
 */
export interface CheckApplicationPrintStatusRequest {
    jobId: UUID;
    maxPollingRetries?: number;
    pollingRetryIntervalInMilliseconds: number;
    abortSignal: AbortSignal;
}

/**
 * Response data for CheckApplicationPrintStatus use case
 */
export interface CheckApplicationPrintStatusResponse {
    link?: URI;
}

/**
 * CheckApplicationPrintStatus use case is used to check for the results of the Print job
 */
export interface CheckApplicationPrintStatus extends UseCase {
    execute(
        request: CheckApplicationPrintStatusRequest,
    ): AsyncResult<
        CheckApplicationPrintStatusResponse,
        OperationFailed | InvalidArgument | Aborted | Timeout
    >;
}

class CheckApplicationPrintStatusUseCase extends UseCase implements CheckApplicationPrintStatus {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Shopping/CheckApplicationPrintStatus');

    /**
     * Constructor for CheckApplicationPrintStatus use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
        @inject(DocumentRepository) private documentRepository: DocumentRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes CheckApplicationPrintStatus use case
     * Input is of CheckApplicationPrintStatusRequest type
     * @returns InvalidArgument if there is no such job running
     * @returns OperationFailed if API error or task error occurred
     */
    public async execute({
        jobId,
        maxPollingRetries = 120,
        pollingRetryIntervalInMilliseconds,
        abortSignal,
    }: CheckApplicationPrintStatusRequest): AsyncResult<
        CheckApplicationPrintStatusResponse,
        OperationFailed | InvalidArgument | Aborted | Timeout
    > {
        const pollForPrintTaskStatusResult = await this.tasksRepository.pollForPrintTaskStatus(
            jobId,
            abortSignal,
            maxPollingRetries,
            pollingRetryIntervalInMilliseconds,
        );
        if (isErr(pollForPrintTaskStatusResult)) {
            return pollForPrintTaskStatusResult;
        }

        const documentUrlResponse = await this.documentRepository.getDocumentUrl(
            pollForPrintTaskStatusResult.value,
        );
        if (isErr(documentUrlResponse)) {
            return documentUrlResponse;
        }

        return Success({ link: documentUrlResponse.value as URI });
    }
}

export const CheckApplicationPrintStatus: UseCaseClass<CheckApplicationPrintStatus> =
    CheckApplicationPrintStatusUseCase;
