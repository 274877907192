import { isErr } from '@embroker/shotwell/core/types/Result';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Spinner } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { FileClaim } from '../../useCases/FileClaim';
import { ClaimsLandingPage } from './ClaimsLandingPage';
import { FileClaimList } from './FileClaimList';

export const FileClaimTab = () => {
    const { result } = useUseCase(FileClaim);

    if (result !== undefined && isErr(result)) {
        return <ErrorPage errors={result.errors} />;
    }

    if (result === undefined || result.value.claimContactInfoList === undefined) {
        return <Spinner />;
    }

    return (
        <ClaimsLandingPage>
            <FileClaimList claimContactInfoList={result.value.claimContactInfoList} />
        </ClaimsLandingPage>
    );
};
