import { URI } from '@embroker/shotwell/core/types/URI';
import {
    Modal,
    ModalActions,
    ModalState,
    StackLayout,
    ColumnLayout,
    Text,
    Button,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { DocumentType } from '../../types/Document';

interface DocumentModalProps {
    readonly modal: ModalActions & ModalState;
    readonly fileUrl?: URI;
    readonly documentType?: DocumentType;
}

export function DocumentModal({ modal, fileUrl, documentType }: DocumentModalProps) {
    return (
        <Modal {...modal} size="small" dismissable>
            <StackLayout gap="32">
                <StackLayout gap="24">
                    <Text style="heading 3" data-e2e="document-ready">
                        {`Your ${
                            documentType ? DocumentType.toDisplayName(documentType) : 'document'
                        } is ready to download.`}
                    </Text>
                    <Text data-e2e="download_document-by-clicking">
                        {' '}
                        Download the document by clicking on the button.{' '}
                    </Text>
                </StackLayout>
                <ColumnLayout gap="32">
                    <Button as="a" href={fileUrl} target="_blank" rel="noopener">
                        Download{' '}
                        {documentType ? DocumentType.toDisplayName(documentType) : 'document'}
                    </Button>
                    <TextButton onClick={modal.hide} data-e2e="back-to-quote">
                        Back to quote
                    </TextButton>
                </ColumnLayout>
            </StackLayout>
        </Modal>
    );
}
