import {
    connect,
    FormElement,
    FormStateMachine,
    PageElement,
    PageStateMachine,
} from '@embroker/service-app-engine';
import React from 'react';

export interface FormEngineSubscriberProps {
    isValid?(): boolean;
    id?: string;
    pages?: PageElement[];
    visible?: boolean;
    title?: string;
    active?: boolean;
    pageList?: PageElement[];
}

export class FormEngineSubscriber extends React.Component<FormEngineSubscriberProps> {
    static connect(machine: PageStateMachine | FormStateMachine) {
        return connect<PageElement | FormElement>(this)
            .with(machine)
            .map((m) => {
                const {
                    id,
                    title,
                    pages,
                    visible,
                    descendantVisible,
                    enabled,
                    parentEnabled,
                    hiddenFromNavigation,
                    pageList,
                } = m.state;
                const result: FormEngineSubscriberProps = {
                    id,
                    title,
                    pages,
                    pageList,
                    visible: enabled && parentEnabled && hiddenFromNavigation !== true,
                    active: visible || descendantVisible,
                    isValid: () => m.isValid(),
                };
                return result;
            });
    }
}
