import { Money } from '@embroker/shotwell/core/types/Money';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import {
    CenterLayout,
    ColumnLayout,
    Text,
    LayerCard,
    StackLayout,
    layerCardBackgrounds,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { CarrierLayer } from '../../types/CarrierLayer';
import { ordinal } from './TowerStructure';

export interface TowerCardProps {
    carrierLayer: CarrierLayer;
}

export const TowerCard = function (props: TowerCardProps) {
    const background = layerCardBackgrounds[1];
    return (
        <LayerCard background={background} key={props.carrierLayer.layer_number}>
            <CenterLayout as={ColumnLayout} gap="none">
                <StackLayout gap="none">
                    <Text style="heading 5">
                        {`${ordinal(props.carrierLayer.layer_number)} Excess -
                         ${props.carrierLayer.carrier.name}`}
                    </Text>

                    <MoneyDisplay
                        value={Money.tryFromFloat(props.carrierLayer.policy_aggregate)}
                        fractionDigits={0}
                    />

                    <Text>
                        Premium:{' '}
                        <MoneyDisplay
                            value={Money.tryFromFloat(props.carrierLayer.premium)}
                            fractionDigits={0}
                        />
                    </Text>
                </StackLayout>
            </CenterLayout>
        </LayerCard>
    );
};
