import {
    Button,
    Modal,
    ModalActions,
    ModalState,
    StackLayout,
    ColumnLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { useNavigation } from '../../../view/hooks/useNavigation';

interface GapBetweenPoliciesModalInput {
    readonly modal: ModalActions & ModalState;
}

export function GapBetweenPoliciesModal({ modal }: GapBetweenPoliciesModalInput) {
    const { navigate } = useNavigation();
    return (
        <Modal {...modal} size="small" dismissable={false}>
            <StackLayout gap="32">
                <StackLayout gap="24">
                    <Text style="heading 3">Please review your info.</Text>
                    <Text>
                        Since there is a gap between current policy end (expiration) date and the
                        beginning of the new policy coverage, please contact Embroker.
                    </Text>
                    <Text>Or contact us via live chat or call us at 844.436.2765</Text>
                </StackLayout>
                <ColumnLayout gap="32">
                    <Button onClick={() => navigate('/summary')}>Back to dashboard</Button>
                    <TextButton href="#chat-now">Open live chat</TextButton>
                </ColumnLayout>
            </StackLayout>
        </Modal>
    );
}
