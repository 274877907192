import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { State as LocationState } from '@embroker/shotwell/core/types/StateList';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Loader, useModal, WizardLayout } from '@embroker/ui-toolkit/v2';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FileUpload } from '../../../../documents/view/FileUpload';
import { GetDocumentURL } from '../../../../policy/useCases/GetDocumentURL';
import { AppContext } from '../../../../view/AppContext';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { CertificateEntityRole } from '../../../types/CertificateEntityRole';
import { CertificateProducer } from '../../../types/CertificateProducer';
import {
    SelfServingCertificateCoverage,
    SelfServingCertificateCoverageList,
} from '../../../types/SelfServingCertificateCoverage';
import {
    CertificateIssueSource,
    CreateSelfServingCertificate,
    CreateSelfServingCertificateRequest,
} from '../../../useCases/CreateSelfServingCertificate';
import {
    PreviewSelfServingCertificate,
    PreviewSelfServingCertificateRequest,
} from '../../../useCases/PreviewSelfServingCertificate';
import {
    SendEnterSelfServingFlow,
    SendEnterSelfServingFlowRequest,
} from '../../../useCases/SendEnterSelfSevingFlowEvent';
import { BusinessNameStep, BusinessNameStepData } from './BusinessNameStep';
import { CustomizeCertificateStep, CustomizeCertificateStepData } from './CustomizeCertificateStep';
import { EmailAddressStep, EmailAddressStepData } from './EmailAddressStep';
import { EmailToThemStep, EmailToThemStepData } from './EmailToThemStep';
import { FinalPage } from './FinalPage';
import { IssueCertificateFailModal } from './IssueCertificateFailModal';
import { IssueCertificateStep } from './IssueCertificateStep';
import {
    IssueCertificateProvisionsStep,
    IssueCertificateProvisionsStepData,
} from './IssueCetrificateProvisionsStep';
import { IssueOptionsStep, IssueOptionsStepData } from './IssueOptionsStep';
import { FormattedLocationStepData, LocationStep } from './LocationStep';
import { SelectCoveragesStep, SelectCoveragesStepData } from './SelectCoveragesStep';
import { SubmitRequestFinalPage } from './SubmitRequestFinal';
import { UploadFileStep, UploadFileStepItemData, UploadSubmitRequestData } from './UploadFileStep';
import { WelcomePage } from './WelcomePage';

const State = {
    WELCOME_SCREEN_NAME: 'welcome',
    ISSUE_OPTION_SCREEN_NAME: 'issueOption',
    BUSINESS_NAME_SCREEN_NAME: 'business',
    EMAIL_TO_THEM_SCREEN_NAME: 'emailThem',
    EMAIL_ADDRESS_SCREEN_NAME: 'email',
    LOCATION_SCREEN_NAME: 'location',
    CUSTOMIZE_CERTIFICATE_SCREEN_NAME: 'customize',
    SELECT_COVERAGE_SCREEN_NAME: 'coverage',
    ISSUE_CERTIFICATE_SCREEN_NAME: 'issueCertificate',
    ISSUE_CERTIFICATE_PROVISIONS_SCREEN_NAME: 'issueCertificateProvisions',
    UPLOAD_FILE_SCREEN_NAME: 'uploadFile',
    UPLOAD_FILE_PROVISIONS_SCREEN_NAME: 'uploadFileProvisions',
    FINAL_SCREEN_NAME: 'final',
    SUBMIT_REQUEST_FINAL_SCREEN_NAME: 'submitRequestFinal',
};

const wizardStateMachine = {
    initial: State.WELCOME_SCREEN_NAME,
    states: {
        [State.WELCOME_SCREEN_NAME]: {
            on: {
                NEXT: State.ISSUE_OPTION_SCREEN_NAME,
            },
        },
        [State.ISSUE_OPTION_SCREEN_NAME]: {
            on: {
                MYSELF_NEXT: State.FINAL_SCREEN_NAME,
                NEXT: State.BUSINESS_NAME_SCREEN_NAME,
                BACK: State.WELCOME_SCREEN_NAME,
            },
        },
        [State.BUSINESS_NAME_SCREEN_NAME]: {
            on: {
                NEXT: State.EMAIL_TO_THEM_SCREEN_NAME,
                BACK: State.ISSUE_OPTION_SCREEN_NAME,
            },
        },
        [State.EMAIL_TO_THEM_SCREEN_NAME]: {
            on: {
                DIRECTLY_NEXT: State.EMAIL_ADDRESS_SCREEN_NAME,
                NEXT: State.LOCATION_SCREEN_NAME,
                BACK: State.BUSINESS_NAME_SCREEN_NAME,
            },
        },
        [State.EMAIL_ADDRESS_SCREEN_NAME]: {
            on: {
                NEXT: State.LOCATION_SCREEN_NAME,
                BACK: State.EMAIL_TO_THEM_SCREEN_NAME,
            },
        },
        [State.LOCATION_SCREEN_NAME]: {
            on: {
                NEXT: State.CUSTOMIZE_CERTIFICATE_SCREEN_NAME,
                BACK: State.EMAIL_TO_THEM_SCREEN_NAME,
            },
        },
        [State.CUSTOMIZE_CERTIFICATE_SCREEN_NAME]: {
            on: {
                CUSTOMIZE_NEXT: State.SELECT_COVERAGE_SCREEN_NAME,
                NEXT: State.FINAL_SCREEN_NAME,
                BACK: State.LOCATION_SCREEN_NAME,
            },
        },
        [State.SELECT_COVERAGE_SCREEN_NAME]: {
            on: {
                PROVISIONS_NEXT: State.ISSUE_CERTIFICATE_PROVISIONS_SCREEN_NAME,
                NEXT: State.ISSUE_CERTIFICATE_SCREEN_NAME,
                BACK: State.CUSTOMIZE_CERTIFICATE_SCREEN_NAME,
            },
        },
        [State.ISSUE_CERTIFICATE_SCREEN_NAME]: {
            on: {
                UPLOAD_NEXT: State.UPLOAD_FILE_SCREEN_NAME,
                NEXT: State.FINAL_SCREEN_NAME,
                BACK: State.SELECT_COVERAGE_SCREEN_NAME,
            },
        },
        [State.ISSUE_CERTIFICATE_PROVISIONS_SCREEN_NAME]: {
            on: {
                UPLOAD_NEXT: State.UPLOAD_FILE_PROVISIONS_SCREEN_NAME,
                NEXT: State.FINAL_SCREEN_NAME,
                BACK: State.SELECT_COVERAGE_SCREEN_NAME,
            },
        },
        [State.UPLOAD_FILE_PROVISIONS_SCREEN_NAME]: {
            on: {
                BACK: State.ISSUE_CERTIFICATE_PROVISIONS_SCREEN_NAME,
                NEXT: State.SUBMIT_REQUEST_FINAL_SCREEN_NAME,
            },
        },
        [State.UPLOAD_FILE_SCREEN_NAME]: {
            on: {
                BACK: State.ISSUE_CERTIFICATE_SCREEN_NAME,
                NEXT: State.SUBMIT_REQUEST_FINAL_SCREEN_NAME,
            },
        },
        [State.FINAL_SCREEN_NAME]: {
            on: {
                ANOTHER: State.ISSUE_OPTION_SCREEN_NAME,
            },
        },
        [State.SUBMIT_REQUEST_FINAL_SCREEN_NAME]: {
            on: {
                ANOTHER: State.ISSUE_OPTION_SCREEN_NAME,
            },
        },
    },
};

interface WizardData {
    businessNameData?: BusinessNameStepData;
    issueOptionsData?: IssueOptionsStepData;
    emailToThemData?: EmailToThemStepData;
    emailAddressData?: EmailAddressStepData;
    locationData?: FormattedLocationStepData;
    customizeCertificateData?: CustomizeCertificateStepData;
    issueCertificateProvisionsStepData?: IssueCertificateProvisionsStepData;
    selectCoveragesData: SelectCoveragesStepData;
    certificateId?: UUID;
}

interface AdditionalCreateSelfServingCertificateData {
    businessName?: string;
    emailAddress?: EmailAddress;
    address1?: string;
    address2?: string;
    city?: string;
    state?: LocationState;
    zip?: ZipCode;
    referenceNumber?: string;
    isAdditionalInsuredDisabled?: boolean;
    isWaiverOfSubrogationDisabled?: boolean;
    isPrimaryNonContributoryDisabled?: boolean;
}

interface SelfServingFlowProps {
    coverageMap: Map<UUID, SelfServingCertificateCoverage>;
    certificateOwner: CertificateEntityRole;
    certificateProducer: CertificateProducer;
}

export function SelfServingFlow({
    coverageMap,
    certificateOwner,
    certificateProducer,
}: SelfServingFlowProps) {
    const { activeSession } = useContext(AppContext);
    const navigation = useNavigation();
    const modalOnIssueCertificateFail = useModal();
    const closeFullScreen = useCallback(() => {
        navigation.navigate('/certificates');
    }, [navigation]);

    const [specialWording, setSpecialWording] = useState<string>('');
    const [documentListUploaded, setDocumentListUploaded] = useState<UploadFileStepItemData[]>([]);
    const [submissionFilesUploaded, setSubmissionFilesUploaded] = useState<FileUpload[]>([]);
    const [isSubmitRequestSuccessful, setIsSubmitRequestSuccessful] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hasWOS, setHasWOS] = useState(false);
    const [hasAdditional, setHasAdditional] = useState(false);
    const [hasPrimaryNonContributory, setHasPrimaryNonContributory] = useState(false);

    const [currentWizardStep, setCurrentWizardStep] = useState<string>(wizardStateMachine.initial);
    const [wizardData, setWizardData] = useState<WizardData>({
        selectCoveragesData: { selectedIdList: [], selectedCoverageList: [] },
    });

    const handleCloseModal = () => {
        closeFullScreen();
    };

    const handleSelectedIdListChange = (selectedId: UUID, addToList: boolean) => {
        if (addToList) {
            setWizardData((prevState) => {
                return {
                    ...prevState,
                    selectCoveragesData: {
                        selectedIdList: [
                            ...prevState.selectCoveragesData.selectedIdList,
                            selectedId,
                        ],
                        selectedCoverageList: prevState.selectCoveragesData.selectedCoverageList,
                    },
                };
            });
            return;
        }

        setWizardData((prevState) => {
            const arrayWithoutId = prevState.selectCoveragesData.selectedIdList.filter(
                (id) => id != selectedId,
            );
            return {
                ...prevState,
                selectCoveragesData: {
                    selectedIdList: arrayWithoutId,
                    selectedCoverageList: prevState.selectCoveragesData.selectedCoverageList,
                },
            };
        });
    };

    useEffect(() => {
        const coverageList: SelfServingCertificateCoverageList = [];
        for (const id of wizardData.selectCoveragesData.selectedIdList) {
            const coverage = coverageMap.get(id);
            if (coverage != undefined) {
                coverageList.push(coverage);
            }
        }
        setWizardData((prevState) => {
            return {
                ...prevState,
                selectCoveragesData: {
                    selectedIdList: prevState.selectCoveragesData.selectedIdList,
                    selectedCoverageList: coverageList,
                },
            };
        });
    }, [wizardData.selectCoveragesData.selectedIdList, coverageMap]);

    const handleBack = () => {
        const step = wizardStateMachine.states[currentWizardStep];
        if (!step) {
            return;
        }

        const previousStep = step?.on?.BACK;
        if (!previousStep) {
            return;
        }
        setCurrentWizardStep(previousStep);
    };

    const handleGetStarted = () => {
        const step = wizardStateMachine.states[currentWizardStep];
        if (!step) {
            return;
        }

        const nextStep = step?.on?.NEXT;
        if (!nextStep) {
            return;
        }
        setCurrentWizardStep(nextStep);
    };

    const handleChangeIssueOptionsData = async (data: IssueOptionsStepData) => {
        if (data.isCertificateForMyself) {
            setIsLoading(true);
            const result = await execute(
                CreateSelfServingCertificate,
                buildCreateSelfServingCertificateRequestData(
                    wizardData.selectCoveragesData,
                    {
                        businessName: wizardData.businessNameData?.businessName,
                        emailAddress: wizardData.emailAddressData?.contactEmail,
                        address1: wizardData.locationData?.address1,
                        address2: wizardData.locationData?.address2,
                        state: wizardData.locationData?.state,
                        zip: wizardData.locationData?.zip,
                        city: wizardData.locationData?.city,
                        referenceNumber: wizardData.locationData?.referenceNumber,
                        isWaiverOfSubrogationDisabled: true,
                        isAdditionalInsuredDisabled: true,
                        isPrimaryNonContributoryDisabled: true,
                    },
                    Array.from(coverageMap.values()),
                    'Own',
                ),
            );
            setIsLoading(false);
            if (isOK(result)) {
                setWizardData((wizardData) => {
                    return {
                        ...wizardData,
                        certificateId: result.value.certificateId,
                    };
                });

                const step = wizardStateMachine.states[currentWizardStep];
                if (!step) {
                    return;
                }

                const nextStep = step?.on?.MYSELF_NEXT;
                if (!nextStep) {
                    return;
                }
                setCurrentWizardStep(nextStep);
            } else {
                modalOnIssueCertificateFail.show();
            }
        } else {
            setWizardData((wizardData) => {
                return {
                    ...wizardData,
                    issueOptionsData: data,
                };
            });

            const step = wizardStateMachine.states[currentWizardStep];
            if (!step) {
                return;
            }

            const nextStep = step?.on?.NEXT;
            if (!nextStep) {
                return;
            }
            setCurrentWizardStep(nextStep);
        }
    };

    const handleChangeBusinessNameData = (data: BusinessNameStepData) => {
        setWizardData((wizardData) => {
            return {
                ...wizardData,
                businessNameData: data,
            };
        });

        const step = wizardStateMachine.states[currentWizardStep];
        if (!step) {
            return;
        }

        const nextStep = step?.on?.NEXT;
        if (!nextStep) {
            return;
        }
        setCurrentWizardStep(nextStep);
    };

    const handleChangeEmailToThemData = (data: EmailToThemStepData) => {
        setWizardData((wizardData) => {
            return {
                ...wizardData,
                emailToThemData: data,
            };
        });
        const step = wizardStateMachine.states[currentWizardStep];
        if (!step) {
            return;
        }

        const nextStep = data.directlyToThem ? step?.on?.DIRECTLY_NEXT : step?.on?.NEXT;
        if (!nextStep) {
            return;
        }
        setCurrentWizardStep(nextStep);
    };

    const handleChangeEmailAddressStepData = (data: EmailAddressStepData) => {
        setWizardData((wizardData) => {
            return {
                ...wizardData,
                emailAddressData: data,
            };
        });
        const step = wizardStateMachine.states[currentWizardStep];
        if (!step) {
            return;
        }

        const nextStep = step?.on?.NEXT;
        if (!nextStep) {
            return;
        }
        setCurrentWizardStep(nextStep);
    };

    const handleChangeLocationData = (data: FormattedLocationStepData) => {
        setWizardData((wizardData) => {
            return {
                ...wizardData,
                locationData: data,
            };
        });
        const step = wizardStateMachine.states[currentWizardStep];
        if (!step) {
            return;
        }

        const nextStep = step?.on?.NEXT;
        if (!nextStep) {
            return;
        }
        setCurrentWizardStep(nextStep);
    };

    const handleChangeCustomizeCertificateData = (data: CustomizeCertificateStepData) => {
        setWizardData((wizardData) => {
            return {
                ...wizardData,
                customizeCertificateData: data,
            };
        });
        const step = wizardStateMachine.states[currentWizardStep];
        if (!step) {
            return;
        }

        const nextStep = step?.on?.CUSTOMIZE_NEXT;
        if (!nextStep) {
            return;
        }
        setCurrentWizardStep(nextStep);
    };

    const handleIssueCustomizeCertificateData = async (data: CustomizeCertificateStepData) => {
        setIsLoading(true);
        const result = await execute(
            CreateSelfServingCertificate,
            buildCreateSelfServingCertificateRequestData(
                wizardData.selectCoveragesData,
                {
                    businessName: wizardData.businessNameData?.businessName,
                    emailAddress: wizardData.emailAddressData?.contactEmail,
                    address1: wizardData.locationData?.address1,
                    address2: wizardData.locationData?.address2,
                    state: wizardData.locationData?.state,
                    zip: wizardData.locationData?.zip,
                    city: wizardData.locationData?.city,
                    referenceNumber: wizardData.locationData?.referenceNumber,
                    isWaiverOfSubrogationDisabled: false,
                    isAdditionalInsuredDisabled: false,
                    isPrimaryNonContributoryDisabled: false,
                },
                Array.from(coverageMap.values()),
                '3rd Party Complete',
            ),
        );
        setIsLoading(false);
        if (isOK(result)) {
            setWizardData((wizardData) => {
                return {
                    ...wizardData,
                    customizeCertificateData: data,
                    certificateId: result.value.certificateId,
                };
            });
            const step = wizardStateMachine.states[currentWizardStep];
            if (!step) {
                return;
            }

            const nextStep = step?.on?.NEXT;
            if (!nextStep) {
                return;
            }
            setCurrentWizardStep(nextStep);
        } else {
            modalOnIssueCertificateFail.show();
        }
    };

    const handleChangeSelectCoveragesData = () => {
        if (wizardData.selectCoveragesData.selectedCoverageList.length == 0) {
            return;
        }

        const step = wizardStateMachine.states[currentWizardStep];
        if (!step) {
            return;
        }

        const hasProvisionStep = !!wizardData.selectCoveragesData.selectedCoverageList.find(
            (coverage) => {
                return (
                    coverage.coverageInfo.waiverOfSubrogation == true ||
                    coverage.coverageInfo.blanketAdditionalInsured == true ||
                    coverage.coverageInfo.primaryNonContributory == true
                );
            },
        );

        const hasWOS = !!wizardData.selectCoveragesData.selectedCoverageList.find((coverage) => {
            return coverage.coverageInfo.waiverOfSubrogation == true;
        });
        setHasWOS(hasWOS);

        const hasAdditional = !!wizardData.selectCoveragesData.selectedCoverageList.find(
            (coverage) => {
                return coverage.coverageInfo.blanketAdditionalInsured == true;
            },
        );
        setHasAdditional(hasAdditional);

        const hasPrimaryNonContributory =
            !!wizardData.selectCoveragesData.selectedCoverageList.find((coverage) => {
                return coverage.coverageInfo.primaryNonContributory == true;
            });
        setHasPrimaryNonContributory(hasPrimaryNonContributory);

        if (!hasWOS || !hasAdditional || !hasPrimaryNonContributory) {
            setWizardData((wizardData) => {
                return {
                    ...wizardData,
                    issueCertificateProvisionsStepData: {
                        isWaiverOfSubrogationDisabled: false,
                        isAdditionalInsuredDisabled: false,
                        isPrimaryNonContributoryDisabled: false,
                    },
                };
            });
        }

        const nextStep = hasProvisionStep ? step?.on?.PROVISIONS_NEXT : step?.on?.NEXT;
        if (!nextStep) {
            return;
        }
        setCurrentWizardStep(nextStep);
    };

    const handleChangeUploadFileData = (isSuccess: boolean) => {
        setIsSubmitRequestSuccessful(isSuccess);
        const step = wizardStateMachine.states[currentWizardStep];
        if (!step) {
            return;
        }

        const nextStep = step?.on?.NEXT;
        if (!nextStep) {
            return;
        }
        setCurrentWizardStep(nextStep);
    };

    const handleChangeIssueCertificateProvisionsData = async (
        data: IssueCertificateProvisionsStepData,
    ) => {
        setIsLoading(true);

        const result = await execute(
            CreateSelfServingCertificate,
            buildCreateSelfServingCertificateRequestData(
                wizardData.selectCoveragesData,
                {
                    businessName: wizardData.businessNameData?.businessName,
                    emailAddress: wizardData.emailAddressData?.contactEmail,
                    address1: wizardData.locationData?.address1,
                    address2: wizardData.locationData?.address2,
                    state: wizardData.locationData?.state,
                    zip: wizardData.locationData?.zip,
                    city: wizardData.locationData?.city,
                    referenceNumber: wizardData.locationData?.referenceNumber,
                    isWaiverOfSubrogationDisabled: data.isWaiverOfSubrogationDisabled ?? false,
                    isAdditionalInsuredDisabled: data.isAdditionalInsuredDisabled ?? false,
                    isPrimaryNonContributoryDisabled:
                        data.isPrimaryNonContributoryDisabled ?? false,
                },
                Array.from(coverageMap.values()),
                '3rd Party Custom With Flags',
            ),
        );
        setIsLoading(false);
        if (isOK(result)) {
            setWizardData((wizardData) => {
                return {
                    ...wizardData,
                    certificateId: result.value.certificateId,
                };
            });

            const step = wizardStateMachine.states[currentWizardStep];
            if (!step) {
                return;
            }

            const nextStep = step?.on?.NEXT;
            if (!nextStep) {
                return;
            }
            setCurrentWizardStep(nextStep);
        } else {
            modalOnIssueCertificateFail.show();
        }
    };

    const handleIssueCertificate = async () => {
        setIsLoading(true);
        const result = await execute(
            CreateSelfServingCertificate,
            buildCreateSelfServingCertificateRequestData(
                wizardData.selectCoveragesData,
                {
                    businessName: wizardData.businessNameData?.businessName,
                    emailAddress: wizardData.emailAddressData?.contactEmail,
                    address1: wizardData.locationData?.address1,
                    address2: wizardData.locationData?.address2,
                    state: wizardData.locationData?.state,
                    zip: wizardData.locationData?.zip,
                    city: wizardData.locationData?.city,
                    referenceNumber: wizardData.locationData?.referenceNumber,
                    isWaiverOfSubrogationDisabled: false,
                    isAdditionalInsuredDisabled: false,
                    isPrimaryNonContributoryDisabled: false,
                },
                Array.from(coverageMap.values()),
                '3rd Party Custom',
            ),
        );
        setIsLoading(false);
        if (isOK(result)) {
            setWizardData((wizardData) => {
                return {
                    ...wizardData,
                    certificateId: result.value.certificateId,
                };
            });

            const step = wizardStateMachine.states[currentWizardStep];
            if (!step) {
                return;
            }

            const nextStep = step?.on?.NEXT;
            if (!nextStep) {
                return;
            }
            setCurrentWizardStep(nextStep);
        } else {
            modalOnIssueCertificateFail.show();
        }
    };

    const handleIssueAnotherCertificate = () => {
        setWizardData({
            selectCoveragesData: { selectedIdList: [], selectedCoverageList: [] },
        });
        setSpecialWording('');
        setDocumentListUploaded([]);
        setSubmissionFilesUploaded([]);
        const step = wizardStateMachine.states[currentWizardStep];
        if (!step) {
            return;
        }

        const nextStep = step?.on?.ANOTHER;
        if (!nextStep) {
            return;
        }
        setCurrentWizardStep(nextStep);
        execute(SendEnterSelfServingFlow, {
            organizationId: activeSession.organizationId,
        } as SendEnterSelfServingFlowRequest);
    };

    const handlePreviewCertificate = async () => {
        setIsLoading(true);
        const previewCertificateResult = await execute(
            PreviewSelfServingCertificate,
            buildPreviewSelfServingCertificateRequestData(
                wizardData.selectCoveragesData,
                {
                    businessName: wizardData.businessNameData?.businessName,
                    emailAddress: wizardData.emailAddressData?.contactEmail,
                    address1: wizardData.locationData?.address1,
                    address2: wizardData.locationData?.address2,
                    state: wizardData.locationData?.state,
                    zip: wizardData.locationData?.zip,
                    city: wizardData.locationData?.city,
                    referenceNumber: wizardData.locationData?.referenceNumber,
                    isWaiverOfSubrogationDisabled: false,
                    isAdditionalInsuredDisabled: false,
                    isPrimaryNonContributoryDisabled: false,
                },
                Array.from(coverageMap.values()),
            ),
        );

        if (isErr(previewCertificateResult)) {
            container.get<Logger>(Log).error(previewCertificateResult.errors);
            setIsLoading(false);
            modalOnIssueCertificateFail.show();
            return;
        }

        const documentUrlResult = await execute(GetDocumentURL, {
            fileKey: previewCertificateResult.value.certificateFileKey,
        });

        setIsLoading(false);
        if (isErr(documentUrlResult)) {
            container.get<Logger>(Log).error(documentUrlResult.errors);
            modalOnIssueCertificateFail.show();
            return;
        }

        window.open(documentUrlResult.value.documentURL);
    };

    const handlePreviewProvisionsCertificate = async (data: IssueCertificateProvisionsStepData) => {
        setWizardData((wizardData) => {
            return {
                ...wizardData,
                issueCertificateProvisionsStepData: data,
            };
        });
        setIsLoading(true);
        const previewCertificateResult = await execute(
            PreviewSelfServingCertificate,
            buildPreviewSelfServingCertificateRequestData(
                wizardData.selectCoveragesData,
                {
                    businessName: wizardData.businessNameData?.businessName,
                    emailAddress: wizardData.emailAddressData?.contactEmail,
                    address1: wizardData.locationData?.address1,
                    address2: wizardData.locationData?.address2,
                    state: wizardData.locationData?.state,
                    zip: wizardData.locationData?.zip,
                    city: wizardData.locationData?.city,
                    referenceNumber: wizardData.locationData?.referenceNumber,
                    isWaiverOfSubrogationDisabled: data.isWaiverOfSubrogationDisabled ?? false,
                    isAdditionalInsuredDisabled: data.isAdditionalInsuredDisabled ?? false,
                    isPrimaryNonContributoryDisabled:
                        data.isPrimaryNonContributoryDisabled ?? false,
                },
                Array.from(coverageMap.values()),
            ),
        );

        if (isErr(previewCertificateResult)) {
            container.get<Logger>(Log).error(previewCertificateResult.errors);
            setIsLoading(false);
            modalOnIssueCertificateFail.show();
            return;
        }

        const documentUrlResult = await execute(GetDocumentURL, {
            fileKey: previewCertificateResult.value.certificateFileKey,
        });

        setIsLoading(false);
        if (isErr(documentUrlResult)) {
            container.get<Logger>(Log).error(documentUrlResult.errors);
            modalOnIssueCertificateFail.show();
            return;
        }

        window.open(documentUrlResult.value.documentURL);
    };

    const handlePreviewCustomizeCertificate = async (data: CustomizeCertificateStepData) => {
        setWizardData((wizardData) => {
            return {
                ...wizardData,
                customizeCertificateData: data,
            };
        });
        setIsLoading(true);
        const previewCertificateResult = await execute(
            PreviewSelfServingCertificate,
            buildPreviewSelfServingCertificateRequestData(
                wizardData.selectCoveragesData,
                {
                    businessName: wizardData.businessNameData?.businessName,
                    emailAddress: wizardData.emailAddressData?.contactEmail,
                    address1: wizardData.locationData?.address1,
                    address2: wizardData.locationData?.address2,
                    state: wizardData.locationData?.state,
                    zip: wizardData.locationData?.zip,
                    city: wizardData.locationData?.city,
                    referenceNumber: wizardData.locationData?.referenceNumber,
                    isWaiverOfSubrogationDisabled: false,
                    isAdditionalInsuredDisabled: false,
                    isPrimaryNonContributoryDisabled: false,
                },
                Array.from(coverageMap.values()),
            ),
        );

        if (isErr(previewCertificateResult)) {
            container.get<Logger>(Log).error(previewCertificateResult.errors);
            setIsLoading(false);
            modalOnIssueCertificateFail.show();
            return;
        }

        const documentUrlResult = await execute(GetDocumentURL, {
            fileKey: previewCertificateResult.value.certificateFileKey,
        });

        setIsLoading(false);
        if (isErr(documentUrlResult)) {
            container.get<Logger>(Log).error(documentUrlResult.errors);
            modalOnIssueCertificateFail.show();
            return;
        }

        window.open(documentUrlResult.value.documentURL);
    };

    const handleClickUploadCertificate = () => {
        const step = wizardStateMachine.states[currentWizardStep];
        if (!step) {
            return;
        }

        const nextStep = step?.on?.UPLOAD_NEXT;
        if (!nextStep) {
            return;
        }
        setCurrentWizardStep(nextStep);
    };

    function buildCreateSelfServingCertificateRequestData(
        data: SelectCoveragesStepData,
        additionalSubmitRequestData: AdditionalCreateSelfServingCertificateData,
        allCoverages: Array<SelfServingCertificateCoverage>,
        certificateIssueSource: CertificateIssueSource,
    ): CreateSelfServingCertificateRequest {
        const coverageList =
            data.selectedIdList.length !== 0 ? data.selectedCoverageList : allCoverages;
        return {
            selfServingCoverageList: coverageList,
            companyName: additionalSubmitRequestData.businessName,
            email: additionalSubmitRequestData.emailAddress,
            mailingAddress: additionalSubmitRequestData.address1,
            mailingAddressCont: additionalSubmitRequestData.address2,
            state: additionalSubmitRequestData.state,
            zipCode: additionalSubmitRequestData.zip,
            city: additionalSubmitRequestData.city,
            referenceNumber: additionalSubmitRequestData.referenceNumber,
            isAdditionalInsuredDisabled: additionalSubmitRequestData.isAdditionalInsuredDisabled,
            isWaiverOfSubrogationDisabled:
                additionalSubmitRequestData.isWaiverOfSubrogationDisabled,
            isPrimaryNonContributoryDisabled:
                additionalSubmitRequestData.isPrimaryNonContributoryDisabled,
            certificateIssueSource: certificateIssueSource,
        };
    }

    function buildPreviewSelfServingCertificateRequestData(
        data: SelectCoveragesStepData,
        additionalSubmitRequestData: AdditionalCreateSelfServingCertificateData,
        allCoverages: Array<SelfServingCertificateCoverage>,
    ): PreviewSelfServingCertificateRequest {
        const coverageList =
            data.selectedIdList.length !== 0 ? data.selectedCoverageList : allCoverages;
        return {
            selfServingCoverageList: coverageList,
            companyName: additionalSubmitRequestData.businessName,
            email: additionalSubmitRequestData.emailAddress,
            mailingAddress: additionalSubmitRequestData.address1,
            mailingAddressCont: additionalSubmitRequestData.address2,
            state: additionalSubmitRequestData.state,
            zipCode: additionalSubmitRequestData.zip,
            city: additionalSubmitRequestData.city,
            referenceNumber: additionalSubmitRequestData.referenceNumber,
            isAdditionalInsuredDisabled: additionalSubmitRequestData.isAdditionalInsuredDisabled,
            isWaiverOfSubrogationDisabled:
                additionalSubmitRequestData.isWaiverOfSubrogationDisabled,
            isPrimaryNonContributoryDisabled:
                additionalSubmitRequestData.isPrimaryNonContributoryDisabled,
        };
    }

    function mapWizardDataToUploadSubmitRequestData(
        wizardData: WizardData,
        certificateOwner: CertificateEntityRole,
        certificateProducer: CertificateProducer,
    ): UploadSubmitRequestData | undefined {
        if (
            wizardData.businessNameData &&
            wizardData.locationData &&
            wizardData.locationData.state
        ) {
            return {
                certificateOwner: certificateOwner,
                certificateProducer: certificateProducer,
                businessName: wizardData.businessNameData.businessName,
                emailAddress: wizardData.emailAddressData?.contactEmail,
                address1: wizardData.locationData.address1,
                address2: wizardData.locationData.address2,
                city: wizardData.locationData.city,
                state: wizardData.locationData.state,
                zip: wizardData.locationData.zip,
                referenceNumber: wizardData.locationData.referenceNumber,
                selectedCoverageList: wizardData.selectCoveragesData.selectedCoverageList,
            };
        }
        return;
    }

    const calculateProgress = () => {
        switch (currentWizardStep) {
            case State.WELCOME_SCREEN_NAME:
            default:
                return undefined;
            case State.ISSUE_OPTION_SCREEN_NAME:
                return 0;
            case State.BUSINESS_NAME_SCREEN_NAME:
                return 10;
            case State.EMAIL_TO_THEM_SCREEN_NAME:
                return 25;
            case State.EMAIL_ADDRESS_SCREEN_NAME:
                return 25;
            case State.LOCATION_SCREEN_NAME:
                return 35;
            case State.CUSTOMIZE_CERTIFICATE_SCREEN_NAME:
                return 60;
            case State.SELECT_COVERAGE_SCREEN_NAME:
                return 70;
            case State.ISSUE_CERTIFICATE_SCREEN_NAME:
                return 95;
            case State.ISSUE_CERTIFICATE_PROVISIONS_SCREEN_NAME:
                return 95;
            case State.UPLOAD_FILE_SCREEN_NAME:
            case State.UPLOAD_FILE_PROVISIONS_SCREEN_NAME:
                return 95;
            case State.FINAL_SCREEN_NAME:
            case State.SUBMIT_REQUEST_FINAL_SCREEN_NAME:
                return 100;
        }
    };

    const uploadSubmitRequestData =
        currentWizardStep === State.UPLOAD_FILE_SCREEN_NAME ||
        currentWizardStep === State.UPLOAD_FILE_PROVISIONS_SCREEN_NAME
            ? mapWizardDataToUploadSubmitRequestData(
                  wizardData,
                  certificateOwner,
                  certificateProducer,
              )
            : undefined;

    const title =
        currentWizardStep === State.WELCOME_SCREEN_NAME ? undefined : 'Issue the certificate';

    if (isLoading) {
        return <Loader />;
    }

    return currentWizardStep === State.WELCOME_SCREEN_NAME ||
        currentWizardStep === State.FINAL_SCREEN_NAME ||
        currentWizardStep === State.SUBMIT_REQUEST_FINAL_SCREEN_NAME ? (
        <WizardLayout progress={calculateProgress()} title={title} onDismiss={closeFullScreen}>
            <WizardLayout.Cover>
                {currentWizardStep === State.WELCOME_SCREEN_NAME && (
                    <WelcomePage
                        onGetStarted={() => {
                            handleGetStarted();
                        }}
                    />
                )}
                {currentWizardStep === State.FINAL_SCREEN_NAME &&
                    wizardData.certificateId !== undefined && (
                        <FinalPage
                            certificateID={wizardData.certificateId}
                            emailAddress={wizardData.emailAddressData?.contactEmail || ''}
                            onIssueAnotherCertificate={() => {
                                handleIssueAnotherCertificate();
                            }}
                        />
                    )}
                {currentWizardStep === State.SUBMIT_REQUEST_FINAL_SCREEN_NAME && (
                    <SubmitRequestFinalPage
                        isSuccessful={isSubmitRequestSuccessful}
                        onIssueAnotherCertificate={() => {
                            handleIssueAnotherCertificate();
                        }}
                    />
                )}
            </WizardLayout.Cover>
        </WizardLayout>
    ) : (
        <WizardLayout
            gutters={false}
            progress={calculateProgress()}
            title={title}
            onDismiss={closeFullScreen}
        >
            <IssueCertificateFailModal
                onClose={handleCloseModal}
                hideModal={modalOnIssueCertificateFail.hide}
                modal={modalOnIssueCertificateFail}
            />
            {currentWizardStep === State.ISSUE_OPTION_SCREEN_NAME && (
                <IssueOptionsStep
                    initialData={wizardData.issueOptionsData}
                    onNext={(data) => {
                        handleChangeIssueOptionsData(data);
                    }}
                    onBack={() => {
                        handleBack();
                    }}
                />
            )}
            {currentWizardStep === State.BUSINESS_NAME_SCREEN_NAME && (
                <BusinessNameStep
                    initialData={wizardData.businessNameData}
                    onNext={(data) => {
                        handleChangeBusinessNameData(data);
                    }}
                    onBack={() => {
                        handleBack();
                    }}
                />
            )}
            {currentWizardStep === State.EMAIL_TO_THEM_SCREEN_NAME && (
                <EmailToThemStep
                    initialData={wizardData.emailToThemData}
                    businessName={wizardData.businessNameData?.businessName || ''}
                    onNext={(data) => {
                        handleChangeEmailToThemData(data);
                    }}
                    onBack={() => {
                        handleBack();
                    }}
                />
            )}
            {currentWizardStep === State.EMAIL_ADDRESS_SCREEN_NAME && (
                <EmailAddressStep
                    initialData={wizardData.emailAddressData}
                    onNext={(data) => {
                        handleChangeEmailAddressStepData(data);
                    }}
                    onBack={() => {
                        handleBack();
                    }}
                />
            )}
            {currentWizardStep === State.LOCATION_SCREEN_NAME && (
                <LocationStep
                    initialData={wizardData.locationData}
                    onNext={(data) => {
                        handleChangeLocationData(data);
                    }}
                    onBack={() => {
                        handleBack();
                    }}
                />
            )}
            {currentWizardStep === State.CUSTOMIZE_CERTIFICATE_SCREEN_NAME && (
                <CustomizeCertificateStep
                    initialData={wizardData.customizeCertificateData}
                    onCustomizeCertificate={(data) => {
                        handleChangeCustomizeCertificateData(data);
                    }}
                    onIssueCertificate={(data) => {
                        handleIssueCustomizeCertificateData(data);
                    }}
                    onPreviewCertificate={(data) => {
                        handlePreviewCustomizeCertificate(data);
                    }}
                    onBack={() => {
                        handleBack();
                    }}
                />
            )}
            {currentWizardStep === State.SELECT_COVERAGE_SCREEN_NAME && (
                <SelectCoveragesStep
                    coverageMap={coverageMap}
                    onSelectedIdListChange={handleSelectedIdListChange}
                    selectCoveragesData={wizardData.selectCoveragesData}
                    onNext={() => {
                        handleChangeSelectCoveragesData();
                    }}
                    onBack={() => {
                        handleBack();
                    }}
                />
            )}
            {currentWizardStep === State.ISSUE_CERTIFICATE_SCREEN_NAME && (
                <IssueCertificateStep
                    onIssueCertificate={() => {
                        handleIssueCertificate();
                    }}
                    onPreviewCertificate={() => {
                        handlePreviewCertificate();
                    }}
                    onClick={() => {
                        handleClickUploadCertificate();
                    }}
                    onBack={() => {
                        handleBack();
                    }}
                    selectedCoverageList={wizardData.selectCoveragesData.selectedCoverageList}
                />
            )}
            {currentWizardStep === State.ISSUE_CERTIFICATE_PROVISIONS_SCREEN_NAME && (
                <IssueCertificateProvisionsStep
                    initialData={wizardData.issueCertificateProvisionsStepData}
                    hasWOS={hasWOS}
                    hasAdditional={hasAdditional}
                    hasPrimaryNonContributory={hasPrimaryNonContributory}
                    onIssueCertificate={(data) => {
                        handleChangeIssueCertificateProvisionsData(data);
                    }}
                    onPreviewCertificate={(data) => {
                        handlePreviewProvisionsCertificate(data);
                    }}
                    onClick={() => {
                        handleClickUploadCertificate();
                    }}
                    onBack={() => {
                        handleBack();
                    }}
                />
            )}
            {(currentWizardStep === State.UPLOAD_FILE_SCREEN_NAME ||
                currentWizardStep === State.UPLOAD_FILE_PROVISIONS_SCREEN_NAME) &&
                uploadSubmitRequestData && (
                    <UploadFileStep
                        initialData={{
                            uploadSubmitRequestData: uploadSubmitRequestData,
                            submissionFiles: submissionFilesUploaded,
                            documentList: documentListUploaded,
                            specialWording: specialWording,
                        }}
                        onUploaded={(data) => {
                            setDocumentListUploaded(data);
                        }}
                        onRemoved={(data) => {
                            setDocumentListUploaded(data);
                        }}
                        onSelected={(data, specialWording) => {
                            setSpecialWording(specialWording);
                            setSubmissionFilesUploaded(data);
                        }}
                        onSubmitRequest={(data) => {
                            handleChangeUploadFileData(data);
                        }}
                        onBack={() => {
                            handleBack();
                        }}
                    />
                )}
        </WizardLayout>
    );
}
