import { valueObject } from '@embroker/shotwell/core/types/ValueObject';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { QuoteOptions } from '../../../../quote/entities/Quote';

export const WCChubbPolicyLimit = [500_000, 1_000_000] as const;
export type WCChubbPolicyLimit = (typeof WCChubbPolicyLimit)[number];

export const WCChubbPerAccidentLimit = [500_000, 1_000_000] as const;
export type WCChubbPerAccidentLimit = (typeof WCChubbPerAccidentLimit)[number];

export const WCChubbEachEmployeeLimit = [500_000, 1_000_000] as const;
export type WCChubbEachEmployeeLimit = (typeof WCChubbEachEmployeeLimit)[number];

export const WCChubbStatutoryText = 'Statutory' as const;

export interface WCChubbQuoteOptions extends QuoteOptions {
    readonly policyLimit: WCChubbPolicyLimit;
    readonly perAccidentLimit: WCChubbPerAccidentLimit;
    readonly eachEmployeeLimit: WCChubbEachEmployeeLimit;
    readonly isDeselected: boolean;
}

export const WCChubbQuoteOptions = valueObject({
    ...defineValidator<WCChubbQuoteOptions>({
        effectiveDate: Joi.date().required(),
        isDeselected: Joi.boolean().required(),
        policyLimit: Joi.number()
            .valid(...WCChubbPolicyLimit)
            .required(),
        perAccidentLimit: Joi.number()
            .valid(...WCChubbPerAccidentLimit)
            .required(),
        eachEmployeeLimit: Joi.number()
            .valid(...WCChubbEachEmployeeLimit)
            .required(),
    }),
});
