import { UUID } from '@embroker/shotwell/core/types/UUID';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { valueObject } from '@embroker/shotwell/core/types/ValueObject';

export interface Endorsement {
    id: UUID;
    name: string;
    formNumber: string;
}

export const Endorsement = valueObject({
    ...defineValidator<Endorsement>({
        id: UUID.schema.required(),
        name: Joi.string().required(),
        formNumber: Joi.string().required(),
    }),
});

export type EndorsementList = Array<Endorsement>;

export const EndorsementList = valueObject({
    ...defineValidator<EndorsementList>(Joi.array().items(Endorsement.schema).min(0)),
});
