import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { PolicyRepository } from '../repositories/PolicyRepository';

export interface SendManagePolicyRequestRequest {
    signatureIdList: string[];
    policyId: UUID;
}

export const SendManagePolicyRequestRequest = {
    ...defineValidator<SendManagePolicyRequestRequest>({
        signatureIdList: Joi.array().items(Joi.string()),
        policyId: UUID.schema,
    }),
    create(signatureIdList: string[], policyId: UUID) {
        const sendManagePolicyRequestRequest = {
            signatureIdList: signatureIdList,
            policyId: policyId,
        };
        return SendManagePolicyRequestRequest.validate(sendManagePolicyRequestRequest);
    },
};

export interface SendManagePolicyRequest extends UseCase {
    execute(
        request: SendManagePolicyRequestRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed>;
}

@injectable()
export class SendManagePolicyRequestUseCase extends UseCase {
    public static type = Symbol('Policy/SendManagePolicyRequest');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(PolicyRepository) private policyRepository: PolicyRepository,
    ) {
        super(eventBus);
    }

    async execute(
        request: SendManagePolicyRequestRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed> {
        const result = await this.policyRepository.borSendTransferRequest(request.signatureIdList);

        if (isErr(result)) {
            return result;
        }

        const policyResult = await this.policyRepository.getPolicy(request.policyId);

        if (isErr(policyResult)) {
            return Failure(OperationFailed(policyResult));
        }

        policyResult.value.transferRequest();

        this.eventBus.publishEntityEvents(policyResult.value);

        return result;
    }
}

export const SendManagePolicyRequest: UseCaseClass<SendManagePolicyRequest> =
    SendManagePolicyRequestUseCase;
