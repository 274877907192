import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { CrimeConfig } from '../types/CrimeConfig';
import { CrimeConfigRepository } from '../repositories/CrimeConfigRepository';

export interface GetCrimeConfig extends UseCase {
    execute(): AsyncResult<CrimeConfig, InvalidArgument | OperationFailed>;
}

@injectable()
class GetCrimeConfigUseCase extends UseCase implements GetCrimeConfig {
    public static type = Symbol('CrimeQuote/GetCrimeConfig');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(CrimeConfigRepository) private crimeConfigRepository: CrimeConfigRepository,
    ) {
        super(eventBus);
    }

    public async execute(): AsyncResult<CrimeConfig, InvalidArgument | OperationFailed> {
        return await this.crimeConfigRepository.getCrimeConfig();
    }
}

export const GetCrimeConfig: UseCaseClass<GetCrimeConfig> = GetCrimeConfigUseCase;
