import { ListOptions } from '@embroker/shotwell-api/app';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import {
    Aborted,
    InvalidArgument,
    OperationFailed,
    UnknownEntity,
} from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { equalUUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { Policy } from '../../policy/entities/Policy';
import { PolicyRepository } from '../../policy/repositories/PolicyRepository';
import { Application } from '../entities/Application';
import { ApplicationRepository, PageInfo } from '../repositories/ApplicationRepository';

export interface GetApplicationListRequest {
    abortSignal?: AbortSignal;
    listOptions?: ListOptions;
    sectionType?: String;
}

export interface GetApplicationListResponse {
    readonly applicationList: EntityProps<Application>[];
    readonly pageInfo: PageInfo;
    readonly canViewLplQuotes: boolean;
    readonly canViewCnaBopQuotes: boolean;
    readonly canViewPCoMLQuotes: boolean;
    readonly canViewCrimeQuotes: boolean;
    readonly canViewCyberQuotes: boolean;
    readonly canViewExcessQuotes: boolean;
}

export interface GetApplicationList extends UseCase {
    execute(
        input: GetApplicationListRequest,
    ): AsyncResult<
        GetApplicationListResponse,
        InvalidArgument | OperationFailed | Aborted | UnknownEntity
    >;
}

@injectable()
class GetApplicationListUseCase extends UseCase implements GetApplicationList {
    public static type = Symbol('Shopping/GetApplicationList');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
        @inject(PolicyRepository) private policyRepository: PolicyRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        input: GetApplicationListRequest,
    ): AsyncResult<
        GetApplicationListResponse,
        InvalidArgument | OperationFailed | Aborted | UnknownEntity
    > {
        const configResponse = await this.applicationRepository.getConfig();
        if (isErr(configResponse)) {
            return configResponse;
        }
        const config = configResponse.value;

        const canViewLplQuotes = config.isLPLEnabled;
        const canViewCnaBopQuotes = config.isBOPEnabled;
        const canViewPCoMLQuotes = config.isPCOMLEnabled;
        const canViewCrimeQuotes = config.isEmbrokerCrimeEnabled;
        const canViewCyberQuotes = config.isEmbrokerCyberEnabled;
        const canViewExcessQuotes = config.isEmbrokerExcessEnabled;

        const getApplicationListResponse = await this.applicationRepository.getApplicationList(
            input?.listOptions,
            input?.abortSignal,
            input?.sectionType,
        );

        if (isErr(getApplicationListResponse)) {
            return getApplicationListResponse;
        }

        const { applicationList, pageInfo } = getApplicationListResponse.value;

        if (applicationList.length === 0) {
            return Success({
                applicationList: [],
                pageInfo,
                canViewCnaBopQuotes,
                canViewPCoMLQuotes,
                canViewLplQuotes,
                canViewCrimeQuotes,
                canViewCyberQuotes,
                canViewExcessQuotes,
            });
        }

        const getPoliciesResponse = await this.policyRepository.getPolicies();
        if (isErr(getPoliciesResponse)) {
            return getPoliciesResponse;
        }
        const policyList = getPoliciesResponse.value as Policy[];

        for (const application of applicationList) {
            this.setPolicyExpirationDate(application as Application, policyList);
        }

        const response: GetApplicationListResponse = {
            applicationList: applicationList as Array<Application>,
            pageInfo,
            canViewCnaBopQuotes,
            canViewLplQuotes,
            canViewPCoMLQuotes,
            canViewCrimeQuotes,
            canViewCyberQuotes,
            canViewExcessQuotes,
        };
        return Success(response);
    }

    private setPolicyExpirationDate(application: Application, policyList: Policy[]): void {
        if (!application.isRenewal()) {
            return;
        }
        const renewedPolicyId = application.renewedPolicyIdList[0];

        const expiringPolicy = policyList.find(({ id }) => {
            return equalUUID(id, renewedPolicyId);
        });

        if (!expiringPolicy) {
            return;
        }

        application.setPolicyExpirationDate(expiringPolicy.endDate);
    }
}

export const GetApplicationList: UseCaseClass<GetApplicationList> = GetApplicationListUseCase;
