import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { inject } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { IndicativePremium } from '../entities/IndicativePremium';
import { IndicativePremiumRepository } from '../repositories/IndicativePremiumRepository';
import { BundleAppTypeNotSupportedError } from '../errors';

interface GetIndicativePremiumListRequest {
    applicationId: UUID;
}

interface GetIndicativePremiumListResponse {
    indicativePremiumList: IndicativePremium[];
}

export interface GetIndicativePremiumList extends UseCase {
    execute({
        applicationId,
    }: GetIndicativePremiumListRequest): AsyncResult<
        GetIndicativePremiumListResponse,
        UnknownEntity | InvalidArgument | OperationFailed | BundleAppTypeNotSupportedError
    >;
}

class GetIndicativePremiumListUseCase extends UseCase implements GetIndicativePremiumList {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Shopping/GetIndicativePremiumList');
    /**
     * Constructor for GetIndicativePremiumList use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param indicativePremiumRepository is indicative premium repo used to store applicant's indicative premiums
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(IndicativePremiumRepository)
        private indicativePremiumRepository: IndicativePremiumRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
    }: GetIndicativePremiumListRequest): AsyncResult<
        GetIndicativePremiumListResponse,
        UnknownEntity | InvalidArgument | OperationFailed | BundleAppTypeNotSupportedError
    > {
        const indicativePremiumListResult =
            await this.indicativePremiumRepository.getIndicativePremiumList(applicationId);

        if (isErr(indicativePremiumListResult)) {
            return indicativePremiumListResult;
        }

        const indicativePremiumList = indicativePremiumListResult.value as IndicativePremium[];

        return Success<GetIndicativePremiumListResponse>({
            indicativePremiumList: indicativePremiumList,
        });
    }
}

export const GetIndicativePremiumList: UseCaseClass<GetIndicativePremiumList> =
    GetIndicativePremiumListUseCase;
