import { GetGlobalConfig } from '@app/config/useCases/GetGlobalConfigUseCase';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { execute } from '@embroker/shotwell/core/UseCase';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { map, route, withData } from 'navi';
import React from 'react';
import { redirectToWhoopsPage } from '../../../view/errors';
import { ExcessQuote } from '../../embrokerExcess/entities/ExcessQuote';
import { GetLastExcessQuote } from '../../embrokerExcess/useCases/GetLastExcessQuote';
import { GetRestriction } from '../../embrokerExcess/useCases/GetRestriction';
import { QuoteLandingPage } from '../../embrokerExcess/view/components/QuoteLandingPage';

const embrokerExcessWizardRouteHandler = map(async (request) => {
    const { applicationId } = request.query;
    if (!UUID.check(applicationId)) {
        return redirectToWhoopsPage([
            InvalidArgument({ argument: 'applicationId', value: applicationId, validator: 'UUID' }),
        ]);
    }

    const getLastQuoteResult = await execute(GetLastExcessQuote, {
        applicationId: applicationId,
    });
    if (isErr(getLastQuoteResult)) {
        return redirectToWhoopsPage(getLastQuoteResult.errors);
    }

    const quote = getLastQuoteResult.value as ExcessQuote;

    const getRestrictionResult = await execute(GetRestriction, {
        insuranceApplicationId: applicationId,
    });
    if (isErr(getRestrictionResult)) {
        return redirectToWhoopsPage(getRestrictionResult.errors);
    }

    let isApplicationEditAllowed = false;
    const getConfigResult = await execute(GetGlobalConfig);
    if (isOK(getConfigResult)) {
        isApplicationEditAllowed = getConfigResult.value.config.excessRenewalsEnabled;
    }

    const restriction = getRestrictionResult.value;

    return route({
        title: 'Embroker Excess quotes',
        view: (
            <QuoteLandingPage
                applicationId={applicationId}
                initialQuote={quote}
                restriction={restriction}
                isApplicationEditAllowed={isApplicationEditAllowed}
            />
        ),
    });
});

export const embrokerExcessRoutes = {
    '/': map((request) =>
        withData(
            { url: request.mountpath, page: undefined, fullscreen: true },
            embrokerExcessWizardRouteHandler,
        ),
    ),

    '/:page': map((request) =>
        withData(
            { url: request.mountpath, page: request.params.page, fullscreen: true },
            embrokerExcessWizardRouteHandler,
        ),
    ),
};
