import { CurrencyMarshaller, QuoteExtended } from '@embroker/shotwell-api/app';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { AsyncResult, Failure, isErr } from '@embroker/shotwell/core/types/Result';
import { QuoteExpiration } from '../../../quote/types/QuoteExpiration';
import {
    BundleCoverageTypeEnum,
    BundleQuoteCoverage,
    BundleQuoteCoverageMetadata,
} from '../../types/BundleQuoteCoverage';
import { toBundleQuoteCoverage } from '../bundleMappingFunctions';
import { ESPQuote } from './entities/ESPQuote';
import { InsuranceApplicationStatusCodeListMap, QuotingEngineESP } from '@app/shopping/types/enums';
import startOfToday from 'date-fns/startOfToday';
import { toEspRate } from '@app/quote/esp/repositories/ESPQuoteRepository/APIESPQuoteRepository';
import { ESP } from '../coverageDefinition';
import { IneligibilityReasons } from '@app/shopping/types/IneligibilityReasons';

export async function buildESPCoverage(
    espQuoteExtended: Immutable<QuoteExtended>,
    isBroker: boolean,
    omitEffectiveDateValidation = false,
): AsyncResult<BundleQuoteCoverage<ESPQuote>, OperationFailed | InvalidArgument> {
    if (!espQuoteExtended) {
        return Failure(OperationFailed({ message: 'espQuoteExtended is null or undefined' }));
    }
    const apiQuote = espQuoteExtended.quote;
    const espCoverageMetadata = getESPCoverageMetadata();

    const espQuestionnaireData = JSON.parse(espQuoteExtended.questionnaire_data);

    if (!apiQuote) {
        return toBundleQuoteCoverage<ESPQuote>(
            BundleCoverageTypeEnum.ESPCoverage,
            espQuoteExtended.app_status,
            espCoverageMetadata,
            espQuestionnaireData,
            espQuoteExtended.app_valid_until,
        );
    }
    const apiEspRate = apiQuote.details[ESP];
    const apiEspQuoteOptions = apiQuote.options[ESP];

    if (!apiEspRate) {
        return Failure(OperationFailed({ message: 'ESPQuoteDetails is null or undefined' }));
    }
    if (!apiEspQuoteOptions) {
        return Failure(OperationFailed({ message: 'ESPQuoteOptions is null or undefined' }));
    }

    const espQuoteResp = await ESPQuote.create({
        isIndication: apiQuote.is_indication,
        id: apiQuote.id,
        quoteNumber: apiQuote.quote_number,
        applicationId: apiQuote.app_id,
        fileKey: apiQuote.file_key ?? undefined,
        totalPremium: CurrencyMarshaller.unmarshal(apiEspRate.total_premium),
        annualTechnologyFee: apiQuote.annual_technology_fee,
        totalPayable: apiQuote.total_payable,
        isCurrentLimitExceed: checkIfCurrentLimitExceeded(apiQuote.options.esp),
        status: mapReferralReasonsToStatus(espQuoteExtended.app_status, {
            referralReasons: espQuoteExtended.ineligibility_reasons?.referral_reasons || [],
            declinedReasons: espQuoteExtended.ineligibility_reasons?.declined_reasons || [],
            investigationNeededReasons:
                espQuoteExtended.ineligibility_reasons?.investigation_needed_reasons || [],
        } as IneligibilityReasons),
        options: {
            effectiveDate: apiEspQuoteOptions.effective_period_start || startOfToday(),
            isDeselected: false,
            directorsAndOfficers: apiEspQuoteOptions.directors_and_officers,
            employmentPracticesLiability: apiEspQuoteOptions.employment_practices_liability,
            fiduciary: apiEspQuoteOptions.fiduciary,
            technology: apiEspQuoteOptions.technology,
            cyber: apiEspQuoteOptions.cyber,
            partnerCode: apiEspQuoteOptions.partner_code,
            policyFee: apiEspQuoteOptions.policy_fee,
            isPolicyFeeTaxable: apiEspQuoteOptions.is_policy_fee_taxable,
        },
        details: toEspRate({
            apiEspRate,
            apiEspQuoteOptions,
            shoppingCoverageList: espQuoteExtended.shopping_coverage_list,
            quotableShoppingCoverageList: espQuoteExtended.quotable_shopping_coverage_list,
            // TODO: https://embroker.atlassian.net/browse/EM-44620
            // It's unclear for now whether we to support the functionality leverage by 'existingLiabilities'
            // src/quote/esp/repositories/ESPQuoteRepository/APIESPQuoteRepository.ts
            existingLiabilities: [],
            taxes: apiQuote.taxes,
            fees: apiQuote.fees,
        }),
        daysToExpire: QuoteExpiration.getDaysLeftUntilExpiration({
            quotingEngine: QuotingEngineESP,
            applicationStatus: espQuoteExtended.app_status,
            isBroker: isBroker,
            quoteEffectiveDate: apiQuote.options.esp.effective_period_start || startOfToday(),
            today: startOfToday(),
            validUntil: espQuoteExtended.app_valid_until || null,
            omitEffectiveDateValidation,
        }),
        referralReasons: espQuoteExtended.ineligibility_reasons?.referral_reasons,
    });

    if (isErr(espQuoteResp)) {
        return Failure(InvalidArgument({ argument: 'ESP coverage', value: espQuoteResp.errors }));
    }

    const bundleQuoteCoverage = toBundleQuoteCoverage<ESPQuote>(
        BundleCoverageTypeEnum.ESPCoverage,
        espQuoteExtended.app_status,
        espCoverageMetadata,
        espQuestionnaireData,
        espQuoteExtended.app_valid_until,
        espQuoteResp.value,
    );

    return bundleQuoteCoverage;
}

const checkIfCurrentLimitExceeded = (options: any) => {
    const isCyberLimitHigherThenAllowed =
        options.cyber?.selected && options.cyber?.limit >= 4_000_000;
    const isTechLimitHigherThenAllowed =
        options.technology?.selected && options.technology?.limit >= 4_000_000;
    const isDirectorsAndOfficersLimitHigherThenAllowed =
        options.directors_and_officers?.selected &&
        options.directors_and_officers?.limit >= 4_000_000;
    const isEPLILimitHigherThenAllowed =
        options.employment_practices_liability?.selected &&
        options.employment_practices_liability?.limit >= 4_000_000;

    return (
        isCyberLimitHigherThenAllowed ||
        isTechLimitHigherThenAllowed ||
        isDirectorsAndOfficersLimitHigherThenAllowed ||
        isEPLILimitHigherThenAllowed
    );
};

export const getESPCoverageMetadata = (): BundleQuoteCoverageMetadata => {
    return {
        title: 'ESP Insurance',
        description: 'ESP coverage description',
        icon: 'esp',
        name: 'ESP',
    };
};

// Similar logic to this can be found bellow
// src/quote/esp/repositories/ESPQuoteRepository/APIESPQuoteRepository.ts
const mapReferralReasonsToStatus = function (
    appStatus: string,
    referralReasons: Nullable<IneligibilityReasons>,
) {
    const status =
        InsuranceApplicationStatusCodeListMap[
            appStatus as keyof typeof InsuranceApplicationStatusCodeListMap
        ];
    switch (status) {
        case 'Purchased':
            return 'accepted';
        case 'Referred':
            if (
                referralReasons !== null &&
                referralReasons.referralReasons.length == 1 &&
                referralReasons.referralReasons[0] ==
                    'Submission referred for underwrite review of loss runs'
            ) {
                return 'draft';
            } else {
                return 'referred';
            }
        default:
            return 'draft';
    }
};
