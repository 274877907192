import {
    ClaimStatusCodeListItem,
    ClaimStatusCodeListMap,
    LineOfBusinessSubtypeCodeListItem,
} from '@embroker/shotwell-api/enums';
import { Immutable, Nullable, Props } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import {
    CardLayout,
    ColumnLayout,
    Pagination,
    StackLayout,
    StatusLabel,
    Table,
    Text,
    useStaticTable,
} from '@embroker/ui-toolkit/v2';
import React, { useMemo } from 'react';
import { PolicyIcon } from '../../../policy/view/components/PolicyIcon';
import { Claimant } from '../../entities/Claim';
import { ClaimDetails } from '../../types/ClaimDetails';
import { ClaimDocument } from '../../types/ClaimDocument';
import { PolicyWithClaims } from '../../types/PolicyWithClaims';
import { ClaimDocumentList } from './ClaimDocumentList';

const TABLE_ITEMS_LIMIT_PER_PAGE = 5;

export function PolicyWithClaimsCard(policy: PolicyWithClaims) {
    const policyClaimTableList = useMemo(() => {
        return [...parseRawPolicyClaimsListForTableView(policy)];
    }, [policy]);

    const { visibleItems, pagination } = useStaticTable({
        items: policyClaimTableList,
        itemsPerPage: TABLE_ITEMS_LIMIT_PER_PAGE,
    });

    const showPagination = pagination.totalPages > 1;
    return (
        <StackLayout>
            <CardLayout>
                <CardLayout.Header>
                    <ColumnLayout data-e2e="user-claims-policy-icon-wrapper" split="2">
                        <ColumnLayout>
                            <PolicyIcon
                                lineOfBusiness={policy.lineOfBusiness}
                                subLineOfBusiness={
                                    (policy.subLineOfBusiness as LineOfBusinessSubtypeCodeListItem) ??
                                    undefined
                                }
                            />
                            <Text style="heading 5">{policy.displayName}</Text>
                        </ColumnLayout>
                    </ColumnLayout>
                    <ColumnLayout split={showPagination ? '-1' : '0'}>
                        {showPagination ? (
                            <Pagination {...pagination} maximumVisibleButtons={4} />
                        ) : null}
                    </ColumnLayout>
                </CardLayout.Header>
                <CardLayout.Body>
                    <StackLayout gap={policy.claimList?.length === 0 ? '24' : 'none'}>
                        <StackLayout gap="none">
                            <Text style="heading 5">{policy.insurerName}</Text>
                            <ColumnLayout>
                                <Text data-e2e="user-claims-card-policy-premium">
                                    {policy.premiumPerYear && (
                                        <MoneyDisplay value={policy.premiumPerYear}></MoneyDisplay>
                                    )}{' '}
                                    Premium
                                </Text>
                                <Text data-e2e="user-claims-card-start-end-date-wrapper">
                                    <DateDisplay format="MMM dd, yyyy" value={policy.startDate} />
                                    {' - '}
                                    <DateDisplay format="MMM dd, yyyy" value={policy.endDate} />
                                </Text>
                                <Text data-e2e="user-claims-card-policy-no">
                                    Policy no. {policy.policyNumber}
                                </Text>
                            </ColumnLayout>
                        </StackLayout>
                        {policy.claimList?.length === 0 ? (
                            <Text data-e2e="user-claims-card-no-filled-claims-text">
                                You have not filed any claims under this policy.
                            </Text>
                        ) : (
                            <Table>
                                <Table.Header>
                                    <Table.Column data-e2e="user-claims-claim-no-col">
                                        Claim No.
                                    </Table.Column>
                                    <Table.Column data-e2e="user-claims-date-of-loss-col">
                                        Date of loss
                                    </Table.Column>
                                    <Table.Column data-e2e="user-claims-reported-col">
                                        reported
                                    </Table.Column>
                                    <Table.Column data-e2e="user-claims-close-date-col">
                                        close date
                                    </Table.Column>

                                    <Table.Column data-e2e="user-claims-amt-reserved-col">
                                        Amt. Reserved
                                    </Table.Column>
                                    <Table.Column data-e2e="user-claims-amt-paid-col">
                                        Amt. Paid
                                    </Table.Column>
                                    <Table.Column data-e2e="user-claims-valuation-date">
                                        Valuation date
                                    </Table.Column>
                                    <Table.Column data-e2e="user-claims-status-col">
                                        Status
                                    </Table.Column>
                                    <Table.Column />
                                </Table.Header>
                                <Table.Body>
                                    {visibleItems.map((row, index) => {
                                        let status = '';
                                        if (row.status != null) {
                                            status = ClaimStatusCodeListMap[row.status];
                                        }
                                        return (
                                            <React.Fragment key={index}>
                                                <Table.Row data-e2e="claims-card-layout">
                                                    <Table.Cell>
                                                        <Text
                                                            data-e2e="user-claims-claims-number"
                                                            style="label 1"
                                                            nowrap
                                                        >
                                                            {row.claimNumber}
                                                        </Text>
                                                    </Table.Cell>
                                                    <Table.Cell data-e2e="user-claims-loss-date">
                                                        {row.lossDate && (
                                                            <DateDisplay
                                                                format="M/d/yyyy"
                                                                value={row.lossDate}
                                                            ></DateDisplay>
                                                        )}
                                                    </Table.Cell>
                                                    <Table.Cell data-e2e="user-claims-reported-date">
                                                        <DateDisplay
                                                            format="M/d/yyyy"
                                                            value={row.reportedDate}
                                                        />
                                                    </Table.Cell>
                                                    <Table.Cell data-e2e="user-claims-closed-date">
                                                        {row.closedDate && (
                                                            <DateDisplay
                                                                format="M/d/yyyy"
                                                                value={row.closedDate}
                                                            ></DateDisplay>
                                                        )}
                                                    </Table.Cell>
                                                    <Table.Cell data-e2e="user-claims-reserved-amount">
                                                        {!!row.reservedAmount?.amount && (
                                                            <MoneyDisplay
                                                                value={row.reservedAmount}
                                                            ></MoneyDisplay>
                                                        )}
                                                    </Table.Cell>
                                                    <Table.Cell data-e2e="user-claims-amount-paid">
                                                        {!!row.amountPaid?.amount && (
                                                            <MoneyDisplay
                                                                value={row.amountPaid}
                                                            ></MoneyDisplay>
                                                        )}
                                                    </Table.Cell>
                                                    <Table.Cell data-e2e="user-claims-valuation-date">
                                                        {row.valuationDate && (
                                                            <DateDisplay
                                                                format="M/d/yyyy"
                                                                value={row.valuationDate}
                                                            ></DateDisplay>
                                                        )}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {row.status && (
                                                            <StatusLabel
                                                                data-e2e="user-claims-status"
                                                                fixed
                                                                type={
                                                                    row.status ==
                                                                    'ClaimStatusCodeListClosed'
                                                                        ? 'green'
                                                                        : 'gray'
                                                                }
                                                            >
                                                                {status}
                                                            </StatusLabel>
                                                        )}
                                                    </Table.Cell>
                                                    <Table.Cell data-e2e="claims-expand-close-wrapper">
                                                        <Table.RowDisclosure
                                                            rowId={row.claimNumber}
                                                        />
                                                    </Table.Cell>
                                                </Table.Row>
                                                <Table.Row expandable id={row.claimNumber}>
                                                    <ClaimDocumentList
                                                        claimDetails={
                                                            row as PolicyClaimListTableItem
                                                        }
                                                    />
                                                </Table.Row>
                                            </React.Fragment>
                                        );
                                    })}
                                </Table.Body>
                            </Table>
                        )}
                    </StackLayout>
                </CardLayout.Body>
            </CardLayout>
        </StackLayout>
    );
}

interface PolicyClaimListTableItem extends Record<string, unknown> {
    claimNumber: string;
    lossDate: Nullable<Date>;
    reportedDate: Date;
    closedDate: Nullable<Date>;
    valuationDate: Nullable<Date>;
    status: Nullable<ClaimStatusCodeListItem>;
    amountPaid: Nullable<Money>;
    adjusterName: Nullable<string>;
    reservedAmount: Nullable<Money>;
    claimList: ClaimDetails[];
    causeOfLoss: Nullable<string>;
    claimantList: Claimant[];
    documents: ClaimDocument[];
}

function parseRawPolicyClaimsListForTableView(
    inputPolicyClaimList: Immutable<Props<PolicyWithClaims>>,
) {
    const policyClaimList =
        inputPolicyClaimList.claimList?.map((element) => {
            return {
                claimNumber: element.claimNumber,
                lossDate: element.lossDate,
                reportedDate: element.reportedDate,
                status: element.status,
                amountPaid: element.amountPaid,
                reservedAmount: element.reservedAmount,
                closedDate: element.closedDate,
                valuationDate: element.valuationDate,
                documents: element.documents,
                claimantList: element.claimantList,
                causeOfLoss: element.causeOfLoss,
                adjusterName: element.adjusterName,
            };
        }) || [];
    return policyClaimList;
}
