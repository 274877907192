import { BundleCoverageType } from '../../types/BundleQuoteCoverage';
import React from 'react';
import { ColumnLayout, StackLayout, Text, Tooltip } from '@embroker/ui-toolkit/v2';
import { BundleQuote } from '../../entities/BundleQuote';

export interface BundleQuoteEndorsementsProps {
    preBindEndorsements: Record<BundleCoverageType, string[]>;
    bundleQuote: BundleQuote;
}

export function BundleQuoteEndorsements({
    preBindEndorsements,
    bundleQuote,
}: BundleQuoteEndorsementsProps) {
    return (
        <StackLayout gap="20">
            <ColumnLayout gap="4" center>
                <Text style="heading 4" as="span">
                    Endorsements
                </Text>
                <Tooltip
                    iconColor="ui-500"
                    iconSize="small"
                    text="An underwriter has reviewed your Application and quoted coverage subject to the below endorsements (modifications) being included."
                />
            </ColumnLayout>

            {Object.keys(preBindEndorsements).map((coverageType) => (
                <React.Fragment key={coverageType}>
                    <Text style="heading 5">
                        {
                            bundleQuote.coverageList.find(
                                (coverage) =>
                                    coverage.type === (coverageType as BundleCoverageType),
                            )?.coverageMetadata.title
                        }
                    </Text>
                    <ul>
                        {preBindEndorsements[coverageType as BundleCoverageType].map(
                            (endorsement) => (
                                <li key={endorsement}>
                                    <Text style="body 2" as="p" color="ui-500">
                                        {endorsement}
                                    </Text>
                                </li>
                            ),
                        )}
                    </ul>
                </React.Fragment>
            ))}
        </StackLayout>
    );
}
