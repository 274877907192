import type * as APIType from '@embroker/shotwell-api/app';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { cowbellFormDataType } from './cowbellCyberFormData';
import { CowbellCyberQuoteDetails } from './types/CowbellCyberQuoteDetails';
import {
    CowbellCyberLimit,
    CowbellCyberQuoteOptions,
    CowbellCyberRetention,
} from './types/CowbellCyberQuoteOptions';

export function toApiCowbellCyberQuoteOptions(
    options: CowbellCyberQuoteOptions,
): APIType.CyberCowbellQuoteOptions {
    return {
        effective_date: options.effectiveDate,
        limit: options.limit,
        retention: options.retention,
        level: 'standard',
        is_deselected: options.isDeselected,
    };
}

export function toCowbellCyberQuoteOptions(
    options: APIType.CyberCowbellQuoteOptions,
): CowbellCyberQuoteOptions {
    return {
        effectiveDate: options.effective_date,
        limit: options.limit as CowbellCyberLimit,
        retention: options.retention as CowbellCyberRetention,
        isDeselected: options.is_deselected,
    };
}

export function toCowbellCyberQuoteDetails(
    details: Immutable<APIType.CyberCowbellQuoteDetails>,
    taxes: Nullable<Money>,
): CowbellCyberQuoteDetails {
    return {
        taxes: taxes || undefined,
        basePremium: details.total_base_premium,
        specialSurcharge: details.special_surcharge,
    };
}

export const formDataToCowbellCyberQuoteOptions = (
    formData: any,
): CowbellCyberQuoteOptions | undefined => {
    if (!isCowbellCyberFormData(formData)) {
        return undefined;
    }
    return {
        effectiveDate: formData.effectiveDate,
        limit: formData.cyberLimit,
        retention: formData.cyberRetention,
        isDeselected: !formData.cyberSelected,
    };
};

export function isCowbellCyberFormData(input: any): input is cowbellFormDataType {
    return 'cyberLimit' in input && 'cyberRetention' in input && 'cyberSelected' in input;
}

export function isCowbellCyberQuoteOptions(options: any): options is CowbellCyberQuoteOptions {
    return 'limit' in options && 'retention' in options && 'effectiveDate' in options;
}
