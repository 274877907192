import * as React from 'react';
import { map, route } from 'navi';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { PageNotFound } from '@embroker/shotwell/view/components/PageNotFound';

import { IndicativePremiumPage } from '../components/lawBundle/IndicativePremiumPage';
import { EMBROKER } from '.';
import { LawBundleTier1QuestionnaireAutofill } from '../components/lawBundle/LawBundleTier1QuestionnaireAutofill';

export const lawBundleWizardRoutes = {
    '/': map(() => {
        return route({
            title: EMBROKER,
            view: <LawBundleTier1QuestionnaireAutofill />,
            data: { fullscreen: true },
        });
    }),
    '/coverage-selection': map((request) => {
        const applicationId = UUID.validate(request.query.applicationId);
        if (isErr(applicationId)) {
            return route({ view: <PageNotFound /> });
        }
        return route({
            title: EMBROKER,
            view: <IndicativePremiumPage applicationId={applicationId.value} />,
            data: { fullscreen: true },
        });
    }),
};
