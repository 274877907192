import {
    Button,
    Modal,
    ModalActions,
    ModalState,
    ScrollBox,
    StackLayout,
    Table,
    Text,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { ESPCoverageType } from '../ESPCoverage/limitRetentionOptions';

interface HowMuchCoverageModalContentProps {
    modal: ModalState & ModalActions;
    coverageType: ESPCoverageType;
}

function DirectorsAndOfficers() {
    return (
        <StackLayout gap="12">
            <Text style="heading 4">Directors & Officers Liability</Text>
            <Text>
                Below, Embroker has provided rough guidance on limits, retention and premium for
                Directors & Officers Liability insurance.
            </Text>
            <Table style="subtle">
                <Table.Header>
                    <Table.Column>FUNDING</Table.Column>
                    <Table.Column>LIMITS</Table.Column>
                    <Table.Column>RETENTION</Table.Column>
                    <Table.Column />
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            <Text>$0 - $10M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$1M - $2M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$10k - $25k</Text>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <Text>$10M - $25M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$2M - $3M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$10k - $50k</Text>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <Text>$25M - $50M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$3M - $5M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$25k - $50k</Text>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <Text>$50M - $100M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$5M - $8M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$25k - $75k</Text>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <Text>$100M - $250M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$8M - $10M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$25k - $75k</Text>
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </StackLayout>
    );
}

function EmploymentPracticesLiability() {
    return (
        <StackLayout gap="12">
            <Text style="heading 4">Employment Practices Liability</Text>
            <Text>
                Below, Embroker has provided rough guidance on limits, retention and premium for
                Employment Practices Liability insurance.
            </Text>
            <Table style="subtle">
                <Table.Header>
                    <Table.Column># OF EMPLOYEES</Table.Column>
                    <Table.Column>LIMITS</Table.Column>
                    <Table.Column>RETENTION</Table.Column>
                    <Table.Column />
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            <Text>0 - 25</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$1M - $2M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$10k - $25k</Text>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <Text>25 - 100</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$2M - $3M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$10k - $50k</Text>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <Text>100 - 250</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$3M - $5M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$25k - $50k</Text>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <Text>250 - 1000</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$5M - $8M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$25k - $75k</Text>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <Text>1000+</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$8M - $10M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$50k - $150k</Text>
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </StackLayout>
    );
}

function ErrorsAndOmissions() {
    return (
        <StackLayout gap="12">
            <Text style="heading 4">Technology E&O/Cyber</Text>
            <Text>
                Below, Embroker has provided rough guidance on limits, retention and premium for
                Technology E&O/Cyber insurance.
            </Text>
            <Table style="subtle">
                <Table.Header>
                    <Table.Column>REVENUE</Table.Column>
                    <Table.Column>LIMITS</Table.Column>
                    <Table.Column>RETENTION</Table.Column>
                    <Table.Column />
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            <Text>$0 - $2.5M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$1M - $2M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$2.5k - $5k</Text>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <Text>$5M - $10M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$2M - $3M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$2.5k - $10k</Text>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <Text>$10M - $25M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$3M - $5M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$10k - $25k</Text>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <Text>$25M - $50M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$5M - $8M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$10k - $50k</Text>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <Text>$50M - $100M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$8M - $10M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$25k - $75k</Text>
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
                <Table.Header>
                    <Table.Column># OF SENSITIVE RECORDS COLLECTED</Table.Column>
                    <Table.Column>LIMITS</Table.Column>
                    <Table.Column>RETENTION</Table.Column>
                    <Table.Column />
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            <Text>$0 - $1M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$1M - $2M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$2.5k - $5k</Text>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <Text>$1M - $5M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$2M - $3M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$2.5k - $10k</Text>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <Text>$5M - $25M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$3M - $5M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$10k - $25k</Text>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <Text>$25M - $50M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$5M - $8M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$10k - $50k</Text>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <Text>$50M - $100M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$8M - $10M</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>$25k - $75k</Text>
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </StackLayout>
    );
}

function getTableByCoverageType(coverageType: ESPCoverageType) {
    if (coverageType == 'do') {
        return <DirectorsAndOfficers />;
    }
    if (coverageType == 'epli') {
        return <EmploymentPracticesLiability />;
    }
    if (coverageType == 'eoCyber') {
        return <ErrorsAndOmissions />;
    }
}

export const HowMuchCoverageModalContent = React.memo(function HowMuchCoverageModalContent({
    modal,
    coverageType,
}: HowMuchCoverageModalContentProps) {
    return (
        <Modal {...modal} size="medium">
            <StackLayout gap="24">
                <Text style="heading 3">How much coverage do I need?</Text>
                <ScrollBox>{getTableByCoverageType(coverageType)}</ScrollBox>
                <Button onClick={modal.hide}>Got it</Button>
            </StackLayout>
        </Modal>
    );
});
