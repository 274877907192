import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { OperationFailed, InvalidArgument } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { ShoppingGetSignatureDocumentResponse } from '@embroker/shotwell-api/app';
import { inject } from '@embroker/shotwell/core/di';
import { BrokerRepository } from '../repositories';
import {
    SignatureDocumentAlreadySigned,
    SignatureDocumentNotAllowed,
    SignatureDocumentNotFound,
} from '../errors';

export interface GetSignatureDocument extends UseCase {
    execute(
        token: string,
    ): AsyncResult<
        ShoppingGetSignatureDocumentResponse,
        | OperationFailed
        | InvalidArgument
        | SignatureDocumentNotFound
        | SignatureDocumentAlreadySigned
        | SignatureDocumentNotAllowed
    >;
}

class GetSignatureDocumentUseCase extends UseCase implements GetSignatureDocument {
    public static type = Symbol('Broker/GetSignatureDocument');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BrokerRepository) private brokerRepository: BrokerRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        token: string,
    ): AsyncResult<
        ShoppingGetSignatureDocumentResponse,
        | OperationFailed
        | InvalidArgument
        | SignatureDocumentNotFound
        | SignatureDocumentAlreadySigned
        | SignatureDocumentNotAllowed
    > {
        return this.brokerRepository.getSignatureDocument({ token });
    }
}

export const GetSignatureDocument: UseCaseClass<GetSignatureDocument> = GetSignatureDocumentUseCase;
