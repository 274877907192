import { State } from '@embroker/shotwell/core/types/StateList';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import {
    defineValidator,
    Joi,
    Schema,
    TypeChecker,
    Validator,
} from '@embroker/shotwell/core/validation/schema';
import { physicalAddressValidator } from '@embroker/shotwell/core/types/POBox';

export interface Location {
    readonly addressLine1?: string;
    readonly addressLine2?: string;
    readonly city?: string;
    readonly state?: State;
    readonly county?: string;
    readonly valueOfProperty?: number;
    readonly squareFootageOccupied?: number;
    readonly zip?: ZipCode;
}

export interface LocationValidator {
    /**
     * A Joi schema matching a valid MailingAddress object.
     */
    readonly schema: Schema.ObjectSchema<Location>;
    /**
     * Type predicate that checks if a given value can be used as MailingAddress object.
     *
     * Use this only to do early returns. It's recommended to use validate()
     * before using an unknown value as MailingAddress object as it normalizes the value
     * in addition to performing the same validation as check().
     */
    readonly check: TypeChecker<Location>;
    /**
     * Validates and normalizes the given value to a MailingAddress object.
     *
     * This should be used for all untrusted inputs to verify and, if required
     * (and possible), normalize the input.
     */
    readonly validate: Validator<Location>;
}

export const Location = {
    ...defineValidator<Location>({
        addressLine1: Joi.string()
            .pattern(new RegExp(physicalAddressValidator, 'i'))
            .optional()
            .allow(''),
        addressLine2: Joi.string()
            .pattern(new RegExp(physicalAddressValidator, 'i'))
            .optional()
            .allow(''),
        city: Joi.string().optional().allow(''),
        state: State.schema.optional(),
        county: Joi.string().optional().allow(''),
        valueOfProperty: Joi.number().optional(),
        squareFootageOccupied: Joi.number().optional(),
        zip: ZipCode.schema.optional(),
    }),
    create(location: Location) {
        return Location.validate(location);
    },
};
