import React from 'react';
import { Banner, Button, Text } from '@embroker/ui-toolkit/v2';

interface ActionProps {
    label: string;
    onClick: () => void;
    dataE2e?: string;
}

interface ShoppingBannerProps {
    action: ActionProps;
    title: string;
    subtitle: string;
    className?: string;
}

export function ShoppingBanner({ action, title, subtitle, className }: ShoppingBannerProps) {
    return (
        <Banner
            className={className}
            action={
                <Button data-e2e={action.dataE2e} appearance="primary" onClick={action.onClick}>
                    {action.label}
                </Button>
            }
        >
            <Text as="span" style="label 1">
                {title}{' '}
            </Text>
            <Text as="span" style="body 2">
                {subtitle}
            </Text>
        </Banner>
    );
}
