import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { isAuthenticated } from '../entities/Session';
import { SessionRepository } from '../repositories/SessionRepository';
import { Unauthenticated } from '../errors';

/**
 * Request data for SelectOrganization use case
 * @param organizationId is the organization id which authenticated user want's to set as it's current active session's organization
 * Current active organization is stored so that user can fetch data about organization and execute requests for organization on the platform
 */
export interface SelectOrganizationRequest {
    organizationId: UUID;
}

/**
 * SelectOrganization use case is used to switch current active organization for the authenticated user
 */

export interface SelectOrganization extends UseCase {
    execute(request: SelectOrganizationRequest): AsyncResult<void, Unauthenticated>;
}

@injectable()
class SelectOrganizationUseCase extends UseCase implements SelectOrganization {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/SelectOrganization');
    /**
     * Constructor for SelectOrganization use case class instance
     * @param eventBus An event bus this Use Case will publish events to.
     * @param sessionRepository is the session repository which will be used to save the current active organization in the session entity
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(SessionRepository) private sessionRepository: SessionRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes SelectOrganization use case
     * Input is of type SelectOrganizationRequest
     * @returns Nothing if execution was successful
     * @returns Unauthenticated if provided session entity expired or if session was deauthenticated
     */
    public async execute({
        organizationId,
    }: SelectOrganizationRequest): AsyncResult<void, Unauthenticated> {
        const sessionResult = await this.sessionRepository.getActiveSession();

        if (!isAuthenticated(sessionResult.value)) {
            return Failure(Unauthenticated());
        }

        sessionResult.value.selectOrganization(organizationId);

        const saveResult = await this.sessionRepository.save(sessionResult.value);

        await this.eventBus.publishEntityEvents(saveResult.value);

        return Success();
    }
}

export const SelectOrganization: UseCaseClass<SelectOrganization> = SelectOrganizationUseCase;
