import { Nullable } from '@embroker/shotwell/core/types';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import {
    defineValidator,
    Joi,
    Schema,
    TypeChecker,
    Validator,
} from '@embroker/shotwell/core/validation/schema';
import { LiabilitySection } from './LiabilitySection';

/**
 * Set of policy ids
 */
export interface PolicyIds {
    id: UUID;
    renewalSeriesId: Nullable<UUID>;
}

export const PolicyIds = {
    ...defineValidator<PolicyIds>({
        id: UUID.schema,
        renewalSeriesId: UUID.schema,
    }),
    create(policyIds: PolicyIds) {
        return PolicyIds.validate(policyIds);
    },
};

/**
 * Represents Umbrella/Excess "line of business" object
 */
export interface UmbrellaExcessLiabilitySection extends LiabilitySection {
    /**
     * List of policies "under" umbrella
     */
    underlyingAgreementList: PolicyIds[];
}

export interface UmbrellaExcessLiabilitySectionValidator {
    /**
     * A Joi schema matching a valid LobUmbrellaExcess object.
     */
    readonly schema: Schema.ObjectSchema<UmbrellaExcessLiabilitySection>;
    /**
     * Type predicate that checks if a given value can be used as UmbrellaExcessLiabilitySection object.
     *
     * Use this only to do early returns. It's recommended to use validate()
     * before using an unknown value as UmbrellaExcessLiabilitySection object as it normalizes the value
     * in addition to performing the same validation as check().
     */
    readonly check: TypeChecker<UmbrellaExcessLiabilitySection>;
    /**
     * Validates and normalizes the given value to a LobUmbrellaExcess object.
     *
     * This should be used for all untrusted inputs to verify and, if required
     * (and possible), normalize the input.
     */
    readonly validate: Validator<UmbrellaExcessLiabilitySection>;
}

export const UmbrellaExcessLiabilitySection = {
    ...defineValidator<UmbrellaExcessLiabilitySection>(
        LiabilitySection.schema.keys({
            underlyingAgreementList: Joi.array().items(PolicyIds.schema),
        }),
    ),
    create(lobUmbrellaExcess: UmbrellaExcessLiabilitySection) {
        return UmbrellaExcessLiabilitySection.validate(lobUmbrellaExcess);
    },
};
