import React, { useEffect } from 'react';
import { StackLayout, Text } from '@embroker/ui-toolkit/v2';
import { WorkerTypeFormData, WorkerTypes } from './WorkerTypes';
import { Location } from '../../../shopping/types/Location';

interface WorkerTypesByLocationProps {
    /**
     * Location that pertains to this set of worker types.
     */
    location: Location;

    /**
     * Worker types for this location.
     */
    worker_types: WorkerTypeFormData[];

    /**
     * Form Engine errors to forward to the components.
     * This is used to show the 'This field is required' error message
     * when a user tries to continue without filling out all the fields.
     *
     * Note: This is an array of 'Records'. This is because the underlying form
     * can have multiple instances of 'Worker Types' when the user clicks '+ Add Another Type'.
     */
    errors?: Record<string, string[]>[];

    /**
     * Dictates if the component should show the location header.
     * This is typically true when the user has more than one location, and false otherwise.
     */
    show_location_header?: boolean;

    /**
     * onChange method provided by the form engine.
     * Calling this method will save this components's data to the questionnaire data
     * for the current application.
     * @param newData
     * @returns
     */
    onChange: (newData: any) => void;
}

export const WorkerTypesByLocation = React.forwardRef(function WorkerTypesByLocation(
    props: WorkerTypesByLocationProps,
    ref?: React.Ref<HTMLDivElement>,
) {
    const updateWorkerTypes = (newData: WorkerTypeFormData[]) => {
        props.onChange({
            location: location,
            worker_types: newData,
            show_location_header: show_location_header,
        });
    };

    const setDefaultWorkersTypes = (workerTypes: WorkerTypeFormData[]) => {
        return workerTypes.length > 0 ? workerTypes : [{}];
    };

    const [location, setLocation] = React.useState<Location>(props.location);

    // Add a default state to prevent any situation where a worker_types lenght is 0
    const [worker_types, setWorkerTypes] = React.useState<WorkerTypeFormData[]>(
        setDefaultWorkersTypes(props.worker_types),
    );
    const [show_location_header, setShowLocationHeader] = React.useState<boolean | undefined>(
        props.show_location_header,
    );

    useEffect(() => {
        setLocation(props.location);
        setWorkerTypes(props.worker_types);
        setShowLocationHeader(props.show_location_header);
    }, [props.location, props.worker_types, props.show_location_header]);

    return (
        <StackLayout>
            <React.Fragment>
                {show_location_header && (
                    <Text>
                        <b>
                            {location?.mailing_address +
                                ', ' +
                                location?.city +
                                ', ' +
                                location?.zip +
                                ', ' +
                                location?.state}
                        </b>
                    </Text>
                )}
                <WorkerTypes
                    locationId={location.id || undefined}
                    state={location.state || undefined}
                    onChange={updateWorkerTypes}
                    errors={props.errors}
                    worker_types={worker_types}
                ></WorkerTypes>
            </React.Fragment>
        </StackLayout>
    );
});
