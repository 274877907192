import { UUID } from '@embroker/shotwell/core/types/UUID';
import {
    defineValidator,
    Joi,
    Schema,
    TypeChecker,
    Validator,
} from '@embroker/shotwell/core/validation/schema';
import { CoverageRestriction } from './CoverageRestriction';

export interface InsuranceApplicationRestriction {
    insuranceApplicationId: UUID;
    coverageRestrictions: CoverageRestriction[];
    minimumStartDate: Date;
}

export interface InsuranceApplicationRestrictionValidator {
    /**
     * A Joi schema matching a valid Restriction object.
     */
    readonly schema: Schema.ObjectSchema<InsuranceApplicationRestriction>;
    /**
     * Type predicate that checks if a given value can be used as Restriction object.
     *
     * Use this only to do early returns. It's recommended to use validate()
     * before using an unknown value as Restriction object as it normalizes the value
     * in addition to performing the same validation as check().
     */
    readonly check: TypeChecker<InsuranceApplicationRestriction>;
    /**
     * Validates and normalizes the given value to a Restriction object.
     *
     * This should be used for all untrusted inputs to verify and, if required
     * (and possible), normalize the input.
     */
    readonly validate: Validator<InsuranceApplicationRestriction>;
}

export const InsuranceApplicationRestriction = {
    ...defineValidator<InsuranceApplicationRestriction>({
        insuranceApplicationId: UUID.schema,
        coverageRestrictions: Joi.array().items(CoverageRestriction.schema),
        minimumStartDate: Joi.date(),
    }),
    create(restriction: InsuranceApplicationRestriction) {
        return InsuranceApplicationRestriction.validate(restriction);
    },
};
