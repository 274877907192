import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { isOK, Success, SuccessResult } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { SessionRepository } from '../repositories/SessionRepository';
import { Session } from '../entities/Session';
import { Immutable } from '@embroker/shotwell/core/types';

/**
 * Logout use case is used to deauthenticate currently authenticated user represented by provided session entity
 */

export interface Logout extends UseCase {
    execute(): Promise<SuccessResult<void>>;
}

@injectable()
class LogoutUseCase extends UseCase implements Logout {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/Logout');
    /**
     * Constructor for Logout use case class instance
     * @param eventBus An event bus this Use Case will publish events to.
     * @param sessionRepository is the session repository used to destroy currently active session
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(SessionRepository) private sessionRepository: SessionRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes Logout use case
     * Input is of type LogoutRequest
     * @returns Nothing if execution was successful
     * @returns InvalidArgument error is provided session entity did not represent current active session
     */
    public async execute(): Promise<SuccessResult<void>> {
        let activeSession: Immutable<Session> | undefined = undefined;
        const activeSessionResult = await this.sessionRepository.getActiveSession();
        if (isOK(activeSessionResult)) {
            activeSession = activeSessionResult.value ?? undefined;
        }

        await this.sessionRepository.delete();

        if (activeSession) {
            activeSession.deauthenticate();
            await this.eventBus.publishEntityEvents(activeSession);
        }

        return Success();
    }
}

export const Logout: UseCaseClass<Logout> = LogoutUseCase;
