import { Immutable } from '@embroker/ui-toolkit/v2';
import {
    addDays,
    addMonths,
    endOfYear,
    format,
    isBefore,
    startOfMonth,
    startOfToday,
    startOfYear,
    subMonths,
} from 'date-fns';
import { DataPoint, IndexOptions } from '../../../../repositories';
import { displayDateFormat } from './ChartModule';
import { ReportColors } from './ReportColors';

export interface BrokerReportProps {
    title?: string;
    width?: number;
    height?: number;
    className?: string;
}

interface TypeDataSet {
    backgroundColor?: string;
    borderColor?: string;
    borderWidth?: number;
    data: DataPoint[];
    label: string;
    stack?: string;
}

export interface ChartReadyData {
    datasets: TypeDataSet[];
}
export interface InitialDataStructure {
    [key: string]: TypeDataSet;
}

const defaultIndexOptions: IndexOptions = { indexBy: 'x' };

export const Report = {
    defaults: {
        defaultIndexOptions: defaultIndexOptions,
        getDefaultOptions: function () {
            return {
                indexAxis: Report.defaults.defaultIndexOptions.indexBy,
                maxBarThickness: 15,
                scales: {
                    x: {
                        min: Report.dateUtils.getReportMinDate().getTime(),
                        max: Report.dateUtils.getReportMaxDate().getTime(),
                        type: 'time',
                        time: {
                            unit: 'month',
                            displayFormats: {
                                month: Report.dateUtils.displayDateFormat,
                            },
                        },
                        grid: {
                            tickLength: 0,
                            color: ReportColors.grid,
                            borderColor: ReportColors.reportAxis,
                        },
                    },
                    y: {
                        grid: {
                            tickLength: 0,
                            color: ReportColors.grid,
                            borderColor: ReportColors.reportAxis,
                        },
                        ticks: {
                            stepSize: 1,
                            beginAtZero: true,
                            maxTicksLimit: 10,
                        },
                    },
                },
                plugins: {
                    datalabels: {
                        formatter: function (point: DataPoint) {
                            if (point.y == 0) {
                                return 'Ø';
                            }
                            return '';
                        },
                    },
                },
            };
        },
    },
    dateUtils: {
        chartDateformat: 'yyyy-MM-dd',
        displayDateFormat: displayDateFormat,
        getReportMinDate: function () {
            if (startOfToday().getMonth() >= 7) {
                return startOfYear(startOfToday());
            } else {
                return subMonths(startOfYear(startOfToday()), 6);
            }
        },
        getReportMaxDate: function () {
            const today = startOfToday();
            if (today.getMonth() >= 7) {
                return startOfMonth(endOfYear(startOfToday()));
            } else {
                return startOfMonth(subMonths(endOfYear(startOfToday()), 6));
            }
        },
    },
    currencyUtils: {
        currencyFormatter: new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
        }),
    },
    dataFormatting: {
        getInitialDataStructure: function (): InitialDataStructure {
            return {
                ESP: {
                    backgroundColor: ReportColors.ESP,
                    data: [],
                    label: 'ESP',
                    stack: 'Stack 0',
                },
                PCo: {
                    backgroundColor: ReportColors.PCo,
                    data: [],
                    label: 'PCo',
                    stack: 'Stack 0',
                },
                LPL: {
                    backgroundColor: ReportColors.LPL,
                    data: [],
                    label: 'LPL',
                    stack: 'Stack 0',
                },
                Crime: {
                    backgroundColor: ReportColors.Crime,
                    data: [],
                    label: 'Crime',
                    stack: 'Stack 0',
                },
                Cyber: {
                    backgroundColor: ReportColors.Cyber,
                    data: [],
                    label: 'Cyber',
                    stack: 'Stack 0',
                },
                Dummy: {
                    data: [],
                    label: 'Dummy',
                    stack: 'Stack 0',
                },
            };
        },
        formChartDataFromDataPoints: function (
            dataPoints: Immutable<DataPoint[]>,
            groupNames: Immutable<string[]>,
            indexOptions: IndexOptions,
        ) {
            const result = Report.dataFormatting.getInitialDataStructure();
            dataPoints.forEach((dataPoint, index) => {
                switch (groupNames[index]) {
                    case 'ESP':
                        result.ESP.data.unshift(dataPoint);
                        break;
                    case 'PCo':
                        result.PCo.data.unshift(dataPoint);
                        break;
                    case 'LPL':
                        result.LPL.data.unshift(dataPoint);
                        break;
                    case 'Crime':
                        result.Crime.data.unshift(dataPoint);
                        break;
                    case 'Cyber':
                        result.Cyber.data.unshift(dataPoint);
                        break;
                }
            });

            const chartReadyData = {
                datasets: [
                    result.Crime,
                    result.Cyber,
                    result.ESP,
                    result.LPL,
                    result.PCo,
                    result.Dummy,
                ],
            };
            const dummyMarkers = Report.dataFormatting.getDummyMarkers(
                chartReadyData,
                indexOptions,
            );

            result.Dummy.data = dummyMarkers;
            return {
                datasets: [
                    result.Crime,
                    result.Cyber,
                    result.ESP,
                    result.LPL,
                    result.PCo,
                    result.Dummy,
                ],
            };
        },
        getDummyMarkers: function (data: ChartReadyData, indexOptions: IndexOptions) {
            let date = new Date(Report.dateUtils.getReportMinDate());
            const maxDate = addDays(new Date(Report.dateUtils.getReportMaxDate()), 1);
            let possibleValues: string[] = [];
            while (isBefore(date, maxDate)) {
                possibleValues.push(format(date, Report.dateUtils.chartDateformat));
                date = addMonths(date, 1);
            }

            data.datasets.forEach((dataset) => {
                dataset.data.forEach((datapoint) => {
                    if (indexOptions.indexBy == 'x') {
                        possibleValues = possibleValues.filter(
                            (val) =>
                                new Date(datapoint.x).getUTCMonth() != new Date(val).getUTCMonth(),
                        );
                    } else {
                        possibleValues = possibleValues.filter(
                            (val) =>
                                new Date(datapoint.y).getUTCMonth() != new Date(val).getUTCMonth(),
                        );
                    }
                });
            });

            const dummyPoints: DataPoint[] = [];
            possibleValues.forEach((date) => {
                dummyPoints.push({
                    x: indexOptions.indexBy == 'x' ? date : 0,
                    y: indexOptions.indexBy != 'x' ? date : 0,
                });
            });

            return dummyPoints;
        },
    },
};
