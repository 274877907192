import { CenterLayout, CoverLayout, Image, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';

export function DigitalSignatureNotFound() {
    return (
        <CoverLayout>
            <StackLayout gap="32">
                <CenterLayout>
                    <Image inline={false} name="shotwell-1" />
                </CenterLayout>
                <CenterLayout centerText>
                    <StackLayout gap="32">
                        <Text as="h1" style="heading 1">
                            Looked everywhere...
                        </Text>
                        <Text as="p" style="body 1">
                            We could not find what you were looking for, because your token might be
                            invalid.
                            <br />
                            Please contact your broker to resolve the issue.
                        </Text>
                    </StackLayout>
                </CenterLayout>
            </StackLayout>
        </CoverLayout>
    );
}
