import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Nullable } from '@embroker/shotwell/core/types';

export interface CarrierLayer {
    layer_number: number;
    carrier: Carrier;
    tech_eo_limit: number;
    cyber_limit?: number;
    policy_aggregate: number;
    premium: number;
}

export interface Carrier {
    id: Nullable<UUID>;
    name: string;
}

export function calculateTotalUnderlyingLimit(
    carrierLayers: CarrierLayer[],
    primaryCarrierLayer: CarrierLayer,
): number {
    return (
        carrierLayers.reduce((accumulator, current) => {
            return accumulator + current.policy_aggregate;
        }, 0) + primaryCarrierLayer.policy_aggregate
    );
}
