import { Spinner } from '@embroker/ui-toolkit/v2';
import React, { useEffect, useMemo } from 'react';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { execute } from '@embroker/shotwell/core/UseCase';
import { CreateLawBundleApplication } from '../../../useCases/CreateLawBundleApplication';
import { AppTypeCodeListLawBundle } from '@app/shopping/types/enums';
import { PublishPurchaseIntentEvent } from '../../../useCases/PublishPurchaseIntentEvent';
import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { URI } from '@embroker/shotwell/core/types/URI';

export function LawBundleTier1QuestionnaireAutofill() {
    const { navigate } = useNavigation();
    const abortController = useMemo(() => new AbortController(), []);
    useEffect(() => () => abortController.abort(), [abortController]);

    useEffect(() => {
        (async () => {
            // Since the whole LawBundleWizard flow is dependant on the application being created at the 'tier-1' stage
            // the best way to implement the AB test without impacting the ovrall logic is to create the law bundle application programatically
            // before redirecting to the coverage-selection page
            const result = await execute(CreateLawBundleApplication, {
                abortSignal: abortController.signal,
            });

            execute(PublishPurchaseIntentEvent, { appTypes: [AppTypeCodeListLawBundle] }).then(
                (publishResult) => {
                    if (isErr(publishResult)) {
                        container.get<Logger>(Log).error(publishResult.errors);
                        return;
                    }
                },
            );

            if (isOK(result)) {
                const applicationId = result.value.applicationIdList[0];

                navigate(URI.build(`/shopping/law-bundle/coverage-selection`, { applicationId }));
            } else {
                navigate(URI.build('/'));
            }
        })();
    }, [abortController, navigate]);

    return <Spinner />;
}
