export const ESPDNOStandardURL =
    'https://embroker-public.s3.amazonaws.com/Policies/ESP+Standard+-+DO.pdf';
export const ESPDNOStandardNewInsurerURL =
    'https://embroker-public.s3.amazonaws.com/Policies/Policies+ESP+Standard+DO+V.2.pdf';
export const ESPDNOPlusURL = 'https://embroker-public.s3.amazonaws.com/Policies/ESP+Plus+-+DO.pdf';
export const ESPDNOPlusNewInsurerURL =
    'https://embroker-public.s3.amazonaws.com/Policies/Policies+ESP+Plus+DO+V.2.pdf';
export const ESPDNOOldURL = 'https://embroker-public.s3.amazonaws.com/Policies/5_MLI_D%26O.pdf';
export const ESPDNOStandardPostIPT2024URL =
    'https://embroker-public.s3.amazonaws.com/Policies/Policies+ESP+D%26O+Standard.pdf';
export const ESPDNOPlusPostIPT2024URL =
    'https://embroker-public.s3.amazonaws.com/Policies/Policies+ESP+D%26O+PLUS.pdf';
export const ESPEPLIStandardURL =
    'https://embroker-public.s3.amazonaws.com/Policies/ESP+Standard+-+EPLI.pdf';
export const ESPEPLIStandardNewInsurerURL =
    'https://embroker-public.s3.amazonaws.com/Policies/Policies+ESP+Standard+EPLI+V.2.pdf';
export const ESPEPLIPlusURL =
    'https://embroker-public.s3.amazonaws.com/Policies/ESP+Plus+-+EPLI.pdf';
export const ESPEPLIPlusNewInsurerURL =
    'https://embroker-public.s3.amazonaws.com/Policies/Policies+ESP+Plus+EPLI+V.2.pdf';
export const ESPEPLIOldURL = 'https://embroker-public.s3.amazonaws.com/Policies/6_MLI_EPLI.pdf';
export const ESPEPLIStandardPostIPT2024URL =
    'https://embroker-public.s3.amazonaws.com/Policies/Policies+ESP+EPL+Standard.pdf';
export const ESPEPLIPlusPostIPT2024URL =
    'https://embroker-public.s3.amazonaws.com/Policies/Policies+ESP+EPL+PLUS.pdf';
export const ESPFiduciaryStandardURL =
    'https://embroker-public.s3.amazonaws.com/Policies/ESP+Standard+-+FL.pdf';
export const ESPFiduciaryNewStandardURL =
    'https://embroker-public.s3.amazonaws.com/Policies/ESP%2BStandard%2B-%2BFL+V.2.pdf';
export const ESPEoCyberPlusURL =
    'https://embroker-public.s3.amazonaws.com/Policies/ESP+Plus+-+Tech.pdf';
export const ESPEoCyberStandardURL =
    'https://embroker-public.s3.amazonaws.com/Policies/ESP+Standard+-+Tech.pdf';
export const ESPTechEOCyberSplitPlusURL =
    'https://embroker-public.s3.amazonaws.com/Policies/ESP+Plus+-+Tech+V.2.pdf';
export const ESPTechCyberStandardPostIPT2024URL =
    'https://embroker-public.s3.amazonaws.com/Policies/ESP+Standard+-+Tech+V.2.pdf';
export const ESPTechCyberPlusPostIPT2024URL =
    'https://embroker-public.s3.amazonaws.com/Policies/ESP+Plus+-+Tech+V.3.pdf';

export const ESPGeneralTermsAndConditionsStandardURL =
    'https://embroker-public.s3.amazonaws.com/Policies/General+Terms+and+Conditions+-+Standard.pdf';

export const ESPGeneralTermsAndConditionsStandardNewInsurerURL =
    'https://embroker-public.s3.amazonaws.com/Policies/Policies+General+Terms+and+Conditions+Standard+V.2.pdf';

export const ESPGeneralTermsAndConditionsStandardAfterTechEOCyberSplitURL =
    'https://embroker-public.s3.amazonaws.com/Policies/Policies+General+Terms+and+Conditions+Standard+V.3.pdf';

export const ESPGeneralTermsAndConditionsPlusURL =
    'https://embroker-public.s3.amazonaws.com/Policies/General+Terms+and+Conditions+-+Plus.pdf';

export const ESPGeneralTermsAndConditionsPlusNewInsurerURL =
    'https://embroker-public.s3.amazonaws.com/Policies/Policies+General+Terms+and+Conditions+Plus+V.2.pdf';

export const ESPGeneralTermsAndConditionsPlusAfterTechEOCyberSplitURL =
    'https://embroker-public.s3.amazonaws.com/Policies/Policies+General+Terms+and+Conditions+Plus.pdf';

export const ESPGeneralTermsAndConditionsStandardPostIPT2024URL =
    'https://embroker-public.s3.amazonaws.com/Policies/Policies+General+Terms+and+Conditions+Standard+V.4+Solartis.pdf';

export const ESPGeneralTermsAndConditionsPlusPostIPT2024URL =
    'https://embroker-public.s3.amazonaws.com/Policies/Policies+General+Terms+and+Conditions+Plus+V.4+Solartis.pdf';
