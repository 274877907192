import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { InvalidAnnualTechFee } from '../../../errors';
import { WCGAQuote } from '../../entities/WCGAQuote';

export interface PurchaseResponse {
    policyId: UUID;
    policyDoneTaskId: UUID;
}

export interface RequoteRequest {
    applicationId: UUID;
    startDate: Date;
    includeBlanketWoS: boolean;
    getRecommended: boolean;
}

export interface WCGAQuoteRepository {
    requote(request: RequoteRequest): AsyncResult<UUID, OperationFailed | InvalidArgument>;

    purchase(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<
        PurchaseResponse,
        UnknownEntity | InvalidArgument | OperationFailed | InvalidAnnualTechFee
    >;

    submitForReview(applicationId: UUID): AsyncResult<void, InvalidArgument | OperationFailed>;

    getLast(applicationId: UUID): AsyncResult<WCGAQuote, InvalidArgument | OperationFailed>;
}

export const WCGAQuoteRepository = Symbol('WCGAQuoteRepository');
