import { TextButton } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { DocumentsItemComponentProp } from '@app/bundle/view/components/BundleQuoteLandingPage';

export function DownloadDocumentButton({
    handleTrigger,
    isDisabled,
    displayName,
}: DocumentsItemComponentProp) {
    return (
        <TextButton
            as="button"
            disabled={isDisabled}
            icon="download"
            onClick={handleTrigger}
            data-e2e="document-button"
        >
            {displayName}
        </TextButton>
    );
}
