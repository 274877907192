import { isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Spinner } from '@embroker/ui-toolkit/v2';
import React, { useEffect } from 'react';
import { Token } from '../../../../userOrg/types/Token';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { GetInvitationStatus } from '../../../useCases/GetInvitationStatus';
import { BrokerSignUp } from './BrokerSignUp';
import { InvitationExpired } from './InvitationExpired';

interface InvitationAcceptanceProps {
    readonly invitationToken: Token;
}

export function InvitationAcceptance({ invitationToken }: InvitationAcceptanceProps) {
    const { navigate } = useNavigation();
    const { isLoading, result } = useUseCase(GetInvitationStatus, {
        invitationToken,
    });

    useEffect(() => {
        if (result && isOK(result)) {
            if (result.value.status === 'accepted') {
                navigate('/login');
            }
        }
    }, [result, navigate]);

    if (result === undefined || isLoading) {
        return <Spinner />;
    }

    if (isErr(result)) {
        return <ErrorPage errors={result.errors} />;
    }

    if (result.value.status === 'expired') {
        return <InvitationExpired />;
    }

    if (result.value.status === 'pending') {
        return <BrokerSignUp invitationToken={invitationToken} />;
    }

    return null;
}
