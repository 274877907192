import React from 'react';
import { BOPHartfordPaymentEvent } from '@app/quote/hartford-bop/entities/BOPHartfordQuote';
import { Loader, useModal } from '@embroker/ui-toolkit/v2';
import { ExitConfirmationModal } from '@app/shopping/view/components/ExitConfirmationModal';
import { useNavigation } from '@app/view/hooks/useNavigation';

export interface BOPHartfordPaymentProps {
    paymentUrl: string;
    onPaymentCallback: (data: BOPHartfordPaymentEvent) => void;
    controller: AbortController;
}

export function PaymentPage({
    paymentUrl,
    onPaymentCallback,
    controller,
}: BOPHartfordPaymentProps) {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [keyCounter, setKeyCounter] = React.useState(0);
    const { navigate } = useNavigation();

    const exitConfirmationModal = useModal();

    const parseMessage = (event: MessageEvent): BOPHartfordPaymentEvent | null => {
        try {
            return JSON.parse(event.data) as BOPHartfordPaymentEvent;
        } catch (e) {
            return null;
        }
    };

    window.addEventListener(
        'message',
        (event) => {
            const message = parseMessage(event);

            if (!message) {
                return;
            }

            if (!message.type) return;

            if (
                message.type === 'Error' &&
                message.messages.errorMessage === 'Expired Claim Check Token'
            ) {
                console.log('Expired Claim Check Token');
                return;
            }

            if (message.messages?.bopErrorMessage?.includes('Missing Billing Details For BOP')) {
                setKeyCounter(keyCounter + 1);
                return;
            }

            if (message.type == 'Cancel') {
                exitConfirmationModal.show();
                return;
            }

            if (message.type == 'AppLoaded') {
                if (isLoaded) return;
                setIsLoaded(true);
                return;
            }

            onPaymentCallback(message);
        },
        { signal: controller.signal },
    );

    const onConfirmExitApplication = () => {
        controller.abort();
        navigate('/summary');
    };

    const onCancel = () => {
        setIsLoaded(false);
        setKeyCounter(keyCounter + 1);
    };

    return (
        <React.Fragment>
            <iframe
                key={keyCounter}
                style={{ visibility: isLoaded ? 'visible' : 'hidden' }}
                allowFullScreen
                src={paymentUrl}
                height="800px"
                width="100%"
            ></iframe>

            {!isLoaded && <Loader />}

            <ExitConfirmationModal
                onCancel={onCancel}
                modal={exitConfirmationModal}
                onExitApplication={onConfirmExitApplication}
            />
        </React.Fragment>
    );
}
