import React, { useCallback, useEffect, useMemo } from 'react';
import { useWizardForm } from '../../../../../view/hooks/useWizardForm';
import { isSameDay, isValid, startOfDay, startOfToday } from 'date-fns';
import {
    Button,
    ColumnLayout,
    Form,
    Immutable,
    StackLayout,
    Text,
    TextAreaInput,
    TextButton,
    WizardLayout,
} from '@embroker/ui-toolkit/v2';
import { createForm } from '@embroker/shotwell/view/hooks/useForm';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { execute } from '@embroker/shotwell/core/UseCase';
import { IntakeAdditionalInfo } from '../../../../useCases/IntakeAdditionalInfo';
import { isErr, Success } from '@embroker/shotwell/core/types/Result';
import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { AppTypeCodeListEverestLawyersProfessionalLiability } from '@app/shopping/types/enums';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { FileUpload } from '../../../../../documents/view/FileUpload';
import { toSelectCurrencyOption } from '../../../../../quote/toSelectCurrencyOption';

interface LPLAdditionalInfoFormData {
    applicationId: UUID;
    additionalFiles: Immutable<FileUpload[]>;
    limit: number;
    aggregateLimit: number;
    perClaimDeductible: number;
    firstDollarDeductible: boolean;
    additionalClaimsOptions: string;
    effectiveDate: Date;
    retroactiveDate: Date;
    additionalInfo: string;
}

function createLPLAdditionalInfoForm() {
    return createForm<LPLAdditionalInfoFormData>({
        fields: {
            applicationId: {
                type: 'hidden',
                validator: UUID.schema,
            },
            additionalFiles: {
                type: 'hidden',
                validator: Joi.array(),
            },
            limit: {
                type: 'select',
                validator: Joi.number().required(),
                formatValidationError: (error) => {
                    switch (error.details.validator) {
                        case 'any.required':
                            return 'You must select limit.';
                        default:
                            return error.message;
                    }
                },
            },
            aggregateLimit: {
                type: 'select',
                validator: Joi.number().required(),
                formatValidationError: (error) => {
                    switch (error.details.validator) {
                        case 'any.required':
                            return 'You must select aggregate limit.';
                        default:
                            return error.message;
                    }
                },
            },
            perClaimDeductible: {
                type: 'select',
                validator: Joi.number().required(),
                formatValidationError: (error) => {
                    switch (error.details.validator) {
                        case 'any.required':
                            return 'You must select deductible.';
                        default:
                            return error.message;
                    }
                },
            },
            firstDollarDeductible: {
                type: 'select',
                validator: Joi.boolean().required(),
                formatValidationError: (error) => {
                    switch (error.details.validator) {
                        case 'any.required':
                            return 'You must select option.';
                        default:
                            return error.message;
                    }
                },
            },
            additionalClaimsOptions: {
                type: 'select',
                validator: Joi.string().required(),
                formatValidationError: (error) => {
                    switch (error.details.validator) {
                        case 'any.required':
                            return 'You must select option.';
                        default:
                            return error.message;
                    }
                },
            },
            effectiveDate: {
                type: 'date',
                validator: Joi.date().min(startOfToday()).required(),
                formatValidationError: (error) => {
                    switch (error.details.validator) {
                        case 'date.min':
                            return 'Date cannot be in the past.';
                        case 'any.required':
                            return 'You must enter a date.';
                        default:
                            return error.message;
                    }
                },
            },
            retroactiveDate: {
                type: 'date',
                validator: Joi.date().max(startOfToday()).required(),
                formatValidationError: (error) => {
                    switch (error.details.validator) {
                        case 'date.max':
                            return 'Date cannot be in the future.';
                        case 'any.required':
                            return 'You must enter a date.';
                        default:
                            return error.message;
                    }
                },
            },
            additionalInfo: {
                type: 'textarea',
                validator: Joi.string().optional(),
            },
        },
        formatSubmitErrors(errors) {
            if (errors.length > 0) {
                return ['Oops! Something went wrong. Please try again later.'];
            }

            return [];
        },
        submit: async (data: LPLAdditionalInfoFormData) => {
            const fileKeys: string[] = [];
            data.additionalFiles.forEach((file) => {
                if (file.s3FileKey !== null) {
                    fileKeys.push(file.s3FileKey);
                }
            });
            const result = await execute(IntakeAdditionalInfo, {
                appType: AppTypeCodeListEverestLawyersProfessionalLiability,
                additionalData: {
                    isDnoSelected: false,
                    isEplSelected: false,
                    isFiduciarySelected: false,
                    isEnoSelected: false,
                    lplLimit: data.limit,
                    lplAggregateLimit: data.aggregateLimit,
                    lplDeductible: data.perClaimDeductible,
                    isLplFirstDollarDeductible: data.firstDollarDeductible,
                    lplAdditionalClaimsOption: data.additionalClaimsOptions,
                    lplRetroactiveDate: data.retroactiveDate,
                    effectiveDate: data.effectiveDate,
                    additionalInfo: data.additionalInfo,
                },
                applicationId: data.applicationId,
                additionalFiles: fileKeys,
            });

            if (isErr(result)) {
                container.get<Logger>(Log).error(result.errors);
            }

            return Success();
        },
    });
}

const limitOptions = [100_000, 200_000, 250_000, 500_000, 1_000_000, 2_000_000];

const aggregateLimitOptions = [1_000_000, 2_000_000, 3_000_000];

const deductibleOptions = [1_000, 2_000, 2_500, 3_000, 4_000, 5_000, 10_000];

const firstDollarDeductibleOptions = [
    { title: 'No', value: false },
    { title: 'Yes', value: true },
];

const additionalClaimsOptions = [
    {
        title: 'Claims Expense Inside of Limits',
        value: 'ClaimsExpenseTypeInsideOfLimits',
    },
    {
        title: 'Claims Expense in Addition to Limits',
        value: 'ClaimsExpenseTypeInAdditionToLimits',
    },
    {
        title: 'Separate Claims Expenses Coverage',
        value: 'ClaimsExpenseTypeSeparateClaimsExpenses',
    },
];

interface LPLAdditionalInfoProps {
    firstBackAction: () => void;
    appId: UUID;
    filesUploaded: FileUpload[];
    openSuccessModal: () => void;
}

export function LPLAdditionalInfo({
    firstBackAction,
    appId,
    filesUploaded,
    openSuccessModal,
    ...props
}: LPLAdditionalInfoProps) {
    const additionalInfoForm = useMemo(() => {
        return createLPLAdditionalInfoForm();
    }, []);

    const { value, fields, setValue, submit, status } = useWizardForm(additionalInfoForm, {
        pages: [
            {
                name: 'dateInfo',
                fields: [
                    'limit',
                    'aggregateLimit',
                    'perClaimDeductible',
                    'additionalClaimsOptions',
                    'firstDollarDeductible',
                    'effectiveDate',
                    'retroactiveDate',
                    'additionalInfo',
                ],
            },
        ],
        keepUrl: true,
    });

    useEffect(() => {
        setValue({
            ...value,
            applicationId: appId,
            additionalFiles: filesUploaded,
        });
    }, [appId, setValue, value, filesUploaded]);

    useEffect(() => {
        if (status === 'submitted') {
            openSuccessModal();
        }
    }, [openSuccessModal, status]);

    const handleEffectiveDateChange = useCallback(
        (event: { target: { value: string; date: Date } }) => {
            const newDate = startOfDay(event.target.date);
            const isNotDateValid =
                !isValid(newDate) ||
                (value.effectiveDate !== null && isSameDay(value.effectiveDate, newDate));
            if (isNotDateValid) {
                return;
            }

            setValue({
                ...value,
                effectiveDate: newDate,
            });
        },
        [setValue, value],
    );

    const handleRetroactiveDateChange = useCallback(
        (event: { target: { value: string; date: Date } }) => {
            const newDate = startOfDay(event.target.date);
            const isNotDateValid =
                !isValid(newDate) ||
                (value.retroactiveDate !== null && isSameDay(value.retroactiveDate, newDate));
            if (isNotDateValid) {
                return;
            }

            setValue({
                ...value,
                retroactiveDate: newDate,
            });
        },
        [setValue, value],
    );

    return (
        <StackLayout gap="8" {...props}>
            <Text style="heading 4">Additional information required</Text>
            <StackLayout gap="8">
                <Text style="default">
                    Please answer following questions in order to provide you with the desired quote
                    option:
                </Text>
                <StackLayout gap="32">
                    <StackLayout gap="8">
                        <StackLayout gap="8">
                            <Form.Field
                                inputProps={{
                                    items: limitOptions,
                                    value: fields.limit.props.value,
                                    onChange: (event: { target: { value: number } }) => {
                                        setValue({
                                            ...value,
                                            limit: event.target.value,
                                        });
                                    },
                                    createNewItem: toSelectCurrencyOption,
                                }}
                                type={fields.limit.type}
                                label="Limit"
                                messages={fields.limit.messages}
                            />
                            <Form.Field
                                inputProps={{
                                    items: aggregateLimitOptions,
                                    value: fields.aggregateLimit.props.value,
                                    onChange: (event: { target: { value: number } }) => {
                                        setValue({
                                            ...value,
                                            aggregateLimit: event.target.value,
                                        });
                                    },
                                    createNewItem: toSelectCurrencyOption,
                                }}
                                type={fields.aggregateLimit.type}
                                label="Aggregate Limit"
                                messages={fields.aggregateLimit.messages}
                            />
                            <Form.Field
                                inputProps={{
                                    items: deductibleOptions,
                                    value: fields.perClaimDeductible.props.value,
                                    onChange: (event: { target: { value: number } }) => {
                                        setValue({
                                            ...value,
                                            perClaimDeductible: event.target.value,
                                        });
                                    },
                                    createNewItem: toSelectCurrencyOption,
                                }}
                                type={fields.perClaimDeductible.type}
                                label="Per Claim Deductible"
                                messages={fields.perClaimDeductible.messages}
                            />
                            <Form.Field
                                inputProps={{
                                    items: firstDollarDeductibleOptions,
                                    value: fields.firstDollarDeductible.props.value,
                                    onChange: (event: { target: { value: boolean } }) => {
                                        setValue({
                                            ...value,
                                            firstDollarDeductible: event.target.value,
                                        });
                                    },
                                }}
                                type={fields.firstDollarDeductible.type}
                                label="First dollar / damages only deductible"
                                messages={fields.firstDollarDeductible.messages}
                            />
                            <Form.Field
                                inputProps={{
                                    items: additionalClaimsOptions,
                                    value: fields.additionalClaimsOptions.props.value,
                                    onChange: (event: { target: { value: string } }) => {
                                        setValue({
                                            ...value,
                                            additionalClaimsOptions: event.target.value,
                                        });
                                    },
                                }}
                                type={fields.additionalClaimsOptions.type}
                                label="Additional claims expense options"
                                messages={fields.additionalClaimsOptions.messages}
                            />
                        </StackLayout>
                        <ColumnLayout gap="8">
                            <Form.Field
                                inputProps={{
                                    value: fields.effectiveDate.props.value,
                                    onChange: handleEffectiveDateChange,
                                }}
                                type={fields.effectiveDate.type}
                                label="Desired effective Date"
                                messages={fields.effectiveDate.messages}
                            />
                            <Form.Field
                                inputProps={{
                                    value: fields.retroactiveDate.props.value,
                                    onChange: handleRetroactiveDateChange,
                                }}
                                type={fields.retroactiveDate.type}
                                label="Retroactive Date"
                                messages={fields.retroactiveDate.messages}
                            />
                        </ColumnLayout>
                        <TextAreaInput
                            label="Additional information (optional)"
                            {...fields.additionalInfo.props}
                        />
                    </StackLayout>
                    <WizardLayout.Actions>
                        <Button onClick={submit}>{'Finish'}</Button>
                        <TextButton onClick={firstBackAction}>Back</TextButton>
                    </WizardLayout.Actions>
                </StackLayout>
            </StackLayout>
        </StackLayout>
    );
}
