import {
    API,
    InsuranceApplication,
    Quote,
    WcgaQuoteDetails,
    WcgaQuoteOptions,
} from '@embroker/shotwell-api/app';
import { isAPIError } from '@embroker/shotwell-api/errors';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    isOK,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { State } from '@embroker/shotwell/core/types/StateList';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { startOfToday } from 'date-fns';
import { QuotingEngineWCGA } from '../../../../shopping/types/enums';
import { InvalidAnnualTechFee } from '../../../errors';
import { QuoteExpiration } from '../../../types/QuoteExpiration';
import { QuoteIdMap } from '../../../types/QuoteList';
import { WCGAQuote } from '../../entities/WCGAQuote';
import { WCGAUserInfo } from '../../types/WCGAUserInfo';
import { PurchaseResponse, RequoteRequest, WCGAQuoteRepository } from './index';

interface QuestionnaireData {
    first_name: string;
    last_name: string;
    title: string;
    state: string;
    company_name: string;
}

@injectable()
export class APIWCGAQuoteRepository implements WCGAQuoteRepository {
    public async requote(
        request: RequoteRequest,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed> {
        const appResponse = await API.request('shopping/application', {
            id: request.applicationId,
        });

        if (isErr(appResponse)) {
            return handleOperationFailure(appResponse);
        }

        const quoteResponse = await API.request('shopping/create_quote_task', {
            application_id: request.applicationId,
            quote_options: {
                wcga: {
                    start_date: request.startDate,
                    include_blanket_wos: request.includeBlanketWoS,
                    recommended_blanket_wos: request.getRecommended,
                },
            },
        });

        if (isErr(quoteResponse)) {
            return handleOperationFailure(quoteResponse);
        }
        return Success(quoteResponse.value.task_id);
    }

    public async purchase(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<
        PurchaseResponse,
        UnknownEntity | InvalidArgument | OperationFailed | InvalidAnnualTechFee
    > {
        const quoteIdMap: QuoteIdMap = { [QuotingEngineWCGA]: quoteId };
        const purchaseResponse = await API.request('shopping/purchase', {
            id: applicationId,
            quote_id_map: quoteIdMap,
        });

        if (isErr(purchaseResponse)) {
            const error = purchaseResponse.errors[0];
            if (isAPIError(error) && error.details.name === 'invalid_annual_tech_fee') {
                return Failure(InvalidAnnualTechFee());
            }
            return handleOperationFailure(purchaseResponse);
        }

        const { policy_issuance_task_id: policyIssuanceTaskId, policyId } = purchaseResponse.value;

        return Success({
            policyId: policyId,
            policyDoneTaskId: policyIssuanceTaskId,
        });
    }

    public async submitForReview(
        applicationId: UUID,
    ): AsyncResult<void, InvalidArgument | OperationFailed> {
        const response = await API.request('shopping/submit_for_review', {
            application_id: applicationId,
        });

        if (isErr(response)) {
            return handleOperationFailure(response);
        }

        return Success();
    }

    public async getLast(
        applicationId: UUID,
    ): AsyncResult<WCGAQuote, InvalidArgument | OperationFailed> {
        const applicationResponse = await API.request('shopping/application', {
            id: applicationId,
        });

        if (isErr(applicationResponse)) {
            return handleOperationFailure(applicationResponse);
        }

        const application = applicationResponse.value;
        const lastQuote = this.extractLastQuote(application);

        const quoteOptions = lastQuote.options.wcga ?? ({} as WcgaQuoteOptions);
        const quoteDetails = lastQuote.details.wcga ?? ({} as WcgaQuoteDetails);

        const questionnaireData = application.questionnaire_data;
        // This check is intentional to test both null and undefined
        if (questionnaireData == null) {
            return Failure(
                InvalidArgument({ argument: 'questionnaire_data', value: questionnaireData }),
            );
        }
        let questionnaireDataParsed: QuestionnaireData;
        try {
            questionnaireDataParsed = JSON.parse(questionnaireData);
        } catch (e) {
            return Failure(OperationFailed({ message: 'Failed parsing questionnaire data' }));
        }
        const userInfo = getUserInfoFromQuestionnaireData(questionnaireDataParsed);

        const quoteEntityResult = await WCGAQuote.create({
            isIndication: lastQuote.is_indication,
            id: lastQuote.id,
            totalPremium: lastQuote.total_premium,
            annualTechnologyFee: lastQuote.annual_technology_fee,
            totalPayable: lastQuote.total_payable,
            applicationId,
            status: lastQuote.accepted_at ? 'accepted' : 'draft',
            options: {
                effectiveDate: quoteOptions?.start_date,
                blanketWoS: quoteOptions?.include_blanket_wos,
                isWosRecommended: quoteOptions?.recommended_blanket_wos,
            },
            details: {
                insurerName: quoteDetails?.insurer_name,
                isOFACRejected: false,
            },
            userInfo: userInfo,
            isRenewal: application.renewed_policy_id_list.length > 0,
            daysToExpire: QuoteExpiration.getDaysLeftUntilExpiration({
                quotingEngine: application.quoting_engine || undefined,
                applicationStatus: application.status,
                validUntil: application.valid_until,
                quoteEffectiveDate: quoteOptions?.start_date,
                today: startOfToday(),
                isBroker: application.brokerage_id !== null,
            }),
        });

        if (isErr(quoteEntityResult)) {
            return handleOperationFailure(quoteEntityResult);
        }
        return Success(quoteEntityResult.value);
    }

    private extractLastQuote(application: Immutable<InsuranceApplication>): Immutable<Quote> {
        return application.quote_list.reduce((previous, current) => {
            if (previous.created_at <= current.created_at) {
                return current;
            }
            return previous;
        });
    }
}

function getUserInfoFromQuestionnaireData(questionnaire: QuestionnaireData): WCGAUserInfo {
    let stateCode: State | undefined;

    if (typeof questionnaire.state === 'string' && questionnaire.state.length > 0) {
        const result = State.validate(questionnaire.state);
        if (isOK(result)) {
            stateCode = result.value;
        }
    }

    return {
        fullName: `${questionnaire.first_name} ${questionnaire.last_name}`,
        title: questionnaire.title,
        state: stateCode,
    };
}
