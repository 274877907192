import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { State } from '@embroker/shotwell/core/types/StateList';
import { WCClassCode } from '../../types/WCClassCode';
import { NAICSCode } from './types';

export interface WCDataRepository {
    getAllowedClassCodes(state: State): Immutable<WCClassCode[]>;
    getDefaultClassCodes(naicsCode: NAICSCode, state: State): Immutable<string[]>;
    setEmployeeDataToSessionStorage(employeeData: Record<string, { $: {}[] }>): void;
    setActiveCodesIdToSessionStorage(): void;
    getOfficerInclusion(state: State, classCode: string): boolean;
    loadData(): AsyncResult;
}

export const WCDataRepository = Symbol('WCDataRepository');
