import { Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { BundleQuoteOrganization } from '../../types/BundleQuoteOrganization';
import { BundleQuoteCardLayout } from './BundleQuoteCardLayout.view';

export interface BundleQuoteAddressCardProps {
    organizationInfo: BundleQuoteOrganization;
}

export function BundleQuoteAddressCard({ organizationInfo }: BundleQuoteAddressCardProps) {
    return (
        <BundleQuoteCardLayout>
            <Text style="heading 5" data-e2e="company-name">
                {organizationInfo.companyName}
            </Text>

            <Text style="body 1" data-e2e="address-line-1">
                {organizationInfo.address.addressLine1}
            </Text>
            {organizationInfo.address.addressLine2 ? (
                <Text style="body 1" data-e2e="address-line-2">
                    {organizationInfo.address.addressLine2}
                </Text>
            ) : null}
            <Text style="body 1" data-e2e="address-city-state-zip">
                {organizationInfo.address.city}, {organizationInfo.address.state},{' '}
                {organizationInfo.address.zip}
            </Text>
        </BundleQuoteCardLayout>
    );
}
