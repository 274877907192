import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { LPLQuote } from '../entities/LPLQuote';
import { LPLQuoteRepository } from '../repositories/LPLQuoteRepository';
import { LPLQuoteExtended } from '../types/LPLQuoteExtended';

export interface LPLRequestHigherLimitsRequest {
    lplQuote: LPLQuote;
}

export interface LPLRequestHigherLimit extends UseCase {
    execute(
        request: LPLRequestHigherLimitsRequest,
    ): AsyncResult<LPLQuoteExtended, InvalidArgument | OperationFailed>;
}

@injectable()
export class LPLRequestHigherLimitUseCase extends UseCase implements LPLRequestHigherLimit {
    public static type = Symbol('LPLQuote/RequestHigherLimit');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(LPLQuoteRepository) private lplQuoteRepository: LPLQuoteRepository,
    ) {
        super(eventBus);
    }

    async execute({
        lplQuote,
    }: LPLRequestHigherLimitsRequest): AsyncResult<
        LPLQuoteExtended,
        InvalidArgument | OperationFailed
    > {
        const requestHigherLimitsResult = await this.lplQuoteRepository.requestHigherLimit(
            lplQuote.applicationId,
        );

        if (isErr(requestHigherLimitsResult)) {
            return Failure(
                OperationFailed({
                    message: 'Failed higher limits request',
                }),
            );
        }

        const getCurrentQuoteResult = await this.lplQuoteRepository.getLastLPLQuote(
            lplQuote.applicationId,
        );
        if (isErr(getCurrentQuoteResult)) {
            return getCurrentQuoteResult;
        }

        return Success(getCurrentQuoteResult.value);
    }
}

export const LPLRequestHigherLimit: UseCaseClass<LPLRequestHigherLimit> =
    LPLRequestHigherLimitUseCase;
