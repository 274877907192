import { errorCodes } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { ErrorLike, ErrorObject } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';

export const ErrorCode = errorCodes({
    OneOrMoreWritingCompanyNotFound: 0x7400,
    GetWritingCompanyListError: 0x7401,
});

export type OneOrMoreWritingCompanyNotFound = ErrorObject<
    typeof ErrorCode.OneOrMoreWritingCompanyNotFound,
    { writingCompanyIdList: Immutable<UUID[]> }
>;

export function OneOrMoreWritingCompanyNotFound(
    writingCompanyIdList: Immutable<UUID[]>,
): Immutable<OneOrMoreWritingCompanyNotFound> {
    return {
        name: 'OneOrMoreWritingCompanyNotFound',
        code: ErrorCode.OneOrMoreWritingCompanyNotFound,
        message: 'One or more Writing Company from the list not found.',
        details: { writingCompanyIdList },
    };
}

export type GetWritingCompanyListError = ErrorObject<
    typeof ErrorCode.GetWritingCompanyListError,
    {
        writingCompanyIdList: Immutable<UUID[]>;
        innerErrorList: Immutable<ErrorLike[]>;
    }
>;

export function GetWritingCompanyListError(
    writingCompanyIdList: Immutable<UUID[]>,
    innerErrorList: Immutable<ErrorLike[]>,
): Immutable<GetWritingCompanyListError> {
    return {
        name: 'GetWritingCompanyListError',
        code: ErrorCode.GetWritingCompanyListError,
        message: 'Getting Writing Company List Failed.',
        details: { writingCompanyIdList, innerErrorList },
    };
}
