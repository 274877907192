import { defineEntityValidator, entity } from '@embroker/shotwell/core/entity/Entity';
import { commonQuoteProps, Quote } from '../../../../quote/entities/Quote';
import { CowbellCyberQuoteDetails } from '../types/CowbellCyberQuoteDetails';
import { CowbellCyberQuoteOptions } from '../types/CowbellCyberQuoteOptions';

export interface CowbellCyberQuote extends Quote {
    readonly options: CowbellCyberQuoteOptions;
    readonly details: CowbellCyberQuoteDetails;
}

export const CowbellCyberQuote = entity<CowbellCyberQuote, Quote>({
    validator: defineEntityValidator<CowbellCyberQuote>({
        ...commonQuoteProps,
        options: CowbellCyberQuoteOptions.schema.required(),
        details: CowbellCyberQuoteDetails.schema.required(),
    }),
    inherits: Quote,
});
