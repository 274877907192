import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ESPEndorsementRepository } from '../repositories/ESPEndorsementRepository';
import { ESPEndorsementUserData } from '../types/ESPEndorsementUserData';

export interface GetESPEndorsementUserData extends UseCase {
    execute(request: void): AsyncResult<ESPEndorsementUserData, InvalidArgument | OperationFailed>;
}

@injectable()
class GetESPEndorsementUserDataUseCase extends UseCase implements GetESPEndorsementUserData {
    public static type = Symbol('ESPEndorsement/GetESPEndorsementUserData');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ESPEndorsementRepository)
        private espEndorsementRepository: ESPEndorsementRepository,
    ) {
        super(eventBus);
    }

    public async execute(): AsyncResult<ESPEndorsementUserData, InvalidArgument | OperationFailed> {
        return await this.espEndorsementRepository.loadUserData();
    }
}

export const GetESPEndorsementUserData: UseCaseClass<GetESPEndorsementUserData> =
    GetESPEndorsementUserDataUseCase;
