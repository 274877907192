import { inject, injectable } from '@embroker/shotwell/core/di';
import { Aborted, InvalidArgument, OperationFailed, Timeout } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { CalculateSKU } from '../../../analytics/useCases/CalculateSKU';
import { ApplicationFetchFailed, QuestionnaireParsingFailed } from '../../errors';
import { ESPQuote } from '../entities/ESPQuote';
import { ConfigFetchFailed } from '../errors';
import { ESPQuoteRepository } from '../repositories/ESPQuoteRepository';
import { ESPQuotePurchased } from '../types/ESPQuoteDetails';
import { ESPQuoteOptions } from '../types/ESPQuoteOptions';
import { RequoteESP } from './RequoteESP';

const fromQuoteToQuoteOptions = (quote: ESPQuote): ESPQuoteOptions => ({
    effectiveDate: quote.options.effectiveDate,
    coverages: quote.details.coverages,
});

export interface PurchaseESPRequest {
    quote: ESPQuote;
    abortSignal: AbortSignal;
}

export interface PurchaseESPResponse {
    policyId: UUID;
    policyDoneTaskId: UUID;
}

export interface PurchaseESP extends UseCase {
    execute(
        request: PurchaseESPRequest,
    ): AsyncResult<
        PurchaseESPResponse,
        | InvalidArgument
        | OperationFailed
        | ConfigFetchFailed
        | Timeout
        | Aborted
        | ApplicationFetchFailed
        | QuestionnaireParsingFailed
    >;
}

@injectable()
class PurchaseESPUseCase extends UseCase implements PurchaseESP {
    public static type = Symbol('ESPQuote/PurchaseESP');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ESPQuoteRepository) private espQuoteRepository: ESPQuoteRepository,
        @inject(RequoteESP.type) private requoteESP: RequoteESP,
        @inject(CalculateSKU.type) private calculateSKU: CalculateSKU,
    ) {
        super(eventBus);
    }

    public async execute({
        quote,
        abortSignal,
    }: PurchaseESPRequest): AsyncResult<
        PurchaseESPResponse,
        | InvalidArgument
        | OperationFailed
        | ConfigFetchFailed
        | Timeout
        | Aborted
        | ApplicationFetchFailed
        | QuestionnaireParsingFailed
    > {
        // Currently fileKey is the only indicator if the quote - user is seeing was created using the solartis create quote or
        // solartis rate API. If the last quote user created was made using the create quote API we have the fileKey, therefore
        // we don't need to call Requote usecase.
        // In couple of months this logic should be deleted as all (non expired) quotes will have file keys.
        if (!quote.fileKey) {
            const quoteOptions = fromQuoteToQuoteOptions(quote);
            const requoteResult = await this.requoteESP.execute({
                applicationId: quote.applicationId,
                espQuoteOptions: quoteOptions,
                abortSignal: abortSignal,
            });
            if (isErr(requoteResult)) {
                return requoteResult;
            }
            quote = requoteResult.value;
        }
        const purchaseResult = await this.espQuoteRepository.purchaseESP(
            quote.applicationId,
            quote.id,
        );

        if (isErr(purchaseResult)) {
            return handleOperationFailure(purchaseResult);
        }

        const skuResult = await this.calculateSKU.execute({
            event: 'purchase',
            applicationId: quote.applicationId,
        });

        const event: ESPQuotePurchased = {
            origin: 'ESPQuote',
            name: 'Purchased',
            id: quote.id,
            applicationId: quote.applicationId,
            totalPremium: quote.totalPremium,
            createdAt: new Date(Date.now()),
            sku: skuResult.value,
            isRenewal: false,
        };

        this.eventBus.publish(event);

        return Success({
            policyId: purchaseResult.value.policyId,
            policyDoneTaskId: purchaseResult.value.policyDoneTaskId,
        });
    }
}

export const PurchaseESP: UseCaseClass<PurchaseESP> = PurchaseESPUseCase;
