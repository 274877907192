import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { ServiceDefinition } from './ServiceDefinition';
import { Immutable } from '@embroker/shotwell/core/types';

export interface ManifestDefinition {
    effectiveDate: Date;
    versions: Immutable<ServiceDefinition[]>;
}

export const ManifestDefinition = {
    ...defineValidator<ManifestDefinition>(
        Joi.object({
            effectiveDate: Joi.date().required(),
            versions: Joi.array().items(ServiceDefinition.schema).required(),
        }),
    ),
    create(manifestDefinition: ManifestDefinition) {
        return ManifestDefinition.validate(manifestDefinition);
    },
};
