import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { ErrorPage } from '../../../view/components/ErrorPage.view';
import { map, NaviRequest, redirect, route } from 'navi';
import * as React from 'react';
import { Token } from '../../../userOrg/types/Token';
import { AppContext } from '../../../view/AppContext';
import { withWizard } from '../../../view/routes';
import { RouteDefinitions } from '../../../view/routes/Route';
import { GetSignatureDocument } from '../../useCases/GetSignatureDocument';
import { DigitalSignatureAlreadySigned } from '../components/clientSignatures/AlreadySigned';
import { DigitalSignature } from '../components/clientSignatures/DigitalSignature';
import { DigitalSignatureNotAllowed } from '../components/clientSignatures/NotAllowed';
import { DigitalSignatureNotFound } from '../components/clientSignatures/NotFound';
import { BrokerApplication } from '../components/ApplicationInCreation/ApplicationInCreation';
import { ReviewApplication } from '../components/shareableApplications/clientApplicationEdit/ReviewApplication';
import { ReviewApplicationThankYou } from '../components/shareableApplications/clientApplicationEdit/ThankYou';
import { ShareableApplicationError } from '../components/shareableApplications/Error';
import { ShareableApplicationNotAllowed } from '../components/shareableApplications/NotAllowed';
import { ShareableApplicationNotFound } from '../components/shareableApplications/NotFound';
import { ShareableApplicationProgressSaved } from '../components/shareableApplications/ProgressSaved';
import { ShareableApplication } from '../components/shareableApplications/ShareableApplication';
import { ShareableApplicationThankYou } from '../components/shareableApplications/ThankYou';
import { InvitationAcceptance } from '../components/signUp/InvitationAcceptance';
import { PageNotFound } from '@embroker/shotwell/view/components/PageNotFound';
import { CreatingApplicationErrorPage } from '../components/shareableApplications/CreatingApplicationErrorPage';
import { DigitalSignatureManagingBrokerNotFound } from '../components/clientSignatures/ManagingBrokerNotFound';

const SHAREABLE_APPLICATION_TITLE = 'Embroker | Shareable application';
const REVIEW_APPLICATION_TITLE = 'Embroker | Review your application';
const RENEWAL_APPLICATION_TITLE = 'Embroker | Renew your coverages';

export const routes: RouteDefinitions<AppContext> = {
    '/renewal/application': {
        auth: 'none',
        isOrganizationSpecific: false,
        handler: map((request: NaviRequest<AppContext>) => {
            const { token } = request.query;

            return route({
                title: RENEWAL_APPLICATION_TITLE,
                view: <ShareableApplication token={token} />,
                data: { fullscreen: true },
            });
        }),
    },

    '/review/application/thank-you': {
        auth: 'none',
        isOrganizationSpecific: false,
        handler: map(() => {
            return route({
                title: REVIEW_APPLICATION_TITLE,
                view: <ReviewApplicationThankYou />,
                data: { fullscreen: true },
            });
        }),
    },
    '/review/application': {
        auth: 'none',
        isOrganizationSpecific: false,
        handler: map((request: NaviRequest<AppContext>) => {
            const { token } = request.query;

            return route({
                title: REVIEW_APPLICATION_TITLE,
                view: <ReviewApplication token={token} pageId="basic_info" />,
                data: { fullscreen: true },
            });
        }),
    },

    '/shareable/application/error': {
        auth: 'none',
        isOrganizationSpecific: false,
        handler: map(() => {
            return route({
                title: SHAREABLE_APPLICATION_TITLE,
                view: <ShareableApplicationError />,
                data: { fullscreen: true },
            });
        }),
    },
    '/shareable/application/info-error': {
        auth: 'any',
        isOrganizationSpecific: false,
        handler: map(() => {
            return route({
                title: SHAREABLE_APPLICATION_TITLE,
                view: <CreatingApplicationErrorPage />,
                data: { fullscreen: true },
            });
        }),
    },
    '/broker/application/error': {
        auth: 'default',
        isOrganizationSpecific: false,
        handler: map(() => {
            return route({
                title: SHAREABLE_APPLICATION_TITLE,
                view: <CreatingApplicationErrorPage />,
                data: { fullscreen: true },
            });
        }),
    },
    '/shareable/application/not-allowed': {
        auth: 'none',
        isOrganizationSpecific: false,
        handler: map(() => {
            return route({
                title: SHAREABLE_APPLICATION_TITLE,
                view: <ShareableApplicationNotAllowed />,
                data: { fullscreen: true },
            });
        }),
    },
    '/shareable/application/not-found': {
        auth: 'none',
        isOrganizationSpecific: false,
        handler: map(() => {
            return route({
                title: SHAREABLE_APPLICATION_TITLE,
                view: <ShareableApplicationNotFound />,
                data: { fullscreen: true },
            });
        }),
    },
    '/shareable/application/saved': {
        auth: 'none',
        isOrganizationSpecific: false,
        handler: map(() => {
            return route({
                title: SHAREABLE_APPLICATION_TITLE,
                view: <ShareableApplicationProgressSaved />,
                data: { fullscreen: true },
            });
        }),
    },
    '/shareable/application/thank-you': {
        auth: 'none',
        isOrganizationSpecific: false,
        handler: map((request: NaviRequest<AppContext>) => {
            const { isRenewal } = request.query;

            const validationResult = Joi.boolean().validate(isRenewal);
            if (validationResult.error) {
                container.get<Logger>(Log).error(validationResult.error);
                return route({ view: <ErrorPage /> });
            } else {
                return route({
                    title: SHAREABLE_APPLICATION_TITLE,
                    view: <ShareableApplicationThankYou isRenewal={validationResult.value} />,
                    data: { fullscreen: true },
                });
            }
        }),
    },
    '/shareable/application': {
        auth: 'none',
        isOrganizationSpecific: false,
        handler: map((request: NaviRequest<AppContext>) => {
            const { token } = request.query;

            return route({
                title: SHAREABLE_APPLICATION_TITLE,
                view: <ShareableApplication token={token} />,
                data: { fullscreen: true },
            });
        }),
    },

    '/digital-signature/:token': {
        auth: 'any',
        isOrganizationSpecific: false,
        handler: map(async (request) => {
            const documentUrlResponse = await execute(GetSignatureDocument, request.params.token);
            if (isErr(documentUrlResponse)) {
                if (documentUrlResponse.errors[0].name === 'SignatureDocumentNotFound') {
                    return route({
                        title: 'Sign Application',
                        view: <DigitalSignatureNotFound />,
                    });
                } else if (
                    documentUrlResponse.errors[0].name === 'SignatureDocumentAlreadySigned'
                ) {
                    return route({
                        title: 'Sign Application',
                        view: <DigitalSignatureAlreadySigned />,
                    });
                } else if (documentUrlResponse.errors[0].name === 'SignatureDocumentNotAllowed') {
                    return route({
                        title: 'Sign Application',
                        view: <DigitalSignatureNotAllowed />,
                    });
                }

                return route({
                    title: 'Error',
                    view: <ErrorPage errors={documentUrlResponse.errors} />,
                });
            }
            return route({
                title: 'Sign Application',
                view: (
                    <DigitalSignature
                        token={request.params.token}
                        documentUrl={documentUrlResponse.value.document_url ?? ''}
                    />
                ),
            });
        }),
    },
    '/digital-signature/not-found': {
        auth: 'none',
        isOrganizationSpecific: false,
        handler: map(() => {
            return route({
                title: 'Sign Application',
                view: <DigitalSignatureNotFound />,
            });
        }),
    },
    '/digital-signature/already-signed': {
        auth: 'none',
        isOrganizationSpecific: false,
        handler: map(() => {
            return route({
                title: 'Sign Application',
                view: <DigitalSignatureAlreadySigned />,
            });
        }),
    },
    '/digital-signature/not-allowed': {
        auth: 'none',
        isOrganizationSpecific: false,
        handler: map(() => {
            return route({
                title: 'Sign Application',
                view: <DigitalSignatureNotAllowed />,
            });
        }),
    },
    '/digital-signature/managing-broker-not-found': {
        auth: 'none',
        isOrganizationSpecific: false,
        handler: map(() => {
            return route({
                title: 'Sign Application',
                view: <DigitalSignatureManagingBrokerNotFound />,
            });
        }),
    },
    '/broker/signup-invitation': {
        auth: 'none',
        isOrganizationSpecific: false,
        handler: map(async (request) => {
            const { token: brokerInvitationToken } = request.query;
            if (!Token.check(brokerInvitationToken)) {
                return redirect('/');
            }
            return route({
                title: 'Signup invitation',
                view: <InvitationAcceptance invitationToken={brokerInvitationToken} />,
            });
        }),
    },
    '/broker/application': withWizard(
        map(async (request: NaviRequest<AppContext>) => {
            const { applicationId } = request.query;
            const validatedApplicationId = UUID.validate(applicationId);
            if (isErr(validatedApplicationId)) {
                return route({ view: <PageNotFound /> });
            }
            const { page } = request.params;
            const pageInternal = typeof page === 'string' ? page.replace(/-/g, '_') : undefined;
            return route({
                title: 'Application',
                view: (
                    <BrokerApplication
                        applicationId={validatedApplicationId.value}
                        page={pageInternal}
                    />
                ),
                data: { fullscreen: true },
            });
        }),
    ),
};
