import { isOK } from '@embroker/shotwell/core/types/Result';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Thumbnail, ThumbnailGrid } from '@embroker/ui-toolkit/v2';
import React, { useEffect, useState } from 'react';
import { useNavigation } from '../../../../view/hooks/useNavigation';
import { QuickLinkTotals } from '../../../types/QuickLinkTotals';
import { GetQuickLinkTotals } from '../../../useCases/GetQuickLinkTotals';
import { GetQuoteToBindRatio } from '../../../useCases/GetQuoteToBindRatio';

export function BrokerDashboardQuickLinks() {
    const { navigate } = useNavigation();
    const { result: countResult } = useUseCase(GetQuickLinkTotals);
    const { result: quoteToBindRatioResult } = useUseCase(GetQuoteToBindRatio);
    const [quickLinksCounts, setQuickLinksCounts] = useState<QuickLinkTotals | undefined>();
    const [quoteToBindRatio, setQuoteToBindRatio] = useState<number | undefined>();

    useEffect(() => {
        if (countResult && isOK(countResult)) {
            setQuickLinksCounts(countResult.value);
        }
    }, [countResult]);

    useEffect(() => {
        if (quoteToBindRatioResult && isOK(quoteToBindRatioResult)) {
            setQuoteToBindRatio(quoteToBindRatioResult.value / 100);
        }
    }, [quoteToBindRatioResult]);

    return (
        <ThumbnailGrid>
            <Thumbnail
                title="Quotes"
                className="access_quick_links"
                onClick={() => {
                    navigate('/broker/quotes');
                }}
            >
                <Thumbnail.Number data-e2e="total-open-quotes">
                    {quickLinksCounts?.totalOpenQuotes ?? 0}
                </Thumbnail.Number>
            </Thumbnail>
            <Thumbnail
                title="Renewals"
                className="access_quick_links"
                onClick={() => {
                    navigate('/broker/renewals');
                }}
            >
                <Thumbnail.Number data-e2e="total-renewals">
                    {quickLinksCounts?.totalRenewals ?? 0}
                </Thumbnail.Number>
            </Thumbnail>
            <Thumbnail
                title="Clients"
                className="access_quick_links"
                onClick={() => {
                    navigate('/broker/companies');
                }}
            >
                <Thumbnail.Number data-e2e="total-clients">
                    {quickLinksCounts?.totalAccounts ?? 0}
                </Thumbnail.Number>
            </Thumbnail>
            <Thumbnail
                title="Payments"
                className="access_quick_links"
                onClick={() => {
                    navigate('/broker/unpaid-balances');
                }}
            >
                <Thumbnail.Number data-e2e="total-payments">
                    {quickLinksCounts?.totalPayments ?? 0}
                </Thumbnail.Number>
            </Thumbnail>
            {quoteToBindRatio !== undefined && (
                <ThumbnailGrid.Section>
                    <Thumbnail
                        title="Quote to Bind Ratio"
                        className="access_quick_links"
                        appearance="light"
                    >
                        <Thumbnail.Number data-e2e="qtb-ratio">{quoteToBindRatio}</Thumbnail.Number>
                    </Thumbnail>
                </ThumbnailGrid.Section>
            )}
        </ThumbnailGrid>
    );
}
