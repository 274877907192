import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { State } from '@embroker/shotwell/core/types/StateList';

export interface PCoMLEndorsementUserData {
    company: string;
    fullName: string;
    title: string;
    usaState?: State;
}

export const PCoMLEndorsementUserData = {
    ...defineValidator<PCoMLEndorsementUserData>({
        company: Joi.string().required(),
        fullName: Joi.string().required(),
        title: Joi.string().allow('').required(),
        usaState: State.schema.optional(),
    }),
    create(userData: PCoMLEndorsementUserData) {
        return PCoMLEndorsementUserData.validate(userData);
    },
};
