import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { TextButton } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { WizardForm } from '../../../view/hooks/useWizardForm';

interface DownloadQuoteButtonProps<T extends FormData> {
    isSubmitting: boolean;
    trigger: WizardForm<OpaqueForm<T>>['trigger'];
    isDisabled?: boolean;
}

export function DownloadQuoteButton<T extends FormData>({
    isSubmitting,
    trigger,
    isDisabled,
}: DownloadQuoteButtonProps<T>) {
    return (
        <TextButton
            as="button"
            disabled={isDisabled || isSubmitting}
            icon="download"
            onClick={() => trigger('downloadQuote')}
            data-e2e="download-quote"
        >
            Download quote
        </TextButton>
    );
}
