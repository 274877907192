import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { CalculateSKU } from '../../../analytics/useCases/CalculateSKU';
import { ApplicationRepository } from '../../../shopping/repositories/ApplicationRepository';
import { TasksRepository } from '../../../tasks/repositories';
import { RequoteFailed } from '../../errors';
import { LPLQuoteRepository } from '../repositories/LPLQuoteRepository';
import { LPLQuoteExtended } from '../types/LPLQuoteExtended';
import { LPLQuoteOptions } from '../types/LPLQuoteOptions';

interface ReQuoteLPLRequest {
    applicationId: UUID;
    lplQuoteOptions: LPLQuoteOptions;
    abortSignal: AbortSignal;
}

export interface ReQuoteLPL extends UseCase {
    execute(
        request: ReQuoteLPLRequest,
    ): AsyncResult<LPLQuoteExtended, InvalidArgument | OperationFailed | RequoteFailed>;
}

class ReQuoteLPLUseCase extends UseCase implements ReQuoteLPL {
    public static type = Symbol('LPLQuote/ReQuoteLPL');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(LPLQuoteRepository) private lplQuoteRepository: LPLQuoteRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
        @inject(CalculateSKU.type) private calculateSku: CalculateSKU,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
        lplQuoteOptions,
        abortSignal,
    }: ReQuoteLPLRequest): AsyncResult<
        LPLQuoteExtended,
        InvalidArgument | OperationFailed | RequoteFailed
    > {
        const enqueueReQuoteTaskResult = await this.lplQuoteRepository.enqueueReQuoteLPLTask(
            applicationId,
            lplQuoteOptions,
        );
        if (isErr(enqueueReQuoteTaskResult)) {
            return enqueueReQuoteTaskResult;
        }

        const pollForTaskStatusResult = await this.tasksRepository.pollForTaskStatus(
            enqueueReQuoteTaskResult.value.taskId,
            abortSignal,
        );
        if (isErr(pollForTaskStatusResult)) {
            return Failure(RequoteFailed({ errors: pollForTaskStatusResult.errors }));
        }

        const getCurrentQuoteResult = await this.lplQuoteRepository.getLastLPLQuote(applicationId);
        if (isErr(getCurrentQuoteResult)) {
            return getCurrentQuoteResult;
        }

        const getApplicationResult = await this.applicationRepository.getApplication(applicationId);
        if (isErr(getApplicationResult)) {
            return getApplicationResult;
        }

        const quote = getCurrentQuoteResult.value.lplQuote;
        const application = getApplicationResult.value;

        const skuResult = await this.calculateSku.execute({
            event: 'quote',
            applicationId: application.id,
        });

        application.onQuotePremium({
            totalPremium: quote.totalPayable,
            applicationId: application.id,
            isRenewal: application.isRenewal(),
            sku: skuResult.value,
        });
        await this.eventBus.publishEntityEvents(application);

        return Success(getCurrentQuoteResult.value);
    }
}

export const ReQuoteLPL: UseCaseClass<ReQuoteLPL> = ReQuoteLPLUseCase;
