import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface ESPConfig {
    readonly dynamicESPRetentionEnabled: boolean;
    readonly dynamicESPRetentionStartDate?: Date;
    readonly dynamicESPRetentionUpdateDate?: Date;
    readonly newInsurerDocumentsReleaseDate?: Date;
    readonly fiduciaryDocumentsReleaseDate?: Date;
    readonly pasRaterEnabled?: boolean;
    readonly espDocumentsAfterEoCyberSplitReleaseDate?: Date;
}

export const ESPConfig = {
    ...defineValidator<ESPConfig>({
        dynamicESPRetentionEnabled: Joi.boolean(),
        dynamicESPRetentionStartDate: Joi.date().optional(),
        dynamicESPRetentionUpdateDate: Joi.date().optional(),
        newInsurerDocumentsReleaseDate: Joi.date().optional(),
        fiduciaryDocumentsReleaseDate: Joi.date().optional(),
        pasRaterEnabled: Joi.boolean().optional(),
        espDocumentsAfterEoCyberSplitReleaseDate: Joi.date().optional(),
    }),
    create(espConfig: ESPConfig) {
        return ESPConfig.validate(espConfig);
    },
};
