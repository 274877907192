import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { SKU } from '../../analytics/types/SKU';
import { CalculateSKUFromAppTypes } from '../../analytics/useCases/CalculateSKUFromAppTypes';
import { AppTypeCode } from '../types/enums';
import { ApplicationPurchaseIntent } from '../entities/Application';

export interface PublishPurchaseIntentEventRequest {
    appTypes?: AppTypeCode[];
}

export interface PublishPurchaseIntentEvent extends UseCase {
    execute(
        request: PublishPurchaseIntentEventRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed>;
}

class PublishPurchaseIntentEventUseCase extends UseCase implements PublishPurchaseIntentEvent {
    public static type = Symbol('Shopping/PublishPurchaseIntentEvent');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(CalculateSKUFromAppTypes.type)
        private calculateSKUFromAppTypes: CalculateSKUFromAppTypes,
    ) {
        super(eventBus);
    }

    public async execute(
        request: PublishPurchaseIntentEventRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed> {
        let sku: SKU | undefined;
        if (request.appTypes) {
            const skuResult = await this.calculateSKUFromAppTypes.execute({
                appTypeList: request.appTypes,
            });
            sku = skuResult.value;
        }

        const purchaseIntentEvent: ApplicationPurchaseIntent = {
            origin: 'Application',
            name: 'PurchaseIntent',
            createdAt: new Date(Date.now()),
            sku,
            id: UUID.create(),
        };
        await this.eventBus.publish(purchaseIntentEvent);

        return Success();
    }
}

export const PublishPurchaseIntentEvent: UseCaseClass<PublishPurchaseIntentEvent> =
    PublishPurchaseIntentEventUseCase;
