import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface CertificateInsurer {
    name: string;
    naic: string;
}

export const CertificateInsurer = {
    ...defineValidator<CertificateInsurer>(
        Joi.object({
            name: Joi.string().allow(''),
            naic: Joi.string().allow(''),
        }).preferences({ presence: 'required' }),
    ),
    create(certificateInsurer: CertificateInsurer) {
        return CertificateInsurer.validate(certificateInsurer);
    },
};
