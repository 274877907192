import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { CrimeQuote } from '../../entities/CrimeQuote';
import { CrimeQuoteOptions } from '../../types/CrimeQuoteOptions';
import { InvalidAnnualTechFee } from '../../../errors';

export interface PurchaseResponse {
    policyId: UUID;
    policyDoneTaskId: UUID;
}

export interface CrimeQuoteRepository {
    getLastCrimeQuote(
        applicationId: UUID,
    ): AsyncResult<CrimeQuote, InvalidArgument | OperationFailed>;

    reQuoteCrime(
        applicationId: UUID,
        lplQuoteOptions: CrimeQuoteOptions,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed>;

    purchaseCrime(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<
        PurchaseResponse,
        UnknownEntity | InvalidArgument | OperationFailed | InvalidAnnualTechFee
    >;

    createQuoteSummaryAsyncTask(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed>;

    createSpecimenPolicyAsyncTask(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed>;
}

export const CrimeQuoteRepository = Symbol('CrimeQuoteRepository');
