import React, { Fragment } from 'react';
import { BOPHartfordQuote } from '@app/quote/hartford-bop/entities/BOPHartfordQuote';
import {
    BOPHartfordQuoteOptionsFormData,
    MAX_FUTURE_DAYS_ALLOWED,
} from '@app/quote/hartford-bop/view/components/createBOPHartfordOptionsForm';
import { BundleQuoteTotalCard } from '@app/bundle/view/components/BundleQuoteTotalCard.view';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { WizardForm } from '@app/view/hooks/useWizardForm';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { ColumnLayout, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import { BundleQuoteEffectiveDate } from '@app/bundle/view/components/BundleQuoteEffectiveDate';
import { addDays, isWithinInterval, startOfToday } from 'date-fns';
import { isDateFeb29 } from '@app/quote/lpl/view/components/createLPLQuoteForm';
import { BundleQuoteAddressCard } from '@app/bundle/view/components/BundleQuoteAddressCard';
import { BundleQuoteDocumentsList } from '@app/bundle/view/components/BundleQuoteDocumentsList';
import { DocumentsItem } from '@app/bundle/view/components/BundleQuoteLandingPage';

export interface QuoteBreakdownProps {
    quote: BOPHartfordQuote;
    next: WizardForm<OpaqueForm<BOPHartfordQuoteOptionsFormData>>['next'];
    fields: WizardForm<OpaqueForm<BOPHartfordQuoteOptionsFormData>>['fields'];
    trigger: WizardForm<OpaqueForm<BOPHartfordQuoteOptionsFormData>>['trigger'];
    effectiveDate: Date;
    onDateChange: (event: { target: { value: string; date: Date } }) => void;
    hasPrevious: boolean;
    documentList: DocumentsItem[];
    status: string;
}

export function QuoteBreakdown({
    quote,
    status,
    next,
    trigger,
    fields,
    effectiveDate,
    onDateChange,
    hasPrevious,
    documentList,
}: QuoteBreakdownProps) {
    const isDirty = status === 'dirty';
    const isSubmitting = status === 'submitting';

    if (!quote.userInfo) {
        return null;
    }

    const isEffectiveDateSelectable = (effectiveDate: Date) => {
        const today = startOfToday();
        if (isDateFeb29(effectiveDate)) {
            return false;
        }
        return isWithinInterval(effectiveDate, {
            start: today,
            end: addDays(today, MAX_FUTURE_DAYS_ALLOWED),
        });
    };

    const renderQuoteTotalCard = () => {
        if (hasPrevious) {
            return null;
        }

        if (isDirty) {
            return (
                <BundleQuoteTotalCard
                    title="Premium"
                    titleTooltip={null}
                    label={'Recalculate premium'}
                    buttonContent="Recalculate"
                    onClick={() => {
                        trigger('update');
                    }}
                />
            );
        }

        if (isSubmitting) {
            return (
                <BundleQuoteTotalCard
                    title="Premium"
                    titleTooltip={null}
                    label={'Recalculating premium...'}
                    buttonContent="Recalculate"
                    onClick={() => {
                        next();
                    }}
                />
            );
        }

        return (
            <BundleQuoteTotalCard
                title="Premium"
                titleTooltip={null}
                label={null}
                buttonContent="Continue to Checkout"
                onClick={() => {
                    if (isSubmitting) {
                        return;
                    }

                    next();
                }}
            >
                <ColumnLayout gap="16" bottom>
                    <MoneyDisplay value={quote.totalPremium} data-e2e="value" fractionDigits={0} />
                    &nbsp;
                    <StackLayout gap="4">
                        <Text style="heading 4" as="span" color="brand-400">
                            per year
                        </Text>
                    </StackLayout>
                </ColumnLayout>
            </BundleQuoteTotalCard>
        );
    };

    return (
        <Fragment>
            {renderQuoteTotalCard()}

            <BundleQuoteEffectiveDate
                fields={fields}
                disabled={hasPrevious}
                effectiveDate={effectiveDate}
                onDateChange={onDateChange}
                isEffectiveDateSelectable={isEffectiveDateSelectable}
            />

            <BundleQuoteAddressCard
                organizationInfo={{
                    address: {
                        addressLine1: quote.userInfo.address.addressLine1,
                        addressLine2: quote.userInfo.address.addressLine2,
                        city: quote.userInfo.address.city,
                        state: quote.userInfo.address.state,
                        zip: quote.userInfo.address.zip,
                    },
                    userInfo: {
                        title: quote.userInfo.title,
                        fullName: quote.userInfo.fullName,
                    },
                    companyName: quote.userInfo.company,
                }}
            />

            <BundleQuoteDocumentsList documentsList={documentList} />
        </Fragment>
    );
}
