import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { UserOnboardingCompleted } from '../entities/User';

export interface PublishOnboardingCompletedEvent extends UseCase {
    execute(): AsyncResult<void>;
}

@injectable()
class PublishOnboardingCompletedEventUseCase
    extends UseCase
    implements PublishOnboardingCompletedEvent
{
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/PublishOnboardingCompletedEventUseCase');

    /**
     * Constructor for PublishOnboardingCompletedEvent class instance
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    /**
     * Executes the PublishOnboardingCompletedEvent use case.
     */
    public async execute(): AsyncResult<void> {
        const userOnboardingCompletedEvent: UserOnboardingCompleted = {
            origin: 'User',
            name: 'OnboardingCompleted',
            createdAt: new Date(Date.now()),
            id: UUID.create(),
        };

        await this.eventBus.publish(userOnboardingCompletedEvent);

        return Success();
    }
}

export const PublishOnboardingCompletedEvent: UseCaseClass<PublishOnboardingCompletedEvent> =
    PublishOnboardingCompletedEventUseCase;
