import React, { useMemo, useEffect } from 'react';
import { UserGuidancePageMpl } from './UserGuidancePageMpl';
import { UserGuidancePageOnboarding } from './UserGuidancePageOnboarding';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { useStartApplication } from '../hooks/useStartApplication';

export function ShoppingGuidancePage({
    organizationId,
    isMPLGuidance,
}: {
    organizationId: UUID;
    isMPLGuidance: boolean;
}) {
    const { navigate } = useNavigation();

    const abortController = useMemo(() => {
        return new AbortController();
    }, []);

    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, [abortController]);

    const { startMPLBundleApplication, isLoading } = useStartApplication();

    const handleSecondaryButtonClick = async () => {
        navigate('/shopping');
    };

    return isMPLGuidance ? (
        <UserGuidancePageMpl
            isLoading={isLoading}
            organizationId={organizationId}
            onPrimaryButtonClick={startMPLBundleApplication}
            onSecondaryButtonClick={handleSecondaryButtonClick}
        />
    ) : (
        <UserGuidancePageOnboarding
            isLoading={isLoading}
            organizationId={organizationId}
            onPrimaryButtonClick={startMPLBundleApplication}
            onSecondaryButtonClick={handleSecondaryButtonClick}
        />
    );
}
