import { Table, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { CLAIMS_EXPENSE_TYPE_SEPARATE_CLAIMS_EXPENSES } from '../../../types/BindFactors';
import { PolicyDetailsCard } from './PolicyDetailsCard.view';

export interface ClaimExpensesAndDeductibleTypeCardProps {
    claimsExpense?: string;
    separateClaimsExpenseLimit?: number;
    deductibleType?: string;
}

const mapDeductibleTypeName: Map<string, string> = new Map([
    ['DeductibleTypeLossAndClaimsExpense', 'Loss and Claim Expenses'],
    ['DeductibleTypeLossOnly', 'Loss Only'],
]);
const mapClaimExpensesName: Map<string, string> = new Map([
    ['ClaimsExpenseTypeInsideOfLimits', 'Claims Expense Inside of Limits'],
    ['ClaimsExpenseTypeInAdditionToLimits', 'Claims Expense in Addition to Limits'],
    ['ClaimsExpenseTypeSeparateClaimsExpenses', 'Separate Claims Expenses Coverage'],
    [
        'ClaimsExpenseTypeInsideOfLimitsWithOffset',
        'Claims Expense Inside of Limits With 50% Offset',
    ],
]);

const mapSeparateClaimExpenseOptions: Map<number, string> = new Map([
    [100000, '$100,000'],
    [200000, '$200,000'],
    [250000, '$250,000'],
    [300000, '$300,000'],
    [500000, '$500,000'],
    [1000000, '$1,000,000'],
    [2000000, '$2,000,000'],
    [3000000, '$3,000,000'],
    [4000000, '$4,000,000'],
    [5000000, '$5,000,000'],
]);

export function ClaimExpensesAndDeductibleTypeCard({
    claimsExpense,
    separateClaimsExpenseLimit,
    deductibleType,
}: ClaimExpensesAndDeductibleTypeCardProps) {
    return (
        <PolicyDetailsCard title="Claim Expenses and Deductible Type">
            <Table>
                <Table.Header>
                    <Table.Column>CLAIM EXPENSES</Table.Column>
                    <Table.Column>DEDUCTIBLE TYPE</Table.Column>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            <Text>{mapClaimExpensesName.get(claimsExpense as string)}</Text>
                        </Table.Cell>
                        <Table.Cell>
                            <Text>{mapDeductibleTypeName.get(deductibleType as string)}</Text>
                        </Table.Cell>
                    </Table.Row>
                    {claimsExpense === CLAIMS_EXPENSE_TYPE_SEPARATE_CLAIMS_EXPENSES &&
                        separateClaimsExpenseLimit && (
                            <Table.Row>
                                <Table.Cell>
                                    <Text>
                                        {'Limit ' +
                                            mapSeparateClaimExpenseOptions.get(
                                                separateClaimsExpenseLimit,
                                            )}
                                    </Text>
                                </Table.Cell>
                                <Table.Cell></Table.Cell>
                            </Table.Row>
                        )}
                </Table.Body>
            </Table>
        </PolicyDetailsCard>
    );
}
