import { Nullable } from '@embroker/shotwell/core/types';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Filter, FilterOption, TableFilter } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { GetBrokers } from '../../useCases/GetBrokers';

export type Filters = Nullable<TableFilter<Record<string, unknown>>[]>;

export function BrokerFilter({
    selectedBroker,
    onBrokerSelect,
    filterBy,
    disabled,
}: {
    selectedBroker: 'all' | UUID;
    onBrokerSelect: (selectedBroker: 'all' | UUID) => void;
    filterBy?: (filters: Filters) => void;
    disabled?: boolean;
}) {
    const { result } = useUseCase(GetBrokers);
    let brokers: FilterOption<'all' | UUID>[] = [];
    if (result !== undefined && isOK(result)) {
        brokers = result.value.map((broker) => ({
            value: broker.id,
            title: broker.broker_name,
        }));

        brokers.sort((a, b) => {
            const titleA = a.title?.toString() ?? '';
            const titleB = b.title?.toString() ?? '';

            return titleA >= titleB ? 1 : -1;
        });
    }
    brokers.splice(0, 0, { value: 'all', title: 'All' });

    function handleFilterChange(event: { readonly target: { readonly value: 'all' | UUID } }) {
        const selectedValue = event.target.value;
        onBrokerSelect(selectedValue);

        let filters: Filters = null;
        if (selectedValue != 'all') {
            filters = [{ target: 'managing_broker', value: selectedValue }];
        }

        if (filterBy) {
            filterBy(filters);
        }
    }

    return (
        <Filter
            value={selectedBroker}
            onChange={handleFilterChange}
            title="Filter by broker"
            options={brokers}
            disabled={disabled}
        />
    );
}
