import { WizardLayout, WizardLayoutAppearance } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { FormEngineSubscriber, FormEngineSubscriberProps } from './FormEngineSubscriber';
import { PageTree, PageTreeNode, constructPageTree, getPageTreeItemStatus } from './Navigation';

export type ProgressBarTitleRenderer = (pageTree: PageTree) => string;

export interface ProgressBarRangeType {
    readonly min?: number;
    readonly max?: number;
}

interface ProgressBarProps extends FormEngineSubscriberProps {
    readonly onDismiss: () => void;
    readonly dismissAppearance: 'close-icon' | 'save-and-exit';
    readonly progressBarRange?: ProgressBarRangeType;
    readonly progressBarTitleRenderer?: ProgressBarTitleRenderer;
    readonly footer?: React.ReactNode;
    readonly footerAppearance?: WizardLayoutAppearance;
}

interface CalculateProgressInput {
    pageTree: PageTree;
    min: number;
    max: number;
}

function calculateProgress({ pageTree, min, max }: CalculateProgressInput): number {
    const { numberOfPages, activePageNumber } = getApplicationProgress(pageTree);
    if (numberOfPages === 0) {
        return 0;
    }

    const result = (activePageNumber / numberOfPages) * (max - min) + min;
    return result;
}

function getApplicationProgress(pageTree: PageTree): {
    activePageNumber: number;
    numberOfPages: number;
} {
    let numberOfPages = 0;
    let activePageNumber = 0;

    const addNumberOfUnderlyingPages = (node: PageTreeNode) => {
        for (const item of node.items) {
            if (item.type == 'leaf') {
                numberOfPages++;
                if (item.status === 'active') {
                    activePageNumber = numberOfPages;
                }
            } else {
                addNumberOfUnderlyingPages(item);
            }
        }
    };
    for (const item of pageTree) {
        if (item.type == 'leaf') {
            numberOfPages++;
            if (item.status === 'active') {
                activePageNumber = numberOfPages;
            }
        } else {
            addNumberOfUnderlyingPages(item);
        }
    }

    return { activePageNumber, numberOfPages };
}

function getActivePageTitle(pageTree: PageTree): string {
    for (const page of pageTree) {
        if (page.type === 'leaf') {
            if (page.status === 'active') {
                return page.title;
            }
        } else {
            for (const item of page.items) {
                if (getPageTreeItemStatus(item) === 'active') {
                    return item.title;
                }
            }
        }
    }
    return '';
}

export class ProgressBar extends FormEngineSubscriber {
    private onDismiss: () => void;
    private dismissAppearance: 'close-icon' | 'save-and-exit';
    private min: number;
    private max: number;
    private titleRenderer: ProgressBarTitleRenderer;
    private footer?: React.ReactNode;
    private footerAppearance?: WizardLayoutAppearance;

    constructor(props: ProgressBarProps) {
        super(props);
        this.onDismiss = props.onDismiss;
        this.min = props.progressBarRange?.min ?? 0;
        this.max = props.progressBarRange?.max ?? 100;
        this.dismissAppearance = props.dismissAppearance;
        this.titleRenderer = props.progressBarTitleRenderer ?? getActivePageTitle;
        this.footer = props.footer;
        this.footerAppearance = props.footerAppearance;
    }

    render() {
        const pageTree = constructPageTree(this.props.pages);
        const progress = calculateProgress({ pageTree, min: this.min, max: this.max });
        const title = this.titleRenderer(pageTree);

        return (
            <WizardLayout
                data-e2e-check="this"
                title={title}
                onDismiss={this.onDismiss}
                progress={progress}
                dismissAppearance={this.dismissAppearance}
                footer={this.footer}
                footerAppearance={this.footerAppearance}
            >
                {this.props.children}
            </WizardLayout>
        );
    }
}
