import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { AppTypeCode } from '../../shopping/types/enums';

const BundleUserClickEventNameList = ['Referral Submit For Review Clicked'] as const;
type BundleUserClickEventNameList = typeof BundleUserClickEventNameList;
export type BundleUserClickEventName = BundleUserClickEventNameList[number];

export interface BundleUserClickEvent extends DomainEvent {
    name: 'BundleUserClickEvent';
    clickEventName: BundleUserClickEventName;
    clickEventPayload?: {
        appType?: AppTypeCode;
    };
}

interface ArgsProps {
    clickEventName: BundleUserClickEventName;
    clickEventPayload?: {
        appType?: AppTypeCode;
    };
}

export interface PublishBundleUserClickEvent extends UseCase {
    execute(request: ArgsProps): AsyncResult<void>;
}

@injectable()
class PublishBundleUserClickEventUseCase extends UseCase implements PublishBundleUserClickEvent {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('bundle/PublishBundleUserClickEventUseCase');

    /**
     * Constructor for PublishBundleUserClickEvent class instance
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    /**
     * Executes the PublishBundleUserClickEvent use case.
     */
    public async execute({ clickEventName, clickEventPayload }: ArgsProps): AsyncResult<void> {
        const event = {
            id: UUID.create(),
            createdAt: new Date(Date.now()),
            origin: 'Bundle',
            name: `BundleUserClickEvent`,
            clickEventName: clickEventName,
            clickEventPayload,
        };

        await this.eventBus.publish(event);

        return Success();
    }
}

export const PublishBundleUserClickEvent: UseCaseClass<PublishBundleUserClickEvent> =
    PublishBundleUserClickEventUseCase;
