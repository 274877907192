import * as APITypes from '@embroker/shotwell-api/app.spec';
import { Immutable } from '@embroker/shotwell/core/types';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { ExcessTechEOCyber, ExcessTowerLayer } from '../../types/ExcessRestriction';
import { getLogger } from '../../view/logger';
import { AdditionalQuestions } from '../../types/AdditionalQuestions';

function toExcessTowerLayer(
    apiExcessTowerLayer: Immutable<APITypes.ExcessTowerLayer>,
): ExcessTowerLayer {
    return {
        layerNumber: apiExcessTowerLayer.layer_number,
        carrier: apiExcessTowerLayer.carrier,
        policyNumber: apiExcessTowerLayer.policy_number,
        writingCompany: apiExcessTowerLayer.writing_company,
        premium: apiExcessTowerLayer.premium,
        policyAggregate: apiExcessTowerLayer.policy_aggregate,
    };
}

function toExcessTower(apiExcessTower: Immutable<APITypes.ExcessTowerLayer[]>): ExcessTowerLayer[] {
    const tower: ExcessTowerLayer[] = [];

    for (const apiExcessLayer of apiExcessTower) {
        const layer = toExcessTowerLayer(apiExcessLayer);
        tower.push(layer);
    }

    return tower;
}

function toExcessTechEOCyber(
    apiTechEOCyber: Immutable<APITypes.ExcessTechEOCyber>,
): ExcessTechEOCyber {
    return {
        policyStartDate: apiTechEOCyber.policy_start_date,
        policyEndDate: apiTechEOCyber.policy_end_date,
        techEOLimit: apiTechEOCyber.tech_e_o_limit,
        cyberLimit: apiTechEOCyber.cyber_limit,
        technologySelected: apiTechEOCyber.technology_selected,
        cyberSelected: apiTechEOCyber.cyber_selected,
        primaryCarrier: apiTechEOCyber.primary_carrier,
        primaryPolicyNumber: apiTechEOCyber.primary_policy_number,
        primaryWritingCompany: apiTechEOCyber.primary_writing_company,
        primaryPremium: apiTechEOCyber.primary_premium,
        policyAggregate: apiTechEOCyber.policy_aggregate,
        excessTower: toExcessTower(apiTechEOCyber.excess_tower),
    };
}

export function makeExcessAdditionalQuestions(
    apiExcess?: Immutable<APITypes.UwDecisionData>,
): Immutable<AdditionalQuestions | undefined> {
    if (!apiExcess || !apiExcess?.additional_questions.excess_tech_e_o_cyber) {
        return undefined;
    }

    const additionalQuestions: AdditionalQuestions = {
        excessTechEOCyber: toExcessTechEOCyber(
            apiExcess.additional_questions.excess_tech_e_o_cyber,
        ),
    };

    const excessResult = AdditionalQuestions.create(additionalQuestions);

    if (isErr(excessResult)) {
        getLogger().error(excessResult.errors);
        return undefined;
    }

    return excessResult.value;
}
