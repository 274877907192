import { hasError } from '@embroker/shotwell/core/Error';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Spinner, useModal } from '@embroker/ui-toolkit/v2';
import { Elements } from '@stripe/react-stripe-js/dist/react-stripe.umd';
import React from 'react';
import { PaymentNotFound } from '../../../types/errors';
import { GetPublicInvoice, GetPublicInvoiceRequest } from '../../../useCases/GetPublicInvoice';
import { useStripe } from '../../hooks/useStripe';
import { PayWithACHModal } from '../modals/PayWithACHModal';
import { PayWithCCModal } from '../modals/PayWithCCModal';
import { InvoiceInfo } from './PublicInvoiceInfo';
import { PublicPaymentNotFound } from './PublicPaymentNotFound';
import { PublicPaymentSubmitted } from './PublicPaymentSubmitted';

interface PublicPaymentData {
    publicKey: UUID;
}

export function PublicPayment(props: PublicPaymentData) {
    const stripe = useStripe();

    const ACHModal = useModal();
    const CCModal = useModal();

    const request: GetPublicInvoiceRequest = {
        publicKey: props.publicKey,
    };

    const { result, isLoading } = useUseCase(GetPublicInvoice, request);

    if (isLoading || result === undefined) {
        return <Spinner />;
    }

    if (isErr(result)) {
        if (hasError(result.errors, [PaymentNotFound().code])) {
            return <PublicPaymentNotFound />;
        }
        return <ErrorPage errors={result.errors} />;
    }

    if (result.value.Invoice.status === 'charge') {
        return <PublicPaymentSubmitted />;
    }

    const activeInvoices: UUID[] = [result.value.Invoice.id];

    const total = result.value.Invoice.balance;

    function showACHModal() {
        ACHModal.show();
    }

    function hideACHModal() {
        ACHModal.hide();
    }

    function showCCModal() {
        CCModal.show();
    }

    function hideCCModal() {
        CCModal.hide();
    }

    return (
        <React.Fragment>
            <InvoiceInfo
                invoice={result.value.Invoice}
                showACHModal={showACHModal}
                showCCModal={showCCModal}
            />
            <Elements stripe={stripe}>
                <PayWithACHModal
                    activeInvoices={activeInvoices}
                    hideModal={hideACHModal}
                    modal={ACHModal}
                    publicKey={props.publicKey}
                    total={total}
                />
                <PayWithCCModal
                    activeInvoices={activeInvoices}
                    hideModal={hideCCModal}
                    modal={CCModal}
                    publicKey={props.publicKey}
                    total={total}
                />
            </Elements>
        </React.Fragment>
    );
}
