import { inject } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Success, Failure, AsyncResult, isOK } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { APIQuestionerRepository } from '../repositories/APIQuestionerRepository';
import { SessionRepository } from '../../userOrg/repositories/SessionRepository';
import { isAuthenticated } from '../../userOrg/entities/Session';
import { Unauthenticated } from '../../userOrg/errors';
import { FailedToSaveQuestionerAnswers } from '../errors';
import { OracleAnswer, AnswerType, answerTypeDefinitionMap } from '../types/OracleAnswerType';
import {
    OnboardingPrefillQuestionnaireData,
    OnboardingPrefillQuestionnaireKeys,
} from '@app/userOrg/types/OnboardingPrefillQuestionnaireData';

const OnboardingAnswerMap: Record<string, { key: string; answerType: AnswerType }> = {
    headquarters: {
        key: 'main_business_location',
        answerType: 'ADDRESS',
    },
    naics_code: { key: 'onb_naics_code', answerType: 'TEXT' },
    tech_area_of_focus: { key: 'onb_area_of_focus', answerType: 'TEXT' },
    has_raised_funding: {
        key: 'onb_raised_funding',
        answerType: 'BOOLEAN',
    },
    number_of_employees: {
        key: 'total_number_employees',
        answerType: 'INTEGER',
    },
    location_type: {
        key: 'working_space_type',
        answerType: 'TEXT',
    },
} as const;

export type SaveOnboardingAnswersRequest = {
    currentStepQuestionnaireData?: OnboardingPrefillQuestionnaireData;
};

export type SaveOnboardingAnswersResponse = {};

export type SaveOnboardingAnswersUseCaseResult = AsyncResult<
    SaveOnboardingAnswersResponse,
    Unauthenticated | FailedToSaveQuestionerAnswers
>;

export interface SaveOnboardingAnswers extends UseCase {
    execute(request: SaveOnboardingAnswersRequest): SaveOnboardingAnswersUseCaseResult;
}

class SaveOnboardingAnswersUseCase extends UseCase implements SaveOnboardingAnswers {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Questioner/SaveOnboardingAnswersUseCase');
    /**
     * Constructor for SaveOnboardingAnswersUseCase use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param notificationRepository is notification repository used to store user Questioner
     */
    constructor(
        @inject(APIQuestionerRepository) private questionerRepository: APIQuestionerRepository,
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(SessionRepository) private sessionRepository: SessionRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        currentStepQuestionnaireData,
    }: SaveOnboardingAnswersRequest): SaveOnboardingAnswersUseCaseResult {
        const activeSesion = await this.sessionRepository.getActiveSession();
        if (!isAuthenticated(activeSesion.value)) {
            return Failure(Unauthenticated());
        }

        if (!currentStepQuestionnaireData) {
            return Success<SaveOnboardingAnswersResponse>({});
        }

        const answers = Object.keys(currentStepQuestionnaireData)
            .map((key) => {
                if (!OnboardingAnswerMap[key]) {
                    return null;
                }
                const { key: oracleKey, answerType } = OnboardingAnswerMap[key];
                const { answerKeyType, schemaFunctions } = answerTypeDefinitionMap[answerType];
                const { validator, serializeAnswer } = schemaFunctions;

                const value =
                    currentStepQuestionnaireData[key as OnboardingPrefillQuestionnaireKeys];

                const serializedAnswer = serializeAnswer(value);
                const validateAnswerValue = validator.validate(serializedAnswer);

                if (validateAnswerValue.error) {
                    return null;
                }

                return {
                    key: oracleKey,
                    value: { [answerKeyType]: [validateAnswerValue.value] },
                    type: answerType,
                    multiplicity: 1,
                };
            })
            .filter((answer) => Boolean(answer)) as OracleAnswer[];

        const saveQuestionerAnswersResp = await this.questionerRepository.saveQuestionerAnswers({
            answers,
        });

        if (!isOK(saveQuestionerAnswersResp)) {
            return saveQuestionerAnswersResp;
        }

        return Success<SaveOnboardingAnswersResponse>({});
    }
}

export const SaveOnboardingAnswers: UseCaseClass<SaveOnboardingAnswers> =
    SaveOnboardingAnswersUseCase;
