import { inject, injectable } from '@embroker/shotwell/core/di';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { Certificate } from '../entities/Certificate';
import { CertificateTemplate } from '../entities/CertificateTemplate';
import { CertificateRepository } from '../repositories/CertificateRepository';
import { CertificateTemplateRepository } from '../repositories/CertificateTemplateRepository';

/**
 * Request data for GetShareableCertificateData use case
 * It contains all relevant data for fetching shareable certificate data
 */
export interface GetShareableCertificateDataRequest {
    organizationId: UUID;
}

/**
 * Response data for GetShareableCertificateData use case
 *
 * @property hasShareableCertificateData is boolean value showing if there is sahreable certificate data for the organization
 * @property certificateTemplateList is the list of awailable certificate templates for the organization
 */
export interface GetShareableCertificateDataResponse {
    shareableCertificateData: EntityProps<Certificate>;
    certificateTemplateList: EntityProps<CertificateTemplate>[];
}

/**
 * GetShareableCertificateData use case is used to fetch all potentially shareable certificate data for the organization
 */
export interface GetShareableCertificateData extends UseCase {
    execute(
        request: GetShareableCertificateDataRequest,
    ): AsyncResult<GetShareableCertificateDataResponse, InvalidArgument | OperationFailed>;
}

@injectable()
class GetShareableCertificateDataUseCase extends UseCase implements GetShareableCertificateData {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Certificates/GetShareableCertificateDataUseCase');

    /**
     * Constructor for GetShareableCertificateData class instance
     * @param eventBus An event bus this Use Case will publish events to.
     * @param certificateRepo repo that will be used to check for shareable certificate data
     * @param certificateTemplateRepo repo that will be used for fetching certificate template list for the organization
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(CertificateRepository) private certificateRepo: CertificateRepository,
        @inject(CertificateTemplateRepository)
        private certificateTemplateRepo: CertificateTemplateRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes the GetShareableCertificateData use case.
     * Input is of type GetShareableCertificateDataRequest
     * @returns GetShareableCertificateDataResponse if execution was successful
     * @returns InvalidArgument if one of properties in GetShareableCertificateDataRequest was not valid
     */
    public async execute({
        organizationId,
    }: GetShareableCertificateDataRequest): AsyncResult<
        GetShareableCertificateDataResponse,
        InvalidArgument | OperationFailed
    > {
        const shareableData = await this.certificateRepo.getShareableCertificateData(
            organizationId,
        );

        if (isErr(shareableData)) {
            return handleOperationFailure(shareableData);
        }

        const templateList =
            await this.certificateTemplateRepo.getCertificateTemplatesForOrganization(
                organizationId,
            );

        if (isErr(templateList)) {
            return handleOperationFailure(templateList);
        }

        const response: GetShareableCertificateDataResponse = {
            shareableCertificateData: shareableData.value.toDTO(),
            certificateTemplateList: (templateList.value as CertificateTemplate[]).map(
                (template: CertificateTemplate) => template.toDTO(),
            ),
        };

        return Success(response);
    }
}

export const GetShareableCertificateData: UseCaseClass<GetShareableCertificateData> =
    GetShareableCertificateDataUseCase;
