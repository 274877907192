import {
    API,
    DataEnricherCompany,
    UserGetEnricherCompanyRequest,
} from '@embroker/shotwell-api/app';
import { injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult, handleOperationFailure, isErr } from '@embroker/shotwell/core/types/Result';
import { DataEnricherRepository } from '.';
import { EnricherCompany } from '../../types/EnricherCompany';
import { NumberRangeOfW2Employees } from '../../../shopping/types/enums';

@injectable()
export class APIDataEnricherRepository implements DataEnricherRepository {
    async getCompany(
        domain: string,
    ): AsyncResult<EnricherCompany, InvalidArgument | OperationFailed> {
        const getEnricherCompanyRequest: UserGetEnricherCompanyRequest = {
            domain: domain,
        };
        const result = await API.request('user/get_enricher_company', getEnricherCompanyRequest);

        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return this.toEnricherCompany(result.value.Company);
    }

    private async toEnricherCompany(
        apiCompany: DataEnricherCompany,
    ): AsyncResult<EnricherCompany, InvalidArgument | OperationFailed> {
        const enricherCompany: EnricherCompany = {
            domain: apiCompany.domain,
            fundingRound: apiCompany.funding_round,
            industry: apiCompany.industry,
            lastFundingDate: apiCompany.last_funding_date,
            mailingAddress: apiCompany.mailing_address,
            mailingCity: apiCompany.mailing_city,
            mailingCountry: apiCompany.mailing_country,
            mailingState: apiCompany.mailing_state,
            mailingStreet: apiCompany.mailing_street,
            naic: apiCompany.naic,
            name: apiCompany.name,
            numEmployees: apiCompany.num_employees,
            numberRangeOfW2Employees:
                apiCompany.number_range_of_w2_employees as NumberRangeOfW2Employees,
            phoneNumber: apiCompany.phone_number,
            revenue: apiCompany.revenue,
            isRevenueLargerThan20Mil: apiCompany.revenue_lg_20_mil,
            totalFunding: apiCompany.total_funding.value,
            totalFundingCrunchbaseHandle: apiCompany.total_funding.crunchbaseHandle,
            yearFounded: apiCompany.year_founded,
            email: apiCompany.email,
        };

        const enricherCompanyResult = EnricherCompany.create(enricherCompany);
        if (isErr(enricherCompanyResult)) {
            return handleOperationFailure(enricherCompanyResult);
        }

        return enricherCompanyResult;
    }
}
