import { FormData, OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    Button,
    CheckBox,
    ColumnLayout,
    Modal,
    ModalActions,
    ModalState,
    ScrollBox,
    StackLayout,
    StatusMessage,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React, { useContext, useState } from 'react';
import { hasRole } from '../../../userOrg/entities/Session';
import { AppContext } from '../../../view/AppContext';
import { WizardForm } from '../../../view/hooks/useWizardForm';
import { Quote } from '../../entities/Quote';
import { InvalidAnnualTechFee } from '../../errors';
import { QuoteSummaryComponent } from './QuoteLandingPage';

export interface PurchaseConfirmationModalProps<T extends FormData, Q extends Quote> {
    modal: ModalState & ModalActions;
    onConfirmPurchase: () => void;
    messages: Readonly<string[]>;
    trigger: WizardForm<OpaqueForm<T>>['trigger'];
    quote?: Q;
    quoteSummary?: QuoteSummaryComponent<Q>;
    confirmationTitle?: string;
    confirmationText?: string;
}

export const brokerLegalDisclaimer = `I understand that the policy being purchased is based on the information I've submitted. To the extent the actual information in the uploaded application is different, I understand that the coverage will not be bound and pricing may change.`;

export const PurchaseConfirmationModal = function PurchaseConfirmationModal<
    T extends FormData,
    Q extends Quote,
>({
    modal,
    onConfirmPurchase,
    messages,
    trigger,
    quote,
    quoteSummary: QuoteSummary,
    confirmationTitle,
    confirmationText,
}: PurchaseConfirmationModalProps<T, Q>) {
    const { activeSession } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');
    const [checked, setChecked] = useState(false);

    const showQuoteSummary = QuoteSummary && quote;

    const title = confirmationTitle || (isBroker ? 'Bind coverage' : 'Confirm Purchase');
    const bodyText =
        confirmationText ||
        (isBroker ? brokerLegalDisclaimer : 'Continue to purchase or go back to your quote.');
    const confirmText = showQuoteSummary ? 'Confirm and Bind' : isBroker ? 'Agree' : 'Yes';
    const declineText = showQuoteSummary ? 'Go Back' : isBroker ? 'Cancel' : 'Review quotes';

    const statusMessages = messages.map((message) => {
        switch (message) {
            case InvalidAnnualTechFee().message:
                return (
                    <StatusMessage key={message} status="warning">
                        {message}{' '}
                        <TextButton
                            onClick={async () => {
                                trigger('update');
                                modal.hide();
                            }}
                        >
                            Requote
                        </TextButton>
                    </StatusMessage>
                );
            default:
                return (
                    <StatusMessage key={message} status="error">
                        {message}
                    </StatusMessage>
                );
        }
    });

    const isDisclaimerUnchecked = showQuoteSummary && isBroker && !checked;
    const isPurchaseButtonDisabled = messages.length > 0 ? true : isDisclaimerUnchecked;

    return (
        <Modal {...modal}>
            <ScrollBox>
                <StackLayout gap="32">
                    {statusMessages}
                    <StackLayout gap="24">
                        {QuoteSummary && quote ? (
                            <React.Fragment>
                                <Text style="heading 3" data-e2e="pre-bind-summary-dialog-heading">
                                    Are these details correct?
                                </Text>
                                <QuoteSummary quote={quote} />
                                {isBroker ? (
                                    <CheckBox
                                        onChange={(event) => {
                                            setChecked(event.target.checked);
                                        }}
                                        checked={checked}
                                    >
                                        <Text style="microcopy">{brokerLegalDisclaimer}</Text>
                                    </CheckBox>
                                ) : null}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Text style="heading 3">{title}?</Text>
                                <Text>{bodyText}</Text>
                            </React.Fragment>
                        )}
                    </StackLayout>
                    <ColumnLayout gap="32">
                        <Button disabled={isPurchaseButtonDisabled} onClick={onConfirmPurchase}>
                            {confirmText}
                        </Button>
                        <TextButton onClick={modal.hide}>{declineText}</TextButton>
                    </ColumnLayout>
                </StackLayout>
            </ScrollBox>
        </Modal>
    );
};
