import { ContainerModule } from '@embroker/shotwell/core/di';
import { Module } from '../../Module';
import { BrokerageQuoteRepository } from './repositories';
import { APIBrokerageQuoteRepository } from './repositories/APIBrokerageQuoteRepository';
import { GetLatestPendingSignature } from './useCases/GetLatestPendingSignature';
import { GetSignaturePacketDocuments } from './useCases/GetSignaturePacketDocuments';
import { GetSignaturePacketFileUrl } from './useCases/GetSignaturePacketFileUrl';

export const BrokerQuoteModule: Module = {
    container: new ContainerModule((bind) => {
        bind<BrokerageQuoteRepository>(BrokerageQuoteRepository)
            .to(APIBrokerageQuoteRepository)
            .inSingletonScope();
        bind<GetSignaturePacketDocuments>(GetSignaturePacketDocuments.type)
            .to(GetSignaturePacketDocuments)
            .inSingletonScope();
        bind<GetSignaturePacketFileUrl>(GetSignaturePacketFileUrl.type)
            .to(GetSignaturePacketFileUrl)
            .inSingletonScope();
        bind<GetLatestPendingSignature>(GetLatestPendingSignature.type)
            .to(GetLatestPendingSignature)
            .inSingletonScope();
    }),
};
