import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    isOK,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { Invoice } from '../entities/Invoice';
import { InvoiceRepository } from '../repositories/InvoiceRepository';
import { Payment, BundlePayment } from '../types/Payment';
import { createBundleInvoiceMap } from './GetInvoiceHistory';
import { buildBundlePaymentUI, buildSinglePaymentUI } from './GetPendingInvoices';

export interface GetSelectedInvoiceListRequest {
    invoiceIdList: UUID[];
}

export interface GetSelectedInvoiceListResponse {
    UIInvoiceList: Immutable<Payment[]>;
}

export interface GetSelectedInvoiceList extends UseCase {
    execute(
        request: GetSelectedInvoiceListRequest,
    ): AsyncResult<GetSelectedInvoiceListResponse, InvalidArgument | OperationFailed>;
}

export function buildPaymentUI(result: Immutable<Invoice[]>) {
    const singleInvoiceList = result.filter((invoice) => invoice.bundleId == undefined);
    const singleInvoiceUIList = singleInvoiceList.map((invoice) => buildSinglePaymentUI(invoice));

    const bundleInvoiceList = result.filter((invoice) => invoice.bundleId != undefined);
    const bundleInvoiceMap = bundleInvoiceList.reduce(
        (bundleInvoicesMap, invoice) =>
            createBundleInvoiceMap(bundleInvoicesMap, invoice, invoice.bundleId),
        new Map<UUID, Immutable<Invoice>[]>(),
    );
    const bundleInvoiceUIList: Immutable<BundlePayment>[] = [];
    bundleInvoiceMap.forEach((invoiceList, bundleId) => {
        const bundleUIInvoice = buildBundlePaymentUI(bundleId, invoiceList);

        if (isOK(bundleUIInvoice)) {
            bundleInvoiceUIList.push(bundleUIInvoice.value);
        }
    });
    return { singleInvoiceUIList, bundleInvoiceUIList };
}

@injectable()
export class GetSelectedInvoiceListUseCase extends UseCase {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Payments/GetSelectedInvoiceList');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(InvoiceRepository) private invoiceRepo: InvoiceRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        input: GetSelectedInvoiceListRequest,
    ): AsyncResult<GetSelectedInvoiceListResponse, InvalidArgument | OperationFailed> {
        if (input.invoiceIdList.length === 0) {
            return Success({ UIInvoiceList: [] });
        }

        const result = await this.invoiceRepo.getByIds(input.invoiceIdList);

        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        if (result.value.length == 0) {
            return Failure(OperationFailed({ message: 'Expected at least one selected invoice' }));
        }

        const { singleInvoiceUIList, bundleInvoiceUIList } = buildPaymentUI(result.value);

        return Success({ UIInvoiceList: [...singleInvoiceUIList, ...bundleInvoiceUIList] });
    }
}

export const GetSelectedInvoiceList: UseCaseClass<GetSelectedInvoiceList> =
    GetSelectedInvoiceListUseCase;
