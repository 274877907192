import { hasOwnProp, IndexableObject, isObject } from '@embroker/shotwell/core/object';
import { Matcher } from 'navi';

/**
 * Type of the authentication session required for a route.
 * The 'none' AuthType is used for unauthenticated session.
 */
export type AuthType = 'default' | 'none' | 'any';

export type RouteMatchers<T extends object = IndexableObject> = Record<string, Matcher<T>>;

/**
 * A set of route definitions.
 */
export interface RouteDefinitions<T extends object = IndexableObject> {
    [uri: string]: Matcher<T> | Route<T>;
}

/**
 * Route definition.
 */
export type Route<T extends object> = MatcherWithAuth<T> | RouteGroupDefinition<T>;

/**
 * Wraps any Navi matcher with the specific AuthType.
 */
export interface MatcherWithAuth<T extends object> {
    /**
     * What type of session authentication is required for this route.
     * Defaults to 'default' which means that the session needs to be
     * authenticated with the default authentication strategy.
     *
     * For unauthenticated routes, use 'none' strategy.
     */
    readonly auth: AuthType;
    /**
     * Indicator if this route is organization specific.
     * By default it is true for all routes.
     */
    readonly isOrganizationSpecific?: boolean;
    /**
     * Navi matcher function.
     */
    readonly handler: Matcher<T> | RouteGroupDefinition<T>;
}

export interface RouteGroupDefinition<T extends object = IndexableObject> {
    readonly type: 'group';
    readonly routes: RouteDefinitions<T>;
}

export function group<T extends object>(
    options: {
        auth: AuthType;
        isOrganizationSpecific?: boolean;
    },
    definitions: RouteDefinitions<T>,
): MatcherWithAuth<T>;
export function group<T extends object>(definitions: RouteDefinitions<T>): RouteGroupDefinition<T>;
export function group<T extends object>(
    options:
        | {
              auth: AuthType;
              isOrganizationSpecific?: boolean;
          }
        | RouteDefinitions<T>,
    definitions?: RouteDefinitions<T>,
): RouteGroupDefinition<T> | MatcherWithAuth<T> {
    if (definitions !== undefined) {
        return {
            auth: options.auth as AuthType,
            isOrganizationSpecific:
                typeof options.isOrganizationSpecific === 'boolean'
                    ? options.isOrganizationSpecific
                    : undefined,
            handler: {
                type: 'group',
                routes: definitions,
            },
        };
    }
    return {
        type: 'group',
        routes: options as RouteDefinitions<T>,
    };
}

export function isMatcherWithAuth<T extends object>(route: unknown): route is MatcherWithAuth<T> {
    return hasOwnProp(route, 'auth') && hasOwnProp(route, 'handler');
}

export function isRouteGroupDefinition<T extends object>(
    route: unknown,
): route is RouteGroupDefinition<T> {
    return (
        hasOwnProp(route, 'type') && route.type === 'group' && hasOwnProp(route, 'routes', isObject)
    );
}
