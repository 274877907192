import { valueObject } from '@embroker/shotwell/core/types/ValueObject';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface BundleQuoteUserInfo {
    readonly fullName: string;
    readonly title: string;
}

export const BundleQuoteUserInfo = valueObject({
    ...defineValidator<BundleQuoteUserInfo>({
        fullName: Joi.string().required(),
        title: Joi.string().required(),
    }),
});
