import { Form } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { BundleQuoteFormData } from '../../../types/BundleQuoteFormData';
import { BundleQuoteCoverageListProps } from '../../../view/components/BundleQuoteCoverageList';
import { SignInsuranceCowbellAttestationSignature } from './SignInsuranceCowbellAttestationSignature';
import { checkIsRequiredApplicationAttestation } from '../../../view/components/createBundleQuoteForm';

export function SignInsuranceCowbellAttestationSignatureWrapper({
    fields,
    setValue,
    value,
    trigger,
    documents,
    bundleQuote,
}: BundleQuoteCoverageListProps<BundleQuoteFormData>) {
    const isRequiredApplicationAttestation = checkIsRequiredApplicationAttestation(bundleQuote);

    if (!isRequiredApplicationAttestation) {
        return null;
    }

    return (
        <Form.Field
            type={fields.applicationAttestationSignature.type}
            messages={fields.applicationAttestationSignature.messages}
            inputProps={{
                checked: fields.applicationAttestationSignature.props.value,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    setValue({
                        ...value,
                        applicationAttestationSignature: event.target.checked,
                    });
                    trigger('sign');
                },
                children: (
                    <SignInsuranceCowbellAttestationSignature
                        documents={documents}
                        trigger={trigger}
                    />
                ),
            }}
        />
    );
}
