import { JSONSerdes } from '@embroker/shotwell/core/encoding';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ErrorPage } from '@embroker/shotwell/view/components/ErrorPage';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Spinner } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { GetFileList } from '../../../../documents/useCases/GetFileList';
import { GetActiveUserProfile } from '../../../../userOrg/useCases/GetActiveUserProfile';
import { GetApplication } from '../../../useCases/GetApplication';
import { SubmitForReviewForm, SubmitForReviewFormProps } from './SubmitForReviewForm';

interface SubmitForReviewProps {
    applicationId: UUID;
}

export interface SubmitForReviewData {
    readonly fullName: string;
    readonly email: EmailAddress;
    readonly additionalEmails?: EmailAddress[];
    readonly additionalInformation?: string;
}

interface QuestionnaireData {
    broker_first_name: string;
    broker_last_name: string;
    broker_email: EmailAddress;
}

export function SubmitForReview({ applicationId }: SubmitForReviewProps) {
    const { result: getApplicationResult, isLoading: isLoadingApplication } = useUseCase(
        GetApplication,
        {
            applicationId,
        },
    );

    const { result: userProfileResult, isLoading: isLoadingActiveUserProfile } =
        useUseCase(GetActiveUserProfile);

    const { result: getFileListResult, isLoading: isLoadingFileList } = useUseCase(GetFileList, {
        fileKeyList: [],
        applicationId,
    });

    if (
        getApplicationResult === undefined ||
        userProfileResult === undefined ||
        getFileListResult === undefined ||
        isLoadingApplication ||
        isLoadingActiveUserProfile ||
        isLoadingFileList
    ) {
        return <Spinner />;
    }

    if (isErr(getApplicationResult)) {
        return <ErrorPage errors={getApplicationResult.errors} />;
    }

    if (isErr(userProfileResult)) {
        return <ErrorPage errors={userProfileResult.errors} />;
    }

    if (isErr(getFileListResult)) {
        return <ErrorPage errors={getFileListResult.errors} />;
    }

    const application = getApplicationResult.value.application;

    const questionnaireDataResult = JSONSerdes.deserialize(application.questionnaireData ?? '');
    if (isErr(questionnaireDataResult)) {
        return <ErrorPage errors={questionnaireDataResult.errors} />;
    }

    const questionnaireDataParsed = questionnaireDataResult.value as QuestionnaireData;

    let userFirstName = userProfileResult.value.firstName;
    let userLastName = userProfileResult.value.lastName;
    let userEmail = userProfileResult.value.email;

    if (
        questionnaireDataParsed.broker_first_name &&
        questionnaireDataParsed.broker_last_name &&
        questionnaireDataParsed.broker_email
    ) {
        userFirstName = questionnaireDataParsed.broker_first_name;
        userLastName = questionnaireDataParsed.broker_last_name;
        userEmail = questionnaireDataParsed.broker_email;
    }

    let submitForReviewData: SubmitForReviewData = {
        fullName: `${userFirstName} ${userLastName}`,
        email: userEmail,
        additionalInformation: `I would like to submit this for review. I have attached the requested documents.\nThanks`,
    };

    if (application.supplementalQuestionnaireData !== undefined) {
        const submitForReviewQuestionnaire = JSONSerdes.deserialize(
            application.supplementalQuestionnaireData,
        );
        if (isErr(submitForReviewQuestionnaire)) {
            return <ErrorPage errors={submitForReviewQuestionnaire.errors} />;
        }

        submitForReviewData = submitForReviewQuestionnaire.value as SubmitForReviewData;
    }

    const documents = getFileListResult.value;

    const availableTypes = [
        'Document',
        'ApplicationSignedDocument',
        'ApplicationPrimaryCarrierPolicyDocument',
    ];
    const files = documents
        .filter((d) => availableTypes.includes(d.type))
        .map((d) => ({
            id: d.id,
            file: new File([], d.name),
            s3FileKey: d.fileKey,
            uploadedFileSize: d.size,
        }));

    const submitForReviewFormProps: SubmitForReviewFormProps = {
        applicationId,
        submitForReviewData,
        files,
    };

    return <SubmitForReviewForm {...submitForReviewFormProps} />;
}
