import React from 'react';
import { CenterLayout, Image, Text, BoxLayout, StackLayout } from '@embroker/ui-toolkit/v2';
import { Link } from '../../../../view/components';

export function UnavailablePage() {
    return (
        <BoxLayout gap="56">
            <StackLayout gap="32">
                <CenterLayout>
                    <Image inline={false} name="shotwell-construction" />
                </CenterLayout>
                <CenterLayout centerText>
                    <StackLayout gap="32">
                        <Text as="h1" style="heading 1">
                            Whoops!
                        </Text>
                        <Text as="p" style="body 1">
                            This page is temporarily unavailable.
                            <br />
                            Please try again later or{' '}
                            <Link href="/support/contact">contact us</Link>.
                        </Text>
                    </StackLayout>
                </CenterLayout>
            </StackLayout>
        </BoxLayout>
    );
}
