import { TabNavigation } from '@embroker/ui-toolkit/v2';
import React, { useContext } from 'react';
import { hasRole } from '../../../userOrg/entities/Session';
import { AppContext } from '../../../view/AppContext';
import { Link } from '../../../view/components';

export function PaymentsSubNavigation() {
    const { activeSession } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');

    return (
        <TabNavigation>
            <React.Fragment>
                <TabNavigation.Item
                    data-e2e="payments-sub-nav-due-to-embroker"
                    as={Link}
                    exact
                    href="/payments"
                >
                    Due to Embroker
                </TabNavigation.Item>
                {!isBroker && (
                    <TabNavigation.Item
                        data-e2e="payments-sub-nav-due-to-carriers"
                        as={Link}
                        exact
                        href="/payments/carriers"
                    >
                        Due to Carriers
                    </TabNavigation.Item>
                )}
                <TabNavigation.Item
                    data-e2e="payments-sub-nav-history"
                    as={Link}
                    exact
                    href="/payments/history"
                >
                    Payments History
                </TabNavigation.Item>
                <TabNavigation.Item
                    data-e2e="payments-sub-nav-faq"
                    as={Link}
                    exact
                    href="/payments/faqs"
                >
                    FAQ
                </TabNavigation.Item>
            </React.Fragment>
        </TabNavigation>
    );
}
