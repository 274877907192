import { StackLayout, InsetBox, ComparisonTable, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';

export const ESPEoCyberDifferenceModal = function ESPEoCyberDifferenceModal() {
    return (
        <StackLayout>
            <Text style="heading 3">What is “Plus” E&amp;O coverage?</Text>
            <Text>
                Cover an even broader range of risks with protection that's considered to be the
                best coverage available in the market.
            </Text>
            <InsetBox>
                <ComparisonTable
                    comparisonData={[
                        {
                            topic: (
                                <React.Fragment>
                                    <Text style="heading 4">
                                        Added Coverage for when you are entering into a contract:
                                    </Text>
                                    <Text style="body 2">
                                        Like most good Technology E&amp;O policies available today,
                                        our “Regular” level policy provides cover for breach of
                                        contract and negligence. Our “Plus” policy goes beyond that
                                        to provide best-in-class enhancements to protect your
                                        company against claims coming out of your client / end-user
                                        engagements. Here are the highlights:
                                    </Text>
                                    <ol type="a">
                                        <li>
                                            <Text style="body 2">
                                                Express coverage for liability of others (indemnity)
                                                that you assume in a contract or agreement
                                            </Text>
                                        </li>
                                        <li>
                                            <Text style="body 2">
                                                In a traditional policy, if your client has a breach
                                                in confidential information they would need to file
                                                a claim against your firm to trigger your policy's
                                                coverage. Our policy has the ability to act as an
                                                expense fund if you have agreed (in the contract) to
                                                pay those expenses; e.g. notification costs,
                                                forensic expenses, PR costs, etc. Meaning, our
                                                policy will pay for these costs without the need for
                                                the filing of a claim potentially saving the
                                                relationship with that client and your company's
                                                reputation more generally.
                                            </Text>
                                        </li>
                                        <li>
                                            <Text style="body 2">
                                                Coverage for a return of paid customer fees when the
                                                return is awarded as damages or a part of the
                                                damages. The return of paid fees typically
                                                considered a business expense and is broadly
                                                excluded from nearly all Technology E&amp;0
                                                policies.
                                            </Text>
                                        </li>
                                    </ol>
                                </React.Fragment>
                            ),
                            plus: true,
                            standard: false,
                        },
                        {
                            topic: (
                                <React.Fragment>
                                    <Text style="heading 4">Coverage add-ons:</Text>
                                    <Text style="body 2">
                                        The “Plus” policy includes a host of policy enhancements
                                        that put the form in a position among market leaders. These
                                        include: Pay on Behalf Ransomware coverage, Computer System
                                        Betterment, Bricking and Reputational Harm coverage, Utility
                                        Fraud coverage, Funds Transfer Fraud, Invoice and Delivery
                                        Fraud, and Voluntary Shutdown coverage.
                                    </Text>
                                </React.Fragment>
                            ),
                            plus: true,
                            standard: false,
                        },
                        {
                            topic: (
                                <React.Fragment>
                                    <Text style="heading 4">Social Engineering</Text>
                                    <Text style="body 2">
                                        Subject to a minimum retention of $25,000, Social
                                        Engineering fraud coverage is included in the “Plus” policy.
                                        Social Engineering fraud is a type of “spoofing” or
                                        “phishing” where an employee is misled into believing he or
                                        she is communicating with a vendor or senior executive at
                                        the company (most often CFO). The employee is tricked into
                                        sending money or sensitive data to the person alleging to be
                                        the vendor or executive. Our policy will cover these
                                        fraudulent acts by reimbursing the company for lost funds or
                                        by providing coverage for “misrepresentations of fact or an
                                        intentional, malicious, wilful, dishonest, or fraudulent act
                                        undertaken by a third party that misleads an employee.”
                                    </Text>
                                </React.Fragment>
                            ),
                            plus: true,
                            standard: false,
                        },
                    ]}
                />
            </InsetBox>
        </StackLayout>
    );
};
