import React from 'react';
import { Text, Icon, GridLayout, BlockButton } from '@embroker/ui-toolkit/v2';
import { Link } from '../../../view/components';

enum Destination {
    Claims = '/claims',
    Shop = '/shopping',
    Policy = '/policies',
    Certificate = '/certificates',
}

export function QuickLinks() {
    return (
        <GridLayout>
            <Text style="heading 4" className="u-grid-size-12">
                Looking For Something?
            </Text>
            <BlockButton as={Link} className="u-grid-size-6" href={Destination.Claims}>
                <Icon name="file-claim" />
                <Text style="label 1" className="u-grid-size-12">
                    File a claim
                </Text>
            </BlockButton>
            <BlockButton as={Link} className="u-grid-size-6" href={Destination.Shop}>
                <Icon name="shield" />
                <Text style="label 1" className="u-grid-size-12">
                    Add new coverage
                </Text>
            </BlockButton>
            <BlockButton as={Link} className="u-grid-size-6" href={Destination.Policy}>
                <Icon name="edit" />
                <Text style="label 1" className="u-grid-size-12">
                    Modify your policy
                </Text>
            </BlockButton>
            <BlockButton className="u-grid-size-6" as="a" href="#live-agent">
                <Icon name="chat" />
                <Text style="label 1" className="u-grid-size-12">
                    Chat with an Embroker Advisor
                </Text>
            </BlockButton>
            <BlockButton as={Link} className="u-grid-size-6" href={Destination.Certificate}>
                <Icon name="certificate" />
                <Text style="label 1" className="u-grid-size-12">
                    Send or request a certificate
                </Text>
            </BlockButton>
        </GridLayout>
    );
}
