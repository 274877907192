import { Modal, ModalActions, ModalState } from '@embroker/ui-toolkit/v2';
import React, { useContext } from 'react';
import { hasRole } from '../../../userOrg/entities/Session';
import { AppContext } from '../../../view/AppContext';
import { useNavigation } from '../../../view/hooks/useNavigation';
import { ModalLayout } from '../../../view/components/ModalLayout.view';

interface ThankYouErrorModalInput {
    modal: ModalState & ModalActions;
}

export function ThankYouErrorModal({ modal }: ThankYouErrorModalInput) {
    const { navigate } = useNavigation();
    const { activeSession } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');

    const goToDashboard = isBroker
        ? () => navigate('/broker/dashboard')
        : () => navigate('/summary');

    return (
        <Modal {...modal} size="small" dismissable={false}>
            <ModalLayout
                title="Thank you for your application!"
                primaryAction={{
                    label: 'Back to Dashboard',
                    onClick: goToDashboard,
                    'data-e2e': 'back-to-dashboard',
                }}
            >
                We need to crunch a few more numbers before your quote is ready. One of our
                representatives will contact you shortly.
            </ModalLayout>
        </Modal>
    );
}
