import React, { useEffect } from 'react';
import { OnboardingPageProps, Page } from './OnboardingFlowWrapper';
import { NAICS_CODE_TO_VERTICAL } from '@app/userOrg/types/enums';
import { LawVerticalRefinmentQuestion } from './LawVerticalRefinmentQuestion';
import { StaffDetailsPage } from './StaffDetailsPage';
import { PublishOnboardingStepVisitedEvent } from '@app/userOrg/useCases/PublishOnboardingStepVisitedEvent';
import { execute } from '@embroker/shotwell/core/UseCase';
import { UserOnboardingSubStepType } from '@app/userOrg/types/UserOnboardingDetails';

const lawVerticalRefinmentPage: Page<OnboardingPageProps> = {
    name: 'Law Vertical Onboarding',
    component: LawVerticalRefinmentQuestion,
};

const staffDetailsPage: Page<OnboardingPageProps> = {
    name: 'Staff Details Onboarding',
    component: StaffDetailsPage,
};

export const industryRefinmentComponentMap: {
    [key: string]: Page<OnboardingPageProps>;
} = {
    LawFirm: lawVerticalRefinmentPage,
    TechCompanies: staffDetailsPage,
} as const;

export const verticalStepMap: {
    [key: string]: UserOnboardingSubStepType;
} = {
    LawFirm: 'lawVerticalRefinement',
    TechCompanies: 'staffDetailsPage',
};

export const IndustryRefinmentPage = (props: OnboardingPageProps) => {
    const { questionnaireData, onCompleteOnboardingStep } = props;

    const vertical = NAICS_CODE_TO_VERTICAL[questionnaireData?.naics_code || ''];
    const Component = industryRefinmentComponentMap[vertical].component;

    useEffect(() => {
        const subStep = verticalStepMap[vertical];
        if (subStep) {
            execute(PublishOnboardingStepVisitedEvent, {
                step: 'lawVerticalRefinement',
            });
        }
        // We only wnat to fire this event on page init
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!Component) {
            onCompleteOnboardingStep();
        }
    }, [Component, onCompleteOnboardingStep]);

    return <Component {...props} />;
};
