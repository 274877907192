import { AppTypeCodeList, AppTypeCodeListItem } from '@embroker/shotwell-api/enums';
import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { IndexableObject, isObject } from '@embroker/shotwell/core/object';
import { Joi } from '@embroker/shotwell/core/validation/schema';

export interface DeepLinkData {
    appTypes?: AppTypeCodeListItem[];
    naicsCode?: string;
    raisedFunding?: boolean;
    firstPage?: string;
    includeTechEO?: boolean;
}

export const DeepLinkData = {
    schema: Joi.object({
        appTypes: Joi.array()
            .items(Joi.string().allow(...AppTypeCodeList))
            .optional(),
        naicsCode: Joi.string().optional(),
        raisedFunding: Joi.boolean().optional(),
        firstPage: Joi.string().optional(),
        includeTechEO: Joi.boolean().optional(),
    }),
    /**
     * Parse DeepLinkData from a JSON string passed via query parameter 'qd' in deep link URLs.
     *
     * @param jsonLinkData The JSON-encoded DeepLinkData object.
     * @param overrides Optional overrides that take precedence over the parsed data.
     */
    parse: function parseDeepLinkData(
        jsonLinkData?: string,
        overrides: Partial<DeepLinkData> = {},
    ): DeepLinkData {
        let linkData: DeepLinkData = {};
        if (typeof jsonLinkData === 'string') {
            try {
                const result = DeepLinkData.schema
                    .rename('app_type_list', 'appTypes')
                    .rename('naics_code', 'naicsCode')
                    .validate(JSON.parse(jsonLinkData), {
                        abortEarly: false,
                        convert: true,
                        stripUnknown: true,
                    });
                if (isObject(result.value)) {
                    linkData = result.value;
                }
                if (isObject(result.error)) {
                    for (const { path } of result.error.details) {
                        delete (linkData as IndexableObject)[path[0]];
                    }
                }
            } catch (error) {
                container.get<Logger>(Log).error(error);
            }
        }
        return Object.assign(linkData, overrides);
    },
};
