import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { PolicyRepository } from '../repositories/PolicyRepository';
import { Nullable } from '@embroker/shotwell/core/types';

export interface GetPolicyTransferRequestSignedDate extends UseCase {
    execute(policyId: UUID): AsyncResult<Nullable<Date> | InvalidArgument | OperationFailed>;
}

@injectable()
export class GetPolicyTransferRequestSignedDateUseCase extends UseCase {
    public static type = Symbol('Policy/GetPolicyTransferRequestSignedDateUseCase');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(PolicyRepository) private policyRepository: PolicyRepository,
    ) {
        super(eventBus);
    }

    async execute(policyId: UUID): AsyncResult<Nullable<Date> | InvalidArgument | OperationFailed> {
        return await this.policyRepository.getPolicyTransferRequestSignedDate(policyId);
    }
}

export const GetPolicyTransferRequestSignedDate: UseCaseClass<GetPolicyTransferRequestSignedDate> =
    GetPolicyTransferRequestSignedDateUseCase;
