import React from 'react';
import { Immutable } from '@embroker/ui-toolkit/v2';
import { BOPChubbQuoteOptions } from '@app/bundle/coverageDefinition/bopChubb/types/BOPChubbQuoteOptions';
import { LPLQuoteOptions } from '@app/quote/lpl/types/LPLQuoteOptions';
import { LawCyberQuoteOptions } from '@app/bundle/coverageDefinition/lawCyber/types/LawCyberQuoteOptions';
import { CowbellCyberQuoteOptions } from '@app/bundle/coverageDefinition/cowbellCyber/types/CowbellCyberQuoteOptions';
import { WCChubbQuoteOptions } from '@app/bundle/coverageDefinition/wcChubb/types/WCChubbQuoteOptions';
import { BundleQuoteCoverageType } from '@app/bundle/types/BundleQuoteCoverage';
import { BundleQuoteComparisonContentComponent } from './BundleQuoteComparisonContentComponent.view';

export function BundleQuoteComparisonContentRenderer(coverage: Immutable<BundleQuoteCoverageType>) {
    switch (coverage.type) {
        case 'BOPChubbCoverage':
            return (
                <BundleQuoteComparisonContentComponent
                    content={[
                        {
                            value: (coverage.quote?.options as BOPChubbQuoteOptions)
                                .perOccurrenceLimit,
                            title: 'Per Occurence Limit',
                        },
                    ]}
                />
            );
        case 'LPLBundleCoverage':
            return (
                <BundleQuoteComparisonContentComponent
                    content={[
                        {
                            value: (coverage.quote?.options as LPLQuoteOptions).perClaimLimit,
                            title: 'Per Claim Limit',
                        },
                        {
                            value: (coverage.quote?.options as LPLQuoteOptions).perClaimDeductible,
                            title: 'Per Claim Deductible',
                        },
                        ...((coverage.quote?.options as LPLQuoteOptions).claimsExpenseType ===
                        'ClaimsExpenseTypeSeparateClaimsExpenses'
                            ? [
                                  {
                                      value: 'Capped',
                                      title: 'Defense Cost Limit',
                                  },
                              ]
                            : (coverage.quote?.options as LPLQuoteOptions).claimsExpenseType ===
                              'ClaimsExpenseTypeInAdditionToLimits'
                            ? [
                                  {
                                      value: 'Uncapped',
                                      title: 'Defense Cost Limit',
                                  },
                              ]
                            : []),
                    ]}
                />
            );
        case 'LawCyberBundleCoverage':
            return (
                <BundleQuoteComparisonContentComponent
                    content={[
                        {
                            value: (coverage.quote?.options as LawCyberQuoteOptions).limit,
                            title: 'Limit',
                        },
                    ]}
                />
            );
        case 'LawCyberBundleCoverageCowbell':
            return (
                <BundleQuoteComparisonContentComponent
                    content={[
                        {
                            value: (coverage.quote?.options as CowbellCyberQuoteOptions).limit,
                            title: 'Limit',
                        },
                        {
                            value: (coverage.quote?.options as CowbellCyberQuoteOptions).retention,
                            title: 'Deductible',
                        },
                    ]}
                />
            );
        case 'WCChubbCoverage':
            return (
                <BundleQuoteComparisonContentComponent
                    content={[
                        {
                            value: (coverage.quote?.options as WCChubbQuoteOptions).policyLimit,
                            title: 'Employers Liability Policy Limit',
                        },
                    ]}
                />
            );
        case 'MPLCoverage':
        default:
            return <div></div>;
    }
}
