import { Form, FormElement } from '@embroker/service-app-engine';
import { FormLayout } from '@embroker/ui-toolkit/v2';
import React from 'react';

export interface PlainFormEngineProps {
    instance: FormElement;
    readOnly?: boolean;
    title?: string;
}

export const PlainFormEngine = function PlainFormEngine({
    instance,
    readOnly,
}: PlainFormEngineProps) {
    const headerStyle = instance.machine.state.headerStyle ? ['em-h3--overline'] : [];
    const classNames = ['s-form-engine', [...headerStyle]].join(' ');
    return (
        <FormLayout className={classNames}>
            <Form instance={instance} readOnly={readOnly} />
        </FormLayout>
    );
};
