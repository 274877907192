import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { PaymentsStripePaymentStatus } from '@embroker/shotwell-api/app';
import { PaymentsPurchaseTypeCodeListItem } from '@embroker/shotwell-api/enums';
import { InvoiceItem } from './InvoiceItem';
import { Money } from '@embroker/shotwell/core/types/Money';
import { UUID } from '@embroker/shotwell/core/types/UUID';

export interface SinglePayment {
    readonly id: UUID;
    readonly carrierName?: string;
    readonly organizationId: UUID;
    readonly invoiceNumber: string;
    readonly total: Money;
    readonly balance: Money;
    readonly createdDate: Nullable<Date>;
    readonly lineOfBusiness: string;
    readonly policyNumber: string;
    readonly policyId?: UUID;
    readonly policyEffectiveDate: Date;
    readonly status: PaymentsStripePaymentStatus;
    readonly purchaseType?: PaymentsPurchaseTypeCodeListItem;
    readonly isEndorsement: boolean;
    readonly isNotEligibleForFinancing: boolean;
    readonly invoiceItemList: InvoiceItem[];
    readonly billingName: string;
    readonly description: string;
}

export const SinglePayment = {
    ...defineValidator<SinglePayment>({
        id: UUID.schema,
        carrierName: Joi.string().optional(),
        organizationId: UUID.schema,
        invoiceNumber: Joi.string(),
        total: Money.schema,
        balance: Money.schema,
        createdDate: Joi.date().allow(null),
        lineOfBusiness: Joi.string(),
        policyNumber: Joi.string(),
        policyId: UUID.schema.optional(),
        policyEffectiveDate: Joi.date(),
        status: Joi.string(),
        purchaseType: Joi.string().optional(),
        isEndorsement: Joi.boolean(),
        isNotEligibleForFinancing: Joi.boolean(),
        invoiceItemList: Joi.array().items(InvoiceItem.schema),
        billingName: Joi.string(),
        description: Joi.string(),
    }),
    create(singlePayment: SinglePayment) {
        return SinglePayment.validate(singlePayment);
    },
};

export interface BundlePayment {
    balance: Money;
    createdDate: Nullable<Date>;
    id: UUID;
    invoiceList: Immutable<SinglePayment>[];
    purchaseType?: PaymentsPurchaseTypeCodeListItem;
    isEndorsement: boolean;
    policyId?: UUID;
    policyEffectiveDate: Date;
    isNotEligibleForFinancing: boolean;
    lineOfBusiness: string;
    billingName: string;
    total: Money;
}

export const BundlePayment = {
    ...defineValidator<BundlePayment>({
        balance: Money.schema,
        createdDate: Joi.date().allow(null),
        id: UUID.schema,
        invoiceList: Joi.array().items(SinglePayment.schema),
        purchaseType: Joi.string().optional(),
        isEndorsement: Joi.boolean(),
        policyId: UUID.schema.optional(),
        policyEffectiveDate: Joi.date(),
        isNotEligibleForFinancing: Joi.boolean(),
        lineOfBusiness: Joi.string(),
        billingName: Joi.string(),
        total: Money.schema,
    }),
    create(bundlePayment: BundlePayment) {
        return BundlePayment.validate(bundlePayment);
    },
};

export function isBundlePayment(invoice: Immutable<Payment>): invoice is Immutable<BundlePayment> {
    return Object.prototype.hasOwnProperty.call(invoice, 'invoiceList');
}

export type Payment = Immutable<BundlePayment | SinglePayment>;

export const Payment = {
    ...defineValidator<Payment>(Joi.alternatives(SinglePayment.schema, BundlePayment.schema)),
    create(payment: Payment) {
        return Payment.validate(payment);
    },
};
