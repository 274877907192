import { Modal, ModalActions, ModalState } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { ModalLayout } from '../../../../view/components/ModalLayout.view';

export function SuccessModal({ modal }: { modal: ModalActions & ModalState }) {
    return (
        <Modal {...modal} size="small" dismissable>
            <ModalLayout
                title="Success!"
                primaryAction={{
                    label: 'Got It',
                    onClick: () => modal.hide(),
                }}
            >
                Your request has been forwarded to the claims division and the report will be
                emailed to your shortly.
            </ModalLayout>
        </Modal>
    );
}
