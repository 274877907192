import { ConditionalAction } from './ConditionalAction';
import { Answer, QuestionerQuestion } from './QuestionerQuestionType';

function getUniqueQuestions(questions: QuestionerQuestion[]): QuestionerQuestion[] {
    const questionMap = new Map<string, QuestionerQuestion>();

    for (const question of questions) {
        const { key } = question;
        if (!questionMap.has(question.key)) {
            questionMap.set(key, question);
        }
    }

    return Array.from(questionMap.values());
}

export function getExternalQuestions(questions: QuestionerQuestion[]): QuestionerQuestion[] {
    const externalQuestionsFromConditionalActions =
        getExternalQuestionsFromConditionalActions(questions);
    const externalQuestionsFromDynamicText = getExternalQuestionsFromDynamicText(questions);

    const uniqueQuestions = getUniqueQuestions(
        externalQuestionsFromConditionalActions.concat(externalQuestionsFromDynamicText),
    );

    return uniqueQuestions;
}

export function getExternalQuestionsFromDynamicText(
    questions: QuestionerQuestion[],
): QuestionerQuestion[] {
    const allquestionKeys = questions.map(({ key }) => key);
    const dynamicTextFromQuestions = questions
        .filter(({ text }) => text.some(({ dynamic_text }) => Boolean(dynamic_text)))
        .map(({ text }) => text);

    const answerMap = new Map<string, Answer>();

    for (const dynamicText of dynamicTextFromQuestions) {
        for (const text of dynamicText) {
            if (text.dynamic_text) {
                for (const textElement of text.dynamic_text) {
                    if (textElement.answer && !allquestionKeys.includes(textElement.answer.key)) {
                        answerMap.set(textElement.answer.key, textElement.answer);
                    }
                }
            }
        }
    }

    const questionDefinitions = Array.from(answerMap.values()).map((answer) =>
        getQuestionerQuestionFromAnswer(answer),
    );

    return questionDefinitions;
}

export function getExternalQuestionsFromConditionalActions(
    questions: QuestionerQuestion[],
): QuestionerQuestion[] {
    const allquestionKeys = questions.map(({ key }) => key);
    const conditionalActionsFromQuestions = questions
        .filter(({ conditional_actions }) => Boolean(conditional_actions))
        .map(({ conditional_actions }) => conditional_actions);

    const combinedConditionalActions = conditionalActionsFromQuestions.reduce(
        (acc, currentArray) => {
            if (acc && currentArray) {
                return acc.concat(currentArray);
            }
        },
        [] as ConditionalAction[],
    );

    const answerMap = new Map<string, Answer>();

    if (!combinedConditionalActions) {
        return [];
    }

    for (const conditionalAction of combinedConditionalActions) {
        for (const rule of conditionalAction.rules) {
            for (const condition of rule) {
                // If the condition has an answer and the answer key is not a question in the form, then add it to answerMap
                if (condition.answer && !allquestionKeys.includes(condition.answer.key)) {
                    answerMap.set(condition.answer.key, condition.answer);
                }
            }
        }
    }

    const questionDefinitions = Array.from(answerMap.values()).map((answer) =>
        getQuestionerQuestionFromAnswer(answer),
    );

    return questionDefinitions;
}

function getQuestionerQuestionFromAnswer(answer: Answer): QuestionerQuestion {
    return {
        key: answer.key,
        answer_type: answer.type,
        type: 'EXTERNAL',
        multiplicity: answer.multiplicity,
        text: [],
        inter_validation: [],
        intra_validation: {},
        current_answer: answer,
    };
}
