import { API, GlobalGetConfigResponse } from '@embroker/shotwell-api/app';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { injectable } from '@embroker/shotwell/core/di';
import {
    AsyncResult,
    Success,
    handleOperationFailure,
    isErr,
    mergeErrors,
} from '@embroker/shotwell/core/types/Result';
import { CoverageRepository, GetAllCoveragesRequest } from '.';
import { Coverage } from '../../types/Coverage';
import {
    BOP,
    CommercialAuto,
    Cyber,
    DO,
    EO,
    EPL,
    ESP,
    EmbrokerCrime,
    EmbrokerCyber,
    ExcessTechEOCyber,
    Fiduciary,
    GAWorkersComp,
    GeneralLiability,
    HOA,
    International,
    LPL,
    PCoML,
    ProductLiability,
    Property,
    TechEO,
    Umbrella,
    VentureCapitalAssetProtection,
    MPL,
    MPLBundle,
    BOPChubb,
    WCChubb,
    ManualWorkersComp,
    BOPHartFord,
} from '../../types/enums';

const VENTURE_CAPITAL_OR_PRIVATE_EQUITY = '523910';
const HOMEOWNERS_ASSOCIATION = '813990';

@injectable()
export class APICoverageRepository implements CoverageRepository {
    async getAllCoverages(
        input: GetAllCoveragesRequest,
    ): AsyncResult<Array<Coverage>, InvalidArgument | OperationFailed> {
        const configResponse = await API.request('global/get_config', {});
        if (isErr(configResponse)) {
            return handleOperationFailure(configResponse);
        }
        const config = configResponse.value as GlobalGetConfigResponse;

        let coverageList = allCoverages;
        if (!config.is_bop_enabled) {
            coverageList = coverageList.filter((item) => item != BOP);
        }
        coverageList = coverageList.filter(
            (item) => item != (config.is_embroker_cyber_enabled ? Cyber : EmbrokerCyber),
        );

        if (input.naicsCode === VENTURE_CAPITAL_OR_PRIVATE_EQUITY) {
            coverageList = coverageList.filter(
                (item) =>
                    !vCapIncompatibleCoverages.some((e) => {
                        return e === item;
                    }),
            );
        } else if (input.naicsCode === HOMEOWNERS_ASSOCIATION) {
            coverageList = coverageList.filter(
                (item) =>
                    !hoaIncompatibleCoverages.some((e) => {
                        return e === item;
                    }),
            );
        } else if (input.naicsCode !== HOMEOWNERS_ASSOCIATION) {
            coverageList = coverageList.filter((item) => item !== HOA);
        }
        const promiseResultList = await Promise.all(
            coverageList.map((item) => Coverage.create(item)),
        );
        const result: Array<Coverage> = [];
        for (const coverageResult of promiseResultList) {
            if (isErr(coverageResult)) {
                return mergeErrors(promiseResultList);
            }
            result.push(coverageResult.value);
        }
        return Success(result);
    }

    async getAllCoveragesForNoAuthUser(): AsyncResult<
        Array<Coverage>,
        InvalidArgument | OperationFailed
    > {
        const configResponse = await API.request('global/get_config', {});
        if (isErr(configResponse)) {
            return handleOperationFailure(configResponse);
        }
        const config = configResponse.value as GlobalGetConfigResponse;

        let coverageList = [...allCoverages];
        if (!config.is_bop_enabled) {
            coverageList = coverageList.filter((item) => item != BOP);
        }

        if (!config.is_hartford_enabled) {
            coverageList = coverageList.filter((item) => item != BOPHartFord);
        }

        if (!config.is_embroker_excess_enabled) {
            coverageList = coverageList.filter((item) => item != ExcessTechEOCyber);
        }
        if (config.is_mpl_enabled) {
            coverageList.push(BOPChubb);
            coverageList.push(WCChubb);
        }

        coverageList = coverageList.filter(
            (item) => item != (config.is_embroker_cyber_enabled ? Cyber : EmbrokerCyber),
        );
        const promiseResultList = await Promise.all(
            coverageList.map((item) =>
                Coverage.create({
                    ...item,
                    isSelected: false,
                }),
            ),
        );
        const result: Array<Coverage> = [];
        for (const coverageResult of promiseResultList) {
            if (isErr(coverageResult)) {
                return mergeErrors(promiseResultList);
            }
            result.push(coverageResult.value);
        }
        return Success(result);
    }
}

const allCoverages = [
    ESP,
    TechEO,
    PCoML,
    CommercialAuto,
    Cyber,
    EmbrokerCyber,
    DO,
    EPL,
    International,
    GeneralLiability,
    EO,
    LPL,
    ProductLiability,
    Property,
    GAWorkersComp,
    VentureCapitalAssetProtection,
    HOA,
    BOP,
    BOPHartFord,
    Umbrella,
    EmbrokerCrime,
    Fiduciary,
    ExcessTechEOCyber,
    MPLBundle,
    MPL,
    ManualWorkersComp,
];

const vCapIncompatibleCoverages = [DO, Fiduciary, EO, LPL];

const hoaIncompatibleCoverages = [GeneralLiability, Property, EmbrokerCrime, DO];
