import {
    defineValidator,
    Joi,
    Schema,
    TypeChecker,
    Validator,
} from '@embroker/shotwell/core/validation/schema';

export const PolicyStatusExpired = 'Expired';
export const PolicyStatusExpiring = 'Expiring';
export const PolicyStatusCurrent = 'Current';
export const PolicyStatusRenewal = 'Renewal';
export const PolicyStatusFuture = 'Future';
export const PolicyStatusActive = 'Active';
export const PolicyStatusCancelled = 'Cancelled';

const PolicyStatusList = [
    PolicyStatusExpired,
    PolicyStatusExpiring,
    PolicyStatusCurrent,
    PolicyStatusRenewal,
    PolicyStatusFuture,
    PolicyStatusActive,
    PolicyStatusCancelled,
];

/**
 * Value Object representing an policy status
 */
export type PolicyStatus = (typeof PolicyStatusList)[number];

export interface PolicyStatusValidator {
    /**
     * A Joi schema matching a valid PolicyStatus object.
     */
    readonly schema: Schema.StringSchema;
    /**
     * Type predicate that checks if a given value can be used as PolicyStatus type.
     *
     * Use this only to do early returns. It's recommended to use validate()
     * before using an unknown value as PolicyStatus value as it normalizes the value
     * in addition to performing the same validation as check().
     */
    readonly check: TypeChecker<PolicyStatus>;
    /**
     * Validates and normalizes the given value to a PolicyStatus type.
     *
     * This should be used for all untrusted inputs to verify and, if required
     * (and possible), normalize the input.
     */
    readonly validate: Validator<PolicyStatus>;
}

export const PolicyStatus: PolicyStatusValidator = defineValidator<PolicyStatus>(
    Joi.string().valid(...PolicyStatusList),
);
