import {
    Button,
    Modal,
    ModalActions,
    ModalState,
    StackLayout,
    ColumnLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { useNavigation } from '../../../view/hooks/useNavigation';

export function ClientReservationModal({ modal }: { modal: ModalActions & ModalState }) {
    const { navigate } = useNavigation();
    return (
        <Modal {...modal} size="small" dismissable={false}>
            <StackLayout gap="32">
                <StackLayout gap="24">
                    <Text style="heading 3">Something went wrong here.</Text>
                    <Text>
                        It looks like we've already received a submission for your company from
                        another brokerage. Please contact them to finalize your information.
                    </Text>
                    <Text>
                        If this is an error, please contact us via live chat or at 844.436.2765.
                    </Text>
                </StackLayout>
                <ColumnLayout gap="32">
                    <Button onClick={() => navigate('/summary')}>Back to dashboard</Button>
                    <TextButton href="#chat-now">Open live chat</TextButton>
                </ColumnLayout>
            </StackLayout>
        </Modal>
    );
}
