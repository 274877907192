import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { NumberRangeOfW2Employees } from '../../shopping/types/enums';
import { Money } from '@embroker/shotwell/core/types/Money';

export interface EnricherCompany {
    readonly yearFounded?: number;
    readonly fundingRound?: string;
    readonly industry?: string;
    readonly lastFundingDate?: string;
    readonly numEmployees?: number;
    readonly numberRangeOfW2Employees?: NumberRangeOfW2Employees;
    readonly totalFunding?: Money;
    readonly totalFundingCrunchbaseHandle?: string;
    readonly phoneNumber?: string;
    readonly revenue?: number;
    readonly isRevenueLargerThan20Mil?: boolean;
    readonly domain?: string;
    readonly mailingAddress?: string;
    readonly mailingCity?: string;
    readonly mailingCountry?: string;
    readonly mailingState?: string;
    readonly mailingStreet?: string;
    readonly naic?: string;
    readonly name?: string;
    readonly email?: string;
}

export const EnricherCompany = {
    ...defineValidator<EnricherCompany>({
        yearFounded: Joi.number().optional(),
        fundingRound: Joi.string().optional(),
        industry: Joi.string().optional(),
        lastFundingDate: Joi.string().optional(),
        numEmployees: Joi.number().optional(),
        numberRangeOfW2Employees: Joi.string().optional(),
        totalFunding: Money.schema.optional(),
        totalFundingCrunchbaseHandle: Joi.string().optional(),
        phoneNumber: Joi.string().optional(),
        revenue: Joi.number().optional(),
        isRevenueLargerThan20Mil: Joi.boolean().optional(),
        domain: Joi.string().optional(),
        mailingAddress: Joi.string().optional(),
        mailingCity: Joi.string().optional(),
        mailingCountry: Joi.string().optional(),
        mailingState: Joi.string().optional(),
        mailingStreet: Joi.string().optional(),
        naic: Joi.string().optional(),
        name: Joi.string().optional(),
        email: Joi.string().optional(),
    }),
    create(company: EnricherCompany) {
        return EnricherCompany.validate(company);
    },
};
