import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { execute } from '@embroker/shotwell/core/UseCase';
import { UpdateUserOnboardingDetails } from '../useCases/UpdateUserOnboardingDetails';
import { RemoveUserOnboardingDetails } from '../useCases/RemoveUserOnboardingDetails';
import { Nullable } from '@embroker/ui-toolkit/v2';

export const UserOnboardingStepTypeList = [
    'naicsConfirmation',
    'naicsRefinement',
    'vettingRefinement',
    'businessLocation',
    'industryRefinement',
] as const;

export type UserOnboardingStepTypeListType = typeof UserOnboardingStepTypeList;
export type UserOnboardingStepType = Nullable<UserOnboardingStepTypeListType[number]>;

export const UserOnboardingSubStepTypeList = ['lawVerticalRefinement', 'staffDetailsPage'] as const;

export type UserOnboardingSubStepTypeListType = typeof UserOnboardingSubStepTypeList;
export type UserOnboardingSubStepType = Nullable<UserOnboardingSubStepTypeListType[number]>;

export interface UserOnboardingDetails {
    /**
     * Where a user will be redirected to after onboarding is complete.
     */
    redirectUrl?: string;
}

export const UserOnboardingDetails = {
    ...defineValidator<UserOnboardingDetails>({
        redirectUrl: Joi.string().optional(),
    }),
    create(userOnboardingDetails: UserOnboardingDetails) {
        return UserOnboardingDetails.validate(userOnboardingDetails);
    },
    initializeUserOnboardingContext(redirectUrl?: string): void {
        execute(UpdateUserOnboardingDetails, { redirectUrl });
    },
    updateUserOnboardingContext({ redirectUrl }: { redirectUrl?: string }): void {
        execute(UpdateUserOnboardingDetails, { redirectUrl });
    },
    clearUserOnboardingContext(): void {
        execute(RemoveUserOnboardingDetails);
    },
};
