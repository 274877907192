import { inject } from '@embroker/shotwell/core/di';
import { Aborted, InvalidArgument, OperationFailed, Timeout } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, isOK, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { TasksRepository } from '../../../tasks/repositories';
import { CrimeQuoteOptions } from '../types/CrimeQuoteOptions';
import { CrimeQuote } from '../entities/CrimeQuote';
import { CrimeQuoteRepository } from '../repositories/CrimeQuoteRepository';
import { CalculateSKU } from '../../../analytics/useCases/CalculateSKU';
import { ApplicationQuoteCreated } from '../../../shopping/entities/Application';

export interface ReQuoteCrimeRequest {
    applicationId: UUID;
    crimeQuoteOptions: CrimeQuoteOptions;
    abortSignal: AbortSignal;
}

export interface ReQuoteCrime extends UseCase {
    execute(
        request: ReQuoteCrimeRequest,
    ): AsyncResult<CrimeQuote, InvalidArgument | OperationFailed | Timeout | Aborted>;
}

class ReQuoteCrimeUseCase extends UseCase implements ReQuoteCrime {
    public static type = Symbol('CrimeQuote/ReQuoteCrime');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(CrimeQuoteRepository) private crimeQuoteRepository: CrimeQuoteRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
        @inject(CalculateSKU.type) private calculateSku: CalculateSKU,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
        crimeQuoteOptions,
        abortSignal,
    }: ReQuoteCrimeRequest): AsyncResult<
        CrimeQuote,
        InvalidArgument | OperationFailed | Timeout | Aborted
    > {
        const reQuoteResult = await this.crimeQuoteRepository.reQuoteCrime(
            applicationId,
            crimeQuoteOptions,
        );
        if (isErr(reQuoteResult)) {
            return reQuoteResult;
        }

        const pollForTaskStatusResult = await this.tasksRepository.pollForTaskStatus(
            reQuoteResult.value,
            abortSignal,
        );
        if (isErr(pollForTaskStatusResult)) {
            return pollForTaskStatusResult;
        }

        const getCurrentQuoteResult = await this.crimeQuoteRepository.getLastCrimeQuote(
            applicationId,
        );
        if (isErr(getCurrentQuoteResult)) {
            return getCurrentQuoteResult;
        }

        const quote = getCurrentQuoteResult.value;
        await this.calculateSku
            .execute({
                event: 'quote',
                applicationId: quote.applicationId,
            })
            .then((skuResult) => {
                const event: ApplicationQuoteCreated = {
                    origin: 'Application',
                    name: 'QuoteCreated',
                    createdAt: new Date(Date.now()),
                    applicationId,
                    totalPremium: quote.totalPayable,
                    id: UUID.create(),
                    sku: isOK(skuResult) ? skuResult.value : undefined,
                    isRenewal: quote.isRenewal,
                };
                this.eventBus.publish(event);
            });
        return Success(quote);
    }
}

export const ReQuoteCrime: UseCaseClass<ReQuoteCrime> = ReQuoteCrimeUseCase;
