import { PaymentsStripePaymentStatus } from '@embroker/shotwell-api/app';
import { defineEntityValidator, entity, Entity } from '@embroker/shotwell/core/entity/Entity';
import { Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { InvoiceItem } from '../types/InvoiceItem';
import {
    PaymentsPurchaseTypeCodeList,
    PaymentsPurchaseTypeCodeListItem,
} from '@embroker/shotwell-api/enums';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';

export interface PaymentEvent extends DomainEvent {
    origin: string;
    name: string;
    paymentAmount: Money;
    paymentType: PaymentType;
}

const PaymentTypeEnum = {
    ACH: 'ach',
    CreditCard: 'credit_card',
} as const;
export type PaymentType = (typeof PaymentTypeEnum)[keyof typeof PaymentTypeEnum];

export interface OnPayment extends DomainEvent {
    readonly origin: 'Invoice';
    readonly name: 'OnPayment';
    paymentAmount: Money;
    paymentType: PaymentType;
    success: boolean;
}

export interface OnPremiumFinance extends DomainEvent {
    readonly origin: 'Invoice';
    readonly name: 'OnPremiumFinance';
    success: boolean;
}

export interface Invoice extends Entity {
    readonly organizationId: UUID;
    readonly invoiceNumber: string;
    readonly total: Money;
    readonly balance: Money;
    readonly createdDate: Nullable<Date>;
    readonly lineOfBusiness: string;
    readonly policyNumber?: string;
    readonly policyId?: UUID;
    readonly policyEffectiveDate: Date;
    readonly status: PaymentsStripePaymentStatus;
    readonly purchaseType?: PaymentsPurchaseTypeCodeListItem;
    readonly isEndorsement: boolean;
    readonly isNotEligibleForFinancing: boolean;
    readonly invoiceItemList: InvoiceItem[];
    readonly billingName: string;
    readonly description: string;
    readonly bundleId?: UUID;
    readonly carrierName?: string;
}

export const Invoice = entity<Invoice>({
    validator: defineEntityValidator<Invoice>({
        organizationId: UUID.schema,
        invoiceNumber: Joi.string(),
        total: Money.schema,
        balance: Money.schema,
        createdDate: Joi.date(),
        lineOfBusiness: Joi.string(),
        policyNumber: Joi.string().allow('').optional(),
        policyId: UUID.schema.optional(),
        policyEffectiveDate: Joi.date(),
        status: Joi.string().valid('charge', 'new', 'token', 'processing', 'voided', 'overdue'),
        purchaseType: Joi.string()
            .valid(...PaymentsPurchaseTypeCodeList)
            .optional(),
        isEndorsement: Joi.boolean(),
        isNotEligibleForFinancing: Joi.boolean(),
        invoiceItemList: Joi.array().items(InvoiceItem.schema),
        billingName: Joi.string(),
        description: Joi.string(),
        bundleId: UUID.schema.optional(),
        carrierName: Joi.string().optional(),
    }),
});
export function isActive(invoice: Invoice): boolean {
    return invoice.status == 'new';
}

export function isCredit(invoice: Invoice): boolean {
    return invoice.status == 'charge' && invoice.balance.amount < 0;
}

export function isCharged(invoice: Invoice): boolean {
    return invoice.status == 'charge' && invoice.balance.amount >= 0;
}
