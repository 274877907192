import { inject } from '@embroker/shotwell/core/di';
import {
    Aborted,
    InvalidArgument,
    OperationFailed,
    Timeout,
    UnknownEntity,
} from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Money } from '@embroker/shotwell/core/types/Money';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    isOK,
    Success,
    SuccessResult,
} from '@embroker/shotwell/core/types/Result';
import { State } from '@embroker/shotwell/core/types/StateList';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass, execute } from '@embroker/shotwell/core/UseCase';
import { CalculateSKU } from '../../analytics/useCases/CalculateSKU';
import { TasksRepository } from '../../tasks/repositories';
import { ApplicationCreated } from '../entities/Application';
import { AppTypeNotAllowedForBroker, NoEligibleAppTypeFound } from '../errors';
import { ApplicantRepository } from '../repositories/ApplicantRepository';
import { ApplicationRepository, GetConfigResponse } from '../repositories/ApplicationRepository';
import { LawBundleQuestionnaireData } from '../types/LawBundleQuestionnaireData';
import { LPLAreaOfPractice } from '../types/LPLAreaOfPractice';
import { UpdateApplicantFromTier1 } from './UpdateApplicantFromTier1';
import { AppTypeCode } from '../types/enums';
import { CoverageEligibility } from '../types/CoverageEligibility';
import { GetLawBundlePrefill, GetLawBundlePrefillResponse } from './GetLawBundlePrefill';
import { CoverageType } from '../types/CoverageType';

const coverageToggleTpAppTypeMap = new Map<CoverageType, AppTypeCode>([
    [CoverageType.LPL, 'AppTypeCodeListEverestLawyersProfessionalLiability'],
    [CoverageType.Cyber, 'AppTypeCodeListCyberCowbell'],
    [CoverageType.BOP, 'AppTypeCodeListBOPChubb'],
    [CoverageType.WC, 'AppTypeCodeListWCChubb'],
]);

type LawBundleInputData = Partial<{
    numberOfAttorneys: number;
    revenue: Money;
    areasOfPractice: LPLAreaOfPractice[];
    stateWithMostAttorneys: State;
}>;

export interface CreateLawBundleApplicationRequest {
    inputData?: LawBundleInputData;
    abortSignal: AbortSignal;
    maxPollingRetries?: number;
    pollingRetryIntervalInMilliseconds?: number;
}

export interface CreateLawBundleApplicationResponse {
    applicationIdList: UUID[];
}

export interface CreateLawBundleApplication extends UseCase {
    execute(
        request: CreateLawBundleApplicationRequest,
    ): AsyncResult<
        CreateLawBundleApplicationResponse,
        | InvalidArgument
        | OperationFailed
        | UnknownEntity
        | Aborted
        | Timeout
        | AppTypeNotAllowedForBroker
        | NoEligibleAppTypeFound
    >;
}

export class CreateLawBundleApplicationUseCase
    extends UseCase
    implements CreateLawBundleApplication
{
    public static type = Symbol('Shopping/CreateLawBundleApplication');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
        @inject(ApplicantRepository) private applicantRepository: ApplicantRepository,
        @inject(UpdateApplicantFromTier1.type)
        private updateApplicantFromTier1: UpdateApplicantFromTier1,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
        @inject(CalculateSKU.type)
        private calculateSKU: CalculateSKU,
        @inject(Log) private logger: Logger,
    ) {
        super(eventBus);
    }

    public async execute({
        inputData,
        abortSignal,
        maxPollingRetries = 5,
        pollingRetryIntervalInMilliseconds = 120,
    }: CreateLawBundleApplicationRequest): AsyncResult<
        CreateLawBundleApplicationResponse,
        | InvalidArgument
        | OperationFailed
        | UnknownEntity
        | Aborted
        | Timeout
        | AppTypeNotAllowedForBroker
        | NoEligibleAppTypeFound
    > {
        const configResponse = await this.applicationRepository.getConfig();
        if (isErr(configResponse)) {
            return configResponse;
        }

        let createApplicationInputData: GetLawBundlePrefillResponse = {};

        if (inputData) {
            createApplicationInputData = inputData;
        } else {
            // if the inputData is not coming from user input, we use GetLawBundlePrefill to prefill what we can
            const getLawBundlePrefillResult = await execute(GetLawBundlePrefill, {
                applicationId: undefined,
            });

            createApplicationInputData =
                getLawBundlePrefillResult && isOK(getLawBundlePrefillResult)
                    ? (getLawBundlePrefillResult.value as GetLawBundlePrefillResponse)
                    : {};
        }

        const coverageToggles: CoverageType[] = getCoverageToggles(configResponse);
        const eligibleCoverageToggles: CoverageType[] = coverageToggles.filter((coverageType) =>
            CoverageEligibility.isStateEligabile(
                coverageToggleTpAppTypeMap.get(coverageType),
                createApplicationInputData.stateWithMostAttorneys || null,
            ),
        );

        const coverage_toggles = eligibleCoverageToggles;

        const createQuestionnaireDataResult = LawBundleQuestionnaireData.create({
            ...mapInputToQuestionnaireData(createApplicationInputData),
            coverage_toggles,
        });

        if (isErr(createQuestionnaireDataResult)) {
            return handleOperationFailure(createQuestionnaireDataResult);
        }

        const questionnaireData = createQuestionnaireDataResult.value;

        let questionnaireDataStringified;
        try {
            questionnaireDataStringified = JSON.stringify(questionnaireData);
        } catch (error) {
            return Failure(OperationFailed({ message: 'Failed to stringify questionnaire data' }));
        }

        const updateApplicantFromTier1Response = await this.updateApplicantFromTier1.execute({
            tier1QuestionnaireData: questionnaireData,
        });

        if (isErr(updateApplicantFromTier1Response)) {
            return updateApplicantFromTier1Response;
        }
        const createApplicationsResult = await this.applicationRepository.createApplications({
            appTypeList: ['AppTypeCodeListLawBundle'],
            questionnaireData: questionnaireDataStringified,
        });

        if (isErr(createApplicationsResult)) {
            return createApplicationsResult;
        }

        const { taskId, applicationList } = createApplicationsResult.value;

        const skuResult = await this.calculateSKU.execute({
            event: 'app_created',
            appType: 'AppTypeCodeListLawBundle',
            shoppingCoverageList: applicationList[0].shoppingCoverageList,
            applicationId: applicationList[0].id,
        });
        const eventData: ApplicationCreated = {
            origin: 'Application',
            name: 'ApplicationCreated',
            id: applicationList[0].id,
            isRenewal: applicationList[0].isRenewal(),
            sku: skuResult.value,
            createdAt: new Date(Date.now()),
        };
        this.eventBus.publish(eventData);

        const applicationIdList: UUID[] = applicationList.map((item) => item.id);

        const pollForTaskStatusResult = await this.tasksRepository.pollForTaskStatus(
            taskId,
            abortSignal,
            maxPollingRetries,
            pollingRetryIntervalInMilliseconds,
        );
        if (isErr(pollForTaskStatusResult)) {
            this.logger.warn(
                `Create Applications UseCase - Got error for task with id: ${taskId}`,
                pollForTaskStatusResult.errors,
            );
        } else if (!UUID.check(pollForTaskStatusResult.value)) {
            this.logger.warn(
                `Create Applications UseCase - Invalid response type for task with id: ${taskId}`,
                pollForTaskStatusResult,
            );
        }

        return Success<CreateLawBundleApplicationResponse>({
            applicationIdList: applicationIdList,
        });
    }
}

const getCoverageToggles = (configResponse: SuccessResult<GetConfigResponse>): CoverageType[] => {
    const coverage_toggles: CoverageType[] = [CoverageType.LPL, CoverageType.Cyber];
    if (configResponse.value.isBOPChubbEnabled) {
        coverage_toggles.push(CoverageType.BOP);
    }
    if (configResponse.value.isWCChubbEnabled) {
        coverage_toggles.push(CoverageType.WC);
    }
    return coverage_toggles;
};

function mapInputToQuestionnaireData(inputData: LawBundleInputData): LawBundleQuestionnaireData {
    // The law bundle is only created via prospects, hardcoding the naics_code here as a fix for
    // https://embroker.atlassian.net/browse/EM-37486
    // We may want to revisit this if/when the law bundle becomes available through other avenues

    const result: LawBundleQuestionnaireData = {
        naics_code: '541110', // naics code for law firm
    };

    const revenue = inputData.revenue && Money.toFloat(inputData.revenue);
    const areasOfPracticeInput = inputData.areasOfPractice || [];

    result.totalAttorneysThisYear = inputData.numberOfAttorneys;
    result.state_with_most_attorneys = inputData.stateWithMostAttorneys;
    result.gross_revenue_total = revenue;
    const areas_of_practice_rows: { area: string; percentage: number }[] = [];
    const areasOfPractice = areasOfPracticeInput;
    areasOfPractice.forEach((area: LPLAreaOfPractice, index: number) => {
        areas_of_practice_rows[index] = {
            area: area.areaOfPracticeCode,
            percentage: area.percentage,
        };
    });
    result.areas_of_practice = { areas_of_practice_rows };

    return result;
}

export const CreateLawBundleApplication: UseCaseClass<CreateLawBundleApplication> =
    CreateLawBundleApplicationUseCase;
