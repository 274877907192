import { isOK } from '@embroker/shotwell/core/types/Result';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    BoxLayout,
    ColumnLayout,
    Form,
    Loader,
    ReactProps,
    StackLayout,
    Text,
} from '@embroker/ui-toolkit/v2';
import { isValid, startOfToday } from 'date-fns';
import React, { useCallback, useContext } from 'react';
import { hasRole } from '../../../../../userOrg/entities/Session';
import { AppContext } from '../../../../../view/AppContext';
import { WizardForm } from '../../../../../view/hooks/useWizardForm';
import { EffectiveDate } from '../../../../types/EffectiveDate';
import { PCoMLQuote } from '../../../entities/PCoMLQuote';
import { GetRestriction } from '../../../useCases/GetRestriction';
import { MAX_FUTURE_DAYS_ALLOWED, PCoMLQuoteOptionsFormData } from '../PCoMLQuoteLandingPage';
import { PCoMLRecommendedCoverageList } from './PCoMLRecommendedCoverageList';
import { PCoMLSelectedCoverageList } from './PCoMLSelectedCoverageList';

interface PCoMLCoveragesPageProps extends ReactProps<'div'> {
    quote: PCoMLQuote;
    fields: WizardForm<OpaqueForm<PCoMLQuoteOptionsFormData>>['fields'];
    trigger: WizardForm<OpaqueForm<PCoMLQuoteOptionsFormData>>['trigger'];
    setValue: WizardForm<OpaqueForm<PCoMLQuoteOptionsFormData>>['setValue'];
    value: WizardForm<OpaqueForm<PCoMLQuoteOptionsFormData>>['value'];
    isSubmitting: boolean;
    showEpliStandaloneInCalifornia?: () => void;
}

const DATE_FORMAT = 'MM/dd/yyyy';

export const PCoMLCoveragePage = function PCoMLCoveragePage({
    quote,
    trigger,
    setValue,
    fields,
    value,
    isSubmitting,
    className,
    showEpliStandaloneInCalifornia,
}: PCoMLCoveragesPageProps) {
    const { result: getRestrictionResult, isLoading: isLoadingRestrictionResult } = useUseCase(
        GetRestriction,
        {
            insuranceApplicationId: quote.applicationId,
        },
    );

    const handleDateChange = (event: { target: { value: string; date: Date } }) => {
        if (isValid(event.target.date)) {
            setValue({
                ...value,
                startDate: event.target.date,
            });
            trigger('update');
        }
    };

    const handleIsEPLSelected = () => {
        fields.isEplSelected?.props.onChange({
            target: {
                value: !value.isEplSelected,
            },
        });
    };

    const handleIsDNOSelected = () => {
        fields.isDnoSelected?.props.onChange({
            target: {
                value: !value.isDnoSelected,
            },
        });
    };

    const { activeSession } = useContext(AppContext);
    const isAdmin = hasRole(activeSession, 'admin');
    const endDate = EffectiveDate.calculateCoverageEndDate(quote.options.effectiveDate);

    const isEffectiveDateSelectable = useCallback(
        (effectiveDate: Date) =>
            EffectiveDate.isSelectedEffectiveDateValid(
                effectiveDate,
                startOfToday(),
                MAX_FUTURE_DAYS_ALLOWED,
                isAdmin,
            ),
        [isAdmin],
    );

    if (isLoadingRestrictionResult || !getRestrictionResult) {
        return <Loader />;
    }

    const restriction = isOK(getRestrictionResult)
        ? getRestrictionResult.value.restriction
        : undefined;

    return (
        <StackLayout className={className} gap="32">
            {!quote.applicationInfo?.isRenewal && (
                <StackLayout className={className} gap="32">
                    <Text style="heading 4">When do you want your coverage to begin?</Text>
                    <ColumnLayout gap="none" center>
                        <Form.Field
                            className="u-1/3@desktop u-1/2@tablet u-1/2"
                            type={fields.startDate.type}
                            inputProps={{
                                ...fields.startDate.props,
                                onChange: handleDateChange,
                                disabled: isSubmitting,
                                isSelectable: isEffectiveDateSelectable,
                            }}
                            messages={fields.startDate.messages}
                        />
                        <BoxLayout>
                            <Text style="heading 5" data-e2e="end-date">
                                &ndash;{' '}
                                {endDate ? (
                                    <DateDisplay format={DATE_FORMAT} value={endDate} />
                                ) : null}
                            </Text>
                        </BoxLayout>
                    </ColumnLayout>
                </StackLayout>
            )}
            <PCoMLSelectedCoverageList
                quote={quote}
                isSubmitting={isSubmitting}
                fields={fields}
                handleIsEPLSelected={handleIsEPLSelected}
                handleIsDNOSelected={handleIsDNOSelected}
                restriction={restriction}
                showEpliStandaloneInCalifornia={showEpliStandaloneInCalifornia}
            />
            <PCoMLRecommendedCoverageList
                isRenewal={quote.applicationInfo?.isRenewal ?? false}
                quoteOptions={quote.options}
                fields={fields}
                isSubmitting={isSubmitting}
                handleIsEPLSelected={handleIsEPLSelected}
                handleIsDNOSelected={handleIsDNOSelected}
                newInsurerDocumentsReleaseDate={
                    quote.applicationInfo?.newInsurerDocumentsReleaseDate
                }
                submittedAt={quote.applicationInfo?.submittedAt}
                state={quote.applicationInfo?.userInfo.usaState}
                restriction={restriction}
            />
        </StackLayout>
    );
};
