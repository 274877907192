import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { cast } from '@embroker/shotwell/core/types/Nominal';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute, UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject } from '@embroker/shotwell/core/di';
import { GetDocumentUrl } from './GetDocumentUrl';
import { Async } from '@embroker/shotwell/core/async';
import { DocumentRepository } from '../repositories/DocumentRepository';
import { DocumentRequested } from '../entities/Document';

export interface GenerateAppFileUrlRequest {
    applicationId: UUID;
    abortSignal: AbortSignal;
}

export interface GenerateAppFileUrlResponse {
    readonly documentUrl: URI;
}

export interface GenerateAppFileUrl extends UseCase {
    execute(
        request: GenerateAppFileUrlRequest,
    ): AsyncResult<GenerateAppFileUrlResponse, UnknownEntity | InvalidArgument | OperationFailed>;
}

class GenerateAppFileUrlUseCase extends UseCase implements GenerateAppFileUrl {
    public static type = Symbol('Quote/GenerateAppFileUrl');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(DocumentRepository) private documentRepository: DocumentRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
        abortSignal,
    }: GenerateAppFileUrlRequest): AsyncResult<
        GenerateAppFileUrlResponse,
        UnknownEntity | InvalidArgument | OperationFailed
    > {
        const documentsGeneratedResult = await this.waitForDocumentsToBeGenerated(
            applicationId,
            abortSignal,
        );
        if (isErr(documentsGeneratedResult)) {
            return documentsGeneratedResult;
        }

        const appDocumentFileKeyResult =
            await this.documentRepository.getApplicationDocumentFileKey(applicationId);
        if (isErr(appDocumentFileKeyResult)) {
            return appDocumentFileKeyResult;
        }

        const fileKey = appDocumentFileKeyResult.value;
        if (fileKey === undefined) {
            return Failure(OperationFailed({ message: 'Application document not found.' }));
        }

        const getDocumentUrlResult = await execute(GetDocumentUrl, {
            fileKey: fileKey,
        });
        if (isErr(getDocumentUrlResult)) {
            return getDocumentUrlResult;
        }

        const documentRequestedEvent: DocumentRequested = {
            origin: 'Document',
            name: 'QuotePageDocumentRequested',
            documentType: 'Application',
            id: UUID.create(),
            createdAt: new Date(Date.now()),
        };
        await this.eventBus.publish(documentRequestedEvent);

        return Success<GenerateAppFileUrlResponse>({
            documentUrl: cast<URI>(getDocumentUrlResult.value.downloadUrl),
        });
    }

    private async waitForDocumentsToBeGenerated(
        applicationId: UUID,
        abortSignal: AbortSignal,
    ): AsyncResult<void, InvalidArgument | OperationFailed> {
        const maxPollingRetries = 120;
        const pollingRetryIntervalInMilliseconds = 1000;
        let retryCount = 0;
        let isAborted = false;
        abortSignal.onabort = () => {
            isAborted = true;
        };
        while (retryCount < maxPollingRetries) {
            if (isAborted) {
                return Failure(OperationFailed({ message: 'Polling aborted.' }));
            }
            retryCount++;
            const hasDocGenTaskInProgressResult =
                await this.documentRepository.hasDocGenTaskInProgress(applicationId);
            if (isErr(hasDocGenTaskInProgressResult)) {
                return hasDocGenTaskInProgressResult;
            }
            const hasDocGenTaskInProgress = hasDocGenTaskInProgressResult.value;
            if (!hasDocGenTaskInProgress) {
                return Success();
            }
            await Async.sleep(pollingRetryIntervalInMilliseconds);
        }
        return Failure(OperationFailed({ message: 'appDocumentsGenerated max retries exceeded.' }));
    }
}

export const GenerateAppFileUrl: UseCaseClass<GenerateAppFileUrl> = GenerateAppFileUrlUseCase;
