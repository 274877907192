import React from 'react';
import { StackLayout, Text, List, StatusMessage, TextButton } from '@embroker/ui-toolkit/v2';
import { CoverageRecommendationCard, RecommendationType } from './CoverageRecommendationCard.view';
import { CoverageIneligibilityReasonTypes } from '../../../types/CoverageEligibility';
import { OnboardingPrefillQuestionnaireData } from '../../../../userOrg/types/OnboardingPrefillQuestionnaireData';
import { getCoverageDetails } from '../coverageDetails';

interface CoverageCardProps {
    isSelected: boolean;
    ineligibilityReasons?: CoverageIneligibilityReasonTypes[];
    onboardingQuestionnaire?: OnboardingPrefillQuestionnaireData;
    onTitleTooltipClick: () => void;
    onCoverageSelectionChange: () => void;
}

export function CyberCoverageRecommendationCard(props: CoverageCardProps) {
    const { isSelected, onTitleTooltipClick, onCoverageSelectionChange } = props;
    return (
        <CoverageRecommendationCard
            recommendationType={RecommendationType.popular}
            iconName="cyber"
            title="Cyber Insurance"
            className="u-grid-row-span-2"
            isSelected={isSelected}
            onTitleTooltipClick={onTitleTooltipClick}
            onCoverageSelectionChange={onCoverageSelectionChange}
        >
            <StackLayout gap="16">
                <StackLayout gap="20">
                    <Text style="body 1">
                        Check Point reports that cyber attacks on law firms increased 13% in 2023.
                        Don’t be a statistic. Cyber insurance covers financial and legal costs
                        following a cybercrime.
                    </Text>
                    <Text style="body 1">Common coverages:</Text>
                </StackLayout>
                <StackLayout gap="24">
                    <List>
                        <List.Item icon="check">
                            <Text style="body 1" as="div">
                                Phishing scams
                            </Text>
                        </List.Item>
                        <List.Item icon="check">
                            <Text style="body 1" as="div">
                                Computer / funds transfer fraud
                            </Text>
                        </List.Item>
                        <List.Item icon="check">
                            <Text style="body 1" as="div">
                                Ransomware / extortion
                            </Text>
                        </List.Item>
                        <List.Item icon="check">
                            <Text style="body 1" as="div">
                                Privacy breaches
                            </Text>
                        </List.Item>
                        <List.Item icon="check">
                            <Text style="body 1" as="div">
                                Hard copy records
                            </Text>
                        </List.Item>
                    </List>
                    <StatusMessage status="success">
                        Includes complimentary information security training.
                    </StatusMessage>
                </StackLayout>
            </StackLayout>
        </CoverageRecommendationCard>
    );
}

export function LPLCoverageRecommendationCard(props: CoverageCardProps) {
    const { isSelected, onTitleTooltipClick, onCoverageSelectionChange } = props;
    return (
        <CoverageRecommendationCard
            recommendationType={RecommendationType.essential}
            iconName="lpl"
            title="Lawyers Professional Liability"
            className="u-grid-row-span-2"
            isSelected={isSelected}
            onTitleTooltipClick={onTitleTooltipClick}
            onCoverageSelectionChange={onCoverageSelectionChange}
        >
            <StackLayout gap="16">
                <StackLayout gap="20">
                    <Text style="body 1">
                        Even the most experienced lawyers need to cover their assets. Protect your
                        firm while providing your professional services — anywhere in the world you
                        do business.
                    </Text>
                    <Text style="body 1">Common coverages:</Text>
                </StackLayout>
                <StackLayout gap="24">
                    <List>
                        <List.Item icon="check">
                            <Text style="body 1" as="div">
                                Common mistakes
                            </Text>
                        </List.Item>
                        <List.Item icon="check">
                            <Text style="body 1" as="div">
                                Negligence
                            </Text>
                        </List.Item>
                        <List.Item icon="check">
                            <Text style="body 1" as="div">
                                Missed deadlines
                            </Text>
                        </List.Item>
                        <List.Item icon="check">
                            <Text style="body 1" as="div">
                                Unethical conduct
                            </Text>
                        </List.Item>
                        <List.Item icon="check">
                            <Text style="body 1" as="div">
                                Other liability claims
                            </Text>
                        </List.Item>
                    </List>
                    <StackLayout gap="16">
                        <Text style="body 1">What’s not covered:</Text>
                        <List>
                            <List.Item icon="close" iconColor="negative-500">
                                <Text style="body 1" as="div">
                                    Phishing scams
                                </Text>
                            </List.Item>
                        </List>
                    </StackLayout>
                </StackLayout>
            </StackLayout>
        </CoverageRecommendationCard>
    );
}

export function BOPCoverageRecommendationCard(props: CoverageCardProps) {
    const {
        isSelected,
        ineligibilityReasons,
        onboardingQuestionnaire,
        onTitleTooltipClick,
        onCoverageSelectionChange,
    } = props;
    if (onboardingQuestionnaire?.location_type === 'office_owned') {
        return null;
    }
    const isDisabled = Boolean(ineligibilityReasons && ineligibilityReasons.length);
    const notAvailableInState =
        ineligibilityReasons && ineligibilityReasons.includes('not-available-in-state');

    const cardBody = notAvailableInState
        ? coverageRecommendationCardNotAvailableInStateBody
        : coverageRecommendationCardBOPBody;

    return (
        <CoverageRecommendationCard
            recommendationType={RecommendationType.none}
            iconName="bop"
            title="Liability + Property Insurance"
            isSelected={isSelected}
            isDisabled={isDisabled}
            onTitleTooltipClick={onTitleTooltipClick}
            onCoverageSelectionChange={onCoverageSelectionChange}
        >
            {cardBody}
        </CoverageRecommendationCard>
    );
}

export function WCCoverageRecommendationCard(props: CoverageCardProps) {
    const {
        isSelected,
        onboardingQuestionnaire,
        ineligibilityReasons = [],
        onTitleTooltipClick,
        onCoverageSelectionChange,
    } = props;

    const hasEmployees = onboardingQuestionnaire?.has_employees;
    const isMonoState = ineligibilityReasons.includes('monopolistic-state');
    const isNotAvailableInState = ineligibilityReasons.includes('not-available-in-state');
    const confirmedNoEmployees = onboardingQuestionnaire?.has_employees === false; // questionnaire data may be undefined, in this scenario we do not want to prevent a user from toggling the coverage on/off
    const isDisabled = Boolean(ineligibilityReasons.length) || confirmedNoEmployees;

    const noEmployees = onboardingQuestionnaire && onboardingQuestionnaire.has_employees === false;

    let cardBody: JSX.Element = getCoverageDetails('AppTypeCodeListWCChubb')
        .description as JSX.Element; // Using this as a fallback option;
    let className = '';
    if (isDisabled) {
        if (noEmployees) {
            cardBody = coverageRecommendationCardWCNoStaffBody;
        }
        if (isNotAvailableInState) {
            cardBody = coverageRecommendationCardNotAvailableInStateBody;
        }
        if (isMonoState) {
            cardBody = coverageRecommendationCardWCMonopolisticStateBody;
        }
    } else if (hasEmployees) {
        cardBody = coverageRecommendationCardWCEmployeeMessageBody;
        if (onboardingQuestionnaire.location_type === 'office_owned') {
            cardBody = coverageRecommendationCardWCBody;
            className = 'u-grid-row-span-2';
        }
    }

    const isEssential =
        !isDisabled && onboardingQuestionnaire && onboardingQuestionnaire.has_employees;
    const recommendationType = isEssential ? RecommendationType.essential : RecommendationType.none;

    return (
        <CoverageRecommendationCard
            recommendationType={recommendationType}
            iconName="workers-comp"
            title="Workers Compensation"
            isSelected={isSelected}
            className={className}
            isDisabled={isDisabled}
            onTitleTooltipClick={onTitleTooltipClick}
            onCoverageSelectionChange={onCoverageSelectionChange}
        >
            {cardBody}
        </CoverageRecommendationCard>
    );
}

const coverageRecommendationCardBOPBody = (
    <Text style="body 1">
        Get peace of mind for your practice with a Business Owners Policy. One policy, multiple
        coverages to protect your business, property, and even employees driving vehicles for work.
    </Text>
);

const coverageRecommendationCardWCBody = (
    <StackLayout gap="16">
        <StackLayout gap="20">
            <Text style="body 1">
                You’ve got employees — we’ve got your legal back. Subject to state law, workers
                compensation protects your staff and firm after a workplace injury or illness.
            </Text>
            <Text style="body 1">Common coverages:</Text>
        </StackLayout>
        <StackLayout gap="32">
            <List>
                <List.Item icon="check">
                    <Text style="body 1" as="div">
                        Hospital bills and medication
                    </Text>
                </List.Item>
                <List.Item icon="check">
                    <Text style="body 1" as="div">
                        Physical therapy
                    </Text>
                </List.Item>
                <List.Item icon="check">
                    <Text style="body 1" as="div">
                        Lost wages
                    </Text>
                </List.Item>
                <List.Item icon="check">
                    <Text style="body 1" as="div">
                        Legal expenses
                    </Text>
                </List.Item>
            </List>
            <StackLayout gap="16">
                <Text style="body 1">Plus:</Text>
                <List>
                    <List.Item icon="check">
                        <Text style="body 1" as="div">
                            Employers Liability covers claims of employer negligence.
                        </Text>
                    </List.Item>
                </List>
            </StackLayout>
        </StackLayout>
    </StackLayout>
);

const coverageRecommendationCardWCNoStaffBody = (
    <StackLayout gap="12">
        <span />
        <StatusMessage status="info">
            Since you don’t have staff, you can skip this for now. As you grow your business, you
            will likely require this coverage.
        </StatusMessage>
    </StackLayout>
);

const coverageRecommendationCardWCMonopolisticStateBody = (
    <StackLayout gap="12">
        <span />
        <StatusMessage status="info">
            In your state, you can only get workers compensation through the state-administered
            fund.
            <TextButton
                href="https://www.nfib.com/content/legal-compliance/legal/workers-compensation-laws-state-by-state-comparison-57181/"
                target="_blank"
            >
                View State Requirements
            </TextButton>
        </StatusMessage>
    </StackLayout>
);

const coverageRecommendationCardNotAvailableInStateBody = (
    <StackLayout gap="12">
        <span />
        <StatusMessage status="info">
            This coverage is not available online in your state.
        </StatusMessage>
    </StackLayout>
);

const coverageRecommendationCardWCEmployeeMessageBody = (
    <Text style="body 1">
        You’ve got employees — we’ve got your legal back. Subject to state law, it protects your
        staff and firm after a workplace injury or illness, and claims of employer negligence.
    </Text>
);
