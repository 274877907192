import { BaseContext, BaseContextStore } from '@embroker/shotwell/view/BaseContext';
import React from 'react';
import { Immutable } from '@embroker/shotwell/core/types';

/**
 * Notification Center context object.
 */
export interface NotificationsContext extends BaseContext {
    /**
     * The unread notification amount, used in navigation and header to notify user
     */
    unreadNotificationCount: number;
}

export const NotificationsContextStore = new (class extends BaseContextStore<
    Immutable<NotificationsContext>
> {
    public constructor() {
        super();

        this.data = {
            ...this.data,
            unreadNotificationCount: 0,
        };
    }
})();

export const NotificationsContext = React.createContext<Immutable<NotificationsContext>>({
    ...NotificationsContextStore.context,
});
