import { UUID } from '@embroker/shotwell/core/types/UUID';
import { valueObject } from '@embroker/shotwell/core/types/ValueObject';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface ExcessCarrier {
    readonly id: UUID;
    readonly carrierName: string;
    readonly carrierPaper: string;
    readonly policyFormName: string;
    readonly cyberCoverageName: string;
}

export const ExcessCarrier = valueObject({
    ...defineValidator<ExcessCarrier>({
        id: UUID.schema.required(),
        carrierName: Joi.string().required(),
        carrierPaper: Joi.string().required(),
        policyFormName: Joi.string().required(),
        cyberCoverageName: Joi.string().required(),
    }),
});
