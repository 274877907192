import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { Location } from '@embroker/shotwell/core/types/Location';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { State } from '@embroker/shotwell/core/types/StateList';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { PreviewSelfServingCertificateFailed } from '../errors';
import { CertificateRepository } from '../repositories/CertificateRepository';
import { CertificateEntityRole } from '../types/CertificateEntityRole';
import { SelfServingCertificateCoverageList } from '../types/SelfServingCertificateCoverage';
import { deepClone } from '@embroker/shotwell/core/object';

/**
 * Response data for PreviewSelfServingCertificate use case
 *
 * @property certificateFileKey file key of the self-serving certificate document
 */
export interface PreviewSelfServingCertificateResponse {
    certificateFileKey: string;
}

/**
 * Request data for PreviewSelfServingCertificate use case
 *
 * @param selfServingCoverageList list of shareable coverages
 * @param certificateHolder certificate holder to be displayed in the certificate document
 * @param referenceNumber is a reference number value to be printed on certificate pdf
 */
export interface PreviewSelfServingCertificateRequest {
    selfServingCoverageList: SelfServingCertificateCoverageList;
    companyName?: string;
    email?: EmailAddress;
    mailingAddress?: string;
    mailingAddressCont?: string;
    city?: string;
    state?: State;
    zipCode?: ZipCode;
    referenceNumber?: string;
    isAdditionalInsuredDisabled?: boolean;
    isWaiverOfSubrogationDisabled?: boolean;
    isPrimaryNonContributoryDisabled?: boolean;
}

/**
 * PreviewSelfServingCertificate use case is used to preview self-serving certificate document
 */
export interface PreviewSelfServingCertificate extends UseCase {
    execute(
        request: PreviewSelfServingCertificateRequest,
    ): AsyncResult<
        PreviewSelfServingCertificateResponse,
        InvalidArgument | PreviewSelfServingCertificateFailed
    >;
}

@injectable()
class PreviewSelfServingCertificateUseCase
    extends UseCase
    implements PreviewSelfServingCertificate
{
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Certificates/PreviewSelfServingCertificate');

    /**
     * Constructor for PreviewSelfServingCertificate class instance
     * @param eventBus An event bus this Use Case will publish events to.
     * @param certificateRepo repo that will be used to check for shareable certificate data
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(CertificateRepository) private certificateRepo: CertificateRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes the PreviewSelfServingCertificate use case.
     * @returns PreviewSelfServingCertificateResponse if execution was successful
     * @returns InvalidArgument or OperationFailed if callout to repo fails for any reason.
     */
    public async execute(
        request: PreviewSelfServingCertificateRequest,
    ): AsyncResult<
        PreviewSelfServingCertificateResponse,
        InvalidArgument | PreviewSelfServingCertificateFailed
    > {
        const certificateHolder =
            request.companyName &&
            request.city &&
            request.mailingAddress &&
            request.state &&
            request.zipCode
                ? ({
                      roleType: 'Holder',
                      organizationName: request.companyName,
                      organizationEmail: request.email,
                      userEmail: request.email,
                      location: {
                          type: 'other',
                          addressLine1: request.mailingAddress,
                          addressLine2: request.mailingAddressCont ?? null,
                          city: request.city,
                          state: request.state,
                          zip: request.zipCode,
                          phoneNumber: null,
                      } as Location,
                  } as CertificateEntityRole)
                : undefined;

        const selfServingCoverageList = deepClone(request.selfServingCoverageList);

        for (const coverage of selfServingCoverageList) {
            if (request.isWaiverOfSubrogationDisabled) {
                coverage.coverageInfo.waiverOfSubrogation = false;
            }
            if (request.isAdditionalInsuredDisabled) {
                coverage.coverageInfo.blanketAdditionalInsured = false;
            }
            if (request.isPrimaryNonContributoryDisabled) {
                coverage.coverageInfo.primaryNonContributory = false;
            }
        }

        const fileKeyResult = await this.certificateRepo.previewSelfServingCertificate({
            certificateHolder: certificateHolder,
            referenceNumber: request.referenceNumber,
            selfServingCoverageList: selfServingCoverageList,
        });

        if (isErr(fileKeyResult)) {
            return fileKeyResult;
        }

        const result: PreviewSelfServingCertificateResponse = {
            certificateFileKey: fileKeyResult.value.certificateFileKey,
        };

        return Success(result);
    }
}

export const PreviewSelfServingCertificate: UseCaseClass<PreviewSelfServingCertificate> =
    PreviewSelfServingCertificateUseCase;
