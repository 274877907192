import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { ErrorLike } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    ButtonBar,
    Button,
    BoxLayout,
    TextButton,
    Form,
    Grid,
    Text,
    Spinner,
    StatusMessage,
} from '@embroker/ui-toolkit/v2';
import { format, isSameDay, isValid, startOfDay } from 'date-fns';
import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import { ESPEndorsementPolicy } from '../../types/ESPEndorsementPolicy';
import { ChangeNamedInsuredESPEndorsement } from '../../useCases/ChangeNamedInsuredESPEndorsement';

const DATE_FORMAT = 'MM/dd/yyyy';

interface ESPEndorsementNamedInsuredFormData {
    effectiveDate: Nullable<Date>;
    namedInsured: string;
}

interface CreateEspEndorsementNamedInsuredFormParams {
    policyId: UUID;
    currentNamedInsured: string;
    effectivePeriodStart: Date;
    effectivePeriodEnd: Date;
    onSaveEndorsementSuccess: (updatedPolicy: ESPEndorsementPolicy) => void;
    onSaveEndorsementFailure: (errors: Immutable<ErrorLike[]>) => void;
    abortSignal: AbortSignal;
}

function createEspEndorsementNamedInsuredForm({
    policyId,
    currentNamedInsured,
    effectivePeriodStart,
    effectivePeriodEnd,
    onSaveEndorsementSuccess,
    onSaveEndorsementFailure,
    abortSignal,
}: CreateEspEndorsementNamedInsuredFormParams) {
    return createForm<ESPEndorsementNamedInsuredFormData>({
        fields: {
            effectiveDate: {
                type: 'date',
                validator: Joi.date().min(effectivePeriodStart).max(effectivePeriodEnd).required(),
                formatValidationError: (error) => {
                    if (
                        error.details.validator == 'date.min' ||
                        error.details.validator == 'date.max'
                    ) {
                        return 'The effective date does not coincide with the policy period.';
                    }
                    if (error.details.validator == 'any.required') {
                        return 'You must set an effective date.';
                    }
                    return error.message;
                },
            },
            namedInsured: {
                type: 'text',
                validator: Joi.string().invalid(currentNamedInsured).required(),
                formatValidationError: (error) => {
                    switch (error.details.validator) {
                        case 'any.invalid':
                            return 'Named insured is not changed.';
                        case 'string.empty':
                            return 'Name insured is empty.';
                        default:
                            return error.message;
                    }
                },
            },
        },
        onSuccess: (updatedPolicy: ESPEndorsementPolicy) => onSaveEndorsementSuccess(updatedPolicy),
        onFailure: (errors: Immutable<ErrorLike[]>) => onSaveEndorsementFailure(errors),
        submit: async (namedInsuredFormData: ESPEndorsementNamedInsuredFormData) => {
            // TS: Form date validated - UC accepts date
            const effectiveDate = namedInsuredFormData.effectiveDate as Date;
            return await execute(ChangeNamedInsuredESPEndorsement, {
                policyId,
                namedInsured: namedInsuredFormData.namedInsured,
                effectiveDate: effectiveDate,
                abortSignal,
            });
        },
    });
}

interface ESPEndorsementNamedInsuredProps {
    policyId: UUID;
    namedInsured: string;
    effectivePeriodStart: Date;
    effectivePeriodEnd: Date;
    hasRenewalApplication?: boolean;
    onSaveEndorsementSuccess: (updatedPolicy: ESPEndorsementPolicy) => void;
    onSaveEndorsementFailure: (errors: Immutable<ErrorLike[]>) => void;
    onClose: () => void;
}

export function ESPEndorsementNamedInsured({
    policyId,
    namedInsured,
    effectivePeriodStart,
    effectivePeriodEnd,
    onSaveEndorsementSuccess,
    onSaveEndorsementFailure,
    onClose,
    hasRenewalApplication,
}: ESPEndorsementNamedInsuredProps) {
    const abortController = useMemo(() => {
        return new AbortController();
    }, []);

    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, [abortController]);

    const espChangeNameInsuredForm = useMemo(
        () =>
            createEspEndorsementNamedInsuredForm({
                policyId,
                currentNamedInsured: namedInsured,
                effectivePeriodStart,
                effectivePeriodEnd,
                onSaveEndorsementSuccess,
                onSaveEndorsementFailure,
                abortSignal: abortController.signal,
            }),
        [
            policyId,
            namedInsured,
            effectivePeriodStart,
            effectivePeriodEnd,
            onSaveEndorsementSuccess,
            onSaveEndorsementFailure,
            abortController.signal,
        ],
    );

    const { value, setValue, status, submit, fields } = useForm(espChangeNameInsuredForm, {
        effectiveDate: null,
        namedInsured,
    });

    const handleNamedInsuredChange = (event: { target: { value: string } }) => {
        setValue({
            ...value,
            namedInsured: event.target.value,
        });
    };

    const handleEffectiveDateChange = useCallback(
        (event: { target: { value: string; date: Date } }) => {
            const newDate = startOfDay(event.target.date);
            const isNotDateValid =
                !isValid(newDate) ||
                (value.effectiveDate !== null && isSameDay(value.effectiveDate, newDate));
            if (isNotDateValid) {
                return;
            }

            setValue({
                ...value,
                effectiveDate: newDate,
            });
        },
        [setValue, value],
    );

    const isLoading = status === 'submitting';

    return (
        <BoxLayout gap="24">
            <Form>
                {isLoading && <Spinner />}
                <Grid>
                    <Grid.Row>
                        <Grid.Cell width="1/1">
                            <Text style="heading 3">Update Name</Text>
                        </Grid.Cell>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Cell width="1/1">
                            <Form.Field
                                inputProps={{
                                    value: value.namedInsured,
                                    onChange: handleNamedInsuredChange,
                                }}
                                title="Named Insured"
                                messages={fields.namedInsured.messages}
                                type={fields.namedInsured.type}
                                data-e2e="named-insured"
                            />
                        </Grid.Cell>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Cell width="1/1">
                            <Form.Field
                                inputProps={{
                                    value: value.effectiveDate
                                        ? format(value.effectiveDate, DATE_FORMAT)
                                        : undefined,
                                    onChange: handleEffectiveDateChange,
                                    disabled: isLoading,
                                    note: (
                                        <Fragment>
                                            Current policy period:&nbsp;
                                            <DateDisplay value={effectivePeriodStart} />
                                            &nbsp;-&nbsp;
                                            <DateDisplay value={effectivePeriodEnd} />
                                        </Fragment>
                                    ),
                                }}
                                title="Effective date"
                                messages={fields.effectiveDate.messages}
                                type={fields.effectiveDate.type}
                                data-e2e="effective-date"
                            />
                        </Grid.Cell>
                    </Grid.Row>
                    {hasRenewalApplication && (
                        <Grid.Row>
                            <Grid.Cell width="1/1">
                                <StatusMessage status="warning">
                                    The renewal application started prior to this change. Processing
                                    the request will result in the renewal application being reset.
                                </StatusMessage>
                            </Grid.Cell>
                        </Grid.Row>
                    )}
                    <Grid.Row>
                        <Grid.Cell />
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Cell>
                            <ButtonBar>
                                <Button disabled={isLoading} onClick={submit} data-e2e="save">
                                    Save
                                </Button>
                                <TextButton
                                    disabled={isLoading}
                                    onClick={onClose}
                                    data-e2e="cancel"
                                >
                                    Cancel
                                </TextButton>
                            </ButtonBar>
                        </Grid.Cell>
                    </Grid.Row>
                </Grid>
            </Form>
        </BoxLayout>
    );
}
