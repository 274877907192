import React from 'react';

import { Driver } from '../../../types/Driver';
import { Table, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';

interface DriversSectionProps {
    driverList: Nullable<Immutable<Driver[]>>;
}

export const DriversSection = ({ driverList }: DriversSectionProps) => {
    if (!driverList || driverList.length == 0) {
        return null;
    }
    return (
        <StackLayout gap="none">
            <Text style="heading 5">Drivers</Text>
            <Table>
                <Table.Header>
                    <Table.Column>Driver Name</Table.Column>
                </Table.Header>
                <Table.Body>
                    {driverList.map((driver) => {
                        return (
                            <Table.Row key={driver.licenseNum}>
                                <Table.Cell>{driver.name}</Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        </StackLayout>
    );
};
