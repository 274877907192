import { defineEntityValidator, entity } from '@embroker/shotwell/core/entity/Entity';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { SKU } from '../../../analytics/types/SKU';
import { commonQuoteProps, Quote } from '../../entities/Quote';
import { LPLQuoteDetails } from '../types/LPLQuoteDetails';
import { LPLQuoteOptions } from '../types/LPLQuoteOptions';

export interface LPLQuotePurchased extends DomainEvent {
    readonly origin: 'LPLQuote';
    readonly name: 'Purchased';
    readonly totalPremium: Nullable<Money>;
    readonly applicationId: UUID;
    readonly isRenewal: boolean;
    readonly isStreamline: boolean;
    sku?: SKU;
}

export interface LPLQuote extends Quote {
    readonly options: LPLQuoteOptions;
    readonly details: LPLQuoteDetails;

    assignSpecimenPolicyFileKey(fileKey: string): void;
}

export const LPLQuote = entity<LPLQuote, Quote>({
    validator: defineEntityValidator<LPLQuote>({
        ...commonQuoteProps,
        options: LPLQuoteOptions.schema.required(),
        details: LPLQuoteDetails.schema.required(),
    }),
    assignSpecimenPolicyFileKey(fileKey: string) {
        this.props.details = {
            ...this.props.details,
            specimenPolicyFileKey: fileKey,
        };
    },
    inherits: Quote,
});
