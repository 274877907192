import type * as APIType from '@embroker/shotwell-api/app';
import {
    API,
    BrokerageGetBrokerageByBrokerResponse,
    BrokerageGetBrokersResponse,
    BrokerageGetDashboardPaymentsResponse,
    BrokerageGetDashboardPoliciesRequest,
    BrokerageGetDashboardPoliciesResponse,
    BrokerageGetDashboardQuotesResponse,
    BrokerageGetDashboardRenewalsResponse,
    BrokerageGetDigitalQuotesDataResponse,
    BrokerageGetGrossPremiumDataResponse,
    BrokerageGetLookerEmbedUrlRequest,
    BrokerageGetPolicyBindsDataResponse,
    BrokerDashboardActivity,
    BrokerDashboardQuote,
    BrokerDashboardRenewal,
    CurrencyMarshaller,
    GlobalGetConfigResponse,
    InsuranceApplicationIneligibilityReasons,
    ListOptions,
    PaymentsStripePayment,
    ShoppingCreateSignatureDocumentRequest,
    ShoppingCreateSignatureDocumentResponse,
    ShoppingGetActiveSignatureRequestRequest,
    ShoppingGetActiveSignatureRequestResponse,
    ShoppingGetSignatureDocumentRequest,
    ShoppingGetSignatureDocumentResponse,
    ShoppingSignSignatureRequestRequest,
} from '@embroker/shotwell-api/app';
import { BrokerDashboardAppStatus } from '@embroker/shotwell-api/app.spec';
import { isAPIError } from '@embroker/shotwell-api/errors';
import { inject, injectable } from '@embroker/shotwell/core/di';
import {
    InvalidArgument,
    NotAllowed,
    OperationFailed,
    UnknownEntity,
} from '@embroker/shotwell/core/Error';
import { findDomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { USD } from '@embroker/shotwell/core/types/Money';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Result,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { State } from '@embroker/shotwell/core/types/StateList';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import { differenceInDays, startOfToday } from 'date-fns';
import {
    AppTypeCode,
    InsuranceApplicationCreationTypeCode,
    InsuranceApplicationStatusCode,
    QuotingEngine,
} from '../../shopping/types/enums';
import { IneligibilityReasons } from '../../shopping/types/IneligibilityReasons';
import { isAuthenticated } from '../../userOrg/entities/Session';
import { User } from '../../userOrg/entities/User';
import { SessionRepository } from '../../userOrg/repositories/SessionRepository';
import {
    BrokerageNotFoundError,
    BrokerEmailAlreadyInUse,
    BrokerInvalidEmail,
    EditApplicationError,
    GetNaicsEligibleAppTypesError,
    InvitationExpired,
    InvitationNotFound,
    ServiceNotAvailable,
    SignatureDocumentAlreadySigned,
    SignatureDocumentManagingBrokerNotFound,
    SignatureDocumentNotAllowed,
    SignatureDocumentNotFound,
    Unauthenticated,
} from '../errors';
import { ActivityAppStatus, BrokerActivityItem } from '../types/BrokerActivityItem';
import { BrokerageOrganizationInfo } from '../types/BrokerageOrganizationInfo';
import { BrokerQuoteRecord } from '../types/BrokerQuoteRecord';
import { BrokerRenewalRecord } from '../types/BrokerRenewalRecord';
import { OutstandingPayment } from '../types/OutstandingPayment';
import {
    BrokerActivityItemList,
    BrokerOrganization,
    BrokerPolicy,
    BrokerPolicyList,
    BrokerQuoteRecordList,
    BrokerRenewalRecordList,
    BrokerRepository,
    BrokerSignUpRequest,
    BrokerSignUpResponse,
    GetConfigResponse,
    GetInvitationStatusRequest,
    GetInvitationStatusResponse,
    PageInfo,
    RequestLossRunsRequest,
    SignSignatureRequestResponse,
    UploadBORLetterRequest,
} from './index';

interface Invitation {
    token: UUID;
    accepted: boolean;
}

interface UserData {
    user: Nullable<User>;
    userResponseData: Nullable<APIType.UserAccount>;
    invitation: Nullable<Invitation>;
}

const INVALID_EMAIL_ERROR_CODE = 'invalid_email';

@injectable()
export class APIBrokerRepository implements BrokerRepository {
    private config: Nullable<Immutable<GetConfigResponse>>;
    private readonly userData: UserData;
    private isUserLoggedInBySignup: boolean;

    constructor(@inject(SessionRepository) private sessionRepo: SessionRepository) {
        this.config = null;
        this.userData = {
            user: null,
            userResponseData: null,
            invitation: null,
        };
        this.isUserLoggedInBySignup = false;
    }

    async getConfig(): AsyncResult<GetConfigResponse, InvalidArgument | OperationFailed> {
        if (this.config !== null) {
            return Success(this.config);
        }

        const requestResponse = await API.request('global/get_config', {});
        if (isErr(requestResponse)) {
            return handleOperationFailure(requestResponse);
        }

        const response = requestResponse.value as GlobalGetConfigResponse;
        const result = {
            isAppIntakeEnabled: response.is_app_intake_enabled,
            isClientApplicationEditEnabled: response.is_client_application_edit_enabled,
            isMidTermBorEnabled: response.is_mid_term_bor_enabled,
        };

        this.config = result;
        return Success(result);
    }

    async getBrokers(): AsyncResult<
        BrokerageGetBrokersResponse,
        InvalidArgument | OperationFailed
    > {
        const result = await API.request('brokerage/get_brokers');

        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        return Success(result.value);
    }

    async getBrokerageByBroker(
        userId: UUID,
    ): AsyncResult<BrokerageGetBrokerageByBrokerResponse, BrokerageNotFoundError> {
        const result = await API.request('brokerage/get_brokerage_by_broker', { user_id: userId });

        if (isErr(result)) {
            return Failure(BrokerageNotFoundError());
        }

        return Success(result.value);
    }

    public async getActiveBroker(): AsyncResult<
        User,
        Unauthenticated | InvalidArgument | OperationFailed | UnknownEntity
    > {
        const session = await this.sessionRepo.getActiveSession();
        if (!isAuthenticated(session.value)) {
            return Failure(Unauthenticated());
        }

        const result = await API.request('user/get');

        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        const userAccount = result.value.user;
        const userObject = {
            id: userAccount.id,
            firstName: userAccount.first_name || null,
            lastName: userAccount.last_name || null,
            title: userAccount.prefix_title,
            phoneNumber: userAccount.phone,
            email: userAccount.email as EmailAddress,
            signUpInviteToken: null,
            password: null,
            passwordResetToken: null,
            certificateInviteToken: null,
            oldPassword: null,
            createdAt: userAccount.created_at,
            isUserLoggedInBySignup: this.isUserLoggedInBySignup,
            isBroker: userAccount.is_broker,
        };
        const resultUser = await User.create(userObject);

        if (isErr(resultUser)) {
            return handleOperationFailure(resultUser);
        }
        this.userData.user = resultUser.value;
        this.userData.userResponseData = userAccount;

        return Success(resultUser.value);
    }

    public async save(
        user: User,
    ): AsyncResult<
        void,
        InvalidArgument | OperationFailed | BrokerEmailAlreadyInUse | BrokerInvalidEmail
    > {
        const updatedEvent = findDomainEvent(user.events, 'User', 'Updated');

        // update user
        if (updatedEvent !== undefined) {
            const updateExistingResult = await this.updateExisting(user);

            if (isErr(updateExistingResult)) {
                return updateExistingResult;
            }

            return Success();
        }

        return Failure(OperationFailed({ message: 'Invalid save request' }));
    }

    async getBrokerageOrganizationInfo(
        orgId: UUID,
    ): AsyncResult<BrokerageOrganizationInfo, InvalidArgument | OperationFailed | NotAllowed> {
        const result = await API.request('brokerage/get_organization_info', { org_id: orgId });

        if (isErr(result)) {
            const error = result.errors[0];
            if (isAPIError(error) && error.details.name === 'operation_not_allowed') {
                return Failure(NotAllowed({ operation: 'Broker is not an organization manager' }));
            }
            if (isAPIError(error) && error.details.name === 'user_not_broker') {
                return Failure(NotAllowed({ operation: 'User is not a broker' }));
            }
            return handleOperationFailure(result);
        }

        const orgInfo = BrokerageOrganizationInfo.create({
            name: result.value.organization_info?.name || undefined,
            address: result.value.organization_info?.mailing_address || undefined,
            state: (result.value.organization_info?.mailing_state || undefined) as State,
            city: result.value.organization_info?.mailing_city || undefined,
            revenue: CurrencyMarshaller.unmarshal(result.value.organization_info?.revenue ?? 0),
            county: result.value.organization_info?.mailing_county || undefined,
            website: (result.value.organization_info?.website || undefined) as URI,
            industry: result.value.organization_info?.industry || undefined,
            managingBrokerName: result.value.organization_info?.managing_broker_name || undefined,
            employeeCount: result.value.organization_info?.employee_count,
            zip: (result.value.organization_info?.zip || undefined) as ZipCode,
            email: (result.value.organization_info?.email || undefined) as EmailAddress,
            phoneNumber: result.value.organization_info?.phone_number,
        });

        if (isErr(orgInfo)) {
            return orgInfo;
        }

        return Success(orgInfo.value);
    }

    async getBrokerPolicyList(
        request: BrokerageGetDashboardPoliciesRequest,
    ): AsyncResult<BrokerPolicyList, InvalidArgument | OperationFailed> {
        const result = await API.request('brokerage/get_dashboard_policies', request);
        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        const resultValue: Immutable<BrokerageGetDashboardPoliciesResponse> = result.value;
        const { policy_list, list_page } = resultValue;

        const policyList: BrokerPolicy[] = policy_list.map((item) => ({
            companyId: item.insured_id,
            companyName: item.insured_name,
            effectiveDate: item.effective_period_start,
            expirationDate: item.effective_period_end,
            policyId: item.id,
            policyNumber: item.policy_num,
            premium: item.premium,
            managingBrokerName: item.managing_broker_name,
            productType: item.product_type,
        }));

        const pageInfo: PageInfo = {
            totalItems: list_page.total_count,
            size: list_page.page_size,
            index: list_page.page_index,
        };

        return Success({ policyList, pageInfo });
    }

    async getBrokerQuoteRecordList(
        options: ListOptions,
    ): AsyncResult<BrokerQuoteRecordList, InvalidArgument | OperationFailed> {
        const result = await API.request('brokerage/get_dashboard_quotes', options);

        if (isErr(result)) {
            return handleOperationFailure(result);
        }
        const { application_list, list_page }: Immutable<BrokerageGetDashboardQuotesResponse> =
            result.value;

        const quoteListResult = await APIBrokerRepository.toBrokerQuoteRecordList(application_list);

        if (isErr(quoteListResult)) {
            return Failure(
                OperationFailed({
                    message: 'Failed to convert broker quote records',
                    errors: quoteListResult.errors,
                }),
            );
        }

        const pageInfo: PageInfo = {
            totalItems: list_page.total_count,
            size: list_page.page_size,
            index: list_page.page_index,
        };

        return Success({ quoteList: quoteListResult.value, pageInfo });
    }

    async getBrokerRenewalRecordList(
        options: ListOptions,
    ): AsyncResult<BrokerRenewalRecordList, InvalidArgument | OperationFailed> {
        const result = await API.request('brokerage/get_dashboard_renewals', options);
        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        const resultValue: Immutable<BrokerageGetDashboardRenewalsResponse> = result.value;
        const { application_list, list_page } = resultValue;

        const renewalListResult = await APIBrokerRepository.toBrokerRenewalRecordList(
            application_list,
        );

        if (isErr(renewalListResult)) {
            return Failure(
                OperationFailed({
                    message: 'Failed to convert broker renewal records',
                    errors: renewalListResult.errors,
                }),
            );
        }

        const pageInfo: PageInfo = {
            totalItems: list_page.total_count,
            size: list_page.page_size,
            index: list_page.page_index,
        };

        return Success({ renewalList: renewalListResult.value, pageInfo });
    }

    async getBrokerActivityItemsList(
        options: ListOptions,
    ): AsyncResult<BrokerActivityItemList, InvalidArgument | OperationFailed> {
        const result = await API.request('brokerage/get_dashboard_activity_list', {
            list_options: options,
        });

        if (isErr(result)) {
            return handleOperationFailure(result);
        }

        const { activity_list, list_page, partial_results } = result.value;

        const activityListResult = await APIBrokerRepository.toBrokerActivityItemList(
            activity_list,
        );

        if (isErr(activityListResult)) {
            return Failure(
                OperationFailed({
                    message: 'Failed to convert broker activity records',
                    errors: activityListResult.errors,
                }),
            );
        }

        const pageInfo: PageInfo = {
            totalItems: list_page.total_count,
            size: list_page.page_size,
            index: list_page.page_index,
        };

        return Success({
            activityList: activityListResult.value,
            pageInfo,
            partialResults: partial_results,
        });
    }

    async getBrokerageOutstandingPayments(): AsyncResult<
        OutstandingPayment[],
        InvalidArgument | OperationFailed | ServiceNotAvailable
    > {
        const response = await API.request('brokerage/get_dashboard_payments', {
            list_options: null,
        });
        if (isErr(response)) {
            const error = response.errors[0];
            if (isAPIError(error) && error.details.name === 'service_unavailable') {
                return Failure(ServiceNotAvailable('Payments'));
            }
            return handleOperationFailure(response);
        }

        const responseValue: Immutable<BrokerageGetDashboardPaymentsResponse> = response.value;
        const { payment_list } = responseValue;

        const result: Array<OutstandingPayment> = [];
        payment_list.forEach((payment: Immutable<PaymentsStripePayment>) => {
            const outstandingPayment = OutstandingPayment.create({
                id: payment.id ?? undefined,
                companyId: payment.organization_id,
                companyName: payment.organization_name ?? 'N/A',
                balance: payment.balance ?? USD(0),
                effectiveDate: payment.meta.policy_effective_date,
                invoiceNumber: payment.name,
                policyId: payment.policy_id ?? undefined,
                policyNumber: payment.meta.policy_number ?? undefined,
            });

            if (!isErr(outstandingPayment)) {
                result.push(outstandingPayment.value);
            }
        });

        return Success(result);
    }

    async getBrokerOrganizations(
        broker?: UUID,
        filter?: string,
        count?: number,
    ): AsyncResult<BrokerOrganization[], InvalidArgument | OperationFailed> {
        const getBrokerOrganizationResult = await API.request('brokerage/get_organizations', {
            broker,
            filter,
            count,
        });

        if (isErr(getBrokerOrganizationResult)) {
            return handleOperationFailure(getBrokerOrganizationResult);
        }

        const organizationList = getBrokerOrganizationResult.value.map(
            ({ id, name, city, state, managing_broker }) => ({
                id,
                name,
                city,
                state: state as State,
                broker: managing_broker,
            }),
        );

        return Success(organizationList);
    }

    public async getNaicsEligibleAppTypes(
        naics: string,
    ): AsyncResult<string[], GetNaicsEligibleAppTypesError> {
        const result = await API.request('brokerage/get_naics_eligible_app_types', {
            naics,
        });

        if (isErr(result)) {
            return Failure(GetNaicsEligibleAppTypesError());
        }

        return Success(result.value.app_type_list);
    }

    async editApplication(applicationId: UUID): AsyncResult<void, EditApplicationError> {
        const editApplicationResult = await API.request('brokerage/edit_application', {
            app_id: applicationId,
        });

        if (isErr(editApplicationResult)) {
            return Failure(EditApplicationError());
        }

        return Success();
    }

    private static async toBrokerQuoteRecordList(
        applicationList: Immutable<BrokerDashboardQuote[]>,
    ): AsyncResult<BrokerQuoteRecord[], InvalidArgument | OperationFailed> {
        const list: Immutable<BrokerQuoteRecord>[] = [];

        for (const app of applicationList) {
            const result = await this.toBrokerQuoteRecord(app);
            if (isErr(result)) {
                return handleOperationFailure(result);
            }
            list.push(result.value);
        }

        return Success(list);
    }

    private async updateExisting(
        user: User,
    ): AsyncResult<void, OperationFailed | InvalidArgument | BrokerInvalidEmail> {
        if (!this.userData.userResponseData) {
            return Failure(
                OperationFailed({
                    message: 'User must be fetched with getUser before it can be saved',
                }),
            );
        }
        const requestDataResult = APIBrokerRepository.buildBrokerUpdateRequest(user, this.userData);
        if (isErr(requestDataResult)) {
            return requestDataResult;
        }

        const result = await API.request('brokerage/update_broker', requestDataResult.value);
        if (isErr(result)) {
            for (const error of result.errors) {
                if (
                    isAPIError(error) &&
                    error.details.name === INVALID_EMAIL_ERROR_CODE &&
                    user.email !== null
                ) {
                    return Failure(BrokerInvalidEmail(user.email));
                }
            }
            return handleOperationFailure(result);
        }
        this.userData.user = user;
        return Success();
    }

    /**
     * Create request object from raw response user/broker data and user entity data
     *
     * @param newUserData User entity used to create request object
     * @param currentUserData Last cached raw user data
     * @returns User request object
     */
    private static buildBrokerUpdateRequest(
        newUserData: User,
        currentUserData: UserData,
    ): Result<APIType.BrokerageUpdateBrokerRequest, InvalidArgument> {
        if (newUserData.email === null) {
            return Failure(
                InvalidArgument({ argument: 'newUserData.email', value: newUserData.email }),
            );
        }

        let hasMultipleOrganizations = false;
        let archived: Nullable<boolean> = false;
        let howDidYouHearAboutEmbroker: Nullable<string> = null;
        let createdAt: Nullable<Date> = null;
        let loggedAsAdmin: Nullable<boolean> = null;
        if (
            currentUserData !== null &&
            currentUserData.userResponseData !== undefined &&
            currentUserData.userResponseData !== null
        ) {
            const serverUserData = currentUserData.userResponseData;
            hasMultipleOrganizations = serverUserData.has_multiple_orgs;
            archived = serverUserData.archived;
            howDidYouHearAboutEmbroker =
                serverUserData.how_did_you_hear_about_embroker === undefined
                    ? null
                    : serverUserData.how_did_you_hear_about_embroker;
            createdAt = serverUserData.created_at === undefined ? null : serverUserData.created_at;
            loggedAsAdmin =
                serverUserData.logged_as_admin === undefined
                    ? null
                    : serverUserData.logged_as_admin;
        }

        if (newUserData.firstName === null) {
            return Failure(
                InvalidArgument({ argument: 'firstName', value: newUserData.firstName }),
            );
        }

        if (newUserData.lastName === null) {
            return Failure(InvalidArgument({ argument: 'lastName', value: newUserData.lastName }));
        }

        return Success({
            org: null,
            pwd: newUserData.password,
            user: {
                id: newUserData.id,
                email: newUserData.email,
                prefix_title: newUserData.title,
                first_name: newUserData.firstName,
                last_name: newUserData.lastName,
                phone: newUserData.phoneNumber,
                phone_ext: null,
                has_multiple_orgs: hasMultipleOrganizations,
                archived: archived,
                created_at: createdAt,
                logged_as_admin: loggedAsAdmin,
                how_did_you_hear_about_embroker: howDidYouHearAboutEmbroker,
                is_broker: true,
                brokerage_id: null, // not actually used in broker update
            },
        });
    }

    private static async toBrokerQuoteRecord(app: Immutable<BrokerDashboardQuote>) {
        return BrokerQuoteRecord.create({
            applicationId: app.application_id,
            organizationId: app.applicant_id,
            organizationName: app.applicant_name,
            managingBroker: app.managing_broker,
            dateStarted: app.started_at,
            dateSubmitted: app.submitted_at,
            status: app.status as InsuranceApplicationStatusCode,
            isPristine: app.is_questionnaire_pristine,
            hasQuotes: app.has_quotes,
            daysToQuoteExpiration: app.expires_at
                ? differenceInDays(app.expires_at, startOfToday())
                : undefined,
            quotingEngine: app.quoting_engine as QuotingEngine,
            premium: app.premium,
            ineligibilityReasons: toIneligibilityReasons(app.ineligibility_reasons),
            appType: app.app_type as AppTypeCode,
            coverageType: app.coverage_type,
            creationType: app.creation_type as InsuranceApplicationCreationTypeCode,
        });
    }

    private static async toBrokerRenewalRecordList(
        applicationList: Immutable<BrokerDashboardRenewal[]>,
    ): AsyncResult<BrokerRenewalRecord[], InvalidArgument | OperationFailed> {
        const list: BrokerRenewalRecord[] = [];

        for (const app of applicationList) {
            const result = await this.toBrokerRenewalRecord(app);
            if (isErr(result)) {
                return handleOperationFailure(result);
            }
            list.push(result.value);
        }

        return Success(list);
    }

    private static async toBrokerRenewalRecord(app: Immutable<BrokerDashboardRenewal>) {
        return BrokerRenewalRecord.create({
            applicationId: app.application_id,
            organizationId: app.applicant_id,
            organizationName: app.applicant_name,
            managingBroker: app.managing_broker,
            dateSubmitted: app.submitted_at,
            status: app.status,
            isPristine: app.is_questionnaire_pristine,
            hasQuotes: app.has_quotes,
            quotingEngine: app.quoting_engine as QuotingEngine,
            ineligibilityReasons: toIneligibilityReasons(app.ineligibility_reasons),
            previousPolicyPremium: app.previous_premium,
            previousPolicyExpirationDate: app.renewed_policy_expiration_date,
            previousPolicyProductType: app.previous_product_type,
            renewedPolicyIdList: app.renewed_policy_id_list,
            isSubmittedExternally: app.is_submitted_externally,
            isStreamline: app.is_streamline,
        });
    }

    private static async toBrokerActivityItemList(
        activityList: Immutable<BrokerDashboardActivity[]>,
    ): AsyncResult<BrokerActivityItem[], InvalidArgument | OperationFailed> {
        const list: BrokerActivityItem[] = [];

        for (const activityItem of activityList) {
            const result = await this.toBrokerActivityItem(activityItem);
            if (isErr(result)) {
                return handleOperationFailure(result);
            }
            list.push(result.value);
        }

        return Success(list);
    }

    private static async toBrokerActivityItem(activity: BrokerDashboardActivity) {
        function mapActivityAppStatus(
            activity_app_status: BrokerDashboardAppStatus | undefined,
        ): ActivityAppStatus | undefined {
            switch (activity_app_status) {
                case 'in_creation':
                    return 'inCreation';
                case 'in_progress':
                    return 'inProgress';
                default:
                    return undefined;
            }
        }

        return BrokerActivityItem.create({
            record: activity.activity_type,
            organizationId: activity.applicant_id,
            applicationId: activity.application_id,
            applicationStatus: activity.application_status as InsuranceApplicationStatusCode,
            organizationName: activity.applicant_name,
            productType: activity.product_type,
            expiryDate: activity.expires_at,
            hasQuotes: activity.has_quotes,
            isSubmittedExternally: activity.is_submitted_externally,
            isStreamline: activity.is_streamline,
            appStatus: mapActivityAppStatus(activity.app_status),
        });
    }

    async getActiveSignatureRequest(
        request: ShoppingGetActiveSignatureRequestRequest,
    ): AsyncResult<ShoppingGetActiveSignatureRequestResponse, InvalidArgument | OperationFailed> {
        const activeSignatureRequestResponse = await API.request(
            'shopping/get_active_signature_request',
            request,
        );

        if (isErr(activeSignatureRequestResponse)) {
            return handleOperationFailure(activeSignatureRequestResponse);
        }

        return Success(activeSignatureRequestResponse.value);
    }

    async createSignatureDocument(
        request: ShoppingCreateSignatureDocumentRequest,
    ): AsyncResult<
        ShoppingCreateSignatureDocumentResponse,
        | OperationFailed
        | InvalidArgument
        | SignatureDocumentNotFound
        | SignatureDocumentAlreadySigned
        | SignatureDocumentManagingBrokerNotFound
    > {
        const result = await API.request('shopping/create_signature_document', request);
        if (isErr(result)) {
            const error = result.errors[0];
            if (isAPIError(error)) {
                if (error.details.name === 'not_found') {
                    return Failure(SignatureDocumentNotFound());
                } else if (error.details.name === 'already_signed') {
                    return Failure(SignatureDocumentAlreadySigned());
                } else if (error.details.name === 'managing_broker_not_found')
                    return Failure(SignatureDocumentManagingBrokerNotFound());
            }
            return handleOperationFailure(result);
        }
        return Success(result.value);
    }

    async getSignatureDocument(
        request: ShoppingGetSignatureDocumentRequest,
    ): AsyncResult<
        ShoppingGetSignatureDocumentResponse,
        | OperationFailed
        | InvalidArgument
        | SignatureDocumentNotFound
        | SignatureDocumentNotAllowed
        | SignatureDocumentAlreadySigned
    > {
        const signatureDocumentResponse = await API.request(
            'shopping/get_signature_document',
            request,
        );
        if (isErr(signatureDocumentResponse)) {
            const error = signatureDocumentResponse.errors[0];
            if (isAPIError(error)) {
                if (error.details.name === 'not_found') {
                    return Failure(SignatureDocumentNotFound());
                } else if (error.details.name === 'not_allowed') {
                    return Failure(SignatureDocumentNotAllowed());
                } else if (error.details.name === 'already_signed') {
                    return Failure(SignatureDocumentAlreadySigned());
                }
            }

            return handleOperationFailure(signatureDocumentResponse);
        }

        return Success(signatureDocumentResponse.value);
    }

    async signSignatureRequest(
        request: ShoppingSignSignatureRequestRequest,
    ): AsyncResult<
        SignSignatureRequestResponse,
        | InvalidArgument
        | OperationFailed
        | SignatureDocumentAlreadySigned
        | SignatureDocumentNotFound
    > {
        const signSignatureResponse = await API.request('shopping/sign_signature_request', request);
        if (isErr(signSignatureResponse)) {
            const error = signSignatureResponse.errors[0];
            if (isAPIError(error)) {
                if (error.details.name === 'already_signed') {
                    return Failure(SignatureDocumentAlreadySigned());
                }
                if (error.details.name === 'not_found') {
                    return Failure(SignatureDocumentAlreadySigned());
                }
            }
            return handleOperationFailure(signSignatureResponse);
        }
        return Success({
            signatureToken: signSignatureResponse.value.signature_token,
        });
    }

    async getRenewalCount(): AsyncResult<number, InvalidArgument | OperationFailed> {
        const getRenewalCountResponse = await API.request('brokerage/get_renewal_count');

        if (isErr(getRenewalCountResponse)) {
            return handleOperationFailure(getRenewalCountResponse);
        }

        return Success(getRenewalCountResponse.value.total_renewals);
    }

    async getQuoteCount(): AsyncResult<number, InvalidArgument | OperationFailed> {
        const getQuoteCountResponse = await API.request('brokerage/get_quote_count');

        if (isErr(getQuoteCountResponse)) {
            return handleOperationFailure(getQuoteCountResponse);
        }

        return Success(getQuoteCountResponse.value.total_quotes);
    }

    async getOrganizationCount(): AsyncResult<number, InvalidArgument | OperationFailed> {
        const getOrganizationCountResponse = await API.request('brokerage/get_organization_count');

        if (isErr(getOrganizationCountResponse)) {
            return handleOperationFailure(getOrganizationCountResponse);
        }

        return Success(getOrganizationCountResponse.value.total_organizations);
    }

    async getPaymentCount(): AsyncResult<number, InvalidArgument | OperationFailed> {
        const getPaymentCountResponse = await API.request('brokerage/get_payments_count');

        if (isErr(getPaymentCountResponse)) {
            return handleOperationFailure(getPaymentCountResponse);
        }

        return Success(getPaymentCountResponse.value.total_payments);
    }

    async getLookerReportUrl({
        resource,
    }: BrokerageGetLookerEmbedUrlRequest): AsyncResult<string, InvalidArgument | OperationFailed> {
        const getLookerEmbedUrlResponse = await API.request('brokerage/get_looker_embed_url', {
            resource,
        });

        if (isErr(getLookerEmbedUrlResponse)) {
            return handleOperationFailure(getLookerEmbedUrlResponse);
        }

        return Success(getLookerEmbedUrlResponse.value.url);
    }

    async getQuoteToBindRatio(): AsyncResult<number, InvalidArgument | OperationFailed> {
        const result = await API.request('brokerage/get_quote_to_bind_ratio');
        if (isErr(result)) {
            return handleOperationFailure(result);
        }
        return Success(result.value.quote_to_bind_ratio);
    }

    async getDigitalQuotesData(): AsyncResult<
        BrokerageGetDigitalQuotesDataResponse,
        InvalidArgument | OperationFailed
    > {
        const digitalQuotesData = await API.request('brokerage/get_digital_quotes_data');

        if (isErr(digitalQuotesData)) {
            return handleOperationFailure(digitalQuotesData);
        }

        return Success(digitalQuotesData.value);
    }

    async getBoundPoliciesData(): AsyncResult<
        BrokerageGetPolicyBindsDataResponse,
        InvalidArgument | OperationFailed
    > {
        const policyBindsData = await API.request('brokerage/get_policy_binds_data');

        if (isErr(policyBindsData)) {
            return handleOperationFailure(policyBindsData);
        }

        return Success(policyBindsData.value);
    }

    async getGrossPremiumData(): AsyncResult<
        BrokerageGetGrossPremiumDataResponse,
        InvalidArgument | OperationFailed
    > {
        const grossPremiumData = await API.request('brokerage/get_gross_premium_data');

        if (isErr(grossPremiumData)) {
            return handleOperationFailure(grossPremiumData);
        }

        return Success(grossPremiumData.value);
    }

    async uploadBORLetter(
        request: UploadBORLetterRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed> {
        const response = await API.request('brokerage/upload_bor_letter', {
            broker_id: request.brokerId,
            file_key: request.fileKey,
            additional_notes: request.additionalNotes,
            organization_id: request.organizationId,
        });

        if (isErr(response)) {
            return handleOperationFailure(response);
        }

        return Success();
    }

    async requestLossRuns(
        request: RequestLossRunsRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed | BrokerageNotFoundError | NotAllowed> {
        const response = await API.request('brokerage/request_loss_runs', {
            policy_number_list: request.policyNumberList,
            send_to_current_broker: request.sendToCurrentBroker,
            recipient_list: request.recipientList,
        });

        if (isErr(response)) {
            const error = response.errors[0];
            if (isAPIError(error) && error.details.name === 'policy_number_list_empty') {
                return Failure(
                    InvalidArgument({
                        argument: 'policy_number_list',
                        value: request.policyNumberList,
                    }),
                );
            }
            if (isAPIError(error) && error.details.name === 'recipient_not_set') {
                return Failure(
                    OperationFailed({
                        message: 'Email recipient not set',
                        errors: [error],
                    }),
                );
            }
            return handleOperationFailure(response);
        }

        return Success();
    }

    async getInvitationStatus({
        invitationToken,
    }: GetInvitationStatusRequest): AsyncResult<
        GetInvitationStatusResponse,
        InvitationNotFound | InvalidArgument | OperationFailed
    > {
        const response = await API.request('brokerage/get_invitation_status', {
            invitation_token: invitationToken,
        });
        if (isErr(response)) {
            const error = response.errors[0];
            if (isAPIError(error) && error.details.name === 'invitation_not_found') {
                return Failure(InvitationNotFound());
            }
            return handleOperationFailure(response);
        }
        return Success({ status: response.value.invitation_status });
    }

    async signUpBroker({
        firstName,
        lastName,
        password,
        invitationToken,
    }: BrokerSignUpRequest): AsyncResult<
        BrokerSignUpResponse,
        InvitationNotFound | InvitationExpired | InvalidArgument | OperationFailed
    > {
        const response = await API.request('brokerage/broker_accept_invitation', {
            invitation_token: invitationToken,
            first_name: firstName,
            last_name: lastName,
            password,
        });
        if (isErr(response)) {
            const error = response.errors[0];
            if (isAPIError(error) && error.details.name === 'invitation_not_found') {
                return Failure(InvitationNotFound());
            }
            if (isAPIError(error) && error.details.name === 'invitation_expired') {
                return Failure(InvitationExpired());
            }
            return handleOperationFailure(response);
        }

        return Success({ brokerId: response.value.brokerId });
    }
}

function toIneligibilityReasons(
    apiReasons?: Immutable<InsuranceApplicationIneligibilityReasons>,
): Immutable<IneligibilityReasons> | undefined {
    if (apiReasons === undefined) {
        return undefined;
    }

    return {
        declinedReasons: apiReasons.declined_reasons,
        referralReasons: apiReasons.referral_reasons,
        investigationNeededReasons: apiReasons.investigation_needed_reasons,
    };
}
