import { AppTypeCodeList } from '@embroker/shotwell-api/enums';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { execute } from '@embroker/shotwell/core/UseCase';
import { EntityProps } from '@embroker/shotwell/core/entity/Entity';
import { Immutable } from '@embroker/shotwell/core/types';
import {
    AsyncResult,
    Success,
    isErr,
    isOK,
    mergeErrors,
} from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    Button,
    ButtonBar,
    ColumnLayout,
    FloatingLayout,
    GridLayout,
    Header,
    Image,
    PageLayout,
    Spinner,
    StackLayout,
    Text,
    TextButton,
    usePageLayout,
    useResponsive,
} from '@embroker/ui-toolkit/v2';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { hasRole } from '../../../userOrg/entities/Session';
import { MPLVerticalList, NAICS_CODE_TO_VERTICAL } from '../../../userOrg/types/enums';
import { GetOrganizationProfile } from '../../../userOrg/useCases/GetOrganizationProfile';
import { AppContext } from '../../../view/AppContext';
import { navigateToErrorPage } from '../../../view/errors';
import { NavigateFunction, useNavigation } from '../../../view/hooks/useNavigation';
import { Application } from '../../entities/Application';
import {
    DEFAULT_RISK_ASSESSMENT_COVERAGE_DATA,
    enrichBOPCardData,
    enrichMPLCardData,
    enrichWCCardData,
} from '../../types/RiskAssessmentCardData';
import { DeleteApplication } from '../../useCases/DeleteApplication';
import { GetApplication } from '../../useCases/GetApplication';
import { GetPartialEligibility } from '../../useCases/GetPartialEligibility';
import { SaveApplicationLastPage } from '../../useCases/SaveApplicationLastPage';
import { SubmitApplication } from '../../useCases/SubmitApplication';
import { UpdateApplicationQuestionnaire } from '../../useCases/UpdateApplicationQuestionnaire';
import {
    MPLBundleUnderlyingAppType,
    AppTypeCodeListBOPChubb,
    AppTypeCodeListMPL,
    AppTypeCodeListWCChubb,
} from '@app/shopping/types/enums';
import { RiskAssessmentCard } from './RiskAssessmentCard.view';
import { publishFormEvent } from './ApplicationQuestionnaire';
import { FormEventName } from '../../useCases/PublishFormEvents';
import { GetApplicationQuestionnaire } from '../../useCases/GetApplicationQuestionnaire';

const RISK_PROFILE_PAGE_ID = 'risk_profile';

type Props = {
    applicationId: UUID;
    organizationId: UUID;
    completed: boolean;
};

export function RiskAssessment({ applicationId, organizationId, completed }: Props) {
    const { navigate } = useNavigation();

    const { globalConfig } = useContext(AppContext);

    const { result: getOrganizationProfileResult } = useUseCase(GetOrganizationProfile, {
        organizationId,
    });
    const { result: getApplicationResult } = useUseCase(GetApplication, {
        applicationId,
    });
    const { result: partialEligibilityResult } = useUseCase(GetPartialEligibility, {
        applicationId,
    });
    const { result: formEngineResult } = useUseCase(GetApplicationQuestionnaire, {
        applicationId,
        globalConfig,
    });

    useEffect(() => {
        if (
            !formEngineResult ||
            !isOK(formEngineResult) ||
            !getApplicationResult ||
            !isOK(getApplicationResult)
        ) {
            return;
        }
        const formEngine = formEngineResult.value.formEngine;
        const currentApplication = getApplicationResult.value.application;
        publishFormEvent(
            FormEventName.Viewed,
            formEngine,
            applicationId,
            currentApplication?.appType,
            currentApplication?.shoppingCoverageList,
            currentApplication?.isRenewal,
            RISK_PROFILE_PAGE_ID,
        );
    }, [applicationId, formEngineResult, getApplicationResult]);

    useEffect(() => {
        if (getOrganizationProfileResult && getApplicationResult && partialEligibilityResult) {
            if (isErr(getOrganizationProfileResult)) {
                navigateToErrorPage(navigate, getOrganizationProfileResult.errors);
            }
            if (isErr(getApplicationResult)) {
                navigateToErrorPage(navigate, getApplicationResult.errors);
            }
            if (isErr(partialEligibilityResult)) {
                navigateToErrorPage(navigate, partialEligibilityResult.errors);
            }
            if (
                isOK(partialEligibilityResult) &&
                partialEligibilityResult.value.appTypeList?.length === 0
            ) {
                execute(SubmitApplication, { applicationId });
                navigate('/shopping/application/guided-flow-ineligible');
            }
            if (isOK(getApplicationResult)) {
                const application = getApplicationResult.value.application;

                if (!application.bundleDetails) {
                    navigateToErrorPage(navigate, [
                        InvalidArgument({
                            argument: 'bundleDetails',
                            value: application.bundleDetails,
                        }),
                    ]);
                }
            }
        }
    }, [
        applicationId,
        organizationId,
        getOrganizationProfileResult,
        getApplicationResult,
        partialEligibilityResult,
        navigate,
    ]);

    if (!getOrganizationProfileResult || !getApplicationResult || !partialEligibilityResult) {
        return <Spinner />;
    }

    if (
        partialEligibilityResult &&
        isOK(partialEligibilityResult) &&
        getOrganizationProfileResult &&
        isOK(getOrganizationProfileResult) &&
        getApplicationResult &&
        isOK(getApplicationResult)
    ) {
        const application = getApplicationResult.value.application;
        return (
            <RiskAssessmentView
                applicationId={application.id}
                companyName={getOrganizationProfileResult.value.organization.companyLegalName}
                initialDisplayCoverages={getDisplayCoverages(
                    application,
                    partialEligibilityResult.value.appTypeList,
                )}
                questionnaireData={getParsedQuestionnaire(application, navigate)}
                completed={completed}
            />
        );
    }
    return null;
}

interface CoverageProps {
    appId: UUID;
    appType: MPLBundleUnderlyingAppType;
    selected: boolean;
}

interface RiskAssessmentViewProps {
    applicationId: UUID;
    companyName: string;
    initialDisplayCoverages: CoverageProps[];
    questionnaireData: any;
    completed: boolean;
}

function RiskAssessmentView({
    applicationId,
    companyName,
    initialDisplayCoverages,
    questionnaireData,
    completed,
}: RiskAssessmentViewProps) {
    const { navigate } = useNavigation();
    const { activeSession } = useContext(AppContext);
    const isBroker = hasRole(activeSession, 'broker');
    const [displayCoverages, setDisplayCoverages] =
        useState<CoverageProps[]>(initialDisplayCoverages);
    const visited = questionnaireData.risk_profile_visited;

    const handleSaveAndExit = useCallback(async () => {
        if (isBroker) {
            navigate('/broker/dashboard');
            return;
        }
        navigate('/summary');
    }, [isBroker, navigate]);

    const handleFinishTheApplication = useCallback(async () => {
        if (!completed) {
            await execute(SaveApplicationLastPage, {
                applicationId,
                userId: activeSession.userId as UUID,
                lastPage: RISK_PROFILE_PAGE_ID,
            });
        }

        const resultList = await Promise.all(
            displayCoverages.map((coverage) => {
                if (!coverage.selected) {
                    return execute(DeleteApplication, {
                        id: coverage.appId,
                    });
                }
                return Success();
            }),
        );

        for (const result of resultList) {
            if (isErr(result)) {
                return navigateToErrorPage(navigate, mergeErrors(resultList).errors);
            }
        }
        if (!visited) {
            const result = await setRiskProfileVisited(applicationId, questionnaireData);
            if (isErr(result)) {
                navigateToErrorPage(navigate, result.errors);
                return;
            }
        }

        navigate(
            URI.build('/shopping/application', {
                applicationId,
            }),
        );
    }, [
        completed,
        applicationId,
        activeSession.userId,
        displayCoverages,
        navigate,
        questionnaireData,
        visited,
    ]);

    const handleCardChecked = (checked: boolean, appType: MPLBundleUnderlyingAppType) => {
        const newDisplayCoverages = displayCoverages.map((coverage) => {
            if (coverage.appType === appType) {
                coverage.selected = checked;
            }
            return coverage;
        });
        setDisplayCoverages(newDisplayCoverages);
    };

    const isBreakpoint = useResponsive({ screenWidth: { smallerThan: 'tablet' } });

    const header = useMemo(
        () => (
            <Header>
                <a href="#">
                    <Image name="logotype" height={20} />
                </a>
                <StackLayout as={Text}>
                    <ColumnLayout>
                        <TextButton onClick={handleSaveAndExit}>Save and exit</TextButton>
                        {!isBreakpoint && (
                            <Button
                                onClick={handleFinishTheApplication}
                                disabled={!displayCoverages.some((coverage) => coverage.selected)}
                            >
                                Finish the application
                            </Button>
                        )}
                    </ColumnLayout>
                </StackLayout>
            </Header>
        ),
        [displayCoverages, handleFinishTheApplication, handleSaveAndExit, isBreakpoint],
    );

    const state = usePageLayout({
        header: header,
    });

    useEffect(() => {
        state.setHeader(header);
    }, [displayCoverages, state, header]);

    const displayCoverageCard = (coverage: CoverageProps) => {
        let cardData = DEFAULT_RISK_ASSESSMENT_COVERAGE_DATA[coverage.appType];
        if (!cardData) {
            return null;
        }
        if (coverage.appType === AppTypeCodeListMPL) {
            const vertical = NAICS_CODE_TO_VERTICAL[questionnaireData.naics_code];
            if (!vertical) {
                navigateToErrorPage(navigate, [
                    OperationFailed({
                        message: `Unknown vertical for NAICS code '${questionnaireData.naics_code}'`,
                    }),
                ]);
            }
            const isMPLVertical = MPLVerticalList.includes(vertical);
            if (!isMPLVertical) {
                navigateToErrorPage(navigate, [
                    OperationFailed({
                        message: `Vertical '${vertical}', represented by NAICS code '${questionnaireData.naics_code}' is not an MPL vertical`,
                    }),
                ]);
            }
            cardData = enrichMPLCardData(vertical);
        }
        if (coverage.appType === AppTypeCodeListBOPChubb) {
            cardData = enrichBOPCardData(questionnaireData.working_space_type);
        } else if (coverage.appType === AppTypeCodeListWCChubb) {
            cardData = enrichWCCardData(NAICS_CODE_TO_VERTICAL[questionnaireData.naics_code]);
        }
        return (
            <RiskAssessmentCard
                key={coverage.appType}
                appType={coverage.appType}
                disabled={visited}
                selected={coverage.selected}
                handleCardChecked={handleCardChecked}
                {...cardData}
            />
        );
    };

    return (
        <PageLayout {...state}>
            <PageLayout.Section>
                <StackLayout gap="48">
                    <StackLayout gap="32">
                        <GridLayout>
                            <StackLayout gap="none" className="u-grid-size-12">
                                <Text style="heading 2">
                                    {companyName}, we've identified some of your business risks.
                                </Text>
                                <Text style="body 1">
                                    Every business needs financial protection against unique areas
                                    of risk. Start covering your bases with some of our most popular
                                    coverages.
                                </Text>
                            </StackLayout>
                        </GridLayout>
                        <GridLayout>
                            {displayCoverages.map((coverage) => displayCoverageCard(coverage))}
                        </GridLayout>
                    </StackLayout>
                    {isBreakpoint && <span />}
                </StackLayout>
                {isBreakpoint && (
                    <FloatingLayout position="bottom">
                        <ButtonBar responsive={{ screenWidth: { smallerThan: 'tablet' } }}>
                            <Button
                                onClick={handleFinishTheApplication}
                                disabled={!displayCoverages.some((coverage) => coverage.selected)}
                            >
                                Finish the application
                            </Button>
                        </ButtonBar>
                    </FloatingLayout>
                )}
            </PageLayout.Section>
        </PageLayout>
    );
}

const CoverageDisplayPriority: { [key: MPLBundleUnderlyingAppType]: number } = {
    AppTypeCodeListMPL: 1,
    AppTypeCodeListCyberCowbell: 3,
    AppTypeCodeListBOPChubb: 2,
    AppTypeCodeListWCChubb: 4,
};

function getDisplayCoverages(
    application: Immutable<EntityProps<Application>>,
    eligibleAppTypeList: Immutable<AppTypeCodeList>,
) {
    return (
        application.bundleDetails?.appDetails.filter((item) => {
            return (
                item.appStatus !== 'InsuranceApplicationStatusCodeListDeleted' &&
                eligibleAppTypeList.some((appType) => item.appType === appType)
            );
        }) ?? []
    )
        .map((item) => {
            return {
                appId: item.appId,
                appType: item.appType,
                selected: true,
            };
        })
        .sort((a, b) => {
            return CoverageDisplayPriority[a.appType] - CoverageDisplayPriority[b.appType];
        });
}

function getParsedQuestionnaire(
    application: Immutable<EntityProps<Application>>,
    navigate: NavigateFunction,
) {
    if (!application.questionnaireData) {
        return navigateToErrorPage(navigate, [
            InvalidArgument({
                argument: 'questionnaireData',
                value: application.questionnaireData,
            }),
        ]);
    } else {
        let parsedQuestionnaireData;
        try {
            parsedQuestionnaireData = JSON.parse(application.questionnaireData);
        } catch (_e) {
            return navigateToErrorPage(navigate, [
                OperationFailed({ message: 'Failed to parse questionnaire_data' }),
            ]);
        }
        return parsedQuestionnaireData;
    }
}

function setRiskProfileVisited(
    applicationId: UUID,
    questionnaireData: any,
): AsyncResult<void, InvalidArgument | OperationFailed> {
    const updatedQuestionnaireData = questionnaireData;
    updatedQuestionnaireData.risk_profile_visited = true;
    return execute(UpdateApplicationQuestionnaire, {
        applicationId,
        questionnaireData: JSON.stringify(updatedQuestionnaireData),
    });
}
