import { HartfordBopCoverage } from '@app/quote/hartford-bop/entities/BOPHartfordQuote';
import { ColumnLayout, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import { CoverageLimitValue } from '@app/quote/hartford-bop/view/components/quoteCoverage/CoverageLimitValue';
import React from 'react';

const NO_VALUE = 0;

interface Props {
    coverage: HartfordBopCoverage;
    aggregateLimit: HartfordBopCoverage;
}

export function CoverageLimit({ coverage, aggregateLimit }: Props) {
    return (
        <StackLayout key={coverage.code} gap="8">
            <ColumnLayout gap="4">
                <Text>{coverage.description}</Text>
            </ColumnLayout>
            <StackLayout>
                {coverage.limits
                    .filter((limit) => limit.limit.amount !== NO_VALUE)
                    .map((limit, index) => (
                        <StackLayout key={index}>
                            <CoverageLimitValue
                                coverage={coverage}
                                limit={limit}
                                aggregateLimit={aggregateLimit}
                            />
                        </StackLayout>
                    ))}
            </StackLayout>
        </StackLayout>
    );
}
