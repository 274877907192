import { BundleQuote } from '@app/bundle/entities/BundleQuote';
import { QuestionnaireData } from '@app/bundle/types/BundleQuoteQuestionnaireData';
import { CoveragePackageType } from '@app/bundle/useCases/GetRecommendedCoverageQuotes';
import { CowbellCyberQuoteOptions } from './types/CowbellCyberQuoteOptions';

export const getRecommendedQuotingOptionsCowbellCyber = (
    bundleQuote: BundleQuote,
    packageType: CoveragePackageType,
    questionnaireData: QuestionnaireData,
): CowbellCyberQuoteOptions | undefined => {
    const revenueRange = QuestionnaireData.getRevenueRangeFromQuestionnaire(questionnaireData);
    const isDeselected = !bundleQuote.isCoverageSelected('LawCyberBundleCoverageCowbell');
    const retention = revenueRange === 'LOW' ? 2500 : revenueRange === 'VERY-HIGH' ? 10000 : 5000;
    switch (packageType) {
        case 'starter': {
            const limit =
                revenueRange === 'LOW' ? 100000 : revenueRange === 'VERY-HIGH' ? 1000000 : 500000;
            return {
                effectiveDate: bundleQuote.effectiveDate,
                limit,
                retention,
                isDeselected,
            };
        }
        case 'enhanced': {
            const limit = revenueRange === 'LOW' ? 500000 : 1000000;
            return {
                effectiveDate: bundleQuote.effectiveDate,
                limit,
                retention,
                isDeselected,
            };
        }
        case 'deluxe': {
            const limit = ['LOW', 'MEDIUM'].includes(revenueRange) ? 1000000 : 2000000;
            return {
                effectiveDate: bundleQuote.effectiveDate,
                limit,
                retention,
                isDeselected,
            };
        }
    }
};
