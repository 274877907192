import { Nullable } from '@embroker/shotwell/core/types';
import { State } from '@embroker/shotwell/core/types/StateList';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    Form,
    Modal,
    ReactProps,
    StackLayout,
    Text,
    TextButton,
    useModal,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { AwaitingBind } from '../../../../shopping/types/enums';
import { WizardForm } from '../../../../view/hooks/useWizardForm';
import { SignInsuranceApplicationCheckbox } from '../../../view/components/signature/SignInsuranceApplicationCheckbox';
import { CNAQuote } from '../../entities/CNAQuote';
import { CNAQuoteOptionsFormData } from './CNAQuoteLandingPage';
import { WarrantyAndFraudModalContent } from './WarrantyAndFraudModalContent';

interface CNASignaturePageProps extends ReactProps<'div'> {
    quote: CNAQuote;
    fields: WizardForm<OpaqueForm<CNAQuoteOptionsFormData>>['fields'];
    setValue: WizardForm<OpaqueForm<CNAQuoteOptionsFormData>>['setValue'];
    value: WizardForm<OpaqueForm<CNAQuoteOptionsFormData>>['value'];
    trigger: WizardForm<OpaqueForm<CNAQuoteOptionsFormData>>['trigger'];
}

export function CNASignaturePage({
    quote,
    fields,
    setValue,
    value,
    trigger,
    className,
}: CNASignaturePageProps) {
    const warrantyAndFraudModal = useModal();

    const userInfo = quote.appInfo;

    const handleStatementAgreementCheckChanged = (
        { target: { checked } }: React.ChangeEvent<HTMLInputElement>,
        property: string,
    ) => {
        setValue({
            ...value,
            [property]: checked,
        });
        trigger('sign');
    };

    return (
        <div className={className}>
            <StackLayout gap="32">
                <Text style="heading 4">Please agree to the statements below</Text>
                <Form.Field
                    type={fields.agreementToConductSignature.type}
                    messages={fields.agreementToConductSignature.messages}
                    inputProps={{
                        checked: fields.agreementToConductSignature.props.value,
                        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                            handleStatementAgreementCheckChanged(
                                event,
                                'agreementToConductSignature',
                            );
                        },
                        children: (
                            <SignInsuranceApplicationCheckbox
                                company={userInfo.company}
                                fullName={userInfo.fullName}
                                usaState={userInfo.usaState}
                                disableSurplusLinesDisclosure
                            />
                        ),
                        disabled: quote.appInfo.status === AwaitingBind,
                    }}
                    data-e2e="agreement-to-conduct-signature"
                />
                <Form.Field
                    type={fields.warrantyAndFraudSignature.type}
                    messages={fields.warrantyAndFraudSignature.messages}
                    inputProps={{
                        checked: fields.warrantyAndFraudSignature.props.value,
                        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                            handleStatementAgreementCheckChanged(
                                event,
                                'warrantyAndFraudSignature',
                            );
                        },
                        children: (
                            <Text as="span">
                                By checking this box, you certify all information provided in the
                                insurance application is true and correct and acknowledge that you
                                have read and agree to the{' '}
                                <TextButton onClick={warrantyAndFraudModal.show}>
                                    Warranty and Fraud Statement
                                </TextButton>
                            </Text>
                        ),
                        disabled: quote.appInfo.status === AwaitingBind,
                    }}
                    data-e2e="warranty-and-fraud-signature"
                />
                <Modal {...warrantyAndFraudModal} size="medium">
                    <WarrantyAndFraudModalContent
                        fullName={userInfo.fullName}
                        title={userInfo.title}
                        usaState={userInfo.usaState as Nullable<State>}
                    />
                </Modal>
            </StackLayout>
        </div>
    );
}
