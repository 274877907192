import { Immutable } from '@embroker/shotwell/core/types';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { isLimitType } from '../../../../quote/types/Limits';
import { MPLAggregateLimit, MPLPerClaimLimit } from './MPLQuoteOptions';

export interface MPLHigherLimit {
    readonly per_claim_limit: MPLPerClaimLimit;
    readonly aggregate_limit: MPLPerClaimLimit;
}

export const MPLHigherLimit = {
    ...defineValidator<MPLHigherLimit>({
        per_claim_limit: Joi.number()
            .valid(...MPLPerClaimLimit)
            .required(),
        aggregate_limit: Joi.number()
            .valid(...MPLAggregateLimit)
            .required(),
    }),
    create(lplHigherLimit?: { per_claim_limit: number; aggregate_limit: number }) {
        return MPLHigherLimit.validate(lplHigherLimit);
    },
};

export function mapFromMPLApiLimit(
    apiLimit?: Immutable<Record<string, number>>,
): MPLHigherLimit | undefined {
    if (!apiLimit) {
        return undefined;
    }
    if (!isLimitType(apiLimit)) {
        return undefined;
    }
    const limit = MPLHigherLimit.create({
        per_claim_limit: apiLimit.MultiValueLimitNameListPerClaimLimit,
        aggregate_limit: apiLimit.MultiValueLimitNameListAggregateLimit,
    });
    if (isErr(limit)) {
        return undefined;
    }
    return limit.value;
}
