import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { GetActiveSession } from './GetActiveSession';
import { NBRVFunnelRepository } from '../repositories/NBRVFunnelRepository';
import { NBRVFunnelDetails } from '../repositories/NBRVFunnelRepository/APINBRVFunnelRepository';

export interface NBRVFunnelStatus {
    shouldShowPopup: boolean;
    funnelDetails: NBRVFunnelDetails | null;
}

export interface GetNBRVFunnelStatus extends UseCase {
    execute(): AsyncResult<NBRVFunnelStatus>;
}

@injectable()
export class GetNBRVFunnelStatusUseCase extends UseCase implements GetNBRVFunnelStatus {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/GetNBRVFunnelStatus');
    /**
     * Constructor for GetNBRVFunnelStatus use case class instance
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(GetActiveSession.type) private getActiveSession: GetActiveSession,
        @inject(NBRVFunnelRepository) private nbrvFunnelRepository: NBRVFunnelRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes GetNBRVFunnelStatus use case
     * @returns Nothing if execution was successful
     */
    public async execute(): AsyncResult<NBRVFunnelStatus> {
        const sessionResult = await this.getActiveSession.execute();

        if (isErr(sessionResult)) {
            return handleOperationFailure(sessionResult);
        }

        const activeSession = sessionResult.value.session;

        const funnelDetailsResponse = await this.nbrvFunnelRepository.getNBRVFunnelDetails();

        if (isErr(funnelDetailsResponse)) {
            return handleOperationFailure(funnelDetailsResponse);
        }

        const funnelDetails = funnelDetailsResponse.value;

        if (!funnelDetails) {
            return Success({
                shouldShowPopup: false,
                funnelDetails,
            });
        }

        if (funnelDetails.forceShow) {
            return Success({
                shouldShowPopup: true,
                funnelDetails,
            });
        }

        if (!funnelDetails.shown && funnelDetails.initialSessionId !== activeSession.id) {
            return Success({
                shouldShowPopup: true,
                funnelDetails,
            });
        }

        return Success({
            shouldShowPopup: false,
            funnelDetails,
        });
    }
}

export const GetNBRVFunnelStatus: UseCaseClass<GetNBRVFunnelStatus> = GetNBRVFunnelStatusUseCase;
