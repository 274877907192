import { defineValidator } from '@embroker/shotwell/core/validation/schema';
import { Money } from '@embroker/shotwell/core/types/Money';

export interface ExcessQuoteDetails {
    readonly technologyPremium: Money;
    readonly cyberPremium?: Money;
    readonly surplusLinesTax?: Money;
    readonly fireMarshalTax?: Money;
    readonly stampingFee?: Money;
    readonly mississippiWindstormUnderwritingAssociationFee?: Money;
    readonly surplusLinesServiceChargeFee?: Money;
    readonly clearingHouseFee?: Money;
    readonly embrokerAccessFee?: Money;
}

export const ExcessQuoteDetails = {
    ...defineValidator<ExcessQuoteDetails>({
        technologyPremium: Money.schema.required(),
        cyberPremium: Money.schema.optional(),
        surplusLinesTax: Money.schema.optional(),
        fireMarshalTax: Money.schema.optional(),
        stampingFee: Money.schema.optional(),
        mississippiWindstormUnderwritingAssociationFee: Money.schema.optional(),
        surplusLinesServiceChargeFee: Money.schema.optional(),
        clearingHouseFee: Money.schema.optional(),
        embrokerAccessFee: Money.schema.optional(),
    }),
    create(excessQuoteDetails: ExcessQuoteDetails) {
        return ExcessQuoteDetails.validate(excessQuoteDetails);
    },
};
