import React, { forwardRef, Ref, SyntheticEvent, useCallback, useImperativeHandle } from 'react';
import { FormFieldViewProps } from './FormFieldView.view';
import { DataDrivenFormProps, useDataDrivenForm } from '../hooks/useDataDrivenForm';
import { FormView } from './FormView.view';

export interface FormFieldViewDefinition {
    questionProps: FormFieldViewProps['questionProps'];
    inputFieldProps: FormFieldViewProps['inputFieldProps'];
}

export interface DataDrivenFormRefrence {
    setActiveStep: (step: string) => void;
    setUnansweredFormValues: (formValues: { [key: string]: unknown }) => void;
    activePage: string;
}
export const DataDrivenForm = forwardRef(function MyComponen(
    props: DataDrivenFormProps,
    ref: Ref<DataDrivenFormRefrence>,
) {
    const formProps = useDataDrivenForm(props);
    const {
        hasNext,
        hasPrevious,
        currentPage,
        next,
        previous,
        setFieldValue,
        setActiveStep,
        setUnansweredFormValues,
    } = formProps;

    const handleComplexFieldChange = useCallback(
        (questionKey: string, value: unknown) => {
            setFieldValue(questionKey, value);
        },
        [setFieldValue],
    );

    const handleSubmit = (e: SyntheticEvent) => {
        e.preventDefault();
        next();
    };

    const handleBack = () => {
        previous();
    };

    useImperativeHandle(ref, () => ({
        setActiveStep,
        setUnansweredFormValues,
        activePage: currentPage.name,
    }));

    const submitText = hasNext ? 'Continue' : 'Submit';
    return (
        <FormView
            submitText={submitText}
            hasPrevious={hasPrevious}
            currentPageTitle={currentPage.title}
            currentPageQuestions={currentPage.questions}
            handleSubmit={handleSubmit}
            handleBack={handleBack}
            handleComplexFieldChange={handleComplexFieldChange}
        />
    );
});
