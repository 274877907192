import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { Quote } from '../entities/Quote';

export interface ReferQuoteRequest {
    quote: Quote;
}

export interface ReferQuoteResponse {
    quote: Immutable<Quote>;
}

export interface ReferQuote extends UseCase {
    execute(
        request: ReferQuoteRequest,
    ): AsyncResult<ReferQuoteResponse, UnknownEntity | InvalidArgument | OperationFailed>;
}

class ReferQuoteUseCase extends UseCase implements ReferQuote {
    public static type = Symbol('Quote/ReferQuote');

    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    public async execute({
        quote,
    }: ReferQuoteRequest): AsyncResult<
        ReferQuoteResponse,
        UnknownEntity | InvalidArgument | OperationFailed
    > {
        const referResult = quote.refer();

        if (isErr(referResult)) {
            return handleOperationFailure(referResult);
        }

        return Success<ReferQuoteResponse>({
            quote,
        });
    }
}

export const ReferQuote: UseCaseClass<ReferQuote> = ReferQuoteUseCase;
