import React, { useEffect, useMemo } from 'react';
import { Form } from '@embroker/ui-toolkit/v2';
import { ComplexFormFieldViewProps, getFormFieldProps } from '../../types/ComplexFieldTypes';
import {
    CurrencyInput,
    CurrencyInputEvent,
} from '@embroker/shotwell/view/components/CurrencyInput';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { getYear } from 'date-fns';
import { Revenue } from '@embroker/shotwell/core/types/Revenue';
import { Year } from '@embroker/shotwell/core/types/Year';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { ErrorMsgObject } from '../../types/validationObject';

export function assertRevenueType(input: unknown): input is Revenue {
    if (typeof input !== 'object' || input === null) {
        return false;
    }
    return 'grossTotal' in input || 'fiscalYear' in input;
}

export const revenueFieldFormatValidationError = (error: InvalidArgument) => {
    return ErrorMsgObject.getValidationMessage(error);
};

export const getPreviouYear = () => {
    const previousYear = Year.create(getYear(Date.now()) - 1);

    if (isOK(previousYear)) {
        return previousYear.value;
    }
};

export function PreviousYearRevenueView(complexFormFieldViewProps: ComplexFormFieldViewProps) {
    const { questionProps, inputFieldProps, onComplexFieldChange } = complexFormFieldViewProps;
    const { key } = questionProps;
    const formFieldProps = getFormFieldProps(questionProps);
    const previousYear = getPreviouYear();

    const inputProps = useMemo(
        () => ({
            ...inputFieldProps.inputProps,
            placeholder: inputFieldProps.inputProps.placeholder,
            items: questionProps.selectOptions,
        }),
        [inputFieldProps, questionProps],
    );

    useEffect(() => {
        if (inputProps.value && (inputProps.value as Revenue).fiscalYear !== previousYear) {
            // If we pass a value that is not for the previous year, we reset the value
            onComplexFieldChange(key, undefined);
        }
    }, [inputProps, key, previousYear, onComplexFieldChange]);

    const handleChange = ({ target: { value } }: CurrencyInputEvent) => {
        if (!value || !previousYear) {
            return;
        }
        const revenue: Revenue = { fiscalYear: previousYear, grossTotal: value };
        onComplexFieldChange(key, revenue);
    };

    const inputValue = assertRevenueType(inputProps.value)
        ? inputProps.value.grossTotal
        : undefined;

    return (
        <Form.Field {...formFieldProps} messages={inputFieldProps.messages}>
            <CurrencyInput
                label="Prior year annual revenue"
                allowDecimal={false}
                {...{ ...inputProps, value: inputValue }}
                onChange={handleChange}
            />
        </Form.Field>
    );
}
