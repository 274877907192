import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isOK, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { UserOnboardingRepository } from '../repositories/UserOnboardingRepository';
import { OnboardingPrefillQuestionnaireData } from '../types/OnboardingPrefillQuestionnaireData';

export interface GetUserOnboardingQuestionnaireData extends UseCase {
    execute(): AsyncResult<OnboardingPrefillQuestionnaireData, never>;
}

@injectable()
export class GetUserOnboardingQuestionnaireDataUseCase
    extends UseCase
    implements GetUserOnboardingQuestionnaireData
{
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/GetUserOnboardingQuestionnaireData');
    /**
     * Constructor for GetUserOnboardingQuestionnaireData use case class instance
     * @param eventBus An event bus this Use Case will publish events to.
     * @param userOnboardingRepository is repository used to update UserOnboardingQuestionnaireData
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(UserOnboardingRepository)
        private userOnboardingRepository: UserOnboardingRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes GetUserOnboardingQuestionnaireData use case
     * @returns Nothing if execution was successful
     */
    public async execute(): AsyncResult<OnboardingPrefillQuestionnaireData, never> {
        const response = await this.userOnboardingRepository.getUserOnboardingQuestionnaireData();
        if (isOK(response) && response.value) {
            return Success(response.value);
        }
        return Success({});
    }
}

export const GetUserOnboardingQuestionnaireData: UseCaseClass<GetUserOnboardingQuestionnaireData> =
    GetUserOnboardingQuestionnaireDataUseCase;
