import { SortColumn } from '@embroker/shotwell-api/app';
import { Nullable } from '@embroker/shotwell/core/types';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface ListOptions {
    sort_column: Nullable<SortColumn>;
    paging: Nullable<{
        size: number;
        index: number;
    }>;
    filters: Nullable<
        {
            target: string;
            value: string;
        }[]
    >;
}

export interface PageDataRequest {
    column?: string;
    order?: string;
    index: number;
    size: number;
    filters?: Array<{
        target: string;
        value: string;
    }>;
}

export const ListOptions = {
    ...defineValidator<ListOptions>({
        sort_column: Joi.object({
            column: Joi.string().required(),
            order: Joi.string().valid('ascending', 'descending').required(),
        }).allow(null),
        paging: Joi.object({
            size: Joi.number().required(),
            index: Joi.number().required(),
        }).allow(null),
        filters: Joi.array()
            .items(
                Joi.object({
                    target: Joi.string().required(),
                    value: Joi.string().required(),
                }),
            )
            .allow(null),
    }),
    create(request: PageDataRequest) {
        const { index, size, column, order, filters } = request;
        const paging = {
            index,
            size,
        };
        const sortColumn =
            column !== undefined && order !== undefined
                ? {
                      column,
                      order,
                  }
                : null;
        const options = { paging, sort_column: sortColumn, filters: filters ?? null };

        return ListOptions.validate(options);
    },
};
