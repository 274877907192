import { Immutable } from '@embroker/shotwell/core/types';
import { USD } from '@embroker/shotwell/core/types/Money';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { CheckBox, ColumnLayout, Icon, Text } from '@embroker/ui-toolkit/v2';
import React, { Fragment } from 'react';
import { CoverageCatalog } from '../../CoverageCatalog';
import {
    BundleCoverageAppStatusEnum,
    BundleCoverageType,
    BundleQuoteCoverageType,
} from '../../types/BundleQuoteCoverage';

export interface BundleQuoteCoverageHeaderProps {
    coverage: Immutable<BundleQuoteCoverageType>;
    isDirty?: boolean;
    quoteHasExpired: boolean;
    appHasExpired: boolean;
    handleCoverageSelectToggle: (checked: boolean, coverageType: BundleCoverageType) => void;
    isSelected: boolean;
    isReferred: boolean;
    isNotEligible: boolean;
}

export function BundleQuoteCoverageHeader({
    coverage,
    isDirty = false,
    quoteHasExpired,
    appHasExpired,
    handleCoverageSelectToggle,
    isSelected,
    isReferred,
    isNotEligible,
}: BundleQuoteCoverageHeaderProps) {
    const { quote, type, status, coverageMetadata } = coverage;
    const isReferredAwaitingReview = status === BundleCoverageAppStatusEnum.ReferredAwaitingReview;
    const isHigherLimitQuoted = coverage.quote?.isIndication;
    const isUserDeclined = status === BundleCoverageAppStatusEnum.UserDeclined;

    const isCoveragePremiumVisible =
        quote &&
        isSelected &&
        !(
            isNotEligible ||
            isReferred ||
            isReferredAwaitingReview ||
            isDirty ||
            isHigherLimitQuoted ||
            appHasExpired
        );

    const isToggleDisabled =
        isNotEligible ||
        isUserDeclined ||
        isReferredAwaitingReview ||
        !quote ||
        quoteHasExpired ||
        appHasExpired;

    const totalPayable = quote?.totalPayable ?? USD(0);

    function renderCoverageHeaderInfo() {
        const coveragePremiumRange = CoverageCatalog.getPremiumRange(type, quote);
        if (isNotEligible) {
            return (
                <Text color="ui-400" as="span">
                    Ineligible
                </Text>
            );
        }

        if (isDirty) {
            return <Text as="span">Recalculate to get a new price</Text>;
        }

        if (isReferred && coveragePremiumRange) {
            return (
                <Fragment>
                    <MoneyDisplay value={coveragePremiumRange.min} fractionDigits={0} />
                    <span>&nbsp;-&nbsp;</span>
                    <MoneyDisplay value={coveragePremiumRange.max} fractionDigits={0} />
                </Fragment>
            );
        }

        if (isCoveragePremiumVisible) {
            return <MoneyDisplay value={totalPayable} fractionDigits={0} />;
        }

        return null;
    }

    return (
        <ColumnLayout gap="24" split="1" top>
            <Icon
                name={coverageMetadata.icon}
                color={isNotEligible ? 'ui-300' : undefined}
                data-e2e="coverage-icon"
            />
            <Text>
                <ColumnLayout gap="24" as="span">
                    <Text
                        style={isNotEligible ? 'label 1' : 'heading 5'}
                        color={isNotEligible ? 'ui-400' : 'ui-500'}
                        data-e2e="coverage-premium"
                    >
                        {renderCoverageHeaderInfo()}
                    </Text>
                    {!isNotEligible ? (
                        <CheckBox
                            appearance="toggle"
                            checked={isSelected}
                            onChange={(event) =>
                                handleCoverageSelectToggle(event.target.checked, coverage.type)
                            }
                            disabled={isToggleDisabled}
                            data-e2e="coverage-toggle"
                            inline
                        />
                    ) : null}
                </ColumnLayout>
            </Text>
        </ColumnLayout>
    );
}
