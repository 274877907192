import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Money } from '@embroker/shotwell/core/types/Money';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface Limit {
    id: UUID;
    type?: string;
    amount?: Money;
    description?: string;
    deductibleAmount?: Money;
    deductibleCode?: string;
    sirAmount?: Money;
    sirType?: string;
}

export const Limit = {
    ...defineValidator<Limit>(
        Joi.object({
            id: UUID.schema.required(),
            type: Joi.string().optional(),
            amount: Money.schema.optional(),
            description: Joi.string().optional(),
            deductibleAmount: Money.schema.optional(),
            deductibleCode: Joi.string().optional(),
            sirAmount: Money.schema.optional(),
            sirType: Joi.string().optional(),
        }),
    ),
    create(limit: Limit) {
        return Limit.validate(limit);
    },
};
