import { Nullable } from '@embroker/shotwell/core/types';
import { Money, USD } from '@embroker/shotwell/core/types/Money';
import { State } from '@embroker/shotwell/core/types/StateList';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { ESPEndorsementLiabilityCoverageType } from './ESPEndorsementLiabilityCoverageType';

export interface ESPEndorsementPolicyAddressData {
    addressLine1: string;
    addressLine2: string;
    city: string;
    state?: State;
    zipCode?: ZipCode;
}

const ESPEndorsementPolicyAddressData = {
    ...defineValidator<ESPEndorsementPolicyAddressData>({
        addressLine1: Joi.string().invalid('').required(),
        addressLine2: Joi.string().allow('').required(),
        city: Joi.string().invalid('').required(),
        state: State.schema.optional(),
        zipCode: ZipCode.schema.optional(),
    }),
};

export interface ESPEndorsementLiabilityCoverage {
    limit: Money;
    premium: Money;
    retention: Money;
    typeCode: ESPEndorsementLiabilityCoverageType;
}

// api workaround: empty amount not allowed - invalid "1" sentinel value
const ESPEndorsementLiabilityCoverage = {
    ...defineValidator<ESPEndorsementLiabilityCoverage>({
        limit: Money.schema.invalid(USD(-1)).required(),
        premium: Money.schema.invalid(USD(-1)).required(),
        retention: Money.schema.invalid(USD(-1)).required(),
        typeCode: Joi.string()
            .valid(
                'LiabilityCoverageCodeListIndemnifiableDirectorsAndOfficersLiability',
                'LiabilityCoverageCodeListEmploymentPracticesLiability',
                'LiabilityCoverageCodeListFiduciaryLiability',
                'LiabilityCoverageCodeListTechnologyAndMediaErrorsAndOmissions',
                'LiabilityCoverageCodeListCyberSplit',
            )
            .required(),
    }),
};

export interface ESPEndorsementPolicy {
    applicationId: UUID;
    effectivePeriodStart: Date;
    effectivePeriodEnd: Date;
    namedInsured: string;
    addressData: ESPEndorsementPolicyAddressData;
    availableLiabilities: Array<ESPEndorsementLiabilityCoverage>;
    submittedAt: Nullable<string>;
    isEPLIEligible: boolean;
    isDNOEligible: boolean;
    isFiduciaryEligible: boolean;
    inRunoff: boolean;
    isReferred?: boolean;
    hasRenewalApplication?: boolean;
}

// api workaround: empty namedInsured invalid
export const ESPEndorsementPolicy = {
    ...defineValidator<ESPEndorsementPolicy>({
        applicationId: UUID.schema,
        effectivePeriodStart: Joi.date().required(),
        effectivePeriodEnd: Joi.date().required(),
        namedInsured: Joi.string().invalid('').required(),
        addressData: ESPEndorsementPolicyAddressData.schema,
        availableLiabilities: Joi.array().items(ESPEndorsementLiabilityCoverage.schema),
        submittedAt: Joi.string().optional(),
        isEPLIEligible: Joi.boolean().required(),
        isDNOEligible: Joi.boolean().required(),
        isFiduciaryEligible: Joi.boolean().required(),
        inRunoff: Joi.boolean().required(),
        isReferred: Joi.boolean().optional(),
        hasRenewalApplication: Joi.boolean().optional(),
    }),
    create(espEndorsementPolicy: ESPEndorsementPolicy) {
        return ESPEndorsementPolicy.validate(espEndorsementPolicy);
    },
};
