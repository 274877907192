import { API, InsuranceApplication } from '@embroker/shotwell-api/app';
import { injectable } from '@embroker/shotwell/core/di';
import { OperationFailed } from '@embroker/shotwell/core/Error';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import {
    SupplementalQuestionnaireRepository,
    SaveSupplementalQuestionnaireRequest,
    SubmitSupplementalQuestionnaireRequest,
} from './index';
import { QuoteList } from '../../../../quote/types/QuoteList';
import { SupplementalAppInfo } from '../../types/SupplementalAppInfo';
import { isBefore, isValid } from 'date-fns';
import { QuotingEngineESP } from '../../../../shopping/types/enums';

interface FundingRoundsRow {
    funding_rounds_row_date?: string;
    funding_rounds_row_money_raised?: number;
    funding_rounds_row_lead_investor?: string;
}

interface FundingRounds {
    funding_rounds_row?: Array<FundingRoundsRow>;
}

interface QuestionnaireData {
    esp_existing_dno_limit: number;
    esp_existing_cyber_limit: number;
    total_funding_amount?: number;
    funding_rounds?: FundingRounds;
    raised_venture_funding?: boolean;
}

@injectable()
export class APISupplementalQuestionnaireRepository implements SupplementalQuestionnaireRepository {
    public async getSupplementalAppInfo(
        applicationId: UUID,
    ): AsyncResult<SupplementalAppInfo, OperationFailed> {
        const applicationResponse = await API.request('shopping/application', {
            id: applicationId,
        });
        if (isErr(applicationResponse)) {
            return Failure(
                OperationFailed({
                    message: "Can't get application",
                    errors: applicationResponse.errors,
                }),
            );
        }
        const apiApplication = applicationResponse.value as InsuranceApplication;
        const lastApiQuote = QuoteList.getLastQuote(apiApplication.quote_list);
        let questionnaireData: QuestionnaireData;
        try {
            questionnaireData = JSON.parse(
                apiApplication.questionnaire_data ?? '{}',
                function (key, value) {
                    if (key === 'esp_existing_dno_info.limit') {
                        this.esp_existing_dno_limit = value;
                        return;
                    }
                    if (key === 'esp_existing_cyber_info.limit') {
                        this.esp_existing_cyber_limit = value;
                        return;
                    }
                    return value;
                },
            );
        } catch (error) {
            return Failure(OperationFailed({ message: 'Failed parse questionnaire data' }));
        }

        const isTechUnfundedApp =
            apiApplication.quoting_engine === QuotingEngineESP &&
            questionnaireData.raised_venture_funding === false;

        let supplementalQuestionnaire;
        try {
            supplementalQuestionnaire = JSON.parse(
                apiApplication.supplemental_questionnaire_data ?? '{}',
            );
        } catch (error) {
            return Failure(
                OperationFailed({ message: 'Failed parse supplemental questionnaire data' }),
            );
        }
        return Success({
            supplementalQuestionnaire,
            dnoLimit: lastApiQuote?.options.esp?.directors_and_officers?.limit ?? 0,
            eoLimit:
                lastApiQuote?.options.esp?.errors_and_omissions?.limit ??
                lastApiQuote?.options.esp?.technology?.limit ??
                0,
            currentDnoLimit: questionnaireData.esp_existing_dno_limit ?? 0,
            currentEoLimit: questionnaireData.esp_existing_cyber_limit ?? 0,
            isRenewal: apiApplication.renewed_policy_id_list.length > 0,
            totalFundingAmount: questionnaireData.total_funding_amount,
            effectiveDate: lastApiQuote?.options.esp?.effective_period_start ?? undefined,
            latestFundingDate: getLatestFundingDate(
                questionnaireData.funding_rounds?.funding_rounds_row,
            ),
            isTechUnfundedApp: isTechUnfundedApp,
        });
    }

    async saveSupplementalQuestionnaire(
        request: SaveSupplementalQuestionnaireRequest,
    ): AsyncResult<void, OperationFailed> {
        const saveQuestionnaireResponse = await API.request(
            'shopping/save_supplemental_questionnaire',
            {
                application_id: request.applicationId,
                questionnaire_data: request.questionnaireData,
            },
        );
        if (isErr(saveQuestionnaireResponse)) {
            return Failure(
                OperationFailed({
                    message: 'Failed to save supplemental questionnaire.',
                    errors: saveQuestionnaireResponse.errors,
                }),
            );
        }

        return Success();
    }

    async submitSupplementalQuestionnaire(
        request: SubmitSupplementalQuestionnaireRequest,
    ): AsyncResult<void, OperationFailed> {
        const submitQuestionnaireResponse = await API.request(
            'shopping/submit_supplemental_questionnaire',
            {
                application_id: request.applicationId,
                questionnaire_data: request.questionnaireData,
            },
        );
        if (isErr(submitQuestionnaireResponse)) {
            return Failure(
                OperationFailed({
                    message: 'Failed to submit supplemental questionnaire.',
                    errors: submitQuestionnaireResponse.errors,
                }),
            );
        }

        return Success();
    }

    public async requestHigherLimits(applicationId: UUID): AsyncResult<void, OperationFailed> {
        const requestHigherLimitsResponse = await API.request('shopping/request_higher_limits', {
            application_id: applicationId,
            is_submit: true,
        });

        if (isErr(requestHigherLimitsResponse)) {
            return Failure(
                OperationFailed({
                    message: 'Failed request higher limits.',
                    errors: requestHigherLimitsResponse.errors,
                }),
            );
        }

        return Success();
    }
}

const getLatestFundingDate = (funding_rounds_row?: Array<FundingRoundsRow>): Date | undefined => {
    return funding_rounds_row
        ?.filter(
            (row) => row.funding_rounds_row_date && isValid(new Date(row.funding_rounds_row_date)),
        )
        .map((row) => {
            return new Date(row.funding_rounds_row_date as string);
        })
        .sort((a: Date, b: Date) => (isBefore(a, b) ? 1 : -1))[0];
};
