import { OracleAnswerTypeDefinition } from '../OracleAnswerType';
import { Joi } from '@embroker/shotwell/core/validation/schema';

export const serializeBoolean = (formData: unknown): boolean | undefined => {
    return Boolean(formData);
};

export const deserializeBooleanAnswers = (currentValue: any): boolean[] | undefined => {
    if (!Array.isArray(currentValue)) {
        return undefined;
    }
    return currentValue.map((current: any) => Boolean(current));
};

export const BooleanDefinition: OracleAnswerTypeDefinition = {
    answerKeyType: 'boolean',
    schemaFunctions: {
        validator: Joi.boolean(),
        serializeAnswer: serializeBoolean,
        deserializeAnswer: deserializeBooleanAnswers,
    },
};
