import { QuoteOptionsExtensionRequestStatus } from '@embroker/shotwell-api/enums';
import { valueObject } from '@embroker/shotwell/core/types/ValueObject';
import {
    defineValidator,
    Joi,
    Schema,
    TypeChecker,
    Validator,
} from '@embroker/shotwell/core/validation/schema';
import { CoverageType, ESPCoverageTypes } from './CoverageRestriction';

export interface QuoteOptionsExtensionRequest {
    requestedAt: Date;
    status: string;
    coverageQuoteOptionsExtensionMap: Record<CoverageType, number>;
}

export interface QuoteOptionsExtensionRequestValidator {
    readonly schema: Schema.ObjectSchema<QuoteOptionsExtensionRequest>;
    readonly check: TypeChecker<QuoteOptionsExtensionRequest>;
    readonly validate: Validator<QuoteOptionsExtensionRequest>;
}

export const QuoteOptionsExtensionRequest = {
    ...defineValidator<QuoteOptionsExtensionRequest>({
        requestedAt: Joi.date().required(),
        status: Joi.string()
            .required()
            .valid(...QuoteOptionsExtensionRequestStatus),
        coverageQuoteOptionsExtensionMap: Joi.object()
            .pattern(Joi.string().valid(...ESPCoverageTypes), [
                Joi.number().required(),
                Joi.object({
                    per_claim_limit: Joi.number().required(),
                    aggregate_limit: Joi.number().required(),
                }).required(),
            ])
            .required(),
    }),
    create(restriction: QuoteOptionsExtensionRequest) {
        return QuoteOptionsExtensionRequest.validate(restriction);
    },
};

export type QuoteOptionsExtensionRequestList = Array<QuoteOptionsExtensionRequest>;

export const QuoteOptionsExtensionRequestList = valueObject({
    ...defineValidator<QuoteOptionsExtensionRequestList>(
        Joi.array().items(QuoteOptionsExtensionRequest.schema).min(0),
    ),
});
