import { Money } from '@embroker/shotwell/core/types/Money';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import React from 'react';
import { LPLQuote } from '../../../../quote/lpl/entities/LPLQuote';
import { ModalCoverageSummaryProp } from '../../coverageDefinition';
import { CoverageModalSummary } from '../../CoverageSummary.view';

export function LPLModalCoverageSummary({ quote }: ModalCoverageSummaryProp<LPLQuote>) {
    return (
        <CoverageModalSummary
            title="Lawyers Professional Liability"
            summaryList={[
                {
                    label: 'PER CLAIM LIMIT',
                    value: (
                        <MoneyDisplay
                            value={Money.tryFromFloat(quote.options.perClaimLimit)}
                            fractionDigits={0}
                        />
                    ),
                },
                {
                    label: 'AGGREGATE LIMIT',
                    value: (
                        <MoneyDisplay
                            value={Money.tryFromFloat(quote.options.aggregateLimit)}
                            fractionDigits={0}
                        />
                    ),
                },
                {
                    label: 'PREMIUM',
                    value: <MoneyDisplay value={quote.totalPremium} />,
                },
            ]}
        />
    );
}
