import {
    Accordion,
    Button,
    InsetBox,
    Modal,
    ModalActions,
    ModalState,
    StackLayout,
    Text,
} from '@embroker/ui-toolkit/v2';
import React from 'react';

interface FAQModalContentProps {
    modal: ModalState & ModalActions;
    isClearBlueToEverspanSwitchActive: boolean;
}

export const FAQModalContent = React.memo(function FAQModalContent({
    modal,
    isClearBlueToEverspanSwitchActive,
}: FAQModalContentProps) {
    return (
        <Modal {...modal} size="large">
            <StackLayout gap="24">
                <Text style="heading 3">FAQs</Text>
                <Text>Cover an even broader range of risk with best-in-market coverage.</Text>
                <InsetBox gap="16">
                    <Accordion.Item
                        title="What are the benefits of Embroker's Startup Package?"
                        state="open"
                    >
                        Our goal is simple - we intend to completely transform the process and
                        experience of buying insurance, with instant underwriting decisions, claims
                        support, and custom built coverage.When we created the Embroker Startup
                        Package, we didn’t aim for incremental improvements, we built something that
                        was so much better than existing alternatives that it marks the new standard
                        in insurance for startups. Ultimately that means our customers will save
                        time and money - and ensure they get the right coverage on critical
                        policies. For more detailed information on how our coverage compares with
                        other policies, see Coverage Comparison tab.
                    </Accordion.Item>
                    <Accordion.Item title="What lines of insurance are offered in the Embroker Startup Package?">
                        The Embroker Startup Package offers several of the most critical coverages
                        for startups -- Directors and Officers insurance (D&O), Employment Practices
                        Liability insurance (EPLI), and Fiduciary Liability insurance. Directors &
                        Officers Liability - D&O insurance provides coverage to directors, officers,
                        or the company itself against lawsuits alleging errors, omissions, or breach
                        of fiduciary duties. Employment Practices Liability insurance - EPLI
                        provides coverage for claims made by employees alleging discrimination,
                        wrongful termination, harassment and other employment related issues.
                        Fiduciary Liability - Covers claims arising out of the mismanagement or
                        administration of employee retirement / 401K plans.
                    </Accordion.Item>
                    <Accordion.Item title="Is Embroker an insurance provider?">
                        Embroker is not an insurance carrier. We act as an fully licensed,
                        independent insurance agency and we are committed to making it easier to
                        insure your business intelligently. All of our insurance partners maintain
                        strong A.M. Best ratings, which is the leading full-service credit rating
                        agency for the insurance industry.
                    </Accordion.Item>
                    {isClearBlueToEverspanSwitchActive ? (
                        <Accordion.Item title="Who is the insurance carrier for the Embroker Startup Package?">
                            The Embroker Startup Package is fully backed by a coalition of A-rated
                            reinsurers. The policy itself is underwritten by Everspan Group, a
                            leading property and casualty insurance company rated ‘excellent’ by AM
                            Best.
                        </Accordion.Item>
                    ) : (
                        <Accordion.Item title="Who is the insurance carrier for the Embroker Startup Package?">
                            The Embroker Startup Package is fully backed by a coalition of A-rated
                            reinsurers. The policy itself is underwritten by Clear Blue Insurance
                            Group, a leading property and casualty insurance company rated
                            ‘excellent’ by AM Best. For more information on Clear Blue can be
                            accessed{' '}
                            <a
                                href="http://www.clearblueinsurancegroup.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                here
                            </a>
                        </Accordion.Item>
                    )}
                    <Accordion.Item title='What is a "retention"'>
                        Every policy in the Embroker Startup Package has a ‘retention’. This is the
                        specific amount that must be paid before the insurance policy will respond
                        to a loss. It is similar to a ‘deductible’, although paying the retention
                        does not reduce the insurance available under the policy. For example, our
                        hypothetical customer Shotwell Inc has a $1,000,000 D&O policy with a
                        $25,000 retention. If Shotwell Inc experiences a $100,000 covered claim,
                        they will be responsible for the first $25,000 in loss, and the insurance
                        policy will pay the remaining $75,000. For the remainder of the policy
                        period, Shotwell Inc will have $925,000 of insurance limits remaining. With
                        a ‘deductible’, Shotwell Inc would actually only have $900,000 of insurance
                        limits remaining under their insurance policy.
                    </Accordion.Item>
                    <Accordion.Item title="When will I receive my policy?">
                        If you’ve bought a D&O policy before, you probably know that it often takes
                        between 30 - 60 days for you to receive a copy of your policy. You receive a
                        ‘quote’ and ‘binder’, but the actual policy document (the one you’ve paid
                        for!) takes weeks to be delivered. Embroker delivers your insurance policies
                        instantly. After purchase, you will find a downloadable copy of your policy
                        on our{' '}
                        <a
                            href="https://www.embroker.com/blog/digital-insurance-policy-vault-risk-review/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Risk Management Platform
                        </a>
                        . You will also find a digital policy card with pertinent details about your
                        policy, including the policy number, insurance carrier, limits of liability
                        and expiration dates.
                    </Accordion.Item>
                    <Accordion.Item title="What are surplus lines taxes and fees?">
                        On our digital quote screen, you will see surplus lines taxes and fees
                        included on all policies. Surplus lines taxes and fees are charged on all
                        surplus line insurance transactions. We are required by law to remit this
                        tax to the specific state on all insurance contracts written. The rate and
                        calculation of these taxes and fees vary from state to state. Embroker does
                        not retain any of these taxes and fees.
                    </Accordion.Item>
                    <Accordion.Item title="How can I reach Embroker with questions I may have?">
                        We are available to answer any questions you may have! Feel free to use the
                        chat feature located in the bottom right corner of every screen to speak
                        with a licensed insurance expert. You can also email success@embroker.com
                        and an Embroker Team Member will quickly follow-up. Or call 1 844 436 2765
                        to speak to a licensed insurance representative. We look forward to hearing
                        from you!
                    </Accordion.Item>
                </InsetBox>
                <Button onClick={modal.hide}>Got it</Button>
            </StackLayout>
        </Modal>
    );
});
