import React from 'react';
import { Form } from '@embroker/ui-toolkit/v2';
import {
    ComplexFormFieldViewProps,
    getFormFieldProps,
    getInputProps,
} from '../../types/ComplexFieldTypes';

import { Naics } from '@app/industries/view/components/Naics';

export function assertInputValueAsString(input: unknown): input is string {
    return typeof input === 'string';
}

export function NaicsCodeFieldView(complexFormFieldViewProps: ComplexFormFieldViewProps) {
    const { questionProps, inputFieldProps, onComplexFieldChange } = complexFormFieldViewProps;
    const { key } = questionProps;
    const formFieldProps = getFormFieldProps(questionProps);
    const inputProps = getInputProps(questionProps, inputFieldProps);
    const inputValue = assertInputValueAsString(inputProps.value) ? inputProps.value : undefined;

    return (
        <Form.Field {...formFieldProps}>
            <Naics
                {...inputProps}
                initialValue={inputValue ?? undefined}
                onChange={(naicsResult) => onComplexFieldChange(key, naicsResult.value)}
            />
        </Form.Field>
    );
}
