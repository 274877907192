import { API, InsuranceApplication } from '@embroker/shotwell-api/app';
import { API as APIv2 } from '@embroker/shotwell-api/v2/app';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { injectable } from '@embroker/shotwell/core/di';
import {
    AsyncResult,
    Failure,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import {
    BOPHartfordQuote,
    PurchaseResponse,
    HartfordUserInfo,
} from '../../entities/BOPHartfordQuote';
import { BOPHartfordQuoteRepository } from './index';
import * as APIType from '@embroker/shotwell-api/app';
import { QuoteIdMap, QuoteList } from '@app/quote/types/QuoteList';
import { ZipCode } from '@embroker/shotwell/core/types/ZipCode';
import { State } from '@embroker/shotwell/core/types/StateList';
import { QuotingEngineHartfordBOP } from '@app/shopping/types/enums';

@injectable()
export class APIBOPHartfordQuoteRepository implements BOPHartfordQuoteRepository {
    async getLatestQuote(
        applicationId: UUID,
    ): AsyncResult<BOPHartfordQuote, InvalidArgument | OperationFailed> {
        const applicationResponse = await API.request('shopping/application', {
            id: applicationId,
        });

        if (isErr(applicationResponse)) {
            return handleOperationFailure(applicationResponse);
        }

        return await toHartfordQuote(
            applicationResponse.value as InsuranceApplication,
            applicationId,
        );
    }

    async reQuote(
        applicationId: UUID,
        effectiveDate: Date,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed> {
        const reQuoteResponse = await API.request('shopping/create_quote_task', {
            application_id: applicationId,
            quote_options: {
                hartford_bop: {
                    effective_date: effectiveDate,
                    is_deselected: false,
                },
            },
        });
        if (isErr(reQuoteResponse)) {
            return handleOperationFailure(reQuoteResponse);
        }

        const value = reQuoteResponse.value as APIType.ShoppingCreateQuoteTaskResponse;

        return Success(value.task_id);
    }

    async purchaseHartfordBOP(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<PurchaseResponse, InvalidArgument | OperationFailed> {
        const quoteIdMap: QuoteIdMap = { [QuotingEngineHartfordBOP]: quoteId };
        const purchaseResponse = await API.request('shopping/purchase', {
            id: applicationId,
            quote_id_map: quoteIdMap,
        });
        if (isErr(purchaseResponse)) {
            return handleOperationFailure(purchaseResponse);
        }

        const { policyId: policyId, policy_issuance_task_id: policyDoneTaskId } =
            purchaseResponse.value;

        return Success({
            policyId: policyId,
            policyDoneTaskId: policyDoneTaskId,
        });
    }

    public async createQuoteSummaryAsyncTask(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<UUID, InvalidArgument | OperationFailed> {
        const createQuoteSummaryTaskResult = await API.request(
            'shopping/create_quote_summary_task',
            {
                application_id: applicationId,
                quote_id: quoteId,
            },
        );
        if (isErr(createQuoteSummaryTaskResult)) {
            return handleOperationFailure(createQuoteSummaryTaskResult);
        }
        return Success(createQuoteSummaryTaskResult.value.task_id);
    }

    async getPaymentUrl(path: string): AsyncResult<string, InvalidArgument | OperationFailed> {
        const url = path as `hartford/${string}/payments`;
        const response = await APIv2.get(url);

        if (isErr(response)) {
            return handleOperationFailure(response);
        }

        return Success(response.value.url);
    }
}

async function toHartfordQuote(
    apiInsuranceApplication: APIType.InsuranceApplication,
    applicationId: UUID,
): AsyncResult<BOPHartfordQuote, InvalidArgument | OperationFailed> {
    const quote = QuoteList.getLastQuote(apiInsuranceApplication.quote_list);

    if (quote === null) {
        return Failure(OperationFailed({ message: 'Quote not found in the application object' }));
    }

    const quoteOptions = quote.options.hartford_bop ?? ({} as APIType.HartfordBopQuoteOptions);
    const quoteDetails = quote.details.hartford_bop ?? ({} as APIType.HartfordBopQuoteDetails);

    let userInfo: HartfordUserInfo;
    const questionnaireData = apiInsuranceApplication.questionnaire_data;
    if (questionnaireData === null) {
        return Failure(
            InvalidArgument({ argument: 'questionnaire_data', value: questionnaireData }),
        );
    }
    try {
        const questionnaireDataParsed = JSON.parse(questionnaireData) as QuestionnaireData;
        userInfo = getUserInfoFromQuestionnaire(questionnaireDataParsed);
    } catch (e) {
        return Failure(OperationFailed({ message: 'Failed parsing questionnaire data' }));
    }

    const result = await BOPHartfordQuote.create({
        isIndication: quote.is_indication,
        id: quote.id,
        totalPremium: quote.total_premium,
        annualTechnologyFee: quote.annual_technology_fee,
        totalPayable: quote.total_payable,
        applicationId: applicationId,
        status: 'draft',
        options: {
            effectiveDate: new Date(quoteOptions.effective_date),
        },
        userInfo: userInfo,
        fileKey: quote.file_key || undefined,
        details: {
            paymentUrl: quoteDetails.payment_url,
            coverages: quoteDetails.coverages.map((coverage) => ({
                code: coverage.code,
                description: coverage.description,
                limits: coverage.limits.map((limit) => ({
                    code: limit.code,
                    description: limit.description,
                    limit: limit.limit,
                })),
            })),
        },
    });

    if (isErr(result)) {
        return handleOperationFailure(result);
    }

    const signResult = result.value.sign();
    if (isErr(signResult)) {
        return handleOperationFailure(signResult);
    }

    return result;
}

interface QuestionnaireData {
    company_name: string;
    first_name: string;
    last_name: string;
    title: string;
    state: State;
    zip: string;
    city: string;
    mailing_address: string;
    suite: string;
}

function getUserInfoFromQuestionnaire(questionnaire: QuestionnaireData): HartfordUserInfo {
    return {
        company: questionnaire?.company_name,
        fullName: `${questionnaire?.first_name} ${questionnaire?.last_name}`,
        title: questionnaire?.title,
        usaState: questionnaire?.state,
        address: {
            state: questionnaire?.state,
            zip: questionnaire?.zip as ZipCode,
            city: questionnaire?.city,
            addressLine1: questionnaire?.mailing_address,
            addressLine2: questionnaire?.suite,
        },
    };
}
