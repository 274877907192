import { defineEntityValidator, entity } from '@embroker/shotwell/core/entity/Entity';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { SKU } from '../../../analytics/types/SKU';
import { commonQuoteProps, Quote } from '../../entities/Quote';
import { CNAAppInfo } from '../types/CNAAppInfo';
import { CNAQuoteOptions } from '../types/CNAQuoteOptions';

/**
 * CNA Quote purchased event interface
 * NOTE: This would be part of entity if CNAQuote was entity but since it is a type
 * we are placing this here. Consider rewriting this as entity since every CNAQuote is uniquely
 * differentiated
 */
export interface CNAQuotePurchased extends DomainEvent {
    readonly origin: 'CNAQuote';
    readonly name: 'Purchased';
    readonly totalPremium: Nullable<Money>;
    /**
     * Returns true if purchase is OFAC rejected
     */
    readonly isOFACRejected: boolean;
    readonly isRenewal: boolean;
    readonly details: CNAAppInfo;
    readonly applicationId: UUID;
    sku?: SKU;
}

export interface CNAQuote extends Quote {
    readonly options: CNAQuoteOptions;
    readonly isQuoteReferred: boolean;
    readonly appInfo: CNAAppInfo;
    readonly isRenewal: boolean;
}

export const CNAQuote = entity<CNAQuote, Quote>({
    validator: defineEntityValidator<CNAQuote>({
        ...commonQuoteProps,
        details: Joi.forbidden(),
        options: CNAQuoteOptions.schema.required(),
        appInfo: CNAAppInfo.schema.required(),
        isQuoteReferred: Joi.boolean().required(),
        isRenewal: Joi.boolean().required(),
    }),
    inherits: Quote,
});
