import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { QuoteCommonRepository } from '../repositories';

export interface CancelApplicationRequest {
    applicationId: UUID;
}

export interface CancelApplication extends UseCase {
    execute(
        request: CancelApplicationRequest,
    ): AsyncResult<void, InvalidArgument | OperationFailed>;
}

class CancelApplicationUseCase extends UseCase implements CancelApplication {
    public static type = Symbol('Quote/CancelApplication');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(QuoteCommonRepository) private quoteCommonRepository: QuoteCommonRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
    }: CancelApplicationRequest): AsyncResult<void, InvalidArgument | OperationFailed> {
        const result = await this.quoteCommonRepository.cancelApplication(applicationId);

        if (isErr(result)) {
            return result;
        }

        return Success();
    }
}

export const CancelApplication: UseCaseClass<CancelApplication> = CancelApplicationUseCase;
