import { API } from '@embroker/shotwell-api/app';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { injectable } from '@embroker/shotwell/core/di';
import {
    AsyncResult,
    Success,
    handleOperationFailure,
    isErr,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { EndorsementIntakeRepository, RequestNameOrAddressChangeEndorsementRequest } from './index';

const ENDORSEMENT_TYPE_NEW = 'new';

@injectable()
export class APIEndorsementIntakeRepository implements EndorsementIntakeRepository {
    async requestNameOrAddressChangeEndorsement({
        policyId,
        insuredName,
        reasonForChange,
        reasonForChangeAdditionalDetails,
        address,
        city,
        state,
        zip,
        effectiveDate,
    }: RequestNameOrAddressChangeEndorsementRequest): AsyncResult<
        void,
        InvalidArgument | OperationFailed
    > {
        const requestNameOrAddressChangeEndorsementResponse = await API.request(
            'endorsement/request_name_or_address_endorsement',
            {
                change_type: ENDORSEMENT_TYPE_NEW,
                policy_id: policyId,
                insured_name: insuredName,
                reason: reasonForChange,
                reason_additional_description: reasonForChangeAdditionalDetails,
                address: address,
                city: city,
                state: state,
                zip: zip,
                effective_date: effectiveDate,
            },
        );
        if (isErr(requestNameOrAddressChangeEndorsementResponse)) {
            return handleOperationFailure(requestNameOrAddressChangeEndorsementResponse);
        }

        return Success();
    }

    async createOtherEndorsement(
        effectiveDate: Date,
        requestedChangeMessage: string,
        policyId: UUID,
    ): AsyncResult<void, InvalidArgument | OperationFailed> {
        const createOtherEndorsementResponse = await API.request(
            'endorsement/create_other_endorsement',
            {
                change_type: ENDORSEMENT_TYPE_NEW,
                effective_date: effectiveDate,
                requested_change_message: requestedChangeMessage,
                policy_id: policyId,
            },
        );
        if (isErr(createOtherEndorsementResponse)) {
            return handleOperationFailure(createOtherEndorsementResponse);
        }

        return Success();
    }
}
