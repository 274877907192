import { errorCodes } from '@embroker/shotwell/core/Error';
import { ErrorObject } from '@embroker/shotwell/core/types/Result';
import { Immutable } from '@embroker/shotwell/core/types';
import { UUID } from '@embroker/shotwell/core/types/UUID';

export const ErrorCode = errorCodes({
    PurchaseQuoteNotConsistent: 0x2200,
    ApplicationNotFound: 0x2201,
    Unknown: 0x2202,
});

export type PurchaseQuoteNotConsistent = ErrorObject<
    typeof ErrorCode.PurchaseQuoteNotConsistent,
    {}
>;

export function PurchaseQuoteNotConsistent(): Immutable<PurchaseQuoteNotConsistent> {
    return {
        name: 'PurchaseQuoteNotConsistent',
        code: ErrorCode.PurchaseQuoteNotConsistent,
        message:
            'We’re finding the best options for your request and will send you a revised proposal shortly.',
        details: {},
    };
}

export type ApplicationNotFound = ErrorObject<typeof ErrorCode.ApplicationNotFound, {}>;

export function ApplicationNotFound(applicationId: UUID): Immutable<ApplicationNotFound> {
    return {
        name: 'ApplicationNotFound',
        code: ErrorCode.ApplicationNotFound,
        message: 'Application not found',
        details: { applicationId },
    };
}

export type Unknown = ErrorObject<typeof ErrorCode.Unknown, {}>;

export function Unknown(): Immutable<Unknown> {
    return {
        name: 'Unknown',
        code: ErrorCode.Unknown,
        message: 'Unknown error',
        details: {},
    };
}
