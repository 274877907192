import { Joi } from '@embroker/shotwell/core/validation/schema';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    BoxLayout,
    Button,
    ColumnLayout,
    Form,
    Modal,
    ModalActions,
    ModalState,
    ScrollBox,
    SelectChangeEvent,
    Spinner,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-navi';
import { PremiumFinanceUpdateBillingInfoACH } from '../../../../useCases/PremiumFinanceUpdateBillingInfoACH';
import { errorsStyle } from '../../modals/PayWithCCModal';

interface BillingInfoACHModalInput {
    quoteNumber: number;
    modal: ModalState & ModalActions;
    hideModal(): void;
    showLeavingToFIFModal?: () => void;
    onSignedDateTimeTermsAndConditionsChange(dateTime: Date): void;
}

export interface BillingInfoACHInput {
    readonly routingNumber: string;
    readonly accountNumber: string;
    readonly accountType: string;
    readonly autoChargeACH: boolean;
    readonly quoteNumber: number;
}

const BillingInfoACHForm = createForm<BillingInfoACHInput>({
    fields: {
        quoteNumber: {
            type: 'hidden',
            validator: Joi.number().required(),
        },
        routingNumber: {
            type: 'text',
            validator: Joi.string().required().length(9).replace(' ', ''),
            formatValidationError: (error) => {
                switch (error.details.validator) {
                    case 'string.length':
                        return 'Routing number must have 9 digits.';
                    case 'any.required':
                        return 'Routing number is required.';
                    case 'string.empty':
                        return 'Routing number is empty.';
                    default:
                        return error.message;
                }
            },
        },
        accountNumber: {
            type: 'text',
            validator: Joi.string().required(),
            formatValidationError: (error) => {
                switch (error.details.validator) {
                    case 'any.required':
                        return 'Account number is required.';
                    case 'string.empty':
                        return 'Account number is empty.';
                    default:
                        return error.message;
                }
            },
        },
        accountType: {
            type: 'select',
            validator: Joi.string().required(),
            formatValidationError: (error) => {
                switch (error.details.validator) {
                    case 'any.required':
                        return 'Account type filed is required.';
                    case 'string.empty':
                        return 'Account type filed is empty.';
                    default:
                        return error.message;
                }
            },
        },
        autoChargeACH: {
            type: 'checkbox',
            validator: Joi.boolean().required().disallow(false),
            formatValidationError: (error) => {
                switch (error.details.validator) {
                    case 'any.required':
                        return 'You need to accept Terms & Conditions in order to proceed.';
                    case 'any.invalid':
                        return 'You need to accept Terms & Conditions in order to proceed.';
                    default:
                        return error.message;
                }
            },
        },
    },
    useCase: PremiumFinanceUpdateBillingInfoACH,
});

const CommercialACHDebitAuthorizationTermsAndConditions =
    'https://embroker-public.s3.amazonaws.com/PremiumFinance/Commercial+ACH+Debit+Authorization+Terms+And+Conditions.pdf';

export function BillingInfoACHModal({
    modal,
    hideModal,
    quoteNumber,
    showLeavingToFIFModal,
    onSignedDateTimeTermsAndConditionsChange,
}: BillingInfoACHModalInput) {
    const { submit, fields, status, messages, result, setValue, value, reset } = useForm(
        BillingInfoACHForm,
        {
            quoteNumber: quoteNumber,
            autoChargeACH: false,
        },
    );
    const [displayError, setDisplayError] = useState(false);
    const [modalClosed, setModalClosed] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (status == 'pristine' || status == 'submitting') {
            setModalClosed(false);
        }
        if (status == 'submitting') {
            setIsSubmitting(true);
            return;
        }
        setIsSubmitting(false);
        if (status == 'invalid') {
            if (messages.length > 0) {
                return setDisplayError(true);
            }
        }
        setDisplayError(false);
        if (
            result != undefined &&
            result.achSignatureDateTime != undefined &&
            status == 'submitted' &&
            !modalClosed
        ) {
            hideModal();
            setModalClosed(true);
            onSignedDateTimeTermsAndConditionsChange(result.achSignatureDateTime);
            if (showLeavingToFIFModal !== undefined) {
                showLeavingToFIFModal();
            }
            return;
        }
    }, [
        status,
        result,
        hideModal,
        reset,
        messages,
        fields,
        modalClosed,
        showLeavingToFIFModal,
        onSignedDateTimeTermsAndConditionsChange,
    ]);

    function handleRoutingNumberChange(event: { target: { value: string } }) {
        setValue({
            ...value,
            routingNumber: event.target.value,
        });
    }

    function handleAccountNumberChange(event: { target: { value: string } }) {
        setValue({
            ...value,
            accountNumber: event.target.value,
        });
    }

    const handleAccountTypeChange = (event: SelectChangeEvent<string, false>) => {
        const newAccountTypeValue = event.target.value;
        if (newAccountTypeValue == undefined) {
            return;
        }
        setValue({
            ...value,
            accountType: newAccountTypeValue,
        });
    };

    function handleBillingMethodChange(event: React.ChangeEvent<HTMLInputElement>) {
        setValue({
            ...value,
            autoChargeACH: event.target.checked,
        });
    }

    return (
        <Modal {...modal} hide={hideModal}>
            {isSubmitting && <Spinner appearance="transparent" />}
            <Form onSubmit={submit}>
                <ScrollBox>
                    <StackLayout gap="16">
                        <Text data-e2e="pay-with-ach-modal-header" style="heading 3">
                            ACH Info
                        </Text>
                        <Text data-e2e="pay-ach-routing-number-label">Bank routing number</Text>
                        <Form.Field
                            data-e2e="pay-ach-routing-number-input"
                            inputProps={{
                                ...fields.routingNumber.props,
                                onChange: handleRoutingNumberChange,
                            }}
                            type="text"
                            messages={fields.routingNumber.messages}
                        />
                        <Text data-e2e="pay-ach-account-number-label">Bank account number</Text>
                        <Form.Field
                            data-e2e="pay-ach-account-number-input"
                            inputProps={{
                                ...fields.accountNumber.props,
                                onChange: handleAccountNumberChange,
                            }}
                            type="text"
                            messages={fields.accountNumber.messages}
                        />
                        <Text data-e2e="pay-ach-holder-name-label">Account type</Text>
                        <Form.Field
                            data-e2e="pay-ach-holder-name-input"
                            inputProps={{
                                ...fields.accountType.props,
                                items: [
                                    {
                                        value: 'Checking',
                                        title: 'Checking',
                                    },
                                    {
                                        value: 'Savings',
                                        title: 'Savings',
                                    },
                                ],
                                onChange: handleAccountTypeChange,
                            }}
                            label=""
                            type="select"
                            messages={fields.accountType.messages}
                        />
                        <Form.Field
                            type={fields.autoChargeACH.type}
                            messages={fields.autoChargeACH.messages}
                            inputProps={{
                                ...fields.autoChargeACH.props,
                                onChange: handleBillingMethodChange,
                                checked: fields.autoChargeACH.props.value || false,
                                children: (
                                    <Text style="Label 1">
                                        I agree to the Terms and Conditions of the Commercial ACH
                                        Debit Authorization
                                    </Text>
                                ),
                            }}
                        />
                        <TextButton
                            as={Link}
                            href={CommercialACHDebitAuthorizationTermsAndConditions}
                            target="_blank"
                            data-e2e="pay-with-ach-terms-and-conditions"
                        >
                            Terms and Conditions
                        </TextButton>
                        <hr />
                        <Text>
                            {displayError && (
                                <span style={errorsStyle}>
                                    {messages.map((message) => message)}
                                </span>
                            )}
                        </Text>
                        <ColumnLayout>
                            <Button
                                // disabled={disablePayButton}
                                data-e2e="pay-with-ach-submit-btn"
                                appearance="primary"
                                type="submit"
                            >
                                Submit
                            </Button>
                            <BoxLayout>
                                <TextButton data-e2e="pay-with-ach-cancel-btn" onClick={hideModal}>
                                    Cancel
                                </TextButton>
                            </BoxLayout>
                        </ColumnLayout>
                    </StackLayout>
                </ScrollBox>
            </Form>
        </Modal>
    );
}
