import { Form, StackLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { PageComponentProps } from '../../../view/components/QuoteLandingPage';
import { withQuoteInfoProps } from '../../../view/components/WithExtraProps';
import { SignInsuranceApplicationCheckbox } from '../../../view/components/signature/SignInsuranceApplicationCheckbox';
import { LPLQuote } from '../../entities/LPLQuote';
import { WarrantyAndFraudStatement } from './LPLWarrantyAndFraudStatement';
import { LPLQuoteFormData } from './createLPLQuoteForm';

interface LPLSignStepProps
    extends PageComponentProps<LPLQuoteFormData, LPLQuote>,
        withQuoteInfoProps {}

export function LPLSignStep({
    fields,
    value,
    setValue,
    trigger,
    lplQuoteInfo,
    className,
}: LPLSignStepProps) {
    const handleAgreementToConductSignatureChange = () => {
        const newValue = {
            ...value,
            agreementToConductSignature: !value.agreementToConductSignature,
        };
        setValue(newValue);
        trigger('sign');
    };

    const handleWarrantyAndFraudSignatureChange = () => {
        const newValue = {
            ...value,
            warrantyAndFraudSignature: !value.warrantyAndFraudSignature,
        };
        setValue(newValue);
        trigger('sign');
    };

    return (
        <StackLayout className={className} gap="32">
            <Text style="heading 4">Please agree to the statements below</Text>
            <Form.Field
                type={fields.agreementToConductSignature.type}
                inputProps={{
                    checked: value.agreementToConductSignature,
                    onChange: handleAgreementToConductSignatureChange,
                    children: (
                        <SignInsuranceApplicationCheckbox
                            company={lplQuoteInfo.userInfo.companyName}
                            fullName={lplQuoteInfo.userInfo.fullName}
                            usaState={lplQuoteInfo.userInfo.usaState}
                            disableSurplusLinesDisclosure
                        />
                    ),
                }}
                data-e2e="agreement-to-conduct-signature"
            />
            <Form.Field
                type={fields.warrantyAndFraudSignature.type}
                inputProps={{
                    checked: value.warrantyAndFraudSignature,
                    onChange: handleWarrantyAndFraudSignatureChange,
                    children: WarrantyAndFraudStatement(
                        lplQuoteInfo.userInfo,
                        lplQuoteInfo.isStreamlineRenewal,
                    ),
                }}
                data-e2e="warranty-and-fraud-signature"
            />
        </StackLayout>
    );
}
