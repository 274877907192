import { BundleQuote } from '@app/bundle/entities/BundleQuote';
import { QuestionnaireData } from '@app/bundle/types/BundleQuoteQuestionnaireData';
import { CoveragePackageType } from '@app/bundle/useCases/GetRecommendedCoverageQuotes';
import { WCChubbQuoteOptions } from './types/WCChubbQuoteOptions';

export const getRecommendedQuotingOptionsWcChubb = (
    bundleQuote: BundleQuote,
    packageType: CoveragePackageType,
    questionnaireData: QuestionnaireData,
): WCChubbQuoteOptions | undefined => {
    const isDeselected = !bundleQuote.isCoverageSelected('WCChubbCoverage');
    switch (packageType) {
        case 'starter': {
            return {
                effectiveDate: bundleQuote.effectiveDate,
                policyLimit: 1000000,
                perAccidentLimit: 500000,
                eachEmployeeLimit: 500000,
                isDeselected,
            };
        }
        case 'enhanced': {
            return {
                effectiveDate: bundleQuote.effectiveDate,
                policyLimit: 1000000,
                perAccidentLimit: 500000,
                eachEmployeeLimit: 500000,
                isDeselected,
            };
        }
        case 'deluxe': {
            return {
                effectiveDate: bundleQuote.effectiveDate,
                policyLimit: 1000000,
                perAccidentLimit: 500000,
                eachEmployeeLimit: 500000,
                isDeselected,
            };
        }
    }
};
