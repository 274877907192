import React from 'react';

import {
    Card,
    Text,
    ColumnLayout,
    StatusLabel,
    StackLayout,
    useModal,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import { PolicyInTheWorksModal } from '../modals/PolicyInTheWorks';

interface ProcessingPolicyProps {
    policyName?: string;
}

export const ProcessingPolicy = ({ policyName }: ProcessingPolicyProps) => {
    const modalPolicyInTheWorks = useModal();

    return (
        <React.Fragment>
            <PolicyInTheWorksModal
                hideModal={modalPolicyInTheWorks.hide}
                modal={modalPolicyInTheWorks}
            />
            <Card flat size="medium">
                <Card.Header singleColumn>
                    <Text style="heading 5">
                        {policyName ? `${policyName} - Policy Pending` : 'New Policy'}
                    </Text>
                </Card.Header>
                <Card.Body singleColumn>
                    <ColumnLayout split="-1">
                        <StatusLabel type="yellow-outline">Processing</StatusLabel>
                    </ColumnLayout>
                </Card.Body>
                <Card.Footer singleColumn>
                    <StackLayout split="-1">
                        <ColumnLayout gap="8">
                            <Text color="ui-500">
                                Your policy is being processed. It will be available shortly for
                                review and download.
                            </Text>
                            <TextButton onClick={modalPolicyInTheWorks.show}>
                                More information
                            </TextButton>
                        </ColumnLayout>
                    </StackLayout>
                </Card.Footer>
            </Card>
        </React.Fragment>
    );
};
