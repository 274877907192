import { isErr } from '@embroker/shotwell/core/types/Result';
import {
    DefaultFormFieldRegistry,
    JSONSchema,
} from '@embroker/shotwell/view/components/JSONSchemaForm';
import { FormData } from '@embroker/shotwell/view/hooks/useForm';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    Button,
    ButtonBar,
    Spinner,
    StackLayout,
    StatusMessage,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { EndorsementContext, LimitAndRetention } from '../types';
import { PrecreateEndorsement } from '../useCases/PrecreateEndorsementUseCase';
import { NonPremiumBearingEndorsementJSONSchemaForm } from './NonPremiumBearingEndorsementJSONSchemaForm';
import { PremiumBearingEndorsementJSONSchemaForm } from './PremiumBearingEndorsementJSONSchemaForm';
import { CoverageRestriction } from '../types/CoverageRestriction';

export type EndorsementJSONSchemaFormSelectorProps<T extends FormData> = {
    endorsementTitle: string;
    jsonSchema: JSONSchema<T>;
    onDismiss(): void;
    onBack(): void;
    initialValue?: Partial<T>;
    endorsementContext: EndorsementContext;
    isPremiumBearing: boolean;
    restriction?: CoverageRestriction;
    currentOptions?: LimitAndRetention;
};

export function EndorsementJSONSchemaFormSelector<T extends FormData>(
    props: EndorsementJSONSchemaFormSelectorProps<T>,
) {
    const { endorsementTitle, jsonSchema, onDismiss, onBack, initialValue, endorsementContext } =
        props;
    const { result: precreateResult, isLoading: precreateIsLoading } = useUseCase(
        PrecreateEndorsement,
        {
            policyId: endorsementContext.policyId,
            endorsementType: endorsementContext.endorsementType,
            origin: endorsementContext.origin,
        },
    );

    if (precreateResult === undefined || precreateIsLoading) {
        return <Spinner />;
    }

    if (isErr(precreateResult)) {
        return (
            <StatusMessage status="error">
                Endorsement precreation failed. Please try to reload the page.
            </StatusMessage>
        );
    }

    const formFieldRegistry = new DefaultFormFieldRegistry();

    if (endorsementContext.endorsementType === 'amendNamedInsuredEndorsement') {
        return (
            <StackLayout gap="48">
                <Text style="heading 3">Make changes to your policy</Text>
                <StackLayout>
                    <Text style="body 1">
                        To modify your original policy, submit your requested changes (and when
                        these changes should take effect) via the form linked below. Once your
                        request is processed, we’ll update your policy and notify you. <br />
                        <br />
                        <span key="with-bold-content">
                            <strong>Please note: </strong>Making changes to your policy may increase
                            the cost of your insurance.
                        </span>
                    </Text>
                </StackLayout>
                <ButtonBar split="-1">
                    <Button
                        onClick={() => {
                            window.open(
                                'https://embroker.atlassian.net/servicedesk/customer/portal/31/create/11014',
                                '_blank',
                                'noopener,noreferrer',
                            );
                            onDismiss();
                        }}
                        data-e2e="slideout-cta"
                    >
                        Open Request Form
                    </Button>
                    <TextButton data-e2e="back-button" onClick={() => onBack()}>
                        Back
                    </TextButton>
                </ButtonBar>
            </StackLayout>
        );
    }

    return props.isPremiumBearing ? (
        <PremiumBearingEndorsementJSONSchemaForm
            endorsementTitle={endorsementTitle}
            jsonSchema={jsonSchema}
            onDismiss={onDismiss}
            onBack={onBack}
            initialValue={initialValue}
            restriction={props.restriction}
            currentOptions={props.currentOptions}
            endorsementContext={{
                endorsementId: precreateResult.value,
                policyId: endorsementContext.policyId,
                userTitle: endorsementContext.userTitle,
                company: endorsementContext.company,
                userFullName: endorsementContext.userFullName,
                policyState: endorsementContext.policyState,
                policyEffectiveDate: endorsementContext.policyEffectiveDate,
                policyExpirationDate: endorsementContext.policyExpirationDate,
                endorsementType: endorsementContext.endorsementType,
                origin: endorsementContext.origin,
            }}
            formFieldRegistry={formFieldRegistry}
        />
    ) : (
        <NonPremiumBearingEndorsementJSONSchemaForm
            endorsementTitle={endorsementTitle}
            jsonSchema={jsonSchema}
            onDismiss={onDismiss}
            onBack={onBack}
            initialValue={initialValue}
            endorsementContext={{
                endorsementId: precreateResult.value,
                policyId: endorsementContext.policyId,
                policyState: endorsementContext.policyState,
                company: endorsementContext.company,
                userTitle: endorsementContext.userTitle,
                userFullName: endorsementContext.userFullName,
                policyEffectiveDate: endorsementContext.policyEffectiveDate,
                policyExpirationDate: endorsementContext.policyExpirationDate,
                endorsementType: endorsementContext.endorsementType,
                origin: endorsementContext.origin,
            }}
            formFieldRegistry={formFieldRegistry}
        />
    );
}
