import { inject } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Success, isErr, Failure, AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import {
    APIQuestionerRepository,
    GetQuestionerFormRepositoryResponse,
} from '../repositories/APIQuestionerRepository';
import { SessionRepository } from '../../userOrg/repositories/SessionRepository';
import { isAuthenticated } from '../../userOrg/entities/Session';
import { Unauthenticated } from '../../userOrg/errors';
import { FailedToGetQuestionerForm } from '../errors';
import { QuestionerPageDefinition } from '../types/QuestionerPageDefinition';
import { FormQuestionDefinition } from '@app/view/components/DataDrivenForm/hooks/useDataDrivenForm';
import {
    getOrderedQuestions,
    QuestionerQuestion,
    StaticQuestionConfigs,
} from '../types/QuestionerQuestionType';
import { Section } from '../types/QuestionerSectionType';
import { deepClone } from '@embroker/shotwell/core/object';
import { Immutable } from '@embroker/ui-toolkit/v2';
import { ExpandedApplicationView } from '@app/shopping/useCases/GetApplication';
import { GetApplicationLastPage } from '@app/shopping/useCases/GetApplicationLastPage';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import {
    GetStaticConditionalActions,
    GetStaticConditionalActionsResponse,
} from './GetStaticConditionalActions';

export type GetQuestionerFormRequest = {
    application: Immutable<ExpandedApplicationView>;
};

export interface GetQuestionerFormResponse {
    questionerPageDefinitions?: QuestionerPageDefinition[];
    formQuestionDefinitions: FormQuestionDefinition[];
    questionerQuestions: QuestionerQuestion[];
}
export interface GetQuestionerForm extends UseCase {
    execute(
        request: GetQuestionerFormRequest,
    ): AsyncResult<
        GetQuestionerFormResponse,
        Unauthenticated | FailedToGetQuestionerForm | InvalidArgument | OperationFailed
    >;
}

class GetQuestionerFormUseCase extends UseCase implements GetQuestionerForm {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Questioner/GetQuestionerFormUseCase');
    /**
     * Constructor for GetQuestionerFormUseCase use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param notificationRepository is notification repository used to store user Questioner
     */
    constructor(
        @inject(APIQuestionerRepository) private questionerRepository: APIQuestionerRepository,
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(SessionRepository) private sessionRepository: SessionRepository,
        @inject(GetApplicationLastPage.type) private getApplicationLastPage: GetApplicationLastPage,
        @inject(GetStaticConditionalActions.type)
        private getStaticConditionalActions: GetStaticConditionalActions,
    ) {
        super(eventBus);
    }

    public async execute(
        request: GetQuestionerFormRequest,
    ): AsyncResult<
        GetQuestionerFormResponse,
        Unauthenticated | FailedToGetQuestionerForm | InvalidArgument | OperationFailed
    > {
        const { application } = request;

        const applicationId = application.id as UUID;

        const activeSession = await this.sessionRepository.getActiveSession();

        if (!isAuthenticated(activeSession.value)) {
            return Failure(Unauthenticated());
        }

        const userId = activeSession.value.userId as UUID;

        const getQuestionnaireIdsResult = await getQuestionnaireIdsForApplication(application);
        if (isErr(getQuestionnaireIdsResult)) {
            return getQuestionnaireIdsResult;
        }

        const questionnaireIds = getQuestionnaireIdsResult.value as string[]; // using as string[] to remove readonly status
        const getQuestionerFormResp = await this.questionerRepository.getQuestionerForm({
            questionnaireIds,
            includeAnswers: true,
        });

        if (isErr(getQuestionerFormResp)) {
            return getQuestionerFormResp;
        }

        const { sections, questions, externalQuestions } =
            getQuestionerFormResp.value as GetQuestionerFormRepositoryResponse;

        const getApplicationLastPageResp = await this.getApplicationLastPage.execute({
            applicationId,
            userId,
        });

        if (isErr(getApplicationLastPageResp)) {
            return getApplicationLastPageResp;
        }

        const initialPage = getApplicationLastPageResp.value.lastPage;

        const questionerPageDefinitions = Section.getPageDefinitionsForQuestionnaire(
            questions,
            sections,
            initialPage,
        );

        const staticConditionalActionsResp = await this.getStaticConditionalActions.execute();

        if (isErr(staticConditionalActionsResp)) {
            return staticConditionalActionsResp;
        }

        const { staticConditionalActions } =
            staticConditionalActionsResp.value as GetStaticConditionalActionsResponse;

        const staticQuestionConfigs: StaticQuestionConfigs = {
            staticConditionalActions,
        };

        const fieldSets = Section.getFieldSetDefinitions(questions, sections);
        const formQuestionDefinitions = QuestionerQuestion.getDataDrivenFormQuestions(
            questions,
            staticQuestionConfigs,
        );
        const externalQuestionsDefinitions = QuestionerQuestion.getDataDrivenFormQuestions(
            externalQuestions,
            staticQuestionConfigs,
        );

        const formElements = [
            ...fieldSets,
            ...formQuestionDefinitions,
            ...externalQuestionsDefinitions,
        ];

        const orderedElements = getOrderedQuestions(deepClone(formElements));

        return Success<GetQuestionerFormResponse>({
            formQuestionDefinitions: orderedElements,
            questionerQuestions: questions,
            questionerPageDefinitions,
        });
    }
}

export async function getQuestionnaireIdsForApplication(
    application: Immutable<ExpandedApplicationView>,
): AsyncResult<string[], FailedToGetQuestionerForm> {
    // TODO: longer term implementation to be handled in a separate ticket
    // https://embroker.atlassian.net/browse/EM-45369
    // The fully integrated solution will inlude a call to product definitions service to get the questionnaire ids for selected products
    const appTypeQuestionnaireIdMap: { [key: string]: string } = {
        AppTypeCodeListGenericBundle: 'esp_1',
    };

    const questionnaireId = appTypeQuestionnaireIdMap[application.appType];

    if (!questionnaireId) {
        return Failure(FailedToGetQuestionerForm());
    }

    return Success([questionnaireId]);
}

export const GetQuestionerForm: UseCaseClass<GetQuestionerForm> = GetQuestionerFormUseCase;
