import { ContainerModule } from '@embroker/shotwell/core/di';
import { Module } from '../Module';
import { NAICSRepository } from './repositories/NAICSRepository';
import { APINAICSRepository } from './repositories/NAICSRepository/APINAICSRepository';
import { GetNAICSByCode } from './useCases/GetNAICSByCode';
import { GetNAICSBySearchTerm } from './useCases/GetNAICSBySearchTerm';
import { GetNAICSCodesByGroup } from './useCases/GetNAICSCodesByGroup';
import { GetNAICSGroupNameByCode } from './useCases/GetNAICSGroupNameByCode';

/**
 * The industries module.
 */
export const IndustriesModule: Module = {
    container: new ContainerModule((bind) => {
        bind<NAICSRepository>(NAICSRepository).to(APINAICSRepository).inSingletonScope();
        bind<GetNAICSBySearchTerm>(GetNAICSBySearchTerm.type)
            .to(GetNAICSBySearchTerm)
            .inSingletonScope();
        bind<GetNAICSByCode>(GetNAICSByCode.type).to(GetNAICSByCode).inSingletonScope();
        bind<GetNAICSCodesByGroup>(GetNAICSCodesByGroup.type)
            .to(GetNAICSCodesByGroup)
            .inSingletonScope();
        bind<GetNAICSGroupNameByCode>(GetNAICSGroupNameByCode.type)
            .to(GetNAICSGroupNameByCode)
            .inSingletonScope();
    }),
};
