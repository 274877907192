import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { CheckTokenValidityError } from '../errors';
import { UserRepository } from '../repositories/UserRepository';

export interface CheckIfUserIsProspectRequest {
    userId: UUID;
}

export interface CheckIfUserIsProspect extends UseCase {
    execute(request: CheckIfUserIsProspectRequest): AsyncResult<boolean, CheckTokenValidityError>;
}

@injectable()
class CheckIfUserIsProspectUseCase extends UseCase implements CheckIfUserIsProspect {
    public static type = Symbol('userOrg/CheckIfUserIsProspect');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(UserRepository) private userRepository: UserRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: CheckIfUserIsProspectRequest,
    ): AsyncResult<boolean, CheckTokenValidityError> {
        return await this.userRepository.isUserBundleProspect(request.userId);
    }
}

export const CheckIfUserIsProspect: UseCaseClass<CheckIfUserIsProspectUseCase> =
    CheckIfUserIsProspectUseCase;
