import React from 'react';
import { BundleSummaryComponentProps } from '../../coverageDefinition';
import { CowbellCyberQuote } from '../entities/CowbellCyberQuote';
import { CyberInvoiceSummaryTable } from '../../../view/components/cyber/CyberInvoiceSummaryTable';

export function CowbellCyberQuoteSummary({
    questionnaireData,
    quote,
}: BundleSummaryComponentProps<CowbellCyberQuote>) {
    return (
        <CyberInvoiceSummaryTable
            insurerName="Insurer: Cowbell Insurance Company"
            quote={quote}
            questionnaireData={questionnaireData}
            taxesTitleText={getCowbellTaxesTitle(
                questionnaireData?.location_with_largest_number?.state ?? questionnaireData.state,
            )}
        />
    );
}

const stateWithSurchargeTitleMap: { [key: string]: string } = {
    FL: 'Florida FIGA Surcharge',
    NJ: 'New Jersey PLIGA Surcharge',
    WV: 'West Virginia Fire & Casualty Surcharge',
};

function getCowbellTaxesTitle(stateWithLargestNumber: string): string {
    if (stateWithLargestNumber in stateWithSurchargeTitleMap) {
        return stateWithSurchargeTitleMap[stateWithLargestNumber];
    } else {
        return 'Taxes';
    }
}
