import { DateDisplay } from '@embroker/shotwell/view/components/DateDisplay';
import { MoneyDisplay } from '@embroker/shotwell/view/components/MoneyDisplay';
import { OpaqueForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    BoxLayout,
    Card,
    ColumnLayout,
    Form,
    Icon,
    Modal,
    ReactProps,
    StackLayout,
    Text,
    TextButton,
    useModal,
} from '@embroker/ui-toolkit/v2';
import React, { useCallback, useContext } from 'react';
import { AppTypeLabelMap } from '../../../../shopping/types/enums';
import { hasRole } from '../../../../userOrg/entities/Session';
import { AppContext } from '../../../../view/AppContext';
import { WizardForm } from '../../../../view/hooks/useWizardForm';
import { EffectiveDate } from '../../../types/EffectiveDate';
import { CrimeQuote } from '../../entities/CrimeQuote';
import { useDevModeToday } from '../hooks/useDevModeToday';
import { CrimeQuoteOptionsFormData } from './createCrimeQuoteOptionsForm';
import { getCrimeRetentionOptions, getLimitRetentionOptions } from './limitRentetionOptions';
import { CrimeDifferenceModalContent } from './CrimeDifferenceModal';
import { Money } from '@embroker/shotwell/core/types/Money';

interface CrimeQuoteStepProps extends ReactProps<'div'> {
    quote: CrimeQuote;
    status: WizardForm<OpaqueForm<CrimeQuoteOptionsFormData>>['status'];
    fields: WizardForm<OpaqueForm<CrimeQuoteOptionsFormData>>['fields'];
    trigger: WizardForm<OpaqueForm<CrimeQuoteOptionsFormData>>['trigger'];
    setValue: WizardForm<OpaqueForm<CrimeQuoteOptionsFormData>>['setValue'];
    value: WizardForm<OpaqueForm<CrimeQuoteOptionsFormData>>['value'];
    isSubmitting: boolean;
}

const DATE_FORMAT = 'MM/dd/yyyy';
const MAX_FUTURE_DAYS_ALLOWED = 90;

export function CrimeCoverage({
    quote,
    trigger,
    status,
    setValue,
    fields,
    value,
    isSubmitting,
    className,
}: CrimeQuoteStepProps) {
    const { activeSession } = useContext(AppContext);
    const isAdmin = hasRole(activeSession, 'admin');
    const isRenewal = quote.isRenewal;
    const shouldHidePremium = status === 'dirty';
    const crimeLimitOptions = getLimitRetentionOptions(quote.options.previousLimit).limits;
    const crimeRetentionOptions = getCrimeRetentionOptions();
    const crimeDifferenceModal = useModal();

    const { result: devModeToday, isLoading: isLoadingDevModeToday } = useDevModeToday();
    const isEffectiveDateSelectable = useCallback(
        (effectiveDate: Date) => {
            return EffectiveDate.isSelectedEffectiveDateValid(
                effectiveDate,
                devModeToday,
                MAX_FUTURE_DAYS_ALLOWED,
                isAdmin,
            );
        },
        [isAdmin, devModeToday],
    );

    if (isLoadingDevModeToday) {
        return null;
    }

    const handleDateChange = (event: { target: { value: string; date: Date } }) => {
        setValue({
            ...value,
            effectiveDate: event.target.date,
        });
    };

    const endDate = EffectiveDate.calculateCoverageEndDate(value.effectiveDate);

    const limitField = quote.options.limit;
    const previousLimitField = quote.options.previousLimit;
    const retentionField = quote.options.retention;
    const previousRetentionField = quote.options.previousRetention;

    if (!limitField || !retentionField) {
        return null;
    }

    return (
        <StackLayout gap="32" className={className}>
            <Text style="heading 4">When do you want your coverage to begin?</Text>
            <ColumnLayout gap="none">
                <Form.Field
                    className="u-1/3@desktop u-1/2@tablet u-1/2"
                    type={fields.effectiveDate.type}
                    inputProps={{
                        ...fields.effectiveDate.props,
                        onChange: handleDateChange,
                        disabled: isSubmitting || isRenewal,
                        isSelectable: isEffectiveDateSelectable,
                    }}
                    messages={fields.effectiveDate.messages}
                />
                <BoxLayout>
                    <Text style="heading 5" data-e2e="end-date">
                        &ndash;{' '}
                        {endDate ? <DateDisplay format={DATE_FORMAT} value={endDate} /> : null}
                    </Text>
                </BoxLayout>
            </ColumnLayout>
            <Card isSelected={false} locked={false} data-e2e="crime-card">
                <Card.Header>
                    <Icon name="cage" />
                    <Text style="heading 5">{AppTypeLabelMap['AppTypeCodeListEmbrokerCrime']}</Text>
                </Card.Header>
                <Card.Body>
                    <StackLayout>
                        <Text>
                            Covers the loss of money, securities, or tangible property, directly
                            caused by crimes, such as employee theft, robbery, forgery, and certain
                            types of computer fraud.
                        </Text>
                        <ColumnLayout
                            gap="16"
                            responsive={{ screenWidth: { smallerThan: 'tablet' } }}
                        >
                            <Form.Field
                                type={fields.limit.type}
                                inputProps={{
                                    ...fields.limit.props,
                                    filterable: false,
                                    items: crimeLimitOptions,
                                    label: 'Limit',
                                    disabled: isSubmitting,
                                }}
                                data-e2e="crime-limit"
                            />
                            <Form.Field
                                type={fields.retention.type}
                                inputProps={{
                                    ...fields.retention.props,
                                    filterable: false,
                                    items: crimeRetentionOptions,
                                    label: 'Retention',
                                    disabled: isSubmitting,
                                }}
                                data-e2e="crime-retention"
                            />
                        </ColumnLayout>
                        {isRenewal && (
                            <ColumnLayout>
                                <BoxLayout gap="12" className="u-1/2">
                                    <StackLayout gap="none">
                                        <Text style="microcopy">Previous Limit:</Text>
                                        <Text style="body 1">
                                            {previousLimitField && (
                                                <MoneyDisplay
                                                    value={Money.tryFromFloat(previousLimitField)}
                                                    fractionDigits={0}
                                                />
                                            )}
                                        </Text>
                                    </StackLayout>
                                </BoxLayout>
                                <BoxLayout gap="12" className="u-1/2">
                                    <StackLayout gap="none">
                                        <Text style="microcopy">Previous Retention</Text>
                                        <Text style="body 1">
                                            {previousRetentionField && (
                                                <MoneyDisplay
                                                    value={Money.tryFromFloat(
                                                        previousRetentionField,
                                                    )}
                                                    fractionDigits={0}
                                                />
                                            )}
                                        </Text>
                                    </StackLayout>
                                </BoxLayout>
                            </ColumnLayout>
                        )}
                        <React.Fragment>
                            <ColumnLayout split="-1">
                                <Text style="heading 5">Select your coverage </Text>
                                <TextButton onClick={crimeDifferenceModal.show}>
                                    What's the difference?
                                </TextButton>
                            </ColumnLayout>
                            <Form.Field
                                type="radioGroup"
                                inputProps={{
                                    ...fields.level.props,
                                    items: [
                                        {
                                            value: true,
                                            title: 'Plus',
                                        },
                                        {
                                            value: false,
                                            title: 'Standard',
                                        },
                                    ],
                                    disabled: isSubmitting,
                                    value: value.level,
                                }}
                            />
                        </React.Fragment>
                    </StackLayout>
                </Card.Body>
                <Card.Footer>
                    {!shouldHidePremium ? (
                        <Text as="span" style="body 1">
                            Premium:{' '}
                            <Text as="span" style="heading 5">
                                <MoneyDisplay value={quote.details.totalBasePremium} />
                            </Text>
                            {quote.details.previousPremium && (
                                <Text style="body 1">
                                    Previous Premium:{' '}
                                    <Text as="span" style="heading 5">
                                        <MoneyDisplay value={quote.details.previousPremium} />
                                    </Text>
                                </Text>
                            )}
                        </Text>
                    ) : null}
                </Card.Footer>
                <Modal size="large" {...crimeDifferenceModal}>
                    <CrimeDifferenceModalContent />
                </Modal>
            </Card>
        </StackLayout>
    );
}
