import type * as APIType from '@embroker/shotwell-api/app';
import { API, InsuranceApplicationGeneratedDocument } from '@embroker/shotwell-api/app';
import { AllowedCoverageQuoteOptions } from '@embroker/shotwell-api/app.spec';
import { isAPIError } from '@embroker/shotwell-api/errors';
import { APIRequest } from '@embroker/shotwell-api/request';
import { injectable } from '@embroker/shotwell/core/di';
import { JSONSerdes } from '@embroker/shotwell/core/encoding';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { Immutable, Nullable } from '@embroker/shotwell/core/types';
import { USD } from '@embroker/shotwell/core/types/Money';
import {
    AsyncResult,
    Failure,
    FailureResult,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { State } from '@embroker/shotwell/core/types/StateList';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { isAfter, isValid, parseISO, startOfDay, startOfToday } from 'date-fns';
import {
    QuotingEngineLPLEverest,
    ShoppingCoverageCodeListProfessionalLiability,
} from '@app/shopping/types/enums';
import { InvalidAnnualTechFee, QuoteOptionsApprovalNeeded } from '../../../errors';
import { DocumentType } from '../../../types/Document';
import { isLimitType } from '../../../types/Limits';
import { QuoteExpiration } from '../../../types/QuoteExpiration';
import { QuoteIdMap } from '../../../types/QuoteList';
import { LPLQuote } from '../../entities/LPLQuote';
import { LPLAttorneyInfo } from '../../types/LPLAttorneyInfo';
import { LPLConfig } from '../../types/LPLConfig';
import { LPLHigherLimit } from '../../types/LPLHigherLimit';
import { LPLQuoteExtended } from '../../types/LPLQuoteExtended';
import { LPLQuoteInfo } from '../../types/LPLQuoteInfo';
import {
    AggregateLimit,
    LPLQuoteOptions,
    PerClaimDeductible,
    PerClaimLimit,
    SeparateClaimExpenseLimit,
} from '../../types/LPLQuoteOptions';
import { EnqueueTaskResponse, LPLQuoteRepository } from './index';
import { ApplicationNotFound, Unknown } from '@app/quote/lpl/errors';

@injectable()
export class APILPLQuoteRepository implements LPLQuoteRepository {
    async getLastLPLQuote(
        applicationId: UUID,
    ): AsyncResult<LPLQuoteExtended, InvalidArgument | OperationFailed> {
        const quoteExtendedResponse = await API.request('shopping/get_quote_extended', {
            app_id: applicationId,
        });
        if (isErr(quoteExtendedResponse)) {
            return Failure(OperationFailed({ errors: quoteExtendedResponse.errors }));
        }

        const quoteExtended = quoteExtendedResponse.value;

        const lplQuoteExtended = quoteExtended.quote_extended_map[QuotingEngineLPLEverest];
        if (!lplQuoteExtended) {
            return Failure(
                InvalidArgument({
                    argument: 'quote_extended_map',
                    value: quoteExtended.quote_extended_map,
                }),
            );
        }

        const lplQuoteResult = await toLplQuote(quoteExtended, lplQuoteExtended);
        if (isErr(lplQuoteResult)) {
            return Failure(
                InvalidArgument({
                    argument: 'Lpl quote extended',
                    value: { lplQuoteExtended },
                }),
            );
        }

        const questionnaireDataResult = JSONSerdes.deserialize(lplQuoteExtended.questionnaire_data);
        if (isErr(questionnaireDataResult)) {
            return Failure(
                InvalidArgument({
                    argument: 'questionnaire_data',
                    value: lplQuoteExtended.questionnaire_data,
                }),
            );
        }

        const lplQuoteInfo = buildLplQuoteInfo(
            questionnaireDataResult.value as QuestionnaireData,
            lplQuoteExtended.is_renewal,
            lplQuoteExtended.is_streamline_renewal,
            getLPLHigherLimit(
                lplQuoteExtended.uw_review?.allowed_app_quote_options
                    .allowed_coverage_quote_options,
            ),
            !!lplQuoteExtended.uw_review,
        );

        const lplQuoteInfoResult = LPLQuoteInfo.create(lplQuoteInfo);
        if (isErr(lplQuoteInfoResult)) {
            return lplQuoteInfoResult;
        }

        return Success({
            lplQuote: lplQuoteResult.value,
            lplQuoteInfo: lplQuoteInfo,
        });
    }

    async getLPLApplicationDocument(
        applicationId: UUID,
    ): AsyncResult<string, InvalidArgument | OperationFailed> {
        const applicationResponse = await API.request('shopping/application', {
            id: applicationId,
        });
        if (isErr(applicationResponse)) {
            return Failure(
                OperationFailed({
                    errors: applicationResponse.errors,
                }),
            );
        }

        const lplAppFileKey = getAppFileKeyFromGeneratedDocuments(
            applicationResponse.value.generated_documents,
        );

        if (lplAppFileKey === null) {
            return Failure(
                OperationFailed({
                    message: 'Application document file key is null',
                }),
            );
        }

        return Success<string>(lplAppFileKey);
    }

    async enqueueReQuoteLPLTask(
        applicationId: UUID,
        lplQuoteOptions: LPLQuoteOptions,
    ): AsyncResult<EnqueueTaskResponse, InvalidArgument | OperationFailed> {
        const userTzOffsetMinutes = new Date(Date.now()).getTimezoneOffset();
        const enqueueReQuoteLPLTaskResponse = await API.request('shopping/create_quote_task', {
            application_id: applicationId,
            quote_options: {
                lpl_everest: toApiLplQuoteOptions(lplQuoteOptions),
            },
            user_tz_offset_minutes: -userTzOffsetMinutes,
        });
        if (isErr(enqueueReQuoteLPLTaskResponse)) {
            return Failure(
                OperationFailed({
                    errors: enqueueReQuoteLPLTaskResponse.errors,
                }),
            );
        }
        return Success({ taskId: enqueueReQuoteLPLTaskResponse.value.task_id });
    }

    async purchaseLPL(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<
        void,
        InvalidArgument | OperationFailed | InvalidAnnualTechFee | QuoteOptionsApprovalNeeded
    > {
        const userTzOffsetMinutes = new Date(Date.now()).getTimezoneOffset();
        const quoteIdMap: QuoteIdMap = { [QuotingEngineLPLEverest]: quoteId };
        const purchaseResponse = await API.request('shopping/purchase', {
            id: applicationId,
            quote_id_map: quoteIdMap,
            user_tz_offset_minutes: -userTzOffsetMinutes, // "-" because getTimezoneOffset() returns inverse UTC minutes
        });
        if (isErr(purchaseResponse)) {
            const error = purchaseResponse.errors[0];
            if (isAPIError(error)) {
                if (error.details.name === 'invalid_annual_tech_fee') {
                    return Failure(InvalidAnnualTechFee());
                }
                if (error.details.name === 'quote_options_not_allowed') {
                    return Failure(QuoteOptionsApprovalNeeded());
                }
            }
            return Failure(
                OperationFailed({
                    errors: purchaseResponse.errors,
                }),
            );
        }
        return Success();
    }

    async enqueueIsQuoteConsistentTask(
        applicationId: UUID,
        quoteId: UUID,
    ): AsyncResult<EnqueueTaskResponse, InvalidArgument | OperationFailed> {
        const isQuoteConsistentResponse = await API.request('shopping/lpl_is_quote_consistent', {
            app_id: applicationId,
            quote_id: quoteId,
        });
        if (isErr(isQuoteConsistentResponse)) {
            return Failure(
                OperationFailed({
                    errors: isQuoteConsistentResponse.errors,
                }),
            );
        }
        return Success({ taskId: isQuoteConsistentResponse.value.task_id });
    }

    async enqueueCreateLPLDocumentTask(
        applicationId: UUID,
        quoteId: UUID,
        documentType: DocumentType,
    ): AsyncResult<EnqueueTaskResponse, InvalidArgument | OperationFailed> {
        const enqueueCreateLPLDocumentTaskResponse = await downloadQuoteDocument(
            applicationId,
            quoteId,
            documentType,
        );
        if (isErr(enqueueCreateLPLDocumentTaskResponse)) {
            return Failure(
                OperationFailed({
                    errors: enqueueCreateLPLDocumentTaskResponse.errors,
                }),
            );
        }
        return Success({ taskId: enqueueCreateLPLDocumentTaskResponse.value.task_id });
    }

    async getLPLConfig(): AsyncResult<LPLConfig, InvalidArgument | OperationFailed> {
        const getConfigResponse = await API.request('global/get_config');

        if (isErr(getConfigResponse)) {
            return Failure(
                OperationFailed({
                    errors: getConfigResponse.errors,
                }),
            );
        }

        return LPLConfig.create({
            lplDevModeEffectiveDate: getConfigResponse.value.lpl_dev_mode_effective_date,
        });
    }

    async hasDocGenTaskInProcess(
        applicationId: UUID,
    ): AsyncResult<boolean, InvalidArgument | OperationFailed> {
        const hasDocGenTaskResponse = await API.request('task/has_doc_gen_task_in_process', {
            application_id: applicationId,
        });

        if (isErr(hasDocGenTaskResponse)) {
            return Failure(
                OperationFailed({
                    errors: hasDocGenTaskResponse.errors,
                }),
            );
        }

        return hasDocGenTaskResponse;
    }

    public async requestHigherLimit(
        applicationId: UUID,
    ): AsyncResult<void, InvalidArgument | OperationFailed> {
        const result = await API.request('shopping/request_higher_limits', {
            application_id: applicationId,
            is_submit: true,
        });

        if (isErr(result)) {
            return Failure(
                OperationFailed({
                    message: 'Request higher limits failed',
                    errors: result.errors,
                }),
            );
        }

        return Success();
    }

    public async updateApplicationAddCyberAfterCheckout(
        applicationId: UUID,
        addCyberAfterCheckout: boolean,
    ): AsyncResult<void, OperationFailed | ApplicationNotFound | Unknown> {
        const result = await API.request('shopping/update_application_add_cyber_after_checkout', {
            application_id: applicationId,
            add_cyber_after_checkout: addCyberAfterCheckout,
        });

        if (isErr(result)) {
            const error = result.errors[0];
            if (isAPIError(error)) {
                if (error.details.name === 'application_not_found') {
                    return Failure(ApplicationNotFound(applicationId));
                }
                if (error.details.name === 'unknown') {
                    return Failure(Unknown());
                }
            }
            return Failure(
                OperationFailed({
                    errors: result.errors,
                }),
            );
        }
        return Success();
    }
}

interface StaffMember {
    fullName: string;
    dateJoinedFirm: string;
}

interface QuestionnaireData {
    current_retroactive_date: string;
    current_pl: boolean;
    current_policy_details: { start_and_end_date: { policy_end_date: string } };
    totalAttorneysThisYear: number;
    location_with_largest_number: LocationWithLargestNumber;
    first_name: string;
    last_name: string;
    title: string;
    state: State;
    company_name: string;
    allOwners: { addStaffMember: StaffMember[] };
}

interface LocationWithLargestNumber {
    state: State;
}

function toApiLplQuoteOptions(lplQuoteOptions: LPLQuoteOptions): APIType.LplQuoteOptions {
    return {
        effective_date: lplQuoteOptions.effectiveDate,
        per_claim_limit: lplQuoteOptions.perClaimLimit,
        aggregate_limit: lplQuoteOptions.aggregateLimit,
        per_claim_deductible: lplQuoteOptions.perClaimDeductible,
        aggregate_deductible: 'AggregateDeductibleNA',
        deductible_type: lplQuoteOptions.deductibleType,
        claims_expense_type: lplQuoteOptions.claimsExpenseType,
        separate_claim_expense_limit: lplQuoteOptions.separateClaimExpenseLimit,
        is_deselected: false,
    };
}

export function toLplQuoteOptions(apiLplQuoteOptions: APIType.LplQuoteOptions): LPLQuoteOptions {
    return {
        effectiveDate: apiLplQuoteOptions.effective_date,
        perClaimLimit: apiLplQuoteOptions.per_claim_limit as PerClaimLimit,
        aggregateLimit: apiLplQuoteOptions.aggregate_limit as AggregateLimit,
        perClaimDeductible: apiLplQuoteOptions.per_claim_deductible as PerClaimDeductible,
        deductibleType: apiLplQuoteOptions.deductible_type,
        claimsExpenseType: apiLplQuoteOptions.claims_expense_type,
        separateClaimExpenseLimit:
            apiLplQuoteOptions.separate_claim_expense_limit as Nullable<SeparateClaimExpenseLimit>,
        isDeselected: apiLplQuoteOptions.is_deselected,
    };
}

async function toLplQuote(
    apiQuoteExtended: Immutable<APIType.ShoppingGetQuoteExtendedResponse>,
    apiLplQuoteExtended: Immutable<APIType.QuoteExtended>,
): AsyncResult<LPLQuote> {
    const apiLplQuote = apiLplQuoteExtended.quote;
    if (!apiLplQuote) {
        return Failure(InvalidArgument({ argument: 'quote extended', value: apiLplQuoteExtended }));
    }

    const apiLplQuoteOptions = apiLplQuote.options.lpl_everest;
    if (!apiLplQuoteOptions) {
        return Failure(OperationFailed({ message: 'lplQuoteOptions is null or undefined' }));
    }

    return await LPLQuote.create({
        isIndication: apiLplQuote.is_indication,
        id: apiLplQuote.id,
        applicationId: apiLplQuote.app_id,
        status: 'draft',
        totalPremium: apiLplQuote.total_premium,
        annualTechnologyFee: apiLplQuote.annual_technology_fee,
        totalPayable: apiLplQuote.total_payable,
        fileKey: apiLplQuote.file_key || undefined,
        fees: apiLplQuote.fees || undefined,
        details: {
            specimenPolicyFileKey:
                apiLplQuote.details.lpl_everest?.specimen_policy_file_key || undefined,
            lplProcessingFee: apiLplQuote.details.lpl_everest?.policy_administration_fee ?? USD(0),
            premium: apiLplQuote.details.lpl_everest?.premium ?? undefined,
        },
        options: toLplQuoteOptions(apiLplQuoteOptions),
        daysToExpire: QuoteExpiration.getDaysLeftUntilExpiration({
            quotingEngine: QuotingEngineLPLEverest,
            applicationStatus: apiLplQuoteExtended.app_status,
            validUntil: apiLplQuoteExtended.app_valid_until ?? null,
            quoteEffectiveDate: apiLplQuoteOptions.effective_date,
            today: startOfToday(),
            isBroker: !!apiQuoteExtended.brokerage_id,
        }),
    });
}

function buildAttorneyList(
    attorneyList: StaffMember[],
    currentPl: boolean,
    currentRetroactiveDate?: Date,
): LPLAttorneyInfo[] {
    return attorneyList.map((attorney) => {
        const hireDate = parseISO(attorney.dateJoinedFirm);
        if (!isValid(hireDate)) {
            return {
                fullName: attorney.fullName,
                retroactiveDate: undefined,
            };
        }

        if (!currentRetroactiveDate) {
            return {
                fullName: attorney.fullName,
                retroactiveDate: currentPl ? hireDate : undefined,
            };
        }

        const isHireDateAfterRetroDate = isAfter(hireDate, currentRetroactiveDate);
        return {
            fullName: attorney.fullName,
            retroactiveDate: isHireDateAfterRetroDate ? hireDate : currentRetroactiveDate,
        };
    });
}

function buildLplQuoteInfo(
    questionnaireData: QuestionnaireData,
    isRenewal: boolean,
    isStreamlineRenewal: boolean,
    lplHigherLimit?: LPLHigherLimit,
    disableEditAdd = false,
): LPLQuoteInfo {
    const retroactiveDateParsed = parseISO(questionnaireData.current_retroactive_date);
    const retroactiveDate = isValid(retroactiveDateParsed)
        ? startOfDay(retroactiveDateParsed)
        : undefined;

    const policyEndDateParsed = parseISO(
        questionnaireData.current_policy_details?.start_and_end_date?.policy_end_date,
    );
    const currentPolicyEndDate = isValid(policyEndDateParsed)
        ? startOfDay(policyEndDateParsed)
        : undefined;
    return {
        currentRetroactiveDate: retroactiveDate,
        hasCurrentPolicy: questionnaireData?.current_pl || false,
        currentPolicyEndDate: currentPolicyEndDate,
        totalAttorneys: questionnaireData?.totalAttorneysThisYear,
        stateWithLargestNumber: questionnaireData?.location_with_largest_number?.state,
        userInfo: {
            fullName: `${questionnaireData?.first_name} ${questionnaireData?.last_name}`,
            title: questionnaireData?.title,
            usaState: questionnaireData?.state,
            companyName: questionnaireData?.company_name,
        },
        isRenewal: isRenewal,
        isStreamlineRenewal,
        attorneyList: buildAttorneyList(
            questionnaireData?.allOwners.addStaffMember,
            questionnaireData?.current_pl,
            retroactiveDate,
        ),
        higherLimit: lplHigherLimit,
        disableEditApp: disableEditAdd,
    };
}

function getAppFileKeyFromGeneratedDocuments(
    generatedDocuments: Immutable<InsuranceApplicationGeneratedDocument[]>,
) {
    const docs = generatedDocuments || [];
    const appFile = docs.find((file) => {
        return file.file_name.endsWith('application.pdf');
    });
    return appFile && appFile.file_key ? appFile.file_key : null;
}

function downloadQuoteDocument(
    applicationId: UUID,
    quoteId: UUID,
    documentType: DocumentType,
):
    | APIRequest<
          APIType.ShoppingCreateQuoteSummaryTaskRequest,
          APIType.ShoppingCreateQuoteSummaryTaskResponse
      >
    | APIRequest<
          APIType.ShoppingCreateSpecimenPolicyTaskRequest,
          APIType.ShoppingCreateSpecimenPolicyTaskResponse
      >
    | FailureResult<InvalidArgument> {
    switch (documentType) {
        case DocumentType.QuoteSummary: {
            return API.request('shopping/create_quote_summary_task', {
                application_id: applicationId,
                quote_id: quoteId,
            });
        }
        case DocumentType.SpecimenPolicy: {
            return API.request('shopping/create_specimen_policy_task', {
                application_id: applicationId,
                quote_id: quoteId,
            });
        }
        default: {
            return Failure(
                InvalidArgument({ argument: 'Document type invalid', value: documentType }),
            );
        }
    }
}

function getLPLHigherLimit(
    allowedQuoteOptionsList?: Immutable<AllowedCoverageQuoteOptions[]>,
): LPLHigherLimit | undefined {
    const lplAllowedQuoteOptions = allowedQuoteOptionsList?.find(
        (allowedCoverageQuoteOptions) =>
            allowedCoverageQuoteOptions.coverage_type ===
            ShoppingCoverageCodeListProfessionalLiability,
    );
    if (!lplAllowedQuoteOptions?.limit.max) {
        return undefined;
    }
    return mapFromApiLimit(lplAllowedQuoteOptions.limit.max);
}

export function mapFromApiLimit(
    apiLimit?: Immutable<Record<string, number>>,
): LPLHigherLimit | undefined {
    if (!apiLimit) {
        return undefined;
    }
    if (!isLimitType(apiLimit)) {
        return undefined;
    }
    const limit = LPLHigherLimit.create({
        per_claim_limit: apiLimit.MultiValueLimitNameListPerClaimLimit,
        aggregate_limit: apiLimit.MultiValueLimitNameListAggregateLimit,
    });
    if (isErr(limit)) {
        return undefined;
    }
    return limit.value;
}
