import { errorCodes } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { ErrorObject } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';

export const ErrorCode = errorCodes({
    GetActiveSessionFailedErrorCode: 0x8101,
    GetOrganizationFailedErrorCode: 0x8102,
    GetOrganizationsForUserFailedErrorCode: 0x8103,
    GetActiveUserFailedErrorCode: 0x8104,
});

export type GetActiveSessionFailedError = ErrorObject<
    typeof ErrorCode.GetActiveSessionFailedErrorCode,
    {
        message: string;
    }
>;
export function GetActiveSessionFailedError(
    message: string,
): Immutable<GetActiveSessionFailedError> {
    return {
        name: 'GetActiveSessionFailedError',
        code: ErrorCode.GetActiveSessionFailedErrorCode,
        message: 'Failed to get active session',
        details: {
            message,
        },
    };
}

export type GetOrganizationFailedError = ErrorObject<
    typeof ErrorCode.GetOrganizationFailedErrorCode,
    {
        organizationId: UUID;
    }
>;
export function GetOrganizationFailedError(
    organizationId: UUID,
): Immutable<GetOrganizationFailedError> {
    return {
        name: 'GetOrganizationFailedError',
        code: ErrorCode.GetOrganizationFailedErrorCode,
        message: 'Failed to get organization by id',
        details: {
            organizationId,
        },
    };
}

export type GetOrganizationsForUserFailedError = ErrorObject<
    typeof ErrorCode.GetOrganizationsForUserFailedErrorCode,
    {
        userId: UUID;
    }
>;
export function GetOrganizationsForUserFailedError(
    userId: UUID,
): Immutable<GetOrganizationsForUserFailedError> {
    return {
        name: 'GetOrganizationsForUserFailedError',
        code: ErrorCode.GetOrganizationsForUserFailedErrorCode,
        message: 'Failed to get organizations by user id',
        details: {
            userId,
        },
    };
}

export type GetActiveUserFailedError = ErrorObject<
    typeof ErrorCode.GetActiveUserFailedErrorCode,
    {}
>;
export function GetActiveUserFailedError(): Immutable<GetActiveUserFailedError> {
    return {
        name: 'GetActiveUserFailedError',
        code: ErrorCode.GetActiveUserFailedErrorCode,
        message: 'Failed to get active user',
        details: {},
    };
}
