import { Joi, defineValidator } from '@embroker/shotwell/core/validation/schema';
import { QuestionerPageDefinition } from './QuestionerPageDefinition';
import { QuestionerQuestion, Text, TextSchema } from './QuestionerQuestionType';
import { FormQuestionDefinition } from '@app/view/components/DataDrivenForm/hooks/useDataDrivenForm';

export const SectionTypes = ['PAGE', 'SECTION', 'FIELD_SET'] as const;
export type SectionType = (typeof SectionTypes)[number];

export interface Section {
    key: string;
    parent_key?: string;
    text: Text[];
    layout?: { [key: string]: any };
    type: SectionType;
    sort_order?: number;
}

const SectionSchema = Joi.object({
    key: Joi.string().required(),
    parent_key: Joi.string().optional(),
    text: Joi.array().items(TextSchema).required(),
    layout: Joi.object().pattern(Joi.string(), Joi.any()).optional(),
    type: Joi.string()
        .required()
        .valid(...SectionTypes),
    sort_order: Joi.number().optional(),
});

function findSmallestNumber(arr: (number | undefined)[]) {
    const numbers = arr.filter((n) => Boolean(n)) as number[];
    return arr.length > 0 ? Math.min(...numbers) : undefined;
}

const hasInitialPage = (pageDefinitions: QuestionerPageDefinition[]): boolean => {
    return pageDefinitions.some((page) => {
        if (page.isInitial) {
            return true;
        }
        if (page.subPages && page.subPages.length > 0) {
            return hasInitialPage(page.subPages);
        }
        return false;
    });
};

export const Section = {
    ...defineValidator<Section>(SectionSchema),
    create(section: unknown) {
        return Section.validate(section);
    },
    getPageDefinitionsForQuestionnaire(
        questions: readonly QuestionerQuestion[],
        sections?: Section[],
        initialPage?: string,
    ): QuestionerPageDefinition[] | undefined {
        if (!sections) {
            return;
        }

        const pageDefinitions: QuestionerPageDefinition[] = [];
        const rootSections = sections.filter(({ parent_key }) => {
            return !sections.some(({ key }) => parent_key === key);
        });

        rootSections.forEach((rootSection) => {
            const page = createPageDefinition(rootSection, sections, questions, initialPage);
            if (page) {
                pageDefinitions.push(page);
            }
        });

        const questionKeysFromPageDefinitions =
            Section.getQuestionKeysFromPageDefinitions(pageDefinitions);

        const orphanQuestions = questions
            .filter(({ key }) => !questionKeysFromPageDefinitions.includes(key))
            .map(({ key }) => key);

        if (orphanQuestions.length) {
            pageDefinitions.push({
                name: 'additional_questions',
                title: 'Additional Questions',
                fields: orphanQuestions,
                isInitial: initialPage === 'additional_questions',
            });
        }

        if (!hasInitialPage(pageDefinitions)) {
            // If no initial page is found, make the first page the initial page
            for (const page of pageDefinitions) {
                if (page.subPages) {
                    // We know that nesting is only one level deep, so we can safely set the first element as initial
                    page.subPages[0].isInitial = true;
                    break;
                }
                page.isInitial = true;
                break;
            }
        }

        return pageDefinitions;
    },
    getFieldSetDefinitions(
        questions: QuestionerQuestion[],
        sections?: Section[],
    ): FormQuestionDefinition[] {
        if (!sections) {
            return [];
        }
        const fieldSets = sections.filter(({ type }) => type === 'FIELD_SET');
        return fieldSets.map((fieldSet) => {
            const { key, text } = fieldSet;
            const title = text.find((text) => text.type === 'TITLE')?.value;
            const fieldSetQuestions = questions.filter(({ section_key }) => section_key === key);
            const fieldSetQuestionsKeys = fieldSetQuestions.map(({ key }) => key);
            const sortOrder = findSmallestNumber(fieldSetQuestions.map(({ order }) => order));
            return {
                key,
                questionType: 'static',
                sortOrder,
                staticOptions: {
                    fieldSetProps: { title, questions: fieldSetQuestionsKeys },
                },
            };
        });
    },
    getQuestionKeysFromFromFieldSets(
        section: Section,
        sections: Section[],
        questions: QuestionerQuestion[],
    ): string[] {
        if (!sections.length) {
            return [];
        }
        const fieldSets = sections.filter(
            ({ parent_key, type }) => parent_key === section.key && type === 'FIELD_SET',
        );

        return fieldSets.reduce((acc, fieldSet) => {
            const { key } = fieldSet;
            const fieldSetQuestionsKeys = questions
                .filter(({ section_key }) => section_key === key)
                .map(({ key }) => key);
            return [...acc, ...fieldSetQuestionsKeys];
        }, [] as string[]);
    },
    getQuestionKeysFromPageDefinitions(pageDefinitions: QuestionerPageDefinition[]): string[] {
        const questionKeys: string[] = pageDefinitions.reduce((acc, page) => {
            if (page.fields) {
                return [...acc, ...page.fields];
            }
            if (page.subPages) {
                return [...acc, ...Section.getQuestionKeysFromPageDefinitions(page.subPages)];
            }
            return acc;
        }, [] as string[]);
        return questionKeys;
    },
};

function createPageDefinition(
    section: Section,
    sections: Section[],
    questions: readonly QuestionerQuestion[],
    activePage?: string,
): QuestionerPageDefinition | undefined {
    const { key, type, text } = section;
    const title = text.find((text) => text.type === 'TITLE')?.value;

    if (type === 'PAGE') {
        const questionKeys = questions
            .filter((question) => question.section_key === key)
            .map((question) => question.key);

        const fieldSetKeys = sections
            .filter((section) => section.parent_key === key)
            .map((section) => section.key);

        const questionKeysFromfieldSets = Section.getQuestionKeysFromFromFieldSets(
            section,
            sections,
            questions as QuestionerQuestion[],
        );

        if (questionKeys.length > 0) {
            return {
                isInitial: activePage === key,
                name: key,
                title,
                fields: [...questionKeys, ...fieldSetKeys, ...questionKeysFromfieldSets],
            };
        }
    } else {
        const subPages = sections
            .filter((subSection) => subSection.parent_key === key)
            .map((subSection) => createPageDefinition(subSection, sections, questions, activePage))
            .filter((subPage): subPage is QuestionerPageDefinition => subPage !== undefined);

        if (subPages.length > 0) {
            return {
                name: key,
                title,
                subPages,
            };
        }
    }

    return undefined;
}
