import { errorCodes } from '@embroker/shotwell/core/Error';
import { Immutable } from '@embroker/shotwell/core/types';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { ErrorObject } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { Session } from './entities/Session';

export const ErrorCode = errorCodes({
    /**
     * Invalid Email
     */
    InvalidEmail: 0x2000,
    /**
     * Email address already in use error.
     */
    EmailAlreadyInUse: 0x2001,
    /**
     * Invalid session object
     */
    InvalidSession: 0x2002,
    /**
     * No account, account does not exist, username is not registered
     */
    NoAccount: 0x2003,
    /**
     * Max number of signUp attempts
     */
    MaxNumberOfSignUpAttemptsExceeded: 0x2004,
    /**
     * Wrong username password pair
     */
    WrongUsernamePasswordPair: 0x2005,
    /**
     * Operation could not be performed by an unauthenticated user.
     */
    Unauthenticated: 0x2006,
    InactiveAccount: 0x2007,
    OrganizationNotFound: 0x2010,
    OperationNotAllowed: 0x2011,
    InvalidToken: 0x2020,
    ExpiredToken: 0x2021,
    FailedToRetrieveSession: 0x2022,
    CheckTokenValidityError: 0x2009,
    NaicsConfirmationError: 0x2010,
    NaicsCodeRequiresRefinementError: 0x2011,
});

/**
 * Email already in use error object.
 */
export type EmailAlreadyInUse = ErrorObject<
    typeof ErrorCode.EmailAlreadyInUse,
    {
        /**
         * The email address that is already in use.
         */
        email: EmailAddress;
    }
>;

export function EmailAlreadyInUse(email: EmailAddress): Immutable<EmailAlreadyInUse> {
    return {
        name: 'EmailAlreadyInUse',
        code: ErrorCode.EmailAlreadyInUse,
        message: 'Email already in use.',
        details: {
            email,
        },
    };
}

/**
 * Invalid email error object.
 */
export type InvalidEmail = ErrorObject<
    typeof ErrorCode.InvalidEmail,
    {
        /**
         * The email address that is invalid.
         */
        email: EmailAddress;
    }
>;

export function InvalidEmail(email: EmailAddress): Immutable<InvalidEmail> {
    return {
        name: 'InvalidEmail',
        code: ErrorCode.InvalidEmail,
        message: 'Invalid email.',
        details: {
            email,
        },
    };
}

export type CheckTokenValidityError = ErrorObject<
    typeof ErrorCode.CheckTokenValidityError,
    {
        userId: UUID;
    }
>;

/**
 * Max number of SIgnUp requests exceeded error object.
 */
export type MaxNumberOfSignUpAttemptsExceeded = ErrorObject<
    typeof ErrorCode.MaxNumberOfSignUpAttemptsExceeded,
    {}
>;

export function MaxNumberOfSignUpAttemptsExceeded(): Immutable<MaxNumberOfSignUpAttemptsExceeded> {
    return {
        name: 'MaxNumberOfSignUpAttemptsExceeded',
        code: ErrorCode.MaxNumberOfSignUpAttemptsExceeded,
        message: 'Max Number of sign up attempts exceeded.',
        details: {},
    };
}

/**
 * Wrong username password pair
 */
export type WrongUsernamePasswordPair = ErrorObject<typeof ErrorCode.WrongUsernamePasswordPair, {}>;

export function WrongUsernamePasswordPair(): Immutable<WrongUsernamePasswordPair> {
    return {
        name: 'WrongUsernamePasswordPair',
        code: ErrorCode.WrongUsernamePasswordPair,
        message: 'Wrong username password pair.',
        details: {},
    };
}

/**
 * Inactive account
 */
export type InactiveAccount = ErrorObject<typeof ErrorCode.InactiveAccount, {}>;

export function InactiveAccount(): Immutable<InactiveAccount> {
    return {
        name: 'InactiveAccount',
        code: ErrorCode.InactiveAccount,
        message: 'Inactive account.',
        details: {},
    };
}

/**
 * No account
 */
export type NoAccount = ErrorObject<typeof ErrorCode.NoAccount, {}>;

export function NoAccount(): Immutable<NoAccount> {
    return {
        name: 'NoAccount',
        code: ErrorCode.NoAccount,
        message: 'No account.',
        details: {},
    };
}

/**
 * Session object is invalid
 */
export type InvalidSession = ErrorObject<
    typeof ErrorCode.InvalidSession,
    {
        session: Session;
    }
>;

/**
 * Invalid session error object
 */
export function InvalidSession(session: Session): Immutable<InvalidSession> {
    return {
        name: 'InvalidSession',
        code: ErrorCode.InvalidSession,
        message: 'Session object is invalid',
        details: {
            session,
        },
    };
}

/**
 * Unauthenticated error object.
 */
export type Unauthenticated = ErrorObject<typeof ErrorCode.Unauthenticated>;

export function Unauthenticated(): Immutable<Unauthenticated> {
    return {
        name: 'Unauthenticated',
        code: ErrorCode.Unauthenticated,
        message: 'Unauthenticated.',
    };
}

export type OrganizationNotFoundError = ErrorObject<typeof ErrorCode.OrganizationNotFound>;
export function OrganizationNotFoundError(): Immutable<OrganizationNotFoundError> {
    return {
        name: 'OrganizationNotFoundError',
        code: ErrorCode.OrganizationNotFound,
        message: 'Unable to find the organization for the given token.',
    };
}

export type OperationNotAllowedError = ErrorObject<typeof ErrorCode.OperationNotAllowed>;
export function OperationNotAllowedError(): Immutable<OperationNotAllowedError> {
    return {
        name: 'OperationNotAllowed',
        code: ErrorCode.OperationNotAllowed,
        message: 'The operation is not allowed.',
    };
}

export type InvalidTokenError = ErrorObject<typeof ErrorCode.InvalidToken>;
export function InvalidTokenError(): Immutable<InvalidTokenError> {
    return {
        name: 'InvalidToken',
        code: ErrorCode.InvalidToken,
        message: 'Provided token is not valid.',
    };
}

export type ExpiredTokenError = ErrorObject<typeof ErrorCode.ExpiredToken>;
export function ExpiredTokenError(): Immutable<ExpiredTokenError> {
    return {
        name: 'ExpiredToken',
        code: ErrorCode.ExpiredToken,
        message: 'Provided token ihas expired.',
    };
}

export type FailedToRetrieveSessionError = ErrorObject<typeof ErrorCode.FailedToRetrieveSession>;
export function FailedToRetrieveSessionError(): Immutable<FailedToRetrieveSessionError> {
    return {
        name: 'FailedToRetrieveSessionError',
        code: ErrorCode.FailedToRetrieveSession,
        message: 'Failed to retrieve created session.',
    };
}

export function CheckTokenValidityError(userId: UUID): Immutable<CheckTokenValidityError> {
    return {
        name: 'CheckTokenValidityError',
        code: ErrorCode.CheckTokenValidityError,
        message: 'Failed to check token validity for user',
        details: {
            userId,
        },
    };
}

export type NaicsConfirmationError = ErrorObject<typeof ErrorCode.NaicsConfirmationError>;
export function NaicsConfirmationError(): Immutable<NaicsConfirmationError> {
    return {
        name: 'NaicsConfirmationError',
        code: ErrorCode.NaicsConfirmationError,
        message: 'Failed to update profile NIACs code.',
    };
}

export type NaicsCodeRequiresRefinementError = ErrorObject<
    typeof ErrorCode.NaicsCodeRequiresRefinementError
>;
export function NaicsCodeRequiresRefinementError(): Immutable<NaicsCodeRequiresRefinementError> {
    return {
        name: 'NaicsCodeRequiresRefinementError',
        code: ErrorCode.NaicsCodeRequiresRefinementError,
        message: 'NIACs code requires refinemnt.',
    };
}
