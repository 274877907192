import { defineEntityValidator, entity } from '@embroker/shotwell/core/entity/Entity';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { Money } from '@embroker/shotwell/core/types/Money';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { SKU } from '../../../analytics/types/SKU';
import { commonQuoteProps, Quote, QuoteDetails, QuoteOptions } from '../../entities/Quote';
import { WCGAUserInfo } from '../types/WCGAUserInfo';

export interface WCGAQuoteOptions extends QuoteOptions {
    /**
     * Boolean indicating whether Blanket Waiver of Subrogation is included
     */
    readonly blanketWoS: boolean;
    /**
     * Boolean indicating whether Blanket Waiver of Subrogation is recommended
     */
    readonly isWosRecommended: boolean;
}

export const WCGAQuoteOptions = {
    ...defineValidator<WCGAQuoteOptions>({
        effectiveDate: Joi.date(),
        blanketWoS: Joi.boolean().required(),
        isWosRecommended: Joi.boolean().required(),
    }),
};

export interface WCGAQuoteDetails extends QuoteDetails {
    /**
     * Insurer from GA Alliance selected for the quote
     */
    readonly insurerName: string;
    /**
     * Boolean indicating whether quote was OFAC rejected on purchase or not
     */
    readonly isOFACRejected: boolean;
}

export const WCGAQuoteDetails = {
    ...defineValidator<WCGAQuoteDetails>({
        insurerName: Joi.string().required().allow(''),
        isOFACRejected: Joi.boolean().optional().default(false),
    }),
};

/**
 * An entity representing quote for WCGA
 */
export interface WCGAQuote extends Quote {
    readonly options: WCGAQuoteOptions;
    readonly details: WCGAQuoteDetails;
    readonly userInfo?: WCGAUserInfo;
    readonly isRenewal: boolean;

    setQuoteDetails(details: Partial<WCGAQuoteDetails>): void;
}

export const WCGAQuote = entity<WCGAQuote, Quote>({
    validator: defineEntityValidator<WCGAQuote>({
        ...commonQuoteProps,
        options: WCGAQuoteOptions.schema.required(),
        details: WCGAQuoteDetails.schema,
        userInfo: WCGAUserInfo.schema.optional(),
        isRenewal: Joi.boolean().required(),
    }),
    setQuoteDetails(details: Partial<WCGAQuoteDetails>) {
        this.props.details = {
            ...this.props.details,
            ...details,
        };
    },
    inherits: Quote,
});

/**
 * Event for purchase of WCGA quote
 */
export interface WCGAQuotePurchased extends DomainEvent {
    readonly origin: 'WCGAQuote';
    readonly name: 'Purchased';
    readonly totalPremium: Money;
    readonly applicationId: UUID;
    sku?: SKU;
    readonly isRenewal: boolean;
}
