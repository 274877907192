import { container } from '@embroker/shotwell/core/di';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Immutable } from '@embroker/shotwell/core/types';
import { Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Joi } from '@embroker/shotwell/core/validation/schema';
import { createForm, useForm } from '@embroker/shotwell/view/hooks/useForm';
import {
    BoxLayout,
    CardLayout,
    GridLayout,
    SelectOptionAsyncType,
    StackLayout,
    Text,
    ColumnLayout,
} from '@embroker/ui-toolkit/v2';
import React, { useCallback, useMemo, useState } from 'react';
import { Naics } from '../../../../industries/view/components/Naics';
import { AppTypeCode } from '../../../../shopping/types/enums';
import {
    GetNaicsEligibleAppTypes,
    GetNaicsEligibleAppTypesRequest,
} from '../../../useCases/GetNaicsEligibleAppTypes';
import { BrokerTableHeader } from '../BrokerTableHeader';
import { NaicsAppetiteAppCard } from './components/NaicsAppetiteAppCard';

interface NaicsAppetiteData {
    naics: string;
}

interface AppCardInfo {
    appType: AppTypeCode;
    isInAppetite: boolean;
    isPartiallyInAppetite: boolean;
    cardTextToDisplay: string;
    isDefault: boolean;
}

const supportedAppTypes: AppTypeCode[] = [
    'AppTypeCodeListESP',
    'AppTypeCodeListPCoML',
    'AppTypeCodeListEverestLawyersProfessionalLiability',
    'AppTypeCodeListEmbrokerCrime',
    'AppTypeCodeListEmbrokerCyber',
];

const defaultAppCards = supportedAppTypes.map((appType) => {
    return {
        appType,
        isInAppetite: false,
        isPartiallyInAppetite: false,
        cardTextToDisplay: '',
        isDefault: true,
    };
});

const NaicsAppetiteForm = (setAppCardList: (data: Immutable<AppCardInfo[]>) => void) => {
    const isInAppetite = (appCardAppType: AppTypeCode, appTypes: AppTypeCode[]): boolean => {
        if (appTypes == null) {
            return false;
        } else {
            return appTypes.some((appType) => appType == appCardAppType);
        }
    };

    const checkTextToDisplay = (appCardAppType: AppTypeCode, appTypes: AppTypeCode[]): string => {
        switch (appCardAppType) {
            case 'AppTypeCodeListPCoML':
                if (appTypes != null) {
                    if (appTypes.includes('AppTypeCodeListManualDirectorsAndOfficers')) {
                        return 'Eligible for D&O only';
                    } else if (appTypes.includes('AppTypeCodeListManualEmploymentPractices')) {
                        return 'Eligible for EPLI only';
                    }
                }
                return 'In appetite';
            default:
                return 'In appetite';
        }
    };

    return createForm<NaicsAppetiteData>({
        fields: {
            naics: {
                type: 'selectasync',
                validator: Joi.string().allow(''),
            },
        },
        submit: async (data: NaicsAppetiteData) => {
            if (data.naics === '') {
                return Success(null);
            }

            const requestData = {
                naics: data.naics,
            } as GetNaicsEligibleAppTypesRequest;

            const getEligibleAppTypesResult = await execute(GetNaicsEligibleAppTypes, requestData);

            if (isErr(getEligibleAppTypesResult)) {
                container.get<Logger>(Log).error(getEligibleAppTypesResult.errors);
                return Failure(
                    InvalidArgument({ argument: 'Get eligible app types failed', value: data }),
                );
            }
            return Success(getEligibleAppTypesResult.value);
        },
        onSuccess(appTypes) {
            const appCards: AppCardInfo[] = [];
            defaultAppCards.map((appCard) => {
                const isAppCardPCOML = appCard.appType == 'AppTypeCodeListPCoML';
                let isPartiallyInAppetite = false;
                if (isAppCardPCOML) {
                    isPartiallyInAppetite =
                        isInAppetite('AppTypeCodeListManualDirectorsAndOfficers', appTypes) ||
                        isInAppetite('AppTypeCodeListManualEmploymentPractices', appTypes);
                }
                const cardTextToDisplay = checkTextToDisplay(appCard.appType, appTypes);
                appCards.push({
                    appType: appCard.appType,
                    isInAppetite: isInAppetite(appCard.appType, appTypes),
                    isPartiallyInAppetite: isPartiallyInAppetite,
                    cardTextToDisplay: cardTextToDisplay,
                    isDefault: appTypes == null,
                });
            });
            setAppCardList(appCards);
        },
        formatSubmitErrors(errors) {
            if (errors.length === 0) {
                return [];
            }
            return ['Sorry, data you entered is invalid.'];
        },
    });
};

export function BrokerNaicsAppetite() {
    const [appCardList, setAppCardList] = useState<Immutable<AppCardInfo[]>>(defaultAppCards);

    const optionsForm = useMemo(() => NaicsAppetiteForm(setAppCardList), []);

    const { submit, fields, setValue, value } = useForm(optionsForm, {
        naics: '',
    });

    const handleNaicsCodeTypeChange = useCallback(
        (newValue?: SelectOptionAsyncType) => {
            if (newValue) {
                setValue({
                    ...value,
                    naics: newValue.value,
                });
                submit();
            } else {
                setValue({
                    ...value,
                    naics: '',
                });
                submit();
            }
        },
        [value, setValue, submit],
    );

    return (
        <StackLayout>
            <CardLayout>
                <CardLayout.Header>
                    <ColumnLayout split="-1">
                        <BrokerTableHeader
                            isDashboardPage
                            title="Embroker Appetite Checker"
                            noItems
                        />
                    </ColumnLayout>
                </CardLayout.Header>
                <CardLayout.Body>
                    <BoxLayout>
                        <StackLayout gap="32">
                            <StackLayout data-e2e="check-appetite">
                                <Text>
                                    Check which coverages Embroker offers based on industry (NAICS
                                    code):
                                </Text>
                                <Naics
                                    {...fields.naics.props}
                                    initialValue={fields.naics.props.value}
                                    onChange={handleNaicsCodeTypeChange}
                                />
                            </StackLayout>
                            <GridLayout data-e2e="appetite-grid">
                                {appCardList.map((coverage: Immutable<AppCardInfo>) => {
                                    return (
                                        <NaicsAppetiteAppCard
                                            data-e2e={
                                                coverage.isInAppetite
                                                    ? 'in-appetite'
                                                    : 'out-of-appetite'
                                            }
                                            naicsCode={fields.naics.props.value ?? ''}
                                            key={coverage.appType}
                                            appType={coverage.appType}
                                            isInAppetite={coverage.isInAppetite}
                                            isPartiallyInAppetite={
                                                coverage.isPartiallyInAppetite ?? false
                                            }
                                            cardTextToDisplay={coverage.cardTextToDisplay}
                                            isDefault={coverage.isDefault}
                                        />
                                    );
                                })}
                            </GridLayout>
                        </StackLayout>
                    </BoxLayout>
                </CardLayout.Body>
            </CardLayout>
        </StackLayout>
    );
}
