import { container } from '@embroker/shotwell/core/di';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { FailedToGetAnswerFromFormValues, InvalidAnswerFromFormValue } from '../errors';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { Joi, SchemaType, defineValidator } from '@embroker/shotwell/core/validation/schema';
import { QuestionerQuestion, QuestionType } from './QuestionerQuestionType';
import { DateDefinition } from './OracleAnswerTypes/date';
import { LocationDefinition } from './OracleAnswerTypes/location';
import { RequireAtLeastOne } from '@embroker/ui-toolkit/v2';
import { RevenueDefinition } from './OracleAnswerTypes/revenue';
import { CurrencyDefinition } from './OracleAnswerTypes/currency';
import { NumberDefinition } from './OracleAnswerTypes/number';
import { IntegerDefinition } from './OracleAnswerTypes/integer';
import { TextDefinition } from './OracleAnswerTypes/text';
import { BooleanDefinition } from './OracleAnswerTypes/boolean';
import { DecimalDefinition } from './OracleAnswerTypes/decimal';
import { FundraisingRoundDefinition } from './OracleAnswerTypes/fundraisingRound';

// Some questions do not make sense as 'multiple' questions,
// We define them here so we can handle them silightly differently
export const ARRAY_TYPE_QUESTIONS: QuestionType[] = ['MULTISELECT', 'FILE'];

//
// AnswerTypes definition
export const AnswerTypes = [
    'TEXT',
    'NUMBER',
    'DATE',
    'BOOLEAN',
    'INTEGER',
    'ADDRESS',
    'FUNDING_ROUND',
    'REVENUE',
    'CURRENCY',
    'DECIMAL',
] as const;
export type AnswerType = (typeof AnswerTypes)[number];

export type OracleAnswerType = RequireAtLeastOne<{
    [key in AnswerKeysType]: unknown[];
}>;
export const OracleAnswerTypeSchema = Joi.object().pattern(
    Joi.string(),
    Joi.array().items(Joi.any()),
);

// For reference: Oracle answer value types are defined here
// https://github.com/embroker/oracle/blob/98100c961a7f2347c58aeec87c2c8d38c5f31c65/proto/oracle_model.proto#L41
export const AnswerKeysTypes = [
    'text',
    'number',
    'date',
    'boolean',
    'integer',
    'address',
    'funding_round',
    'revenue',
    'currency',
    'decimal',
] as const;
export type AnswerKeysType = (typeof AnswerKeysTypes)[number];

export type OracleAnswerTypeDefinition = {
    answerKeyType: AnswerKeysType;
    schemaFunctions: {
        validator: SchemaType<any>;
        serializeAnswer: (formData: unknown) => unknown;
        deserializeAnswer: (formData: unknown) => unknown[] | undefined;
    };
};

export const answerTypeDefinitionMap: Record<AnswerType, OracleAnswerTypeDefinition> = {
    TEXT: TextDefinition,
    NUMBER: NumberDefinition,
    DATE: DateDefinition,
    BOOLEAN: BooleanDefinition,
    INTEGER: IntegerDefinition,
    ADDRESS: LocationDefinition,
    FUNDING_ROUND: FundraisingRoundDefinition,
    REVENUE: RevenueDefinition,
    CURRENCY: CurrencyDefinition,
    DECIMAL: DecimalDefinition,
};
export interface OracleAnswer {
    key: string;
    value: OracleAnswerType;
    type: AnswerType;
    multiplicity: number;
}

const OracleAnswerSchema = Joi.object({
    key: Joi.string().required(),
    value: Joi.object()
        .pattern(Joi.string().valid(...AnswerKeysTypes), Joi.any().required())
        .optional(),
    multiplicity: Joi.number().required(),
    type: Joi.string()
        .required()
        .valid(...AnswerTypes),
});

export const OracleAnswer = {
    ...defineValidator<OracleAnswer>(OracleAnswerSchema),
    create(oracleAnswer: unknown) {
        return OracleAnswer.validate(oracleAnswer);
    },
    getAnswerFromQuestionnaire(
        formValue: unknown,
        questionerQuestion: QuestionerQuestion,
    ): OracleAnswer | InvalidAnswerFromFormValue {
        const answerValue = Array.isArray(formValue) ? (formValue as unknown[]) : [formValue];

        const { answerKeyType, schemaFunctions } =
            answerTypeDefinitionMap[questionerQuestion.answer_type];

        const { validator, serializeAnswer } = schemaFunctions;

        const serializeAnswers = answerValue.map((value) => serializeAnswer(value));
        // Oracle answers will always be an array of values, validate as array
        const validateAnswerValue = Joi.array().items(validator).validate(serializeAnswers);

        if (validateAnswerValue.error) {
            return InvalidAnswerFromFormValue();
        }

        const value = { [answerKeyType]: validateAnswerValue.value } as OracleAnswerType;

        return {
            key: questionerQuestion.key,
            value,
            type: questionerQuestion.answer_type,
            multiplicity: questionerQuestion.multiplicity,
        };
    },
    getAnswersFromQuestionnaire(
        formData: { [key: string]: unknown },
        questionerQuestions: QuestionerQuestion[],
    ): { answers: OracleAnswer[]; errors: FailedToGetAnswerFromFormValues[] } {
        const answers: OracleAnswer[] = [];
        const errors: FailedToGetAnswerFromFormValues[] = [];

        Object.keys(formData).forEach((questionKey) => {
            const value = formData[questionKey];
            if (value === undefined) {
                // If value is undefined, there is no answer to save
                return;
            }

            const questionerQuestion = questionerQuestions.find(({ key }) => key === questionKey);
            if (!questionerQuestion) {
                errors.push(FailedToGetAnswerFromFormValues({ questionKey, value }));
                return;
            }

            const answer = OracleAnswer.getAnswerFromQuestionnaire(value, questionerQuestion);
            const answerResult = OracleAnswer.create(answer);

            if (!isOK(answerResult)) {
                if (process.env.NODE_ENV === 'production') {
                    // This scenario should never happen we are being very defensive here
                    // If an answer type fails validation before being sent to Oracle service then it has incorectly passed validation in the form
                    //
                    // We still want to surface an error in the logs to catch this scenario
                    container
                        .get<Logger>(Log)
                        .error(
                            `Cannot get answer from questionnaire. Form value: ${value}. Form value type: ${typeof value}. Question: ${JSON.stringify(
                                questionerQuestion,
                            )}.`,
                        );
                } else {
                    errors.push(
                        FailedToGetAnswerFromFormValues({
                            questionKey,
                            value,
                        }),
                    );
                }
                return;
            }

            answers.push(answerResult.value as OracleAnswer);
        });

        return { answers, errors };
    },
};

export const deserializeOracleAnswer = (question: QuestionerQuestion) => {
    if (!question.current_answer) {
        // boolean checkboxes with no current answer should default to false not undefined
        if (question.type === 'BOOLEAN_CHECKBOX') {
            return false;
        }
        return;
    }

    const { type, multiplicity, value } = question.current_answer;
    const {
        answerKeyType,
        schemaFunctions: { deserializeAnswer },
    } = answerTypeDefinitionMap[type];

    const deserializeAnswers = deserializeAnswer(value[answerKeyType]);
    if (deserializeAnswers === undefined) {
        return;
    }

    const formatedValues = deserializeAnswers.map((answerValue) =>
        formatAnswerForQuestionType(question, answerValue),
    );

    if (multiplicity === 1) {
        if (ARRAY_TYPE_QUESTIONS.includes(question.type)) {
            // Some questions take an array of values in the form itself, so we need to return the array for those when multiplicity === 1
            return formatedValues;
        }
        // In all other cases, this is a single value input, so return the 0th element
        return formatedValues[0];
    }

    return formatedValues;
};

const formatAnswerForQuestionType = (question: QuestionerQuestion, answer: unknown) => {
    if (question.type === 'YEAR' && typeof answer !== 'string') {
        // https://github.com/embroker/ui-toolkit/blob/684ad3919d6c319d5f4e9a91558af39edf750e2d/components/Input/YearInput.tsx#L65-L70
        // Our year input accepts only string & Date value types
        // Questions with type YEAR will have answer as type number, so we need to convert it to string
        return String(answer);
    }
    return answer;
};
