import React, { useContext, useEffect, useState } from 'react';

import { GetActiveOrganizationProfile } from '@app/userOrg/useCases/GetActiveOrganizationProfile';
import { AppContext } from '@app/view/AppContext';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    BoxLayout,
    ColumnLayout,
    CoverLayout,
    Image,
    StackLayout,
    Text,
    useResponsive,
} from '@embroker/ui-toolkit/v2';
import { GetBillingInfo } from '../../useCases/GetBillingInfo';

interface PaymentsErrorPageProps {
    isInvoiceError?: boolean;
}

export function PaymentsErrorPage({ isInvoiceError }: PaymentsErrorPageProps) {
    const [organizationEmail, setOrganizationEmail] = useState('');
    const { result: orgInfoResult } = useUseCase(GetActiveOrganizationProfile);

    const [billingEmail, setBillingEmail] = useState<string | undefined>();

    const { activeSession } = useContext(AppContext);
    const organizationId: UUID = activeSession.organizationId as UUID;
    const { result: billingInfoResult } = useUseCase(GetBillingInfo, { organizationId });

    const isLowerThanDesktop = useResponsive({ screenWidth: { smallerThan: 'desktop' } });

    const spacing = isLowerThanDesktop ? '16' : '64';
    const headerText = isInvoiceError ? 'Your invoice will be ready soon.' : 'We’re sorry!';

    useEffect(() => {
        if (orgInfoResult && isOK(orgInfoResult)) {
            setOrganizationEmail(orgInfoResult.value.organization.email ?? '');
        }
    }, [orgInfoResult]);

    useEffect(() => {
        if (billingInfoResult && isOK(billingInfoResult)) {
            setBillingEmail(billingInfoResult.value.billingEmailAddress);
        }
    }, [billingInfoResult]);

    function getBodyText(): JSX.Element {
        if (isInvoiceError) {
            const email = billingEmail ?? organizationEmail;
            return (
                <StackLayout gap="12">
                    <Text style="body 1">
                        Thank you for your patience. Our electronic payment vendor, Ascend, will
                        send an email to {email} with your invoice and instructions for payment
                        within 2-3 business days.
                    </Text>
                    <Text style="body 1">
                        Please submit payment immediately upon receipt to ensure continued coverage.
                    </Text>
                </StackLayout>
            );
        }

        return (
            <StackLayout gap="12">
                <Text style="body 1">
                    Unfortunately, we’re unable to connect to our payment processor.
                </Text>
                <Text style="body 1">
                    We will reach out to you via email in 1-2 business days. This email will include
                    a copy of your invoice and instructions on how to pay your bill.{' '}
                </Text>
            </StackLayout>
        );
    }

    return (
        <CoverLayout>
            <BoxLayout gap={spacing}>
                <BoxLayout gap={spacing}>
                    <BoxLayout gap={spacing}>
                        <ColumnLayout
                            center
                            gap="24"
                            responsive={{ containerWidth: { smallerThan: 'tablet' } }}
                        >
                            <StackLayout center>
                                <Image height={193} width={147} name="shotwell-service" />
                            </StackLayout>
                            <StackLayout gap="16">
                                <Text style="heading 1" data-e2e="page-title">
                                    {headerText}
                                </Text>
                                <StackLayout
                                    gap="32"
                                    data-e2e="page-body"
                                    className="u-3/5@large-desktop"
                                >
                                    {getBodyText()}
                                    <StackLayout gap="16">
                                        <Text style="heading 4">
                                            In the meantime, here’s what you can do:
                                        </Text>
                                        <ColumnLayout
                                            gap="24"
                                            responsive={{
                                                containerWidth: { smallerThan: 'mobile' },
                                            }}
                                        >
                                            <StackLayout>
                                                <a href="/summary">Go to your dashboard</a>
                                                {!isInvoiceError && (
                                                    <a href="#chat-now">Contact us</a>
                                                )}
                                            </StackLayout>
                                        </ColumnLayout>
                                    </StackLayout>
                                </StackLayout>
                            </StackLayout>
                        </ColumnLayout>
                    </BoxLayout>
                </BoxLayout>
            </BoxLayout>
        </CoverLayout>
    );
}
