import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UserOnboardingDetails } from '../../types/UserOnboardingDetails';
import { Nullable } from '@embroker/ui-toolkit/v2';
import { OnboardingPrefillQuestionnaireData } from '../../types/OnboardingPrefillQuestionnaireData';

export interface UserOnboardingRepository {
    setUserOnboardingDetails(
        userOnboardingDetails: UserOnboardingDetails,
    ): AsyncResult<void, never>;

    removeUserOnboardingDetails(): AsyncResult<void, never>;

    getUserOnboardingDetails(): AsyncResult<Nullable<UserOnboardingDetails>, never>;

    setUserOnboardingQuestionnaireData(
        questionnaireData: OnboardingPrefillQuestionnaireData,
    ): AsyncResult<void, never>;

    removeUserOnboardingQuestionnaireData(): AsyncResult<void, never>;

    getUserOnboardingQuestionnaireData(): AsyncResult<
        Nullable<OnboardingPrefillQuestionnaireData>,
        never
    >;
}

export const UserOnboardingRepository = Symbol('UserOnboardingRepository');
