export const MedicalExpenses = 'MEDEX';
export const DamageToPremisesRentedToYou = 'FIRDM';
export const AdditionalInsuredByContract = 'AICON';
export const GeneralAggregateLimit = 'GENAG';
export const ProductsCompletedOperationsAggLimit = 'PRDCO';
export const PersonalAndAdvertisingInjury = 'PIADV';
export const BusinessLiabilityLimit = 'EAOCC';
export const EmploymentPracticesLiability = 'EPLI';

const PerOccurrenceLimit = 'Per Occurrence Limit';
const AggregateLimit = 'Aggregate Limit';

const descriptionMap = {
    [MedicalExpenses]:
        'Covers medical expenses incurred due to accidents or injuries on the premises.',
    [DamageToPremisesRentedToYou]:
        'Covers damages to premises rented to you, typically including fire damage.',
    [PersonalAndAdvertisingInjury]:
        'Covers liability for personal injuries like slander or false advertising.',
    [ProductsCompletedOperationsAggLimit]:
        'Covers liability for products manufactured or operations completed by the insured.',
    [BusinessLiabilityLimit]: 'Covers liability for injury and property damage of others.',
};

export function GetCoverageLimitDescription(coverage: string): string {
    return descriptionMap[coverage as keyof typeof descriptionMap] || '';
}

const limitCodeMap = {
    [MedicalExpenses]: PerOccurrenceLimit,
    [DamageToPremisesRentedToYou]: PerOccurrenceLimit,
    [PersonalAndAdvertisingInjury]: PerOccurrenceLimit,
    [ProductsCompletedOperationsAggLimit]: PerOccurrenceLimit,
    [GeneralAggregateLimit]: AggregateLimit,
    [BusinessLiabilityLimit]: PerOccurrenceLimit,
    [AdditionalInsuredByContract]: PerOccurrenceLimit,
};

export function GetCoverageLimitCode(coverage: string): string {
    return limitCodeMap[coverage as keyof typeof limitCodeMap] || '';
}
