import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';

const ClickEventNameList = [
    'Redirect from Ascend - Page Loaded',
    'Redirect from Ascend - Update your billing email address clicked',
] as const;
type ReturnFromAscendEventNameList = typeof ClickEventNameList;
export type ReturnFromAscendEventName = ReturnFromAscendEventNameList[number];

export enum ReturnFromAscendEventType {
    pageLoaded = 'pageLoaded',
    updateYourBillingEmailClicked = 'updateYourBillingEmailClicked',
}

export interface ReturnFromAscendEvent extends DomainEvent {
    name: 'ReturnFromAscendEvent';
    clickEventName: ReturnFromAscendEventName;
}

export interface PublishReturnFromAscendEvent extends UseCase {
    execute(request: PublishReturnFromAscendEventRequest): AsyncResult<void>;
}

export interface PublishReturnFromAscendEventRequest {
    eventType: ReturnFromAscendEventType;
}

@injectable()
class PublishReturnFromAscendEventUseCase extends UseCase implements PublishReturnFromAscendEvent {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('Policy/PublishReturnFromAscendEventUseCase');

    /**
     * Constructor for PublishReturnFromAscendEvent class instance
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    /**
     * Executes the PublishReturnFromAscendEvent use case.
     */
    public async execute({ eventType }: PublishReturnFromAscendEventRequest): AsyncResult<void> {
        let clickEventName: ReturnFromAscendEventName | null = null;

        switch (eventType) {
            case ReturnFromAscendEventType.pageLoaded: {
                clickEventName = 'Redirect from Ascend - Page Loaded';
                break;
            }

            case ReturnFromAscendEventType.updateYourBillingEmailClicked: {
                clickEventName = 'Redirect from Ascend - Update your billing email address clicked';
                break;
            }
        }

        if (clickEventName) {
            const event: ReturnFromAscendEvent = {
                origin: 'Policy',
                name: 'ReturnFromAscendEvent',
                clickEventName,
                createdAt: new Date(Date.now()),
                id: UUID.create(),
            };
            await this.eventBus.publish(event);
        }

        return Success();
    }
}

export const PublishReturnFromAscendEvent: UseCaseClass<PublishReturnFromAscendEvent> =
    PublishReturnFromAscendEventUseCase;
