import { Immutable } from '@embroker/shotwell/core/types';
import { equalUUID, UUID } from '@embroker/shotwell/core/types/UUID';
import { TextButton, StackLayout, ColumnLayout, Text } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { LocationData } from './Location';
import { LocationWithMenu } from './LocationWithMenu';

export interface LocationListProps {
    data?: Immutable<LocationData[]>;
    onChange: (list: Immutable<LocationData[]>) => void;
    messages: any;
}

export function LocationList({ data, onChange, messages }: LocationListProps) {
    const handleLocationChange = (location: LocationData) => {
        onChange(
            (data ?? []).map((item) => {
                return equalUUID(item.id, location.id) ? location : item;
            }),
        );
    };

    const handleLocationRemove = (id: UUID) => () => {
        onChange(
            (data ?? []).filter((item) => {
                return !equalUUID(item.id, id);
            }),
        );
    };

    return (
        <React.Fragment>
            {messages.length ? <Text color="negative-500">{messages[0]}</Text> : null}
            <StackLayout gap="none" key="location-list">
                {(data || []).map((item) => {
                    return (
                        <StackLayout key={item.id}>
                            <LocationWithMenu data={item} onChange={handleLocationChange} />
                            <ColumnLayout split="-1">
                                <TextButton
                                    onClick={handleLocationRemove(item.id)}
                                    data-e2e="remove-location"
                                >
                                    Remove
                                </TextButton>
                            </ColumnLayout>
                        </StackLayout>
                    );
                })}
            </StackLayout>
        </React.Fragment>
    );
}
