import { SelectInput, SelectInputProps, Tooltip } from '@embroker/ui-toolkit/v2';
import React from 'react';

export interface DropdownWithTooltipProps<T> extends SelectInputProps<T, false> {
    defaultTooltip: string;
}

interface TooltipItem {
    readonly tooltip?: string;
    readonly value?: unknown;
}

export const DropdownWithTooltip = React.forwardRef(function DropdownWithTooltip<T>(
    { defaultTooltip, ...props }: DropdownWithTooltipProps<T>,
    ref: React.Ref<HTMLInputElement>,
) {
    const items = props.items as unknown[];

    const matchingItem = items.find((item) => {
        const tooltipItem = item as TooltipItem;
        if (tooltipItem.value && tooltipItem.value === props.value) {
            return true;
        }

        return false;
    });

    let matchingTooltip: string = defaultTooltip;

    if (matchingItem) {
        const match = matchingItem as TooltipItem;

        if (match.tooltip?.length) {
            matchingTooltip = match.tooltip;
        }
    }

    return (
        <div className="em-tooltip-dropdown">
            <SelectInput ref={ref} {...(props as SelectInputProps<unknown, false>)} />
            <Tooltip text={<span dangerouslySetInnerHTML={{ __html: matchingTooltip }} />} />
        </div>
    );
});
