import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { redirectToWhoopsPage } from '../../../view/errors';
import { map, route, withData } from 'navi';
import React from 'react';
import { ESPRenewalQuote } from '../../espRenewal/entities/ESPRenewalQuote';
import { GetQuote } from '../../espRenewal/useCases/GetQuote';
import { ESPRenewalQuoteLandingPage } from '../../espRenewal/view/components/ESPRenewalQuoteLandingPage';
import { ESPRenewalThankYouPage } from '../../espRenewal/view/components/ESPRenewalThankYouPage';

const espRenewalWizardRouteHandler = map(async (request) => {
    const { applicationId } = request.query;
    if (!UUID.check(applicationId)) {
        return redirectToWhoopsPage([
            InvalidArgument({ argument: 'applicationId', value: applicationId, validator: 'UUID' }),
        ]);
    }

    return route({
        title: 'ESP Renewals',
        view: <ESPRenewalQuoteLandingPage applicationId={applicationId} />,
    });
});

export const espRenewalRoutes = {
    '/': map((request) =>
        withData(
            { url: request.mountpath, page: undefined, fullscreen: true },
            espRenewalWizardRouteHandler,
        ),
    ),

    '/:page': map((request) =>
        withData(
            { url: request.mountpath, page: request.params.page, fullscreen: true },
            espRenewalWizardRouteHandler,
        ),
    ),

    '/thank-you': map(async (request) => {
        const { applicationId } = request.query;
        if (!UUID.check(applicationId)) {
            return redirectToWhoopsPage([
                InvalidArgument({
                    argument: 'applicationId',
                    value: applicationId,
                    validator: 'UUID',
                }),
            ]);
        }

        const getQuoteResult = await execute(GetQuote, {
            applicationId: applicationId,
        });

        if (isErr(getQuoteResult)) {
            return redirectToWhoopsPage(getQuoteResult.errors);
        }

        const quote = getQuoteResult.value.quote as ESPRenewalQuote;

        return route({
            title: 'PCoML quotes thank you',
            view: (
                <ESPRenewalThankYouPage
                    isDNOSelected={
                        'dno' in quote.options.coverage && quote.options.coverage['dno'].isSelected
                    }
                    isEPLISelected={
                        'epli' in quote.options.coverage &&
                        quote.options.coverage['epli'].isSelected
                    }
                    isEOSelected={
                        'eo' in quote.options.coverage && quote.options.coverage['eo'].isSelected
                    }
                    isTechCyberSelected={
                        'techCyber' in quote.options.coverage &&
                        quote.options.coverage['techCyber'].isSelected
                    }
                    isFiduciarySelected={
                        'fiduciary' in quote.options.coverage &&
                        quote.options.coverage['fiduciary'].isSelected
                    }
                />
            ),
        });
    }),
};
