import { inject } from '@embroker/shotwell/core/di';
import { Aborted, InvalidArgument, OperationFailed, Timeout } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import {
    AsyncResult,
    handleOperationFailure,
    isErr,
    Success,
} from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { CalculateSKU } from '../../../analytics/useCases/CalculateSKU';
import { ApplicationRepository } from '../../../shopping/repositories/ApplicationRepository';
import { TasksRepository } from '../../../tasks/repositories';
import { WCGAQuote } from '../entities/WCGAQuote';
import { WCGAQuoteRepository } from '../repositories/WCGAQuoteRepository';

/**
 * Request data for GetWCGAQuote use case
 */
export interface GetWCGAQuoteRequest {
    applicationId: UUID;
    startDate: Date;
    includeBlanketWoS: boolean;
    getRecommended: boolean;
    abortSignal: AbortSignal;
    maxPollingRetries?: number;
    pollingRetryIntervalInMilliseconds?: number;
}

/**
 * Response data for GetWCGAQuote use case
 */
export interface GetWCGAQuoteResponse {
    quote: Immutable<WCGAQuote>;
}

/**
 * GetWCGAQuote use case is used to create WCGA quote for given insurance app and selected time
 */
export interface GetWCGAQuote extends UseCase {
    execute(
        request: GetWCGAQuoteRequest,
    ): AsyncResult<GetWCGAQuoteResponse, OperationFailed | InvalidArgument | Aborted | Timeout>;
}

class GetWCGAQuoteUseCase extends UseCase implements GetWCGAQuote {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('WCGAQuote/GetWCGAQuote');

    /**
     * Constructor for GetWCGAQuote use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(WCGAQuoteRepository) private wcgaQuoteRepository: WCGAQuoteRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
        @inject(CalculateSKU.type) private calculateSku: CalculateSKU,
    ) {
        super(eventBus);
    }

    /**
     * Executes GetWCGAQuote use case
     * Input is of GetWCGAQuoteRequest type
     * @returns InvalidArgument if there is no such insurance application
     * @returns OperationFailed if repository failed to get a quote
     */
    public async execute({
        applicationId,
        startDate,
        includeBlanketWoS,
        getRecommended,
        abortSignal,
        maxPollingRetries = 120,
        pollingRetryIntervalInMilliseconds = 1000,
    }: GetWCGAQuoteRequest): AsyncResult<
        GetWCGAQuoteResponse,
        OperationFailed | InvalidArgument | Aborted | Timeout
    > {
        const quoteResult = await this.wcgaQuoteRepository.requote({
            applicationId,
            startDate,
            includeBlanketWoS,
            getRecommended,
        });

        if (isErr(quoteResult)) {
            return handleOperationFailure(quoteResult);
        }

        const pollForTaskStatusResult = await this.tasksRepository.pollForTaskStatus(
            quoteResult.value,
            abortSignal,
            maxPollingRetries,
            pollingRetryIntervalInMilliseconds,
        );
        if (isErr(pollForTaskStatusResult)) {
            return pollForTaskStatusResult;
        }

        const getCurrentQuoteResult = await this.wcgaQuoteRepository.getLast(applicationId);
        if (isErr(getCurrentQuoteResult)) {
            return getCurrentQuoteResult;
        }

        const getApplicationResult = await this.applicationRepository.getApplication(applicationId);
        if (isErr(getApplicationResult)) {
            return getApplicationResult;
        }

        const quote = getCurrentQuoteResult.value;
        const application = getApplicationResult.value;

        const skuResult = await this.calculateSku.execute({
            event: 'quote',
            applicationId: quote.applicationId,
        });

        application.onQuotePremium({
            totalPremium: quote.totalPayable,
            applicationId,
            isRenewal: application.isRenewal(),
            sku: skuResult.value,
        });
        await this.eventBus.publishEntityEvents(application);

        return Success({ quote: quote });
    }
}

export const GetWCGAQuote: UseCaseClass<GetWCGAQuote> = GetWCGAQuoteUseCase;
