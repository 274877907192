import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject } from '@embroker/shotwell/core/di';
import { DocumentRepository } from '../repositories/DocumentRepository';
import { GeneratedDocument } from '../entities/GeneratedDocument';

export interface GetApplicationDocumentFileKeyRequest {
    applicationId: UUID;
    fileName: string;
}

export interface GetApplicationDocumentFileKey extends UseCase {
    execute(
        request: GetApplicationDocumentFileKeyRequest,
    ): AsyncResult<string, InvalidArgument | OperationFailed>;
}

class GetApplicationDocumentFileKeyUseCase
    extends UseCase
    implements GetApplicationDocumentFileKey
{
    public static type = Symbol('Quote/GetApplicationDocumentFileKey');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(DocumentRepository) private documentRepository: DocumentRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
        fileName,
    }: GetApplicationDocumentFileKeyRequest): AsyncResult<
        string,
        InvalidArgument | OperationFailed
    > {
        const appDocumentFileKeyResult =
            await this.documentRepository.getApplicationDocumentGeneratedDocuments(applicationId);

        if (isErr(appDocumentFileKeyResult)) {
            return appDocumentFileKeyResult;
        }

        const generatedDocuments = appDocumentFileKeyResult.value;
        const document = generatedDocuments.find(
            (document: GeneratedDocument) => document.fileName === fileName,
        );

        if (document === undefined || document.fileKey === null) {
            return Failure(OperationFailed({ message: 'Application document not found.' }));
        }

        return Success(document.fileKey);
    }
}

export const GetApplicationDocumentFileKey: UseCaseClass<GetApplicationDocumentFileKey> =
    GetApplicationDocumentFileKeyUseCase;
