import { inject, injectable } from '@embroker/shotwell/core/di';
import { Aborted, InvalidArgument, OperationFailed, Timeout } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { CalculateSKU } from '../../../analytics/useCases/CalculateSKU';
import { TasksRepository } from '../../../tasks/repositories';
import { InvalidAnnualTechFee, QuoteOptionsApprovalNeeded } from '../../errors';
import { LPLQuote, LPLQuotePurchased } from '../entities/LPLQuote';
import { PurchaseQuoteNotConsistent } from '../errors';
import { LPLQuoteRepository } from '../repositories/LPLQuoteRepository';

interface PurchaseLPLRequest {
    quote: LPLQuote;
    abortSignal: AbortSignal;
    isRenewal: boolean;
    isStreamline: boolean;
}

export interface PurchaseLPL extends UseCase {
    execute(
        request: PurchaseLPLRequest,
    ): AsyncResult<
        void,
        | InvalidArgument
        | OperationFailed
        | PurchaseQuoteNotConsistent
        | InvalidAnnualTechFee
        | Timeout
        | Aborted
        | QuoteOptionsApprovalNeeded
    >;
}

@injectable()
class PurchaseLPLUseCase extends UseCase implements PurchaseLPL {
    public static type = Symbol('LPLQuote/PurchaseLPL');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(LPLQuoteRepository) private lplQuoteRepository: LPLQuoteRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
        @inject(CalculateSKU.type) private calculateSku: CalculateSKU,
    ) {
        super(eventBus);
    }

    public async execute({
        quote,
        abortSignal,
        isRenewal,
        isStreamline,
    }: PurchaseLPLRequest): AsyncResult<
        void,
        | OperationFailed
        | PurchaseQuoteNotConsistent
        | InvalidArgument
        | InvalidAnnualTechFee
        | Timeout
        | Aborted
        | QuoteOptionsApprovalNeeded
    > {
        const enqueueIsQuoteConsistentTaskResult =
            await this.lplQuoteRepository.enqueueIsQuoteConsistentTask(
                quote.applicationId,
                quote.id,
            );
        if (isErr(enqueueIsQuoteConsistentTaskResult)) {
            return enqueueIsQuoteConsistentTaskResult;
        }

        const isQuoteConsistentTaskResult = await this.tasksRepository.pollForTaskStatus(
            enqueueIsQuoteConsistentTaskResult.value.taskId,
            abortSignal,
        );
        if (isErr(isQuoteConsistentTaskResult)) {
            return isQuoteConsistentTaskResult;
        }

        try {
            const isQuoteConsistent = JSON.parse(isQuoteConsistentTaskResult.value);
            if (!isQuoteConsistent) {
                return Failure(PurchaseQuoteNotConsistent());
            }
        } catch (error: any) {
            return Failure(
                OperationFailed({
                    message: 'PurchaseLPL enqueueIsQuoteConsistentTask parsing error: ' + error,
                }),
            );
        }

        const purchaseResult = await this.lplQuoteRepository.purchaseLPL(
            quote.applicationId,
            quote.id,
        );
        if (isErr(purchaseResult)) {
            return purchaseResult;
        }

        const acceptQuoteResult = quote.accept();

        if (isErr(acceptQuoteResult)) {
            return Failure(
                OperationFailed({
                    message: 'PurchaseLPL quote.accept() failed',
                    errors: acceptQuoteResult.errors,
                }),
            );
        }

        const skuResult = await this.calculateSku.execute({
            event: 'purchase',
            applicationId: quote.applicationId,
        });

        const purchaseEvent: LPLQuotePurchased = {
            origin: 'LPLQuote',
            name: 'Purchased',
            id: quote.id,
            applicationId: quote.applicationId,
            createdAt: new Date(Date.now()),
            totalPremium: quote.totalPremium,
            sku: skuResult.value,
            isRenewal: isRenewal,
            isStreamline: isStreamline,
        };
        await this.eventBus.publish(purchaseEvent);

        return Success();
    }
}

export const PurchaseLPL: UseCaseClass<PurchaseLPL> = PurchaseLPLUseCase;
