import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { UserOnboardingStarted } from '../entities/User';

export interface PublishOnboardingStartedEvent extends UseCase {
    execute(): AsyncResult<void>;
}

@injectable()
class PublishOnboardingStartedEventUseCase
    extends UseCase
    implements PublishOnboardingStartedEvent
{
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/PublishOnboardingStartedEventUseCase');

    /**
     * Constructor for PublishOnboardingStartedEvent class instance
     * @param eventBus An event bus this Use Case will publish events to.
     */
    constructor(@inject(DomainEventBus) eventBus: DomainEventBus) {
        super(eventBus);
    }

    /**
     * Executes the PublishOnboardingStartedEvent use case.
     */
    public async execute(): AsyncResult<void> {
        const userOnboardingStartedEvent: UserOnboardingStarted = {
            origin: 'User',
            name: 'OnboardingStarted',
            createdAt: new Date(Date.now()),
            id: UUID.create(),
        };

        await this.eventBus.publish(userOnboardingStartedEvent);

        return Success();
    }
}

export const PublishOnboardingStartedEvent: UseCaseClass<PublishOnboardingStartedEvent> =
    PublishOnboardingStartedEventUseCase;
