import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { NAICSRepository } from '../repositories/NAICSRepository/index';

export interface GetNAICSCodesByGroupRequest {
    readonly naicsGroup: string;
}

export interface GetNAICSCodesByGroupResponse {
    readonly naicsList: Immutable<string[]>;
}

export interface GetNAICSCodesByGroup extends UseCase {
    execute(
        request: GetNAICSCodesByGroupRequest,
    ): AsyncResult<GetNAICSCodesByGroupResponse, OperationFailed | InvalidArgument>;
}

@injectable()
class GetNAICSCodesByGroupUseCase extends UseCase implements GetNAICSCodesByGroup {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/GetNAICSCodesByGroup');

    /**
     * Constructor for GetNAICSCodesByGroup use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param naicsRepository is a naics repository used to search naics
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(NAICSRepository) private naicsRepository: NAICSRepository,
    ) {
        super(eventBus);
    }

    /**
     * Executes GetNAICSCodesByGroup use case
     * Input is of GetNAICSCodesByGroupRequest type
     * @returns data of GetNAICSCodesByGroupResponse type if execution was successful
     */
    public async execute({
        naicsGroup,
    }: GetNAICSCodesByGroupRequest): AsyncResult<
        GetNAICSCodesByGroupResponse,
        OperationFailed | InvalidArgument
    > {
        const getNaicsCodesInGroupResult = await this.naicsRepository.getNaicsCodesInGroup(
            naicsGroup,
        );
        if (isErr(getNaicsCodesInGroupResult)) {
            return getNaicsCodesInGroupResult;
        }
        return Success<GetNAICSCodesByGroupResponse>({
            naicsList: getNaicsCodesInGroupResult.value.naicsCodes,
        });
    }
}

export const GetNAICSCodesByGroup: UseCaseClass<GetNAICSCodesByGroup> = GetNAICSCodesByGroupUseCase;
