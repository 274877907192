import { container } from '@embroker/shotwell/core/di';
import { Log, Logger } from '@embroker/shotwell/core/logging/Logger';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { useUseCaseTrigger } from '@embroker/shotwell/view/hooks/useUseCaseTrigger';
import {
    BoxLayout,
    Button,
    ColumnLayout,
    CopyToClipboard,
    InsetBox,
    Modal,
    ModalActions,
    ModalState,
    ScrollBox,
    Spinner,
    StackLayout,
    StatusLabel,
    Text,
    TextButton,
    Tooltip,
} from '@embroker/ui-toolkit/v2';
import React, { useEffect, useMemo, useState } from 'react';
import { GetApplicationShareToken } from '../../../../shopping/useCases/GetApplicationShareToken';
import { PrintStartedApplication } from '../../../../shopping/useCases/PrintStartedApplication';
import { SelectOrganization } from '../../../../userOrg/useCases/SelectOrganization';
import { useNavigation } from '../../../../view/hooks/useNavigation';

export interface RenewNowModalProps {
    modal: ModalState & ModalActions;
    applicationId: UUID;
    organizationId: UUID;
}

export function RenewNowModal({ modal, applicationId, organizationId }: RenewNowModalProps) {
    const { navigate } = useNavigation();
    const [shareToken, setShareToken] = useState<string>();

    const abortController = useMemo(() => {
        return new AbortController();
    }, []);

    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, [abortController]);

    const {
        result,
        isLoading: isDownloading,
        trigger: downloadDocument,
    } = useUseCaseTrigger(PrintStartedApplication, {
        applicationId,
        abortSignal: abortController.signal,
    });

    useEffect(() => {
        if (result) {
            if (isOK(result)) {
                const pdfTab = window.open(
                    result.value.documentUrl,
                    '_blank',
                    'noopener, noreferrer',
                );
                pdfTab?.focus();
            } else {
                container.get<Logger>(Log).error(result);
            }
        }
    }, [result]);

    const handleDownloadButton = async () => {
        await execute(SelectOrganization, { organizationId });
        downloadDocument();
    };

    const handleGoToAppButton = async () => {
        await execute(SelectOrganization, { organizationId });
        navigate(
            URI.build('/shopping/application/basic-info', {
                applicationId,
            }),
        );
    };

    useEffect(() => {
        let isCanceled = false;
        execute(GetApplicationShareToken, { applicationId }).then((result) => {
            if (isCanceled) {
                return;
            }
            if (isOK(result)) {
                setShareToken(result.value.shareToken);
            } else {
                container.get<Logger>(Log).error(result);
            }
        });
        return () => {
            isCanceled = true;
        };
    }, [applicationId]);

    return (
        <Modal {...modal} size="large" appearance="seamless">
            <ScrollBox>
                <BoxLayout gap="48" data-e2e="renew-now-modal">
                    {isDownloading && <Spinner />}
                    <StackLayout gap="24">
                        <Text style="heading 3">Choose a renewal flow</Text>
                        <Text>
                            You can renew the policy with your client by following one of the two
                            paths outlined below. Once you’re done with either one of them, click
                            ‘Go to app’ button.
                        </Text>
                        <Text>
                            Alternatively, you can click the ‘Go to app’ button directly and fill
                            out the renewal application yourself.
                        </Text>

                        <ColumnLayout responsive={{ containerWidth: { smallerThan: 'mobile' } }}>
                            <InsetBox className="u-1/2@mobile u-1/1">
                                <StackLayout>
                                    <StackLayout gap="32">
                                        <Text style="heading 5">
                                            Share Embroker Renewal App
                                            <Tooltip
                                                text="Copy the link below (by clicking the icon) and share it with your client. They will be able to access a pre-filled application by pasting the link in their browser. Once the client completes the application, we'll notify you via email. You will be able to review their completed application and submit it to get the quote (the client will not see the quote)."
                                                iconSize="small"
                                            />
                                        </Text>
                                        <CopyToClipboard
                                            value={getShareableRenewalUrl(shareToken)}
                                        />
                                        <ul>
                                            <li>
                                                <Text style="body 2">
                                                    Share the Embroker renewal app with the Insured
                                                    (Copy & Paste link above)
                                                </Text>
                                            </li>
                                            <li>
                                                <Text style="body 2">
                                                    Review the completed renewal app submitted by
                                                    the Insured
                                                </Text>
                                            </li>
                                            <li>
                                                <Text style="body 2">
                                                    Configure and review quote
                                                </Text>
                                            </li>
                                            <li>
                                                <Text style="body 2">
                                                    Have Signature Packet executed by the Insured
                                                </Text>
                                            </li>
                                            <li>
                                                <Text style="body 2">Bind the policy</Text>
                                            </li>
                                        </ul>
                                    </StackLayout>

                                    <StatusLabel type="green">Recommended</StatusLabel>
                                </StackLayout>
                            </InsetBox>

                            <InsetBox className="u-1/2@mobile u-1/1">
                                <StackLayout gap="32">
                                    <Text style="heading 5"> Download Application Document </Text>

                                    <TextButton icon="download" onClick={handleDownloadButton}>
                                        Download application document
                                    </TextButton>
                                    <ul>
                                        <li>
                                            <Text style="body 2">
                                                Download renewal application (PDF version)
                                            </Text>{' '}
                                        </li>
                                        <li>
                                            <Text style="body 2">Send it to the Insured</Text>{' '}
                                        </li>
                                        <li>
                                            <Text style="body 2">
                                                Review the completed renewal app
                                            </Text>{' '}
                                        </li>
                                        <li>
                                            <Text style="body 2">
                                                Update client information and submit app in the
                                                portal
                                            </Text>{' '}
                                        </li>
                                        <li>
                                            <Text style="body 2">Configure and review quote</Text>{' '}
                                        </li>
                                        <li>
                                            <Text style="body 2">
                                                Have Signature Packet executed by the Insured
                                            </Text>{' '}
                                        </li>
                                        <li>
                                            <Text style="body 2">Bind the policy</Text>
                                        </li>
                                    </ul>
                                </StackLayout>
                            </InsetBox>
                        </ColumnLayout>

                        <ColumnLayout
                            responsive={{ containerWidth: { smallerThan: 'mobile' } }}
                            split="-1"
                        >
                            <Button onClick={handleGoToAppButton}>Go to app</Button>
                        </ColumnLayout>
                    </StackLayout>
                </BoxLayout>
            </ScrollBox>
        </Modal>
    );
}

function getShareableRenewalUrl(token: string | undefined) {
    return `${window.location.host}` + URI`/renewal/application?token=${token}`;
}
