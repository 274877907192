import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { PolicyFilterRepository } from '../../policy/repositories/PolicyFilterRepository';
import { isAuthenticated } from '../entities/Session';
import { InvalidTokenError, ExpiredTokenError, FailedToRetrieveSessionError } from '../errors';
import { SessionRepository } from '../repositories/SessionRepository';
import { Token } from '../types/Token';

export interface ProspectUserLoginByTokenResponse {
    bundleApplicationId?: UUID;
}

export interface ProspectUserLoginByToken extends UseCase {
    execute(
        token: string,
    ): AsyncResult<
        ProspectUserLoginByTokenResponse,
        InvalidArgument | InvalidTokenError | ExpiredTokenError | FailedToRetrieveSessionError
    >;
}

@injectable()
class ProspectUserLoginByTokenUseCase extends UseCase implements ProspectUserLoginByToken {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/ProspectUserLoginByToken');
    /**
     * Constructor for ProspectUserLoginByToken use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to
     * @param sessionRepository Session repository used to authenticate the user
     * @param policyFilterRepository Policy Filter repository used reset the policy filter
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(SessionRepository) private sessionRepository: SessionRepository,
        @inject(PolicyFilterRepository) private policyFilterRepository: PolicyFilterRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        token: string,
    ): AsyncResult<
        ProspectUserLoginByTokenResponse,
        InvalidArgument | InvalidTokenError | ExpiredTokenError | FailedToRetrieveSessionError
    > {
        if (!Token.check(token)) {
            return Failure(InvalidArgument({ argument: 'request', value: { token } }));
        }

        const result = await this.sessionRepository.loginByBundleToken(token);

        if (isErr(result)) {
            return result;
        }

        const session = result.value.session;

        if (!isAuthenticated(session)) {
            return Failure(InvalidArgument({ argument: 'request', value: { token } }));
        }

        // reset policy filter to default
        const defaultFilter = this.policyFilterRepository.getDefault();
        this.policyFilterRepository.save(defaultFilter);

        session.onLoginByUser();

        await this.eventBus.publishEntityEvents(session);

        return Success({
            bundleApplicationId: result.value.bundleApplicationId,
        });
    }
}

export const ProspectUserLoginByToken: UseCaseClass<ProspectUserLoginByToken> =
    ProspectUserLoginByTokenUseCase;
