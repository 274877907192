import { Nullable } from '@embroker/shotwell/core/types';
import {
    defineValidator,
    Joi,
    Schema,
    TypeChecker,
    Validator,
} from '@embroker/shotwell/core/validation/schema';

/**
 * Represents a monetary value.
 *
 * Amount is specified in minor currency units (e.g. cents for USD).
 */
export interface Driver {
    // id: UUID
    /**
     * Driver number
     */
    numDriver: number;
    /**
     * Drivers first and last name
     */
    name: string;
    /**
     * Drivers licence number
     */
    licenseNum: Nullable<string>;
    /**
     * State that issued drivers licence
     */
    issuingState: Nullable<string>;
}

export interface DriverValidator {
    /**
     * A Joi schema matching a valid Driver object.
     */
    readonly schema: Schema.ObjectSchema<Driver>;
    /**
     * Type predicate that checks if a given value can be used as Driver object.
     *
     * Use this only to do early returns. It's recommended to use validate()
     * before using an unknown value as Driver object as it normalizes the value
     * in addition to performing the same validation as check().
     */
    readonly check: TypeChecker<Driver>;
    /**
     * Validates and normalizes the given value to a Driver object.
     *
     * This should be used for all untrusted inputs to verify and, if required
     * (and possible), normalize the input.
     */
    readonly validate: Validator<Driver>;
}

export const Driver = {
    ...defineValidator<Driver>({
        numDriver: Joi.number(),
        issuingState: Joi.string().allow(null, ''),
        licenseNum: Joi.string().allow(null, ''),
        name: Joi.string().allow(null, ''),
    }),
    create({ numDriver, issuingState, licenseNum, name }: Driver) {
        const driver = {
            numDriver,
            issuingState,
            licenseNum,
            name,
        };
        return Driver.validate(driver);
    },
};
