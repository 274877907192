import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { AsyncResult, Failure, Success, isErr, isOK } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { GetManifestByVersionError, GetProductDefinitionError } from '../errors';
import {
    AvailableEndorsementsSpecs,
    DigitalEndorsementsRepository,
    EndorsementDefinition,
    EndorsementSpec,
} from '../repositories/digitalEndorsements';
import { InsuranceApplicationRepository } from '../repositories/insuranceApplication';
import { Policy } from '../../policy/entities/Policy';
import {
    PolicyStatusCurrent,
    PolicyStatusExpiring,
    PolicyStatusRenewal,
    PolicyStatusFuture,
    PolicyStatusActive,
} from '../../policy/types/PolicyStatus';
import {
    StructuralComponentTypeCodeListDirectorsAndOfficersLiabilitySection,
    StructuralComponentTypeCodeListEmploymentPracticesLiabilitySection,
    StructuralComponentTypeCodeListFiduciaryLiabilitySection,
    StructuralComponentTypeCodeListTechEOCyberLiabilitySection,
} from '../../policy/types/PolicySection';
import { InsuranceApplicationRestrictionRepository } from '../repositories/insuranceApplicationRestriction';
import { InsuranceApplicationRestriction } from '../types/InsuranceApplicationRestriction';
import { LimitAndRetention } from '../types';
import {
    CoverageRestriction,
    CoverageType,
    findCoverageRestrictionByType,
} from '../types/CoverageRestriction';
import { StructuralComponentTypeCodeListItem } from '@embroker/shotwell-api/enums';

const POSTBIND_ENDORSEMENT_TAG = 'postbind-endorsement';
const PREMIUM_BEARING_TAG = 'premium-bearing';

export interface GetEndorsementsRequest {
    manifestId: UUID;
    policy?: Policy;
    isBroker?: boolean;
}
export interface GetEndorsements extends UseCase {
    execute(
        request: GetEndorsementsRequest,
    ): AsyncResult<
        Immutable<EndorsementDefinition[]>,
        GetManifestByVersionError | GetProductDefinitionError | InvalidArgument | OperationFailed
    >;
}

@injectable()
class GetEndorsementsUseCase extends UseCase implements GetEndorsements {
    public static type = Symbol('DigitalEndorsements/GetEndorsements');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(InsuranceApplicationRepository)
        private insuranceApplicationRepository: InsuranceApplicationRepository,
        @inject(InsuranceApplicationRestrictionRepository)
        private insuranceApplicationRestrictionRepository: InsuranceApplicationRestrictionRepository,
        @inject(DigitalEndorsementsRepository)
        private digitalEndorsementsRepository: DigitalEndorsementsRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: GetEndorsementsRequest,
    ): AsyncResult<
        Immutable<EndorsementDefinition[]>,
        GetManifestByVersionError | GetProductDefinitionError | InvalidArgument | OperationFailed
    > {
        const availableEndorsementsSpecs =
            await this.digitalEndorsementsRepository.getAvailableEndorsements(request.manifestId);
        if (isErr(availableEndorsementsSpecs)) {
            return Failure(OperationFailed({ message: 'Failed to get available endorsements' }));
        }

        const activePolicyStatuses = [
            PolicyStatusCurrent,
            PolicyStatusExpiring,
            PolicyStatusRenewal,
            PolicyStatusFuture,
            PolicyStatusActive,
        ];

        let quotableCoverages: string[] = [];
        if (request.policy?.insuranceApplicationId) {
            const quotableCoveragesResult =
                await this.insuranceApplicationRepository.loadQuotableCoverages(
                    request.policy.insuranceApplicationId,
                );
            if (isOK(quotableCoveragesResult)) {
                quotableCoverages = [...quotableCoveragesResult.value];
            }
        }

        let uwReview: InsuranceApplicationRestriction | undefined;
        if (request.policy?.insuranceApplicationId) {
            const getReviewResult = await this.insuranceApplicationRestrictionRepository.get(
                request.policy.insuranceApplicationId,
            );
            if (isOK(getReviewResult)) {
                uwReview = getReviewResult.value as InsuranceApplicationRestriction;
            }
        }

        const isPostBindTagPresent = (documentSpec: EndorsementSpec): boolean =>
            Array.isArray(documentSpec.tags) &&
            documentSpec.tags.includes(POSTBIND_ENDORSEMENT_TAG);

        const isAddEPLIEndorsementAllowed = (
            documentSpec: EndorsementSpec,
            policy?: Policy,
        ): boolean => {
            if (documentSpec.docType !== 'espAddEpliCoverageEndorsement') {
                return true;
            }

            const restriction = findCoverageByDocType(
                'espAddEpliCoverageEndorsement',
                uwReview?.coverageRestrictions,
            );

            const isAllowed = restriction?.allowCoverage ?? true;

            return (
                policy?.status != undefined &&
                !policy.coverageSectionList.find(
                    (coverageSection) =>
                        coverageSection.type ===
                        StructuralComponentTypeCodeListEmploymentPracticesLiabilitySection,
                ) &&
                activePolicyStatuses.includes(policy.status) &&
                quotableCoverages.includes('ShoppingCoverageCodeListEmploymentPractices') &&
                isAllowed
            );
        };

        const isAddDOEndorsementAllowed = (
            documentSpec: EndorsementSpec,
            policy?: Policy,
        ): boolean => {
            if (documentSpec.docType !== 'espAddDoCoverage') {
                return true;
            }

            const restriction = findCoverageByDocType(
                'espAddDoCoverage',
                uwReview?.coverageRestrictions,
            );

            const isAllowed = restriction?.allowCoverage ?? true;

            return (
                policy?.status != undefined &&
                !policy.coverageSectionList.find(
                    (coverageSection) =>
                        coverageSection.type ===
                        StructuralComponentTypeCodeListDirectorsAndOfficersLiabilitySection,
                ) &&
                activePolicyStatuses.includes(policy.status) &&
                quotableCoverages.includes('ShoppingCoverageCodeListDirectorsAndOfficers') &&
                isAllowed
            );
        };

        const isAddFiduciaryEndorsementAllowed = (
            documentSpec: EndorsementSpec,
            policy?: Policy,
        ): boolean => {
            if (documentSpec.docType !== 'espAddFiduciaryLiabilityCoverageEndorsement') {
                return true;
            }

            const restriction = findCoverageByDocType(
                'espAddFiduciaryLiabilityCoverageEndorsement',
                uwReview?.coverageRestrictions,
            );

            const isAllowed = restriction?.allowCoverage ?? true;

            return (
                policy?.status != undefined &&
                !policy.coverageSectionList.find(
                    (coverageSection) =>
                        coverageSection.type ===
                        StructuralComponentTypeCodeListFiduciaryLiabilitySection,
                ) &&
                activePolicyStatuses.includes(policy.status) &&
                quotableCoverages.includes('ShoppingCoverageCodeListFiduciary') &&
                isAllowed
            );
        };

        const isAmendEPLIEndorsementAllowed = (
            documentSpec: EndorsementSpec,
            policy?: Policy,
        ): boolean => {
            if (documentSpec.docType !== 'espAmendEpliLimitsRetentionEndorsement') {
                return true;
            }
            return (
                policy?.status != undefined &&
                policy.coverageSectionList.find(
                    (coverageSection) =>
                        coverageSection.type ===
                        StructuralComponentTypeCodeListEmploymentPracticesLiabilitySection,
                ) !== undefined &&
                activePolicyStatuses.includes(policy.status)
            );
        };

        const isAmendTechEOEndorsementAllowed = (
            documentSpec: EndorsementSpec,
            policy?: Policy,
        ): boolean => {
            if (documentSpec.docType !== 'espAmendTechEoCyberLiabilityCoverageLimitOfLiability') {
                return true;
            }
            return (
                policy?.status != undefined &&
                policy.coverageSectionList.find(
                    (coverageSection) =>
                        coverageSection.type ===
                        StructuralComponentTypeCodeListTechEOCyberLiabilitySection,
                ) !== undefined &&
                activePolicyStatuses.includes(policy.status)
            );
        };

        const isAmendDOEndorsementAllowed = (
            documentSpec: EndorsementSpec,
            policy?: Policy,
        ): boolean => {
            if (documentSpec.docType !== 'espAmendDoCoverageLimitOfLiability') {
                return true;
            }
            return (
                policy?.status != undefined &&
                policy.coverageSectionList.find(
                    (coverageSection) =>
                        coverageSection.type ===
                        StructuralComponentTypeCodeListDirectorsAndOfficersLiabilitySection,
                ) !== undefined &&
                activePolicyStatuses.includes(policy.status)
            );
        };

        const isAllowedForBroker = (documentSpec: EndorsementSpec): boolean => {
            if (!request.isBroker) {
                return true;
            }

            return documentSpec.docType == 'changeAddressEndorsement';
        };

        const availableEnddorsements =
            availableEndorsementsSpecs.value as AvailableEndorsementsSpecs;

        const endorsements: EndorsementDefinition[] = availableEnddorsements
            .filter(
                (documentSpec) =>
                    isPostBindTagPresent(documentSpec) &&
                    isAddEPLIEndorsementAllowed(documentSpec, request.policy) &&
                    isAddDOEndorsementAllowed(documentSpec, request.policy) &&
                    isAddFiduciaryEndorsementAllowed(documentSpec, request.policy) &&
                    isAmendDOEndorsementAllowed(documentSpec, request.policy) &&
                    isAmendTechEOEndorsementAllowed(documentSpec, request.policy) &&
                    isAmendEPLIEndorsementAllowed(documentSpec, request.policy) &&
                    isAllowedForBroker(documentSpec),
            )
            .map((documentSpec) => ({
                type: documentSpec.docType,
                schema: documentSpec.inputSchema,
                name: documentSpec.displayName,
                isPremiumBearing: documentSpec.tags.includes(PREMIUM_BEARING_TAG),
                restriction: findCoverageByDocType(
                    documentSpec.docType,
                    uwReview?.coverageRestrictions,
                ),
                currentOptions: getLimitAndRetention(documentSpec.docType, request.policy),
            }))
            .sort((a, b) => {
                const getPriority = (str = '') => {
                    if (str.startsWith('Add')) return 1;
                    if (str.startsWith('Amend')) return 2;
                    if (str.startsWith('Change')) return 3;
                    if (str.startsWith('Delete')) return 4;
                    return 5; // Default for other strings
                };

                const priorityA = getPriority(a.name);
                const priorityB = getPriority(b.name);

                // First sort by priority
                if (priorityA !== priorityB) {
                    return priorityA - priorityB;
                }

                // If priorities are the same, sort alphabetically
                return (a.name || '').localeCompare(b.name || '');
            });
        return Success(endorsements);
    }
}

export const GetEndorsements: UseCaseClass<GetEndorsements> = GetEndorsementsUseCase;

function findCoverageByDocType(
    docType: string,
    coverageRestrictions?: CoverageRestriction[],
): CoverageRestriction | undefined {
    if (!coverageRestrictions) {
        return;
    }

    let coverageType: CoverageType | undefined;
    if (
        ['espAddEpliCoverageEndorsement', 'espAmendEpliLimitsRetentionEndorsement'].includes(
            docType,
        )
    ) {
        coverageType = 'ShoppingCoverageCodeListEmploymentPractices';
    }

    if (['espAddDoCoverage', 'espAmendDoCoverageLimitOfLiability'].includes(docType)) {
        coverageType = 'ShoppingCoverageCodeListDirectorsAndOfficers';
    }

    if ('espAddFiduciaryLiabilityCoverageEndorsement' == docType) {
        coverageType = 'ShoppingCoverageCodeListFiduciary';
    }

    if ('espAmendTechEoCyberLiabilityCoverageLimitOfLiability' == docType) {
        coverageType = 'ShoppingCoverageCodeListTechSplit';
    }

    if (coverageType) {
        return findCoverageRestrictionByType(coverageRestrictions, coverageType);
    }
}

function getLimitAndRetention(docType: string, policy?: Policy): LimitAndRetention | undefined {
    if (!policy) {
        return;
    }

    const coverageSection = policy.coverageSectionList.find(
        (coverageSection) => coverageSection.type === docTypeToCoverageType[docType],
    );

    if (coverageSection) {
        return {
            limit: coverageSection.limit,
            retention: coverageSection.retention,
            subLimit: coverageSection.subLimit,
        };
    }

    return;
}

const docTypeToCoverageType: Record<string, StructuralComponentTypeCodeListItem> = {
    espAmendEpliLimitsRetentionEndorsement:
        StructuralComponentTypeCodeListEmploymentPracticesLiabilitySection,
    espAmendDoCoverageLimitOfLiability:
        StructuralComponentTypeCodeListDirectorsAndOfficersLiabilitySection,
    espAmendTechEoCyberLiabilityCoverageLimitOfLiability:
        StructuralComponentTypeCodeListTechEOCyberLiabilitySection,
};
