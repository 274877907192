import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { inject, injectable } from '@embroker/shotwell/core/di';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Money } from '@embroker/shotwell/core/types/Money';
import { AsyncResult, Success, isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { DigitalEndorsementsRepository } from '../repositories/digitalEndorsements';

export interface GetEndorsementQuoteRequest {
    endorsementId: UUID;
    endorsementData: unknown;
}

export interface GetEndorsementQuoteResponse {
    rateId: UUID;
    prorateId: UUID;
    currentPremium: Money;
    returnPremium: Money;
    premiumChange: Money;
    taxes: Money;
    fees: Money;
    totalPremiumChange: Money;
}

export interface GetEndorsementQuote extends UseCase {
    execute(
        request: GetEndorsementQuoteRequest,
    ): AsyncResult<GetEndorsementQuoteResponse, InvalidArgument | OperationFailed>;
}

@injectable()
class GetEndorsementQuoteUseCase extends UseCase implements GetEndorsementQuote {
    public static type = Symbol('DigitalEndorsements/GetEndorsementQuote');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(DigitalEndorsementsRepository)
        private digitalEndorsementsRepository: DigitalEndorsementsRepository,
    ) {
        super(eventBus);
    }

    public async execute(
        request: GetEndorsementQuoteRequest,
    ): AsyncResult<GetEndorsementQuoteResponse, InvalidArgument | OperationFailed> {
        const endorsementQuoteResult = await this.digitalEndorsementsRepository.getEndorsementQuote(
            {
                endorsementId: request.endorsementId,
                endorsementData: request.endorsementData,
            },
        );

        if (isErr(endorsementQuoteResult)) {
            return endorsementQuoteResult;
        }

        const endorsementQuote = endorsementQuoteResult.value;

        return Success({
            rateId: endorsementQuote.rateId,
            prorateId: endorsementQuote.prorateId,
            currentPremium: endorsementQuote.currentPremium,
            returnPremium: endorsementQuote.returnPremium,
            premiumChange: endorsementQuote.premiumChange,
            taxes: endorsementQuote.taxes,
            fees: endorsementQuote.fees,
            totalPremiumChange: endorsementQuote.totalPremiumChange,
        });
    }
}

export const GetEndorsementQuote: UseCaseClass<GetEndorsementQuote> = GetEndorsementQuoteUseCase;
