import { Nullable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { AdditionalInterest } from './AdditionalInterest';
import { PropertyCoverage } from './PropertyCoverage';

export interface SubjectOfInsurance {
    id: UUID;
    streetAddress: string;
    city: string;
    state: string;
    zipCode: string;
    deductible: Nullable<Money>;
    buildingNumber: number;
    propertyCoverageList: PropertyCoverage[];
    additionalInterestList: AdditionalInterest[];
    natureOfBusiness: string;
}

export const SubjectOfInsurance = {
    ...defineValidator<SubjectOfInsurance>({
        id: UUID.schema,
        streetAddress: Joi.string().allow(null, ''),
        city: Joi.string().allow(null, ''),
        state: Joi.string().allow(null, ''),
        zipCode: Joi.string().allow(null, ''),
        deductible: Money.schema.allow(null),
        buildingNumber: Joi.number(),
        propertyCoverageList: Joi.array().items(PropertyCoverage.schema),
        additionalInterestList: Joi.array().items(AdditionalInterest.schema),
        natureOfBusiness: Joi.string().allow(''),
    }),
    create(subjectOfInsurance: SubjectOfInsurance) {
        return SubjectOfInsurance.validate(subjectOfInsurance);
    },
};
