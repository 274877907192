import { Immutable } from '@embroker/shotwell/core/types';
import { StackLayout, Text, Tooltip } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { Link } from '../../../../view/components';
import { Lob } from '../../../types/Lob';
import { WorkersCompensationLiabilitySection } from '../../../types/WorkersCompensationLiabilitySection';
import { CoverageSectionTable } from './CoverageSectionTable';
import { CoverageSectionLimitAndRetention } from './CoverageSectionLimitAndRetention';

interface WCCoverageSectionBodyProps {
    lob: Immutable<Lob>;
}

export const WCCoverageSectionBody = ({ lob }: WCCoverageSectionBodyProps) => {
    const workersCompLob = lob.liabilitySection as WorkersCompensationLiabilitySection;

    return (
        <StackLayout gap="32">
            <Text>
                Executives/Officers are{' '}
                <Text style="label 1">
                    {workersCompLob.officersIncluded ? 'included' : 'excluded'}
                </Text>{' '}
                in this policy.
                <Tooltip
                    iconSize="small"
                    text={
                        <React.Fragment>
                            In some states, a company can opt to include or exclude a company's
                            executives and officers from workers compensation coverage. For some
                            businesses, this decision can have a significant impact on workers
                            compensation premiums. It's important to know the rules in all the
                            states where you do business.{' '}
                            <Link href={'/support/contact'}>Contact us </Link> if you’d like help
                        </React.Fragment>
                    }
                />
            </Text>
            <StackLayout gap="16">
                <CoverageSectionLimitAndRetention lob={lob} />
                <StackLayout gap="none">
                    <CoverageSectionTable lob={lob} customRetentionWording={' '} />
                </StackLayout>
            </StackLayout>
        </StackLayout>
    );
};
