import { isErr } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import { Spinner, StatusMessage, Text, WizardLayout } from '@embroker/ui-toolkit/v2';
import React from 'react';
import { GetPolicy } from '../../../../policy/useCases/GetPolicy';
import { EndorsementSlideout } from './EndorsementSlideout';

interface EndorsementIntakeProps {
    policyId: UUID;
    isPolicyReferred?: boolean;
    onDismiss: () => void;
}

export function EndorsementIntake({
    policyId,
    isPolicyReferred,
    onDismiss,
}: EndorsementIntakeProps) {
    if (isPolicyReferred) {
        return <ReferredPolicyComponent onDismiss={onDismiss} />;
    } else {
        return <RegularPolicyComponent policyId={policyId} onDismiss={onDismiss} />;
    }
}

interface ReferredPolicyComponentProps {
    onDismiss: () => void;
}

function ReferredPolicyComponent({ onDismiss }: ReferredPolicyComponentProps) {
    return (
        <WizardLayout title="Modify Policy" onDismiss={onDismiss}>
            <Text style="heading 3">Make changes to your policy</Text>
            <StatusMessage status="warning">
                Your policy is now in a state of referral. This means that you won't be able to
                automatically renew your policy, or purchase a new one. Please contact our customer
                success team if you have any questions.
            </StatusMessage>
        </WizardLayout>
    );
}

interface RegularPolicyComponentProps {
    policyId: UUID;
    onDismiss: () => void;
}
function RegularPolicyComponent({ policyId, onDismiss }: RegularPolicyComponentProps) {
    const { result: getPolicyResult, isLoading: isGetPolicyLoading } = useUseCase(GetPolicy, {
        policyId,
    });

    if (isGetPolicyLoading || getPolicyResult === undefined) {
        return <Spinner />;
    }

    if (isErr(getPolicyResult)) {
        return null;
    }

    return <EndorsementSlideout onDismiss={onDismiss} />;
}
