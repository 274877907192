import { Money } from '@embroker/shotwell/core/types/Money';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';
import { ESPUserInfo } from './ESPUserInfo';

export interface ESPQuoteInfo {
    readonly userInfo: ESPUserInfo;
    readonly revenue: number;
    readonly isFunded: boolean;
    readonly sociusEndorsementPremium?: Money;
    readonly isReferredBecauseExistingCoverage: boolean;
    readonly submittedAt?: Date;
    readonly newInsurerDocumentsReleaseDate?: Date;
    readonly fiduciaryDocumentsReleaseDate?: Date;
    readonly espDocumentsAfterEoCyberSplitReleaseDate?: Date;
}

export const ESPQuoteInfo = {
    ...defineValidator<ESPQuoteInfo>({
        userInfo: ESPUserInfo.schema.required(),
        revenue: Joi.number().required(),
        isFunded: Joi.bool().required(),
        sociusEndorsementPremium: Money.schema.optional(),
        isReferredBecauseExistingCoverage: Joi.bool().required(),
        submittedAt: Joi.date().optional(),
        newInsurerDocumentsReleaseDate: Joi.date().optional(),
        fiduciaryDocumentsReleaseDate: Joi.date().optional(),
        espDocumentsAfterEoCyberSplitReleaseDate: Joi.date().optional(),
    }),
    create(espQuoteInfo: ESPQuoteInfo) {
        return ESPQuoteInfo.validate(espQuoteInfo);
    },
};
