import { InvalidArgument, isError } from '@embroker/shotwell/core/Error';
import { execute } from '@embroker/shotwell/core/UseCase';
import { Nullable } from '@embroker/shotwell/core/types';
import { ErrorLike, Failure, Success } from '@embroker/shotwell/core/types/Result';
import { URI } from '@embroker/shotwell/core/types/URI';
import { Joi, Schema } from '@embroker/shotwell/core/validation/schema';
import { createForm } from '@embroker/shotwell/view/hooks/useForm';
import { Immutable } from '@embroker/ui-toolkit/v2';
import { isSameDay } from 'date-fns';
import { ErrorCode as BrokerErrorCode } from '../../../../brokerDashboard/errors';
import { EditApplication } from '../../../../brokerDashboard/useCases/EditApplication';
import { GenerateAppFileUrl } from '../../../../documents/useCases/GenerateAppFileUrl';
import { navigateToErrorPage } from '../../../../view/errors';
import { NavigateFunction } from '../../../../view/hooks/useNavigation';
import { GetSignaturePacketFileUrl } from '../../../brokerage/useCases/GetSignaturePacketFileUrl';
import { ErrorCode as QuoteErrorCode, isDocGenError } from '../../../errors';
import { SignQuote } from '../../../useCases/SignQuote';
import { UnsignQuote } from '../../../useCases/UnsignQuote';
import { validateEffectiveDate } from '../../../view/components/formValidators';
import { LPLQuote } from '../../entities/LPLQuote';
import { ErrorCode as LPLErrorCode } from '../../errors';
import { LPLHigherLimit } from '../../types/LPLHigherLimit';
import { LPLQuoteInfo } from '../../types/LPLQuoteInfo';
import {
    AggregateLimit,
    ClaimsExpenseType,
    DeductibleType,
    DeductibleTypeSchema,
    LPLQuoteOptions,
    PerClaimDeductible,
    PerClaimLimit,
    SeparateClaimExpenseLimit,
} from '../../types/LPLQuoteOptions';
import { DownloadLPLQuoteSummary } from '../../useCases/DownloadLPLQuoteSummary';
import { DownloadLPLSpecimenPolicy } from '../../useCases/DownloadLPLSpecimenPolicy';
import { LPLRequestHigherLimit } from '../../useCases/LPLRequestHigherLimit';
import { PurchaseLPL } from '../../useCases/PurchaseLPL';
import { ReQuoteLPL } from '../../useCases/ReQuoteLPL';
import {
    isAggregateLimitValid,
    isClaimsExpenseTypeValid,
    isPerClaimDeductibleValid,
    isPerClaimLimitValid,
    isSeparateClaimExpenseLimitValid,
} from './getLPLQuoteFormItems';

export interface LPLQuoteFormData {
    effectiveDate: Date;
    perClaimLimit: PerClaimLimit;
    aggregateLimit: AggregateLimit;
    perClaimDeductible: PerClaimDeductible;
    deductibleType: DeductibleType;
    claimsExpenseType: ClaimsExpenseType;
    separateClaimExpenseLimit: Nullable<SeparateClaimExpenseLimit>;

    agreementToConductSignature: boolean;
    warrantyAndFraudSignature: boolean;

    brokerSignature: boolean;
}

export interface LPLQuoteFormProps {
    isBroker: boolean;
    isAdmin: boolean;
    abortSignal: AbortSignal;
    onUpdateQuote: (newLplQuote: LPLQuote, newLplQuoteInfo?: LPLQuoteInfo) => void;
    onPurchaseSuccess: () => void;
    setQuoteSummaryUrl: (url: URI) => void;
    setSpecimenPolicyUrl: (url: URI) => void;
    quote: LPLQuote;
    stateWithLargestNumber: string;
    numberOfAttorneys: number;
    currentPolicyEndDate?: Date;
    getToday: () => Date;
    navigate: NavigateFunction;
    isRenewal: boolean;
    isStreamline: boolean;
    onGenerateDocument: () => void;
    onDocGenError: () => void;
    openHigherLimitModal: () => void;
    referredQuote: boolean;
    higherLimit?: Immutable<LPLHigherLimit>;
}

const MAX_FUTURE_DAYS_ALLOWED = 90;

export function isDateFeb29(value: Date) {
    return value.getMonth() == 1 && value.getDay() == 29;
}

export function createLPLQuoteForm({
    isBroker,
    isAdmin,
    abortSignal,
    onUpdateQuote,
    onPurchaseSuccess,
    setQuoteSummaryUrl,
    setSpecimenPolicyUrl,
    quote,
    stateWithLargestNumber,
    numberOfAttorneys,
    currentPolicyEndDate,
    getToday,
    navigate,
    isRenewal,
    isStreamline,
    onGenerateDocument,
    onDocGenError,
    openHigherLimitModal,
    referredQuote,
    higherLimit,
}: LPLQuoteFormProps) {
    return createForm<LPLQuoteFormData>({
        fields: {
            effectiveDate: {
                type: 'date',
                validator: Joi.date()
                    .custom((value: Date, helpers) => {
                        if (isRenewal && !isSameDay(quote.options.effectiveDate, value)) {
                            return helpers.error('date.fixed');
                        }
                        if (isDateFeb29(value)) {
                            return helpers.error('date.feb29');
                        }
                        return validateEffectiveDate(
                            value,
                            getToday(),
                            MAX_FUTURE_DAYS_ALLOWED,
                            helpers,
                            isAdmin,
                        );
                    })
                    .required(),
                formatValidationError: (error) => {
                    switch (error.details.validator) {
                        case 'date.min':
                            return 'Effective date can not be in the past.';
                        case 'date.max':
                            return `Effective date can not be more than ${MAX_FUTURE_DAYS_ALLOWED} days in the future.`;
                        case 'date.fixed':
                            return 'Effective date can not be changed for renewal application.';
                        case 'date.feb29':
                            return 'Effective date can not be 29th of February.';
                        default:
                            return error.message;
                    }
                },
            },
            perClaimLimit: {
                type: 'select',
                validator: Joi.number()
                    .required()
                    .custom((value, helpers) => {
                        return helpers.prefs.context !== undefined
                            ? validatePerClaimLimit(
                                  value,
                                  stateWithLargestNumber,
                                  helpers,
                                  higherLimit,
                              )
                            : value;
                    }),
                formatValidationError: (error) => {
                    return error.message;
                },
            },
            aggregateLimit: {
                type: 'select',
                validator: Joi.number()
                    .required()
                    .custom((value, helpers) => {
                        return helpers.prefs.context !== undefined
                            ? validateAggregateLimit(
                                  value,
                                  stateWithLargestNumber,
                                  helpers.prefs.context['perClaimLimit'],
                                  helpers,
                              )
                            : value;
                    }),
                formatValidationError: (error) => {
                    if (error.details.validator === 'aggregateLimit.invalid') {
                        return 'Selected aggregate limit is not valid for selected per claim limit.';
                    } else {
                        return error.message;
                    }
                },
            },
            perClaimDeductible: {
                type: 'select',
                validator: Joi.number()
                    .required()
                    .custom((value, helpers) => {
                        return validatePerClaimDeductible(
                            value,
                            numberOfAttorneys,
                            stateWithLargestNumber,
                            helpers,
                        );
                    }),
                formatValidationError: (error) => {
                    if (error.details.validator === 'perClaimDeductible.invalid') {
                        return 'Selected per claim deductible is not valid for current number of attorneys.';
                    } else {
                        return error.message;
                    }
                },
            },
            deductibleType: {
                type: 'select',
                validator: Joi.string().required(),
            },
            claimsExpenseType: {
                type: 'select',
                validator: Joi.string()
                    .required()
                    .custom((value, helpers) => {
                        return helpers.prefs.context !== undefined
                            ? validateClaimsExpenseType(
                                  value,
                                  stateWithLargestNumber,
                                  helpers.prefs.context['perClaimLimit'],
                                  helpers,
                              )
                            : value;
                    }),
                formatValidationError: (error) => {
                    if (error.details.validator === 'claimsExpenseType.invalid') {
                        return 'Selected claims expense type is not valid for current state with largest number of attorneys.';
                    } else {
                        return error.message;
                    }
                },
            },
            separateClaimExpenseLimit: {
                type: 'select',
                validator: Joi.number()
                    .custom((value, helpers) => {
                        return helpers.prefs.context !== undefined
                            ? validateSeparateClaimExpenseLimit(
                                  value,
                                  helpers.prefs.context['claimsExpenseType'],
                                  helpers.prefs.context['perClaimLimit'],
                                  helpers,
                                  stateWithLargestNumber,
                              )
                            : value;
                    })
                    .allow(null),
                formatValidationError: (error) => {
                    if (error.details.validator === 'separateClaimExpenseLimit.invalid') {
                        return 'Selected separate claim expense limit is not valid for selected per claim limit.';
                    } else {
                        return error.message;
                    }
                },
            },
            agreementToConductSignature: {
                type: 'checkbox',
                validator: Joi.boolean().custom((value, helpers) => {
                    return (
                        isBroker || value || helpers.error('agreementToConductSignature.invalid')
                    );
                }),
            },
            warrantyAndFraudSignature: {
                type: 'checkbox',
                validator: Joi.boolean().custom((value, helpers) => {
                    return isBroker || value || helpers.error('warrantyAndFraudSignature.invalid');
                }),
            },
            brokerSignature: {
                type: 'hidden',
                validator: Joi.boolean().custom((value, helpers) => {
                    return !isBroker || value || helpers.error('brokerSignature.invalid');
                }),
            },
        },
        validator: LPLQuoteOptions.schema.keys({
            effectiveDate: Joi.date()
                .custom((value, helpers) => {
                    if (isRenewal && !isSameDay(quote.options.effectiveDate, value)) {
                        return helpers.error('date.fixed');
                    }
                    return validateEffectiveDate(
                        value,
                        getToday(),
                        MAX_FUTURE_DAYS_ALLOWED,
                        helpers,
                        isAdmin,
                    );
                })
                .required(),
            perClaimLimit: Joi.number().required(),
            aggregateLimit: Joi.number()
                .required()
                .custom((value, helpers) => {
                    return validateAggregateLimit(
                        value,
                        stateWithLargestNumber,
                        helpers.state.ancestors[0].perClaimLimit,
                        helpers,
                    );
                }),
            perClaimDeductible: Joi.number()
                .required()
                .custom((value, helpers) => {
                    return validatePerClaimDeductible(
                        value,
                        numberOfAttorneys,
                        stateWithLargestNumber,
                        helpers,
                    );
                }),
            deductibleType: DeductibleTypeSchema.schema.required(),
            claimsExpenseType: Joi.string()
                .required()
                .custom((value, helpers) => {
                    return validateClaimsExpenseType(
                        value,
                        stateWithLargestNumber,
                        helpers.state.ancestors[0].perClaimLimit,
                        helpers,
                    );
                }),
            separateClaimExpenseLimit: Joi.number().when('claimsExpenseType', {
                switch: [
                    {
                        is: Joi.string()
                            .valid(
                                'ClaimsExpenseTypeInsideOfLimits',
                                'ClaimsExpenseTypeInAdditionToLimits',
                                'ClaimsExpenseTypeInsideOfLimitsWithOffset',
                            )
                            .required(),
                        then: Joi.valid(null),
                    },
                    {
                        is: Joi.string()
                            .valid('ClaimsExpenseTypeSeparateClaimsExpenses')
                            .required(),
                        then: Joi.number()
                            .required()
                            .custom((value, helpers) => {
                                return validateSeparateClaimExpenseLimit(
                                    value,
                                    helpers.state.ancestors[0].claimsExpenseType,
                                    helpers.state.ancestors[0].perClaimLimit,
                                    helpers,
                                    stateWithLargestNumber,
                                );
                            }, 'Validate separateClaimExpenseLimit against claimsExpenseType and perClaimLimit'),
                    },
                ],
            }),
            agreementToConductSignature: Joi.boolean().custom((value, helpers) => {
                return isBroker || value || helpers.error('agreementToConductSignature.invalid');
            }),
            warrantyAndFraudSignature: Joi.boolean().custom((value, helpers) => {
                return isBroker || value || helpers.error('warrantyAndFraudSignature.invalid');
            }),
            brokerSignature: Joi.boolean().custom((value, helpers) => {
                return !isBroker || value || helpers.error('brokerSignature.invalid');
            }),
            isDeselected: Joi.boolean().optional(),
        }),
        actions: {
            downloadSignaturePacket: {
                action: async () =>
                    await execute(GetSignaturePacketFileUrl, {
                        applicationId: quote.applicationId,
                        quoteId: quote.id,
                        abortSignal,
                    }),
                fields: ['effectiveDate'],
            },
            update: {
                action: async (formData) => {
                    const quoteOptions = formDataToQuoteOptions(formData);

                    return await execute(ReQuoteLPL, {
                        applicationId: quote.applicationId,
                        lplQuoteOptions: quoteOptions,
                        abortSignal: abortSignal,
                    });
                },
                fields: [
                    'effectiveDate',
                    'perClaimLimit',
                    'aggregateLimit',
                    'perClaimDeductible',
                    'deductibleType',
                    'claimsExpenseType',
                    'separateClaimExpenseLimit',
                ],
            },
            downloadQuote: {
                action: async () => {
                    return await execute(DownloadLPLQuoteSummary, {
                        quote,
                        abortSignal: abortSignal,
                    });
                },
                fields: [
                    'effectiveDate',
                    'perClaimLimit',
                    'aggregateLimit',
                    'perClaimDeductible',
                    'deductibleType',
                    'claimsExpenseType',
                    'separateClaimExpenseLimit',
                ],
            },
            downloadSpecimenPolicy: {
                action: async () => {
                    return await execute(DownloadLPLSpecimenPolicy, {
                        quote,
                        abortSignal: abortSignal,
                    });
                },
                fields: [
                    'effectiveDate',
                    'perClaimLimit',
                    'aggregateLimit',
                    'perClaimDeductible',
                    'deductibleType',
                    'claimsExpenseType',
                    'separateClaimExpenseLimit',
                ],
            },
            downloadApp: {
                action: async () => {
                    return await execute(GenerateAppFileUrl, {
                        applicationId: quote.applicationId,
                        abortSignal: abortSignal,
                    });
                },
                fields: [
                    'effectiveDate',
                    'perClaimLimit',
                    'aggregateLimit',
                    'perClaimDeductible',
                    'deductibleType',
                    'claimsExpenseType',
                    'separateClaimExpenseLimit',
                ],
            },
            editApplication: {
                action: async () => {
                    if (!quote) {
                        return Failure(InvalidArgument({ argument: 'quote', value: quote }));
                    }

                    return await execute(EditApplication, {
                        applicationId: quote.applicationId,
                        isRenewal,
                    });
                },
                fields: ['effectiveDate'],
            },
            sign: {
                action: async (formData) => {
                    if (
                        (isBroker && formData.brokerSignature) ||
                        (formData.agreementToConductSignature && formData.warrantyAndFraudSignature)
                    ) {
                        return await execute(SignQuote, { quote });
                    }

                    if (quote.status === 'signed') {
                        return await execute(UnsignQuote, { quote });
                    }

                    return Success({ quote });
                },
                fields: [
                    'effectiveDate',
                    'perClaimLimit',
                    'aggregateLimit',
                    'perClaimDeductible',
                    'deductibleType',
                    'claimsExpenseType',
                    'separateClaimExpenseLimit',
                ],
            },
            requestHigherLimit: {
                action: async () => {
                    return await execute(LPLRequestHigherLimit, { lplQuote: quote });
                },
                fields: ['effectiveDate', 'perClaimLimit', 'aggregateLimit'],
            },
            default: async () => {
                return await execute(PurchaseLPL, {
                    quote,
                    abortSignal,
                    isRenewal,
                    isStreamline,
                });
            },
        },
        onSuccess: (value, action) => {
            switch (action) {
                case 'downloadSignaturePacket':
                    window.open(value.documentUrl as string, '_blank');
                    break;
                case 'update':
                    onUpdateQuote(value.lplQuote, value.lplQuoteInfo);
                    if (!isBroker && !referredQuote && value.lplQuote.isIndication) {
                        openHigherLimitModal();
                    }
                    break;
                case 'downloadQuote':
                    setQuoteSummaryUrl(value.fileUrl);
                    onGenerateDocument();
                    break;
                case 'downloadSpecimenPolicy':
                    setSpecimenPolicyUrl(value.fileUrl);
                    onGenerateDocument();
                    break;
                case 'downloadApp':
                    window.open(value.documentUrl as string, '_blank');
                    break;
                case 'sign':
                    onUpdateQuote(value.quote);
                    break;
                case 'editApplication':
                    navigate(
                        URI.build('/shopping/application/basic-info', {
                            applicationId: quote.applicationId,
                        }),
                    );
                    break;
                case 'requestHigherLimit':
                    onUpdateQuote(value.lplQuote, value.lplQuoteInfo);
                    navigate(URI.build('/summary'));
                    break;
                case 'default':
                    onPurchaseSuccess();
                    break;
                default:
                    break;
            }
        },
        onFailure: (errors: Immutable<ErrorLike[]>) => {
            if (!errors.every(isRecoverableError)) {
                navigateToErrorPage(navigate, errors);
            } else if (errors.some(isDocGenError)) {
                onDocGenError();
            }
            return;
        },
    });
}

const formDataToQuoteOptions = (formData: LPLQuoteFormData): LPLQuoteOptions => ({
    effectiveDate: formData.effectiveDate,
    perClaimLimit: formData.perClaimLimit,
    aggregateLimit: formData.aggregateLimit,
    perClaimDeductible: formData.perClaimDeductible,
    deductibleType: formData.deductibleType,
    claimsExpenseType: formData.claimsExpenseType,
    separateClaimExpenseLimit: formData.separateClaimExpenseLimit,
    isDeselected: false,
});

function validatePerClaimDeductible(
    perClaimDeductible: PerClaimDeductible,
    numberOfAttorneys: number,
    stateWithLargestNumber: string,
    helpers: Schema.CustomHelpers<number>,
): PerClaimDeductible | Schema.ErrorReport {
    if (!isPerClaimDeductibleValid(perClaimDeductible, numberOfAttorneys, stateWithLargestNumber)) {
        return helpers.error('perClaimDeductible.invalid');
    }
    return perClaimDeductible;
}

function validateClaimsExpenseType(
    claimsExpenseType: ClaimsExpenseType,
    stateWithLargestNumber: string,
    perClaimLimit: PerClaimLimit,
    helpers: Schema.CustomHelpers<string>,
): ClaimsExpenseType | Schema.ErrorReport {
    if (!isClaimsExpenseTypeValid(claimsExpenseType, stateWithLargestNumber, perClaimLimit)) {
        return helpers.error('claimsExpenseType.invalid');
    }
    return claimsExpenseType;
}

function validateSeparateClaimExpenseLimit(
    separateClaimExpenseLimit: SeparateClaimExpenseLimit,
    claimsExpenseType: ClaimsExpenseType,
    perClaimLimit: PerClaimLimit,
    helpers: Schema.CustomHelpers<number>,
    stateWithLargestNumber: string,
): SeparateClaimExpenseLimit | Schema.ErrorReport {
    if (
        !isSeparateClaimExpenseLimitValid(
            claimsExpenseType,
            perClaimLimit,
            separateClaimExpenseLimit,
            stateWithLargestNumber,
        )
    ) {
        return helpers.error('separateClaimExpenseLimit.invalid');
    }
    return separateClaimExpenseLimit;
}

export function validateAggregateLimit(
    aggregateLimit: AggregateLimit,
    stateWithLargestNumber: string,
    perClaimLimit: PerClaimLimit,
    helpers: Schema.CustomHelpers<number>,
): AggregateLimit | Schema.ErrorReport {
    if (!isAggregateLimitValid(perClaimLimit, stateWithLargestNumber, aggregateLimit)) {
        return helpers.error('aggregateLimit.invalid');
    }
    return aggregateLimit;
}

export function validatePerClaimLimit(
    perClaimLimit: PerClaimLimit,
    stateWithLargestNumber: string,
    helpers: Schema.CustomHelpers<number>,
    higherLimit?: LPLHigherLimit,
): PerClaimLimit | Schema.ErrorReport {
    if (!isPerClaimLimitValid(perClaimLimit, stateWithLargestNumber, higherLimit)) {
        return helpers.error('perClaimLimit.invalid');
    }
    return perClaimLimit;
}

function isRecoverableError(error: ErrorLike) {
    return (
        isError(BrokerErrorCode.EditApplicationErrorCode, error) ||
        isError(LPLErrorCode.PurchaseQuoteNotConsistent, error) ||
        isError(QuoteErrorCode.InvalidAnnualTechFee, error) ||
        isError(QuoteErrorCode.RequoteFailed, error) ||
        isError(QuoteErrorCode.DocGenFailed, error) ||
        isError(QuoteErrorCode.QuoteOptionsApprovalNeeded, error)
    );
}
