import { URI } from '@embroker/shotwell/core/types/URI';
import {
    Button,
    ColumnLayout,
    Modal,
    ModalActions,
    ModalState,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { CoverageCatalog } from '../../CoverageCatalog';
import { BundleCoverageType } from '../../types/BundleQuoteCoverage';
import { DocumentType } from '../../types/BundleQuoteDocument';

interface DocumentModalProps {
    readonly modal: ModalActions & ModalState;
    readonly fileUrl?: URI;
    readonly documentType?: DocumentType;
    readonly coverageType?: BundleCoverageType;
}

export function DocumentModal({ modal, fileUrl, documentType, coverageType }: DocumentModalProps) {
    const documentTitle = coverageType ? CoverageCatalog.getDocumentTitle(coverageType) : undefined;
    const toDisplayName =
        documentType && documentTitle
            ? documentTitle + ' ' + DocumentType.toDisplayName(documentType)
            : 'document';
    return (
        <Modal {...modal} size="small" dismissable>
            <StackLayout gap="32">
                <StackLayout gap="24">
                    <Text style="heading 3" data-e2e="document-ready-to-download">
                        {`Your ${toDisplayName} is ready to download.`}
                    </Text>
                    <Text data-e2e="download-document-by-clicking">
                        {' '}
                        Download the document by clicking on the button.{' '}
                    </Text>
                </StackLayout>
                <ColumnLayout gap="32">
                    <Button
                        as="a"
                        href={fileUrl}
                        target="_blank"
                        rel="noopener"
                        onClick={() => modal.hide()}
                        data-e2e="download-document-modal-button"
                    >
                        Download
                    </Button>
                    <TextButton onClick={modal.hide} data-e2e="back-to-quote">
                        Back to Quote{' '}
                    </TextButton>
                </ColumnLayout>
            </StackLayout>
        </Modal>
    );
}
