import { inject, injectable } from '@embroker/shotwell/core/di';
import {
    InvalidArgument,
    NotAllowed,
    OperationFailed,
    UnknownEntity,
} from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { CalculateSKU } from '../../../analytics/useCases/CalculateSKU';
import { BOPHartfordQuoteRepository } from '../repositories/BOPHartfordQuoteRepository';
import { BOPHartfordPurchased, BOPHartfordQuote } from '../entities/BOPHartfordQuote';

export interface PurchaseHartfordBOPRequest {
    quote: BOPHartfordQuote;
}

export interface PurchaseHartfordBOPResponse {
    policyId: UUID;
    policyDoneTaskId: UUID;
}

export interface PurchaseHartfordBOP extends UseCase {
    execute(
        request: PurchaseHartfordBOPRequest,
    ): AsyncResult<
        PurchaseHartfordBOPResponse,
        NotAllowed | OperationFailed | InvalidArgument | UnknownEntity
    >;
}

@injectable()
class PurchaseHartfordBOPUseCase extends UseCase implements PurchaseHartfordBOP {
    public static type = Symbol('CyberQuote/PurchaseCyber');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BOPHartfordQuoteRepository)
        private hartfordRepository: BOPHartfordQuoteRepository,
        @inject(CalculateSKU.type) private calculateSku: CalculateSKU,
    ) {
        super(eventBus);
    }

    public async execute({
        quote,
    }: PurchaseHartfordBOPRequest): AsyncResult<
        PurchaseHartfordBOPResponse,
        NotAllowed | OperationFailed | InvalidArgument | UnknownEntity
    > {
        const acceptResult = quote.accept();
        if (isErr(acceptResult)) {
            return acceptResult;
        }

        const result = await this.hartfordRepository.purchaseHartfordBOP(
            quote.applicationId,
            quote.id,
        );
        if (isErr(result)) {
            return result;
        }

        const skuResult = await this.calculateSku.execute({
            event: 'purchase',
            applicationId: quote.applicationId,
        });

        const purchaseEvent: BOPHartfordPurchased = {
            id: quote.id,
            origin: 'HartfordBOPQuote',
            name: 'Purchased',
            createdAt: new Date(Date.now()),
            totalPremium: quote.totalPremium,
            applicationId: quote.applicationId,
            sku: skuResult.value,
            isRenewal: false,
        };
        await this.eventBus.publish(purchaseEvent);

        return Success({
            policyId: result.value.policyId,
            policyDoneTaskId: result.value.policyDoneTaskId,
        });
    }
}

export const PurchaseHartfordBOP: UseCaseClass<PurchaseHartfordBOP> = PurchaseHartfordBOPUseCase;
