import { UUID } from '@embroker/shotwell/core/types/UUID';
import {
    StackLayout,
    UploadFile as UploadFileView,
    UploadFileState,
    useStableEventHandler,
} from '@embroker/ui-toolkit/v2';
import React, { useEffect, useState } from 'react';
import { FileUpload } from '../FileUpload';
import { UploadFileCard } from './UploadFileCard';

/**
 * All props of upload file component
 */
export interface UploadFileProps {
    /**
     * File type filter function
     */
    fileTypeFilters?: string[];
    /**
     * File size limit in megabytes
     */
    sizeLimit?: number;
    /**
     * Single or multiple file support
     */
    isMultiple?: boolean;
    /**
     * Placeholder message
     */
    placeholder?: string;
    /**
     * Handle before files are uploaded
     */
    onSelected(selectedFiles: FileUpload[], newFiles: FileUpload[]): void;
    /**
     * Handle after file is uploaded
     */
    onUploaded(uploadedFile: FileUpload, newFiles: FileUpload[]): void;
    /**
     * Handle after file is removed
     */
    onRemoved(removedFile: FileUpload, newFiles: FileUpload[]): void;
    /**
     * Controls weather to show or hide upload file button
     */
    showButton: boolean;
    /**
     * Display text for upload file button
     * It is gonna be used only if showButton is set to true
     */
    buttonText: string;
    /**
     * Controls selected/uploaded files list from outside the component
     */
    files: FileUpload[];
    /**
     * Read-only, passed further to the upload controls and file cards
     */
    readOnly?: boolean;
}

const MB = 1048576;

export const UploadFile = React.forwardRef(function UploadFile(
    {
        isMultiple,
        onSelected,
        onUploaded,
        onRemoved,
        placeholder,
        fileTypeFilters,
        sizeLimit,
        showButton = false,
        buttonText,
        files = [],
        readOnly,
    }: UploadFileProps,
    ref: React.Ref<HTMLDivElement>,
) {
    const [dropBoxContainerState, setDropBoxContainerState] = useState<UploadFileState>('default');
    const [totalUploadProgress, setTotalUploadProgress] = useState<number>(0);

    useEffect(() => {
        if (files.length === 0) {
            setTotalUploadProgress(0);
        }
    }, [files]);

    const checkFilesNumber = (files: File[]) => {
        return isMultiple || files.length <= 1;
    };

    const checkFileType = (file: File) => {
        return fileTypeFilters ? fileTypeFilters.includes(file.type) : true;
    };

    const checkFilesType = (files: File[]) => {
        return Array.from(files).every(checkFileType);
    };

    const checkFileMaxSize = (file: File) => {
        return sizeLimit ? file.size < sizeLimit * MB : true;
    };

    const checkFilesMaxSize = (files: File[]) => {
        return Array.from(files).every(checkFileMaxSize);
    };

    const checkFileMinSize = (file: File) => {
        return file.size > 0;
    };

    const checkFilesMinSize = (files: File[]) => {
        return Array.from(files).every(checkFileMinSize);
    };

    const checkFiles = (files: File[]) => {
        if (!checkFilesNumber(files)) {
            setDropBoxContainerState('numberOfFilesError');
            return false;
        }
        if (!checkFilesType(files)) {
            setDropBoxContainerState('fileTypeError');
            return false;
        }
        if (!checkFilesMaxSize(files)) {
            setDropBoxContainerState('fileSizeError');
            return false;
        }
        if (!checkFilesMinSize(files)) {
            setDropBoxContainerState('fileCorruptedError');
            return false;
        }
        return true;
    };

    const handleNewFilesAdded = (addedFiles: File[]) => {
        if (checkFiles(addedFiles)) {
            setDropBoxContainerState('default');
            const filesToUpload: FileUpload[] = [];
            for (const file of addedFiles) {
                const uploadId = UUID.create();
                filesToUpload.push({
                    id: uploadId,
                    file,
                    s3FileKey: null,
                });
            }

            onSelected(filesToUpload, files.concat(filesToUpload));
        }
    };

    const handleFileUploaded = useStableEventHandler((file: FileUpload) => {
        onUploaded(
            file,
            files.map((item) => (item.id === file.id ? file : item)),
        );
    });

    const handleFileUploadCancelled = useStableEventHandler((file: FileUpload) => {
        onRemoved(
            file,
            files.filter((item) => item.id !== file.id),
        );
    });

    const handleFileUploadProgress = useStableEventHandler(() => {
        const filesBeingUploaded = files.filter((file) => file.uploadedSize !== undefined);
        let uploaded = 0;
        let totalSize = 0;
        for (const fileBeingUploaded of filesBeingUploaded) {
            uploaded += fileBeingUploaded.uploadedSize ?? 0;
            totalSize += fileBeingUploaded.file.size;
        }
        setTotalUploadProgress((uploaded / totalSize) * 100);
    });

    function getNormalizedUploadPercentage() {
        return Math.max(0, Math.min(100, totalUploadProgress));
    }

    const renderFileCards = () => {
        const fileCards = files.map((fileUpload: FileUpload) => {
            return (
                <UploadFileCard
                    key={fileUpload.id}
                    fileUpload={fileUpload}
                    onFileUploadCancelled={handleFileUploadCancelled}
                    onFileUploadCompleted={handleFileUploaded}
                    onFileUploadProgress={handleFileUploadProgress}
                    readOnly={readOnly}
                />
            );
        });

        return <div>{fileCards}</div>;
    };

    return (
        <StackLayout gap="8">
            {readOnly ? null : (
                <UploadFileView
                    buttonText={buttonText}
                    fileTypeFilters={fileTypeFilters}
                    isMultiple={isMultiple}
                    onFilesAdded={handleNewFilesAdded}
                    onUploadAbort={() => {
                        //noop
                    }}
                    placeholder={placeholder}
                    showButton={showButton}
                    state={dropBoxContainerState}
                    uploadPercent={getNormalizedUploadPercentage()}
                />
            )}
            {renderFileCards()}
        </StackLayout>
    );
});

UploadFile.displayName = 'UploadFile';
