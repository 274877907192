import { SKU } from '@app/analytics/types/SKU';
import { CalculateSKU } from '@app/analytics/useCases/CalculateSKU';
import { inject } from '@embroker/shotwell/core/di';
import { DomainEvent } from '@embroker/shotwell/core/event/DomainEvent';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { AsyncResult, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { EditApplicationError } from '../errors';
import { BrokerRepository } from '../repositories';

export interface ApplicationEdited extends DomainEvent {
    origin: 'Application';
    name: 'Edited';
    sku?: SKU;
    isRenewal?: boolean;
}

export interface EditApplicationRequest {
    applicationId: UUID;
    isRenewal?: boolean;
}

export interface EditApplication extends UseCase {
    execute(request: EditApplicationRequest): AsyncResult<void, EditApplicationError>;
}

class EditApplicationUseCase extends UseCase implements EditApplication {
    public static type = Symbol('Broker/EditApplication');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(BrokerRepository) private brokerRepository: BrokerRepository,
        @inject(CalculateSKU.type) private calculateSKU: CalculateSKU,
    ) {
        super(eventBus);
    }

    public async execute({
        applicationId,
        isRenewal,
    }: EditApplicationRequest): AsyncResult<void, EditApplicationError> {
        const result = await this.brokerRepository.editApplication(applicationId);

        if (isErr(result)) {
            return result;
        }

        await this.publishApplicationEditedEvent(applicationId, isRenewal);

        return Success();
    }

    async publishApplicationEditedEvent(applicationId: UUID, isRenewal?: boolean) {
        const skuResult = await this.calculateSKU.execute({
            event: 'quote',
            applicationId,
        });

        const event: ApplicationEdited = {
            id: applicationId,
            createdAt: new Date(Date.now()),
            name: 'Edited',
            origin: 'Application',
            sku: skuResult.value,
            isRenewal,
        };
        await this.eventBus.publish(event);
    }
}

export const EditApplication: UseCaseClass<EditApplication> = EditApplicationUseCase;
