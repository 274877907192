import { UUID } from '@embroker/shotwell/core/types/UUID';
import { execute } from '@embroker/shotwell/core/UseCase';
import { isErr } from '@embroker/shotwell/core/types/Result';
import { addDays, startOfToday } from 'date-fns';
import { map, route, withData } from 'navi';
import React from 'react';
import { PCoMLQuoteReQuotePage } from '../../pcoml/view/components/PCoMLReQuotePage';
import { PCoMLQuoteLandingPage } from '../../pcoml/view/components/PCoMLQuoteLandingPage';
import { GetQuote } from '../../pcoml/useCases/GetQuote';
import { USD } from '@embroker/shotwell/core/types/Money';
import { PCoMLQuoteOptions } from '../../pcoml/entities/PCoMLQuote';
import { limitRetentionOptionsMap } from '../../pcoml/view/config';
import { redirectToWhoopsPage } from '../../../view/errors';
import { InvalidArgument } from '@embroker/shotwell/core/Error';

const defaultRetentionValue = USD(limitRetentionOptionsMap.epl.retentions[0].value);

function shouldUpdateEplRetention(quoteOptions: PCoMLQuoteOptions): boolean {
    return (
        undefined ===
        limitRetentionOptionsMap['epl'].retentions.find(
            (item) => item.value == quoteOptions.epl.retention.amount,
        )
    );
}

function shouldUpdateStartDate(quoteOptions: PCoMLQuoteOptions): boolean {
    const interval = 90;
    const from = startOfToday();
    const to = addDays(from, interval);
    const timestamp = quoteOptions.effectiveDate.getTime();
    return timestamp < from.getTime() || timestamp > to.getTime();
}

const pcomlWizardRouteHandler = map(async (request) => {
    const { applicationId } = request.query;
    if (!UUID.check(applicationId)) {
        return redirectToWhoopsPage([
            InvalidArgument({ argument: 'applicationId', value: applicationId, validator: 'UUID' }),
        ]);
    }

    const getLastQuoteResult = await execute(GetQuote, {
        applicationId,
    });

    if (isErr(getLastQuoteResult)) {
        return redirectToWhoopsPage(getLastQuoteResult.errors);
    }

    if (shouldUpdateEplRetention(getLastQuoteResult.value.quote.options)) {
        const quoteOptions = getLastQuoteResult.value.quote.options;
        const updatedQuoteOptions = {
            ...quoteOptions,
            epl: {
                ...quoteOptions.epl,
                retention: defaultRetentionValue,
            },
        };

        if (shouldUpdateStartDate(updatedQuoteOptions)) {
            updatedQuoteOptions.effectiveDate = startOfToday();
        }

        return route({
            title: 'PCoML quotes',
            view: (
                <PCoMLQuoteReQuotePage
                    applicationId={applicationId}
                    initialQuote={getLastQuoteResult.value.quote}
                    quoteOptions={updatedQuoteOptions}
                />
            ),
        });
    }

    return route({
        title: 'PCoML quotes',
        view: (
            <PCoMLQuoteLandingPage
                applicationId={applicationId}
                initialQuote={getLastQuoteResult.value.quote}
            />
        ),
    });
});

export const pcomlRoutes = {
    '/': map((request) =>
        withData(
            { url: request.mountpath, page: undefined, fullscreen: true },
            pcomlWizardRouteHandler,
        ),
    ),

    '/:page': map((request) =>
        withData(
            { url: request.mountpath, page: request.params.page, fullscreen: true },
            pcomlWizardRouteHandler,
        ),
    ),
};
