import { Immutable } from '@embroker/shotwell/core/types';
import { Money } from '@embroker/shotwell/core/types/Money';
import { CoverageRestriction } from '../../../../quote/esp/types/CoverageRestriction';
import {
    AddESPEndorsementLiabilityCoverageType,
    EditESPEndorsementLiabilityCoverageType,
} from '../../types/ESPEndorsementLiabilityCoverageType';

export const defaultDoLimitOptions = [1_000_000, 2_000_000, 3_000_000, 4_000_000, 5_000_000];

const defaultDoRetentionOptions = [10_000, 25_000, 50_000, 75_000, 100_000, 150_000];

// fixed because of COVID-19
export const defaultEpliLimitOptions = [1000000, 2000000];

const defaultEpliRetentionOptions = [10_000, 25_000, 50_000, 75_000, 100_000, 150_000];

export const defaultEoCyberLimitOptions = [1_000_000, 2_000_000, 3_000_000, 4_000_000, 5_000_000];

const defaultCyberLimitOptions = [0, 1_000_000, 2_000_000, 3_000_000, 4_000_000, 5_000_000];

const defaultFiduciaryLimitOptions = [1_000_000];

const defaultFiduciaryRetentionOptions = [0];

export function getEditCoverageLimitOptions(
    coverageType: EditESPEndorsementLiabilityCoverageType,
    currentLimit: Money,
): number[] {
    const currentLimitInt = Money.toFloat(currentLimit);
    let limitOptions: number[];

    switch (coverageType) {
        case 'LiabilityCoverageCodeListIndemnifiableDirectorsAndOfficersLiability':
            limitOptions = defaultDoLimitOptions.filter(
                (limitOption) => limitOption > currentLimitInt,
            );
            break;
        case 'LiabilityCoverageCodeListTechnologyAndMediaErrorsAndOmissions':
            limitOptions = defaultEoCyberLimitOptions.filter(
                (limitOption) => limitOption >= currentLimitInt,
            );
            break;
        case 'LiabilityCoverageCodeListEmploymentPracticesLiability':
            limitOptions = defaultEpliLimitOptions.filter(
                (limitOption) => limitOption > currentLimitInt,
            );
            break;
        default:
            return [];
    }

    return limitOptions;
}

export function getEditCoverageCyberLimitOptions(
    coverageType: EditESPEndorsementLiabilityCoverageType,
    currentLimit: number,
    currentSecondLimit: Money,
): number[] {
    const currentSecondLimitInt = Money.toFloat(currentSecondLimit);
    return defaultCyberLimitOptions.filter(
        (limitOption) => limitOption <= currentLimit && limitOption >= currentSecondLimitInt,
    );
}

export function getAddCoverageLimitOptions(
    coverageType: AddESPEndorsementLiabilityCoverageType,
): number[] {
    let limitOptions: number[];

    switch (coverageType) {
        case 'LiabilityCoverageCodeListEmploymentPracticesLiability':
            limitOptions = defaultEpliLimitOptions;
            break;
        case 'LiabilityCoverageCodeListIndemnifiableDirectorsAndOfficersLiability':
            limitOptions = defaultDoLimitOptions;
            break;
        case 'LiabilityCoverageCodeListFiduciaryLiability':
            limitOptions = defaultFiduciaryLimitOptions;
            break;
        default:
            return [];
    }

    return limitOptions;
}

export function getAddCoverageRetentionOptions(
    coverageType: AddESPEndorsementLiabilityCoverageType,
    restriction?: Immutable<CoverageRestriction>,
): number[] {
    let retentionOptions: number[];

    switch (coverageType) {
        case 'LiabilityCoverageCodeListIndemnifiableDirectorsAndOfficersLiability':
            retentionOptions = defaultDoRetentionOptions;
            break;
        case 'LiabilityCoverageCodeListEmploymentPracticesLiability':
            retentionOptions = defaultEpliRetentionOptions;
            break;
        case 'LiabilityCoverageCodeListFiduciaryLiability':
            retentionOptions = defaultFiduciaryRetentionOptions;
            break;
        default:
            return [];
    }

    if (restriction) {
        return retentionOptions.filter((retentionOption) =>
            Money.isGreaterThanOrEqual(
                Money.tryFromFloat(retentionOption),
                restriction.minRetention,
            ),
        );
    }

    return retentionOptions;
}
