import { Nullable } from '@embroker/shotwell/core/types';
import { isOK } from '@embroker/shotwell/core/types/Result';
import { useUseCase } from '@embroker/shotwell/view/hooks/useUseCase';
import {
    Button,
    Card,
    CardLayout,
    CenterLayout,
    ColumnLayout,
    Icon,
    StackLayout,
    Text,
    TextButton,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { Quote } from '../../../entities/Quote';
import { DownloadPDFAgreement } from '../../../useCases/DownloadPDFAgreement';
import { GenerateDocumentLink } from '../../../useCases/GenerateDocumentLink';

export interface PDFAgreementProps {
    next(): void;
    previous(): void;
    activeStepIndex: number;
    quote: Nullable<Quote>;
}

async function handleDownload(file: string) {
    window.location.href = file;
}

interface PDFAgreementDisplayProps extends PDFAgreementProps {
    quote: Quote;
}

function PDFAgreementDisplay({ quote, ...props }: PDFAgreementDisplayProps) {
    const { pdfFileKey } = quote;

    const { result } = useUseCase(GenerateDocumentLink, { pdfFileKey });
    const { result: pdf } = useUseCase(DownloadPDFAgreement, { pdfFileKey });

    if (result === undefined || !isOK(result)) {
        return null;
    }

    if (pdf === undefined || !isOK(pdf)) {
        return null;
    }

    const stylePDF = {
        display: 'block',
        margin: 'auto',
        maxWidth: '100%',
    };

    return (
        <StackLayout gap="24">
            <TextButton
                size="small"
                data-e2e="fif-pdf-agreement-back-btn"
                icon="bold-caret-left"
                onClick={props.previous}
            >
                Back to loan section
            </TextButton>
            <Text style="heading 2">Embroker Premium Financing</Text>
            <CardLayout>
                <CardLayout.Header>
                    <ColumnLayout split="1">
                        <Text style="heading 5">Financing Agreement</Text>
                        <TextButton
                            data-e2e="fif-pdf-agreement-download-btn"
                            onClick={() => handleDownload(pdf.value)}
                        >
                            <Icon size="small" name="download" />
                            {'  '}Download PDF agreement
                        </TextButton>
                    </ColumnLayout>
                </CardLayout.Header>
                <Card>
                    <object
                        className="object"
                        data={result.value}
                        height="500"
                        style={stylePDF}
                        type="application/pdf"
                        width="1000"
                    >
                        <embed src={result.value} type="application/pdf" />
                    </object>
                    <CenterLayout>
                        <Button
                            data-e2e="fif-pdf-agreement-accept-btn"
                            appearance="primary"
                            onClick={props.next}
                        >
                            Accept
                        </Button>
                    </CenterLayout>
                </Card>
            </CardLayout>
        </StackLayout>
    );
}

export function PDFAgreement(props: PDFAgreementProps) {
    if (props.activeStepIndex !== 1 || props.quote === null) {
        return null;
    }
    return <PDFAgreementDisplay {...(props as PDFAgreementDisplayProps)} />;
}
