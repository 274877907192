import { PcomlQuoteOptions } from '@embroker/shotwell-api/app';
import { inject } from '@embroker/shotwell/core/di';
import {
    Aborted,
    InvalidArgument,
    OperationFailed,
    Timeout,
    UnknownEntity,
} from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Immutable } from '@embroker/shotwell/core/types';
import { USD } from '@embroker/shotwell/core/types/Money';
import { addYears } from 'date-fns';
import { AsyncResult, Failure, isErr, Success } from '@embroker/shotwell/core/types/Result';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { CalculateSKU } from '../../../analytics/useCases/CalculateSKU';
import { ApplicationRepository } from '../../../shopping/repositories/ApplicationRepository';
import { TasksRepository } from '../../../tasks/repositories';
import { PCoMLQuote, PCoMLQuoteOptions } from '../entities/PCoMLQuote';
import { ConfigFetchFailed, StateAgentLicenseNotFound } from '../errors';
import { QuoteRepository } from '../repositories/QuoteRepository';

export interface UpdateQuoteRequest {
    quote: PCoMLQuote;
    quoteOptions: PCoMLQuoteOptions;
    abortSignal?: AbortSignal;
}

export interface UpdateQuoteResponse {
    quote: Immutable<PCoMLQuote>;
}

export interface UpdateQuote extends UseCase {
    execute(
        request: UpdateQuoteRequest,
    ): AsyncResult<
        UpdateQuoteResponse,
        | UnknownEntity
        | InvalidArgument
        | OperationFailed
        | StateAgentLicenseNotFound
        | ConfigFetchFailed
        | Timeout
        | Aborted
    >;
}

class UpdateQuoteUseCase extends UseCase implements UpdateQuote {
    public static type = Symbol('PCoMLQuote/updateQuote');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(QuoteRepository) private quoteRepository: QuoteRepository,
        @inject(TasksRepository) private tasksRepository: TasksRepository,
        @inject(ApplicationRepository) private applicationRepository: ApplicationRepository,
        @inject(CalculateSKU.type) private calculateSku: CalculateSKU,
    ) {
        super(eventBus);
    }

    public async execute({
        quote,
        quoteOptions,
        abortSignal,
    }: UpdateQuoteRequest): AsyncResult<
        UpdateQuoteResponse,
        | UnknownEntity
        | InvalidArgument
        | OperationFailed
        | StateAgentLicenseNotFound
        | ConfigFetchFailed
        | Timeout
        | Aborted
    > {
        const apiQuoteOptions: PcomlQuoteOptions = {
            effective_date: quoteOptions.effectiveDate,
            effective_period_end: addYears(quoteOptions.effectiveDate, 1),
            dno_rating_info: quoteOptions.dno.isSelected,
            dno_limit: quoteOptions.dno.limit.amount,
            dno_retention: quoteOptions.dno.retention.amount,
            epli_rating_info: quoteOptions.epl.isSelected,
            epli_limit: quoteOptions.epl.limit.amount,
            epli_retention: quoteOptions.epl.retention.amount,
            policy_administration_fee_pre_tax: USD(0),
            policy_administration_fee_post_tax: USD(0),
        };

        const updateResult = await this.quoteRepository.updateQuotePCoMLAsync(
            quote.applicationId,
            apiQuoteOptions,
        );

        if (isErr(updateResult)) {
            return updateResult;
        }

        const pollForTaskStatusResult = await this.tasksRepository.pollForTaskStatus(
            updateResult.value,
            abortSignal,
        );

        if (isErr(pollForTaskStatusResult)) {
            if (pollForTaskStatusResult.errors[0].message === 'requoteApplication_NoAgentFound') {
                return Failure(StateAgentLicenseNotFound());
            }
            return pollForTaskStatusResult;
        }

        const quoteResult = await this.quoteRepository.getQuoteByApplicationId(quote.applicationId);

        if (isErr(quoteResult)) {
            return quoteResult;
        }

        const getApplicationResult = await this.applicationRepository.getApplication(
            quote.applicationId,
        );
        if (isErr(getApplicationResult)) {
            return getApplicationResult;
        }

        const pcomlQuote = quoteResult.value;
        const application = getApplicationResult.value;

        const skuResult = await this.calculateSku.execute({
            event: 'quote',
            applicationId: quote.applicationId,
        });

        application.onQuotePremium({
            totalPremium: pcomlQuote.totalPayable,
            applicationId: application.id,
            isRenewal: application.isRenewal(),
            sku: skuResult.value,
        });
        await this.eventBus.publishEntityEvents(application);

        return Success<UpdateQuoteResponse>({
            quote: quoteResult.value,
        });
    }
}

export const UpdateQuote: UseCaseClass<UpdateQuote> = UpdateQuoteUseCase;
