import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

export interface PCoMLConfig {
    readonly newInsurerDocumentsReleaseDate?: Date;
}

export const PCoMLConfig = {
    ...defineValidator<PCoMLConfig>({
        newInsurerDocumentsReleaseDate: Joi.date().optional(),
    }),
    create(pcomlConfig: PCoMLConfig) {
        return PCoMLConfig.validate(pcomlConfig);
    },
};
