import { InvalidArgument } from '@embroker/shotwell/core/Error';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { ExportUWReviewFromAPIResponseFailed, UwReviewFetchFailed } from '../../errors';
import { InsuranceApplicationRestriction } from '../../types/InsuranceApplicationRestriction';

export interface InsuranceApplicationRestrictionRepository {
    get(
        insuranceApplicationId: UUID,
    ): AsyncResult<
        InsuranceApplicationRestriction | undefined,
        InvalidArgument | UwReviewFetchFailed | ExportUWReviewFromAPIResponseFailed
    >;
}

export const InsuranceApplicationRestrictionRepository = Symbol(
    'InsuranceApplicationRestrictionRepository',
);
