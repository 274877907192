import { valueObject } from '@embroker/shotwell/core/types/ValueObject';
import { defineValidator, Joi } from '@embroker/shotwell/core/validation/schema';

interface LPLBindFactors {
    /**
     * Type of claims expense
     */
    claimsExpense: string;
    /**
     * Claim expense limit in case Separate Claims Expenses Coverage option
     */
    separateClaimsExpenseLimit?: number;
    /**
     * Deductible type bind factor
     */
    deductibleType: string;
}

export const CLAIMS_EXPENSE_TYPE_SEPARATE_CLAIMS_EXPENSES =
    'ClaimsExpenseTypeSeparateClaimsExpenses';

export const LPLBindFactors = valueObject({
    ...defineValidator<LPLBindFactors>({
        claimsExpense: Joi.string().required().allow(''),
        separateClaimsExpenseLimit: Joi.alternatives().conditional('claimsExpense', {
            is: CLAIMS_EXPENSE_TYPE_SEPARATE_CLAIMS_EXPENSES,
            then: Joi.number().required(),

            // In the other cases, the value has no significance
            // We could make it forbidden, but that could break admin
            otherwise: Joi.number().optional(),
        }),
        deductibleType: Joi.string().required().allow(''),
    }),
});

export type BindFactors = LPLBindFactors | undefined;

export const BindFactors = valueObject({
    ...defineValidator<BindFactors>(Joi.alternatives(LPLBindFactors.schema)),
});
