import { inject } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed, UnknownEntity } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Nullable } from '@embroker/shotwell/core/types';
import { EmailAddress } from '@embroker/shotwell/core/types/EmailAddress';
import { AsyncResult, Failure, isErr, isOK, Success } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { OrganizationRepository } from '../repositories/OrganizationRepository';
import { UserRepository } from '../repositories/UserRepository';
import { Unauthenticated } from '../errors';
import { PhoneNumber } from '@embroker/shotwell/core/types/PhoneNumber';
import { BrokerRepository } from '../../brokerDashboard/repositories';

export interface GetActiveUserProfileResponse {
    readonly id: UUID;
    readonly email: EmailAddress;
    readonly phoneNumber?: PhoneNumber;
    readonly firstName: string;
    readonly lastName: string;
    readonly title: string;
    readonly isUserLoggedInBySignup: boolean;
    readonly organizations: {
        readonly id: UUID;
        readonly companyLegalName: string;
        readonly naics: Nullable<string>;
    }[];
    readonly organizationCount: number;
    readonly isBroker: boolean;
}

export interface GetActiveUserProfile extends UseCase {
    execute(): AsyncResult<
        GetActiveUserProfileResponse,
        Unauthenticated | InvalidArgument | OperationFailed | UnknownEntity
    >;
}

class GetUserProfileUseCase extends UseCase implements GetActiveUserProfile {
    /**
     * A symbol identifying this Use Case.
     */
    public static type = Symbol('UserOrg/GetActiveUserProfile');
    /**
     * Constructor for GetActiveUserProfile use case class instance
     *
     * @param eventBus An event bus this Use Case will publish events to.
     * @param userRepository is user repository used to store user entity with updated properties
     */
    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(UserRepository) private userRepository: UserRepository,
        @inject(OrganizationRepository) private organizationRepository: OrganizationRepository,
        @inject(BrokerRepository) private brokerRepository: BrokerRepository,
    ) {
        super(eventBus);
    }

    public async execute(): AsyncResult<
        GetActiveUserProfileResponse,
        Unauthenticated | InvalidArgument | OperationFailed | UnknownEntity
    > {
        const userResult = await this.userRepository.getActiveUser();

        if (isErr(userResult)) {
            return userResult;
        }

        const user = userResult.value;

        // NB: The user.email should **never** be null at this point, but we have to check!
        if (user.email === null) {
            return Failure(UnknownEntity('User', user.id));
        }

        const organizationsResult = await this.organizationRepository.getOrganizationsForUser(
            userResult.value.id,
        );

        if (isErr(organizationsResult)) {
            return organizationsResult;
        }

        const organizations = organizationsResult.value;

        let organizationCount = organizations.length;

        if (user.isBroker) {
            const organizationsResult = await this.brokerRepository.getOrganizationCount();
            if (isOK(organizationsResult)) {
                organizationCount = organizationsResult.value;
            }
        }

        return Success<GetActiveUserProfileResponse>({
            id: user.id,
            email: user.email,
            firstName: user.firstName ?? '',
            lastName: user.lastName ?? '',
            title: user.title ?? '',
            phoneNumber: user.phoneNumber !== null ? user.phoneNumber : undefined,
            organizations: organizations.map(({ id, companyLegalName, naics }) => ({
                id,
                companyLegalName,
                naics,
            })),
            isUserLoggedInBySignup: user.isUserLoggedInBySignup,
            organizationCount,
            isBroker: user.isBroker ?? false,
        });
    }
}

export const GetActiveUserProfile: UseCaseClass<GetActiveUserProfile> = GetUserProfileUseCase;
