import {
    Button,
    CardLayout,
    CenterLayout,
    CoverLayout,
    FrameLayout,
    Image,
    StackLayout,
    Text,
    Tooltip,
} from '@embroker/ui-toolkit/v2';
import React from 'react';
import { Bar, BarChartOptions } from './ChartModule';
import { BrokerReportProps, ChartReadyData } from './ReportDefinitions';

interface BarChartReportProps extends BrokerReportProps {
    chartReadyData: ChartReadyData | undefined;
    chartOptions: BarChartOptions;
    reportEmpty: boolean;
    reportEnabled: boolean;
    reportLoading: boolean;
    enableReportCallback: () => void;
}

export function BarChartReport({
    title,
    width = 980,
    height = 400,
    className,
    chartReadyData,
    reportEmpty,
    chartOptions,
    reportEnabled,
    reportLoading,
    enableReportCallback,
}: BarChartReportProps) {
    let view = resourceNotFoundView(width, height);

    if (reportEnabled) {
        if (reportLoading) {
            view = loadingView(width, height);
        } else {
            if (chartReadyData) {
                if (!reportEmpty) {
                    view = (
                        <Bar
                            data={chartReadyData}
                            height={height}
                            width={width}
                            options={chartOptions}
                        />
                    );
                } else {
                    view = emptyStateView(width, height);
                }
            }
        }
    } else {
        view = reportDisabledView(width, height, enableReportCallback);
    }

    return (
        <CardLayout className={className}>
            {title && (
                <CardLayout.Header>
                    <Text style="heading 5">
                        {title}

                        <Tooltip
                            iconSize="small"
                            placement="top"
                            text={
                                <React.Fragment>
                                    The report contains data for new business only, excluding any
                                    Embroker renewal business.
                                    <br />
                                    Please note that the report is not updated in real-time, but
                                    once a day.
                                </React.Fragment>
                            }
                        />
                    </Text>
                </CardLayout.Header>
            )}
            <CardLayout.Body>{view}</CardLayout.Body>
        </CardLayout>
    );
}

function emptyStateView(width: number, height: number) {
    return (
        <FrameLayout aspectRatio={[width, height]}>
            <CoverLayout>
                <Image inline={false} name="shotwell-1" width={53} height={64} />
                <CenterLayout centerText>
                    <Text as="h4" style="heading 4">
                        No data.
                    </Text>
                </CenterLayout>
            </CoverLayout>
        </FrameLayout>
    );
}

function loadingView(width: number, height: number) {
    return (
        <FrameLayout aspectRatio={[width, height]}>
            <CoverLayout>
                <Image inline={false} name="shotwell-1" width={53} height={64} />
                <CenterLayout centerText>
                    <Text as="h4" style="heading 4">
                        Loading...
                    </Text>
                </CenterLayout>
            </CoverLayout>
        </FrameLayout>
    );
}

function resourceNotFoundView(width: number, height: number) {
    return (
        <FrameLayout aspectRatio={[width, height]}>
            <StackLayout gap="32">
                <CenterLayout>
                    <Image inline={false} name="shotwell-1" />
                </CenterLayout>
                <CenterLayout centerText>
                    <StackLayout>
                        <Text as="h2" style="heading 2">
                            Sorry for the inconvenience
                        </Text>
                        <Text as="p" style="body 1">
                            It seems like the requested resource could not be found.
                        </Text>
                    </StackLayout>
                </CenterLayout>
            </StackLayout>
        </FrameLayout>
    );
}

function reportDisabledView(width: number, height: number, enableReportCallback: () => void) {
    return (
        <FrameLayout aspectRatio={[width, height]}>
            <CoverLayout>
                <CenterLayout centerText>
                    <Button onClick={enableReportCallback}>Display report</Button>
                </CenterLayout>
            </CoverLayout>
        </FrameLayout>
    );
}
