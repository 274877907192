import React from 'react';
import { StackLayout, Text, ColumnLayout, Button, TextButton } from '@embroker/ui-toolkit/v2';

interface PCoMLEndorsementErrorModalProps {
    onClose: () => void;
    onGoToContactUs: () => void;
}

export function PCoMLEndorsementErrorModal({
    onClose,
    onGoToContactUs,
}: PCoMLEndorsementErrorModalProps) {
    return (
        <StackLayout gap="32">
            <StackLayout gap="24">
                <Text style="heading 3">Something went wrong here.</Text>
                <Text>Pardon the malfunction. We're fixing it. Please try again later.</Text>
            </StackLayout>
            <ColumnLayout gap="32">
                <Button onClick={onGoToContactUs}>Contact Embroker</Button>
                <TextButton onClick={onClose}>Cancel</TextButton>
            </ColumnLayout>
        </StackLayout>
    );
}
