import { inject, injectable } from '@embroker/shotwell/core/di';
import { InvalidArgument, OperationFailed } from '@embroker/shotwell/core/Error';
import { DomainEventBus } from '@embroker/shotwell/core/event/DomainEventBus';
import { Money } from '@embroker/shotwell/core/types/Money';
import { AsyncResult } from '@embroker/shotwell/core/types/Result';
import { UUID } from '@embroker/shotwell/core/types/UUID';
import { UseCase, UseCaseClass } from '@embroker/shotwell/core/UseCase';
import { ESPEndorsementRepository } from '../repositories/ESPEndorsementRepository';
import { AddESPEndorsementLiabilityCoverageType } from '../types/ESPEndorsementLiabilityCoverageType';
import { ESPEndorsementRate } from '../types/ESPEndorsementRate';

export interface RateAddCoverageESPEndorsementRequest {
    policyId: UUID;
    coverageTypeCode: AddESPEndorsementLiabilityCoverageType;
    limit: Money;
    retention: Money;
    effectiveDate: Date;
    enhanced: boolean;
}

export interface RateAddCoverageESPEndorsement extends UseCase {
    execute(
        request: RateAddCoverageESPEndorsementRequest,
    ): AsyncResult<ESPEndorsementRate, InvalidArgument | OperationFailed>;
}

@injectable()
class RateAddCoverageESPEndorsementUseCase
    extends UseCase
    implements RateAddCoverageESPEndorsement
{
    public static type = Symbol('ESPEndorsement/RateAddCoverageESPEndorsement');

    constructor(
        @inject(DomainEventBus) eventBus: DomainEventBus,
        @inject(ESPEndorsementRepository)
        private espEndorsementRepository: ESPEndorsementRepository,
    ) {
        super(eventBus);
    }

    public async execute({
        policyId,
        coverageTypeCode,
        limit,
        retention,
        effectiveDate,
        enhanced,
    }: RateAddCoverageESPEndorsementRequest): AsyncResult<
        ESPEndorsementRate,
        OperationFailed | InvalidArgument
    > {
        return await this.espEndorsementRepository.rateAddCoverageEndorsement({
            policyId: policyId,
            coverageTypeCode: coverageTypeCode,
            limit: limit,
            retention: retention,
            effectiveDate: effectiveDate,
            enhanced: enhanced,
        });
    }
}

export const RateAddCoverageESPEndorsement: UseCaseClass<RateAddCoverageESPEndorsement> =
    RateAddCoverageESPEndorsementUseCase;
